/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from "react";
import { getTextualEditorHeaderComponentType, TextualEditorView } from "@jss/js-text-editor";
import { Conf, IEditorViewProps } from "@jss/js-rest-api";
import { AppHeader } from "../common/AppHeader";
import { useSelector } from "react-redux";
import { Footer } from '@jss/js-common';


export function TextualEditorViewWrapper({ descriptor, path, doExit, onFirstSave }: IEditorViewProps) {
    const getHeaderComponent: getTextualEditorHeaderComponentType = ({ goToHome, requestLogout }) => {
        return (
            <AppHeader goToHome={goToHome} requestLogout={requestLogout} />
        );
    }
    //language is actually not used since it is read from the server conf, but this will trigger the update
    const language: string | undefined = useSelector((state: any) => {
        return (state.persistentReducer.storedData as Map<string, any>).get('language') as string | undefined
    });
    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                <TextualEditorView
                    descriptor={descriptor}
                    path={path}
                    doExit={doExit}
                    onFirstSave={onFirstSave}
                    getHeaderComponent={getHeaderComponent}
                    language={language}
                />
            </div>
            <Footer config={Conf.get('jrws.jrio.serverInfo')}/>
        </div>
    );
}
