/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg, UiProperty, UiPropertyProps } from '../UiProperty';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@jss/js-common';
import { APDescriptor } from '../APDescriptor';
import i18n from '../../../../../i18n';

const TYPEID = 'PAccordeon';
reg(TYPEID, (mc) => { return <UPAccordeon mcontext={mc} />; });
export class PAccordeon extends APDescriptor {
    expanded?: boolean = false;
    public constructor(init: Partial<PAccordeon>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

export class UPAccordeon extends React.Component<UiPropertyProps> {

    private isExpanded = () => {
        const defaultValue = (this.props.mcontext.descriptor as PAccordeon).expanded === true;
        const isExpanded = this.props.mcontext.rootModel.getIn(['widgetStatus', this.props.mcontext.descriptor.id, 'expanded'], defaultValue) as boolean;
        return isExpanded;        
    }

    private handleExpanded = () => {
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expanded'], !this.isExpanded(), false, true);
    }

    render() {
        if (this.props.mcontext.descriptor.layouts === undefined || this.props.mcontext.descriptor.layouts.length === 0)
            return <></>;
        const isExpanded = this.isExpanded();
        return <Accordion expanded={isExpanded} onChange={this.handleExpanded} size='small' >
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" size="small">
                <Typography style={{ textTransform: 'capitalize' }} >{i18n.t(this.props.mcontext.descriptor.label)}</Typography>
            </AccordionSummary>
            <AccordionDetails
                style={this.props.mcontext.descriptor.style ? this.props.mcontext.descriptor.style : { display: 'block' }}
                className={this.props.mcontext.descriptor.className}>
                {//render the element only if expanded
                    isExpanded && this.props.mcontext.descriptor.layouts?.map((key, index) => {
                        key.readonly = this.props.mcontext.descriptor.readonly;
                        return <UiProperty key={'ad' + key.id + '.' + index} mcontext={{ ...this.props.mcontext, descriptor: key }} />
                    })}
            </AccordionDetails>
        </Accordion>;
    }

}