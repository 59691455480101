/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { FormControlledSelect, FormControlledWritableCombo } from "@jss/js-common";
import { getKey, getPath, reg, UiPropertyProps } from '../UiProperty';
import { APDescriptor } from '../APDescriptor';
import { resolveAttribute } from '../../types/common/StyleResolver';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { IState } from '../../../../../reducers';
import i18n from '../../../../../i18n';

const TYPEID = 'fontname';
reg(TYPEID, (mc) => { return <UPFontName mcontext={mc} />; });
export class PFontName extends APDescriptor {
    items: { key: string, value: string, deprecated?: boolean }[] =[{ key: 'Serif', value: 'Serif' }, { key: 'SansSerif', value: 'SansSerif' }];
    default?: string;
    writeable?: boolean;
    public constructor(init: Partial<PFontName>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

interface FontName {
    fontCache?: Map<string, any>,
    additionalIcon?: React.ReactElement, 
}

class UPFontName extends React.Component<UiPropertyProps & FontName> {

    render() {
        const d = this.props.mcontext.descriptor as PFontName;

        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        let v = this.props.mcontext.model.getIn(p);
        let inheritedValue;
        if (v === undefined && this.props.mcontext.descriptor.inheritedPropertyId) {
            const elementPath = getPath(undefined, this.props.mcontext.elements[0].path).slice(0, 2);
            const element = this.props.mcontext.model.getIn(elementPath) as Map<string, any>;
            inheritedValue = resolveAttribute(this.props.mcontext.model, element, this.props.mcontext.descriptor.inheritedPropertyId);
        }

        if (v === undefined) v = d.default;
        const fontsData : Map<string, string> | undefined = this.props.fontCache.get('fonts');
        let items : { key: string, value: string }[] = [];
        if (fontsData){
            items = fontsData.keySeq().toArray().map((fontFamily: string) => {
                return {
                    key: fontFamily,
                    value: fontFamily,
                }
            });
        }
        items = items.sort((a, b) => a.value.localeCompare(b.value));

        // if (v === undefined && inheritedValue === undefined && items.length > 0) {
        //     inheritedValue = items[0].key;
        // }
        if (d.writeable === true) {
            return <FormControlledWritableCombo key={getKey(p)} items={items}
                className={this.props.mcontext.descriptor.className}
                style={this.props.mcontext.descriptor.style}
                value={v}
                placeholder={inheritedValue}
                onComboChange={this.onWritableNameChange}
                label={i18n.t(d.label)}
                disabled={d.readonly}
                InputLabelProps={d.deprecated ? {className: 'deprecatedProperty'} : undefined}
                inline={true}
                size="small"
                iconButtons={this.props.additionalIcon ? [this.props.additionalIcon] : undefined}
            />
        }
        return <FormControlledSelect key={getKey(p)} items={items}
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            value={v}
            placeholder={inheritedValue}
            disabled={d.readonly}
            onItemSelected={this.onNameChange}
            InputLabelProps={d.deprecated ? {className: 'deprecatedProperty'} : undefined}
            label={i18n.t(d.label)}
            inline={true} size={'small'}
            iconButtons={this.props.additionalIcon ? [this.props.additionalIcon] : undefined}
        />
    }
    public onNameChange = (str) => {
        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: str });
        });
    }
    public onWritableNameChange = (selectedKey: string | undefined, selectedText: string) => {
        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: selectedKey ? selectedKey : selectedText });
        });
    }

}

const mapStateToProps = (state: IState) => {
    return {
        fontCache: state.getIn(['report', 'cachedData', 'comboValues'], Map<string, any>()),
    };
}

export default connect(mapStateToProps)(UPFontName);