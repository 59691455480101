/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { ErrorDialog, MenuButton } from '@jss/js-common';
import { IRepositoryItemDescriptor, MIME_TYPES, Permission, RepositoryApi, RESOURCE_TYPE } from '@jss/js-rest-api';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import StorageIcon from '@material-ui/icons/Storage';
import * as React from 'react';
import { CreateFolder } from './actions/CreateFolder';
import { RunContainer, RunContext } from '../RunContainer';
import i18n from "../i18n";
import { AxiosResponse } from 'axios';
import { getUUID } from './repositoryUtils';
import InboxIcon from '@material-ui/icons/Inbox';
import OrganizationIcon from '@material-ui/icons/Apartment';
import HomeIcon from '@material-ui/icons/House';
import ReportIcon from '@material-ui/icons/Report';
import { Tooltip } from '@material-ui/core';


export interface INewResourcesProps {
    path: IRepositoryItemDescriptor[];
    openResource: (descriptor: IRepositoryItemDescriptor) => void;
    refresh: () => void;
}

interface IState {
    isCreatingFolder: string | undefined;
}

function buildFileSelector(handleFileChange: (e) => void, acceptedFileTypes: string) {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', acceptedFileTypes)
    fileSelector.onchange = handleFileChange;
    fileSelector.onclick = function () {
        (this as any).value = null;
    };
    return fileSelector;
}

function buildFolderSelector(handleFileChange: (e) => void) {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('webkitdirectory', 'webkitdirectory');
    fileSelector.onchange = handleFileChange;
    fileSelector.onclick = function () {
        (this as any).value = null;
    };
    return fileSelector;
}


export class NewResources extends React.Component<INewResourcesProps, IState>{

    protected fileSelector: HTMLInputElement;

    protected folderSelector: HTMLInputElement;

    public state: IState = {
        isCreatingFolder: undefined,
    };

    componentDidMount() {
        this.fileSelector = buildFileSelector(this.onFileSelect, '*.*');
        this.folderSelector = buildFolderSelector(this.onFolderSelect);
    }

    private onFolderSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        interface fileEntry {
            name: string,
            file: File,
            children: undefined,
        }

        interface folderEntry {
            name: string,
            children: Map<string, fileEntry | folderEntry>,
            file: undefined,
        }
        const invalidFiles: { file: File, error: string }[] = [];
        const structure = { name: '/', children: new Map<string, folderEntry>(), file: undefined };
        const pathuuidMap = new Map<string, string>();
        const showInvalidFiles = () => {
            if (invalidFiles.length > 0) {
                const content = <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ marginBottom: 10 }}>{i18n.t('common.dialog.fileSizeError')}</span>
                    {
                        invalidFiles.map((invalidFile, index) => {
                            return <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{invalidFile.error}</span>}>
                                    <ReportIcon fontSize='small' style={{ color: 'red' }} />
                                </Tooltip>
                                <span style={{ marginLeft: 5 }}>{invalidFile.file.name}</span>
                            </div>;
                        })
                    }
                </div>
                this.context.showDialog(ErrorDialog,
                    {
                        content,
                        onClose: this.props.refresh,
                    }
                );
            } else {
                this.props.refresh();
            }
        }
        const limit = RepositoryApi.inst().getFileUploadLimit() * 1024 * 1024;
        if (event.currentTarget.files.length > 0) {
            for (let i = 0; i < event.currentTarget.files.length; i++) {
                const file = event.currentTarget.files[i];
                if (file.size > limit) {
                    invalidFiles.push({ file: file, error: `File exceeds maximum file size of ${limit}MB` });
                } else {
                    const path = file.webkitRelativePath.split('/');
                    const filename = path.pop();
                    let currentLevel: folderEntry = structure;
                    path.forEach((folder) => {
                        if (!currentLevel.children.has(folder)) {
                            currentLevel.children.set(folder, { name: folder, children: new Map<string, folderEntry>(), file: undefined });
                        }
                        currentLevel = currentLevel.children.get(folder) as folderEntry;
                    });
                    currentLevel.children.set(filename, { name: filename, file: file, children: undefined });
                }
            }

            const foldersToCreate: { name: string, path: string }[] = [];
            const filesToCreate: { name: string, path: string, file: File, uuid?: string }[] = [];

            const createFolderStructure = (currentLevel: folderEntry | fileEntry, currentPath: string) => {
                if (currentLevel.children) {
                    foldersToCreate.push({ name: currentLevel.name, path: currentPath });
                    const children = currentLevel.children;
                    children.forEach((child) => {
                        createFolderStructure(child, currentPath + '/' + currentLevel.name);
                    });
                } else {
                    filesToCreate.push({ name: currentLevel.name, path: currentPath, file: currentLevel.file });
                }
            }
            structure.children.forEach((child) => {
                createFolderStructure(child, '');
            })

            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                const textContent = fileReader.result as string;
                if (textContent) {
                    // TODO - perform some kind of validation of the loaded content
                    // or be sure that a sort of validation is triggered when editorContent is updated
                    const base64data = textContent.substring(textContent.indexOf(',') + 1);//Base64.encode(textContent);
                    const newDescriptor: IRepositoryItemDescriptor = {
                        name: filesToCreate[0].name,
                        data: base64data,
                        type: RESOURCE_TYPE.FILE,
                        path: this.getPath() + filesToCreate[0].path
                    }
                    const uuid = pathuuidMap.has(filesToCreate[0].path) ? pathuuidMap.get(filesToCreate[0].path) : newDescriptor.path;
                    this.context.runNoCancelNoMessage(RepositoryApi.inst().save)(uuid.length > 0 ? 'uuid:' + uuid : '', newDescriptor).then((response: AxiosResponse) => {
                        if (response.data) {
                            filesToCreate.shift();
                            if (filesToCreate.length > 0) {
                                fileReader.readAsDataURL(filesToCreate[0].file);
                            } else {
                                showInvalidFiles();
                            }
                        } else {
                            this.props.refresh();
                        }
                    }).catch(error => {
                        console.log(error);
                        const errorFile = filesToCreate.shift();
                        invalidFiles.push({ file: errorFile.file, error: error.message ? error.message : error });
                        if (filesToCreate.length > 0) {
                            fileReader.readAsDataURL(filesToCreate[0].file);
                        } else {
                            showInvalidFiles();
                        }
                    });
                }
            };

            const createFolder = (foldersArray: { name: string, path: string }[]) => {
                const folderToCreate = foldersArray.shift();
                const p = this.getPath();

                this.context.runNoCancel(RepositoryApi.inst().save)('/', {
                    name: folderToCreate.name,
                    type: RESOURCE_TYPE.FOLDER,
                    path: p + folderToCreate.path,
                }).then((response) => {
                    const uuid = response.data.uuid;
                    pathuuidMap.set(folderToCreate.path + '/' + folderToCreate.name, uuid);
                    if (foldersArray.length > 0) {
                        createFolder(foldersArray);
                    } else {
                        //folders created, upload files
                        if (filesToCreate.length > 0) {
                            fileReader.readAsDataURL(filesToCreate[0].file);
                        } else {
                            showInvalidFiles();
                        }
                    }
                }).catch(error => {
                    console.log(error)
                    if (filesToCreate.length > 0) {
                        fileReader.readAsDataURL(filesToCreate[0].file);
                    } else {
                        showInvalidFiles();
                    }
                });
            }
            if (foldersToCreate.length > 0) {
                createFolder(foldersToCreate);
            } else {
                showInvalidFiles();
            }
        }
    }

    private onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files: File[] = [];
        const invalidFiles: { file: File, error: string }[] = [];
        if (event.currentTarget.files.length > 0) {
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                const textContent = fileReader.result as string;
                if (textContent) {
                    // TODO - perform some kind of validation of the loaded content
                    // or be sure that a sort of validation is triggered when editorContent is updated
                    const base64data = textContent.substring(textContent.indexOf(',') + 1);//Base64.encode(textContent);
                    const newDescriptor: IRepositoryItemDescriptor = {
                        name: files[0].name,
                        data: base64data,
                        type: RESOURCE_TYPE.FILE,
                        path: this.getPath()
                    }
                    const path = this.props.path;
                    this.context.runNoCancelNoMessage(RepositoryApi.inst().save)(path.length > 0 ? 'uuid:' + path[path.length - 1].uuid : '', newDescriptor).then((response: AxiosResponse) => {
                        if (response.data) {
                            console.log(response.data);
                            files.shift();
                            if (files.length > 0) {
                                fileReader.readAsText(files[0]);
                            } else {
                                this.props.refresh();
                                showInvalidFiles();
                            }
                        }
                    }).catch(error => {
                        console.log(error);
                        const errorFile = files.shift();
                        invalidFiles.push({ file: errorFile, error: error.message ? error.message : error });
                        if (files.length > 0) {
                            fileReader.readAsDataURL(files[0]);
                        } else {
                            showInvalidFiles();
                        }
                    });
                }
            };
            const showInvalidFiles = () => {
                if (invalidFiles.length > 0) {
                    const content = <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ marginBottom: 10 }}>{i18n.t('common.dialog.fileSizeError')}</span>
                        {
                            invalidFiles.map((invalidFile, index) => {
                                return <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{invalidFile.error}</span>}>
                                        <ReportIcon fontSize='small' style={{ color: 'red' }} />
                                    </Tooltip>
                                    <span style={{ marginLeft: 5 }}>{invalidFile.file.name}</span>
                                </div>;
                            })
                        }
                    </div>
                    this.context.showDialog(ErrorDialog,
                        {
                            content,
                            onClose: this.props.refresh,
                        }
                    );
                }
            }
            const limit = RepositoryApi.inst().getFileUploadLimit() * 1024 * 1024;
            for (let i = 0; i < event.currentTarget.files.length; i++) {
                const file = event.currentTarget.files[i];
                if (file.size > limit) {
                    invalidFiles.push({ file: file, error: `File exceeds maximum file size of ${limit}MB` });
                } else {
                    files.push(file);
                }
            }
            if (files.length > 0) {
                fileReader.readAsDataURL(files[0]);
            } else if (invalidFiles.length > 0) {
                showInvalidFiles();
            }
        }
    }

    handleUploadPress = () => {
        this.fileSelector.click();
    }

    handleFolderUploadPress = () => {
        this.folderSelector.click();
    }

    public render() {
        const newVisualizationMenu = [];
        const hasPermission = this.isResourceCreationAllowed();
        if (hasPermission) {
            newVisualizationMenu.push({
                onClick: this.createNewReportName,
                text: i18n.t('newresources.report.text'),
                icon: <PostAddIcon fontSize="small" />,
            });
            newVisualizationMenu.push(null);
            newVisualizationMenu.push({
                onClick: this.createNewDatasetName,
                text: i18n.t('newresources.da.text'),
                icon: <StorageIcon fontSize="small" />,
            });
        }
        if (this.isFolderCreationAllowed()) {
            newVisualizationMenu.push(null);
            newVisualizationMenu.push({
                onClick: this.createNewFolderName,
                text: i18n.t('newresources.folder.text'),
                icon: <CreateNewFolderIcon fontSize="small" />,
            });
        }
        if (this.isOwnerCreationAllowed()) {
            newVisualizationMenu.push(null);
            newVisualizationMenu.push({
                onClick: this.createNewOwnerUser,
                text: i18n.t('newresources.owner.user.text'),
                icon: <HomeIcon fontSize="small" />,
            });
            newVisualizationMenu.push({
                onClick: this.createNewOwnerOrganization,
                text: i18n.t('newresources.owner.organization.text'),
                icon: <OrganizationIcon fontSize="small" />,
            });
        }
        if (this.isRepositoryCreationAllowed()) {
            newVisualizationMenu.push(null);
            newVisualizationMenu.push({
                onClick: this.createNewRepository,
                text: i18n.t('newresources.repository.text'),
                icon: <InboxIcon fontSize="small" />,
            });
        }

        if (hasPermission) {
            newVisualizationMenu.push({
                onClick: this.handleUploadPress,
                text: i18n.t('newresources.upload.text'),
                icon: <CloudUploadIcon fontSize="small" />,
            });
        }
        if (this.isFolderCreationAllowed()) {
            newVisualizationMenu.push({
                onClick: this.handleFolderUploadPress,
                text: i18n.t('newresources.uploadFolder.text'),
                icon: <DriveFolderUploadIcon fontSize="small" />,
            });
        }
        if (newVisualizationMenu.length > 0)
            return (<div className="tc-jsw-repository-left-column-buttons-group">
                <MenuButton text={i18n.t('newresources.menubutton.text')} size="large" color="primary" variant="contained" endIcon={<KeyboardArrowDownIcon />} content={newVisualizationMenu} id={'newRepositoryElement'} />
                {this.renderNameModal()}
            </div>);

        return <></>;
    }

    private renderNameModal = () => {
        const p = this.getPath();
        if (this.state.isCreatingFolder) {
            return <Dialog style={{ minHeight: 150 }}
                open={true}
                aria-labelledby="simple-dialog-title"
                fullWidth={true}
                maxWidth={'sm'}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        this.onCancel();
                    }
                }}>
                <DialogContent style={{ minHeight: 150 }}>
                    <div style={{ position: 'absolute', minHeight: 150, top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', flexDirection: 'column', flex: 1 }}>
                        <RunContainer>
                            <CreateFolder path={p} close={this.onCancel} refresh={this.props.refresh} type={this.state.isCreatingFolder} />
                        </RunContainer>
                    </div>
                </DialogContent>
            </Dialog>;
        }
    }

    private isNameUsed = (currentName: string, usedNames: IRepositoryItemDescriptor[]) => {
        const sameNameElement = usedNames.find((currentDescriptor) => { return currentDescriptor.name === currentName; });
        return sameNameElement !== undefined;
    }

    private createNewDatasetName = () => {
        this.context.run(RepositoryApi.inst().listWithCancel)(getUUID(this.props.path)).then((response: AxiosResponse) => {
            if (response.data) {
                let baseName = 'My Data Adapter';
                if (response.data.children) {
                    const existingFiles = Object.values(response.data.children) as IRepositoryItemDescriptor[];
                    let counter = 1;
                    while (this.isNameUsed(baseName + '.jrdax', existingFiles)) {
                        baseName = 'My Data Adapter ' + counter;
                        counter++;
                    }
                }
                const d = {
                    name: baseName + '.jrdax',
                    type: RESOURCE_TYPE.FILE,
                    mime: MIME_TYPES.DATA_ADAPTER_JDBC,
                    path: this.getPath()
                }
                RepositoryApi.inst().setPermission(d, Permission.READ, Permission.WRITE);
                this.props.openResource(d);
            }
        }).catch(error => console.log(error));
    }

    private createNewReportName = () => {
        this.context.run(RepositoryApi.inst().listWithCancel)(getUUID(this.props.path)).then((response: AxiosResponse) => {
            if (response.data) {
                let baseName = 'My Report';
                if (response.data.children) {
                    const existingFiles = Object.values(response.data.children) as IRepositoryItemDescriptor[];
                    let counter = 1;
                    while (this.isNameUsed(baseName + '.jrxml', existingFiles)) {
                        baseName = 'My Report ' + counter;
                        counter++;
                    }
                }
                const d = {
                    name: baseName + '.jrxml',
                    type: RESOURCE_TYPE.FILE,
                    mime: MIME_TYPES.JRXML,
                    path: this.getPath()
                }
                RepositoryApi.inst().setPermission(d, Permission.READ, Permission.WRITE);
                this.props.openResource(d);
            }
        }).catch(error => console.log(error));
    }

    private createNewFolderName = () => {
        this.setState({ isCreatingFolder: 'folder' });
    }
    private createNewRepository = () => {
        this.setState({ isCreatingFolder: 'repository' });
    }
    private createNewOwnerUser = () => {
        this.setState({ isCreatingFolder: 'user' });
    }
    private createNewOwnerOrganization = () => {
        this.setState({ isCreatingFolder: 'organization' });
    }
    private isFolderCreationAllowed = () => {
        const p = this.props.path[this.props.path.length - 1];
        if (!p)
            return false;
        const mimes = Object.values([MIME_TYPES.REPORTUNIT]) as string[];
        if (p.type === RESOURCE_TYPE.CONTAINER && mimes.includes(p.mime))
            return false;
        return this.isCreationAllowed(p);
    }
    private isOwnerCreationAllowed = () => {
        const p = this.props.path[this.props.path.length - 1];
        if (!p)
            return false;
        const mimes = Object.values([MIME_TYPES.REPOSITORY_ROOT_JACKRABBIT]) as string[];
        if (p.type === RESOURCE_TYPE.CONTAINER && mimes.includes(p.mime))
            return p.permission && RepositoryApi.inst().hasPermission(p, Permission.WRITE);
        else return false;
    }
    private isRepositoryCreationAllowed = () => {
        const p = this.props.path[this.props.path.length - 1];
        if (!p)
            return false;
        const mimes = Object.values([MIME_TYPES.OWNER_ORGANIZATION, MIME_TYPES.OWNER_USER]) as string[];
        if (p.type === RESOURCE_TYPE.CONTAINER && mimes.includes(p.mime)) {
            if (p.properties && p.properties['jrws.owner.max.repositories'] && p.children && Object.keys(p.children).length >= p.properties['jrws.owner.max.repositories'])
                return false;
            return p.permission && RepositoryApi.inst().hasPermission(p, Permission.WRITE);
        }
        else return false;
    }

    private isResourceCreationAllowed = () => {
        const p = this.props.path[this.props.path.length - 1];
        if (!p)
            return false;
        const mimes = Object.values([]) as string[];
        if (p.type === RESOURCE_TYPE.CONTAINER && mimes.includes(p.mime))
            return false;
        return this.isCreationAllowed(p);
    }

    private isCreationAllowed = (p: IRepositoryItemDescriptor): boolean => {
        if (p.permission) {
            if (RepositoryApi.inst().hasPermission(p, Permission.WRITE)) {
                const mimes = Object.values([MIME_TYPES.REPOSITORY_ROOT_JACKRABBIT, MIME_TYPES.OWNER_ORGANIZATION, MIME_TYPES.OWNER_USER]) as string[];
                if (p.type === RESOURCE_TYPE.CONTAINER && mimes.includes(p.mime))
                    return false;
            } else
                return false;
        }
        return true;
    }

    private onCancel = () => {
        this.setState({ isCreatingFolder: undefined });
    }

    private getPath = (): string => {
        return RepositoryApi.inst().getParentPath([...this.props.path])

        // const pathParts: IRepositoryItemDescriptor[] = this.props.path;
        // if (pathParts.length > 0) {
        //     const last = pathParts[pathParts.length - 1];
        //     if (last.path)
        //         return last.path + (last.path.endsWith('/') ? '' : '/') + last.name;
        //     if (last.uuid)
        //         return last.uuid;
        // }
        // return '/';
    }
}

NewResources.contextType = RunContext;