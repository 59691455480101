/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { i18n } from '@jss/js-common';
import * as React from 'react';
import { connect } from 'react-redux';

import { IState } from '../../../reducers';
import { MGenericElement } from '../properties/types/elements/MGenericElement';

interface IDummyElement {
  id: string;
  elementType: string;
  element?: any;
  zoom?: number;
  availableWidth: number,
  availableHeight: number,
}


class DummyElement extends React.Component<IDummyElement> {


  public shouldComponentUpdate = (nextProps: Readonly<IDummyElement>) => {

    const visualProperties = ['width', 'height'];

    for (const propName of visualProperties) {
      if (this.props.element.get(propName) !== nextProps.element.get(propName)) {
        return true;
      }
      if (this.props.elementType !== nextProps.elementType) {
        return true;
      }
    }

    return false; // Nothing impacting the content of the figure is changed...
  }

  public render() {

    return (
      <div style={{ width: '100%', height: '100%', fontSize: 12, textAlign: 'left', display: 'flex', position: 'relative' }}>
        <div style={{ paddingTop: 5, paddingLeft: 5 }}>
          {MGenericElement.icon}
        </div>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, textAlign: 'center'}}>
          {i18n.t('properties.unknownElement')} {this.props.elementType}
        </div>
      </div>
    );
  }


}

const mapStateToProps = (state: IState, props: IDummyElement) => {

  return {
    element: state.getIn(['report', 'model', 'elements', props.id]),
    zoom: state.getIn(['report', 'zoom'])
  };
}

// const mapDispatchToProps = dispatch => {
//   return { 
//     selectElement: (id: string, add: boolean) => {
//       dispatch( selectElement(id, add) );
//     },
//   };
// }

export default connect(mapStateToProps)(DummyElement);   // ,mapDispatchToProps