/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../ui/APDescriptor";
import { PCategories } from "../../ui/composite/UPCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { MDivElementDataset } from "../dataset/MDatasetRun";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { FMapPath } from "./MMapPath";
import { PConnectedTableDetail } from "../../ui/tables/UPConnectedTableDetail";

export const FTibcoStyle = {

};

export const MMapStyle: APDescriptor = new PCategories({
    id: 'map.styles',
    layouts: [
        new PDiv({
            id: 'map.styles.styles',
            label: 'Styles', layouts: [new PConnectedTableDetail({
                id: 'items', label: 'Styles', rowLabel: 'Style', rowLabelPaths: ['name/value', 'name/valueExpression'], expanded: true,
                layouts: [new PDiv({
                    label: 'Style',
                    layouts: [FMapPath.name,
                    FMapPath.style,
                    new PAccordeon({
                        id: 'stroke', label: "Stroke", layouts: [FMapPath.strokeColor, FMapPath.strokeOpacity,
                        FMapPath.strokeWeight]
                    }),
                    FMapPath.fillColor, FMapPath.isPolygon, FMapPath.clickable, FMapPath.editable, FMapPath.draggable, FMapPath.geodesic, FMapPath.visible, FMapPath.zIndex
                    ]
                })]
            })]
        }),
        { ...MDivElementDataset, path: 'dataset' }
    ]
});