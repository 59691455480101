
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import ReportDesigner, { IReportDesigner } from './components/report/ReportDesigner';
import { CommandRegistry, IResourceListenerManager, IEditor, CollapsiblePanel } from '@jss/js-common';
import { IState } from './reducers';
import Outline from './components/report/outline/Outline';
import ReportPalette from './components/report/palette/ReportPalette';
import SelectedAndDatasetProperties from './components/report/properties/SelectedAndDatasetProperties';
import { Map } from 'immutable';
import * as ReportActions from './actions/reportActions';
import { getEditorIndex } from './reducers/report/reportReducer';
import { PanelDefinition, PanelOrSubPanelExpansionProps, PanelSizeStateDefinition } from '@jss/js-common/src/uxpl/CollapsiblePanel/collapsiblePanelTypes';
import BookEditor from './components/report/BookEditor/BookEditor';
import ParametersTab from './components/report/parameters/ParametersTab';
import MetadataTab from './components/report/QueryEditor/MetadataTab';
import { TableTypes } from './components/common/JrxmlModel/reader/JrxmlTableUtils';
import { CrosstabTypes } from './components/common/JrxmlModel/reader/JrxmlCrosstabUtils';

export interface IResourceHandlerReceiver {
    resourceListenerManager: IResourceListenerManager[],
    commandRegistry: CommandRegistry,
    hidePreviewTab?: boolean,
}

export class ReportEditor extends React.Component<IResourceHandlerReceiver & { language?: string }> implements IEditor {

    private designerRef: React.RefObject<IEditor & IReportDesigner> = React.createRef<IEditor & IReportDesigner>();

    constructor(props: { resourceListenerManager: IResourceListenerManager[], commandRegistry: CommandRegistry }) {
        super(props);
    }

    public getEditorContent = () => {
        if (this.designerRef.current) {
            return this.designerRef.current.getEditorContent();
        }
        return undefined;
    }

    public render() {
        const newProps: any = {
            ...this.props,
            resourceListenerManagers: this.props.resourceListenerManager,
            commandRegistry: this.props.commandRegistry,
        }
        return <ReportDesigner {...newProps} ref={this.designerRef} />;
    }

    public save() {
        if (this.designerRef.current) {
            return this.designerRef.current.save();
        }
    }

    public undo() {
        if (this.designerRef.current) {
            return this.designerRef.current.undo();
        }
    }

    public redo() {
        if (this.designerRef.current) {
            return this.designerRef.current.redo();
        }
    }
}

interface IMultiEditor {
    report?: Map<string, any>;
    widgetStatus?: Map<string, any>;
    language?: string;
    isTextControlFocused?: boolean,
    setObjectProperties?: (path: string[], value: any) => void;
    deleteVisualElements?: (paths: string[][]) => void;
    updateElementsSizeAndPosition?: (changes) => void;
    setWidgetStatus?: (path, data) => void;
}

class MultiPageEditor extends React.Component<IResourceHandlerReceiver & IMultiEditor> {

    private leftPanel: React.RefObject<HTMLDivElement> = React.createRef();

    private rightPanel: React.RefObject<HTMLDivElement> = React.createRef();

    private reportEditorRef: React.RefObject<ReportEditor> = React.createRef<ReportEditor>();

    constructor(props: { resourceListenerManager: IResourceListenerManager[], commandRegistry: CommandRegistry }) {
        super(props);
    }

    onKeyPress = (event: KeyboardEvent) => {
        const isModifierPressed = event.metaKey || event.ctrlKey;
        let catched = false;
        const selectedElement = document.activeElement?.tagName.toLowerCase();
        const isTextSelected = selectedElement === 'input' || selectedElement === 'textarea';
        if (isModifierPressed) {
            switch (event.key) {
                case 's': {
                    this.save();
                    catched = true;
                    break;
                }
                case 'z': {
                    this.undo();
                    catched = true;
                    break;
                }
                case 'y': {
                    this.redo();
                    catched = true;
                    break;
                }
            }
        } else {
            const KeyID = event.keyCode;
            if (!isTextSelected) {
                if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                    const selection: Map<string, any> = this.props.report.get('selection');
                    if (selection) {
                        const actions = [];
                        selection.forEach((elementInfo, elementId: string) => {
                            const element = this.props.report.getIn(['model', 'elements', elementId]) as Map<string, any>;
                            if (element) {
                                let x = element.get('x');
                                let y = element.get('y');
                                switch (event.key) {
                                    case 'ArrowDown': {
                                        y = y + 1;
                                        break;
                                    }
                                    case 'ArrowUp': {
                                        y = y - 1;
                                        break;
                                    }
                                    case 'ArrowLeft': {
                                        x = x - 1;
                                        break;
                                    }
                                    case 'ArrowRight': {
                                        x = x + 1;
                                        break;
                                    }
                                }
                                actions.push({
                                    id: elementId,
                                    x,
                                    y,
                                })
                            }
                        });
                        if (actions.length > 0) {
                            this.props.updateElementsSizeAndPosition(actions);
                        }
                    }
                }
            }
            switch (KeyID) {
                case 8:
                    if (isTextSelected) {
                        break;
                    }
                case 46:
                    const selection: Map<string, any> = this.props.report.get('selection');
                    const elementToDelete: string[][] = [];
                    selection.forEach((selectedInfo) => {
                        if (this.isDeleteAllowed(selectedInfo)){
                            elementToDelete.push(selectedInfo.path);
                        }
                    });
                    this.props.deleteVisualElements(elementToDelete);
                    catched = true;
                    break;
            }
        }
        if (catched) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    isDeleteAllowed = (element) => {
        const type = element.type;
        //don't allow delete with keyboard on table and crosstab cells
        const tableKeys = Object.values(TableTypes);
        if (tableKeys.includes(type)){
            return false;
        }
        const crosstabKeys = Object.values(CrosstabTypes);
        if (crosstabKeys.includes(type)){
            return false;
        }
        return true;
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyPress, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyPress, false);
    }

    private getpanel1 = () : PanelDefinition[] => {
        return [
            {
                id: 'paletteOutlineColumn',
                width: this.props.widgetStatus.getIn(['panelProperties', 'paletteOutlineColumn', 'width'], 350) as number,
                subPanels: [
                    {
                        id: 'palettePanel',
                        open: this.props.widgetStatus.getIn(['panelProperties', 'palettePanel', 'open'], true) as boolean,
                        label: "Palette",
                        height: this.props.widgetStatus.getIn(['panelProperties', 'palettePanel', 'height'], 300) as number,
                        minHeight: 150,
                        content: <ReportPalette />
                    },
                    {
                        id: 'outlinePanel',
                        open: this.props.widgetStatus.getIn(['panelProperties', 'outlinePanel', 'open'], true) as boolean,
                        label: "Outline",
                        minHeight: 150,
                        content: <Outline language={this.props.language} />
                    }
                ]
            },
        ];
    }

    private getPanel1Book = () : PanelDefinition[]  => {
        return [
            {
                id: 'paletteOutlineColumn',
                width: this.props.widgetStatus.getIn(['panelProperties', 'paletteOutlineColumn', 'width'], 350) as number,
                subPanels: [
                    {
                        id: 'outlinePanel',
                        open: this.props.widgetStatus.getIn(['panelProperties', 'outlinePanel', 'open'], true) as boolean,
                        label: "Outline",
                        content: <Outline />
                    }
                ]
            },
        ];
    }

    private getPanel2 = () : PanelDefinition[]  => {
        return [
            {
                id: 'propertiesColumn',
                width: this.props.widgetStatus.getIn(['panelProperties', 'propertiesColumn', 'width'], 450) as number,
                subPanels: [
                    {
                        id: 'propertiesPanel',
                        open: this.props.widgetStatus.getIn(['panelProperties', 'propertiesPanel', 'open'], true) as boolean,
                        hideHeader: true,
                        label: "Properties",
                        content: <SelectedAndDatasetProperties />
                    },
                ]
            },
        ]
    }

    private getPanel1QueryEditor = () : PanelDefinition[]  => {
        return [
            {
                id: 'paletteOutlineColumn',
                width: this.props.widgetStatus.getIn(['panelProperties', 'paletteOutlineColumn', 'width'], 350) as number,
                subPanels: [
                    {
                        id: 'metadataColumn',
                        open: this.props.widgetStatus.getIn(['panelProperties', 'metadataColumn', 'open'], true) as boolean,
                        hideHeader: false,
                        label: "Metadata",
                        height: this.props.widgetStatus.getIn(['panelProperties', 'metadataColumn', 'height'], 250) as number,
                        content: <MetadataTab />
                    },
                    {
                        id: 'parameters',
                        height: this.props.widgetStatus.getIn(['panelProperties', 'parameters', 'height'], 250) as number,
                        open: this.props.widgetStatus.getIn(['panelProperties', 'parameters', 'open'], true) as boolean,
                        hideHeader: false,
                        label: "Parameters",
                        content: <ParametersTab />
                    },
                ]
            },
        ]
    }

    private isBookReport = () => {
        const sectionType = this.props.report.getIn(['model', 'sectionType']) as string;
        return sectionType && sectionType.toLowerCase() === 'part';
    }

    public save() {
        if (this.reportEditorRef.current) {
            return this.reportEditorRef.current.save();
        }
    }

    public undo() {
        if (this.reportEditorRef.current) {
            return this.reportEditorRef.current.undo();
        }
    }

    public redo() {
        if (this.reportEditorRef.current) {
            return this.reportEditorRef.current.redo();
        }
    }

    private getMaxWidth = (panelId: string): number => {
        const currentEditorIndex = getEditorIndex(this.props.report);
        let isQueryEditorOpen = false;
        if (currentEditorIndex !== undefined) {
            isQueryEditorOpen = this.props.report.getIn(['subeditors', currentEditorIndex, 'type']) === 'datasetQueryEditor';
        }
        const reservedWidth = isQueryEditorOpen ? 350 : 200;
        if (panelId === 'paletteOutlineColumn') {
            if (this.rightPanel.current) {
                const rightWidth = this.rightPanel.current.clientWidth;
                return window.innerWidth - rightWidth - reservedWidth;
            } else {
                return window.innerWidth;
            }
        } else {
            if (this.leftPanel.current) {
                const leftPanel = this.leftPanel.current.clientWidth;
                return window.innerWidth - leftPanel - reservedWidth;
            } else {
                return window.innerWidth;
            }
        }
    }

    private onPanelResizeStop = (state: PanelSizeStateDefinition[]) => {
        state.forEach((panel) => {
            this.props.setWidgetStatus(['panelProperties', panel.id, 'width'], panel.width);
            panel.subPanels.forEach((subpanel) => {
                this.props.setWidgetStatus(['panelProperties', subpanel.id, 'height'], subpanel.height);
            })
        });
    }

    private onExpandedClick = (state: PanelOrSubPanelExpansionProps) => {
        this.props.setWidgetStatus(['panelProperties', state.id, 'open'], true);
        state.subPanels.forEach((panel) => {
            this.props.setWidgetStatus(['panelProperties', panel.id, 'open'], true);
        });
    }

    private onCollapseClick = (state: PanelOrSubPanelExpansionProps) => {
        this.props.setWidgetStatus(['panelProperties', state.id, 'open'], false);
        state.subPanels.forEach((panel) => {
            this.props.setWidgetStatus(['panelProperties', panel.id, 'open'], false);
        });
    }

    private getLeftColumn = () => {
        const currentEditorIndex = getEditorIndex(this.props.report);
        let isQueryEditorOpen = false;
        if (currentEditorIndex !== undefined) {
            isQueryEditorOpen = this.props.report.getIn(['subeditors', currentEditorIndex, 'type']) === 'datasetQueryEditor';
        }
        if (isQueryEditorOpen) {
            return <CollapsiblePanel
                style={{ display: 'flex', backgroundColor: '#f7f6f6' }}
                anchor="left"
                panels={this.getPanel1QueryEditor()}
                computeMaxWidth={this.getMaxWidth}
                ref={this.leftPanel}
                onResizeStop={this.onPanelResizeStop}
                onExpandClick={this.onExpandedClick}
                onCollapseClick={this.onCollapseClick}
            />
        } else if (this.isBookReport()) {
            return <CollapsiblePanel
                style={{ display: 'flex', backgroundColor: '#f7f6f6' }}
                anchor="left"
                panels={this.getPanel1Book()}
                computeMaxWidth={this.getMaxWidth}
                ref={this.leftPanel}
                onResizeStop={this.onPanelResizeStop}
                onExpandClick={this.onExpandedClick}
                onCollapseClick={this.onCollapseClick}
            />
        } else {
            return <CollapsiblePanel
                style={{ display: 'flex', backgroundColor: '#f7f6f6' }}
                anchor="left"
                panels={this.getpanel1()}
                onExpandClick={this.onExpandedClick}
                onCollapseClick={this.onCollapseClick}
                computeMaxWidth={this.getMaxWidth}
                ref={this.leftPanel}
                onResizeStop={this.onPanelResizeStop}
            />
        }
    }

    private getRightColumn = () => {
        return <CollapsiblePanel
            style={{ display: 'flex', backgroundColor: '#f7f6f6' }}
            anchor="right"
            panels={this.getPanel2()}
            onResizeStop={this.onPanelResizeStop}
            ref={this.rightPanel}
            computeMaxWidth={this.getMaxWidth}
            onExpandClick={this.onExpandedClick}
            onCollapseClick={this.onCollapseClick}
        />;
    }

    public getEditor = () => {
        if (this.isBookReport()) {
            const newProps: any = {
                ...this.props,
                resourceListenerManagers: this.props.resourceListenerManager,
                commandRegistry: this.props.commandRegistry,
            }
            return <BookEditor {...newProps} />
        } else {
            return <ReportEditor ref={this.reportEditorRef} {...this.props} />
        }
    }

    public render() {
        return <div style={{ display: "flex", flex: 1 }}>
            <DndProvider backend={HTML5Backend}>
                {this.getLeftColumn()}
                <div style={{ display: 'flex', flex: 1, minWidth: 0 }}>
                    <main style={{ display: 'flex', flex: 1 }}>
                        {this.getEditor()}
                    </main>
                </div>
                {this.getRightColumn()}
            </DndProvider>
        </div>
    }
}

const mapStateToProps = (state: IState) => {
    return {
        report: state.getIn(['report']),
        widgetStatus: state.getIn(['report', 'widgetStatus'], Map<string, any>()),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setObjectProperties: (path: string[], value: any) => { dispatch(ReportActions.setObjectProperties(path, value)); },
        deleteVisualElements: (paths: string[][]) => { dispatch(ReportActions.deleteVisualElements(paths)); },
        updateElementsSizeAndPosition: (changes) => { dispatch(ReportActions.updateElementsSizeAndPosition(changes)); },
        setWidgetStatus: (path, data) => { dispatch(ReportActions.setWidgetStatus(path, data)); },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiPageEditor);
