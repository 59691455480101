/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { Header as StandardHeader, IToolbarProvider } from '@jss/js-common';
import { MessageInfo } from '@jss/js-rest-api';
import { AppHeader } from './AppHeader';

interface IHeaderProps {
    toolbarData?: IToolbarProvider,
    message?: MessageInfo,
    resourceName?: string,
    warningMessage?: string,
    documentType?: string,
    tabs: {
        icon: React.ReactElement,
        label: string,
    }[],
    currentTabIndex: number,
    onTabChange?: (newTabIndex: number) => void,
    goToHome?: () => void,
    requestLogout: (callback) => void,
    closeMessage?: () => void,
}

export class Header extends React.Component<IHeaderProps> {

    protected goToHome = () => {
        if (this.props.goToHome) {
            this.props.goToHome();
        }
    }

    public render() {
        return <div style={{ display: 'flex', flexDirection: 'column' }}>
            <AppHeader goToHome={this.goToHome} requestLogout={this.props.requestLogout}/>
            <StandardHeader tabs={this.props.tabs} message={this.props.message} currentTabIndex={this.props.currentTabIndex} documentType={this.props.documentType} warningMessage={this.props.warningMessage}
                            toolbarData={this.props.toolbarData} resourceName={this.props.resourceName} onTabChange={this.props.onTabChange} closeMessage={this.props.closeMessage} />
        </div>
    }
}
