/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map } from 'immutable';
import { ChartTypes, CHART_TYPE, SPIDERCHART_NAMESPACE } from '../reader/JrxmlChartUtils';
import { addHyperlinkAttributes, createBox, createDatasetRun, createFontElement, createReportElement, setAttributeIfPresent, setExpressionNode, setExpressionNodeFromMap } from './JrxmlHelper';

export const createChartElement = (elementNode: Map<string, any>, xmlDocument: Document) : Element | null => {
    const charType = elementNode.get(CHART_TYPE, null);
    if (charType !== null){
        const chartElement = xmlDocument.createElement(charType);
        if (charType === ChartTypes.PIE_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createPieDataset(elementNode.get("pieDataset"), xmlDocument));
            chartElement.appendChild(createPiePlot(elementNode.get("piePlot"), xmlDocument));
        } else if (charType === ChartTypes.PIE3D_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createPieDataset(elementNode.get("pieDataset"), xmlDocument));
            chartElement.appendChild(createPie3DPlot(elementNode.get("pie3DPlot"), xmlDocument));
        } else if (charType === ChartTypes.BAR_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createCateogryDataset(elementNode.get("categoryDataset"), xmlDocument));
            chartElement.appendChild(createBarPlot(elementNode.get("barPlot"), xmlDocument));
        } else if (charType === ChartTypes.BAR3D_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createCateogryDataset(elementNode.get("categoryDataset"), xmlDocument));
            chartElement.appendChild(createBar3DPlot(elementNode.get("bar3DPlot"), xmlDocument));
        } else if (charType === ChartTypes.XY_BAR_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));

            const timePeriodDataset = elementNode.get("timePeriodDataset", null);
            if (timePeriodDataset !== null){
                chartElement.appendChild(createTimePeriodDataset(timePeriodDataset, xmlDocument));
            } else {
                const timeSeriesDataset = elementNode.get("timeSeriesDataset", null);
                if (timeSeriesDataset !== null){
                    chartElement.appendChild(createTimeSeriesDataset(timeSeriesDataset, xmlDocument));
                } else {
                    const xyDataset = elementNode.get("xyDataset", null);
                    if (xyDataset !== null){
                        chartElement.appendChild(createXYDataset(xyDataset, xmlDocument));
                    }
                }
            }

            chartElement.appendChild(createBarPlot(elementNode.get("barPlot"), xmlDocument));
        } else if (charType === ChartTypes.STACKED_BAR_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createCateogryDataset(elementNode.get("categoryDataset"), xmlDocument));
            chartElement.appendChild(createBarPlot(elementNode.get("barPlot"), xmlDocument));
        } else if (charType === ChartTypes.STACKED_BAR3D_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createCateogryDataset(elementNode.get("categoryDataset"), xmlDocument));
            chartElement.appendChild(createBar3DPlot(elementNode.get("bar3DPlot"), xmlDocument));
        } else if (charType === ChartTypes.LINE_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createCateogryDataset(elementNode.get("categoryDataset"), xmlDocument));
            chartElement.appendChild(createLinePlot(elementNode.get("linePlot"), xmlDocument));
        } else if (charType === ChartTypes.XY_LINE_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createXYDataset(elementNode.get("xyDataset"), xmlDocument));
            chartElement.appendChild(createLinePlot(elementNode.get("linePlot"), xmlDocument));
        } else if (charType === ChartTypes.AREA_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createCateogryDataset(elementNode.get("categoryDataset"), xmlDocument));
            chartElement.appendChild(createAreaPlot(elementNode.get("areaPlot"), xmlDocument));
        } else if (charType === ChartTypes.XY_AREA_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createXYDataset(elementNode.get("xyDataset"), xmlDocument));
            chartElement.appendChild(createAreaPlot(elementNode.get("areaPlot"), xmlDocument));
        } else if (charType === ChartTypes.SCATTER_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createXYDataset(elementNode.get("xyDataset"), xmlDocument));
            chartElement.appendChild(createScatterPlot(elementNode.get("scatterPlot"), xmlDocument));
        } else if (charType === ChartTypes.BUBBLE_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createXYZDataset(elementNode.get("xyzDataset"), xmlDocument));
            chartElement.appendChild(createBubblePlot(elementNode.get("bubblePlot"), xmlDocument));
        } else if (charType === ChartTypes.TIMESERIES_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createTimeSeriesDataset(elementNode.get("timeSeriesDataset"), xmlDocument));
            chartElement.appendChild(createTimeSeriesPlot(elementNode.get("timeSeriesPlot"), xmlDocument));
        } else if (charType === ChartTypes.HIGHLOW_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createHighLowDataset(elementNode.get("highLowDataset"), xmlDocument));
            chartElement.appendChild(createHighLowPlot(elementNode.get("highLowPlot"), xmlDocument));
        } else if (charType === ChartTypes.CANDLESTICK_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createHighLowDataset(elementNode.get("highLowDataset"), xmlDocument));
            chartElement.appendChild(createCandlestickPlot(elementNode.get("candlestickPlot"), xmlDocument));
        } else if (charType === ChartTypes.METER_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createValueDataset(elementNode.get("valueDataset"), xmlDocument));
            chartElement.appendChild(createMeterPlot(elementNode.get("meterPlot"), xmlDocument));
        } else if (charType === ChartTypes.THERMOMETER_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createValueDataset(elementNode.get("valueDataset"), xmlDocument));
            chartElement.appendChild(createThermometerPlot(elementNode.get("thermometerPlot"), xmlDocument));
        } else if (charType === ChartTypes.STACKED_AREA_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createCateogryDataset(elementNode.get("categoryDataset"), xmlDocument));
            chartElement.appendChild(createAreaPlot(elementNode.get("areaPlot"), xmlDocument));
        } else if (charType === ChartTypes.GANTT_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createGanttDataset(elementNode.get("ganttDataset"), xmlDocument));
            chartElement.appendChild(createBarPlot(elementNode.get("barPlot"), xmlDocument));
        } else if (charType === ChartTypes.MULTIAXIS_CHART_NAME){
            chartElement.appendChild(createBaseChart(elementNode, xmlDocument));
            chartElement.appendChild(createMultiaxisPlot(elementNode.get("multiAxisPlot"), xmlDocument));
        }  else if (charType === ChartTypes.SPIDER_CHART_NAME){
            const componentElement = xmlDocument.createElement("componentElement");
            const reportElement = createReportElement(elementNode, xmlDocument);
            componentElement.appendChild(reportElement);
            componentElement.appendChild(createSpiderChart(elementNode, xmlDocument));
            return componentElement;
        } else {
            // unknown type
            return null;
        }
        return chartElement;
    }
    return null;
}

const createSpiderChart =  (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const baseNamespace = elementNode.get(SPIDERCHART_NAMESPACE, "sc");
    const namespace = baseNamespace + ":";
    const spiderChartElement = xmlDocument.createElement(namespace + "spiderChart");

    spiderChartElement.appendChild(createSpiderChartSettings(elementNode.get("spiderSettings", null), xmlDocument, namespace));
    spiderChartElement.appendChild(createSpiderDataset(elementNode.get("spiderDataset", null), xmlDocument, namespace));
    spiderChartElement.appendChild(createSpiderPlot(elementNode.get("spiderPlot", null), xmlDocument, namespace));

    spiderChartElement.setAttribute("xmlns:" + baseNamespace, elementNode.get("xmlns:" + baseNamespace, "http://jasperreports.sourceforge.net/jasperreports/components"));
    spiderChartElement.setAttribute("xsi:schemaLocation", elementNode.get("xsi:schemaLocation", "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd"));
    return spiderChartElement;
}

const createSpiderChartSettings  = (elementNode: Map<string, any> | null, xmlDocument: Document, namespace : string) : Element => {
    const chartSettingsElement = xmlDocument.createElement(namespace + "chartSettings");
    if (elementNode !== null){
        const chartTitleNode: Map<string, any> = elementNode.get("chartTitle", null);
        chartSettingsElement.appendChild(createChartTitle(chartTitleNode, xmlDocument));
    
        const chartSubTitleNode: Map<string, any> = elementNode.get("chartSubtitle", null);
        chartSettingsElement.appendChild(createChartSubTitle(chartSubTitleNode, xmlDocument));
    
        const chartLegendNode: Map<string, any> = elementNode.get("chartLegend", null);
        chartSettingsElement.appendChild(createChartLegend(chartLegendNode, xmlDocument));
    
        if (elementNode !== null){
            addHyperlinkAttributes(elementNode, chartSettingsElement, xmlDocument);
    
            setAttributeIfPresent(elementNode, "renderType", chartSettingsElement);
            setAttributeIfPresent(elementNode, "customizerClass", chartSettingsElement);
            setAttributeIfPresent(elementNode, "backcolor", chartSettingsElement);
            setAttributeIfPresent(elementNode, "isShowLegend", chartSettingsElement);
        }
    }

    return chartSettingsElement;
}


const createMultiaxisPlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = xmlDocument.createElement("multiAxisPlot");
    plotElement.appendChild(createPlot(elementNode.get("plot"), xmlDocument));

    const axis : List<Map<string, any>> = elementNode.get("axis", null);
    if (axis !== null){
        axis.forEach((propertyValue:Map<string, any>) => {
            const subChartNode = propertyValue.get("subchart", null);
            if (subChartNode !== null){
                const subChartElement = createChartElement(subChartNode, xmlDocument);
                if (subChartElement !== null){
                    const axisElement = xmlDocument.createElement("axis");
                    axisElement.appendChild(subChartElement);
                    setAttributeIfPresent(propertyValue, "position", axisElement);
                    plotElement.appendChild(axisElement);
                }
            }
        });
    }

    return plotElement;
}


const createThermometerPlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = xmlDocument.createElement("thermometerPlot");
    plotElement.appendChild(createPlot(elementNode.get("plot"), xmlDocument));

    const valueDisplayNode: Map<string, any> = elementNode.get("valueDisplay", null);
    plotElement.appendChild(createValueDisplay(valueDisplayNode, xmlDocument));

    const dataRangeElement = xmlDocument.createElement("dataRange");
    setExpressionNodeFromMap(dataRangeElement, xmlDocument, elementNode, "lowExpression");
    setExpressionNodeFromMap(dataRangeElement, xmlDocument, elementNode, "highExpression");
    plotElement.appendChild(dataRangeElement);

    const lowLowExpression = elementNode.get("low_lowExpression", null);
    const lowHighExpression = elementNode.get("low_highExpression", null);
    if (lowHighExpression !== null && lowLowExpression !== null){
        const lowRangeElement = xmlDocument.createElement("lowRange");
        const lowDataRangeElement = xmlDocument.createElement("dataRange");
        setExpressionNode(lowDataRangeElement, xmlDocument, lowLowExpression, "lowExpression");
        setExpressionNode(lowDataRangeElement, xmlDocument, lowHighExpression, "highExpression");
        lowRangeElement.appendChild(lowDataRangeElement);
        plotElement.appendChild(lowRangeElement);
    }

    const mediumLowExpression = elementNode.get("medium_lowExpression", null);
    const mediumHighExpression = elementNode.get("medium_highExpression", null);
    if (mediumLowExpression !== null && mediumHighExpression !== null){
        const mediumRangeElement = xmlDocument.createElement("mediumRange");
        const mediumDataRangeElement = xmlDocument.createElement("dataRange");
        setExpressionNode(mediumDataRangeElement, xmlDocument, mediumLowExpression, "lowExpression");
        setExpressionNode(mediumDataRangeElement, xmlDocument, mediumHighExpression, "highExpression");
        mediumRangeElement.appendChild(mediumDataRangeElement);
        plotElement.appendChild(mediumRangeElement);
    }

    const highLowExpression = elementNode.get("high_lowExpression", null);
    const highHighExpression = elementNode.get("high_highExpression", null);
    if (highLowExpression !== null && highHighExpression !== null){
        const highRangeElement = xmlDocument.createElement("highRange");
        const highDataRangeElement = xmlDocument.createElement("dataRange");
        setExpressionNode(highDataRangeElement, xmlDocument, highLowExpression, "lowExpression");
        setExpressionNode(highDataRangeElement, xmlDocument, highHighExpression, "highExpression");
        highRangeElement.appendChild(highDataRangeElement);
        plotElement.appendChild(highRangeElement);
    }

    setAttributeIfPresent(elementNode, "valueLocation", plotElement);
    setAttributeIfPresent(elementNode, "isShowValueLines", plotElement);
    setAttributeIfPresent(elementNode, "mercuryColor", plotElement);
    return plotElement;
}

const createSpiderPlot = (elementNode: Map<string, any>, xmlDocument: Document, namespace: string) : Element => {
    const plotElement = xmlDocument.createElement(namespace + "spiderPlot");

    if (elementNode !== null){
        const labelFontNode: Map<string, any> = elementNode.get("labelFont", null);
        if (labelFontNode !== null && !labelFontNode.isEmpty()){
            const fontElement = createFontElement(labelFontNode, xmlDocument);
            if (fontElement !== null){
                const labelFontElement = xmlDocument.createElement("labelFont");
                labelFontElement.appendChild(fontElement);
                plotElement.appendChild(labelFontElement);
            }
        }
    }

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, namespace + "maxValueExpression");

    setAttributeIfPresent(elementNode, "rotation", plotElement);
    setAttributeIfPresent(elementNode, "tableOrder", plotElement);
    setAttributeIfPresent(elementNode, "isWebFilled", plotElement);
    setAttributeIfPresent(elementNode, "startAngle", plotElement);
    setAttributeIfPresent(elementNode, "headPercent", plotElement);
    setAttributeIfPresent(elementNode, "interiorGap", plotElement);
    setAttributeIfPresent(elementNode, "axisLineColor", plotElement);
    setAttributeIfPresent(elementNode, "axisLineWidth", plotElement);
    setAttributeIfPresent(elementNode, "labelGap", plotElement);
    setAttributeIfPresent(elementNode, "labelColor", plotElement);
    setAttributeIfPresent(elementNode, "backcolor", plotElement);
    setAttributeIfPresent(elementNode, "backgroundAlpha", plotElement);
    setAttributeIfPresent(elementNode, "axisLineWidth", plotElement);
    setAttributeIfPresent(elementNode, "foregroundAlpha", plotElement);

    return plotElement;
}

const createMeterPlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = xmlDocument.createElement("meterPlot");
    plotElement.appendChild(createPlot(elementNode.get("plot"), xmlDocument));

    const labelFontNode: Map<string, any> = elementNode.get("tickLabelFont", null);
    if (labelFontNode !== null && !labelFontNode.isEmpty()){
        const fontElement = createFontElement(labelFontNode, xmlDocument);
        if (fontElement !== null){
            const labelFontElement = xmlDocument.createElement("tickLabelFont");
            labelFontElement.appendChild(fontElement);
            plotElement.appendChild(labelFontElement);
        }
    }

    const valueDisplayNode: Map<string, any> = elementNode.get("valueDisplay", null);
    plotElement.appendChild(createValueDisplay(valueDisplayNode, xmlDocument));

    const dataRangeElement = xmlDocument.createElement("dataRange");
    setExpressionNodeFromMap(dataRangeElement, xmlDocument, elementNode, "lowExpression");
    setExpressionNodeFromMap(dataRangeElement, xmlDocument, elementNode, "highExpression");
    plotElement.appendChild(dataRangeElement);

    const meterIntervals : List<Map<string, any>> = elementNode.get("meterIntervals", null);
    if (meterIntervals !== null){
        meterIntervals.forEach((propertyValue:Map<string, any>) => {
            const meterIntervalElementElement = xmlDocument.createElement("meterInterval");

            const intervalDataRangeElement = xmlDocument.createElement("dataRange");
            setExpressionNodeFromMap(intervalDataRangeElement, xmlDocument, propertyValue, "lowExpression");
            setExpressionNodeFromMap(intervalDataRangeElement, xmlDocument, propertyValue, "highExpression");
            meterIntervalElementElement.appendChild(intervalDataRangeElement);

            setAttributeIfPresent(propertyValue, "label", meterIntervalElementElement);
            setAttributeIfPresent(propertyValue, "color", meterIntervalElementElement);
            setAttributeIfPresent(propertyValue, "alpha", meterIntervalElementElement);
            plotElement.appendChild(meterIntervalElementElement);
        });
    }

    setAttributeIfPresent(elementNode, "shape", plotElement);
    setAttributeIfPresent(elementNode, "angle", plotElement);
    setAttributeIfPresent(elementNode, "units", plotElement);
    setAttributeIfPresent(elementNode, "tickInterval", plotElement);
    setAttributeIfPresent(elementNode, "meterColor", plotElement);
    setAttributeIfPresent(elementNode, "needleColor", plotElement);
    setAttributeIfPresent(elementNode, "tickColor", plotElement);
    setAttributeIfPresent(elementNode, "tickCount", plotElement);

    return plotElement;
}

const createValueDisplay = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const valueDisplayElement = xmlDocument.createElement("valueDisplay");
    if (elementNode !== null){
        const fontNode: Map<string, any> = elementNode.get("font", null);
        if (fontNode !== null && !fontNode.isEmpty()){
            const fontElement = createFontElement(fontNode, xmlDocument);
            if (fontElement !== null){
                valueDisplayElement.appendChild(fontElement);
            }
        }

        setAttributeIfPresent(elementNode, "color", valueDisplayElement);
        setAttributeIfPresent(elementNode, "mask", valueDisplayElement);
    }
    return valueDisplayElement;
}

const createCandlestickPlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = xmlDocument.createElement("candlestickPlot");
    plotElement.appendChild(createPlot(elementNode.get("plot"), xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "timeAxisLabelExpression");

    const timeAxisFormatNode: Map<string, any> = elementNode.get("timeAxisFormat", null);
    plotElement.appendChild(createTimeAxisFormat(timeAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "valueAxisLabelExpression");

    const valueAxisFormatNode: Map<string, any> = elementNode.get("valueAxisFormat", null);
    plotElement.appendChild(createValueAxisFormat(valueAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMaxValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMaxValueExpression");

    setAttributeIfPresent(elementNode, "isShowVolume", plotElement);

    return plotElement;
}

const createHighLowPlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = xmlDocument.createElement("highLowPlot");
    plotElement.appendChild(createPlot(elementNode.get("plot"), xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "timeAxisLabelExpression");

    const timeAxisFormatNode: Map<string, any> = elementNode.get("timeAxisFormat", null);
    plotElement.appendChild(createTimeAxisFormat(timeAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "valueAxisLabelExpression");

    const valueAxisFormatNode: Map<string, any> = elementNode.get("valueAxisFormat", null);
    plotElement.appendChild(createValueAxisFormat(valueAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMaxValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMaxValueExpression");

    setAttributeIfPresent(elementNode, "isShowCloseTicks", plotElement);
    setAttributeIfPresent(elementNode, "isShowOpenTicks", plotElement);

    return plotElement;
}

const createTimeSeriesPlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = xmlDocument.createElement("timeSeriesPlot");
    plotElement.appendChild(createPlot(elementNode.get("plot"), xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "timeAxisLabelExpression");

    const timeAxisFormatNode: Map<string, any> = elementNode.get("timeAxisFormat", null);
    plotElement.appendChild(createTimeAxisFormat(timeAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "valueAxisLabelExpression");

    const valueAxisFormatNode: Map<string, any> = elementNode.get("valueAxisFormat", null);
    plotElement.appendChild(createValueAxisFormat(valueAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMaxValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMaxValueExpression");

    setAttributeIfPresent(elementNode, "isShowLines", plotElement);
    setAttributeIfPresent(elementNode, "isShowShapes", plotElement);

    return plotElement;
}

const createBubblePlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = xmlDocument.createElement("bubblePlot");
    plotElement.appendChild(createPlot(elementNode.get("plot"), xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "xAxisLabelExpression");

    const xAxisFormatNode: Map<string, any> = elementNode.get("xAxisFormat", null);
    plotElement.appendChild(createXAxisFormat(xAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "yAxisLabelExpression");

    const yAxisFormatNode: Map<string, any> = elementNode.get("yAxisFormat", null);
    plotElement.appendChild(createYAxisFormat(yAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMaxValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMaxValueExpression");

    setAttributeIfPresent(elementNode, "scaleType", plotElement);

    return plotElement;
}

const createScatterPlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = xmlDocument.createElement("scatterPlot");
    plotElement.appendChild(createPlot(elementNode.get("plot"), xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "xAxisLabelExpression");

    const xAxisFormatNode: Map<string, any> = elementNode.get("xAxisFormat", null);
    plotElement.appendChild(createXAxisFormat(xAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "yAxisLabelExpression");

    const yAxisFormatNode: Map<string, any> = elementNode.get("yAxisFormat", null);
    plotElement.appendChild(createYAxisFormat(yAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMaxValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMaxValueExpression");

    setAttributeIfPresent(elementNode, "isShowLines", plotElement);
    setAttributeIfPresent(elementNode, "isShowShapes", plotElement);

    return plotElement;
}

const createTimeAxisFormat = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const timeAxisFormatElement = xmlDocument.createElement("timeAxisFormat");
    if (elementNode !== null){
        const axisFormatNode: Map<string, any> = elementNode.get("axisFormat", null);
        if (axisFormatNode !== null && !axisFormatNode.isEmpty()){
            timeAxisFormatElement.appendChild(createAxisFormat(axisFormatNode, xmlDocument));
        }
    } else {
        const axisFormatElement = xmlDocument.createElement("axisFormat");
        timeAxisFormatElement.appendChild(axisFormatElement);
    }
    return timeAxisFormatElement;
}

const createXAxisFormat = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const xAxisFormatElement = xmlDocument.createElement("xAxisFormat");
    if (elementNode !== null){
        const axisFormatNode: Map<string, any> = elementNode.get("axisFormat", null);
        if (axisFormatNode !== null && !axisFormatNode.isEmpty()){
            xAxisFormatElement.appendChild(createAxisFormat(axisFormatNode, xmlDocument));
        }
    } else {
        const axisFormatElement = xmlDocument.createElement("axisFormat");
        xAxisFormatElement.appendChild(axisFormatElement);
    }

    return xAxisFormatElement;
}

const createYAxisFormat = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const yAxisFormatElement = xmlDocument.createElement("yAxisFormat");
    if (elementNode !== null){
        const axisFormatNode: Map<string, any> = elementNode.get("axisFormat", null);
        if (axisFormatNode !== null && !axisFormatNode.isEmpty()){
            yAxisFormatElement.appendChild(createAxisFormat(axisFormatNode, xmlDocument));
        }
    } else {
        const axisFormatElement = xmlDocument.createElement("axisFormat");
        yAxisFormatElement.appendChild(axisFormatElement);
    }
    return yAxisFormatElement;
}

const createAreaPlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = xmlDocument.createElement("areaPlot");
    plotElement.appendChild(createPlot(elementNode.get("plot"), xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "categoryAxisLabelExpression");

    const categoryAxisFormatNode: Map<string, any> = elementNode.get("categoryAxisFormat", null);
    plotElement.appendChild(createCategoryAxisFormat(categoryAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "valueAxisLabelExpression");

    const valueAxisFormatNode: Map<string, any> = elementNode.get("valueAxisFormat", null);
    plotElement.appendChild(createValueAxisFormat(valueAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMaxValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMaxValueExpression");

    return plotElement;
}

const createLinePlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = xmlDocument.createElement("linePlot");
    plotElement.appendChild(createPlot(elementNode.get("plot"), xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "categoryAxisLabelExpression");

    const categoryAxisFormatNode: Map<string, any> = elementNode.get("categoryAxisFormat", null);
    plotElement.appendChild(createCategoryAxisFormat(categoryAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "valueAxisLabelExpression");

    const valueAxisFormatNode: Map<string, any> = elementNode.get("valueAxisFormat", null);
    plotElement.appendChild(createValueAxisFormat(valueAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMaxValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMaxValueExpression");

    setAttributeIfPresent(elementNode, "isShowLines", plotElement);
    setAttributeIfPresent(elementNode, "isShowShapes", plotElement);

    return plotElement;
}

const createBar3DPlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = createBaseBarPlot(elementNode, xmlDocument, "bar3DPlot");
    setAttributeIfPresent(elementNode, "xOffset", plotElement);
    setAttributeIfPresent(elementNode, "yOffset", plotElement);

    return plotElement;
}

const createBarPlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = createBaseBarPlot(elementNode, xmlDocument, "barPlot");
    setAttributeIfPresent(elementNode, "isShowTickLabels", plotElement);
    setAttributeIfPresent(elementNode, "isShowTickMarks", plotElement);

    return plotElement;
}

const createBaseBarPlot = (elementNode: Map<string, any>, xmlDocument: Document, tagName: string) : Element => {
    const plotElement = xmlDocument.createElement(tagName);
    plotElement.appendChild(createPlot(elementNode.get("plot"), xmlDocument));
    plotElement.appendChild(createItemLabel(elementNode.get("itemLabel"), xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "categoryAxisLabelExpression");

    const categoryAxisFormatNode: Map<string, any> = elementNode.get("categoryAxisFormat", null);
    plotElement.appendChild(createCategoryAxisFormat(categoryAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "valueAxisLabelExpression");

    const valueAxisFormatNode: Map<string, any> = elementNode.get("valueAxisFormat", null);
    plotElement.appendChild(createValueAxisFormat(valueAxisFormatNode, xmlDocument));

    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "domainAxisMaxValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMinValueExpression");
    setExpressionNodeFromMap(plotElement, xmlDocument, elementNode, "rangeAxisMaxValueExpression");

    setAttributeIfPresent(elementNode, "isShowLabels", plotElement);

    return plotElement;
}

const createValueAxisFormat = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const valueAxisFormatElement = xmlDocument.createElement("valueAxisFormat");
    if (elementNode !== null){
        const axisFormatNode: Map<string, any> = elementNode.get("axisFormat", null);
        if (axisFormatNode !== null && !axisFormatNode.isEmpty()){
            valueAxisFormatElement.appendChild(createAxisFormat(axisFormatNode, xmlDocument));
        }
    } else {
        const axisFormatElement = xmlDocument.createElement("axisFormat");
        valueAxisFormatElement.appendChild(axisFormatElement);
    }
    return valueAxisFormatElement;
}

const createCategoryAxisFormat = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const categoryAxisFormatElement = xmlDocument.createElement("categoryAxisFormat");
    if (elementNode !== null){
        const axisFormatNode: Map<string, any> = elementNode.get("axisFormat", null);
        if (axisFormatNode !== null && !axisFormatNode.isEmpty()){
            categoryAxisFormatElement.appendChild(createAxisFormat(axisFormatNode, xmlDocument));
        }
        setAttributeIfPresent(elementNode, "labelRotation", categoryAxisFormatElement);
    } else {
        const axisFormatElement = xmlDocument.createElement("axisFormat");
        categoryAxisFormatElement.appendChild(axisFormatElement);
    }
    return categoryAxisFormatElement;
}

const createAxisFormat = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const axisFormatElement = xmlDocument.createElement("axisFormat");

    const labelFontNode: Map<string, any> = elementNode.get("labelFont", null);
    if (labelFontNode !== null && !labelFontNode.isEmpty()){
        const fontElement = createFontElement(labelFontNode, xmlDocument);
        if (fontElement !== null){
            const labelFontElement = xmlDocument.createElement("labelFont");
            labelFontElement.appendChild(fontElement);
            axisFormatElement.appendChild(labelFontElement);
        }
    }

    const tickLabelFontNode: Map<string, any> = elementNode.get("tickLabelFont", null);
    if (tickLabelFontNode !== null && !tickLabelFontNode.isEmpty()){
        const fontElement = createFontElement(tickLabelFontNode, xmlDocument);
        if (fontElement !== null){
            const labelFontElement = xmlDocument.createElement("tickLabelFont");
            labelFontElement.appendChild(fontElement);
            axisFormatElement.appendChild(labelFontElement);
        }
    }

    setAttributeIfPresent(elementNode, "labelColor", axisFormatElement);
    setAttributeIfPresent(elementNode, "tickLabelColor", axisFormatElement);
    setAttributeIfPresent(elementNode, "tickLabelMask", axisFormatElement);
    setAttributeIfPresent(elementNode, "axisLineColor", axisFormatElement);
    setAttributeIfPresent(elementNode, "verticalTickLabels", axisFormatElement);

    return axisFormatElement;
}

const createPie3DPlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = xmlDocument.createElement("pie3DPlot");
    plotElement.appendChild(createPlot(elementNode.get("plot"), xmlDocument));
    plotElement.appendChild(createItemLabel(elementNode.get("itemLabel"), xmlDocument));

    setAttributeIfPresent(elementNode, "isShowLabels", plotElement);
    setAttributeIfPresent(elementNode, "depthFactor", plotElement);
    setAttributeIfPresent(elementNode, "isCircular", plotElement);
    setAttributeIfPresent(elementNode, "labelFormat", plotElement);
    setAttributeIfPresent(elementNode, "legendLabelFormat", plotElement);

    return plotElement;
}

const createPiePlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = xmlDocument.createElement("piePlot");
    plotElement.appendChild(createPlot(elementNode.get("plot"), xmlDocument));
    plotElement.appendChild(createItemLabel(elementNode.get("itemLabel"), xmlDocument));

    setAttributeIfPresent(elementNode, "isShowLabels", plotElement);
    setAttributeIfPresent(elementNode, "isCircular", plotElement);
    setAttributeIfPresent(elementNode, "labelFormat", plotElement);
    setAttributeIfPresent(elementNode, "legendLabelFormat", plotElement);

    return plotElement;
}

const createPlot = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const plotElement = xmlDocument.createElement("plot");

    if (elementNode && elementNode !== null){
        const seriesColor : List<Map<string, string | number>> = elementNode.get("seriesColor", null);
        if (seriesColor !== null){
            seriesColor.forEach((propertyValue: Map<string, string | number>) => {
                const seriesColorElement = xmlDocument.createElement("seriesColor");
                seriesColorElement.setAttribute("seriesOrder", String(propertyValue.get('seriesOrder')));
                seriesColorElement.setAttribute("color", String(propertyValue.get('color')));
                plotElement.appendChild(seriesColorElement);
            });
        }

        setAttributeIfPresent(elementNode, "backcolor", plotElement);
        setAttributeIfPresent(elementNode, "orientation", plotElement);
        setAttributeIfPresent(elementNode, "labelRotation", plotElement);
        setAttributeIfPresent(elementNode, "backgroundAlpha", plotElement);
        setAttributeIfPresent(elementNode, "foregroundAlpha", plotElement);
    }

    return plotElement;
}

const createItemLabel = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const itemLabelElement = xmlDocument.createElement("itemLabel");

    if (elementNode !== null){
        setAttributeIfPresent(elementNode, "color", itemLabelElement);
        setAttributeIfPresent(elementNode, "backgroundColor", itemLabelElement);
        const fontNode = elementNode.get("font", null);
        if (fontNode !== null && !fontNode.isEmpty()){
            const fontElement = createFontElement(fontNode, xmlDocument);
            if (fontElement !== null){
                itemLabelElement.appendChild(fontElement);
            }
        }
    }

    return itemLabelElement;
}

const createValueDataset  = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetElement = xmlDocument.createElement("valueDataset");

    const datasetNode: Map<string, any> = elementNode.get("dataset", null);
    if (datasetNode !== null && !datasetNode.isEmpty()){
        datasetElement.appendChild(createChartDataset(datasetNode, xmlDocument));
    }

    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "valueExpression");

    return datasetElement;
}


const createHighLowDataset  = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetElement = xmlDocument.createElement("highLowDataset");

    const datasetNode: Map<string, any> = elementNode.get("dataset", null);
    if (datasetNode !== null && !datasetNode.isEmpty()){
        datasetElement.appendChild(createChartDataset(datasetNode, xmlDocument));
    }

    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "seriesExpression");
    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "dateExpression");
    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "highExpression");
    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "lowExpression");
    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "openExpression");
    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "closeExpression");
    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "volumeExpression");

    const hyperlink = createSectionHyperlinkElement(elementNode, xmlDocument, "itemHyperlink");
    if (hyperlink !== null){
        datasetElement.appendChild(hyperlink);
    }

    return datasetElement;
}

const createTimePeriodDataset  = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetElement = xmlDocument.createElement("timePeriodDataset");

    const datasetNode: Map<string, any> = elementNode.get("dataset", null);
    if (datasetNode !== null && !datasetNode.isEmpty()){
        datasetElement.appendChild(createChartDataset(datasetNode, xmlDocument));
    }

    const timePeriodSeries : List<Map<string, any>> | null = elementNode.get("timePeriodSeries", null);
    if (timePeriodSeries !== null){
        timePeriodSeries.forEach((propertyValue: Map<string, any>) => {
            const timePeriodSeriesElement = xmlDocument.createElement("timePeriodSeries");
            setExpressionNodeFromMap(timePeriodSeriesElement, xmlDocument, propertyValue, "seriesExpression");
            setExpressionNodeFromMap(timePeriodSeriesElement, xmlDocument, propertyValue, "startDateExpression");
            setExpressionNodeFromMap(timePeriodSeriesElement, xmlDocument, propertyValue, "endDateExpression");
            setExpressionNodeFromMap(timePeriodSeriesElement, xmlDocument, propertyValue, "valueExpression");
            setExpressionNodeFromMap(timePeriodSeriesElement, xmlDocument, propertyValue, "labelExpression");

            const seriesHyperlink = createSectionHyperlinkElement(propertyValue, xmlDocument, "itemHyperlink");
            if (seriesHyperlink !== null){
                timePeriodSeriesElement.appendChild(seriesHyperlink);
            }

            datasetElement.appendChild(timePeriodSeriesElement);
        });
    }

    return datasetElement;
}

const createTimeSeriesDataset  = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetElement = xmlDocument.createElement("timeSeriesDataset");

    const datasetNode: Map<string, any> = elementNode.get("dataset", null);
    if (datasetNode !== null && !datasetNode.isEmpty()){
        datasetElement.appendChild(createChartDataset(datasetNode, xmlDocument));
    }

    const timeSeries : List<Map<string, any>> | null = elementNode.get("timeSeries", null);
    if (timeSeries !== null){
        timeSeries.forEach((propertyValue: Map<string, any>) => {
            const timeSeriesElement = xmlDocument.createElement("timeSeries");
            setExpressionNodeFromMap(timeSeriesElement, xmlDocument, propertyValue, "seriesExpression");
            setExpressionNodeFromMap(timeSeriesElement, xmlDocument, propertyValue, "timePeriodExpression");
            setExpressionNodeFromMap(timeSeriesElement, xmlDocument, propertyValue, "valueExpression");
            setExpressionNodeFromMap(timeSeriesElement, xmlDocument, propertyValue, "labelExpression");

            const seriesHyperlink = createSectionHyperlinkElement(propertyValue, xmlDocument, "itemHyperlink");
            if (seriesHyperlink !== null){
                timeSeriesElement.appendChild(seriesHyperlink);
            }

            datasetElement.appendChild(timeSeriesElement);
        });
    }

    setAttributeIfPresent(elementNode, "timePeriod", datasetElement);

    return datasetElement;
}

const createXYZDataset  = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetElement = xmlDocument.createElement("xyzDataset");

    const datasetNode: Map<string, any> = elementNode.get("dataset", null);
    if (datasetNode !== null && !datasetNode.isEmpty()){
        datasetElement.appendChild(createChartDataset(datasetNode, xmlDocument));
    }

    const xyzSeries : List<Map<string, any>> | null = elementNode.get("xyzSeries", null);
    if (xyzSeries !== null){
        xyzSeries.forEach((propertyValue: Map<string, any>) => {
            const xySeriesElement = xmlDocument.createElement("xyzSeries");
            setExpressionNodeFromMap(xySeriesElement, xmlDocument, propertyValue, "seriesExpression");
            setExpressionNodeFromMap(xySeriesElement, xmlDocument, propertyValue, "xValueExpression");
            setExpressionNodeFromMap(xySeriesElement, xmlDocument, propertyValue, "yValueExpression");
            setExpressionNodeFromMap(xySeriesElement, xmlDocument, propertyValue, "zValueExpression");
            const seriesHyperlink = createSectionHyperlinkElement(propertyValue, xmlDocument, "itemHyperlink");
            if (seriesHyperlink !== null){
                xySeriesElement.appendChild(seriesHyperlink);
            }

            datasetElement.appendChild(xySeriesElement);
        });
    }

    return datasetElement;
}

const createXYDataset  = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetElement = xmlDocument.createElement("xyDataset");

    const datasetNode: Map<string, any> = elementNode.get("dataset", null);
    if (datasetNode !== null && !datasetNode.isEmpty()){
        datasetElement.appendChild(createChartDataset(datasetNode, xmlDocument));
    }

    const xySeries : List<Map<string, any>> | null = elementNode.get("xySeries", null);
    if (xySeries !== null){
        xySeries.forEach((propertyValue: Map<string, any>) => {
            const xySeriesElement = xmlDocument.createElement("xySeries");
            setExpressionNodeFromMap(xySeriesElement, xmlDocument, propertyValue, "seriesExpression");
            setExpressionNodeFromMap(xySeriesElement, xmlDocument, propertyValue, "xValueExpression");
            setExpressionNodeFromMap(xySeriesElement, xmlDocument, propertyValue, "yValueExpression");
            setExpressionNodeFromMap(xySeriesElement, xmlDocument, propertyValue, "labelExpression");
            setAttributeIfPresent(propertyValue, "autoSort", xySeriesElement);

            const seriesHyperlink = createSectionHyperlinkElement(propertyValue, xmlDocument, "itemHyperlink");
            if (seriesHyperlink !== null){
                xySeriesElement.appendChild(seriesHyperlink);
            }

            datasetElement.appendChild(xySeriesElement);
        });
    }

    return datasetElement;
}

const createGanttDataset  = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetElement = xmlDocument.createElement("ganttDataset");

    const datasetNode: Map<string, any> = elementNode.get("dataset", null);
    if (datasetNode !== null && !datasetNode.isEmpty()){
        datasetElement.appendChild(createChartDataset(datasetNode, xmlDocument));
    }

    const ganttSeries : List<Map<string, any>> | null = elementNode.get("ganttSeries", null);
    if (ganttSeries !== null){
        ganttSeries.forEach((propertyValue: Map<string, any>) => {
            const ganttSeriesElement = xmlDocument.createElement("ganttSeries");
            setExpressionNodeFromMap(ganttSeriesElement, xmlDocument, propertyValue, "seriesExpression");
            setExpressionNodeFromMap(ganttSeriesElement, xmlDocument, propertyValue, "taskExpression");
            setExpressionNodeFromMap(ganttSeriesElement, xmlDocument, propertyValue, "subtaskExpression");
            setExpressionNodeFromMap(ganttSeriesElement, xmlDocument, propertyValue, "startDateExpression");
            setExpressionNodeFromMap(ganttSeriesElement, xmlDocument, propertyValue, "endDateExpression");
            setExpressionNodeFromMap(ganttSeriesElement, xmlDocument, propertyValue, "percentExpression");

            datasetElement.appendChild(ganttSeriesElement);
        });
    }

    return datasetElement;
}

const createSpiderDataset  = (elementNode: Map<string, any>, xmlDocument: Document, namespace: string) : Element => {
    const datasetElement = xmlDocument.createElement(namespace + "spiderDataset");

    if (elementNode !== null){
        const datasetNode: Map<string, any> = elementNode.get("dataset", null);
        if (datasetNode !== null && !datasetNode.isEmpty()){
            datasetElement.appendChild(createChartDataset(datasetNode, xmlDocument));
        }

        const categorySeries : List<Map<string, any>> | null = elementNode.get("categorySeries", null);
        if (categorySeries !== null){
            categorySeries.forEach((propertyValue: Map<string, any>) => {
                const categorySeriesElement = xmlDocument.createElement("categorySeries");
                setExpressionNodeFromMap(categorySeriesElement, xmlDocument, propertyValue, "seriesExpression");
                setExpressionNodeFromMap(categorySeriesElement, xmlDocument, propertyValue, "categoryExpression");
                setExpressionNodeFromMap(categorySeriesElement, xmlDocument, propertyValue, "valueExpression");
                setExpressionNodeFromMap(categorySeriesElement, xmlDocument, propertyValue, "labelExpression");

                const seriesHyperlink = createSectionHyperlinkElement(propertyValue, xmlDocument, "itemHyperlink");
                if (seriesHyperlink !== null){
                    categorySeriesElement.appendChild(seriesHyperlink);
                }

                datasetElement.appendChild(categorySeriesElement);
            });
        }
    }

    return datasetElement;
}

const createCateogryDataset  = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetElement = xmlDocument.createElement("categoryDataset");

    const datasetNode: Map<string, any> = elementNode.get("dataset", null);
    if (datasetNode !== null && !datasetNode.isEmpty()){
        datasetElement.appendChild(createChartDataset(datasetNode, xmlDocument));
    }

    const categorySeries : List<Map<string, any>> | null = elementNode.get("categorySeries", null);
    if (categorySeries !== null){
        categorySeries.forEach((propertyValue: Map<string, any>) => {
            const categorySeriesElement = xmlDocument.createElement("categorySeries");
            setExpressionNodeFromMap(categorySeriesElement, xmlDocument, propertyValue, "seriesExpression");
            setExpressionNodeFromMap(categorySeriesElement, xmlDocument, propertyValue, "categoryExpression");
            setExpressionNodeFromMap(categorySeriesElement, xmlDocument, propertyValue, "valueExpression");
            setExpressionNodeFromMap(categorySeriesElement, xmlDocument, propertyValue, "labelExpression");

            const seriesHyperlink = createSectionHyperlinkElement(propertyValue, xmlDocument, "itemHyperlink");
            if (seriesHyperlink !== null){
                categorySeriesElement.appendChild(seriesHyperlink);
            }

            datasetElement.appendChild(categorySeriesElement);
        });
    }

    return datasetElement;
}

const createPieDataset  = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetElement = xmlDocument.createElement("pieDataset");

    const datasetNode: Map<string, any> = elementNode.get("dataset", null);
    if (datasetNode !== null && !datasetNode.isEmpty()){
        datasetElement.appendChild(createChartDataset(datasetNode, xmlDocument));
    }

    const pieSeries : List<Map<string, any>> | null = elementNode.get("pieSeries", null);
    if (pieSeries !== null){
        if (pieSeries.size === 1) {
            //with only one element the pie series it is at the root of the dataset
            const propertyValue = pieSeries.get(0)
            setExpressionNodeFromMap(datasetElement, xmlDocument, propertyValue, "keyExpression");
            setExpressionNodeFromMap(datasetElement, xmlDocument, propertyValue, "valueExpression");
            setExpressionNodeFromMap(datasetElement, xmlDocument, propertyValue, "labelExpression");

            const seriesHyperlink = createSectionHyperlinkElement(propertyValue, xmlDocument, "sectionHyperlink");
            if (seriesHyperlink !== null){
                datasetElement.appendChild(seriesHyperlink);
            }

        } else {
            pieSeries.forEach((propertyValue: Map<string, any>) => {
                const pieSeriesElement = xmlDocument.createElement("pieSeries");
                setExpressionNodeFromMap(pieSeriesElement, xmlDocument, propertyValue, "keyExpression");
                setExpressionNodeFromMap(pieSeriesElement, xmlDocument, propertyValue, "valueExpression");
                setExpressionNodeFromMap(pieSeriesElement, xmlDocument, propertyValue, "labelExpression");

                const seriesHyperlink = createSectionHyperlinkElement(propertyValue, xmlDocument, "sectionHyperlink");
                if (seriesHyperlink !== null){
                    pieSeriesElement.appendChild(seriesHyperlink);
                }

                datasetElement.appendChild(pieSeriesElement);
            });
        }
    }

    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "keyExpression");
    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "valueExpression");
    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "labelExpression");

    const sectionHyperlinkNode = elementNode.get("sectionHyperlink", null);
    if (sectionHyperlinkNode !== null && !sectionHyperlinkNode.isEmpty()){
        const sectionHyperlinkElement = createSectionHyperlinkElement(sectionHyperlinkNode, xmlDocument, "sectionHyperlink");
        if (sectionHyperlinkElement !== null){
            datasetElement.appendChild(sectionHyperlinkElement);
        }
    }

    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "otherKeyExpression");
    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "otherLabelExpression");

    const otherSectionHyperlinkNode = elementNode.get("otherSectionHyperlink", null);
    if (otherSectionHyperlinkNode !== null && !otherSectionHyperlinkNode.isEmpty()){
        const otherSectionHyperlinkElement = createSectionHyperlinkElement(otherSectionHyperlinkNode, xmlDocument, "sectionHyperlink");
        if (otherSectionHyperlinkElement !== null){
            datasetElement.appendChild(otherSectionHyperlinkElement);
        }
    }

    setAttributeIfPresent(elementNode, "minPercentage", datasetElement);
    setAttributeIfPresent(elementNode, "maxCount", datasetElement);

    return datasetElement;
}

export const createChartDataset = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetElement = xmlDocument.createElement("dataset");

    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "incrementWhenExpression");

    const datasetRunNode: Map<string, any> = elementNode.get("datasetRun", null);
    if (datasetRunNode !== null && !datasetRunNode.isEmpty()){
        datasetElement.appendChild(createDatasetRun(datasetRunNode, xmlDocument));
    }

    setAttributeIfPresent(elementNode, "resetType", datasetElement);
    setAttributeIfPresent(elementNode, "resetGroup", datasetElement);
    setAttributeIfPresent(elementNode, "incrementType", datasetElement);
    setAttributeIfPresent(elementNode, "incrementGroup", datasetElement);

    return datasetElement;
}

export const createSectionHyperlinkElement = (elementNode: Map<string, any>, xmlDocument: Document, nodeName: string) : Element | null => {
    const sectionHyperlinkElement = xmlDocument.createElement(nodeName);
    let hyperlinkDataFound = false;
    // it is really important to do the or after the call or not before, otherwise compiler otimizations will force and call following the first true to be skipped
    hyperlinkDataFound = setAttributeIfPresent(elementNode, "hyperlinkType", sectionHyperlinkElement) || hyperlinkDataFound;
    hyperlinkDataFound = setAttributeIfPresent(elementNode, "hyperlinkTarget", sectionHyperlinkElement) || hyperlinkDataFound;
    hyperlinkDataFound = setExpressionNodeFromMap(sectionHyperlinkElement, xmlDocument, elementNode, "hyperlinkReferenceExpression") || hyperlinkDataFound;
    hyperlinkDataFound = setExpressionNodeFromMap(sectionHyperlinkElement, xmlDocument, elementNode, "hyperlinkWhenExpression") || hyperlinkDataFound;
    hyperlinkDataFound = setExpressionNodeFromMap(sectionHyperlinkElement, xmlDocument, elementNode, "hyperlinkAnchorExpression") || hyperlinkDataFound;
    hyperlinkDataFound = setExpressionNodeFromMap(sectionHyperlinkElement, xmlDocument, elementNode, "hyperlinkPageExpression") || hyperlinkDataFound;
    hyperlinkDataFound = setExpressionNodeFromMap(sectionHyperlinkElement, xmlDocument, elementNode, "hyperlinkTooltipExpression") || hyperlinkDataFound;

    const hyperlinkParameters : List<Map<string, string>> = elementNode.get("hyperlinkParameters", null);
    if (hyperlinkParameters !== null){
        hyperlinkParameters.forEach((propertyValue: Map<string, string>) => {
            const hyperlinkParameterElement = xmlDocument.createElement("hyperlinkParameter");
            hyperlinkParameterElement.setAttribute("name", String(propertyValue.get('name')));
            setExpressionNode(hyperlinkParameterElement, xmlDocument, propertyValue.get('value'), "hyperlinkParameterExpression");
            sectionHyperlinkElement.appendChild(hyperlinkParameterElement);
            hyperlinkDataFound = true;
        });
    }

    if (hyperlinkDataFound) {
        return sectionHyperlinkElement;
    } else {
        return null;
    }
}

const createBaseChart  = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const chartElement = xmlDocument.createElement("chart");
    const reportElement = createReportElement(elementNode, xmlDocument);
    chartElement.append(reportElement);

    const boxNode: Map<string, any> = elementNode.get("box", null);
    if (boxNode !== null && !boxNode.isEmpty()){
        chartElement.appendChild(createBox(boxNode, xmlDocument));
    }

    const chartTitleNode: Map<string, any> = elementNode.get("chartTitle", null);
    chartElement.appendChild(createChartTitle(chartTitleNode, xmlDocument));

    const chartSubTitleNode: Map<string, any> = elementNode.get("chartSubtitle", null);
    chartElement.appendChild(createChartSubTitle(chartSubTitleNode, xmlDocument));

    const chartLegendNode: Map<string, any> = elementNode.get("chartLegend", null);
    chartElement.appendChild(createChartLegend(chartLegendNode, xmlDocument));

    addHyperlinkAttributes(elementNode, chartElement, xmlDocument);

    setAttributeIfPresent(elementNode, "evaluationTime", chartElement);
    setAttributeIfPresent(elementNode, "evaluationGroup", chartElement);
    setAttributeIfPresent(elementNode, "renderType", chartElement);
    setAttributeIfPresent(elementNode, "theme", chartElement);
    setAttributeIfPresent(elementNode, "customizerClass", chartElement);
    setAttributeIfPresent(elementNode, "isShowLegend", chartElement);
    return chartElement;
}

const createChartTitle = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const chartTitleElement = xmlDocument.createElement("chartTitle");
    if (elementNode !== null){
        setAttributeIfPresent(elementNode, "position", chartTitleElement);
        setAttributeIfPresent(elementNode, "color", chartTitleElement);
        const fontNode = elementNode.get("font", null);
        if (fontNode !== null && !fontNode.isEmpty()){
            const fontElement = createFontElement(fontNode, xmlDocument);
            if (fontElement !== null){
                chartTitleElement.appendChild(fontElement);
            }
        }
        setExpressionNodeFromMap(chartTitleElement, xmlDocument, elementNode, "titleExpression");
    }
    return chartTitleElement;
}

const createChartSubTitle = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const chartSubTitleElement = xmlDocument.createElement("chartSubtitle");
    if (elementNode !== null){
        setAttributeIfPresent(elementNode, "color", chartSubTitleElement);
        const fontNode = elementNode.get("font", null);
        if (fontNode !== null && !fontNode.isEmpty()){
            const fontElement = createFontElement(fontNode, xmlDocument);
            if (fontElement !== null){
                chartSubTitleElement.appendChild(fontElement);
            }
        }
        setExpressionNodeFromMap(chartSubTitleElement, xmlDocument, elementNode, "subtitleExpression");
    }
    return chartSubTitleElement;
}

const createChartLegend = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const chartLegendElement = xmlDocument.createElement("chartLegend");
    if (elementNode !== null){
        setAttributeIfPresent(elementNode, "backgroundColor", chartLegendElement);
        setAttributeIfPresent(elementNode, "textColor", chartLegendElement);
        setAttributeIfPresent(elementNode, "position", chartLegendElement);
        const fontNode = elementNode.get("font", null);
        if (fontNode !== null && !fontNode.isEmpty()){
            const fontElement = createFontElement(fontNode, xmlDocument);
            if (fontElement !== null){
                chartLegendElement.appendChild(fontElement);
            }
        }
    }
    return chartLegendElement;
}