/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { PCategories } from "../../ui/composite/UPCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MAppearanceStyle } from "../common/MAppearance";
import { MLinePenStyle } from "../common/MLinePen";
import { MTextElement } from "../common/MTextElement";
import { PExpression } from "../../ui/primitive/UPExpression";

import { FImage } from "../elements/MImage";
import { PParentStyles } from "./UPParentStyles";

export const FConditionalStyle = {
    conditionExpression: new PExpression({ id: 'conditionExpression', label: 'Condition Expression' }),
    parentStyle: new PParentStyles({ id: 'style', label: 'Parent Style' }),
}

export const MConditionalStyle: APDescriptor = new PCategories({
    id: 'MConditionalStyle',
    layouts: [
        new PDiv({ id: 'style', label: 'Style', layouts: [FConditionalStyle.conditionExpression, FConditionalStyle.parentStyle] }),
        new PDiv({ id: 'appearance', label: 'Appearance', layouts: [...MAppearanceStyle.layouts] }),
        new PDiv({ id: 'text', label: 'Text', layouts: [...MTextElement.layouts] }),
        new PDiv({
            id: 'image', label: 'Image',
            layouts: [{ ...FImage.fill, default: undefined },
            new PAccordeon({
                label: 'Image Arrangement', expanded: true,
                layouts: [FImage.horizontalImageAlignment, FImage.verticalImageAlignment, { ...FImage.scaleImage, default: undefined }]
            })]
        }),
        new PDiv({ id: 'line', label: 'Line', layouts: [...MLinePenStyle.layouts] }),
    ]
});
