/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IRepositoryAPI } from "./IRepositoryAPI";
import { IRepositoryItemDescriptor } from "./IRepositoryItemDescriptor";
import { MIME_TYPES, Mimes, IResourceManager } from "./mimes";

export function fixMimeType(descriptor: IRepositoryItemDescriptor, repo: IRepositoryAPI) {
    if (descriptor.permission === 0)
        return;
    const vals: string[] = Object.values(MIME_TYPES);
    if (descriptor.mime === MIME_TYPES.XML && descriptor.path?.endsWith('JR-INF') && descriptor.name === 'context.xml') {
        descriptor.mime = MIME_TYPES.JRIO_CONTEXT_XML;
    }
    if (descriptor.mime && vals.includes(descriptor.mime)) {
        return;
    }
    if (descriptor.mime && ['json', 'css', 'txt', 'prop', 'properties', 'html', 'jrtx', 'jrxml', 'jrctx'].includes(descriptor.mime)) {
        descriptor.mime = repo.field().encode('mime', descriptor.mime);
    }
    if (descriptor.data && (['.xml', '.jrxml', '.jrtx', '.jrctx', '.jrdax'].some(item => descriptor.uuid?.endsWith(item) || descriptor.name?.endsWith(item)) || ['file', 'xml'].includes(descriptor.mime && descriptor.data))) {
        if (descriptor.path?.endsWith('JR-INF') && descriptor.name === 'context.xml') {
            descriptor.mime = MIME_TYPES.JRIO_CONTEXT_XML;
        }
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(descriptor.data, 'application/xml');
        if (xmlDoc.documentElement.getElementsByTagName('parsererror').length === 0) {
            const root = xmlDoc.getRootNode();
            let firstNodeName = '';
            for (let i = 0; i < root.childNodes.length && !firstNodeName; i++) {
                const child = root.childNodes.item(i);
                if (child.nodeType !== 8) {
                    firstNodeName = child.nodeName;
                }
            }

            if (firstNodeName && firstNodeName.endsWith('DataAdapter')) {
                switch (firstNodeName) {
                    case 'jdbcDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_JDBC; break;
                    case 'emptyDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_EMPTY; break;
                    case 'jndiDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_JNDI; break;
                    case 'jsonDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_JSON; break;
                    case 'randomDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_RANDOM; break;
                    case 'xlsDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_XLS; break;
                    case 'xmlDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_XML; break;
                    case 'csvDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_CSV; break;
                    case 'dsDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_DATA_SOURCE; break;

                    case 'xmlaDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_XMLA; break;
                    case 'springHibernateDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_SPRING_HIBERNATE; break;
                    case 'jrsDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_JRS; break;
                    case 'mondrianDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_MONDRIAN; break;
                    case 'MongoDbDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_MONGODB; break;
                    case 'ejbqlDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_EJBQL; break;
                    case 'beanDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_BEAN; break;
                    case 'hibernateDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_HIBERNATE; break;
                    case 'providerDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_PROVIDER; break;
                    case 'SpotfireDataAdapter': descriptor.mime = MIME_TYPES.DATA_ADAPTER_SPOTFIRE; break;
                }
            } else if (firstNodeName === 'jasperReport') {
                descriptor.mime = MIME_TYPES.JRXML;
            } else if (firstNodeName === 'jasperTemplate') {
                descriptor.mime = MIME_TYPES.JRTX;
            } else if (firstNodeName === 'chart-theme') {
                descriptor.mime = MIME_TYPES.JRCTX;
            } else if (firstNodeName === 'html') {
                descriptor.mime = 'application/html';
            } else {
                Mimes.mime4extension(descriptor);
            }
        } else {
            Mimes.mime4extension(descriptor);
        }
    } else {
        Mimes.mime4extension(descriptor);
    }
}
const DATASOURCES = [MIME_TYPES.DATASOURCE_BEAN.toString(),
MIME_TYPES.DATASOURCE_CUSTOM.toString(),
MIME_TYPES.DATASOURCE_DOMAIN.toString(),
MIME_TYPES.DATASOURCE_SEMANTIC_LAYER.toString(),
MIME_TYPES.DATASOURCE_JNDI.toString(),
MIME_TYPES.DATASOURCE_MONDRIAN_CONNECTION.toString(),
MIME_TYPES.DATASOURCE_MONDRIAN_XMLA_DEFINITION.toString(),
MIME_TYPES.DATASOURCE_MONDRIAN_SECURE_CONNECTION.toString(),
MIME_TYPES.DATASOURCE_VIRTUAL.toString(),
MIME_TYPES.DATASOURCE_XMLA_CONNECTION.toString(),
MIME_TYPES.DATASOURCE_AWS.toString(),
MIME_TYPES.DATASOURCE_AZURE_SQL.toString(),
MIME_TYPES.DATASOURCE_JDBC.toString(),
MIME_TYPES.DATASOURCE_ADHOC_DATA_VIEW.toString(),
];
export function isDataSource(mime: string): boolean {
    return DATASOURCES.includes(mime);
}
export interface IEditorViewProps {
    descriptor: IRepositoryItemDescriptor;
    path: IRepositoryItemDescriptor[];
    doExit: () => void;
    onFirstSave: (descriptor: IRepositoryItemDescriptor) => void
}

function register(
    mimes: MIME_TYPES[],
    showFn: (
        item: IRepositoryItemDescriptor,
        path: IRepositoryItemDescriptor[],
        callback: IResourceManager
    ) => JSX.Element
): void {
    mimes.forEach((mime) => {
        const m = Mimes.get(mime);
        if (m)
            Mimes.register({ ...m, show: showFn });
    });
}

export type IEditorViewComponentFn = (props: IEditorViewProps) => JSX.Element;

function registerMimes(mimes: MIME_TYPES[], getEditorComponentFn: IEditorViewComponentFn): void {
    register(mimes, (descriptor, path, callback) => {
        return getEditorComponentFn({
            descriptor,
            path,
            doExit: callback.onExit,
            onFirstSave: callback.onFirstSave
        });
    });
}

export function registerJrxmlEditorMimes(getJrxmlEditorComponentFn: IEditorViewComponentFn): void {
    registerMimes([MIME_TYPES.JRXML], getJrxmlEditorComponentFn);
}

export function registerJRIOContextEditorMimes(getJRIOContextEditorComponentFn: IEditorViewComponentFn): void {
    registerMimes([MIME_TYPES.JRIO_CONTEXT_XML], getJRIOContextEditorComponentFn);
}

export function registerImageEditorMimes(getImageEditorComponentFn: IEditorViewComponentFn): void {
    registerMimes(
        [
            MIME_TYPES.BMP,
            MIME_TYPES.PNG,
            MIME_TYPES.JPG,
            MIME_TYPES.SVG,
            MIME_TYPES.GIF,
            MIME_TYPES.WEBP,
        ],
        getImageEditorComponentFn);
}

export function registerTextEditorMimes(getTextEditorComponentFn: IEditorViewComponentFn): void {
    registerMimes(
        [
            MIME_TYPES.FILE,
            MIME_TYPES.JRTX,
            MIME_TYPES.TEXT,
            MIME_TYPES.CSS,
            MIME_TYPES.CSV,
            MIME_TYPES.JRCTX,
            MIME_TYPES.XHTML,
            MIME_TYPES.JAVASCRIPT,
            MIME_TYPES.JAVASCRIPT_APP,
            MIME_TYPES.HTML,
            MIME_TYPES.XML,
            MIME_TYPES.JSON,
            MIME_TYPES.PROPERTIES,
        ],
        getTextEditorComponentFn);
}

export function registerDataAdapterEditorMimes(getDataAdapterEditorComponentFn: IEditorViewComponentFn): void {
    registerMimes(
        [
            MIME_TYPES.DATA_ADAPTER_JDBC,
            MIME_TYPES.DATA_ADAPTER_CSV,
            MIME_TYPES.DATA_ADAPTER_XML,
            MIME_TYPES.DATA_ADAPTER_XLS,
            MIME_TYPES.DATA_ADAPTER_JNDI,
            MIME_TYPES.DATA_ADAPTER_JSON,
            MIME_TYPES.DATA_ADAPTER_EMPTY,
            MIME_TYPES.DATA_ADAPTER_RANDOM,
            MIME_TYPES.DATA_ADAPTER_BEAN,
            MIME_TYPES.DATA_ADAPTER_DATA_SOURCE,
            MIME_TYPES.DATA_ADAPTER_EJBQL,
            MIME_TYPES.DATA_ADAPTER_JRS,
            MIME_TYPES.DATA_ADAPTER_MONDRIAN,
            MIME_TYPES.DATA_ADAPTER_MONGODB,
            MIME_TYPES.DATA_ADAPTER_PROVIDER,
            MIME_TYPES.DATA_ADAPTER_QUERY_EXECUTER,
            MIME_TYPES.DATA_ADAPTER_SPOTFIRE,
            MIME_TYPES.DATA_ADAPTER_SPRING_HIBERNATE,
            MIME_TYPES.DATA_ADAPTER_HIBERNATE,
            MIME_TYPES.DATA_ADAPTER_XMLA,
            MIME_TYPES.DATASOURCE_JNDI,
            MIME_TYPES.DATASOURCE_JDBC,
            MIME_TYPES.DATASOURCE_XMLA_CONNECTION,
        ],
        getDataAdapterEditorComponentFn);
}
