/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { i18n } from '@jss/js-common';
import { IRepositoryItemDescriptor, RepositoryApi } from '@jss/js-rest-api';
import { CardHeader, Tab, Tabs } from '@material-ui/core';
import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as ReportActions from '../../../actions/reportActions';
import '../../../assets/uxpl/css/ReportProperties.css';
import { IState } from '../../../reducers';
import { DatasetProperties } from './DatasetProperties';
import { SelectedProperties } from './SelectedProperties';
import { getNormalizedPath } from './ui/UiProperty';

interface IProperties {
  rootModel?: ImmutableMap<string, any>;
  model?: ImmutableMap<string, any>;
  selection?: ImmutableMap<string, any>;
  subeditors?: ImmutableList<ImmutableMap<string, any>>;
  currentEditorIndex?: number | undefined;
  descriptor?: IRepositoryItemDescriptor | undefined,
  setObjectProperties?: (path: (string | number)[], value: any) => void;
  createEditor?: (editorType: string, editorLabel: string, isSelectable: boolean, editedResourceId: string, setActive: boolean, params: Record<string, unknown>) => void;
  setEditorProperties?: (index: number, editorLabel: string, isSelectable: boolean, editedResourceId: string, params: Record<string, unknown>) => void;
  closeEditor?: (editorIndex: number) => void;
  deleteElement?: (path: (string | number)[]) => void;
  deletePropertyRefreshAction?: (path: (string | number)[], reportPath: string) => void;
  setPropertiesRefreshAction?: (path: (string | number)[], value: any, reportPath: string) => void;
  loadCombosAction?: (path: string) => void;
  setWidgetStatus?: (path, data) => void;
}

class SelectedAndDatasetProperties extends React.Component<IProperties> {

  private switchToProperties = () => {
    if (this.props.currentEditorIndex !== undefined && this.props.currentEditorIndex >= 0) {
      const currentEditorType = this.props.subeditors.getIn([this.props.currentEditorIndex, 'type']);
      if (currentEditorType === 'datasetQueryEditor') {
        //if the current editor is the dataset and query editor close it
        this.props.closeEditor(this.props.currentEditorIndex);
      }
    }
    this.props.setWidgetStatus(['selectedAndDatasetProperties', 'tabIndex'], 0);
  }

  private handleChange = (event, value) => {
    if (value === 0) {
      this.switchToProperties();
    } else {
      this.props.setWidgetStatus(['selectedAndDatasetProperties', 'tabIndex'], value);
    }
  }

  render() {
    const currentTab = this.props.rootModel.getIn(['widgetStatus', 'selectedAndDatasetProperties', 'tabIndex'], 0) as number;
    return <div style={{ flex: 1, display: 'flex' }} id="propertiesContainer" className='jr-jrws-pccontainer-root'>
      <CardHeader
        className="jr-mPanel-section-header jr-mPanel-section-headerTabbed mui"
        classes={{ content: "jr-mPanel-section-header-title mui" }}
        title={<Tabs
          className={"jr-mTabs jr-mTabsClassic jr-mTabsMedium mui"}
          value={currentTab}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab classes={{ wrapper: "jr-mTab-label mui" }} className={"jr-mTab mui"} label={i18n.t('reportdesigner.properties.label')} />
          <Tab classes={{ wrapper: "jr-mTab-label mui" }} className={"jr-mTab mui"} label={i18n.t('components.datasets.label')} />
        </Tabs>}
        disableTypography
      />
      <div style={{ display: 'flex', flex: 1 }} >
        {currentTab === 0 ? <SelectedProperties reportDescriptor={this.props.descriptor} model={this.props.model} rootModel={this.props.rootModel} selection={this.props.selection} setObjectProperties={this.setProperty} deleteElement={this.deleteElement} refreshCache={this.refreshCache} />
          : this.getDatasetProperties()}
      </div>
    </div>;
  }

  private getDatasetProperties = () => {
    return <DatasetProperties reportDescriptor={this.props.descriptor} model={this.props.model} rootModel={this.props.rootModel} selection={this.props.selection} setObjectProperties={this.setProperty} deleteElement={this.deleteElement} refreshCache={this.refreshCache} />
  }

  private getReportPath = () => {
    return RepositoryApi.getAPath(this.props.descriptor);
  }

  private deleteElement = (path, refreshCache?: boolean) => {
    if (refreshCache) {
      this.props.deletePropertyRefreshAction(path, this.getReportPath());
    } else {
      this.props.deleteElement(path);
    }
  }

  private setProperty = (path: (string | number)[], value: any, refreshCache?: boolean, isWidgetProperty?: boolean) => {
    //console.log(path, value);
    if (isWidgetProperty){
      this.props.setWidgetStatus(path, value);
    } else {
      const p = getNormalizedPath(path);
      if (refreshCache) {
        this.props.setPropertiesRefreshAction(p, value, this.getReportPath());
      } else {
        this.props.setObjectProperties(p, value);
      }
    }
  }

  private refreshCache = () => {
    this.props.loadCombosAction(this.getReportPath());
  }
}

const mapStateToProps = (state: IState) => {
  return {
    currentEditor: state.getIn(['report', 'currentEditor']),
    rootModel: state.getIn(['report']),
    model: state.getIn(['report', 'model']),
    selection: state.getIn(['report', 'selection']),
    subeditors: state.getIn(['report', 'subeditors']),
    currentEditorIndex: state.getIn(['report', 'currentEditorIndex']),
    descriptor: state.getIn(['report', 'fileDescriptor']),
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setObjectProperties: (path: string[], value: any) => { dispatch(ReportActions.setObjectProperties(path, value)); },
    createEditor: (editorType: string, editorLabel: string, isSelectable: boolean, editedResourceId: string, setActive: boolean, params: Record<string, unknown>) => {
      dispatch(ReportActions.createEditor(editorType, editorLabel, isSelectable, editedResourceId, setActive, params));
    },
    setEditorProperties: (index: number, editorLabel: string, isSelectable: boolean, editedResourceId: string, params: Record<string, unknown>) => {
      dispatch(ReportActions.setEditorProperties(index, editorLabel, isSelectable, editedResourceId, params));
    },
    closeEditor: (editorIndex: number) => { dispatch(ReportActions.closeEditor(editorIndex)) },
    deleteElement: (path: string[]) => { dispatch(ReportActions.deleteElement(path)); },
    setPropertiesRefreshAction: (path: (string | number)[], value: any, reportPath: string) => { dispatch(ReportActions.setPropertiesRefreshAction(path, value, reportPath)); },
    deletePropertyRefreshAction: (path: (string | number)[], reportPath: string) => { dispatch(ReportActions.deletePropertyRefreshAction(path, reportPath)); },
    loadCombosAction: (path: string) => { dispatch(ReportActions.loadCombosAction(path, true)); },
    setWidgetStatus: (path, data) => { dispatch(ReportActions.setWidgetStatus(path, data)); },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedAndDatasetProperties);