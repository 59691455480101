/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../ui/APDescriptor";
import { MBookmarks } from "../common/MBookmarks";
import { MHyperlinks } from "../common/MHyperlinks";
import AreaChartIcon from "../../../../../assets/icons/charts/area.svg";
import TimeSeriesChartIcon from "../../../../../assets/icons/charts/time_series.svg";
import BarChartIcon from "../../../../../assets/icons/charts/bar.svg";
import BubbleChartIcon from "../../../../../assets/icons/charts/bubble.svg";
import LineChartIcon from "../../../../../assets/icons/charts/line.svg";

import PieChartIcon from "../../../../../assets/icons/charts/pie.svg";
import SemiPieChartIcon from "../../../../../assets/icons/charts/semi_pie.svg";

import ScatterChartIcon from "../../../../../assets/icons/charts/scatter.svg";
import StackedAreaChartIcon from "../../../../../assets/icons/charts/stacked_area.svg";
import StackedBarChartIcon from "../../../../../assets/icons/charts/stacked_bar.svg";
import SpiderChartIcon from "../../../../../assets/icons/charts/spider_area.svg";
import SpiderColumnChartIcon from "../../../../../assets/icons/charts/spider_column.svg";
import SpiderLineChartIcon from "../../../../../assets/icons/charts/spider_line.svg";

import SplineChartIcon from "../../../../../assets/icons/charts/spline.svg";
import AreaSplineChartIcon from "../../../../../assets/icons/charts/area_spline.svg";
import HeatMapChartIcon from "../../../../../assets/icons/charts/heat_map.svg";
import TimeSeriesHeatMapChartIcon from "../../../../../assets/icons/charts/time_series_heap_map.svg";

import StackedColumnChartIcon from "../../../../../assets/icons/charts/stacked_column.svg";
import StackedColumnLineChartIcon from "../../../../../assets/icons/charts/stacked_column_line.svg";
import StackedColumnSplineChartIcon from "../../../../../assets/icons/charts/stacked_column_spline.svg";
import ColumnLineChartIcon from "../../../../../assets/icons/charts/column_line.svg";
import ColumnSplineChartIcon from "../../../../../assets/icons/charts/column_spline.svg";
import ColumnChartIcon from "../../../../../assets/icons/charts/column.svg";

import DualLevelPieChartIcon from "../../../../../assets/icons/charts/dual_level_pie.svg";

import DualMeasureTreeMapChartIcon from "../../../../../assets/icons/charts/dual_measure_tree_map.svg";
import TreeMapChartIcon from "../../../../../assets/icons/charts/tree_map.svg";
import ParentTreeMapChartIcon from "../../../../../assets/icons/charts/parent_tree_map.svg";

import GaugeChartIcon from "../../../../../assets/icons/charts/gauge.svg";
import PercentAreaChartIcon from "../../../../../assets/icons/charts/percent_area.svg";
import PercentBarChartIcon from "../../../../../assets/icons/charts/percent_bar.svg";
import PercentColumnChartIcon from "../../../../../assets/icons/charts/percent_column.svg";

import MultiAxisColumnChartIcon from "../../../../../assets/icons/charts/multi_axis_column.svg";
import MultiAxisLineChartIcon from "../../../../../assets/icons/charts/multi_axis_line.svg";
import MultiAxisSplineChartIcon from "../../../../../assets/icons/charts/multi_axis_spline.svg";
import MultiLevelGaugeChartIcon from "../../../../../assets/icons/charts/multi-level-gauge.svg";

import TileMapChartIcon from "../../../../../assets/icons/charts/tile-map.svg";


import { MHChartProperties } from "./MHighChartsProperties";
import { MHTileMapProperties } from "./MTileMapProperties";
import { MHHighMapProperties } from "./MHighMapProperties";



export const FHChart = {

}

export const MHChart: APDescriptor = {
    type: 'chart', id: 'chart',
    layouts: [...MHChartProperties.layouts, MBookmarks, MHyperlinks]
};

export const MHSplineChart: APDescriptor = {
    type: 'Spline', id: 'Spline', label: 'Spline', icon: SplineChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'Spline', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};

export const MHBarChart: APDescriptor = {
    type: 'Bar', id: 'Bar', label: 'Bar', icon: BarChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'Bar', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHLineChart: APDescriptor = {
    type: 'Line', id: 'Line', label: 'Line', icon: LineChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'Line', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHAreaChart: APDescriptor = {
    type: 'Area', id: 'Area', label: 'Area', icon: AreaChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'Area', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHAreaSplineChart: APDescriptor = {
    type: 'AreaSpline', id: 'AreaSpline', label: 'Area Spline', icon: AreaSplineChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'AreaSpline', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHHeatMapChart: APDescriptor = {
    type: 'HeatMap', id: 'HeatMap', label: 'Heat Map', icon: HeatMapChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'HeatMap', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHStackedBarChart: APDescriptor = {
    type: 'StackedBar', id: 'StackedBar', label: 'Stacked Bar', icon: StackedBarChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'StackedBar', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHStackedColumnChart: APDescriptor = {
    type: 'StackedColumn', id: 'StackedColumn', label: 'Stacked Column', icon: StackedColumnChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'StackedColumn', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHStackedLineChart: APDescriptor = {
    type: 'StackedLine', id: 'StackedLine', label: 'Stacked Line', icon: LineChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'StackedLine', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHStackedAreaChart: APDescriptor = {
    type: 'StackedArea', id: 'StackedArea', label: 'Stacked Area', icon: StackedAreaChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'StackedArea', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHStackedAreaSplineChart: APDescriptor = {
    type: 'StackedAreaSpline', id: 'StackedAreaSpline', label: 'Stacked Area Spline', icon: StackedAreaChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'StackedAreaSpline', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHStackedPercentBarChart: APDescriptor = {
    type: 'StackedPercentBar', id: 'StackedPercentBar', label: 'Stacked Percent Bar', icon: PercentBarChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'StackedPercentBar', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHStackedPercentColumnChart: APDescriptor = {
    type: 'StackedPercentColumn', id: 'StackedPercentColumn', label: 'StackedPercentColumn', icon: PercentColumnChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'StackedPercentColumn', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHStackedPercentLineChart: APDescriptor = {
    type: 'StackedPercentLine', id: 'StackedPercentLine', label: 'Stacked Percent Line', icon: LineChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'StackedPercentLine', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHStackedPercentAreaChart: APDescriptor = {
    type: 'StackedPercentArea', id: 'StackedPercentArea', label: 'Stacked Percent Area', icon: PercentAreaChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'StackedPercentArea', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHStackedPercentSplineChart: APDescriptor = {
    type: 'StackedPercentSpline', id: 'StackedPercentSpline', label: 'Stacked Percent Spline', icon: SplineChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'StackedPercentSpline', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const StackedPercentAreaSpline: APDescriptor = {
    type: 'StackedPercentAreaSpline', id: 'StackedPercentAreaSpline', label: 'Stacked Percent Area Spline', icon: PercentAreaChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'StackedPercentAreaSpline', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHPieChart: APDescriptor = {
    type: 'Pie', id: 'Pie', label: 'Pie', icon: PieChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'Pie', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHDualLevelPieChart: APDescriptor = {
    type: 'DualLevelPie', id: 'DualLevelPie', label: 'Dual Level Pie', icon: DualLevelPieChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'DualLevelPie', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHSemiPieChart: APDescriptor = {
    type: 'SemiPie', id: 'SemiPie', label: 'Semi Pie', icon: SemiPieChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'SemiPie', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHTimeSeriesLineChart: APDescriptor = {
    type: 'TimeSeriesLine', id: 'TimeSeriesLine', label: 'Time Series Line', icon: TimeSeriesChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'TimeSeriesLine', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHTimeSeriesAreaChart: APDescriptor = {
    type: 'TimeSeriesArea', id: 'TimeSeriesArea', label: 'Time Series Area', icon: TimeSeriesChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'TimeSeriesArea', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHTimeSeriesSplineChart: APDescriptor = {
    type: 'TimeSeriesSpline', id: 'TimeSeriesSpline', label: 'Time Series Spline', icon: TimeSeriesChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'TimeSeriesSpline', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHTimeSeriesAreaSplineChart: APDescriptor = {
    type: 'TimeSeriesAreaSpline', id: 'TimeSeriesAreaSpline', label: 'Time Series Area Spline', icon: TimeSeriesChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'TimeSeriesAreaSpline', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHTimeSeriesHeatMapChart: APDescriptor = {
    type: 'TimeSeriesHeatMap', id: 'TimeSeriesHeatMap', label: 'Time Series Heat Map', icon: TimeSeriesHeatMapChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'TimeSeriesHeatMap', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHColumnChart: APDescriptor = {
    type: 'Column', id: 'Column', label: 'Column', icon: ColumnChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'Column', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHColumnLineChart: APDescriptor = {
    type: 'ColumnLine', id: 'ColumnLine', label: 'Column Line', icon: ColumnLineChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'ColumnLine', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHColumnSplineChart: APDescriptor = {
    type: 'ColumnSpline', id: 'ColumnSpline', label: 'Column Spline', icon: ColumnSplineChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'ColumnSpline', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHStackedColumnLineChart: APDescriptor = {
    type: 'StackedColumnLine', id: 'StackedColumnLine', label: 'Stacked Column Line', icon: StackedColumnLineChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'StackedColumnLine', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHStackedColumnSplineChart: APDescriptor = {
    type: 'StackedColumnSpline', id: 'StackedColumnSpline', label: 'Stacked Column Spline', icon: StackedColumnSplineChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'StackedColumnSpline', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHMultiAxisLineChart: APDescriptor = {
    type: 'MultiAxisLine', id: 'MultiAxisLine', label: 'Multi Axis Line', icon: MultiAxisLineChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'MultiAxisLine', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHMultiAxisSplineChart: APDescriptor = {
    type: 'MultiAxisSpline', id: 'MultiAxisSpline', label: 'Multi Axis Spline', icon: MultiAxisSplineChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'MultiAxisSpline', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHMultiAxisColumnChart: APDescriptor = {
    type: 'MultiAxisColumn', id: 'MultiAxisColumn', label: 'Multi Axis Column', icon: MultiAxisColumnChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'MultiAxisColumn', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHScatterChart: APDescriptor = {
    type: 'Scatter', id: 'Scatter', label: 'Scatter', icon: ScatterChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'Scatter', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHBubbleChart: APDescriptor = {
    type: 'Bubble', id: 'Bubble', label: 'Bubble', icon: BubbleChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'Bubble', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHSpiderColumnChart: APDescriptor = {
    type: 'SpiderColumn', id: 'SpiderColumn', label: 'Spider Column', icon: SpiderColumnChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'SpiderColumn', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHSpiderLineChart: APDescriptor = {
    type: 'SpiderLine', id: 'SpiderLine', label: 'Spider Line', icon: SpiderLineChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'SpiderLine', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHSpiderAreaChart: APDescriptor = {
    type: 'SpiderArea', id: 'SpiderArea', label: 'Spider Area', icon: SpiderChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'SpiderArea', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHDualMeasureTreeMapChart: APDescriptor = {
    type: 'DualMeasureTreeMap', id: 'DualMeasureTreeMap', label: 'Dual Measure Tree Map', icon: DualMeasureTreeMapChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'DualMeasureTreeMap', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHTreeMapChart: APDescriptor = {
    type: 'TreeMap', id: 'TreeMap', label: 'Tree Map', icon: TreeMapChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'TreeMap', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHOneParentTreeMapChart: APDescriptor = {
    type: 'OneParentTreeMap', id: 'OneParentTreeMap', label: 'One Parent Tree Map', icon: ParentTreeMapChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'OneParentTreeMap', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHGaugeChart: APDescriptor = {
    type: 'Gauge', id: 'Gauge', label: 'Gauge', icon: GaugeChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'Gauge', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHMultiLevelGaugeChart: APDescriptor = {
    type: 'MultiLevelGauge', id: 'MultiLevelGauge', label: 'Multi Level Gauge', icon: MultiLevelGaugeChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'MultiLevelGauge', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};
export const MHArcGaugeChart: APDescriptor = {
    type: 'ArcGauge', id: 'ArcGauge', label: 'Arc Gauge', icon: GaugeChartIcon,
    layouts: [...MHChart.layouts],
    default: {
        type: 'HTML5CHART_JR', chartType: 'ArcGauge', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};

export const MHTilemapChart: APDescriptor = {
    type: 'TileMap', id: 'TileMap', label: 'Tile Map', icon: TileMapChartIcon,
    layouts: [...MHChartProperties.layouts, ...MHTileMapProperties.layouts, MBookmarks, MHyperlinks],
    default: {
        type: 'HTML5CHART_JR', chartType: 'TileMap', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};

export const MHHighMapChart: APDescriptor = {
    type: 'Map', id: 'Map', label: 'High Map', icon: HeatMapChartIcon,
    layouts: [...MHChartProperties.layouts, ...MHHighMapProperties.layouts, MBookmarks, MHyperlinks],
    default: {
        type: 'HTML5CHART_JR', chartType: 'Map', 'xmlns:jrhc': "http://jaspersoft.com/highcharts", "xsi:schemaLocation": "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd", "HTML5CHART_XMLNS_NAMESPACE": "jrhc"
    }
};