/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { APDescriptor } from "../../ui/APDescriptor";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PButtonGroup } from "../../ui/primitive/UPButtonGroup";
import { PEnum } from "../../ui/primitive/UPEnum";
import { PInt } from "../../ui/primitive/UPInteger";
import { PConnectedFontName } from "../../ui/primitive/UPConnectedFontName";

export function setFonts(prefix: string): APDescriptor[] {
    const fonts = [...MFont.layouts];
    return fonts.map(f => { return { ...f, path: prefix } });
}

export const FFont = {
    fontSize: new PInt({ id: 'size', label: 'Size', min: 0, inheritedPropertyId: 'font.size', path: 'font' }),
    fontName: new PConnectedFontName({
        id: 'fontName', label: 'Name', default: 'SansSerif', inheritedPropertyId: 'font.fontName', path: 'font', writeable: true
    }),
    isBold: new PBoolean({ id: 'isBold', path: 'font', label: 'Bold', icon: 'textBold', inheritedPropertyId: 'font.isBold' }),
    isItalic: new PBoolean({ id: 'isItalic', path: 'font', label: 'Italic', icon: 'textItalic', inheritedPropertyId: 'font.isItalic' }),
    isUnderline: new PBoolean({ id: 'isUnderline', path: 'font', label: 'Underline', icon: 'textUnderline', inheritedPropertyId: 'font.isUnderline' }),
    isStrikeThrough: new PBoolean({ id: 'isStrikeThrough', path: 'font', label: 'Strike Through', icon: 'textStrikethrough', inheritedPropertyId: 'font.isStrikeThrough' }),

    pdfFontName: new PEnum({
        id: 'pdfFontName', label: 'PDF Font Name', default: 'SansSerif', deprecated: true,
        items: [{ key: 'Helvetica', value: 'Helvetica' }, { key: 'Helvetica-Bold', value: 'Helvetica-Bold' }
            , { key: 'Helvetica-BoldOblique', value: 'Helvetica-BoldOblique' }
            , { key: 'Helvetica-Oblique', value: 'Helvetica-Oblique' }
            , { key: 'Courier', value: 'Courier' }
            , { key: 'Courier-Bold', value: 'Courier-Bold' }
            , { key: 'Courier-BoldOblique', value: 'Courier-BoldOblique' }
            , { key: 'Courier-Oblique', value: 'Courier-Oblique' }
            , { key: 'Symbol', value: 'Symbol' }
            , { key: 'Times-Roman', value: 'Times-Roman' }
            , { key: 'Times-Bold', value: 'Times-Bold' }
            , { key: 'Times-BoldItalic', value: 'Times-BoldItalic' }
            , { key: 'Times-Italic', value: 'Times-Italic' }
            , { key: 'ZapfDingbats', value: 'ZapfDingbats' }
            , { key: 'STSong-Light', value: 'STSong-Light' }
            , { key: 'MHei-Medium', value: 'MHei-Medium' }
            , { key: 'MSung-Light', value: 'MSung-Light' }
            , { key: 'HeiseiKakuGo-W5', value: 'HeiseiKakuGo-W5' }
            , { key: 'HeiseiMin-W3', value: 'HeiseiMin-W3' }
            , { key: 'HYGoThic-Medium', value: 'HYGoThic-Medium' }
            , { key: 'HYSMyeongJo-Medium', value: 'HYSMyeongJo-Medium' }
        ]
    }),
    pdfEncoding: new PEnum({
        id: 'pdfEncoding', label: 'PDF Encoding', default: 'SansSerif', deprecated: true, isEmptyUndefined: true,
        items: [{ key: '', value: '' },
        { key: 'Cp1250', value: 'CP1250 (Central European)' },
        { key: 'Cp1251', value: 'CP1251 (Cyrillic)' },
        { key: 'Cp1252', value: 'CP1252 (Western European ANSI aka WinAnsi)' },
        { key: 'Cp1253', value: 'CP1253 (Greek)' },
        { key: 'Cp1254', value: 'CP1254 (Turkish)' },
        { key: 'Cp1255', value: 'CP1255 (Hebrew)' },
        { key: 'Cp1256', value: 'CP1256 (Arabic)' },
        { key: 'Cp1257', value: 'CP1257 (Baltic)' },
        { key: 'Cp1258', value: 'CP1258 (Vietnamese)' },
        { key: 'UniGB-UCS2-H', value: 'UniGB-UCS2-H (Chinese Simplified)' },
        { key: 'UniGB-UCS2-V', value: 'UniGB-UCS2-V (Chinese Simplified)' },
        { key: 'UniCNS-UCS2-H', value: 'UniCNS-UCS2-H (Chinese traditional)' },
        { key: 'UniCNS-UCS2-V', value: 'UniCNS-UCS2-V (Chinese traditional)' },
        { key: 'UniJIS-UCS2-H', value: 'UniJIS-UCS2-H (Japanese)' },
        { key: 'UniJIS-UCS2-V', value: 'UniJIS-UCS2-V (Japanese)' },
        { key: 'UniJIS-UCS2-HW-H', value: 'UniJIS-UCS2-HW-H (Japanese)' },
        { key: 'UniJIS-UCS2-HW-V', value: 'UniJIS-UCS2-HW-V (Japanese)' },
        { key: 'UniKS-UCS2-H', value: 'UniKS-UCS2-H (Korean)' },
        { key: 'UniKS-UCS2-V', value: 'UniKS-UCS2-V (Korean)' },
        { key: 'Identity-H', value: 'Identity-H (Unicode with horizontal writing)' },
        { key: 'Identity-V', value: 'Identity-V (Unicode with vertical writing)' }
        ]
    }),
    isPdfEmbedded: new PBoolean({ id: 'isPdfEmbedded', label: 'PDF Embedded', deprecated: true })

};

export const MFont: APDescriptor = {
    type: 'MFont',
    id: 'fontProeprtiesDescriptor',
    layouts: [new PAccordeon({
        label: 'Font', expanded: true,
        layouts: [FFont.fontName, FFont.fontSize,
        new PButtonGroup({ label: ' ', layouts: [FFont.isBold, FFont.isItalic, FFont.isUnderline, FFont.isStrikeThrough] })]
    }), new PAccordeon({
        label: 'PDF',
        layouts: [FFont.pdfFontName, FFont.pdfEncoding, FFont.isPdfEmbedded]
    })]
};
