
/*
* Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
* Licensed under commercial Jaspersoft Subscription License Agreement
*/

import { isImmutable, List, Map } from 'immutable';
import _ from 'lodash';
import * as React from 'react';
import { getPath, reg, UiProperty, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';

import { Accordion, AccordionDetails, AccordionSummary, IconButton, TimedSearchBar, Typography } from '@jss/js-common';
import { IRepositoryItemDescriptor } from '@jss/js-rest-api';
import i18n from '../../../../../i18n';

export interface UPTableState {
    selected: number;
    search?: string;
}

export interface IConnectedUPTable {
    descriptor?: IRepositoryItemDescriptor | undefined;
    deletePropertyRefreshAction?: (path: (string | number)[], reportPath: string) => void;
    setPropertiesRefreshAction?: (path: (string | number)[], value: any, reportPath: string) => void;
    deleteElement?: (path: string[]) => void;
    addProperty?: (path: string[], element) => void;
}

const TYPEID = 'table';
export class PTable extends APDescriptor {
    default?: any;
    seachable?: boolean;
    expanded?: boolean = false;
    rowLabel?: string;
    rowLabelPaths?: string[];
    refreshCacheOnDelete?: boolean;
    refreshCacheOnAdd?: boolean;
    public constructor(init: Partial<PTable>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPTable mcontext={mc} />; });



export class UPTable<T = void> extends React.Component<UiPropertyProps & IConnectedUPTable & T, UPTableState> {

    public state: UPTableState = { selected: 0 };

    private isExpanded = () => {
        const defaultValue = (this.props.mcontext.descriptor as PTable).expanded === true;
        const isExpanded = this.props.mcontext.rootModel.getIn(['widgetStatus', this.props.mcontext.descriptor.id, 'expanded'], defaultValue) as boolean;
        return isExpanded;        
    }

    private handleExpanded = () => {
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expanded'], !this.isExpanded(), false, true);
    }

    render() {
        return <Accordion expanded={this.isExpanded()} onChange={this.handleExpanded} size='small'>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className="js-jrws-search-accordion-header" size='small'>
                <Typography style={{ textTransform: 'capitalize' }}>{i18n.t(this.props.mcontext.descriptor.label)}</Typography>
                {this.renderSearch()}
                <IconButton color='primary' icon='plus' size='small' style={{ marginRight: '-8px', marginLeft: '4px' }} onClick={this.addItem} title={i18n.t('common.actions.add')} />
            </AccordionSummary>
            <AccordionDetails
                style={this.props.mcontext.descriptor.style ? this.props.mcontext.descriptor.style : { display: 'block', maxHeight: '350px', overflow: 'auto', padding: 0 }}
                className={this.props.mcontext.descriptor.className}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {this.renderFields()}
                </div>

            </AccordionDetails>
        </Accordion>;
    }

    public renderFields = () => {
        const d = this.props.mcontext.descriptor as PTable;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p) as List<Map<string, any>> | undefined;
        if (!v) {
            return <div style={{ textAlign: 'center', color: 'lightgray' }}>{i18n.t('tableElement.novalues')}</div>;
        }

        const fields: List<Map<string, any> | string> = v;
        if (fields.size < 1) {
            return <div style={{ textAlign: 'center', color: 'lightgray' }}>{i18n.t('tableElement.novalues')}</div>;
        }
        const tmp = [];
        let filteredFields = fields;
        if (this.state.search && this.state.search.length > 0) {
            filteredFields = fields.filter((field) => {
                const name = typeof field === 'string' ? field : field.get('name');
                return name.toLowerCase().includes(this.state.search)
            })
        }

        filteredFields.map((f: Map<string, any> | string, index) => {
            if (f !== undefined) {
                if (typeof f === 'string') {
                    //use always the index as key
                    const fieldId = 'f' + index;
                    const els = _.cloneDeep(this.props.mcontext.elements);
                    const dl = d.layouts[0];
                    els.forEach(k => { k.path = [...p]; k.path.push(index) });
                    const mc = { ...this.props.mcontext, descriptor: { ...dl }, elements: els };
                    tmp.push(<div key={'div.' + fieldId} style={{ display: 'grid', gridTemplateColumns: '1fr 23px' }}>
                        <UiProperty key={fieldId} mcontext={mc} /> {mc.descriptor.readonly ? <></> : this.renderDelete(index)}
                    </div>);
                } else {
                    let itemname = `${index + 1} - ${d.rowLabel ? d.rowLabel : 'item'}`;
                    if (d.rowLabelPaths) {
                        d.rowLabelPaths.some((key) => {
                            const namePath = key.split('/');
                            const tmp = f.getIn(namePath) as string;
                            if (tmp !== undefined) {
                                itemname = tmp;
                                return true;
                            }
                            return false;
                        });
                    }
                    const fieldName = itemname;
                    //use the id as key when available, othwrwise use the index
                    const fieldId = f.get('id', 'f' + index);
                    if (this.props.mcontext.descriptor.layouts?.length > 0) {
                        const dl = d.layouts[0];
                        const subElements = this.props.mcontext.elements.map((oldElement) => {
                            return {
                                path: [...p, index],
                                descriptor: oldElement.descriptor,
                            }
                        });
                        const mc = { ...this.props.mcontext, descriptor: { ...dl, id: fieldName }, elements: subElements };
                        tmp.push(<div key={'div.' + fieldId} className="jr-MuiFormControl-root jr-MuiTextField-root jr-mInput jr-mInputText mui  jr-mInputLarge jr-mInputInline">
                            <UiProperty key={fieldId} mcontext={mc} /> {mc.descriptor.readonly ? <></> : this.renderDelete(index)}
                        </div>);
                    } else {
                        tmp.push(<div key={'div.key'} className="jr-MuiFormControl-root jr-MuiTextField-root jr-mInput jr-mInputText mui  jr-mInputLarge jr-mInputInline">{fieldName} {this.renderDelete(index)}</div>);
                    }

                }
            }
        })
        return tmp;
    }

    private deleteElement = (key) => {
        if (this.props.deleteElement) {
            const path = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
            const p = ['model', ...path, key];
            const d = this.props.mcontext.descriptor as PTable;
            if (d.refreshCacheOnDelete) {
                let pa = this.props.descriptor && this.props.descriptor.path ? this.props.descriptor.path : '/';
                if (!pa.endsWith('/')) {
                    pa += "/";
                }
                pa += this.props.descriptor ? this.props.descriptor.name : '';
                this.props.deletePropertyRefreshAction(p, pa);
            } else {
                this.props.deleteElement(p);
            }
        }
    }

    public renderDelete(key) {
        return <IconButton key={'ib.' + key} icon='delete' onClick={() => this.deleteElement(key)} title={i18n.t('common.actions.menu.delete')} />
        // return <MIconButton key={'ib.' + key} aria-label='delete' onClick={() => this.deleteElement(key)} ><DeleteIcon /> </MIconButton>;
    }

    private renderSearch() {
        const d = this.props.mcontext.descriptor as PTable;
        if (d.seachable === true)
            return <TimedSearchBar onClick={this.onClick} className="js-jrws-properties-search" style={{ marginTop: '12px' }} placeholder='search ...' id="file-searchbar" onChange={this.seearchTextChange} value={this.state.search} pauseTime={40} />
    }

    private onClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private seearchTextChange = (v: string) => {
        v = v.trim().toLowerCase();
        v = v.length > 0 ? v : undefined;
        this.setState({ search: v });
    }

    public addItem = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expanded'], true, false, true);

        const d = this.props.mcontext.descriptor as PTable;
        let def = {};
        if (d.default !== undefined) {
            if (d.default !== Object(d.default) || _.isString(d.default))
                def = d.default;
            else {
                def = { ...d.default };
                if (def['name']) {
                    const pth = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
                    const v = this.props.mcontext.model.getIn(pth) as List<Map<string, any>> | undefined;

                    if (v) {
                        const fields: List<Map<string, any>> = v;
                        def['name'] = this.findName(def['name'], def['name'], 1, fields);
                    }
                }
            }
        }
        const p: string[] = ['model', ...getPath(d.id, this.props.mcontext.elements[0].path)];
        this.props.addProperty(p, def);
    }

    protected findName = (n: string, prefix: string, id: number, fields: List<Map<string, any>>): string => {
        for (const f of fields) {
            const fld = isImmutable(f) ? f.toJS() : f;
            const name = fld.name;
            if (name === n) {
                return this.findName(prefix + id, prefix, id + 1, fields);
            }
        }
        return n;
    }
}