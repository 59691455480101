/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';

import '../assets/uxpl/css/EmptyView.css';

interface IEmptyViewProps {
  title?: string;
  subtitle?: string;
  icon?: JSX.Element;
}

export class EmptyView extends React.Component<IEmptyViewProps>
{


    public render() {
        return <div className="EmptyView">
                {this.props.icon ? <div className="EmptyView-icon" style={{fontSize: 75}}>{this.props.icon}</div> : null}
                {this.props.title ? <div className="EmptyView-title">{this.props.title}</div> : null}
                {this.props.subtitle ? <div className="EmptyView-subtitle">{this.props.subtitle}</div> : null}
                {this.props.children ? <div>{ this.props.children }</div> : null}
              </div>
    }
}
