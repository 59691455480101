/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StackedBarChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M44 4v130h218V4z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M24.51 157.188L245.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M24.51 173.5L245.49 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M24.51 173.5L24.51 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M245.49 173.5L245.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="#F55" stroke="none" d="M28.01 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="40.01" y="168.113" stroke="none" clipPath="url(#clipPath1)">Double Freight</text>
            <path fill="#55F" stroke="none" d="M105.22 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="117.221" y="168.113" stroke="none" clipPath="url(#clipPath1)">Normal Freight</text>
            <path fill="#5F5" stroke="none" d="M183.17 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="195.17" y="168.113" stroke="none" clipPath="url(#clipPath1)">Half Freight</text>
            <path fill="none" strokeWidth="0.5" d="M44 134L262 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M70.16 134L70.16 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M70.16 134L70.16 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M111.58 134L111.58 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M111.58 134L111.58 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M153 134L153 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M153 134L153 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M194.42 134L194.42 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M194.42 134L194.42 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M235.84 134L235.84 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M235.84 134L235.84 136" clipPath="url(#clipPath1)" />
            <text x="56.965" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Arge...</text>
            <text x="97.449" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Austria</text>
            <text x="136.602" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Belgium</text>
            <text x="183.258" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Brazil</text>
            <text x="220.619" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Canada</text>
            <path fill="none" strokeWidth="0.5" d="M44 4L44 134" clipPath="url(#clipPath1)" />
            <text x="32.91" y="136.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
            <path fill="none" strokeWidth="0.5" d="M42 134L44 134" clipPath="url(#clipPath1)" />
            <text x="15.098" y="112.841" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">5.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 110.071L44 110.071" clipPath="url(#clipPath1)" />
            <text x="10.008" y="88.912" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 86.142L44 86.142" clipPath="url(#clipPath1)" />
            <text x="10.008" y="64.983" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">15.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 62.213L44 62.213" clipPath="url(#clipPath1)" />
            <text x="10.008" y="41.054" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">20.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 38.284L44 38.284" clipPath="url(#clipPath1)" />
            <text x="10.008" y="17.125" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">25.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 14.355L44 14.355" clipPath="url(#clipPath1)" />
         </g>
         <g fill="silver" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M44 134L262 134" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 110.071L262 110.071" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 86.142L262 86.142" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 62.213L262 62.213" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 38.284L262 38.284" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 14.355L262 14.355" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M54.9 128.271H85.42V134H54.9z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M54.9 125.406H85.42V128.27100000000002H54.9z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M54.9 123.974H85.42V125.406H54.9z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M96.32 63.252H126.83999999999999V134H96.32z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M96.32 27.878H126.83999999999999V63.252H96.32z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M96.32 10.191H126.83999999999999V27.878H96.32z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M137.74 121.747H168.26000000000002V134H137.74z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M137.74 115.621H168.26000000000002V121.74799999999999H137.74z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M137.74 112.557H168.26000000000002V115.62H137.74z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M179.16 87.289H209.68V134H179.16z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M179.16 63.933H209.68V87.289H179.16z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M179.16 52.256H209.68V63.934H179.16z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M220.58 112.961H251.10000000000002V134H220.58z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M220.58 102.441H251.10000000000002V112.961H220.58z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M220.58 97.181H251.10000000000002V102.441H220.58z" clipPath="url(#clipPath2)" />
         </g>
      </svg>
   );
}
export default StackedBarChartIcon;