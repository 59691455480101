/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const PositionTypeEnum = new PEnum({
    id: 'positionType', label: 'PositionType.label', default: 'FixRelativeToTop',
    items: [
        { key: 'Float', value: 'PositionType.Float' },
        { key: 'FixRelativeToTop', value: 'PositionType.FixRelativeToTop' },
        { key: 'FixRelativeToBottom', value: 'PositionType.FixRelativeToBottom' }
    ]
}); 

