/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const XYAreaChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M39 4v134h223V4z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M24.51 157.188L245.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M24.51 173.5L245.49 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M24.51 173.5L24.51 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M245.49 173.5L245.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="#F55" stroke="none" d="M32.01 161.344l3 2 1 6h-8l1-6z" clipPath="url(#clipPath1)" />
            <text x="40.01" y="168.113" stroke="none" clipPath="url(#clipPath1)">Half Freight</text>
            <path fill="#55F" stroke="none" d="M96.83 161.344l3 2 1 6h-8l1-6z" clipPath="url(#clipPath1)" />
            <text x="104.83" y="168.113" stroke="none" clipPath="url(#clipPath1)">Normal Freight</text>
            <path fill="#5F5" stroke="none" d="M174.78 161.344l3 2 1 6h-8l1-6z" clipPath="url(#clipPath1)" />
            <text x="182.779" y="168.113" stroke="none" clipPath="url(#clipPath1)">Double Freight</text>
            <path fill="none" strokeWidth="0.5" d="M39 138L262 138" clipPath="url(#clipPath1)" />
            <text x="33.148" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.250</text>
            <path fill="none" strokeWidth="0.5" d="M47.144 140L47.144 138" clipPath="url(#clipPath1)" />
            <text x="95.41" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.500</text>
            <path fill="none" strokeWidth="0.5" d="M109.407 140L109.407 138" clipPath="url(#clipPath1)" />
            <text x="157.673" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.750</text>
            <path fill="none" strokeWidth="0.5" d="M171.669 140L171.669 138" clipPath="url(#clipPath1)" />
            <text x="219.936" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">11.000</text>
            <path fill="none" strokeWidth="0.5" d="M233.932 140L233.932 138" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M39 4L39 138" clipPath="url(#clipPath1)" />
            <text x="27.91" y="140.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
            <path fill="none" strokeWidth="0.5" d="M37 138L39 138" clipPath="url(#clipPath1)" />
            <text x="17.73" y="120.575" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">250</text>
            <path fill="none" strokeWidth="0.5" d="M37 117.806L39 117.806" clipPath="url(#clipPath1)" />
            <text x="17.73" y="100.381" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">500</text>
            <path fill="none" strokeWidth="0.5" d="M37 97.612L39 97.612" clipPath="url(#clipPath1)" />
            <text x="17.73" y="80.187" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">750</text>
            <path fill="none" strokeWidth="0.5" d="M37 77.418L39 77.418" clipPath="url(#clipPath1)" />
            <text x="10.098" y="59.993" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">1.000</text>
            <path fill="none" strokeWidth="0.5" d="M37 57.223L39 57.223" clipPath="url(#clipPath1)" />
            <text x="10.098" y="39.799" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">1.250</text>
            <path fill="none" strokeWidth="0.5" d="M37 37.029L39 37.029" clipPath="url(#clipPath1)" />
            <text x="10.098" y="19.605" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">1.500</text>
            <path fill="none" strokeWidth="0.5" d="M37 16.835L39 16.835" clipPath="url(#clipPath1)" />
         </g>
         <g fill="none" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path d="M47.144 4L47.144 138" clipPath="url(#clipPath2)" />
            <path d="M109.407 4L109.407 138" clipPath="url(#clipPath2)" />
            <path d="M171.669 4L171.669 138" clipPath="url(#clipPath2)" />
            <path d="M233.932 4L233.932 138" clipPath="url(#clipPath2)" />
            <path d="M39 138L262 138" clipPath="url(#clipPath2)" />
            <path d="M39 117.806L262 117.806" clipPath="url(#clipPath2)" />
            <path d="M39 97.612L262 97.612" clipPath="url(#clipPath2)" />
            <path d="M39 77.418L262 77.418" clipPath="url(#clipPath2)" />
            <path d="M39 57.223L262 57.223" clipPath="url(#clipPath2)" />
            <path d="M39 37.029L262 37.029" clipPath="url(#clipPath2)" />
            <path d="M39 16.835L262 16.835" clipPath="url(#clipPath2)" />
         </g>
         <g fill="#5F5" stroke="none" strokeLinejoin="bevel" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path d="M49.136 138v-22.7l1.246-.897 21.916-2.628.498-32.036 3.736 41.79 3.487 1.16 1.992-5.106.498.633 2.49 8.818.25-.955 5.977 6.866.747-69.062 2.989 66.372 17.931-119.874 3.985 72.817 10.211 50.592 5.977-11.425.499 9.961 8.965-71.532 8.468 64.59 4.732-2.974 2.989-28.428 12.203 25.063 4.234-4.543 2.242 7.92.747-41.213 4.732 36.329 10.21-46.079 1.993 62.467 2.49-12.117 10.212-10.102 18.18 14.241 2.74-44.988 2.74 38.04 4.482 6.163 2.242-109.016 8.966 113.283 4.732-33.214V138z" clipPath="url(#clipPath2)" />
            <path fill="#55F" d="M49.136 138v-11.35l1.246-.448 21.916-1.314.498-16.019 3.736 20.896 3.487.58 1.992-2.554.498.317 2.49 4.409.25-.477 5.977 3.433.747-34.532 2.989 33.187 17.931-59.937 3.985 36.408 10.211 25.296 5.977-5.713.499 4.981 8.965-35.766 8.468 32.295 4.732-1.487 2.989-14.214 12.203 12.532 4.234-2.272 2.242 3.96.747-20.606 4.732 18.164 10.21-23.04 1.993 31.234 2.49-6.058 10.212-5.051 18.18 7.12 2.74-22.494 2.74 19.02 4.482 3.082 2.242-54.509 8.966 56.642 4.732-16.607V138z" clipPath="url(#clipPath2)" />
            <path fill="#F55" d="M49.136 138v-5.675l1.246-.224 21.916-.657.498-8.01 3.736 10.448 3.487.29 1.992-1.276.498.158 2.49 2.204.25-.238 5.977 1.716.747-17.265 2.989 16.593 17.931-29.969 3.985 18.204 10.211 12.648 5.977-2.856.499 2.49 8.965-17.883 8.468 16.148 4.732-.744 2.989-7.107 12.203 6.266 4.234-1.136 2.242 1.98.747-10.303 4.732 9.082 10.21-11.52 1.993 15.617 2.49-3.029 10.212-2.525 18.18 3.56 2.74-11.247 2.74 9.51 4.482 1.54 2.242-27.253 8.966 28.32 4.732-8.303V138z" clipPath="url(#clipPath2)" />
         </g>
      </svg>
   );
}
export default XYAreaChartIcon;