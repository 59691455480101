/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../../ui/APDescriptor";
import { MHyperlinks } from "../../../common/MHyperlinks";
import { PBoolean } from "../../../../ui/primitive/UPBoolean";
import { PExpression } from "../../../../ui/primitive/UPExpression";
import { PConnectedTable } from "../../../../ui/tables/UPConnectedTable";
import { PTableElement } from "../../../../ui/tables/UPTableElement";

export const FXYSeries = {
    xValueExpression: new PExpression({ id: 'xValueExpression', label: 'X Value Expression' }),
    yValueExpression: new PExpression({ id: 'yValueExpression', label: 'Y Value Expression' }),
    labelExpression: new PExpression({ id: 'labelExpression', label: 'Label Expression' }),
    seriesExpression: new PExpression({ id: 'seriesExpression', label: 'Series Expression' }),
    autoSort: new PBoolean({ id: 'autoSort', label: 'Auto Sort', canBeNull: true })
};


export const MXYSeries: APDescriptor = new PConnectedTable({
    id: 'xySeries', label: 'Series', rowLabel: 'Series', rowLabelPaths: [FXYSeries.seriesExpression.id],
    layouts: [
        new PTableElement({
            label: 'XY Series',
            anchorOrigin: {vertical: 'center', horizontal: 'right'},
            transformOrigin: {vertical: 'center', horizontal: 'right'},
            layouts: [FXYSeries.labelExpression,
            FXYSeries.seriesExpression, FXYSeries.xValueExpression, FXYSeries.yValueExpression, FXYSeries.autoSort,
            { ...MHyperlinks, id: 'itemHyperlink', label: 'Item Hyperlink' }]
        })]
});