/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const FillEnum = new PEnum({
    id: 'fill', label: 'Fill.label', isEmptyUndefined: true,
    items: [
        { key: '', value: ''},
        { key: 'SOLID', value: 'Fill.SOLID'}
    ]
}); 
 
