/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { getPath, reg, setupPath4Elements, UiProperty, UiPropertyProps } from '../UiProperty';
import { APDescriptor } from '../APDescriptor';
import _ from 'lodash';
import { uuidv4 } from '../../../../../utils/uuidGenerator';

const TYPEID = 'BSelectedType';
reg(TYPEID, (mc) => { return <UPSelectedType mcontext={mc} />; });
export class PSelectedType extends APDescriptor {
    selectionField?: string = 'id';

    public getSelected(props: UiPropertyProps): APDescriptor | undefined {
        const pac = props.mcontext.descriptor as PSelectedType;
        const p = getPath(pac.selectionField ? pac.selectionField : pac.id, props.mcontext.elements[0].path);
        const v = props.mcontext.model.getIn(p);

        return props.mcontext.descriptor.layouts?.find(k => { return k.id === v });
    }

    public constructor(init: Partial<PSelectedType>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

export class UPSelectedType extends React.Component<UiPropertyProps> {

    render() {
        const pac = this.props.mcontext.descriptor as PSelectedType;
        const d = pac.getSelected(this.props);
        if (d)
            return <div key={`stdiv.${d.id ? d.id : uuidv4()}`} style={{ flex: 1, flexDirection: 'column', overflow: 'auto' }} >
                {this.renderDescriptor(d)}
            </div>;
        return <></>;
    }

    private renderDescriptor = (d: APDescriptor) => {
        if (d.layouts?.length > 0) {
            const mc = _.cloneDeep(this.props.mcontext);
            mc.elements = setupPath4Elements(this.props.mcontext.elements, d);
            return d.layouts?.map((key, ind) => {
                key.readonly = this.props.mcontext.descriptor.readonly;
                return <UiProperty key={d.id + '.' + key.id + '.' + ind} mcontext={{ ...mc, descriptor: key }} />
            })
        }
        d.readonly = this.props.mcontext.descriptor.readonly;
        return <UiProperty key={`stuiprep.${d.id ? d.id : uuidv4()}`} mcontext={{ ...this.props.mcontext, descriptor: d }} />
    }

}


