/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import Popover from '@material-ui/core/Popover';
import {Button} from './Button';
import { ButtonProps } from '@material-ui/core';

interface IPopoverButtonProps extends ButtonProps {
    id: string;
    content: React.ReactNode;
    isOpen?: boolean;
    text: string;
    onClose?: () => void;
}

interface IState {
    anchorEl: null | EventTarget
}

export class PopoverButton extends React.Component<IPopoverButtonProps, IState> {

 state = {
    anchorEl: null,
 }

 private setAnchorEl = (newAnchorEl: null | EventTarget) => {
     this.setState({
        anchorEl: newAnchorEl,
     });
 }

 public render () {

    const handleClick = (event) => {
      if (this.props.onClick) {
        this.props.onClick(event);
      }
      this.setAnchorEl(event.currentTarget);
    };
  
    const {content, isOpen, onClose, text, ...buttonProps} = this.props; 

    const handleClose = () => {
      if (onClose){
          onClose();
      }
      this.setAnchorEl(null);
    };
  
    const open = Boolean(this.state.anchorEl);
    const id = open ? this.props.id : undefined;
    
    const clickButtonProps : ButtonProps = {
        ...buttonProps,
        onClick: handleClick,
    }
    return (
      <div>
        <Button {...clickButtonProps}>{text}</Button>
        <Popover
          id={id}
          open={isOpen !== undefined ? isOpen : open}
          anchorEl={this.state.anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {content}
        </Popover>
      </div>
    );
 }

}
