/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Button, CommandRegistry, IconButton, IEditor, IMenuItem, IResourceInfo, IResourceListener, IResourceListenerManager, IToolBarItemOptions, IToolbarProvider, Spinner as UXPLSpinner, ToolBar, ToolButton } from '@jss/js-common';
import { ResourceInfoDialog, ResourcePickerDialog, RunContext } from '@jss/js-repository';
import { DatasourceApi, ErrorDescriptor, info, IRepositoryItemDescriptor, isReadOnly, MIME_TYPES, RepositoryApi, RESOURCE_TYPE } from '@jss/js-rest-api';
import { saveAs } from 'file-saver';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InfoIcon from '@material-ui/icons/Info';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PublishIcon from '@material-ui/icons/Publish';
import { AxiosError, AxiosResponse } from 'axios';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Base64 } from 'js-base64';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { DataAdapterTypePicker } from '../widget/DataAdapterTypePicker';
import { DATA_ADAPTER_EDITOR_DIRTY, DATA_ADAPTER_SAVED, DATA_ADAPTER_SAVED_AS, EXIT_DATA_ADAPTER_EDITOR, SAVE_DATA_ADAPTER, UPDATED_DATA_ADAPTER_CONTENT } from './commandIds';
import { getDataAdapterTypes, getJrsDataAdapterTypes } from './DataAdaptersRegistry';
import { IDataAdapterDescriptor } from './IDataAdapterDescriptor';

import '../assets/css/DataSourceEditor.css';
import i18n from '../i18n';
import { getDataAdapterNode } from '../editor/Utils';

function buildFileSelector(handleFileChange: (e) => void, acceptedFileTypes: string) {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', acceptedFileTypes);
    fileSelector.onchange = handleFileChange;
    fileSelector.onclick = function () {
        (this as any).value = null;
    };
    return fileSelector;
}

interface IDataSourceEditor {
    resourceListenerManagers: IResourceListenerManager[],
    commandRegistry: CommandRegistry,
    language?: string,
    onExit?: () => void;
}

interface IDatasourceEditorState {
    openDescriptor: IRepositoryItemDescriptor | undefined,
    path?: IRepositoryItemDescriptor[],
    isDirty: boolean,
    defaultFileName: string,
    initializingComponent: boolean,
    dataAdapterType: string | null,      // The type of the data adapter.
    dataAdapterInfo: any,      // This is an object which holds the whole data adapter configuration
    canNext: boolean,              // Tell us if we can complete the page. Depending of the page it may also allow to save or not this resource...
    errorMessage: string | null,
    errorMessageSave: string | null,
    successMessage: string | null,
    pageLoading: boolean,
    isNewDataAdapter: boolean,
    toolbarData: IToolbarProvider | undefined,
}


/**
 * The DataSourceEditor implements a special component for the creation of a new data source or editing an existing one.
 * Please note that in some contextes, we may still refer to a data source with the legacy name data adapter.
 *
 * @class DataSourceEditor
 * @extends {React.Component}
 */
export class InternalDataSourceEditor extends React.Component<IDataSourceEditor, IDatasourceEditorState> implements IEditor {

    protected fileSelector: HTMLInputElement;

    public state: IDatasourceEditorState = {
        initializingComponent: true,
        dataAdapterType: 'net.sf.jasperreports.data.jdbc.JdbcDataAdapterImpl',      // The type of the data adapter, jdbc by default
        dataAdapterInfo: null,      // This is an object which holds the whole data adapter configuration
        canNext: false,              // Tell us if we can complete the page. Depending of the page it may also allow to save or not this resource...
        errorMessage: null,
        errorMessageSave: null,
        successMessage: null,
        pageLoading: false,
        isNewDataAdapter: true,
        openDescriptor: undefined,
        path: undefined,
        defaultFileName: 'New Data Adapter',
        isDirty: false,
        toolbarData: undefined,
    }

    private resourceListenerId = ''

    public componentDidMount = () => {
        this.resourceListenerId = uuidv4();
        const resourceListener: IResourceListener = {
            onResourceOpen: (resource: IResourceInfo) => {
                let createDefualtSelector = true;
                if (!this.fileSelector) {
                    if (resource.resource) {
                        const ds = getJrsDataAdapterTypes(resource.resource.mime);
                        if (ds) {
                            this.fileSelector = buildFileSelector(this.onFileSelected, '.json');
                            createDefualtSelector = false;
                        }
                    }
                }
                if (createDefualtSelector) {
                    this.fileSelector = buildFileSelector(this.onFileSelected, '.xml,.jrdax');
                }
                if (resource.resource) {
                    if (!resource.content) {
                        //it has a descriptor but not content, consider it a new da
                        const defaultType = 'net.sf.jasperreports.data.jdbc.JdbcDataAdapterImpl';
                        const newInfo: any = this.createDataAdapterInfo(defaultType);
                        this.setState({
                            dataAdapterType: defaultType,
                            dataAdapterInfo: newInfo,
                            canNext: false,
                            errorMessage: null,
                            errorMessageSave: null,
                            successMessage: null,
                            pageLoading: false,
                            isNewDataAdapter: true,
                            initializingComponent: false,
                            openDescriptor: resource.resource,
                            path: resource.path,
                            defaultFileName: resource.resource.name,
                            isDirty: resource.isResourceDirty !== undefined ? resource.isResourceDirty : false,
                        });
                    } else {
                        this.setState({
                            isNewDataAdapter: resource.resource.data === undefined,
                            canNext: true,
                            openDescriptor: resource.resource,
                            path: resource.path,
                            defaultFileName: resource.resource.name,
                            isDirty: resource.isResourceDirty !== undefined ? resource.isResourceDirty : false,
                        }, () => {
                            this.loadResource(resource.content);
                        });
                    }
                }
            }
        }
        this.props.resourceListenerManagers.forEach(manager => {
            manager.addResourceListener(this.resourceListenerId, resourceListener);
        });
        this.props.commandRegistry.registerHandler(SAVE_DATA_ADAPTER, {
            execute: (params: Record<string, unknown>, callback?: () => void) => {
                this.doSave(false, callback);
            },
            isEnabled: () => {
                return true;
            }
        });
        this.props.commandRegistry.registerHandler(EXIT_DATA_ADAPTER_EDITOR, {
            execute: () => {
                if (this.props.commandRegistry) {
                    this.props.commandRegistry.executeCommand(EXIT_DATA_ADAPTER_EDITOR);
                }
            },
            isEnabled: () => {
                return true;
            }
        });
        this.provideToolbar();
    }

    componentWillUnmount() {
        this.props.resourceListenerManagers.forEach(manager => {
            manager.removeResourceListener(this.resourceListenerId);
        });
    }

    componentDidUpdate = (previousProps: IDataSourceEditor, previousState: IDatasourceEditorState) => {
        if (previousState.isDirty !== this.state.isDirty || this.props.language !== previousProps.language) {
            this.provideToolbar();
            this.props.commandRegistry.executeCommand(DATA_ADAPTER_EDITOR_DIRTY, { isDirty: this.state.isDirty });
        } else if (previousState.dataAdapterInfo !== this.state.dataAdapterInfo) {
            this.provideToolbar();
        } else if (previousState.canNext !== this.state.canNext || this.state.openDescriptor !== previousState.openDescriptor) {
            this.provideToolbar();
        }
    }

    provideToolbar = () => {
        this.setState({ toolbarData: this.getToolbar() });
    }

    handleUploadPress = (e) => {
        e.preventDefault();
        this.fileSelector.click();
    }

    getToolbar = (): IToolbarProvider => {
        const isRO = !this.state.openDescriptor || isReadOnly(this.state.openDescriptor);
        const isSaveDisabled = !this.state.isDirty || !this.state.canNext || isRO;
        const getSaveSubmenu: IMenuItem[] = [
            {
                id: 'saveAction',
                label: i18n.t('common.actions.menu.save'),
                isEnabled: !isSaveDisabled,
                icon: <IconButton className='jr-mMenuDecorated jr-mMenu-list-item-icon jr-MuiListItemIcon-root jr-mIcon mui' style={{ minWidth: 0, backgroundColor: 'transparent' }} icon={'save'} size="small" disabled={isSaveDisabled} />,
                onClick: () => {
                    if (this.state.openDescriptor.uuid) {
                        this.doSave(false);
                    } else {
                        //if the file is new force a new file to let the user choose the name
                        this.doSave(true);
                    }
                }
            },
            {
                id: 'saveAsAction',
                label: i18n.t('common.actions.menu.saveas'),
                isEnabled: this.state.canNext,
                icon: <SaveAltIcon color={this.state.canNext ? 'disabled' : 'inherit'} className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    this.doSave(true);
                }
            },
            {
                id: 'propertiesAction',
                label: i18n.t('common.actions.menu.resourceinfo'),
                icon: <InfoIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    this.showPropertiesDialog();
                },
                isEnabled: this.state.openDescriptor !== undefined && this.state.openDescriptor.uuid !== undefined,
            },
            {
                // TODO -- Improve the structure of this MenuItem.
                // 1. avoid duplication for icon and text
                // 2. the fallback is the central part of the menu item
                // is not trigger the file choose opening
                label: i18n.t('common.actions.menu.upload'),
                id: 'importAction',
                getComponent: (handleClose: () => void) => {
                    return (
                        <MenuItem key={'importAction'} selected={false} className={"jr-mMenu-list-item mui"} style={{ opacity: isRO ? 0.5 : undefined }}>
                            <div style={{ display: 'flex' }} onClick={(event) => {
                                if (!isRO) {
                                    handleClose(); this.handleUploadPress(event)
                                }
                            }}>
                                <ListItemIcon className={"jr-mMenu-list-item-icon mui"}>
                                    <PublishIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />
                                </ListItemIcon>
                                <ListItemText primary={i18n.t('common.actions.menu.upload')} classes={{ primary: "jr-mText mui" }} />
                            </div>
                        </MenuItem>
                    );
                },
            }, {
                label: i18n.t('common.actions.menu.download'),
                icon: <GetAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                id: 'exportAction',
                onClick: () => {
                    if (!this.state.canNext) {
                        alert(i18n.t('datasource.error.completefields'));
                    } else {
                        const type = this.state.dataAdapterType;
                        const daInfo: any = this.state.dataAdapterInfo;
                        const ds = getJrsDataAdapterTypes(type);
                        if (ds) {
                            const jsonDsContent = ds.toJSON(this.state.dataAdapterInfo);
                            const blob = new Blob([jsonDsContent], { type: "text/json;charset=utf-8" });
                            saveAs(blob, RepositoryApi.inst().field().decode('name', daInfo.name) + '.json');
                        } else {
                            const dataAdapterTypes = getDataAdapterTypes();
                            const da = dataAdapterTypes.find((item: IDataAdapterDescriptor) => item.getDataAdapterClass() === this.state.dataAdapterType);
                            if (da && this.state.dataAdapterInfo) {
                                const xmlDaContent = da.toXml(this.state.dataAdapterInfo);
                                if (this.props.commandRegistry) {
                                    const blob = new Blob([xmlDaContent], { type: "text/xml;charset=utf-8" });
                                    saveAs(blob, RepositoryApi.inst().field().decode('name', daInfo.name) + '.jrdax');
                                }
                            }
                        }
                    }
                }
            }, {
                label: i18n.t('common.actions.menu.exit'),
                id: 'exitAction',
                icon: <ExitToAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    if (this.props.commandRegistry) {
                        this.props.commandRegistry.executeCommand(EXIT_DATA_ADAPTER_EDITOR);
                    }
                }
            }
        ];
        return {
            getToolItems: () => {
                const items = [
                    {
                        id: 'saveToolaction',
                        render: (options?: IToolBarItemOptions) => {
                            //disable the save when the report is not dirty or we don't have write permission
                            const height = options && options.height ? options.height : 24;
                            const backgroundColor = options && options.background ? options.background : undefined;
                            return <ToolButton label={i18n.t('common.actions.menu.save')} isDisabled={isSaveDisabled} onClick={() => {
                                if (this.state.openDescriptor.uuid) {
                                    this.doSave(false);
                                } else {
                                    //if the file is new force a new file to let the user choose the name
                                    this.doSave(true);
                                }
                            }} height={height} backgroundColor={backgroundColor} content={getSaveSubmenu}>
                                <IconButton style={{ backgroundColor: 'transparent' }} icon={'save'} size="small" disabled={isSaveDisabled} />
                            </ToolButton>
                        }
                    },
                ];
                if (DatasourceApi.inst().hasTest() && getJrsDataAdapterTypes(this.state.dataAdapterType) === undefined) {
                    //add the test item only if enabled
                    items.push(
                        {
                            id: 'testDataAdapterAction',
                            render: (options?: IToolBarItemOptions) => {
                                const height = options && options.height ? options.height : 24;
                                const foregroundColor = this.state.canNext ? (options && options.foreground ? options.foreground : '#0081cb') : 'lightgray';
                                const backgroundColor = options && options.background ? options.background : undefined;
                                return <div style={{ display: 'flex', width: 70, height: height, justifyContent: 'center', alignItems: 'center', background: backgroundColor }} title={i18n.t('datasource.editor.testTooltip')}>
                                    <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                                    <div style={{ width: 70, height: 24, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} onClick={() => {
                                        if (this.state.canNext) {
                                            this.testDataSource();
                                        }
                                    }}>
                                        <span style={{ color: foregroundColor, fontSize: 15 }}>{i18n.t('datasource.editor.test.label')}</span>
                                    </div>
                                </div>
                            }
                        },
                    );
                }
                return items;
            }
        }
    }

    private onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            const daXML = fileReader.result as string;
            // TODO - perform some kind of validation of the loaded content
            // or be sure that a sort of validation is triggered when editorContent is updated
            const base64data = Base64.encode(daXML);
            const descriptor: IRepositoryItemDescriptor = this.state.openDescriptor;
            const newDescriptor: IRepositoryItemDescriptor = {
                ...(descriptor as any),
                data: base64data,
            }
            this.setState({
                openDescriptor: newDescriptor
            }, () => {
                this.loadResource(daXML);
                this.props.commandRegistry.executeCommand(UPDATED_DATA_ADAPTER_CONTENT, { descriptor: newDescriptor, data: daXML });
            });
        };
        fileReader.readAsText(event.currentTarget.files[0]);
    }

    public openSaveDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = this.state.path;
        let filename = this.state.defaultFileName;
        if (!filename.toLowerCase().endsWith('.jrdax') && !filename.toLowerCase().endsWith('.xml')) {
            filename += '.jrdax';
        }
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('datasource.editor.saveAs.name'),
                mode: 'save',
                title: !this.state.openDescriptor.uuid ? i18n.t('datasource.editor.save.title') : i18n.t('datasource.editor.saveAs.title'),
                confirmButtonLabel: !this.state.openDescriptor.uuid ? i18n.t('common.actions.button.ok') : i18n.t('common.actions.button.save'),
                onViewClose: () => {
                    //empty
                },
                onFileSelected: this.confirmSave,
                defaultFileName: filename,
                defaultPath: defaultPath
            }
        );
    }

    private confirmSave = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null, fileName: string) => {
        const type = this.state.dataAdapterType;
        const ds = getJrsDataAdapterTypes(type);

        let textContent;
        let mime;
        if (ds && this.state.dataAdapterInfo) {
            textContent = ds.toJSON(this.state.dataAdapterInfo);
            mime = ds.getMime();
        } else {
            const dataAdapterTypes = getDataAdapterTypes();
            const da = dataAdapterTypes.find((item: IDataAdapterDescriptor) => item.getDataAdapterClass() === this.state.dataAdapterType);
            if (da && this.state.dataAdapterInfo) {
                textContent = da.toXml(this.state.dataAdapterInfo);
                const daInfo = this.state.dataAdapterInfo;
                const daClass = daInfo.class;
                const daDescriptor = dataAdapterTypes.find((item: IDataAdapterDescriptor) => item.getDataAdapterClass() === daClass);
                mime = daDescriptor ? daDescriptor.getMime() : MIME_TYPES.DATA_ADAPTER_JDBC
            }
        }

        if (textContent) {
            const base64data = Base64.encode(textContent);
            let root = false;
            const newDescriptor: IRepositoryItemDescriptor = {
                type: RESOURCE_TYPE.FILE,
                name: fileName,
                path: RepositoryApi.inst().getParentPath(folderPath.filter(k => {
                    root = root || file === null || k.type !== RESOURCE_TYPE.CONTAINER;
                    return file == null || (root && k.type === RESOURCE_TYPE.CONTAINER)
                })),
                data: base64data,
                mime: mime,
            }
            if (file !== null) {
                //the save as is overwriting a file
                newDescriptor.path = file.path;
                newDescriptor.uuid = file.uuid;
            }

            this.context.runNoCancel(RepositoryApi.inst().save)(file !== null ? 'uuid:' + file.uuid : '', newDescriptor).then((response: AxiosResponse) => {
                if (response.data) {
                    this.setState({ openDescriptor: response.data, isNewDataAdapter: false, isDirty: false }, () => {
                        this.props.commandRegistry.executeCommand(DATA_ADAPTER_SAVED_AS, { descriptor: response.data, data: textContent });
                        this.props.commandRegistry.showMessage(info(i18n.t('datasource.editor.saveAs.saveOk')));
                    });
                }
            });
        }
    }

    private showPropertiesDialog = () => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.context.showDialog(ResourceInfoDialog, { descriptor: this.state.openDescriptor, onViewClose: () => { }, onChanged: this.handleItemPropertiesChanged });
    }

    private handleItemPropertiesChanged = (oldDescriptor: IRepositoryItemDescriptor, newDescriptor: IRepositoryItemDescriptor | null) => {
        this.setState({
            openDescriptor: newDescriptor,
        }, () => {
            this.props.commandRegistry.executeCommand(DATA_ADAPTER_SAVED, { descriptor: newDescriptor });
        });
    }

    public getEditorContent = () => {
        const type = this.state.dataAdapterType;
        const ds = getJrsDataAdapterTypes(type);
        if (ds) {
            return ds.toJSON(this.state.dataAdapterInfo);
        } else {
            const dataAdapterTypes = getDataAdapterTypes();
            const da = dataAdapterTypes.find((item: IDataAdapterDescriptor) => item.getDataAdapterClass() === type);
            if (da && this.state.dataAdapterInfo) {
                const xmlDaContent = da.toXml(this.state.dataAdapterInfo);
                return xmlDaContent;
            }
        }
        return this.state.dataAdapterInfo.rawData;
    }

    private doSave = (forceNewFile = false, callback?: () => void) => {
        const type = this.state.dataAdapterType;
        const daInfo: any = this.state.dataAdapterInfo;

        let textContent;
        let mime;

        const ds = getJrsDataAdapterTypes(type);
        if (ds) {
            textContent = ds.toJSON(daInfo);
            mime = ds.getMime();
        } else {
            const dataAdapterTypes = getDataAdapterTypes();
            const da = dataAdapterTypes.find((item: IDataAdapterDescriptor) => item.getDataAdapterClass() === type);
            if (da && this.state.dataAdapterInfo) {
                textContent = da.toXml(daInfo);
                const daClass = daInfo.class;
                const daDescriptor = dataAdapterTypes.find((item: IDataAdapterDescriptor) => item.getDataAdapterClass() === daClass);
                mime = daDescriptor ? daDescriptor.getMime() : MIME_TYPES.DATA_ADAPTER_JDBC;
            }
        }

        if (textContent) {
            if (this.state.openDescriptor && !forceNewFile) {
                //it is a save of an existing report
                const base64data = Base64.encode(textContent);
                const descriptor: IRepositoryItemDescriptor = this.state.openDescriptor;

                const newDescriptor: IRepositoryItemDescriptor = {
                    ...(descriptor as any),
                    data: base64data,
                    mime: mime,
                }

                const commitFile = () => this.context.runNoCancel(RepositoryApi.inst().save)(descriptor.uuid ? 'uuid:' + descriptor.uuid : (descriptor.path ? descriptor.path : '/'), newDescriptor,).then((response: AxiosResponse) => {
                    if (response.data) {
                        //need to update the descriptor also in simple save because it could contain the version
                        this.setState({ isNewDataAdapter: false, openDescriptor: response.data, isDirty: false }, () => {
                            this.props.commandRegistry.executeCommand(DATA_ADAPTER_SAVED, { descriptor: response.data, data: textContent });
                            if (callback) {
                                callback();
                            }
                        })
                    }
                }).catch(error => console.log(error));
                /*if (this.state.isNewDataAdapter){
                    //check if the file already exist
                    const parentPathUUID = RepositoryApi.inst().getParentPath([newDescriptor]);
                    this.context.run(RepositoryApi.inst().list)(parentPathUUID).then((response: AxiosResponse) => {
                        if (response.data) {
                            if (response.data.children) {
                                const existingFiles = Object.values(response.data.children) as IRepositoryItemDescriptor[];
                                if (this.isNameUsed(newDescriptor.name, existingFiles)){
                                    this.context.showDialog(OverwriteResourceDialog).then((dialogState: IDialogState) => {
                                        if (!dialogState.canceled){
                                            commitFile();
                                        }
                                    });
                                    return;
                                }
                            }
                        }
                        commitFile();
                    });
                } else {
                    commitFile();
                }*/
                commitFile();
            } else {
                //it is a new file
                this.openSaveDialog();
            }
        }
    }

    /**
     *  This method load the data source we want to edit...
     *  We assume that the data is in format uuid:....
     *  TODO: check if the provided path is in format uuid:....
     *
     * @private
     * @memberof DataSourceEditor
     */
    private loadResource = (data: string) => {
        // Perform loading....
        const ds = getJrsDataAdapterTypes(this.state.openDescriptor.mime);
        if (ds) {
            const result = ds.fromJson(data);
            this.setState({
                initializingComponent: false,
                dataAdapterType: result.class,
                dataAdapterInfo: result,
            }, () => {
                this.setState({ canNext: this.validateDataAdapterInfo() })
            });
        } else {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(data, 'text/xml');
            const rootNode = xmlDoc.getRootNode();
            const dataAdapterNode = getDataAdapterNode(rootNode);
            const dataAdapterType = dataAdapterNode ? dataAdapterNode.nodeName : undefined;
            const dataAdapterTypes = getDataAdapterTypes();
            const da = dataAdapterTypes.find((item: IDataAdapterDescriptor) => item.getDataAdapterRootName() === dataAdapterType);
            if (da) {
                const result = da.toJson(xmlDoc);
                this.setState({
                    initializingComponent: false,
                    dataAdapterType: result.class,
                    dataAdapterInfo: result,
                }, () => {
                    this.setState({ canNext: this.validateDataAdapterInfo() })
                });
            } else {
                this.setState({ initializingComponent: false, dataAdapterType: dataAdapterType, dataAdapterInfo: { rawData: data } })
            }
        }
    }

    private getPage = () => {
        const type = this.state.dataAdapterType;
        let daContent;

        const ds = getJrsDataAdapterTypes(type);
        if (ds) {
            daContent = <div style={{ flex: 1, display: 'flex' }}>{ds.createEditor(this.state.dataAdapterInfo, this.onDataAdapterSettingsChange, isReadOnly(this.state.openDescriptor))}</div>
        } else {
            const dataAdapterTypes = getDataAdapterTypes();

            let da = dataAdapterTypes.find((item: IDataAdapterDescriptor) => item.getDataAdapterClass() === type);
            if (!da && this.state.isNewDataAdapter) {
                da = dataAdapterTypes[1];
            }
            if (da !== undefined) {
                daContent = <div style={{ flex: 1, display: 'flex' }}>{da.createEditor(this.state.dataAdapterInfo, this.onDataAdapterSettingsChange, isReadOnly(this.state.openDescriptor))}</div>
            }
            else {
                daContent = <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{i18n.t('datasource.editor.unknowData')}</div>;
            }
        }

        let picker;
        if (this.state.isNewDataAdapter) {
            picker = <div key="0" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <DataAdapterTypePicker selectedType={type} onDataAdapterTypePicked={this.onDataAdapterTypePicked} />
            </div>;
        } else {
            picker = <div key="0" style={{ marginLeft: '10px' }} />
        }
        return [picker,
            <div key="1" style={{ display: 'flex', flex: 3.5, alignItems: 'stretch', flexDirection: 'column', position: 'relative', overflow: 'scroll' }}>
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', flex: 1, alignItems: 'stretch', flexDirection: 'column', inset: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }} className='tc-js-datasource-configuration-container '>
                        {daContent}
                    </div>
                </div>
            </div>
        ];
    }

    private onDataAdapterTypePicked = (type: string) => {
        const oldInfo: any = this.createDataAdapterInfo(this.state.dataAdapterType);
        const newInfo: any = this.createDataAdapterInfo(type);
        if (this.state.dataAdapterInfo && this.state.dataAdapterInfo.name
            && this.state.dataAdapterInfo.name !== oldInfo.name) {
            //keep the name only if it is not the default one
            newInfo.name = this.state.dataAdapterInfo.name;
        }
        this.setState({
            dataAdapterType: type,
            dataAdapterInfo: newInfo,
        }, () => {
            this.validateStep();
        });
    }

    /**
     * Perform a test and shows an ok or error.
     *
     * @private
     * @memberof DataSourceEditor
     */
    private testDataSource = () => {
        // Perform an async call which will ultimately move
        // or not to the next page....
        // Let's try to print the XML...

        // Now we need to lookup for the proper editor UI based on the selected DA type.
        const dataAdapterTypes = getDataAdapterTypes();
        const da = dataAdapterTypes.find((item: IDataAdapterDescriptor) => item.getDataAdapterClass() === this.state.dataAdapterType);

        if (da && this.state.dataAdapterInfo !== null) {
            this.asyncDAServerValidation((res: boolean, errMsg?: string) => {
                if (res) {
                    this.setState({ successMessage: i18n.t('datasource.editor.test.success') });
                } else {
                    this.setState({ errorMessage: errMsg ? errMsg : i18n.t('datasource.editor.test.fail') });
                }
            });
        }
    }

    /**
     * Allows to decide if I can go to the next page.
     * After the decision is made, we can execute a callback.
     * This callback is actually used in the first step of the wizard to just click on a
     * data adapter type to proceed to the next step. Easier than having to click next.
     */
    private validateStep = (cb?: () => void) => {
        const canGoNext = this.validateDataAdapterInfo();
        this.setState({ canNext: canGoNext }, () => { if (canGoNext && cb) { cb() } });
    }

    private onDataAdapterSettingsChange = (data: any) => {
        const oldData = this.state.dataAdapterInfo !== null && typeof this.state.dataAdapterInfo === 'object' ? this.state.dataAdapterInfo : {};

        this.setState({ dataAdapterInfo: { ...oldData, ...data }, isDirty: true }, () => this.validateStep());
    }

    /**
     * This method initializes the data used by the selected data adapter.
     */
    private createDataAdapterInfo = (type: string) => {
        const da = getDataAdapterTypes().find((item: IDataAdapterDescriptor) => item.getDataAdapterClass() === type);

        if (da) {
            const d = da.initializeDataAdapterConfig();
            d.uuid = uuidv4();
            return d;
        }

        // if I did not find the proper data adapter descriptor, we return just an empty object as configuration.
        return {};
    }

    /**
     * Simply clear the error message, so that the dialog showing it will automatically close...
     *
     * @private
     */
    private clearErrors = () => {
        this.setState({ errorMessage: null });
    }

    /**
     * This method initializes the data used by the selected data adapter.
     * This method should be placed in an appropriate class specifically design to provide
     * data adapters support such as descriptors and validators.
     */
    private validateDataAdapterInfo = () => {
        if (this.state.dataAdapterType === null) {
            return true;
        }

        const da = getDataAdapterTypes().find((item: IDataAdapterDescriptor) => item.getDataAdapterClass() === this.state.dataAdapterType);

        if (!da) {
            return true;
        }
        const daInfo: any = this.state.dataAdapterInfo;
        const err = da.validateDataAdapterConfig(daInfo);

        return err === undefined;
    }

    /**
     * We use this method to:
     * 1. generate the DA xml
     * 2. store the DA xml on the server
     * 3. test our DA on the server
     * 4. In case of error, show the error, otherwise proceed on step 3.
     *
     * @private
     */
    private asyncDAServerValidation = async (callBack: (res: boolean, errMsg?: string) => void) => {
        this.setState({ pageLoading: true, errorMessage: null }, async () => {
            const dataAdapterTypes = getDataAdapterTypes();
            const da = dataAdapterTypes.find((item: IDataAdapterDescriptor) => item.getDataAdapterClass() === this.state.dataAdapterType);

            if (da && this.state.dataAdapterInfo) {
                const da = dataAdapterTypes.find((item: IDataAdapterDescriptor) => item.getDataAdapterClass() === this.state.dataAdapterType);
                if (da && this.state.dataAdapterInfo) {
                    const xmlDaContent = da.toXml(this.state.dataAdapterInfo);
                    if (this.state.openDescriptor) {
                        const base64data = Base64.encode(xmlDaContent);
                        const descriptor: IRepositoryItemDescriptor = this.state.openDescriptor;
                        const dataAdapterTypes = getDataAdapterTypes();
                        const daInfo: any = this.state.dataAdapterInfo;
                        const daClass = daInfo.class;
                        const daDescriptor = dataAdapterTypes.find((item: IDataAdapterDescriptor) => item.getDataAdapterClass() === daClass);
                        const newDescriptor: IRepositoryItemDescriptor = {
                            ...(descriptor as any),
                            data: base64data,
                            mime: daDescriptor ? daDescriptor.getMime() : MIME_TYPES.DATA_ADAPTER_JDBC,
                            path: RepositoryApi.getAPathOnly(descriptor)
                        }
                        const t = RepositoryApi.inst().tmp();
                        if (t != RepositoryApi.inst().currentRepository())
                            newDescriptor.uuid = undefined;

                        try {
                            const r = await this.context.runNoCancel(t.save)(newDescriptor.path ? newDescriptor.path : '/',
                                newDescriptor);
                            if (!r.canceled) {
                                const path = RepositoryApi.getAPath(this.state.openDescriptor); 
                                await this.context.run(DatasourceApi.inst().testDataAdapter)(path).then((response) => {
                                    if (response.data) {
                                        //valid da
                                        this.setState({ pageLoading: false }, () => callBack(true));
                                    } else {
                                        this.setState({ pageLoading: false }, () => callBack(false, response.message));
                                    }
                                }).catch((error: AxiosError) => {
                                    this.setState({ pageLoading: false }, () => callBack(false, new ErrorDescriptor(error).message));
                                });
                            }
                        } finally { }
                    }
                }
            }
        });
    }

    private clearSuccessMessage = () => {
        this.setState({ successMessage: null });
    }

    private clearErrorMessageSave = () => {
        this.setState({ errorMessageSave: null });
    }

    private getSuccessMessageDialog = () => {
        return <Dialog
            open={this.state.successMessage !== null}
            title={'Success'}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    this.clearSuccessMessage();
                }
            }}
            aria-labelledby="simple-dialog-title"
            fullWidth={true}
            maxWidth={'xs'}
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.state.successMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button size="large" color="primary" variant="contained" className="tc-jsw-margin-right" onClick={this.clearSuccessMessage}>{i18n.t('datasource.editor.test.close')}</Button>
            </DialogActions>
        </Dialog>
    }

    private getErrorMessageSaveDialog = () => {
        return <Dialog
            open={this.state.errorMessageSave !== null}
            title={'Error'}
            aria-labelledby="simple-dialog-title"
            fullWidth={true}
            maxWidth={'xs'}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    this.clearErrorMessageSave();
                }
            }}
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.state.errorMessageSave}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button size="large" color="primary" variant="contained" className="tc-jsw-margin-right" onClick={this.clearErrorMessageSave}>{i18n.t('datasource.editor.test.close')}</Button>
            </DialogActions>
        </Dialog>;
    }

    public render() {
        let content = null;
        if (this.state.initializingComponent) {
            content = <div className="tc-jsw-center-in-div"><UXPLSpinner /></div>;
        } else {
            content = [this.getPage()];
        }
        let loadingScreen = null;
        if (this.state.pageLoading) {
            loadingScreen = <div style={{ display: 'flex', position: 'absolute', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 100 }}>
                <UXPLSpinner message={i18n.t('datasource.editor.loading')} />
            </div>
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flex: 1, position: 'relative' }}>
                <ToolBar toolbarData={this.state.toolbarData} />
                <div className="tc-dataset-jsw-wizard" style={{ display: 'flex', flex: 1 }}>
                    {content}
                    {this.getSuccessMessageDialog()}
                    {this.getErrorMessageSaveDialog()}
                </div>
                {loadingScreen}
            </div>
        );
    }

}

InternalDataSourceEditor.contextType = RunContext;


export default InternalDataSourceEditor;