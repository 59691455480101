/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const BarChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="802"
      height="493"
      version="1.1"
      style={{
         WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
         WebkitUserSelect: "none",
         MozUserSelect: "none",
         userSelect: "none",
         position: "relative",
      }}
      viewBox="0 0 802 493"
      cursor="default"
      overflow="hidden"
      {...props}
   >
      <defs>
         <linearGradient
            id="19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100"
            x1="0"
            x2="0"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#cbcbcb" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#e9e9e9" stopOpacity="0.5"></stop>
         </linearGradient>
         <clipPath id="08AB7166-78A6-4E85-ADFC-2F4947BE92DA">
            <path d="M59 55H784V444H59z"></path>
         </clipPath>
         <linearGradient
            id="19-540-rgba_175_216_248_1_-rgba_255_255_255_1_"
            x1="1"
            x2="0"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#afd8f8"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-540-rgba_246_189_15_1_-rgba_255_255_255_1_"
            x1="1"
            x2="0"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#f6bd0f"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-540-rgba_139_186_0_1_-rgba_255_255_255_1_"
            x1="1"
            x2="0"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#8bba00"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-540-rgba_255_142_70_1_-rgba_255_255_255_1_"
            x1="1"
            x2="0"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#ff8e46"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-540-rgba_0_142_142_1_-rgba_255_255_255_1_"
            x1="1"
            x2="0"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#008e8e"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-540-rgba_214_70_70_1_-rgba_255_255_255_1_"
            x1="1"
            x2="0"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#d64646"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-540-rgba_142_70_142_1_-rgba_255_255_255_1_"
            x1="1"
            x2="0"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#8e468e"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-540-rgba_88_133_38_1_-rgba_255_255_255_1_"
            x1="1"
            x2="0"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#588526"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
      </defs>
      <g className="raphael-group-20-background">
         <rect
            width="800"
            height="491"
            x="1"
            y="1"
            fill="url('#19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100')"
            rx="0"
            ry="0"
         ></rect>
         <rect
            width="801"
            height="492"
            x="0.5"
            y="0.5"
            fill="none"
            stroke="#767575"
            strokeOpacity="0.5"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-27-canvas">
         <rect
            width="727"
            height="391"
            x="58"
            y="54"
            fill="none"
            stroke="#545454"
            strokeWidth="2"
            rx="0"
            ry="0"
         ></rect>
         <rect
            width="725"
            height="389"
            x="59"
            y="55"
            fill="#FFF"
            strokeWidth="0"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-30-axisbottom">
         <g
            fill="#767575"
            fillOpacity="0.1"
            stroke="#000"
            strokeWidth="0"
            className="raphael-group-39-x-axis-bands"
         >
            <rect width="145" height="389" x="204" y="55" rx="0" ry="0"></rect>
            <rect width="145" height="389" x="494" y="55" rx="0" ry="0"></rect>
         </g>
         <g
            fill="none"
            stroke="#717170"
            strokeOpacity="0.4"
            className="raphael-group-41-x-axis-lines"
            shapeRendering="crispedges"
         >
            <path d="M204 55v389M349 55v389M494 55v389M639 55v389"></path>
         </g>
         <g className="fusioncharts-xaxis-0-gridlabels">
            <text
               x="53"
               y="79.313"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="53" dy="4">
                  AK
               </tspan>
            </text>
            <text
               x="53"
               y="127.938"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="53" dy="4">
                  CA
               </tspan>
            </text>
            <text
               x="53"
               y="176.563"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="53" dy="4">
                  ID
               </tspan>
            </text>
            <text
               x="53"
               y="225.188"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="53" dy="4">
                  MT
               </tspan>
            </text>
            <text
               x="53"
               y="273.813"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="53" dy="4">
                  NM
               </tspan>
            </text>
            <text
               x="53"
               y="322.438"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="53" dy="4">
                  OR
               </tspan>
            </text>
            <text
               x="53"
               y="371.063"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="53" dy="4">
                  WA
               </tspan>
            </text>
            <text
               x="53"
               y="419.688"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="53" dy="4">
                  WY
               </tspan>
            </text>
         </g>
         <g className="fusioncharts-yaxis-0-gridlabels">
            <text
               x="59"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="59" dy="-2">
                  0
               </tspan>
            </text>
            <text
               x="204"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="204" dy="-2">
                  8
               </tspan>
            </text>
            <text
               x="349"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="349" dy="-2">
                  16
               </tspan>
            </text>
            <text
               x="494"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="494" dy="-2">
                  24
               </tspan>
            </text>
            <text
               x="639"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="639" dy="-2">
                  32
               </tspan>
            </text>
            <text
               x="784"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="784" dy="-2">
                  40
               </tspan>
            </text>
         </g>
         <g className="raphael-group-50-axis-name">
            <text
               fill="#555"
               className="fusioncharts-yaxis-0-title"
               fontSize="10"
               textAnchor="middle"
               transform="translate(421.5 465)"
            >
               <tspan x="0" dy="10">
                  Orders
               </tspan>
            </text>
            <text
               fill="#555"
               className="fusioncharts-xaxis-0-title"
               fontSize="10"
               textAnchor="middle"
               transform="rotate(-90 133.75 115.75)"
            >
               <tspan x="0" dy="10">
                  States
               </tspan>
            </text>
         </g>
      </g>
      <g className="raphael-group-21-dataset">
         <g
            className="raphael-group-73-bars"
            clipPath="url('#08AB7166-78A6-4E85-ADFC-2F4947BE92DA')"
         >
            <rect
               width="181"
               height="39"
               x="59.5"
               y="59.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="181"
               height="39"
               x="59.5"
               y="59.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="181"
               height="39"
               x="59.5"
               y="59.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="181"
               height="39"
               x="59.5"
               y="59.5"
               fill="url('#19-540-rgba_175_216_248_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="72"
               height="39"
               x="59.5"
               y="108.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="72"
               height="39"
               x="59.5"
               y="108.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="72"
               height="39"
               x="59.5"
               y="108.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="72"
               height="39"
               x="59.5"
               y="108.5"
               fill="url('#19-540-rgba_246_189_15_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="561"
               height="39"
               x="59.5"
               y="157.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="561"
               height="39"
               x="59.5"
               y="157.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="561"
               height="39"
               x="59.5"
               y="157.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="561"
               height="39"
               x="59.5"
               y="157.5"
               fill="url('#19-540-rgba_139_186_0_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="54"
               height="39"
               x="59.5"
               y="205.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="54"
               height="39"
               x="59.5"
               y="205.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="54"
               height="39"
               x="59.5"
               y="205.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="54"
               height="39"
               x="59.5"
               y="205.5"
               fill="url('#19-540-rgba_255_142_70_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="326"
               height="39"
               x="59.5"
               y="254.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="326"
               height="39"
               x="59.5"
               y="254.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="326"
               height="39"
               x="59.5"
               y="254.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="326"
               height="39"
               x="59.5"
               y="254.5"
               fill="url('#19-540-rgba_0_142_142_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="507"
               height="39"
               x="59.5"
               y="302.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="507"
               height="39"
               x="59.5"
               y="302.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="507"
               height="39"
               x="59.5"
               y="302.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="507"
               height="39"
               x="59.5"
               y="302.5"
               fill="url('#19-540-rgba_214_70_70_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="344"
               height="39"
               x="59.5"
               y="351.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="344"
               height="39"
               x="59.5"
               y="351.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="344"
               height="39"
               x="59.5"
               y="351.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="344"
               height="39"
               x="59.5"
               y="351.5"
               fill="url('#19-540-rgba_142_70_142_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="163"
               height="39"
               x="59.5"
               y="400.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="163"
               height="39"
               x="59.5"
               y="400.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="163"
               height="39"
               x="59.5"
               y="400.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="163"
               height="39"
               x="59.5"
               y="400.5"
               fill="url('#19-540-rgba_88_133_38_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
         </g>
      </g>
      <g className="fusioncharts-datalabels">
         <text
            x="236.5"
            y="79"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="236.5" dy="3.25">
               10
            </tspan>
         </text>
         <text
            x="127.5"
            y="128"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="127.5" dy="3.25">
               4
            </tspan>
         </text>
         <text
            x="616.5"
            y="177"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="616.5" dy="3.25">
               31
            </tspan>
         </text>
         <text
            x="109.5"
            y="225"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="109.5" dy="3.25">
               3
            </tspan>
         </text>
         <text
            x="381.5"
            y="274"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="381.5" dy="3.25">
               18
            </tspan>
         </text>
         <text
            x="562.5"
            y="322"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="562.5" dy="3.25">
               28
            </tspan>
         </text>
         <text
            x="399.5"
            y="371"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="399.5" dy="3.25">
               19
            </tspan>
         </text>
         <text
            x="218.5"
            y="420"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="218.5" dy="3.25">
               9
            </tspan>
         </text>
      </g>
      <g className="raphael-group-122-caption">
         <text
            x="421.5"
            y="16"
            fill="#555"
            className="fusioncharts-caption"
            fontFamily="Verdana, sans"
            fontSize="13"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="421.5" dy="12.992">
               Chart Caption
            </tspan>
         </text>
         <text
            x="421.5"
            y="34"
            fill="#555"
            className="fusioncharts-subcaption"
            fontFamily="Verdana, sans"
            fontSize="11"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="421.5" dy="10.852">
               Chart Sub Caption
            </tspan>
         </text>
      </g>
      <g
         fill="silver"
         fillOpacity="0"
         stroke="silver"
         strokeOpacity="0"
         className="raphael-group-22-hot"
      >
         <rect width="181" height="38.9" x="59.5" y="59.5" rx="0" ry="0"></rect>
         <rect width="72" height="38.9" x="59.5" y="108.5" rx="0" ry="0"></rect>
         <rect width="561" height="38.9" x="59.5" y="157.5" rx="0" ry="0"></rect>
         <rect width="54" height="38.9" x="59.5" y="205.5" rx="0" ry="0"></rect>
         <rect width="326" height="38.9" x="59.5" y="254.5" rx="0" ry="0"></rect>
         <rect width="507" height="38.9" x="59.5" y="302.5" rx="0" ry="0"></rect>
         <rect width="344" height="38.9" x="59.5" y="351.5" rx="0" ry="0"></rect>
         <rect width="163" height="38.9" x="59.5" y="400.5" rx="0" ry="0"></rect>
      </g>
   </svg>)
};

export default BarChartIcon;