/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../ui/APDescriptor";
import { CalculationEnum } from "../enums/CalculationEnum";
import { IncrementTypeEnum } from "../enums/IncrementTypeEnum";
import { ResetTypeEnum } from "../enums/ResetTypeEnum";
import { PExpression } from "../../ui/primitive/UPExpression";

import { PJavaClass } from "../../ui/primitive/UPJavaClass";
import { PString } from "../../ui/primitive/UPString";
import { PTableElement } from "../../ui/tables/UPTableElement";
import VariableIcon from "../../../../../assets/icons/outline/variables.svg";
import { DefaultClasses } from "./MParameter";
import { PUniqueName } from "../../ui/primitive/UPUniqueName";

export const FVariable = {
    name: new PUniqueName({ id: 'name', label: 'Name', canBeEmpty: false, canBeNull: false }),
    variableDescription: new PString({ id: 'variableDescription', label: 'Description' }),
    class: new PJavaClass({ id: 'class', label: 'Class', items: [...DefaultClasses] }),
    variableExpression: new PExpression({ id: 'variableExpression', label: 'Expression' }),
    initialValueExpression: new PExpression({ id: 'initialValueExpression', label: 'Initial Value Expression' }),
    incrementerFactoryClass: new PJavaClass({ id: 'incrementerFactoryClass', label: 'Incrementer Factory Class' }),
    incrementType: IncrementTypeEnum,
    resetType: ResetTypeEnum,
    calculation: CalculationEnum
}

export const MVariable: APDescriptor = new PTableElement({
    label: 'Variable',
    icon: VariableIcon,
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    layouts: [FVariable.name, FVariable.variableDescription, FVariable.class, FVariable.calculation, FVariable.variableExpression, FVariable.initialValueExpression, FVariable.incrementType, FVariable.incrementerFactoryClass, FVariable.resetType]
});
