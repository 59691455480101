export const en = {
	datasource: {
		editor: {
			test: "Test"
		}
	},
	text: {
		editor: {
			test: {
				label: "Test",
				error: "Error",
				cancel: "Cancel",
				success: "Data Source successfully validated",
				fail: "The Data Source test failed, but no reason has been provided.",
				tryAgain: "Try Again",
				close: "Close"
			},
			testTooltip: "This will perform a test on data adapter",
			help: {
				tooltip: "Help"
			},
			format: {
				tooltip: "Format"
			},
			savedialogtitle: "Save File As...",
			unrecognizedResource: "UNRECOGNIZED RESOURCE TYPE",
			noPermission: "There are no permissions on this file"
		}
	},
	jrioContextEditorView: {
		basename: {
			text: "New JRIO Context"
		},
		tabs: {
			editor: "Editor",
			source: "Source"
		}
	},
	jrioContextEditor: {
		propertytable: {
			title: "Properties",
			property: {
				name: "Name",
				value: "Value"
			}
		},
		classpathList: {
			title: "Classpath",
			jar: "Jar"
		},
		parentContext: {
			title: "Parent Context",
			location: "Context location",
			dialog: {
				title: "Select the Parent Context Resource",
				resource: "Resource"
			}
		},
		classpath: {
			dialog: {
				title: "Select the Classpath Resource",
				resource: "Resource"
			}
		},
		saveAs: {
			name: "JRIO Context name",
			title: "Save JRIO Context As...",
			saveOk: "JRIO Context Correctly Saved"
		},
		save: {
			title: "Save JRIO Context"
		},
		validators: {
			contextName: "The Parent Context path must contain a folder JR-INF with a proper context.xml file.",
			xmlBroken: "A problem occurred while loading the JRIO context file. Check the XML source tab.",
			pathNotFound: "The path specified is not a valid resource in the repository"
		}
	}
}