import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25.3}
    height={25.3}
    viewBox="0 0 25.3 25.3"
    preserveAspectRatio="none"
    {...props}
  >
    <g
      style={{
        fill: "#000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "#000",
        strokeWidth: 1,
        strokeLinecap: "square",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        strokeOpacity: 1,
      }}
      stroke="none"
    >
      <path
        style={{
          fill: "#fff",
          stroke: "none",
        }}
        d="M5 5.975h100v100H5z"
        transform="translate(-1.265 -1.265) scale(.25304)"
      />
      <path
        style={{
          fill: "#900",
          stroke: "none",
        }}
        d="M5 85.243v20.732h100V65.404L83.062 43.465 62.553 63.974l-5.657-5.657 11.666-11.664-12.486-12.486z"
        transform="translate(-1.265 -1.265) scale(.25304)"
      />
      <path
        style={{
          fill: "#c00",
          stroke: "none",
        }}
        d="m61.724 28.52-5.648-5.649.009-.008 18.133 18.132 8.883-8.882 5.657 5.656-.04.04L105 54.09V5.975H5V73.93l51.067-51.067z"
        transform="translate(-1.265 -1.265) scale(.25304)"
      />
    </g>
  </svg>
)

export default SvgComponent
