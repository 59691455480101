/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PDiv } from "../../ui/composite/UPDiv";
import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { APDescriptor } from "../../ui/APDescriptor";
import { FAppearance, MBorders } from "../common/MAppearance";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PInt } from "../../ui/primitive/UPInteger";
import { MTableCellCustom } from "./MTableCellCustom";
import { MTableColumnCustom } from "./MTableColumnCustom";
export const FTableCell = {
    printWhenExpression: new PExpression({ id: 'printWhenExpression', label: 'Print When' }),
    rowSpan: new PInt({id: 'rowSpan', label: 'Row Span'})
};

export const MTableCell: APDescriptor = {
    type: 'table.cell',
    label: 'Table Cell',
    id: 'tableCellProeprtiesDescriptor',
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [FAppearance.style, MBorders, MTableCellCustom, MTableColumnCustom] }),
            new PDiv({
                label: 'Cell',
                layouts: [FTableCell.printWhenExpression, FTableCell.rowSpan]
            })
        ]
    })]
};
