/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { DatasourceJRS } from '../jrs/DatasourceJRS';
import { JobResult } from '../repo/IRepositoryAPI';
import { DatasourceREST } from '../rest/DatasourceREST';
import { IDatasource } from './IDatasource';


export class DatasourceApi {

    private static instance: DatasourceApi = new DatasourceApi();
    private current: IDatasource = new DatasourceREST();

    public static inst(): DatasourceApi {
        return this.instance;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public setBase = (base: string, userID: string) => {
        if (base.startsWith('jrs:')) {
            this.current = new DatasourceJRS();
        } else {
            this.current = new DatasourceREST();
        }
    }

    public getSupportedDatasourceTypes = (): JobResult => {
        return this.current.getSupportedDatasourceTypes();
    }

    public testDataAdapter = (path: string, da: string): JobResult => {
        return this.current.testDataAdapter(path, da);
    }

    public getMetadata = (path: string, da: string): JobResult => {
        return this.current.getMetadata(path, da);
    }

    public readFields = (path: string, params: { key: string, value: any }[]): JobResult => {
        return this.current.readFields(path, params);
    }

    public runQuery = (path: string, params: { key: string, value: any }[]): JobResult => {
        return this.current.runQuery(path, params);
    }

    public evaluateParameters = (path: string, params: { key: string, value: any }[]): JobResult => {
        return this.current.evaluateParameters(path, params);
    }

    public hasTest = (): boolean => {
        return this.current.hasTest();
    }

    public getReportLists = (path: string): JobResult => {
        return this.current.getReportLists(path);
    }

    public getReportDatasetsCustomProperties = (path: string): JobResult => {
        return this.current.getReportDatasetsCustomProperties(path);
    }

    public resourceExists = (ref: string, path: string): JobResult => {
        return this.current.resourceExists(ref, path);
    }

}

