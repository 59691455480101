/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MBoxGraphicElement } from "../common/MGraphicElement";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { ICONS } from "@jss/js-common";
import { MDivElementDataset } from "../dataset/MDatasetRun";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PInt } from "../../ui/primitive/UPInteger";
import { RunDirectionEnum } from "../enums/RunDirectionEnum";
import { HorizontalPositionEnum } from "../enums/HorizontalPositionEnum";
import { MCrosstabCustom } from "./MCrosstabCustom";
import { List } from 'immutable';

export const FCrosstab = {
    isRepeatColumnHeaders: new PBoolean({ id: 'isRepeatColumnHeaders', label: 'Repeat Column Headers' }),
    isRepeatRowHeaders: new PBoolean({ id: 'isRepeatRowHeaders', label: 'Repeat Row Headers' }),
    ignoreWidth: new PBoolean({ id: 'ignoreWidth', label: 'Ignore Width', canBeNull: true }),
    columnBreakOffset: new PInt({ id: 'columnBreakOffset', label: 'Column Break Offset' }),
    runDirection: RunDirectionEnum,
    horizontalPosition: HorizontalPositionEnum
};

export const MCrosstab: APDescriptor = {
    type: ElementTypes.CROSSTAB,
    label: 'Crosstab',
    id: 'crosstabProeprtiesDescriptor',
    default: { width: 200, height: 200, elementIds: List<string>(), dataset: { resetType: 'Report' }},
    icon: ICONS.CROSSTAB_ICON,
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MBoxGraphicElement.layouts, MCrosstabCustom] }),
            new PDiv({ label: 'Crosstab', layouts: [FCrosstab.isRepeatColumnHeaders, FCrosstab.isRepeatRowHeaders, FCrosstab.ignoreWidth, FCrosstab.columnBreakOffset, FCrosstab.runDirection, FCrosstab.horizontalPosition, MCrosstabCustom] }),
            { ...MDivElementDataset, path: 'dataset' }
        ]
    })]
};
