/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import i18n from "../i18n";

/**
 * Validates the name of a folder.
 * It returns an error string if the path is not valid, otherwise null
 * 
 * TODO: Transform this to a more generic name validator.
 * 
 * @param path
 */

const httpRegExpt = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

const folderNameRegExp = /^([a-zA-Z0-9][^*%$!#ˆ&@/><?\|:]*)$/;

const intPattern = /^-?\d*$/;

export enum VALIDATION_RESULT { VALID, INVALID, PARTIALLY_INVALID }

export interface ValidationResult {
    message?: string | undefined,
    result: VALIDATION_RESULT
}

export function validatePositiveInteger(value: string) : ValidationResult {
    if (!intPattern.test(value)) {
        return { message: i18n.t('datasource.record.notanumbererror'), result: VALIDATION_RESULT.INVALID };
    }
    if (!value || value.trim().length === 0){
        return { message: i18n.t('datasource.record.notanumbererror'), result: VALIDATION_RESULT.VALID };
    }
    const number = parseInt(value, 10);
    if (isNaN(number)) {
        return { message: i18n.t('datasource.record.notanumbererror'), result: VALIDATION_RESULT.INVALID };
    }
    if (number < 0) {
        return { message: i18n.t('datasource.record.negativeerror'), result: VALIDATION_RESULT.INVALID };
    }
    if (number > 2147483647) {
        return { message: i18n.t('datasource.record.valuetoobigerror'), result: VALIDATION_RESULT.VALID };
    }
    return { result: VALIDATION_RESULT.VALID };
}

export function validateFolderName(name: string): string | null {
    name = name.trim();
    // if (name.indexOf('/') >= 0) {
    //     return "Folder name cannot contain the '/' character.";
    // }
    const regExp = new RegExp("uuid:[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}");
    if (regExp.test(name)) {
        return i18n.t('validators.folderNameInvalid');
    }
    return null;
}

export function advancedFolderNameValidator(name: string): ValidationResult {
    if (name){
        name = name.trim();
        // if (name.indexOf('/') >= 0) {
        //     return "Folder name cannot contain the '/' character.";
        // }
        const regExp = new RegExp("uuid:[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}");
        if (regExp.test(name)) {
            return { result: VALIDATION_RESULT.VALID, message: i18n.t('validators.folderNameInvalid') };
        }
    }
    return { result: VALIDATION_RESULT.VALID };
}

export function advancedNotEmptyFolderNameValidator(name: string | undefined): ValidationResult {
    if (!name || name.trim().length === 0){
        return { result: VALIDATION_RESULT.VALID, message: i18n.t('validators.folderNameEmpty') };
    }
    name = name.trim();
    // if (name.indexOf('/') >= 0) {
    //     return "Folder name cannot contain the '/' character.";
    // }
    if (!folderNameRegExp.test(name)) {
        return { result: VALIDATION_RESULT.VALID, message: i18n.t('validators.folderNameInvalid') };
    }
    return { result: VALIDATION_RESULT.VALID };
}

export function httpValidator(name: string | undefined): ValidationResult {
    if (name){
        if (!httpRegExpt.test(name)) {
            return {result: VALIDATION_RESULT.PARTIALLY_INVALID, message: i18n.t('validators.httperror')};
        }
    }
    return {result: VALIDATION_RESULT.VALID};
}

export const nameValidator = (value: string | undefined) => {
    if (!value || value.trim().length === 0) {
        return i18n.t('validator.name.invalidName');
    }
    return undefined;
}

export const advancedNameValidator = (value: string | undefined): ValidationResult => {
    if (!value || value.trim().length === 0) {
        return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.name.invalidName') };
    }
    return { result: VALIDATION_RESULT.VALID };
}

export const advancedNotEmptyValidator = (value: string | undefined): ValidationResult => {
    if (!value || value === null || value.trim().length === 0) {
        return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.value.isEmpty') };
    }
    return { result: VALIDATION_RESULT.VALID };
}

export const advancendNotEmptyClassNameValidator = (value: string | undefined) : ValidationResult => {
    if (!value || value === null || value.trim().length === 0) {
        return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.value.isEmpty') };
    } else {
        const classnameInvalidMessage = classNameValidator(value);
        if (classnameInvalidMessage){
            return { result: VALIDATION_RESULT.VALID, message: classnameInvalidMessage };   
        }
    }
    return { result: VALIDATION_RESULT.VALID };
}

export const advancendNotEmptyMethodNameValidator = (value: string | undefined) : ValidationResult => {
    if (!value || value === null || value.trim().length === 0) {
        return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.value.isEmpty') };
    } else {
        const methodInvalidMessage = methodNameValidator(value);
        if (methodInvalidMessage){
            return { result: VALIDATION_RESULT.VALID, message: methodInvalidMessage };   
        }
    }
    return { result: VALIDATION_RESULT.VALID };
}


export const notEmptyValidator = (value: string | undefined) => {
    if (!value || value === null || value.trim().length === 0) {
        return i18n.t('validator.value.isEmpty');
    }
    return undefined;
}


export const isValidUrl = (url) => {
    try {
        new URL(url);
    } catch (e) {
        //console.error(e);
        return false;
    }
    return true;
};

export const urlValidator = (value: string | undefined) => {
    if (!value || value.trim().length === 0) {
        return i18n.t('validator.url.invalidURLorPath');
    } else {
        const splittedvalue = value.split('://')
        if (splittedvalue.length !== 2) {
            //it could be a path
            const isInvalidPath = /\\{2}|\/{2}|[,*,<,>,",|,?,:]/.test(value);
            if (isInvalidPath) {
                return i18n.t('validator.url.invalidPath');
            }
        } else {
            //it is an url validate it
            if (!isValidUrl(value)) {
                return i18n.t('validator.url.invalidURL');
            }
        }
    }
    return undefined;
}

export const advancedUrlValidator = (value: string | undefined): ValidationResult => {
    if (!value || value.trim().length === 0) {
        return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.url.invalidURLorPath') };
    } else {
        const splittedvalue = value.split('://')
        if (splittedvalue.length !== 2) {
            //it could be a path
            const isInvalidPath = /\\{2}|\/{2}|[,*,<,>,",|,?,:]/.test(value);
            if (isInvalidPath) {
                return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.url.invalidPath') };
            }
        } else {
            //it is an url validate it
            if (!isValidUrl(value)) {
                return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.url.invalidURL') };
            }
        }
    }
    return { result: VALIDATION_RESULT.VALID };
}

export const classNameValidator = (value: string | undefined) => {
    if (value) {
        const regexp = /[\s\<\>\#\(\)\ˆ\{\}\[\]\;\/\,\"\'\`\?\!\:\&\@\-\^\%\&\*\+\=\˜]/;
        if (value.match(regexp)) {
            return i18n.t('datasource.jdbc.className');
        }
    }
    return undefined;
}

export const notEmptyClassNameValidator = (value: string | undefined) => {
    if (value && value !== null && value.trim().length > 0) {
        return classNameValidator(value);
    }
    return i18n.t('validator.value.isEmpty');
}

export const methodNameValidator = (value: string | undefined) => {
    if (value) {
        const regexp = /[\s\<\>\#\(\)\ˆ\{\}\[\]\;\/\,\"\'\`\?\!\:\&\@\-\^\.\%\&\*\+\=\˜]/;
        if (value.match(regexp)) {
            return i18n.t('datasource.jdbc.methodName');
        }
    }
    return undefined;
}

export const notEmptyMethodNameValidator = (value: string | undefined) => {
    if (value && value !== null && value.trim().length > 0) {
        return methodNameValidator(value);
    }
    return i18n.t('validator.value.isEmpty');
}


export const pathValidator = (value: string | undefined) => {
    if (value) {
        const pattern = /[^a-z^A-Z^0-9\/_]/;
        if (value.match(pattern)) {
            return i18n.t('datasource.jdbc.pathName');
        }
    }
    return undefined;
}
