/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { APDescriptor } from "../../ui/APDescriptor";
import { MBookmarks } from "../common/MBookmarks";
import { MFont } from "../common/MFont";
import { MHyperlinks } from "../common/MHyperlinks";
import { EdgeEnum } from "../enums/EdgeEnum";
import { EvaluationTimeEnum } from "../enums/EvaluationTimeEnum";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PColor } from "../../ui/primitive/UPColor";
import { PEnum } from "../../ui/primitive/UPEnum";
import { PExpression } from "../../ui/primitive/UPExpression";
import AreaChartIcon from "../../../../../assets/icons/charts/area.svg";
import AreaXYChartIcon from "../../../../../assets/icons/charts/xy_area.svg";
import BarChartIcon from "../../../../../assets/icons/charts/bar.svg";
import BarXYChartIcon from "../../../../../assets/icons/charts/xy_bar.svg";
import Bar3DChartIcon from "../../../../../assets/icons/charts/bar_3d.svg";
import BubbleChartIcon from "../../../../../assets/icons/charts/bubble.svg";
import LineChartIcon from "../../../../../assets/icons/charts/line.svg";
import LineXYChartIcon from "../../../../../assets/icons/charts/xy_line.svg";
import PieChartIcon from "../../../../../assets/icons/charts/pie.svg";
import Pie3DChartIcon from "../../../../../assets/icons/charts/pie_3d.svg";
import ScatterChartIcon from "../../../../../assets/icons/charts/scatter.svg";
import StackedAreaChartIcon from "../../../../../assets/icons/charts/stacked_area.svg";
import StackedBarChartIcon from "../../../../../assets/icons/charts/stacked_bar_3d.svg";
import StackedBar3DChartIcon from "../../../../../assets/icons/charts/stacked_bar.svg";
import SpiderChartIcon from "../../../../../assets/icons/charts/spider_area.svg";
import CandleStickChartIcon from "../../../../../assets/icons/charts/candlestick.svg";
import GanttChartIcon from "../../../../../assets/icons/charts/gantt.svg";
import HighLowChartIcon from "../../../../../assets/icons/charts/highlow.svg";
import MeterChartIcon from "../../../../../assets/icons/charts/meter.svg";
import ThermometerChartIcon from "../../../../../assets/icons/charts/thermometer.svg";
import MultiAxisChartIcon from "../../../../../assets/icons/charts/multi_axis.svg";
import TimeSeriesChartIcon from "../../../../../assets/icons/charts/time_series.svg";

export const FChart = {
    evaluationTime: EvaluationTimeEnum,
    renderType: new PEnum({
        id: 'renderType', label: 'Render Type', writeable: true, isEmptyUndefined: true,
        items: [
            { key: '', value: '' },
            { key: 'draw', value: 'Draw' },
            { key: 'image', value: 'Image' },
            { key: 'svg', value: 'SVG' }]
    }),
    theme: new PEnum({ id: 'theme', label: 'Themes', writeable: true, items: [], inheritedPropertyId: 'net.sf.jasperreports.chart.theme' }),
    titleExpression: new PExpression({ id: 'titleExpression', label: 'Title' }),
    subtitleExpression: new PExpression({ id: 'subtitleExpression', label: 'Subtitle' }),
    color: new PColor({ id: 'color', label: 'Color' }),

    legendColor: new PColor({ id: 'textColor', label: 'Color' }),
    backgroundColor: new PColor({ id: 'backgroundColor', label: 'Background' }),

    isShowLegend: new PBoolean({ id: 'isShowLegend', label: 'Show Legend', canBeNull: true }),
    position: EdgeEnum
}

export const MChart: APDescriptor = {
    type: 'chart', id: 'chart',
    layouts: [new PAccordeon({
        id: 'chart.title', label: 'Title', path: 'chartTitle',
        layouts: [FChart.titleExpression, FChart.color, FChart.position, ...MFont.layouts]
    }), new PAccordeon({
        id: 'chart.subtitle', label: 'Subtitle', path: 'chartSubtitle',
        layouts: [FChart.subtitleExpression, FChart.color, FChart.position, ...MFont.layouts]
    }), new PAccordeon({
        id: 'chart.legend', label: 'Legend', path: 'chartLegend',
        layouts: [FChart.isShowLegend, FChart.legendColor, FChart.position, FChart.backgroundColor, ...MFont.layouts]
    }), new PAccordeon({
        id: 'chart.runtime', label: 'Runtime',
        layouts: [FChart.evaluationTime, FChart.renderType, FChart.theme]
    }),
        MBookmarks, MHyperlinks]
};


export const MAreaChart: APDescriptor = {
    type: 'area', id: 'areaChart', label: 'Area', icon: AreaChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'areaChart',
        categoryDataset: { dataset: { resetType: 'Report' }, categorySeries: [] },
        areaPlot: {}
    }
};
export const MStackedAreaChart: APDescriptor = {
    type: 'stacked', id: 'stackedAreaChart', label: 'Stacked Area', icon: StackedAreaChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'stackedAreaChart',
        categoryDataset: { dataset: { resetType: 'Report' }, categorySeries: [] },
        areaPlot: {}
    }
};
export const MXYAreaChart: APDescriptor = {
    type: 'xyArea', id: 'xyAreaChart', label: 'XY Area', icon: AreaXYChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'xyAreaChart',
        xyDataset: { dataset: { resetType: 'Report' }, xySeries: [] },
        areaPlot: {}
    }
};
export const MBarChart: APDescriptor = {
    type: 'bar', id: 'barChart', label: 'Bar', icon: BarChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'barChart',
        categoryDataset: { dataset: { resetType: 'Report' }, categorySeries: [] },
        barPlot: {}
    }
};
export const MBar3DChart: APDescriptor = {
    type: 'bar3D', id: 'bar3DChart', label: 'Bar 3D', icon: Bar3DChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'bar3DChart',
        categoryDataset: { dataset: { resetType: 'Report' }, categorySeries: [] },
        bar3DPlot: {}
    }
};
export const MXYBarChart: APDescriptor = {
    type: 'xyBar', id: 'xyBarChart', label: 'XY Bar', icon: BarXYChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'xyBarChart',
        categoryDataset: { dataset: { resetType: 'Report' }, categorySeries: [] },
        barPlot: {}
    }
};
export const MStackedBar3DChart: APDescriptor = {
    type: 'stacked3DBar', id: 'stackedBar3DChart', label: 'Stacked Bar 3D', icon: StackedBar3DChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'stackedBar3DChart',
        categoryDataset: { dataset: { resetType: 'Report' }, categorySeries: [] },
        bar3DPlot: {}
    }
};
export const MstackedBarChart: APDescriptor = {
    type: 'stackedBar', id: 'stackedBarChart', label: 'Stacked Bar', icon: StackedBarChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'stackedBarChart',
        categoryDataset: { dataset: { resetType: 'Report' }, categorySeries: [] },
        barPlot: {}
    }
};
export const MBubbleChart: APDescriptor = {
    type: 'bubble', id: 'bubbleChart', label: 'Bubble', icon: BubbleChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'bubbleChart',
        xyzDataset: { dataset: { resetType: 'Report' }, xyzSeries: [] },
        bubblePlot: {}
    }
};
export const MCandleStickChart: APDescriptor = {
    type: 'candleStick', id: 'candleStickChart', label: 'Candle Stick', icon: CandleStickChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'candleStick',
        categoryDataset: { dataset: { resetType: 'Report' }, categorySeries: [] },
        candleStickPlot: {}
    }
};
export const MGanttChart: APDescriptor = {
    type: 'gantt', id: 'ganttChart', label: 'Gantt', icon: GanttChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'ganttChart',
        ganttDataset: { dataset: { resetType: 'Report' }, ganttSeries: [] },
        ganttPlot: {}
    }
};
export const MHighLowChart: APDescriptor = {
    type: 'highlow', id: 'highLowChart', label: 'High Low', icon: HighLowChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'highlowChart',
        highLowDataset: { dataset: { resetType: 'Report' }, seriesExpression: '', dateExpression: '', highExpression: '', lowExpression: '', openExpression: '', closeExpression: '', volumeExpression: '' },
        highLowPlot: {}
    }
};
export const MLineChart: APDescriptor = {
    type: 'line', id: 'lineChart', label: 'Line', icon: LineChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'lineChart',
        categoryDataset: { dataset: { resetType: 'Report' }, categorySeries: [] },
        linePlot: {}
    }
};
export const MXYLineChart: APDescriptor = {
    type: 'xyLine', id: 'xyLineChart', label: 'XY Line', icon: LineXYChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'xyLineChart',
        xyDataset: { dataset: { resetType: 'Report' }, xySeries: [] },
        linePlot: {}
    }
};
export const MMeterChart: APDescriptor = {
    type: 'meter', id: 'meterChart', label: 'Meter', icon: MeterChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'meterChart', valueDataset: { dataset: { resetType: 'Report' }, valueExpression: '' }
    }
};
export const MMultiAxisChart: APDescriptor = {
    type: 'multiAxis', id: 'multiAxisChart', label: 'Multi Axis', icon: MultiAxisChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'multiAxisChart',
        multiAxisPlot: { plot: {}, axis: [] }
    }
};
export const MPie3DChart: APDescriptor = {
    type: 'pie3D', id: 'pie3DChart', label: 'Pie 3D', icon: Pie3DChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'pie3DChart', pieDataset: { dataset: { resetType: 'Report' }, pieSeries: [] }
    }
};
export const MPieChart: APDescriptor = {
    type: 'pie', id: 'pieChart', label: 'Pie', icon: PieChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'pieChart',
        pieDataset: { dataset: { resetType: 'Report' }, pieSeries: [] },
        pie3DPlot: {}
    }
};
export const MScatterChart: APDescriptor = {
    type: 'scatter', id: 'scatterChart', label: 'Scatter', icon: ScatterChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'scatterChart',
        xyDataset: { dataset: { resetType: 'Report' }, xySeries: [] },
        linePlot: {}
    }
};

export const MThermometerChart: APDescriptor = {
    type: 'thermometer', id: 'thermometerChart', label: 'Thermometer', icon: ThermometerChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'thermometerChart',
        valueDataset: { dataset: { resetType: 'Report' }, valueExpression: '' },
        thermometerPlot: {}
    }
};
export const MTimeSeriesChart: APDescriptor = {
    type: 'timeSeries', id: 'timeSeriesChart', label: 'Time Series', icon: TimeSeriesChartIcon,
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'timeSeriesChart',
        timeSeriesDataset: { dataset: { resetType: 'Report' }, timeSeries: [] },
        linePlot: {}
    }
};

export const MSpiderChart: APDescriptor = {
    type: 'spider', id: 'spiderChart', label: 'Spider', icon: SpiderChartIcon, path: 'spiderSettings',
    layouts: [...MChart.layouts],
    default: {
        type: 'JFREECHART', CHART_TYPE: 'spiderChart', SPIDERCHART_XMLNS_NAMESPACE: 'c',
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:c': "http://jasperreports.sourceforge.net/jasperreports/components",
        spiderDataset: { dataset: { resetType: 'Report' }, categorySeries: [] },
        spiderPlot: {}
    }
};