/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import React, { useCallback, useState } from "react";
import { ErrorDescriptor } from "@jss/js-rest-api";


export interface IBookmark {
    anchor: string;
    page: number;
    elementAddress: string;
    bookmarks: Array<IBookmark> | null;
}

export interface IReportPart {
    name: string;
    page: number;
}

export interface ISearchResult {
    page: number;
    hitCount: number;
}

export interface IViewerState {
    reportInstance: any,
    containerClassName: string | null;
    currentPage: number;
    totalPages: number | null;
    canUndo: boolean;
    canRedo: boolean;
    zoomScale: number;
    zoomLiteral: string | null;
    isExporting: boolean;
    isLoading: boolean;
    isPageLoading: boolean;
    reportError: ErrorDescriptor | null;
    bookmarks: Array<IBookmark> | null;
    reportParts: Array<IReportPart> | null;
    isSearching: boolean;
    searchResults: Array<ISearchResult> | null;
    beforeActions: Array<string> | null;
}

const initialViewerState: IViewerState = {
    reportInstance: null,
    containerClassName: null,
    currentPage: 1,
    totalPages: null,
    canUndo: false,
    canRedo: false,
    zoomScale: 1,
    zoomLiteral: null,
    isExporting: false,
    isLoading: false,
    isPageLoading: false,
    reportError: null,
    bookmarks: null,
    reportParts: null,
    isSearching: false,
    searchResults: null,
    beforeActions: null
};

export interface IViewerStore extends IViewerState {
    reset: () => void;
    setReportInstance: (reportInstance: any) => void;
    setContainerClassName: (containerClassName: string) => void;
    setCurrentPage: (currentPage: number) => void;
    setTotalPages: (totalPages: number) => void;
    setCanUndo: (canUndo: boolean) => void;
    setCanRedo: (canRedo: boolean) => void;
    goToPage: (page: number) => Promise<void>;
    goToAnchor: ({ page, anchor }: { page: number, anchor: string }) => void;
    goToFirstPage: () => void;
    goToPreviousPage: () => void;
    goToNextPage: () => void;
    goToLastPage: () => void;
    hasPages: () => boolean;
    undo: () => void;
    redo: () => void;
    undoAll: () => void;
    zoomIn: () => void;
    zoomOut: () => void;
    zoomTo: (zoomScale: number | string) => void;
    exportTo: (exportFormat: string) => Promise<string>;
    setIsLoading: (isLoading: boolean) => void;
    setIsPageLoading: (isPageLoading: boolean) => void;
    setReportError: (reportError: ErrorDescriptor) => void;
    hasError: () => boolean;
    setBookmarks: (bookmarks: Array<IBookmark>) => void;
    setReportParts: (reportParts: Array<IReportPart>) => void;
    applyReportParameters: (reportParameters: Record<string, unknown>) => void;
    searchReport: (text: string, caseSensitive: boolean, wholeWordsOnly: boolean) => Promise<void>;
    hasSearchResults:() => boolean;
    setSearchResults: (searchResults: Array<ISearchResult> | null) => void;
    setBeforeActions: (beforeActions: Array<string> | null) => void;
}

export const ViewerStoreContext = React.createContext<IViewerStore>(null);

export const useViewerStore = (errorHandler: (err: string) => void): IViewerStore => {
    const [viewerState, setViewerState] = useState<IViewerState>(initialViewerState);

    const reset = useCallback(
        (): void => {
            setViewerState(initialViewerState);
        }, []);

    const setReportInstance = useCallback(
        (reportInstance: any): void => {
            setViewerState(prevState => {
                return { ...prevState, reportInstance};
            });
        }, []);

    const setContainerClassName = useCallback(
        (containerClassName: string): void => {
            setViewerState(prevState => {
                return { ...prevState, containerClassName};
            });
        }, []);

    const setCurrentPage = useCallback(
        (currentPage: number): void => {
            setViewerState(prevState => {
                return { ...prevState, currentPage };
            });
        }, []);

    const goToPage = useCallback(
        (page: number): Promise<void> => {
            return new Promise((resolve, reject) => {
                if (page <= viewerState.totalPages) {
                    setViewerState(prevState => {
                        return {...prevState, isPageLoading: true};
                    });
                    viewerState.reportInstance
                        .pages(page)
                        .run()
                        .done(() => {
                            setViewerState(prevState => {
                                return {...prevState, currentPage: page};
                            });
                            resolve();
                        })
                        .fail(e => {
                            setViewerState(prevState => {
                                return {...prevState, isPageLoading: false};
                            });
                            errorHandler(e);
                            reject(e);
                        });
                } else {
                    reject(Error("Requested page is greater than total pages!"));
                }
            });
        }, [viewerState.totalPages, viewerState.reportInstance, errorHandler]);

    const goToAnchor = useCallback(
        ({ page, anchor }: { page: number, anchor: string }): void => {
            const pageOptions: Record<any, any> = {};
            if (page != null) {
                pageOptions.pages = page;
            }
            if (anchor != null) {
                pageOptions.anchor = anchor;
            }
            viewerState.reportInstance
                .pages(pageOptions)
                .run()
                .fail((e) => errorHandler(e));
        }, [viewerState.reportInstance, errorHandler]);

    const goToFirstPage = useCallback(
        (): void => {
            goToPage(1);
        }, [goToPage]);

    const goToPreviousPage = useCallback(
        (): void => {
            const previousPage = viewerState.currentPage - 1;
            goToPage(previousPage);
        }, [viewerState.currentPage, goToPage]);

    const goToNextPage = useCallback(
        (): void => {
            const nextPage = viewerState.currentPage + 1;
            goToPage(nextPage);
        }, [viewerState.currentPage, goToPage]);

    const goToLastPage = useCallback(
        (): void => {
            goToPage(viewerState.totalPages);
        }, [goToPage, viewerState.totalPages]);

    const hasPages = useCallback(
        (): boolean => {
            return viewerState.totalPages !== null && viewerState.totalPages > 0;
        }, [viewerState.totalPages]);

    const setTotalPages = useCallback(
        (totalPages: number): void => {
            setViewerState(prevState => {
                return { ...prevState, totalPages };
            });
        }, []);

    const setCanUndo = useCallback(
        (canUndo: boolean): void => {
            setViewerState(prevState => {
                return { ...prevState, canUndo };
            });
        }, []);

    const setCanRedo = useCallback(
        (canRedo: boolean): void => {
            setViewerState(prevState => {
                return { ...prevState, canRedo };
            });
        }, []);

    const undo = useCallback(
        (): void => {
            viewerState.reportInstance.undo();
        }, [viewerState.reportInstance]);

    const redo = useCallback(
        (): void => {
            viewerState.reportInstance.redo();
        }, [viewerState.reportInstance]);

    const undoAll = useCallback(
        (): void => {
            viewerState.reportInstance.undoAll();
        }, [viewerState.reportInstance]);

    const zoomTo = useCallback(
        (zoomScale: number | string): void => {
            viewerState.reportInstance
                .scale(zoomScale)
                .resize()
                .done(function (computedZoomScale: number) {
                    const parsed = parseFloat(computedZoomScale.toFixed(2));
                    setViewerState(prevState => {
                        return {
                            ...prevState,
                            zoomScale: parsed,
                            zoomLiteral: typeof zoomScale === "string" ? zoomScale : null
                        };
                    });
                });
        }, [viewerState.reportInstance]);

    const zoomIn = useCallback(
        (): void => {
            zoomTo(viewerState.zoomScale + 0.2);
        }, [zoomTo, viewerState.zoomScale]);

    const zoomOut = useCallback(
        (): void => {
            zoomTo(Math.max(viewerState.zoomScale - 0.2, 0.1));
        }, [zoomTo, viewerState.zoomScale]);

    const exportTo = useCallback(
        (exportFormat: string): Promise<string> => {
            return new Promise((resolve, reject) => {
                setViewerState(prevState => {
                    return { ...prevState, isExporting: true };
                });
                viewerState.reportInstance.export({
                    outputFormat: exportFormat
                }).done((exportLink) => {
                    resolve(exportLink.href);
                }).fail((e) => reject(e)
                ).always(() => {
                    setViewerState(prevState => {
                        return { ...prevState, isExporting: false };
                    });
                });
            });

        }, [viewerState.reportInstance]);

    const setIsLoading = useCallback(
        (isLoading: boolean): void => {
            setViewerState(prevState => {
                return { ...prevState, isLoading };
            });
        }, []);

    const setIsPageLoading = useCallback(
        (isPageLoading: boolean): void => {
            setViewerState(prevState => {
                return { ...prevState, isPageLoading };
            });
        }, []);

    const setReportError = useCallback(
        (reportError: ErrorDescriptor): void => {
            setViewerState(prevState => {
                return { ...prevState, reportError };
            });
        }, []);

    const hasError = useCallback(
        (): boolean => {
            return viewerState.reportError !== null;
        }, [viewerState.reportError]);

    const setBookmarks = useCallback(
        (bookmarks: Array<IBookmark>): void => {
            setViewerState(prevState => {
                return { ...prevState, bookmarks };
            });
        }, []);

    const setReportParts = useCallback(
        (reportParts: Array<IReportPart>): void => {
            setViewerState(prevState => {
                return { ...prevState, reportParts };
            });
        }, []);

    const applyReportParameters = useCallback(
        (reportParameters: Record<string, unknown>): void => {
            setViewerState(prevState => {
                return { ...prevState, reportParameters }
            });

            viewerState.reportInstance
                .params(reportParameters)
                .refresh()
                .fail((e) => errorHandler(e));
        }, [viewerState.reportInstance, errorHandler]);

    const searchReport = useCallback(
        (text: string, caseSensitive: boolean, wholeWordsOnly: boolean): Promise<void> => {
            return new Promise((resolve, reject) => {
                setViewerState(prevState => { return { ...prevState, isSearching: true }; });
                viewerState.reportInstance.search({
                    text,
                    caseSensitive,
                    wholeWordsOnly
                })
                .done(searchResults => {
                    setViewerState(prevState => {
                        return { ...prevState, searchResults };
                    });
                    resolve();
                })
                .fail(jqXHR => {
                    let errMsg;
                    if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
                        errMsg = jqXHR.responseJSON.message;
                    } else {
                        errMsg = JSON.stringify(jqXHR);
                    }
                    setViewerState(prevState => {
                        return { ...prevState, searchResults: null };
                    });
                    errorHandler(errMsg);
                    reject(errMsg);
                })
                .always(() => {
                    setViewerState(prevState => { return { ...prevState, isSearching: false }; });
                });
            });
        }, [viewerState.reportInstance, errorHandler]);

    const hasSearchResults = useCallback(
        (): boolean => {
            return viewerState.searchResults !== null;
        }, [viewerState.searchResults]);

    const setSearchResults = useCallback(
        (searchResults: Array<ISearchResult> | null): void => {
            setViewerState(prevState => {
                return { ...prevState, searchResults };
            });
        }, []);

    const setBeforeActions = useCallback(
        (beforeActions: Array<string> | null): void => {
            setViewerState(prevState => {
                return { ...prevState, beforeActions };
            });
        }, []);

    return {
        ...viewerState,
        reset,
        setReportInstance,
        setContainerClassName,
        setCurrentPage,
        setTotalPages,
        setCanUndo,
        setCanRedo,
        goToPage,
        goToAnchor,
        goToFirstPage,
        goToPreviousPage,
        goToNextPage,
        goToLastPage,
        hasPages,
        undo,
        redo,
        undoAll,
        zoomIn,
        zoomOut,
        zoomTo,
        exportTo,
        setIsLoading,
        setIsPageLoading,
        setReportError,
        hasError,
        setBookmarks,
        setReportParts,
        applyReportParameters,
        searchReport,
        hasSearchResults,
        setSearchResults,
        setBeforeActions
    }
}
