import { createStore, combineReducers } from 'redux';
import persistentReducer from './reducers/persistentReducer';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import { fromJS } from 'immutable';
import routerReducer from './reducers/routerReducer';

const rootReducer = combineReducers({
  persistentReducer: persistentReducer,
  routerReducer: routerReducer,
});

const ImmutableTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    // convert an immutable to json
    let json;
    if (key === 'persistentReducer') {
      json = inboundState.storedData.toJS();
    }
    return json;
  },
  // transform state being rehydrated
  (outboundState, key) => {
    if (key === 'persistentReducer') {
      const immutable = fromJS(outboundState);
      return { storedData: immutable };
    }
  },
  // define which reducers this transform gets called for.
  { whitelist: ['persistentReducer'] },
);

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: 'root',
  // Storage Method (React Native)
  storage,
  // Whitelist (Save Specific Reducers)
  whitelist: [
    'persistentReducer',
  ],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    'routerReducer',
  ],
  transforms: [ImmutableTransform],
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux: Store
const store = createStore(
  persistedReducer
);

// Middleware: Redux Persist Persister
let persistor = persistStore(store);

// Exports
export {
  store,
  persistor,
};
