/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { APDescriptor } from "../../ui/APDescriptor";
import { PositionTypeEnum } from "../enums/PositionTypeEnum";
import { StretchTypeEnum } from "../enums/StretchTypeEnum";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PColor } from "../../ui/primitive/UPColor";
import { PConnectedSizeAndPosition } from "../../ui/primitive/UPConnectedSizePosition";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PInt } from "../../ui/primitive/UPInteger";
import { PMode } from "../../ui/primitive/UPMode";
import { PString } from "../../ui/primitive/UPString";
import { MAppearance } from "./MAppearance";
import { MColor } from "./MColor";
import { DsObject, PDsObject } from "../dataset/UPDsObject";
import { PDiv } from "../../ui/composite/UPDiv";


export const FGraphicElement = {
    x: new PConnectedSizeAndPosition({ id: 'x', label: 'Position X', min: 0, canBeEmpty: false }),
    y: new PConnectedSizeAndPosition({ id: 'y', label: 'Position Y', min: 0, canBeEmpty: false }),
    positionType: { ...PositionTypeEnum, className: 'span2' },

    width: new PConnectedSizeAndPosition({ id: 'width', label: 'Width', min: 0, canBeEmpty: false }),
    height: new PConnectedSizeAndPosition({ id: 'height', label: 'Height', min: 0, canBeEmpty: false }),
    stretchType: { ...StretchTypeEnum, className: 'span2' },

    forecolor: new PColor({ id: 'forecolor', label: 'Forecolor', inheritedPropertyId: 'forecolor', allowTransparent: true }),
    backcolor: new PColor({ id: 'backcolor', label: 'Backcolor', inheritedPropertyId: 'backcolor', allowTransparent: true }),
    mode: new PMode({ id: 'mode', label: 'Transparent' }),

    detailOverflows: new PBoolean({ id: 'detailOverflow', label: 'Detail Overflow', description: 'The element will be printed when the band overflows to a new page or a new column.' }),
    printWhenExpression: new PExpression({ id: 'printWhenExpression', label: 'Print When Expression' }),

    key: new PString({ id: 'key', label: 'Key' }),

    isPrintRepeatedValues: new PBoolean({ id: 'printRepeatedValues', label: 'Print Repeated Values', description: 'This flag allows suppressing the repeating values for the dynamic elements such as text fields and image fields and to fully customize the behavior of the static elements like rectangles, lines, ellipses and static texts.' }),
    isRemoveLineWhenBlank: new PBoolean({ id: 'isRemoveLineWhenBlank', label: 'Remove Line When Blank', description: 'Collapses the band if the element is not printing and no other element is occupying the same horizontal space.' }),
    printInFirstWholeBand: new PBoolean({ id: 'printInFirstWholeBand', label: 'Print In Whole Band', description: 'The element gets printed in the first band of a new page or column that is not an overflow from a previous page or column.' }),
    groupChanges: new PDsObject({ id: 'groupChanges', label: 'Group Changes', description: 'The element will be printed when the specified group changes.', types: [DsObject.GROUP] }),

    leftPadding: new PInt({ id: 'leftPadding', label: 'Left', min: 0 }),
    rightPadding: new PInt({ id: 'rightPadding', label: 'Right', min: 0 }),
    topPadding: new PInt({ id: 'topPadding', label: 'Top', min: 0 }),
    bottomPadding: new PInt({ id: 'bottomPadding', label: 'Bottom', min: 0 }),

    elementName: new PDiv({ 
        layouts: [
            new PString({ path: 'properties."com.jaspersoft.studio.element.name"', id: 'value', label: `com.jaspersoft.studio.element.name`, description: `Label given to an element to show in outline view or other places.` })
        ]}),

};

export const MPrintWhen = new PAccordeon({
    id: 'element.printWhen', label: 'Print When',
    layouts: [FGraphicElement.detailOverflows, FGraphicElement.groupChanges, FGraphicElement.printWhenExpression, FGraphicElement.key,
    FGraphicElement.isPrintRepeatedValues, FGraphicElement.isRemoveLineWhenBlank,
    FGraphicElement.printInFirstWholeBand]
});

export const MLocation = new PAccordeon({
    id: 'element.location', label: 'Location', expanded: true,
    // style: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: 10 },
    layouts: [FGraphicElement.x, FGraphicElement.y, FGraphicElement.positionType]
});

export const MSize = new PAccordeon({
    id: 'element.size', label: 'Size', expanded: true,
    // style: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: 10 },
    layouts: [FGraphicElement.width, FGraphicElement.height, FGraphicElement.stretchType]
});

export const MBoxGraphicElement: APDescriptor = {
    id: 'boxGraphiclementProeprtiesDescriptor',
    type: 'MBoxGraphicElement',
    layouts: [MLocation, MSize, ...MAppearance.layouts, MPrintWhen, FGraphicElement.elementName]
};

export const MGraphicElement: APDescriptor = {
    type: 'MGraphicElement',
    id: 'graphicElementProeprtiesDescriptor',
    layouts: [MLocation, MSize, ...MColor.layouts, MPrintWhen, FGraphicElement.elementName]
};
