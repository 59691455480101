/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { FormControlledTextField } from '@jss/js-common';
import { getKey, getPath, PropertyContext, reg, UiPropertyProps } from '../UiProperty';
import { APDescriptor } from '../APDescriptor';
import { resolveAttribute } from '../../types/common/StyleResolver';
import { Map } from 'immutable';
import i18n from '../../../../../i18n';
import { ValidationResult, VALIDATION_RESULT } from '@jss/js-common/src/utils/validators';

const TYPEID = 'numberunit';
reg(TYPEID, (mc) => { return <UPNumberUnit mcontext={mc} />; });
export class PNumberUnit extends APDescriptor {
    min?: number;
    max?: number;
    canBeNull?: boolean = true;
    default?: number | string;
    readonly?: boolean = false;
    public validator(v?: number): string | undefined {
        if (!v && this.canBeNull)
            return undefined;
        if (v === undefined && !this.canBeNull) {
            return i18n.t('validators.fieldNameEmpty');
        }
        if (isNaN(v)) {
            return 'The value is not a number';
        }
        if (this.max !== undefined && v > this.max) {
            return 'The value exceed the maximum';
        }
        if (this.min !== undefined && v < this.min) {
            return 'The value is below the minimum';
        }
        return undefined;
    }

    public constructor(init: Partial<PNumberUnit>) {
        super();
        Object.assign(this, { validator: this.validator, ...init, type: TYPEID });
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const numberUnitValidator = (stringValue: string | undefined, descriptor: PNumberUnit, mcontext: PropertyContext): ValidationResult => {
    if (!stringValue) {
        if (descriptor.canBeNull) {
            return { result: VALIDATION_RESULT.VALID }
        } else {
            return { result: VALIDATION_RESULT.INVALID, message: i18n.t('validators.fieldNameEmpty') }
        }
    }
    if (descriptor.validator){
        const validationResult = descriptor.validator(parseInt(stringValue, 10));
        if (validationResult !== undefined){
            return { result: VALIDATION_RESULT.INVALID, message: validationResult }            
        }
        return { result: VALIDATION_RESULT.INVALID }
    }
    return { result: VALIDATION_RESULT.VALID }
}

export interface IUPNumberUnit {
    additionalIcon?: React.ReactElement, 
}

export class UPNumberUnit extends React.Component<UiPropertyProps & IUPNumberUnit> {

    shouldComponentUpdate(nextProps: Readonly<UiPropertyProps>): boolean {
        const d1 = this.props.mcontext.descriptor as PNumberUnit;
        const d2 = nextProps.mcontext.descriptor as PNumberUnit;
        const p1 = getPath(d1.id, this.props.mcontext.elements[0].path);
        const p2 = getPath(d2.id, nextProps.mcontext.elements[0].path);
        const v1 = this.props.mcontext.model.getIn(p1);
        const v2 = nextProps.mcontext.model.getIn(p2);
        return v1 !== v2;
    }
    render() {
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const descriptor = this.props.mcontext.descriptor as PNumberUnit;
        let             v = this.props.mcontext.model.getIn(p);
        if (v === undefined && this.props.mcontext.descriptor.inheritedPropertyId) {
                const elementPath = getPath(undefined, this.props.mcontext.elements[0].path).slice(0, 2);
                const element = this.props.mcontext.model.getIn(elementPath) as Map<string, any>;
                v = resolveAttribute(this.props.mcontext.model, element, this.props.mcontext.descriptor.inheritedPropertyId);
        }
        const validator = (stringValue: string) => numberUnitValidator(stringValue, descriptor, this.props.mcontext);
        return <FormControlledTextField
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            key={getKey(p)}
            onTextChange={this.onNameChange}
            label={i18n.t(this.props.mcontext.descriptor.label)}
            // defaultValue={this.props.placeholder}
            inline={true}
            size={'small'}
            value={v}
            disabled={descriptor.readonly}
            onBlur={() => { this.setState({ uncommittedValue: undefined }) }}
            InputLabelProps={descriptor.deprecated ? {className: 'deprecatedProperty'} : undefined}
            validator={validator}
            iconButtons={this.props.additionalIcon ? [this.props.additionalIcon] : undefined}
        />
    }

    public onNameChange = (textValue: string) => {
        const newValue = parseInt(textValue, 10);
        this.setState({ uncommittedValue: undefined }, () => {
            this.props.mcontext.elements.forEach(key => {
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: newValue });
            });
        });
    }
}