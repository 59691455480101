/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import axios, { AxiosPromise } from 'axios';
import { Conf, Pref } from '../config/Conf';
import { RepositoryApi } from '../repo/RepositoryApi';
import { UserCredential } from '../user/UserCredential';

export class UserRest {

    public getUser(path: string): AxiosPromise<any> {
        return axios.get(Conf.get('jrws.url.rest.repository') + path, { headers: { 'Cache-Control': 'no-cache', 'UserID': RepositoryApi.inst().getUserId() } }).then((response) => {
            const uc: UserCredential = response.data;
            if (uc.locales && uc.locales.length > 0) {
                Conf.set(Pref.LOCALES, uc.locales);
                Conf.set(Pref.LOCALE, uc.locales[0]);
            }
            Conf.set(Pref.CURRENT_USER, uc);
            return response;
        });
    }

    public getUsers(path: string): AxiosPromise<any> {
        return axios.get(Conf.get('jrws.url.rest.repository') + path, { headers: { 'Cache-Control': 'no-cache', 'UserID': RepositoryApi.inst().getUserId() } }).then((response) => {
            const uc: UserCredential[] = response.data;
            console.log(uc);
            return response;
        });
    }
}