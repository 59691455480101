/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Resizer } from './Resizer';
import ReactResizeDetector from 'react-resize-detector';
import '../assets/uxpl/css/Dialog.css';

export interface IDialog {
    open: boolean,
    title?: string,
    modal?: boolean,
    resizable?: boolean,
    minWidth?: number,
    minHeight?: number,
    className?: string,
    footer?: JSX.Element,
    onClose?: () => void;
}


/**
 * Dialog is a custom version of the Dialog provided by  which is not
 * modal and can be dragged around the screen.
 */
export class Dialog extends React.Component<IDialog> {


    /**
     * 
     * @param nextProps 
     * @param prevState 
     */
    public static getDerivedStateFromProps(nextProps: IDialog, prevState: any) {

        if (nextProps.open === false) {
            return { moved: false, entered: false };
        }

        let stateChanged = false;
        const newState: {
            width?: number,
            height?: number,
        } = { ...prevState };

        if (nextProps.minWidth && prevState.width < nextProps.minWidth) {
            stateChanged = true;
            newState.width = nextProps.minWidth;
        }

        if (nextProps.minHeight && prevState.height < nextProps.minHeight) {
            stateChanged = true;
            newState.height = nextProps.minHeight;
        }

        return stateChanged ? newState : null;
    }



    public state = {
        top: 0,
        left: 0,
        windowWidth: 0,
        windowHeight: 0,
        width: 0,
        height: 0,
        entered: false,
        dragging: false,
        resizing: false,
        moved: false, // Wait for the dialog to display...
        lastPosition: { x: 0, y: 0 } // pageX, pageY
    };

    private dialogRef: React.RefObject<HTMLDivElement> = React.createRef();

    public componentDidMount() {

        // updateWindowsDimensions shoudn't be called here because it is in a race condition with onResize,
        // because the updateWindowsDimensions will call an async setState, but the method will end making
        // the component render and mounting the ResizeObserver. If the resize observer is mounted it will
        // automatically call onResize that will also update the state. So calling updateWindowDimensions
        // could bring to a situation where the state is updated before from the onResize (with the using
        // the real size of the window) and then by the updateWindowDimensions where the size of the 
        // windows were not yet set (0), showing in the end a decentered window
        // this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('mouseup', this.onMouseUp);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);
    }

    public render() {

        const modalBackgroundClasses = ["tc-jsw-modal-background"];
        if (this.props.open && this.props.modal) {
            modalBackgroundClasses.push("active");
        }

        const dialogClasses = ['modal-content']; // 'bp3-dialog'
        if (this.props.className) {
            dialogClasses.push(this.props.className);
        }

        if (this.props.resizable) {
            dialogClasses.push("tc-jsw-dialog-resizable");
        }


        const sizeStyle = this.state.width !== 0 && this.state.height !== 0 ? { width: this.state.width, height: this.state.height } : {};
        return (
            <div className={this.props.open && this.props.modal ? "tc-jsw-modal-container" : ""}>
                <div className={modalBackgroundClasses.join(' ')} />
                <div className="tc-modal-large">
                    <div className="tc-jsw-dialog" style={{ top: this.state.top, left: this.state.left }}>
                        <CSSTransition in={this.props.open}
                            classNames="tc-jsw-dialog"
                            unmountOnExit={true}
                            timeout={300}
                            onEntered={this.onEntered}
                            onEntering={this.onEntering}
                        >
                            {() => (
                                <ReactResizeDetector handleWidth={true} handleHeight={true} onResize={this.onResize}>
                                    <div className={dialogClasses.join(' ')} ref={this.dialogRef} style={sizeStyle}>
                                        <div className="modal-header"
                                            onMouseDown={this.onMouseDown}
                                            onTouchStart={this.onTouchStart}
                                            onTouchMove={this.onTouchMove}
                                            onTouchEnd={this.onTouchEnd}
                                            onTouchCancel={this.onTouchEnd}
                                        >
                                            <div className="modal-heading">{this.props.title}</div>
                                            <span className="close-new" onClick={this.onClose} />
                                        </div>
                                        <div className="modal-large-body">
                                            {this.props.children}
                                        </div>
                                        <div>
                                            {this.props.footer}

                                        </div>
                                        {this.props.resizable ? <Resizer updateStateResizing={this.updateStateResizing} /> : null}
                                    </div>
                                </ReactResizeDetector>
                            )}
                        </CSSTransition>
                    </div>
                </div>
            </div>
        );
    }

    private updateStateResizing = (value: boolean, event: React.MouseEvent<HTMLElement>) => {
        this.stopEventPropagation(event);
        this.setState({
            resizing: value,
            lastPosition: { x: event.pageX, y: event.pageY }
        })
    };

    private onClose = (event: React.MouseEvent<HTMLSpanElement>) => {

        event.preventDefault();

        if (this.props.onClose) {
            this.props.onClose();
        }
    }
    private onEntered = () => {
        this.setState({ entered: true }, this.updateWindowDimensions);
    }

    private onEntering = () => {
        const windowWidth = window.innerWidth;
        const { width } = this.state;
        this.setState({ left: windowWidth - width / 2, entered: true }, this.updateWindowDimensions);
    }

    /**
     * Handle window resize and ensure a portion of the dialog is always visible.
     *
     * @private
     * @memberof Dialog
     */
    private updateWindowDimensions = () => {


        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        if (!this.dialogRef.current) {
            return;
        }

        const { width, height } = this.state;

        let { top, left } = this.state;

        if (top > windowHeight) {
            top = windowHeight - 50;
        }

        if (left > windowWidth) {
            left = windowWidth - 50;
        }

        this.setState({
            left,
            top,
            width,
            height,
            windowWidth,
            windowHeight
        }, () => {
            if (!this.state.moved) {
                this.centerDialog();
            }
        });
    }

    private onResize = (width: number | undefined, height: number | undefined) => {
        if (width !== this.state.width || height !== this.state.height) {
            this.setState({ width: width ? width : 0, height: height ? height : 0 });
        }
    }

    private centerDialog = () => {

        const { width, windowWidth } = this.state;

        this.setState({
            left: (windowWidth - width) / 2,
            top: 120, // (windowHeight - height)/2
        });

    }


    private onMouseDown = (e: React.MouseEvent<HTMLElement>) => {

        this.stopEventPropagation(e);

        this.setState({
            dragging: true,
            lastPosition: { x: e.pageX, y: e.pageY }
        });
    }


    // Touch dragging events ----------------------------------

    private onTouchStart = (e: React.TouchEvent) => {

        this.setState({
            dragging: true,
            lastPosition: { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY }
        });

        // e.stopPropagation();
        // e.preventDefault();
    }

    private onMouseMove = (e: MouseEvent) => {
        if (!this.state.dragging && !this.state.resizing) {
            return;
        }
        e.stopPropagation();
        e.preventDefault();

        if (this.state.resizing && this.props.resizable) {
            // brench for resizing
            const { lastPosition, height, width } = this.state;
            // check if the last position is initialized
            if (lastPosition.x === 0 || lastPosition.y === 0) {
                this.setState({
                    lastPosition: { x: e.pageX, y: e.pageY }
                });
            } else {
                const delta = {
                    x: e.pageX - lastPosition.x,
                    y: e.pageY - lastPosition.y
                };

                // Let's check the constraints (min/max width and height)

                const maxWidth = window.innerWidth - 20;
                const maxHeight = window.innerHeight - 20;

                let newWidth = width + delta.x;
                newWidth = Math.max(newWidth, this.props.minWidth ? this.props.minWidth : newWidth);
                newWidth = Math.min(newWidth, maxWidth);

                let newHeight = height + delta.y;
                newHeight = Math.max(newHeight, this.props.minHeight ? this.props.minHeight : newHeight);
                newHeight = Math.min(newHeight, maxHeight);


                this.setState({
                    moved: true,
                    width: newWidth,
                    height: newHeight,
                    lastPosition: { x: e.pageX, y: e.pageY }
                });
            }
        } else {

            const { lastPosition, left, top, windowWidth, windowHeight, width } = this.state;

            const delta = {
                x: e.pageX - lastPosition.x,
                y: e.pageY - lastPosition.y
            };


            this.setState({
                left: Math.max(-width + 30, Math.min(left + delta.x, windowWidth - 30)),
                top: Math.max(0, Math.min(top + delta.y, windowHeight - 30)),
                moved: true,
                lastPosition: { x: e.pageX, y: e.pageY }
            });
        }
    }

    private onTouchMove = (e: React.TouchEvent) => {

        this.stopEventPropagation(e);

        const { lastPosition, left, top, windowWidth, windowHeight, width } = this.state;

        // e.stopPropagation();
        // e.preventDefault();

        const delta = {
            x: e.changedTouches[0].clientX - lastPosition.x,
            y: e.changedTouches[0].clientY - lastPosition.y
        };


        this.setState({
            left: Math.max(-width + 30, Math.min(left + delta.x, windowWidth - 30)),
            top: Math.max(0, Math.min(top + delta.y, windowHeight - 30)),
            moved: true,
            lastPosition: { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY }
        });
    }


    private onMouseUp = (e: MouseEvent) => {
        this.setState({ dragging: false, resizing: false });
        e.stopPropagation();
        e.preventDefault();
    }

    private onTouchEnd = () => {
        this.setState({ dragging: false });
        // e.stopPropagation();
        // e.preventDefault();
    }


    /**
     * When a mouse event was initiated by the designer to start a drag operation, we want to stop
     * it's propagation so no other elements get this event.
     */
    private stopEventPropagation = (e: React.MouseEvent<HTMLElement> | React.TouchEvent) => {

        e.stopPropagation();
        e.preventDefault();

    }

}
