/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { nameValidator, notEmptyValidator } from '@jss/js-common/src/utils/validators';
import { MIME_TYPES } from '@jss/js-rest-api';
import * as React from 'react';
import { IDataAdapterConfig } from '../IDataAdapterDescriptor';
import { IJrsDataAdapterDescriptor } from '../IJrsDataAdapterDescriptor';
import { JrsJndiDataAdapterEditor } from './JrsJndiDataAdapterEditor';


export default class JrsJndiDataAdapterDescriptor implements IJrsDataAdapterDescriptor {

  public getMime = () => {
    return MIME_TYPES.DATASOURCE_JNDI;
  }

  public getIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" height={48} width={48} viewBox="0 0 48 48">
      <path d="M24 22q-8.05 0-13.025-2.45T6 14q0-3.15 4.975-5.575Q15.95 6 24 6t13.025 2.425Q42 10.85 42 14q0 3.1-4.975 5.55Q32.05 22 24 22Zm0 10q-7.3 0-12.65-2.2Q6 27.6 6 24.5v-5q0 1.95 1.875 3.375t4.65 2.35q2.775.925 5.9 1.35Q21.55 27 24 27q2.5 0 5.6-.425 3.1-.425 5.875-1.325 2.775-.9 4.65-2.325Q42 21.5 42 19.5v5q0 3.1-5.35 5.3Q31.3 32 24 32Zm0 10q-7.3 0-12.65-2.2Q6 37.6 6 34.5v-5q0 1.95 1.875 3.375t4.65 2.35q2.775.925 5.9 1.35Q21.55 37 24 37q2.5 0 5.6-.425 3.1-.425 5.875-1.325 2.775-.9 4.65-2.325Q42 31.5 42 29.5v5q0 3.1-5.35 5.3Q31.3 42 24 42Z" />
    </svg>
  }

  /**
   *  Return the name of this data adapter to be presented to the user
   */
  public getDataAdapterLabel = () => {
    return 'datasource.jndi.label';
  }

  /**
  *  Return the name of this data adapter to be presented to the user
  *  i.e. CSV File
  */
  public getDataAdapterDescription = () => {
    return 'datasource.jndi.description';
  }


  /**
   * Return a plain json object with the default data adapter configuration.
   * It can be just an empty object.
   *
   * @memberof IDataAdapter
   */
  public initializeDataAdapterConfig = () => {
    return {
      class: MIME_TYPES.DATASOURCE_JNDI,
      name: 'New JNDI Data Adapter',
      jndi: '',
      uri: '',
    }
  };

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public validateDataAdapterConfig = (config: IDataAdapterConfig) => {

    // Check for errors...
    const errors = [];

    const nameError = nameValidator(config.name);
    if (nameError) {
      errors.push(nameError);
    }

    const urlError = notEmptyValidator(config.jndi);
    if (urlError) {
      errors.push(urlError);
    }

    if (errors.length > 0) {
      return errors.join("\n");
    }

    return undefined;

  }

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public createEditor = (config: IDataAdapterConfig, onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => {
    return <JrsJndiDataAdapterEditor {...config} onDataAdapterSettingsChange={onDataAdapterSettingsChange} readOnly={readOnly}/>
  }

  public getLanguages = () => {
    return ['text'];
  }

  public toJSON = (config: IDataAdapterConfig) => {
    return JSON.stringify({
      version: config.version,
      permissionMask: config.permissionMask,
      creationDate: config.creationDate,
      updateDate: config.updateDate,
      label: config.name,
      description: config.description,
      uri: config.uri,
      jndiName: config.jndi,
      timezone: config.timezone,
    });
  }

  public fromJson = (json: string) => {
    const jsonObject = JSON.parse(json);
    jsonObject.class = MIME_TYPES.DATASOURCE_JNDI;
    jsonObject.jndi = jsonObject.jndiName;
    jsonObject.name = jsonObject.label;
    return jsonObject;
  }
}