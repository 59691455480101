/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const WidgetsProIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/palette/widgets-pro" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" transform="translate(1.000000, 1.000000)" stroke="#FFFFFF" strokeWidth="0.5">
                    <rect id="piece-4" fill="#B6D978" x="0.25" y="7.10714286" width="7.5" height="6.35714286"></rect>
                    <path d="M7.75,0.25 L7.75,6.60714286 L3.66741514,6.60714286 C3.87709663,7.26118358 3.9239906,7.75659505 3.70921771,8.06298733 C3.53564596,8.3106026 3.19952683,8.40626791 2.73886089,8.40626791 C2.28423913,8.40626791 1.95392991,8.31485441 1.7926024,8.06923819 C1.59056394,7.76164078 1.6646388,7.26401694 1.90406653,6.60714286 L1.90406653,6.60714286 L0.25,6.60714286 L0.25,0.25 L7.75,0.25 Z" id="piece-1" fill="#0081CB"></path>
                    <path d="M15.75,0.25 L15.75,6.60714286 L8.25,6.60714286 L8.25,4.30173882 C7.46178437,4.59597996 6.8782632,4.69385873 6.52827051,4.48457196 C6.20168659,3.95956628 6.18189301,3.72575849 6.20028648,3.44490952 C6.23438918,2.92419694 6.36178041,2.55762824 6.64729146,2.39966724 C7.00148835,2.20370531 7.54376971,2.33946061 8.25,2.68182505 L8.25,2.68182505 L8.25,0.25 L15.75,0.25 Z" id="piece-2" fill="#55ADEB"></path>
                    <path d="M12.2090692,4.89636161 C12.8016974,4.89636161 13.2249816,5.02267013 13.4348396,5.33766775 C13.6854229,5.71379432 13.6194258,6.31301531 13.3368584,7.10714286 L13.3368584,7.10714286 L15.75,7.10714286 L15.75,13.4642857 L8.25,13.4642857 L8.25,11.0138784 C7.37344609,11.4804979 6.71711406,11.6821397 6.30170175,11.4862164 C5.88439794,10.9037691 5.84341149,10.6261582 5.84341149,10.2857143 C5.84341149,9.64771875 5.98167594,9.19686496 6.32644596,8.98388714 C6.73671131,8.73045031 7.38654068,8.82151882 8.25,9.15476788 L8.25,9.15476788 L8.25,7.10714286 L10.7493493,7.10714286 C10.5743165,6.32062865 10.6006017,5.72793479 10.8980921,5.35494164 C11.1538413,5.03428366 11.599908,4.89636161 12.2090692,4.89636161 Z" id="piece-3" fill="#7CC344"></path>
                </g>
                <path d="M14,6.87022427 L15.8014959,10.5204536 L19.8297632,11.1057951 L16.9148816,13.9471024 L17.6029918,17.9590927 L14,16.0648879 L10.3970082,17.9590927 L11.0851184,13.9471024 L8.17023685,11.1057951 L12.1985041,10.5204536 L14,6.87022427 Z" id="Star" stroke="#FFFFFF" fill="#F57F4F"></path>
            </g>
        </svg>
    );
}
export default WidgetsProIcon;