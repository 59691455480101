/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React, { useCallback, useState } from 'react';
import { Conf, RepositoryApi } from '@jss/js-rest-api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import { persistor } from '../../store/configureStore';
import { logoutUser } from '../../store/actions/actions';
import { LOGGED_USERS_KEY } from '../../store/reducers/persistentReducer';
import { useNavigate } from 'react-router-dom';

interface IState {
	showLogin?: string
}

export function LoggedArea() {
	const [state, setState] = useState<IState>({});
	const storedData = useSelector((state: any) => state.persistentReducer.storedData);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		Conf.initAxios((msg: string): boolean => {
			setState({ showLogin: msg });
			// show login dialog
			// don't reroute to login page, to be back in the same place after login
			// if dialog returns ok/means logged in and retry return true
			// else logout, reroute to logout page
			return false;
		})
	}, []); // empty array

	const u: Map<string, any> = storedData.get(LOGGED_USERS_KEY);
    if (u && u.has('userId') && u.has('baseUrl')) {
        RepositoryApi.inst().setBaseURL(u.get('baseUrl'), u.get('userId'), u.get('info'));
    }

	const reLogin = useCallback(
        (): boolean => {
            const u: Map<string, any> = storedData.get(LOGGED_USERS_KEY);
            if (u && u.has('baseUrl')) {
                const baseUrl: string = u.get('baseUrl');
                dispatch(logoutUser());
                persistor.flush().then(() => {
                    if (baseUrl.startsWith('jrs')) {
                        navigate('/jrslogin');
                    } else {
                        navigate('/');
                    }
                });
            }
            return true;
        }, [dispatch, navigate, storedData]);

	const onClick = useCallback(
		() => {
			setState({ showLogin: undefined })
			reLogin();
		}, [reLogin]);

	if (state.showLogin) {
		return <div>
			<Snackbar open={true} autoHideDuration={6000} onClose={onClick} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<MuiAlert elevation={6} variant="filled" onClose={onClick} severity="warning">
					Forbidden. Going to re authentication.
				</MuiAlert>
			</Snackbar>
		</div >;
	}
	return <></>;
}
