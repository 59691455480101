/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { Icon } from '@jss/js-common';
import { Tooltip } from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem';
import DataArrayIcon from '@mui/icons-material/DataArray';
import DataObjectIcon from '@mui/icons-material/DataObject';
import CodeIcon from '@mui/icons-material/Code';
import * as React from 'react';
import '../../../assets/uxpl/css/Outline.css';

interface INodeObject {
    id: string,
    label?: string,
    description?: string,
}

interface INodeElement {
    id: string;
    label: string;
    isSelected: boolean;
    isEmpty?: boolean,
    type?: string,
    description?: string,
    node: INodeObject,
    path: string,
    onClick: (e: React.MouseEvent<HTMLElement>, id: string, allowRightClick?: boolean) => void;
}

interface IState {
    anchorEl: null | EventTarget
}

const TYPE_ICONMAP = new Map<string, JSX.Element>();

const createIcon = (type: string) => {
    const lowerType = type.toLocaleLowerCase();
    if (lowerType.includes('table') || lowerType.includes('system view')) {
        return <Icon icon="table" />;
    } else if (lowerType.includes('procedure') || lowerType.includes('function')) {
        return <Icon icon="gears" className='jr-MuiTreeItem-Icon' />;
    } else if (lowerType.includes('domain-level')) {
        return <Icon icon="table" />;
    } else if (lowerType.includes('array')) {
        return <span className="jr jr-mTree-leaf jr-mTree-leafField">
            <DataArrayIcon fontSize='small' className='jr-MuiTreeItem-MuiIcon' />
        </span>;
    } else if (lowerType.includes('xml-node')) {
        return <span className="jr jr-mTree-leaf jr-mTree-leafField">
            <CodeIcon fontSize='small' className='jr-MuiTreeItem-MuiIcon' />
        </span>;
    } else if (lowerType.includes('json-node')) {
        return <span className="jr jr-mTree-leaf jr-mTree-leafField">
            <DataObjectIcon fontSize='small' className='jr-MuiTreeItem-MuiIcon' />
        </span>;
    } else if (lowerType.includes('long') || lowerType.includes('integer') || lowerType.includes('short') || lowerType.includes('float') || lowerType.includes('double') || lowerType.includes('bigdecimal')) {
        return <span className="jr jr-mTree-leaf jr-mTree-leafField">
            <span style={{ margin: 0 }} className="jr-mTree-icon jr jr-mTree-iconNumber" />
        </span>;
    } else if (lowerType.includes('string')) {
        return <span className="jr jr-mTree-leaf jr-mTree-leafField">
            <span style={{ margin: 0 }} className="jr-mTree-icon jr jr-mTree-iconString" />
        </span>;
    } else if (lowerType.includes('boolean')) {
        return <span className="jr jr-mTree-leaf jr-mTree-leafField">
            <span style={{ margin: 0 }} className="jr-mTree-icon jr jr-mTree-iconBoolean" />
        </span>;
    } else if (lowerType.includes('date') || lowerType.includes('datetime')) {
        return <span className="jr jr-mTree-leaf jr-mTree-leafField">
            <span style={{ margin: 0 }} className="jr-mTree-icon jr jr-mTree-iconDate" />
        </span>;
    } else if (lowerType.includes('time')) {
        return <span className="jr jr-mTree-leaf jr-mTree-leafField">
            <span style={{ margin: 0 }} className="jr-mTree-icon jr jr-mTree-iconTime" />
        </span>;
    } else {
        return <span className="jr jr-mTree-leaf jr-mTree-leafField">
            <DataObjectIcon fontSize='small' className='jr-MuiTreeItem-MuiIcon' />
        </span>;
    }
}

const getIcon = (type: string | undefined) => {
    if (!type) {
        return null;
    }
    let icon = TYPE_ICONMAP.get(type);
    if (!icon) {
        icon = createIcon(type);
        TYPE_ICONMAP.set(type, icon);
    }
    return icon;
}

const getTooltip = (node: INodeObject, label: string, path: string,) => {

    const tooltip = [];
    if (label.length > 30) {
        tooltip.push(label);
    }
    Object.keys(node).forEach((key) => {
        const name = key.toLowerCase();
        if (name === 'properties' && Array.isArray(node[key])){
            const properties = [];
            node[key].forEach(property => {
                properties.push(`${property.key}=${property.value}`);
            });
            tooltip.push(`${name}: ${properties.join(', ')}`);  
        } else if (name !== 'fields' && name !== 'tables'){
            tooltip.push(`${name}: ${node[key]}`)
        }
    })
    tooltip.push('Path: ' + path);
    return tooltip.join('\n');
}

const getTreeLabel = (node: INodeObject, label: string) => {
    if (label?.length > 30) {
        return label.substring(0, 30) + '...';
    }
    return label;
}

const getLabelComponent = (node: INodeObject, label: string, path: string, type: string | undefined, description: string | undefined) => {
    if (label?.length > 30 || type || description) {
        return <Tooltip  title={<span style={{ whiteSpace: 'pre-line' }}>{getTooltip(node, label, path)}</span>} enterDelay={1000} enterNextDelay={1000} arrow placement="bottom-start">
            <div className="tc-jsw-noselect" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <span style={{ marginRight: 2 }}>{getIcon(type)}</span>
                <span className="jr-mTree-item-label-text mui">{getTreeLabel(node, label)}</span>
            </div>
        </Tooltip>;
    }
    return <div className="tc-jsw-noselect" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {getIcon(type)}
        <span className="jr-mTree-item-label-text mui">{getTreeLabel(node, label)}</span>
    </div>;
}

class NodeElement extends React.Component<INodeElement, IState> {

    lastClick = undefined;

    private preventExpansion = (event: React.MouseEvent<HTMLElement>) => {
        if (this.lastClick && event.timeStamp - this.lastClick <= 250) {
            //double click
            // this.props.onDoubleClick(event, this.props.id);
        } else {
            this.props.onClick(event, this.props.path);
            //preventing the default prevent also the node expansion, the selection will 
            //be handled by the event on the TreeView
            event.preventDefault();
        }
        this.lastClick = event.timeStamp;
    };

    public render() {
        const id = this.props.id;
        const children = this.props.children;
        const labelClass = [];
        if (this.props.isSelected) {
            labelClass.push('tc-jsw-report-outline-label-selected');
        }
        if (this.props.isEmpty) {
            labelClass.push('tc-jsw-report-outline-label-empty');
        }
        return (<div>
            <TreeItem ria-describedby={id} classes={{ label: labelClass.join(' ') }} key={id} nodeId={id} onLabelClick={this.preventExpansion} label={getLabelComponent(this.props.node, this.props.label, this.props.path, this.props.type, this.props.description)}>
                {children}
            </TreeItem>
        </div>
        );
    }
}

export default NodeElement;