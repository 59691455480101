/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const MultiAxisColumnChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath id="highcharts-aj7j4zs-1">
          <path fill="none" d="M0 0H366V672H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M56 10H422V682H56z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" d="M86.5 10v672M116.5 10v672M147.5 10v672M177.5 10v672M208.5 10v672M238.5 10v672M269.5 10v672M299.5 10v672M330.5 10v672M360.5 10v672M391.5 10v672M421.5 10v672M55.5 10v672" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M56 682.5h366M56 615.5h366M56 548.5h366M56 480.5h366M56 413.5h366M56 346.5h366M56 279.5h366M56 212.5h366M56 144.5h366M56 77.5h366M56 9.5h366" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M56 682.5h366M56 615.5h366M56 548.5h366M56 480.5h366M56 413.5h366M56 346.5h366M56 279.5h366M56 212.5h366M56 144.5h366M56 77.5h366M56 9.5h366" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M56 682.5h366M56 615.5h366M56 548.5h366M56 480.5h366M56 413.5h366M56 346.5h366M56 279.5h366M56 212.5h366M56 144.5h366M56 77.5h366M56 9.5h366" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M56 10H422V682H56z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" stroke="#ccd6eb" d="M86.5 682v10M116.5 682v10M147.5 682v10M177.5 682v10M208.5 682v10M238.5 682v10M269.5 682v10M299.5 682v10M330.5 682v10M360.5 682v10M391.5 682v10M422.5 682v10M55.5 682v10" className="highcharts-tick" />
       <path fill="none" stroke="#ccd6eb" d="M56 682.5h366" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M56 10v672" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <text x="478.313" y="346" fill="#666" className="highcharts-axis-title" color="#666" data-z-index="7" textAnchor="middle" transform="rotate(90 478.313 346)">
          <tspan>Max Freight</tspan>
       </text>
       <path fill="none" d="M422 10v672" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <text x="531.234" y="346" fill="#666" className="highcharts-axis-title" color="#666" data-z-index="7" textAnchor="middle" transform="rotate(90 531.234 346)">
          <tspan>Orders</tspan>
       </text>
       <path fill="none" d="M492 10v672" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-column-series highcharts-color-0 highcharts-tracker" clipPath="url(#highcharts-aj7j4zs-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="#7cb5ec" stroke="#fff" d="M6.5 493.5H11.5V672.5H6.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M36.5 589.5H41.5V672.5H36.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M67.5 111.5H72.5V672.5H67.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M97.5 661.5H102.5V672.5H97.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M128.5 655.5H133.5V672.5H128.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M158.5 583.5H163.5V672.5H158.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M189.5 666.5H194.5V672.5H189.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M219.5 625.5H224.5V672.5H219.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M250.5 643.5H255.5V672.5H250.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M280.5 655.5H285.5V672.5H280.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M311.5 558.5H316.5V672.5H311.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M341.5 670.5H346.5V672.5H341.5z" className="highcharts-point highcharts-color-0" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-column-series highcharts-color-1 highcharts-tracker" clipPath="url(#highcharts-aj7j4zs-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="#434348" stroke="#fff" d="M12.5 196.5H17.5V672.5H12.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M42.5 499.5H47.5V672.5H42.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M73.5 114.5H78.5V672.5H73.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M103.5 608.5H108.5V672.5H103.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M134.5 597.5H139.5V672.5H134.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M164.5 188.5H169.5V672.5H164.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M195.5 647.5H200.5V672.5H195.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M225.5 541.5H230.5V672.5H225.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M256.5 513.5H261.5V672.5H256.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M286.5 611.5H291.5V672.5H286.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M317.5 265.5H322.5V672.5H317.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M347.5 664.5H352.5V672.5H347.5z" className="highcharts-point highcharts-color-1" />
       </g>
       <g className="highcharts-series highcharts-series-2 highcharts-column-series highcharts-color-2 highcharts-tracker" clipPath="url(#highcharts-aj7j4zs-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="#90ed7d" stroke="#fff" d="M18.5 386.5H23.5V672.5H18.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M48.5 504.5H53.5V672.5H48.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M79.5 151.5H84.5V672.5H79.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M109.5 622.5H114.5V672.5H109.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M140.5 588.5H145.5V672.5H140.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M170.5 521.5H175.5V672.5H170.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M201.5 622.5H206.5V672.5H201.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M231.5 521.5H236.5V672.5H231.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M262.5 470.5H267.5V672.5H262.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M292.5 605.5H297.5V672.5H292.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M323.5 437.5H328.5V672.5H323.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M353.5 638.5H358.5V672.5H353.5z" className="highcharts-point highcharts-color-2" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(128 762)">
       <rect width="299" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-column-series highcharts-color-0 highcharts-series-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Total Freight</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-column-series highcharts-color-1 highcharts-series-1" data-z-index="1" transform="translate(123.672 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Max Freight</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-column-series highcharts-color-2 highcharts-series-2" data-z-index="1" transform="translate(232.61 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Orders</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#90ed7d" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="73.843" y="698" transform="rotate(-45 73.843 698)">Albuquerque</text>
       <text x="104.343" y="698" transform="rotate(-45 104.343 698)">Anchorage</text>
       <text x="134.843" y="698" transform="rotate(-45 134.843 698)">Boise</text>
       <text x="165.343" y="698" transform="rotate(-45 165.343 698)">Butte</text>
       <text x="195.843" y="698" transform="rotate(-45 195.843 698)">Elgin</text>
       <text x="226.343" y="698" transform="rotate(-45 226.343 698)">Eugene</text>
       <text x="256.843" y="698" transform="rotate(-45 256.843 698)">Kirkland</text>
       <text x="287.343" y="698" transform="rotate(-45 287.343 698)">Lander</text>
       <text x="317.843" y="698" transform="rotate(-45 317.843 698)">Portland</text>
       <text x="348.343" y="698" transform="rotate(-45 348.343 698)">
          <tspan>San Francisco</tspan>
       </text>
       <text x="378.843" y="698" transform="rotate(-45 378.843 698)">Seattle</text>
       <text x="409.343" y="698" transform="rotate(-45 409.343 698)">
          <tspan>Walla Walla</tspan>
       </text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="41" y="687">0</text>
       <text x="41" y="619">800</text>
       <text x="41" y="552">1.600</text>
       <text x="41" y="485">2.400</text>
       <text x="41" y="418">3.200</text>
       <text x="41" y="351">4.000</text>
       <text x="41" y="283">4.800</text>
       <text x="41" y="216">5.600</text>
       <text x="41" y="149">6.400</text>
       <text x="41" y="82">7.200</text>
       <text x="41" y="15">8.000</text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11">
       <text x="437" y="687">0</text>
       <text x="437" y="619">100</text>
       <text x="437" y="552">200</text>
       <text x="437" y="485">300</text>
       <text x="437" y="418">400</text>
       <text x="437" y="351">500</text>
       <text x="437" y="283">600</text>
       <text x="437" y="216">700</text>
       <text x="437" y="149">800</text>
       <text x="437" y="82">900</text>
       <text x="437" y="15">1.000</text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11">
       <text x="507.313" y="687">0</text>
       <text x="507.313" y="619">4</text>
       <text x="507.313" y="552">8</text>
       <text x="507.313" y="485">12</text>
       <text x="507.313" y="418">16</text>
       <text x="507.313" y="351">20</text>
       <text x="507.313" y="283">24</text>
       <text x="507.313" y="216">28</text>
       <text x="507.313" y="149">32</text>
       <text x="507.313" y="82">36</text>
       <text x="507.313" y="15">40</text>
    </g>
 </svg>)};

export default MultiAxisColumnChartIcon;