/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MBoxGraphicElement } from "../common/MGraphicElement";
import { MTextElement } from "../common/MTextElement"; 
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { ICONS } from "@jss/js-common";
import { MStaticTextCustom } from "./MStaticTextCustom";
import { PExpandableString } from "../../ui/primitive/UPExpandableString";

export const FStaticText = {
    text: new PExpandableString({ id: 'text', label: 'Text' }),
};

export const MStaticText: APDescriptor = {
    type: ElementTypes.STATICTEXT,
    label: 'Static Text',
    id: 'staticTextPropertiesDescriptor',
    default: { width: 200, height: 30, text: 'Static Text' },
    icon: ICONS.STATICTEXT_ICON,
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MBoxGraphicElement.layouts, MStaticTextCustom] }),
            new PDiv({
                label: 'Static Text',
                layouts: [FStaticText.text, ...MTextElement.layouts]
            })
        ]
    })]
};
