/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { Switch } from "@jss/js-common";
import { getKey, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../../ui/APDescriptor';
import { IState } from '../../../../../reducers';
import { connect } from 'react-redux';
import * as ReportActions from '../../../../../actions/reportActions';
import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
import i18n from '../../../../../i18n';

const TYPEID = 'query';
reg(TYPEID, (mc) => { return <UPQuery mcontext={mc} />; });
export class PQuery extends APDescriptor {
    public constructor(init: Partial<PQuery>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

interface IQueryProperties extends UiPropertyProps {
    subeditors?: ImmutableList<ImmutableMap<string, any>>;
    currentEditorIndex?: number | undefined;
    createEditor?: (editorType: string, editorLabel: string, isSelectable: boolean, editedResourceId: string | number, setActive: boolean, params?: Record<string, unknown>) => void;
    setEditorProperties?: (index: number, editorLabel: string, isSelectable: boolean, editedResourceId: string, params: Record<string, unknown>) => void;
    closeEditor?: (editorIndex: number) => void;
}

export class UPQuery extends React.Component<IQueryProperties> {

    private isDatasetEditorVisible = () => {
        if (this.props.currentEditorIndex !== undefined && this.props.currentEditorIndex >= 0) {
            const currentEditorType = this.props.subeditors.getIn([this.props.currentEditorIndex, 'type']);
            return (currentEditorType === 'datasetQueryEditor');
        }
        return false;
    }

    private switchToDatasetEditor = () => {
        if (this.isDatasetEditorVisible()) {
            this.props.closeEditor(this.props.currentEditorIndex);
        } else {
            const ds = this.props.mcontext.elements[0].path.length > 0 ? this.props.mcontext.elements[0].path[this.props.mcontext.elements[0].path.length - 1].toString() : 'main';
            this.props.createEditor('datasetQueryEditor', 'Dataset Editor', false, ds, true);
        }
    }
    
    render() {
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        return <Switch
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            key={getKey(p)}
            onChange={this.switchToDatasetEditor}
            label={i18n.t(this.props.mcontext.descriptor.label)}
            // defaultValue={this.props.placeholder}
            // inline={true}
            size={'medium'}
            checked={this.isDatasetEditorVisible()}
        />;
    }
}

const mapStateToProps = (state: IState) => {
    return {
        subeditors: state.getIn(['report', 'subeditors']),
        currentEditorIndex: state.getIn(['report', 'currentEditorIndex']),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        createEditor: (editorType: string, editorLabel: string, isSelectable: boolean, editedResourceId: string, setActive: boolean, params?: Record<string, unknown>) => {
            dispatch(ReportActions.createEditor(editorType, editorLabel, isSelectable, editedResourceId, setActive, params));
        },
        closeEditor: (editorIndex: number) => { dispatch(ReportActions.closeEditor(editorIndex)) },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UPQuery);
