/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../ui/APDescriptor";
import { ParameterEvaluationTimeEnum } from "../enums/ParameterEvaluationTimeEnum";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PExpression } from "../../ui/primitive/UPExpression";

import { PJavaClass } from "../../ui/primitive/UPJavaClass";
import { PString } from "../../ui/primitive/UPString";
import { PTableElement } from "../../ui/tables/UPTableElement";
import ParameterIcon from "../../../../../assets/icons/outline/parameters.svg";
import { PConnectedDynProperties } from "./UPConnectedDynProperties";
import { PParameterClass } from "../../ui/primitive/UPParameterClass";
import { PParameterName } from "../../ui/primitive/UPParameterName";


export const DefaultClasses = ["java.lang.Integer", "java.lang.Long", "java.lang.Double",
    "java.lang.String", "java.lang.Short", "java.lang.Boolean", "java.lang.Float", "java.math.BigDecimal",
    "java.sql.Time", "java.sql.Timestamp", "java.sql.Date", "java.util.Date"];

export const FParameter = {
    name: new PParameterName({ id: 'name', label: 'Name', canBeEmpty: false, canBeNull: false }),
    parameterDescription: new PString({ id: 'parameterDescription', label: 'Description' }),
    class: new PParameterClass({ id: 'class', label: 'Class', items: [...DefaultClasses] }),
    isForPrompting: new PBoolean({ id: 'isForPrompting', label: 'Is For Prompting' }),
    defaultValueExpression: new PExpression({ id: 'defaultValueExpression', label: 'Default Value Expression' }),
    evaluationTime: ParameterEvaluationTimeEnum,
    nestedType: new PJavaClass({ id: 'nestedType', label: 'Nested Type', items: [...DefaultClasses] })
}

export const MParameter: APDescriptor = new PTableElement({
    label: 'Parameter',
    icon: ParameterIcon,
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    default: { isForPrompting: true },
    layouts: [FParameter.name, FParameter.parameterDescription, FParameter.class, FParameter.nestedType, FParameter.isForPrompting, FParameter.defaultValueExpression, FParameter.evaluationTime, new PConnectedDynProperties({ id: 'dsParameterCustomProperties', propspath: "parameters" })]
});
