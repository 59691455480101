/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const LineIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/palette/line" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" transform="translate(10.000000, 9.000000) rotate(45.000000) translate(-10.000000, -9.000000) translate(8.250000, 0.000000)">
                    <path d="M1,3.32 C1.25,3.44 1.5,3.5 1.75,3.5 C2,3.5 2.25,3.44 2.5,3.32 L2.5,14.68 C2.25,14.56 2,14.5 1.75,14.5 C1.5,14.5 1.25,14.56 1,14.68 L1,3.32 Z" id="rectangle-left" fill="#A8D2F9"></path>
                    <path d="M1.75,14.5 C2.71649831,14.5 3.5,15.2835017 3.5,16.25 C3.5,17.2164983 2.71649831,18 1.75,18 C0.783501688,18 0,17.2164983 0,16.25 C0,15.2835017 0.783501688,14.5 1.75,14.5 Z M1.75,0 C2.71649831,0 3.5,0.783501688 3.5,1.75 C3.5,2.71649831 2.71649831,3.5 1.75,3.5 C0.783501688,3.5 0,2.71649831 0,1.75 C0,0.783501688 0.783501688,0 1.75,0 Z" id="Combined-Shape" fill="#0081CB"></path>
                </g>
            </g>
        </svg>
    );
}
export default LineIcon;