/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StackedColumn3DChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
  return (<svg
    xmlns="http://www.w3.org/2000/svg"
    width="802"
    height="493"
    version="1.1"
    style={{
      WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      userSelect: "none",
      position: "relative",
    }}
    cursor="default"
    overflow="hidden"
    viewBox="0 0 802 493"
    {...props}
  >
    <defs>
      <linearGradient
        id="19-329.0909881288068-rgba_187_192_181_1_-rgba_236_240_232_1_"
        x1="0"
        x2="1"
        y1="0"
        y2="0.599"
      >
        <stop offset="0%" stopColor="#bbc0b5"></stop>
        <stop offset="100%" stopColor="#ecf0e8"></stop>
      </linearGradient>
      <linearGradient
        id="19-270-rgb_209.35_217.6_198.9_-rgb_111.8_121.55_99.45_"
        x1="0"
        x2="0"
        y1="0"
        y2="1"
      >
        <stop offset="0%" stopColor="#d1d9c6"></stop>
        <stop offset="100%" stopColor="#6f7963"></stop>
      </linearGradient>
      <linearGradient
        id="19-270-rgb_129_140.25_114.75_-rgb_60.199999999999996_65.45_53.55_"
        x1="0"
        x2="0"
        y1="0"
        y2="1"
      >
        <stop offset="0%" stopColor="#818c72"></stop>
        <stop offset="100%" stopColor="#3c4135"></stop>
      </linearGradient>
      <linearGradient
        id="19-45.88140399658214-rgb_134.16_145.86_119.34_-rgb_236.74_240.04_232.56_"
        x1="0"
        x2="0.97"
        y1="1"
        y2="0"
      >
        <stop offset="0%" stopColor="#869177"></stop>
        <stop offset="100%" stopColor="#ecf0e8"></stop>
      </linearGradient>
      <linearGradient
        id="19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_"
        x1="0"
        x2="0"
        y1="0"
        y2="1"
      >
        <stop offset="0%" stopColor="#d3e9fb"></stop>
        <stop offset="100%" stopColor="#718ca1"></stop>
      </linearGradient>
      <linearGradient
        id="19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_"
        x1="0"
        x2="0"
        y1="0"
        y2="1"
      >
        <stop offset="0%" stopColor="#83a2ba"></stop>
        <stop offset="100%" stopColor="#3d4b56"></stop>
      </linearGradient>
      <linearGradient
        id="19-54.46232220802562-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
        x1="0"
        x2="0.714"
        y1="1"
        y2="0"
      >
        <stop offset="0%" stopColor="#88a8c1"></stop>
        <stop offset="100%" stopColor="#edf6fd"></stop>
      </linearGradient>
      <filter
        id="52C86AE6-861B-4C09-A579-E8ECC02F70F4"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="2DD0687F-763A-4EBB-BFCC-B655F844705E"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <linearGradient
        id="19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_"
        x1="0"
        x2="0"
        y1="0"
        y2="1"
      >
        <stop offset="0%" stopColor="#dcdcdc" stopOpacity="0"></stop>
        <stop offset="100%" stopColor="#7c7c7c" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_"
        x1="0"
        x2="0"
        y1="0"
        y2="1"
      >
        <stop offset="0%" stopColor="#909090" stopOpacity="0"></stop>
        <stop offset="100%" stopColor="#434343" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
        x1="0"
        x2="0.714"
        y1="1"
        y2="0"
      >
        <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
        <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
      </linearGradient>
      <filter
        id="887BE681-8FE1-4ADC-B8D2-3A63A855B17D"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="A73D83F1-0810-4BF7-8BBA-B295B3732CB8"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="184E5DDE-4F68-401B-B09F-25F4A6DBFB01"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="C0232B6C-AA26-4221-B41E-FC5C835AFDDC"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="2069FE69-8AB6-44E4-ABDB-41DA9550F66B"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="45E58029-A90E-4891-A72D-445408AA3A9C"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="C2D707E4-39C9-472C-ACF5-8E74B80BDE51"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="B1F77220-77C1-4C07-B474-90D3F86F0312"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="E04F4692-79CB-4426-9060-B1668D5F0610"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="8216FF2B-1DAC-4FCE-93EF-F6BEDC4761DB"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="0022E5F1-3DFD-4AAE-A235-17B5716F4DA6"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="78F99B5E-3556-4E7C-B5E9-6DE3DD69734D"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <linearGradient
        id="19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_"
        x1="0"
        x2="0"
        y1="0"
        y2="1"
      >
        <stop offset="0%" stopColor="#fada7b"></stop>
        <stop offset="100%" stopColor="#9f7a09"></stop>
      </linearGradient>
      <linearGradient
        id="19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_"
        x1="0"
        x2="0"
        y1="0"
        y2="1"
      >
        <stop offset="0%" stopColor="#b88d0b"></stop>
        <stop offset="100%" stopColor="#564205"></stop>
      </linearGradient>
      <linearGradient
        id="19-54.46232220802562-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_"
        x1="0"
        x2="0.714"
        y1="1"
        y2="0"
      >
        <stop offset="0%" stopColor="#bf930b"></stop>
        <stop offset="100%" stopColor="#fdf0ca"></stop>
      </linearGradient>
      <filter
        id="C60B6176-1BBC-4B15-9C2C-B31F45889E1C"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="3281B28B-53F7-4BDB-9924-61634D240AA2"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="0CDE747D-8ADC-41D8-95B5-778FD335E952"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="8F47B448-E1BF-4DCF-9ED9-ABC6F5C9AFBA"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="7389532A-396A-403E-B4E4-D575FCFCBE1F"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="966AE348-1671-4DFA-946E-FA7790A5EFCC"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="E3C8DEFB-CEB5-48CE-A854-5FFB43B52198"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="B04F2155-C076-47E1-8843-02F1A28B4143"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="2C437DC4-5433-4AD8-93BA-CEE0E3E7643C"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="208EF635-71CC-4741-A26C-B3A1A6452391"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="95E93C81-5466-4AA8-8C08-71D1A113090C"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="CAF18E34-DC4B-40B9-A95B-6E55DCC8EBE2"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="FCFFC855-F680-4C20-8C31-CE0EA73376E9"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="319E9C62-0642-44E8-B110-881D0FB7E21E"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <linearGradient
        id="19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_"
        x1="0"
        x2="0"
        y1="0"
        y2="1"
      >
        <stop offset="0%" stopColor="#bfd972"></stop>
        <stop offset="100%" stopColor="#5a7800"></stop>
      </linearGradient>
      <linearGradient
        id="19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_"
        x1="0"
        x2="0"
        y1="0"
        y2="1"
      >
        <stop offset="0%" stopColor="#688b00"></stop>
        <stop offset="100%" stopColor="#304100"></stop>
      </linearGradient>
      <linearGradient
        id="19-54.46232220802562-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_"
        x1="0"
        x2="0.714"
        y1="1"
        y2="0"
      >
        <stop offset="0%" stopColor="#6c9100"></stop>
        <stop offset="100%" stopColor="#e5efc6"></stop>
      </linearGradient>
      <filter
        id="1B411374-0C38-4831-97D4-F6288FE785E2"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="29DE1AA6-CAF8-41D3-ACB9-7347109DA2EC"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="69B26320-462C-4053-9272-49E5C50342A4"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="D43167BC-44A8-419C-A186-254C345F8EB3"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="7ECBA479-A493-40F8-B50D-A7A137C03E88"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="68EB1EAE-8B6A-43DB-8B72-CDD0C12FE935"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="80E8FFD5-54FC-4830-8EDD-2D9ECF18D967"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="5515CE04-EF83-48BA-BB44-CFABEF21D36C"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="F74C8EF8-37D2-41FD-BC1C-A7E436A3B5A4"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="D95548F6-8803-47E0-B5D9-BC041A24AE10"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="563CE25C-CEF6-456A-9F59-DEDD60F127C9"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="F01ADC36-2F72-486A-8E51-3ECF8A388437"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="9ABAE838-16CF-4C24-973E-A138BAC35419"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <filter
        id="519787EB-55B8-4841-8969-423C35750411"
        width="200%"
        height="200%"
      >
        <feOffset
          dx="1"
          dy="-1"
          in="SourceGraphic"
          result="offOut"
        ></feOffset>
        <feColorMatrix
          in="offOut"
          result="matrixOut"
          values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="matrixOut"
          result="blurOut"
          stdDeviation="1.732"
        ></feGaussianBlur>
        <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
      </filter>
      <linearGradient
        id="19-90-rgba_175_216_248_1__0-rgba_175_216_248_1__30-rgba_223_240_252_1__60-rgba_175_216_248_1__90-rgba_175_216_248_1__100"
        x1="0"
        x2="0"
        y1="1"
        y2="0"
      >
        <stop offset="0%" stopColor="#afd8f8"></stop>
        <stop offset="30%" stopColor="#afd8f8"></stop>
        <stop offset="60%" stopColor="#dff0fc"></stop>
        <stop offset="90%" stopColor="#afd8f8"></stop>
        <stop offset="100%" stopColor="#afd8f8"></stop>
      </linearGradient>
      <linearGradient
        id="19-90-rgba_246_189_15_1__0-rgba_246_189_15_1__30-rgba_252_229_159_1__60-rgba_246_189_15_1__90-rgba_246_189_15_1__100"
        x1="0"
        x2="0"
        y1="1"
        y2="0"
      >
        <stop offset="0%" stopColor="#f6bd0f"></stop>
        <stop offset="30%" stopColor="#f6bd0f"></stop>
        <stop offset="60%" stopColor="#fce59f"></stop>
        <stop offset="90%" stopColor="#f6bd0f"></stop>
        <stop offset="100%" stopColor="#f6bd0f"></stop>
      </linearGradient>
      <linearGradient
        id="19-90-rgba_139_186_0_1__0-rgba_139_186_0_1__30-rgba_209_228_153_1__60-rgba_139_186_0_1__90-rgba_139_186_0_1__100"
        x1="0"
        x2="0"
        y1="1"
        y2="0"
      >
        <stop offset="0%" stopColor="#8bba00"></stop>
        <stop offset="30%" stopColor="#8bba00"></stop>
        <stop offset="60%" stopColor="#d1e499"></stop>
        <stop offset="90%" stopColor="#8bba00"></stop>
        <stop offset="100%" stopColor="#8bba00"></stop>
      </linearGradient>
    </defs>
    <g className="raphael-group-20-background">
      <rect width="802" height="493" fill="#FFF" rx="0" ry="0"></rect>
      <rect
        width="802"
        height="493"
        fill="none"
        stroke="#666"
        strokeWidth="0"
        rx="0"
        ry="0"
      ></rect>
    </g>
    <g className="raphael-group-27-canvas">
      <rect
        width="704"
        height="336"
        x="79"
        y="55"
        fill="none"
        stroke="#545454"
        strokeWidth="0"
        rx="0"
        ry="0"
      ></rect>
      <path
        fill="url('#19-329.0909881288068-rgba_187_192_181_1_-rgba_236_240_232_1_')"
        strokeWidth="0"
        d="M783 55l3 3.6V388l-3 3z"
      ></path>
      <path
        fill="url('#19-45.88140399658214-rgb_134.16_145.86_119.34_-rgb_236.74_240.04_232.56_')"
        strokeLinejoin="round"
        strokeWidth="0"
        d="M68 402l1 1h703v-1l11-11H79z"
        shapeRendering="geometricprecision"
      ></path>
      <path
        fill="url('#19-270-rgb_129_140.25_114.75_-rgb_60.199999999999996_65.45_53.55_')"
        strokeLinejoin="round"
        strokeWidth="0"
        d="M771 403v9h1l11-11v-10l-11 11"
        shapeRendering="geometricprecision"
      ></path>
      <path
        fill="url('#19-270-rgb_209.35_217.6_198.9_-rgb_111.8_121.55_99.45_')"
        strokeLinejoin="round"
        strokeWidth="0"
        d="M772 402v10H68v-10z"
        shapeRendering="geometricprecision"
      ></path>
      <path fill="none" stroke="#FAFBF9" d="M79 391h704"></path>
      <rect
        width="704"
        height="336"
        x="79"
        y="55"
        fill="url('#19-329.0909881288068-rgba_187_192_181_1_-rgba_236_240_232_1_')"
        strokeWidth="0"
        rx="0"
        ry="0"
      ></rect>
    </g>
    <g className="raphael-group-35-axisbottom">
      <g
        fill="none"
        stroke="#ACBB99"
        className="raphael-group-46-y-axis-lines"
        shapeRendering="crispedges"
      >
        <path d="M79 324.5h704M79 257.5h704M79 189.5h704M79 122.5h704"></path>
      </g>
      <g className="fusioncharts-xaxis-0-gridlabels">
        <text
          x="128.571"
          y="427"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="128.571" dy="-2">
            Denmark
          </tspan>
        </text>
        <text
          x="227.714"
          y="427"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="227.714" dy="-2">
            France
          </tspan>
        </text>
        <text
          x="326.857"
          y="427"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="326.857" dy="-2.008">
            Germany
          </tspan>
        </text>
        <text
          x="426"
          y="427"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="426" dy="-2.008">
            Italy
          </tspan>
        </text>
        <text
          x="525.143"
          y="427"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="525.143" dy="-2.008">
            Spain
          </tspan>
        </text>
        <text
          x="624.286"
          y="427"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="624.286" dy="-2">
            UK
          </tspan>
        </text>
        <text
          x="723.429"
          y="427"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="723.429" dy="-2">
            USA
          </tspan>
        </text>
      </g>
      <g className="fusioncharts-yaxis-0-gridlabels">
        <text
          x="73"
          y="391"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          fontStyle="undefined"
          fontWeight="undefined"
          textAnchor="end"
        >
          <tspan x="73" dy="4">
            0.00
          </tspan>
        </text>
        <text
          x="73"
          y="323.8"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          fontStyle="undefined"
          fontWeight="undefined"
          textAnchor="end"
        >
          <tspan x="73" dy="3.995">
            10.00K
          </tspan>
        </text>
        <text
          x="73"
          y="256.6"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          fontStyle="undefined"
          fontWeight="undefined"
          textAnchor="end"
        >
          <tspan x="73" dy="3.998">
            20.00K
          </tspan>
        </text>
        <text
          x="73"
          y="189.4"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          fontStyle="undefined"
          fontWeight="undefined"
          textAnchor="end"
        >
          <tspan x="73" dy="4.002">
            30.00K
          </tspan>
        </text>
        <text
          x="73"
          y="122.2"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          fontStyle="undefined"
          fontWeight="undefined"
          textAnchor="end"
        >
          <tspan x="73" dy="4.005">
            40.00K
          </tspan>
        </text>
        <text
          x="73"
          y="55"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          fontStyle="undefined"
          fontWeight="undefined"
          textAnchor="end"
        >
          <tspan x="73" dy="4">
            50.00K
          </tspan>
        </text>
      </g>
      <g className="raphael-group-55-axis-name">
        <text
          fill="#49563A"
          className="fusioncharts-yaxis-0-title"
          fontSize="10"
          textAnchor="middle"
          transform="rotate(-90 120.5 102.5)"
        >
          <tspan x="0" dy="10">
            Orders
          </tspan>
        </text>
        <text
          fill="#49563A"
          className="fusioncharts-xaxis-0-title"
          fontSize="10"
          textAnchor="middle"
          transform="translate(431 432)"
        >
          <tspan x="0" dy="10">
            States
          </tspan>
        </text>
      </g>
    </g>
    <g className="raphael-group-21-dataset">
      <g className="raphael-group-75-columns">
        <g
          strokeLinejoin="round"
          className="raphael-group-78-positive-values"
          shapeRendering="geometricprecision"
        >
          <path
            fill="url('#19-54.46232220802562-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
            d="M103.571 382.235l1 1h49v-1l10-10h-50z"
            filter='url("#52C86AE6-861B-4C09-A579-E8ECC02F70F4")'
          ></path>
          <path
            fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
            d="M152.571 383.235V401h1l10-10v-18.765l-10 10"
            filter='url("#2DD0687F-763A-4EBB-BFCC-B655F844705E")'
          ></path>
          <path
            fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
            d="M153.571 382.235V401h-50v-18.765z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
            d="M103.571 372.853l1 1h49v-1l10-10h-50z"
            filter='url("#C60B6176-1BBC-4B15-9C2C-B31F45889E1C")'
          ></path>
          <path
            fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
            d="M152.571 373.853v8.382h1l10-10v-9.382l-10 10"
            filter='url("#3281B28B-53F7-4BDB-9924-61634D240AA2")'
          ></path>
          <path
            fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
            d="M153.571 372.853v9.382h-50v-9.382z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
            d="M103.571 368.162l1 1h49v-1l10-10h-50z"
            filter='url("#1B411374-0C38-4831-97D4-F6288FE785E2")'
          ></path>
          <path
            fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
            d="M152.571 369.162v3.69h1l10-10v-4.69l-10 10"
            filter='url("#29DE1AA6-CAF8-41D3-ACB9-7347109DA2EC")'
          ></path>
          <path
            fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
            d="M153.571 368.162v4.69h-50v-4.69z"
          ></path>
          <g>
            <path
              fill="url('#19-54.46232220802562-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
              d="M202.714 344.043l1 1h49v-1l10-10h-50z"
              filter='url("#887BE681-8FE1-4ADC-B8D2-3A63A855B17D")'
            ></path>
            <path
              fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
              d="M251.714 345.043V401h1l10-10v-56.957l-10 10"
              filter='url("#A73D83F1-0810-4BF7-8BBA-B295B3732CB8")'
            ></path>
            <path
              fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
              d="M252.714 344.043V401h-50v-56.957z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
              d="M202.714 315.565l1 1h49v-1l10-10h-50z"
              filter='url("#0CDE747D-8ADC-41D8-95B5-778FD335E952")'
            ></path>
            <path
              fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
              d="M251.714 316.565v27.478h1l10-10v-28.478l-10 10"
              filter='url("#8F47B448-E1BF-4DCF-9ED9-ABC6F5C9AFBA")'
            ></path>
            <path
              fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
              d="M252.714 315.565v28.478h-50v-28.478z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
              d="M202.714 301.326l1 1h49v-1l10-10h-50z"
              filter='url("#69B26320-462C-4053-9272-49E5C50342A4")'
            ></path>
            <path
              fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
              d="M251.714 302.326v13.24h1l10-10v-14.24l-10 10"
              filter='url("#D43167BC-44A8-419C-A186-254C345F8EB3")'
            ></path>
            <path
              fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
              d="M252.714 301.326v14.24h-50v-14.24z"
            ></path>
          </g>
          <g>
            <path
              fill="url('#19-54.46232220802562-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
              d="M301.857 249.353l1 1h49v-1l10-10h-50z"
              filter='url("#184E5DDE-4F68-401B-B09F-25F4A6DBFB01")'
            ></path>
            <path
              fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
              d="M350.857 250.353V401h1l10-10V239.353l-10 10"
              filter='url("#C0232B6C-AA26-4221-B41E-FC5C835AFDDC")'
            ></path>
            <path
              fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
              d="M351.857 249.353V401h-50V249.353z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
              d="M301.857 173.53l1 1h49v-1l10-10h-50z"
              filter='url("#7389532A-396A-403E-B4E4-D575FCFCBE1F")'
            ></path>
            <path
              fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
              d="M350.857 174.53v74.823h1l10-10v-75.824l-10 10"
              filter='url("#966AE348-1671-4DFA-946E-FA7790A5EFCC")'
            ></path>
            <path
              fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
              d="M351.857 173.53v75.823h-50v-75.824z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
              d="M301.857 135.617l1 1h49v-1l10-10h-50z"
              filter='url("#7ECBA479-A493-40F8-B50D-A7A137C03E88")'
            ></path>
            <path
              fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
              d="M350.857 136.617v36.912h1l10-10v-37.912l-10 10"
              filter='url("#68EB1EAE-8B6A-43DB-8B72-CDD0C12FE935")'
            ></path>
            <path
              fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
              d="M351.857 135.617v37.912h-50v-37.912z"
            ></path>
          </g>
          <g>
            <path
              fill="url('#19-54.46232220802562-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
              d="M401 389.382l1 1h49v-1l10-10h-50z"
              filter='url("#2069FE69-8AB6-44E4-ABDB-41DA9550F66B")'
            ></path>
            <path
              fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
              d="M450 390.382V401h1l10-10v-11.618l-10 10"
              filter='url("#45E58029-A90E-4891-A72D-445408AA3A9C")'
            ></path>
            <path
              fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
              d="M451 389.382V401h-50v-11.618z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
              d="M401 383.573l1 1h49v-1l10-10h-50z"
              filter='url("#E3C8DEFB-CEB5-48CE-A854-5FFB43B52198")'
            ></path>
            <path
              fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
              d="M450 384.573v4.809h1l10-10v-5.81l-10 10"
              filter='url("#B04F2155-C076-47E1-8843-02F1A28B4143")'
            ></path>
            <path
              fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
              d="M451 383.573v5.809h-50v-5.81z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
              d="M401 380.668l1 1h49v-1l10-10h-50z"
              filter='url("#80E8FFD5-54FC-4830-8EDD-2D9ECF18D967")'
            ></path>
            <path
              fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
              d="M450 381.668v1.905h1l10-10v-2.905l-10 10"
              filter='url("#5515CE04-EF83-48BA-BB44-CFABEF21D36C")'
            ></path>
            <path
              fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
              d="M451 380.668v2.905h-50v-2.905z"
            ></path>
          </g>
          <g>
            <path
              fill="url('#19-54.46232220802562-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
              d="M500.143 389.416l1 1h49v-1l10-10h-50z"
              filter='url("#C2D707E4-39C9-472C-ACF5-8E74B80BDE51")'
            ></path>
            <path
              fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
              d="M549.143 390.416V401h1l10-10v-11.584l-10 10"
              filter='url("#B1F77220-77C1-4C07-B474-90D3F86F0312")'
            ></path>
            <path
              fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
              d="M550.143 389.416V401h-50v-11.584z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
              d="M500.143 383.624l1 1h49v-1l10-10h-50z"
              filter='url("#2C437DC4-5433-4AD8-93BA-CEE0E3E7643C")'
            ></path>
            <path
              fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
              d="M549.143 384.624v4.792h1l10-10v-5.792l-10 10"
              filter='url("#208EF635-71CC-4741-A26C-B3A1A6452391")'
            ></path>
            <path
              fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
              d="M550.143 383.624v5.792h-50v-5.792z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
              d="M500.143 380.728l1 1h49v-1l10-10h-50z"
              filter='url("#F74C8EF8-37D2-41FD-BC1C-A7E436A3B5A4")'
            ></path>
            <path
              fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
              d="M549.143 381.728v1.896h1l10-10v-2.896l-10 10"
              filter='url("#D95548F6-8803-47E0-B5D9-BC041A24AE10")'
            ></path>
            <path
              fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
              d="M550.143 380.728v2.896h-50v-2.896z"
            ></path>
          </g>
          <g>
            <path
              fill="url('#19-54.46232220802562-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
              d="M599.286 361.295l1 1h49v-1l10-10h-50z"
              filter='url("#E04F4692-79CB-4426-9060-B1668D5F0610")'
            ></path>
            <path
              fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
              d="M648.286 362.295V401h1l10-10v-39.705l-10 10"
              filter='url("#8216FF2B-1DAC-4FCE-93EF-F6BEDC4761DB")'
            ></path>
            <path
              fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
              d="M649.286 361.295V401h-50v-39.705z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
              d="M599.286 341.442l1 1h49v-1l10-10h-50z"
              filter='url("#95E93C81-5466-4AA8-8C08-71D1A113090C")'
            ></path>
            <path
              fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
              d="M648.286 342.442v18.853h1l10-10v-19.853l-10 10"
              filter='url("#CAF18E34-DC4B-40B9-A95B-6E55DCC8EBE2")'
            ></path>
            <path
              fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
              d="M649.286 341.442v19.853h-50v-19.853z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
              d="M599.286 331.516l1 1h49v-1l10-10h-50z"
              filter='url("#563CE25C-CEF6-456A-9F59-DEDD60F127C9")'
            ></path>
            <path
              fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
              d="M648.286 332.516v8.926h1l10-10v-9.926l-10 10"
              filter='url("#F01ADC36-2F72-486A-8E51-3ECF8A388437")'
            ></path>
            <path
              fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
              d="M649.286 331.516v9.926h-50v-9.926z"
            ></path>
          </g>
          <g>
            <path
              fill="url('#19-54.46232220802562-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
              d="M698.429 215.914l1 1h49v-1l10-10h-50z"
              filter='url("#0022E5F1-3DFD-4AAE-A235-17B5716F4DA6")'
            ></path>
            <path
              fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
              d="M747.429 216.914V401h1l10-10V205.914l-10 10"
              filter='url("#78F99B5E-3556-4E7C-B5E9-6DE3DD69734D")'
            ></path>
            <path
              fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
              d="M748.429 215.914V401h-50V215.914z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
              d="M698.429 123.37l1 1h49v-1l10-10h-50z"
              filter='url("#FCFFC855-F680-4C20-8C31-CE0EA73376E9")'
            ></path>
            <path
              fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
              d="M747.429 124.37v91.544h1l10-10V113.37l-10 10"
              filter='url("#319E9C62-0642-44E8-B110-881D0FB7E21E")'
            ></path>
            <path
              fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
              d="M748.429 123.37v92.544h-50V123.37z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
              d="M698.429 77.1l1 1h49v-1l10-10h-50z"
              filter='url("#9ABAE838-16CF-4C24-973E-A138BAC35419")'
            ></path>
            <path
              fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
              d="M747.429 78.1v45.27h1l10-10V67.1l-10 10"
              filter='url("#519787EB-55B8-4841-8969-423C35750411")'
            ></path>
            <path
              fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
              d="M748.429 77.1v46.27h-50V77.1z"
            ></path>
          </g>
        </g>
      </g>
    </g>
    <g className="raphael-group-26-datalabels">
      <g className="raphael-group-254-datalabel">
        <text
          x="128.571"
          y="391.618"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="128.571" dy="4">
            2.79K
          </tspan>
        </text>
        <text
          x="227.714"
          y="372.522"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="227.714" dy="3.998">
            8.48K
          </tspan>
        </text>
        <text
          x="326.857"
          y="325.176"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="326.857" dy="3.997">
            22.57K
          </tspan>
        </text>
        <text
          x="426"
          y="393"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="426" dy="4">
            1.73K
          </tspan>
        </text>
        <text
          x="525.143"
          y="393"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="525.143" dy="4">
            1.72K
          </tspan>
        </text>
        <text
          x="624.286"
          y="381.147"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="624.286" dy="3.999">
            5.91K
          </tspan>
        </text>
        <text
          x="723.429"
          y="308.457"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="723.429" dy="3.996">
            27.54K
          </tspan>
        </text>
      </g>
      <g className="raphael-group-262-datalabel">
        <text
          x="128.571"
          y="377.544"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="128.571" dy="4.005">
            1.40K
          </tspan>
        </text>
        <text
          x="227.714"
          y="329.804"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="227.714" dy="4">
            4.24K
          </tspan>
        </text>
        <text
          x="326.857"
          y="211.441"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="326.857" dy="3.996">
            11.28K
          </tspan>
        </text>
        <text
          x="426"
          y="386.477"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="426" dy="4.001">
            864.44
          </tspan>
        </text>
        <text
          x="525.143"
          y="386.52"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="525.143" dy="3.997">
            861.89
          </tspan>
        </text>
        <text
          x="624.286"
          y="351.368"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="624.286" dy="4.001">
            2.95K
          </tspan>
        </text>
        <text
          x="723.429"
          y="169.642"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="723.429" dy="3.994">
            13.77K
          </tspan>
        </text>
      </g>
      <g className="raphael-group-270-datalabel">
        <text
          x="128.571"
          y="370.507"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="128.571" dy="3.999">
            698.10
          </tspan>
        </text>
        <text
          x="227.714"
          y="308.446"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="227.714" dy="4">
            2.12K
          </tspan>
        </text>
        <text
          x="326.857"
          y="154.573"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="326.857" dy="4.003">
            5.64K
          </tspan>
        </text>
        <text
          x="426"
          y="382.121"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="426" dy="4.003">
            432.22
          </tspan>
        </text>
        <text
          x="525.143"
          y="382.176"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="525.143" dy="3.997">
            430.95
          </tspan>
        </text>
        <text
          x="624.286"
          y="336.479"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="624.286" dy="4.002">
            1.48K
          </tspan>
        </text>
        <text
          x="723.429"
          y="100.235"
          fill="#49563A"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="723.429" dy="3.993">
            6.89K
          </tspan>
        </text>
      </g>
    </g>
    <g className="fusioncharts-legend" transform="translate(280.5 452.5)">
      <rect
        width="304"
        height="25"
        fill="none"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.05"
        strokeWidth="5"
        rx="0"
        ry="0"
        transform="translate(1 1)"
      ></rect>
      <rect
        width="304"
        height="25"
        fill="none"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.1"
        strokeWidth="3"
        rx="0"
        ry="0"
        transform="translate(1 1)"
      ></rect>
      <rect
        width="304"
        height="25"
        fill="none"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.15"
        rx="0"
        ry="0"
        transform="translate(1 1)"
      ></rect>
      <rect
        width="304"
        height="25"
        fill="#F0F3ED"
        stroke="#C6CFB8"
        rx="0"
        ry="0"
      ></rect>
      <text
        x="23"
        y="6.5"
        fill="#49563A"
        className="fusioncharts-legenditem"
        cursor="pointer"
        fontSize="10"
      >
        <tspan x="23" dy="9.25">
          Double Freight
        </tspan>
      </text>
      <path
        fill="url('#19-90-rgba_175_216_248_1__0-rgba_175_216_248_1__30-rgba_223_240_252_1__60-rgba_175_216_248_1__90-rgba_175_216_248_1__100')"
        stroke="#CFE8FB"
        strokeWidth="0.5"
        d="M7 18v-7.7h2.75V18zm4.125 0V7h2.75v11zm4.125 0v-4.4H18V18z"
        cursor="pointer"
      ></path>
      <rect
        width="95"
        height="17"
        x="4"
        y="4"
        fill="silver"
        fillOpacity="0"
        cursor="pointer"
        rx="0"
        ry="0"
      ></rect>
      <text
        x="128.85"
        y="6.5"
        fill="#49563A"
        className="fusioncharts-legenditem"
        cursor="pointer"
        fontSize="10"
      >
        <tspan x="128.85" dy="9.25">
          Normal Freight
        </tspan>
      </text>
      <path
        fill="url('#19-90-rgba_246_189_15_1__0-rgba_246_189_15_1__30-rgba_252_229_159_1__60-rgba_246_189_15_1__90-rgba_246_189_15_1__100')"
        stroke="#FAD76F"
        strokeWidth="0.5"
        d="M112.85 18v-7.7h2.75V18zm4.125 0V7h2.75v11zm4.125 0v-4.4h2.75V18z"
        cursor="pointer"
      ></path>
      <rect
        width="97"
        height="17"
        x="109.85"
        y="4"
        fill="silver"
        fillOpacity="0"
        cursor="pointer"
        rx="0"
        ry="0"
      ></rect>
      <text
        x="236.7"
        y="6.5"
        fill="#49563A"
        className="fusioncharts-legenditem"
        cursor="pointer"
        fontSize="10"
      >
        <tspan x="236.7" dy="9.25">
          Half Freight
        </tspan>
      </text>
      <path
        fill="url('#19-90-rgba_139_186_0_1__0-rgba_139_186_0_1__30-rgba_209_228_153_1__60-rgba_139_186_0_1__90-rgba_139_186_0_1__100')"
        stroke="#B9D666"
        strokeWidth="0.5"
        d="M220.7 18v-7.7h2.75V18zm4.125 0V7h2.75v11zm4.125 0v-4.4h2.75V18z"
        cursor="pointer"
      ></path>
      <rect
        width="80"
        height="17"
        x="217.7"
        y="4"
        fill="silver"
        fillOpacity="0"
        cursor="pointer"
        rx="0"
        ry="0"
      ></rect>
    </g>
    <g className="raphael-group-251-caption">
      <text
        x="431"
        y="16"
        fill="#49563A"
        className="fusioncharts-caption"
        fontFamily="Verdana, sans"
        fontSize="13"
        fontWeight="bold"
        textAnchor="middle"
      >
        <tspan x="431" dy="12.992">
          Chart Caption
        </tspan>
      </text>
      <text
        x="431"
        y="34"
        fill="#49563A"
        className="fusioncharts-subcaption"
        fontFamily="Verdana, sans"
        fontSize="11"
        fontWeight="bold"
        textAnchor="middle"
      >
        <tspan x="431" dy="10.852">
          Chart Sub Caption
        </tspan>
      </text>
    </g>
    <g className="raphael-group-22-hot">
      <g
        stroke="silver"
        strokeLinejoin="round"
        strokeOpacity="0"
        className="raphael-group-80-positive-values"
        shapeRendering="geometricprecision"
      >
        <path
          fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
          d="M103.571 382.235l1 1h49v-1l10-10h-50z"
        ></path>
        <path
          fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
          d="M152.571 383.235V401h1l10-10v-18.765l-10 10"
        ></path>
        <path
          fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
          d="M153.571 382.235V401h-50v-18.765z"
        ></path>
        <path
          fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
          d="M103.571 372.853l1 1h49v-1l10-10h-50z"
        ></path>
        <path
          fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
          d="M152.571 373.853v8.382h1l10-10v-9.382l-10 10"
        ></path>
        <path
          fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
          d="M153.571 372.853v9.382h-50v-9.382z"
        ></path>
        <path
          fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
          d="M103.571 368.007l1 1h49v-1l10-10h-50z"
        ></path>
        <path
          fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
          d="M152.571 369.007v4h1l10-10v-5l-10 10"
        ></path>
        <path
          fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
          d="M153.571 368.007v5h-50v-5z"
        ></path>
        <g>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M202.714 344.043l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M251.714 345.043V401h1l10-10v-56.957l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M252.714 344.043V401h-50v-56.957z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M202.714 315.565l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M251.714 316.565v27.478h1l10-10v-28.478l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M252.714 315.565v28.478h-50v-28.478z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M202.714 301.326l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M251.714 302.326v13.24h1l10-10v-14.24l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M252.714 301.326v14.24h-50v-14.24z"
          ></path>
        </g>
        <g>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M301.857 249.353l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M350.857 250.353V401h1l10-10V239.353l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M351.857 249.353V401h-50V249.353z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M301.857 173.53l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M350.857 174.53v74.823h1l10-10v-75.824l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M351.857 173.53v75.823h-50v-75.824z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M301.857 135.617l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M350.857 136.617v36.912h1l10-10v-37.912l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M351.857 135.617v37.912h-50v-37.912z"
          ></path>
        </g>
        <g>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M401 389.382l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M450 390.382V401h1l10-10v-11.618l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M451 389.382V401h-50v-11.618z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M401 383.573l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M450 384.573v4.809h1l10-10v-5.81l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M451 383.573v5.809h-50v-5.81z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M401 379.62l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M450 380.62v4h1l10-10v-5l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M451 379.62v5h-50v-5z"
          ></path>
        </g>
        <g>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M500.143 389.416l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M549.143 390.416V401h1l10-10v-11.584l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M550.143 389.416V401h-50v-11.584z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M500.143 383.624l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M549.143 384.624v4.792h1l10-10v-5.792l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M550.143 383.624v5.792h-50v-5.792z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M500.143 379.676l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M549.143 380.676v4h1l10-10v-5l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M550.143 379.676v5h-50v-5z"
          ></path>
        </g>
        <g>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M599.286 361.295l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M648.286 362.295V401h1l10-10v-39.705l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M649.286 361.295V401h-50v-39.705z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M599.286 341.442l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M648.286 342.442v18.853h1l10-10v-19.853l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M649.286 341.442v19.853h-50v-19.853z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M599.286 331.516l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M648.286 332.516v8.926h1l10-10v-9.926l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M649.286 331.516v9.926h-50v-9.926z"
          ></path>
        </g>
        <g>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M698.429 215.914l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M747.429 216.914V401h1l10-10V205.914l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M748.429 215.914V401h-50V215.914z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M698.429 123.37l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M747.429 124.37v91.544h1l10-10V113.37l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M748.429 123.37v92.544h-50V123.37z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M698.429 77.1l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M747.429 78.1v45.27h1l10-10V67.1l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M748.429 77.1v46.27h-50V77.1z"
          ></path>
        </g>
      </g>
    </g>
  </svg>)
};

export default StackedColumn3DChartIcon;