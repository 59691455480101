/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { MChartPlot } from "./MChartPlot";
import { PColor } from "../../../ui/primitive/UPColor";
import { setFonts } from "../../common/MFont";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { PString } from "../../../ui/primitive/UPString";

export const FPiePlot = {
    isShowLabels: new PBoolean({ id: 'isShowLabels', label: 'Show Labels', canBeNull: true }),
    isCircular: new PBoolean({ id: 'isCircular', label: 'Circular', canBeNull: true }),

    legendLabelFormat: new PString({ id: 'legendLabelFormat', label: 'Legend Label Format' }),
    labelFormat: new PString({ id: 'labelFormat', label: 'Label Format' }),

    color: new PColor({ id: 'color', label: 'Color' }),
    backgroundColor: new PColor({ id: 'backgroundColor', label: 'Background' })
};

export const MPiePlot: APDescriptor = {
    type: 'MPiePlot',
    path: 'piePlot',
    id: 'piePlotProeprtiesDescriptor',
    layouts: [...MChartPlot.layouts, FPiePlot.isShowLabels, FPiePlot.isCircular, FPiePlot.labelFormat, FPiePlot.legendLabelFormat,
    new PAccordeon({
        id: 'chart.value.item.label', label: 'Item Label', path: 'itemLabel',
        layouts: [FPiePlot.color, FPiePlot.backgroundColor, ...setFonts('font')]
    })
    ]
};
