/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { List, Map } from "immutable";
import { FUSIONWIDGETS_NAMESPACE, FusionWidgetTypes } from "../reader/JrxmlFusionWidgetUtils";
import { createChartDataset, createSectionHyperlinkElement } from "./JrxmlChartUtils";
import { createReportElement, setAttributeIfPresent, setExpressionNode } from "./JrxmlHelper";

const createFusionPropertyExpression = (name: string, itemElement: Map<string, any>, xmlDocument: Document, tagName: string, namespace: string): Element => {
    const propertyElement = xmlDocument.createElement(namespace + tagName);
    propertyElement.setAttribute('name', name);
    const expression = itemElement.get('propertyExpression');
    setExpressionNode(propertyElement, xmlDocument, expression, namespace + "propertyExpression");
    return propertyElement;
}

const getWidgetTagName = (widgetType: string) => {
    if (widgetType === FusionWidgetTypes.BULB){
        return 'bulb';
    } else if (widgetType === FusionWidgetTypes.HORIZONTAL_LED){
        return 'horizontalLED';
    } else if (widgetType === FusionWidgetTypes.VERTICAL_LED) {
        return 'verticalLED'
    } else if (widgetType === FusionWidgetTypes.THERMOMETER){
        return 'thermometer';
    } else if (widgetType === FusionWidgetTypes.CYLINDER) {
        return 'cylinder';
    } else if (widgetType === FusionWidgetTypes.HORIZONTAL_BULLET) {
        return 'horizontalBullet';
    } else if (widgetType === FusionWidgetTypes.VERTICAL_BULLET) {
        return 'verticalBullet';
    } else if (widgetType === FusionWidgetTypes.ANGULAR_GAUGE) {
        return 'angularGauge';
    } else if (widgetType === FusionWidgetTypes.LINEAR_GAUGE) {
        return 'linearGauge';
    } else if (widgetType === FusionWidgetTypes.FUNNEL) {
        return 'funnel';
    } else if (widgetType === FusionWidgetTypes.PYRAMID) {
        return 'pyramid';
    } else if (widgetType === FusionWidgetTypes.SPARK_LINE) {
        return 'sparkLine';
    } else if (widgetType === FusionWidgetTypes.SPARK_COLUMN) {
        return 'sparkColumn';
    } else if (widgetType === FusionWidgetTypes.SPARK_WIN_LOSS) {
        return 'sparkWinLoss';
    } else if (widgetType === FusionWidgetTypes.GANTT) {
        return 'ganttChart';
    }
}

const createColorRange = (colorRangeModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const colorRangeElement = xmlDocument.createElement(namespace + "colorRange");
    setAttributeIfPresent(colorRangeModel, "color", colorRangeElement);

    const minValueExpression = colorRangeModel.get('minValueExpression');
    setExpressionNode(colorRangeElement, xmlDocument, minValueExpression, namespace + "minValueExpression");
    const maxValueExpression = colorRangeModel.get('maxValueExpression');
    setExpressionNode(colorRangeElement, xmlDocument, maxValueExpression, namespace + "maxValueExpression");
    const labelExpression = colorRangeModel.get('labelExpression');
    setExpressionNode(colorRangeElement, xmlDocument, labelExpression, namespace + "labelExpression");

    return colorRangeElement;
}

const createAbstractWidget = (elementNode: Element, widgetModel: Map<string, any>, xmlDocument: Document, namespace: string) => {
    const widgetProperties : Map<string, Map<string, any>> | undefined = widgetModel.get('widgetProperties');
    if (widgetProperties){
        widgetProperties.forEach((widgetProperty, name) => {
            const widgetPropertyNode = createFusionPropertyExpression(name, widgetProperty, xmlDocument, 'widgetProperty', namespace);
            elementNode.appendChild(widgetPropertyNode);
        });
    }
    const hyperlink = widgetModel.get('hyperlink');
    if (hyperlink){
        const hyperlinkElement = createSectionHyperlinkElement(hyperlink, xmlDocument, namespace + 'hyperlink');  
        elementNode.appendChild(hyperlinkElement); 
    }
}

const createTrendPoint = (trendPointModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const trendPointElement = xmlDocument.createElement(namespace + "trendPoint");

    const trendPoinntProperties : Map<string, Map<string, any>> | undefined = trendPointModel.get('properties');
    if (trendPoinntProperties){
        trendPoinntProperties.forEach((trendPointProperty, name) => {
            const dialPropertyNode = createFusionPropertyExpression(name, trendPointProperty, xmlDocument, 'property', namespace);
            trendPointElement.appendChild(dialPropertyNode);
        })
    }
    const startValueExpression = trendPointModel.get('startValueExpression');
    setExpressionNode(trendPointElement, xmlDocument, startValueExpression, namespace + "startValueExpression");

    const endValueExpression = trendPointModel.get('endValueExpression');
    setExpressionNode(trendPointElement, xmlDocument, endValueExpression, namespace + "endValueExpression");

    const labelExpression = trendPointModel.get('labelExpression');
    setExpressionNode(trendPointElement, xmlDocument, labelExpression, namespace + "labelExpression");

    setAttributeIfPresent(trendPointModel, "color", trendPointElement);

    return trendPointElement;
}

const createDial = (dialModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const dialElement = xmlDocument.createElement(namespace + "dial");

    const dialProperties : Map<string, Map<string, any>> | undefined = dialModel.get('dialProperties');
    if (dialProperties){
        dialProperties.forEach((widgetProperty, name) => {
            const dialPropertyNode = createFusionPropertyExpression(name, widgetProperty, xmlDocument, 'dialProperty', namespace);
            dialElement.appendChild(dialPropertyNode);
        })
    }
    const valueExpression = dialModel.get('valueExpression');
    setExpressionNode(dialElement, xmlDocument, valueExpression, namespace + "valueExpression");
    const hyperlink = dialModel.get('hyperlink');
    if (hyperlink){
        const hyperlinkElement = createSectionHyperlinkElement(hyperlink, xmlDocument, namespace + 'hyperlink');  
        dialElement.appendChild(hyperlinkElement); 
    }
    return dialElement;
}

const createPointer = (pointerModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const pointerElement = xmlDocument.createElement(namespace + "dial");

    const properties : Map<string, Map<string, any>> | undefined = pointerModel.get('properties');
    if (properties){
        properties.forEach((pointerProperty, name) => {
            const pointerPropertyNode = createFusionPropertyExpression(name, pointerProperty, xmlDocument, 'property', namespace);
            pointerElement.appendChild(pointerPropertyNode);
        })
    }
    const valueExpression = pointerModel.get('valueExpression');
    setExpressionNode(pointerElement, xmlDocument, valueExpression, namespace + "valueExpression");
    const hyperlink = pointerModel.get('hyperlink');
    if (hyperlink){
        const hyperlinkElement = createSectionHyperlinkElement(hyperlink, xmlDocument, namespace + 'hyperlink');  
        pointerElement.appendChild(hyperlinkElement); 
    }
    return pointerElement;
}

const createAngularGaugeWidget = (elementNode: Element, widgetModel: Map<string, any>, xmlDocument: Document, namespace: string) => {
    createAbstractWidget(elementNode, widgetModel, xmlDocument, namespace);
    const colorRanges : List<Map<string, any>> | undefined = widgetModel.get('colorRanges');
    if (colorRanges){
        colorRanges.forEach((colorRangeModel) => {
            const colorRangeNode = createColorRange(colorRangeModel, xmlDocument, namespace);
            elementNode.appendChild(colorRangeNode);
        });
    }
    const datasetModel = widgetModel.get('dataset');
    if (datasetModel) {
        const dataset = createChartDataset(datasetModel, xmlDocument);
        elementNode.appendChild(dataset);
    }
    const dials : List<Map<string, any>> | undefined = widgetModel.get('dials');
    if (dials){
        dials.forEach((dialModel) => {
            const dialNode = createDial(dialModel, xmlDocument, namespace);
            elementNode.appendChild(dialNode);
        });
    }

    const trendPoints : List<Map<string, any>> | undefined = widgetModel.get('trendPoints');
    if (trendPoints){
        trendPoints.forEach((trendPoint) => {
            const trendPointNode = createTrendPoint(trendPoint, xmlDocument, namespace);
            elementNode.appendChild(trendPointNode);
        });
    }    
};

const createLinearGaugeWidget = (elementNode: Element, widgetModel: Map<string, any>, xmlDocument: Document, namespace: string) => {
    createAbstractWidget(elementNode, widgetModel, xmlDocument, namespace);
    const colorRanges : List<Map<string, any>> | undefined = widgetModel.get('colorRanges');
    if (colorRanges){
        colorRanges.forEach((colorRangeModel) => {
            const colorRangeNode = createColorRange(colorRangeModel, xmlDocument, namespace);
            elementNode.appendChild(colorRangeNode);
        });
    }
    const datasetModel = widgetModel.get('dataset');
    if (datasetModel) {
        const dataset = createChartDataset(datasetModel, xmlDocument);
        elementNode.appendChild(dataset);
    }
    const pointers : List<Map<string, any>> | undefined = widgetModel.get('pointers');
    if (pointers){
        pointers.forEach((pointerModel) => {
            const pointerNode = createPointer(pointerModel, xmlDocument, namespace);
            elementNode.appendChild(pointerNode);
        });
    }

    const trendPoints : List<Map<string, any>> | undefined = widgetModel.get('trendPoints');
    if (trendPoints){
        trendPoints.forEach((trendPoint) => {
            const trendPointNode = createTrendPoint(trendPoint, xmlDocument, namespace);
            elementNode.appendChild(trendPointNode);
        });
    }    
};

const createSingleValueWidget = (elementNode: Element, widgetModel: Map<string, any>, xmlDocument: Document, namespace: string) => {
    createAbstractWidget(elementNode, widgetModel, xmlDocument, namespace);
    const datasetModel = widgetModel.get('dataset');
    if (datasetModel) {
        const dataset = createChartDataset(datasetModel, xmlDocument);
        elementNode.appendChild(dataset);
    }
    const valueExpressionValue = widgetModel.get('valueExpression');
    setExpressionNode(elementNode, xmlDocument, valueExpressionValue, namespace + "valueExpression");
};

const createSingleValueRangeWidget = (elementNode: Element, widgetModel: Map<string, any>, xmlDocument: Document, namespace: string) => {
    createSingleValueWidget(elementNode, widgetModel, xmlDocument, namespace);
    const colorRanges : List<Map<string, any>> | undefined = widgetModel.get('colorRanges');
    if (colorRanges){
        colorRanges.forEach((colorRangeModel) => {
            const colorRangeNode = createColorRange(colorRangeModel, xmlDocument, namespace);
            elementNode.appendChild(colorRangeNode);
        })
    }
};

const createSingleValueTargetWidget = (elementNode: Element, widgetModel: Map<string, any>, xmlDocument: Document, namespace: string) => {
    createSingleValueWidget(elementNode, widgetModel, xmlDocument, namespace);
    const targetExpression = widgetModel.get('targetExpression');
    setExpressionNode(elementNode, xmlDocument, targetExpression, namespace + "targetExpression");
    const colorRanges : List<Map<string, any>> | undefined = widgetModel.get('colorRanges');
    if (colorRanges){
        colorRanges.forEach((colorRangeModel) => {
            const colorRangeNode = createColorRange(colorRangeModel, xmlDocument, namespace);
            elementNode.appendChild(colorRangeNode);
        });
    }
};

const createValueSet = (valuesSetModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const valuesSetElement = xmlDocument.createElement(namespace + "valueSet");

    const valueExpression = valuesSetModel.get('valueExpression');
    setExpressionNode(valuesSetElement, xmlDocument, valueExpression, namespace + "valueExpression");
    const labelExpression = valuesSetModel.get('labelExpression');
    setExpressionNode(valuesSetElement, xmlDocument, labelExpression, namespace + "labelExpression");
    
    const hyperlink = valuesSetModel.get('hyperlink');
    if (hyperlink){
        const hyperlinkElement = createSectionHyperlinkElement(hyperlink, xmlDocument, namespace + 'hyperlink');  
        valuesSetElement.appendChild(hyperlinkElement); 
    }

    const properties : Map<string, Map<string, any>> | undefined = valuesSetModel.get('properties');
    if (properties){
        properties.forEach((pointerProperty, name) => {
            const pointerPropertyNode = createFusionPropertyExpression(name, pointerProperty, xmlDocument, 'property', namespace);
            valuesSetElement.appendChild(pointerPropertyNode);
        })
    }

    return valuesSetElement;
}

const createValuesDataset = (valuesDatasetModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const valuesDatasetElement = xmlDocument.createElement(namespace + "valuesDataset");
    const datasetModel = valuesDatasetModel.get('dataset');
    if (datasetModel) {
        const dataset = createChartDataset(datasetModel, xmlDocument);
        valuesDatasetElement.appendChild(dataset);
    }

    const valueSets : List<Map<string, any>> = valuesDatasetModel.get('valueSets');
    if (valueSets){
        valueSets.forEach((valueSetModel) => {
            const valueSetElement = createValueSet(valueSetModel, xmlDocument, namespace);
            valuesDatasetElement.appendChild(valueSetElement);
        });
    }
    return valuesDatasetElement;
}

const createValueSetWidget = (elementNode: Element, widgetModel: Map<string, any>, xmlDocument: Document, namespace: string) => {
    createAbstractWidget(elementNode, widgetModel, xmlDocument, namespace);
    const valuesDataset : Map<string, any> | undefined = widgetModel.get('valuesDataset');
    if (valuesDataset) {
        elementNode.appendChild(createValuesDataset(valuesDataset, xmlDocument, namespace));
    }
};

const createChartTrendLine = (itemElement: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const trendLineElement = xmlDocument.createElement(namespace + "trendLine");
    setAttributeIfPresent(itemElement, "trendZone", trendLineElement);
    setAttributeIfPresent(itemElement, "color", trendLineElement);
    const startValueExpression = itemElement.get('startValueExpression');
    setExpressionNode(trendLineElement, xmlDocument, startValueExpression, namespace + "startValueExpression");
    const endValueExpression = itemElement.get('endValueExpression');
    setExpressionNode(trendLineElement, xmlDocument, endValueExpression, namespace + "endValueExpression");
    const labelExpression = itemElement.get('labelExpression');
    setExpressionNode(trendLineElement, xmlDocument, labelExpression, namespace + "labelExpression");
    return trendLineElement;
}

const createSparkValuesDataset = (valuesDatasetModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const valuesDatasetElement = xmlDocument.createElement(namespace + "valuesDataset");
    const datasetModel = valuesDatasetModel.get('dataset');
    if (datasetModel) {
        const dataset = createChartDataset(datasetModel, xmlDocument);
        valuesDatasetElement.appendChild(dataset);
    }

    const valueExpression = valuesDatasetModel.get('valueExpression');
    setExpressionNode(valuesDatasetElement, xmlDocument, valueExpression, namespace + "valueExpression");
    return valuesDatasetElement;
}

const createSparkChartWidget = (elementNode: Element, widgetModel: Map<string, any>, xmlDocument: Document, namespace: string) => {
    createAbstractWidget(elementNode, widgetModel, xmlDocument, namespace);
    
    const trendLines : List<Map<string, any>> | undefined = widgetModel.get('trendLines');
    if (trendLines){
        trendLines.forEach((chartTrendLine) => {
            const trendLineElement = createChartTrendLine(chartTrendLine, xmlDocument, namespace);
            elementNode.appendChild(trendLineElement);
        });
    }

    const valuesDataset : Map<string, any> | undefined = widgetModel.get('valuesDataset');
    if (valuesDataset) {
        elementNode.appendChild(createSparkValuesDataset(valuesDataset, xmlDocument, namespace));
    }
};

const createSparkWinLossItem = (itemModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const itemElement = xmlDocument.createElement(namespace + "item");
    const resultExpression = itemModel.get('resultExpression');
    setExpressionNode(itemElement, xmlDocument, resultExpression, namespace + "resultExpression");
    const scorelessExpression = itemModel.get('scorelessExpression');
    setExpressionNode(itemElement, xmlDocument, scorelessExpression, namespace + "scorelessExpression");
    return itemElement
} 

const createSparkWinLossValuesDataset = (valuesDatasetModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const valuesDatasetElement = xmlDocument.createElement(namespace + "valuesDataset");
    const datasetModel = valuesDatasetModel.get('dataset');
    if (datasetModel) {
        const dataset = createChartDataset(datasetModel, xmlDocument);
        valuesDatasetElement.appendChild(dataset);
    }

    const items : List<Map<string, any>> | undefined = valuesDatasetModel.get('items');
    if (items) {
        items.forEach((item) => {
            valuesDatasetElement.appendChild(createSparkWinLossItem(item, xmlDocument, namespace));
        });
    }

    return valuesDatasetElement;
}

const createSparkWinLossChartWidget = (elementNode: Element, widgetModel: Map<string, any>, xmlDocument: Document, namespace: string) => {
    createAbstractWidget(elementNode, widgetModel, xmlDocument, namespace);

    const valuesDataset : Map<string, any> | undefined = widgetModel.get('valuesDataset');
    if (valuesDataset) {
        elementNode.appendChild(createSparkWinLossValuesDataset(valuesDataset, xmlDocument, namespace));
    }
};

const createGanttCategory = (categoryModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const categoryElement = xmlDocument.createElement(namespace + "category");
    const categorySetElement = xmlDocument.createElement(namespace + "categorySet");
    const properties : Map<string, Map<string, any>> | undefined = categoryModel.get('categoryProperties');
    if (properties){
        properties.forEach((pointerProperty, name) => {
            const pointerPropertyNode = createFusionPropertyExpression(name, pointerProperty, xmlDocument, 'categoryProperty', namespace);
            categorySetElement.appendChild(pointerPropertyNode);
        })
    }

    const startExpression = categoryModel.get('startExpression');
    setExpressionNode(categoryElement, xmlDocument, startExpression, namespace + "startExpression");
    const endExpression = categoryModel.get('endExpression');
    setExpressionNode(categoryElement, xmlDocument, endExpression, namespace + "endExpression");
    const labelExpression = categoryModel.get('labelExpression');
    setExpressionNode(categoryElement, xmlDocument, labelExpression, namespace + "labelExpression");

    const hyperlink = categoryModel.get('hyperlink');
    if (hyperlink){
        const hyperlinkElement = createSectionHyperlinkElement(hyperlink, xmlDocument, namespace + 'hyperlink');  
        categoryElement.appendChild(hyperlinkElement); 
    }

    return categoryElement
}

const createGanttCategorySet = (categorySetModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const categorySetElement = xmlDocument.createElement(namespace + "categorySet");
    const properties : Map<string, Map<string, any>> | undefined = categorySetModel.get('categoriesProperties');
    if (properties){
        properties.forEach((pointerProperty, name) => {
            const pointerPropertyNode = createFusionPropertyExpression(name, pointerProperty, xmlDocument, 'categoriesProperty', namespace);
            categorySetElement.appendChild(pointerPropertyNode);
        })
    }
    const categories : List<Map<string, any>> = categorySetModel.get('categories');
    if (categories){
        categories.forEach((categoryModel) => {
            categorySetElement.appendChild(createGanttCategory(categoryModel, xmlDocument, namespace));
        });
    } else {
        const bulkCategoriesExpression = categorySetModel.get('bulkCategoriesExpression');
        if (bulkCategoriesExpression){
            setExpressionNode(categorySetElement, xmlDocument, bulkCategoriesExpression, namespace + "bulkCategoriesExpression");   
        }
    }
    return categorySetElement;
}

const createGanttProcess = (processModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const processElement = xmlDocument.createElement(namespace + "process");
    const processProperties : Map<string, Map<string, any>> = processModel.get('processProperties');
    if (processProperties){
        processProperties.forEach((propertyModel, propertyName) => {
            processElement.appendChild(createFusionPropertyExpression(propertyName, propertyModel, xmlDocument, 'processProperty', namespace));
        })
    }

    const idExpression = processModel.get('idExpression');
    setExpressionNode(processElement, xmlDocument, idExpression, namespace + "idExpression");
    const labelExpression = processModel.get('labelExpression');
    setExpressionNode(processElement, xmlDocument, labelExpression, namespace + "labelExpression");

    const hyperlink = processModel.get('hyperlink');
    if (hyperlink){
        const hyperlinkElement = createSectionHyperlinkElement(hyperlink, xmlDocument, namespace + 'hyperlink');  
        processElement.appendChild(hyperlinkElement); 
    }

    return processElement;
}

const createGanttProcesses = (processesModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const processesElement = xmlDocument.createElement(namespace + "processes");
    const processesProperties : Map<string, Map<string, any>> = processesModel.get('processesProperties');
    if (processesProperties){
        processesProperties.forEach((propertyModel, propertyName) => {
            processesElement.appendChild(createFusionPropertyExpression(propertyName, propertyModel, xmlDocument, 'processesProperty', namespace));
        })
    }

    const datasetModel = processesModel.get('dataset');
    if (datasetModel) {
        const dataset = createChartDataset(datasetModel, xmlDocument);
        processesElement.appendChild(dataset);
    }

    const processes : List<Map<string, any>> = processesModel.get('processes');
    if (processes){
        processes.forEach((processModel) => {
            processesElement.appendChild(createGanttProcess(processModel, xmlDocument, namespace));
        })
    }


    return processesElement;
}

const createGanttMilestone = (processModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const milestoneElement = xmlDocument.createElement(namespace + "milestone");
    const milestoneProperties : Map<string, Map<string, any>> = processModel.get('milestoneProperties');
    if (milestoneProperties){
        milestoneProperties.forEach((propertyModel, propertyName) => {
            milestoneElement.appendChild(createFusionPropertyExpression(propertyName, propertyModel, xmlDocument, 'milestoneProperty', namespace));
        })
    }

    const idExpression = processModel.get('idExpression');
    setExpressionNode(milestoneElement, xmlDocument, idExpression, namespace + "idExpression");
    const dateExpression = processModel.get('dateExpression');
    setExpressionNode(milestoneElement, xmlDocument, dateExpression, namespace + "dateExpression");

    const hyperlink = processModel.get('hyperlink');
    if (hyperlink){
        const hyperlinkElement = createSectionHyperlinkElement(hyperlink, xmlDocument, namespace + 'hyperlink');  
        milestoneElement.appendChild(hyperlinkElement); 
    }

    return milestoneElement;
}

const createGanttTask = (taskModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const taskElement = xmlDocument.createElement(namespace + "task");
    const taskProperties : Map<string, Map<string, any>> = taskModel.get('taskProperties');
    if (taskProperties){
        taskProperties.forEach((propertyModel, propertyName) => {
            taskElement.appendChild(createFusionPropertyExpression(propertyName, propertyModel, xmlDocument, 'taskProperty', namespace));
        })
    }

    const idExpression = taskModel.get('idExpression');
    setExpressionNode(taskElement, xmlDocument, idExpression, namespace + "idExpression");

    const processIdExpression = taskModel.get('processIdExpression');
    if (processIdExpression){
        setExpressionNode(taskElement, xmlDocument, processIdExpression, namespace + "processIdExpression");
    } else {
        const processesModel : Map<string, any> = taskModel.get('process');
        if (processesModel) {
            taskElement.appendChild(createGanttProcess(processesModel, xmlDocument, namespace));
        }
    }

    const startExpression = taskModel.get('startExpression');
    setExpressionNode(taskElement, xmlDocument, startExpression, namespace + "startExpression");

    const endExpression = taskModel.get('endExpression');
    setExpressionNode(taskElement, xmlDocument, endExpression, namespace + "endExpression");

    const labelExpression = taskModel.get('labelExpression');
    setExpressionNode(taskElement, xmlDocument, labelExpression, namespace + "labelExpression");

    const percentCompleteExpression = taskModel.get('percentCompleteExpression');
    setExpressionNode(taskElement, xmlDocument, percentCompleteExpression, namespace + "percentCompleteExpression");

    const isGroupExpression = taskModel.get('isGroupExpression');
    setExpressionNode(taskElement, xmlDocument, isGroupExpression, namespace + "isGroupExpression");

    const hyperlink = taskModel.get('hyperlink');
    if (hyperlink){
        const hyperlinkElement = createSectionHyperlinkElement(hyperlink, xmlDocument, namespace + 'hyperlink');  
        taskElement.appendChild(hyperlinkElement); 
    }

    const milestoneModel : Map<string, any> = taskModel.get('milestone');
    if (milestoneModel) {
        taskElement.appendChild(createGanttMilestone(milestoneModel, xmlDocument, namespace));
    }

    return taskElement;
}

const createGanttTasks = (tasksModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const tasksElement = xmlDocument.createElement(namespace + "tasks");
    const tasksProperties : Map<string, Map<string, any>> = tasksModel.get('tasksProperties');
    if (tasksProperties){
        tasksProperties.forEach((propertyModel, propertyName) => {
            tasksElement.appendChild(createFusionPropertyExpression(propertyName, propertyModel, xmlDocument, 'tasksProperty', namespace));
        })
    }

    const datasetModel = tasksModel.get('dataset');
    if (datasetModel) {
        const dataset = createChartDataset(datasetModel, xmlDocument);
        tasksElement.appendChild(dataset);
    }

    const tasks : List<Map<string, any>> = tasksModel.get('tasks');
    if (tasks){
        tasks.forEach((tasksModel) => {
            tasksElement.appendChild(createGanttTask(tasksModel, xmlDocument, namespace));
        })
    }

    return tasksElement;
}

const createGanttDataItem = (dataItemModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const dataItemElement = xmlDocument.createElement(namespace + "item");
    const itemProperties : Map<string, Map<string, any>> = dataItemModel.get('itemProperties');
    if (itemProperties){
        itemProperties.forEach((propertyModel, propertyName) => {
            dataItemElement.appendChild(createFusionPropertyExpression(propertyName, propertyModel, xmlDocument, 'itemProperty', namespace));
        })
    }

    const textExpression = dataItemModel.get('textExpression');
    setExpressionNode(dataItemElement, xmlDocument, textExpression, namespace + "textExpression");



    const hyperlink = dataItemModel.get('hyperlink');
    if (hyperlink){
        const hyperlinkElement = createSectionHyperlinkElement(hyperlink, xmlDocument, namespace + 'hyperlink');  
        dataItemElement.appendChild(hyperlinkElement); 
    }

    return dataItemElement;
}

const createGanttDataColumn = (dataColumnModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const dataColumnElement = xmlDocument.createElement(namespace + "datacolumn");

    const columnProperties : Map<string, Map<string, any>> = dataColumnModel.get('columnProperties');
    if (columnProperties){
        columnProperties.forEach((propertyModel, propertyName) => {
            dataColumnElement.appendChild(createFusionPropertyExpression(propertyName, propertyModel, xmlDocument, 'columnProperty', namespace));
        });
    }

    const idExpression = dataColumnModel.get('headerTextExpression');
    setExpressionNode(dataColumnElement, xmlDocument, idExpression, namespace + "headerTextExpression");

    const hyperlink = dataColumnModel.get('headerHyperlink');
    if (hyperlink){
        const hyperlinkElement = createSectionHyperlinkElement(hyperlink, xmlDocument, namespace + 'headerHyperlink');  
        dataColumnElement.appendChild(hyperlinkElement); 
    }

    const items : List<Map<string, any>> = dataColumnModel.get('items');
    if (items) {
        items.forEach((dataItemModel) => {
            dataColumnElement.appendChild(createGanttDataItem(dataItemModel, xmlDocument, namespace));
        });
    }

    return dataColumnElement;
}

const createGanttDatatable = (tasksModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const datatableElement = xmlDocument.createElement(namespace + "datatable");
    const datatableProperties : Map<string, Map<string, any>> = tasksModel.get('datatableProperties');
    if (datatableProperties){
        datatableProperties.forEach((propertyModel, propertyName) => {
            datatableElement.appendChild(createFusionPropertyExpression(propertyName, propertyModel, xmlDocument, 'datatableProperty', namespace));
        })
    }

    const dataColumns : List<Map<string, any>> = tasksModel.get('dataColumns');
    if (dataColumns){
        dataColumns.forEach((tasksModel) => {
            datatableElement.appendChild(createGanttDataColumn(tasksModel, xmlDocument, namespace));
        })
    }

    return datatableElement;
}

const createGanttConnector = (connectorModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const connectorElement = xmlDocument.createElement(namespace + "connector");

    const connectorProperties : Map<string, Map<string, any>> = connectorModel.get('connectorProperties');
    if (connectorProperties){
        connectorProperties.forEach((propertyModel, propertyName) => {
            connectorElement.appendChild(createFusionPropertyExpression(propertyName, propertyModel, xmlDocument, 'connectorProperty', namespace));
        });
    }

    const fromTaskIdExpression = connectorModel.get('fromTaskIdExpression');
    setExpressionNode(connectorElement, xmlDocument, fromTaskIdExpression, namespace + "fromTaskIdExpression");

    const toTaskIdExpression = connectorModel.get('toTaskIdExpression');
    setExpressionNode(connectorElement, xmlDocument, toTaskIdExpression, namespace + "toTaskIdExpression");

    const fromTaskConnectStartExpression = connectorModel.get('fromTaskConnectStartExpression');
    setExpressionNode(connectorElement, xmlDocument, fromTaskConnectStartExpression, namespace + "fromTaskConnectStartExpression");

    const toTaskConnectEndExpression = connectorModel.get('toTaskConnectEndExpression');
    setExpressionNode(connectorElement, xmlDocument, toTaskConnectEndExpression, namespace + "toTaskConnectEndExpression");


    return connectorElement;
}

const createGanttConnectors = (connectorsModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const connectorsElemenet = xmlDocument.createElement(namespace + "connectors");
    const connectorsProperties : Map<string, Map<string, any>> = connectorsModel.get('connectorsProperties');
    if (connectorsProperties){
        connectorsProperties.forEach((propertyModel, propertyName) => {
            connectorsElemenet.appendChild(createFusionPropertyExpression(propertyName, propertyModel, xmlDocument, 'connectorsProperty', namespace));
        })
    }

    const datasetModel = connectorsModel.get('dataset');
    if (datasetModel) {
        const dataset = createChartDataset(datasetModel, xmlDocument);
        connectorsElemenet.appendChild(dataset);
    }


    const connectors : List<Map<string, any>> = connectorsModel.get('connectors');
    if (connectors){
        connectors.forEach((tasksModel) => {
            connectorsElemenet.appendChild(createGanttConnector(tasksModel, xmlDocument, namespace));
        })
    }

    return connectorsElemenet;
}

const createGanttMilestones = (milestonesModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const milestonesElemenet = xmlDocument.createElement(namespace + "milestones");

    const datasetModel = milestonesModel.get('dataset');
    if (datasetModel) {
        const dataset = createChartDataset(datasetModel, xmlDocument);
        milestonesElemenet.appendChild(dataset);
    }

    const milestones : List<Map<string, any>> = milestonesModel.get('milestones');
    if (milestones){
        milestones.forEach((milestoneModel) => {
            milestonesElemenet.appendChild(createGanttMilestone(milestoneModel, xmlDocument, namespace));
        })
    }

    return milestonesElemenet;
}

const createGanttLegendItem = (legendItemModel: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const legendITemElement = xmlDocument.createElement(namespace + "legendItem");
    setAttributeIfPresent(legendItemModel, "color", legendITemElement);

    const labelExpression = legendItemModel.get('labelExpression');
    setExpressionNode(legendITemElement, xmlDocument, labelExpression, namespace + "labelExpression");

    return legendITemElement;
}

const createGanttChartWidget = (elementNode: Element, widgetModel: Map<string, any>, xmlDocument: Document, namespace: string) => {
    createAbstractWidget(elementNode, widgetModel, xmlDocument, namespace);

    const categorySets : List<Map<string, any>> | undefined = widgetModel.get('categorySets');
    if (categorySets) {
        categorySets.forEach((categorySetModel) => {
            elementNode.appendChild(createGanttCategorySet(categorySetModel, xmlDocument, namespace));
        })
    }

    const processesModel = widgetModel.get('processes');
    if (processesModel) {
        elementNode.appendChild(createGanttProcesses(processesModel, xmlDocument, namespace));
    }

    const tasksModel = widgetModel.get('tasks');
    if (tasksModel) {
        elementNode.appendChild(createGanttTasks(tasksModel, xmlDocument, namespace));
    }

    const datatableModel = widgetModel.get('datatable');
    if (datatableModel) {
        elementNode.appendChild(createGanttDatatable(datatableModel, xmlDocument, namespace));
    }

    const connectorsModel = widgetModel.get('connectors');
    if (connectorsModel) {
        elementNode.appendChild(createGanttConnectors(connectorsModel, xmlDocument, namespace));
    }

    const milestonesModel = widgetModel.get('milestones');
    if (milestonesModel) {
        elementNode.appendChild(createGanttMilestones(milestonesModel, xmlDocument, namespace));
    }

    const trendLines : List<Map<string, any>> = widgetModel.get('trendLines');
    if (trendLines) {
        trendLines.forEach((trendLineModel) => {
            elementNode.appendChild(createChartTrendLine(trendLineModel, xmlDocument, namespace));
        });
    }

    const legendItems : List<Map<string, any>> = widgetModel.get('legendItems');
    if (legendItems) {
        legendItems.forEach((trendLineModel) => {
            elementNode.appendChild(createGanttLegendItem(trendLineModel, xmlDocument, namespace));
        });
    }
    setAttributeIfPresent(widgetModel, "useDate", elementNode);
    setAttributeIfPresent(widgetModel, "useTime", elementNode);
};

/**
 * Creates the Fusion chart information for the final JRXML.
 *
 * @param elementNode the JSON map containing the Fusion chart details
 * @param xmlDocument the XML document
 */
export const createFusionWidgetElement = (elementNode: Map<string, any>, xmlDocument: Document): Element => {
    const componentElement = xmlDocument.createElement("componentElement");
    const reportElement = createReportElement(elementNode, xmlDocument);
    componentElement.appendChild(reportElement);

    const baseNamespace = elementNode.get(FUSIONWIDGETS_NAMESPACE, "fw");
    const namespace = baseNamespace + ":";

    const widgetType = elementNode.get('widgetType');
    const fusionWidgetElement = xmlDocument.createElement(namespace + getWidgetTagName(widgetType));

    if (widgetType === FusionWidgetTypes.THERMOMETER || widgetType === FusionWidgetTypes.CYLINDER){
        createSingleValueWidget(fusionWidgetElement, elementNode, xmlDocument, namespace);
    } else if (widgetType === FusionWidgetTypes.BULB || widgetType === FusionWidgetTypes.HORIZONTAL_LED || widgetType === FusionWidgetTypes.VERTICAL_LED){
        createSingleValueRangeWidget(fusionWidgetElement, elementNode, xmlDocument, namespace);
    } else if (widgetType === FusionWidgetTypes.HORIZONTAL_BULLET || widgetType === FusionWidgetTypes.VERTICAL_BULLET){
        createSingleValueTargetWidget(fusionWidgetElement, elementNode, xmlDocument, namespace);
    } else if (widgetType === FusionWidgetTypes.ANGULAR_GAUGE){
        createAngularGaugeWidget(fusionWidgetElement, elementNode, xmlDocument, namespace);
    } else if (widgetType === FusionWidgetTypes.LINEAR_GAUGE){
        createLinearGaugeWidget(fusionWidgetElement, elementNode, xmlDocument, namespace);
    } else if (widgetType === FusionWidgetTypes.FUNNEL || widgetType === FusionWidgetTypes.PYRAMID){
        createValueSetWidget(fusionWidgetElement, elementNode, xmlDocument, namespace);
    } else if (widgetType === FusionWidgetTypes.SPARK_LINE || widgetType === FusionWidgetTypes.SPARK_COLUMN){
        createSparkChartWidget(fusionWidgetElement, elementNode, xmlDocument, namespace);
    } else if (widgetType === FusionWidgetTypes.SPARK_WIN_LOSS){
        createSparkWinLossChartWidget(fusionWidgetElement, elementNode, xmlDocument, namespace);
    } else if (widgetType === FusionWidgetTypes.GANTT){
        createGanttChartWidget(fusionWidgetElement, elementNode, xmlDocument, namespace);
    }


    fusionWidgetElement.setAttribute("xmlns:" + baseNamespace, elementNode.get("xmlns:" + baseNamespace, "http://jaspersoft.com/fusion"));
    fusionWidgetElement.setAttribute("xsi:schemaLocation", elementNode.get("xsi:schemaLocation", "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd"));

    setAttributeIfPresent(elementNode, "evaluationTime", fusionWidgetElement);
    setAttributeIfPresent(elementNode, "evaluationGroup", fusionWidgetElement);
    componentElement.appendChild(fusionWidgetElement);

    return componentElement;
}

