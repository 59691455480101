/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StackedColumnChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath id="highcharts-r0it848-1">
          <path fill="none" d="M0 0H507V672H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M38 10H545V682H38z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" d="M79.5 10v672M122.5 10v672M164.5 10v672M206.5 10v672M248.5 10v672M291.5 10v672M333.5 10v672M375.5 10v672M417.5 10v672M460.5 10v672M502.5 10v672M544.5 10v672M37.5 10v672" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M38 682.5h507M38 598.5h507M38 514.5h507M38 430.5h507M38 346.5h507M38 262.5h507M38 178.5h507M38 94.5h507M38 9.5h507" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M38 10H545V682H38z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" stroke="#ccd6eb" d="M79.5 682v10M122.5 682v10M164.5 682v10M206.5 682v10M248.5 682v10M291.5 682v10M333.5 682v10M375.5 682v10M417.5 682v10M460.5 682v10M502.5 682v10M545.5 682v10M37.5 682v10" className="highcharts-tick" />
       <path fill="none" stroke="#ccd6eb" d="M38 682.5h507" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M38 10v672" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-column-series highcharts-color-0 highcharts-tracker" clipPath="url(#highcharts-r0it848-1)" data-z-index="0.1" transform="translate(38 10)">
          <path fill="#7cb5ec" stroke="#fff" d="M10.5 493.5H31.5V549.5H10.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M52.5 589.5H73.5V618.5H52.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M94.5 111.5H115.5V147.5H94.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M179.5 655.5H200.5V659.5H179.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M306.5 625.5H327.5V661.5H306.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M348.5 643.5H369.5V646.5H348.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M432.5 558.5H453.5V561.5H432.5z" className="highcharts-point highcharts-color-0" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-column-series highcharts-color-1 highcharts-tracker" clipPath="url(#highcharts-r0it848-1)" data-z-index="0.1" transform="translate(38 10)">
          <path fill="#434348" stroke="#fff" d="M10.5 549.5H31.5V621.5H10.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M52.5 618.5H73.5V638.5H52.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M94.5 147.5H115.5V408.5H94.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M137.5 661.5H158.5V671.5H137.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M179.5 659.5H200.5V672.5H179.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M221.5 583.5H242.5V607.5H221.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M263.5 666.5H284.5V671.5H263.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M306.5 661.5H327.5V668.5H306.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M348.5 646.5H369.5V670.5H348.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M390.5 655.5H411.5V664.5H390.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M432.5 561.5H453.5V602.5H432.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M475.5 670.5H496.5V672.5H475.5z" className="highcharts-point highcharts-color-1" />
       </g>
       <g className="highcharts-series highcharts-series-2 highcharts-column-series highcharts-color-2 highcharts-tracker" clipPath="url(#highcharts-r0it848-1)" data-z-index="0.1" transform="translate(38 10)">
          <path fill="#90ed7d" stroke="#fff" d="M10.5 621.5H31.5V672.5H10.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M52.5 638.5H73.5V672.5H52.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M94.5 408.5H115.5V672.5H94.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M137.5 671.5H158.5V672.5H137.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M221.5 607.5H242.5V672.5H221.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M263.5 671.5H284.5V672.5H263.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M306.5 668.5H327.5V672.5H306.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M348.5 670.5H369.5V672.5H348.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M390.5 664.5H411.5V672.5H390.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M432.5 602.5H453.5V672.5H432.5z" className="highcharts-point highcharts-color-2" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(178 762)">
       <rect width="199" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-column-series highcharts-color-0 highcharts-series-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1996</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-column-series highcharts-color-1 highcharts-series-1" data-z-index="1" transform="translate(75.672 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1997</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-column-series highcharts-color-2 highcharts-series-2" data-z-index="1" transform="translate(143.344 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1998</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#90ed7d" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="61.718" y="698" transform="rotate(-45 61.718 698)">
          <tspan>Albuquerque</tspan>
       </text>
       <text x="103.968" y="698" transform="rotate(-45 103.968 698)">Anchorage</text>
       <text x="146.218" y="698" transform="rotate(-45 146.218 698)">Boise</text>
       <text x="188.468" y="698" transform="rotate(-45 188.468 698)">Butte</text>
       <text x="230.718" y="698" transform="rotate(-45 230.718 698)">Elgin</text>
       <text x="272.968" y="698" transform="rotate(-45 272.968 698)">Eugene</text>
       <text x="315.218" y="698" transform="rotate(-45 315.218 698)">Kirkland</text>
       <text x="357.468" y="698" transform="rotate(-45 357.468 698)">Lander</text>
       <text x="399.718" y="698" transform="rotate(-45 399.718 698)">Portland</text>
       <text x="441.968" y="698" transform="rotate(-45 441.968 698)">
          <tspan>San Francisco</tspan>
       </text>
       <text x="484.218" y="698" transform="rotate(-45 484.218 698)">Seattle</text>
       <text x="526.468" y="698" transform="rotate(-45 526.468 698)">
          <tspan>Walla Walla</tspan>
       </text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="23" y="687">0</text>
       <text x="23" y="603">1k</text>
       <text x="23" y="519">2k</text>
       <text x="23" y="435">3k</text>
       <text x="23" y="351">4k</text>
       <text x="23" y="267">5k</text>
       <text x="23" y="183">6k</text>
       <text x="23" y="99">7k</text>
       <text x="23" y="15">8k</text>
    </g>
 </svg>)};

export default StackedColumnChartIcon;