/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const PieChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M5 0v150.688h260V0z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M61.75 157.188L208.25 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M61.75 173.5L208.25 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M61.75 173.5L61.75 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M208.25 173.5L208.25 157.188" clipPath="url(#clipPath1)" />
            <path fill="#F55" stroke="none" d="M73.25 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
         </g>
         <g fill="gray" stroke="gray" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M73.25 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <text x="77.25" y="168.113" fill="#000" stroke="none" clipPath="url(#clipPath1)">Austria 7.391,5</text>
            <path fill="#55F" stroke="none" d="M152.59 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <path fill="none" d="M152.59 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <text x="156.59" y="168.113" fill="#000" stroke="none" clipPath="url(#clipPath1)">Other 8.957</text>
            <path stroke="none" d="M139 19.22c29.726 0 54.99 21.723 59.445 51.114 4.455 29.391-13.238 57.625-41.63 66.434L139 79.344z" clipPath="url(#clipPath2)" />
            <path stroke="none" d="M156.816 136.768a60.124 60.124 0 01-72.633-82.123A60.124 60.124 0 01139 19.22v60.125z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M135 15.22a60.124 60.124 0 0117.816 117.548L135 75.344z" clipPath="url(#clipPath2)" />
            <path fill="none" d="M135 15.22a60.124 60.124 0 0117.816 117.548L135 75.344z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M152.816 132.768A60.124 60.124 0 11135 15.219v60.125z" clipPath="url(#clipPath2)" />
            <path fill="none" d="M152.816 132.768A60.124 60.124 0 11135 15.219v60.125z" clipPath="url(#clipPath2)" />
         </g>
         <g fill="none" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path d="M81.499 83.452L72.426 84.828" clipPath="url(#clipPath2)" />
            <path d="M71.711 84.828L72.426 84.828" clipPath="url(#clipPath2)" />
            <path d="M71.711 84.828L65.248 84.828" clipPath="url(#clipPath2)" />
         </g>
         <path fill="#979797" fillOpacity="0.502" stroke="none" strokeOpacity="0.502" strokeWidth="0.5" d="M20.209 80.171H67.248V93.48400000000001H20.209z" clipPath="url(#clipPath2)" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility" />
         <g fill="#fff" stroke="#fff" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path stroke="none" d="M18.209 78.171H65.248V91.48400000000001H18.209z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M18.209 78.171H65.248V91.48400000000001H18.209z" clipPath="url(#clipPath2)" />
            <text x="20.209" y="87.597" fill="#000" stroke="none" clipPath="url(#clipPath2)">Other 55%</text>
            <path fill="none" stroke="#000" d="M188.501 67.235L197.574 65.86" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M198.289 65.86L197.574 65.86" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M198.289 65.86L204.752 65.86" clipPath="url(#clipPath2)" />
         </g>
         <path fill="#979797" fillOpacity="0.502" stroke="none" strokeOpacity="0.502" strokeWidth="0.5" d="M206.752 61.204H259.338V74.517H206.752z" clipPath="url(#clipPath2)" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility" />
         <g fill="#fff" stroke="#fff" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path stroke="none" d="M204.752 59.204H257.338V72.517H204.752z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M204.752 59.204H257.338V72.517H204.752z" clipPath="url(#clipPath2)" />
            <text x="206.752" y="68.629" fill="#000" stroke="none" clipPath="url(#clipPath2)">Austria 45%</text>
         </g>
      </svg>
   );
}
export default PieChartIcon;