/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { TextInput } from '@jss/js-common';
import { advancedNameValidator, isValidUrl, ValidationResult, VALIDATION_RESULT } from '@jss/js-common/src/utils/validators';
import * as React from 'react';
import '../../assets/css/DataAdapterTypePicker.css';
import i18n from '../../i18n';

interface IMongoDBDataAdapterEditor {
    name?: string,
    mongoURI?: string,
    username?: string,
    password?: string,
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

export const mongoDBUrlValidator = (value: string | undefined): ValidationResult => {
    if (!value || value.trim().length === 0) {
        return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.url.invalidURL') };
    } else {
        if (!value.startsWith('mongodb://')) {
            return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.url.invalidJRSURL') };
        }
        //it is an url validate it
        if (!isValidUrl(value)) {
            return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.url.invalidURL') };
        }
    }
    return { result: VALIDATION_RESULT.VALID };
}

export const simpleMongoDBUrlValidator = (value: string | undefined) => {
    if (!value || value.trim().length === 0){
        return i18n.t('validator.url.invalidURL');
    } else {
        if (!value.startsWith('mongodb://')){
            return i18n.t('validator.url.invalidJRSURL');
        }
        //it is an url validate it
        if (!isValidUrl(value)){
            return i18n.t('validator.url.invalidURL');
        }
    }
    return undefined;
}

export class MongoDBDataAdapterEditor extends React.Component<IMongoDBDataAdapterEditor> {

    public render() {
        return (<div className="tc-jsw-dataadapter-editor">

            <div className="tc-jsw-form-wrapper" style={{ flex: 1, alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}>

                <TextInput label={i18n.t('datasource.mongoDB.name')}
                    help={i18n.t('datasource.mongoDB.name.help')}
                    id="data-adapter-name"
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.mongoDB.descriptor.mongouri')}
                    id="mong-uri"
                    value={this.props.mongoURI}
                    onChange={this.onMongoURIChange}
                    advancedValidator={mongoDBUrlValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.mongoDB.descriptor.username')}
                    id="username"
                    autoComplete="off"
                    value={this.props.username}
                    onChange={this.onUsernameChange}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.mongoDB.descriptor.password')}
                    id="password"
                    type="password"
                    autoComplete="off"
                    value={this.props.password}
                    onChange={this.onPasswordChange}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />
            </div>
        </div>);
    }

    private onMongoURIChange = (str: string) => {
        this.notifyChange({ mongoURI: str });
    }

    private onPasswordChange = (str: string) => {
        this.notifyChange({ password: str });
    }

    private onUsernameChange = (str: string) => {
        this.notifyChange({ username: str });
    }

    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }
}