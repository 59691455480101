/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { JsonDataAdapterEditor } from './JsonDataAdapterEditor';
import { IDataAdapterConfig, IDataAdapterDescriptor, setFileDataAdapterAttributes, createNode, getChilNodeByName, createNodeWithValue, getProlog } from '../IDataAdapterDescriptor';
import format from 'xml-formatter';
import { MIME_TYPES } from '@jss/js-rest-api';
import { readXML } from '../HTTPConfigurationPanel';
import { nameValidator, urlValidator } from '@jss/js-common/src/utils/validators';
import { getDataAdapterNode } from '../../editor/Utils';


export default class JsonDataAdapterDescriptor implements IDataAdapterDescriptor {

  public getMime = () => {
    return MIME_TYPES.DATA_ADAPTER_JSON;
  }

  public getIcon = () => {
    return   <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={160}
    height={160}
    viewBox="0 0 160 160" xmlSpace="preserve"
  >
    <defs>
      <linearGradient id="jsonIconA">
        <stop offset={0} />
        <stop stopColor="#fff" offset={1} />
      </linearGradient>
      <linearGradient
        id="jsonIconC"
        x1={-553.27}
        x2={-666.12}
        y1={525.91}
        y2={413.05}
        gradientTransform="matrix(.99884 0 0 .9987 689.01 -388.84)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#jsonIconA"
      />
      <linearGradient
        id="jsonIconB"
        x1={-666.12}
        x2={-553.27}
        y1={413.04}
        y2={525.91}
        gradientTransform="matrix(.99884 0 0 .9987 689.01 -388.84)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#jsonIconA"
      />
    </defs>
    <g fillRule="evenodd" color="#000">
      <path
        d="M79.865 119.1c35.398 48.255 70.04-13.469 69.989-50.587C149.794 24.627 105.313.099 79.836.099 38.944.099 0 33.895 0 80.135 0 131.531 44.64 160 79.836 160c-7.965-1.147-34.506-6.834-34.863-67.967-.24-41.347 13.488-57.866 34.805-50.599.477.177 23.514 9.265 23.514 38.951 0 29.56-23.427 38.715-23.427 38.715z"
        fill="url(#jsonIconB)"
      />
      <path
        d="M79.823 41.401C56.433 33.339 27.78 52.617 27.78 91.23c0 63.048 46.721 68.77 52.384 68.77C121.056 160 160 126.204 160 79.964 160 28.568 115.36.099 80.164.099c9.748-1.35 52.541 10.55 52.541 69.037 0 38.141-31.953 58.905-52.735 50.033-.477-.177-23.514-9.264-23.514-38.951 0-29.56 23.367-38.818 23.367-38.818z"
        fill="url(#jsonIconC)"
      />
    </g>
  </svg>
  }

  /**
   *  Return the name of this data adapter to be presented to the user
   *  i.e. JSON File
   */
  public getDataAdapterLabel = () => {
    return 'datasource.json.label';
  }

  /**
  *  Return the name of this data adapter to be presented to the user
  *  i.e. CSV File
  */
  public getDataAdapterDescription = () => {
    return 'datasource.json.description';
  }

  /**
   *  Return the canonical name of the class which impements this data adapter in Java.
   *  net.sf.jasperreports.data.csv.CsvDataAdapter
   */
  public getDataAdapterClass = () => {
    return 'net.sf.jasperreports.data.json.JsonDataAdapterImpl'
  }

  public getDataAdapterRootName = () => {
    return 'jsonDataAdapter';
  }

  /**
   * Return a plain json object with the default data adapter configuration.
   * It can be just an empty object.
   *
   * @memberof IDataAdapter
   */
  public initializeDataAdapterConfig = () => {
    return {
      class: this.getDataAdapterClass(),
      name: 'New JSON Data Adapter',
      location: '',
      language: 'json',
      useConnection: false,
      datePattern: undefined,
      numberPattern: undefined,
      selectExpression: '',
      locale: undefined,
      timezone: undefined,
    }
  };

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public validateDataAdapterConfig = (config: IDataAdapterConfig) => {

    // Check for errors...
    const errors = [];

    const nameError = nameValidator(config.name);
    if (nameError) {
      errors.push(nameError);
    }

    const urlError = urlValidator(config.location);
    if (urlError) {
      errors.push(urlError);
    }

    if (errors.length > 0) {
      return errors.join("\n");
    }

    return undefined;

  }

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public createEditor = (config: IDataAdapterConfig, onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => {
    return <JsonDataAdapterEditor {...config} onDataAdapterSettingsChange={onDataAdapterSettingsChange} readOnly={readOnly}/>
  }

  public getLanguages = () => {
    return ['text'];
  }

  public toXml = (config: IDataAdapterConfig) => {
    const xmlDoc = document.implementation.createDocument(null, this.getDataAdapterRootName());
    const root = xmlDoc.getElementsByTagName(this.getDataAdapterRootName())[0];
    root.setAttribute('class', this.getDataAdapterClass());

    const nameNode = createNode(xmlDoc, config, 'name', 'name');
    if (nameNode) {
      root.appendChild(nameNode);
    }

    setFileDataAdapterAttributes(xmlDoc, config, root);

    const useConnectionNode = createNode(xmlDoc, config, 'useConnection', 'useConnection');
    if (useConnectionNode) {
      root.appendChild(useConnectionNode);
    }

    const languageNode = createNode(xmlDoc, config, 'language', 'language');
    if (languageNode) {
      root.appendChild(languageNode);
    }

    const selectExpressionNode = createNode(xmlDoc, config, 'selectExpression', 'selectExpression');
    if (selectExpressionNode) {
      root.appendChild(selectExpressionNode);
    }

    const numberPatternNode = createNode(xmlDoc, config, 'numberPattern', 'numberPattern');
    if (numberPatternNode) {
      root.appendChild(numberPatternNode);
    }

    const datePatternNode = createNode(xmlDoc, config, 'datePattern', 'datePattern');
    if (datePatternNode) {
      root.appendChild(datePatternNode);
    }

    const timezoneNode = createNode(xmlDoc, config, 'timeZone', 'timeZone');
    if (timezoneNode) {
      root.appendChild(timezoneNode);
    }

    const localeValue = config.locale === 'en_EN' ? 'en' : config.locale;
    const localeNode = createNodeWithValue(xmlDoc, config, 'locale', localeValue);
    if (localeNode) {
      root.appendChild(localeNode);
    }

    const prolog = getProlog();
    const xml = prolog + (new XMLSerializer().serializeToString(xmlDoc));
    return format(xml, { indentation: '  ', collapseContent: true });
  }

  public toJson = (xml: Document) => {
    let result: any = {
      class: this.getDataAdapterClass(),
      name: '',
      location: '',
      language: 'json',
      useConnection: false,
      datePattern: undefined,
      numberPattern: undefined,
      selectExpression: '',
      locale: undefined,
      timeZone: undefined,
    }

    getDataAdapterNode(xml.getRootNode()).childNodes.forEach((childNode) => {
      const nodeName = childNode.nodeName;
      if (nodeName === 'name') {
        result.name = childNode.textContent;
      } else if (nodeName === 'dataFile') {
        const locationNode = getChilNodeByName(childNode, 'location');
        if (!locationNode) {
          const urlNode = getChilNodeByName(childNode, 'url');
          const url = urlNode.textContent;
          result.location = url;
        } else {
          result.location = locationNode.textContent;
        }
        result = {
          ...result,
          ...readXML(childNode as Element),
        }
      } else if (nodeName === 'fileName') {
        result.location = childNode.textContent;
      } else if (nodeName === 'selectExpression') {
        result.selectExpression = childNode.textContent;
      } else if (nodeName === 'useConnection') {
        result.useConnection = (childNode.textContent === 'true');
      } else if (nodeName === 'locale') {
        //replace the en locale with en_EN to avoid glitch when typing since the key is the same of 
        //the start of the name
        const locale = childNode.textContent === 'en' ? 'en_EN' : childNode.textContent;
        result.locale = locale;
      } else if (nodeName === 'numberPattern') {
        result.numberPattern = childNode.textContent;
      } else if (nodeName === 'datePattern') {
        result.datePattern = childNode.textContent;
      } else if (nodeName === 'timeZone') {
        result.timeZone = childNode.textContent;
      } else if (nodeName === 'language') {
        result.language = childNode.textContent;
      }
    });
    return result;
  }
}