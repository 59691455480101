/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../ui/APDescriptor";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { PCategories } from "../../ui/composite/UPCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { PInt } from "../../ui/primitive/UPInteger";
import { PString } from "../../ui/primitive/UPString";
import { PValueExpression } from "../../ui/primitive/UPValueExpession";
import { PConnectedTableDetail } from "../../ui/tables/UPConnectedTableDetail";
import { MDivElementDataset } from "../dataset/MDatasetRun";


export const FTibcoMapPath = {
    name: new PValueExpression({ id: 'name', vtype: new PString({ id: "name", label: 'Name', description: 'Name of the path.' }) }),
    latitude: new PValueExpression({ id: 'latitude', vtype: new PInt({ id: "latitude", label: 'Latitude', description: 'Marker latitude', min: -85, max: 85, default: 37.7833 }) }),
    longitude: new PValueExpression({ id: 'longitude', vtype: new PInt({ id: "longitude", label: 'Longitude', description: 'Marker longitude', min: -180, max: 180, default: -122.4167 }) }),

    country: new PValueExpression({ id: 'country', vtype: new PString({ id: "country", label: 'Country', description: 'Country' }) }),
    state: new PValueExpression({ id: 'state', vtype: new PString({ id: "state", label: 'State', description: 'State' }) }),
    zip: new PValueExpression({ id: 'zip', vtype: new PString({ id: "zip", label: 'Zip', description: 'Zip' }) }),
    city: new PValueExpression({ id: 'city', vtype: new PString({ id: "city", label: 'City', description: 'City' }) }),
    street: new PValueExpression({ id: 'street', vtype: new PString({ id: "street", label: 'Street', description: 'Street' }) }),

    style: new PValueExpression({ id: 'style', vtype: new PString({ id: "style", label: 'Style', description: 'Style.' }) }),
};

export const MTibcoMapPath: APDescriptor = new PCategories({
    id: 'tibcomap.paths',
    layouts: [
        new PDiv({
            id: 'tibcomap.paths.paths',
            label: 'Paths', layouts: [new PConnectedTableDetail({
                id: 'items', label: 'Paths', rowLabel: 'Path', rowLabelPaths: ['name/value', 'name/valueExpression'], expanded: true,
                layouts: [new PDiv({
                    label: 'Path',
                    layouts: [
                        new PAccordeon({
                            id: 'tibcomap.path.location', label: "Location", expanded: true, layouts: [FTibcoMapPath.name, FTibcoMapPath.latitude, FTibcoMapPath.longitude,
                            FTibcoMapPath.country, FTibcoMapPath.state, FTibcoMapPath.zip, FTibcoMapPath.city, FTibcoMapPath.street]
                        }),
                        FTibcoMapPath.style
                    ]
                })]
            })]
        }),
        { ...MDivElementDataset, path: 'dataset' }
    ]
});
