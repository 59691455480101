/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map } from 'immutable';
import { ElementTypes } from '../../../../sagas/report/document/elementTypes';
import { addAttributeToMap, addChildToParent, addChildValueToMap, createDatasetRun, createReportElement, generatePath, getChildNodeByName, getNamespace, IObjectCounter } from './JrxmlHelpers';

export const CVC_NAMESPACE = "CVC_XMLNS_NAMESPACE";

export const isCVC = (componentElement : Element) : boolean => {
    const childList = componentElement.childNodes;
    let found = false;
    for (let i = 0; i < childList.length && !found; i++) {
        const child = childList[i];
        const nodeName = child.nodeName;
        if (child.nodeType === Node.ELEMENT_NODE && nodeName !== "reportElement"){
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length>0 ? baseNamespace + ":" : baseNamespace;
            found = nodeName === namespace + "customvisualization";
        }
    }
    return found;
}

export const createCVCElement = (componentElementNode : Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) => {
    const reportElementNode : Element = getChildNodeByName(componentElementNode, "reportElement") as Element;
    const pathValue = generatePath(parentElement);
    let result : Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.CVC_ELEMENT, objectCounter);
  
    let newDocument = document;
    componentElementNode.childNodes.forEach(compChild => {
        if (compChild.nodeType === Node.ELEMENT_NODE && compChild.nodeName !== "reportElement"){
            const baseNamespace = getNamespace(compChild as Element);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : "";
            const CVCNode = getChildNodeByName(componentElementNode, namespace + "customvisualization");
            if (CVCNode !== null){
                let properties : List<Map<string, string>> = List<Map<string, string>>();
                let data : List<Map<string, string>> = List<Map<string,string>>();
                const CVCElement = CVCNode as Element;
                result = result.set(CVC_NAMESPACE, baseNamespace);
                result = addAttributeToMap(CVCElement, baseNamespace.length > 0 ? "xmlns:" + baseNamespace : "xmlns", result); 
                result = addAttributeToMap(CVCElement, "xsi:schemaLocation", result);
                result = addAttributeToMap(CVCElement, "evaluationTime", result);
                result = addAttributeToMap(CVCElement, "evaluationGroup", result);
                CVCElement.childNodes.forEach(child => {
                    const nodeName = child.nodeName;
                    if (nodeName === namespace + "itemProperty"){
                        let propertyValue = Map<string, string>();
                        const propertyName = (child as Element).getAttribute("name");
                        propertyValue = addAttributeToMap(child as Element, "value", propertyValue);
                        propertyValue = addChildValueToMap(child as Element, "valueExpression", propertyValue);
                        propertyValue = propertyValue.set("name", propertyName);
                        properties = properties.push(propertyValue);
                    } else if (nodeName === namespace + "cvData" ){
                        data = data.push(createCVData(child as Element, namespace, objectCounter));
                    }
                });
                if (!data.isEmpty()){
                    result = result.set("cvcData", data);
                }
                if (!properties.isEmpty()){
                    result = result.set("cvcProperties", properties);
                }
            }
            newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), newDocument, result.get("id"));
            newDocument = newDocument.setIn(["elements", result.get("id")], result);
        }
    });
    return newDocument;
}

const createCVData = (cvDataElementNode : Element, namespace: string, objectCounter: IObjectCounter) : Map<string, any> => {
    let result : Map<string, any> = Map<string, any>();
    const datasetNode = getChildNodeByName(cvDataElementNode, "dataset");
    if (datasetNode !== null){  
        result = result.set("dataset", createCVCDataset(datasetNode as Element, objectCounter));
    }
    let items = List<List<Map<string, any>>>();
    cvDataElementNode.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === namespace + "item"){
            // expore the citem for the itemproperty
            let itemProperties = List<Map<string, any>>();
            element.childNodes.forEach(itemProperty => {
                if (itemProperty.nodeName === namespace + "itemProperty"){
                    const value = createCVCItem(itemProperty as Element);
                    itemProperties = itemProperties.push(value);
                }
            });
            if (!itemProperties.isEmpty()){
                items = items.push(itemProperties);
            }
        }
    });
    if (!items.isEmpty()){
        result = result.set("cvcItems", items);
    }
    return result;
}

const createCVCItem = (itemElement: Element) : Map<string, any> => {
    let result = Map<string, any>();
    const name = itemElement.getAttribute('name');
    result = addChildValueToMap(itemElement, "valueExpression", result);
    result = addAttributeToMap(itemElement, "value", result);
    result = result.set('name', name);
    return result;
}

const createCVCDataset = (itemDataElement: Element, objectCounter: IObjectCounter) : Map<string, any> => {
    let result = Map<string, any>();

    result = addAttributeToMap(itemDataElement, "resetType", result);
    result = addAttributeToMap(itemDataElement, "resetGroup", result);
    result = addAttributeToMap(itemDataElement, "incrementType", result);
    result = addAttributeToMap(itemDataElement, "incrementGroup", result);

    result = addChildValueToMap(itemDataElement, "incrementWhenExpression", result);

    // check for the dataset run
    const datasetRunNode = getChildNodeByName(itemDataElement, "datasetRun");
    if (datasetRunNode !== null){
        result = result.set("datasetRun", createDatasetRun(datasetRunNode as Element, objectCounter));
    }

    return result;
}