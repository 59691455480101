/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { FFusionWidgetProps, FFusionWidgets, MFusionWidgetCommon } from "./MFusionWidgetsCommon";
import GaugeChartIcon from "../../../../../../assets/icons/charts/fusion/gauge_linear.svg";
import { PDiv } from "../../../ui/composite/UPDiv";
import { PConnectedTableDetail } from "../../../ui/tables/UPConnectedTableDetail";
import { MHyperlinks } from "../../common/MHyperlinks";
import { MDatasetRun } from "../../dataset/MDatasetRun";
import { PCategories } from "../../../ui/composite/UPCategories";
import { FFusionChart } from "../chart/MFusionChart";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { FFusionMap } from "../map/MFusionMap";



export const MFusionLinearGauge: APDescriptor = {
    id: 'LinearGauge', type: 'fusion.chart.LinearGauge', label: 'Linear Gauge', icon: GaugeChartIcon, path: 'widgetProperties',
    layouts: [...MFusionWidgetCommon.layouts,
    new PAccordeon({
        id: 'linearGaugeTicks', label: "Ticks", layouts: [FFusionWidgets.showTickMarks, FFusionWidgets.showTickValues, FFusionWidgets.forceTickValueDecimals, FFusionWidgets.setAdaptiveMin, FFusionWidgets.placeTicksInside, FFusionWidgets.ticksBelowGauge]
    }),
    new PAccordeon({
        id: 'gauge', label: "Gauge", layouts: [FFusionWidgets.gaugeRoundRadius,
        new PAccordeon({
            id: 'gauge.border', label: "Border", layouts: [FFusionWidgets.showGaugeBorder, FFusionWidgets.gaugeBorderColor, FFusionWidgets.gaugeBorderAlpha, FFusionWidgets.gaugeBorderThickness]
        })
        ]
    }), new PAccordeon({
        id: 'pointer', label: "Pointer", layouts: [FFusionWidgets.pointerRadius, FFusionWidgets.pointerSides, FFusionWidgets.pointerBgColor, FFusionWidgets.pointerBgAlpha,
        new PAccordeon({
            id: 'gauge.border', label: "Border", layouts: [FFusionWidgets.pointerBorderColor, FFusionWidgets.pointerBorderAlpha, FFusionWidgets.pointerBorderThickness]
        })
        ]
    }), new PAccordeon({
        id: 'linear.gauge', label: "Angular Gauge", layouts: [FFusionWidgets.placeValuesInside, FFusionWidgets.setAdaptiveMin,
        new PAccordeon({
            id: 'gauge.limits', label: "Limits",
            layouts: [FFusionWidgetProps.showLimits, FFusionWidgets.lowerLimit, FFusionWidgets.upperLimit,
            new PAccordeon({
                id: 'gauge.limits.display', label: "Display Limits",
                layouts: [FFusionWidgets.lowerLimitDisplay, FFusionWidgets.upperLimitDisplay]
            })]
        })
        ]
    })],
    default: {
        type: 'FUSION_WIDGET', widgetType: 'LinearGauge',
        pointers: [{ valueExpression: "" }], widgetProperties: []
    }
};

export const MFusionLinearGaugeDataset: APDescriptor = {
    id: 'LinearGauge', type: 'fusion.chart.LinearGauge', label: 'Linear Gauge', icon: GaugeChartIcon,
    layouts: [
        new PCategories({
            label: 'Data', layouts: [
                new PDiv({
                    id: 'fusion.data.dataset', path: 'dataset',
                    label: 'Dataset',
                    layouts: [FFusionWidgets.incrementType, FFusionWidgets.incrementWhenExpression, FFusionWidgets.resetType, MDatasetRun]
                }),
                new PConnectedTableDetail({
                    id: 'pointers', label: 'Pointers', rowLabel: 'Value', rowLabelPaths: ['valueExpression'], expanded: true,
                    layouts: [new PCategories({
                        label: 'Data', layouts: [
                            new PDiv({
                                id: 'pointers', label: 'Pointer',
                                layouts: [FFusionWidgets.valueExpression,
                                new PAccordeon({
                                    id: 'properties', label: "Properties", path: 'properties',
                                    layouts: [FFusionWidgets.color, FFusionMap.radius, FFusionMap.bgAlpha, FFusionMap.sides, FFusionWidgets.showValue, FFusionWidgets.editMode]
                                }),
                                new PAccordeon({
                                    id: 'border', label: "Border", path: 'properties', layouts: [FFusionMap.borderColor, FFusionMap.borderAlpha, FFusionMap.borderThickness]
                                }),
                                ]
                            }), new PDiv({
                                id: 'fusion.data.valuesets.hyperlinks',
                                label: 'Hyperlink', path: 'hyperlink',
                                layouts: [...MHyperlinks.layouts]
                            })]
                    })], default: { valueExpression: 'new Double(20)' }
                }),
                new PConnectedTableDetail({
                    id: 'colorRanges', label: 'Color Ranges', rowLabel: 'Color Range', rowLabelPaths: ['color', 'labelExpression'], expanded: true, layouts: [new PDiv({
                        id: 'fusion.colorranges',
                        layouts: [FFusionChart.color, FFusionChart.labelExpression, FFusionWidgets.minExpression, FFusionWidgets.maxExpression
                        ]
                    })], default: { color: '#000000' }
                }),
                new PConnectedTableDetail({
                    id: 'trendPoints', label: 'Trend Points', rowLabel: 'Trend Point', rowLabelPaths: ['labelExpression', 'color'], expanded: true, layouts: [new PDiv({
                        id: 'fusion.trendpoints',
                        layouts: [FFusionChart.color, FFusionChart.labelExpression, FFusionChart.startValueExpression, FFusionChart.endValueExpression,
                        { ...FFusionWidgets.valueInside, path: 'properties' },
                        new PAccordeon({
                            id: 'border', label: "Border", path: 'properties', layouts: [FFusionMap.showBorder, FFusionMap.borderColor]
                        }),
                        new PAccordeon({
                            id: 'line', label: "Line", path: 'properties', layouts: [FFusionWidgets.dashed, FFusionWidgets.thickness, FFusionWidgets.dashGap, FFusionWidgets.dashLen, FFusionMap.radius, FFusionMap.innerRadius]
                        }),
                        new PAccordeon({
                            id: 'marker', label: "Marker", path: 'properties', layouts: [FFusionWidgets.useMarker, FFusionWidgets.markerColor, FFusionMap.markerBorderColor, FFusionMap.markerRadius]
                        })
                        ]
                    })], default: { color: '#000000' }
                }),
                new PDiv({
                    id: 'fusion.data.valuesets.hyperlinks',
                    label: 'Hyperlink', path: 'hyperlink',
                    layouts: [...MHyperlinks.layouts]
                })]
        })]
};