/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const TimeSeriesChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M39 4v134h223V4z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M15.338 157.188L254.662 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M15.338 173.5L254.662 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M15.338 173.5L15.338 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M254.662 173.5L254.662 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#F55" strokeLinejoin="bevel" d="M18.838 165.344h14" clipPath="url(#clipPath1)" />
            <path fill="#F55" stroke="none" strokeLinejoin="bevel" d="M22.838 162.344h6v6h-6v-6z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#F55" d="M22.838 162.344h6v6h-6v-6z" clipPath="url(#clipPath1)" />
            <text x="36.838" y="168.113" stroke="none" clipPath="url(#clipPath1)">Double Freight</text>
            <path fill="none" stroke="#55F" strokeLinejoin="bevel" d="M102.049 165.344h14" clipPath="url(#clipPath1)" />
            <path fill="#55F" stroke="none" strokeLinejoin="bevel" d="M112.049 165.344a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#55F" d="M112.049 165.344a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath1)" />
            <text x="120.049" y="168.113" stroke="none" clipPath="url(#clipPath1)">Normal Freight</text>
            <path fill="none" stroke="#5F5" strokeLinejoin="bevel" d="M185.998 165.344h14" clipPath="url(#clipPath1)" />
            <path fill="#5F5" fillRule="evenodd" stroke="none" strokeLinejoin="bevel" d="M192.998 162.344l3 6h-6z" clipPath="url(#clipPath1)" />
            <path fill="none" fillRule="evenodd" stroke="#5F5" d="M192.998 162.344l3 6h-6z" clipPath="url(#clipPath1)" />
            <text x="203.998" y="168.113" stroke="none" clipPath="url(#clipPath1)">Half-Freight</text>
            <path fill="none" strokeWidth="0.5" d="M39 138L262 138" clipPath="url(#clipPath1)" />
            <text x="45.424" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">set-1996</text>
            <path fill="none" strokeWidth="0.5" d="M63.16 140L63.16 138" clipPath="url(#clipPath1)" />
            <text x="83.118" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">gen-1997</text>
            <path fill="none" strokeWidth="0.5" d="M102.276 140L102.276 138" clipPath="url(#clipPath1)" />
            <text x="120.215" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">mag-1997</text>
            <path fill="none" strokeWidth="0.5" d="M140.724 140L140.724 138" clipPath="url(#clipPath1)" />
            <text x="162.411" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">set-1997</text>
            <path fill="none" strokeWidth="0.5" d="M180.147 140L180.147 138" clipPath="url(#clipPath1)" />
            <text x="200.105" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">gen-1998</text>
            <path fill="none" strokeWidth="0.5" d="M219.263 140L219.263 138" clipPath="url(#clipPath1)" />
            <text x="237.202" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">mag-1998</text>
            <path fill="none" strokeWidth="0.5" d="M257.712 140L257.712 138" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M39 4L39 138" clipPath="url(#clipPath1)" />
            <text x="27.91" y="140.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
            <path fill="none" strokeWidth="0.5" d="M37 138L39 138" clipPath="url(#clipPath1)" />
            <text x="17.73" y="124.886" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">250</text>
            <path fill="none" strokeWidth="0.5" d="M37 122.116L39 122.116" clipPath="url(#clipPath1)" />
            <text x="17.73" y="109.002" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">500</text>
            <path fill="none" strokeWidth="0.5" d="M37 106.232L39 106.232" clipPath="url(#clipPath1)" />
            <text x="17.73" y="93.118" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">750</text>
            <path fill="none" strokeWidth="0.5" d="M37 90.349L39 90.349" clipPath="url(#clipPath1)" />
            <text x="10.098" y="77.234" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">1.000</text>
            <path fill="none" strokeWidth="0.5" d="M37 74.465L39 74.465" clipPath="url(#clipPath1)" />
            <text x="10.098" y="61.35" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">1.250</text>
            <path fill="none" strokeWidth="0.5" d="M37 58.581L39 58.581" clipPath="url(#clipPath1)" />
            <text x="10.098" y="45.467" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">1.500</text>
            <path fill="none" strokeWidth="0.5" d="M37 42.697L39 42.697" clipPath="url(#clipPath1)" />
            <text x="10.098" y="29.583" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">1.750</text>
            <path fill="none" strokeWidth="0.5" d="M37 26.813L39 26.813" clipPath="url(#clipPath1)" />
            <text x="10.098" y="13.699" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">2.000</text>
            <path fill="none" strokeWidth="0.5" d="M37 10.93L39 10.93" clipPath="url(#clipPath1)" />
         </g>
         <g fill="none" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path d="M63.16 4L63.16 138" clipPath="url(#clipPath2)" />
            <path d="M102.276 4L102.276 138" clipPath="url(#clipPath2)" />
            <path d="M140.724 4L140.724 138" clipPath="url(#clipPath2)" />
            <path d="M180.147 4L180.147 138" clipPath="url(#clipPath2)" />
            <path d="M219.263 4L219.263 138" clipPath="url(#clipPath2)" />
            <path d="M257.712 4L257.712 138" clipPath="url(#clipPath2)" />
            <path d="M39 138L262 138" clipPath="url(#clipPath2)" />
            <path d="M39 122.116L262 122.116" clipPath="url(#clipPath2)" />
            <path d="M39 106.232L262 106.232" clipPath="url(#clipPath2)" />
            <path d="M39 90.349L262 90.349" clipPath="url(#clipPath2)" />
            <path d="M39 74.465L262 74.465" clipPath="url(#clipPath2)" />
            <path d="M39 58.581L262 58.581" clipPath="url(#clipPath2)" />
            <path d="M39 42.697L262 42.697" clipPath="url(#clipPath2)" />
            <path d="M39 26.813L262 26.813" clipPath="url(#clipPath2)" />
            <path d="M39 10.93L262 10.93" clipPath="url(#clipPath2)" />
         </g>
         <g fill="#5F5" stroke="#5F5" strokeLinejoin="bevel" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M43.288 128.896L82.725 118.148" clipPath="url(#clipPath2)" />
            <path fill="none" d="M82.725 118.148L92.34 127.084" clipPath="url(#clipPath2)" />
            <path fill="none" d="M92.34 127.084L102.276 117.931" clipPath="url(#clipPath2)" />
            <path fill="none" d="M102.276 117.931L112.212 136.477" clipPath="url(#clipPath2)" />
            <path fill="none" d="M112.212 136.477L121.186 137.832" clipPath="url(#clipPath2)" />
            <path fill="none" d="M121.186 137.832L131.109 112.905" clipPath="url(#clipPath2)" />
            <path fill="none" d="M131.109 112.905L140.724 127.224" clipPath="url(#clipPath2)" />
            <path fill="none" d="M140.724 127.224L150.66 137.172" clipPath="url(#clipPath2)" />
            <path fill="none" d="M150.66 137.172L160.276 133.81" clipPath="url(#clipPath2)" />
            <path fill="none" d="M160.276 133.81L170.212 122.818" clipPath="url(#clipPath2)" />
            <path fill="none" d="M170.212 122.818L180.147 132.454" clipPath="url(#clipPath2)" />
            <path fill="none" d="M180.147 132.454L189.763 129.344" clipPath="url(#clipPath2)" />
            <path fill="none" d="M189.763 129.344L199.712 134.273" clipPath="url(#clipPath2)" />
            <path fill="none" d="M199.712 134.273L209.327 114.77" clipPath="url(#clipPath2)" />
            <path fill="none" d="M209.327 114.77L219.263 120.931" clipPath="url(#clipPath2)" />
            <path fill="none" d="M219.263 120.931L229.199 132.83" clipPath="url(#clipPath2)" />
            <path fill="none" d="M229.199 132.83L238.174 124.414" clipPath="url(#clipPath2)" />
            <path fill="none" d="M238.174 124.414L248.096 106.093" clipPath="url(#clipPath2)" />
            <path fill="none" d="M248.096 106.093L257.712 129.784" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M43.288 119.793L82.725 98.296" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M82.725 98.296L92.34 116.169" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M92.34 116.169L102.276 97.862" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M102.276 97.862L112.212 134.954" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M112.212 134.954L121.186 137.664" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M121.186 137.664L131.109 87.81" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M131.109 87.81L140.724 116.448" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M140.724 116.448L150.66 136.344" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M150.66 136.344L160.276 129.62" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M160.276 129.62L170.212 107.636" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M170.212 107.636L180.147 126.907" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M180.147 126.907L189.763 120.689" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M189.763 120.689L199.712 130.545" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M199.712 130.545L209.327 91.539" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M209.327 91.539L219.263 103.861" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M219.263 103.861L229.199 127.66" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M229.199 127.66L238.174 110.828" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M238.174 110.828L248.096 74.186" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" d="M248.096 74.186L257.712 121.567" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M43.288 101.585L82.725 58.592" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M82.725 58.592L92.34 94.337" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M92.34 94.337L102.276 57.724" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M102.276 57.724L112.212 131.908" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M112.212 131.908L121.186 137.328" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M121.186 137.328L131.109 37.621" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M131.109 37.621L140.724 94.895" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M140.724 94.895L150.66 134.689" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M150.66 134.689L160.276 121.239" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M160.276 121.239L170.212 77.273" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M170.212 77.273L180.147 115.815" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M180.147 115.815L189.763 103.377" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M189.763 103.377L199.712 123.091" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M199.712 123.091L209.327 45.078" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M209.327 45.078L219.263 69.722" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M219.263 69.722L229.199 117.319" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M229.199 117.319L238.174 83.656" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M238.174 83.656L248.096 10.373" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M248.096 10.373L257.712 105.135" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" d="M43.288 125.896l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M43.288 125.896l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M82.725 115.148l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M82.725 115.148l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M92.34 124.084l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M92.34 124.084l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M102.276 114.931l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M102.276 114.931l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M112.212 133.477l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M112.212 133.477l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M121.186 134.832l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M121.186 134.832l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M131.109 109.905l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M131.109 109.905l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M140.724 124.224l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M140.724 124.224l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M150.66 134.172l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M150.66 134.172l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M160.276 130.81l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M160.276 130.81l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M170.212 119.818l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M170.212 119.818l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M180.147 129.454l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M180.147 129.454l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M189.763 126.344l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M189.763 126.344l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M199.712 131.273l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M199.712 131.273l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M209.327 111.77l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M209.327 111.77l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M219.263 117.93l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M219.263 117.93l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M229.2 129.83l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M229.2 129.83l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M238.174 121.414l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M238.174 121.414l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M248.096 103.093l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M248.096 103.093l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M257.712 126.784l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M257.712 126.784l3 6h-6z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M46.288 119.793a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M46.288 119.793a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M85.725 98.296a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M85.725 98.296a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M95.34 116.169a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M95.34 116.169a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M105.276 97.862a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M105.276 97.862a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M115.212 134.954a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M115.212 134.954a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M124.186 137.664a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M124.186 137.664a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M134.109 87.81a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M134.109 87.81a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M143.724 116.448a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M143.724 116.448a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M153.66 136.344a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M153.66 136.344a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M163.276 129.62a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M163.276 129.62a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M173.212 107.636a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M173.212 107.636a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M183.147 126.907a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M183.147 126.907a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M192.763 120.689a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M192.763 120.689a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M202.712 130.545a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M202.712 130.545a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M212.327 91.54a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M212.327 91.54a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M222.263 103.861a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M222.263 103.861a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M232.2 127.66a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M232.2 127.66a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M241.174 110.828a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M241.174 110.828a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M251.096 74.186a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M251.096 74.186a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M260.712 121.567a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M260.712 121.567a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M40.288 98.585h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M40.288 98.585h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M79.725 55.592h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M79.725 55.592h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M89.34 91.337h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M89.34 91.337h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M99.276 54.724h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M99.276 54.724h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M109.212 128.908h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M109.212 128.908h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M118.186 134.328h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M118.186 134.328h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M128.109 34.62h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M128.109 34.62h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M137.724 91.895h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M137.724 91.895h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M147.66 131.689h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M147.66 131.689h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M157.276 118.24h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M157.276 118.24h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M167.212 74.273h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M167.212 74.273h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M177.147 112.815h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M177.147 112.815h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M186.763 100.377h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M186.763 100.377h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M196.712 120.09h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M196.712 120.09h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M206.327 42.078h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M206.327 42.078h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M216.263 66.722h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M216.263 66.722h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M226.2 114.32h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M226.2 114.32h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M235.174 80.656h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M235.174 80.656h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M245.096 7.373h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M245.096 7.373h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M254.712 102.135h6v6h-6v-6z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M254.712 102.135h6v6h-6v-6z" clipPath="url(#clipPath2)" />
         </g>
      </svg>
   );
}
export default TimeSeriesChartIcon;