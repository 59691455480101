/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Map } from 'immutable';
import { LOGOUT_USER, SET_LANGUAGE, SET_THEME } from '../actions/actionTypes';
import { RepositoryApi } from '@jss/js-rest-api';

const initialState = {
    parameters: Map(),
    language: undefined,
    theme: undefined,
};

const routerReducer = (state = initialState, action: any) => {
    let newData = state.parameters;
    switch (action.type) {
        case LOGOUT_USER:
            RepositoryApi.inst().logout();
            sessionStorage.clear();
            newData = newData.clear();
            return {
                ...state,
                parameters: newData,
            }
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.name,
            }
        case SET_THEME:
            return {
                ...state,
                theme: action.name,
            }
        default:
            return state;
    }
};

export default routerReducer;
