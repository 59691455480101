/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

export const EXIT_DATA_ADAPTER_EDITOR = 'EXIT_DATA_ADAPTER_EDITOR';

export const SAVE_DATA_ADAPTER = 'SAVE_DATA_ADAPTER';

export const SAVE_AS_DATA_ADAPTER = 'SAVE_AS_DATA_ADAPTER';

export const DATA_ADAPTER_SAVED = 'DATA_ADAPTER_SAVED';

export const DATA_ADAPTER_SAVED_AS = 'DATA_ADAPTER_SAVED_AS';

export const DATA_ADAPTER_EDITOR_DIRTY = 'DATA_ADAPTER_EDITOR_DIRTY';

export const UPDATED_DATA_ADAPTER_CONTENT = 'UPDATED_DATA_ADAPTER_CONTENT';
