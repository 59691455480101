/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { AbstractTextualEditor } from './AbstractTextualEditor';
import AceEditor, { IAceEditorProps } from 'react-ace';
import { RunContext } from '@jss/js-repository/src/RunContainer';

export default class InternalTextEditor extends AbstractTextualEditor {

    public getAcceptedFileTypes() {
        return this.props.acceptedFileTypes ? this.props.acceptedFileTypes : 'text/plain,.txt,.properties';
    }

    public getDefaultExtension() {
        return this.props.defaultExtension ? this.props.defaultExtension : 'txt';
    }

    public getTextEditor(editorProps: IAceEditorProps){
        const props = {
            ...editorProps,
            mode: 'text',
            wrapEnabled: true,
            enableBasicAutocompletion: true,
            tabSize: 2,
            theme: 'textmate',
        };
        return <AceEditor {...props} ref={this.editor}/>;
    }
}

InternalTextEditor.contextType = RunContext;
