/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { MIME_TYPES } from '@jss/js-rest-api';
import * as React from 'react';
import format from 'xml-formatter';
import { createNode, getProlog, IDataAdapterConfig, IDataAdapterDescriptor } from '../IDataAdapterDescriptor';
import { DatasourceProviderDataAdapterEditor } from './DatasourceProviderDataAdapterEditor';
import JRLogoImage from '@jss/js-common/src/svg/JRLogoImage';
import { nameValidator, notEmptyClassNameValidator } from '@jss/js-common/src/utils/validators';
import { getDataAdapterNode } from '../../editor/Utils';

export default class DatasourceProviderDataAdapterDescriptor implements IDataAdapterDescriptor {

  public getMime = () => {
    return MIME_TYPES.DATA_ADAPTER_PROVIDER;
  }

  getIcon = () => {
    return <JRLogoImage />;
  };

  /**
   *  Return the name of this data adapter to be presented to the user
   *  i.e. CSV File
   */
  public getDataAdapterLabel = () => {
    return 'datasource.datasourceprovider.descriptor.label';
  }

  /**
  *  Return the name of this data adapter to be presented to the user
  *  i.e. CSV File
  */
  public getDataAdapterDescription = () => {
    return 'datasource.datasourceprovider.description';
  }

  /**
   *  Return the canonical name of the class which impements this data adapter in Java.
   *  net.sf.jasperreports.data.csv.CsvDataAdapter
   */
  public getDataAdapterClass = () => {
    return 'net.sf.jasperreports.data.provider.DataSourceProviderDataAdapterImpl'
  }

  public getDataAdapterRootName = () => {
    return 'providerDataAdapter';
  }

  /**
   * Return a plain json object with the default data adapter configuration.
   * It can be just an empty object.
   *
   * @memberof IDataAdapter
   */
  public initializeDataAdapterConfig = () => {
    return {
      class: this.getDataAdapterClass(),
      name: 'New Datasource Provider Data Adapter',
      providerClass: '',
      classpaths: [],
    }
  };

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public validateDataAdapterConfig = (config: IDataAdapterConfig) => {

    // Check for errors...
    const errors = [];

    const nameError = nameValidator(config.name);
    if (nameError) {
      errors.push(nameError);
    }

    const classnameError = notEmptyClassNameValidator(config.providerClass);
    if (classnameError) {
      errors.push(classnameError);
    }

    if (errors.length > 0) {
      return errors.join("\n");
    }

    return undefined;

  }

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public createEditor = (config: IDataAdapterConfig, onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => {
    return <DatasourceProviderDataAdapterEditor {...config} onDataAdapterSettingsChange={onDataAdapterSettingsChange} readOnly={readOnly} />
  }

  public getLanguages = () => {
    return ['text'];
  }

  public toXml = (config: IDataAdapterConfig) => {
    const xmlDoc = document.implementation.createDocument(null, this.getDataAdapterRootName());
    const root = xmlDoc.getElementsByTagName(this.getDataAdapterRootName())[0];
    root.setAttribute('class', this.getDataAdapterClass());

    const nameNode = createNode(xmlDoc, config, 'name', 'name');
    if (nameNode) {
      root.appendChild(nameNode);
    }

    const providerClassClassNode = createNode(xmlDoc, config, 'providerClass', 'providerClass');
    if (providerClassClassNode) {
      root.appendChild(providerClassClassNode);
    }

    config.classpaths.forEach((classpath: string) => {
      const classpatNode = xmlDoc.createElement('classpath');
      const newText = xmlDoc.createTextNode(classpath);
      classpatNode.appendChild(newText);
      root.appendChild(classpatNode);
    });

    const prolog = getProlog();
    const xml = prolog + (new XMLSerializer().serializeToString(xmlDoc));
    return format(xml, { indentation: '  ', collapseContent: true });
  }

  public toJson = (xml: Document) => {
    const result: any = {
      class: this.getDataAdapterClass(),
      name: '',
      providerClass: '',
      classpaths: [],
    }

    getDataAdapterNode(xml.getRootNode()).childNodes.forEach((childNode) => {
      const nodeName = childNode.nodeName;
      if (nodeName === 'name') {
        result.name = childNode.textContent;
      } else if (nodeName === 'providerClass') {
        result.providerClass = childNode.textContent;
      } else if (nodeName === 'classpath') {
        result.classpaths.push(childNode.textContent);
      }
    });
    return result;
  }
}