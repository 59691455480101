/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IRepositoryItemDescriptor, RESOURCE_TYPE } from "../repo/IRepositoryItemDescriptor";
import { IResourceField } from "../repo/IResourceField";
import { MIME_TYPES } from "../repo/mimes";
import { Permission, RepositoryApi } from "../repo/RepositoryApi";

export class ResourceFieldREST implements IResourceField {

    public getContainerLenght() {
        return 0;
    }

    public isEditable(res: IRepositoryItemDescriptor, name: string) {
        if (!res.permission || RepositoryApi.inst().hasPermission(res, Permission.WRITE)) {
            switch (name) {
                case 'name': return true;
                case 'description': return true;
            }
        }
        return false;
    }
    public isAvailable(res: IRepositoryItemDescriptor, name: string) {
        switch (name) {
            case 'version': return false;
        }
        return true;
    }
    public isAvailable4Children(res: IRepositoryItemDescriptor, name: string) {
        return this.isAvailable(res, name);
    }

    public encode(name: string, value?: string, add?: any) {
        switch (name) {
            case 'name': return value?.trim();
            case 'mime': return this.toMime(value, add as string);
            case 'path': return this.encodePath(value);
            case 'permission':
                let p = 0;
                value?.split(',').map(perm => {
                    switch (perm) {
                        case Permission.READ: p = p + 4; break;
                        case Permission.WRITE: p = p + 2; break;
                        case Permission.EXECUTE: p = p + 1; break;
                    }
                })
                return `${p}`;
        }
        return value;
    }
    public decode(name: string, value?: string) {
        switch (name) {
            case 'name': return value ? decodeURIComponent(value?.trim()) : undefined;
            case 'mime': return this.fromMime(value);
            case 'permission':
                switch (value) {
                    case '0': return Permission.NONE;
                    case '1': return Permission.EXECUTE;
                    case '2': return Permission.WRITE;
                    case '3': return `${Permission.EXECUTE},${Permission.WRITE}`;
                    case '4': return Permission.READ;
                    case '6': return `${Permission.READ},${Permission.WRITE}`;
                    case '7': return `${Permission.EXECUTE},${Permission.WRITE},${Permission.EXECUTE}`;
                    default:
                        return Permission.NONE;
                }
        }
        return value;
    }

    public validate(name: string, value?: string): string | undefined {
        switch (name) {
            case 'name': return this.validateName(value);
            case 'description': return this.validateDescription(value);
        }
        return undefined;
    }

    protected validateName(name?: string): string | undefined {
        if (!name || name.trim().length === 0) {
            return "Name must have a value";
        }
        name = name.trim();
        if (name.length > 260) {
            return "Name max size 260";
        }
        if (name.indexOf('/') > -1) {
            return "/ is not allowed in the name";
        }
        return undefined;
    }
    protected validateDescription(desc?: string): string | undefined {
        if (desc && desc.trim().length > 1000) {
            return 'Description should be max 1000 chars.';
        }
    }
    protected fromMime(mime?: string): string | undefined {
        return mime;
    }

    protected toMime(mime?: string, type?: string): string | undefined {
        if (mime === 'application/vnd.google-apps.folder') {
            return MIME_TYPES.FOLDER;
        }
        if (mime === 'text/xml') {
            return MIME_TYPES.XML;
        }
        if (type === RESOURCE_TYPE.FOLDER && mime === 'application/octet-stream')
            return MIME_TYPES.FOLDER;
        return mime;
    }

    /**
     * Escape special charactes in each path component, strip unnecessary slashes and trim the path.
     */
    protected encodePath(path?: string): string | undefined {
        if (!path)
            return;
        path = path.trim();
        while (path.startsWith('/')) {
            path = path.substring(1);
        }
        while (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }
        const parts = path.split('/');
        let encodePath = '';
        for (const part of parts) {
            encodePath += encodeURIComponent(part) + '/';
        }
        while (encodePath.endsWith('/')) {
            encodePath = encodePath.substring(0, encodePath.length - 1);
        }
        return encodePath;
    }
}