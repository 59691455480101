/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { IRepositoryItemDescriptor } from '@jss/js-rest-api';

import { UxType } from '@jss/js-common';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { RunContext } from '../../RunContainer';
import { ResourceInfoDialog } from './ResourceInfoDialog';
import i18n from '../../i18n';


interface IResourceInfoProps {
    type?: UxType;
    size?: 'small' | 'medium' | 'large';
    disabled?: boolean;
    descriptor: IRepositoryItemDescriptor;
    onChanged: (oldDescriptor: IRepositoryItemDescriptor, newDescriptor: IRepositoryItemDescriptor) => void,
    onClose: () => void;
}

interface IState {
    show: boolean;
}
export class ResourceInfo extends React.Component<IResourceInfoProps, IState> {
    static defaultProps = {
        type: UxType.BUTTON,
        size: 'large',
        disabled: false
    }
    public state: IState = { show: false };
    render() {
        switch (this.props.type) {
            case UxType.BUTTON: return <Button onClick={this.handleClicked} disabled={this.props.disabled} size={this.props.size}>{i18n.t('common.actions.menu.resourceinfo')}</Button>;
            case UxType.MENU: return <MenuItem disabled={this.props.disabled} style={{ height: 30 }} onClick={this.handleClicked} key='resource.info'>
                <ListItemIcon>
                    <InfoIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />
                </ListItemIcon>
                <Typography variant="inherit">{i18n.t('common.actions.menu.resourceinfo')}</Typography>
            </MenuItem>;
        }
    }
    private handleClicked = () => {
        this.props.onClose();
        this.context.showDialog(ResourceInfoDialog, { descriptor: this.props.descriptor, onViewClose: this.props.onClose, onChanged: this.props.onChanged });
    }
}
ResourceInfo.contextType = RunContext;