/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { MChartPlot } from "./MChartPlot";
import { FPiePlot } from "./MPiePlot";
import { setFonts } from "../../common/MFont";
import { PInt } from "../../../ui/primitive/UPInteger";

export const FPie3DPlot = {
    depthFactor: new PInt({ id: 'depthFactor', label: 'Depth Factor' })
};

export const MPie3DPlot: APDescriptor = {
    type: 'MPie3DPlot',
    path: 'pie3DPlot',
    id: 'pie3DPlotProeprtiesDescriptor',
    layouts: [...MChartPlot.layouts, FPiePlot.isShowLabels, FPiePlot.isCircular, FPiePlot.labelFormat, FPiePlot.legendLabelFormat, FPie3DPlot.depthFactor,
    new PAccordeon({
        id: 'chart.value.item.label', label: 'Item Label', path: 'itemLabel',
        layouts: [FPiePlot.color, FPiePlot.backgroundColor, ...setFonts('font')]
    })
    ]
};
