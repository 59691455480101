/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Map } from 'immutable';
import { ElementTypes } from '../../../../sagas/report/document/elementTypes';
import { addAttributeToMap, addAttributeToMapWithName, addBooleanAttributeToMap, addChildToParent, addChildValueWithPrefixToMap, addFloatAttributeToMap, addIntAttributeToMap, createReportElement, generatePath, getChildNodeByName, getNamespace, IObjectCounter } from './JrxmlHelpers';


const BARBECUE_NAME = "barbecue";
const CODABAR_NAME = "Codabar";
const CODE128_NAME = "Code128";
const CODE39_NAME = "Code39";
const EAN128_NAME = "EAN128";
const EAN13_NAME = "EAN13"; 
const EAN8_NAME = "EAN8";
const PDF417_NAME = "PDF417";
const POSTNET_NAME = "POSTNET";
const DATAMATRIX_NAME = "DataMatrix";
const INTERLEAVED2OF5_NAME = "Interleaved2Of5";
const ROYALMAILCUSTOMER_NAME = "RoyalMailCustomer";
const UPCA_NAME = "UPCA";
const UPCE_NAME = "UPCE"; 
const USPS_NAME = "USPSIntelligentMail"; 
const QRCODE_NAME = "QRCode"; 

export const BARCODE_TYPE = "BARCODE_TYPE";

export const BARCODE_NAMESPACE = "BARCODE_XMLNS_NAMESPACE";

 export const BarcodeTypes = {
    BARBECUE_NAME,
    CODABAR_NAME,
    CODE128_NAME,
    CODE39_NAME,
    EAN128_NAME,
    EAN13_NAME,
    EAN8_NAME,
    PDF417_NAME,
    POSTNET_NAME,
    DATAMATRIX_NAME,
    INTERLEAVED2OF5_NAME,
    ROYALMAILCUSTOMER_NAME,
    UPCA_NAME,
    UPCE_NAME,
    USPS_NAME,
    QRCODE_NAME
  }

export const isBarcode = (componentElement : Element) : boolean => {
    const childList = componentElement.childNodes;
    let barcodeIndex = -1;
    for (let i = 0; i < childList.length && barcodeIndex === -1; i++) {
        const child = childList[i];
        const nodeName = child.nodeName;
        if (child.nodeType === Node.ELEMENT_NODE && nodeName !== "reportElement"){
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length>0 ? baseNamespace + ":" : baseNamespace;
            barcodeIndex = Object.values(BarcodeTypes).findIndex((value : string) => {
                return namespace + value === child.nodeName;
            });
        }
     
    }
    return barcodeIndex !== -1;
}

export const createBarcodeElement = (componentElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    const reportElementNode : Element = getChildNodeByName(componentElement, "reportElement") as Element;
    let barcode : Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.BARCODE, objectCounter);
    
    componentElement.childNodes.forEach(element => {
        if (element.nodeType === Node.ELEMENT_NODE && element.nodeName !== "reportElement"){
            const baseNamespace = getNamespace(element as Element);
            const namespace = baseNamespace.length>0 ? baseNamespace + ":" : baseNamespace;
            const elementName = element.nodeName.substring(namespace.length);
            barcode = barcode.set(BARCODE_NAMESPACE, baseNamespace);
            if (elementName === BarcodeTypes.BARBECUE_NAME ){
                barcode = createBarbecue(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.CODABAR_NAME) {
                barcode = createCodabar(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.CODE128_NAME){
                barcode = createCode128(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.CODE39_NAME){
                barcode = createCode39(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.EAN128_NAME){
                barcode = createEan128(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.EAN13_NAME){
                barcode = createEan13(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.EAN8_NAME){
                barcode = createEan8(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.PDF417_NAME){
                barcode = createPDF417(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.POSTNET_NAME){
                barcode = createPostnet(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.DATAMATRIX_NAME){
                barcode = createDatamatrix(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.INTERLEAVED2OF5_NAME){
                barcode = createInt2Of5(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.ROYALMAILCUSTOMER_NAME){
                barcode = createRoyalMail(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.USPS_NAME){
                barcode = createUSPS(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.UPCA_NAME){
                barcode = createUPCA(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.UPCE_NAME){
                barcode = createUPCE(element as Element, barcode, namespace);
            } else if (elementName === BarcodeTypes.QRCODE_NAME){
                barcode = createQRCode(element as Element, barcode, namespace);
            }
            if (baseNamespace.length>0){
                barcode = addAttributeToMap(element as Element, "xmlns:" + baseNamespace, barcode);
            } else {
                barcode = addAttributeToMap(element as Element, "xmlns", barcode);
            }
            barcode = addAttributeToMap(element as Element, "xsi:schemaLocation", barcode);
        }
    });

    let newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, barcode.get("id"));
    newDocument = newDocument.setIn(["elements", barcode.get("id")], barcode);
   
    return newDocument;
}

const createBarbecue = (barbecueElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {

    let result = initialProperties;
    result = result.set(BARCODE_TYPE, BARBECUE_NAME);
    result = addAttributeToMapWithName(barbecueElement, "type", "barbecue_type", result);
    result = addAttributeToMap(barbecueElement, "drawText", result);
    result = addBooleanAttributeToMap(barbecueElement, "checksumRequired", result);
    result = addIntAttributeToMap(barbecueElement, "barWidth", result);
    result = addIntAttributeToMap(barbecueElement, "barHeight", result);
    result = addAttributeToMap(barbecueElement, "evaluationTime", result);
    result = addAttributeToMap(barbecueElement, "evaluationGroup", result);
    result = addAttributeToMap(barbecueElement, "rotation", result);

    result = addChildValueWithPrefixToMap(barbecueElement, "codeExpression", prefix, result);
    result = addChildValueWithPrefixToMap(barbecueElement, "applicationIdentifierExpression", prefix, result);
    return result;
}

const createBarcode = (barcodeElement : Element, initialProperties: Map<string, any>, barcodeType: string, prefix: string) : Map<string, any> => {

    let result = initialProperties;
    result = result.set(BARCODE_TYPE, barcodeType);

    result = addAttributeToMap(barcodeElement, "evaluationTime", result);
    result = addAttributeToMap(barcodeElement, "evaluationGroup", result);
    result = addChildValueWithPrefixToMap(barcodeElement, "codeExpression", prefix, result);
    
    return result;
}

const createBarcode4j = (barcodeElement : Element, initialProperties: Map<string, any>, barcodeType: string, prefix: string) : Map<string, any> => {

    let result = initialProperties;
    result = createBarcode(barcodeElement, initialProperties, barcodeType, prefix);

    result = addAttributeToMap(barcodeElement, "orientation", result);
    result = addAttributeToMap(barcodeElement, "textPosition", result);
    result = addFloatAttributeToMap(barcodeElement, "moduleWidth", result);
    result = addFloatAttributeToMap(barcodeElement, "quietZone", result);
    result = addFloatAttributeToMap(barcodeElement, "verticalQuietZone", result);
    result = addChildValueWithPrefixToMap(barcodeElement, "patternExpression", prefix, result);
    
    return result;
}

const createCodabar = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {
    let result = initialProperties;
    result = createBarcode4j(barcodeElement, initialProperties, BarcodeTypes.CODABAR_NAME, prefix);
    result = addFloatAttributeToMap(barcodeElement, "wideFactor", result);
    return result;
}

const createCode128 = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {
    const result = createBarcode4j(barcodeElement, initialProperties, BarcodeTypes.CODE128_NAME, prefix);
    return result;
}

const createEan128 = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {
    let result = createBarcode4j(barcodeElement, initialProperties, BarcodeTypes.EAN128_NAME, prefix);
    result = addAttributeToMap(barcodeElement, "checksumMode", result);
    result = addChildValueWithPrefixToMap(barcodeElement, "templateExpression", prefix, result);
    return result;
}

const createEan13 = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {
    let result = createBarcode4j(barcodeElement, initialProperties, BarcodeTypes.EAN13_NAME, prefix);
    result = addAttributeToMap(barcodeElement, "checksumMode", result);
    return result;
}

const createEan8 = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {
    let result = createBarcode4j(barcodeElement, initialProperties, BarcodeTypes.EAN8_NAME, prefix);
    result = addAttributeToMap(barcodeElement, "checksumMode", result);
    return result;
}

const createCode39 = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {
    let result = initialProperties;
    result = createBarcode4j(barcodeElement, initialProperties, BarcodeTypes.CODE39_NAME, prefix);
    result = addAttributeToMap(barcodeElement, "checksumMode", result);
    result = addBooleanAttributeToMap(barcodeElement, "displayChecksum", result);
    result = addBooleanAttributeToMap(barcodeElement, "displayStartStop", result);
    result = addBooleanAttributeToMap(barcodeElement, "extendedCharSetEnabled", result);
    result = addFloatAttributeToMap(barcodeElement, "wideFactor", result);
    result = addFloatAttributeToMap(barcodeElement, "intercharGapWidth", result);
    return result;
}

const createPDF417 = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {
    let result = initialProperties;
    result = createBarcode4j(barcodeElement, initialProperties, BarcodeTypes.PDF417_NAME, prefix);
    result = addIntAttributeToMap(barcodeElement, "minColumns", result);
    result = addIntAttributeToMap(barcodeElement, "maxColumns", result);
    result = addIntAttributeToMap(barcodeElement, "minRows", result);
    result = addIntAttributeToMap(barcodeElement, "maxRows", result);
    result = addIntAttributeToMap(barcodeElement, "errorCorrectionLevel", result);

    result = addFloatAttributeToMap(barcodeElement, "widthToHeightRatio", result);
    return result;
}

const createPostnet = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {
    let result = initialProperties;
    result = createBarcode4j(barcodeElement, initialProperties, BarcodeTypes.POSTNET_NAME, prefix);
    result = addAttributeToMap(barcodeElement, "baselinePosition", result);
    result = addAttributeToMap(barcodeElement, "checksumMode", result);
    result = addBooleanAttributeToMap(barcodeElement, "displayChecksum", result);

    result = addFloatAttributeToMap(barcodeElement, "shortBarHeight", result);
    result = addFloatAttributeToMap(barcodeElement, "intercharGapWidth", result);
    return result;
}

const createDatamatrix = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {
    let result = initialProperties;
    result = createBarcode4j(barcodeElement, initialProperties, BarcodeTypes.DATAMATRIX_NAME, prefix);
    result = addAttributeToMap(barcodeElement, "shape", result);
    return result;
}

const createInt2Of5 = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {
    let result = initialProperties;
    result = createBarcode4j(barcodeElement, initialProperties, BarcodeTypes.INTERLEAVED2OF5_NAME, prefix);
    result = addAttributeToMap(barcodeElement, "checksumMode", result);
    result = addBooleanAttributeToMap(barcodeElement, "displayChecksum", result);

    result = addFloatAttributeToMap(barcodeElement, "wideFactor", result);
    return result;
}

const createUPCA = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {
    let result = initialProperties;
    result = createBarcode4j(barcodeElement, initialProperties, BarcodeTypes.UPCA_NAME, prefix);
    result = addAttributeToMap(barcodeElement, "checksumMode", result);
    return result;
}

const createUPCE = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {
    let result = initialProperties;
    result = createBarcode4j(barcodeElement, initialProperties, BarcodeTypes.UPCE_NAME, prefix);
    result = addAttributeToMap(barcodeElement, "checksumMode", result);
    return result;
}

const createQRCode = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => {
    let result = initialProperties;
    result = createBarcode(barcodeElement, initialProperties, BarcodeTypes.QRCODE_NAME, prefix);
    result = addAttributeToMap(barcodeElement, "errorCorrectionLevel", result);
    result = addIntAttributeToMap(barcodeElement, "margin", result);
    return result;
}

const createBarcode4JFourState = (barcodeElement : Element, initialProperties: Map<string, any>, type: string, prefix: string) : Map<string, any> => {
    let result = initialProperties;
    result = createBarcode4j(barcodeElement, initialProperties, type, prefix);
    result = addAttributeToMap(barcodeElement, "checksumMode", result);

    result = addFloatAttributeToMap(barcodeElement, "ascenderHeight", result);
    result = addFloatAttributeToMap(barcodeElement, "intercharGapWidth", result);
    result = addFloatAttributeToMap(barcodeElement, "trackHeight", result);
    return result;
}

const createRoyalMail = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => { 
    const result = createBarcode4JFourState(barcodeElement, initialProperties, BarcodeTypes.ROYALMAILCUSTOMER_NAME, prefix);
    return result;
}

const createUSPS = (barcodeElement : Element, initialProperties: Map<string, any>, prefix: string) : Map<string, any> => { 
    const result = createBarcode4JFourState(barcodeElement, initialProperties, BarcodeTypes.USPS_NAME, prefix);
    return result;
}