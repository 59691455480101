/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../../ui/APDescriptor";
import { RotationEnum } from "../../enums/RotationEnum";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { PInt } from "../../../ui/primitive/UPInteger";


export const FBarbecue = {
    barWidth: new PInt({ id: 'barWidth', label: 'Bar Width', min: 0 }),
    barHeight: new PInt({ id: 'barHeight', label: 'Bar Height', min: 0 }),
    applicationIdentifierExpression: new PExpression({ id: 'applicationIdentifierExpression', label: 'Application Identifier Expression' }),
    checksumRequired: new PBoolean({id: 'checksumRequired', label: 'Checksum Required'}), 
    drawText: new PBoolean({id: 'drawText', label: 'Draw Text'}),
    rotation: RotationEnum
}

export const MBarbecue: APDescriptor = {
    type: 'barbecue',
    id: 'barbecuePropertiesDescriptor',
    layouts: [FBarbecue.applicationIdentifierExpression, FBarbecue.barWidth, FBarbecue.barHeight, FBarbecue.checksumRequired, FBarbecue.drawText, FBarbecue.rotation],
    default: {
        type: 'BARCODE', BARCODE_TYPE: 'barbecue', BARCODE_XMLNS_NAMESPACE: 'c', barbecue_type: 'Code128',
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:c': "http://jasperreports.sourceforge.net/jasperreports/components",
        codeExpression: '"123456789"'
    }
};
