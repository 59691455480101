/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const ScaleTypeEnum = new PEnum({
    id: 'scaleType', label: 'ScaleType.label', default: 'BothAxes',
    items: [
        { key: 'BothAxes', value: 'ScaleType.BothAxes'},
        { key: 'DomainAxis', value: 'ScaleType.DomainAxis'},
        { key: 'RangeAxis', value: 'ScaleType.RangeAxis'}
    ]
}); 
 
