/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { APDescriptor } from '../APDescriptor';
import { reg, UiProperty, UiPropertyProps } from '../UiProperty';

const TYPEID = 'BDiv';
reg(TYPEID, (mc) => { return <UPDiv mcontext={mc} />; });
export class PDiv extends APDescriptor {

    public constructor(init: Partial<PDiv>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

export class UPDiv extends React.Component<UiPropertyProps> {
    public state = { selected: 0 }
    render() {
        if (this.props.mcontext.descriptor.layouts === undefined || this.props.mcontext.descriptor.layouts.length === 0)
            return <></>;
        let className = "jr-jrws-properties-content";
        if (this.props.mcontext.descriptor.className) {
            className += " " + this.props.mcontext.descriptor.className;
        }
        return <div
            style={this.props.mcontext.descriptor.style ? this.props.mcontext.descriptor.style : { display: 'flex', flex: 1, flexDirection: 'column', flexBasis: 0 }}
            className={className}>
            {this.props.mcontext.descriptor.layouts?.map((key, index) => {
                key.readonly = this.props.mcontext.descriptor.readonly;
                return <UiProperty key={key.id ? key.id + '.' + index : 'div.' + key.type + '.' + index} mcontext={{ ...this.props.mcontext, descriptor: key }} />
            })}
        </div>
    }

}