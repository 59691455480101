/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../ui/APDescriptor";

import { PString } from "../../ui/primitive/UPString";
import { PTableElement } from "../../ui/tables/UPTableElement";
import ScriptletIcon from "../../../../../assets/icons/outline/scriptlets.svg";
import { PConnectedDynProperties } from "./UPConnectedDynProperties";
import { PJavaClass } from "../../ui/primitive/UPJavaClass";
import { PUniqueName } from "../../ui/primitive/UPUniqueName";

export const FScriptlet = {
    name: new PUniqueName({ id: 'name', label: 'Name', canBeEmpty: false, canBeNull: false }),
    scriptletDescription: new PString({ id: 'scriptletDescription', label: 'Description' }),
    class: new PJavaClass({
        id: 'class', label: 'Class', items: ['net.sf.jasperreports.engine.JRDefaultScriptlet',
            'com.jaspersoft.jasperreports.jrs.bursting.BurstingScriptlet'], refreshCacheOnAdd: true, refreshCacheOnDelete: true
    }),
}

export const MScriptlet: APDescriptor = new PTableElement({
    label: 'Scriptlet', icon: ScriptletIcon,
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    default: { class: 'net.sf.jasperreports.engine.JRDefaultScriptlet' },
    layouts: [FScriptlet.name, FScriptlet.scriptletDescription, FScriptlet.class, new PConnectedDynProperties({ id: 'dsScriptletCustomProperties', propspath: "scriptlet", propsname: "name" })]
});
