/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map, OrderedMap } from 'immutable';
import { uuidv4 } from '../../../../utils/uuidGenerator';
import { BandTypes } from '../../../../sagas/report/document/elementTypes';

export const setAttributeIfPresent = (map: Map<string, any>, attributeName: string, xmlNode : Element, avoidEmptyString = false) : boolean => {
    return setXMLAttributeIfPresent(map, attributeName, attributeName, xmlNode, avoidEmptyString);
}


export const setXMLAttributeIfPresent = (map: Map<string, any>, attributeName: string, xmlAttributeName: string, xmlNode : Element, avoidEmptyString = false) : boolean => {
    if (map.has(attributeName)){
        const value = map.get(attributeName);
        if (avoidEmptyString && typeof value === 'string' && value.trim().length === 0){
            return false;
        }
        xmlNode.setAttribute(xmlAttributeName, value);
        return true;
    }
    return false;
}

export const setExpressionNode = (parentElement : Element, xmlDocument: Document, expressionValue: string | null, expressionElementName: string) : boolean => {
    if (expressionValue !== null && expressionValue !== undefined){
        const expressionElement = xmlDocument.createElement(expressionElementName);
        const expressionContent = xmlDocument.createCDATASection(expressionValue);
        expressionElement.appendChild(expressionContent);
        parentElement.appendChild(expressionElement);
        return true;
    }
    return false;
}

export const setExpressionNodeFromMap = (parentElement : Element, xmlDocument: Document, node: Map<string, any>, expressionFieldName: string) : boolean => {
    return setExpressionNode(parentElement, xmlDocument, node.get(expressionFieldName), expressionFieldName);
}

export const setExpressionNodeWithPrefixFromMap = (parentElement : Element, xmlDocument: Document, node: Map<string, any>, expressionFieldName: string, prefix: string) : boolean => {
    return setExpressionNode(parentElement, xmlDocument, node.get(expressionFieldName), prefix + expressionFieldName);
}

export const createDatasetRun =  (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetRunElement = xmlDocument.createElement("datasetRun");

    const properties : OrderedMap<string, string | null> = elementNode.get("properties", null); 
    createProperties(datasetRunElement, properties, xmlDocument);

    setExpressionNodeFromMap(datasetRunElement, xmlDocument, elementNode, "parametersMapExpression");

    const datasetParameters : List<{name: string, expression: string}> = elementNode.get("datasetParameters", List<{name: string, expression: string}>()); 
    datasetParameters.forEach((propertyValue: {name: string, expression: string}) => {
        const parameterElement = xmlDocument.createElement("datasetParameter");
        parameterElement.setAttribute("name", propertyValue.name);
        setExpressionNode(parameterElement, xmlDocument, propertyValue.expression, "datasetParameterExpression");
        datasetRunElement.appendChild(parameterElement);
    });

    setExpressionNodeFromMap(datasetRunElement, xmlDocument, elementNode, "connectionExpression");
    setExpressionNodeFromMap(datasetRunElement, xmlDocument, elementNode, "dataSourceExpression");

    const returnValues : List<Map<string, string>> = elementNode.get("returnValues", List<Map<string, string>>()); 
    returnValues.forEach((propertyValue: Map<string, string>) => {
        const returnValueElement = xmlDocument.createElement("returnValue");
        setAttributeIfPresent(propertyValue, "fromVariable", returnValueElement);
        setAttributeIfPresent(propertyValue, "toVariable", returnValueElement);
        setAttributeIfPresent(propertyValue, "calculation", returnValueElement);
        setAttributeIfPresent(propertyValue, "incrementerFactoryClass", returnValueElement);
        datasetRunElement.appendChild(returnValueElement);
    });

    setAttributeIfPresent(elementNode, "subDataset", datasetRunElement);

    let uuid = elementNode.get('uuid');
    if (!uuid){
        uuid = uuidv4();
    }
    datasetRunElement.setAttribute('uuid', uuid);

    return datasetRunElement;
}

export const createFontElement = (fontNode: Map<string, any>, xmlDocument: Document) : Element | null => {
    const fontElement = xmlDocument.createElement("font");

    setAttributeIfPresent(fontNode, "reportFont", fontElement);
    setAttributeIfPresent(fontNode, "fontName", fontElement);
    setAttributeIfPresent(fontNode, "size", fontElement);
    setAttributeIfPresent(fontNode, "isBold", fontElement);
    setAttributeIfPresent(fontNode, "isItalic", fontElement);
    setAttributeIfPresent(fontNode, "isUnderline", fontElement);
    setAttributeIfPresent(fontNode, "isStrikeThrough", fontElement);
    setAttributeIfPresent(fontNode, "pdfFontName", fontElement);
    setAttributeIfPresent(fontNode, "pdfEncoding", fontElement);
    setAttributeIfPresent(fontNode, "isPdfEmbedded", fontElement);

    return (fontElement.attributes.length > 0) ? fontElement : null;
}

export const createReportFontElement = (fontNode: Map<string, any>, xmlDocument: Document) : Element | null => {
    const fontElement = xmlDocument.createElement("reportFont");

    setAttributeIfPresent(fontNode, "name", fontElement);
    setAttributeIfPresent(fontNode, "isDefault", fontElement);
    setAttributeIfPresent(fontNode, "fontName", fontElement);
    setAttributeIfPresent(fontNode, "size", fontElement);
    setAttributeIfPresent(fontNode, "isBold", fontElement);
    setAttributeIfPresent(fontNode, "isItalic", fontElement);
    setAttributeIfPresent(fontNode, "isUnderline", fontElement);
    setAttributeIfPresent(fontNode, "isStrikeThrough", fontElement);
    setAttributeIfPresent(fontNode, "pdfFontName", fontElement);
    setAttributeIfPresent(fontNode, "pdfEncoding", fontElement);
    setAttributeIfPresent(fontNode, "isPdfEmbedded", fontElement);

    return (fontElement.attributes.length > 0) ? fontElement : null;
}

export const createBox = (boxNode : Map<string, any>, xmlDocument : Document) : Element => {
    const boxElement = xmlDocument.createElement("box");
    setAttributeIfPresent(boxNode, "padding", boxElement);
    setAttributeIfPresent(boxNode, "topPadding", boxElement);
    setAttributeIfPresent(boxNode, "leftPadding", boxElement);
    setAttributeIfPresent(boxNode, "bottomPadding", boxElement);
    setAttributeIfPresent(boxNode, "rightPadding", boxElement);

    const penNode : Map<string, any> = boxNode.get("pen", null);
    if (penNode !== null && !penNode.isEmpty()){
        const penElement = createPen(penNode, "pen", xmlDocument);
        boxElement.appendChild(penElement);
    }

    
    const topPenNode : Map<string, any> = boxNode.get("topPen", null);
    if (topPenNode !== null && !topPenNode.isEmpty()){
        const penElement = createPen(topPenNode, "topPen", xmlDocument);
        boxElement.appendChild(penElement);
    }

    
    const leftPenNode : Map<string, any> = boxNode.get("leftPen", null);
    if (leftPenNode !== null && !leftPenNode.isEmpty()){
        const penElement = createPen(leftPenNode, "leftPen", xmlDocument);
        boxElement.appendChild(penElement);
    }

    
    const bottomPenNode : Map<string, any> = boxNode.get("bottomPen", null);
    if (bottomPenNode !== null && !bottomPenNode.isEmpty()){
        const penElement = createPen(bottomPenNode, "bottomPen", xmlDocument);
        boxElement.appendChild(penElement);
    }

    
    const rightPenNode : Map<string, any> = boxNode.get("rightPen", null);
    if (rightPenNode !== null && !rightPenNode.isEmpty()){
        const penElement = createPen(rightPenNode, "rightPen", xmlDocument);
        boxElement.appendChild(penElement);
    }

    return boxElement;
}

export const createPen = (penNode : Map<string, any>, penTagName: string, xmlDocument : Document) : Element => {
    const penElement = xmlDocument.createElement(penTagName);
    setAttributeIfPresent(penNode, "lineWidth", penElement);
    setAttributeIfPresent(penNode, "lineStyle", penElement);
    setAttributeIfPresent(penNode, "lineColor", penElement);
    return penElement;
}

export const addHyperlinkAttributes = (elementNode: Map<string, any>, parentElement: Element, xmlDocument: Document) => {
    setExpressionNodeFromMap(parentElement, xmlDocument, elementNode, "anchorNameExpression");
    setExpressionNodeFromMap(parentElement, xmlDocument, elementNode, "bookmarkLevelExpression");
    setExpressionNodeFromMap(parentElement, xmlDocument, elementNode, "hyperlinkReferenceExpression");
    setExpressionNodeFromMap(parentElement, xmlDocument, elementNode, "hyperlinkWhenExpression");
    setExpressionNodeFromMap(parentElement, xmlDocument, elementNode, "hyperlinkAnchorExpression");
    setExpressionNodeFromMap(parentElement, xmlDocument, elementNode, "hyperlinkPageExpression");
    setExpressionNodeFromMap(parentElement, xmlDocument, elementNode, "hyperlinkTooltipExpression");

    setAttributeIfPresent(elementNode, "hyperlinkType", parentElement);
    setAttributeIfPresent(elementNode, "hyperlinkTarget", parentElement);
    setAttributeIfPresent(elementNode, "bookmarkLevel", parentElement);

    const hyperlinkParameters : List<Map<string, any>> | null = elementNode.get("hyperlinkParameters", null);
    if (hyperlinkParameters !== null){
        hyperlinkParameters.forEach((parameterMap: Map<string, any>) => {
            const hyperlinkParameterElement = xmlDocument.createElement("hyperlinkParameter");
            const name = parameterMap.get('name');
            const expression = parameterMap.get('valueExpression');
            hyperlinkParameterElement.setAttribute("name", name);
            setExpressionNode(hyperlinkParameterElement, xmlDocument, expression, "hyperlinkParameterExpression");
            parentElement.appendChild(hyperlinkParameterElement);
        });
    }
}

export const createPropertiesExpression = (parentElement: Element, propertiesExpression: OrderedMap<string, Map<string, any>>, xmlDocument : Document ) => {
    if (propertiesExpression && propertiesExpression !== null){
        propertiesExpression.forEach((property: Map<string, any>, propertyName: string) => {
            const valueExpressionValue = property.get('valueExpression');
            if (valueExpressionValue !== undefined){
                const propertyElement = xmlDocument.createElement("propertyExpression");
                propertyElement.setAttribute("name", propertyName);
                const expressionContent = xmlDocument.createCDATASection(valueExpressionValue);
                propertyElement.appendChild(expressionContent);
                const evaluationTime = property.get('evaluationTime');
                if (evaluationTime !== undefined && evaluationTime !== null) {
                    propertyElement.setAttribute("evaluationTime", evaluationTime);
                }
                const type = property.get('type');
                if (type !== undefined && type !== null) {
                    propertyElement.setAttribute("type", type);
                }
                parentElement.appendChild(propertyElement);
            } else {
                const propertyElement = xmlDocument.createElement("property");
                propertyElement.setAttribute("name", propertyName);
                const value : string | boolean | undefined | null = property.get('value');
                if (value !== undefined && value !== null){
                    if (typeof value === 'string' && (value.includes('&') || value.includes('"') || value.includes('\'') || value.includes('<') || value.includes('>'))){
                        const propertyContent = xmlDocument.createCDATASection(value);
                        propertyElement.appendChild(propertyContent);
                    } else {
                        propertyElement.setAttribute("value", value.toString());
                    }
                }
                parentElement.appendChild(propertyElement);
            }
        });
    }
}

export const createProperties = (parentElement: Element, properties: OrderedMap<string,string | null>, xmlDocument : Document ) => {
    if (properties !== null){
        properties.forEach((propertyValue: string | null, propertyName: string) => {
            const propertyElement = xmlDocument.createElement("property");
            propertyElement.setAttribute("name", propertyName);
            if (propertyValue !== null){
                propertyElement.setAttribute("value", propertyValue);
            }
            parentElement.appendChild(propertyElement);
        });
       
    }
}

export const createReportElement = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const reportElement = xmlDocument.createElement("reportElement");
    setAttributeIfPresent(elementNode, "x", reportElement);
    setAttributeIfPresent(elementNode, "y", reportElement);
    setAttributeIfPresent(elementNode, "width", reportElement);
    setAttributeIfPresent(elementNode, "height", reportElement);
    setAttributeIfPresent(elementNode, "key", reportElement);
    setAttributeIfPresent(elementNode, "style", reportElement);
    setAttributeIfPresent(elementNode, "mode", reportElement);
    setAttributeIfPresent(elementNode, "forecolor", reportElement);
    setAttributeIfPresent(elementNode, "backcolor", reportElement);

    setAttributeIfPresent(elementNode, "positionType", reportElement);
    setAttributeIfPresent(elementNode, "stretchType", reportElement);
    setAttributeIfPresent(elementNode, "isPrintRepeatedValues", reportElement);
    setAttributeIfPresent(elementNode, "isRemoveLineWhenBlank", reportElement);
    setAttributeIfPresent(elementNode, "isPrintInFirstWholeBand", reportElement);
    setAttributeIfPresent(elementNode, "isPrintWhenDetailOverflows", reportElement);
    setAttributeIfPresent(elementNode, "printWhenGroupChanges", reportElement);

    let uuid = elementNode.get('uuid');
    if (!uuid){
        uuid = uuidv4();
    }
    reportElement.setAttribute('uuid', uuid);

    const propertiesExpression : OrderedMap<string, Map<string, any>> = elementNode.get("properties", null); 
    createPropertiesExpression(reportElement, propertiesExpression, xmlDocument);

    setExpressionNodeFromMap(reportElement, xmlDocument, elementNode, "printWhenExpression");
    return reportElement;
}

export const createObjectFromMap = (mapElement: Map<string, any>) : any =>{
    const element: any = {}
    mapElement.forEach((value: any, key: string) => {
        if (value) {
            element[key] = value;
        }
    });
    return element;
}

export const getBandJRTypeFromModel = (modelType: string) => {
    if (modelType === BandTypes.BAND_DETAIL){
        return 'detail';
    } else if (modelType === BandTypes.BAND_BACKGROUND){
        return 'background';
    } else if (modelType === BandTypes.BAND_TITLE){
        return 'title';
    } else if (modelType === BandTypes.BAND_PAGE_HEADER){
        return 'pageHeader';
    } else if (modelType === BandTypes.BAND_COLUMN_HEADER){
        return 'columnHeader';
    } else if (modelType === BandTypes.BAND_COLUMN_FOOTER){
        return 'columnFooter';
    } else if (modelType === BandTypes.BAND_PAGE_FOOTER){
        return 'pageFooter';
    } else if (modelType === BandTypes.BAND_LAST_PAGE_FOOTER){
        return 'lastPageFooter';
    } else if (modelType === BandTypes.BAND_SUMMARY){
        return 'summary';
    } else if (modelType === BandTypes.BAND_NO_DATA){
        return 'noData';
    }
    
    return 'detail';
}