/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PButtonsEnum } from "../../ui/primitive/UPButtonsEnum";


export const ErrorCorrectionLevelEnum = new PButtonsEnum({
    id: 'errorCorrectionLevel', label: 'ErrorCorrectionLevel.label', default: 'H',
    items: [
        { key: 'L', value: 'L' },
        { key: 'M', value: 'M' },
        { key: 'Q', value: 'Q' },
        { key: 'H', value: 'H' }
    ]
});

