/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const BarChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
            <defs>
                <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
                    <path d="M0 0h270v175H0V0z" />
                </clipPath>
                <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
                    <path d="M44 4v130h218V4z" />
                </clipPath>
            </defs>
            <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
                <path fill="none" d="M24.51 157.188L245.49 157.188" clipPath="url(#clipPath1)" />
                <path fill="none" d="M24.51 173.5L245.49 173.5" clipPath="url(#clipPath1)" />
                <path fill="none" d="M24.51 173.5L24.51 157.188" clipPath="url(#clipPath1)" />
                <path fill="none" d="M245.49 173.5L245.49 157.188" clipPath="url(#clipPath1)" />
                <path fill="#F55" stroke="none" d="M28.01 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
                <text x="40.01" y="168.113" stroke="none" clipPath="url(#clipPath1)">Double Freight</text>
                <path fill="#55F" stroke="none" d="M105.22 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
                <text x="117.221" y="168.113" stroke="none" clipPath="url(#clipPath1)">Normal Freight</text>
                <path fill="#5F5" stroke="none" d="M183.17 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
                <text x="195.17" y="168.113" stroke="none" clipPath="url(#clipPath1)">Half Freight</text>
                <path fill="none" strokeWidth="0.5" d="M44 134L262 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M70.16 134L70.16 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M70.16 134L70.16 136" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M111.58 134L111.58 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M111.58 134L111.58 136" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M153 134L153 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M153 134L153 136" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M194.42 134L194.42 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M194.42 134L194.42 136" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M235.84 134L235.84 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M235.84 134L235.84 136" clipPath="url(#clipPath1)" />
                <text x="56.965" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Arge...</text>
                <text x="97.449" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Austria</text>
                <text x="136.602" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Belgium</text>
                <text x="183.258" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Brazil</text>
                <text x="220.619" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Canada</text>
                <path fill="none" strokeWidth="0.5" d="M44 4L44 134" clipPath="url(#clipPath1)" />
                <text x="32.91" y="136.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
                <path fill="none" strokeWidth="0.5" d="M42 134L44 134" clipPath="url(#clipPath1)" />
                <text x="15.098" y="115.832" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">2.500</text>
                <path fill="none" strokeWidth="0.5" d="M42 113.062L44 113.062" clipPath="url(#clipPath1)" />
                <text x="15.098" y="94.894" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">5.000</text>
                <path fill="none" strokeWidth="0.5" d="M42 92.124L44 92.124" clipPath="url(#clipPath1)" />
                <text x="15.098" y="73.956" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">7.500</text>
                <path fill="none" strokeWidth="0.5" d="M42 71.186L44 71.186" clipPath="url(#clipPath1)" />
                <text x="10.008" y="53.018" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.000</text>
                <path fill="none" strokeWidth="0.5" d="M42 50.249L44 50.249" clipPath="url(#clipPath1)" />
                <text x="10.008" y="32.08" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">12.500</text>
                <path fill="none" strokeWidth="0.5" d="M42 29.311L44 29.311" clipPath="url(#clipPath1)" />
                <text x="10.008" y="11.143" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">15.000</text>
                <path fill="none" strokeWidth="0.5" d="M42 8.373L44 8.373" clipPath="url(#clipPath1)" />
            </g>
            <g fill="silver" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
                <path fill="none" d="M44 134L262 134" clipPath="url(#clipPath2)" />
                <path fill="none" d="M44 113.062L262 113.062" clipPath="url(#clipPath2)" />
                <path fill="none" d="M44 92.124L262 92.124" clipPath="url(#clipPath2)" />
                <path fill="none" d="M44 71.186L262 71.186" clipPath="url(#clipPath2)" />
                <path fill="none" d="M44 50.249L262 50.249" clipPath="url(#clipPath2)" />
                <path fill="none" d="M44 29.311L262 29.311" clipPath="url(#clipPath2)" />
                <path fill="none" d="M44 8.373L262 8.373" clipPath="url(#clipPath2)" />
                <path fill="#F55" stroke="none" d="M54.9 123.974H62.167V134H54.9z" clipPath="url(#clipPath2)" />
                <path fill="#55F" stroke="none" d="M66.527 128.987H73.794V134H66.527z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" stroke="none" d="M78.153 131.493H85.42V134H78.153z" clipPath="url(#clipPath2)" />
                <path fill="#F55" stroke="none" d="M96.32 10.191H103.58699999999999V134H96.32z" clipPath="url(#clipPath2)" />
                <path fill="#55F" stroke="none" d="M107.947 72.095H115.214V134H107.947z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" stroke="none" d="M119.573 103.048H126.83999999999999V134H119.573z" clipPath="url(#clipPath2)" />
                <path fill="#F55" stroke="none" d="M137.74 112.557H145.007V134H137.74z" clipPath="url(#clipPath2)" />
                <path fill="#55F" stroke="none" d="M149.367 123.279H156.634V134H149.367z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" stroke="none" d="M160.993 128.639H168.26V134H160.993z" clipPath="url(#clipPath2)" />
                <path fill="#F55" stroke="none" d="M179.16 52.256H186.427V134H179.16z" clipPath="url(#clipPath2)" />
                <path fill="#55F" stroke="none" d="M190.787 93.128H198.054V134H190.787z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" stroke="none" d="M202.413 113.564H209.68V134H202.413z" clipPath="url(#clipPath2)" />
                <path fill="#F55" stroke="none" d="M220.58 97.181H227.847V134H220.58z" clipPath="url(#clipPath2)" />
                <path fill="#55F" stroke="none" d="M232.207 115.591H239.474V134H232.207z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" stroke="none" d="M243.833 124.795H251.1V134H243.833z" clipPath="url(#clipPath2)" />
            </g>
        </svg>
    );
}
export default BarChartIcon;