/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { FormControlledWritableCombo, i18n, IWritableComboItem } from "@jss/js-common";
import { List, Map as ImmutableMap } from 'immutable';
import * as React from 'react';
import { APDescriptor } from '../../ui/APDescriptor';
import { getKey, getNormalizedPath, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import '../../../../../assets/uxpl/css/UPControls.css';

const TYPEID = 'stylesEnum';
reg(TYPEID, (mc) => { return <UPParentStyles mcontext={mc} />; });
export class PParentStyles extends APDescriptor {
    items: { key: string, value: string, deprecated?: boolean }[];
    public constructor(init: Partial<PParentStyles>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

interface IState {
    isFocused: boolean,
}

export class UPParentStyles extends React.Component<UiPropertyProps, IState> {

    state = {
        isFocused: false,
    };

    isInHierarchy = (allStyles : Map<string, ImmutableMap<string, any>>, styleToCheckName: string, currentStyleName: string) : boolean => {
        if (styleToCheckName === currentStyleName){
            return true;
        }
        let styleData = allStyles.get(styleToCheckName);
        let parentStyle = styleData.get('style');
        let found = false;
        while (!found && parentStyle){
            if (parentStyle === currentStyleName){
                found = true;
            } else {
                styleData = allStyles.get(parentStyle);
                parentStyle = styleData.get('style');
            }
        }
        return found;
    }

    render() {
        const d = this.props.mcontext.descriptor as PParentStyles;

        const styleNamePath = getPath('name', this.props.mcontext.elements[0].path);
        const currentStyleName : string = this.props.mcontext.model.getIn(styleNamePath) as string;

        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        let v = this.props.mcontext.model.getIn(p);

        const items : IWritableComboItem[] = [{ key: 'noStyleValueKey', value: i18n.t('StyleCombo.noStyle'), style: {color: 'lightgray'}, textValue: '' }];
        let reportStyles: List<ImmutableMap<string, any>> = this.props.mcontext.model.get('styles');
        const allStylesMap = new Map<string, ImmutableMap<string, any>>();
        reportStyles.forEach((reportStyle) => {
            const reportStyleName = reportStyle.get('name');
            allStylesMap.set(reportStyleName, reportStyle);
        })
        reportStyles = reportStyles.filter((style: ImmutableMap<string, any>) => {
            const styleName = style.get('name');
            return !this.isInHierarchy(allStylesMap, styleName, currentStyleName);
        });
        reportStyles.forEach(s => {
            const name = s.get('name');
            items.push({ key: name, value: name })
        });



        // read styles from the model ... ? get also the files from template?
        const classes: string[] = [];
        if (this.props.mcontext.descriptor.className) {
            classes.push(this.props.mcontext.descriptor.className);
        }
        if (v === undefined && !this.state.isFocused) {
            classes.push('placeHolderTextColor');
            v = i18n.t('StyleCombo.noStyle');
        }
        return <FormControlledWritableCombo key={getKey(p)} items={items}
            className={classes.join(' ')}
            style={this.props.mcontext.descriptor.style}
            value={v}
            onComboChange={this.onNameChange}
            label={i18n.t(d.label)}
            disabled={d.readonly}
            inline={true}
            InputLabelProps={d.deprecated ? { className: 'deprecatedProperty' } : undefined}
            onTextFieldFocus={() => {
                this.setState({isFocused: true});
            }}
            onTextFieldBlur={() => {
                this.setState({isFocused: false});
            }}
            size="small"
        />
    }
    public onNameChange = (selectedKey: string | undefined, value: string) => {
        this.props.mcontext.elements.forEach(key => {
            if (selectedKey === 'noStyleValueKey' || !value || value.trim().length === 0 ){
                const path = getNormalizedPath(key.path);
                path.push(this.props.mcontext.descriptor.id);
                this.props.mcontext.deleteElement(path);
            } else {
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: value });
            }
        });
    }

}