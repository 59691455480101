/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Map } from 'immutable';
import { ElementTypes } from '../../../../sagas/report/document/elementTypes';
import { addAttributeToMap, addChildToParent, createReportElement, generatePath, getChildNodeByName, getNamespace, IObjectCounter } from './JrxmlHelpers';

export const ADHOC_NAMESPACE = "ADHOC_NAMESPACE";

export const isAdhoc = (componentElement : Element) : boolean => {
    const childList = componentElement.childNodes;
    let found = false;
    for (let i = 0; i < childList.length && !found; i++) {
        const child = childList[i];
        const nodeName = child.nodeName;
        if (child.nodeType === Node.ELEMENT_NODE && nodeName !== "reportElement"){
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length>0 ? baseNamespace + ":" : baseNamespace;
            found = nodeName === namespace + "adhoc";
        }
    }
    return found;
}

export const createAdhocElement = (componentElementNode : Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) => {
    const reportElementNode : Element = getChildNodeByName(componentElementNode, "reportElement") as Element;
    const pathValue = generatePath(parentElement);
    let result : Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.AD_HOC_COMPONENT, objectCounter);
  
    let newDocument = document;
    componentElementNode.childNodes.forEach(compChild => {
        if (compChild.nodeType === Node.ELEMENT_NODE && compChild.nodeName !== "reportElement"){
            const baseNamespace = getNamespace(compChild as Element);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : "";
            const CVCNode = getChildNodeByName(componentElementNode, namespace + "adhoc");
            result = result.set(ADHOC_NAMESPACE, baseNamespace);

            if (CVCNode !== null){
                const CVCElement = CVCNode as Element;
                result = addAttributeToMap(CVCElement, baseNamespace.length > 0 ? "xmlns:" + baseNamespace : "xmlns", result); 
                result = addAttributeToMap(CVCElement, "xsi:schemaLocation", result);
            }
            newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), newDocument, result.get("id"));
            newDocument = newDocument.setIn(["elements", result.get("id")], result);
        }
    });
    return newDocument;
}
