/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from "react";
import { RepositoryApi, UserCredential, UserJRS, UserRest } from "@jss/js-rest-api";
import { useDispatch } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";
import { addLoggedUser, addUserMetadata } from "../../store/actions/actions";

export function LoggedIn() {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const provider = searchParams.get('provider') as string;

    if (provider === 'jrs') {
        const serverAddress = searchParams.get('serverAddress') as string;
        const username = searchParams.get('username') as string;
        const organization = searchParams.get('organization') as string;
        RepositoryApi.inst().setBaseURL('jrs:' + serverAddress, username);
        dispatch(addLoggedUser('jrs:' + serverAddress, username));

        new UserJRS().getUser(serverAddress, organization, username).then((response) => {
            dispatch(addUserMetadata(response.data as UserCredential));
        }).catch((error) => {
            console.log(error);
            dispatch(addUserMetadata({ type: 'jrs', username: username }));
        });
    } else {
        const user = searchParams.get('user') as string;
        const info = searchParams.get('info') as string;
        RepositoryApi.inst().setBaseURL(provider, user, info);
        dispatch(addLoggedUser(provider, user, info));
        new UserRest().getUser(`/${provider}-userinfo`).then((response) => {
            dispatch(addUserMetadata(response.data as UserCredential));
        }).catch((error) => {
            console.log(error);
            dispatch(addUserMetadata({ type: provider, username: ' ' }));
        });
    }

    return <Navigate to="/" />;
}
