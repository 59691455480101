/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const ThermometerChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
         </defs>
         <g fill="#00C" stroke="#00C" textRendering="optimizeLegibility">
            <path stroke="none" d="M127.5 68a7.5 7.5 0 00-7.5 7.5v14.868c-11.825 5.618-20 17.67-20 31.632 0 19.33 15.67 35 35 35s35-15.67 35-35c0-13.962-8.175-26.014-20-31.632V75.5a7.5 7.5 0 00-7.5-7.5z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#0f0" strokeWidth="2" d="M157 61.429L167 61.429" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#FFC800" strokeWidth="2" d="M157 78.571L167 78.571" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="red" strokeWidth="2" d="M157 82L167 82" clipPath="url(#clipPath1)" />
         </g>
         <g strokeWidth="2" fontFamily="sans-serif" fontSize="10" textRendering="optimizeLegibility">
            <text x="102.677" y="86.779" stroke="none" clipPath="url(#clipPath1)">0</text>
            <path fill="none" stroke="gray" strokeWidth="0.5" d="M113 83L115 83" clipPath="url(#clipPath1)" />
            <text x="74.22" y="69.065" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.000</text>
            <path fill="none" stroke="gray" strokeWidth="0.5" d="M113 65.286L115 65.286" clipPath="url(#clipPath1)" />
            <text x="113" y="127" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)" fontFamily="'DejaVu Sans'" fontSize="12">$7.990</text>
            <path fill="none" strokeWidth="1" d="M135 12.35c-11.046 0-20 8.954-20 20v55.001h0C103.044 94.267 95 107.194 95 122c0 22.091 17.909 40 40 40s40-17.909 40-40c0-14.806-8.044-27.733-20-34.649h0V32.35c0-11.046-8.954-20-20-20z" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="1" d="M135 17.35c-8.284 0-15 6.716-15 15v58.018h0c-11.825 5.618-20 17.67-20 31.632 0 19.33 15.67 35 35 35s35-15.67 35-35c0-13.962-8.175-26.014-20-31.632h0V32.35c0-8.284-6.716-15-15-15z" clipPath="url(#clipPath1)" />
         </g>
      </svg>
   );
}
export default ThermometerChartIcon;