/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
const cache = new Map<string, HTMLLinkElement>();

export function loadExternalCSS(href: string): HTMLLinkElement {
    let cssElement: HTMLLinkElement;

    if (cache.has(href)) {
        cssElement = cache.get(href);
    } else {
        cssElement = document.head.querySelector(`link[href="${href}"]`);

        if (!cssElement) {
            cssElement = document.createElement("link");
            cssElement.type = "text/css";
            cssElement.rel = "stylesheet";
            cssElement.href = href;

            document.head.appendChild(cssElement);
        }

        cache.set(href, cssElement);
    }

    return cssElement;
}
