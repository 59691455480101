/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map } from 'immutable';
import { MAP_NAMESPACE } from '../reader/JrxmlMapUtils';
import { createDatasetRun, createReportElement, setAttributeIfPresent, setExpressionNode, setExpressionNodeFromMap } from './JrxmlHelper';

export const createMapElement = (elementNode: Map<string, any>, xmlDocument: Document) : Element | null => {
    const componentElement = xmlDocument.createElement("componentElement");
    const reportElement = createReportElement(elementNode, xmlDocument);
    componentElement.appendChild(reportElement);

    const baseNamespace = elementNode.get(MAP_NAMESPACE, "c");
    const namespace = baseNamespace + ":";

    const mapElement = xmlDocument.createElement(namespace + "map");

    setExpressionNodeFromMap(mapElement, xmlDocument, elementNode, namespace + "latitudeExpression");
    setExpressionNodeFromMap(mapElement, xmlDocument, elementNode, namespace + "longitudeExpression");
    setExpressionNodeFromMap(mapElement, xmlDocument, elementNode, namespace + "addressExpression");
    setExpressionNodeFromMap(mapElement, xmlDocument, elementNode, namespace + "zoomExpression");
    setExpressionNodeFromMap(mapElement, xmlDocument, elementNode, namespace + "languageExpression");

    const legendItems : Map<string, Map<string, any>> = elementNode.get("legendItems", Map<string, Map<string, any>>()); 
    if (legendItems.size > 0){
        const legendItemsElement = xmlDocument.createElement(namespace + "legendItem");
        legendItems.forEach((legendPropertyValue: Map<string, any>, propertyName: string) => {
            const itemPropertyElement = xmlDocument.createElement(namespace + "itemProperty");
            itemPropertyElement.setAttribute("name", propertyName);
            let hasValue = false;
            hasValue = hasValue || setAttributeIfPresent(legendPropertyValue, "value", itemPropertyElement, true);
            hasValue = hasValue || setExpressionNodeFromMap(itemPropertyElement, xmlDocument, legendPropertyValue, "valueExpression");
            if (hasValue){
                legendItemsElement.appendChild(itemPropertyElement);
            }
        });
        if (legendItemsElement.childNodes.length > 0){
            mapElement.appendChild(legendItemsElement);
        }
    }

    const resetMapItems : Map<string, Map<string, any>> = elementNode.get("resetMapItems", Map<string, Map<string, any>>()); 
    if (resetMapItems.size > 0){
        const resetMapItemsItemsElement = xmlDocument.createElement(namespace + "resetMapItem");
        resetMapItems.forEach((resetPropertyValue: Map<string, any>, propertyName: string) => {
            const itemPropertyElement = xmlDocument.createElement(namespace + "itemProperty");
            itemPropertyElement.setAttribute("name", propertyName);
            let hasValue = false;
            hasValue = hasValue || setAttributeIfPresent(resetPropertyValue, "value", itemPropertyElement, true);
            hasValue = hasValue || setExpressionNodeFromMap(itemPropertyElement, xmlDocument, resetPropertyValue, "valueExpression");
            if (hasValue){
                resetMapItemsItemsElement.appendChild(itemPropertyElement);
            }
        });
        if (resetMapItemsItemsElement.childNodes.length > 0){
            mapElement.appendChild(resetMapItemsItemsElement);
        }
    }

    const markerDatasetNode = elementNode.get("markerDataset", null);
    if (markerDatasetNode !== null){
        mapElement.appendChild(createMarkerDataset(markerDatasetNode, xmlDocument, namespace));
    } else {
        const markersData : List<Map<string, any>> = elementNode.get("markerDatas", List<Map<string, any>>()); 
        markersData.forEach((propertyValue: Map<string, any>) => {
            mapElement.appendChild(createMarkerData(propertyValue, xmlDocument, namespace));
        });
    }

    const pathStyles : List<Map<string, any>> = elementNode.get("pathStyles", List<Map<string, any>>()); 
    pathStyles.forEach((propertyValue: Map<string, any>) => {
        mapElement.appendChild(createPathStyle(propertyValue, xmlDocument, namespace));
    });

    const pathDatas : List<Map<string, any>> = elementNode.get("pathDatas", List<Map<string, any>>()); 
    pathDatas.forEach((propertyValue: Map<string, any>) => {
        mapElement.appendChild(createPathData(propertyValue, xmlDocument, namespace));
    });

    mapElement.setAttribute("xmlns:" + baseNamespace, elementNode.get("xmlns:" + baseNamespace, "http://jasperreports.sourceforge.net/jasperreports/components"));
    mapElement.setAttribute("xsi:schemaLocation", elementNode.get("xsi:schemaLocation", "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd"));
    setAttributeIfPresent(elementNode, 'markerClustering', mapElement, true);
    setAttributeIfPresent(elementNode, 'markerSpidering', mapElement, true);

    setAttributeIfPresent(elementNode, "mapScale", mapElement);
    setAttributeIfPresent(elementNode, "evaluationTime", mapElement);
    setAttributeIfPresent(elementNode, "evaluationGroup", mapElement);
    setAttributeIfPresent(elementNode, "mapType", mapElement);
    setAttributeIfPresent(elementNode, "imageType", mapElement);
    setAttributeIfPresent(elementNode, "onErrorType", mapElement);

    componentElement.appendChild(mapElement);
    return componentElement;
}

export const createFusionMapElement = (elementNode: Map<string, any>, xmlDocument: Document) : Element | null => {
    const componentElement = xmlDocument.createElement("componentElement");
    const reportElement = createReportElement(elementNode, xmlDocument);
    componentElement.appendChild(reportElement);

    const baseNamespace = elementNode.get(MAP_NAMESPACE, "fm");
    const namespace = baseNamespace + ":";

    const mapElement = xmlDocument.createElement(namespace + "map");

    const mapNameExpression = elementNode.get('mapNameExpression');
    if (mapNameExpression){
        setExpressionNode(mapElement, xmlDocument, mapNameExpression, namespace + 'mapNameExpression');
    }

    const mapProperties : Map<string, Map<string, any>> = elementNode.get('mapProperties');
    if (mapProperties){
        mapProperties.forEach((mapProperty: Map<string, any>, name: string) => {
            const propertyNode = xmlDocument.createElement(namespace + "mapProperty");
            propertyNode.setAttribute('name', name);
            const propertyExpression = mapProperty.get('propertyExpression');
            if (propertyExpression){
                setExpressionNode(propertyNode, xmlDocument, propertyExpression, namespace + 'propertyExpression');   
            }
            mapElement.appendChild(propertyNode);
        });
    }

    const colorRanges = elementNode.get('colorRanges');
    if (colorRanges) {
        colorRanges.forEach((colorRange: Map<string, any>) => {
            const colorRangeNode = xmlDocument.createElement(namespace + "colorRange");
            setAttributeIfPresent(colorRange, 'color', colorRangeNode);
            const minValueExpression = colorRange.get('minValueExpression');
            if (minValueExpression){
                setExpressionNode(colorRangeNode, xmlDocument, minValueExpression, namespace + 'minValueExpression');   
            }
            const maxValueExpression = colorRange.get('maxValueExpression');
            if (maxValueExpression){
                setExpressionNode(colorRangeNode, xmlDocument, maxValueExpression, namespace + 'maxValueExpression');   
            }
            const labelExpression = colorRange.get('labelExpression');
            if (labelExpression){
                setExpressionNode(colorRangeNode, xmlDocument, labelExpression, namespace + 'labelExpression');   
            }
            mapElement.appendChild(colorRangeNode);
        });
    }

    const mapDataset = elementNode.get('mapDataset');
    if (mapDataset) {
        const mapDatasetElement = xmlDocument.createElement(namespace + "mapDataset");
        const dataset = mapDataset.get('dataset');
        if (dataset){
            const datasetElement = createDataset(dataset, xmlDocument);
            mapDatasetElement.appendChild(datasetElement);
        }
        const entities = mapDataset.get('entities');
        if (entities){
            entities.forEach((entity: Map<string, any>) => {
                const entityElement = createEntity(entity, xmlDocument, namespace);
                mapDatasetElement.appendChild(entityElement);
            });
        }
        mapElement.appendChild(mapDatasetElement);
    }

    const shapeData = elementNode.get('shapeData');
    if (shapeData) {
        mapElement.appendChild(createShapeData(shapeData, xmlDocument, namespace));
    }

    const markerData = elementNode.get('markerData');
    if (markerData) {
        const markerDataElement = createMarkerData(markerData, xmlDocument, 'c:');   
        markerDataElement.setAttribute("xmlns:c", "http://jasperreports.sourceforge.net/jasperreports/components");
        markerDataElement.setAttribute("xsi:schemaLocation", "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd");
        mapElement.appendChild(markerDataElement);
    }

    mapElement.setAttribute("xmlns:" + baseNamespace, elementNode.get("xmlns:" + baseNamespace, "http://jaspersoft.com/fusion"));
    mapElement.setAttribute("xsi:schemaLocation", elementNode.get("xsi:schemaLocation", "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd"));


    componentElement.appendChild(mapElement);
    return componentElement;
}

export const createTibcoMapElement = (elementNode: Map<string, any>, xmlDocument: Document) : Element | null => {
    const componentElement = xmlDocument.createElement("componentElement");
    const reportElement = createReportElement(elementNode, xmlDocument);
    componentElement.appendChild(reportElement);

    const baseNamespace = elementNode.get(MAP_NAMESPACE, "c");
    const namespace = baseNamespace + ":";

    const mapElement = xmlDocument.createElement(namespace + "tibcoMap");

    const mapDataElement = xmlDocument.createElement(namespace + "mapData");
    const datasetNode : Map<string, string> = elementNode.get("dataset", null); 
    if (datasetNode !== null){
        const datasetElement = createDataset(datasetNode, xmlDocument);
        mapDataElement.appendChild(datasetElement);
    }

    const mapDataNode : Map<string, Map<string, string>> = elementNode.get("mapData", null);
    if (mapDataNode !== null){
        const itemElement = xmlDocument.createElement(namespace + "item");
        mapDataNode.forEach((propertyValue: Map<string, any>, name: string) => {
            const itemPropertyElement = xmlDocument.createElement(namespace + "itemProperty");
            itemPropertyElement.setAttribute("name", name);
            setAttributeIfPresent(propertyValue, "value", itemPropertyElement);
            setExpressionNodeFromMap(itemPropertyElement, xmlDocument, propertyValue, "valueExpression");
            itemElement.appendChild(itemPropertyElement);
        });

        mapDataElement.appendChild(itemElement);
    }

    mapElement.appendChild(mapDataElement);

    const markerDatasetNode = elementNode.get("markerDataset", null);
    if (markerDatasetNode !== null){
        mapElement.appendChild(createMarkerDataset(markerDatasetNode, xmlDocument, namespace));
    } else {
        const markersData : List<Map<string, any>> = elementNode.get("markerDatas"); 
        markersData.forEach((propertyValue: Map<string, any>) => {
            mapElement.appendChild(createMarkerData(propertyValue, xmlDocument, namespace));
        });
    }

    const pathStyles : List<Map<string, any>> = elementNode.get("pathStyles"); 
    pathStyles.forEach((propertyValue: Map<string, any>) => {
        mapElement.appendChild(createPathStyle(propertyValue, xmlDocument, namespace));
    });

    const pathDatas : List<Map<string, any>> = elementNode.get("pathDatas"); 
    pathDatas.forEach((propertyValue: Map<string, any>) => {
        mapElement.appendChild(createPathData(propertyValue, xmlDocument, namespace));
    });

    mapElement.setAttribute("xmlns:" + baseNamespace, elementNode.get("xmlns:" + baseNamespace, "http://jaspersoft.com/tibcomaps"));
    mapElement.setAttribute("xsi:schemaLocation", elementNode.get("xsi:schemaLocation", "http://jaspersoft.com/tibcomaps http://jaspersoft.com/schema/tibcomaps.xsd"));

    componentElement.appendChild(mapElement);
    return componentElement;
}

const createMarkerData = (elementNode: Map<string, any>, xmlDocument: Document, namespace: string) : Element => {
    return createItemData(elementNode, xmlDocument, namespace + "markerData", namespace);
}

const createPathStyle = (elementNode: Map<string, any>, xmlDocument: Document, namespace: string) : Element => {
    return createItemData(elementNode, xmlDocument, namespace + "pathStyle", namespace);
}

const createPathData = (elementNode: Map<string, any>, xmlDocument: Document, namespace: string) : Element => {
    return createItemData(elementNode, xmlDocument, namespace + "pathData", namespace);
}

const createItemData = (elementNode: Map<string, any>, xmlDocument: Document, tagName: string, namespace: string) : Element => {
    const itemDataElement = xmlDocument.createElement(tagName);
    const datasetNode : Map<string, string> = elementNode.get("dataset", null); 
    if (datasetNode !== null){
        const datasetElement = createDataset(datasetNode, xmlDocument);
        itemDataElement.appendChild(datasetElement);
    }

    const items: List<Map<string, Map<string, any>>> = elementNode.get("items", List<Map<string, Map<string, any>>>());
    items.forEach((propertyValue: Map<string, Map<string, any>>) => {
        const itemElement = xmlDocument.createElement(namespace + "item");
        propertyValue.forEach((markerPropertyValue: Map<string, any>, propertyName: string) => {
            if (propertyName !== 'id'){
                const itemPropertyElement = xmlDocument.createElement(namespace + "itemProperty");
                itemPropertyElement.setAttribute("name", propertyName);
                let hasValue = false;
                hasValue = hasValue || setAttributeIfPresent(markerPropertyValue, "value", itemPropertyElement, true);
                hasValue = hasValue || setExpressionNodeFromMap(itemPropertyElement, xmlDocument, markerPropertyValue, "valueExpression");
                if (hasValue) {
                    itemElement.appendChild(itemPropertyElement);
                }
            }
        });
        if (itemElement.childNodes.length > 0){
            itemDataElement.appendChild(itemElement);
        }
    });

    const seriesNameExpression = elementNode.get('seriesNameExpression');
    if (seriesNameExpression){
        setExpressionNode(itemDataElement, xmlDocument, seriesNameExpression, namespace + 'seriesNameExpression');
    }
    const markerClusteringExpression = elementNode.get('markerClusteringExpression');
    if (markerClusteringExpression){
        setExpressionNode(itemDataElement, xmlDocument, markerClusteringExpression, namespace + 'markerClusteringExpression');
    }
    const markerSpideringExpression = elementNode.get('markerSpideringExpression');
    if (markerSpideringExpression){
        setExpressionNode(itemDataElement, xmlDocument, markerSpideringExpression, namespace + 'markerSpideringExpression');
    }
    const legendIconExpression = elementNode.get('legendIconExpression');
    if (legendIconExpression){
        setExpressionNode(itemDataElement, xmlDocument, legendIconExpression, namespace + 'legendIconExpression');
    }

    return itemDataElement;
}

const createMarkerDataset = (elementNode: Map<string, any>, xmlDocument: Document, namespace: string) : Element => {
    const markerDatasetElement = xmlDocument.createElement(namespace + "markerDataset");

    const datasetRunNode = elementNode.get("datasetRun", null);
    if (datasetRunNode !== null){
        markerDatasetElement.appendChild(createDatasetRun(datasetRunNode, xmlDocument));
    }

    const markers: List<List<Map<string, any>>> = elementNode.get("markers");
    markers.forEach((propertyValue: List<Map<string, any>>) => {
        const markerElement = xmlDocument.createElement(namespace + "marker");
        propertyValue.forEach((markerPropertyValue: Map<string, any>) => {
            const markerPropertyElement = xmlDocument.createElement(namespace + "markerProperty");
            setAttributeIfPresent(markerPropertyValue, "name", markerPropertyElement);
            setAttributeIfPresent(markerPropertyValue, "value", markerPropertyElement);
            setExpressionNodeFromMap(markerPropertyElement, xmlDocument, markerPropertyValue, "valueExpression");
            markerElement.appendChild(markerPropertyElement);
        });
        markerDatasetElement.appendChild(markerElement);
    });

    return markerDatasetElement;
}

const createDataset = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetElement = xmlDocument.createElement("dataset");
    
    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "incrementWhenExpression");

    const datasetRunNode: Map<string, any> = elementNode.get("datasetRun", null);
    if (datasetRunNode !== null && !datasetRunNode.isEmpty()){
        datasetElement.appendChild(createDatasetRun(datasetRunNode, xmlDocument));
    }

    setAttributeIfPresent(elementNode, "resetType", datasetElement);
    setAttributeIfPresent(elementNode, "resetGroup", datasetElement);
    setAttributeIfPresent(elementNode, "incrementType", datasetElement);
    setAttributeIfPresent(elementNode, "incrementGroup", datasetElement);
    
    return datasetElement;
}


const createEntity = (elementNode: Map<string, any>, xmlDocument: Document, namespace: string) : Element => {
    const entityElement = xmlDocument.createElement(namespace + "entity");

    const entityProperties = elementNode.get('entityProperties');
    if (entityProperties) {
        entityProperties.forEach((entityProperty: Map<string, any>) => {
            const entityPropertyElement = xmlDocument.createElement(namespace + 'entityProperty');
            setAttributeIfPresent(entityProperty, 'name', entityPropertyElement);
            const propertyExpression = entityProperty.get('propertyExpression');
            if (propertyExpression){
                setExpressionNode(entityPropertyElement, xmlDocument, propertyExpression, namespace + 'propertyExpression');   
            }
            entityElement.appendChild(entityPropertyElement);
        });
    }

    const idExpression = elementNode.get('idExpression');
    if (idExpression){
        setExpressionNode(entityElement, xmlDocument, idExpression, namespace + 'idExpression');   
    }

    const valueExpression = elementNode.get('valueExpression');
    if (valueExpression){
        setExpressionNode(entityElement, xmlDocument, valueExpression, namespace + 'valueExpression');   
    }

    const labelExpression = elementNode.get('labelExpression');
    if (labelExpression){
        setExpressionNode(entityElement, xmlDocument, labelExpression, namespace + 'labelExpression');   
    }

    const colorExpressio = elementNode.get('colorExpressio');
    if (colorExpressio){
        setExpressionNode(entityElement, xmlDocument, colorExpressio, namespace + 'colorExpressio');   
    }


    return entityElement;
}

const createFusionItemProperty = (name: string, itemPropertyNode: Map<string, any>, xmlDocument: Document) : Element => {
    const itemPropertyElement = xmlDocument.createElement("c:itemProperty");
    itemPropertyElement.setAttribute("name", name);
    setAttributeIfPresent(itemPropertyNode, "value", itemPropertyElement);
    setExpressionNodeFromMap(itemPropertyElement, xmlDocument, itemPropertyNode, "valueExpression");
    return itemPropertyElement;
}

const createShapeData = (elementNode: Map<string, any>, xmlDocument: Document, namespace: string) : Element => {
    const shapeDataElement = xmlDocument.createElement(namespace + "shapeData");
    const dataset = elementNode.get('dataset');
    if (dataset) {
        const datasetElement = createDataset(dataset, xmlDocument);
        shapeDataElement.appendChild(datasetElement);
    }

    const items = elementNode.get('items');
    if (items && items.size > 0) {
        items.forEach((itemProperties: Map<string, Map<string, any>>) => {
            const itemElement = xmlDocument.createElement("c:item");
            itemElement.setAttribute("xmlns:c", "http://jasperreports.sourceforge.net/jasperreports/components");
            itemElement.setAttribute("xsi:schemaLocation", "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd");
            itemProperties.forEach((itemProperty, name) => {
                itemElement.appendChild(createFusionItemProperty(name, itemProperty, xmlDocument));
            });
            shapeDataElement.appendChild(itemElement);
        });
    }
    return shapeDataElement;
}