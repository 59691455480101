import { Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { APDescriptor } from '../properties/ui/APDescriptor';

interface ILabelComponent {
    model: APDescriptor,
    label: string,
    tooltip: string,
    hoverActions?: JSX.Element[],
}


export const LabelComponent: React.FunctionComponent<ILabelComponent> = ({ model, label, tooltip, hoverActions }) => {
    const [isHover, setHover] = useState(false);
    let icons;
    if (isHover && hoverActions) {
        icons = hoverActions;
    }
    if (label.length > 30) {
        return <Tooltip title={tooltip} enterDelay={1000} enterNextDelay={1000}>
            <div className="tc-jsw-noselect" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {model.icon}
                <span className="jr-mTree-item-label-text mui">{label}</span>
                <div style={{ position: 'absolute', right: 0, top: 0, margin: 2, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                    {icons}
                </div>
            </div>
        </Tooltip>;
    }
    return <div className="tc-jsw-noselect" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onMouseEnter={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }}>
        {model.icon}
        <span className="jr-mTree-item-label-text mui">{label}</span>
        <div style={{ position: 'absolute', right: 0, top: 0, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
            {icons}
        </div>
    </div>;
}
