/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { APDescriptor } from "../../../ui/APDescriptor";
import { MHyperlinks } from "../../common/MHyperlinks";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { PInt } from "../../../ui/primitive/UPInteger";
import { MChartDataset } from "./MChartDataset";

export const FPieDataset = {
    minPercentage: new PInt({ id: 'minPercentage', label: 'Min Slice Percentage' }),
    maxCount: new PInt({ id: 'maxCount', label: 'Max Slices To Show' }),

    otherKeyExpression: new PExpression({ id: 'otherKeyExpression', label: 'Key' }),
    otherLabelExpression: new PExpression({ id: 'otherLabelExpression', label: 'Label' }),


};

export const MPieDataset: APDescriptor = {
    type: 'MPieDataset', id: 'pieDataset', path: 'pieDataset.dataset',
    layouts: [...MChartDataset.layouts, FPieDataset.minPercentage, FPieDataset.maxCount, new PAccordeon({
        id: 'pie.dataset.other', label: 'Catch All Slice', 
        layouts: [FPieDataset.otherKeyExpression, FPieDataset.otherLabelExpression, { ...MHyperlinks, path: 'otherSectionHyperlink' }]
    })]
};
