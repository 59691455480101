/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map, OrderedMap } from 'immutable';
import { initializeMapWithObject } from '../../../../sagas/report/document/documentFactory';
import { ElementTypes } from '../../../../sagas/report/document/elementTypes';
import { addAttributeToMap, addChildToParent2, createBox, createDatasetRun, createReportElement, generatePath, getChildNodeByName, getNamespace, getPropertyValue, IObjectCounter, readCDataText } from './JrxmlHelpers';
import { parseNode } from './JrxmlReader';

// cell types
const TABLE_GROUP_CELL_NAME = 'TABLE_GROUP_CELL';
const TABLE_CELL_NAME = 'TABLE_CELL';
const TABLE_NO_DATA_CELL_NAME = 'TABLE_NO_DATA_CELL_NAME';
const TABLE_NO_DATA_CELL_PLACEHOLDER = 'TABLE_NO_DATA_CELL_PLACEHOLDER';
const TABLE_GROUP_COLUMN_NAME = 'TABLE_GROUP_COLUMN';

// section
const TABLE_HEADER_NAME = 'TABLE_HEADER';
const TABLE_FOOTER_NAME = 'TABLE_FOOTER';
const TABLE_GROUP_HEADER_NAME = 'TABLE_GROUP_HEADER';
const TABLE_GROUP_FOOTER_NAME = 'TABLE_GROUP_FOOTER';
const TABLE_COLUMN_HEADER_NAME = 'TABLE_COLUMN_HEADER';
const TABLE_COLUMN_FOOTER_NAME = 'TABLE_COLUMN_FOOTER';
const TABLE_DETAIL_NAME = 'TABLE_DETAIL';

export const TableTypes = {
    TABLE_GROUP_CELL_NAME,
    TABLE_CELL_NAME,
    TABLE_GROUP_COLUMN_NAME,
    TABLE_HEADER_NAME,
    TABLE_FOOTER_NAME,
    TABLE_GROUP_HEADER_NAME,
    TABLE_GROUP_FOOTER_NAME,
    TABLE_COLUMN_HEADER_NAME,
    TABLE_COLUMN_FOOTER_NAME,
    TABLE_DETAIL_NAME,
    TABLE_NO_DATA_CELL_NAME,
    TABLE_NO_DATA_CELL_PLACEHOLDER,
}

export const TABLE_NAMESPACE = "TABLE_XMLNS_NAMESPACE";

export const isTable = (componentElement: Element): boolean => {
    const childList = componentElement.childNodes;
    let found = false;
    for (let i = 0; i < childList.length && !found; i++) {
        const child = childList[i];
        const nodeName = child.nodeName;
        if (child.nodeType === Node.ELEMENT_NODE && nodeName !== "reportElement") {
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : baseNamespace;
            found = nodeName === namespace + "table";
        }
    }
    return found;
}

export const createTableElement = (componentElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    const reportElementNode: Element = getChildNodeByName(componentElement, "reportElement") as Element;
    let table: Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.TABLE, objectCounter);
    table = table.set("elementIds", List<string>());
    let newDocument = document;
    componentElement.childNodes.forEach(child => {
        if (child.nodeType === Node.ELEMENT_NODE && child.nodeName !== "reportElement") {
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : "";
            const tableElement = getChildNodeByName(componentElement, namespace + "table") as Element;
            if (tableElement !== null) {
                table = table.set(TABLE_NAMESPACE, baseNamespace);
                table = addAttributeToMap(tableElement, baseNamespace.length > 0 ? "xmlns:" + baseNamespace : "xmlns", table);
                table = addAttributeToMap(tableElement, "xsi:schemaLocation", table);
                table = addAttributeToMap(tableElement, "whenNoDataType", table);

                // check for the dataset run
                const datasetRunNode = getChildNodeByName(tableElement, "datasetRun");
                if (datasetRunNode !== null) {
                    table = table.set("datasetRun", createDatasetRun(datasetRunNode as Element, objectCounter));
                }

                newDocument = addChildToParent2(parentElement, newDocument, table.get("id"));
                newDocument = newDocument.setIn(["elements", table.get("id")], table);
                newDocument = createTableSections(table, newDocument, objectCounter);
                table = newDocument.getIn(["elements", table.get("id")]) as Map<string, any>;
                const tableId = table.get("id");
                tableElement.childNodes.forEach(element => {
                    const elementName = element.nodeName;
                    if (elementName === namespace + "column") {
                        newDocument = createTableColumn(element as Element, tableId, [], newDocument, objectCounter, namespace);
                    } else if (elementName === namespace + "columnGroup") {
                        newDocument = createTableColumnGroup(element as Element, tableId, [], newDocument, objectCounter, namespace);
                    } else if (elementName === namespace + "noData") {
                        const cellInfo = createNoDataCell(table, newDocument, element as Element, objectCounter);
                        newDocument = cellInfo.document;
                        const cellId = cellInfo.newCellId;
                        newDocument = createCellContent(element as Element, cellId, newDocument, objectCounter);
                    }
                });

                // delete empty sections
                /* table.get("elementIds").forEach((elementId: string) => {
                    const element = newDocument.getIn(["elements", elementId]);
                    if (isBrenchEmpty(element, newDocument)){
                        newDocument = cutBrench(element, newDocument);
                    }
                }); */

                // compute the height of each section
                (newDocument.getIn(["elements", tableId, "elementIds"]) as List<string>).forEach((elementId: string) => {
                    const element = newDocument.getIn(["elements", elementId]) as Map<string, any>;
                    const type = element.get('type');
                    if (type !== TableTypes.TABLE_NO_DATA_CELL_NAME) {
                        const sectionWidth = calculateSectionWidth(element, newDocument);
                        const sectionHeight = calculateBrenchHeight(element, newDocument);
                        newDocument = newDocument.setIn(["elements", elementId, "height"], sectionHeight);
                        newDocument = newDocument.setIn(["elements", elementId, "width"], sectionWidth);
                    }

                });

                // compute the position of each cell
                newDocument = calculateCellPositions(getTable(tableId, newDocument), newDocument);

                //all section created, create the section properties
                tableElement.childNodes.forEach(element => {
                    const elementName = element.nodeName;
                    if (elementName === namespace + "tableHeader") {
                        const tableHeaderNodeId = getTableSection(newDocument, tableId, TableTypes.TABLE_HEADER_NAME);
                        newDocument = createSectionProperty(newDocument, tableHeaderNodeId, element as Element, namespace);
                    } else if (elementName === namespace + "tableFooter") {
                        const tableHeaderNodeId = getTableSection(newDocument, tableId, TableTypes.TABLE_FOOTER_NAME);
                        newDocument = createSectionProperty(newDocument, tableHeaderNodeId, element as Element, namespace);
                    } else if (elementName === namespace + "columnHeader") {
                        const tableHeaderNodeId = getTableSection(newDocument, tableId, TableTypes.TABLE_COLUMN_HEADER_NAME);
                        newDocument = createSectionProperty(newDocument, tableHeaderNodeId, element as Element, namespace);
                    } else if (elementName === namespace + "columnFooter") {
                        const tableHeaderNodeId = getTableSection(newDocument, tableId, TableTypes.TABLE_COLUMN_FOOTER_NAME);
                        newDocument = createSectionProperty(newDocument, tableHeaderNodeId, element as Element, namespace);
                    } else if (elementName === namespace + "groupHeader") {
                        const tableHeaderNodeId = getTableSection(newDocument, tableId, TableTypes.TABLE_GROUP_HEADER_NAME, (element as Element).getAttribute('groupName'));
                        newDocument = createSectionProperty(newDocument, tableHeaderNodeId, element as Element, namespace);
                    } else if (elementName === namespace + "groupFooter") {
                        const tableHeaderNodeId = getTableSection(newDocument, tableId, TableTypes.TABLE_GROUP_FOOTER_NAME, (element as Element).getAttribute('groupName'));
                        newDocument = createSectionProperty(newDocument, tableHeaderNodeId, element as Element, namespace);
                    } else if (elementName === namespace + "detail") {
                        const tableHeaderNodeId = getTableSection(newDocument, tableId, TableTypes.TABLE_DETAIL_NAME);
                        newDocument = createSectionProperty(newDocument, tableHeaderNodeId, element as Element, namespace);
                    }
                });
            }
        }
    });
    return newDocument;
}

const createSectionProperty = (document: Map<string, any>, sectionId: string, sectionElement: Element, namespace: string): Map<string, any> => {
    let newDocument = document;
    if (sectionId && sectionElement) {
        if (sectionElement.getAttribute('groupName')) {
            //iti is a group
            const rowElement = getChildNodeByName(sectionElement, namespace + 'row') as Element;
            newDocument = createSectionProperty(newDocument, sectionId, rowElement, namespace);
        } else {
            const splitType = sectionElement.getAttribute('splitType');
            let printWhenExpression;
            const expressionNode = getChildNodeByName(sectionElement, 'printWhenExpression');
            if (expressionNode) {
                printWhenExpression = readCDataText(expressionNode);
            }
            if (splitType || printWhenExpression) {
                if (splitType) {
                    newDocument = newDocument.setIn(['elements', sectionId, 'splitType'], splitType);
                }
                if (printWhenExpression) {
                    newDocument = newDocument.setIn(['elements', sectionId, 'printWhenExpression'], printWhenExpression);
                }
            }
        }
    }
    return newDocument;
}

const getTableSectionY = (model: Map<string, any>, tableSection: Map<string, any>, table: Map<string, any>): number => {
    let result = 0;

    const type = tableSection.get("type");
    const sections: List<string> = table.get("elementIds");
    let groupsHeadersHeight = 0;
    let groupsFooterHeight = 0;
    let tableHeaderHeight = 0;
    let columnHeaderHeight = 0;
    let columnFooterHeight = 0;
    let detailHeight = 0;
    for (let i = 0; i < sections.size; i++) {
        const section = model.getIn(['elements', sections.get(i)]) as Map<string, any>;
        const sectionType = section.get("type");
        if (sectionType === TableTypes.TABLE_GROUP_HEADER_NAME) {
            groupsHeadersHeight += section.get("height");
        } else if (sectionType === TableTypes.TABLE_GROUP_FOOTER_NAME) {
            groupsFooterHeight += section.get("height");
        } else if (sectionType === TableTypes.TABLE_HEADER_NAME) {
            tableHeaderHeight = section.get("height");
        } else if (sectionType === TableTypes.TABLE_COLUMN_HEADER_NAME) {
            columnHeaderHeight = section.get("height");
        } else if (sectionType === TableTypes.TABLE_COLUMN_FOOTER_NAME) {
            columnFooterHeight = section.get("height");
        } else if (sectionType === TableTypes.TABLE_DETAIL_NAME) {
            detailHeight = section.get("height");
        }
    }
    if (type === TableTypes.TABLE_COLUMN_HEADER_NAME) {
        result = tableHeaderHeight;
    } else if (type === TableTypes.TABLE_GROUP_HEADER_NAME) {

        result = tableHeaderHeight + columnHeaderHeight;

        const groupName = tableSection.get("groupName");
        let found = false;
        for (let i = 0; i < sections.size && !found; i++) {
            const section = model.getIn(['elements', sections.get(i)]) as Map<string, any>;
            if (section.get("type") === TableTypes.TABLE_GROUP_HEADER_NAME) {
                const sectionGroupName = section.get("groupName");
                if (sectionGroupName === groupName) {
                    found = true;
                } else {
                    result += section.get("height");
                }
            }
        }
    } else if (type === TableTypes.TABLE_DETAIL_NAME) {
        result = tableHeaderHeight + columnHeaderHeight + groupsHeadersHeight;
    } else if (type === TableTypes.TABLE_GROUP_FOOTER_NAME) {

        result = tableHeaderHeight + columnHeaderHeight + groupsHeadersHeight + detailHeight;

        const groupName = tableSection.get("groupName");
        let found = false;
        for (let i = sections.size - 1; i >= 0 && !found; i--) {
            const section = model.getIn(['elements', sections.get(i)]) as Map<string, any>;
            if (section.get("type") === TableTypes.TABLE_GROUP_FOOTER_NAME) {
                const sectionGroupName = section.get("groupName");
                if (sectionGroupName === groupName) {
                    found = true;
                } else {
                    result += section.get("height");
                }
            }
        }
    } else if (type === TableTypes.TABLE_COLUMN_FOOTER_NAME) {
        result = tableHeaderHeight + columnHeaderHeight + groupsHeadersHeight + detailHeight + groupsFooterHeight;
    } else if (type === TableTypes.TABLE_FOOTER_NAME) {
        result = tableHeaderHeight + columnHeaderHeight + groupsHeadersHeight + detailHeight + groupsFooterHeight + columnFooterHeight;
    }

    return result;
}


const calculateCellPositions = (table: Map<string, any>, document: Map<string, any>): Map<string, any> => {
    const sections: List<string> = table.get("elementIds");
    let newDocument = document;
    sections.forEach(sectionId => {
        const section: Map<string, any> = document.getIn(["elements", sectionId]) as Map<string, any>;
        const type = section.get('type');
        if (type !== TableTypes.TABLE_NO_DATA_CELL_NAME) {
            newDocument = recCalculateCellPositions(section, 0, 0, newDocument).newDocument;
            const sectionY = getTableSectionY(newDocument, section, table);
            newDocument = newDocument.setIn(['elements', sectionId, 'y'], sectionY);
            newDocument = newDocument.setIn(['elements', sectionId, 'x'], 0);
        }
    });
    return newDocument;
}

const recCalculateCellPositions = (currentParent: Map<string, any>, currentX: number, currentY: number, document: Map<string, any>): { newDocument: Map<string, any>, newX: number } => {
    const childrenIds: List<string> = currentParent.get("elementIds");
    let newDocument = document;
    let newX = currentX;
    childrenIds.forEach(childId => {
        const child: Map<string, any> = document.getIn(["elements", childId]) as Map<string, any>;
        if (child.get("type") === TABLE_CELL_NAME) {
            newDocument = newDocument.setIn(["elements", childId, "x"], newX);
            newDocument = newDocument.setIn(["elements", childId, "y"], currentY);
            newX += child.get("width");
        } else if (child.get("type") === TABLE_GROUP_COLUMN_NAME) {
            const groupChildren: List<string> = child.get("elementIds");
            // update the start y with the group height
            const groupCellId = groupChildren.find((value: string) => {
                const groupChild: Map<string, any> = document.getIn(["elements", value]) as Map<string, any>;
                return (groupChild.get("type") === TABLE_GROUP_CELL_NAME);
            });
            let groupHeighOffset = 0;
            if (groupCellId !== undefined) {
                newDocument = newDocument.setIn(["elements", groupCellId, "x"], newX);
                newDocument = newDocument.setIn(["elements", groupCellId, "y"], currentY);
                groupHeighOffset = (document.getIn(["elements", groupCellId]) as Map<string, any>).get("height");
            }
            const recursionResult = recCalculateCellPositions(child, newX, currentY + groupHeighOffset, newDocument);
            newDocument = recursionResult.newDocument;
            newX = recursionResult.newX;
        }
    });
    return { newDocument, newX };
}

const calculateBrenchHeight = (brenchNode: Map<string, any>, document: Map<string, any>): number => {
    const children: List<string> = brenchNode.get("elementIds");

    for (let i = 0; i < children.size; i++) {
        const child: Map<string, any> = document.getIn(["elements", children.get(i)]) as Map<string, any>;
        if (child.get("type") === TABLE_CELL_NAME && child.get("isDefined") === true) {
            return child.get("height");
        } else {
            if (child.get("type") === TABLE_GROUP_COLUMN_NAME) {
                let groupCellHeight = 0;
                const groupChildren: List<string> = child.get("elementIds");
                const groupCellId = groupChildren.find((value: string) => {
                    const groupChild: Map<string, any> = document.getIn(["elements", value]) as Map<string, any>;
                    return (groupChild.get("type") === TABLE_GROUP_CELL_NAME);
                });
                if (groupCellId !== undefined) {
                    groupCellHeight = (document.getIn(["elements", groupCellId]) as Map<string, any>).get("height");
                }
                return groupCellHeight + calculateBrenchHeight(child, document);
            }
        }
    }

    return 0;
}

const calculateSectionWidth = (sectionNode: Map<string, any>, document: Map<string, any>): number => {
    const children: List<string> = sectionNode.get("elementIds");
    let currentWidth = 0;
    for (let i = 0; i < children.size; i++) {
        const child: Map<string, any> = document.getIn(["elements", children.get(i)]) as Map<string, any>;
        if (child.get("type") === TABLE_CELL_NAME && child.get("isDefined") === true) {
            currentWidth += child.get("width");
        } else {
            if (child.get("type") === TABLE_GROUP_COLUMN_NAME) {
                const groupChildren: List<string> = child.get("elementIds");
                const groupCellId = groupChildren.find((value: string) => {
                    const groupChild: Map<string, any> = document.getIn(["elements", value]) as Map<string, any>;
                    return (groupChild.get("type") === TABLE_GROUP_CELL_NAME);
                });
                if (groupCellId !== undefined) {
                    //the width of the group is the same of the column
                    currentWidth += (document.getIn(["elements", groupCellId]) as Map<string, any>).get("width");
                } else {
                    //no group cell, check the cells inside the group
                    currentWidth += calculateSectionWidth(child, document);
                }
            }
        }
    }

    return currentWidth;
}

export const computeTableRealSize = (table: Map<string, any>, document: Map<string, any>): { width: number, height: number } => {
    let height = 0;
    const sections: List<string> = table.get("elementIds");
    let width = 0;
    if (sections) {
        sections.forEach(sectionId => {
            const section: Map<string, any> = document.getIn(["elements", sectionId]) as Map<string, any>;
            height += section.get('height');
            width = Math.max(width, section.get('width'));
        });
    }
    return { width: Math.max(width, table.get('width')), height: Math.max(height, table.get('height')) };
}

/* 
const getColumnIndex = (currentElement : Map<string, any>, document: Map<string, any>) : number => {
    const path : string = currentElement.get("path");
    const currentNodeId = currentElement.get("id");
    const parent : Map<string, any> = document.getIn(path.split('/'));
    const parentType = parent.get("type");
    if (parentType === ElementTypes.TABLE){
        return 0;
    }
    const siblingsId : List<string> = parent.get("elementIds");
    let currentNodeFound = false;
    let result = 0;
    for (let i = 0; i < siblingsId.size && !currentNodeFound; i++) {
        const siblingId = siblingsId.get(i);
        if (siblingId === currentNodeId){
            currentNodeFound = true;
        } else {
            result += getColumnsInsideNode(document.getIn(["elements", siblingId]), document);
        }
    }
    result += getColumnIndex(parent, document);
    return result;
}

const getColumnsInsideNode = (currentElement: Map<string, any>, document: Map<string, any>) : number => {
    const type = currentElement.get("type");
    if (type === TABLE_CELL_NAME){
        return 1;
    } else if (type === TABLE_GROUP_COLUMN_NAME){
        // count the group column
        let result = 1;
        const children : List<string> = currentElement.get("elementIds");
        children.forEach(childId => {
            result += getColumnsInsideNode(document.getIn(["elements", childId]), document);
        });
        return result;
    }
    return 0;
} */

const createTableSection = (tableElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter, sectionType: string): Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(tableElement);
    const tableSection = initializeMapWithObject({
        id: objectCounter.uniqueID("ele-"),
        path: pathValue,
        type: sectionType,
        selected: false,
        highlighted: false,
        width: 0,
        height: 0,
        elementIds: List<string>()
    });
    let newDocument = document;

    newDocument = addChildToParent2(tableElement, newDocument, tableSection.get("id"));
    newDocument = newDocument.setIn(["elements", tableSection.get("id")], tableSection);
    return newDocument;
}


const createTableSections = (tableElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let newDocument = document;

    newDocument = createTableSection(tableElement, newDocument, objectCounter, TableTypes.TABLE_HEADER_NAME);
    newDocument = createTableSection(tableElement, newDocument, objectCounter, TableTypes.TABLE_COLUMN_HEADER_NAME);
    newDocument = createTableSection(tableElement, newDocument, objectCounter, TableTypes.TABLE_DETAIL_NAME);
    newDocument = createTableSection(tableElement, newDocument, objectCounter, TableTypes.TABLE_COLUMN_FOOTER_NAME);
    newDocument = createTableSection(tableElement, newDocument, objectCounter, TableTypes.TABLE_FOOTER_NAME);

    return newDocument;
}

const getTableSection = (model: Map<string, any>, tableElementId: string, sectionType: string, groupName?: string): string => {
    const tableElement = model.getIn(['elements', tableElementId]) as Map<string, any>;
    const tableChildren = tableElement.get('elementIds', List<string>()) as List<string>;
    let result;
    for (let i = 0; i < tableChildren.size && !result; i++) {
        const id = tableChildren.get(i);
        const childElement = model.getIn(['elements', id]) as Map<string, any>;
        if (childElement && childElement.get('type') === sectionType && childElement.get('groupName') === groupName) {
            result = id;
        }
    }
    return result;
}

const getParentElement = (tableElement: Map<string, any>, path: number[], document: Map<string, any>): Map<string, any> => {
    let currentElement = tableElement;
    path.forEach((element: number) => {
        const childId = currentElement.get("elementIds").get(element);
        currentElement = (document.getIn(["elements", childId]) as Map<string, any>);
    });
    return currentElement;
}

const createColumnGroup = (parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter): { document: Map<string, any>, newGroupId: string } => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    const columnGroupId = objectCounter.uniqueID("ele-");
    const columnGroup = initializeMapWithObject({
        id: columnGroupId,
        path: pathValue,
        type: TABLE_GROUP_COLUMN_NAME,
        selected: false,
        highlighted: false,
        width: 0,
        height: 0,
        isDefined: false,
        properties: OrderedMap<string, string | null>(),
        propertiesExpression: OrderedMap<string, string>(),
        elementIds: List<string>()
    });
    let newDocument = addChildToParent2(parentElement, document, columnGroupId);
    newDocument = newDocument.setIn(["elements", columnGroupId], columnGroup);
    return { document: newDocument, newGroupId: columnGroupId };
}

const createColumnCell = (parentElement: Map<string, any>, document: Map<string, any>, widthValue: number, objectCounter: IObjectCounter): { document: Map<string, any>, newCellId: string } => {
    // here we mix the properties from the component element and the child table
    const cellId = objectCounter.uniqueID("ele-");
    const pathValue = generatePath(parentElement);
    const columnCell = initializeMapWithObject({
        id: cellId,
        path: pathValue,
        type: TABLE_CELL_NAME,
        selected: false,
        highlighted: false,
        width: widthValue,
        height: 0,
        isDefined: false,
        properties: OrderedMap<string, string | null>(),
        elementIds: List<string>()
    });
    let newDocument = addChildToParent2(parentElement, document, cellId);
    newDocument = newDocument.setIn(["elements", cellId], columnCell);
    return { document: newDocument, newCellId: cellId };
}

const createNoDataCell = (tableElement: Map<string, any>, document: Map<string, any>, noDataCellElement: Element, objectCounter: IObjectCounter): { document: Map<string, any>, newCellId: string } => {
    // here we mix the properties from the component element and the child table
    const cellId = objectCounter.uniqueID("ele-");
    const hegith = parseInt(noDataCellElement.getAttribute('height'), 10);
    const width = tableElement.get('width');
    const pathValue = generatePath(tableElement);
    const columnCell = initializeMapWithObject({
        id: cellId,
        path: pathValue,
        type: TABLE_NO_DATA_CELL_NAME,
        selected: false,
        highlighted: false,
        width: width,
        height: hegith,
        isDefined: true,
        properties: OrderedMap<string, string | null>(),
        elementIds: List<string>()
    });
    let newDocument = addChildToParent2(tableElement, document, cellId);
    newDocument = newDocument.setIn(["elements", cellId], columnCell);
    return { document: newDocument, newCellId: cellId };
}

const createColumnGroupCell = (parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    const columnGroup = initializeMapWithObject({
        id: objectCounter.uniqueID("ele-"),
        path: pathValue,
        type: TABLE_GROUP_CELL_NAME,
        selected: false,
        highlighted: false,
        width: 0,
        height: 0,
        isDefined: false,
        properties: OrderedMap<string, string | null>(),
        elementIds: List<string>()
    });
    let newDocument = addChildToParent2(parentElement, document, columnGroup.get("id"));
    newDocument = newDocument.setIn(["elements", columnGroup.get("id")], columnGroup);
    return newDocument;
}


const createCellContent = (tableCellElement: Element, cellElementId: string, document: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let newDocument = document;

    const style = tableCellElement.getAttribute("style");
    if (style !== null) {
        newDocument = newDocument.setIn(["elements", cellElementId, "style"], style);
    }
    const rowSpan = tableCellElement.getAttribute("rowSpan");
    if (rowSpan !== null) {
        newDocument = newDocument.setIn(["elements", cellElementId, "rowSpan"], parseInt(rowSpan, 10));
    }

    tableCellElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "property") {
            const childElement: Element = element as Element;
            const attName = childElement.getAttribute("name");
            const attValue = getPropertyValue(childElement);
            newDocument = newDocument.setIn(["elements", cellElementId, "properties", attName], attValue);
        } else if (elementName === "box") {
            const box: Map<string, any> = createBox(element as Element);
            newDocument = newDocument.setIn(["elements", cellElementId, "box"], box);
        } else {
            newDocument = parseNode(element, tableCellElement, (newDocument.getIn(["elements", cellElementId]) as Map<string, any>), newDocument, objectCounter);
        }
    });

    return newDocument;
}


const getTable = (tableId: string, document: Map<string, any>): Map<string, any> => {
    return document.getIn(["elements", tableId]) as Map<string, any>;
}

const createGroupCell = (groupNode: Element, columnWidth: number, tableId: string, parentPath: number[], document: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let newDocument = document;
    const groupElement = getParentElement(getTable(tableId, newDocument), parentPath, newDocument);
    newDocument = createColumnGroupCell(groupElement, newDocument, objectCounter);
    const height = parseInt((groupNode as Element).getAttribute("height"), 10);
    const cellId = (newDocument.getIn(["elements", groupElement.get("id"), "elementIds"]) as List<string>).last();
    newDocument = newDocument.setIn(["elements", cellId, "width"], columnWidth);
    newDocument = newDocument.setIn(["elements", cellId, "height"], height);
    newDocument = newDocument.setIn(["elements", cellId, "isDefined"], true);
    newDocument = createCellContent(groupNode as Element, cellId, newDocument, objectCounter);
    return newDocument;
}

const copyCellNodes = (source: Map<string, any>, target: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let newDocument = document;
    const sourceChildrenIds: List<string> = source.get("elementIds");
    sourceChildrenIds.forEach(sourceChildId => {
        const sourceChild = newDocument.getIn(["elements", sourceChildId]) as Map<string, any>;
        if (sourceChild.get("type") === TABLE_CELL_NAME) {
            newDocument = createColumnCell(target, newDocument, sourceChild.get("width"), objectCounter).document;
        } else if (sourceChild.get("type") === TABLE_GROUP_COLUMN_NAME) {
            newDocument = createColumnGroup(target, newDocument, objectCounter).document;
            const targetNewGroupId = (newDocument.getIn(["elements", target.get("id"), "elementIds"]) as List<string>).last();
            newDocument = copyCellNodes(sourceChild, (newDocument.getIn(["elements", targetNewGroupId]) as Map<string, any>), newDocument, objectCounter);
        }
    });
    return newDocument;
}

const createGroupSection = (groupName: string, tableId: string, document: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let newDocument = document;

    // use the table header to replicate the structure
    const tableHeaderId = newDocument.getIn(["elements", tableId, "elementIds", 0]);
    const tableHeader = newDocument.getIn(["elements", tableHeaderId]) as Map<string, any>;

    newDocument = createTableSection(newDocument.getIn(["elements", tableId]) as Map<string, any>, newDocument, objectCounter, TableTypes.TABLE_GROUP_HEADER_NAME);
    const groupHeaderSectionId = (newDocument.getIn(["elements", tableId, "elementIds"]) as List<string>).last();
    newDocument = newDocument.setIn(["elements", groupHeaderSectionId, "groupName"], groupName);
    const groupHeaderSection = newDocument.getIn(["elements", groupHeaderSectionId]) as Map<string, any>;
    newDocument = copyCellNodes(tableHeader, groupHeaderSection, newDocument, objectCounter);


    newDocument = createTableSection(newDocument.getIn(["elements", tableId]) as Map<string, any>, newDocument, objectCounter, TableTypes.TABLE_GROUP_FOOTER_NAME);
    const groupFooterSectionId = (newDocument.getIn(["elements", tableId, "elementIds"]) as List<string>).last();
    newDocument = newDocument.setIn(["elements", groupFooterSectionId, "groupName"], groupName);
    const groupFooterSection = newDocument.getIn(["elements", groupFooterSectionId]) as Map<string, any>;
    newDocument = copyCellNodes(tableHeader, groupFooterSection, newDocument, objectCounter);

    return newDocument;
}

const findGroupIndex = (groupName: string, tableId: string, document: Map<string, any>, isHeader: boolean): number => {
    // create the cell on the groups
    const tableSections: List<string> = document.getIn(["elements", tableId, "elementIds"]) as List<string>;
    for (let i = 5; i < tableSections.size; i++) {
        const child = document.getIn(["elements", tableSections.get(i)]) as Map<string, any>;
        const type = child.get("type");
        if (isHeader && type === TableTypes.TABLE_GROUP_HEADER_NAME && child.get("groupName") === groupName) {
            return i;
        }
        if (!isHeader && type === TableTypes.TABLE_GROUP_FOOTER_NAME && child.get("groupName") === groupName) {
            return i;
        }
    }
    return -1;
}

const createTableColumnGroup = (columnGroupElement: Element, tableId: string, currentPath: number[], document: Map<string, any>, objectCounter: IObjectCounter, prefix: string): Map<string, any> => {

    let newDocument = document;

    // node for the table header
    const tableHeaderGroup = createColumnGroup(getParentElement(getTable(tableId, newDocument), [0].concat(currentPath), newDocument), newDocument, objectCounter);
    newDocument = tableHeaderGroup.document;
    const tableHeaderColumnGroupId = tableHeaderGroup.newGroupId;
    const uuid = columnGroupElement.getAttribute("uuid");
    if (uuid !== null) {
        newDocument = newDocument.setIn(["elements", tableHeaderColumnGroupId, "uuid"], uuid);
    }

    // node for the column header
    newDocument = createColumnGroup(getParentElement(getTable(tableId, newDocument), [1].concat(currentPath), newDocument), newDocument, objectCounter).document;

    // node for the detail
    newDocument = createColumnGroup(getParentElement(getTable(tableId, newDocument), [2].concat(currentPath), newDocument), newDocument, objectCounter).document;

    // node for the column footer
    newDocument = createColumnGroup(getParentElement(getTable(tableId, newDocument), [3].concat(currentPath), newDocument), newDocument, objectCounter).document;

    // node for the table footer
    newDocument = createColumnGroup(getParentElement(getTable(tableId, newDocument), [4].concat(currentPath), newDocument), newDocument, objectCounter).document;

    // create the cell on the groups
    const tableSections: List<string> = getTable(tableId, newDocument).get("elementIds");
    for (let i = 5; i < tableSections.size; i++) {
        newDocument = createColumnGroup(getParentElement(getTable(tableId, newDocument), [i].concat(currentPath), newDocument), newDocument, objectCounter).document;
    }

    const parentID = getParentElement(getTable(tableId, newDocument), [0].concat(currentPath), newDocument).get("id");
    // the size is the same in all the grouos
    const groupPosition = (newDocument.getIn(["elements", parentID, "elementIds"]) as List<string>).size - 1;

    const columnWidth = parseInt(columnGroupElement.getAttribute("width"), 10);

    columnGroupElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === prefix + "column") {
            newDocument = createTableColumn(element as Element, tableId, currentPath.concat(groupPosition), newDocument, objectCounter, prefix);
        } else if (elementName === prefix + "columnGroup") {
            const newPath = currentPath.concat(groupPosition);
            newDocument = createTableColumnGroup(element as Element, tableId, newPath, newDocument, objectCounter, prefix);
        } else if (elementName === "property") {
            // store the properties in the table header
            const childElement: Element = element as Element;
            const attName = childElement.getAttribute("name");
            const attValue = getPropertyValue(childElement);
            let propertiesMap = newDocument.getIn(["elements", tableHeaderColumnGroupId, "properties"], OrderedMap<string, Map<string, any>>()) as OrderedMap<string, Map<string, any>>;
            let oldValue = propertiesMap.get(attName, Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('value', attValue);
            propertiesMap = propertiesMap.set(attName, oldValue);
            newDocument = newDocument.setIn(["elements", tableHeaderColumnGroupId, "properties"], propertiesMap);
        } else if (elementName === "propertyExpression") {
            // store the properties in the table header
            const childElement: Element = element as Element;
            const attName = childElement.getAttribute("name");
            const attValue = readCDataText(childElement);
            let propertiesMap = newDocument.getIn(["elements", tableHeaderColumnGroupId, "properties"], OrderedMap<string, Map<string, any>>()) as OrderedMap<string, Map<string, any>>;
            let oldValue = propertiesMap.get(attName, Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('valueExpression', attValue);
            propertiesMap = propertiesMap.set(attName, oldValue);
            newDocument = newDocument.setIn(["elements", tableHeaderColumnGroupId, "properties"], propertiesMap);
        } else if (elementName === "printWhenExpression") {
            // store the properties in the table header
            const childElement: Element = element as Element;
            const attValue = readCDataText(childElement);
            newDocument = newDocument.setIn(["elements", tableHeaderColumnGroupId, "printWhenExpression"], attValue);
        }
    });

    columnGroupElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === prefix + "groupHeader") {
            const cell = getChildNodeByName(element as Element, prefix + "cell");
            const groupName = (element as Element).getAttribute("groupName");

            let groupIndex = findGroupIndex(groupName, tableId, newDocument, true);
            if (groupIndex === -1) {
                newDocument = createGroupSection(groupName, tableId, newDocument, objectCounter);
                groupIndex = findGroupIndex(groupName, tableId, newDocument, true);
            }

            const path = [groupIndex].concat(currentPath).concat(groupPosition);
            newDocument = createGroupCell(cell as Element, columnWidth, tableId, path, newDocument, objectCounter);
        } else if (elementName === prefix + "groupFooter") {
            const cell = getChildNodeByName(element as Element, prefix + "cell");
            const groupName = (element as Element).getAttribute("groupName");

            let groupIndex = findGroupIndex(groupName, tableId, newDocument, false);
            if (groupIndex === -1) {
                newDocument = createGroupSection(groupName, tableId, newDocument, objectCounter);
                groupIndex = findGroupIndex(groupName, tableId, newDocument, false);
            }

            const path = [groupIndex].concat(currentPath).concat(groupPosition);
            newDocument = createGroupCell(cell as Element, columnWidth, tableId, path, newDocument, objectCounter);
        }
    });


    // create the cell for this group
    const groupTableHeader = getChildNodeByName(columnGroupElement, prefix + "tableHeader");
    if (groupTableHeader !== null) {
        const path = [0].concat(currentPath).concat(groupPosition);
        newDocument = createGroupCell(groupTableHeader as Element, columnWidth, tableId, path, newDocument, objectCounter);
    }

    const groupColumnHeader = getChildNodeByName(columnGroupElement, prefix + "columnHeader");
    if (groupColumnHeader !== null) {
        const path = [1].concat(currentPath).concat(groupPosition);
        newDocument = createGroupCell(groupColumnHeader as Element, columnWidth, tableId, path, newDocument, objectCounter);
    }

    const groupColumnFooter = getChildNodeByName(columnGroupElement, prefix + "columnFooter");
    if (groupColumnFooter !== null) {
        const path = [3].concat(currentPath).concat(groupPosition);
        newDocument = createGroupCell(groupColumnFooter as Element, columnWidth, tableId, path, newDocument, objectCounter);
    }

    const groupTableFooter = getChildNodeByName(columnGroupElement, prefix + "tableFooter");
    if (groupTableFooter !== null) {
        const path = [4].concat(currentPath).concat(groupPosition);
        newDocument = createGroupCell(groupTableFooter as Element, columnWidth, tableId, path, newDocument, objectCounter);
    }

    return newDocument;
}

const createTableColumn = (columnElement: Element, tableId: string, currentPath: number[], document: Map<string, any>, objectCounter: IObjectCounter, prefix: string): Map<string, any> => {
    let newDocument = document;
    const columnWidth = parseInt(columnElement.getAttribute("width"), 10);

    // node for the table header
    const tableHeaderCell = createColumnCell(getParentElement(getTable(tableId, newDocument), [0].concat(currentPath), newDocument), newDocument, columnWidth, objectCounter);
    newDocument = tableHeaderCell.document;
    const tableHeaderCellId = tableHeaderCell.newCellId;
    const uuid = columnElement.getAttribute("uuid");
    if (uuid !== null) {
        newDocument = newDocument.setIn(["elements", tableHeaderCellId, "uuid"], uuid);
    }

    // node for the column header
    const columnHeaderCell = createColumnCell(getParentElement(getTable(tableId, newDocument), [1].concat(currentPath), newDocument), newDocument, columnWidth, objectCounter);
    newDocument = columnHeaderCell.document;
    const columnHeaderCellId = columnHeaderCell.newCellId;

    // node for the detail
    const detailCell = createColumnCell(getParentElement(getTable(tableId, newDocument), [2].concat(currentPath), newDocument), newDocument, columnWidth, objectCounter);
    newDocument = detailCell.document;
    const detailCellId = detailCell.newCellId;

    // node for the column footer
    const columnFooterCell = createColumnCell(getParentElement(getTable(tableId, newDocument), [3].concat(currentPath), newDocument), newDocument, columnWidth, objectCounter);
    newDocument = columnFooterCell.document;
    const columnFooterCellId = columnFooterCell.newCellId;

    // node for the table footer
    const tableFooterCell = createColumnCell(getParentElement(getTable(tableId, newDocument), [4].concat(currentPath), newDocument), newDocument, columnWidth, objectCounter);
    newDocument = tableFooterCell.document;
    const tableFooterCellId = tableFooterCell.newCellId;

    // create the cell on the groups
    const tableSections: List<string> = getTable(tableId, newDocument).get("elementIds");
    for (let i = 5; i < tableSections.size; i++) {
        newDocument = createColumnCell(getParentElement(getTable(tableId, newDocument), [i].concat(currentPath), newDocument), newDocument, columnWidth, objectCounter).document;
    }

    columnElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === prefix + "tableHeader") {
            const height = parseInt((element as Element).getAttribute("height"), 10);
            newDocument = newDocument.setIn(["elements", tableHeaderCellId, "height"], height);
            newDocument = newDocument.setIn(["elements", tableHeaderCellId, "isDefined"], true);
            newDocument = createCellContent(element as Element, tableHeaderCellId, newDocument, objectCounter);
        } else if (elementName === prefix + "tableFooter") {
            const height = parseInt((element as Element).getAttribute("height"), 10);
            newDocument = newDocument.setIn(["elements", tableFooterCellId, "height"], height);
            newDocument = newDocument.setIn(["elements", tableFooterCellId, "isDefined"], true);
            newDocument = createCellContent(element as Element, tableFooterCellId, newDocument, objectCounter);
        } else if (elementName === prefix + "columnHeader") {
            const height = parseInt((element as Element).getAttribute("height"), 10);
            newDocument = newDocument.setIn(["elements", columnHeaderCellId, "height"], height);
            newDocument = newDocument.setIn(["elements", columnHeaderCellId, "isDefined"], true);
            newDocument = createCellContent(element as Element, columnHeaderCellId, newDocument, objectCounter);
        } else if (elementName === prefix + "columnFooter") {
            const height = parseInt((element as Element).getAttribute("height"), 10);
            newDocument = newDocument.setIn(["elements", columnFooterCellId, "height"], height);
            newDocument = newDocument.setIn(["elements", columnFooterCellId, "isDefined"], true);
            newDocument = createCellContent(element as Element, columnFooterCellId, newDocument, objectCounter);
        } else if (elementName === prefix + "detailCell") {
            const height = parseInt((element as Element).getAttribute("height"), 10);
            newDocument = newDocument.setIn(["elements", detailCellId, "height"], height);
            newDocument = newDocument.setIn(["elements", detailCellId, "isDefined"], true);
            newDocument = createCellContent(element as Element, detailCellId, newDocument, objectCounter);
        } else if (elementName === "property") {
            // store the column properties in the table header
            const childElement: Element = element as Element;
            const attName = childElement.getAttribute("name");
            const attValue = getPropertyValue(childElement);
            let propertiesMap = newDocument.getIn(["elements", tableHeaderCellId, "columnProperties"], OrderedMap<string, Map<string, any>>()) as OrderedMap<string, Map<string, any>>;
            let oldValue = propertiesMap.get(attName, Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('value', attValue);
            propertiesMap = propertiesMap.set(attName, oldValue);
            newDocument = newDocument.setIn(["elements", tableHeaderCellId, "columnProperties"], propertiesMap);
        } else if (elementName === "propertyExpression") {
            // store the column properties in the table header
            const childElement: Element = element as Element;
            const attName = childElement.getAttribute("name");
            const attValue = readCDataText(childElement);
            let propertiesMap = newDocument.getIn(["elements", tableHeaderCellId, "columnProperties"], OrderedMap<string, Map<string, any>>()) as OrderedMap<string, Map<string, any>>;
            let oldValue = propertiesMap.get(attName, Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('valueExpression', attValue);
            propertiesMap = propertiesMap.set(attName, oldValue);
            newDocument = newDocument.setIn(["elements", tableHeaderCellId, "columnProperties"], propertiesMap);
        } else if (elementName === "printWhenExpression") {
            // store the properties in the table header
            const childElement: Element = element as Element;
            const attValue = readCDataText(childElement);
            newDocument = newDocument.setIn(["elements", tableHeaderCellId, "printWhenExpression"], attValue);
        } else if (elementName === prefix + "groupHeader") {
            const cell = getChildNodeByName(element as Element, prefix + "cell");
            const groupName = (element as Element).getAttribute("groupName");

            let groupIndex = findGroupIndex(groupName, tableId, newDocument, true);
            if (groupIndex === -1) {
                newDocument = createGroupSection(groupName, tableId, newDocument, objectCounter);
                groupIndex = findGroupIndex(groupName, tableId, newDocument, true);
            }

            const height = parseInt((cell as Element).getAttribute("height"), 10);
            const parentID = getParentElement(getTable(tableId, newDocument), [groupIndex].concat(currentPath), newDocument).get("id");
            const cellId = (newDocument.getIn(["elements", parentID, "elementIds"]) as List<string>).last();
            newDocument = newDocument.setIn(["elements", cellId, "height"], height);
            newDocument = newDocument.setIn(["elements", cellId, "isDefined"], true);
            newDocument = createCellContent(cell as Element, cellId, newDocument, objectCounter);
        } else if (elementName === prefix + "groupFooter") {
            const cell = getChildNodeByName(element as Element, prefix + "cell");
            const groupName = (element as Element).getAttribute("groupName");

            let groupIndex = findGroupIndex(groupName, tableId, newDocument, false);
            if (groupIndex === -1) {
                newDocument = createGroupSection(groupName, tableId, newDocument, objectCounter);
                groupIndex = findGroupIndex(groupName, tableId, newDocument, false);
            }

            const height = parseInt((cell as Element).getAttribute("height"), 10);
            const parentID = getParentElement(newDocument.getIn(["elements", tableId]) as Map<string, any>, [groupIndex].concat(currentPath), newDocument).get("id");
            const cellId = (newDocument.getIn(["elements", parentID, "elementIds"]) as List<string>).last();
            newDocument = newDocument.setIn(["elements", cellId, "height"], height);
            newDocument = newDocument.setIn(["elements", cellId, "isDefined"], true);
            newDocument = createCellContent(cell as Element, cellId, newDocument, objectCounter);
        }
    });
    return newDocument;
}
