/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { AbstractTextualEditor, IAbstractTextualEditor } from './AbstractTextualEditor';
import InternalJRXMLEditor from './InternalJRXMLEditor';

export class JRXMLEditor extends React.Component<IAbstractTextualEditor> {

    private xmlEditorRef: React.RefObject<AbstractTextualEditor> = React.createRef<AbstractTextualEditor>();

    getEditorContent = () => {
        return this.xmlEditorRef.current?.getEditorContent();
    }

    render() {
        return <InternalJRXMLEditor ref={this.xmlEditorRef} {...this.props}/>;
    }
}