/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */



import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../reducers';
import { Map } from 'immutable';
import { resolveAttribute } from '../properties/types/common/StyleResolver';

interface ILineElement {
  id: string,
  element?: Map<string, any>,
  model?: Map<string, any>,
  zoom?: number,
  availableWidth: number,
  availableHeight: number,
}

const isOblique = (width: number, height: number) => {
  return width > 1 && height > 1;
}

const visualProperties = ['direction'];

const inheritedProperties = ['pen.lineColor', 'pen.lineWidth', 'pen.lineStyle', 'forecolor'];


class LineElement extends React.Component<ILineElement> {

  public shouldComponentUpdate = (nextProps: Readonly<ILineElement>) => {
    if (this.props.availableHeight !== nextProps.availableHeight || this.props.availableWidth !== nextProps.availableWidth){
      return true;
    }

    if (this.props.zoom !== nextProps.zoom){
      return true;
    }
    for (const propName of visualProperties) {
      if (this.props.element.get(propName) !== nextProps.element.get(propName)) {
        return true;
      }
    }

    for (const propName of inheritedProperties) {
      if (resolveAttribute(this.props.model, this.props.element, propName) !== resolveAttribute(nextProps.model, nextProps.element, propName)) {
        return true;
      }
    }

    return false; // Nothing impacting the content of the figure is changed...
  }

  getSvgStyle = () : React.CSSProperties => {
    const zoom = this.props.zoom;
    let lineColor =  resolveAttribute(this.props.model, this.props.element, 'pen.lineColor', { fallbackToDefault: false });
    const lineWidth =  resolveAttribute(this.props.model, this.props.element, 'pen.lineWidth');
    const lineStyle =  resolveAttribute(this.props.model, this.props.element, 'pen.lineStyle');

    if (!lineColor) {
      lineColor = resolveAttribute(this.props.model, this.props.element, 'forecolor');
    }

    const result : React.CSSProperties = {
      stroke: lineColor,
      strokeWidth: lineWidth * zoom,
    };
    switch (lineStyle.toLowerCase())
    {
      case 'dotted' :
      {
        result.strokeDasharray = `${lineWidth * zoom},${lineWidth * zoom}`;
        break;
      }
      case 'dashed' :
      {
        result.strokeDasharray = `${5 * lineWidth * zoom},${3 * lineWidth * zoom}`;
        break;
      }
      case 'double' : //FIXME: there is no built-in svg support for double stroke style; strokes could be rendered twice as a workaround
      case 'solid' :
      default :
      {
        break;
      }
    }
    return result;
  }

  private getPen = (borderWidth: number, borderStyle: string, borderColor: string, side?: string): React.CSSProperties => {
    let currentBorderWidth = borderWidth;
    if (0 < borderWidth && borderWidth < 1) {
      currentBorderWidth = 1;
    }

    let currentBorderStyle;
    const cleanedStyle = borderStyle ? borderStyle.toLowerCase().trim() : borderStyle;
    switch (cleanedStyle) {
      case 'double':
        {
          currentBorderStyle = "double";
          break;
        }
      case 'dotted':
        {
          currentBorderStyle = "dotted";
          break;
        }
      case 'dashed':
        {
          currentBorderStyle = "dashed";
          break;
        }
      case 'solid':
      default:
        {
          currentBorderStyle = "solid";
          break;
        }
    }

    const result: React.CSSProperties = {};
    if (currentBorderWidth > 0) {
      const value = `${(currentBorderWidth)}px ${currentBorderStyle} ${borderColor}`;
      if (side) {
        switch (side) {
          case 'left':
            result.borderLeft = value;
            break;
          case 'right':
            result.borderRight = value;
            break;
          case 'top':
            result.borderTop = value;
            break;
          case 'bottom':
            result.borderBottom = value;
            break;
        }
      } else {
        result.border = value;
      }

    }

    return result;
  }

  public render() {
    const width = this.props.availableWidth;
    const height = this.props.availableHeight;
    const zoom = this.props.zoom;
    const lineDirection = this.props.element.get('direction', 'TopDown').toLowerCase();
    let globalColor = resolveAttribute(this.props.model, this.props.element, 'pen.lineColor');
    let globalWidth = resolveAttribute(this.props.model, this.props.element, 'pen.lineWidth', { fallbackToDefault: false });
    if (!globalWidth) {
      globalWidth = 1;
      globalColor = '#CCCCCC';
    }
    if(isOblique(width, height)){
      globalColor = '#CCCCCC';
      globalWidth = 1;
      const heightLessBorder =  Math.max(0, height - ((2 * globalWidth) * zoom));
      const widthLessBorder = Math.max(0, width - ((2 * globalWidth) * zoom));
      const y1 = lineDirection === 'bottomup' ? heightLessBorder : globalWidth * zoom;
      const y2 = lineDirection === 'bottomup' ? globalWidth * zoom : heightLessBorder;

      return <div style={{ top: 0, left: 0,width: widthLessBorder, height: heightLessBorder, display: 'flex', ...this.getPen(globalWidth * this.props.zoom, 'solid', globalColor)}}>
        <svg height={heightLessBorder} width={widthLessBorder}>
          <line x1={globalWidth * zoom} y1={y1} x2={widthLessBorder} y2={y2} style={this.getSvgStyle() as any}/>
        </svg>
      </div>
    } else {
      let side = null;
      let lineColor = resolveAttribute(this.props.model, this.props.element, 'pen.lineColor');
      if (!lineColor) {
        lineColor = resolveAttribute(this.props.model, this.props.element, 'forecolor');
      }
      const lineWidth = resolveAttribute(this.props.model, this.props.element, 'pen.lineWidth');
      const lineStyle = resolveAttribute(this.props.model, this.props.element, 'pen.lineStyle');

			const ratio = width / height;
			if (ratio > 1)
			{
				if (lineDirection === 'topdown') {
					side = "top";
				} else {
					side = "bottom";
				}
			}
			else {
				if (lineDirection == 'topdown') {
					side = "left";
				} else {
					side = "right";
				}
			}
      return <div style={{ top: 0, left: 0, width, height, display: 'flex', ...this.getPen(Math.max(lineWidth * zoom, zoom), lineStyle, lineColor, side)}}/>
    }
  }
}

const mapStateToProps = (state: IState, props: ILineElement) => {

  return {
    element: state.getIn(['report', 'model', 'elements', props.id]),
    model: state.getIn(['report', 'model']),
    zoom: state.getIn(['report', 'zoom']),
  };
}

export default connect(mapStateToProps)(LineElement);   // ,mapDispatchToProps