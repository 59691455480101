/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { getKey, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import React from 'react';
import { Select } from '@jss/js-common';
import { APDescriptor } from '../../ui/APDescriptor';
import { MXYBarChart } from './MChart';

const TYPEID = 'chartDataset';
reg(TYPEID, (mc) => { return <UPChartDataset mcontext={mc} />; });
export class PChartDataset extends APDescriptor {
    items: { key: string, value: string, deprecated?: boolean }[];
    groupProperty: string;
    public constructor(init: Partial<PChartDataset>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
export class UPChartDataset extends React.Component<UiPropertyProps> {
    render() {
        const d = this.props.mcontext.descriptor as PChartDataset;

        const path = this.props.mcontext.elements[0].path;
        let ep = [];
        if (path?.length > 0)
            if (Array.isArray(path[0])) { ep = [...path[0]]; } else { ep = [...path]; }
        ep = [ep.splice(0, 2)];

        const p = getPath(d.id, ep);
        let v = this.props.mcontext.model.getIn(p);
        if (v === MXYBarChart.id) {
            const items = [{ key: 'xyDataset', value: 'XY Dataset' },
            { key: 'timePeriodDataset', value: 'Time Period Dataset' },
            { key: 'timeSeriesDataset', value: 'Time Series Dataset' }];

            v = 'xyDataset';
            if (this.props.mcontext.model.getIn(getPath('timeSeriesDataset', ep)))
                v = 'timeSeriesDataset';
            if (this.props.mcontext.model.getIn(getPath('timePeriodDataset', ep)))
                v = 'timePeriodDataset';
            if (this.props.mcontext.model.getIn(getPath('xyDataset', ep)))
                v = 'xyDataset';
            //console.log(v);
            return <Select key={getKey(p)} items={items}
                className={this.props.mcontext.descriptor.className}
                style={this.props.mcontext.descriptor.style}
                value={v}
                onItemSelected={this.onNameChange}
                label={d.label}
                inline={true} size={'large'}
            />
        }
        return <></>;
    }
    public onNameChange = () => {
        // console.log(str);
        // const path = this.props.mcontext.elements[0].path;
        // const p = getPath(undefined, path);
        // console.log(p);

        // const ds = this.props.mcontext.model.getIn(p);
        // this.props.mcontext.elements.forEach(key => {
        //     let ep = [];
        //     if (key.path?.length > 0)
        //         if (Array.isArray(path[0])) { ep = [...key.path[0]]; } else { ep = [...key.path]; }
        //     ep = [ep.splice(0, 2)];
        //     ep.push('str')
        //     console.log(ep, str, ds);
        //     this.props.mcontext.setObjectProperties(ep, { ['dataset']: ds });

            // could copy also series with series_expression and hyperlinks

            // delete the old one            
            
        // });


    }

}