/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Button, IconButton, IMenuItem, IToolBarItemOptions, IToolbarProvider, ToolButton } from '@jss/js-common';
import { ResourceInfoDialog, RunContext } from '@jss/js-repository';
import { DatasourceApi, ErrorDescriptor, IRepositoryItemDescriptor, isReadOnly, MIME_TYPES, RepositoryApi } from '@jss/js-rest-api';
import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import PublishIcon from '@material-ui/icons/Publish';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { AxiosError } from 'axios';
import { saveAs } from 'file-saver';
import { Base64 } from 'js-base64';
import * as React from 'react';
import AceEditor, { IAceEditorProps } from 'react-ace';
import format from 'xml-formatter';
import SimplyBeautiful from 'simply-beautiful';
import i18n from '../i18n';
import { AbstractTextualEditor, EXIT_TEXT_EDITOR, IAbstractTextualEditor } from './AbstractTextualEditor';

export default class InternalDataAdapterTextEditor extends AbstractTextualEditor {

    constructor(props: IAbstractTextualEditor) {
        super(props);
    }

    public getAcceptedFileTypes(): string {
        return this.props.acceptedFileTypes ? this.props.acceptedFileTypes : ".xml,.jrdax,application/xml"
    }

    public getDefaultExtension(): string {
        return this.props.defaultExtension ? this.props.defaultExtension : 'xml';
    }

    public getTextEditor(editorProps: IAceEditorProps): React.ReactElement {
        const props = {
            ...editorProps,
            mode: this.isJrsDataSource() ? 'json' : 'xml',
            wrapEnabled: true,
            enableBasicAutocompletion: true,
            tabSize: 2,
            theme: 'textmate',
        };
        return <AceEditor {...props} ref={this.editor} />;
    }

    private showPropertiesDialog = () => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.context.showDialog(ResourceInfoDialog, { descriptor: this.state.openDescriptor, onViewClose: () => { }, onChanged: this.handleItemPropertiesChanged });
    }

    public formatContent(content: string): string {
        try {
            if (this.isJrsDataSource()) {
                const options = {
                    indent_size: 2,
                };
                const result = SimplyBeautiful.json(content, options);
                return result;
            } else {
                return format(content, { indentation: '  ', collapseContent: true });
            }
        } catch (error) {
            return content;
        }
    }

    private isJrsDataSource = () => {
        return (this.state.openDescriptor && (this.state.openDescriptor.mime === MIME_TYPES.DATASOURCE_JNDI ||
            this.state.openDescriptor.mime === MIME_TYPES.DATASOURCE_JDBC));
    }

    public hasFomatter = () => {
        return true;
    }

    private testDataSource = () => {
        this.asyncDAServerValidation((res: boolean, errMsg?: string) => {
            if (res) {
                this.setState({ successMessage: i18n.t('text.editor.test.success') });
            } else {
                this.setState({ errorMessage: errMsg ? errMsg : i18n.t('text.editor.test.fail') });
            }
        });
    }

    private asyncDAServerValidation = async (callBack: (res: boolean, errMsg?: string) => void) => {
        this.setState({ pageLoading: true, errorMessage: null }, async () => {
            const xmlDaContent = this.state.editorContent;
            if (this.state.openDescriptor) {
                const base64data = Base64.encode(xmlDaContent);
                const descriptor: IRepositoryItemDescriptor = this.state.openDescriptor;
                const newDescriptor: IRepositoryItemDescriptor = {
                    ...(descriptor as any),
                    data: base64data,
                    path: RepositoryApi.getAPathOnly(descriptor)
                }
                const t = RepositoryApi.inst().tmp();
                if (t != RepositoryApi.inst().currentRepository())
                    newDescriptor.uuid = undefined;

                try {
                    const r = await this.context.runNoCancel(t.save)(newDescriptor.path ? newDescriptor.path : '/',
                        newDescriptor);
                    if (!r.canceled) {
                        let pa = this.state.openDescriptor.path;
                        if (!pa.endsWith('/')) {
                            pa += "/";
                        }
                        pa += this.state.openDescriptor.name;
                        await this.context.run(DatasourceApi.inst().testDataAdapter)(pa, pa).then((response) => {
                            if (response.data) {
                                //valid da
                                this.setState({ pageLoading: false }, () => callBack(true));
                            } else {
                                this.setState({ pageLoading: false }, () => callBack(false, response.message));
                            }
                        }).catch((error: AxiosError) => {
                            this.setState({ pageLoading: false }, () => callBack(false, new ErrorDescriptor(error).message));
                        });
                    }
                } finally { }
            }
        });
    }

    protected getErrorDialog = () => {
        return <Dialog
            open={this.state.errorMessage !== null}
            aria-labelledby="simple-dialog-title"
            fullWidth={true}
            maxWidth={'xs'}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    this.clearErrors();
                }
            }}
            aria-describedby="alert-dialog-description"
            title={i18n.t('text.editor.test.error')}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.state.errorMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button size="large" color="secondary" variant="contained" className="tc-jsw-margin-right tc-buttons-borderless" onClick={this.clearErrors}>{i18n.t('text.editor.test.cancel')}</Button>
                <Button size="large" color="primary" variant="contained" className="tc-jsw-margin-right" onClick={this.testDataSource}>{i18n.t('text.editor.test.tryAgain')}</Button>
            </DialogActions>
        </Dialog>
    }


    getInternalToolbar = (): IToolbarProvider => {
        const isRO = !this.state.openDescriptor || isReadOnly(this.state.openDescriptor);
        const isSaveDisabled = !this.state.isDirty || isRO;
        const getSaveSubmenu: IMenuItem[] = [
            {
                id: 'saveAction',
                label: i18n.t('common.actions.menu.save'),
                isEnabled: !isSaveDisabled,
                icon: <IconButton className='jr-mMenuDecorated jr-mMenu-list-item-icon jr-MuiListItemIcon-root jr-mIcon mui' style={{ minWidth: 0, backgroundColor: 'transparent' }} icon={'save'} size="small" disabled={isSaveDisabled} />,
                onClick: () => {
                    if (this.state.openDescriptor.uuid) {
                        this.doSave(false);
                    } else {
                        //if the file is new force a new file to let the user choose the name
                        this.doSave(true);
                    }
                }
            },
            {
                id: 'saveAsAction',
                label: i18n.t('common.actions.menu.saveas'),
                icon: <SaveAltIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    this.doSave(true);
                }
            },
            {
                id: 'propertiesAction',
                label: i18n.t('common.actions.menu.resourceinfo'),
                icon: <InfoIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    this.showPropertiesDialog();
                },
                isEnabled: this.state.openDescriptor !== undefined && this.state.openDescriptor.uuid !== undefined,
            },
            {
                label: i18n.t('common.actions.menu.upload'),
                id: 'importAction',
                getComponent: (handleClose: () => void) => {
                    return (
                        <MenuItem key={'importAction'} selected={false} className={"jr-mMenu-list-item mui"} style={{ opacity: isRO ? 0.5 : undefined }}>
                            <div style={{ display: 'flex' }} onClick={(event) => {
                                if (!isRO) {
                                    handleClose(); this.handleUploadPress(event)
                                }
                            }}>
                                <ListItemIcon className={"jr-mMenu-list-item-icon mui"}>
                                    <PublishIcon className={'jr-MuiListItemIcon-root jr-mIcon mui'} />
                                </ListItemIcon>
                                <ListItemText primary={i18n.t('common.actions.menu.upload')} classes={{ primary: "jr-mText mui" }} />
                            </div>
                        </MenuItem>)
                }
            },
            {
                label: i18n.t('common.actions.menu.download'),
                icon: <GetAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                id: 'exportAction',
                onClick: () => {
                    const blob = new Blob([this.state.editorContent], { type: "text/xml;charset=utf-8" });
                    const extension = !this.state.openDescriptor.name.endsWith(this.getDefaultExtension()) ? `.${this.getDefaultExtension()}` : '';
                    saveAs(blob, this.state.openDescriptor.name + extension);
                }
            },
            {
                label: i18n.t('common.actions.menu.exit'),
                id: 'exitAction',
                icon: <ExitToAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    if (this.props.commandRegistry) {
                        this.props.commandRegistry.executeCommand(EXIT_TEXT_EDITOR);
                    }
                }
            },
        ];
        return {
            getToolItems: () => {
                const items = [
                    {
                        id: 'saveToolaction',
                        render: (options?: IToolBarItemOptions) => {
                            //disable the save when the report is not dirty or we don't have write permission
                            const height = options && options.height ? options.height : 24;
                            const backgroundColor = options && options.background ? options.background : undefined;
                            return <ToolButton label={i18n.t('common.actions.menu.save')} isDisabled={isSaveDisabled} onClick={() => {
                                if (this.state.openDescriptor.uuid) {
                                    this.doSave(false);
                                } else {
                                    //if the file is new force a new file to let the user choose the name
                                    this.doSave(true);
                                }
                            }} height={height} backgroundColor={backgroundColor} content={getSaveSubmenu}>
                                <IconButton style={{ backgroundColor: 'transparent' }} icon={'save'} size="small" disabled={isSaveDisabled} />
                            </ToolButton>
                        }
                    },
                    {
                        id: 'undoToolaction',
                        render: (options?: IToolBarItemOptions | undefined) => {
                            const isUndoDisabled = !this.canUndo();
                            const height = options && options.height ? options.height : 24;
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                                <div title={i18n.t('common.actions.menu.undo')} style={{ height: height, cursor: isUndoDisabled ? 'default' : 'pointer', justifyContent: 'center', alignItems: 'center', marginRight: 15 }} onClick={!isUndoDisabled ? this.undo : undefined}>
                                    <IconButton style={{ backgroundColor: 'transparent' }} icon={'undo'} size="small" disabled={isUndoDisabled} />
                                </div>
                            </div>
                        }
                    },
                    {
                        id: 'redoToolaction',
                        render: (options?: IToolBarItemOptions) => {
                            const height = options && options.height ? options.height : 24;
                            const isRedoDisabled = !this.canRedo();
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div title={i18n.t('common.actions.menu.redo')} style={{ height: height, cursor: isRedoDisabled ? 'default' : 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={!isRedoDisabled ? this.redo : undefined}>
                                    <IconButton icon={'redo'} size="small" disabled={isRedoDisabled} />
                                </div>
                                <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                            </div>;
                        }
                    },

                ];
                if (this.hasFomatter()) {
                    items.push(
                        {
                            id: 'formatToolaction',
                            render: (options?: IToolBarItemOptions) => {
                                const height = options && options.height ? options.height : 24;
                                return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <div title={i18n.t('text.editor.format.tooltip')} style={{ height: height, cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={this.doFormat}>
                                        <IconButton icon={'textACenter'} size="small" />
                                    </div>
                                </div>;
                            }
                        }
                    );
                }
                if (DatasourceApi.inst().hasTest() && !this.isJrsDataSource()) {
                    //add the test item only if enabled
                    items.push(
                        {
                            id: 'testDataAdapterAction',
                            render: (options?: IToolBarItemOptions) => {
                                const height = options && options.height ? options.height : 24;
                                const foregroundColor = options && options.foreground ? options.foreground : '#0081cb';
                                const backgroundColor = options && options.background ? options.background : undefined;
                                return <div style={{ display: 'flex', width: 70, height: height, justifyContent: 'center', alignItems: 'center', background: backgroundColor }} title={i18n.t('text.editor.testTooltip')}>
                                    <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                                    <div style={{ width: 70, height: 24, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} onClick={this.testDataSource}>
                                        <span style={{ color: foregroundColor, fontSize: 15 }}>{i18n.t('text.editor.test.label')}</span>
                                    </div>
                                    <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                                </div>
                            }
                        },
                    );
                }
                if (this.editor.current !== null) {
                    const currentAceEditor = this.editor.current.editor;
                    items.push(
                        {
                            id: 'helpToolAction',
                            render: (options?: IToolBarItemOptions) => {
                                const height = options && options.height ? options.height : 24;
                                return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <div title={i18n.t('text.editor.help.tooltip')} style={{ height: height, cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                                        currentAceEditor.execCommand("showKeyboardShortcuts");
                                    }}>
                                        <HelpOutlineIcon className='jr-iconColor' />
                                    </div>
                                </div>;
                            }
                        }
                    );
                }
                return items;
            },
        }
    }
}

InternalDataAdapterTextEditor.contextType = RunContext;
