/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../reducers';
import anglularGaugeImage from '../../../assets/uxpl/img/widgetpreviews/angularGauge.png';
import bulbImage from '../../../assets/uxpl/img/widgetpreviews/bulb.png';
import cylinderGaugeImage from '../../../assets/uxpl/img/widgetpreviews/cylinder.png';
import funnelImage from '../../../assets/uxpl/img/widgetpreviews/funnel.png';
import ganttChartImage from '../../../assets/uxpl/img/widgetpreviews/ganttChart.png';
import horizontalBulletImage from '../../../assets/uxpl/img/widgetpreviews/horizontalBullet.png';
import horizontalLEDImage from '../../../assets/uxpl/img/widgetpreviews/horizontalLED.png';
import linearGaugeImage from '../../../assets/uxpl/img/widgetpreviews/linearGauge.png';
import noPreviewImage from '../../../assets/uxpl/img/widgetpreviews/no_preview.png';
import pyramidImage from '../../../assets/uxpl/img/widgetpreviews/pyramid.png';
import sparkColumnImage from '../../../assets/uxpl/img/widgetpreviews/sparkColumn.png';
import sparkLineImage from '../../../assets/uxpl/img/widgetpreviews/sparkLine.png';
import sparkWinLossImage from '../../../assets/uxpl/img/widgetpreviews/sparkWinLoss.png';
import thermometerImage from '../../../assets/uxpl/img/widgetpreviews/thermometer.png';
import verticalBulletImage from '../../../assets/uxpl/img/widgetpreviews/verticalBullet.png';
import verticalLedImage from '../../../assets/uxpl/img/widgetpreviews/verticalLED.png';
import { FusionWidgetTypes } from '../../common/JrxmlModel/reader/JrxmlFusionWidgetUtils';

interface IChartElement {
  id: string;
  element?: any;
  zoom?: number;
  availableWidth: number,
  availableHeight: number,
}

const visualProperties = ["widgetType"];

class FusionWidgetElement extends React.Component<IChartElement> {

  public shouldComponentUpdate = (nextProps: Readonly<IChartElement>) => {
    if (this.props.availableHeight !== nextProps.availableHeight || this.props.availableWidth !== nextProps.availableWidth) {
      return true;
    }

    if (this.props.zoom !== nextProps.zoom) {
      return true;
    }
    for (const propName of visualProperties) {
      if (this.props.element.get(propName) !== nextProps.element.get(propName)) {
        return true;
      }
    }

    return false; // Nothing impacting the content of the figure is changed...
  }

  public render() {
    const type = this.props.element.get('widgetType');
    let image = noPreviewImage;
    if (type === FusionWidgetTypes.ANGULAR_GAUGE) {
      image = anglularGaugeImage;
    } else if (type === FusionWidgetTypes.BULB) {
      image = bulbImage;
    } else if (type === FusionWidgetTypes.CYLINDER) {
      image = cylinderGaugeImage;
    } else if (type === FusionWidgetTypes.FUNNEL) {
      image = funnelImage;
    } else if (type === FusionWidgetTypes.GANTT) {
      image = ganttChartImage;
    } else if (type === FusionWidgetTypes.HORIZONTAL_BULLET) {
      image = horizontalBulletImage;
    } else if (type === FusionWidgetTypes.HORIZONTAL_LED) {
      image = horizontalLEDImage;
    } else if (type === FusionWidgetTypes.LINEAR_GAUGE) {
      image = linearGaugeImage;
    } else if (type === FusionWidgetTypes.PYRAMID) {
      image = pyramidImage;
    } else if (type === FusionWidgetTypes.SPARK_COLUMN) {
      image = sparkColumnImage;
    } else if (type === FusionWidgetTypes.SPARK_LINE) {
      image = sparkLineImage;
    } else if (type === FusionWidgetTypes.SPARK_WIN_LOSS) {
      image = sparkWinLossImage;
    } else if (type === FusionWidgetTypes.THERMOMETER) {
      image = thermometerImage;
    } else if (type === FusionWidgetTypes.VERTICAL_BULLET) {
      image = verticalBulletImage;
    } else if (type === FusionWidgetTypes.VERTICAL_LED) {
      image = verticalLedImage;
    }
    return <div style={{ width: '100%', height: '100%'}}>
      <img src={image} style={{width: '100%', height: '100%'}}/>
    </div>;
  }
}

const mapStateToProps = (state: IState, props: IChartElement) => {

  return {
    element: state.getIn(['report', 'model', 'elements', props.id]),
    model: state.getIn(['report', 'model']),
    zoom: state.getIn(['report', 'zoom']),
  };
}

export default connect(mapStateToProps)(FusionWidgetElement);   // ,mapDispatchToProps