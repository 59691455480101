/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { defaultReportState } from "../../reportReducer";
import { Map } from 'immutable';
import { MBubbleChart, MGanttChart, MHighLowChart, MMeterChart, MPie3DChart, MPieChart, MScatterChart, MSpiderChart, MThermometerChart, MTimeSeriesChart, MXYAreaChart, MXYBarChart, MXYLineChart } from "../../../../components/report/properties/types/chart/MChart";
import { MChartCategoryDataset, MChartGanttDataset, MChartHighLowDataset, MChartPieDataset, MChartSpiderDataset, MChartTimeDataset, MChartTimePeriodDataset, MChartValueDataset, MChartXYDataset, MChartXYZDataset } from "../../../../components/report/properties/types/chart/MJFreeChart";
import { ImMap } from "../refactorElement";

interface ChartDataset {
    type: string;
    dataset: Map<string, any>;
}

export const changeDatasetType = (state = defaultReportState, action: any, newState: ImMap, newType: string, oldType: string): ImMap => {
    const oldDataset = dataset4chart(oldType, state, action); // here get the name of the old dataset
    switch (newType) {
        case MXYBarChart.id:
            switch (oldDataset.type) {
                case MChartTimeDataset.id:
                    newState = merge2TimeDataset(oldDataset, action, newState);
                    break;
                case MChartTimePeriodDataset.id:
                    newState = merge2TimePeriodDataset(oldDataset, action, newState);
                    break;
                case MChartXYDataset.id:
                    newState = merge2XYDataset(oldDataset, action, newState);
                    break;
            }
            break;
        case MBubbleChart.id:
            newState = merge2XYZDataset(oldDataset, action, newState);
            break;
        case MGanttChart.id:
            newState = merge2GanttDataset(oldDataset, action, newState);
            break;
        case MHighLowChart.id:
            newState = merge2HighLowDataset(oldDataset, action, newState);
            break;
        case MPieChart.id:
        case MPie3DChart.id:
            newState = merge2PieDataset(oldDataset, action, newState);
            break;
        case MScatterChart.id:
        case MXYAreaChart.id:
        case MXYLineChart.id:
            newState = merge2XYDataset(oldDataset, action, newState);
            break;
        case MMeterChart.id:
        case MThermometerChart.id:
            newState = merge2ValueDataset(oldDataset, action, newState);
            break;
        case MTimeSeriesChart.id:
            newState = merge2TimeDataset(oldDataset, action, newState);
            break;
        case MSpiderChart.id:
            newState = merge2SpiderDataset(oldDataset, action, newState);
            break;
        default:
            newState = merge2CategoryDataset(oldDataset, action, newState);
    }
    return newState;
}
const dataset4chart = (type: string, state = defaultReportState, action: any): ChartDataset => {
    switch (type) {
        case MXYBarChart.id:
            let r = state.getIn([...action.path, MChartTimeDataset.id]) as Map<string, any>;
            if (r) return { type: MChartTimeDataset.id, dataset: r };
            r = state.getIn([...action.path, MChartTimePeriodDataset.id]) as Map<string, any>;
            if (r) return { type: MChartTimePeriodDataset.id, dataset: r };
            r = state.getIn([...action.path, MChartXYDataset.id]) as Map<string, any>;
            if (r) return { type: MChartXYDataset.id, dataset: r };
            break;
        case MBubbleChart.id:
            return { type: MChartXYZDataset.id, dataset: state.getIn([...action.path, MChartXYZDataset.id]) as Map<string, any> };
        case MGanttChart.id:
            return { type: MChartGanttDataset.id, dataset: state.getIn([...action.path, MChartGanttDataset.id]) as Map<string, any> };
        case MHighLowChart.id:
            return { type: MChartHighLowDataset.id, dataset: state.getIn([...action.path, MChartHighLowDataset.id]) as Map<string, any> };
        case MPieChart.id:
        case MPie3DChart.id:
            return { type: MChartPieDataset.id, dataset: state.getIn([...action.path, MChartPieDataset.id]) as Map<string, any> };
        case MScatterChart.id:
        case MXYAreaChart.id:
        case MXYLineChart.id:
            return { type: MChartXYDataset.id, dataset: state.getIn([...action.path, MChartXYDataset.id]) as Map<string, any> };
        case MMeterChart.id:
        case MThermometerChart.id:
            return { type: MChartValueDataset.id, dataset: state.getIn([...action.path, MChartValueDataset.id]) as Map<string, any> };
        case MTimeSeriesChart.id:
            return { type: MChartTimeDataset.id, dataset: state.getIn([...action.path, MChartTimeDataset.id]) as Map<string, any> };
        case MSpiderChart.id:
            return { type: MChartSpiderDataset.id, dataset: state.getIn([...action.path, MChartSpiderDataset.id]) as Map<string, any> };
    }
    return { type: MChartCategoryDataset.id, dataset: state.getIn([...action.path, MChartCategoryDataset.id]) as Map<string, any> };
}
const merge2SpiderDataset = (oldDataset: ChartDataset, action: any, newState: ImMap): ImMap => {
    if (oldDataset.type === MChartSpiderDataset.id)
        return newState;
    newState = newState.mergeDeepIn([...action.path, MChartSpiderDataset.id, 'dataset'], oldDataset.dataset.get('dataset'));
    switch (oldDataset.type) {
        case MChartXYZDataset.id:
            let series = oldDataset.dataset.get('xyzSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        categoryExpression: k.get('xValueExpression'),
                        valueExpression: k.get('yValueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartSpiderDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartXYDataset.id:
            series = oldDataset.dataset.get('xySeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        categoryExpression: k.get('xValueExpression'),
                        valueExpression: k.get('yValueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartSpiderDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartPieDataset.id:
            series = oldDataset.dataset.get('pieSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        categoryExpression: k.get('keyExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartSpiderDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartTimePeriodDataset.id:
            series = oldDataset.dataset.get('timePeriodDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartSpiderDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartTimeDataset.id:
            series = oldDataset.dataset.get('timeSeriesDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        categoryExpression: k.get('timePeriodExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartSpiderDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartGanttDataset.id:
            series = oldDataset.dataset.get('ganttSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartSpiderDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartHighLowDataset.id:
            newState = newState.setIn([...action.path, MChartSpiderDataset.id, 'categorySeries'], oldDataset.dataset.get('categorySeries'));
            break;
        case MChartCategoryDataset.id:
            newState = newState.setIn([...action.path, MChartSpiderDataset.id, 'categorySeries'], oldDataset.dataset.get('categorySeries'));
            break;
    }
    newState = newState.deleteIn([...action.path, oldDataset.type]);
    return newState;
}
const merge2TimeDataset = (oldDataset: ChartDataset, action: any, newState: ImMap): ImMap => {
    if (oldDataset.type === MChartTimeDataset.id)
        return newState;
    newState = newState.mergeDeepIn([...action.path, MChartTimeDataset.id, 'dataset'], oldDataset.dataset.get('dataset'));
    switch (oldDataset.type) {
        case MChartXYZDataset.id:
            let series = oldDataset.dataset.get('xyzSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        timePeriodExpression: k.get('xValueExpression'),
                        valueExpression: k.get('yValueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartTimeDataset.id, 'timeSeries', index], s);
                });
            break;
        case MChartHighLowDataset.id:
        case MChartSpiderDataset.id:
        case MChartCategoryDataset.id:
            series = oldDataset.dataset.get('categorySeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        timePeriodExpression: k.get('categoryExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartTimeDataset.id, 'timeSeries', index], s);
                });
            break;
        case MChartPieDataset.id:
            series = oldDataset.dataset.get('pieSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        timePeriodExpression: k.get('keyExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartTimeDataset.id, 'timeSeries', index], s);
                });
            break;
        case MChartTimePeriodDataset.id:
            series = oldDataset.dataset.get('timePeriodDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartTimeDataset.id, 'timeSeries', index], s);
                });
            break;
        case MChartXYDataset.id:
            series = oldDataset.dataset.get('xySeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        timePeriodExpression: k.get('xValueExpression'),
                        valueExpression: k.get('yValueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartTimeDataset.id, 'timeSeries', index], s);
                });
            break;
        case MChartGanttDataset.id:
            series = oldDataset.dataset.get('ganttSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartTimeDataset.id, 'timeSeries', index], s);
                });
            break;
    }
    newState = newState.deleteIn([...action.path, oldDataset.type]);
    return newState;
}
const merge2TimePeriodDataset = (oldDataset: ChartDataset, action: any, newState: ImMap): ImMap => {
    if (oldDataset.type === MChartValueDataset.id)
        return newState;
    newState = newState.mergeDeepIn([...action.path, MChartValueDataset.id, 'dataset'], oldDataset.dataset.get('dataset'));

    newState = newState.deleteIn([...action.path, oldDataset.type]);
    return newState;
}
const merge2PieDataset = (oldDataset: ChartDataset, action: any, newState: ImMap): ImMap => {
    if (oldDataset.type === MChartPieDataset.id)
        return newState;
    newState = newState.mergeDeepIn([...action.path, MChartPieDataset.id, 'dataset'], oldDataset.dataset.get('dataset'));
    switch (oldDataset.type) {
        case MChartXYZDataset.id:
            let series = oldDataset.dataset.get('xyzSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        valueExpression: k.get('yValueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartPieDataset.id, 'pieSeries', index], s);
                });
            break;
        case MChartXYDataset.id:
            series = oldDataset.dataset.get('xySeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        valueExpression: k.get('yValueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartPieDataset.id, 'pieSeries', index], s);
                });
            break;
        case MChartGanttDataset.id:
            series = oldDataset.dataset.get('ganttSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartPieDataset.id, 'pieSeries', index], s);
                });
            break;
        case MChartTimePeriodDataset.id:
            series = oldDataset.dataset.get('timePeriodDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartPieDataset.id, 'pieSeries', index], s);
                });
            break;
        case MChartTimeDataset.id:
            series = oldDataset.dataset.get('timeSeriesDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartPieDataset.id, 'pieSeries', index], s);
                });
            break;
        case MChartHighLowDataset.id:
        case MChartSpiderDataset.id:
        case MChartCategoryDataset.id:
            series = oldDataset.dataset.get('categorySeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartPieDataset.id, 'pieSeries', index], s);
                });
            break;
    }
    newState = newState.deleteIn([...action.path, oldDataset.type]);
    return newState;
}
const merge2HighLowDataset = (oldDataset: ChartDataset, action: any, newState: ImMap): ImMap => {
    if (oldDataset.type === MChartHighLowDataset.id)
        return newState;
    newState = newState.mergeDeepIn([...action.path, MChartHighLowDataset.id, 'dataset'], oldDataset.dataset.get('dataset'));
    switch (oldDataset.type) {
        case MChartXYZDataset.id:
            let series = oldDataset.dataset.get('xyzSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        categoryExpression: k.get('xValueExpression'),
                        valueExpression: k.get('yValueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartHighLowDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartXYDataset.id:
            series = oldDataset.dataset.get('xySeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        categoryExpression: k.get('xValueExpression'),
                        valueExpression: k.get('yValueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartHighLowDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartPieDataset.id:
            series = oldDataset.dataset.get('pieSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        categoryExpression: k.get('keyExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartHighLowDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartTimePeriodDataset.id:
            series = oldDataset.dataset.get('timePeriodDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartHighLowDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartTimeDataset.id:
            series = oldDataset.dataset.get('timeSeriesDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        categoryExpression: k.get('timePeriodExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartHighLowDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartGanttDataset.id:
            series = oldDataset.dataset.get('ganttSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartHighLowDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartSpiderDataset.id:
            newState = newState.mergeDeepIn([...action.path, MChartHighLowDataset.id, 'categorySeries'], oldDataset.dataset.get('categorySeries'));
            break;
        case MChartCategoryDataset.id:
            newState = newState.mergeDeepIn([...action.path, MChartHighLowDataset.id, 'categorySeries'], oldDataset.dataset.get('categorySeries'));
            break;
    }
    newState = newState.deleteIn([...action.path, oldDataset.type]);
    return newState;
}
const merge2GanttDataset = (oldDataset: ChartDataset, action: any, newState: ImMap): ImMap => {
    if (oldDataset.type === MChartGanttDataset.id)
        return newState;
    newState = newState.mergeDeepIn([...action.path, MChartGanttDataset.id, 'dataset'], oldDataset.dataset.get('dataset'));
    switch (oldDataset.type) {
        case MChartXYZDataset.id:
            let series = oldDataset.dataset.get('xyzSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartGanttDataset.id, 'ganttSeries', index], s);
                });
            break;
        case MChartXYDataset.id:
            series = oldDataset.dataset.get('xySeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartGanttDataset.id, 'ganttSeries', index], s);
                });
            break;
        case MChartPieDataset.id:
            series = oldDataset.dataset.get('pieSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartGanttDataset.id, 'ganttSeries', index], s);
                });
            break;
        case MChartTimePeriodDataset.id:
            series = oldDataset.dataset.get('timePeriodDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartGanttDataset.id, 'ganttSeries', index], s);
                });
            break;
        case MChartTimeDataset.id:
            series = oldDataset.dataset.get('timeSeriesDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartGanttDataset.id, 'ganttSeries', index], s);
                });
            break;
        case MChartHighLowDataset.id:
        case MChartSpiderDataset.id:
        case MChartCategoryDataset.id:
            series = oldDataset.dataset.get('categorySeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartGanttDataset.id, 'ganttSeries', index], s);
                });
            break;
    }
    newState = newState.deleteIn([...action.path, oldDataset.type]);
    return newState;
}
const merge2XYZDataset = (oldDataset: ChartDataset, action: any, newState: ImMap): ImMap => {
    // first copy dataset, deep copy?
    if (oldDataset.type === MChartXYZDataset.id)
        return newState;
    newState = newState.mergeDeepIn([...action.path, MChartXYZDataset.id, 'dataset'], oldDataset.dataset.get('dataset'));
    switch (oldDataset.type) {
        case MChartXYDataset.id:
            let series = oldDataset.dataset.get('xySeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        xValueExpression: k.get('xValueExpression'),
                        yValueExpression: k.get('yValueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartXYZDataset.id, 'xyzSeries', index], s);
                });
            break;
        case MChartHighLowDataset.id:
        case MChartSpiderDataset.id:
        case MChartCategoryDataset.id:
            series = oldDataset.dataset.get('categorySeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        xValueExpression: k.get('categoryExpression'),
                        yValueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartXYZDataset.id, 'xyzSeries', index], s);
                });
            break;
        case MChartPieDataset.id:
            series = oldDataset.dataset.get('pieSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        xValueExpression: k.get('keyExpression'),
                        yValueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartXYZDataset.id, 'xyzSeries', index], s);
                });
            break;
        case MChartTimePeriodDataset.id:
            series = oldDataset.dataset.get('timePeriodDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        yValueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartXYZDataset.id, 'xyzSeries', index], s);
                });
            break;
        case MChartTimeDataset.id:
            series = oldDataset.dataset.get('timeSeriesDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        xValueExpression: k.get('timePeriodExpression'),
                        yValueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartXYZDataset.id, 'xyzSeries', index], s);
                });
            break;
        case MChartGanttDataset.id:
            series = oldDataset.dataset.get('ganttSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartXYZDataset.id, 'xyzSeries', index], s);
                });
            break;
    }
    newState = newState.deleteIn([...action.path, oldDataset.type]);
    return newState;
}
const merge2ValueDataset = (oldDataset: ChartDataset, action: any, newState: ImMap): ImMap => {
    if (oldDataset.type === MChartValueDataset.id)
        return newState;
    newState = newState.mergeDeepIn([...action.path, MChartValueDataset.id, 'dataset'], oldDataset.dataset.get('dataset'));

    newState = newState.deleteIn([...action.path, oldDataset.type]);
    return newState;
}
const merge2XYDataset = (oldDataset: ChartDataset, action: any, newState: ImMap): ImMap => {
    // first copy dataset, deep copy?
    if (oldDataset.type === MChartXYDataset.id)
        return newState;
    newState = newState.mergeDeepIn([...action.path, MChartXYDataset.id, 'dataset'], oldDataset.dataset.get('dataset'));
    switch (oldDataset.type) {
        case MChartXYZDataset.id:
            let series = oldDataset.dataset.get('xyzSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        xValueExpression: k.get('xValueExpression'),
                        yValueExpression: k.get('yValueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartXYDataset.id, 'xySeries', index], s);
                });
            break;
        case MChartHighLowDataset.id:
        case MChartSpiderDataset.id:
        case MChartCategoryDataset.id:
            series = oldDataset.dataset.get('categorySeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        xValueExpression: k.get('categoryExpression'),
                        yValueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartXYDataset.id, 'xySeries', index], s);
                });
            break;
        case MChartPieDataset.id:
            series = oldDataset.dataset.get('pieSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        xValueExpression: k.get('keyExpression'),
                        yValueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartXYDataset.id, 'xySeries', index], s);
                });
            break;
        case MChartTimePeriodDataset.id:
            series = oldDataset.dataset.get('timePeriodDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        yValueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartXYDataset.id, 'xySeries', index], s);
                });
            break;
        case MChartTimeDataset.id:
            series = oldDataset.dataset.get('timeSeriesDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        xValueExpression: k.get('timePeriodExpression'),
                        yValueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartXYDataset.id, 'xySeries', index], s);
                });
            break;
        case MChartGanttDataset.id:
            series = oldDataset.dataset.get('ganttSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartXYDataset.id, 'xySeries', index], s);
                });
            break;
    }
    newState = newState.deleteIn([...action.path, oldDataset.type]);
    return newState;
}
const merge2CategoryDataset = (oldDataset: ChartDataset, action: any, newState: ImMap): ImMap => {
    if (oldDataset.type === MChartCategoryDataset.id)
        return newState;
    newState = newState.mergeDeepIn([...action.path, MChartCategoryDataset.id, 'dataset'], oldDataset.dataset.get('dataset'));
    switch (oldDataset.type) {
        case MChartXYZDataset.id:
            let series = oldDataset.dataset.get('xyzSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        categoryExpression: k.get('xValueExpression'),
                        valueExpression: k.get('yValueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartCategoryDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartXYDataset.id:
            series = oldDataset.dataset.get('xySeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        categoryExpression: k.get('xValueExpression'),
                        valueExpression: k.get('yValueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartCategoryDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartPieDataset.id:
            series = oldDataset.dataset.get('pieSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        categoryExpression: k.get('keyExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartCategoryDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartTimePeriodDataset.id:
            series = oldDataset.dataset.get('timePeriodDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartCategoryDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartTimeDataset.id:
            series = oldDataset.dataset.get('timeSeriesDataset');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        categoryExpression: k.get('timePeriodExpression'),
                        valueExpression: k.get('valueExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartCategoryDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartGanttDataset.id:
            series = oldDataset.dataset.get('ganttSeries');
            if (series)
                series.forEach((k, index) => {
                    const s = {
                        itemHyperlink: k.get('itemHyperlink'),
                        seriesExpression: k.get('seriesExpression'),
                        labelExpression: k.get('labelExpression')
                    }
                    newState = newState.mergeDeepIn([...action.path, MChartCategoryDataset.id, 'categorySeries', index], s);
                });
            break;
        case MChartHighLowDataset.id:
            newState = newState.mergeDeepIn([...action.path, MChartCategoryDataset.id, 'categorySeries'], oldDataset.dataset.get('categorySeries'));
            break;
        case MChartSpiderDataset.id:
            newState = newState.mergeDeepIn([...action.path, MChartCategoryDataset.id, 'categorySeries'], oldDataset.dataset.get('categorySeries'));
            break;
    }
    newState = newState.deleteIn([...action.path, oldDataset.type]);
    return newState;
}