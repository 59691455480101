/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { MChartPlot } from "./MChartPlot";
import { PString } from "../../../ui/primitive/UPString";
import { MeterShapeEnum } from "../../enums/MeterShapeEnum";
import { PColor } from "../../../ui/primitive/UPColor";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { MFont, setFonts } from "../../common/MFont";
import { PInt } from "../../../ui/primitive/UPInteger";
import { PJavaClass } from "../../../ui/primitive/UPJavaClass";
import { PExpression } from "../../../ui/primitive/UPExpression";

export const FMeterPlot = {
    units: new PString({ id: 'units', label: 'Units' }),
    shape: MeterShapeEnum,

    tickColor: new PColor({ id: 'tickColor', label: 'Color' }),
    tickInterval: new PColor({ id: 'tickInterval', label: 'Interval' }),

    needleColor: new PColor({ id: 'needleColor', label: 'Needle Color' }),

    meterAngle: new PInt({ id: 'meterAngle', label: 'Angle' }),
    meterColor: new PColor({ id: 'meterColor', label: 'Background' }),
    intervals: new PJavaClass({ id: 'intervals', label: 'Intervals' }),



    color: new PColor({ id: 'color', label: 'Color' }),
    mask: new PString({ id: 'mask', label: 'Mask' }),


    lowExpression: new PExpression({ id: 'lowExpression', label: 'Low', path: 'dataRange' }),
    highExpression: new PExpression({ id: 'highExpression', label: 'High', path: 'dataRange' }),
};

export const MMeterPlot: APDescriptor = {
    type: 'MMeterPlot',
    path: 'meterPlot',
    id: 'meterPlotProeprtiesDescriptor',
    layouts: [...MChartPlot.layouts, FMeterPlot.units, FMeterPlot.shape,
    FMeterPlot.needleColor,
    new PAccordeon({
        id: 'chart.tick', label: 'Meter',
        layouts: [FMeterPlot.meterColor, FMeterPlot.meterAngle, FMeterPlot.intervals]
    }),
    new PAccordeon({
        id: 'chart.tick', label: 'Tick',
        layouts: [FMeterPlot.tickColor, FMeterPlot.tickInterval, ...setFonts('tickLabelFont')]
    }),
    new PAccordeon({
        id: 'chart.value.axis', label: 'Value Axis', path: 'valueDisplay',
        layouts: [FMeterPlot.color, MFont, FMeterPlot.mask
        ]
    }),
    FMeterPlot.lowExpression, FMeterPlot.highExpression
    ]
};
