/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from "react";
import { Conf, IEditorViewProps } from "@jss/js-rest-api";
import { useSelector } from "react-redux";
import { Footer } from '@jss/js-common';
import { Header } from "../common/Header";
import { getImageEditorHeaderComponentType, ImageEditorView } from "@jss/js-util-editors";


export function ImageEditorViewWrapper({ descriptor, path, doExit, onFirstSave }: IEditorViewProps) {
    const getHeaderComponent: getImageEditorHeaderComponentType = ({
        resourceName,
        goToHome,
        requestLogout,
        tabIndex,
        tabs,
        onTabChange
    }) => {
        return (
            <Header
                tabs={tabs}
                currentTabIndex={tabIndex}
                onTabChange={onTabChange}
                resourceName={resourceName}
                goToHome={goToHome}
                requestLogout={requestLogout}
                documentType="image" />
        );
    }
    //language is actually not used since it is read from the server conf, but this will trigger the update
    const language: string | undefined = useSelector((state: any) => {
        return (state.persistentReducer.storedData as Map<string, any>).get('language') as string | undefined
    });
    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                <ImageEditorView
                    descriptor={descriptor}
                    path={path}
                    doExit={doExit}
                    onFirstSave={onFirstSave}
                    getHeaderComponent={getHeaderComponent}
                    language={language}
                />
            </div>
            <Footer config={Conf.get('jrws.jrio.serverInfo')} />
        </div>
    );
}
