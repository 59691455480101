/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { FormControlledWritableCombo, i18n, IWritableComboItem } from "@jss/js-common";
import { getKey, getNormalizedPath, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../../ui/APDescriptor';
import { List, Map } from 'immutable';

const TYPEID = 'stylesEnum';
reg(TYPEID, (mc) => { return <UPStyles mcontext={mc} />; });
export class PStyles extends APDescriptor {
    items: { key: string, value: string, deprecated?: boolean }[];
    public constructor(init: Partial<PStyles>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
export class UPStyles extends React.Component<UiPropertyProps> {

    render() {
        const d = this.props.mcontext.descriptor as PStyles;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        let v = this.props.mcontext.model.getIn(p);

        const items : IWritableComboItem[] = [{ key: 'noStyleValueKey', value: i18n.t('StyleCombo.noStyle'), textValue: '' }];
        const reportStyles: List<Map<string, any>> = this.props.mcontext.model.get('styles');
        reportStyles?.forEach(s => {
            const name = s.get('name');
            items.push({ key: name, value: name })
        });

        // read styles from the model ... ? get also the files from template?

        if (!v) v = items[0].key;
        return <FormControlledWritableCombo key={getKey(p)} items={items}
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            value={v}
            onComboChange={this.onNameChange}
            label={i18n.t(d.label)}
            disabled={d.readonly}
            inline={true}
            InputLabelProps={d.deprecated ? { className: 'deprecatedProperty' } : undefined}
            size="small"
        />

    }
    public onNameChange = (selectedKey: string | undefined, value: string) => {
        this.props.mcontext.elements.forEach(key => {
            if (selectedKey === 'noStyleValueKey' || !value || value.trim().length === 0 ){
                const path = getNormalizedPath(key.path);
                path.push(this.props.mcontext.descriptor.id);
                this.props.mcontext.deleteElement(path);
            } else {
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: value });
            }
        });
    }

}