/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { TextInput } from '@jss/js-common';
import '../../assets/css/DataAdapterTypePicker.css';
import i18n from '../../i18n';
import { advancedNameValidator } from '@jss/js-common/src/utils/validators';

interface IQueryExecuterDataAdapterEditor {
    name?: string,
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

export class QueryExecuterDataAdapterEditor extends React.Component<IQueryExecuterDataAdapterEditor> {

    public render() {

        return (<div className="tc-jsw-dataadapter-editor">

            <div className="tc-jsw-form-wrapper">

                <TextInput label={i18n.t('datasource.queryexecuter.name')}
                    help={i18n.t('datasource.queryexecuter.name.help')}
                    id="data-adapter-name"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />
            </div>
        </div>);
    }

    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }
}