/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import axios from 'axios';
import { Conf } from '../config/Conf';
import { JobResult } from '../repo/IRepositoryAPI';
import { RepositoryApi } from '../repo/RepositoryApi';


export class DatasourceJRS {

    private baseURL;

    public getBaseURL = (): string => {
        if (!this.baseURL) {
            this.baseURL = Conf.get('jrws.url.rest.datasource');
        }
        return this.baseURL;
    }
    /**
     * Returns the list of supported datasource types from server.
     */
    public getSupportedDatasourceTypes = (): JobResult => {
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.get(this.getBaseURL() + '/datasource', { cancelToken: source.token, headers: { 'Cache-Control': 'no-cache' } })
        }
    }
    /**
     * Create a folder
     * @param path 
     */
    public testDataAdapter = (path: string, da: string): JobResult => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        const repository = RepositoryApi.inst();
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.get(this.getBaseURL() + '/datasource' + path + '?test=true&dapath=' + da, { cancelToken: source.token, headers: { 'Cache-Control': 'no-cache', 'X-JRS-Repo': repository.getBase(), 'X-JRS-UserID': repository.getUserId() } })
        };
    }

    public getMetadata = (path: string, da: string): JobResult => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        const repository = RepositoryApi.inst();
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.get(this.getBaseURL() + '/datasource' + path + "?dapath=" + da, { cancelToken: source.token, headers: { 'Cache-Control': 'no-cache', 'X-JRS-Repo': repository.getBase(), 'X-JRS-UserID': repository.getUserId() } })
        };
    }

    public readFields = (path: string, params: { key: string, value: any }[]): JobResult => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        const repository = RepositoryApi.inst();
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.post(this.getBaseURL() + '/dataset/fields' + path, params, { cancelToken: source.token, headers: { 'Cache-Control': 'no-cache', 'X-JRS-Repo': repository.getBase(), 'X-JRS-UserID': repository.getUserId() } })
        };
    }

    public runQuery = (path: string, params: { key: string, value: any }[]): JobResult => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        const repository = RepositoryApi.inst();
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.post(this.getBaseURL() + '/dataset/query' + path, params, { cancelToken: source.token, headers: { 'Cache-Control': 'no-cache', 'X-JRS-Repo': repository.getBase(), 'X-JRS-UserID': repository.getUserId() } })
        };
    }

    public evaluateParameters = (path: string, params: { key: string, value: any }[]): JobResult => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        const repository = RepositoryApi.inst();
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.post(this.getBaseURL() + '/dataset/report/params' + path, params, { cancelToken: source.token, headers: { 'Cache-Control': 'no-cache', 'X-JRS-Repo': repository.getBase(), 'X-JRS-UserID': repository.getUserId() } })
        };
    }

    public hasTest = (): boolean => {
        return true;
    }

    public getReportLists = (path: string): JobResult => {
        if (!path.startsWith('/'))
            path = '/' + path;
        if (path.startsWith('//'))
            path = path.substring(1);
        const repository = RepositoryApi.inst();
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.get(this.getBaseURL() + '/dataset/report/lists' + path, { cancelToken: source.token, headers: { 'Cache-Control': 'no-cache', 'X-JRS-Repo': repository.getBase(), 'X-JRS-UserID': repository.getUserId() } })
        };
    }

    public getReportDatasetsCustomProperties = (path: string): JobResult => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        const repository = RepositoryApi.inst();
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.get(this.getBaseURL() + '/dataset/report/properties' + path, { cancelToken: source.token, headers: { 'Cache-Control': 'no-cache', 'X-JRS-Repo': repository.getBase(), 'X-JRS-UserID': repository.getUserId() } })
        };
    }

    public resourceExists = (path: string, name: string): JobResult => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        const repository = RepositoryApi.inst();
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.get(this.getBaseURL() + '/context/resource/exists' + path + '?name=' + name, { cancelToken: source.token, headers: { 'Cache-Control': 'no-cache', 'X-JRS-Repo': repository.getBase(), 'X-JRS-UserID': repository.getUserId() } })
        };
    }
}

