/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const StretchTypeEnum = new PEnum({
    id: 'stretchType', label: 'StretchType.label', default: 'NoStretch',
    items: [
        { key: 'NoStretch', value: 'StretchType.NoStretch' },
        { key: 'RelativeToTallestObject', value: 'StretchType.RelativeToTallestObject', deprecated: true },
        { key: 'RelativeToBandHeight', value: 'StretchType.RelativeToBandHeight', deprecated: true },
        { key: 'ElementGroupHeight', value: 'StretchType.ElementGroupHeight' },
        { key: 'ElementGroupBottom', value: 'StretchType.ElementGroupBottom' },
        { key: 'ContainerHeight', value: 'StretchType.ContainerHeight' },
        { key: 'ContainerBottom', value: 'StretchType.ContainerBottom' }
    ]
});

