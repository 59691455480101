/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const Pie3DChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="802"
      height="493"
      version="1.1"
      style={{
         WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
         WebkitUserSelect: "none",
         MozUserSelect: "none",
         userSelect: "none",
         position: "relative",
      }}
      cursor="default"
      overflow="hidden"
      viewBox="0 0 802 493"
      {...props}
   >
      <defs>
         <radialGradient
            id="19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_146_176_114_1__0-rgba_83_126_36_1__100"
            cx="401"
            cy="258.5"
            r="304"
            fx="309.8"
            fy="404.42"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#92b072"></stop>
            <stop offset="100%" stopColor="#537e24"></stop>
         </radialGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_255_255_255_0__0-rgba_255_255_255_0.16666666666666669__20-rgba_255_255_255_0.33333333333333337__35-rgba_255_255_255_0.16666666666666669__50-rgba_255_255_255_0__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#fff" stopOpacity="0"></stop>
            <stop offset="20%" stopColor="#fff" stopOpacity="0.167"></stop>
            <stop offset="35%" stopColor="#fff" stopOpacity="0.333"></stop>
            <stop offset="50%" stopColor="#fff" stopOpacity="0.167"></stop>
            <stop offset="100%" stopColor="#fff" stopOpacity="0"></stop>
         </linearGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_66_99_28_1__0-rgba_113_151_70_1__20-rgba_172_194_147_1__35-rgba_113_151_70_1__50-rgba_60_91_26_1__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#42631c"></stop>
            <stop offset="20%" stopColor="#719746"></stop>
            <stop offset="35%" stopColor="#acc293"></stop>
            <stop offset="50%" stopColor="#719746"></stop>
            <stop offset="100%" stopColor="#3c5b1a"></stop>
         </linearGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_66_99_28_0.5__0-rgba_88_133_38_0.5__62-rgba_188_206_168_0.5__70-rgba_88_133_38_0.5__78-rgba_66_99_28_0.5__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#42631c" stopOpacity="0.5"></stop>
            <stop offset="62%" stopColor="#588526" stopOpacity="0.5"></stop>
            <stop offset="70%" stopColor="#bccea8" stopOpacity="0.5"></stop>
            <stop offset="78%" stopColor="#588526" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#42631c" stopOpacity="0.5"></stop>
         </linearGradient>
         <radialGradient
            id="19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_181_135_181_1__0-rgba_134_66_134_1__100"
            cx="401"
            cy="258.5"
            r="304"
            fx="309.8"
            fy="404.42"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#b587b5"></stop>
            <stop offset="100%" stopColor="#864286"></stop>
         </radialGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_106_52_106_1__0-rgba_159_97_159_1__20-rgba_199_163_199_1__35-rgba_159_97_159_1__50-rgba_97_48_97_1__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#6a346a"></stop>
            <stop offset="20%" stopColor="#9f619f"></stop>
            <stop offset="35%" stopColor="#c7a3c7"></stop>
            <stop offset="50%" stopColor="#9f619f"></stop>
            <stop offset="100%" stopColor="#613061"></stop>
         </linearGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_106_52_106_0.5__0-rgba_142_70_142_0.5__62-rgba_210_181_210_0.5__70-rgba_142_70_142_0.5__78-rgba_106_52_106_0.5__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#6a346a" stopOpacity="0.5"></stop>
            <stop offset="62%" stopColor="#8e468e" stopOpacity="0.5"></stop>
            <stop offset="70%" stopColor="#d2b5d2" stopOpacity="0.5"></stop>
            <stop offset="78%" stopColor="#8e468e" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#6a346a" stopOpacity="0.5"></stop>
         </linearGradient>
         <radialGradient
            id="19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_228_135_135_1__0-rgba_203_66_66_1__100"
            cx="401"
            cy="258.5"
            r="304"
            fx="309.8"
            fy="404.42"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#e48787"></stop>
            <stop offset="100%" stopColor="#cb4242"></stop>
         </radialGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_160_52_52_1__0-rgba_220_97_97_1__20-rgba_235_163_163_1__35-rgba_220_97_97_1__50-rgba_147_48_48_1__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#a03434"></stop>
            <stop offset="20%" stopColor="#dc6161"></stop>
            <stop offset="35%" stopColor="#eba3a3"></stop>
            <stop offset="50%" stopColor="#dc6161"></stop>
            <stop offset="100%" stopColor="#933030"></stop>
         </linearGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_160_52_52_0.5__0-rgba_214_70_70_0.5__62-rgba_239_181_181_0.5__70-rgba_214_70_70_0.5__78-rgba_160_52_52_0.5__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#a03434" stopOpacity="0.5"></stop>
            <stop offset="62%" stopColor="#d64646" stopOpacity="0.5"></stop>
            <stop offset="70%" stopColor="#efb5b5" stopOpacity="0.5"></stop>
            <stop offset="78%" stopColor="#d64646" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#a03434" stopOpacity="0.5"></stop>
         </linearGradient>
         <radialGradient
            id="19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_89_181_181_1__0-rgba_0_134_134_1__100"
            cx="401"
            cy="258.5"
            r="304"
            fx="309.8"
            fy="404.42"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#59b5b5"></stop>
            <stop offset="100%" stopColor="#008686"></stop>
         </radialGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_0_106_106_1__0-rgba_38_159_159_1__20-rgba_128_199_199_1__35-rgba_38_159_159_1__50-rgba_0_97_97_1__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#006a6a"></stop>
            <stop offset="20%" stopColor="#269f9f"></stop>
            <stop offset="35%" stopColor="#80c7c7"></stop>
            <stop offset="50%" stopColor="#269f9f"></stop>
            <stop offset="100%" stopColor="#006161"></stop>
         </linearGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_0_106_106_0.5__0-rgba_0_142_142_0.5__62-rgba_153_210_210_0.5__70-rgba_0_142_142_0.5__78-rgba_0_106_106_0.5__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#006a6a" stopOpacity="0.5"></stop>
            <stop offset="62%" stopColor="#008e8e" stopOpacity="0.5"></stop>
            <stop offset="70%" stopColor="#99d2d2" stopOpacity="0.5"></stop>
            <stop offset="78%" stopColor="#008e8e" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#006a6a" stopOpacity="0.5"></stop>
         </linearGradient>
         <radialGradient
            id="19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_255_181_135_1__0-rgba_242_134_66_1__100"
            cx="401"
            cy="258.5"
            r="304"
            fx="309.8"
            fy="404.42"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#ffb587"></stop>
            <stop offset="100%" stopColor="#f28642"></stop>
         </radialGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_191_106_52_1__0-rgba_255_159_97_1__20-rgba_255_199_163_1__35-rgba_255_159_97_1__50-rgba_175_97_48_1__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#bf6a34"></stop>
            <stop offset="20%" stopColor="#ff9f61"></stop>
            <stop offset="35%" stopColor="#ffc7a3"></stop>
            <stop offset="50%" stopColor="#ff9f61"></stop>
            <stop offset="100%" stopColor="#af6130"></stop>
         </linearGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_191_106_52_0.5__0-rgba_255_142_70_0.5__62-rgba_255_210_181_0.5__70-rgba_255_142_70_0.5__78-rgba_191_106_52_0.5__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#bf6a34" stopOpacity="0.5"></stop>
            <stop offset="62%" stopColor="#ff8e46" stopOpacity="0.5"></stop>
            <stop offset="70%" stopColor="#ffd2b5" stopOpacity="0.5"></stop>
            <stop offset="78%" stopColor="#ff8e46" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#bf6a34" stopOpacity="0.5"></stop>
         </linearGradient>
         <radialGradient
            id="19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_179_210_89_1__0-rgba_132_176_0_1__100"
            cx="401"
            cy="258.5"
            r="304"
            fx="309.8"
            fy="404.42"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#b3d259"></stop>
            <stop offset="100%" stopColor="#84b000"></stop>
         </radialGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_104_139_0_1__0-rgba_156_196_38_1__20-rgba_197_221_128_1__35-rgba_156_196_38_1__50-rgba_95_128_0_1__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#688b00"></stop>
            <stop offset="20%" stopColor="#9cc426"></stop>
            <stop offset="35%" stopColor="#c5dd80"></stop>
            <stop offset="50%" stopColor="#9cc426"></stop>
            <stop offset="100%" stopColor="#5f8000"></stop>
         </linearGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_104_139_0_0.5__0-rgba_139_186_0_0.5__62-rgba_209_228_153_0.5__70-rgba_139_186_0_0.5__78-rgba_104_139_0_0.5__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#688b00" stopOpacity="0.5"></stop>
            <stop offset="62%" stopColor="#8bba00" stopOpacity="0.5"></stop>
            <stop offset="70%" stopColor="#d1e499" stopOpacity="0.5"></stop>
            <stop offset="78%" stopColor="#8bba00" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#688b00" stopOpacity="0.5"></stop>
         </linearGradient>
         <radialGradient
            id="19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_249_212_99_1__0-rgba_233_179_14_1__100"
            cx="401"
            cy="258.5"
            r="304"
            fx="309.8"
            fy="404.42"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#f9d463"></stop>
            <stop offset="100%" stopColor="#e9b30e"></stop>
         </radialGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_184_141_11_1__0-rgba_247_199_51_1__20-rgba_251_222_135_1__35-rgba_247_199_51_1__50-rgba_169_130_10_1__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#b88d0b"></stop>
            <stop offset="20%" stopColor="#f7c733"></stop>
            <stop offset="35%" stopColor="#fbde87"></stop>
            <stop offset="50%" stopColor="#f7c733"></stop>
            <stop offset="100%" stopColor="#a9820a"></stop>
         </linearGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_184_141_11_0.5__0-rgba_246_189_15_0.5__62-rgba_252_229_159_0.5__70-rgba_246_189_15_0.5__78-rgba_184_141_11_0.5__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#b88d0b" stopOpacity="0.5"></stop>
            <stop offset="62%" stopColor="#f6bd0f" stopOpacity="0.5"></stop>
            <stop offset="70%" stopColor="#fce59f" stopOpacity="0.5"></stop>
            <stop offset="78%" stopColor="#f6bd0f" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#b88d0b" stopOpacity="0.5"></stop>
         </linearGradient>
         <radialGradient
            id="19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_203_230_250_1__0-rgba_166_205_235_1__100"
            cx="401"
            cy="258.5"
            r="304"
            fx="309.8"
            fy="404.42"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#cbe6fa"></stop>
            <stop offset="100%" stopColor="#a6cdeb"></stop>
         </radialGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_131_162_186_1__0-rgba_187_222_249_1__20-rgba_215_236_252_1__35-rgba_187_222_249_1__50-rgba_120_149_171_1__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#83a2ba"></stop>
            <stop offset="20%" stopColor="#bbdef9"></stop>
            <stop offset="35%" stopColor="#d7ecfc"></stop>
            <stop offset="50%" stopColor="#bbdef9"></stop>
            <stop offset="100%" stopColor="#7895ab"></stop>
         </linearGradient>
         <linearGradient
            id="19-360_97_0_705_0_userSpaceOnUse_-rgba_131_162_186_0.5__0-rgba_175_216_248_0.5__62-rgba_223_240_252_0.5__70-rgba_175_216_248_0.5__78-rgba_131_162_186_0.5__100"
            x1="97"
            x2="705"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0%" stopColor="#83a2ba" stopOpacity="0.5"></stop>
            <stop offset="62%" stopColor="#afd8f8" stopOpacity="0.5"></stop>
            <stop offset="70%" stopColor="#dff0fc" stopOpacity="0.5"></stop>
            <stop offset="78%" stopColor="#afd8f8" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#83a2ba" stopOpacity="0.5"></stop>
         </linearGradient>
      </defs>
      <g className="raphael-group-20-background">
         <rect width="802" height="493" fill="#FFF" rx="0" ry="0"></rect>
         <rect
            width="802"
            height="493"
            fill="none"
            stroke="#666"
            strokeWidth="0"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-21-dataset">
         <g className="raphael-group-26-bottom-border">
            <path
               fill="#588526"
               fillOpacity="0.5"
               stroke="#000"
               strokeWidth="0"
               d="M705 258.5a304 121.6 0 01-32.142 54.42L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="none"
               stroke="#68913B"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M705 258.5a304 121.6 0 01-32.142 54.42L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="#8E468E"
               fillOpacity="0.5"
               stroke="#000"
               strokeWidth="0"
               d="M672.858 312.92a304 121.6 0 01-233.114 66.188L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="none"
               stroke="#995899"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M672.858 312.92a304 121.6 0 01-233.114 66.188L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="#D64646"
               fillOpacity="0.5"
               stroke="#000"
               strokeWidth="0"
               d="M439.744 379.108a304 121.6 0 01-332.791-89.748L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="none"
               stroke="#DA5858"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M439.744 379.108a304 121.6 0 01-332.791-89.748L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="#008E8E"
               fillOpacity="0.5"
               stroke="#000"
               strokeWidth="0"
               d="M106.953 289.36a304 121.6 0 0155.842-106.41L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="none"
               stroke="#199999"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M106.953 289.36a304 121.6 0 0155.842-106.41L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="#FF8E46"
               fillOpacity="0.5"
               stroke="#000"
               strokeWidth="0"
               d="M162.795 182.95a304 121.6 0 0132.015-13.805L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="none"
               stroke="#FF9958"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M162.795 182.95a304 121.6 0 0132.015-13.805L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="#8BBA00"
               fillOpacity="0.5"
               stroke="#000"
               strokeWidth="0"
               d="M194.81 169.145a304 121.6 0 01434.902 9.248L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="none"
               stroke="#96C119"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M194.81 169.145a304 121.6 0 01434.902 9.248L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="#F6BD0F"
               fillOpacity="0.5"
               stroke="#000"
               strokeWidth="0"
               d="M629.712 178.393A304 121.6 0 01665.84 198.8L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="none"
               stroke="#F7C327"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M629.712 178.393A304 121.6 0 01665.84 198.8L401 258.5z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="#AFD8F8"
               fillOpacity="0.5"
               stroke="#000"
               strokeWidth="0"
               d="M665.84 198.8A304 121.6 0 01705 258.477l-304 .023z"
               transform="translate(0 15)"
            ></path>
            <path
               fill="none"
               stroke="#B7DCF8"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M665.84 198.8A304 121.6 0 01705 258.477l-304 .023z"
               transform="translate(0 15)"
            ></path>
         </g>
         <g
            stroke="#000"
            strokeWidth="0"
            className="raphael-group-27-outer-back-Side"
         >
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_66_99_28_0.5__0-rgba_88_133_38_0.5__62-rgba_188_206_168_0.5__70-rgba_88_133_38_0.5__78-rgba_66_99_28_0.5__100')"
               d="M401 136.9v258.2z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_106_52_106_0.5__0-rgba_142_70_142_0.5__62-rgba_210_181_210_0.5__70-rgba_142_70_142_0.5__78-rgba_106_52_106_0.5__100')"
               d="M401 136.9v258.2z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_160_52_52_0.5__0-rgba_214_70_70_0.5__62-rgba_239_181_181_0.5__70-rgba_214_70_70_0.5__78-rgba_160_52_52_0.5__100')"
               d="M401 136.9v258.2z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_0_106_106_0.5__0-rgba_0_142_142_0.5__62-rgba_153_210_210_0.5__70-rgba_0_142_142_0.5__78-rgba_0_106_106_0.5__100')"
               d="M97 258.5a304 121.6 0 0165.795-75.55v15A304 121.6 0 0097 273.5z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_191_106_52_0.5__0-rgba_255_142_70_0.5__62-rgba_255_210_181_0.5__70-rgba_255_142_70_0.5__78-rgba_191_106_52_0.5__100')"
               d="M162.795 182.95a304 121.6 0 0132.015-13.805v15a304 121.6 0 00-32.015 13.804z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_104_139_0_0.5__0-rgba_139_186_0_0.5__62-rgba_209_228_153_0.5__70-rgba_139_186_0_0.5__78-rgba_104_139_0_0.5__100')"
               d="M194.81 169.145a304 121.6 0 01434.902 9.248v15a304 121.6 0 00-434.902-9.248z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_184_141_11_0.5__0-rgba_246_189_15_0.5__62-rgba_252_229_159_0.5__70-rgba_246_189_15_0.5__78-rgba_184_141_11_0.5__100')"
               d="M629.712 178.393A304 121.6 0 01665.84 198.8v15a304 121.6 0 00-36.128-20.407z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_131_162_186_0.5__0-rgba_175_216_248_0.5__62-rgba_223_240_252_0.5__70-rgba_175_216_248_0.5__78-rgba_131_162_186_0.5__100')"
               d="M665.84 198.8A304 121.6 0 01705 258.477v15a304 121.6 0 00-39.16-59.677z"
            ></path>
         </g>
         <g className="raphael-group-28-slicingWalls-back-Side">
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_104_139_0_1__0-rgba_156_196_38_1__20-rgba_197_221_128_1__35-rgba_156_196_38_1__50-rgba_95_128_0_1__100')"
               stroke="#000"
               strokeWidth="0"
               d="M401 136.9v258.2z"
            ></path>
            <path
               fill="#6F9400"
               stroke="#96C119"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M194.81 169.145v15L401 273.5v-15z"
            ></path>
            <path
               fill="#CC7138"
               stroke="#FF9958"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M194.81 169.145v15L401 273.5v-15z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_191_106_52_1__0-rgba_255_159_97_1__20-rgba_255_199_163_1__35-rgba_255_159_97_1__50-rgba_175_97_48_1__100')"
               stroke="#000"
               strokeWidth="0"
               d="M401 136.9v258.2z"
            ></path>
            <path
               fill="#CC7138"
               stroke="#FF9958"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M162.795 182.95v15L401 273.5v-15z"
            ></path>
            <path
               fill="#007171"
               stroke="#199999"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M162.795 182.95v15L401 273.5v-15z"
            ></path>
            <path
               fill="#6F9400"
               stroke="#96C119"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M629.712 178.393v15L401 273.5v-15z"
            ></path>
            <path
               fill="#C4970C"
               stroke="#F7C327"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M629.712 178.393v15L401 273.5v-15z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_184_141_11_1__0-rgba_247_199_51_1__20-rgba_251_222_135_1__35-rgba_247_199_51_1__50-rgba_169_130_10_1__100')"
               stroke="#000"
               strokeWidth="0"
               d="M401 136.9v258.2z"
            ></path>
            <path
               fill="#C4970C"
               stroke="#F7C327"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M665.84 198.8v15L401 273.5v-15z"
            ></path>
            <path
               fill="#8CACC6"
               stroke="#B7DCF8"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M665.84 198.8v15L401 273.5v-15z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_131_162_186_1__0-rgba_187_222_249_1__20-rgba_215_236_252_1__35-rgba_187_222_249_1__50-rgba_120_149_171_1__100')"
               stroke="#000"
               strokeWidth="0"
               d="M401 136.9v258.2z"
            ></path>
            <path
               fill="#8CACC6"
               stroke="#B7DCF8"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M705 258.477v15l-304 .023v-15z"
            ></path>
         </g>
         <g className="raphael-group-31-slicingWalls-front-Side">
            <path
               fill="#466A1E"
               stroke="#68913B"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M705 258.5v15H401v-15z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_66_99_28_1__0-rgba_113_151_70_1__20-rgba_172_194_147_1__35-rgba_113_151_70_1__50-rgba_60_91_26_1__100')"
               stroke="#000"
               strokeWidth="0"
               d="M705 258.5a304 121.6 0 01-32.142 54.42v15A304 121.6 0 00705 273.5z"
            ></path>
            <path
               fill="#466A1E"
               stroke="#68913B"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M672.858 312.92v15L401 273.5v-15z"
            ></path>
            <path
               fill="#713871"
               stroke="#995899"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M672.858 312.92v15L401 273.5v-15z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_106_52_106_1__0-rgba_159_97_159_1__20-rgba_199_163_199_1__35-rgba_159_97_159_1__50-rgba_97_48_97_1__100')"
               stroke="#000"
               strokeWidth="0"
               d="M672.858 312.92a304 121.6 0 01-233.114 66.188v15a304 121.6 0 00233.114-66.189z"
            ></path>
            <path
               fill="#713871"
               stroke="#995899"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M439.744 379.108v15L401 273.5v-15z"
            ></path>
            <path
               fill="#AB3838"
               stroke="#DA5858"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M439.744 379.108v15L401 273.5v-15z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_0_106_106_1__0-rgba_38_159_159_1__20-rgba_128_199_199_1__35-rgba_38_159_159_1__50-rgba_0_97_97_1__100')"
               stroke="#000"
               strokeWidth="0"
               d="M106.953 289.36A304 121.6 0 0197 258.5v15a304 121.6 0 009.953 30.86z"
            ></path>
            <path
               fill="#007171"
               stroke="#199999"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M106.953 289.36v15L401 273.5v-15z"
            ></path>
            <path
               fill="#AB3838"
               stroke="#DA5858"
               strokeOpacity="0.8"
               strokeWidth="0.1"
               d="M106.953 289.36v15L401 273.5v-15z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_160_52_52_1__0-rgba_220_97_97_1__20-rgba_235_163_163_1__35-rgba_220_97_97_1__50-rgba_147_48_48_1__100')"
               stroke="#000"
               strokeWidth="0"
               d="M439.744 379.108a304 121.6 0 01-332.791-89.748v15a304 121.6 0 00332.791 89.748z"
            ></path>
         </g>
         <g stroke="#000" strokeWidth="0" className="raphael-group-32-top-Side">
            <path
               fill="url('#19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_146_176_114_1__0-rgba_83_126_36_1__100')"
               d="M705 258.5a304 121.6 0 01-32.142 54.42L401 258.5z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_255_255_255_0__0-rgba_255_255_255_0.16666666666666669__20-rgba_255_255_255_0.33333333333333337__35-rgba_255_255_255_0.16666666666666669__50-rgba_255_255_255_0__100')"
               d="M704 258.5a303 120.6 0 01-32.036 53.972l.894.447v1A304 121.6 0 00705 259.5v-1z"
            ></path>
            <path
               fill="url('#19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_181_135_181_1__0-rgba_134_66_134_1__100')"
               d="M672.858 312.92a304 121.6 0 01-233.114 66.188L401 258.5z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_255_255_255_0__0-rgba_255_255_255_0.16666666666666669__20-rgba_255_255_255_0.33333333333333337__35-rgba_255_255_255_0.16666666666666669__50-rgba_255_255_255_0__100')"
               d="M671.964 312.472a303 120.6 0 01-232.347 65.645l.127.991v1a304 121.6 0 00233.114-66.189v-1z"
            ></path>
            <path
               fill="url('#19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_228_135_135_1__0-rgba_203_66_66_1__100')"
               d="M439.744 379.108a304 121.6 0 01-332.791-89.748L401 258.5z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_255_255_255_0__0-rgba_255_255_255_0.16666666666666669__20-rgba_255_255_255_0.33333333333333337__35-rgba_255_255_255_0.16666666666666669__50-rgba_255_255_255_0__100')"
               d="M439.617 378.117a303 120.6 0 01-331.697-89.01l-.967.253v1a304 121.6 0 00332.791 89.748v-1z"
            ></path>
            <path
               fill="url('#19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_89_181_181_1__0-rgba_0_134_134_1__100')"
               d="M106.953 289.36a304 121.6 0 0155.842-106.41L401 258.5z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_255_255_255_0__0-rgba_255_255_255_0.16666666666666669__20-rgba_255_255_255_0.33333333333333337__35-rgba_255_255_255_0.16666666666666669__50-rgba_255_255_255_0__100')"
               d="M107.92 289.107A303 120.6 0 01163.58 183.57l-.784-.622v1a304 121.6 0 00-55.842 106.411v-1z"
            ></path>
            <path
               fill="url('#19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_255_181_135_1__0-rgba_242_134_66_1__100')"
               d="M162.795 182.95a304 121.6 0 0132.015-13.805L401 258.5z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_255_255_255_0__0-rgba_255_255_255_0.16666666666666669__20-rgba_255_255_255_0.33333333333333337__35-rgba_255_255_255_0.16666666666666669__50-rgba_255_255_255_0__100')"
               d="M163.579 183.57a303 120.6 0 0131.909-13.69l-.678-.735v1a304 121.6 0 00-32.015 13.804v-1z"
            ></path>
            <path
               fill="url('#19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_179_210_89_1__0-rgba_132_176_0_1__100')"
               d="M194.81 169.145a304 121.6 0 01434.902 9.248L401 258.5z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_255_255_255_0__0-rgba_255_255_255_0.16666666666666669__20-rgba_255_255_255_0.33333333333333337__35-rgba_255_255_255_0.16666666666666669__50-rgba_255_255_255_0__100')"
               d="M195.488 169.88a303 120.6 0 01433.472 9.172l.752-.659v1a304 121.6 0 00-434.902-9.248v-1z"
            ></path>
            <path
               fill="url('#19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_249_212_99_1__0-rgba_233_179_14_1__100')"
               d="M629.712 178.393A304 121.6 0 01665.84 198.8L401 258.5z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_255_255_255_0__0-rgba_255_255_255_0.16666666666666669__20-rgba_255_255_255_0.33333333333333337__35-rgba_255_255_255_0.16666666666666669__50-rgba_255_255_255_0__100')"
               d="M628.96 179.052a303 120.6 0 0136.01 20.239l.87-.491v1a304 121.6 0 00-36.128-20.407v-1z"
            ></path>
            <path
               fill="url('#19-xr_309.8_404.42_304_401_258.5_userSpaceOnUse_rgba_203_230_250_1__0-rgba_166_205_235_1__100')"
               d="M665.84 198.8A304 121.6 0 01705 258.477l-304 .023z"
            ></path>
            <path
               fill="url('#19-360_97_0_705_0_userSpaceOnUse_-rgba_255_255_255_0__0-rgba_255_255_255_0.16666666666666669__20-rgba_255_255_255_0.33333333333333337__35-rgba_255_255_255_0.16666666666666669__50-rgba_255_255_255_0__100')"
               d="M664.97 199.29A303 120.6 0 01704 258.479h1v1a304 121.6 0 00-39.16-59.678v-1z"
            ></path>
         </g>
         <text
            x="731.186"
            y="299.477"
            fill="#49563A"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="731.186" dy="4">
               WY, 9
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M696.855 293.959l24.33 5.518h5"
         ></path>
         <text
            x="601.436"
            y="384.729"
            fill="#49563A"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="601.436" dy="4.002">
               WA, 19
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M576.965 365.158l14.47 19.571h5"
         ></path>
         <text
            x="207.082"
            y="386.725"
            fill="#49563A"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="207.082" dy="3.998">
               OR, 28
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M231.058 366.825l-13.976 19.9h-5"
         ></path>
         <text
            x="69.017"
            y="228.589"
            fill="#49563A"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="69.017" dy="4.003">
               NM, 18
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M103.484 233.52l-24.467-4.931h-5"
         ></path>
         <text
            x="149.806"
            y="159.477"
            fill="#49563A"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="149.806" dy="4">
               MT, 3
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M178.133 175.8l-18.327-16.323h-5"
         ></path>
         <text
            x="428.465"
            y="113.105"
            fill="#49563A"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="428.465" dy="4.004">
               ID, 31
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M417.138 137.071l1.327-23.966h5"
         ></path>
         <text
            x="679.493"
            y="174.354"
            fill="#49563A"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="679.493" dy="4.002">
               CA, 4
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M649.091 188.224l20.402-13.87h5"
         ></path>
         <text
            x="729.229"
            y="221.549"
            fill="#49563A"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="729.229" dy="3.994">
               AK, 10
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M695.047 227.64l24.182-6.091h5"
         ></path>
      </g>
      <g className="raphael-group-177-caption">
         <text
            x="401"
            y="16"
            fill="#49563A"
            className="fusioncharts-caption"
            fontFamily="Verdana, sans"
            fontSize="13"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="401" dy="12.992">
               Chart Caption
            </tspan>
         </text>
         <text
            x="401"
            y="34"
            fill="#49563A"
            className="fusioncharts-subcaption"
            fontFamily="Verdana, sans"
            fontSize="11"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="401" dy="10.852">
               Chart Sub Caption
            </tspan>
         </text>
      </g>
   </svg>)
};

export default Pie3DChartIcon;