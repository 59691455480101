/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import BarChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/BarChartIcon';
import Bar3DChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/Bar3DChartIcon';
import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../reducers';
import { FusionChartTypes } from '../../common/JrxmlModel/reader/JrxmlFusionChartUtils';
import ColumnChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/ColumnChartIcon';
import Column3DChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/Column3DChartIcon';
import LineChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/LineChartIcon';
import AreaChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/AreaChartIcon';
import PieChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/PieChartIcon';
import Pie3DChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/Pie3DChartIcon';
import DoughnutChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/DoughnutChartIcon';
import Doughnut3DChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/Doughnut3DChartIcon';
import StackedColumnChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/StackedColumnChartIcon';
import StackedColumn3DChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/StackedColumn3DChartIcon';
import StackedBarChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/StackedBarChartIcon';
import StackedBar3DChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/StackedBar3DChartIcon';
import StackedAreaChartIcon from '@jss/js-common/src/svg/elements/fusioncharts/StackedAreaChartIcon';

interface IChartElement {
  id: string;
  element?: any;
  zoom?: number;
  availableWidth: number,
  availableHeight: number,
}

const visualProperties = ["chartType"];

class ChartElement extends React.Component<IChartElement> {

  public shouldComponentUpdate = (nextProps: Readonly<IChartElement>) => {
    if (this.props.availableHeight !== nextProps.availableHeight || this.props.availableWidth !== nextProps.availableWidth) {
      return true;
    }

    if (this.props.zoom !== nextProps.zoom) {
      return true;
    }
    for (const propName of visualProperties) {
      if (this.props.element.get(propName) !== nextProps.element.get(propName)) {
        return true;
      }
    }

    return false; // Nothing impacting the content of the figure is changed...
  }

  public render() {
    let image = <BarChartIcon width='100%' height='100%' preserveAspectRatio='none' />;
    const chartType = this.props.element.get("chartType");
    if (chartType === FusionChartTypes.BAR_CHART_NAME) {
      image = <BarChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.BAR3D_CHART_NAME) {
      image = <Bar3DChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.COLUMN_CHART_NAME) {
      image = <ColumnChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.COLUMN3D_CHART_NAME) {
      image = <Column3DChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.LINE_CHART_NAME) {
      image = <LineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.AREA_CHART_NAME) {
      image = <AreaChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.PIE_CHART_NAME) {
      image = <PieChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.PIE3D_CHART_NAME) {
      image = <Pie3DChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.DOUGHNUT_CHART_NAME) {
      image = <DoughnutChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.DOUGHNUT3D_CHART_NAME) {
      image = <Doughnut3DChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.STACKED_COLUMN_CHART_NAME) {
      image = <StackedColumnChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.STACKED3D_COLUMN_CHART_NAME) {
      image = <StackedColumn3DChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.STACKED_BAR_CHART_NAME) {
      image = <StackedBarChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.STACKED_BAR3D_CHART_NAME) {
      image = <StackedBar3DChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === FusionChartTypes.STACKED_AREA_CHART_NAME) {
      image = <StackedAreaChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    }
    return <div style={{ width: '100%', height: '100%' }}>
      {image}
    </div>;
  }
}

const mapStateToProps = (state: IState, props: IChartElement) => {

  return {
    element: state.getIn(['report', 'model', 'elements', props.id]),
    model: state.getIn(['report', 'model']),
    zoom: state.getIn(['report', 'zoom']),
  };
}

export default connect(mapStateToProps)(ChartElement);   // ,mapDispatchToProps