/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { Select } from "@jss/js-common";
import { getKey, getPath, reg, UiPropertyProps } from '../UiProperty';
import { APDescriptor } from '../APDescriptor';
import i18n from '../../../../../i18n';

const TYPEID = 'imbplementationCombo';
reg(TYPEID, (mc) => { return <UPImplementationCombo mcontext={mc} />; });
export class PImplementationCombo extends APDescriptor {
    types?: APDescriptor[];
    default?: string;
    public constructor(init: Partial<PImplementationCombo>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
export class UPImplementationCombo extends React.Component<UiPropertyProps> {

    render() {
        const d = this.props.mcontext.descriptor as PImplementationCombo;
        const items: { key: string; value: string; }[] = d.types?.map(k => {
            return { key: k.type, value: k.label }
        });

        const p = getPath('type', this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p) as string;

        return <Select key={getKey(p)} items={items}
            className={d.className}
            style={d.style}
            value={v}
            onItemSelected={str => this.onNameChange(str, v)}
            label={i18n.t('Implementation')}
            inline={true}
            size={'small'}
        />
    }
    public onNameChange = (newv: string, oldv: string) => {
        if (oldv === newv)
            return;
        const d = this.props.mcontext.descriptor as PImplementationCombo;
        const dt = d.types?.find(k => k.type === newv);

        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, {...dt.default,  type: dt.type });
             
        });
    }

}