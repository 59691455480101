/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { MBarbecue } from "./MBarbecue";

export const MUCC128: APDescriptor = {
    ...MBarbecue, id: 'UCC128', label: 'UCC128', icon: ICONS.BARCODE_USPS_ICON,
    default: {
        ...MBarbecue.default, barbecue_type: 'UCC128',
        codeExpression: '"1234"',
        applicationIdentifierExpression: '"00"',
        width: 100, height: 30
    }
};
