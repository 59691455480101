export const it = {
  "dataAdaptersView.basename.text": "Nuovo Data Adapter",
  "dataAdaptersView.tabs.editor": "Editor",
  "dataAdaptersView.tabs.source": "Sorgente",
  "home.buttons.github.login": "Login con GitHub",
  "home.buttons.jrs.login": "Login con JasperReports Server",
  "repositoryView.contextual.action.run": "Esegui",
  "repositoryView.create.menu.newreport": "Nuovo Report",
  "repositoryView.create.menu.newda": "Nuovo Data Adapter",
  "repositoryView.create.menu.newfolder": "Nuova Cartella",
  "languageselector.combo.language": "Italiano",
  "appheader.selector.theme.text": "Tema:",
  "appheader.selector.lang.text": "Lingua:",
  "appheader.button.logout": "Esci"
}