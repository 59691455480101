/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import '../../assets/uxpl/css/TextInput.css';

interface INumericInput {
  id?: string;
  label?: string;
  help?: string;
  placeholder?: string;
  postfix?: string;
  onChangeComplete?: (num: number) => void;
  step?: number;
  min?: number;
  max?: number;
  value?: string|number|null;
  wrapperStyles?: React.CSSProperties;
  inputStyles?: React.CSSProperties;
  classes?: string;
  disabled?: boolean;
}


interface INumericInputState {
  value: string|undefined,         // string representation of the value (what is shown in the input field)
  originalValue: string|undefined, // the value set from outside
  internal: boolean // defines if the value was last set from this class or passed as property from the outside
  hasFocus: boolean
}


export class NumericInput extends React.Component<INumericInput> {

  
    /**
     * As soon the props are passed, we save the value and adjust the internal state.
     * @param nextProps
     * @param prevState 
     */
    public static getDerivedStateFromProps(nextProps: INumericInput, prevState: INumericInputState){

      if (prevState.internal) {
        return { internal: false };
      }

      return { 
        value: nextProps.value === 0 ? '0' : nextProps.value,
        originalValue: nextProps.value,
      };
    }

    public state: INumericInputState = {
      value: undefined,
      originalValue: undefined,
      internal: false,
      hasFocus: false
    }


    private inputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();


    public render() {

        const wrapperClasses = ['tc-form-div'];
        const inputClasses = ['tc-form'];
        if (this.props.disabled === true) {
          wrapperClasses.push('disabled');
          inputClasses.push('disabled');
        }

        let str = this.state.value ? '' + this.state.value : '';

        if (str.length > 0 && !this.state.hasFocus && this.props.postfix) {
            str += ' ' + this.props.postfix;
        }
        
        return (
          <div className={wrapperClasses.join(' ')} style={ this.props.wrapperStyles ? this.props.wrapperStyles : {}}>
                  <input 
                        id={this.props.id}
                        ref={ this.inputRef }
                        type='text'
                        value={str}
                        className="tc-form"
                        disabled={this.props.disabled === true}
                        onKeyDown={this.onKeyDown}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        placeholder={!this.state.hasFocus ? this.props.placeholder : undefined }
                        style={ this.props.inputStyles ? this.props.inputStyles : {fontSize: '1em'}}
                  />
                  { this.props.label ? <label>{ this.props.label }</label> : null}
                  { this.props.help ? <div className="tc-suggest" style={{ display: 'inline-block', width: 'auto'}}>{ this.props.help }</div> : null }
            </div>
        );
    }


    /**
     * Controls step increments via arrows up/down and confirmation with enter
     */
    private onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

          switch (e.keyCode) {

            case 38: // Up Arrow
              e.preventDefault();
              this.stepUpValue();
              break;
            
            case 40: // Up Arrow
              e.preventDefault();
              this.stepDownValue();
              break;

            case 13: // Enter
              e.preventDefault();
              if (this.inputRef.current) {
                this.inputRef.current.blur();
              }
              break;
          }
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.onValueChange( event.currentTarget.value );
    }

    /**
     * Internally update the current value
     */
    private onValueChange = (str: string) => {

        if (str === '') {
          this.setState({ value: str, internal: true });
          return;
        }
        
        if (!this.isValidNumber(str)) {
          this.setState({ value: this.state.value });
        }

        this.setState({ value: '' + parseInt(str, 10), internal: true });
    }

    
    private stepUpValue = () => {
        if (this.props.step) {

          const { value } = this.state;
          const max = (typeof this.props.max !== 'undefined') ? this.props.max : Infinity;

          if (typeof value !== 'undefined' && value !== null && this.isValidNumber(value)) {
              let newValue = this.valueAsNumber(value) + this.props.step;
              if (newValue > max) {
                newValue = max;
              }
              this.onValueChange('' + newValue );
          }
        }
    }

    private stepDownValue = () => {
      if (this.props.step) {

        const { value } = this.state;
        const min = (typeof this.props.min !== 'undefined') ? this.props.min : -Infinity;

        if (typeof value !== 'undefined' && value !== null && this.isValidNumber(value)) {
            let newValue = this.valueAsNumber(value) - this.props.step;
            if (newValue < min) {
              newValue = min;
            }
            this.onValueChange('' + newValue );
        }
      }
  }

  

  private isValidNumber = (str: string) => {
      // We check a valid number with a simple regular expression
      if (str === '') {
        return true;
      }

      return /[0-9]*/.test(str);
  }


  private valueAsNumber = (str: string|undefined) => {
    if (!str) {
        return 0;
    }

    const min = typeof this.props.min !== 'undefined' ? this.props.min : -Infinity;
    const max = typeof this.props.max !== 'undefined' ? this.props.max : Infinity;
    
    const v = parseInt(str, 10);
  
    if (isNaN(v)) {
      return 0;
    }
    else if (v < min) {
        return min;
    }
    else if ( v > max) {
        return max;
    }
    
    return v;
  }


  /**
   * We fire a change event only when the user has done...
   */
  private onBlur = () => {

    this.setState({ hasFocus: false });
    
    if (this.props.onChangeComplete) {

      if (this.state.value === this.state.originalValue) {
        return;
      }
      
      this.props.onChangeComplete( this.valueAsNumber( this.state.value ) );
    }
  }

  private onFocus = () => {
    this.setState({ hasFocus: true }, () => { if (this.inputRef.current) { this.inputRef.current.select() } });
  }
}
