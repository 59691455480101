/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../ui/APDescriptor";
import { PCategories } from "../../ui/composite/UPCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { MDivElementDataset } from "../dataset/MDatasetRun";
import { PValueExpression } from "../../ui/primitive/UPValueExpession";
import { PString } from "../../ui/primitive/UPString";
import { PInt } from "../../ui/primitive/UPInteger";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PEnum } from "../../ui/primitive/UPEnum";
import { PColor } from "../../ui/primitive/UPColor";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { PConnectedTableDetail } from "../../ui/tables/UPConnectedTableDetail";

export const FTibcoMapStyle = {
    name: new PValueExpression({ id: 'name', vtype: new PString({ id: "name", label: 'Name', description: 'Name of the style.' }) }),

    color: new PValueExpression({ id: 'color', vtype: new PColor({ id: "color", label: 'Color', description: 'he color of the shape stroke; can be represented as #-prefixed HTML color code or as predefined HTML color name' }) }),
    opacity: new PValueExpression({ id: 'opacity', vtype: new PInt({ id: "opacity", label: 'Opacity', description: '[0..1] opacity of the shape stroke', default: 0.5, min: 0, max: 1 }) }),
    weight: new PValueExpression({ id: 'weight', vtype: new PInt({ id: "weight", label: 'Weight', description: 'specifies the stroke weight in pixels', default: 1, min: 0 }) }),
    dashArray: new PValueExpression({ id: 'dashArray', vtype: new PString({ id: "dashArray", label: 'Dash Array', description: 'the dash array style pattern for the stroke' }) }),
    clickable: new PValueExpression({ id: 'clickable', vtype: new PBoolean({ id: "clickable", label: 'Clickable', description: 'specifies if the geometry handles click events', canBeNull: true, default: 'true' }) }),

    lineCap: new PValueExpression({
        id: 'lineCap', vtype: new PEnum({
            id: "lineCap", label: 'Line Cap', description: 'the line cap style',
            items: [{ key: "", value: "" }, { key: "butt", value: "Butt" }, { key: "round", value: "Round" }, { key: "square", value: "Square" },]
        })
    }),
    lineJoin: new PValueExpression({
        id: 'lineJoin', vtype: new PEnum({
            id: "lineJoin", label: 'Line Join', description: 'the line join style',
            items: [{ key: "", value: "" }, { key: "miter", value: "Miter" }, { key: "round", value: "Round" }, { key: "bevel", value: "Bevel" },]
        })
    }),


    stroke: new PValueExpression({ id: 'stroke', vtype: new PBoolean({ id: "stroke", label: 'Stroke', description: 'specifies whether strokes are used when drawing shapes', canBeNull: true, default: 'false' }) }),
    reverseCoordinates: new PValueExpression({ id: 'reverseCoordinates', vtype: new PBoolean({ id: "reverseCoordinates", label: 'Reverse Coordinates', description: 'specifies if the coordinates will be handled as reversed', canBeNull: true, default: 'false' }) }),
    isPolygon: new PValueExpression({ id: 'isPolygon', vtype: new PBoolean({ id: "isPolygon", label: 'Is Polygon', description: 'specifies whether the path is a polygon', canBeNull: true, default: 'false' }) }),


    fillColor: new PValueExpression({ id: 'fillColor', vtype: new PString({ id: "fillColor", label: 'Fill Color', description: 'the color of the shape fill; can be represented as #-prefixed HTML color code or as predefined HTML color name' }) }),
    fill: new PValueExpression({ id: 'fill', vtype: new PBoolean({ id: "fill", label: 'Fill', description: 'specifies if shapes are filled', canBeNull: true, default: 'true' }) }),
    fillOpacity: new PValueExpression({ id: 'fillOpacity', vtype: new PInt({ id: "fillOpacity", label: 'Fill Opacity', description: '[0..1] opacity of the shape fill', default: 0.2, min: 0, max: 1 }) })
};

export const MTibcoMapStyle: APDescriptor = new PCategories({
    id: 'tibcomap.styles',
    layouts: [
        new PDiv({
            id: 'tibcomap.styles.styles',
            label: 'Styles', layouts: [new PConnectedTableDetail({
                id: 'items', label: 'Styles', rowLabel: 'Style', rowLabelPaths: ['name/value', 'name/valueExpression'], expanded: true,
                layouts: [new PDiv({
                    label: 'Style',
                    layouts: [FTibcoMapStyle.name,
                    new PAccordeon({
                        id: 'tibcomap.style.stroke', label: "Stroke", layouts: [
                            FTibcoMapStyle.stroke,
                            FTibcoMapStyle.weight, FTibcoMapStyle.dashArray,
                            FTibcoMapStyle.lineJoin,
                            FTibcoMapStyle.color, FTibcoMapStyle.opacity,
                            FTibcoMapStyle.lineCap]
                    }),
                    new PAccordeon({
                        id: 'tibcomap.style.fill', label: "Fill", layouts: [FTibcoMapStyle.fill, FTibcoMapStyle.fillColor, FTibcoMapStyle.fillOpacity]
                    }),
                    FTibcoMapStyle.clickable, FTibcoMapStyle.reverseCoordinates, FTibcoMapStyle.isPolygon
                    ]
                })]
            })]
        }),
        { ...MDivElementDataset, path: 'dataset' }
    ]
});