/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../ui/APDescriptor";
import { OrderEnum } from "../enums/OrderEnum";
import { SortFieldTypeEnum } from "../enums/SortFieldTypeEnum";

import { PTableElement } from "../../ui/tables/UPTableElement";
import SortFieldIcon from "../../../../../assets/icons/outline/sort-fields.svg";
import { DsObject, PDsObject } from "./UPDsObject";

export const FSortField = {
    name: new PDsObject({ id: 'name', label: 'Name', types: [DsObject.FIELD, DsObject.VARIABLE], writeable: false }),
    type: SortFieldTypeEnum,
    order: OrderEnum,

}

export const MSortField: APDescriptor = new PTableElement({
    label: 'Sort Field', icon: SortFieldIcon,
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    layouts: [FSortField.name, FSortField.type, FSortField.order]
});
