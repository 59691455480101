/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import '../../../assets/uxpl/css/Page.css';
import { IState } from '../../../reducers';
import * as DocumentUtils from '../../../sagas/report/designer/documentUtils';
import { ElementTypes } from '../../../sagas/report/document/elementTypes';
import CrosstabPreviewElement from '../crosstab/CrosstabPreviewElement';
import BaseElement from '../elements/BaseElement';
import { PositionFinderContext } from '../hoc/positionFinder';
import TablePreviewElement from '../table/TablePreviewElement';
import ListFeedbackElement from './ListFeedbackElement';
import ListPreviewElement from './ListPreviewElement';
import { computeListRealSize } from './ListUtils';

interface IPage {
  model?: Map<string, any>;
  report?: Map<string, any>;
  state?: IState;
  modelActions?: List<any>;
}

/**
 * The Document component manage the display of the main document and the grid.
 * It acts as container for all the report content.
 *
 * @class Document
 * @extends {React.Component<IDocument>}
 */
class ListPage extends React.Component<IPage> {


  public positionFinder: DocumentUtils.IPositionFinder;


  constructor(props: IPage) {
    super(props);
    this.positionFinder = DocumentUtils.createObjectAbsolutePositionFinder(props.state);
  }

  public shouldComponentUpdate = (nextProps: IPage) => {
    if (nextProps.modelActions.size !== this.props.modelActions.size) {
      this.positionFinder = DocumentUtils.createObjectAbsolutePositionFinder(nextProps.state);
    }
    return true;
  }

  getListChildren = (currentNode): Map<string, any>[] => {
    const currentChildren = [];
    const children = currentNode.get('elementIds');
    if (children) {
      children.forEach(childId => {
        const child = this.props.model.getIn(['elements', childId]);
        currentChildren.push(child);
        const subchildren = this.getListChildren(child);
        currentChildren.push(...subchildren);
      });
    }
    return currentChildren;
  }

  public render() {
    const currentEditorIndex = this.props.report.getIn(['currentEditorIndex']);
    const currentEditor = this.props.report.getIn(['subeditors', currentEditorIndex]) as Map<string, any>;
    const listPath = currentEditor.get('editedResourceId').split('/');
    const listModel = this.props.report.getIn(['model', ...listPath]) as Map<string, any> | undefined;
    if (!this.props.model || !this.positionFinder || !listModel) {
      return null;
    }
    const mainListId = listModel.get('id');
    const zoom = currentEditor.get('zoom', 1);

    const modelRect = computeListRealSize(listModel, this.props.model);
    const childrenToRender = this.getListChildren(listModel);
    childrenToRender.push(listModel);


    const renderedElements = childrenToRender.map((ele: Map<string, any>) => {
      const id: string = ele.get('id');
      if (id) {
        if (id === mainListId) {
          return (<ListFeedbackElement zoom={zoom} key={id} id={id} />);
        } else {
          const type = ele.get('type');
          if (type === ElementTypes.TABLE) {
            return <TablePreviewElement zoom={zoom} key={id} id={id} />
          } else if (type === ElementTypes.LIST) {
            return <ListPreviewElement zoom={zoom} key={id} id={id} />
          } else if (type === ElementTypes.CROSSTAB) {
            return <CrosstabPreviewElement zoom={zoom} key={id} id={id} />
          } else {
            return (<BaseElement zoom={zoom} key={id} id={id} />);
          }
        }
      }
    });
    // }

    const documentStyle = { width: 0, height: 0 };

    if (modelRect) {
      documentStyle.width = modelRect.width * zoom;
      documentStyle.height = modelRect.height * zoom;
    }

    return (
      <PositionFinderContext.Provider value={this.positionFinder}>
        <div className="Page" style={documentStyle}>
          <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style={{ ...documentStyle, position: 'relative' }}>
            <defs>
              <pattern id="smallGrid" width="10" height="10" patternUnits="userSpaceOnUse">
                <path d="M 10 0 L 0 0 0 10" fill="none" stroke="#dedede" strokeWidth={0.5 / zoom} />
              </pattern>
              <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
                <rect width="100" height="100" fill="url(#smallGrid)" />
                <path d="M 100 0 L 0 0 0 100" fill="none" stroke="#dedede" strokeWidth={1 / zoom} />
              </pattern>
            </defs>

            <rect width="100%" height="100%" fill="url(#grid)" />
          </svg>
          {renderedElements}
        </div>
      </PositionFinderContext.Provider>
    );
  }
}

const mapStateToProps = (state: IState) => {

  return {
    state: state,
    modelActions: state.getIn(['report', 'modelActions']),
    model: state.getIn(['report', 'model']),
    report: state.getIn(['report']),
  };
}


export default connect(mapStateToProps)(ListPage);
