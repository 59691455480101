/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const FooterPositionEnum = new PEnum({
    id: 'footerPosition', label: 'FooterPosition.label', default: 'Normal',
    items: [
        { key: 'Normal', value: 'FooterPosition.Normal' },
        { key: 'StackAtBottom', value: 'FooterPosition.StackAtBottom' },
        { key: 'ForceAtBottom', value: 'FooterPosition.ForceAtBottom' },
        { key: 'CollateAtBottom', value: 'FooterPosition.CollateAtBottom' }
    ]
});

