/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { fromJS } from 'immutable';
import { put, select } from 'redux-saga/effects';

import { IState } from '../../../../reducers';

import { IPoint } from '../../../../types/geometry';

import * as ReportActions from '../../../../actions/reportActions';
import { getEditorIndex } from '../../../../reducers/report/reportReducer';


/**
 * This function manage the pan of the document (touch or shift + mouse drag)
 * 
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function* handlePan(point: IPoint, options: any, commit = false) {

  const state = yield select( (s: IState) => s);
  // Let's get the mouse and model from the state....
  const currentEditorIndex = getEditorIndex(state.get('report'));
  let mouse;
  if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
    const currentEditor = state.getIn(['report', 'subeditors', currentEditorIndex]);
    mouse = currentEditor.get('mouse');
  } else {
    mouse = state.getIn(['report','mouse']);
  }
  const model = state.getIn(['report','model']);

  if (mouse.get('operation') !== ReportActions.DragOperations.DRAG_OP_PAN ||
      model == null) {
      return; // Nothing to do...
  }


  const currentLocation = point;
  const startLocation = mouse.get('startLocation').toJS();

  const delta = { x: currentLocation.x - startLocation.x,
                  y: currentLocation.y - startLocation.y};

  yield put( ReportActions.updateDragOperation(fromJS(currentLocation), true) );

  if (delta.y) {
    yield put( ReportActions.scrollY( delta.y ));
  }
  
  if (delta.x) {
    yield put( ReportActions.scrollX( delta.x ));
  }

  
}


