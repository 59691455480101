/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const Pie3DChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M5 0v135.375h260V0z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M1.5 141.875L268.5 141.875" clipPath="url(#clipPath1)" />
            <path fill="none" d="M1.5 173.5L268.5 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M1.5 173.5L1.5 141.875" clipPath="url(#clipPath1)" />
            <path fill="none" d="M268.5 173.5L268.5 141.875" clipPath="url(#clipPath1)" />
            <path fill="#F55" stroke="none" d="M13 150.031a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
         </g>
         <g fill="gray" stroke="gray" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M13 150.031a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <text x="17" y="152.801" fill="#000" stroke="none" clipPath="url(#clipPath1)">Argentina 598,58</text>
            <path fill="#55F" stroke="none" d="M100.7 150.031a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <path fill="none" d="M100.7 150.031a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <text x="104.699" y="152.801" fill="#000" stroke="none" clipPath="url(#clipPath1)">Austria 7.391,5</text>
            <path fill="#5F5" stroke="none" d="M180.04 150.031a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <path fill="none" d="M180.04 150.031a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <text x="184.039" y="152.801" fill="#000" stroke="none" clipPath="url(#clipPath1)">Belgium 1.280,14</text>
            <path fill="#FF5" stroke="none" d="M13 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <path fill="none" d="M13 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <text x="17" y="168.113" fill="#000" stroke="none" clipPath="url(#clipPath1)">Brazil 4.880,19</text>
            <path fill="#F5F" stroke="none" d="M91.492 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <path fill="none" d="M91.492 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <text x="95.492" y="168.113" fill="#000" stroke="none" clipPath="url(#clipPath1)">Canada 2.198,09</text>
            <path fill="#F55" stroke="none" d="M135 34.251a64.642 44.436 0 0114.74 1.171L135 78.688z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M135 34.251a64.642 44.436 0 0114.74 1.171L135 78.688z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="silver" stroke="none" d="M135 78L135 56 135 12 135 34z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M135 78L135 56 135 12 135 34z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#55F" stroke="none" d="M149.74 35.422a64.642 44.436 0 01-10.168 87.59L135 78.687z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M149.74 35.422a64.642 44.436 0 01-10.168 87.59L135 78.687z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="silver" stroke="none" d="M135 78L135 56 149 13 149 35z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M135 78L135 56 149 13 149 35z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#5F5" stroke="none" d="M139.572 123.012a64.642 44.436 0 01-31.002-3.772L135 78.688z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M139.572 123.012a64.642 44.436 0 01-31.002-3.772L135 78.688z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="silver" stroke="none" d="M135 78L135 56 139 101 139 123z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M135 78L135 56 139 101 139 123z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#FF5" stroke="none" d="M108.57 119.24a64.642 44.436 0 01-21.912-70.053L135 78.687z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M108.57 119.24a64.642 44.436 0 01-21.912-70.053L135 78.687z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="silver" stroke="none" d="M135 78L135 56 108 97 108 119z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M135 78L135 56 108 97 108 119z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#F5F" stroke="none" d="M86.658 49.187A64.642 44.436 0 01135 34.25v44.437z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M86.658 49.187A64.642 44.436 0 01135 34.25v44.437z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="silver" stroke="none" d="M135 78L135 56 86 27 86 49z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M135 78L135 56 86 27 86 49z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path stroke="none" d="M135 12.251c-35.701 0-64.642 19.895-64.642 44.436v22c0-24.54 28.94-44.436 64.642-44.436 35.701 0 64.643 19.895 64.643 44.437v-22c0-24.542-28.942-44.437-64.643-44.437z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path stroke="none" d="M70.358 56.688v22c0 24.54 28.94 44.436 64.642 44.436 35.701 0 64.643-19.895 64.643-44.436v-22c0 24.54-28.942 44.436-64.643 44.436S70.358 81.229 70.358 56.688z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#F55" stroke="none" d="M135 12.251v22c5.071 0 10.006.402 14.74 1.16v-22a93.181 93.181 0 00-14.74-1.16z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M135 12.251v22c5.071 0 10.006.402 14.74 1.16h0v-22h0a93.181 93.181 0 00-14.74-1.16z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#55F" stroke="none" d="M149.74 13.412v22c28.599 4.585 49.903 22.22 49.903 43.276v-22c0-21.056-21.304-38.691-49.903-43.276z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M149.74 13.412v22h0c28.599 4.585 49.903 22.22 49.903 43.276v-22c0-21.056-21.304-38.691-49.903-43.276z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#FF5" stroke="none" d="M86.658 27.186c-10.14 7.847-16.3 18.18-16.3 29.502v22c0-11.322 6.16-21.655 16.3-29.502v-22z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M86.658 27.186c-10.14 7.847-16.3 18.18-16.3 29.502v22c0-11.322 6.16-21.655 16.3-29.502h0v-22z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#F5F" stroke="none" d="M135 12.251c-19.23 0-36.5 5.773-48.342 14.935v22C98.5 40.024 115.77 34.251 135 34.251v-22z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M135 12.251c-19.23 0-36.5 5.773-48.342 14.935h0v22h0C98.5 40.024 115.77 34.251 135 34.251v-22z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#55F" stroke="none" d="M199.643 56.688c0 23.484-26.504 42.714-60.071 44.326v22c33.567-1.612 60.07-20.842 60.07-44.326v-22z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M199.643 56.688c0 23.484-26.504 42.714-60.071 44.326h0v22h0c33.567-1.612 60.07-20.842 60.07-44.326v-22z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#5F5" stroke="none" d="M108.57 97.252v22c8.068 2.488 17.014 3.872 26.43 3.872 1.537 0 3.061-.037 4.572-.11v-22c-1.51.073-3.035.11-4.572.11-9.416 0-18.362-1.384-26.43-3.872z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M108.57 97.252v22h0c8.068 2.488 17.014 3.872 26.43 3.872 1.537 0 3.061-.037 4.572-.11h0v-22h0c-1.51.073-3.035.11-4.572.11-9.416 0-18.362-1.384-26.43-3.872z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#FF5" stroke="none" d="M70.358 56.688v22c0 18.068 15.688 33.618 38.212 40.564v-22c-22.524-6.946-38.212-22.496-38.212-40.564z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M70.358 56.688v22c0 18.068 15.688 33.618 38.212 40.564h0v-22h0c-22.524-6.946-38.212-22.496-38.212-40.564z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#F55" stroke="none" d="M135 12.251a64.642 44.436 0 0114.74 1.171L135 56.688z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M135 12.251a64.642 44.436 0 0114.74 1.171L135 56.688z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#55F" stroke="none" d="M149.74 13.422a64.642 44.436 0 01-10.168 87.59L135 56.687z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M149.74 13.422a64.642 44.436 0 01-10.168 87.59L135 56.687z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#5F5" stroke="none" d="M139.572 101.012a64.642 44.436 0 01-31.002-3.772L135 56.688z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M139.572 101.012a64.642 44.436 0 01-31.002-3.772L135 56.688z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#FF5" stroke="none" d="M108.57 97.24a64.642 44.436 0 01-21.912-70.053L135 56.687z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M108.57 97.24a64.642 44.436 0 01-21.912-70.053L135 56.687z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="#F5F" stroke="none" d="M86.658 27.187A64.642 44.436 0 01135 12.25v44.436z" clipPath="url(#clipPath2)" opacity="0.5" />
            <path fill="none" d="M86.658 27.187A64.642 44.436 0 01135 12.25v44.436z" clipPath="url(#clipPath2)" opacity="0.5" />
         </g>
         <g fill="none" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path d="M111.15 20.21L107.821 14.86" clipPath="url(#clipPath2)" />
            <path d="M68.7 14.86L107.821 14.86" clipPath="url(#clipPath2)" />
            <path d="M68.7 14.86L64.118 14.86" clipPath="url(#clipPath2)" />
         </g>
         <path fill="#979797" fillOpacity="0.502" stroke="none" strokeOpacity="0.502" strokeWidth="0.5" d="M11.352 10.204H66.118V23.517000000000003H11.352z" clipPath="url(#clipPath2)" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility" />
         <g fill="#fff" stroke="#fff" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path stroke="none" d="M9.352 8.204H64.118V21.517000000000003H9.352z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M9.352 8.204H64.118V21.517000000000003H9.352z" clipPath="url(#clipPath2)" />
            <text x="11.352" y="17.63" fill="#000" stroke="none" clipPath="url(#clipPath2)">Canada 13%</text>
            <path fill="none" stroke="#000" d="M78.122 65.094L70.181 66.327" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M68.7 66.327L70.181 66.327" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M68.7 66.327L62.273 66.327" clipPath="url(#clipPath2)" />
         </g>
         <path fill="#979797" fillOpacity="0.502" stroke="none" strokeOpacity="0.502" strokeWidth="0.5" d="M17.624 61.671H64.272V74.984H17.624z" clipPath="url(#clipPath2)" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility" />
         <g fill="#fff" stroke="#fff" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path stroke="none" d="M15.624 59.671H62.272000000000006V72.984H15.624z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M15.624 59.671H62.272000000000006V72.984H15.624z" clipPath="url(#clipPath2)" />
            <text x="17.624" y="69.097" fill="#000" stroke="none" clipPath="url(#clipPath2)">Brazil 30%</text>
            <path fill="none" stroke="#000" d="M124.858 96.068L123.443 101.843" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M68.7 101.843L123.443 101.843" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M68.7 101.843L64.883 101.843" clipPath="url(#clipPath2)" />
         </g>
         <path fill="#979797" fillOpacity="0.502" stroke="none" strokeOpacity="0.502" strokeWidth="0.5" d="M14.852 97.187H66.883V110.5H14.852z" clipPath="url(#clipPath2)" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility" />
         <g fill="#fff" stroke="#fff" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path stroke="none" d="M12.852 95.187H64.883V108.5H12.852z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M12.852 95.187H64.883V108.5H12.852z" clipPath="url(#clipPath2)" />
            <text x="14.852" y="104.612" fill="#000" stroke="none" clipPath="url(#clipPath2)">Belgium 8%</text>
            <path fill="none" stroke="#000" d="M141.677 16.959L142.609 11.133" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M201.3 11.133L142.609 11.133" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M201.3 11.133L204.923 11.313" clipPath="url(#clipPath2)" />
         </g>
         <path fill="#979797" fillOpacity="0.502" stroke="none" strokeOpacity="0.502" strokeWidth="0.5" d="M206.923 2H252.63V24.625H206.923z" clipPath="url(#clipPath2)" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility" />
         <g fill="#fff" stroke="#fff" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path stroke="none" d="M204.923 0H250.63V22.625H204.923z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M204.923 0H250.63V22.625H204.923z" clipPath="url(#clipPath2)" />
            <text x="206.923" y="9.426" fill="#000" stroke="none" clipPath="url(#clipPath2)">Argentina</text>
            <text x="221.431" y="18.738" fill="#000" stroke="none" clipPath="url(#clipPath2)">4%</text>
            <path fill="none" stroke="#000" d="M192.994 59.869L201.09 60.336" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M201.3 60.336L201.09 60.336" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M201.3 60.336L207.79 106.719" clipPath="url(#clipPath2)" />
         </g>
         <path fill="#979797" fillOpacity="0.502" stroke="none" strokeOpacity="0.502" strokeWidth="0.5" d="M209.79 102.063H262.376V115.376H209.79z" clipPath="url(#clipPath2)" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility" />
         <g fill="#fff" stroke="#fff" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path stroke="none" d="M207.79 100.063H260.376V113.376H207.79z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#000" d="M207.79 100.063H260.376V113.376H207.79z" clipPath="url(#clipPath2)" />
            <text x="209.79" y="109.488" fill="#000" stroke="none" clipPath="url(#clipPath2)">Austria 45%</text>
         </g>
      </svg>
   );
}
export default Pie3DChartIcon;