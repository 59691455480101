/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { CommandRegistry, EditableTable, Icon, IconButton, IEditor, IMenuItem, IResourceInfo, IResourceListener, IResourceListenerManager, IToolBarItemOptions, IToolbarProvider, TextInput, ToolBar, ToolButton, UxType } from '@jss/js-common';
import { getRealName, info, IRepositoryItemDescriptor, isReadOnly, MIME_TYPES, RepositoryApi, RESOURCE_TYPE } from '@jss/js-rest-api';
import * as React from 'react';
import i18n from '../i18n';
import IJRIOContextConfig from './IJRIOContextConfig';
import InfoIcon from '@material-ui/icons/Info';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeleteIcon from '@material-ui/icons/Delete';
import { InputAdornment, IconButton as MaterialIcontButton, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { ResourceInfo, ResourcePickerDialog, RunContext } from '@jss/js-repository';
import { Base64 } from 'js-base64';
import { v4 as uuidv4 } from 'uuid';
import { EXIT_JRIO_CONTEXT_EDITOR, JRIO_CONTEXT_EDITOR_DIRTY, JRIO_CONTEXT_SAVED, JRIO_CONTEXT_SAVED_AS, SAVE_JRIO_CONTEXT, UPDATED_JRIO_CONTEXT_CONTENT } from '../commandIds';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Tab, Tabs } from '@material-ui/core';
import '../assets/uxpl/css/JRIOContextEditor.css';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { VALIDATION_RESULT } from '@jss/js-common/src/utils/validators';

interface IJRIOContextEditor {
    resourceListenerManagers: IResourceListenerManager[],
    commandRegistry: CommandRegistry,
    language?: string,
    onExit?: () => void;
}

interface IJRIOContextEditorState {
    openDescriptor: IRepositoryItemDescriptor | undefined,
    path?: IRepositoryItemDescriptor[],
    isDirty: boolean,
    defaultFileName: string,
    initializingComponent: boolean,
    jrioContextInfo: IJRIOContextConfig,
    errorMessage: string | null,
    errorMessageSave: string | null,
    successMessage: string | null,
    parentContextValidationMessage: string | null,
    pageLoading: boolean,
    isNewJRIOContext: boolean,
    toolbarData: IToolbarProvider | undefined,
}

function buildFileSelector(handleFileChange: (e) => void, acceptedFileTypes: string) {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', acceptedFileTypes);
    fileSelector.onchange = handleFileChange;
    fileSelector.onclick = function () {
        (this as any).value = null;
    };
    return fileSelector;
}

export class InternalJRIOContextEditor extends React.Component<IJRIOContextEditor, IJRIOContextEditorState> implements IEditor {

    protected fileSelector: HTMLInputElement;
    private resourceListenerId = ''

    public state: IJRIOContextEditorState = {
        openDescriptor: undefined,
        path: undefined,
        isDirty: false,
        defaultFileName: 'context.xml',
        initializingComponent: true,
        jrioContextInfo: { parentContext: undefined, classpath: [], propertyNames: [], propertyValues: [] },
        errorMessage: null,
        errorMessageSave: null,
        successMessage: null,
        parentContextValidationMessage: null,
        pageLoading: false,
        isNewJRIOContext: true,
        toolbarData: undefined
    }

    provideToolbar = () => {
        this.setState({ toolbarData: this.getToolbar() });
    }

    getToolbar = (): IToolbarProvider => {
        const isSaveDisabled = !this.state.isDirty || !this.state.openDescriptor || isReadOnly(this.state.openDescriptor);
        const getSaveSubmenu: IMenuItem[] = [
            {
                id: 'saveAction',
                label: i18n.t('common.actions.menu.save'),
                isEnabled: !isSaveDisabled,
                icon: <IconButton className='jr-mMenuDecorated jr-mMenu-list-item-icon jr-MuiListItemIcon-root jr-mIcon mui' style={{ minWidth: 0, backgroundColor: 'transparent' }} icon={'save'} size="small" disabled={isSaveDisabled} />,
                onClick: () => {
                    if (this.state.openDescriptor.uuid) {
                        this.doSave(false);
                    } else {
                        //if the file is new force a new file to let the user choose the name
                        this.doSave(true);
                    }
                }
            },
            {
                id: 'saveAsAction',
                label: i18n.t('common.actions.menu.saveas'),
                icon: <SaveAltIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    this.doSave(true);
                }
            },
            {
                id: 'propertiesAction',
                label: i18n.t('common.actions.menu.resourceinfo'),
                icon: <InfoIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                getComponent: (handleClose: () => void) => {
                    const isEnabled = this.state.openDescriptor !== undefined && this.state.openDescriptor.uuid !== undefined;
                    return <ResourceInfo disabled={!isEnabled} key='rs.info' type={UxType.MENU} onClose={handleClose} descriptor={this.state.openDescriptor} onChanged={this.handleItemPropertiesChanged} />
                }
            },
            {
                label: i18n.t('common.actions.menu.upload'),
                id: 'importAction',
                getComponent: (handleClose: () => void) => {
                    return (
                        <MenuItem key={'importAction'} selected={false} className={"jr-mMenu-list-item mui"}>
                            <div style={{ display: 'flex' }} onClick={(event) => { handleClose(); this.handleUploadPress(event) }}>
                                <ListItemIcon className={"jr-mMenu-list-item-icon mui"}>
                                    <PublishIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />
                                </ListItemIcon>
                                <ListItemText primary={i18n.t('common.actions.menu.upload')} classes={{ primary: "jr-mText mui" }} />
                            </div>
                        </MenuItem>)
                }
            },
            {
                label: i18n.t('common.actions.menu.download'),
                icon: <GetAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                id: 'exportAction',
                onClick: () => {
                    if (this.state.jrioContextInfo) {
                        const xmlDaContent = this.toXml(this.state.jrioContextInfo);
                        if (this.props.commandRegistry) {
                            const blob = new Blob([xmlDaContent], { type: "text/xml;charset=utf-8" });
                            saveAs(blob, 'context.xml');
                        }
                    }
                }
            },
            {
                label: i18n.t('common.actions.menu.exit'),
                id: 'exitAction',
                icon: <ExitToAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    if (this.props.commandRegistry) {
                        this.props.commandRegistry.executeCommand(EXIT_JRIO_CONTEXT_EDITOR);
                    }
                }
            },
        ];
        return {
            getToolItems: () => {
                const items = [
                    {
                        id: 'saveToolaction',
                        render: (options?: IToolBarItemOptions) => {
                            //disable the save when the report is not dirty or we don't have write permission
                            const height = options && options.height ? options.height : 24;
                            const backgroundColor = options && options.background ? options.background : undefined;
                            return <ToolButton label={i18n.t('common.actions.menu.save')} isDisabled={isSaveDisabled} onClick={() => {
                                if (this.state.openDescriptor.uuid) {
                                    this.doSave(false);
                                } else {
                                    //if the file is new force a new file to let the user choose the name
                                    this.doSave(true);
                                }
                            }} height={height} backgroundColor={backgroundColor} content={getSaveSubmenu}>
                                <IconButton style={{ backgroundColor: 'transparent' }} icon={'save'} size="small" disabled={isSaveDisabled} />
                            </ToolButton>
                        }
                    }
                ];
                return items;
            },
        }
    }

    private onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            const jcXML = fileReader.result as string;
            const base64data = Base64.encode(jcXML);
            const descriptor: IRepositoryItemDescriptor = this.state.openDescriptor;
            const newDescriptor: IRepositoryItemDescriptor = {
                ...(descriptor as any),
                data: base64data,
            }
            this.setState({
                openDescriptor: newDescriptor,
                isDirty: true
            }, () => {
                this.loadResource(jcXML);
                this.props.commandRegistry.executeCommand(UPDATED_JRIO_CONTEXT_CONTENT, { descriptor: newDescriptor, data: jcXML });
            });
        };
        fileReader.readAsText(event.currentTarget.files[0]);
    }

    private loadResource = (data: string) => {
        // Perform loading....
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, 'text/xml');
        const errorNode = xmlDoc.querySelector('parsererror');
        if (errorNode) {
            // something went wrong during the xml loading
            this.setState({
                initializingComponent: false,
                jrioContextInfo: null,
                errorMessage: i18n.t('jrioContextEditor.validators.xmlBroken')
            })
        }
        else {
            const result = this.toJson(xmlDoc);
            this.setState({
                initializingComponent: false,
                jrioContextInfo: result,
                errorMessage: null
            }, () => { this.parentContextNameValidator(this.state.jrioContextInfo.parentContext) });
        }
    }

    handleUploadPress = (e) => {
        e.preventDefault();
        this.fileSelector.click();
    }

    protected handleItemPropertiesChanged = (oldDescriptor: IRepositoryItemDescriptor, newDescriptor: IRepositoryItemDescriptor | null) => {
        this.setState({
            openDescriptor: newDescriptor,
        }, () => {
            this.props.commandRegistry.executeCommand(JRIO_CONTEXT_SAVED, { descriptor: newDescriptor });
        });
    }

    protected doSave = (forceNewFile = false, callback?: () => void) => {
        if (this.state.jrioContextInfo) {
            const jrioContextXml = this.toXml(this.state.jrioContextInfo);
            if (this.state.openDescriptor && !forceNewFile) {
                //it is a save of an existing context file
                const base64data = Base64.encode(jrioContextXml);
                const descriptor: IRepositoryItemDescriptor = this.state.openDescriptor;
                const newDescriptor: IRepositoryItemDescriptor = {
                    ...(descriptor as any),
                    data: base64data,
                    mime: MIME_TYPES.JRIO_CONTEXT_XML,
                }

                const commitFile = () => this.context.runNoCancel(RepositoryApi.inst().save)(descriptor.uuid ? 'uuid:' + descriptor.uuid : (descriptor.path ? descriptor.path : '/'), newDescriptor,).then((response: AxiosResponse) => {
                    if (response.data) {
                        //need to update the descriptor also in simple save because it could contain the version
                        this.setState({ isNewJRIOContext: false, openDescriptor: response.data, isDirty: false }, () => {
                            this.props.commandRegistry.executeCommand(JRIO_CONTEXT_SAVED, { descriptor: response.data, data: jrioContextXml });
                            if (callback) {
                                callback();
                            }
                        })
                    }
                });
                commitFile();
            } else {
                //it is a new file
                this.openSaveDialog();
            }
        }
    }

    public openSaveDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = this.state.path;
        let filename = this.state.defaultFileName;
        if (!filename.toLowerCase().endsWith('.xml')) {
            filename += '.xml';
        }
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('jrioContextEditor.saveAs.name'),
                mode: 'save',
                title: !this.state.openDescriptor.uuid ? i18n.t('jrioContextEditor.save.title') : i18n.t('jrioContextEditor.saveAs.title'),
                confirmButtonLabel: !this.state.openDescriptor.uuid ? i18n.t('common.actions.button.ok') : i18n.t('common.actions.button.save'),
                onViewClose: () => {
                    //empty
                },
                onFileSelected: this.confirmSave,
                defaultFileName: filename,
                defaultPath: defaultPath
            }
        );

    }

    private confirmSave = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null, fileName: string) => {
        if (this.state.jrioContextInfo) {
            const jrioContextXml = this.toXml(this.state.jrioContextInfo);
            const base64data = Base64.encode(jrioContextXml);
            const newDescriptor: IRepositoryItemDescriptor = {
                type: RESOURCE_TYPE.FILE,
                name: fileName,
                path: RepositoryApi.inst().getParentPath(folderPath),
                data: base64data,
                mime: MIME_TYPES.JRIO_CONTEXT_XML,
            }
            if (file !== null) {
                //the save as is overwriting a file
                newDescriptor.uuid = file.uuid;
            }

            this.context.runNoCancel(RepositoryApi.inst().save)(file !== null ? 'uuid:' + file.uuid : '', newDescriptor).then((response: AxiosResponse) => {
                if (response.data) {
                    this.setState({ openDescriptor: response.data, isNewJRIOContext: false, isDirty: false }, () => {
                        this.props.commandRegistry.executeCommand(JRIO_CONTEXT_SAVED_AS, { descriptor: response.data, data: jrioContextXml });
                        this.props.commandRegistry.showMessage(info(i18n.t('jrioContextEditor.saveAs.saveOk')));
                    });
                }
            });
        }
    }

    public getEditorContent = () => {
        if (this.state.errorMessage && this.state.openDescriptor) {
            // use the original "broken" XML so it can be modified in the source editor
            return this.state.openDescriptor.data;
        }
        if (this.state.jrioContextInfo) {
            const xmlJRIOContext = this.toXml(this.state.jrioContextInfo);
            return xmlJRIOContext;
        } else {
            return "<context><parentContext><path/></parentContext><classpath/><properties/></context>";
        }
    }

    public toJson = (xml: Document) => {
        const result: IJRIOContextConfig = this.createJRIOContextInfo();
        try {
            const firstNode: Node = xml.getRootNode().childNodes[0];
            if (firstNode.nodeName === 'context') {
                firstNode.childNodes.forEach((childNode) => {
                    const nodeName = childNode.nodeName;
                    if (nodeName === 'parentContext') {
                        const parentContexPathtNode = this.getChildNodeByName(childNode, 'path');
                        if (parentContexPathtNode !== undefined) {
                            result.parentContext = parentContexPathtNode.textContent;
                        }
                    }
                    else if (nodeName === 'classpath' && childNode.childNodes !== undefined) {
                        childNode.childNodes.forEach((classPathEntry) => {
                            if (classPathEntry.nodeName === 'entry') {
                                const classPathEntryPathNode = this.getChildNodeByName(classPathEntry, 'path');
                                if (classPathEntryPathNode !== undefined) {
                                    result.classpath.push(classPathEntryPathNode.textContent);
                                }
                            }
                        });
                    }
                    else if (nodeName === 'properties' && childNode.childNodes !== undefined) {
                        childNode.childNodes.forEach((propertyEntry) => {
                            if (propertyEntry.nodeName === 'property' && propertyEntry.childNodes !== undefined) {
                                const propertyNameNode: Node = this.getChildNodeByName(propertyEntry, 'name');
                                const propertyValueNode: Node = this.getChildNodeByName(propertyEntry, 'value');
                                if (propertyNameNode !== undefined && propertyValueNode !== undefined) {
                                    result.propertyNames.push(propertyNameNode.textContent);
                                    result.propertyValues.push(propertyValueNode.textContent);
                                }
                            }
                        });
                    }
                });
            }
        }
        catch (error) {
            console.error(error);
        }
        return result;
    }

    private getChildNodeByName = (parent: ChildNode, name: string) => {
        let node;
        for (let i = 0; i < parent.childNodes.length && !node; i++) {
            const currentNode = parent.childNodes.item(i);
            if (currentNode.nodeName === name) {
                node = currentNode;
            }
        }
        return node;
    }

    public toXml = (config: IJRIOContextConfig) => {
        const xmlDoc = document.implementation.createDocument(null, "context");
        const root = xmlDoc.getElementsByTagName("context")[0];
        // parent context
        const parentContext = xmlDoc.createElement("parentContext");
        if (config.parentContext !== undefined) {
            const pcPath = xmlDoc.createElement("path");
            const pcPathText = xmlDoc.createTextNode(config.parentContext);
            pcPath.appendChild(pcPathText);
            parentContext.appendChild(pcPath);
        }
        root.appendChild(parentContext);

        // classpath entries
        const classpath = xmlDoc.createElement("classpath");
        if (config.classpath) {
            config.classpath.forEach((classpathEntry) => {
                const entry = xmlDoc.createElement("entry");
                const entryPath = xmlDoc.createElement("path");
                const entryPathText = xmlDoc.createTextNode(classpathEntry);
                entryPath.appendChild(entryPathText);
                entry.appendChild(entryPath);
                classpath.appendChild(entry);
            });
        }
        root.appendChild(classpath);

        // property entries
        const properties = xmlDoc.createElement("properties");
        if (config.propertyNames && config.propertyValues) {
            config.propertyNames.forEach((pName, idx) => {
                const property = xmlDoc.createElement("property");
                const name = xmlDoc.createElement("name");
                const nameText = xmlDoc.createTextNode(pName);
                const value = xmlDoc.createElement("value");
                const valueText = xmlDoc.createTextNode(config.propertyValues[idx]);
                value.appendChild(valueText);
                name.appendChild(nameText);
                property.appendChild(name);
                property.appendChild(value);
                properties.appendChild(property);
            });
        }
        root.appendChild(properties);

        return new XMLSerializer().serializeToString(xmlDoc);
    }

    private createJRIOContextInfo = () => {
        const result: IJRIOContextConfig = {
            parentContext: undefined,
            classpath: [],
            propertyNames: [],
            propertyValues: []
        };
        return result;
    }

    public componentDidMount = () => {
        this.resourceListenerId = uuidv4();
        this.fileSelector = buildFileSelector(this.onFileSelected, '.xml');
        const resourceListener: IResourceListener = {
            onResourceOpen: (resource: IResourceInfo) => {
                if (resource.resource) {
                    if (!resource.content) {
                        const newInfo: IJRIOContextConfig = this.createJRIOContextInfo();
                        this.setState({
                            jrioContextInfo: newInfo,
                            errorMessage: null,
                            errorMessageSave: null,
                            successMessage: null,
                            pageLoading: false,
                            isNewJRIOContext: true,
                            initializingComponent: false,
                            openDescriptor: resource.resource,
                            path: resource.path,
                            defaultFileName: resource.resource.name,
                            isDirty: resource.isResourceDirty !== undefined ? resource.isResourceDirty : false,
                        });
                    } else {
                        this.setState({
                            isNewJRIOContext: resource.resource.data === undefined,
                            openDescriptor: resource.resource,
                            path: resource.path,
                            defaultFileName: resource.resource.name,
                            isDirty: resource.isResourceDirty !== undefined ? resource.isResourceDirty : false,
                        }, () => {
                            this.loadResource(resource.content);
                        });
                    }
                }
            }
        }
        this.props.resourceListenerManagers.forEach(manager => {
            manager.addResourceListener(this.resourceListenerId, resourceListener);
        });
        this.props.commandRegistry.registerHandler(SAVE_JRIO_CONTEXT, {
            execute: (params: Record<string, unknown>, callback?: () => void) => {
                this.doSave(false, callback);
            },
            isEnabled: () => {
                return true;
            }
        });
        this.props.commandRegistry.registerHandler(EXIT_JRIO_CONTEXT_EDITOR, {
            execute: () => {
                if (this.props.commandRegistry) {
                    this.props.commandRegistry.executeCommand(EXIT_JRIO_CONTEXT_EDITOR);
                }
            },
            isEnabled: () => {
                return true;
            }
        });

        this.provideToolbar();
    }

    componentWillUnmount() {
        this.props.resourceListenerManagers.forEach(manager => {
            manager.removeResourceListener(this.resourceListenerId);
        });
    }

    componentDidUpdate = (previousProps: IJRIOContextEditor, previousState: IJRIOContextEditorState) => {
        if (previousState.isDirty !== this.state.isDirty) {
            this.provideToolbar();
            this.props.commandRegistry.executeCommand(JRIO_CONTEXT_EDITOR_DIRTY, { isDirty: this.state.isDirty });
        } else if (previousState.jrioContextInfo !== this.state.jrioContextInfo) {
            this.provideToolbar();
        } else if (this.state.openDescriptor !== previousState.openDescriptor) {
            this.provideToolbar();
        }
    }

    public render() {
        const searchIconAdornament =
            <InputAdornment position="end">
                <MaterialIcontButton edge="end" style={{ padding: 0, paddingRight: 5 }}>
                    {this.getSearchIcon()}
                </MaterialIcontButton>
            </InputAdornment>;
        let jrioContextPageContent;
        if (this.state.errorMessage) {
            jrioContextPageContent = <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{this.state.errorMessage}</div>;
        }
        else {
            jrioContextPageContent =
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', position: 'relative', overflow: 'auto' }} >
                    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', flex: 1, alignItems: 'stretch', flexDirection: 'column', inset: 'auto' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }} className='tc-jsw-form-wrapper tc-js-jriocontext-configuration-container'>
                            {/* <strong>DEBUG PRINT:</strong> {JSON.stringify(this.state.jrioContextInfo)}<br /><br /> */}
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                                <TabContext value="jrioContextParentCtx">
                                    <div style={{ flex: 1 }}>
                                        <Tabs
                                            value="jrioContextParentCtx"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="auto tabs example" >
                                            <Tab key={'jrioContextParentCtx'} label={i18n.t('jrioContextEditor.parentContext.title')} value="jrioContextParentCtx" />
                                        </Tabs>
                                    </div>
                                    <TabPanel key={'jrioContextParentCtx'} value="jrioContextParentCtx" className="tc-jsw-jriocontext-editor-categories-no-padding">
                                        <TextInput label={i18n.t('jrioContextEditor.parentContext.location')}
                                            id="parentcontext-url-input"
                                            className={'.tc-jsw-jriocontext-editor-text-input'}
                                            value={this.state.jrioContextInfo.parentContext ? this.state.jrioContextInfo.parentContext : ''}
                                            onChange={this.onFileUrlChange}
                                            advancedValidator={() => { return { message: this.state.parentContextValidationMessage, result: VALIDATION_RESULT.VALID } }}
                                            InputProps={{ endAdornment: searchIconAdornament }} />
                                    </TabPanel>
                                </TabContext>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                                <TabContext value="jrioContextClasspath">
                                    <div style={{ flex: 1 }}>
                                        <Tabs
                                            value="jrioContextClasspath"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="auto tabs example" >
                                            <Tab key={'jrioContextClasspath'} label={i18n.t('jrioContextEditor.classpathList.title')} value="jrioContextClasspath" />
                                        </Tabs>
                                    </div>
                                    <TabPanel key={'jrioContextClasspath'} value="jrioContextClasspath" className="tc-jsw-jriocontext-editor-categories-no-padding">
                                        {this.getClasspathList()}
                                    </TabPanel>
                                </TabContext>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                                <TabContext value="jrioContextProperties">
                                    <div style={{ flex: 1 }}>
                                        <Tabs
                                            value="jrioContextProperties"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="auto tabs example" >
                                            <Tab key={'jrioContextProperties'} label={i18n.t('jrioContextEditor.propertytable.title')} value="jrioContextProperties" />
                                        </Tabs>
                                    </div>
                                    <TabPanel key={'jrioContextProperties'} value="jrioContextProperties" className="tc-jsw-jriocontext-editor-categories-no-padding">
                                        {this.getConnectionProperties()}
                                    </TabPanel>
                                </TabContext>
                            </div>
                        </div>
                    </div>
                </div >;
        }
        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#f7f6f6' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                    <ToolBar toolbarData={this.state.toolbarData} />
                </div>
                {jrioContextPageContent}
            </div >
        );
    }

    private parentContextNameValidator = (value: string | undefined) => {
        if (value) {
            // Load the folders..
            RepositoryApi.inst().list(value).then((response) => {
                const foundChildren = response.data.children ? Object.values(response.data.children) as IRepositoryItemDescriptor[] : [];
                const jrInfResource = foundChildren.find((child) => {
                    return child.name === 'JR-INF';
                });
                if (jrInfResource) {
                    RepositoryApi.inst().list(`uuid:${jrInfResource.uuid}`).then((innerResponse) => {
                        const childFiles = innerResponse.data.children ? Object.values(innerResponse.data.children) as IRepositoryItemDescriptor[] : [];
                        const contextResource = childFiles.find((jrInfChild) => {
                            return jrInfChild.name === 'context.xml';
                        })
                        if (!contextResource) {
                            this.setState({ parentContextValidationMessage: i18n.t('jrioContextEditor.validators.contextName') });
                        } else {
                            this.setState({ parentContextValidationMessage: undefined });
                        }
                    });

                } else {
                    this.setState({ parentContextValidationMessage: i18n.t('jrioContextEditor.validators.contextName') });
                }
            }).catch(() => {
                this.setState({ parentContextValidationMessage: i18n.t('jrioContextEditor.validators.pathNotFound') });
            });
        }
        else {
            this.setState({ parentContextValidationMessage: undefined });
        }
    }


    private confirmParentContextFile = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => {
        const path = folderPath.length > 0 ? `${RepositoryApi.inst().getParentPath(folderPath)}/${getRealName(file)}` : `/${getRealName(file)}`;
        this.notifyChange({ parentContext: path });
    }

    public openParentContextSelectionDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = [];
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('jrioContextEditor.parentContext.dialog.resource'),
                mode: 'open_folder',
                title: i18n.t('jrioContextEditor.parentContext.dialog.title'),
                onFileSelected: this.confirmParentContextFile,
                defaultPath: defaultPath,
                allowSearch: true,
            }
        );
    }

    getSearchIcon = () => {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 2.5 }}>
            <Icon icon="search" onClick={this.openParentContextSelectionDialog} />
        </div>;
    }

    private onFileUrlChange = (str: string) => {
        this.notifyChange({ parentContext: str });
    }

    private getClasspathItemNames = () => {
        if (this.state.jrioContextInfo.classpath) {
            if (Array.isArray(this.state.jrioContextInfo.classpath)) {
                return this.state.jrioContextInfo.classpath;
            } else {
                return [this.state.jrioContextInfo.classpath];
            }
        }
        return [];
    }

    private getClasspathsRows = () => {
        const result: string[][] = [];
        const rows = [...this.getClasspathItemNames()];
        rows.forEach((columnName: string) => {
            result.push([columnName]);
        });
        return result;
    }

    private onClasspathCellChange = (row: number, col: number, value: string, isEditing: boolean) => {
        const rows = this.state.jrioContextInfo.classpath ? [...this.state.jrioContextInfo.classpath] : [];
        if (value.trim().length === 0 && !isEditing) {
            rows.splice(row, 1);
        } else {
            rows[row] = value;
        }
        if (!isEditing) {
            this.notifyChange({ classpaths: rows });
        }
    }

    private onClasspathRowDelete = (rowIndex: number) => {
        const newColumns = [...this.state.jrioContextInfo.classpath];
        newColumns.splice(rowIndex, 1);
        this.notifyChange({ classpaths: newColumns });
    }

    private confirmJarFile = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => {
        const path = folderPath.length > 0 ? `${RepositoryApi.inst().getParentPath(folderPath)}/${getRealName(file)}` : `/${getRealName(file)}`;
        const newFiles = [...this.state.jrioContextInfo.classpath, path];
        this.notifyChange({ classpaths: newFiles });
    }

    public openJarSelectionDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = [];
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('jrioContextEditor.classpath.dialog.resource'),
                mode: 'open',
                title: i18n.t('jrioContextEditor.classpath.dialog.title'),
                onFileSelected: this.confirmJarFile,
                defaultPath: defaultPath,
                allowSearch: true,
                allowedMimes: [MIME_TYPES.JAR]
            }
        );
    }

    getClasspathList = () => {
        const tableRows = this.getClasspathsRows();
        return <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
            <div style={{ display: 'flex', alignItems: 'stretch' }} id="tableContainer">
                <EditableTable cellStyle={{ textAlign: 'left' }} onCellChange={this.onClasspathCellChange} cellData={tableRows} numRows={this.state.jrioContextInfo.classpath ? this.state.jrioContextInfo.classpath.length + 1 : 1}
                    columnNames={[i18n.t('jrioContextEditor.classpathList.jar'), '']} columnActions={[{ onClick: this.onClasspathRowDelete, icon: <DeleteIcon /> }]} onAddAction={this.openJarSelectionDialog} />
            </div>
        </div>;
    }

    getPropertiesRows = () => {
        const result: string[][] = [];
        const rows = this.state.jrioContextInfo.propertyNames ? [...this.state.jrioContextInfo.propertyNames] : [];
        const values = this.state.jrioContextInfo.propertyValues ? [...this.state.jrioContextInfo.propertyValues] : [];
        rows.forEach((columnName: string, index: number) => {
            result.push([columnName, values[index]]);
        });
        return result;
    }

    getPropertiesColumnSize = () => {
        return [400 * 2 / 3, 400 * 1 / 3];
    }

    getConnectionProperties = () => {
        const tableRows = this.getPropertiesRows();
        return <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
            <div style={{ display: 'flex', alignItems: 'stretch', marginTop: '20px', marginBottom: '20px' }} id="tableContainer">
                <EditableTable cellStyle={{ textAlign: 'left' }} columnsWidths={this.getPropertiesColumnSize()} onCellChange={this.onPropertiesCellChange} cellData={tableRows}
                    numRows={this.state.jrioContextInfo.propertyNames ? this.state.jrioContextInfo.propertyNames.length + 1 : 1}
                    columnNames={[i18n.t('jrioContextEditor.propertytable.property.name'), i18n.t('jrioContextEditor.propertytable.property.value'), '']}
                    columnActions={[{ onClick: this.onPropertiesRowDelete, icon: <DeleteIcon /> }]} onAddAction={this.onPropertiesAddAction} />
            </div>
        </div>
    }

    onPropertiesCellChange = (row: number, col: number, value: string, isEditing: boolean) => {
        const rowNames = this.state.jrioContextInfo.propertyNames ? [...this.state.jrioContextInfo.propertyNames] : [];
        const rowIndexes = this.state.jrioContextInfo.propertyValues ? [...this.state.jrioContextInfo.propertyValues] : [];
        if (col === 0 && value.trim().length === 0 && !isEditing) {
            //has erased a row, delete the row
            rowNames.splice(row, 1);
            rowIndexes.splice(row, 1);
        } else if (!isEditing) {
            if (col === 0) {
                rowNames[row] = value;
            } else if (col === 1) {
                rowIndexes[row] = value;
            }
        }
        if (!isEditing) {
            this.notifyChange({ propertiesNames: rowNames, propertiesValues: rowIndexes });
        }
    }

    onPropertiesRowDelete = (rowIndex: number) => {
        const newColumnsNames = [...this.state.jrioContextInfo.propertyNames];
        newColumnsNames.splice(rowIndex, 1);
        const newColumnsIndexes = [...this.state.jrioContextInfo.propertyValues];
        newColumnsIndexes.splice(rowIndex, 1);
        this.notifyChange({ propertiesNames: newColumnsNames, propertiesValues: newColumnsIndexes });
    }

    private onPropertiesAddAction = () => {
        let index = 1;
        let found = false;
        const names = this.state.jrioContextInfo.propertyNames ? [...this.state.jrioContextInfo.propertyNames] : [];
        const values = this.state.jrioContextInfo.propertyValues ? [...this.state.jrioContextInfo.propertyValues] : [];
        let currentName = 'property';
        while (!found) {
            const elementIndex = names.findIndex((value: string) => {
                return value.trim() === currentName;
            });
            if (elementIndex === -1) {
                found = true;
            } else {
                currentName = 'property' + index;
                index++;
            }
        }
        names.push(currentName);
        values.push('value');
        this.notifyChange({ propertiesNames: names, propertiesValues: values });
    }

    private notifyChange = (data: any) => {
        if ('propertiesNames' in data && 'propertiesValues' in data) {
            this.setState({ jrioContextInfo: { ...this.state.jrioContextInfo, propertyNames: data.propertiesNames, propertyValues: data.propertiesValues }, isDirty: true });
        }
        else if ('classpaths' in data) {
            this.setState({ jrioContextInfo: { ...this.state.jrioContextInfo, classpath: data.classpaths }, isDirty: true });
        }
        else if ('parentContext' in data) {
            this.setState({ jrioContextInfo: { ...this.state.jrioContextInfo, parentContext: data.parentContext }, isDirty: true }, () => {
                this.parentContextNameValidator(data.parentContext);
            });
        }
    }

}

InternalJRIOContextEditor.contextType = RunContext;

export default InternalJRIOContextEditor;