/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { FormControlledSelect, FormControlledWritableCombo } from "@jss/js-common";
import { getKey, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { Map } from 'immutable';
import { IState } from '../../../../../reducers';
import { connect } from 'react-redux';
import { List } from 'immutable';
import i18n from "../../../../../i18n";
import '../../../../../assets/uxpl/css/UPControls.css';


const TYPEID = 'queryLanguage';
reg(TYPEID, (mc) => { return <UPQueryLanguage mcontext={mc} />; });
export class PQueryLanguage extends APDescriptor {
    default?: string;
    writeable?: boolean;
    public constructor(init: Partial<PQueryLanguage>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

interface QueryLanguage {
    combosCache?: Map<string, any>
}

class UPQueryLanguage extends React.Component<UiPropertyProps & QueryLanguage> {

    render() {
        const d = this.props.mcontext.descriptor as PQueryLanguage;

        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        let v = this.props.mcontext.model.getIn(p);

        if (!v) v = d.default;//i18n.t('Default to SQL');
        const queryLanguages: List<string> | undefined = this.props.combosCache.get('queryLanguages');
        let items: { key: string, value: string }[] = [];
        if (queryLanguages) {
            items = queryLanguages.toArray().map((queryLanguage: string) => {
                return {
                    key: queryLanguage,
                    value: queryLanguage,
                }
            });
        }
        if (d.writeable === true) {
            return <FormControlledWritableCombo key={getKey(p)} items={items}
                className={this.props.mcontext.descriptor.className}
                style={this.props.mcontext.descriptor.style}
                value={v}
                placeholder={i18n.t('reportdesigner.properties.query.language.default')}
                onComboChange={this.onWritableNameChange}
                label={i18n.t(d.label)}
                disabled={d.readonly}
                InputLabelProps={d.deprecated ? {className: 'deprecatedProperty'} : undefined}
                inline={true}
                size="small"
            />
        }
        return <FormControlledSelect key={getKey(p)} items={items}
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            value={v}
            onItemSelected={this.onNameChange}
            label={d.label}
            disabled={d.readonly}
            InputLabelProps={d.deprecated ? {className: 'deprecatedProperty'} : undefined}
            inline={true}
            size={'small'}
        />
    }
    public onNameChange = (str) => {
        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: str });
        });
    }
    public onWritableNameChange = (selectedKey: string | undefined, selectedText: string) => {
        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: selectedKey ? selectedKey : selectedText });
        });
    }

}

const mapStateToProps = (state: IState) => {
    return {
        combosCache: state.getIn(['report', 'cachedData', 'comboValues'], Map<string, any>()),
    };
}

export default connect(mapStateToProps)(UPQueryLanguage);