export const fr = {
	about: "À propos de JasperReports Web Studio",
	components: {
		filesTable: {
			filename: "Nom de fichier",
			type: "Type",
			lastmodified: "Dernière modification"
		}
	},
	materialUi: {
		component: {
			collapsiblePanel: {
				close: "Fermer",
				open: "Ouvrir"
			}
		}
	},
	jrws: {
		header: {
			betaicon: {
				tooltip: "This is a beta version and should be used with care"
			}
		}
	},
	common: {
		actions: {
			menu: {
				save: "Sauvegarder",
				saveas: "Enregistrer Sous...",
				resourceinfo: "Informations Sur La Ressource",
				upload: "Téléverser",
				download: "Télécharger",
				exit: "Sortir",
				undo: "Défaire",
				redo: "Refaire",
				zoomin: "Agrandir",
				zoomout: "Dézoomer",
				zoomlevel: "Le niveau de zoom",
				delete: "Effacer",
				help: "Aide"
			}
		},
		editabletable: {
			add: "Ajouter",
			remove: "Supprimer"
		},
		button: {
			cancel: "Annuler",
			exitEditor: "Fermer l'editeur"
		},
		dialog: {
			exitWithoutSave: "Voulez-vous vraiment quitter l'editeur sans enregistrer? Toutes vos modifications seront perdues."
		}
	}
}