/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const Bar3DChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
            <defs>
                <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
                    <path d="M0 0h270v175H0V0z" />
                </clipPath>
                <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
                    <path d="M44 4v130h218V4z" />
                </clipPath>
            </defs>
            <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
                <path fill="none" d="M24.51 157.188L245.49 157.188" clipPath="url(#clipPath1)" />
                <path fill="none" d="M24.51 173.5L245.49 173.5" clipPath="url(#clipPath1)" />
                <path fill="none" d="M24.51 173.5L24.51 157.188" clipPath="url(#clipPath1)" />
                <path fill="none" d="M245.49 173.5L245.49 157.188" clipPath="url(#clipPath1)" />
                <path fill="#F55" stroke="none" d="M28.01 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
                <text x="40.01" y="168.113" stroke="none" clipPath="url(#clipPath1)">Double Freight</text>
                <path fill="#55F" stroke="none" d="M105.22 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
                <text x="117.221" y="168.113" stroke="none" clipPath="url(#clipPath1)">Normal Freight</text>
                <path fill="#5F5" stroke="none" d="M183.17 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
                <text x="195.17" y="168.113" stroke="none" clipPath="url(#clipPath1)">Half Freight</text>
                <path fill="#DDD" stroke="none" d="M44 134V12l12-8v122z" clipPath="url(#clipPath1)" />
                <path fill="#DDD" stroke="none" d="M44 134l12-8h206l-12 8z" clipPath="url(#clipPath1)" />
                <path fill="none" stroke="silver" d="M44 134L56 126" clipPath="url(#clipPath1)" />
                <path fill="none" stroke="silver" d="M56 126L56 4" clipPath="url(#clipPath1)" />
                <path fill="none" stroke="silver" d="M56 126L262 126" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M44 134L250 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M68.72 134L68.72 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M68.72 134L68.72 136" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M107.86 134L107.86 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M107.86 134L107.86 136" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M147 134L147 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M147 134L147 136" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M186.14 134L186.14 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M186.14 134L186.14 136" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M225.28 134L225.28 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M225.28 134L225.28 136" clipPath="url(#clipPath1)" />
                <text x="55.525" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Arge...</text>
                <text x="93.729" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Austria</text>
                <text x="130.602" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Belgium</text>
                <text x="174.978" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Brazil</text>
                <text x="210.059" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Canada</text>
                <path fill="none" strokeWidth="0.5" d="M44 12L44 134" clipPath="url(#clipPath1)" />
                <text x="32.91" y="136.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
                <path fill="none" strokeWidth="0.5" d="M42 134L44 134" clipPath="url(#clipPath1)" />
                <text x="15.098" y="117.12" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">2.500</text>
                <path fill="none" strokeWidth="0.5" d="M42 114.351L44 114.351" clipPath="url(#clipPath1)" />
                <text x="15.098" y="97.471" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">5.000</text>
                <path fill="none" strokeWidth="0.5" d="M42 94.701L44 94.701" clipPath="url(#clipPath1)" />
                <text x="15.098" y="77.822" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">7.500</text>
                <path fill="none" strokeWidth="0.5" d="M42 75.052L44 75.052" clipPath="url(#clipPath1)" />
                <text x="10.008" y="58.172" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.000</text>
                <path fill="none" strokeWidth="0.5" d="M42 55.403L44 55.403" clipPath="url(#clipPath1)" />
                <text x="10.008" y="38.523" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">12.500</text>
                <path fill="none" strokeWidth="0.5" d="M42 35.753L44 35.753" clipPath="url(#clipPath1)" />
                <text x="10.008" y="18.873" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">15.000</text>
                <path fill="none" strokeWidth="0.5" d="M42 16.104L44 16.104" clipPath="url(#clipPath1)" />
            </g>
            <g fill="silver" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
                <path fill="none" d="M44 134L56 126" clipPath="url(#clipPath2)" />
                <path fill="none" d="M56 126L262 126" clipPath="url(#clipPath2)" />
                <path fill="none" d="M44 114.351L56 106.351" clipPath="url(#clipPath2)" />
                <path fill="none" d="M56 106.351L262 106.351" clipPath="url(#clipPath2)" />
                <path fill="none" d="M44 94.701L56 86.701" clipPath="url(#clipPath2)" />
                <path fill="none" d="M56 86.701L262 86.701" clipPath="url(#clipPath2)" />
                <path fill="none" d="M44 75.052L56 67.052" clipPath="url(#clipPath2)" />
                <path fill="none" d="M56 67.052L262 67.052" clipPath="url(#clipPath2)" />
                <path fill="none" d="M44 55.403L56 47.403" clipPath="url(#clipPath2)" />
                <path fill="none" d="M56 47.403L262 47.403" clipPath="url(#clipPath2)" />
                <path fill="none" d="M44 35.753L56 27.753" clipPath="url(#clipPath2)" />
                <path fill="none" d="M56 27.753L262 27.753" clipPath="url(#clipPath2)" />
                <path fill="none" d="M44 16.104L56 8.104" clipPath="url(#clipPath2)" />
                <path fill="none" d="M56 8.104L262 8.104" clipPath="url(#clipPath2)" />
                <path fill="#F55" stroke="none" d="M54.3 124.591H61.166999999999994V134H54.3z" clipPath="url(#clipPath2)" />
                <path fill="#B23B3B" stroke="none" d="M61.167 134v-9.41l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#B23B3B" stroke="none" d="M54.3 124.59l12-8h6.867l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#55F" stroke="none" d="M65.287 129.295H72.15400000000001V134H65.287z" clipPath="url(#clipPath2)" />
                <path fill="#3B3BB2" stroke="none" d="M72.153 134v-4.705l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#3B3BB2" stroke="none" d="M65.287 129.295l12-8h6.866l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" stroke="none" d="M76.273 131.648H83.14V134H76.273z" clipPath="url(#clipPath2)" />
                <path fill="#3BB23B" stroke="none" d="M83.14 134v-2.352l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#3BB23B" stroke="none" d="M76.273 131.648l12-8h6.867l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#F55" stroke="none" d="M93.44 17.809H100.307V134H93.44z" clipPath="url(#clipPath2)" />
                <path fill="#B23B3B" stroke="none" d="M100.307 134V17.81l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#B23B3B" stroke="none" d="M93.44 17.81l12-8h6.867l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#55F" stroke="none" d="M104.427 75.905H111.29400000000001V134H104.427z" clipPath="url(#clipPath2)" />
                <path fill="#3B3BB2" stroke="none" d="M111.293 134V75.905l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#3B3BB2" stroke="none" d="M104.427 75.905l12-8h6.866l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" stroke="none" d="M115.413 104.952H122.28V134H115.413z" clipPath="url(#clipPath2)" />
                <path fill="#3BB23B" stroke="none" d="M122.28 134v-29.048l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#3BB23B" stroke="none" d="M115.413 104.952l12-8h6.867l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#F55" stroke="none" d="M132.58 113.877H139.447V134H132.58z" clipPath="url(#clipPath2)" />
                <path fill="#B23B3B" stroke="none" d="M139.447 134v-20.123l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#B23B3B" stroke="none" d="M132.58 113.877l12-8h6.867l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#55F" stroke="none" d="M143.567 123.938H150.434V134H143.567z" clipPath="url(#clipPath2)" />
                <path fill="#3B3BB2" stroke="none" d="M150.433 134v-10.062l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#3B3BB2" stroke="none" d="M143.567 123.938l12-8h6.866l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" stroke="none" d="M154.553 128.969H161.42V134H154.553z" clipPath="url(#clipPath2)" />
                <path fill="#3BB23B" stroke="none" d="M161.42 134v-5.03l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#3BB23B" stroke="none" d="M154.553 128.97l12-8h6.867l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#F55" stroke="none" d="M171.72 57.286H178.587V134H171.72z" clipPath="url(#clipPath2)" />
                <path fill="#B23B3B" stroke="none" d="M178.587 134V57.286l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#B23B3B" stroke="none" d="M171.72 57.286l12-8h6.867l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#55F" stroke="none" d="M182.707 95.643H189.57399999999998V134H182.707z" clipPath="url(#clipPath2)" />
                <path fill="#3B3BB2" stroke="none" d="M189.573 134V95.643l12-8V126zM182.707 95.643l12-8h6.866l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" stroke="none" d="M193.693 114.822H200.56V134H193.693z" clipPath="url(#clipPath2)" />
                <path fill="#3BB23B" stroke="none" d="M200.56 134v-19.178l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#3BB23B" stroke="none" d="M193.693 114.822l12-8h6.867l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#F55" stroke="none" d="M210.86 99.447H217.727V134H210.86z" clipPath="url(#clipPath2)" />
                <path fill="#B23B3B" stroke="none" d="M217.727 134V99.447l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#B23B3B" stroke="none" d="M210.86 99.447l12-8h6.867l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#55F" stroke="none" d="M221.847 116.724H228.714V134H221.847z" clipPath="url(#clipPath2)" />
                <path fill="#3B3BB2" stroke="none" d="M228.713 134v-17.276l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#3B3BB2" stroke="none" d="M221.847 116.724l12-8h6.866l-12 8z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" stroke="none" d="M232.833 125.362H239.7V134H232.833z" clipPath="url(#clipPath2)" />
                <path fill="#3BB23B" stroke="none" d="M239.7 134v-8.638l12-8V126z" clipPath="url(#clipPath2)" />
                <path fill="#3BB23B" stroke="none" d="M232.833 125.362l12-8h6.867l-12 8z" clipPath="url(#clipPath2)" />
            </g>
        </svg>
    );
}
export default Bar3DChartIcon;