/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Conf } from "../config/Conf"; 

export class ResourceSorting {
    
    public static sortByType = (a: string, b: string, desc: boolean, first?: string) => {
        if (desc) {
            [a, b] = [b, a]
        }
        if (first) {
            if (a !== b && a === first) {
                return -1;
            }
            if (a !== b && b === first) {
                return 1;
            }
        }
        return ResourceSorting.sortByName(a, b);
    }

    public static sortByName = (a: string, b: string, desc?: boolean): number => {
        if (desc) {
            [a, b] = [b, a]
        }
        a = a ? a : '';
        b = b ? b : '';

        return a.localeCompare(b, Conf.getLocale());
    }

    public static sortByDate = (a: string, b: string, desc: boolean): number => {
        if (desc) {
            [a, b] = [b, a]
        }
        a = a ? a : '';
        b = b ? b : '';
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA.getTime() - dateB.getTime();
    }
}
