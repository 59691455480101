/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import BarChartIcon from '@jss/js-common/src/svg/elements/highcharts/BarChartIcon';
import ColumnChartIcon from '@jss/js-common/src/svg/elements/highcharts/ColumnChartIcon';
import LineChartIcon from '@jss/js-common/src/svg/elements/highcharts/LineChartIcon';
import AreaChartIcon from '@jss/js-common/src/svg/elements/highcharts/AreaChartIcon';
import SplineChartIcon from '@jss/js-common/src/svg/elements/highcharts/SplineChartIcon';
import AreaSplineChartIcon from '@jss/js-common/src/svg/elements/highcharts/AreaSplineChartIcon';
import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../reducers';
import { ChartTypes } from '../../common/JrxmlModel/reader/JrxmlHTML5ChartUtils';
import StackedBarChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedBarChartIcon';
import StackedColumnChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedColumnChartIcon';
import StackedLineChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedLineChartIcon';
import StackedAreaChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedAreaChartIcon';
import StackedSplineChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedSplineChartIcon';
import StackedAreaSplineChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedAreaSplineChartIcon';
import StackedPercentBarChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedPercentBarChartIcon';
import StackedPercentColumnChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedPercentColumnChartIcon';
import StackedPercentLineChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedPercentLineChartIcon';
import StackedPercentAreaChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedPercentAreaChartIcon';
import StackedPercentSplineChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedPercentSplineChartIcon';
import StackedPercentAreaSplineChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedPercentAreaSplineChartIcon';
import PieChartIcon from '@jss/js-common/src/svg/elements/highcharts/PieChartIcon';
import TimeSeriesSplineChartIcon from '@jss/js-common/src/svg/elements/highcharts/TimeSeriesSplineChartIcon';
import TimeSeriesLineChartIcon from '@jss/js-common/src/svg/elements/highcharts/TimeSeriesLineChartIcon';
import TimeSeriesAreaChartIcon from '@jss/js-common/src/svg/elements/highcharts/TimeSeriesAreaChartIcon';
import TimeSeriesAreaSplineChartIcon from '@jss/js-common/src/svg/elements/highcharts/TimeSeriesAreaSplineChartIcon';
import ColumnLineChartIcon from '@jss/js-common/src/svg/elements/highcharts/ColumnLineChartIcon';
import ColumnSplineChartIcon from '@jss/js-common/src/svg/elements/highcharts/ColumnSplineChartIcon';
import StackedColumnLineChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedColumnLineChartIcon';
import StackedColumnSplineChartIcon from '@jss/js-common/src/svg/elements/highcharts/StackedColumnSplineChartIcon';
import MultiAxisLineChartIcon from '@jss/js-common/src/svg/elements/highcharts/MultiAxisLineChartIcon';
import MultiAxisSplineChartIcon from '@jss/js-common/src/svg/elements/highcharts/MultiAxisSplineChartIcon';
import MultiAxisColumnChartIcon from '@jss/js-common/src/svg/elements/highcharts/MultiAxisColumnChartIcon';
import ScatterChartIcon from '@jss/js-common/src/svg/elements/highcharts/ScatterChartIcon';
import BubbleChartIcon from '@jss/js-common/src/svg/elements/highcharts/BubbleChartIcon';
import SpiderColumnChartIcon from '@jss/js-common/src/svg/elements/highcharts/SpiderColumnChartIcon';
import SpiderLineChartIcon from '@jss/js-common/src/svg/elements/highcharts/SpiderLineChartIcon';
import SpiderAreaChartIcon from '@jss/js-common/src/svg/elements/highcharts/SpiderAreaChartIcon';
import DualLevelPieChartIcon from '@jss/js-common/src/svg/elements/highcharts/DualLevelPieChartIcon';
import HeatmapChartIcon from '@jss/js-common/src/svg/elements/highcharts/HeatmapChartIcon';
import TimeseriesHeatmapChartIcon from '@jss/js-common/src/svg/elements/highcharts/TimeseriesHeatmapChartIcon';
import SemiPieChartIcon from '@jss/js-common/src/svg/elements/highcharts/SemiPieChartIcon';
import TreeMapChartIcon from '@jss/js-common/src/svg/elements/highcharts/TreeMapChartIcon';
import OneParentTreeMapChartIcon from '@jss/js-common/src/svg/elements/highcharts/OneParentTreeMapChartIcon';
import TileMapChartIcon from '@jss/js-common/src/svg/elements/highcharts/TileMapChartIcon';
import HighMapChartIcon from '@jss/js-common/src/svg/elements/highcharts/HighMapChartIcon';
import GaugeIcon from '@jss/js-common/src/svg/elements/highcharts/GaugeIcon';
import ArcGaugeIcon from '@jss/js-common/src/svg/elements/highcharts/ArcGaugeIcon';
import MultiLevelGaugeIcon from '@jss/js-common/src/svg/elements/highcharts/MultiLevelGaugeIcon';
import DualMeasureTreeMapChartIcon from '@jss/js-common/src/svg/elements/highcharts/DualMeasureTreeMapChartIcon';

interface IChartElement {
  id: string;
  element?: any;
  zoom?: number;
  availableWidth: number,
  availableHeight: number,
}

const visualProperties = ["chartType"];

class ChartElement extends React.Component<IChartElement> {

  public shouldComponentUpdate = (nextProps: Readonly<IChartElement>) => {
    if (this.props.availableHeight !== nextProps.availableHeight || this.props.availableWidth !== nextProps.availableWidth) {
      return true;
    }

    if (this.props.zoom !== nextProps.zoom) {
      return true;
    }
    for (const propName of visualProperties) {
      if (this.props.element.get(propName) !== nextProps.element.get(propName)) {
        return true;
      }
    }

    return false; // Nothing impacting the content of the figure is changed...
  }

  public render() {
    let image = <BarChartIcon width='100%' height='100%' preserveAspectRatio='none' />;
    const chartType = this.props.element.get("chartType");
    if (chartType === ChartTypes.BAR_CHART_NAME) {
      image = <BarChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.COLUMN_CHART_NAME) {
      image = <ColumnChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.LINE_CHART_NAME) {
      image = <LineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.AREA_CHART_NAME) {
      image = <AreaChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.SPLINE_CHART_NAME) {
      image = <SplineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.AREA_SPLINE_CHART_NAME) {
      image = <AreaSplineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_BAR_CHART_NAME) {
      image = <StackedBarChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_COLUMN_CHART_NAME) {
      image = <StackedColumnChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_LINE_CHART_NAME) {
      image = <StackedLineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_AREA_CHART_NAME) {
      image = <StackedAreaChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_SPLINE_CHART_NAME) {
      image = <StackedSplineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_AREA_SPLINE_NAME) {
      image = <StackedAreaSplineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_PERCENT_BAR_NAME) {
      image = <StackedPercentBarChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_PERCENT_COLUMN_NAME) {
      image = <StackedPercentColumnChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_PERCENT_LINE_NAME) {
      image = <StackedPercentLineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_PERCENT_AREA_NAME) {
      image = <StackedPercentAreaChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_PERCENT_SPLINE_NAME) {
      image = <StackedPercentSplineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_PERCENT_AREA_SPLINE_NAME) {
      image = <StackedPercentAreaSplineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.PIE_NAME) {
      image = <PieChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.TIME_SERIES_SPLINE_NAME) {
      image = <TimeSeriesSplineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.TIME_SERIES_LINE_NAME) {
      image = <TimeSeriesLineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.TIME_SERIES_AREA_NAME) {
      image = <TimeSeriesAreaChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.TIME_SERIES_AREA_SPLINE_NAME) {
      image = <TimeSeriesAreaSplineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.COLUMN_LINE_NAME) {
      image = <ColumnLineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.COLUMN_SPLINE_NAME) {
      image = <ColumnSplineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_COLUMN_LINE_NAME) {
      image = <StackedColumnLineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_COLUMN_SPLINE_NAME) {
      image = <StackedColumnSplineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.MULTIAXIS_LINE_NAME) {
      image = <MultiAxisLineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.MULTIAXIS_SPLINE_NAME) {
      image = <MultiAxisSplineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.MULTIAXIS_COLUMN_NAME) {
      image = <MultiAxisColumnChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.SCATTER_NAME) {
      image = <ScatterChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.BUBBLE_NAME) {
      image = <BubbleChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.SPIDER_COLUMN_NAME) {
      image = <SpiderColumnChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.SPIDER_LINE_NAME) {
      image = <SpiderLineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.SPIDER_AREA_NAME) {
      image = <SpiderAreaChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.DUAL_LEVEL_PIE_NAME) {
      image = <DualLevelPieChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.HEATMAP_NAME) {
      image = <HeatmapChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.TIMESERIES_HEATMAP_NAME) {
      image = <TimeseriesHeatmapChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.SEMIPIE_NAME) {
      image = <SemiPieChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.TREEMAP_NAME) {
      image = <TreeMapChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.ONE_PARENT_TREEMAP_NAME) {
      image = <OneParentTreeMapChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.TILEMAP_NAME) {
      image = <TileMapChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.HIGHMAP_NAME) {
      image = <HighMapChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.GAUGE_NAME) {
      image = <GaugeIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.ARCGAUGE_NAME) {
      image = <ArcGaugeIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.MULTILEVEL_GAUGE_NAME) {
      image = <MultiLevelGaugeIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.DUAL_MEASURE_TREE_MAP) {
      image = <DualMeasureTreeMapChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    }
    return <div style={{ width: '100%', height: '100%' }}>
      {image}
    </div>;
  }
}

const mapStateToProps = (state: IState, props: IChartElement) => {

  return {
    element: state.getIn(['report', 'model', 'elements', props.id]),
    model: state.getIn(['report', 'model']),
    zoom: state.getIn(['report', 'zoom']),
  };
}

export default connect(mapStateToProps)(ChartElement);   // ,mapDispatchToProps