/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';

import '../../../../assets/uxpl/css/DragElement.css';

interface IDragElement {
    zoom: number,
    key: string,
    className?: string,
    style: {
        top: number,
        left: number,
        width: number,
        height: number
    }
}


const DragElement = (props: IDragElement) => {

        const dashSize = Math.round(4/props.zoom);

        let border = null;
        
        switch (props.className) {
            case "DragElement":
                border = (<svg className="DragElementBorder" style={{position: 'absolute'}} width={Math.max(props.style.width * props.zoom, 1)} height={Math.max(props.style.height * props.zoom, 1)}>
                    <rect width="100%" height="100%" fill="none" shapeRendering="optimizeSpeed" strokeWidth={1/props.zoom} strokeDasharray={ dashSize + ' ' + dashSize } stroke="#000000"/>
                </svg>);
                break;
            case "DragSelection":
                border = (<svg className="DragElementBorder" style={{position: 'absolute'}} width={Math.max(props.style.width * props.zoom, 1)} height={Math.max(props.style.height * props.zoom, 1)}>
                    <rect width="100%" height="100%" fill="none" shapeRendering="optimizeSpeed" strokeWidth={1/props.zoom} stroke="rgb(29, 103, 149)"/>
                </svg>);
                break;
        }

        return (<div {...props } style={{...props.style, top: props.style.top * props.zoom, left: props.style.left * props.zoom, width: props.style.width * props.zoom, height: props.style.height * props.zoom }}>
                   { border } 
                </div>)
}

export default DragElement;