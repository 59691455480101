/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../ui/APDescriptor";
import { PCategories } from "../../ui/composite/UPCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { MDivElementDataset } from "../dataset/MDatasetRun"; 
import { PValueExpression } from "../../ui/primitive/UPValueExpession";
import { PString } from "../../ui/primitive/UPString";
import { PInt } from "../../ui/primitive/UPInteger";
import { PEnum } from "../../ui/primitive/UPEnum";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { PColor } from "../../ui/primitive/UPColor";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PConnectedTableDetail } from "../../ui/tables/UPConnectedTableDetail";
import { httpValidator } from "@jss/js-common/src/utils/validators";
import { PExpression } from "../../ui/primitive/UPExpression";

export const FMapMarker = {
    title: new PValueExpression({ id: 'title', vtype: new PString({ id: "title", label: 'Title', description: 'Marker Title' }) }),
    latitude: new PValueExpression({ id: 'latitude', vtype: new PInt({ id: "latitude", label: 'Latitude', description: 'Marker latitude', min: -85, max: 85, default: 37.7833 }) }),
    longitude: new PValueExpression({ id: 'longitude', vtype: new PInt({ id: "longitude", label: 'Longitude', description: 'Marker longitude', min: -180, max: 180, default: -122.4167 }) }),
    address: new PValueExpression({ id: 'address', vtype: new PString({ id: "address", label: 'Address', description: 'Marker address' }) }),
    url: new PValueExpression({ id: 'url', vtype: new PString({ id: "url", label: 'URL', description: 'Marker URL', validator: httpValidator }) }),
    target: new PValueExpression({
        id: 'target', vtype: new PEnum({
            id: "target", label: 'Target', description: 'Marker target', items: [{ key: "", value: "" }, { key: "_blank", value: "Blank" },
            { key: "_parent", value: "Parent" },
            { key: "_self", value: "Self" },
            { key: "_top", value: "Top" }]
        })
    }),

    icon: new PValueExpression({ id: 'icon', vtype: new PString({ id: "icon", label: 'Icon', description: 'Representing the icon URL', validator: httpValidator }) }),
    iconurl: new PValueExpression({ id: 'icon.url', vtype: new PString({ id: "icon.url", label: 'URL', description: '(representing the custom icon URL). If set, it takes precedence over the icon property.', validator: httpValidator }) }),
    iconwidth: new PValueExpression({ id: 'icon.width', vtype: new PInt({ id: "icon.width", label: 'Width', description: 'Marker icon width', min: 0 }) }),
    iconheight: new PValueExpression({ id: 'icon.height', vtype: new PInt({ id: "icon.height", label: 'Height', description: 'Marker icon height', min: 0 }) }),
    iconoriginx: new PValueExpression({ id: 'icon.origin.x', vtype: new PInt({ id: "icon.origin.x", label: 'Origin X', description: 'Integer value representing the horizontal position of the image within a sprite, if any, in pixels.', default: 0 }) }),
    iconoriginy: new PValueExpression({ id: 'icon.origin.y', vtype: new PInt({ id: "icon.origin.y", label: 'Origin Y', description: 'Integer value representing the vertical position of the image within a sprite, if any, in pixels.', default: 0 }) }),
    iconanchorx: new PValueExpression({ id: 'icon.anchor.x', vtype: new PInt({ id: "icon.anchor.x", label: 'Anchor X', description: 'Integer value representing the horizontal position to anchor the marker icon with respect to the location of the marker on the map.', default: 0 }) }),
    iconanchory: new PValueExpression({ id: 'icon.anchor.y', vtype: new PInt({ id: "icon.anchor.y", label: 'Anchor Y', description: 'Integer value representing the vertical position to anchor the marker icon with respect to the location of the marker on the map.', default: 0 }) }),

    shadow: new PValueExpression({ id: 'shadow', vtype: new PString({ id: "shadow", label: 'Shadow', description: 'Representing the shadow icon URL', validator: httpValidator }) }),
    shadowurl: new PValueExpression({ id: 'shadow.url', vtype: new PString({ id: "shadow.url", label: 'URL', description: '(representing the custom icon URL). If set, it takes precedence over the icon property.', validator: httpValidator }) }),
    shadowwidth: new PValueExpression({ id: 'shadow.width', vtype: new PInt({ id: "shadow.width", label: 'Width', description: 'Marker icon width', min: 0 }) }),
    shadowheight: new PValueExpression({ id: 'shadow.height', vtype: new PInt({ id: "shadow.height", label: 'Height', description: 'Marker icon height', min: 0 }) }),
    shadoworiginx: new PValueExpression({ id: 'shadow.origin.x', vtype: new PInt({ id: "shadow.origin.x", label: 'Origin X', description: 'Integer value representing the horizontal position of the image within a sprite, if any, in pixels.', default: 0 }) }),
    shadoworiginy: new PValueExpression({ id: 'shadow.origin.y', vtype: new PInt({ id: "shadow.origin.y", label: 'Origin Y', description: 'Integer value representing the vertical position of the image within a sprite, if any, in pixels.', default: 0 }) }),
    shadowanchorx: new PValueExpression({ id: 'shadow.anchor.x', vtype: new PInt({ id: "shadow.anchor.x", label: 'Anchor X', description: 'Integer value representing the horizontal position to anchor the marker icon with respect to the location of the marker on the map.', default: 0 }) }),
    shadowanchory: new PValueExpression({ id: 'shadow.anchor.y', vtype: new PInt({ id: "shadow.anchor.y", label: 'Anchor Y', description: 'Integer value representing the vertical position to anchor the marker icon with respect to the location of the marker on the map.', default: 0 }) }),

    infowindowcontent: new PValueExpression({ id: 'infowindow.content', vtype: new PString({ id: "infowindow.content", label: 'Content', description: 'Represents the content to be displayed in an info window, processed as HTML fragment. Is mandatory for info windows. Empty contents are not processed.' }) }),
    infowindowpixelOffset: new PValueExpression({ id: 'infowindow.pixelOffset', vtype: new PInt({ id: "infowindow.pixelOffset", label: 'Pixel Offset', description: 'Represents the offset (in pixels) from the tip of the info window to the location on which the info window is anchored.' }) }),
    infowindowmaxWidth: new PValueExpression({ id: 'infowindow.maxWidth', vtype: new PInt({ id: "infowindow.maxWidth", label: 'Max Width', description: 'Specifies the maximum width in pixels of the info window.', min: 0 }) }),


    color: new PValueExpression({ id: 'color', vtype: new PColor({ id: "color", label: 'Color', description: 'The color for the default pin icons. This property is taken into account when none of icon or icon. url properties are set.' }) }),
    label: new PValueExpression({ id: 'label', vtype: new PString({ id: "label", label: 'Label', description: 'The label for the default pin icons. This property is taken into account when none of icon or icon. url properties are set. \nNote: Unlike interactive maps, Google static maps require single uppercase alphanumeric character labels. If multiple character labels are set, the JR engine will process the entire label for dynamic maps and only the first character in the label for static maps. Labels are displayed only for default and mid sized markers, small and tiny markers provide no capability to display labels.' }) }),
    cursor: new PValueExpression({ id: 'cursor', vtype: new PString({ id: "cursor", label: 'Cursor', description: 'Cursor' }) }),
    zIndex: new PValueExpression({ id: 'zIndex', vtype: new PInt({ id: "zIndex", label: 'zIndex', description: 'zIndex', min: 0 }) }),
    clickable: new PValueExpression({ id: 'clickable', vtype: new PBoolean({ id: "clickable", label: 'Clickable', description: 'clickable', canBeNull: true }) }),
    draggable: new PValueExpression({ id: 'draggable', vtype: new PBoolean({ id: "draggable", label: 'Ddraggable', description: 'Draggable', canBeNull: true }) }),
    flat: new PValueExpression({ id: 'flat', vtype: new PBoolean({ id: "flat", label: 'Flat', description: 'Flat', canBeNull: true }) }),
    optimized: new PValueExpression({ id: 'optimized', vtype: new PBoolean({ id: "optimized", label: 'Optimized', description: 'Optimized', canBeNull: true }) }),
    raiseOnDrag: new PValueExpression({ id: 'raiseOnDrag', vtype: new PBoolean({ id: "raiseOnDrag", label: 'Raise On Drag', description: 'Raise on drag', canBeNull: true }) }),
    visible: new PValueExpression({ id: 'visible', vtype: new PBoolean({ id: "visible", label: 'Visible', description: 'Visible', canBeNull: true }) }),
    size: new PValueExpression({ id: 'size', vtype: new PInt({ id: "size", label: 'Size', description: 'Specifies the marker size (for the built-in markers only)' }) })

};

export const MMapMarker: APDescriptor = new PCategories({
    id: 'map.markers',
    layouts: [
        new PDiv({
            id: 'map.markers.markers',
            label: 'Markers',
            layouts: [
                new PExpression({ id: 'seriesNameExpression', label: 'Series Name Expression' }),
                new PExpression({ id: 'markerClusteringExpression', label: 'Marker Clustering Expression' }),
                new PExpression({ id: 'markerSpideringExpression', label: 'Marker Spidering Expression' }),
                new PExpression({ id: 'legendIconExpression', label: 'Legend Icon Expression' }),
                new PConnectedTableDetail({
                id: 'items', label: 'Markers', rowLabel: 'Marker', rowLabelPaths: ['title/value', 'title/valueExpression'], expanded: true,
                layouts: [new PDiv({
                    label: 'Marker', 
                    layouts: [FMapMarker.title,
                    FMapMarker.latitude, FMapMarker.longitude, FMapMarker.address,
                    FMapMarker.url, FMapMarker.target,
                    new PAccordeon({
                        id: 'markericon', label: "Icon", layouts: [FMapMarker.icon, FMapMarker.iconurl,
                        FMapMarker.iconwidth, FMapMarker.iconheight,
                        FMapMarker.iconoriginx, FMapMarker.iconoriginy,
                        FMapMarker.iconanchorx, FMapMarker.iconanchory]
                    }),
                    new PAccordeon({
                        id: 'markershadow', label: "Shadow Icon", layouts: [FMapMarker.shadow, FMapMarker.shadowurl,
                        FMapMarker.shadowwidth, FMapMarker.shadowheight,
                        FMapMarker.shadoworiginx, FMapMarker.shadoworiginy,
                        FMapMarker.shadowanchorx, FMapMarker.shadowanchory]
                    }),
                    new PAccordeon({
                        id: 'markerinfoWindow', label: "Info Window", layouts: [FMapMarker.infowindowcontent, FMapMarker.infowindowpixelOffset,
                        FMapMarker.infowindowmaxWidth]
                    }),
                    FMapMarker.color, FMapMarker.label, FMapMarker.cursor, FMapMarker.zIndex, FMapMarker.clickable, FMapMarker.draggable, FMapMarker.flat, FMapMarker.optimized, FMapMarker.raiseOnDrag, FMapMarker.visible, FMapMarker.size]
                })]
            })]
        }),
        { ...MDivElementDataset, path: 'dataset' }
    ]
});
