/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { connect } from 'react-redux';
import * as ReportActions from '../../../../../actions/reportActions';
import { getPath, reg } from '../../ui/UiProperty';
import { PString } from './UPString';
import { UPUniqueName } from './UPUniqueName';


interface IPrameterName {
    setParameterValue: (parameterName: string, value: any) => void;
}


class UPParameterName extends UPUniqueName<IPrameterName> {

    public onValueChange = (newValue: string | undefined) => {
        //this is called only if the value is valid, no need to redo the validation
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const oldName = this.props.mcontext.model.getIn(p) as string | undefined;
        const oldParameterValue = this.props.mcontext.rootModel.getIn(['parametersValue', oldName]);
        const isMain = !getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path).includes('subdatasets');

        this.props.mcontext.elements.forEach(key => {
            if (this.props.mcontext.descriptor.id) {
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: newValue });
            } else {
                this.props.mcontext.setObjectProperties(key.path, newValue);
            }
        });
        if (oldParameterValue && newValue && isMain){
            this.props.setParameterValue(oldName, undefined);
            this.props.setParameterValue(newValue, oldParameterValue);
        }
    }

}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setParameterValue: (parameterName: string, value: any) => { dispatch(ReportActions.setParameterValue(parameterName, value)); },
    };
}

const ConnectedParameterClass = connect(undefined, mapDispatchToProps)(UPParameterName);

const TYPEID = 'parameterName';
reg(TYPEID, (mc) => { return <ConnectedParameterClass mcontext={mc} />; });
export class PParameterName extends PString {
    public constructor(init: Partial<PParameterName>) {
        super(init);
        Object.assign(this, { ...init, type: TYPEID });
    }
}