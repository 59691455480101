/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { getNormalizedPath, reg, UiProperty, UiPropertyProps } from '../UiProperty';
import { APDescriptor } from '../APDescriptor';
import { MDSPROPERTYEXPRESSION_TYPEID, MPROPERTIESEXPRESSION_TYPEID, PExpression, UPExpression } from './UPExpression';
import _ from 'lodash';
import i18n from '../../../../../i18n';
import { UPString } from './UPString';
import { UPFloat } from './UPFloat';
import { UPInteger } from './UPInteger';
import { UPEnum } from './UPEnum';
import UPValidatedPath from './UPValidatedPath';
import { ICONS } from '@jss/js-common';
import { UPBoolean } from './UPBoolean';
import { UPColor } from './UPColor';
import { UPButtonsEnum } from './UPButtonsEnum';
import UPFontName from './UPFontName';
import { UPNumberUnit } from './UPNumberUnit';
import { UPJavaClass } from './UPJavaClass';

const TYPEID = 'valueExpression';
const DS_PROPERTY_TYPEID = 'DsValueExpression';
const PROPERTY_TYPE_ID = 'propertyExpression';

reg(TYPEID, (mc) => { return <UPValueExpression mcontext={mc} />; });
reg(DS_PROPERTY_TYPEID, (mc) => { return <UPValueExpression mcontext={mc} />; });
reg(PROPERTY_TYPE_ID, (mc) => { return <UPValueExpression mcontext={mc} />; });
export class PValueExpression extends APDescriptor {
    vtype: APDescriptor;
    etype: string = PExpression.type;
    fieldPrefix?: string = 'value';
    hasValue?: boolean = true;
    onlyValue?: boolean = false;
    scopes?: string[];
    public constructor(init: Partial<PValueExpression>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
export class PPropertyExpressionValue extends PValueExpression {
    public constructor(init: Partial<PValueExpression>) {
        super(init);
        Object.assign(this, { ...init, type: PROPERTY_TYPE_ID, fieldPrefix: 'property' });
    }
}
export class PPropertyExpression extends PValueExpression {
    public constructor(init: Partial<PValueExpression>) {
        super(init);
        Object.assign(this, { ...init, type: PROPERTY_TYPE_ID, fieldPrefix: 'property', hasValue: false });
    }
}

export class PPropertiesExpression extends PValueExpression {
    public constructor(init: Partial<PValueExpression>) {
        super(init);
        Object.assign(this, { ...init, type: DS_PROPERTY_TYPEID, etype: MPROPERTIESEXPRESSION_TYPEID });
    }
}
export class PDsValueExpression extends PValueExpression {
    public constructor(init: Partial<PValueExpression>) {
        super(init);
        Object.assign(this, { ...init, type: DS_PROPERTY_TYPEID, etype: MDSPROPERTYEXPRESSION_TYPEID });
    }
}

export interface IUPNumberUnit {
    additionalIcon?: React.ReactElement,
}

export class UPValueExpression extends React.Component<UiPropertyProps> {

    render() {
        const d = this.props.mcontext.descriptor as PValueExpression;
        const p = getNormalizedPath(this.props.mcontext.elements[0].path);
        p.shift();
        if (d.id !== undefined && d.id !== '')
            p.push(d.id);
        p.push(this.getFieldName());
        let isExp = d.onlyValue !== true && (!d.hasValue || this.props.mcontext.model.getIn(p) === undefined);
        p.pop();
        if (isExp) {
            p.push(this.getFieldExpressionName());
            isExp = !d.hasValue || this.props.mcontext.model.getIn(p) !== undefined;
            p.pop();
        }

        const els = _.cloneDeep(this.props.mcontext.elements);
        els.forEach(k => { k.path = [[...p]]; });
        const baseLabel = d.label ? i18n.t(d.label) : (d.vtype.label ? d.vtype.label : d.id);
        const label = baseLabel;// && baseLabel.length > 30 ? baseLabel.substring(0, 10) + '...' + baseLabel.substring(baseLabel.length - 17, baseLabel.length) : baseLabel;
        let tooltip = d.vtype.description ? i18n.t(d.vtype.description) : '';
        const desc = d.vtype.description ? i18n.t(d.vtype.description) : '';
        // if (baseLabel.length > 20) {
        tooltip = `${tooltip} \n(${d.id})`;
        // }
        const newd = isExp ? { ...d.vtype, id: this.getFieldExpressionName(), type: d.etype, label: label, description: desc } : { ...d.vtype, id: this.getFieldName(), label: label, description: desc };
        const mc = { ...this.props.mcontext, descriptor: newd, elements: els };
        // here if scopes, set inheredProperty id
        if (d.scopes && d.scopes.length > 1) {
            mc.descriptor.inheritedPropertyId = d.id;
        }
        if (d.hasValue) {
            if (d.onlyValue) {
                return <UiProperty mcontext={mc} />;
            }
            const switchIcon = <div className="jr-MuiInputBase-input mui jr-jrws-button-text-field-button"
                style={{ margin: 0, marginLeft: 5, justifyContent: 'flex-start', padding: 0, fontSize: 18, cursor: 'pointer' }}
                onClick={() => this.onChange(isExp)} title={isExp ? i18n.t('ExpressionValue.switchToValue') : i18n.t('ExpressionValue.switchToExpression')}>
                {isExp ? ICONS.EXPRESSION_ICON : ICONS.VALUE_ICON}
            </div>;
            let control = <div style={{ display: 'grid', gridTemplateColumns: '1fr 23px', marginRight: '5px' }} title={tooltip}>
                <UiProperty mcontext={mc} />
                {switchIcon}
            </div>;
            if (isExp) {
                if (d.etype === 'MDsPropertyExpression' || d.etype === 'expression' || d.etype === 'MPropertiesExpression') {
                    control = <UPExpression mcontext={mc} additionalIcon={switchIcon} />
                }
            } else if (!isExp && d.vtype) {
                if (d.vtype.type === 'string') {
                    control = <UPString mcontext={mc} additionalIcon={switchIcon} />
                } else if (d.vtype.type === 'int') {
                    control = <UPFloat mcontext={mc} additionalIcon={switchIcon} />
                } else if (d.vtype.type === 'float') {
                    control = <UPInteger mcontext={mc} additionalIcon={switchIcon} />
                } else if (d.vtype.type === 'enum') {
                    control = <UPEnum mcontext={mc} additionalIcon={switchIcon} />
                } else if (d.vtype.type === 'connectedValidatedPath' || d.vtype.type === 'ValidatedPath') {
                    control = <UPValidatedPath mcontext={mc} additionalIcon={switchIcon} />
                } else if (d.vtype.type === 'boolean') {
                    control = <UPBoolean mcontext={mc} additionalIcon={switchIcon} />
                } else if (d.vtype.type === 'color') {
                    control = <UPColor mcontext={mc} additionalIcon={switchIcon} />
                } else if (d.vtype.type === 'enumButtons') {
                    control = <UPButtonsEnum mcontext={mc} additionalIcon={switchIcon} />
                } else if (d.vtype.type === 'fontname' || d.vtype.type === 'connectedFontName') {
                    control = <UPFontName mcontext={mc} additionalIcon={switchIcon} />
                } else if (d.vtype.type === 'numberunit') {
                    control = <UPNumberUnit mcontext={mc} additionalIcon={switchIcon} />
                } else if (d.vtype.type === 'javaClass') {
                    control = <UPJavaClass mcontext={mc} additionalIcon={switchIcon} />
                }
            }

            return control;
        }
        return <UiProperty mcontext={mc} />;
    }

    public onChange = (isExp: boolean) => {
        this.props.mcontext.elements.forEach(key => {
            const propertyPath = getNormalizedPath(key.path);
            //remove the model from the path, since set properties will add it anyway
            propertyPath.shift();
            if (this.props.mcontext.descriptor.id === undefined || this.props.mcontext.descriptor.id.length !== 0) {
                propertyPath.push(this.props.mcontext.descriptor.id);
            }
            const paths = [...propertyPath];
            if (!isExp) {
                // in case it is an value, delete the value
                paths.push(this.getFieldName())
                this.props.mcontext.deleteElement(paths);
                paths.pop();
                paths.push(this.getFieldExpressionName());
                if (this.props.mcontext.model.getIn(paths) === undefined) {
                    paths.pop();
                    const oldValueExpressionPath = [...propertyPath, 'oldValueExpression'];
                    const oldValueExpression = this.props.mcontext.model.getIn(oldValueExpressionPath, '');
                    const valuePath = [...propertyPath, this.getFieldName()];
                    const value = this.props.mcontext.model.getIn(valuePath);
                    this.props.mcontext.setObjectProperties(paths, { [this.getFieldExpressionName()]: oldValueExpression, [this.getFieldName()]: undefined, oldValue: value });
                }
            } else {
                const valueExpressionPath = [...propertyPath, this.getFieldExpressionName()];
                const oldValuePath = [...propertyPath, 'oldValue'];
                const oldValue = this.props.mcontext.model.getIn(oldValuePath, '');
                const valueExpression = this.props.mcontext.model.getIn(valueExpressionPath);
                const newValue = { [this.getFieldName()]: oldValue, [this.getFieldExpressionName()]: undefined, oldValueExpression: valueExpression }
                this.props.mcontext.setObjectProperties(paths, newValue);
            }
        });
    }

    private getFieldName(): string {
        const d = this.props.mcontext.descriptor as PValueExpression;
        return d.fieldPrefix ? d.fieldPrefix : 'value';
    }
    private getFieldExpressionName(): string {
        const d = this.props.mcontext.descriptor as PValueExpression;
        return d.fieldPrefix ? d.fieldPrefix + 'Expression' : 'valueExpression';
    }

}