/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StackedBar3DChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M44 4v130h218V4z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M24.51 157.188L245.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M24.51 173.5L245.49 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M24.51 173.5L24.51 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M245.49 173.5L245.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="#F55" stroke="none" d="M28.01 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="40.01" y="168.113" stroke="none" clipPath="url(#clipPath1)">Double Freight</text>
            <path fill="#55F" stroke="none" d="M105.22 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="117.221" y="168.113" stroke="none" clipPath="url(#clipPath1)">Normal Freight</text>
            <path fill="#5F5" stroke="none" d="M183.17 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="195.17" y="168.113" stroke="none" clipPath="url(#clipPath1)">Half Freight</text>
            <path fill="#DDD" stroke="none" d="M44 134V12l12-8v122z" clipPath="url(#clipPath1)" />
            <path fill="#DDD" stroke="none" d="M44 134l12-8h206l-12 8z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="silver" d="M44 134L56 126" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="silver" d="M56 126L56 4" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="silver" d="M56 126L262 126" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M44 134L250 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M68.72 134L68.72 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M68.72 134L68.72 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M107.86 134L107.86 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M107.86 134L107.86 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M147 134L147 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M147 134L147 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M186.14 134L186.14 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M186.14 134L186.14 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M225.28 134L225.28 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M225.28 134L225.28 136" clipPath="url(#clipPath1)" />
            <text x="55.525" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Arge...</text>
            <text x="93.729" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Austria</text>
            <text x="130.602" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Belgium</text>
            <text x="174.978" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Brazil</text>
            <text x="210.059" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Canada</text>
            <path fill="none" strokeWidth="0.5" d="M44 12L44 134" clipPath="url(#clipPath1)" />
            <text x="32.91" y="136.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
            <path fill="none" strokeWidth="0.5" d="M42 134L44 134" clipPath="url(#clipPath1)" />
            <text x="15.098" y="114.313" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">5.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 111.544L44 111.544" clipPath="url(#clipPath1)" />
            <text x="10.008" y="91.857" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 89.087L44 89.087" clipPath="url(#clipPath1)" />
            <text x="10.008" y="69.4" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">15.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 66.631L44 66.631" clipPath="url(#clipPath1)" />
            <text x="10.008" y="46.944" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">20.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 44.174L44 44.174" clipPath="url(#clipPath1)" />
            <text x="10.008" y="24.488" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">25.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 21.718L44 21.718" clipPath="url(#clipPath1)" />
         </g>
         <g fill="silver" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M44 134L56 126" clipPath="url(#clipPath2)" />
            <path fill="none" d="M56 126L262 126" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 111.544L56 103.544" clipPath="url(#clipPath2)" />
            <path fill="none" d="M56 103.544L262 103.544" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 89.087L56 81.087" clipPath="url(#clipPath2)" />
            <path fill="none" d="M56 81.087L262 81.087" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 66.631L56 58.631" clipPath="url(#clipPath2)" />
            <path fill="none" d="M56 58.631L262 58.631" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 44.174L56 36.174" clipPath="url(#clipPath2)" />
            <path fill="none" d="M56 36.174L262 36.174" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 21.718L56 13.718" clipPath="url(#clipPath2)" />
            <path fill="none" d="M56 13.718L262 13.718" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M54.3 134h28.84l12-8H66.3zM95.14 120.623V126H66.3v-5.377z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M54.3 128.623V134l12-8v-5.377zM83.14 128.623V134l12-8v-5.377z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M54.3 128.623h28.84l12-8H66.3z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M54.3 128.623h28.84V134H54.3z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M54.3 128.623h28.84l12-8H66.3z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M95.14 117.935v2.688H66.3v-2.688zM54.3 125.935v2.688l12-8v-2.688z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M83.14 125.935v2.688l12-8v-2.688z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M54.3 125.935h28.84l12-8H66.3z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M54.3 125.935h28.84v2.688H54.3z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M54.3 125.935h28.84l12-8H66.3zM95.14 116.59v1.345H66.3v-1.344z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M54.3 124.59v1.345l12-8v-1.344zM83.14 124.59v1.345l12-8v-1.344z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M54.3 124.59h28.84l12-8H66.3z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M54.3 124.59h28.84v1.345H54.3z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M93.44 134h28.84l12-8h-28.84zM134.28 59.605V126h-28.84V59.605z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M93.44 67.605V134l12-8V59.605zM122.28 67.605V134l12-8V59.605z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M93.44 67.605h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M93.44 67.605h28.84V134H93.44z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M93.44 67.605h28.84l12-8h-28.84zM134.28 26.408v33.197h-28.84V26.408z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M93.44 34.408v33.197l12-8V26.408zM122.28 34.408v33.197l12-8V26.408z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M93.44 34.408h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M93.44 34.408h28.84v33.197H93.44z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M93.44 34.408h28.84l12-8h-28.84zM134.28 9.81v16.598h-28.84V9.81z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M93.44 17.81v16.598l12-8V9.81zM122.28 17.81v16.598l12-8V9.81z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M93.44 17.81h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M93.44 17.81h28.84v16.598H93.44z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M132.58 134h28.84l12-8h-28.84zM173.42 114.501V126h-28.84v-11.499z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M132.58 122.501V134l12-8v-11.499zM161.42 122.501V134l12-8v-11.499z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M132.58 122.501h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M132.58 122.501h28.84V134h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M132.58 122.501h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M173.42 108.752v5.75h-28.84v-5.75z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M132.58 116.752v5.75l12-8v-5.75zM161.42 116.752v5.75l12-8v-5.75z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M132.58 116.752h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M132.58 116.752h28.84v5.75h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M132.58 116.752h28.84l12-8h-28.84zM173.42 105.877v2.875h-28.84v-2.875z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M132.58 113.877v2.875l12-8v-2.875zM161.42 113.877v2.875l12-8v-2.875z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M132.58 113.877h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M132.58 113.877h28.84v2.875h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M171.72 134h28.84l12-8h-28.84zM212.56 82.163V126h-28.84V82.163z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M171.72 90.163V134l12-8V82.163zM200.56 90.163V134l12-8V82.163z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M171.72 90.163h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M171.72 90.163h28.84V134h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M171.72 90.163h28.84l12-8h-28.84zM212.56 60.245v21.918h-28.84V60.245z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M171.72 68.245v21.918l12-8V60.245zM200.56 68.245v21.918l12-8V60.245z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M171.72 68.245h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M171.72 68.245h28.84v21.918h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M171.72 68.245h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M212.56 49.286v10.96h-28.84v-10.96zM171.72 57.286v10.96l12-8v-10.96z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M200.56 57.286v10.96l12-8v-10.96zM171.72 57.286h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M171.72 57.286h28.84v10.96h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M210.86 134h28.84l12-8h-28.84zM251.7 106.255V126h-28.84v-19.745z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M210.86 114.255V134l12-8v-19.745zM239.7 114.255V134l12-8v-19.745z" clipPath="url(#clipPath2)" />
            <path fill="#B23B3B" stroke="none" d="M210.86 114.255h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M210.86 114.255h28.84V134h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M210.86 114.255h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M251.7 96.383v9.873h-28.84v-9.873z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M210.86 104.383v9.873l12-8v-9.873zM239.7 104.383v9.873l12-8v-9.873z" clipPath="url(#clipPath2)" />
            <path fill="#3B3BB2" stroke="none" d="M210.86 104.383h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M210.86 104.383h28.84v9.873h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M210.86 104.383h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M251.7 91.447v4.936h-28.84v-4.936z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M210.86 99.447v4.936l12-8v-4.936zM239.7 99.447v4.936l12-8v-4.936z" clipPath="url(#clipPath2)" />
            <path fill="#3BB23B" stroke="none" d="M210.86 99.447h28.84l12-8h-28.84z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M210.86 99.447h28.84v4.936h-28.84z" clipPath="url(#clipPath2)" />
         </g>
      </svg>
   );
}
export default StackedBar3DChartIcon;