
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { Button as JSSButton } from '@jss/js-common';
import { RunContext } from '@jss/js-repository';
import { DatasourceApi, IRepositoryItemDescriptor, RepositoryApi } from '@jss/js-rest-api';
import TreeView from '@material-ui/lab/TreeView';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as ReportActions from '../../../actions/reportActions';
import '../../../assets/uxpl/css/Outline.css';
import i18n from '../../../i18n';
import { IState } from '../../../reducers';
import NodeElement from './NodeElement';

interface IMetadataTab {
    report?: Map<string, any>;
    descriptor?: IRepositoryItemDescriptor | undefined,
    metadataContainer: Map<string, any>;
    setObjectProperties?: (path: string[], value: any) => void;
    storeInCache?: (metadataContainer: Map<string, any>) => void;
}

interface IMetadataTabState {
    isGettinMetdatadaInProgress: boolean,
    selectedNode: string,
}


class MetadataTab extends React.Component<IMetadataTab, IMetadataTabState> {

    public state = {
        isGettinMetdatadaInProgress: false,
        selectedNode: undefined,
    }

    private getReportPath = () => {
        return RepositoryApi.getAPath(this.props.descriptor);
    }

    public shouldComponentUpdate(nextProps: Readonly<IMetadataTab>, nextState: Readonly<IMetadataTabState>): boolean {
        const currentEditorIndex = this.props.report.get('currentEditorIndex');
        const nextEditorIndex = nextProps.report.get('currentEditorIndex');
        if (nextEditorIndex !== currentEditorIndex) return true;
        if (this.props.descriptor !== nextProps.descriptor || this.props.metadataContainer !== nextProps.metadataContainer) return true;
        if (this.state.selectedNode !== nextState.selectedNode || this.state.isGettinMetdatadaInProgress !== nextState.isGettinMetdatadaInProgress) return true;
        return false;
    }

    private isButtonEnabled = () => {
        const editorIndex = this.props.report.get('currentEditorIndex');
        let editedResourceId = this.props.report.getIn(['subeditors', editorIndex, 'editedResourceId']) as string;
        let dataset;
        if (editedResourceId && editedResourceId !== 'main') {
            dataset = this.props.report.getIn(['model', 'subdatasets', editedResourceId]);
            editedResourceId = dataset.get('name');
        } else {
            dataset = this.props.report.getIn(['model']);
        }
        let da = dataset.get('properties', Map<string, any>()).get('net.sf.jasperreports.data.adapter');
        if (da === undefined){
            da = dataset.get('properties', Map<string, any>()).get('com.jaspersoft.jrs.data.source');
        }
        return da !== undefined;
    }

    private getMetdata = () => {
        this.setState({ isGettinMetdatadaInProgress: true, selectedNode: undefined }, () => {
            const editorIndex = this.props.report.get('currentEditorIndex');
            let editedResourceId = this.props.report.getIn(['subeditors', editorIndex, 'editedResourceId']) as string;
            let dataset;
            if (editedResourceId && editedResourceId !== 'main') {
                dataset = this.props.report.getIn(['model', 'subdatasets', editedResourceId]);
                editedResourceId = dataset.get('name');
            } else {
                dataset = this.props.report.getIn(['model']);
                editedResourceId = 'main';
            }
            let da = dataset.get('properties', Map<string, any>()).get('net.sf.jasperreports.data.adapter');
            if (da === undefined)
                da = dataset.get('properties', Map<string, any>()).get('com.jaspersoft.jrs.data.source');
            if (da) {
                this.context.run(DatasourceApi.inst().getMetadata)(this.getReportPath(), editedResourceId).then((response) => {
                    this.setState({ isGettinMetdatadaInProgress: false }, () => {
                        console.log(response.data);
                        this.props.storeInCache(Map<string, any>({ value: response.data }));
                    });
                }).catch(() => {
                    this.setState({ isGettinMetdatadaInProgress: false });
                });
            } else {
                // eventually say there is no Data adapter
                this.setState({ isGettinMetdatadaInProgress: false });
            }
        });
    }

    private onClick = (e: React.MouseEvent<HTMLElement>, id: string, allowRightClick = false) => {

        if (e.button !== 0 && !allowRightClick) {
            return;
        }
        this.setState({ selectedNode: id });
    }

    public getNodeContent = (node, path: string) => {
        if (node.tables) {
            return this.getTables(node.tables, path);
        } else if (node.fields) {
            return this.getFields(node.fields, path);
        }
        else return null;
    }

    public getTables = (tables: any[], path: string) => {
        return tables.map((table, index: number) => {
            const fullPath = path + '/' + table.name;
            return <NodeElement key={`${path}-${index}-name`} id={`${path}-${index}-name`} isSelected={fullPath === this.state.selectedNode}
                label={table.label ? table.label : table.name}
                node={table}
                onClick={this.onClick}
                type={table.type}
                path={fullPath}>
                {this.getNodeContent(table, fullPath)}
            </NodeElement>
        });
    }

    public getFields = (fields: any[], path: string) => {
        if (fields)
            return fields.map((field, index: number) => {
                const fullPath = path + '/' + field.name;
                return [
                    <NodeElement type={field.type} description={field.description} key={`${path}-${index}-name`} id={`${path}-${index}-name`} isSelected={fullPath === this.state.selectedNode}
                        label={field.label ? field.label : field.name}
                        node={field}
                        onClick={this.onClick}
                        path={fullPath}>
                        {this.getFields(field.fields, fullPath)}
                    </NodeElement>
                ];
            });
    }

    private getCollapseIcon = () => {
        return <svg width="10px" height="12px" viewBox="0 0 10 12">
            <g id="chevron_down_theme_6" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M2.44264069,1.24264069 L2.44264069,6.04264069 L7.24264069,6.04264069 L7.24264069,7.24264069 L2.44264069,7.24264069 L2.44264069,7.24264069 L1.24264069,7.24264069 L1.24264069,1.24264069 L2.44264069,1.24264069 Z" id="Combined-Shape" fill="#055DAB" transform="translate(4.242641, 4.242641) rotate(-45.000000) translate(-4.242641, -4.242641) ">
                </path>
            </g>
        </svg>;
    }

    private getExpandIcon = () => {
        return <svg width="10px" height="12px" viewBox="0 0 10 12"><g id="chevron_right_theme_6" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M6.24264069,8.04264069 L6.24264069,9.24264069 L1.44264069,9.24264069 L1.44264069,8.04264069 L6.24264069,8.04264069 Z M1.44264069,3.24264069 L1.44264069,9.24264069 L0.242640687,9.24264069 L0.242640687,3.24264069 L1.44264069,3.24264069 Z" id="Combined-Shape" fill="#055DAB" transform="translate(3.242641, 6.242641) scale(-1, 1) rotate(45.000000) translate(-3.242641, -6.242641) ">
            </path>
        </g>
        </svg>;
    }

    public render() {
        let root = [];
        const metadata = this.props.metadataContainer ? this.props.metadataContainer.get('value') : undefined;
        if (metadata && metadata.objects) {
            root = metadata.objects.map((object, index) => {
                return <NodeElement key={index} id={index.toString()} isSelected={object.name === this.state.selectedNode}
                    label={object.label ? object.label : object.name}
                    type={object.type}
                    node={object}
                    path={object.name}
                    onClick={this.onClick}>
                    {this.getNodeContent(object, object.name)}
                </NodeElement>
            });
        }
        const isGetButtonEnabled = this.isButtonEnabled();
        return <div style={{ display: 'flex', flex: 1, alignItems: 'stretch', flexDirection: 'column' }}>
            <div style={{ flex: 1, marginRight: 10, overflow: 'auto' }}>
                <TreeView
                    defaultCollapseIcon={this.getCollapseIcon()}
                    defaultExpandIcon={this.getExpandIcon()}
                    multiSelect={false}
                    className="tc-jsw-report-outline-label jr-mTree jr-mTreeOutline mui"
                >
                    {root}
                </TreeView>
            </div>
            <div aria-disabled={!isGetButtonEnabled || this.state.isGettinMetdatadaInProgress} className="jr-parameters-button" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {
                if (isGetButtonEnabled && !this.state.isGettinMetdatadaInProgress){
                    this.getMetdata();
                }
            }}>
                <JSSButton disabled={!isGetButtonEnabled || this.state.isGettinMetdatadaInProgress} size="large" variant="text" style={{ marginLeft: '10px' }}>{i18n.t('queryeditor.metadata.readbutton')}</JSSButton>
            </div>
        </div>
    }

}

const mapStateToProps = (state: IState) => {
    return {
        report: state.getIn(['report']),
        descriptor: state.getIn(['report', 'fileDescriptor']),
        metadataContainer: state.getIn(['report', 'cachedData', 'metadataContainer'], Map<string, any>()),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setObjectProperties: (path: string[], value: any) => { dispatch(ReportActions.setObjectProperties(path, value)); },
        storeInCache: (metadataContainer: Map<string, any>) => { dispatch(ReportActions.storeInCache('metadataContainer', metadataContainer)) }
    };
}

MetadataTab.contextType = RunContext;

export default connect(mapStateToProps, mapDispatchToProps)(MetadataTab);
