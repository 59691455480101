/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Icon, TextInput } from '@jss/js-common';
import { advancedNameValidator } from '@jss/js-common/src/utils/validators';
import { ResourcePickerDialog, RunContext } from '@jss/js-repository';
import { getRealName, IRepositoryItemDescriptor, RepositoryApi } from '@jss/js-rest-api';
import { IconButton, InputAdornment } from '@material-ui/core';
import * as React from 'react';
import '../../assets/css/DataAdapterTypePicker.css';
import i18n from '../../i18n';

interface ISpringHibernateDataAdapterEditor {
    name?: string,
    springConfig?: string,
    beanId?: string,
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

export class SpringHibernateDataAdapterEditor extends React.Component<ISpringHibernateDataAdapterEditor> {

    getSearchIcon = (setter: (path: string) => void) => {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 2.5 }}>
            <Icon icon="search" onClick={() => this.openBrowseDialog(setter)} />
        </div>;
    }

    public openBrowseDialog = (setter: (path: string) => void) => {
        const defaultPath: IRepositoryItemDescriptor[] = [];
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('datasource.common.dialog.resource'),
                mode: 'open',
                title: i18n.t('datasource.common.dialog.title'),
                onFileSelected: (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => this.confirmFile(setter, folderPath, file),
                defaultPath: defaultPath,
                allowSearch: true,
            }
        );
    }

    private confirmFile = (setter: (path: string) => void, folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => {
        const path = folderPath.length > 0 ? `${RepositoryApi.inst().getParentPath(folderPath)}/${getRealName(file)}` : `/${getRealName(file)}`;  
        setter(path);
    }

    public render() {
        const xMLFileNameAdornament = <InputAdornment position="end">
            <IconButton
                edge="end"
                style={{ padding: 0 }}
            >
                {this.getSearchIcon(this.springConfigChange)}
            </IconButton>
        </InputAdornment>;

        return (<div className="tc-jsw-dataadapter-editor">

            <div className="tc-jsw-form-wrapper" style={{ flex: 1, alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}>

                <TextInput label={i18n.t('datasource.csv.name')}
                    help={i18n.t('datasource.hibernate.name.help')}
                    id="data-adapter-name"
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.hibernate.springConfigName')}
                    id="spirng-config-input"
                    value={this.props.springConfig}
                    onChange={this.springConfigChange}
                    InputProps={{
                        endAdornment: !this.props.readOnly ? xMLFileNameAdornament : undefined,
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.springhibernate.sessionID')}
                    id="session-id-input"
                    value={this.props.beanId}
                    onChange={this.beanIdChange}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />
            </div>
        </div>);
    }

    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private springConfigChange = (str: string) => {
        this.notifyChange({ springConfig: str });
    }

    private beanIdChange = (str: string) => {
        this.notifyChange({ beanId: str });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }
}

SpringHibernateDataAdapterEditor.contextType = RunContext;