import { buffers, channel } from 'redux-saga';
import { all, call, fork, put, take } from 'redux-saga/effects';

function* createConcurrentTaskQueue(handler, workersCount = 1) {
    const queueChannel = yield call(channel, buffers.expanding());

    function* worker(chan) {
        while (true){
            const payload = yield take(chan);
            yield handler(payload);
        }
    }

    function* watcher() {
        const workersChannel = yield call(channel, buffers.expanding());

        yield all(Array(workersCount).fill(fork(worker, workersChannel)));
        while(true){
            const action = yield take(queueChannel);
            yield put(workersChannel, action);
        }
    }

    return {
        watcher,
        queueChannel,
    };
}

export default createConcurrentTaskQueue;