/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { EditableTable, TextInput } from '@jss/js-common';
import { advancedNameValidator, advancendNotEmptyMethodNameValidator, advancendNotEmptyClassNameValidator } from '@jss/js-common/src/utils/validators';
import { ResourcePickerDialog, RunContext } from '@jss/js-repository';
import { getRealName, IRepositoryItemDescriptor, MIME_TYPES, RepositoryApi } from '@jss/js-rest-api';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import '../../assets/css/DataAdapterTypePicker.css';
import i18n from '../../i18n';

interface IDatasourceDataAdapterEditor {
    name?: string,
    factoryClass?: string | null,
    methodToCall?: string | null,
    classpaths?: string[],
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

export class DatasourceDataAdapterEditor extends React.Component<IDatasourceDataAdapterEditor> {


    private getColumnNames = () => {
        if (this.props.classpaths) {
            if (Array.isArray(this.props.classpaths)) {
                return this.props.classpaths;
            } else {
                return [this.props.classpaths];
            }
        }
        return [];
    }

    private getRows = () => {
        const result: string[][] = [];
        const rows = [...this.getColumnNames()];
        rows.forEach((columnName: string) => {
            result.push([columnName]);
        });
        return result;
    }

    private onCellChange = (row: number, col: number, value: string, isEditing: boolean) => {
        const rows = this.props.classpaths ? [...this.props.classpaths] : [];
        if (value.trim().length === 0 && !isEditing) {
            rows.splice(row, 1);
        } else {
            rows[row] = value;
        }
        if (!isEditing) {
            this.notifyChange({ classpaths: rows });
        }
    }

    private onRowDelete = (rowIndex: number) => {
        const newColumns = [...this.props.classpaths];
        newColumns.splice(rowIndex, 1);
        this.notifyChange({ classpaths: newColumns });
    }

    public openBrowserDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = [];
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('datasource.common.dialog.resource'),
                mode: 'open',
                title: i18n.t('datasource.common.dialog.title'),
                onFileSelected: this.confirmFile,
                defaultPath: defaultPath,
                allowSearch: true,
                allowedMimes: [MIME_TYPES.JAR, MIME_TYPES.ZIP, 'jar', 'zip'],
            }
        );
    }

    private confirmFile = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => {
        const path = folderPath.length > 0 ? `${RepositoryApi.inst().getParentPath(folderPath)}/${getRealName(file)}` : `/${getRealName(file)}`;  
        const newFiles = [...this.props.classpaths, path];
        this.notifyChange({ classpaths: newFiles });
    }

    public render() {
        const tableRows = this.getRows();

        return (<div className="tc-jsw-dataadapter-editor">

            <div className="tc-jsw-form-wrapper">

                <TextInput label={i18n.t('datasource.datasource.name')}
                    help={i18n.t('datasource.datasource.name.help')}
                    id="data-adapter-name"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly,
                    }}/>

                <TextInput label={i18n.t('datasource.datasource.factoryClass')}
                    id="factory-class-input"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.factoryClass}
                    onChange={this.onFactoryClassChange}
                    advancedValidator={advancendNotEmptyClassNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly,
                    }}/>

                <TextInput label={i18n.t('datasource.datasource.methodToCall')}
                    id="method-to-call-input"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.methodToCall}
                    onChange={this.onMethodToCallChange}
                    advancedValidator={advancendNotEmptyMethodNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly,
                    }}/>

                <div style={{ display: 'flex', alignItems: 'stretch' }} id="tableContainer">
                    <EditableTable cellStyle={{ textAlign: 'left' }} onCellChange={this.onCellChange} cellData={tableRows} numRows={this.props.classpaths ? this.props.classpaths.length + 1 : 1} columnNames={[i18n.t('datasource.bean.classpaths'), '']}
                        columnActions={[{ onClick: this.onRowDelete, icon: <DeleteIcon /> }]} onAddAction={this.openBrowserDialog} readOnly={this.props.readOnly}/>
                </div>
            </div>
        </div>);
    }

    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private onFactoryClassChange = (str: string) => {
        this.notifyChange({ factoryClass: str });
    }

    private onMethodToCallChange = (str: string) => {
        this.notifyChange({ methodToCall: str });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }
}

DatasourceDataAdapterEditor.contextType = RunContext;