/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { IMenuItem } from '../toolbars/ToolbarProvider';
import { SelectItem } from '../select/SelectItems';


interface IPopoverLabelProps {
    id: string;
    text: string,
    style: React.CSSProperties,
    content?: IMenuItem[];
}

interface IState {
    anchorEl: null | EventTarget
}

export class MenuLabel extends React.Component<IPopoverLabelProps, IState> {

    state = {
        anchorEl: null,
    }

    private setAnchorEl = (newAnchorEl: null | EventTarget) => {
        this.setState({
            anchorEl: newAnchorEl,
        });
    }

    public render() {

        const handleClick = (event) => {
            this.setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            this.setAnchorEl(null);
        };

        const open = Boolean(this.state.anchorEl);
        const id = open ? this.props.id : undefined;
        return (
            <div>
                <div aria-describedby={id} style={this.props.style} onClick={handleClick}>{this.props.text}</div>
                <Menu
                    id={this.props.id}
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div key={'feedbackElement'} />
                    {this.props.content && this.props.content.map((element: IMenuItem) => {
                        const isEnabled = element.isEnabled === undefined || element.isEnabled;
                        if (element.getComponent) {
                            return element.getComponent(handleClose);
                        } else {
                            return (
                                <SelectItem key={element.id} selected={false} onClick={() => {
                                    if (isEnabled) {
                                        handleClose();
                                        element.onClick?.();
                                    }
                                }}>
                                    {
                                        element.icon &&
                                            <ListItemIcon>
                                                {element.icon}
                                            </ListItemIcon>
                                    }
                                    <ListItemText disableTypography={true}
                                        primary={<Typography style={{ color: isEnabled ? undefined : 'lightgray' }}>{element.label}</Typography>} />
                                </SelectItem>
                            );
                        }
                    })}
                </Menu>
            </div>
        );
    }

}
