/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { PInt } from "../../ui/primitive/UPInteger";
import { PString } from "../../ui/primitive/UPString";
import { MStyles } from "../styles/MStyles";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { WhenNoDataTypeEnum } from "../enums/WhenNoDataTypeEnum";
import { OrientationTypeEnum } from "../enums/OrientationTypeEnum";
import { PrintOrderEnum } from "../enums/PrintOrderEnum";
import { PJavaClass } from "../../ui/primitive/UPJavaClass";
import { PConnectedReportLanguage } from "../../ui/primitive/UPConnectedReportLanguage";
import { MReportCustom } from "./MReportCustom";
import { PSubDiv } from "../../ui/composite/UPSubDiv";
import { RunDirectionEnum } from "../enums/RunDirectionEnum"; 
import { PImport } from "../../ui/primitive/UPImport";
import { PConnectedStringTable } from "../../ui/tables/UPConnectedStringTable";


export const FReport = {
    name: new PString({ id: 'name', label: 'Name', description: 'Name of the report' }),
    description: new PString({ id: 'description', label: 'Description' }),

    language: new PConnectedReportLanguage({ id: 'language', label: 'Language', default: 'java', description: 'Language used for the report expressions', writeable: true }),
    imports: new PImport({  }),
    formatFactoryClass: new PJavaClass({ id: 'formatFactoryClass', label: 'Format Factory Class' }),


    titleNewPage: new PBoolean({ id: 'isTitleNewPage', label: 'Title On A New Page', description: 'TEST' }),
    summaryNewPage: new PBoolean({ id: 'isSummaryNewPage', label: 'Summary On A New Page' }),
    summaryWithPageHeaderAndFooter: new PBoolean({ id: 'isSummaryWithPageHeaderAndFooter', label: 'Summary With Page Header And Footer' }),
    floatColumnFooter: new PBoolean({ id: 'isFloatColumnFooter', label: 'Float Column Footer' }),
    ignorePagination: new PBoolean({ id: 'isIgnorePagination', label: 'Ignore Pagination' }),
    whenNoDataTypeEnum: WhenNoDataTypeEnum,

    docWidth: new PInt({ id: 'docWidth', label: 'Width', min: 0, className: 'labelAlignRight' }),
    docHeight: new PInt({ id: 'docHeight', label: 'Height', min: 0, className: 'labelAlignRight' }),

    marginTop: new PInt({ id: 'topMargin', label: 'Top', min: 0, className: 'labelAlignRight' }),
    marginBottom: new PInt({ id: 'bottomMargin', label: 'Bottom', min: 0, className: 'labelAlignRight' }),
    marginLeft: new PInt({ id: 'leftMargin', label: 'Left', min: 0, className: 'labelAlignRight' }),
    marginRight: new PInt({ id: 'rightMargin', label: 'Right', min: 0, className: 'labelAlignRight' }),

    orientation: OrientationTypeEnum,

    columns: new PInt({ id: 'columnCount', label: 'Number Of Columns', min: 0 }),
    columnsWidth: new PInt({ id: 'columnWidth', label: 'Column Width', min: 0 }),
    columnsSpace: new PInt({ id: 'columnSpacing', label: 'Space', min: 0 }),
    printOrder: PrintOrderEnum,
    columnDirection: { ...RunDirectionEnum, id: 'columnDirection' }
};

export const MReport: APDescriptor = {
    type: 'MReport',
    label: 'Report',
    id: 'reportPropertiesDescriptor',
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({
                id: 'report', label: 'Report', layouts: [
                    new PDiv({
                        style: {padding: 8},
                        layouts: [
                            FReport.name,
                            FReport.description,
                        ]
                    }),
                    new PAccordeon({
                        id: 'report.pagination', label: 'Pagination', expanded: true, layouts: [
                            FReport.ignorePagination,
                            FReport.titleNewPage,
                            FReport.summaryNewPage,
                            FReport.summaryWithPageHeaderAndFooter,
                            FReport.floatColumnFooter,
                            FReport.whenNoDataTypeEnum
                        ]
                    }),
                    new PAccordeon({
                        id: 'report.page', label: 'Page Format', layouts: [
                            FReport.orientation,
                            new PSubDiv({
                                label: 'Size', id: 'size',
                                //style: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: 10 },
                                layouts: [FReport.docWidth, FReport.docHeight]
                            }),
                            new PSubDiv({
                                label: 'Margins', id: 'margin',
                                //style: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: 10 },
                                layouts: [FReport.marginTop, FReport.marginBottom, FReport.marginLeft, FReport.marginRight]
                            })
                        ]
                    }),
                    new PAccordeon({
                        id: 'report.columns', label: 'Columns', layouts: [
                            FReport.columns, FReport.columnsWidth, FReport.columnsSpace, FReport.printOrder, FReport.columnDirection
                        ]
                    }),
                    new PAccordeon({
                        id: 'report.runtime', label: 'Runtime', expanded: true, layouts: [
                            FReport.language, FReport.formatFactoryClass,
                            new PConnectedStringTable({
                                style: { display: 'block', maxHeight: '350px', overflow: 'auto', padding: 0, marginTop: 5, },
                                id: "imports", label: 'Imports',   
                                layouts: [FReport.imports],
                                default: 'NewImport'
                            }),
                            MReportCustom
                        ]
                    })
                ]
            }),
            new PDiv({ id: 'styles', label: 'Styles', layouts: [...MStyles.layouts] })
        ]
    })]
};

