/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import i18n from '../../i18n';

export interface ITableProps {
    columnNames: string[],
    columnActions?: {
        icon: React.ReactNode,
        onClick: (selectedRow: number) => void,
    }[],
    columnsWidths?: number[],
    cellData: string[][],
    numRows: number,
    enableRowHeader?: boolean,
    cellStyle?: React.CSSProperties,
    onCellChange?: (row: number, col: number, value: string, isEditing: boolean) => void,
    onAddAction?: () => void;
    readOnly?: boolean,
}

export interface ITableState {
    isEditMode: boolean,
    editIndex: {
        row: number,
        column: number,
    } | undefined,
    currentEditValue: string | undefined,
}

export interface ITableEditableExampleState {
    columnNames?: string[];
    sparseCellData?: { [key: string]: string };
}

const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '0px',
            },
        },
    },
});

export class EditableTable extends React.Component<ITableProps, ITableState> {

    public state: ITableState = {
        isEditMode: false,
        editIndex: undefined,
        currentEditValue: undefined,
    }

    private onCellDoubleClick = (rowIndex: number, colIndex: number) => {
        if (!this.props.readOnly){
            const currentValue = this.props.cellData[rowIndex][colIndex];
            this.setState({
                isEditMode: true,
                editIndex: {
                    row: rowIndex,
                    column: colIndex,
                },
                currentEditValue: currentValue,
            });
        }
    }

    private onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            currentEditValue: event.currentTarget.value,
        }, () => {
            if (this.props.onCellChange &&
                this.state.editIndex &&
                this.state.currentEditValue !== undefined) {
                this.props.onCellChange(this.state.editIndex.row, this.state.editIndex.column, this.state.currentEditValue, true);
            }
        });
    }

    private onTextBlur = () => {
        this.completeEdit();
    }

    private completeEdit = () => {
        const newValue = this.state.currentEditValue;
        const editedRow = this.state.editIndex?.row;
        const editedColumn = this.state.editIndex?.column;
        this.setState({
            isEditMode: false,
            editIndex: undefined,
            currentEditValue: undefined,
        }, () => {
            if (this.props.onCellChange &&
                editedRow !== undefined &&
                editedColumn !== undefined &&
                newValue !== undefined) {
                this.props.onCellChange(editedRow, editedColumn, newValue, false);
            }
        });
    }

    private onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case "Enter": // enter
                this.completeEdit();
                break;
            case "Escape": // esc
                this.setState({
                    isEditMode: false,
                    editIndex: undefined,
                    currentEditValue: undefined,
                });
                break;
        }
    }

    public render() {
        const columns = this.props.columnNames.map((columnName: string, index: number) => {
            return (
                <TableCell key={columnName} width={this.props.columnsWidths ? this.props.columnsWidths[index] : undefined}>
                    {columnName}
                </TableCell>
            );
        });
        const rows = this.props.cellData.map((row: string[], rowIndex: number) => {
            const rowCells = row.map((cellData: string, colIndex: number) => {
                if (this.state.isEditMode &&
                    this.state.editIndex &&
                    colIndex === this.state.editIndex.column &&
                    rowIndex === this.state.editIndex.row) {
                    return <TableCell key={rowIndex + '-' + colIndex} height={36} align="left">
                        <TextField onKeyDown={this.onKeyDown} onChange={this.onTextChange} onBlur={this.onTextBlur} id="cell-editor" autoFocus={true} value={this.state.currentEditValue} />
                    </TableCell>
                } else {
                    return <TableCell key={rowIndex + '-' + colIndex} height={36} onDoubleClick={() => { this.onCellDoubleClick(rowIndex, colIndex); }} align="left">
                        {cellData}
                    </TableCell>
                }
            });
            if (this.props.columnActions && !this.props.readOnly) {
                this.props.columnActions.forEach((action, index) => {
                    rowCells.push(
                        <TableCell key={'action' + index} height={36} width={32} onClick={() => action.onClick(rowIndex)} align="right" style={{ cursor: 'pointer' }} title={i18n.t('common.editabletable.remove')}>
                            {action.icon}
                        </TableCell>
                    );
                });
            }
            return <TableRow key={rowIndex}>
                {rowCells}
            </TableRow>
        });
        if (this.props.onAddAction && !this.props.readOnly) {
            rows.push(
                <TableRow key={'addRowButton'}>
                    <TableCell colSpan={this.props.columnNames.length} height={36} onClick={this.props.onAddAction} align="left" style={{ cursor: 'pointer' }} title={i18n.t('common.editabletable.add')}>
                        <AddCircleIcon color='primary' />
                    </TableCell>
                </TableRow>
            );
        }
        return <ThemeProvider theme={theme}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead className='jr-mTable-row jr-mTable-rowHeader mui jr-MuiTableRow-head'>
                        <TableRow>
                            {columns}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </TableContainer>
        </ThemeProvider>
    }
}