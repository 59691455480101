/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Map } from 'immutable';
import { ADHOC_NAMESPACE } from '../reader/JrxmlAdhocUtils';
import { createReportElement } from './JrxmlHelper';

export const createAdhocElement = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const componentElement = xmlDocument.createElement("componentElement");
    const reportElement = createReportElement(elementNode, xmlDocument);
    componentElement.appendChild(reportElement);

    const baseNamespace = elementNode.get(ADHOC_NAMESPACE, "ah");
    const namespace = baseNamespace + ":";

    const cvcElement = xmlDocument.createElement(namespace + "adhoc");

    cvcElement.setAttribute("xmlns:" + baseNamespace, elementNode.get("xmlns:" + baseNamespace, "http://jaspersoft.com/adhoc"));
    cvcElement.setAttribute("xsi:schemaLocation", elementNode.get("xsi:schemaLocation", "http://jaspersoft.com/adhoc http://jaspersoft.com/schema/adhoc.xsd"));
    
    componentElement.appendChild(cvcElement);
    return componentElement;
}