/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { ColorPicker, FormControlLabel } from '@jss/js-common';
import { getKey, getNormalizedPath, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { resolveAttribute } from '../../types/common/StyleResolver';
import '../../../../../assets/uxpl/css/ColorPicker.css';
import { Map } from 'immutable';
import i18n from '../../../../../i18n';
import { Typography } from '@material-ui/core';
import { SizeToClass } from '@jss/js-common/src/uxpl/types/InputTypes';

const TYPEID = 'color';
reg(TYPEID, (mc) => { return <UPColor mcontext={mc} />; });
export class PColor extends APDescriptor {
    canBeNull?: boolean = true;
    default?: number | string;
    format: 'hex' | 'rgb' | 'number' = 'number';
    allowTransparent?: boolean;
    public validator(v: string): string | void {
        if (this.canBeNull === false && (!v || v.trim().length === 0)) return `value can't be empty`;
    }

    public constructor(init: Partial<PColor>) {
        super();
        Object.assign(this, { validator: this.validator, ...init, type: TYPEID });
    }
}

interface IUPColor {
    additionalIcon?: React.ReactElement,
}

export class UPColor extends React.Component<UiPropertyProps & IUPColor> {

    render() {
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const descriptor = this.props.mcontext.descriptor as PColor;
        let v = this.props.mcontext.model.getIn(p) as string | undefined;
        const classes: string[] = ['jrws-properties-color-picker'];
        if (this.props.mcontext.descriptor.className) {
            classes.push(this.props.mcontext.descriptor.className);
        }
        if (v === undefined && this.props.mcontext.descriptor.inheritedPropertyId) {
            const elementPath = getPath(undefined, this.props.mcontext.elements[0].path).slice(0, 2);
            const element = this.props.mcontext.model.getIn(elementPath) as Map<string, any>;
            v = resolveAttribute(this.props.mcontext.model, element, this.props.mcontext.descriptor.inheritedPropertyId);
            classes.push('placeHolderTextColor');
        }
        const label = i18n.t(this.props.mcontext.descriptor.label);
        const control = <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
            <ColorPicker
                className={classes.join(' ')}
                style={this.props.mcontext.descriptor.style}
                key={getKey(p)}
                allowTransparent={descriptor.allowTransparent}
                allowNull={true}
                // defaultValue={this.props.placeholder}
                inline={true}
                size="small"
                value={v}
                disabled={descriptor.readonly}
                InputLabelProps={descriptor.deprecated ? { className: 'deprecatedProperty' } : undefined}
                onColorChange={this.onColorChange} 
                onColorTextChange={this.onColorChange}/>
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                {this.props.additionalIcon}
            </div>
        </div>;
        return <div className={`jr-formControlledTextFieldContainer ${SizeToClass['small']} jr-mInputText mui`}>
            <FormControlLabel
                classes={{ label: `jr-formControlledTextFieldContainer jr-mInput-label mui` }}
                style={{ margin: 0 }}
                control={control}
                labelPlacement="start"
                label={<Typography className={"jr-mInputs-group-label mui"} variant="body2">{label}</Typography>}
            />
        </div>;
    }

    public onColorChange = (color: string | undefined) => {
        this.props.mcontext.elements.forEach(key => {
            if (!color) {
                const path = getNormalizedPath(key.path);
                path.push(this.props.mcontext.descriptor.id);
                this.props.mcontext.deleteElement(path);
            } else {
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: color });
            }
        });
    }


}