/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnumGroup } from "../dataset/UPEnumGroup";


export const IncrementTypeEnum = new PEnumGroup({
    id: 'incrementType', label: 'IncrementType.label', default: 'None',
    groupProperty: 'incrementGroup',
    items: [
        { key: 'None', value: 'IncrementType.None' },
        { key: 'Report', value: 'IncrementType.Report' },
        { key: 'Page', value: 'IncrementType.Page' },
        { key: 'Column', value: 'IncrementType.Column' },
        { key: 'Group', value: 'IncrementType.Group' }
    ]
});

