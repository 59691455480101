/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { CommandRegistry, IconButton, IEditor, IMenuItem, IResourceInfo, IResourceListener, IResourceListenerManager, IToolBarItemOptions, IToolbarProvider, ToolButton, Spinner as UXPLSpinner, ToolBar } from '@jss/js-common';
import { ResourceInfoDialog } from '@jss/js-repository';
import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import PublishIcon from '@material-ui/icons/Publish';
import { saveAs } from 'file-saver';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Base64 } from 'js-base64';
import { IRepositoryItemDescriptor, RepositoryApi } from '@jss/js-rest-api';
import { TEXT_SAVED, EXIT_TEXT_EDITOR, UPDATED_DESCRIPTOR } from './AbstractTextualEditor';
import { AxiosResponse } from 'axios';
import i18n from '../i18n';


export interface IUnsupportedEditor {
    resourceListenerManagers: IResourceListenerManager[],
    commandRegistry: CommandRegistry,
    acceptedFileTypes?: string,
    defaultExtension?: string,
    onExit?: () => void;
}

interface IUnsupportedEditorState {
    content: any,
    openDescriptor: IRepositoryItemDescriptor | undefined,
    path: IRepositoryItemDescriptor[],
    toolbarData: IToolbarProvider | undefined,
}

function buildFileSelector(handleFileChange: (e) => void, acceptedFileTypes: string) {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', acceptedFileTypes);
    fileSelector.onchange = handleFileChange;
    fileSelector.onclick = function () {
        (this as any).value = null;
    };
    return fileSelector;
}

export abstract class UnsupporteEditor extends React.Component<IUnsupportedEditor, IUnsupportedEditorState> implements IEditor {

    public state = {
        openDescriptor: undefined,
        path: undefined,
        toolbarData: undefined,
        content: undefined,
    }

    private resourceListenerId = '';

    protected fileSelector: HTMLInputElement;

    public componentDidMount = () => {
        this.fileSelector = buildFileSelector(this.onFileSelected, '.*');
        this.resourceListenerId = uuidv4();
        const resourceListener: IResourceListener = {
            onResourceOpen: (resource: IResourceInfo) => {
                if (resource.resource) {
                    if (!resource.content) {
                        //it has a descriptor but not content, consider it a new da
                        this.setState({
                            openDescriptor: resource.resource,
                            path: resource.path,
                        });
                    } else {
                        this.setState({
                            openDescriptor: resource.resource,
                            path: resource.path,
                            content: resource.content,
                        });
                    }
                }
            }
        }
        this.props.resourceListenerManagers.forEach(manager => {
            manager.addResourceListener(this.resourceListenerId, resourceListener);
        });
        this.props.commandRegistry.registerHandler(EXIT_TEXT_EDITOR, {
            execute: () => {
                if (this.props.commandRegistry) {
                    this.props.commandRegistry.executeCommand(EXIT_TEXT_EDITOR);
                }
            },
            isEnabled: () => {
                return true;
            }
        });
        this.provideToolbar();
    }

    componentWillUnmount() {
        this.props.resourceListenerManagers.forEach(manager => {
            manager.removeResourceListener(this.resourceListenerId);
        });
    }

    componentDidUpdate = (previousProps: IUnsupportedEditor, previousState: IUnsupportedEditorState) => {
        if (previousState.openDescriptor !== this.state.openDescriptor) {
            this.provideToolbar();
        }
    }

    getEditorContent = () => {
        return this.state.content;
    }

    provideToolbar = () => {
        this.setState({ toolbarData: this.getInternalToolbar() });
        //this.props.commandRegistry.registerToolbar(this.getToolbar());
    }

    getToolbar = (): IToolbarProvider => {
        return {
            getMenus: () => {
                return [];
            },
            getToolItems: () => {
                return [];
            }
        }
    }

    handleUploadPress = (e) => {
        e.preventDefault();
        this.fileSelector.click();
    }

    private showPropertisDialog = () => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.context.showDialog(ResourceInfoDialog, { descriptor: this.state.openDescriptor, onViewClose: () => { }, onChanged: this.handleItemPropertiesChanged });
    }

    getInternalToolbar = (): IToolbarProvider => {
        const getExitSubmenu: IMenuItem[] = [
            {
                id: 'propertiesAction',
                label: i18n.t('common.actions.menu.resourceinfo'),
                icon: <InfoIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    this.showPropertisDialog();
                },
                isEnabled: this.state.openDescriptor !== undefined && this.state.openDescriptor.uuid !== undefined,
            },
            {
                label: i18n.t('common.actions.menu.upload'),
                id: 'importAction',
                
                getComponent: (handleClose: () => void) => {
                    return (
                        <MenuItem key={'importAction'} selected={false} className={"jr-mMenu-list-item mui"}>
                            <div style={{ display: 'flex' }} onClick={(event) => { handleClose(); this.handleUploadPress(event) }}>
                                <ListItemIcon className={"jr-mMenu-list-item-icon mui"}>
                                    <PublishIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />
                                </ListItemIcon>
                                <ListItemText primary={i18n.t('common.actions.menu.upload')} classes={{ primary: "jr-mText mui" }} />
                            </div>
                        </MenuItem>
                    );
                },
            },
            {
                label: i18n.t('common.actions.menu.download'),
                icon: <GetAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                id: 'exportAction',
                onClick: () => {
                    //const blob = new Blob([this.state.content]);
                    const byteCharacters = this.state.content;
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray]);
                    saveAs(blob, this.state.openDescriptor.name);
                }
            },
            {
                label: i18n.t('common.actions.menu.exit'),
                id: 'exitAction',
                icon: <ExitToAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: this.doExit
            },
        ];
        return {
            getToolItems: () => {
                const items = [
                    {
                        id: 'exitToolAction',
                        render: (options?: IToolBarItemOptions) => {
                            //disable the save when the report is not dirty or we don't have write permission
                            const height = options && options.height ? options.height : 24;
                            const backgroundColor = options && options.background ? options.background : undefined;
                            return <ToolButton label={i18n.t('common.actions.menu.exit')} onClick={this.doExit} height={height} backgroundColor={backgroundColor} content={getExitSubmenu}>
                                <IconButton style={{ backgroundColor: 'transparent' }} icon={'linkExternal'} size="small" />
                            </ToolButton>;
                        }
                    },
                ];
                return items;
            },
        }
    }

    private doExit = () => {
        if (this.props.commandRegistry) {
            this.props.commandRegistry.executeCommand(EXIT_TEXT_EDITOR);
        }
    }

    private onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            const content = fileReader.result as string;
            // TODO - perform some kind of validation of the loaded content
            // or be sure that a sort of validation is triggered when editorContent is updated
            this.setState({ content: content }, () => {
                this.doSave();
            });

        };
        fileReader.readAsText(event.currentTarget.files[0]);
    }


    private doSave = () => {
        const content = this.state.content;
        if (this.state.openDescriptor) {
            //it is a save of an existing report
            const base64data = Base64.encode(content);
            const descriptor: IRepositoryItemDescriptor = this.state.openDescriptor;
            const newDescriptor: IRepositoryItemDescriptor = {
                ...(descriptor as any),
                data: base64data,
            }

            this.context.runNoCancel(RepositoryApi.inst().save)(descriptor.uuid ? 'uuid:' + descriptor.uuid : (descriptor.path ? descriptor.path : '/'), newDescriptor, newDescriptor).then((response: AxiosResponse) => {
                if (response.data) {
                    //need to update the descriptor also in simple save because it could contain the version
                    this.setState({ openDescriptor: response.data }, () => {
                        this.props.commandRegistry.executeCommand(TEXT_SAVED, { descriptor: response.data, data: content });
                    });
                }
            }).catch(error => console.log(error));
        }
    }

    private handleItemPropertiesChanged = (oldDescriptor: IRepositoryItemDescriptor, newDescriptor: IRepositoryItemDescriptor | null) => {
        this.setState({
            openDescriptor: newDescriptor,
        }, () => {
            this.props.commandRegistry.executeCommand(UPDATED_DESCRIPTOR, { descriptor: newDescriptor });
        });
    }

    public render() {
        if (!this.state.openDescriptor) {
            return <div className="tc-jsw-center-in-div"><UXPLSpinner /></div>
        }
        return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                <ToolBar toolbarData={this.state.toolbarData} />
            </div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <span>{this.renderMessage()}</span>
                <div title={i18n.t('common.actions.menu.exit')} style={{ height: 50, cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={this.doExit}>
                    <IconButton className="jr-unrecognizedTextualEditorExitIcon" style={{ backgroundColor: 'transparent' }} icon={'linkExternal'} size="medium" />
                </div>
            </div>
        </div>
    }

    private renderMessage = (): string => {
        if (this.state.openDescriptor.permission === 0)
            return i18n.t('text.editor.noPermission');
        return i18n.t('text.editor.unrecognizedResource') + this.state.openDescriptor.mime
    }
}
