/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import i18n from '../../../../../i18n';
import { APDescriptor } from '../APDescriptor';
import { reg, UiPropertyProps } from '../UiProperty';


const TYPEID = 'label';
reg(TYPEID, (mc) => { return <UPLabel mcontext={mc} />; });
export class PLabel extends APDescriptor {

    public constructor(init: Partial<PLabel>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

interface IState {
    uncommittedValue: string | undefined
}

export class UPLabel extends React.Component<UiPropertyProps, IState> {

    state = {
        uncommittedValue: undefined,
    }

    render() {
        const d = this.props.mcontext.descriptor as PLabel;
        return <div className={d.className} style={d.style}>{i18n.t(d.label)}</div>
    }
}

