/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { MChartPlot } from "./MChartPlot";
import { MCategoryAxis, MValueAxis } from "./MAxis";
import { PBoolean } from "../../../ui/primitive/UPBoolean";

export const FHighLowPlot = {
    isShowCloseTicks: new PBoolean({id: 'isShowCloseTicks', label: 'Show Close Ticks', canBeNull: true}),
    isShowOpenTicks: new PBoolean({id: 'isShowOpenTicks', label: 'Show Open Ticks', canBeNull: true})
};

export const MHighLowPlot: APDescriptor = {
    type: 'MHighLowPlot',
    path: 'highLowPlot',
    id: 'highLowPlotProeprtiesDescriptor',
    layouts: [...MChartPlot.layouts, FHighLowPlot.isShowCloseTicks, FHighLowPlot.isShowOpenTicks,
        MCategoryAxis, MValueAxis
    ]
};
