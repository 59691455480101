/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const OrientationEnum = new PEnum({
    id: 'orientation', label: 'Orientation.label',
    items: [
        { key: 'up', value: 'Orientation.up' },
        { key: 'left', value: 'Orientation.left' },
        { key: 'down', value: 'Orientation.down' },
        { key: 'right', value: 'Orientation.right' }
    ]
}); 

