/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import '../../../assets/uxpl/css/Page.css';

import { Map, List } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { IState } from '../../../reducers';
import * as DocumentUtils from '../../../sagas/report/designer/documentUtils';
import * as Actions from '../../../actions/reportActions';
import { PositionFinderContext } from '../hoc/positionFinder';
import Band from './Band';
import BaseElement from './BaseElement';
import { getOrderedBands } from '../../../sagas/report/document/documentFactory';
import { BandTypes, ElementTypes } from '../../../sagas/report/document/elementTypes';
import TablePreviewElement from '../table/TablePreviewElement';
import ListPreviewElement from '../list/ListPreviewElement';
import CrosstabPreviewElement from '../crosstab/CrosstabPreviewElement';
import { i18n } from '@jss/js-common';
import { LanguageContext } from '../LanguageContext';

interface IPage {
  zoom?: number;
  model?: Map<string, any>;
  state?: IState,
  modelActions?: List<any>;
}


/**
 * The Document component manage the display of the main document and the grid.
 * It acts as container for all the report content.
 *
 * @class Document
 * @extends {React.Component<IDocument>}
 */
class Page extends React.Component<IPage> {

  public positionFinder: DocumentUtils.IPositionFinder;

  constructor(props: IPage) {
    super(props);
    this.positionFinder = DocumentUtils.createObjectAbsolutePositionFinder(props.state);
  }

  public shouldComponentUpdate = (nextProps: IPage) => {
    if (this.shouldRebuilPositionFinder(nextProps)) {
      this.positionFinder = DocumentUtils.createObjectAbsolutePositionFinder(nextProps.state);
    }
    return true;
  }

  private shouldRebuilPositionFinder = (nextProps: IPage) => {
    if (nextProps.modelActions.size !== this.props.modelActions.size) {
      return true;
    }
    if (nextProps.modelActions.size > 0 &&
      nextProps.modelActions.get(nextProps.modelActions.size - 1).type === Actions.Actions.OPEN_DOCUMENT) {
      //if the last action is an OPEN DOCUMENT force the refresh, this is to fix the case when a document is opened,
      //closed and another document is opened, the number of model actions is the same but they are two different documents
      return true;
    }
    return false;
  }

  protected getRenderedElements = (topMargin, zoom) => {
    if (!this.props.model || !this.positionFinder) {
      return {renderedBands: [], renderedElements: []};
    }

    const elements = this.props.model.get('elements');
    const bands: Map<string, Map<string, any>> = this.props.model.get('bands');
    const bandsOrder: List<string> = getOrderedBands(this.props.model);

    let renderedBands = null;
    // let elementIdsToRender: string[]|null = null;

    if (bands) {
      let top = topMargin;
      let detailCount = 1;
      const groupsHeadersCount = {};
      const groupsFootersCount = {};
      renderedBands = bandsOrder.map((bandId: string) => {
        const band = bands.get(bandId);
        const currentTop = top;
        const height = band.get('height', 0);
        top += height;
        const bandType = band.get('bandType');
        let label = bandId;
        if (bandType === BandTypes.BAND_DETAIL) {
          label = `${i18n.t('band.detail')} ${detailCount}`;
          detailCount++;
        } else if (bandType === BandTypes.BAND_GROUP_HEADER) {
          const name = band.get('groupName');
          let currentIndex = groupsHeadersCount[name];
          if (!currentIndex) {
            groupsHeadersCount[name] = 1;
            currentIndex = 1;
          }
          label = `${i18n.t('band.groupHeader')} ${name} ${currentIndex > 1 ? currentIndex : ''}`;
          currentIndex++;
          groupsHeadersCount[name] = currentIndex;
        } else if (bandType === BandTypes.BAND_GROUP_FOOTER) {
          const name = band.get('groupName');
          let currentIndex = groupsFootersCount[name];
          if (!currentIndex) {
            groupsFootersCount[name] = 1;
            currentIndex = 1;
          }
          label = `${i18n.t('band.groupFooter')} ${name} ${currentIndex > 1 ? currentIndex : ''}`;
          currentIndex++;
          groupsFootersCount[name] = currentIndex;
        } else {
          label = DocumentUtils.getBandLabel(bandType);
        }
        return (<Band key={bandId} label={label} id={bandId} top={currentTop} height={height} zoom={zoom} />);
      }).toList();

      // elementIdsToRender = bands.reduce( (arr: string[], band: Map<string, any>, name: string) => {
      //     return arr.concat( band.get('elementIds').toJS() );
      // }, []);
    }


    const renderedElements = [];


    // We render all the elements on the page, even the nested ones (i.e. frames and list contents).
    // A special editor mode may enable/disable interactivity on specific elements and show
    // elements that are usually hidden (i.e. the content of a table component).
    const getChildren = (currentNodeId: string, currentNode: Map<string, any>) => {
      const keyValue = currentNode.get('path') + currentNodeId;
      const type = currentNode.get('type');
      if (type === ElementTypes.TABLE) {
        renderedElements.push(<TablePreviewElement zoom={zoom} key={keyValue} id={currentNodeId} />);
      } else if (type === ElementTypes.LIST) {
        renderedElements.push(<ListPreviewElement zoom={zoom} key={keyValue} id={currentNodeId} />);
      } else if (type === ElementTypes.CROSSTAB) {
        renderedElements.push(<CrosstabPreviewElement zoom={zoom} key={keyValue} id={currentNodeId} />);
      } else if (type === ElementTypes.FRAME || type === ElementTypes.ELEMENT_GROUP) {
        renderedElements.push(<BaseElement zoom={zoom} key={keyValue} id={currentNodeId} />);
        const childrenIds = currentNode.get('elementIds');
        childrenIds.forEach(childId => {
          getChildren(childId, elements.get(childId));
        });
      } else {
        renderedElements.push(<BaseElement zoom={zoom} key={keyValue} id={currentNodeId} />);
      }

    }

    bandsOrder.forEach((bandId: string) => {
      const band = bands.get(bandId);
      const bandChildrenIds = band.get('elementIds');
      bandChildrenIds.forEach(childId => {
        getChildren(childId, elements.get(childId));
      });
    });

    return {renderedBands, renderedElements};
  }

  public render() {
    if (!this.props.model || !this.positionFinder) {
      return null;
    }

    const zoom = (this.props.zoom) ? this.props.zoom : 1;

    const topMargin = this.props.model.get('topMargin', 0);
    const bottomMargin = this.props.model.get('bottomMargin', 0);
    const leftMargin = this.props.model.get('leftMargin', 0);
    const rightMargin = this.props.model.get('rightMargin', 0);

    const modelRect = DocumentUtils.documentToRect(this.props.model);
    modelRect.height = this.positionFinder.getPageHeight();

    const documentStyle: React.CSSProperties = { width: 0, height: 0, display: 'flex' };

    if (modelRect) {
      documentStyle.width = modelRect.width * zoom;
      documentStyle.height = (modelRect.height + topMargin + bottomMargin) * zoom;
    }


    const pageWidth = this.props.model.get('docWidth') - leftMargin - rightMargin;
    const pageHeight = this.positionFinder.getPageHeight();


    return (
      <PositionFinderContext.Provider value={this.positionFinder}>
        <LanguageContext.Consumer>
          {
            value => {
              const renderedElements = this.getRenderedElements(topMargin, zoom);
              return <div className="Page" style={documentStyle}>
                <div style={{ position: 'absolute', top: topMargin * zoom, left: leftMargin * zoom, width: pageWidth * zoom, height: pageHeight * zoom }}>
                  <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style={{}}>
                    <defs>
                      <pattern id="smallGrid" width="10" height="10" patternUnits="userSpaceOnUse">
                        <path d="M 10 0 L 0 0 0 10" fill="none" stroke="#dedede" strokeWidth={0.5 / zoom} />
                      </pattern>
                      <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
                        <rect width="100" height="100" fill="url(#smallGrid)" />
                        <path d="M 100 0 L 0 0 0 100" fill="none" stroke="#dedede" strokeWidth={1 / zoom} />
                      </pattern>
                    </defs>

                    <rect width="100%" height="100%" fill="url(#grid)" />
                  </svg>
                </div>
                {renderedElements.renderedBands}
                {renderedElements.renderedElements}
              </div>
            }
          }
        </LanguageContext.Consumer>
      </PositionFinderContext.Provider>
    );
  }
}

const mapStateToProps = (state: IState) => {

  return {
    state: state,
    modelActions: state.getIn(['report', 'modelActions']),
    model: state.getIn(['report', 'model']),
    zoom: state.getIn(['report', 'zoom'])
  };
}


export default connect(mapStateToProps)(Page);
