/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import { IMenuItem } from '@jss/js-common';
import { BandTypes, ElementTypes } from './elementTypes';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

const CREATE_BAND_ACTION_ID = 'createBand';
const CREATE_DETAIL_BAND = 'createDetailBand';
const CREATE_GROUP_HEADER_BAND = 'createGroupHeaderBand';
const CREATE_GROUP_FOOTER_BAND = 'createGroupFooterBand';
const DELETE_BAND_ACTION_ID = 'deleteBand';
const CREATE_PART_ACTION_ID = 'createPart';
const DELETE_PART_ACTION_ID = 'deletePart';

const typeActionsMapCreator = () => {
    const result = new Map<string, IMenuItem[]>();
    result.set(ElementTypes.BAND_PLACEHOLDER, [{
        label: 'Create Band',
        id: CREATE_BAND_ACTION_ID,
        icon: <AddIcon fontSize='small' />,
        hooverAction: true,
    }]);
    result.set(BandTypes.BAND_DETAIL, [{
        label: 'Create Band',
        id: CREATE_DETAIL_BAND,
        icon: <AddIcon fontSize='small' />,
        hooverAction: true,
    }, {
        label: 'Delete Band',
        id: DELETE_BAND_ACTION_ID,
        icon: <ClearIcon fontSize='small' />,
        hooverAction: true,
    }]);
    result.set(BandTypes.BAND_GROUP_HEADER, [{
        label: 'Create Band',
        id: CREATE_GROUP_HEADER_BAND,
        icon: <AddIcon fontSize='small' />,
        hooverAction: true,
    }, {
        label: 'Delete Band',
        id: DELETE_BAND_ACTION_ID,
        icon: <ClearIcon fontSize='small' />,
        hooverAction: true,
    }]);
    result.set(BandTypes.BAND_GROUP_FOOTER, [{
        label: 'Create Band',
        id: CREATE_GROUP_FOOTER_BAND,
        icon: <AddIcon fontSize='small' />,
        hooverAction: true,
    }, {
        label: 'Delete Band',
        id: DELETE_BAND_ACTION_ID,
        icon: <ClearIcon fontSize='small' />,
        hooverAction: true,
    }]);
    result.set(ElementTypes.BAND, [{
        label: 'Delete Band',
        id: DELETE_BAND_ACTION_ID,
        icon: <ClearIcon fontSize='small' />,
        hooverAction: true,
    }]);
    result.set(ElementTypes.SECTION_GROUP, [
        {
            label: 'Create Part',
            id: CREATE_PART_ACTION_ID,
            icon: <AddIcon fontSize='small' />,
            hooverAction: true,
        },
    ]);
    result.set(ElementTypes.SECTION_DETAIL, [
        {
            label: 'Create Part',
            id: CREATE_PART_ACTION_ID,
            icon: <AddIcon fontSize='small' />,
            hooverAction: true,
        },
    ]);
    result.set(ElementTypes.PART, [
        {
            label: 'Delete Part',
            id: DELETE_PART_ACTION_ID,
            icon: <ClearIcon fontSize='small' />,
            hooverAction: true,
        },
    ]);
    return result;
}

export const ACTIONS_BY_TYPE = typeActionsMapCreator();

export const getActionsByType = (type: string) => {
    const actions = ACTIONS_BY_TYPE.get(type);
    return actions ? actions : [];
}

export const ACTION_IDS = {
    CREATE_BAND_ACTION_ID,
    CREATE_DETAIL_BAND,
    CREATE_GROUP_HEADER_BAND,
    CREATE_GROUP_FOOTER_BAND,
    DELETE_BAND_ACTION_ID,
    CREATE_PART_ACTION_ID,
    DELETE_PART_ACTION_ID,
}