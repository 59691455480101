/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddelware from 'redux-saga';
import thunk from 'redux-thunk';
import { IState, state } from '../reducers';
import { rootSaga } from './sagas';


// import the saga that will listen for actions and dispatch the proper action.
// Reducers

// Create the saga middleware
const sagaMiddelware = createSagaMiddelware();

// /**
//  * This middelware help us to transform a TypeScript class implementing an action to a plain javascript object implementing Action.
//  */
// const convertObjectToPlainObject: Middleware<IState, any> = (aStore: Store) => (next: Dispatch<Action>) => (action: any) => {
  
//   // This is a dirty way to convert an class to an object, but the case the action is null or not some sort of object
//   // is close to zero.
//   try {
//     return next({ ...action});
//   }  
//   catch (e) {
//     return next(action);
//   }
// }


// Time to compose our middelwares. We use a special compose is debug is on.
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(
  applyMiddleware( sagaMiddelware, thunk ),
);

// Create the store with the middelware
const store: Store<IState> = createStore(state, enhancer);




// Initialize our sagas.
sagaMiddelware.run(rootSaga);

// Store
export default store;