/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import './assets/uxpl/css/jasper-ui.css';
import './App.css';
import './assets/uxpl/fonts/icons.woff';

import * as React from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import { CreateMuiThemeOptions, createStylesProvider, GenerateClassNameOptions, createGenerateClassName } from '@jss/js-common';
import { StylesProvider as MuiStylesProvider, ThemeProvider, createTheme } from '@material-ui/core/styles';
import ThemeWrapper from './components/common/ThemeWrapper';
import { RunContainer } from '@jss/js-repository';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LoggedArea } from './components/common/LoggedArea';
import { NothingHere, RedirectLoggedIn, RequireLogin } from './components/router/utils';
import { Home } from './components/Home';
import { RepositoryView } from './components/RepositoryView';
import { JRSLoginPage } from './components/JRS/JRSLoginPage';
import { JackrabbitLoginPage } from './components/jackrabbit/JackrabbitLoginPage';
import { LoggedIn } from './components/common/LoggedIn';
import { LogOut } from './components/common/Logout';
import { ResourceViewerWrapper } from './components/editors/ResourceViewerWrapper';
import { JRSLoginPageREST } from './components/JRS/JRSLoginPageREST';

//CSS from JRS

const generateClassName = createGenerateClassName(GenerateClassNameOptions);
const muiTheme = createTheme(CreateMuiThemeOptions);

const StylesProvider = createStylesProvider(generateClassName, muiTheme, MuiStylesProvider, ThemeProvider)

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <StylesProvider>
                    <ThemeWrapper />
                    <RunContainer>
                        <BrowserRouter>
                            <div className="App">
                                <LoggedArea />
                                <Routes>
                                    <Route element={<RedirectLoggedIn to='/browse' />}>
                                        <Route path="/" element={<Home />} />
                                    </Route>
                                    <Route element={<RequireLogin redirectTo='/' />}>
                                        <Route path="/browse" element={<RepositoryView />} />
                                        <Route path='/:editMode/*' element={<ResourceViewerWrapper />}/>
                                    </Route>
                                    <Route path="/jrslogin" element={<JRSLoginPage />} />
                                    <Route path="/js-login" element={<JRSLoginPageREST />} />
                                    <Route path="/jackrabbit-login" element={<JackrabbitLoginPage />} />
                                    <Route path="/loggedin" element={<LoggedIn />} />
                                    <Route path="/logout" element={<LogOut />} />
                                    <Route path="*" element={<NothingHere />} />
                                </Routes>
                            </div>
                        </BrowserRouter>
                    </RunContainer>
                </StylesProvider>
            </PersistGate>
        </Provider>
    );

}

export default App;
