/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { getReportPaletteItems, IPaletteCategories, IReportPaletteElement } from './paletteConstants';
import Palette from '../../common/palette/Palette';
import ReportPaletteItem from './ReportPaletteItem';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@jss/js-common';
import i18n from '../../../i18n';

class ReportPalette extends React.Component {
  public render() {
    return <div style={{ flex: 1, flexDirection: 'column', overflow: 'auto' }}>
      {getReportPaletteItems().map(cat => <ReportPaletteCategory key={`rpc.${cat.category}`} category={cat} />)}
    </div>;
  }
}
interface IReportPaletteCategoryProps {
  category: IPaletteCategories;
}
interface IReportPaletteCategoryState {
  expanded: boolean | undefined;
}
class ReportPaletteCategory extends React.Component<IReportPaletteCategoryProps, IReportPaletteCategoryState> {
  public state = { expanded: true }

  private handleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  }
  private getPaletteItems(items: IReportPaletteElement[]): JSX.Element[] {
    return items.map((item: IReportPaletteElement, index) => (<ReportPaletteItem key={`rpi.${item.id}.${index}`} item={item} />));
  }

  public render() {
    const cat = this.props.category;
    if (cat.category === '')
      return <Palette key={`pd.default`}>{this.getPaletteItems(cat.items)}</Palette>;
    return <Accordion key={`acc.${cat.category}`} expanded={this.state.expanded} onChange={this.handleExpanded} size='small'>
      <AccordionSummary key={`as.${cat.category}`} aria-controls="panel1a-content" id="panel1a-header" size='small'>
        <Typography key={`at.${cat.category}`} style={{ textTransform: 'capitalize' }} >{i18n.t(cat.category)}</Typography>
      </AccordionSummary>
      <AccordionDetails key={`ad.${cat.category}`}>
        <Palette key={`plt.${cat.category}`}>{this.getPaletteItems(cat.items)}</Palette>
      </AccordionDetails>
    </Accordion>;
  }

}

export default ReportPalette;
