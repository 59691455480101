/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map } from 'immutable';
import { CVC_NAMESPACE } from '../reader/JrxmCVCUtils';
import { createDatasetRun, createReportElement, setAttributeIfPresent, setExpressionNodeFromMap } from './JrxmlHelper';

export const createCVCElement = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const componentElement = xmlDocument.createElement("componentElement");
    const reportElement = createReportElement(elementNode, xmlDocument);
    componentElement.appendChild(reportElement);

    const baseNamespace = elementNode.get(CVC_NAMESPACE, "cvc");
    const namespace = baseNamespace + ":";

    const cvcElement = xmlDocument.createElement(namespace + "customvisualization");

    const properties: Map<string, Map<string, any>> = elementNode.get("cvcProperties", null);
    if (properties !== null){
        properties.forEach((propertyValue: Map<string, any>, keyName: string) => {
            const name = propertyValue.get('name', keyName);
            const itemPropertyElement = xmlDocument.createElement(namespace + "itemProperty");
            itemPropertyElement.setAttribute("name", name);
            setAttributeIfPresent(propertyValue, "value", itemPropertyElement);
            setExpressionNodeFromMap(itemPropertyElement, xmlDocument, propertyValue, "valueExpression");
            cvcElement.appendChild(itemPropertyElement);
        });
    }

    const data: List<Map<string, string>> = elementNode.get("cvcData", null);
    if (data !== null){
        data.forEach((cvcDataNode: Map<string, any>) => {
            const dataElement = xmlDocument.createElement(namespace + "cvData");
            const datasetNode : Map<string, string> = cvcDataNode.get("dataset", null); 
            if (datasetNode !== null){
                const datasetElement = createCVCDataset(datasetNode, xmlDocument);
                dataElement.appendChild(datasetElement);
            }
            const itemsNode : List<List<Map<string, any>>> = cvcDataNode.get("cvcItems", null); 
            if (itemsNode !== null){
                itemsNode.forEach((cvcItemValue: List<Map<string, any>>) => {
                    const itemElement = xmlDocument.createElement(namespace + "item");
                    cvcItemValue.forEach((cvcItemPropertyValue: Map<string, any>) => {
                        const name = cvcItemPropertyValue.get('name');
                        const itemPropertyElement = xmlDocument.createElement(namespace + "itemProperty");
                        itemPropertyElement.setAttribute("name", name);
                        setAttributeIfPresent(cvcItemPropertyValue, "value", itemPropertyElement);
                        setExpressionNodeFromMap(itemPropertyElement, xmlDocument, cvcItemPropertyValue, "valueExpression");
                        itemElement.appendChild(itemPropertyElement);
                    });
                    dataElement.appendChild(itemElement);
                });
            }

            cvcElement.appendChild(dataElement);
        });
    }

    setAttributeIfPresent(elementNode, "evaluationTime", cvcElement);
    setAttributeIfPresent(elementNode, "evaluationGroup", cvcElement);

    cvcElement.setAttribute("xmlns:" + baseNamespace, elementNode.get("xmlns:" + baseNamespace, "http://www.jaspersoft.com/cvcomponent"));
    cvcElement.setAttribute("xsi:schemaLocation", elementNode.get("xsi:schemaLocation", "http://www.jaspersoft.com/cvcomponent http://www.jaspersoft.com/cvcomponent/component.xsd"));
    
    componentElement.appendChild(cvcElement);
    return componentElement;
}

const createCVCDataset = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetElement = xmlDocument.createElement("dataset");
    
    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "incrementWhenExpression");

    const datasetRunNode: Map<string, any> = elementNode.get("datasetRun", null);
    if (datasetRunNode !== null && !datasetRunNode.isEmpty()){
        datasetElement.appendChild(createDatasetRun(datasetRunNode, xmlDocument));
    }

    setAttributeIfPresent(elementNode, "resetType", datasetElement);
    setAttributeIfPresent(elementNode, "resetGroup", datasetElement);
    setAttributeIfPresent(elementNode, "incrementType", datasetElement);
    setAttributeIfPresent(elementNode, "incrementGroup", datasetElement);
    
    return datasetElement;
}