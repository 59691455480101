/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

 import * as React from 'react';

import '../../../../assets/uxpl/css/BandResize.css';



interface IBandResize {
  zoom: number,
  key: string,
  className?: string,
  style: {
      top: number,
      left: number,
      width: number,
      height: number
  },
  limitReached?: boolean
  label?: string
}


const BandResize = (props: IBandResize) => {

    const barColor = props.limitReached ? "#FF0000" : "#000000";

    // Magic of ES7: create a new const called divProps from the object props,
    // by EXCLUDING zoom and limitReached....
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { zoom, limitReached, label, ...divProps } = props;
    const tooltipZoom = zoom && zoom > 1 ? zoom : 1 
    return (<div {...divProps} style={{...divProps.style, ...props.style, top: props.style.top * props.zoom, left: props.style.left * props.zoom, width: props.style.width * props.zoom, height: props.style.height * props.zoom }}>
              <svg className="DragElementBorder" style={{position: 'absolute'}} width={props.style.width * props.zoom} height={ Math.max(1, props.style.height * props.zoom)}>
                <rect width="100%" y="100%" height={1/props.zoom} fill={barColor} shapeRendering="optimizeSpeed" stroke="none"/>
              </svg>
              <label style={{ transform: `scale(${1 * tooltipZoom}) translate(-50%, -50%)`}}>
                  {props.label} {props.style.height} px { props.limitReached ? '(No more space available)' : null }
              </label>
            </div>);
}

export default BandResize;


/* <svg className="BaseElementBorder" width={props.element.get('width')} height={props.element.get('height')} style={{ position: 'absolute' }}>
                  <rect shapeRendering="crispedges" width="100%" height="100%" style={{ fill: 'none', strokeWidth: 2/props.zoom, stroke: '#0081cb' }} />
              </svg>
              <FigureHandle id={id} direction={Direction.TOP_LEFT} />
              <FigureHandle id={id} direction={Direction.TOP}/>
              <FigureHandle id={id} direction={Direction.TOP_RIGHT}/>
              <FigureHandle id={id} direction={Direction.RIGHT}/>
              <FigureHandle id={id} direction={Direction.BOTTOM_RIGHT}/>
              <FigureHandle id={id} direction={Direction.BOTTOM}/>
              <FigureHandle id={id} direction={Direction.BOTTOM_LEFT}/>
              <FigureHandle id={id} direction={Direction.LEFT}/> */