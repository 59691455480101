/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnumGroup } from "../dataset/UPEnumGroup";


export const ResetTypeEnum = new PEnumGroup({
    id: 'resetType', label: 'ResetType.label', default: 'None',
    groupProperty: 'resetGroup',
    items: [
        { key: 'None', value: 'ResetType.None' },
        { key: 'Report', value: 'ResetType.Report' },
        { key: 'Page', value: 'ResetType.Page' },
        { key: 'Column', value: 'ResetType.Column' },
        { key: 'Master', value: 'ResetType.Master' },
        { key: 'Group', value: 'ResetType.Group' }
    ]
});

