/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import '../../../assets/uxpl/css/Page.css';
import { IState } from '../../../reducers';
import * as DocumentUtils from '../../../sagas/report/designer/documentUtils';
import { ElementTypes } from '../../../sagas/report/document/elementTypes';
import { computeCrosstabRealSize } from '../../common/JrxmlModel/reader/JrxmlCrosstabUtils';
import { PositionFinderContext } from '../hoc/positionFinder';
import ListPreviewElement from '../list/ListPreviewElement';
import TablePreviewElement from '../table/TablePreviewElement';
import CrosstabBaseElement from './CrosstabBaseElement';
import CrosstabFeedbackElement from './CrosstabFeedbackElement';
import CrosstabPreviewElement from './CrosstabPreviewElement';

interface ICrosstabPage {
  model?: Map<string, any>;
  report?: Map<string, any>;
  state?: IState;
  modelActions?: List<any>;
}

/**
 * The Document component manage the display of the main document and the grid.
 * It acts as container for all the report content.
 *
 * @class Document
 * @extends {React.Component<IDocument>}
 */
class CrosstabPage extends React.Component<ICrosstabPage> {


  public positionFinder: DocumentUtils.IPositionFinder;


  constructor(props: ICrosstabPage) {
    super(props);
    this.positionFinder = DocumentUtils.createObjectAbsolutePositionFinder(props.state);
  }

  public shouldComponentUpdate = (nextProps: ICrosstabPage) => {
    if (nextProps.modelActions.size !== this.props.modelActions.size) {
      this.positionFinder = DocumentUtils.createObjectAbsolutePositionFinder(nextProps.state);
    }
    return true;
  }

  getCrosstabChildren = (currentNode): Map<string, any>[] => {
    const currentChildren = [];
    const children = currentNode.get('elementIds');
    if (children) {
      children.forEach(childId => {
        const child = this.props.model.getIn(['elements', childId]);
        //the no data cell is rendered by it's own
        currentChildren.push(child);
        const subchildren = this.getCrosstabChildren(child);
        currentChildren.push(...subchildren);
      });
    }
    return currentChildren;
  }

  public render() {
    const currentEditorIndex = this.props.report.getIn(['currentEditorIndex']);
    const currentEditor = this.props.report.getIn(['subeditors', currentEditorIndex]) as Map<string, any>;
    const corsstabPath = currentEditor.get('editedResourceId').split('/');
    const crosstabModel = this.props.report.getIn(['model', ...corsstabPath]) as Map<string, any>;
    if (!this.props.model || !this.positionFinder || !crosstabModel) {
      return null;
    }
    const mainCrosstabId = crosstabModel.get('id');
    const zoom = currentEditor.get('zoom', 1);

    const modelRect = computeCrosstabRealSize(crosstabModel, this.props.model);
    const childrenToRender = this.getCrosstabChildren(crosstabModel);
    childrenToRender.push(crosstabModel);

    const renderedElements = childrenToRender.map((ele: Map<string, any>) => {
      const id: string = ele.get('id');
      if (id) {
        if (id === mainCrosstabId) {
          return (<CrosstabFeedbackElement zoom={zoom} key={id} id={id} />);
        } else {
          const type = ele.get('type');
          if (type === ElementTypes.LIST) {
            return <ListPreviewElement zoom={zoom} key={id} id={id} />
          } else if (type === ElementTypes.TABLE) {
            return <TablePreviewElement zoom={zoom} key={id} id={id} />
          } else if (type === ElementTypes.CROSSTAB) {
            return <CrosstabPreviewElement zoom={zoom} key={id} id={id} />
          }
          return (<CrosstabBaseElement zoom={zoom} key={id} id={id} />);
        }
      }
      // We should never reach this point...
      return undefined;
    });
    // }

    const documentStyle = { width: 0, height: 0 };

    if (modelRect) {
      documentStyle.width = modelRect.width * zoom;
      documentStyle.height = modelRect.height * zoom;
    }

     /*const crosstabNoDataCell = getCrosstabNoDataCell(crosstabModel, this.props.model);
    let crosstabNoDataCellElement;
   if (crosstabNoDataCell) {
      const noDataCellStyle : React.CSSProperties = {
        width: crosstabModel.get('width', 0) * zoom,
        height: crosstabModel.get('height', 0) * zoom,
        marginTop: CROSSTAB_NO_DATA_CELL_GAP,
        background: 'white',
      }
      crosstabNoDataCellElement = <div style={noDataCellStyle}>
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style={{ ...documentStyle, position: 'relative' }}>
          <defs>
            <pattern id="smallGrid" width="10" height="10" patternUnits="userSpaceOnUse">
              <path d="M 10 0 L 0 0 0 10" fill="none" stroke="#dedede" strokeWidth={0.5 / zoom} />
            </pattern>
            <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
              <rect width="100" height="100" fill="url(#smallGrid)" />
              <path d="M 100 0 L 0 0 0 100" fill="none" stroke="#dedede" strokeWidth={1 / zoom} />
            </pattern>
          </defs>

          <rect width="100%" height="100%" fill="url(#grid)" />
        </svg>
        {renderedElements}
      </div>
    }*/

    return (
      <PositionFinderContext.Provider value={this.positionFinder}>
        <div className="Page" style={documentStyle}>
          <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style={{ ...documentStyle, position: 'relative' }}>
            <defs>
              <pattern id="smallGrid" width="10" height="10" patternUnits="userSpaceOnUse">
                <path d="M 10 0 L 0 0 0 10" fill="none" stroke="#dedede" strokeWidth={0.5 / zoom} />
              </pattern>
              <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
                <rect width="100" height="100" fill="url(#smallGrid)" />
                <path d="M 100 0 L 0 0 0 100" fill="none" stroke="#dedede" strokeWidth={1 / zoom} />
              </pattern>
            </defs>

            <rect width="100%" height="100%" fill="url(#grid)" />
          </svg>
          {renderedElements}
        </div>
      </PositionFinderContext.Provider>
    );
  }
}

const mapStateToProps = (state: IState) => {

  return {
    state: state,
    modelActions: state.getIn(['report', 'modelActions']),
    model: state.getIn(['report', 'model']),
    report: state.getIn(['report']),
  };
}

export default connect(mapStateToProps)(CrosstabPage);
