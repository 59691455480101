/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { EmptyDataAdapterEditor } from './EmptyDataAdapterEditor';
import { createNode, getProlog, IDataAdapterConfig, IDataAdapterDescriptor } from '../IDataAdapterDescriptor';
import format from 'xml-formatter';
import { MIME_TYPES } from '@jss/js-rest-api'; 
import { i18n } from '@jss/js-common';
import { nameValidator } from '@jss/js-common/src/utils/validators';
import { getDataAdapterNode } from '../../editor/Utils';

export default class JndiDataAdapterDescriptor implements IDataAdapterDescriptor {

    public getMime = () => {
        return MIME_TYPES.DATA_ADAPTER_EMPTY;
    }

    public getDataAdapterLabel = () => {
        return 'datasource.empty.label';
    }

    /**
    *  Return the name of this data adapter to be presented to the user
    *  i.e. CSV File
    */
    public getDataAdapterDescription = () => {
        return 'datasource.empty.description';
    }

    public getDataAdapterClass = () => {
        return 'net.sf.jasperreports.data.empty.EmptyDataAdapterImpl'
    }

    public getDataAdapterRootName = () => {
        return 'emptyDataAdapter';
    }

    /**
     * Return a plain json object with the default data adapter configuration.
     * It can be just an empty object.
     *
     * @memberof IDataAdapter
     */
    public initializeDataAdapterConfig = () => {
        return {
            class: this.getDataAdapterClass(),
            name: 'New Empty Data Adapter',
            recordCount: '10',
        }
    };

    /**
     * Given a data adapter configuration, this methos returns null or undefined if
     * the configuration is valid, otherwise it provides an error.
     *
     * @memberof IDataAdapter
     */
    public validateDataAdapterConfig = (config: IDataAdapterConfig) => {

        // Check for errors...
        const errors = [];

        const nameError = nameValidator(config.name);
        if (nameError) {
          errors.push(nameError);
        }

        const number = parseInt(config.recordCount, 10);
        if (isNaN(number)) {
            errors.push(i18n.t('datasource.record.notanumbererror'));
        } else if (number < 0) {
            errors.push(i18n.t('datasource.record.negativeerror'));
        }

        if (errors.length > 0) {
            return errors.join("\n");
        }

        return undefined;

    }

    /**
     * Given a data adapter configuration, this methos returns null or undefined if
     * the configuration is valid, otherwise it provides an error.
     *
     * @memberof IDataAdapter
     */
    public createEditor = (config: IDataAdapterConfig, onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => {
        return <EmptyDataAdapterEditor {...config} onDataAdapterSettingsChange={onDataAdapterSettingsChange} readOnly={readOnly}/>
    }

    public getLanguages = () => {
        return [];
    }

    public toXml = (config: IDataAdapterConfig) => {
        const xmlDoc = document.implementation.createDocument(null, this.getDataAdapterRootName());
        const root = xmlDoc.getElementsByTagName(this.getDataAdapterRootName())[0];
        root.setAttribute('class', this.getDataAdapterClass());

        const nameNode = createNode(xmlDoc, config, 'name', 'name');
        if (nameNode) {
            root.appendChild(nameNode);
        }

        const dataSourceNameNode = createNode(xmlDoc, config, 'recordCount', 'recordCount');
        if (dataSourceNameNode) {
            root.appendChild(dataSourceNameNode);
        }

        const prolog = getProlog();
        const xml = prolog + (new XMLSerializer().serializeToString(xmlDoc));
        return format(xml, { indentation: '  ', collapseContent: true });
    }

    public toJson = (xml: Document) => {
        const result: any = {
            class: this.getDataAdapterClass(),
            name: '',
            recordCount: '',
        }

        getDataAdapterNode(xml.getRootNode()).childNodes.forEach((childNode) => {
            const nodeName = childNode.nodeName;
            if (nodeName === 'name') {
                result.name = childNode.textContent;
            } else if (nodeName === 'recordCount') {
                result.recordCount = childNode.textContent;
            }
        });
        return result;
    }
}