/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { Repository } from '@jss/js-repository';
import { Conf, IRepositoryItemDescriptor } from '@jss/js-rest-api';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/uxpl/css/RepositoryView.css';
import { AppHeader } from './common/AppHeader';
import { ResourceRouteState } from '@jss/js-repository';
import { useSelector } from "react-redux";
import { Map } from 'immutable';
import { Footer } from '@jss/js-common';
import { RepositoryApi } from '@jss/js-rest-api';

interface IState {
    path?: IRepositoryItemDescriptor[],
}

/**
 * This is the repository main screen
 */
export function RepositoryView() {
    const [state, setState] = useState<IState>({});
    const navigate = useNavigate();

    useEffect(() => {
        const cp = sessionStorage.getItem('currentPath');
        if (cp) {
            const path = JSON.parse(cp);
            if (path) {
                setState({ path: path });
            }
        } else {
            setState({ path: [RepositoryApi.inst().getRoot()] });
        }
    }, []); // empty array

    const goToHome = useCallback(
        () => {
            setState({ path: [RepositoryApi.inst().getRoot()] });
        }, []);

    const changePath = useCallback(
        (path: IRepositoryItemDescriptor[]) => {
            setState({ path: path });
            sessionStorage.setItem('currentPath', JSON.stringify(path));
        }, []);

    const openResource = useCallback(
        (descriptor: IRepositoryItemDescriptor) => {
            const prm: ResourceRouteState = {
                descriptor,
                path: state.path
            };
            if (descriptor.mime !== undefined) {
                if (descriptor.uuid) {
                    navigate(`/edit?path=${descriptor.uuid}`, { state: prm });
                } else {
                    let path = descriptor.path;
                    if (state.path){
                        if (state.path[state.path.length - 1].uuid){
                            path = state.path[state.path.length - 1].uuid
                        } else {
                            path = state.path[state.path.length - 1].path;
                        }
                    }
                    navigate(`/new?path=${path}`, { state: prm });
                }
            }
        }, [navigate, state]);
    //language is actually not used since it is read from the server conf, but this will trigger the update
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language: string | undefined = useSelector((state: any) => {
        return (state.persistentReducer.storedData as Map<string, any>).get('language') as string | undefined
    });
    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <AppHeader goToHome={goToHome} />
            {state && state.path && <Repository path={state.path} changePath={changePath} openResource={openResource} />}
            <Footer config={Conf.get('jrws.jrio.serverInfo')} />
        </div>
    );
}
