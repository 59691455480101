/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MBoxGraphicElement } from "../common/MGraphicElement";
import { MTextElement } from "../common/MTextElement";
import { EvaluationTimeEnum } from "../enums/EvaluationTimeEnum";
import { TextAdjustEnum } from "../enums/TextAdjustEnum";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PPattern } from "../../ui/primitive/UPPattern";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { MBookmarks } from "../common/MBookmarks";
import { MHyperlinks } from "../common/MHyperlinks";
import { ICONS } from "@jss/js-common";
import { MTextFieldCustom } from "./MTextFieldCustom";

export const FTextField = {
    expression: new PExpression({ id: 'textFieldExpression', label: 'Expression' }),
    evaluationTime: EvaluationTimeEnum,
    textAdjust: TextAdjustEnum,
    blankWhenNull: new PBoolean({ id: 'isBlankWhenNull', label: 'Blank When Null' }),
    pattern: new PPattern({ id: 'pattern', label: 'Pattern' }),
    patternExpression: new PExpression({ id: 'patternExpression', label: 'Pattern Expression' }),
};

export const MTextField: APDescriptor = {
    type: ElementTypes.TEXTFIELD,
    id: 'textFieldPropertiesDescriptor',
    label: 'Text Field',
    default: { width: 200, height: 30, textFieldExpression: '"Text Field"' },
    icon: ICONS.TEXTFIELD_ICON,
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MBoxGraphicElement.layouts, MTextFieldCustom] }),
            new PDiv({
                label: 'Text Field',
                layouts: [FTextField.expression, FTextField.evaluationTime, FTextField.pattern, FTextField.patternExpression, 
                    FTextField.textAdjust, FTextField.blankWhenNull, ...MTextElement.layouts, MBookmarks, MHyperlinks]
            })
        ]
    })]
};
