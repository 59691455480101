/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { fromJS } from 'immutable';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as ReportActions from '../../../actions/reportActions';
import { createReportElement } from '../document/documentFactory';
import { ElementTypes } from '../document/elementTypes';
import * as DocumentUtils from './documentUtils';


export function* create100Elements() {

      const state = yield select();

      const model = state.getIn(['report','model']);
      if (model) {
            // Let's create 100 elements...
            let x = 0;
            let y = 0;

            for (let i=0; i<100; ++i) {
                  const newEle = createReportElement(ElementTypes.STATICTEXT, {x, y})
                                    .merge(fromJS({ width: 80, height: 30 }));
                  yield put( ReportActions.addElement(newEle, 'bands/title'));
                  x += 81;

                  if (x > 595) {
                        y += 31;
                        x = 0;   
                  }
            }
            yield call( DocumentUtils.updateClientArea );
      }
}


/**
 * Binding of event type and proper flow control for saga.
 */
export const debugSagas = [
  takeEvery( ReportActions.Actions.DESIGNER_DEBUG_CREATE_100_ELEMENTS_ACTION, create100Elements )
];





