/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const OnErrorTypeEnum = new PEnum({
    id: 'onErrorType', label: 'OnErrorType.label', isEmptyUndefined: true, default: 'Error',
    items: [
        { key: '', value: ''},
        { key: 'Error', value: 'OnErrorType.Error'},
        { key: 'Blank', value: 'OnErrorType.Blank'},
        { key: 'Icon', value: 'OnErrorType.Icon'},
    ]
}); 
 
