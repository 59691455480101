/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from "react";
import { getJRIOContextEditorHeaderComponentType, JRIOContextEditorView } from "@jss/js-text-editor";
import { Conf, IEditorViewProps } from "@jss/js-rest-api";
import { Header } from "../common/Header";
import { useSelector } from "react-redux";
import { Footer } from "@jss/js-common";

export function JRIOContextEditorViewWrapper({ descriptor, path, doExit, onFirstSave }: IEditorViewProps) {
    const getHeaderComponent: getJRIOContextEditorHeaderComponentType = ({
        messageInfo,
        tabs,
        tabIndex,
        toolbarData,
        resourceName,
        onTabChange,
        goToHome,
        requestLogout,
        closeMessage
    }) => {
        return (
            <Header
                message={messageInfo}
                tabs={tabs}
                currentTabIndex={tabIndex}
                toolbarData={toolbarData}
                resourceName={resourceName}
                onTabChange={onTabChange}
                goToHome={goToHome}
                requestLogout={requestLogout}
                closeMessage={closeMessage}
                documentType="jrio-context"
            />
        );
    }
    //language is actually not used since it is read from the server conf, but this will trigger the update
    const language: string | undefined = useSelector((state: any) => {
        return (state.persistentReducer.storedData as Map<string, any>).get('language') as string | undefined
    });
    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                <JRIOContextEditorView
                    descriptor={descriptor}
                    path={path}
                    doExit={doExit}
                    onFirstSave={onFirstSave}
                    getHeaderComponent={getHeaderComponent}
                    language={language}
                />
            </div>
            <Footer config={Conf.get('jrws.jrio.serverInfo')}/>
        </div>
    );
}
