/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const LineChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M44 4v130h218V4z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M15.51 157.188L254.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M15.51 173.5L254.49 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M15.51 173.5L15.51 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M254.49 173.5L254.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#f0f" strokeLinejoin="bevel" d="M19.01 165.344h14" clipPath="url(#clipPath1)" />
            <text x="37.01" y="168.113" stroke="none" strokeLinejoin="bevel" clipPath="url(#clipPath1)">Double Freight</text>
            <path fill="none" stroke="#ff0" strokeLinejoin="bevel" d="M102.22 165.344h14" clipPath="url(#clipPath1)" />
            <text x="120.221" y="168.113" stroke="none" strokeLinejoin="bevel" clipPath="url(#clipPath1)">Normal Freight</text>
            <path fill="none" stroke="#0ff" strokeLinejoin="bevel" d="M186.17 165.344h14" clipPath="url(#clipPath1)" />
            <text x="204.17" y="168.113" stroke="none" strokeLinejoin="bevel" clipPath="url(#clipPath1)">Half Freight</text>
            <path fill="none" strokeWidth="0.5" d="M44 134L262 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M70.16 134L70.16 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M70.16 134L70.16 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M111.58 134L111.58 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M111.58 134L111.58 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M153 134L153 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M153 134L153 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M194.42 134L194.42 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M194.42 134L194.42 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M235.84 134L235.84 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M235.84 134L235.84 136" clipPath="url(#clipPath1)" />
            <text x="56.965" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Arge...</text>
            <text x="97.449" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Austria</text>
            <text x="136.602" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Belgium</text>
            <text x="183.258" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Brazil</text>
            <text x="220.619" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Canada</text>
            <path fill="none" strokeWidth="0.5" d="M44 4L44 134" clipPath="url(#clipPath1)" />
            <text x="32.91" y="136.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
            <path fill="none" strokeWidth="0.5" d="M42 134L44 134" clipPath="url(#clipPath1)" />
            <text x="15.098" y="115.832" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">2.500</text>
            <path fill="none" strokeWidth="0.5" d="M42 113.062L44 113.062" clipPath="url(#clipPath1)" />
            <text x="15.098" y="94.894" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">5.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 92.124L44 92.124" clipPath="url(#clipPath1)" />
            <text x="15.098" y="73.956" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">7.500</text>
            <path fill="none" strokeWidth="0.5" d="M42 71.186L44 71.186" clipPath="url(#clipPath1)" />
            <text x="10.008" y="53.018" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 50.249L44 50.249" clipPath="url(#clipPath1)" />
            <text x="10.008" y="32.08" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">12.500</text>
            <path fill="none" strokeWidth="0.5" d="M42 29.311L44 29.311" clipPath="url(#clipPath1)" />
            <text x="10.008" y="11.143" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">15.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 8.373L44 8.373" clipPath="url(#clipPath1)" />
         </g>
         <g fill="none" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path d="M44 134L262 134" clipPath="url(#clipPath2)" />
            <path d="M44 113.062L262 113.062" clipPath="url(#clipPath2)" />
            <path d="M44 92.124L262 92.124" clipPath="url(#clipPath2)" />
            <path d="M44 71.186L262 71.186" clipPath="url(#clipPath2)" />
            <path d="M44 50.249L262 50.249" clipPath="url(#clipPath2)" />
            <path d="M44 29.311L262 29.311" clipPath="url(#clipPath2)" />
            <path d="M44 8.373L262 8.373" clipPath="url(#clipPath2)" />
         </g>
         <g fill="none" stroke="#f0f" strokeLinejoin="bevel" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path d="M70.16 123.974L111.58 10.191" clipPath="url(#clipPath2)" />
            <path stroke="#ff0" d="M70.16 128.987L111.58 72.095" clipPath="url(#clipPath2)" />
            <path stroke="#0ff" d="M70.16 131.493L111.58 103.048" clipPath="url(#clipPath2)" />
            <path d="M111.58 10.191L153 112.557" clipPath="url(#clipPath2)" />
            <path stroke="#ff0" d="M111.58 72.095L153 123.279" clipPath="url(#clipPath2)" />
            <path stroke="#0ff" d="M111.58 103.048L153 128.639" clipPath="url(#clipPath2)" />
            <path d="M153 112.557L194.42 52.256" clipPath="url(#clipPath2)" />
            <path stroke="#ff0" d="M153 123.279L194.42 93.128" clipPath="url(#clipPath2)" />
            <path stroke="#0ff" d="M153 128.639L194.42 113.564" clipPath="url(#clipPath2)" />
            <path d="M194.42 52.256L235.84 97.181" clipPath="url(#clipPath2)" />
            <path stroke="#ff0" d="M194.42 93.128L235.84 115.591" clipPath="url(#clipPath2)" />
            <path stroke="#0ff" d="M194.42 113.564L235.84 124.795" clipPath="url(#clipPath2)" />
         </g>
      </svg>
   );
}
export default LineChartIcon;