/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { FormControlledTextField } from '@jss/js-common';
import { getKey, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { connect } from 'react-redux';
import * as ReportActions from '../../../../../actions/reportActions';
import i18n from '../../../../../i18n';
import { ValidationResult, VALIDATION_RESULT } from '@jss/js-common/src/utils/validators';

const TYPEID = 'sizeAndPosition';
reg(TYPEID, (mc) => { return <UPSizeAndPosition mcontext={mc} />; });
export class PSizeAndPosition extends APDescriptor {
    min?: number;
    max?: number;
    canBeEmpty?: boolean = true;
    default?: number;
    readonly?: boolean = false;

    public validator(v: string): string | undefined {
        if(this.canBeEmpty === true && v === undefined)
            return undefined;

        if (!this.canBeEmpty && (!v || v.trim().length === 0)) return `value can't be empty`;
        const value = parseFloat(v);
        if (isNaN(value)){
            return 'The value is not a valid number';
        }
        if (this.max && v && value > this.max) return 'value is exceeding ' + this.max;
        if (this.min && v && value > this.min) return 'value is exceeding ' + this.min;
        return undefined;
    }


    public constructor(init: Partial<PSizeAndPosition>) {
        super();
        Object.assign(this, { validator: this.validator, ...init, type: TYPEID });
    }
}

interface SizeAndPositionProps {
    updateElementsSizeAndPosition?: (changes) => void;
}

interface IState {
    uncommittedValue: string | undefined
}

const pattern = /^-?\d*$/;

const integerValidator = (stringValue: string | undefined, descriptor: PSizeAndPosition): ValidationResult => {
    if (!stringValue) {
        if (descriptor.canBeEmpty) {
            return { result: VALIDATION_RESULT.VALID }
        } else {
            return { result: VALIDATION_RESULT.PARTIALLY_INVALID, message: i18n.t('validators.fieldNameEmpty') }
        }
    }
    if (pattern.test(stringValue) || stringValue.length === 0) {
        if (stringValue.length === 1 && stringValue.startsWith('-')) {
            return { result: VALIDATION_RESULT.PARTIALLY_INVALID }
        } else {
            const newValue = stringValue.length === 0 ? undefined : parseInt(stringValue, 10);
            if (Number.isNaN(newValue)) {
                return { result: VALIDATION_RESULT.INVALID }
            }
        }
    } else {
        return { result: VALIDATION_RESULT.INVALID }
    }
    return { result: VALIDATION_RESULT.VALID }
}


export class UPSizeAndPosition extends React.Component<UiPropertyProps & SizeAndPositionProps, IState> {

    state = {
        uncommittedValue: undefined,
    }

    render() {
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const v = this.state.uncommittedValue !== undefined ? this.state.uncommittedValue : this.props.mcontext.model.getIn(p);
        const validator = (stringValue: string) => integerValidator(stringValue, descriptor);
        const descriptor = this.props.mcontext.descriptor as PSizeAndPosition;
        return <FormControlledTextField
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            key={getKey(p)}
            onTextChange={this.onSizePositionChange}
            label={i18n.t(this.props.mcontext.descriptor.label)}
            // defaultValue={this.props.placeholder}
            inline={true}
            size={'small'}
            value={v !== undefined ? v.toString() : ''}
            disabled={descriptor.readonly}
            InputProps={{
                readOnly: descriptor.readonly,
            }}
            InputLabelProps={descriptor.deprecated ? {className: 'deprecatedProperty'} : undefined}
            validator={validator}
        />
    }

    public onSizePositionChange = (newValue: string | undefined) => {
        const descriptor = this.props.mcontext.descriptor as PSizeAndPosition;
        if (!descriptor.validator || !descriptor.validator(newValue)){
            this.setState({uncommittedValue: undefined}, () => {
                const actions = []
                this.props.mcontext.elements.forEach(key => {
                    const path = getPath(undefined, key.path);
                    const elementId = path[path.length - 1];
                    actions.push({
                        id: elementId,
                        [this.props.mcontext.descriptor.id]: parseInt(newValue, 10)
                    })
                });
                this.props.updateElementsSizeAndPosition(actions);
            });
        } else {
            this.setState({uncommittedValue: newValue})
        }
    }
}

const mapStateToProps = () => {
    return {};
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateElementsSizeAndPosition: (changes) => { dispatch(ReportActions.updateElementsSizeAndPosition(changes)); },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UPSizeAndPosition);
