import { PAccordeon } from '../../ui/composite/UPAccordeon';
import { PBoolean } from '../../ui/primitive/UPBoolean';
import { PColor } from '../../ui/primitive/UPColor';
import { PConnectedTable } from '../../ui/tables/UPConnectedTable';
import { PTableElement } from '../../ui/tables/UPTableElement';
import { PEnum } from '../../ui/primitive/UPEnum';
import { PInt } from '../../ui/primitive/UPInteger';
import { PNumberUnit } from '../../ui/primitive/UPNumberUnit';
import { PConnectedFontName } from '../../ui/primitive/UPConnectedFontName';
import { PResourcePath } from '../../ui/primitive/UPResourcePath';
import { PString } from '../../ui/primitive/UPString';
import { PValueExpression } from '../../ui/primitive/UPValueExpession';

import { APDescriptor } from '../../ui/APDescriptor';

export const MHTileMapProperties: APDescriptor = {
    type: 'MHTileMapProperties', id: 'tileMapProperties',
    layouts: [new PAccordeon({
        id: "Chart", label: "Chart", path: "chartSettings.default", layouts: [new PAccordeon({
            id: "Borders and Plot Area", label: "Borders and Plot Area", layouts: [new PValueExpression({ id: 'chart.plotShadow', vtype: new PBoolean({ id: "chart.plotShadow", label: 'labelPlotShadow', description: 'descriptionPlotShadow', default: 'false', canBeNull: true }) }),
            new PValueExpression({ id: 'chart.plotBackgroundImage', vtype: new PResourcePath({ id: "chart.plotBackgroundImage", label: 'labelPlotBackgroundImage', description: 'descriptionPlotBackgroundImage' }) }),
            new PValueExpression({ id: 'chart.plotBackgroundColor', vtype: new PColor({ id: "chart.plotBackgroundColor", label: 'labelPlotBackgroundColor', description: 'descriptionPlotBackgroundColor', format: 'hex' }) }),
            new PValueExpression({ id: 'chart.plotBorderColor', vtype: new PColor({ id: "chart.plotBorderColor", label: 'labelPlotBorderColor', description: 'descriptionPlotBorderColor', format: 'hex', default: "#C0C0C0" }) }),
            new PValueExpression({ id: 'chart.plotBorderWidth', vtype: new PNumberUnit({ id: "chart.plotBorderWidth", label: 'labelPlotBorderWidth', description: 'descriptionPlotBorderWidth', default: "0", min: 0 }) }),
            new PValueExpression({ id: 'chart.borderColor', vtype: new PColor({ id: "chart.borderColor", label: 'labelBorderColor', description: 'descriptionBorderColor', format: 'hex', default: "#4572A7" }) }),
            new PValueExpression({ id: 'chart.borderRadius', vtype: new PNumberUnit({ id: "chart.borderRadius", label: 'labelBorderRadius', description: 'descriptionBorderRadius', default: "0", min: 0 }) }),
            new PValueExpression({ id: 'chart.borderWidth', vtype: new PNumberUnit({ id: "chart.borderWidth", label: 'labelBorderWidth', description: 'descriptionBorderWidth', default: "0", min: 0 }) })]
        }),
        new PAccordeon({
            id: "Margins and Spacing", label: "Margins and Spacing", layouts: [new PValueExpression({ id: 'chart.marginTop', vtype: new PNumberUnit({ id: "chart.marginTop", label: 'labelTopMargin', description: 'descriptionTopMargin' }) }),
            new PValueExpression({ id: 'chart.marginBottom', vtype: new PNumberUnit({ id: "chart.marginBottom", label: 'labelBottomMargin', description: 'descriptionBottomMargin' }) }),
            new PValueExpression({ id: 'chart.marginLeft', vtype: new PNumberUnit({ id: "chart.marginLeft", label: 'labelLeftMargin', description: 'descriptionLeftMargin' }) }),
            new PValueExpression({ id: 'chart.marginRight', vtype: new PNumberUnit({ id: "chart.marginRight", label: 'labelRightMargin', description: 'descriptionRightMargin' }) }),
            new PValueExpression({ id: 'chart.spacingTop', vtype: new PNumberUnit({ id: "chart.spacingTop", label: 'labelTopSpacing', description: 'descriptionTopSpacing', default: "10" }) }),
            new PValueExpression({ id: 'chart.spacingBottom', vtype: new PNumberUnit({ id: "chart.spacingBottom", label: 'labelBottomSpacing', description: 'descriptionBottomSpacing', default: "15" }) }),
            new PValueExpression({ id: 'chart.spacingLeft', vtype: new PNumberUnit({ id: "chart.spacingLeft", label: 'labelLeftSpacing', description: 'descriptionLeftSpacing', default: "10" }) }),
            new PValueExpression({ id: 'chart.spacingRight', vtype: new PNumberUnit({ id: "chart.spacingRight", label: 'labelRightSpacing', description: 'descriptionRightSpacing', default: "10" }) })]
        }),
        new PValueExpression({ id: 'chart.alignTicks', vtype: new PBoolean({ id: "chart.alignTicks", label: 'labelAlignTicks', description: 'descriptionAlignTick', default: 'true', canBeNull: true }) }),
        new PValueExpression({ id: 'chart.animation', vtype: new PBoolean({ id: "chart.animation", label: 'labelAnimation', description: 'descriptionAnimation', default: 'true', canBeNull: true }) }),
        new PValueExpression({ id: 'chart.backgroundColor', vtype: new PColor({ id: "chart.backgroundColor", label: 'labelBackgroundColor', description: 'descriptionBackgroundColor', format: 'hex', default: "#FFFFFF" }) }),
        new PValueExpression({ id: 'chart.selectionMarkerFill', vtype: new PColor({ id: "chart.selectionMarkerFill", label: 'labelSelectionMarkFillColor', description: 'descriptionSelectionMarkFillColor', format: 'hex', default: "#4572A7" }) }),
        new PValueExpression({ id: 'chart.shadow', vtype: new PBoolean({ id: "chart.shadow", label: 'labelDropShadow', description: 'descriptionDropShadow', default: 'false', canBeNull: true }) }),
        new PValueExpression({ id: 'chart.showAxes', vtype: new PBoolean({ id: "chart.showAxes", label: 'labelShowAxes', description: 'descriptionShowAxes', default: 'false', canBeNull: true }) }),
        new PValueExpression({ id: 'chart.width', vtype: new PNumberUnit({ id: "chart.width", label: 'labelChartWidth', description: 'descriptionChartWidth' }) }),
        new PValueExpression({
            id: 'chart.zoomType', vtype: new PEnum({
                id: "chart.zoomType", label: 'labelZoomType', description: 'descriptionZoomType', default: "none", items: [{ key: "none", value: "none" },
                { key: "x", value: "x" },
                { key: "y", value: "y" },
                { key: "xy", value: "xy" }]
            })
        }),
        new PValueExpression({ id: 'chart.ignoreHiddenSeries', vtype: new PBoolean({ id: "chart.ignoreHiddenSeries", label: 'labelIgnoreHiddenSeries', description: 'descriptionIgnoreHiddenSeries', default: 'true', canBeNull: true }) }),
        new PValueExpression({ id: 'chart.inverted', vtype: new PBoolean({ id: "chart.inverted", label: 'labelInvertAxes', description: 'descriptionInvertAxes', default: 'false', canBeNull: true }) }),
        new PValueExpression({ id: 'chart.reflow', vtype: new PBoolean({ id: "chart.reflow", label: 'labelReflowChart', description: 'descriptionReflowChart', default: 'true', canBeNull: true }) })]
    }),
    new PAccordeon({
        id: "Colors Palette", label: "Colors Palette", path: "chartSettings.default", layouts: [new PValueExpression({
            id: 'colors', vtype: new PConnectedTable({
                id: "colors", label: 'labelColorsPalette', description: 'descriptionColorsPalette', layouts: [new PTableElement({

                    anchorOrigin: { vertical: 'center', horizontal: 'right' },
                    transformOrigin: { vertical: 'center', horizontal: 'right' },
                    label: 'Color', layouts: [new PColor({ id: '', label: 'Color' })]

                })]

            })
        })]
    }),
    new PAccordeon({
        id: "Title", label: "Title", path: "chartSettings.default", layouts: [new PValueExpression({ id: 'title.text', vtype: new PString({ id: "title.text", label: 'labelTitleText', description: 'descriptionTitleText' }) }),
        new PValueExpression({ id: 'title.style.color', vtype: new PColor({ id: "title.style.color", label: 'labelTitleFontColor', description: 'descriptionTitleFontColor', format: 'hex', default: "#333333" }) }),
        new PValueExpression({ id: 'title.style.fontFamily', vtype: new PConnectedFontName({ id: "title.style.fontFamily", label: 'labelTitleFontFamily', description: 'descriptionTitleFontFamily' }) }),
        new PValueExpression({ id: 'title.style.fontSize', vtype: new PNumberUnit({ id: "title.style.fontSize", label: 'labelTitleFontSize', description: 'descriptionTitleFontSize', default: "18px" }) }),
        new PValueExpression({
            id: 'title.align', vtype: new PEnum({
                id: "title.align", label: 'labelTitleHorizontalAlignment', description: 'descriptionTitleHorizontalAlignment', default: "center", items: [{ key: "left", value: "left" },
                { key: "center", value: "center" },
                { key: "right", value: "right" }]
            })
        }),
        new PValueExpression({
            id: 'title.verticalAlign', vtype: new PEnum({
                id: "title.verticalAlign", label: 'labelTitleVerticalAlignment', description: 'descriptionTitleVerticalAlignment', default: "top", items: [{ key: "top", value: "top" },
                { key: "middle", value: "middle" },
                { key: "bottom", value: "bottom" }]
            })
        }),
        new PValueExpression({ id: 'title.x', vtype: new PNumberUnit({ id: "title.x", label: 'labelTitleXPosition', description: 'descriptionTitleXPosition', default: "0px" }) }),
        new PValueExpression({ id: 'title.y', vtype: new PNumberUnit({ id: "title.y", label: 'labelTitleYPosition', description: 'descriptionTitleYPosition', default: "15" }) }),
        new PValueExpression({ id: 'title.margin', vtype: new PNumberUnit({ id: "title.margin", label: 'labelTitleMargin', description: 'descriptionTitleMargin', default: "15" }) }),
        new PValueExpression({ id: 'title.floating', vtype: new PBoolean({ id: "title.floating", label: 'labelTitleFloatingTitle', description: 'descriptionTitleFloatingTitle', default: 'false', canBeNull: true }) })]
    }),
    new PAccordeon({
        id: "Subtitle", label: "Subtitle", path: "chartSettings.default", layouts: [new PValueExpression({ id: 'subtitle.text', vtype: new PString({ id: "subtitle.text", label: 'labelSubtitleText', description: 'descriptionSubtitleText' }) }),
        new PValueExpression({ id: 'subtitle.style.color', vtype: new PColor({ id: "subtitle.style.color", label: 'labelSubtitleFontColor', description: 'descriptionSubtitleFontColor', format: 'hex', default: "#555555" }) }),
        new PValueExpression({ id: 'subtitle.style.fontFamily', vtype: new PConnectedFontName({ id: "subtitle.style.fontFamily", label: 'labelSubtitleFontFamily', description: 'descriptionSubtitleFontFamily' }) }),
        new PValueExpression({ id: 'subtitle.style.fontSize', vtype: new PNumberUnit({ id: "subtitle.style.fontSize", label: 'labelSubtitleFontSize', description: 'descriptionSubtitleFontSize', default: "18px" }) }),
        new PValueExpression({
            id: 'subtitle.align', vtype: new PEnum({
                id: "subtitle.align", label: 'labelSubtitleHorizontalAlignment', description: 'descriptionSubtitleHorizontalAlignment', default: "center", items: [{ key: "left", value: "left" },
                { key: "center", value: "center" },
                { key: "right", value: "right" }]
            })
        }),
        new PValueExpression({
            id: 'subtitle.verticalAlign', vtype: new PEnum({
                id: "subtitle.verticalAlign", label: 'labelSubtitleVerticalAlignment', description: 'descriptionSubtitleVerticalAlignment', default: "top", items: [{ key: "top", value: "top" },
                { key: "middle", value: "middle" },
                { key: "bottom", value: "bottom" }]
            })
        }),
        new PValueExpression({ id: 'subtitle.x', vtype: new PNumberUnit({ id: "subtitle.x", label: 'labelSubtitleXPosition', description: 'descriptionSubtitleXPosition', default: "0" }) }),
        new PValueExpression({ id: 'subtitle.y', vtype: new PNumberUnit({ id: "subtitle.y", label: 'labelSubtitleYPosition', description: 'descriptionSubtitleYPosition' }) }),
        new PValueExpression({ id: 'subtitle.floating', vtype: new PBoolean({ id: "subtitle.floating", label: 'labelSubtitleFloatingTitle', description: 'descriptionSubtitleFloatingTitle', default: 'false', canBeNull: true }) })]
    }),
    new PAccordeon({
        id: "Plot Options for Series", label: "Plot Options for Series", path: "chartSettings.default", layouts: [new PAccordeon({
            id: "Styling", label: "Styling", layouts: [new PValueExpression({ id: 'plotOptions.series.color', vtype: new PColor({ id: "plotOptions.series.color", label: 'labelPlotOptionsMainColor', description: 'descriptionPlotOptionsMainColor', format: 'hex' }) }),
            new PValueExpression({ id: 'plotOptions.series.cropThreshold', vtype: new PInt({ id: "plotOptions.series.cropThreshold", label: 'labelPlotOptionCropTreshold', description: 'descriptionPlotOptionCropTreshold', default: 300 }) }),
            new PValueExpression({
                id: 'plotOptions.series.cursor', vtype: new PEnum({
                    id: "plotOptions.series.cursor", label: 'labelPlotOptionsCursor', description: 'descriptionPlotOptionsCursor', items: [{ key: "", value: "" },
                    { key: "alias", value: "alias" },
                    { key: "all-scroll", value: "all-scroll" },
                    { key: "auto", value: "auto" },
                    { key: "cell", value: "cell" },
                    { key: "col-resize", value: "col-resize" },
                    { key: "context-menu", value: "context-menu" },
                    { key: "copy", value: "copy" },
                    { key: "crosshair", value: "crosshair" },
                    { key: "default", value: "default" },
                    { key: "e-resize", value: "e-resize" },
                    { key: "ew-resize", value: "ew-resize" },
                    { key: "grab", value: "grab" },
                    { key: "grabbing", value: "grabbing" },
                    { key: "help", value: "help" },
                    { key: "move", value: "move" },
                    { key: "n-resize", value: "n-resize" },
                    { key: "ne-resize", value: "ne-resize" },
                    { key: "nesw-resize", value: "nesw-resize" },
                    { key: "no-drop", value: "no-drop" },
                    { key: "none", value: "none" },
                    { key: "not-allowed", value: "not-allowed" },
                    { key: "ns-resize", value: "ns-resize" },
                    { key: "nw-resize", value: "nw-resize" },
                    { key: "nwse-resize", value: "nwse-resize" },
                    { key: "pointer", value: "pointer" },
                    { key: "progress", value: "progress" },
                    { key: "row-resize", value: "row-resize" },
                    { key: "s-resize", value: "s-resize" },
                    { key: "se-resize", value: "se-resize" },
                    { key: "sw-resize", value: "sw-resize" },
                    { key: "text", value: "text" },
                    { key: "vertical-text", value: "vertical-text" },
                    { key: "w-resize", value: "w-resize" },
                    { key: "wait", value: "wait" },
                    { key: "zoom-in", value: "zoom-in" },
                    { key: "zoom-out", value: "zoom-out" }]
                })
            }),
            new PValueExpression({
                id: 'plotOptions.series.dashStyle', vtype: new PEnum({
                    id: "plotOptions.series.dashStyle", label: 'labelPlotOptionsDashStyle', description: 'descriptionPlotOptionsDashStyle', default: "Solid", items: [{ key: "Solid", value: "Solid" },
                    { key: "ShortDash", value: "ShortDash" },
                    { key: "ShortDot", value: "ShortDot" },
                    { key: "ShortDashDot", value: "ShortDashDot" },
                    { key: "ShortDashDotDot", value: "ShortDashDotDot" },
                    { key: "Dot", value: "Dot" },
                    { key: "Dash", value: "Dash" },
                    { key: "LongDash", value: "LongDash" },
                    { key: "DashDot", value: "DashDot" },
                    { key: "LongDashDot", value: "LongDashDot" },
                    { key: "LongDashDotDot", value: "LongDashDotDot" }]
                })
            }),
            new PValueExpression({ id: 'plotOptions.series.lineWidth', vtype: new PNumberUnit({ id: "plotOptions.series.lineWidth", label: 'labelPlotOptionsLineWidth', description: 'descriptionPlotOptionsLineWidth', default: "2" }) })]
        }),
        new PAccordeon({
            id: "Plot Options for Series", label: "Plot Options for Series", layouts: [new PValueExpression({ id: 'plotOptions.series.tooltip.dateTimeLabelFormats.millisecond', vtype: new PString({ id: "plotOptions.series.tooltip.dateTimeLabelFormats.millisecond", label: 'labelPlotOptionsMilliseconds', description: 'descriptionPlotOptionsMilliseconds', default: "%A, %b %e, %H:%M:%S.%L" }) }),
            new PValueExpression({ id: 'plotOptions.series.tooltip.dateTimeLabelFormats.second', vtype: new PString({ id: "plotOptions.series.tooltip.dateTimeLabelFormats.second", label: 'labelPlotOptionsSeconds', description: 'descriptionPlotOptionsSeconds', default: "%A, %b %e, %H:%M:%S" }) }),
            new PValueExpression({ id: 'plotOptions.series.tooltip.dateTimeLabelFormats.minute', vtype: new PString({ id: "plotOptions.series.tooltip.dateTimeLabelFormats.minute", label: 'labelPlotOptionsMinutes', description: 'descriptionPlotOptionsMinutes', default: "%A, %b %e, %H:%M" }) }),
            new PValueExpression({ id: 'plotOptions.series.tooltip.dateTimeLabelFormats.hour', vtype: new PString({ id: "plotOptions.series.tooltip.dateTimeLabelFormats.hour", label: 'labelPlotOptionsHours', description: 'descriptionPlotOptionsHours', default: "%A, %b %e, %H:%M" }) }),
            new PValueExpression({ id: 'plotOptions.series.tooltip.dateTimeLabelFormats.day', vtype: new PString({ id: "plotOptions.series.tooltip.dateTimeLabelFormats.day", label: 'labelPlotOptionsDays', description: 'descriptionPlotOptionsDays', default: "%A, %b %e, %Y" }) }),
            new PValueExpression({ id: 'plotOptions.series.tooltip.dateTimeLabelFormats.week', vtype: new PString({ id: "plotOptions.series.tooltip.dateTimeLabelFormats.week", label: 'labelPlotOptionsWeeks', description: 'descriptionPlotOptionsWeeks', default: "Week from %A, %b %e, %Y" }) }),
            new PValueExpression({ id: 'plotOptions.series.tooltip.dateTimeLabelFormats.month', vtype: new PString({ id: "plotOptions.series.tooltip.dateTimeLabelFormats.month", label: 'labelPlotOptionsMonths', description: 'descriptionPlotOptionsMonths', default: "%B %Y" }) }),
            new PValueExpression({ id: 'plotOptions.series.tooltip.dateTimeLabelFormats.year', vtype: new PString({ id: "plotOptions.series.tooltip.dateTimeLabelFormats.year", label: 'labelPlotOptionsYears', description: 'descriptionPlotOptionYears', default: "%Y" }) })]
        }),
        new PValueExpression({ id: 'plotOptions.series.visible', vtype: new PBoolean({ id: "plotOptions.series.visible", label: 'labelSeriesVisible', description: 'descriptionSeriesVisible', default: 'true', canBeNull: true }) }),
        new PValueExpression({ id: 'plotOptions.series.animation', vtype: new PBoolean({ id: "plotOptions.series.animation", label: 'labelSeriesShowAnimation', description: 'descriptionSeriesShowAnimation', default: 'true', canBeNull: true }) }),
        new PValueExpression({ id: 'plotOptions.series.showCheckBox', vtype: new PBoolean({ id: "plotOptions.series.showCheckBox", label: 'labelSeriesShowCheckbox', description: 'descriptionSeriesShowCheckbox', default: 'false', canBeNull: true }) }),
        new PValueExpression({ id: 'plotOptions.series.selected', vtype: new PBoolean({ id: "plotOptions.series.selected", label: 'labelSeriesSelected', description: 'descriptionSeriesSelected', default: 'false', canBeNull: true }) }),
        new PValueExpression({ id: 'plotOptions.series.shadow', vtype: new PBoolean({ id: "plotOptions.series.shadow", label: 'labelSeriesShowShadow', description: 'descriptionSeriesShowShadow', default: 'true', canBeNull: true }) }),
        new PValueExpression({ id: 'plotOptions.series.showInLegend', vtype: new PBoolean({ id: "plotOptions.series.showInLegend", label: 'labelSeriesShowInLegend', description: 'descriptionSeriesShowInLegend', default: 'true', canBeNull: true }) }),
        new PValueExpression({ id: 'plotOptions.series.allowPointSelect', vtype: new PBoolean({ id: "plotOptions.series.allowPointSelect", label: 'lebelSeriesAllPointSelection', description: 'descriptionSeriesAllPointSelection', default: 'false', canBeNull: true }) }),
        new PValueExpression({ id: 'plotOptions.series.connectNulls', vtype: new PBoolean({ id: "plotOptions.series.connectNulls", label: 'labelSeriesConnectNullPoints', description: 'descriptionSeriesConnectNullPoints', default: 'false', canBeNull: true }) })]
    }),
    new PAccordeon({
        id: "Legend", label: "Legend", path: "chartSettings.default", layouts: [new PAccordeon({
            id: "Sizes and positions", label: "Sizes and positions", layouts: [new PValueExpression({
                id: 'legend.align', vtype: new PEnum({
                    id: "legend.align", label: 'labelLegendHorizontalAlignment', description: 'descriptionLegendHorizontalAlignment', default: "center", items: [{ key: "left", value: "left" },
                    { key: "center", value: "center" },
                    { key: "right", value: "right" }]
                })
            }),
            new PValueExpression({
                id: 'legend.verticalAlign', vtype: new PEnum({
                    id: "legend.verticalAlign", label: 'labelLegendVerticalAlignment', description: 'descriptionLegendVerticalAlignment', default: "bottom", items: [{ key: "top", value: "top" },
                    { key: "middle", value: "middle" },
                    { key: "bottom", value: "bottom" }]
                })
            }),
            new PValueExpression({ id: 'legend.x', vtype: new PNumberUnit({ id: "legend.x", label: 'labelLegendXOffset', description: 'descriptionLegendXOffset', default: "0" }) }),
            new PValueExpression({ id: 'legend.y', vtype: new PNumberUnit({ id: "legend.y", label: 'labelLegendYOffset', description: 'descriptionLegendYOffset', default: "0" }) }),
            new PValueExpression({ id: 'legend.width', vtype: new PNumberUnit({ id: "legend.width", label: 'labelLegendWidth', description: 'descriptionLegendWidth', min: 0 }) }),
            new PValueExpression({ id: 'legend.maxHeight', vtype: new PNumberUnit({ id: "legend.maxHeight", label: 'labelLegendMaxHeight', description: 'descriptionLegendMaxHeight' }) }),
            new PValueExpression({ id: 'legend.margin', vtype: new PNumberUnit({ id: "legend.margin", label: 'labelLegendMargin', description: 'descriptionLegendMargin', default: "12" }) }),
            new PValueExpression({ id: 'legend.padding', vtype: new PNumberUnit({ id: "legend.padding", label: 'labelLegendPadding', description: 'descriptionLegendPadding', default: "8" }) })]
        }),
        new PAccordeon({
            id: "Items", label: "Items", layouts: [new PValueExpression({
                id: 'legend.layout', vtype: new PEnum({
                    id: "legend.layout", label: 'labelLegendItemsLayout', description: 'descriptionLegendItemsLayout', default: "horizontal", items: [{ key: "horizontal", value: "horizontal" },
                    { key: "vertical", value: "vertical" }]
                })
            }),
            new PValueExpression({ id: 'legend.itemMarginBottom', vtype: new PNumberUnit({ id: "legend.itemMarginBottom", label: 'labelLegendItemsBottomMargin', description: 'descriptionLegendItemsBottomMargin', default: "0" }) }),
            new PValueExpression({ id: 'legend.itemMarginTop', vtype: new PNumberUnit({ id: "legend.itemMarginTop", label: 'labelLegendItemsTopMargin', description: 'descriptionLegendItemsTopMargin', default: "0" }) }),
            new PValueExpression({ id: 'legend.itemWidth', vtype: new PNumberUnit({ id: "legend.itemWidth", label: 'labelLegendItemsWidth', description: 'descriptionLegendItemsWidth', min: 0 }) }),
            new PValueExpression({ id: 'legend.reversed', vtype: new PBoolean({ id: "legend.reversed", label: 'labelLegendItemsReverseOrder', description: 'descriptionLegendItemsReverseOrder', default: 'false', canBeNull: true }) })]
        }),
        new PAccordeon({
            id: "Symbols", label: "Symbols", layouts: [new PValueExpression({ id: 'legend.rtl', vtype: new PBoolean({ id: "legend.rtl", label: 'labelLegendShowSymbol', description: 'descriptionLegendShowSymbol', default: 'false', canBeNull: true }) }),
            new PValueExpression({ id: 'legend.symbolWidth', vtype: new PNumberUnit({ id: "legend.symbolWidth", label: 'labelLegendSymbolWidth', description: 'descriptionLegendSymbolWidth', default: "16", min: 0 }) }),
            new PValueExpression({ id: 'legend.symbolHeight', vtype: new PNumberUnit({ id: "legend.symbolHeight", label: 'labelLegendSymbolHeight', description: 'descriptionLegendSymbolHeight', min: 0 }) }),
            new PValueExpression({ id: 'legend.symbolPadding', vtype: new PNumberUnit({ id: "legend.symbolPadding", label: 'labelLegendSymbolPadding', description: 'descriptionLegendSymbolPadding', default: "5" }) }),
            new PValueExpression({ id: 'legend.symbolRadius', vtype: new PNumberUnit({ id: "legend.symbolRadius", label: 'labelLegendSymbolRadius', description: 'descriptionLegendSymbolRadius', default: "0", min: 0 }) })]
        }),
        new PValueExpression({ id: 'legend.enabled', vtype: new PBoolean({ id: "legend.enabled", label: 'labelShowLegend', description: 'descriptionShowLegend', default: 'true', canBeNull: true }) }),
        new PValueExpression({ id: 'legend.floating', vtype: new PBoolean({ id: "legend.floating", label: 'labelFloatingLegend', description: 'descriptionFloatingLegend', default: 'false', canBeNull: true }) }),
        new PValueExpression({ id: 'legend.backgroundColor', vtype: new PColor({ id: "legend.backgroundColor", label: 'labelLegendBackgroundColor', description: 'descriptionLegendBackgroundColor', format: 'hex' }) }),
        new PValueExpression({ id: 'legend.borderColor', vtype: new PColor({ id: "legend.borderColor", label: 'labelLegendBorderColor', description: 'descriptionLegendBorderColor', format: 'hex', default: "#909090" }) }),
        new PValueExpression({ id: 'legend.borderRadius', vtype: new PNumberUnit({ id: "legend.borderRadius", label: 'labelLegendBorderRadius', description: 'descriptionLegendBorderRadius', default: "0", min: 0 }) }),
        new PValueExpression({ id: 'legend.borderWidth', vtype: new PNumberUnit({ id: "legend.borderWidth", label: 'labelLegendBorderWidht', description: 'descriptionLegendBorderWidth', default: "0", min: 0 }) })]
    }),
    new PAccordeon({
        id: "Tooltip", label: "Tooltip", path: "chartSettings.default", layouts: [new PAccordeon({
            id: "Tooltip Formatting", label: "Tooltip Formatting", layouts: [new PValueExpression({ id: 'tooltip.valueDecimals', vtype: new PInt({ id: "tooltip.valueDecimals", label: 'labelTooltipValueDecimals', description: 'descriptionTooltipValueDecimals' }) }),
            new PValueExpression({ id: 'tooltip.valuePrefix', vtype: new PString({ id: "tooltip.valuePrefix", label: 'labelTooltipValuePrefix', description: 'descriptionTooltipValuePrefix' }) }),
            new PValueExpression({ id: 'tooltip.valueSuffix', vtype: new PString({ id: "tooltip.valueSuffix", label: 'labelTooltipValueSuffix', description: 'descriptionTooltipValueSuffix' }) }),
            new PValueExpression({ id: 'tooltip.xDateFormat', vtype: new PString({ id: "tooltip.xDateFormat", label: 'labelTooltipDateFormat', description: 'descriptionTooltipDateFormat' }) }),
            new PValueExpression({ id: 'tooltip.pointFormat', vtype: new PString({ id: "tooltip.pointFormat", label: 'labelTooltipPointFormat', description: 'descriptionTooltipPointFormat', default: "<span style=\"color:{point.color}\">●</span> {series.name}: <b>{point.y}</b><br/>" }) }),
            new PValueExpression({ id: 'tooltip.headerFormat', vtype: new PString({ id: "tooltip.headerFormat", label: 'labelTooltipHeaderFormat', description: 'descriptionTooltipHeaderFormat', default: "<span style=\"font-size: 10px\">{point.key}</span><br/>" }) }),
            new PValueExpression({ id: 'tooltip.footerFormat', vtype: new PString({ id: "tooltip.footerFormat", label: 'labelTooltipFooterFormat', description: 'descriptionTooltipFooterFormat' }) })]
        }),
        new PValueExpression({ id: 'tooltip.enabled', vtype: new PBoolean({ id: "tooltip.enabled", label: 'labelShowTooltip', description: 'descriptionShowTooltip', default: 'true', canBeNull: true }) }),
        new PValueExpression({ id: 'tooltip.animation', vtype: new PBoolean({ id: "tooltip.animation", label: 'labelShowTooltipAnimation', description: 'descriptionShowTooltipAnimation', default: 'true', canBeNull: true }) }),
        new PValueExpression({ id: 'tooltip.shadow', vtype: new PBoolean({ id: "tooltip.shadow", label: 'labelTooltipShowShadow', description: 'descriptionTooltipShowShadow', default: 'true', canBeNull: true }) }),
        new PValueExpression({ id: 'tooltip.shared', vtype: new PBoolean({ id: "tooltip.shared", label: 'labelUseSharedTooltip', description: 'descriptionUseSharedTooltip', default: 'false', canBeNull: true }) }),
        new PValueExpression({ id: 'tooltip.useHTML', vtype: new PBoolean({ id: "tooltip.useHTML", label: 'labelTooltipUseHTML', description: 'descriptionTooltipUseHTML', default: 'false', canBeNull: true }) }),
        new PValueExpression({ id: 'tooltip.backgroundColor', vtype: new PColor({ id: "tooltip.backgroundColor", label: 'labelTooltipBackgroundColor', description: 'descriptionTooltipBackgroundColor', format: 'hex', default: "#FFFFFF" }) }),
        new PValueExpression({ id: 'tooltip.borderColor', vtype: new PColor({ id: "tooltip.borderColor", label: 'labelTooltipBorderColor', description: 'descriptionTooltipBorderColor', format: 'hex' }) }),
        new PValueExpression({ id: 'tooltip.borderRadius', vtype: new PNumberUnit({ id: "tooltip.borderRadius", label: 'labelTooltipBorderRadius', description: 'descriptionTooltipBorderRadius', default: "3", min: 0 }) }),
        new PValueExpression({ id: 'tooltip.borderWidth', vtype: new PNumberUnit({ id: "tooltip.borderWidth", label: 'labelTooltipBorderWidth', description: 'descriptionTooltipBorderWidth', default: "1", min: 0 }) })]
    }),
    new PAccordeon({
        id: "Credits", label: "Credits", path: "chartSettings.default", layouts: [new PValueExpression({ id: 'credits.enabled', vtype: new PBoolean({ id: "credits.enabled", label: 'labelShowCredits', description: 'descriptionShowCredits', default: 'true', canBeNull: true }) }),
        new PValueExpression({ id: 'credits.text', vtype: new PString({ id: "credits.text", label: 'labelCredits', description: 'descriptionCredits', default: "Highcharts.com" }) }),
        new PValueExpression({ id: 'credits.href', vtype: new PString({ id: "credits.href", label: 'labelHyperlinkReference', description: 'descriptionHyperlinkReference', default: "http://www.highcharts.com" }) }),
        new PValueExpression({
            id: 'credits.position.align', vtype: new PEnum({
                id: "credits.position.align", label: 'labelHorizontalAlignment', description: 'descriptionHorizontalAlignment', default: "right", items: [{ key: "left", value: "left" },
                { key: "center", value: "center" },
                { key: "right", value: "right" }]
            })
        }),
        new PValueExpression({
            id: 'credits.position.verticalAlign', vtype: new PEnum({
                id: "credits.position.verticalAlign", label: 'labelVerticalAlignment', description: 'descriptionVerticalAlignment', default: "bottom", items: [{ key: "top", value: "top" },
                { key: "middle", value: "middle" },
                { key: "bottom", value: "bottom" }]
            })
        }),
        new PValueExpression({ id: 'credits.position.x', vtype: new PNumberUnit({ id: "credits.position.x", label: 'labelXPosition', description: 'descriptionXPosition', default: "-10" }) }),
        new PValueExpression({ id: 'credits.position.y', vtype: new PNumberUnit({ id: "credits.position.y", label: 'labelYPosition', description: 'descriptionYPosition', default: "-5" }) })]
    }),
    new PAccordeon({
        id: "Tilemap", label: "Tilemap", path: "chartSettings.default", layouts: [new PValueExpression({
            id: 'plotOptions.tilemap.tileShape', vtype: new PEnum({
                id: "plotOptions.tilemap.tileShape", label: 'tileShape', description: 'descriptionTileShape', default: "hexagon", items: [{ key: "hexagon", value: "Hexagon" },
                { key: "diamond", value: "Diamond" },
                { key: "circle", value: "Circle" },
                { key: "square", value: "Square" }]
            })
        }),
        new PValueExpression({ id: 'plotOptions.tilemap.colorByPoint', vtype: new PBoolean({ id: "plotOptions.tilemap.colorByPoint", label: 'colorByPoint', description: 'descriptionColorByPoint', default: 'false', canBeNull: true }) })]
    })]
}; 