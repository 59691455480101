/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { IRepositoryItemDescriptor, RepositoryApi } from '@jss/js-rest-api';
import { RunContext } from '../../RunContainer';
import DeleteIcon from '@material-ui/icons/Delete';
import { UxType } from '@jss/js-common';

import Button from '@material-ui/core/Button';
import { ConfirmDialog } from '../../jobs/ConfirmDialog';
import i18n from '../../i18n';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { MenuItem } from '@material-ui/core';

export interface IResourceDeleteProps {
    type?: UxType;
    size?: 'small' | 'medium' | 'large';
    disabled?: boolean;
    selection: IRepositoryItemDescriptor[];
    onDeleted: () => void;
    onClose?: () => void
}

export class ResourceDelete extends React.Component<IResourceDeleteProps> {
    static defaultProps = {
        type: UxType.BUTTON,
        size: 'large',
        disabled: false
    }

    render() { return <>{this.renderButtons()}</>; }

    private renderButtons() {
        const d = this.props.selection.length > 0 && this.props.disabled;
        // setup disabled look at permissions

        switch (this.props.type) {
            case UxType.BUTTON: return <Button onClick={this.showDialog} disabled={d} size={this.props.size}>{i18n.t('common.actions.menu.delete')}</Button>;
            case UxType.MENU: return <MenuItem disabled={d} style={{ height: 30 }} onClick={this.showDialog} key='resource.delete'>
                <ListItemIcon>
                    <DeleteIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />
                </ListItemIcon>
                <Typography variant="inherit">{i18n.t('common.actions.menu.delete')}</Typography>
            </MenuItem>;
        }
    }

    private showDialog = async () => {
        this.props.onClose?.();
        try {
            let r = await this.context.showDialog(ConfirmDialog, { title: i18n.t('repository.delete.confirmation.title'), message: i18n.t('repository.delete.confirmation.text') });
            if (!r.canceled) {
                const d = this.props.selection[0];
                r = await this.context.runNoCancel(RepositoryApi.inst().delete)("uuid:" + d.uuid);
                if (!r.error) {
                    this.props.onDeleted();
                }
            }
        } catch (e) {
            console.log(e);
        } finally { }
    }
}
ResourceDelete.contextType = RunContext;