/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PButtonsEnum } from "../../ui/primitive/UPButtonsEnum";

export const VerticalTextAlignEnum = new PButtonsEnum({
    id: 'verticalAlignment', label: 'VerticalTextAlign.label', inheritedPropertyId: 'verticalAlignment',
    items: [
        { key: 'Top', value: 'VerticalTextAlign.Top', icon: 'textATop' },
        { key: 'Middle', value: 'VerticalTextAlign.Middle', icon: 'textAMiddle' },
        { key: 'Bottom', value: 'VerticalTextAlign.Bottom', icon: 'textABottom' },
        { key: 'Justified', value: 'VerticalTextAlign.Justify', icon: 'textAStretch'}
    ]
}); 
 
