/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IConfigStore } from "./IConfigStore";
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ErrorDescriptor } from '../ErrorDescriptor';

export class UiServerConfigStore implements IConfigStore {
	public init(cfg: any, fireRefresh?: () => void) {
		axios.get('/config', { headers: { 'Cache-Control': 'no-cache' } })
			.then((response: AxiosResponse) => {
				// console.log(response.data);
				Object.assign(cfg, response.data);
				fireRefresh?.();
				axios.get(`${cfg['jrws.url.rest.jrio']}/rest_v2/serverInfo`, { headers: { 'Cache-Control': 'no-cache' } })
					.then((r: AxiosResponse) => {
						cfg['jrws.jrio.serverInfo'] = r.data;
					}).catch((e: AxiosError) => {
						console.log(new ErrorDescriptor(e).getMessage());
					});
				sessionStorage.setItem('jrws.config', JSON.stringify(cfg));
			}).catch((error: AxiosError) => {
				console.log(new ErrorDescriptor(error).getMessage());
			});
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public set(key: string, value: any): boolean {
		// there is no implementation yet
		return false;
	}
}