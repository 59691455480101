/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { fromJS, List, Map } from 'immutable';
import { call, put, select, spawn } from 'redux-saga/effects';

import { IState } from '../../../../reducers';

import { IPoint, } from '../../../../types/geometry';
import * as DocumentUtils from '../documentUtils';

import * as ReportActions from '../../../../actions/reportActions';
import { ElementTypes } from '../../document/elementTypes';
import { getEditorIndex } from '../../../../reducers/report/reportReducer';



const RESIZE_ELEMENT_PREFIX = "dragelement-";

/**
 * This function manage the dragging of elements, produces special effects and conclude the final
 * operation on end...
 * The handle will process 3 possible states:
 * 1. drag not yet started
 * 2. drag in progress (dragStarted === true && commit === false)
 * 3. drag finished (commit === false)m it happens for instance on mouseUp event
 * 
 */
export function* handleDrag(point: IPoint, options: any, commit = false) {

  const state = yield select((s: IState) => s);
  const positionFinder = DocumentUtils.createObjectAbsolutePositionFinder(state);
  // 1. Let's see if this was the first move...
  const currentEditorIndex = getEditorIndex(state.get('report'));
  let mouse;
  if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
    const currentEditor = state.getIn(['report', 'subeditors', currentEditorIndex]);
    mouse = currentEditor.get('mouse');
  } else {
    mouse = state.getIn(['report','mouse']);
  }

  const model = state.getIn(['report', 'model']);
  if (mouse.get('operation') !== ReportActions.DragOperations.DRAG_OP_ELEMENT ||
    model === null) {
    return; // Nothing to do...
  }

  const currentLocation = point;
  const startLocation = mouse.get('startLocation').toJS();
  let dragStarted = mouse.get('dragStarted'); // This is a primitive, no need to convert.

  // Let's create the shadow figure that represents the moved elements...
  // This figure will be as big as all the selected elements, and will have shadow figure of
  // each of them...
  const selectedElements = DocumentUtils.getDraggableElements(state);

  if (selectedElements.count() === 0) {
    return; // Nothing to do with 0 elements selected... it should never happen...
  }

  const pointDelta = {
    x: point.x - startLocation.x,
    y: point.y - startLocation.y
  };

  if (!dragStarted) {

    // We need to initialize the drag. We will do it after some lag/treaschold.
    // If the drag can start, we will initialize our phantom shape that will
    // be added to the list of intaraction elements.
    // Let's check if we are ready to start (3px lag required...)
    if (Math.abs(pointDelta.x) > 3 || Math.abs(pointDelta.y) > 3) {
      dragStarted = true;

      let figureElements = selectedElements.map((ele: Map<string, any>) => DocumentUtils.createInteractionFigure(ele, RESIZE_ELEMENT_PREFIX + ele.get('id'), true, positionFinder)).toList();
      // Let's create some interaction figures, one for each element on which we operate...
      figureElements = moveElements(
        figureElements,
        pointDelta);

      // Now we have a set of figures, let's add them to the state...
      yield put(ReportActions.updateDragOperation(fromJS(currentLocation), dragStarted, mouse.get("operationState")));
      yield put(ReportActions.addInteractionFigures(figureElements));

      if (haveSameProperty(selectedElements.toMap(), ['path'])) {
        const path = DocumentUtils.findContainerAt(currentLocation, positionFinder);
        yield put(ReportActions.highilightContainer(path === (selectedElements.first() as Map<string, any>).get('path') ? null : path));
      }
    }
  }
  else { // Keep dragging

    // let figureElements = getState().getIn(['report','interactionElements']).filter( (ele: Map<string, any>, key: string) => key.startsWith(RESIZE_ELEMENT_PREFIX) ).toList();

    // We recreate the figures. After all the structure is very symple and names will be reused...
    const figureElements = selectedElements.map((ele: Map<string, any>) => DocumentUtils.createInteractionFigure(ele, RESIZE_ELEMENT_PREFIX + ele.get('id'), true, positionFinder)).toList();
    // Let's update our interaction figures
    let updatedfigureElements = moveElements(
      figureElements,
      pointDelta);

    if (!commit) {
      yield put(ReportActions.updateDragOperation(fromJS(currentLocation), true, mouse.get("operationState")));
      yield put(ReportActions.updateInteractionFigures(updatedfigureElements));

      // Recalculate client area in response to element modifications
      yield call(DocumentUtils.updateClientArea, positionFinder);

      if (haveSameProperty(selectedElements.toMap(), ['path'])) {
        const path = DocumentUtils.findContainerAt(currentLocation, positionFinder);
        yield put(ReportActions.highilightContainer(path === (selectedElements.first() as Map<string, any>).get('path') ? null : path));
      }

    }
    else {

      for (let i = 0; i < updatedfigureElements.count(); ++i) {
        updatedfigureElements = updatedfigureElements.update(i, (ele: Map<string, any>) => {
          return ele.updateIn(['props', 'className'], (val: string) => val + ' fadeOut')
            .set('transient', false);
        });
      }

      const figureElementIds = updatedfigureElements.reduce((arr: string[], coll: Map<string, any>) => { arr.push(coll.get('id')); return arr; }, []);
      const elementPaths = selectedElements.reduce((arr: string[], o: Map<string, any>) => { arr.push('elements/' + o.get('id')); return arr; }, []);

      yield put(ReportActions.updateInteractionFigures(updatedfigureElements));

      // The container delta is used to move elements from a container to another.
      // If the container is not changed, it will be 0,0 otherwise it will be the difference
      // between the old container and the new container coordinates.
      const containerDelta = { x: 0, y: 0 };
      let newContainerPath = null; // If the container changes, this var contains the new container path..

      // 1. Let's calculate the position changes based on the new container, if ant...
      if (haveSameProperty(selectedElements.toMap(), ['path'])) {
        // Path of the new container
        const newPath = DocumentUtils.findContainerAt(currentLocation, positionFinder);
        // Path of the old container
        const oldPath = (selectedElements.first() as Map<string, any>).get('path');
        // If the container is actually changed, and it is not one of the elements theirself,
        // let's see where we need to move the element...
        if (newPath && oldPath !== newPath && elementPaths.indexOf(newPath) < 0) {
          // The container is changed, so we need to adjust the coordinates also considering
          // the change of container...
          const originalContainerPosition = positionFinder.findPosition(state.getIn(['report', 'model', ...oldPath.split('/')]));
          const newContainerPosition = positionFinder.findPosition(state.getIn(['report', 'model', ...newPath.split('/')]));
          containerDelta.x = originalContainerPosition.x - newContainerPosition.x;
          containerDelta.y = originalContainerPosition.y - newContainerPosition.y;
          newContainerPath = newPath;
        }
      }

      if (newContainerPath) {
        pointDelta.x += containerDelta.x;
        pointDelta.y += containerDelta.y;
      }

      // Let's store the changes in a suitable structure...
      const elementChanges = [];
      selectedElements.forEach((ele) => {
        const elementType = ele.get('type');
        if (elementType === ElementTypes.BREAK){
          //break elements can never change the x value
          elementChanges.push({
            id: ele.get('id'),
            x: 0,
            y: ele.get('y') + pointDelta.y,
            width: ele.get('width'),
            height: ele.get('height')
          });
        } else {
          elementChanges.push({
            id: ele.get('id'),
            x: ele.get('x') + pointDelta.x,
            y: ele.get('y') + pointDelta.y,
            width: ele.get('width'),
            height: ele.get('height')
          });
        }
      });

      if (elementChanges.length > 0) {
        yield put(ReportActions.updateElementsSizeAndPosition(elementChanges));
        if (newContainerPath) {
          yield put(ReportActions.updateElementsContainer(selectedElements.keySeq().toJS(), newContainerPath));
        }
      }

      yield put(ReportActions.highilightContainer(null));

      // Recalculate the client area and spawn the detached process to clean up the figure
      // Saga will not wait for removeInteractionFigureAfterFade.
      yield spawn(DocumentUtils.removeInteractionFiguresAfterAnimation, figureElementIds);
      yield call(DocumentUtils.updateClientArea, positionFinder);
    }
  }

}



/**
 * Resize a set of elements by considering the delta and the direction.
 * 
 * It returns the real delta applied, since it may be constrained by the current size of the elements.
 * 
 * @param ele 
 * @param resizeDirection 
 * @param pointDelta 
 */
const moveElements = (elements: List<any>, pointDelta: IPoint): List<Map<string, any>> => {

  for (let i = 0; i < elements.count(); ++i) {
    elements = elements.update(i, (ele: Map<string, any>) => {
      return ele.update('x', (val: number) => val + pointDelta.x)
        .update('y', (val: number) => val + pointDelta.y)
        .updateIn(['props', 'style', 'left'], (val: number) => val + pointDelta.x)
        .updateIn(['props', 'style', 'top'], (val: number) => val + pointDelta.y);
    });
  }

  return elements;
}


const haveSameProperty = (elements: Map<string, any>, propertypath: string[]) => {

  // Check first if all the elements comes from the same container...
  if (elements.count() === 0) {
    return true;
  }

  const path = elements.first().getIn(propertypath);

  let foundDifferent = false;
  elements.forEach((ele: Map<string, any>) => {
    if (ele.getIn(propertypath) !== path) {
      foundDifferent = true;
      return false;
    }
    return true;
  });

  return !foundDifferent;
}
