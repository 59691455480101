/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../ui/APDescriptor";
import { PDiv } from "../../ui/composite/UPDiv";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PEnum } from "../../ui/primitive/UPEnum";
import { PString } from "../../ui/primitive/UPString";
import { PValueExpression } from "../../ui/primitive/UPValueExpession";


const FMapMarker = {
    enable: new PValueExpression({ id: 'enable', vtype: new PBoolean({ id: "enable", label: 'Enable', description: 'enable', canBeNull: true }) }),
    label: new PValueExpression({ id: 'value', vtype: new PString({ id: "label", label: 'Label', description: 'label', canBeNull: true }) }),
    position: new PValueExpression({
        id: 'position', vtype: new PEnum({
            id: "position", label: 'Position', description: 'Position', items: [
            { key: "", value: "" },
            { key: "TOP_CENTER", value: "Top Center" },
            { key: "TOP_LEFT", value: "Top Left" },
            { key: "TOP_RIGHT", value: "Top Right" },
            { key: "LEFT_TOP", value: "Left Top"},
            { key: "LEFT_CENTER", value: "Left Center" },
            { key: "LEFT_BOTTOM", value: "Left Bottom" },
            { key: "RIGHT_TOP", value: "Right Top" },
            { key: "RIGHT_CENTER", value: "Right Center"},
            { key: "RIGHT_BOTTOM", value: "Right Bottom" },
            { key: "BOTTOM_LEFT", value: "Bottom Left" },
            { key: "BOTTOM_CENTER", value: "Bottom Center" },
            { key: "BOTTOM_RIGHT", value: "Bottom Right"},
        ] })
    }),
    orientation: new PValueExpression({
        id: 'orientation', vtype: new PEnum({
            id: "orientation", label: 'Orientation.label', description: 'Orientation', items: [
            { key: "", value: "" },
            { key: "vertical", value: "Vertical" },
            { key: "horizontal", value: "Horizontal" }]
        })
    }),
    legendmaxwidth: new PValueExpression({ id: 'legendMaxWidth', vtype: new PString({ id: "legendMaxWidth", label: 'Legend Max Width', description: 'Legend Max Width', canBeNull: true }) }),
    legendmaxwidthfullscreen: new PValueExpression({ id: 'legendMaxWidth.fullscreen', vtype: new PString({ id: "legendMaxWidth.fullscreen", label: 'Legend Max Width Fullscreen', description: 'Legend Max Width Fullscreen', canBeNull: true }) }),
    legendmaxheight: new PValueExpression({ id: 'legendMaxHeight', vtype: new PString({ id: "legendMaxHeight", label: 'Legend Max Height', description: 'Legend Max Height', canBeNull: true }) }),
    legendmaxhegithfullscreen: new PValueExpression({ id: 'legendMaxHeight.fullscreen', vtype: new PString({ id: "legendMaxHeight.fullscreen", label: 'Legend Max Height Fullscreen', description: 'Legend Max Height Fullscreen', canBeNull: true }) }),
    useMarkerIcons: new PValueExpression({ id: 'useMarkerIcons', vtype: new PBoolean({ id: "useMarkerIcons", label: 'Use Marker Icons', description: 'Use Marker Icons', canBeNull: true }) }),

};

export const MMapLegend: APDescriptor = new PDiv({
    label: 'Legend Item', 
    id: 'legendItems',
    path: 'legendItems',
    layouts: [
        FMapMarker.enable,
        FMapMarker.label,
        FMapMarker.position,
        FMapMarker.orientation,
        FMapMarker.legendmaxwidth,
        FMapMarker.legendmaxwidthfullscreen,
        FMapMarker.legendmaxheight,
        FMapMarker.legendmaxhegithfullscreen,
        FMapMarker.useMarkerIcons
    ],
});

export const MMapReset: APDescriptor = new PDiv({
    label: 'Reset Item', 
    id: 'resetMapItems',
    path: 'resetMapItems',
    layouts: [
        FMapMarker.enable,
        FMapMarker.label,
        FMapMarker.position,
    ],
});