/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import Popover, { PopoverOrigin } from '@material-ui/core/Popover';
import ReactResizeDetector from 'react-resize-detector';

interface IPopoverButtonProps {
  id: string;
  isOpen?: boolean;
  anchorOrigin?: PopoverOrigin,
  transformOrigin?: PopoverOrigin,
  anchorRef?: React.MutableRefObject<Element>,
  keepInitialSize?: boolean,
  onClose?: () => void;
  onVisibleChange?: (visible: boolean) => void;
  getContent: (handleClose: () => void) => React.ReactNode;
  getControl: (handleClick: (event: any) => void, handleClose: () => void) => React.ReactNode;
}

interface IState {
  anchorEl: null | EventTarget,
  width: number | undefined,
  height: number | undefined,
}

export class PopoverControl extends React.Component<IPopoverButtonProps, IState> {

  state = {
    anchorEl: null,
    width: undefined,
    height: undefined,
  }

  componentDidUpdate(prevProps: Readonly<IPopoverButtonProps>, prevState: Readonly<IState>): void {
    if (prevState.anchorEl !== this.state.anchorEl && this.props.onVisibleChange){
      this.props.onVisibleChange(this.state.anchorEl !== null);
    }
  }

  private setAnchorEl = (newAnchorEl: null | EventTarget) => {
    this.setState({
      anchorEl: newAnchorEl,
    });
  }

  private clearAnchorEl = () => {
    this.setState({
      anchorEl: null,
      width: undefined,
      height: undefined,
    });
  }

  private onResize = (width: number | undefined, height: number | undefined) => {
    if (this.state.width === undefined || this.state.height === undefined) {
      this.setState({ width: width, height: height });
    }
  }

  getContent = (handleClose) => {
    if (this.props.keepInitialSize) {
      return <ReactResizeDetector handleWidth={true} handleHeight={true} onResize={this.onResize}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: this.state.height,
          flex: 1,
        }}>
          {this.props.getContent(handleClose)}
        </div>
      </ReactResizeDetector>
    } else {
      return this.props.getContent(handleClose);
    }
  }

  public render() {

    const handleClick = (event) => {
      this.setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      if (this.props.onClose) {
        this.props.onClose();
      }
      this.clearAnchorEl();
    };

    const open = Boolean(this.state.anchorEl);
    const id = open ? this.props.id : undefined;
    return (
      <>
        {this.props.getControl(handleClick, handleClose)}
        <Popover
          id={id}
          open={this.props.isOpen !== undefined ? this.props.isOpen : open}
          anchorEl={this.props.anchorRef && this.props.anchorRef.current ? this.props.anchorRef.current : this.state.anchorEl}
          onClose={handleClose}
          disableRestoreFocus
          anchorOrigin={this.props.anchorOrigin ? this.props.anchorOrigin : {
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={this.props.transformOrigin ? this.props.transformOrigin : {
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {this.getContent(handleClose)}
        </Popover>
      </>
    );
  }

}
