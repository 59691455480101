export const en = {
	about: "About JasperReports Web Studio",
	materialUi: {
		component: {
			collapsiblePanel: {
				close: "Close",
				open: "Open"
			}
		}
	},
	jrws: {
		header: {
			betaicon: {
				tooltip: "This is a beta version and should be used with care"
			}
		}
	},
	common: {
		actions: {
			menu: {
				save: "Save",
				saveas: "Save As...",
				resourceinfo: "Resource Information",
				upload: "Upload",
				download: "Download",
				exit: "Exit",
				undo: "Undo",
				redo: "Redo",
				undoAll: "Undo All",
				zoomin: "Zoom In",
				zoomout: "Zoom Out",
				zoomlevel: "Zoom Options",
				delete: "Delete",
				help: "Help"
			},
			button: {
				ok: "Ok",
				save: "Save",
				yes: "Yes",
				no: "No",
				cancel: "Cancel"
			},
			add: "Add"
		},
		editabletable: {
			add: "Add",
			remove: "Remove"
		},
		button: {
			cancel: "Cancel",
			close: "Close",
			ok: "Ok",
			exitEditor: "Exit Editor",
			yes: "Yes",
			no: "No",
		},
		dialog: {
			exitWithoutSave: "Are you sure you want to exit the editor without saving? All your changes will be lost.",
			fileSizeError: "There were error uploading the following files:\n\n",
			newResourceTitle: 'Enter the new resource name',
		}
	},
	validators: {
		httperror: "The value must be a valid URL starting with http:// or https://",
		folderNameInvalid: "The folder name contains invalid charachters",
		folderNameEmpty: "The folder name can't be empty.",
		fieldNameEmpty: "The value can\'t be empty",
	}
}