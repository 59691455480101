/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const BorderSplitTypeEnum = new PEnum({
    id: 'borderSplitType', label: 'BorderSplittype.label', description: 'BorderSplittype.description',
    items: [
        { key: 'NoBorders', value: 'BorderSplittype.NoBorders' },
        { key: 'DrawBorders', value: 'BorderSplittype.DrawBorders' }
    ]
});

