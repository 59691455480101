/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import '../assets/uxpl/css/Dialog.css';


export interface IResizer {
    updateStateResizing: (value: boolean, event: React.MouseEvent<HTMLElement>) => void;
}
  

export class Resizer extends React.Component<IResizer> {
  
  public render() {
    const style = {
      width: 13,
      height: 13
    };
    return (
      <div
        className="tc-jsw-modal-resizer"
        style={style}
        onMouseDown={this.onMouseDown}
      />
    );
  }

  private onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    this.props.updateStateResizing(true, event);
  }
}
