/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

export const LOCALES = [
    { key: "", value: "" },
    { key: "af", value: "Afrikaans" },
    { key: "af_NA", value: "Afrikaans (Namibia)" },
    { key: "af_ZA", value: "Afrikaans (South Africa)" },
    { key: "agq", value: "Aghem" },
    { key: "agq_CM", value: "Aghem (Cameroon)" },
    { key: "ak", value: "Akan" },
    { key: "ak_GH", value: "Akan (Ghana)" },
    { key: "sq", value: "Albanian" },
    { key: "sq_AL", value: "Albanian (Albania)" },
    { key: "sq_XK", value: "Albanian (Kosovo)" },
    { key: "sq_MK", value: "Albanian (Macedonia)" },
    { key: "am", value: "Amharic" },
    { key: "am_ET", value: "Amharic (Ethiopia)" },
    { key: "ar", value: "Arabic" },
    { key: "ar_DZ", value: "Arabic (Algeria)" },
    { key: "ar_BH", value: "Arabic (Bahrain)" },
    { key: "ar_TD", value: "Arabic (Chad)" },
    { key: "ar_KM", value: "Arabic (Comoros)" },
    { key: "ar_DJ", value: "Arabic (Djibouti)" },
    { key: "ar_EG", value: "Arabic (Egypt)" },
    { key: "ar_ER", value: "Arabic (Eritrea)" },
    { key: "ar_IQ", value: "Arabic (Iraq)" },
    { key: "ar_IL", value: "Arabic (Israel)" },
    { key: "ar_JO", value: "Arabic (Jordan)" },
    { key: "ar_KW", value: "Arabic (Kuwait)" },
    { key: "ar_LB", value: "Arabic (Lebanon)" },
    { key: "ar_LY", value: "Arabic (Libya)" },
    { key: "ar_MR", value: "Arabic (Mauritania)" },
    { key: "ar_MA", value: "Arabic (Morocco)" },
    { key: "ar_OM", value: "Arabic (Oman)" },
    { key: "ar_PS", value: "Arabic (Palestinian Territories)" },
    { key: "ar_QA", value: "Arabic (Qatar)" },
    { key: "ar_SA", value: "Arabic (Saudi Arabia)" },
    { key: "ar_SO", value: "Arabic (Somalia)" },
    { key: "ar_SS", value: "Arabic (South Sudan)" },
    { key: "ar_SD", value: "Arabic (Sudan)" },
    { key: "ar_SY", value: "Arabic (Syria)" },
    { key: "ar_TN", value: "Arabic (Tunisia)" },
    { key: "ar_AE", value: "Arabic (United Arab Emirates)" },
    { key: "ar_EH", value: "Arabic (Western Sahara)" },
    { key: "ar_001", value: "Arabic (World)" },
    { key: "ar_YE", value: "Arabic (Yemen)" },
    { key: "hy", value: "Armenian" },
    { key: "hy_AM", value: "Armenian (Armenia)" },
    { key: "as", value: "Assamese" },
    { key: "as_IN", value: "Assamese (India)" },
    { key: "ast", value: "Asturian" },
    { key: "ast_ES", value: "Asturian (Spain)" },
    { key: "asa", value: "Asu" },
    { key: "asa_TZ", value: "Asu (Tanzania)" },
    { key: "az", value: "Azerbaijani" },
    { key: "az__#Cyrl", value: "Azerbaijani (Cyrillic)" },
    { key: "az_AZ_#Cyrl", value: "Azerbaijani (Cyrillic, Azerbaijan)" },
    { key: "az__#Latn", value: "Azerbaijani (Latin)" },
    { key: "az_AZ_#Latn", value: "Azerbaijani (Latin, Azerbaijan)" },
    { key: "ksf", value: "Bafia" },
    { key: "ksf_CM", value: "Bafia (Cameroon)" },
    { key: "bm", value: "Bambara" },
    { key: "bm_ML", value: "Bambara (Mali)" },
    { key: "bn", value: "Bangla" },
    { key: "bn_BD", value: "Bangla (Bangladesh)" },
    { key: "bn_IN", value: "Bangla (India)" },
    { key: "bas", value: "Basaa" },
    { key: "bas_CM", value: "Basaa (Cameroon)" },
    { key: "eu", value: "Basque" },
    { key: "eu_ES", value: "Basque (Spain)" },
    { key: "be", value: "Belarusian" },
    { key: "be_BY", value: "Belarusian (Belarus)" },
    { key: "bem", value: "Bemba" },
    { key: "bem_ZM", value: "Bemba (Zambia)" },
    { key: "bez", value: "Bena" },
    { key: "bez_TZ", value: "Bena (Tanzania)" },
    { key: "brx", value: "Bodo" },
    { key: "brx_IN", value: "Bodo (India)" },
    { key: "bs", value: "Bosnian" },
    { key: "bs__#Cyrl", value: "Bosnian (Cyrillic)" },
    { key: "bs_BA_#Cyrl", value: "Bosnian (Cyrillic, Bosnia & Herzegovina)" },
    { key: "bs__#Latn", value: "Bosnian (Latin)" },
    { key: "bs_BA_#Latn", value: "Bosnian (Latin, Bosnia & Herzegovina)" },
    { key: "br", value: "Breton" },
    { key: "br_FR", value: "Breton (France)" },
    { key: "bg", value: "Bulgarian" },
    { key: "bg_BG", value: "Bulgarian (Bulgaria)" },
    { key: "my", value: "Burmese" },
    { key: "my_MM", value: "Burmese (Myanmar (Burma))" },
    { key: "yue", value: "Cantonese" },
    { key: "yue__#Hans", value: "Cantonese (Simplified)" },
    { key: "yue_CN_#Hans", value: "Cantonese (Simplified, China)" },
    { key: "yue__#Hant", value: "Cantonese (Traditional)" },
    { key: "yue_HK_#Hant", value: "Cantonese (Traditional, Hong Kong SAR China)" },
    { key: "ca", value: "Catalan" },
    { key: "ca_AD", value: "Catalan (Andorra)" },
    { key: "ca_FR", value: "Catalan (France)" },
    { key: "ca_IT", value: "Catalan (Italy)" },
    { key: "ca_ES", value: "Catalan (Spain)" },
    { key: "ca_ES_VALENCIA", value: "Catalan (Spain, Valencian)" },
    { key: "tzm", value: "Central Atlas Tamazight" },
    { key: "tzm_MA", value: "Central Atlas Tamazight (Morocco)" },
    { key: "ckb", value: "Central Kurdish" },
    { key: "ckb_IR", value: "Central Kurdish (Iran)" },
    { key: "ckb_IQ", value: "Central Kurdish (Iraq)" },
    { key: "ccp", value: "Chakma" },
    { key: "ccp_BD", value: "Chakma (Bangladesh)" },
    { key: "ccp_IN", value: "Chakma (India)" },
    { key: "ce", value: "Chechen" },
    { key: "ce_RU", value: "Chechen (Russia)" },
    { key: "chr", value: "Cherokee" },
    { key: "chr_US", value: "Cherokee (United States)" },
    { key: "cgg", value: "Chiga" },
    { key: "cgg_UG", value: "Chiga (Uganda)" },
    { key: "zh", value: "Chinese" },
    { key: "zh_CN", value: "Chinese (China)" },
    { key: "zh_HK", value: "Chinese (Hong Kong SAR China)" },
    { key: "zh__#Hans", value: "Chinese (Simplified)" },
    { key: "zh_CN_#Hans", value: "Chinese (Simplified, China)" },
    { key: "zh_HK_#Hans", value: "Chinese (Simplified, Hong Kong SAR China)" },
    { key: "zh_MO_#Hans", value: "Chinese (Simplified, Macau SAR China)" },
    { key: "zh_SG_#Hans", value: "Chinese (Simplified, Singapore)" },
    { key: "zh_SG", value: "Chinese (Singapore)" },
    { key: "zh_TW", value: "Chinese (Taiwan)" },
    { key: "zh__#Hant", value: "Chinese (Traditional)" },
    { key: "zh_HK_#Hant", value: "Chinese (Traditional, Hong Kong SAR China)" },
    { key: "zh_MO_#Hant", value: "Chinese (Traditional, Macau SAR China)" },
    { key: "zh_TW_#Hant", value: "Chinese (Traditional, Taiwan)" },
    { key: "cu", value: "Church Slavic" },
    { key: "cu_RU", value: "Church Slavic (Russia)" },
    { key: "ksh", value: "Colognian" },
    { key: "ksh_DE", value: "Colognian (Germany)" },
    { key: "kw", value: "Cornish" },
    { key: "kw_GB", value: "Cornish (United Kingdom)" },
    { key: "hr", value: "Croatian" },
    { key: "hr_BA", value: "Croatian (Bosnia & Herzegovina)" },
    { key: "hr_HR", value: "Croatian (Croatia)" },
    { key: "cs", value: "Czech" },
    { key: "cs_CZ", value: "Czech (Czechia)" },
    { key: "da", value: "Danish" },
    { key: "da_DK", value: "Danish (Denmark)" },
    { key: "da_GL", value: "Danish (Greenland)" },
    { key: "dua", value: "Duala" },
    { key: "dua_CM", value: "Duala (Cameroon)" },
    { key: "nl", value: "Dutch" },
    { key: "nl_AW", value: "Dutch (Aruba)" },
    { key: "nl_BE", value: "Dutch (Belgium)" },
    { key: "nl_BQ", value: "Dutch (Caribbean Netherlands)" },
    { key: "nl_CW", value: "Dutch (Curaçao)" },
    { key: "nl_NL", value: "Dutch (Netherlands)" },
    { key: "nl_SX", value: "Dutch (Sint Maarten)" },
    { key: "nl_SR", value: "Dutch (Suriname)" },
    { key: "dz", value: "Dzongkha" },
    { key: "dz_BT", value: "Dzongkha (Bhutan)" },
    { key: "ebu", value: "Embu" },
    { key: "ebu_KE", value: "Embu (Kenya)" },
    { key: "en", value: "English" },
    { key: "en_AS", value: "English (American Samoa)" },
    { key: "en_AI", value: "English (Anguilla)" },
    { key: "en_AG", value: "English (Antigua & Barbuda)" },
    { key: "en_AU", value: "English (Australia)" },
    { key: "en_AT", value: "English (Austria)" },
    { key: "en_BS", value: "English (Bahamas)" },
    { key: "en_BB", value: "English (Barbados)" },
    { key: "en_BE", value: "English (Belgium)" },
    { key: "en_BZ", value: "English (Belize)" },
    { key: "en_BM", value: "English (Bermuda)" },
    { key: "en_BW", value: "English (Botswana)" },
    { key: "en_IO", value: "English (British Indian Ocean Territory)" },
    { key: "en_VG", value: "English (British Virgin Islands)" },
    { key: "en_BI", value: "English (Burundi)" },
    { key: "en_CM", value: "English (Cameroon)" },
    { key: "en_CA", value: "English (Canada)" },
    { key: "en_KY", value: "English (Cayman Islands)" },
    { key: "en_CX", value: "English (Christmas Island)" },
    { key: "en_CC", value: "English (Cocos (Keeling) Islands)" },
    { key: "en_CK", value: "English (Cook Islands)" },
    { key: "en_CY", value: "English (Cyprus)" },
    { key: "en_DK", value: "English (Denmark)" },
    { key: "en_DG", value: "English (Diego Garcia)" },
    { key: "en_DM", value: "English (Dominica)" },
    { key: "en_ER", value: "English (Eritrea)" },
    { key: "en_150", value: "English (Europe)" },
    { key: "en_FK", value: "English (Falkland Islands)" },
    { key: "en_FJ", value: "English (Fiji)" },
    { key: "en_FI", value: "English (Finland)" },
    { key: "en_GM", value: "English (Gambia)" },
    { key: "en_DE", value: "English (Germany)" },
    { key: "en_GH", value: "English (Ghana)" },
    { key: "en_GI", value: "English (Gibraltar)" },
    { key: "en_GD", value: "English (Grenada)" },
    { key: "en_GU", value: "English (Guam)" },
    { key: "en_GG", value: "English (Guernsey)" },
    { key: "en_GY", value: "English (Guyana)" },
    { key: "en_HK", value: "English (Hong Kong SAR China)" },
    { key: "en_IN", value: "English (India)" },
    { key: "en_IE", value: "English (Ireland)" },
    { key: "en_IM", value: "English (Isle of Man)" },
    { key: "en_IL", value: "English (Israel)" },
    { key: "en_JM", value: "English (Jamaica)" },
    { key: "en_JE", value: "English (Jersey)" },
    { key: "en_KE", value: "English (Kenya)" },
    { key: "en_KI", value: "English (Kiribati)" },
    { key: "en_LS", value: "English (Lesotho)" },
    { key: "en_LR", value: "English (Liberia)" },
    { key: "en_MO", value: "English (Macau SAR China)" },
    { key: "en_MG", value: "English (Madagascar)" },
    { key: "en_MW", value: "English (Malawi)" },
    { key: "en_MY", value: "English (Malaysia)" },
    { key: "en_MT", value: "English (Malta)" },
    { key: "en_MH", value: "English (Marshall Islands)" },
    { key: "en_MU", value: "English (Mauritius)" },
    { key: "en_FM", value: "English (Micronesia)" },
    { key: "en_MS", value: "English (Montserrat)" },
    { key: "en_NA", value: "English (Namibia)" },
    { key: "en_NR", value: "English (Nauru)" },
    { key: "en_NL", value: "English (Netherlands)" },
    { key: "en_NZ", value: "English (New Zealand)" },
    { key: "en_NG", value: "English (Nigeria)" },
    { key: "en_NU", value: "English (Niue)" },
    { key: "en_NF", value: "English (Norfolk Island)" },
    { key: "en_MP", value: "English (Northern Mariana Islands)" },
    { key: "en_PK", value: "English (Pakistan)" },
    { key: "en_PW", value: "English (Palau)" },
    { key: "en_PG", value: "English (Papua New Guinea)" },
    { key: "en_PH", value: "English (Philippines)" },
    { key: "en_PN", value: "English (Pitcairn Islands)" },
    { key: "en_PR", value: "English (Puerto Rico)" },
    { key: "en_RW", value: "English (Rwanda)" },
    { key: "en_WS", value: "English (Samoa)" },
    { key: "en_SC", value: "English (Seychelles)" },
    { key: "en_SL", value: "English (Sierra Leone)" },
    { key: "en_SG", value: "English (Singapore)" },
    { key: "en_SX", value: "English (Sint Maarten)" },
    { key: "en_SI", value: "English (Slovenia)" },
    { key: "en_SB", value: "English (Solomon Islands)" },
    { key: "en_ZA", value: "English (South Africa)" },
    { key: "en_SS", value: "English (South Sudan)" },
    { key: "en_SH", value: "English (St. Helena)" },
    { key: "en_KN", value: "English (St. Kitts & Nevis)" },
    { key: "en_LC", value: "English (St. Lucia)" },
    { key: "en_VC", value: "English (St. Vincent & Grenadines)" },
    { key: "en_SD", value: "English (Sudan)" },
    { key: "en_SZ", value: "English (Swaziland)" },
    { key: "en_SE", value: "English (Sweden)" },
    { key: "en_CH", value: "English (Switzerland)" },
    { key: "en_TZ", value: "English (Tanzania)" },
    { key: "en_TK", value: "English (Tokelau)" },
    { key: "en_TO", value: "English (Tonga)" },
    { key: "en_TT", value: "English (Trinidad & Tobago)" },
    { key: "en_TC", value: "English (Turks & Caicos Islands)" },
    { key: "en_TV", value: "English (Tuvalu)" },
    { key: "en_UM", value: "English (U.S. Outlying Islands)" },
    { key: "en_VI", value: "English (U.S. Virgin Islands)" },
    { key: "en_UG", value: "English (Uganda)" },
    { key: "en_GB", value: "English (United Kingdom)" },
    { key: "en_US", value: "English (United States)" },
    { key: "en_US_POSIX", value: "English (United States, Computer)" },
    { key: "en_VU", value: "English (Vanuatu)" },
    { key: "en_001", value: "English (World)" },
    { key: "en_ZM", value: "English (Zambia)" },
    { key: "en_ZW", value: "English (Zimbabwe)" },
    { key: "eo", value: "Esperanto" },
    { key: "eo_001", value: "Esperanto (World)" },
    { key: "et", value: "Estonian" },
    { key: "et_EE", value: "Estonian (Estonia)" },
    { key: "ee", value: "Ewe" },
    { key: "ee_GH", value: "Ewe (Ghana)" },
    { key: "ee_TG", value: "Ewe (Togo)" },
    { key: "ewo", value: "Ewondo" },
    { key: "ewo_CM", value: "Ewondo (Cameroon)" },
    { key: "fo", value: "Faroese" },
    { key: "fo_DK", value: "Faroese (Denmark)" },
    { key: "fo_FO", value: "Faroese (Faroe Islands)" },
    { key: "fil", value: "Filipino" },
    { key: "fil_PH", value: "Filipino (Philippines)" },
    { key: "fi", value: "Finnish" },
    { key: "fi_FI", value: "Finnish (Finland)" },
    { key: "fr", value: "French" },
    { key: "fr_DZ", value: "French (Algeria)" },
    { key: "fr_BE", value: "French (Belgium)" },
    { key: "fr_BJ", value: "French (Benin)" },
    { key: "fr_BF", value: "French (Burkina Faso)" },
    { key: "fr_BI", value: "French (Burundi)" },
    { key: "fr_CM", value: "French (Cameroon)" },
    { key: "fr_CA", value: "French (Canada)" },
    { key: "fr_CF", value: "French (Central African Republic)" },
    { key: "fr_TD", value: "French (Chad)" },
    { key: "fr_KM", value: "French (Comoros)" },
    { key: "fr_CG", value: "French (Congo - Brazzaville)" },
    { key: "fr_CD", value: "French (Congo - Kinshasa)" },
    { key: "fr_CI", value: "French (Côte d’Ivoire)" },
    { key: "fr_DJ", value: "French (Djibouti)" },
    { key: "fr_GQ", value: "French (Equatorial Guinea)" },
    { key: "fr_FR", value: "French (France)" },
    { key: "fr_GF", value: "French (French Guiana)" },
    { key: "fr_PF", value: "French (French Polynesia)" },
    { key: "fr_GA", value: "French (Gabon)" },
    { key: "fr_GP", value: "French (Guadeloupe)" },
    { key: "fr_GN", value: "French (Guinea)" },
    { key: "fr_HT", value: "French (Haiti)" },
    { key: "fr_LU", value: "French (Luxembourg)" },
    { key: "fr_MG", value: "French (Madagascar)" },
    { key: "fr_ML", value: "French (Mali)" },
    { key: "fr_MQ", value: "French (Martinique)" },
    { key: "fr_MR", value: "French (Mauritania)" },
    { key: "fr_MU", value: "French (Mauritius)" },
    { key: "fr_YT", value: "French (Mayotte)" },
    { key: "fr_MC", value: "French (Monaco)" },
    { key: "fr_MA", value: "French (Morocco)" },
    { key: "fr_NC", value: "French (New Caledonia)" },
    { key: "fr_NE", value: "French (Niger)" },
    { key: "fr_RW", value: "French (Rwanda)" },
    { key: "fr_RE", value: "French (Réunion)" },
    { key: "fr_SN", value: "French (Senegal)" },
    { key: "fr_SC", value: "French (Seychelles)" },
    { key: "fr_BL", value: "French (St. Barthélemy)" },
    { key: "fr_MF", value: "French (St. Martin)" },
    { key: "fr_PM", value: "French (St. Pierre & Miquelon)" },
    { key: "fr_CH", value: "French (Switzerland)" },
    { key: "fr_SY", value: "French (Syria)" },
    { key: "fr_TG", value: "French (Togo)" },
    { key: "fr_TN", value: "French (Tunisia)" },
    { key: "fr_VU", value: "French (Vanuatu)" },
    { key: "fr_WF", value: "French (Wallis & Futuna)" },
    { key: "fur", value: "Friulian" },
    { key: "fur_IT", value: "Friulian (Italy)" },
    { key: "ff", value: "Fulah" },
    { key: "ff_CM", value: "Fulah (Cameroon)" },
    { key: "ff_GN", value: "Fulah (Guinea)" },
    { key: "ff_MR", value: "Fulah (Mauritania)" },
    { key: "ff_SN", value: "Fulah (Senegal)" },
    { key: "gl", value: "Galician" },
    { key: "gl_ES", value: "Galician (Spain)" },
    { key: "lg", value: "Ganda" },
    { key: "lg_UG", value: "Ganda (Uganda)" },
    { key: "ka", value: "Georgian" },
    { key: "ka_GE", value: "Georgian (Georgia)" },
    { key: "de", value: "German" },
    { key: "de_AT", value: "German (Austria)" },
    { key: "de_BE", value: "German (Belgium)" },
    { key: "de_DE", value: "German (Germany)" },
    { key: "de_IT", value: "German (Italy)" },
    { key: "de_LI", value: "German (Liechtenstein)" },
    { key: "de_LU", value: "German (Luxembourg)" },
    { key: "de_CH", value: "German (Switzerland)" },
    { key: "el", value: "Greek" },
    { key: "el_CY", value: "Greek (Cyprus)" },
    { key: "el_GR", value: "Greek (Greece)" },
    { key: "gu", value: "Gujarati" },
    { key: "gu_IN", value: "Gujarati (India)" },
    { key: "guz", value: "Gusii" },
    { key: "guz_KE", value: "Gusii (Kenya)" },
    { key: "ha", value: "Hausa" },
    { key: "ha_GH", value: "Hausa (Ghana)" },
    { key: "ha_NE", value: "Hausa (Niger)" },
    { key: "ha_NG", value: "Hausa (Nigeria)" },
    { key: "haw", value: "Hawaiian" },
    { key: "haw_US", value: "Hawaiian (United States)" },
    { key: "iw", value: "Hebrew" },
    { key: "iw_IL", value: "Hebrew (Israel)" },
    { key: "hi", value: "Hindi" },
    { key: "hi_IN", value: "Hindi (India)" },
    { key: "hu", value: "Hungarian" },
    { key: "hu_HU", value: "Hungarian (Hungary)" },
    { key: "is", value: "Icelandic" },
    { key: "is_IS", value: "Icelandic (Iceland)" },
    { key: "ig", value: "Igbo" },
    { key: "ig_NG", value: "Igbo (Nigeria)" },
    { key: "smn", value: "Inari Sami" },
    { key: "smn_FI", value: "Inari Sami (Finland)" },
    { key: "in", value: "Indonesian" },
    { key: "in_ID", value: "Indonesian (Indonesia)" },
    { key: "ga", value: "Irish" },
    { key: "ga_IE", value: "Irish (Ireland)" },
    { key: "it", value: "Italian" },
    { key: "it_IT", value: "Italian (Italy)" },
    { key: "it_SM", value: "Italian (San Marino)" },
    { key: "it_CH", value: "Italian (Switzerland)" },
    { key: "it_VA", value: "Italian (Vatican City)" },
    { key: "ja", value: "Japanese" },
    { key: "ja_JP", value: "Japanese (Japan)" },
    { key: "ja_JP_JP_#u-ca-japanese", value: "Japanese (Japan, JP, Japanese Calendar)" },
    { key: "dyo", value: "Jola-Fonyi" },
    { key: "dyo_SN", value: "Jola-Fonyi (Senegal)" },
    { key: "kea", value: "Kabuverdianu" },
    { key: "kea_CV", value: "Kabuverdianu (Cape Verde)" },
    { key: "kab", value: "Kabyle" },
    { key: "kab_DZ", value: "Kabyle (Algeria)" },
    { key: "kkj", value: "Kako" },
    { key: "kkj_CM", value: "Kako (Cameroon)" },
    { key: "kl", value: "Kalaallisut" },
    { key: "kl_GL", value: "Kalaallisut (Greenland)" },
    { key: "kln", value: "Kalenjin" },
    { key: "kln_KE", value: "Kalenjin (Kenya)" },
    { key: "kam", value: "Kamba" },
    { key: "kam_KE", value: "Kamba (Kenya)" },
    { key: "kn", value: "Kannada" },
    { key: "kn_IN", value: "Kannada (India)" },
    { key: "ks", value: "Kashmiri" },
    { key: "ks_IN", value: "Kashmiri (India)" },
    { key: "kk", value: "Kazakh" },
    { key: "kk_KZ", value: "Kazakh (Kazakhstan)" },
    { key: "km", value: "Khmer" },
    { key: "km_KH", value: "Khmer (Cambodia)" },
    { key: "ki", value: "Kikuyu" },
    { key: "ki_KE", value: "Kikuyu (Kenya)" },
    { key: "rw", value: "Kinyarwanda" },
    { key: "rw_RW", value: "Kinyarwanda (Rwanda)" },
    { key: "kok", value: "Konkani" },
    { key: "kok_IN", value: "Konkani (India)" },
    { key: "ko", value: "Korean" },
    { key: "ko_KP", value: "Korean (North Korea)" },
    { key: "ko_KR", value: "Korean (South Korea)" },
    { key: "khq", value: "Koyra Chiini" },
    { key: "khq_ML", value: "Koyra Chiini (Mali)" },
    { key: "ses", value: "Koyraboro Senni" },
    { key: "ses_ML", value: "Koyraboro Senni (Mali)" },
    { key: "nmg", value: "Kwasio" },
    { key: "nmg_CM", value: "Kwasio (Cameroon)" },
    { key: "ky", value: "Kyrgyz" },
    { key: "ky_KG", value: "Kyrgyz (Kyrgyzstan)" },
    { key: "lkt", value: "Lakota" },
    { key: "lkt_US", value: "Lakota (United States)" },
    { key: "lag", value: "Langi" },
    { key: "lag_TZ", value: "Langi (Tanzania)" },
    { key: "lo", value: "Lao" },
    { key: "lo_LA", value: "Lao (Laos)" },
    { key: "lv", value: "Latvian" },
    { key: "lv_LV", value: "Latvian (Latvia)" },
    { key: "ln", value: "Lingala" },
    { key: "ln_AO", value: "Lingala (Angola)" },
    { key: "ln_CF", value: "Lingala (Central African Republic)" },
    { key: "ln_CG", value: "Lingala (Congo - Brazzaville)" },
    { key: "ln_CD", value: "Lingala (Congo - Kinshasa)" },
    { key: "lt", value: "Lithuanian" },
    { key: "lt_LT", value: "Lithuanian (Lithuania)" },
    { key: "nds", value: "Low German" },
    { key: "nds_DE", value: "Low German (Germany)" },
    { key: "nds_NL", value: "Low German (Netherlands)" },
    { key: "dsb", value: "Lower Sorbian" },
    { key: "dsb_DE", value: "Lower Sorbian (Germany)" },
    { key: "lu", value: "Luba-Katanga" },
    { key: "lu_CD", value: "Luba-Katanga (Congo - Kinshasa)" },
    { key: "luo", value: "Luo" },
    { key: "luo_KE", value: "Luo (Kenya)" },
    { key: "lb", value: "Luxembourgish" },
    { key: "lb_LU", value: "Luxembourgish (Luxembourg)" },
    { key: "luy", value: "Luyia" },
    { key: "luy_KE", value: "Luyia (Kenya)" },
    { key: "mk", value: "Macedonian" },
    { key: "mk_MK", value: "Macedonian (Macedonia)" },
    { key: "jmc", value: "Machame" },
    { key: "jmc_TZ", value: "Machame (Tanzania)" },
    { key: "mgh", value: "Makhuwa-Meetto" },
    { key: "mgh_MZ", value: "Makhuwa-Meetto (Mozambique)" },
    { key: "kde", value: "Makonde" },
    { key: "kde_TZ", value: "Makonde (Tanzania)" },
    { key: "mg", value: "Malagasy" },
    { key: "mg_MG", value: "Malagasy (Madagascar)" },
    { key: "ms", value: "Malay" },
    { key: "ms_BN", value: "Malay (Brunei)" },
    { key: "ms_MY", value: "Malay (Malaysia)" },
    { key: "ms_SG", value: "Malay (Singapore)" },
    { key: "ml", value: "Malayalam" },
    { key: "ml_IN", value: "Malayalam (India)" },
    { key: "mt", value: "Maltese" },
    { key: "mt_MT", value: "Maltese (Malta)" },
    { key: "gv", value: "Manx" },
    { key: "gv_IM", value: "Manx (Isle of Man)" },
    { key: "mr", value: "Marathi" },
    { key: "mr_IN", value: "Marathi (India)" },
    { key: "mas", value: "Masai" },
    { key: "mas_KE", value: "Masai (Kenya)" },
    { key: "mas_TZ", value: "Masai (Tanzania)" },
    { key: "mzn", value: "Mazanderani" },
    { key: "mzn_IR", value: "Mazanderani (Iran)" },
    { key: "mer", value: "Meru" },
    { key: "mer_KE", value: "Meru (Kenya)" },
    { key: "mgo", value: "Metaʼ" },
    { key: "mgo_CM", value: "Metaʼ (Cameroon)" },
    { key: "mn", value: "Mongolian" },
    { key: "mn_MN", value: "Mongolian (Mongolia)" },
    { key: "mfe", value: "Morisyen" },
    { key: "mfe_MU", value: "Morisyen (Mauritius)" },
    { key: "mua", value: "Mundang" },
    { key: "mua_CM", value: "Mundang (Cameroon)" },
    { key: "naq", value: "Nama" },
    { key: "naq_NA", value: "Nama (Namibia)" },
    { key: "ne", value: "Nepali" },
    { key: "ne_IN", value: "Nepali (India)" },
    { key: "ne_NP", value: "Nepali (Nepal)" },
    { key: "nnh", value: "Ngiemboon" },
    { key: "nnh_CM", value: "Ngiemboon (Cameroon)" },
    { key: "jgo", value: "Ngomba" },
    { key: "jgo_CM", value: "Ngomba (Cameroon)" },
    { key: "nd", value: "North Ndebele" },
    { key: "nd_ZW", value: "North Ndebele (Zimbabwe)" },
    { key: "lrc", value: "Northern Luri" },
    { key: "lrc_IR", value: "Northern Luri (Iran)" },
    { key: "lrc_IQ", value: "Northern Luri (Iraq)" },
    { key: "se", value: "Northern Sami" },
    { key: "se_FI", value: "Northern Sami (Finland)" },
    { key: "se_NO", value: "Northern Sami (Norway)" },
    { key: "se_SE", value: "Northern Sami (Sweden)" },
    { key: "no", value: "Norwegian" },
    { key: "no_NO", value: "Norwegian (Norway)" },
    { key: "no_NO_NY", value: "Norwegian (Norway, Nynorsk)" },
    { key: "nb", value: "Norwegian Bokmål" },
    { key: "nb_NO", value: "Norwegian Bokmål (Norway)" },
    { key: "nb_SJ", value: "Norwegian Bokmål (Svalbard & Jan Mayen)" },
    { key: "nn", value: "Norwegian Nynorsk" },
    { key: "nn_NO", value: "Norwegian Nynorsk (Norway)" },
    { key: "nus", value: "Nuer" },
    { key: "nus_SS", value: "Nuer (South Sudan)" },
    { key: "nyn", value: "Nyankole" },
    { key: "nyn_UG", value: "Nyankole (Uganda)" },
    { key: "or", value: "Odia" },
    { key: "or_IN", value: "Odia (India)" },
    { key: "om", value: "Oromo" },
    { key: "om_ET", value: "Oromo (Ethiopia)" },
    { key: "om_KE", value: "Oromo (Kenya)" },
    { key: "os", value: "Ossetic" },
    { key: "os_GE", value: "Ossetic (Georgia)" },
    { key: "os_RU", value: "Ossetic (Russia)" },
    { key: "ps", value: "Pashto" },
    { key: "ps_AF", value: "Pashto (Afghanistan)" },
    { key: "fa", value: "Persian" },
    { key: "fa_AF", value: "Persian (Afghanistan)" },
    { key: "fa_IR", value: "Persian (Iran)" },
    { key: "pl", value: "Polish" },
    { key: "pl_PL", value: "Polish (Poland)" },
    { key: "pt", value: "Portuguese" },
    { key: "pt_AO", value: "Portuguese (Angola)" },
    { key: "pt_BR", value: "Portuguese (Brazil)" },
    { key: "pt_CV", value: "Portuguese (Cape Verde)" },
    { key: "pt_GQ", value: "Portuguese (Equatorial Guinea)" },
    { key: "pt_GW", value: "Portuguese (Guinea-Bissau)" },
    { key: "pt_LU", value: "Portuguese (Luxembourg)" },
    { key: "pt_MO", value: "Portuguese (Macau SAR China)" },
    { key: "pt_MZ", value: "Portuguese (Mozambique)" },
    { key: "pt_PT", value: "Portuguese (Portugal)" },
    { key: "pt_CH", value: "Portuguese (Switzerland)" },
    { key: "pt_ST", value: "Portuguese (São Tomé & Príncipe)" },
    { key: "pt_TL", value: "Portuguese (Timor-Leste)" },
    { key: "prg", value: "Prussian" },
    { key: "prg_001", value: "Prussian (World)" },
    { key: "pa", value: "Punjabi" },
    { key: "pa__#Arab", value: "Punjabi (Arabic)" },
    { key: "pa_PK_#Arab", value: "Punjabi (Arabic, Pakistan)" },
    { key: "pa__#Guru", value: "Punjabi (Gurmukhi)" },
    { key: "pa_IN_#Guru", value: "Punjabi (Gurmukhi, India)" },
    { key: "qu", value: "Quechua" },
    { key: "qu_BO", value: "Quechua (Bolivia)" },
    { key: "qu_EC", value: "Quechua (Ecuador)" },
    { key: "qu_PE", value: "Quechua (Peru)" },
    { key: "ro", value: "Romanian" },
    { key: "ro_MD", value: "Romanian (Moldova)" },
    { key: "ro_RO", value: "Romanian (Romania)" },
    { key: "rm", value: "Romansh" },
    { key: "rm_CH", value: "Romansh (Switzerland)" },
    { key: "rof", value: "Rombo" },
    { key: "rof_TZ", value: "Rombo (Tanzania)" },
    { key: "rn", value: "Rundi" },
    { key: "rn_BI", value: "Rundi (Burundi)" },
    { key: "ru", value: "Russian" },
    { key: "ru_BY", value: "Russian (Belarus)" },
    { key: "ru_KZ", value: "Russian (Kazakhstan)" },
    { key: "ru_KG", value: "Russian (Kyrgyzstan)" },
    { key: "ru_MD", value: "Russian (Moldova)" },
    { key: "ru_RU", value: "Russian (Russia)" },
    { key: "ru_UA", value: "Russian (Ukraine)" },
    { key: "rwk", value: "Rwa" },
    { key: "rwk_TZ", value: "Rwa (Tanzania)" },
    { key: "sah", value: "Sakha" },
    { key: "sah_RU", value: "Sakha (Russia)" },
    { key: "saq", value: "Samburu" },
    { key: "saq_KE", value: "Samburu (Kenya)" },
    { key: "sg", value: "Sango" },
    { key: "sg_CF", value: "Sango (Central African Republic)" },
    { key: "sbp", value: "Sangu" },
    { key: "sbp_TZ", value: "Sangu (Tanzania)" },
    { key: "gd", value: "Scottish Gaelic" },
    { key: "gd_GB", value: "Scottish Gaelic (United Kingdom)" },
    { key: "seh", value: "Sena" },
    { key: "seh_MZ", value: "Sena (Mozambique)" },
    { key: "sr", value: "Serbian" },
    { key: "sr_BA", value: "Serbian (Bosnia & Herzegovina)" },
    { key: "sr__#Cyrl", value: "Serbian (Cyrillic)" },
    { key: "sr_BA_#Cyrl", value: "Serbian (Cyrillic, Bosnia & Herzegovina)" },
    { key: "sr_XK_#Cyrl", value: "Serbian (Cyrillic, Kosovo)" },
    { key: "sr_ME_#Cyrl", value: "Serbian (Cyrillic, Montenegro)" },
    { key: "sr_RS_#Cyrl", value: "Serbian (Cyrillic, Serbia)" },
    { key: "sr__#Latn", value: "Serbian (Latin)" },
    { key: "sr_BA_#Latn", value: "Serbian (Latin, Bosnia & Herzegovina)" },
    { key: "sr_XK_#Latn", value: "Serbian (Latin, Kosovo)" },
    { key: "sr_ME_#Latn", value: "Serbian (Latin, Montenegro)" },
    { key: "sr_RS_#Latn", value: "Serbian (Latin, Serbia)" },
    { key: "sr_ME", value: "Serbian (Montenegro)" },
    { key: "sr_CS", value: "Serbian (Serbia and Montenegro)" },
    { key: "sr_RS", value: "Serbian (Serbia)" },
    { key: "ksb", value: "Shambala" },
    { key: "ksb_TZ", value: "Shambala (Tanzania)" },
    { key: "sn", value: "Shona" },
    { key: "sn_ZW", value: "Shona (Zimbabwe)" },
    { key: "ii", value: "Sichuan Yi" },
    { key: "ii_CN", value: "Sichuan Yi (China)" },
    { key: "sd", value: "Sindhi" },
    { key: "sd_PK", value: "Sindhi (Pakistan)" },
    { key: "si", value: "Sinhala" },
    { key: "si_LK", value: "Sinhala (Sri Lanka)" },
    { key: "sk", value: "Slovak" },
    { key: "sk_SK", value: "Slovak (Slovakia)" },
    { key: "sl", value: "Slovenian" },
    { key: "sl_SI", value: "Slovenian (Slovenia)" },
    { key: "xog", value: "Soga" },
    { key: "xog_UG", value: "Soga (Uganda)" },
    { key: "so", value: "Somali" },
    { key: "so_DJ", value: "Somali (Djibouti)" },
    { key: "so_ET", value: "Somali (Ethiopia)" },
    { key: "so_KE", value: "Somali (Kenya)" },
    { key: "so_SO", value: "Somali (Somalia)" },
    { key: "es", value: "Spanish" },
    { key: "es_AR", value: "Spanish (Argentina)" },
    { key: "es_BZ", value: "Spanish (Belize)" },
    { key: "es_BO", value: "Spanish (Bolivia)" },
    { key: "es_BR", value: "Spanish (Brazil)" },
    { key: "es_IC", value: "Spanish (Canary Islands)" },
    { key: "es_EA", value: "Spanish (Ceuta & Melilla)" },
    { key: "es_CL", value: "Spanish (Chile)" },
    { key: "es_CO", value: "Spanish (Colombia)" },
    { key: "es_CR", value: "Spanish (Costa Rica)" },
    { key: "es_CU", value: "Spanish (Cuba)" },
    { key: "es_DO", value: "Spanish (Dominican Republic)" },
    { key: "es_EC", value: "Spanish (Ecuador)" },
    { key: "es_SV", value: "Spanish (El Salvador)" },
    { key: "es_GQ", value: "Spanish (Equatorial Guinea)" },
    { key: "es_GT", value: "Spanish (Guatemala)" },
    { key: "es_HN", value: "Spanish (Honduras)" },
    { key: "es_419", value: "Spanish (Latin America)" },
    { key: "es_MX", value: "Spanish (Mexico)" },
    { key: "es_NI", value: "Spanish (Nicaragua)" },
    { key: "es_PA", value: "Spanish (Panama)" },
    { key: "es_PY", value: "Spanish (Paraguay)" },
    { key: "es_PE", value: "Spanish (Peru)" },
    { key: "es_PH", value: "Spanish (Philippines)" },
    { key: "es_PR", value: "Spanish (Puerto Rico)" },
    { key: "es_ES", value: "Spanish (Spain)" },
    { key: "es_US", value: "Spanish (United States)" },
    { key: "es_UY", value: "Spanish (Uruguay)" },
    { key: "es_VE", value: "Spanish (Venezuela)" },
    { key: "zgh", value: "Standard Moroccan Tamazight" },
    { key: "zgh_MA", value: "Standard Moroccan Tamazight (Morocco)" },
    { key: "sw", value: "Swahili" },
    { key: "sw_CD", value: "Swahili (Congo - Kinshasa)" },
    { key: "sw_KE", value: "Swahili (Kenya)" },
    { key: "sw_TZ", value: "Swahili (Tanzania)" },
    { key: "sw_UG", value: "Swahili (Uganda)" },
    { key: "sv", value: "Swedish" },
    { key: "sv_FI", value: "Swedish (Finland)" },
    { key: "sv_SE", value: "Swedish (Sweden)" },
    { key: "sv_AX", value: "Swedish (Åland Islands)" },
    { key: "gsw", value: "Swiss German" },
    { key: "gsw_FR", value: "Swiss German (France)" },
    { key: "gsw_LI", value: "Swiss German (Liechtenstein)" },
    { key: "gsw_CH", value: "Swiss German (Switzerland)" },
    { key: "shi", value: "Tachelhit" },
    { key: "shi__#Latn", value: "Tachelhit (Latin)" },
    { key: "shi_MA_#Latn", value: "Tachelhit (Latin, Morocco)" },
    { key: "shi__#Tfng", value: "Tachelhit (Tifinagh)" },
    { key: "shi_MA_#Tfng", value: "Tachelhit (Tifinagh, Morocco)" },
    { key: "dav", value: "Taita" },
    { key: "dav_KE", value: "Taita (Kenya)" },
    { key: "tg", value: "Tajik" },
    { key: "tg_TJ", value: "Tajik (Tajikistan)" },
    { key: "ta", value: "Tamil" },
    { key: "ta_IN", value: "Tamil (India)" },
    { key: "ta_MY", value: "Tamil (Malaysia)" },
    { key: "ta_SG", value: "Tamil (Singapore)" },
    { key: "ta_LK", value: "Tamil (Sri Lanka)" },
    { key: "twq", value: "Tasawaq" },
    { key: "twq_NE", value: "Tasawaq (Niger)" },
    { key: "tt", value: "Tatar" },
    { key: "tt_RU", value: "Tatar (Russia)" },
    { key: "te", value: "Telugu" },
    { key: "te_IN", value: "Telugu (India)" },
    { key: "teo", value: "Teso" },
    { key: "teo_KE", value: "Teso (Kenya)" },
    { key: "teo_UG", value: "Teso (Uganda)" },
    { key: "th", value: "Thai" },
    { key: "th_TH", value: "Thai (Thailand)" },
    { key: "th_TH_TH_#u-nu-thai", value: "Thai (Thailand, TH, Thai Digits)" },
    { key: "bo", value: "Tibetan" },
    { key: "bo_CN", value: "Tibetan (China)" },
    { key: "bo_IN", value: "Tibetan (India)" },
    { key: "ti", value: "Tigrinya" },
    { key: "ti_ER", value: "Tigrinya (Eritrea)" },
    { key: "ti_ET", value: "Tigrinya (Ethiopia)" },
    { key: "to", value: "Tongan" },
    { key: "to_TO", value: "Tongan (Tonga)" },
    { key: "tr", value: "Turkish" },
    { key: "tr_CY", value: "Turkish (Cyprus)" },
    { key: "tr_TR", value: "Turkish (Turkey)" },
    { key: "tk", value: "Turkmen" },
    { key: "tk_TM", value: "Turkmen (Turkmenistan)" },
    { key: "uk", value: "Ukrainian" },
    { key: "uk_UA", value: "Ukrainian (Ukraine)" },
    { key: "hsb", value: "Upper Sorbian" },
    { key: "hsb_DE", value: "Upper Sorbian (Germany)" },
    { key: "ur", value: "Urdu" },
    { key: "ur_IN", value: "Urdu (India)" },
    { key: "ur_PK", value: "Urdu (Pakistan)" },
    { key: "ug", value: "Uyghur" },
    { key: "ug_CN", value: "Uyghur (China)" },
    { key: "uz", value: "Uzbek" },
    { key: "uz__#Arab", value: "Uzbek (Arabic)" },
    { key: "uz_AF_#Arab", value: "Uzbek (Arabic, Afghanistan)" },
    { key: "uz__#Cyrl", value: "Uzbek (Cyrillic)" },
    { key: "uz_UZ_#Cyrl", value: "Uzbek (Cyrillic, Uzbekistan)" },
    { key: "uz__#Latn", value: "Uzbek (Latin)" },
    { key: "uz_UZ_#Latn", value: "Uzbek (Latin, Uzbekistan)" },
    { key: "vai", value: "Vai" },
    { key: "vai__#Latn", value: "Vai (Latin)" },
    { key: "vai_LR_#Latn", value: "Vai (Latin, Liberia)" },
    { key: "vai__#Vaii", value: "Vai (Vai)" },
    { key: "vai_LR_#Vaii", value: "Vai (Vai, Liberia)" },
    { key: "vi", value: "Vietnamese" },
    { key: "vi_VN", value: "Vietnamese (Vietnam)" },
    { key: "vo", value: "Volapük" },
    { key: "vo_001", value: "Volapük (World)" },
    { key: "vun", value: "Vunjo" },
    { key: "vun_TZ", value: "Vunjo (Tanzania)" },
    { key: "wae", value: "Walser" },
    { key: "wae_CH", value: "Walser (Switzerland)" },
    { key: "cy", value: "Welsh" },
    { key: "cy_GB", value: "Welsh (United Kingdom)" },
    { key: "fy", value: "Western Frisian" },
    { key: "fy_NL", value: "Western Frisian (Netherlands)" },
    { key: "wo", value: "Wolof" },
    { key: "wo_SN", value: "Wolof (Senegal)" },
    { key: "yav", value: "Yangben" },
    { key: "yav_CM", value: "Yangben (Cameroon)" },
    { key: "ji", value: "Yiddish" },
    { key: "ji_001", value: "Yiddish (World)" },
    { key: "yo", value: "Yoruba" },
    { key: "yo_BJ", value: "Yoruba (Benin)" },
    { key: "yo_NG", value: "Yoruba (Nigeria)" },
    { key: "dje", value: "Zarma" },
    { key: "dje_NE", value: "Zarma (Niger)" },
    { key: "zu", value: "Zulu" },
    { key: "zu_ZA", value: "Zulu (South Africa)" },
]