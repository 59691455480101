/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor, SubtypedElement } from "../../ui/APDescriptor";
import { MGraphicElement } from "../common/MGraphicElement";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { EvaluationTimeEnum } from "../enums/EvaluationTimeEnum";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PAccordeonCategories } from "../../ui/composite/UPAccordeonCategories";
import { MQRCode } from "./barcode4j/MQRCode";
import { MCodabar } from "./barcode4j/MCodabar";
import { MPDF417 } from "./barcode4j/MPDF417";
import { MCode128 } from "./barcode4j/MCode128";
import { MCode39 } from "./barcode4j/MCode39";
import { MDataMatrix } from "./barcode4j/MDataMatrix";
import { MEAN128 } from "./barcode4j/MEAN128";
import { MEAN13 } from "./barcode4j/MEAN13";
import { MEAN8 } from "./barcode4j/MEAN8";
import { MPostnet } from "./barcode4j/MPostnet";
import { MInterleaved2Of5 } from "./barcode4j/MInterleaved2Of5";
import { MRoyalMailCustomer } from "./barcode4j/MRoyalMailCustomer";
import { MUSPSIntelligentMail } from "./barcode4j/MUSPSIntelligentMail";
import { MUPCA } from "./barcode4j/MUPCA";
import { MUPCE } from "./barcode4j/MUPCE";
import { getPath, UiPropertyProps } from "../../ui/UiProperty";
import { MGlobalTradeItemNumber } from "./barbeque/MGlobalTradeItemNumber";
import { MMonarch } from "./barbeque/MMonarch";
import { MBookland } from "./barbeque/MBookland";
import { MNW7 } from "./barbeque/MNW7";
import { MRandomWeightUPCA } from "./barbeque/MRandomWeightUPCA";
import { MShipmentIdentificationNumber } from "./barbeque/MShipmentIdentificationNumber";
import { MSCC14ShippingCode } from "./barbeque/MSCC14ShippingCode";
import { MSSCC18 } from "./barbeque/MSSCC18";
import { MCode128A } from "./barbeque/MCode128A";
import { MCode128C } from "./barbeque/MCode128C";
import { MCode128B } from "./barbeque/MCode128B";
import { MCode39Extended } from "./barbeque/MCode39Extended";
import { MUSPS } from "./barbeque/MUSPS";
import { MUSD4 } from "./barbeque/MUSD4";
import { MUSD3 } from "./barbeque/MUSD3";
import { MUCC128 } from "./barbeque/MUCC128";
import { MStd2of5 } from "./barbeque/MStd2of5";
import { M3of9 } from "./barbeque/M3of9";
import { M2of7 } from "./barbeque/M2of7";
import { PBarcodeImpl } from "./UPBarcodeImpl";
import { MBbcCodabar } from "./barbeque/MBbcCodabar";
import { MBbcCode128 } from "./barbeque/MBbcCode128";
import { MBbcCode39 } from "./barbeque/MBbcCode39";
import { MBbcEAN128 } from "./barbeque/MBbcEAN128";
import { MBbcEAN13 } from "./barbeque/MBbcEAN13";
import { MBbcPDF417 } from "./barbeque/MBbcPDF417";
import { MBbcPostnet } from "./barbeque/MBbcPostnet";
import { MBbcInt2of5 } from "./barbeque/MBbcInt2of5";
import { ICONS } from "@jss/js-common";
import { MBarbecueCustom } from "./MBarbecueCustom";
import { fromJS } from "immutable";
import { BarcodeTypes } from "../../../../common/JrxmlModel/reader/JrxmlBarcodeUtils";

export const FBarcode = {
    codeExpression: new PExpression({ id: 'codeExpression', label: 'Code Expression' }),
    evaluationTime: EvaluationTimeEnum
};

export const MBarcode: SubtypedElement = {
    type: ElementTypes.BARCODE,
    id: 'barcodeElementProperties',
    label: 'Barcode',
    default: { width: 100, height: 30, ...MQRCode.default },
    subtypes: [
        MQRCode, MCodabar, MCode128, MCode128A, MCode128B, MCode128C, MCode39, MCode39Extended, MEAN128,
        MEAN13, MEAN8, MPDF417, MPostnet, MDataMatrix, MInterleaved2Of5, MStd2of5, M2of7, M3of9, MRoyalMailCustomer, MUPCA, 
        MRandomWeightUPCA, MUPCE, MUSPSIntelligentMail, MBookland,
        MGlobalTradeItemNumber, MMonarch, MNW7, MShipmentIdentificationNumber, MSCC14ShippingCode, 
        MSSCC18, MUSPS, MUSD3, MUSD4, MUCC128
    ],
    icon: ICONS.BARCODE_ICON,
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MGraphicElement.layouts, MBarbecueCustom] }),
            new PDiv({
                label: 'Barcode', layouts: [FBarcode.codeExpression, FBarcode.evaluationTime,
                new PAccordeonCategories({
                    selectionField: 'BARCODE_TYPE',
                    getSelected(props: UiPropertyProps): APDescriptor | undefined {
                        const pac = props.mcontext.descriptor as PAccordeonCategories;
                        let p = getPath(pac.selectionField ? pac.selectionField : pac.id, props.mcontext.elements[0].path);
                        let v: string = props.mcontext.model.getIn(p) as string;
                        if (v === 'barbecue') {
                            p = getPath('barbecue_type', props.mcontext.elements[0].path);
                            v = props.mcontext.model.getIn(p) as string;
                            if (v === 'Int2of5')
                                v = 'Interleaved2Of5';
                        }
                        return pac.layouts?.find(k => {
                            return k.id.toLowerCase() === v.toLowerCase()
                        });
                    },
                    setSelected(props: UiPropertyProps, d: APDescriptor) {
                        props.mcontext.elements.forEach(key => {
                            const isBarbecue = BarcodeTypes[d.id] === undefined;
                            if (isBarbecue) {
                                props.mcontext.setObjectProperties(key.path, { [this.selectionField]: 'barbecue', barbecue_type: d.id });
                            } else {
                                props.mcontext.setObjectProperties(key.path, { [this.selectionField]: d.id });
                            }
                            Object.keys(d.default).forEach(k => {
                                if (k !== this.selectionField) {
                                    const p = getPath(k, props.mcontext.elements[0].path);
                                    const v = props.mcontext.model.getIn(p);
                                    if (!v) {
                                        const def = fromJS(d.default[k]);
                                        props.mcontext.setObjectProperties(key.path, { [k]: def });
                                    }
                                }
                            });
                        });
                    },
                    categories: [
                        MQRCode, // --- barcode4j
                        new PBarcodeImpl([MCodabar, MBbcCodabar]), // --- mixt

                        new PBarcodeImpl([MCode128, MBbcCode128]),
                        MCode128A,
                        MCode128B,
                        MCode128C,

                        new PBarcodeImpl([MCode39, MBbcCode39]),
                        MCode39Extended,

                        new PBarcodeImpl([MEAN128, MBbcEAN128]),
                        new PBarcodeImpl([MEAN13, MBbcEAN13]),
                        MEAN8, // --- barcode4j
                        new PBarcodeImpl([MPDF417, MBbcPDF417]),
                        new PBarcodeImpl([MPostnet, MBbcPostnet]),
                        MDataMatrix, // --- barcode4j

                        new PBarcodeImpl([MInterleaved2Of5, MBbcInt2of5]),
                        MStd2of5,
                        M2of7,
                        M3of9,

                        MRoyalMailCustomer, // --- barcode4j
                        MUPCA, // --- barcode4j
                        MRandomWeightUPCA,
                        MUPCE, // --- barcode4j
                        MUSPSIntelligentMail, // --- barcode4j
                        MBookland,
                        MGlobalTradeItemNumber,
                        MMonarch,
                        MNW7,
                        MShipmentIdentificationNumber,
                        MSCC14ShippingCode,
                        MSSCC18,
                        MUSPS,
                        MUSD3,
                        MUSD4,
                        MUCC128
                    ]
                })
                ]
            })
        ]
    })]
};
