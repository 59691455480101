/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const SpiderChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M8 4v142.867h254V4z" />
            </clipPath>
         </defs>
         <path fill="none" fillOpacity="0" stroke="none" strokeOpacity="0" d="M0 0H270V175H0z" clipPath="url(#clipPath1)" textRendering="optimizeLegibility" />
         <g fill="#fff" stroke="#fff" fontFamily="sans-serif" textRendering="optimizeLegibility">
            <path stroke="none" d="M38.428 151.867H231.572V174H38.428z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" d="M38.928 152.367L231.072 152.367" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" d="M38.928 173.5L231.072 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" d="M38.928 173.5L38.928 152.367" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" d="M231.072 173.5L231.072 152.367" clipPath="url(#clipPath1)" />
            <path fill="#F55" stroke="none" d="M50.428 162.934a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
         </g>
         <g fill="gray" stroke="gray" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" fontFamily="sans-serif" textRendering="optimizeLegibility">
            <path fill="none" d="M50.428 162.934a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <text x="54.428" y="167.469" fill="#000" stroke="none" clipPath="url(#clipPath1)">Series 1</text>
            <path fill="#55F" stroke="none" d="M114.143 162.934a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <path fill="none" d="M114.143 162.934a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <text x="118.143" y="167.469" fill="#000" stroke="none" clipPath="url(#clipPath1)">Series 2</text>
            <path fill="#5F5" stroke="none" d="M177.857 162.934a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <path fill="none" d="M177.857 162.934a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <text x="181.857" y="167.469" fill="#000" stroke="none" clipPath="url(#clipPath1)">Series 3</text>
            <path fill="#fff" stroke="none" d="M8 4H262V146.867H8z" clipPath="url(#clipPath1)" />
            <path fill="none" d="M8 4H262V146.867H8z" clipPath="url(#clipPath1)" />
         </g>
         <g fontFamily="sans-serif" textRendering="optimizeLegibility">
            <path fill="none" d="M135 75.434L135 21.858" clipPath="url(#clipPath2)" />
            <text x="108.533" y="16.501" stroke="none" clipPath="url(#clipPath2)" fontSize="10">Category 1</text>
            <path fill="none" d="M135 75.434L176.887 42.03" clipPath="url(#clipPath2)" />
            <text x="181.076" y="38.69" stroke="none" clipPath="url(#clipPath2)" fontSize="10">Category 2</text>
            <path fill="none" d="M135 75.434L187.232 87.355" clipPath="url(#clipPath2)" />
            <text x="192.455" y="98.215" stroke="none" clipPath="url(#clipPath2)" fontSize="10">Category 3</text>
            <path fill="none" d="M135 75.434L158.245 123.703" clipPath="url(#clipPath2)" />
            <text x="160.57" y="138.198" stroke="none" clipPath="url(#clipPath2)" fontSize="10">Category 4</text>
            <path fill="none" d="M135 75.434L111.755 123.703" clipPath="url(#clipPath2)" />
            <text x="56.495" y="138.198" stroke="none" clipPath="url(#clipPath2)" fontSize="10">Category 5</text>
            <path fill="none" d="M135 75.434L82.768 87.355" clipPath="url(#clipPath2)" />
            <text x="24.61" y="98.215" stroke="none" clipPath="url(#clipPath2)" fontSize="10">Category 6</text>
            <path fill="none" d="M135 75.434L93.113 42.03" clipPath="url(#clipPath2)" />
            <text x="35.99" y="38.69" stroke="none" clipPath="url(#clipPath2)" fontSize="10">Category 7</text>
            <ellipse cx="135" cy="69.481" fill="#F55" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
         </g>
         <g fill="gray" stroke="gray" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" fontFamily="sans-serif" fontSize="10" textRendering="optimizeLegibility">
            <ellipse cx="135" cy="69.481" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="158.27" cy="56.876" fill="#F55" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="158.27" cy="56.876" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="158.214" cy="80.732" fill="#F55" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="158.214" cy="80.732" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="142.749" cy="91.523" fill="#F55" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="142.749" cy="91.523" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="119.503" cy="107.613" fill="#F55" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="119.503" cy="107.613" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="111.786" cy="80.732" fill="#F55" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="111.786" cy="80.732" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="121.038" cy="64.299" fill="#F55" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="121.038" cy="64.299" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <path fill="none" stroke="#F55" d="M135 69L158 56 158 80 142 91 119 107 111 80 121 64z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M135 69L158 56 158 80 142 91 119 107 111 80 121 64z" clipPath="url(#clipPath2)" opacity="0.1" />
            <ellipse cx="135" cy="45.67" fill="#55F" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="135" cy="45.67" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="167.579" cy="49.453" fill="#55F" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="167.579" cy="49.453" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="181.428" cy="86.031" fill="#55F" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="181.428" cy="86.031" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="150.497" cy="107.613" fill="#55F" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="150.497" cy="107.613" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="111.755" cy="123.703" fill="#55F" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="111.755" cy="123.703" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="88.572" cy="86.031" fill="#55F" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="88.572" cy="86.031" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="102.421" cy="49.453" fill="#55F" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="102.421" cy="49.453" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <path fill="none" stroke="#55F" d="M135 45L167 49 181 86 150 107 111 123 88 86 102 49z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M135 45L167 49 181 86 150 107 111 123 88 86 102 49z" clipPath="url(#clipPath2)" opacity="0.1" />
            <ellipse cx="135" cy="45.67" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="135" cy="45.67" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="153.616" cy="60.588" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="153.616" cy="60.588" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="169.821" cy="83.381" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="169.821" cy="83.381" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="142.749" cy="91.523" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="142.749" cy="91.523" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="129.834" cy="86.16" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="129.834" cy="86.16" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="94.375" cy="84.706" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="94.375" cy="84.706" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="111.73" cy="56.876" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <ellipse cx="111.73" cy="56.876" fill="none" clipPath="url(#clipPath2)" rx="1.27" ry="0.714" />
            <path fill="none" stroke="#5F5" d="M135 45L153 60 169 83 142 91 129 86 94 84 111 56z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M135 45L153 60 169 83 142 91 129 86 94 84 111 56z" clipPath="url(#clipPath2)" opacity="0.1" />
            <path fill="none" d="M8 4H262V146.867H8z" clipPath="url(#clipPath1)" />
         </g>
      </svg>
   );
}
export default SpiderChartIcon;