/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const HighLowChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M32 4v134h230V4z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M105.918 157.188L164.082 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M105.918 173.5L164.082 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M105.918 173.5L105.918 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M164.082 173.5L164.082 157.188" clipPath="url(#clipPath1)" />
            <path fill="#F55" stroke="none" d="M109.418 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="121.418" y="168.113" stroke="none" clipPath="url(#clipPath1)">Argentina</text>
            <path fill="none" strokeWidth="0.5" d="M32 138L262 138" clipPath="url(#clipPath1)" />
            <text x="19.767" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">gen-1997</text>
            <path fill="none" strokeWidth="0.5" d="M38.925 140L38.925 138" clipPath="url(#clipPath1)" />
            <text x="71.336" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">mag-1997</text>
            <path fill="none" strokeWidth="0.5" d="M91.846 140L91.846 138" clipPath="url(#clipPath1)" />
            <text x="128.372" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">set-1997</text>
            <path fill="none" strokeWidth="0.5" d="M146.108 140L146.108 138" clipPath="url(#clipPath1)" />
            <text x="180.79" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">gen-1998</text>
            <path fill="none" strokeWidth="0.5" d="M199.948 140L199.948 138" clipPath="url(#clipPath1)" />
            <text x="232.359" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">mag-1998</text>
            <path fill="none" strokeWidth="0.5" d="M252.869 140L252.869 138" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M32 4L32 138" clipPath="url(#clipPath1)" />
            <text x="20.91" y="140.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
            <path fill="none" strokeWidth="0.5" d="M30 138L32 138" clipPath="url(#clipPath1)" />
            <text x="15.82" y="122.464" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">50</text>
            <path fill="none" strokeWidth="0.5" d="M30 119.694L32 119.694" clipPath="url(#clipPath1)" />
            <text x="10.73" y="104.158" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">100</text>
            <path fill="none" strokeWidth="0.5" d="M30 101.388L32 101.388" clipPath="url(#clipPath1)" />
            <text x="10.73" y="85.852" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">150</text>
            <path fill="none" strokeWidth="0.5" d="M30 83.083L32 83.083" clipPath="url(#clipPath1)" />
            <text x="10.73" y="67.546" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">200</text>
            <path fill="none" strokeWidth="0.5" d="M30 64.777L32 64.777" clipPath="url(#clipPath1)" />
            <text x="10.73" y="49.241" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">250</text>
            <path fill="none" strokeWidth="0.5" d="M30 46.471L32 46.471" clipPath="url(#clipPath1)" />
            <text x="10.73" y="30.935" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">300</text>
            <path fill="none" strokeWidth="0.5" d="M30 28.165L32 28.165" clipPath="url(#clipPath1)" />
            <text x="10.73" y="12.629" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">350</text>
            <path fill="none" strokeWidth="0.5" d="M30 9.86L32 9.86" clipPath="url(#clipPath1)" />
         </g>
         <g fill="none" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path d="M38.925 4L38.925 138" clipPath="url(#clipPath2)" />
            <path d="M91.846 4L91.846 138" clipPath="url(#clipPath2)" />
            <path d="M146.108 4L146.108 138" clipPath="url(#clipPath2)" />
            <path d="M199.948 4L199.948 138" clipPath="url(#clipPath2)" />
            <path d="M252.869 4L252.869 138" clipPath="url(#clipPath2)" />
            <path d="M32 138L262 138" clipPath="url(#clipPath2)" />
            <path d="M32 119.694L262 119.694" clipPath="url(#clipPath2)" />
            <path d="M32 101.388L262 101.388" clipPath="url(#clipPath2)" />
            <path d="M32 83.083L262 83.083" clipPath="url(#clipPath2)" />
            <path d="M32 64.777L262 64.777" clipPath="url(#clipPath2)" />
            <path d="M32 46.471L262 46.471" clipPath="url(#clipPath2)" />
            <path d="M32 28.165L262 28.165" clipPath="url(#clipPath2)" />
            <path d="M32 9.86L262 9.86" clipPath="url(#clipPath2)" />
         </g>
         <g fill="none" stroke="#F55" strokeLinejoin="bevel" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path d="M238.752 106.095L238.752 10.381" clipPath="url(#clipPath2)" />
            <path d="M42.455 133.631L42.455 120.526" clipPath="url(#clipPath2)" />
            <path d="M59.66 132.315L59.66 115.26" clipPath="url(#clipPath2)" />
            <path d="M90.964 135.478L90.964 127.913" clipPath="url(#clipPath2)" />
            <path d="M94.934 136.811L94.934 133.243" clipPath="url(#clipPath2)" />
            <path d="M244.928 137.536L244.928 136.143" clipPath="url(#clipPath2)" />
            <path d="M169.49 134.695L169.49 124.779" clipPath="url(#clipPath2)" />
            <path d="M193.331 137.839L193.331 137.356" clipPath="url(#clipPath2)" />
            <path d="M233.476 130.742L233.476 108.969" clipPath="url(#clipPath2)" />
            <path d="M202.595 135.106L202.595 126.425" clipPath="url(#clipPath2)" />
            <path d="M205.242 124.695L205.242 84.781" clipPath="url(#clipPath2)" />
            <path d="M251.546 137.952L251.546 137.807" clipPath="url(#clipPath2)" />
            <path d="M218.036 137.584L218.036 136.336" clipPath="url(#clipPath2)" />
            <path d="M229.947 133.386L229.947 119.542" clipPath="url(#clipPath2)" />
            <path d="M222.006 137.814L222.006 137.256" clipPath="url(#clipPath2)" />
            <path d="M225.094 128.661L225.094 100.644" clipPath="url(#clipPath2)" />
         </g>
      </svg>
   );
}
export default HighLowChartIcon;