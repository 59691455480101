/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const BreakTypeEnum = new PEnum({
    id: 'breakType', label: 'BreakType.label', default: 'Page',
    items: [
        { key: 'Page', value: 'BreakType.Page'},
        { key: 'Column', value: 'BreakType.Column'}
    ]
}); 
 
