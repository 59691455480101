/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const BarcodeInt2of5Icon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    const fillColor = props.color ? props.color : 'black';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="19.4004mm" viewBox="0 0 24.99 19.4004" width="24.99mm" {...props}><g fill={fillColor} stroke="none"><rect height="15.873" width="0.21" x="2.1" y="0"/><rect height="15.873" width="0.21" x="2.52" y="0"/><rect height="15.873" width="0.21" x="2.94" y="0"/><rect height="15.873" width="0.21" x="3.78" y="0"/><rect height="15.873" width="0.63" x="4.2" y="0"/><rect height="15.873" width="0.63" x="5.04" y="0"/><rect height="15.873" width="0.21" x="5.88" y="0"/><rect height="15.873" width="0.21" x="6.72" y="0"/><rect height="15.873" width="0.63" x="7.56" y="0"/><rect height="15.873" width="0.21" x="8.82" y="0"/><rect height="15.873" width="0.21" x="9.24" y="0"/><rect height="15.873" width="0.63" x="9.66" y="0"/><rect height="15.873" width="0.21" x="10.5" y="0"/><rect height="15.873" width="0.21" x="11.34" y="0"/><rect height="15.873" width="0.63" x="11.76" y="0"/><rect height="15.873" width="0.21" x="13.02" y="0"/><rect height="15.873" width="0.63" x="13.44" y="0"/><rect height="15.873" width="0.21" x="14.28" y="0"/><rect height="15.873" width="0.63" x="14.7" y="0"/><rect height="15.873" width="0.63" x="15.54" y="0"/><rect height="15.873" width="0.21" x="16.38" y="0"/><rect height="15.873" width="0.21" x="17.22" y="0"/><rect height="15.873" width="0.63" x="18.06" y="0"/><rect height="15.873" width="0.21" x="18.9" y="0"/><rect height="15.873" width="0.21" x="19.74" y="0"/><rect height="15.873" width="0.63" x="20.16" y="0"/><rect height="15.873" width="0.21" x="21.42" y="0"/><rect height="15.873" width="0.63" x="21.84" y="0"/><rect height="15.873" width="0.21" x="22.68" y="0"/><text fontFamily="DejaVu Sans" fontSize="3.5273" textAnchor="middle" x="12.495" y="19.1515">0123456789</text></g></svg>
    );
}
export default BarcodeInt2of5Icon;