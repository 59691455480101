/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const LineDirectionEnum = new PEnum({
    id: 'direction', label: 'LineDirection.label', default: 'TopDown',
    items: [
        { key: 'TopDown', value: 'LineDirection.TopDown'},
        { key: 'BottomUp', value: 'LineDirection.BottomUp'}
    ]
}); 
 
