/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import "regenerator-runtime/runtime";

import { en } from "./assets/locales/en/messages";
import { en as chartEn } from "./assets/locales/en/chartMessages";
import { it } from "./assets/locales/it/messages";
import { fr } from "./assets/locales/fr/messages";
import { i18n } from "@jss/js-common";

i18n.addResourceBundle('en', 'jrws', en, true);
i18n.addResourceBundle('en', 'jrws', chartEn, true);
i18n.addResourceBundle('it', 'jrws', it, true);
i18n.addResourceBundle('fr', 'jrws', fr, true);

export * from './components/report/JrxmlEditorView';
export * from './components/report/viewer/BasicReportViewer';
export * from './PaletteView';
export * from './ReportEditor';
export * from './components/report/properties/PropertiesView';
export * from './OutlineView';
export * from './types/commandIds';
export * from './components/common/JrxmlModel/reader/JrxmlReader';