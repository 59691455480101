/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../../ui/APDescriptor";

import { PExpression } from "../../../ui/primitive/UPExpression";
import { PJavaClass } from "../../../ui/primitive/UPJavaClass";

import { PString } from "../../../ui/primitive/UPString";
import { PTableElement } from "../../../ui/tables/UPTableElement";
import { BucketEnum } from "../../enums/BucketEnum";


export const FHCSeries = {
    axisLevelName: new PString({ id: 'axisLevelName', label: 'Name' }),

    labelExpression: new PExpression({ id: 'labelExpression', label: 'Label Expression' }),

    bucketExpression: new PExpression({ id: 'bucketExpression', label: 'Expression', path: 'axisLevelBucket' }),
    class: new PJavaClass({ id: 'class', label: 'Class Name', path: 'axisLevelBucket' }),

    order: BucketEnum,
    comparatorExpression: new PExpression({ id: 'comparatorExpression', label: 'Comparator Expression' }),
}

export const MHCSeries: APDescriptor = new PTableElement({
    label: 'Series',
    anchorOrigin: {vertical: 'center', horizontal: 'right'},
    transformOrigin: {vertical: 'center', horizontal: 'right'},
    layouts: [FHCSeries.axisLevelName, FHCSeries.labelExpression, FHCSeries.bucketExpression, FHCSeries.class, FHCSeries.order, FHCSeries.comparatorExpression]
});
