/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../../ui/APDescriptor";
import { MHyperlinks } from "../../../common/MHyperlinks";
import { PExpression } from "../../../../ui/primitive/UPExpression";
import { PConnectedTable } from "../../../../ui/tables/UPConnectedTable";
import { PTableElement } from "../../../../ui/tables/UPTableElement";

export const FPieSeries = {
    keyExpression: new PExpression({ id: 'keyExpression', label: 'Key Expression' }),
    labelExpression: new PExpression({ id: 'labelExpression', label: 'Label Expression' }),
    valueExpression: new PExpression({ id: 'valueExpression', label: 'Value Expression' }),
    seriesExpression: new PExpression({ id: 'seriesExpression', label: 'Series Expression' })
};

export const MPieSeries: APDescriptor = new PConnectedTable({
    id: 'pieSeries', label: 'Series', rowLabel: 'Series',  rowLabelPaths:[FPieSeries.seriesExpression.id],
    layouts: [
        new PTableElement({
            label: 'Pie Series',
            anchorOrigin: {vertical: 'center', horizontal: 'right'},
            transformOrigin: {vertical: 'center', horizontal: 'right'},
            layouts: [FPieSeries.labelExpression, FPieSeries.keyExpression, FPieSeries.valueExpression,
                FPieSeries.seriesExpression,
            {...MHyperlinks, id: 'sectionHyperlink' , label: 'Section Hyperlink'}]
        })]
});
