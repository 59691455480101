/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { APDescriptor } from './APDescriptor';
import { Map as ImmutableMap } from 'immutable';
import _ from 'lodash';
import { IRepositoryItemDescriptor } from '@jss/js-rest-api';
import i18n from '../../../../i18n';
export interface IElements {
    path: (string | number)[],
    descriptor: APDescriptor
}

export interface PropertyContext {
    rootModel: ImmutableMap<string, any>;
    model: ImmutableMap<string, any>;
    elements: IElements[];
    descriptor: APDescriptor;
    reportDescriptor: IRepositoryItemDescriptor,
    setObjectProperties?: (path: (string|number)[], value: any, refreshCache?: boolean, isWidgetProperty?: boolean) => void;
    deleteElement?: (path: (string|number)[], refreshCache?: boolean) => void;
    refreshCache?: () => void;
}

export interface UiPropertyProps {
    mcontext: PropertyContext,
}

export const getNormalizedPath = (path) => {
    const p: (string|number)[] = ['model'];
    if (path?.length > 0) {
        path.forEach(k => {
            if (Array.isArray(k) && k.every(item => typeof item === "string" || typeof item === 'number')) { p.push(...k) }
            else if (typeof k === 'string' || typeof k === 'number') { p.push(k); }
            else { p.push(k); }
        });
    }
    return p;
}


export class UiProperty extends React.Component<UiPropertyProps> {

    render() {
        if (this.props.mcontext.descriptor.description) {
            return <span title={i18n.t(this.props.mcontext.descriptor.description)} style={{ minWidth: 0 }}>{this.renderProperty()}</span>;
        }
        return this.renderProperty();
    }
    private renderProperty = () => {
        let mc = this.props.mcontext
        mc = setupPath(mc);
        let c = typemap.get(mc.descriptor.type);
        if (!c) {
            if (mc.descriptor.layouts?.length > 0) {
                return mc.descriptor.layouts.map(k => {
                    return <UiProperty key={k.id} mcontext={{ ...mc, descriptor: k }} />;
                })
            }
            return <></>;
        }
        if (!c) c = typemap.get('string');
        return c ? c(mc) : <div>no widget for value</div>;
    }
}

export function setupPath(mc): any {
    if (mc.descriptor.path) {
        mc = { ...mc, elements: setupPath4Elements(mc.elements, mc.descriptor) };
    }
    return mc;
}
export function setupPath4Elements(elements: IElements[], d: APDescriptor): IElements[] {
    if (d.path) {
        const els = _.cloneDeep(elements);
        const p = d.path.split(/\.(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/, -1).map(k => k.replace(/^"(.*)"$/, '$1'));
        els.forEach(key => {
            if (key.path?.length > 0 && Array.isArray(key.path[0])) {
                key.path[0].push(...p)
            } else {
                key.path.push(...p)
            }
        });
        return els;
    } return elements;
}

const typemap = new Map<string, any>();

export function reg(type: string, component: any): void {
    typemap.set(type, component);
}

export function getKey(path: string[]) {
    let key = 'model';
    path.forEach(p => { key += '.' + p; })
    return key;
}

export function getPath(id?: string, path?: (string | number)[]) {
    let p = [];
    if (path?.length > 0)
        if (Array.isArray(path[0])) { p = [...path[0]]; } else { p = [...path]; }
    if (id) {
        // if (p[p.length - 1] !== 'properties') {
        //     const split = id.split('.');
        //     p.push(...split);
        // } else { p.push(id) }
        p.push(...id.split('|'))
    }
    return p;
}

