/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { defaultReportState } from "../reportReducer";
import { Map } from 'immutable';
import { refactorFusion } from "./fusion";
import { refactorHighchart } from "./highcharts";
import { ElementTypes } from "../../../sagas/report/document/elementTypes";
import { refactorJFreeChart } from "./jfreechart/jfreecharts";
import { refactorDeleteGroup, refactorGroup } from "./group";

export type ImMap = Map<string, any> | null;

export const refactorElement = (state = defaultReportState, action: any, newState: ImMap): ImMap => {
    const t = state.getIn([...action.path, 'type']);
    if (t === ElementTypes.FUSIONCHART) {
        return refactorFusion(state, action, newState);
    } else if (t === ElementTypes.FUSION_MAP) {
        return refactorFusion(state, action, newState);
    } else if (t === ElementTypes.FUSION_WIDGET) {
        return refactorFusion(state, action, newState);
    } else if (t === ElementTypes.HTML5CHART_JR) {
        return refactorHighchart(state, action, newState);
    } else if (t === ElementTypes.JFREECHART) {
        return refactorJFreeChart(state, action, newState);
    } else if (t === ElementTypes.MAIN_DATASET_GROUP) {
        return refactorGroup(state, action, newState);
    }
    // add barcodes here?

    return newState;
};

export const refactorOnDelete = (state = defaultReportState, action: any, newState: ImMap): ImMap => {
    const t = state.getIn([...action.path, 'type']);
    if (t === ElementTypes.MAIN_DATASET_GROUP) {
        return refactorDeleteGroup(state, action, newState);
    }
    // add barcodes here?

    return newState;
};