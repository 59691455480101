/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { defaultReportState } from "../../reportReducer";
import { changeDatasetType } from "./datasets";
import { ImMap } from "../refactorElement";
import { changePlotType } from "./plots";


export const refactorJFreeChart = (state = defaultReportState, action: any, newState: ImMap): ImMap => {
    if (action.properties.CHART_TYPE !== undefined) {
        return changeType(state, action, newState);
    }
    // here listen for other properties changes and do some refactorings
    return newState;
};

const changeType = (state = defaultReportState, action: any, newState: ImMap): ImMap => {
    const old = state.getIn([...action.path, 'CHART_TYPE']) as string;
    const newType = action.properties.CHART_TYPE;
    if (old === newType)
        return newState;

    newState = changeDatasetType(state, action, newState, newType, old);
    newState = changePlotType(state, action, newState, newType, old);
    return newState;
}
