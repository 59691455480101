/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { all } from 'redux-saga/effects';
import { fetchResourceSaga } from '../sagas/report/designer/modelSaga';
import { sagas } from '../sagas';

/**
 * This generators allow to start the listening to all the events of our application.
 */
export function* rootSaga() {
  yield all([...sagas]);
  yield fetchResourceSaga();
}