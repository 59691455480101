/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { defaultReportState } from "../../reportReducer";
import { MAreaChart, MBar3DChart, MBarChart, MBubbleChart, MCandleStickChart, MGanttChart, MHighLowChart, MLineChart, MMeterChart, MMultiAxisChart, MPie3DChart, MPieChart, MScatterChart, MSpiderChart, MStackedAreaChart, MStackedBar3DChart, MstackedBarChart, MThermometerChart, MTimeSeriesChart, MXYAreaChart, MXYBarChart, MXYLineChart } from "../../../../components/report/properties/types/chart/MChart";

import { ImMap } from "../refactorElement";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const changePlotType = (state = defaultReportState, action: any, newState: ImMap, newType: string, oldType: string): ImMap => {

    const oldPlot = ''; // here get the name of the old plot

    switch (newType) {
        case MAreaChart.id:
            newState = mergeAreaPlot(oldPlot, state, action, newState);
            break;
        case MStackedAreaChart.id:
        case MXYAreaChart.id:
        case MBarChart.id:
        case MBar3DChart.id:
        case MXYBarChart.id:
        case MstackedBarChart.id:
        case MStackedBar3DChart.id:
        case MBubbleChart.id:
        case MCandleStickChart.id:
        case MGanttChart.id:
        case MHighLowChart.id:
        case MLineChart.id:
        case MXYLineChart.id:
        case MMeterChart.id:
        case MMultiAxisChart.id:
        case MPieChart.id:
        case MPie3DChart.id:
        case MScatterChart.id:
        case MThermometerChart.id:
        case MTimeSeriesChart.id:
        case MSpiderChart.id:
    }
    return newState;
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mergeAreaPlot = (oldPlot: string, state = defaultReportState, action: any, newState: ImMap): ImMap => {
    // delete the old plot
    return newState;
}