/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const AxisPositionEnum = new PEnum({
    id: 'position', label: 'AxisPosition.label', isEmptyUndefined: true,
    items: [
        { key: '', value: '' },
        { key: 'leftOrTop', value: 'AxisPosition.leftOrTop' },
        { key: 'rightOrBottom', value: 'AxisPosition.rightOrBottom' }
    ]
});

