/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../../ui/APDescriptor";
import { TimePeriodEnum } from "../../enums/TimePeriodEnum";
import { MChartDataset } from "./MChartDataset";

export const FTimeSeriesDataset = {
    timePeriod: TimePeriodEnum
};

export const MTimeSeriesDataset: APDescriptor = {
    type: 'MTimeSeriesDataset', id: 'timeSeriesDataset', path: 'timeSeriesDataset.dataset',
    layouts: [...MChartDataset.layouts, FTimeSeriesDataset.timePeriod]
};
