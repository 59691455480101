/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { MChartPlot } from "./MChartPlot";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { MCategoryAxis, MValueAxis } from "./MAxis";

export const FCandlestickPlot = {
    isShowVolume: new PBoolean({ id: 'isShowVolume', label: 'Show Volume', canBeNull: true }),
};

export const MCandlestickPlot: APDescriptor = {
    type: 'MCandlestickPlot',
    path: 'candlestickPlot',
    id: 'candleStickPlotProeprtiesDescriptor',
    layouts: [...MChartPlot.layouts, FCandlestickPlot.isShowVolume,
        MCategoryAxis, MValueAxis
    ]
};