/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StackedColumnLineChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath id="highcharts-sckhxv8-1">
          <path fill="none" d="M0 0H436V672H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M56 10H492V682H56z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" d="M91.5 10v672M128.5 10v672M164.5 10v672M200.5 10v672M237.5 10v672M273.5 10v672M309.5 10v672M346.5 10v672M382.5 10v672M418.5 10v672M455.5 10v672M491.5 10v672M55.5 10v672" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M56 682.5h436M56 615.5h436M56 548.5h436M56 480.5h436M56 413.5h436M56 346.5h436M56 279.5h436M56 212.5h436M56 144.5h436M56 77.5h436M56 9.5h436" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M56 682.5h436M56 615.5h436M56 548.5h436M56 480.5h436M56 413.5h436M56 346.5h436M56 279.5h436M56 212.5h436M56 144.5h436M56 77.5h436M56 9.5h436" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M56 10H492V682H56z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" stroke="#ccd6eb" d="M91.5 682v10M128.5 682v10M164.5 682v10M200.5 682v10M237.5 682v10M273.5 682v10M309.5 682v10M346.5 682v10M382.5 682v10M418.5 682v10M455.5 682v10M492.5 682v10M55.5 682v10" className="highcharts-tick" />
       <path fill="none" stroke="#ccd6eb" d="M56 682.5h436" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M56 10v672" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <text x="530.922" y="346" fill="#666" className="highcharts-axis-title" color="#666" data-z-index="7" textAnchor="middle" transform="rotate(90 530.922 346)">
          <tspan>Orders</tspan>
       </text>
       <path fill="none" d="M492 10v672" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-column-series highcharts-color-0 highcharts-tracker" clipPath="url(#highcharts-sckhxv8-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="#7cb5ec" stroke="#fff" d="M8.5 434.5H26.5V612.5H8.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M45.5 568.5H63.5V650.5H45.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M81.5 41.5H99.5V602.5H81.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M117.5 653.5H135.5V664.5H117.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M154.5 645.5H172.5V663.5H154.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M190.5 523.5H208.5V612.5H190.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M226.5 663.5H244.5V669.5H226.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M263.5 609.5H281.5V656.5H263.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M299.5 623.5H317.5V652.5H299.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M335.5 647.5H353.5V664.5H335.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M372.5 507.5H390.5V621.5H372.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M408.5 669.5H426.5V671.5H408.5z" className="highcharts-point highcharts-color-0" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-column-series highcharts-color-1 highcharts-tracker" clipPath="url(#highcharts-sckhxv8-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="#434348" stroke="#fff" d="M8.5 612.5H26.5V672.5H8.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M45.5 650.5H63.5V672.5H45.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M81.5 602.5H99.5V672.5H81.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M117.5 664.5H135.5V672.5H117.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M154.5 663.5H172.5V672.5H154.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M190.5 612.5H208.5V672.5H190.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M226.5 669.5H244.5V672.5H226.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M263.5 656.5H281.5V672.5H263.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M299.5 652.5H317.5V672.5H299.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M335.5 664.5H353.5V672.5H335.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M372.5 621.5H390.5V672.5H372.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M408.5 671.5H426.5V672.5H408.5z" className="highcharts-point highcharts-color-1" />
       </g>
       <g className="highcharts-series highcharts-series-2 highcharts-line-series highcharts-color-2" clipPath="url(#highcharts-sckhxv8-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="none" stroke="#90ed7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.167 386.4L54.5 504l36.333-352.8 36.334 470.4L163.5 588l36.333-67.2 36.334 100.8L272.5 520.8l36.333-50.4 36.334 134.4 36.333-168 36.333 201.6" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M8.167 386.4h10L54.5 504l36.333-352.8 36.334 470.4L163.5 588l36.333-67.2 36.334 100.8L272.5 520.8l36.333-50.4 36.334 134.4 36.333-168 36.333 201.6h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-2 highcharts-line-series highcharts-color-2 highcharts-tracker" data-z-index="0.1">
          <path fill="#90ed7d" d="M22 386a4 4 0 110-.004zM58 504a4 4 0 110-.004zM94 151a4 4 0 110-.004zM131 622a4 4 0 110-.004zM167 588a4 4 0 110-.004zM203 521a4 4 0 110-.004z" className="highcharts-point highcharts-color-2" transform="translate(56 10)" />
          <path fill="#90ed7d" strokeWidth="0" d="M240 621.6a4 4 0 110-.004zM276 520.8a4 4 0 110-.004zM312 470.4a4 4 0 110-.004z" className="highcharts-point highcharts-color-2" transform="translate(56 10)" />
          <path fill="#90ed7d" d="M349 604.8a4 4 0 110-.004z" className="highcharts-point highcharts-color-2" transform="translate(56 10)" />
          <path fill="#90ed7d" strokeWidth="0" d="M385 436.8a4 4 0 110-.004z" className="highcharts-point highcharts-color-2" transform="translate(56 10)" />
          <path fill="#90ed7d" d="M421 638.4a4 4 0 110-.004z" className="highcharts-point highcharts-color-2" transform="translate(56 10)" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(128 762)">
       <rect width="299" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-column-series highcharts-color-0 highcharts-series-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Total Freight</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-column-series highcharts-color-1 highcharts-series-1" data-z-index="1" transform="translate(123.672 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Max Freight</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-line-series highcharts-color-2 highcharts-series-2" data-z-index="1">
             <path fill="none" stroke="#90ed7d" strokeWidth="2" d="M0 11h12" className="highcharts-graph" transform="translate(232.61 3)" />
             <path fill="#90ed7d" d="M10 11a4 4 0 110-.004z" className="highcharts-point" transform="translate(232.61 3)" />
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold" transform="translate(232.61 3)">
                <tspan>Orders</tspan>
             </text>
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="76.759" y="698" transform="rotate(-45 76.76 698)">Albuquerque</text>
       <text x="113.093" y="698" transform="rotate(-45 113.093 698)">Anchorage</text>
       <text x="149.426" y="698" transform="rotate(-45 149.426 698)">Boise</text>
       <text x="185.759" y="698" transform="rotate(-45 185.76 698)">Butte</text>
       <text x="222.093" y="698" transform="rotate(-45 222.093 698)">Elgin</text>
       <text x="258.426" y="698" transform="rotate(-45 258.426 698)">Eugene</text>
       <text x="294.759" y="698" transform="rotate(-45 294.76 698)">Kirkland</text>
       <text x="331.093" y="698" transform="rotate(-45 331.093 698)">Lander</text>
       <text x="367.426" y="698" transform="rotate(-45 367.426 698)">Portland</text>
       <text x="403.759" y="698" transform="rotate(-45 403.76 698)">
          <tspan>San Francisco</tspan>
       </text>
       <text x="440.093" y="698" transform="rotate(-45 440.093 698)">Seattle</text>
       <text x="476.426" y="698" transform="rotate(-45 476.426 698)">
          <tspan>Walla Walla</tspan>
       </text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="41" y="687">0</text>
       <text x="41" y="619">800</text>
       <text x="41" y="552">1.600</text>
       <text x="41" y="485">2.400</text>
       <text x="41" y="418">3.200</text>
       <text x="41" y="351">4.000</text>
       <text x="41" y="283">4.800</text>
       <text x="41" y="216">5.600</text>
       <text x="41" y="149">6.400</text>
       <text x="41" y="82">7.200</text>
       <text x="41" y="15">8.000</text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11">
       <text x="507" y="687">0</text>
       <text x="507" y="619">4</text>
       <text x="507" y="552">8</text>
       <text x="507" y="485">12</text>
       <text x="507" y="418">16</text>
       <text x="507" y="351">20</text>
       <text x="507" y="283">24</text>
       <text x="507" y="216">28</text>
       <text x="507" y="149">32</text>
       <text x="507" y="82">36</text>
       <text x="507" y="15">40</text>
    </g>
 </svg>)};

export default StackedColumnLineChartIcon;