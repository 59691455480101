/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import TreeItem from '@material-ui/lab/TreeItem';
import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { SelectItem, IMenuItem } from '@jss/js-common';
import '../../../assets/uxpl/css/Outline.css';
import { getActionsByType } from '../../../sagas/report/document/elementActions';
import { getModel } from '../properties/types/TypeFactory';
import { LabelComponent } from './LabelComponent';
import { BandTypes } from '../../../sagas/report/document/elementTypes';

interface IOutlineElement {
    id: string;
    type: string; // Type of the element
    bandType?: string;
    label: string;
    isSelected: boolean;
    path: string[];
    isEmpty?: boolean,
    data?: { [key: string]: any }
    onClick: (e: React.MouseEvent<HTMLElement>, id: string, path: string[], type: string, allowRightClick?: boolean) => void;
    onDoubleClick: (e: React.MouseEvent<HTMLElement>, id: string, path: string[], type: string, allowRightClick?: boolean) => void;
    contextualActionSelected: (actionId: string, targetElementId: string, targetElementType: string, targetElementPath: string[], data? : { [key: string]: any }) => void,
}

interface IState {
    anchorEl: null | EventTarget
}

class OutlineElement extends React.Component<IOutlineElement, IState> {

    state = {
        anchorEl: null,
    }

    lastClick = undefined;

    private setAnchorEl = (newAnchorEl: null | EventTarget) => {
        this.setState({
            anchorEl: newAnchorEl,
        });
    }

    private preventExpansion = (event: React.MouseEvent<HTMLElement>) => {
        this.setAnchorEl(null);
        if (this.lastClick && event.timeStamp - this.lastClick <= 250) {
            //double click
            this.props.onDoubleClick(event, this.props.id, this.props.path, this.props.type);
        } else {
            this.props.onClick(event, this.props.id, this.props.path, this.props.type);
            //preventing the default prevent also the node expansion, the selection will 
            //be handled by the event on the TreeView
            event.preventDefault();
        }
        this.lastClick = event.timeStamp;
    };

    private onContextMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.onClick(event, this.props.id, this.props.path, this.props.type, true);
        this.setAnchorEl(event.currentTarget);
    }

    private getTreeLabel = () => {
        if (this.props.label.length > 30) {
            return this.props.label.substring(0, 30) + '...';
        }
        return this.props.label;
    }

    private getLabelComponent = (content: IMenuItem[]) => {
        const model = getModel(this.props.type);
        const hoverActions = content.map((action) => {
            if (action.hooverAction){
                const isEnabled = action.isEnabled === undefined || action.isEnabled;
                return <div key={action.id} title={action.label} style={{color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (isEnabled) {
                        this.props.contextualActionSelected(action.id, this.props.id, this.props.type, this.props.path, this.props.data);
                    }
                }}>
                    {action.icon}
                </div>;
            }
            return null;
        })
        return <LabelComponent label={this.getTreeLabel()} model={model} tooltip={this.props.label} hoverActions={hoverActions}/>
    }

    public render() {
        const id = this.props.id;
        const children = this.props.children;
        const labelClass = ['jr-mTree-item', 'mui'];
        if (this.props.isSelected) {
            labelClass.push('tc-jsw-report-outline-label-selected');
        }
        if (this.props.isEmpty) {
            labelClass.push('tc-jsw-report-outline-label-empty');
        }
        const open = Boolean(this.state.anchorEl);
        let content: IMenuItem[];
        if (this.props.bandType === BandTypes.BAND_DETAIL || this.props.bandType === BandTypes.BAND_GROUP_HEADER || this.props.bandType === BandTypes.BAND_GROUP_FOOTER){
            content = getActionsByType(this.props.bandType);
        } else {
            content = getActionsByType(this.props.type);
        }
    
        const handleClose = () => {
            this.setAnchorEl(null);
        };
        const labelComponent = this.getLabelComponent(content);
        return (<div>
            <TreeItem aria-describedby={id} classes={{ label: labelClass.join(' ') }} onLabelClick={this.preventExpansion} onContextMenu={this.onContextMenu} key={id} nodeId={id} label={labelComponent}>
                {children}
            </TreeItem>
            <Menu
                BackdropProps={{
                    invisible: true,
                    onContextMenu: (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleClose();
                    }
                }}
                id={this.props.id}
                anchorEl={this.state.anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        minHeight: 45,
                        transform: 'translateY(17px)'
                    }
                }}
            >
                {content.map((element: IMenuItem) => {
                    if (!element.hooverAction){
                        const isEnabled = element.isEnabled === undefined || element.isEnabled;
                        if (element.getComponent) {
                            return element.getComponent(handleClose);
                        } else if (element === null) {
                            return <Divider key={element.id} />;
                        } else {
                            let icon;
                            if (element.icon) {
                                icon = (
                                    <ListItemIcon>
                                        {element.icon}
                                    </ListItemIcon>
                                );
                            }
                            return (
                                <SelectItem key={element.id} selected={false} onClick={() => {
                                    if (isEnabled) {
                                        handleClose();
                                        this.props.contextualActionSelected(element.id, this.props.id, this.props.type, this.props.path, this.props.data);
                                    }
                                }}>
                                    {icon}
                                    <ListItemText disableTypography={true}
                                        primary={<Typography style={{ color: isEnabled ? undefined : 'lightgray' }}>{element.label}</Typography>} />
                                </SelectItem>
                            );
                        }
                    }
                })}
            </Menu>
        </div>
        );
    }
}

export default OutlineElement;