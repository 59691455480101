/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { AbstractTextualEditor, IAbstractTextualEditor } from './AbstractTextualEditor';
import AceEditor, { IAceEditorProps } from 'react-ace';
import { RunContext } from '@jss/js-repository/src/RunContainer';
import SimplyBeautiful from 'simply-beautiful';


export default class InternalJSONEditor extends AbstractTextualEditor {

    constructor(props: IAbstractTextualEditor) {
        super(props);
    }

    public getAcceptedFileTypes() {
        return ".json"
    }

    public getDefaultExtension() {
        return 'json';
    }

    public getTextEditor(editorProps: IAceEditorProps){
        const props = {
            ...editorProps,
            mode: 'json',
            wrapEnabled: true,
            enableBasicAutocompletion: true,
            tabSize: 2,
            theme: 'textmate',
        };
        return <AceEditor {...props} ref={this.editor}/>;
    }

    public formatContent(content: string): string { 
        try {
            const options = {
                indent_size: 2,
            };
            const result = SimplyBeautiful.json(content, options);
            return result;
        } catch (error) {
            return content;
        }
    }

    public hasFomatter = () => {
        return true;
    }
}

InternalJSONEditor.contextType = RunContext;
