/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { FormControlledTextField } from '@jss/js-common';
import { UiPropertyProps, reg, getKey, getPath } from '../UiProperty';
import { APDescriptor } from '../APDescriptor';
import i18n from '../../../../../i18n';

const TYPEID = 'resourcepath';
reg(TYPEID, (mc) => { return <UPResourcePath mcontext={mc} />; });
export class PResourcePath extends APDescriptor {
    public constructor(init: Partial<PResourcePath>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

export class UPResourcePath extends React.Component<UiPropertyProps> {

    render() {
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const descriptor = this.props.mcontext.descriptor as PResourcePath;
        const v = this.props.mcontext.model.getIn(p) as string | undefined;
        return <FormControlledTextField
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            key={getKey(p)}
            onTextChange={this.onResourcePathChange}
            label={i18n.t(this.props.mcontext.descriptor.label)}
            // defaultValue={this.props.placeholder}
            inline={true}
            size={'small'}
            value={v}
            disabled={this.props.mcontext.descriptor.readonly}
            InputLabelProps={descriptor.deprecated ? {className: 'deprecatedProperty'} : undefined}
            InputProps={{title: v}}
            type={'text'}
        />
    }
    public onResourcePathChange = (value: string | undefined) => {
        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: value });
        });
    }

}
