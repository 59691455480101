/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React, { forwardRef } from 'react';
import { AccordionDetails as MuiAccordionDetails, AccordionDetailsProps as MuiAccordionDetailsProps } from '@material-ui/core';

export type AccordionDetailsProps = MuiAccordionDetailsProps;

// eslint-disable-next-line react/display-name
export const AccordionDetails = forwardRef<unknown, MuiAccordionDetailsProps>(({
    className = '', children, ...rest
}, ref) => {

    return (
        <MuiAccordionDetails ref={ref} className={`jr-mAccordion-body mui ${className}`} {...rest}>{children}</MuiAccordionDetails>
    )
})
