/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { getNormalizedPath, reg } from '../../ui/UiProperty';
import { PJavaClass, UPJavaClass } from './UPJavaClass';
import { connect } from 'react-redux';
import * as ReportActions from '../../../../../actions/reportActions';

interface IUPParameterClass {
    setParameterValue?: (parameterName: string, value: any) => void;
}

class UPParameterClass extends UPJavaClass<IUPParameterClass> {

    public onWritableNameChange = (selectedKey: string | undefined, selectedText: string) => {
        const d = this.props.mcontext.descriptor as PJavaClass;
        
        this.props.mcontext.elements.forEach(key => {
           // const p = getPath(d.id, key.path);
           // const v = this.props.mcontext.model.getIn(p) as string | undefined;
    
            if ((!selectedKey && !selectedText) || (!selectedKey && selectedText.trim().length === 0)) {
                const path = getNormalizedPath(key.path);
                path.push(this.props.mcontext.descriptor.id);
                this.props.mcontext.deleteElement(path, d.refreshCacheOnDelete);
            } else {
                //when the value change with the combo check the refresh cache
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: selectedKey ? selectedKey : selectedText }, selectedKey !== undefined && d.refreshCacheOnAdd);
                if (selectedText !== 'java.lang.String'){
                    const path = getNormalizedPath(key.path);
                    path.push('name');
                    const parameterName = this.props.mcontext.rootModel.getIn(path) as string | undefined;
                    this.props.setParameterValue(parameterName, undefined);
                }
            }
        });
    }

}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setParameterValue: (parameterName: string, value: any) => { dispatch(ReportActions.setParameterValue(parameterName, value)); },
    };
}

const ConnectedParameterClass = connect(undefined, mapDispatchToProps)(UPParameterClass);

const TYPEID = 'parameterJavaClass';
reg(TYPEID, (mc) => { return <ConnectedParameterClass mcontext={mc} />; });
export class PParameterClass extends PJavaClass {
    public constructor(init: Partial<PParameterClass>) {
        super(init);
        Object.assign(this, { ...init, type: TYPEID });
    }
}