/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MLocation, MSize } from "../common/MGraphicElement";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { ICONS } from "@jss/js-common";
import { MGenericElementCustom } from "./MGenericElementCustom";
import { MColor } from "../common/MColor";

export const MUnknownElement: APDescriptor = {
    type: ElementTypes.COMPONENT_ELEMENT_GENERIC,
    label: 'Unknown Element',
    id: 'unknownElementPrpertiesDescriptor',
    icon: ICONS.GENERIC_ICON,
    default: { width: 100, height: 30 },
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [MLocation, MSize, ...MColor.layouts, MGenericElementCustom] }),
        ]
    })]
};
