/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { TextInput, EditableTable, WritableCombo, TIMEZONES, LOCALES, Icon, ENCODINGS } from '@jss/js-common';
import '../../assets/css/DataAdapterTypePicker.css';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import i18n from '../../i18n';
import { HTTPConfigurationPanel } from '../HTTPConfigurationPanel';
import { ResourcePickerDialog, RunContext } from '@jss/js-repository';
import { getRealName, IRepositoryItemDescriptor, MIME_TYPES, RepositoryApi } from '@jss/js-rest-api';
import { IconButton, InputAdornment } from '@material-ui/core';
import { advancedNameValidator, advancedUrlValidator } from '@jss/js-common/src/utils/validators';

interface ICSVDataAdapterEditor {
    name?: string,
    location?: string | null,
    fieldDelimiter?: string,
    recordDelimiter?: string,
    columnNames?: string[] | string,
    datePattern?: string,
    numberPattern?: string,
    locale?: string,
    timeZone?: string,
    encoding?: string,
    useFirstRowAsHeader?: boolean,
    queryExecuterMode?: boolean,
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

const ALTERED_LOCALES = LOCALES.map((locale) => { 
    if (locale.key === 'en'){
        return {key: locale.key + '_EN', value: locale.value}
    }
    return locale; 
});

export class CSVDataAdapterEditor extends React.Component<ICSVDataAdapterEditor> {

    private getSeparatorItems = () => {
        return [
            {
                key: '\n',
                value: i18n.t('datasource.csv.delimiter.lf')
            },
            {
                key: '\r',
                value: i18n.t('datasource.csv.delimiter.cr')
            },
            {
                key: '\r\n',
                value: i18n.t('datasource.csv.delimiter.crlf')
            },
            {
                key: '\t',
                value: i18n.t('datasource.csv.delimiter.tab')
            },
            {
                key: ';',
                value: i18n.t('datasource.csv.delimiter.semicolon')
            },
            {
                key: ',',
                value: i18n.t('datasource.csv.delimiter.comma')
            },
            {
                key: ' ',
                value: i18n.t('datasource.csv.delimiter.space')
            },
        ];
    }

    private getColumnNames = () => {
        if (this.props.columnNames) {
            if (Array.isArray(this.props.columnNames)) {
                return this.props.columnNames;
            } else {
                return [this.props.columnNames];
            }
        }
        return [];
    }

    private getRows = () => {
        const result: string[][] = [];
        const rows = [...this.getColumnNames()];
        rows.forEach((columnName: string) => {
            result.push([columnName]);
        });
        return result;
    }

    private onCellChange = (row: number, col: number, value: string, isEditing: boolean) => {
        const rows = this.props.columnNames ? [...this.props.columnNames] : [];
        if (value.trim().length === 0 && !isEditing) {
            rows.splice(row, 1);
        } else {
            rows[row] = value;
        }
        if (!isEditing) {
            this.notifyChange({ columnNames: rows });
        }
    }

    private getColumnSize = () => {
        //const width = document.getElementById('tableContainer')?.clientWidth;
        //if (width){
        //  return [width];
        //}
        //return undefined;
        return [400];
    }

    private onRowDelete = (rowIndex: number) => {
        const newColumns = [...this.props.columnNames];
        newColumns.splice(rowIndex, 1);
        this.notifyChange({ columnNames: newColumns });
    }

    private onAddAction = () => {
        let index = 1;
        let found = false;
        const rows = this.props.columnNames ? [...this.props.columnNames] : [];
        while (!found) {
            const newName = 'COLUMN_' + index;
            const elementIndex = rows.findIndex((value: string) => {
                const currentName = value.toUpperCase().trim();
                return currentName === newName;
            });
            if (elementIndex === -1) {
                found = true;
            } else {
                index += 1;
            }
        }
        rows.push('COLUMN_' + index);
        this.notifyChange({ columnNames: rows });
    }

    getSearchIcon = () => {
        if (!this.props.readOnly) {
            return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 2.5 }}>
                <Icon icon="search" onClick={this.openBrowseDialog} />
            </div>;
        }
        return null;
    }

    public openBrowseDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = [];
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('datasource.common.dialog.resource'),
                mode: 'open',
                title: i18n.t('datasource.common.dialog.title'),
                onFileSelected: this.confirmFile,
                defaultPath: defaultPath,
                allowSearch: true,
                allowedMimes: [MIME_TYPES.CSV, 'csv'],
            }
        );
    }

    private confirmFile = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => {
        const path = folderPath.length > 0 ? `${RepositoryApi.inst().getParentPath(folderPath)}/${getRealName(file)}` : `/${getRealName(file)}`;
        this.onFileUrlChange(path);
    }

    public render() {
        const tableRows = this.getRows();
        let httpConfigurationPanel;
        let searchIconAdornament;
        if (this.props.location && (this.props.location.startsWith('http://') || this.props.location.startsWith('https://'))) {
            httpConfigurationPanel = <HTTPConfigurationPanel {...this.props} />
        } else {
            searchIconAdornament = <InputAdornment position="end">
                <IconButton
                    edge="end"
                    style={{ padding: 0 }}
                >
                    {this.getSearchIcon()}
                </IconButton>
            </InputAdornment>;
        }
        const separatorItems = this.getSeparatorItems();
        return (<div className="tc-jsw-dataadapter-editor">

            <div className="tc-jsw-form-wrapper" style={{ flex: 1, alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}>

                <TextInput label={i18n.t('datasource.csv.name')}
                    help={i18n.t('datasource.csv.name.help')}
                    id="data-adapter-name"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly,
                    }}
                />

                <TextInput label={i18n.t('datasource.csv.url')}
                    help={i18n.t('datasource.csv.url.help')}
                    id="csv-url-input"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.location}
                    onChange={this.onFileUrlChange}
                    advancedValidator={advancedUrlValidator}
                    InputProps={{
                        endAdornment: !this.props.readOnly ? searchIconAdornament : undefined,
                        readOnly: this.props.readOnly,
                    }} />


                {httpConfigurationPanel}

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.props.queryExecuterMode}
                            onChange={this.onQueryExecuterMode}
                            name="queryExecuterMode"
                            color="primary"
                            disabled={this.props.readOnly}
                        />
                    }
                    title={i18n.t('datasource.csv.queryExecuterMode.help')}
                    label={i18n.t('datasource.csv.queryExecuterMode')}
                />

                <WritableCombo label={i18n.t('datasource.csv.separator')}
                    id="separator-input"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.fieldDelimiter}
                    items={separatorItems}
                    onComboChange={this.onFieldSeparatorChange}
                    disabled={this.props.readOnly}
                    InputProps={{
                        title: i18n.t('datasource.csv.separator.help'),
                    }}/>

                <WritableCombo label={i18n.t('datasource.csv.delimiter')}
                    id="delimiter-input"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.recordDelimiter}
                    items={separatorItems}
                    onComboChange={this.onRecordSeparatorChange}                     
                    disabled={this.props.readOnly}
                    InputProps={{
                        title: i18n.t('datasource.csv.delimiter.help'),
                    }}/>

                <TextInput label={i18n.t('datasource.csv.datepattern')}
                    help={i18n.t('datasource.csv.datepattern.help')}
                    id="csv-date-pattern"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.datePattern}
                    onChange={this.onDatePatternChange}
                    InputProps={{
                        readOnly: this.props.readOnly,
                        title: i18n.t('datasource.csv.descriptor.datepattern'),
                    }}/>

                <TextInput label={i18n.t('datasource.csv.numberpattern')}
                    help={i18n.t('datasource.csv.numberpattern.help')}
                    id="csv-number-pattern"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.numberPattern}
                    onChange={this.onNumberPatternChange}          
                    InputProps={{
                        readOnly: this.props.readOnly,
                        title: i18n.t('datasource.csv.descriptor.numberpattern'),
                    }} />

                <WritableCombo label={i18n.t('datasource.csv.timezone')}
                    id="csv-timezone"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.timeZone}
                    items={TIMEZONES.map((timezone) => { return { key: timezone.id, value: `${timezone.id} (${timezone.label})` } })}
                    onComboChange={this.onTimezoneChange}
                    disabled={this.props.readOnly}
                    InputProps={{
                        title: i18n.t('datasource.csv.timezone.help'),
                    }} />

                <WritableCombo label={i18n.t('datasource.csv.locale')}
                    id="csv-locale"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    items={ALTERED_LOCALES}
                    value={this.props.locale}
                    onComboChange={this.onLocalChange} 
                    disabled={this.props.readOnly}
                    InputProps={{
                        title: i18n.t('datasource.csv.locale.help'),
                    }} />

                <WritableCombo label={i18n.t('datasource.csv.encoding')}
                    id="encoding-input"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.encoding}
                    items={ENCODINGS.map((encoding) => { return { key: encoding, value: encoding } })}
                    onComboChange={this.onEncodingChange}
                    disabled={this.props.readOnly}
                    InputProps={{
                        title: i18n.t('datasource.csv.encoding.help'),
                    }} />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.props.useFirstRowAsHeader}
                            onChange={this.onSkipFirstRowChange}
                            name="useFirstLineAsHeader"
                            color="primary"
                            disabled={this.props.readOnly}
                        />
                    }
                    title={i18n.t('datasource.csv.useFirstLineAsHeader.help')}
                    label={i18n.t('datasource.csv.useFirstLineAsHeader')}
                />

                <div style={{ display: 'flex', alignItems: 'stretch' }} id="tableContainer">
                    <EditableTable cellStyle={{ textAlign: 'left' }} onCellChange={this.onCellChange} cellData={tableRows} numRows={this.props.columnNames ? this.props.columnNames.length + 1 : 1} columnNames={[i18n.t('datasource.csv.columnName'), '']}
                        columnActions={[{ onClick: this.onRowDelete, icon: <DeleteIcon /> }]} onAddAction={this.onAddAction} readOnly={this.props.readOnly}/>
                </div>
            </div>
        </div>);
    }



    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private onFileUrlChange = (str: string) => {
        this.notifyChange({ location: str });
    }

    private onFieldSeparatorChange = (selectedKey: string | undefined, value: string) => {
        const comboValue = selectedKey ? selectedKey : value;
        this.notifyChange({ fieldDelimiter: comboValue });
    }

    private onEncodingChange = (selectedKey: string | undefined, value: string) => {
        const comboValue = selectedKey ? selectedKey : value;
        this.notifyChange({ encoding: comboValue });
    }

    private onRecordSeparatorChange = (selectedKey: string | undefined, value: string) => {
        const comboValue = selectedKey ? selectedKey : value;
        this.notifyChange({ recordDelimiter: comboValue });
    }

    private onDatePatternChange = (str: string) => {
        this.notifyChange({ datePattern: str });
    }

    private onNumberPatternChange = (str: string) => {
        this.notifyChange({ numberPattern: str });
    }

    private onLocalChange = (selectedKey: string | undefined, value: string) => {
        this.notifyChange({ locale: selectedKey ? selectedKey : value });
    }

    private onTimezoneChange = (selectedKey: string | undefined, value: string) => {
        this.notifyChange({ timeZone: selectedKey ? selectedKey : value });
    }

    private onSkipFirstRowChange = (event: React.FormEvent<HTMLElement>) => {
        this.notifyChange({ useFirstRowAsHeader: (event.target as HTMLInputElement).checked });
    }

    private onQueryExecuterMode = (event: React.FormEvent<HTMLElement>) => {
        this.notifyChange({ queryExecuterMode: (event.target as HTMLInputElement).checked });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }
}

CSVDataAdapterEditor.contextType = RunContext;