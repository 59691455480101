/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { MBarbecue } from "./MBarbecue";

export const MBbcInt2of5: APDescriptor = {
    ...MBarbecue, id: 'Int2of5', label: 'Int2of5', icon: ICONS.BARCODE_INT2OF5_ICON,
    default: {
        ...MBarbecue.default, barbecue_type: 'Int2of5',
        width: 30, height: 30
    }
};
