/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { SplitTypeEnum } from "../enums/SplitTypeEnum";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PInt } from "../../ui/primitive/UPInteger";
import { PropertyContext } from "../../ui/UiProperty";
import { MBandCustom } from "./MBandCustom";
import i18n from "../../../../../i18n";
import { ValidationResult, VALIDATION_RESULT } from "@jss/js-common/src/utils/validators";

const bandHeightValidator = (value: number | undefined, propertyContext: PropertyContext): string | undefined | ValidationResult => {
    const desciprtor = propertyContext.descriptor as PInt;
    if (!value && desciprtor.canBeNull) {
        return undefined;
    }
    if (value === undefined && !desciprtor.canBeNull) {
        return i18n.t('MBand.property.error.emptyHeight');
    }
    if (isNaN(value)) {
        return i18n.t('MBand.property.error.notANumber');
    }
    if (desciprtor.max !== undefined && value > desciprtor.max) {
         return {result: VALIDATION_RESULT.PARTIALLY_INVALID, message: i18n.t('MBand.property.error.maximumExceeded')};
    }
    if (desciprtor.min !== undefined && value < desciprtor.min) {
        return {result: VALIDATION_RESULT.PARTIALLY_INVALID, message: i18n.t('MBand.property.error.minimumExceeded')};
    }
    const bands = propertyContext.model.get('bands') as Map<string, any>;
    const docHeight = propertyContext.model.get('docHeight');
    let totalHeight = 0;
    const currentBandId = propertyContext.elements[0].path[1];
    bands.forEach((bandModel: Map<string, any>, key: string) => {
        const bandHeight = bandModel.get('height');
        if (key !== currentBandId){
            totalHeight += bandHeight;
        }
    });
    totalHeight += value;
    if (totalHeight > docHeight){
        return {result: VALIDATION_RESULT.PARTIALLY_INVALID, message: i18n.t('MBand.property.error.documentHeightExceeded')};
    }
    
    return undefined;
}

export const FBand = {
    height: new PInt({ id: 'height', label: 'MBand.property.height.label', min: 0, validator: bandHeightValidator }),
    printWhenExpression: new PExpression({ id: 'printWhenExpression', label: 'MBand.property.printWhen.label', }),
    splitType: {...SplitTypeEnum, inheritedPropertyId: 'net.sf.jasperreports.band.split.type' }
};

export const MBand: APDescriptor = {
    id: 'bandPropertiesDescriptor',
    type: 'MBand',
    label: 'Band',
    layouts: [new PDiv({ layouts: [FBand.height, FBand.splitType, FBand.printWhenExpression, MBandCustom] })]
};
