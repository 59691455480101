/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { AxiosError, AxiosResponse } from 'axios';
import { OrderedMap, Set } from 'immutable';
import * as React from 'react';
import SplitPane from 'react-split-pane';
import { RepositoryApi, IRepositoryItemDescriptor, RESOURCE_TYPE } from '@jss/js-rest-api';
import { Validators, TimedSearchBar, TextField, withRouter, RouterProps } from '@jss/js-common';
import FilesTable from '../../components/FilesTable';
import FoldersTree from '../../components/FoldersTree';

import '../../assets/uxpl/css/ResourcePicker.css';
import { getUUID, RunContext } from '../..';

export interface IResourcePicker {
    description?: string;
    mode?: 'open' | 'save' | 'open_folder';
    defaultPath?: IRepositoryItemDescriptor[]; // Each element of the path should be ancoded.
    defaultFileName?: string;
    fileNameLabel?: string;
    allowedMimes?: string[];
    onFileSelected?: (folderPath: IRepositoryItemDescriptor[], selectedFile: IRepositoryItemDescriptor | null, fileName: string, folderContent: IRepositoryItemDescriptor[]) => void;
}


export interface IResourcePickerState {
    rootName: string,
    files: IRepositoryItemDescriptor[];
    fetching: boolean;
    error: string | null;
    selectedFolderUUID: string | null;
    selectedFile: IRepositoryItemDescriptor | null;
    filename: string,
    search: string | null;
    childrenMap: OrderedMap<string, IRepositoryItemDescriptor[]>
    parentMap: OrderedMap<string, IRepositoryItemDescriptor[]>,
    refreshedNodes: Set<string>,
    expanded: string[],
}

/**
 * The resource picker provides a way to select a resource from the repository for save or to load.
 *
 * @class ResourcePicker
 * @extends {React.Component}
 */
class SearchableResourcePicker extends React.Component<IResourcePicker & RouterProps, IResourcePickerState> {

    public state = {
        files: [] as IRepositoryItemDescriptor[],
        fetching: false,
        error: null,
        selectedFolderUUID: null,
        selectedFile: null,
        filename: '',
        rootName: '/',
        search: null,
        childrenMap: OrderedMap<string, IRepositoryItemDescriptor[]>(),
        parentMap: OrderedMap<string, IRepositoryItemDescriptor[]>(),
        refreshedNodes: Set<string>(),
        expanded: ['/'],
    }

    public componentDidMount = () => {
        // Let's load the selected path, part by part...

        let defaultUUID;
        let defaultChildrenMap = OrderedMap<string, IRepositoryItemDescriptor[]>();
        let defaultParentMap = OrderedMap<string, IRepositoryItemDescriptor[]>();
        let rootName = '/';
        if (this.props.defaultPath && this.props.defaultPath.length > 0) {
            const tempChildrenMap = new Map<string, IRepositoryItemDescriptor[]>();
            const tempParentMap = new Map<string, IRepositoryItemDescriptor[]>();

            this.descriptorToMap(this.props.defaultPath, tempChildrenMap, tempParentMap);
            this.props.defaultPath.forEach((descriptor) => {
                if (descriptor.type === RESOURCE_TYPE.CONTAINER) {
                    rootName = descriptor.uuid
                }
            });

            defaultChildrenMap = OrderedMap<string, IRepositoryItemDescriptor[]>(tempChildrenMap);
            defaultParentMap = OrderedMap<string, IRepositoryItemDescriptor[]>(tempParentMap);

            defaultUUID = this.props.defaultPath[this.props.defaultPath.length - 1].uuid;
        }
        const defFile = this.props.defaultFileName ? this.props.defaultFileName : this.state.selectedFile;
        if (!defaultUUID){
            if (this.props.defaultPath && this.props.defaultPath.length > 0){
                defaultUUID = this.props.defaultPath[this.props.defaultPath.length - 1].path;
            } else {
                defaultUUID = '/';
            }
        }
        this.setState({
            selectedFolderUUID: defaultUUID ? defaultUUID : null,
            selectedFile: defFile,
            childrenMap: defaultChildrenMap,
            parentMap: defaultParentMap,
            rootName,
        }, () => {
            this.loadFolder(this.state.selectedFolderUUID);
        });
    }

    private descriptorToMap = (descriptors: IRepositoryItemDescriptor[], childrenMap: Map<string, IRepositoryItemDescriptor[]>, parentMap: Map<string, IRepositoryItemDescriptor[]>) => {
        const rootPath = [];
        let lastContainer: IRepositoryItemDescriptor | undefined;
        for (let i = 0; i < descriptors.length; i++) {
            if (descriptors[i].type === RESOURCE_TYPE.CONTAINER) {
                lastContainer = descriptors[i];
            } else {
                break;
            }
        }
        if (lastContainer) {
            const children = lastContainer.children && Object.values(lastContainer.children).length > 0 ? Object.values(lastContainer.children) : [];
            childrenMap.set('/', children);
            parentMap.set('/', [lastContainer]);
            descriptors.forEach((descriptor) => {
                rootPath.push(descriptor);
                const descriptorUUID = descriptor.uuid;
                const children = descriptor.children && Object.values(descriptor.children).length > 0 ? Object.values(descriptor.children) : [];
                parentMap.set(descriptorUUID, rootPath);
                if (children.length > 0) {
                    childrenMap.set(descriptorUUID, children);
                }
            });
            children.forEach((child) => {
                this.recursiveDescriptorToMap(child, childrenMap, parentMap, rootPath);
            });
        } else {
            descriptors.forEach((descriptor, index) => {
                rootPath.push(descriptor);
                const descriptorUUID = descriptor.uuid;
                const children = descriptor.children && Object.values(descriptor.children).length > 0 ? Object.values(descriptor.children) : [];
                parentMap.set(descriptorUUID, rootPath);
                if (index === 0) {
                    //this is the root
                    //for convention the root must be saved also with /
                    parentMap.set('/', rootPath);
                }
                if (children.length > 0) {
                    childrenMap.set(descriptorUUID, children);
                    if (index === 0) {
                        //this is the root
                        //for convention the root must be saved also with /
                        childrenMap.set('/', children);
                    }
                    children.forEach((child) => {
                        this.recursiveDescriptorToMap(child, childrenMap, parentMap, rootPath);
                    })
                }
            });
        }
    }

    private recursiveDescriptorToMap = (descriptor: IRepositoryItemDescriptor, childrenMap: Map<string, IRepositoryItemDescriptor[]>, parentMap: Map<string, IRepositoryItemDescriptor[]>, currentPath: IRepositoryItemDescriptor[]) => {
        const descriptorUUID = descriptor.uuid;
        const children = descriptor.children && Object.values(descriptor.children).length > 0 ? Object.values(descriptor.children) : [];
        const descriptorPath = [...currentPath, descriptor];
        parentMap.set(descriptorUUID, descriptorPath);
        if (children.length > 0) {
            childrenMap.set(descriptorUUID, children);
            children.forEach((child) => {
                this.recursiveDescriptorToMap(child, childrenMap, parentMap, descriptorPath);
            })
        }
    }

    private createTree = () => {
        return <FoldersTree nodes={this.state.childrenMap}
            handleNodeClick={this.handleNodeClick}
            handleNodeExpandCollapse={this.handleNodeExpand}
            selectedFolderUUID={this.state.selectedFolderUUID}
            defaultPath={this.props.defaultPath}
            rootName={this.state.rootName}
            expanded={this.state.expanded}
            updateExpanded={this.updateExpanded}
        />
    }

    private getFilesPanel = () => {
        const content = this.state.childrenMap.get(this.state.selectedFolderUUID);
        return <FilesTable showOnlyFolder={this.props.mode === 'open_folder'} selectedElement={this.state.selectedFile} files={content} onElementClick={this.onTableRowClick} allowedMimes={this.props.allowedMimes} />;
    }

    private getSearchedFilesPanel = () => {
        return <FilesTable showOnlyFolder={this.props.mode === 'open_folder'} selectedElement={this.state.selectedFile} files={this.state.files} onElementClick={this.onTableRowClick} allowedMimes={this.props.allowedMimes} />;
    }

    public render() {
        const description = this.props.description ? this.props.description : "";
        let content;
        if (this.state.search) {
            const table = this.getSearchedFilesPanel();
            content = <div className="jrsw-file-browser-scrollable-container">
                {table}
            </div>;
        } else {
            const table = this.getFilesPanel();
            content = <SplitPane split="vertical" defaultSize="35%" style={{ position: 'relative', flex: 1, paddingLeft: 20, paddingRight: 20 }}>
                <div className="jrsw-file-browser-scrollable-container" style={{ paddingTop: 10 }}>
                    {this.createTree()}
                </div>
                <div className="jrsw-file-browser-scrollable-container">
                    {table}
                </div>

            </SplitPane>;
        }
        return <div className="tc-jsw-resourcepicker">
            <div className="tc-jsw-repository-title-container">
                <div className="tc-jsw-repository-title-bar">
                    <div className="tc-jsw-repository-title-bar-title">
                        <span className="tc-jsw-repository-title-bar-title-text">{description}</span>
                        <div className="tc-jsw-repository-title-bar-item">{this.getSearchBar()}</div>
                    </div>
                </div>
            </div>
            <div className="jrws-resourcepicker" style={{ minHeight: 500 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', flexDirection: 'column', borderBottom: 'solid lightgray 1px', paddingLeft: 10, paddingRight: 10 }}>
                    {this.getFileNameInput()}
                </div>
                {content}
            </div>
        </div >
    }

    private handleNodeExpand = (nodeId: string, expanded: boolean) => {
        if (expanded) {
            this.loadFolder(nodeId);
        }
    };

    private onTableRowClick = (clickedItem: IRepositoryItemDescriptor) => {
        if (this.props.mode !== 'open_folder' && (clickedItem.type === RESOURCE_TYPE.CONTAINER || clickedItem.type === RESOURCE_TYPE.FOLDER)){
            if (!this.state.expanded.includes(clickedItem.uuid)){
                const newExpanded = [...this.state.expanded, clickedItem.uuid];
                this.setState({expanded: newExpanded}, () => this.handleNodeClick(undefined, clickedItem.uuid));
            } else {
                //already expanded
                this.handleNodeClick(undefined, clickedItem.uuid);
            }
        } else {
            this.setState({ selectedFile: clickedItem, filename: clickedItem.name }, () => {
                this.notifyFileChanged();
            });
        }
    }

    private getFileNameInput = () => {
        if (this.props.mode === 'save') {
            return <TextField
                onChange={this.onFileNameChanged}
                label={this.props.fileNameLabel ? this.props.fileNameLabel : 'File Name'}
                inline={false}
                size={'large'}
                value={this.state.filename}
            />
        }
        return null;
    }

    // tree related callbacks

    private handleNodeClick = (event: React.ChangeEvent, nodeId: string) => {
        const newSelectedUUID = nodeId;
        if (newSelectedUUID !== this.state.selectedFolderUUID) {
            this.setState({ selectedFolderUUID: newSelectedUUID }, () => {
                // Let's load the files contained in this path...
                this.loadFolder(newSelectedUUID);
                /*this.loadFolder(newSelectedUUID, () => {
                    if (this.props.mode === 'open_folder'){
                        if (nodeId === '/'){
                            this.setState({selectedFolderUUID: '/', selectedFile: { name: '/', type: RESOURCE_TYPE.CONTAINER, uuid: '/' }, filename: '/'}, this.notifyFileChanged);
                        } else {
                            const paths = this.state.parentMap.get(nodeId);
                            const currentItem = paths[paths.length - 1];
                            this.setState({selectedFolderUUID: paths.length > 1 ? paths[paths.length - 2].uuid : '/', selectedFile: currentItem, filename: currentItem.name}, this.notifyFileChanged);
                        }
                    }
                });*/
            });
        }
    };

    private updateExpanded = (newExpanded: string[], callback?: () => void) => {
        this.setState({ expanded: newExpanded }, callback);
    }

    /**
     * Notify the container of a file selection change by invoking, if provided, the onFileSelected.
     * Please note that the file selected could be null.
     *
     * @private
     * @memberof ResourcePicker
     */
    private notifyFileChanged = () => {
        if (this.props.onFileSelected) {
            const content = this.state.childrenMap.get(this.state.selectedFolderUUID);
            const folderPath = this.state.parentMap.get(this.state.selectedFolderUUID);
            this.props.onFileSelected(folderPath, this.state.selectedFile, this.state.filename, content);
        }
    }

    private onFileNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const error = Validators.validateFolderName(event.target.value);
        if (error === null) {
            const filename = event.target.value;
            let selectedFileDescriptor;
            const content = this.state.childrenMap.get(this.state.selectedFolderUUID);
            if (content) {
                selectedFileDescriptor = content.find((siblingDescriptor) => { return siblingDescriptor.name === filename; });
            }
            this.setState({ filename, selectedFile: selectedFileDescriptor ? selectedFileDescriptor : null }, () => {
                this.notifyFileChanged();
            });
        }
    }

    // search bar related 

    private getSearchBar = () => {
        return <div style={{ display: 'flex', width: '250px', marginTop: 5, marginBottom: 5, justifyContent: 'flex-end', alignItems: 'center' }}>
            <TimedSearchBar style={{ margin: 0 }} placeholder="Search" help="Type the name (use * for all)" id="file-searchbar" onChange={this.searchTextChange} onEnterPress={this.searchTextEnterPress} />
        </div>
    }

    private searchTextChange = (value: any) => {
        this.setState({ search: value, fetching: true }, this.searchFiles);
    }

    private searchTextEnterPress = (value: any) => {
        this.setState({ search: value, fetching: true }, () => this.searchFiles(true));
    }

    // work with server

    private searchFiles = (force?: boolean) => {
        const toSearch = this.state.search;
        if (toSearch && (toSearch !== "" || force)) {
            const callParams = { name: toSearch, mime: this.props.allowedMimes, root: getUUID(this.props.defaultPath) }
            this.context.run(RepositoryApi.inst().findWithCancel)(callParams).then((response: AxiosResponse) => {
                if (response.data) {
                    const items: IRepositoryItemDescriptor[] = response.data ? response.data : [];
                    this.setState({ fetching: false, files: items, selectedFolderUUID: null, selectedFile: null }, () => {
                        this.notifyFileChanged();
                    });
                }
            }).catch((error: AxiosError) => {
                this.setState({ fetching: false, error: error.message });
            });
        } else {
            this.setState({ fetching: false }, () => {
                this.loadFolder(this.state.selectedFolderUUID);
            });
        }
    }

    /**
     * Sunsequentially list elements for each part of a path.
     *
     * @private
     * @memberof ResourcePicker
     */
     private loadFolder = (folderUUID: string | null, loadCallback?: () => void) => {
        if (folderUUID === null || folderUUID === '/') {
            if (!this.state.refreshedNodes.has('/')) {
                // Load the folders..
                const path = this.props.router.searchParams.get('path');
                this.context.runNoCancel(RepositoryApi.inst().list)('/').then((response: AxiosResponse) => {
                    let newRefreshedNodes = this.state.refreshedNodes.add('/');
                    // We should filter the folders from the resources...
                    let foundChildren = response.data.children ? Object.values(response.data.children) as IRepositoryItemDescriptor[] : [];
                    let containerRoot: IRepositoryItemDescriptor | undefined;
                    if (path) {
                        containerRoot = foundChildren.find((child) => {
                            return child.type === RESOURCE_TYPE.CONTAINER && path.startsWith(child.uuid);
                        });
                    }
                    let parentPath: IRepositoryItemDescriptor[];
                    if (containerRoot) {
                        parentPath = [{ name: '/', type: RESOURCE_TYPE.CONTAINER, uuid: '/' }];
                        let newMap = this.state.childrenMap;
                        let newParentMap = this.state.parentMap;
                        let rootName = containerRoot.uuid;
                        const exploreStructure = () => {
                            this.context.runNoCancel(RepositoryApi.inst().list)(`uuid:${containerRoot.uuid}`).then((response: AxiosResponse) => {
                                foundChildren = response.data.children ? Object.values(response.data.children) as IRepositoryItemDescriptor[] : [];
                                newRefreshedNodes = newRefreshedNodes.add(containerRoot.uuid);
                                containerRoot = foundChildren.find((child) => {
                                    return child.type === RESOURCE_TYPE.CONTAINER && path.startsWith(child.uuid);
                                });
                                if (!containerRoot){
                                    newMap = newMap.set('/', foundChildren);
                                    foundChildren.forEach((child) => {
                                        const childPath = [...parentPath, child];
                                        newParentMap = newParentMap.set(child.uuid, childPath);
                                    });
                                    this.setState({ childrenMap: newMap, parentMap: newParentMap, rootName: rootName, refreshedNodes: newRefreshedNodes }, loadCallback);
                                } else {
                                    rootName = containerRoot.uuid;
                                    exploreStructure();
                                }
                            });
                        }
                        exploreStructure();
                    } else {
                        parentPath = [{ name: '/', type: RESOURCE_TYPE.FOLDER }];
                        let newMap = this.state.childrenMap;
                        newMap = newMap.set('/', foundChildren);
                        let newParentMap = this.state.parentMap;
                        foundChildren.forEach((child) => {
                            const childPath = [...parentPath, child];
                            newParentMap = newParentMap.set(child.uuid, childPath);
                        });
                        this.setState({ childrenMap: newMap, parentMap: newParentMap, rootName: '/', refreshedNodes: newRefreshedNodes }, loadCallback);
                    }
                });
            } else if (loadCallback) {
                //the value is already loaded, just call the callback
                loadCallback();
            }
        } else {
            if (!this.state.refreshedNodes.has(folderUUID)) {
                const repoQuery = `uuid:${folderUUID}`;
                // Load the folders..
                this.context.runNoCancel(RepositoryApi.inst().list)(repoQuery).then((response: AxiosResponse) => {
                    // We should filter the folders from the resources...
                    const foundChildren = response.data.children ? Object.values(response.data.children) as IRepositoryItemDescriptor[] : [];
                    let newMap = this.state.childrenMap;
                    newMap = newMap.set(folderUUID, foundChildren);

                    const parentPath: IRepositoryItemDescriptor[] = this.state.parentMap.has(folderUUID) ? this.state.parentMap.get(folderUUID) : [{ name: '/', type: RESOURCE_TYPE.FOLDER }];
                    let newParentMap = this.state.parentMap;
                    foundChildren.forEach((child) => {
                        const childPath = [...parentPath, child];
                        newParentMap = newParentMap.set(child.uuid, childPath);
                    });
                    const newRefreshed = this.state.refreshedNodes.add(folderUUID);
                    this.setState({ childrenMap: newMap, parentMap: newParentMap, refreshedNodes: newRefreshed }, loadCallback);
                });
            } else if (loadCallback) {
                //the value is already loaded, just call the callback
                loadCallback();
            }
        }
    }
}

SearchableResourcePicker.contextType = RunContext;

export default withRouter(SearchableResourcePicker);