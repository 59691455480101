/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { MChartPlot } from "./MChartPlot";
import { PColor } from "../../../ui/primitive/UPColor";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { PInt } from "../../../ui/primitive/UPInteger";
import { setFonts } from "../../common/MFont";
import { MCategoryAxis, MValueAxis } from "./MAxis";

export const FBar3DPlot = {
    isShowLabels: new PBoolean({ id: 'isShowLabels', label: 'Show Labels', canBeNull: true }),

    color: new PColor({ id: 'color', label: 'Color' }),
    backgroundColor: new PColor({ id: 'backgroundColor', label: 'Background' }),

    xOffset: new PInt({ id: 'xOffset', label: 'X Offset' }),
    yOffset: new PInt({ id: 'yOffset', label: 'Y Offset' })
};

export const MBar3DPlot: APDescriptor = {
    id: 'bar3DPlotProeprtiesDescriptor',
    type: 'MBar3DPlot',
    path: 'bar3DPlot',
    layouts: [...MChartPlot.layouts, FBar3DPlot.isShowLabels, FBar3DPlot.xOffset, FBar3DPlot.yOffset,
        MCategoryAxis, MValueAxis,
    new PAccordeon({
        id: 'chart.value.item.label', label: 'Item Label', path: 'itemLabel',
        layouts: [FBar3DPlot.color, FBar3DPlot.backgroundColor, ...setFonts('font')]
    })
    ]
};

