import { ElementTypes } from "../../../sagas/report/document/elementTypes";
import { getPath } from "./ui/UiProperty";
import { Map } from "immutable";

export const getDataset = (model: Map<string, any>, path: (string | number)[]) => {
    let mpath = [];
    if (path?.length > 0)
        if (Array.isArray(path[0])) { mpath = [...path[0]]; } else { mpath = [...path]; }
    mpath = [mpath.splice(0, 2)];

    let subdataset = model;
    const ptype = getPath('type', mpath);
    const vtype = model.getIn(ptype);
    let vn = undefined;
    switch (vtype) {
        case ElementTypes.JFREECHART:
            ['timeSeriesDataset', 'highLowDataset', 'categoryDataset', 'xyDataset', 'xyzDataset', 'pieDataset', 'valueDataset'].some(v => {
                const spath = getPath(v + '.dataset.datasetRun.subDataset', mpath);
                const vname = model.getIn(spath);
                if (vname !== undefined) {
                    vn = vname;
                    return true;
                }
            });
            break;
        // here depending on the type, should look if container is giving the dataset, like inside a table
        // if there is no subdataset, should look into the parent
        case ElementTypes.VARIABLE:
            break;
        case ElementTypes.PARAMETER:
            break;
        case ElementTypes.DATASET: {
            //get the dataset
            const spath = getPath(undefined, mpath);
            subdataset = model.getIn(spath) as Map<string, any>;
            break;
        }
        case ElementTypes.CROSSTAB:
        case ElementTypes.TABLE:
        case ElementTypes.LIST:{
            const spath = getPath('datasetRun', mpath);
            spath.push('subDataset');
            vn = model.getIn(spath);
            // if there is no subdataset, should look into the parent
            break;
        }
        case ElementTypes.HTML5CHART_JR:{
            const spath = getPath('multiAxisData.dataset.datasetRun.subDataset', mpath);
            vn = model.getIn(spath);
            // if there is no subdataset, should look into the parent
            break;
        }
        case ElementTypes.GENERIC_ELEMENT:
        case ElementTypes.TEXTFIELD:
        case ElementTypes.IMAGE:
        case ElementTypes.BARCODE:
            // don't have his own dataset run, so look at parent container first
            break;
        case ElementTypes.CVC_ELEMENT:
        case ElementTypes.TIBCO_MAP:
        case ElementTypes.MAP:
        default:{
            const spath = getPath('datasetRun', mpath);
            spath.push('subDataset');
            vn = model.getIn(spath);
        }
        // if there is no subdataset, should look into the parent
    }
    if (vn) {
        const sds =  model.get('subdatasets');
        if (sds) {
            sds.some((sub) => {
                const n = sub.get('name');
                if (n === vn) {
                    subdataset = sub;
                    return true;
                }
            });
        }
    }
    return subdataset
}