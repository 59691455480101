
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { getKey, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { Switch } from '@jss/js-common';
import { resolveAttribute } from '../../types/common/StyleResolver';
import { Map } from 'immutable';
import '../../../../../assets/uxpl/css/UPControls.css';
import i18n from '../../../../../i18n';

const TYPEID = 'mode';
reg(TYPEID, (mc) => { return <UPMode mcontext={mc} />; });
export class PMode extends APDescriptor {
    icon?: string;
    canBeNull?: boolean;
    default?: boolean;
    public constructor(init: Partial<PMode>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

export class UPMode extends React.Component<UiPropertyProps> {

    render() {
        const d = this.props.mcontext.descriptor as PMode;
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        let v = this.props.mcontext.model.getIn(p);
        let isInherited = false;
        if (v === undefined) {
            const elementPath = getPath(undefined, this.props.mcontext.elements[0].path);
            const element = this.props.mcontext.model.getIn(elementPath) as Map<string, any>;
            const resolvedValue = resolveAttribute(this.props.mcontext.model, element, 'mode');
            v = resolvedValue;
            isInherited = true;
        }
        const classes: string[] = [];
        if (this.props.mcontext.descriptor.className) {
            classes.push(this.props.mcontext.descriptor.className);
        }
        if (!isInherited) {
            classes.push('sliderDotNotInheritedColor');
        } else {
            classes.push('sliderInherithedColor');
            classes.push('sliderDotInheritedColor');
        }
        return <Switch
            className={classes.join(' ')}
            style={this.props.mcontext.descriptor.style}
            key={getKey(p)}
            onChange={this.onValueChange}
            label={i18n.t(this.props.mcontext.descriptor.label)}
            SwitchLabelProps={d.deprecated ? {className: 'deprecatedProperty'} : undefined}
            // defaultValue={this.props.placeholder}
            // inline={true}
            size={'small'}
            disabled={this.props.mcontext.descriptor.readonly}
            checked={v === 'Transparent'}
        />
    }

    public onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: (isChecked ? 'Transparent' : 'Opaque') });
        });
    }

}