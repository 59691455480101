/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import React, { useCallback } from 'react';
import { TreeItem, TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IBookmark } from './ViewerStore';


export function Bookmarks(
    { bookmarks, onItemClick }:
    {
        bookmarks: Array<IBookmark>,
        onItemClick: ({ page, anchor }: {page: number, anchor: string}) => void
    }) {


    const getBookmarks = useCallback(
        (bookmarks: Array<IBookmark>) => {
            return (
                bookmarks
                    ? <>
                        {
                            bookmarks.map(({ anchor, page, elementAddress, bookmarks }) => {
                                return (
                                    <TreeItem
                                        key={`${elementAddress}@${page}`}
                                        nodeId={`${elementAddress}@${page}`}
                                        label={anchor}
                                        onLabelClick={(event) => {
                                            event.preventDefault();
                                            onItemClick({page, anchor: elementAddress})
                                        }}
                                    >
                                        {bookmarks && getBookmarks(bookmarks)}
                                    </TreeItem>
                                );
                            })
                        }
                    </>
                    : null

            );
        }, [onItemClick]);

    return (
        <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
            {getBookmarks(bookmarks)}
        </TreeView>
    );
}
