/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const ChartIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Tree/dashboard/chart" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Bar-Chart" transform="translate(1.000000, 1.000000)">
                    <rect id="Rectangle" fill="#055DAB" x="6" y="3" width="5" height="13"></rect>
                    <rect id="Rectangle-Copy-2" fill="#062E79" x="12" y="7" width="5" height="9"></rect>
                    <rect id="Rectangle-Copy" fill="#318DDE" x="0" y="0" width="5" height="16"></rect>
                </g>
            </g>
        </svg>
    );
}
export default ChartIcon;