/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Typography } from '@jss/js-common';
import * as React from 'react';
import i18n from '../../../../../i18n';
import { APDescriptor } from '../APDescriptor';
import { reg, UiProperty, UiPropertyProps } from '../UiProperty';

const TYPEID = 'BSubDiv';
reg(TYPEID, (mc) => { return <UPSubDiv mcontext={mc} />; });
export class PSubDiv extends APDescriptor {

    public constructor(init: Partial<PSubDiv>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

export class UPSubDiv extends React.Component<UiPropertyProps> {
    public state = { selected: 0 }
    render() {
        if (this.props.mcontext.descriptor.layouts === undefined || this.props.mcontext.descriptor.layouts.length === 0)
            return <></>;
        let className = "jr-mInputs-group mui";
        if (this.props.mcontext.descriptor.className) {
            className += " " + this.props.mcontext.descriptor.className;
        }
        return <div
            style={this.props.mcontext.descriptor.style ? this.props.mcontext.descriptor.style : { display: 'block' }} className={className}>
            <div className={"jr-mInputs-group mui"}>
                <Typography className={"jr-mInputs-group-label mui"} variant="body2">{i18n.t(this.props.mcontext.descriptor.label)}</Typography>
                <div className={"jr-mInputs-group-items jr-mInputs-group-itemsIndent mui"}>
                    {this.props.mcontext.descriptor.layouts?.map((key, index) => {
                        key.readonly = this.props.mcontext.descriptor.readonly;
                        return <UiProperty key={key.id ? key.id + '.' + index : 'div.' + key.type + '.' + index} mcontext={{ ...this.props.mcontext, descriptor: key }} />
                    })}
                </div>
            </div>
        </div>
    }

}