/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import '../../../assets/uxpl/css/DndLayer.css';

import * as React from 'react';
import { DragLayer, XYCoord } from 'react-dnd';

import { PALETTE_ITEM_POSTFIX } from '../palette/Palette';
import { convertPageToDesignerCoords } from '../../../sagas/report/designer/documentUtils';
import store from '../../../store/store';
import { ElementTypes } from '../../../sagas/report/document/elementTypes';
import { MStaticText } from '../../report/properties/types/elements/MStaticText';
import { MTextField } from '../../report/properties/types/elements/MTextField';
import { MIMage } from '../../report/properties/types/elements/MImage';
import { MFrame } from '../../report/properties/types/elements/MFrame';
import { MEllipse } from '../../report/properties/types/elements/MEllipse';
import { MLine } from '../../report/properties/types/elements/MLine';
import { MRectangle } from '../../report/properties/types/elements/MRectangle';
import { MBarcode } from '../../report/properties/types/barcode/MBarcode';
import { MBreak } from '../../report/properties/types/elements/MBreak';
import { MGenericElement } from '../../report/properties/types/elements/MGenericElement';
import { MSubreport } from '../../report/properties/types/elements/MSubreport';
import { MList } from '../../report/properties/types/elements/MList';
import { MJFreeChart } from '../../report/properties/types/chart/MJFreeChart';
import { MCrosstab } from '../../report/properties/types/crosstab/MCrosstab';
import { MTable } from '../../report/properties/types/table/MTable';
import { MMap } from '../../report/properties/types/map/MMap';
import { MCVC } from '../../report/properties/types/cvc/MCVC';
import { MHighCharts } from '../../report/properties/types/highchart/MHighChart';
import { MTibcoMap } from '../../report/properties/types/maptibco/MTibcoMap';

export interface IDndLayer {
  item?: any
  itemType?: string
  isDragging?: boolean
  initialSourceClientOffset?: XYCoord,
  initialClientOffset?: XYCoord,
  currentOffset?: XYCoord
  clientOffset?: XYCoord,
  zoom: number,
}

export class DndLayer extends React.Component<IDndLayer> {

  public render() {

    const { item, itemType, isDragging, initialSourceClientOffset, initialClientOffset, currentOffset, clientOffset } = this.props;

    if (!itemType ||
      !itemType.endsWith(PALETTE_ITEM_POSTFIX) ||
      !isDragging ||
      !currentOffset ||
      !initialSourceClientOffset ||
      !initialClientOffset ||
      !clientOffset) {
      return null;
    }
    //console.log('current offest ' + JSON.stringify(currentOffset));
    //console.log('initial client offset ' +  JSON.stringify(initialClientOffset));
    //console.log('initial source client offset ' +  JSON.stringify(initialSourceClientOffset));
    //console.log('client offset ' +  JSON.stringify(clientOffset));
    const containerPath = store.getState().getIn(['report', 'highlightedContainer'], null);
    if (!containerPath) {
      return null;
    }

    let w = 100;
    let h = 30;
    switch (item.id) {
      case ElementTypes.STATICTEXT: {
        const defaults = MStaticText.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.TEXTFIELD: {
        const defaults = MTextField.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.IMAGE: {
        const defaults = MIMage.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.FRAME: {
        const defaults = MFrame.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.ELLIPSE: {
        const defaults = MEllipse.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.LINE: {
        const defaults = MLine.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.RECTANGLE: {
        const defaults = MRectangle.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.BARCODE: {
        const defaults = MBarcode.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.BREAK: {
        const defaults = MBreak.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.GENERIC_ELEMENT: {
        const defaults = MGenericElement.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.SUBREPORT: {
        const defaults = MSubreport.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.LIST: {
        const defaults = MList.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.JFREECHART: {
        const defaults = MJFreeChart.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.CROSSTAB: {
        const defaults = MCrosstab.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.TABLE: {
        const defaults = MTable.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.MAP: {
        const defaults = MMap.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.CVC_ELEMENT: {
        const defaults = MCVC.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.HTML5CHART_JR: {
        const defaults = MHighCharts.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
      case ElementTypes.TIBCO_MAP: {
        const defaults = MTibcoMap.default;
        w = defaults.width;
        h = defaults.height;
        break;
      }
    }
    const location = convertPageToDesignerCoords(clientOffset);

    //console.log('location ' +  JSON.stringify(location));

    const x = location.x;
    const y = location.y;

    let figure = <div className="DndPreviewFigure" style={{
      top: y * this.props.zoom,
      left: x * this.props.zoom,
      width: w * this.props.zoom,
      height: h * this.props.zoom,
      position: 'absolute',
    }} />;

    if (item.getFigure) {
      figure = <div style={{
        position: 'absolute',
        top: y * this.props.zoom,
        left: x * this.props.zoom,
      }}>{item.getFigure()}</div>
    }

    return <div className="DndLayer">
      {figure}
    </div>;

    // 
  }
}

export default DragLayer<IDndLayer>(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialSourceClientOffset: monitor.getInitialSourceClientOffset(),
  initialClientOffset: monitor.getInitialClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  clientOffset: monitor.getClientOffset(),
  isDragging: monitor.isDragging(),
}))(DndLayer)