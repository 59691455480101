/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const PieChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="802"
      height="493"
      version="1.1"
      style={{
         WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
         WebkitUserSelect: "none",
         MozUserSelect: "none",
         userSelect: "none",
         position: "relative",
      }}
      cursor="default"
      overflow="hidden"
      viewBox="0 0 802 493"
      {...props}
   >
      <defs>
         <linearGradient
            id="19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100"
            x1="0"
            x2="0"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#cbcbcb" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#e9e9e9" stopOpacity="0.5"></stop>
         </linearGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_179_218_248_1__90-rgba_101_125_144_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="90%" stopColor="#b3daf8"></stop>
            <stop offset="100%" stopColor="#657d90"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_246_192_27_1__90-rgba_143_110_8_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="90%" stopColor="#f6c01b"></stop>
            <stop offset="100%" stopColor="#8f6e08"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_144_189_12_1__90-rgba_80_108_0_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="90%" stopColor="#90bd0c"></stop>
            <stop offset="100%" stopColor="#506c00"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_255_147_79_1__90-rgba_148_82_40_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="90%" stopColor="#ff934f"></stop>
            <stop offset="100%" stopColor="#945228"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_12_147_147_1__90-rgba_0_82_82_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="90%" stopColor="#0c9393"></stop>
            <stop offset="100%" stopColor="#005252"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_216_79_79_1__90-rgba_124_40_40_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="90%" stopColor="#d84f4f"></stop>
            <stop offset="100%" stopColor="#7c2828"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_147_79_147_1__90-rgba_82_40_82_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="90%" stopColor="#934f93"></stop>
            <stop offset="100%" stopColor="#522852"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_96_139_48_1__90-rgba_51_77_22_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="90%" stopColor="#608b30"></stop>
            <stop offset="100%" stopColor="#334d16"></stop>
         </radialGradient>
      </defs>
      <g className="raphael-group-20-background">
         <rect
            width="800"
            height="491"
            x="1"
            y="1"
            fill="url('#19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100')"
            rx="0"
            ry="0"
         ></rect>
         <rect
            width="801"
            height="492"
            x="0.5"
            y="0.5"
            fill="none"
            stroke="#767575"
            strokeOpacity="0.5"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-21-dataset">
         <g
            fill="none"
            stroke="#404040"
            strokeLinecap="round"
            strokeLinejoin="round"
         >
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M553.43 181.297A174 174 0 01576 267H402z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M553.43 181.297A174 174 0 01576 267H402z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M553.43 181.297A174 174 0 01576 267H402z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M532.697 152.134a174 174 0 0120.733 29.163L402 267z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M532.697 152.134a174 174 0 0120.733 29.163L402 267z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M532.697 152.134a174 174 0 0120.733 29.163L402 267z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M283.806 139.304a174 174 0 01248.891 12.83L402 267z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M283.806 139.304a174 174 0 01248.891 12.83L402 267z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M283.806 139.304a174 174 0 01248.891 12.83L402 267z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M265.563 159.014a174 174 0 0118.243-19.71L402 267z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M265.563 159.014a174 174 0 0118.243-19.71L402 267z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M265.563 159.014a174 174 0 0118.243-19.71L402 267z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M233.737 311.313a174 174 0 0131.826-152.299L402 267z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M233.737 311.313a174 174 0 0131.826-152.299L402 267z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M233.737 311.313a174 174 0 0131.826-152.299L402 267z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M424.341 439.56a174 174 0 01-190.604-128.247L402 267z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M424.341 439.56a174 174 0 01-190.604-128.247L402 267z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M424.341 439.56a174 174 0 01-190.604-128.247L402 267z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M557.64 344.794a174 174 0 01-133.299 94.766L402 267z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M557.64 344.794a174 174 0 01-133.299 94.766L402 267z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M557.64 344.794a174 174 0 01-133.299 94.766L402 267z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M576 267a174 174 0 01-18.36 77.794L402 267z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M576 267a174 174 0 01-18.36 77.794L402 267z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M576 267a174 174 0 01-18.36 77.794L402 267z"
            ></path>
         </g>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_179_218_248_1__90-rgba_101_125_144_1__100')"
            stroke="#EBF6FE"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M552.43 180.297A174 174 0 01575 266H401z"
         ></path>
         <text
            x="603.439"
            y="212.774"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="603.439" dy="4">
               AK, 10
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M569.263 221.687l24.176-8.913h5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_246_192_27_1__90-rgba_143_110_8_1__100')"
            stroke="#FDEFC3"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M531.697 151.134a174 174 0 0120.733 29.163L401 266z"
         ></path>
         <text
            x="573.191"
            y="144.901"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="573.191" dy="4.003">
               CA, 4
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M542.815 165.18l20.376-20.279h5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_144_189_12_1__90-rgba_80_108_0_1__100')"
            stroke="#E2EEC0"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M282.806 138.304a174 174 0 01248.891 12.83L401 266z"
         ></path>
         <text
            x="421.244"
            y="57.277"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="421.244" dy="4.004">
               ID, 31
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M409.957 92.23l1.287-34.953h5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_255_147_79_1__90-rgba_148_82_40_1__100')"
            stroke="#FFE3D1"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M264.563 158.014a174 174 0 0118.243-19.71L401 266z"
         ></path>
         <text
            x="244.957"
            y="124.032"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="244.957" dy="3.993">
               MT, 3
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M273.304 147.806l-18.347-23.774h-5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_12_147_147_1__90-rgba_0_82_82_1__100')"
            stroke="#C0E3E3"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M232.737 310.313a174 174 0 0131.826-152.299L401 266z"
         ></path>
         <text
            x="196.208"
            y="223.249"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="196.208" dy="4.006">
               NM, 18
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M230.68 230.408l-24.472-7.16h-5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_216_79_79_1__90-rgba_124_40_40_1__100')"
            stroke="#F5D1D1"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M423.341 438.56a174 174 0 01-190.604-128.247L401 266z"
         ></path>
         <text
            x="279.909"
            y="439.402"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="279.909" dy="4.004">
               OR, 28
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M303.866 410.364l-13.957 29.038h-5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_147_79_147_1__90-rgba_82_40_82_1__100')"
            stroke="#E3D1E3"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M556.64 343.794a174 174 0 01-133.299 94.766L401 266z"
         ></path>
         <text
            x="526.305"
            y="436.341"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="526.305" dy="4.005">
               WA, 19
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M501.82 407.815l14.485 28.526h5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_96_139_48_1__90-rgba_51_77_22_1__100')"
            stroke="#D6E1C9"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M575 266a174 174 0 01-18.36 77.794L401 266z"
         ></path>
         <text
            x="604.68"
            y="314.005"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="604.68" dy="3.997">
               WY, 9
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M570.348 305.966l24.332 8.039h5"
         ></path>
      </g>
      <g className="raphael-group-75-caption">
         <text
            x="401"
            y="16"
            fill="#555"
            className="fusioncharts-caption"
            fontFamily="Verdana, sans"
            fontSize="13"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="401" dy="12.992">
               Chart Caption
            </tspan>
         </text>
         <text
            x="401"
            y="34"
            fill="#555"
            className="fusioncharts-subcaption"
            fontFamily="Verdana, sans"
            fontSize="11"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="401" dy="10.852">
               Chart Sub Caption
            </tspan>
         </text>
      </g>
   </svg>)
};

export default PieChartIcon;