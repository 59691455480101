/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import * as ReportActions from '../../../../../actions/reportActions';
import { reg } from '../../ui/UiProperty';
import { APDescriptor } from '..//APDescriptor';
import { UPTable } from './UPTable';

import { connect } from 'react-redux';
import { IState } from '../../../../../reducers';

const TYPEID = 'connectedTable';
export class PConnectedTable extends APDescriptor {
    default?: any;
    seachable?: boolean;
    expanded?: boolean = false;
    rowLabel?: string;
    rowLabelPaths?: string[];
    refreshCacheOnDelete?: boolean;
    refreshCacheOnAdd?: boolean;
    public constructor(init: Partial<PConnectedTable>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

const mapStateToProps = (state: IState) => {
    return {
        descriptor: state.getIn(['report', 'fileDescriptor']),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteElement: (path: string[]) => { dispatch(ReportActions.deleteElement(path)); },
        addProperty: (path: string[], element) => { dispatch(ReportActions.addProperty(path, element)); },
        setPropertiesRefreshAction: (path: (string | number)[], value: any, reportPath: string) => { dispatch(ReportActions.setPropertiesRefreshAction(path, value, reportPath)); },
        deletePropertyRefreshAction: (path: (string | number)[], reportPath: string) => { dispatch(ReportActions.deletePropertyRefreshAction(path, reportPath)); }
    };
}

const ConnectedTable = connect(mapStateToProps, mapDispatchToProps)(UPTable as any) as any;
reg(TYPEID, (mc) => { return <ConnectedTable mcontext={mc} />; });
