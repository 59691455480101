/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { JDBCDataAdapterEditor } from './JDBCDataAdapterEditor';
import { IDataAdapterConfig, IDataAdapterDescriptor, createNode } from '../IDataAdapterDescriptor';
import format from 'xml-formatter';
import { MIME_TYPES } from '@jss/js-rest-api';
import { getChilNodeByName, getProlog } from '..';
import { jdbcUrlValidator } from '../DBConfigurationPanel';
import { nameValidator, notEmptyClassNameValidator } from '@jss/js-common/src/utils/validators';
import { getDataAdapterNode } from '../../editor/Utils';


export default class JDBCDataAdapterDescriptor implements IDataAdapterDescriptor {
  
  public getMime = () => {
    return MIME_TYPES.DATA_ADAPTER_JDBC;
  }

  public getIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" height={48} width={48} viewBox="0 0 48 48">
      <path d="M24 22q-8.05 0-13.025-2.45T6 14q0-3.15 4.975-5.575Q15.95 6 24 6t13.025 2.425Q42 10.85 42 14q0 3.1-4.975 5.55Q32.05 22 24 22Zm0 10q-7.3 0-12.65-2.2Q6 27.6 6 24.5v-5q0 1.95 1.875 3.375t4.65 2.35q2.775.925 5.9 1.35Q21.55 27 24 27q2.5 0 5.6-.425 3.1-.425 5.875-1.325 2.775-.9 4.65-2.325Q42 21.5 42 19.5v5q0 3.1-5.35 5.3Q31.3 32 24 32Zm0 10q-7.3 0-12.65-2.2Q6 37.6 6 34.5v-5q0 1.95 1.875 3.375t4.65 2.35q2.775.925 5.9 1.35Q21.55 37 24 37q2.5 0 5.6-.425 3.1-.425 5.875-1.325 2.775-.9 4.65-2.325Q42 31.5 42 29.5v5q0 3.1-5.35 5.3Q31.3 42 24 42Z" />
    </svg>
  }

  /**
   *  Return the name of this data adapter to be presented to the user
   *  i.e. CSV File
   */
  public getDataAdapterLabel = () => {
    return 'datasource.jdbc.label';
  }

  /**
   *  Return the name of this data adapter to be presented to the user
   *  i.e. CSV File
   */
   public getDataAdapterDescription = () => {
    return 'datasource.jdbc.description';
  }

  /**
   *  Return the canonical name of the class which impements this data adapter in Java.
   */
  public getDataAdapterClass = () => {
    return 'net.sf.jasperreports.data.jdbc.JdbcDataAdapterImpl'
  }

  public getDataAdapterRootName = () => {
    return 'jdbcDataAdapter';
  }

  /**
   * Return a plain json object with the default data adapter configuration.
   * It can be just an empty object.
   *
   * @memberof IDataAdapter
   */
  public initializeDataAdapterConfig = () => {
    return {
      class: this.getDataAdapterClass(),
      name: 'New JDBC Data Adapter',
      url: '',
      username: '',
      password: '',
      classpaths: [],
      propertiesNames: [],
      propertiesValues: [],
    }
  };

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public validateDataAdapterConfig = (config: IDataAdapterConfig) => {

    // Check for errors...
    const errors = [];

    const nameError = nameValidator(config.name);
    if (nameError) {
      errors.push(nameError);
    }

    const urlError = jdbcUrlValidator(config.url);
    if (urlError) {
      errors.push(urlError);
    }

    const driverError = notEmptyClassNameValidator(config.driver);
    if (driverError) {
      errors.push(driverError);
    }

    if (errors.length > 0) {
      return errors.join("\n");
    }

    return undefined;

  }

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public createEditor = (config: IDataAdapterConfig, onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => {
    return <JDBCDataAdapterEditor {...config} onDataAdapterSettingsChange={onDataAdapterSettingsChange} readOnlyWidgets={readOnly}/>
  }

  public getLanguages = () => {
    return ['sql'];
  }

  public toXml = (config: IDataAdapterConfig) => {
    const xmlDoc = document.implementation.createDocument(null, this.getDataAdapterRootName());
    const root = xmlDoc.getElementsByTagName(this.getDataAdapterRootName())[0];
    root.setAttribute('class', this.getDataAdapterClass());

    const nameNode = createNode(xmlDoc, config, 'name', 'name');
    if (nameNode) {
      root.appendChild(nameNode);
    }

    const usernameNode = createNode(xmlDoc, config, 'username', 'username');
    if (usernameNode) {
      root.appendChild(usernameNode);
    }

    const passwordNode = createNode(xmlDoc, config, 'password', 'password');
    if (passwordNode) {
      root.appendChild(passwordNode);
    }

    const urlNode = createNode(xmlDoc, config, 'url', 'url');
    if (urlNode) {
      root.appendChild(urlNode);
    }


    const driverNode = createNode(xmlDoc, config, 'driver', 'driver');
    if (driverNode) {
      root.appendChild(driverNode);
    }

    if (config.autoCommit !== undefined){
      const autoCommitNode = createNode(xmlDoc, config, 'autoCommit', 'autoCommit');
      if (autoCommitNode) {
        root.appendChild(autoCommitNode);
      }
    }

    if (config.readOnly !== undefined){
      const readOnlyNode = createNode(xmlDoc, config, 'readOnly', 'readOnly');
      if (readOnlyNode) {
        root.appendChild(readOnlyNode);
      }
    }

    if (config.transactionIsolation !== undefined){
      const transactionIsolationNode = createNode(xmlDoc, config, 'transactionIsolation', 'transactionIsolation');
      if (transactionIsolationNode) {
        root.appendChild(transactionIsolationNode);
      }
    }

    if (config.serverAddress !== undefined){
      const serverAddressNode = createNode(xmlDoc, config, 'serverAddress', 'serverAddress');
      if (serverAddressNode) {
        root.appendChild(serverAddressNode);
      }
    }

    if (config.database !== undefined){
      const databaseNode = createNode(xmlDoc, config, 'database', 'database');
      if (databaseNode) {
        root.appendChild(databaseNode);
      }
    }

    config.classpaths.forEach((classpath: string) => {
      const classpatNode = xmlDoc.createElement('classpath');
      const newText = xmlDoc.createTextNode(classpath);
      classpatNode.appendChild(newText);
      root.appendChild(classpatNode);
    });

    config.propertiesNames.forEach((propertyName: string, index: number) => {
      const propertiesNode = xmlDoc.createElement('properties');

      const keyNode = xmlDoc.createElement('key');
      const keyNodeText = xmlDoc.createTextNode(propertyName);
      keyNode.appendChild(keyNodeText);

      const valueNode = xmlDoc.createElement('value');
      const valueNodeText = xmlDoc.createTextNode(config.propertiesValues[index]);
      valueNode.appendChild(valueNodeText);
      
      propertiesNode.appendChild(keyNode);
      propertiesNode.appendChild(valueNode);

      root.appendChild(propertiesNode);
    });


    const prolog = getProlog();
    const xml = prolog + (new XMLSerializer().serializeToString(xmlDoc));
    return format(xml, { indentation: '  ', collapseContent: true });
  }

  public toJson = (xml: Document) => {
    const result: any = {
      class: this.getDataAdapterClass(),
      name: 'Unknown JDBC Data Source',
      url: '',
      username: '',
      password: '',
      classpaths: [],
      propertiesNames: [],
      propertiesValues: [],
    }

    getDataAdapterNode(xml.getRootNode()).childNodes.forEach((childNode) => {
      const nodeName = childNode.nodeName;
      if (nodeName === 'name') {
        result.name = childNode.textContent;
      } else if (nodeName === 'driver') {
        result.driver = childNode.textContent;
      } else if (nodeName === 'username') {
        result.username = childNode.textContent;
      } else if (nodeName === 'password') {
        result.password = childNode.textContent;
      } else if (nodeName === 'url') {
        result.url = childNode.textContent;
      } else if (nodeName === 'autoCommit') {
        result.autoCommit = (childNode.textContent === 'true');
      } else if (nodeName === 'readOnly') {
        result.readOnly = (childNode.textContent === 'true');
      } else if (nodeName === 'transactionIsolation') {
        result.transactionIsolation = childNode.textContent;
      } else if (nodeName === 'classpath'){
        result.classpaths.push(childNode.textContent);
      } else if (nodeName === 'properties'){
        const keyNode = getChilNodeByName(childNode, 'key');
        const valueNode = getChilNodeByName(childNode, 'value');
        result.propertiesNames.push(keyNode.textContent);
        result.propertiesValues.push(valueNode.textContent);
      } else if (nodeName === 'serverAddress'){
        result.serverAddress = childNode.textContent;
      } else if (nodeName === 'database'){
        result.database = childNode.textContent;
      }
    });
    return result;
  }
}