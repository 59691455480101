/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { MChartPlot } from "./MChartPlot";
import { PColor } from "../../../ui/primitive/UPColor";
import { setFonts } from "../../common/MFont";
import { PString } from "../../../ui/primitive/UPString";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { ValueLocationEnum } from "../../enums/ValueLocationEnum";

export const FThermometerPlot = {
    mercuryColor: new PColor({ id: 'mercuryColor', label: 'Mercury Color' }),

    color: new PColor({ id: 'color', label: 'Color' }),
    mask: new PString({ id: 'mask', label: 'Mask' }),
    valueLocation: ValueLocationEnum,

    highExpression: new PExpression({ id: 'highExpression', label: 'High Expression' }),
    lowExpression: new PExpression({ id: 'lowExpression', label: 'Low Expression' })
};

export const MThermometerPlot: APDescriptor = {
    type: 'MThermometerPlot',
    path: 'thermometerPlot',
    id: 'thermometerPlotProeprtiesDescriptor',
    layouts: [...MChartPlot.layouts, FThermometerPlot.mercuryColor, FThermometerPlot.mask,
    new PAccordeon({
        id: 'chart.value', label: 'Value', path: 'valueDisplay',
        layouts: [FThermometerPlot.color, ...setFonts('font'), FThermometerPlot.mask, FThermometerPlot.valueLocation]
    }),
    { ...FThermometerPlot.lowExpression, path: 'dataRange', label: 'Data Range Low' }, { ...FThermometerPlot.lowExpression, path: 'dataRange', label: 'Data Range High' },
    { ...FThermometerPlot.lowExpression, path: 'lowRange', label: 'Low Range Low' }, { ...FThermometerPlot.lowExpression, path: 'lowRange', label: 'Low Range High' },
    { ...FThermometerPlot.lowExpression, path: 'mediumRange', label: 'Medium Range Low' }, { ...FThermometerPlot.lowExpression, path: 'mediumRange', label: 'Medium Range High' },
    { ...FThermometerPlot.lowExpression, path: 'highRange', label: 'High Range Low' }, { ...FThermometerPlot.lowExpression, path: 'highRange', label: 'High Range High' },
    ]
};
