/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MGraphicElement } from "../common/MGraphicElement";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PExpression } from "../../ui/primitive/UPExpression";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { OverflowTypeEnum } from "../enums/OverflowTypeEnum";
import { PConnectedTable } from "../../ui/tables/UPConnectedTable";
import { PString } from "../../ui/primitive/UPString";
import { ICONS } from "@jss/js-common";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { FDatasetParameter, FDatasetRun, FReturnValue } from "../dataset/MDatasetRun";
import { PTableElement } from "../../ui/tables/UPTableElement";
import ParameterIcon from "../../../../../assets/icons/outline/parameters.svg";
import VariableIcon from "../../../../../assets/icons/outline/variables.svg";
import { MSubreportCustom } from "./MSubreportCustom";
import { DsObject, PDsObject } from "../dataset/UPDsObject";

export const FSubreport = {
    runToBottom: new PBoolean({ id: 'runToBottom', label: 'Run To Bottom', canBeNull: true }),
    isUsingCache: new PBoolean({ id: 'isUsingCache', label: 'Use Cache', canBeNull: true }),
    subreportExpression: new PExpression({ id: 'subreportExpression', label: 'Expression' }),
    overflowType: OverflowTypeEnum
};

export const FSubreportParameter = {
    name: new PDsObject({ id: 'name', label: 'Name', types: [DsObject.PARAMETER] }),
    expression: new PExpression({ id: 'expression', label: 'Expression' }),
};

export const FSubreportReturnValue = {
    subreportVariable: new PString({ id: 'subreportVariable', label: 'From Variable', canBeEmpty: false })
};

export const MSubreport: APDescriptor = {
    type: ElementTypes.SUBREPORT,
    label: 'Subreport',
    id: 'subreportPropertiesDescriptor',
    default: { width: 200, height: 200 },
    icon: ICONS.SUBREPORT_ICON,
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MGraphicElement.layouts, MSubreportCustom] }),
            new PDiv({
                label: 'Subreport', layouts: [FSubreport.runToBottom, FSubreport.overflowType, FSubreport.isUsingCache, FSubreport.subreportExpression,
                new PAccordeon({
                    id: 'datasource', label: 'Datasource',
                    layouts: [FDatasetRun.connectionExpression, FDatasetRun.dataSourceExpression]
                }), new PAccordeon({
                    id: 'Parameters', label: 'Set Parameters Values', description: 'Set parameter values for the subreport dataset',
                    layouts: [FDatasetRun.parametersMapExpression, new PConnectedTable({
                        id: 'subreportParameters', label: 'Values', description: 'Set value for parameters defined in the subreport dataset',
                        rowLabel: 'Parameter', rowLabelPaths: ['name'], layouts: [new PTableElement({
                            label: 'Parameter',
                            icon: ParameterIcon,
                            anchorOrigin: { vertical: 'center', horizontal: 'right' },
                            transformOrigin: { vertical: 'center', horizontal: 'right' },
                            layouts: [FDatasetParameter.name, FDatasetParameter.expression]
                        })],
                        default: { name: 'Parameter', expression: '' }
                    })]
                }), new PConnectedTable({
                    id: 'returnValues', label: 'Update Main Dataset Variables', description: 'You can update main dataset variables with a value',
                    rowLabel: 'Return Value', rowLabelPaths: ['name'],
                    layouts: [new PTableElement({
                        label: 'Return Value',
                        icon: VariableIcon,
                        anchorOrigin: { vertical: 'center', horizontal: 'right' },
                        transformOrigin: { vertical: 'center', horizontal: 'right' },
                        layouts: [FSubreportReturnValue.subreportVariable, FReturnValue.toVariable, FReturnValue.calculation, FReturnValue.incrementerFactoryClassName]
                    })],
                    default: { calculation: 'Sum' }
                })]
            })]
    })]
};
