/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnumGroup } from "../dataset/UPEnumGroup";

export const EvaluationTimeEnum = new PEnumGroup({
    id: 'evaluationTime', label: 'EvaluationTime.label', default: 'Now',
    groupProperty: 'evaluationGroup',
    items: [
        { key: 'Now', value: 'EvaluationTime.Now' },
        { key: 'Report', value: 'EvaluationTime.Report' },
        { key: 'Page', value: 'EvaluationTime.Page' },
        { key: 'Column', value: 'EvaluationTime.Column' },
        { key: 'Group', value: 'EvaluationTime.Group' },
        { key: 'Band', value: 'EvaluationTime.Band' },
        { key: 'Auto', value: 'EvaluationTime.Auto' },
        { key: 'Master', value: 'EvaluationTime.Master' }
    ]
});

export const PartEvaluationTimeEnum = new PEnumGroup({
    id: 'evaluationTime', label: 'EvaluationTime.label', default: 'Now',
    groupProperty: 'evaluationGroup',
    items: [
        { key: 'Now', value: 'EvaluationTime.Now' },
        { key: 'Report', value: 'EvaluationTime.Report' },
        { key: 'Group', value: 'EvaluationTime.Group' }
    ]
});

