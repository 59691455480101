/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const PieChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="440" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 440" {...props}>
    <defs>
       <clipPath>
          <path fill="none" d="M0 0H535V350H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="440" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M10 10H545V360H10z" className="highcharts-plot-background" />
    <path fill="none" d="M10 10H545V360H10z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="0.1">
          <path fill="#7cb5ec" stroke="#fff" strokeLinejoin="round" d="M267.473 42A133.5 133.5 0 01374.18 95.24L267.5 175.5h0z" className="highcharts-point highcharts-color-0" transform="translate(10 10)" />
          <path fill="#434348" stroke="#fff" strokeLinejoin="round" d="M374.26 95.347a133.5 133.5 0 0125.614 62.847L267.5 175.5h0z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#90ed7d" stroke="#fff" strokeLinejoin="round" d="M399.89 158.326a133.5 133.5 0 01-118.558 149.956L267.5 175.5h0z" className="highcharts-point highcharts-color-2" transform="translate(10 10)" />
          <path fill="#f7a35c" stroke="#fff" strokeLinejoin="round" d="M281.2 308.295a133.5 133.5 0 01-20.4.537l6.7-133.332h0z" className="highcharts-point highcharts-color-3" transform="translate(10 10)" />
          <path fill="#8085e9" stroke="#fff" strokeLinejoin="round" d="M260.667 308.825a133.5 133.5 0 01-33.664-6.116L267.5 175.5h0z" className="highcharts-point highcharts-color-4" transform="translate(10 10)" />
          <path fill="#f15c80" stroke="#fff" strokeLinejoin="round" d="M226.876 302.669a133.5 133.5 0 01-61.775-41.514L267.5 175.5h0z" className="highcharts-point highcharts-color-5" transform="translate(10 10)" />
          <path fill="#e4d354" stroke="#fff" strokeLinejoin="round" d="M165.016 261.052a133.5 133.5 0 01-11.91-16.733L267.5 175.5h0z" className="highcharts-point highcharts-color-6" transform="translate(10 10)" />
          <path fill="#2b908f" stroke="#fff" strokeLinejoin="round" d="M153.037 244.205a133.5 133.5 0 01-18.625-58.224L267.5 175.5h0z" className="highcharts-point highcharts-color-7" transform="translate(10 10)" />
          <path fill="#f45b5b" stroke="#fff" strokeLinejoin="round" d="M134.402 185.848a133.5 133.5 0 0118.553-78.917L267.5 175.5h0z" className="highcharts-point highcharts-color-8" transform="translate(10 10)" />
          <path fill="#91e8e1" stroke="#fff" strokeLinejoin="round" d="M153.024 106.816a133.5 133.5 0 0116.379-21.865L267.5 175.5h0z" className="highcharts-point highcharts-color-9" transform="translate(10 10)" />
          <path fill="#7cb5ec" stroke="#fff" strokeLinejoin="round" d="M169.493 84.853a133.5 133.5 0 0184.096-42.126L267.5 175.5h0z" className="highcharts-point highcharts-color-0" transform="translate(10 10)" />
          <path fill="#434348" stroke="#fff" strokeLinejoin="round" d="M253.722 42.713A133.5 133.5 0 01267.315 42l.185 133.5h0z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
       </g>
    </g>
    <g className="highcharts-data-labels highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="6">
       <path fill="none" stroke="#7cb5ec" d="M345.6 29.251c-5 0-13.048 16.101-15.73 21.468l-2.683 5.367" className="highcharts-data-label-connector highcharts-color-0" transform="translate(10 10)" />
       <path fill="none" stroke="#434348" d="M423.954 113.905c-5 0-21.674 6.781-27.232 9.041l-5.558 2.26" className="highcharts-data-label-connector highcharts-color-1" transform="translate(10 10)" />
       <path fill="none" stroke="#90ed7d" d="M400.704 276.97c-5 0-19.115-11.171-23.82-14.895l-4.704-3.724" className="highcharts-data-label-connector highcharts-color-2" transform="translate(10 10)" />
       <path fill="none" stroke="#f7a35c" d="M276.71 338.946c-5 0-5.464-17.994-5.618-23.992l-.155-5.998" className="highcharts-data-label-connector highcharts-color-3" transform="translate(10 10)" />
       <path fill="none" stroke="#8085e9" d="M233.188 336.351c5 0 8.227-17.708 9.302-23.611l1.076-5.903" className="highcharts-data-label-connector highcharts-color-4" transform="translate(10 10)" />
       <path fill="none" stroke="#f15c80" d="M171.227 311.152c5 0 15.049-14.934 18.398-19.912l3.35-4.978" className="highcharts-data-label-connector highcharts-color-5" transform="translate(10 10)" />
       <path fill="none" stroke="#e4d354" d="M129.243 270.235c5 0 19.67-10.43 24.56-13.906l4.89-3.476" className="highcharts-data-label-connector highcharts-color-6" transform="translate(10 10)" />
       <path fill="none" stroke="#2b908f" d="M106.744 225.223c5 0 22.148-5.474 27.863-7.299l5.716-1.825" className="highcharts-data-label-connector highcharts-color-7" transform="translate(10 10)" />
       <path fill="none" stroke="#f45b5b" d="M103.371 137.946c5 0 22.519 4.134 28.359 5.513l5.84 1.378" className="highcharts-data-label-connector highcharts-color-8" transform="translate(10 10)" />
       <path fill="none" stroke="#91e8e1" d="M131.726 77.365c5 0 19.397 10.804 24.196 14.405l4.8 3.601" className="highcharts-data-label-connector highcharts-color-9" transform="translate(10 10)" />
       <path fill="none" stroke="#7cb5ec" d="M178.882 35c5 0 23.566 10.352 26.248 15.72l2.683 5.366" className="highcharts-data-label-connector highcharts-color-0" transform="translate(10 10)" />
       <path fill="none" stroke="#434348" d="M254.083 12c5 0 5.927 18.193 6.236 24.185l.309 5.992" className="highcharts-data-label-connector highcharts-color-1" transform="translate(10 10)" />
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(351 19)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Albuquerque</tspan>
          <tspan x="5" y="16">Albuquerque</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(429 104)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Anchorage</tspan>
          <tspan x="5" y="16">Anchorage</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(406 267)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Boise</tspan>
          <tspan x="5" y="16">Boise</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(282 329)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Butte</tspan>
          <tspan x="5" y="16">Butte</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(190 326)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Elgin</tspan>
          <tspan x="5" y="16">Elgin</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(115 301)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Eugene</tspan>
          <tspan x="5" y="16">Eugene</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(66 260)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Kirkland</tspan>
          <tspan x="5" y="16">Kirkland</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(53 215)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Lander</tspan>
          <tspan x="5" y="16">Lander</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(41 128)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Portland</tspan>
          <tspan x="5" y="16">Portland</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(40 67)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">San Francisco</tspan>
          <tspan x="5" y="16">San Francisco</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(126 25)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Seattle</tspan>
          <tspan x="5" y="16">Seattle</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(175 2)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Walla Walla</tspan>
          <tspan x="5" y="16">Walla Walla</tspan>
       </text>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(39 372)">
       <rect width="476" height="53" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Albuquerque</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-1" data-z-index="1" transform="translate(128.156 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Anchorage</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-2" data-z-index="1" transform="translate(248.313 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Boise</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#90ed7d" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-3" data-z-index="1" transform="translate(368.469 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Butte</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#f7a35c" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-4" data-z-index="1" transform="translate(8 17)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Elgin</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#8085e9" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-5" data-z-index="1" transform="translate(128.156 17)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Eugene</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#f15c80" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-6" data-z-index="1" transform="translate(248.313 17)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Kirkland</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#e4d354" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-7" data-z-index="1" transform="translate(368.469 17)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Lander</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#2b908f" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-8" data-z-index="1" transform="translate(8 31)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Portland</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#f45b5b" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-9" data-z-index="1" transform="translate(128.156 31)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>San Francisco</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#91e8e1" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-0" data-z-index="1" transform="translate(248.313 31)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Seattle</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-1" data-z-index="1" transform="translate(368.469 31)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Walla Walla</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
       </g>
    </g>
 </svg>)};

export default PieChartIcon;