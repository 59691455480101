/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, i18n } from '@jss/js-common';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


interface IConfirmSwitchDialog {
    handleConfirm: (rememberChoice: boolean) => void,
    onEnd: () => void,
}

export const ConfirmSwitchDialog : React.FunctionComponent<IConfirmSwitchDialog> = ({ handleConfirm, onEnd }) => {

    const [rememberChoice, setRememberChoice] = useState(false);

    const  onDontAskAnymoreChange = (event: React.FormEvent<HTMLElement>) => {
        const value = (event.target as HTMLInputElement).checked;
        setRememberChoice(value);
    }
    return <Dialog
        open={true}
        onClose={onEnd}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {i18n.t('components.report.reportUnitWarning')}
            </DialogContentText>
            <FormControlLabel
                    control={
                        <Checkbox
                            checked={rememberChoice}
                            onChange={onDontAskAnymoreChange}
                            name="dontAskAnymore"
                            color="primary"
                        />
                    }
                    label={i18n.t('components.report.dontAskAnymore')}
                />
        </DialogContent>
        <DialogActions>
            <Button onClick={onEnd} size="large" color="secondary" variant="contained">{i18n.t('common.button.cancel')}</Button>
            <Button onClick={() => {
                handleConfirm(rememberChoice);
                onEnd();
            }} size="large" color="primary" variant="contained">{i18n.t('common.button.yes')}</Button>
        </DialogActions>
    </Dialog>
}