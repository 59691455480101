/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import '../../../../assets/uxpl/css/SelectionFigure.css';

import { fromJS, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import * as Actions from '../../../../actions/reportActions';
import * as DocumentUtils from '../../../../sagas/report/designer/documentUtils';
import { Direction, IPoint } from '../../../../types/geometry';

interface ISelectionFigure {
  id: string; // The element id
  zoom: number;
  position: React.CSSProperties,
  forbidResize?: boolean,
  initElementResizeOp?: (point: IPoint, options: any) => void;
  onClick?: () => void;
  onDoubleClick?: () => void;
}

/**
 * TODO: Handle figures are taking way too long, needs to revisit, we should consider to
 * transform FigureHandle in a functional component.
 * 
 * @param props 
 */
class SelectionFigure extends React.Component<ISelectionFigure> {


  public shouldComponentUpdate(nextProps: ISelectionFigure) {

    // The component should change only when the position changes...
    return (this.props.zoom !== nextProps.zoom ||
      this.props.position.top !== nextProps.position.top ||
      this.props.position.left !== nextProps.position.left ||
      this.props.position.width !== nextProps.position.width ||
      this.props.position.height !== nextProps.position.height);
  }

  private onClick = () => {
    if (this.props.onClick){
      this.props.onClick();
    }
  }

  private onDoubleClick = () => {
    if (this.props.onDoubleClick){
      this.props.onDoubleClick();
    }
  }


  public render() {

    const zoom = this.props.zoom ? this.props.zoom : 1;
    const h = Math.max(1, this.props.position.height as number);
    const w = Math.max(1, this.props.position.width as number);
    const r = 5 * zoom;
    let resizeHandle;
    if (!this.props.forbidResize) {
      resizeHandle = <svg className="BaseElementBorder" width={w} height={h} style={{ position: 'absolute' }}>
        <rect shapeRendering="optimizeSpeed" width="100%" height="100%" strokeWidth={1 / this.props.zoom} style={{ fill: 'none', stroke: '#0081cb' }} />
        <circle className={'SelectionFigureHandle FigureHandle-' + Direction.TOP_LEFT} cx="0" cy="0" r={r}
          onMouseDown={this.onMouseDownCb(Direction.TOP_LEFT)}
          onTouchStart={this.onTouchStartCb(Direction.TOP_LEFT)} />
        <circle className={'SelectionFigureHandle FigureHandle-' + Direction.TOP} cx={w / 2} cy="0" r={r}
          onMouseDown={this.onMouseDownCb(Direction.TOP)}
          onTouchStart={this.onTouchStartCb(Direction.TOP)} />
        <circle className={'SelectionFigureHandle FigureHandle-' + Direction.TOP_RIGHT} cx={w} cy="0" r={r}
          onMouseDown={this.onMouseDownCb(Direction.TOP_RIGHT)}
          onTouchStart={this.onTouchStartCb(Direction.TOP_RIGHT)} />
        <circle className={'SelectionFigureHandle FigureHandle-' + Direction.RIGHT} cx={w} cy={h / 2} r={r}
          onMouseDown={this.onMouseDownCb(Direction.RIGHT)}
          onTouchStart={this.onTouchStartCb(Direction.RIGHT)} />
        <circle className={'SelectionFigureHandle FigureHandle-' + Direction.BOTTOM_RIGHT} cx={w} cy={h} r={r}
          onMouseDown={this.onMouseDownCb(Direction.BOTTOM_RIGHT)}
          onTouchStart={this.onTouchStartCb(Direction.BOTTOM_RIGHT)} />
        <circle className={'SelectionFigureHandle FigureHandle-' + Direction.BOTTOM} cx={w / 2} cy={h} r={r}
          onMouseDown={this.onMouseDownCb(Direction.BOTTOM)}
          onTouchStart={this.onTouchStartCb(Direction.BOTTOM)} />
        <circle className={'SelectionFigureHandle FigureHandle-' + Direction.BOTTOM_LEFT} cx="0" cy={h} r={r}
          onMouseDown={this.onMouseDownCb(Direction.BOTTOM_LEFT)}
          onTouchStart={this.onTouchStartCb(Direction.BOTTOM_LEFT)} />
        <circle className={'SelectionFigureHandle FigureHandle-' + Direction.LEFT} cx="0" cy={h / 2} r={r}
          onMouseDown={this.onMouseDownCb(Direction.LEFT)}
          onTouchStart={this.onTouchStartCb(Direction.LEFT)} />
      </svg>
    } else {
      resizeHandle = <svg className="BaseElementBorder" width={w} height={h} style={{ position: 'absolute' }}>
        <rect shapeRendering="optimizeSpeed" width="100%" height="100%" strokeWidth={1 / this.props.zoom} style={{ fill: 'none', stroke: '#0081cb' }} />
      </svg>
    }
    return (<div onClick={this.onClick} onDoubleClick={this.onDoubleClick} className="SelectionFigure" style={{ ...this.props.position, background: 'transparent' }}>
      {resizeHandle}
    </div>);
  }


  private onMouseDownCb = (d: Direction) => {
    return (e: React.MouseEvent<SVGCircleElement>) => this.onMouseDown(e, d);
  }

  private onTouchStartCb = (d: Direction) => {
    return (e: React.TouchEvent) => this.onTouchStart(e, d);
  }

  private onMouseDown = (e: React.MouseEvent<SVGCircleElement>, direction: Direction) => {

    // consider only left mouse button
    if (e.button !== 0) {
      return
    }

    // Let's stop propagation, so no other listeners will get this click...
    e.stopPropagation()
    e.preventDefault()

    // Kick a drag op operation...

    const documentLocation = DocumentUtils.convertPageToDesignerCoords({ x: e.pageX, y: e.pageY });

    if (this.props.initElementResizeOp) {
      this.props.initElementResizeOp(documentLocation, {
        direction
      });
    }

  }

  private onTouchStart = (e: React.TouchEvent, direction: Direction) => {

    const documentLocation = DocumentUtils.convertPageToDesignerCoords({ x: e.touches[0].pageX, y: e.touches[0].pageY });
    e.stopPropagation()
    e.preventDefault()

    if (this.props.initElementResizeOp) {
      this.props.initElementResizeOp(documentLocation, {
        direction
      });
    }

  }

}

const mapDispatchToProps = (dispatch: any) => {
  return {
    initElementResizeOp: (point: IPoint, options: any) => {
      dispatch(Actions.setDragOperation(Actions.DragOperations.DRAG_OP_ELEMENT_RESIZE, fromJS(point), Map(options)));
    },
  };
}

export default connect(null, mapDispatchToProps)(SelectionFigure);   // ,mapDispatchToProps

