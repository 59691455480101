/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { FFusionWidgetProps, FFusionWidgets, MFusionWidgetCommon } from "./MFusionWidgetsCommon";
import SparkChartIcon from "../../../../../../assets/icons/charts/fusion/spark_line.svg";
import { PDiv } from "../../../ui/composite/UPDiv";
import { MHyperlinks } from "../../common/MHyperlinks";
import { MDatasetRun } from "../../dataset/MDatasetRun";
import { PConnectedTable } from "../../../ui/tables/UPConnectedTable";
import { PTableElement } from "../../../ui/tables/UPTableElement";
import { PCategories } from "../../../ui/composite/UPCategories";
import { PConnectedTableDetail } from "../../../ui/tables/UPConnectedTableDetail";
import { FFusionChart } from "../chart/MFusionChart";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";

export const MFusionSparkLine: APDescriptor = {
    id: 'SparkLine', type: 'fusion.chart.SparkLine', label: 'Spark Line', icon: SparkChartIcon, path: 'widgetProperties',
    layouts: [...MFusionWidgetCommon.layouts,
    new PAccordeon({
        id: 'sc.SparkLine', label: "Spark Line", layouts: [
            new PAccordeon({
                id: 'butllet.period', label: "Period",
                layouts: [FFusionWidgets.periodColor, FFusionWidgets.periodAlpha, FFusionWidgets.periodLength]
            }),
            new PAccordeon({
                id: 'butllet.line', label: "Line",
                layouts: [FFusionWidgetProps.lineColor, FFusionWidgetProps.lineAlpha, FFusionWidgetProps.lineThickness]
            }),
            new PAccordeon({
                id: 'gauge.limits', label: "Limits",
                layouts: [FFusionWidgetProps.yAxisMinValue, FFusionWidgets.yAxisMaxValue, FFusionWidgetProps.setAdaptiveYMin,
                FFusionWidgets.highColor, FFusionWidgets.lowColor, FFusionWidgets.openColor, FFusionWidgets.closeColor
                ]
            })
        ]
    })],
    default: {
        type: 'FUSION_WIDGET', widgetType: 'SparkLine',
        valuesDataset: { dataset: {}, valueExpressions: [{ valueExpression: "" }] }
    }
};

export const MFusionSparkLineDataset: APDescriptor = {
    id: 'SparkLine', type: 'fusion.chart.SparkLine', label: 'Spark Line', icon: SparkChartIcon,
    layouts: [
        new PCategories({
            label: 'Data', layouts: [
                new PDiv({
                    id: 'fusion.data.dataset', path: 'valuesDataset.dataset',
                    label: 'Dataset',
                    layouts: [FFusionWidgets.incrementType, FFusionWidgets.incrementWhenExpression, FFusionWidgets.resetType, MDatasetRun]
                }),
                new PDiv({
                    id: 'fusion.data.values', path: 'valuesDataset',
                    label: 'Values',
                    layouts: [new PConnectedTable({
                        id: 'valueExpressions', label: 'Values', rowLabel: 'Value', rowLabelPaths: ['valueExpression'], expanded: true,
                        layouts: [new PTableElement({
                            label: 'Value',
                            anchorOrigin: { vertical: 'center', horizontal: 'right' },
                            transformOrigin: { vertical: 'center', horizontal: 'right' },
                            layouts: [FFusionWidgets.valueExpression]
                        })],
                        default: { valueExpression: 'new Double(20)' }
                    })]
                }),
                new PConnectedTableDetail({
                    id: 'trendLines', label: 'Trend Lines', rowLabel: 'Trend Line', rowLabelPaths: ['color', 'labelExpression'], expanded: true, layouts: [new PDiv({
                        id: 'fusion.colorranges',
                        layouts: [FFusionChart.color, FFusionChart.trendZone, FFusionChart.labelExpression, FFusionChart.startValueExpression, FFusionChart.endValueExpression
                        ]
                    })], default: { color: '#000000' }
                }),
                new PDiv({
                    id: 'fusion.data.valuesets.hyperlinks',
                    label: 'Hyperlink', path: 'hyperlink',
                    layouts: [...MHyperlinks.layouts]
                })]
        })]
};
