
/*
* Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
* Licensed under commercial Jaspersoft Subscription License Agreement
*/
import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button as JSSButton, IconButton, IToolbarContributor, IToolBarItemOptions, IToolbarProvider, Select, Typography } from '@jss/js-common';
import { RunContext } from '@jss/js-repository';
import { DatasourceApi, IRepositoryItemDescriptor, RepositoryApi } from '@jss/js-rest-api';
import ace from 'ace-builds';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Map as ImmutableMap, OrderedMap } from 'immutable';
import { Base64 } from 'js-base64';
import AceEditor from 'react-ace';
import { connect } from 'react-redux';
import * as ReportActions from '../../../actions/reportActions';
import i18n from '../../../i18n';
import { IState } from '../../../reducers';
import { getDatasetReport } from '../../../sagas/report/document/documentFactory';
import SimplyBeautiful from 'simply-beautiful';
import xmlFormat from 'xml-formatter';
import { ParameterQueryFormatter } from '../../../utils/SqlFormatter';
import format from 'xml-formatter';
import { AxiosResponse } from 'axios';
import { ElementTypes } from '../../../sagas/report/document/elementTypes';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface IQueryEditor {
    report?: ImmutableMap<string, any>;
    model?: ImmutableMap<string, any>;
    setObjectProperties?: (path: string[], value: any) => void;
    addProperties?: (path: string[], element: any[]) => void;
    setWidgetStatus?: (path, data) => void;
    descriptor?: IRepositoryItemDescriptor | undefined,
    parametersValue?: ImmutableMap<string, any>,
}

interface IQueryEditorState {
    isPreviewInProgress: boolean
    selectedRecordCount: string,
    previewData: { names: string[], rows: (string | number)[][] } | undefined,
}

class QueryEditor extends React.Component<IQueryEditor, IQueryEditorState> implements IToolbarContributor {

    protected editor = React.createRef<AceEditor>();

    public state = {
        isPreviewInProgress: false,
        selectedRecordCount: 'first10',
        previewData: undefined,
    }

    private isDetailExpanded = () => {
        return this.props.report.getIn(['widgetStatus', 'queryEditorDetails', 'expanded'], true) as boolean;
    }

    componentDidUpdate = () => {
        if (this.editor.current !== null) {
            const currentAceEditor = this.editor.current.editor;
            const doFormatCommand = () => {
                this.doFormat();
            }
            currentAceEditor.commands.addCommand({
                name: "Format Text",
                bindKey: { win: "Ctrl-Alt-l", mac: "Command-Alt-l" },
                exec: doFormatCommand,
            });
            currentAceEditor.commands.addCommand({
                name: "showKeyboardShortcuts",
                bindKey: { win: "Ctrl-Alt-h", mac: "Command-Alt-h" },
                exec: function (editor) {
                    ace.config.loadModule("ace/ext/keybinding_menu", function (module) {
                        module.init(editor);
                        currentAceEditor.showKeyboardShortcuts()
                    })
                }
            })
        }
    }


    getPreviewItems = () => {
        return [
            {
                key: 'first10',
                value: i18n.t('queryeditor.querypreview.first10'),
            },
            {
                key: 'first100',
                value: i18n.t('queryeditor.querypreview.first100'),
            },
            {
                key: 'first500',
                value: i18n.t('queryeditor.querypreview.first500')
            },
            {
                key: 'first1000',
                value: i18n.t('queryeditor.querypreview.first1000')
            },
        ];
    }

    private readFields = () => {
        const descriptor = this.props.descriptor;
        const editorIndex = this.props.report.get('currentEditorIndex');
        const type = this.props.report.getIn(['subeditors', editorIndex, 'type']) as string;
        if (type === 'datasetQueryEditor') {
            const editedResourceId = this.props.report.getIn(['subeditors', editorIndex, 'editedResourceId']) as string;
            let dataset;
            let datasetPath
            if (editedResourceId !== 'main') {
                datasetPath = ['model', 'subdatasets', editedResourceId, 'fields'];
                dataset = this.props.report.getIn(['model', 'subdatasets', editedResourceId]);
            } else {
                datasetPath = ['model', 'fields'];
                dataset = this.props.report.getIn(['model']);
            }

            const jrxml = getDatasetReport(dataset);
            const base64data = Base64.encode(format(jrxml, { indentation: '  ', collapseContent: true }));
            const newDescriptor: IRepositoryItemDescriptor = {
                ...descriptor,
                uuid: undefined,
                data: base64data,
                path: RepositoryApi.getAPathOnly(this.props.descriptor)
            }
            this.context.runNoCancel(RepositoryApi.inst().tmp().save)(newDescriptor.path ? newDescriptor.path : '/', newDescriptor, newDescriptor).then(() => {
                const stateParmeters = this.props.report.get('parametersValue');
                const params = [];
                if (stateParmeters) {
                    stateParmeters.forEach((value: any, key: string) => {
                        params.push({
                            key,
                            value,
                        });
                    });
                }
                this.context.run(DatasourceApi.inst().readFields)(RepositoryApi.getAPath(this.props.descriptor), params).then((response: AxiosResponse) => {
                    if (response.data) {
                        const fields = response.data.map((field) => {
                            const modelField = {
                                name: field.name,
                                type: ElementTypes.FIELD,
                                class: field.type,
                                fieldDescription: field.label,
                                properties: OrderedMap<string, ImmutableMap<string, any>>(),
                            }
                            if (field.properties) {
                                let properties = modelField.properties;
                                field.properties.forEach(property => {
                                    if (property.valueExpression) {
                                        let oldValue = properties.get(property.key, ImmutableMap<string, any>()) as ImmutableMap<string, any>;
                                        oldValue = oldValue.set('valueExpression', property.valueExpression);
                                        properties = properties.set(property.key, oldValue);
                                    } else {
                                        let oldValue = properties.get(property.key, ImmutableMap<string, any>()) as ImmutableMap<string, any>;
                                        oldValue = oldValue.set('value', property.value);
                                        properties = properties.set(property.key, oldValue);
                                    }
                                });
                                modelField.properties = properties;
                            }
                            return modelField;
                        });
                        this.props.addProperties(datasetPath, fields);
                    }

                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    contributeToolbar = () => {
        const currentAceEditor = this.editor.current.editor;
        const toolbarProvider: IToolbarProvider = {
            getMenus: () => {
                return [];
            },
            getToolItems: () => {
                const items = [
                    {
                        id: 'formatToolaction',
                        render: (options?: IToolBarItemOptions) => {
                            const height = options && options.height ? options.height : 24;
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div title={i18n.t('queryeditor.format.tooltip')} style={{ height: height, cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={this.doFormat}>
                                    <IconButton icon={'textACenter'} size="small" />
                                </div>
                            </div>;
                        }
                    },
                    {
                        id: 'helpToolAction',
                        render: (options?: IToolBarItemOptions) => {
                            const height = options && options.height ? options.height : 24;
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                                <div title={i18n.t('queryeditor.help.tooltip')} style={{ height: height, cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                                    currentAceEditor.execCommand("showKeyboardShortcuts");
                                }}>
                                    <HelpOutlineIcon className='jr-iconColor' />
                                </div>
                            </div>;
                        }
                    },
                    {
                        id: 'readFieldsButton',
                        render: () => {
                            return <JSSButton size="large" color="primary" variant="contained" style={{ marginLeft: '10px' }} onClick={this.readFields}>Read Fields</JSSButton>
                        }
                    }
                ];
                return items;
            },
        }
        return toolbarProvider;
    }

    doFormat = () => {
        const editorIndex = this.props.report.get('currentEditorIndex');
        const editedResourceId = this.props.report.getIn(['subeditors', editorIndex, 'editedResourceId']) as string;
        const language = this.getEditorLanguage();
        const onEditorChange = (newQuery: string) => {
            if (editedResourceId === 'main') {
                this.props.setObjectProperties(['model'], { queryString: newQuery });
            } else {
                this.props.setObjectProperties(['model', 'subdatasets', editedResourceId], { queryString: newQuery });
            }
        }
        const currentQuery = editedResourceId !== 'main' ? this.props.report.getIn(['model', 'subdatasets', editedResourceId, 'queryString']) as string : this.props.report.getIn(['model', 'queryString']) as string;
        if (language === 'sql') {
            const formatterQuery = new ParameterQueryFormatter({}).format(currentQuery);
            onEditorChange(formatterQuery);
        } else if (language === 'xquery' || language === 'xml') {
            onEditorChange(xmlFormat(currentQuery, { indentation: '  ', collapseContent: true }));
        } else if (language === 'json') {
            const options = {
                indent_size: 2,
            };
            onEditorChange(SimplyBeautiful.json(currentQuery, options));
        }
    }

    private handleExpanded = () => {
        this.props.setWidgetStatus(['queryEditorDetails', 'expanded'], !this.isDetailExpanded());
    }

    private getDatasetReport = (nameSuffix: string) => {
        const editorIndex = this.props.report.get('currentEditorIndex');
        const editedResourceId = this.props.report.getIn(['subeditors', editorIndex, 'editedResourceId']) as string;
        let dataset;
        if (editedResourceId !== undefined && editedResourceId !== 'main') {
            dataset = this.props.report.getIn(['model', 'subdatasets', editedResourceId]);
        } else {
            dataset = this.props.report.getIn(['model']);
        }
        const jrxml = getDatasetReport(dataset);
        const base64data = Base64.encode(jrxml);
        const descriptor: IRepositoryItemDescriptor = this.props.descriptor;
        const newDescriptor: IRepositoryItemDescriptor = {
            ...descriptor,
            data: base64data,
            name: (descriptor.name.endsWith('.jrxml') ? descriptor.name.substring(0, descriptor.name.length - 6) + nameSuffix + '.jrxml' : descriptor.name + nameSuffix),
        }
        newDescriptor.path = RepositoryApi.getAPathOnly(this.props.descriptor);
        newDescriptor.uuid = undefined;

        return newDescriptor;
    }

    private startPreview = () => {
        if (this.props.descriptor) {
            this.setState({ isPreviewInProgress: true }, () => {
                const newDescriptor = this.getDatasetReport('previewReport');
                this.context.runNoCancel(RepositoryApi.inst().tmp().save)(newDescriptor.path ? newDescriptor.path : '/', newDescriptor).then(() => {
                    const params = [];
                    if (this.state.selectedRecordCount === 'first10') {
                        params.push({
                            key: 'REPORT_MAX_COUNT',
                            value: 10,
                        });
                    } else if (this.state.selectedRecordCount === 'first100') {
                        params.push({
                            key: 'REPORT_MAX_COUNT',
                            value: 100,
                        });
                    } else if (this.state.selectedRecordCount === 'first500') {
                        params.push({
                            key: 'REPORT_MAX_COUNT',
                            value: 500,
                        });
                    } else if (this.state.selectedRecordCount === 'first1000') {
                        params.push({
                            key: 'REPORT_MAX_COUNT',
                            value: 1000,
                        });
                    }
                    if (this.props.parametersValue) {
                        this.props.parametersValue.forEach((value: any, key: string) => {
                            params.push({
                                key,
                                value,
                            });
                        });
                    }
                    this.context.run(DatasourceApi.inst().runQuery)(RepositoryApi.getAPath(newDescriptor), params).then((response) => {
                        this.setState({ isPreviewInProgress: false, previewData: response.data });
                    }).catch(() => {
                        this.setState({ isPreviewInProgress: false });
                    });
                });
            });
        }
    }

    private onRecordCountChange = (newCount: string) => {
        this.setState({ selectedRecordCount: newCount })
    }

    private getPreviewArea = () => {
        let colunms = [];
        let data = [];
        if (this.state.previewData) {
            colunms = this.state.previewData.names.map((name: string, index: number) => {
                return <AgGridColumn key={name} field={index.toString()} resizable={true} headerName={name} />
            });
            data = this.state.previewData.rows.map((row: string[]) => {
                const result = {};
                row.forEach((fieldValue: string, index: number) => {
                    const fieldName = index.toString();
                    result[fieldName] = fieldValue;
                });
                return result;
            });
        }
        return <div style={{ display: 'flex', alignItems: 'stretch', flexDirection: 'column', minHeight: 300, flex: 1 }}>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
                <JSSButton disabled={this.state.isPreviewInProgress} size="large" color="primary" variant="contained" style={{ marginRight: '10px' }} onClick={this.startPreview}>{i18n.t('queryeditor.querypreview.previewbutton')}</JSSButton>
                <Select
                    items={this.getPreviewItems()}
                    value={this.state.selectedRecordCount}
                    onItemSelected={this.onRecordCountChange} />
            </div>
            <div
                className="ag-theme-balham"
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <AgGridReact rowData={data}>
                    {colunms}
                </AgGridReact>
            </div>
        </div>
    }


    private getEditorLanguage = () => {
        const editorIndex = this.props.report.get('currentEditorIndex');
        let editedResourceId = this.props.report.getIn(['subeditors', editorIndex, 'editedResourceId']) as string;
        let dataset;
        if (editedResourceId && editedResourceId !== 'main') {
            dataset = this.props.report.getIn(['model', 'subdatasets', editedResourceId]);
        } else {
            dataset = this.props.report.getIn(['model']);
            editedResourceId = 'main';
        }
        let language = dataset.get('queryLanguage', 'sql');
        if (language !== null){
            language = language.toLowerCase();
        }
        if (language === null || language.trim() === '' || language === 'sql' || language === 'plsql' || language === 'olap4j' || language === 'mdx' || language === 'ejbql' || language === 'hql') {
            return 'sql';
        } else if (language === 'xpath') {
            return 'xquery';
        } else if (language === 'jasperQL') {
            return 'json';
        } else if (language === 'domain') {
            return 'xml';
        }
        return 'text';
    }

    public render() {
        const editorIndex = this.props.report.get('currentEditorIndex');
        const editedResourceId = this.props.report.getIn(['subeditors', editorIndex, 'editedResourceId']) as string;
        const props = {
            mode: this.getEditorLanguage(),
            wrapEnabled: true,
            enableBasicAutocompletion: true,
            tabSize: 2
        }
        const onEditorChange = (newQuery: string) => {
            if (editedResourceId === 'main') {
                this.props.setObjectProperties(['model'], { queryString: newQuery });
            } else {
                this.props.setObjectProperties(['model', 'subdatasets', editedResourceId], { queryString: newQuery });
            }
        }
        const currentQuery = editedResourceId !== 'main' ? this.props.report.getIn(['model', 'subdatasets', editedResourceId, 'queryString']) as string : this.props.report.getIn(['model', 'queryString']) as string;
        return <div style={{ display: 'flex', flex: 1 }}>
            <main style={{ display: 'flex', flex: 1 }}>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
                    <div style={{ display: 'flex', flex: 1 }} contentEditable={true} suppressContentEditableWarning={true}>
                        <AceEditor ref={this.editor} {...props} style={{ paddingTop: 5, flex: 1, height: '100%', width: '100%' }} onChange={onEditorChange} value={currentQuery ? currentQuery : ''} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'stretch', minHeight: 100 }}>
                        <Accordion expanded={this.isDetailExpanded()} onChange={this.handleExpanded} style={{ flex: 1 }} >
                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                                <Typography>{i18n.t('queryeditor.querypreview.title')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flex: 1 }}>
                                    <div style={{ display: 'flex', flex: 1, padding: 8, paddingTop: 0 }} >
                                        {this.getPreviewArea()}
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </main>
        </div>
    }

}

const mapStateToProps = (state: IState) => {
    return {
        descriptor: state.getIn(['report', 'fileDescriptor']),
        report: state.getIn(['report']),
        model: state.getIn(['report', 'model']),
        parametersValue: state.getIn(['report', 'parametersValue']),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setObjectProperties: (path: string[], value: any) => { dispatch(ReportActions.setObjectProperties(path, value)); },
        addProperties: (path: string[], elements) => { dispatch(ReportActions.addProperties(path, elements, true)); },
        setWidgetStatus: (path, data) => { dispatch(ReportActions.setWidgetStatus(path, data)); },
    };
}

QueryEditor.contextType = RunContext;

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(QueryEditor);
