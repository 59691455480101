/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Conf } from "@jss/js-rest-api";

export function formatDateAsString(possibleDate: string | undefined) {
    if (possibleDate) {
        const msTime = Date.parse(possibleDate);
        if (!isNaN(msTime)) {
            const d = new Date(msTime);
            return d.toLocaleDateString(Conf.getLocale()) + " " + d.toLocaleTimeString();
        }
    }
    return "N/A";
}