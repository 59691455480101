/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { connect } from 'react-redux';
import AreaChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/AreaChartIcon';
import Bar3DChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/Bar3DChartIcon';
import BarChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/BarChartIcon';
import BubbleChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/BubbleChartIcon';
import CandleStickChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/CandleStickChartIcon';
import GanttChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/GanttChartIcon';
import HighLowChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/HighLowChartIcon';
import LineChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/LineChartIcon';
import MeterChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/MeterChartIcon';
import MultipleAxisChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/MultipleAxisChart';
import Pie3DChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/Pie3DChartIcon';
import PieChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/PieChartIcon';
import ScatterChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/ScatterChartIcon';
import StackedAreaChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/StackedAreaChartIcon';
import StackedBar3DChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/StackedBar3DChartIcon';
import StackedBarChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/StackedBarChartIcon';
import ThermometerChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/ThermometerChartIcon';
import TimeSeriesChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/TimeSeriesChartIcon';
import XYAreaChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/XYAreaChartIcon';
import XYBarChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/XYBarChartIcon';
import XYLineChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/XYLineChartIcon';
import SpiderChartIcon from '@jss/js-common/src/svg/elements/jfreecharts/SpiderChartIcon';
import { IState } from '../../../reducers';
import { ChartTypes, CHART_TYPE } from '../../common/JrxmlModel/reader/JrxmlChartUtils';

interface IChartElement {
  id: string;
  element?: any;
  zoom?: number;
  availableWidth: number,
  availableHeight: number,
}

const visualProperties = [CHART_TYPE];

class ChartElement extends React.Component<IChartElement> {

  public shouldComponentUpdate = (nextProps: Readonly<IChartElement>) => {
    if (this.props.availableHeight !== nextProps.availableHeight || this.props.availableWidth !== nextProps.availableWidth) {
      return true;
    }

    if (this.props.zoom !== nextProps.zoom) {
      return true;
    }
    for (const propName of visualProperties) {
      if (this.props.element.get(propName) !== nextProps.element.get(propName)) {
        return true;
      }
    }

    return false; // Nothing impacting the content of the figure is changed...
  }

  public render() {
    let image = <AreaChartIcon width='100%' height='100%' preserveAspectRatio='none' />;
    const chartType = this.props.element.get(CHART_TYPE);
    if (chartType === ChartTypes.AREA_CHART_NAME) {
      image = <AreaChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.BAR3D_CHART_NAME) {
      image = <Bar3DChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.BAR_CHART_NAME){
      image = <BarChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.BUBBLE_CHART_NAME){
      image = <BubbleChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.CANDLESTICK_CHART_NAME){
      image = <CandleStickChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.GANTT_CHART_NAME){
      image = <GanttChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.HIGHLOW_CHART_NAME){
      image = <HighLowChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.LINE_CHART_NAME){
      image = <LineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.METER_CHART_NAME){
      image = <MeterChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.MULTIAXIS_CHART_NAME){
      image = <MultipleAxisChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.PIE3D_CHART_NAME){
      image = <Pie3DChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.PIE_CHART_NAME){
      image = <PieChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.SCATTER_CHART_NAME){
      image = <ScatterChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_AREA_CHART_NAME){
      image = <StackedAreaChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_BAR3D_CHART_NAME){
      image = <StackedBar3DChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.STACKED_BAR_CHART_NAME){
      image = <StackedBarChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.THERMOMETER_CHART_NAME){
      image = <ThermometerChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.TIMESERIES_CHART_NAME){
      image = <TimeSeriesChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.XY_AREA_CHART_NAME){
      image = <XYAreaChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.XY_BAR_CHART_NAME){
      image = <XYBarChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.XY_LINE_CHART_NAME){
      image = <XYLineChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    } else if (chartType === ChartTypes.SPIDER_CHART_NAME){
      image = <SpiderChartIcon width='100%' height='100%' preserveAspectRatio='none' />
    }
    return <div style={{ width: '100%', height: '100%' }}>
      {image}
    </div>;
  }
}

const mapStateToProps = (state: IState, props: IChartElement) => {

  return {
    element: state.getIn(['report', 'model', 'elements', props.id]),
    model: state.getIn(['report', 'model']),
    zoom: state.getIn(['report', 'zoom']),
  };
}

export default connect(mapStateToProps)(ChartElement);   // ,mapDispatchToProps