/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from '../../ui/APDescriptor';
import { PAccordeon } from '../../ui/composite/UPAccordeon';
import { Locale } from '../enums/Locale';
import { FDatasets } from '../dataset/MDatasets';
import { SplitTypeEnum } from '../enums/SplitTypeEnum';
import { PString } from '../../ui/primitive/UPString';
import { PColor } from '../../ui/primitive/UPColor';
import { TransactionIsolation } from '../enums/TransactionIsolation';
import { ParameterEvaluationTimeEnum } from '../enums/ParameterEvaluationTimeEnum';
import { WhenNoDataTypeTableEnum } from '../enums/WhenNoDataTypeTableEnum';
import { PdfFieldBorderStyleEnum } from '../enums/PdfFieldBorderStyleEnum';
import { PdfPrintScalingEnum } from '../enums/PdfPrintScalingEnum';
import { PDsValueExpression } from '../../ui/primitive/UPValueExpession';
import { PInt } from "../../ui/primitive/UPInteger";
import { TimeZone } from '../enums/TimeZone';
import { PropertyEvaluationTimeEnum } from '../enums/PropertyEvaluationTimeEnum';
import { PBoolean } from '../../ui/primitive/UPBoolean';
import { PJavaClass } from '../../ui/primitive/UPJavaClass';
import { ImageAnchorTypeEnum } from '../enums/ImageAnchorTypeEnum';
import { PEnum } from '../../ui/primitive/UPEnum';
import { RunDirectionEnum } from '../enums/RunDirectionEnum';
import { WhenNoDataTypeEnum } from '../enums/WhenNoDataTypeEnum';
import { BorderSplitTypeEnum } from '../enums/BorderSplitTypeEnum';

export const MReportCustom: APDescriptor = { type: 'MReportCustom', id: 'MReportCustom', path: 'properties', layouts: [
	new PAccordeon({ id: 'barcode', label: 'barcode', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.barcode4j.image.antiAlias', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.components.barcode4j.image.antiAlias', label: `net.sf.jasperreports.components.barcode4j.image.antiAlias`, description: `Flag property that specify the barcode image should use antialiasing.`, default: 'true', sinceVersion: '3.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'COMPONENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.barcode4j.image.gray', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.components.barcode4j.image.gray', label: `net.sf.jasperreports.components.barcode4j.image.gray`, description: `Flag property that specify the barcode image should use the gray color scheme.`, default: 'true', sinceVersion: '3.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'COMPONENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.barcode4j.image.producer', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.components.barcode4j.image.producer', label: `net.sf.jasperreports.components.barcode4j.image.producer`, description: `Property that determines which image producer to be used for a barcode component element.`, default: 'svg', sinceVersion: '3.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'COMPONENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.barcode4j.image.resolution', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.components.barcode4j.image.resolution', label: `net.sf.jasperreports.components.barcode4j.image.resolution`, description: `Property that specify the barcode image resolution (ppi).`, default: 300, sinceVersion: '3.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'COMPONENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.qrcode.character.encoding', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.components.qrcode.character.encoding', label: `net.sf.jasperreports.components.qrcode.character.encoding`, description: `Property that provides the character encoding to be used for QR code component elements.`, default: 'UTF-8', sinceVersion: '6.5.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'COMPONENT'] }),
	]}),
	new PAccordeon({ id: 'compile', label: 'compile', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.legacy.compiler.source.included.fields', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.legacy.compiler.source.included.fields', label: `net.sf.jasperreports.legacy.compiler.source.included.fields`, description: `Property to specify fields that are to be included in classes generated for reports.`, sinceVersion: '6.13.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.legacy.compiler.source.included.parameters', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.legacy.compiler.source.included.parameters', label: `net.sf.jasperreports.legacy.compiler.source.included.parameters`, description: `Property to specify parameters that are to be included in classes generated for reports.`, sinceVersion: '6.13.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.legacy.compiler.source.included.variables', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.legacy.compiler.source.included.variables', label: `net.sf.jasperreports.legacy.compiler.source.included.variables`, description: `Property to specify variables that are to be included in classes generated for reports.`, sinceVersion: '6.13.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
	]}),
	new PAccordeon({ id: 'crosstab', label: 'crosstab', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.crosstab.floating.headers', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.crosstab.floating.headers', label: `net.sf.jasperreports.crosstab.floating.headers`, description: `Property that enables/disables the floating headers in the crosstab component when scrolling.`, default: 'true', sinceVersion: '6.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'CROSSTAB'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.crosstab.ignore.width', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.crosstab.ignore.width', label: `net.sf.jasperreports.crosstab.ignore.width`, description: `Property that provides the default value for the crosstab ignoreWidth attribute.`, default: 'false', sinceVersion: '3.5.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.crosstab.interactive', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.crosstab.interactive', label: `net.sf.jasperreports.crosstab.interactive`, description: `Property that enables/disables the interactivity in the crosstab component.`, default: 'true', sinceVersion: '5.5.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'CROSSTAB'] }),
	]}),
	new PAccordeon({ id: 'data.cache', label: 'data.cache', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.data.cache.persistable', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.data.cache.persistable', label: `net.sf.jasperreports.data.cache.persistable`, description: `Flag property that specifies if the data snapshot can be persisted.`, default: 'true', sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.data.cache.recordable', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.data.cache.recordable', label: `net.sf.jasperreports.data.cache.recordable`, description: `Flag property that specifies if data recording is enabled.`, default: 'true', sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
	]}),
	new PAccordeon({ id: 'data.source', label: 'data.source', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.csv.date.pattern', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.csv.date.pattern', label: `net.sf.jasperreports.csv.date.pattern`, description: `Property holding the value of the date format pattern to be used when parsing the CSV data.`, sinceVersion: '4.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.csv.encoding', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.csv.encoding', label: `net.sf.jasperreports.csv.encoding`, description: `Property holding the value of the charset used to encode the CSV stream.`, sinceVersion: '4.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.csv.field.delimiter', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.csv.field.delimiter', label: `net.sf.jasperreports.csv.field.delimiter`, description: `Property holding the value of the field delimiter from the CSV source.`, default: ',', sinceVersion: '4.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.csv.locale.code', vtype: {...Locale,custom: true, id: 'net.sf.jasperreports.csv.locale.code', label: `net.sf.jasperreports.csv.locale.code`, description: `Property holding the java.lang.String code of the locale to be used when parsing the CSV data.`, sinceVersion: '4.0.0'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.csv.number.pattern', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.csv.number.pattern', label: `net.sf.jasperreports.csv.number.pattern`, description: `Property holding the value of the number format pattern to be used when parsing the CSV data.`, sinceVersion: '4.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.csv.record.delimiter', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.csv.record.delimiter', label: `net.sf.jasperreports.csv.record.delimiter`, description: `Property holding the value of the record delimiter from the CSV source`, default: '\n', sinceVersion: '4.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.csv.source', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.csv.source', label: `net.sf.jasperreports.csv.source`, description: `String property representing a valid path to the requested resource containing the CSV data.`, sinceVersion: '4.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.csv.timezone.id', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.csv.timezone.id', label: `net.sf.jasperreports.csv.timezone.id`, description: `Property holding the java.lang.String value of the time zone id to be used when parsing the CSV data.`, sinceVersion: '4.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.data.adapter', vtype: {...FDatasets.dataAdapter, path: undefined, custom: true, id: 'net.sf.jasperreports.data.adapter', label: `net.sf.jasperreports.data.adapter`, description: `Property that provides the location of a data adapter resource to be used for the dataset.`, sinceVersion: '4.1.1'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.ejbql.query.page.size', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.ejbql.query.page.size', label: `net.sf.jasperreports.ejbql.query.page.size`, description: `Property specifying the number of result rows to be retrieved at once.`, sinceVersion: '1.2.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.hql.clear.cache', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.hql.clear.cache', label: `net.sf.jasperreports.hql.clear.cache`, description: `Property specifying whether Hibernate session cache should be cleared between two consecutive fetches when using pagination.`, default: 'false', sinceVersion: '1.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.hql.field.mapping.descriptions', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.hql.field.mapping.descriptions', label: `net.sf.jasperreports.hql.field.mapping.descriptions`, description: `Property specifying whether field descriptions should be used to determine the mapping between the fields and the query return values.`, default: 'true', sinceVersion: '1.2.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.hql.query.list.page.size', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.hql.query.list.page.size', label: `net.sf.jasperreports.hql.query.list.page.size`, description: `Property specifying the number of result rows to be retrieved at once when the execution type is list.`, default: 0, sinceVersion: '1.2.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.hql.query.run.type', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.hql.query.run.type', label: `net.sf.jasperreports.hql.query.run.type`, description: `Property specifying the Hibernate query execution type.`, default: 'list', sinceVersion: '1.2.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.http.data.body', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.http.data.body', label: `Request body`, description: `Property that specifies the body entity of the POST/PUT request used by the HTTP data adapters.`, sinceVersion: '6.3.1'}), scopes: ['REPORT', 'DATASET', 'PARAMETER'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.http.data.method', vtype: new PEnum({ custom: true, id: 'net.sf.jasperreports.http.data.method', label: `Request method`, description: `Property that specifies the request method used by the HTTP data adapters.`, sinceVersion: '6.4.3',
	items: [{key: '', value: ''},
{ key: 'GET', value: 'GET'},
{ key: 'POST', value: 'POST'},
{ key: 'PUT', value: 'PUT'},
]}), scopes: ['REPORT', 'DATASET', 'PARAMETER'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.http.data.password', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.http.data.password', label: `Password`, description: `Property that specifies the password to be used by the HTTP data adapters with basic authentication.`, sinceVersion: '6.3.1'}), scopes: ['REPORT', 'DATASET', 'PARAMETER'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.http.data.url', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.http.data.url', label: `Data URL`, description: `Property that specifies the base URL to be used by the HTTP data adapters.`, sinceVersion: '6.3.1'}), scopes: ['REPORT', 'DATASET', 'PARAMETER'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.http.data.username', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.http.data.username', label: `Username`, description: `Property that specifies the user name to be used by the HTTP data adapters with basic authentication.`, sinceVersion: '6.3.1'}), scopes: ['REPORT', 'DATASET', 'PARAMETER'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.jdbc.cached.rowset', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.jdbc.cached.rowset', label: `net.sf.jasperreports.jdbc.cached.rowset`, description: `Flag property specifying if data will be stored in a cached rowset.`, default: 'false', sinceVersion: '4.1.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.jdbc.concurrency', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.jdbc.concurrency', label: `net.sf.jasperreports.jdbc.concurrency`, description: `Property specifying the concurrency rule for the java.sql.ResultSet created by the 
JRJdbcQueryExecuter.`, default: 'readOnly', sinceVersion: '3.5.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.jdbc.data.adapter.auto.commit', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.jdbc.data.adapter.auto.commit', label: `net.sf.jasperreports.jdbc.data.adapter.auto.commit`, description: `Flag that sets a default value for the autoCommit attribute of JDBC data adapters.`, sinceVersion: '6.13.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.jdbc.data.adapter.read.only', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.jdbc.data.adapter.read.only', label: `net.sf.jasperreports.jdbc.data.adapter.read.only`, description: `Flag that sets a default value for the readOnly attribute of JDBC data adapters.`, sinceVersion: '6.13.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.jdbc.data.adapter.transaction.isolation', vtype: {...TransactionIsolation,custom: true, id: 'net.sf.jasperreports.jdbc.data.adapter.transaction.isolation', label: `net.sf.jasperreports.jdbc.data.adapter.transaction.isolation`, description: `Property that sets a default value for the transactionIsolation attribute of JDBC data adapters.`, sinceVersion: '6.13.0'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.jdbc.fetch.size', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.jdbc.fetch.size', label: `net.sf.jasperreports.jdbc.fetch.size`, description: `Property specifying the fetch size for the java.sql.ResultSet created by the 
JRJdbcQueryExecuter and the 
JRHibernateQueryExecuter.`, default: 0, sinceVersion: '1.2.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.jdbc.fields.time.zone', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.jdbc.fields.time.zone', label: `net.sf.jasperreports.jdbc.fields.time.zone`, description: `Property specifying the default time zone to be used for all date/time fields when sending and retrieving date/time values to and from the database.`, sinceVersion: '6.1.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.jdbc.holdability', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.jdbc.holdability', label: `net.sf.jasperreports.jdbc.holdability`, description: `Property specifying the cursor holdability after commit for the java.sql.ResultSet created by the 
JRJdbcQueryExecuter.`, default: 'hold', sinceVersion: '3.5.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.jdbc.max.field.size', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.jdbc.max.field.size', label: `net.sf.jasperreports.jdbc.max.field.size`, description: `Property specifying the limit for the maximum number of bytes in a ResultSet column 
storing character or binary values created by the 
JRJdbcQueryExecuter.`, sinceVersion: '3.5.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.jdbc.parameters.time.zone', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.jdbc.parameters.time.zone', label: `net.sf.jasperreports.jdbc.parameters.time.zone`, description: `Property specifying the default time zone to be used for all date/time parameters when sending and retrieving date/time values to and from the database.`, sinceVersion: '6.1.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.jdbc.query.timeout', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.jdbc.query.timeout', label: `net.sf.jasperreports.jdbc.query.timeout`, description: `Property specifying the number of seconds to wait for the Statement created by the 
JRJdbcQueryExecuter to execute.`, sinceVersion: '6.8.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.jdbc.result.set.type', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.jdbc.result.set.type', label: `net.sf.jasperreports.jdbc.result.set.type`, description: `Property specifying the type for the java.sql.ResultSet created by the 
JRJdbcQueryExecuter.`, default: 'forwardOnly', sinceVersion: '3.5.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.jdbc.time.zone', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.jdbc.time.zone', label: `Time zone`, description: `Property specifying the default time zone to be used for sending and retrieving date/time values to and from the database.`, sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET', 'PARAMETER', 'FIELD'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.json.date.pattern', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.json.date.pattern', label: `net.sf.jasperreports.json.date.pattern`, description: `Parameter/property holding the format pattern used to instantiate java.util.Date instances.`, sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.json.locale.code', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.json.locale.code', label: `net.sf.jasperreports.json.locale.code`, description: `Built-in parameter/property holding the java.lang.String code of the locale to be used when parsing the JSON data.`, sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.json.number.pattern', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.json.number.pattern', label: `net.sf.jasperreports.json.number.pattern`, description: `Parameter/property holding the format pattern used to instantiate java.lang.Number instances.`, sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.json.source', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.json.source', label: `net.sf.jasperreports.json.source`, description: `Built-in parameter/property holding the value of the source for the JSON file.`, sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.json.timezone.id', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.json.timezone.id', label: `net.sf.jasperreports.json.timezone.id`, description: `Built-in parameter/property holding the java.lang.String value of the time zone id to be used when parsing the JSON data.`, sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.xls.date.pattern', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.xls.date.pattern', label: `net.sf.jasperreports.xls.date.pattern`, description: `Property holding the value of the date format pattern to be used when parsing the Excel data.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.xls.format', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.xls.format', label: `net.sf.jasperreports.xls.format`, description: `Built-in parameter/property holding the value of the Excel format to be used when parsing the Excel data source.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.xls.locale.code', vtype: {...Locale,custom: true, id: 'net.sf.jasperreports.xls.locale.code', label: `net.sf.jasperreports.xls.locale.code`, description: `String property specifying the code of the locale to be used when parsing the Excel data.`, sinceVersion: '5.5.2'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.xls.number.pattern', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.xls.number.pattern', label: `net.sf.jasperreports.xls.number.pattern`, description: `Property holding the value of the number format pattern to be used when parsing the Excel data.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.xls.sheet.selection', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.xls.sheet.selection', label: `net.sf.jasperreports.xls.sheet.selection`, description: `Built-in parameter/property holding the value of the sheet name to be used when parsing the Excel data source.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.xls.source', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.xls.source', label: `net.sf.jasperreports.xls.source`, description: `String property representing a valid path to the requested resource containing the Excel data.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.xls.timezone.id', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.xls.timezone.id', label: `net.sf.jasperreports.xls.timezone.id`, description: `String property specifying value of the time zone id to be used when parsing the Excel data.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.xml.date.pattern', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.xml.date.pattern', label: `net.sf.jasperreports.xml.date.pattern`, description: `Property holding the value of the date format pattern to be used when parsing the XML data.`, sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.xml.detect.namespaces', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.xml.detect.namespaces', label: `net.sf.jasperreports.xml.detect.namespaces`, description: `Boolean parameter/property that specifies whether the XML document should be parsed for namespaces or not.`, default: 'false', sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.xml.number.pattern', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.xml.number.pattern', label: `net.sf.jasperreports.xml.number.pattern`, description: `Property holding the value of the number format pattern to be used when parsing the XLS data.`, sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.xml.source', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.xml.source', label: `net.sf.jasperreports.xml.source`, description: `String property representing a valid path to the requested resource containing the XML data.`, sinceVersion: '4.7.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
	]}),
	new PAccordeon({ id: 'design', label: 'design', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.allow.element.negative.width', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.allow.element.negative.width', label: `net.sf.jasperreports.allow.element.negative.width`, description: `A property that determines whether elements having negative width values are allowed.`, default: 'false', sinceVersion: '3.7.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.allow.element.negative.x', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.allow.element.negative.x', label: `net.sf.jasperreports.allow.element.negative.x`, description: `Property that determines whether elements positioned at negative X offsets on bands, frames and other element containers are allowed in a report.`, default: 'true', sinceVersion: '6.1.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.allow.element.negative.y', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.allow.element.negative.y', label: `net.sf.jasperreports.allow.element.negative.y`, description: `Property that determines whether elements positioned at negative Y offsets on bands, frames and other element containers are allowed in a report.`, default: 'true', sinceVersion: '3.7.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.allow.element.overlap', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.allow.element.overlap', label: `net.sf.jasperreports.allow.element.overlap`, description: `A property that determines whether elements are allowed to overlap.`, default: 'true', sinceVersion: '3.5.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
	]}),
	new PAccordeon({ id: 'export', label: 'export', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.character.encoding', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.character.encoding', label: `net.sf.jasperreports.export.character.encoding`, description: `Property whose value is used as as default for the getEncoding() export output setting.`, default: 'UTF-8', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.csv.escape.formula', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.csv.escape.formula', label: `net.sf.jasperreports.export.csv.escape.formula`, description: `Flag property that enforces field values containing formula to be escaped in order to be interpreted as pure text.`, default: 'false', sinceVersion: '6.18.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.csv.field.delimiter', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.csv.field.delimiter', label: `net.sf.jasperreports.export.csv.field.delimiter`, description: `Property that contains the character or sequence of characters used to delimit two fields on the same record.`, default: ',', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.csv.field.enclosure', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.csv.field.enclosure', label: `net.sf.jasperreports.export.csv.field.enclosure`, description: `Property containing the character to enclose a field value on a record.`, default: '"', sinceVersion: '6.2.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.csv.force.field.enclosure', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.csv.force.field.enclosure', label: `net.sf.jasperreports.export.csv.force.field.enclosure`, description: `Property that enforces all exported field values to be enclosed within a pair of enclosure characters (usually a pair of quotes:"").`, default: 'false', sinceVersion: '6.2.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.csv.record.delimiter', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.csv.record.delimiter', label: `net.sf.jasperreports.export.csv.record.delimiter`, description: `Property that contains the character or sequence of characters used to delimit two records.`, default: '\n', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.csv.write.bom', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.csv.write.bom', label: `net.sf.jasperreports.export.csv.write.bom`, description: `Property that specifies whether a BOM character should be put at the beginning of the output.`, default: 'false', sinceVersion: '6.1.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.csv.write.header', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.csv.write.header', label: `net.sf.jasperreports.export.csv.write.header`, description: `Property that specifies whether the CSV metadata-based exporter should include a header (the column names) in the output.`, default: 'false', sinceVersion: '4.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.default.filter.factory', vtype: new PJavaClass({ custom: true, id: 'net.sf.jasperreports.export.default.filter.factory', label: `net.sf.jasperreports.export.default.filter.factory`, description: `A property that gives the generic default filter factory class name.`, default: 'net.sf.jasperreports.engine.export.DefaultExporterFilterFactory', sinceVersion: '3.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.docx.embed.fonts', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.docx.embed.fonts', label: `net.sf.jasperreports.export.docx.embed.fonts`, description: `This property indicates whether the embeddable true type fonts used in the report should be embedded into the generated DOCX document.`, default: 'false', sinceVersion: '6.9.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.docx.encryption.password', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.docx.encryption.password', label: `net.sf.jasperreports.export.docx.encryption.password`, description: `Property whose value is used as default for the 
getEncryptionPassword() DOCX export configuration setting.`, sinceVersion: '6.20.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.docx.flexible.row.height', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.docx.flexible.row.height', label: `net.sf.jasperreports.export.docx.flexible.row.height`, description: `This property indicates whether table rows can grow if more text is added into cells.`, default: 'false', sinceVersion: '3.6.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.docx.frames.as.nested.tables', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.docx.frames.as.nested.tables', label: `net.sf.jasperreports.export.docx.frames.as.nested.tables`, description: `This property specifies whether JRPrintFrame frames are to be exported as nested tables.`, default: 'true', sinceVersion: '3.5.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'FRAME'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.docx.ignore.hyperlink', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.docx.ignore.hyperlink', label: `net.sf.jasperreports.export.docx.ignore.hyperlink`, description: `This property is used by the DOCX exporter to determine if a certain hyperlink should be activated or not.`, sinceVersion: '5.1.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'HYPERLINK'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.docx.metadata.application', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.docx.metadata.application', label: `net.sf.jasperreports.export.docx.metadata.application`, description: `Property that contains the application information to use for the generated DOCX metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.docx.metadata.author', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.docx.metadata.author', label: `net.sf.jasperreports.export.docx.metadata.author`, description: `Property that contains the author information to use for the generated DOCX metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.docx.metadata.keywords', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.docx.metadata.keywords', label: `net.sf.jasperreports.export.docx.metadata.keywords`, description: `Property that contains the keywords to use for the generated DOCX metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.docx.metadata.subject', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.docx.metadata.subject', label: `net.sf.jasperreports.export.docx.metadata.subject`, description: `Property that contains the subject information to use for the generated DOCX metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.docx.metadata.title', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.docx.metadata.title', label: `net.sf.jasperreports.export.docx.metadata.title`, description: `Property that contains the title information to use for the generated DOCX metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.docx.new.line.as.paragraph', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.docx.new.line.as.paragraph', label: `net.sf.jasperreports.export.docx.new.line.as.paragraph`, description: `This property specifies whether the newline element present in a justified paragraph introduces a new justified paragraph.`, default: 'false', sinceVersion: '6.1.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.flash.element.allow.script.access', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.flash.element.allow.script.access', label: `net.sf.jasperreports.export.flash.element.allow.script.access`, description: `Property that determines the value of the allowScriptAccess parameter for Flash elements.`, default: 'sameDomain', sinceVersion: '4.1.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'GENERIC_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.graphics2d.min.job.size', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.graphics2d.min.job.size', label: `net.sf.jasperreports.export.graphics2d.min.job.size`, description: `Flag property that controls the use of an AWT rendering fix which causes the printer job size to be reduced when the exporter draws onto a printer graphic context.`, default: 'true', sinceVersion: '1.2.8'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.graphics2d.white.page.background', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.graphics2d.white.page.background', label: `net.sf.jasperreports.export.graphics2d.white.page.background`, description: `Property that that determines whether the Graphics2D exporter is to draw white backgrounds for exported pages.`, default: 'true', sinceVersion: '6.5.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.accessible', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.html.accessible', label: `net.sf.jasperreports.export.html.accessible`, description: `Configuration property that determines the exporter to produce accessible HTML.`, default: 'false', sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.between.pages', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.html.between.pages', label: `net.sf.jasperreports.export.html.between.pages`, description: `Configuration property that provides the HTML code that will be inserted between pages of the generated report.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.border.collapse', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.html.border.collapse', label: `net.sf.jasperreports.export.html.border.collapse`, description: `Configuration property that provides the value for the border-collapse CSS property to be applied 
to the table generated for the report.`, default: 'collapse', sinceVersion: '5.0.4'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.convert.svg.to.image', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.html.convert.svg.to.image', label: `net.sf.jasperreports.export.html.convert.svg.to.image`, description: `This property specifies whether the SVG graphics should be rasterized as images.`, default: 'false', sinceVersion: '6.3.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'IMAGE_ELEMENT', 'CHART_ELEMENT', 'COMPONENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.embed.image', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.html.embed.image', label: `net.sf.jasperreports.export.html.embed.image`, description: `This property specifies whether the image should be Base64 encoded and embedded into the src attribute of the image tag, and not referenced as separate resource.`, default: 'false', sinceVersion: '6.2.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'IMAGE_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.embedded.svg.use.fonts', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.html.embedded.svg.use.fonts', label: `net.sf.jasperreports.export.html.embedded.svg.use.fonts`, description: `This property specifies whether the HTML embedded SVG should have the font family names replaced with the web fonts coming from font extensions, similar to what we do for text elements.`, default: 'false', sinceVersion: '6.2.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'IMAGE_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.flush.output', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.html.flush.output', label: `net.sf.jasperreports.export.html.flush.output`, description: `A boolean property that determines whether the HTML exporter should flush the output stream after writing the HTML content to it.`, default: 'true', sinceVersion: '3.7.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.footer', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.html.footer', label: `net.sf.jasperreports.export.html.footer`, description: `Configuration property that provides the HTML code that will be inserted after the generated report content.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.header', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.html.header', label: `net.sf.jasperreports.export.html.header`, description: `Configuration property that provides the HTML code that will be inserted before the generated report content.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.ignore.hyperlink', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.html.ignore.hyperlink', label: `net.sf.jasperreports.export.html.ignore.hyperlink`, description: `This property is used by the HTML exporter to determine if a certain hyperlink should be activated or not.`, sinceVersion: '5.1.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'HYPERLINK'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.remove.emtpy.space.between.rows', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.html.remove.emtpy.space.between.rows', label: `net.sf.jasperreports.export.html.remove.emtpy.space.between.rows`, description: `Flag property that specifies whether the blank lines that sometimes appear between rows as spacers should be removed.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.size.unit', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.html.size.unit', label: `net.sf.jasperreports.export.html.size.unit`, description: `Property that specifies the unit to use when measuring lengths or font size.`, default: 'px', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.use.background.image.to.align', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.html.use.background.image.to.align', label: `net.sf.jasperreports.export.html.use.background.image.to.align`, description: `Property that indicates whether the images should be rendered using CSS background images, to achieve certain image alignment requirements, especially in the case of lazy images,
for which the real dimensions are not known at report fill time.`, default: 'true', sinceVersion: '6.8.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'IMAGE_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.white.page.background', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.html.white.page.background', label: `net.sf.jasperreports.export.html.white.page.background`, description: `Property that indicates whether the report background should be turned to white.`, default: 'true', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.html.wrap.break.word', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.html.wrap.break.word', label: `net.sf.jasperreports.export.html.wrap.break.word`, description: `Property that specifies whether the export engine should force text wrapping by breaking words (CSS word-wrap: break-word).`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.ignore.page.margins', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.ignore.page.margins', label: `net.sf.jasperreports.export.ignore.page.margins`, description: `This flag property specifies whether page margins should be ignored when the report is exported using a grid-based exporter.`, default: 'false', sinceVersion: '2.0.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.json.escape.members', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.json.escape.members', label: `net.sf.jasperreports.export.json.escape.members`, description: `This report-level flag is considered by the JsonMetadataExporter 
and specifies if the member property names should be enclosed with quotes or no.`, default: 'true', sinceVersion: '6.0.0'}), scopes: ['REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.json.schema', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.json.schema', label: `net.sf.jasperreports.export.json.schema`, description: `This report-level property is considered by the JsonMetadataExporter 
and specifies the path to a JSON schema file that will be used to generate the JSON structure at export time.`, sinceVersion: '6.0.0'}), scopes: ['REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.ods.flexible.row.height', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.ods.flexible.row.height', label: `net.sf.jasperreports.export.ods.flexible.row.height`, description: `This property indicates whether table rows in ODS documents can grow if more text is added into cells.`, default: 'false', sinceVersion: '5.0.4'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.odt.flexible.row.height', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.odt.flexible.row.height', label: `net.sf.jasperreports.export.odt.flexible.row.height`, description: `This property table rows in ODT documents can grow if more text is added into cells.`, default: 'false', sinceVersion: '5.0.4'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.odt.ignore.hyperlink', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.odt.ignore.hyperlink', label: `net.sf.jasperreports.export.odt.ignore.hyperlink`, description: `This property is used by the ODT exporter to determine if a certain hyperlink should be activated or not.`, sinceVersion: '5.1.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'HYPERLINK'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.128.bit.key', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.128.bit.key', label: `net.sf.jasperreports.export.pdf.128.bit.key`, description: `Property that specifies whether the encryption key is 128 bits.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.collapse.missing.bookmark.levels', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.collapse.missing.bookmark.levels', label: `net.sf.jasperreports.export.pdf.collapse.missing.bookmark.levels`, description: `Property that determines if missing bookmark levels are collapsed, or if 
empty bookmarks are created for the missing levels.`, default: 'false', sinceVersion: '3.7.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.compressed', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.compressed', label: `net.sf.jasperreports.export.pdf.compressed`, description: `Property that specifies whether the PDF document should be compressed.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.create.batch.mode.bookmarks', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.create.batch.mode.bookmarks', label: `net.sf.jasperreports.export.pdf.create.batch.mode.bookmarks`, description: `Property that specifies whether the PDF document should contain an outline section.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.display.metadata.title', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.display.metadata.title', label: `net.sf.jasperreports.export.pdf.display.metadata.title`, description: `Property that specifies whether the document title should be displayed in the title bar instead of the file name.`, default: 'false', sinceVersion: '6.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.encrypted', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.encrypted', label: `net.sf.jasperreports.export.pdf.encrypted`, description: `Property that specifies  whether the final PDF document should be encrypted.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.even.page.offset.x', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.pdf.even.page.offset.x', label: `net.sf.jasperreports.export.pdf.even.page.offset.x`, description: `Property that specifies the X offset (in pixels) for moving elements in even page number pages, to simulate gutter margins.`, default: 0, sinceVersion: '6.0.4'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.even.page.offset.y', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.pdf.even.page.offset.y', label: `net.sf.jasperreports.export.pdf.even.page.offset.y`, description: `Property that specifies the Y offset (in pixels) for moving elements in even page number pages, to simulate gutter margins.`, default: 0, sinceVersion: '6.0.4'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.border.style', vtype: {...PdfFieldBorderStyleEnum,custom: true, id: 'net.sf.jasperreports.export.pdf.field.border.style', label: `net.sf.jasperreports.export.pdf.field.border.style`, description: `Property that specifies the PDF-compatible border style for input fields.`, sinceVersion: '6.12.0'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.choice.separators', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.field.choice.separators', label: `net.sf.jasperreports.export.pdf.field.choice.separators`, description: `Specifies the set of characters used as choices separator in the choices list.`, default: '|', sinceVersion: '6.12.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.combo.edit', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.field.combo.edit', label: `net.sf.jasperreports.export.pdf.field.combo.edit`, description: `Flag property applicable for combobox input fields that specifies if the combobox field is editable.`, default: 'false', sinceVersion: '6.12.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.force.linebreak.policy', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.force.linebreak.policy', label: `net.sf.jasperreports.export.pdf.force.linebreak.policy`, description: `Property that specifies whether the PDF exporter should use a SplitCharacter 
implementation to ensure that PDF text wrap behavior is the same as in AWT.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.force.svg.shapes', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.force.svg.shapes', label: `net.sf.jasperreports.export.pdf.force.svg.shapes`, description: `Property that specifies whether the rendering of SVG images using shapes on the PDF Graphics2D context should be forced.`, default: 'true', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.ignore.hyperlink', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.ignore.hyperlink', label: `net.sf.jasperreports.export.pdf.ignore.hyperlink`, description: `This property is used by the PDF exporter to determine if a certain hyperlink should be activated or not.`, sinceVersion: '5.1.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'HYPERLINK'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.javascript', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.javascript', label: `net.sf.jasperreports.export.pdf.javascript`, description: `Property that contains the user defined JavaScript piece of code to be inserted into the generated PDF document.`, sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.justified.letter.spacing', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.justified.letter.spacing', label: `net.sf.jasperreports.export.pdf.justified.letter.spacing`, description: `Property that determines whether justified text alignment can modify letter spacing in words.`, default: 'false', sinceVersion: '6.2.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.legacy.target.blank.links', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.legacy.target.blank.links', label: `net.sf.jasperreports.export.pdf.legacy.target.blank.links`, description: `Flag that enables legacy hyperlinkTarget="Blank" links in PDF.`, default: 'false', sinceVersion: '6.20.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.metadata.author', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.metadata.author', label: `net.sf.jasperreports.export.pdf.metadata.author`, description: `Property that contains the author information to use for the generated PDF metadata.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.metadata.creator', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.metadata.creator', label: `net.sf.jasperreports.export.pdf.metadata.creator`, description: `Property that contains the creator information to use for the generated PDF metadata.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.metadata.keywords', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.metadata.keywords', label: `net.sf.jasperreports.export.pdf.metadata.keywords`, description: `Property that contains the keywords to use for the generated PDF metadata.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.metadata.subject', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.metadata.subject', label: `net.sf.jasperreports.export.pdf.metadata.subject`, description: `Property that contains the subject information to use for the generated PDF metadata.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.metadata.title', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.metadata.title', label: `net.sf.jasperreports.export.pdf.metadata.title`, description: `Property that contains the title information to use for the generated PDF metadata.`, sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.odd.page.offset.x', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.pdf.odd.page.offset.x', label: `net.sf.jasperreports.export.pdf.odd.page.offset.x`, description: `Property that specifies the X offset (in pixels) for moving elements in odd page number pages, to simulate gutter margins.`, default: 0, sinceVersion: '6.0.4'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.odd.page.offset.y', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.pdf.odd.page.offset.y', label: `net.sf.jasperreports.export.pdf.odd.page.offset.y`, description: `Property that specifies the Y offset (in pixels) for moving elements in odd page number pages, to simulate gutter margins.`, default: 0, sinceVersion: '6.0.4'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.owner.password', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.owner.password', label: `net.sf.jasperreports.export.pdf.owner.password`, description: `Property that contains the password belonging to the owner of the document, if it is encrypted.`, sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.permissions.allowed', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.permissions.allowed', label: `net.sf.jasperreports.export.pdf.permissions.allowed`, description: `Property that contains the allowed user permissions of the PDF document, if it is encrypted.`, sinceVersion: '5.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.permissions.denied', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.permissions.denied', label: `net.sf.jasperreports.export.pdf.permissions.denied`, description: `Property that contains the denied user permissions of the PDF document, if it is encrypted.`, sinceVersion: '5.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.print.scaling', vtype: {...PdfPrintScalingEnum,custom: true, id: 'net.sf.jasperreports.export.pdf.print.scaling', label: `net.sf.jasperreports.export.pdf.print.scaling`, description: `Property that specifies the print scaling preference in the PDF print dialog.`, default: 'default', sinceVersion: '3.6.1'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.size.page.to.content', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.size.page.to.content', label: `net.sf.jasperreports.export.pdf.size.page.to.content`, description: `Property which specifies if the size of each page should be increased to accommodate its content.`, default: 'false', sinceVersion: '4.7.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.language', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.language', label: `net.sf.jasperreports.export.pdf.tag.language`, description: `Property that specifies the language that the exporter should put in the language tag of the generated PDF.`, sinceVersion: '3.1.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tagged', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.tagged', label: `net.sf.jasperreports.export.pdf.tagged`, description: `Property that specifies whether the exporter should put structure tags in the generated PDF.`, default: 'false', sinceVersion: '3.1.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.user.password', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.user.password', label: `net.sf.jasperreports.export.pdf.user.password`, description: `Property that contains the user password needed to open the document, if it is encrypted.`, sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.version', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.version', label: `net.sf.jasperreports.export.pdf.version`, description: `Property that contains a Character instance representing the version of the generated PDF.`, sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdfa.conformance', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdfa.conformance', label: `net.sf.jasperreports.export.pdfa.conformance`, description: `Property whose value is used as default for the getPdfaConformance() 
export configuration setting.`, sinceVersion: '3.1.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pdfa.icc.profile.path', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdfa.icc.profile.path', label: `net.sf.jasperreports.export.pdfa.icc.profile.path`, description: `Property whose value is used as default for the getIccProfilePath() 
export configuration setting.`, sinceVersion: '3.1.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pptx.background.as.slide.master', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pptx.background.as.slide.master', label: `net.sf.jasperreports.export.pptx.background.as.slide.master`, description: `Property that specifies whether the elements from the background section on the first page should be exported as the contents of the slide master, 
and then ignoring the background section elements for all pages/slides.`, default: 'false', sinceVersion: '6.8.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pptx.embed.fonts', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pptx.embed.fonts', label: `net.sf.jasperreports.export.pptx.embed.fonts`, description: `This property indicates whether the fonts used in the report should be embedded into the generated PPTX document.`, default: 'false', sinceVersion: '6.9.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pptx.encryption.password', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pptx.encryption.password', label: `net.sf.jasperreports.export.pptx.encryption.password`, description: `Property whose value is used as default for the 
getEncryptionPassword() PPTX export configuration setting.`, sinceVersion: '6.20.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pptx.hide.slide.master.pages', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pptx.hide.slide.master.pages', label: `net.sf.jasperreports.export.pptx.hide.slide.master.pages`, description: `Property specifying the pages and/or page ranges for which the contents of the slide master should be hidden on.`, sinceVersion: '6.8.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pptx.ignore.hyperlink', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pptx.ignore.hyperlink', label: `net.sf.jasperreports.export.pptx.ignore.hyperlink`, description: `This property is used by the PPTX exporter to determine if a certain hyperlink should be activated or not.`, sinceVersion: '5.1.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'HYPERLINK'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pptx.metadata.application', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pptx.metadata.application', label: `net.sf.jasperreports.export.pptx.metadata.application`, description: `Property that contains the application information to use for the generated PPTX metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pptx.metadata.author', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pptx.metadata.author', label: `net.sf.jasperreports.export.pptx.metadata.author`, description: `Property that contains the author information to use for the generated PPTX metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pptx.metadata.keywords', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pptx.metadata.keywords', label: `net.sf.jasperreports.export.pptx.metadata.keywords`, description: `Property that contains the keywords to use for the generated PPTX metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pptx.metadata.subject', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pptx.metadata.subject', label: `net.sf.jasperreports.export.pptx.metadata.subject`, description: `Property that contains the subject information to use for the generated PPTX metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pptx.metadata.title', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pptx.metadata.title', label: `net.sf.jasperreports.export.pptx.metadata.title`, description: `Property that contains the title information to use for the generated PPTX metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pptx.slide.master.page', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.pptx.slide.master.page', label: `net.sf.jasperreports.export.pptx.slide.master.page`, description: `Property that specifies the page number from which the slide master content is coming from.`, default: 1, sinceVersion: '6.8.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.pptx.slide.master.report', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.pptx.slide.master.report', label: `net.sf.jasperreports.export.pptx.slide.master.report`, description: `Property that specifies from which report is the page providing slide master content coming from, in a multi-report batch export.`, default: 1, sinceVersion: '6.8.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.rtf.ignore.hyperlink', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.rtf.ignore.hyperlink', label: `net.sf.jasperreports.export.rtf.ignore.hyperlink`, description: `This property is used by the RTF exporter to determine if a certain hyperlink should be activated or not.`, sinceVersion: '5.1.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'HYPERLINK'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.text.character.height', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.text.character.height', label: `net.sf.jasperreports.export.text.character.height`, description: `Property whose floating point value is representing the pixel/character vertical ratio.`, sinceVersion: '3.6.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.text.character.width', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.text.character.width', label: `net.sf.jasperreports.export.text.character.width`, description: `Property whose floating point value is representing the pixel/character horizontal ratio.`, sinceVersion: '3.6.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.text.line.separator', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.text.line.separator', label: `net.sf.jasperreports.export.text.line.separator`, description: `Property whose value represents the separator between two lines of text in the exported report.`, default: 'the value of the "line.separator" system property', sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.text.page.height', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.text.page.height', label: `net.sf.jasperreports.export.text.page.height`, description: `Property whose integer value representing the page height in characters.`, sinceVersion: '3.6.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.text.page.separator', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.text.page.separator', label: `net.sf.jasperreports.export.text.page.separator`, description: `Property whose value represents text that will be inserted between pages of the generated report.`, default: 'the duplicated value of the "line.separator" system property', sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.text.page.width', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.text.page.width', label: `net.sf.jasperreports.export.text.page.width`, description: `Property whose integer value is representing the page width in characters.`, sinceVersion: '3.6.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.text.trim.line.right', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.text.trim.line.right', label: `net.sf.jasperreports.export.text.trim.line.right`, description: `Property that specifies whether the text lines in the document should be trimmed to the right.`, default: 'false', sinceVersion: '6.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.auto.fit.page.height', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.auto.fit.page.height', label: `net.sf.jasperreports.export.xls.auto.fit.page.height`, description: `Flag property that provides a default value for the isAutoFitPageHeight() export setting.`, default: 'false', sinceVersion: '6.0.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.auto.fit.row', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.auto.fit.row', label: `net.sf.jasperreports.export.xls.auto.fit.row`, description: `Flag property that indicates whether Excel should autofit the current row height.`, default: 'false', sinceVersion: '4.5.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.cell.hidden', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.cell.hidden', label: `net.sf.jasperreports.export.xls.cell.hidden`, description: `Property that specifies if the cell content is hidden or exposed in the formula bar of a protected sheet.`, default: 'false', sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.cell.locked', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.cell.locked', label: `net.sf.jasperreports.export.xls.cell.locked`, description: `Property that specifies if the cell content is locked (read-only) within a protected sheet.`, default: 'true', sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.collapse.row.span', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.collapse.row.span', label: `net.sf.jasperreports.export.xls.collapse.row.span`, description: `Property that specifies whether row spans should be collapsed and merging cells across rows should be inhibited.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.column.width.ratio', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.column.width.ratio', label: `net.sf.jasperreports.export.xls.column.width.ratio`, description: `Property used to adjust all column widths in a document or sheet with the same width ratio, in order to get column width 
values suitable for Excel output.`, sinceVersion: '4.1.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.create.custom.palette', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.create.custom.palette', label: `net.sf.jasperreports.export.xls.create.custom.palette`, description: `Flag property specifying whether the standard color palette should be customized so that the XLS result uses the original report colors.`, default: 'false', sinceVersion: '2.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.detect.cell.type', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.detect.cell.type', label: `net.sf.jasperreports.export.xls.detect.cell.type`, description: `Property that specifies whether the exporter should take into consideration the type of the original text field expressions and set the cell types and values accordingly.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.encryption.password', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.encryption.password', label: `net.sf.jasperreports.export.xls.encryption.password`, description: `Property whose value is used as default for the 
getEncryptionPassword() XLS/XLSX export configuration setting.`, sinceVersion: '6.20.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.first.page.number', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.first.page.number', label: `net.sf.jasperreports.export.xls.first.page.number`, description: `Property that specifies the first page number in the page setup dialog.`, sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.fit.height', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.fit.height', label: `net.sf.jasperreports.export.xls.fit.height`, description: `Property used at print time to indicate the number of pages height to fit the sheet in.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.fit.width', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.fit.width', label: `net.sf.jasperreports.export.xls.fit.width`, description: `Property used at print time to indicate the number of pages width to fit the sheet in.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.font.size.fix.enabled', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.font.size.fix.enabled', label: `net.sf.jasperreports.export.xls.font.size.fix.enabled`, description: `Flag property that indicates whether the font size should be decreased so that texts fit into the specified cell height.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.force.page.breaks', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.force.page.breaks', label: `net.sf.jasperreports.export.xls.force.page.breaks`, description: `Flag property that provides a default value for the isForcePageBreaks() export setting.`, default: 'false', sinceVersion: '6.0.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.freeze.column', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.freeze.column', label: `net.sf.jasperreports.export.xls.freeze.column`, description: `Indicates the name of the first unlocked column in document's sheets.`, sinceVersion: '4.1.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.freeze.row', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.freeze.row', label: `net.sf.jasperreports.export.xls.freeze.row`, description: `Specifies the index of the first unlocked row in document's sheets.`, sinceVersion: '4.1.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.ignore.anchors', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.ignore.anchors', label: `net.sf.jasperreports.export.xls.ignore.anchors`, description: `Flag property that indicates whether local anchors should be ignored when elements are exported to Excel.`, default: 'false', sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.ignore.cell.background', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.ignore.cell.background', label: `net.sf.jasperreports.export.xls.ignore.cell.background`, description: `Property that specifies whether cell background color should be ignored in the generated document.`, default: 'false', sinceVersion: '3.6.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.ignore.cell.border', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.ignore.cell.border', label: `net.sf.jasperreports.export.xls.ignore.cell.border`, description: `Property that specifies whether cell borders should be ignored in the generated document.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.ignore.graphics', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.ignore.graphics', label: `net.sf.jasperreports.export.xls.ignore.graphics`, description: `Property that specifies whether graphic elements should be ignored and only text elements will be exported.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.ignore.hyperlink', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.ignore.hyperlink', label: `net.sf.jasperreports.export.xls.ignore.hyperlink`, description: `This property is used by XLS, XLSX and ODS exporters to determine if a certain hyperlink should be activated or not.`, sinceVersion: '5.1.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'HYPERLINK'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.ignore.text.formatting', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.ignore.text.formatting', label: `net.sf.jasperreports.export.xls.ignore.text.formatting`, description: `Property that specifies whether the text formatting (such as text color, background color, bold style, italic, underline, etc) 
should be ignored in the resulting Excel document at export time or not.`, default: 'false', sinceVersion: '6.0.4'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.image.anchor.type', vtype: {...ImageAnchorTypeEnum,custom: true, id: 'net.sf.jasperreports.export.xls.image.anchor.type', label: `net.sf.jasperreports.export.xls.image.anchor.type`, description: `Property that specifies the image anchor type.`, default: 'MoveNoSize', sinceVersion: '5.0.4'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'IMAGE_ELEMENT', 'CHART_ELEMENT', 'COMPONENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.image.border.fix.enabled', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.image.border.fix.enabled', label: `net.sf.jasperreports.export.xls.image.border.fix.enabled`, description: `Flag property that specifies whether the minimum image padding will be forced to 1 pixel, to avoid situations where the image hides the cell border.`, default: 'false', sinceVersion: '3.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.max.rows.per.sheet', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.max.rows.per.sheet', label: `net.sf.jasperreports.export.xls.max.rows.per.sheet`, description: `Property that specifies the maximum number of rows allowed to be exported to the same sheet.`, default: 0, sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.metadata.application', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.metadata.application', label: `net.sf.jasperreports.export.xls.metadata.application`, description: `Property that contains the application information to use for the generated XLS metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.metadata.author', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.metadata.author', label: `net.sf.jasperreports.export.xls.metadata.author`, description: `Property that contains the author information to use for the generated XLS metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.metadata.keywords', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.metadata.keywords', label: `net.sf.jasperreports.export.xls.metadata.keywords`, description: `Property that contains the keywords to use for the generated XLS metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.metadata.subject', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.metadata.subject', label: `net.sf.jasperreports.export.xls.metadata.subject`, description: `Property that contains the subject information to use for the generated XLS metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.metadata.title', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.metadata.title', label: `net.sf.jasperreports.export.xls.metadata.title`, description: `Property that contains the title information to use for the generated XLS metadata.`, sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.one.page.per.sheet', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.one.page.per.sheet', label: `net.sf.jasperreports.export.xls.one.page.per.sheet`, description: `Property that specifies whether each report page should be written in a different XLS sheet.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.page.scale', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.page.scale', label: `net.sf.jasperreports.export.xls.page.scale`, description: `Property used to adjust the page content to a given percent of the normal size in the print preview pane.`, sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.password', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.password', label: `net.sf.jasperreports.export.xls.password`, description: `Property that contains a String value representing the password in case of password protected sheets in documents.`, sinceVersion: '3.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.footer.margin', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.footer.margin', label: `net.sf.jasperreports.export.xls.print.footer.margin`, description: `Custom property storing the footer margin for an Excel sheet, expressed in pixels.`, default: 0, sinceVersion: '6.4.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.header.margin', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.header.margin', label: `net.sf.jasperreports.export.xls.print.header.margin`, description: `Custom property storing the header margin for an Excel sheet, expressed in pixels.`, default: 0, sinceVersion: '6.4.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.page.bottom.margin', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.page.bottom.margin', label: `net.sf.jasperreports.export.xls.print.page.bottom.margin`, description: `Custom property storing the bottom page margin for an Excel sheet, expressed in pixels.`, default: 0, sinceVersion: '6.4.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.page.height', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.page.height', label: `net.sf.jasperreports.export.xls.print.page.height`, description: `Custom property specifying the page height in print preview pane, measured in pixels.`, sinceVersion: '6.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.page.left.margin', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.page.left.margin', label: `net.sf.jasperreports.export.xls.print.page.left.margin`, description: `Custom property storing the left page margin for an Excel sheet, expressed in pixels.`, default: 0, sinceVersion: '6.4.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.page.right.margin', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.page.right.margin', label: `net.sf.jasperreports.export.xls.print.page.right.margin`, description: `Custom property storing the right page margin for an Excel sheet, expressed in pixels.`, default: 0, sinceVersion: '6.4.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.page.top.margin', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.page.top.margin', label: `net.sf.jasperreports.export.xls.print.page.top.margin`, description: `Custom property storing the top page margin for an Excel sheet, expressed in pixels.`, default: 0, sinceVersion: '6.4.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.page.width', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.page.width', label: `net.sf.jasperreports.export.xls.print.page.width`, description: `Custom property specifying the page width in print preview pane, measured in pixels.`, sinceVersion: '6.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.remove.empty.space.between.columns', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.remove.empty.space.between.columns', label: `net.sf.jasperreports.export.xls.remove.empty.space.between.columns`, description: `Property that specifies whether the empty spacer columns should be removed or not.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.remove.empty.space.between.rows', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.remove.empty.space.between.rows', label: `net.sf.jasperreports.export.xls.remove.empty.space.between.rows`, description: `Property that specifies whether the empty spacer rows should be removed or not.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.direction', vtype: {...RunDirectionEnum,custom: true, id: 'net.sf.jasperreports.export.xls.sheet.direction', label: `net.sf.jasperreports.export.xls.sheet.direction`, description: `This property indicates if the sheet is left-to-right or right-to-left oriented.`, default: 'LTR', sinceVersion: '3.7.5'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.footer.center', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.footer.center', label: `net.sf.jasperreports.export.xls.sheet.footer.center`, description: `Custom property storing the text content of the Excel sheet center footers.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.footer.left', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.footer.left', label: `net.sf.jasperreports.export.xls.sheet.footer.left`, description: `Custom property storing the text content of the Excel sheet left footers.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.footer.right', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.footer.right', label: `net.sf.jasperreports.export.xls.sheet.footer.right`, description: `Custom property storing the text content of the Excel sheet right footers.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.header.center', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.header.center', label: `net.sf.jasperreports.export.xls.sheet.header.center`, description: `Custom property storing the text content of the Excel sheet center headers.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.header.left', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.header.left', label: `net.sf.jasperreports.export.xls.sheet.header.left`, description: `Custom property storing the text content of the Excel sheet left headers.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.header.right', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.header.right', label: `net.sf.jasperreports.export.xls.sheet.header.right`, description: `Custom property storing the text content of the Excel sheet right headers.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.tab.color', vtype: new PColor({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.tab.color', label: `net.sf.jasperreports.export.xls.sheet.tab.color`, description: `Property whose value is used to set the tab color of a given sheet in XLSX and ODS output formats.`, sinceVersion: '6.2.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.show.gridlines', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.show.gridlines', label: `net.sf.jasperreports.export.xls.show.gridlines`, description: `Flag property that specifies if the gridlines in a given sheet are shown.`, default: 'true', sinceVersion: '4.8.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.shrink.to.fit', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.shrink.to.fit', label: `net.sf.jasperreports.export.xls.shrink.to.fit`, description: `Flag property that indicates whether the text font size should be decreased in order to 
keep the entire text visible in the cell.`, default: 'false', sinceVersion: '6.0.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.use.timezone', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.use.timezone', label: `net.sf.jasperreports.export.xls.use.timezone`, description: `Property that determines whether date values are to be translated to the timezone that was used to fill the report.`, default: 'false', sinceVersion: '4.5.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.white.page.background', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.white.page.background', label: `net.sf.jasperreports.export.xls.white.page.background`, description: `Property that specifies whether the page background should be white or the default XLS background color.`, default: 'true', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.workbook.template', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.workbook.template', label: `net.sf.jasperreports.export.xls.workbook.template`, description: `Property used to store the location of an existing workbook template.`, sinceVersion: '4.5.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.workbook.template.keep.sheets', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.workbook.template.keep.sheets', label: `net.sf.jasperreports.export.xls.workbook.template.keep.sheets`, description: `Flag property that specifies whether to keep the sheets of the existing template into generated document.`, default: 'false', sinceVersion: '4.5.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.wrap.text', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.wrap.text', label: `net.sf.jasperreports.export.xls.wrap.text`, description: `Custom element property specifying if text wrapping is allowed in a given cell.`, default: 'true', sinceVersion: '3.7.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.write.header', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.write.header', label: `net.sf.jasperreports.export.xls.write.header`, description: `Property that specifies whether the export header (the column names) should be written or not, in the metadata-based Excel export.`, default: 'false', sinceVersion: '4.0.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xlsx.macro.template', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xlsx.macro.template', label: `net.sf.jasperreports.export.xlsx.macro.template`, description: `Property used to store the location of an existing Excel 2007 workbook template containing a macro object.`, sinceVersion: '4.5.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xml.end.page.index', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xml.end.page.index', label: `net.sf.jasperreports.export.xml.end.page.index`, description: `Property used to store the end page index for the XML export.`, sinceVersion: '3.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xml.page.count', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xml.page.count', label: `net.sf.jasperreports.export.xml.page.count`, description: `Property used to store the page count for the XML export.`, sinceVersion: '3.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xml.replace.invalid.chars', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xml.replace.invalid.chars', label: `net.sf.jasperreports.export.xml.replace.invalid.chars`, description: `Stores the text sequence used to replace invalid XML characters.`, default: '?', sinceVersion: '4.7.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xml.start.page.index', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xml.start.page.index', label: `net.sf.jasperreports.export.xml.start.page.index`, description: `Property used to store the start page index for the XML export.`, sinceVersion: '3.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.export.xml.validation', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xml.validation', label: `net.sf.jasperreports.export.xml.validation`, description: `Property that forces the XML validation, when a document exported to XML format is loaded.`, default: 'true', sinceVersion: '1.0.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.force.html.embed.image', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.force.html.embed.image', label: `net.sf.jasperreports.force.html.embed.image`, description: `Flag that determines whether charts, barcodes and other visualization elements produce images that are embedded into HTML export output.`, default: 'true', sinceVersion: '6.20.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'CHART_ELEMENT', 'COMPONENT'] }),
	]}),
	new PAccordeon({ id: 'fill', label: 'fill', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.band.split.type', vtype: {...SplitTypeEnum,custom: true, id: 'net.sf.jasperreports.band.split.type', label: `net.sf.jasperreports.band.split.type`, description: `Specifies the default split type for bands.`, default: 'Stretch', sinceVersion: '3.5.2'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.chart.pie.ignore.duplicated.key', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.chart.pie.ignore.duplicated.key', label: `net.sf.jasperreports.chart.pie.ignore.duplicated.key`, description: `Specifies whether duplicated key entries in pie dataset should be ignored and only the last value be considered, or whether an exception should be raised instead.`, default: 'false', sinceVersion: '6.0.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.chart.render.type', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.chart.render.type', label: `net.sf.jasperreports.chart.render.type`, description: `Specifies the default render type for charts.`, default: 'draw', sinceVersion: '2.0.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.chart.theme', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.chart.theme', label: `net.sf.jasperreports.chart.theme`, description: `Property used to specify the chart theme name.`, default: 'default', sinceVersion: '3.1.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.consume.space.on.overflow', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.consume.space.on.overflow', label: `net.sf.jasperreports.consume.space.on.overflow`, description: `Property used to force the expanding text fields to occupy all remaining space at the bottom of the page so that no other element renders there.`, default: 'true', sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.default.locale', vtype: {...Locale,custom: true, id: 'net.sf.jasperreports.default.locale', label: `net.sf.jasperreports.default.locale`, description: `The default locale used to fill reports.`, default: 'system locale', sinceVersion: '5.2.0'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.default.timezone', vtype: {...TimeZone,custom: true, id: 'net.sf.jasperreports.default.timezone', label: `net.sf.jasperreports.default.timezone`, description: `The default time zone used to fill reports.`, default: 'system time zone', sinceVersion: '5.2.0'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.element.template.populate.style', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.element.template.populate.style', label: `net.sf.jasperreports.element.template.populate.style`, description: `Flag that determines whether report style attributes are to be populated in
element print template objects during report execution.`, default: 'false', sinceVersion: '6.20.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.evaluator.ignore.npe', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.evaluator.ignore.npe', label: `net.sf.jasperreports.evaluator.ignore.npe`, description: `The expression evaluation engine in JasperReports has always ignored 
java.lang.NullPointerException exceptions raised during expression evaluation.`, default: 'true', sinceVersion: '6.1.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.frame.border.split.type', vtype: {...BorderSplitTypeEnum,custom: true, id: 'net.sf.jasperreports.frame.border.split.type', label: `net.sf.jasperreports.frame.border.split.type`, description: `Provides the default border split type for frames.`, default: 'NoBorders', sinceVersion: '6.0.0'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.legacy.frame.width.stretch.disabled', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.legacy.frame.width.stretch.disabled', label: `net.sf.jasperreports.legacy.frame.width.stretch.disabled`, description: `Flag to disable stretching the width of frames to accomodate containing elements
that become wider than their design time width at fill time (such as crosstabs
and subreports).`, default: 'false', sinceVersion: '6.13.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'FRAME'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.measure.simple.text', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.measure.simple.text', label: `net.sf.jasperreports.measure.simple.text`, description: `Flag property that specifies whether a faster text measurer should be used for simple texts (text without markup or complex scripts).`, default: 'true', sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.measure.simple.text.exact', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.measure.simple.text.exact', label: `net.sf.jasperreports.measure.simple.text.exact`, description: `Property that specifies the exact text measuring type.`, sinceVersion: '4.7.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.page.break.no.pagination', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.page.break.no.pagination', label: `net.sf.jasperreports.page.break.no.pagination`, description: `Determines how page breaks are to be handled in reports that are not paginated.`, default: 'ignore', sinceVersion: '5.0.4'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'BREAK_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.parameter.evaluation.time', vtype: {...ParameterEvaluationTimeEnum,custom: true, id: 'net.sf.jasperreports.parameter.evaluation.time', label: `net.sf.jasperreports.parameter.evaluation.time`, description: `Specifies the default value for the getEvaluationTime() property of a dataset parameter.`, default: 'Late', sinceVersion: '6.3.1'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.print.collapse.missing.bookmark.levels', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.print.collapse.missing.bookmark.levels', label: `net.sf.jasperreports.print.collapse.missing.bookmark.levels`, description: `This property is used to determine whether missing bookmarks levels should be colapsed when 
the propety net.sf.jasperreports.print.create.bookmarks is set`, default: 'false', sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.print.create.bookmarks', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.print.create.bookmarks', label: `net.sf.jasperreports.print.create.bookmarks`, description: `Property used to determine whether bookmarks should be collected at report generation time.`, default: 'false', sinceVersion: '5.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.print.keep.full.text', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.print.keep.full.text', label: `net.sf.jasperreports.print.keep.full.text`, description: `Property used to determine whether the fill process must preserve the original text for text elements that are not able to fit their entire contents.`, default: 'false', sinceVersion: '2.0.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.property.evaluation.time', vtype: {...PropertyEvaluationTimeEnum,custom: true, id: 'net.sf.jasperreports.property.evaluation.time', label: `net.sf.jasperreports.property.evaluation.time`, description: `Specifies the default value for the getEvaluationTime() setting of a dataset property expression.`, default: 'Early', sinceVersion: '6.3.1'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.scale.font.step.limit', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.scale.font.step.limit', label: `net.sf.jasperreports.scale.font.step.limit`, description: `Property used to specify the minimum amount when scaling down font size to adjust the content in text elements.`, default: 0.5, sinceVersion: '6.11.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.sql.date.pattern.timezone', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.sql.date.pattern.timezone', label: `net.sf.jasperreports.sql.date.pattern.timezone`, description: `Property that specifies the timezone ID for elements of type java.sql.Date in the report.`, sinceVersion: '6.1.1'}), scopes: ['REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.sql.time.pattern.timezone', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.sql.time.pattern.timezone', label: `net.sf.jasperreports.sql.time.pattern.timezone`, description: `Property that specifies the timezone ID for elements of type java.sql.Time in the report.`, sinceVersion: '6.1.1'}), scopes: ['REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.sql.timestamp.pattern.timezone', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.sql.timestamp.pattern.timezone', label: `net.sf.jasperreports.sql.timestamp.pattern.timezone`, description: `Property that specifies the timezone ID for elements of type java.sql.Timestamp in the report.`, sinceVersion: '6.1.1'}), scopes: ['REPORT', 'DATASET'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.style.evaluation.time.enabled', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.style.evaluation.time.enabled', label: `net.sf.jasperreports.style.evaluation.time.enabled`, description: `A flag the determines whether the style of an element is evaluated at
the element evaluation time, or at the time the band on which the element 
is placed is rendered.`, default: 'false', sinceVersion: '3.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.subreport.generate.rectangle', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.subreport.generate.rectangle', label: `net.sf.jasperreports.subreport.generate.rectangle`, description: `Property used to specify when rectangle elements should be generated  for subreports during the report filling.`, sinceVersion: '6.0.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET', 'ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.text.measurer.factory', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.text.measurer.factory', label: `net.sf.jasperreports.text.measurer.factory`, description: `This property can either hold the name of a text measurer factory class, or hold an alias of a text measurer factory class property.`, default: 'net.sf.jasperreports.engine.fill.TextMeasurerFactory', sinceVersion: '2.0.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.text.save.line.breaks', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.text.save.line.breaks', label: `net.sf.jasperreports.text.save.line.breaks`, description: `Boolean property that determines whether the positions where text line
break occurs are to be saved during report fill in oder to be used at
export time.`, default: 'false', sinceVersion: '3.5.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.text.truncate.at.char', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.text.truncate.at.char', label: `net.sf.jasperreports.text.truncate.at.char`, description: `Boolean property that determines whether text elements are to be truncated at the last character that fits.`, default: 'false', sinceVersion: '2.0.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.text.truncate.suffix', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.text.truncate.suffix', label: `net.sf.jasperreports.text.truncate.suffix`, description: `Property whose value is used as a suffix for the truncated text.`, default: 'empty string', sinceVersion: '2.0.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.viewer.async', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.viewer.async', label: `net.sf.jasperreports.viewer.async`, description: `Property that determines whether a report can be generated and displayed asynchronously in a viewer.`, default: 'true', sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.virtual.page.element.size', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.virtual.page.element.size', label: `net.sf.jasperreports.virtual.page.element.size`, description: `Property that determines how many print elements will make up a virtual page handled as a unit by the virtualizer.`, default: 2000, sinceVersion: '4.1.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.when.no.data.type', vtype: {...WhenNoDataTypeEnum,custom: true, id: 'net.sf.jasperreports.when.no.data.type', label: `net.sf.jasperreports.when.no.data.type`, description: `Property that specifies a default value for the whenNoDataType attribute of report.`, default: 'NoPages', sinceVersion: '6.3.1'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
	]}),
	new PAccordeon({ id: 'governor', label: 'governor', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.governor.max.pages', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.governor.max.pages', label: `net.sf.jasperreports.governor.max.pages`, description: `If the governor that checks if a report exceeds a specified limit of pages is turned on, this property will indicate the 
maximum number of pages allowed to be ran, in order to prevent a memory overflow error.`, sinceVersion: '3.1.4'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.governor.max.pages.enabled', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.governor.max.pages.enabled', label: `net.sf.jasperreports.governor.max.pages.enabled`, description: `A flag indicating whether the governor that checks if a report exceeds a specified limit of pages is turned on.`, default: 'false', sinceVersion: '3.1.4'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.governor.timeout', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.governor.timeout', label: `net.sf.jasperreports.governor.timeout`, description: `If the governor that checks if the elapsed time during report execution 
exceeds a specified amount of time is turned on, this property will indicate the 
maximum allowed amount of time, in order to prevent a memory overflow error.`, sinceVersion: '3.1.4'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.governor.timeout.enabled', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.governor.timeout.enabled', label: `net.sf.jasperreports.governor.timeout.enabled`, description: `A flag indicating whether the governor that checks if the elapsed time during report execution 
exceeds a specified amount of time is turned on.`, default: 'false', sinceVersion: '3.1.4'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
	]}),
	new PAccordeon({ id: 'map', label: 'map', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.map.client.id', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.components.map.client.id', label: `net.sf.jasperreports.components.map.client.id`, description: `Property that specifies the client ID for Google Maps API for Business.`, sinceVersion: '5.5.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.map.default.marker.icon', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.components.map.default.marker.icon', label: `net.sf.jasperreports.components.map.default.marker.icon`, description: `Property that specifies the URL of the default marker icon for the Google map component.`, sinceVersion: '6.20.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.map.google.version', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.components.map.google.version', label: `net.sf.jasperreports.components.map.google.version`, description: `Property that indicates which version of the Google Maps API to be loaded.`, sinceVersion: '6.4.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.map.key', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.components.map.key', label: `net.sf.jasperreports.components.map.key`, description: `Property that specifies the Google Maps API key.`, sinceVersion: '5.5.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.map.signature', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.components.map.signature', label: `net.sf.jasperreports.components.map.signature`, description: `Property that specifies the encrypted client signature for signed request URLs.`, sinceVersion: '5.5.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
	]}),
	new PAccordeon({ id: 'other', label: 'other', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.awt.check.available.printers', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.awt.check.available.printers', label: `net.sf.jasperreports.awt.check.available.printers`, description: `Property whose value is used to check the availability of printers accepting jobs.`, default: 'true', sinceVersion: '3.7.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.awt.ignore.missing.font', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.awt.ignore.missing.font', label: `net.sf.jasperreports.awt.ignore.missing.font`, description: `When set to false, it forces the engine to check if the font used as value for the fontName attribute inside 
the report template is available either through the JasperReports font extension mechanism or as a JVM accesible font, 
and raise a JRFontNotFoundException exception, if this font is not available.`, default: 'false', sinceVersion: '3.6.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.awt.indent.first.line', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.awt.indent.first.line', label: `net.sf.jasperreports.awt.indent.first.line`, description: `Property used to disable the indentation for the first line in a paragraph.`, default: 'true', sinceVersion: '6.12.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.awt.justify.last.line', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.awt.justify.last.line', label: `net.sf.jasperreports.awt.justify.last.line`, description: `Property used to force the justified alignment for the last line in a paragraph.`, default: 'false', sinceVersion: '6.12.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'TEXT_ELEMENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.report.version', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.report.version', label: `net.sf.jasperreports.report.version`, description: `Property that specifies the JasperReports version associated with this report.`, sinceVersion: '4.8.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
	]}),
	new PAccordeon({ id: 'repository', label: 'repository', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.legacy.relative.path.enabled', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.legacy.relative.path.enabled', label: `net.sf.jasperreports.legacy.relative.path.enabled`, description: `Property that determines whether resource paths in subreports, style templates and data adapters 
should be interpreted as relative to the master report location.`, default: 'false', sinceVersion: '6.6.0', deprecated: true}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
	]}),
	new PAccordeon({ id: 'table', label: 'table', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.table.accessible', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.components.table.accessible', label: `net.sf.jasperreports.components.table.accessible`, description: `Property that enables/disables the automatic addition of specific custom properties to the elements that make up the table and its cells.`, sinceVersion: '6.19.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'COMPONENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.table.floating.header', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.components.table.floating.header', label: `net.sf.jasperreports.components.table.floating.header`, description: `Property that enables/disables the floating header in the table component when scrolling.`, default: 'true', sinceVersion: '6.3.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'COMPONENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.table.generate.pdf.tags', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.components.table.generate.pdf.tags', label: `net.sf.jasperreports.components.table.generate.pdf.tags`, description: `Property that enables/disables the automatic addition of specific custom properties to table cell elements, 
that would in turn trigger the creation of special document accessibility tags during PDF export.`, default: 'false', sinceVersion: '6.1.2', deprecated: true}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'COMPONENT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.table.interactive', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.components.table.interactive', label: `net.sf.jasperreports.components.table.interactive`, description: `Property that enables/disables the interactivity in the table component`, default: 'true', sinceVersion: '4.7.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'COMPONENT', 'TABLE_COLUMN'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.components.table.when.no.data.type', vtype: {...WhenNoDataTypeTableEnum,custom: true, id: 'net.sf.jasperreports.components.table.when.no.data.type', label: `net.sf.jasperreports.components.table.when.no.data.type`, description: `Property that specifies a default value for the whenNoDataType attribute of table components.`, default: 'Blank', sinceVersion: '6.0.0'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT'] }),
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.create.sort.fields.for.groups', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.create.sort.fields.for.groups', label: `net.sf.jasperreports.create.sort.fields.for.groups`, description: `Property that specifies whether additional sort fields should be created automatically to preserve 
the integrity of dataset groups, when interactive sorting is performed.`, default: 'false', sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET'] }),
	]}),
]};
