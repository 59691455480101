/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { ErrorCorrectionLevelEnum } from "../../enums/ErrorCorrectionLevelEnum";
import { PInt } from "../../../ui/primitive/UPInteger";
import { FBarcode4J } from "./MBarcode4J";


export const FQRCode = {
    qrVersion: new PInt({ id: 'qrVersion', label: 'QR Version', min: 1, max: 40 }),
    margin: new PInt({ id: 'margin', label: 'Margin', default: 1, min: 0 }),
    errorCorrectionLevel: ErrorCorrectionLevelEnum
}

export const MQRCode: APDescriptor = {
    type: 'QRCode', id: 'QRCode', label: 'QRCode', icon: ICONS.BARCODE_QRCODE_ICON,
    layouts: [FBarcode4J.verticalQuietZone, FQRCode.qrVersion, FQRCode.margin, FQRCode.errorCorrectionLevel],
    default: {
        type: 'BARCODE', BARCODE_TYPE: 'QRCode', BARCODE_XMLNS_NAMESPACE: 'c',
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:c': "http://jasperreports.sourceforge.net/jasperreports/components",
        codeExpression: '"123456789"',
        width: 30, height: 30
    }
};
