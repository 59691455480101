/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../ui/APDescriptor";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";

export const FElementGroup = {

};

export const MElementGroup: APDescriptor = {
    type: ElementTypes.ELEMENT_GROUP,
    id: 'elementGroupProeprtiesDescriptor',
    label: 'Element Group',
    layouts: []
};
