/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { getPath, reg, UiProperty, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../../ui/APDescriptor';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, ICONS, TimedSearchBar, Typography } from "@jss/js-common";
import _ from 'lodash';
import { List as ImmuttableList, Map as ImmutableMap } from 'immutable';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import * as ReportActions from '../../../../../actions/reportActions';
import { connect } from 'react-redux';
import { getModel } from '../TypeFactory';
import { ListItem, ListItemSecondaryAction, ListItemText, List, Collapse, ListItemIcon, Menu } from '@material-ui/core';
import { ElementTypes } from '../../../../../sagas/report/document/elementTypes';
import MenuItem from "@material-ui/core/MenuItem";

interface UPStylesTreeState {
    search?: string;
    anchor: any;
}

interface IUPStylesTree {
    deleteElement?: (path: (string | number)[]) => void;
    addProperty?: (path: (string | number)[], element) => void;
}

const TYPEID = 'stylesTree';
export class PStylesTree extends APDescriptor {
    default?: any;
    seachable?: boolean;
    expanded?: boolean = false;
    public constructor(init: Partial<PStylesTree>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPStylesTree mcontext={mc} />; });


class UPStylesTree extends React.Component<UiPropertyProps & IUPStylesTree, UPStylesTreeState> {

    public state: UPStylesTreeState = {
        anchor: null,
    };

    handleArrowClick = (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({ anchor: event.currentTarget });
    };

    handleArrowClose = (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({ anchor: null });
    };

    getPopoverContent = () => {
        return <Menu
            classes={{
                paper: "jr-mMenu jr-mMenuDecorated mui",
                list: "jr-mMenu-list mui"
            }}
            elevation={4}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            id="customized-menu"
            anchorEl={this.state.anchor}
            keepMounted
            open={Boolean(this.state.anchor)}
            onClose={this.handleArrowClose}
        >
            <div />
            <MenuItem className={"jr-mMenu-list-item mui"} onClick={(event) => {
                this.addStyle(event);
            }}>
                <ListItemText
                    className={"jr-mMenu-list-item-text mui"}
                    classes={{ primary: "jr-mText mui" }}
                    primary={'Create Style'}
                />
            </MenuItem>
            <MenuItem className={"jr-mMenu-list-item mui"} onClick={(event) => {
                this.addStyleTemplate(event);
            }}>
                <ListItemText
                    className={"jr-mMenu-list-item-text mui"}
                    classes={{ primary: "jr-mText mui" }}
                    primary={'Create Style Template Reference'}
                />
            </MenuItem>
        </Menu>
    }


    private isExpanded = () => {
        const defaultValue = (this.props.mcontext.descriptor as PStylesTree).expanded === true;
        const isExpanded = this.props.mcontext.rootModel.getIn(['widgetStatus', this.props.mcontext.descriptor.id, 'expanded'], defaultValue) as boolean;
        return isExpanded;
    }

    private handleExpanded = () => {
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expanded'], !this.isExpanded(), false, true);
    }

    private getSelection = () => {
        const selection = this.props.mcontext.rootModel.getIn(['widgetStatus', this.props.mcontext.descriptor.id, 'selection']) as string | undefined;
        return selection;
    }

    private setSelection = (selection) => {
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'selection'], selection, false, true);
    }

    render() {
        const styles = this.renderStyles();
        return <div style={{ flex: 1, flexDirection: 'column', overflow: 'auto' }} >
            <Accordion expanded={this.isExpanded()} onChange={this.handleExpanded} style={{ borderBottom: 'solid #dedede 1px' }}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className="js-jrws-search-accordion-header" >
                    <Typography  >{this.props.mcontext.descriptor.label}</Typography>
                    {this.renderSearch()}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton color='primary' icon='plus' size='small' onClick={this.addStyle} />
                        <ArrowDropDownIcon style={{ width: 15, cursor: 'pointer', height: 22 }} onClick={this.handleArrowClick} />
                        {this.getPopoverContent()}
                    </div>
                </AccordionSummary>
                <AccordionDetails
                    style={this.props.mcontext.descriptor.style ? this.props.mcontext.descriptor.style : { display: 'block', height: '215px', overflow: 'auto', padding: '0px' }}
                    className={this.props.mcontext.descriptor.className}>
                        {styles.component}
                </AccordionDetails>
            </Accordion>

            {this.renderDetail(styles.pathMap)}
        </div>;
    }

    private renderStyles = () : {pathMap: Map<string, (string | number)[]>, component: React.ReactElement} => {
        const pathMap = new Map<string, (string | number)[]>();
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p) as ImmuttableList<ImmutableMap<string, any>> | undefined;
        if (!v) {
            return {
                pathMap: pathMap,
                component: <>Empty</>,
            };
        }
        const templateStyles = this.props.mcontext.model.get('templateStyles') as ImmuttableList<ImmutableMap<string, any>>;
        const fieldsToRender: { type: string, key: string, value: ImmutableMap<string, any> }[] = [];
        if (this.state.search?.length > 0) {
            v.forEach((field, index) => {
                const name = field.get('name');
                if (name && name.toLowerCase().includes(this.state.search)) {
                    fieldsToRender.push({ type: 'style', key: field.get('id'), value: field });
                    pathMap.set(field.get('id'), ['styles', index]);
                    const cstyles = field.get('conditionalStyles');
                    if (cstyles && cstyles.size > 0) {
                        cstyles.forEach((conditionalStyle, conditionalStyleIndex) => {
                            pathMap.set(conditionalStyle.get('id'), ['styles', index, 'conditionalStyles', conditionalStyleIndex]);
                        });
                    }
                }
            });

            templateStyles.forEach((expressionMap, index) => {
                const expression = expressionMap.get('expression');
                if (expression && expression.toLowerCase().includes(this.state.search)) {
                    fieldsToRender.push({ type: 'reference', key: expressionMap.get('id'), value: expressionMap });
                    pathMap.set(expressionMap.get('id'), ['templateStyles', index]);
                }
            });
        } else {
            v.forEach((field, index) => {
                fieldsToRender.push({ type: 'style', key: field.get('id'), value: field });
                pathMap.set(field.get('id'), ['styles', index]);
                const cstyles = field.get('conditionalStyles');
                if (cstyles && cstyles.size > 0) {
                    cstyles.forEach((conditionalStyle, conditionalStyleIndex) => {
                        pathMap.set(conditionalStyle.get('id'), ['styles', index, 'conditionalStyles', conditionalStyleIndex]);
                    });
                }
            });

            templateStyles.forEach((expressionMap, index) => {
                fieldsToRender.push({ type: 'reference', key: expressionMap.get('id'), value: expressionMap });
                pathMap.set(expressionMap.get('id'), ['templateStyles', index]);
            });
        }
        return {
            pathMap: pathMap,
            component: <List style={{ height: '150px', padding: '0px' }}>
                {fieldsToRender.map((fieldContainer, index) => {
                    const type = fieldContainer.type;
                    if (type === 'style') {
                        const field = fieldContainer.value;
                        const fieldName = field.get('name');
                        const cstyles = field.get('conditionalStyles');
                        return <div key={fieldContainer.key} >
                            <ListItem button
                                onClick={() => this.setSelection(fieldContainer.key)}
                                selected={this.getSelection() === fieldContainer.key}>
                                {this.renderConditionalStyleCollapse(cstyles, index, field)}
                                <ListItemIcon>{ICONS.STYLES_ICON}</ListItemIcon>
                                <ListItemText primary={fieldName} title={fieldName} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} />
                                <ListItemSecondaryAction>
                                    {this.renderMore(fieldName, index)}
                                    {this.renderDelete(fieldContainer.key, fieldContainer.type)}
                                </ListItemSecondaryAction>
                            </ListItem>
                            {this.renderConditionalStyles(cstyles, fieldName, index, field)}
                        </div>;
                    } else {
                        const expression = fieldContainer.value.get('expression');
                        return <div key={fieldContainer.key} >
                            <ListItem button onClick={() => this.setSelection(fieldContainer.key)} selected={this.getSelection() === fieldContainer.key}>
                                <IconButton style={{backgroundColor: 'transparent'}} icon='none' />
                                <ListItemIcon>{ICONS.STYLES_ICON}</ListItemIcon>
                                <ListItemText primary={expression} title={expression} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} />
                                <ListItemSecondaryAction>
                                    {this.renderDelete(fieldContainer.key, fieldContainer.type)}
                                </ListItemSecondaryAction>
                            </ListItem>
                        </div>;
                    }
                })}
            </ List>
        };
    }

    private renderConditionalStyles(cstyles, fieldName, index, parentStyle) {
        if (cstyles && cstyles.size > 0) {
            let stylesCount = 1;
            const parentStyleId = parentStyle.get('id');
            const expantedStyles = this.getExpandedStyles();
            const opened = expantedStyles.includes(parentStyleId);
            return <>
                <Collapse key={`cstyle.${fieldName}.${index}`} in={opened} timeout="auto" unmountOnExit>
                    <List key={`cstylelist.${fieldName}.${index}`} component="div" style={{ paddingLeft: '15px' }}>
                        {cstyles.map((cstyle, conditionalStyleIndex) => {
                            let label = cstyle.get('id');
                            const exp = cstyle.get('conditionExpression');
                            if (exp) {
                                label = exp.trim();
                            } else {
                                label = 'Conditional Style ' + stylesCount;
                                stylesCount++;
                            }
                            const conditionalStyleId = cstyle.get('id');
                            const conditionalStylePath = [index, 'conditionalStyles', conditionalStyleIndex];

                            return <ListItem key={`cstyle.${label}.${conditionalStyleIndex}`} button
                                onClick={() => this.setSelection(conditionalStyleId)}
                                selected={this.getSelection() === conditionalStyleId}>
                                <ListItemText primary={label} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={label} />
                                <ListItemSecondaryAction>
                                    {this.renderDelete(conditionalStylePath, 'conditionalStyle')}
                                </ListItemSecondaryAction>
                            </ListItem>;
                        })}
                    </List>
                </Collapse>
            </>;
        }
    }

    private getExpandedStyles = () => {
        const selection = this.props.mcontext.rootModel.getIn(['widgetStatus', this.props.mcontext.descriptor.id, 'expandedStyles'], []) as string | undefined;
        return selection;
    }

    private setExpandedStyles = (expandedStyles) => {
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expandedStyles'], expandedStyles, false, true);
    }

    private renderConditionalStyleCollapse = (cstyles, index, parentStyle) => {
        if (cstyles && cstyles.size > 0) {
            const parentStyleId = parentStyle.get('id');
            const expantedStyles = this.getExpandedStyles();
            const opened = expantedStyles.includes(parentStyleId);
            return <IconButton style={{backgroundColor: 'transparent'}} key={'imr.' + index} icon={opened ? 'caretUp' : 'caretDown'}
                onClick={() => {
                    let ex = [...expantedStyles];
                    if (opened){
                        ex = ex.filter((id) => {
                            return id !== parentStyleId;
                        })
                    } else {
                        ex.push(parentStyleId);
                    }
                    this.setExpandedStyles(ex);
                }} />;
        }
        return <IconButton style={{backgroundColor: 'transparent'}} key={'imr.' + index} icon='none' />;
    }

    private renderDetail = (pathMap: Map<string, (string | number)[]>) => {
        const selection = this.getSelection();
        if (selection) {
            const p = pathMap.get(selection);
            if (p){
                p.push('type')
                const v = this.props.mcontext.model.getIn(p) as string;
                if (v) {
                    p.pop();
                    const els = _.cloneDeep(this.props.mcontext.elements);
                    els.forEach(key => { key.path = [...p] });
                    let m = getModel(v);
                    const fm = this.props.mcontext.descriptor.layouts?.find(k => { return k.id === m.id });
                    if (fm) {
                        m = fm;
                    }
                    return <UiProperty key={m.id} mcontext={{ ...this.props.mcontext, descriptor: m, elements: els }} />;
                }
            }
        }
    }

    private deleteElement = (key) => {
        if (this.props.deleteElement) {
            const styles = this.props.mcontext.model.get('styles') as ImmuttableList<ImmutableMap<string, any>>;
            const index = styles.findIndex((style) => {
                return style.get('id') === key;
            });
            const p = ['model', 'styles', index];
            this.props.deleteElement(p);
            if (this.getSelection() === key){
                this.setSelection(undefined);
            }
        }
    }

    private deleteElementReference = (key) => {
        if (this.props.deleteElement) {
            const stylesReferences = this.props.mcontext.model.get('templateStyles') as ImmuttableList<ImmutableMap<string, any>>;
            const index = stylesReferences.findIndex((styleReference) => {
                return styleReference.get('id') === key;
            });
            const p = ['model', 'templateStyles', index];
            this.props.deleteElement(p);
            if (this.getSelection() === key){
                this.setSelection(undefined);
            }
        }
    }

    private deleteConditionalStyle = (path) => {
        if (this.props.deleteElement) {
            const p = ['model', 'styles', ...path];
            const conditionalStyleId = this.props.mcontext.rootModel.getIn([...p, 'id']);
            this.props.deleteElement(p);
            if (this.getSelection() === conditionalStyleId){
                this.setSelection(undefined);
            }
        }
    }

    private renderDelete(key, type) {
        if (type === 'style') {
            return <IconButton key={'ib.' + key} icon='delete' onClick={() => this.deleteElement(key)} />;
        } else if (type === 'conditionalStyle') {
            return <IconButton key={'ib.' + key} icon='delete' onClick={() => this.deleteConditionalStyle(key)} />;
        } else {
            return <IconButton key={'ib.' + key} icon='delete' onClick={() => this.deleteElementReference(key)} />;
        }
    }

    private renderMore(fieldName, key) {
        return <IconButton key={`imore.${fieldName}.${key}`} icon='plus' onClick={(event) => this.addConditionalStyle(event, fieldName, key)} />;
    }

    private addConditionalStyle = (event: React.MouseEvent<HTMLElement>, fieldName: string, key) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        // here add conditional style
        const d = this.props.mcontext.descriptor as PStylesTree;

        const p: (string | number)[] = ['model', ...this.props.mcontext.elements[0].path];
        p.push(d.id);
        p.push(key)
        p.push('conditionalStyles');
        this.props.addProperty(p, { type: ElementTypes.CONDITIONAL_STYLE, id: 'conditional_style-' });
    }

    private renderSearch() {
        const d = this.props.mcontext.descriptor as PStylesTree;
        if (d.seachable === true)
            return <TimedSearchBar onClick={this.onClick} className="js-jrws-properties-search" placeholder='search ...' id="file-searchbar" onChange={this.seearchTextChange} value={this.state.search} pauseTime={40} />
    }

    private onClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private seearchTextChange = (v: string) => {
        v = v.trim().toLowerCase();
        v = v.length > 0 ? v : undefined;
        this.setState({ search: v });
    }

    private addStyle = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.handleArrowClose(event);
        const d = this.props.mcontext.descriptor as PStylesTree;
        const def = d.default ? { ...d.default } : {};
        if (def.name) {
            const pth = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
            const v = this.props.mcontext.model.getIn(pth) as ImmuttableList<ImmutableMap<string, any>>;

            if (v) {
                const fields: ImmuttableList<ImmutableMap<string, any>> = v;
                def.name = this.findName(def.name, def.name, 1, fields);
            }
        }
        def.id = 'style-';

        const p: (string | number)[] = ['model', ...this.props.mcontext.elements[0].path];
        p.push(d.id);
        this.props.addProperty(p, def);
    }

    private addStyleTemplate = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.handleArrowClose(event);
        const def = { type: ElementTypes.STYLE_TEMPLATE_REFERENCE, expression: '"EXPRESSION"', id: 'template-' }

        const p: (string | number)[] = ['model', 'templateStyles'];
        this.props.addProperty(p, def);
    }


    private findName = (n: string, prefix: string, id: number, fields: ImmuttableList<ImmutableMap<string, any>>): string => {
        for (const f of fields) {
            const name = f.get('name');
            if (name === n) {
                return this.findName(prefix + id, prefix, id + 1, fields);
            }
        }
        return n;
    }
}


const mapStateToProps = () => {
    return {};
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteElement: (path: (string | number)[]) => { dispatch(ReportActions.deleteElement(path)); },
        addProperty: (path: (string | number)[], element) => { dispatch(ReportActions.addProperty(path, element)); },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UPStylesTree);