/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import {
    CommandHandler,
    CommandRegistry,
    getExitDialog,
    IToolbarProvider,
    ResourceListenerManager,
    RouterProps,
    withRouter
} from "@jss/js-common";
import { ResourceRouteState, RunContainer } from '@jss/js-repository';
import { IEditorViewProps, IRepositoryItemDescriptor, MessageInfo } from '@jss/js-rest-api';
import {
    EXIT_TEXT_EDITOR,
    TEXT_EDITOR_DIRTY,
    TEXT_SAVED,
    TEXT_SAVED_AS,
    UPDATED_DESCRIPTOR
} from "@jss/js-text-editor";
import { DataAdapterTextEditor } from "@jss/js-text-editor/src/editor/DataAdapterTextEditor";
import React from 'react';
import {
    DataSourceEditor,
    DATA_ADAPTER_EDITOR_DIRTY,
    DATA_ADAPTER_SAVED,
    DATA_ADAPTER_SAVED_AS,
    EXIT_DATA_ADAPTER_EDITOR,
    getDataAdapterTypes,
    IDataAdapterDescriptor,
    UPDATED_DATA_ADAPTER_CONTENT
} from '../';
import i18n from "../i18n";
import { getJrsDataAdapterTypes } from '../datasource/DataAdaptersRegistry';
import { IconButton } from "@material-ui/core";
import { getDataAdapterNode } from "./Utils";


interface IState {
    openDescriptor: IRepositoryItemDescriptor | undefined;
    path?: IRepositoryItemDescriptor[];
    saveDialogOpen: boolean;
    daToSave: string | undefined;
    daToSaveClass: string | undefined;
    baseName: string;
    toolbardData: IToolbarProvider | undefined;
    currentMessage: MessageInfo | undefined;
    savedOnce: boolean;
    currentTabIndex: number;
    isEditorDirty: boolean;
    isSupportedDataAdapter: boolean;
    exitAlertOpen: boolean;
    logoutCallback: () => void | undefined;
}

class RegistryStub implements CommandRegistry {

    private editor: _DataAdapterEditorView;

    constructor(editor: _DataAdapterEditorView) {
        this.editor = editor;
    }

    public registerHandler = (id: string, handle: CommandHandler) => {
        this.editor.commandHandlers.set(id, handle);
    }

    public registerToolbar = (provider: IToolbarProvider) => {
        this.editor.updateToolbar(provider);
    }

    public executeCommand = (id: string, ...params: any) => {
        if (id === EXIT_DATA_ADAPTER_EDITOR || id === EXIT_TEXT_EDITOR) {
            if (this.editor.state.isEditorDirty){
                this.editor.setState({exitAlertOpen: true});
            } else {
                this.editor.props.doExit();
            }
        } else if (id === DATA_ADAPTER_SAVED || id === TEXT_SAVED || id === UPDATED_DATA_ADAPTER_CONTENT || id === UPDATED_DESCRIPTOR || id === DATA_ADAPTER_SAVED_AS || id === TEXT_SAVED_AS) {
            if (params[0] && params[0].descriptor) {
                if (!this.editor.state.savedOnce) {
                    this.editor.props.onFirstSave(params[0].descriptor);
                }

                if (id === DATA_ADAPTER_SAVED_AS){
                    this.editor.setState({ openDescriptor: params[0].descriptor, savedOnce: true, currentTabIndex: 0 }, () => {
                        window.history.replaceState("", "",`/edit?path=${this.editor.state.openDescriptor.uuid}`);
                        this.editor.props.router.location.search = `?path=${this.editor.state.openDescriptor.uuid}`;
                    });
                } else if ( id === TEXT_SAVED_AS) {
                    this.editor.setState({ openDescriptor: params[0].descriptor, savedOnce: true, currentTabIndex: 1 }, () => {
                        window.history.replaceState("", "",`/edit?path=${this.editor.state.openDescriptor.uuid}`);
                        this.editor.props.router.location.search = `?path=${this.editor.state.openDescriptor.uuid}`;
                    });
                } else {
                    this.editor.setState({ openDescriptor: params[0].descriptor, savedOnce: true });
                }
            }
        } else if (id === DATA_ADAPTER_EDITOR_DIRTY || id === TEXT_EDITOR_DIRTY) {
            this.editor.setState({ isEditorDirty: params[0].isDirty });
        }
    }

    public showMessage = (messageInfo: MessageInfo) => {
        this.editor.setState({ currentMessage: messageInfo });
    }
}

export type getDataAdapterEditorHeaderComponentType = (
    {
        messageInfo,
        tabs,
        tabIndex,
        toolbarData,
        resourceName,
        onTabChange,
        goToHome,
        requestLogout,
        closeMessage
    } :
    {
        messageInfo: MessageInfo,
        tabs: Array<any>,
        tabIndex: number,
        toolbarData: IToolbarProvider,
        resourceName: string,
        onTabChange: (tabIndex: number) => void,
        goToHome: () => void,
        requestLogout: (callback) => void,
        closeMessage: () => void
    }) => JSX.Element;

export interface DataAdapterEditorViewProps extends IEditorViewProps {
    getHeaderComponent: getDataAdapterEditorHeaderComponentType;
    language?: string,
}

/**
 * This is the repository main screen
 */
class _DataAdapterEditorView extends React.Component<DataAdapterEditorViewProps & RouterProps, IState>  {

    private registryStub = new RegistryStub(this);

    private resourceListeners = [new ResourceListenerManager(this)];

    public commandHandlers: Map<string, CommandHandler> = new Map();

    private visualEditorRef: React.RefObject<any> = React.createRef<any>();

    private xmlEditorRef: React.RefObject<any> = React.createRef<any>();

    public state: IState = {
        currentTabIndex: 0,
        openDescriptor: undefined,
        path: undefined,
        saveDialogOpen: false,
        daToSave: undefined,
        daToSaveClass: undefined,
        baseName: i18n.t('dataAdaptersView.basename.text'),
        toolbardData: undefined,
        currentMessage: undefined,
        savedOnce: false,
        isEditorDirty: false,
        isSupportedDataAdapter: true,
        exitAlertOpen: false,
        logoutCallback: undefined,
    }

    private onBeforeUnload = (event) => {
        // Show prompt based on state
        if (this.state && this.state.isEditorDirty) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = ''
            }
            return '';
        }
    }

    public componentDidMount = () => {
        window.addEventListener("beforeunload", this.onBeforeUnload);

        const dataAdaptersData: ResourceRouteState =  { descriptor: this.props.descriptor };
        if (dataAdaptersData) {
            const { descriptor } = dataAdaptersData;
            const path = this.props.path;
            const isNewResource = !descriptor.uuid;
            const isSupportedDataAdapter = this.isSupportedDataAdapter(descriptor);
            this.setState({
                isSupportedDataAdapter: isSupportedDataAdapter,
                openDescriptor: descriptor,
                path,
                savedOnce: !isNewResource,
                isEditorDirty: isNewResource
            }, () => {
                this.resourceListeners.forEach((resourceListener) => {
                    resourceListener.fireOpenEvent(this.state.openDescriptor, this.state.openDescriptor.data, this.state.path);
                });
            });
        }
    }

    public componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onBeforeUnload);
    }

    public updateToolbar = (toolbarProvider: IToolbarProvider) => {
        this.setState({ toolbardData: toolbarProvider });
    }

    private closeMessage = () => {
        this.setState({ currentMessage: undefined });
    }

    public onTabChange = (newTabIndex: number) => {
        let newData;
        if (newTabIndex !== 2) {
            if (this.state.currentTabIndex === 0) {
                const currentData = this.visualEditorRef.current.getEditorContent();
                newData = currentData;
            } else if (this.state.currentTabIndex === 1) {
                const currentData = this.xmlEditorRef.current.getEditorContent();
                newData = currentData;
            }
        }
        this.setState({ currentTabIndex: newTabIndex }, () => {
            this.resourceListeners.forEach((resourceListener) => {
                resourceListener.fireOpenEvent(this.state.openDescriptor, newData, this.state.path);
            });
        });
    }

    public getTabContent = () => {
        if (this.state.currentTabIndex === 1 || !this.state.isSupportedDataAdapter) {
            return <DataAdapterTextEditor language={this.props.language} ref={this.xmlEditorRef} resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />
        } else {
            return <DataSourceEditor language={this.props.language} ref={this.visualEditorRef} resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />
        }
    }

    private goToHome = () => {
        if (this.state.currentTabIndex === 1) {
            const handler = this.commandHandlers.get(EXIT_TEXT_EDITOR);
            if (handler && handler.isEnabled()) {
                handler.execute({});
            }
        } else {
            const handler = this.commandHandlers.get(EXIT_DATA_ADAPTER_EDITOR);
            if (handler && handler.isEnabled()) {
                handler.execute({});
            }
        }
    }

    private requestLogout = (callback: () => void) => {
        if (this.state.isEditorDirty) {
            this.setState({logoutCallback: callback, exitAlertOpen: true});
        } else {
            callback();
        }
    }

    private isSupportedDataAdapter = (descriptor: IRepositoryItemDescriptor) => {
        if (getJrsDataAdapterTypes(descriptor.mime) !== undefined){
            return true;
        } else if (descriptor.data){
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(descriptor.data, 'text/xml');
            const dataAdapterType = getDataAdapterNode(xmlDoc.getRootNode()).nodeName;
            const dataAdapterTypes = getDataAdapterTypes();
            const da = dataAdapterTypes.find((item: IDataAdapterDescriptor) => item.getDataAdapterRootName() === dataAdapterType);
            if (!da) {
                return false;
            }
        }
        return true;
    }

    private getTabs = () => {
        if (this.state.isSupportedDataAdapter){
            return [
                {
                    label: i18n.t('dataAdaptersView.tabs.editor'),
                    icon: <IconButton
                    aria-label="edit"
                    className={"jr-mButton jr-mButtonLarge jr-mButtonSecondary jr-MuiButton-contained mui"}
                    classes={{ label: "jr-mButton-icon jr-mIcon mui jr-edit"}}/>
                },
                {
                    label: i18n.t('dataAdaptersView.tabs.source'),
                    icon: <IconButton
                    aria-label="edit"
                    className={"jr-mButton jr-mButtonLarge jr-mButtonSecondary jr-MuiButton-contained mui"}
                    classes={{ label: "jr-mButton-icon jr-mIcon mui jr-code"}}/>
                }
            ]
        } else {
            return [
                {
                    label: i18n.t('dataAdaptersView.tabs.source'),
                    icon: <IconButton
                    aria-label="edit"
                    className={"jr-mButton jr-mButtonLarge jr-mButtonSecondary jr-MuiButton-contained mui"}
                    classes={{ label: "jr-mButton-icon jr-mIcon mui jr-code"}}/>
                }
            ]
        }
    }

    private handleExitAlertCancel = () => {
        this.setState({ exitAlertOpen: false, logoutCallback: undefined });
    }

    private handleExitConfirm = () => {
        this.setState({exitAlertOpen: false, isEditorDirty: false}, () => {
            if (this.state.logoutCallback) {
                this.state.logoutCallback()
            } else {
                this.props.doExit();
            }
        })
    }

    public render() {
        let dataAdapterName: string;
        if (this.state.openDescriptor !== undefined) {
            dataAdapterName = this.state.openDescriptor.name;
        } else {
            dataAdapterName = this.state.baseName;
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                {
                    this.props.getHeaderComponent({
                        messageInfo: this.state.currentMessage,
                        tabs: this.getTabs(),
                        tabIndex: this.state.currentTabIndex,
                        toolbarData: this.state.toolbardData,
                        resourceName: dataAdapterName,
                        onTabChange: this.onTabChange,
                        goToHome: this.goToHome,
                        requestLogout: this.requestLogout,
                        closeMessage: this.closeMessage
                    })
                }
                <div style={{ flex: 1, justifyContent: 'stretch', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                    <RunContainer>
                        {this.getTabContent()}
                    </RunContainer>
                </div>
                {getExitDialog(this.state.exitAlertOpen, this.handleExitAlertCancel, this.handleExitConfirm)}
            </div>
        );
    }
}

export const DataAdapterEditorView = withRouter(_DataAdapterEditorView);
