/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import '../assets/css/DataAdapterTypePicker.css';
import StorageRoundedIcon from '@material-ui/icons/StorageRounded';
import i18n from '../i18n';

interface IDataAdapterTypePickerItem {
  id: string,
  label: string,
  description?: string,
  icon?: JSX.Element,
  selected: boolean,
  onClick?: (type: string) => void
}


export class DataAdapterTypePickerItem extends React.Component<IDataAdapterTypePickerItem> {

  public render() {

    const classes = 'tc-jsw-dataadapter-type-picker-item ' + ((this.props.selected) ? 'selected' : '');

    return <div key={this.props.id} className={classes} onClick={this.onClick}>
      <div className="tc-jsw-dataadapter-type-picker-item-icon">
        {this.props.icon ? <span style={{marginTop: 4, marginLeft: 2, display: 'flex'}}>{this.props.icon}</span> : <StorageRoundedIcon fontSize="small" />}
      </div>
      <div className='tc-jsw-dataadapter-type-picker-item-text-content'>
        <span className="tc-jsw-dataadapter-type-picker-item-title">{i18n.t(this.props.label)}</span>
        <span className="tc-jsw-dataadapter-type-picker-item-description">{i18n.t(this.props.description)}</span>
      </div>
    </div>
  }


  private onClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.id);
    }
  }
}