/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import {   reg } from '../../ui/UiProperty';
import { APDescriptor } from '../../ui/APDescriptor';

import UPStylesTree from './UPStylesTree';



const TYPEID = 'connectedStylesTree';
export class PConnectedStylesTree extends APDescriptor {
    default?: any;
    seachable?: boolean;
    expanded?: boolean = false;
    public constructor(init: Partial<PConnectedStylesTree>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPStylesTree mcontext={mc} />; });


