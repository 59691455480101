/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { IRepositoryItemDescriptor, RepositoryApi } from '@jss/js-rest-api';
import { AxiosResponse } from 'axios';
import { UxType, MenuItem } from '@jss/js-common';
import { RunContext } from '../../RunContainer';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { Base64 } from 'js-base64';
import i18n from '../../i18n';


export interface IResourceSaveProps {
    type?: UxType;
    size?: 'small' | 'medium' | 'large';
    disabled?: boolean;
    descriptor: IRepositoryItemDescriptor;
    onDescriptorSaved: (newDescriptor: IRepositoryItemDescriptor) => void;
}

export class ResourceSave extends React.Component<IResourceSaveProps> {
    static defaultProps = {
        type: UxType.BUTTON,
        size: 'large',
        disabled: false
    }
    render() {
        switch (this.props.type) {
            case UxType.BUTTON: return <Button onClick={this.doUpdateFile} disabled={this.props.disabled} size={this.props.size}>{i18n.t('repository.delete.dialog.save')}</Button>;
            case UxType.MENU: return <MenuItem label={i18n.t('repository.delete.dialog.save')} disabled={this.props.disabled} icon={<SaveIcon fontSize="small" />} onClick={this.doUpdateFile} />;
        }
    }

    private doUpdateFile = () => {
        if (this.props.descriptor.data) {
            this.props.descriptor.data = Base64.encode(this.props.descriptor.data);
        }
        this.context.runNoCancel(RepositoryApi.inst().save)('uuid:' + this.props.descriptor.uuid, this.props.descriptor).then((response: AxiosResponse) => {
            if (response.data) {
                this.props.onDescriptorSaved(response.data);
            }
        }).catch(error => console.log(error));
    }

}
ResourceSave.contextType = RunContext;