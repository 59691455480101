/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from '../uxpl/button/Button';
import i18n from '../i18n';

export const getExitDialog = (exitAlertOpen : boolean, handleExitAlertCancel : () => void, handleExitAlertConfirm : () => void) => {
    return <Dialog
        open={exitAlertOpen}
        onClose={handleExitAlertCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {i18n.t('common.dialog.exitWithoutSave')}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleExitAlertCancel} size="large" color="secondary" variant="contained">{i18n.t('common.button.cancel')}</Button>
            <Button onClick={handleExitAlertConfirm} size="large" color="primary" variant="contained">{i18n.t('common.button.exitEditor')}</Button>
        </DialogActions>
    </Dialog>
}