/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import { connect } from 'react-redux';
import withPositionFinder, { IWithPositionFinder } from '../hoc/positionFinder';
import DummyElement from './DummyElement';
import FrameElement from './FrameElement';
import SelectionFigure from './interaction/SelectionFigure';
import StaticTextElement from './StaticTextElement';
import TextFieldElement from './TextFieldElement';
import { TableTypes } from '../../common/JrxmlModel/reader/JrxmlTableUtils';
import { CrosstabTypes } from '../../common/JrxmlModel/reader/JrxmlCrosstabUtils';
import { IState } from '../../../reducers';
import { Map, List } from 'immutable';
import ImageElement from './ImageElement';
import { resolveAttribute } from '../properties/types/common/StyleResolver';

import '../../../assets/uxpl/css/BaseElement.css';
import { ElementTypes } from '../../../sagas/report/document/elementTypes';
import LineElement from './LineElement';
import RectangleElement from './RectangleElement';
import BarcodeElement from './BarcodeElement';
import ChartElement from './ChartElement';
import SubreportElement from './SubreportElement';
import HighChartElement from './HighChartElement';
import EllipseElement from './EllipseElement';
import CVCElement from './CVCElement';
import MapElement from './MapElement';
import GenericElement from './GenericElement';
import FusionChartElement from './FusionChartElement';
import FusionWidgetElement from './FusionWidgetElement';
import BreakElement from './BreakElement';
import AdHocElement from './AdHocElement';

export interface IBaseElement extends IWithPositionFinder {
  id: string;
  element?: any;
  model?: Map<string, any>;
  selection?: Map<string, any>;
  zoom: number;
  highlightedAsContainer?: boolean;
  modelActions?: List<any>;
}

/*const visualProperties = ['x', 'y', 'width', 'height', 'highlighted'];

const inheritedPen = ['pen.lineColor', 'pen.lineWidth', 'pen.lineStyle'];

const inheritedBox = ['box.pen.lineColor', 'box.pen.lineWidth', 'box.pen.lineStyle', 'box.topPen.lineColor',
  'box.topPen.lineWidth', 'box.topPen.lineStyle', 'box.bottomPen.lineColor', 'box.bottomPen.lineWidth',
  'box.bottomPen.lineStyle', 'box.leftPen.lineColor', 'box.leftPen.lineWidth', 'box.leftPen.lineStyle',
  'box.rightPen.lineColor', 'box.rightPen.lineWidth', 'box.rightPen.lineStyle', 'box.padding', 'box.topPadding',
  'box.leftPadding', 'box.bottomPadding', 'box.rightPadding'];

const inheritedProperties = ['mode', 'backcolor', 'forecolor'];*/

export const getPen = (borderWidth: number, borderStyle: string, borderColor: string, side?: string): React.CSSProperties => {
  let currentBorderWidth = borderWidth;
  if (0 < borderWidth && borderWidth < 1) {
    currentBorderWidth = 1;
  }

  let currentBorderStyle;
  const cleanedStyle = borderStyle ? borderStyle.toLowerCase().trim() : borderStyle;
  switch (cleanedStyle) {
    case 'double':
      {
        currentBorderStyle = "double";
        break;
      }
    case 'dotted':
      {
        currentBorderStyle = "dotted";
        break;
      }
    case 'dashed':
      {
        currentBorderStyle = "dashed";
        break;
      }
    case 'solid':
    default:
      {
        currentBorderStyle = "solid";
        break;
      }
  }

  const result: React.CSSProperties = {};
  if (currentBorderWidth > 0) {
    const value = `${(currentBorderWidth)}px ${currentBorderStyle} ${borderColor}`;
    if (side) {
      switch (side) {
        case 'left':
          result.borderLeft = value;
          break;
        case 'right':
          result.borderRight = value;
          break;
        case 'top':
          result.borderTop = value;
          break;
        case 'bottom':
          result.borderBottom = value;
          break;
      }
    } else {
      result.border = value;
    }

  }

  return result;
}

export class BaseElement extends React.Component<IBaseElement> {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public shouldComponentUpdate(nextProps: Readonly<IBaseElement>): boolean {
    return true;
    /*
    //need to move the position finder inside the reducer
    if (nextProps.modelActions.size !== this.props.modelActions.size) {
      const lastAction = nextProps.modelActions.get(nextProps.modelActions.size - 1);
      if (lastAction.type === Actions.SET_BAND_SIZE) {
        return true;
      }
      if (lastAction.type === Actions.SET_OBJECT_PROPERTIES && lastAction.path[1] === 'bands') {
        //when a band change refresh the element to update the position
        return true;
      }

      if (lastAction.type === Actions.UPDATE_ELEMENT_SIZE_AND_POSITION || lastAction.type === Actions.UPDATE_ELEMENTS_CONTAINER) {
        //when a band change refresh the element to update the position
        return true;
      }
    }

    if (this.props.zoom !== nextProps.zoom) {
      return true;
    }

    if (this.props.highlightedAsContainer !== nextProps.highlightedAsContainer) {
      return true;
    }

    if (this.props.selection.has(this.props.id) !== nextProps.selection.has(this.props.id)) {
      return true;
    }

    for (const propName of visualProperties) {
      if (this.props.element.get(propName) !== nextProps.element.get(propName)) {
        return true;
      }
    }

    for (const propName of inheritedProperties) {
      if (resolveAttribute(this.props.model, this.props.element, propName) !== resolveAttribute(nextProps.model, nextProps.element, propName)) {
        return true;
      }
    }

    const elementType = nextProps.element.get('type');
    if (elementType === ElementTypes.RECTANGLE || elementType === ElementTypes.LINE || elementType === ElementTypes.ELLIPSE) {
      for (const propName of inheritedPen) {
        if (resolveAttribute(this.props.model, this.props.element, propName) !== resolveAttribute(nextProps.model, nextProps.element, propName)) {
          return true;
        }
      }
    } else if (elementType === ElementTypes.STATICTEXT || elementType === ElementTypes.TEXTFIELD || elementType === ElementTypes.IMAGE ||
      elementType === ElementTypes.FRAME || elementType === ElementTypes.CROSSTAB || elementType === ElementTypes.TABLE ||
      elementType === ElementTypes.JFREECHART) {
      for (const propName of inheritedBox) {
        if (resolveAttribute(this.props.model, this.props.element, propName) !== resolveAttribute(nextProps.model, nextProps.element, propName)) {
          return true;
        }
      }
    }

    return false; // Nothing impacting the content of the figure is changed...*/
  }

  private getBordersStyle = (elementType: string, width: number, height: number, top: number, left: number): { css: React.CSSProperties, topOffset: number, leftOffset: number } => {
    const zoom = (this.props.zoom) ? this.props.zoom : 1;
    if (elementType === ElementTypes.STATICTEXT || elementType === ElementTypes.TEXTFIELD || elementType === ElementTypes.IMAGE ||
      elementType === ElementTypes.FRAME || elementType === ElementTypes.CROSSTAB || elementType === ElementTypes.TABLE ||
      elementType === ElementTypes.JFREECHART) {

      //global width and color always fallback to something in the designer to show a border on the element
      let globalColor = resolveAttribute(this.props.model, this.props.element, 'box.pen.lineColor');
      let globalWidth = resolveAttribute(this.props.model, this.props.element, 'box.pen.lineWidth');
      if (!globalWidth) {
        globalWidth = 1;
        globalColor = '#CCCCCC';
      }
      const globalStyle = resolveAttribute(this.props.model, this.props.element, 'box.pen.lineStyle');

      const topColor = resolveAttribute(this.props.model, this.props.element, 'box.topPen.lineColor', { fallbackToDefault: false });
      const topWidth = resolveAttribute(this.props.model, this.props.element, 'box.topPen.lineWidth', { fallbackToDefault: false });
      const topStyle = resolveAttribute(this.props.model, this.props.element, 'box.topPen.lineStyle', { fallbackToDefault: false });
      const topMergedWidth = (topWidth ? topWidth : globalWidth);
      const topBorder = getPen(topMergedWidth * zoom, topStyle ? topStyle : globalStyle, topColor ? topColor : globalColor, 'top');

      const bottomColor = resolveAttribute(this.props.model, this.props.element, 'box.bottomPen.lineColor', { fallbackToDefault: false });
      const bottomWidth = resolveAttribute(this.props.model, this.props.element, 'box.bottomPen.lineWidth', { fallbackToDefault: false });
      const bottomStyle = resolveAttribute(this.props.model, this.props.element, 'box.bottomPen.lineStyle', { fallbackToDefault: false });
      const bottomMergedWidth = (bottomWidth ? bottomWidth : globalWidth);
      const bottomBorder = getPen(bottomMergedWidth * zoom, bottomStyle ? bottomStyle : globalStyle, bottomColor ? bottomColor : globalColor, 'bottom');

      const leftColor = resolveAttribute(this.props.model, this.props.element, 'box.leftPen.lineColor', { fallbackToDefault: false });
      const leftWidth = resolveAttribute(this.props.model, this.props.element, 'box.leftPen.lineWidth', { fallbackToDefault: false });
      const leftStyle = resolveAttribute(this.props.model, this.props.element, 'box.leftPen.lineStyle', { fallbackToDefault: false });
      const leftMergedWidth = (leftWidth ? leftWidth : globalWidth);
      const leftBorder = getPen(leftMergedWidth * zoom, leftStyle ? leftStyle : globalStyle, leftColor ? leftColor : globalColor, 'left');

      const rightColor = resolveAttribute(this.props.model, this.props.element, 'box.rightPen.lineColor', { fallbackToDefault: false });
      const rightWidth = resolveAttribute(this.props.model, this.props.element, 'box.rightPen.lineWidth', { fallbackToDefault: false });
      const rightStyle = resolveAttribute(this.props.model, this.props.element, 'box.rightPen.lineStyle', { fallbackToDefault: false });
      const rightMergedWidth = (rightWidth ? rightWidth : globalWidth);
      const rightBorder = getPen(rightMergedWidth * zoom, rightStyle ? rightStyle : globalStyle, rightColor ? rightColor : globalColor, 'right');
      const css: React.CSSProperties = {
        display: 'flex',
        top: top - (topMergedWidth / 2) * zoom,
        left: left - (leftMergedWidth / 2) * zoom,
        height: Math.max(0, height - (((topMergedWidth + bottomMergedWidth) / 2) * zoom)),
        width: Math.max(0, width - (((leftMergedWidth + rightMergedWidth) / 2) * zoom)),
        position: 'absolute',
        ...topBorder,
        ...bottomBorder,
        ...leftBorder,
        ...rightBorder
      };
      return { css, topOffset: (topMergedWidth / 2) * zoom, leftOffset: (leftMergedWidth / 2) * zoom }
    } else if (elementType !== ElementTypes.RECTANGLE && elementType !== ElementTypes.LINE && elementType !== ElementTypes.ELLIPSE) {
      //case simple border
      const defaultBorder: React.CSSProperties = {
        top: top,
        left: left,
        height: height - 2 * zoom,
        width: width - 2 * zoom,
        position: 'absolute',
      };
      defaultBorder.border = `${(1 * zoom)}px solid #CCCCCC`;
      return { css: defaultBorder, topOffset: top, leftOffset: left };
    }
    return { css: undefined, topOffset: 0, leftOffset: 0 };
  }

  private getPadding = (elementType: string): { padding: React.CSSProperties, width: number, height: number } => {
    const zoom = (this.props.zoom) ? this.props.zoom : 1;
    if (elementType === ElementTypes.STATICTEXT || elementType === ElementTypes.TEXTFIELD || elementType === ElementTypes.IMAGE ||
      elementType === ElementTypes.FRAME || elementType === ElementTypes.CROSSTAB || elementType === ElementTypes.TABLE ||
      elementType === ElementTypes.JFREECHART) {
      const globalPadding = resolveAttribute(this.props.model, this.props.element, 'box.padding');

      const leftPadding = resolveAttribute(this.props.model, this.props.element, 'box.leftPadding', { fallbackToDefault: false });
      const bottomPadding = resolveAttribute(this.props.model, this.props.element, 'box.bottomPadding', { fallbackToDefault: false });
      const rightPadding = resolveAttribute(this.props.model, this.props.element, 'box.rightPadding', { fallbackToDefault: false });
      const topPadding = resolveAttribute(this.props.model, this.props.element, 'box.topPadding', { fallbackToDefault: false });

      const top = (topPadding ? topPadding : globalPadding) * zoom;
      const left = (leftPadding ? leftPadding : globalPadding) * zoom;
      const right = (rightPadding ? rightPadding : globalPadding) * zoom;
      const bottom = (bottomPadding ? bottomPadding : globalPadding) * zoom;
      const width = Math.max((this.props.element.get('width', 0) * zoom) - right - left, 0);
      const height = Math.max((this.props.element.get('height', 0) * zoom) - top - bottom, 0);
      return {
        padding: {
          top,
          left,
          width,
          height,
          position: 'absolute',
          overflow: 'hidden'
        },
        width,
        height,
      };
    } else {
      const width = this.props.element.get('width', 0) * zoom;
      const height = this.props.element.get('height', 0) * zoom;
      return {
        padding: {
          top: 0,
          left: 0,
          width,
          height,
          position: 'absolute',
          overflow: 'visible',
        },
        width,
        height,
      };
    }
  }

  private getBackgroundColor = (type: string): string | undefined => {
    if (type === ElementTypes.LINE || type === ElementTypes.ELLIPSE || type === ElementTypes.RECTANGLE) {
      //these elements resolve the background by its own
      return undefined;
    } else {
      const mode = resolveAttribute(this.props.model, this.props.element, 'mode');
      const backgroundColor = resolveAttribute(this.props.model, this.props.element, 'backcolor');
      if (mode === 'Opaque') {
        return backgroundColor;
      }
    }
    return undefined;
  }

  protected isResizeForbidden = () => {
    const type = this.props.element.get('type');
    return type === ElementTypes.BREAK;
  }

  public render() {
    const type = this.props.element.get('type');
    const { padding, width, height } = this.getPadding(type);
    const renderedElement = this.renderElement(type, width, height);
    if (renderedElement === null) {
      return null;
    }

    if (!this.props.positionFinder) {
      return null;
    }

    const zoom = (this.props.zoom) ? this.props.zoom : 1;

    let selectionFigure = null;
    let highlightBorder = null;

    const { x, y, renderElement } = this.props.positionFinder.findPosition(this.props.element);

    if (!renderElement) {
      return null;
    }

    if (isNaN(x) || isNaN(y)) {
      console.log('error');
    }

    const fullWidth = this.props.element.get('width', 0) * zoom;
    const fullHeight = this.props.element.get('height', 0) * zoom;
    const top = y * zoom;
    const left = x * zoom;

    const borderInfo = this.getBordersStyle(type, fullWidth, fullHeight, top, left);
    const borderStyle: React.CSSProperties = borderInfo.css;

    const baseAttributes: React.CSSProperties = {
      top: top,
      left: left,
      position: 'absolute',
      width: fullWidth,
      height: fullHeight,
      display: 'flex',
      overflow: 'visible'
    };


    const classes = ["BaseElement"];
    const id = this.props.element.get('id', '');
    const isSelected = this.props.selection.has(id);
    if (isSelected) {
      selectionFigure = (<SelectionFigure forbidResize={this.isResizeForbidden()} position={baseAttributes} id={this.props.id} zoom={1.0} />);
    }

    if ((!isSelected && this.props.element.get('highlighted', false)) || this.props.highlightedAsContainer) {
      highlightBorder = (
        <svg className="BaseElementBorder" width={this.props.element.get('width', 0)} height={this.props.element.get('height', 0)} style={{ ...baseAttributes, zIndex: 1 }}>
          <rect shapeRendering="optimizeSpeed" width="100%" height="100%" strokeWidth={2 / zoom} style={{ fill: 'none', stroke: 'rgba(0, 129, 203, 0.5)' }} />
        </svg>);
    }


    const foregroundColor = resolveAttribute(this.props.model, this.props.element, 'forecolor');
    baseAttributes.color = foregroundColor;
    baseAttributes.background = this.getBackgroundColor(type);

    return (
      <>
        <div className={classes.join(' ')} style={baseAttributes}>
          <div style={padding}>
            {renderedElement}
          </div>
        </div>
        {highlightBorder}
        {selectionFigure}
        <div style={borderStyle} />
      </>
    );
  }


  /**
   * This is the function that actually renders the element. We can consider it like
   * a big figure factory method.
   */
  private renderElement = (type: string, availableWidth: number, availableHeight: number) => {
    if (type === ElementTypes.ELEMENT_GROUP) {
      return null;
    } else if (type === ElementTypes.IMAGE) {
      return (<ImageElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.TEXTFIELD) {
      return (<TextFieldElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.STATICTEXT) {
      return (<StaticTextElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.LINE) {
      return (<LineElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.RECTANGLE) {
      return (<RectangleElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.ELLIPSE) {
      return (<EllipseElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.FRAME) {
      return (<FrameElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.BARCODE) {
      return (<BarcodeElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.SUBREPORT) {
      return (<SubreportElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.CVC_ELEMENT) {
      return (<CVCElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.GENERIC_ELEMENT) {
      return (<GenericElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.HTML5CHART_JR) {
      return (<HighChartElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.FUSIONCHART) {
      return (<FusionChartElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.FUSION_WIDGET) {
      return (<FusionWidgetElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.JFREECHART) {
      return (<ChartElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.COMPONENT_ELEMENT_GENERIC) {
      return (<DummyElement elementType={this.props.element.get('componentName')} {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.AD_HOC_COMPONENT) {
      return (<AdHocElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === TableTypes.TABLE_CELL_NAME || type === TableTypes.TABLE_GROUP_CELL_NAME) {
      return (<FrameElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.MAP || type === ElementTypes.TIBCO_MAP || type === ElementTypes.FUSION_MAP) {
      return (<MapElement {...this.props} type={type} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === ElementTypes.BREAK){
      return (<BreakElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === CrosstabTypes.CROSSTAB_ROW_HEADER_NAME || type === CrosstabTypes.CROSSTAB_COLUMN_HEADER_NAME ||
      type === CrosstabTypes.CROSSTAB_TOTAL_ROW_HEADER_NAME || type === CrosstabTypes.CROSSTAB_TOTAL_COLUMN_HEADER_NAME ||
      type === CrosstabTypes.CROSSTAB_CELL_NAME || type === CrosstabTypes.CROSSTAB_TITLE_CELL_NAME ||
      type === CrosstabTypes.CROSSTAB_HEADER_CELL_NAME || type === CrosstabTypes.CROSSTAB_HEADER_NAME) {
      return (<FrameElement {...this.props} availableHeight={availableHeight} availableWidth={availableWidth} />);
    } else if (type === TableTypes.TABLE_HEADER_NAME || type === TableTypes.TABLE_COLUMN_HEADER_NAME || type === TableTypes.TABLE_DETAIL_NAME ||
      type === TableTypes.TABLE_FOOTER_NAME || type === TableTypes.TABLE_COLUMN_FOOTER_NAME || type === TableTypes.TABLE_GROUP_HEADER_NAME ||
      type === TableTypes.TABLE_GROUP_FOOTER_NAME || type === TableTypes.TABLE_GROUP_COLUMN_NAME) {
      return null;
    } else if (type === CrosstabTypes.CROSSTAB_ROW_GROUP_NAME || type === CrosstabTypes.CROSSTAB_COLUMN_GROUP_NAME) {
      return null;
    }
    return <div title={'' + this.props.element.get('x') + ',' + this.props.element.get('y')}
      style={{ width: '100%', height: '100%', fontSize: 12, textAlign: 'left', background: this.props.element.get('backcolor') }}
    >Element {type}</div>;
  }

}

const mapStateToProps = (state: IState, props: IBaseElement) => {

  return {
    selection: state.getIn(['report', 'selection']),
    element: state.getIn(['report', 'model', 'elements', props.id]),
    model: state.getIn(['report', 'model']),
    modelActions: state.getIn(['report', 'modelActions']),
    highlightedAsContainer: state.getIn(['report', 'highlightedContainer']) === ('elements/' + props.id),
  };
}

// const mapDispatchToProps = dispatch => {
//   return { 
//     selectElement: (id: string, add: boolean) => {
//       dispatch( selectElement(id, add) );
//     },
//   };
// }

export default connect(mapStateToProps)(withPositionFinder(BaseElement));   // ,mapDispatchToProps