/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

/* tslint:disable */

/**
 * Function based on StackOverflow answer here:
 * https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 * 
 */
export const uuidv4 = () => {
  return ( '' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}
