/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { Map } from 'immutable';
import { Reducer } from 'redux';
import { combineReducers } from 'redux-immutable';
import report from './report/reportReducer';

export type IState = Map<string, any>;

export const state : Reducer<{ stateReducer: IState }> | any = combineReducers({
  report,
});
