/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { en } from "./assets/locales/en/messages";
import { it } from "./assets/locales/it/messages";
import { fr } from "./assets/locales/fr/messages";
import { i18n } from "@jss/js-common";

i18n.addResourceBundle('en', 'jrws', en, true);
i18n.addResourceBundle('it', 'jrws', it, true);
i18n.addResourceBundle('fr', 'jrws', fr, true);

//editors
export * from './editor/AbstractTextualEditor';
export * from './editor/TextualEditorView';
export * from './editor/XMLEditor';
export * from './editor/JRXMLEditor';
export * from './editor/TextEditor';
export * from './editor/JSONEditor';
export * from './editor/HTMLEditor';
export * from './editor/CSSEditor';
export * from './editor/TextualEditor';
export * from './editor/config/AceConfigurator';

export * from './customeditors/JRIOContextEditor';
export * from './customeditors/JRIOContextEditorView';
export * from './commandIds';
