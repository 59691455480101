/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StackedPercentColumnChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath id="highcharts-mydye89-1">
          <path fill="none" d="M0 0H719V448H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M97 10H545V729H97z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" d="M97 70.5h448M97 130.5h448M97 190.5h448M97 250.5h448M97 310.5h448M97 369.5h448M97 429.5h448M97 489.5h448M97 549.5h448M97 609.5h448M97 669.5h448M97 729.5h448M97 10.5h448" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M96.5 10v719M186.5 10v719M275.5 10v719M365.5 10v719M454.5 10v719M545.5 10v719" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M97 10H545V729H97z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" stroke="#ccd6eb" d="M97 70.5H87M97 130.5H87M97 190.5H87M97 250.5H87M97 310.5H87M97 370.5H87M97 429.5H87M97 489.5H87M97 549.5H87M97 609.5H87M97 669.5H87M97 729.5H87M97 9.5H87" className="highcharts-tick" />
       <path fill="none" stroke="#ccd6eb" d="M96.5 10v719" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M97 729h448" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-bar-series highcharts-color-0 highcharts-tracker" clipPath="url(#highcharts-mydye89-1)" data-z-index="0.1" transform="matrix(0 -1 -1 0 545 729)">
          <path fill="#7cb5ec" stroke="#fff" d="M674.5 -0.5H703.5V139.5H674.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M614.5 -0.5H643.5V156.5H614.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M554.5 -0.5H583.5V29.5H554.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M434.5 -0.5H463.5V109.5H434.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M254.5 -0.5H283.5V346.5H254.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M194.5 -0.5H223.5V40.5H194.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M74.5 -0.5H103.5V9.5H74.5z" className="highcharts-point highcharts-color-0" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-bar-series highcharts-color-1 highcharts-tracker" clipPath="url(#highcharts-mydye89-1)" data-z-index="0.1" transform="matrix(0 -1 -1 0 545 729)">
          <path fill="#434348" stroke="#fff" d="M674.5 139.5H703.5V320.5H674.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M614.5 156.5H643.5V265.5H614.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M554.5 29.5H583.5V238.5H554.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M494.5 -0.5H523.5V397.5H494.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M434.5 109.5H463.5V448.5H434.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M374.5 -0.5H403.5V117.5H374.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M314.5 -0.5H343.5V403.5H314.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M254.5 346.5H283.5V408.5H254.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M194.5 40.5H223.5V424.5H194.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M134.5 -0.5H163.5V246.5H134.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M74.5 9.5H103.5V174.5H74.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M15.5 -0.5H44.5V448.5H15.5z" className="highcharts-point highcharts-color-1" />
       </g>
       <g className="highcharts-series highcharts-series-2 highcharts-bar-series highcharts-color-2 highcharts-tracker" clipPath="url(#highcharts-mydye89-1)" data-z-index="0.1" transform="matrix(0 -1 -1 0 545 729)">
          <path fill="#90ed7d" stroke="#fff" d="M674.5 320.5H703.5V448.5H674.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M614.5 265.5H643.5V448.5H614.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M554.5 238.5H583.5V448.5H554.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M494.5 397.5H523.5V448.5H494.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M374.5 117.5H403.5V448.5H374.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M314.5 403.5H343.5V448.5H314.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M254.5 408.5H283.5V448.5H254.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M194.5 424.5H223.5V448.5H194.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M134.5 246.5H163.5V448.5H134.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M74.5 174.5H103.5V448.5H74.5z" className="highcharts-point highcharts-color-2" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(178 762)">
       <rect width="199" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-bar-series highcharts-color-0 highcharts-series-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1996</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-bar-series highcharts-color-1 highcharts-series-1" data-z-index="1" transform="translate(75.672 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1997</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-bar-series highcharts-color-2 highcharts-series-2" data-z-index="1" transform="translate(143.344 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1998</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#90ed7d" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="82" y="44">Albuquerque</text>
       <text x="82" y="104">Anchorage</text>
       <text x="82" y="164">Boise</text>
       <text x="82" y="224">Butte</text>
       <text x="82" y="284">Elgin</text>
       <text x="82" y="344">Eugene</text>
       <text x="82" y="404">Kirkland</text>
       <text x="82" y="464">Lander</text>
       <text x="82" y="524">Portland</text>
       <text x="82" y="584">
          <tspan>San Francisco</tspan>
       </text>
       <text x="82" y="644">Seattle</text>
       <text x="82" y="704">
          <tspan>Walla Walla</tspan>
       </text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="middle">
       <text x="97" y="748">0</text>
       <text x="186.6" y="748">20</text>
       <text x="276.2" y="748">40</text>
       <text x="365.8" y="748">60</text>
       <text x="455.4" y="748">80</text>
       <text x="534.563" y="748">100</text>
    </g>
 </svg>)};

export default StackedPercentColumnChartIcon;