/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { OrderedMap } from "immutable";
import * as React from "react";
import { IRepositoryItemDescriptor, ResourceSorting, RESOURCE_TYPE } from "@jss/js-rest-api";
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import FolderOpen from '@material-ui/icons/FolderOpen';
import Folder from '@material-ui/icons/Folder';
import { CircularProgress } from "@material-ui/core";
import '../assets/uxpl/css/ResourcePicker.css';

export interface IFoldersTree {
    nodes: OrderedMap<string, IRepositoryItemDescriptor[]>;  // We use an immutable object to store the elements....
    handleNodeClick: (event: React.ChangeEvent, value: string) => void;
    handleNodeExpandCollapse: (nodeId: string, expanded: boolean) => void;
    updateExpanded: (newExpanded: string[], callback?: () => void) => void;
    defaultPath?: IRepositoryItemDescriptor[],
    selectedFolderUUID?: string,
    rootName: string,
    expanded: string[],
}


const isFolderOrContainer = (descriptor) => {
    return descriptor.type === RESOURCE_TYPE.FOLDER || descriptor.type === RESOURCE_TYPE.CONTAINER;
}

/**
 * Show a folder structure in a tree.
 *
 * @class FileTree
 * @extends {React.Component}
 */
export default class FoldersTree extends React.Component<IFoldersTree> {

    componentDidMount = () => {
        if (this.props.defaultPath){
            const newExpanded = [...this.props.expanded];
            this.props.defaultPath.forEach((path, index) => {
                if (path.type === RESOURCE_TYPE.FOLDER && index < this.props.defaultPath.length - 1){
                    newExpanded.push(path.uuid);
                }
            });
            this.props.updateExpanded(newExpanded);
        }
    }

    public render() {
        return <div className="jrsw-file-browser-scrollable-container"><TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeSelect={this.props.handleNodeClick}
            onNodeToggle={this.onNodeToggle}
            multiSelect={false}
            selected={this.props.selectedFolderUUID}
            expanded={this.props.expanded}
        >
            {this.createTreeItem(this.props.rootName, '/', this.props.expanded.includes('/'))}
        </TreeView></div>
    }

    private onNodeToggle = () => {
        // empty
    }

    private onNodeIconClick = (event: React.MouseEvent<Element, MouseEvent>, nodeId: string) => {
        let nodeIds;
        if (this.props.expanded.includes(nodeId)){
            nodeIds = this.props.expanded.filter((id) => {
                return id !== nodeId;
            });
        } else {
            nodeIds = [...this.props.expanded, nodeId];
        }
        this.props.updateExpanded(nodeIds, () => {
            if (this.props.handleNodeExpandCollapse) {
                this.props.handleNodeExpandCollapse(nodeId, true);
            }
        });
    }

    private createTreeItem = (labelText, id, isExpanded) => {
        return (
            <TreeItem
                key={id}
                nodeId={id}
                label={
                    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                        {isExpanded ? <FolderOpen className="jrws-folder-tree-open-icon" style={{marginRight: 5}}/> : <Folder className="jrws-folder-tree-closed-icon" style={{marginRight: 5}}/>}
                        <Typography variant="body2" className="jrws-folder-tree-label ">
                            {labelText}
                        </Typography>
                    </div>
                }
                onIconClick={(event) => this.onNodeIconClick(event, id)}
            >
                { this.createTree(id) }
            </TreeItem>
        );
    }

    private createPlaceHolder = () => {
        return (
            <TreeItem
                key={'refreshElement'}
                nodeId={'refreshElement'}
                label={
                    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                        <CircularProgress size={10} style={{marginRight: '5'}}/>
                        <Typography variant="body2">
                            {'Loading...'}
                        </Typography>
                    </div>
                }
            />
        );
    }

    private createTree = (parentId = '/'): JSX.Element[] => {
        const root: JSX.Element[] = [];
        const children = this.props.nodes.get(parentId);
        if (children){
            const sorted = children.sort((item1, item2) => {return ResourceSorting.sortByName(item1.name, item2.name, false)})
            sorted.forEach((descriptor: IRepositoryItemDescriptor) => {
                if (isFolderOrContainer(descriptor)) {
                    root.push(this.createTreeItem(descriptor.name, descriptor.uuid, this.props.expanded.includes(descriptor.uuid)));
                }
            });
        } else {
            root.push(this.createPlaceHolder());
        }
        return root;
    }
}