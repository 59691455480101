/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Permission, RepositoryApi } from "./RepositoryApi";

export enum RESOURCE_TYPE {
    FOLDER = 'nt:folder',
    CONTAINER = 'nt:container',
    FILE = 'nt:file'
}
export interface IVersion {
    uuid: string,
    path?: string,
    name?: string,
    version?: string,
    created?: string,
    lastModified?: string,
}
export interface IRepositoryItemDescriptor {
    type: RESOURCE_TYPE,
    mime?: string,

    data?: any,

    uuid?: string,
    name: string,
    realName?: string,
    path?: string,

    description?: string,
    isMainReport?: boolean,

    created?: string,
    lastModified?: string,
    size?: number,

    children?: { [key: string]: IRepositoryItemDescriptor },
    version?: number,
    versions?: IVersion[],

    owner?: string,
    permission?: number, // unix permission like 444
    permissionMask?: number,

    properties?: any
    acl?: any
}

export function getRealName(d: IRepositoryItemDescriptor): string {
    return d.realName !== undefined ? d.realName : d.name;
}

export const isReadOnly = function (d: IRepositoryItemDescriptor): boolean {
    return d && !RepositoryApi.inst().hasPermission(d, Permission.WRITE);
}