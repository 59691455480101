/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import image from '../../../assets/uxpl/img/subreport.png'; 

interface ISubreportElement {
  id: string;
  element?: any;
  zoom?: number;
  availableWidth: number,
  availableHeight: number,
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SubreportElement = (props: ISubreportElement) => {

    // The actual content of the frame is rendered at band level.
    // Essentially a frame is just a logical component with nothing to do...
    return <div style={{ width: '100%', height: '100%'}}>
      <img src={image} style={{width: '100%', height: '100%'}}/>
    </div>;
}

export default SubreportElement;