/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { AxiosError } from 'axios';

export function format(str: string, args?: string[]): string {
    return str ? str.replace(/{(\d+)}/g, (match, index) => ((args && args[index]) || '')) : '';
}
function isAxiosError(error: any): error is AxiosError {
    return (error as AxiosError).isAxiosError !== undefined;
}
export class KVProperty {
    public key: string;
    public value: string
}
export class ErrorDescriptor {
    public errorCode: string;
    public errorUid: string;
    public message: string;
    public parameters?: string[];
    public details: ErrorDescriptor[];
    public properties: KVProperty[];
    public origin: AxiosError<any> | Error;

    public constructor(error: AxiosError<any> | Error, i18n?: (msg: string) => string) {
        this.origin = error;
        if (isAxiosError(error)) {
            this.handleAxiosError(error, i18n);
        } else if (error instanceof Error) {
            this.message = error.message;
            this.errorCode = error.name;
        } else {
            this.handleAxiosError(error);
        }
    }

    private handleAxiosError(error: AxiosError<any>, i18n?: (msg: string) => string) {
        if (error.response) {
            console.log(error.code, error.message, error.response);
        } else {
            console.log(error.toJSON());
        }
        if (error.response?.data && (error.response?.headers['content-type'] === 'application/errorDescriptor+json' || error.response?.headers['content-type'] !== 'text/html')) {
            Object.assign(this, error.response.data);
            if (this.message)
                this.message = i18n ? i18n(this.message) : this.message;
            if (this.errorCode && this.message)
                return;
        }
        let ecode = error.code;
        this.message = i18n ? i18n(error.message) : error.message;

        if (error.response?.status && error.message?.startsWith('Request failed with status code')) {
            ecode = String(error.response.status);
            if (error.response.status === 401) {
                ecode = '';
                this.message = 'Invalid credentials supplied. Could not login.';
            }
        }
        this.errorCode = ecode ? ecode : '';
    }


    public getMessage(): string {
        let msg = format(this.message, this.parameters);
        if (this.errorCode && msg && !msg.endsWith(this.errorCode)) {
            msg = this.errorCode + " : " + msg;
        }
        return msg;
    }

}