/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import axios, { AxiosPromise } from 'axios';
import { IRepositoryAPI, JobResult } from '../repo/IRepositoryAPI';
import { getRealName, IRepositoryItemDescriptor, RESOURCE_TYPE } from '../repo/IRepositoryItemDescriptor';
import { fixMimeType } from '../repo/mimeUtil';
import { Base64 } from 'js-base64';
import { Conf } from '../config/Conf';
import { ResourceFieldREST } from './ResourceFieldREST';
import { ResourceFieldGithub } from './ResourceFieldGithub';
import { ResourceFieldFile } from './ResourceFieldFile';
import { ResourceFieldS3 } from './ResourceFieldS3';
import { isBinary } from '../repo/mimes';
import { ResourceFieldJRS } from '../jrs/ResourceFieldJRS';
import { RepositoryApi } from '../repo/RepositoryApi';
import { ResourceFieldJackrabbit } from './ResourceFieldJackrabbit';


export class RepositoryREST implements IRepositoryAPI {
    private base: string;
    private info?: string;
    private repositoryBaseURL = Conf.get('jrws.url.rest.repository');
    private f = new ResourceFieldREST();
    private userid = '';
    private temp: RepositoryREST;

    constructor(base: string, userid: string, info?: string) {
        switch (base) {
            case 'github': this.f = new ResourceFieldGithub(); break;
            case 's3': this.f = new ResourceFieldS3(); break;
            case 'js': this.f = new ResourceFieldJRS(); break;
            case 'jrws':
            case 'jackrabbit': this.f = new ResourceFieldJackrabbit(); break;
            default:
                if (base.startsWith('file.'))
                    this.f = new ResourceFieldFile();
                else
                    this.f = new ResourceFieldREST();
        }
        this.base = base;
        this.userid = userid;
        this.info = info;
        this.repositoryBaseURL = Conf.get('jrws.url.rest.repository') + '/' + base;
        const cp = Conf.get(`jrws.repository.${base}`);
        if (cp)
            this.repositoryBaseURL = cp;
    }
    public getInfo = (): string | undefined => {
        return this.info;
    }

    public field = (): ResourceFieldREST => {
        return this.f;
    }

    public getBaseURL = (): string => {
        return this.repositoryBaseURL;
    }

    public setBaseURL(baseURL: string, userid: string) {
        this.userid = userid;
        this.repositoryBaseURL = baseURL;
    }
    public tmp(): IRepositoryAPI {
        if (!this.temp) {
            this.temp = new RepositoryREST(this.base, this.userid);
            this.temp.setBaseURL(`${Conf.get('jrws.repository.tmp.url')}/${this.base}-${this.userid}`, this.userid);
        }
        return this.temp;
    }

    public getRoot(): IRepositoryItemDescriptor {
        if (['github', 's3'].some(b => this.base === b))
            return { type: RESOURCE_TYPE.CONTAINER, name: "" };
        return { type: RESOURCE_TYPE.FOLDER, name: "" };
    }

    public logout() {
        axios.get(Conf.get('jrws.url.rest.repository') + '/logout', { headers: { 'Cache-Control': 'no-cache', 'UserID': this.userid } });
    }

    public listWithCancel = (path: string, depth = 1): JobResult => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        let prm;
        if (!path.includes('?')) {
            prm = '?' + 'descriptor=true&depth=' + depth;
        } else {
            prm = '&descriptor=true&depth=' + depth;
        }
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.get(this.repositoryBaseURL + path + prm, { cancelToken: source.token, headers: { 'Cache-Control': 'no-cache', 'UserID': this.userid } }).then(result => {
                let d = result.data.children;
                if (!d) { d = [] }
                for (const c in d) {
                    const item = d[c];
                    if (item) {
                        item.mime = this.field().encode('mime', item.mime, item.type);
                    }
                }
                return result;
            })
        }
    }

    /**
     * list content of a resource
     * @param path - resource path on the server, could be an uuid
     * @param depth - how deep should children should be get
     */
    public list = (path: string, depth = 1): AxiosPromise<any> => {
        return this.listWithCancel(path, depth).promise;
    }

    /**
     * save a resource in the repository
     * @param path - resource path on the server, could be an uuid
     * @param descriptor - ResourceDescriptor
     */
    public save = (path: string, descriptor: IRepositoryItemDescriptor): AxiosPromise<any> => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        if (descriptor.path?.startsWith('//')) {
            descriptor.path = descriptor.path.substring(1);
        }
        const data = descriptor.data;
        return axios.post(this.getBaseURL() + path, descriptor, { headers: { 'UserID': this.userid } })
            .then(result => {
                const d = result.data;
                if (d.data) {
                    d.data = Base64.decode(d.data);
                } else if (data) {
                    d.data = Base64.decode(data);
                }
                d.mime = this.field().encode('mime', d.mime, d.type);
                if (d.path === undefined)
                    d.path = descriptor.path;
                fixMimeType(d, this);
                return result;
            });
    }

    /**
     * move a resource to another path, should be used for renames, uuid will rest the same
     * @param path resource path on the server, could be an uuid
     * @param dest destination path, not an uuid
     */
    public move = (path: string, dest: string): AxiosPromise<any> => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        return axios.put(this.getBaseURL() + path, "", { headers: { 'Content-Location': dest, 'UserID': this.userid } }).then(result => {
            const d = result.data;
            if (d.data) {
                d.data = Base64.decode(d.data);
            }
            d.mime = this.field().encode('mime', d.mime, d.type);
            fixMimeType(d, this);
            return result;
        });
    }

    /**
     * copy a resource to another path, should be used for renames, uuid will be different
     * @param path resource path on the server, could be an uuid
     * @param dest destination path, not an uuid
     */
    public copy = (path: string, dest: string): AxiosPromise<any> => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        return axios.post(this.getBaseURL() + path, "", { headers: { 'Content-Location': dest, 'UserID': this.userid } }).then(result => {
            const d = result.data;
            if (d.data) {
                d.data = Base64.decode(d.data);
            }
            d.mime = this.field().encode('mime', d.mime, d.type);
            fixMimeType(d, this);
            return result;
        });
    }

    /**
     * delete a resource from the repository
     * @param path resource path on the server, could be an uuid
     */
    public delete = (path: string): AxiosPromise<any> => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        return axios.delete(this.getBaseURL() + path, { headers: { 'Cache-Control': 'no-cache', 'UserID': this.userid } });
    }

    /**
     * load a resource from the repository
     * @param path resource path on the server, could be an uuid
     */
    public load = (path: string, metadata = false, withData = false, allPermissions?: boolean): AxiosPromise<IRepositoryItemDescriptor> => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        const depth = allPermissions ? '' : '&depth=1';

        if (metadata) {
            if (!path.includes('?')) {
                path += `?descriptor=true${depth}`;
            } else {
                path += `&descriptor=true${depth}`;
            }
            if (withData) {
                path += '&includedata=true';
            }
            if (allPermissions) {
                path += '&allpermissions=true';
            }
        }
        return axios.get(this.getBaseURL() + path, { headers: { 'Cache-Control': 'no-cache', 'UserID': this.userid } }).then(result => {
            if (withData) {
                const d = result.data;
                if (d.data) {
                    if (isBinary(d)) {
                        d.data = Base64.atob(d.data);
                    } else {
                        d.data = Base64.decode(d.data);
                    }
                }
                fixMimeType(d, this);
                d.mime = this.field().encode('mime', d.mime, d.type);
            }
            return result;
        });
    }

    public getResourcePath(path: string) {
        return `uuid:${path}`;
    }

    /**
     * search repository by one or more fields, all are like()
     * @param params contains an object with search parameters { name by resource name, description by resource description, mime by mime, astModified by last modified date, tags by tags
     */
    public find = (params: { name?: string, description?: string, root?: string, mime?: string[], lastModified?: string, tags?: string[] }): AxiosPromise<any> => {
        return this.findWithCancel(params).promise;
    }

    public findWithCancel = (params: { name?: string, description?: string, root?: string, mime?: string[], lastModified?: string, tags?: string[] }): JobResult => {
        let url = this.getBaseURL() + '?';
        let del = '';
        if (params.name !== undefined && params.name !== "*") {
            url += 'name=' + encodeURIComponent(params.name);
            del = '&';
        }
        if (params.description !== undefined) {
            url += del + 'description=' + encodeURIComponent(params.description);
            del = '&';
        }
        if (params.root !== undefined) {
            url += del + 'root=' + encodeURIComponent(params.root);
            del = '&';
        }
        if (params.mime !== undefined) {
            for (const t of params.mime) {
                url += del + 'mime=' + encodeURIComponent(t);
                del = '&';
            }
        }
        if (params.lastModified !== undefined) {
            url += del + 'lastModified=' + encodeURIComponent(params.lastModified);
            del = '&';
        }
        if (params.tags !== undefined) {
            for (const t of params.tags) {
                url += del + 'tags=' + encodeURIComponent(t);
                del = '&';
            }
        }
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.get(url, { headers: { 'Cache-Control': 'no-cache', 'UserID': this.userid } })
        }
    }


    /**
     * Create a folder, path is not encoded
     * @param path 
     */
    public createFolder = (path: string): AxiosPromise<any> => {
        const descriptor: IRepositoryItemDescriptor = {
            type: RESOURCE_TYPE.FOLDER,
            name: 'Folder'
        };
        return this.save(path, descriptor);
    }

    public getPath(pathParts: IRepositoryItemDescriptor[]): string {
        if (pathParts.length > 0) {
            const last = pathParts[pathParts.length - 1];
            if (last.path) {
                const rname = getRealName(last);
                const p = last.path + (last.path.endsWith('/') ? '' : '/') + (rname ? rname : '');
                let prefix = '';
                const b = RepositoryApi.inst().getBase();
                for (let i = 0; i < pathParts.length; i++) {
                    const pp = pathParts[i];
                    if (pp === last || pp.name === '')
                        continue;
                    if (pp.type === 'nt:container') {
                        if (b === 'jackrabbit') {
                            prefix += '/' + pp.name;
                        } else {
                            if (pp.uuid === '/' || pp.name === '/')
                                continue;
                            prefix += i === 1 && pp.uuid !== pp.name ? '/' + pp.uuid : '/' + pp.name;
                        }
                    } else break;
                }
                return prefix + p;
            }
            if (last.uuid)
                return last.uuid;
        }
        return '/';
        // let path: string | undefined;
        // if (pathParts.length > 0) {
        //     path = pathParts[pathParts.length - 1].path;
        //     if (!path) {
        //         path = pathParts[pathParts.length - 1].uuid;
        //     }
        // }
        // if (!path) {
        //     path = '';
        //     let del = '';
        //     pathParts.forEach(item => {
        //         if (item.name) {
        //             path += del + item.name;
        //         }
        //         del = '/';
        //     });
        // }
        // return path;
    }
}
