/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IConfigStore } from "./IConfigStore";
import { Pref } from "./Conf";
import { Lang } from './Lang';
import { MIME_TYPES } from "../repo/mimes";

export class StaticConfigStore implements IConfigStore {
	private cfg: any;

	public setCfg(cfg: any) {
		this.cfg = cfg;
	}

	public init(cfg: any) {
		this.cfg = cfg;
		Lang.init(cfg);

		cfg['jrws.user.theme.prefix'] = '';
		cfg[Pref.THEME] = 'light';
		cfg[Pref.THEME_AVAILABLE] = ['light', 'dark'];

		cfg['jrws.user.timezone'] = '';

		cfg['jrws.url.rest.repository'] = '/repo';
		cfg['jrws.url.rest.datasource'] = '/jrio/rest_v2';
		cfg['jrws.url.rest.jrio'] = '/jrio';
		cfg['jrws.url.rest.jrio.client'] = '/jrio/client';
		cfg['jrws.url'] = '/';
		cfg['jrws.hide.login.buttons'] = 'false';
		cfg['.url'] = '/repo/tmp';
		// cfg['jrws.url.rest.login.gdrive'] = '/repo/gdrive-login';
		// cfg['jrws.url.rest.login.github'] = '/repo/github-login';

		cfg['jrws.repo.file.upload.limit'] = '200';
		cfg['jrws.data.adapter.hide.creation'] = [MIME_TYPES.DATA_ADAPTER_PROVIDER.toString(),
		MIME_TYPES.DATA_ADAPTER_DATA_SOURCE.toString(),
		MIME_TYPES.DATA_ADAPTER_BEAN.toString(),
		MIME_TYPES.DATA_ADAPTER_EJBQL.toString(),
		MIME_TYPES.DATA_ADAPTER_SPRING_HIBERNATE.toString(),
		MIME_TYPES.DATA_ADAPTER_HIBERNATE.toString(),
		MIME_TYPES.DATA_ADAPTER_XMLA.toString()]

		cfg[Pref.EDITOR_JRXML_PROPS_SHOWDEPRECATED] = false;
		cfg['version'] = '2.0.0'
		cfg['jrws.jrio.serverInfo'] = {
			"version": cfg['version'],
			"buildTime": "2023-03-08 23:59:59",
			"buildRevision": "0",
			"edition": "Professional",
			"licenseType": "Evaluation",
			"expirationDate": "2023-03-08 23:59:59",
			"environmentType": "Unknown",
			"concurrentReportExecutions": "2"
		}
	}

	public set(key: string, value: any): boolean {
		this.cfg[key] = value;
		return false;
	}
}