/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

export abstract class APDescriptor {
    type: string;
    id: string;
    path?: string;
    inheritedPropertyId?: string;
    layouts?: APDescriptor[];
    readonly?: boolean = false;

    label?: string;
    description?: string;
    help?: string;
    icon?: any;

    default?: any;
    deprecated?: boolean = false;
    custom?: boolean = false;
    sinceVersion?: string;

    style?: any;
    className?: string;
}

export class SubtypedElement extends APDescriptor{
    subtypes: APDescriptor[]
}