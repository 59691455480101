/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../../ui/APDescriptor";
import { MHyperlinks } from "../../../common/MHyperlinks";
import { PExpression } from "../../../../ui/primitive/UPExpression";
import { PConnectedTable } from "../../../../ui/tables/UPConnectedTable";
import { PTableElement } from "../../../../ui/tables/UPTableElement";

export const FXYZSeries = {
    xValueExpression: new PExpression({ id: 'xValueExpression', label: 'X Value Expression' }),
    yValueExpression: new PExpression({ id: 'yValueExpression', label: 'Y Value Expression' }),
    zValueExpression: new PExpression({ id: 'zValueExpression', label: 'Z Value Expression' }),
    seriesExpression: new PExpression({ id: 'seriesExpression', label: 'Series Expression' })
};


export const MXYZSeries: APDescriptor = new PConnectedTable({
    id: 'xyzSeries', label: 'Series', rowLabel: 'Series', rowLabelPaths:[FXYZSeries.seriesExpression.id],
    layouts: [
        new PTableElement({
            label: 'XYZ Series',
            anchorOrigin: {vertical: 'center', horizontal: 'right'},
            transformOrigin: {vertical: 'center', horizontal: 'right'},
            layouts: [
                FXYZSeries.seriesExpression, FXYZSeries.xValueExpression, FXYZSeries.yValueExpression, FXYZSeries.zValueExpression,
                { ...MHyperlinks, id: 'itemHyperlink', label: 'Item Hyperlink' }]
        })]
});