/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { PColor } from "../../../ui/../ui/primitive/UPColor";
import { PInt } from "../../../ui/primitive/UPInteger";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { setFonts } from "../../common/MFont";
import { SpiderRotationEnum } from "../../enums/SpiderRotationEnum";
import { TableOrderEnum } from "../../enums/TableOrderEnum";

export const FSpiderPlot = {
    backcolor: new PColor({ id: 'backcolor', label: 'Backcolor' }),
    backgroundAlpha: new PInt({ id: 'backgroundAlpha', label: 'Background Alpha', min: 0, max: 255 }),
    foregroundAlpha: new PInt({ id: 'foregroundAlpha', label: 'Foreground Alpha', min: 0, max: 255 }),
    axisLineColor: new PColor({ id: 'axisLineColor', label: 'Axis Line Color' }),
    axisLineWidth: new PInt({ id: 'axisLineWidth', label: 'Axis Line Width' }),

    labelColor: new PColor({ id: 'labelColor', label: 'Label Color' }),
    labelGap: new PInt({ id: 'labelGap', label: 'Label Gap' }),

    maxValueExpression: new PExpression({ id: 'maxValueExpression', label: 'Max Value' }),
    webFilled: new PBoolean({ id: 'webFilled', label: 'Web Filled', canBeNull: true }),

    startAngle: new PInt({ id: 'startAngle', label: 'Start Angle' }),
    headPercent: new PInt({ id: 'headPercent', label: 'Head Percent' }),
    interiorGap: new PInt({ id: 'interiorGap', label: 'Interior Gap' }),

    rotation: SpiderRotationEnum,
    tableOrder: TableOrderEnum
};

export const MSpiderPlot: APDescriptor = {
    type: 'MSpiderPlot',
    path: 'spiderPlot',
    id: 'spiderPlotProeprtiesDescriptor',
    layouts: [FSpiderPlot.backcolor, FSpiderPlot.backgroundAlpha, FSpiderPlot.foregroundAlpha,
    FSpiderPlot.axisLineColor, FSpiderPlot.axisLineWidth,
    FSpiderPlot.maxValueExpression,
    FSpiderPlot.startAngle, FSpiderPlot.headPercent, FSpiderPlot.interiorGap, FSpiderPlot.rotation, FSpiderPlot.tableOrder,
    new PAccordeon({
        id: 'chart.label', label: 'Label',
        layouts: [FSpiderPlot.labelColor, FSpiderPlot.labelGap, ...setFonts('labelFont')]
    })
    ]
};
