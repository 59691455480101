/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { APDescriptor } from '../../ui/APDescriptor';
import { reg } from '../../ui/UiProperty';

import UPDynProperties from './UPDynProperties';



const TYPEID = 'BConnectedDynProperties';
reg(TYPEID, (mc) => { return <UPDynProperties mcontext={mc} />; });


export class PConnectedDynProperties extends APDescriptor {

    propspath: string;
    propsname?: string;

    public constructor(init: Partial<PConnectedDynProperties>) {
        super();
        Object.assign(this, { ...init, type: TYPEID, custom: true });
    }
}
