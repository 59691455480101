/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import axios from 'axios';
import { error } from '../MessageInfo';
import { BLocalConfigStore } from './BLocalConfigStore';
import { CloudConfigStore } from './CloudConfigStore';
import { IConfigStore, IConfigStoreListener } from "./IConfigStore";
import { StaticConfigStore } from './StaticConfigStore';
import { UiServerConfigStore } from './UiServerConfigStore';

const NOCONFIG = process.env.NOCONFIG;

export enum Pref {
    LOCALES = 'jrws.user.locales',
    LOCALE = 'jrws.user.locale',
    LOCALES_AVAILABLE = 'jrws.available.locales',

    THEME = 'jrws.user.theme',
    THEME_AVAILABLE = 'jrws.available.themes',

    EDITOR_JRXML_PROPS_SHOWDEPRECATED = 'editor.jrxml.props.showDeprecated',
    EDITOR_JRXML_PROPS_SHOWCUSTOMPROPERTIES = 'editor.jrxml.props.show.custom.properties',

    CURRENT_USER = 'current.user'
}

export class Conf {
    private static instance: Conf = new Conf();
    private cfg: any = {};
    private stores: IConfigStore[] = [];
    private listeners: IConfigStoreListener[] = [];
    private staticConfigStore = new StaticConfigStore();

    private constructor() {
        this.stores.push(this.staticConfigStore);
        this.stores.push(new CloudConfigStore());
        this.stores.push(new BLocalConfigStore());
        if (!NOCONFIG) {
            this.stores.push(new UiServerConfigStore())
        }
        this.init();
        window.onbeforeunload = function() {
            sessionStorage.setItem("origin", window.location.href);
        }
        window.onload = function() {
            if (window.location.pathname === Conf.get('jrws.url') &&  window.location.href == sessionStorage.getItem("origin")) {
                sessionStorage.removeItem("origin")
                sessionStorage.removeItem('jrws.config');
            }
        }
    }

    private init() {
        const v = sessionStorage.getItem('jrws.config');
        if (v) {
            const conf = JSON.parse(v);
            this.cfg = conf;
            this.staticConfigStore.setCfg(this.cfg);
        } else
            this.stores.forEach(s => { s.init(this.cfg, this.fireRefresh); });
        this.save();
    }
    public save() {
        sessionStorage.setItem('jrws.config', JSON.stringify(this.cfg));
    }

    public fireRefresh = (): void => {
        this.listeners.forEach(l => l.refresh());
    }

    public static addListener(listener: IConfigStoreListener) {
        Conf.instance.listeners.push(listener);
    }

    public static removeListener(listener: IConfigStoreListener) {
        const indx = Conf.instance.listeners.indexOf(listener);
        if (indx != -1) {
            Conf.instance.listeners.splice(indx, 1);
        }
    }

    public static reset() {
        sessionStorage.removeItem('jrws.config');
        Conf.instance.cfg = {};
        Conf.instance.init();
        // notify listeners? should read everytime they show something
    }

    public static get(key: string) {
        return Conf.instance.cfg[key];
    }
    public static getPrefix(prefix: string): string[] {
        return Object.entries(Conf.instance.cfg).filter(([key]) => key.startsWith(prefix)).map(([key]) => key);
    }

    public static getLocale() {
        const langs = Conf.get(Pref.LOCALES_AVAILABLE);
        let locale = Conf.get(Pref.LOCALE);
        if (locale) {
            if (langs.includes(locale)) {
                locale = locale;
            }
        }
        if (!locale) {
            locale = "en";
        }
        return locale;
    }
    public static set(key: string, value: any) {
        Conf.instance.stores.some(v => v.set(key, value));
        Conf.instance.save();
        // notify listeners that property changed? should read everytime they show something
    }

    private static authFailCounter = 0;

    public static initAxios(doLogin: (msg: string) => boolean) {
        axios.interceptors.response.use((response) => {
            if (this.isProtectedUrl(response.config.url ? response.config.url : '')) {
                Conf.authFailCounter = 0;
            }
            return response;
        }, (e) => {
            if (e.response && this.isProtectedUrl(e.config.url) && ([401].includes(e.response.status))) {
                console.log(Conf.authFailCounter);
                Conf.authFailCounter++;
                if (Conf.authFailCounter < 3 && doLogin(error(e).getMessage())) {
                    return axios.request(e.config);
                }
            }
            return Promise.reject(e);
        });
    }

    public static isProtectedUrl(url: string): boolean {
        const wl = ['/rest_v2/login', '/rest_v2/users/'];
        const end = ['-login', '-oauth2callback'];
        return !(wl.some(value => url.includes(value)) || end.some(value => url.endsWith(value)));
    }


}