/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { MDatasetRun } from "../../dataset/MDatasetRun";
import { IncrementTypeEnum } from "../../enums/IncrementTypeEnum";
import { ResetTypeEnum } from "../../enums/ResetTypeEnum";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { PChartDataset } from "../UPChartDataset";

export const FChartDataset = {
    incrementType: IncrementTypeEnum,
    resetType: ResetTypeEnum,
    incrementWhenExpression: new PExpression({ id: 'incrementWhenExpression', label: 'Increment When' })
};

export const MChartDataset: APDescriptor = {
    type: 'MChartDataset', id: 'chart.dataset',
    layouts: [FChartDataset.incrementType, FChartDataset.incrementWhenExpression, FChartDataset.resetType,
    new PChartDataset({ id: 'CHART_TYPE', label: 'Dataset Type' }),
        MDatasetRun]
};

