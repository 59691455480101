/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { XmlDataAdapterEditor } from './XmlDataAdapterEditor';
import { IDataAdapterConfig, IDataAdapterDescriptor, createNode, setFileDataAdapterAttributes, getChilNodeByName, getProlog } from '../IDataAdapterDescriptor';
import format from 'xml-formatter';
import { MIME_TYPES } from '@jss/js-rest-api';
import { readXML } from '../HTTPConfigurationPanel';
import { nameValidator, urlValidator } from '@jss/js-common/src/utils/validators';
import { getDataAdapterNode } from '../../editor/Utils';


export default class XmlDataAdapterDescriptor implements IDataAdapterDescriptor {

  public getMime = () => {
    return MIME_TYPES.DATA_ADAPTER_XML;
  }

  public getIcon = () => {
    return <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 92 27"
      fill="#fff"
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <use xlinkHref="#xmlIconA" x={0.5} y={0.5} />
      <symbol id="xmlIconA" overflow="visible">
        <path
          d="M10.239 12.05.913 0h10.24l4.286 7 4.207-7h9.683l-9.088 12.122 10.12 13.637H19.842L15 18.075l-5 7.684H0zM32.009 0h12.422l4.048 15.153h.08L52.607 0h12.42v25.76h-8.254V9.236h-.08l-4.92 16.524h-6.508l-4.92-16.524h-.08V25.76H32.01zm37.633 0h8.73v19.157h12.54v6.602h-21.27z"
          stroke="none"
          fill="#000"
          fillRule="nonzero"
        />
      </symbol>
    </svg>
  }

  /**
   *  Return the name of this data adapter to be presented to the user
   *  i.e. XML File
   */
  public getDataAdapterLabel = () => {
    return 'datasource.xml.label';
  }

  /**
  *  Return the name of this data adapter to be presented to the user
  *  i.e. CSV File
  */
  public getDataAdapterDescription = () => {
    return 'datasource.xml.description';
  }


  /**
   *  Return the canonical name of the class which impements this data adapter in Java.
   *  net.sf.jasperreports.data.csv.CsvDataAdapter
   */
  public getDataAdapterClass = () => {
    return 'net.sf.jasperreports.data.xml.XmlDataAdapterImpl'
  }

  public getDataAdapterRootName = () => {
    return 'xmlDataAdapter';
  }

  /**
   * Return a plain json object with the default data adapter configuration.
   * It can be just an empty object.
   *
   * @memberof IDataAdapter
   */
  public initializeDataAdapterConfig = () => {
    return {
      class: this.getDataAdapterClass(),
      name: 'New XML Data Adapter',
      location: '',
      useConnection: false,
      namespaceAware: false,
      datePattern: undefined,
      numberPattern: undefined,
      selectExpression: '',
      locale: undefined,
      timeZone: undefined,
    }
  };

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public validateDataAdapterConfig = (config: IDataAdapterConfig) => {

    // Check for errors...
    const errors = [];

    const nameError = nameValidator(config.name);
    if (nameError) {
      errors.push(nameError);
    }

    const urlError = urlValidator(config.location);
    if (urlError) {
      errors.push(urlError);
    }

    if (errors.length > 0) {
      return errors.join("\n");
    }

    return undefined;

  }

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public createEditor = (config: IDataAdapterConfig, onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => {
    return <XmlDataAdapterEditor {...config} onDataAdapterSettingsChange={onDataAdapterSettingsChange} readOnly={readOnly}/>
  }

  public getLanguages = () => {
    return ['text'];
  }

  public toXml = (config: IDataAdapterConfig) => {
    const xmlDoc = document.implementation.createDocument(null, this.getDataAdapterRootName());
    const root = xmlDoc.getElementsByTagName(this.getDataAdapterRootName())[0];
    root.setAttribute('class', this.getDataAdapterClass());

    const nameNode = createNode(xmlDoc, config, 'name', 'name');
    if (nameNode) {
      root.appendChild(nameNode);
    }

    setFileDataAdapterAttributes(xmlDoc, config, root);

    const useConnectionNode = createNode(xmlDoc, config, 'useConnection', 'useConnection');
    if (useConnectionNode) {
      root.appendChild(useConnectionNode);
    }

    const namespaceAwareNode = createNode(xmlDoc, config, 'namespaceAware', 'namespaceAware');
    if (namespaceAwareNode) {
      root.appendChild(namespaceAwareNode);
    }

    const selectExpressionNode = createNode(xmlDoc, config, 'selectExpression', 'selectExpression');
    if (selectExpressionNode) {
      root.appendChild(selectExpressionNode);
    }

    const numberPatternNode = createNode(xmlDoc, config, 'numberPattern', 'numberPattern');
    if (numberPatternNode) {
      root.appendChild(numberPatternNode);
    }

    const datePatternNode = createNode(xmlDoc, config, 'datePattern', 'datePattern');
    if (datePatternNode) {
      root.appendChild(datePatternNode);
    }

    const timezoneNode = createNode(xmlDoc, config, 'timeZone', 'timeZone');
    if (timezoneNode) {
      root.appendChild(timezoneNode);
    }

    const localeNode = createNode(xmlDoc, config, 'locale', 'locale');
    if (localeNode) {
      root.appendChild(localeNode);
    }

    const prolog = getProlog();
    const xml = prolog + (new XMLSerializer().serializeToString(xmlDoc));
    return format(xml, { indentation: '  ', collapseContent: true });
  }

  public toJson = (xml: Document) => {
    let result: any = {
      class: this.getDataAdapterClass(),
      name: '',
      location: '',
      useConnection: false,
      namespaceAware: false,
      datePattern: undefined,
      numberPattern: undefined,
      selectExpression: '',
      locale: undefined,
      timeZone: undefined,
    }

    getDataAdapterNode(xml.getRootNode()).childNodes.forEach((childNode) => {
      const nodeName = childNode.nodeName;
      if (nodeName === 'name') {
        result.name = childNode.textContent;
      } else if (nodeName === 'dataFile') {
        const locationNode = getChilNodeByName(childNode, 'location');
        if (!locationNode) {
          const urlNode = getChilNodeByName(childNode, 'url');
          const url = urlNode.textContent;
          result.location = url;
        } else {
          result.location = locationNode.textContent;
        }
        result = {
          ...result,
          ...readXML(childNode as Element),
        }
      } else if (nodeName === 'fileName') {
        result.location = childNode.textContent;
      } else if (nodeName === 'selectExpression') {
        result.selectExpression = childNode.textContent;
      } else if (nodeName === 'useConnection') {
        result.useConnection = (childNode.textContent === 'true');
      } else if (nodeName === 'locale') {
        result.locale = childNode.textContent;
      } else if (nodeName === 'numberPattern') {
        result.numberPattern = childNode.textContent;
      } else if (nodeName === 'datePattern') {
        result.datePattern = childNode.textContent;
      } else if (nodeName === 'timeZone') {
        result.timeZone = childNode.textContent;
      } else if (nodeName === 'namespaceAware') {
        result.namespaceAware = (childNode.textContent === 'true');
      }
    });
    return result;
  }
}