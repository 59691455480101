/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { APDescriptor } from "../../ui/APDescriptor";
import { RotationEnum } from "../enums/RotationEnum";
import { PInt } from "../../ui/primitive/UPInteger";
import { MColor, MColorStyle } from "./MColor";
import { FLinePen, getLinePen, getLinePenStyle } from "./MLinePen";
import { PStyles } from "../styles/UPStyles";

export const FAppearance = {
    rotation: RotationEnum,

    padding: new PInt({ id: 'padding', label: 'Padding', min: 0, className: 'span2', inheritedPropertyId: 'box.padding' }),
    leftPadding: new PInt({ id: 'leftPadding', label: 'Left', min: 0, /* className: 'labelAlignRight', */ inheritedPropertyId: 'box.leftPadding' }),
    rightPadding: new PInt({ id: 'rightPadding', label: 'Right', min: 0, /* className: 'labelAlignRight', */ inheritedPropertyId: 'box.rightPadding' }),
    topPadding: new PInt({ id: 'topPadding', label: 'Top', min: 0, /* className: 'labelAlignRight', */ inheritedPropertyId: 'box.topPadding' }),
    bottomPadding: new PInt({ id: 'bottomPadding', label: 'Bottom', min: 0, /* className: 'labelAlignRight', */ inheritedPropertyId: 'box.bottomPadding' }),
    style: new PStyles({ id: 'style', label: 'Style' })
};
export const MBorders = new PAccordeon({
    id: 'element.borders', label: 'Borders', path: 'box', expanded: false,
    layouts: [
        { ...FLinePen.lineColor, path: 'pen', inheritedPropertyId: 'box.pen.' + FLinePen.lineColor.id },
        { ...FLinePen.lineStyle, path: 'pen', inheritedPropertyId: 'box.pen.' + FLinePen.lineStyle.id },
        { ...FLinePen.lineWidth, path: 'pen', inheritedPropertyId: 'box.pen.' + FLinePen.lineWidth.id },
        getLinePen('top.pen', 'Top', 'topPen', false, 'box.topPen'),
        getLinePen('bottom.pen', 'Bottom', 'bottomPen', false, 'box.bottomPen'),
        getLinePen('left.pen', 'Left', 'leftPen', false, 'box.leftPen'),
        getLinePen('right.pen', 'Right', 'rightPen', false, 'box.rightPen'),
    ]
});
export const MBordersStyles = new PAccordeon({
    id: 'element.borders.styles', label: 'Borders', path: 'box', expanded: false,
    layouts: [
        { ...FLinePen.lineColor, path: 'pen', inheritedPropertyId: 'box.pen.' + FLinePen.lineColor.id, default: undefined },
        { ...FLinePen.lineStyle, path: 'pen', inheritedPropertyId: 'box.pen.' + FLinePen.lineStyle.id, default: undefined },
        { ...FLinePen.lineWidth, path: 'pen', inheritedPropertyId: 'box.pen.' + FLinePen.lineWidth.id, default: undefined },
        getLinePenStyle('top.pen', 'Top', 'topPen', false, 'box.topPen'),
        getLinePenStyle('bottom.pen', 'Bottom', 'bottomPen', false, 'box.bottomPen'),
        getLinePenStyle('left.pen', 'Left', 'leftPen', false, 'box.leftPen'),
        getLinePenStyle('right.pen', 'Right', 'rightPen', false, 'box.rightPen'),
    ]
});
export const MAppearance: APDescriptor = {
    type: 'MAppearance',
    id: 'appearanceProperties',
    layouts: [FAppearance.style, FAppearance.rotation,
    ...MColor.layouts,
    new PAccordeon({
        id: 'element.paddings', label: 'Paddings', path: 'box',
        // style: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: 10 },
        layouts: [FAppearance.padding, FAppearance.leftPadding, FAppearance.rightPadding, FAppearance.topPadding, FAppearance.bottomPadding]
    }),
        MBorders],
};

export const MAppearanceStyle: APDescriptor = {
    id: 'mstyleappearance',
    type: 'MAppearance',
    layouts: [  { ...FAppearance.rotation, default: undefined },
    ...MColorStyle.layouts,
    new PAccordeon({
        id: 'element.paddings', label: 'Paddings', path: 'box',
        // style: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: 10 },
        layouts: [FAppearance.padding, FAppearance.leftPadding, FAppearance.rightPadding, FAppearance.topPadding, FAppearance.bottomPadding]
    }),
        MBordersStyles],
};