/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { defaultReportState } from "../reportReducer";
import { Map } from 'immutable';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const refactorFusion = (state = defaultReportState, action: any, newState: Map<string, any> | null): Map<string, any> | null => {
    //console.log('ok, this is fusion ')
    if (action.properties.widgetType !== undefined) {
        //console.log(`haha, we are changing the chart type`);
        //const old = state.getIn([...action.path, 'widgetType']);
        //const newValue = action.properties.widgetType;
        //console.log('old value: ', old);
        //console.log('new value: ', newValue);
    }
    return newState;
};