/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const Bar3DChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="802"
      height="493"
      version="1.1"
      style={{
         WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
         WebkitUserSelect: "none",
         MozUserSelect: "none",
         userSelect: "none",
         position: "relative",
      }}
      cursor="default"
      overflow="hidden"
      viewBox="0 0 802 493"
      {...props}
   >
      <defs>
         <linearGradient
            id="19-329.0909881288068-rgba_187_192_181_1_-rgba_236_240_232_1_"
            x1="0"
            x2="1"
            y1="0"
            y2="0.599"
         >
            <stop offset="0%" stopColor="#bbc0b5"></stop>
            <stop offset="100%" stopColor="#ecf0e8"></stop>
         </linearGradient>
         <linearGradient
            id="19-270-rgb_209.35_217.6_198.9_-rgb_111.8_121.55_99.45_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#d1d9c6"></stop>
            <stop offset="100%" stopColor="#6f7963"></stop>
         </linearGradient>
         <linearGradient
            id="19-270-rgb_129_140.25_114.75_-rgb_60.199999999999996_65.45_53.55_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#818c72"></stop>
            <stop offset="100%" stopColor="#3c4135"></stop>
         </linearGradient>
         <linearGradient
            id="19-65.55604521958347-rgb_134.16_145.86_119.34_-rgb_236.74_240.04_232.56_"
            x1="0"
            x2="0.455"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#869177"></stop>
            <stop offset="100%" stopColor="#ecf0e8"></stop>
         </linearGradient>
         <linearGradient
            id="19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#d3e9fb"></stop>
            <stop offset="100%" stopColor="#718ca1"></stop>
         </linearGradient>
         <linearGradient
            id="19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#83a2ba"></stop>
            <stop offset="100%" stopColor="#3d4b56"></stop>
         </linearGradient>
         <filter
            id="99AEA3F9-25DB-4DAB-92CC-46239C76C4B4"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <filter
            id="68961901-76B7-46F7-A019-E6D826540C31"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <linearGradient
            id="19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#dcdcdc" stopOpacity="0"></stop>
            <stop offset="100%" stopColor="#7c7c7c" stopOpacity="0"></stop>
         </linearGradient>
         <linearGradient
            id="19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#909090" stopOpacity="0"></stop>
            <stop offset="100%" stopColor="#434343" stopOpacity="0"></stop>
         </linearGradient>
         <linearGradient
            id="19-46.59779431802667-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
            x1="0"
            x2="0.946"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
            <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
         </linearGradient>
         <filter
            id="4835D3A8-A86B-41C5-8309-8DE488A91DC2"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <filter
            id="66966797-7EA6-4500-B5B7-392109EB4C41"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <linearGradient
            id="19-48.829346920871295-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
            x1="0"
            x2="0.875"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
            <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
         </linearGradient>
         <filter
            id="02AE9406-B598-44B9-A7A0-5B4A67A6111A"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <filter
            id="44918680-5C42-407C-97BA-2EF7D21C9EA9"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <linearGradient
            id="19-45.52546588834422-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
            x1="0"
            x2="0.982"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
            <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
         </linearGradient>
         <filter
            id="E02D98B4-4D8A-45B6-BE0C-B474D72941FA"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <filter
            id="3A1CAFCE-AC6F-42DE-9486-369C63D5F7ED"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <linearGradient
            id="19-49.98916558622196-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
            x1="0"
            x2="0.839"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
            <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
         </linearGradient>
         <filter
            id="D3477A30-05AC-44F1-9101-6E28447118BD"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <filter
            id="9CC4EA86-F257-43C2-BF0F-A1D0E2D78A0F"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <linearGradient
            id="19-45.89896560290124-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
            x1="0"
            x2="0.969"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
            <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
         </linearGradient>
         <filter
            id="7F157F22-9413-4E18-A661-843001C36EA3"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <filter
            id="27F5FA98-71F5-44D2-9FA8-174BC30F38B5"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <linearGradient
            id="19-45.581191056578504-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
            x1="0"
            x2="0.98"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
            <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
         </linearGradient>
         <filter
            id="4CC179B8-63D7-40F7-89CB-CCA73047C435"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <filter
            id="3766E648-9814-4756-BB9E-B545AF14698F"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <linearGradient
            id="19-45.85236260830118-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
            x1="0"
            x2="0.971"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
            <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
         </linearGradient>
         <filter
            id="57397723-26BC-457D-92AB-10C1F13A2DA0"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <filter
            id="834D4703-62D7-4CF0-B613-231A2BB6E38F"
            width="200%"
            height="200%"
         >
            <feOffset
               dx="1"
               dy="-1"
               in="SourceGraphic"
               result="offOut"
            ></feOffset>
            <feColorMatrix
               in="offOut"
               result="matrixOut"
               values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
            ></feColorMatrix>
            <feGaussianBlur
               in="matrixOut"
               result="blurOut"
               stdDeviation="1.732"
            ></feGaussianBlur>
            <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
         </filter>
         <linearGradient
            id="19-46.769737595688554-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
            x1="0"
            x2="0.94"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
            <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
         </linearGradient>
         <linearGradient
            id="19-360-rgba_175_216_248_1__0-rgba_175_216_248_1__30-rgba_223_240_252_1__60-rgba_175_216_248_1__90-rgba_175_216_248_1__100"
            x1="0"
            x2="1"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#afd8f8"></stop>
            <stop offset="30%" stopColor="#afd8f8"></stop>
            <stop offset="60%" stopColor="#dff0fc"></stop>
            <stop offset="90%" stopColor="#afd8f8"></stop>
            <stop offset="100%" stopColor="#afd8f8"></stop>
         </linearGradient>
         <linearGradient
            id="19-46.59779431802667-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
            x1="0"
            x2="0.946"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#88a8c1"></stop>
            <stop offset="100%" stopColor="#edf6fd"></stop>
         </linearGradient>
         <linearGradient
            id="19-48.829346920871295-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
            x1="0"
            x2="0.875"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#88a8c1"></stop>
            <stop offset="100%" stopColor="#edf6fd"></stop>
         </linearGradient>
         <linearGradient
            id="19-45.526509190545994-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
            x1="0"
            x2="0.982"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#88a8c1"></stop>
            <stop offset="100%" stopColor="#edf6fd"></stop>
         </linearGradient>
         <linearGradient
            id="19-50.025675242316765-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
            x1="0"
            x2="0.838"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#88a8c1"></stop>
            <stop offset="100%" stopColor="#edf6fd"></stop>
         </linearGradient>
         <linearGradient
            id="19-45.91152463119076-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
            x1="0"
            x2="0.969"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#88a8c1"></stop>
            <stop offset="100%" stopColor="#edf6fd"></stop>
         </linearGradient>
         <linearGradient
            id="19-45.5929285197799-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
            x1="0"
            x2="0.98"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#88a8c1"></stop>
            <stop offset="100%" stopColor="#edf6fd"></stop>
         </linearGradient>
         <linearGradient
            id="19-45.87653688112969-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
            x1="0"
            x2="0.97"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#88a8c1"></stop>
            <stop offset="100%" stopColor="#edf6fd"></stop>
         </linearGradient>
         <linearGradient
            id="19-46.82999602097813-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
            x1="0"
            x2="0.938"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#88a8c1"></stop>
            <stop offset="100%" stopColor="#edf6fd"></stop>
         </linearGradient>
      </defs>
      <g className="raphael-group-20-background">
         <rect width="802" height="493" fill="#FFF" rx="0" ry="0"></rect>
         <rect
            width="802"
            height="493"
            fill="none"
            stroke="#666"
            strokeWidth="0"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-27-canvas">
         <rect
            width="697"
            height="348"
            x="89"
            y="58"
            fill="none"
            stroke="#545454"
            strokeWidth="0"
            rx="0"
            ry="0"
         ></rect>
         <path
            fill="url('#19-329.0909881288068-rgba_187_192_181_1_-rgba_236_240_232_1_')"
            strokeWidth="0"
            d="M89 58l3.6-3H783l3 3z"
         ></path>
         <path
            fill="url('#19-65.55604521958347-rgb_134.16_145.86_119.34_-rgb_236.74_240.04_232.56_')"
            strokeLinejoin="round"
            strokeWidth="0"
            d="M73 64l1 1h9v-1l6-6H79z"
            shapeRendering="geometricprecision"
         ></path>
         <path
            fill="url('#19-270-rgb_129_140.25_114.75_-rgb_60.199999999999996_65.45_53.55_')"
            strokeLinejoin="round"
            strokeWidth="0"
            d="M82 65v347h1l6-6V58l-6 6"
            shapeRendering="geometricprecision"
         ></path>
         <path
            fill="url('#19-270-rgb_209.35_217.6_198.9_-rgb_111.8_121.55_99.45_')"
            strokeLinejoin="round"
            strokeWidth="0"
            d="M83 64v348H73V64z"
            shapeRendering="geometricprecision"
         ></path>
         <path fill="none" stroke="#FAFBF9" d="M89 58v348"></path>
         <rect
            width="697"
            height="348"
            x="89"
            y="58"
            fill="url('#19-329.0909881288068-rgba_187_192_181_1_-rgba_236_240_232_1_')"
            strokeWidth="0"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-35-axisbottom">
         <g
            fill="none"
            stroke="#ACBB99"
            className="raphael-group-46-x-axis-lines"
            shapeRendering="crispedges"
         >
            <path d="M228.4 58v348M367.8 58v348M507.2 58v348M646.6 58v348"></path>
         </g>
         <g className="fusioncharts-xaxis-0-gridlabels">
            <text
               x="68"
               y="82.09"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="68" dy="4.004">
                  AK
               </tspan>
            </text>
            <text
               x="68"
               y="125.278"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="68" dy="4.005">
                  CA
               </tspan>
            </text>
            <text
               x="68"
               y="168.466"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="68" dy="4.005">
                  ID
               </tspan>
            </text>
            <text
               x="68"
               y="211.654"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="68" dy="4.006">
                  MT
               </tspan>
            </text>
            <text
               x="68"
               y="254.842"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="68" dy="4.006">
                  NM
               </tspan>
            </text>
            <text
               x="68"
               y="298.03"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="68" dy="4.007">
                  OR
               </tspan>
            </text>
            <text
               x="68"
               y="341.218"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="68" dy="4.007">
                  WA
               </tspan>
            </text>
            <text
               x="68"
               y="384.406"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="68" dy="4.008">
                  WY
               </tspan>
            </text>
         </g>
         <g className="fusioncharts-yaxis-0-gridlabels">
            <text
               x="79"
               y="427"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="79" dy="-2">
                  0
               </tspan>
            </text>
            <text
               x="218.4"
               y="427"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="218.4" dy="-2">
                  8
               </tspan>
            </text>
            <text
               x="357.8"
               y="427"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="357.8" dy="-2">
                  16
               </tspan>
            </text>
            <text
               x="497.2"
               y="427"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="497.2" dy="-2">
                  24
               </tspan>
            </text>
            <text
               x="636.6"
               y="427"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="636.6" dy="-2">
                  32
               </tspan>
            </text>
            <text
               x="776"
               y="427"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="776" dy="-2">
                  40
               </tspan>
            </text>
         </g>
         <g className="raphael-group-55-axis-name">
            <text
               fill="#49563A"
               className="fusioncharts-yaxis-0-title"
               fontSize="10"
               textAnchor="middle"
               transform="translate(437.5 432)"
            >
               <tspan x="0" dy="10">
                  Orders
               </tspan>
            </text>
            <text
               fill="#49563A"
               className="fusioncharts-xaxis-0-title"
               fontSize="10"
               textAnchor="middle"
               transform="rotate(-90 132.5 99.5)"
            >
               <tspan x="0" dy="10">
                  States
               </tspan>
            </text>
         </g>
      </g>
      <g className="raphael-group-21-dataset">
         <g className="raphael-group-76-bars">
            <g
               strokeLinejoin="round"
               className="raphael-group-79-positive-values"
               shapeRendering="geometricprecision"
            >
               <path
                  fill="url('#19-46.82999602097813-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                  d="M84 372.13l1 1h155.825v-1l5-5H89z"
                  filter='url("#57397723-26BC-457D-92AB-10C1F13A2DA0")'
               ></path>
               <path
                  fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                  d="M239.825 373.13v33.551h1l5-5v-34.55l-5 5"
                  filter='url("#834D4703-62D7-4CF0-B613-231A2BB6E38F")'
               ></path>
               <path
                  fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                  d="M240.825 372.13v34.551H84v-34.55z"
               ></path>
               <path
                  fill="url('#19-45.87653688112969-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                  d="M84 328.943l1 1h330.075v-1l5-5H89z"
                  filter='url("#4CC179B8-63D7-40F7-89CB-CCA73047C435")'
               ></path>
               <path
                  fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                  d="M414.075 329.943v33.55h1l5-5v-34.55l-5 5"
                  filter='url("#3766E648-9814-4756-BB9E-B545AF14698F")'
               ></path>
               <path
                  fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                  d="M415.075 328.943v34.55H84v-34.55z"
               ></path>
               <path
                  fill="url('#19-45.5929285197799-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                  d="M84 285.755l1 1h486.9v-1l5-5H89z"
                  filter='url("#7F157F22-9413-4E18-A661-843001C36EA3")'
               ></path>
               <path
                  fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                  d="M570.9 286.755v33.55h1l5-5v-34.55l-5 5"
                  filter='url("#27F5FA98-71F5-44D2-9FA8-174BC30F38B5")'
               ></path>
               <path
                  fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                  d="M571.9 285.755v34.55H84v-34.55z"
               ></path>
               <g>
                  <path
                     fill="url('#19-45.91152463119076-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                     d="M84 242.567l1 1h312.65v-1l5-5H89z"
                     filter='url("#D3477A30-05AC-44F1-9101-6E28447118BD")'
                  ></path>
                  <path
                     fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                     d="M396.65 243.567v33.55h1l5-5v-34.55l-5 5"
                     filter='url("#9CC4EA86-F257-43C2-BF0F-A1D0E2D78A0F")'
                  ></path>
                  <path
                     fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                     d="M397.65 242.567v34.55H84v-34.55z"
                  ></path>
               </g>
               <g>
                  <path
                     fill="url('#19-50.025675242316765-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                     d="M84 199.38l1 1h51.275v-1l5-5H89z"
                     filter='url("#E02D98B4-4D8A-45B6-BE0C-B474D72941FA")'
                  ></path>
                  <path
                     fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                     d="M135.275 200.38v33.55h1l5-5v-34.55l-5 5"
                     filter='url("#3A1CAFCE-AC6F-42DE-9486-369C63D5F7ED")'
                  ></path>
                  <path
                     fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                     d="M136.275 199.38v34.55H84v-34.55z"
                  ></path>
               </g>
               <g>
                  <path
                     fill="url('#19-45.526509190545994-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                     d="M84 156.191l1 1h539.175v-1l5-5H89z"
                     filter='url("#02AE9406-B598-44B9-A7A0-5B4A67A6111A")'
                  ></path>
                  <path
                     fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                     d="M623.175 157.191v33.55h1l5-5v-34.55l-5 5"
                     filter='url("#44918680-5C42-407C-97BA-2EF7D21C9EA9")'
                  ></path>
                  <path
                     fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                     d="M624.175 156.191v34.55H84v-34.55z"
                  ></path>
               </g>
               <g>
                  <path
                     fill="url('#19-48.829346920871295-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                     d="M84 113.003l1 1h68.7v-1l5-5H89z"
                     filter='url("#4835D3A8-A86B-41C5-8309-8DE488A91DC2")'
                  ></path>
                  <path
                     fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                     d="M152.7 114.003v33.55h1l5-5v-34.55l-5 5"
                     filter='url("#66966797-7EA6-4500-B5B7-392109EB4C41")'
                  ></path>
                  <path
                     fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                     d="M153.7 113.003v34.55H84v-34.55z"
                  ></path>
               </g>
               <g>
                  <path
                     fill="url('#19-46.59779431802667-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                     d="M84 69.815l1 1h173.25v-1l5-5H89z"
                     filter='url("#99AEA3F9-25DB-4DAB-92CC-46239C76C4B4")'
                  ></path>
                  <path
                     fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                     d="M257.25 70.815v33.55h1l5-5v-34.55l-5 5"
                     filter='url("#68961901-76B7-46F7-A019-E6D826540C31")'
                  ></path>
                  <path
                     fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                     d="M258.25 69.815v34.55H84v-34.55z"
                  ></path>
               </g>
            </g>
         </g>
      </g>
      <g className="raphael-group-26-datalabels">
         <g className="raphael-group-173-datalabel">
            <text
               x="254.25"
               y="87.09"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="254.25" dy="4.004">
                  10
               </tspan>
            </text>
            <text
               x="149.7"
               y="130.278"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="149.7" dy="4.005">
                  4
               </tspan>
            </text>
            <text
               x="620.175"
               y="173.466"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="620.175" dy="4.005">
                  31
               </tspan>
            </text>
            <text
               x="132.275"
               y="216.654"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="132.275" dy="4.006">
                  3
               </tspan>
            </text>
            <text
               x="393.65"
               y="259.842"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="393.65" dy="4.006">
                  18
               </tspan>
            </text>
            <text
               x="567.9"
               y="303.03"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="567.9" dy="4.007">
                  28
               </tspan>
            </text>
            <text
               x="411.075"
               y="346.218"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="411.075" dy="4.007">
                  19
               </tspan>
            </text>
            <text
               x="236.825"
               y="389.406"
               fill="#49563A"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="236.825" dy="4.008">
                  9
               </tspan>
            </text>
         </g>
      </g>
      <g className="fusioncharts-legend" transform="translate(405.5 452.5)">
         <rect
            width="50"
            height="25"
            fill="none"
            stroke="#404040"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeOpacity="0.05"
            strokeWidth="5"
            rx="0"
            ry="0"
            transform="translate(1 1)"
         ></rect>
         <rect
            width="50"
            height="25"
            fill="none"
            stroke="#404040"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeOpacity="0.1"
            strokeWidth="3"
            rx="0"
            ry="0"
            transform="translate(1 1)"
         ></rect>
         <rect
            width="50"
            height="25"
            fill="none"
            stroke="#404040"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeOpacity="0.15"
            rx="0"
            ry="0"
            transform="translate(1 1)"
         ></rect>
         <rect
            width="50"
            height="25"
            fill="#F0F3ED"
            stroke="#C6CFB8"
            rx="0"
            ry="0"
         ></rect>
         <text
            x="23"
            y="6.5"
            fill="#49563A"
            className="fusioncharts-legenditem"
            cursor="pointer"
            fontSize="10"
         >
            <tspan x="23" dy="9.25">
               null
            </tspan>
         </text>
         <path
            fill="url('#19-360-rgba_175_216_248_1__0-rgba_175_216_248_1__30-rgba_223_240_252_1__60-rgba_175_216_248_1__90-rgba_175_216_248_1__100')"
            stroke="#CFE8FB"
            strokeWidth="0.5"
            d="M7 7h6.6v2.75H7zm0 4.125h11v2.75H7zm0 4.125h3.3V18H7z"
            cursor="pointer"
         ></path>
         <rect
            width="40"
            height="17"
            x="4"
            y="4"
            fill="silver"
            fillOpacity="0"
            cursor="pointer"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-170-caption">
         <text
            x="437.5"
            y="16"
            fill="#49563A"
            className="fusioncharts-caption"
            fontFamily="Verdana, sans"
            fontSize="13"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="437.5" dy="12.992">
               Chart Caption
            </tspan>
         </text>
         <text
            x="437.5"
            y="34"
            fill="#49563A"
            className="fusioncharts-subcaption"
            fontFamily="Verdana, sans"
            fontSize="11"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="437.5" dy="10.852">
               Chart Sub Caption
            </tspan>
         </text>
      </g>
      <g className="raphael-group-22-hot">
         <g
            stroke="silver"
            strokeLinejoin="round"
            strokeOpacity="0"
            className="raphael-group-81-positive-values"
            shapeRendering="geometricprecision"
         >
            <path
               fill="url('#19-46.769737595688554-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
               d="M84 372.13l1 1h155.825v-1l5-5H89z"
            ></path>
            <path
               fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
               d="M239.825 373.13v33.551h1l5-5v-34.55l-5 5"
            ></path>
            <path
               fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
               d="M240.825 372.13v34.551H84v-34.55z"
            ></path>
            <path
               fill="url('#19-45.85236260830118-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
               d="M84 328.943l1 1h330.075v-1l5-5H89z"
            ></path>
            <path
               fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
               d="M414.075 329.943v33.55h1l5-5v-34.55l-5 5"
            ></path>
            <path
               fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
               d="M415.075 328.943v34.55H84v-34.55z"
            ></path>
            <path
               fill="url('#19-45.581191056578504-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
               d="M84 285.755l1 1h486.9v-1l5-5H89z"
            ></path>
            <path
               fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
               d="M570.9 286.755v33.55h1l5-5v-34.55l-5 5"
            ></path>
            <path
               fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
               d="M571.9 285.755v34.55H84v-34.55z"
            ></path>
            <g>
               <path
                  fill="url('#19-45.89896560290124-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
                  d="M84 242.567l1 1h312.65v-1l5-5H89z"
               ></path>
               <path
                  fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
                  d="M396.65 243.567v33.55h1l5-5v-34.55l-5 5"
               ></path>
               <path
                  fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
                  d="M397.65 242.567v34.55H84v-34.55z"
               ></path>
            </g>
            <g>
               <path
                  fill="url('#19-49.98916558622196-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
                  d="M84 199.38l1 1h51.275v-1l5-5H89z"
               ></path>
               <path
                  fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
                  d="M135.275 200.38v33.55h1l5-5v-34.55l-5 5"
               ></path>
               <path
                  fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
                  d="M136.275 199.38v34.55H84v-34.55z"
               ></path>
            </g>
            <g>
               <path
                  fill="url('#19-45.52546588834422-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
                  d="M84 156.191l1 1h539.175v-1l5-5H89z"
               ></path>
               <path
                  fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
                  d="M623.175 157.191v33.55h1l5-5v-34.55l-5 5"
               ></path>
               <path
                  fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
                  d="M624.175 156.191v34.55H84v-34.55z"
               ></path>
            </g>
            <g>
               <path
                  fill="url('#19-48.829346920871295-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
                  d="M84 113.003l1 1h68.7v-1l5-5H89z"
               ></path>
               <path
                  fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
                  d="M152.7 114.003v33.55h1l5-5v-34.55l-5 5"
               ></path>
               <path
                  fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
                  d="M153.7 113.003v34.55H84v-34.55z"
               ></path>
            </g>
            <g>
               <path
                  fill="url('#19-46.59779431802667-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
                  d="M84 69.815l1 1h173.25v-1l5-5H89z"
               ></path>
               <path
                  fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
                  d="M257.25 70.815v33.55h1l5-5v-34.55l-5 5"
               ></path>
               <path
                  fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
                  d="M258.25 69.815v34.55H84v-34.55z"
               ></path>
            </g>
         </g>
      </g>
   </svg>)
};

export default Bar3DChartIcon;