/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const EdgeEnum = new PEnum({
    id: 'edge', label: 'Edge.label', isEmptyUndefined: true,
    items: [
        { key: '', value: ''},
        { key: 'Top', value: 'Edge.Top'},
        { key: 'Bottom', value: 'Edge.Bottom'},
        { key: 'Left', value: 'Edge.Left'},
        { key: 'Right', value: 'Edge.Right'},
    ]
}); 
 
