/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ICONS } from "@jss/js-common";
import { ElementTypes } from "../../../../../../sagas/report/document/elementTypes";
import { APDescriptor } from "../../../ui/APDescriptor";
import { PAccordeonCategories } from "../../../ui/composite/UPAccordeonCategories";

import { PDiv } from "../../../ui/composite/UPDiv";
import { PScrollableCategories } from "../../../ui/composite/UPScrollableCategories";
import { PSelectedType } from "../../../ui/composite/UPSelectedType";
import { getPath, UiPropertyProps } from "../../../ui/UiProperty";
import { MBoxGraphicElement } from "../../common/MGraphicElement";
import { MFusionAngularGauge, MFusionAngularGaugeDataset } from "./MFusionAngularGauge";
import { MFusionBulb, MFusionBulbDataset } from "./MFusionBulb";
import { MFusionCylinder, MFusionCylinderDataset } from "./MFusionCylinder";
import { MFusionFunnel, MFusionFunnelDataset } from "./MFusionFunnel";
import { MFusionGanttChart, MFusionGanttChartDataset } from "./MFusionGanttChart";
import { MFusionHorizontalBullet, MFusionHorizontalBulletDataset } from "./MFusionHorizontalBullet";
import { MFusionHorizontalLED, MFusionHorizontalLEDDataset } from "./MFusionHorizontalLED";
import { MFusionLinearGauge, MFusionLinearGaugeDataset } from "./MFusionLinearGauge";
import { MFusionPyramid, MFusionPyramidDataset } from "./MFusionPyramid";
import { MFusionSparkColumn, MFusionSparkColumnDataset } from "./MFusionSparkColumn";
import { MFusionSparkLine, MFusionSparkLineDataset } from "./MFusionSparkLine";
import { MFusionSparkWinLoss, MFusionSparkWinLossDataset } from "./MFusionSparkWinLoss";
import { MFusionThermometer, MFusionThermometerDataset } from "./MFusionThermometer";
import { MFusionVerticalBullet, MFusionVerticalBulletDataset } from "./MFusionVerticalBullet";
import { MFusionVerticalLED, MFusionVerticalLEDDataset } from "./MFusionVerticalLED";
import { MFustionWidgetCustom } from "../MFustionWidgetCustom";

export const MFusionWidgets: APDescriptor = {
    type: ElementTypes.TEXTFIELD,
    label: 'Fusion Widgets',
    id: 'fusionWidgetsPropertiesDescriptor',
    default: { width: 200, height: 30, ...MFusionThermometer.default },
    icon: ICONS.MAP_PRO,
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MBoxGraphicElement.layouts, MFustionWidgetCustom] }),
            new PDiv({
                label: 'Fusion Widget',
                layouts: [
                    new PAccordeonCategories({
                        selectionField: 'widgetType',
                        getSelected(props: UiPropertyProps): APDescriptor | undefined {
                            const pac = props.mcontext.descriptor as PAccordeonCategories;
                            const p = getPath(pac.selectionField ? pac.selectionField : pac.id, props.mcontext.elements[0].path);
                            const v: string = props.mcontext.model.getIn(p) as string;
                            return pac.layouts?.find(k => {
                                return k.id.toLowerCase() === v.toLowerCase()
                            });
                        },
                        categories: [MFusionThermometer, MFusionBulb, MFusionPyramid, MFusionFunnel, MFusionCylinder, MFusionHorizontalLED, MFusionVerticalLED,
                            MFusionHorizontalBullet, MFusionVerticalBullet, MFusionAngularGauge, MFusionLinearGauge, MFusionSparkLine, MFusionSparkColumn, MFusionSparkWinLoss, MFusionGanttChart]
                    })
                ]
            }), new PDiv({
                label: 'Data', layouts: [
                    new PSelectedType({
                        id: 'fusion.widgets.data',
                        selectionField: 'widgetType',
                        getSelected(props: UiPropertyProps): APDescriptor | undefined {
                            const pac = props.mcontext.descriptor as PSelectedType;
                            const p = getPath(pac.selectionField ? pac.selectionField : pac.id, props.mcontext.elements[0].path);
                            const v: string = props.mcontext.model.getIn(p) as string;
                            let r;
                            switch (v) {
                                case MFusionThermometer.id: r = MFusionThermometerDataset; break;
                                case MFusionBulb.id: r = MFusionBulbDataset; break;
                                case MFusionPyramid.id: r = MFusionPyramidDataset; break;
                                case MFusionFunnel.id: r = MFusionFunnelDataset; break;
                                case MFusionCylinder.id: r = MFusionCylinderDataset; break;
                                case MFusionHorizontalLED.id: r = MFusionHorizontalLEDDataset; break;
                                case MFusionVerticalLED.id: r = MFusionVerticalLEDDataset; break;
                                case MFusionHorizontalBullet.id: r = MFusionHorizontalBulletDataset; break;
                                case MFusionVerticalBullet.id: r = MFusionVerticalBulletDataset; break;
                                case MFusionAngularGauge.id: r = MFusionAngularGaugeDataset; break;
                                case MFusionLinearGauge.id: r = MFusionLinearGaugeDataset; break;
                                case MFusionSparkLine.id: r = MFusionSparkLineDataset; break;
                                case MFusionSparkColumn.id: r = MFusionSparkColumnDataset; break;
                                case MFusionSparkWinLoss.id: r = MFusionSparkWinLossDataset; break;
                                case MFusionGanttChart.id: r = MFusionGanttChartDataset; break;
                            }
                            if (r) {
                                return pac.layouts.find(k => { return k.type === r.type });
                            }
                        },
                        layouts: [
                            MFusionThermometerDataset, MFusionBulbDataset, MFusionPyramidDataset, MFusionFunnelDataset, MFusionCylinderDataset, MFusionHorizontalLEDDataset, MFusionVerticalLEDDataset, MFusionHorizontalBulletDataset, MFusionVerticalBulletDataset, MFusionAngularGaugeDataset, MFusionLinearGaugeDataset, MFusionSparkLineDataset, MFusionSparkColumnDataset, MFusionSparkWinLossDataset, MFusionGanttChartDataset
                        ]
                    })]
            })]
    })]
};