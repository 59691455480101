/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { getPath, reg, setupPath4Elements, UiProperty, UiPropertyProps } from '../UiProperty';
import { APDescriptor } from '../APDescriptor';
import { PAccordeon } from './UPAccordeon';
import { Accordion, AccordionDetails, AccordionSummary, ICONS, Typography } from '@jss/js-common';
import _ from 'lodash';
import { fromJS } from 'immutable';
import i18n from '../../../../../i18n';

const TYPEID = 'BAccordeonCategory';
reg(TYPEID, (mc) => { return <UPAccordeonCategories mcontext={mc} />; });
export class PAccordeonCategories extends APDescriptor {
    selectionField?: string = 'id';
    categories: APDescriptor[];

    public getSelected = (props: UiPropertyProps): APDescriptor | undefined => {
        const pac = props.mcontext.descriptor as PAccordeonCategories;
        const p = getPath(pac.selectionField ? pac.selectionField : pac.id, props.mcontext.elements[0].path);
        const v = props.mcontext.model.getIn(p);

        return pac.categories?.find(k => { return k.id === v });
    }

    public setSelected = (props: UiPropertyProps, d: APDescriptor) => {
        props.mcontext.elements.forEach(key => {
            props.mcontext.setObjectProperties(key.path, { [this.selectionField]: d.id });
            Object.keys(d.default).forEach(k => {
                if (k !== this.selectionField) {
                    const p = getPath(k, props.mcontext.elements[0].path);
                    const v = props.mcontext.model.getIn(p);
                    if (!v) {
                        const def = fromJS(d.default[k]);
                        props.mcontext.setObjectProperties(key.path, { [k]: def });
                    }
                }
            });
        });
    }

    public constructor(init: Partial<PAccordeonCategories>) {
        super();
        const l = _.cloneDeep(init.categories);
        Object.assign(this, { ...init, type: TYPEID, layouts: l });
    }
}
export class PAccordeonCategoriesByID extends PAccordeonCategories {

    public getSelected = (props: UiPropertyProps): APDescriptor | undefined => {
        const pac = props.mcontext.descriptor as PAccordeonCategories;
        const p = getPath(pac.selectionField ? pac.selectionField : pac.id, props.mcontext.elements[0].path);
        const v: string = props.mcontext.model.getIn(p) as string;
        return pac.layouts?.find(k => {
            if (k.id) {
                return k.id.toLowerCase() === v?.toLowerCase();
            }
        });
    }

    public constructor(init: Partial<PAccordeonCategoriesByID>) {
        super(init);
    }
}

export interface IUPAccordeonCategoriesState {
    expanded: boolean | undefined;
}

export class UPAccordeonCategories extends React.Component<UiPropertyProps, IUPAccordeonCategoriesState> {
    public state = { expanded: (this.props.mcontext.descriptor as PAccordeon).expanded === true }

    private handleExpanded = () => {
        this.setState({ expanded: !this.state.expanded });
    }
    private handleSelected = (d: APDescriptor) => {
        const pac = this.props.mcontext.descriptor as PAccordeonCategories;
        pac.setSelected(this.props, d);
    }

    render() {
        const pac = this.props.mcontext.descriptor as PAccordeonCategories;
        const d = pac.getSelected(this.props);
        const lbl = 'Type: ' + i18n.t(d ? d.label : '');
        return <div style={{ flex: 1, flexDirection: 'column', overflow: 'auto' }} >
            <Accordion expanded={this.state.expanded} onChange={this.handleExpanded} size='small'>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" size='small'>
                    <Typography style={{ textTransform: 'capitalize' }} >{lbl}</Typography>
                </AccordionSummary>
                <AccordionDetails
                    style={this.props.mcontext.descriptor.style ? this.props.mcontext.descriptor.style : { display: 'block' }}
                    className={this.props.mcontext.descriptor.className}>
                    <div className="Palette">
                        <div className="PaletteItems">
                            {//render the element only if expanded
                                this.state.expanded && pac.categories?.map((key) => {
                                    const c = d.id === key.id ? 'PaletteItem PaletteItemSelected' : 'PaletteItem';
                                    return <div key={'p' + key.id} className={c} onClick={() => this.handleSelected(key)}>
                                        {this.renderIcon(key)}{i18n.t(key.label)}</div>
                                })}
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            {this.renderDescriptor(d)}
        </div>;
    }
    private renderIcon(d: APDescriptor) {
        if (d?.icon) {
            if (typeof d.icon === "string")
                return <img src={d.icon as string} />;
            return d.icon;
        }

        return ICONS.GENERIC_ICON;
    }

    private renderDescriptor = (d: APDescriptor) => {
        if (d === undefined)
            return <></>;
        if (d.layouts?.length > 0 && d.type !== 'barcodeImp') {
            const mc = _.cloneDeep(this.props.mcontext);
            mc.elements = setupPath4Elements(this.props.mcontext.elements, d);
            return d.layouts?.map((key, index) => {
                key.readonly = this.props.mcontext.descriptor.readonly;
                return <UiProperty key={d.id + '.' + key.id + '.' + index} mcontext={{ ...mc, descriptor: key }} />
            })
        }
        d.readonly = this.props.mcontext.descriptor.readonly;
        return <UiProperty key={d.id} mcontext={{ ...this.props.mcontext, descriptor: d }} />
    }

}


