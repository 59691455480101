/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IRepositoryItemDescriptor, RESOURCE_TYPE } from "../repo/IRepositoryItemDescriptor";
import { Mimes, MIME_TYPES } from "../repo/mimes";
import { Permission } from "../repo/RepositoryApi";
import { ResourceFieldREST } from "../rest/ResourceFieldREST";

export class ResourceFieldJRS extends ResourceFieldREST {
    public getContainerLenght() {
        return 0;
    }
    
    public isEditable(res: IRepositoryItemDescriptor, name: string) {
        if (res.type === RESOURCE_TYPE.CONTAINER || (res.type === RESOURCE_TYPE.FILE && (!res.mime || Mimes.get(res.mime, false) === undefined)))
            return false;
        return super.isEditable(res, name);
    }
    public isAvailable(res: IRepositoryItemDescriptor, name: string) {
        switch (name) {
            case 'size':
            case 'owner':
            case 'versions': return false;
        }
        return super.isAvailable(res, name);
    }


    public encode(name: string, value?: string, add?: any) {
        switch (name) {
            case 'id': return this.encodeID(value?.trim());
            case 'permission':
                let p = 0;
                value?.split(',').map(perm => {
                    switch (perm) {
                        case Permission.READ: p = p | (1 << 1); break;
                        case Permission.WRITE: p = p | (1 << 2); break;
                        case Permission.EXECUTE: p = p | (1 << 5); break;
                        case Permission.DELETE: p = p | (1 << 4); break;
                        case Permission.ADMINISTER: p = p | (1 << 0); break;
                        case Permission.CREATE: p = p | (1 << 3); break;
                    }
                })
                return `${p}`;
        }
        return super.encode(name, value, add);
    }
    public decode(name: string, value?: string) {
        switch (name) {
            case 'name': return value && decodeURIComponent(value?.trim());
            case 'mime': return this.fromMime(value);
            case 'permission':
                switch (value) {
                    case '0': return Permission.NONE;
                    case '1': return `${Permission.READ},${Permission.WRITE},${Permission.DELETE},${Permission.ADMINISTER}`;
                    case '2': return Permission.READ;
                    case '4': return Permission.WRITE;
                    case '6': return `${Permission.READ},${Permission.WRITE}`;
                    case '8': return Permission.CREATE;
                    case '16': return Permission.DELETE;
                    case '18': return `${Permission.READ},${Permission.DELETE}`;
                    case '30': return `${Permission.READ},${Permission.WRITE},${Permission.DELETE}`;
                    case '32': return Permission.EXECUTE;
                    default:
                        return Permission.NONE;
                }
        }
        return super.decode(name, value);
    }

    public encodeName(name?: string) {
        return name ? encodeURIComponent(name.trim()) : undefined;
    }
    public encodeID(name?: string) {
        if (!name)
            return;
        let result = '';
        for (const c of name) {
            result += c.match("[A-Za-z0-9._]") ? c : '_';
        }
        return result;
    }
    protected validateName(name?: string) {
        if (!name || name.trim().length === 0) {
            return "Name must have a value";
        }
        name = name.trim();
        if (name.length > 100) {
            return "Name should be max 100 chars.";
        }
        if (name.indexOf('/') > -1) {
            return "/ is not allowed in the name";
        }
        return undefined;
    }
    protected validateDescription(desc?: string) {
        if (desc && desc.trim().length > 250) {
            return 'Description should be max 250 chars.';
        }
    }
    protected fromMime(mime?: string) {
        if (!mime)
            return;
        if (mime === MIME_TYPES.JRXML) {
            return 'jrxml';
        }
        if (mime === MIME_TYPES.JRTX) {
            return 'jrtx';
        }
        if (mime.startsWith('application/vnd.jaspersoft.dataadapter.')) {
            return 'xml'; //'dataAdapter'; until we have JRS updated with new type
        }
        if (mime === MIME_TYPES.PROPERTIES) {
            return 'prop';
        }
        if (['text/xml', 'application/xml'].includes(mime)) {
            return 'xml';
        }
        if (mime.startsWith('image/')) {
            return 'img';
        }
        if (mime.startsWith('font/')) {
            return 'font';
        }
        if (mime === MIME_TYPES.CSS || mime === MIME_TYPES.JAVASCRIPT || mime === MIME_TYPES.JAVASCRIPT_APP) {
            return 'css';
        }
        if (mime === MIME_TYPES.CSV) {
            return 'csv';
        }
        if (mime === MIME_TYPES.HTML) {
            return 'html';
        }
        if (mime === MIME_TYPES.JSON) {
            return 'json';
        }
        if (mime === MIME_TYPES.PDF) {
            return 'pdf';
        }
        if (mime === MIME_TYPES.TEXT) {
            return 'text';
        }
        if (mime && mime.startsWith('application/repository.reportUnit+')) {
            return 'reportUnit';
        }
        if (mime && mime.startsWith('application/repository.adhocDataView+')) {
            return 'adhocDataView';
        }
        if (mime && mime.startsWith('application/repository.dashboard+')) {
            return 'dashboard';
        }
        return mime;
    }

    protected toMime(mime?: string, xmlHeader?: string) {
        if (mime && mime.startsWith('application/repository.folder+')) {
            return MIME_TYPES.FOLDER
        }
        if (mime && mime.startsWith('application/repository.reportUnit+')) {
            return 'reportUnit';
        }
        if (mime && mime.startsWith('application/repository.adhocDataView+')) {
            return 'adhocDataView';
        }
        if (mime && mime.startsWith('application/repository.dashboards+')) {
            return 'dashboard';
        }
        if (mime === 'jrxml') {
            return MIME_TYPES.JRXML;
        }
        if (mime === 'jrtx') {
            return MIME_TYPES.JRTX;
        } if (mime === 'jrctx') {
            return MIME_TYPES.JRCTX;
        }
        if (mime === 'dataAdapter') {
            switch (xmlHeader?.toLowerCase()) {
                case 'jdbc': return MIME_TYPES.DATA_ADAPTER_JDBC;
                case 'empty': return MIME_TYPES.DATA_ADAPTER_EMPTY;
                case 'jndi': return MIME_TYPES.DATA_ADAPTER_JNDI;
                case 'json': return MIME_TYPES.DATA_ADAPTER_JSON;
                case 'random': return MIME_TYPES.DATA_ADAPTER_RANDOM;
                case 'xls': return MIME_TYPES.DATA_ADAPTER_XLS;
                case 'xml': return MIME_TYPES.DATA_ADAPTER_XML;
                case 'csv': return MIME_TYPES.DATA_ADAPTER_CSV;
                default:
                    return MIME_TYPES.DATA_ADAPTER_JDBC;
            }
        }
        if (mime === 'css') {
            return MIME_TYPES.CSS;
        }
        if (mime === 'csv') {
            return MIME_TYPES.CSV;
        }
        if (mime === 'html') {
            return MIME_TYPES.HTML;
        }
        if (mime === 'pdf') {
            return MIME_TYPES.PDF;
        }
        if (mime === 'xml') {
            return MIME_TYPES.XML;
        }
        if (mime === 'json') {
            return MIME_TYPES.JSON;
        }
        if (mime === 'prop') {
            return MIME_TYPES.PROPERTIES;
        }
        if (mime === 'txt') {
            return MIME_TYPES.TEXT;
        }
        return mime;
    }

}