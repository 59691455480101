/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import BarChartIcon from "../../../../../../assets/icons/charts/bar.svg";
import DonutChartIcon from "../../../../../../assets/icons/charts/donut.svg";
import Donut3DChartIcon from "../../../../../../assets/icons/charts/donut_3d.svg";
import { PPropertyExpression } from "../../../ui/primitive/UPValueExpession";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { MFusionChartCommon } from "./MFusionChartCommon";
import { PInt } from "../../../ui/primitive/UPInteger";
import { MFusionPieChartCommon } from "./MFusionPieChart";
import { MFusionChart3DCommon } from "./MFusionChart3DCommon";

export const FFusionDoughnutChartProps = {
    doughnutRadius: new PPropertyExpression({ id: 'doughnutRadius', vtype: new PInt({ id: 'doughnutRadius', label: 'Radius', min: 0 }) }),
};

export const MFusionDoughnutChartCommon: APDescriptor = {
    id: 'Doughnut', type: 'fusion.chart.doughnut', label: 'Pie', icon: BarChartIcon,
    layouts: [
        new PAccordeon({
            id: 'fusion.chart.pie', label: 'Doughnut', layouts: [FFusionDoughnutChartProps.doughnutRadius]
        }),
    ]
};

export const MFusionDoughnutChart: APDescriptor = {
    id: 'Doughnut', type: 'fusion.chart.doughnut', label: 'Doughnut', icon: DonutChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts, ...MFusionPieChartCommon.layouts, ...MFusionDoughnutChartCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'Doughnut',
        'xmlns:fw': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [{ valueExpression: "new Double(0)", labelExpression: '"Label"' }] }
    }
};
export const MFusionDoughnut3DChart: APDescriptor = {
    id: 'Doughnut3D', type: 'fusion.chart.doughnut3d', label: 'Doughnut 3D', icon: Donut3DChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts, ...MFusionPieChartCommon.layouts, ...MFusionDoughnutChartCommon.layouts, ...MFusionChart3DCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'Doughnut3D',
        'xmlns:fw': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [{ valueExpression: "new Double(0)", labelExpression: '"Label"' }] }
    }
};
