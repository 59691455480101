/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import { IRepositoryItemDescriptor, RESOURCE_TYPE } from '@jss/js-rest-api';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import i18n from '../i18n';

export interface IProps {
    path: IRepositoryItemDescriptor[];
    changePath: (newPath: IRepositoryItemDescriptor[]) => void;
}

export class PathBreadcrumb extends React.Component<IProps>{
    public render() {
        const breadcrumbs = [];
        breadcrumbs.push(<Link key="0" style={{ cursor: 'pointer' }} color="inherit" onClick={() => { this.props.changePath([{ type: RESOURCE_TYPE.FOLDER, name: "" }]) }}>{i18n.t('repoview.home')}</Link>);
        const last = this.props.path.length - 1;
        if (last > 0)
            this.props.path.forEach((part, index) => {
                if (index === last) {
                    //don't add the click on the last segment
                    breadcrumbs.push(<Typography key={index} color="textPrimary">{part.name}</Typography>);
                } else {
                    if (index > 0) {
                        breadcrumbs.push(<Link color="inherit" style={{ cursor: 'pointer' }} key={index} onClick={() => {
                            this.props.changePath(this.props.path.slice(0, index + 1));
                        }}>{part.name}</Link>);
                    }
                }
            })
        return <Breadcrumbs aria-label="breadcrumb">{breadcrumbs}</Breadcrumbs>;
    }
}