/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { APDescriptor } from '../../ui/APDescriptor';
import { getPath, reg, UiProperty, UiPropertyProps } from '../../ui/UiProperty';

const TYPEID = 'BHighChartsDataset';
reg(TYPEID, (mc) => { return <UPHighChartsDataset mcontext={mc} />; });
export class PHighChartsDataset extends APDescriptor {
    layouts1?: APDescriptor[];
    public constructor(init: Partial<PHighChartsDataset>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

export class UPHighChartsDataset extends React.Component<UiPropertyProps> {
    render() {
        const d = this.props.mcontext.descriptor as PHighChartsDataset;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);

        const v = this.props.mcontext.model.getIn(p);
        if (!v)
            return <>
                {d.layouts?.map((key, index) => {
                    return <UiProperty key={index} mcontext={{ ...this.props.mcontext, descriptor: key }} />
                })}
            </>
        return <>{d.layouts1?.map((key, index) => {
            return <UiProperty key={index} mcontext={{ ...this.props.mcontext, descriptor: key }} />
        })}</>
    }

}