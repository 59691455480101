/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import { SelectItem } from './SelectItems';
import {TextField, TextFieldProps} from '../textinput/TextField';
import '../../assets/uxpl/css/Select.css';

type SelectProps = TextFieldProps &  {
    help?: string;
    items: Array<{ key: string, value: string }>,
    onItemSelected?: (key: string) => void;
    validator?: (value: string | undefined) => string | undefined;
}

export class Select extends React.Component<SelectProps > {

    private handleSelect = (key: string) => {
        if (this.props.onItemSelected) {
            this.props.onItemSelected(key);
        }
    };

    public render() {
        let validationError: string | undefined;
        if (this.props.validator) {
            validationError = this.props.validator(this.props.value as string | undefined);
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { onItemSelected, ...restProps } = this.props;
        return (
            <TextField
                size="large"
                id={this.props.id}
                label={this.props.label}
                defaultValue={this.props.placeholder}
                helperText={validationError ? validationError : this.props.help}
                error={validationError}
                disabled={this.props.disabled}
                textFieldClassName="jrws-max-width-select"
                onChange={this.props.onChange}
                value={this.props.value}
                select
                {...restProps}
            >
                {this.getItemsMenu()}
            </TextField>
        );
    }

    private getItemsMenu = () => {
        return this.props.items.map((item: { key: string, value: string }) => {
            return <SelectItem key={item.key} value={item.key} onClick={() => {this.handleSelect(item.key)}}>{item.value}</SelectItem>;
        });
    }
}