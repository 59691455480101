/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { IAbstractTextualEditor } from './AbstractTextualEditor';
import InternalDataAdapterTextEditor from './InternalDataAdapterTextEditor';

export class DataAdapterTextEditor extends React.Component<IAbstractTextualEditor> {

    private xmlEditorRef: React.RefObject<InternalDataAdapterTextEditor> = React.createRef<InternalDataAdapterTextEditor>();

    getEditorContent = () => {
        return this.xmlEditorRef.current?.getEditorContent();
    }

    render() {
        return <InternalDataAdapterTextEditor ref={this.xmlEditorRef} {...this.props} />;
    }
}