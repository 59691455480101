/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { fromJS, Map, List } from 'immutable';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { IState } from '../../../reducers';
import * as ReportActions from '../../../actions/reportActions';
import * as DocumentUtils from './documentUtils';
import { BandTypes, ElementTypes } from '../document/elementTypes';
import { initializeMapWithObject } from '@jss/js-common';
import { DatasourceApi, IRepositoryItemDescriptor, RepositoryApi } from '@jss/js-rest-api';
import { FETCHING_STATUS, STATUS_LOADING, STATUS_NOT_LOADED } from '../../../reducers/report/reportReducer';
import createConcurrentTaskQueue from './sagaQueue';
import jsToJrxml from '../../../components/common/JrxmlModel/writer/JrxmlWriter';
import { Base64 } from 'js-base64';
import format from 'xml-formatter';


/**
 * Creates the new document and dispatch the calculateClientArea action
 * It needs to be trasnformed to a saga...
 * 
 * @param {*} model 
 */

function* createNewDocument(action: any) {
  yield put(ReportActions.resetEditor());
  yield put(ReportActions.prepareNewDocument(action.template));
  const currentState = yield select((state: IState) => state);
  const clientArea = DocumentUtils.calculateClientArea(currentState);
  yield put(ReportActions.clientAreaChanged(fromJS(clientArea)));
  yield put(ReportActions.scrollY(0));
  yield put(ReportActions.scrollX(0));
}

function* openDocument(action: any) {
  yield put(ReportActions.resetEditor(action.options.keepCache, action.options.keepHistory, action.model));
  yield put(ReportActions.openDocument(action.model, action.options.keepCache));
  yield put(ReportActions.setDescriptor(action.descriptor, action.path));
  if (action.parameters){
    const parameters = action.parameters as Map<string, any>;
    yield put(ReportActions.setParametersValues(parameters));
  }

  const currentState = yield select((state: IState) => state);
  const clientArea = DocumentUtils.calculateClientArea(currentState);
  yield put(ReportActions.clientAreaChanged(fromJS(clientArea)));
  yield put(ReportActions.scrollY(0));
  yield put(ReportActions.scrollX(0));
}

function* loadComboValues(action: any) {
  const path = action.path;
  const force = action.force;

  const currentState = yield select((state: IState) => state);
  const comboValues: Map<string, any> = currentState.getIn(['report', 'cachedData', 'comboValues'], Map<string, any>());
  const fontFetchingStatus = comboValues.get('status');
  const loadedPath = comboValues.get('loadedPath');
  if (force || !loadedPath || loadedPath !== path || fontFetchingStatus === STATUS_NOT_LOADED) {
    //console.log('start fetching')
    if (fontFetchingStatus === STATUS_LOADING) {
      const cancelToken = comboValues.get('cancelToken');
      if (cancelToken) {
        cancelToken.cancel();
      }
    }
    const request = DatasourceApi.inst().getReportLists(path);
    if (request) {
      const data = Map({
        status: FETCHING_STATUS.STATUS_LOADING,
        loadedPath: path,
        cancelToken: request.cancelToken,
      });
      yield put(ReportActions.storeInCache('comboValues', data));
      try {
        const result = yield request.promise;
        const systemParameters = fromJS(result.data.systemParameters);
        const data = Map({
          status: FETCHING_STATUS.STATUS_LOADED,
          loadedPath: path,
          fonts: fromJS(result.data.fonts),
          queryLanguages: fromJS(result.data.queryLanguages),
          reportLanguages: fromJS(result.data.reportLanguages),
          systemParameters,
          systemVariables: fromJS(result.data.systemVariables),
          datasetProperties: fromJS(result.data.datasetProperties),
          contextPropertyValues: fromJS(result.data.contextPropertyValues)
        });
        yield put(ReportActions.storeInCache('comboValues', data));
        //console.log(JSON.stringify(data.toJS()));
      } catch (err) {
        //console.log(JSON.stringify(err));
        const data = Map({
          status: FETCHING_STATUS.STATUS_ERROR,
          loadedPath: path,
          fonts: comboValues.get('fonts'),
          queryLanguages: comboValues.get('queryLanguages'),
          reportLanguages: comboValues.get('reportLanguages'),
          systemParameters: comboValues.get('systemParameters', Map<string, any>()),
          systemVariables: comboValues.get('systemVariables'),
          datasetProperties: comboValues.get('datasetProperties'),
          contextPropertyValues: comboValues.get('contextPropertyValues')
        });
        yield put(ReportActions.storeInCache('comboValues', data));
      }
    }
  }
}

function* deletePropertyRefreshAction(action: any){
  const path = action.path;
  const reportPath = action.reportPath;
  if (path && path[1] === 'subdatasets'){
    const currentState = yield select((state: IState) => state);
    const currentSelectedDataset: string = currentState.getIn(['report', 'datasetSelection'], 'main');
    if (path[path.length - 1] === currentSelectedDataset){
      yield put(ReportActions.selectDataset(undefined));
    }
  }
  yield put(ReportActions.deleteElement(path));
  const currentState = yield select((state: IState) => state);
  const descriptor = currentState.getIn(['report', 'fileDescriptor']);
  const jrxml = jsToJrxml(currentState.getIn(['report', 'model']));
  const base64data = Base64.encode(format(jrxml, { indentation: '  ', collapseContent: true }));
  const newDescriptor: IRepositoryItemDescriptor = {
      ...descriptor,
      uuid: undefined,
      data: base64data,
      path: RepositoryApi.getAPathOnly(descriptor)
  }
  const tempSaveCall = () => RepositoryApi.inst().tmp().save(newDescriptor.path ? newDescriptor.path : '/', newDescriptor);
  yield call(tempSaveCall);
  yield loadComboValues({path: reportPath, force: true});
}

function* setPropertiesRefreshAction(action: any){
  const path = action.path;
  const properties = action.properties;
  const reportPath = action.reportPath;
  yield put(ReportActions.setObjectProperties(path, properties));
  const currentState = yield select((state: IState) => state);
  const descriptor = currentState.getIn(['report', 'fileDescriptor']);
  const jrxml = jsToJrxml(currentState.getIn(['report', 'model']));
  const base64data = Base64.encode(format(jrxml, { indentation: '  ', collapseContent: true }));
  const newDescriptor: IRepositoryItemDescriptor = {
      ...descriptor,
      uuid: undefined,
      data: base64data,
      path: RepositoryApi.getAPathOnly(descriptor)
  }
  const tempSaveCall = () => RepositoryApi.inst().tmp().save(newDescriptor.path ? newDescriptor.path : '/', newDescriptor);
  yield call(tempSaveCall);
  yield loadComboValues({path: reportPath, force: true});
}

function* checkResourceExistence(action: any) {
  const resourcePath = action.resourcePath;
  const reportPath = action.reportPath;
  const force = action.force;

  const currentState = yield select((state: IState) => state);
  const resourcesCache: Map<string, any> = currentState.getIn(['report', 'cachedData', 'cachedResources'], Map<string, any>());
  const resourceStatus = resourcesCache.get(resourcePath);
  if (force || !resourceStatus) {
    if (resourceStatus) {
      //check if there is a previous request pending to load
      const resourceFetchingStatus = resourceStatus.get('status');
      if (resourceFetchingStatus === STATUS_LOADING) {
        const cancelToken = resourceStatus.get('cancelToken');
        if (cancelToken) {
          cancelToken.cancel();
        }
      }
    }
    const request = DatasourceApi.inst().resourceExists(reportPath, resourcePath);

    if (request) {
      const data = Map({
        status: FETCHING_STATUS.STATUS_LOADING,
        cancelToken: request.cancelToken,
      });
      let newResourceCache = resourcesCache.set(resourcePath, data);
      yield put(ReportActions.storeInCache('cachedResources', newResourceCache));
      try {
        const result = yield request.promise;
        if (result.status === 200) {
          const data = Map({
            status: FETCHING_STATUS.STATUS_LOADED,
          });
          newResourceCache = resourcesCache.set(resourcePath, data);
          yield put(ReportActions.storeInCache('cachedResources', newResourceCache));
        }
      } catch (error) {
        let data;
        if (error.response.status === 404) {
          data = Map({
            status: FETCHING_STATUS.STATUS_NOT_FOUND,
          });
        } else {
          data = Map({
            status: FETCHING_STATUS.STATUS_LOADED,
          });
        }
        newResourceCache = resourcesCache.set(resourcePath, data);
        yield put(ReportActions.storeInCache('cachedResources', newResourceCache));
      }
    }
  }
}

function* createNewEditor(action: any) {

  yield put(ReportActions.instantiateEditor(action.editorType, action.editorLabel, action.isSelectable, action.editedResourceId, action.setActive, action.param));

  const currentState = yield select((state: IState) => state);
  const clientArea = DocumentUtils.calculateClientArea(currentState);
  yield put(ReportActions.clientAreaChanged(fromJS(clientArea)));
}

function* createNewBand(action: any) {
  yield put(ReportActions.addBand(action.band, action.index));
  yield call(DocumentUtils.updateClientArea);
}

function* createNewPart(action: any) {
  const currentState = yield select((state: IState) => state);
  const part: Map<string, any> = action.part;
  const path: string[] = action.path;
  if (!currentState.hasIn(['report', 'model', ...path])) {
    //need to create the section
    let section: Map<string, any> = Map<string, any>(initializeMapWithObject({
      type: ElementTypes.SECTION_GROUP,
      elementIds: List<string>(),
    }));
    const sectionNames = path[1].split('_', 2);
    if (sectionNames[0] === "groupHeaderSection") {
      const sectionName = "groupHeaderSection_" + sectionNames[1];
      section = section.set('sectionType', BandTypes.BAND_GROUP_HEADER);
      section = section.set('id', sectionName);
    } else if (sectionNames[0] === "groupFooterSection") {
      const sectionName = "groupFooterSection_" + sectionNames[1];
      section = section.set('sectionType', BandTypes.BAND_GROUP_FOOTER);
      section = section.set('id', sectionName);
    }
    section = section.set('groupName', sectionNames[1]);
    yield put(ReportActions.setObjectProperties(['model', 'bookSections', section.get('id')], section));
  }
  yield put(ReportActions.addElement(part, path.join('/')));
}

function* fetchResource(queueChannel, action) {
  yield put(queueChannel, action);
}

export function* fetchResourceSaga() {
  const QUEUE_CONCURRENT = 1;
  const {watcher, queueChannel} = yield createConcurrentTaskQueue(checkResourceExistence, QUEUE_CONCURRENT);
  yield fork(watcher);
  yield takeEvery(ReportActions.Actions.CHECK_RESOURCE_ACTION, fetchResource , queueChannel);
}


/**
 * Binding of event type to the proper flow control for saga.
 */
export const modelSagas = [
  takeEvery(ReportActions.Actions.CREATE_NEW_DOCUMENT_ACTION, createNewDocument),
  takeEvery(ReportActions.Actions.OPEN_DOCUMENT_ACTION, openDocument),
  takeEvery(ReportActions.Actions.LOAD_COMBOS_ACTION, loadComboValues),
  takeEvery(ReportActions.Actions.SET_PROPERTIES_REFRESH_ACTION, setPropertiesRefreshAction),
  takeEvery(ReportActions.Actions.DELETE_PROPERTY_REFRESH_ACTION, deletePropertyRefreshAction),
  takeEvery(ReportActions.Actions.CREATE_NEW_BAND_ACTION, createNewBand),
  takeEvery(ReportActions.Actions.CREATE_NEW_PART_ACTION, createNewPart),
  takeEvery(ReportActions.Actions.CREATE_EDITOR, createNewEditor),
]