/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { AceEditorWrapper, FormControlledTextField, PopoverControl } from '@jss/js-common';
import { getKey, getNormalizedPath, getPath, reg, UiProperty, UiPropertyProps } from '../UiProperty';
import { APDescriptor } from '../APDescriptor';
import { PropertyEvaluationTimeEnum } from '../../types/enums/PropertyEvaluationTimeEnum';
import { ExpressionTypeEnum } from '../../types/enums/ExpressionTypeEnum';
import i18n from '../../../../../i18n';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const TYPEID = 'expression';
reg(TYPEID, (mc) => { return <UPExpression mcontext={mc} />; });
export class PExpression extends APDescriptor {
    public static readonly type: string = TYPEID;
    default?: string;
    public constructor(init: Partial<PExpression>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
export const MPROPERTIESEXPRESSION_TYPEID = 'MPropertiesExpression';
reg(MPROPERTIESEXPRESSION_TYPEID, (mc) => {
    mc.descriptor.layouts = [ExpressionTypeEnum];
    mc.descriptor.type = MPROPERTIESEXPRESSION_TYPEID;
    return <UPExpression mcontext={mc} />;
});

export const MDSPROPERTYEXPRESSION_TYPEID = 'MDsPropertyExpression';
reg(MDSPROPERTYEXPRESSION_TYPEID, (mc) => {
    mc.descriptor.layouts = [PropertyEvaluationTimeEnum, ExpressionTypeEnum];
    mc.descriptor.type = MDSPROPERTYEXPRESSION_TYPEID;
    return <UPExpression mcontext={mc} />;
});

export interface IUPExpression {
    additionalIcon?: React.ReactElement,
}

export class UPExpression extends React.Component<UiPropertyProps & IUPExpression> {

    constructor(props: UiPropertyProps) {
        super(props);
    }

    private getPathAndValue = (): { path: any[], value: any } => {
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p);
        return { path: p, value: v };
    }

    private onExpressionChange = (value: string) => {
        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: value });
        });
    }

    private getMode = (language) => {
        if (language === 'java' || language === 'groovy' || language === 'javascript') {
            return language;
        }
        return 'text';
    }

    private getPopoverContent = (handleClose: () => void) => {
        const pv = this.getPathAndValue();
        const language = this.props.mcontext.model.get('language', 'java');
        const props = {
            mode: this.getMode(language),
            wrapEnabled: true,
            enableBasicAutocompletion: true,
            tabSize: 2,
        }
        return <div style={{ padding: 10, height: 200, width: 500, display: 'flex', flexDirection: 'column' }} contentEditable={true} suppressContentEditableWarning={true}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <label className="jr-MuiFormLabel-root jr-MuiInputLabel-root jr-mInput-label mui  jr-MuiInputLabel-formControl jr-MuiInputLabel-outlined" data-shrink="false">
                    {`${i18n.t(this.props.mcontext.descriptor.label)} ${language}`}
                </label>
                <IconButton aria-label="close" onClick={() => { handleClose(); }} size='small'>
                    <CloseIcon />
                </IconButton>
            </div>
            <AceEditorWrapper aceProps={props} value={pv.value} onValueChange={this.onExpressionChange} readonly={this.props.mcontext.descriptor.readonly}/>
            {this.props.mcontext.descriptor.layouts?.map((key, index) => {
                key.readonly = this.props.mcontext.descriptor.readonly;
                return <UiProperty key={key.id ? key.id + '.' + index : 'div.' + key.type + '.' + index} mcontext={{ ...this.props.mcontext, descriptor: key }} />
            })}

        </div>;
    }

    private getControl = (handleClick: (event) => void) => {
        return <div style={{ display: 'flex' }} onClick={handleClick}><MoreHorizIcon style={{ cursor: 'pointer' }} /></div>;
    }

    render() {
        const pv = this.getPathAndValue();
        const d = this.props.mcontext.descriptor as PExpression;
        return <FormControlledTextField
            className={this.props.mcontext.descriptor.className}
            key={getKey(pv.path)}
            onTextChange={this.onNameChange}
            label={i18n.t(this.props.mcontext.descriptor.label)}
            title={i18n.t(this.props.mcontext.descriptor.description)}
            // defaultValue={this.props.placeholder}
            inline={true}
            size={'small'}
            value={pv.value ? pv.value : ''}
            type={'text'}
            InputLabelProps={d.deprecated ? { className: 'deprecatedProperty' } : undefined}
            iconInnerButtons={[<PopoverControl id="editExpressionButton" key="editExpressionButton" getContent={this.getPopoverContent} getControl={this.getControl} />]}
            iconButtons={this.props.additionalIcon ? [this.props.additionalIcon] : undefined}
            disabled={this.props.mcontext.descriptor.readonly}
            inputProps={{
                style: {paddingRight: 27},
            }}
            InputProps={
                {
                    title: pv.value,
                    readOnly: false,
                }
            }
        />
    }

    public onNameChange = (value: string | undefined) => {
        this.props.mcontext.elements.forEach(key => {
            if (!value || value.trim().length === 0) {
                if (this.props.mcontext.descriptor.id) {
                    const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
                    const path = getNormalizedPath(p);
                    this.props.mcontext.deleteElement(path);
                } else {
                    this.props.mcontext.deleteElement(key.path);
                }
            } else {
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: value });
            }
        });
    }
}
