/*
* Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
* Licensed under commercial Jaspersoft Subscription License Agreement
*/

import { List, Map } from 'immutable';
import _ from 'lodash';
import * as React from 'react';
import { getPath, reg } from '../../ui/UiProperty';

import { IRepositoryItemDescriptor } from '@jss/js-rest-api';
import { PTable, UPTable } from './UPTable';
import { connect } from 'react-redux';
import { IState } from '../../../../../reducers';
import * as ReportActions from '../../../../../actions/reportActions';

export interface IConnectedUPTable {
    descriptor?: IRepositoryItemDescriptor | undefined;
    deletePropertyRefreshAction?: (path: (string | number)[], reportPath: string) => void;
    setPropertiesRefreshAction?: (path: (string | number)[], value: any, reportPath: string) => void;
    deleteElement?: (path: string[]) => void;
    addProperty?: (path: string[], element) => void;
}

export class UPSortFieldTable extends UPTable {
    public addItem = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expanded'], true, false, true);

        const d = this.props.mcontext.descriptor as PTable;
        let def = {};
        if (d.default !== undefined) {
            if (d.default !== Object(d.default) || _.isString(d.default))
                def = d.default;
            else {
                def = { ...d.default };
                if (def['name']) {
                    const pth = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
                    const datasetPath = [...pth];
                    datasetPath.pop();

                    const dataset = this.props.mcontext.model.getIn(datasetPath) as Map<string, any>;
                    const usedNames = new Set<string>();
                    const sortFileds = dataset.get('sortFields', Map<string, any>()) as Map<string, any>;
                    sortFileds.forEach((value) => {
                        usedNames.add(value.get('name'));
                    });

                    const items = [];
                    const variables = dataset.get('variables');
                    if (variables){
                        variables.forEach((variable: Map<string, any>)  => {
                            const name = variable.get('name');
                            if (!usedNames.has(name)){
                                items.push(name);
                            }
                        });
                    }

                    if (items.length === 0){
                        const systemVariables = this.props.mcontext.rootModel.getIn(['cachedData', 'comboValues', 'systemVariables']) as List<Map<string, any>> | undefined;
                        if (systemVariables){
                            systemVariables.forEach((variable: Map<string, any>)  => {
                                const name = variable.get('name');
                                if (!usedNames.has(name)){
                                    items.push(name);
                                }
                            });
                        }
                    }

                    if (items.length === 0){
                        const parameters = dataset.get('parameters');
                        if (parameters){
                            parameters.forEach((parameter: Map<string, any>)  => {
                                const name = parameter.get('name');
                                if (!usedNames.has(name)){
                                    items.push(name);
                                }
                            });
                        }
                    }

                    if (items.length === 0){
                        const language = dataset.get('queryLanguage', 'sql');
                        const systemParameters = this.props.mcontext.rootModel.getIn(['cachedData', 'comboValues', 'systemParameters', language]) as List<Map<string, any>> | undefined;
                        if (systemParameters){
                            systemParameters.forEach((parameter: Map<string, any>)  => {
                                const name = parameter.get('name');
                                if (!usedNames.has(name)){
                                    items.push(name);
                                }
                            });
                        }
                    }

                    if (items.length > 0){
                        def['name'] = items[0];
                    } else {
                      return;
                    }
                }
            }
        }
        const p: string[] = ['model', ...getPath(d.id, this.props.mcontext.elements[0].path)];
        this.props.addProperty(p, def);
    }

}

const TYPEID = 'sortFieldTable';
export class PSortFieldTable extends PTable {
    default?: any;
    seachable?: boolean;
    expanded?: boolean = false;
    rowLabel?: string;
    rowLabelPaths?: string[];
    refreshCacheOnDelete?: boolean;
    refreshCacheOnAdd?: boolean;
    public constructor(init: Partial<PSortFieldTable>) {
        super(init);
        Object.assign(this, { ...init, type: TYPEID });
    }
}

const mapStateToProps = (state: IState) => {
    return {
        descriptor: state.getIn(['report', 'fileDescriptor']),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteElement: (path: string[]) => { dispatch(ReportActions.deleteElement(path)); },
        addProperty: (path: string[], element) => { dispatch(ReportActions.addProperty(path, element)); },
        setPropertiesRefreshAction: (path: (string | number)[], value: any, reportPath: string) => { dispatch(ReportActions.setPropertiesRefreshAction(path, value, reportPath)); },
        deletePropertyRefreshAction: (path: (string | number)[], reportPath: string) => { dispatch(ReportActions.deletePropertyRefreshAction(path, reportPath)); }
    };
}

const ConnectedTable = connect(mapStateToProps, mapDispatchToProps)(UPSortFieldTable as any) as any;
reg(TYPEID, (mc) => { return <ConnectedTable mcontext={mc} />; });
