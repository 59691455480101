/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const MeterShapeEnum = new PEnum({
    id: 'shape', label: 'MeterShape.label', default: 'chord', isEmptyUndefined: true,
    items: [
        { key: '', value: ''},
        { key: 'chord', value: 'MeterShape.chord'},
        { key: 'circle', value: 'MeterShape.circle'},
        { key: 'pie', value: 'MeterShape.pie'},
        { key: 'dial', value: 'MeterShape.dial'},
    ]
}); 
 
