/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { FGraphicElement } from "../common/MGraphicElement";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PExpression } from "../../ui/primitive/UPExpression";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { PConnectedTable } from "../../ui/tables/UPConnectedTable";
import { ICONS } from "@jss/js-common";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { FDatasetParameter, FDatasetRun, FReturnValue } from "../dataset/MDatasetRun";
import { PTableElement } from "../../ui/tables/UPTableElement";
import ParameterIcon from "../../../../../assets/icons/outline/parameters.svg";
import VariableIcon from "../../../../../assets/icons/outline/variables.svg";
import { FSubreportReturnValue } from "../elements/MSubreport";
import { PartEvaluationTimeEnum } from "../enums/EvaluationTimeEnum";
import { MSubreportPartCustom } from "./MSubreportPartCustom";

export const FSubreportPart = {
    isUsingCache: new PBoolean({ id: 'isUsingCache', label: 'Use Cache', canBeNull: true, default: 'true' }),
    partNameExpression: new PExpression({ id: 'partNameExpression', label: 'Part Name' }),
    subreportExpression: new PExpression({ id: 'subreportExpression', label: 'Subreport' }),
};


export const MSubreportPart: APDescriptor = {
    type: ElementTypes.SUBREPORT,
    label: 'Subreport',
    id: 'subreportPartPropertiesDescriptor',
    default: { width: 200, height: 200 },
    icon: ICONS.SUBREPORT_ICON,
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({
                label: 'Subreport', layouts: [FSubreportPart.partNameExpression, FSubreportPart.subreportExpression, FGraphicElement.printWhenExpression,
                    PartEvaluationTimeEnum,
                FSubreportPart.isUsingCache, MSubreportPartCustom,
                new PAccordeon({
                    id: 'datasource', label: 'Datasource', expanded: true,
                    layouts: [FDatasetRun.connectionExpression, FDatasetRun.dataSourceExpression]
                }), new PAccordeon({
                    id: 'Parameters', label: 'Set Parameters Values', description: 'Set value for parameters defined in the subreport dataset',
                    layouts: [FDatasetRun.parametersMapExpression, new PConnectedTable({
                        id: 'subreportParameters', label: 'Values', description: 'Set value for parameters defined in the subreport dataset', rowLabel: 'Parameter', rowLabelPaths: ['name'],
                        layouts: [new PTableElement({
                            label: 'Parameter',
                            icon: ParameterIcon,
                            anchorOrigin: { vertical: 'center', horizontal: 'right' },
                            transformOrigin: { vertical: 'center', horizontal: 'right' },
                            layouts: [FDatasetParameter.name, FDatasetParameter.expression]
                        })],
                        default: { name: 'Parameter', expression: '' }
                    })]
                }), new PConnectedTable({
                    id: 'returnValues', label: 'Update Main Dataset Variables', rowLabel: 'Return Value', rowLabelPaths: ['name'],
                    layouts: [new PTableElement({
                        label: 'Return Value',
                        icon: VariableIcon,
                        anchorOrigin: { vertical: 'center', horizontal: 'right' },
                        transformOrigin: { vertical: 'center', horizontal: 'right' },
                        layouts: [FSubreportReturnValue.subreportVariable, FReturnValue.toVariable, FReturnValue.calculation, FReturnValue.incrementerFactoryClassName]
                    })],
                    default: { calculation: 'Sum' }
                })]
            })]
    })]
};

export const MSectionDetail: APDescriptor = { type: 'SectionDetail', id: 'detailPartPropertiesDescriptor', layouts: [] };

export const MSectionGroup: APDescriptor = { type: 'SectionGroup', id: 'groupPartPropertiesDescriptor', layouts: [] };
