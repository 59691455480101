/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const CrosstabPercentageEnum = new PEnum({
    id: 'percentageOfType', label: 'CrosstabPercentage.label', default: 'None',
    items: [
        { key: 'None', value: 'CrosstabPercentage.None' },
        { key: 'GrandTotal', value: 'CrosstabPercentage.GrandTotal' } 
    ]
});

