/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LOGGED_USERS_KEY } from "../../store/reducers/persistentReducer";


export function RequireLogin({ redirectTo }: { redirectTo: string }) {
    const storedData = useSelector((state: any) => state.persistentReducer.storedData);
    const isLoggedIn = storedData.hasIn([LOGGED_USERS_KEY, 'userId']);
    const location = useLocation();

    if (!isLoggedIn) {
        return <Navigate to={redirectTo} state={{ from: location }}/>
    }

    return <Outlet />;
}

export function RedirectLoggedIn({ to }: { to: string}) {
    const storedData = useSelector((state: any) => state.persistentReducer.storedData);
    const isLoggedIn = storedData.hasIn([LOGGED_USERS_KEY, 'userId']);

    if (!isLoggedIn) {
        return <Outlet />;
    }

    return <Navigate to={to} />
}

export function NothingHere() {
    return <div>Nothing here!</div>
}
