/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import ResourcePicker, { IResourcePicker } from './ResourcePicker';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import '../../assets/uxpl/css/ResourcePicker.css';
import { IRepositoryItemDescriptor, RESOURCE_TYPE } from '@jss/js-rest-api';
import { IResultDialogProp, Typography } from '@jss/js-common';
import { RunContainer } from '../../RunContainer';
import { DialogActions, DialogContentText } from '@material-ui/core';
import SearchableResourcePicker from './SearchableResourcePicker';
import i18n from '../../i18n';

export interface IResourcePickerDialog extends IResourcePicker, IResultDialogProp {
    title?: string;
    allowSearch?: boolean,
    allowedMimes?: string[],
    fileNameLabel?: string;
    confirmButtonLabel?: string;
    onClose?: () => void;
    onFileSelected?: (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null, fileName: string) => void;
}

export interface IResourcePickerDialogState {
    folderPath: IRepositoryItemDescriptor[];
    file: IRepositoryItemDescriptor | null;
    fileName: string | undefined;
    folderContent: IRepositoryItemDescriptor[];
    showOverwriteDialog: boolean;
}

export class ResourcePickerDialog extends React.Component<IResourcePickerDialog, IResourcePickerDialogState> {

    public state: IResourcePickerDialogState = {
        folderPath: [],
        file: null,
        fileName: undefined,
        folderContent: [],
        showOverwriteDialog: false,
    };

    render() {
        const canFinish = this.canFinish();
        const confirmButtonLabel = this.props.confirmButtonLabel ? this.props.confirmButtonLabel : (this.props.mode === 'save' ? 'Save' : 'Open');
        const picker = this.props.allowSearch ?
            <SearchableResourcePicker
                mode={this.props.mode}
                defaultPath={this.props.defaultPath}
                defaultFileName={this.props.defaultFileName}
                onFileSelected={this.onInternalFileSelected}
                fileNameLabel={this.props.fileNameLabel}
                allowedMimes={this.props.allowedMimes} /> :
            <ResourcePicker
                mode={this.props.mode}
                defaultPath={this.props.defaultPath}
                defaultFileName={this.props.defaultFileName}
                onFileSelected={this.onInternalFileSelected}
                fileNameLabel={this.props.fileNameLabel} />;
        return <Dialog
            open={true}
            aria-labelledby="simple-dialog-title"
            fullWidth={true} maxWidth={'md'}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && this.props.onClose) {
                    this.props.onClose();
                }
            }}>
            <DialogTitle id="customized-dialog-title" style={{ paddingTop: '0px', paddingBottom: '0px', borderBottom: 'solid lightgray 1px' }} >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>
                    <IconButton aria-label="close" onClick={this.onCancel} size='small'>
                        <CloseIcon />
                    </IconButton>
                    <Typography style={{ marginLeft: '5px', fontWeight: 'bolder', fontSize: '2em' }}>{this.props.title}</Typography>
                </div>
            </DialogTitle>
            <RunContainer>
                <DialogContent className="jrws-resource-picker-no-padding">
                    {picker}
                </DialogContent>
                <DialogActions style={{ borderTop: 'solid lightgray 1px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Button onClick={this.onCancel}>{i18n.t('common.actions.button.cancel')}</Button>
                        <Button disabled={!canFinish} onClick={this.onConfirm}>{confirmButtonLabel}</Button>
                    </div>
                </DialogActions>
            </RunContainer>
            {this.getOverwriteDialog()}
        </Dialog>
    }

    getOverwriteDialog = () => {
        return <Dialog
            open={this.state.showOverwriteDialog}
            onClose={this.onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {i18n.t('resource.ovewrite.message')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onOverwriteCancel} size="large" color="secondary" variant="contained">{i18n.t('common.actions.button.cancel')}</Button>
                <Button onClick={this.onOverwriteConfirm} size="large" color="primary" variant="contained">{i18n.t('common.actions.button.yes')}</Button>
            </DialogActions>
        </Dialog>;
    }

    private onOverwriteConfirm = () => {
        this.setState({ showOverwriteDialog: false }, () => {
            const fileName = this.state.fileName ? this.state.fileName : this.props.defaultFileName;
            this.props.onFileSelected(this.state.folderPath, this.state.file, fileName);
            this.props.onClose?.();
            this.props.onEnd({ canceled: false });
        });
    }

    private onOverwriteCancel = () => {
        this.setState({ showOverwriteDialog: false, file: null });
    }

    private onConfirm = () => {
        if (this.canFinish() && this.props.onFileSelected) {
            const fileName = this.state.fileName ? this.state.fileName : this.props.defaultFileName;
            //check if the file already exist only if we are not in open mode
            const alreadyExist = this.props.mode !== 'open' && this.props.mode !== 'open_folder' && this.state.folderContent.find((siblingDescriptor) => { return siblingDescriptor.name === fileName; });
            if (alreadyExist) {
                this.setState({ showOverwriteDialog: true, file: alreadyExist });
            } else {
                this.props.onFileSelected(this.state.folderPath, this.state.file, fileName);
                this.props.onClose?.();
                this.props.onEnd({ canceled: false });
            }
        }
    }

    private onCancel = () => {
        this.props.onEnd({ canceled: true });
        this.props.onClose?.();
    }

    private canFinish = () => {
        const fileName = this.state.fileName ? this.state.fileName : this.props.defaultFileName;
        const folderAlreadyExist = this.props.mode !== 'open' && this.props.mode !== 'open_folder' && this.state.folderContent.find((siblingDescriptor) => {
            return siblingDescriptor.name === fileName && (siblingDescriptor.type === RESOURCE_TYPE.CONTAINER || siblingDescriptor.type === RESOURCE_TYPE.FOLDER);
        });
        return !(this.isNullOrWhiteSpace(fileName)) && !folderAlreadyExist;
    }

    private isNullOrWhiteSpace = (value: string | null) => {

        if (this.isNullOrUndefined(value)) {
            return true;
        }

        return value.replace(/\s/g, '').length === 0;
    }


    private isNullOrUndefined = (value: any | undefined) => {

        if (value === null || value === undefined) {
            return true;
        }

        return false;
    }

    private onInternalFileSelected = (folderPath: IRepositoryItemDescriptor[] | undefined, file: IRepositoryItemDescriptor | null, fileName: string, folderContent: IRepositoryItemDescriptor[]) => {
        this.setState({ folderPath: folderPath ? folderPath : [], file: file, fileName: fileName, folderContent: folderContent });
    }
}