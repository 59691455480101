/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const OrientationTypeEnum = new PEnum({
    id: 'orientation', label: 'OrientationType.label', default: 'Portrait',
    items: [
        { key: 'Portrait', value: 'OrientationType.Portrait' },
        { key: 'Landscape', value: 'OrientationType.Landscape' }
    ]
}); 

