/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const ScatterChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath>
          <path fill="none" d="M0 0H506V700H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M39 10H545V710H39z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" d="M39.5 10v700M159.5 10v700M280.5 10v700M400.5 10v700M521.5 10v700" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M39 710.5h506M39 632.5h506M39 554.5h506M39 477.5h506M39 399.5h506M39 321.5h506M39 243.5h506M39 166.5h506M39 88.5h506M39 9.5h506" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M39 10H545V710H39z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" stroke="#ccd6eb" d="M39.5 710v10M159.5 710v10M280.5 710v10M400.5 710v10M521.5 710v10" className="highcharts-tick" />
       <text x="292" y="748" fill="#666" className="highcharts-axis-title" color="#666" data-z-index="7" textAnchor="middle">
          <tspan>Max Freight</tspan>
       </text>
       <path fill="none" stroke="#ccd6eb" d="M39 710.5h506" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M39 10v700" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-markers highcharts-series-0 highcharts-scatter-series highcharts-color-0 highcharts-tracker" data-z-index="0.1">
          <path fill="#7cb5ec" d="M95 467a4 4 0 110-.004zM159 622a4 4 0 110-.004zM133 583a4 4 0 110-.004zM23 622a4 4 0 110-.004zM122 506a4 4 0 110-.004zM15 583a4 4 0 110-.004z" className="highcharts-point highcharts-color-0" transform="translate(39 10)" />
          <path fill="#7cb5ec" d="M18 622a4 4 0 110-.004z" className="highcharts-point highcharts-color-0" transform="translate(39 10)" />
       </g>
       <g className="highcharts-markers highcharts-series-1 highcharts-scatter-series highcharts-color-1 highcharts-tracker" data-z-index="0.1">
          <path fill="#434348" d="M427 463l4 4-4 4-4-4zM82 540l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(39 10)" />
          <path fill="#434348" strokeWidth="0" d="M328 34.889l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(39 10)" />
          <path fill="#434348" d="M57 618l4 4-4 4-4-4zM67 579l4 4-4 4-4-4zM70 463l4 4-4 4-4-4zM23 618l4 4-4 4-4-4zM44 618l4 4-4 4-4-4zM143 502l4 4-4 4-4-4zM31 579l4 4-4 4-4-4zM84 385l4 4-4 4-4-4zM7 618l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(39 10)" />
       </g>
       <g className="highcharts-markers highcharts-series-2 highcharts-scatter-series highcharts-color-2 highcharts-tracker" data-z-index="0.1">
          <path fill="#90ed7d" d="M165 502h8v8h-8zM99 540h8v8h-8zM497 268h8v8h-8zM5 657h8v8H5zM430 579h8v8h-8z" className="highcharts-point highcharts-color-2" transform="translate(39 10)" />
          <path fill="#90ed7d" d="M0 657h8v8H0zM18 618h8v8h-8zM3 540h8v8H3zM51 657h8v8h-8zM361 540h8v8h-8z" className="highcharts-point highcharts-color-2" transform="translate(39 10)" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(178 762)">
       <rect width="199" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-scatter-series highcharts-color-0 highcharts-series-0" data-z-index="1">
             <path fill="none" d="M0 11h12" className="highcharts-graph" transform="translate(8 3)" />
             <path fill="#7cb5ec" d="M10 11a4 4 0 110-.004z" className="highcharts-point" transform="translate(8 3)" />
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold" transform="translate(8 3)">
                <tspan>1996</tspan>
             </text>
          </g>
          <g className="highcharts-legend-item highcharts-scatter-series highcharts-color-1 highcharts-series-1" data-z-index="1">
             <path fill="none" d="M0 11h12" className="highcharts-graph" transform="translate(75.672 3)" />
             <path fill="#434348" d="M6 7l4 4-4 4-4-4z" className="highcharts-point" transform="translate(75.672 3)" />
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold" transform="translate(75.672 3)">
                <tspan>1997</tspan>
             </text>
          </g>
          <g className="highcharts-legend-item highcharts-scatter-series highcharts-color-2 highcharts-series-2" data-z-index="1">
             <path fill="none" d="M0 11h12" className="highcharts-graph" transform="translate(143.344 3)" />
             <path fill="#90ed7d" d="M2 7h8v8H2z" className="highcharts-point" transform="translate(143.344 3)" />
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold" transform="translate(143.344 3)">
                <tspan>1998</tspan>
             </text>
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="middle">
       <text x="39.745" y="729">0</text>
       <text x="160.189" y="729">200</text>
       <text x="280.633" y="729">400</text>
       <text x="401.077" y="729">600</text>
       <text x="521.521" y="729">800</text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="24" y="715">0</text>
       <text x="24" y="637">2</text>
       <text x="24" y="559">4</text>
       <text x="24" y="481">6</text>
       <text x="24" y="403">8</text>
       <text x="24" y="326">10</text>
       <text x="24" y="248">12</text>
       <text x="24" y="170">14</text>
       <text x="24" y="92">16</text>
       <text x="24" y="15">18</text>
    </g>
 </svg>)};

export default ScatterChartIcon;