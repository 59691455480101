/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MGraphicElement } from "../common/MGraphicElement";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { EvaluationTimeEnum } from "../enums/EvaluationTimeEnum";
import { PString } from "../../ui/primitive/UPString";
import { PExpression } from "../../ui/primitive/UPExpression";
import { ICONS } from "@jss/js-common";
import { MGenericElementCustom } from "./MGenericElementCustom";
import { PElementParametersTable } from "../../ui/tables/UPElementParametersTable";

export const FGenericElement = {
    evaluationTime: EvaluationTimeEnum,
    name: new PString({ id: 'name', label: 'Name' }),
    evaluationGroupName: new PString({ id: 'evaluationGroupName', label: 'Evaluation Group Name' }),
    namespace: new PString({ id: 'namespace', label: 'Namespace' })
};
export const FGenericElementParameter = {
    name: new PString({ id: 'name', label: 'Name', canBeNull: false }),
    expression: new PExpression({ id: 'expression', label: 'Expression' }),
};

export const MGenericElement: APDescriptor = {
    type: ElementTypes.GENERIC_ELEMENT,
    label: 'Generic',
    id: 'genericElementProeprtiesDescriptor',
    icon: ICONS.GENERIC_ICON,
    default: { width: 100, height: 30 },
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MGraphicElement.layouts, MGenericElementCustom] }),
            new PDiv({
                label: 'Generic Element', layouts: [
                    FGenericElement.name, FGenericElement.namespace,
                    FGenericElement.evaluationTime, FGenericElement.evaluationGroupName,
                    new PElementParametersTable({
                        id: "genericElementParameters", label: 'Parameters',rowLabel: 'Parameter', rowLabelPaths:['name'],
                        default: { name: 'Group' }
                    }),
                ]
            })
        ]
    })]
};
