/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { PopoverControl } from "../button/PopoverControl";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import { IMenuItem } from "./ToolbarProvider";

interface IToolButton {
    isDisabled?: boolean,
    onClick?: () => void,
    label: string,
    height: number | string,
    backgroundColor: string,
    content?: IMenuItem[];
}

interface IHoverToolButtonState {
    isButtonHovered: boolean,
    isMenuHovered: boolean,
}

export class HoverToolButton extends React.Component<IToolButton, IHoverToolButtonState> {

    state = {
        isButtonHovered: false,
        isMenuHovered: false,
    }

    private onClick = () => {
        if (!this.props.isDisabled && this.props.onClick) {
            this.props.onClick();
        }
    }

    private getPopoverContent = (handleClose: () => void) => {
        return <div onMouseLeave={() => { this.onContentExit(handleClose)}} onMouseEnter={this.onContentEnter}>
            {this.props.content?.map((element, index) => {
                if (element.getComponent) {
                    return element.getComponent(handleClose);
                } else if (element === null) {
                    return <Divider key={index} />;
                } else {
                    const isEnabled = element.isEnabled === undefined || element.isEnabled;
                    if (element.icon) {
                        return <MenuItem key={index} onClick={() => {
                            if (isEnabled) {
                                handleClose();
                                element.onClick?.();
                            }
                        }}>
                            <ListItemIcon>
                                {element.icon}
                            </ListItemIcon>
                            <Typography variant="inherit" style={{ color: isEnabled ? undefined : 'lightgray' }}>
                                {element.label}
                            </Typography>
                        </MenuItem>
                    } else {
                        return <MenuItem key={index} onClick={() => {
                            if (isEnabled) {
                                handleClose();
                                element.onClick?.();
                            }
                        }}>
                            <Typography variant="inherit" style={{ color: isEnabled ? undefined : 'lightgray' }}>
                                {element.label}
                            </Typography>
                        </MenuItem>
                    }
                }
            })}
        </div>
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onButtonEnter = (clickEvent: any, handleClick: (clickEvent: any) => void, handleClose: () => void) => {
        console.log('button enter');
        handleClick(clickEvent);
        this.setState({
            isButtonHovered: true,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onButtonExit = (handleClick: (clickEvent: any) => void, handleClose: () => void) => {
        if (!this.state.isMenuHovered){
            this.setState({
                isButtonHovered: false,
            }, () => {
                console.log('button colose');
               // handleClose();
            });
        } else {
            this.setState({
                isButtonHovered: false,
            });
        }
    }

    onContentEnter = () => {
        console.log('content enter');
        this.setState({
            isMenuHovered: true,
        });
    }

    onContentExit = (handleClose: () => void) => {
        console.log('content exit');
        if (!this.state.isButtonHovered){
            this.setState({
                isMenuHovered: false,
            }, () => {
                console.log('conent close');
                handleClose();
            });
        } else {
            this.setState({
                isMenuHovered: false,
            });
        }
    }


    public getPopoverControl = (handleClick: (clickEvent: any) => void, handleClose: () => void) => {
        return <ArrowDropDownIcon style={{ width: 15, cursor: 'pointer', height: this.props.height }} 
                    onMouseEnter={(event) => this.onButtonEnter(event, handleClick, handleClose)} onMouseLeave={() => this.onButtonExit(handleClick, handleClose)}/>
    }

    public getPopoverMainControl = (handleClick: (clickEvent: any) => void) => {
        return <div style={{
            width: 24,
            height: this.props.height,
            display: 'flex',
            cursor: this.props.isDisabled ? 'default' : 'pointer',
            justifyContent: 'center',
            alignItems: 'center'
        }} onClick={handleClick}>
            {this.props.children}
        </div>;
    }

    public render() {
        let dropDownButton;
        if (this.props.content) {
            dropDownButton = <PopoverControl id={'popoverAction'} getContent={this.getPopoverContent} getControl={this.getPopoverControl} />
        }
        let mainControl;
        if (!this.props.onClick && this.props.content) {
            mainControl = <PopoverControl id={'popoverAction'} getContent={this.getPopoverContent} getControl={this.getPopoverMainControl} />
        } else {
            mainControl = <div style={{
                width: 24,
                height: this.props.height,
                display: 'flex',
                cursor: this.props.isDisabled ? 'default' : 'pointer',
                justifyContent: 'center',
                alignItems: 'center'
            }} onClick={this.onClick}>
                {this.props.children}
            </div>
        }
        return <div title={this.props.label} style={{
            display: 'flex',
            width: '39px',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            background: this.props.backgroundColor
        }}>
            {mainControl}
            {dropDownButton}
        </div>
    }
}