/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import {
    CommandHandler, CommandRegistry, i18n, IconButton, IconWrapper,
    IEditor, IMenuItem, IResourceInfo, IResourceInfoOptions, IResourceListener, IResourceListenerManager, IToolbarContributor, IToolBarItemOptions, IToolbarProvider, MenuDivider, TextField, ToolBar, ToolButton
} from '@jss/js-common';
import { ResourceInfoDialog, ResourcePickerDialog, RunContext } from '@jss/js-repository';
import { IRepositoryItemDescriptor, isReadOnly, MIME_TYPES, RepositoryApi, RESOURCE_TYPE } from '@jss/js-rest-api';
import { ListItemIcon, ListItemText, MenuItem, Popover } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import PublishIcon from '@material-ui/icons/Publish';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { fromJS, List as ImmutableList, Map } from 'immutable';
import { Base64 } from 'js-base64';
import * as React from 'react';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import format from 'xml-formatter';
import * as ReportActions from '../../actions/reportActions';
import { IState } from '../../reducers';
import { getEditorIndex } from '../../reducers/report/reportReducer';
import { PAGE_HORIZONTAL_MARGIN_MIN, PAGE_VERTICAL_OFFSET } from '../../sagas/report/designer/documentUtils';
import { createDefaultDocument } from '../../sagas/report/document/documentFactory';
import { EXIT_REPORT_DESIGNER, REPORT_EDITOR_DIRTY, REPORT_SAVED, REPORT_SAVED_AS, SAVE_REPORT, UPDATED_DESCRIPTOR } from '../../types/commandIds';
import { IRectangle, ISize } from '../../types/geometry';
import { uuidv4 } from '../../utils/uuidGenerator';
import jrxmlToJs from '../common/JrxmlModel/reader/JrxmlReader';
import jsToJrxml from '../common/JrxmlModel/writer/JrxmlWriter';
import CrosstabDesigner from './CrosstabDesigner';
import Designer from './Designer';
import ListDesigner from './ListDesigner';
import QueryEditor from './QueryEditor/QueryEditor';
import TableDesigner from './TableDesigner';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { WebfontLoader } from "./WebfontLoader";
import { ElementTypes } from '../../sagas/report/document/elementTypes';

import '../../assets/uxpl/css/ReportViewer.css';
import { LanguageContext } from './LanguageContext';

function buildFileSelector(handleFileChange: (e) => void, acceptedFileTypes: string) {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', acceptedFileTypes);
    fileSelector.onchange = handleFileChange;
    fileSelector.onclick = function () {
        (this as any).value = null;
    };
    return fileSelector;
}

interface IReportDesignerProps {
    resourceListenerManagers: IResourceListenerManager[],
    commandRegistry: CommandRegistry,
    clientArea: IRectangle,
    viewport: ISize,
    model: any;
    zoom: number;
    undoRedo: Map<string, any>,
    isDirty: boolean,
    report?: Map<string, any>;
    subeditors?: ImmutableList<Map<string, any>>;
    currentEditorIndex?: number | undefined;
    openDescriptor?: IRepositoryItemDescriptor | undefined;
    repositoryPath?: IRepositoryItemDescriptor[],
    language?: string;
    openDocumentAction?: (model: any, descriptor: IRepositoryItemDescriptor, path: IRepositoryItemDescriptor[], parameters: Map<string, any> | undefined, options: IResourceInfoOptions) => void,
    setDescriptor: (descriptor: IRepositoryItemDescriptor, path: IRepositoryItemDescriptor[]) => void,
    loadCombosAction?: (path: string) => void,
    viewportChanged?: (viewport: any) => void,
    undo: () => void;
    redo: () => void;
    onDecrementZoom: () => void;
    onIncrementZoom: () => void;
    setZoom: (val: number) => void;
    setDirty: (isDirty: boolean) => void;
    setCurrentEditor: (editorIndex: number | undefined) => void;
}

interface IReportDesignerState {
    zoomMenuOpen: null | EventTarget,
    toolbarData: IToolbarProvider | undefined,
    defaultFileName: string,
}

export interface IReportDesigner {
    resourceListenerId: string,
    save: () => void,
    undo: () => void,
    redo: () => void,
}

class ReportDesigner extends React.Component<IReportDesignerProps, IReportDesignerState> implements IReportDesigner, IEditor {

    private queryEditor: React.RefObject<any> = React.createRef<IToolbarContributor>();

    public resourceListenerId = '';

    protected fileSelector: HTMLInputElement;

    public state = {
        zoomMenuOpen: null,
        toolbarData: undefined,
        defaultFileName: 'My Report',
    };

    componentDidMount() {
        this.resourceListenerId = uuidv4();
        this.fileSelector = buildFileSelector(this.onFileSelected, '.jrxml');
        const resourceListener: IResourceListener = {
            onResourceOpen: (resource: IResourceInfo) => {
                if (this.props.openDocumentAction) {
                    const descriptor = resource.resource;
                    const pa = '/' + RepositoryApi.getAPath(descriptor);
                    if (resource.content) {
                        const jsModel = jrxmlToJs(resource.content);
                        this.props.openDocumentAction(jsModel, descriptor, resource.path, resource.parameters, resource.options);
                        if (resource.isResourceDirty !== undefined) {
                            this.props.setDirty(resource.isResourceDirty);
                        }
                        if (!resource.options || !resource.options.keepCache) {
                            //load the fonts
                            this.props.loadCombosAction(pa);
                        }
                    } else {
                        this.props.openDocumentAction(createDefaultDocument(), descriptor, resource.path, resource.parameters, resource.options);
                        if (resource.isResourceDirty !== undefined) {
                            this.props.setDirty(resource.isResourceDirty);
                        }
                        this.props.loadCombosAction(pa);
                    }
                    this.setState({
                        defaultFileName: resource.resource.name,
                    });
                }
            }
        }
        this.props.resourceListenerManagers.forEach(manager => {
            manager.addResourceListener(this.resourceListenerId, resourceListener);
        });
        const getUndo = (designer: ReportDesigner): CommandHandler => {
            return {
                execute() {
                    designer.undo();
                },
                isEnabled() {
                    return true;
                }
            }
        }
        const getRedo = (designer: ReportDesigner): CommandHandler => {
            return {
                execute() {
                    designer.redo();
                },
                isEnabled() {
                    return true;
                }
            }
        }
        const getZoomIn = (designer: ReportDesigner): CommandHandler => {
            return {
                execute() {
                    designer.zoomIn();
                },
                isEnabled() {
                    return true;
                }
            }
        }
        const getZoomOut = (designer: ReportDesigner): CommandHandler => {
            return {
                execute() {
                    designer.zoomOut();
                },
                isEnabled() {
                    return true;
                }
            }
        }
        this.props.commandRegistry.registerHandler('core.undo', getUndo(this));
        this.props.commandRegistry.registerHandler('core.redo', getRedo(this));
        this.props.commandRegistry.registerHandler('core.zoomIn', getZoomIn(this));
        this.props.commandRegistry.registerHandler('core.zoomOut', getZoomOut(this));
        this.props.commandRegistry.registerHandler(EXIT_REPORT_DESIGNER, {
            execute: () => {
                if (this.props.commandRegistry) {
                    this.props.commandRegistry.executeCommand(EXIT_REPORT_DESIGNER);
                }
            },
            isEnabled: () => {
                return true;
            }
        });
        this.props.commandRegistry.registerHandler(SAVE_REPORT, {
            execute: (params: Record<string, unknown>, callback?: () => void) => {
                this.doSave(false, callback);
            },
            isEnabled: () => {
                return true;
            }
        });
        this.provideToolbar();
    }

    componentDidUpdate = (previousProps: IReportDesignerProps, previousState: IReportDesignerState) => {
        let currentZoom;
        const currentEditorIndex = getEditorIndex(this.props.report);
        if (currentEditorIndex !== undefined) {
            currentZoom = this.props.report.getIn(['subeditors', currentEditorIndex, 'zoom']);
        } else {
            currentZoom = this.props.zoom;
        }

        let prevZoom;
        const prevEditorIndex = getEditorIndex(previousProps.report);
        if (prevEditorIndex !== undefined) {
            prevZoom = previousProps.report.getIn(['subeditors', prevEditorIndex, 'zoom']);
        } else {
            prevZoom = previousProps.zoom;
        }
        if (previousProps.openDescriptor !== this.props.openDescriptor || this.props.language !== previousProps.language) {
            this.provideToolbar();
        } else if (currentEditorIndex !== prevEditorIndex || this.props.subeditors.size !== previousProps.subeditors.size) {
            this.provideToolbar();
        } else if (previousProps.report.getIn(['currentEditor', 'editorType']) !== this.props.report.getIn(['currentEditor', 'editorType'])) {
            this.provideToolbar();
        } else if (this.props.isDirty !== previousProps.isDirty) {
            this.provideToolbar();
            this.props.commandRegistry.executeCommand(REPORT_EDITOR_DIRTY, { isDirty: this.props.isDirty });
        } else if (previousState.zoomMenuOpen !== this.state.zoomMenuOpen) {
            this.provideToolbar();
        } if (previousState.zoomMenuOpen !== this.state.zoomMenuOpen) {
            this.provideToolbar();
        } else if (currentZoom !== prevZoom) {
            this.provideToolbar();
        } else if (this.props.undoRedo !== previousProps.undoRedo) {
            this.provideToolbar();
        }
    }

    provideToolbar = () => {
        this.setState({ toolbarData: this.getToolbar() });
    }

    handleUploadPress = (e) => {
        e.preventDefault();
        this.fileSelector.click();
    }

    getToolbar = (): IToolbarProvider => {
        const currentEditorIndex = getEditorIndex(this.props.report);
        let isQueryEditorOpen = false;
        if (currentEditorIndex !== undefined) {
            isQueryEditorOpen = this.props.report.getIn(['subeditors', currentEditorIndex, 'type']) === 'datasetQueryEditor';
        }
        const isSaveDisabled = !this.props.isDirty || !this.props.openDescriptor || isReadOnly(this.props.openDescriptor);
        const getSaveSubmenu: IMenuItem[] = [
            {
                id: 'saveAction',
                label: i18n.t('common.actions.menu.save'),
                isEnabled: !isSaveDisabled,
                icon: <IconButton className='jr-mMenuDecorated jr-mMenu-list-item-icon jr-MuiListItemIcon-root jr-mIcon mui' style={{ minWidth: 0, backgroundColor: 'transparent' }} icon={'save'} size="small" disabled={isSaveDisabled} />,
                onClick: () => {
                    if (this.props.openDescriptor && this.props.openDescriptor.uuid) {
                        this.doSave(false);
                    } else {
                        //if the file is new force a new file to let the user choose the name
                        this.doSave(true);
                    }
                }
            },
            {
                id: 'saveAsAction',
                label: i18n.t('common.actions.menu.saveas'),
                icon: <SaveAltIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    this.doSave(true);
                }
            },
            {
                id: 'propertiesAction',
                label: i18n.t('common.actions.menu.resourceinfo'),
                icon: <InfoIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    this.showPropertisDialog();
                },
                isEnabled: this.props.openDescriptor !== undefined && this.props.openDescriptor.uuid !== undefined,
            }
        ]
        getSaveSubmenu.push(
            {
                // TODO -- Improve the structure of this MenuItem.
                // 1. avoid duplication for icon and text
                // 2. the fallback is the central part of the menu item
                // is not trigger the file choose opening
                label: i18n.t('common.actions.menu.upload'),
                id: 'importAction',
                getComponent: (handleClose: () => void) => {
                    return (
                        <MenuItem key={'importAction'} selected={false} className={"jr-mMenu-list-item mui"}>
                            <div style={{ display: 'flex' }} onClick={(event) => { handleClose(); this.handleUploadPress(event) }}>
                                <ListItemIcon className={"jr-mMenu-list-item-icon mui"}>
                                    <PublishIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />
                                </ListItemIcon>
                                <ListItemText primary={i18n.t('common.actions.menu.upload')} classes={{ primary: "jr-mText mui" }} />
                            </div>
                        </MenuItem>
                    );
                },
            }
        );
        getSaveSubmenu.push(
            {
                label: i18n.t('common.actions.menu.download'),
                icon: <GetAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                id: 'exportAction',
                onClick: () => {
                    this.exportReport();
                }
            }
        );
        getSaveSubmenu.push(
            {
                label: i18n.t('common.actions.menu.exit'),
                id: 'exitAction',
                icon: <ExitToAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    if (this.props.commandRegistry) {
                        this.props.commandRegistry.executeCommand(EXIT_REPORT_DESIGNER);
                    }
                }
            }
        );
        return {
            getMenus: () => {
                return []
            },
            getToolItems: () => {
                const items = [
                    {
                        id: 'saveToolaction',
                        render: (options?: IToolBarItemOptions) => {
                            //disable the save when the report is not dirty or we don't have write permission
                            const height = options && options.height ? options.height : 24;
                            const backgroundColor = options && options.background ? options.background : undefined;
                            return <ToolButton label={i18n.t('common.actions.menu.save')} isDisabled={isSaveDisabled} onClick={() => {
                                if (this.props.openDescriptor && this.props.openDescriptor.uuid) {
                                    this.doSave(false);
                                } else {
                                    //if the file is new force a new file to let the user choose the name
                                    this.doSave(true);
                                }
                            }} height={height} backgroundColor={backgroundColor} content={getSaveSubmenu}>
                                <IconButton style={{ backgroundColor: 'transparent' }} icon={'save'} size="small" disabled={isSaveDisabled} />
                            </ToolButton>
                        }
                    },
                    {
                        id: 'undoToolaction',
                        render: (options?: IToolBarItemOptions | undefined) => {
                            const isUndoDisabled = !this.props.undoRedo || this.props.undoRedo.get('past').count() === 0;
                            const height = options && options.height ? options.height : 24;
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                                <div title={i18n.t('common.actions.menu.undo')} style={{ height: height, cursor: isUndoDisabled ? 'default' : 'pointer', justifyContent: 'center', alignItems: 'center', marginRight: 15 }} onClick={!isUndoDisabled ? this.undo : undefined}>
                                    <IconButton style={{ backgroundColor: 'transparent' }} icon={'undo'} size="small" disabled={isUndoDisabled} />
                                </div>
                            </div>
                        }
                    },
                    {
                        id: 'redoToolaction',
                        render: (options?: IToolBarItemOptions) => {
                            const height = options && options.height ? options.height : 24;
                            const isRedoDisabled = !this.props.undoRedo || this.props.undoRedo.get('future').count() === 0;
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div title={i18n.t('common.actions.menu.redo')} style={{ height: height, cursor: isRedoDisabled ? 'default' : 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={!isRedoDisabled ? this.redo : undefined}>
                                    <IconButton icon={'redo'} size="small" disabled={isRedoDisabled} />
                                </div>
                                <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                            </div>;
                        }
                    },

                ];
                if (!isQueryEditorOpen) {
                    //add the zoom controls
                    let currentZoom;
                    const currentEditorIndex = getEditorIndex(this.props.report);
                    if (currentEditorIndex !== undefined) {
                        currentZoom = this.props.report.getIn(['subeditors', currentEditorIndex, 'zoom']);
                    } else {
                        currentZoom = this.props.zoom;
                    }
                    items.push(
                        {
                            id: 'zoomControls',
                            render: (options?: IToolBarItemOptions) => {
                                const height = options && options.height ? options.height : 24;
                                const backgroundColor = options && options.background ? options.background : undefined;
                                const zoomOutDisabled = currentZoom <= 0.1;
                                const zoomInDisabled = currentZoom >= 5;
                                return <div style={{ display: 'flex', marginRight: 10, justifyContent: 'space-between', alignItems: 'center', background: backgroundColor }}>
                                    <div style={{ width: 24, height: height, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={zoomOutDisabled ? undefined : this.props.onDecrementZoom} title={i18n.t('common.actions.menu.zoomout')}>
                                        <ZoomOutIcon color={zoomOutDisabled ? 'disabled' : 'action'} />
                                    </div>
                                    <div style={{ width: 24, height: 24, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={zoomInDisabled ? undefined : this.props.onIncrementZoom} title={i18n.t('common.actions.menu.zoomin')}>
                                        <ZoomInIcon color={zoomInDisabled ? 'disabled' : 'action'} />
                                    </div>
                                    <div style={{ height: 24 }} title={i18n.t('common.actions.menu.zoomlevel')}>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', position: 'relative' }}>
                                            <TextField style={{ padding: 0, margin: 0, borderWidth: 0 }} size="small" className='jr-mControlZoom mui' value={(currentZoom * 100).toFixed(0) + '%'} onBlurWithSetter={this.onZoomFocusLost} onKeyDownWithSetter={this.onZoomKeyDown} />
                                            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', position: 'absolute', right: 0, top: 0, bottom: 0, width: 30 }} onClick={(event) => { this.handleZoomMenuInteraction(event.currentTarget); }}>
                                                <svg key="arrowIcon" className="jr-MuiSvgIcon-root jr-MuiSelect-icon jr-MuiSelect-iconOutlined" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
                                            </div>
                                        </div>
                                        <Popover
                                            id={'zoomMenuPopover'}
                                            open={this.state.zoomMenuOpen !== null}
                                            anchorEl={this.state.zoomMenuOpen}
                                            onClose={() => { this.handleZoomMenuInteraction(null); }}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            {this.getZoomMenu()}
                                        </Popover>
                                    </div>
                                </div>
                            }
                        }
                    );

                    //add the opened editor selector
                    items.push({
                        id: 'editorSelector',
                        render: (options?: IToolBarItemOptions) => {
                            const height = options && options.height ? options.height : 24;
                            const backgroundColor = options && options.background ? options.background : undefined;
                            const selectedEditorIndex = this.props.currentEditorIndex;
                            const subeditiorsComponents = this.props.subeditors.toArray().map((subeditorInfo, index) => {
                                const isSelectable = subeditorInfo.get('isSelectable');
                                if (isSelectable) {
                                    const isSelected = index === selectedEditorIndex;
                                    const editedResourceId = subeditorInfo.get('editedResourceId').split('/')[1];
                                    let label;
                                    const editedElement = this.props.report.getIn(['model', 'elements', editedResourceId]) as Map<string, any>;
                                    if (editedElement) {
                                        label = editedElement.getIn(['properties', 'com.jaspersoft.studio.element.name', 'value']);
                                        if (!label || label.trim().length === 0) {
                                            label = subeditorInfo.get('label');
                                        }
                                    }
                                    return <div key={editedResourceId} style={{ marginLeft: 10, backgroundColor, height, cursor: isSelected ? 'default' : 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { this.props.setCurrentEditor(index) }}>
                                        <IconWrapper disabled={isSelected}>
                                            <span style={{ fontSize: '60%' }}>{label}</span>
                                        </IconWrapper>
                                    </div>
                                } else {
                                    return null;
                                }
                            });
                            if (subeditiorsComponents.length > 0) {
                                const isSelected = selectedEditorIndex === undefined || selectedEditorIndex < 0;
                                const mainEditorButton = <div key={'mainEditor'} style={{ backgroundColor, height, cursor: isSelected ? 'default' : 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { this.props.setCurrentEditor(undefined) }} >
                                    <IconWrapper disabled={isSelected}>
                                        <span style={{ fontSize: '60%' }}>{i18n.t('reportdesigner.menu.report')}</span>
                                    </IconWrapper>
                                </div>
                                subeditiorsComponents.unshift(mainEditorButton);
                            }
                            return <div style={{ display: 'flex', marginRight: 10, alignItems: 'center', height: height }}>
                                {subeditiorsComponents}
                            </div>
                        }
                    });
                } else {
                    if (this.queryEditor.current !== null) {
                        const subToolbar = this.queryEditor.current.contributeToolbar();
                        subToolbar.getToolItems().forEach((tooltiem) => {
                            items.push(tooltiem);
                        });
                    }
                }
                return items;
            }
        }
    }

    onZoomKeyDown = (e, setText: (value) => void) => {
        //disable the press of enter on the autocomplete
        if (e.key === 'Enter') {
            e.stopPropagation();
            let text = e.target.value;
            if (text.endsWith('%')) {
                text = text.substring(0, text.length - 1);
            }
            let zoom = parseInt(text, 10);
            if (!isNaN(zoom)) {
                if (zoom < 10) {
                    zoom = 10;
                }
                if (zoom > 500) {
                    zoom = 500;
                }
                const newZoom = zoom / 100.0;
                this.props.setZoom(newZoom);
                setText((newZoom * 100).toFixed(0) + '%');
            } else {
                let currentZoom;
                const currentEditorIndex = getEditorIndex(this.props.report);
                if (currentEditorIndex !== undefined) {
                    currentZoom = this.props.report.getIn(['subeditors', currentEditorIndex, 'zoom']);
                } else {
                    currentZoom = this.props.zoom;
                }
                setText((currentZoom * 100).toFixed(0) + '%');
            }
        }
    }

    private onZoomFocusLost = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, setText: (value) => void) => {
        let text = event.target.value;
        if (text.endsWith('%')) {
            text = text.substring(0, text.length - 1);
        }
        let zoom = parseInt(text, 10);
        if (!isNaN(zoom)) {
            if (zoom < 10) {
                zoom = 10;
            }
            if (zoom > 500) {
                zoom = 500;
            }
            const newZoom = zoom / 100.0;
            this.props.setZoom(newZoom);
            setText((newZoom * 100).toFixed(0) + '%');
        } else {
            let currentZoom;
            const currentEditorIndex = getEditorIndex(this.props.report);
            if (currentEditorIndex !== undefined) {
                currentZoom = this.props.report.getIn(['subeditors', currentEditorIndex, 'zoom']);
            } else {
                currentZoom = this.props.zoom;
            }
            setText((currentZoom * 100).toFixed(0) + '%');
        }
    }

    private onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            const jrxml = fileReader.result as string;
            // TODO - perform some kind of validation of the loaded content
            // or be sure that a sort of validation is triggered when editorContent is updated
            const base64data = Base64.encode(format(jrxml, { indentation: '  ', collapseContent: true }));
            const descriptor: IRepositoryItemDescriptor = this.props.openDescriptor;
            const newDescriptor: IRepositoryItemDescriptor = {
                ...(descriptor as any),
                data: base64data,
            }
            const jsModel = jrxmlToJs(jrxml);
            this.props.openDocumentAction(jsModel, newDescriptor, this.props.repositoryPath, undefined, {});
            this.props.commandRegistry.executeCommand(UPDATED_DESCRIPTOR, { descriptor: newDescriptor, data: jrxml });
        };
        fileReader.readAsText(event.currentTarget.files[0]);
    }

    componentWillUnmount() {
        this.props.resourceListenerManagers.forEach(manager => {
            manager.removeResourceListener(this.resourceListenerId);
        });
    }

    private showPropertisDialog = () => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.context.showDialog(ResourceInfoDialog, { descriptor: this.props.openDescriptor, onViewClose: () => { }, onChanged: this.handleItemPropertiesChanged });
    }

    public getEditorContent = () => {
        const jrxml = jsToJrxml(this.props.model);
        return jrxml;
    }

    public save = () => {
        const isSaveDisabled = !this.props.isDirty || !this.props.openDescriptor || isReadOnly(this.props.openDescriptor);
        if (!isSaveDisabled) {
            if (this.props.openDescriptor && this.props.openDescriptor.uuid) {
                this.doSave(false);
            } else {
                //if the file is new force a new file to let the user choose the name
                this.doSave(true);
            }
        }
    }

    private exportReport = () => {
        const jrxml = jsToJrxml(this.props.model);
        const blob = new Blob([jrxml], { type: "text/xml;charset=utf-8" });
        const extension = !this.props.openDescriptor.name.endsWith('.jrxml') ? '.jrxml' : '';
        saveAs(blob, this.props.openDescriptor.name + extension);
    }

    private doSave = (forceNewFile: boolean, callback?: () => void) => {
        const jrxml = jsToJrxml(this.props.model);
        if (jrxml) {
            if (this.props.openDescriptor && !forceNewFile) {
                //it is a save of an existing report
                const base64data = Base64.encode(format(jrxml, { indentation: '  ', collapseContent: true }));
                const descriptor: IRepositoryItemDescriptor = this.props.openDescriptor;
                const newDescriptor: IRepositoryItemDescriptor = {
                    ...(descriptor as any),
                    data: base64data,
                }

                this.context.runNoCancel(RepositoryApi.inst().save)(descriptor.uuid ? 'uuid:' + descriptor.uuid : (descriptor.path ? descriptor.path : '/'), newDescriptor).then((response: AxiosResponse) => {
                    if (response.data) {
                        this.props.setDescriptor(response.data, this.props.repositoryPath);
                        this.props.commandRegistry.executeCommand(REPORT_SAVED, { descriptor: response.data, data: jrxml });
                        this.props.setDirty(false);
                        if (callback) {
                            callback();
                        }
                    }
                });
            } else {
                //it is a new file
                this.openSaveDialog();
            }
        }
    }

    private fitPageWidth = () => {
        this.handleZoomMenuInteraction(null);
        this.props.setZoom(this.props.viewport.width / (this.props.clientArea.width + PAGE_HORIZONTAL_MARGIN_MIN * 2));
    }

    private fitPageHeight = () => {
        this.handleZoomMenuInteraction(null);
        this.props.setZoom(this.props.viewport.height / (this.props.clientArea.height + PAGE_VERTICAL_OFFSET * 2));
    }

    private fitPage = () => {
        this.handleZoomMenuInteraction(null);
        this.props.setZoom(Math.min(
            this.props.viewport.height / (this.props.clientArea.height + PAGE_VERTICAL_OFFSET * 2),
            this.props.viewport.width / (this.props.clientArea.width + PAGE_HORIZONTAL_MARGIN_MIN * 2)));
    }

    private handleZoomMenuInteraction = (nextOpenState: null | EventTarget) => {
        this.setState({ zoomMenuOpen: nextOpenState });
    }

    zoomMenuMouseMove = (event: React.MouseEvent<HTMLElement>) => {
        //avoid to trigger the mouse move on the editor below
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    getZoomMenu = () => {
        const setZoomLevel = (value) => {
            this.handleZoomMenuInteraction(null);
            this.props.setZoom(value);
        }
        return (
            <div onMouseMove={this.zoomMenuMouseMove}>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(1.0)}>{i18n.t('reportdesigner.menu.resetzoom')}</MenuItem>
                <MenuDivider />
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(0.2)}>20%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(0.4)}>40%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(0.6)}>60%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(0.8)}>80%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(1.0)}>100%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(1.25)}>125%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(1.50)}>150%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(2.0)}>200%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(3.0)}>300%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(4.0)}>400%</MenuItem>
                <MenuDivider />
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={this.fitPageWidth}>{i18n.t("viewer.zoom.fitWidth")}</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={this.fitPageHeight}>{i18n.t("viewer.zoom.fitHeight")}</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={this.fitPage}>{i18n.t("viewer.zoom.fitPage")}</MenuItem>
            </div>
        );
    }

    undo = () => {
        const isUndoDisabled = !this.props.undoRedo || this.props.undoRedo.get('past').count() === 0;
        if (!isUndoDisabled) {
            this.props.undo();
        }
    }

    redo = () => {
        const isRedoDisabled = !this.props.undoRedo || this.props.undoRedo.get('future').count() === 0;
        if (!isRedoDisabled) {
            this.props.redo();
        }
    }

    zoomIn = () => {
        this.props.onIncrementZoom();
    }

    zoomOut = () => {
        this.props.onDecrementZoom();
    }


    private getMainControl = () => {
        const currentEditorIndex = this.props.report.getIn(['currentEditorIndex']);
        if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
            const currentEditor = this.props.report.getIn(['subeditors', currentEditorIndex]) as Map<string, any>;
            const type = currentEditor.get('type');
            if (type === 'datasetQueryEditor') {
                return <QueryEditor ref={this.queryEditor} />
            } else if (type === ElementTypes.TABLE) {
                return <TableDesigner />
            } else if (type === ElementTypes.LIST) {
                return <ListDesigner />
            } else if (type === ElementTypes.CROSSTAB) {
                return <CrosstabDesigner />
            }
        }
        return <Designer />
    }

    private handleItemPropertiesChanged = (oldDescriptor: IRepositoryItemDescriptor, newDescriptor: IRepositoryItemDescriptor | null) => {
        this.props.setDescriptor(newDescriptor, this.props.repositoryPath);
        this.props.commandRegistry.executeCommand(REPORT_SAVED, { descriptor: newDescriptor });

    }

    public openSaveDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = this.props.repositoryPath;
        let filename = this.state.defaultFileName;
        if (!filename.toLowerCase().endsWith('.jrxml')) {
            filename += '.jrxml';
        }
        this.context.showDialog(ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('ReportDesigner.save.reportName'),
                mode: 'save',
                title: !this.props.openDescriptor.uuid ? i18n.t('ReportDesigner.save.save') : i18n.t('ReportDesigner.save.saveAs'),
                confirmButtonLabel: !this.props.openDescriptor.uuid ? i18n.t('common.actions.button.ok') : i18n.t('common.actions.button.save'),
                onViewClose: () => {
                    //empty
                },
                onFileSelected: this.confirmSave,
                defaultFileName: filename,
                defaultPath: defaultPath
            });
    }

    private confirmSave = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null, fileName: string) => {
        const jrxml = jsToJrxml(this.props.model);
        const base64data = Base64.encode(format(jrxml, { indentation: '  ', collapseContent: true }));
        let root = false;
        const newDescriptor: IRepositoryItemDescriptor = {
            type: RESOURCE_TYPE.FILE,
            name: fileName,
            path: RepositoryApi.inst().getParentPath(folderPath.filter(k => {
                root = root || file === null || k.type !== RESOURCE_TYPE.CONTAINER;
                return file == null || (root && k.type === RESOURCE_TYPE.CONTAINER)
            })),
            data: base64data,
            mime: this.props.openDescriptor ? this.props.openDescriptor.mime : MIME_TYPES.JRXML,
        }
        if (file !== null) {
            newDescriptor.path = file.path;
            //the save as is overwriting a file
            newDescriptor.uuid = file.uuid;
        }
        this.context.runNoCancel(RepositoryApi.inst().save)(file !== null ? 'uuid:' + file.uuid : '', newDescriptor).then((response: AxiosResponse) => {
            if (response.data) {
                this.props.setDescriptor(response.data, folderPath);
                this.props.commandRegistry.executeCommand(REPORT_SAVED_AS, { descriptor: response.data, data: jrxml });
                this.props.setDirty(false);
                this.props.commandRegistry.executeCommand(REPORT_EDITOR_DIRTY, { isDirty: false });
            }
        });
    }

    public render() {
        return <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <WebfontLoader />
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: 0, paddingLeft: -10, paddingRight: -10 }}>
                    <ToolBar toolbarData={this.state.toolbarData} />
                </div>
                <LanguageContext.Provider value={this.props.language}>
                    <ReactResizeDetector onResize={this.onResize} handleWidth handleHeight>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }} id="reportDesigner">
                            <div style={{ display: 'flex', flex: 1, flexBasis: 0, minHeight: 0 }}>
                                {this.getMainControl()}
                            </div>
                        </div>
                    </ReactResizeDetector>
                </LanguageContext.Provider>
            </div>
        </div>
    }

    private onResize = (width: number, height: number) => {
        // On resize, we should also update the scrollbars.
        // In particular we should adapt the quantity of scroll...
        if (this.props.viewportChanged) {
            const designerPosition = document.getElementById('reportDesigner').getBoundingClientRect();
            this.props.viewportChanged({
                x: designerPosition.x,
                y: designerPosition.y,
                width: width,
                height: height
            });
        }
    }
}

const mapStateToProps = (state: IState) => {
    return {
        report: state.getIn(['report']),
        subeditors: state.getIn(['report', 'subeditors']),
        currentEditorIndex: state.getIn(['report', 'currentEditorIndex']),
        // Plain javascript properties
        zoom: state.getIn(['report', 'zoom']),
        viewport: ((state.getIn(['report', 'viewport']) as Map<string, any>).toJS() as unknown) as ISize,
        clientArea: ((state.getIn(['report', 'clientArea']) as Map<string, any>).toJS() as unknown) as IRectangle,
        // Immutable objects....
        interactionElements: state.getIn(['report', 'interactionElements']),
        model: state.getIn(['report', 'model']),
        undoRedo: state.getIn(['report', 'undoRedo']),
        isDirty: state.getIn(['report', 'isDirty']),
        openDescriptor: state.getIn(['report', 'fileDescriptor']),
        repositoryPath: state.getIn(['report', 'repositoryPath']),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onIncrementZoom: () => {
            dispatch(ReportActions.increaseZoom());
        },
        onDecrementZoom: () => {
            dispatch(ReportActions.decreaseZoom());
        },
        setZoom: (val: number) => {
            dispatch(ReportActions.setZoom(val));
        },
        openDocumentAction: (model: any, descriptor: IRepositoryItemDescriptor, path: IRepositoryItemDescriptor[], parameters: Map<string, any> | undefined, options: IResourceInfoOptions) => {
            dispatch(ReportActions.openDocumentAction(model, descriptor, path, parameters, options));
        },
        setDescriptor: (descriptor: IRepositoryItemDescriptor, path: IRepositoryItemDescriptor[]) => {
            dispatch(ReportActions.setDescriptor(descriptor, path));
        },
        loadCombosAction: (path: string) => {
            dispatch(ReportActions.loadCombosAction(path, false));
        },
        viewportChanged: (viewport: IRectangle) => {
            dispatch(ReportActions.viewportChanged(fromJS(viewport)));
        },
        undo: () => {
            dispatch(ReportActions.undo());
        },
        redo: () => {
            dispatch(ReportActions.redo());
        },
        setDirty: (isDirty: boolean) => {
            dispatch(ReportActions.setDirty(isDirty));
        },
        setCurrentEditor: (editorIndex: number | undefined) => {
            dispatch(ReportActions.setCurrentEditor(editorIndex));
        },
    };
}

ReportDesigner.contextType = RunContext;

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
        forwardRef: true
    }
)(ReportDesigner);
