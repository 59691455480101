/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { TextInput } from '@jss/js-common';
import '../../assets/css/DataAdapterTypePicker.css';
import i18n from '../../i18n';
import { advancedNameValidator, validatePositiveInteger } from '@jss/js-common/src/utils/validators';

interface IEmptyDataAdapterEditor {
    name?: string,
    recordCount?: string,
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

export class EmptyDataAdapterEditor extends React.Component<IEmptyDataAdapterEditor> {

    public render() {
        return (<div className="tc-jsw-dataadapter-editor">
            <div className="tc-jsw-form-wrapper">
                <TextInput label={i18n.t('datasource.random.name')}
                    help={i18n.t('datasource.random.name.help')}
                    id="data-adapter-name"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly,
                    }} />

                <TextInput label={i18n.t('datasource.empty.recordnumber')}
                    id="record-number-input"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.recordCount}
                    onChange={this.onRecordNumberChange}
                    type="number"
                    min={0}
                    advancedValidator={validatePositiveInteger}
                    InputProps={{
                        readOnly: this.props.readOnly,
                    }} />
            </div>
        </div>);
    }

    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private onRecordNumberChange = (str: string) => {
        if (str.match('.') || str.trim().length === 0) {
            this.notifyChange({ recordCount: str });
        }
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }
}