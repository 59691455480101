/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const SortFieldTypeEnum = new PEnum({
    id: 'sortFieldType', label: 'SortFieldType.label',
    items: [
        { key: 'Field', value: 'SortFieldType.Field' },
        { key: 'Variable', value: 'SortFieldType.Variable' }
    ]
});

