/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";


const concatenateProperties = (prop1 : string, prop2: string) => {
    return prop1.concat(".").concat(prop2);
}

 const createBaseLinePen = (result : Map<string, any>, baseProperty: string) => {
     const widthKey = concatenateProperties(baseProperty, 'lineWidth');
     const colorKey = concatenateProperties(baseProperty, 'lineColor');
     const styleKey = concatenateProperties(baseProperty, 'lineStyle');
     result.set(colorKey, '#000000');
     result.set(styleKey, 'Solid');
     result.set(widthKey, 0);
 }

 const createFigureBaseLinePen = (result : Map<string, any>, baseProperty: string) => {
    const widthKey = concatenateProperties(baseProperty, 'lineWidth');
    const colorKey = concatenateProperties(baseProperty, 'lineColor');
    const styleKey = concatenateProperties(baseProperty, 'lineStyle');
    result.set(colorKey, '#000000');
    result.set(styleKey, 'Solid');
    result.set(widthKey, 1);
}

 const createBaseLineBox = (result : Map<string, any>, baseProperty: string) => {
     const paddingKey = concatenateProperties(baseProperty, 'padding');
     const paddingLeftKey = concatenateProperties(baseProperty, 'leftPadding');
     const paddingRightKey = concatenateProperties(baseProperty, 'rightPadding');
     const paddingBottomKey = concatenateProperties(baseProperty, 'bottomPadding');
     const paddingTopKey = concatenateProperties(baseProperty, 'topPadding');
     const penKey = concatenateProperties(baseProperty, 'pen');
     const penLeftKey = concatenateProperties(baseProperty, 'leftPen');
     const penRightKey = concatenateProperties(baseProperty, 'rightPen');
     const penBottomKey = concatenateProperties(baseProperty, 'bottomPen');
     const penTopKey = concatenateProperties(baseProperty, 'topPen');

     result.set(paddingKey, 0);
     result.set(paddingTopKey, 0);
     result.set(paddingBottomKey, 0);
     result.set(paddingLeftKey, 0);
     result.set(paddingRightKey, 0);

     createBaseLinePen(result, penKey);
     createBaseLinePen(result, penLeftKey);
     createBaseLinePen(result, penRightKey);
     createBaseLinePen(result, penBottomKey);
     createBaseLinePen(result, penTopKey);
 }

 const createBaseParagraph = (result: Map<string, any>, baseProperty: string) => {
     const lineSpacingKey = concatenateProperties(baseProperty, 'lineSpacing');
     const firstLineIdentKey = concatenateProperties(baseProperty, 'firstLineIndent');
     const lineSpacingSizeKey = concatenateProperties(baseProperty, 'lineSpacingSize');
     const leftIdentKey = concatenateProperties(baseProperty, 'leftIndent');
     const rightIdentKey = concatenateProperties(baseProperty, 'rightIndent');

     /*

     const spacingBeforeKey = concatenateProperties(baseProperty, 'spacingBefore');
     const spacingAfterKey = concatenateProperties(baseProperty, 'spacingAfter');
     const tabStopsWidthKey = concatenateProperties(baseProperty, 'tabStopWidth');*/

     result.set(lineSpacingKey, 'Single');
     result.set(firstLineIdentKey, 0);
     result.set(lineSpacingSizeKey, 1.0);
     result.set(leftIdentKey, 0);
     result.set(rightIdentKey, 0);

     /*JRPropertiesUtil prop = JRPropertiesUtil.getInstance(DefaultJasperReportsContext.getInstance());
     result.put(lineSpacingSizeKey, prop.getFloatProperty(JRParagraph.DEFAULT_LINE_SPACING_SIZE));
     result.put(leftIdentKey, prop.getIntegerProperty(JRParagraph.DEFAULT_LEFT_INDENT));
     result.put(rightIdentKey, prop.getIntegerProperty(JRParagraph.DEFAULT_RIGHT_INDENT));
     result.put(spacingBeforeKey, prop.getIntegerProperty(JRParagraph.DEFAULT_SPACING_BEFORE));
     result.put(spacingAfterKey, prop.getIntegerProperty(JRParagraph.DEFAULT_SPACING_AFTER));
     result.put(tabStopsWidthKey, prop.getIntegerProperty(JRParagraph.DEFAULT_TAB_STOP_WIDTH));*/
 }

 const initializeType = (type: string) => {
     const result = new Map<string, any>();

     if (type === ElementTypes.STYLE) {
         result.set('mode', 'Opaque');
         createBaseLinePen(result, 'pen');
         createBaseLineBox(result, 'box');
         createBaseParagraph(result, 'paragraph');
     } else {
         result.set('backcolor', '#FFFFFF');
         result.set('forecolor', '#000000');
         let transparency = 'Opaque';

         if (type === ElementTypes.LINE || type === ElementTypes.ELLIPSE || type === ElementTypes.RECTANGLE){
            createFigureBaseLinePen(result, 'pen');
         } else {
            createBaseLineBox(result, 'box');
         }

         if (type === ElementTypes.TEXTFIELD || type === ElementTypes.STATICTEXT) {
             result.set('font.size', 10);
             result.set('font.isBold', false);
             result.set('font.isItalic', false);
             result.set('font.isUnderline', false);
             result.set('font.isStrikeThrough', false);
             result.set('verticalAlignment', 'Top');
             result.set('textAlignment', 'Left');
             result.set('rotation', 'None');
             transparency = 'Transparent';
             createBaseParagraph(result, 'paragraph');
         }

         if (type === ElementTypes.TEXTFIELD) {
             result.set('isBlankWhenNull', false);
         }

         if (type === ElementTypes.RECTANGLE) {
             result.set('fill', 'Solid');
             result.set('radius', 0);
         }

         if (type === ElementTypes.IMAGE) {
             result.set('scaleImage', 'RetainShape');
             result.set('fill', 'Solid');
         }

         result.set('mode', transparency);
     }
     return result;
 }

 const valuesMap = new Map<string, any>();


 export const getPropertiesByType = (elementType: string) => {
    // Check the requested properties are cached
    let result :  Map<string, any> = valuesMap.get(elementType);
    if (!result) {
        // The are not, so i need to create them
        result = initializeType(elementType);
        valuesMap.set(elementType, result);
    }
    return result;
}
