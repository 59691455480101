/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { PEnum } from "../../ui/primitive/UPEnum";

export const PdfFieldTypeEnum = new PEnum({
    id: 'pdfPrintScalingEnum', label: 'PdfFieldTypeEnum.label', sinceVersion: '6.12.0',
    items: [{ key: '', value: '' },
    { key: 'Text', value: 'PdfFieldTypeEnum.Text' },
    { key: 'Combo', value: 'PdfFieldTypeEnum.Combo' },
    { key: 'List', value: 'PdfFieldTypeEnum.List' },
    { key: 'Check', value: 'PdfFieldTypeEnum.Check' },
    { key: 'Radio', value: 'PdfFieldTypeEnum.Radio' },
    ]
});

