/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const Column3DChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="802"
      height="493"
      version="1.1"
      style={{
        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        userSelect: "none",
        position: "relative",
      }}
      cursor="default"
      overflow="hidden"
      viewBox="0 0 802 493"
      {...props}
    >
      <defs>
        <linearGradient
          id="19-329.0909881288068-rgba_187_192_181_1_-rgba_236_240_232_1_"
          x1="0"
          x2="1"
          y1="0"
          y2="0.599"
        >
          <stop offset="0%" stopColor="#bbc0b5"></stop>
          <stop offset="100%" stopColor="#ecf0e8"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgb_209.35_217.6_198.9_-rgb_111.8_121.55_99.45_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#d1d9c6"></stop>
          <stop offset="100%" stopColor="#6f7963"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgb_129_140.25_114.75_-rgb_60.199999999999996_65.45_53.55_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#818c72"></stop>
          <stop offset="100%" stopColor="#3c4135"></stop>
        </linearGradient>
        <linearGradient
          id="19-45.85278353714864-rgb_134.16_145.86_119.34_-rgb_236.74_240.04_232.56_"
          x1="0"
          x2="0.971"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#869177"></stop>
          <stop offset="100%" stopColor="#ecf0e8"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#d3e9fb"></stop>
          <stop offset="100%" stopColor="#718ca1"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#83a2ba"></stop>
          <stop offset="100%" stopColor="#3d4b56"></stop>
        </linearGradient>
        <linearGradient
          id="19-54.46232220802562-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
          x1="0"
          x2="0.714"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#88a8c1"></stop>
          <stop offset="100%" stopColor="#edf6fd"></stop>
        </linearGradient>
        <filter
          id="2D6793C5-B1ED-4A6D-A068-EB9912ECA726"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="2248005E-5C83-467F-B423-16E1B9AB9C35"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#dcdcdc" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#7c7c7c" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#909090" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#434343" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.714"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#fada7b"></stop>
          <stop offset="100%" stopColor="#9f7a09"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#b88d0b"></stop>
          <stop offset="100%" stopColor="#564205"></stop>
        </linearGradient>
        <linearGradient
          id="19-54.46232220802562-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_"
          x1="0"
          x2="0.714"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#bf930b"></stop>
          <stop offset="100%" stopColor="#fdf0ca"></stop>
        </linearGradient>
        <filter
          id="6DCD5E4A-2A48-4BDA-9923-72C0FC0407AA"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="ADC7EBA6-8E44-450D-8182-BB54BE651F91"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#bfd972"></stop>
          <stop offset="100%" stopColor="#5a7800"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#688b00"></stop>
          <stop offset="100%" stopColor="#304100"></stop>
        </linearGradient>
        <linearGradient
          id="19-54.46232220802562-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_"
          x1="0"
          x2="0.714"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#6c9100"></stop>
          <stop offset="100%" stopColor="#e5efc6"></stop>
        </linearGradient>
        <filter
          id="9C512F98-B961-4CC5-A9D7-7431F2470ACE"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="FC1F00BE-100D-4E7F-B9D6-E38BBBE00CE4"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-270-rgba_255_192.85_153.25_1_-rgba_165.75_92.3_45.5_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#ffc099"></stop>
          <stop offset="100%" stopColor="#a55c2d"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_191.25_106.5_52.5_1_-rgba_89.25_49.699999999999996_24.5_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#bf6a34"></stop>
          <stop offset="100%" stopColor="#593118"></stop>
        </linearGradient>
        <linearGradient
          id="19-54.46232220802562-rgba_198.9_110.76_54.6_1_-rgba_255_230.14_214.3_1_"
          x1="0"
          x2="0.714"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#c66e36"></stop>
          <stop offset="100%" stopColor="#ffe6d6"></stop>
        </linearGradient>
        <filter
          id="94FBB868-11E1-4283-AC3F-276C7AC39F48"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="89EF10C4-6B35-48B5-AD32-D1CED9CA6377"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-270-rgba_114.75_192.85_192.85_1_-rgba_0_92.3_92.3_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#72c0c0"></stop>
          <stop offset="100%" stopColor="#005c5c"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_0_106.5_106.5_1_-rgba_0_49.699999999999996_49.699999999999996_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#006a6a"></stop>
          <stop offset="100%" stopColor="#003131"></stop>
        </linearGradient>
        <linearGradient
          id="19-54.46232220802562-rgba_0_110.76_110.76_1_-rgba_198.9_230.14_230.14_1_"
          x1="0"
          x2="0.714"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#006e6e"></stop>
          <stop offset="100%" stopColor="#c6e6e6"></stop>
        </linearGradient>
        <filter
          id="D32390ED-0970-4873-9C42-1FF3B3480BA6"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="1A545013-08F6-4074-AD5B-FEC076A96070"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-270-rgba_232.45_153.25_153.25_1_-rgba_139.1_45.5_45.5_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#e89999"></stop>
          <stop offset="100%" stopColor="#8b2d2d"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_160.5_52.5_52.5_1_-rgba_74.89999999999999_24.5_24.5_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#a03434"></stop>
          <stop offset="100%" stopColor="#4a1818"></stop>
        </linearGradient>
        <linearGradient
          id="19-54.46232220802562-rgba_166.92000000000002_54.6_54.6_1_-rgba_245.98_214.3_214.3_1_"
          x1="0"
          x2="0.714"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#a63636"></stop>
          <stop offset="100%" stopColor="#f5d6d6"></stop>
        </linearGradient>
        <filter
          id="76C20A46-607F-47CC-B1D0-B21F1982E173"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="1B9607A6-A375-4B5C-A85F-4C5C177F7F65"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-270-rgba_192.85_153.25_192.85_1_-rgba_92.3_45.5_92.3_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#c099c0"></stop>
          <stop offset="100%" stopColor="#5c2d5c"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_106.5_52.5_106.5_1_-rgba_49.699999999999996_24.5_49.699999999999996_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#6a346a"></stop>
          <stop offset="100%" stopColor="#311831"></stop>
        </linearGradient>
        <linearGradient
          id="19-54.46232220802562-rgba_110.76_54.6_110.76_1_-rgba_230.14_214.3_230.14_1_"
          x1="0"
          x2="0.714"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#6e366e"></stop>
          <stop offset="100%" stopColor="#e6d6e6"></stop>
        </linearGradient>
        <filter
          id="5A595453-9255-4145-8AF1-DA330E5BD744"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="53CA726A-F285-45EF-9FF4-6D9B8CA81629"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-270-rgba_163.14999999999998_187.89999999999998_135.64999999999998_1_-rgba_57.2_86.45_24.7_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#a3bb87"></stop>
          <stop offset="100%" stopColor="#395618"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_66_99.75_28.5_1_-rgba_30.799999999999997_46.55_13.299999999999999_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#42631c"></stop>
          <stop offset="100%" stopColor="#1e2e0d"></stop>
        </linearGradient>
        <linearGradient
          id="19-54.46232220802562-rgba_68.64_103.74000000000001_29.64_1_-rgba_218.26_228.16_207.26_1_"
          x1="0"
          x2="0.714"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#44671d"></stop>
          <stop offset="100%" stopColor="#dae4cf"></stop>
        </linearGradient>
        <filter
          id="330A39B3-1DDD-4450-B80C-3C3B7A719528"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="3886C232-8002-4352-B2C4-B2192C0259E5"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
      </defs>
      <g className="raphael-group-20-background">
        <rect width="802" height="493" fill="#FFF" rx="0" ry="0"></rect>
        <rect
          width="802"
          height="493"
          fill="none"
          stroke="#666"
          strokeWidth="0"
          rx="0"
          ry="0"
        ></rect>
      </g>
      <g className="raphael-group-27-canvas">
        <rect
          width="728"
          height="369"
          x="55"
          y="55"
          fill="none"
          stroke="#545454"
          strokeWidth="0"
          rx="0"
          ry="0"
        ></rect>
        <path
          fill="url('#19-329.0909881288068-rgba_187_192_181_1_-rgba_236_240_232_1_')"
          strokeWidth="0"
          d="M783 55l3 3.6V421l-3 3z"
        ></path>
        <path
          fill="url('#19-45.85278353714864-rgb_134.16_145.86_119.34_-rgb_236.74_240.04_232.56_')"
          strokeLinejoin="round"
          strokeWidth="0"
          d="M44 435l1 1h727v-1l11-11H55z"
          shapeRendering="geometricprecision"
        ></path>
        <path
          fill="url('#19-270-rgb_129_140.25_114.75_-rgb_60.199999999999996_65.45_53.55_')"
          strokeLinejoin="round"
          strokeWidth="0"
          d="M771 436v9h1l11-11v-10l-11 11"
          shapeRendering="geometricprecision"
        ></path>
        <path
          fill="url('#19-270-rgb_209.35_217.6_198.9_-rgb_111.8_121.55_99.45_')"
          strokeLinejoin="round"
          strokeWidth="0"
          d="M772 435v10H44v-10z"
          shapeRendering="geometricprecision"
        ></path>
        <path fill="none" stroke="#FAFBF9" d="M55 424h728"></path>
        <rect
          width="728"
          height="369"
          x="55"
          y="55"
          fill="url('#19-329.0909881288068-rgba_187_192_181_1_-rgba_236_240_232_1_')"
          strokeWidth="0"
          rx="0"
          ry="0"
        ></rect>
      </g>
      <g className="raphael-group-35-axisbottom">
        <g
          fill="none"
          stroke="#ACBB99"
          className="raphael-group-46-y-axis-lines"
          shapeRendering="crispedges"
        >
          <path d="M55 350.5h728M55 276.5h728M55 203.5h728M55 129.5h728"></path>
        </g>
        <g className="fusioncharts-xaxis-0-gridlabels">
          <text
            x="99.875"
            y="460"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="99.875" dy="-2">
              AK
            </tspan>
          </text>
          <text
            x="189.625"
            y="460"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="189.625" dy="-2">
              CA
            </tspan>
          </text>
          <text
            x="279.375"
            y="460"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="279.375" dy="-2">
              ID
            </tspan>
          </text>
          <text
            x="369.125"
            y="460"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="369.125" dy="-2">
              MT
            </tspan>
          </text>
          <text
            x="458.875"
            y="460"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="458.875" dy="-2">
              NM
            </tspan>
          </text>
          <text
            x="548.625"
            y="460"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="548.625" dy="-2">
              OR
            </tspan>
          </text>
          <text
            x="638.375"
            y="460"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="638.375" dy="-2">
              WA
            </tspan>
          </text>
          <text
            x="728.125"
            y="460"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="728.125" dy="-2">
              WY
            </tspan>
          </text>
        </g>
        <g className="fusioncharts-yaxis-0-gridlabels">
          <text
            x="49"
            y="424"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="end"
          >
            <tspan x="49" dy="4">
              0
            </tspan>
          </text>
          <text
            x="49"
            y="350.2"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="end"
          >
            <tspan x="49" dy="4.005">
              8
            </tspan>
          </text>
          <text
            x="49"
            y="276.4"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="end"
          >
            <tspan x="49" dy="4.002">
              16
            </tspan>
          </text>
          <text
            x="49"
            y="202.6"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="end"
          >
            <tspan x="49" dy="3.998">
              24
            </tspan>
          </text>
          <text
            x="49"
            y="128.8"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="end"
          >
            <tspan x="49" dy="3.995">
              32
            </tspan>
          </text>
          <text
            x="49"
            y="55"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="end"
          >
            <tspan x="49" dy="4">
              40
            </tspan>
          </text>
        </g>
        <g className="raphael-group-55-axis-name">
          <text
            fill="#49563A"
            className="fusioncharts-yaxis-0-title"
            fontSize="10"
            textAnchor="middle"
            transform="rotate(-90 128.75 110.75)"
          >
            <tspan x="0" dy="10">
              Orders
            </tspan>
          </text>
          <text
            fill="#49563A"
            className="fusioncharts-xaxis-0-title"
            fontSize="10"
            textAnchor="middle"
            transform="translate(419 465)"
          >
            <tspan x="0" dy="10">
              States
            </tspan>
          </text>
        </g>
      </g>
      <g className="raphael-group-21-dataset">
        <g className="raphael-group-77-columns">
          <g
            stroke="#000"
            strokeLinejoin="round"
            strokeWidth="0"
            className="raphael-group-79-positive-values"
            shapeRendering="geometricprecision"
          >
            <path
              fill="url('#19-54.46232220802562-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
              d="M74.875 341.75l1 1h49v-1l10-10h-50z"
              filter='url("#2D6793C5-B1ED-4A6D-A068-EB9912ECA726")'
            ></path>
            <path
              fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
              d="M123.875 342.75V434h1l10-10v-92.25l-10 10"
              filter='url("#2248005E-5C83-467F-B423-16E1B9AB9C35")'
            ></path>
            <path
              fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
              d="M124.875 341.75V434h-50v-92.25z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
              d="M164.625 397.1l1 1h49v-1l10-10h-50z"
              filter='url("#6DCD5E4A-2A48-4BDA-9923-72C0FC0407AA")'
            ></path>
            <path
              fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
              d="M213.625 398.1V434h1l10-10v-36.9l-10 10"
              filter='url("#ADC7EBA6-8E44-450D-8182-BB54BE651F91")'
            ></path>
            <path
              fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
              d="M214.625 397.1V434h-50v-36.9z"
            ></path>
            <path
              fill="url('#19-54.46232220802562-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
              d="M254.375 148.025l1 1h49v-1l10-10h-50z"
              filter='url("#9C512F98-B961-4CC5-A9D7-7431F2470ACE")'
            ></path>
            <path
              fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
              d="M303.375 149.025V434h1l10-10V138.025l-10 10"
              filter='url("#FC1F00BE-100D-4E7F-B9D6-E38BBBE00CE4")'
            ></path>
            <path
              fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
              d="M304.375 148.025V434h-50V148.025z"
            ></path>
            <g>
              <path
                fill="url('#19-54.46232220802562-rgba_198.9_110.76_54.6_1_-rgba_255_230.14_214.3_1_')"
                d="M344.125 406.325l1 1h49v-1l10-10h-50z"
                filter='url("#94FBB868-11E1-4283-AC3F-276C7AC39F48")'
              ></path>
              <path
                fill="url('#19-270-rgba_191.25_106.5_52.5_1_-rgba_89.25_49.699999999999996_24.5_1_')"
                d="M393.125 407.325V434h1l10-10v-27.675l-10 10"
                filter='url("#89EF10C4-6B35-48B5-AD32-D1CED9CA6377")'
              ></path>
              <path
                fill="url('#19-270-rgba_255_192.85_153.25_1_-rgba_165.75_92.3_45.5_1_')"
                d="M394.125 406.325V434h-50v-27.675z"
              ></path>
            </g>
            <g>
              <path
                fill="url('#19-54.46232220802562-rgba_0_110.76_110.76_1_-rgba_198.9_230.14_230.14_1_')"
                d="M433.875 267.95l1 1h49v-1l10-10h-50z"
                filter='url("#D32390ED-0970-4873-9C42-1FF3B3480BA6")'
              ></path>
              <path
                fill="url('#19-270-rgba_0_106.5_106.5_1_-rgba_0_49.699999999999996_49.699999999999996_1_')"
                d="M482.875 268.95V434h1l10-10V257.95l-10 10"
                filter='url("#1A545013-08F6-4074-AD5B-FEC076A96070")'
              ></path>
              <path
                fill="url('#19-270-rgba_114.75_192.85_192.85_1_-rgba_0_92.3_92.3_1_')"
                d="M483.875 267.95V434h-50V267.95z"
              ></path>
            </g>
            <g>
              <path
                fill="url('#19-54.46232220802562-rgba_166.92000000000002_54.6_54.6_1_-rgba_245.98_214.3_214.3_1_')"
                d="M523.625 175.7l1 1h49v-1l10-10h-50z"
                filter='url("#76C20A46-607F-47CC-B1D0-B21F1982E173")'
              ></path>
              <path
                fill="url('#19-270-rgba_160.5_52.5_52.5_1_-rgba_74.89999999999999_24.5_24.5_1_')"
                d="M572.625 176.7V434h1l10-10V165.7l-10 10"
                filter='url("#1B9607A6-A375-4B5C-A85F-4C5C177F7F65")'
              ></path>
              <path
                fill="url('#19-270-rgba_232.45_153.25_153.25_1_-rgba_139.1_45.5_45.5_1_')"
                d="M573.625 175.7V434h-50V175.7z"
              ></path>
            </g>
            <g>
              <path
                fill="url('#19-54.46232220802562-rgba_110.76_54.6_110.76_1_-rgba_230.14_214.3_230.14_1_')"
                d="M613.375 258.725l1 1h49v-1l10-10h-50z"
                filter='url("#5A595453-9255-4145-8AF1-DA330E5BD744")'
              ></path>
              <path
                fill="url('#19-270-rgba_106.5_52.5_106.5_1_-rgba_49.699999999999996_24.5_49.699999999999996_1_')"
                d="M662.375 259.725V434h1l10-10V248.725l-10 10"
                filter='url("#53CA726A-F285-45EF-9FF4-6D9B8CA81629")'
              ></path>
              <path
                fill="url('#19-270-rgba_192.85_153.25_192.85_1_-rgba_92.3_45.5_92.3_1_')"
                d="M663.375 258.725V434h-50V258.725z"
              ></path>
            </g>
            <g>
              <path
                fill="url('#19-54.46232220802562-rgba_68.64_103.74000000000001_29.64_1_-rgba_218.26_228.16_207.26_1_')"
                d="M703.125 350.975l1 1h49v-1l10-10h-50z"
                filter='url("#330A39B3-1DDD-4450-B80C-3C3B7A719528")'
              ></path>
              <path
                fill="url('#19-270-rgba_66_99.75_28.5_1_-rgba_30.799999999999997_46.55_13.299999999999999_1_')"
                d="M752.125 351.975V434h1l10-10v-83.025l-10 10"
                filter='url("#3886C232-8002-4352-B2C4-B2192C0259E5")'
              ></path>
              <path
                fill="url('#19-270-rgba_163.14999999999998_187.89999999999998_135.64999999999998_1_-rgba_57.2_86.45_24.7_1_')"
                d="M753.125 350.975V434h-50v-83.025z"
              ></path>
            </g>
          </g>
        </g>
      </g>
      <g className="fusioncharts-datalabels">
        <text
          x="99.875"
          y="353.75"
          fill="#49563A"
          className="fusioncharts-label"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="99.875" dy="3.25">
            10
          </tspan>
        </text>
        <text
          x="189.625"
          y="409.1"
          fill="#49563A"
          className="fusioncharts-label"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="189.625" dy="3.248">
            4
          </tspan>
        </text>
        <text
          x="279.375"
          y="160.025"
          fill="#49563A"
          className="fusioncharts-label"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="279.375" dy="3.252">
            31
          </tspan>
        </text>
        <text
          x="369.125"
          y="418.325"
          fill="#49563A"
          className="fusioncharts-label"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="369.125" dy="3.255">
            3
          </tspan>
        </text>
        <text
          x="458.875"
          y="279.95"
          fill="#49563A"
          className="fusioncharts-label"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="458.875" dy="3.255">
            18
          </tspan>
        </text>
        <text
          x="548.625"
          y="187.7"
          fill="#49563A"
          className="fusioncharts-label"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="548.625" dy="3.255">
            28
          </tspan>
        </text>
        <text
          x="638.375"
          y="270.725"
          fill="#49563A"
          className="fusioncharts-label"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="638.375" dy="3.248">
            19
          </tspan>
        </text>
        <text
          x="728.125"
          y="362.975"
          fill="#49563A"
          className="fusioncharts-label"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="728.125" dy="3.248">
            9
          </tspan>
        </text>
      </g>
      <g className="raphael-group-152-caption">
        <text
          x="419"
          y="16"
          fill="#49563A"
          className="fusioncharts-caption"
          fontFamily="Verdana, sans"
          fontSize="13"
          fontWeight="bold"
          textAnchor="middle"
        >
          <tspan x="419" dy="12.992">
            Chart Caption
          </tspan>
        </text>
        <text
          x="419"
          y="34"
          fill="#49563A"
          className="fusioncharts-subcaption"
          fontFamily="Verdana, sans"
          fontSize="11"
          fontWeight="bold"
          textAnchor="middle"
        >
          <tspan x="419" dy="10.852">
            Chart Sub Caption
          </tspan>
        </text>
      </g>
      <g className="raphael-group-22-hot">
        <g
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="0"
          className="raphael-group-76-col-hot"
          shapeRendering="geometricprecision"
        >
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M74.875 341.75l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M123.875 342.75V434h1l10-10v-92.25l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M124.875 341.75V434h-50v-92.25z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M164.625 397.1l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M213.625 398.1V434h1l10-10v-36.9l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M214.625 397.1V434h-50v-36.9z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M254.375 148.025l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M303.375 149.025V434h1l10-10V138.025l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M304.375 148.025V434h-50V148.025z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M344.125 406.325l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M393.125 407.325V434h1l10-10v-27.675l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M394.125 406.325V434h-50v-27.675z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M433.875 267.95l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M482.875 268.95V434h1l10-10V257.95l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M483.875 267.95V434h-50V267.95z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M523.625 175.7l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M572.625 176.7V434h1l10-10V165.7l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M573.625 175.7V434h-50V175.7z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M613.375 258.725l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M662.375 259.725V434h1l10-10V248.725l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M663.375 258.725V434h-50V258.725z"
          ></path>
          <path
            fill="url('#19-54.46232220802562-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M703.125 350.975l1 1h49v-1l10-10h-50z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M752.125 351.975V434h1l10-10v-83.025l-10 10"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M753.125 350.975V434h-50v-83.025z"
          ></path>
        </g>
      </g>
    </svg>)
};

export default Column3DChartIcon;