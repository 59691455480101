/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

export const ADD_LOGGED_USER = 'ADD_LOGGED_USER';

export const ADD_LOGGED_USER_METADATA = 'ADD_LOGGED_USER_METADATA';

export const LOGOUT_USER = 'LOGOUT_USER';

export const SET_JRS_URL = 'SET_JRS_URL';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export const SET_THEME = 'SET_THEME';

