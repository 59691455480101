/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import { connect } from 'react-redux';

import { IState } from '../../../reducers';
import image from '../../../assets/uxpl/img/image.png';

interface IImageElement {
  id: string;
  element?: any;
  zoom?: number;
  availableWidth: number,
  availableHeight: number,
}

const visualProperties = ['width', 'height', 'imageExpression'];


class ImageElement extends React.Component<IImageElement> {


  public shouldComponentUpdate = (nextProps: Readonly<IImageElement>) => {

    for (const propName of visualProperties) {
      if (this.props.element.get(propName) !== nextProps.element.get(propName)) {
        return true;
      }
    }

    return false; // Nothing impacting the content of the figure is changed...
  }

  public render() {
    const imageExpression =  this.props.element.get('imageExpression');
    return (
      <div title={'' + this.props.element.get('x') + ',' + this.props.element.get('y')}
        style={{ width: '100%', height: '100%', fontSize: 12, textAlign: 'left', display: 'flex' }}>
        <img src={image} style={{ width: '100%', height: '100%', top: 0, left: 0 }} />
        <div style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, left: 0, width: '100%', height: '100%'}}>
          <span style={{wordBreak: 'break-all'}}>{imageExpression}</span>
        </div>
      </div>
    );
  }


}

const mapStateToProps = (state: IState, props: IImageElement) => {

  return {
    element: state.getIn(['report', 'model', 'elements', props.id]),
    zoom: state.getIn(['report', 'zoom'])
  };
}

// const mapDispatchToProps = dispatch => {
//   return { 
//     selectElement: (id: string, add: boolean) => {
//       dispatch( selectElement(id, add) );
//     },
//   };
// }

export default connect(mapStateToProps)(ImageElement);   // ,mapDispatchToProps