/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const TextAdjustEnum = new PEnum({
    id: 'textAdjust', label: 'TextAdjust.label', default: 'CutText',
    items: [
        { key: 'CutText', value: 'TextAdjust.CutText' },
        { key: 'StretchHeight', value: 'TextAdjust.StretchHeight'  },
        { key: 'ScaleFont', value: 'TextAdjust.ScaleFont'  }
    ]
}); 
 
