/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../../ui/APDescriptor";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { PJavaClass } from "../../../ui/primitive/UPJavaClass";

import { PString } from "../../../ui/primitive/UPString";
import { PTableElement } from "../../../ui/tables/UPTableElement";
import { CalculationEnum } from "../../enums/CalculationEnum";
import { CrosstabPercentageEnum } from "../../enums/CrosstabPercentageEnum";


export const FHCMeasures = {
    measureName: new PString({ id: 'measureName', label: 'Name' }),

    calculation: CalculationEnum,
    incrementerFactoryClassName: new PJavaClass({ id: 'incrementerFactoryClassName', label: 'Incrementer Factory Class Name' }),

    percentageOfType: CrosstabPercentageEnum,
    percentageCalculatorClassName: new PJavaClass({ id: 'percentageCalculatorClassName', label: 'Percentage Calculator Class Name' }),

    labelExpression: new PExpression({ id: 'labelExpression', label: 'Label Expression' }),

    valueExpression: new PExpression({ id: 'valueExpression', label: 'Value Expression' }),
    class: new PJavaClass({ id: 'class', label: 'Value Class Name' })
}

export const MHCMeasures: APDescriptor = new PTableElement({
    label: 'Measures',
    anchorOrigin: {vertical: 'center', horizontal: 'right'},
    transformOrigin: {vertical: 'center', horizontal: 'right'},
    layouts: [FHCMeasures.measureName, FHCMeasures.labelExpression, FHCMeasures.valueExpression, FHCMeasures.class, FHCMeasures.calculation, FHCMeasures.incrementerFactoryClassName, FHCMeasures.percentageOfType, FHCMeasures.percentageCalculatorClassName]
});
