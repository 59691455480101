/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { APDescriptor } from "../../ui/APDescriptor";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PInt } from "../../ui/primitive/UPInteger";

export const FBookmarks = {
    bookmarkLevelExpression: new PExpression({ id: 'bookmarkLevelExpression', label: 'Level Expression' }),
    bookmarkLevel: new PInt({ id: 'bookmarkLevel', label: 'Level' })
};

export const MBookmarks: APDescriptor = new PAccordeon({
    id: 'element.bookmarks', label: 'Bookmarks',
    layouts: [FBookmarks.bookmarkLevel, FBookmarks.bookmarkLevelExpression]
});
