/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Button as JSSButton, IconButton } from '@jss/js-common';
import { RunContext } from '@jss/js-repository';
import { DatasourceApi, IRepositoryItemDescriptor, RepositoryApi } from '@jss/js-rest-api';
import { AxiosResponse } from 'axios';
import { List, Map } from 'immutable';
import { Base64 } from 'js-base64';
import * as React from 'react';
import { connect } from 'react-redux';
import format from 'xml-formatter';
import * as ReportActions from '../../../actions/reportActions';
import i18n from '../../../i18n';
import { IState } from '../../../reducers';
import jsToJrxml from '../../common/JrxmlModel/writer/JrxmlWriter';
import { JRParameter } from './JRParameter';
import { getParameterControl } from './ParametersUtils';


interface ISystemParameters {
    model?: Map<string, any>,
    report?: Map<string, any>,
    systemParameters?: Map<string, any>,
    parametersValue?: Map<string, any>,
    openDescriptor?: IRepositoryItemDescriptor | undefined;
    setObjectProperties?: (path: string[], value: any) => void;
    setParameterValue?: (parameterName: string, value: any) => void;
}
export interface ICParameters {
    param: JRParameter;
    nestedType?: string;
    parametersValue?: Map<string, any>,
    setParameterValue?: (parameterName: string, value: any) => void;
}
export interface IParametersTabState {
    system: boolean;
}
export class ParametersTab extends React.Component<ISystemParameters, IParametersTabState> {
    public state: IParametersTabState = { system: false };

    getDataset = (): Immutable.Collection<string, any> => {
        const editorIndex = this.props.report.get('currentEditorIndex');
        if (editorIndex !== undefined && editorIndex != null) {
            const editedResourceId = this.props.report.getIn(['subeditors', editorIndex, 'editedResourceId']) as string;
            if (editedResourceId !== 'main') {
                return this.props.report.getIn(['model', 'subdatasets', editedResourceId]) as Immutable.Collection<string, any>;
            }
        }
        return this.props.report.getIn(['model']) as Immutable.Collection<string, any>;
    }

    getSystemParameters = () => {
        const dataset = this.getDataset();
        let queryLanguage = dataset.get('queryLanguage');
        if (!queryLanguage) {
            queryLanguage = "sql";
        }
        return this.props.systemParameters.get(queryLanguage, List<Map<string, any>>());
    }

    getParameters = () => {
        const dataset = this.getDataset();
        if (dataset) {
            return dataset.get('parameters', List<Map<string, any>>());
        }
        return List<Map<string, any>>();
    }

    render() {
        return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {this.state.system ? this.renderSystemParameters() : this.renderParameters()}

            <div className="jr-parameters-button" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <IconButton color={this.state.system ? 'primary' : 'secondary'} icon='list' size='small' onClick={this.switchList}
                    title={i18n.t('reportdesigner.properties.query.filteredtable.filter.button.tooltip')} />
                <JSSButton size="large" variant="text" style={{ marginLeft: '10px' }} onClick={this.resetParameters}>{i18n.t('queryeditor.querypreview.resetbutton')}</JSSButton>
            </div>
        </div>
    }

    private switchList = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState((state) => ({ system: !state.system }));
    }

    private renderParameters = () => {
        const params = this.getParameters();
        if (params) {
            let paramsContainer;
            const paramsControls = params.map(p => {
                const prm = p.toJS() as JRParameter;
                if (prm.isForPrompting === true)
                    return this.renderParameter(p.toJS());
                return null;
            });
            if (paramsControls.size > 0) {
                paramsContainer = <div style={{ display: 'flex', flex: 1, overflow: 'auto' }}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
                        {paramsControls}
                    </div>
                </div>;
            } else {
                paramsContainer = <div style={{ display: 'flex', flex: 1 }} />;
            }
            return <>
                {paramsContainer}
            </>;
        }
        return <span style={{ textAlign: 'center', color: 'lightgray' }}>{i18n.t('tableElement.novalues')}</span>;
    }


    private getReportPath = (descriptor) => {
        let pa = descriptor.path;
        if (descriptor.name) {
            if (!pa.endsWith('/')) {
                pa += "/";
            }
            pa += descriptor.name;
        }
        return pa;
    }

    private resetParameters = () => {
        const descriptor = this.props.openDescriptor;
        const jrxml = jsToJrxml(this.props.model);
        const base64data = Base64.encode(format(jrxml, { indentation: '  ', collapseContent: true }));
        const newDescriptor: IRepositoryItemDescriptor = {
            ...descriptor,
            uuid: undefined,
            data: base64data,
            path: RepositoryApi.getAPathOnly(descriptor)
        }
        this.context.runNoCancel(RepositoryApi.inst().tmp().save)(newDescriptor.path ? newDescriptor.path : '/', newDescriptor).then(() => {
            this.context.run(DatasourceApi.inst().evaluateParameters)(this.getReportPath(newDescriptor), []).then((response: AxiosResponse) => {
                if (response.data) {
                    response.data.forEach((parameterValue) => {
                        // set values to null in the model
                        // set this defaults into the widget placeholder
                        this.props.setParameterValue(parameterValue.key, parameterValue.value ? JSON.parse(parameterValue.value) : undefined);
                    });
                }
            });
        }).catch(error => console.log(error));
    }

    private renderSystemParameters = () => {
        const systemParameterForLanguage = this.getSystemParameters();
        if (systemParameterForLanguage) {
            let paramsContainer;
            const paramsControls = systemParameterForLanguage.map(p => {
                return this.renderParameter(p.toJS());
            });
            if (paramsControls.size > 0) {
                paramsContainer = <div style={{ display: 'flex', flex: 1, overflow: 'auto' }}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
                        {paramsControls}
                    </div>
                </div>;
            } else {
                paramsContainer = <div style={{ display: 'flex', flex: 1 }} />;
            }
            return <>
                {paramsContainer}
            </>;
        }
        return <span>{i18n.t('tableElement.novalues')}</span>;
    }

    private renderParameter = (prm: JRParameter) => {
        return getParameterControl(prm, this.props.parametersValue, this.props.setParameterValue);
    }

}

const mapStateToProps = (state: IState) => {
    return {
        report: state.getIn(['report']),
        model: state.getIn(['report', 'model']),
        parametersValue: state.getIn(['report', 'parametersValue']),
        systemParameters: state.getIn(['report', 'cachedData', 'comboValues', 'systemParameters']),
        openDescriptor: state.getIn(['report', 'fileDescriptor']),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setObjectProperties: (path: string[], value: any) => { dispatch(ReportActions.setObjectProperties(path, value)); },
        setParameterValue: (parameterName: string, value: any) => { dispatch(ReportActions.setParameterValue(parameterName, value)); },
    };
}

ParametersTab.contextType = RunContext;

export default connect(mapStateToProps, mapDispatchToProps)(ParametersTab);
