/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { call, put, takeEvery } from 'redux-saga/effects';
import * as ReportActions from '../../../actions/reportActions';
import * as DocumentUtils from './documentUtils';


export function* undoSaga() {

      yield put( ReportActions.modelUndo() );
      yield call( DocumentUtils.updateClientArea );
}

export function* redoSaga() {

      yield put( ReportActions.modelRedo() );
      yield call( DocumentUtils.updateClientArea);
}



/**
 * Binding of event type and proper flow control for saga.
 */
export const undoRedoSagas = [
  takeEvery( ReportActions.Actions.DESIGNER_UNDO_ACTION, undoSaga ),
  takeEvery( ReportActions.Actions.DESIGNER_REDO_ACTION, redoSaga ),
];

