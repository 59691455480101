/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import '../../assets/uxpl/css/TitleEditor.css';

interface ITitleEditor {
  value?: string;
  onChangeComplete?: (value?: string) => void;
}

interface ITitleEditorState {
  value: string | undefined;
  originalValue: string | undefined;
  internal: boolean; // Defines if the latest value comes from an internal change.
  hasFocus: boolean;
}

export class TitleEditor extends React.Component<ITitleEditor, ITitleEditorState> {

  /**
   * As soon the props are passed, we save the value and adjust the internal state.
   * @param nextProps
   * @param prevState 
   */
  public static getDerivedStateFromProps(nextProps: ITitleEditor, prevState: ITitleEditorState) {

    if (prevState.internal) {
      return { internal: false };
    }

    return {
      value: nextProps.value,
      originalValue: nextProps.value,
    };
  }

  public state: ITitleEditorState = {
    value: undefined,
    originalValue: undefined,
    internal: false,
    hasFocus: false
  }

  private inputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  public render() {
    let value = this.state.value;
    if (value) {
      value = decodeURIComponent(value);
    }
    const classes = ["title-editor-content-editable"];
    if (this.state.hasFocus) {
      classes.push("focused");
    }

    // Process the text in order to replace double spaces...


    return (<span className={classes.join(' ')}>
      <input type="text"
        maxLength={60}
        ref={this.inputRef}
        value={value}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        spellCheck={false}
        className={this.state.hasFocus ? 'focused' : ''}
      />
    </span>);
  }


  /*private convertBlankSpace = (str: string|undefined|null) => {

    if (!str) {
      return null;
    }

    const parts: Array<JSX.Element> = [];
    let nextWord = '';
    let index = 0;
    for (const c of str) {

        if (c === ' ') {
          if (nextWord.length > 0) {
            parts.push((<span key={index++}>{nextWord}</span>));
            nextWord = '';
          }
          parts.push((<span key={index++}>&nbsp;</span>));
        }
        else {
          nextWord += c;
        }
    }

    if (nextWord.length > 0) {
      parts.push((<span key={index++}>{nextWord}</span>));
    }

    return parts;
  }*/


  // private onInput = (evt: React.SyntheticEvent<any>) => {
  //   this.updateValue();
  // }
  private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: e.target.value, internal: true });
  }

  private onFocus = () => {
    this.setState({ hasFocus: true });
  }

  private onBlur = () => {
    if (this.props.onChangeComplete) {
      if (this.state.value === this.state.originalValue || !this.state.value || this.state.value.trim().length === 0) {
        this.setState({ value: this.state.originalValue, internal: true, hasFocus: false });
      } else {
        this.setState({ hasFocus: false, internal: true }, () => {
          if (this.props.onChangeComplete)
            this.props.onChangeComplete(this.state.value !== undefined ? this.state.value.trim() : this.state.value);
        });
      }
    } else {
      this.setState({ hasFocus: false });
    }
  }

  /**
   * Controls step increments via arrows up/down and confirmation with enter
   */
  private onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

    switch (e.key) {

      case 'Enter': // Enter
        e.preventDefault();
        if (this.inputRef.current) {
          this.inputRef.current.blur();
        }
        break;
    }
  }


}
