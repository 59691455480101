/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { EditableTable, TextInput } from '@jss/js-common';
import { advancedNameValidator, advancendNotEmptyClassNameValidator, advancendNotEmptyMethodNameValidator } from '@jss/js-common/src/utils/validators';
import { ResourcePickerDialog, RunContext } from '@jss/js-repository';
import { getRealName, IRepositoryItemDescriptor, MIME_TYPES, RepositoryApi } from '@jss/js-rest-api';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import '../../assets/css/DataAdapterTypePicker.css';
import i18n from '../../i18n';

interface IJavaBeanDataAdapterEditor {
    name?: string,
    factoryClass?: string,
    methodName?: string,
    useFieldDescription?: boolean,
    classpaths?: string[],
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

export class JavaBeanDataAdapterEditor extends React.Component<IJavaBeanDataAdapterEditor> {

    private getColumnNames = () => {
        if (this.props.classpaths) {
            if (Array.isArray(this.props.classpaths)) {
                return this.props.classpaths;
            } else {
                return [this.props.classpaths];
            }
        }
        return [];
    }

    private getRows = () => {
        const result: string[][] = [];
        const rows = [...this.getColumnNames()];
        rows.forEach((columnName: string) => {
            result.push([columnName]);
        });
        return result;
    }

    private onCellChange = (row: number, col: number, value: string, isEditing: boolean) => {
        const rows = this.props.classpaths ? [...this.props.classpaths] : [];
        if (value.trim().length === 0 && !isEditing) {
            rows.splice(row, 1);
        } else {
            rows[row] = value;
        }
        if (!isEditing) {
            this.notifyChange({ classpaths: rows });
        }
    }

    private onRowDelete = (rowIndex: number) => {
        const newColumns = [...this.props.classpaths];
        newColumns.splice(rowIndex, 1);
        this.notifyChange({ classpaths: newColumns });
    }

    public openBrowserDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = [];
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('datasource.common.dialog.resource'),
                mode: 'open',
                title: i18n.t('datasource.common.dialog.title'),
                onFileSelected: this.confirmFile,
                defaultPath: defaultPath,
                allowSearch: true,
                allowedMimes: [MIME_TYPES.JAR, MIME_TYPES.ZIP, 'jar', 'zip'],
            }
        );
    }

    private confirmFile = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => {
        const path = folderPath.length > 0 ? `${RepositoryApi.inst().getParentPath(folderPath)}/${getRealName(file)}` : `/${getRealName(file)}`;  
        const newFiles = [...this.props.classpaths, path];
        this.notifyChange({ classpaths: newFiles });
    }

    public render() {
        const tableRows = this.getRows();
        return (<div className="tc-jsw-dataadapter-editor">

            <div className="tc-jsw-form-wrapper" style={{ flex: 1, alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}>

                <TextInput label={i18n.t('datasource.bean.name')}
                    help={i18n.t('datasource.bean.name.help')}
                    id="data-adapter-name"
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.bean.factoryClass')}
                    help={i18n.t('datasource.bean.factoryClass.help')}
                    id="bean-factoryclass-input"
                    value={this.props.factoryClass}
                    onChange={this.onFactoryClassChange}
                    advancedValidator={advancendNotEmptyClassNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />
                
                <TextInput label={i18n.t('datasource.bean.methodName')}
                    help={i18n.t('datasource.bean.methodName.help')}
                    id="bean-methodname-input"
                    value={this.props.methodName}
                    onChange={this.onMethodNameChange}
                    advancedValidator={advancendNotEmptyMethodNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.props.useFieldDescription}
                            onChange={this.onUseFieldDescriptionChange}
                            name="useFieldDescription"
                            color="primary"
                            readOnly={this.props.readOnly}
                        />
                    }
                    label={i18n.t('datasource.bean.useFiledDescription')}
                />

                <div style={{ display: 'flex', alignItems: 'stretch' }} id="tableContainer">
                    <EditableTable cellStyle={{ textAlign: 'left' }} onCellChange={this.onCellChange} cellData={tableRows} numRows={this.props.classpaths ? this.props.classpaths.length + 1 : 1} columnNames={[i18n.t('datasource.bean.classpaths'), '']}
                        columnActions={[{ onClick: this.onRowDelete, icon: <DeleteIcon /> }]} onAddAction={this.openBrowserDialog} readOnly={this.props.readOnly}/>
                </div>
            </div>
        </div>);
    }



    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private onFactoryClassChange = (str: string) => {
        this.notifyChange({ factoryClass: str });
    }

    private onMethodNameChange = (str: string) => {
        this.notifyChange({ methodName: str });
    }

    private onUseFieldDescriptionChange = (event: React.FormEvent<HTMLElement>) => {
        this.notifyChange({ useFieldDescription: (event.target as HTMLInputElement).checked });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }
}

JavaBeanDataAdapterEditor.contextType = RunContext;