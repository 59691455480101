/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const DualLevelPieChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="422" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 422" {...props}>
    <defs>
       <clipPath>
          <path fill="none" d="M0 0H535V318H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="422" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M10 10H545V328H10z" className="highcharts-plot-background" />
    <path fill="none" d="M10 10H545V328H10z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="0.1">
          <path fill="#7cb5ec" stroke="#fff" strokeLinejoin="round" d="M267.481 65.6a93.4 93.4 0 0145.924 12.06L267.5 159h0z" className="highcharts-point highcharts-color-0" transform="translate(10 10)" />
          <path fill="#434348" stroke="#fff" strokeLinejoin="round" d="M313.486 77.705a93.4 93.4 0 0115.586 11.064L267.5 159h0z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#90ed7d" stroke="#fff" strokeLinejoin="round" d="M329.142 88.83a93.4 93.4 0 016.952 133.561L267.5 159h0z" className="highcharts-point highcharts-color-2" transform="translate(10 10)" />
          <path fill="#f7a35c" stroke="#fff" strokeLinejoin="round" d="M336.03 222.46a93.4 93.4 0 01-10.472 9.703L267.5 159h0z" className="highcharts-point highcharts-color-3" transform="translate(10 10)" />
          <path fill="#8085e9" stroke="#fff" strokeLinejoin="round" d="M325.485 232.22a93.4 93.4 0 01-81.653 17.131L267.5 159h0z" className="highcharts-point highcharts-color-4" transform="translate(10 10)" />
          <path fill="#f15c80" stroke="#fff" strokeLinejoin="round" d="M243.741 249.328a93.4 93.4 0 01-68.884-102.196L267.5 159h0z" className="highcharts-point highcharts-color-5" transform="translate(10 10)" />
          <path fill="#e4d354" stroke="#fff" strokeLinejoin="round" d="M174.869 147.04a93.4 93.4 0 0150.8-71.549L267.5 159h0z" className="highcharts-point highcharts-color-6" transform="translate(10 10)" />
          <path fill="#2b908f" stroke="#fff" strokeLinejoin="round" d="M225.752 75.45a93.4 93.4 0 0141.618-9.85l.13 93.4h0z" className="highcharts-point highcharts-color-7" transform="translate(10 10)" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-pie-series highcharts-tracker" data-z-index="0.1">
          <path fill="#AFE8FF" stroke="#fff" strokeLinejoin="round" d="M267.472 23.9A135.1 135.1 0 01333.9 41.343l-26.56 47.063a81.06 81.06 0 00-39.857-10.466z" className="highcharts-point highcharts-color-0" transform="translate(10 10)" />
          <path fill="#76767B" stroke="#fff" strokeLinejoin="round" d="M334.018 41.41a135.1 135.1 0 0122.544 16.003l-35.625 40.635a81.06 81.06 0 00-13.526-9.602z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#C3FFB0" stroke="#fff" strokeLinejoin="round" d="M356.664 57.502a135.1 135.1 0 0110.055 193.191l-39.688-36.677a81.06 81.06 0 00-6.033-115.915z" className="highcharts-point highcharts-color-2" transform="translate(10 10)" />
          <path fill="#FFD68F" stroke="#fff" strokeLinejoin="round" d="M366.627 250.792a135.1 135.1 0 01-15.147 14.035l-33.592-42.33a81.06 81.06 0 009.088-8.422z" className="highcharts-point highcharts-color-3" transform="translate(10 10)" />
          <path fill="#B3B8FF" stroke="#fff" strokeLinejoin="round" d="M351.374 264.911a135.1 135.1 0 01-118.11 24.78l13.695-52.277a81.06 81.06 0 0070.865-14.867z" className="highcharts-point highcharts-color-4" transform="translate(10 10)" />
          <path fill="#FF8FB3" stroke="#fff" strokeLinejoin="round" d="M233.134 289.656a135.1 135.1 0 01-32.082-13.027l26.58-47.051a81.06 81.06 0 0019.248 7.816z" className="highcharts-point highcharts-color-5" transform="translate(10 10)" />
          <path fill="#FF7EA2" stroke="#fff" strokeLinejoin="round" d="M200.935 276.563a135.1 135.1 0 01-52.6-53.91L196 197.19a81.06 81.06 0 0031.56 32.347z" className="highcharts-point highcharts-color-6" transform="translate(10 10)" />
          <path fill="#FF6D91" stroke="#fff" strokeLinejoin="round" d="M148.27 222.533a135.1 135.1 0 01-14.775-80.7l53.602 6.867a81.06 81.06 0 008.866 48.42z" className="highcharts-point highcharts-color-7" transform="translate(10 10)" />
          <path fill="#FFFF87" stroke="#fff" strokeLinejoin="round" d="M133.512 141.7a135.1 135.1 0 014.24-20.348l51.899 15.06a81.06 81.06 0 00-2.544 12.208z" className="highcharts-point highcharts-color-8" transform="translate(10 10)" />
          <path fill="#FFF576" stroke="#fff" strokeLinejoin="round" d="M137.79 121.222a135.1 135.1 0 0157.103-76.153l29.043 45.573a81.06 81.06 0 00-34.262 45.691z" className="highcharts-point highcharts-color-9" transform="translate(10 10)" />
          <path fill="#F5E465" stroke="#fff" strokeLinejoin="round" d="M195.007 44.997a135.1 135.1 0 0111.985-6.79l24.203 48.318a81.06 81.06 0 00-7.19 4.073z" className="highcharts-point highcharts-color-0" transform="translate(10 10)" />
          <path fill="#5EC3C2" stroke="#fff" strokeLinejoin="round" d="M207.113 38.147a135.1 135.1 0 0160.2-14.247l.074 54.04a81.06 81.06 0 00-36.12 8.548z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
       </g>
    </g>
    <g className="highcharts-data-labels highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="6">
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(271 88)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">AK</tspan>
          <tspan x="5" y="16">AK</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(291 97)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">CA</tspan>
          <tspan x="5" y="16">CA</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(320 146)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">ID</tspan>
          <tspan x="5" y="16">ID</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(297 196)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">MT</tspan>
          <tspan x="5" y="16">MT</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(266 211)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">NM</tspan>
          <tspan x="5" y="16">NM</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(202 184)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">OR</tspan>
          <tspan x="5" y="16">OR</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(202 112)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">WA</tspan>
          <tspan x="5" y="16">WA</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(239 87)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">WY</tspan>
          <tspan x="5" y="16">WY</tspan>
       </text>
    </g>
    <g className="highcharts-data-labels highcharts-series-1 highcharts-pie-series highcharts-tracker" data-z-index="6">
       <path fill="none" stroke="#AFE8FF" d="M314.546 0c-5 0-9.584 16.75-11.112 22.552l-1.528 5.803" className="highcharts-data-label-connector highcharts-color-0" transform="translate(10 10)" />
       <path fill="none" stroke="#76767B" d="M368.162 24.439c-5 0-15.43 14.67-18.906 19.56l-3.476 4.89" className="highcharts-data-label-connector highcharts-color-1" transform="translate(10 10)" />
       <path fill="none" stroke="#C3FFB0" d="M437.381 150.5c-5 0-22.976.927-28.968 1.236l-5.992.31" className="highcharts-data-label-connector highcharts-color-2" transform="translate(10 10)" />
       <path fill="none" stroke="#FFD68F" d="M384.648 280.164c-5 0-17.227-13.21-21.303-17.613l-4.075-4.403" className="highcharts-data-label-connector highcharts-color-3" transform="translate(10 10)" />
       <path fill="none" stroke="#B3B8FF" d="M306.272 318c-5 0-8.682-15.01-9.91-20.883l-1.227-5.874" className="highcharts-data-label-connector highcharts-color-4" transform="translate(10 10)" />
       <path fill="none" stroke="#FF8FB3" d="M200.302 311.936c5 0 11.781-16.674 14.042-22.232l2.26-5.558" className="highcharts-data-label-connector highcharts-color-5" transform="translate(10 10)" />
       <path fill="none" stroke="#FF7EA2" d="M144.263 274.23c5 0 17.891-12.562 22.188-16.75l4.297-4.188" className="highcharts-data-label-connector highcharts-color-6" transform="translate(10 10)" />
       <path fill="none" stroke="#FF6D91" d="M100.075 188.6c5 0 22.708-3.228 28.611-4.303l5.903-1.076" className="highcharts-data-label-connector highcharts-color-7" transform="translate(10 10)" />
       <path fill="none" stroke="#FFFF87" d="M100.89 125.228c5 0 22.62 3.682 28.493 4.91l5.874 1.227" className="highcharts-data-label-connector highcharts-color-8" transform="translate(10 10)" />
       <path fill="none" stroke="#FFF576" d="M130.447 59.905c5 0 19.397 10.803 24.196 14.405l4.799 3.601" className="highcharts-data-label-connector highcharts-color-9" transform="translate(10 10)" />
       <path fill="none" stroke="#F5E465" d="M168.894 23c5 0 26.152 7.981 29.107 13.203l2.956 5.221" className="highcharts-data-label-connector highcharts-color-0" transform="translate(10 10)" />
       <path fill="none" stroke="#5EC3C2" d="M224.579 0c5 0 9.134 15.833 10.512 21.672l1.378 5.84" className="highcharts-data-label-connector highcharts-color-1" transform="translate(10 10)" />
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(320 -10)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Anchorage, AK</tspan>
          <tspan x="5" y="16">Anchorage, AK</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(373 14)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">San Francisco, CA</tspan>
          <tspan x="5" y="16">San Francisco, CA</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(442 141)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Boise, ID</tspan>
          <tspan x="5" y="16">Boise, ID</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(390 270)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Butte, MT</tspan>
          <tspan x="5" y="16">Butte, MT</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(311 308)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Albuquerque, NM</tspan>
          <tspan x="5" y="16">Albuquerque, NM</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(134 302)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Elgin, OR</tspan>
          <tspan x="5" y="16">Elgin, OR</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(65 264)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Eugene, OR</tspan>
          <tspan x="5" y="16">Eugene, OR</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(14 179)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Portland, OR</tspan>
          <tspan x="5" y="16">Portland, OR</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(14 115)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Kirkland, WA</tspan>
          <tspan x="5" y="16">Kirkland, WA</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(53 50)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Seattle, WA</tspan>
          <tspan x="5" y="16">Seattle, WA</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(66 13)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Walla Walla, WA</tspan>
          <tspan x="5" y="16">Walla Walla, WA</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(147 -10)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Lander, WY</tspan>
          <tspan x="5" y="16">Lander, WY</tspan>
       </text>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(63 340)">
       <rect width="429" height="67" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Anchorage, AK</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#AFE8FF" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-1" data-z-index="1" transform="translate(152.469 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>San Francisco, CA</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#76767B" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-2" data-z-index="1" transform="translate(296.938 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Boise, ID</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#C3FFB0" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-3" data-z-index="1" transform="translate(8 17)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Butte, MT</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#FFD68F" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-4" data-z-index="1" transform="translate(152.469 17)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Albuquerque, NM</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#B3B8FF" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-5" data-z-index="1" transform="translate(296.938 17)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Elgin, OR</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#FF8FB3" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-6" data-z-index="1" transform="translate(8 31)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Eugene, OR</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#FF7EA2" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-7" data-z-index="1" transform="translate(152.469 31)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Portland, OR</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#FF6D91" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-8" data-z-index="1" transform="translate(296.938 31)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Kirkland, WA</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#FFFF87" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-9" data-z-index="1" transform="translate(8 45)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Seattle, WA</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#FFF576" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-0" data-z-index="1" transform="translate(152.469 45)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Walla Walla, WA</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#F5E465" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-1" data-z-index="1" transform="translate(296.938 45)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Lander, WY</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#5EC3C2" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
       </g>
    </g>
 </svg>)};

export default DualLevelPieChartIcon;