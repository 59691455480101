/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const ExpressionTypeEnum = new PEnum({
    id: 'type', label: 'ExpressionTypeEnum.label', default: 'default', 
    items: [
        { key: 'default', value: 'ExpressionTypeEnum.default' },
        { key: 'simpleText', value: 'ExpressionTypeEnum.simpleText' }, 
    ]
}); 

