/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const MapTypeEnum = new PEnum({
    id: 'mapType', label: 'MapType.label',
    items: [
        { key: 'roadmap', value: 'MapType.roadmap'},
        { key: 'satellite', value: 'MapType.satellite'},
        { key: 'terrain', value: 'MapType.terrain'},
        { key: 'hybrid', value: 'MapType.hybrid'},
    ]
}); 
 
