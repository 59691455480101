/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg, UiProperty, UiPropertyProps } from '../UiProperty';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import { APDescriptor } from '../APDescriptor';
import ReactResizeDetector from 'react-resize-detector';
import i18n from '../../../../../i18n';

const TYPEID = 'BScrollableCategory';
reg(TYPEID, (mc) => { return <UPScrollableCategories mcontext={mc} />; });

export class PScrollableCategories extends APDescriptor {

    public constructor(init: Partial<PScrollableCategories>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

export class UPScrollableCategories extends React.Component<UiPropertyProps> {
    public state = {
        width: 0,
        height: 0
    }
    private a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    private onResize = (width: number | undefined, height: number | undefined) => {
        if (width !== this.state.width || height !== this.state.height) {
            this.setState({ width: width ? width : 0, height: height ? height : 0 });
        }
    }

    render() {
        const layouts = this.props.mcontext.descriptor.layouts;
        if (layouts?.length > 0) {
            const currentTab = this.props.mcontext.rootModel.getIn(['widgetStatus', this.props.mcontext.descriptor.id, 'tabIndex'], 0) as number;
            const selection = Math.min(currentTab, layouts.length - 1);
            return <ReactResizeDetector handleWidth={true} handleHeight={true} onResize={this.onResize}>
                <div className='jr-jrws-pccontainer-root'>
                    <div style={{ maxWidth: this.state.width, minWidth: 0 }} className={"jr-mPanel-section-subheader mui"}>
                        <Tabs
                            value={selection}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs"
                            className={"jr-mTabs jr-mTabsMedium mui"}>
                            {layouts.map((key, index) => { return <Tab key={index} classes={{ wrapper: "jr-mTab-label mui"}} className={"jr-mTab mui"} label={i18n.t(key.label)} {...this.a11yProps(index)} /> })}
                        </Tabs>
                    </div>

                    <div className='jr-jrws-pccontainer-scrollable-content'
                        style={{ maxWidth: this.state.width - 10, minWidth: 0 }}>
                        <TabContext value={String(selection)}>
                            {layouts?.map((key, index) => {
                                key.readonly = this.props.mcontext.descriptor.readonly;
                                return <TabPanel key={'tp.' + key.id + '.' + index} value={String(index)} className='jr-jrws-pccontainer-content-nopadding'>
                                    <UiProperty key={'scui.' + key.id + '.' + index} mcontext={{ ...this.props.mcontext, descriptor: key }} />
                                </TabPanel>
                            })}
                        </TabContext>
                    </div>
                </div>
            </ReactResizeDetector>
        }
    }

    private handleChange = (event, value) => {
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'tabIndex'], value, false, true);
    }

}


