/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { fromJS, Map, List, Collection, Set } from 'immutable';
import { Action, Dispatch } from 'redux';
import { call, delay, put, select } from 'redux-saga/effects';
import { CrosstabTypes } from '../../../components/common/JrxmlModel/reader/JrxmlCrosstabUtils';
import { TableTypes } from '../../../components/common/JrxmlModel/reader/JrxmlTableUtils';

import * as ReportActions from '../../../actions/reportActions';
import { IState } from '../../../reducers';
import store from '../../../store/store';
import { areaContainsPoint, Direction, IElementPosition, IPoint, IRectangle } from '../../../types/geometry';
import { getOrderedBands } from '../document/documentFactory';
import { BandTypes, ElementTypes } from '../document/elementTypes';
import { getEditorIndex } from '../../../reducers/report/reportReducer';
import { getTableObjectAbsolutePosition, isTableStructure, tableClientAreaProvider, tablePositionFinderProvider } from '../../../components/report/table/TableUtils';
import { i18n, initializeMapWithObject } from '@jss/js-common';
import { getListObjectAbsolutePosition, listClientAreaProvider, listPositionFinderProvider } from '../../../components/report/list/ListUtils';
import { crosstabClientAreaProvider, crosstabPositionFinderProvider, getCrosstabObjectAbsolutePosition } from '../../../components/report/crosstab/CrosstabUtils';
import { IViewport } from '../../../components/report/IViewport';


export const PAGE_VERTICAL_OFFSET = 50;

export const PAGE_HORIZONTAL_MARGIN_MIN = 40;

const subeditorClientAreaProviders = {
  TABLE: tableClientAreaProvider,
  LIST: listClientAreaProvider,
  CROSSTAB: crosstabClientAreaProvider,
}

const positionFinderProviders = {
  TABLE: tablePositionFinderProvider,
  LIST: listPositionFinderProvider,
  CROSSTAB: crosstabPositionFinderProvider,
}

const absolutePositionProviders = {
  TABLE: getTableObjectAbsolutePosition,
  LIST: getListObjectAbsolutePosition,
  CROSSTAB: getCrosstabObjectAbsolutePosition,
}

export const getBandLabel = (bandType) => {
  if (bandType === BandTypes.BAND_TITLE) {
      return i18n.t('band.title');
  } else if (bandType === BandTypes.BAND_PAGE_HEADER) {
      return i18n.t('band.pageHeader');
  } else if (bandType === BandTypes.BAND_COLUMN_HEADER) {
      return i18n.t('band.columnHeader');
  } else if (bandType === BandTypes.BAND_COLUMN_FOOTER) {
      return i18n.t('band.columnFooter');
  } else if (bandType === BandTypes.BAND_PAGE_FOOTER) {
      return i18n.t('band.pageFooter');
  } else if (bandType === BandTypes.BAND_LAST_PAGE_FOOTER) {
      return i18n.t('band.lastPageFooter');
  } else if (bandType === BandTypes.BAND_SUMMARY) {
      return i18n.t('band.summary');
  } else if (bandType === BandTypes.BAND_NO_DATA) {
      return i18n.t('band.noData');
  } else if (bandType === BandTypes.BAND_BACKGROUND) {
      return i18n.t('band.background');
  }
  return 'unknown';
}

/**
 * Given a model, process the size of a container containing all the elements displayed on the screen.
 * This area is then properly modified to fit the screen, in order to keep the document in the senter of the screen
 * when possible.
 * 
 * // TODO: Since we use the state taken from the store anyway, a good question is why we provide an argument
 * at all....
 * 
 * @param {IState} model 
 * @return a plain javascript object { x, y, width, height }
 */
export const calculateClientArea = (state: IState, cachedPositionFinder?: IPositionFinder): { x: number, y: number, width: number, height: number } => {

  const currentEditorIndex = getEditorIndex(state.get('report'));
  if (currentEditorIndex !== undefined) {
    const currentEditor = state.getIn(['report', 'subeditors', currentEditorIndex]) as Map<string, any>;
    const clientAreaProvider = subeditorClientAreaProviders[currentEditor.get('type')];
    if (clientAreaProvider) {
      return clientAreaProvider(state, currentEditor);
    } else {
      //no client area provider defined, return default
      return { x: 0, y: 0, width: 0, height: 0 };
    }
  }


  //standard client area
  const positionFinder = cachedPositionFinder ? cachedPositionFinder : createObjectAbsolutePositionFinder(state);
  // Recalculating the client area is very expensive, in particular it is expensive to recalculate
  // all the element absolute position. We start by caching as much we can for the bands...
  // In a single pass we calculate both page height (sum of the band heights) and each band position...
  // const { pageHeight, cachedContainerPositions } = state.getIn(['report', 'model', 'bands']).reduce( 
  //         (cache: { pageHeight: number, cachedContainerPositions: any }, b: Map<string, any>) => {
  //           cache.cachedContainerPositions['bands/' + b.get('id')] = cache.pageHeight;
  //           cache.pageHeight += b.get('height');
  //           return cache;
  //       }, { pageHeight: 0, cachedContainerPositions: {} } );

  const model = state.getIn(['report', 'model']) as Map<string, any>;
  const pageHeight = positionFinder.getPageHeight();
  const pageWidth = model.get('docWidth');

  // const pageHeight = calculatePageDesignHeight(state);

  // Option 1: the designing area is calculated all the time...
  // Pro: scrollbars used on for relevant content area
  // Cons: document moving on dragging outside visible area due to client area growing
  let ca = { x: 0, y: 0, width: pageWidth, height: pageHeight };

  // Option 2: the design area is fixed and centered on the screen.
  // Pro: document positioned in some kind of pre-defined way
  // Cons: 1.scrollbars related to a wide area, not fully relevant
  //       2.document floating on the screen due to the big design area...
  // let ca = { x: -2000,
  //              y: -2000, 
  //              width: 2000 + state.getIn(['report','model', 'width']),
  //              height: 2000 + pageHeight };
  // if (1 === 1) {
  //   return ca;
  // }
  // Option 3: should be a mix of the two, with:
  //           - ability to grow the area on demand
  //           - sincronyze the scrollbars on a calculated design area, while no need to
  //             recalculate it on dragging.

  // Let's aggregate in a temporary list all the objects on screen...
  const screenObjects = (state.getIn(['report', 'model', 'elements']) as List<Map<string, any>>).toList()
    .concat((store.getState().getIn(['report', 'interactionElements']) as List<Map<string, any>>).toList());



  if (screenObjects.size > 0) {

    // Find elements outside the document on the top left side
    ca = screenObjects.reduce((rect: IRectangle, o: Map<string, any>) => {

      // let container = { x: 0, y: 0 };
      // const containerPath = o.get('path');
      // if (containerPath) {
      //   if (typeof cachedContainerPositions[containerPath] === "undefined") {
      //     cachedContainerPositions[containerPath] = getObjectAbsolutePosition( state.getIn(['report', 'model', ...containerPath.split('/')]) );
      //   }
      //   container = cachedContainerPositions[containerPath];
      // }
      // const p = { x: container.x + o.get('x'), y: container.y + o.get('y') }; // getObjectAbsolutePosition(o);
      if (!(o instanceof Map)) {
        //console.log("Not instance of Map", o);
        return rect;
      }

      const p = positionFinder.findPosition(o);

      if (p.renderElement) {
        rect.x = p.x < rect.x ? p.x : rect.x;  // Find min x
        rect.y = p.y < rect.y ? p.y : rect.y;  // Find min y
        rect.width = p.x + o.get('width') > rect.width ? p.x + o.get('width') : rect.width; // Find max width
        rect.height = p.y + o.get('height') > rect.height ? p.y + o.get('height') : rect.height; // Find max height
      }
      return rect;
    }, ca);

    // ca.y = screenObjects.reduce((min: number, o: Map<string, number>) => min = o.get('y') < min ? o.get('y') : min, ca.y);

    // Find elements outside the document on the right bottom
    // ca.width = screenObjects.reduce((max: number, o: Map<string, number>) => max = (o.get('x') + o.get('width')) > max ? o.get('x') + o.get('width') : max, ca.width);
    // ca.height = screenObjects.reduce((max: number, o: Map<string, number>) => max = (o.get('y') + o.get('height')) > max ? o.get('y') + o.get('height') : max, ca.height);
  }

  // Expand the client area with a margin of 10 pixels..
  ca.x -= 10;
  ca.y -= 10;
  ca.width += 10;
  ca.height += 10;

  // Check if we can keep the document to the center when we have enough space...


  const viewport = ((state.getIn(["report", "viewport"]) as Map<string, any>).toJS() as unknown) as IViewport;
  const zoom = state.getIn(["report", "zoom"]) as number;
  const fullWidth = (ca.width - ca.x) * zoom;
  const fullHeight = (ca.height - ca.y) * zoom;

  if (fullWidth < viewport.width) {

    // Calculate the median size (which is the biggest half from the middle of the document)
    const mw = (state.getIn(['report', 'model', 'docWidth']) as number) / 2;
    const medianW = Math.max(-ca.x + mw, ca.width - mw);

    if (2 * medianW * zoom <= viewport.width) {
      ca.x = mw - medianW;
      ca.width = medianW + mw;
    }
    else if (-ca.x + mw > ca.width - mw) {
      ca.x = mw - medianW;
      ca.width = (viewport.width + ca.x * zoom) / zoom;
    } else {
      ca.width = medianW + mw;
      ca.x = - (viewport.width - ca.width * zoom) / zoom;
    }

  }


  if (fullHeight < viewport.height) {

    // Calculate the median size (which is the biggest half from the middle of the document)
    const mh = pageHeight / 2;
    const medianH = Math.max(-ca.y + mh, ca.height - mh);

    if (2 * medianH * zoom <= viewport.height) {
      ca.y = mh - medianH;
      ca.height = medianH + mh;
    }
    else if (-ca.y + mh > ca.height - mh) {
      ca.y = mh - medianH;
      ca.height = (viewport.height + ca.y * zoom) / zoom;
    } else {
      ca.height = medianH + mh;
      ca.y = - (viewport.height - ca.height * zoom) / zoom;
    }

  }

  return ca;

}


/**
 * Calculates the size of the page at design time, which is the sum of the band heights
 */
export const calculatePageDesignHeight = (state?: IState): number => {

  const aState: IState = state ? state : store.getState();

  const bands: Map<string, Map<string, any>> = aState.getIn(['report', 'model', 'bands']) as Map<string, Map<string, any>>;

  return bands.reduce((top: number, band: Map<string, any>) => (top + band.get('height', 0)), 0);
}


/**
 * Converts HTML document page coordinates (or the mouse location returned by pageX/pageY) to the designer coordinate system.
 * 
 * We first calculate the position of (0,0) coordinates of the document top left corner in page coordinates...
 */
export const convertPageToDesignerCoords = (docPoint: IPoint): IPoint => {

  const cPoint = { ...docPoint };
  const report = store.getState().get('report');
  const currentEditorIndex = report.getIn(['currentEditorIndex']);
  let zoom;
  let scroll;
  let clientArea;
  if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
    const currentEditor = report.getIn(['subeditors', currentEditorIndex]);
    scroll = currentEditor.get('scroll').toJS();
    zoom = currentEditor.get('zoom');
    clientArea = currentEditor.get('clientArea').toJS();
  } else {
    scroll = report.get('scroll').toJS();
    zoom = report.get('zoom');
    clientArea = report.get('clientArea').toJS();
  }

  const viewport = ((store.getState().getIn(['report', 'viewport']) as Map<string, any>).toJS() as unknown) as IViewport;
  // Calculate the designing area Rect in viewport coordinates...

  //console.log(`client area ${JSON.stringify((clientArea))}`);
  //console.log(`viewport ${JSON.stringify(viewport)}`);
  //console.log(`scroll ${JSON.stringify(scroll)}`);


  const widthWithMarging = (clientArea.width + PAGE_HORIZONTAL_MARGIN_MIN * 2) * zoom;
  let horizontalOffset = 0;
  if (widthWithMarging < viewport.width) {
    //the viewport is bigger than the report
    horizontalOffset = (viewport.width - clientArea.width * zoom) / 2;
  } else {
    //the report is bigger thant the viewport
    horizontalOffset = PAGE_HORIZONTAL_MARGIN_MIN;
  }
  //console.log('horizontal offset ' + horizontalOffset);
  // Now we need to calculate the delta between this and mouse coords...
  const cPointLocal = {
    x: Math.round(((cPoint.x - (viewport.x - scroll.x * zoom + horizontalOffset))) / zoom ),
    y: Math.round(((cPoint.y - (viewport.y - scroll.y * zoom + PAGE_VERTICAL_OFFSET)) / zoom))
  }
  //console.log('original point ' + JSON.stringify(cPoint));
  //console.log('translated post ' + JSON.stringify((cPointLocal)));
  return cPointLocal;
}


/**
 * Find elements that can be dragged
 */
export const getDraggableElements = (state: IState): Collection<string, Map<string, any>> => {
  const currentEditorIndex = getEditorIndex(state.get('report')) as number | undefined;
  const selection = state.getIn(['report', 'selection']) as Map<string, any>;
  const model = state.getIn(['report', 'model']) as Map<string, any>;
  const result = {};
  let subeditorEditedElementId;
  if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
    const currentEditor = state.getIn(['report', 'subeditors', currentEditorIndex]) as Map<string, any>;
    const subeditorPath = currentEditor.get('editedResourceId');
    const subeditorEditedElement = model.getIn(subeditorPath.split('/')) as Map<string, any>;
    subeditorEditedElementId = subeditorEditedElement.get('id');
  }
  selection.forEach((info: { path: string[], type: string }, id: string) => {
    const element = model.getIn(info.path) as Map<string, any>;
    const type = element.get('type');
    //never allow the movment of a table section or the root element of a subeditor
    if (!isTableStructure(type) && id !== subeditorEditedElementId) {
      result[id] = element;
    }
  });
  return Map<string, any>(initializeMapWithObject(result));
}

/**
 * Find selected elements
 * // TODO: cache selected elements!
 *  
 * @param {*} elements 
 * @param {*} id 
 */
export const getSelectedElements = (selection: Set<string>, elements: Collection<string, Map<string, any>>): Collection<string, Map<string, any>> => {

  return elements.filter((ele: Map<string, any>) => {
    return selection.has(ele.get('id'));
  });
}

/**
 * Find the first element located at point, looking from the bottom of the list...
 * 
 * @returns Element (as immutable Map)
 */
export const findElementAt = (model: IState, point: IPoint, customFinder?: IPositionFinder): Map<string, any> => {

  const positionFinder = customFinder ? customFinder : createObjectAbsolutePositionFinder(store.getState());
  //console.log(point);
  const elements = model.get('elements');
    const bands: Map<string, Map<string, any>> = model.get('bands');
    const bandsOrder: List<string> = getOrderedBands(model);
    // let elementIdsToRender: string[]|null = null;

    const elementsOrder : Map<string, any>[] = [];


    // We render all the elements on the page, even the nested ones (i.e. frames and list contents).
    // A special editor mode may enable/disable interactivity on specific elements and show
    // elements that are usually hidden (i.e. the content of a table component).
    const getChildren = (currentNodeId: string, currentNode: Map<string, any>) =>{
      const childrenIds = currentNode.get('elementIds');
      if (childrenIds){
        childrenIds.forEach(childId => {
          getChildren(childId, elements.get(childId));
        });
      }
      elementsOrder.push(currentNode);
    }

    bandsOrder.forEach((bandId: string) => {
      const band = bands.get(bandId);
      const bandChildrenIds = band.get('elementIds');
      bandChildrenIds.forEach(childId => {
        getChildren(childId, elements.get(childId));
      });
    });


  const foundElement = elementsOrder.find((ele: Map<string, any>) => {
    const type = ele.get('type');
    if (type !== ElementTypes.ELEMENT_GROUP){
      const elementPosition = positionFinder.findPosition(ele);
      if (elementPosition.renderElement) {
        const elementAbsoluteRect = { ...immutableBoxToRect(ele), ...elementPosition };
        return areaContainsPoint(elementAbsoluteRect, point);
      }
    }
    return false;
  });
  return foundElement;
}


/**
 * Read an immutable object having the properties x, y, width and height and returns it
 * as plain javascript object with the properties { x, y, width, height } 
 * 
 * @param {*} immutableElement 
 */
export const immutableBoxToRect = (immutableElement: Map<string, any>): IRectangle => {
  return {
    x: immutableElement.get('x'),
    y: immutableElement.get('y'),
    width: immutableElement.get('width'),
    height: immutableElement.get('height')
  };
}

export const documentToRect = (model: Map<string, any>): IRectangle => {
  return {
    x: model.get('x'),
    y: model.get('y'),
    width: model.get('docWidth', 0),
    height: model.get('docHeight', 0),
  };
}


/**
 * Updates the client area, to be used inside a thunk action creator
 */
export function* updateClientArea(cachedPositionFinder?: IPositionFinder) {
  const state = yield select((aState: IState) => aState);
  const currentEditorIndex = getEditorIndex(state.get('report'));
  let clientArea;
  if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
    const currentEditor = state.getIn(['report', 'subeditors', currentEditorIndex]);
    clientArea = currentEditor.get('clientArea').toJS();
  } else {
    clientArea = state.getIn(['report', 'clientArea']).toJS();
  }

  // Hard way to check if the client area was changed..., we could definitively
  // find a better way, since the client area is expensive to calculate, not being cached.
  const newClientArea = calculateClientArea(state, cachedPositionFinder);
  if (clientArea.x !== newClientArea.x ||
    clientArea.y !== newClientArea.y ||
    clientArea.width !== newClientArea.width ||
    clientArea.height !== newClientArea.height) {

    yield put(ReportActions.clientAreaChanged(fromJS(newClientArea)));
  }
}

/**
 * Update scroll on drag. When the mouse goes out of the viewpoer, fire a scroll...
 * 
 * @param {box} ele 
 */
export const updateScrollsOnDrag = (aState?: IState, aDispatch?: Dispatch<Action>): void => {

  const theState = aState ? store.getState() : aState;
  const theDispatcher = aDispatch ? store.dispatch : aDispatch;

  if (!theState || !theDispatcher) {
    return;
  }


  const mouseLocation = ((theState.getIn(['report', 'mouse', 'currentLocation']) as Map<string, any>).toJS() as unknown) as IPoint;
  // Let's calculate the real visible rect, and translate it to clientArea coords system
  const visibleRect = getVisibleRect(theState);

  if (visibleRect === null) {
    return;
  }

  const currentEditorIndex = getEditorIndex(theState.get('report'));
  let zoom;
  if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
    const currentEditor = theState.getIn(['report', 'subeditors', currentEditorIndex]) as Map<string, any>;
    zoom = currentEditor.get('zoom') as number;
  } else {
    zoom = theState.getIn(['report', 'zoom']) as number;
  }

  if (mouseLocation.x < visibleRect.x) {
    theDispatcher(ReportActions.scrollX((visibleRect.x - mouseLocation.x) * zoom));
  }
  else if (mouseLocation.x > (visibleRect.x + visibleRect.width)) {
    theDispatcher(ReportActions.scrollX(((visibleRect.x + visibleRect.width) - (mouseLocation.x)) * zoom));
  }
  if (mouseLocation.y < visibleRect.y) {
    theDispatcher(ReportActions.scrollY((visibleRect.y - mouseLocation.y) * zoom));
  }
  else if (mouseLocation.y > (visibleRect.y + visibleRect.height)) {
    theDispatcher(ReportActions.scrollY(((visibleRect.y + visibleRect.height) - (mouseLocation.y)) * zoom));
  }
}

/**
 * Calculate the portion of document that is currently visible in the viewport
 * 
 * @param {*} aState - if not provided, the global state will be used instead.
 * @return rect in plain javascript object
 */
export const getVisibleRect = (aState?: IState): IRectangle | null => {
  const theState = aState ? store.getState() : aState;

  if (!theState) {
    return null;
  }

  const currentEditorIndex = getEditorIndex(theState.get('report'));
  let clientArea;
  let zoom;
  let scroll;
  if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
    const currentEditor = theState.getIn(['report', 'subeditors', currentEditorIndex]) as Map<string, any>;
    clientArea = currentEditor.get('clientArea').toJS();
    zoom = currentEditor.get('zoom') as number;
    scroll = currentEditor.get('scroll').toJS();
  } else {
    clientArea = (theState.getIn(['report', 'clientArea']) as Map<string, any>).toJS();
    zoom = theState.getIn(['report', 'zoom']) as number;
    scroll = (theState.getIn(['report', 'scroll']) as Map<string, any>).toJS();
  }

  // 1. Let's calculate the portion of the clientArea that is actually shown...
  const viewport = ((theState.getIn(['report', 'viewport']) as Map<string, any>).toJS() as unknown) as IViewport;
  const fullWidth = (clientArea.width - clientArea.x);
  const fullHeight = (clientArea.height - clientArea.y);

  const visibleWidth = viewport.width / zoom;
  const visibleHeight = viewport.height / zoom;

  // Let's calculate the real visible rect, and translate it to clientArea coords system
  return {
    x: (fullWidth - visibleWidth) / 2 - scroll.x / zoom + clientArea.x,
    y: (fullHeight - visibleHeight) / 2 - scroll.y / zoom + clientArea.y,
    width: visibleWidth,
    height: visibleHeight
  };
}

/**
 * Create an Immutable (Map) representation of an interaction figure (which is essentially an IRectabngle wrapped in a Map) from
 * a real element (which is an immutable Map with the x, y, width and height keys)
 * 
 * @param ele 
 * @param prefix - If provided, it will be prefixed to the original id to create a new key for this element.
 */
export const createInteractionFigure = (ele: Map<string, any>, id: string, transient: boolean, finder: IPositionFinder): Map<string, any> => {


  const { x, y } = finder.findPosition(ele);

  return fromJS({
    id,
    elementId: ele.get('id'),
    props: {
      key: id,
      style: {
        left: x,
        top: y,
        width: ele.get('width'),
        height: ele.get('height')
      },
      className: 'DragElement'
    },
    x,
    y,
    width: ele.get('width'),
    height: ele.get('height'),
    transient: transient === true
  }) as Map<string, any>;
}


/**
 * Creates a direction constant from a string...
 * @param str 
 */
export const directionFromString = (str: string) => {

  switch (str) {
    case 'top': return Direction.TOP;
    case 'top-left': return Direction.TOP_LEFT;
    case 'top-right': return Direction.TOP_RIGHT;
    case 'bottom': return Direction.BOTTOM;
    case 'bottom-left': return Direction.BOTTOM_LEFT;
    case 'bottom-right': return Direction.BOTTOM_RIGHT;
    case 'left': return Direction.LEFT;
    case 'right': return Direction.RIGHT;
  }

  return undefined;
}

/**
 * This function is invoked with a spawn to remove the figures after the animation is completed (fade out)
 * 
 * @param figureElementIds - The element ids to remove
 * @param delayMills - Time in milliseconds before removing the elements.
 */
export function* removeInteractionFiguresAfterAnimation(figureElementIds: string[], delayMills = 200) {
  // Wait delay ms for the animation to complete, before remove the shadow figures.
  yield delay(delayMills);
  yield put(ReportActions.removeInteractionFigures(figureElementIds));
  yield call(updateClientArea);
}

/**
 * Given a band, returns its top position.
 * The position is calculated all the time, we may consider to cache it...
 */
export const findBandTop = (bandId: string): number => {
  const bandsOrder: List<string> = getOrderedBands(store.getState().getIn(['report', 'model']) as Map<string, any>);
  const bands: Map<string, Map<string, any>> = store.getState().getIn(['report', 'model', 'bands']) as Map<string, Map<string, any>>;

  let top = 0;

  bandsOrder.forEach((id: string) => {
    const band = bands.get(id);
    if (id === bandId) {
      return false; // This is the proper way to break;
    }
    top += band.get('height', 0);
    return undefined;
  });

  return top;
}

/**
 * Given a band, returns its top position.
 */
export const findMaxBandHeight = (bandId: string): number => {

  const docHeight = store.getState().getIn(['report', 'model', 'docHeight']) as number;
  const bands = store.getState().getIn(['report', 'model', 'bands']) as Map<string, Map<string, any>>;

  const takenHeight = bands.reduce((h: number, band: Map<string, any>, key: string) => key === bandId ? h : h + band.get('height', 0), 0);

  return Math.max(0, docHeight - takenHeight);
}

export const isContainer = (parent: Map<string, any>) : boolean => {
  const type = parent.get('type');
  return type === ElementTypes.BAND ||
    type === ElementTypes.FRAME ||
    type === ElementTypes.LIST ||
    type === TableTypes.TABLE_CELL_NAME ||
    type === TableTypes.TABLE_GROUP_CELL_NAME ||
    type === TableTypes.TABLE_NO_DATA_CELL_NAME ||
    type === CrosstabTypes.CROSSTAB_CELL_NAME ||
    type === CrosstabTypes.CROSSTAB_COLUMN_HEADER_NAME ||
    type === CrosstabTypes.CROSSTAB_TOTAL_COLUMN_HEADER_NAME ||
    type === CrosstabTypes.CROSSTAB_ROW_HEADER_NAME ||
    type === CrosstabTypes.CROSSTAB_ROW_HEADER_NAME ||
    type === CrosstabTypes.CROSSTAB_HEADER_CELL_NAME ||
    type === CrosstabTypes.CROSSTAB_TITLE_CELL_NAME ||
    type === CrosstabTypes.CROSSTAB_HEADER_NAME || 
    type === CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME;
}

/**
 * Given a document point, this function looks for a possible container located at this point...
 * If found, it will return a path to the container (i.e. band/title or elements/some-element)
 * 
 * @param point
 */
export const findContainerAt = (point: IPoint, customFinder?: IPositionFinder): string | null => {
  const model = store.getState().getIn(['report', 'model']) as Map<string, any>;


  // Case 1: are we over a sophisticated container (i.e. a table or crosstab cell?)
  // TODO: implement this case

  // Case 2: are we over an element?
  const ele = findElementAt(model, point, customFinder);
  if (ele) {
    // If the element is a container itself, just return the element....
    if (isContainer(ele)) {
      return 'elements/' + ele.get('id');
    }
    else {
      return ele.get('path');
    }
  }

  // Case 2: are we over a band? Let's find out...
  const editorIndex = store.getState().getIn(['report', 'currentEditorIndex']);
  if (editorIndex === undefined || editorIndex < 0){
    //search in bands only if I'm on the main editor
    const topMargin = model.get('topMargin', 0);
    let progressiveTop = topMargin;
    const leftMargin = model.get('leftMargin', 0);
    const foundBandId = getOrderedBands(model).find((id: string) => {
      const b: Map<string, any> = model.getIn(['bands', id]) as Map<string, any>;
      if (point.y >= progressiveTop &&
        point.y < progressiveTop + b.get('height', 0) &&
        point.x >= 0 &&
        point.x <= model.get('docWidth', 0) + leftMargin
      ) { // If we reached our band, return, no band is covered
        return true;
      }
      progressiveTop += b.get('height', 0);
      return false;
  
    });
    if (foundBandId) {
      return 'bands/' + foundBandId;
    }
  
  }

  // No container found...
  return null;
}

/**
 * Return the position {x,y} of an element, band or whatever is presented on screen by converting
 * its coordinates relative to the container (if it has any) to its absolute position on the designer.
 * 
 * This is an expensive operation, consider to use findObjectAbsolutePositionWithCache().
 */
const getReportObjectAbsolutePosition = (element: Map<string, any>): IElementPosition => {
  const elementType = element?.get('type', null);

  if (elementType === ElementTypes.BAND) {
    const model = store.getState().getIn(['report', 'model'], null) as Map<string, any>;
    const topMargin = model.get('topMargin', 0);
    const leftMargin = model.get('leftMargin', 0);
    return { x: leftMargin, y: topMargin + findBandTop(element.get('id')), renderElement: true };
  } else if (elementType === ElementTypes.ELEMENT_GROUP){
    //element group are not visual so they don't have a position
    const containerPath = element.get('path', null);
    const container = store.getState().getIn(['report', 'model', ...containerPath.split('/')], null) as Map<string, any>;
    return getReportObjectAbsolutePosition(container);
  }

  // We assume object that are not bands or element groups to always have an x,y position
  const position = {
    x: element.get('x'),
    y: element.get('y'),
    renderElement: (elementType !== ElementTypes.TABLE && elementType !== ElementTypes.LIST && elementType !== ElementTypes.CROSSTAB),
  };

  // Let's check if this position is relative to a parent figure
  const containerPath = element.get('path', null);

  if (containerPath) {
    // We have found a container.
    const container = store.getState().getIn(['report', 'model', ...containerPath.split('/')], null) as Map<string, any> | undefined;
    if (container) {
      // We look recursively throug parents...
      const containerPosition = getReportObjectAbsolutePosition(container);
      position.x += containerPosition.x;
      position.y += containerPosition.y;
      position.renderElement = position.renderElement && containerPosition.renderElement;
    }
  }

  return position;
}

export const getObjectAbsolutePosition = (state: IState, element: Map<string, any>): IElementPosition => {
  const aState = state ? state : store.getState();

  const currentEditorIndex = getEditorIndex(aState.get('report'));
  if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
    const currentEditor = aState.getIn(['report', 'subeditors', currentEditorIndex]) as Map<string, any>;
    const positionFinderProvider = absolutePositionProviders[currentEditor.get('type')];
    if (positionFinderProvider) {
      return positionFinderProvider(aState, element, currentEditor.get('editedResourceId'));
    }
  }

  return getReportObjectAbsolutePosition(element);
}


export interface IPositionFinder {
  getPageHeight: () => number;
  findPosition: (o: Map<string, any>) => IElementPosition;
  debugCache: () => void;
}

/**
 * Return a function which will cache positions while used.
 */
export const createObjectAbsolutePositionFinder = (state?: IState): IPositionFinder => {

  const aState = state ? state : store.getState();
  //console.log('create finder');
  const currentEditorIndex = getEditorIndex(aState.get('report'));
  if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
    const currentEditor = aState.getIn(['report', 'subeditors', currentEditorIndex]) as Map<string, any>;
    const positionFinderProvider = positionFinderProviders[currentEditor.get('type')];
    if (positionFinderProvider) {
      const editedResourceId = currentEditor.get('editedResourceId');
      return positionFinderProvider(aState, editedResourceId);
    }
  }
  return createReportAbsolutePositionFinder(state);
}

export const createReportAbsolutePositionFinder = (state?: IState): IPositionFinder => {

  const aState = state ? state : store.getState();

  let pageHeight = 0;
  let cachedContainerPositions: any = {};

  const model = aState.getIn(['report', 'model']) as Map<string, any> | undefined;

  if (model) {
    // 1. prepare a basic cache:
    const topMargin = model.get('topMargin', 0);
    const leftMargin = model.get('leftMargin', 0);
    const bandsOrder: List<string> = getOrderedBands(model);
    const bands: Map<string, Map<string, any>> = model.get('bands');
    const c = bandsOrder.reduce(
      (cache: { pageHeight: number, cachedContainerPositions: any }, id: string) => {
        const b: Map<string, any> = bands.get(id);
        cache.cachedContainerPositions['bands/' + b.get('id')] = { x: leftMargin, y: topMargin + cache.pageHeight, renderElement: true };
        cache.pageHeight += b.get('height', 0);
        return cache;
      }, { pageHeight: 0, cachedContainerPositions: {} });

    pageHeight = c.pageHeight;
    cachedContainerPositions = c.cachedContainerPositions;
  }

  return {
    getPageHeight: () => pageHeight,
    findPosition: (o: Map<string, any>): IElementPosition => {
      let container = { x: 0, y: 0, renderElement: true };

      const type = o.get('type'); 
      if (type === ElementTypes.BAND) {
        return { ...cachedContainerPositions['bands/' + o.get('id')] };
      }

      let x = 0;
      let y = 0;
      if (type !== ElementTypes.ELEMENT_GROUP){
        x = o.get('x');
        y = o.get('y');
      }

      const containerPath = o.get('path');
      if (containerPath) {
        if (cachedContainerPositions[containerPath] === undefined) {
          const containerElement = aState.getIn(['report', 'model', ...containerPath.split('/')]) as Map<string, any>;
          const containerPosition = getReportObjectAbsolutePosition(containerElement);
          const containerType = containerElement.get('type');
          if (containerType === ElementTypes.TABLE || containerType === ElementTypes.LIST || containerType === ElementTypes.CROSSTAB) {
            containerPosition.renderElement = false;
          }
          cachedContainerPositions[containerPath] = containerPosition;
        }
        container = cachedContainerPositions[containerPath];
      }
      const result = { x: container.x + x, y: container.y + y, renderElement: container.renderElement };
      return result;
    },
    debugCache: () => { console.log(cachedContainerPositions) }
  }
}
