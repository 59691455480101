/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const OrderEnum = new PEnum({
    id: 'order', label: 'Order.label',
    items: [
        { key: 'Ascending', value: 'Order.Ascending' },
        { key: 'Descending', value: 'Order.Descending' }
    ]
});

