export const it = {
  components: {
    filesTable: {
      filename: "Nome File",
      type: "Tipo",
      lastmodified: "Ultima Modifica",
    }
  },
  common: {
    actions: {
      menu: {
        save: "Salva",
        saveas: "Salva come...",
        resourceinfo: "Informazioni Risorsa",
        upload: "Importa",
        download: "Esporta",
        exit: "Esci",
        undo: "Indietro",
        redo: "Avanti",
        zoomin: "Ingrandisci",
        zoomout: "Rimpicciolisci",
        zoomlevel: "Livello Zoom",
        help: "Guida",
        delete: "Elimina",
      }
    },
    button: {
      cancel: "Annulla",
      exitEditor: "Esci"
    },
    dialog: {
      exitWithoutSave: "Sei sicuro di voler uscire dall'editor senza salvare? Tutte le modifiche andranno perse"
    }
  },
}