/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import BarChartIcon from "../../../../../../assets/icons/charts/bar.svg";
import { PPropertyExpression } from "../../../ui/primitive/UPValueExpession";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { PInt } from "../../../ui/primitive/UPInteger";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { PColor } from "../../../ui/primitive/UPColor";

export const FFusionChart3DProps = {

    showCanvasBg: new PPropertyExpression({ id: 'showCanvasBg', vtype: new PBoolean({ id: 'showCanvasBg', label: 'Show Background', canBeNull: true }) }),
    canvasBgDepth: new PPropertyExpression({ id: 'canvasBgDepth', vtype: new PInt({ id: 'canvasBgDepth', label: 'Depth' }) }),

    showCanvasBase: new PPropertyExpression({ id: 'showCanvasBase', vtype: new PBoolean({ id: 'showCanvasBase', label: 'Show Base', canBeNull: true }) }),
    canvasBaseDepth: new PPropertyExpression({ id: 'canvasBaseDepth', vtype: new PInt({ id: 'canvasBaseDepth', label: 'Depth' }) }),
    canvasBaseColor: new PPropertyExpression({ id: 'canvasBaseColor', vtype: new PColor({ id: 'canvasBaseColor', label: 'Color' }) }),
};

export const MFusionChart3DCommon: APDescriptor = {
    id: 'canvas3D', type: 'fusion.chart.canvas3d', label: 'Canvas 3D', icon: BarChartIcon,
    layouts: [
        new PAccordeon({
            id: 'fusion.chart.canvas3D', label: 'Canvas 3D', layouts: [
                new PAccordeon({ id: 'fusion.chart.canvas3D.bg', label: 'Background', layouts: [FFusionChart3DProps.showCanvasBg, FFusionChart3DProps.canvasBgDepth] }),
                new PAccordeon({ id: 'fusion.chart.canvas3D.base', label: 'Base', layouts: [FFusionChart3DProps.showCanvasBase, FFusionChart3DProps.canvasBaseDepth, FFusionChart3DProps.canvasBaseColor] }),
            ]
        }),
    ]
};

