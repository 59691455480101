/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const ChartProIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/palette/charts-pro" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect id="bar-3" fill="#B6D978" x="12" y="6" width="4" height="10"></rect>
                <rect id="bar-2" fill="#7CC344" x="6.5" y="4" width="4" height="12"></rect>
                <rect id="bar-1" fill="#318DDE" x="1" y="1" width="4" height="15"></rect>
                <path d="M14,6.87022427 L15.8014959,10.5204536 L19.8297632,11.1057951 L16.9148816,13.9471024 L17.6029918,17.9590927 L14,16.0648879 L10.3970082,17.9590927 L11.0851184,13.9471024 L8.17023685,11.1057951 L12.1985041,10.5204536 L14,6.87022427 Z" id="Star" stroke="#FFFFFF" fill="#F57F4F"></path>
            </g>
        </svg>
    );
}
export default ChartProIcon;