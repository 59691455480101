/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { CalculationEnum } from "../enums/CalculationEnum";
import { IncrementTypeEnum } from "../enums/IncrementTypeEnum";
import { ResetTypeEnum } from "../enums/ResetTypeEnum";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PJavaClass } from "../../ui/primitive/UPJavaClass";
import { PConnectedTable } from "../../ui/tables/UPConnectedTable";
import { PTableElement } from "../../ui/tables/UPTableElement";
import { PEnumDatasets } from "./UPDatasetCombo";
import { PDatasetRun } from "./UPDatasetRun";
import ParameterIcon from "../../../../../assets/icons/outline/parameters.svg";
import VariableIcon from "../../../../../assets/icons/outline/variables.svg";
import { DsObject, DsType, PDsObject } from "./UPDsObject";

export const FDatasetRun = {
    subDataset: new PEnumDatasets({ id: 'subDataset', label: 'Dataset' }),
    parametersMapExpression: new PExpression({ id: 'parametersMapExpression', label: 'Values Map', description: 'Return a Map<String, Object> of parameter name, parameter value for the parameters defined in the selected dataset' }),
    dataSourceExpression: new PExpression({ id: 'dataSourceExpression', label: 'Datasource' }),
    connectionExpression: new PExpression({ id: 'connectionExpression', label: 'Connection' })
};

export const FDatasetParameter = {
    name: new PDsObject({ id: 'name', label: 'Name', types: [DsObject.PARAMETER] }),
    expression: new PExpression({ id: 'expression', label: 'Expression' })
}

export const FReturnValue = {
    fromVariable: new PDsObject({ id: 'fromVariable', label: 'From Variable', types: [DsObject.VARIABLE] }),
    toVariable: new PDsObject({ id: 'toVariable', label: 'To Variable', types: [DsObject.VARIABLE], dataset: DsType.MAIN }),
    calculation: CalculationEnum,
    incrementerFactoryClassName: new PJavaClass({ id: 'incrementerFactoryClassName', label: 'Incrementer Factory Class Name' })
}

export const FElementDataset = {
    resetType: ResetTypeEnum,
    incrementType: IncrementTypeEnum,
    incrementWhenExpression: new PExpression({ id: 'incrementWhenExpression', label: 'Increment When' })
}
export const updateVars = new PConnectedTable({
    id: 'returnValues', label: 'Update Main Dataset Variables', description: 'You can update main dataset variables with a value',
    rowLabel: 'Return Value', rowLabelPaths: ['fromVariable'],
    layouts: [new PTableElement({
        label: 'Return Value',
        icon: VariableIcon,
        anchorOrigin: { vertical: 'center', horizontal: 'right' },
        transformOrigin: { vertical: 'center', horizontal: 'right' },
        layouts: [FReturnValue.fromVariable, FReturnValue.toVariable, FReturnValue.calculation, FReturnValue.incrementerFactoryClassName]
    })],
    default: { calculation: 'Sum', id: 'returnValue-' }
})
export const paramValues = new PAccordeon({
    id: 'Parameters', label: 'Set Parameters Values', description: 'Set parameter values for the selected dataset',
    layouts: [FDatasetRun.parametersMapExpression, new PConnectedTable({
        id: 'parameters', label: 'Values', description: 'Set value for parameters defined in the selected dataset',
        rowLabel: 'Parameter', rowLabelPaths: ['name'],
        layouts: [new PTableElement({
            label: 'Parameter',
            icon: ParameterIcon,
            anchorOrigin: { vertical: 'center', horizontal: 'right' },
            transformOrigin: { vertical: 'center', horizontal: 'right' },
            layouts: [FDatasetParameter.name, FDatasetParameter.expression]
        })],
        default: { name: 'Parameter', expression: '' }
    })]
})

export const fetchData = new PAccordeon({
    id: 'datasource', label: 'Fetch Data From', description: 'Fetch data from the connection or datasource defined below, if not, from DataAdapter selected in the dataset or it will take connection from the main dataset',
    layouts: [FDatasetRun.connectionExpression, FDatasetRun.dataSourceExpression]
})
export const MDatasetRun: APDescriptor = {
    type: 'MDatasetRun',
    id: 'datasetRunProeprtiesDescriptor',
    layouts: [new PAccordeon({
        id: 'datasetRun', label: 'Use Data From', path: 'datasetRun', expanded: true,
        layouts: [FDatasetRun.subDataset, new PDatasetRun({
            id: 'subDataset',
            layouts: [fetchData, paramValues, updateVars]
        })]
    })],
};


export const MDivElementDataset: APDescriptor = new PDiv({
    id: 'divdatasetRun', label: 'Data',
    layouts: [
        FElementDataset.resetType, FElementDataset.incrementType, FElementDataset.incrementWhenExpression,
        new PAccordeon({
            id: 'datasetRun', label: 'Use Data From', expanded: true, path: 'datasetRun',
            layouts: [
                FDatasetRun.subDataset, new PDatasetRun({
                    id: 'subDataset',
                    layouts: [fetchData, paramValues, updateVars]
                })
            ]
        })]

});

