
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Map } from 'immutable';
import { IState } from '../../../reducers';
import { findBandTop, IPositionFinder } from '../../../sagas/report/designer/documentUtils';
import { ElementTypes } from '../../../sagas/report/document/elementTypes';
import { IElementPosition } from '../../../types/geometry';
import { computeCrosstabRealSize, CrosstabTypes } from '../../common/JrxmlModel/reader/JrxmlCrosstabUtils';

export const CROSSTAB_NO_DATA_CELL_GAP = 20;


export const crosstabClientAreaProvider = (state: IState, editorInfo: Map<string, any>): { x: number, y: number, width: number, height: number } => {
    const crosstabPath = editorInfo.get('editedResourceId').split('/');
    const crosstab = state.getIn(['report', 'model', ...crosstabPath]) as Map<string, any>;
    const model = state.getIn(['report', 'model']) as Map<string, any>;
    const crosstabSize = computeCrosstabRealSize(crosstab, model);
    const crosstabNoDataCell = getCrosstabNoDataCell(crosstab, model);
    let noDataCellHeight = 0;
    if (crosstabNoDataCell) {
        noDataCellHeight = CROSSTAB_NO_DATA_CELL_GAP + crosstab.get('height');
    }
    return {
        x: -10, y: -10, width: crosstabSize.width, height: crosstabSize.height + noDataCellHeight,
    }
}

export const isCrosstabSection = (elementType: string): boolean => {
    return (elementType === CrosstabTypes.CROSSTAB_ROW_GROUP_NAME || elementType === CrosstabTypes.CROSSTAB_COLUMN_GROUP_NAME);
}

export const getCrosstabNoDataCell = (crosstab: Map<string, any>, model: Map<string, any>): Map<string, any> | undefined => {
    const childrenIds = crosstab.get('elementIds');
    const noDataCell = childrenIds.find((childId) => {
      const child = model.getIn(['elements', childId]) as Map<string, any>;
      const type = child.get('type');
      return type === CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME;
    });
    return noDataCell;
  }


/**
 * Return the position {x,y} of an element, band or whatever is presented on screen by converting
 * its coordinates relative to the container (if it has any) to its absolute position on the designer.
 * 
 * This is an expensive operation, consider to use findObjectAbsolutePositionWithCache().
 */
export const getCrosstabObjectAbsolutePosition = (state: IState, element: Map<string, any>, editedCrosstabPath: string): IElementPosition => {
    const elementType = element.get('type', null);

    if (elementType === ElementTypes.BAND) {
        const model = state.getIn(['report', 'model'], null) as Map<string, any>;
        const topMargin = model.get('topMargin', 0);
        const leftMargin = model.get('leftMargin', 0);
        return { x: leftMargin, y: findBandTop(element.get('id')) + topMargin, renderElement: false };
    } else if (elementType === ElementTypes.ELEMENT_GROUP){
        //element group are not visual so they don't have a position
        const containerPath = element.get('path', null);
        const container = state.getIn(['report', 'model', ...containerPath.split('/')], null) as Map<string, any>;
        return getCrosstabObjectAbsolutePosition(state, container, editedCrosstabPath);
    }

    if (elementType === CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME) {
        const containerPath = element.get('path', null);
        const crosstab = state.getIn(['report', 'model', ...containerPath.split('/')]) as Map<string, any>;
        const crosstabSize = computeCrosstabRealSize(crosstab, state.getIn(['report', 'model']) as Map<string, any>);
        return { x: 0, y: crosstabSize.height + CROSSTAB_NO_DATA_CELL_GAP, renderElement: true };
    }
    if (elementType === ElementTypes.CROSSTAB){
        if (`elements/${element.get('id')}` === editedCrosstabPath) {
            //render only the content of this table, not table inside table
            return {
                x: 0,
                y: 0,
                renderElement: true,
            };
        } else {
            //Im in a crosstab inside the crosstab
            return {
                x: element.get('x'),
                y: element.get('y'),
                renderElement: false,
            };
        }
    }

    if (isCrosstabSection(elementType)) {
        const crosstabParent = element.get('path', null);
        if (crosstabParent === editedCrosstabPath) {
            //render only the content of this table, not table inside table
            return {
                x: 0,
                y: 0,
                renderElement: true,
            };
        } else {
            //Im in a crosstab inside the crosstab
            return {
                x: element.get('x'),
                y: element.get('y'),
                renderElement: false,
            };
        }
    }

    // We assume object that are not bands to always have an x,y position
    const position = {
        x: element.get('x'),
        y: element.get('y'),
        //complex element, don't show its content inside the table
        renderElement: (elementType !== ElementTypes.LIST && elementType !== ElementTypes.TABLE),
    };

    // Let's check if this position is relative to a parent figure
    const containerPath = element.get('path', null);

    if (containerPath) {
        // We have found a container.
        const container = state.getIn(['report', 'model', ...containerPath.split('/')], null) as Map<string, any>;
        if (container) {
            const containerPosition = getCrosstabObjectAbsolutePosition(state, container, editedCrosstabPath);
            position.x += containerPosition.x;
            position.y += containerPosition.y;
            position.renderElement = position.renderElement && containerPosition.renderElement;
        }
    }

    return position;
}


/**
 * Return a function which will cache positions while used.
 */
export const crosstabPositionFinderProvider = (state: IState, editedCrosstabPath: string): IPositionFinder => {

    const crosstabPath = editedCrosstabPath.split('/');
    const crosstabModel = state.getIn(['report', 'model', ...crosstabPath]) as Map<string, any> | undefined;

    let pageHeight = 0;
    const cachedContainerPositions: any = {};
    if (crosstabModel) {
        const model = state.getIn(['report', 'model']) as Map<string, any>;
        const crosstabSize = computeCrosstabRealSize(crosstabModel, model);
        const crosstabNoDataCell = getCrosstabNoDataCell(crosstabModel, model);
        let noDataCellHeight = 0;
        if (crosstabNoDataCell) {
            noDataCellHeight = CROSSTAB_NO_DATA_CELL_GAP + crosstabModel.get('height');
        }
        pageHeight = crosstabSize.height + noDataCellHeight;
    }

    return {
        getPageHeight: () => pageHeight,
        findPosition: (o: Map<string, any>): IElementPosition => {
            let container = { x: 0, y: 0, renderElement: true };

            if (o.get('type') === ElementTypes.CROSSTAB) {
                return { x: 0, y: 0, renderElement: true };
            }
            const containerPath = o.get('path');
            if (o.get('type') === CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME) {
                const crosstab = state.getIn(['report', 'model', ...containerPath.split('/')]) as Map<string, any>;
                return { x: 0, y: pageHeight - crosstab.get('height'), renderElement: true };
            }
            if (containerPath) {
                if (cachedContainerPositions[containerPath] === undefined) {
                    const containerElement = state.getIn(['report', 'model', ...containerPath.split('/')]) as Map<string, any>;
                    cachedContainerPositions[containerPath] = getCrosstabObjectAbsolutePosition(state, containerElement, editedCrosstabPath);
                }
                container = cachedContainerPositions[containerPath];
            }

            const result = { x: container.x + o.get('x', 0), y: container.y + o.get('y', 0), renderElement: container.renderElement };
            return result;
        },
        debugCache: () => { console.log(cachedContainerPositions) }
    }
}
