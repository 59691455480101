/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import BarChartIcon from "../../../../../../assets/icons/charts/bar.svg";
import PieChartIcon from "../../../../../../assets/icons/charts/pie.svg";
import Pie3DChartIcon from "../../../../../../assets/icons/charts/pie_3d.svg";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { PPropertyExpression } from "../../../ui/primitive/UPValueExpession";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { MFusionChartCommon } from "./MFusionChartCommon";
import { PInt } from "../../../ui/primitive/UPInteger";
import { PColor } from "../../../ui/primitive/UPColor";
import { MFusionChart3DCommon } from "./MFusionChart3DCommon";

export const FFusionPieChartProps = {
    enableRotation: new PPropertyExpression({ id: 'enableRotation', vtype: new PBoolean({ id: 'enableRotation', label: 'Enable Rotation', canBeNull: true }) }),
    startingAngle: new PPropertyExpression({ id: 'startingAngle', vtype: new PInt({ id: 'startingAngle', label: 'Starting Angle' }) }),
    slicingDistance: new PPropertyExpression({ id: 'slicingDistance', vtype: new PInt({ id: 'slicingDistance', label: 'Slicing Distance' }) }),
    pieSliceDepth: new PPropertyExpression({ id: 'pieSliceDepth', vtype: new PInt({ id: 'pieSliceDepth', label: 'Slice Depth' }) }),
    pieRadius: new PPropertyExpression({ id: 'pieRadius', vtype: new PInt({ id: 'pieRadius', label: 'Radius', min: 0 }) }),
    pieYScale: new PPropertyExpression({ id: 'pieYScale', vtype: new PInt({ id: 'pieYScale', label: 'Y Scale', min: 0 }) }),
    pieInnerFaceAlpha: new PPropertyExpression({ id: 'pieInnerFaceAlpha', vtype: new PInt({ id: 'pieInnerFaceAlpha', label: 'Inner Face Alpha', min: 0, max: 255 }) }),
    pieOuterFaceAlpha: new PPropertyExpression({ id: 'pieOuterFaceAlpha', vtype: new PInt({ id: 'pieOuterFaceAlpha', label: 'Outer Face Alpha', min: 0, max: 255 }) }),
    showZeroPies: new PPropertyExpression({ id: 'showZeroPies', vtype: new PBoolean({ id: 'showZeroPies', label: 'Show Zero Pies', canBeNull: true }) }),
    showPercentValues: new PPropertyExpression({ id: 'showPercentValues', vtype: new PBoolean({ id: 'showPercentValues', label: 'Show Percent Values', canBeNull: true }) }),
    showPercentInToolTip: new PPropertyExpression({ id: 'showPercentInToolTip', vtype: new PBoolean({ id: 'showPercentInToolTip', label: 'Show Percent In Tool Tip', canBeNull: true }) }),

    enableSmartLabel: new PPropertyExpression({ id: 'enableSmartLabel', vtype: new PBoolean({ id: 'enableSmartLabel', label: 'Enable Smart Label', canBeNull: true }) }),
    skiptOverlapLabels: new PPropertyExpression({ id: 'skiptOverlapLabels', vtype: new PBoolean({ id: 'skiptOverlapLabels', label: 'Skip Overlap Labels', canBeNull: true }) }),
    smartLabelClearance: new PPropertyExpression({ id: 'smartLabelClearance', vtype: new PInt({ id: 'smartLabelClearance', label: 'Clearance', min: 0 }) }),
    labelDistance: new PPropertyExpression({ id: 'labelDistance', vtype: new PInt({ id: 'labelDistance', label: 'Distance', min: 0 }) }),

    isSmartLineSlanted: new PPropertyExpression({ id: 'isSmartLineSlanted', vtype: new PBoolean({ id: 'isSmartLineSlanted', label: 'Smart Line Slanted', canBeNull: true }) }),
    smartLineColor: new PPropertyExpression({ id: 'smartLineColor', vtype: new PColor({ id: 'smartLineColor', label: 'Color' }) }),
    smartLineAlpha: new PPropertyExpression({ id: 'smartLineAlpha', vtype: new PInt({ id: 'smartLineAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    smartLineThickness: new PPropertyExpression({ id: 'smartLineThickness', vtype: new PInt({ id: 'smartLineThickness', label: 'Thickness', min: 0 }) }),

};

export const MFusionPieChartCommon: APDescriptor = {
    id: 'Pie', type: 'fusion.chart.pie', label: 'Pie', icon: BarChartIcon,
    layouts: [
        new PAccordeon({
            id: 'fusion.chart.pie', label: 'Pie', layouts: [FFusionPieChartProps.enableRotation, FFusionPieChartProps.startingAngle, FFusionPieChartProps.slicingDistance, FFusionPieChartProps.pieSliceDepth, FFusionPieChartProps.pieRadius, FFusionPieChartProps.pieYScale, FFusionPieChartProps.pieInnerFaceAlpha, FFusionPieChartProps.pieOuterFaceAlpha, FFusionPieChartProps.showZeroPies, FFusionPieChartProps.showPercentValues, FFusionPieChartProps.showPercentInToolTip,
            new PAccordeon({
                id: 'fusion.chart.pie.smart.label', label: 'Smart Label', layouts: [FFusionPieChartProps.enableSmartLabel, FFusionPieChartProps.skiptOverlapLabels, FFusionPieChartProps.smartLabelClearance, FFusionPieChartProps.labelDistance]
            }),
            new PAccordeon({
                id: 'fusion.chart.pie.smart.line', label: 'Smart Line', layouts: [FFusionPieChartProps.smartLineColor, FFusionPieChartProps.smartLineAlpha, FFusionPieChartProps.smartLineThickness, FFusionPieChartProps.isSmartLineSlanted]
            })]
        }),
    ]
};

export const MFusionPieChart: APDescriptor = {
    id: 'Pie', type: 'fusion.chart.pie', label: 'Pie', icon: PieChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts, ...MFusionPieChartCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'Pie',
        'xmlns:fw': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [] }
    }
};
export const MFusionPie3DChart: APDescriptor = {
    id: 'Pie3D', type: 'fusion.chart.pie3d', label: 'Pie 3D', icon: Pie3DChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts, ...MFusionPieChartCommon.layouts, ...MFusionChart3DCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'Pie3D',
        'xmlns:fw': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [] }
    }
};
