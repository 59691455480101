/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { TextInput } from '@jss/js-common';
import '../../assets/css/DataAdapterTypePicker.css';
import i18n from '../../i18n';
import { advancedNameValidator, advancedNotEmptyValidator } from '@jss/js-common/src/utils/validators';

interface IJndiDataAdapterEditor {
    name?: string,
    jndi?: string | null,
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

export class JndiDataAdapterEditor extends React.Component<IJndiDataAdapterEditor> {

    public render() {

        return (<div className="tc-jsw-dataadapter-editor">

            <div className="tc-jsw-form-wrapper">

                <TextInput label={i18n.t('datasource.jndi.name')}
                    help={i18n.t('datasource.jndi.name.help')}
                    id="data-adapter-name"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.jndi.jndi')}
                    id="jndi-url-input"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.jndi}
                    onChange={this.onJndiChange}
                    advancedValidator={advancedNotEmptyValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

            </div>
        </div>);
    }

    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private onJndiChange = (str: string) => {
        this.notifyChange({ jndi: str });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }
}