/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../ui/APDescriptor";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { PCategories } from "../../ui/composite/UPCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PColor } from "../../ui/primitive/UPColor";
import { PInt } from "../../ui/primitive/UPInteger";
import { PString } from "../../ui/primitive/UPString";
import { PValueExpression } from "../../ui/primitive/UPValueExpession"; 
import { PConnectedTableDetail } from "../../ui/tables/UPConnectedTableDetail"; 
import { MDivElementDataset } from "../dataset/MDatasetRun";


export const FMapPath = {
    name: new PValueExpression({ id: 'name', vtype: new PString({ id: "name", label: 'Name', description: 'Represents the identifier of a given path, necessary when multiple paths have to be represented on the same map. Points with the same name property belong to the same path. If not provided, the point will be added to the default path.' }) }),
    latitude: new PValueExpression({ id: 'latitude', vtype: new PInt({ id: "latitude", label: 'Latitude', description: 'latitude', min: -85, max: 85, default: 37.7833 }) }),
    longitude: new PValueExpression({ id: 'longitude', vtype: new PInt({ id: "longitude", label: 'Longitude', description: 'longitude', min: -180, max: 180, default: -122.4167 }) }),
    address: new PValueExpression({ id: 'address', vtype: new PString({ id: "address", label: 'Address', description: 'address' }) }),

    style: new PValueExpression({ id: 'style', vtype: new PString({ id: "style", label: 'Style', description: 'Represents the name of a given path style (path styles are discussed in the next section of this document). If not provided, the path will be generated using Google default style settings for paths.' }) }),

    strokeColor: new PValueExpression({ id: 'strokeColor', vtype: new PColor({ id: "strokeColor", label: 'Color', description: 'Represents the path line color. Named colors are not supported for all Google Maps APIs, so the hex value representation for the color would be more suited in this case.' }) }),
    strokeOpacity: new PValueExpression({ id: 'strokeOpacity', vtype: new PInt({ id: "strokeOpacity", label: 'Opacity', description: 'value between 0 and 1 (default). Represents the path line opacity. 1 means 100% opaque, 0 means 100% transparent.', min: 0, max: 1 }) }),
    strokeWeight: new PValueExpression({ id: 'strokeWeight', vtype: new PInt({ id: "strokeWeight", label: 'Weight', description: 'Represents the path line weight in pixels. Default value is 5.', default: 5, min: 0 }) }),

    fillColor: new PValueExpression({ id: 'fillColor', vtype: new PColor({ id: "fillColor", label: 'Fill Color', description: 'Considered for polygons only, represents the fill color for the polygonal contour. Also named colors are not recommended in this case.' }) }),

    isPolygon: new PValueExpression({ id: 'isPolygon', vtype: new PBoolean({ id: "isPolygon", label: 'Is Polygon', description: 'If set to true the last point in the path is connected to the first point in order to form a polygon (closed path)', canBeNull: true, default: 'false' }) }),
    clickable: new PValueExpression({ id: 'clickable', vtype: new PBoolean({ id: "clickable", label: 'Clickable', description: 'If set to true, the path object may handle mouse events.', canBeNull: true, default: 'true' }) }),
    editable: new PValueExpression({ id: 'editable', vtype: new PBoolean({ id: "editable", label: 'Editable', description: 'If set to true, the user may edit the path using the control points shown along the path line.', canBeNull: true, default: 'false' }) }),
    draggable: new PValueExpression({ id: 'draggable', vtype: new PBoolean({ id: "draggable", label: 'Draggable', description: 'If set to true, the user may drag the path contour over the map.', canBeNull: true, default: 'false' }) }),
    geodesic: new PValueExpression({ id: 'geodesic', vtype: new PBoolean({ id: "geodesic", label: 'Geodesic', description: 'Defines the mode of dragging. If set to true, edges of the polygon are interpreted as geodesic and will follow the curvature of the Earth when the path contour is dragged.', canBeNull: true, default: 'false' }) }),
    visible: new PValueExpression({ id: 'visible', vtype: new PBoolean({ id: "visible", label: 'Visible', description: 'If set to true, the path is visible on the map.', canBeNull: true, default: 'false' }) }),
    zIndex: new PValueExpression({ id: 'zIndex', vtype: new PInt({ id: "zIndex", label: 'zIndex', description: 'Represents the zIndex compared to other path elements', min: 0 }) }),
};

export const MMapPath: APDescriptor = new PCategories({
    id: 'map.paths',
    layouts: [
        new PDiv({
            id: 'map.paths.paths',
            label: 'Paths', layouts: [new PConnectedTableDetail({
                id: 'items', label: 'Paths', rowLabel: 'Path', rowLabelPaths: ['name/value', 'name/valueExpression'], expanded: true,
                layouts: [new PDiv({
                    label: 'Path',
                    layouts: [FMapPath.name,
                    FMapPath.latitude, FMapPath.longitude, FMapPath.address,
                    FMapPath.style,
                    new PAccordeon({
                        id: 'stroke', label: "Stroke", layouts: [FMapPath.strokeColor, FMapPath.strokeOpacity,
                        FMapPath.strokeWeight]
                    }),
                    FMapPath.fillColor, FMapPath.isPolygon, FMapPath.clickable, FMapPath.editable, FMapPath.draggable, FMapPath.geodesic, FMapPath.visible, FMapPath.zIndex
                    ]
                })]
            })]
        }),
        { ...MDivElementDataset, path: 'dataset' }
    ]
});
