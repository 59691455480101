/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const SemiPieChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="440" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 440" {...props}>
    <defs>
       <clipPath>
          <path fill="none" d="M0 0H535V350H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="440" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M10 10H545V360H10z" className="highcharts-plot-background" />
    <path fill="none" d="M10 10H545V360H10z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="0.1">
          <path fill="#7cb5ec" stroke="#fff" strokeLinejoin="round" d="M118 252.56a149.5 149.5 0 0115.713-66.777L267.5 252.5h0z" className="highcharts-point highcharts-color-0" transform="translate(10 10)" />
          <path fill="#434348" stroke="#fff" strokeLinejoin="round" d="M133.78 185.649a149.5 149.5 0 0121.285-31.681L267.5 252.5h0z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#90ed7d" stroke="#fff" strokeLinejoin="round" d="M155.163 153.855A149.5 149.5 0 01259.7 103.204L267.5 252.5h0z" className="highcharts-point highcharts-color-2" transform="translate(10 10)" />
          <path fill="#f7a35c" stroke="#fff" strokeLinejoin="round" d="M259.848 103.196a149.5 149.5 0 0111.359-.15L267.5 252.5h0z" className="highcharts-point highcharts-color-3" transform="translate(10 10)" />
          <path fill="#8085e9" stroke="#fff" strokeLinejoin="round" d="M271.356 103.05a149.5 149.5 0 0119.046 1.715L267.5 252.5h0z" className="highcharts-point highcharts-color-4" transform="translate(10 10)" />
          <path fill="#f15c80" stroke="#fff" strokeLinejoin="round" d="M290.55 104.788a149.5 149.5 0 0140.194 12.248L267.5 252.5h0z" className="highcharts-point highcharts-color-5" transform="translate(10 10)" />
          <path fill="#e4d354" stroke="#fff" strokeLinejoin="round" d="M330.88 117.1a149.5 149.5 0 0110.097 5.203L267.5 252.5h0z" className="highcharts-point highcharts-color-6" transform="translate(10 10)" />
          <path fill="#2b908f" stroke="#fff" strokeLinejoin="round" d="M341.107 122.376a149.5 149.5 0 0127.837 20.31L267.5 252.5h0z" className="highcharts-point highcharts-color-7" transform="translate(10 10)" />
          <path fill="#f45b5b" stroke="#fff" strokeLinejoin="round" d="M369.054 142.787a149.5 149.5 0 0128.481 35.949L267.5 252.5h0z" className="highcharts-point highcharts-color-8" transform="translate(10 10)" />
          <path fill="#91e8e1" stroke="#fff" strokeLinejoin="round" d="M397.609 178.866a149.5 149.5 0 016.821 13.63L267.5 252.5h0z" className="highcharts-point highcharts-color-9" transform="translate(10 10)" />
          <path fill="#7cb5ec" stroke="#fff" strokeLinejoin="round" d="M404.49 192.634a149.5 149.5 0 0112.3 51.946L267.5 252.5h0z" className="highcharts-point highcharts-color-0" transform="translate(10 10)" />
          <path fill="#434348" stroke="#fff" strokeLinejoin="round" d="M416.798 244.73a149.5 149.5 0 01.202 7.62l-149.5.15h0z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
       </g>
    </g>
    <g className="highcharts-data-labels highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="6">
       <path fill="none" stroke="#7cb5ec" d="M87.799 211.271c5 0 22.519 4.134 28.358 5.513l5.84 1.378" className="highcharts-data-label-connector highcharts-color-0" transform="translate(10 10)" />
       <path fill="none" stroke="#434348" d="M113.573 152.295c5 0 19.934 10.049 24.912 13.398l4.978 3.35" className="highcharts-data-label-connector highcharts-color-1" transform="translate(10 10)" />
       <path fill="none" stroke="#90ed7d" d="M174.591 96c5 0 22.57 11.123 25.183 16.524l2.613 5.4" className="highcharts-data-label-connector highcharts-color-2" transform="translate(10 10)" />
       <path fill="none" stroke="#f7a35c" d="M260.189 73c5 0 5.232 18.013 5.309 24.013l.077 6" className="highcharts-data-label-connector highcharts-color-3" transform="translate(10 10)" />
       <path fill="none" stroke="#8085e9" d="M288.656 73c-5 0-6.62 18.655-7.16 24.631l-.54 5.976" className="highcharts-data-label-connector highcharts-color-4" transform="translate(10 10)" />
       <path fill="none" stroke="#f15c80" d="M360.409 96c-5 0-45.78 2.03-47.53 7.769l-1.751 5.738" className="highcharts-data-label-connector highcharts-color-5" transform="translate(10 10)" />
       <path fill="none" stroke="#e4d354" d="M392.492 119c-5 0-50.932-10.018-53.684-4.686l-2.751 5.332" className="highcharts-data-label-connector highcharts-color-6" transform="translate(10 10)" />
       <path fill="none" stroke="#2b908f" d="M413.957 142c-5 0-51.194-19.911-54.733-15.066l-3.539 4.845" className="highcharts-data-label-connector highcharts-color-7" transform="translate(10 10)" />
       <path fill="none" stroke="#f45b5b" d="M429.23 165c-5 0-35.095-12.728-39.8-9.005l-4.704 3.724" className="highcharts-data-label-connector highcharts-color-8" transform="translate(10 10)" />
       <path fill="none" stroke="#91e8e1" d="M440.011 188c-5 0-28.051-7.706-33.418-5.023l-5.367 2.682" className="highcharts-data-label-connector highcharts-color-9" transform="translate(10 10)" />
       <path fill="none" stroke="#7cb5ec" d="M447.201 211.271c-5 0-22.519 4.134-28.358 5.513l-5.84 1.378" className="highcharts-data-label-connector highcharts-color-0" transform="translate(10 10)" />
       <path fill="none" stroke="#434348" d="M451.94 247.878c-5 0-22.994.464-28.992.618l-5.998.155" className="highcharts-data-label-connector highcharts-color-1" transform="translate(10 10)" />
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(0 201)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Albuquerque</tspan>
          <tspan x="5" y="16">Albuquerque</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(38 142)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Anchorage</tspan>
          <tspan x="5" y="16">Anchorage</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(129 86)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Boise</tspan>
          <tspan x="5" y="16">Boise</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(216 63)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Butte</tspan>
          <tspan x="5" y="16">Butte</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(294 63)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Elgin</tspan>
          <tspan x="5" y="16">Elgin</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(365 86)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Eugene</tspan>
          <tspan x="5" y="16">Eugene</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(397 109)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Kirkland</tspan>
          <tspan x="5" y="16">Kirkland</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(419 132)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Lander</tspan>
          <tspan x="5" y="16">Lander</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(434 155)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Portland</tspan>
          <tspan x="5" y="16">Portland</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(445 178)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">San Francisco</tspan>
          <tspan x="5" y="16">San Francisco</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(452 201)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Seattle</tspan>
          <tspan x="5" y="16">Seattle</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 10) translate(457 238)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Walla Walla</tspan>
          <tspan x="5" y="16">Walla Walla</tspan>
       </text>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(39 372)">
       <rect width="476" height="53" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Albuquerque</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-1" data-z-index="1" transform="translate(128.156 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Anchorage</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-2" data-z-index="1" transform="translate(248.313 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Boise</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#90ed7d" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-3" data-z-index="1" transform="translate(368.469 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Butte</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#f7a35c" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-4" data-z-index="1" transform="translate(8 17)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Elgin</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#8085e9" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-5" data-z-index="1" transform="translate(128.156 17)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Eugene</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#f15c80" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-6" data-z-index="1" transform="translate(248.313 17)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Kirkland</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#e4d354" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-7" data-z-index="1" transform="translate(368.469 17)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Lander</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#2b908f" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-8" data-z-index="1" transform="translate(8 31)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Portland</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#f45b5b" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-9" data-z-index="1" transform="translate(128.156 31)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>San Francisco</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#91e8e1" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-0" data-z-index="1" transform="translate(248.313 31)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Seattle</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-pie-series highcharts-color-1" data-z-index="1" transform="translate(368.469 31)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Walla Walla</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
       </g>
    </g>
 </svg>)};

export default SemiPieChartIcon;