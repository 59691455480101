/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

export const AdHocIcon = (): React.ReactElement => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="18" viewBox="0 0 20 18">
            <defs>
                <circle id="path-1" cx="9" cy="9" r="9" />
                <path id="path-3" d="M5.957 2.429l4.254 8.42 6.255-6.876a8.948 8.948 0 011.45 3.792l.019 2.323A9.002 9.002 0 01.027 8.296l5.93-5.867z" />
                <path id="path-5" d="M17.741 6.849A9 9 0 01.707 12.504v-.147l5.336-3.04 4.224 5.203z" />
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(1)">
                    <mask fill="#fff">
                        <use xlinkHref="#path-1" />
                    </mask>
                    <use fill="#318DDE" fillRule="nonzero" xlinkHref="#path-1" />
                    <mask fill="#fff">
                        <use xlinkHref="#path-3" />
                    </mask>
                    <use fill="#A5D4F0" fillRule="nonzero" xlinkHref="#path-3" />
                    <mask fill="#fff">
                        <use xlinkHref="#path-5" />
                    </mask>
                    <use fill="#006E9C" fillRule="nonzero" xlinkHref="#path-5" />
                </g>
            </g>
        </svg>
    );
}