/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StackedAreaChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="802"
      height="493"
      version="1.1"
      style={{
        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        userSelect: "none",
        position: "relative",
      }}
      cursor="default"
      overflow="hidden"
      viewBox="0 0 802 493"
      {...props}
    >
      <defs>
        <linearGradient
          id="19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100"
          x1="0"
          x2="0"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#cbcbcb" stopOpacity="0.5"></stop>
          <stop offset="100%" stopColor="#e9e9e9" stopOpacity="0.5"></stop>
        </linearGradient>
        <linearGradient
          id="19-90-rgba_175_216_248_1_-rgba_255_255_255_1_"
          x1="0"
          x2="0"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#afd8f8"></stop>
          <stop offset="100%" stopColor="#fff"></stop>
        </linearGradient>
        <linearGradient
          id="19-90-rgba_246_189_15_1_-rgba_255_255_255_1_"
          x1="0"
          x2="0"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#f6bd0f"></stop>
          <stop offset="100%" stopColor="#fff"></stop>
        </linearGradient>
        <linearGradient
          id="19-90-rgba_139_186_0_1_-rgba_255_255_255_1_"
          x1="0"
          x2="0"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#8bba00"></stop>
          <stop offset="100%" stopColor="#fff"></stop>
        </linearGradient>
        <linearGradient
          id="19-90-rgba_175_216_248_1__0-rgba_175_216_248_1__30-rgba_223_240_252_1__60-rgba_175_216_248_1__90-rgba_175_216_248_1__100"
          x1="0"
          x2="0"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#afd8f8"></stop>
          <stop offset="30%" stopColor="#afd8f8"></stop>
          <stop offset="60%" stopColor="#dff0fc"></stop>
          <stop offset="90%" stopColor="#afd8f8"></stop>
          <stop offset="100%" stopColor="#afd8f8"></stop>
        </linearGradient>
        <linearGradient
          id="19-90-rgba_246_189_15_1__0-rgba_246_189_15_1__30-rgba_252_229_159_1__60-rgba_246_189_15_1__90-rgba_246_189_15_1__100"
          x1="0"
          x2="0"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#f6bd0f"></stop>
          <stop offset="30%" stopColor="#f6bd0f"></stop>
          <stop offset="60%" stopColor="#fce59f"></stop>
          <stop offset="90%" stopColor="#f6bd0f"></stop>
          <stop offset="100%" stopColor="#f6bd0f"></stop>
        </linearGradient>
        <linearGradient
          id="19-90-rgba_139_186_0_1__0-rgba_139_186_0_1__30-rgba_209_228_153_1__60-rgba_139_186_0_1__90-rgba_139_186_0_1__100"
          x1="0"
          x2="0"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#8bba00"></stop>
          <stop offset="30%" stopColor="#8bba00"></stop>
          <stop offset="60%" stopColor="#d1e499"></stop>
          <stop offset="90%" stopColor="#8bba00"></stop>
          <stop offset="100%" stopColor="#8bba00"></stop>
        </linearGradient>
        <clipPath id="4D095640-94DA-452F-945C-6CA84EEA0C46">
          <path d="M79 55H784V411H79z"></path>
        </clipPath>
      </defs>
      <g className="raphael-group-20-background">
        <rect
          width="800"
          height="491"
          x="1"
          y="1"
          fill="url('#19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100')"
          rx="0"
          ry="0"
        ></rect>
        <rect
          width="801"
          height="492"
          x="0.5"
          y="0.5"
          fill="none"
          stroke="#767575"
          strokeOpacity="0.5"
          rx="0"
          ry="0"
        ></rect>
      </g>
      <g className="raphael-group-27-canvas">
        <rect
          width="707"
          height="358"
          x="78"
          y="54"
          fill="none"
          stroke="#545454"
          strokeWidth="2"
          rx="0"
          ry="0"
        ></rect>
        <rect
          width="705"
          height="356"
          x="79"
          y="55"
          fill="#FFF"
          strokeWidth="0"
          rx="0"
          ry="0"
        ></rect>
      </g>
      <g className="raphael-group-30-axisbottom">
        <g
          fill="#EEE"
          fillOpacity="0.5"
          stroke="#000"
          strokeWidth="0"
          className="raphael-group-39-y-axis-bands"
        >
          <rect width="705" height="71.2" x="79" y="268.6" rx="0" ry="0"></rect>
          <rect width="705" height="71.2" x="79" y="126.2" rx="0" ry="0"></rect>
        </g>
        <g
          fill="none"
          stroke="#717170"
          strokeOpacity="0.4"
          className="raphael-group-41-y-axis-lines"
          shapeRendering="crispedges"
        >
          <path d="M79 340.5h705M79 269.5h705M79 197.5h705M79 126.5h705"></path>
        </g>
        <g className="fusioncharts-xaxis-0-gridlabels">
          <text
            x="99"
            y="427"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="99" dy="-2">
              Denmark
            </tspan>
          </text>
          <text
            x="209.833"
            y="427"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="209.833" dy="-2">
              France
            </tspan>
          </text>
          <text
            x="320.667"
            y="427"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="320.667" dy="-2.008">
              Germany
            </tspan>
          </text>
          <text
            x="431.5"
            y="427"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="431.5" dy="-2.008">
              Italy
            </tspan>
          </text>
          <text
            x="542.333"
            y="427"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="542.333" dy="-2.008">
              Spain
            </tspan>
          </text>
          <text
            x="653.167"
            y="427"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="653.167" dy="-2">
              UK
            </tspan>
          </text>
          <text
            x="764"
            y="427"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="764" dy="-2">
              USA
            </tspan>
          </text>
        </g>
        <g className="fusioncharts-yaxis-0-gridlabels">
          <text
            x="73"
            y="411"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="end"
          >
            <tspan x="73" dy="4">
              0.00
            </tspan>
          </text>
          <text
            x="73"
            y="339.8"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="end"
          >
            <tspan x="73" dy="3.995">
              10.00K
            </tspan>
          </text>
          <text
            x="73"
            y="268.6"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="end"
          >
            <tspan x="73" dy="3.998">
              20.00K
            </tspan>
          </text>
          <text
            x="73"
            y="197.4"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="end"
          >
            <tspan x="73" dy="4.002">
              30.00K
            </tspan>
          </text>
          <text
            x="73"
            y="126.2"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="end"
          >
            <tspan x="73" dy="4.005">
              40.00K
            </tspan>
          </text>
          <text
            x="73"
            y="55"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="end"
          >
            <tspan x="73" dy="4">
              50.00K
            </tspan>
          </text>
        </g>
        <g className="raphael-group-50-axis-name">
          <text
            fill="#555"
            className="fusioncharts-yaxis-0-title"
            fontSize="10"
            textAnchor="middle"
            transform="rotate(-90 125.5 107.5)"
          >
            <tspan x="0" dy="10">
              Orders
            </tspan>
          </text>
          <text
            fill="#555"
            className="fusioncharts-xaxis-0-title"
            fontSize="10"
            textAnchor="middle"
            transform="translate(431.5 432)"
          >
            <tspan x="0" dy="10">
              States
            </tspan>
          </text>
        </g>
      </g>
      <g className="raphael-group-21-dataset">
        <g
          className="raphael-group-72-area"
          clipPath="url('#4D095640-94DA-452F-945C-6CA84EEA0C46')"
        >
          <g className="raphael-group-76-area">
            <g
              fill="none"
              stroke="#404040"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="raphael-group-78-shadows"
            >
              <path
                strokeOpacity="0.048"
                strokeWidth="5"
                d="M100 392.118l110.833-40.465 110.834-100.327L432.5 399.69l110.833.037 110.834-29.796L765 215.897V412H100z"
              ></path>
              <path
                strokeOpacity="0.095"
                strokeWidth="3"
                d="M100 392.118l110.833-40.465 110.834-100.327L432.5 399.69l110.833.037 110.834-29.796L765 215.897V412H100z"
              ></path>
              <path
                strokeOpacity="0.142"
                d="M100 392.118l110.833-40.465 110.834-100.327L432.5 399.69l110.833.037 110.834-29.796L765 215.897V412H100z"
              ></path>
              <path
                strokeOpacity="0.048"
                strokeWidth="5"
                d="M100 382.177l110.833-60.697 110.834-150.49L432.5 393.535l110.833.054 110.834-44.693L765 117.845v98.052L654.167 369.93l-110.834 29.796-110.833-.037-110.833-148.364-110.834 100.327L100 392.118z"
              ></path>
              <path
                strokeOpacity="0.095"
                strokeWidth="3"
                d="M100 382.177l110.833-60.697 110.834-150.49L432.5 393.535l110.833.054 110.834-44.693L765 117.845v98.052L654.167 369.93l-110.834 29.796-110.833-.037-110.833-148.364-110.834 100.327L100 392.118z"
              ></path>
              <path
                strokeOpacity="0.142"
                d="M100 382.177l110.833-60.697 110.834-150.49L432.5 393.535l110.833.054 110.834-44.693L765 117.845v98.052L654.167 369.93l-110.834 29.796-110.833-.037-110.833-148.364-110.834 100.327L100 392.118z"
              ></path>
              <path
                strokeOpacity="0.048"
                strokeWidth="5"
                d="M100 377.207l110.833-70.814 110.834-175.572L432.5 390.458l110.833.064 110.834-52.142L765 68.82v49.025L654.167 348.897 543.333 393.59l-110.833-.054-110.833-222.547-110.834 150.49L100 382.178z"
              ></path>
              <path
                strokeOpacity="0.095"
                strokeWidth="3"
                d="M100 377.207l110.833-70.814 110.834-175.572L432.5 390.458l110.833.064 110.834-52.142L765 68.82v49.025L654.167 348.897 543.333 393.59l-110.833-.054-110.833-222.547-110.834 150.49L100 382.178z"
              ></path>
              <path
                strokeOpacity="0.142"
                d="M100 377.207l110.833-70.814 110.834-175.572L432.5 390.458l110.833.064 110.834-52.142L765 68.82v49.025L654.167 348.897 543.333 393.59l-110.833-.054-110.833-222.547-110.834 150.49L100 382.178z"
              ></path>
            </g>
            <path
              fill="url('#19-90-rgba_175_216_248_1_-rgba_255_255_255_1_')"
              stroke="#666"
              strokeLinecap="round"
              strokeOpacity="0.95"
              d="M99 391.118l110.833-40.465 110.834-100.327L431.5 398.69l110.833.037 110.834-29.796L764 214.897V411H99z"
            ></path>
          </g>
          <path
            fill="url('#19-90-rgba_246_189_15_1_-rgba_255_255_255_1_')"
            stroke="#666"
            strokeLinecap="round"
            strokeOpacity="0.95"
            d="M99 381.177l110.833-60.697 110.834-150.49L431.5 392.535l110.833.054 110.834-44.693L764 116.845v98.052L653.167 368.93l-110.834 29.796-110.833-.037-110.833-148.364-110.834 100.327L99 391.118z"
            className="0"
          ></path>
          <path
            fill="url('#19-90-rgba_139_186_0_1_-rgba_255_255_255_1_')"
            stroke="#666"
            strokeLinecap="round"
            strokeOpacity="0.95"
            d="M99 376.207l110.833-70.814 110.834-175.572L431.5 389.458l110.833.064 110.834-52.142L764 67.82v49.025L653.167 347.897 542.333 392.59l-110.833-.054-110.833-222.547-110.834 150.49L99 381.178z"
            className="0"
          ></path>
        </g>
        <g className="raphael-group-73-area-connector">
          <g
            fill="#FFF"
            fillOpacity="0"
            stroke="#AFD8F8"
            strokeOpacity="0"
            className="raphael-group-77-anchors"
          >
            <path d="M96 391.118a3 3 0 006 0 3 3 0 00-6 0zM206.833 350.653a3 3 0 006 0 3 3 0 00-6 0zM317.667 250.326a3 3 0 006 0 3 3 0 00-6 0zM428.5 398.69a3 3 0 006 0 3 3 0 00-6 0zM539.333 398.727a3 3 0 006 0 3 3 0 00-6 0zM650.167 368.931a3 3 0 006 0 3 3 0 00-6 0zM761 214.897a3 3 0 006 0 3 3 0 00-6 0z"></path>
          </g>
          <g
            fill="#FFF"
            fillOpacity="0"
            stroke="#F6BD0F"
            strokeOpacity="0"
            className="raphael-group-109-anchors"
          >
            <path d="M96 381.177a3 3 0 006 0 3 3 0 00-6 0zM206.833 320.48a3 3 0 006 0 3 3 0 00-6 0zM317.667 169.99a3 3 0 006 0 3 3 0 00-6 0zM428.5 392.536a3 3 0 006 0 3 3 0 00-6 0zM539.333 392.59a3 3 0 006 0 3 3 0 00-6 0zM650.167 347.897a3 3 0 006 0 3 3 0 00-6 0zM761 116.845a3 3 0 006 0 3 3 0 00-6 0z"></path>
          </g>
          <g
            fill="#FFF"
            fillOpacity="0"
            stroke="#8BBA00"
            strokeOpacity="0"
            className="raphael-group-140-anchors"
          >
            <path d="M96 376.207a3 3 0 006 0 3 3 0 00-6 0zM206.833 305.393a3 3 0 006 0 3 3 0 00-6 0zM317.667 129.82a3 3 0 006 0 3 3 0 00-6 0zM428.5 389.458a3 3 0 006 0 3 3 0 00-6 0zM539.333 389.522a3 3 0 006 0 3 3 0 00-6 0zM650.167 337.38a3 3 0 006 0 3 3 0 00-6 0zM761 67.82a3 3 0 006 0 3 3 0 00-6 0z"></path>
          </g>
        </g>
      </g>
      <g className="raphael-group-26-datalabels">
        <g className="raphael-group-80-datalabel">
          <text
            x="99"
            y="381.118"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="99" dy="4.001">
              2.79K
            </tspan>
          </text>
          <text
            x="209.833"
            y="340.653"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="209.833" dy="4.005">
              8.48K
            </tspan>
          </text>
          <text
            x="320.667"
            y="240.326"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="320.667" dy="4.006">
              22.57K
            </tspan>
          </text>
          <text
            x="431.5"
            y="388.69"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="431.5" dy="3.995">
              1.73K
            </tspan>
          </text>
          <text
            x="542.333"
            y="388.727"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="542.333" dy="4">
              1.72K
            </tspan>
          </text>
          <text
            x="653.167"
            y="358.931"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="653.167" dy="4.002">
              5.91K
            </tspan>
          </text>
          <text
            x="764"
            y="204.897"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="764" dy="3.998">
              27.54K
            </tspan>
          </text>
        </g>
        <g className="raphael-group-111-datalabel">
          <text
            x="99"
            y="371.177"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="99" dy="3.998">
              1.40K
            </tspan>
          </text>
          <text
            x="209.833"
            y="310.48"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="209.833" dy="4.003">
              4.24K
            </tspan>
          </text>
          <text
            x="320.667"
            y="159.989"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="320.667" dy="3.997">
              11.28K
            </tspan>
          </text>
          <text
            x="431.5"
            y="382.536"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="431.5" dy="3.996">
              864.44
            </tspan>
          </text>
          <text
            x="542.333"
            y="382.59"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="542.333" dy="4.004">
              861.89
            </tspan>
          </text>
          <text
            x="653.167"
            y="337.897"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="653.167" dy="3.998">
              2.95K
            </tspan>
          </text>
          <text
            x="764"
            y="106.845"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="764" dy="3.994">
              13.77K
            </tspan>
          </text>
        </g>
        <g className="raphael-group-142-datalabel">
          <text
            x="99"
            y="366.207"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="99" dy="3.996">
              698.10
            </tspan>
          </text>
          <text
            x="209.833"
            y="295.393"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="209.833" dy="3.995">
              2.12K
            </tspan>
          </text>
          <text
            x="320.667"
            y="119.821"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="320.667" dy="4">
              5.64K
            </tspan>
          </text>
          <text
            x="431.5"
            y="399.458"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="431.5" dy="3.997">
              432.22
            </tspan>
          </text>
          <text
            x="542.333"
            y="399.522"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="542.333" dy="3.998">
              430.95
            </tspan>
          </text>
          <text
            x="653.167"
            y="327.38"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="653.167" dy="3.997">
              1.48K
            </tspan>
          </text>
          <text
            x="764"
            y="77.819"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="764" dy="3.999">
              6.89K
            </tspan>
          </text>
        </g>
      </g>
      <g className="fusioncharts-legend" transform="translate(279.5 452.5)">
        <rect
          width="304"
          height="25"
          fill="none"
          stroke="#404040"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="304"
          height="25"
          fill="none"
          stroke="#404040"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="304"
          height="25"
          fill="none"
          stroke="#404040"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="304"
          height="25"
          fill="#FFF"
          stroke="#545454"
          rx="0"
          ry="0"
        ></rect>
        <text
          x="23"
          y="6.5"
          fill="#555"
          className="fusioncharts-legenditem"
          cursor="pointer"
          fontSize="10"
        >
          <tspan x="23" dy="9.25">
            Double Freight
          </tspan>
        </text>
        <path
          fill="url('#19-90-rgba_175_216_248_1__0-rgba_175_216_248_1__30-rgba_223_240_252_1__60-rgba_175_216_248_1__90-rgba_175_216_248_1__100')"
          stroke="#CFE8FB"
          strokeWidth="0.5"
          d="M7 15.8v-2.2l3.3-4.4 3.3 4.4L18 9.2v6.6z"
          cursor="pointer"
        ></path>
        <rect
          width="95"
          height="17"
          x="4"
          y="4"
          fill="silver"
          fillOpacity="0"
          cursor="pointer"
          rx="0"
          ry="0"
        ></rect>
        <text
          x="128.85"
          y="6.5"
          fill="#555"
          className="fusioncharts-legenditem"
          cursor="pointer"
          fontSize="10"
        >
          <tspan x="128.85" dy="9.25">
            Normal Freight
          </tspan>
        </text>
        <path
          fill="url('#19-90-rgba_246_189_15_1__0-rgba_246_189_15_1__30-rgba_252_229_159_1__60-rgba_246_189_15_1__90-rgba_246_189_15_1__100')"
          stroke="#FAD76F"
          strokeWidth="0.5"
          d="M112.85 15.8v-2.2l3.3-4.4 3.3 4.4 4.4-4.4v6.6z"
          cursor="pointer"
        ></path>
        <rect
          width="97"
          height="17"
          x="109.85"
          y="4"
          fill="silver"
          fillOpacity="0"
          cursor="pointer"
          rx="0"
          ry="0"
        ></rect>
        <text
          x="236.7"
          y="6.5"
          fill="#555"
          className="fusioncharts-legenditem"
          cursor="pointer"
          fontSize="10"
        >
          <tspan x="236.7" dy="9.25">
            Half Freight
          </tspan>
        </text>
        <path
          fill="url('#19-90-rgba_139_186_0_1__0-rgba_139_186_0_1__30-rgba_209_228_153_1__60-rgba_139_186_0_1__90-rgba_139_186_0_1__100')"
          stroke="#B9D666"
          strokeWidth="0.5"
          d="M220.7 15.8v-2.2l3.3-4.4 3.3 4.4 4.4-4.4v6.6z"
          cursor="pointer"
        ></path>
        <rect
          width="80"
          height="17"
          x="217.7"
          y="4"
          fill="silver"
          fillOpacity="0"
          cursor="pointer"
          rx="0"
          ry="0"
        ></rect>
      </g>
      <g className="raphael-group-182-caption">
        <text
          x="431.5"
          y="16"
          fill="#555"
          className="fusioncharts-caption"
          fontFamily="Verdana, sans"
          fontSize="13"
          fontWeight="bold"
          textAnchor="middle"
        >
          <tspan x="431.5" dy="12.992">
            Chart Caption
          </tspan>
        </text>
        <text
          x="431.5"
          y="34"
          fill="#555"
          className="fusioncharts-subcaption"
          fontFamily="Verdana, sans"
          fontSize="11"
          fontWeight="bold"
          textAnchor="middle"
        >
          <tspan x="431.5" dy="10.852">
            Chart Sub Caption
          </tspan>
        </text>
      </g>
      <g className="raphael-group-22-hot">
        <g
          fill="silver"
          fillOpacity="0"
          stroke="silver"
          strokeOpacity="0"
          className="raphael-group-79-area-hot"
        >
          <circle cx="99" cy="391.118" r="3"></circle>
          <circle cx="209.833" cy="350.653" r="3"></circle>
          <circle cx="320.667" cy="250.326" r="3"></circle>
          <circle cx="431.5" cy="398.69" r="3"></circle>
          <circle cx="542.333" cy="398.727" r="3"></circle>
          <circle cx="653.167" cy="368.931" r="3"></circle>
          <circle cx="764" cy="214.897" r="3"></circle>
        </g>
        <g
          fill="silver"
          fillOpacity="0"
          stroke="silver"
          strokeOpacity="0"
          className="raphael-group-110-area-hot"
        >
          <circle cx="99" cy="381.177" r="3"></circle>
          <circle cx="209.833" cy="320.48" r="3"></circle>
          <circle cx="320.667" cy="169.989" r="3"></circle>
          <circle cx="431.5" cy="392.536" r="3"></circle>
          <circle cx="542.333" cy="392.59" r="3"></circle>
          <circle cx="653.167" cy="347.897" r="3"></circle>
          <circle cx="764" cy="116.845" r="3"></circle>
        </g>
        <g
          fill="silver"
          fillOpacity="0"
          stroke="silver"
          strokeOpacity="0"
          className="raphael-group-141-area-hot"
        >
          <circle cx="99" cy="376.207" r="3"></circle>
          <circle cx="209.833" cy="305.393" r="3"></circle>
          <circle cx="320.667" cy="129.821" r="3"></circle>
          <circle cx="431.5" cy="389.458" r="3"></circle>
          <circle cx="542.333" cy="389.522" r="3"></circle>
          <circle cx="653.167" cy="337.38" r="3"></circle>
          <circle cx="764" cy="67.819" r="3"></circle>
        </g>
      </g>
    </svg>)};

export default StackedAreaChartIcon;