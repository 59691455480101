/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { TextInput } from '@jss/js-common';
import { advancedNameValidator, advancedNotEmptyValidator } from '@jss/js-common/src/utils/validators';
import * as React from 'react';
import '../../assets/css/DataAdapterTypePicker.css';
import i18n from '../../i18n';
import { DBConfigurationPanel } from '../DBConfigurationPanel';

interface IMondrianDataAdapterEditor {
    name?: string,
    catalogURI?: string,
    driver?: string
    url?: string | null,
    username?: string,
    password?: string,
    autoCommit?: boolean,
    readOnly?: boolean,
    transactionIsolation?: string,
    classpaths?: string[],
    propertiesNames?: string[],
    propertiesValues?: string[],
    readOnlyWidgets?: boolean, 
    onDataAdapterSettingsChange?: (data: any) => void
}

export class MondrianDataAdapterEditor extends React.Component<IMondrianDataAdapterEditor> {

    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private onCatalogURIChange = (str: string) => {
        this.notifyChange({ catalogURI: str });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }

    public render() {
        return <div className="tc-jsw-dataadapter-editor">
            <div className="tc-jsw-form-wrapper" style={{ flex: 1, alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}>
                <TextInput label={i18n.t('datasource.jdbc.name')}
                    help={i18n.t('datasource.jdbc.name.help')}
                    id="data-adapter-name"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnlyWidgets
                    }} />
                <TextInput label={i18n.t('datasource.mondrian.catalog')}
                    id="catalog-uri"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.catalogURI}
                    onChange={this.onCatalogURIChange}
                    advancedValidator={advancedNotEmptyValidator}
                    InputProps={{
                        readOnly: this.props.readOnlyWidgets
                    }} />
                <DBConfigurationPanel {...this.props} />
            </div>
        </div>;
    }
}