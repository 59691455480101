/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { ChecksumModeEnum } from "../../enums/ChecksumModeEnum";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { PInt } from "../../../ui/primitive/UPInteger";
import { FBarcode4J, MBarcode4J } from "./MBarcode4J";


export const FCode39 = {
    wideFactor: new PInt({ id: 'wideFactor', label: 'Wide Factor', min: 0, max: 1 }),
    intercharGapWidth: new PInt({ id: 'intercharGapWidth', label: 'Interchar Gap Width', min: 0 }),

    displayChecksum: new PBoolean({ id: 'displayChecksum', label: 'Display Checksum', canBeNull: true }),
    displayStartStop: new PBoolean({ id: 'displayStartStop', label: 'Display Start Stop', canBeNull: true }),
    extendedCharSetEnabled: new PBoolean({ id: 'extendedCharSetEnabled', label: 'Extended Char Set Enabled', canBeNull: true }),
    checksumMode: ChecksumModeEnum
}

export const MCode39: APDescriptor = {
    type: 'Code39', id: 'Code39', label: 'Code39', icon: ICONS.BARCODE_CODE39_ICON,
    layouts: [...MBarcode4J.layouts, FBarcode4J.verticalQuietZone, FBarcode4J.textPosition, FCode39.wideFactor, FCode39.intercharGapWidth, FCode39.displayChecksum, FCode39.displayStartStop, FCode39.extendedCharSetEnabled, FCode39.checksumMode],
    default: {
        type: 'BARCODE', BARCODE_TYPE: 'Code39', BARCODE_XMLNS_NAMESPACE: 'c',
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:c': "http://jasperreports.sourceforge.net/jasperreports/components",
        codeExpression: '"123456789"'
    }
};
