/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { PInt } from "../../../ui/primitive/UPInteger";
import { FBarcode4J, MBarcode4J } from "./MBarcode4J";


export const FPDF417 = {
    minColumns: new PInt({ id: 'minColumns', label: 'Min Columns', min: 1, max: 30 }),
    maxColumns: new PInt({ id: 'maxColumns', label: 'Max Columns', min: 1, max: 30 }),
    minRows: new PInt({ id: 'minRows', label: 'Min Rows', min: 3, max: 90 }),
    maxRows: new PInt({ id: 'maxRows', label: 'Max Rows', min: 3, max: 90 }),
    widthToHeightRatio: new PInt({ id: 'widthToHeightRatio', label: 'Width To Height Ratio' }),
    errorCorrectionLevel: new PInt({ id: 'errorCorrectionLevel', label: 'Error Correction Level', min: 0, max: 8 }),
}

export const MPDF417: APDescriptor = {
    type: 'PDF417', id: 'PDF417', label: 'PDF417', icon: ICONS.BARCODE_PDF417_ICON,
    layouts: [...MBarcode4J.layouts, FBarcode4J.verticalQuietZone, FBarcode4J.textPosition, FPDF417.minColumns, FPDF417.maxColumns, FPDF417.minRows, FPDF417.maxRows, FPDF417.widthToHeightRatio, FPDF417.errorCorrectionLevel],
    default: {
        type: 'BARCODE', BARCODE_TYPE: 'PDF417', BARCODE_XMLNS_NAMESPACE: 'c',
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:c': "http://jasperreports.sourceforge.net/jasperreports/components",
        codeExpression: '"123456789"',
        width: 30, height: 30
    }
};
