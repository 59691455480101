/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { put, select } from 'redux-saga/effects';

import { IState } from '../../../../reducers';
import * as DocumentUtils from '../documentUtils';

import { IPoint } from '../../../../types/geometry';

import * as ReportActions from '../../../../actions/reportActions';


/**
 * This function manage the heighlight of elements on hover. It works only when no drag operations are active.
 * This is assured by the fact that this function is invoked only when there are no drag operations in place.
 * 
 */
export function* handleElementHighlight(point: IPoint, cachedPositionFinder?: DocumentUtils.IPositionFinder) {
    // 1. Let's see if this was the first move...
    const state = yield select( (aState: IState) => aState );
    const mouse = state.getIn(['report','mouse']);
    const model = state.getIn(['report','model']);

    if (mouse.get('operation') || model === null) {
        return; // Nothing to do...
    }

    // Find one and only one elements hover the mouse...
    const ele = DocumentUtils.findElementAt(model, point, cachedPositionFinder);
    yield put( ReportActions.setHighlightedElements( (ele) ? [ele.get('id')] : [] ) );
}

