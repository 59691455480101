/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const SpiderRotationEnum = new PEnum({
    id: 'rotation', label: 'SpiderRotation.label', isEmptyUndefined: true,
    items: [
        { key: '', value: ''},
        { key: 'Clockwise', value: 'SpiderRotation.Clockwise'},
        { key: 'Anticlockwise', value: 'SpiderRotation.Anticlockwise'}
    ]
}); 
 
