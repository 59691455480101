/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { PInt } from "../../../ui/primitive/UPInteger";
import { FBarcode4J, MBarcode4J } from "./MBarcode4J";


export const FCodabar = {
    wideFactor: new PInt({ id: 'wideFactor', label: 'Wide Factor', min: 1.000001 })
}

export const MCodabar: APDescriptor = {
    type: 'Codabar', id: 'Codabar', label: 'Codabar', icon: ICONS.BARCODE_CODABAR_ICON,
    layouts: [...MBarcode4J.layouts, FBarcode4J.verticalQuietZone, FBarcode4J.textPosition, FCodabar.wideFactor],
    default: {
        type: 'BARCODE', BARCODE_TYPE: 'Codabar', BARCODE_XMLNS_NAMESPACE: 'c',
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:c': "http://jasperreports.sourceforge.net/jasperreports/components",
        codeExpression: '"123456789"'
    }
};
