/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StaticTextIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Tree/dashboard/text" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" transform="translate(3.000000, 0.000000)">
                    <text id="T" fontFamily="AmericanTypewriter-Semibold, American Typewriter" fontSize="16" fontWeight="500" line-spacing="16" fill="#062E79">
                        <tspan x="1.5" y="14">T</tspan>
                    </text>
                    <rect id="Rectangle" fill="#318DDE" fillRule="nonzero" x="0" y="15" width="14" height="2"></rect>
                </g>
            </g>
        </svg>
    );
}
export default StaticTextIcon;