/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { FormControlledTextField, FormControlledWritableCombo } from '@jss/js-common';
import { classNameValidator, ValidationResult, VALIDATION_RESULT } from '@jss/js-common/src/utils/validators';
import * as React from 'react';
import i18n from '../../../../../i18n';
import { APDescriptor } from '../APDescriptor';
import { getKey, getNormalizedPath, getPath, reg, UiPropertyProps } from '../UiProperty';

const TYPEID = 'import';
reg(TYPEID, (mc) => { return <UPImport mcontext={mc} />; });
export class PImport extends APDescriptor {
    items?: string[];
    parentClass?: string;
    mandatory?: boolean
    public constructor(init: Partial<PImport>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

const standardClassNameValidator = (value: string | undefined, descriptor: PImport) => {
    const currentValue = value && value !== null ? value.trim() : undefined;
    if (descriptor.mandatory && (!currentValue || currentValue.length === 0)) {
        return i18n.t('JavaClass.className.error.mandatory');
    }
    // here should allow: javaclass name or package name or package.* like in java imports
    const regexp = /^([a-zA-Z_]*)+([.][a-zA-Z_]\w*)*(\.\*)?$/;
    if (value && !value.match(regexp)) {
        return i18n.t('JavaClass.className.error.label');
    }
    return undefined;
}

const advancendNotEmptyClassNameValidator = (value: string | undefined) : ValidationResult => {
    if (!value || value === null || value.trim().length === 0) {
        return { result: VALIDATION_RESULT.VALID };
    } else {
        const classnameInvalidMessage = classNameValidator(value);
        if (classnameInvalidMessage){
            return { result: VALIDATION_RESULT.VALID, message: classnameInvalidMessage };   
        }
    }
    return { result: VALIDATION_RESULT.VALID };
}

export class UPImport extends React.Component<UiPropertyProps> {

    render() {
        const d = this.props.mcontext.descriptor as PImport;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p) as string | undefined;

        if (d.items && d.items.length > 0){
            const items = [];
            if (d.items) {
                d.items.forEach(k => { items.push({ key: k, value: k }) });
            }
            const validator = (value: string) => standardClassNameValidator(value, d);
            return <FormControlledWritableCombo
                items={items}
                className={this.props.mcontext.descriptor.className}
                style={this.props.mcontext.descriptor.style}
                key={getKey(p)}
                value={v}
                disabled={d.readonly}
                onComboChange={this.onWritableNameChange}
                inline={true}
                InputLabelProps={d.deprecated ? { className: 'deprecatedProperty' } : undefined}
                InputProps={{ title: v }}
                validator={validator}
                size="small"
            />
        } else {
            return <FormControlledTextField
                className={this.props.mcontext.descriptor.className}
                inputStyle={{marginBottom: 4}}
                key={getKey(p)}
                value={v}
                disabled={d.readonly}
                onTextChange={this.onTextChange}
                inline={true}
                InputLabelProps={d.deprecated ? { className: 'deprecatedProperty' } : undefined}
                InputProps={{ title: v }}
                validator={advancendNotEmptyClassNameValidator}
                size="small"
            />
        }
    }

    public onTextChange = (newValue: string | undefined) => {
        this.props.mcontext.elements.forEach(key => {
            if (newValue === undefined) {
                const path = getNormalizedPath(key.path);
                this.props.mcontext.deleteElement(path);
            } else {
                this.props.mcontext.setObjectProperties(key.path, newValue ? newValue : newValue );
            }
        });
    }

    public onWritableNameChange = (selectedKey: string | undefined, selectedText: string) => {
        this.props.mcontext.elements.forEach(key => {
            if ((!selectedKey && !selectedText) || (!selectedKey && selectedText.trim().length === 0)) {
                const path = getNormalizedPath(key.path);
                this.props.mcontext.deleteElement(path);
            } else {
                this.props.mcontext.setObjectProperties(key.path, selectedKey ? selectedKey : selectedText );
            }
        });
    }

}
