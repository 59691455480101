import { List, Map, OrderedMap} from 'immutable';
import { BandTypes, ElementTypes } from '../../../sagas/report/document/elementTypes';
import { TableTypes } from '../../../components/common/JrxmlModel/reader/JrxmlTableUtils';
import { CrosstabTypes } from '../../..//components/common/JrxmlModel/reader/JrxmlCrosstabUtils';

export const isContainer = (type: string): boolean => {
    switch (type) {
        case ElementTypes.FRAME:
        case ElementTypes.LIST:
        case ElementTypes.ELEMENT_GROUP:
            return true;
        //table cases
        case ElementTypes.TABLE:
        case TableTypes.TABLE_HEADER_NAME:
        case TableTypes.TABLE_COLUMN_HEADER_NAME:
        case TableTypes.TABLE_DETAIL_NAME:
        case TableTypes.TABLE_COLUMN_FOOTER_NAME:
        case TableTypes.TABLE_FOOTER_NAME:
        case TableTypes.TABLE_CELL_NAME:
        case TableTypes.TABLE_GROUP_CELL_NAME:
        case TableTypes.TABLE_GROUP_COLUMN_NAME:
        case TableTypes.TABLE_GROUP_HEADER_NAME:
        case TableTypes.TABLE_GROUP_FOOTER_NAME:
        case TableTypes.TABLE_NO_DATA_CELL_NAME:
            return true;
        //crosstab cases
        case ElementTypes.CROSSTAB:
        case CrosstabTypes.CROSSTAB_TITLE_CELL_NAME:
        case CrosstabTypes.CROSSTAB_ROW_HEADER_NAME:
        case CrosstabTypes.CROSSTAB_COLUMN_HEADER_NAME:
        case CrosstabTypes.CROSSTAB_TOTAL_ROW_HEADER_NAME:
        case CrosstabTypes.CROSSTAB_TOTAL_COLUMN_HEADER_NAME:
        case CrosstabTypes.CROSSTAB_HEADER_CELL_NAME:
        case CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME:
        case CrosstabTypes.CROSSTAB_HEADER_NAME:
        case CrosstabTypes.CROSSTAB_ROW_GROUP_NAME:
        case CrosstabTypes.CROSSTAB_COLUMN_GROUP_NAME:
        case CrosstabTypes.CROSSTAB_CELL_NAME:
            return true;
        default:
            return false;
    }
}

export const removeElement = (state: Map<string, any>, path: string[]) => {
    const elementToRemove = state.getIn(['model', ...path]) as Map<string, any>;
    let newState = state;
    const subeditors: List<Map<string, any>> = state.get('subeditors');
    const pathId = path.join('/');
    //check if it is in a subeditor
    const elementSubeditorIndex = subeditors.findIndex((subeditor) => {
        const subeditorId = subeditor.get('editedResourceId');
        return (subeditorId === pathId);
    });
    if (elementSubeditorIndex !== -1) {
        //the deleted element is opened in a subeditor as root, close the subeditor
        newState = newState.deleteIn(['subeditors', elementSubeditorIndex]);
        if (newState.get('currentEditorIndex') === elementSubeditorIndex) {
            newState = newState.delete('currentEditorIndex');
        }
    }
    if (elementToRemove) {
        const type = elementToRemove.get('type');
        const id = elementToRemove.get('id');
        let selection = state.getIn(['selection']) as Map<string, any>;
        if (selection.has(id)) {
            //remove the element from the selection
            selection = selection.remove(id);
            newState = newState.set('selection', selection);
        }
        if (isContainer(type)) {
            const childrenToDelete = [];
            const elements: OrderedMap<string, any> = newState.getIn(['model', 'elements']) as OrderedMap<string, any>;
            const containerPath = path.join('/');
            elements.forEach((element) => {
                const elementPath = element.get('path');
                if (elementPath === containerPath) {
                    childrenToDelete.push(element);
                }
            });
            childrenToDelete.forEach((childToDelete) => {
                newState = removeElement(newState, ['elements', childToDelete.get('id')]);
            });

            const parentPath = elementToRemove.get('path').split('/');
            const containerElementsPath = ['model', ...parentPath, 'elementIds'];
            const parentChildrenList = newState.getIn(containerElementsPath, List<string>()) as List<string>;
            const deletedElementIndex = parentChildrenList.indexOf(id);
            newState = newState.updateIn(containerElementsPath, (val: List<string>) => (val || List<string>()).delete(deletedElementIndex));
            newState = newState.deleteIn(['model', ...path]);
        } else if (type === ElementTypes.BAND) {
            const childrenToDeleteId = [];
            const childrenIds = elementToRemove.get('elementIds');
            childrenIds.forEach((childId) => {
                const element = newState.getIn(['model', 'elements', childId]);
                if (element) {
                    childrenToDeleteId.push(childId);
                }
            });
            childrenToDeleteId.forEach((childId) => {
                newState = removeElement(newState, ['elements', childId]);
            });
            const bandId = elementToRemove.get('id');
            newState = newState.deleteIn(['model', 'bands', bandId]);
            const bandType = elementToRemove.get('bandType');
            if (bandType === BandTypes.BAND_DETAIL) {
                newState = newState.updateIn(['model', 'detailsOrder'], (list: List<string>) => (list || List<string>()).filter((currentId) => { return currentId !== bandId }));
            } else if (bandType === BandTypes.BAND_GROUP_HEADER) {
                newState = newState.updateIn(['model', 'groupHeadersOrder'], (list: List<string>) => (list || List<string>()).filter((currentId) => { return currentId !== bandId }));
            } else if (bandType === BandTypes.BAND_GROUP_FOOTER) {
                newState = newState.updateIn(['model', 'groupFootersOrder'], (list: List<string>) => (list || List<string>()).filter((currentId) => { return currentId !== bandId }));
            }
        } else {
            //simple element
            const parentPath = elementToRemove.get('path').split('/');
            const containerElementsPath = ['model', ...parentPath, 'elementIds'];
            const parentChildrenList = newState.getIn(containerElementsPath, List<string>()) as List<string>;
            const deletedElementIndex = parentChildrenList.indexOf(id);
            newState = newState.updateIn(containerElementsPath, (val: List<string>) => (val || List<string>()).delete(deletedElementIndex));
            newState = newState.deleteIn(['model', ...path]);

        }
    }
    return newState;
}