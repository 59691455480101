/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const GanttChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="555" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 555 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h555v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M46 18v133h501V18z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M225.478 157.188L329.522 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M225.478 173.5L329.522 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M225.478 173.5L225.478 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M329.522 173.5L329.522 157.188" clipPath="url(#clipPath1)" />
            <path fill="#F55" stroke="none" d="M228.978 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="240.978" y="168.113" stroke="none" clipPath="url(#clipPath1)">Scheduled</text>
            <path fill="#55F" stroke="none" d="M288.818 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="300.818" y="168.113" stroke="none" clipPath="url(#clipPath1)">Actual</text>
            <path fill="none" strokeWidth="0.5" d="M46 18L547 18" clipPath="url(#clipPath1)" />
            <text x="81.201" y="12.113" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">2-mag</text>
            <path fill="none" strokeWidth="0.5" d="M94.076 16L94.076 18" clipPath="url(#clipPath1)" />
            <text x="131.807" y="12.113" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">4-mag</text>
            <path fill="none" strokeWidth="0.5" d="M144.682 16L144.682 18" clipPath="url(#clipPath1)" />
            <text x="182.413" y="12.113" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">6-mag</text>
            <path fill="none" strokeWidth="0.5" d="M195.288 16L195.288 18" clipPath="url(#clipPath1)" />
            <text x="233.019" y="12.113" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">8-mag</text>
            <path fill="none" strokeWidth="0.5" d="M245.894 16L245.894 18" clipPath="url(#clipPath1)" />
            <text x="281.08" y="12.113" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10-mag</text>
            <path fill="none" strokeWidth="0.5" d="M296.5 16L296.5 18" clipPath="url(#clipPath1)" />
            <text x="331.686" y="12.113" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">12-mag</text>
            <path fill="none" strokeWidth="0.5" d="M347.106 16L347.106 18" clipPath="url(#clipPath1)" />
            <text x="382.292" y="12.113" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">14-mag</text>
            <path fill="none" strokeWidth="0.5" d="M397.712 16L397.712 18" clipPath="url(#clipPath1)" />
            <text x="432.898" y="12.113" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">16-mag</text>
            <path fill="none" strokeWidth="0.5" d="M448.318 16L448.318 18" clipPath="url(#clipPath1)" />
            <text x="483.504" y="12.113" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">18-mag</text>
            <path fill="none" strokeWidth="0.5" d="M498.924 16L498.924 18" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M46 18L46 151" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M46 40.167L46 40.167" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M46 40.167L44 40.167" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M46 84.5L46 84.5" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M46 84.5L44 84.5" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M46 128.833L46 128.833" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M46 128.833L44 128.833" clipPath="url(#clipPath1)" />
            <text x="14.227" y="42.936" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">TASK1</text>
            <text x="14.227" y="87.269" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">TASK2</text>
            <text x="14.227" y="131.603" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">TASK3</text>
         </g>
         <g fill="silver" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M94.076 18L94.076 151" clipPath="url(#clipPath2)" />
            <path fill="none" d="M144.682 18L144.682 151" clipPath="url(#clipPath2)" />
            <path fill="none" d="M195.288 18L195.288 151" clipPath="url(#clipPath2)" />
            <path fill="none" d="M245.894 18L245.894 151" clipPath="url(#clipPath2)" />
            <path fill="none" d="M296.5 18L296.5 151" clipPath="url(#clipPath2)" />
            <path fill="none" d="M347.106 18L347.106 151" clipPath="url(#clipPath2)" />
            <path fill="none" d="M397.712 18L397.712 151" clipPath="url(#clipPath2)" />
            <path fill="none" d="M448.318 18L448.318 151" clipPath="url(#clipPath2)" />
            <path fill="none" d="M498.924 18L498.924 151" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M195.288 24.65H220.591V35.733H195.288z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M68.773 24.65H94.076V35.733H68.773z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M119.379 24.65H169.985V35.733H119.379z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M321.803 24.65H423.015V35.733H321.803z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M220.591 44.6H271.197V55.683H220.591z" clipPath="url(#clipPath2)" />
            <path fill="#0f0" stroke="none" d="M220.591 48.479H233.243V51.804H220.591z" clipPath="url(#clipPath2)" />
            <path fill="red" stroke="none" d="M233.242 48.479H271.197V51.804H233.242z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M68.773 44.6H94.076V55.683H68.773z" clipPath="url(#clipPath2)" />
            <path fill="#0f0" stroke="none" d="M68.773 48.479H90.28099999999999V51.804H68.773z" clipPath="url(#clipPath2)" />
            <path fill="red" stroke="none" d="M90.28 48.479H94.07600000000001V51.804H90.28z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M94.076 44.6H169.985V55.683H94.076z" clipPath="url(#clipPath2)" />
            <path fill="#0f0" stroke="none" d="M94.076 48.479H132.031V51.804H94.076z" clipPath="url(#clipPath2)" />
            <path fill="red" stroke="none" d="M132.03 48.479H169.985V51.804H132.03z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M347.106 44.6H423.015V55.683H347.106z" clipPath="url(#clipPath2)" />
            <path fill="#0f0" stroke="none" d="M347.106 48.479H423.015V51.804H347.106z" clipPath="url(#clipPath2)" />
            <path fill="red" stroke="none" d="M423.015 48.479L423.015 51.804" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M195.288 68.983H245.894V80.066H195.288z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M252.22 68.983H271.197V80.066H252.22z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M296.5 68.983H423.015V80.066H296.5z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M195.288 88.933H296.5V100.016H195.288z" clipPath="url(#clipPath2)" />
            <path fill="#0f0" stroke="none" d="M195.288 92.813H210.47V96.138H195.288z" clipPath="url(#clipPath2)" />
            <path fill="red" stroke="none" d="M210.47 92.813H296.5V96.138H210.47z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M505.25 88.933H524.227V100.016H505.25z" clipPath="url(#clipPath2)" />
            <path fill="#0f0" stroke="none" d="M505.25 92.813H509.046V96.138H505.25z" clipPath="url(#clipPath2)" />
            <path fill="red" stroke="none" d="M509.046 92.813H524.228V96.138H509.046z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M321.803 88.933H423.015V100.016H321.803z" clipPath="url(#clipPath2)" />
            <path fill="#0f0" stroke="none" d="M321.803 92.813H367.348V96.138H321.803z" clipPath="url(#clipPath2)" />
            <path fill="red" stroke="none" d="M367.349 92.813H423.01599999999996V96.138H367.349z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M220.591 113.317H245.894V124.39999999999999H220.591z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M296.5 133.267H321.803V144.35H296.5z" clipPath="url(#clipPath2)" />
            <path fill="#0f0" stroke="none" d="M296.5 137.146H320.538V140.47099999999998H296.5z" clipPath="url(#clipPath2)" />
            <path fill="red" stroke="none" d="M320.538 137.146H321.803V140.47099999999998H320.538z" clipPath="url(#clipPath2)" />
         </g>
      </svg>
   );
}
export default GanttChartIcon;