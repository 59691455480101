/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const GaugeIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="802" height="493" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 802 493" {...props}>
      <defs>
        <clipPath id="highcharts-b51kkja-1">
          <path fill="none" d="M0 0H782V425H0z"></path>
        </clipPath>
        <linearGradient id="highcharts-b51kkja-2" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0" stopColor="#fff"></stop>
          <stop offset="1" stopColor="#e6e6e6"></stop>
        </linearGradient>
      </defs>
      <rect
        width="802"
        height="493"
        fill="#fff"
        className="highcharts-background"
        rx="0"
        ry="0"
      ></rect>
      <path
        fill="none"
        d="M10 53H792V478H10z"
        className="highcharts-plot-background"
      ></path>
      <g className="highcharts-pane-group" data-z-index="0">
        <path
          fill="url(#highcharts-b51kkja-2)"
          d="M401 84.875a180.625 180.625 0 11-.18 0m.017 18.063a162.563 162.563 0 10.163 0"
          className="highcharts-pane"
        ></path>
      </g>
      <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
        <path
          fill="none"
          d="M401 265.5V84.875M401 265.5V84.875"
          className="highcharts-grid-line"
          data-z-index="1"
        ></path>
      </g>
      <path
        fill="none"
        d="M10 53H792V478H10z"
        className="highcharts-plot-border"
        data-z-index="1"
      ></path>
      <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
        <path
          fill="none"
          d="M401 84.875a180.625 180.625 0 11-.18 0M401 265.5"
          className="highcharts-axis-line"
          data-z-index="7"
        ></path>
      </g>
      <g
        className="highcharts-data-labels highcharts-series-0 highcharts-solidgauge-series highcharts-tracker"
        data-z-index="2"
      >
        <text
          x="5"
          y="16"
          color="#000"
          data-z-index="1"
          fontSize="11"
          fontWeight="bold"
          textAnchor="middle"
          transform="translate(10 53) translate(391 191)"
          className="0"
        >
          <tspan
            x="5"
            y="16"
            fill="#FFF"
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="2"
            className="highcharts-text-outline"
          >
            122
          </tspan>
          <tspan
            x="5"
            fill="#FFF"
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="2"
            className="highcharts-text-outline"
            dy="14"
            fontWeight="normal"
          >
            Region Count
          </tspan>
          <tspan x="5" y="16">
            122
          </tspan>
          <tspan x="5" dy="14" fontWeight="normal">
            Region Count
          </tspan>
        </text>
      </g>
      <g className="highcharts-series-group" data-z-index="3">
        <g
          className="highcharts-series highcharts-series-0 highcharts-solidgauge-series highcharts-tracker"
          clipPath="url(#highcharts-b51kkja-1)"
          data-z-index="0.1"
          transform="translate(10 53)"
        >
          <path
            fill="#7cb5ec"
            d="M391 31.875a180.625 180.625 0 11-180.082 166.633l18.008 1.4A162.563 162.563 0 10391 49.938z"
            className="highcharts-point highcharts-color-0"
          ></path>
        </g>
      </g>
      <text
        x="401"
        y="19"
        fill="#333"
        className="highcharts-title"
        color="#333"
        data-z-index="4"
        fontFamily="Lobster Two"
        fontSize="24"
        textAnchor="middle"
      >
        <tspan>Gauge</tspan>
      </text>
      <text
        x="792"
        y="488"
        fill="#999"
        className="highcharts-credits"
        color="#999"
        cursor="pointer"
        data-z-index="8"
        fontSize="9"
        textAnchor="end"
      >
        Highcharts.com
      </text>
    </svg>
)};

export default GaugeIcon;