/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import { Rnd } from 'react-rnd';
import { Section } from './Section';

interface IState {
  width: number | undefined;
  height: number | undefined,
  x: number | undefined,
  y: number | undefined,
}

interface IProps {
    width: number;
    height: number,
    x: number,
    y: number,
    dragHandleClassName?: string,
    title?: string,
    style?: React.CSSProperties
    isFixed?: boolean,
}

/**
 * This is the repository main screen
 */
export class MovableRnD extends React.Component<IProps, IState> {

    
    constructor(props: IProps) {
        super(props);
        this.state = {
            width: undefined,
            height: undefined,
            x: undefined,
            y: undefined,
        }
    }

    private setSize = (width: number, height: number) => {
        if (!this.props.isFixed && (this.state.width !== width || this.state.height !== height)){
            this.setState({width, height});
        }
    }

    private setLocation = (x: number, y: number) => {
        if (!this.props.isFixed && (this.state.x !== x || this.state.y !== y)){
            this.setState({x, y});
        }
    }

    public render() {
        const xPosition = this.state.x !== undefined ? this.state.x : this.props.x;
        const yPosition = this.state.y !== undefined ? this.state.y : this.props.y;
        const width = this.state.width !== undefined ? this.state.width : this.props.width;
        const height = this.state.height !== undefined ? this.state.height : this.props.height;
        let content;
        let handleClassName = this.props.dragHandleClassName;
        if (this.props.title){
            handleClassName = 'tc-jsw-column-section-title';
            content = <Section key={this.props.title} title={this.props.title} bodyStyle={{ paddingTop: 0 }}>
                {this.props.children}
            </Section>
        } else {
            content = this.props.children; 
        }
        const style = {
            display: 'flex', 
            flex: 1,
            width: '100%',
            height: '100%',
            ...this.props.style,
        };
        if (this.props.isFixed){
            return (
                <div style={{position: 'absolute', display: 'flex', left: this.props.x, top: this.props.y, width: width, height: height}}>
                    <div style={style}>
                        {content}
                    </div>
                </div>
            );
        } else {
            return (
                <Rnd dragHandleClassName={handleClassName} position={{x : xPosition, y: yPosition }} size={{width, height}} onDragStop={(e, d) => { this.setLocation(d.x, d.y); }}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onResizeStop={(e, direction, ref, delta, position) => { this.setSize(Number.parseInt(ref.style.width, 10), Number.parseInt(ref.style.height, 10)); }}>
                    <div style={style}>
                        {content}
                    </div>
                </Rnd>
            );
        }
    }
}
