/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { AbstractTextualEditor, IAbstractTextualEditor } from '../editor/AbstractTextualEditor';
import AceEditor, { IAceEditorProps } from 'react-ace';
import format from 'xml-formatter';
import { RunContext } from '@jss/js-repository';

export default class InternalJRIOContextSourceEditor extends AbstractTextualEditor {

    constructor(props: IAbstractTextualEditor) {
        super(props);
    }

    public getAcceptedFileTypes(): string {
        return this.props.acceptedFileTypes ? this.props.acceptedFileTypes : ".xml,application/xml"
    }

    public getDefaultExtension(): string {
        return this.props.defaultExtension ? this.props.defaultExtension : 'xml';
    }

    public getTextEditor(editorProps: IAceEditorProps): React.ReactElement {
        const props = {
            ...editorProps,
            mode: 'xml',
            wrapEnabled: true,
            enableBasicAutocompletion: true,
            tabSize: 2,
            theme: 'textmate',
        };
        return <AceEditor {...props} ref={this.editor} />;
    }

    public formatContent(content: string): string { 
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(content, 'text/xml');
        const errorNode = xmlDoc.querySelector('parsererror');
        if (errorNode) {
            // the XML content is invalid, do not format allowing to see possible errors           
            return content;
        }
        else {
            try {
                return format(content, { indentation: '  ', collapseContent: true });
            } catch (error) {
                return content;
            }    
        }
    }

    public hasFomatter = () => {
        return true;
    }
}

InternalJRIOContextSourceEditor.contextType = RunContext;
