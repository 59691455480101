/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MBoxGraphicElement } from "../common/MGraphicElement";
import { EvaluationTimeEnum } from "../enums/EvaluationTimeEnum";
import { FillEnum } from "../enums/FillEnum";
import { HorizontalImageAlignEnum } from "../enums/HorizontalImageAlignEnum";
import { OnErrorTypeEnum } from "../enums/OnErrorTypeEnum";
import { ScaleImageEnum } from "../enums/ScaleImageEnum";
import { VerticalImageAlignEnum } from "../enums/VerticalImageAlignEnum";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PExpression } from "../../ui/primitive/UPExpression";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { MBookmarks } from "../common/MBookmarks";
import { MHyperlinks } from "../common/MHyperlinks";
import { ICONS } from "@jss/js-common";
import { MImageCustom } from "./MImageCustom";

export const FImage = {
    imageExpression: new PExpression({ id: 'imageExpression', label: 'Expression' }),
    evaluationTime: EvaluationTimeEnum,
    isLazy: new PBoolean({ id: 'isLazy', label: 'Lazy' }),
    horizontalImageAlignment: HorizontalImageAlignEnum,
    verticalImageAlignment: VerticalImageAlignEnum,
    fill: FillEnum,
    onErrorType: OnErrorTypeEnum,
    scaleImage: ScaleImageEnum,
    isUsingCache: new PBoolean({ id: 'isUsingCache', label: 'Use Cache', default: 'false',  canBeNull: true })
};

export const MIMage: APDescriptor = {
    type: ElementTypes.IMAGE,
    label: 'Image',
    id: 'imageProeprtiesDescriptor',
    description: 'Report element for Image',
    icon: ICONS.IMAGE_ICON,
    default: { width: 200, height: 30 },
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MBoxGraphicElement.layouts, MImageCustom] }),
            new PDiv({
                label: 'Image',
                layouts: [FImage.imageExpression, FImage.evaluationTime, FImage.isLazy, FImage.isUsingCache, FImage.fill, FImage.onErrorType,
                new PAccordeon({
                    label: 'Image Arrangement', expanded: true,
                    layouts: [FImage.horizontalImageAlignment, FImage.verticalImageAlignment, FImage.scaleImage]
                }), MBookmarks, MHyperlinks]
            })
        ]
    })]
};
