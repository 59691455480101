/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const DataMatrixShapeEnum = new PEnum({
    id: 'shape', label: 'DataMatrixShape.label', isEmptyUndefined: true,
    items: [
        { key: '', value: '' },
        { key: 'forceNone', value: 'DataMatrixShape.forceNone' },
        { key: 'forceSquare', value: 'DataMatrixShape.forceSquare' },
        { key: 'forceRectangle', value: 'DataMatrixShape.forceRectangle' }
    ]
});

