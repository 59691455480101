/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { FormControlledTextField } from '@jss/js-common';
import { getKey, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { resolveAttribute } from '../../types/common/StyleResolver';
import { Map } from 'immutable';
import '../../../../../assets/uxpl/css/UPControls.css';
import i18n from '../../../../../i18n';
import { ValidationResult, VALIDATION_RESULT } from '@jss/js-common/src/utils/validators';

const TYPEID = 'string';
reg(TYPEID, (mc) => { return <UPString mcontext={mc} />; });
export class PString extends APDescriptor {
    max?: number = 1000;
    canBeEmpty?: boolean = true;
    canBeNull?: boolean = true;
    default?: string;
    iconComponent?: React.ReactElement;

    public validator(v: string | undefined): ValidationResult {
        if (this.max && v && v.length > this.max) { 
            return {result: VALIDATION_RESULT.PARTIALLY_INVALID, message: 'value is exceeding ' + this.max};
        }
        if (!this.canBeEmpty && (!v || v.trim().length === 0)) { 
            return {result: VALIDATION_RESULT.INVALID, message: `The value can't be empty`};
        }
        if (!this.canBeNull && (!v || v === null)) { 
            return {result: VALIDATION_RESULT.INVALID, message: `The value can't be empty`};
        }
        return {result: VALIDATION_RESULT.VALID};
    }

    public constructor(init: Partial<PString>) {
        super();
        Object.assign(this, { validator: this.validator, ...init, type: TYPEID });
    }
}

export const stringValidator = (stringValue: string | undefined, descriptor: PString): ValidationResult => {
    if (descriptor.validator) {
        const validationResult = descriptor.validator(stringValue);
        if (validationResult) {
            return validationResult;
        }
    }
    return { result: VALIDATION_RESULT.VALID }
}

export interface IUPString {
    additionalIcon?: React.ReactElement, 
}

export class UPString<T = void> extends React.Component<UiPropertyProps & IUPString & T> {

    shouldComponentUpdate(nextProps: Readonly<UiPropertyProps>): boolean {
        const d1 = this.props.mcontext.descriptor as PString;
        const d2 = nextProps.mcontext.descriptor as PString;
        const p1 = getPath(d1.id, this.props.mcontext.elements[0].path);
        const p2 = getPath(d2.id, nextProps.mcontext.elements[0].path);
        const v1 = this.props.mcontext.model.getIn(p1);
        const v2 = nextProps.mcontext.model.getIn(p2);
        return v1 !== v2;
    }

    getValidator = (descriptor) => {
        return (stringValue: string) => stringValidator(stringValue, descriptor)
    }

    render() {
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        let isInherited = false;
        let v = this.props.mcontext.model.getIn(p);
        if (v === undefined && this.props.mcontext.descriptor.inheritedPropertyId) {
            const elementPath = getPath(undefined, this.props.mcontext.elements[0].path).slice(0, 2);
            const element = this.props.mcontext.model.getIn(elementPath) as Map<string, any>;
            v = resolveAttribute(this.props.mcontext.model, element, this.props.mcontext.descriptor.inheritedPropertyId);
            isInherited = true;
        }
        const descriptor = this.props.mcontext.descriptor as PString;
        const classes: string[] = [];
        if (this.props.mcontext.descriptor.className) {
            classes.push(this.props.mcontext.descriptor.className);
        }
        if (isInherited) {
            classes.push('placeHolderTextColor');
        }

        const validator = this.getValidator(descriptor);

        return <FormControlledTextField
            className={classes.join(' ')}
            style={this.props.mcontext.descriptor.style}
            key={getKey(p)}
            onTextChange={this.onValueChange}
            label={i18n.t(this.props.mcontext.descriptor.label)}
            InputLabelProps={descriptor.deprecated ? {className: 'deprecatedProperty'} : undefined}
            InputProps={{title: v as string}}
            inline={true}
            size={'small'}
            disabled={descriptor.readonly}
            iconButtons={this.props.additionalIcon ? [this.props.additionalIcon] : undefined}
            //use default value for the value, since value tend to put the cursor at the end
            value={v === undefined ? '' : v}
            type={'text'}
            validator={validator}
        />
    }

    public onValueChange = (newValue: string | undefined) => {
        //this is called only if the value is valid, no need to redo the validation
        this.props.mcontext.elements.forEach(key => {
            if (this.props.mcontext.descriptor.id)
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: newValue });
            else
                this.props.mcontext.setObjectProperties(key.path, newValue);
        });
    }
}

