/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const AreaChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
            <defs>
                <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
                    <path d="M0 0h270v175H0V0z" />
                </clipPath>
                <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
                    <path d="M44 4v130h218V4z" />
                </clipPath>
            </defs>
            <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
                <path fill="none" d="M24.51 157.188L245.49 157.188" clipPath="url(#clipPath1)" />
                <path fill="none" d="M24.51 173.5L245.49 173.5" clipPath="url(#clipPath1)" />
                <path fill="none" d="M24.51 173.5L24.51 157.188" clipPath="url(#clipPath1)" />
                <path fill="none" d="M245.49 173.5L245.49 157.188" clipPath="url(#clipPath1)" />
                <path fill="#F55" stroke="none" d="M28.01 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
                <path fill="none" stroke="gray" d="M28.01 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
                <text x="40.01" y="168.113" stroke="none" clipPath="url(#clipPath1)">Double Freight</text>
                <path fill="#55F" stroke="none" d="M105.22 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
                <path fill="none" stroke="gray" d="M105.22 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
                <text x="117.221" y="168.113" stroke="none" clipPath="url(#clipPath1)">Normal Freight</text>
                <path fill="#5F5" stroke="none" d="M183.17 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
                <path fill="none" stroke="gray" d="M183.17 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
                <text x="195.17" y="168.113" stroke="none" clipPath="url(#clipPath1)">Half Freight</text>
                <path fill="none" strokeWidth="0.5" d="M44 134L262 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M74.52 134L74.52 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M74.52 134L74.52 136" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M113.76 134L113.76 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M113.76 134L113.76 136" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M153 134L153 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M153 134L153 136" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M192.24 134L192.24 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M192.24 134L192.24 136" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M231.48 134L231.48 134" clipPath="url(#clipPath1)" />
                <path fill="none" strokeWidth="0.5" d="M231.48 134L231.48 136" clipPath="url(#clipPath1)" />
                <text x="54.938" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Argentina</text>
                <text x="99.629" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Austria</text>
                <text x="136.602" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Belgium</text>
                <text x="181.078" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Brazil</text>
                <text x="216.259" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Canada</text>
                <path fill="none" strokeWidth="0.5" d="M44 4L44 134" clipPath="url(#clipPath1)" />
                <text x="32.91" y="136.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
                <path fill="none" strokeWidth="0.5" d="M42 134L44 134" clipPath="url(#clipPath1)" />
                <text x="15.098" y="115.832" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">2.500</text>
                <path fill="none" strokeWidth="0.5" d="M42 113.062L44 113.062" clipPath="url(#clipPath1)" />
                <text x="15.098" y="94.894" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">5.000</text>
                <path fill="none" strokeWidth="0.5" d="M42 92.124L44 92.124" clipPath="url(#clipPath1)" />
                <text x="15.098" y="73.956" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">7.500</text>
                <path fill="none" strokeWidth="0.5" d="M42 71.186L44 71.186" clipPath="url(#clipPath1)" />
                <text x="10.008" y="53.018" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.000</text>
                <path fill="none" strokeWidth="0.5" d="M42 50.249L44 50.249" clipPath="url(#clipPath1)" />
                <text x="10.008" y="32.08" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">12.500</text>
                <path fill="none" strokeWidth="0.5" d="M42 29.311L44 29.311" clipPath="url(#clipPath1)" />
                <text x="10.008" y="11.143" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">15.000</text>
                <path fill="none" strokeWidth="0.5" d="M42 8.373L44 8.373" clipPath="url(#clipPath1)" />
            </g>
            <g fill="none" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
                <path d="M44 134L262 134" clipPath="url(#clipPath2)" />
                <path d="M44 113.062L262 113.062" clipPath="url(#clipPath2)" />
                <path d="M44 92.124L262 92.124" clipPath="url(#clipPath2)" />
                <path d="M44 71.186L262 71.186" clipPath="url(#clipPath2)" />
                <path d="M44 50.249L262 50.249" clipPath="url(#clipPath2)" />
                <path d="M44 29.311L262 29.311" clipPath="url(#clipPath2)" />
                <path d="M44 8.373L262 8.373" clipPath="url(#clipPath2)" />
            </g>
            <g fill="#F55" stroke="none" strokeLinejoin="bevel" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
                <path d="M55 134l20-10.026 19-56.892V134z" clipPath="url(#clipPath2)" />
                <path fill="#55F" d="M55 134l20-5.013 19-28.446V134z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" d="M55 134l20-2.507 19-14.222V134z" clipPath="url(#clipPath2)" />
                <path d="M94 134V67.082l20-56.891 19 51.183V134z" clipPath="url(#clipPath2)" />
                <path fill="#55F" d="M94 134v-33.459l20-28.446 19 25.592V134z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" d="M94 134v-16.73l20-14.222 19 12.796V134z" clipPath="url(#clipPath2)" />
                <path d="M133 134V61.374l20 51.183 20-30.15V134z" clipPath="url(#clipPath2)" />
                <path fill="#55F" d="M133 134V97.687l20 25.592 20-15.076V134z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" d="M133 134v-18.156l20 12.795 20-7.537V134z" clipPath="url(#clipPath2)" />
                <path d="M173 134V82.406l19-30.15 20 22.463V134z" clipPath="url(#clipPath2)" />
                <path fill="#55F" d="M173 134v-25.797l19-15.075 20 11.231V134z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" d="M173 134v-12.898l19-7.538 20 5.616V134z" clipPath="url(#clipPath2)" />
                <path d="M212 134V74.719l19 22.462L251 134z" clipPath="url(#clipPath2)" />
                <path fill="#55F" d="M212 134v-29.64l19 11.23L251 134z" clipPath="url(#clipPath2)" />
                <path fill="#5F5" d="M212 134v-14.82l19 5.615L251 134z" clipPath="url(#clipPath2)" />
            </g>
        </svg>
    );
}
export default AreaChartIcon;