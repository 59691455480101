/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { TextInput } from './TextInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const ENTER_KEY = 13;

interface ITimedSearchBar {
    id?: string;
    label?: string;
    help?: string;
    placeholder?: string;
    disabled?: boolean;
    onChange?: (str: string | null | undefined, event?: React.ChangeEvent<HTMLInputElement>) => void;
    onEnterPress?: (str: string | null | undefined) => void;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    value?: string | null;
    className?: string;
    style?: React.CSSProperties;
    pauseTime: number;
    WrapperProps?: React.HTMLAttributes<HTMLDivElement> & {[key: string]: any},
}

interface ITimedSearchBarState {
    currentValue?: string | null;
    event: React.ChangeEvent<HTMLInputElement> | undefined;
}

/**
 * Search bar that has a stop digit timer, to trigger the change event only when the
 * user hit enter or he has stop to digit for at least one second (configurable in the wait interval).
 */
export class TimedSearchBar extends React.Component<ITimedSearchBar, ITimedSearchBarState> {
    static defaultProps = {
        pauseTime: 1000
    }
    public state: ITimedSearchBarState = {
        currentValue: null,
        event: undefined,
    };

    private timer: any;

    public componentDidUpdate = (prevProps: ITimedSearchBar) => {
        if (prevProps.value !== this.props.value) {
            this.setState({ currentValue: this.props.value });
        }
    }

    public componentDidMount() {
        this.timer = null;
        this.setState({ currentValue: this.props.value });
    }

    public render() {
        return <TextInput label={this.props.label}
            id={this.props.id}
            disabled={this.props.disabled ?? false}
            placeholder={this.props.placeholder}
            className={this.props.className}
            style={this.props.style}
            value={this.state.currentValue != null ? this.state.currentValue : undefined}
            onChange={this.handleChange}
            onClick={this.props.onClick}
            onKeyDown={this.handleKeyDown}
            WrapperProps={this.props.WrapperProps}
            InputProps={{
                className: "jr-mInitialTextIconPadding",
                startAdornment: (
                    <InputAdornment
                        position="start"
                        className={"jr-mInputSearch-leftadornment mui"}
                    >
                        <SearchIcon/>
                    </InputAdornment>
                )
            }}/>
    }

    public setTextContent = (value: string | undefined) => {
        this.setState({ currentValue: value });
    }

    private handleChange = (value: string) => {
        clearTimeout(this.timer);
        this.setState({ currentValue: value }, this.startTimer);
    }

    private startTimer = () => {
        this.timer = setTimeout(this.triggerChange, this.props.pauseTime);
    }

    private handleKeyDown = (key: string, keycode: number) => {
        if (keycode === ENTER_KEY) {
            clearTimeout(this.timer);
            this.triggerEnterPress();
        }
    }

    private triggerChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.state.currentValue, this.state.event);
        }
    }

    private triggerEnterPress = () => {
        if (this.props.onEnterPress){
            this.props.onEnterPress(this.state.currentValue);
        } else if (this.props.onChange) {
            this.props.onChange(this.state.currentValue, this.state.event);
        }
    }
}