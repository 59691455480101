/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const ChecksumModeEnum = new PEnum({
    id: 'checksumMode', label: 'ChecksumMode.label', isEmptyUndefined: true,
    items: [
        { key: '', value: '' },
        { key: 'auto', value: 'ChecksumMode.auto' },
        { key: 'ignore', value: 'ChecksumMode.ignore' },
        { key: 'add', value: 'ChecksumMode.add' },
        { key: 'check', value: 'ChecksumMode.check' }
    ]
});

