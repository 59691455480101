/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IRepositoryItemDescriptor, MessageInfo } from '@jss/js-rest-api';
import { Map as ImmutableMap } from 'immutable';
import { IToolbarProvider } from '../uxpl/toolbars/ToolbarProvider';

export interface IResourceInfo {
    name: string,
    content: string,
    resource: IRepositoryItemDescriptor,
    path: IRepositoryItemDescriptor[], //Array<{ name: string, uuid: IRepositoryItemDescriptor }>,
    // folderUUID: IRepositoryItemDescriptor | undefined,
    isResourceDirty?: boolean,
    parameters?: ImmutableMap<string, any>,
    options?: IResourceInfoOptions,
}

export interface IResourceInfoOptions {
    keepCache?: boolean
    keepHistory?: boolean
}

export interface IResourceListener {
    onResourceOpen(resource: IResourceInfo): void;
}

export interface IResourceListenerManager {
    addResourceListener(id: string, resourceListener: IResourceListener): void;

    removeResourceListener(id: string): boolean;
}

export interface CommandRegistry {
    registerHandler(id: string, handle: CommandHandler): void,
    executeCommand(id: string, ...params: any): void;
    registerToolbar: (toolbarProvider: IToolbarProvider) => void;
    showMessage: (message: MessageInfo) => void;
}

export interface CommandHandler {
    execute(args: Record<string, unknown>, callback?: () => void): void,
    isEnabled(...args: any[]): boolean,
}

interface IEditorState {
    isEditorDirty: boolean;
}

interface IEditor {
    state: IEditorState;
}

export class ResourceListenerManager implements IResourceListenerManager {

    private registeredEditors = new Map<string, IResourceListener>();

    private editor: IEditor;

    constructor(editor: IEditor) {
        this.editor = editor;
    }

    public addResourceListener = (id: string, resourceListener: IResourceListener) => {
        this.registeredEditors.set(id, resourceListener);
    }

    public removeResourceListener = (id: string) => {
        return this.registeredEditors.delete(id);
    };

    public fireOpenEvent = (resource: IRepositoryItemDescriptor, data: any, path: IRepositoryItemDescriptor[], options: IResourceInfoOptions = {keepCache: false}, parameters: ImmutableMap<string, any> = ImmutableMap<string, any>()) => {
        this.registeredEditors.forEach((editor) => {
            editor.onResourceOpen({
                name: resource.name ? resource.name : '',
                content: data,
                resource: resource,
                path: path,
                isResourceDirty: this.editor.state.isEditorDirty,
                options: options,
                parameters: parameters
            });
        })
    }
}
