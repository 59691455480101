/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const ImageIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Tree/dashboard/image" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" transform="translate(1.000000, 1.000000)" fillRule="nonzero">
                    <rect id="Rectangle" fill="#0081CB" x="0" y="0" width="18" height="16" rx="1"></rect>
                    <polygon id="Path-13" fill="#E0F0F9" points="2 14 7.25 5 10.75 11 12.5 8 16 14"></polygon>
                    <circle id="Oval" fill="#E0F0F9" cx="13.5" cy="4.5" r="1.5"></circle>
                </g>
            </g>
        </svg>
    );
}
export default ImageIcon;