/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ElementTypes } from '../../../sagas/report/document/elementTypes';
import { MBarcode } from '../properties/types/barcode/MBarcode';
import { MTextField } from '../properties/types/elements/MTextField';
import { MBreak } from '../properties/types/elements/MBreak';
import { MRectangle } from '../properties/types/elements/MRectangle';
import { MEllipse } from '../properties/types/elements/MEllipse';
import { MLine } from '../properties/types/elements/MLine';
import { MGenericElement } from '../properties/types/elements/MGenericElement';
import { MFrame } from '../properties/types/elements/MFrame';
import { MSubreport } from '../properties/types/elements/MSubreport';
import { MJFreeChart } from '../properties/types/chart/MJFreeChart';
import { MHighCharts } from '../properties/types/highchart/MHighChart';
import { MCVC } from '../properties/types/cvc/MCVC';
import { MTable } from '../properties/types/table/MTable';
import { MCrosstab } from '../properties/types/crosstab/MCrosstab';
import { MStaticText } from '../properties/types/elements/MStaticText';
import { MIMage } from '../properties/types/elements/MImage';
import { MList } from '../properties/types/elements/MList';
import { MMap } from '../properties/types/map/MMap';
import { MFusionChart } from '../properties/types/fusion/chart/MFusionChart';
import { MFusionWidgets } from '../properties/types/fusion/widgets/MFusionWidgets';
import { MFusionMap } from '../properties/types/fusion/map/MFusionMap';
//import { MAdhocComponent } from '../properties/types/elements/MAdhocComponent';

export interface IReportPaletteElement {
  id?: string,
  icon?: JSX.Element,
  label?: string,
  break?: boolean
}
export interface IPaletteCategories {
  category: string,
  items: IReportPaletteElement[]
}
export const getReportPaletteItems = (): IPaletteCategories[] => {
  return [{ category: '', items: getStandardElements },
  { category: 'Charts', items: getCharts },
  { category: 'Barcode', items: getBarcodes }]
}

export const getStandardElements: IReportPaletteElement[] = [
  {
    id: ElementTypes.TEXTFIELD,
    icon: MTextField.icon,
    label: MTextField.label
  },
  {
    id: ElementTypes.STATICTEXT,
    icon: MStaticText.icon,
    label: MStaticText.label,
  },
  {
    id: ElementTypes.RECTANGLE,
    icon: MRectangle.icon,
    label: MRectangle.label,
  },
  {
    id: ElementTypes.ELLIPSE,
    icon: MEllipse.icon,
    label: MEllipse.label,
  },
  {
    id: ElementTypes.LINE,
    icon: MLine.icon,
    label: MLine.label,
  },
  { break: true },
  {
    id: ElementTypes.TABLE,
    icon: MTable.icon,
    label: MTable.label
  },
  {
    id: ElementTypes.CROSSTAB,
    icon: MCrosstab.icon,
    label: MCrosstab.label
  },
  {
    id: ElementTypes.LIST,
    icon: MList.icon,
    label: MList.label
  },
  {
    id: ElementTypes.FRAME,
    icon: MFrame.icon,
    label: MFrame.label
  },
  {
    id: ElementTypes.SUBREPORT,
    icon: MSubreport.icon,
    label: MSubreport.label,
  },
  { break: true },
  {
    id: ElementTypes.IMAGE,
    icon: MIMage.icon,
    label: MIMage.label
  },
  {
    id: ElementTypes.MAP,
    icon: MMap.icon,
    label: MMap.label
  },
  {
    id: ElementTypes.BREAK,
    icon: MBreak.icon,
    label: MBreak.label,
  },
  {
    id: ElementTypes.GENERIC_ELEMENT,
    icon: MGenericElement.icon,
    label: MGenericElement.label,
  },
  {
    id: ElementTypes.CVC_ELEMENT,
    icon: MCVC.icon,
    label: MCVC.label
  },
  /*{
    id: ElementTypes.AD_HOC_COMPONENT,
    icon: MAdhocComponent.icon,
    label: MAdhocComponent.label
  }*/
];

export const getBarcodes: IReportPaletteElement[] = MBarcode.subtypes.map(k => { return { id: ElementTypes.BARCODE + ':' + k.id, icon: k.icon, label: k.label } });

export const getCharts: IReportPaletteElement[] = [
  {
    id: ElementTypes.JFREECHART,
    icon: MJFreeChart.icon,
    label: MJFreeChart.label
  },
  {
    id: ElementTypes.HTML5CHART_JR,
    icon: MHighCharts.icon,
    label: MHighCharts.label
  },
  {
    id: ElementTypes.FUSIONCHART,
    icon: MFusionChart.icon,
    label: MFusionChart.label
  },
  {
    id: ElementTypes.FUSION_WIDGET,
    icon: MFusionWidgets.icon,
    label: MFusionWidgets.label
  },
  {
    id: ElementTypes.FUSION_MAP,
    icon: MFusionMap.icon,
    label: MFusionMap.label
  },
  //...MJFreeChart.subtypes.map(k => { return { id: ElementTypes.JFREECHART + ':' + k.id, icon: k.icon, label: k.label } })
];