export const fr = {
  "datasource.csv.descriptor.label": "CSV",
  "datasource.csv.description": "Obtenir des données à partir de fichiers CSV",
  "datasource.editor.testTooltip": "Cela effectuera un test sur l'adaptateur de données",
  "datasource.editor.test.label": "Test",
  "datasource.editor.saveAs.name": "Nom de l'adaptateur de données",
  "datasource.editor.saveAs.title": "Enregistrer l'adaptateur de données sous...",
  "datasource.editor.save.title": "Enregistrer l'adaptateur de données",
  "datasource.editor.saveAs.okButton": "Ok",
  "datasource.editor.save.saveButton": "Enregistrer",
  "datasource.editor.saveAs.saveOk": "Adaptateur de données correctement enregistré",
  "datasource.editor.unknowData": "Source inconnue sélectionnée....",
  "datasource.editor.test.success": "Source de données validée avec succès",
  "datasource.editor.test.fail": "Le test de la source de données a échoué, mais aucune raison n'a été fournie",
  "datasource.editor.test.close": "Fermer",
  "datasource.editor.test.error": "Erreur",
  "datasource.editor.test.cancel": "Annuler",
  "datasource.editor.test.tryAgain": "Réessayer",
  "datasource.editor.loading": "Traitement en cours, veuillez patienter...",

  "datasource.csv.name": "Nom",
  "datasource.csv.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.csv.url": "URL ou Path du fichier CSV",
  "datasource.csv.url.help": "L'URL ou Path pour récupérer le fichier CSV",
  "datasource.csv.separator": "Délimiteur de champ",
  "datasource.csv.delimiter": "Délimiteur d'enregistrement",
  "datasource.csv.datepattern": "Format Des Dates",
  "datasource.csv.datepattern.help": "Le format des dates",
  "datasource.csv.numberpattern": "Format Des Nombres",
  "datasource.csv.numberpattern.help": "Le format du nombre",
  "datasource.csv.timezone": "Fuseau Horaire",
  "datasource.csv.locale": "Paramètres Régionaux",
  "datasource.csv.useFirstLineAsHeader": "Utiliser la première ligne comme en-tête",
  "datasource.csv.columnName": "Nom de colonne",
 
  "datasource.csv.delimiter.lf": "Nouvelle ligne \\n LF, Unix",
  "datasource.csv.delimiter.cr": "Nouvelle ligne \\r CR, MacOS",
  "datasource.csv.delimiter.crlf": "Nouvelle ligne \\r\\n CRLF, Windows",
  "datasource.csv.delimiter.tab": "Tabulation \\t",
  "datasource.csv.delimiter.semicolon": "Point-virgule",
  "datasource.csv.delimiter.comma": "Virgule",
  "datasource.csv.delimiter.space": "Espace",
 
  "datasource.excel.label": "Microsoft Excel (XLS, XLSX)",
  "datasource.excel.description": "Lire les données des fichiers XLS, XLSX",
  "datasource.excel.name": "Nom",
  "datasource.excel.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.excel.url": "URL ou Path du fichier Excel",
  "datasource.excel.url.help": "L'URL ou Path pour récupérer le fichier Excel",
  "datasource.excel.queryMode": "Utiliser le mode exécuteur de requête",
  "datasource.excel.format": "Format Excel",
  "datasource.excel.datepattern": "Format Des Dates",
  "datasource.excel.datepattern.help": "Le format des dates",
  "datasource.excel.numberpattern": "Format Des Nombres",
  "datasource.excel.numberpattern.help": "Le format du nombre",
  "datasource.excel.timezone": "Fuseau Horaire",
  "datasource.excel.locale": "Paramètres Régionaux",
  "datasource.excel.useFirstLineAsHeader": "Utiliser la première ligne comme en-tête",
  "datasource.excel.sheetSelection": "Sélection de feuilles",
  "datasource.excel.columnName": "Nom de colonne",
  "datasource.excel.columnIndex": "Index de colonne",
 
  "datasource.jdbc.label": "Connexion JDBC",
  "datasource.jdbc.description": "Connectez-vous aux bases de données SQL",
  "datasource.jdbc.name": "Nom",
  "datasource.jdbc.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.jdbc.driver": "Class Du Pilote",
  "datasource.jdbc.url": "URL",
  "datasource.jdbc.urlHelp": "L'URL pour se connecter à la base de données",
  "datasource.jdbc.username": "Utilisateur",
  "datasource.jdbc.password": "Mot de passe",
  "datasource.jdbc.urlError": "Veuillez définir une URL valide",
  "datasource.jdbc.className": "Veuillez définir un nom de classe valide",
  "datasource.jdbc.methodName": "Veuillez définir un nom de méthode valide",
  "datasource.jdbc.pathName": "Veuillez définir un chemin valide",
  "datasource.jdbc.autocommit": "Auto Commit",
  "datasource.jdbc.readonly": "Lecture seulement",
  "datasource.jdbc.transactionisolation": "Isolement des transactions",
 
  "datasource.jndi.label": "JNDI",
  "datasource.jndi.description": "Utiliser JNDI comme source de données",
  "datasource.jndi.name": "Nom",
  "datasource.jndi.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.jndi.jndi": "JNDI",

  "datasource.queryexecuter.label": "Query Executor",
 "datasource.queryexecuter.description": "Force le rapport à passer par la requête",
 "datasource.queryexecuter.name": "Nom",
 "datasource.queryexecuter.name.help": "Spécifiez un nom pour cet adaptateur de données",
 
  "datasource.json.label": "JSON",
  "datasource.json.description": "Utiliser les données des fichiers JSON",
  "datasource.json.name": "Nom",
  "datasource.json.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.json.url": "URL ou Path du fichier JSON",
  "datasource.json.url.help": "L'URL ou Path pour récupérer le fichier JSON",
  "datasource.json.useConnection":"Utiliser la connexion",
  "datasource.json.language":"Langue",
  "datasource.json.datepattern": "Format Des Dates",
  "datasource.json.datepattern.help": "Le format des dates",
  "datasource.json.numberpattern": "Format Des Nombres",
  "datasource.json.numberpattern.help": "Le format du nombre",
  "datasource.json.timezone": "Fuseau Horaire",
  "datasource.json.locale": "Paramètres Régionaux",
  "datasource.json.selectExpression": "Expression Du Sélection", 
 
  "datasource.empty.label": "Données Vides",
  "datasource.empty.description": "Des valeurs nulles sont générées pour chaque champ",
  "datasource.empty.recordnumber": "Nombre d'enregistrements",
 
  "datasource.random.label": "Données Aléatoires",
  "datasource.random.description": "Des données aléatoires sont générées pour chaque champ",
  "datasource.random.name": "Nom",
  "datasource.random.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.random.recordnumber": "Nombre d'enregistrements",
  "datasource.random.number.notanumber": "Le nombre d'enregistrements n'est pas un nombre",
  "datasource.random.number.negative": "Le nombre d'enregistrements ne peut pas être négatif",
 
  "datasource.xml.label": "XML",
  "datasource.xml.description": "Utiliser les données des fichiers XML",
  "datasource.xml.name": "Nom",
  "datasource.xml.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.xml.selectExpression": "Expression Du Sélection",
  "datasource.xml.url": "URL ou Path du fichier XML",
  "datasource.xml.url.help": "L'URL ou Path pour récupérer le fichier XML",
  "datasource.xml.enableNamespace": "Activer le support des Namespaces",
  "datasource.xml.xpath": "Utiliser le rapport XPath lors du remplissage du rapport",
  "datasource.xml.expression": "Créer une source de données à l'aide de l'expression select",
  "datasource.xml.datepattern": "Format Des Dates",
  "datasource.xml.datepattern.help": "Le format des dates",
  "datasource.xml.numberpattern": "Format Des Nombres",
  "datasource.xml.numberpattern.help": "Le format du nombre",
  "datasource.xml.timezone": "Fuseau Horaire",
  "datasource.xml.locale": "Paramètres Régionaux",
 
  "datasource.datasource.label": "Data Source",
  "datasource.datasource.description": "Lire les données de a java class",
  "datasource.datasource.name": "Nom",
  "datasource.datasource.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.datasource.factoryClass": "Factory Class",
  "datasource.datasource.methodToCall": "Méthode d'appel",
 
  "datasource.html.allowBodyText": "Autoriser le texte à l'intérieur du corps",
  "datasource.html.httpOptionsPanel": "Options HTTP",
  "datasource.html.username": "Utilisateur",
  "datasource.html.password": "Mot de passe",
  "datasource.html.type": "Type",
  "datasource.html.URLParameters": "Paramètres d'URL",
  "datasource.html.POSTParameters": "Paramètres POST",
  "datasource.html.PUTParameters": "Paramètres PUT",
  "datasource.html.HTTPHeader": "En-tête HTTP",
  "datasource.html.table.parameter.name": "Nom",
  "datasource.html.table.parameter.value": "Valeur",
 
  "datasource.common.dialog.resource": "Ressource",
  "datasource.common.dialog.title": "Sélectionnez la ressource d'adaptateur de données",
 
  "datasource.bean.name": "Nom",
  "datasource.bean.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.bean.factoryClass": "Factory Class",
  "datasource.bean.factoryClass.help": "La classe qui produira les JavaBeans",
  "datasource.bean.methodName": "Méthode d'appel",
  "datasource.bean.methodName.help": "La méthode statique dans la fabrique qui renvoie une collection ou un tableau d'objets",
  "datasource.bean.classpaths": "Chemin des fichiers JAR",
  "datasource.bean.useFiledDescription": "Utiliser la description du champ",
  "datasource.bean.descriptor.label": "JavaBeans",
  "datasource.bean.description": "Utiliser JavaBeans comme source de données", 
 
  "datasource.ejbql.descriptor.label": "EJBQL",
  "datasource.ejbql.description": "Lire les données d'EJBQL",
  "datasource.ejbql.name": "Nom",
  "datasource.ejbql.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.bean.persistanceUnitName": "Nom de l'unité de persistance",
 
  "datasource.hibernate.descriptor.label": "Hibernate",
  "datasource.hibernate.description": "Lire les données de Hibernate",
  "datasource.hibernate.name": "Nom",
  "datasource.hibernate.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.hibernate.xmlFileName": "Fichier Hibernate (.cfg.xml)",
  "datasource.hibernate.propertiesFileName": "Fichier hibernate.proeprties ",
  "datasource.hibernate.useAnnotation": "Utiliser les annotations",
  "datasource.hibernate.springConfigName": "Nom de configuration",
 
  "datasource.springhibernate.descriptor.label": "Hibernate + Spring",
  "datasource.springhibernate.description": "Lire les données de Hibernate avec Spring",
  "datasource.springhibernate.springConfigName": "Configuration Spring",
  "datasource.springhibernate.sessionID": "Session Factory Bean ID",
 
  "datasource.xmla.descriptor.label": "XMLA",
  "datasource.xmla.description": "Lire les données de la base de données XMLA",
  "datasource.xmla.descriptor.url": "URL du serveur XML/A",
  "datasource.xmla.descriptor.datasource": "La source de données",
  "datasource.xmla.descriptor.catalog": "Catalogue",
  "datasource.xmla.descriptor.cube": "Cube",
  "datasource.xmla.descriptor.username": "Utilisateur",
  "datasource.xmla.descriptor.password": "Mot de passe",
 
  "datasource.mondrian.label": "Mondrian",
  "datasource.mondrian.description": "Lire les données de la base de données Mondrian",
  "datasource.mondrian.catalog": "URI Du Catalogue",
 
  "datasource.datasourceprovider.descriptor.label": "DataSourceProvider",
  "datasource.datasourceprovider.description": "Lire les données de la classe DataSourceProvider",
  "datasource.datasourceprovider.descriptor.className": "Nom de la classe DataSourceProvider",
 
  "datasource.jrsdatasource.descriptor.label": "JasperReports Server",
  "datasource.jrsdatasource.description": "Se connecter et utiliser JasperReports Server",
  "datasource.jrsdatasource.name": "Nom",
  "datasource.jrsdatasource.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.jrsdatasource.descriptor.serverurl": "Serveur",
  "datasource.jrsdatasource.descriptor.username": "Utilisateur",
  "datasource.jrsdatasource.descriptor.password": "Mot de passe",
  "datasource.jrsdatasource.descriptor.organizzation": "Organisation",
  "datasource.jrsdatasource.descriptor.datasourceurl": "Chemin de la source de données",
 
  "datasource.mongoDB.descriptor.label": "MongoDB",
  "datasource.mongoDB.description": "Lire les données de MongoDB",
  "datasource.mongoDB.name": "Nom",
  "datasource.mongoDB.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.mongoDB.descriptor.username": "Utilisateur",
  "datasource.mongoDB.descriptor.password": "Mot de passe",
  "datasource.mongoDB.descriptor.mongouri": "URI Du Mongo",
 
  "datasource.spotfire.descriptor.label": "Spotfire Information Link",
  "datasource.spotfire.description": "Lire les données de Spotfire",
  "datasource.spotfire.name": "Nom",
  "datasource.spotfire.name.help": "Spécifiez un nom pour cet adaptateur de données",
  "datasource.spotfire.descriptor.url": "URL",
  "datasource.spotfire.descriptor.username": "Utilisateur",
  "datasource.spotfire.descriptor.password": "Mot de passe",
  "datasource.spotfire.descriptor.version": "Version",
  "datasource.spotfire.descriptor.resourceid": "Resource ID",
  "datasource.spotfire.descriptor.usequery": "Use a query (required to use parameters)",
 
  "datasource.error.completefields": "Remplissez tous les champs obligatoires",
  "datasource.dbconfiguration.location": "Adresse",
  "datasource.dbconfiguration.connectionProperties": "Propriétés", 
  "datasource.dbconfiguration.classpath": "Classpath",
  "datasource.dbconfiguration.property.name": "Propriété",
  "datasource.dbconfiguration.property.value": "Valeur",
 
  "validator.url.invalidPath":  "La valeur insérée n'est pas un chemin valide",
  "validator.url.invalidURL": "La valeur insérée n'est pas une URL valide",
  "validator.url.invalidURLorPath": "Veuillez définir une URL ou un chemin",
  "validator.url.invalidJRSURL": "L'URL doit commencer par http:// ou https://",
  "validator.url.invalidMongoDBURL": "L'URL doit commencer par mongodb://",
  "validator.name.invalidName": "Veuillez définir un nom",
  "validator.value.isEmpty": "La valeur ne peut pas être vide",
 }
 