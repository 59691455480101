
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import {List, Map} from 'immutable';
import { getPropertiesByType } from './DefaultValuesMap';

interface IResolverOptions {
    fallbackToDefault: boolean,
    overrideDefault?: any,
}

const defaultResolverOptions : IResolverOptions = {
    fallbackToDefault: true,
}

export const resolveAttribute = (model: Map<string, any>, element: Map<string, any>, attributeFullName: string, options = defaultResolverOptions) => {
    if (!element) {
        return undefined;
    }
    const hierarchy : Map<string, any>[] = [element];
    let elementsStyleName : string | undefined  = element.get('style');
    const reportStyles : List<Map<string, any>> = model.get('styles');
    while (elementsStyleName){
        const style = reportStyles.find((currentStyle) => {
            return currentStyle.get('name') === elementsStyleName;
        });
        if (style) {
            hierarchy.push(style);
            elementsStyleName = style.get('style');
        } else {
            elementsStyleName = undefined;
        }
    }
    const defaultStyle = reportStyles.find((currentStyle) => {
        return currentStyle.get('isDefault') === true;
    });
    if (defaultStyle){
        hierarchy.push(defaultStyle);
    }
    let attributeValue = undefined;
    let index = 0;
    const attributePath = attributeFullName.split('.');
    while (attributeValue === undefined && index < hierarchy.length){
        if (index === 0){
            attributeValue = hierarchy[index].getIn(attributePath);
        } else {
            //searching into a style, that has a plain structure
            const firstSegment = attributePath[0];
            const secondSegment = attributePath[1];
            if (firstSegment === 'font'){
                if (secondSegment === 'size'){
                    attributeValue = hierarchy[index].get('fontSize');
                } else if (secondSegment === 'isBold') {
                    attributeValue = hierarchy[index].get('isBold');
                } else if (secondSegment === 'isItalic') {
                    attributeValue = hierarchy[index].get('isItalic');
                } else if (secondSegment === 'isUnderline') {
                    attributeValue = hierarchy[index].get('isUnderline');
                } else if (secondSegment === 'isStrikeThrough') {
                    attributeValue = hierarchy[index].get('isStrikeThrough');
                }
            } else if (firstSegment === 'textAlignment'){
                attributeValue = hierarchy[index].get('hTextAlign');
                if (!attributeValue){
                    attributeValue = hierarchy[index].get('hAlign');
                }
            } else if (firstSegment === 'verticalAlignment'){
                attributeValue = hierarchy[index].get('vTextAlign');
                if (!attributeValue){
                    attributeValue = hierarchy[index].get('vAlign');
                }
            } else if (firstSegment === 'horizontalImageAlignment'){
                attributeValue = hierarchy[index].get('hImageAlign');
                if (!attributeValue){
                    attributeValue = hierarchy[index].get('hAlign');
                }
            } else if (firstSegment === 'verticalImageAlignment'){
                attributeValue = hierarchy[index].get('vImageAlign');
                if (!attributeValue){
                    attributeValue = hierarchy[index].get('vAlign');
                }
            } else {
                //seatch the same structure in the style
                attributeValue = hierarchy[index].getIn(attributePath);
                if (!attributeValue){
                    attributeValue = hierarchy[index].get(attributePath[attributePath.length - 1]);
                }            
            }
        }
        index++;
    }
    if (attributeValue === undefined && options.fallbackToDefault){
        if (options.overrideDefault !== undefined){
            return options.overrideDefault;
        }
        const type = element.get('type');
        if (type){
            const defaultMap = getPropertiesByType(type);
            attributeValue = defaultMap.get(attributeFullName);
        }
    }

    return attributeValue;
}