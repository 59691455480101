/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { APDescriptor } from "../../ui/APDescriptor";
import { LineStyleEnum } from "../enums/LineStyleEnum";
import { PColor } from "../../ui/primitive/UPColor";
import { PFloat } from "../../ui/primitive/UPFloat";

export const FLinePen = {
    lineColor: new PColor({ id: 'lineColor', label: 'Color' }),
    lineStyle: LineStyleEnum,
    lineWidth: new PFloat({ id: 'lineWidth', label: 'Width', min: 0 }),
};

export const MLinePen = new PAccordeon({
    id: 'pen', label: 'Pen', expanded: true, path: 'pen',
    layouts: [
        { ...FLinePen.lineColor, inheritedPropertyId: 'pen.lineColor' },
        { ...FLinePen.lineStyle, inheritedPropertyId: 'pen.lineStyle' },
        { ...FLinePen.lineWidth, inheritedPropertyId: 'pen.lineWidth' },
    ]
});
export const MLinePenStyle = new PAccordeon({
    id: 'pen.style', label: 'Pen', expanded: true, path: 'pen',
    layouts: [
        { ...FLinePen.lineColor, inheritedPropertyId: 'pen.lineColor' },
        { ...FLinePen.lineStyle, inheritedPropertyId: 'pen.lineStyle', default: undefined },
        { ...FLinePen.lineWidth, inheritedPropertyId: 'pen.lineWidth' },
    ]
});

export const getLinePen = (id: string, label: string, path: string, expanded: boolean, inheritedPath?: string) => {
    const layouts: APDescriptor[] = [];
    if (inheritedPath) {
        layouts.push({
            ...FLinePen.lineColor,
            inheritedPropertyId: `${inheritedPath}.${FLinePen.lineColor.id}`,
        });
        layouts.push({
            ...FLinePen.lineStyle,
            inheritedPropertyId: `${inheritedPath}.${FLinePen.lineStyle.id}`,
        });
        layouts.push({
            ...FLinePen.lineWidth,
            inheritedPropertyId: `${inheritedPath}.${FLinePen.lineWidth.id}`,
        });
    } else {
        layouts.push(FLinePen.lineColor);
        layouts.push(FLinePen.lineStyle);
        layouts.push(FLinePen.lineWidth);
    }
    return new PAccordeon({
        id: id, label: label, expanded: expanded, path: path,
        layouts: layouts,
    });

}
export const getLinePenStyle = (id: string, label: string, path: string, expanded: boolean, inheritedPath?: string) => {
    const layouts: APDescriptor[] = [];
    if (inheritedPath) {
        layouts.push({
            ...FLinePen.lineColor, default: undefined,
            inheritedPropertyId: `${inheritedPath}.${FLinePen.lineColor.id}`,
        });
        layouts.push({
            ...FLinePen.lineStyle, default: undefined,
            inheritedPropertyId: `${inheritedPath}.${FLinePen.lineStyle.id}`,
        });
        layouts.push({
            ...FLinePen.lineWidth, default: undefined,
            inheritedPropertyId: `${inheritedPath}.${FLinePen.lineWidth.id}`,
        });
    } else {
        layouts.push({ ...FLinePen.lineColor, default: undefined });
        layouts.push({ ...FLinePen.lineStyle, default: undefined });
        layouts.push({ ...FLinePen.lineWidth, default: undefined });
    }
    return new PAccordeon({
        id: id, label: label, expanded: expanded, path: path,
        layouts: layouts,
    });

}