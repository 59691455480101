/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map } from 'immutable';
import { ElementTypes } from '../../../../sagas/report/document/elementTypes';
import { addAttributeToMap, addBooleanAttributeToMap, addChildToParent, addChildValueToMap, addChildValueToMapWithName, addIntAttributeToMap, createDatasetRun, createHyperLinkForElement, generatePath, getChildNodeByName, getNamespace, IObjectCounter, readCDataText, addChildValueWithPrefixToMap } from './JrxmlHelpers';
import { createReportElement } from './JrxmlHelpers';

export const MAP_NAMESPACE = "MAP_XMLNS_NAMESPACE";

const isGenricMap = (componentElement : Element, tagname: string, namespace?: string) : boolean => {
    const childList = componentElement.childNodes;
    let found = false;
    for (let i = 0; i < childList.length && !found; i++) {
        const child = childList[i];
        const nodeName = child.nodeName;
        if (child.nodeType === Node.ELEMENT_NODE && nodeName !== "reportElement"){
            const baseNamespace = getNamespace(child as Element);
            if (baseNamespace.length > 0){
                const namespaceUrl = (child as Element).getAttribute(`xmlns:${baseNamespace}`);
                if (namespaceUrl && namespace){
                    //compare also the namespaces url
                    return found = (nodeName === `${baseNamespace}:${tagname}`) && (namespace === namespaceUrl);
                } else {
                    const currentNamespace = baseNamespace.length>0 ? baseNamespace + ":" : baseNamespace;
                    found = nodeName === currentNamespace + tagname;
                }
            } else {
                found = nodeName === tagname;
            }
        }
    }
    return found;
}

export const isMap = (componentElement : Element) : boolean => {
    return isGenricMap(componentElement, "map")
}

export const isTibcoMap = (componentElement : Element) : boolean => {
    return isGenricMap(componentElement, "tibcoMap")
}

export const isFusionMap = (componentElement : Element) : boolean => {
    return isGenricMap(componentElement, "map","http://jaspersoft.com/fusion");
}


export const createMapElement = (componentElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    const reportElementNode : Element = getChildNodeByName(componentElement, "reportElement") as Element;
    let map : Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.MAP, objectCounter);

    let newDocument = document;
    componentElement.childNodes.forEach(child => {
        if (child.nodeType === Node.ELEMENT_NODE && child.nodeName !== "reportElement"){
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : "";
            const mapElement = getChildNodeByName(componentElement, namespace + "map") as Element;
            if (mapElement !== null){
                map = map.set(MAP_NAMESPACE, baseNamespace);
                map = addAttributeToMap(mapElement, baseNamespace.length > 0 ? "xmlns:" + baseNamespace : "xmlns", map);
                map = addAttributeToMap(mapElement, "xsi:schemaLocation", map);
                map = addBooleanAttributeToMap(mapElement, "markerClustering", map);
                map = addBooleanAttributeToMap(mapElement, "markerSpidering", map);


                map = addChildValueToMap(mapElement, namespace + "latitudeExpression", map);
                map = addChildValueToMap(mapElement, namespace + "longitudeExpression", map);
                map = addChildValueToMap(mapElement, namespace + "addressExpression", map);
                map = addChildValueToMap(mapElement, namespace + "zoomExpression", map);
                map = addChildValueToMap(mapElement, namespace + "languageExpression", map);

                map = addIntAttributeToMap(mapElement, "mapScale", map);

                map = addAttributeToMap(mapElement, "evaluationTime", map);
                map = addAttributeToMap(mapElement, "evaluationGroup", map);
                map = addAttributeToMap(mapElement, "mapType", map);
                map = addAttributeToMap(mapElement, "imageType", map);
                map = addAttributeToMap(mapElement, "onErrorType", map);

                map = map.set("markerDatas", List<Map<string, any>>());
                map = map.set("pathStyles", List<Map<string, any>>());
                map = map.set("pathDatas", List<Map<string, any>>()); 
                map = map.set("legendItems", Map<string, Map<string, any>>());    
                map = map.set("resetMapItems", Map<string, Map<string, any>>()); 

                mapElement.childNodes.forEach(element => {
                    const elementName = element.nodeName;
                    if (elementName === namespace + "markerData" ){
                        map = map.updateIn(["markerDatas"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(createMarkerData(element as Element, namespace, objectCounter)));
                    } else if (elementName === namespace + "legendItem" ){
                        element.childNodes.forEach(itemProperty => {
                            if (itemProperty.nodeName === namespace + "itemProperty"){
                                const { name, value } = createItem(itemProperty as Element);
                                map = map.updateIn(["legendItems"], map => (map as Map<string, Map<string, any>> || Map<string, Map<string, any>>()).set(name, value));
                            }
                        });
                    }  else if (elementName === namespace + "resetMapItem" ){
                        element.childNodes.forEach(itemProperty => {
                            if (itemProperty.nodeName === namespace + "itemProperty"){
                                const { name, value } = createItem(itemProperty as Element);
                                map = map.updateIn(["resetMapItems"], map => (map as Map<string, Map<string, any>> || Map<string, Map<string, any>>()).set(name, value));
                            }
                        });
                    } else if (elementName === namespace + "pathStyle"){
                        map = map.updateIn(["pathStyles"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(createMarkerData(element as Element, namespace, objectCounter)));
                    } else if (elementName === namespace + "pathData"){
                        map = map.updateIn(["pathDatas"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(createMarkerData(element as Element, namespace, objectCounter)));
                    } else if (elementName === namespace + "markerDataset"){
                        map = map.set("markerDataset", createMarkerDataset(element as Element, namespace, objectCounter));
                    }
                });

                newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), newDocument, map.get("id"));
                newDocument = newDocument.setIn(["elements", map.get("id")], map);
            }
        }
    });
    return newDocument;
}

export const createFusionMapElement = (componentElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    const reportElementNode : Element = getChildNodeByName(componentElement, "reportElement") as Element;
    let map : Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.FUSION_MAP, objectCounter);

    let newDocument = document;
    componentElement.childNodes.forEach(child => {
        if (child.nodeType === Node.ELEMENT_NODE && child.nodeName !== "reportElement"){
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : "";

            const mapElement = getChildNodeByName(componentElement, namespace + "map") as Element;
            if (mapElement !== null){
                map = map.set(MAP_NAMESPACE, baseNamespace);
                map = addAttributeToMap(mapElement, baseNamespace.length > 0 ? "xmlns:" + baseNamespace : "xmlns", map);
                map = addAttributeToMap(mapElement, "xsi:schemaLocation", map);
                map = addAttributeToMap(mapElement, "entityNamesBundle", map);

                map = map.set("colorRanges", List<Map<string, any>>());
                map = map.set("mapProperties", Map<string, Map<string, any>>());
                
                const mapNameExpressionNode = getChildNodeByName(mapElement, namespace + 'mapNameExpression');
                if (mapNameExpressionNode !== null){
                    map = map.set('mapNameExpression', readCDataText(mapNameExpressionNode)); 
                }
           
                const mapDatasetNode = getChildNodeByName(mapElement, namespace + "mapDataset");
                if (mapDatasetNode !== null){
                    let mapDataset = Map<string, any>();
                    let entities : List<Map<string, string>> = List<Map<string, string>>();
                    mapDatasetNode.childNodes.forEach(element => {
                        const elementName = element.nodeName;
                        if (elementName === "dataset" ){
                            mapDataset = mapDataset.set("dataset", createDataset(element as Element, objectCounter));
                        } else if (elementName === namespace + "entity"){
                            entities = entities.push(createEntity(element as Element, namespace));
                        }
                    });
                    if (entities.size > 0){
                        mapDataset = mapDataset.set('entities', entities)
                    }
                    if (!mapDataset.isEmpty()){
                        map = map.set("mapDataset", mapDataset);
                    }
                }

                mapElement.childNodes.forEach(element => {
                    const elementName = element.nodeName;
                    if (elementName === namespace + "mapProperty" ){
                        const {name, value} = createMapPropertyExpression(element as Element, namespace);
                        map = map.updateIn(["mapProperties"], map => (map as Map<string, Map<string, any>> || Map<string, Map<string, any>>()).set(name, value));
                    } else if (elementName === namespace + "colorRange"){
                        map = map.updateIn(["colorRanges"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(createMapColorRange(element as Element, namespace)));
                    } else if (elementName === namespace + "shapeData"){
                        map = map.set('shapeData', createShapeData(element as Element, objectCounter))
                    } else {
                        let nodeNamespace = getNamespace(element as Element);
                        if (nodeNamespace.length > 0){
                            nodeNamespace = nodeNamespace + ":";
                        }
                        if (elementName === nodeNamespace + "markerData"){
                            const markerData = createMarkerData(element as Element, nodeNamespace, objectCounter);
                            if (markerData.size > 0) {
                                //avoid to add an empty marker data
                                map = map.set('markerData', markerData);
                            }
                        }
                    }
                });
            
                newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), newDocument, map.get("id"));
                newDocument = newDocument.setIn(["elements", map.get("id")], map);
            }
        }
    });
    return newDocument;
}


export const createTibcoMapElement = (componentElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    const reportElementNode : Element = getChildNodeByName(componentElement, "reportElement") as Element;
    let map : Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.TIBCO_MAP, objectCounter);

    let newDocument = document;
    componentElement.childNodes.forEach(child => {
        if (child.nodeType === Node.ELEMENT_NODE && child.nodeName !== "reportElement"){
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : "";

            const mapElement = getChildNodeByName(componentElement, namespace + "tibcoMap") as Element;
            if (mapElement !== null){
                map = map.set(MAP_NAMESPACE, baseNamespace);
                map = addAttributeToMap(mapElement, baseNamespace.length > 0 ? "xmlns:" + baseNamespace : "xmlns", map);
                map = addAttributeToMap(mapElement, "xsi:schemaLocation", map);
            
                map = addAttributeToMap(mapElement, "evaluationTime", map);
                map = addAttributeToMap(mapElement, "evaluationGroup", map);
                map = addAttributeToMap(mapElement, "onErrorType", map);
            
                map = map.set("markerDatas", List<Map<string, any>>());
                map = map.set("pathStyles", List<Map<string, any>>());
                map = map.set("pathDatas", List<Map<string, any>>());   
                
                const mapDataNode = getChildNodeByName(mapElement, namespace + "mapData");
                if (mapDataNode !== null){
                    let mapProperties : Map<string, Map<string, string>> = Map<string, Map<string, string>>();
                    mapDataNode.childNodes.forEach(element => {
                        const elementName = element.nodeName;
                        if (elementName === "dataset" ){
                            map = map.set("dataset", createDataset(element as Element, objectCounter));
                        } else if (elementName === namespace + "item"){
                            // expore the citem for the itemproperty
                            element.childNodes.forEach(itemProperty => {
                            if (itemProperty.nodeName === namespace + "itemProperty"){
                                const {name, value} = createItem(itemProperty as Element);
                                mapProperties = mapProperties.set(name, value);
                            }
                        });
                        }
                    });
                    if (!mapProperties.isEmpty()){
                        map = map.set("mapData", mapProperties);
                    }
                }
            
                mapElement.childNodes.forEach(element => {
                    const elementName = element.nodeName;
                    if (elementName === namespace + "markerData" ){
                        map = map.updateIn(["markerDatas"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(createMarkerData(element as Element, namespace, objectCounter)));
                    } else if (elementName === namespace + "pathStyle"){
                        map = map.updateIn(["pathStyles"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(createMarkerData(element as Element, namespace, objectCounter)));
                    } else if (elementName === namespace + "pathData"){
                        map = map.updateIn(["pathDatas"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(createMarkerData(element as Element, namespace, objectCounter)));
                    } else if (elementName === namespace + "markerDataset"){
                        map = map.set("markerDataset", createMarkerDataset(element as Element, namespace, objectCounter));
                    }
                });
            
                newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), newDocument, map.get("id"));
                newDocument = newDocument.setIn(["elements", map.get("id")], map);
            }
        }
    });
    return newDocument;
}

const createMarkerDataset = (markerDatasetElement : Element, namespace: string, objectCounter: IObjectCounter) : Map<string, any> => {
    let result = Map<string, any>();

    // check for the dataset run
    const datasetRunNode = getChildNodeByName(markerDatasetElement, "datasetRun");
    if (datasetRunNode !== null){
    result = result.set("datasetRun", createDatasetRun(datasetRunNode as Element, objectCounter));
    }

    markerDatasetElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === namespace + "marker" ){
            const newMarker = createMarker(element as Element, namespace);
            result = result.updateIn(["markers"], (list : List<List<Map<string, any>>>) => (list || List<List<Map<string, any>>>()).push(newMarker));
        }
    });
    return result;
}

const createMarker = (markerElement: Element, namespace: string) : List<Map<string, any>> => {
    let result = List<Map<string, any>>();
    markerElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === namespace + "markerProperty" ){
           const {name, value} = createItem(markerElement);
           const mapWithName = value.set("name", name);
           result = result.push(mapWithName);
        }
    });
    return result;
}

const createMarkerData = (markerDataElement: Element, namespace: string, objectCounter: IObjectCounter) : Map<string, any> => {
    let result = Map<string, any>();
    result = addChildValueWithPrefixToMap(markerDataElement, "seriesNameExpression", namespace, result);
    result = addChildValueWithPrefixToMap(markerDataElement, "markerClusteringExpression", namespace, result);
    result = addChildValueWithPrefixToMap(markerDataElement, "markerSpideringExpression", namespace, result);
    result = addChildValueWithPrefixToMap(markerDataElement, "legendIconExpression", namespace, result);

    markerDataElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "dataset" ){
            result = result.set("dataset", createDataset(element as Element, objectCounter));
        } else if (elementName === namespace + "item"){
            // expore the citem for the itemproperty
            let itemProperties = Map<string, Map<string, any>>();
            element.childNodes.forEach(itemProperty => {
                if (itemProperty.nodeName === namespace + "itemProperty"){
                    const {name, value} = createItem(itemProperty as Element);
                    itemProperties = itemProperties.set(name, value);
                }
            });
            if (!itemProperties.isEmpty()){
                result = result.updateIn(["items"], (items : List<Map<string, Map<string, any>>>) => (items || List<Map<string, Map<string, any>>>()).push(itemProperties));
            }
        }
    });
    return result;
}

const createItem = (itemElement: Element) : {name: string, value: Map<string, any>} => {
    let result = Map<string, any>();
    const name = itemElement.getAttribute("name");
    result = addChildValueToMap(itemElement, "valueExpression", result);
    result = addAttributeToMap(itemElement, "value", result);
    return {name, value: result};
}

const createEntity = (itemElement: Element, namespace: string) : Map<string, any> => {
    let result = Map<string, any>();
    let entityProperties = List<Map<string, any>>();
    itemElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === namespace + "entityProperty" ){
            let entityPropertyMap = addAttributeToMap(element as Element, 'name', Map<string, any>());
            entityPropertyMap = addChildValueToMapWithName(element as Element, namespace + 'propertyExpression', "propertyExpression", entityPropertyMap);
            entityProperties = entityProperties.push(entityPropertyMap);
        }
    });
    result = result.set('entityProperties', entityProperties);

    result = addChildValueToMapWithName(itemElement, namespace + "idExpression", "idExpression", result);
    result = addChildValueToMapWithName(itemElement, namespace + "valueExpression", "valueExpression", result);
    result = addChildValueToMapWithName(itemElement, namespace + "labelExpression", "labelExpression", result);
    result = addChildValueToMapWithName(itemElement, namespace + "colorExpressio", "colorExpressio", result);

    const hyperlink = getChildNodeByName(itemElement, namespace + "hyperlink");
    if (hyperlink !== null) {
        result = createHyperLinkForElement(hyperlink as Element, result);
    }
    return result;
}

const createMapPropertyExpression = (itemElement: Element, namespace: string) : {name: string, value: Map<string, any>} => {
    let result = Map<string, any>();
    const attName = itemElement.getAttribute("name");
    const attValueNode = getChildNodeByName(itemElement, namespace + "propertyExpression");
    if (attValueNode !== null){
        const attValue = readCDataText(attValueNode);
        result = result.set('propertyExpression', attValue);
    }
    return {name: attName, value: result};
}

const createMapColorRange = (itemElement: Element, namespace: string) : Map<string, any> => {
    let result = Map<string, any>();
    const color = itemElement.getAttribute("color");
    result = result.set('color', color);
    const labelExpressionNode = getChildNodeByName(itemElement, namespace + "labelExpression");
    if (labelExpressionNode !== null){
        const labelExpression = readCDataText(labelExpressionNode);
        result = result.set('labelExpression', labelExpression);
    }

    const minValueExpressionNode = getChildNodeByName(itemElement, namespace + "minValueExpression");
    if (minValueExpressionNode !== null){
        const minValueExpression = readCDataText(minValueExpressionNode);
        result = result.set('minValueExpression', minValueExpression);
    }

    const maxValueExpressionNode = getChildNodeByName(itemElement, namespace + "maxValueExpression");
    if (maxValueExpressionNode !== null){
        const maxValueExpression = readCDataText(maxValueExpressionNode);
        result = result.set('maxValueExpression', maxValueExpression);
    }
    return result;
}

const createShapeData = (itemElement: Element, objectCounter: IObjectCounter) : Map<string, any> => {
    let result = Map<string, any>();
    result = result.set('items', List<Map<string, any>>());
    itemElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        let elementBaseNamespace = getNamespace(element as Element);
        if (elementBaseNamespace.length > 0) {
            elementBaseNamespace = elementBaseNamespace + ":";
        }
        if (elementName === "dataset" ){
            result = result.set('dataset', createDataset(element as Element, objectCounter));
        } else if (elementName === elementBaseNamespace + "item"){
            let itemProperties = Map<string, Map<string, any>>();
            const itemBaseNamespace = getNamespace(element as Element);
            const itemNamespace = itemBaseNamespace && itemBaseNamespace.length > 0 ? itemBaseNamespace + ":" : "";
            element.childNodes.forEach(itemPropertyNode => {
                const nodeName = itemPropertyNode.nodeName;
                if (itemPropertyNode.nodeType === Node.ELEMENT_NODE && nodeName !==  itemNamespace + "reportElement"){
                    const {name, value} = createItem(itemPropertyNode as Element);
                    itemProperties = itemProperties.set(name, value);
                }
            });
            if (itemProperties.size > 0){
                result = result.updateIn(["items"], list => (list as List<Map<string, Map<string, any>>> || List<Map<string, Map<string, any>>>()).push(itemProperties));
            }
        }
    });
    return result;
}

const createDataset = (itemDataElement: Element, objectCounter: IObjectCounter) : Map<string, any> => {
    let result = Map<string, any>();

    result = addAttributeToMap(itemDataElement, "resetType", result);
    result = addAttributeToMap(itemDataElement, "resetGroup", result);
    result = addAttributeToMap(itemDataElement, "incrementType", result);
    result = addAttributeToMap(itemDataElement, "incrementGroup", result);

    result = addChildValueToMap(itemDataElement, "incrementWhenExpression", result);

    // check for the dataset run
    const datasetRunNode = getChildNodeByName(itemDataElement, "datasetRun");
    if (datasetRunNode !== null){
        result = result.set("datasetRun", createDatasetRun(datasetRunNode as Element, objectCounter));
    }

    return result;
}