import React from 'react';


export const SplitLabel: React.FunctionComponent = (props) => {
    const { children, ...other } = props;
    const filteredChildren = React.Children.toArray(children);

    if (filteredChildren.length === 1 &&
        typeof filteredChildren[0] === 'string' &&
        filteredChildren[0].length > 0)
    {
        const stringChild = filteredChildren[0];
        const left = Math.floor(0.7 * stringChild.length);
        return (
            <span {...other} style={{ display: "flex", minWidth: 0 }}>
                <span style={{ flex: "0 1 auto", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "pre"  }} >
                {stringChild.substring(0, left)}
                </span>
                <span style={{ flex: "1 0 auto", textAlign: "left", overflow: "hidden", whiteSpace: "pre" }} >
                {stringChild.substring(left)}
                </span>
            </span>
        );
    }

    return (
        <span {...other}>
            {children}
        </span>
    );

}
