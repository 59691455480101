/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const BreakIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/palette/break" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M4.5,10 L11.5,10 L11.5,10 L11.5,14.5 L16,14.5 L16,17 L4,17 L4,10.5 C4,10.2238576 4.22385763,10 4.5,10 Z" id="page-bottom" fill="#95C9F9"></path>
                <path d="M17.5,7.5 C17.7761424,7.5 18,7.72385763 18,8 C18,8.27614237 17.7761424,8.5 17.5,8.5 L16.5,8.5 C16.2238576,8.5 16,8.27614237 16,8 C16,7.72385763 16.2238576,7.5 16.5,7.5 L17.5,7.5 Z M14,7.5 C14.2761424,7.5 14.5,7.72385763 14.5,8 C14.5,8.27614237 14.2761424,8.5 14,8.5 L13,8.5 C12.7238576,8.5 12.5,8.27614237 12.5,8 C12.5,7.72385763 12.7238576,7.5 13,7.5 L14,7.5 Z M10.5,7.5 C10.7761424,7.5 11,7.72385763 11,8 C11,8.27614237 10.7761424,8.5 10.5,8.5 L9.5,8.5 C9.22385763,8.5 9,8.27614237 9,8 C9,7.72385763 9.22385763,7.5 9.5,7.5 L10.5,7.5 Z M7,7.5 C7.27614237,7.5 7.5,7.72385763 7.5,8 C7.5,8.27614237 7.27614237,8.5 7,8.5 L6,8.5 C5.72385763,8.5 5.5,8.27614237 5.5,8 C5.5,7.72385763 5.72385763,7.5 6,7.5 L7,7.5 Z M3.5,7.5 C3.77614237,7.5 4,7.72385763 4,8 C4,8.27614237 3.77614237,8.5 3.5,8.5 L2.5,8.5 C2.22385763,8.5 2,8.27614237 2,8 C2,7.72385763 2.22385763,7.5 2.5,7.5 L3.5,7.5 Z" id="Combined-Shape" fill="#0081CB"></path>
                <polygon id="fold" fill="#0081CB" points="11.5 10 16 14.5 11.5 14.5"></polygon>
                <path d="M4,2 L16,2 L16,5.5 C16,5.77614237 15.7761424,6 15.5,6 L4.5,6 C4.22385763,6 4,5.77614237 4,5.5 L4,2 L4,2 Z" id="page-top" fill="#95C9F9"></path>
            </g>
        </svg>
    );
}
export default BreakIcon;