/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { EditableTable, Select, TextInput, WritableCombo } from '@jss/js-common';
import { notEmptyClassNameValidator, ValidationResult, VALIDATION_RESULT } from '@jss/js-common/src/utils/validators';
import { ResourcePickerDialog, RunContext } from '@jss/js-repository';
import { getRealName, IRepositoryItemDescriptor, MIME_TYPES, RepositoryApi } from '@jss/js-rest-api';
import { Tab, Tabs } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { TabPanel } from '@material-ui/lab';
import TabContext from '@material-ui/lab/TabContext';
import * as React from 'react';
import '../assets/css/DataSourceEditor.css';
import i18n from '../i18n';

interface IXmlDataAdapterEditor {
    driver?: string,
    selectedDriverId?: string,
    url?: string | null,
    username?: string,
    password?: string,
    autoCommit?: boolean,
    readOnly?: boolean,
    transactionIsolation?: string,
    classpaths?: string[],
    propertiesNames?: string[],
    propertiesValues?: string[],
    readOnlyWidgets?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

interface IState {
    selectedTab: string,
}

interface IDriverDefinition {
    id: string,
    label: string,
    driver: string,
    defaultUrl: string
}

export const jdbcUrlValidator = (value: string | undefined) => {
    if (!value || value.trim().length === 0) {
        return i18n.t('datasource.jdbc.urlError');
    }
    if (!value.startsWith('jdbc:')) {
        return i18n.t('datasource.jdbc.urlError');
    }
    return undefined;
}

export const advancedJdbcUrlValidator = (value: string | undefined): ValidationResult => {
    if (!value || value.trim().length === 0) {
        return { result: VALIDATION_RESULT.VALID, message: i18n.t('datasource.jdbc.urlError') };
    }
    if (!value.startsWith('jdbc:')) {
        return { result: VALIDATION_RESULT.VALID, message: i18n.t('datasource.jdbc.urlError') };
    }
    return { result: VALIDATION_RESULT.VALID };
}

export const jdbcDrivers: IDriverDefinition[] = [
    { id: 'org.hsqldb.jdbcDriverServer', driver: 'org.hsqldb.jdbcDriver', label: 'HSQLDB (server)', defaultUrl: 'jdbc:hsqldb:hsql://localhost' },
    { id: 'org.hsqldb.jdbcDriverFile', driver: 'org.hsqldb.jdbcDriver', label: 'HSQLDB (file)', defaultUrl: 'jdbc:hsqldb:[PATH_TO_DB_FILES]/database' },
    { id: 'org.h2.DriverFile', driver: 'org.h2.Driver', label: 'H2 (file)', defaultUrl: 'jdbc:h2:file:database' },
    { id: 'org.h2.DriverTCO', driver: 'org.h2.Driver', label: 'H2 (tco)', defaultUrl: 'jdbc:h2:tcp://localhost:9101/~/database' },
    { id: 'org.sqlite.JDBC', driver: 'org.sqlite.JDBC', label: 'SQLite (file)', defaultUrl: 'jdbc:sqlite:[PATH_TO_DB_FILES]/database' },
    { id: 'org.apache.derby.jdbc.EmbeddedDriver', driver: 'org.apache.derby.jdbc.EmbeddedDriver', label: 'DerbyDB (Embedded)', defaultUrl: 'jdbc:derby:/database' },
    { id: 'org.apache.derby.jdbc.ClientDriver', driver: 'org.apache.derby.jdbc.ClientDriver', label: 'DerbyDB (Client)', defaultUrl: 'jdbc:derby://localhost[:1527]/database' },
    { id: 'COM.cloudscape.JDBCDriver', driver: 'COM.cloudscape.JDBCDriver', label: 'Cloudscape', defaultUrl: 'jdbc:cloudscape:/database' },
    { id: 'org.firebirdsql.jdbc.FBDriver', driver: 'org.firebirdsql.jdbc.FBDriver', label: 'Firebird', defaultUrl: 'jdbc:firebirdsql://localhost:3050/database' },
    { id: 'com.ibm.db2.jcc.DB2DriverType4', driver: 'com.ibm.db2.jcc.DB2Driver', label: 'IBM DB2 (Type 4)', defaultUrl: 'jdbc:db2://localhost/database' },
    { id: 'com.ibm.db2.jcc.DB2DriverCloudscape', driver: 'com.ibm.db2.jcc.DB2Driver', label: 'IBM DB2 (IBM Cloudscape® server)', defaultUrl: 'jdbc:db2j:net://localhost/database' },
    { id: 'com.ibm.db2.jcc.DB2DriverIDS', driver: 'com.ibm.db2.jcc.DB2Driver', label: 'IBM DB2 (IDS data source)', defaultUrl: 'jdbc:db2j:net://localhost/database' },

    { id: 'com.inet.tds.TdsDriver', driver: 'com.inet.tds.TdsDriver', label: 'inetdae7', defaultUrl: 'jdbc:inetdae7:localhost:1433/database' },
    { id: 'com.informix.jdbc.IfxDriver', driver: 'com.informix.jdbc.IfxDriver', label: 'Informix', defaultUrl: 'jdbc:informix-sqli://localhost:informixserver=database' },
    { id: 'com.ingres.jdbc.IngresDriver', driver: 'com.ingres.jdbc.IngresDriver', label: 'Ingres', defaultUrl: 'jdbc:ingres://localhost:II7/database' },
    { id: 'sun.jdbc.odbc.JdbcOdbcDriver', driver: 'sun.jdbc.odbc.JdbcOdbcDriver', label: 'JDBC-ODBC Bridge', defaultUrl: 'jdbc:odbc:database' },
    { id: 'com.ms.jdbc.odbc.JdbcOdbcDriver', driver: 'com.ms.jdbc.odbc.JdbcOdbcDriver', label: 'JDBC-ODBC Bridge', defaultUrl: 'jdbc:odbc:database' },

    { id: 'com.microsoft.jdbc.sqlserver.SQLServerDriver', driver: 'com.microsoft.jdbc.sqlserver.SQLServerDriver', label: 'MS SQLServer (2000)', defaultUrl: 'jdbc:microsoft:sqlserver://localhost:1433;DatabaseName=database' },
    { id: 'com.microsoft.sqlserver.jdbc.SQLServerDriver', driver: 'com.microsoft.sqlserver.jdbc.SQLServerDriver', label: 'MS SQLServer (2005-2012)', defaultUrl: 'jdbc:sqlserver://localhost:1433;databaseName=database' },
    { id: 'net.sourceforge.jtds.jdbc.Driver', driver: 'net.sourceforge.jtds.jdbc.Driver', label: 'MS SQLServer', defaultUrl: 'jdbc:jtds:sqlserver://localhost/database' },
    { id: 'com.merant.datadirect.jdbc.sqlserver.SQLServerDriver', driver: 'com.merant.datadirect.jdbc.sqlserver.SQLServerDriver', label: 'MS SQLServer', defaultUrl: 'jdbc:sqlserver://localhost:1433/database' },

    { id: 'com.internetcds.jdbc.tds.Driver', driver: 'com.internetcds.jdbc.tds.Driver', label: 'MS SQLServer', defaultUrl: 'jdbc:freetds:sqlserver://localhost/database' },
    { id: 'org.gjt.mm.mysql.Driver', driver: 'org.gjt.mm.mysql.Driver', label: 'MySQL', defaultUrl: 'jdbc:mysql://localhost/database' },
    { id: 'com.mysql.jdbc.Driver', driver: 'com.mysql.jdbc.Driver', label: 'MySQL', defaultUrl: 'jdbc:mysql://localhost/database' },
    { id: 'org.mariadb.jdbc.Driver', driver: 'org.mariadb.jdbc.Driver', label: 'MariaDB', defaultUrl: 'jdbc:mariadb://localhost:3306/database' },
    { id: 'oracle.jdbc.driver.OracleDriver', driver: 'oracle.jdbc.driver.OracleDriver', label: 'Oracle', defaultUrl: 'jdbc:oracle:thin:@localhost:1521:database' },

    { id: 'org.postgresql.Driver', driver: 'org.postgresql.Driver', label: 'PostgreSQL', defaultUrl: 'jdbc:postgresql://localhost:5432/database' },
    { id: 'com.sybase.jdbc4.jdbc.SybDriver', driver: 'com.sybase.jdbc4.jdbc.SybDriver', label: 'Sybase', defaultUrl: 'jdbc:sybase:Tds:localhost:2638/database' },
    { id: 'org.apache.hadoop.hive.jdbc.HiveDriver', driver: 'org.apache.hadoop.hive.jdbc.HiveDriver', label: 'Hadoop Hive', defaultUrl: 'jdbc:hive://localhost:10000/default' },

    { id: 'com.vertica.Driver', driver: 'com.vertica.Driver', label: 'Vertica', defaultUrl: 'jdbc:vertica://localhost:5433/database' },
    { id: 'com.vertica.jdbc.Driver', driver: 'com.vertica.jdbc.Driver', label: 'Vertica', defaultUrl: 'jdbc:vertica://localhost:5433/database' },
    { id: 'mondrian.olap4j.MondrianOlap4jDriver', driver: 'mondrian.olap4j.MondrianOlap4jDriver', label: 'Mondrian', defaultUrl: 'jdbc:mondrian:' },
    { id: 'org.olap4j.driver.xmla.XmlaOlap4jDriver', driver: 'org.olap4j.driver.xmla.XmlaOlap4jDriver', label: 'OLAP4J', defaultUrl: 'jdbc:xmla:' },

];

export const driversList = jdbcDrivers.map((dd: IDriverDefinition) => ({ key: dd.id, value: `${dd.label} (${dd.driver})` })).sort((driver1, driver2) => {
    return driver1.value.localeCompare(driver2.value);
});

const booleanCombo = [
    {
        key: '',
        value: ' ',
    },
    {
        key: 'true',
        value: 'True',
    },
    {
        key: 'false',
        value: 'False',
    }
];

const transactionIsolationCombo = [
    {
        key: '',
        value: ' ',
    },
    {
        key: 'NONE',
        value: 'None',
    },
    {
        key: 'READ_UNCOMMITTED',
        value: 'Read Uncommitted',
    },
    {
        key: 'READ_COMMITTED',
        value: 'Read Committed',
    },
    {
        key: 'REPEATABLE_READ',
        value: 'Repetable Read',
    },
    {
        key: 'SERIALIZABLE',
        value: 'Serializable',
    },
];

export class DBConfigurationPanel extends React.Component<IXmlDataAdapterEditor, IState> {

    state = {
        selectedTab: 'databaseLocation',
    }

    private onJDBCUrlChange = (str: string) => {
        this.notifyChange({ url: str });
    }

    private onUsernameChange = (str: string) => {
        this.notifyChange({ username: str });
    }

    private onPasswordChange = (str: string) => {
        this.notifyChange({ password: str });
    }

    private onDriverChange = (key: string | undefined, str: string) => {
        if (key) {
            // Find the selected driver...
            const driverDefinition = jdbcDrivers.find((d: any) => d !== null && d.id === key);

            if (!driverDefinition) {
                return;
            }
            this.notifyChange({ driver: driverDefinition.driver, selectedDriverId: driverDefinition.id, url: driverDefinition.defaultUrl });
        } else {
            this.notifyChange({ driver: str, selectedDriverId: undefined });
        }
    }

    getPropertiesRows = () => {
        const result: string[][] = [];
        const rows = this.props.propertiesNames ? [...this.props.propertiesNames] : [];
        const values = this.props.propertiesValues ? [...this.props.propertiesValues] : [];
        rows.forEach((columnName: string, index: number) => {
            result.push([columnName, values[index]]);
        });
        return result;
    }

    isNumber = (value: string) => {
        return (!Number.isNaN(parseInt(value, 10)));
    }

    onPropertiesCellChange = (row: number, col: number, value: string, isEditing: boolean) => {
        const rowNames = this.props.propertiesNames ? [...this.props.propertiesNames] : [];
        const rowIndexes = this.props.propertiesValues ? [...this.props.propertiesValues] : [];
        if (col === 0 && value.trim().length === 0 && !isEditing) {
            //has erased a row, delete the row
            rowNames.splice(row, 1);
            rowIndexes.splice(row, 1);
        } else if (!isEditing) {
            if (col === 0) {
                rowNames[row] = value;
            } else if (col === 1) {
                rowIndexes[row] = value;
            }
        }
        if (!isEditing) {
            this.notifyChange({ propertiesNames: rowNames, propertiesValues: rowIndexes });
        }
    }

    onPropertiesRowDelete = (rowIndex: number) => {
        const newColumnsNames = [...this.props.propertiesNames];
        newColumnsNames.splice(rowIndex, 1);
        const newColumnsIndexes = [...this.props.propertiesValues];
        newColumnsIndexes.splice(rowIndex, 1);
        this.notifyChange({ propertiesNames: newColumnsNames, propertiesValues: newColumnsIndexes });
    }

    getPropertiesColumnSize = () => {
        return [400 * 2 / 3, 400 * 1 / 3];
    }

    private onPropertiesAddAction = () => {
        let index = 1;
        let found = false;
        const names = this.props.propertiesNames ? [...this.props.propertiesNames] : [];
        const values = this.props.propertiesValues ? [...this.props.propertiesValues] : [];
        let currentName = 'property';
        while (!found) {
            const elementIndex = names.findIndex((value: string) => {
                return value.trim() === currentName;
            });
            if (elementIndex === -1) {
                found = true;
            } else {
                currentName = 'property' + index;
                index++;
            }
        }
        names.push(currentName);
        values.push('value');
        this.notifyChange({ propertiesNames: names, propertiesValues: values });
    }

    private getClasspathColumnNames = () => {
        if (this.props.classpaths) {
            if (Array.isArray(this.props.classpaths)) {
                return this.props.classpaths;
            } else {
                return [this.props.classpaths];
            }
        }
        return [];
    }

    private getClasspathsRows = () => {
        const result: string[][] = [];
        const rows = [...this.getClasspathColumnNames()];
        rows.forEach((columnName: string) => {
            result.push([columnName]);
        });
        return result;
    }

    private onClasspathCellChange = (row: number, col: number, value: string, isEditing: boolean) => {
        const rows = this.props.classpaths ? [...this.props.classpaths] : [];
        if (value.trim().length === 0 && !isEditing) {
            rows.splice(row, 1);
        } else {
            rows[row] = value;
        }
        if (!isEditing) {
            this.notifyChange({ classpaths: rows });
        }
    }

    private onClasspathRowDelete = (rowIndex: number) => {
        const newColumns = [...this.props.classpaths];
        newColumns.splice(rowIndex, 1);
        this.notifyChange({ classpaths: newColumns });
    }

    public openBrowserDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = [];
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('datasource.common.dialog.resource'),
                mode: 'open',
                title: i18n.t('datasource.common.dialog.title'),
                onFileSelected: this.confirmFile,
                defaultPath: defaultPath,
                allowSearch: true,
                allowedMimes: [MIME_TYPES.JAR, MIME_TYPES.ZIP, 'jar', 'zip'],
            }
        );
    }

    private confirmFile = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => {
        const path = folderPath.length > 0 ? `${RepositoryApi.inst().getParentPath(folderPath)}/${getRealName(file)}` : `/${getRealName(file)}`;
        const newFiles = [...this.props.classpaths, path];
        this.notifyChange({ classpaths: newFiles });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }

    private onAutoCommitChangeChange = (key: string) => {
        if (key !== '') {
            this.notifyChange({ autoCommit: key });
        } else {
            this.notifyChange({ autoCommit: undefined });
        }
    }

    private onReadOnlyChangeChange = (key: string) => {
        if (key !== '') {
            this.notifyChange({ readOnly: key });
        } else {
            this.notifyChange({ readOnly: undefined });
        }
    }

    private onTransactionIsolationChange = (key: string) => {
        if (key !== '') {
            this.notifyChange({ transactionIsolation: key });
        } else {
            this.notifyChange({ transactionIsolation: undefined });
        }
    }

    getConnectionProperties = () => {
        const tableRows = this.getPropertiesRows();
        return <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
            <Select items={booleanCombo}
                label={i18n.t('datasource.jdbc.autocommit')}
                id="auto-commit"
                className={'tc-jsw-dataadapter-editor-text-input'}
                value={this.props.autoCommit ? this.props.autoCommit : ''}
                onItemSelected={this.onAutoCommitChangeChange}
                disabled={this.props.readOnlyWidgets}
                InputProps={{
                    readOnly: this.props.readOnlyWidgets,
                }} />
            <Select items={booleanCombo}
                label={i18n.t('datasource.jdbc.readonly')}
                id="read-only"
                className={'tc-jsw-dataadapter-editor-text-input'}
                value={this.props.readOnly ? this.props.readOnly : ''}
                onItemSelected={this.onReadOnlyChangeChange}
                disabled={this.props.readOnlyWidgets}
                InputProps={{
                    readOnly: this.props.readOnlyWidgets,
                }} />
            <Select items={transactionIsolationCombo}
                label={i18n.t('datasource.jdbc.transactionisolation')}
                id="transaction-isolation"
                className={'tc-jsw-dataadapter-editor-text-input'}
                value={this.props.transactionIsolation ? this.props.transactionIsolation : ''}
                onItemSelected={this.onTransactionIsolationChange}
                disabled={this.props.readOnlyWidgets}
                InputProps={{
                    readOnly: this.props.readOnlyWidgets,
                }} />
            <div style={{ display: 'flex', alignItems: 'stretch', marginTop: '20px', marginBottom: '20px' }} id="tableContainer">
                <EditableTable cellStyle={{ textAlign: 'left' }} columnsWidths={this.getPropertiesColumnSize()} onCellChange={this.onPropertiesCellChange} cellData={tableRows} numRows={this.props.propertiesNames ? this.props.propertiesNames.length + 1 : 1} columnNames={[i18n.t('datasource.dbconfiguration.property.name'), i18n.t('datasource.dbconfiguration.property.value'), '']}
                    columnActions={[{ onClick: this.onPropertiesRowDelete, icon: <DeleteIcon /> }]} onAddAction={this.onPropertiesAddAction} readOnly={this.props.readOnlyWidgets} />
            </div>
        </div>
    }

    getClasspathProperties = () => {
        const tableRows = this.getClasspathsRows();
        return <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
            <div style={{ display: 'flex', alignItems: 'stretch' }} id="tableContainer">
                <EditableTable cellStyle={{ textAlign: 'left' }} onCellChange={this.onClasspathCellChange} cellData={tableRows} numRows={this.props.classpaths ? this.props.classpaths.length + 1 : 1} columnNames={[i18n.t('datasource.bean.classpaths'), '']}
                    columnActions={[{ onClick: this.onClasspathRowDelete, icon: <DeleteIcon /> }]} onAddAction={this.openBrowserDialog} readOnly={this.props.readOnlyWidgets} />
            </div>
        </div>;
    }

    getDatabaseLocation = () => {
        return <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
            <WritableCombo items={driversList}
                label={i18n.t('datasource.jdbc.driver')}
                id="jdbc-driver"
                className={'tc-jsw-dataadapter-editor-text-input'}
                value={this.props.selectedDriverId ? this.props.selectedDriverId : this.props.driver}
                validator={notEmptyClassNameValidator}
                onComboChange={this.onDriverChange}
                disabled={this.props.readOnlyWidgets} />
            <TextInput label={i18n.t('datasource.jdbc.url')}
                help={i18n.t('datasource.jdbc.urlHelp')}
                id="jdbc-url-input"
                className={'tc-jsw-dataadapter-editor-text-input'}
                value={this.props.url}
                onChange={this.onJDBCUrlChange}
                advancedValidator={advancedJdbcUrlValidator}
                InputProps={{
                    readOnly: this.props.readOnlyWidgets,
                }} />

            <TextInput label={i18n.t('datasource.jdbc.username')}
                id="username-input"
                className={'tc-jsw-dataadapter-editor-text-input'}
                value={this.props.username}
                autoComplete="off"
                onChange={this.onUsernameChange}
                InputProps={{
                    readOnly: this.props.readOnlyWidgets,
                }} />

            <TextInput label={i18n.t('datasource.jdbc.password')}
                id="password-input"
                className={'tc-jsw-dataadapter-editor-text-input'}
                type="password"
                autoComplete="off"
                value={this.props.password}
                onChange={this.onPasswordChange}
                InputProps={{
                    readOnly: this.props.readOnlyWidgets,
                }} />

        </div>;
    }

    getButtons = () => {
        const buttons = [
            <TabPanel key={'databaseLocation'} value="databaseLocation" className="tc-jsw-datasource-editor-categories-no-padding">
                {this.getDatabaseLocation()}
            </TabPanel>,
            <TabPanel key={'connectionProperties'} value="connectionProperties" className="tc-jsw-datasource-editor-categories-no-padding">
                {this.getConnectionProperties()}
            </TabPanel>,
            <TabPanel key={'classpath'} value="classpath" className="tc-jsw-datasource-editor-categories-no-padding">
                {this.getClasspathProperties()}
            </TabPanel>,
        ];
        return buttons;
    }

    public render() {

        return <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                <TabContext value={this.state.selectedTab}>
                    <div style={{ flex: 1 }}>
                        <Tabs
                            value={this.state.selectedTab}
                            onChange={(event, newValue) => { this.setState({ selectedTab: newValue }) }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="auto tabs example" >
                            <Tab key={'databaseLocation'} label={i18n.t('datasource.dbconfiguration.location')} value="databaseLocation" />
                            <Tab key={'connectionProperties'} label={i18n.t('datasource.dbconfiguration.connectionProperties')} value="connectionProperties" />
                            <Tab key={'classpath'} label={i18n.t('datasource.dbconfiguration.classpath')} value="classpath" />
                        </Tabs>
                    </div>
                    {this.getButtons()}
                </TabContext>
            </div>
        </div>;
    }
}

DBConfigurationPanel.contextType = RunContext;