/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import { TextField, TextFieldProps } from '../textinput/TextField';
import { Autocomplete } from './Autocomplete';
import FormControlLabel from '../_from_mui_src/FormControlLabel/FormControlLabel';

import '../../assets/uxpl/css/WritableCombo.css';
import { Box, InputAdornment, Popper } from '@material-ui/core';
import '../../assets/uxpl/css/FormControlLabel.css';
import { SizeToClass } from '../types/InputTypes';

export interface ISelectComboItem {
    key: string,
    value: string,
    textValue?: string,
    icon?: React.ReactElement,
    style?: React.CSSProperties,
}

interface ISelectCombo {
    items: ISelectComboItem[];
    validator?: (value: string | undefined) => string | undefined;
    onItemSelected: (selectedKey: string | undefined, value: string) => void;
    onTextFieldFocus?: (e) => void;
    onTextFieldBlur?: (e) => void;
    iconButtons?: React.ReactElement[];
}

interface ISelectComboState {
    isSuggestionOpened: boolean,
}

export class FormControlledSelect extends React.Component<TextFieldProps & ISelectCombo, ISelectComboState> {

    /*
    Little hack here, the currentFilter normally should be part of the state since it affect the rendering,
    but doing like this will trigger the rerender of the children, among them the input control. The input
    control when it is controlled in case like this lose the information on the cursor, placing it at the
    end of the control. To avoid this the filter is kept as variable so its change will not trigger a refresh.
    This in this particular case is not a problem since the filter is always updated before a props update or 
    an internal state update, so in any case the component will be refreshed but only once
    */
    private currentFilter: string | undefined = undefined;

    private textFieldRef: React.RefObject<HTMLDivElement> = React.createRef();

    state = {
        isSuggestionOpened: false,
    }

    getOptionLabel = (option: any) => {
        if (!option) return '';
        if (typeof option === 'object') {
            if (option.textValue !== undefined) return option.textValue;
            return option.value ? option.value : (option.key ? option.key : '')
        }
        return option;
    }

    fitToWidthPopper = (props) => {
        return <Popper {...props} style={{ width: 'fit-content', fontSize: '13px' }} placement='bottom-start' />;
    };

    public render() {
        let validationError: string | undefined;
        const {onItemSelected, validator, items, InputProps, InputLabelProps, iconButtons, inline, onTextFieldFocus, onTextFieldBlur, defaultValue, ...restProps } = this.props;
        if (validator) {
            validationError = validator(this.props.value as any);
        }
        const textValue = items.find((currentItem) => {
            return currentItem.key === this.props.value;
        });
        let value: string | undefined;
        if (textValue) {
            value = textValue.textValue !== undefined ? textValue.textValue : textValue.value;
        } else if (!value && this.props.value) {
            value = this.props.value as string;
        }
        let filteredItems = items;
        if (this.currentFilter) {
            const currentValue = (this.currentFilter as string).trim().toLowerCase();
            filteredItems = filteredItems.filter((item) => {
                return item.value.toLowerCase().includes(currentValue);
            })
        }
        const propsIcons = iconButtons ? iconButtons : [];
        const icons = [<InputAdornment key="dropIcon" position="end" className={"jr-mInputSearch-adornment mui"} style={{right: 1 + (35 * propsIcons.length)}}>
            <svg key="arrowIcon" className="jr-MuiSvgIcon-root jr-MuiSelect-icon jr-MuiSelect-iconOutlined" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
        </InputAdornment>, ...propsIcons];

        const control = <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}><Autocomplete
            freeSolo
            id={this.props.id}
            disableClearable
            options={filteredItems}
            open={this.state.isSuggestionOpened && items.length > 0}
            PopperComponent={this.fitToWidthPopper}
            autoHighlight
            className="jrws-writable-combo"
            style={{ flex: 1 }}
            classes={{ input: 'jrws-writable-combo-small-font' }}
            onChange={(event, newValue: { key: string, value: string }) => {
                this.currentFilter = undefined;
                this.setState({ isSuggestionOpened: false }, () => {
                    if (this.textFieldRef.current !== null) {
                        this.textFieldRef.current?.blur();
                    }
                    onItemSelected(newValue.key, newValue.value);
                });
            }}
            renderOption={(option: ISelectComboItem) => {
                if (option.icon) {
                    return <Box style={{ display: 'flex', flexDirection: 'row', ...option.style }}>
                        {option.icon}
                        {option.value}
                    </Box>
                } else {
                    return <Box style={{ display: 'flex', flexDirection: 'row', ...option.style }}>
                        {option.value}
                    </Box>
                }
            }}
            value={textValue ? textValue : { key: value, value: value }}
            getOptionLabel={this.getOptionLabel}
            renderInput={(params) => {
                return <TextField
                    {...params}
                    {...restProps}
                    className={`${restProps.className ? restProps.className : ''}`}
                    inputRef={this.textFieldRef}
                    error={validationError}
                    id="outlined01"
                    label={null}
                    size={'small'}
                    style={{ margin: 0 }}
                    // error="You can have error text here."
                    helperText={this.props.helperText}
                    InputLabelProps={{
                        ...InputLabelProps,
                        classes: { root: "jr-mInput-label mui" },
                        disableAnimation: true,
                        shrink: true
                    }}
                    inline={inline}
                    inputProps={{
                        ...params.inputProps,
                        style: {paddingRight: 20 * icons.length, caretColor: 'transparent'},
                        title: InputProps ? InputProps.title : undefined,
                        onKeyPress: (e) => {
                            e.preventDefault();
                        },
                        onFocus: (e) => {
                            this.currentFilter = undefined;
                            this.setState({ isSuggestionOpened: true });
                            if ((params.inputProps as any).onFocus) {
                                (params.inputProps as any).onFocus(e);
                            }
                            if (onTextFieldFocus){
                                onTextFieldFocus(e);
                            }
                        },
                        onBlur: (e) => {
                            this.currentFilter = undefined;
                            this.setState({ isSuggestionOpened: false });
                            if ((params.inputProps as any).onBlur) {
                                (params.inputProps as any).onBlur(e);
                            }
                            if (onTextFieldBlur){
                                onTextFieldBlur(e);
                            }
                        }
                    }}
                    value={value}
                    defaultValue={value === undefined ? defaultValue : undefined}
                />
            }}
            disabled={this.props.disabled}
        />
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', position: 'relative' }}>
                {icons}
            </div>
        </div>;

        const labelClasses = InputLabelProps && InputLabelProps.className ? InputLabelProps.className : ''; 

        return (
            <div className={`jr-formControlledTextFieldContainer ${SizeToClass['small']} jr-mInputText mui`}>
                <FormControlLabel
                    classes={{ label: `jr-formControlledTextFieldContainer jr-mInputs-group-label jr-mInput-label mui ${labelClasses}` }}
                    style={{ margin: 0 }}
                    control={control}
                    labelPlacement="start"
                    {...restProps}
                    label={restProps.label}
                />
            </div>
        )
    }
}

