/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { nameValidator, notEmptyValidator } from '@jss/js-common/src/utils/validators';
import { MIME_TYPES } from '@jss/js-rest-api';
import * as React from 'react';
import format from 'xml-formatter';
import { getDataAdapterNode } from '../../editor/Utils';
import { createNode, getProlog, IDataAdapterConfig, IDataAdapterDescriptor } from '../IDataAdapterDescriptor';
import { EJBQLBeanDataAdapterEditor } from './EJBQLBeanDataAdapterEditor';


export default class EJBQLBeanDataAdapterDescriptor implements IDataAdapterDescriptor {

  public getMime = () => {
    return MIME_TYPES.DATA_ADAPTER_EJBQL;
  }

  public getIcon = () => {
    return <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 50 67"
      fill="#fff"
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <use xlinkHref="#ejbqlIconA" x={1} y={1} />
      <symbol id="ejbqlIconA" overflow="visible">
        <g stroke="none" fillRule="nonzero">
          <path
            d="M15.256 49.478s-2.46 1.496 1.71 1.924c5 .64 7.694.534 13.25-.534a20.022 20.022 0 0 0 3.526 1.71c-12.5 5.344-28.32-.32-18.488-3.1zm-1.6-6.946s-2.672 2.03 1.496 2.46c5.45.534 9.724.64 17.1-.854a6.52 6.52 0 0 0 2.564 1.602c-15.068 4.488-31.952.428-21.16-3.206zm29.494 12.2s1.816 1.496-2.03 2.672c-7.16 2.14-30.028 2.8-36.44 0-2.244-.96 2.03-2.35 3.4-2.564 1.39-.32 2.14-.32 2.14-.32-2.46-1.71-16.35 3.526-7.052 5 25.54 4.168 46.592-1.816 39.966-4.808zM16.412 35.264s-11.648 2.8-4.168 3.74c3.206.428 9.51.32 15.388-.106 4.808-.428 9.6-1.28 9.6-1.28s-1.71.748-2.886 1.496c-11.86 3.1-34.624 1.71-28.104-1.496 5.556-2.672 10.152-2.35 10.152-2.35zm20.84 11.648c11.968-6.2 6.412-12.2 2.564-11.434-.96.214-1.39.428-1.39.428s.32-.64 1.068-.854c7.588-2.672 13.572 8.014-2.46 12.2 0 0 .106-.106.214-.32zm-19.64 16.886c11.54.748 29.174-.428 29.6-5.88 0 0-.854 2.14-9.51 3.74-9.832 1.816-22.014 1.6-29.174.428 0 0 1.496 1.28 9.084 1.71z"
            fill="#4e7896"
          />
          <path
            d="M30.004 0S36.63 6.732 23.7 16.884c-10.366 8.228-2.35 12.93 0 18.274-6.092-5.45-10.472-10.26-7.48-14.746C20.6 13.784 32.676 10.598 30.004 0zm-3.4 30.67c3.1 3.526-.854 6.732-.854 6.732s7.908-4.06 4.274-9.084c-3.312-4.808-5.88-7.16 8.014-15.174 0 0-21.906 5.45-11.434 17.526z"
            fill="#f58219"
          />
        </g>
      </symbol>
    </svg>
  }

  /**
   *  Return the name of this data adapter to be presented to the user
   *  i.e. CSV File
   */
  public getDataAdapterLabel = () => {
    return 'datasource.ejbql.descriptor.label';
  }

  /**
  *  Return the name of this data adapter to be presented to the user
  *  i.e. CSV File
  */
  public getDataAdapterDescription = () => {
    return 'datasource.ejbql.description';
  }

  /**
   *  Return the canonical name of the class which impements this data adapter in Java.
   *  net.sf.jasperreports.data.csv.CsvDataAdapter
   */
  public getDataAdapterClass = () => {
    return 'net.sf.jasperreports.data.ejbql.EjbqlDataAdapterImpl'
  }

  public getDataAdapterRootName = () => {
    return 'ejbqlDataAdapter';
  }

  /**
   * Return a plain json object with the default data adapter configuration.
   * It can be just an empty object.
   *
   * @memberof IDataAdapter
   */
  public initializeDataAdapterConfig = () => {
    return {
      class: this.getDataAdapterClass(),
      name: 'New EJBQL Data Adapter',
      persistanceUnitName: '',
    }
  };

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public validateDataAdapterConfig = (config: IDataAdapterConfig) => {

    // Check for errors...
    const errors = [];

    const nameError = nameValidator(config.name);
    if (nameError) {
      errors.push(nameError);
    }

    const unitNameError = notEmptyValidator(config.persistanceUnitName);
    if (unitNameError) {
      errors.push(unitNameError);
    }

    if (errors.length > 0) {
      return errors.join("\n");
    }

    return undefined;

  }

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public createEditor = (config: IDataAdapterConfig, onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => {
    return <EJBQLBeanDataAdapterEditor {...config} onDataAdapterSettingsChange={onDataAdapterSettingsChange} readOnly={readOnly}/>
  }

  public getLanguages = () => {
    return ['text'];
  }

  public toXml = (config: IDataAdapterConfig) => {
    const xmlDoc = document.implementation.createDocument(null, this.getDataAdapterRootName());
    const root = xmlDoc.getElementsByTagName(this.getDataAdapterRootName())[0];
    root.setAttribute('class', this.getDataAdapterClass());

    const nameNode = createNode(xmlDoc, config, 'name', 'name');
    if (nameNode) {
      root.appendChild(nameNode);
    }

    const persistanceUnitNameNode = createNode(xmlDoc, config, 'persistanceUnitName', 'persistanceUnitName');
    if (persistanceUnitNameNode) {
      root.appendChild(persistanceUnitNameNode);
    }

    const prolog = getProlog();
    const xml = prolog + (new XMLSerializer().serializeToString(xmlDoc));
    return format(xml, { indentation: '  ', collapseContent: true });
  }

  public toJson = (xml: Document) => {
    const result: any = {
      class: this.getDataAdapterClass(),
      name: '',
      persistanceUnitName: '',
    }

    getDataAdapterNode(xml.getRootNode()).childNodes.forEach((childNode) => {
      const nodeName = childNode.nodeName;
      if (nodeName === 'name') {
        result.name = childNode.textContent;
      } else if (nodeName === 'persistanceUnitName') {
        result.persistanceUnitName = childNode.textContent;
      }
    });
    return result;
  }
}