/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { IRepositoryItemDescriptor } from '@jss/js-rest-api';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ResourceDetail } from './ResourceDetail';
import { RunContainer } from '../../RunContainer';
import { ResourceSave } from './ResourceSave';
import { ResourceRefresh } from './ResourceRefresh';
import { DialogActions } from '@material-ui/core';
import { IResultDialogProp, Typography } from '@jss/js-common';
import i18n from '../../i18n';

export interface IResourceInfoProps extends IResultDialogProp {
    descriptor: IRepositoryItemDescriptor,
    onChanged: (oldDescriptor: IRepositoryItemDescriptor, newDescriptor: IRepositoryItemDescriptor) => void,
    onClose?: () => void
}

interface IState {
    newDescriptor: IRepositoryItemDescriptor;
    saveDisabled: boolean;
}

export class ResourceInfoDialog extends React.Component<IResourceInfoProps, IState> {
    public state: IState = { newDescriptor: this.props.descriptor, saveDisabled: true }
    constructor(props) {
        super(props);
        this.state = { newDescriptor: props.descriptor, saveDisabled: true };
    }

    render() {
        return <Dialog
            open={true}
            aria-labelledby="simple-dialog-title"
            fullWidth={true}
            maxWidth={'md'}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && this.props.onClose) {
                    this.props.onClose();
                }
            }}>
            <DialogTitle id="customized-dialog-title" style={{ paddingTop: '0px', paddingBottom: '0px', borderBottom: 'solid lightgray 1px' }} >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 10, paddingBottom: 10, justifyContent: 'space-between' }}>
                    <Typography style={{ marginLeft: '5px', fontWeight: '300', fontSize: '24px' }}>{i18n.t('common.actions.menu.resourceinfo')}</Typography>
                    <IconButton aria-label="close" onClick={this.onCancel} size='small'>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <RunContainer>
                <DialogContent>
                    <ResourceDetail descriptor={this.state.newDescriptor} canSave={this.canSave} />
                </DialogContent>
                <DialogActions style={{ borderTop: 'solid lightgray 1px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <ResourceRefresh descriptor={this.state.newDescriptor} onChange={this.onChange} refresh={true} />
                        <Button onClick={this.onCancel} >{i18n.t('repository.delete.dialog.cancel')}</Button>
                        <ResourceSave descriptor={this.state.newDescriptor} disabled={this.state.saveDisabled}
                            onDescriptorSaved={this.onSave} />
                    </div>
                </DialogActions>
            </RunContainer>
        </Dialog>
    }
    private onCancel = () => {
        this.props.onEnd({ canceled: true });
        this.props.onClose?.();
    }

    private onChange = (newDescriptor: IRepositoryItemDescriptor) => {
        this.props.onChanged(this.props.descriptor, newDescriptor);
        this.setState({ newDescriptor: newDescriptor, saveDisabled: true });

    }
    private onSave = (newDescriptor: IRepositoryItemDescriptor) => {
        this.props.onChanged(this.props.descriptor, newDescriptor);
        this.props.onClose?.();
        this.props.onEnd({ canceled: false });
    }
    private canSave = (enabled: boolean, newDesc: IRepositoryItemDescriptor) => {
        this.setState({ newDescriptor: newDesc, saveDisabled: !enabled });
    }
}