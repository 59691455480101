/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PButtonsEnum } from "../../ui/primitive/UPButtonsEnum";

export const RotationEnum = new PButtonsEnum({
    id: 'rotation', label: 'Rotation.label', inheritedPropertyId: 'rotation', default: 'None',
    items: [
        { key: 'None', value: 'Rotation.None', icon: 'textRotate0' },
        { key: 'Left', value: 'Rotation.Left', icon: 'textRotate270' },
        { key: 'Right', value: 'Rotation.Right', icon: 'textRotate90' },
        { key: 'UpsideDown', value: 'Rotation.UpsideDown', icon: 'textRotate180' }
    ]
}); 
 
