/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { en } from "./assets/locales/en/messages";
import { i18n } from "@jss/js-common";

i18n.addResourceBundle('en', 'jrws', en, true);

export * from './images/ImageEditor';
export * from './images/ImageEditorView';