/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const HyperlinkTypeEnum = new PEnum({
    id: 'hyperlinkType', label: 'HyperlinkType.label',
    items: [
        { key: 'Null', value: 'HyperlinkType.Null' },
        { key: 'None', value: 'HyperlinkType.None' },
        { key: 'Reference', value: 'HyperlinkType.Reference' },
        { key: 'LocalAnchor', value: 'HyperlinkType.LocalAnchor' },
        { key: 'LocalPage', value: 'HyperlinkType.LocalPage' },
        { key: 'RemoteAnchor', value: 'HyperlinkType.RemoteAnchor' },
        { key: 'RemotePage', value: 'HyperlinkType.RemotePage' },
        { key: 'Custom', value: 'HyperlinkType.Custom' } 
    ]
});

