/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { initializeMapWithObject } from '@jss/js-common';
import TreeView from '@material-ui/lab/TreeView';
import { List as ImmutableList, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { getBandLabel } from '../../../sagas/report/designer/documentUtils';
import * as ReportActions from '../../../actions/reportActions';
import { Actions } from '../../../actions/reportActions';
import '../../../assets/uxpl/css/Outline.css';
import i18n from "../../../i18n";
import { IState } from '../../../reducers';
import { getEditorIndex } from '../../../reducers/report/reportReducer';
import { createBand } from '../../../sagas/report/document/documentFactory';
import { ACTION_IDS } from '../../../sagas/report/document/elementActions';
import { BandTypes, ElementTypes } from '../../../sagas/report/document/elementTypes';
import { CrosstabTypes } from '../../common/JrxmlModel/reader/JrxmlCrosstabUtils';
import { TableTypes } from '../../common/JrxmlModel/reader/JrxmlTableUtils';
import OutlineElement from './OutlineElement';
import { uuidv4 } from '../../../utils/uuidGenerator';
import { IRepositoryItemDescriptor } from '@jss/js-rest-api';



interface IOutline {
    report?: ImmutableMap<string, any>;
    model?: ImmutableMap<string, any>;
    selection?: ImmutableSet<string>;
    modelActions?: ImmutableList<any>;
    subeditors?: ImmutableList<Map<string, any>>;
    currentEditorIndex?: number | undefined;
    outlineExpandedNodes?: string[];
    language?: string;
    openDescriptor?: IRepositoryItemDescriptor | undefined;
    elementClicked?: (id: string, info: { path: string[], type: string }, options: { metaKey: boolean, shiftKey: boolean, touch?: boolean }) => void;
    addElement?: (newElement: ImmutableMap<string, any>, path: string) => void;
    addElements?: (newElements: { element: ImmutableMap<string, any>, path: string }[]) => void;
    createNewBand?: (band: ImmutableMap<string, any>, index?: number) => void;
    createNewPart?: (path: string[], part: ImmutableMap<string, any>) => void;
    createEditor?: (editorType: string, editorLabel: string, isSelectable: boolean, editedResourceId: string, setActive: boolean, params: Record<string, unknown>) => void;
    deleteVisualElements?: (paths: string[][]) => void;
    expandOutlineElements: (elements: string[]) => void;
}

const createUpdatePropertiesSet = () => {
    const set = new Set<string>();
    set.add(Actions.CREATE_ELEMENT);
    set.add(Actions.CREATE_BAND);
    set.add(Actions.UPDATE_ELEMENTS_CONTAINER);
    set.add(Actions.SELECT_ELEMENT);
    set.add(Actions.DESELECT_ELEMENT);
    set.add(Actions.CLEAR_SELECTION);
    set.add(Actions.SELECT_ELEMENTS);
    set.add(Actions.OPEN_DOCUMENT);
    set.add(Actions.DELETE_VISUAL_ELEMENT);
    return set;
}

const getOrderedBands = (model: ImmutableMap<string, any>) => {
    const bands: ImmutableMap<string, ImmutableMap<string, any>> = model.get('bands');
    const groupHeadersOrder: ImmutableList<string> = model.get('groupHeadersOrder', ImmutableList<string>());
    const detailsOrder: ImmutableList<string> = model.get('detailsOrder', ImmutableList<string>());
    const groupFootersOrder: ImmutableList<string> = model.get('groupFootersOrder', ImmutableList<string>());
    const orderedBands = [];
    if (bands.has('title')) {
        orderedBands.push('title');
    }
    if (bands.has('pageHeader')) {
        orderedBands.push('pageHeader');
    }
    if (bands.has('columnHeader')) {
        orderedBands.push('columnHeader');
    }
    groupHeadersOrder.forEach((bandName: string) => {
        orderedBands.push(bandName);
    });
    detailsOrder.forEach((bandName: string) => {
        orderedBands.push(bandName);
    });
    groupFootersOrder.forEach((bandName: string) => {
        orderedBands.push(bandName);
    });
    if (bands.has('columnFooter')) {
        orderedBands.push('columnFooter');
    }
    if (bands.has('pageFooter')) {
        orderedBands.push('pageFooter');
    }
    if (bands.has('lastPageFooter')) {
        orderedBands.push('lastPageFooter');
    }
    if (bands.has('summary')) {
        orderedBands.push('summary');
    }
    if (bands.has('noData')) {
        orderedBands.push('noData');
    }
    if (bands.has('background')) {
        orderedBands.push('background');
    }
    return ImmutableList<string>(orderedBands);
}



const updateOnActions = createUpdatePropertiesSet();

class Outline extends React.Component<IOutline> {

    public shouldComponentUpdate = (nextProps: IOutline) => {
        const currentEditorIndex = getEditorIndex(this.props.report);
        const nextEditorIndex = getEditorIndex(nextProps.report);

        if (this.props.language !== nextProps.language){
            return true;
        }
        if (this.props.outlineExpandedNodes !== nextProps.outlineExpandedNodes) {
            return true;
        }
        if (nextEditorIndex !== currentEditorIndex || this.props.subeditors.size !== nextProps.subeditors.size) {
            return true;
        }

        if (!this.props.modelActions && nextProps.modelActions) {
            return true;
        }
        const oldModelProps = this.props.modelActions;
        const newModelProps = nextProps.modelActions;
        if (oldModelProps.size !== newModelProps.size && newModelProps.size > 0) {
            const lastExecutedAction = newModelProps.get(newModelProps.size - 1);
            if (lastExecutedAction.type === Actions.UNDO || lastExecutedAction.type === Actions.REDO || (lastExecutedAction.type === Actions.CREATE_PROPERTY && lastExecutedAction.element && lastExecutedAction.element.type === ElementTypes.MAIN_DATASET_GROUP)) {
                return true;
            }
            if (lastExecutedAction.type === Actions.DELETE_ELEMENT) {
                const t = this.props.report.getIn([...lastExecutedAction.path, 'type']);
                if (t === ElementTypes.MAIN_DATASET_GROUP) {
                    return true;
                }
            }
            if (updateOnActions.has(lastExecutedAction.type)) {
                return true;
            }
            if (lastExecutedAction.path && lastExecutedAction.path[lastExecutedAction.path.length - 1] === 'com.jaspersoft.studio.element.name') {
                return true;
            }
            if (lastExecutedAction.properties && 
                (lastExecutedAction.properties.name || lastExecutedAction.properties.partNameExpression || lastExecutedAction.properties.subreportExpression)) {
                //when a name change refresh the outline, this help to update the visual when ie a name of a group changes
                return true;
            }
        } else if (nextProps.modelActions.size > 0 && nextProps.modelActions.get(nextProps.modelActions.size - 1).type === ReportActions.Actions.OPEN_DOCUMENT) {
            //if the last action is an OPEN DOCUMENT force the refresh, this is to fix the case when a document is opened,
            //closed and another document is opened, the number of model actions is the same but they are two different documents
            return true;
        }
        return false;
    }

    private isBookReport = () => {
        const sectionType = this.props.report.getIn(['model', 'sectionType']) as string;
        return sectionType && sectionType.toLowerCase() === 'part';
    }

    public getVariables = (basePath = ['variables']) => {
        const variablesNodeId = basePath.join();
        const isVariablesSelected = this.props.selection.has(variablesNodeId);
        const variablesModel: ImmutableMap<string, any> = this.props.model.getIn(basePath, ImmutableMap<string, any>()) as ImmutableMap<string, any>;
        const variablesNodes = variablesModel.entrySeq().map(([key, variableModel]) => {
            const variableName = variableModel.get('name');
            const variableId = variableModel.get('id');
            const isVariableSelected = this.props.selection.has(variableId);
            return <OutlineElement key={variableId}
                id={variableId}
                type={ElementTypes.VARIABLE}
                label={variableName}
                isSelected={isVariableSelected}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                path={[...basePath, key]}
                contextualActionSelected={this.contextualActionSelected} />
        });
        if (variablesNodes.size > 0) {
            const variables = <OutlineElement key={variablesNodeId}
                id={variablesNodeId}
                type={'variables'}
                label={'Variables'}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                path={[...basePath]}
                isSelected={isVariablesSelected}
                contextualActionSelected={this.contextualActionSelected}>
                {variablesNodes.size > 0 ? variablesNodes : undefined}
            </OutlineElement>;
            return variables;
        }
        return null;
    }

    public getFields = (basePath = ['fields']) => {
        const fieldsNodeId = basePath.join();
        const isFieldsSelected = this.props.selection.has(fieldsNodeId);
        const fieldsModel: ImmutableMap<string, any> = this.props.model.getIn(basePath, ImmutableMap<string, any>()) as ImmutableMap<string, any>;
        const fieldsNodes = fieldsModel.entrySeq().map(([key, fieldModel]) => {
            const fieldName = fieldModel.get('name');
            const fieldId = fieldModel.get('id');
            const isFieldSelected = this.props.selection.has(fieldId);
            return <OutlineElement key={fieldId}
                id={fieldId}
                type={ElementTypes.FIELD}
                label={fieldName}
                isSelected={isFieldSelected}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                path={[...basePath, key]}
                contextualActionSelected={this.contextualActionSelected} />
        });
        if (fieldsNodes.size > 0) {
            const fields = <OutlineElement key={fieldsNodeId}
                id={fieldsNodeId}
                type={'fields'}
                label={'Fields'}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                path={[...basePath]}
                isSelected={isFieldsSelected}
                contextualActionSelected={this.contextualActionSelected}>
                {fieldsNodes.size > 0 ? fieldsNodes : undefined}
            </OutlineElement>;
            return fields;
        }
        return null;
    }

    public getParameters = (basePath = ['parameters']) => {
        const parametersNodeId = basePath.join();
        const isParametersSelected = this.props.selection.has(parametersNodeId);
        const parametersModel: ImmutableMap<string, any> = this.props.model.getIn(basePath, ImmutableMap<string, any>()) as ImmutableMap<string, any>;
        const parametersNodes = parametersModel.entrySeq().map(([key, parameterModel]) => {
            const parameterName = parameterModel.get('name');
            const parameterId = parameterModel.get('id');
            const isParameterSelected = this.props.selection.has(parameterId);
            return <OutlineElement key={parameterId}
                id={parameterId}
                type={ElementTypes.PARAMETER}
                label={parameterName}
                isSelected={isParameterSelected}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                path={[...basePath, key]}
                contextualActionSelected={this.contextualActionSelected} />
        });
        if (parametersNodes.size > 0) {
            const parameters = <OutlineElement key={parametersNodeId}
                id={parametersNodeId}
                type={'parameters'}
                label={'Parameters'}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                path={[...basePath]}
                isSelected={isParametersSelected}
                contextualActionSelected={this.contextualActionSelected}>
                {parametersNodes.size > 0 ? parametersNodes : undefined}
            </OutlineElement>;
            return parameters;
        }
        return null;
    }

    public getSortFields = (basePath = ['sortFields']) => {
        const sortFieldsNodeId = basePath.join();
        const isSortFieldsSelected = this.props.selection.has(sortFieldsNodeId);
        const sortFieldsModel: ImmutableMap<string, any> = this.props.model.getIn(basePath, ImmutableMap<string, any>()) as ImmutableMap<string, any>;
        const sortFieldsNodes = sortFieldsModel.entrySeq().map(([key, sortFIeldModel]) => {
            const sortFieldName = sortFIeldModel.get('name');
            const sortFieldId = sortFIeldModel.get('id');
            const isSortFieldSelected = this.props.selection.has(sortFieldId);
            return <OutlineElement key={sortFieldId}
                id={sortFieldId}
                type={ElementTypes.SORT_FIELD}
                label={sortFieldName}
                isSelected={isSortFieldSelected}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                path={[...basePath, key]}
                contextualActionSelected={this.contextualActionSelected} />
        });
        if (sortFieldsNodes.size > 0) {
            const sortFields = <OutlineElement key={sortFieldsNodeId}
                id={sortFieldsNodeId}
                type={'sortFields'}
                label={'Sort Fields'}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                path={[...basePath]}
                isSelected={isSortFieldsSelected}
                contextualActionSelected={this.contextualActionSelected}>
                {sortFieldsNodes.size > 0 ? sortFieldsNodes : undefined}
            </OutlineElement>;
            return sortFields;
        }
        return null;
    }

    public getStyles = () => {
        const isStylesSelected = this.props.selection.has('stylesNode');
        const basePath = ['styles'];
        const stylesModel: ImmutableMap<string, any> = this.props.model.getIn(basePath, ImmutableMap<string, any>()) as ImmutableMap<string, any>;
        const stylesNodes = stylesModel.entrySeq().map(([key, styleModel]) => {
            const styleName = styleModel.get('name');
            const styleId = styleModel.get('id');
            const isStyleSelected = this.props.selection.has(styleId);
            return <OutlineElement key={styleId}
                id={styleId}
                type={ElementTypes.STYLE}
                label={styleName}
                isSelected={isStyleSelected}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                path={[...basePath, key]}
                contextualActionSelected={this.contextualActionSelected} />
        });
        if (stylesNodes.size > 0) {
            const styles = <OutlineElement key={'stylesNode'}
                id={'stylesNode'}
                type={'styles'}
                label={'Styles'}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                path={[...basePath]}
                isSelected={isStylesSelected}
                contextualActionSelected={this.contextualActionSelected}>
                {stylesNodes.size > 0 ? stylesNodes : undefined}
            </OutlineElement>;
            return styles;
        }
        return null;
    }

    public getScriptlets = (basePath = ['scriptlets']) => {
        const scriptletsNodeId = basePath.join();
        const isScriptletsSelected = this.props.selection.has(scriptletsNodeId);
        const scriptletsModel: ImmutableMap<string, any> = this.props.model.getIn(basePath, ImmutableMap<string, any>()) as ImmutableMap<string, any>;
        const scriptletsNodes = scriptletsModel.entrySeq().map(([key, scriptletModel]) => {
            const scriptletName = scriptletModel.get('name');
            const scriptletId = scriptletModel.get('id');
            const isScriptletSelected = this.props.selection.has(scriptletId);
            return <OutlineElement key={scriptletId}
                id={scriptletId}
                type={ElementTypes.SCRIPTLET}
                label={scriptletName}
                isSelected={isScriptletSelected}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                path={[...basePath, key]}
                contextualActionSelected={this.contextualActionSelected} />
        });
        if (scriptletsNodes.size > 0) {
            const styles = <OutlineElement key={scriptletsNodeId}
                id={scriptletsNodeId}
                type={'scriptlets'}
                label={'Scriptlets'}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                path={[...basePath]}
                isSelected={isScriptletsSelected}
                contextualActionSelected={this.contextualActionSelected}>
                {scriptletsNodes.size > 0 ? scriptletsNodes : undefined}
            </OutlineElement>;
            return styles;
        }
        return null;
    }

    getSubdatasets = () => {
        const basePath = ['subdatasets'];
        const subdatasetsModel: ImmutableMap<string, any> = this.props.model.getIn(basePath, ImmutableMap<string, any>()) as ImmutableMap<string, any>;
        const subdatasetNodes = subdatasetsModel.entrySeq().map(([key, subdatasetModel]) => {
            const subdatasetName = subdatasetModel.get('name');
            const subdatasetId = subdatasetModel.get('id');
            const isSubdatasetSelected = this.props.selection.has(subdatasetId);
            const datasetPath = [...basePath, subdatasetName];
            const groupsPath = [...datasetPath, 'groups'];
            const datasetGroupsNodeId = groupsPath.join();
            const isDatasetGroupsSelected = this.props.selection.has(datasetGroupsNodeId);
            const groupsModel = subdatasetModel.get('groups', ImmutableMap<string, any>());
            const groupsNodes = groupsModel.entrySeq().map(([groupNameKey, groupModel]) => {
                const groupName = groupModel.get('name');
                const groupId = groupModel.get('id');
                const isGroupSelected = this.props.selection.has(groupId);
                return <OutlineElement key={groupId}
                    id={groupId}
                    type={ElementTypes.DATASET_GROUP}
                    label={groupName}
                    isSelected={isGroupSelected}
                    onClick={this.onClick}
                    onDoubleClick={this.onDobuleClick}
                    path={[...groupsPath, groupNameKey]}
                    contextualActionSelected={this.contextualActionSelected} />
            });
            let groupsRootNode;
            if (groupsNodes.size > 0) {
                groupsRootNode = <OutlineElement key={datasetGroupsNodeId}
                    id={datasetGroupsNodeId}
                    type={'datasetGroups'}
                    label={'Groups'}
                    onClick={this.onClick}
                    onDoubleClick={this.onDobuleClick}
                    path={groupsPath}
                    isSelected={isDatasetGroupsSelected}
                    contextualActionSelected={this.contextualActionSelected}>
                    {groupsNodes}
                </OutlineElement>
            }
            return <OutlineElement key={subdatasetId}
                id={subdatasetId}
                type={ElementTypes.DATASET}
                label={subdatasetName}
                isSelected={isSubdatasetSelected}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                path={[...basePath, key]}
                contextualActionSelected={this.contextualActionSelected}>
                {this.getParameters([...datasetPath, 'parameters'])}
                {this.getFields([...datasetPath, 'fields'])}
                {this.getSortFields([...datasetPath, 'sortFields'])}
                {this.getVariables([...datasetPath, 'variables'])}
                {this.getScriptlets([...datasetPath, 'scriptlets'])}
                {groupsRootNode}
            </OutlineElement>
        });
        return subdatasetNodes;
    }

    getLabel = (element: ImmutableMap<string, any>, type: string) => {
        const propertyLabel = element.getIn(['properties', 'com.jaspersoft.studio.element.name', 'value']) as string | undefined;
        if (propertyLabel && propertyLabel.trim().length > 0) {
            return propertyLabel;
        }
        if (type === ElementTypes.TEXTFIELD) {
            const expression = element.get('textFieldExpression');
            return expression ? expression : type;
        } else if (type === ElementTypes.BARCODE) {
            const expression = element.get('codeExpression');
            return expression ? expression : type;
        } else if (type === ElementTypes.STATICTEXT) {
            const text = element.get('text');
            return text ? text : type;
        } else if (type === ElementTypes.ELEMENT_GROUP) {
            return 'Element Group';
        } else if (type === ElementTypes.COMPONENT_ELEMENT_GENERIC) {
            return `${i18n.t('properties.unknownElement')} ${element.get('componentName')}`;
        } else if (type === ElementTypes.AD_HOC_COMPONENT) {
            return i18n.t('adhoc.outlineLabel');
        } else if (type === ElementTypes.GENERIC_ELEMENT) {
            return i18n.t('genericElement.outlineLabel');
        }
        return type;
    }

    getTableContent = (map: Map<string, any>, table: ImmutableMap<string, any>) => {
        const tableId = table.get('id');
        let columnIndex = 0;
        const mapTableChildren = (tableChild: ImmutableMap<string, any>) => {
            const id = tableChild.get('id');
            const type = tableChild.get('type');
            const pathId = "elements/" + id;
            let elementContent = null;
            const indexBeforeSearch = columnIndex;
            if (type !== ElementTypes.TABLE && type !== ElementTypes.CROSSTAB && type !== ElementTypes.LIST && map.has(pathId)) {
                elementContent = map.get(pathId).map(mapTableChildren);
            }
            const isElementSelected = this.props.selection.has(id);
            let label;
            if (type === TableTypes.TABLE_HEADER_NAME) {
                label = i18n.t(TableTypes.TABLE_HEADER_NAME);
            } else if (type === TableTypes.TABLE_COLUMN_HEADER_NAME) {
                label = i18n.t(TableTypes.TABLE_COLUMN_HEADER_NAME);
            } else if (type === TableTypes.TABLE_GROUP_HEADER_NAME) {
                const groupName = tableChild.get('groupName')
                label = `${i18n.t(TableTypes.TABLE_GROUP_HEADER_NAME)}: ${groupName}`;
            } else if (type === TableTypes.TABLE_DETAIL_NAME) {
                label = i18n.t(TableTypes.TABLE_DETAIL_NAME);
            } else if (type === TableTypes.TABLE_GROUP_FOOTER_NAME) {
                const groupName = tableChild.get('groupName')
                label = `${i18n.t(TableTypes.TABLE_GROUP_FOOTER_NAME)}: ${groupName}`;
            } else if (type === TableTypes.TABLE_COLUMN_FOOTER_NAME) {
                label = i18n.t(TableTypes.TABLE_COLUMN_FOOTER_NAME);
            } else if (type === TableTypes.TABLE_FOOTER_NAME) {
                label = i18n.t(TableTypes.TABLE_FOOTER_NAME);
            } else if (type === TableTypes.TABLE_GROUP_COLUMN_NAME) {
                label = `${i18n.t(TableTypes.TABLE_GROUP_COLUMN_NAME)} ${indexBeforeSearch + 1}-${columnIndex}`;
            } else if (type === TableTypes.TABLE_CELL_NAME) {
                columnIndex++;
                label = `${i18n.t(TableTypes.TABLE_CELL_NAME)} ${columnIndex}`;
            } else if (type === TableTypes.TABLE_GROUP_CELL_NAME) {
                label = i18n.t(TableTypes.TABLE_GROUP_CELL_NAME);
            } else {
                label = this.getLabel(tableChild, type);
            }
            return (
                <OutlineElement id={id}
                    key={id}
                    label={label}
                    type={type}
                    onClick={this.onClick}
                    onDoubleClick={this.onDobuleClick}
                    path={['elements', id]}
                    isSelected={isElementSelected}
                    contextualActionSelected={this.contextualActionSelected}>
                    {elementContent}
                </OutlineElement>
            );
        }

        const mapTableDetailChildren = (tableChild: Map<string, any>) => {
            const id = tableChild.get('id');
            const type = tableChild.get('type');
            const pathId = "elements/" + id;
            if (type === TableTypes.TABLE_CELL_NAME) {
                columnIndex++;
                const label = `${i18n.t(TableTypes.TABLE_CELL_NAME)} ${columnIndex}`;
                const isElementSelected = this.props.selection.has(id);
                const content = map.get(pathId);
                const elementContent = content ? content.map(mapTableChildren) : null;
                return (
                    <OutlineElement id={id}
                        key={id}
                        label={label}
                        type={type}
                        onClick={this.onClick}
                        onDoubleClick={this.onDobuleClick}
                        path={['elements', id]}
                        isSelected={isElementSelected}
                        contextualActionSelected={this.contextualActionSelected}>
                        {elementContent}
                    </OutlineElement>
                );
            } else if (type !== ElementTypes.TABLE && type !== ElementTypes.CROSSTAB && type !== ElementTypes.LIST && map.has(pathId)) {
                return map.get(pathId).map(mapTableDetailChildren);
            }
            return null;
        }
        const tableChildren = map.get('elements/' + tableId);
        let tableContent;
        if (tableChildren) {
            tableContent = tableChildren.map((element) => {
                columnIndex = 0;
                if (element.get('type') === TableTypes.TABLE_DETAIL_NAME) {
                    const id = element.get('id');
                    const elementContent = mapTableDetailChildren(element);
                    const label = i18n.t(TableTypes.TABLE_DETAIL_NAME);
                    const isElementSelected = this.props.selection.has(id);
                    return <OutlineElement id={id}
                        key={id}
                        label={label}
                        type={TableTypes.TABLE_DETAIL_NAME}
                        onClick={this.onClick}
                        onDoubleClick={this.onDobuleClick}
                        path={['elements', id]}
                        isSelected={isElementSelected}
                        contextualActionSelected={this.contextualActionSelected}>
                        {elementContent}
                    </OutlineElement>
                }
                return mapTableChildren(element)
            });
        }
        const isTableSelected = this.props.selection.has(tableId);
        const propertyLabel = table.getIn(['properties', 'com.jaspersoft.studio.element.name', 'value']) as string | undefined;
        let label = 'Table';
        if (propertyLabel && propertyLabel.trim().length > 0) {
            label = propertyLabel;
        }
        const tableElement = <OutlineElement key={tableId}
            id={tableId}
            type={ElementTypes.TABLE}
            label={label}
            path={['elements', tableId]}
            onClick={this.onClick}
            onDoubleClick={this.onDobuleClick}
            isSelected={isTableSelected}
            contextualActionSelected={this.contextualActionSelected}>
            {tableContent}
        </OutlineElement>
        return tableElement;
    }

    getListContent = (map: Map<string, any>, list: ImmutableMap<string, any>) => {
        const listId = list.get('id');
        const mapListChildren = (tableChild: ImmutableMap<string, any>) => {
            const id = tableChild.get('id');
            const type = tableChild.get('type');
            const pathId = "elements/" + id;
            let elementContent = null;
            if (type !== ElementTypes.TABLE && type !== ElementTypes.CROSSTAB && type !== ElementTypes.LIST && map.has(pathId)) {
                elementContent = map.get(pathId).map(mapListChildren);
            }
            const isElementSelected = this.props.selection.has(id);
            return (
                <OutlineElement id={id}
                    key={id}
                    label={this.getLabel(tableChild, type)}
                    type={type}
                    onClick={this.onClick}
                    onDoubleClick={this.onDobuleClick}
                    path={['elements', id]}
                    isSelected={isElementSelected}
                    contextualActionSelected={this.contextualActionSelected}>
                    {elementContent}
                </OutlineElement>
            );
        }

        let listContent;
        const listChildren = map.get('elements/' + listId);
        if (listChildren) {
            listContent = listChildren.map(mapListChildren);
        }

        const isListSelected = this.props.selection.has(listId);
        const propertyLabel = list.getIn(['properties', 'com.jaspersoft.studio.element.name', 'value']) as string | undefined;
        let label = 'List';
        if (propertyLabel && propertyLabel.trim().length > 0) {
            label = propertyLabel;
        }
        const listElement = <OutlineElement key={listId}
            id={listId}
            type={ElementTypes.LIST}
            label={label}
            path={['elements', listId]}
            onClick={this.onClick}
            onDoubleClick={this.onDobuleClick}
            isSelected={isListSelected}
            contextualActionSelected={this.contextualActionSelected}>
            {listContent}
        </OutlineElement>
        return listElement;
    }

    getCrosstabContent = (map: Map<string, any>, crosstab: ImmutableMap<string, any>) => {
        const crosstabId = crosstab.get('id');
        const mapCrosstabChildren = (crosstabChild: ImmutableMap<string, any>) => {
            const id = crosstabChild.get('id');
            const type = crosstabChild.get('type');
            const pathId = "elements/" + id;
            let elementContent = null;
            if (type !== ElementTypes.TABLE && type !== ElementTypes.CROSSTAB && type !== ElementTypes.LIST && map.has(pathId)) {
                elementContent = map.get(pathId).map(mapCrosstabChildren);
            }
            const isElementSelected = this.props.selection.has(id);
            let label;
            if (type === CrosstabTypes.CROSSTAB_ROW_GROUP_NAME || type === CrosstabTypes.CROSSTAB_COLUMN_GROUP_NAME) {
                label = crosstabChild.get('name');
            } else if (type === CrosstabTypes.CROSSTAB_ROW_HEADER_NAME) {
                const rowGroupPath = crosstabChild.get('path');
                const rowGroup = this.props.model.getIn(rowGroupPath.split('/')) as ImmutableMap<string, any>;
                const groupName = rowGroup.get('name')
                label = `${i18n.t('CROSSTAB_ROW_HEADER_NAME_PREFIX')} ${groupName}`;
            } else if (type === CrosstabTypes.CROSSTAB_TOTAL_ROW_HEADER_NAME) {
                const rowGroupPath = crosstabChild.get('path');
                const rowGroup = this.props.model.getIn(rowGroupPath.split('/')) as ImmutableMap<string, any>;
                const groupName = rowGroup.get('name');
                label = `${i18n.t('CROSSTAB_TOTAL_ROW_HEADER_NAME_PREFIX')} ${groupName}`;
            } else if (type === CrosstabTypes.CROSSTAB_COLUMN_HEADER_NAME) {
                const columnGroupPath = crosstabChild.get('path');
                const columnGroup = this.props.model.getIn(columnGroupPath.split('/')) as ImmutableMap<string, any>;
                const groupName = columnGroup.get('name');
                label = `${i18n.t('CROSSTAB_COLUMN_HEADER_NAME_PREFIX')} ${groupName}`;
            } else if (type === CrosstabTypes.CROSSTAB_TOTAL_COLUMN_HEADER_NAME) {
                const columnGroupPath = crosstabChild.get('path');
                const columnGroup = this.props.model.getIn(columnGroupPath.split('/')) as ImmutableMap<string, any>;
                const groupName = columnGroup.get('name');
                label = `${i18n.t('CROSSTAB_TOTAL_COLUMN_HEADER_NAME_PREFIX')} ${groupName}`;
            } else if (type === CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME) {
                label = i18n.t('CROSSTAB_NO_DATA_CELL');
            } else if (type === CrosstabTypes.CROSSTAB_HEADER_CELL_NAME) {
                label = i18n.t('CROSSTAB_HEADER_CELL');
            } else {
                label = this.getLabel(crosstabChild, type);
            }
            return (
                <OutlineElement id={id}
                    key={id}
                    label={label}
                    type={type}
                    onClick={this.onClick}
                    onDoubleClick={this.onDobuleClick}
                    path={['elements', id]}
                    isSelected={isElementSelected}
                    contextualActionSelected={this.contextualActionSelected}>
                    {elementContent}
                </OutlineElement>
            );
        }
        const crosstabChildren = map.get('elements/' + crosstabId);
        const crosstabContent = [];
        if (crosstabChildren) {
            const rowGroups = [];
            const columnGroups = [];
            let hasNoDataCell = false;
            crosstabChildren.forEach((crosstabChild: ImmutableMap<string, any>) => {
                const type = crosstabChild.get('type');
                if (type === CrosstabTypes.CROSSTAB_ROW_GROUP_NAME) {
                    rowGroups.push(crosstabChild);
                } else if (type === CrosstabTypes.CROSSTAB_COLUMN_GROUP_NAME) {
                    columnGroups.push(crosstabChild);
                } else {
                    const id = crosstabChild.get('id');
                    const pathId = "elements/" + id;
                    if (type === CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME) {
                        hasNoDataCell = true;
                    }
                    let label;
                    if (type === CrosstabTypes.CROSSTAB_CELL_NAME) {
                        const column = crosstabChild.get('columnTotalGroup');
                        const row = crosstabChild.get('rowTotalGroup');
                        label = `${column ? column : i18n.t('CROSSTAB_DETAIL')}/${row}`;
                    } else if (type === CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME || type === CrosstabTypes.CROSSTAB_HEADER_CELL_NAME || type === CrosstabTypes.CROSSTAB_COLUMN_GROUP_NAME || type === CrosstabTypes.CROSSTAB_ROW_GROUP_NAME) {
                        label = i18n.t(type);
                    } else {
                        label = this.getLabel(crosstabChild, type);
                    }

                    let elementContent = null;
                    if (type !== ElementTypes.TABLE && type !== ElementTypes.CROSSTAB && type !== ElementTypes.LIST && map.has(pathId)) {
                        elementContent = map.get(pathId).map(mapCrosstabChildren);
                    }
                    const isElementSelected = this.props.selection.has(id);
                    crosstabContent.push(
                        <OutlineElement id={id}
                            key={id}
                            label={label}
                            type={type}
                            onClick={this.onClick}
                            onDoubleClick={this.onDobuleClick}
                            path={['elements', id]}
                            isSelected={isElementSelected}
                            contextualActionSelected={this.contextualActionSelected}>
                            {elementContent}
                        </OutlineElement>
                    );
                }
            });
            if (!hasNoDataCell) {
                const isSelected = this.props.selection.has('emptyCrosstabNoData');
                crosstabContent.push(<OutlineElement
                    key={'emptyCrosstabNoData'}
                    id={'emptyCrosstabNoData'}
                    type={CrosstabTypes.CROSSTAB_NO_DATA_CELL_PLACEHOLDER}
                    label={i18n.t(CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME)}
                    path={['elements', crosstabId]}
                    onClick={this.onClick}
                    onDoubleClick={this.onDobuleClick}
                    isEmpty={true}
                    isSelected={isSelected}
                    contextualActionSelected={this.contextualActionSelected} />
                );
            }
            const isRowGroupsSelected = this.props.selection.has('rowGroups');
            const isColumnGroupsSelected = this.props.selection.has('columnGroups');
            const isMeasuresSelected = this.props.selection.has('measures');
            crosstabContent.splice(0, 0, <OutlineElement
                key={'rowGroups'}
                id={'rowGroups'}
                type={'CROSSTAB_ROW_GROUPS'}
                label={i18n.t('CROSSTAB_ROW_GROUPS')}
                path={['elements', crosstabId]}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                isSelected={isRowGroupsSelected}
                contextualActionSelected={this.contextualActionSelected}>
                {rowGroups.map((rowGroup: ImmutableMap<string, any>) => {
                    return mapCrosstabChildren(rowGroup);
                })}
            </OutlineElement>
            );
            crosstabContent.splice(1, 0, <OutlineElement
                key={'columnGroups'}
                id={'columnGroups'}
                type={'CROSSTAB_COLUMN_GROUPS'}
                label={i18n.t('CROSSTAB_COLUMN_GROUPS')}
                path={['elements', crosstabId]}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                isSelected={isColumnGroupsSelected}
                contextualActionSelected={this.contextualActionSelected}>
                {columnGroups.map((rowGroup: ImmutableMap<string, any>) => {
                    return mapCrosstabChildren(rowGroup);
                })}
            </OutlineElement>
            );
            const measures = crosstab.get('measures');
            crosstabContent.splice(2, 0, <OutlineElement
                key={'measures'}
                id={'measures'}
                type={'CROSSTAB_MEASURES'}
                label={i18n.t('CROSSTAB_MEASURES')}
                path={['elements', crosstabId]}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                isSelected={isMeasuresSelected}
                contextualActionSelected={this.contextualActionSelected}>
                {measures.map((measure: Map<string, any>, index: number) => {
                    const measureName = measure.get('name');
                    const isMeasureSelected = this.props.selection.has(measureName);
                    return <OutlineElement
                        key={measureName}
                        id={measureName}
                        type={CrosstabTypes.CROSSTAB_MEASURE_NAME}
                        label={measureName}
                        path={['elements', crosstabId, 'measures', index]}
                        onClick={this.onClick}
                        onDoubleClick={this.onDobuleClick}
                        isSelected={isMeasureSelected}
                        contextualActionSelected={this.contextualActionSelected} />
                })}
            </OutlineElement>
            );
        }
        const isCrosstabSelected = this.props.selection.has(crosstabId);
        const propertyLabel = crosstab.getIn(['properties', 'com.jaspersoft.studio.element.name', 'value']) as string | undefined;
        let label = 'Crosstab';
        if (propertyLabel && propertyLabel.trim().length > 0) {
            label = propertyLabel;
        }
        const crosstabElement = <OutlineElement key={crosstabId}
            id={crosstabId}
            type={ElementTypes.CROSSTAB}
            label={label}
            path={['elements', crosstabId]}
            onClick={this.onClick}
            onDoubleClick={this.onDobuleClick}
            isSelected={isCrosstabSelected}
            contextualActionSelected={this.contextualActionSelected}>
            {crosstabContent}
        </OutlineElement>
        return crosstabElement;
    }


    getBands = (map: Map<string, any>) => {
        const getEmptyBand = (bandId: string, label: string) => {
            const isBandSelected = this.props.selection.has(bandId);
            return <OutlineElement key={bandId}
                id={bandId}
                type={ElementTypes.BAND_PLACEHOLDER}
                label={label}
                path={['bands']}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                isEmpty={true}
                isSelected={isBandSelected}
                contextualActionSelected={this.contextualActionSelected} />
        }

        const getEmptyGroupBand = (bandId: string, label: string, groupName: string) => {
            const isBandSelected = this.props.selection.has(bandId);
            return <OutlineElement key={bandId + groupName}
                id={bandId}
                type={ElementTypes.BAND_PLACEHOLDER}
                label={label}
                path={['bands']}
                onClick={this.onClick}
                data={{ groupName: groupName }}
                onDoubleClick={this.onDobuleClick}
                isEmpty={true}
                isSelected={isBandSelected}
                contextualActionSelected={this.contextualActionSelected} />
        }

        const mapBandChildren = (bandChild: ImmutableMap<string, any>) => {
            const id = bandChild.get('id');
            const type = bandChild.get('type');
            const pathId = "elements/" + id;
            let elementContent = null;
            if (type !== ElementTypes.TABLE && type !== ElementTypes.CROSSTAB && type !== ElementTypes.LIST && map.has(pathId)) {
                elementContent = map.get(pathId).map(mapBandChildren);
            }
            const isElementSelected = this.props.selection.has(id);
            return (
                <OutlineElement id={id}
                    key={id}
                    label={this.getLabel(bandChild, type)}
                    type={type}
                    onClick={this.onClick}
                    onDoubleClick={this.onDobuleClick}
                    path={['elements', id]}
                    isSelected={isElementSelected}
                    contextualActionSelected={this.contextualActionSelected}>
                    {elementContent}
                </OutlineElement>
            );
        }
        let title;
        let pageHeader;
        let columnHeader;
        const groupHeaders: Map<string, ImmutableMap<string, any>[]> = new Map<string, ImmutableMap<string, any>[]>();
        const details = [];
        const groupFooters: Map<string, ImmutableMap<string, any>[]> = new Map<string, ImmutableMap<string, any>[]>();
        let columnFooter;
        let pageFooter;
        let lastPageFooter
        let summary;
        let noData;
        let background;
        const bands: ImmutableMap<string, ImmutableMap<string, any>> = this.props.model.get('bands');
        const bandsOrder: ImmutableList<string> = getOrderedBands(this.props.model);
        let detailCount = 1;

        bandsOrder.forEach((bandId: string) => {
            const band = bands.get(bandId);
            let bandContent = null;
            const bandType = band.get('bandType');
            if (map.has('bands/' + bandId)) {
                //inside the map there are all the direct child of the band
                bandContent = map.get('bands/' + bandId).map(mapBandChildren);
            }
            const isBandSelected = this.props.selection.has(bandId);
            let label = bandId;
            if (bandType === BandTypes.BAND_DETAIL) {
                label = `${i18n.t('band.detail')} ${detailCount}`;
                detailCount++;
            } else {
                label = getBandLabel(bandType);
            }
            const bandElement = <OutlineElement key={bandId}
                id={bandId}
                type={ElementTypes.BAND}
                label={label}
                bandType={band.get('bandType')}
                path={['bands', bandId]}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                isSelected={isBandSelected}
                contextualActionSelected={this.contextualActionSelected}>
                {bandContent}
            </OutlineElement>
            if (bandType === BandTypes.BAND_TITLE) {
                title = bandElement;
            } else if (bandType === BandTypes.BAND_PAGE_HEADER) {
                pageHeader = bandElement;
            } else if (bandType === BandTypes.BAND_COLUMN_HEADER) {
                columnHeader = bandElement;
            } else if (bandType === BandTypes.BAND_GROUP_HEADER) {
                const groupName = band.get('groupName');
                const oldEntry = groupHeaders.get(groupName);
                if (!oldEntry) {
                    groupHeaders.set(groupName, [band]);
                } else {
                    oldEntry.push(band);
                }
            } else if (bandType === BandTypes.BAND_DETAIL) {
                details.push(bandElement);
            } else if (bandType === BandTypes.BAND_GROUP_FOOTER) {
                const groupName = band.get('groupName');
                const oldEntry = groupFooters.get(groupName);
                if (!oldEntry) {
                    groupFooters.set(groupName, [band]);
                } else {
                    oldEntry.push(band);
                }
            } else if (bandType === BandTypes.BAND_COLUMN_FOOTER) {
                columnFooter = bandElement;
            } else if (bandType === BandTypes.BAND_PAGE_FOOTER) {
                pageFooter = bandElement;
            } else if (bandType === BandTypes.BAND_LAST_PAGE_FOOTER) {
                lastPageFooter = bandElement;
            } else if (bandType === BandTypes.BAND_SUMMARY) {
                summary = bandElement;
            } else if (bandType === BandTypes.BAND_NO_DATA) {
                noData = bandElement;
            } else if (bandType === BandTypes.BAND_BACKGROUND) {
                background = bandElement;
            }
        });
        if (!title) {
            title = getEmptyBand('emptyTitle', i18n.t('band.title'));
        }
        if (!pageHeader) {
            pageHeader = getEmptyBand('emptyPageHeader', i18n.t('band.pageHeader'));
        }
        if (!columnHeader) {
            columnHeader = getEmptyBand('emptyColumnHeader', i18n.t('band.columnHeader'));
        }
        if (details.length === 0) {
            details.push(getEmptyBand('emptyDetail', i18n.t('band.detail')));
        }
        if (!columnFooter) {
            columnFooter = getEmptyBand('emptyColumnFooter', i18n.t('band.columnFooter'));
        }
        if (!pageFooter) {
            pageFooter = getEmptyBand('emptyPageFooter', i18n.t('band.pageFooter'));
        }
        if (!lastPageFooter) {
            lastPageFooter = getEmptyBand('emptyLastPageFooter', i18n.t('band.lastPageFooter'));
        }
        if (!summary) {
            summary = getEmptyBand('emptySummary', i18n.t('band.summary'));
        }
        if (!noData) {
            noData = getEmptyBand('emptyNoData', i18n.t('band.noData'));
        }
        if (!background) {
            background = getEmptyBand('emptyBackground', i18n.t('band.background'));
        }
        const groups = this.props.report.getIn(['model', 'groups']) as ImmutableList<ImmutableMap<string, any>>;
        const groupHeadersElements = [];
        const groupFootersElements = [];
        if (groups) {
            groups.forEach((group) => {
                const groupName = group.get('name');
                const alreadyCreatedGroupHeaders = groupHeaders.get(groupName);
                if (alreadyCreatedGroupHeaders) {
                    alreadyCreatedGroupHeaders.forEach((bandNode, index) => {
                        const bandId = bandNode.get('id');
                        const isBandSelected = this.props.selection.has(bandId);
                        let bandContent = null;
                        const bandType = bandNode.get('bandType');
                        if (map.has('bands/' + bandId)) {
                            //inside the map there are all the direct child of the band
                            bandContent = map.get('bands/' + bandId).map(mapBandChildren);
                        }
                        groupHeadersElements.push(
                            <OutlineElement key={bandId}
                                id={bandId}
                                type={ElementTypes.BAND}
                                label={`${i18n.t('band.groupHeader')} ${groupName} ${index > 0 ? index + 1 : ''}`}
                                bandType={bandType}
                                path={['bands', bandId]}
                                onClick={this.onClick}
                                onDoubleClick={this.onDobuleClick}
                                isSelected={isBandSelected}
                                contextualActionSelected={this.contextualActionSelected}>
                                {bandContent}
                            </OutlineElement>)
                    });
                } else {
                    const label = `${i18n.t('band.groupHeader')} ${groupName}`;
                    groupHeadersElements.push(getEmptyGroupBand('emptyGroupHeader', label, groupName))
                }
            });
            groups.reverse().forEach((group) => {
                const groupName = group.get('name');
                const alreadyCreatedGroupFooters = groupFooters.get(groupName);
                if (alreadyCreatedGroupFooters) {
                    alreadyCreatedGroupFooters.forEach((bandNode, index) => {
                        const bandId = bandNode.get('id');
                        const isBandSelected = this.props.selection.has(bandId);
                        let bandContent = null;
                        const bandType = bandNode.get('bandType');
                        if (map.has('bands/' + bandId)) {
                            //inside the map there are all the direct child of the band
                            bandContent = map.get('bands/' + bandId).map(mapBandChildren);
                        }
                        groupFootersElements.push(
                            <OutlineElement key={bandId}
                                id={bandId}
                                type={ElementTypes.BAND}
                                label={`${i18n.t('band.groupFooter')} ${groupName} ${index > 0 ? index + 1 : ''}`}
                                bandType={bandType}
                                path={['bands', bandId]}
                                onClick={this.onClick}
                                onDoubleClick={this.onDobuleClick}
                                isSelected={isBandSelected}
                                contextualActionSelected={this.contextualActionSelected}>
                                {bandContent}
                            </OutlineElement>)
                    });
                } else {
                    const label = `${i18n.t('band.groupFooter')} ${groupName}`;
                    groupFootersElements.push(getEmptyGroupBand('emptyGroupFooter', label, groupName))
                }
            })
        }
        return [title, pageHeader, columnHeader, ...groupHeadersElements, ...details, ...groupFootersElements, columnFooter, pageFooter, lastPageFooter, summary, noData, background];
    }

    createBookRoot = () => {
        const groups = this.props.report.getIn(['model', 'groups']) as ImmutableList<ImmutableMap<string, any>>;
        const components = [];
        if (groups) {
            groups.forEach((group) => {
                const groupName = group.get('name');
                const groupHeaderParts = this.props.report.getIn(['model', 'bookSections', 'groupHeaderSection_' + groupName]) as ImmutableMap<string, any>;
                const isSelected = this.props.selection.has(groupName + 'HeaderSection');
                if (groupHeaderParts) {
                    const partsIds = groupHeaderParts.get('elementIds');
                    const nodeContent = [];
                    if (partsIds) {
                        partsIds.map((partId: string) => {
                            const part = this.props.report.getIn(['model', 'elements', partId]) as ImmutableMap<string, any>;
                            const partExpression = part.get('partNameExpression', part.get('subreportExpression', 'Book Part'));
                            nodeContent.push(
                                <OutlineElement id={partId}
                                    key={partId}
                                    label={partExpression}
                                    type={ElementTypes.PART}
                                    onClick={this.onClick}
                                    onDoubleClick={this.onDobuleClick}
                                    path={['elements', partId]}
                                    isSelected={this.props.selection.has(partId)}
                                    contextualActionSelected={this.contextualActionSelected}>
                                </OutlineElement>
                            )
                        });
                    }
                    components.push(
                        <OutlineElement id={groupName + 'HeaderSection'}
                            key={groupName + 'Header'}
                            label={`Group Header ${groupName}`}
                            type={ElementTypes.SECTION_GROUP}
                            onClick={this.onBookClick}
                            onDoubleClick={this.onDobuleClick}
                            path={['bookSections', 'groupHeaderSection_' + groupName]}
                            isSelected={isSelected}
                            contextualActionSelected={this.contextualActionSelected}>
                            {nodeContent}
                        </OutlineElement>
                    );
                } else {
                    components.push(
                        <OutlineElement id={groupName + 'HeaderSection'}
                            key={groupName + 'Header'}
                            label={`Group Header ${groupName}`}
                            type={ElementTypes.SECTION_GROUP}
                            onClick={this.onBookClick}
                            onDoubleClick={this.onDobuleClick}
                            path={['bookSections', 'groupHeaderSection_' + groupName]}
                            isSelected={isSelected}
                            contextualActionSelected={this.contextualActionSelected} />
                    );
                }
            });
        }
        const detailSection = this.props.report.getIn(['model', 'bookSections', 'detailSection']) as ImmutableMap<string, any>;
        if (detailSection) {
            const partsIds = detailSection.get('elementIds');
            const nodeContent = [];
            partsIds.map((partId: string) => {
                const part = this.props.report.getIn(['model', 'elements', partId]) as ImmutableMap<string, any>;
                nodeContent.push(
                    <OutlineElement id={partId}
                        key={partId}
                        label={part.get('partNameExpression', part.get('subreportExpression', 'Book Part'))}
                        type={ElementTypes.PART}
                        onClick={this.onClick}
                        onDoubleClick={this.onDobuleClick}
                        path={['elements', partId]}
                        isSelected={this.props.selection.has(partId)}
                        contextualActionSelected={this.contextualActionSelected}>
                    </OutlineElement>
                )
            });
            const isSelected = this.props.selection.has('detailSection');
            components.push(
                <OutlineElement id={'detailSection'}
                    key={'detailSection'}
                    label={'Detail'}
                    type={ElementTypes.SECTION_DETAIL}
                    onClick={this.onBookClick}
                    onDoubleClick={this.onDobuleClick}
                    path={['bookSections', 'detailSection']}
                    isSelected={isSelected}
                    contextualActionSelected={this.contextualActionSelected}>
                    {nodeContent}
                </OutlineElement>
            );
        }
        if (groups) {
            groups.forEach((group) => {
                const groupName = group.get('name');
                const groupFooterParts = this.props.report.getIn(['model', 'bookSections', 'groupFooterSection_' + groupName]) as ImmutableMap<string, any>;
                const isSelected = this.props.selection.has(groupName + 'FooterSection');
                if (groupFooterParts) {
                    const partsIds = groupFooterParts.get('elementIds');
                    const nodeContent = [];
                    if (partsIds) {
                        partsIds.map((partId: string) => {
                            const part = this.props.report.getIn(['model', 'elements', partId]) as ImmutableMap<string, any>;
                            nodeContent.push(
                                <OutlineElement id={partId}
                                    key={partId}
                                    label={part.get('partNameExpression', part.get('subreportExpression', 'Book Part'))}
                                    type={ElementTypes.PART}
                                    onClick={this.onClick}
                                    onDoubleClick={this.onDobuleClick}
                                    path={['elements', partId]}
                                    isSelected={this.props.selection.has(partId)}
                                    contextualActionSelected={this.contextualActionSelected}>
                                </OutlineElement>
                            )
                        });
                    }
                    components.push(
                        <OutlineElement id={groupName + 'FooterSection'}
                            key={groupName + 'Footer'}
                            label={`Group Footer ${groupName}`}
                            type={ElementTypes.SECTION_GROUP}
                            onClick={this.onBookClick}
                            onDoubleClick={this.onDobuleClick}
                            path={['bookSections', 'groupFooterSection_' + groupName]}
                            isSelected={isSelected}
                            contextualActionSelected={this.contextualActionSelected}>
                            {nodeContent}
                        </OutlineElement>
                    );
                } else {
                    components.push(
                        <OutlineElement id={groupName + 'FooterSection'}
                            key={groupName + 'Footer'}
                            label={`Group Footer ${groupName}`}
                            type={ElementTypes.SECTION_GROUP}
                            onClick={this.onBookClick}
                            onDoubleClick={this.onDobuleClick}
                            path={['bookSections', 'groupFooterSection_' + groupName]}
                            isSelected={isSelected}
                            contextualActionSelected={this.contextualActionSelected} />
                    );
                }
            });
        }
        return components;
    }

    private getCollapseIcon = () => {
        return <svg width="10px" height="12px" viewBox="0 0 10 12">
            <g id="chevron_down_theme_6" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M2.44264069,1.24264069 L2.44264069,6.04264069 L7.24264069,6.04264069 L7.24264069,7.24264069 L2.44264069,7.24264069 L2.44264069,7.24264069 L1.24264069,7.24264069 L1.24264069,1.24264069 L2.44264069,1.24264069 Z" id="Combined-Shape" fill="#055DAB" transform="translate(4.242641, 4.242641) rotate(-45.000000) translate(-4.242641, -4.242641) ">
                </path>
            </g>
        </svg>;
    }

    private getExpandIcon = () => {
        return <svg width="10px" height="12px" viewBox="0 0 10 12"><g id="chevron_right_theme_6" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M6.24264069,8.04264069 L6.24264069,9.24264069 L1.44264069,9.24264069 L1.44264069,8.04264069 L6.24264069,8.04264069 Z M1.44264069,3.24264069 L1.44264069,9.24264069 L0.242640687,9.24264069 L0.242640687,3.24264069 L1.44264069,3.24264069 Z" id="Combined-Shape" fill="#055DAB" transform="translate(3.242641, 6.242641) scale(-1, 1) rotate(45.000000) translate(-3.242641, -6.242641) ">
            </path>
        </g>
        </svg>;
    }

    private onNodeToggle = (event: React.ChangeEvent, nodeIds: string[]) => {
        this.props.expandOutlineElements(nodeIds);
    };


    public render() {

        if (!this.props.model) {
            return null;
        }
        let root;
        let reportName = this.props.model.get('name', 'Blank');
        if (this.props.openDescriptor && this.props.openDescriptor.name){
            const name = this.props.openDescriptor.name;
            reportName = name.toLowerCase().endsWith('.jrxml') ? name.substring(0, name.length - 6) : name;
        }
        const isRootSelected = this.props.selection.has('/');
        if (this.isBookReport()) {
            root = <OutlineElement key={'/'}
                id={'/'}
                type={ElementTypes.BOOK}
                label={reportName}
                path={[]}
                onClick={this.onClick}
                onDoubleClick={this.onDobuleClick}
                isSelected={isRootSelected}
                contextualActionSelected={this.contextualActionSelected}>
                {/*this.getStyles()*/}
                {/*this.getParameters()*/}
                {/*this.getFields()*/}
                {/*this.getSortFields()*/}
                {/*this.getVariables()*/}
                {/*this.getScriptlets()*/}
                {/*this.getSubdatasets()*/}
                {this.createBookRoot()}
            </OutlineElement>
        } else {
            const elements = this.props.model.get('elements');
            const map: Map<string, any> = new Map();
            //map contains all the containers
            elements.forEach((element: Map<string, any>) => {
                if (map.has(element.get('path'))) {
                    map.get(element.get('path')).push(element);
                } else {
                    map.set(element.get('path'), [element]);
                }
            });
            if (this.props.currentEditorIndex === undefined || this.props.currentEditorIndex < 0) {
                //main editor    
                root = <OutlineElement key={'/'}
                    id={'/'}
                    type={ElementTypes.REPORT}
                    label={reportName}
                    path={[]}
                    onClick={this.onClick}
                    onDoubleClick={this.onDobuleClick}
                    isSelected={isRootSelected}
                    contextualActionSelected={this.contextualActionSelected}>
                    {/*this.getStyles()*/}
                    {/*this.getParameters()*/}
                    {/*this.getFields()*/}
                    {/*this.getSortFields()*/}
                    {/*this.getVariables()*/}
                    {/*this.getScriptlets()*/}
                    {/*this.getSubdatasets()*/}
                    {this.getBands(map)}
                </OutlineElement>
            } else {
                //subeditor
                const currentEditor = this.props.subeditors.get(this.props.currentEditorIndex);
                const editorType = currentEditor.get('type');
                if (editorType === ElementTypes.TABLE) {
                    //table editor
                    const tablePath = currentEditor.get('editedResourceId');
                    const tableElement = this.props.model.getIn(tablePath.split('/')) as ImmutableMap<string, any>;
                    root = this.getTableContent(map, tableElement);
                } else if (editorType === ElementTypes.LIST) {
                    //list editor
                    const listPath = currentEditor.get('editedResourceId');
                    const listElement = this.props.model.getIn(listPath.split('/')) as ImmutableMap<string, any>;
                    root = this.getListContent(map, listElement);
                } else if (editorType === ElementTypes.CROSSTAB) {
                    //crosstab editor
                    const crosstabPath = currentEditor.get('editedResourceId');
                    const crosstabElement = this.props.model.getIn(crosstabPath.split('/')) as ImmutableMap<string, any>;
                    root = this.getCrosstabContent(map, crosstabElement);
                }
            }
        }
        return (
            <div className="tc-jsw-report-outline">
                <TreeView
                    defaultCollapseIcon={this.getCollapseIcon()}
                    defaultExpandIcon={this.getExpandIcon()}
                    multiSelect={true}
                    expanded={this.props.outlineExpandedNodes}
                    onNodeToggle={this.onNodeToggle}
                    className="tc-jsw-report-outline-label jr-mTree jr-mTreeOutline mui"
                >
                    {root}
                </TreeView>
            </div>
        );
    }

    private onClick = (e: React.MouseEvent<HTMLElement>, id: string, path: string[], type: string, allowRightClick = false) => {

        if (!this.props.model || (e.button !== 0 && !allowRightClick) || !this.props.elementClicked) {
            return;
        }
        this.props.elementClicked(id, { path, type }, { metaKey: e.metaKey, shiftKey: e.shiftKey });
    }


    private onBookClick = (e: React.MouseEvent<HTMLElement>, id: string, path: string[], type: string, allowRightClick = false) => {

        if (!this.props.model || (e.button !== 0 && !allowRightClick) || !this.props.elementClicked) {
            return;
        }
        this.props.elementClicked(id, { path, type }, { metaKey: e.metaKey, shiftKey: e.shiftKey });
    }

    private onDobuleClick = (e: React.MouseEvent<HTMLElement>, id: string, path: string[], type: string) => {

        if (!this.props.model || e.button !== 0 || !this.props.elementClicked) {
            return;
        }
        if (type === ElementTypes.TABLE) {
            //always call the create editor, if it is already existing it will be just selected
            const tablePath = path.join('/');
            this.props.createEditor(ElementTypes.TABLE, 'Table', true, tablePath, true, {});
        } else if (type === ElementTypes.LIST) {
            //always call the create editor, if it is already existing it will be just selected
            const listPath = path.join('/');
            this.props.createEditor(ElementTypes.LIST, 'List', true, listPath, true, {});
        } else if (type === ElementTypes.CROSSTAB) {
            //always call the create editor, if it is already existing it will be just selected
            const crosstabPath = path.join('/');
            this.props.createEditor(ElementTypes.CROSSTAB, 'Crosstab', true, crosstabPath, true, {});
        }
    }

    private contextualActionSelected = (actionId: string, targetElementId: string, targetElementType: string, targetElementPath: string[], data?: { [key: string]: any }) => {
        if (actionId === ACTION_IDS.CREATE_BAND_ACTION_ID) {
            if (targetElementType === ElementTypes.BAND_PLACEHOLDER) {
                let band;
                if (targetElementId === 'emptyTitle') {
                    band = createBand('title', BandTypes.BAND_TITLE, 50);
                } else if (targetElementId === 'emptyPageHeader') {
                    band = createBand('pageHeader', BandTypes.BAND_PAGE_HEADER, 50);
                } else if (targetElementId === 'emptyColumnHeader') {
                    band = createBand('columnHeader', BandTypes.BAND_COLUMN_HEADER, 50);
                } else if (targetElementId === 'emptyDetail') {
                    const detailBands: ImmutableList<string> = this.props.model.get('detailsOrder');
                    let startingIndex = detailBands.size - 1;
                    let availableName = false;
                    let detailId;
                    while (!availableName) {
                        startingIndex++;
                        detailId = 'detail_' + startingIndex;
                        availableName = !detailBands.find((currentDetailId) => {
                            return currentDetailId === detailId;
                        });
                    }
                    band = createBand(detailId, BandTypes.BAND_DETAIL, 50);
                } else if (targetElementId === 'emptyColumnFooter') {
                    band = createBand('columnFooter', BandTypes.BAND_COLUMN_FOOTER, 50);
                } else if (targetElementId === 'emptyPageFooter') {
                    band = createBand('pageFooter', BandTypes.BAND_PAGE_FOOTER, 50);
                } else if (targetElementId === 'emptyLastPageFooter') {
                    band = createBand('lastPageFooter', BandTypes.BAND_LAST_PAGE_FOOTER, 50);
                } else if (targetElementId === 'emptySummary') {
                    band = createBand('summary', BandTypes.BAND_SUMMARY, 50);
                } else if (targetElementId === 'emptyNoData') {
                    band = createBand('noData', BandTypes.BAND_NO_DATA, 50);
                } else if (targetElementId === 'emptyBackground') {
                    band = createBand('background', BandTypes.BAND_BACKGROUND, 50);
                } else if (targetElementId === 'emptyGroupHeader') {
                    const groupName = data.groupName;
                    const groupId = 'groupHeader_' + groupName + '_' + 0;
                    band = createBand(groupId, BandTypes.BAND_GROUP_HEADER, 50);
                    band = band.set('groupName', groupName);
                } else if (targetElementId === 'emptyGroupFooter') {
                    const groupName = data.groupName;
                    const groupId = 'groupFooter_' + groupName + '_' + 0;
                    band = createBand(groupId, BandTypes.BAND_GROUP_FOOTER, 50);
                    band = band.set('groupName', groupName);
                }
                if (band) {
                    this.props.createNewBand(band);
                }
            }
        } else if (actionId === ACTION_IDS.CREATE_DETAIL_BAND) {
            const bandSelected = this.props.model.getIn(targetElementPath) as ImmutableMap<string, any>;
            const detailsOrder = this.props.model.get('detailsOrder') as ImmutableList<string>;
            const index = detailsOrder.findIndex((currentElement) => {
                return currentElement === bandSelected.get('id');
            })
            let startingIndex = detailsOrder.size - 1;
            let availableName = false;
            let detailId;
            while (!availableName) {
                startingIndex++;
                detailId = 'detail_' + startingIndex;
                availableName = !detailsOrder.find((currentDetailId) => {
                    return currentDetailId === detailId;
                });
            }
            const band = createBand(detailId, BandTypes.BAND_DETAIL, 50);
            this.props.createNewBand(band, index + 1);
        } else if (actionId === ACTION_IDS.CREATE_GROUP_HEADER_BAND) {
            const bandSelected = this.props.model.getIn(targetElementPath) as ImmutableMap<string, any>;
            const groupName = bandSelected.get('groupName');
            const groupHeadersOrder = this.props.model.get('groupHeadersOrder') as ImmutableList<string>;
            const index = groupHeadersOrder.findIndex((currentElement) => {
                return currentElement === bandSelected.get('id');
            })
            let startingIndex = groupHeadersOrder.size - 1;
            let availableName = false;
            let groupId;
            while (!availableName) {
                startingIndex++;
                groupId = 'groupHeader_' + groupName + '_' + startingIndex;
                availableName = !groupHeadersOrder.find((currentGroupdId) => {
                    return currentGroupdId === groupId;
                });
            }
            let band = createBand(groupId, BandTypes.BAND_GROUP_HEADER, 50);
            band = band.set('groupName', groupName);
            this.props.createNewBand(band, index + 1);
        } else if (actionId === ACTION_IDS.CREATE_GROUP_FOOTER_BAND) {
            const bandSelected = this.props.model.getIn(targetElementPath) as ImmutableMap<string, any>;
            const groupName = bandSelected.get('groupName');
            const groupFootersOrder = this.props.model.get('groupFootersOrder') as ImmutableList<string>;
            const index = groupFootersOrder.findIndex((currentElement) => {
                return currentElement === bandSelected.get('id');
            })
            let startingIndex = groupFootersOrder.size - 1;
            let availableName = false;
            let groupId;
            while (!availableName) {
                startingIndex++;
                groupId = 'groupFooter_' + groupName + '_' + startingIndex;
                availableName = !groupFootersOrder.find((currentGroupdId) => {
                    return currentGroupdId === groupId;
                });
            }
            let band = createBand(groupId, BandTypes.BAND_GROUP_FOOTER, 50);
            band = band.set('groupName', groupName);
            this.props.createNewBand(band, index + 1);
        } else if (actionId === ACTION_IDS.DELETE_BAND_ACTION_ID) {
            this.props.deleteVisualElements([targetElementPath])
        } else if (actionId === ACTION_IDS.CREATE_PART_ACTION_ID) {
            const newElement = ImmutableMap(initializeMapWithObject({
                type: ElementTypes.PART,
                partNameExpression: 'CHANGE ME',
                uuid: uuidv4(),
            }));
            if (targetElementType === ElementTypes.SECTION_DETAIL) {
                this.props.addElement(newElement, 'bookSections/detailSection');
            } else if (targetElementType === ElementTypes.SECTION_GROUP) {
                this.props.createNewPart(targetElementPath, newElement);
            }
        } else if (actionId === ACTION_IDS.DELETE_PART_ACTION_ID) {
            this.props.deleteVisualElements([targetElementPath]);
        }
    }
}

const mapStateToProps = (state: IState) => {
    return {
        report: state.getIn(['report']),
        selection: state.getIn(['report', 'selection']),
        outlineExpandedNodes: state.getIn(['report', 'outlineExpandedNodes']),
        model: state.getIn(['report', 'model']),
        modelActions: state.getIn(['report', 'modelActions']),
        subeditors: state.getIn(['report', 'subeditors']),
        openDescriptor: state.getIn(['report', 'fileDescriptor']),
        currentEditorIndex: state.getIn(['report', 'currentEditorIndex']),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        elementClicked: (id: string, info: { path: string[], type: string }, options: { metaKey: boolean, shiftKey: boolean, touch?: boolean }) => {
            dispatch(ReportActions.selectElement(id, info, options.shiftKey === true));
        },
        createEditor: (editorType: string, editorLabel: string, isSelectable: boolean, editedResourceId: string, setActive: boolean, params: Record<string, unknown>) => {
            dispatch(ReportActions.createEditor(editorType, editorLabel, isSelectable, editedResourceId, setActive, params));
        },
        setObjectProperties: (path: string[], properties: any) => {
            dispatch(ReportActions.setObjectProperties(path, properties));
        },
        addElement: (newElement: ImmutableMap<string, any>, path: string) => {
            dispatch(ReportActions.addElement(newElement, path));
        },
        addElements: (newElements: { path: string, element: ImmutableMap<string, any> }[]) => {
            dispatch(ReportActions.addElements(newElements));
        },
        createNewBand: (band: ImmutableMap<string, any>, index = 0) => {
            dispatch(ReportActions.createNewBand(band, index));
        },
        createNewPart: (path: string[], part: ImmutableMap<string, any>) => {
            dispatch(ReportActions.createNewPart(path, part));
        },
        expandOutlineElements: (elements: string[]) => {
            dispatch(ReportActions.expandOutlineElements(elements));
        },
        deleteVisualElements: (paths: string[][]) => { dispatch(ReportActions.deleteVisualElements(paths)); },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Outline);
