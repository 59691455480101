/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Conf } from "@jss/js-rest-api";
import { writeXML } from "./HTTPConfigurationPanel";

/**
 * The generic configuration of a Data Adapter is essentially an map plus a name.
 *
 * @export
 * @interface IDataAdapterConfig
 */
export interface IDataAdapterConfig {
    name: string;
    class: string; // The class name which implements this data adapter
    [key: string]: any;
}

export const getProlog = () => {
    const config = Conf.get('jrws.jrio.serverInfo');
    const version = config && config.version ? `version ${config.version}` : '';
    return `<?xml version="1.0" encoding="UTF-8"?>\n<!-- Created with Jaspersoft WebStudio${version} -->`;
}

/**
 *
 *
 * @interface IDataAdapterDescriptor
 */
export interface IDataAdapterDescriptor {

    getMime: () => string;

    getDataAdapterRootName: () => string;

    getIcon?: () => JSX.Element;

    /**
     *  Return the canonical name of the class which impements this data adapter in Java.
     *  net.sf.jasperreports.data.csv.CsvDataAdapter
     */
    getDataAdapterClass: () => string;


    /**
     *  Return the name of this data adapter to be presented to the user
     *  i.e. CSV File
     */
    getDataAdapterLabel: () => string;

    /**
    *  Return the name of this data adapter to be presented to the user
    *  i.e. CSV File
    */
    getDataAdapterDescription: () => string;

    /**
     * Return a plain json object with the default data adapter configuration.
     * It can be just an empty object.
     *
     * @memberof IDataAdapterDescriptor
     */
    initializeDataAdapterConfig: () => IDataAdapterConfig;

    /**
     * Given a data adapter configuration, this methos returns null or undefined if
     * the configuration is valid, otherwise it provides an error.
     *
     * @memberof IDataAdapterDescriptor
     */
    validateDataAdapterConfig: (config: IDataAdapterConfig) => string | undefined;

    /**
     *  Return an xml representation of this data adapter
     */
    toXml: (config: any) => string;

    /**
     *  Return a json representation of this data adapter
     */
    toJson: (xml: Document) => any;

    /**
     * Return a list of languages that can be used to extract the data from this data adapter
     */
    getLanguages: () => string[];

    /**
     * Given a data adapter configuration, this methos returns null or undefined if
     * the configuration is valid, otherwise it provides an error.
     *
     * @memberof IDataAdapterDescriptor
     */
    createEditor: (config: IDataAdapterConfig,
        onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => string | JSX.Element | null;

}

export const createNode = (xmlDoc: Document, config: IDataAdapterConfig, fieldName: string, tagName: string, attributes?: { name: string, value: string }[]) => {
    const data = config[fieldName];
    if (data !== undefined) {
        const newEle = xmlDoc.createElement(tagName);
        const newText = xmlDoc.createTextNode(data);
        newEle.appendChild(newText);
        if (attributes) {
            attributes.forEach((attribute) => {
                newEle.setAttribute(attribute.name, attribute.value);
            });
        }
        return newEle;
    }
    return undefined;
}

export const createNodeWithValue = (xmlDoc: Document, config: IDataAdapterConfig, tagName: string, value: string) => {
    if (value !== undefined) {
        const newEle = xmlDoc.createElement(tagName);
        const newText = xmlDoc.createTextNode(value);
        newEle.appendChild(newText);
        return newEle;
    }
    return undefined;
}

export const setFileDataAdapterAttributes = (xmlDoc: Document, config: IDataAdapterConfig, root: Element) => {

    const locationValue = config['location'];

    if (locationValue && (locationValue.startsWith('http://') || locationValue.startsWith('https://'))) {
        const dataFileNode = xmlDoc.createElement('dataFile');
        dataFileNode.setAttribute('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance');
        dataFileNode.setAttribute('xsi:type', 'httpDataLocation');
        const locationNode = xmlDoc.createElement('url');
        const locationValueNode = xmlDoc.createTextNode(locationValue);
        locationNode.appendChild(locationValueNode);
        dataFileNode.appendChild(locationNode);
        writeXML(xmlDoc, config, dataFileNode);
        root.appendChild(dataFileNode);
    } else {
        const dataFileNode = xmlDoc.createElement('dataFile');
        dataFileNode.setAttribute('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance');
        dataFileNode.setAttribute('xsi:type', 'repositoryDataLocation');
        const locationNode = xmlDoc.createElement('location');
        const locationValueNode = xmlDoc.createTextNode(locationValue);
        locationNode.appendChild(locationValueNode);
        dataFileNode.appendChild(locationNode);
        root.appendChild(dataFileNode);
    }

    const localeTimezoneAttributes = [
        {
            name: 'xmlns:xsi',
            value: 'http://www.w3.org/2001/XMLSchema-instance',
        },
        {
            name: 'xmlns:java',
            value: 'http://java.sun.com',
        },
        {
            name: 'xsi:type',
            value: 'java:java.lang.String',
        },
    ];


    const locale = config['locale'];
    if (locale && locale.lenght > 0) {
        const localeNode = createNode(xmlDoc, config, 'locale', 'locale', localeTimezoneAttributes);
        if (localeNode) {
            root.appendChild(localeNode);
        }
    }

    const timezone = config['timezone'];
    if (timezone && timezone.lenght > 0) {
        const timezoneNode = createNode(xmlDoc, config, 'timezone', 'timezone', localeTimezoneAttributes);
        if (timezoneNode) {
            root.appendChild(timezoneNode);
        }
    }
    const datePattern = config['datePattern'];
    if (datePattern && datePattern.lenght > 0) {
        const datePatternNode = createNode(xmlDoc, config, 'datePattern', 'datePattern');
        if (datePatternNode) {
            root.appendChild(datePatternNode);
        }
    }

    const numberPattern = config['numberPattern'];
    if (numberPattern && numberPattern.lenght > 0) {
        const numberPatternNode = createNode(xmlDoc, config, 'numberPattern', 'numberPattern');
        if (numberPatternNode) {
            root.appendChild(numberPatternNode);
        }
    }
}

export const getChilNodeByName = (parent: ChildNode, name: string) => {
    let node;
    for (let i = 0; i < parent.childNodes.length && !node; i++) {
        const currentNode = parent.childNodes.item(i);
        if (currentNode.nodeName === name) {
            node = currentNode;
        }
    }
    return node;
}