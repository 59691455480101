/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Map } from 'immutable';

export function isNullOrWhiteSpace(value: string | null) {
  return value === null || value === undefined || value.replace(/\s/g, '').length === 0;
}


export function isNullOrUndefined(value: any | undefined) {
  return value === null || value === undefined;
}
/**
 * Return default if value is null or undefined
 * @param value 
 * @param def 
 */
export function nv(value: any | undefined, def: any): any {
  if (value == null || value == undefined)
    return def;
  return value;
}

export const initializeMapWithObject = (object: any): Map<string, any> => {
  return Map<string, any>(Object.keys(object).map<[string, any]>(key => [key, object[key]]));
}


/**
 * 
 * @param path 
 * @param file 
 */
export const buildPath = (path: string, file: string) => {
  if (path !== undefined && !path.endsWith('/')) {
    path += '/';
  }
  while (file.startsWith('/')) {
    file = file.substring(0);
  }
  return path !== undefined ? path + file : file;
}