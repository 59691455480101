/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IRepositoryItemDescriptor, RESOURCE_TYPE } from "../repo/IRepositoryItemDescriptor";
import { ResourceFieldREST } from "./ResourceFieldREST";

export class ResourceFieldGithub extends ResourceFieldREST {

    public getContainerLenght() {
        return 3;
    }

    public isEditable(res: IRepositoryItemDescriptor, name: string) {
        switch (name) {
            case 'name': return res.type !== RESOURCE_TYPE.FOLDER && super.isEditable(res, name);
            case 'description': return false;
        }
        return super.isEditable(res, name);
    }
    public isAvailable(res: IRepositoryItemDescriptor, name: string) {
        switch (name) {
            case 'description':
            case 'lastModified':
            case 'created':
            case 'owner':
                return res.mime !== undefined && ['github/repository'].includes(res.mime);
            case 'permissionMask': return false;
        }
        return super.isAvailable(res, name);
    }
    public isAvailable4Children(res: IRepositoryItemDescriptor, name: string) {
        switch (name) {
            case 'description':
            case 'lastModified':
            case 'created':
            case 'owner':
                return res.type === RESOURCE_TYPE.FOLDER && !res.mime && !res.name;
        }
        return this.isAvailable(res, name);
    }

}