/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { MBarbecue } from "./MBarbecue";

export const MBbcEAN13: APDescriptor = {
    ...MBarbecue, id: 'EAN13', label: 'EAN13', icon: ICONS.BARCODE_EAN13_ICON,
    default: { ...MBarbecue.default, barbecue_type: 'EAN13' }
};
