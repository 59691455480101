/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { MGenericElement } from '../properties/types/elements/MGenericElement';
interface IGenericElement {
  id: string;
  element?: any;
  zoom?: number;
  availableWidth: number,
  availableHeight: number,
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GenericElement = (props: IGenericElement) => {

    // The actual content of the frame is rendered at band level.
    // Essentially a frame is just a logical component with nothing to do..
    return <div style={{ width: '100%', height: '100%'}}>
      <div style={{paddingTop: 5, paddingLeft: 5}}>
        {MGenericElement.icon}
      </div>
    </div>;
}

export default GenericElement;