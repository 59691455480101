
/*
* Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
* Licensed under commercial Jaspersoft Subscription License Agreement
*/

import { List } from 'immutable';
import _ from 'lodash';
import * as React from 'react';
import * as ReportActions from '../../../../../actions/reportActions';
import { getPath, reg, UiProperty } from '../../ui/UiProperty';

import { connect } from 'react-redux';
import i18n from '../../../../../i18n';
import { PTable, UPTable } from './UPTable';


const TYPEID = 'stringTable';
export class PStringTable extends PTable {
    public constructor(init: Partial<PStringTable>) {
        super(init);
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPTable mcontext={mc} />; });



class UPStringTable extends UPTable {
    public renderFields = () => {
        const d = this.props.mcontext.descriptor as PTable;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p) as List<string> | undefined;
        if (!v) {
            return <div style={{ textAlign: 'center', color: 'lightgray' }}>{i18n.t('tableElement.novalues')}</div>;
        }

        const fields: List<string> = v;
        if (fields.size < 1) {
            return <div style={{ textAlign: 'center', color: 'lightgray' }}>{i18n.t('tableElement.novalues')}</div>;
        }
        const tmp = [];
        let filteredFields = fields;
        if (this.state.search && this.state.search.length > 0) {
            filteredFields = fields.filter((field) => {
                return field.toLowerCase().includes(this.state.search)
            })
        }

        filteredFields.map((f: string, index) => {
            if (f !== undefined) {
                //use always the index as key
                const fieldId = 'f' + index;
                const els = _.cloneDeep(this.props.mcontext.elements);
                const dl = d.layouts[0];
                els.forEach(k => { k.path = [...p]; k.path.push(index) });
                const mc = { ...this.props.mcontext, descriptor: { ...dl }, elements: els };
                tmp.push(<div key={'div.' + fieldId} style={{ display: 'grid', gridTemplateColumns: '1fr 23px' }}>
                    <UiProperty key={fieldId} mcontext={mc} /> {mc.descriptor.readonly ? <></> : this.renderDelete(index)}
                </div>);
            }
        })
        return tmp;
    }

    public addItem = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expanded'], true, false, true);

        const d = this.props.mcontext.descriptor as PTable;
        const p: string[] = ['model', ...getPath(d.id, this.props.mcontext.elements[0].path)];
        this.props.addProperty(p, d.default ? d.default : 'New Value');
    }
}


const mapStateToProps = () => {
    return {};
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteElement: (path: string[]) => { dispatch(ReportActions.deleteElement(path)); },
        addProperty: (path: string[], element) => { dispatch(ReportActions.addProperty(path, element)); },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UPStringTable);
