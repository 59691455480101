/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

/* eslint-disable react/display-name */
import { Icon, IconButton, IMenuItem, IToolBarItemOptions, MenuDivider, TextField, Typography, ToolBar, ToolButton } from '@jss/js-common';
import { RunContext } from '@jss/js-repository';
import { MenuItem, Popover } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import '../../../assets/uxpl/css/Toolbar.css';
import { ViewerStoreContext } from './ViewerStore';
import i18n from "../../../i18n";
import { ReportParts } from "./ReportParts";
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import _ from "lodash";
import { SearchControl } from "./search/SearchControl";

const exportOptions = [
    {
        key: "components.report.jrxmlviewer.export.pdf",
        val: "pdf",
    },
    {
        key: "components.report.jrxmlviewer.export.docx",
        val: "docx",
    },
    {
        key: "components.report.jrxmlviewer.export.pptx",
        val: "pptx",
    },
    {
        key: "components.report.jrxmlviewer.export.xlsx",
        val: "xlsx",
    },
    {
        key: "components.report.jrxmlviewer.export.data_xlsx",
        val: "data_xlsx",
    },
    {
        key: "components.report.jrxmlviewer.export.ods",
        val: "ods",
    },
    {
        key: "components.report.jrxmlviewer.export.odt",
        val: "odt",
    },
    {
        key: "components.report.jrxmlviewer.export.csv",
        val: "csv",
    },
    {
        key: "components.report.jrxmlviewer.export.data_csv",
        val: "data_csv",
    },
    
    {
        key: "components.report.jrxmlviewer.export.rtf",
        val: "rtf",
    },
];

const PAGINATION_CONTROLS_DEBOUNCE = 300;

interface IViewerToolbar {
    exitEditorAction: () => void;
    runReportAction: () => void;
}

export const ViewerToolbar: React.FunctionComponent<IViewerToolbar> = ({ exitEditorAction, runReportAction }) => {
    const runContext = useContext(RunContext);
    const viewerStore = useContext(ViewerStoreContext);
    const [currentPage, setCurrentPage] = useState<string>(viewerStore.currentPage.toString());
    const [zoomAnchorEl, setZoomAnchorEl] = useState<HTMLElement>(null);

    useEffect(
        () => {
            setCurrentPage(viewerStore.currentPage.toString());
        }, [viewerStore]);

    const zoomOptionsMenuItemClick = useCallback(
        (zoomScale: number | string) => {
            viewerStore.zoomTo(zoomScale);
            setZoomAnchorEl(null);
        }, [viewerStore]);

    const exportMenuItemClick = useCallback(
        async (exportFormat: string) => {
            try {
                const exportLink = await viewerStore.exportTo(exportFormat);
                window.open(exportLink);
            } catch (e) {
                runContext.handleError(e);
            }
        }, [viewerStore, runContext]
    );

    const onCurrentPageChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            if (value.length === 0 || /^\d+$/.test(value)) {
                setCurrentPage(value);
            }
        }, []);


    const goToPage = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            const key = event.key;
            if (key === "Enter" && currentPage.length) {
                viewerStore.goToPage(parseInt(currentPage, 10));
            }
        }, [viewerStore, currentPage]);

    const onReportPartClick = useCallback(
        (page: number) => {
            viewerStore.goToPage(page);
        }, [viewerStore]);

    const getZoomMenu = () => {
        const setZoomLevel = (value) => {
            setZoomAnchorEl(null);
            zoomOptionsMenuItemClick(value);
        }
        const fitPageWidth = () => {
            setZoomAnchorEl(null);
            zoomOptionsMenuItemClick('width');
        }
        const fitPageHeight = () => {
            setZoomAnchorEl(null);
            zoomOptionsMenuItemClick('height');
        }
        const fitPage = () => {
            setZoomAnchorEl(null);
            zoomOptionsMenuItemClick('container');
        }
        return (
            <div>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(1.0)}>{i18n.t('reportdesigner.menu.resetzoom')}</MenuItem>
                <MenuDivider />
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(0.2)}>20%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(0.4)}>40%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(0.6)}>60%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(0.8)}>80%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(1.0)}>100%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(1.25)}>125%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(1.50)}>150%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(2.0)}>200%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(3.0)}>300%</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={() => setZoomLevel(4.0)}>400%</MenuItem>
                <MenuDivider />
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={fitPageWidth}>{i18n.t("viewer.zoom.fitWidth")}</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={fitPageHeight}>{i18n.t("viewer.zoom.fitHeight")}</MenuItem>
                <MenuItem className={"jr-mMenu-list-item mui"} onClick={fitPage}>{i18n.t("viewer.zoom.fitPage")}</MenuItem>
            </div>
        );
    }

    const onZoomFocusLost = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, setText: (value) => void) => {
        const isReportNotLoaded = !viewerStore.hasPages() || viewerStore.reportError !== null;
        if (!isReportNotLoaded) {
            let text = event.target.value;
            if (text.endsWith('%')) {
                text = text.substring(0, text.length - 1);
            }
            let zoom = parseInt(text, 10);
            if (!isNaN(zoom)) {
                if (zoom < 10) {
                    zoom = 10;
                }
                if (zoom > 500) {
                    zoom = 500;
                }
                const newZoom = zoom / 100.0;
                zoomOptionsMenuItemClick(newZoom);
            } else {
                setText((viewerStore.zoomScale * 100).toFixed(0) + '%');
            }
        }
    }

    const onZoomKeyDown = (e, setText: (value) => void) => {
        //disable the press of enter on the autocomplete
        if (e.key === 'Enter') {
            e.stopPropagation();
            const isReportNotLoaded = !viewerStore.hasPages() || viewerStore.reportError !== null;
            if (!isReportNotLoaded) {
                let text = e.target.value;
                if (text.endsWith('%')) {
                    text = text.substring(0, text.length - 1);
                }
                let zoom = parseInt(text, 10);
                if (!isNaN(zoom)) {
                    if (zoom < 10) {
                        zoom = 10;
                    }
                    if (zoom > 500) {
                        zoom = 500;
                    }
                    const newZoom = zoom / 100.0;
                    zoomOptionsMenuItemClick(newZoom);
                    setText((newZoom * 100).toFixed(0) + '%');
                } else {
                    setText((viewerStore.zoomScale * 100).toFixed(0) + '%');
                }
            }
        }
    }

    const toolbarData = {
        getToolItems: () => {
            const getExportSubmenu: IMenuItem[] = [];
            exportOptions.forEach(({ key, val }) => {
                getExportSubmenu.push(
                    {
                        id: key,
                        label: i18n.t(key),
                        isEnabled: viewerStore.hasPages(),
                        onClick: () => {
                            exportMenuItemClick(val);
                        }
                    },
                )
            }
            );
            return [
                {
                    id: 'exitAction',
                    render: (options?: IToolBarItemOptions | undefined) => {
                        const height = options && options.height ? options.height : 24;
                        return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div title={i18n.t('common.actions.menu.exit')} style={{ height: height, cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={exitEditorAction}>
                                <IconButton style={{ backgroundColor: 'transparent' }} icon={'linkExternal'} size="medium" />
                            </div>
                            <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                        </div>
                    }
                },
                {
                    id: 'runAction',
                    render: (options?: IToolBarItemOptions | undefined) => {
                        const height = options && options.height ? options.height : 24;
                        const isRunDisabled = viewerStore.isLoading;
                        return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div title={i18n.t('viewer.pageControl.run')} style={{ height: height, cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }}>
                                <IconButton
                                    style={{ backgroundColor: 'transparent' }}
                                    icon={'arrowRight'} size="small"
                                    disabled={isRunDisabled}
                                    onClick={runReportAction}
                                />
                            </div>
                            <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                        </div>
                    }
                },
                {
                    id: 'menuAction',
                    render: (options?: IToolBarItemOptions) => {
                        const height = options && options.height ? options.height : 24;
                        const backgroundColor = options && options.background ? options.background : undefined;
                        const isExportDisabled = viewerStore.hasError() || !viewerStore.hasPages();
                        return (
                            <ToolButton
                                label={i18n.t('viewer.pageControl.export')}
                                height={height} backgroundColor={backgroundColor}
                                isDisabled={isExportDisabled} content={isExportDisabled ? undefined : getExportSubmenu}
                                onClick={() => {
                                    if (isExportDisabled) {
                                        return false;
                                    }
                                }}
                            >
                                <Icon style={{
                                    backgroundColor: 'transparent',
                                    color: isExportDisabled ? 'lightgray' : undefined
                                }} icon={'export'} size="small" />
                            </ToolButton>
                        );
                    }
                },
                {
                    id: 'undoToolaction',
                    render: (options?: IToolBarItemOptions | undefined) => {
                        const isUndoDisabled = viewerStore.hasError() || !viewerStore.hasPages() || viewerStore.isExporting || !viewerStore.canUndo;
                        const height = options && options.height ? options.height : 24;
                        return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                            <div title={i18n.t('common.actions.menu.undo')} style={{ height: height, cursor: isUndoDisabled ? 'default' : 'pointer', justifyContent: 'center', alignItems: 'center', marginRight: 15 }} onClick={!isUndoDisabled ? () => viewerStore.undo() : undefined}>
                                <IconButton style={{ backgroundColor: 'transparent' }} icon={'undo'} size="small" disabled={isUndoDisabled} />
                            </div>
                        </div>
                    }
                },
                {
                    id: 'redoToolaction',
                    render: (options?: IToolBarItemOptions) => {
                        const height = options && options.height ? options.height : 24;
                        const isRedoDisabled = viewerStore.hasError() || !viewerStore.hasPages() || viewerStore.isExporting || !viewerStore.canRedo;
                        return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div title={i18n.t('common.actions.menu.redo')} style={{ height: height, cursor: isRedoDisabled ? 'default' : 'pointer', justifyContent: 'center', alignItems: 'center', marginRight: 15 }} onClick={!isRedoDisabled ? () => viewerStore.redo() : undefined}>
                                <IconButton icon={'redo'} size="small" disabled={isRedoDisabled} />
                            </div>
                        </div>;
                    }
                },
                {
                    id: 'undoAllToolaction',
                    render: (options?: IToolBarItemOptions) => {
                        const height = options && options.height ? options.height : 24;
                        const isUndoAllDisabled = viewerStore.hasError() || !viewerStore.hasPages() || viewerStore.isExporting || !viewerStore.canUndo;
                        return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div title={i18n.t('common.actions.menu.undoAll')} style={{ height: height, cursor: isUndoAllDisabled ? 'default' : 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={!isUndoAllDisabled ? () => viewerStore.undoAll() : undefined}>
                                <IconButton icon={'undoAll'} size="small" disabled={isUndoAllDisabled} />
                            </div>
                            <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                        </div>;
                    }
                },
                {
                    id: 'zoomControls',
                    render: (options?: IToolBarItemOptions) => {
                        const height = options && options.height ? options.height : 24;
                        const backgroundColor = options && options.background ? options.background : undefined;
                        const isReportNotLoaded = viewerStore.hasError() || !viewerStore.hasPages();
                        const zoomOutDisabled = isReportNotLoaded || viewerStore.zoomScale <= 0.1;
                        const zoomInDisabled = isReportNotLoaded || viewerStore.zoomScale >= 4;
                        return <div style={{ display: 'flex', marginRight: 10, width: 120, justifyContent: 'space-between', alignItems: 'center', background: backgroundColor }}>
                            <div style={{ width: 24, height: height, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={zoomOutDisabled ? undefined : viewerStore.zoomOut} title={i18n.t('common.actions.menu.zoomout')}>
                                <ZoomOutIcon color={zoomOutDisabled ? 'disabled' : 'action'} />
                            </div>
                            <div style={{ width: 24, height: 24, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={zoomInDisabled ? undefined : viewerStore.zoomIn} title={i18n.t('common.actions.menu.zoomin')}>
                                <ZoomInIcon color={zoomInDisabled ? 'disabled' : 'action'} />
                            </div>
                            <div style={{ height: 24 }} title={i18n.t('common.actions.menu.zoomlevel')}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'row', position: 'relative' }}>
                                    <TextField InputProps={{ readOnly: isReportNotLoaded }} onKeyDownWithSetter={onZoomKeyDown} style={{ padding: 0, margin: 0, borderWidth: 0 }} size="small" className='jr-mControlZoom mui' value={(viewerStore.zoomScale * 100).toFixed(0) + '%'} onBlurWithSetter={onZoomFocusLost} />
                                    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', position: 'absolute', right: 0, top: 0, bottom: 0, width: 30 }} onClick={(event) => {
                                        if (!isReportNotLoaded) {
                                            setZoomAnchorEl(event.currentTarget);
                                        }
                                    }}>
                                        <svg key="arrowIcon" className={`jr-MuiSvgIcon-root jr-MuiSelect-icon jr-MuiSelect-iconOutlined ${isReportNotLoaded ? 'jr-mButton jr-Mui-disabled mui' : undefined}`} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
                                    </div>
                                </div>
                                <Popover
                                    id={'zoomMenuPopover'}
                                    open={!isReportNotLoaded && Boolean(zoomAnchorEl)}
                                    anchorEl={zoomAnchorEl}
                                    onClose={() => { setZoomAnchorEl(null); }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    {getZoomMenu()}
                                </Popover>
                            </div>

                            <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                        </div>
                    }
                },
                {
                    id: 'searchControl',
                    render: (options?: IToolBarItemOptions) => {
                        const height = options && options.height ? parseInt(options.height) : 24;
                        const backgroundColor = options && options.background ? options.background : undefined;

                        return <>
                            <SearchControl height={height} backgroundColor={backgroundColor} />
                            <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                        </>
                    }
                },
                {
                    id: 'pageControl',
                    render: (options?: IToolBarItemOptions) => {
                        const height = options && options.height ? options.height : 24;
                        const backgroundColor = options && options.background ? options.background : undefined;
                        const foregroundColor = options && options.foreground ? options.foreground : '#0081cb';
                        const isNextDisabled = viewerStore.hasError() || !viewerStore.hasPages() || viewerStore.currentPage === viewerStore.totalPages || viewerStore.isPageLoading;
                        const isPreviousDisabled = viewerStore.hasError() || viewerStore.currentPage === 1 || viewerStore.isPageLoading;
                        const isPageInputDisabled = viewerStore.hasError() || viewerStore.totalPages === 0 || viewerStore.isPageLoading;
                        return <div style={{ display: 'flex', marginRight: 10, justifyContent: 'space-between', alignItems: 'center', background: backgroundColor }}>
                            <div style={{ width: 24, height: height, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} title={i18n.t('viewer.pageControl.page.firstPage')}>
                                <IconButton
                                    style={{ backgroundColor: 'transparent' }}
                                    icon={'arrowToLeft'}
                                    size="small"
                                    disabled={isPreviousDisabled}
                                    onClick={_.debounce(viewerStore.goToFirstPage, PAGINATION_CONTROLS_DEBOUNCE)}
                                />
                            </div>
                            <div style={{ width: 24, height: height, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} title={i18n.t('viewer.pageControl.page.previousPage')}>
                                <IconButton
                                    style={{ backgroundColor: 'transparent' }}
                                    icon={'arrowLeft'}
                                    size="small"
                                    disabled={isPreviousDisabled}
                                    onClick={_.debounce(viewerStore.goToPreviousPage, PAGINATION_CONTROLS_DEBOUNCE)}
                                />
                            </div>
                            <div style={{ height: height }}>
                                {
                                    viewerStore.hasPages() &&
                                    <Typography variant="body3" style={{ padding: "2px 5px 0 10px", color: foregroundColor }}>
                                        {i18n.t("viewer.pageControl.page.label")}
                                    </Typography>
                                }
                            </div>
                            <div style={{ height: 24 }}>
                                <TextField
                                    className="jrws-viewer-page-control"
                                    value={viewerStore.hasPages() ? currentPage : ''}
                                    disabled={isPageInputDisabled}
                                    onChange={onCurrentPageChange}
                                    onKeyDown={_.debounce(goToPage, PAGINATION_CONTROLS_DEBOUNCE)}
                                    title="Page"
                                />
                            </div>
                            <div style={{ height: height }}>
                                {
                                    viewerStore.hasPages() &&
                                    <Typography variant="body3" style={{ padding: "2px 10px 0 5px", color: foregroundColor }}>
                                        {`${i18n.t("viewer.pageControl.page.of")}\u00A0\u00A0\u00A0${viewerStore.totalPages}`}
                                    </Typography>
                                }
                            </div>
                            <div style={{ width: 24, height: 24, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} title={i18n.t('viewer.pageControl.page.nextPage')}>
                                <IconButton
                                    style={{ backgroundColor: 'transparent' }}
                                    icon={'arrowRight'}
                                    size="small"
                                    disabled={isNextDisabled}
                                    onClick={_.debounce(viewerStore.goToNextPage, PAGINATION_CONTROLS_DEBOUNCE)}
                                />
                            </div>
                            <div style={{ width: 24, height: 24, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} title={i18n.t('viewer.pageControl.page.lastPage')}>
                                <IconButton
                                    style={{ backgroundColor: 'transparent' }}
                                    icon={'arrowToRight'}
                                    size="small"
                                    disabled={isNextDisabled}
                                    onClick={_.debounce(viewerStore.goToLastPage, PAGINATION_CONTROLS_DEBOUNCE)}
                                />
                            </div>
                        </div>
                    }
                },
                {
                    id: 'reportPartsControl',
                    render: () => {
                        return (
                            <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                <div style={{ alignSelf: 'flex-end', marginLeft: 100, marginTop: -8, maxWidth: 450 }}>
                                    <ReportParts onItemClick={onReportPartClick} />
                                </div>
                            </div>
                        );
                    }
                }
            ];
        }
    }

    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        <ToolBar toolbarData={toolbarData} />
    </div>;
};
