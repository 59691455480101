/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const LineStyleEnum = new PEnum({
    id: 'lineStyle', label: 'LineStyle.label', inheritedPropertyId: 'lineStyle', isEmptyUndefined: true, default: 'Solid',
    items: [
        { key: '', value: '[Inherited]'},
        { key: 'Solid', value: 'LineStyle.Solid' },
        { key: 'Dashed', value: 'LineStyle.Dashed' },
        { key: 'Dotted', value: 'LineStyle.Dotted' },
        { key: 'Double', value: 'LineStyle.Double' }
    ]
});

