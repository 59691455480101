/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import CSVDataAdapterDescriptor from './csv/CSVDataAdapterDescriptor';
import ExcelDataAdapterDescriptor from './excel/ExcelDataAdapterDescriptor';
import { IDataAdapterDescriptor } from './IDataAdapterDescriptor';
import JDBCDataAdapterDescriptor from './jdbc/JDBCDataAdapterDescriptor';
import JndiDataAdapterDescriptor from './jndi/JndiDataAdapterDescriptor';
import JsonDataAdapterDescriptor from './json/JsonDataAdapterDescriptor';
import XmlDataAdapterDescriptor from './xml/XmlDataAdapterDescriptor';
import EmptyDataAdapterDescriptor from './empty/EmptyDataAdapterDescriptor';
import RandomDataAdapterDescriptor from './random/RandomDataAdapterDescriptor';
import DatasourceDataAdapterDescriptor from './datasource/DatasourceDataAdapterDescriptor';
import JavaBeanDataAdapterDescriptor from './javabean/JavaBeanDataAdapterDescriptor';
import EJBQLBeanDataAdapterDescriptor from './ejbql/EJBQLBeanDataAdapterDescriptor';
import SpringHibernateDataAdapterDescriptor from './springhibernate/SpringHibernateDataAdapterDescriptor';
import HibernateDataAdapterDescriptor from './hibernate/HibernateDataAdapterDescriptor';
import XMLADataAdapterDescriptor from './xmla/XMLADataAdapterDescriptor';
import MondrianDataAdapterDescriptor from './mondrian/MondrianDataAdapterDescriptor';
import JRSDatasourceDataAdapterDescriptor from './jrsdatasource/JRSDatasourceDataAdapterDescriptor';
import MongoDBDataAdapterDescriptor from './mongodb/MongoDBDataAdapterDescriptor';
import DatasourceProviderDataAdapterDescriptor from './datasourceprovider/DatasourceProviderDataAdapterDescriptor';
import QueryExecuterDataAdapterDescriptor from './queryexecuter/QueryExecuterDataAdapterDescriptor';
import JrsJndiDataAdapterDescriptor from './jndi/JrsJndiDataAdapterDescriptor';
import { IJrsDataAdapterDescriptor } from './IJrsDataAdapterDescriptor';
import { Conf, MIME_TYPES } from '@jss/js-rest-api';
import JrsJDBCDataAdapterDescriptor from './jdbc/JrsJDBCDataAdapterDescriptor';
import JrsXMLADataAdapterDescriptor from './xmla/JrsXMLADataAdapterDescriptor';

let dataAdapterTypesCache: IDataAdapterDescriptor[] | null = null;

const jrsDataAdapterTypesCache = new Map<string, IJrsDataAdapterDescriptor>();

export const getDataAdapterTypes = () => {
        if (dataAdapterTypesCache === null) {
                // Initialize the cache of data adapter types... 

                dataAdapterTypesCache = [
                        new JDBCDataAdapterDescriptor(),

                        new EmptyDataAdapterDescriptor(),
                        new RandomDataAdapterDescriptor(),

                        new CSVDataAdapterDescriptor(),
                        new ExcelDataAdapterDescriptor(),

                        new JsonDataAdapterDescriptor(),
                        new XmlDataAdapterDescriptor(),

                        new JRSDatasourceDataAdapterDescriptor(),

                        new JndiDataAdapterDescriptor(),
                        new QueryExecuterDataAdapterDescriptor(),

                        new DatasourceProviderDataAdapterDescriptor(),
                        new DatasourceDataAdapterDescriptor(),
                        new JavaBeanDataAdapterDescriptor(),
                        new EJBQLBeanDataAdapterDescriptor(),
                        new SpringHibernateDataAdapterDescriptor(),
                        new HibernateDataAdapterDescriptor(),

                        new XMLADataAdapterDescriptor(),

                        new MondrianDataAdapterDescriptor(),
                        new MongoDBDataAdapterDescriptor()
                ];
        }
        return dataAdapterTypesCache;
}
export const getNewDataAdapterTypes = () => {
        return getDataAdapterTypes().filter(item => !Conf.get('jrws.data.adapter.hide.creation').includes(item.getMime()))
}

export const getJrsDataAdapterTypes = (mime: string) => {
        if (jrsDataAdapterTypesCache.size === 0) {
                // Initialize the cache of data adapter types... 
                jrsDataAdapterTypesCache.set(MIME_TYPES.DATASOURCE_JNDI, new JrsJndiDataAdapterDescriptor());
                jrsDataAdapterTypesCache.set(MIME_TYPES.DATASOURCE_JDBC, new JrsJDBCDataAdapterDescriptor());
                jrsDataAdapterTypesCache.set(MIME_TYPES.DATASOURCE_XMLA_CONNECTION, new JrsXMLADataAdapterDescriptor());
        }
        return jrsDataAdapterTypesCache.get(mime);
}



