/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const SpiderAreaChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath>
          <path fill="none" d="M0 0H535V740H0z" />
       </clipPath>
       <clipPath id="highcharts-0i02qtc-2">
          <circle cx="267.5" cy="370" r="227.375" />
       </clipPath>
       <clipPath id="highcharts-0i02qtc-3">
          <circle cx="267.5" cy="370" r="227.375" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M10 10H545V750H10z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M277.5 380V152.625M277.5 380l113.688-196.913M277.5 380l196.913-113.688M277.5 380h227.375M277.5 380l196.913 113.687M277.5 380l113.688 196.913M277.5 380v227.375M277.5 380L163.813 576.913M277.5 380L80.587 493.688M277.5 380H50.125M277.5 380L80.587 266.313M277.5 380L163.812 183.087M277.5 380V152.625" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M277.5 380h0M277.5 323.156h0l-28.422 7.616-20.806 20.806L220.656 380l7.616 28.422 20.806 20.806 28.422 7.616 28.422-7.616 20.806-20.806L334.344 380l-7.616-28.422-20.806-20.806-28.422-7.616" className="highcharts-grid-line" data-z-index="1" />
       <path fill="none" stroke="#e6e6e6" d="M277.5 266.313h0l-56.844 15.23-41.612 41.613L163.812 380l15.232 56.844 41.612 41.612 56.844 15.231 56.844-15.23 41.612-41.613L391.187 380l-15.23-56.844-41.613-41.612-56.844-15.231" className="highcharts-grid-line" data-z-index="1" />
       <path fill="none" stroke="#e6e6e6" d="M277.5 209.469h0l-85.266 22.847-62.418 62.418L106.969 380l22.847 85.266 62.418 62.418 85.266 22.847 85.266-22.847 62.418-62.418L448.031 380l-22.847-85.266-62.418-62.418-85.266-22.847" className="highcharts-grid-line" data-z-index="1" />
       <path fill="none" stroke="#e6e6e6" d="M277.5 152.625h0l-113.688 30.462-83.225 83.226L50.125 380l30.462 113.688 83.226 83.225L277.5 607.375l113.688-30.462 83.225-83.226L504.875 380l-30.462-113.688-83.226-83.225L277.5 152.625" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M10 10H545V750H10z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" d="M277.5 152.625a227.375 227.375 0 11-.227 0M277.5 380" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M277.5 380V152.625" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-area-series highcharts-color-0" clipPath="url(#highcharts-0i02qtc-2)" data-z-index="0.1" transform="translate(10 10)">
          <path fill="rgba(124,181,236,0.75)" d="M267.5 208.802l29.288 110.47 134.297-43.718L289.055 370l.36 12.652 59.915 129.082-81.83-133.185-22.246 29.983-24.49-11.55L246.817 370l-98.682-68.916 118.01 66.569 1.356-158.85V370" className="highcharts-area" data-z-index="0" />
          <path fill="none" stroke="#7cb5ec" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M267.5 208.802l29.288 110.47 134.297-43.718L289.055 370l.36 12.652 59.915 129.082-81.83-133.185-22.246 29.983-24.49-11.55L246.817 370l-98.682-68.916 118.01 66.569 1.356-158.85" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M257.5 208.802h10l29.288 110.47 134.297-43.718L289.055 370l.36 12.652 59.915 129.082-81.83-133.185-22.246 29.983-24.49-11.55L246.817 370l-98.682-68.916 118.01 66.569 1.356-158.85h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-0 highcharts-area-series highcharts-color-0 highcharts-tracker" data-z-index="0.1">
          <path fill="#7cb5ec" d="M271 209a4 4 0 110-.004zM300 319a4 4 0 110-.004z" className="highcharts-point highcharts-color-0" transform="translate(10 10)" />
          <path fill="#7cb5ec" strokeWidth="0.002" d="M435 275.554a4 4 0 110-.004z" className="highcharts-point highcharts-color-0" transform="translate(10 10)" />
          <path fill="#7cb5ec" d="M293 370a4 4 0 110-.004zM293 383a4 4 0 110-.004zM353 512a4 4 0 110-.004zM271 379a4 4 0 110-.004zM249 409a4 4 0 110-.004zM224 397a4 4 0 110-.004zM250 370a4 4 0 110-.004zM152 301a4 4 0 110-.004zM270 368a4 4 0 110-.004z" className="highcharts-point highcharts-color-0" transform="translate(10 10)" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-area-series highcharts-color-1" clipPath="url(#highcharts-0i02qtc-3)" data-z-index="0.1" transform="translate(10 10)">
          <path fill="rgba(67,67,72,0.75)" d="M267.5 366.135l1.137 1.896 4.967-1.555-5.422 3.524.303.568.038 1.204-1.023-1.09-1.023 1.09-1.34-.408 1.454-1.364-1.848-1.592 2.53 1.198.227-3.471V370" className="highcharts-area" data-z-index="0" />
          <path fill="none" stroke="#434348" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M267.5 366.135l1.137 1.896 4.967-1.555-5.422 3.524.303.568.038 1.204-1.023-1.09-1.023 1.09-1.34-.408 1.454-1.364-1.848-1.592 2.53 1.198.227-3.471" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M257.5 366.135h10l1.137 1.896 4.967-1.555-5.422 3.524.303.568.038 1.204-1.023-1.09-1.023 1.09-1.34-.408 1.454-1.364-1.848-1.592 2.53 1.198.227-3.471h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-1 highcharts-area-series highcharts-color-1 highcharts-tracker" data-z-index="0.1">
          <path fill="#434348" d="M267 362l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#434348" d="M268 364l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#434348" d="M273 362l4 4-4 4-4-4zM268 366l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#434348" d="M268 367l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#434348" d="M268 368l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#434348" d="M267 367l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#434348" d="M266 368l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#434348" d="M265 367l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#434348" d="M266 366l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#434348" d="M264 364l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
          <path fill="#434348" d="M267 366l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(10 10)" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(186 762)">
       <rect width="183" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-area-series highcharts-color-0 highcharts-series-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Max Freight</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-area-series highcharts-color-1 highcharts-series-1" data-z-index="1" transform="translate(116.938 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Orders</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11">
       <text x="277.5" y="142.125" textAnchor="middle">Albuquerque</text>
       <text x="398.688" y="174.597">Anchorage</text>
       <text x="487.403" y="263.313">Boise</text>
       <text x="519.875" y="384.5">
          <tspan>Butte</tspan>
       </text>
       <text x="487.403" y="505.687">Elgin</text>
       <text x="398.688" y="594.403">Eugene</text>
       <text x="277.5" y="626.875" textAnchor="middle">Kirkland</text>
       <text x="156.313" y="594.403" textAnchor="end">Lander</text>
       <text x="67.597" y="505.688" textAnchor="end">Portland</text>
       <text x="35.125" y="384.5" textAnchor="end">
          <tspan>San</tspan>
          <tspan x="35.125" dy="14">Francisco</tspan>
       </text>
       <text x="67.597" y="263.313" textAnchor="end">Seattle</text>
       <text x="156.312" y="174.597" textAnchor="end">
          <tspan>Walla Walla</tspan>
       </text>
       <text y="-9999" textAnchor="middle">12</text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="274.5" y="378">0</text>
       <text x="274.5" y="321.156">250</text>
       <text x="274.5" y="264.313">500</text>
       <text x="274.5" y="207.469">750</text>
       <text y="-9999">1.000</text>
    </g>
 </svg>)};

export default SpiderAreaChartIcon;