/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import '../../../assets/uxpl/css/BaseElement.css';
import { IState } from '../../../reducers';
import { ElementTypes } from '../../../sagas/report/document/elementTypes';
import BaseElement, { IBaseElement } from '../elements/BaseElement';
import SelectionFigure from '../elements/interaction/SelectionFigure';
import withPositionFinder, { PositionFinderContext } from '../hoc/positionFinder';
import { listPositionFinderProvider } from './ListUtils';
import * as ReportActions from '../../../actions/reportActions';
import TablePreviewElement from '../table/TablePreviewElement';
import CrosstabPreviewElement from '../crosstab/CrosstabPreviewElement';

const visualProperties = ['x', 'y', 'width', 'height', 'highlighted'];

interface IListPreviewElement extends IBaseElement {
  state?: IState,
  createEditor?: (editorType: string, editorLabel: string, isSelectable: boolean, editedResourceId: string, setActive: boolean, params: Record<string, unknown>) => void;
}

class ListPreviewElement extends React.Component<IListPreviewElement> {

  public shouldComponentUpdate = (nextProps: IListPreviewElement): boolean => {
    if (this.props.zoom !== nextProps.zoom) {
      return true;
    }

    if (this.props.selection.has(this.props.id) !== nextProps.selection.has(this.props.id)) {
      return true;
    }

    for (const propName of visualProperties) {
      if (this.props.element.get(propName) !== nextProps.element.get(propName)) {
        return true;
      }
    }

    return false; // Nothing impacting the content of the figure is changed...
  }

  private getChildren = (parentElement: Map<string, any>): Map<string, any>[] => {
    const result = [parentElement];
    const childrens: List<string> = parentElement.get('elementIds');
    if (childrens) {
      childrens.forEach(elementId => {
        const child = this.props.model.getIn(['elements', elementId]) as Map<string, any>;
        result.push(...this.getChildren(child));
      });
    }
    return result;
  }

  private onDoubleClick = () => {
    const listPath = `elements/${this.props.id}`;
    this.props.createEditor(ElementTypes.LIST, 'List', true, listPath, true, {});
  }

  public render() {
    if (!this.props.positionFinder) {
      return null;
    }

    const zoom = (this.props.zoom) ? this.props.zoom : 1;

    let selectionFigure = null;
    let highlightBorder = null;

    const { x, y, renderElement } = this.props.positionFinder.findPosition(this.props.element);

    if (!renderElement) {
      return null;
    }

    if (isNaN(x) || isNaN(y)) {
      console.log('error');
    }

    const fullWidth = this.props.element.get('width') * zoom;
    const fullHeight = this.props.element.get('height') * zoom;
    const baseAttributes: React.CSSProperties = {
      top: y * zoom,
      left: x * zoom,
      width: fullWidth,
      height: fullHeight,
      display: 'flex',
      border: `${(1 * zoom)}px solid #CCCCCC`,
    };

    const classes = ["BaseElement"];
    const id = this.props.element.get('id', '');
    const isSelected = this.props.selection.has(id);
    if (isSelected) {
      selectionFigure = (<SelectionFigure onDoubleClick={this.onDoubleClick} position={baseAttributes} id={this.props.id} zoom={1.0} />);
    }

    if ((!isSelected && this.props.element.get('highlighted', false))) {
      highlightBorder = (
        <svg className="BaseElementBorder" width={this.props.element.get('width')} height={this.props.element.get('height')} style={{ ...baseAttributes, zIndex: 1 }}>
          <rect shapeRendering="optimizeSpeed" width="100%" height="100%" strokeWidth={2 / zoom} style={{ fill: 'none', stroke: 'rgba(0, 129, 203, 0.5)' }} />
        </svg>);
    }

    const children: Map<string, any>[] = [];
    const sections: List<string> = this.props.element.get('elementIds');
    if (sections) {
      sections.forEach((sectionId) => {
        const section = this.props.model.getIn(['elements', sectionId]) as Map<string, any>;
        children.push(...this.getChildren(section));
      });
    }

    const renderedElements = children.map((ele: Map<string, any>) => {
      const id: string = ele.get('id');
      if (id) {
        const type = ele.get('type');
        if (type === ElementTypes.TABLE) {
          return <TablePreviewElement zoom={zoom} key={id} id={id} />
        } else if (type === ElementTypes.LIST) {
          return <ListPreviewElement zoom={zoom} key={id} id={id} />
        } else if (type === ElementTypes.CROSSTAB) {
          return <CrosstabPreviewElement zoom={zoom} key={id} id={id} />
        } else {
          return (<BaseElement zoom={zoom} key={id} id={id} />);
        }
      }
      // We should never reach this point...
      return undefined;
    });
    const listPositionFinder = listPositionFinderProvider(this.props.state, `elements/${this.props.id}`);
    return (
      <>
        <div onDoubleClick={this.onDoubleClick} className={classes.join(' ')} style={baseAttributes}>
          <PositionFinderContext.Provider value={listPositionFinder}>
            {renderedElements}
          </PositionFinderContext.Provider>
        </div>
        {highlightBorder}
        {selectionFigure}
      </>
    );
  }

}

const mapStateToProps = (state: IState, props: IListPreviewElement) => {

  return {
    state: state,
    selection: state.getIn(['report', 'selection']),
    element: state.getIn(['report', 'model', 'elements', props.id]),
    model: state.getIn(['report', 'model']),
  };
}

const mapDispatchToProps = dispatch => {
  return {
    createEditor: (editorType: string, editorLabel: string, isSelectable: boolean, editedResourceId: string, setActive: boolean, params: Record<string, unknown>) => {
      dispatch(ReportActions.createEditor(editorType, editorLabel, isSelectable, editedResourceId, setActive, params));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withPositionFinder(ListPreviewElement));   // ,mapDispatchToProps