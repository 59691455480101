/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { TextField } from "@jss/js-common";
import React from "react";
import i18n from '../../../i18n';
import { ICParameters } from "./ParametersTab";

export class ICDateTime extends React.Component<ICParameters> {

    render() {
        let value = this.props.parametersValue?.get(this.props.param.name);
        let error;
        if (value && value.length > 0) {
            const dateValue = new Date(value);
            if (isNaN(dateValue.getTime())) {
                error = i18n.t('components.datasets.parameters.dateError');
            } else {
                const isoString = dateValue.toISOString();
                value = isoString.substring(0, isoString.length - 1);
            }
        } else {
            value = '';
        }
        return <TextField
            id={this.props.param.name}
            key={this.props.param.name}
            onChange={this.onValueChange}
            label={i18n.t(this.props.param.name)}
            inline={true}
            size={'small'}
            //use default value for the value, since value tend to put the cursor at the end
            // defaultValue={v}
            value={value}
            type={error ? 'text' : 'datetime-local'}
            title={this.props.param.description}
            inputProps={{
                step: 0.0001
            }}
            error={error}
        // onBlur={() => { this.setState({ uncommittedValue: undefined }) }}
        // error={descriptor.validator && descriptor.validator(v)}
        />
    }
    private onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = event.currentTarget.value;
        if (newValue && newValue.trim().length > 0) {
            const slicedValue = newValue.split('T');
            if (slicedValue[1] === '00:00' || !slicedValue[1].includes(':')){
                newValue += ':00.000';
            }
            this.props.setParameterValue(this.props.param.name, newValue + 'Z');
        } else {
            this.props.setParameterValue(this.props.param.name, undefined);
            event.currentTarget.value = '';
        }
    }
}