/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { PColor } from "../../../ui/primitive/UPColor";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { setFonts } from "../../common/MFont";
import { PString } from "../../../ui/primitive/UPString";
import { PInt } from "../../../ui/primitive/UPInteger";
import { PBoolean } from "../../../ui/primitive/UPBoolean";


export const FAxis = {
    categoryAxisLineColor: new PColor({ id: 'categoryAxisLineColor', label: 'Line Color' }),
    categoryAxisLabelExpression: new PExpression({ id: 'categoryAxisLabelExpression', label: 'Label' }),
    categoryAxisTickLabelColor: new PColor({ id: 'categoryAxisTickLabelColor', label: 'Color' }),
    categoryAxisTickLabelMask: new PString({ id: 'categoryAxisTickLabelMask', label: 'Mask' }),
    categoryAxisTickLabelRotation: new PInt({ id: 'categoryAxisTickLabelRotation', label: 'Label Rotation' }),
    categoryAxisVerticalTickLabels: new PBoolean({ id: 'categoryAxisVerticalTickLabels', label: 'Show Vertical Labels', canBeNull: true }),
    domainAxisMinValueExpression: new PExpression({ id: 'domainAxisMinValueExpression', label: 'Min' }),
    domainAxisMaxValueExpression: new PExpression({ id: 'domainAxisMaxValueExpression', label: 'Max' }),


    valueAxisLineColor: new PColor({ id: 'valueAxisLineColor', label: 'Line Color' }),
    valueAxisLabelExpression: new PExpression({ id: 'valueAxisLabelExpression', label: 'Label' }),
    valueAxisTickLabelColor: new PColor({ id: 'valueAxisTickLabelColor', label: 'Color' }),
    valueAxisTickLabelMask: new PString({ id: 'valueAxisTickLabelMask', label: 'Mask' }),
    valueAxisTickLabelRotation: new PInt({ id: 'valueAxisTickLabelRotation', label: 'Label Rotation' }),
    valueAxisVerticalTickLabels: new PBoolean({ id: 'valueAxisVerticalTickLabels', label: 'Show Vertical Labels', canBeNull: true }),
    rangeAxisMinValueExpression: new PExpression({ id: 'rangeAxisMinValueExpression', label: 'Min' }),
    rangeAxisMaxValueExpression: new PExpression({ id: 'rangeAxisMaxValueExpression', label: 'Max' })
};

export const MCategoryAxis: APDescriptor = new PAccordeon({
    id: 'chart.category.axis', label: 'Category Axis',
    layouts: [FAxis.categoryAxisLineColor,
    new PAccordeon({
        id: 'chart.category.axis.label', label: 'Label',
        layouts: [FAxis.categoryAxisLabelExpression, FAxis.categoryAxisLineColor, ...setFonts('valueAxisLabelFont')]
    }),
    new PAccordeon({
        id: 'chart.category.axis.tick', label: 'Tick',
        layouts: [FAxis.categoryAxisVerticalTickLabels, FAxis.categoryAxisTickLabelColor, ...setFonts('categoryAxisTickLabelFont'), FAxis.categoryAxisTickLabelMask, FAxis.categoryAxisTickLabelRotation, FAxis.domainAxisMinValueExpression, FAxis.domainAxisMaxValueExpression]
    })]
});


export const MValueAxis: APDescriptor = new PAccordeon({
    id: 'chart.value.axis', label: 'Value Axis',
    layouts: [FAxis.valueAxisLineColor,
    new PAccordeon({
        id: 'chart.value.axis.label', label: 'Label',
        layouts: [FAxis.valueAxisLabelExpression, FAxis.valueAxisLineColor, ...setFonts('valueAxisLabelFont')]
    }),
    new PAccordeon({
        id: 'chart.value.axis.tick', label: 'Tick',
        layouts: [FAxis.valueAxisVerticalTickLabels, FAxis.valueAxisTickLabelColor, ...setFonts('valueAxisTickLabelFont'), FAxis.valueAxisTickLabelMask, FAxis.valueAxisTickLabelRotation, FAxis.rangeAxisMinValueExpression, FAxis.rangeAxisMaxValueExpression]
    })]
});


