/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { MChartPlot } from "./MChartPlot";
import { PColor } from "../../../ui/primitive/UPColor";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { setFonts } from "../../common/MFont";
import { MCategoryAxis, MValueAxis } from "./MAxis";

export const FBarPlot = {
    isShowLabels: new PBoolean({ id: 'isShowLabels', label: 'Show Labels', canBeNull: true }),
    isShowTickLabels: new PBoolean({ id: 'isShowTickLabels', label: 'Show Tick Labels', canBeNull: true }),
    isShowTickMarks: new PBoolean({ id: 'isShowTickMarks', label: 'Show Tick Marks', canBeNull: true }),

    color: new PColor({ id: 'color', label: 'Color' }),
    backgroundColor: new PColor({ id: 'backgroundColor', label: 'Background' })
};

export const MBarPlot: APDescriptor = {
    type: 'MBarPlot',
    path: 'barPlot',
    id: 'barPlotProeprtiesDescriptor',
    layouts: [MChartPlot, FBarPlot.isShowLabels, FBarPlot.isShowTickLabels, FBarPlot.isShowTickMarks,
        MCategoryAxis, MValueAxis,
        new PAccordeon({
            id: 'chart.value.item.label', label: 'Item Label', path: 'itemLabel',
            layouts: [FBarPlot.color, FBarPlot.backgroundColor, ...setFonts('font')]
        })
    ]
};
