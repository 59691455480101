/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Conf, Pref } from "@jss/js-rest-api";
import React from "react";
import { connect } from "react-redux";
import { setLanguage } from "../../store/actions/actions";
import { Select } from "@jss/js-common";
import { I18nService } from "@jss/js-i18n";
import i18n from "../../i18n";

interface ILanguageSelector {
    language: string,
    setLanguage?: (newLanguage: string | undefined) => void;
}

class LanguageSelector extends React.Component<ILanguageSelector> {

    public render() {
        const langs = Conf.get(Pref.LOCALES_AVAILABLE);
        const currentLanguage = this.props.language ? this.props.language : Conf.getLocale();
        return <Select items={langs.map(item => {
            const langT = i18n.getFixedT(item);
            const langValue = langT('languageselector.combo.language');
            return { key: item, value: langValue }
        })}
            className="tc-jsw-language-selector"
            value={currentLanguage}
            onItemSelected={this.onSelection} />
    }

    private onSelection = (newValue: string) => {
        Conf.set(Pref.LOCALE, newValue);
        I18nService.changeAllLanguages(newValue);
        if (this.props.setLanguage)
            this.props.setLanguage(newValue);
    }
}

const mapStateToProps = (state: any) => {
    return {
        language: state.routerReducer.language,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLanguage: (newLanguage: string | undefined) => dispatch(setLanguage(newLanguage)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
