/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const OneParentTreeMapChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="752" height="572" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 752 572" {...props}>
    <defs>
       <clipPath id="highcharts-l69covv-1">
          <path fill="none" d="M0 0H732V510H0z" />
       </clipPath>
    </defs>
    <rect width="752" height="572" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M10 47H742V557H10z" className="highcharts-plot-background" />
    <path fill="none" d="M10 47H742V557H10z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" d="M10 557h732" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M10 47v510" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-treemap-series highcharts-color-0 highcharts-tracker" clipPath="url(#highcharts-l69covv-1)" data-z-index="0.1" transform="translate(10 47)">
          <g className="highcharts-level-group-2" data-z-index="998">
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M572.5 406.5H630.5V465.5H572.5z" className="highcharts-point" />
             <path fill="#434348" stroke="#e6e6e6" d="M-0.5 307.5H176.5V509.5H-0.5z" className="highcharts-point" />
             <path fill="#434348" stroke="#e6e6e6" d="M176.5 307.5H209.5V509.5H176.5z" className="highcharts-point" />
             <path fill="#90ed7d" stroke="#e6e6e6" d="M435.5 314.5H517.5V347.5H435.5z" className="highcharts-point" />
             <path fill="#90ed7d" stroke="#e6e6e6" d="M435.5 256.5H517.5V314.5H435.5z" className="highcharts-point" />
             <path fill="#f7a35c" stroke="#e6e6e6" d="M461.5 155.5H556.5V256.5H461.5z" className="highcharts-point" />
             <path fill="#8085e9" stroke="#e6e6e6" d="M209.5 280.5H333.5V295.5H209.5z" className="highcharts-point" />
             <path fill="#8085e9" stroke="#e6e6e6" d="M333.5 155.5H341.5V295.5H333.5z" className="highcharts-point" />
             <path fill="#8085e9" stroke="#e6e6e6" d="M209.5 155.5H333.5V280.5H209.5z" className="highcharts-point" />
             <path fill="#f15c80" stroke="#e6e6e6" d="M582.5 347.5H659.5V406.5H582.5z" className="highcharts-point" />
             <path fill="#f15c80" stroke="#e6e6e6" d="M659.5 347.5H660.5V406.5H659.5z" className="highcharts-point" />
             <path fill="#e4d354" stroke="#e6e6e6" d="M341.5 341.5H435.5V426.5H341.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M405.5 256.5H435.5V341.5H405.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M341.5 256.5H405.5V341.5H341.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M435.5 422.5H498.5V430.5H435.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M435.5 347.5H498.5V422.5H435.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M456.5 105.5H529.5V155.5H456.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M529.5 106.5H587.5V155.5H529.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M456.5 -0.5H529.5V105.5H456.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M587.5 43.5H612.5V97.5H587.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M587.5 97.5H612.5V122.5H587.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M587.5 142.5H612.5V155.5H587.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M529.5 43.5H587.5V106.5H529.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M529.5 -0.5H612.5V43.5H529.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M587.5 122.5H612.5V142.5H587.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M181.5 138.5H209.5V201.5H181.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M181.5 201.5H209.5V247.5H181.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M130.5 168.5H181.5V259.5H130.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M-0.5 -0.5H130.5V245.5H-0.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M130.5 -0.5H209.5V72.5H130.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M130.5 72.5H181.5V168.5H130.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M181.5 72.5H209.5V138.5H181.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M181.5 247.5H209.5V281.5H181.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M-0.5 245.5H130.5V307.5H-0.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M181.5 281.5H209.5V307.5H181.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M130.5 259.5H181.5V307.5H130.5z" className="highcharts-point" />
             <path fill="#434348" stroke="#e6e6e6" d="M209.5 295.5H341.5V416.5H209.5z" className="highcharts-point" />
             <path fill="#90ed7d" stroke="#e6e6e6" d="M435.5 430.5H493.5V477.5H435.5z" className="highcharts-point" />
             <path fill="#90ed7d" stroke="#e6e6e6" d="M435.5 477.5H493.5V509.5H435.5z" className="highcharts-point" />
             <path fill="#90ed7d" stroke="#e6e6e6" d="M493.5 430.5H498.5V509.5H493.5z" className="highcharts-point" />
             <path fill="#f7a35c" stroke="#e6e6e6" d="M597.5 256.5H668.5V347.5H597.5z" className="highcharts-point" />
             <path fill="#8085e9" stroke="#e6e6e6" d="M620.5 465.5H667.5V499.5H620.5z" className="highcharts-point" />
             <path fill="#f15c80" stroke="#e6e6e6" d="M701.5 465.5H731.5V499.5H701.5z" className="highcharts-point" />
             <path fill="#e4d354" stroke="#e6e6e6" d="M498.5 459.5H572.5V509.5H498.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M553.5 398.5H582.5V406.5H553.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M553.5 347.5H582.5V398.5H553.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M498.5 347.5H553.5V406.5H498.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M612.5 -0.5H731.5V80.5H612.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M612.5 80.5H731.5V155.5H612.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M410.5 426.5H435.5V509.5H410.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M341.5 426.5H410.5V509.5H341.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M685.5 406.5H731.5V465.5H685.5z" className="highcharts-point" />
             <path fill="#434348" stroke="#e6e6e6" d="M572.5 465.5H620.5V509.5H572.5z" className="highcharts-point" />
             <path fill="#90ed7d" stroke="#e6e6e6" d="M341.5 155.5H461.5V256.5H341.5z" className="highcharts-point" />
             <path fill="#f7a35c" stroke="#e6e6e6" d="M668.5 256.5H731.5V347.5H668.5z" className="highcharts-point" />
             <path fill="#8085e9" stroke="#e6e6e6" d="M667.5 465.5H701.5V499.5H667.5z" className="highcharts-point" />
             <path fill="#f15c80" stroke="#e6e6e6" d="M209.5 -0.5H456.5V155.5H209.5z" className="highcharts-point" />
             <path fill="#e4d354" stroke="#e6e6e6" d="M620.5 499.5H693.5V509.5H620.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M209.5 416.5H341.5V509.5H209.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M637.5 155.5H649.5V256.5H637.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M556.5 155.5H637.5V232.5H556.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M556.5 232.5H637.5V256.5H556.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M649.5 251.5H730.5V256.5H649.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M649.5 155.5H730.5V251.5H649.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M730.5 155.5H731.5V256.5H730.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M630.5 406.5H685.5V465.5H630.5z" className="highcharts-point" />
             <path fill="#434348" stroke="#e6e6e6" d="M693.5 499.5H731.5V509.5H693.5z" className="highcharts-point" />
             <path fill="#90ed7d" stroke="#e6e6e6" d="M660.5 347.5H731.5V406.5H660.5z" className="highcharts-point" />
             <path fill="#f7a35c" stroke="#e6e6e6" d="M498.5 406.5H572.5V459.5H498.5z" className="highcharts-point" />
             <path fill="#8085e9" stroke="#e6e6e6" d="M517.5 256.5H597.5V347.5H517.5z" className="highcharts-point" />
          </g>
          <g className="highcharts-level-group-1" data-z-index="999">
             <path fill="rgba(124,181,236,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M572.5 406.5H630.5V465.5H572.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(67,67,72,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M-0.5 307.5H209.5V509.5H-0.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(144,237,125,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M435.5 256.5H517.5V347.5H435.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(247,163,92,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M461.5 155.5H556.5V256.5H461.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(128,133,233,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M209.5 155.5H341.5V295.5H209.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(241,92,128,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M582.5 347.5H660.5V406.5H582.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(228,211,84,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M341.5 341.5H435.5V426.5H341.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(43,144,143,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M341.5 256.5H435.5V341.5H341.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(244,91,91,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M435.5 347.5H498.5V430.5H435.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M456.5 -0.5H612.5V155.5H456.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(124,181,236,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M-0.5 -0.5H209.5V307.5H-0.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(67,67,72,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M209.5 295.5H341.5V416.5H209.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(144,237,125,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M435.5 430.5H498.5V509.5H435.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(247,163,92,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M597.5 256.5H668.5V347.5H597.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(128,133,233,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M620.5 465.5H667.5V499.5H620.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(241,92,128,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M701.5 465.5H731.5V499.5H701.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(228,211,84,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M498.5 459.5H572.5V509.5H498.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(43,144,143,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M498.5 347.5H582.5V406.5H498.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(244,91,91,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M612.5 -0.5H731.5V155.5H612.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M341.5 426.5H435.5V509.5H341.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(124,181,236,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M685.5 406.5H731.5V465.5H685.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(67,67,72,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M572.5 465.5H620.5V509.5H572.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(144,237,125,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M341.5 155.5H461.5V256.5H341.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(247,163,92,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M668.5 256.5H731.5V347.5H668.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(128,133,233,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M667.5 465.5H701.5V499.5H667.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(241,92,128,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M209.5 -0.5H456.5V155.5H209.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(228,211,84,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M620.5 499.5H693.5V509.5H620.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(43,144,143,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M209.5 416.5H341.5V509.5H209.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(244,91,91,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M556.5 155.5H649.5V256.5H556.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M649.5 155.5H731.5V256.5H649.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(124,181,236,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M630.5 406.5H685.5V465.5H630.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(67,67,72,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M693.5 499.5H731.5V509.5H693.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(144,237,125,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M660.5 347.5H731.5V406.5H660.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(247,163,92,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M498.5 406.5H572.5V459.5H498.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(128,133,233,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M517.5 256.5H597.5V347.5H517.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
          </g>
       </g>
    </g>
    <text x="376" y="24" fill="#333" className="highcharts-title" color="#333" data-z-index="4" fontSize="18" textAnchor="middle">
       <tspan>Freight details of the year</tspan>
    </text>
    <g className="highcharts-data-labels highcharts-series-0 highcharts-treemap-series highcharts-color-0 highcharts-tracker" data-z-index="6">
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(577 418)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region</tspan>
          <tspan x="5" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline" dy="14">N/A</tspan>
          <tspan x="5" y="16">Region</tspan>
          <tspan x="5" dy="14">N/A</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(67 397)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region N/A</tspan>
          <tspan x="5" y="16">Region N/A</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(439 291)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region N/A</tspan>
          <tspan x="5" y="16">Region N/A</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(498 195)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">RJ</tspan>
          <tspan x="5" y="16">RJ</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(264 214)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">SP</tspan>
          <tspan x="5" y="16">SP</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(609 366)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">BC</tspan>
          <tspan x="5" y="16">BC</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(362 373)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Québec</tspan>
          <tspan x="5" y="16">Québec</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(351 288)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region N/A</tspan>
          <tspan x="5" y="16">Region N/A</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(442 371)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region</tspan>
          <tspan x="5" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline" dy="14">N/A</tspan>
          <tspan x="5" y="16">Region</tspan>
          <tspan x="5" dy="14">N/A</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(497 66)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region N/A</tspan>
          <tspan x="5" y="16">Region N/A</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(67 142)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region N/A</tspan>
          <tspan x="5" y="16">Region N/A</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(246 345)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Co. Cork</tspan>
          <tspan x="5" y="16">Co. Cork</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(442 452)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region</tspan>
          <tspan x="5" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline" dy="14">N/A</tspan>
          <tspan x="5" y="16">Region</tspan>
          <tspan x="5" dy="14">N/A</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(595 291)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region N/A</tspan>
          <tspan x="5" y="16">Region N/A</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(619 464)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region</tspan>
          <tspan x="5" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline" dy="14">N/A</tspan>
          <tspan x="5" y="16">Region</tspan>
          <tspan x="5" dy="14">N/A</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(688 464)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region</tspan>
          <tspan x="5" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline" dy="14">N/A</tspan>
          <tspan x="5" y="16">Region</tspan>
          <tspan x="5" dy="14">N/A</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(498 473)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region N/A</tspan>
          <tspan x="5" y="16">Region N/A</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(503 366)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region N/A</tspan>
          <tspan x="5" y="16">Region N/A</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(634 66)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region N/A</tspan>
          <tspan x="5" y="16">Region N/A</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(351 457)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region N/A</tspan>
          <tspan x="5" y="16">Region N/A</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(688 425)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Essex</tspan>
          <tspan x="5" y="16">Essex</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(574 469)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Isle of</tspan>
          <tspan x="5" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline" dy="14">Wight</tspan>
          <tspan x="5" y="16">Isle of</tspan>
          <tspan x="5" dy="14">Wight</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(364 195)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Region N/A</tspan>
          <tspan x="5" y="16">Region N/A</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(687 291)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">AK</tspan>
          <tspan x="5" y="16">AK</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(672 471)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">CA</tspan>
          <tspan x="5" y="16">CA</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(322 66)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">ID</tspan>
          <tspan x="5" y="16">ID</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(643 492)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">MT</tspan>
          <tspan x="5" y="16">MT</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(261 452)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">NM</tspan>
          <tspan x="5" y="16">NM</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(590 195)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">OR</tspan>
          <tspan x="5" y="16">OR</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(676 195)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">WA</tspan>
          <tspan x="5" y="16">WA</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(644 425)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">WY</tspan>
          <tspan x="5" y="16">WY</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(700 492)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">DF</tspan>
          <tspan x="5" y="16">DF</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(679 366)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Lara</tspan>
          <tspan x="5" y="16">Lara</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(509 415)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Nueva</tspan>
          <tspan x="5" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline" dy="14">Esparta</tspan>
          <tspan x="5" y="16">Nueva</tspan>
          <tspan x="5" dy="14">Esparta</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(531 291)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Táchira</tspan>
          <tspan x="5" y="16">Táchira</tspan>
       </text>
    </g>
 </svg>
 )};

export default OneParentTreeMapChartIcon;