/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */



import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../reducers';
import { Map } from 'immutable';
import { resolveAttribute } from '../properties/types/common/StyleResolver';

interface IRectangleElement {
  id: string,
  element?: Map<string, any>,
  model?: Map<string, any>,
  zoom?: number,
  availableWidth: number,
  availableHeight: number,
}

const inheritedProperties = ['pen.lineColor', 'pen.lineWidth', 'pen.lineStyle', 'backcolor', 'forecolor', 'radius', 'mode'];


class RectangleElement extends React.Component<IRectangleElement> {

  public shouldComponentUpdate = (nextProps: Readonly<IRectangleElement>) => {
    if (this.props.availableHeight !== nextProps.availableHeight || this.props.availableWidth !== nextProps.availableWidth) {
      return true;
    }

    if (this.props.zoom !== nextProps.zoom) {
      return true;
    }

    for (const propName of inheritedProperties) {
      if (resolveAttribute(this.props.model, this.props.element, propName) !== resolveAttribute(nextProps.model, nextProps.element, propName)) {
        return true;
      }
    }

    return false; // Nothing impacting the content of the figure is changed...
  }

  getSvgStyle = (): React.CSSProperties => {
    const zoom = this.props.zoom;
    let lineColor = resolveAttribute(this.props.model, this.props.element, 'pen.lineColor', { fallbackToDefault: false });
    const lineWidth = resolveAttribute(this.props.model, this.props.element, 'pen.lineWidth');
    const lineStyle = resolveAttribute(this.props.model, this.props.element, 'pen.lineStyle');
    const backgroundColor = resolveAttribute(this.props.model, this.props.element, 'backcolor');

    if (!lineColor) {
      lineColor = resolveAttribute(this.props.model, this.props.element, 'forecolor');
    }

    const result: React.CSSProperties = {
      stroke: lineColor,
      strokeWidth: lineWidth * zoom,
      fill: backgroundColor,
    };
    switch (lineStyle.toLowerCase()) {
      case 'dotted':
        {
          result.strokeDasharray = `${lineWidth * zoom},${lineWidth * zoom}`;
          break;
        }
      case 'dashed':
        {
          result.strokeDasharray = `${5 * lineWidth * zoom},${3 * lineWidth * zoom}`;
          break;
        }
      case 'double': //FIXME: there is no built-in svg support for double stroke style; strokes could be rendered twice as a workaround
      case 'solid':
      default:
        {
          break;
        }
    }
    return result;
  }

  private getPen = (borderWidth: number, borderStyle: string, borderColor: string, side?: string): React.CSSProperties => {
    let currentBorderWidth = borderWidth;
    if (0 < borderWidth && borderWidth < 1) {
      currentBorderWidth = 1;
    }

    let currentBorderStyle;
    const cleanedStyle = borderStyle ? borderStyle.toLowerCase().trim() : borderStyle;
    switch (cleanedStyle) {
      case 'double':
        {
          currentBorderStyle = "double";
          break;
        }
      case 'dotted':
        {
          currentBorderStyle = "dotted";
          break;
        }
      case 'dashed':
        {
          currentBorderStyle = "dashed";
          break;
        }
      case 'solid':
      default:
        {
          currentBorderStyle = "solid";
          break;
        }
    }

    const result: React.CSSProperties = {};
    if (currentBorderWidth > 0) {
      const value = `${(currentBorderWidth)}px ${currentBorderStyle} ${borderColor}`;
      if (side) {
        switch (side) {
          case 'left':
            result.borderLeft = value;
            break;
          case 'right':
            result.borderRight = value;
            break;
          case 'top':
            result.borderTop = value;
            break;
          case 'bottom':
            result.borderBottom = value;
            break;
        }
      } else {
        result.border = value;
      }

    }

    return result;
  }

  public render() {
    const width = this.props.availableWidth;
    const height = this.props.availableHeight;
    const zoom = this.props.zoom;
    const radius = resolveAttribute(this.props.model, this.props.element, 'radius');

    let globalWidth = resolveAttribute(this.props.model, this.props.element, 'pen.lineWidth', { fallbackToDefault: false });
    let borderColor;
    if (globalWidth !== 0) {
      borderColor = resolveAttribute(this.props.model, this.props.element, 'pen.lineColor', { fallbackToDefault: false });
      if (!borderColor) {
        borderColor = resolveAttribute(this.props.model, this.props.element, 'forecolor');
      }
    } else {
      borderColor = '#CCCCCC';
    }
    if (!globalWidth) {
      globalWidth = 1;
    }

    if (radius == 0 || !radius) {
      const lineStyle = resolveAttribute(this.props.model, this.props.element, 'pen.lineStyle');
      const borderStyle: React.CSSProperties = {
        position: 'absolute',
        display: 'flex',
        top: - (globalWidth / 2) * zoom,
        left: - (globalWidth / 2) * zoom,
        height: Math.max(0, height - (globalWidth * zoom)),
        width: Math.max(0, width - (globalWidth * zoom)),
        borderColor: borderColor,
        borderStyle: lineStyle,
        borderWidth: globalWidth * zoom,
      }

      const mode = resolveAttribute(this.props.model, this.props.element, 'mode');
      let backgroundColor;
      if (mode === 'Opaque') {
        backgroundColor = resolveAttribute(this.props.model, this.props.element, 'backcolor');
      }
      return <div style={{ top: 0, left: 0, width: width, height: height, display: 'flex', background: backgroundColor }}>
        <div style={borderStyle} />
      </div>
    } else {
      return <div style={{ top: - (globalWidth / 2) * zoom, left: - (globalWidth / 2) * zoom, width: width + (globalWidth * zoom), height: height + (globalWidth * zoom), display: 'flex', position: 'absolute' }}>
        <svg width={width + (globalWidth * zoom)} height={height + (globalWidth * zoom)}>
          <rect style={this.getSvgStyle()} x={(globalWidth * zoom) / 2} y={(globalWidth * zoom) / 2} rx={radius * zoom} ry={radius * zoom} width={width} height={height} />
        </svg>
      </div>
    }
  }
}

const mapStateToProps = (state: IState, props: IRectangleElement) => {

  return {
    element: state.getIn(['report', 'model', 'elements', props.id]),
    model: state.getIn(['report', 'model']),
    zoom: state.getIn(['report', 'zoom']),
  };
}

export default connect(mapStateToProps)(RectangleElement);   // ,mapDispatchToProps