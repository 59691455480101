/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { IDataAdapterDescriptor } from '../datasource/IDataAdapterDescriptor';
import { getNewDataAdapterTypes } from '../datasource/DataAdaptersRegistry';
import { DataAdapterTypePickerItem } from './DataAdapterTypePickerItem';
import '../assets/css/DataAdapterTypePicker.css';


interface IDataAdapterTypePicker {
    selectedType: string | null,
    onDataAdapterTypePicked?: (type: string) => void
}

export class DataAdapterTypePicker extends React.Component<IDataAdapterTypePicker> {


    public render() {

        const da = getNewDataAdapterTypes().map((item: IDataAdapterDescriptor) => {
            return <DataAdapterTypePickerItem icon={item.getIcon ? item.getIcon() : undefined} label={item.getDataAdapterLabel()} description={item.getDataAdapterDescription()}  key={item.getDataAdapterClass()} id={item.getDataAdapterClass()} onClick={this.onClick} selected={item.getDataAdapterClass() === this.props.selectedType} />;
        });


        return (<div className="tc-jsw-dataadapter-type-picker">
            <div className="tc-jsw-dataadapter-type-picker-list">
                {da}
            </div>
        </div>);
    }


    /**
     *  when a Data Adapter type is seleced.
     */
    private onClick = (type: string) => {

        if (this.props.onDataAdapterTypePicked) {
            this.props.onDataAdapterTypePicked(type);
        }
    }

}