/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const ValueLocationEnum = new PEnum({
    id: 'valueLocation', label: 'ValueLocation.label', default: 'none',
    items: [
        { key: 'none', value: 'ValueLocation.none' },
        { key: 'left', value: 'ValueLocation.left' },
        { key: 'right', value: 'ValueLocation.right' },
        { key: 'bulb', value: 'ValueLocation.bulb' }
    ]
});

