/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MGraphicElement } from "../common/MGraphicElement";
import { LineDirectionEnum } from "../enums/LineDirectionEnum";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { MLinePen } from "../common/MLinePen";
import { ICONS } from "@jss/js-common";
import { MLineCustom } from "./MLineCustom";

export const FLine = {
    direction: LineDirectionEnum
};

export const MLine: APDescriptor = {
    type: ElementTypes.LINE,
    label: 'Line',
    id: 'lineProeprtiesDescriptor',
    default: { width: 100, height: 30 },
    icon: ICONS.LINE_ICON,
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MGraphicElement.layouts, MLineCustom] }),
            new PDiv({ label: 'Line', layouts: [FLine.direction, MLinePen] })
        ]
    })]
};
