/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React, { forwardRef } from 'react';
import {
    IconButton as MuiIconButton, ButtonProps as MuiButtonProps, IconButtonProps as MuiIconButtonProps
} from '@material-ui/core';
import {
    ButtonColor, ColorToClass, SizeToClass, VariantToClassName
} from '../types/ButtonTypes';

export type IconButtonProps = MuiIconButtonProps & {
    icon: string,
    variant?: MuiButtonProps['variant'],
    selected?: boolean,
    inherited?: boolean,
    color?: ButtonColor,
    disableElevation?: boolean,
    fullWidth?: boolean,
}

// eslint-disable-next-line react/display-name
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    color = 'secondary', classes = {}, className = '', disableElevation, fullWidth, size = 'medium', icon, variant = 'text', selected = false, inherited, ...rest
}, ref) => {
    const { label: labelClasses = '', ...restClasses } = classes;
    const iconClassName = `jr-${icon}`;
    const selectedClassName = selected ? 'jr-Mui-selected' : '';
    return (
        <MuiIconButton
            ref={ref}
            classes={{ label: `jr-mButton-icon jr-mIcon mui ${iconClassName} ${labelClasses}`, ...restClasses }}
            className={`jr-mButton ${ColorToClass[color]} ${SizeToClass[size]} mui ${VariantToClassName[variant]} ${selectedClassName} ${className}`}
            {...rest}
        />
    )
})
