/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StyleIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"  >
            <g id="jrws/outline/style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" transform="translate(2.500000, 1.500000)">
                    <path d="M10.3694593,12.4177786 C10.9111259,10.60812 11.1819593,8.48346907 11.1819593,6.04382584 C11.1819593,3.60418261 10.6402926,1.3955002 9.55695928,-0.582221399 C8.47362595,1.3955002 7.93195928,3.60418261 7.93195928,6.04382584 C7.93195928,7.90226323 8.20279262,10.0269142 8.74445928,12.4177786 L10.3694593,12.4177786 Z" id="brush-handle" fill="#055DAB" transform="translate(9.556959, 5.917779) rotate(42.000000) translate(-9.556959, -5.917779) "></path>
                    <path d="M0,14.0982276 L0.962763757,15 C4.42532065,14.125399 2.90277203,12.5800405 5,11.2590301 C3.73810509,10.6740506 0.301613571,10.8681264 0,14.0982276 Z" id="brush-tip" fill="#062E79" transform="translate(2.500000, 13.000000) rotate(180.000000) translate(-2.500000, -13.000000) "></path>
                </g>
            </g>
        </svg>
    );
}
export default StyleIcon;