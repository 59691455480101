/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import { RouterProps, withRouter } from '@jss/js-common';
import { Icon, PopoverControl } from '@jss/js-common';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { UserCredential, Conf, Pref } from '@jss/js-rest-api';
import { LOGGED_USERS_KEY } from '../../store/reducers/persistentReducer';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LanguageSelector from './LanguageSelector';
import ThemeSelector from './ThemeSelector';
import i18n from "../../i18n";
import { Link } from 'react-router-dom';
import EmailIcon from '@material-ui/icons/Email';
import '../../assets/uxpl/css/AppHeader.css';

interface IHeaderProps {
    storedData?: Map<string, any>;
    goToHome?: () => void;
    requestLogout?: (callback) => void,
}

export class _AppHeader extends React.Component<IHeaderProps & RouterProps> {

    protected goToHome = () => {
        if (this.props.goToHome) {
            this.props.goToHome();
        }
    }

    protected logoutUser = () => {
        if (this.props.requestLogout) {
            this.props.requestLogout(() => this.props.router?.navigate('/logout'));
        } else {
            this.props.router?.navigate('/logout')
        }
    }

    protected getUserId = () => {
        return this.props.storedData?.get('userId');
    }

    protected isLoggedIn = () => {
        return this.props.storedData?.hasIn([LOGGED_USERS_KEY, 'userId']);
    }

    private getUserButton = () => {
        let logoutButton;
        const loggedUser = this.props.storedData?.get(LOGGED_USERS_KEY);
        if (loggedUser && loggedUser.has('userId')) {
            const getContent = (handleClose: () => void) => {
                let themeSelector = <></>;
                const langs: string[] = Conf.get(Pref.THEME_AVAILABLE);
                if (langs && langs.length > 0) {
                    themeSelector = <MenuItem style={{ height: 40 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <span style={{ marginRight: 10 }}>{i18n.t('appheader.selector.theme.text')}</span>
                            <ThemeSelector />
                        </div>
                    </MenuItem>;
                }
                return <div className="tc-jsw-user-popup">
                    {themeSelector}
                    <MenuItem style={{ height: 40 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <span style={{ marginRight: 10 }}>{i18n.t('appheader.selector.lang.text')}</span>
                            <LanguageSelector />
                        </div>
                    </MenuItem>
                    <Divider className="tc-jsw-selector-divider" />
                    {this.renderEmailFeedback()}
                    <Divider className="tc-jsw-selector-divider" />
                    <MenuItem style={{ height: 40 }} onClick={() => { handleClose(); this.logoutUser() }}>
                        <ListItemIcon>
                            <ExitToAppIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">{i18n.t('appheader.button.logout')}</Typography>
                    </MenuItem>
                </div>;
            }

            const metadata: Map<string, any> | undefined = loggedUser.get('metadata');
            if (metadata) {
                const user = (metadata.toJS() as unknown) as UserCredential;
                const imageSource = UserCredential.getDisplayImage(user);
                const uname = UserCredential.getDisplayName(user);

                const getControl = (handleClick: (event) => void) => {
                    let innerControl;
                    if (imageSource) {
                        innerControl = <img onClick={handleClick} style={{ display: 'flex', flex: 1, borderRadius: '50%', width: '30px', height: '30px', cursor: 'pointer' }} src={imageSource} title={uname} alt="Avatar" />;
                    } else {
                        innerControl =
                            <div onClick={handleClick} className="noUserImage" style={{ fontSize: '1em', fontWeight: 'bold', width: '30px', height: '30px', borderRadius: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} title={uname}>
                                {UserCredential.getDisplayType(user)}
                            </div>
                    }
                    return innerControl;
                }


                logoutButton = (<>
                    <div style={{ display: 'flex', paddingRight: '10px', color: 'white', float: 'left', marginTop: '5px' }}>{uname}</div>
                    <div style={{ display: 'flex', paddingRight: '10px' }}>
                        <PopoverControl id={'userButton'} getControl={getControl} getContent={getContent} />
                    </div>
                </>
                );
            } else {
                const getControl = (handleClick: (event) => void) => {
                    return <div onClick={handleClick} style={{ fontSize: '1em', fontWeight: 'bold', width: '30px', height: '30px', borderRadius: '60px', backgroundColor: '#0081cb', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}></div>;
                }

                logoutButton = (
                    <div style={{ display: 'flex', paddingRight: '10px' }}>
                        <PopoverControl id={'userButton'} getControl={getControl} getContent={getContent} />
                    </div>
                );
            }
        }
        return logoutButton;
    }

    private getNotLoggedButton = () => {
        const getContent = () => {
            let themeSelector = <></>;
            const langs: string[] = Conf.get(Pref.THEME_AVAILABLE);
            if (langs && langs.length > 0) {
                themeSelector = <MenuItem style={{ height: 40 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ marginRight: 10 }}>{i18n.t('appheader.selector.theme.text')}</span>
                        <ThemeSelector />
                    </div>
                </MenuItem>;
            }
            return <div className="tc-jsw-user-popup">
                {themeSelector}
                <MenuItem style={{ height: 40 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ marginRight: 10 }}>{i18n.t('appheader.selector.lang.text')}</span>
                        <LanguageSelector />
                    </div>
                </MenuItem>
                <Divider className="tc-jsw-selector-divider" />
                {this.renderEmailFeedback()}
            </div>;
        }

        const getControl = (handleClick: (event) => void) => {
            return <div onClick={handleClick} className="loggedOutImage" style={{ fontSize: '1em', fontWeight: 'bold', width: '30px', height: '30px', borderRadius: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}/>;
        }

        return <div style={{ display: 'flex', paddingRight: '10px' }}>
            <PopoverControl id={'userButton'} getControl={getControl} getContent={getContent} />
        </div>;
    }

    public render() {
        return <div className="tc-jsw-app-header">
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                {this.isLoggedIn() ? this.renderLogged() : this.renderNotLogged()}
            </div>
        </div>
    }

    private renderLogged() {
        return <>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ cursor: 'pointer' }} onClick={this.goToHome} title={i18n.t('appheader.button.home.tooltip')}>
                    <div className="jrws-banner-navigation">
                        <Icon icon={'home'} size="medium" className="jr-mIconLight" />
                    </div>
                </div>
                <div className="logoAppHeader" />
            </div>
            <div>{this.getUserButton()}</div>
        </>;
    }

    private renderEmailFeedback = () => {
        return <MenuItem style={{ height: 40 }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Link
                    to='#'
                    onClick={(e) => {
                        window.location.href = 'mailto:js-product-team@tibco.com?subject=[JRWS FEEDBACK]';
                        e.preventDefault();
                    }}
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flex: 1 }}
                >
                    <EmailIcon />
                    <span style={{ marginRight: 10, marginLeft: 10 }}>{i18n.t('appheader.selector.lang.sendfeedback')}</span>
                </Link>

            </div>
        </MenuItem>;
    }

    private renderNotLogged() {
        let homeControl;
        if (this.props.router?.location.pathname !== '/') {
            homeControl = <div style={{ cursor: 'pointer' }} onClick={this.goToHome} title={i18n.t('appheader.button.home.tooltip')}>
                <div className="jrws-banner-navigation">
                    <Icon icon={'home'} size="medium" className="jr-mIconLight" />
                </div>
            </div>;
        }
        return <>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {homeControl}
                <div className="logoAppHeader" />
            </div>
            <div>{this.getNotLoggedButton()}</div>
        </>;
    }
}



const mapStateToProps = (state: any) => {
    return {
        storedData: state.persistentReducer.storedData,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export const AppHeader = withRouter<IHeaderProps>(connect(mapStateToProps, mapDispatchToProps)(_AppHeader));
