/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */



import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../reducers';
import { Map } from 'immutable';
import { resolveAttribute } from '../properties/types/common/StyleResolver';

interface IRectangleElement {
  id: string,
  element?: Map<string, any>,
  model?: Map<string, any>,
  zoom?: number,
  availableWidth: number,
  availableHeight: number,
}

const inheritedProperties = ['pen.lineColor', 'pen.lineWidth', 'pen.lineStyle', 'backcolor', 'forecolor', 'mode'];


class EllipseElement extends React.Component<IRectangleElement> {

  public shouldComponentUpdate = (nextProps: Readonly<IRectangleElement>) => {
    if (this.props.availableHeight !== nextProps.availableHeight || this.props.availableWidth !== nextProps.availableWidth) {
      return true;
    }

    if (this.props.zoom !== nextProps.zoom) {
      return true;
    }

    for (const propName of inheritedProperties) {
      if (resolveAttribute(this.props.model, this.props.element, propName) !== resolveAttribute(nextProps.model, nextProps.element, propName)) {
        return true;
      }
    }

    return false; // Nothing impacting the content of the figure is changed...
  }

  getSvgStyle = (): React.CSSProperties => {
    const zoom = this.props.zoom;
    let lineColor = resolveAttribute(this.props.model, this.props.element, 'pen.lineColor', { fallbackToDefault: false });
    const lineWidth = resolveAttribute(this.props.model, this.props.element, 'pen.lineWidth');
    const lineStyle = resolveAttribute(this.props.model, this.props.element, 'pen.lineStyle');
    const mode = resolveAttribute(this.props.model, this.props.element, 'mode');
    let backgroundColor = 'transparent';
    if (mode === 'Opaque') {
      backgroundColor = resolveAttribute(this.props.model, this.props.element, 'backcolor');
    }

    if (!lineColor) {
      lineColor = resolveAttribute(this.props.model, this.props.element, 'forecolor');
    }

    const result: React.CSSProperties = {
      stroke: lineColor,
      strokeWidth: lineWidth * zoom,
      fill: backgroundColor,
    };
    switch (lineStyle.toLowerCase()) {
      case 'dotted':
        {
          result.strokeDasharray = `${lineWidth * zoom},${lineWidth * zoom}`;
          break;
        }
      case 'dashed':
        {
          result.strokeDasharray = `${5 * lineWidth * zoom},${3 * lineWidth * zoom}`;
          break;
        }
      case 'double': //FIXME: there is no built-in svg support for double stroke style; strokes could be rendered twice as a workaround
      case 'solid':
      default:
        {
          break;
        }
    }
    return result;
  }

  private getPen = (borderWidth: number, borderStyle: string, borderColor: string, side?: string): React.CSSProperties => {
    let currentBorderWidth = borderWidth;
    if (0 < borderWidth && borderWidth < 1) {
      currentBorderWidth = 1;
    }

    let currentBorderStyle;
    const cleanedStyle = borderStyle ? borderStyle.toLowerCase().trim() : borderStyle;
    switch (cleanedStyle) {
      case 'double':
        {
          currentBorderStyle = "double";
          break;
        }
      case 'dotted':
        {
          currentBorderStyle = "dotted";
          break;
        }
      case 'dashed':
        {
          currentBorderStyle = "dashed";
          break;
        }
      case 'solid':
      default:
        {
          currentBorderStyle = "solid";
          break;
        }
    }

    const result: React.CSSProperties = {};
    if (currentBorderWidth > 0) {
      const value = `${(currentBorderWidth)}px ${currentBorderStyle} ${borderColor}`;
      if (side) {
        switch (side) {
          case 'left':
            result.borderLeft = value;
            break;
          case 'right':
            result.borderRight = value;
            break;
          case 'top':
            result.borderTop = value;
            break;
          case 'bottom':
            result.borderBottom = value;
            break;
        }
      } else {
        result.border = value;
      }

    }

    return result;
  }

  public render() {
    const width = this.props.availableWidth;
    const height = this.props.availableHeight;
    const zoom = this.props.zoom;
    let globalWidth = resolveAttribute(this.props.model, this.props.element, 'pen.lineWidth', { fallbackToDefault: false });
    let noContainerBorder = true;
    if (!globalWidth || globalWidth <= 0) {
      globalWidth = 1;
      //the element has no line, to make it more visible draw a light line around it 
      noContainerBorder = false;
    }

    if (noContainerBorder) {
      const containerStyle: React.CSSProperties = {
        display: 'flex',
        top: - (globalWidth / 2) * zoom,
        left: - (globalWidth / 2) * zoom,
        width: width + (globalWidth * zoom),
        height: height + (globalWidth * zoom),
        position: 'absolute'
      }
      return <div style={containerStyle}>
        <svg width={width + (globalWidth * zoom)} height={height + (globalWidth * zoom)}>
          <ellipse style={this.getSvgStyle()} cx={(width / 2) + (globalWidth * zoom) / 2} cy={(height / 2) + (globalWidth * zoom) / 2} rx={(width / 2)} ry={(height / 2)} height={height} width={width} />
        </svg>
      </div>;
    } else {
      const containerStyle = {
        top: 0,
        left: 0,
        width: width,
        height: height,
        display: 'flex',
        ...this.getPen(globalWidth * this.props.zoom, 'solid', '#CCCCCC'),
      }
      return <div style={containerStyle}>
        <svg height={height} width={width}>
          <ellipse style={this.getSvgStyle()} cx={width / 2 - globalWidth * zoom} cy={height / 2 - globalWidth * zoom} rx={(width / 2 - globalWidth * zoom)} ry={(height / 2 - globalWidth * zoom)} height={height} width={width} />
        </svg>
      </div>;
    }
  }
}

const mapStateToProps = (state: IState, props: IRectangleElement) => {

  return {
    element: state.getIn(['report', 'model', 'elements', props.id]),
    model: state.getIn(['report', 'model']),
    zoom: state.getIn(['report', 'zoom']),
  };
}

export default connect(mapStateToProps)(EllipseElement);   // ,mapDispatchToProps