/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map } from 'immutable';
import { ElementTypes } from '../../../../sagas/report/document/elementTypes';
import { createSectionHyperLinkForElement } from './JrxmlChartUtils';
import { addAttributeToMap, addAttributeToMapWithName, addBooleanAttributeToMap, addChildToParent, addChildValueToMapWithName, createChartCrosstabDataset, createReportElement, generatePath, getChildNodeByName, getNamespace, IObjectCounter, readCDataText } from './JrxmlHelpers';


const PIE_CHART_NAME = "Pie";
const PIE3D_CHART_NAME = "Pie3D";
const BAR_CHART_NAME = "Bar";
const BAR3D_CHART_NAME = "Bar3D";
const STACKED_BAR_CHART_NAME = "StackedBar";
const STACKED_BAR3D_CHART_NAME = "StackedBar3D";
const LINE_CHART_NAME = "Line";
const AREA_CHART_NAME = "Area";
const COLUMN_CHART_NAME = 'Column';
const COLUMN3D_CHART_NAME = 'Column3D';
const DOUGHNUT_CHART_NAME = 'Doughnut';
const DOUGHNUT3D_CHART_NAME = 'Doughnut3D';
const STACKED_COLUMN_CHART_NAME = 'StackedColumn';
const STACKED3D_COLUMN_CHART_NAME = 'StackedColumn3D';
const STACKED_AREA_CHART_NAME = 'StackedArea';

export const FUSIONCHARTS_NAMESPACE = "FUSIONCHARTS_XMLNS_NAMESPACE";

export const FusionChartTypes = {
    PIE_CHART_NAME,
    PIE3D_CHART_NAME,
    BAR_CHART_NAME,
    BAR3D_CHART_NAME,
    STACKED_BAR_CHART_NAME,
    STACKED_BAR3D_CHART_NAME,
    LINE_CHART_NAME,
    AREA_CHART_NAME,
    STACKED_AREA_CHART_NAME,
    COLUMN_CHART_NAME,
    COLUMN3D_CHART_NAME,
    DOUGHNUT_CHART_NAME,
    DOUGHNUT3D_CHART_NAME,
    STACKED_COLUMN_CHART_NAME,
    STACKED3D_COLUMN_CHART_NAME,
}
/**
 * Checks if the specified component is a real HTML5 chart.
 *
 * @param componentElement the component element to check
 */
export const isFusionChart = (componentElement: Element): boolean => {
    const childList = componentElement.childNodes;
    let found = false;
    for (let i = 0; i < childList.length && !found; i++) {
        const child = childList[i];
        const nodeName = child.nodeName;
        if (child.nodeType === Node.ELEMENT_NODE && nodeName !== "reportElement") {
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : baseNamespace;
            if (namespace + 'chart' === nodeName) {
                //check the xmlns to avoid conflict with fusion
                const xmlns = (child as Element).getAttribute('xmlns:' + baseNamespace);
                found = xmlns && xmlns.includes('fusion');
            }
        }
    }
    return found;
}

const createFusionPropertyExpression = (itemElement: Element, namespace: string): {name: string, value: Map<string, any>} => {
    let result = Map<string, any>();
    const attName = itemElement.getAttribute("name");
    const attValueNode = getChildNodeByName(itemElement, namespace + "propertyExpression");
    if (attValueNode !== null) {
        const attValue = readCDataText(attValueNode);
        result = result.set('propertyExpression', attValue);
    }
    return {name: attName, value: result};
}

const createChartDatasetItem = (itemElement: Element, namespace: string): Map<string, any> => {
    let result: Map<string, any> = Map<string, any>();
    result = addChildValueToMapWithName(itemElement, namespace + "seriesExpression", "seriesExpression", result);
    result = addChildValueToMapWithName(itemElement, namespace + "labelExpression", "labelExpression", result);
    result = addChildValueToMapWithName(itemElement, namespace + "valueExpression", "valueExpression", result);
    result = addChildValueToMapWithName(itemElement, namespace + "displayValueExpression", "displayValueExpression", result);
    const hyperlink = getChildNodeByName(itemElement, namespace + "hyperlink");
    if (hyperlink !== null) {
        let hyperLinkMap = Map<string, any>();  
        hyperLinkMap = createSectionHyperLinkForElement(hyperlink as Element, hyperLinkMap);
        if (hyperLinkMap && hyperLinkMap.size > 0){
            result = result.set('hyperlink', hyperLinkMap)
        }
    }
    return result;
}

const createChartTrendLine = (itemElement: Element, namespace: string): Map<string, any> => {
    let result: Map<string, any> = Map<string, any>();
    result = addBooleanAttributeToMap(itemElement, 'trendZone', result);
    result = addAttributeToMap(itemElement, 'color', result);
    result = addChildValueToMapWithName(itemElement, namespace + "startValueExpression", "startValueExpression", result);
    result = addChildValueToMapWithName(itemElement, namespace + "endValueExpression", "endValueExpression", result);
    result = addChildValueToMapWithName(itemElement, namespace + "labelExpression", "labelExpression", result);
    return result;
}

/**
 * Create the JSON structure to represent the HTML5 chart of JRXML.
 *
 * @param componentElementNode the HTML5 chart component node
 * @param parentElement the parent map containing details
 * @param document the current JSON representation of the JRXML
 * @param objectCounter accessory information for producing valid indexes
 */
export const createFusionChartElement = (componentElementNode: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) => {
    const reportElementNode: Element = getChildNodeByName(componentElementNode, "reportElement") as Element;
    const pathValue = generatePath(parentElement);
    let result: Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.FUSIONCHART, objectCounter);

    let newDocument = document;

    componentElementNode.childNodes.forEach(compChild => {
        if (compChild.nodeType === Node.ELEMENT_NODE && compChild.nodeName !== "reportElement") {
            const baseNamespace = getNamespace(compChild as Element);
            result = result.set(FUSIONCHARTS_NAMESPACE, baseNamespace);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : "";
            if (compChild.nodeName === namespace + 'chart') {
                const fusionChartNode = compChild as Element;
                result = addAttributeToMapWithName(fusionChartNode, "type", "chartType", result);
                result = addAttributeToMap(fusionChartNode, "evaluationTime", result);
                result = addAttributeToMap(fusionChartNode, "evaluationGroup", result);
                fusionChartNode.childNodes.forEach(fusionChartChild => {
                    if (fusionChartChild.nodeName === namespace + 'chartProperty') {
                        const {name, value} = createFusionPropertyExpression(fusionChartChild as Element, namespace);
                        result = result.updateIn(["chartProperties"], map => (map as Map<string, Map<string, any>> || Map<string, Map<string, any>>()).set(name, value));
                    } else if (fusionChartChild.nodeName === namespace + 'chartDataset') {
                        let chartDataset = Map<string, any>();
                        let items: List<Map<string, string>> = List<Map<string, string>>();
                        fusionChartChild.childNodes.forEach(element => {
                            const elementName = element.nodeName;
                            if (elementName === "dataset") {
                                chartDataset = chartDataset.set("dataset", createChartCrosstabDataset(element as Element, objectCounter));
                            } else if (elementName === namespace + "item") {
                                items = items.push(createChartDatasetItem(element as Element, namespace));
                            }
                        });
                        if (items.size > 0) {
                            chartDataset = chartDataset.set('items', items);
                        }
                        if (!chartDataset.isEmpty()) {
                            result = result.set("chartDataset", chartDataset);
                        }
                    } else if (fusionChartChild.nodeName === namespace + 'hyperlink') {
                        let hyperlink = Map<string, any>();
                        hyperlink = createSectionHyperLinkForElement(fusionChartChild as Element, result);
                        result = result.set('hyperlink', hyperlink);
                    } else if (fusionChartChild.nodeName === namespace + 'trendLine') {
                        const trendLine = createChartTrendLine(fusionChartChild as Element, namespace);
                        result = result.updateIn(["trendLines"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(trendLine));
                    }
                });
            }
        }
    });
    newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, result.get("id"));
    newDocument = newDocument.set("elements", newDocument.get("elements").set(result.get("id"), result));

    return newDocument;
}