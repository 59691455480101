/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

/* eslint @typescript-eslint/no-var-requires: "off" */
import axios, { AxiosError } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { setJRSURL } from '../../store/actions/actions';
import { JRS_URLS } from '../../store/reducers/persistentReducer';
import { List, Map } from 'immutable';
import { Footer, RouterProps, withRouter } from '@jss/js-common';
import '../../assets/uxpl/css/JRSLoginPage.css';
import { MessageInfo, error, msg, Conf } from '@jss/js-rest-api';
import LinearProgress from '@material-ui/core/LinearProgress';
import { AppHeader } from '../common/AppHeader';
import { Base64 } from 'js-base64';
import { RepositoryJRS } from '@jss/js-rest-api/src/jrs/RepositoryJRS';
import i18n from '../../i18n';

interface IJRSLoginPage {
    storedData?: Map<string, any>,
    setJRSURL?: (url: string) => void,
}

interface IJRSLoginPageState {
    serverAddress?: string,
    organization: string,
    username: string,
    password: string,
    show?: string | MessageInfo,
    showLocaleAndTimezone: boolean,
}

class _JRSLoginPageREST extends React.Component<IJRSLoginPage & RouterProps, IJRSLoginPageState> {

    public state: IJRSLoginPageState = {
        showLocaleAndTimezone: false,
        username: '',
        password: '',
        organization: ''
    }


    private onOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ organization: event.currentTarget.value.trim() });
    }

    private onServerAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let saddr = event.currentTarget.value.trim();
        if (!saddr.startsWith('http://localhost'))
            saddr = saddr.replace('http://', 'https://');
        saddr = saddr.replace('/login.html', '/');
        this.setState({ serverAddress: saddr });
    }

    private onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ username: event.currentTarget.value.trim() });
    }

    private onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ password: event.currentTarget.value.trim() });
    }

    private canLogin = () => {
        return this.state.username.trim().length > 0 && this.state.password.trim().length > 0 && this.getServerAddress().length > 0;
    }

    private getLastUrl = () => {
        const urlsList = (this.props.storedData?.get(JRS_URLS, List<string>()) as List<string>);
        if (urlsList.size > 0) {
            return urlsList.get(urlsList.size - 1) as string;
        }
        return '';
    }

    private getServerAddress = () => {
        if (this.state.serverAddress !== undefined) {
            return this.state.serverAddress;
        } else {
            return this.getLastUrl();
        }
    }

    private onLogin = () => {
        if (this.canLogin()) {
            const organization = this.state.organization.trim();
            const serverAddress = this.getServerAddress().trim();
            if (this.props.setJRSURL)
                this.props.setJRSURL(serverAddress);

            const params = new URLSearchParams();
            params.append('j_username', this.state.username);
            params.append('j_organization', organization);
            params.append('j_password', this.state.password);
            params.append('jrs', serverAddress);

            this.setState({ show: 'loading' });
            axios.post('/repo' + Conf.get('jrws.url.rest.login.js'), params).then((response) => {
                const uname = this.state.username + (organization && organization.length > 0 ? `|${organization}` : '');
                RepositoryJRS.authenticateClient(serverAddress, uname, this.state.password).catch(e => { console.log(e); });

                const info = Base64.encode(`serverAddress=${serverAddress}&username=${uname}&password=${this.state.password}`);
                this.props.router?.navigate(`/loggedin?provider=js&user=${response.data}&info=${info}`);
            }).catch((e: AxiosError) => {
                this.setState({ show: error(e) });
            });
        }
    }

    protected goToHome = () => {
        this.props.router?.navigate('/');
    }

    public render = () => {
        let textDisableClassName = '';
        let buttonDisableClassName = '';
        if (!this.canLogin()) {
            textDisableClassName = 'textDisabled';
            buttonDisableClassName = 'buttonDisabled';
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <AppHeader goToHome={this.goToHome} />
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                    <div className="loginPage">
                        <div className="loginPanel">
                            <div className="inputContainer"
                                onKeyDown={
                                    (e) => {
                                        if (e.key === "Enter" &&
                                            this.state.username.length &&
                                            this.state.password.length &&
                                            this.getServerAddress().length) {
                                            this.onLogin();
                                        }
                                    }
                                }>
                                <div className="logo" />
                                {this.showStatus()}
                                <div className="usernameContainer">
                                    <span className="labelLogin">{i18n.t('home.login.js.organization')}</span>
                                    <input onChange={this.onOrganizationChange} value={this.state.organization} className="inputLogin" id="organization" name="j_organization" type="text" autoCapitalize="off" aria-labelledby="usernamelabel" autoComplete="off" />
                                </div>
                                <div className="usernameContainer">
                                    <span className="labelLogin">{i18n.t('home.login.js.userid')}</span>
                                    <input onChange={this.onUsernameChange} value={this.state.username} className="inputLogin" id="username" name="j_username" type="text" autoCapitalize="off" autoFocus={true} aria-labelledby="usernamelabel" autoComplete="off" />
                                </div>
                                <div className="passwordContainer">
                                    <span className="labelPassword">{i18n.t('home.login.password')}</span>
                                    <input onChange={this.onPasswordChange} value={this.state.password} className="inputPassword" id="password" name="j_password" type="password" autoCapitalize="off" aria-labelledby="usernamelabel" autoComplete="off" />
                                </div>
                                <div className="usernameContainer">
                                    <span className="labelLogin">{i18n.t('home.login.js.serverurl')}</span>
                                    <input onChange={this.onServerAddressChange} value={this.getServerAddress()} className="inputLogin" id="serverAddress" name="j_serverAddress" type="text" autoCapitalize="off" aria-labelledby="usernamelabel" autoComplete="off" placeholder="https://hostname/jasperserver-pro" />
                                </div>
                            </div>
                            <div className="buttonContainer">
                                <button type="button" id="submitButton" className={`loginButton ${buttonDisableClassName}`} onClick={this.onLogin}>
                                    <span className={`loginButtonLabel ${textDisableClassName}`}>{i18n.t('home.login.button')}</span>
                                </button>
                            </div>
                            <div className="passwordContainer" style={{ padding: '20px' }}>
                                {i18n.t('home.login.js.info')}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer config={Conf.get('jrws.jrio.serverInfo')} />
            </div>
        );
    }
    private showStatus = () => {
        if (this.state.show === 'loading') {
            return <LinearProgress />;
        }
        const m = msg(this.state.show);
        if (m) {
            return <div style={{ color: 'red', maxHeight: 200, overflow: 'auto' }}>{m}</div>;
        }
    }

}

const mapStateToProps = (state: any) => {
    return {
        storedData: state.persistentReducer.storedData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setJRSURL: (url: string) => dispatch(setJRSURL(url)),
    };
};

export const JRSLoginPageREST = withRouter(connect(mapStateToProps, mapDispatchToProps)(_JRSLoginPageREST));
