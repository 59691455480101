/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { MLinePen } from "../common/MLinePen";
import { MGraphicElement } from "../common/MGraphicElement";
import { ICONS } from "@jss/js-common";
import { MEllipseCustom } from "./MEllipseCustom";

export const FEllipse = {
    
};

export const MEllipse: APDescriptor = {
    type: ElementTypes.ELLIPSE,
    id: 'ellipseProeprtiesDescriptor',
    label: 'MEllipse.property.ellipse.label',
    default: { width: 100, height: 50 },
    icon: ICONS.ELLIPSE_ICON,
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'MEllipse.property.appearance.label', layouts: [...MGraphicElement.layouts, MEllipseCustom] }),
            new PDiv({ label: 'MEllipse.property.ellipse.label', layouts: [ MLinePen] })
        ]
    })]
};
