/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import { connect } from 'react-redux';

import { IState } from '../../../reducers';
import { resolveAttribute } from '../properties/types/common/StyleResolver';
import GenericTextElement from './GenericTextElement';
import { Map } from 'immutable';

interface ITextFieldElement {
  id: string;
  element?: Map<string, any>;
  model?: Map<string, any>;
  zoom?: number;
  availableWidth: number,
  availableHeight: number,
  fontCache?: Map<string, any>,
}

const visualProperties = ['textFieldExpression'];

const inheritedProperties = ['font.size', 'font.isBold', 'font.isItalic', 'font.isUnderline', 'font.isStrikeThrough', 'font.fontName', 'rotation', 'textAlignment',
  'verticalAlignment', 'paragraph.firstLineIndent', 'paragraph.lineSpacing', 'paragraph.lineSpacingSize', 'paragraph.leftIndent', 'paragraph.rightIndent'];

class TextFieldElement extends React.Component<ITextFieldElement> {


  public shouldComponentUpdate = (nextProps: Readonly<ITextFieldElement>) => {
    if (this.props.availableHeight !== nextProps.availableHeight || this.props.availableWidth !== nextProps.availableWidth) {
      return true;
    }

    const oldFontsMap = this.props.fontCache.get('fonts');
    const fontsMap = nextProps.fontCache.get('fonts');
    if (oldFontsMap !== fontsMap) {
      return true;
    }
    const oldPropertiesMap = this.props.fontCache.get('contextPropertyValues');
    const propertiesMap = nextProps.fontCache.get('contextPropertyValues');
    if (oldPropertiesMap !== propertiesMap) {
      return true;
    }

    if (this.props.zoom !== nextProps.zoom) {
      return true;
    }
    for (const propName of visualProperties) {
      if (this.props.element.get(propName) !== nextProps.element.get(propName)) {
        return true;
      }
    }

    for (const propName of inheritedProperties) {
      if (resolveAttribute(this.props.model, this.props.element, propName) !== resolveAttribute(nextProps.model, nextProps.element, propName)) {
        return true;
      }
    }

    return false; // Nothing impacting the content of the figure is changed...
  }

  public render() {
    const defaultFontSize = this.props.fontCache.getIn(['contextPropertyValues', 'net.sf.jasperreports.default.font.size']);
    const defaultFontName = this.props.fontCache.getIn(['contextPropertyValues', 'net.sf.jasperreports.default.font.name']);
    const fontSize = resolveAttribute(this.props.model, this.props.element, 'font.size', { fallbackToDefault: true, overrideDefault: defaultFontSize }) * this.props.zoom;
    let fontName = resolveAttribute(this.props.model, this.props.element, 'font.fontName', { fallbackToDefault: true, overrideDefault: defaultFontName });
    const isBold = resolveAttribute(this.props.model, this.props.element, 'font.isBold');
    const isItalic = resolveAttribute(this.props.model, this.props.element, 'font.isItalic');
    const isUnderline = resolveAttribute(this.props.model, this.props.element, 'font.isUnderline');
    const isStrikeThrough = resolveAttribute(this.props.model, this.props.element, 'font.isStrikeThrough');
    const rotation = resolveAttribute(this.props.model, this.props.element, 'rotation');
    const textAlignment = resolveAttribute(this.props.model, this.props.element, 'textAlignment');
    const verticalAlignment = resolveAttribute(this.props.model, this.props.element, 'verticalAlignment');
    const firstLineIndent = resolveAttribute(this.props.model, this.props.element, 'paragraph.firstLineIndent') * this.props.zoom;
    const lineSpacingEnum = resolveAttribute(this.props.model, this.props.element, 'paragraph.lineSpacing');
    const lineSpacingSize = resolveAttribute(this.props.model, this.props.element, 'paragraph.lineSpacingSize') * this.props.zoom;
    const leftIndent = resolveAttribute(this.props.model, this.props.element, 'paragraph.leftIndent') * this.props.zoom;
    const rightIndent = resolveAttribute(this.props.model, this.props.element, 'paragraph.rightIndent') * this.props.zoom;
    const width = this.props.availableWidth;
    const height = this.props.availableHeight;
    //fontname need to be translated to font family
    const fontFamily = this.props.fontCache.getIn(['fonts', fontName, 'family']);
    if (fontFamily) {
      fontName = fontFamily;
    }

    return (
      <div style={{ width: '100%', height: '100%', display: 'flex' }}>
        <GenericTextElement fontSize={fontSize} isBold={isBold} isItalic={isItalic} isUnderline={isUnderline} isStrikeTrough={isStrikeThrough}
          rotation={rotation} verticalAlignment={verticalAlignment} horizontalAlignment={textAlignment} text={this.props.element.get('textFieldExpression')}
          width={width} height={height} firstLineIndent={firstLineIndent} lineSpacingEnum={lineSpacingEnum} lineSpacingSize={lineSpacingSize}
          leftIndent={leftIndent} rightIndent={rightIndent} fontName={fontName} />
      </div>
    );
  }


}

const mapStateToProps = (state: IState, props: ITextFieldElement) => {

  return {
    element: state.getIn(['report', 'model', 'elements', props.id]),
    model: state.getIn(['report', 'model']),
    zoom: state.getIn(['report', 'zoom']),
    fontCache: state.getIn(['report', 'cachedData', 'comboValues'], Map<string, any>()),
  };
}

// const mapDispatchToProps = dispatch => {
//   return { 
//     selectElement: (id: string, add: boolean) => {
//       dispatch( selectElement(id, add) );
//     },
//   };
// }

export default connect(mapStateToProps)(TextFieldElement);   // ,mapDispatchToProps