/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { TextInput } from '@jss/js-common';
import { advancedNameValidator, isValidUrl, ValidationResult, VALIDATION_RESULT } from '@jss/js-common/src/utils/validators';
import * as React from 'react';
import '../../assets/css/DataAdapterTypePicker.css';
import i18n from '../../i18n';

interface IJRSDatasourceDataAdapterEditor {
    name?: string,
    serverUrl?: string,
    username?: string,
    password?: string,
    organization?: string,
    useSSO?: boolean,
    datasourceUrl?: string,
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

export const jrsUrlValidator = (value: string | undefined): ValidationResult => {
    if (!value || value.trim().length === 0) {
        return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.url.invalidURL') };
    } else {
        if (!value.startsWith('http://') && !value.startsWith('https://')) {
            return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.url.invalidJRSURL') };
        }
        //it is an url validate it
        if (!isValidUrl(value)) {
            return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.url.invalidURL') };
        }
    }
    return { result: VALIDATION_RESULT.VALID };
}

export const simpleJrsUrlValidator = (value: string | undefined) => {
    if (!value || value.trim().length === 0){
        return i18n.t('validator.url.invalidURL');
    } else {
        if (!value.startsWith('http://') && !value.startsWith('https://')){
            return i18n.t('validator.url.invalidJRSURL');
        }
        //it is an url validate it
        if (!isValidUrl(value)){
            return i18n.t('validator.url.invalidURL');
        }
    }
    return undefined;
}

export const serverPathValidator = (value: string | undefined): ValidationResult => {
    if (value) {
        const pattern = /[^a-z^A-Z^0-9\/_]/;
        if (value.match(pattern)) {
            return { result: VALIDATION_RESULT.VALID, message: i18n.t('validator.url.invalidURL') };
        }
        //it is an url validate it
        if (!isValidUrl(value)) {
        }
    }
    return { result: VALIDATION_RESULT.VALID };
}

export class JRSDatasourceDataAdapterEditor extends React.Component<IJRSDatasourceDataAdapterEditor> {

    public render() {
        return (<div className="tc-jsw-dataadapter-editor">

            <div className="tc-jsw-form-wrapper" style={{ flex: 1, alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}>

                <TextInput label={i18n.t('datasource.jrsdatasource.name')}
                    help={i18n.t('datasource.jrsdatasource.name.help')}
                    id="data-adapter-name"
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.jrsdatasource.descriptor.serverurl')}
                    id="server-url"
                    value={this.props.serverUrl}
                    onChange={this.onServerUrlChange}
                    advancedValidator={jrsUrlValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.jrsdatasource.descriptor.organizzation')}
                    id="organization"
                    value={this.props.organization}
                    autoComplete="off"
                    onChange={this.onOrganizationChange}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.jrsdatasource.descriptor.username')}
                    id="username"
                    value={this.props.username}
                    autoComplete="off"
                    onChange={this.onUsernameChange}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.jrsdatasource.descriptor.password')}
                    id="password"
                    type="password"
                    value={this.props.password}
                    autoComplete="off"
                    onChange={this.onPasswordChange}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.jrsdatasource.descriptor.datasourceurl')}
                    id="datasourceurl"
                    value={this.props.datasourceUrl}
                    onChange={this.onDatasourceUrlChange}
                    autoComplete="off"
                    advancedValidator={serverPathValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />
            </div>
        </div>);
    }

    private onServerUrlChange = (str: string) => {
        this.notifyChange({ serverUrl: str });
    }

    private onDatasourceUrlChange = (str: string) => {
        this.notifyChange({ datasourceUrl: str });
    }

    private onPasswordChange = (str: string) => {
        this.notifyChange({ password: str });
    }

    private onUsernameChange = (str: string) => {
        this.notifyChange({ username: str });
    }

    private onOrganizationChange = (str: string) => {
        this.notifyChange({ organization: str });
    }

    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }
}