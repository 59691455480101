/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { IRepositoryAPI, JobResult } from '../repo/IRepositoryAPI';
import { IRepositoryItemDescriptor, RESOURCE_TYPE } from '../repo/IRepositoryItemDescriptor';
import { Base64 } from 'js-base64';
import { fixMimeType } from '../repo/mimeUtil';
import { isBinary, Mimes } from '../repo/mimes';
import { ResourceFieldJRS } from './ResourceFieldJRS';

interface JrsDescriptor {
    version?: number,
    permissionMask?: number,
    description?: string,
    label?: string,
    uri: string,
    type?: string,
    content?: string
}
export class RepositoryJRS implements IRepositoryAPI {
    private base: string;
    private repositoryBaseURL = '';
    private userid = '';
    private temp: RepositoryJRS;
    private info?: string;

    constructor(base: string, userid: string, info?: string) {
        this.userid = userid;
        this.base = base;
        this.info = info;
        this.repositoryBaseURL = base ? base.substring(4) : "";
        this.repositoryBaseURL = `${this.repositoryBaseURL}${this.repositoryBaseURL.endsWith('/') ? '' : '/'}rest_v2/`;
    }

    public getBaseURL = (): string => {
        return this.repositoryBaseURL.substring(0, this.repositoryBaseURL.length - 8);
    }
    public getInfo = (): string | undefined => {
        return this.info;
    }
    public setBaseURL = (baseURL: string, userid: string) => {
        this.userid = userid;
        this.repositoryBaseURL = baseURL;
    }

    public tmp(): IRepositoryAPI {
        return this;
        // if (!this.temp) {
        //     this.temp = new RepositoryJRS(this.base, this.userid);
        //     this.temp.setBaseURL(`${this.repositoryBaseURL}temp/${this.userid}`, this.userid);
        // }
        // return this.temp;
    }

    public getRoot(): IRepositoryItemDescriptor {
        return { type: RESOURCE_TYPE.FOLDER, name: "" };
    }
    private getType(jrstype: string): RESOURCE_TYPE {
        switch (jrstype) {
            case 'adhocDataView':
            case 'dashboard':
            case 'reportUnit': return RESOURCE_TYPE.CONTAINER;
            case 'folder': return RESOURCE_TYPE.FOLDER;
            default: return RESOURCE_TYPE.FILE;
        }
    }
    private getJRSContentType(descriptor: IRepositoryItemDescriptor) {
        if (descriptor.type === RESOURCE_TYPE.FOLDER) {
            return 'folder';
        }
        if (descriptor.type === RESOURCE_TYPE.CONTAINER) {
            if (descriptor.mime === 'reportUnit')
                return 'reportUnit';
            if (descriptor.mime === 'adhocDataView')
                return 'adhocDataView';
            if (descriptor.mime === 'dashboard')
                return 'dashboard';
            // return 'folder';
        }
        if (descriptor.type === RESOURCE_TYPE.FILE) {
            return 'file';
        }
        return 'unknown';
    }
    private getJRSType(descriptor: IRepositoryItemDescriptor) {
        if (descriptor.type === RESOURCE_TYPE.FOLDER) {
            return 'folder';
        }
        if (descriptor.type === RESOURCE_TYPE.FILE) {
            return this.field().decode('mime', descriptor.mime);
        }
        if (descriptor.type === RESOURCE_TYPE.CONTAINER) {
            return this.field().decode('mime', descriptor.mime);
        }
        return descriptor.type;
    }
    private getDescriptor(f: {
        uri: any; resourceType: string; label: string; description?: string;
        creationDate?: any; updateDate?: any; type?: string; version?: number; permissionMask?: number
    }): IRepositoryItemDescriptor {
        let p = '/';
        const indx = f.uri.lastIndexOf('/');
        if (indx >= 1) {
            p = f.uri.substr(0, indx);
        }
        const d: IRepositoryItemDescriptor = {
            uuid: f.uri,
            type: this.getType(f.resourceType),
            name: f.label,
            description: f.description,
            created: f.creationDate,
            lastModified: f.updateDate,
            path: p,
            mime: this.field().encode('mime', f.type ? f.type : f.resourceType),
            version: f.version,
            permission: f.permissionMask ? this.getPermission(f.permissionMask) : 0
        };
        Mimes.mime4extension(d);
        return d;
    }

    private getJrsDescriptor(path: string, descriptor: IRepositoryItemDescriptor): JrsDescriptor {
        if (descriptor.path) {
            path = descriptor.path;
        }
        const d: JrsDescriptor = {
            version: descriptor.version ? descriptor.version : 0,
            permissionMask: descriptor.permissionMask ? descriptor.permissionMask : 1,
            label: descriptor.name,
            uri: descriptor.uuid ? descriptor.uuid : path + (path && !path.endsWith('/') ? '/' : '') + this.field().encode('id', descriptor.name),
            type: this.getJRSType(descriptor)
        }
        if (descriptor.description?.trim()) {
            d.description = descriptor.description;
        }
        if (descriptor.data?.trim()) {
            d.content = descriptor.data;
        }
        return d;
    }
    private getPermission(pmask: number): number {
        if (pmask == 1 || (pmask & 16) === 16) { return 6; }
        if ((pmask & 2) === 2) { return 4 }
        return 0;
    }

    private getDescriptor4Data(result: AxiosResponse<any>): AxiosResponse<any> {
        const item = result.data;
        let ctype = result.headers['content-type'];
        if (!item.resourceType && ctype) {
            if (ctype.startsWith('application/repository.folder+')) ctype = RESOURCE_TYPE.FOLDER;
            else if (ctype.startsWith('application/repository.reportUnit+') || ctype.startsWith('application/repository.adhocDataView+') || ctype.startsWith('application/repository.dashboard+')) ctype = RESOURCE_TYPE.CONTAINER;
            else ctype = this.getType(item.type);
        } else {
            ctype = this.getType(item.resourceType)
        }
        let parentPath = item.uri.substr(0, item.uri.lastIndexOf('/'));
        parentPath = parentPath.startsWith('/') ? parentPath : '/' + parentPath;
        const pd: IRepositoryItemDescriptor = {
            uuid: item.uri,
            type: ctype as RESOURCE_TYPE,
            mime: ctype === RESOURCE_TYPE.FILE && item.type !== undefined ? item.type : result.headers['content-type'],
            name: item.label,
            description: item.description,
            created: item.creationDate,
            lastModified: item.updateDate,
            path: parentPath,
            version: item.version,
            permission: this.getPermission(item.permissionMask),
            permissionMask: item.permissionMask
            // here should convert permission too
        };
        if (item.data) {
            pd.data = Base64.decode(item.data);
        }
        if (pd.mime?.startsWith('application/repository.file') || pd.mime?.startsWith('application/json') || pd.type == RESOURCE_TYPE.FILE) {
            fixMimeType(pd, this);
            pd.mime = this.field().encode('mime', pd.mime);
        }
        if (pd.mime?.startsWith('application/repository.reportUnit')) {
            pd.type = RESOURCE_TYPE.CONTAINER;
            pd.mime = 'reportUnit';
            pd.children = {};
            if (item.jrxml && item.jrxml.jrxmlFile) {
                const jrxml = this.getDescriptor(item.jrxml.jrxmlFile);
                jrxml.isMainReport = true;
                pd.children[jrxml.uuid ? jrxml.uuid : jrxml.name] = jrxml;
            }
            if (item.resources) {
                item.resources.resource.forEach((element: { file: { fileResource: any; }; }) => {
                    if (pd.children) {
                        const descriptor = this.getDescriptor(element.file.fileResource);
                        pd.children[descriptor.uuid ? descriptor.uuid : descriptor.name] = descriptor;
                    }
                });
            }
        }
        if (pd.mime?.startsWith('application/repository.adhocDataView')) {
            pd.type = RESOURCE_TYPE.CONTAINER;
            pd.mime = 'adhocDataView';
            pd.children = {};
        }
        if (pd.type === RESOURCE_TYPE.FILE && pd.path && pd.path.endsWith('_files')) {
            pd.isMainReport = true;
        }
        result.data = pd;
        return result;
    }

    /**
     * list content of a resource
     * @param path - resource path on the server, could be an uuid
     * @param depth - how deep should children should be get
     */
    public list = (path: string, depth = 1): AxiosPromise<any> => {
        return this.listWithCancel(path, depth).promise;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public listWithCancel = (path: string, depth: number): JobResult => {
        if (!path) {
            path = '';
        }

        if (path.startsWith('container:')) {
            return this.loadWithCancel(path.substr(10), true);
        }
        if (path.startsWith('uuid:')) {
            path = path.substring(5);
        }
        if (!path.startsWith('/')) {
            path = '/' + path;
        }

        let prm = !path.includes('?') ? '?' : '&';
        prm += 'folderUri=' + path + '&recursive=false&sortBy=label';
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.get(this.repositoryBaseURL + "resources" + prm, {
                cancelToken: source.token,
                withCredentials: true,
                headers: { 'Cache-Control': 'no-cache', 'X-REMOTE-DOMAIN': '1', 'Accept': 'application/json', 'X-Suppress-Basic': 'true' }
            }).then(result => {
                const items: IRepositoryItemDescriptor[] = [];
                let hasPublic = false;
                if (result.data.resourceLookup) {
                    result.data.resourceLookup.forEach((element: { resourceType: string; label: any; description: any; creationDate: any; updateDate: any; uri: any; }) => {
                        items.push(this.getDescriptor(element));
                        hasPublic = element.uri === '/public';
                    });
                    if (path === '/' && !hasPublic) {
                        items.push(this.getDescriptor({ resourceType: 'folder', label: 'Public', uri: '/public' }));
                    }
                }
                //sometime can happen that the data is an empty string
                if (typeof result.data === 'string') {
                    result.data = {
                        children: items,
                    }
                }
                result.data.children = items.reduce(function (map, obj) {
                    if (obj.uuid)
                        map[obj.uuid] = obj;
                    return map;
                }, {});
                return result;
            })
        }
    }

    /**
     * load a resource from the repository
     * @param path resource path on the server, could be an uuid
     */
    public load = (path: string, metadata = true, withData = false, allPermissions?: boolean): AxiosPromise<IRepositoryItemDescriptor> => {
        return this.loadWithCancel(path, metadata, withData, allPermissions).promise;
    }

    public getResourcePath(path: string) {
        return path;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public loadWithCancel = (path: string, metadata = true, withData = false, allPermissions?: boolean): JobResult => {
        if (path.startsWith('uuid:')) {
            path = path.substring(5);
        }
        if (path.startsWith('/')) {
            path = path.substring(1);
        }
        let prm = '';
        if (metadata) {
            prm = !path.includes('?') ? '?' : '&';
            prm += 'expanded=true';
        }
        const h = metadata ?
            { 'Cache-Control': 'no-cache', 'X-REMOTE-DOMAIN': '1', 'Accept': 'application/repository.file+json', 'X-Suppress-Basic': 'true' } :
            { 'Cache-Control': 'no-cache', 'X-REMOTE-DOMAIN': '1', 'X-Suppress-Basic': 'true', 'Accept': 'application/json' };
        const url = this.repositoryBaseURL + "resources/" + path + prm;
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.get(url, { withCredentials: true, headers: h })
                .then(result => {
                    if (metadata || result.headers['content-type']?.startsWith('application/repository.')) {
                        const d = this.getDescriptor4Data(result).data;
                        d.mime = this.field().encode('mime', d.mime);
                        if (withData) {
                            return axios.get(url, { withCredentials: true, headers: { 'Cache-Control': 'no-cache', 'X-REMOTE-DOMAIN': '1', 'X-Suppress-Basic': 'true' } }).then(rslt => {
                                d.data = rslt.data;
                                if (d.data) {
                                    if (isBinary(d)) {
                                        d.data = Base64.atob(d.data);
                                    } else {
                                        d.data = Base64.decode(d.data);
                                    }
                                }
                                fixMimeType(d, this);
                                rslt.data = d;
                                return rslt;
                            });
                        }
                        result.data = d;
                    }
                    return result;
                })
        }
    }


    /**
     * save a resource in the repository
     * @param path - resource path on the server, could be an uuid
     * @param descriptor - ResourceDescriptor
     */
    public save = (path: string, descriptor: IRepositoryItemDescriptor): AxiosPromise<any> => {
        if (path.startsWith('uuid:')) {
            path = path.substring(5);
        }
        if (path.startsWith('/')) {
            path = path.substring(1);
        }
        const jrsdesc = this.getJrsDescriptor(path, descriptor);
        const url = this.repositoryBaseURL + 'resources';
        const h = {
            withCredentials: true,
            headers: { 'Content-type': 'application/repository.' + this.getJRSContentType(descriptor) + '+json', 'X-REMOTE-DOMAIN': '1', 'X-Suppress-Basic': 'true' }
        };
        if (!descriptor.uuid) {
            return this.create(0, url, jrsdesc, h);
        }
        const isMain = descriptor.isMainReport;
        const data = jrsdesc.content;
        return axios.put(url + jrsdesc.uri + '?overwrite=true', jrsdesc, h).then(result => {
            const d = this.getDescriptor4Data(result);
            if (descriptor.type === RESOURCE_TYPE.FOLDER) {
                d.data.type = RESOURCE_TYPE.FOLDER;
            } else {
                d.data.mime = this.field().encode('mime', d.data.mime);
                fixMimeType(d.data, this);
                d.data.isMainReport = isMain;
                if (data) {
                    d.data.data = Base64.decode(data);
                }
            }
            return d;
        });
    }

    private create = (count = 0, url: string, d: JrsDescriptor, h): AxiosPromise<any> => {
        let path = d.uri.substring(0, d.uri.lastIndexOf('/'));
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        return axios.get(url + d.uri, { withCredentials: true, headers: { 'Cache-Control': 'no-cache', 'X-REMOTE-DOMAIN': '1', 'X-Suppress-Basic': 'true', 'Accept': 'application/json' } })
            .then(() => {
                if (count > 100) {
                    return Promise.reject({ status: 400, statusText: 'Can\'t find a new id for the resource' });
                }
                d.uri = path + this.field().encode('id', (d.label ? d.label : '') + (++count));
                return this.create(count, url, d, h);
            }).catch(e => {
                if (e.response?.status === 404) {
                    return axios.put(url + d.uri + '?overwrite=true&createFolders=true', d, h)
                        .then(result => { return this.getDescriptor4Data(result); });
                }
                return Promise.reject(e);
            });
    }

    /**
     * move a resource to another path, should be used for renames, uuid will rest the same
     * @param path resource path on the server, could be an uuid
     * @param dest destination path, not an uuid
     */
    public move = (path: string, dest: string): AxiosPromise<any> => {
        if (path.startsWith('uuid:')) {
            path = path.substring(5);
        }
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        return axios.put(this.repositoryBaseURL + "resources" + dest + "?createFolders=true&overwrite=false", "",
            { withCredentials: true, headers: { 'Content-Location': path, 'X-REMOTE-DOMAIN': '1', 'X-Suppress-Basic': 'true' } });
    }

    /**
     * copy a resource to another path, should be used for renames, uuid will be different
     * @param path resource path on the server, could be an uuid
     * @param dest destination path, not an uuid
     */
    public copy = (path: string, dest: string): AxiosPromise<any> => {
        if (path.startsWith('uuid:')) {
            path = path.substring(5);
        }
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        return axios.post(this.repositoryBaseURL + "resources" + dest + "?createFolders=true&overwrite=false", "",
            { withCredentials: true, headers: { 'Content-Location': path, 'X-REMOTE-DOMAIN': '1', 'X-Suppress-Basic': 'true' } });
    }

    /**
     * delete a resource from the repository
     * @param path resource path on the server, could be an uuid
     */
    public delete = (path: string): AxiosPromise<any> => {
        if (path.startsWith('uuid:')) {
            path = path.substring(5);
        }
        if (path.startsWith('/')) {
            path = path.substring(1);
        }
        return axios.delete(this.repositoryBaseURL + "resources/" + path, {
            withCredentials: true, headers: { 'X-REMOTE-DOMAIN': '1', 'X-Suppress-Basic': 'true' }
        });
    }

    /**
     * search repository by one or more fields, all are like()
     * @param params contains an object with search parameters { name by resource name, description by resource description, mime by mime, astModified by last modified date, tags by tags
    */
    public find = (params: { name?: string, description?: string, root?: string, mime?: string[], lastModified?: string, tags?: string[] }): AxiosPromise<any> => {
        return this.findWithCancel(params).promise;
    }

    public findWithCancel = (params: { name?: string, description?: string, mime?: string[], lastModified?: string, tags?: string[] }): JobResult => {
        let url = this.repositoryBaseURL + 'resources?sortBy=label&';
        let del = '';
        if (params.name !== undefined && params.name !== "*") {
            url += 'q=' + encodeURIComponent(params.name);
            del = '&';
        }
        if (params.description !== undefined) {
            url += del + 'q=' + encodeURIComponent(params.description);
            del = '&';
        }
        if (params.mime !== undefined && params.tags) {
            for (const t of params.tags) {
                url += del + 'mime=' + encodeURIComponent(t);
                del = '&';
            }
        }
        const source = axios.CancelToken.source();
        return {
            cancelToken: source,
            promise: axios.get(url, {
                withCredentials: true, headers: { 'X-REMOTE-DOMAIN': '1', 'X-Suppress-Basic': 'true' }
            }).then(result => {
                if (result.data.resourceLookup) {
                    result.data = result.data.resourceLookup.map((element: { resourceType: string; label: any; description: any; creationDate: any; updateDate: any; uri: any; }) => {
                        return this.getDescriptor(element);
                    });
                }
                return result;
            })
        }
    }


    /**
     * Create a folder, path is not encoded
     * @param path 
     */
    public createFolder = (path: string): AxiosPromise<any> => {
        const descriptor: IRepositoryItemDescriptor = {
            type: RESOURCE_TYPE.FOLDER,
            name: "Folder"
        };
        return this.save(path, descriptor);
    }

    private static field = new ResourceFieldJRS();

    public field(): ResourceFieldJRS {
        return RepositoryJRS.field;
    }

    public getMime(mime: string): string {
        if (mime === 'jrxml') {
            return 'jrxml';
        }
        return mime;
    }

    public getPath(pathParts: IRepositoryItemDescriptor[]): string {
        if (pathParts.length > 0) {
            const last = pathParts[pathParts.length - 1];
            if (last.uuid)
                return last.uuid;
            if (last.path)
                return last.path + (last.path.endsWith('/') ? '' : '/') + (last.name ? last.name : '');
        }
        return '/';

        // let path: string | undefined;
        // if (pathParts.length > 0) {
        //     //for jrs the path and uuid are the same
        //     path = pathParts[pathParts.length - 1].uuid;
        // }
        // if (!path) {
        //     path = '/';
        //     let del = '';
        //     pathParts.forEach(item => {
        //         if (item.name) {
        //             path += del + item.name;
        //         }
        //         del = '/';
        //     });
        // }
        // return path;
    }

    public logout() {
        axios.get(this.getBaseURL() + "logout.html", {
            headers: { 'Cache-Control': 'no-cache', 'X-REMOTE-DOMAIN': '1', 'Accept': 'application/json', 'X-Suppress-Basic': 'true' }
        })
    }

    public static authenticateClient(serverAddress: string, uname: string, pass: string): AxiosPromise<any> {
        const url = `${serverAddress}${serverAddress.endsWith('/') ? '' : '/'}rest_v2/login`;
        const params = new URLSearchParams();
        params.append('forceDefaultRedirect', 'false');
        params.append('j_username', uname);
        params.append('j_password', pass);

        return axios.post(url, params, {
            headers: { 'X-REMOTE-DOMAIN': '1' }, withCredentials: true
        });
    }

}

