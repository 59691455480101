/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const FrameIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/frame" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect id="Rectangle" stroke="#318DDE" strokeWidth="2" x="2" y="2" width="16" height="14" rx="1"></rect>
                <rect id="Rectangle" stroke="#55ADEB" strokeWidth="0.5" fill="#FFFFFF" x="3.75" y="3.75" width="12.5" height="10.5"></rect>
            </g>
        </svg>
    );
}
export default FrameIcon;