/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { TimedSearchBar } from '@jss/js-common';
import * as React from 'react';
import { UiProperty } from './ui/UiProperty';

import { Map as ImmutableMap } from 'immutable';
import { IElements } from './ui/UiProperty';

import { IRepositoryItemDescriptor } from '@jss/js-rest-api';
import { ElementTypes } from '../../../sagas/report/document/elementTypes';
import '../../../assets/uxpl/css/ReportProperties.css';
import { MDataset, MDatasets } from './types/dataset/MDatasets';
import { APDescriptor } from './ui/APDescriptor';
import i18n from '../../../i18n';
import '../../../assets/uxpl/css/ReportProperties.css';


export interface ISelectedProperties {
  rootModel: ImmutableMap<string, any>;
  model: ImmutableMap<string, any>;
  selection?: ImmutableMap<string, any>;
  reportDescriptor: IRepositoryItemDescriptor,
  setObjectProperties?: (path: string[], value: any, refreshCache?: boolean, isWidgetProperty?: boolean) => void;
  deleteElement?: (path: string[], refreshCache?: boolean) => void;
  refreshCache?: () => void;
}

interface ISelectedPropertiesState {
  root: APDescriptor;
  elements: IElements[];
  search?: string;
}
interface IFilters {
  search?: string;
}


const buildLayout = (desc: APDescriptor, elements: APDescriptor[], filter: IFilters): APDescriptor | undefined => {
  if (canShowProperty(desc, filter)) {
    if (desc.layouts?.length > 0) {
      const mdesc: APDescriptor = { ...desc, layouts: [] };
      desc.layouts?.forEach((key) => {
        if (key && canShowProperty(key, filter)) {
          if (key.layouts?.length > 0) {
            const c = buildLayout(key, elements, filter);
            if (c) {
              mdesc.layouts.push(c);
            }
          } else if (hasProperties(key, elements)) {
            mdesc.layouts.push(key);
          }
        }
      });
      return mdesc.layouts.length === 0 ? undefined : mdesc;
    } else if (hasProperties(desc, elements)) {
      return desc;
    }
  }
  return undefined;
}

const hasProperties = (ref: APDescriptor, elements: APDescriptor[]) => {
  return elements.length === 1 || elements.some((k, i) => i > 0 && hasProperty(ref, k));
}

const hasProperty = (ref: APDescriptor, element: APDescriptor): boolean => {
  if (element.layouts?.length > 0) {
    return element.layouts?.some(key => {
      return key.layouts?.some(k => hasProperty(ref, k));
    });
  }
  return (element.id === ref.id && element.type === ref.type);
}

const canShowProperty = (desc: APDescriptor, filter: IFilters) => {
  if (!filter.search)
    return true;
  if (!desc.id || desc.layouts?.length > 0)
    return true;
  const canShow = desc.id.toLowerCase().includes(filter.search) || i18n.t(desc.label).toLowerCase().includes(filter.search);
  return canShow;
}


export class DatasetProperties extends React.Component<ISelectedProperties, ISelectedPropertiesState> {

  public state: ISelectedPropertiesState = {
    root: MDatasets, elements: [{ path: [], descriptor: MDatasets }],
  };

  private computeDescriptors = (): APDescriptor => {
    let d = MDatasets;
    if (this.props.model) {
      const type = this.props.model.get('type');
      if (type === ElementTypes.BOOK)
        d = MDataset;
    }
    return buildLayout(d, [d], { search: this.state.search });
  }

  render() {
    if (this.props.model !== null) {
      return <div className='jr-jrws-properties-root'>
        <div className='jr-jrws-properties-content'>
          {this.renderProperties()}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', gap: '5px' }}>
          <TimedSearchBar
            pauseTime={100}
            placeholder={i18n.t('properties.filterby')}
            id="file-searchbar"
            WrapperProps={{ style: { flex: 1 } }}
            onChange={this.searchTextChange}
            value={this.state.search} />
        </div>
      </div>;
    }
    return <></>;
  }

  private renderProperties = () => {
    let d = MDatasets;
    if (this.props.model) {
      const type = this.props.model.get('type');
      if (type === ElementTypes.BOOK)
        d = MDataset;
    }
    let control;
    if (this.state.root) {
      control = this.state.search && this.state.root.layouts?.length > 0 ?
        this.state.root.layouts?.map((key, index) => {
          const k = key.id ? key.id : 'sp.' + this.state.root.id + '.' + index;
          return <UiProperty key={k} mcontext={{ reportDescriptor: this.props.reportDescriptor, model: this.props.model, rootModel: this.props.rootModel, descriptor: key, elements: [{ path: [], descriptor: d }], setObjectProperties: this.props.setObjectProperties, deleteElement: this.props.deleteElement, refreshCache: this.props.refreshCache }} />;
        })
        : <UiProperty mcontext={{ reportDescriptor: this.props.reportDescriptor, model: this.props.model, rootModel: this.props.rootModel, descriptor: d, elements: [{ path: [], descriptor: d }], setObjectProperties: this.props.setObjectProperties, deleteElement: this.props.deleteElement, refreshCache: this.props.refreshCache  }} />
    }
    return control;
  }

  private searchTextChange = (v: string) => {
    v = v.trim().toLowerCase();
    v = v.length > 0 ? v : undefined;
    let d;
    if (v) {
      d = this.computeDescriptors();
    } else {
      d = MDatasets;
      if (this.props.model) {
        const type = this.props.model.get('type');
        if (type === ElementTypes.BOOK)
          d = MDataset;
      }
    }
    this.setState({ search: v, root: d });
  }
}
