/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import '../assets/uxpl/css/Modal.css';

interface IModalProps {
  open: boolean;
  title: string;
  width?: number;
  className?: string;
  overlay?: boolean;
}

const SAFE_MARGIN = 30; // Used to keep always a portion on the dialog inside the window area.

class Modal extends React.Component<IModalProps> {

    private overlayDiv: HTMLDivElement | null = null;

    private modalDiv: HTMLDivElement | null = null;

    public state = {
        top: 0,
        left: 0,
        dragging: false,
        lastPosition: { x: 0, y: 0 }
    }

    /**
     *
     *
     * @memberof Modal
     */
    public componentDidMount = () => {
        // Let's calculate the proper position of the modal on the screen...
        this.updateModalRect(); // Center the dialog if it has been just opened.

        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('mouseup', this.onMouseUp);
    }

    /**
     *
     *
     * @memberof Modal
     */
    public componentDidUpdate = (prevProps: IModalProps) => {
        if (this.modalDiv !== null // if we actually have a dialog on screen
            && this.props.open // which we have just opened...
            && this.props.open !== prevProps.open) {
            this.updateModalRect();
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);
    }

    private updateModalRect = (center = true) => {
        if (this.overlayDiv === null || this.modalDiv === null) {
            return;
        }

        const overlayRect = this.overlayDiv.getBoundingClientRect();
        const modalRect = this.modalDiv.getBoundingClientRect();

        // If the window must be centered, let's recalculate the center...
        if (center) {
            this.setState({
                top: (overlayRect.height - modalRect.height) / 2,
                left: (overlayRect.width - modalRect.width) / 2
            });
            return;
        }

        let { top, left } = this.state;

        if (top > overlayRect.height) {
            top = overlayRect.height - SAFE_MARGIN;
        }

        if (left > overlayRect.width) {
            left = overlayRect.width - SAFE_MARGIN;
        }

        this.setState({
            left,
            top
        });
    }

    private onMouseDown = (e: React.MouseEvent<HTMLElement>) => {
        this.stopEventPropagation(e);

        this.setState({
            dragging: true,
            lastPosition: { x: e.pageX, y: e.pageY }
        });
    }

    private onMouseMove = (e: MouseEvent) => {
        if (!this.state.dragging) {
            return;
        }

        this.stopEventPropagation(e);

        const { lastPosition } = this.state;

        // Calculate delta
        let delta = {
            x: e.pageX - lastPosition.x,
            y: e.pageY - lastPosition.y
        }

        // Apply the delta...
        delta = this.moveOf(delta);

        // Update the state...
        this.setState({
            lastPosition: { x: lastPosition.x + delta.x, y: lastPosition.y + delta.y }
        });
    }

    private onMouseUp = (e: MouseEvent) => {
        this.setState({ dragging: false });
        this.stopEventPropagation(e);
    }

    /**
     * Move the dialog of the specified x/y delta.
     * Returns the applied delta accordingly to the constraints.
     */
    private moveOf = (delta: { x: number, y: number }) => {
        if (delta.x === 0 && delta.y === 0) {
            return delta; // Nothing to do...
        }

        if (this.overlayDiv === null || this.modalDiv === null) {
            return {x: 0, y: 0};
        }

        const overlayRect = this.overlayDiv.getBoundingClientRect();
        const modalRect = this.modalDiv.getBoundingClientRect();
        const windowHeight = overlayRect.height;
        const windowWidth = overlayRect.width;
        const { left, top } = this.state;
        // The window left should not be less than
        const newLeft = Math.max(-modalRect.width + SAFE_MARGIN, Math.min(left + delta.x, windowWidth - SAFE_MARGIN));
        const newTop = Math.max(0, Math.min(top + delta.y, windowHeight - SAFE_MARGIN));

        const realDelta = {
            x: newLeft - left,
            y: newTop - top
        }

        this.setState({
            top: newTop,
            left: newLeft
        });

        return realDelta;
    }

    /**
     * When a mouse event was initiated by the designer to start a drag operation, we want to stop
     * it's propagation so no other elements get this event.
     */
    private stopEventPropagation = (e: MouseEvent | React.TouchEvent | React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
    }


    private setOverlayDivRef = (currentElement: HTMLDivElement) => {
        this.overlayDiv = currentElement;
    }

    private setModalDivRef = (currentElement: HTMLDivElement) => {
        this.modalDiv = currentElement;
    }

    public render() {
        
        const modalWidth = typeof this.props.width !== 'undefined' ? this.props.width : 500;

        const overlayStyles: React.CSSProperties = {};
        overlayStyles.zIndex = 19999;
        overlayStyles.display = 'block';
        overlayStyles.overflow = 'hidden';

        if (typeof this.props.overlay !== 'undefined'
            && this.props.overlay === false) {
            overlayStyles.backgroundColor = 'transparent';
        }
        
        return <>{ReactDOM.createPortal(
            <CSSTransition
                in={this.props.open}
                timeout={300}
                classNames="jr-Modal"
                unmountOnExit={true}
            >
                <div className="jr-mOverlay jr" style={overlayStyles} ref={this.setOverlayDivRef}>
                    <div className={ 'panel dialog overlay moveable' + ((this.props.className) ? this.props.className : '') }
                        style={{
                            position: 'absolute',
                            top: this.state.top,
                            left: this.state.left,
                            width: modalWidth
                        }}
                        ref={this.setModalDivRef}
                    >  
                        <div className="content hasFooter">
                            <div className="header mover" onMouseDown={this.onMouseDown}>
                                <div className="title">
                                    { this.props.title }
                                </div>
                            </div>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </CSSTransition>,
            document.body)}
        </>
    }
}

export default Modal;
