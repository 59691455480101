/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Conf, Pref } from "../config/Conf";

export class UserCredential {
    public type: string;
    public username?: string;
    public email?: string;
    public firstname?: string;
    public lastname?: string;
    public avatarURL?: string;
    public avatarData?: string;
    public locales?: string[];
    public properties?: any;

    public static getDisplayName(user: UserCredential): string {
        let dname;
        if (user.firstname) {
            dname = user.firstname + " ";
        }
        if (user.lastname) {
            dname += user.lastname;
        }
        if (!dname) {
            dname = user.username;
        }
        if (!dname) {
            dname = user.email;
        }
        if (!dname) {
            dname = ' ';
        }
        return dname.trim();
    }

    public static getSource(): string {
        const user = Conf.get(Pref.CURRENT_USER);
        if (user?.properties)
            return user.properties['source'];
        return '';
    }

    public static isAdmin(): boolean {
        const user = Conf.get(Pref.CURRENT_USER);
        if (user?.properties)
            return Object.keys(user.properties)?.some(k => k === 'permissions.admin' && user.properties[k] === 'true');
        return false;
    }

    public static getDisplayImage(user: UserCredential): string {
        return user.avatarData ? "data:image/png;base64, " + user.avatarData : (user.avatarURL ? user.avatarURL : '');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static getDisplayType(user: UserCredential) {
        return '';
        // return  user && user.type ? user.type.substring(0, Math.min(1, user.type.length)).toUpperCase() : '';
    }
}