/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../ui/APDescriptor";
import { MConditionalStyle } from "./MConditionalStyle";
import { MStyle, MStyleTemplateReference } from "./MStyle";
import { PConnectedStylesTree } from "./UPConnectedStylesTree";


export const MStyles: APDescriptor = {
    type: 'MStyles',
    label: 'Styles',
    id: 'stylesProeprtiesDescriptor',
    layouts: [new PConnectedStylesTree({
        id: 'styles', label: 'Styles', expanded: true,
        layouts: [MStyle, MConditionalStyle, MStyleTemplateReference],
        default: { name: 'Style', id: 'style-1', type: 'STYLE' }
    })]
};
