/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

export const ExpressionIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 380 380"
            {...props}
        >
            <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                fill="none"
                stroke="#000"
                strokeWidth="2"
            ></rect>
            <text
                style={{ whiteSpace: "pre" }}
                x="50%"
                y="50%"
                dy="0.3em"
                dx="0.05em"
                fill="#333"
                fontFamily="Helvetica"
                fontSize="210"
                fontWeight="bold"
                textAnchor="middle"
            >
                {"{ }"}
            </text>
        </svg>)
};

export const ValueIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 380 380"
            {...props}
        >
            <circle
                cx="190"
                cy="190"
                r="180"
                fill="none"
                stroke="#000"
                strokeWidth="2"
            ></circle>
            <text
                style={{ whiteSpace: "pre" }}
                x="50%"
                y="50%"
                dy="0.3em"
                dx="-0.1em"
                textAnchor="middle"
                fill="#333"
                fontFamily="Helvetica"
                fontSize="300"
                fontStyle="italic"
            >
                π
            </text>
        </svg>)
};
