/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { getNormalizedPath, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { Button, ButtonGroup, FormControlLabel, IconButton } from '@jss/js-common';
import { resolveAttribute } from '../../types/common/StyleResolver';
import { Map } from 'immutable';
import i18n from '../../../../../i18n';

const TYPEID = 'enumButtons';
reg(TYPEID, (mc) => { return <UPButtonsEnum mcontext={mc} />; });
export class PButtonsEnum extends APDescriptor {
    canBeNull?: boolean = true;
    items: { key: string, value: string, icon?: string, deprecated?: boolean }[];
    default?: string;
    public constructor(init: Partial<PButtonsEnum>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

interface IUPButtonsEnum {
    additionalIcon?: React.ReactElement,
}

export class UPButtonsEnum extends React.Component<UiPropertyProps & IUPButtonsEnum> {

    render() {
        const d = this.props.mcontext.descriptor as PButtonsEnum;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        let v = this.props.mcontext.model.getIn(p);
        let isInherited = false;
        if (v === undefined && this.props.mcontext.descriptor.inheritedPropertyId) {
            const elementPath = getPath(undefined, this.props.mcontext.elements[0].path).slice(0, 2);
            const element = this.props.mcontext.model.getIn(elementPath) as Map<string, any>;
            v = resolveAttribute(this.props.mcontext.model, element, this.props.mcontext.descriptor.inheritedPropertyId);
            isInherited = true;
        }
        if (v === undefined) {
            v = d.default;
            isInherited = true;
        }
        if (v === undefined && v === undefined && d.items.length > 0) {
            // v = d.items[0].key;
            isInherited = true;
        }
        let labelClasses = 'jr-MuiFormLabel-root jr-MuiInputLabel-root jr-mInput-label mui  jr-MuiInputLabel-formControl jr-MuiInputLabel-outlined';
        if (d.deprecated) {
            labelClasses += ' deprecatedProperty';
        }
        const control = <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
            <div className="jr-MuiFormControl-root jr-MuiTextField-root jr-mInput jr-mInputText mui  jr-mInputLarge jr-mInputInline"  >
                <div className="jr-MuiInputBase-root jr-MuiOutlinedInput-root jr-MuiInputBase-formControl">
                    <ButtonGroup aria-label="contained primary button group" className="jr-jrws-fill-button-group" disabled={d.readonly}>
                        {d.items.map(k => {
                            let buttonContent;
                            if (k.icon) {
                                const isSelected = v === k.key;
                                buttonContent = <IconButton className={isSelected && isInherited ? 'placeHolderTextColor' : undefined} inherited={isInherited} icon={k.icon} size="medium" key={k.key} onClick={() => this.onNameChange(k.key)} title={i18n.t(k.value)} selected={isSelected} />
                                //<span className="jr-MuiIconButton-label jr-mButton-icon jr-mIcon mui">{k.icon}</span>
                            } else {
                                buttonContent = <Button size="small" key={k.key} onClick={() => this.onNameChange(k.key)} title={k.value} disabled={v === k.key}>
                                    {k.value[0]}
                                </Button>;
                            }
                            return buttonContent;
                        })}
                    </ButtonGroup>
                </div>
            </div>
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                {this.props.additionalIcon}
            </div>
        </div>
        return <div className='jr-formControlledTextFieldContainer'>
            <FormControlLabel
                classes={{ label: `jr-formControlledTextFieldContainer jr-mInput-label mui ${labelClasses}` }}
                style={{ margin: 0 }}
                control={control}
                labelPlacement="start"
                label={i18n.t(this.props.mcontext.descriptor.label)}
            />
        </div>;
    }
    public onNameChange = (str) => {
        const d = this.props.mcontext.descriptor as PButtonsEnum;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        const currentOwnValue = this.props.mcontext.model.getIn(p);
        this.props.mcontext.elements.forEach(key => {
            if (currentOwnValue === str && d.canBeNull){
                const path = getNormalizedPath(key.path);
                path.push(this.props.mcontext.descriptor.id);
                this.props.mcontext.deleteElement(path);
            } else {
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: str });
            }
        });
    }

}