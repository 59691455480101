/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { CommandHandler, CommandRegistry, Header, IResourceListener, IResourceListenerManager, IToolbarProvider } from '@jss/js-common';
import { IRepositoryItemDescriptor, MessageInfo, Mimes, MIME_TYPES } from '@jss/js-rest-api';
import * as React from 'react';
import "../assets/uxpl/css/TextualEditor.css";
import { EXIT_TEXT_EDITOR } from './AbstractTextualEditor';
import { CSSEditor } from './CSSEditor';
import { DataAdapterTextEditor } from './DataAdapterTextEditor';
import { HTMLEditor } from './HTMLEditor';
import { JavascriptEditor } from './JavascriptEditor';
import { JSONEditor } from './JSONEditor';
import { PropertiesEditor } from './PropertiesEditor';
import { TextEditor } from './TextEditor';
import { UnsupporteEditor } from './UnsupporteEditor';
import { XMLEditor } from './XMLEditor';

interface IEditor {
    openDescriptor: IRepositoryItemDescriptor,
    executeCommand?: (id: string, params: any[]) => void,
    registerHandler?: (id: string, handle: CommandHandler) => void,
}

interface IState {
    toolbardData: IToolbarProvider | undefined,
    currentMessage: MessageInfo | undefined,
}

class RegistryStub implements CommandRegistry {

    private editor: TextualEditor;

    constructor(editor: TextualEditor) {
        this.editor = editor;
    }

    public registerHandler = (id: string, handle: CommandHandler) => {
        if (this.editor.props.registerHandler) {
            this.editor.props.registerHandler(id, handle);
        }
    }

    public registerToolbar = (provider: IToolbarProvider) => {
        this.editor.updateToolbar(provider);
    }

    public executeCommand = (id: string, ...params: any) => {
        if (this.editor.props.executeCommand) {
            this.editor.props.executeCommand(id, params);
        }
    }

    public showMessage = (messageInfo: MessageInfo) => {
        this.editor.setState({ currentMessage: messageInfo });
    }
}

class ResourceListenerManager implements IResourceListenerManager {

    private registeredEditors = new Map<string, IResourceListener>();

    private editor: TextualEditor;

    constructor(editor: TextualEditor) {
        this.editor = editor;
    }

    public addResourceListener = (id: string, resourceListener: IResourceListener) => {
        this.registeredEditors.set(id, resourceListener);
    }

    public removeResourceListener = (id: string) => {
        return this.registeredEditors.delete(id);
    };

    public fireOpenEvent = (resource: IRepositoryItemDescriptor, data: any, path: IRepositoryItemDescriptor[]) => {
        this.registeredEditors.forEach((editor) => {
            editor.onResourceOpen({
                name: resource.name ? resource.name : '',
                content: data,
                resource: resource,
                path: path,
            });
        })
    }
}

const DATA_ATAPTER_MIME_TYPES : string[] = [
    MIME_TYPES.DATA_ADAPTER_JDBC,
    MIME_TYPES.DATA_ADAPTER_CSV,
    MIME_TYPES.DATA_ADAPTER_XML,
    MIME_TYPES.DATA_ADAPTER_XLS,
    MIME_TYPES.DATA_ADAPTER_JNDI,
    MIME_TYPES.DATA_ADAPTER_JSON,
    MIME_TYPES.DATA_ADAPTER_EMPTY,
    MIME_TYPES.DATA_ADAPTER_RANDOM,
    MIME_TYPES.DATA_ADAPTER_DATA_SOURCE,
    MIME_TYPES.DATA_ADAPTER_XMLA,
    MIME_TYPES.DATA_ADAPTER_MONDRIAN,
    MIME_TYPES.DATA_ADAPTER_SPRING_HIBERNATE,
    MIME_TYPES.DATA_ADAPTER_QUERY_EXECUTER,
    MIME_TYPES.DATA_ADAPTER_JRS,
    MIME_TYPES.DATA_ADAPTER_MONGODB,
    MIME_TYPES.DATA_ADAPTER_EJBQL,
    MIME_TYPES.DATA_ADAPTER_BEAN,
    MIME_TYPES.DATA_ADAPTER_HIBERNATE,
    MIME_TYPES.DATA_ADAPTER_PROVIDER,
    MIME_TYPES.DATA_ADAPTER_SPOTFIRE,
]

/**
 * This is the repository main screen
 */
export class TextualEditor extends React.Component<IEditor, IState>  {

    private registryStub = new RegistryStub(this);

    private resourceListeners: ResourceListenerManager[] = [new ResourceListenerManager(this)];

    public state: IState = {
        toolbardData: undefined,
        currentMessage: undefined,
    }

    public componentDidMount = () => {
        const resourceDescriptor: IRepositoryItemDescriptor = this.props.openDescriptor;
        this.resourceListeners.forEach((resourceListener) => {
            resourceListener.fireOpenEvent(resourceDescriptor, resourceDescriptor.data, []);
        });
    }

    public updateToolbar = (toolbarProvider: IToolbarProvider) => {
        this.setState({ toolbardData: toolbarProvider });
    }

    private closeMessage = () => {
        this.setState({ currentMessage: undefined });
    }

    private getTabContent = () => {
        if(this.props.openDescriptor.permission === 0)
            return <UnsupporteEditor resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
        const descriptor = Mimes.getDescriptor(this.props.openDescriptor.mime);
        if (descriptor) {
            const mime = descriptor.mime;
            if (DATA_ATAPTER_MIME_TYPES.includes(mime)){
                return <DataAdapterTextEditor resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
            }
            if (mime === MIME_TYPES.JSON) {
                return <JSONEditor resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
            }
            if (this.props.openDescriptor.mime === MIME_TYPES.JRTX || mime === MIME_TYPES.JRCTX) {
                return <XMLEditor defaultExtension=".jrtx" acceptedFileTypes=".jrtx,.jrxml,application/xml" resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
            }
            if (mime === MIME_TYPES.XML || mime === MIME_TYPES.TXML) {
                return <XMLEditor resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
            }
            if (mime === MIME_TYPES.PROPERTIES) {
                return <PropertiesEditor defaultExtension=".properties" acceptedFileTypes=".properties,text/plain,.txt,.properties" resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
            }
            if (mime === MIME_TYPES.CSV) {
                return <TextEditor resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
            }
            if (mime === MIME_TYPES.CSS) {
                return <CSSEditor resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
            }
            if (mime === MIME_TYPES.HTML || mime === MIME_TYPES.XHTML) {
                return <HTMLEditor resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
            }
            if (mime === MIME_TYPES.JAVASCRIPT || mime === MIME_TYPES.JAVASCRIPT_APP) {
                return <JavascriptEditor resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
            }
            if (mime === MIME_TYPES.TEXT || mime.startsWith('text/')) {
                return <TextEditor resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
            }
            if (mime === MIME_TYPES.SH) {
                return <TextEditor resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
            }
            if (this.props.openDescriptor.name.endsWith('.bat')) {
                return <TextEditor resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
            }
            if (mime.endsWith('+xml')) {
                return <XMLEditor resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
            }
        }
        return <UnsupporteEditor resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />;
    }

    exitEditorAction = () => {
        this.registryStub.executeCommand(EXIT_TEXT_EDITOR);
    }

    private getDocumentName = () => {
        return this.props.openDescriptor.name;
    }

    public render() {
        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Header tabs={[]} message={this.state.currentMessage} currentTabIndex={0}
                    toolbarData={this.state.toolbardData} resourceName={this.getDocumentName()} closeMessage={this.closeMessage} />
                <div id="container" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {this.getTabContent()}
                </div>
            </div>
        );
    }
}