/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import '../../../assets/uxpl/css/Scrollbar.css';

const MOUSEMOVE = 'mousemove';
const MOUSEUP = 'mouseup';

export enum Orientation {
  HORIZONTAL = 1,
  VERTICAL
}

interface IScrollbar {
  viewportSize: number;
  clientSize: number;
  orientation: Orientation;
  scroll: number;
  centered?: boolean;
  onScroll?: (pos: number) => void;
} 


/**
 * Implements a scrollbar, either vertical or horizontal
 * depending by the property "orientation"
 */
export class Scrollbar extends React.Component<IScrollbar> {

  public state = {
      dragging: false,
      touchDragging: false,
      lastPos: 0,
      orientation: Orientation.HORIZONTAL
  };

  // Stores the event listeners registered at document level.
  private listeners = {};

  // Let's attach the listener on the document for dragging events.
  // The listeners will be attached only if we are actually dragging
  // the cursor bar...
  public componentDidMount = () => {
    
    this.listeners[MOUSEMOVE] = this.onMouseMove.bind(this);
    this.listeners[MOUSEUP] = this.onMouseUp.bind(this);
    
    for (const key in this.listeners) {
      if (this.listeners.hasOwnProperty(key)) {
        document.addEventListener(key, this.listeners[key]);
      }
    }

  }

  public componentWillUnmount = () => {
    
    for (const key in this.listeners) {
      if (this.listeners.hasOwnProperty(key)) {
        document.removeEventListener(key, this.listeners[key]);
      }
    }

    // Clear the list of event listeners..
    this.listeners = {};
  }


  public render() {

    
    const size = this.getBarSize();

    let bar = null;

    // Let's render the scroll bar only if necessary...
    let className = '';

    if (size > 0) {


      const top = this.isCentered() ? (this.props.viewportSize - size)/2 - this.getBarOffset() : this.getBarOffset();
    
      const cursorStyle = { 
        top: 0,
        left: 0,
        width: 0,
        height: 0
      };
  
      if (this.props.orientation === Orientation.VERTICAL) {
        className = 'Scrollbar-y';
        cursorStyle.top = top;
        cursorStyle.width = 10;
        cursorStyle.height = size;
      }
      else
      {
        className = 'Scrollbar-x';
        cursorStyle.left = top;
        cursorStyle.top = 0;
        cursorStyle.width = size;
        cursorStyle.height = 10;
      }
      bar = (
        <div className="Scrollbar-cursor" style={cursorStyle}
          onMouseDown={this.onMouseDown}
          onTouchStart={this.onTouchStart}
          onTouchMove={this.onTouchMove}
          onTouchEnd={this.onTouchEnd}
        />
      );
    }

    return (
       <div className={className}>
          {bar}
       </div>
    );
  }




  // Generic dragging events ----------------------------------

  private onStart = (pos: number) => {
    
    const { viewportSize, clientSize } = this.props;
    
    // If the whole design area fit the viewport, we have nothing to do.
    if (viewportSize >= clientSize) {
      return;
    }

    // Store the dragging position...
    this.setState( {
      dragging: true,
      lastPos: pos
    });
  }

  private onEnd = () => {
    if (!this.state.dragging) {
        return;
    }
  }

  private onMove = (lastPos: number, multiplyer = 1) => {

    const barSize = this.getBarSize();

    if (!this.state.dragging || barSize === 0) {
      return;
    }

    const delta = -(this.state.lastPos - lastPos);

    if (delta !== 0 && this.props.onScroll) {

      this.setState({
        lastPos
      });

      // 1. Calculate the max

      // We calculate the number of pixels that makes a movment of 1 pixel of the bar...
      // Proportion = virtualDelta : delta = viewportSize : barSize
      this.props.onScroll( (this.props.viewportSize * delta / barSize) * multiplyer);
    }
  }

  // Mouse dragging events ----------------------------------

   // Ready to start a dragging operation....
  private onMouseDown = (e: React.MouseEvent<HTMLElement>) => {

      // consider only left mouse button
      if (e.button !== 0) {
        return
      }
  
      e.stopPropagation()
      e.preventDefault()
  
      this.onStart((this.props.orientation === Orientation.VERTICAL) ? e.screenY : e.screenX);
    }

  private onMouseUp = (e: React.MouseEvent<HTMLElement>) => {
    
    if (!this.state.dragging) {
      return; // Not our business, since a dragging event has not been started.
    }

    this.onEnd();
    this.setState({dragging: false, lastPos: null})
    e.stopPropagation()
    e.preventDefault()
  }

  private onMouseMove = (e: React.MouseEvent<HTMLElement>) => {

    if (!this.state.dragging) {
        return; // Not our business, since a dragging event has not been started.
    }

    e.stopPropagation();
    e.preventDefault();

    this.onMove((this.props.orientation === Orientation.VERTICAL) ? e.screenY : e.screenX);
  }
    

  // Touch dragging events ----------------------------------
  
  private onTouchStart = (e: React.TouchEvent) => {
    const touch0 = e.touches[0];
    this.onStart((this.props.orientation === Orientation.VERTICAL) ? touch0.screenY : touch0.screenX);
    e.stopPropagation()
    e.preventDefault()

  }

  private onTouchMove = (e: React.TouchEvent) => {
    const touch0 = e.touches[0];
    this.onMove((this.props.orientation === Orientation.VERTICAL) ? touch0.screenY : touch0.screenX, this.isCentered() ? 2.0 : 1.0);
    e.stopPropagation()
    e.preventDefault()
  }

  private onTouchEnd = (e: React.TouchEvent) => {
    this.onEnd(); // (this.props.orientation === Orientation.Vertical)
    e.stopPropagation()
    e.preventDefault()
  }





  /**
   * Returns the size of the scrollable bar, 0 if no
   * scroll is necessary.
   */
  private getBarSize = () => {
    const { viewportSize, clientSize } = this.props;

    if (clientSize > viewportSize) {
      // Proportion = clientSize : viewportSize = viewportSize : barSize
      return (viewportSize * viewportSize / clientSize);
    }
    return 0;
  }

  /**
   * Returns the position of the scrollable bar or 0 if no
   * scroll is necessary.
   */
  private getBarOffset = () => {
    const { viewportSize, clientSize, scroll } = this.props;
    
    const barSize = this.getBarSize();
    if (barSize === 0) {
        return 0;
    }

    const centered = this.isCentered();
    // Transform scroll in viewportSize coords...
    const localScroll = scroll * viewportSize / clientSize;

    const maxOffset = centered ?  (viewportSize - barSize)/2 : viewportSize - barSize;
    const minOffset = centered ?  -maxOffset : 0;

    if (localScroll < minOffset) {
      return minOffset;
    }

    if (localScroll > maxOffset) {
      return maxOffset;
    }

    return localScroll;
  }

 
  
  private isCentered = () => {
    return this.props.centered === true;
  }

}




