export const it = {
	datasource: {
		editor: {
			test: "Test"
		}
	},
	text: {
		editor: {
			test: {
				label: "Test",
				error: "Errore",
				cancel: "Annulla",
				success: "Risorsa validata con successo",
				fail: "Il test è fallito",
				tryAgain: "Prova nuovamente",
				close: "Chiudi"
			},
			testTooltip: "Verrà effettuato un test sul Data Adapter",
			help: {
				tooltip: "Aiuto"
			},
			format: {
				tooltip: "Formatta"
			},
			savedialogtitle: "Salva file come...",
			unrecognizedResource: "TIPO RISORSA NON RICONOSCIUTO"
		}
	}
}