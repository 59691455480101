/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Button, CommandRegistry, i18n, IconButton, IEditor, IMenuItem, IResourceInfo, IResourceListener, IResourceListenerManager, IToolBarItemOptions, IToolbarProvider, ToolBar, ToolButton, UxType } from '@jss/js-common';
import { IRepositoryItemDescriptor, isReadOnly, MIME_TYPES, RepositoryApi, RESOURCE_TYPE } from '@jss/js-rest-api';
import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import PublishIcon from '@material-ui/icons/Publish';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { Base64 } from 'js-base64';
import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { v4 as uuidv4 } from 'uuid';

import ToastImageEditor from '@toast-ui/react-image-editor';
import 'tui-image-editor/dist/tui-image-editor.css';

import '../assets/uxpl/css/ImageEditor.css';

import background from '../assets/uxpl/img/bg.png';
import { ResourceInfo, ResourcePickerDialog, RunContext } from '@jss/js-repository';



export interface IAbstractTextualEditor {
    resourceListenerManagers: IResourceListenerManager[],
    commandRegistry: CommandRegistry,
    acceptedFileTypes?: string,
    defaultExtension?: string,
    language?: string,
    onExit?: () => void;
}

interface IAbstralTextualEditorState {
    openDescriptor: IRepositoryItemDescriptor | undefined,
    editorContent: any,
    width: number,
    height: number,
    isDirty: boolean,
    toolbarData: IToolbarProvider | undefined,
    errorMessage: string | null,
    successMessage: string | null,
    pageLoading: boolean,
    path?: IRepositoryItemDescriptor[],
    redoStackLength: number,
    undoStackLength: number,
    defaultFileName: string,
}

export const EXIT_TEXT_EDITOR = 'EXIT_TEXT_EDITOR';

export const TEXT_SAVED = 'TEXT_SAVED';

export const SAVE_TEXT = 'SAVE_TEXT';

export const TEXT_MODIFIED = 'TEXT_MODIFIED';

export const TEXT_EDITOR_DIRTY = 'TEXT_EDITOR_DIRTY';

export const TEXT_SAVED_AS = 'TEXT_SAVED_AS';

export const UPDATED_DESCRIPTOR = 'UPDATED_DESCRIPTOR';

function buildFileSelector(handleFileChange: (e) => void, acceptedFileTypes: string) {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', acceptedFileTypes);
    fileSelector.onchange = handleFileChange;
    fileSelector.onclick = function () {
        (this as any).value = null;
    };
    return fileSelector;
}

const whiteTheme = {
    'common.bisize.width': '251px',
    'common.bisize.height': '21px',
    'common.backgroundImage': background,
    'common.backgroundColor': '#fff',
    'common.border': '1px solid #c1c1c1',

    // header
    'header.backgroundImage': 'none',
    'header.backgroundColor': 'transparent',
    'header.border': '0px',

    // load button
    'loadButton.backgroundColor': '#fff',
    'loadButton.border': '1px solid #ddd',
    'loadButton.color': '#222',
    'loadButton.fontFamily': "'Noto Sans', sans-serif",
    'loadButton.fontSize': '12px',

    // download button
    'downloadButton.backgroundColor': '#fdba3b',
    'downloadButton.border': '1px solid #fdba3b',
    'downloadButton.color': '#fff',
    'downloadButton.fontFamily': "'Noto Sans', sans-serif",
    'downloadButton.fontSize': '12px',

    // main icons
    'menu.normalIcon.color': '#8a8a8a',
    'menu.activeIcon.color': '#555555',
    'menu.disabledIcon.color': '#434343',
    'menu.hoverIcon.color': '#e9e9e9',
    'menu.iconSize.width': '24px',
    'menu.iconSize.height': '24px',

    // submenu icons
    'submenu.normalIcon.color': '#8a8a8a',
    'submenu.activeIcon.color': '#555555',
    'submenu.iconSize.width': '32px',
    'submenu.iconSize.height': '32px',

    // submenu primary color
    'submenu.backgroundColor': 'transparent',
    'submenu.partition.color': '#e5e5e5',

    // submenu labels
    'submenu.normalLabel.color': '#858585',
    'submenu.normalLabel.fontWeight': 'normal',
    'submenu.activeLabel.color': '#000',
    'submenu.activeLabel.fontWeight': 'normal',

    // checkbox style
    'checkbox.border': '1px solid #ccc',
    'checkbox.backgroundColor': '#fff',

    // rango style
    'range.pointer.color': '#333',
    'range.bar.color': '#ccc',
    'range.subbar.color': '#606060',

    'range.disabledPointer.color': '#d3d3d3',
    'range.disabledBar.color': 'rgba(85,85,85,0.06)',
    'range.disabledSubbar.color': 'rgba(51,51,51,0.2)',

    'range.value.color': '#000',
    'range.value.fontWeight': 'normal',
    'range.value.fontSize': '11px',
    'range.value.border': '0',
    'range.value.backgroundColor': '#f5f5f5',
    'range.title.color': '#000',
    'range.title.fontWeight': 'lighter',

    // colorpicker style
    'colorpicker.button.border': '0px',
    'colorpicker.title.color': '#000',
};


export class ImageEditor extends React.Component<IAbstractTextualEditor, IAbstralTextualEditorState> implements IEditor {

    public state: IAbstralTextualEditorState = {
        openDescriptor: undefined,
        editorContent: '',
        width: 0,
        height: 0,
        isDirty: false,
        toolbarData: undefined,
        errorMessage: null,
        successMessage: null,
        pageLoading: false,
        defaultFileName: 'New Text File',
        redoStackLength: 0,
        undoStackLength: 0,
        path: undefined,
    }

    private resourceListenerId = '';

    private editorRef = React.createRef<ToastImageEditor>();

    protected fileSelector: HTMLInputElement;

    public componentDidMount = () => {
        this.resourceListenerId = uuidv4();
        const resourceListener: IResourceListener = {
            onResourceOpen: (resource: IResourceInfo) => {
                let createDefualtSelector = true;
                if (!this.fileSelector) {
                    if (resource.resource.name) {
                        const extensionStart = resource.resource.name.lastIndexOf('.');
                        if (extensionStart !== -1) {
                            const extension = resource.resource.name.substring(extensionStart);
                            this.fileSelector = buildFileSelector(this.onFileSelected, extension);
                            createDefualtSelector = false;
                        }
                    }
                }
                if (createDefualtSelector) {
                    this.fileSelector = buildFileSelector(this.onFileSelected, ".jpeg,.png,.bmp,.svg,.gif");
                }
                if (resource.resource) {
                    if (!resource.content) {
                        //it has a descriptor but not content, consider it a new da
                        this.setState({
                            editorContent: '',
                            openDescriptor: resource.resource,
                            path: resource.path,
                            defaultFileName: resource.resource.name,
                        });
                    } else {
                        this.setState({
                            editorContent: resource.content,
                            openDescriptor: resource.resource,
                            path: resource.path,
                            isDirty: resource.isResourceDirty !== undefined ? resource.isResourceDirty : false,
                            defaultFileName: resource.resource.name,
                        });
                    }
                }
            }
        }
        this.props.resourceListenerManagers.forEach(manager => {
            manager.addResourceListener(this.resourceListenerId, resourceListener);
        });
        this.props.commandRegistry.registerHandler(EXIT_TEXT_EDITOR, {
            execute: () => {
                if (this.props.commandRegistry) {
                    this.props.commandRegistry.executeCommand(EXIT_TEXT_EDITOR);
                }
            },
            isEnabled: () => {
                return true;
            }
        });
        this.props.commandRegistry.registerHandler(SAVE_TEXT, {
            execute: (params: Record<string, unknown>, callback?: () => void) => {
                this.doSave(false, callback);
            },
            isEnabled: () => {
                return true;
            }
        });

        this.provideToolbar();
    }

    componentWillUnmount() {
        this.props.resourceListenerManagers.forEach(manager => {
            manager.removeResourceListener(this.resourceListenerId);
        });
    }

    componentDidUpdate = (previousProps: IAbstractTextualEditor, previousState: IAbstralTextualEditorState) => {
        if (this.editorRef && this.editorRef.current) {
            const instance = this.editorRef.current.getInstance();
            const setStateRedo = (length) => {
                this.setState({ redoStackLength: length });
            }
            const setStateUndo = (length) => {
                this.setState({ undoStackLength: length, isDirty: length > 0 });
            }
            instance.on('redoStackChanged', (length) => {
                if (this.state.redoStackLength !== length) {
                    setStateRedo(length);
                }
            });

            instance.on('undoStackChanged', (length) => {
                if (this.state.undoStackLength !== length) {
                    setStateUndo(length);
                }
            });
        }
        if (previousState.openDescriptor !== this.state.openDescriptor || previousState.isDirty !== this.state.isDirty ||
            this.props.language !== previousProps.language || this.state.undoStackLength !== previousState.undoStackLength ||
            this.state.redoStackLength !== previousState.redoStackLength) {
            this.provideToolbar();
            this.props.commandRegistry.executeCommand(TEXT_EDITOR_DIRTY, { isDirty: this.state.isDirty });
        }
    }

    getEditorContent = () => {
        return this.state.editorContent;
    }

    provideToolbar = () => {
        this.setState({ toolbarData: this.getInternalToolbar() });
        //this.props.commandRegistry.registerToolbar(this.getToolbar());
    }

    getToolbar = (): IToolbarProvider => {
        return {
            getMenus: () => {
                return [];
            },
            getToolItems: () => {
                return [];
            }
        }
    }

    handleUploadPress = (e) => {
        e.preventDefault();
        this.fileSelector.click();
    }

    getInternalToolbar = (): IToolbarProvider => {
        const isSaveDisabled = !this.state.isDirty || !this.state.openDescriptor || isReadOnly(this.state.openDescriptor);
        const getSaveSubmenu: IMenuItem[] = [
            {
                id: 'saveAction',
                label: i18n.t('common.actions.menu.save'),
                isEnabled: !isSaveDisabled,
                icon: <IconButton className='jr-mMenuDecorated jr-mMenu-list-item-icon jr-MuiListItemIcon-root jr-mIcon mui' style={{ minWidth: 0, backgroundColor: 'transparent' }} icon={'save'} size="small" disabled={isSaveDisabled} />,
                onClick: () => {
                    if (this.state.openDescriptor.uuid) {
                        this.doSave(false);
                    } else {
                        //if the file is new force a new file to let the user choose the name
                        this.doSave(true);
                    }
                }
            },
            {
                id: 'saveAsAction',
                label: i18n.t('common.actions.menu.saveas'),
                icon: <SaveAltIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    this.doSave(true);
                }
            },
            {
                id: 'propertiesAction',
                label: i18n.t('common.actions.menu.resourceinfo'),
                icon: <InfoIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                getComponent: (handleClose: () => void) => {
                    const isEnabled = this.state.openDescriptor !== undefined && this.state.openDescriptor.uuid !== undefined;
                    return <ResourceInfo disabled={!isEnabled} key='rs.info' type={UxType.MENU} onClose={handleClose} descriptor={this.state.openDescriptor} onChanged={this.handleItemPropertiesChanged} />
                }
            },
            {
                label: i18n.t('common.actions.menu.upload'),
                id: 'importAction',
                getComponent: (handleClose: () => void) => {
                    return (
                        <MenuItem key={'importAction'} selected={false} className={"jr-mMenu-list-item mui"}>
                            <div style={{ display: 'flex' }} onClick={(event) => { handleClose(); this.handleUploadPress(event) }}>
                                <ListItemIcon className={"jr-mMenu-list-item-icon mui"}>
                                    <PublishIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />
                                </ListItemIcon>
                                <ListItemText primary={i18n.t('common.actions.menu.upload')} classes={{ primary: "jr-mText mui" }} />
                            </div>
                        </MenuItem>)
                }
            },
            {
                label: i18n.t('common.actions.menu.download'),
                icon: <GetAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                id: 'exportAction',
                onClick: () => {
                    const blob = new Blob([this.state.editorContent], { type: "text/xml;charset=utf-8" });
                    const extension = '';
                    saveAs(blob, this.state.openDescriptor?.name + extension);
                }
            },
            {
                label: i18n.t('common.actions.menu.exit'),
                id: 'exitAction',
                icon: <ExitToAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    if (this.props.commandRegistry) {
                        this.props.commandRegistry.executeCommand(EXIT_TEXT_EDITOR);
                    }
                }
            },
        ];
        return {
            getToolItems: () => {
                const items = [
                    {
                        id: 'saveToolaction',
                        render: (options?: IToolBarItemOptions) => {
                            //disable the save when the report is not dirty or we don't have write permission
                            const height = options && options.height ? options.height : 24;
                            const backgroundColor = options && options.background ? options.background : undefined;
                            return <ToolButton label={i18n.t('common.actions.menu.save')} isDisabled={isSaveDisabled} onClick={() => {
                                if (this.state.openDescriptor.uuid) {
                                    this.doSave(false);
                                } else {
                                    //if the file is new force a new file to let the user choose the name
                                    this.doSave(true);
                                }
                            }} height={height} backgroundColor={backgroundColor} content={getSaveSubmenu}>
                                <IconButton style={{ backgroundColor: 'transparent' }} icon={'save'} size="small" disabled={isSaveDisabled} />
                            </ToolButton>
                        }
                    },
                    {
                        id: 'undoToolaction',
                        render: (options?: IToolBarItemOptions | undefined) => {
                            const isUndoDisabled = this.state.undoStackLength === 0;
                            const height = options && options.height ? options.height : 24;
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                                <div title={i18n.t('common.actions.menu.undo')} style={{ height: height, cursor: isUndoDisabled ? 'default' : 'pointer', justifyContent: 'center', alignItems: 'center', marginRight: 15 }} onClick={!isUndoDisabled ? this.undo : undefined}>
                                    <IconButton style={{ backgroundColor: 'transparent' }} icon={'undo'} size="small" disabled={isUndoDisabled} />
                                </div>
                            </div>
                        }
                    },
                    {
                        id: 'redoToolaction',
                        render: (options?: IToolBarItemOptions) => {
                            const height = options && options.height ? options.height : 24;
                            const isRedoDisabled = this.state.redoStackLength === 0;
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div title={i18n.t('common.actions.menu.redo')} style={{ height: height, cursor: isRedoDisabled ? 'default' : 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={!isRedoDisabled ? this.redo : undefined}>
                                    <IconButton icon={'redo'} size="small" disabled={isRedoDisabled} />
                                </div>
                                <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                            </div>;
                        }
                    },
                ];
                return items;
            },
        }
    }

    private doExit = () => {
        if (this.props.commandRegistry) {
            this.props.commandRegistry.executeCommand(EXIT_TEXT_EDITOR);
        }
    }

    simpleToolbarData = (): IToolbarProvider => {
        const getExitSubmenu: IMenuItem[] = [
            {
                id: 'propertiesAction',
                label: i18n.t('common.actions.menu.resourceinfo'),
                icon: <InfoIcon />,
                getComponent: (handleClose: () => void) => {
                    const isEnabled = this.state.openDescriptor !== undefined && this.state.openDescriptor.uuid !== undefined;
                    return <ResourceInfo disabled={!isEnabled} key='rs.info' type={UxType.MENU} onClose={handleClose} descriptor={this.state.openDescriptor} onChanged={this.handleItemPropertiesChanged} />
                }
            },
            {
                label: i18n.t('common.actions.menu.upload'),
                id: 'importAction',
                getComponent: (handleClose: () => void) => {
                    return (
                        <MenuItem key={'importAction'} selected={false}>
                            <div style={{ display: 'flex' }} onClick={(event) => { handleClose(); this.handleUploadPress(event) }}>
                                <ListItemIcon>
                                    <PublishIcon />
                                </ListItemIcon>
                                <ListItemText primary={i18n.t('common.actions.menu.upload')} />
                            </div>
                        </MenuItem>)
                }
            },
            {
                label: i18n.t('common.actions.menu.download'),
                icon: <GetAppIcon />,
                id: 'exportAction',
                onClick: () => {
                    const blob = new Blob([this.state.editorContent]);
                    saveAs(blob, this.state.openDescriptor.name);
                }
            },
            {
                label: i18n.t('common.actions.menu.exit'),
                id: 'exitAction',
                icon: <ExitToAppIcon />,
                onClick: this.doExit
            },
        ];
        return {
            getToolItems: () => {
                const items = [
                    {
                        id: 'exitToolAction',
                        render: (options?: IToolBarItemOptions) => {
                            //disable the save when the report is not dirty or we don't have write permission
                            const height = options && options.height ? options.height : 24;
                            const backgroundColor = options && options.background ? options.background : undefined;
                            return <ToolButton label={i18n.t('common.actions.menu.exit')} onClick={this.doExit} height={height} backgroundColor={backgroundColor} content={getExitSubmenu}>
                                <IconButton style={{ backgroundColor: 'transparent' }} icon={'linkExternal'} size="small" />
                            </ToolButton>;
                        }
                    },
                ];
                return items;
            },
        }
    }

    private undo = () => {
        if (this.editorRef && this.editorRef.current) {
            const instance = this.editorRef.current.getInstance();
            instance.undo();
        }
    }

    private redo = () => {
        if (this.editorRef && this.editorRef.current) {
            const instance = this.editorRef.current.getInstance();
            instance.redo();
        }
    }

    private onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            const content = fileReader.result as string;
            // TODO - perform some kind of validation of the loaded content
            // or be sure that a sort of validation is triggered when editorContent is updated
            this.setState({ editorContent: content, isDirty: true });
        };
        fileReader.readAsText(event.currentTarget.files[0]);
    }

    public openSaveDialog() {
        const defaultPath: IRepositoryItemDescriptor[] = this.state.path;
        this.context.showDialog(ResourcePickerDialog, {
            mode: 'save',
            title: i18n.t('text.editor.savedialogtitle'),
            onViewClose: () => {
                //empty
            },
            onFileSelected: this.confirmSave,
            defaultFileName: this.state.defaultFileName,
            defaultPath: defaultPath,
        });
    }

    protected confirmSave = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null, fileName: string) => {
        if (this.editorRef.current) {
            const instance = this.editorRef.current.getInstance();
            const base64data = instance.toDataURL().split(',')[1];
            let root = false;
            const newDescriptor: IRepositoryItemDescriptor = {
                type: RESOURCE_TYPE.FILE,
                name: fileName,
                path: RepositoryApi.inst().getParentPath(folderPath.filter(k => {
                    root = root || file === null || k.type !== RESOURCE_TYPE.CONTAINER;
                    return file == null || (root && k.type === RESOURCE_TYPE.CONTAINER)
                })),
                data: base64data,
                mime: this.state.openDescriptor.mime,
            }
            if (file !== null) {
                //the save as is overwriting a file
                newDescriptor.path = file.path;
                newDescriptor.uuid = file.uuid;
            }

            this.context.runNoCancel(RepositoryApi.inst().save)(file !== null ? 'uuid:' + file.uuid : '', newDescriptor).then((response: AxiosResponse) => {
                if (response.data) {
                    this.setState({ openDescriptor: response.data, isDirty: false }, () => {
                        this.setState({ openDescriptor: response.data });
                        this.props.commandRegistry.executeCommand(TEXT_SAVED_AS, { descriptor: response.data, data: Base64.atob(base64data) });
                    });
                }
            });
        }
    }

    protected handleItemPropertiesChanged = (oldDescriptor: IRepositoryItemDescriptor, newDescriptor: IRepositoryItemDescriptor | null) => {
        this.setState({
            openDescriptor: newDescriptor,
        }, () => {
            this.props.commandRegistry.executeCommand(UPDATED_DESCRIPTOR, { descriptor: newDescriptor });
        });
    }

    protected doSave = (forceNewFile = false, callback?: () => void) => {
        const xmlDaContent = this.state.editorContent;
        if (this.state.openDescriptor && !forceNewFile) {
            //it is a save of an existing report

            if (this.editorRef.current) {
                const instance = this.editorRef.current.getInstance();
                const base64data = instance.toDataURL().split(',')[1];

                const descriptor: IRepositoryItemDescriptor = this.state.openDescriptor;
                const newDescriptor: IRepositoryItemDescriptor = {
                    ...(descriptor as any),
                    data: base64data,
                }
                this.context.runNoCancel(RepositoryApi.inst().save)(descriptor.uuid ? 'uuid:' + descriptor.uuid : (descriptor.path ? descriptor.path : '/'), newDescriptor, newDescriptor).then((response: AxiosResponse) => {
                    if (response.data) {
                        //need to update the descriptor also in simple save because it could contain the version
                        this.setState({ openDescriptor: response.data, isDirty: false }, () => {
                            this.props.commandRegistry.executeCommand(TEXT_SAVED, { descriptor: response.data, data: xmlDaContent });
                            instance.clearUndoStack();
                            instance.clearRedoStack();
                            if (callback) {
                                callback();
                            }
                        });
                    }
                });
            }
        } else {
            //it is a new file
            this.openSaveDialog();
        }
    }

    private onResizeWidth = (width: number | undefined) => {
        if (width !== this.state.width) {
            this.setState({ width: width ? width : 0 }, () => {
                this.updateImageEditorSize();
            });
        }
    }

    private onResizeHeight = (width: number | undefined, height: number | undefined) => {
        if (height !== this.state.height) {
            this.setState({ height: height ? height : 0 }, () => {
                this.updateImageEditorSize();
            });
        }
    }

    private updateImageEditorSize = () => {
        if (this.editorRef.current) {
            const instance = this.editorRef.current.getInstance();
            instance.ui.resizeEditor({
                uiSize: { width: this.state.width + 'px', height: this.state.height + 'px' },
            });
        }
    }

    protected clearSuccessMessage = () => {
        this.setState({ successMessage: null });
    }

    protected clearErrors = () => {
        this.setState({ errorMessage: null });
    }

    private getSuccessMessageDialog = () => {
        return <Dialog
            open={this.state.successMessage !== null}
            title={'Success'}
            aria-labelledby="simple-dialog-title"
            fullWidth={true}
            maxWidth={'xs'}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    this.clearSuccessMessage();
                }
            }}
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.state.successMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button size="large" color="primary" variant="contained" className="tc-jsw-margin-right" onClick={this.clearSuccessMessage}>{i18n.t('text.editor.test.close')}</Button>
            </DialogActions>
        </Dialog>
    }

    protected getErrorDialog = () => {
        return <Dialog
            open={this.state.errorMessage !== null}
            aria-labelledby="simple-dialog-title"
            fullWidth={true}
            maxWidth={'xs'}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    this.clearErrors();
                }
            }}
            aria-describedby="alert-dialog-description"
            title={i18n.t('text.editor.test.error')}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.state.errorMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button size="large" color="secondary" variant="contained" className="tc-jsw-margin-right tc-buttons-borderless" onClick={this.clearErrors}>{i18n.t('text.editor.test.cancel')}</Button>
            </DialogActions>
        </Dialog>
    }

    public render() {
        if (this.state.openDescriptor) {
            const mime = this.state.openDescriptor.mime;
            if (mime === MIME_TYPES.GIF || mime === MIME_TYPES.SVG || mime === MIME_TYPES.WEBP || isReadOnly(this.state.openDescriptor)) {
                let url;
                if (mime === MIME_TYPES.SVG) {
                    const blob = new Blob([this.state.editorContent], { type: "image/svg+xml" });
                    url = URL.createObjectURL(blob);
                } else {
                    url = "data:image/jpeg;base64," + Base64.btoa(this.state.editorContent);
                }
                return <div style={{ display: 'flex', flex: 1, alignItems: 'stretch', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                        <ToolBar toolbarData={this.simpleToolbarData()} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, backgroundImage: `url(${background})`, position: 'relative' }}>
                        <div style={{ display: 'flex', height: 1, width: '100%' }}>
                            <ReactResizeDetector handleWidth={true} handleHeight={false} onResize={this.onResizeWidth}>
                                <div style={{ flex: 1 }} />
                            </ReactResizeDetector>
                        </div>
                        <div style={{ display: 'flex', height: '100%', width: 1 }}>
                            <ReactResizeDetector handleWidth={false} handleHeight={true} onResize={this.onResizeHeight}>
                                <div style={{ flex: 1 }} />
                            </ReactResizeDetector>
                        </div>

                        <div style={{ flex: 1, display: 'flex', position: 'absolute', top: 0, left: 0 }}>
                            <img src={url} style={{ objectFit: 'contain', width: this.state.width, height: this.state.height }} />
                        </div>
                    </div>
                </div>
            } else {
                const base64 = "data:image/jpeg;base64," + Base64.btoa(this.state.editorContent);
                return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }} className="jrws-image-editor">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                        <ToolBar toolbarData={this.state.toolbarData} />
                    </div>
                    <div style={{ position: 'relative', flex: 1, alignItems: 'stretch', display: 'flex' }}>
                        <div style={{ display: 'flex', height: 1, width: '100%' }}>
                            <ReactResizeDetector handleWidth={true} handleHeight={false} onResize={this.onResizeWidth}>
                                <div style={{ flex: 1 }} />
                            </ReactResizeDetector>
                        </div>
                        <div style={{ display: 'flex', height: '100%', width: 1 }}>
                            <ReactResizeDetector handleWidth={false} handleHeight={true} onResize={this.onResizeHeight}>
                                <div style={{ flex: 1 }} />
                            </ReactResizeDetector>
                        </div>

                        <div style={{ flex: 1, display: 'flex', position: 'absolute', top: 0, left: 0 }}>
                            <ToastImageEditor
                                style={{ flex: 1 }}
                                ref={this.editorRef}
                                includeUI={{
                                    loadImage: {
                                        path: base64,
                                        name: this.state.openDescriptor.name,
                                    },
                                    theme: whiteTheme,
                                    menu: ['draw', 'text', 'crop', 'flip', 'rotate', 'icon', 'shape', 'mask', 'filter'],
                                    initMenu: 'draw',
                                    menuBarPosition: 'right',
                                }}
                                selectionStyle={{
                                    cornerSize: 20,
                                    rotatingPointOffset: 70,
                                }}
                                usageStatistics={false}
                            />
                            {this.getErrorDialog()}
                            {this.getSuccessMessageDialog()}
                        </div>

                    </div>

                </div>
            }
        } else {
            return null;
        }
    }
}

ImageEditor.contextType = RunContext;
