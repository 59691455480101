/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from "react";
import { ICBoolean } from "./ICBoolean";
import { ICCharacter } from "./ICCharacter";
import { ICCollection } from "./ICCollection";
import { ICDateTime } from "./ICDateTime";
import { ICFloat } from "./ICFloat";
import { ICInteger } from "./ICInteger";
import { ICLocale } from "./ICLocale";
import { ICSQLDate } from "./ICSQLDate";
import { ICString } from "./ICString";
import { ICTime } from "./ICTime";
import { ICTimeZone } from "./ICTimeZone";
import { JRParameter } from "./JRParameter";

const coltypes = ['java.lang.Integer', 'java.lang.Long', 'java.lang.Float', 'java.lang.Double', 'java.lang.String', 'java.lang.Boolean', 'java.math.BigInteger', 'java.math.BigDecimal', 'java.lang.Byte', 'java.lang.Short', 'java.util.Date', 'java.sql.Date', 'java.sql.Time', 'java.sql.Timestamp', 'java.lang.Character'];

export const getParameterControl = (prm: JRParameter, parameterValue: any, setParameterValue: any) => {
    if (!prm.class) return null;
    switch (prm.class) {
        // add here more java types
        case 'java.sql.Date':
            return <ICSQLDate key={prm.name} param={prm} parametersValue={parameterValue} setParameterValue={setParameterValue} />;
        case 'java.sql.Time':
            return <ICTime key={prm.name} param={prm} parametersValue={parameterValue} setParameterValue={setParameterValue} />;
        case 'java.util.Date':
        case 'java.sql.Timestamp':
            return <ICDateTime key={prm.name} param={prm} parametersValue={parameterValue} setParameterValue={setParameterValue} />;
        case 'java.lang.Integer':
        case 'java.lang.Long':
        case 'java.lang.Byte':
        case 'java.lang.Short':
        case 'java.math.BigInteger':
            return <ICInteger key={prm.name} param={prm} parametersValue={parameterValue} setParameterValue={setParameterValue} />;
        case 'java.lang.Float':
        case 'java.lang.Double':
        case 'java.math.BigDecimal':
            return <ICFloat key={prm.name} param={prm} parametersValue={parameterValue} setParameterValue={setParameterValue} />;
        case 'java.lang.String':
            return <ICString key={prm.name} param={prm} parametersValue={parameterValue} setParameterValue={setParameterValue} />;
        case 'java.lang.Character':
            return <ICCharacter key={prm.name} param={prm} parametersValue={parameterValue} setParameterValue={setParameterValue} />;
        // timezone
        // locales (java)
        case 'java.util.Locale':
            return <ICLocale key={prm.name} param={prm} parametersValue={parameterValue} setParameterValue={setParameterValue} />;
        case 'java.util.TimeZone':
            return <ICTimeZone key={prm.name} param={prm} parametersValue={parameterValue} setParameterValue={setParameterValue} />;
        case 'java.lang.Boolean':
            return <ICBoolean key={prm.name} param={prm} parametersValue={parameterValue} setParameterValue={setParameterValue} />;
        case 'java.util.ArrayList':
        case 'java.util.List':
        case 'java.util.Set':
        case 'java.util.HashSet':
        case 'java.util.Collection':
            const nt = prm.nestedTypeName !== undefined ? prm.nestedTypeName.trim() : 'java.lang.String';
            if (coltypes.some(t => t === nt))
                return <ICCollection key={prm.name} param={prm} parametersValue={parameterValue} setParameterValue={setParameterValue} nestedType={nt} />;
        default:
            if (prm.class?.endsWith('[]')) {
                const nt = prm.class.substring(0, prm.class.length - 2);
                if (coltypes.some(t => t === nt))
                    return <ICCollection key={prm.name} param={prm} parametersValue={parameterValue} setParameterValue={setParameterValue} nestedType={nt} />;
            }
    }
}