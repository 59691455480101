/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { i18n } from '@jss/js-common';
import { en } from './assets/locales/en/messages';
import { fr } from './assets/locales/fr/messages';
import { it } from './assets/locales/it/messages';

i18n.addResourceBundle('en', 'jrws', en, true);
i18n.addResourceBundle('it', 'jrws', it, true);
i18n.addResourceBundle('fr', 'jrws', fr, true);

export * from './widget';
export * from './datasource';
export * from './datasource/DataAdaptersRegistry';
export * from './datasource/IDataAdapterDescriptor';
export * from './editor/DataAdapterEditorView';