/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const SpiderColumnChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath>
          <path fill="none" d="M0 0H535V740H0z" />
       </clipPath>
       <clipPath id="highcharts-ozc04a7-2">
          <circle cx="267.5" cy="370" r="227.375" />
       </clipPath>
       <clipPath id="highcharts-ozc04a7-3">
          <circle cx="267.5" cy="370" r="227.375" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M10 10H545V750H10z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M277.5 380V152.625M277.5 380l113.688-196.913M277.5 380l196.913-113.688M277.5 380h227.375M277.5 380l196.913 113.687M277.5 380l113.688 196.913M277.5 380v227.375M277.5 380L163.813 576.913M277.5 380L80.587 493.688M277.5 380H50.125M277.5 380L80.587 266.313M277.5 380L163.812 183.087M277.5 380V152.625" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M277.5 380h0M277.5 323.156h0l-28.422 7.616-20.806 20.806L220.656 380l7.616 28.422 20.806 20.806 28.422 7.616 28.422-7.616 20.806-20.806L334.344 380l-7.616-28.422-20.806-20.806-28.422-7.616" className="highcharts-grid-line" data-z-index="1" />
       <path fill="none" stroke="#e6e6e6" d="M277.5 266.313h0l-56.844 15.23-41.612 41.613L163.812 380l15.232 56.844 41.612 41.612 56.844 15.231 56.844-15.23 41.612-41.613L391.187 380l-15.23-56.844-41.613-41.612-56.844-15.231" className="highcharts-grid-line" data-z-index="1" />
       <path fill="none" stroke="#e6e6e6" d="M277.5 209.469h0l-85.266 22.847-62.418 62.418L106.969 380l22.847 85.266 62.418 62.418 85.266 22.847 85.266-22.847 62.418-62.418L448.031 380l-22.847-85.266-62.418-62.418-85.266-22.847" className="highcharts-grid-line" data-z-index="1" />
       <path fill="none" stroke="#e6e6e6" d="M277.5 152.625h0l-113.688 30.462-83.225 83.226L50.125 380l30.462 113.688 83.226 83.225L277.5 607.375l113.688-30.462 83.225-83.226L504.875 380l-30.462-113.688-83.226-83.225L277.5 152.625" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M10 10H545V750H10z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" d="M277.5 152.625a227.375 227.375 0 11-.227 0M277.5 380" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M277.5 380V152.625" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-column-series highcharts-color-0 highcharts-dense-data highcharts-tracker" clipPath="url(#highcharts-ozc04a7-2)" data-z-index="0.1" transform="translate(10 10)">
          <path fill="#7cb5ec" d="M244.787 210.41a161.198 161.198 0 0120.02-1.585L267.5 370zM289.348 315.65a58.576 58.576 0 016.589 3.14L267.5 370zM416.146 253.447a188.892 188.892 0 0113.338 19.387L267.5 370zM288.84 366.963a21.555 21.555 0 01.212 2.677L267.5 370zM290.978 379.438a25.305 25.305 0 01-1.355 2.846L267.5 370zM368.484 498.79a163.66 163.66 0 01-16.797 11.557L267.5 370zM268.705 378.464a8.55 8.55 0 01-1.062.084L267.5 370zM250.905 411.282a44.493 44.493 0 01-5.005-2.384L267.5 370zM225.033 403.298a53.965 53.965 0 01-3.81-5.538L267.5 370zM247.022 372.914a20.684 20.684 0 01-.203-2.568L267.5 370zM139.614 318.59A137.832 137.832 0 01147 303.088L267.5 370zM265.828 367.867a2.71 2.71 0 01.278-.191L267.5 370z" className="highcharts-point highcharts-color-0" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-column-series highcharts-color-1 highcharts-dense-data highcharts-tracker" clipPath="url(#highcharts-ozc04a7-3)" data-z-index="0.1" transform="translate(10 10)">
          <path fill="#434348" d="M267.56 366.135a3.865 3.865 0 01.48.038L267.5 370zM268.668 368.049a2.274 2.274 0 01.233.16L267.5 370zM273.659 366.572a7.049 7.049 0 01.378.792L267.5 370zM268.182 370.01a.682.682 0 01-.007.085L267.5 370zM268.476 370.584a1.137 1.137 0 01-.08.117L267.5 370zM268.495 371.788a2.046 2.046 0 01-.23.11L267.5 370z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" d="M267.49 370.682a.682.682 0 01-.085-.007l.095-.675zM266.45 371.756a2.046 2.046 0 01-.211-.144L267.5 370zM265.116 371.327a2.728 2.728 0 01-.147-.307L267.5 370zM266.59 369.986a.91.91 0 01.01-.113l.9.127zM264.769 368.365a3.183 3.183 0 01.224-.327L267.5 370z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" d="M267.279 369.603a.455.455 0 01.051-.025l.17.422z" className="highcharts-point highcharts-color-1" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(186 762)">
       <rect width="183" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-column-series highcharts-color-0 highcharts-series-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Max Freight</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-column-series highcharts-color-1 highcharts-series-1" data-z-index="1" transform="translate(116.938 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>Orders</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11">
       <text x="277.5" y="142.125" textAnchor="middle">Albuquerque</text>
       <text x="398.688" y="174.597">Anchorage</text>
       <text x="487.403" y="263.313">Boise</text>
       <text x="519.875" y="384.5">
          <tspan>Butte</tspan>
       </text>
       <text x="487.403" y="505.687">Elgin</text>
       <text x="398.688" y="594.403">Eugene</text>
       <text x="277.5" y="626.875" textAnchor="middle">Kirkland</text>
       <text x="156.313" y="594.403" textAnchor="end">Lander</text>
       <text x="67.597" y="505.688" textAnchor="end">Portland</text>
       <text x="35.125" y="384.5" textAnchor="end">
          <tspan>San</tspan>
          <tspan x="35.125" dy="14">Francisco</tspan>
       </text>
       <text x="67.597" y="263.313" textAnchor="end">Seattle</text>
       <text x="156.312" y="174.597" textAnchor="end">
          <tspan>Walla Walla</tspan>
       </text>
       <text y="-9999" textAnchor="middle">12</text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="274.5" y="378">0</text>
       <text x="274.5" y="321.156">250</text>
       <text x="274.5" y="264.313">500</text>
       <text x="274.5" y="207.469">750</text>
       <text y="-9999">1.000</text>
    </g>
 </svg>)};

export default SpiderColumnChartIcon;