/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ICONS } from "@jss/js-common";
import { ElementTypes } from "../../../../../../sagas/report/document/elementTypes";
import { APDescriptor } from "../../../ui/APDescriptor";
import { PAccordeonCategories } from "../../../ui/composite/UPAccordeonCategories";
import { PCategories } from "../../../ui/composite/UPCategories";
import { PDiv } from "../../../ui/composite/UPDiv";
import { PScrollableCategories } from "../../../ui/composite/UPScrollableCategories";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { PColor } from "../../../ui/primitive/UPColor";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { PString } from "../../../ui/primitive/UPString";
import { PConnectedTableDetail } from "../../../ui/tables/UPConnectedTableDetail";
import { getPath, UiPropertyProps } from "../../../ui/UiProperty";
import { MBoxGraphicElement } from "../../common/MGraphicElement";
import { MHyperlinks } from "../../common/MHyperlinks";
import { MDatasetRun } from "../../dataset/MDatasetRun";
import { EvaluationTimeEnum } from "../../enums/EvaluationTimeEnum";
import { IncrementTypeEnum } from "../../enums/IncrementTypeEnum";
import { ResetTypeEnum } from "../../enums/ResetTypeEnum";
import { MFusionAreaChart, MFusionBar3DChart, MFusionBarChart, MFusionColumn3DChart, MFusionColumnChart, MFusionLineChart, MFusionStackedAreaChart, MFusionStackedBar3DChart, MFusionStackedBarChart, MFusionStackedColumn3DChart, MFusionStackedColumnChart } from "./MFusionChartCommon";
import { MFusionChartsCustom } from "../MFusionChartsCustom";
import { MFusionDoughnut3DChart, MFusionDoughnutChart } from "./MFusionDoughnut";
import { MFusionPie3DChart, MFusionPieChart } from "./MFusionPieChart";

export const FFusionChart = {
    mapNameExpression: new PExpression({ id: 'mapNameExpression', label: 'Map Name' }),
    entityNamesBundle: new PString({ id: 'entityNamesBundle', label: 'Names Bundle' }),
    evaluationTime: EvaluationTimeEnum,

    incrementType: IncrementTypeEnum,
    resetType: ResetTypeEnum,
    incrementWhenExpression: new PExpression({ id: 'incrementWhenExpression', label: 'Increment When' }),

    valueExpression: new PExpression({ id: 'valueExpression', label: 'Value' }),
    labelExpression: new PExpression({ id: 'labelExpression', label: 'Label' }),
    seriesExpression: new PExpression({ id: 'seriesExpression', label: 'Series' }),
    displayValueExpression: new PExpression({ id: 'displayValueExpression', label: 'displayValue' }),

    // --- trend line
    color: new PColor({ id: 'color', label: 'Color' }),
    startValueExpression: new PExpression({ id: 'startValueExpression', label: 'Start Value' }),
    endValueExpression: new PExpression({ id: 'endValueExpression', label: 'End Value' }),
    trendZone: new PBoolean({ id: 'trendZone', label: 'Trend Zone' }),
};

export const MFusionChart: APDescriptor = {
    type: ElementTypes.FUSIONCHART,
    label: 'Fusion Chart',
    id: 'fusionChartProeprtiesDescriptor',
    default: { width: 200, height: 30, ...MFusionBarChart.default },
    icon: ICONS.MAP_PRO,
    layouts: [new PScrollableCategories({
        id: 'fusionChartPropertiesTabsDescriptor',
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MBoxGraphicElement.layouts, MFusionChartsCustom] }),
            new PDiv({
                label: 'Fusion Chart',
                layouts: [
                    new PAccordeonCategories({
                        selectionField: 'chartType',
                        getSelected(props: UiPropertyProps): APDescriptor | undefined {
                            const pac = props.mcontext.descriptor as PAccordeonCategories;
                            const p = getPath(pac.selectionField ? pac.selectionField : pac.id, props.mcontext.elements[0].path);
                            const v: string = props.mcontext.model.getIn(p) as string;
                            return pac.layouts?.find(k => {
                                return k.id.toLowerCase() === v.toLowerCase()
                            });
                        },
                        categories: [MFusionBarChart, MFusionBar3DChart, MFusionColumnChart, MFusionColumn3DChart, MFusionLineChart, MFusionAreaChart, MFusionPieChart,
                            MFusionPie3DChart, MFusionDoughnutChart, MFusionDoughnut3DChart, MFusionStackedColumnChart, MFusionStackedColumn3DChart, MFusionStackedBarChart, MFusionStackedBar3DChart, MFusionStackedAreaChart
                        ]
                    })
                ]
            }), new PCategories({
                label: 'Data',
                id: 'fusionChartDataPropertiesTabsDescriptor',
                layouts: [
                    new PDiv({
                        id: 'fusion.data.dataset', path: 'chartDataset.dataset',
                        label: 'Dataset',
                        layouts: [FFusionChart.incrementType, FFusionChart.incrementWhenExpression, FFusionChart.resetType, MDatasetRun]
                    }),
                    new PDiv({
                        id: 'fusion.data.entities', path: 'chartDataset',
                        label: 'Items',
                        layouts: [new PConnectedTableDetail({
                            id: 'items', label: 'Items', rowLabel: 'Item',
                            rowLabelPaths: [FFusionChart.labelExpression.id, FFusionChart.valueExpression.id, FFusionChart.displayValueExpression.id],
                            expanded: true, layouts: [new PCategories({
                                layouts: [
                                    new PDiv({
                                        id: 'fusion.data.entities.data',
                                        label: 'Data',
                                        layouts: [FFusionChart.seriesExpression, FFusionChart.valueExpression, FFusionChart.labelExpression, FFusionChart.displayValueExpression]
                                    }),
                                    new PDiv({
                                        id: 'fusion.data.entities.hyperlinks',
                                        label: 'Hyperlink', path: 'hyperlink',
                                        layouts: [...MHyperlinks.layouts]
                                    })
                                ]
                            })],
                            default: { id: 'entity-1' }
                        })]
                    })]
            }),
            new PConnectedTableDetail({
                id: 'trendLines', label: 'Trend Lines', rowLabel: 'Trend Line', rowLabelPaths: ['color', 'labelExpression'], expanded: true, layouts: [new PDiv({
                    id: 'fusion.colorranges',
                    layouts: [FFusionChart.color, FFusionChart.trendZone, FFusionChart.labelExpression, FFusionChart.startValueExpression, FFusionChart.endValueExpression
                    ]
                })], default: { color: '#000000' }
            })]
    })]
};
