/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StackedAreaChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M44 4v130h218V4z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M24.51 157.188L245.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M24.51 173.5L245.49 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M24.51 173.5L24.51 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M245.49 173.5L245.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="#F55" stroke="none" d="M28.01 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="gray" d="M28.01 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="40.01" y="168.113" stroke="none" clipPath="url(#clipPath1)">Double Freight</text>
            <path fill="#55F" stroke="none" d="M105.22 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="gray" d="M105.22 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="117.221" y="168.113" stroke="none" clipPath="url(#clipPath1)">Normal Freight</text>
            <path fill="#5F5" stroke="none" d="M183.17 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="gray" d="M183.17 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="195.17" y="168.113" stroke="none" clipPath="url(#clipPath1)">Half Freight</text>
            <path fill="none" strokeWidth="0.5" d="M44 134L262 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M74.52 134L74.52 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M74.52 134L74.52 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M113.76 134L113.76 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M113.76 134L113.76 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M153 134L153 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M153 134L153 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M192.24 134L192.24 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M192.24 134L192.24 136" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M231.48 134L231.48 134" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M231.48 134L231.48 136" clipPath="url(#clipPath1)" />
            <text x="54.938" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Argentina</text>
            <text x="99.629" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Austria</text>
            <text x="136.602" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Belgium</text>
            <text x="181.078" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Brazil</text>
            <text x="216.259" y="147.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Canada</text>
            <path fill="none" strokeWidth="0.5" d="M44 4L44 134" clipPath="url(#clipPath1)" />
            <text x="32.91" y="136.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
            <path fill="none" strokeWidth="0.5" d="M42 134L44 134" clipPath="url(#clipPath1)" />
            <text x="15.098" y="112.841" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">5.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 110.071L44 110.071" clipPath="url(#clipPath1)" />
            <text x="10.008" y="88.912" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 86.142L44 86.142" clipPath="url(#clipPath1)" />
            <text x="10.008" y="64.983" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">15.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 62.213L44 62.213" clipPath="url(#clipPath1)" />
            <text x="10.008" y="41.054" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">20.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 38.284L44 38.284" clipPath="url(#clipPath1)" />
            <text x="10.008" y="17.125" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">25.000</text>
            <path fill="none" strokeWidth="0.5" d="M42 14.355L44 14.355" clipPath="url(#clipPath1)" />
         </g>
         <g fill="silver" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M44 134L262 134" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 110.071L262 110.071" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 86.142L262 86.142" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 62.213L262 62.213" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 38.284L262 38.284" clipPath="url(#clipPath2)" />
            <path fill="none" d="M44 14.355L262 14.355" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M74.52 128.27V134H54.9v-5.73zM74.52 134v-5.73l19.62-32.509V134z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M74.52 125.406v2.865H54.9v-2.865zM74.52 128.27v-2.864l19.62-48.764v19.12z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M74.52 123.974v1.432H54.9v-1.432zM74.52 125.406v-1.432l19.62-56.892v9.56z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M113.76 63.252V134H94.14V95.761zM113.76 134V63.252l19.62 29.247V134z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M113.76 27.878v35.374l-19.62 32.51v-19.12zM113.76 63.252V27.878l19.62 43.871V92.5z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M113.76 10.19v17.688L94.14 76.642v-9.56zM113.76 27.878V10.19l19.62 51.184v10.375z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M153 121.747V134h-19.62V92.5zM153 134v-12.253l19.62-17.229V134z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M153 115.62v6.127l-19.62-29.248V71.75zM153 121.747v-6.126l19.62-25.844v14.741z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M153 112.557v3.064l-19.62-43.872V61.374zM153 115.62v-3.063l19.62-30.15v7.37z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M192.24 87.289V134h-19.62v-29.482zM192.24 134V87.289l19.62 12.836V134z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M192.24 63.933V87.29l-19.62 17.229V89.777zM192.24 87.289V63.933l19.62 19.254v16.938z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M192.24 52.256v11.677l-19.62 25.844v-7.37zM192.24 63.933V52.256l19.62 22.463v8.468z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M231.48 112.96V134h-19.62v-33.875zM231.48 134v-21.04h19.62V134z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M231.48 102.441v10.52l-19.62-12.836V83.187zM231.48 112.96v-10.519h19.62v10.52z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M231.48 97.181v5.26l-19.62-19.254V74.72zM231.48 102.441v-5.26h19.62v5.26z" clipPath="url(#clipPath2)" />
         </g>
      </svg>
   );
}
export default StackedAreaChartIcon;