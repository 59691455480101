
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ElementTypes } from "../../../../sagas/report/document/elementTypes";
import { MBand } from "./band/MBand"
import { MDatasets } from "./dataset/MDatasets";
import { MField } from "./dataset/MField";
import { MGroup } from "./dataset/MGroup";
import { MParameter } from "./dataset/MParameter";
import { MScriptlet } from "./dataset/MScriptlet";
import { MSortField } from "./dataset/MSortField";
import { MVariable } from "./dataset/MVariable";
import { MReport } from "./report/MReport";
import { MConditionalStyle } from "./styles/MConditionalStyle";
import { MStyle, MStyleTemplateReference } from "./styles/MStyle";
import { MBarcode } from "./barcode/MBarcode";
import { MBreak } from "./elements/MBreak";
import { MCrosstab } from "./crosstab/MCrosstab";
import { MCVC } from "./cvc/MCVC";
import { MElementGroup } from "./elements/MElementGroup";
import { MEllipse } from "./elements/MEllipse";
import { MFrame } from "./elements/MFrame";
import { MGenericElement } from "./elements/MGenericElement";
import { MHighCharts } from "./highchart/MHighChart";
import { MIMage } from "./elements/MImage";
import { MJFreeChart } from "./chart/MJFreeChart";
import { MLine } from "./elements/MLine";
import { MList } from "./elements/MList";
import { MMap } from "./map/MMap";
import { MRectangle } from "./elements/MRectangle";
import { MStaticText } from "./elements/MStaticText";
import { MSubreport } from "./elements/MSubreport";
import { MTable } from "./table/MTable";
import { MTextField } from "./elements/MTextField";
import { MTibcoMap } from "./maptibco/MTibcoMap";
import { APDescriptor, SubtypedElement } from "../ui/APDescriptor";
import { TableTypes } from "../../../common/JrxmlModel/reader/JrxmlTableUtils";
import { MTableRow } from "./table/MTableRow";
import { MTableCell } from "./table/MTableCell";
import { CrosstabTypes } from "../../../common/JrxmlModel/reader/JrxmlCrosstabUtils";
import { MCrosstabRowGroup } from "./crosstab/MCrosstabRowGroup";
import { MCrosstabColumnGroup } from "./crosstab/MCrosstabColumnGroup";
import { MCrosstabCell } from "./crosstab/MCrosstabCell";
import { MFusionMap } from "./fusion/map/MFusionMap";
import { MFusionChart } from "./fusion/chart/MFusionChart";
import { MFusionWidgets } from "./fusion/widgets/MFusionWidgets";
import { MSectionDetail, MSectionGroup, MSubreportPart } from "./book/MSubreportPart";
import { MBook } from "./book/MBook";
import { MEmpty } from "./common/MEmpty";
import { MUnknownElement } from "./elements/MUnknownElement";
import { MAdhocComponent } from "./elements/MAdhocComponent";

const getDescriptor = (t: string[], type: APDescriptor): APDescriptor => {
    let item: APDescriptor;
    if (t.length > 1 && 'subtypes' in type)
        item = (<SubtypedElement>type).subtypes.find(k => k.id === t[1]);
    if (!item)
        item = type;
    return item;
}

export const getModel = (type: string): APDescriptor => {
    if (type === undefined)
        return MReport;
    const t = type.split(':');
    switch (t[0]) {
        case ElementTypes.DATASET: return MDatasets;
        case ElementTypes.DATASET_GROUP: return MGroup;
        case ElementTypes.FIELD: return MField;
        case ElementTypes.PARAMETER: return MParameter;
        case ElementTypes.SORT_FIELD: return MSortField;
        case ElementTypes.VARIABLE: return MVariable;
        case ElementTypes.SCRIPTLET: return MScriptlet;

        case ElementTypes.STYLE: return MStyle;
        case ElementTypes.CONDITIONAL_STYLE: return MConditionalStyle;
        case ElementTypes.STYLE_TEMPLATE_REFERENCE: return MStyleTemplateReference;
        // case ElementTypes.STYLE_TEMPLATE: return MStyle;

        case ElementTypes.BAND: return MBand;

        case ElementTypes.TEXTFIELD: return MTextField;
        case ElementTypes.STATICTEXT: return MStaticText;
        case ElementTypes.IMAGE: return MIMage;

        case ElementTypes.RECTANGLE: return MRectangle;
        case ElementTypes.LINE: return MLine;

        case ElementTypes.TABLE: return MTable;
        case TableTypes.TABLE_HEADER_NAME:
        case TableTypes.TABLE_FOOTER_NAME:
        case TableTypes.TABLE_GROUP_HEADER_NAME:
        case TableTypes.TABLE_GROUP_FOOTER_NAME:
        case TableTypes.TABLE_COLUMN_HEADER_NAME:
        case TableTypes.TABLE_COLUMN_FOOTER_NAME:
        case TableTypes.TABLE_DETAIL_NAME:
            return MTableRow;
        case TableTypes.TABLE_CELL_NAME:
        case TableTypes.TABLE_GROUP_COLUMN_NAME:
        case TableTypes.TABLE_GROUP_CELL_NAME:
            return MTableCell;

        case ElementTypes.CROSSTAB: return MCrosstab;
        case CrosstabTypes.CROSSTAB_ROW_GROUP_NAME: return MCrosstabRowGroup;
        case CrosstabTypes.CROSSTAB_ROW_HEADER_NAME: return MCrosstabCell;
        case CrosstabTypes.CROSSTAB_TOTAL_ROW_HEADER_NAME: return MCrosstabCell;
        case CrosstabTypes.CROSSTAB_COLUMN_GROUP_NAME: return MCrosstabColumnGroup;
        case CrosstabTypes.CROSSTAB_COLUMN_HEADER_NAME: return MCrosstabCell;
        case CrosstabTypes.CROSSTAB_TOTAL_COLUMN_HEADER_NAME: return MCrosstabCell;
        case CrosstabTypes.CROSSTAB_HEADER_CELL_NAME:
        case CrosstabTypes.CROSSTAB_CELL_NAME: return MCrosstabCell;

        case ElementTypes.FRAME: return MFrame;
        case ElementTypes.AD_HOC_COMPONENT: return MAdhocComponent;
        case ElementTypes.SUBREPORT: return MSubreport;

        case ElementTypes.PART: return MSubreportPart;
        case ElementTypes.SECTION_DETAIL: return MSectionDetail;
        case ElementTypes.SECTION_GROUP: return MSectionGroup;
        case ElementTypes.BOOK: return MBook;

        case ElementTypes.BARCODE: return getDescriptor(t, MBarcode);
        case ElementTypes.GENERIC_ELEMENT: return MGenericElement;

        case ElementTypes.CVC_ELEMENT: return MCVC;
        case ElementTypes.ELLIPSE: return MEllipse;
        case ElementTypes.JFREECHART: return getDescriptor(t, MJFreeChart);
        case ElementTypes.HTML5CHART_JR: return getDescriptor(t, MHighCharts);
        case ElementTypes.LIST: return MList;
        case ElementTypes.MAP: return getDescriptor(t, MMap);
        case ElementTypes.TIBCO_MAP: return getDescriptor(t, MTibcoMap);
        case ElementTypes.FUSION_MAP: return getDescriptor(t, MFusionMap);
        case ElementTypes.FUSIONCHART: return getDescriptor(t, MFusionChart);
        case ElementTypes.FUSION_WIDGET: return getDescriptor(t, MFusionWidgets);
        case ElementTypes.BREAK: return MBreak;

        case ElementTypes.ELEMENT_GROUP: return MElementGroup;

        case ElementTypes.BAND_PLACEHOLDER: return MEmpty;

        case ElementTypes.COMPONENT_ELEMENT_GENERIC: return MUnknownElement;

        default: return MReport;
    }
}