/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MGraphicElement } from "../common/MGraphicElement";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PrintOrderEnum } from "../enums/PrintOrderEnum";
import { PInt } from "../../ui/primitive/UPInteger";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { ICONS } from "@jss/js-common";
import { MDatasetRun } from "../dataset/MDatasetRun";
import { MListCustom } from "./MListCustom"; 

export const FList = {
    ignoreWidth: new PBoolean({ id: 'ignoreWidth', label: 'Ignore Width', canBeNull: true }),
    printOrder: PrintOrderEnum,
    width: new PInt({ id: 'listWidth', label: 'Width' }),
    height: new PInt({ id: 'listHeight', label: 'Height' })
};

export const MList: APDescriptor = {
    id: 'listProeprtiesDescriptor',
    type: ElementTypes.LIST,
    label: 'List',
    default: {
        listWidth: 100, listHeight: 30, LIST_XMLNS_NAMESPACE: "jr", width: 100, height: 30,
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:jr': "http://jasperreports.sourceforge.net/jasperreports/components",
        elementIds: []
    },
    icon: ICONS.LIST_ICON,
    layouts: [new PScrollableCategories({
        id: 'listPropertiesTabsDescriptor',
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MGraphicElement.layouts, MListCustom] }),
            new PDiv({
                label: 'List', layouts: [FList.ignoreWidth, FList.printOrder,
                new PAccordeon({
                    id: 'cell', label: 'Cell', expanded: true,
                    layouts: [FList.width, FList.height]
                }),
                ]
            }),
            new PDiv({
                label: 'Data',
                layouts: [MDatasetRun]
            })
        ]
    })]
};
