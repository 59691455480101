/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Pref } from "./Conf";
import { IConfigStore } from "./IConfigStore";
import { Lang } from "./Lang";

export class BLocalConfigStore implements IConfigStore {
	private store: string[] = [Pref.LOCALE, 'jrws.user.theme', 'jrws.legal.accept'];
	public init(cfg: any) {
		this.store.forEach(k => {
			const v = localStorage.getItem(k);
			if (v) {
				if (k === Pref.LOCALE) {
					cfg[k] = Lang.getLocale(v, cfg[Pref.LOCALES_AVAILABLE]);
				} else {
					cfg[k] = v;
				}
			}
		});
	}
	public set(key: string, value: any): boolean {
		if (this.store.includes(key)) {
			localStorage.setItem(key, value);
			return true;
		}
		return false;
	}
}