/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React, { forwardRef } from 'react';
import {
    ButtonGroup as MuiButtonGroup, ButtonGroupProps as MuiButtonGroupProps
} from '@material-ui/core';

// eslint-disable-next-line react/display-name
export const ButtonGroup = forwardRef<HTMLDivElement, MuiButtonGroupProps>(({
    className = '', disableElevation = true, ...rest
}, ref) => {
    return (
        <MuiButtonGroup
            ref={ref}
            className={`jr-mButtongroup mui ${className}`}
            variant="contained"
            color="secondary"
            disableElevation={disableElevation}
            {...rest}
        />
    )
})
