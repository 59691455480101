/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ADD_LOGGED_USER, LOGOUT_USER, ADD_LOGGED_USER_METADATA, SET_JRS_URL, SET_LANGUAGE, SET_THEME } from './actionTypes';
import {UserCredential} from '@jss/js-rest-api';

export const addLoggedUser = (baseUrl: string, userId: string, info?: string) => {
    return {
        type: ADD_LOGGED_USER,
        baseUrl,
        userId,
        info
    };
};

export const addUserMetadata = (metadata: UserCredential) => {
    return {
        type: ADD_LOGGED_USER_METADATA,
        metadata
    };
};

export const logoutUser = () => {
    return {
        type: LOGOUT_USER,
    };
};

export const setJRSURL = (url: string) => {
    return {
        type: SET_JRS_URL,
        url,
    };
};

export const setLanguage = (languageName) => {
    return {
        type: SET_LANGUAGE,
        name: languageName,
    }
}

export const setTheme = (themeName) => {
    return {
        type: SET_THEME,
        name: themeName,
    }
}