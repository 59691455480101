/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Accordion, AccordionDetails, AccordionSummary, IconButton, Typography } from '@jss/js-common';
import React from "react";
import { JRParameter } from './JRParameter';
import { ICParameters } from "./ParametersTab";
import { getParameterControl } from './ParametersUtils';
import { Map } from 'immutable';

export interface ICCollectionState {
    expanded: boolean;
}


export class ICCollection extends React.Component<ICParameters, ICCollectionState> {

    state = {
        expanded: true,
    }

    private handleExpanded = () => {
        this.setState((prevState) => { return { expanded: !prevState.expanded } });
    }

    private addItem = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        const currentParameters = this.props.parametersValue?.get(this.props.param.name);
        const values: string[] = currentParameters ? [...currentParameters] : [];
        values.push('');
        this.props.setParameterValue(this.props.param.name, values);
    }

    render() {
        const currentParameters = this.props.parametersValue?.get(this.props.param.name);
        const values: string[] = currentParameters ? currentParameters : [];
        const nestedType = this.props.nestedType ? this.props.nestedType : (this.props.param.nestedTypeName ? this.props.param.nestedTypeName : 'java.lang.String');
        return <Accordion expanded={this.state.expanded} onChange={this.handleExpanded} >
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className="js-jrws-search-accordion-header" >
                <Typography  >{this.props.param.name}</Typography>
                <IconButton color='primary' icon='plus' size='small' style={{ marginRight: '-8px', marginLeft: '4px' }} onClick={this.addItem} />
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block', maxHeight: '350px', overflow: 'auto', paddingLeft: 0, paddingRight: 0 }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flex: 1, overflow: 'auto' }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
                            {values?.map((value, index) => {
                                const prm: JRParameter = {
                                    name: 'Value ' + index,
                                    description: 'Value ' + index,
                                    class: nestedType,
                                    isSystemDefined: false,
                                    isForPrompting: true,
                                }
                                return this.renderParameter(prm, value, index);
                            })}
                        </div>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>;
    }

    deleteElement = (index: number) => {
        const parameterString = this.props.parametersValue?.get(this.props.param.name);
        const values: string[] = [...parameterString];
        values.splice(index, 1);
        this.props.setParameterValue(this.props.param.name, values);
    }

    private renderDelete(index: number) {
        return <IconButton icon='delete' onClick={() => this.deleteElement(index)} />
    }

    private renderParameter = (prm: JRParameter, value: string, parameterIndex: number) => {

        const setParameterValues = (parameterName: string, parameterValue: string) => {
            const currentParameters = this.props.parametersValue?.get(this.props.param.name);
            const newParameters = currentParameters ? [...currentParameters] : [];
            if (parameterIndex >= newParameters.length){
                newParameters.push(parameterValue);
            } else {
                newParameters[parameterIndex] = parameterValue;
            }
            this.props.setParameterValue(this.props.param.name, newParameters);
        }
        const objectValue = {};
        objectValue[prm.name] = value;
        const mapValues = Map<string, string>(objectValue);
        return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
            <div style={{ display: 'flex', flex: 1, alignItems: 'stretch', flexDirection: 'column' }}>
                {getParameterControl(prm, mapValues, setParameterValues)}
            </div>
            {this.renderDelete(parameterIndex)}
        </div>;
    }
}