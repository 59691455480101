/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { PEnum } from "../../ui/primitive/UPEnum";

export const ImageAnchorTypeEnum = new PEnum({
    id: 'imageAnchorTypeEnum', label: 'ImageAnchorType.Label', default: 'MoveNoSize', sinceVersion: '5.0.4',
    items: [{ key: '', value: '' },
    { key: 'MoveSize', value: 'ImageAnchorType.MoveSize' },
    { key: 'MoveNoSize', value: 'ImageAnchorType.MoveNoSize' },
    { key: 'NoMoveNoSize', value: 'ImageAnchorType.NoMoveNoSize' },
    ]
});

