/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { APDescriptor } from "../../ui/APDescriptor";
import { PColor } from "../../ui/primitive/UPColor";
import { PMode } from "../../ui/primitive/UPMode";

export const FColor = {
    forecolor: new PColor({ id: 'forecolor', label: 'Forecolor', inheritedPropertyId: 'forecolor', allowTransparent: true }),
    backcolor: new PColor({ id: 'backcolor', label: 'Backcolor', allowTransparent: true, inheritedPropertyId: 'backcolor' }),
    mode: new PMode({ id: 'mode', label: 'Transparent' }),

};

export const MColor: APDescriptor = {
    type: 'MColor',
    id: 'colorProeprtiesDescriptor',
    layouts: [new PAccordeon({
        id: 'element.color', label: 'Colors',
        layouts: [FColor.forecolor, FColor.backcolor, FColor.mode]
    })],
};

export const MColorStyle: APDescriptor = {
    type: 'MColor',
    id: 'styleColorProeprtiesDescriptor',
    layouts: [new PAccordeon({
        id: 'element.color.style', label: 'Colors',
        layouts: [{ ...FColor.forecolor, default: undefined }, { ...FColor.backcolor, default: undefined }, { ...FColor.mode, default: undefined }]
    })],
};