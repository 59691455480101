/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { Select } from "@jss/js-common";
import { getKey, getPath, reg, UiProperty, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../../ui/APDescriptor';

const TYPEID = 'barcodeImp';
reg(TYPEID, (mc) => { return <UPBarcodeImpl mcontext={mc} />; });
export class PBarcodeImpl extends APDescriptor {
    types: APDescriptor[];
    default?: string;
    public constructor(types: APDescriptor[]) {
        super();
        const def = types[0];
        Object.assign(this, { id: def.id, label: def.label, icon: def.icon, default: def.default, type: TYPEID, types: types, layouts: [...types] });
    }
}
export class UPBarcodeImpl extends React.Component<UiPropertyProps> {

    render() {
        const d = this.props.mcontext.descriptor as PBarcodeImpl;
        const p = getPath('BARCODE_TYPE', this.props.mcontext.elements[0].path);

        let v = this.props.mcontext.model.getIn(p);
        v = v === 'barbecue' ? 'barbecue' : 'barcode4j';
        const bd = v === 'barbecue' ? d.layouts?.find(k => { return k.type === 'barbecue' }) : d.layouts?.find(k => { return k.type !== 'barbecue' });

        return <div style={{ flex: 1, flexDirection: 'column', flexBasis: 0, overflow: 'auto' }} >
            <Select key={getKey(p)} items={[{ key: 'barcode4j', value: 'Barcode4J' }, { key: 'barbecue', value: 'Barbecue' }]}
                className={d.className}
                style={d.style}
                value={v}
                onItemSelected={this.onNameChange}
                label='Implementation'
                inline={true} size={'large'}
            />
            {bd?.layouts?.map(key => {
                return <UiProperty key={bd.id + '.' + key.id} mcontext={{ ...this.props.mcontext, descriptor: key }} />
            })}
        </div>
    }
    public onNameChange = (str) => {
        const d = this.props.mcontext.descriptor as PBarcodeImpl;
        const dt = str === 'barbecue' ? d.types[1] : d.types[0];

        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { ['BARCODE_TYPE']: dt.default.BARCODE_TYPE });
            if (str === 'barbecue') {
                this.props.mcontext.setObjectProperties(key.path, { ['barbecue_type']: dt.default.barbecue_type });
            }

            Object.keys(dt.default).forEach(k => {
                const p = getPath(k, this.props.mcontext.elements[0].path);
                const v = this.props.mcontext.model.getIn(p);
                if (!v) {
                    this.props.mcontext.setObjectProperties(key.path, { [k]: dt.default.barbecue_type });
                }
            });
        });
    }

}