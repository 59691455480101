/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { getKey, getNormalizedPath, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { FormControlledWritableCombo } from '@jss/js-common';
import i18n from '../../../../../i18n';

const TYPEID = 'javaClass';
reg(TYPEID, (mc) => { return <UPJavaClass mcontext={mc} />; });
export class PJavaClass extends APDescriptor {
    items?: string[];
    parentClass?: string;
    mandatory?: boolean;
    refreshCacheOnDelete?: boolean;
    refreshCacheOnAdd?: boolean;
    public constructor(init: Partial<PJavaClass>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

const classNameValidator = (value: string | undefined, descriptor: PJavaClass) => {
    const currentValue = value && value !== null ? value.trim() : undefined;
    if (descriptor.mandatory && (!currentValue || currentValue.length === 0)){
        return i18n.t('JavaClass.className.error.mandatory');
    }
    const regexp = /[\s\<\>\#\(\)\ˆ\{\}\;\/\,\"\'\`\?\!\:\&\@\-\^\%\&\*\+\=\˜]/;
    if (value && value.match(regexp)){
        return i18n.t('JavaClass.className.error.label');
    }
  return undefined;
}

export interface IUPJavaClass {
    additionalIcon?: React.ReactElement, 
}

export class UPJavaClass<T = void> extends React.Component<UiPropertyProps & IUPJavaClass & T> {

    shouldComponentUpdate(nextProps: Readonly<UiPropertyProps>): boolean {
        const d1 = this.props.mcontext.descriptor as PJavaClass;
        const d2 = nextProps.mcontext.descriptor as PJavaClass;
        const p1 = getPath(d1.id, this.props.mcontext.elements[0].path);
        const p2 = getPath(d2.id, nextProps.mcontext.elements[0].path);
        const v1 = this.props.mcontext.model.getIn(p1);
        const v2 = nextProps.mcontext.model.getIn(p2);
        return v1 !== v2;
    }

    render() {
        const d = this.props.mcontext.descriptor as PJavaClass;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p) as string | undefined;

        const items = [];
        if(d.items){
            d.items.forEach(k=>{items.push({key: k, value: k})});
        }
        const validator = (value: string) => classNameValidator(value, d);
        return <FormControlledWritableCombo
            items={items}
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            key={getKey(p)}
            value={v}
            disabled={d.readonly}
            onComboChange={this.onWritableNameChange}
            label={i18n.t(this.props.mcontext.descriptor.label)}
            inline={true}
            InputLabelProps={d.deprecated ? {className: 'deprecatedProperty'} : undefined}
            InputProps={{title: v}}
            iconButtons={this.props.additionalIcon ? [this.props.additionalIcon] : undefined}
            validator={validator}
            size="small"
            onBlur={() => {
                if (d.refreshCacheOnAdd){
                    this.props.mcontext.refreshCache();
                }
            }}
        />
    }

    public onWritableNameChange = (selectedKey: string | undefined, selectedText: string) => {
        const d = this.props.mcontext.descriptor as PJavaClass;
        this.props.mcontext.elements.forEach(key => {
            if ((!selectedKey && !selectedText) || (!selectedKey && selectedText.trim().length === 0)){
                const path = getNormalizedPath(key.path);
                path.push(this.props.mcontext.descriptor.id);
                this.props.mcontext.deleteElement(path, d.refreshCacheOnDelete);
            } else {
                //when the value change with the combo check the refresh cache
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: selectedKey ? selectedKey : selectedText }, selectedKey !== undefined && d.refreshCacheOnAdd);
            }
        });
    }

}
