/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { BaselinePositionEnum } from "../../enums/BaselinePositionEnum";
import { ChecksumModeEnum } from "../../enums/ChecksumModeEnum";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { PInt } from "../../../ui/primitive/UPInteger";
import { FBarcode4J, MBarcode4J } from "./MBarcode4J";


export const FPostnet = {
    shortBarHeight: new PInt({ id: 'shortBarHeight', label: 'Short Bar Height', min: 0 }),
    intercharGapWidth: new PInt({ id: 'intercharGapWidth', label: 'Interchar Gap Width', min: 0 }),
    displayChecksum: new PBoolean({ id: 'displayChecksum', label: 'Display Checksum', canBeNull: true }),
    checksumMode: ChecksumModeEnum,
    baselinePosition: BaselinePositionEnum
}

export const MPostnet: APDescriptor = {
    type: 'POSTNET', id: 'POSTNET', label: 'POSTNET', icon: ICONS.BARCODE_POSTNET_ICON,
    layouts: [...MBarcode4J.layouts, FBarcode4J.verticalQuietZone, FBarcode4J.textPosition, FPostnet.shortBarHeight, FPostnet.intercharGapWidth, FPostnet.displayChecksum, FPostnet.checksumMode, FPostnet.baselinePosition],
    default: {
        type: 'BARCODE', BARCODE_TYPE: 'POSTNET', BARCODE_XMLNS_NAMESPACE: 'c',
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:c': "http://jasperreports.sourceforge.net/jasperreports/components",
        codeExpression: '"123456789"'
    }
};
