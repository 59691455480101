/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { nameValidator } from '@jss/js-common/src/utils/validators';
import { MIME_TYPES } from '@jss/js-rest-api';
import * as React from 'react';
import format from 'xml-formatter';
import { getDataAdapterNode } from '../../editor/Utils';
import { createNode, getProlog, IDataAdapterConfig, IDataAdapterDescriptor } from '../IDataAdapterDescriptor';
import { MongoDBDataAdapterEditor } from './MongoDBDataAdapterEditor';


export default class MongoDBDataAdapterDescriptor implements IDataAdapterDescriptor {

  public getMime = () => {
    return MIME_TYPES.DATA_ADAPTER_MONGODB;
  }

  public getIcon = () => {
    return <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 37 81"
      fill="#fff"
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <use xlinkHref="#mongodbIconA" x={0.5} y={0.5} />
      <symbol id="mongodbIconA" overflow="visible">
        <g stroke="none" fillRule="nonzero">
          <path
            d="m17.716.218 2.135 4.01a11.98 11.98 0 0 0 1.612 2.005c1.787 1.787 3.485 3.66 5.01 5.665 3.617 4.75 6.057 9.999 7.799 15.729 1.045 3.485 1.613 7.059 1.655 10.674.175 10.807-3.53 20.086-10.999 27.798a31.74 31.74 0 0 1-3.925 3.355c-.74 0-1.09-.567-1.395-1.09-.568-.957-.915-2.05-1.09-3.137-.262-1.307-.435-2.615-.35-3.965v-.61c-.06-.155-.712-60.15-.453-60.433z"
            fill="#599636"
          />
          <path
            d="M17.716.086c-.087-.175-.175-.042-.262.042.042.875-.262 1.655-.74 2.4-.5.74-1.22 1.307-1.917 1.917-3.875 3.355-6.925 7.408-9.367 11.94C2.18 22.484.505 29.021.03 35.903c-.217 2.482.785 11.242 1.567 13.769 2.135 6.709 5.97 12.332 10.937 17.211a43.78 43.78 0 0 0 3.875 3.312c.392 0 .435-.35.5-.61a11.96 11.96 0 0 0 .392-1.7l.875-6.535-.46-61.265z"
            fill="#6cac48"
          />
          <path
            d="M19.851 72.108c.087-1 .567-1.83 1.09-2.657-.5-.217-.915-.65-1.22-1.132-.262-.435-.48-.957-.65-1.437-.61-1.83-.74-3.75-.915-5.62V60.13c-.217.175-.262 1.655-.262 1.875a43.301 43.301 0 0 1-.785 5.882c-.13.785-.217 1.567-.7 2.265 0 .087 0 .175.042.305.785 2.31 1 4.662 1.132 7.059v.875c0 1.045-.042.825.825 1.175.35.13.74.175 1.09.435.262 0 .305-.217.305-.392l-.13-1.437v-4.01c-.042-.7.087-1.395.175-2.05z"
            fill="#c2bfbf"
          />
        </g>
      </symbol>
    </svg>
  };

  /**
   *  Return the name of this data adapter to be presented to the user
   *  i.e. CSV File
   */
  public getDataAdapterLabel = () => {
    return 'datasource.mongoDB.descriptor.label';
  }

  /**
  *  Return the name of this data adapter to be presented to the user
  *  i.e. CSV File
  */
  public getDataAdapterDescription = () => {
    return 'datasource.mongoDB.description';
  }

  /**
   *  Return the canonical name of the class which impements this data adapter in Java.
   *  net.sf.jasperreports.data.csv.CsvDataAdapter
   */
  public getDataAdapterClass = () => {
    return 'com.jaspersoft.mongodb.adapter.MongoDbDataAdapterImpl'
  }

  public getDataAdapterRootName = () => {
    return 'MongoDbDataAdapter';
  }

  /**
   * Return a plain json object with the default data adapter configuration.
   * It can be just an empty object.
   *
   * @memberof IDataAdapter
   */
  public initializeDataAdapterConfig = () => {
    return {
      class: this.getDataAdapterClass(),
      name: 'New MongoDB Connection Data Adapter',
      mongoURI: 'mongodb://HOST:27017/DB_NAME',
      username: '',
      password: '',
    }
  };

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public validateDataAdapterConfig = (config: IDataAdapterConfig) => {

    // Check for errors...
    const errors = [];

    const nameError = nameValidator(config.name);
    if (nameError) {
      errors.push(nameError);
    }

    if (errors.length > 0) {
      return errors.join("\n");
    }

    return undefined;

  }

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public createEditor = (config: IDataAdapterConfig, onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => {
    return <MongoDBDataAdapterEditor {...config} onDataAdapterSettingsChange={onDataAdapterSettingsChange} readOnly={readOnly}/>
  }

  public getLanguages = () => {
    return ['text'];
  }

  public toXml = (config: IDataAdapterConfig) => {
    const xmlDoc = document.implementation.createDocument(null, this.getDataAdapterRootName());
    const root = xmlDoc.getElementsByTagName(this.getDataAdapterRootName())[0];
    root.setAttribute('class', this.getDataAdapterClass());

    const nameNode = createNode(xmlDoc, config, 'name', 'name');
    if (nameNode) {
      root.appendChild(nameNode);
    }

    const mongoURINode = createNode(xmlDoc, config, 'mongoURI', 'mongoURI');
    if (mongoURINode) {
      root.appendChild(mongoURINode);
    }

    const usernameNode = createNode(xmlDoc, config, 'username', 'username');
    if (usernameNode) {
      root.appendChild(usernameNode);
    }

    const passwordNode = createNode(xmlDoc, config, 'password', 'password');
    if (passwordNode) {
      root.appendChild(passwordNode);
    }

    const prolog = getProlog();
    const xml = prolog + (new XMLSerializer().serializeToString(xmlDoc));
    return format(xml, { indentation: '  ', collapseContent: true });
  }

  public toJson = (xml: Document) => {
    const result: any = {
      class: this.getDataAdapterClass(),
      name: '',
      mongoURI: 'mongodb://HOST:27017/DB_NAME',
      username: '',
      password: '',
    }

    getDataAdapterNode(xml.getRootNode()).childNodes.forEach((childNode) => {
      const nodeName = childNode.nodeName;
      if (nodeName === 'name') {
        result.name = childNode.textContent;
      } else if (nodeName === 'mongoURI') {
        result.mongoURI = childNode.textContent;
      } else if (nodeName === 'username') {
        result.username = childNode.textContent;
      } else if (nodeName === 'password') {
        result.password = childNode.textContent;
      }
    });
    return result;
  }
}