
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import '../../assets/uxpl/css/TitleBar.css';

interface IToolbar {
    tabList?: {
        icon: React.ReactElement,
        label: string,
    }[],
    onTabSwitch?: (tabIndex: number) => void,
    currentTabIndex?: number,
    warningMessage?: string,
    documentName?: string,
    documentType?: string,
}

export class TitleBar extends React.Component<IToolbar> {

    private switchTab = (tabIndex: number) => {
        if (this.props.onTabSwitch) {
            this.props.onTabSwitch(tabIndex);
        }
    }

    public render() {
        if (this.props.tabList) {
            let tabs;
            if (this.props.tabList.length > 1) {
                tabs = this.props.tabList.map((value, index: number) => {
                    const baseClassName = 'jr-mButtonLarge jr-MuiIconButton-root jr-mButtongroup.mui jr-mButton mui';
                    return <div title={value.label} key={value.label} className={index === this.props.currentTabIndex ? baseClassName + ' jr-Mui-selected' : baseClassName} onClick={() => { this.switchTab(index); }} >
                        {value.icon}
                    </div>
                });
            }
            const types: string[] = [];
            if (this.props.documentType === 'report') {
                types.push('jr-jrws-icon-report');
            } else if (this.props.documentType === 'dataset') {
                types.push('jr-jrws-icon-dataset');
            }
            types.push('jr-mDomain-header-title-icon jr');
            const tabOptions = <div className="jrws-titlebar-title">
                <div className="jr-mDomain-header-title jr">
                    <div className={types.join(' ')}>
                        <span className="icon"></span>
                    </div>
                    <div className="jrws-titlebar-title-text">
                        {this.props.documentName}
                    </div>
                    {this.props.warningMessage && <div className="jrws-titlebar-title-text-warning">
                        {this.props.warningMessage}
                    </div>}
                </div>
                <div className="jr-jrws-icon-container">
                    <div style={{ height: '80%', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                        <div className='jrws-buttongroup-container' aria-label="icon button group contained">
                            {tabs}
                        </div>
                    </div>
                </div>
            </div>

            return <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} className="jrws-titlebar">
                {tabOptions}
            </div>
        }
        return null;
    }
}
