/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { FFusionWidgetProps, FFusionWidgets, MFusionWidgetCommon } from "./MFusionWidgetsCommon";
import LedChartIcon from "../../../../../../assets/icons/charts/fusion/led_vertical.svg";
import { PDiv } from "../../../ui/composite/UPDiv";
import { PConnectedTableDetail } from "../../../ui/tables/UPConnectedTableDetail";
import { MHyperlinks } from "../../common/MHyperlinks";
import { MDatasetRun } from "../../dataset/MDatasetRun";
import { PCategories } from "../../../ui/composite/UPCategories";
import { FFusionChart } from "../chart/MFusionChart";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";

export const MFusionVerticalLED: APDescriptor = {
    id: 'VerticalLed', type: 'fusion.chart.VerticalLed', label: 'Vertical LED', icon: LedChartIcon, path: 'widgetProperties',
    layouts: [...MFusionWidgetCommon.layouts,
    new PAccordeon({
        id: 'verticalLEDTicks', label: "Ticks", layouts: [FFusionWidgets.showTickMarks, FFusionWidgets.showTickValues, FFusionWidgets.forceTickValueDecimals, FFusionWidgets.setAdaptiveMin, FFusionWidgets.placeTicksInside, FFusionWidgets.ticksOnRight]
    }),
    new PAccordeon({
        id: 'gauge', label: "Gauge", layouts: [FFusionWidgets.gaugeRoundRadius, FFusionWidgets.gaugeFillColor,
        new PAccordeon({
            id: 'gauge.border', label: "Border", layouts: [FFusionWidgets.showGaugeBorder, FFusionWidgets.gaugeBorderColor, FFusionWidgets.gaugeBorderAlpha, FFusionWidgets.gaugeBorderThickness]
        })
        ]
    }), new PAccordeon({
        id: 'vertical.led', label: "Vertical LED", layouts: [FFusionWidgets.ledGap, FFusionWidgets.ledSize,
        new PAccordeon({
            id: 'gauge.limits', label: "Limits",
            layouts: [FFusionWidgetProps.showLimits, FFusionWidgets.lowerLimit, FFusionWidgets.upperLimit,
            new PAccordeon({
                id: 'gauge.limits.display', label: "Display Limits",
                layouts: [FFusionWidgets.lowerLimitDisplay, FFusionWidgets.upperLimitDisplay]
            })]
        })
        ]
    })],
    default: {
        type: 'FUSION_WIDGET', widgetType: 'VerticalLed',
        widgetProperties: [], valueExpression: "new Integer(20)"
    }
};

export const MFusionVerticalLEDDataset: APDescriptor = {
    id: 'VerticalLed', type: 'fusion.chart.VerticalLed', label: 'Vertical LED', icon: LedChartIcon,
    layouts: [
        new PCategories({
            label: 'Data', layouts: [
                new PDiv({
                    id: 'fusion.data.dataset', path: 'dataset',
                    label: 'Dataset',
                    layouts: [FFusionWidgets.incrementType, FFusionWidgets.incrementWhenExpression, FFusionWidgets.resetType, MDatasetRun]
                }),
                new PDiv({
                    id: 'fusion.data.entities',
                    label: 'Value',
                    layouts: [FFusionWidgets.valueExpression]
                }),
                new PConnectedTableDetail({
                    id: 'colorRanges', label: 'Color Ranges', rowLabel: 'Color Range', rowLabelPaths: ['color', 'labelExpression'], expanded: true, layouts: [new PDiv({
                        id: 'fusion.colorranges',
                        layouts: [FFusionChart.color, FFusionChart.labelExpression, FFusionWidgets.minExpression, FFusionWidgets.maxExpression
                        ]
                    })], default: { color: '#000000' }
                }),
                new PDiv({
                    id: 'fusion.data.valuesets.hyperlinks',
                    label: 'Hyperlink', path: 'hyperlink',
                    layouts: [...MHyperlinks.layouts]
                })]
        })]
};
