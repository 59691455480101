export const it = {
	searchfile: {
		placeholder: "Ricerca",
		help: "Scrivi il nome del file"
	},
	repoview: {
		emptyfolder: {
			text: "Questa cartella è vuota"
		}
	},
	createfolder: {
		text: "Nuova Cartella"
	},
	newresources: {
		menubutton: {
			text: "Nuovo"
		},
		folder: {
			text: "Nuova Cartella"
		},
		report: {
			text: "Nuovo Report"
		},
		da: {
			text: "Nuovo Data Adapter"
		},
		upload: {
			text: "Carica..."
		}
	},
	resourcestable: {
		header: {
			name: "Nome",
			lastmodified: "Ultima modifica",
			type: "Tipo"
		}
	},
	repository: {
		delete: {
			dialog: {
				cancel: "Annulla",
				delitem: "Elimina elemento",
				msg: "Sei sicuro di voler cancellare l'elemento?"
			}
		}
	},
	menuitem: {
		info: {
			text: "Info"
		},
		rename: {
			text: "Rinomina"
		},
		delete: {
			text: "Cancella"
		}
	},
}