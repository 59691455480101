/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const TreeMapChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="752" height="572" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 752 572" {...props}>
    <defs>
       <clipPath id="highcharts-gu0fr49-1">
          <path fill="none" d="M0 0H732V510H0z" />
       </clipPath>
    </defs>
    <rect width="752" height="572" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M10 47H742V557H10z" className="highcharts-plot-background" />
    <path fill="none" d="M10 47H742V557H10z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" d="M10 557h732" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M10 47v510" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-treemap-series highcharts-color-0 highcharts-tracker" clipPath="url(#highcharts-gu0fr49-1)" data-z-index="0.1" transform="translate(10 47)">
          <g className="highcharts-level-group-3" data-z-index="997">
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M597.5 464.5H673.5V509.5H597.5z" className="highcharts-point" />
             <path fill="#434348" stroke="#e6e6e6" d="M281.5 -0.5H509.5V156.5H281.5z" className="highcharts-point" />
             <path fill="#434348" stroke="#e6e6e6" d="M509.5 -0.5H552.5V156.5H509.5z" className="highcharts-point" />
             <path fill="#90ed7d" stroke="#e6e6e6" d="M586.5 365.5H663.5V399.5H586.5z" className="highcharts-point" />
             <path fill="#90ed7d" stroke="#e6e6e6" d="M586.5 304.5H663.5V365.5H586.5z" className="highcharts-point" />
             <path fill="#f7a35c" stroke="#e6e6e6" d="M669.5 -0.5H731.5V156.5H669.5z" className="highcharts-point" />
             <path fill="#f7a35c" stroke="#e6e6e6" d="M552.5 139.5H662.5V156.5H552.5z" className="highcharts-point" />
             <path fill="#f7a35c" stroke="#e6e6e6" d="M662.5 -0.5H669.5V156.5H662.5z" className="highcharts-point" />
             <path fill="#f7a35c" stroke="#e6e6e6" d="M552.5 -0.5H662.5V139.5H552.5z" className="highcharts-point" />
             <path fill="#8085e9" stroke="#e6e6e6" d="M403.5 383.5H503.5V429.5H403.5z" className="highcharts-point" />
             <path fill="#8085e9" stroke="#e6e6e6" d="M503.5 383.5H504.5V429.5H503.5z" className="highcharts-point" />
             <path fill="#8085e9" stroke="#e6e6e6" d="M403.5 304.5H504.5V383.5H403.5z" className="highcharts-point" />
             <path fill="#f15c80" stroke="#e6e6e6" d="M471.5 429.5H504.5V509.5H471.5z" className="highcharts-point" />
             <path fill="#f15c80" stroke="#e6e6e6" d="M403.5 429.5H471.5V509.5H403.5z" className="highcharts-point" />
             <path fill="#e4d354" stroke="#e6e6e6" d="M588.5 399.5H597.5V456.5H588.5z" className="highcharts-point" />
             <path fill="#e4d354" stroke="#e6e6e6" d="M504.5 399.5H588.5V456.5H504.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M281.5 292.5H339.5V356.5H281.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M339.5 279.5H393.5V331.5H339.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M281.5 156.5H339.5V292.5H281.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M339.5 331.5H393.5V356.5H339.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M393.5 213.5H403.5V274.5H393.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M393.5 323.5H403.5V356.5H393.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M339.5 213.5H393.5V279.5H339.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M339.5 156.5H403.5V213.5H339.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M393.5 274.5H403.5V323.5H393.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M230.5 416.5H263.5V470.5H230.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M230.5 470.5H263.5V509.5H230.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M174.5 380.5H230.5V464.5H174.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M-0.5 279.5H174.5V463.5H-0.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M174.5 279.5H232.5V380.5H174.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M232.5 279.5H281.5V380.5H232.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M230.5 380.5H281.5V416.5H230.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M263.5 416.5H281.5V469.5H263.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M-0.5 463.5H174.5V509.5H-0.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M263.5 469.5H281.5V509.5H263.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M174.5 464.5H230.5V509.5H174.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M518.5 156.5H625.5V304.5H518.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M504.5 456.5H554.5V509.5H504.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M554.5 456.5H597.5V499.5H554.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M554.5 499.5H597.5V509.5H554.5z" className="highcharts-point" />
             <path fill="#434348" stroke="#e6e6e6" d="M663.5 304.5H731.5V399.5H663.5z" className="highcharts-point" />
             <path fill="#90ed7d" stroke="#e6e6e6" d="M673.5 464.5H709.5V509.5H673.5z" className="highcharts-point" />
             <path fill="#f7a35c" stroke="#e6e6e6" d="M709.5 464.5H731.5V509.5H709.5z" className="highcharts-point" />
             <path fill="#8085e9" stroke="#e6e6e6" d="M674.5 399.5H731.5V464.5H674.5z" className="highcharts-point" />
             <path fill="#f15c80" stroke="#e6e6e6" d="M648.5 456.5H674.5V464.5H648.5z" className="highcharts-point" />
             <path fill="#f15c80" stroke="#e6e6e6" d="M648.5 399.5H674.5V456.5H648.5z" className="highcharts-point" />
             <path fill="#f15c80" stroke="#e6e6e6" d="M597.5 399.5H648.5V464.5H597.5z" className="highcharts-point" />
             <path fill="#e4d354" stroke="#e6e6e6" d="M281.5 356.5H403.5V435.5H281.5z" className="highcharts-point" />
             <path fill="#e4d354" stroke="#e6e6e6" d="M281.5 435.5H403.5V509.5H281.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M504.5 374.5H586.5V399.5H504.5z" className="highcharts-point" />
             <path fill="#2b908f" stroke="#e6e6e6" d="M504.5 304.5H586.5V374.5H504.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M403.5 277.5H504.5V304.5H403.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M504.5 156.5H518.5V304.5H504.5z" className="highcharts-point" />
             <path fill="#f45b5b" stroke="#e6e6e6" d="M403.5 156.5H504.5V277.5H403.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M180.5 203.5H254.5V279.5H180.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M254.5 209.5H281.5V252.5H254.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M-0.5 -0.5H180.5V211.5H-0.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M254.5 252.5H281.5V279.5H254.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M-0.5 211.5H180.5V279.5H-0.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M269.5 -0.5H281.5V92.5H269.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M180.5 -0.5H269.5V69.5H180.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M180.5 69.5H269.5V92.5H180.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M180.5 198.5H253.5V203.5H180.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M180.5 92.5H253.5V198.5H180.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M253.5 92.5H254.5V203.5H253.5z" className="highcharts-point" />
             <path fill="#91e8e1" stroke="#e6e6e6" d="M254.5 92.5H281.5V209.5H254.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M702.5 290.5H731.5V304.5H702.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M625.5 249.5H702.5V304.5H625.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M702.5 156.5H731.5V290.5H702.5z" className="highcharts-point" />
             <path fill="#7cb5ec" stroke="#e6e6e6" d="M625.5 156.5H702.5V249.5H625.5z" className="highcharts-point" />
          </g>
          <g className="highcharts-level-group-2" data-z-index="998">
             <path fill="rgba(124,181,236,0.15)" stroke="#e6e6e6" d="M597.5 464.5H673.5V509.5H597.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(67,67,72,0.15)" stroke="#e6e6e6" d="M281.5 -0.5H552.5V156.5H281.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(144,237,125,0.15)" stroke="#e6e6e6" d="M586.5 304.5H663.5V399.5H586.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(247,163,92,0.15)" stroke="#e6e6e6" d="M669.5 -0.5H731.5V156.5H669.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(247,163,92,0.15)" stroke="#e6e6e6" d="M552.5 -0.5H669.5V156.5H552.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(128,133,233,0.15)" stroke="#e6e6e6" d="M403.5 383.5H504.5V429.5H403.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(128,133,233,0.15)" stroke="#e6e6e6" d="M403.5 304.5H504.5V383.5H403.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(241,92,128,0.15)" stroke="#e6e6e6" d="M403.5 429.5H504.5V509.5H403.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(228,211,84,0.15)" stroke="#e6e6e6" d="M504.5 399.5H597.5V456.5H504.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(43,144,143,0.15)" stroke="#e6e6e6" d="M281.5 156.5H403.5V356.5H281.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(244,91,91,0.15)" stroke="#e6e6e6" d="M-0.5 279.5H281.5V509.5H-0.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" d="M518.5 156.5H625.5V304.5H518.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(124,181,236,0.15)" stroke="#e6e6e6" d="M504.5 456.5H597.5V509.5H504.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(67,67,72,0.15)" stroke="#e6e6e6" d="M663.5 304.5H731.5V399.5H663.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(144,237,125,0.15)" stroke="#e6e6e6" d="M673.5 464.5H709.5V509.5H673.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(247,163,92,0.15)" stroke="#e6e6e6" d="M709.5 464.5H731.5V509.5H709.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(128,133,233,0.15)" stroke="#e6e6e6" d="M674.5 399.5H731.5V464.5H674.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(241,92,128,0.15)" stroke="#e6e6e6" d="M597.5 399.5H674.5V464.5H597.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(228,211,84,0.15)" stroke="#e6e6e6" d="M281.5 356.5H403.5V509.5H281.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(43,144,143,0.15)" stroke="#e6e6e6" d="M504.5 304.5H586.5V399.5H504.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(244,91,91,0.15)" stroke="#e6e6e6" d="M403.5 277.5H504.5V304.5H403.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(244,91,91,0.15)" stroke="#e6e6e6" d="M504.5 156.5H518.5V304.5H504.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(244,91,91,0.15)" stroke="#e6e6e6" d="M403.5 156.5H504.5V277.5H403.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" d="M180.5 203.5H254.5V279.5H180.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" d="M254.5 209.5H281.5V252.5H254.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" d="M-0.5 -0.5H180.5V211.5H-0.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" d="M254.5 252.5H281.5V279.5H254.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" d="M-0.5 211.5H180.5V279.5H-0.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" d="M180.5 -0.5H281.5V92.5H180.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" d="M180.5 92.5H254.5V203.5H180.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" d="M254.5 92.5H281.5V209.5H254.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(124,181,236,0.15)" stroke="#e6e6e6" d="M702.5 290.5H731.5V304.5H702.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(124,181,236,0.15)" stroke="#e6e6e6" d="M625.5 249.5H702.5V304.5H625.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(124,181,236,0.15)" stroke="#e6e6e6" d="M702.5 156.5H731.5V290.5H702.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(124,181,236,0.15)" stroke="#e6e6e6" d="M625.5 156.5H702.5V249.5H625.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
          </g>
          <g className="highcharts-level-group-1" data-z-index="999">
             <path fill="rgba(124,181,236,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M597.5 464.5H673.5V509.5H597.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(67,67,72,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M281.5 -0.5H552.5V156.5H281.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(144,237,125,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M586.5 304.5H663.5V399.5H586.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(128,133,233,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M403.5 304.5H504.5V429.5H403.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(241,92,128,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M403.5 429.5H504.5V509.5H403.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(228,211,84,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M504.5 399.5H597.5V456.5H504.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(43,144,143,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M281.5 156.5H403.5V356.5H281.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(244,91,91,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M-0.5 279.5H281.5V509.5H-0.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M518.5 156.5H625.5V304.5H518.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(124,181,236,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M504.5 456.5H597.5V509.5H504.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(67,67,72,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M663.5 304.5H731.5V399.5H663.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(144,237,125,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M673.5 464.5H709.5V509.5H673.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(247,163,92,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M709.5 464.5H731.5V509.5H709.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(128,133,233,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M674.5 399.5H731.5V464.5H674.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(241,92,128,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M597.5 399.5H674.5V464.5H597.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(228,211,84,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M281.5 356.5H403.5V509.5H281.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(43,144,143,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M504.5 304.5H586.5V399.5H504.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(244,91,91,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M403.5 156.5H518.5V304.5H403.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(145,232,225,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M-0.5 -0.5H281.5V279.5H-0.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(124,181,236,0.15)" stroke="#e6e6e6" strokeWidth="3" d="M625.5 156.5H731.5V304.5H625.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" />
             <path fill="rgba(247,163,92,0.15)" stroke="#E6E6E6" strokeWidth="3" d="M552.5 -0.5H731.5V156.5H552.5z" className="highcharts-point highcharts-internal-node-interactive" cursor="pointer" data-z-index="0" />
          </g>
       </g>
    </g>
    <text x="376" y="24" fill="#333" className="highcharts-title" color="#333" data-z-index="4" fontSize="18" textAnchor="middle">
       <tspan>Freight details of the year</tspan>
    </text>
    <g className="highcharts-data-labels highcharts-series-0 highcharts-treemap-series highcharts-color-0 highcharts-tracker" data-z-index="6">
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(603 476)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Argentina</tspan>
          <tspan x="5" y="16">Argentina</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(391 67)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Austria</tspan>
          <tspan x="5" y="16">Austria</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(597 341)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Belgium</tspan>
          <tspan x="5" y="16">Belgium</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(621 67)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Brazil</tspan>
          <tspan x="5" y="16">Brazil</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(428 356)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Canada</tspan>
          <tspan x="5" y="16">Canada</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(423 458)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Denmark</tspan>
          <tspan x="5" y="16">Denmark</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(525 417)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Finland</tspan>
          <tspan x="5" y="16">Finland</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(319 245)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">France</tspan>
          <tspan x="5" y="16">France</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(110 383)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Germany</tspan>
          <tspan x="5" y="16">Germany</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(547 219)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Ireland</tspan>
          <tspan x="5" y="16">Ireland</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(534 472)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Italy</tspan>
          <tspan x="5" y="16">Italy</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(673 341)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Mexico</tspan>
          <tspan x="5" y="16">Mexico</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(665 476)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Norway</tspan>
          <tspan x="5" y="16">Norway</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(674 421)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Portugal</tspan>
          <tspan x="5" y="16">Portugal</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(616 421)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Spain</tspan>
          <tspan x="5" y="16">Spain</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(316 422)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Sweden</tspan>
          <tspan x="5" y="16">Sweden</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(507 341)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Switzerland</tspan>
          <tspan x="5" y="16">Switzerland</tspan>
       </text>
       <text x="5" y="16" fill="#FFF" color="#FFF" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(448 219)" className="0">
          <tspan x="5" y="16" fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">UK</tspan>
          <tspan x="5" y="16">UK</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(124 128)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">USA</tspan>
          <tspan x="5" y="16">USA</tspan>
       </text>
       <text x="5" y="16" color="#000" data-z-index="1" fontSize="11" fontWeight="bold" transform="translate(10 47) translate(644 219)" className="0">
          <tspan x="5" y="16" fill="#FFF" stroke="#FFF" strokeLinejoin="round" strokeWidth="2" className="highcharts-text-outline">Venezuela</tspan>
          <tspan x="5" y="16">Venezuela</tspan>
       </text>
    </g>
 </svg>
 )};

export default TreeMapChartIcon;