/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { FFusionWidgetProps, FFusionWidgets, MFusionWidgetCommon } from "./MFusionWidgetsCommon";
import FunnelChartIcon from "../../../../../../assets/icons/charts/fusion/funnel.svg";
import { PDiv } from "../../../ui/composite/UPDiv";
import { PConnectedTableDetail } from "../../../ui/tables/UPConnectedTableDetail";
import { MHyperlinks } from "../../common/MHyperlinks";
import { MDatasetRun } from "../../dataset/MDatasetRun";
import { PCategories } from "../../../ui/composite/UPCategories";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { FFusionChart } from "../chart/MFusionChart";
import { FFusionPieChartProps } from "../chart/MFusionPieChart";

export const MFusionFunnel: APDescriptor = {
    id: 'Funnel', type: 'fusion.chart.Funnel', label: 'Funnel', icon: FunnelChartIcon, path: 'widgetProperties',
    layouts: [...MFusionWidgetCommon.layouts,
    FFusionWidgets.isHollow, FFusionWidgets.is2D, FFusionWidgets.isSliced, FFusionWidgets.percentOfPrevious, FFusionWidgets.showLabelsAtCenter,
    new PAccordeon({ id: 'gauge.fill', label: "Fill", layouts: [FFusionWidgetProps.plotFillColor, FFusionWidgetProps.plotFillAlpha] }),
    new PAccordeon({
        id: 'plot.border', label: "Border", layouts: [FFusionWidgets.showPlotBorder, FFusionWidgetProps.plotBorderColor, FFusionWidgetProps.plotBorderAlpha, FFusionWidgetProps.plotBorderThickness]
    }),
    new PAccordeon({ id: 'gauge.smartline', label: "Smart Line", layouts: [FFusionPieChartProps.smartLineColor, FFusionPieChartProps.smartLineAlpha, FFusionPieChartProps.smartLineThickness] }),
    new PAccordeon({
        id: 'funnel', label: "Funnel", layouts: [FFusionWidgets.cylFillColor, FFusionWidgets.cylHeight, FFusionWidgets.cylOriginX, FFusionWidgets.cylOriginY, FFusionWidgets.cylRadius, FFusionWidgets.cylYScale,
        ]
    })],
    default: {
        type: 'FUSION_WIDGET', widgetType: 'Funnel',
        valuesDataset: { dataset: {}, valueSets: [{ valueExpression: "", labelExpression: "Label" }] }
    }
};

export const MFusionFunnelDataset: APDescriptor = {
    id: 'Funnel', type: 'fusion.chart.Funnel', label: 'Funnel', icon: FunnelChartIcon,
    layouts: [
        new PCategories({
            label: 'Data', layouts: [
                new PDiv({
                    id: 'fusion.data.dataset', path: 'valuesDataset.dataset',
                    label: 'Dataset',
                    layouts: [FFusionWidgets.incrementType, FFusionWidgets.incrementWhenExpression, FFusionWidgets.resetType, MDatasetRun]
                }),
                new PConnectedTableDetail({
                    id: 'valueSets', label: 'Value Set', rowLabel: 'Value', rowLabelPaths: ['labelExpression', 'valueExpression'], expanded: true,
                    path: 'valuesDataset',
                    layouts: [new PCategories({
                        label: 'Data', layouts: [
                            new PDiv({
                                id: 'values', label: 'Value',
                                layouts: [
                                    FFusionWidgets.valueExpression, FFusionChart.labelExpression,
                                    new PAccordeon({
                                        id: 'properties', label: "Properties", path: 'properties',
                                        layouts: [FFusionWidgets.showValue, FFusionWidgets.issliced, FFusionWidgets.color, FFusionWidgets.alpha]
                                    }),
                                    new PAccordeon({
                                        id: 'border', label: "Border", path: 'properties',
                                        layouts: [FFusionWidgets.bordercolor, FFusionWidgets.borderalpha]
                                    }),
                                ]
                            }), new PDiv({
                                id: 'fusion.data.valuesets.hyperlinks',
                                label: 'Hyperlink', path: 'hyperlink',
                                layouts: [...MHyperlinks.layouts]
                            })]
                    })], default: { valueExpression: 'new Double(20)' }
                }),
                new PDiv({
                    id: 'fusion.data.valuesets.hyperlinks',
                    label: 'Hyperlink', path: 'hyperlink',
                    layouts: [...MHyperlinks.layouts]
                })]
        })]
};
