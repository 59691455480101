/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { getPath, reg, UiProperty, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, TimedSearchBar, Typography } from "@jss/js-common";
import _ from 'lodash';
import { getIn, List as ImmuttableList } from 'immutable';
import * as ReportActions from '../../../../../actions/reportActions';
import { connect } from 'react-redux';
import { ListItem, ListItemSecondaryAction, ListItemText, List } from '@material-ui/core';
import i18n from '../../../../../i18n';

interface UPTableDetailState {
    search?: string;
    sStylesExpanded: any[];
}

interface IUPTableDetail {
    deleteElement?: (path: string[]) => void;
    addProperty?: (path: (string | number)[], element) => void;
}

const TYPEID = 'tableDetail';
export class PTableDetail extends APDescriptor {
    default?: any;
    seachable?: boolean;
    expanded?: boolean = false;
    rowLabel?: string;
    rowLabelPaths?: string[];
    maxRows?: number;
    public constructor(init: Partial<PTableDetail>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPTableDetail mcontext={mc} />; });

class UPTableDetail extends React.Component<UiPropertyProps & IUPTableDetail, UPTableDetailState> {

    public state: UPTableDetailState = { sStylesExpanded: [] };

    private isExpanded = () => {
        const defaultValue = (this.props.mcontext.descriptor as PTableDetail).expanded === true;
        const isExpanded = this.props.mcontext.rootModel.getIn(['widgetStatus', this.props.mcontext.descriptor.id, 'expanded'], defaultValue) as boolean;
        return isExpanded;        
    }

    private handleExpanded = () => {
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expanded'], !this.isExpanded(), false, true);
    }

    private handleSelectionChange = (newSelection) => {
        const currentPath = this.props.mcontext.elements[0].path;
        const selectedElementId = currentPath[currentPath.length - 1];
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id + selectedElementId, 'rowIndex'], newSelection, false, true);
    }


    private getCurrentSelection = () => {
        const currentPath = this.props.mcontext.elements[0].path;
        const selectedElementId = currentPath[currentPath.length - 1];
        return this.props.mcontext.rootModel.getIn(['widgetStatus', this.props.mcontext.descriptor.id + selectedElementId, 'rowIndex'], []) as (string | number)[];
    }

    render() {
        const d = this.props.mcontext.descriptor as PTableDetail;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p) as ImmuttableList<Map<string, any>> | undefined;
        let addButton = <IconButton color='primary' icon='plus' size='small' onClick={this.addItem} />;
        if (d.maxRows && v && v.size === d.maxRows){
            addButton = null;
        }
        return <div style={{ flex: 1, flexDirection: 'column', overflow: 'auto' }} >
            <Accordion expanded={this.isExpanded()} onChange={this.handleExpanded} style={{ borderBottom: 'solid #dedede 1px', marginBottom: '5px' }}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className="js-jrws-search-accordion-header" >
                    <Typography>{i18n.t(this.props.mcontext.descriptor.label)}</Typography>
                    {this.renderSearch()}
                    {addButton}
                </AccordionSummary>
                <AccordionDetails
                    style={this.props.mcontext.descriptor.style ? this.props.mcontext.descriptor.style : { display: 'block', height: '100px', overflow: 'auto', padding: 0 }}
                    className={this.props.mcontext.descriptor.className}>
                    {this.renderItems(v)}
                </AccordionDetails>
            </Accordion>

            {this.renderDetail()}
        </div>;
    }

    private renderItems = (v: ImmuttableList<Map<string, any>> | undefined) => {
        const d = this.props.mcontext.descriptor as PTableDetail;
        if (!v) {
            return <div style={{ textAlign: 'center', color: 'lightgray' }}>{i18n.t('tableElement.novalues')}</div>;
        }

        let fields: ImmuttableList<Map<string, any>> = v;
        if (this.state.search?.length > 0) {
            fields = fields.filter((field) => {
                const name = field.get('name');
                return name && name.toLowerCase().includes(this.state.search);
            });
        }
        if (fields.size < 1) {
            return <div style={{ textAlign: 'center', color: 'lightgray' }}>{i18n.t('tableElement.novalues')}</div>;
        }
        const selection = this.getCurrentSelection();
        return <List style={{ height: '150px' }}>
            {fields.map((f, index) => {
                if (f) {
                    let itemname = `${index + 1} - ${d.rowLabel ? d.rowLabel : 'item'}`;
                    if (d.rowLabelPaths) {
                        d.rowLabelPaths.some((key) => {
                            const t = key.split('/');
                            const tmp = t.length === 1 ? f.get(key) : getIn(f, t, undefined);
                            if (tmp) {
                                itemname = tmp;
                                return true;
                            }
                            return false;
                        });
                    }
                    const fieldName = itemname;
                    const s = [index];
                    return <ListItem key={'listitem' + d.id + index} button
                            onClick={() => this.handleSelectionChange(s)}
                            selected={_.isEqual(selection, s)}>
                            <ListItemText primary={fieldName} title={'Item ' + fieldName} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} />
                            <ListItemSecondaryAction>
                                {this.renderDelete(s)}
                            </ListItemSecondaryAction>
                    </ListItem>;
                }
            })}
        </ List>;
    }

    private renderDetail = () => {
        const selection = this.getCurrentSelection();
        if (selection && selection.length > 0) {
            const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
            p.push(...selection);

            const els = _.cloneDeep(this.props.mcontext.elements);
            els.forEach(key => { key.path = [...p] });
            return this.props.mcontext.descriptor.layouts?.map((key, index) => {
                const k = key.id ? key.id : 'div.' + this.props.mcontext.descriptor.id + '.' + key.type + '.' + index
                return <UiProperty key={k} mcontext={{ ...this.props.mcontext, descriptor: key, elements: els }} />;
            });
        }
    }

    private deleteElement = (key) => {
        if (this.props.deleteElement) {
            this.handleSelectionChange([]);
            const path = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
            const p = ['model', ...path, ...key];
            this.props.deleteElement(p);
        }
    }

    private renderDelete(key) {
        return <IconButton key={'idb.' + key} icon='delete' onClick={() => this.deleteElement(key)} />;
    }

    private renderSearch() {
        const d = this.props.mcontext.descriptor as PTableDetail;
        if (d.seachable === true)
            return <TimedSearchBar onClick={this.onClick} className="js-jrws-properties-search" placeholder='search ...' id="file-searchbar" onChange={this.seearchTextChange} value={this.state.search} pauseTime={40} />
    }

    private onClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private seearchTextChange = (v: string) => {
        v = v.trim().toLowerCase();
        v = v.length > 0 ? v : undefined;
        this.setState({ search: v });
    }
    private addItem = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expanded'], true, false, true);
        const d = this.props.mcontext.descriptor as PTableDetail;
        const def = d.default ? { ...d.default } : {};
        if (def.name) {
            const pth = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
            const v = this.props.mcontext.model.getIn(pth) as ImmuttableList<Map<string, any>> | undefined;

            if (v) {
                const fields: ImmuttableList<Map<string, any>> = v;
                def.name = this.findName(def.name, def.name, 1, fields);
            }
        }

        const p: (string | number)[] = ['model', ...this.props.mcontext.elements[0].path];
        p.push(d.id);
        this.props.addProperty(p, def);
    }

    private findName = (n: string, prefix: string, id: number, fields: ImmuttableList<Map<string, any>>): string => {
        for (const f of fields) {
            const name = f.get('name');
            if (name === n) {
                return this.findName(prefix + id, prefix, id + 1, fields);
            }
        }
        return n;
    }
}


const mapStateToProps = () => {
    return {};
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteElement: (path: string[]) => { dispatch(ReportActions.deleteElement(path)); },
        addProperty: (path: (string | number)[], element) => { dispatch(ReportActions.addProperty(path, element)); },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UPTableDetail);