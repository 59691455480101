/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map } from 'immutable';
import { ElementTypes } from '../../../../sagas/report/document/elementTypes';
import { addAttributeToMap, addBooleanAttributeToMap, addChildToParent, createDatasetRun, createReportElement, generatePath, getChildNodeByName, getNamespace, IObjectCounter } from './JrxmlHelpers';
import { parseNode } from './JrxmlReader';


export const LIST_NAMESPACE = "LIST_XMLNS_NAMESPACE";

export const isList = (componentElement : Element) : boolean => {
    const childList = componentElement.childNodes;
    let found = false;
    for (let i = 0; i < childList.length && !found; i++) {
        const child = childList[i];
        const nodeName = child.nodeName;
        if (child.nodeType === Node.ELEMENT_NODE && nodeName !== "reportElement"){
            const basePrefix = getNamespace(child as Element);
            const prefix = basePrefix.length>0 ? basePrefix + ":" : basePrefix;
            found = nodeName === prefix + "list";
        }
    }
    return found;
}

export const createListElement = (componentElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    const reportElementNode : Element = getChildNodeByName(componentElement, "reportElement") as Element;
    let list : Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.LIST, objectCounter);
    list = list.set("elementIds", List<string>());
    let newDocument = document;
    componentElement.childNodes.forEach(child => {
        if (child.nodeType === Node.ELEMENT_NODE && child.nodeName !== "reportElement"){
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : "";
            const listElement = getChildNodeByName(componentElement, namespace + "list") as Element;
            if (listElement !== null){
                list = list.set(LIST_NAMESPACE, baseNamespace);
                list = addAttributeToMap(listElement, baseNamespace.length > 0 ? "xmlns:" + baseNamespace : "xmlns", list);
                list = addAttributeToMap(listElement, "xsi:schemaLocation", list);
                list = addAttributeToMap(listElement, "printOrder", list);
                list = addBooleanAttributeToMap(listElement, "ignoreWidth", list);
            
                const listContentsElement = getChildNodeByName(listElement, namespace + "listContents") as Element;
            
                const width = listContentsElement.getAttribute("width");
                if (width !== null){
                    list = list.set("listWidth", parseInt(width, 10));
                } 
            
                const height = listContentsElement.getAttribute("height");
                if (height !== null){
                    list = list.set("listHeight", parseInt(height, 10));
                } 
            
                // check for the dataset run
                const datasetRunNode = getChildNodeByName(listElement, "datasetRun");
                if (datasetRunNode !== null){
                    list = list.set("datasetRun", createDatasetRun(datasetRunNode as Element, objectCounter));
                }
            
                newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), newDocument, list.get("id"));
                newDocument = newDocument.setIn(["elements", list.get("id")], list);
                listContentsElement.childNodes.forEach(element => {
                    newDocument = parseNode(element, listContentsElement, list, newDocument, objectCounter);
                });
            }
        }
    });
  
    return newDocument;
}