/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../ui/APDescriptor";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PString } from "../../ui/primitive/UPString";
import { BucketEnum } from "../enums/BucketEnum";
import { CrosstabTotalPositionEnum } from "../enums/CrosstabTotalPositionEnum";


export const FCrosstabGroup = {
    name: new PString({ id: 'name', label: 'Name' }),
    mergeHeaderCells: new PBoolean({ id: 'mergeHeaderCells', label: 'Merge Header Cells' }),
    totalPosition: CrosstabTotalPositionEnum,

    order: BucketEnum,
    orderByExpression: new PExpression({ id: 'orderByExpression', label: 'Order By Expression' }),
    comparatorExpression: new PExpression({ id: 'comparatorExpression', label: 'Comparator Expression' }),
    bucketExpression: new PExpression({ id: 'bucketExpression', label: 'Expression' }),
    class: new PString({ id: 'class', label: 'Value Class Name' })
};

export const MCrosstabGroup: APDescriptor = {
    type: 'MCrosstabGroup',
    id: 'crosstabGroupProeprtiesDescriptor',
    layouts: [FCrosstabGroup.name, FCrosstabGroup.mergeHeaderCells, FCrosstabGroup.totalPosition,
    new PAccordeon({
        id: 'bucket', label: 'Bucket', path: 'bucket', expanded: true,
        layouts: [FCrosstabGroup.order, FCrosstabGroup.orderByExpression, FCrosstabGroup.comparatorExpression, FCrosstabGroup.bucketExpression, FCrosstabGroup.class]
    })
    ]
};
