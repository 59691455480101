/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IRepositoryItemDescriptor, RESOURCE_TYPE } from "../repo/IRepositoryItemDescriptor";
import { ResourceFieldREST } from "./ResourceFieldREST";

export class ResourceFieldS3 extends ResourceFieldREST {

    public getContainerLenght() {
        return 1;
    }

    public isEditable(res: IRepositoryItemDescriptor, name: string) {
        switch (name) {
            case 'name': return res.type !== RESOURCE_TYPE.CONTAINER && super.isEditable(res, name);
        }
        return super.isEditable(res, name);
    }
    public isAvailable(res: IRepositoryItemDescriptor, name: string) {
        switch (name) {
            case 'description': return false;
            case 'lastModified': return res.type !== RESOURCE_TYPE.CONTAINER;
            case 'created': return res.type === RESOURCE_TYPE.CONTAINER;
        }
        return super.isAvailable(res, name);
    }
    public isAvailable4Children(res: IRepositoryItemDescriptor, name: string) {
        switch (name) {
            case 'lastModified': return res.name !== '' && ([RESOURCE_TYPE.CONTAINER, RESOURCE_TYPE.FOLDER].includes(res.type));
            case 'created': return res.name === '';
        }
        return this.isAvailable(res, name);
    }
}