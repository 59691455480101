/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg } from '../../ui/UiProperty';
import UPValidatedPath, { PValidatedPath } from './UPValidatedPath';

const TYPEID = 'connectedValidatedPath';
export class PConnectedValidatedPath extends PValidatedPath {
    
    public constructor(init: Partial<PValidatedPath>) {
        super(init);
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPValidatedPath mcontext={mc} />; });