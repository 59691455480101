/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { TextInput, TIMEZONES, WritableCombo } from '@jss/js-common';
import { advancedNameValidator, notEmptyClassNameValidator } from '@jss/js-common/src/utils/validators';
import * as React from 'react';
import '../../assets/css/DataAdapterTypePicker.css';
import i18n from '../../i18n';
import { driversList, jdbcDrivers, jdbcUrlValidator } from '../DBConfigurationPanel';

interface IJDBCDataAdapterEditor {
    name?: string,
    driverClass?: string,
    selectedDriverId?: string,
    connectionUrl?: string,
    password?: string,
    username?: string,
    timezone?: string,
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

export class JrsJDBCDataAdapterEditor extends React.Component<IJDBCDataAdapterEditor> {

    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }

    private onJDBCUrlChange = (str: string) => {
        this.notifyChange({ connectionUrl: str });
    }

    private onUsernameChange = (str: string) => {
        this.notifyChange({ username: str });
    }

    private onPasswordChange = (str: string) => {
        this.notifyChange({ password: str });
    }

    private onDriverChange = (key: string | undefined, str: string) => {
        if (key) {
            // Find the selected driver...
            const driverDefinition = jdbcDrivers.find((d: any) => d !== null && d.id === key);

            if (!driverDefinition) {
                return;
            }
            this.notifyChange({ driverClass: driverDefinition.driver, selectedDriverId: driverDefinition.id, url: driverDefinition.defaultUrl });
        } else {
            this.notifyChange({ driverClass: str, selectedDriverId: undefined });
        }
    }

    private onTimezoneChange = (selectedKey: string | undefined, value: string) => {
        this.notifyChange({ timezone: selectedKey ? selectedKey : value });
    }

    public render() {
        return <div className="tc-jsw-dataadapter-editor">
            <div className="tc-jsw-form-wrapper" style={{ flex: 1, alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}>
                <TextInput label={i18n.t('datasource.jdbc.name')}
                    help={i18n.t('datasource.jdbc.name.help')}
                    id="data-adapter-name"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />
                    
                <WritableCombo items={driversList}
                    label={i18n.t('datasource.jdbc.driver')}
                    id="jdbc-driver"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.selectedDriverId ? this.props.selectedDriverId : this.props.driverClass}
                    validator={notEmptyClassNameValidator}
                    onComboChange={this.onDriverChange} 
                    disabled={this.props.readOnly}/>

                <TextInput label={i18n.t('datasource.jdbc.url')}
                    help={i18n.t('datasource.jdbc.urlHelp')}
                    id="jdbc-url-input"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.connectionUrl}
                    onChange={this.onJDBCUrlChange}
                    validator={jdbcUrlValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.jdbc.username')}
                    id="username-input"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.username}
                    autoComplete="off"
                    onChange={this.onUsernameChange}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.jdbc.password')}
                    id="password-input"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    type="password"
                    autoComplete="off"
                    value={this.props.password}
                    onChange={this.onPasswordChange}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <WritableCombo label={i18n.t('datasource.excel.timezone')}
                    id="excel-timezone"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.timezone}
                    items={TIMEZONES.map((timezone) => { return { key: timezone.id, value: `${timezone.id} (${timezone.label})` } })}
                    onComboChange={this.onTimezoneChange}
                    disabled={this.props.readOnly} />
            </div>
        </div>;
    }
}