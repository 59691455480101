/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ICONS } from '@jss/js-common';
import * as React from 'react';
import { ElementTypes } from '../../../sagas/report/document/elementTypes';
import { MMap } from '../properties/types/map/MMap';
import { MTibcoMap } from '../properties/types/maptibco/MTibcoMap';

interface IMapElement {
  id: string;
  element?: any;
  zoom?: number;
  type: string,
  availableWidth: number,
  availableHeight: number,
}


const MapElement = (props: IMapElement) => {

    // The actual content of the frame is rendered at band level.
    // Essentially a frame is just a logical component with nothing to do..
    let icon;
    if (props.type === ElementTypes.FUSION_MAP) {
      icon = ICONS.MAP_PRO;
    } else if (props.type === ElementTypes.TIBCO_MAP) {
      icon = MTibcoMap.icon;
    } else {
      icon = MMap.icon;
    }
    return <div style={{ width: '100%', height: '100%'}}>
      <div style={{paddingTop: 5, paddingLeft: 5}}>
        {icon}
      </div>
    </div>;
}

export default MapElement;