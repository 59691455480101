/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import Immutable, { List, Map, OrderedMap } from 'immutable';
// import { MBarcode } from '../../../components/report/properties/types/barcode/MBarcode';
// import { MBreak } from '../../../components/report/properties/types/elements/MBreak';
// import { MCrosstab } from '../../../components/report/properties/types/crosstab/MCrosstab';
// import { MCVC } from '../../../components/report/properties/types/cvc/MCVC';
// import { MEllipse } from '../../../components/report/properties/types/elements/MEllipse';
import { MFrame } from '../../../components/report/properties/types/elements/MFrame';
// import { MGenericElement } from '../../../components/report/properties/types/elements/MGenericElement';
// import { MHighCharts } from '../../../components/report/properties/types/highchart/MHighChart';
// import { MIMage } from '../../../components/report/properties/types/elements/MImage';
// import { MJFreeChart } from '../../../components/report/properties/types/chart/MJFreeChart';
// import { MLine } from '../../../components/report/properties/types/elements/MLine';
// import { MList } from '../../../components/report/properties/types/elements/MList';
// import { MMap } from '../../../components/report/properties/types/map/MMap';
// import { MRectangle } from '../../../components/report/properties/types/elements/MRectangle';
// import { MStaticText } from '../../../components/report/properties/types/elements/MStaticText';
// import { MSubreport } from '../../../components/report/properties/types/elements/MSubreport';
// import { MTable } from '../../../components/report/properties/types/table/MTable';
// import { MTextField } from '../../../components/report/properties/types/elements/MTextField';
// import { MTibcoMap } from '../../../components/report/properties/types/maptibco/MTibcoMap';
import { IPoint } from '../../../types/geometry';

import { BandTypes, ElementTypes } from './elementTypes';
import jsToJrxml from '../../../components/common/JrxmlModel/writer/JrxmlWriter';
// import { MFusionChart } from '../../../components/report/properties/types/fusion/chart/MFusionChart';
// import { MFusionMap } from '../../../components/report/properties/types/fusion/map/MFusionMap';
// import { MFusionWidgets } from '../../../components/report/properties/types/fusion/widgets/MFusionWidgets';
import { getModel } from '../../../components/report/properties/types/TypeFactory';
import { uuidv4 } from '../../../utils/uuidGenerator';


export const initializeMapWithObject = (object: Record<string, unknown>): Map<string, any> => {
    return Map<string, any>(Object.keys(object).map<[string, any]>(key => [key, object[key]]));
}

export const getDatasetReport = (dataset: Map<string, any>) => {
    let defaultReport = createDefaultDocument();
    const currentQuery = dataset.get('queryString');
    if (currentQuery) {
        defaultReport = defaultReport.set('queryString', currentQuery);
    }
    if (dataset.has('queryLanguage')) {
        defaultReport = defaultReport.set('queryLanguage', dataset.get('queryLanguage'));
    }
    if (dataset.has('fields')){
        defaultReport = defaultReport.set('fields', dataset.get('fields'));
    }
    if (dataset.has('sortFields')){
        defaultReport = defaultReport.set('sortFields', dataset.get('sortFields'));
    }
    if (dataset.has('scriptlets')){
        defaultReport = defaultReport.set('scriptlets', dataset.get('scriptlets'));
    }
    if (dataset.has('parameters')){
        defaultReport = defaultReport.set('parameters', dataset.get('parameters'));
    }
    if (dataset.has('filterExpression')){
        defaultReport = defaultReport.set('filterExpression', dataset.get('filterExpression'));
    }
    if (dataset.has('properties')){
        defaultReport = defaultReport.set('properties', dataset.get('properties'));

    }
    //defaultReport = defaultReport.set('variables', dataset.get('variables'));
    //defaultReport = defaultReport.set('groups', dataset.get('groups'));

    const jrxml = jsToJrxml(defaultReport);
    return jrxml;
}

export const createDefaultDocument = () => {
    let report = createEmptyReport(595, 842, 'Blank_A4').set("bands", Map(initializeMapWithObject({
        title: createBand('title', BandTypes.BAND_TITLE, 200),
        pageHeader: createBand('pageHeader', BandTypes.BAND_PAGE_HEADER, 100),
        detail_0: createBand('detail_0', BandTypes.BAND_DETAIL, 442),
        pageFooter: createBand('pageFooter', BandTypes.BAND_PAGE_FOOTER, 100),
    })));
    const groupHeadersOrder = List<string>();
    const groupFootersOrder = List<string>();
    const detailsOrder = List<string>(['detail_0']);
    report = report.set('groupHeadersOrder', groupHeadersOrder);
    report = report.set('groupFootersOrder', groupFootersOrder);
    report = report.set('detailsOrder', detailsOrder);
    return report;
}

export const getOrderedBands = (model: Map<string, any>) => {
    const bands: Map<string, Map<string, any>> = model.get('bands');
    const groupHeadersOrder: List<string> = model.get('groupHeadersOrder', List<string>());
    const detailsOrder: List<string> = model.get('detailsOrder', List<string>());
    const groupFootersOrder: List<string> = model.get('groupFootersOrder', List<string>());
    const orderedBands = [];
    if (bands.has('title')) {
        orderedBands.push('title');
    }
    if (bands.has('pageHeader')) {
        orderedBands.push('pageHeader');
    }
    if (bands.has('columnHeader')) {
        orderedBands.push('columnHeader');
    }
    groupHeadersOrder.forEach((bandName: string) => {
        orderedBands.push(bandName);
    });
    detailsOrder.forEach((bandName: string) => {
        orderedBands.push(bandName);
    });
    groupFootersOrder.forEach((bandName: string) => {
        orderedBands.push(bandName);
    });
    if (bands.has('columnFooter')) {
        orderedBands.push('columnFooter');
    }
    if (bands.has('pageFooter')) {
        orderedBands.push('pageFooter');
    }
    if (bands.has('lastPageFooter')) {
        orderedBands.push('lastPageFooter');
    }
    if (bands.has('summary')) {
        orderedBands.push('summary');
    }
    if (bands.has('noData')) {
        orderedBands.push('noData');
    }
    if (bands.has('background')) {
        orderedBands.push('background');
    }
    return List<string>(orderedBands);
}

export const createEmptyReport = (widthValue: number, heightValue: number, nameValue: string) => {

    // Create an empty document model
    return Map({
        width: widthValue,
        // height: 842,
        docWidth: widthValue,
        docHeight: heightValue,
        name: nameValue,
        properties: OrderedMap<string, string | null>(),
        propertiesExpression: OrderedMap<string, { value: string, evaluationTime: string | null }>(),
        elements: OrderedMap<string, Map<string, any>>(),
        bookSections: Map<string, any>(),
        bands: Map<string, any>(),
        groupHeadersOrder: List<string>(),
        groupFootersOrder: List<string>(),
        detailsOrder: List<string>(),
        templateStyles: List<string>(),
        styles: List<Map<string, any>>(),
        scriptlets: List<Map<string, any>>(),
        parameters: List<Map<string, any>>(),
        fields: List<Map<string, any>>(),
        sortFields: List<Map<string, any>>(),
        variables: List<Map<string, any>>(),
        groups: List<Map<string, any>>(),
        imports: List<string>(),
        reportFonts: List<Map<string, any>>(),
        subdatasets: List(),
        lastUniqueId: 0,
        uuid: uuidv4(),
    });
}

export const createBand = (bandName: string, bandType: string, height?: number): Map<string, any> => {
    let band: Map<string, any> = Map<string, any>(initializeMapWithObject({
        type: ElementTypes.BAND,
        bandType,
        id: bandName,
        properties: OrderedMap<string, string | null>(),
        returnValues: List<Map<string, string>>(),
        elementIds: List<string>()
    }));
    if (height) {
        band = band.set("height", height);
    }
    return band;
}

/**
 * Creates a simple generic element
 *
 * @return Immutable.Map
 */
const createGenericElement = (uniqueId?: number) => {

    return Map<string, any>(initializeMapWithObject({
        id: 'ele-' + (uniqueId !== undefined ? uniqueId.toString() : ''),
        path: '', // The path tells us who is the parent object of this element, i.e. bands/title
        width: 0,
        height: 0,
        selected: false,
        highlighted: false,
        uuid: uuidv4(),
    }));
}

const init = (basicElement: Map<string, any>, defaults: any, dw = 100, dh = 30) => {
    if (!defaults.width) defaults.width = dw;
    if (!defaults.height) defaults.height = dh;
    Object.keys(defaults).forEach(key => {
        let obj = defaults[key];
        obj =  Immutable.fromJS(obj);
        basicElement = basicElement.set(key, obj);
    });
    return basicElement;
}

export const createReportElement = (type: string, position: IPoint, uniqueId?: number) => {
    let basicElement = createGenericElement(uniqueId).set('type', type);
    let defaults = getModel(type)?.default;
    switch (type) {
        case ElementTypes.FRAME:
            defaults = MFrame.default;
            defaults.elementIds = List<string>([]);
            break;
    }
    if (!defaults)
        defaults = { width: 100, height: 30 };
    basicElement = init(basicElement, defaults);

    if (!basicElement.has('x')) {
        basicElement = basicElement.set('x', position.x)
    }

    if (!basicElement.has('y')) {
        basicElement = basicElement.set('y', position.y)
    }

    return basicElement;
}
