/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const MultiLevelGaugeIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="802" height="493" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 802 493" {...props}>
      <defs>
        <clipPath id="highcharts-b51kkja-7">
          <path fill="none" d="M0 0H782V431H0z"></path>
        </clipPath>
      </defs>
      <rect
        width="802"
        height="493"
        fill="#fff"
        className="highcharts-background"
        rx="0"
        ry="0"
      ></rect>
      <path
        fill="none"
        d="M10 47H792V478H10z"
        className="highcharts-plot-background"
      ></path>
      <g className="highcharts-pane-group" data-z-index="0">
        <path
          fill="rgba(124,181,236,0.3)"
          d="M401 79.325a183.175 183.175 0 11-.183 0m.027 27.476a155.699 155.699 0 10.156 0"
          className="highcharts-pane"
        ></path>
        <path
          fill="rgba(67,67,72,0.3)"
          d="M401 106.801a155.699 155.699 0 11-.156 0m.028 27.477a128.222 128.222 0 10.128 0"
          className="highcharts-pane"
        ></path>
        <path
          fill="rgba(144,237,125,0.3)"
          d="M401 134.278a128.222 128.222 0 11-.128 0m.027 27.476a100.746 100.746 0 10.101 0"
          className="highcharts-pane"
        ></path>
      </g>
      <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
        <path
          fill="none"
          d="M401 262.5V79.325M401 262.5V79.325"
          className="highcharts-grid-line"
          data-z-index="1"
        ></path>
      </g>
      <path
        fill="none"
        d="M10 47H792V478H10z"
        className="highcharts-plot-border"
        data-z-index="1"
      ></path>
      <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
        <path
          fill="none"
          d="M401 79.325a183.175 183.175 0 11-.183 0M401 262.5"
          className="highcharts-axis-line"
          data-z-index="7"
        ></path>
      </g>
      <g className="highcharts-series-group" data-z-index="3">
        <g
          className="highcharts-series highcharts-series-0 highcharts-solidgauge-series highcharts-tracker"
          clipPath="url(#highcharts-b51kkja-7)"
          data-z-index="0.1"
          transform="translate(10 47)"
        >
          <path
            fill="#7cb5ec"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M391 32.325A183.175 183.175 0 01517.928 83.43a13.738 13.738 0 11-19.04 19.81A155.699 155.699 0 00391 59.802a13.738 13.738 0 110-27.476z"
            className="highcharts-point highcharts-color-0"
          ></path>
        </g>
        <g
          className="highcharts-series highcharts-series-1 highcharts-solidgauge-series highcharts-tracker"
          clipPath="url(#highcharts-b51kkja-7)"
          data-z-index="0.1"
          transform="translate(10 47)"
        >
          <path
            fill="#434348"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M391 59.801a155.699 155.699 0 01101.282 37.445 13.738 13.738 0 11-17.873 20.868A128.222 128.222 0 00391 87.277a13.738 13.738 0 110-27.476z"
            className="highcharts-point highcharts-color-0"
          ></path>
        </g>
        <g
          className="highcharts-series highcharts-series-2 highcharts-solidgauge-series highcharts-tracker"
          clipPath="url(#highcharts-b51kkja-7)"
          data-z-index="0.1"
          transform="translate(10 47)"
        >
          <path
            fill="#90ed7d"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M391 87.278a128.222 128.222 0 11-112.132 66.034 13.738 13.738 0 1124.028 13.326A100.746 100.746 0 10391 114.754a13.738 13.738 0 110-27.477z"
            className="highcharts-point highcharts-color-0"
          ></path>
        </g>
      </g>
      <text
        x="401"
        y="24"
        fill="#333"
        className="highcharts-title"
        color="#333"
        data-z-index="4"
        fontSize="18"
        textAnchor="middle"
      >
        <tspan>Multi Level Gauge</tspan>
      </text>
      <text
        x="792"
        y="488"
        fill="#999"
        className="highcharts-credits"
        color="#999"
        cursor="pointer"
        data-z-index="8"
        fontSize="9"
        textAnchor="end"
      >
        Highcharts.com
      </text>
    </svg>
)};

export default MultiLevelGaugeIcon;