/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const ColumnChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="802"
      height="493"
      version="1.1"
      style={{
         WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
         WebkitUserSelect: "none",
         MozUserSelect: "none",
         userSelect: "none",
         position: "relative",
      }}
      cursor="default"
      overflow="hidden"
      viewBox="0 0 802 493"
      {...props}
   >
      <defs>
         <linearGradient
            id="19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100"
            x1="0"
            x2="0"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#cbcbcb" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#e9e9e9" stopOpacity="0.5"></stop>
         </linearGradient>
         <clipPath id="67F6D01A-5C7A-4645-BC5E-0EB33C335937">
            <path d="M55 55H784V444H55z"></path>
         </clipPath>
         <linearGradient
            id="19-270-rgba_175_216_248_1_-rgba_255_255_255_1_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#afd8f8"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-270-rgba_246_189_15_1_-rgba_255_255_255_1_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#f6bd0f"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-270-rgba_139_186_0_1_-rgba_255_255_255_1_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#8bba00"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-270-rgba_255_142_70_1_-rgba_255_255_255_1_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#ff8e46"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-270-rgba_0_142_142_1_-rgba_255_255_255_1_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#008e8e"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-270-rgba_214_70_70_1_-rgba_255_255_255_1_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#d64646"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-270-rgba_142_70_142_1_-rgba_255_255_255_1_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#8e468e"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-270-rgba_88_133_38_1_-rgba_255_255_255_1_"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
         >
            <stop offset="0%" stopColor="#588526"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
      </defs>
      <g className="raphael-group-20-background">
         <rect
            width="800"
            height="491"
            x="1"
            y="1"
            fill="url('#19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100')"
            rx="0"
            ry="0"
         ></rect>
         <rect
            width="801"
            height="492"
            x="0.5"
            y="0.5"
            fill="none"
            stroke="#767575"
            strokeOpacity="0.5"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-27-canvas">
         <rect
            width="731"
            height="391"
            x="54"
            y="54"
            fill="none"
            stroke="#545454"
            strokeWidth="2"
            rx="0"
            ry="0"
         ></rect>
         <rect
            width="729"
            height="389"
            x="55"
            y="55"
            fill="#FFF"
            strokeWidth="0"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-30-axisbottom">
         <g
            fill="#EEE"
            fillOpacity="0.5"
            stroke="#000"
            strokeWidth="0"
            className="raphael-group-39-y-axis-bands"
         >
            <rect width="729" height="77.8" x="55" y="288.4" rx="0" ry="0"></rect>
            <rect width="729" height="77.8" x="55" y="132.8" rx="0" ry="0"></rect>
         </g>
         <g
            fill="none"
            stroke="#717170"
            strokeOpacity="0.4"
            className="raphael-group-41-y-axis-lines"
            shapeRendering="crispedges"
         >
            <path d="M55 366.5h729M55 288.5h729M55 211.5h729M55 133.5h729"></path>
         </g>
         <g className="fusioncharts-xaxis-0-gridlabels">
            <text
               x="100.563"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="100.563" dy="-2">
                  AK
               </tspan>
            </text>
            <text
               x="191.688"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="191.688" dy="-2">
                  CA
               </tspan>
            </text>
            <text
               x="282.813"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="282.813" dy="-2">
                  ID
               </tspan>
            </text>
            <text
               x="373.938"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="373.938" dy="-2">
                  MT
               </tspan>
            </text>
            <text
               x="465.063"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="465.063" dy="-2">
                  NM
               </tspan>
            </text>
            <text
               x="556.188"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="556.188" dy="-2">
                  OR
               </tspan>
            </text>
            <text
               x="647.313"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="647.313" dy="-2">
                  WA
               </tspan>
            </text>
            <text
               x="738.438"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="738.438" dy="-2">
                  WY
               </tspan>
            </text>
         </g>
         <g className="fusioncharts-yaxis-0-gridlabels">
            <text
               x="49"
               y="444"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="end"
            >
               <tspan x="49" dy="4">
                  0
               </tspan>
            </text>
            <text
               x="49"
               y="366.2"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="end"
            >
               <tspan x="49" dy="4.005">
                  8
               </tspan>
            </text>
            <text
               x="49"
               y="288.4"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="end"
            >
               <tspan x="49" dy="4.002">
                  16
               </tspan>
            </text>
            <text
               x="49"
               y="210.6"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="end"
            >
               <tspan x="49" dy="3.998">
                  24
               </tspan>
            </text>
            <text
               x="49"
               y="132.8"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="end"
            >
               <tspan x="49" dy="3.995">
                  32
               </tspan>
            </text>
            <text
               x="49"
               y="55"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="end"
            >
               <tspan x="49" dy="4">
                  40
               </tspan>
            </text>
         </g>
         <g className="raphael-group-50-axis-name">
            <text
               fill="#555"
               className="fusioncharts-yaxis-0-title"
               fontSize="10"
               textAnchor="middle"
               transform="rotate(-90 133.75 115.75)"
            >
               <tspan x="0" dy="10">
                  Orders
               </tspan>
            </text>
            <text
               fill="#555"
               className="fusioncharts-xaxis-0-title"
               fontSize="10"
               textAnchor="middle"
               transform="translate(419.5 465)"
            >
               <tspan x="0" dy="10">
                  States
               </tspan>
            </text>
         </g>
      </g>
      <g className="raphael-group-21-dataset">
         <g
            className="raphael-group-74-columns"
            clipPath="url('#67F6D01A-5C7A-4645-BC5E-0EB33C335937')"
         >
            <rect
               width="50"
               height="98"
               x="75.5"
               y="346.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="98"
               x="75.5"
               y="346.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="98"
               x="75.5"
               y="346.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="98"
               x="75.5"
               y="346.5"
               fill="url('#19-270-rgba_175_216_248_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="50"
               height="39"
               x="166.5"
               y="405.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="39"
               x="166.5"
               y="405.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="39"
               x="166.5"
               y="405.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="39"
               x="166.5"
               y="405.5"
               fill="url('#19-270-rgba_246_189_15_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="50"
               height="302"
               x="257.5"
               y="142.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="302"
               x="257.5"
               y="142.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="302"
               x="257.5"
               y="142.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="302"
               x="257.5"
               y="142.5"
               fill="url('#19-270-rgba_139_186_0_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="50"
               height="30"
               x="348.5"
               y="414.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="30"
               x="348.5"
               y="414.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="30"
               x="348.5"
               y="414.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="30"
               x="348.5"
               y="414.5"
               fill="url('#19-270-rgba_255_142_70_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="50"
               height="176"
               x="440.5"
               y="268.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="176"
               x="440.5"
               y="268.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="176"
               x="440.5"
               y="268.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="176"
               x="440.5"
               y="268.5"
               fill="url('#19-270-rgba_0_142_142_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="50"
               height="273"
               x="531.5"
               y="171.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="273"
               x="531.5"
               y="171.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="273"
               x="531.5"
               y="171.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="273"
               x="531.5"
               y="171.5"
               fill="url('#19-270-rgba_214_70_70_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="50"
               height="185"
               x="622.5"
               y="259.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="185"
               x="622.5"
               y="259.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="185"
               x="622.5"
               y="259.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="185"
               x="622.5"
               y="259.5"
               fill="url('#19-270-rgba_142_70_142_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="50"
               height="88"
               x="713.5"
               y="356.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="88"
               x="713.5"
               y="356.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="88"
               x="713.5"
               y="356.5"
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
               transform="translate(1 1)"
            ></rect>
            <rect
               width="50"
               height="88"
               x="713.5"
               y="356.5"
               fill="url('#19-270-rgba_88_133_38_1_-rgba_255_255_255_1_')"
               stroke="#333"
               rx="0"
               ry="0"
            ></rect>
         </g>
      </g>
      <g className="fusioncharts-datalabels">
         <text
            x="100.5"
            y="358.5"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="100.5" dy="3.25">
               10
            </tspan>
         </text>
         <text
            x="191.5"
            y="417.5"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="191.5" dy="3.25">
               4
            </tspan>
         </text>
         <text
            x="282.5"
            y="154.5"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="282.5" dy="3.25">
               31
            </tspan>
         </text>
         <text
            x="373.5"
            y="426.5"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="373.5" dy="3.25">
               3
            </tspan>
         </text>
         <text
            x="465.5"
            y="280.5"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="465.5" dy="3.25">
               18
            </tspan>
         </text>
         <text
            x="556.5"
            y="183.5"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="556.5" dy="3.25">
               28
            </tspan>
         </text>
         <text
            x="647.5"
            y="271.5"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="647.5" dy="3.25">
               19
            </tspan>
         </text>
         <text
            x="738.5"
            y="368.5"
            fill="#555"
            className="fusioncharts-label"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="738.5" dy="3.25">
               9
            </tspan>
         </text>
      </g>
      <g className="raphael-group-123-caption">
         <text
            x="419.5"
            y="16"
            fill="#555"
            className="fusioncharts-caption"
            fontFamily="Verdana, sans"
            fontSize="13"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="419.5" dy="12.992">
               Chart Caption
            </tspan>
         </text>
         <text
            x="419.5"
            y="34"
            fill="#555"
            className="fusioncharts-subcaption"
            fontFamily="Verdana, sans"
            fontSize="11"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="419.5" dy="10.852">
               Chart Sub Caption
            </tspan>
         </text>
      </g>
      <g className="raphael-group-22-hot">
         <g
            fill="silver"
            fillOpacity="0"
            stroke="silver"
            strokeOpacity="0"
            className="raphael-group-73-col-hot"
         >
            <rect width="50" height="98" x="75.5" y="346.5" rx="0" ry="0"></rect>
            <rect width="50" height="39" x="166.5" y="405.5" rx="0" ry="0"></rect>
            <rect
               width="50"
               height="302"
               x="257.5"
               y="142.5"
               rx="0"
               ry="0"
            ></rect>
            <rect width="50" height="30" x="348.5" y="414.5" rx="0" ry="0"></rect>
            <rect
               width="50"
               height="176"
               x="440.5"
               y="268.5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="50"
               height="273"
               x="531.5"
               y="171.5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="50"
               height="185"
               x="622.5"
               y="259.5"
               rx="0"
               ry="0"
            ></rect>
            <rect width="50" height="88" x="713.5" y="356.5" rx="0" ry="0"></rect>
         </g>
      </g>
   </svg>)
};

export default ColumnChartIcon;