/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from "react";
import { ErrorDialog } from "./ErrorDialog";
import i18n from "../../../i18n";


interface IErrorBoundaryState {
    hasError: boolean;
    error?: Error;
}

export class ErrorBoundary extends React.Component<Record<string, any>, IErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ display: "flex" }}>
                    <ErrorDialog message={i18n.t("components.common.error.boundary.message")} details={this.state.error.stack} />
                </div>
            );
        }
        return this.props.children;
    }
}
