
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { connect } from 'react-redux';
import '../../../assets/uxpl/css/BaseElement.css';
import { IState } from '../../../reducers';
import { CrosstabTypes } from '../../common/JrxmlModel/reader/JrxmlCrosstabUtils';
import { BaseElement, IBaseElement } from '../elements/BaseElement';
import withPositionFinder from '../hoc/positionFinder';
import { isCrosstabSection } from './CrosstabUtils';
import * as React from 'react';

class CrosstabBaseElement extends BaseElement {

  public shouldComponentUpdate (nextProps: Readonly<IBaseElement>) {
    const type = this.props.element.get('type');
    if (type === CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME) {
      return true;
    }


    return super.shouldComponentUpdate(nextProps); // Nothing impacting the content of the figure is changed...
  }


  public render() {
    const type = this.props.element.get('type');
    if (type === CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME) {
      const width = this.props.element.get('width', 0) * this.props.zoom;
      const height = this.props.element.get('height', 0) * this.props.zoom;
      const { x, y } = this.props.positionFinder.findPosition(this.props.element);
      const noDataCellStyle : React.CSSProperties = {
        width: width,
        height: height,
        top: y,
        left: x,
        background: 'white',
        display: 'flex'
      }
      let highlightBorder;
      if ((this.props.element.get('highlighted', false)) || this.props.highlightedAsContainer) {
        highlightBorder = (
          <div style={{position: 'absolute', width: width, height: height, left: x, display: 'flex'}}>
          <svg className="BaseElementBorder" width={this.props.element.get('width')} height={this.props.element.get('height')} style={{ width: '100%', height: '100%' }}>
            <rect shapeRendering="optimizeSpeed" width="100%" height="100%" strokeWidth={2 / this.props.zoom} style={{ fill: 'none', stroke: 'rgba(0, 129, 203, 0.5)' }} />
          </svg>
          </div>);
      }
      return <div style={noDataCellStyle}>
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style={{ width, height, flex: 1, }}>
          <defs>
            <pattern id="smallGrid" width="10" height="10" patternUnits="userSpaceOnUse">
              <path d="M 10 0 L 0 0 0 10" fill="none" stroke="#dedede" strokeWidth={0.5 / this.props.zoom} />
            </pattern>
            <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
              <rect width="100" height="100" fill="url(#smallGrid)" />
              <path d="M 100 0 L 0 0 0 100" fill="none" stroke="#dedede" strokeWidth={1 / this.props.zoom} />
            </pattern>
          </defs>

          <rect width="100%" height="100%" fill="url(#grid)" />
        </svg>
        <div style={{position: 'absolute', width: width, height: height, left: x, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <label style={{fontSize: 25, fontWeight: 100, color: 'rgba(204, 204, 204, 0.5)'}}>Crosstab No Data Cell</label>
        </div>
        {highlightBorder}
      </div>
    } else {
      return super.render();
    }
  }

  protected isResizeForbidden = () => {
    const type = this.props.element.get('type'); 
    return isCrosstabSection(type) || type === CrosstabTypes.CROSSTAB_CELL_NAME || type === CrosstabTypes.CROSSTAB_TITLE_CELL_NAME
      || type === CrosstabTypes.CROSSTAB_HEADER_CELL_NAME || type === CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME || type === CrosstabTypes.CROSSTAB_TOTAL_COLUMN_HEADER_NAME
      || type === CrosstabTypes.CROSSTAB_TOTAL_ROW_HEADER_NAME || type === CrosstabTypes.CROSSTAB_ROW_HEADER_NAME || type === CrosstabTypes.CROSSTAB_COLUMN_HEADER_NAME;
  }

}

const mapStateToProps = (state: IState, props: IBaseElement) => {

  return {
    selection: state.getIn(['report', 'selection']),
    element: state.getIn(['report', 'model', 'elements', props.id]),
    model: state.getIn(['report', 'model']),
    highlightedAsContainer: state.getIn(['report', 'highlightedContainer']) === ('elements/' + props.id),
    modelActions: state.getIn(['report', 'modelActions']),
  };
}

export default connect(mapStateToProps)(withPositionFinder(CrosstabBaseElement));