/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const LineChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath id="highcharts-yg4o7q4-1">
          <path fill="none" d="M0 0H489V672H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M56 10H545V682H56z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" d="M96.5 10v672M137.5 10v672M177.5 10v672M218.5 10v672M259.5 10v672M300.5 10v672M340.5 10v672M381.5 10v672M422.5 10v672M463.5 10v672M503.5 10v672M544.5 10v672M55.5 10v672" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M56 682.5h489M56 634.5h489M56 586.5h489M56 538.5h489M56 490.5h489M56 442.5h489M56 394.5h489M56 346.5h489M56 298.5h489M56 250.5h489M56 202.5h489M56 154.5h489M56 106.5h489M56 58.5h489M56 9.5h489" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M56 10H545V682H56z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" stroke="#ccd6eb" d="M96.5 682v10M137.5 682v10M177.5 682v10M218.5 682v10M259.5 682v10M300.5 682v10M340.5 682v10M381.5 682v10M422.5 682v10M463.5 682v10M503.5 682v10M545.5 682v10M55.5 682v10" className="highcharts-tick" />
       <path fill="none" stroke="#ccd6eb" d="M56 682.5h489" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M56 10v672" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-line-series highcharts-color-0" clipPath="url(#highcharts-yg4o7q4-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="none" stroke="#7cb5ec" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.375 545.286l40.75 61.083 40.75-16.927L142.625 672l40.75-9.688 40.75 9.688h40.75l40.75-82.844 40.75 76.936 40.75 5.908 40.75-5.347 40.75 5.347" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M10.375 545.286h10l40.75 61.083 40.75-16.927L142.625 672l40.75-9.688 40.75 9.688h40.75l40.75-82.844 40.75 76.936 40.75 5.908 40.75-5.347 40.75 5.347h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-0 highcharts-line-series highcharts-color-0 highcharts-tracker" data-z-index="0.1">
          <path fill="#7cb5ec" d="M24 545a4 4 0 110-.004zM65 606a4 4 0 110-.004zM105 589a4 4 0 110-.004zM146 672a4 4 0 110-.004zM187 662a4 4 0 110-.004zM228 672a4 4 0 110-.004zM268 672a4 4 0 110-.004zM309 589a4 4 0 110-.004zM350 666a4 4 0 110-.004zM391 672a4 4 0 110-.004zM431 667a4 4 0 110-.004zM472 672a4 4 0 110-.004z" className="highcharts-point highcharts-color-0" transform="translate(56 10)" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-line-series highcharts-color-1" clipPath="url(#highcharts-yg4o7q4-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="none" stroke="#434348" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.375 507.404l40.75 118.42 40.75-551.616 40.75 575.702 40.75-7.981 40.75-22.942 40.75 40.915 40.75-2.792 40.75-41.328 40.75 34.88 40.75-73.975 40.75 91.588" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M10.375 507.404h10l40.75 118.42 40.75-551.616 40.75 575.702 40.75-7.981 40.75-22.942 40.75 40.915 40.75-2.792 40.75-41.328 40.75 34.88 40.75-73.975 40.75 91.588h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-1 highcharts-line-series highcharts-color-1 highcharts-tracker" data-z-index="0.1">
          <path fill="#434348" d="M20 503l4 4-4 4-4-4zM61 622l4 4-4 4-4-4zM101 70l4 4-4 4-4-4zM142 646l4 4-4 4-4-4zM183 638l4 4-4 4-4-4zM224 615l4 4-4 4-4-4zM264 656l4 4-4 4-4-4zM305 653l4 4-4 4-4-4zM346 612l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(56 10)" />
          <path fill="#434348" strokeWidth="0" d="M387 646.661l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(56 10)" />
          <path fill="#434348" d="M427 573l4 4-4 4-4-4zM468 664.275l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(56 10)" />
       </g>
       <g className="highcharts-series highcharts-series-2 highcharts-line-series highcharts-color-2" clipPath="url(#highcharts-yg4o7q4-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="none" stroke="#90ed7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.375 555.18l40.75 39.79 40.75-525.89 40.75 600.057 40.75 2.863 40.75-149.5 40.75 148.156 40.75-8.187 40.75 6.002 40.75-13.937 40.75-141.662L468.625 672" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M10.375 555.18h10l40.75 39.79 40.75-525.89 40.75 600.057 40.75 2.863 40.75-149.5 40.75 148.156 40.75-8.187 40.75 6.002 40.75-13.937 40.75-141.662L468.625 672h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-2 highcharts-line-series highcharts-color-2 highcharts-tracker" data-z-index="0.1">
          <path fill="#90ed7d" d="M16 551h8v8h-8zM57 591h8v8h-8zM97 65h8v8h-8zM138 665h8v8h-8zM179 668h8v8h-8zM220 518h8v8h-8zM260 667h8v8h-8zM301 658h8v8h-8zM342 664h8v8h-8zM383 651h8v8h-8z" className="highcharts-point highcharts-color-2" transform="translate(56 10)" />
          <path fill="#90ed7d" strokeWidth="0" d="M423 508.872h8v8h-8z" className="highcharts-point highcharts-color-2" transform="translate(56 10)" />
          <path fill="#90ed7d" d="M464 668h8v8h-8z" className="highcharts-point highcharts-color-2" transform="translate(56 10)" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(178 762)">
       <rect width="199" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-line-series highcharts-color-0 highcharts-series-0" data-z-index="1">
             <path fill="none" stroke="#7cb5ec" strokeWidth="2" d="M0 11h12" className="highcharts-graph" transform="translate(8 3)" />
             <path fill="#7cb5ec" d="M10 11a4 4 0 110-.004z" className="highcharts-point" transform="translate(8 3)" />
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold" transform="translate(8 3)">
                <tspan>1996</tspan>
             </text>
          </g>
          <g className="highcharts-legend-item highcharts-line-series highcharts-color-1 highcharts-series-1" data-z-index="1">
             <path fill="none" stroke="#434348" strokeWidth="2" d="M0 11h12" className="highcharts-graph" transform="translate(75.672 3)" />
             <path fill="#434348" d="M6 7l4 4-4 4-4-4z" className="highcharts-point" transform="translate(75.672 3)" />
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold" transform="translate(75.672 3)">
                <tspan>1997</tspan>
             </text>
          </g>
          <g className="highcharts-legend-item highcharts-line-series highcharts-color-2 highcharts-series-2" data-z-index="1">
             <path fill="none" stroke="#90ed7d" strokeWidth="2" d="M0 11h12" className="highcharts-graph" transform="translate(143.344 3)" />
             <path fill="#90ed7d" d="M2 7h8v8H2z" className="highcharts-point" transform="translate(143.344 3)" />
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold" transform="translate(143.344 3)">
                <tspan>1998</tspan>
             </text>
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="78.968" y="698" transform="rotate(-45 78.968 698)">Albuquerque</text>
       <text x="119.718" y="698" transform="rotate(-45 119.718 698)">Anchorage</text>
       <text x="160.468" y="698" transform="rotate(-45 160.468 698)">Boise</text>
       <text x="201.218" y="698" transform="rotate(-45 201.218 698)">Butte</text>
       <text x="241.968" y="698" transform="rotate(-45 241.968 698)">Elgin</text>
       <text x="282.718" y="698" transform="rotate(-45 282.718 698)">Eugene</text>
       <text x="323.468" y="698" transform="rotate(-45 323.468 698)">Kirkland</text>
       <text x="364.218" y="698" transform="rotate(-45 364.218 698)">Lander</text>
       <text x="404.968" y="698" transform="rotate(-45 404.968 698)">Portland</text>
       <text x="445.718" y="698" transform="rotate(-45 445.718 698)">
          <tspan>San Francisco</tspan>
       </text>
       <text x="486.468" y="698" transform="rotate(-45 486.468 698)">Seattle</text>
       <text x="527.218" y="698" transform="rotate(-45 527.218 698)">
          <tspan>Walla Walla</tspan>
       </text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="41" y="687">0</text>
       <text x="41" y="639">250</text>
       <text x="41" y="591">500</text>
       <text x="41" y="543">750</text>
       <text x="41" y="495">1.000</text>
       <text x="41" y="447">1.250</text>
       <text x="41" y="399">1.500</text>
       <text x="41" y="351">1.750</text>
       <text x="41" y="303">2.000</text>
       <text x="41" y="255">2.250</text>
       <text x="41" y="207">2.500</text>
       <text x="41" y="159">2.750</text>
       <text x="41" y="111">3.000</text>
       <text x="41" y="63">3.250</text>
       <text x="41" y="15">3.500</text>
    </g>
 </svg>)};

export default LineChartIcon;