/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from "react";
import {
    CommandHandler,
    getExitDialog,
    RouterProps,
    withRouter
} from "@jss/js-common";
import { ResourceRouteState, RunContainer } from "@jss/js-repository";
import { IEditorViewProps, IRepositoryItemDescriptor } from "@jss/js-rest-api";
import {
    EXIT_TEXT_EDITOR,
    TextualEditor,
    TEXT_EDITOR_DIRTY,
    TEXT_SAVED,
    TEXT_SAVED_AS,
    UPDATED_DESCRIPTOR
} from "../";
import CircularProgress from "@material-ui/core/CircularProgress";


interface IState {
    openDescriptor: IRepositoryItemDescriptor | undefined;
    path?: IRepositoryItemDescriptor[];
    savedOnce: boolean;
    isEditorDirty: boolean;
    exitAlertOpen: boolean;
    logoutCallback: () => void | undefined,
}

export type getTextualEditorHeaderComponentType = ({ goToHome, requestLogout }: { goToHome: () => void, requestLogout: (callback) => void }) => JSX.Element;

export interface TextualEditorViewProps extends IEditorViewProps {
    getHeaderComponent: getTextualEditorHeaderComponentType;
    language?: string,
}

class _TextualEditorView extends React.Component<TextualEditorViewProps & RouterProps, IState>  {

    private commandHandlers: Map<string, CommandHandler> = new Map();

    public state: IState = {
        openDescriptor: undefined,
        path: undefined,
        savedOnce: false,
        isEditorDirty: false,
        exitAlertOpen: false,
        logoutCallback: undefined,
    }

    private onBeforeUnload = (event) => {
        // Show prompt based on state
        if (this.state.isEditorDirty) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = ''
            }
            return '';
        }
    }

    public componentDidMount = () => {
        window.addEventListener("beforeunload", this.onBeforeUnload);

        const textEditorData: ResourceRouteState = { descriptor: this.props.descriptor };
        if (textEditorData) {
            const { descriptor } = textEditorData;
            const path = this.props.path;
            const isNewResource = !descriptor.uuid;
            this.setState({
                openDescriptor: descriptor,
                path,
                savedOnce: !isNewResource,
                isEditorDirty: isNewResource
            });
        }
    }

    public componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onBeforeUnload);
    }

    private registerCommand = (id: string, handle: CommandHandler) => {
        this.commandHandlers.set(id, handle);
    }

    private executeCommand = (id: string, params: any[]) => {
        if (id === EXIT_TEXT_EDITOR) {
            if (this.state.isEditorDirty) {
                this.setState({ exitAlertOpen: true });
            } else {
                this.props.doExit();
            }
        } else if (id === TEXT_SAVED || id === UPDATED_DESCRIPTOR || id === TEXT_SAVED_AS) {
            if (params[0] && params[0].descriptor) {
                if (!this.state.savedOnce) {
                    this.props.onFirstSave(params[0].descriptor);
                }
                this.setState({ openDescriptor: params[0].descriptor, savedOnce: true }, () => {
                    if (id === TEXT_SAVED_AS){
                        this.props.router.navigate(`/edit?path=${this.state.openDescriptor.uuid}`);
                    }
                });
            }
        } else if (id === TEXT_EDITOR_DIRTY) {
            this.setState({ isEditorDirty: params[0].isDirty });
        }
    }

    private goToHome = () => {
        const handler = this.commandHandlers.get(EXIT_TEXT_EDITOR);
        if (handler && handler.isEnabled()) {
            handler.execute({});
        }
    }

    private requestLogout = (callback: () => void) => {
        if (this.state.isEditorDirty) {
            this.setState({logoutCallback: callback, exitAlertOpen: true});
        } else {
            callback();
        }
    }

    private handleExitAlertCancel = () => {
        this.setState({ exitAlertOpen: false, logoutCallback: undefined });
    }

    private handleExitConfirm = () => {
        this.setState({exitAlertOpen: false, isEditorDirty: false}, () => {
            if (this.state.logoutCallback) {
                this.state.logoutCallback()
            } else {
                this.props.doExit();
            }
        })
    }

    public render() {
        if (this.state.openDescriptor) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    {
                        this.props.getHeaderComponent({ goToHome: this.goToHome, requestLogout: this.requestLogout })
                    }
                    <div style={{ flex: 1, justifyContent: 'stretch', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                        <RunContainer>
                            <TextualEditor openDescriptor={this.state.openDescriptor} executeCommand={this.executeCommand} registerHandler={this.registerCommand} />
                        </RunContainer>
                    </div>
                    {getExitDialog(this.state.exitAlertOpen, this.handleExitAlertCancel, this.handleExitConfirm)}
                </div>
            );
        } else {
            return (
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            )
        }
    }
}

export const TextualEditorView = withRouter(_TextualEditorView);
