/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map } from 'immutable';
import { ElementTypes } from '../../../../sagas/report/document/elementTypes';
import { addAttributeToMap, addBooleanAttributeToMap, addChildToParent, addChildValueToMap, addChildValueToMapWithName, addFloatAttributeToMap, addIntAttributeToMap, createBox, createChartCrosstabDataset, createFont, createHyperLinkForElement, createReportElement, generatePath, getChildNodeByName, getNamespace, IObjectCounter, readCDataText } from './JrxmlHelpers';


const PIE_CHART_NAME = "pieChart";
const PIE3D_CHART_NAME = "pie3DChart";
const BAR_CHART_NAME = "barChart";
const BAR3D_CHART_NAME = "bar3DChart";
const XY_BAR_CHART_NAME = "xyBarChart";
const STACKED_BAR_CHART_NAME = "stackedBarChart"; 
const STACKED_BAR3D_CHART_NAME = "stackedBar3DChart";
const LINE_CHART_NAME = "lineChart";
const XY_LINE_CHART_NAME = "xyLineChart";
const AREA_CHART_NAME = "areaChart";
const XY_AREA_CHART_NAME = "xyAreaChart";
const SCATTER_CHART_NAME = "scatterChart";
const BUBBLE_CHART_NAME = "bubbleChart";
const TIMESERIES_CHART_NAME = "timeSeriesChart"; 
const HIGHLOW_CHART_NAME = "highLowChart"; 
const CANDLESTICK_CHART_NAME = "candlestickChart"; 
const METER_CHART_NAME = "meterChart";
const THERMOMETER_CHART_NAME = "thermometerChart"
const MULTIAXIS_CHART_NAME = "multiAxisChart";
const STACKED_AREA_CHART_NAME = "stackedAreaChart";
const GANTT_CHART_NAME = "ganttChart";
const SPIDER_CHART_NAME = "spiderChart";

export const ChartTypes = {
    PIE_CHART_NAME,
    PIE3D_CHART_NAME,
    BAR_CHART_NAME,
    BAR3D_CHART_NAME,
    XY_BAR_CHART_NAME,
    STACKED_BAR_CHART_NAME,
    STACKED_BAR3D_CHART_NAME,
    LINE_CHART_NAME,
    XY_LINE_CHART_NAME,
    AREA_CHART_NAME,
    XY_AREA_CHART_NAME,
    SCATTER_CHART_NAME,
    BUBBLE_CHART_NAME,
    TIMESERIES_CHART_NAME,
    HIGHLOW_CHART_NAME,
    CANDLESTICK_CHART_NAME,
    METER_CHART_NAME,
    THERMOMETER_CHART_NAME,
    MULTIAXIS_CHART_NAME,
    STACKED_AREA_CHART_NAME,
    GANTT_CHART_NAME,
    SPIDER_CHART_NAME
}

export const CHART_TYPE = 'CHART_TYPE';

export const SPIDERCHART_NAMESPACE = "SPIDERCHART_XMLNS_NAMESPACE";

export const isChart = (tagName : string) : boolean => {
    const chartIndex = Object.values(ChartTypes).findIndex((value : string) => {
        return value=== tagName;
    });
    return chartIndex !== -1;
}

export const isSpiderChart = (componentElement : Element) : boolean => {
    const childList = componentElement.childNodes;
    let found = false;
    for (let i = 0; i < childList.length && !found; i++) {
        const child = childList[i];
        const nodeName = child.nodeName;
        if (child.nodeType === Node.ELEMENT_NODE && nodeName !== "reportElement"){
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length>0 ? baseNamespace + ":" : baseNamespace;
            found = nodeName === namespace + "spiderChart";
        }
    }
    return found;
}


export const createChartElement = (chartElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    const pathValue = generatePath(parentElement);
    const result = createBaseChartElement(chartElement, pathValue, objectCounter);
    if (result !== null){
        let newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, result.get("id"));
        newDocument = newDocument.setIn(["elements", result.get("id")], result);
        return newDocument;
    } else {
        return document;
    }
}

export const createSpiderChartElement = (componentElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    const pathValue = generatePath(parentElement);
    const reportElement = getChildNodeByName(componentElement, "reportElement");
    let result : Map<string, any> = createReportElement(reportElement as Element, pathValue, ElementTypes.JFREECHART, objectCounter);
    result = result.set(CHART_TYPE, SPIDER_CHART_NAME);

    let newDocument = document;
    componentElement.childNodes.forEach(child => {
        if (child.nodeType === Node.ELEMENT_NODE && child.nodeName !== "reportElement"){
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : "";
            const spiderChartElement = getChildNodeByName(componentElement, namespace + "spiderChart") as Element;
            if (spiderChartElement !== null){
                result = result.set(SPIDERCHART_NAMESPACE, baseNamespace);
                result = addAttributeToMap(spiderChartElement, baseNamespace.length > 0 ? "xmlns:" + baseNamespace : "xmlns" , result);
                result = addAttributeToMap(spiderChartElement, "xsi:schemaLocation", result);
                result = result.set("spiderSettings", createSpiderChartSettings(getChildNodeByName(spiderChartElement, namespace + "chartSettings") as Element));
                result = result.set("spiderDataset", createSpiderDataset(getChildNodeByName(spiderChartElement, namespace + "spiderDataset") as Element, objectCounter));
                result = result.set("spiderPlot", createSpiderPlot(getChildNodeByName(spiderChartElement, namespace + "spiderPlot") as Element, namespace));
                newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), newDocument, result.get("id"));
                newDocument = newDocument.setIn(["elements", result.get("id")], result);
            }
        }
    });
    return newDocument;
}

const createBaseChartElement = (chartElement: Element, pathValue: string, objectCounter: IObjectCounter) : Map<string, any> | null => {
    const chartType = chartElement.nodeName;
    let result = null;
    if (chartType === ChartTypes.PIE_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("pieDataset", createPieDataset(getChildNodeByName(chartElement, "pieDataset") as Element, objectCounter));
        result = result.set("piePlot", createPiePlot(getChildNodeByName(chartElement, "piePlot") as Element));
    } else if (chartType === ChartTypes.PIE3D_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("pieDataset", createPieDataset(getChildNodeByName(chartElement, "pieDataset") as Element, objectCounter));
        result = result.set("pie3DPlot", createPie3DPlot(getChildNodeByName(chartElement, "pie3DPlot") as Element)); 
    } else if (chartType === ChartTypes.BAR_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("categoryDataset", createCategoryDataset(getChildNodeByName(chartElement, "categoryDataset") as Element, objectCounter));
        result = result.set("barPlot", createBarPlot(getChildNodeByName(chartElement, "barPlot") as Element));
    } else if (chartType === ChartTypes.BAR3D_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("categoryDataset", createCategoryDataset(getChildNodeByName(chartElement, "categoryDataset") as Element, objectCounter));
        result = result.set("bar3DPlot", createBar3DPlot(getChildNodeByName(chartElement, "bar3DPlot") as Element));
    } else if (chartType === ChartTypes.XY_BAR_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
     
        const timePeriodDatasetElement = getChildNodeByName(chartElement, "timePeriodDataset") ;
        if (timePeriodDatasetElement !== null){
            const timePeriodDataset = createTimePeriodDataset(timePeriodDatasetElement as Element, objectCounter);
            if (timePeriodDataset !== null){
                result = result.set("timePeriodDataset", timePeriodDataset);
            }
        }
       
        const timeSeriesDatasetElement = getChildNodeByName(chartElement, "timeSeriesDataset");
        if (timeSeriesDatasetElement !== null){
            const timeSeriesDataset = createTimeSeriesDataset(timeSeriesDatasetElement as Element, objectCounter);
            if (timeSeriesDataset !== null){
                result = result.set("timeSeriesDataset", timeSeriesDataset);
            }
        }

        const xySeriesDatasetElement = getChildNodeByName(chartElement, "xyDataset");
        if (xySeriesDatasetElement !== null){
            const xySeriesDataset = createXYDataset(xySeriesDatasetElement as Element, objectCounter);
            if (xySeriesDataset !== null){
                result = result.set("xyDataset", xySeriesDataset);
            }
        }
        
        result = result.set("barPlot", createBarPlot(getChildNodeByName(chartElement, "barPlot") as Element));
    } else if (chartType === ChartTypes.STACKED_BAR_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("categoryDataset", createCategoryDataset(getChildNodeByName(chartElement, "categoryDataset") as Element, objectCounter));
        result = result.set("barPlot", createBarPlot(getChildNodeByName(chartElement, "barPlot") as Element));
    } else if (chartType === ChartTypes.STACKED_BAR3D_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("categoryDataset", createCategoryDataset(getChildNodeByName(chartElement, "categoryDataset") as Element, objectCounter));
        result = result.set("bar3DPlot", createBar3DPlot(getChildNodeByName(chartElement, "bar3DPlot") as Element));
    } else if (chartType === ChartTypes.LINE_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("categoryDataset", createCategoryDataset(getChildNodeByName(chartElement, "categoryDataset") as Element, objectCounter));
        result = result.set("linePlot", createLinePlot(getChildNodeByName(chartElement, "linePlot") as Element));
    } else if (chartType === ChartTypes.XY_LINE_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("xyDataset", createXYDataset(getChildNodeByName(chartElement, "xyDataset") as Element, objectCounter));
        result = result.set("linePlot", createLinePlot(getChildNodeByName(chartElement, "linePlot") as Element));
    } else if (chartType === ChartTypes.AREA_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("categoryDataset", createCategoryDataset(getChildNodeByName(chartElement, "categoryDataset") as Element, objectCounter));
        result = result.set("areaPlot", createAreaPlot(getChildNodeByName(chartElement, "areaPlot") as Element));
    } else if (chartType === ChartTypes.XY_AREA_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("xyDataset", createXYDataset(getChildNodeByName(chartElement, "xyDataset") as Element, objectCounter));
        result = result.set("areaPlot", createAreaPlot(getChildNodeByName(chartElement, "areaPlot") as Element));
    } else if (chartType === ChartTypes.SCATTER_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("xyDataset", createXYDataset(getChildNodeByName(chartElement, "xyDataset") as Element, objectCounter));
        result = result.set("scatterPlot", createScatterPlot(getChildNodeByName(chartElement, "scatterPlot") as Element));
    } else if (chartType === ChartTypes.BUBBLE_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("xyzDataset", createXYZDataset(getChildNodeByName(chartElement, "xyzDataset") as Element, objectCounter));
        result = result.set("bubblePlot", createBubblePlot(getChildNodeByName(chartElement, "bubblePlot") as Element));
    } else if (chartType === ChartTypes.TIMESERIES_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("timeSeriesDataset", createTimeSeriesDataset(getChildNodeByName(chartElement, "timeSeriesDataset") as Element, objectCounter));
        result = result.set("timeSeriesPlot", createTimeSeriesPlot(getChildNodeByName(chartElement, "timeSeriesPlot") as Element));
    } else if (chartType === ChartTypes.HIGHLOW_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("highLowDataset", createHighLowDataset(getChildNodeByName(chartElement, "highLowDataset") as Element, objectCounter));
        result = result.set("highLowPlot", createHighLowPlot(getChildNodeByName(chartElement, "highLowPlot") as Element));
    } else if (chartType === ChartTypes.CANDLESTICK_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("highLowDataset", createHighLowDataset(getChildNodeByName(chartElement, "highLowDataset") as Element, objectCounter));
        result = result.set("candlestickPlot", createCandleStickPlot(getChildNodeByName(chartElement, "candlestickPlot") as Element));
    } else if (chartType === ChartTypes.METER_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("valueDataset", createValueDataset(getChildNodeByName(chartElement, "valueDataset") as Element, objectCounter));
        result = result.set("meterPlot", createMeterPlot(getChildNodeByName(chartElement, "meterPlot") as Element));
    } else if (chartType === ChartTypes.THERMOMETER_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("valueDataset", createValueDataset(getChildNodeByName(chartElement, "valueDataset") as Element, objectCounter));
        result = result.set("thermometerPlot", createThermoMeterPlot(getChildNodeByName(chartElement, "thermometerPlot") as Element));
    } else if (chartType === ChartTypes.MULTIAXIS_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("multiAxisPlot", createMultiaxisPlot(getChildNodeByName(chartElement, "multiAxisPlot") as Element));
    } else if (chartType === ChartTypes.STACKED_AREA_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("categoryDataset", createCategoryDataset(getChildNodeByName(chartElement, "categoryDataset") as Element, objectCounter));
        result = result.set("areaPlot", createAreaPlot(getChildNodeByName(chartElement, "areaPlot") as Element));
    } else if (chartType === ChartTypes.GANTT_CHART_NAME){
        result = createBaseChart(getChildNodeByName(chartElement, "chart") as Element, pathValue, chartType, objectCounter);
        result = result.set("ganttDataset", createGanttDataset(getChildNodeByName(chartElement, "ganttDataset") as Element, objectCounter));
        result = result.set("barPlot", createBarPlot(getChildNodeByName(chartElement, "barPlot") as Element));
    }
    return result;
}

const createSpiderChartSettings = (spiderChartElement : Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    result = addAttributeToMap(spiderChartElement, "renderType", result);
    result = addAttributeToMap(spiderChartElement, "customizerClass", result);
    result = addAttributeToMap(spiderChartElement, "backcolor", result);
    result = addBooleanAttributeToMap(spiderChartElement, "isShowLegend", result);


    const chartTitleElement = getChildNodeByName(spiderChartElement, "chartTitle");
    if (chartTitleElement !== null){ 
        const chartTitle : Map<string, any> = createChartTitle(chartTitleElement as Element);
        result = result.set("chartTitle", chartTitle);
    }

    const chartSubTitleElement = getChildNodeByName(spiderChartElement, "chartSubtitle");
    if (chartSubTitleElement !== null){ 
        const chartSubTitle : Map<string, any> = createChartSubTitle(chartSubTitleElement as Element);
        result = result.set("chartSubtitle", chartSubTitle);
    }

    const chartLegendElement = getChildNodeByName(spiderChartElement, "chartLegend");
    if (chartLegendElement !== null){ 
        const chartLegend : Map<string, any> = createChartLegend(chartLegendElement as Element);
        result = result.set("chartLegend", chartLegend);
    }

    result = createSpiderHyperLinkForElement(spiderChartElement, result);
    return result;
}

const createChartTitle = (chartTitleElement: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addAttributeToMap(chartTitleElement, "position", result);
    result = addAttributeToMap(chartTitleElement, "color", result);
    const fontElement = getChildNodeByName(chartTitleElement, "font");
    if (fontElement !== null){
        result = result.set("font", createFont(fontElement as Element));
    }
    result = addChildValueToMap(chartTitleElement, "titleExpression", result);
    return result; 
}

const createChartSubTitle = (chartTitleElement: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addAttributeToMap(chartTitleElement, "color", result);
    const fontElement = getChildNodeByName(chartTitleElement, "font");
    if (fontElement !== null){
        result = result.set("font", createFont(fontElement as Element));
    }
    result = addChildValueToMap(chartTitleElement, "subtitleExpression", result);
    return result; 
}

const createChartLegend = (chartLegend: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    const fontElement = getChildNodeByName(chartLegend, "font");
    if (fontElement !== null){
        result = result.set("font", createFont(fontElement as Element));
    }
    result = addAttributeToMap(chartLegend, "backgroundColor", result);
    result = addAttributeToMap(chartLegend, "textColor", result);
    result = addAttributeToMap(chartLegend, "position", result);
    return result; 
}

const createXYZDataset = (xyzDataset: Element, objectCounter: IObjectCounter) : Map<string, any> | null => {
    let result : Map<string, any> = Map<string,any>();

    xyzDataset.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "xyzSeries" ){
            const xyzSeriesElement = element as Element;
            const xyzSeries = crateXYZSeries(xyzSeriesElement);
            result = result.updateIn(["xyzSeries"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(xyzSeries));
        } 
    });

    const datasetNode = getChildNodeByName(xyzDataset, "dataset");
    if (datasetNode !== null){
        const dataset : Map<string, any> = createChartCrosstabDataset(datasetNode as Element, objectCounter);
        if (!dataset.isEmpty()){
            result = result.set("dataset", dataset);
        }
    }
    return result; 
}

const crateXYZSeries = (xyzSeries: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addChildValueToMap(xyzSeries, "seriesExpression", result);
    result = addChildValueToMap(xyzSeries, "xValueExpression", result);
    result = addChildValueToMap(xyzSeries, "yValueExpression", result);
    result = addChildValueToMap(xyzSeries, "zValueExpression", result);
    const hyperlink = getChildNodeByName(xyzSeries, "itemHyperlink");
    if (hyperlink !== null) {
        result = createSectionHyperLinkForElement(hyperlink as Element, result);
    }
    return result; 
}

const createXYDataset = (xyDataset: Element, objectCounter: IObjectCounter) : Map<string, any> | null => {
    let result : Map<string, any> = Map<string,any>();

    xyDataset.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "xySeries" ){
            const xySeriesElement = element as Element;
            const xySeries = crateXYSeries(xySeriesElement);
            result = result.updateIn(["xySeries"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(xySeries));
        } 
    });

    const datasetNode = getChildNodeByName(xyDataset, "dataset");
    if (datasetNode !== null){
        const dataset : Map<string, any> = createChartCrosstabDataset(datasetNode as Element, objectCounter);
        if (!dataset.isEmpty()){
            result = result.set("dataset", dataset);
        }
    }
    return result; 
}

const crateXYSeries = (xySeries: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addChildValueToMap(xySeries, "seriesExpression", result);
    result = addChildValueToMap(xySeries, "xValueExpression", result);
    result = addChildValueToMap(xySeries, "yValueExpression", result);
    result = addChildValueToMap(xySeries, "labelExpression", result);
    result = addBooleanAttributeToMap(xySeries, "autoSort", result);
    const hyperlink = getChildNodeByName(xySeries, "itemHyperlink");
    if (hyperlink !== null) {
        result = createSectionHyperLinkForElement(hyperlink as Element, result);
    }
    return result; 
}


const createTimeSeriesDataset = (timeSeriesDataset: Element, objectCounter: IObjectCounter) : Map<string, any> | null => {
    let result : Map<string, any> = Map<string,any>();

    timeSeriesDataset.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "timeSeries" ){
            const timeSeriesElement = element as Element;
            const timeSeries = crateTimeSeriesSeries(timeSeriesElement);

            result = result.updateIn(["timeSeries"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(timeSeries));
        } 
    });

    const datasetNode = getChildNodeByName(timeSeriesDataset, "dataset");
    if (datasetNode !== null){
        const dataset : Map<string, any> = createChartCrosstabDataset(datasetNode as Element, objectCounter);
        if (!dataset.isEmpty()){
            result = result.set("dataset", dataset);
        }
    }
    result = addAttributeToMap(timeSeriesDataset, "timePeriod", result);
    return result; 
}

const crateTimeSeriesSeries = (timeSeries: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addChildValueToMap(timeSeries, "seriesExpression", result);
    result = addChildValueToMap(timeSeries, "timePeriodExpression", result);
    result = addChildValueToMap(timeSeries, "valueExpression", result);
    result = addChildValueToMap(timeSeries, "labelExpression", result);
    const hyperlink = getChildNodeByName(timeSeries, "itemHyperlink");
    if (hyperlink !== null) {
        result = createSectionHyperLinkForElement(hyperlink as Element, result);
    }
    return result; 
}

const createTimePeriodDataset = (timePeriodDataset: Element, objectCounter: IObjectCounter) : Map<string, any> | null => {
    let result : Map<string, any> = Map<string,any>();

    timePeriodDataset.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "timePeriodSeries" ){
            const timePeriodSeriesElement = element as Element;
            const timePeriodSeries = createTimePeriodSeries(timePeriodSeriesElement);
            result = result.updateIn(["timePeriodSeries"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(timePeriodSeries));
        } 
    });

    const datasetNode = getChildNodeByName(timePeriodDataset, "dataset");
    if (datasetNode !== null){
        const dataset : Map<string, any> = createChartCrosstabDataset(datasetNode as Element, objectCounter);
        if (!dataset.isEmpty()){
            result = result.set("dataset", dataset);
        }
    }
    return result; 
}

const createTimePeriodSeries = (timePeriodSeries: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addChildValueToMap(timePeriodSeries, "seriesExpression", result);
    result = addChildValueToMap(timePeriodSeries, "startDateExpression", result);
    result = addChildValueToMap(timePeriodSeries, "endDateExpression", result);
    result = addChildValueToMap(timePeriodSeries, "valueExpression", result);
    result = addChildValueToMap(timePeriodSeries, "labelExpression", result);
    const hyperlink = getChildNodeByName(timePeriodSeries, "itemHyperlink");
    if (hyperlink !== null) {
        result = createSectionHyperLinkForElement(hyperlink as Element, result);
    }
    return result; 
}

const createValueDataset = (valueDataset: Element, objectCounter: IObjectCounter) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    result = addChildValueToMap(valueDataset, "valueExpression", result);

    const datasetNode = getChildNodeByName(valueDataset, "dataset");
    if (datasetNode !== null){
        const dataset : Map<string, any> = createChartCrosstabDataset(datasetNode as Element, objectCounter);
        if (!dataset.isEmpty()){
            result = result.set("dataset", dataset);
        }
    }
    return result; 
}


const createHighLowDataset = (highLowDataset: Element, objectCounter: IObjectCounter) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    result = addChildValueToMap(highLowDataset, "seriesExpression", result);
    result = addChildValueToMap(highLowDataset, "dateExpression", result);
    result = addChildValueToMap(highLowDataset, "highExpression", result);
    result = addChildValueToMap(highLowDataset, "lowExpression", result);
    result = addChildValueToMap(highLowDataset, "openExpression", result);
    result = addChildValueToMap(highLowDataset, "closeExpression", result);
    result = addChildValueToMap(highLowDataset, "volumeExpression", result);

    const datasetNode = getChildNodeByName(highLowDataset, "dataset");
    if (datasetNode !== null){
        const dataset : Map<string, any> = createChartCrosstabDataset(datasetNode as Element, objectCounter);
        if (!dataset.isEmpty()){
            result = result.set("dataset", dataset);
        }
    }

    const hyperlink = getChildNodeByName(highLowDataset, "itemHyperlink");
    if (hyperlink !== null) {
        result = createSectionHyperLinkForElement(hyperlink as Element, result);
    }

    return result; 
}

const createSpiderDataset = (categoryDataset: Element, objectCounter: IObjectCounter) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    const datasetNode = getChildNodeByName(categoryDataset, "dataset");
    if (datasetNode !== null){
        const dataset : Map<string, any> = createChartCrosstabDataset(datasetNode as Element, objectCounter);
        if (!dataset.isEmpty()){
            result = result.set("dataset", dataset);
        }
    }

    categoryDataset.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "categorySeries" ){
            const categorySeriesElement = element as Element;
            const categorySeries = createCategorySeries(categorySeriesElement);
            result = result.updateIn(["categorySeries"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(categorySeries));
        } 
    });
    return result; 
}


const createCategoryDataset = (categoryDataset: Element, objectCounter: IObjectCounter) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    const datasetNode = getChildNodeByName(categoryDataset, "dataset");
    if (datasetNode !== null){
        const dataset : Map<string, any> = createChartCrosstabDataset(datasetNode as Element, objectCounter);
        if (!dataset.isEmpty()){
            result = result.set("dataset", dataset);
        }
    }

    categoryDataset.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "categorySeries" ){
            const categorySeriesElement = element as Element;
            const categorySeries = createCategorySeries(categorySeriesElement);
            result = result.updateIn(["categorySeries"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(categorySeries));
        } 
    });
    return result; 
}

const createSpiderPlot = (spiderPlotElement: Element, namespace: string) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    const labelFont = getChildNodeByName(spiderPlotElement, "labelFont");
    if (labelFont !== null) {
        const fontElement = getChildNodeByName(labelFont, "font");
        if (fontElement !== null){
            const font = createFont(fontElement as Element)
            if (!font.isEmpty()){
             result = result.set("labelFont", font);
            }
        }
    }

    result = addChildValueToMap(spiderPlotElement, namespace + "maxValueExpression", result);

    result = addAttributeToMap(spiderPlotElement, "rotation", result);
    result = addAttributeToMap(spiderPlotElement, "tableOrder", result);
    result = addBooleanAttributeToMap(spiderPlotElement, "isWebFilled", result);
    result = addFloatAttributeToMap(spiderPlotElement, "startAngle", result);
    result = addFloatAttributeToMap(spiderPlotElement, "headPercent", result);
    result = addFloatAttributeToMap(spiderPlotElement, "interiorGap", result);
    result = addAttributeToMap(spiderPlotElement, "axisLineColor", result);
    result = addFloatAttributeToMap(spiderPlotElement, "axisLineWidth", result);
    result = addFloatAttributeToMap(spiderPlotElement, "labelGap", result);
    result = addAttributeToMap(spiderPlotElement, "labelColor", result);
    result = addAttributeToMap(spiderPlotElement, "backcolor", result);
    result = addFloatAttributeToMap(spiderPlotElement, "backgroundAlpha", result);
    result = addFloatAttributeToMap(spiderPlotElement, "foregroundAlpha", result);
    return result; 
}

const createCategorySeries = (categorySeries: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addChildValueToMap(categorySeries, "seriesExpression", result);
    result = addChildValueToMap(categorySeries, "categoryExpression", result);
    result = addChildValueToMap(categorySeries, "valueExpression", result);
    result = addChildValueToMap(categorySeries, "labelExpression", result);
    const hyperlink = getChildNodeByName(categorySeries, "itemHyperlink");
    if (hyperlink !== null) {
        result = createSectionHyperLinkForElement(hyperlink as Element, result);
    }
    return result; 
}

const createThermoMeterPlot = (meterPlot: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addAttributeToMap(meterPlot, "valueLocation", result);
    result = addAttributeToMap(meterPlot, "mercuryColor", result);
    result = addBooleanAttributeToMap(meterPlot, "isShowValueLines", result);

    const dataRangeElement = getChildNodeByName(meterPlot, "dataRange");
    if (dataRangeElement !== null){
        result = addChildValueToMap(dataRangeElement as Element, "lowExpression",result);
        result = addChildValueToMap(dataRangeElement as Element, "highExpression",result);
    }

    const lowRangeElement = getChildNodeByName(meterPlot, "lowRange");
    if (lowRangeElement !== null){
        const lowDataRange = getChildNodeByName(lowRangeElement, "dataRange");
        result = addChildValueToMapWithName(lowDataRange as Element, "lowExpression", "low_lowExpression", result);
        result = addChildValueToMapWithName(lowDataRange as Element, "highExpression", "low_highExpression", result);
    }

    const mediumRangeElement = getChildNodeByName(meterPlot, "mediumRange");
    if (mediumRangeElement !== null){
        const mediumDataRange = getChildNodeByName(mediumRangeElement, "dataRange");
        result = addChildValueToMapWithName(mediumDataRange as Element, "lowExpression", "medium_lowExpression",result);
        result = addChildValueToMapWithName(mediumDataRange as Element, "highExpression", "medium_highExpression",result);
    }

    const highRangeElement = getChildNodeByName(meterPlot, "highRange");
    if (highRangeElement !== null){
        const highDataRange = getChildNodeByName(highRangeElement, "dataRange");
        result = addChildValueToMapWithName(highDataRange as Element, "lowExpression", "high_lowExpression",result);
        result = addChildValueToMapWithName(highDataRange as Element, "highExpression", "high_highExpression",result);
    }

    const valueDisplayElement = getChildNodeByName(meterPlot, "valueDisplay");
    if (valueDisplayElement !== null) {
        const valueDisplay = createValueDisplay(valueDisplayElement as Element);
        if (!valueDisplay.isEmpty()){
            result = result.set("valueDisplay", valueDisplay);
        }
    }

    const plot = getChildNodeByName(meterPlot, "plot");
    if (plot !== null) {
        result = result.set("plot", createPlot(plot as Element));
    }
    return result;
}


const createMeterPlot = (meterPlot: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addAttributeToMap(meterPlot, "shape", result);
    result = addAttributeToMap(meterPlot, "angle", result);
    result = addAttributeToMap(meterPlot, "units", result);
    result = addAttributeToMap(meterPlot, "tickInterval", result);
    result = addAttributeToMap(meterPlot, "meterColor", result);
    result = addAttributeToMap(meterPlot, "needleColor", result);
    result = addAttributeToMap(meterPlot, "tickColor", result);
    result = addAttributeToMap(meterPlot, "tickCount", result);

    const tickLabelFontElement = getChildNodeByName(meterPlot, "tickLabelFont");
    if (tickLabelFontElement !== null) {
        const fontElement = getChildNodeByName(tickLabelFontElement, "font");
        if (fontElement !== null){
            const font = createFont(fontElement as Element);
            if (!font.isEmpty()){
                result = result.set("font", font);
            }
        }
    }

    const dataRangeElement = getChildNodeByName(meterPlot, "dataRange");
    if (dataRangeElement !== null){
        result = addChildValueToMap(dataRangeElement as Element, "lowExpression",result);
        result = addChildValueToMap(dataRangeElement as Element, "highExpression",result);
    }

    const valueDisplayElement = getChildNodeByName(meterPlot, "valueDisplay");
    if (valueDisplayElement !== null) {
        const valueDisplay = createValueDisplay(valueDisplayElement as Element);
        if (!valueDisplay.isEmpty()){
            result = result.set("valueDisplay", valueDisplay);
        }
    }

    const plot = getChildNodeByName(meterPlot, "plot");
    if (plot !== null) {
        result = result.set("plot", createPlot(plot as Element));
    }

    meterPlot.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "meterInterval" ){
            const meterIntervalElement = element as Element;
            const meterInterval = createMeterInterval(meterIntervalElement);
            result = result.updateIn(["meterIntervals"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(meterInterval));
        } 
    });

    return result;
}

const createMeterInterval = (meterInterval: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    result = addAttributeToMap(meterInterval, "label", result);
    result = addAttributeToMap(meterInterval, "color", result);
    result = addFloatAttributeToMap(meterInterval, "alpha", result);

    const dataRangeElement = getChildNodeByName(meterInterval, "dataRange");
    if (dataRangeElement !== null){
        result = addChildValueToMap(dataRangeElement as Element, "lowExpression",result);
        result = addChildValueToMap(dataRangeElement as Element, "highExpression",result);
    }
    return result;
}

const createValueDisplay = (valueDisplayElement : Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addAttributeToMap(valueDisplayElement, "color", result);
    result = addAttributeToMap(valueDisplayElement, "mask", result);
    const fontElement = getChildNodeByName(valueDisplayElement, "font");
    if (fontElement !== null){
        const font = createFont(fontElement as Element);
        if (!font.isEmpty()){
            result = result.set("font", font);
        }
    }
    return result;
}

const createCandleStickPlot = (candleStickPlot: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addBooleanAttributeToMap(candleStickPlot, "isShowVolume", result);

    result = addChildValueToMap(candleStickPlot, "timeAxisLabelExpression", result);
    result = addChildValueToMap(candleStickPlot, "valueAxisLabelExpression", result);
    result = addChildValueToMap(candleStickPlot, "domainAxisMinValueExpression", result);
    result = addChildValueToMap(candleStickPlot, "domainAxisMaxValueExpression", result);
    result = addChildValueToMap(candleStickPlot, "rangeAxisMinValueExpression", result);
    result = addChildValueToMap(candleStickPlot, "rangeAxisMaxValueExpression", result);
  
    const timeAxisFormatElement = getChildNodeByName(candleStickPlot, "timeAxisFormat");
    if (timeAxisFormatElement !== null) {
        const timeAxisFormat = createTimeAxisFormat(timeAxisFormatElement as Element);
        if (!timeAxisFormat.isEmpty()){
            result = result.set("timeAxisFormat", timeAxisFormat);
        }
    }

    const valueAxisFormatElement = getChildNodeByName(candleStickPlot, "valueAxisFormat");
    if (valueAxisFormatElement !== null) {
        const valueAxisFormat = createValueAxisFormat(valueAxisFormatElement as Element);
        if (!valueAxisFormat.isEmpty()){
            result = result.set("valueAxisFormat", valueAxisFormat);
        }
    }

    const plot = getChildNodeByName(candleStickPlot, "plot");
    if (plot !== null) {
        result = result.set("plot", createPlot(plot as Element));
    }

    return result;
}

const createHighLowPlot = (highLowPlot: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addBooleanAttributeToMap(highLowPlot, "isShowCloseTicks", result);
    result = addBooleanAttributeToMap(highLowPlot, "isShowOpenTicks", result);

    result = addChildValueToMap(highLowPlot, "timeAxisLabelExpression", result);
    result = addChildValueToMap(highLowPlot, "valueAxisLabelExpression", result);
    result = addChildValueToMap(highLowPlot, "domainAxisMinValueExpression", result);
    result = addChildValueToMap(highLowPlot, "domainAxisMaxValueExpression", result);
    result = addChildValueToMap(highLowPlot, "rangeAxisMinValueExpression", result);
    result = addChildValueToMap(highLowPlot, "rangeAxisMaxValueExpression", result);
  
    const timeAxisFormatElement = getChildNodeByName(highLowPlot, "timeAxisFormat");
    if (timeAxisFormatElement !== null) {
        const timeAxisFormat = createTimeAxisFormat(timeAxisFormatElement as Element);
        if (!timeAxisFormat.isEmpty()){
            result = result.set("timeAxisFormat", timeAxisFormat);
        }
    }

    const valueAxisFormatElement = getChildNodeByName(highLowPlot, "valueAxisFormat");
    if (valueAxisFormatElement !== null) {
        const valueAxisFormat = createValueAxisFormat(valueAxisFormatElement as Element);
        if (!valueAxisFormat.isEmpty()){
            result = result.set("valueAxisFormat", valueAxisFormat);
        }
    }

    const plot = getChildNodeByName(highLowPlot, "plot");
    if (plot !== null) {
        result = result.set("plot", createPlot(plot as Element));
    }

    return result;
}

const createTimeSeriesPlot = (timeSeriesPlot: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addBooleanAttributeToMap(timeSeriesPlot, "isShowLines", result);
    result = addBooleanAttributeToMap(timeSeriesPlot, "isShowShapes", result);

    result = addChildValueToMap(timeSeriesPlot, "timeAxisLabelExpression", result);
    result = addChildValueToMap(timeSeriesPlot, "valueAxisLabelExpression", result);
    result = addChildValueToMap(timeSeriesPlot, "domainAxisMinValueExpression", result);
    result = addChildValueToMap(timeSeriesPlot, "domainAxisMaxValueExpression", result);
    result = addChildValueToMap(timeSeriesPlot, "rangeAxisMinValueExpression", result);
    result = addChildValueToMap(timeSeriesPlot, "rangeAxisMaxValueExpression", result);
  
    const timeAxisFormatElement = getChildNodeByName(timeSeriesPlot, "timeAxisFormat");
    if (timeAxisFormatElement !== null) {
        const timeAxisFormat = createTimeAxisFormat(timeAxisFormatElement as Element);
        if (!timeAxisFormat.isEmpty()){
            result = result.set("timeAxisFormat", timeAxisFormat);
        }
    }

    const valueAxisFormatElement = getChildNodeByName(timeSeriesPlot, "valueAxisFormat");
    if (valueAxisFormatElement !== null) {
        const valueAxisFormat = createValueAxisFormat(valueAxisFormatElement as Element);
        if (!valueAxisFormat.isEmpty()){
            result = result.set("valueAxisFormat", valueAxisFormat);
        }
    }

    const plot = getChildNodeByName(timeSeriesPlot, "plot");
    if (plot !== null) {
        result = result.set("plot", createPlot(plot as Element));
    }

    return result;
}

const createMultiaxisPlot = (multiaxisPlot: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    const plot = getChildNodeByName(multiaxisPlot, "plot");
    if (plot !== null) {
        result = result.set("plot", createPlot(plot as Element));
    }

    const objectCounter : IObjectCounter = {
        objectID :  1,
        detailID :  0,
        groupIDs : Map<string, {footer: number, header: number} | null>(),
        uuidsMap : new Set<string>(),

        addUuid(uuid) {
            this.uuidsMap.add(uuid);
        },

        uniqueID(str : string) {
            return str + (this.objectID++);
        },

        uniqueDetailId() {
            return this.detailID++;
        },

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        uniqueGroupBandId(groupName: string, isHeader: boolean){
            return 0;
        }
    }
    multiaxisPlot.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "axis" ){
            const axisElement = element as Element;
            axisElement.childNodes.forEach(axisChild => {
                const subChartName = axisChild.nodeName;
                if (isChart(subChartName)){
                    const subChart = createBaseChartElement(axisChild as Element, subChartName, objectCounter);
                    let axis = Map<string, any>();
                    axis = axis.set("subchart", subChart);
                    axis = addAttributeToMap(axisElement, "position", axis);
                    result = result.updateIn(["axis"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(axis));
                }
            });
        } 
    });


    return result;
}


const createBubblePlot = (bubblePlot: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    result = addAttributeToMap(bubblePlot, "scaleType", result);

    result = addChildValueToMap(bubblePlot, "xAxisLabelExpression", result);
    result = addChildValueToMap(bubblePlot, "yAxisLabelExpression", result);
    result = addChildValueToMap(bubblePlot, "domainAxisMinValueExpression", result);
    result = addChildValueToMap(bubblePlot, "domainAxisMaxValueExpression", result);
    result = addChildValueToMap(bubblePlot, "rangeAxisMinValueExpression", result);
    result = addChildValueToMap(bubblePlot, "rangeAxisMaxValueExpression", result);
  
    const xAxisFormatElement = getChildNodeByName(bubblePlot, "xAxisFormat");
    if (xAxisFormatElement !== null) {
        const xAxisFormat = createXAxisFormat(xAxisFormatElement as Element);
        if (!xAxisFormat.isEmpty()){
            result = result.set("xAxisFormat", xAxisFormat);
        }
    }

    const yAxisFormatElement = getChildNodeByName(bubblePlot, "yAxisFormat");
    if (yAxisFormatElement !== null) {
        const yAxisFormat = createYAxisFormat(yAxisFormatElement as Element);
        if (!yAxisFormat.isEmpty()){
            result = result.set("yAxisFormat", yAxisFormat);
        }
    }

    const plot = getChildNodeByName(bubblePlot, "plot");
    if (plot !== null) {
        result = result.set("plot", createPlot(plot as Element));
    }

    return result;
}

const createScatterPlot = (scatterPlot: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addBooleanAttributeToMap(scatterPlot, "isShowLines", result);
    result = addBooleanAttributeToMap(scatterPlot, "isShowShapes", result);

    result = addChildValueToMap(scatterPlot, "xAxisLabelExpression", result);
    result = addChildValueToMap(scatterPlot, "yAxisLabelExpression", result);
    result = addChildValueToMap(scatterPlot, "domainAxisMinValueExpression", result);
    result = addChildValueToMap(scatterPlot, "domainAxisMaxValueExpression", result);
    result = addChildValueToMap(scatterPlot, "rangeAxisMinValueExpression", result);
    result = addChildValueToMap(scatterPlot, "rangeAxisMaxValueExpression", result);
  
    const xAxisFormatElement = getChildNodeByName(scatterPlot, "xAxisFormat");
    if (xAxisFormatElement !== null) {
        const xAxisFormat = createXAxisFormat(xAxisFormatElement as Element);
        if (!xAxisFormat.isEmpty()){
            result = result.set("xAxisFormat", xAxisFormat);
        }
    }

    const yAxisFormatElement = getChildNodeByName(scatterPlot, "yAxisFormat");
    if (yAxisFormatElement !== null) {
        const yAxisFormat = createYAxisFormat(yAxisFormatElement as Element);
        if (!yAxisFormat.isEmpty()){
            result = result.set("yAxisFormat", yAxisFormat);
        }
    }

    const plot = getChildNodeByName(scatterPlot, "plot");
    if (plot !== null) {
        result = result.set("plot", createPlot(plot as Element));
    }

    return result;
}

const createLinePlot = (linePlot: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addBooleanAttributeToMap(linePlot, "isShowLines", result);
    result = addBooleanAttributeToMap(linePlot, "isShowShapes", result);

    result = addChildValueToMap(linePlot, "categoryAxisLabelExpression", result);
    result = addChildValueToMap(linePlot, "valueAxisLabelExpression", result);
    result = addChildValueToMap(linePlot, "domainAxisMinValueExpression", result);
    result = addChildValueToMap(linePlot, "domainAxisMaxValueExpression", result);
    result = addChildValueToMap(linePlot, "rangeAxisMinValueExpression", result);
    result = addChildValueToMap(linePlot, "rangeAxisMaxValueExpression", result);
  
    const categoryAxisFormatElement = getChildNodeByName(linePlot, "categoryAxisFormat");
    if (categoryAxisFormatElement !== null) {
        const categoryAxisFormat = createCategoryAxisFormat(categoryAxisFormatElement as Element);
        if (!categoryAxisFormat.isEmpty()){
            result = result.set("categoryAxisFormat", categoryAxisFormat);
        }
    }

    const valueAxisFormatElement = getChildNodeByName(linePlot, "valueAxisFormat");
    if (valueAxisFormatElement !== null) {
        const valueAxisFormat = createValueAxisFormat(valueAxisFormatElement as Element);
        if (!valueAxisFormat.isEmpty()){
            result = result.set("valueAxisFormat", valueAxisFormat);
        }
    }

    const plot = getChildNodeByName(linePlot, "plot");
    if (plot !== null) {
        result = result.set("plot", createPlot(plot as Element));
    }

    return result;
}

const createAreaPlot = (areaPlot: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    result = addChildValueToMap(areaPlot, "categoryAxisLabelExpression", result);
    result = addChildValueToMap(areaPlot, "valueAxisLabelExpression", result);
    result = addChildValueToMap(areaPlot, "domainAxisMinValueExpression", result);
    result = addChildValueToMap(areaPlot, "domainAxisMaxValueExpression", result);
    result = addChildValueToMap(areaPlot, "rangeAxisMinValueExpression", result);
    result = addChildValueToMap(areaPlot, "rangeAxisMaxValueExpression", result);
  
    const plot = getChildNodeByName(areaPlot, "plot");
    if (plot !== null) {
        result = result.set("plot", createPlot(plot as Element));
    }

    const categoryAxisFormatElement = getChildNodeByName(areaPlot, "categoryAxisFormat");
    if (categoryAxisFormatElement !== null) {
        const categoryAxisFormat = createCategoryAxisFormat(categoryAxisFormatElement as Element);
        if (!categoryAxisFormat.isEmpty()){
            result = result.set("categoryAxisFormat", categoryAxisFormat);
        }
    }

    const valueAxisFormatElement = getChildNodeByName(areaPlot, "valueAxisFormat");
    if (valueAxisFormatElement !== null) {
        const valueAxisFormat = createValueAxisFormat(valueAxisFormatElement as Element);
        if (!valueAxisFormat.isEmpty()){
            result = result.set("valueAxisFormat", valueAxisFormat);
        }
    }

    return result;
}


const createBarPlot = (barPlot: Element) : Map<string, any> => {
    let result = createBaseBarPlot(barPlot);
    result = addBooleanAttributeToMap(barPlot, "isShowTickMarks", result);
    result = addBooleanAttributeToMap(barPlot, "isShowTickLabels", result);
    return result;
}

const createBar3DPlot = (barPlot: Element) : Map<string, any> => {
    let result = createBaseBarPlot(barPlot);
    result = addBooleanAttributeToMap(barPlot, "xOffset", result);
    result = addBooleanAttributeToMap(barPlot, "yOffset", result);
    return result;
}

const createBaseBarPlot = (barPlot: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    result = addBooleanAttributeToMap(barPlot, "isShowLabels", result);

    result = addChildValueToMap(barPlot, "categoryAxisLabelExpression", result);
    result = addChildValueToMap(barPlot, "valueAxisLabelExpression", result);
    result = addChildValueToMap(barPlot, "domainAxisMinValueExpression", result);
    result = addChildValueToMap(barPlot, "domainAxisMaxValueExpression", result);
    result = addChildValueToMap(barPlot, "rangeAxisMinValueExpression", result);
    result = addChildValueToMap(barPlot, "rangeAxisMaxValueExpression", result);
  
    const plot = getChildNodeByName(barPlot, "plot");
    if (plot !== null) {
        result = result.set("plot", createPlot(plot as Element));
    }

    const itemLabel = getChildNodeByName(barPlot, "itemLabel");
    if (itemLabel !== null) {
        result = result.set("itemLabel", createItemLabel(itemLabel as Element));
    }

    const categoryAxisFormatElement = getChildNodeByName(barPlot, "categoryAxisFormat");
    if (categoryAxisFormatElement !== null) {
        const categoryAxisFormat = createCategoryAxisFormat(categoryAxisFormatElement as Element);
        if (!categoryAxisFormat.isEmpty()){
            result = result.set("categoryAxisFormat", categoryAxisFormat);
        }
    }

    const valueAxisFormatElement = getChildNodeByName(barPlot, "valueAxisFormat");
    if (valueAxisFormatElement !== null) {
        const valueAxisFormat = createValueAxisFormat(valueAxisFormatElement as Element);
        if (!valueAxisFormat.isEmpty()){
            result = result.set("valueAxisFormat", valueAxisFormat);
        }
    }

    return result; 
}

const createXAxisFormat = (xAxisFormat: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    const axisFormatElement = getChildNodeByName(xAxisFormat, "axisFormat") as Element;
    const axisFormat = createAxisFormat(axisFormatElement);
    if (!axisFormat.isEmpty()){
        result = result.set("axisFormat", axisFormat);
    }

    return result;
}

const createYAxisFormat = (xAxisFormat: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    const axisFormatElement = getChildNodeByName(xAxisFormat, "axisFormat") as Element;
    const axisFormat = createAxisFormat(axisFormatElement);
    if (!axisFormat.isEmpty()){
        result = result.set("axisFormat", axisFormat);
    }

    return result;
}

const createCategoryAxisFormat = (categoryAxisFormat: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    result = addAttributeToMap(categoryAxisFormat, "labelRotation", result);
    const axisFormatElement = getChildNodeByName(categoryAxisFormat, "axisFormat") as Element;
    const axisFormat = createAxisFormat(axisFormatElement);
    if (!axisFormat.isEmpty()){
        result = result.set("axisFormat", axisFormat);
    }

    return result;
}

const createTimeAxisFormat = (categoryAxisFormat: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    const axisFormatElement = getChildNodeByName(categoryAxisFormat, "axisFormat") as Element;
    const axisFormat = createAxisFormat(axisFormatElement);
    if (!axisFormat.isEmpty()){
        result = result.set("axisFormat", axisFormat);
    }
    return result;
}

const createValueAxisFormat = (categoryAxisFormat: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    const axisFormatElement = getChildNodeByName(categoryAxisFormat, "axisFormat") as Element;
    const axisFormat = createAxisFormat(axisFormatElement);
    if (!axisFormat.isEmpty()){
        result = result.set("axisFormat", axisFormat);
    }
    return result;
}

const createAxisFormat = (axisFormat: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    result = addAttributeToMap(axisFormat, "labelColor", result);
    result = addAttributeToMap(axisFormat, "tickLabelColor", result);
    result = addAttributeToMap(axisFormat, "tickLabelMask", result);
    result = addAttributeToMap(axisFormat, "axisLineColor", result);

    result = addBooleanAttributeToMap(axisFormat, "verticalTickLabels", result);

  
    const labelFont = getChildNodeByName(axisFormat, "labelFont");
    if (labelFont !== null) {
        const fontElement = getChildNodeByName(labelFont, "font");
        if (fontElement !== null){
            const font = createFont(fontElement as Element)
            if (!font.isEmpty()){
             result = result.set("labelFont", font);
            }
        }
    }

    const tickLabelFont = getChildNodeByName(axisFormat, "tickLabelFont");
    if (tickLabelFont !== null) {
        const fontElement = getChildNodeByName(tickLabelFont, "font");
        if (fontElement !== null){
            const font = createFont(fontElement as Element)
            if (!font.isEmpty()){
             result = result.set("tickLabelFont", font);
            }
        }
    }

    return result; 
}

const createGanttDataset = (ganttDataset: Element, objectCounter: IObjectCounter) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    const datasetNode = getChildNodeByName(ganttDataset, "dataset");
    if (datasetNode !== null){
        const dataset : Map<string, any> = createChartCrosstabDataset(datasetNode as Element, objectCounter);
        if (!dataset.isEmpty()){
            result = result.set("dataset", dataset);
        }
    }

    ganttDataset.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "ganttSeries" ){
            const ganttSeriesElement = element as Element;
            const ganttSeries = createGanttSeries(ganttSeriesElement);
            result = result.updateIn(["ganttSeries"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(ganttSeries));
        } 
    });
    return result; 
}

const createGanttSeries = (ganttSeriesElement: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    result = addChildValueToMap(ganttSeriesElement, "seriesExpression", result);
    result = addChildValueToMap(ganttSeriesElement, "taskExpression", result);
    result = addChildValueToMap(ganttSeriesElement, "subtaskExpression", result);
    result = addChildValueToMap(ganttSeriesElement, "startDateExpression", result);
    result = addChildValueToMap(ganttSeriesElement, "endDateExpression", result);
    result = addChildValueToMap(ganttSeriesElement, "percentExpression", result);

    return result;
}

const createPieDataset = (pieDataset: Element, objectCounter: IObjectCounter) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    result = addFloatAttributeToMap(pieDataset, "minPercentage", result);
    result = addIntAttributeToMap(pieDataset, "maxCount", result);
    result = addChildValueToMap(pieDataset , "otherKeyExpression", result);
    result = addChildValueToMap(pieDataset , "otherLabelExpression", result);

    let pieSeries = List<Map<string, any>>();
    
    const hasRootKeyExpression = getChildNodeByName(pieDataset, 'keyExpression') !== null;
    const hasRootValueExpression = hasRootKeyExpression || getChildNodeByName(pieDataset, 'valueExpression') !== null;
    const hasRootLabelExpression = hasRootValueExpression || getChildNodeByName(pieDataset, 'labelExpression') !== null;  
    const hasRootHyperlink = hasRootLabelExpression || getChildNodeByName(pieDataset, 'sectionHyperlink') !== null;

    if (hasRootHyperlink) {
        //has root hyperlink is in or with the other flags so if one is true also this one is true. This means that this series
        //it is a root level of the dataset, the piechart with only a series keep it at root level otherwise it create a list of
        //pie series elements. To keep the model uniform we conver the root level still to a list of a single element
        pieSeries = pieSeries.push(createPieSeries(pieDataset))
    } else {
        pieDataset.childNodes.forEach(element => {
            const elementName = element.nodeName;
            if (elementName === "pieSeries" ){
                const pieSeriesElement = element as Element;
                pieSeries = pieSeries.push(createPieSeries(pieSeriesElement))
            } 
        });
    }

    result = result.setIn(["pieSeries"], pieSeries);


    const otherSectionHyperLinkNode = getChildNodeByName(pieDataset, "otherSectionHyperlink");
    if (otherSectionHyperLinkNode !== null){
        let otherSectionHyperLink = Map<string, any>();
        otherSectionHyperLink = createSectionHyperLinkForElement(otherSectionHyperLinkNode as Element, otherSectionHyperLink);
        if (!otherSectionHyperLink.isEmpty()){
            result = result.set("otherSectionHyperlink", otherSectionHyperLink);
        }
    }

    const datasetNode = getChildNodeByName(pieDataset, "dataset");
    if (datasetNode !== null){
        const dataset : Map<string, any> = createChartCrosstabDataset(datasetNode as Element, objectCounter);
        if (!dataset.isEmpty()){
            result = result.set("dataset", dataset);
        }
    }
    return result; 
}

const createPieSeries = (pieSeries: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addChildValueToMap(pieSeries, "keyExpression", result);
    result = addChildValueToMap(pieSeries, "valueExpression", result);
    result = addChildValueToMap(pieSeries, "labelExpression", result);
    const hyperlink = getChildNodeByName(pieSeries, "sectionHyperlink");
    if (hyperlink !== null) {
        result = createSectionHyperLinkForElement(hyperlink as Element, result);
    }
    return result; 
}

const createPiePlot = (piePlot: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    result = addBooleanAttributeToMap(piePlot, "isShowLabels", result);
    result = addBooleanAttributeToMap(piePlot, "isCircular", result);
    result = addAttributeToMap(piePlot, "labelFormat", result);
    result = addAttributeToMap(piePlot, "legendLabelFormat", result);
  
    const plot = getChildNodeByName(piePlot, "plot");
    if (plot !== null) {
        result = result.set("plot", createPlot(plot as Element));
    }

    const itemLabel = getChildNodeByName(piePlot, "itemLabel");
    if (itemLabel !== null) {
        result = result.set("itemLabel", createItemLabel(itemLabel as Element));
    }

    return result; 
}

const createPie3DPlot = (pie3DPlot: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();

    result = addBooleanAttributeToMap(pie3DPlot, "isShowLabels", result);
    result = addBooleanAttributeToMap(pie3DPlot, "isCircular", result);
    result = addAttributeToMap(pie3DPlot, "labelFormat", result);
    result = addAttributeToMap(pie3DPlot, "legendLabelFormat", result);
    result = addAttributeToMap(pie3DPlot, "depthFactor", result);
  
    const plot = getChildNodeByName(pie3DPlot, "plot");
    if (plot !== null) {
        result = result.set("plot", createPlot(plot as Element));
    }

    const itemLabel = getChildNodeByName(pie3DPlot, "itemLabel");
    if (itemLabel !== null) {
        result = result.set("itemLabel", createItemLabel(itemLabel as Element));
    }

    return result; 
}

const createPlot = (plotElement: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addAttributeToMap(plotElement, "backcolor", result);
    result = addAttributeToMap(plotElement, "orientation", result);
    result = addAttributeToMap(plotElement, "labelRotation", result);
    result = addFloatAttributeToMap(plotElement, "backgroundAlpha", result);
    result = addFloatAttributeToMap(plotElement, "foregroundAlpha", result);
    plotElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "seriesColor" ){
            const seriesColorElement = element as Element;
            const seriesOrderValue = parseFloat(seriesColorElement.getAttribute("seriesOrder"));
            const colorValue = seriesColorElement.getAttribute("color");
            let newSeriesColor = Map<string, string | number>(); 
            newSeriesColor = newSeriesColor.set('seriesOrder', seriesOrderValue)
            newSeriesColor = newSeriesColor.set('color', colorValue);
            result = result.updateIn(["seriesColor"], (list : List<Map<string, string | number>>) => (list || List<Map<string, string | number>>()).push(newSeriesColor));
        } 
    });
    return result; 
}

const createItemLabel = (itemLabelElement: Element) : Map<string, any> => {
    let result : Map<string, any> = Map<string,any>();
    result = addAttributeToMap(itemLabelElement, "color", result);
    result = addAttributeToMap(itemLabelElement, "backgroundColor", result);

    const fontElement = getChildNodeByName(itemLabelElement, "font");
    if (fontElement !== null){
        result = result.set("font", createFont(fontElement as Element))
    }

    return result; 
}

/**
 * Hyperlink specification for pie chart sections. This hyperlink definition will be evaluated for every chart section and a image map will be created for the chart.
 * @param elementNode 
 * @param currentElementProperties 
 */
export const createSectionHyperLinkForElement = (elementNode : Element, currentElementProperties : Map<string, any>) : Map<string, any> => {
    let result : Map<string, any> = addAttributeToMap(elementNode, "hyperlinkType", currentElementProperties);
    result = addAttributeToMap(elementNode, "hyperlinkTarget", result);
    result = addChildValueToMap(elementNode, "hyperlinkReferenceExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkWhenExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkAnchorExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkPageExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkTooltipExpression", result);
    let hyperLinkParameters : List<Map<string, string>> = List<Map<string, string>>();
    elementNode.childNodes.forEach(element => {
        if (element.nodeName === "hyperlinkParameter"){
            const parameterNode = element as Element;
            const parameterName = parameterNode.getAttribute("name");
            const parameterValue = readCDataText(getChildNodeByName(parameterNode, "hyperlinkParameterExpression"));
            let parameter = Map<string, string>();
            parameter = parameter.set('name', parameterName);
            parameter = parameter.set('value', parameterValue);
            hyperLinkParameters = hyperLinkParameters.push(parameter);
        }
     });
     if (!hyperLinkParameters.isEmpty()){
         result = result.set("hyperlinkParameters", hyperLinkParameters);
     }
    return result;
}

const createBaseChart = (chartElement : Element, path: string, chartType: string, objectCounter: IObjectCounter) : Map<string, any> => {
    const reportElement = getChildNodeByName(chartElement, "reportElement");
    let result : Map<string, any> = createReportElement(reportElement as Element, path, ElementTypes.JFREECHART, objectCounter);
    result = result.set(CHART_TYPE, chartType);

    result = addAttributeToMap(chartElement, "evaluationTime", result);
    result = addAttributeToMap(chartElement, "evaluationGroup", result);
    result = addAttributeToMap(chartElement, "renderType", result);
    result = addAttributeToMap(chartElement, "theme", result);
    result = addAttributeToMap(chartElement, "customizerClass", result);
    result = addBooleanAttributeToMap(chartElement, "isShowLegend", result);

    const boxElement = getChildNodeByName(chartElement, "box");
    if (boxElement !== null){ 
        const box : Map<string, any> = createBox(boxElement as Element);
        result = result.set("box", box);
    }

    const chartTitleElement = getChildNodeByName(chartElement, "chartTitle");
    if (chartTitleElement !== null){ 
        const chartTitle : Map<string, any> = createChartTitle(chartTitleElement as Element);
        result = result.set("chartTitle", chartTitle);
    }

    const chartSubTitleElement = getChildNodeByName(chartElement, "chartSubtitle");
    if (chartSubTitleElement !== null){ 
        const chartSubTitle : Map<string, any> = createChartSubTitle(chartSubTitleElement as Element);
        result = result.set("chartSubtitle", chartSubTitle);
    }

    const chartLegendElement = getChildNodeByName(chartElement, "chartLegend");
    if (chartLegendElement !== null){ 
        const chartLegend : Map<string, any> = createChartLegend(chartLegendElement as Element);
        result = result.set("chartLegend", chartLegend);
    }

    result = createHyperLinkForElement(chartElement, result);
    return result;
}

const createSpiderHyperLinkForElement = (elementNode : Element, currentElementProperties : Map<string, any>) : Map<string, any> => {
    let result : Map<string, any> = addAttributeToMap(elementNode, "hyperlinkType", currentElementProperties);
    result = addAttributeToMap(elementNode, "hyperlinkTarget", result);
    result = addIntAttributeToMap(elementNode, "bookmarkLevel", result);
    result = addChildValueToMap(elementNode, "anchorNameExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkReferenceExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkWhenExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkAnchorExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkPageExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkTooltipExpression", result);

    let hyperLinkParameters : List<{name: string, value: string}> = List<{name: string, value: string}>();
    elementNode.childNodes.forEach(element => {
        if (element.nodeName === "hyperlinkParameter"){
            const parameterNode = element as Element;
            const parameterName = parameterNode.getAttribute("name");
            const parameterValue = readCDataText(getChildNodeByName(parameterNode, "hyperlinkParameterExpression"));
            hyperLinkParameters = hyperLinkParameters.push({name: parameterName, value: parameterValue});
        }
     });
     if (!hyperLinkParameters.isEmpty()){
         result = result.set("hyperlinkParameters", hyperLinkParameters);
     }
    return result;
}