/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const ClientJobAlertRecipientEnum = new PEnum({
    id: 'ClientJobAlertRecipient',   isEmptyUndefined: true,
    items: [
        { key: '', value: '' },
        { key: 'NONE', value: 'ClientJobAlertRecipient.NONE' },
        { key: 'OWNER', value: 'ClientJobAlertRecipient.OWNER' },
        { key: 'ADMIN', value: 'ClientJobAlertRecipient.ADMIN' },
        { key: 'OWNER_AND_ADMIN', value: 'ClientJobAlertRecipient.OWNER_AND_ADMIN' }
    ]
});

