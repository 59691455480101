/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const ScatterChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
<svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
   <defs>
      <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
         <path d="M0 0h270v175H0V0z" />
      </clipPath>
      <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
         <path d="M32 4v134h230V4z" />
      </clipPath>
   </defs>
   <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
      <path fill="none" d="M15.51 157.188L254.49 157.188" clipPath="url(#clipPath1)" />
      <path fill="none" d="M15.51 173.5L254.49 173.5" clipPath="url(#clipPath1)" />
      <path fill="none" d="M15.51 173.5L15.51 157.188" clipPath="url(#clipPath1)" />
      <path fill="none" d="M254.49 173.5L254.49 157.188" clipPath="url(#clipPath1)" />
      <path fill="none" stroke="#F55" strokeLinejoin="bevel" d="M19.01 165.344h14" clipPath="url(#clipPath1)" />
      <path fill="#F55" stroke="none" strokeLinejoin="bevel" d="M23.01 162.344h6v6h-6v-6z" clipPath="url(#clipPath1)" />
      <path fill="none" stroke="#F55" d="M23.01 162.344h6v6h-6v-6z" clipPath="url(#clipPath1)" />
      <text x="37.01" y="168.113" stroke="none" clipPath="url(#clipPath1)">Double Freight</text>
      <path fill="none" stroke="#55F" strokeLinejoin="bevel" d="M102.22 165.344h14" clipPath="url(#clipPath1)" />
      <path fill="#55F" stroke="none" strokeLinejoin="bevel" d="M112.22 165.344a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath1)" />
      <path fill="none" stroke="#55F" d="M112.22 165.344a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath1)" />
      <text x="120.221" y="168.113" stroke="none" clipPath="url(#clipPath1)">Normal Freight</text>
      <path fill="none" stroke="#5F5" strokeLinejoin="bevel" d="M186.17 165.344h14" clipPath="url(#clipPath1)" />
      <path fill="#5F5" fillRule="evenodd" stroke="none" strokeLinejoin="bevel" d="M193.17 162.344l3 6h-6z" clipPath="url(#clipPath1)" />
      <path fill="none" fillRule="evenodd" stroke="#5F5" d="M193.17 162.344l3 6h-6z" clipPath="url(#clipPath1)" />
      <text x="204.17" y="168.113" stroke="none" clipPath="url(#clipPath1)">Half Freight</text>
      <path fill="none" strokeWidth="0.5" d="M32 138L262 138" clipPath="url(#clipPath1)" />
      <text x="57.958" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.500</text>
      <path fill="none" strokeWidth="0.5" d="M71.954 140L71.954 138" clipPath="url(#clipPath1)" />
      <text x="139.001" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.750</text>
      <path fill="none" strokeWidth="0.5" d="M152.997 140L152.997 138" clipPath="url(#clipPath1)" />
      <text x="220.044" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">11.000</text>
      <path fill="none" strokeWidth="0.5" d="M234.04 140L234.04 138" clipPath="url(#clipPath1)" />
      <path fill="none" strokeWidth="0.5" d="M32 4L32 138" clipPath="url(#clipPath1)" />
      <text x="20.91" y="140.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
      <path fill="none" strokeWidth="0.5" d="M30 138L32 138" clipPath="url(#clipPath1)" />
      <text x="15.82" y="126.125" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">50</text>
      <path fill="none" strokeWidth="0.5" d="M30 123.355L32 123.355" clipPath="url(#clipPath1)" />
      <text x="10.73" y="111.48" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">100</text>
      <path fill="none" strokeWidth="0.5" d="M30 108.71L32 108.71" clipPath="url(#clipPath1)" />
      <text x="10.73" y="96.835" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">150</text>
      <path fill="none" strokeWidth="0.5" d="M30 94.065L32 94.065" clipPath="url(#clipPath1)" />
      <text x="10.73" y="82.19" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">200</text>
      <path fill="none" strokeWidth="0.5" d="M30 79.421L32 79.421" clipPath="url(#clipPath1)" />
      <text x="10.73" y="67.545" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">250</text>
      <path fill="none" strokeWidth="0.5" d="M30 64.776L32 64.776" clipPath="url(#clipPath1)" />
      <text x="10.73" y="52.9" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">300</text>
      <path fill="none" strokeWidth="0.5" d="M30 50.131L32 50.131" clipPath="url(#clipPath1)" />
      <text x="10.73" y="38.255" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">350</text>
      <path fill="none" strokeWidth="0.5" d="M30 35.486L32 35.486" clipPath="url(#clipPath1)" />
      <text x="10.73" y="23.61" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">400</text>
      <path fill="none" strokeWidth="0.5" d="M30 20.841L32 20.841" clipPath="url(#clipPath1)" />
      <text x="10.73" y="8.966" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">450</text>
      <path fill="none" strokeWidth="0.5" d="M30 6.196L32 6.196" clipPath="url(#clipPath1)" />
   </g>
   <g fill="none" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
      <path d="M71.954 4L71.954 138" clipPath="url(#clipPath2)" />
      <path d="M152.997 4L152.997 138" clipPath="url(#clipPath2)" />
      <path d="M234.04 4L234.04 138" clipPath="url(#clipPath2)" />
      <path d="M32 138L262 138" clipPath="url(#clipPath2)" />
      <path d="M32 123.355L262 123.355" clipPath="url(#clipPath2)" />
      <path d="M32 108.71L262 108.71" clipPath="url(#clipPath2)" />
      <path d="M32 94.065L262 94.065" clipPath="url(#clipPath2)" />
      <path d="M32 79.421L262 79.421" clipPath="url(#clipPath2)" />
      <path d="M32 64.776L262 64.776" clipPath="url(#clipPath2)" />
      <path d="M32 50.131L262 50.131" clipPath="url(#clipPath2)" />
      <path d="M32 35.486L262 35.486" clipPath="url(#clipPath2)" />
      <path d="M32 20.841L262 20.841" clipPath="url(#clipPath2)" />
      <path d="M32 6.196L262 6.196" clipPath="url(#clipPath2)" />
   </g>
   <g fill="#5F5" stroke="#5F5" strokeLinejoin="bevel" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
      <path fill="none" d="M42.455 133.631L55.097 132.315" clipPath="url(#clipPath2)" />
      <path fill="none" d="M55.097 132.315L78.762 135.478" clipPath="url(#clipPath2)" />
      <path fill="none" d="M78.762 135.478L82.004 136.811" clipPath="url(#clipPath2)" />
      <path fill="none" d="M82.004 136.811L141.975 134.695" clipPath="url(#clipPath2)" />
      <path fill="none" d="M141.975 134.695L163.371 137.839" clipPath="url(#clipPath2)" />
      <path fill="none" d="M163.371 137.839L175.365 135.106" clipPath="url(#clipPath2)" />
      <path fill="none" d="M175.365 135.106L178.283 124.695" clipPath="url(#clipPath2)" />
      <path fill="none" d="M178.283 124.695L195.464 137.584" clipPath="url(#clipPath2)" />
      <path fill="none" d="M195.464 137.584L200.975 137.814" clipPath="url(#clipPath2)" />
      <path fill="none" d="M200.975 137.814L206.81 128.661" clipPath="url(#clipPath2)" />
      <path fill="none" d="M206.81 128.661L213.617 133.385" clipPath="url(#clipPath2)" />
      <path fill="none" d="M213.617 133.385L220.425 130.742" clipPath="url(#clipPath2)" />
      <path fill="none" d="M220.425 130.742L229.502 106.095" clipPath="url(#clipPath2)" />
      <path fill="none" d="M229.502 106.095L240.199 137.536" clipPath="url(#clipPath2)" />
      <path fill="none" d="M240.199 137.536L251.546 137.952" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M42.455 129.263L55.097 126.63" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M55.097 126.63L78.762 132.956" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M78.762 132.956L82.004 135.622" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M82.004 135.622L141.975 131.389" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M141.975 131.389L163.371 137.678" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M163.371 137.678L175.365 132.212" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M175.365 132.212L178.283 111.39" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M178.283 111.39L195.464 137.168" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M195.464 137.168L200.975 137.628" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M200.975 137.628L206.81 119.322" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M206.81 119.322L213.617 128.771" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M213.617 128.771L220.425 123.484" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M220.425 123.484L229.502 74.189" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M229.502 74.189L240.199 137.071" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" d="M240.199 137.071L251.546 137.903" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M42.455 120.526L55.097 115.259" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M55.097 115.259L78.762 127.913" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M78.762 127.913L82.004 133.243" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M82.004 133.243L141.975 124.779" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M141.975 124.779L163.371 137.356" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M163.371 137.356L175.365 126.425" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M175.365 126.425L178.283 84.781" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M178.283 84.781L195.464 136.336" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M195.464 136.336L200.975 137.256" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M200.975 137.256L206.81 100.644" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M206.81 100.644L213.617 119.542" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M213.617 119.542L220.425 108.968" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M220.425 108.968L229.502 10.379" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M229.502 10.379L240.199 136.143" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" d="M240.199 136.143L251.546 137.807" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" d="M42.455 130.631l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M42.455 130.631l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M55.097 129.315l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M55.097 129.315l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M78.762 132.478l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M78.762 132.478l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M82.004 133.81l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M82.004 133.81l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M141.975 131.695l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M141.975 131.695l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M163.37 134.839l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M163.37 134.839l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M175.365 132.106l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M175.365 132.106l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M178.283 121.695l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M178.283 121.695l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M195.464 134.584l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M195.464 134.584l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M200.975 134.814l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M200.975 134.814l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M206.81 125.661l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M206.81 125.661l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M213.617 130.385l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M213.617 130.385l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M220.425 127.742l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M220.425 127.742l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M229.502 103.095l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M229.502 103.095l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M240.2 134.536l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M240.2 134.536l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fillRule="evenodd" stroke="none" strokeLinejoin="miter" d="M251.546 134.952l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="none" fillRule="evenodd" strokeLinejoin="miter" d="M251.546 134.952l3 6h-6z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M45.455 129.263a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M45.455 129.263a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M58.097 126.63a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M58.097 126.63a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M81.762 132.956a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M81.762 132.956a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M85.004 135.622a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M85.004 135.622a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M144.975 131.39a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M144.975 131.39a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M166.37 137.678a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M166.37 137.678a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M178.365 132.212a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M178.365 132.212a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M181.283 111.39a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M181.283 111.39a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M198.464 137.168a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M198.464 137.168a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M203.975 137.628a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M203.975 137.628a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M209.81 119.322a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M209.81 119.322a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M216.617 128.77a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M216.617 128.77a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M223.425 123.484a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M223.425 123.484a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M232.502 74.19a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M232.502 74.19a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M243.2 137.071a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M243.2 137.071a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#55F" stroke="none" strokeLinejoin="miter" d="M254.546 137.903a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#55F" strokeLinejoin="miter" d="M254.546 137.903a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M39.455 117.526h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M39.455 117.526h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M52.097 112.26h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M52.097 112.26h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M75.762 124.913h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M75.762 124.913h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M79.004 130.243h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M79.004 130.243h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M138.975 121.779h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M138.975 121.779h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M160.37 134.356h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M160.37 134.356h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M172.365 123.425h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M172.365 123.425h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M175.283 81.78h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M175.283 81.78h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M192.464 133.336h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M192.464 133.336h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M197.975 134.256h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M197.975 134.256h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M203.81 97.644h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M203.81 97.644h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M210.617 116.542h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M210.617 116.542h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M217.425 105.968h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M217.425 105.968h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M226.502 7.379h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M226.502 7.379h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M237.2 133.143h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M237.2 133.143h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="#F55" stroke="none" strokeLinejoin="miter" d="M248.546 134.807h6v6h-6v-6z" clipPath="url(#clipPath2)" />
      <path fill="none" stroke="#F55" strokeLinejoin="miter" d="M248.546 134.807h6v6h-6v-6z" clipPath="url(#clipPath2)" />
   </g>
</svg>
   );
}
export default ScatterChartIcon;