/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { TextInput } from '@jss/js-common';
import { advancedNameValidator, advancedNotEmptyValidator } from '@jss/js-common/src/utils/validators';
import * as React from 'react';
import '../../assets/css/DataAdapterTypePicker.css';
import i18n from '../../i18n';
import { jrsUrlValidator } from '../jrsdatasource/JRSDatasourceDataAdapterEditor';

interface IXMLADataAdapterEditor {
    name?: string,
    xmlaUrl?: string,
    datasource?: string,
    catalog?: string,
    cube?: string,
    username?: string,
    password?: string,
    savePassword?: boolean,
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

export class XMLADataAdapterEditor extends React.Component<IXMLADataAdapterEditor> {

    public render() {
        return (<div className="tc-jsw-dataadapter-editor">

            <div className="tc-jsw-form-wrapper" style={{ flex: 1, alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}>

                <TextInput label={i18n.t('datasource.csv.name')}
                    help={i18n.t('datasource.hibernate.name.help')}
                    id="data-adapter-name"
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.xmla.descriptor.url')}
                    id="xmla-url-input"
                    value={this.props.xmlaUrl}
                    onChange={this.onXmlaUrlChange}
                    advancedValidator={jrsUrlValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.xmla.descriptor.datasource')}
                    id="datasource-input"
                    autoComplete="off"
                    value={this.props.datasource}
                    onChange={this.onDatasourceChange}
                    advancedValidator={advancedNotEmptyValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.xmla.descriptor.catalog')}
                    id="catalog-input"
                    autoComplete="off"
                    value={this.props.catalog}
                    onChange={this.onCatalogChange}
                    advancedValidator={advancedNotEmptyValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.xmla.descriptor.cube')}
                    id="xmla-url-input"
                    autoComplete="off"
                    value={this.props.cube}
                    onChange={this.onCubeChange}
                    advancedValidator={advancedNotEmptyValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.xmla.descriptor.username')}
                    id="username-input"
                    autoComplete="off"
                    value={this.props.username}
                    onChange={this.onUsernameChange}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.xmla.descriptor.password')}
                    id="password-input"
                    type="password"
                    autoComplete="off"
                    value={this.props.password}
                    onChange={this.onPasswordChange}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />
            </div>
        </div>);
    }

    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private onXmlaUrlChange = (str: string) => {
        this.notifyChange({ xmlaUrl: str });
    }

    private onCatalogChange = (str: string) => {
        this.notifyChange({ catalog: str });
    }

    private onDatasourceChange = (str: string) => {
        this.notifyChange({ datasource: str });
    }

    private onCubeChange = (str: string) => {
        this.notifyChange({ cube: str });
    }

    private onUsernameChange = (str: string) => {
        this.notifyChange({ username: str });
    }

    private onPasswordChange = (str: string) => {
        this.notifyChange({ password: str });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }
}
