/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

export const EXIT_JRIO_CONTEXT_EDITOR = 'EXIT_JRIO_CONTEXT_EDITOR';

export const SAVE_JRIO_CONTEXT = 'SAVE_JRIO_CONTEXT';

export const SAVE_AS_JRIO_CONTEXT = 'SAVE_AS_JRIO_CONTEXT';

export const JRIO_CONTEXT_SAVED = 'JRIO_CONTEXT_SAVED';

export const JRIO_CONTEXT_SAVED_AS = 'JRIO_CONTEXT_SAVED_AS';

export const JRIO_CONTEXT_EDITOR_DIRTY = 'JRIO_CONTEXT_EDITOR_DIRTY';

export const UPDATED_JRIO_CONTEXT_CONTENT = 'UPDATED_JRIO_CONTEXT_CONTENT';