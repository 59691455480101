/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PButtonsEnum } from "../../ui/primitive/UPButtonsEnum";

export const HorizontalTextAlignEnum = new PButtonsEnum({
    id: 'textAlignment', label: 'HorizontalTextAlign.label', inheritedPropertyId: 'textAlignment',
    items: [
        { key: 'Left', value: 'HorizontalTextAlign.Left', icon: 'textALeft' },
        { key: 'Center', value: 'HorizontalTextAlign.Center', icon: 'textACenter' },
        { key: 'Right', value: 'HorizontalTextAlign.Right', icon: 'textARight' },
        { key: 'Justified', value: 'HorizontalTextAlign.Justify', icon: 'textAJustify'}
    ]
}); 
 
