/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const BarcodeCode128Icon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    const fillColor = props.color ? props.color : 'black';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="28.2187mm" viewBox="0 0 35.946 28.2187" width="35.946mm" {...props}><g fill={fillColor} stroke="none"><rect height="23.6332" width="0.7055" x="2.1" y="0"/><rect height="23.6332" width="0.3527" x="3.1582" y="0"/><rect height="23.6332" width="1.0582" x="4.2164" y="0"/><rect height="23.6332" width="0.7055" x="5.9801" y="0"/><rect height="23.6332" width="0.7055" x="7.391" y="0"/><rect height="23.6332" width="0.7055" x="8.4492" y="0"/><rect height="23.6332" width="1.0582" x="9.8601" y="0"/><rect height="23.6332" width="0.7055" x="11.2711" y="0"/><rect height="23.6332" width="1.0582" x="12.3293" y="0"/><rect height="23.6332" width="0.3527" x="13.7402" y="0"/><rect height="23.6332" width="1.0582" x="14.4457" y="0"/><rect height="23.6332" width="0.7055" x="15.8566" y="0"/><rect height="23.6332" width="0.3527" x="17.6203" y="0"/><rect height="23.6332" width="0.3527" x="19.384" y="0"/><rect height="23.6332" width="0.7055" x="20.0894" y="0"/><rect height="23.6332" width="0.7055" x="21.5004" y="0"/><rect height="23.6332" width="0.7055" x="22.5586" y="0"/><rect height="23.6332" width="1.4109" x="23.6168" y="0"/><rect height="23.6332" width="0.3527" x="25.3804" y="0"/><rect height="23.6332" width="0.7055" x="27.1441" y="0"/><rect height="23.6332" width="0.3527" x="28.2023" y="0"/><rect height="23.6332" width="0.7055" x="29.2605" y="0"/><rect height="23.6332" width="1.0582" x="31.0242" y="0"/><rect height="23.6332" width="0.3527" x="32.4351" y="0"/><rect height="23.6332" width="0.7055" x="33.1406" y="0"/><text fontFamily="DejaVu Sans" fontSize="3.5273" textAnchor="middle" x="17.973" y="26.9117">0123456789</text></g></svg>
    );
}
export default BarcodeCode128Icon;