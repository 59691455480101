/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from '../../ui/APDescriptor';
import { PAccordeon } from '../../ui/composite/UPAccordeon';
import { HorizontalImageAlignEnum } from '../enums/HorizontalImageAlignEnum';
import { PColor } from '../../ui/primitive/UPColor';
import { PString } from '../../ui/primitive/UPString';
import { ModeEnum } from '../enums/ModeEnum';
import { LineSpacingEnum } from '../enums/LineSpacingEnum';
import { RotationEnum } from '../enums/RotationEnum';
import { PPropertiesExpression } from '../../ui/primitive/UPValueExpession';
import { PdfFieldBorderStyleEnum } from '../enums/PdfFieldBorderStyleEnum';
import { PdfFieldTypeEnum } from '../enums/PdfFieldTypeEnum';
import { VerticalTextAlignEnum } from '../enums/VerticalTextAlignEnum';
import { EdgeEnum } from '../enums/EdgeEnum';
import { HorizontalTextAlignEnum } from '../enums/HorizontalTextAlignEnum';
import { PInt } from "../../ui/primitive/UPInteger";
import { PBoolean } from '../../ui/primitive/UPBoolean';
import { ImageAnchorTypeEnum } from '../enums/ImageAnchorTypeEnum';
import { PdfFieldCheckTypeEnum } from '../enums/PdfFieldCheckTypeEnum';
import { LineStyleEnum } from '../enums/LineStyleEnum';
import { FillEnum } from '../enums/FillEnum';
import { VerticalImageAlignEnum } from '../enums/VerticalImageAlignEnum';
import { ScaleImageEnum } from '../enums/ScaleImageEnum';

export const MBarbecueCustom: APDescriptor = { type: 'MBarbecueCustom', id: 'MBarbecueCustom', path: 'properties', layouts: [
	new PAccordeon({ id: 'crosstab', label: 'crosstab', custom: true, layouts: [
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.crosstab.column.header.sort.measure.index', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.crosstab.column.header.sort.measure.index', label: `net.sf.jasperreports.crosstab.column.header.sort.measure.index`, description: `Property that provides the index of measure to be used for sorting the crosstab.`, sinceVersion: '5.5.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.crosstab.row.group.column.header', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.crosstab.row.group.column.header', label: `net.sf.jasperreports.crosstab.row.group.column.header`, description: `Property that specifies this element is the column header for a row group represented by its index.`, sinceVersion: '5.5.0'}), scopes: ['ELEMENT'] }),
	]}),
	new PAccordeon({ id: 'design', label: 'design', custom: true, layouts: [
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.allow.element.negative.x', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.allow.element.negative.x', label: `net.sf.jasperreports.allow.element.negative.x`, description: `Property that determines whether elements positioned at negative X offsets on bands, frames and other element containers are allowed in a report.`, default: 'true', sinceVersion: '6.1.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.allow.element.negative.y', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.allow.element.negative.y', label: `net.sf.jasperreports.allow.element.negative.y`, description: `Property that determines whether elements positioned at negative Y offsets on bands, frames and other element containers are allowed in a report.`, default: 'true', sinceVersion: '3.7.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.allow.element.overlap', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.allow.element.overlap', label: `net.sf.jasperreports.allow.element.overlap`, description: `A property that determines whether elements are allowed to overlap.`, default: 'true', sinceVersion: '3.5.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.backcolor', vtype: new PColor({ custom: true, id: 'net.sf.jasperreports.style.backcolor', label: `net.sf.jasperreports.style.backcolor`, description: `Property used to configure dynamic style background color for report elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.bottom.pen.lineColor', vtype: new PColor({ custom: true, id: 'net.sf.jasperreports.style.box.bottom.pen.lineColor', label: `net.sf.jasperreports.style.box.bottom.pen.lineColor`, description: `Property used to configure dynamic pen line color for report element's bottom border.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.bottom.pen.lineStyle', vtype: {...LineStyleEnum,custom: true, id: 'net.sf.jasperreports.style.box.bottom.pen.lineStyle', label: `net.sf.jasperreports.style.box.bottom.pen.lineStyle`, description: `Property used to configure dynamic pen line style for report element's bottom border.`, sinceVersion: '4.8.0'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.bottom.pen.lineWidth', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.box.bottom.pen.lineWidth', label: `net.sf.jasperreports.style.box.bottom.pen.lineWidth`, description: `Property used to configure dynamic pen line width for report element's bottom border.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.bottomPadding', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.box.bottomPadding', label: `net.sf.jasperreports.style.box.bottomPadding`, description: `Property used to configure dynamic bottom padding style for report elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.left.pen.lineColor', vtype: new PColor({ custom: true, id: 'net.sf.jasperreports.style.box.left.pen.lineColor', label: `net.sf.jasperreports.style.box.left.pen.lineColor`, description: `Property used to configure dynamic pen line color for report element's left border.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.left.pen.lineStyle', vtype: {...LineStyleEnum,custom: true, id: 'net.sf.jasperreports.style.box.left.pen.lineStyle', label: `net.sf.jasperreports.style.box.left.pen.lineStyle`, description: `Property used to configure dynamic pen line style for report element's left border.`, sinceVersion: '4.8.0'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.left.pen.lineWidth', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.box.left.pen.lineWidth', label: `net.sf.jasperreports.style.box.left.pen.lineWidth`, description: `Property used to configure dynamic pen line width for report element's left border.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.leftPadding', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.box.leftPadding', label: `net.sf.jasperreports.style.box.leftPadding`, description: `Property used to configure dynamic left padding style for report elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.padding', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.box.padding', label: `net.sf.jasperreports.style.box.padding`, description: `Property used to configure dynamic padding style for report elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.pen.lineColor', vtype: new PColor({ custom: true, id: 'net.sf.jasperreports.style.box.pen.lineColor', label: `net.sf.jasperreports.style.box.pen.lineColor`, description: `Property used to configure dynamic pen line color for report element's border.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.pen.lineStyle', vtype: {...LineStyleEnum,custom: true, id: 'net.sf.jasperreports.style.box.pen.lineStyle', label: `net.sf.jasperreports.style.box.pen.lineStyle`, description: `Property used to configure dynamic pen line style for report element's border.`, sinceVersion: '4.8.0'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.pen.lineWidth', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.box.pen.lineWidth', label: `net.sf.jasperreports.style.box.pen.lineWidth`, description: `Property used to configure dynamic pen line width for report element's border.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.right.pen.lineColor', vtype: new PColor({ custom: true, id: 'net.sf.jasperreports.style.box.right.pen.lineColor', label: `net.sf.jasperreports.style.box.right.pen.lineColor`, description: `Property used to configure dynamic pen line color for report element's right border.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.right.pen.lineStyle', vtype: {...LineStyleEnum,custom: true, id: 'net.sf.jasperreports.style.box.right.pen.lineStyle', label: `net.sf.jasperreports.style.box.right.pen.lineStyle`, description: `Property used to configure dynamic pen line style for report element's right border.`, sinceVersion: '4.8.0'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.right.pen.lineWidth', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.box.right.pen.lineWidth', label: `net.sf.jasperreports.style.box.right.pen.lineWidth`, description: `Property used to configure dynamic pen line width for report element's right border.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.rightPadding', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.box.rightPadding', label: `net.sf.jasperreports.style.box.rightPadding`, description: `Property used to configure dynamic right padding style for report elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.top.pen.lineColor', vtype: new PColor({ custom: true, id: 'net.sf.jasperreports.style.box.top.pen.lineColor', label: `net.sf.jasperreports.style.box.top.pen.lineColor`, description: `Property used to configure dynamic pen line color for report element's top border.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.top.pen.lineStyle', vtype: {...LineStyleEnum,custom: true, id: 'net.sf.jasperreports.style.box.top.pen.lineStyle', label: `net.sf.jasperreports.style.box.top.pen.lineStyle`, description: `Property used to configure dynamic pen line style for report element's top border.`, sinceVersion: '4.8.0'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.top.pen.lineWidth', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.box.top.pen.lineWidth', label: `net.sf.jasperreports.style.box.top.pen.lineWidth`, description: `Property used to configure dynamic pen line width for report element's top border.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.box.topPadding', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.box.topPadding', label: `net.sf.jasperreports.style.box.topPadding`, description: `Property used to configure dynamic top padding style for report elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.fill', vtype: {...FillEnum,custom: true, id: 'net.sf.jasperreports.style.fill', label: `net.sf.jasperreports.style.fill`, description: `Property used to configure dynamic fill pattern style for report elements.`, sinceVersion: '4.8.0'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.fontName', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.style.fontName', label: `net.sf.jasperreports.style.fontName`, description: `Property used to configure dynamic font name for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.fontSize', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.fontSize', label: `net.sf.jasperreports.style.fontSize`, description: `Property used to configure dynamic font size for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.forecolor', vtype: new PColor({ custom: true, id: 'net.sf.jasperreports.style.forecolor', label: `net.sf.jasperreports.style.forecolor`, description: `Property used to configure dynamic style forecolor for report elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.hAlign', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.style.hAlign', label: `net.sf.jasperreports.style.hAlign`, description: `Deprecated since v.6.0.2 - replaced with net.sf.jasperreports.style.hTextAlign 
or net.sf.jasperreports.style.hImageAlign.`, sinceVersion: '4.8.0', deprecated: true}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.hImageAlign', vtype: {...HorizontalImageAlignEnum,custom: true, id: 'net.sf.jasperreports.style.hImageAlign', label: `net.sf.jasperreports.style.hImageAlign`, description: `Property used to configure dynamic horizontal image alignment for report elements.`, sinceVersion: '6.0.2'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.hTextAlign', vtype: {...HorizontalTextAlignEnum,custom: true, id: 'net.sf.jasperreports.style.hTextAlign', label: `net.sf.jasperreports.style.hTextAlign`, description: `Property used to configure dynamic horizontal text alignment for report elements.`, sinceVersion: '6.0.2'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.isBlankWhenNull', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.style.isBlankWhenNull', label: `net.sf.jasperreports.style.isBlankWhenNull`, description: `Property used to specify whether null content of a report element should be rendered as blank space.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.isBold', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.style.isBold', label: `net.sf.jasperreports.style.isBold`, description: `Boolean property used to configure dynamic font bold style for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.isItalic', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.style.isItalic', label: `net.sf.jasperreports.style.isItalic`, description: `Boolean property used to configure dynamic font italic style for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.isPdfEmbedded', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.style.isPdfEmbedded', label: `net.sf.jasperreports.style.isPdfEmbedded`, description: `Boolean property used to specify whether the font should be embedded in the PDF output.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.isStrikeThrough', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.style.isStrikeThrough', label: `net.sf.jasperreports.style.isStrikeThrough`, description: `Boolean property used to configure dynamic font strike through style for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.isUnderline', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.style.isUnderline', label: `net.sf.jasperreports.style.isUnderline`, description: `Boolean property used to configure dynamic font underline style for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.markup', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.style.markup', label: `net.sf.jasperreports.style.markup`, description: `Property used to configure dynamic text markup style for report elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.mode', vtype: {...ModeEnum,custom: true, id: 'net.sf.jasperreports.style.mode', label: `net.sf.jasperreports.style.mode`, description: `Property used to configure dynamic style mode for report elements.`, sinceVersion: '4.8.0'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.paragraph.firstLineIndent', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.paragraph.firstLineIndent', label: `net.sf.jasperreports.style.paragraph.firstLineIndent`, description: `Property used to configure dynamic paragraph first line indent (in pixels) for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.paragraph.leftIndent', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.paragraph.leftIndent', label: `net.sf.jasperreports.style.paragraph.leftIndent`, description: `Property used to configure dynamic paragraph left indent (in pixels) for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.paragraph.lineSpacing', vtype: {...LineSpacingEnum,custom: true, id: 'net.sf.jasperreports.style.paragraph.lineSpacing', label: `net.sf.jasperreports.style.paragraph.lineSpacing`, description: `Property used to configure dynamic paragraph line spacing style for text elements.`, sinceVersion: '4.8.0'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.paragraph.lineSpacingSize', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.paragraph.lineSpacingSize', label: `net.sf.jasperreports.style.paragraph.lineSpacingSize`, description: `Property used to configure dynamic paragraph line spacing size for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.paragraph.rightIndent', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.paragraph.rightIndent', label: `net.sf.jasperreports.style.paragraph.rightIndent`, description: `Property used to configure dynamic paragraph right indent (in pixels) for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.paragraph.spacingAfter', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.paragraph.spacingAfter', label: `net.sf.jasperreports.style.paragraph.spacingAfter`, description: `Property used to configure dynamic spacing after paragraph (in pixels) for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.paragraph.spacingBefore', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.paragraph.spacingBefore', label: `net.sf.jasperreports.style.paragraph.spacingBefore`, description: `Property used to configure dynamic spacing before paragraph (in pixels) for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.paragraph.tabStopWidth', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.paragraph.tabStopWidth', label: `net.sf.jasperreports.style.paragraph.tabStopWidth`, description: `Property used to configure dynamic paragraph tab stop width (in pixels) for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.pattern', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.style.pattern', label: `net.sf.jasperreports.style.pattern`, description: `Property used to configure dynamic format pattern for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.pdfEncoding', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.style.pdfEncoding', label: `net.sf.jasperreports.style.pdfEncoding`, description: `Property used to configure dynamic PDF encoding character set for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.pdfFontName', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.style.pdfFontName', label: `net.sf.jasperreports.style.pdfFontName`, description: `Property used to configure dynamic PDF font name for text elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.pen.lineColor', vtype: new PColor({ custom: true, id: 'net.sf.jasperreports.style.pen.lineColor', label: `net.sf.jasperreports.style.pen.lineColor`, description: `Property used to configure dynamic pen line color for shape elements (line, rectangle, ellipse).`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.pen.lineStyle', vtype: {...LineStyleEnum,custom: true, id: 'net.sf.jasperreports.style.pen.lineStyle', label: `net.sf.jasperreports.style.pen.lineStyle`, description: `Property used to configure dynamic pen line style for shape elements (line, rectangle, ellipse).`, sinceVersion: '4.8.0'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.pen.lineWidth', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.pen.lineWidth', label: `net.sf.jasperreports.style.pen.lineWidth`, description: `Property used to configure dynamic pen line width for shape elements (line, rectangle, ellipse).`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.radius', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.style.radius', label: `net.sf.jasperreports.style.radius`, description: `Property used to configure dynamic corner radius for rectangle elements.`, sinceVersion: '4.8.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.rotation', vtype: {...RotationEnum,custom: true, id: 'net.sf.jasperreports.style.rotation', label: `net.sf.jasperreports.style.rotation`, description: `Property used to configure dynamic text or image rotation for report elements.`, sinceVersion: '4.8.0'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.scaleImage', vtype: {...ScaleImageEnum,custom: true, id: 'net.sf.jasperreports.style.scaleImage', label: `net.sf.jasperreports.style.scaleImage`, description: `Property used to configure dynamic scale image style for report elements.`, sinceVersion: '4.8.0'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.vAlign', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.style.vAlign', label: `net.sf.jasperreports.style.vAlign`, description: `Deprecated since v.6.0.2 - replaced with net.sf.jasperreports.style.vTextAlign 
or net.sf.jasperreports.style.vImageAlign.`, sinceVersion: '4.8.0', deprecated: true}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.vImageAlign', vtype: {...VerticalImageAlignEnum,custom: true, id: 'net.sf.jasperreports.style.vImageAlign', label: `net.sf.jasperreports.style.vImageAlign`, description: `Property used to configure dynamic vertical image alignment for report elements.`, sinceVersion: '6.0.2'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.vTextAlign', vtype: {...VerticalTextAlignEnum,custom: true, id: 'net.sf.jasperreports.style.vTextAlign', label: `net.sf.jasperreports.style.vTextAlign`, description: `Property used to configure dynamic vertical text alignment for report elements.`, sinceVersion: '6.0.2'}, scopes: ['ELEMENT'] }),
	]}),
	new PAccordeon({ id: 'export', label: 'export', custom: true, layouts: [
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.accessibility.tag', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.accessibility.tag', label: `net.sf.jasperreports.export.accessibility.tag`, description: `Property that specifies the type of accessible content introduced by the element.`, sinceVersion: '6.19.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.default.exclude', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.default.exclude', label: `net.sf.jasperreports.export.default.exclude`, description: `Element level property that acts as default for net.sf.jasperreports.export.`, default: 'false', sinceVersion: '6.11.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.html.class', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.html.class', label: `net.sf.jasperreports.export.html.class`, description: `Property used to specify the value of the CSS class attribute of elements when exported to HTML format.`, sinceVersion: '4.0.1'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.html.convert.svg.to.image', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.html.convert.svg.to.image', label: `net.sf.jasperreports.export.html.convert.svg.to.image`, description: `This property specifies whether the SVG graphics should be rasterized as images.`, default: 'false', sinceVersion: '6.3.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'IMAGE_ELEMENT', 'CHART_ELEMENT', 'COMPONENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.html.id', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.html.id', label: `net.sf.jasperreports.export.html.id`, description: `Property used to specify the value of the ID attribute of elements when exported to HTML format.`, sinceVersion: '3.7.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.json.data', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.json.data', label: `net.sf.jasperreports.export.json.data`, description: `This element-level property is considered by the JsonMetadataExporter 
and provides the exported value for that element.`, sinceVersion: '6.0.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.json.path', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.json.path', label: `net.sf.jasperreports.export.json.path`, description: `This element-level property is used by the JsonMetadataExporter 
and specifies a given path within the JSON tree, where the current element will be exported.`, sinceVersion: '6.0.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.json.repeat.value', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.json.repeat.value', label: `net.sf.jasperreports.export.json.repeat.value`, description: `This element-level flag is considered by the JsonMetadataExporter 
and specifies whether the value associated to the JSON path specified by net.sf.jasperreports.export.json.path property 
should be repeated or not when it is missing.`, default: 'false', sinceVersion: '6.0.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.matcher.filter.key', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.matcher.filter.key', label: `net.sf.jasperreports.export.matcher.filter.key`, description: `Property that specifies a key for the element, in order to make it eligible for the exporter filter during the report export.`, sinceVersion: '5.5.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.border.style', vtype: {...PdfFieldBorderStyleEnum,custom: true, id: 'net.sf.jasperreports.export.pdf.field.border.style', label: `net.sf.jasperreports.export.pdf.field.border.style`, description: `Property that specifies the PDF-compatible border style for input fields.`, sinceVersion: '6.12.0'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.check.type', vtype: {...PdfFieldCheckTypeEnum,custom: true, id: 'net.sf.jasperreports.export.pdf.field.check.type', label: `net.sf.jasperreports.export.pdf.field.check.type`, description: `Property applicable to check input fields in a PDF form that specifies the check type decoration.`, sinceVersion: '6.12.0'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.checked', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.field.checked', label: `net.sf.jasperreports.export.pdf.field.checked`, description: `Flag property applicable for check input fields that specifies if the input field is checked.`, sinceVersion: '6.12.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.choice.separators', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.field.choice.separators', label: `net.sf.jasperreports.export.pdf.field.choice.separators`, description: `Specifies the set of characters used as choices separator in the choices list.`, default: '|', sinceVersion: '6.12.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.choices', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.field.choices', label: `net.sf.jasperreports.export.pdf.field.choices`, description: `Specifies the list of choices for an input field such as list or combobox.`, sinceVersion: '6.12.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.combo.edit', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.field.combo.edit', label: `net.sf.jasperreports.export.pdf.field.combo.edit`, description: `Flag property applicable for combobox input fields that specifies if the combobox field is editable.`, default: 'false', sinceVersion: '6.12.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.name', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.field.name', label: `net.sf.jasperreports.export.pdf.field.name`, description: `Property that specifies the field name, which serves as unique identifier within the PDF form.`, sinceVersion: '6.12.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.read.only', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.field.read.only', label: `net.sf.jasperreports.export.pdf.field.read.only`, description: `Flag property that specifies if the input field is read-only.`, sinceVersion: '6.12.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.text.multiline', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.pdf.field.text.multiline', label: `net.sf.jasperreports.export.pdf.field.text.multiline`, description: `Flag property applicable for input text fields that specifies if the input text is also multiline.`, default: 'false', sinceVersion: '6.12.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.type', vtype: {...PdfFieldTypeEnum,custom: true, id: 'net.sf.jasperreports.export.pdf.field.type', label: `net.sf.jasperreports.export.pdf.field.type`, description: `Property that specifies the input field type in PDF forms.`, sinceVersion: '6.12.0'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.field.value', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.field.value', label: `net.sf.jasperreports.export.pdf.field.value`, description: `Property that specifies the initial text content for the text field or the initial selected value in 
list or combobox field, depending on the PDF form field type of the element.`, sinceVersion: '6.12.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.colspan', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.colspan', label: `net.sf.jasperreports.export.pdf.tag.colspan`, description: `Property used to associate a PDF COLSPAN tag with this element.`, sinceVersion: '3.1.2'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.h1', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.h1', label: `net.sf.jasperreports.export.pdf.tag.h1`, description: `Property used to associate a PDF H1 tag with this element.`, sinceVersion: '3.1.2', deprecated: true}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.h2', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.h2', label: `net.sf.jasperreports.export.pdf.tag.h2`, description: `Property used to associate a PDF H2 tag with this element.`, sinceVersion: '3.1.2', deprecated: true}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.h3', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.h3', label: `net.sf.jasperreports.export.pdf.tag.h3`, description: `Property used to associate a PDF H3 tag with this element.`, sinceVersion: '3.1.2', deprecated: true}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.h4', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.h4', label: `net.sf.jasperreports.export.pdf.tag.h4`, description: `Property used to associate a PDF H4 tag with this element.`, sinceVersion: '6.2.0', deprecated: true}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.h5', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.h5', label: `net.sf.jasperreports.export.pdf.tag.h5`, description: `Property used to associate a PDF H5 tag with this element.`, sinceVersion: '6.2.0', deprecated: true}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.h6', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.h6', label: `net.sf.jasperreports.export.pdf.tag.h6`, description: `Property used to associate a PDF H6 tag with this element.`, sinceVersion: '6.2.0', deprecated: true}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.l', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.l', label: `net.sf.jasperreports.export.pdf.tag.l`, description: `Property used to associate a PDF L tag with this element.`, sinceVersion: '6.2.0', deprecated: true}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.li', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.li', label: `net.sf.jasperreports.export.pdf.tag.li`, description: `Property used to associate a PDF LI tag with this element.`, sinceVersion: '6.2.0', deprecated: true}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.rowspan', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.rowspan', label: `net.sf.jasperreports.export.pdf.tag.rowspan`, description: `Property used to associate a PDF ROWSPAN tag with this element.`, sinceVersion: '3.1.2'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.table', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.table', label: `net.sf.jasperreports.export.pdf.tag.table`, description: `Property used to associate a PDF TABLE tag with this element.`, sinceVersion: '3.1.2'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.td', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.td', label: `net.sf.jasperreports.export.pdf.tag.td`, description: `Property used to associate a PDF TD tag with this element.`, sinceVersion: '3.1.2'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.th', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.th', label: `net.sf.jasperreports.export.pdf.tag.th`, description: `Property used to associate a PDF TH tag with this element.`, sinceVersion: '3.1.2'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.pdf.tag.tr', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.pdf.tag.tr', label: `net.sf.jasperreports.export.pdf.tag.tr`, description: `Property used to associate a PDF TR tag with this element.`, sinceVersion: '3.1.2'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.auto.filter', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.auto.filter', label: `net.sf.jasperreports.export.xls.auto.filter`, description: `This element-level property is used to indicate the boundaries of the autofilter data range in the current sheet.`, sinceVersion: '4.1.3'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.auto.fit.column', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.auto.fit.column', label: `net.sf.jasperreports.export.xls.auto.fit.column`, description: `Flag property that indicates whether Excel should autofit the current column width.`, default: 'false', sinceVersion: '4.5.1'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.auto.fit.row', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.auto.fit.row', label: `net.sf.jasperreports.export.xls.auto.fit.row`, description: `Flag property that indicates whether Excel should autofit the current row height.`, default: 'false', sinceVersion: '4.5.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.break.after.row', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.break.after.row', label: `net.sf.jasperreports.export.xls.break.after.row`, description: `Custom element property specifying if a sheet will break after the row displaying that element.`, default: 'false', sinceVersion: '3.0.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.break.before.row', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.break.before.row', label: `net.sf.jasperreports.export.xls.break.before.row`, description: `Custom element property specifying if a sheet will break before the row displaying that element.`, default: 'false', sinceVersion: '3.0.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.cell.hidden', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.cell.hidden', label: `net.sf.jasperreports.export.xls.cell.hidden`, description: `Property that specifies if the cell content is hidden or exposed in the formula bar of a protected sheet.`, default: 'false', sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.cell.locked', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.cell.locked', label: `net.sf.jasperreports.export.xls.cell.locked`, description: `Property that specifies if the cell content is locked (read-only) within a protected sheet.`, default: 'true', sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.column.name', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.column.name', label: `net.sf.jasperreports.export.xls.column.name`, description: `Represents the name for the column that should appear in the metadata-based Excel export.`, sinceVersion: '4.0.2'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.column.width', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.column.width', label: `net.sf.jasperreports.export.xls.column.width`, description: `Element-level property used to adjust the column width to values suitable for Excel output, taking into account 
that column widths are measured in Excel in Normal style default character width units.`, sinceVersion: '4.1.3'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.column.width.metadata', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.column.width.metadata', label: `net.sf.jasperreports.export.xls.column.width.metadata`, description: `Element-level property used to adjust the column width to values suitable for Excel metadata output, taking into account 
that when column headers are included in the report, they come with no available width settings.`, sinceVersion: '6.20.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.column.width.ratio', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.column.width.ratio', label: `net.sf.jasperreports.export.xls.column.width.ratio`, description: `Property used to adjust all column widths in a document or sheet with the same width ratio, in order to get column width 
values suitable for Excel output.`, sinceVersion: '4.1.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.first.page.number', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.first.page.number', label: `net.sf.jasperreports.export.xls.first.page.number`, description: `Property that specifies the first page number in the page setup dialog.`, sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.freeze.column.edge', vtype: {...EdgeEnum,custom: true, id: 'net.sf.jasperreports.export.xls.freeze.column.edge', label: `net.sf.jasperreports.export.xls.freeze.column.edge`, description: `Property that indicates the vertical edge of the freeze pane, relative to the current cell.`, sinceVersion: '4.1.1'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.freeze.row.edge', vtype: {...EdgeEnum,custom: true, id: 'net.sf.jasperreports.export.xls.freeze.row.edge', label: `net.sf.jasperreports.export.xls.freeze.row.edge`, description: `Property that indicates the horizontal edge of the freeze pane, relative to the current cell.`, sinceVersion: '4.1.1'}, scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.ignore.cell.background', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.ignore.cell.background', label: `net.sf.jasperreports.export.xls.ignore.cell.background`, description: `Property that specifies whether cell background color should be ignored in the generated document.`, default: 'false', sinceVersion: '3.6.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.ignore.cell.border', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.ignore.cell.border', label: `net.sf.jasperreports.export.xls.ignore.cell.border`, description: `Property that specifies whether cell borders should be ignored in the generated document.`, default: 'false', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.ignore.row.height', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.ignore.row.height', label: `net.sf.jasperreports.export.xls.ignore.row.height`, description: `Flag property that indicates whether the height of the element should contribute to the current row height calculation.`, default: 'false', sinceVersion: '6.20.1'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.image.anchor.type', vtype: {...ImageAnchorTypeEnum,custom: true, id: 'net.sf.jasperreports.export.xls.image.anchor.type', label: `net.sf.jasperreports.export.xls.image.anchor.type`, description: `Property that specifies the image anchor type.`, default: 'MoveNoSize', sinceVersion: '5.0.4'}, scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'IMAGE_ELEMENT', 'CHART_ELEMENT', 'COMPONENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.page.scale', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.page.scale', label: `net.sf.jasperreports.export.xls.page.scale`, description: `Property used to adjust the page content to a given percent of the normal size in the print preview pane.`, sinceVersion: '4.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.footer.margin', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.footer.margin', label: `net.sf.jasperreports.export.xls.print.footer.margin`, description: `Custom property storing the footer margin for an Excel sheet, expressed in pixels.`, default: 0, sinceVersion: '6.4.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.header.margin', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.header.margin', label: `net.sf.jasperreports.export.xls.print.header.margin`, description: `Custom property storing the header margin for an Excel sheet, expressed in pixels.`, default: 0, sinceVersion: '6.4.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.page.bottom.margin', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.page.bottom.margin', label: `net.sf.jasperreports.export.xls.print.page.bottom.margin`, description: `Custom property storing the bottom page margin for an Excel sheet, expressed in pixels.`, default: 0, sinceVersion: '6.4.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.page.height', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.page.height', label: `net.sf.jasperreports.export.xls.print.page.height`, description: `Custom property specifying the page height in print preview pane, measured in pixels.`, sinceVersion: '6.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.page.left.margin', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.page.left.margin', label: `net.sf.jasperreports.export.xls.print.page.left.margin`, description: `Custom property storing the left page margin for an Excel sheet, expressed in pixels.`, default: 0, sinceVersion: '6.4.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.page.right.margin', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.page.right.margin', label: `net.sf.jasperreports.export.xls.print.page.right.margin`, description: `Custom property storing the right page margin for an Excel sheet, expressed in pixels.`, default: 0, sinceVersion: '6.4.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.page.top.margin', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.page.top.margin', label: `net.sf.jasperreports.export.xls.print.page.top.margin`, description: `Custom property storing the top page margin for an Excel sheet, expressed in pixels.`, default: 0, sinceVersion: '6.4.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.print.page.width', vtype: new PInt({ custom: true, id: 'net.sf.jasperreports.export.xls.print.page.width', label: `net.sf.jasperreports.export.xls.print.page.width`, description: `Custom property specifying the page width in print preview pane, measured in pixels.`, sinceVersion: '6.6.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.repeat.value', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.repeat.value', label: `net.sf.jasperreports.export.xls.repeat.value`, description: `Property that specifies whether the value associated with 
PROPERTY_COLUMN_NAME 
should be repeated or not when it is missing.`, default: 'false', sinceVersion: '4.0.2'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.footer.center', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.footer.center', label: `net.sf.jasperreports.export.xls.sheet.footer.center`, description: `Custom property storing the text content of the Excel sheet center footers.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.footer.left', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.footer.left', label: `net.sf.jasperreports.export.xls.sheet.footer.left`, description: `Custom property storing the text content of the Excel sheet left footers.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.footer.right', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.footer.right', label: `net.sf.jasperreports.export.xls.sheet.footer.right`, description: `Custom property storing the text content of the Excel sheet right footers.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.header.center', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.header.center', label: `net.sf.jasperreports.export.xls.sheet.header.center`, description: `Custom property storing the text content of the Excel sheet center headers.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.header.left', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.header.left', label: `net.sf.jasperreports.export.xls.sheet.header.left`, description: `Custom property storing the text content of the Excel sheet left headers.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.header.right', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.header.right', label: `net.sf.jasperreports.export.xls.sheet.header.right`, description: `Custom property storing the text content of the Excel sheet right headers.`, sinceVersion: '3.7.5'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.name', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.name', label: `net.sf.jasperreports.export.xls.sheet.name`, description: `Property used to set the name of the sheet containing a given element.`, sinceVersion: '4.1.3'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.sheet.tab.color', vtype: new PColor({ custom: true, id: 'net.sf.jasperreports.export.xls.sheet.tab.color', label: `net.sf.jasperreports.export.xls.sheet.tab.color`, description: `Property whose value is used to set the tab color of a given sheet in XLSX and ODS output formats.`, sinceVersion: '6.2.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.show.gridlines', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.show.gridlines', label: `net.sf.jasperreports.export.xls.show.gridlines`, description: `Flag property that specifies if the gridlines in a given sheet are shown.`, default: 'true', sinceVersion: '4.8.0'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.shrink.to.fit', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.shrink.to.fit', label: `net.sf.jasperreports.export.xls.shrink.to.fit`, description: `Flag property that indicates whether the text font size should be decreased in order to 
keep the entire text visible in the cell.`, default: 'false', sinceVersion: '6.0.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.white.page.background', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.white.page.background', label: `net.sf.jasperreports.export.xls.white.page.background`, description: `Property that specifies whether the page background should be white or the default XLS background color.`, default: 'true', sinceVersion: '2.0.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.export.xls.wrap.text', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.export.xls.wrap.text', label: `net.sf.jasperreports.export.xls.wrap.text`, description: `Custom element property specifying if text wrapping is allowed in a given cell.`, default: 'true', sinceVersion: '3.7.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.force.html.embed.image', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.force.html.embed.image', label: `net.sf.jasperreports.force.html.embed.image`, description: `Flag that determines whether charts, barcodes and other visualization elements produce images that are embedded into HTML export output.`, default: 'true', sinceVersion: '6.20.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'CHART_ELEMENT', 'COMPONENT'] }),
	]}),
	new PAccordeon({ id: 'fill', label: 'fill', custom: true, layouts: [
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.element.template.populate.style', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.element.template.populate.style', label: `net.sf.jasperreports.element.template.populate.style`, description: `Flag that determines whether report style attributes are to be populated in
element print template objects during report execution.`, default: 'false', sinceVersion: '6.20.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.pattern.timezone', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.pattern.timezone', label: `net.sf.jasperreports.pattern.timezone`, description: `Property that specifies the timezone ID for this element.`, sinceVersion: '5.0.0'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.print.part.name', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.print.part.name', label: `net.sf.jasperreports.print.part.name`, description: `Element property that triggers the creation of a report part starting on the page on which the element appears.`, sinceVersion: '6.20.2'}), scopes: ['ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.style.evaluation.time.enabled', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.style.evaluation.time.enabled', label: `net.sf.jasperreports.style.evaluation.time.enabled`, description: `A flag the determines whether the style of an element is evaluated at
the element evaluation time, or at the time the band on which the element 
is placed is rendered.`, default: 'false', sinceVersion: '3.5.2'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'ELEMENT'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.subreport.generate.rectangle', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.subreport.generate.rectangle', label: `net.sf.jasperreports.subreport.generate.rectangle`, description: `Property used to specify when rectangle elements should be generated  for subreports during the report filling.`, sinceVersion: '6.0.3'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'DATASET', 'ELEMENT'] }),
	]}),
	new PAccordeon({ id: 'other', label: 'other', custom: true, layouts: [
	]}),
]};
