/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../../ui/APDescriptor";
import { MHyperlinks } from "../../../common/MHyperlinks";
import { PExpression } from "../../../../ui/primitive/UPExpression";
import { PConnectedTable } from "../../../../ui/tables/UPConnectedTable";
import { PTableElement } from "../../../../ui/tables/UPTableElement";

export const FTimePeriodSeries = {
    startDateExpression: new PExpression({ id: 'startDateExpression', label: 'Start Date Expression' }),
    endDateExpression: new PExpression({ id: 'endDateExpression', label: 'End Date Expression' }),
    labelExpression: new PExpression({ id: 'labelExpression', label: 'Label Expression' }),
    valueExpression: new PExpression({ id: 'valueExpression', label: 'Value Expression' }),
    seriesExpression: new PExpression({ id: 'seriesExpression', label: 'Series Expression' })
};

export const MTimePeriodSeries: APDescriptor = new PConnectedTable({
    id: 'timePeriodSeries', label: 'Series', rowLabel: 'Series',  rowLabelPaths:[FTimePeriodSeries.seriesExpression.id],
    layouts: [
        new PTableElement({
            label: 'Time Period Series',
            anchorOrigin: {vertical: 'center', horizontal: 'right'},
            transformOrigin: {vertical: 'center', horizontal: 'right'},
            layouts: [FTimePeriodSeries.labelExpression, FTimePeriodSeries.valueExpression,
            FTimePeriodSeries.seriesExpression, FTimePeriodSeries.startDateExpression, FTimePeriodSeries.endDateExpression,
            { ...MHyperlinks, id: 'itemHyperlink', label: 'Item Hyperlink' }]
        })]
});
