/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const ModeEnum = new PEnum({
    id: 'mode', label: 'Mode.label',
    items: [
        { key: 'Opaque', value: 'Mode.Opaque'},
        { key: 'Transparent', value: 'Mode.Transparent'}
    ]
}); 
 
