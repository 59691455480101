/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from './Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { ButtonProps } from '@material-ui/core';

interface IPopoverButtonProps extends ButtonProps {
    id: string;
    text: string;
    content: ({
        onClick: () => void;
        text: string;
        icon?: React.ReactNode;
    } | null)[];
}

interface IState {
    anchorEl: null | EventTarget
}

export class MenuButton extends React.Component<IPopoverButtonProps, IState> {

    state = {
        anchorEl: null,
    }

    private setAnchorEl = (newAnchorEl: null | EventTarget) => {
        this.setState({
            anchorEl: newAnchorEl,
        });
    }

    public render() {

        const handleClick = (event) => {
            this.setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            this.setAnchorEl(null);
        };

        const open = Boolean(this.state.anchorEl);

        const {id, text, content, ...buttonProps} = this.props; 

        const clickButtonProps : ButtonProps = {
            ...buttonProps,
            onClick: handleClick,
        }

        return (
            <div style={{display: 'flex', flex: 1}}>
                <Button {...clickButtonProps} style={{flex: 1}} >{text}</Button>
                <Menu
                    id={open ? id : undefined}
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                >
                    {content.map((element, index) => {
                        if (element === null) {
                            return <Divider key={index} />;
                        } else {
                            if (element.icon) {
                                return <MenuItem key={index} onClick={() => { handleClose(); element.onClick(); }}>
                                    <ListItemIcon>
                                        {element.icon}
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        {element.text}
                                    </Typography>
                                </MenuItem>
                            } else {
                                return <MenuItem key={index} onClick={() => { handleClose(); element.onClick(); }}>
                                    <Typography variant="inherit">
                                        {element.text}
                                    </Typography>
                                </MenuItem>
                            }
                        }
                    })}
                </Menu>
            </div>
        );
    }

}
