/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { FGraphicElement, MPrintWhen } from "../common/MGraphicElement";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { BreakTypeEnum } from "../enums/BreakTypeEnum";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { MColor } from "../common/MColor";
import { PConnectedSizeAndPosition } from "../../ui/primitive/UPConnectedSizePosition";
import { ICONS } from "@jss/js-common";
import { MBreakCustom } from "./MBreakCustom";

export const FBreak = {
    breakType: BreakTypeEnum
};

const y = new PConnectedSizeAndPosition({ id: 'y', label: 'y', min: 0 });

const MBreakLayout = [
    new PAccordeon({
        id: 'element.location', label: 'MBreak.property.location.label', expanded: true,
        layouts: [y, FGraphicElement.positionType]
    }),
    new PAccordeon({
        id: 'element.size', label: 'MBreak.property.size.label', expanded: true,
        layouts: [FGraphicElement.width, FGraphicElement.stretchType]
    }),
    ...MColor.layouts, MPrintWhen, MBreakCustom
];

export const MBreak: APDescriptor = {
    type: ElementTypes.BREAK,
    icon: ICONS.BREAK_ICON,
    id: 'breakProeprtiesDescriptor',
    default: { x: 0, width: 100, height: 1, type: ElementTypes.BREAK },
    label: 'MBreak.property.break.label',
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'MBreak.property.appearance.label', layouts: MBreakLayout }),
            new PDiv({ label: 'MBreak.property.break.label', layouts: [FBreak.breakType] })
        ]
    })]
};
