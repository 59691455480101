/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { FFusionWidgetProps, FFusionWidgets, MFusionWidgetCommon } from "./MFusionWidgetsCommon";
import ThermometerChartIcon from "../../../../../../assets/icons/charts/thermometer.svg";
import { PDiv } from "../../../ui/composite/UPDiv";
import { MHyperlinks } from "../../common/MHyperlinks";
import { MDatasetRun } from "../../dataset/MDatasetRun";
import { PCategories } from "../../../ui/composite/UPCategories";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";

export const MFusionThermometer: APDescriptor = {
    id: 'Thermometer', type: 'fusion.chart.thermometer', label: 'Thermometer', icon: ThermometerChartIcon, path: 'widgetProperties',
    layouts: [...MFusionWidgetCommon.layouts,
    new PAccordeon({
        id: 'thermometerTicks', label: "Ticks", layouts: [FFusionWidgets.showTickMarks, FFusionWidgets.showTickValues, FFusionWidgets.ticksOnRight, FFusionWidgets.forceTickValueDecimals, FFusionWidgets.setAdaptiveMin]
    }),
    new PAccordeon({
        id: 'gauge', label: "Gauge", layouts: [new PAccordeon({
            id: 'gauge.fill', label: "Fill", layouts: [FFusionWidgets.gaugeFillColor, FFusionWidgets.gaugeFillAlpha]
        }), new PAccordeon({
            id: 'gauge.border', label: "Border", layouts: [FFusionWidgets.showGaugeBorder, FFusionWidgets.gaugeBorderColor, FFusionWidgets.gaugeBorderAlpha, FFusionWidgets.gaugeBorderThickness]
        })
        ]
    }), new PAccordeon({
        id: 'therm', label: "Thermometer", layouts: [FFusionWidgets.thmHeight, FFusionWidgets.thmBulbRadius,
        new PAccordeon({
            id: 'gauge.limits', label: "Limits",
            layouts: [FFusionWidgetProps.showLimits, FFusionWidgets.lowerLimit, FFusionWidgets.upperLimit,
            new PAccordeon({
                id: 'gauge.limits.display', label: "Display Limits",
                layouts: [FFusionWidgets.lowerLimitDisplay, FFusionWidgets.upperLimitDisplay]
            })]
        })
        ]
    })],
    default: {
        type: 'FUSION_WIDGET', widgetType: 'Thermometer',
        widgetProperties: [], valueExpression: "new Integer(20)"
    }
};

export const MFusionThermometerDataset: APDescriptor = {
    id: 'Thermometer', type: 'fusion.chart.thermometer.data', label: 'Thermometer', icon: ThermometerChartIcon,
    layouts: [
        new PCategories({
            label: 'Data', layouts: [
                new PDiv({
                    id: 'fusion.data.dataset', path: 'dataset',
                    label: 'Dataset',
                    layouts: [FFusionWidgets.incrementType, FFusionWidgets.incrementWhenExpression, FFusionWidgets.resetType, MDatasetRun]
                }),
                new PDiv({
                    id: 'fusion.data.entities',
                    label: 'Value',
                    layouts: [FFusionWidgets.valueExpression]
                }),
                new PDiv({
                    id: 'fusion.data.valuesets.hyperlinks',
                    label: 'Hyperlink', path: 'hyperlink',
                    layouts: [...MHyperlinks.layouts]
                })]
        })]
};
