/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg } from '../../ui/UiProperty';
import UPQueryLanguage, { PQueryLanguage } from './UPQueryLanguage';

const TYPEID = 'connectedQueryLanguage';
export class PConnectedQueryLanguage extends PQueryLanguage {
    
    public constructor(init: Partial<PConnectedQueryLanguage>) {
        super(init);
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPQueryLanguage mcontext={mc} />; });