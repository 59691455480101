/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

export type IconSize = 'small' | 'medium' | 'large';

export const SizeToClass: {[key in IconSize]: string} = {
    small: 'jr-mIconSmall',
    medium: '',
    large: 'jr-mIconLarge'
}
