/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { ISearchResult, ViewerStoreContext } from "../ViewerStore";
import {i18n, IconButton, PopoverControl, TimedSearchBar} from "@jss/js-common";
import { RunContext } from "@jss/js-repository";
import { ListItem, ListItemSecondaryAction, ListItemText, List, Switch } from '@material-ui/core';
import TuneIcon from '@mui/icons-material/Tune';


interface ISearchControl {
    backgroundColor?: string;
    height: number;
}

interface ISearchState {
    hitIndex: number | null;
    result: ISearchResult | null;
    term: string | null;
    caseSensitive: boolean;
    wholeWordsOnly: boolean;
    canClear: boolean;
}

const defaultSearchState: ISearchState = {
    hitIndex: null, result: null, term: null, canClear: false,
    caseSensitive: false, wholeWordsOnly: false
};

export const SearchControl: React.FunctionComponent<ISearchControl> = ({ backgroundColor, height }) => {
    const viewerStore = useContext(ViewerStoreContext);
    const runContext = useContext(RunContext);
    const [currentSearchText, setCurrentSearchText] = useState<string>("");
    const [searchState, setSearchState] = useState<ISearchState>(defaultSearchState);
    const [caseSensitive, setCaseSensitive] = useState<boolean>(false);
    const [wholeWordsOnly, setWholeWordsOnly] = useState<boolean>(false);

    const searchReport = useCallback(
        () => {
            if (currentSearchText && currentSearchText.trim().length) {
                viewerStore.searchReport(currentSearchText, caseSensitive, wholeWordsOnly)
                    .then(() => {
                        setSearchState(prevState => {
                            return {
                                ...prevState,
                                term: currentSearchText,
                                canClear: false,
                                caseSensitive,
                                wholeWordsOnly
                            };
                        });
                    });
            } else {
                runContext.handleError(i18n.t("viewer.searchControl.searchCondition"));
            }
        }, [currentSearchText, viewerStore, caseSensitive, wholeWordsOnly]);

    const onSearchTextChange = useCallback(
        (value: string) => {
            setCurrentSearchText(value);
            if (searchState.term !== null && searchState.term !== value) {
                setSearchState(prevState => {
                    return { ...prevState, canClear: true };
                });
            }
        }, [searchState.term]);

    const onNavigateToPreviousSearchResult = useCallback(
        () => {
            const resultsForCurrentPage = viewerStore.searchResults.find(result => result.page === viewerStore.currentPage);
            let usePreviousSearchResult = false;

            if (resultsForCurrentPage) {
                let hitIndex;
                // if we are cycling on the same page
                if (resultsForCurrentPage.page === searchState.result.page) {
                    // continue from existing hitIndex
                    hitIndex = searchState.hitIndex;
                } else {
                    // we are on a different page with search results
                    hitIndex = resultsForCurrentPage.hitCount;
                }

                if (hitIndex > 0) {
                    // mark the previous element to be highlighted
                    setSearchState(prevState => {
                        return {...prevState, hitIndex: hitIndex - 1, result: resultsForCurrentPage};
                    });
                } else {
                    usePreviousSearchResult = true;
                }
            } else {
                usePreviousSearchResult = true;
            }

            if (usePreviousSearchResult) {
                // find next search results
                let previousSearchResult;
                for (let i = viewerStore.searchResults.length -1 ; i >= 0; i--) {
                    if (viewerStore.currentPage > viewerStore.searchResults[i].page) {
                        previousSearchResult = viewerStore.searchResults[i];
                        break;
                    }
                }
                if (!previousSearchResult) {
                    previousSearchResult = viewerStore.searchResults[viewerStore.searchResults.length - 1];
                }

                viewerStore
                    .goToPage(previousSearchResult.page)
                    .then(() => {
                        // mark the last element to be highlighted
                        setSearchState(prevState => {
                            return {...prevState, hitIndex: previousSearchResult.hitCount - 1, result: previousSearchResult};
                        });
                    });
            }
        }, [searchState.hitIndex, searchState.result, viewerStore]);

    const onNavigateToNextSearchResult = useCallback(
        () => {
            const resultsForCurrentPage = viewerStore.searchResults.find(result => result.page === viewerStore.currentPage);
            let useNextSearchResult = false;

            if (resultsForCurrentPage) {
                let hitIndex;
                // if we are cycling on the same page
                if (resultsForCurrentPage.page === searchState.result.page) {
                    // continue from existing hitIndex
                    hitIndex = searchState.hitIndex;
                } else {
                    // we are on a different page with search results
                    hitIndex = -1;
                }

                if (hitIndex < resultsForCurrentPage.hitCount - 1) {
                    // mark the next element to be highlighted
                    setSearchState(prevState => {
                        return {...prevState, hitIndex: hitIndex + 1, result: resultsForCurrentPage};
                    });
                } else {
                    useNextSearchResult = true;
                }
            } else {
                useNextSearchResult = true;
            }

            if (useNextSearchResult) {
                // find next search results
                let nextSearchResult = viewerStore.searchResults.find(result => result.page > viewerStore.currentPage);
                if (!nextSearchResult) {
                    nextSearchResult = viewerStore.searchResults[0];
                }

                viewerStore
                    .goToPage(nextSearchResult.page)
                    .then(() => {
                        // mark the first element to be highlighted
                        setSearchState(prevState => {
                            return {...prevState, hitIndex: 0, result: nextSearchResult};
                        });
                    });
            }
        }, [searchState.hitIndex, searchState.result, viewerStore]);

    const onCaseSensitiveChanged = useCallback(
        (_, checked) => {
            setCaseSensitive(checked);
            if (searchState.term !== null) {
                setSearchState(prevState => {
                    return { ...prevState, canClear: true };
                });
            }
        }, [searchState.term]);

    const onWholeWordsOnlyChanged = useCallback(
        (_, checked) => {
            setWholeWordsOnly(checked);
            if (searchState.term !== null) {
                setSearchState(prevState => {
                    return { ...prevState, canClear: true };
                });
            }
        }, [searchState.term]);

    const isSearchDisabled = viewerStore.hasError() || !viewerStore.hasPages() || viewerStore.isPageLoading;
    const isSearchNavigationDisabled = isSearchDisabled || !viewerStore.hasSearchResults() ||
        !viewerStore.searchResults.length ||
        (viewerStore.searchResults.length === 1 && viewerStore.searchResults[0].hitCount === 1) ||
        (
            searchState.canClear && searchState.term !== null &&
                (
                    searchState.term !== currentSearchText ||
                    searchState.caseSensitive !== caseSensitive ||
                    searchState.wholeWordsOnly !== wholeWordsOnly
                )
        );

    // effect for clearing the search results before certain report jive actions are executed
    useEffect(() => {
        if (viewerStore.beforeActions !== null) {
            viewerStore.setSearchResults(null);
            setSearchState(defaultSearchState);
        }
    }, [viewerStore.beforeActions, viewerStore.setSearchResults]);

    // effect for navigation to the first search result page
    useEffect(() => {
        if (viewerStore.searchResults) {
            if (viewerStore.searchResults.length === 0) {
                runContext.handleError(i18n.t("viewer.searchControl.noResults", { searchTerm: currentSearchText }));
            } else {
                viewerStore
                    .goToPage(viewerStore.searchResults[0].page)
                    .then(() => {
                        // mark the first element to be highlighted
                        setSearchState(prevState => {
                            return { ...prevState, hitIndex: 0, result: viewerStore.searchResults[0] };
                        });
                    });
            }
        }
    }, [viewerStore.searchResults]);

    // hook for setting the search results highlight when navigating across pages
    useEffect(() => {
        if (searchState.result != null &&
            searchState.result.page === viewerStore.currentPage &&
            searchState.hitIndex !== null &&
            viewerStore.containerClassName !== null &&
            !viewerStore.isPageLoading)
        {
            const reportContainer = document.getElementsByClassName(viewerStore.containerClassName);
            if (reportContainer && reportContainer.length) {
                const resultElements = reportContainer[0].getElementsByClassName("jr_search_result");

                // remove highlight from previous element, if any
                if (resultElements) {
                    const resultsArray = [ ...resultElements ];
                    const previousElement = resultsArray.find(element => element.classList.contains("jr_search_result_highlight"));
                    previousElement && previousElement.classList.remove("jr_search_result_highlight");
                }

                // highlight the hitIndex element
                if (resultElements.length > searchState.hitIndex) {
                    resultElements[searchState.hitIndex].classList.add("jr_search_result_highlight");
                    resultElements[searchState.hitIndex].scrollIntoView(false);
                }
            }
        } else if (searchState.term !== null && viewerStore.searchResults !== null && viewerStore.searchResults.length === 0){
            // remove highlight from previous searched elements, if any
            const reportContainer = document.getElementsByClassName(viewerStore.containerClassName);
            if (reportContainer.length) {
                const highlightedElements = reportContainer[0].getElementsByClassName("jr_search_result_highlight");

                if (highlightedElements.length) {
                    const asArray = [ ...highlightedElements ];
                    asArray.forEach(element => element.classList.remove("jr_search_result_highlight"));
                }
            }

        }
    }, [searchState, viewerStore.currentPage, viewerStore.containerClassName, viewerStore.isPageLoading, viewerStore.searchResults]);

    const renderPopoverControl = useCallback(
        (handleClick: (clickEvent: any) => void) => {
            return <div title='Search options' style={{ cursor: 'pointer' }} onClick={isSearchDisabled ? undefined : handleClick}>
                <TuneIcon color={isSearchDisabled ? 'disabled' : 'inherit'}/>
            </div>;
        }, [isSearchDisabled]);

    const renderPopoverContent = useCallback(
        () => {
            return <List style={{ width: '200px' }}>
                <ListItem key={"pref.deprecated"} button>
                    <ListItemText
                        primary={i18n.t("viewer.searchControl.options.caseSensitive")}
                        title={i18n.t("viewer.searchControl.options.caseSensitiveTitle")}
                    />
                    <ListItemSecondaryAction>
                        <Switch
                            edge="end" color="primary"
                            onChange={onCaseSensitiveChanged}
                            checked={caseSensitive}
                            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem key={'pref.custom.properties'} button>
                    <ListItemText
                        primary={i18n.t("viewer.searchControl.options.wholeWordsOnly")}
                        title={i18n.t("viewer.searchControl.options.wholeWordsOnlyTitle")}
                    />
                    <ListItemSecondaryAction>
                        <Switch
                            edge="end" color="primary"
                            onChange={onWholeWordsOnlyChanged}
                            checked={wholeWordsOnly}
                            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            </ List>;
        }, [caseSensitive, wholeWordsOnly, onCaseSensitiveChanged, onWholeWordsOnlyChanged]);

    return <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: backgroundColor }}>
        <div style={{ height: 24, display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', gap: '5px' }}>
            <TimedSearchBar
                className="jr_jrws_viewer_search_bar"
                placeholder={i18n.t("viewer.searchControl.placeHolder")}
                pauseTime={0}
                value={currentSearchText}
                onChange={onSearchTextChange}
                onEnterPress={searchReport}
                disabled={isSearchDisabled}
            />
            <PopoverControl
                id={'searchOptionsControl'}
                getContent={renderPopoverContent}
                getControl={renderPopoverControl}
            />
        </div>
        <div style={{ width: 24, height: height, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }}>
            <IconButton
                style={{ backgroundColor: 'transparent' }}
                icon={'arrowLeft'}
                size="small"
                disabled={isSearchNavigationDisabled}
                onClick={onNavigateToPreviousSearchResult}
                title={i18n.t("viewer.searchControl.searchPrevious")}
            />
        </div>
        <div style={{ width: 24, height: 24, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }}>
            <IconButton
                style={{ backgroundColor: 'transparent' }}
                icon={'arrowRight'}
                size="small"
                disabled={isSearchNavigationDisabled}
                onClick={onNavigateToNextSearchResult}
                title={i18n.t("viewer.searchControl.searchNext")}
            />
        </div>
    </div>
};
