/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../ui/APDescriptor";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PInt } from "../../ui/primitive/UPInteger";

import { PUniqueName } from "../../ui/primitive/UPUniqueName";
import { PTableElement } from "../../ui/tables/UPTableElement";
import { FooterPositionEnum } from "../enums/FooterPositionEnum";


export const FGroup = {
    name: new PUniqueName({ id: 'name', label: 'Name', canBeEmpty: false, canBeNull: false }),
    groupExpression: new PExpression({ id: 'groupExpression', label: 'Group On' }),
    reprintHeaderOnEachPage: new PBoolean({ id: 'reprintHeaderOnEachPage', label: 'Reprint Header On Each Page', description: 'Reprint Group Header at the beginning of each page.' }),
    reprintHeaderOnEachColumn: new PBoolean({ id: 'reprintHeaderOnEachColumn', label: 'Reprint Header On Each Column', description: 'Reprint Group Header at the beginning of each column, in vertically filled reports only' }),
    keepTogether: new PBoolean({ id: 'keepTogether', label: 'Start  New Column/Page If Not Enough Space', description: 'Starts group on a new column/page if there is not enough space in the current column/page to render the whole group.' }),
    preventOrphanFooter: new PBoolean({ id: 'preventOrphanFooter', label: 'Prevent Orphan Footer', description: 'Prevent rendering group footers without a detail row at the top of a new page or column.' }),
    startNewColumn: new PBoolean({ id: 'startNewColumn', label: 'Start New Column', description: 'Group Header always starts on a new column' }),
    startNewPage: new PBoolean({ id: 'startNewPage', label: 'Start New Page', description: 'Group header always starts a new page.' }),
    resetPageNumber: new PBoolean({ id: 'resetPageNumber', label: 'Start New Page & Reset Page Number', description: 'Group header starts a new page and reset the page counter' }),
    minHeightToStartNewPage: new PInt({ id: 'minHeightToStartNewPage', label: 'Min Height To Start New Group', min: 0, description: 'Minimum amount of vertical space needed at the bottom of the column in order to place the group header on the current column.' }),
    minDetailsToStartFromTop: new PInt({ id: 'minDetailsToStartFromTop', label: 'Min Detail Rows To Start From Top', min: 0, description: 'Minimum number of detail rows to be rendered on the current column, to avoid starting the group on a new column.' }),
}

export const MGroup: APDescriptor = new PTableElement({
    label: 'Group',
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    layouts: [FGroup.name, FGroup.groupExpression,
    new PAccordeon({
        id: 'groupLayoutHints', label: 'Layout Hints', expanded: true,
        layouts: [
            FGroup.keepTogether,
            new PAccordeon({
                id: 'groupLayoutHintsPage', label: 'Page', expanded: true,
                layouts: [FGroup.startNewPage, FGroup.resetPageNumber, FGroup.reprintHeaderOnEachPage]
            }),
            new PAccordeon({
                id: 'groupLayoutHintsColumn', label: 'Column', expanded: true,
                layouts: [FGroup.startNewColumn, FGroup.minDetailsToStartFromTop, FGroup.minHeightToStartNewPage, FGroup.reprintHeaderOnEachColumn]
            }),

            new PAccordeon({
                id: 'groupLayoutHintsFooter', label: 'Footer', expanded: true,
                layouts: [FooterPositionEnum, FGroup.preventOrphanFooter]
            })
        ]
    })


    ]
});
