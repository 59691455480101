/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { ErrorDescriptor } from "./ErrorDescriptor";
import { AxiosError } from 'axios';

export enum INTENT { ERROR, WARNING, INFO, DEBUG }

export class MessageInfo {
    private level: INTENT;
    private msg: string;
    private detail?: any;

    constructor(level: INTENT, msg: string, detail?: any) {
        this.level = level;
        this.msg = msg;
        this.detail = detail;
    }
    public getMessage() {
        return this.msg;
    }
    public setMessage(msg: string) {
        this.msg = msg;
    }
    public getDetails() {
        return this.detail;
    }
    public getIntent() {
        return this.level;
    }
}

export function msg(m?: MessageInfo | string) {
    return m instanceof MessageInfo ? m.getMessage() : m;
}

export function error(msg: string | ErrorDescriptor | AxiosError | Error, i18n?: (msg: string) => string): MessageInfo {
    if ((msg as AxiosError).isAxiosError !== undefined) {
        const m = new ErrorDescriptor(msg as AxiosError, i18n);
        return new MessageInfo(INTENT.ERROR, m.getMessage(), m);
    }
    if (msg instanceof Error) {
        msg = new ErrorDescriptor(msg, i18n);
    }
    if (msg instanceof ErrorDescriptor) {
        const message = (msg as ErrorDescriptor).getMessage();
        return new MessageInfo(INTENT.ERROR, message, msg);
    }

    return new MessageInfo(INTENT.ERROR, msg as string);
}
export function warn(msg: string): MessageInfo {
    return new MessageInfo(INTENT.WARNING, msg);
}
export function info(msg: string): MessageInfo {
    return new MessageInfo(INTENT.INFO, msg);
}

export function debug(msg: string): MessageInfo {
    return new MessageInfo(INTENT.DEBUG, msg);
}