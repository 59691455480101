/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { AxiosPromise } from 'axios';
import { JRIOApiService } from '../jrio/jrio';
import { IRepositoryAPI, JobResult } from './IRepositoryAPI';
import { getRealName, IRepositoryItemDescriptor } from './IRepositoryItemDescriptor';
import { RepositoryJRS } from '../jrs/RepositoryJRS';
import { RepositoryREST } from '../rest/RepositoryREST';
import { DatasourceApi } from '../data/Datasource';
import { IResourceField } from './IResourceField';
import { Conf } from '../config/Conf';

export enum Permission {
    NONE = 'resource.permission.none',
    EXECUTE = 'resource.permission.execute',
    WRITE = 'resource.permission.write',
    READ = 'resource.permission.read',
    ADMINISTER = 'resource.permission.administer',
    DELETE = 'resource.permission.delete',
    CREATE = 'resource.permission.create'
}

export class RepositoryApi {

    private current: IRepositoryAPI = new RepositoryREST("", "");
    private currentUserId: string;
    private currentBase: string;
    private static instance: RepositoryApi;

    public static inst = (): RepositoryApi => {
        if (!RepositoryApi.instance) {
            RepositoryApi.instance = new RepositoryApi();
        }
        return RepositoryApi.instance;
    }

    public getBaseURL(): string {
        const rburl = this.current.getBaseURL();
        if (rburl)
            return rburl;
        return '';
    }

    public getUserId(): string {
        return this.currentUserId;
    }

    public getBase(): string {
        return this.currentBase;
    }

    public getInfo(): string | undefined {
        return this.current.getInfo();
    }

    public setBaseURL(base: string, userid: string, info?: string) {
        if (base.startsWith('jrs:')) {
            this.current = new RepositoryJRS(base, userid, info);
        } else {
            this.current = new RepositoryREST(base, userid, info);
        }
        this.currentUserId = userid;
        this.currentBase = base;
        JRIOApiService.inst().setBase(base, userid);
        DatasourceApi.inst().setBase(base, userid);
    }

    public tmp(): IRepositoryAPI {
        return this.current.tmp();
    }

    public getRoot(): IRepositoryItemDescriptor {
        return this.current.getRoot();
    }

    public static getAPath = (d: IRepositoryItemDescriptor): string => {
        if (d.uuid && ['js', 's3', 'github'].includes(RepositoryApi.inst().getBase())) {
            return d.uuid.startsWith('/') ? d.uuid.substring(1) : d.uuid;
        }
        const rname = getRealName(d);
        let path = (d.name?.startsWith('/')) ? rname.substring(1) : rname;
        path = path ? path : '';
        if (d.path) {
            path = d.path + (d.path.endsWith('/') ? '' : '/') + path;
        }
        if (d.uuid && ['jackrabbit', 'jrws'].includes(RepositoryApi.inst().getBase())) {
            const ind = d.uuid.lastIndexOf('/');
            if (ind > -1)
                path = d.uuid.substring(0, ind) + path;
        }
        return path;
    }
    public static getContainer = (path: string): string => {
        const clen = RepositoryApi.inst().field().getContainerLenght();
        let c = '';
        let delimiter = '';
        if (path.startsWith('/')) path = path.substring(1);
        path.split('/').forEach((p, index) => {
            if (index < clen) {
                c += delimiter + p;
                delimiter = '/';
            }
        });
        return c;
    }
    public static getAPathOnly = (d: IRepositoryItemDescriptor): string => {
        let p = this.getAPath(d);
        const ind = p.lastIndexOf('/');
        if (ind >= 0)
            p = p.substring(0, ind);
        return (!p.startsWith('/') ? '/' : '') + p;
    }
    public currentRepository(): IRepositoryAPI {
        return this.current;
    }
    public static getRelativePath = (path: string): string => {
        const rcont = RepositoryApi.getContainer(path);
        return path.substring(rcont.length + 1);
    }

    public logout() {
        if (this.current) {
            this.current.logout();
            Conf.reset();
            RepositoryApi.instance = new RepositoryApi();
        }
    }

    /**
     * list content of a resource
     * @param path - resource path on the server, could be an uuid
     * @param depth - how deep should children should be get
     */
    public list = (path: string, depth = 1): AxiosPromise<any> => {
        return this.current.list(path, depth);
    }
    public listWithCancel = (path: string, depth: number): JobResult => {
        return this.current.listWithCancel(path, depth);
    }

    /**
     * save a resource in the repository
     * @param path - resource path on the server, could be an uuid
     * @param descriptor - ResourceDescriptor
     */
    public save = (path: string, descriptor: IRepositoryItemDescriptor): AxiosPromise<any> => {
        return this.current.save(path, descriptor);
    }

    /**
     * move a resource to another path, should be used for renames, uuid will rest the same
     * @param path resource path on the server, could be an uuid
     * @param dest destination path, not an uuid
     */
    public move = (path: string, dest: string): AxiosPromise<any> => {
        return this.current.move(path, dest);
    }

    /**
     * copy a resource to another path, should be used for renames, uuid will be different
     * @param path resource path on the server, could be an uuid
     * @param dest destination path, not an uuid
     */
    public copy = (path: string, dest: string): AxiosPromise<any> => {
        return this.current.copy(path, dest);
    }

    /**
     * delete a resource from the repository
     * @param path resource path on the server, could be an uuid
     */
    public delete = (path: string): AxiosPromise<any> => {
        return this.current.delete(path);
    }

    /**
     * load a resource from the repository
     * @param path resource path on the server, could be an uuid
     */
    public load = (path: string, metadata = false, withData = false, allPermissions = false): AxiosPromise<IRepositoryItemDescriptor> => {
        return this.current.load(path, metadata, withData, allPermissions);
    }

    /**
     * get the specific resource path
     * @param path
     * @returns the transformed path
     */
    public getResourcePath = (path: string): string => {
        return this.current.getResourcePath(path);
    }

    /**
     * search repository by one or more fields, all are like()
     * @param params contains an object with search parameters { name by resource name, description by resource description, mime by mime, astModified by last modified date, tags by tags
     */
    public find = (params: { name?: string, description?: string, root?: string, mime?: string[], lastModified?: string, tags?: string[] }): AxiosPromise<any> => {
        return this.current.find(params);
    }

    public findWithCancel = (params: { name?: string, description?: string, root?: string, mime?: string[], lastModified?: string, tags?: string[] }): JobResult => {
        return this.current.findWithCancel(params);
    }


    /**
     * Create a folder, path is not encoded
     * @param path
     */
    public createFolder = (path: string): AxiosPromise<any> => {
        return this.current.createFolder(path);
    }

    public field = (): IResourceField => {
        return this.current.field();
    }

    /**
     * Escape special charactes in each path component, strip unnecessary slashes and trim the path.
     */
    public static encodePath(path: string): string {
        path = path.trim();
        while (path.startsWith('/')) {
            path = path.substring(1);
        }
        while (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }
        const parts = path.split('/');
        let encodePath = '';
        for (const part of parts) {
            encodePath += encodeURIComponent(part) + '/';
        }
        while (encodePath.endsWith('/')) {
            encodePath = encodePath.substring(0, encodePath.length - 1);
        }
        return encodePath;
    }


    public getParentPath = (path: IRepositoryItemDescriptor[]): string => {
        return this.current.getPath(path);
    }

    public getFileUploadLimit = (): number => {
        const limit = Conf.get(`jrws.repo.${this.getBase()}.file.upload.limit`);
        if (limit)
            return limit;
        return Conf.get('jrws.repo.file.upload.limit');
    }

    public hasPermission = (d: IRepositoryItemDescriptor, ...perm: Permission[]): boolean => {
        const perms = Object.values(perm) as string[];
        const h = this.current.field().decode('permission', `${d.permission}`)?.split(',').some(p => {
            return perms.includes(p);
        });
        return h ? h : false;
    }

    public setPermission = (d: IRepositoryItemDescriptor, ...perm: Permission[]) => {
        const pms = Object.values(perm) as string[];
        const p = this.current.field().encode('permission', pms.join());
        if (p)
            d.permission = parseInt(p);

    }

}
