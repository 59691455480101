/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const TimePeriodEnum = new PEnum({
    id: 'timePeriod', label: 'TimePeriodEnum.label', isEmptyUndefined: true,
    items: [
        { key: '', value: ''},
        { key: 'Year', value: 'TimePeriodEnum.Year'},
        { key: 'Quarter', value: 'TimePeriodEnum.Quarter'},
        { key: 'Month', value: 'TimePeriodEnum.Month'},
        { key: 'Week', value: 'TimePeriodEnum.Week'},
        { key: 'Day', value: 'TimePeriodEnum.Day'},
        { key: 'Hour', value: 'TimePeriodEnum.Hour'},
        { key: 'Minute', value: 'TimePeriodEnum.Minute'},
        { key: 'Second', value: 'TimePeriodEnum.Second'},
        { key: 'Milisecond', value: 'TimePeriodEnum.Milisecond'},
    ]
}); 
 
