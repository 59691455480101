/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg } from '../../ui/UiProperty';
import { APDescriptor } from '../../ui/APDescriptor';
import UPQuery from './UPQuery';

const TYPEID = 'connectedQuery';
reg(TYPEID, (mc) => { return <UPQuery mcontext={mc} />; });
export class PConnectedQuery extends APDescriptor {

    public constructor(init: Partial<PConnectedQuery>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
