/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import BarcodeIcon from './elements/BarcodeIcon';
import React from 'react';
import BreakIcon from './elements/BreakIcon';
import EllipseIcon from './elements/EllipseIcon';
import LineIcon from './elements/LineIcon';
import RectangleIcon from './elements/RectangleIcon';
import TextFieldIcon from './elements/TextFieldIcon';
import SubreportIcon from './elements/SubreportIcon';
import GenericIcon from './elements/GenericIcon';
import FrameIcon from './elements/FrameIcon';
import ListIcon from './elements/ListIcon';
import ChartIcon from './elements/ChartIcon';
import MapIcon from './elements/MapIcon';
import TibcoMapIcon from './elements/TibcoMapIcon';
import CustomVisualizationIcon from './elements/CustomVisualizationIcon';
import HighchartIcon from './elements/HighchartIcon';
import StaticTextIcon from './elements/StaticTextIcon';
import ChartProIcon from './elements/ChartProIcon';
import MapProIcon from './elements/MapProIcon';
import TableIcon from './elements/TableIcon';
import CrosstabIcon from './elements/CrosstabIcon';
import ImageIcon from './elements/ImageIcon';
import WidgetsProIcon from './elements/WidgetsProIcon';
import BarcodeCodabarIcon from './elements/BarcodeCodabarIcon';
import BarcodeQRCodeIcon from './elements/BarcodeQRCodeIcon';
import BarcodePOSTNETIcon from './elements/BarcodePOSTNETIcon';
import BarcodeRoyalMailCustomerIcon from './elements/BarcodeRoyalMailCustomerIcon';
import BarcodePDF417Icon from './elements/BarcodPDF417Icon';
import BarcodeUPCAIcon from './elements/BarcodeUPCAIcon';
import BarcodeUPCEIcon from './elements/BarcodeUPCEIcon';
import BarcodeUSPSIcon from './elements/BarcodeUSPSIcon';
import BarcodeInt2of5Icon from './elements/BarcodeInt2of5Icon';
import BarcodeEAN13Icon from './elements/BarcodeEAN13Icon';
import BarcodeEAN128Icon from './elements/BarcodeEAN128Icon';
import BarcodeEAN8Icon from './elements/BarcodeEAN8Icon';
import BarcodeDataMatrixIcon from './elements/BarcodeDataMatrixIcon';
import BarcodeCode128Icon from './elements/BarcodeCode128Icon';
import BarcodeCode39Icon from './elements/BarcodeCode39Icon';
import StyleIcon from './elements/StyleIcon';
import VariableIcon from './elements/VariableIcon';
import FieldIcon from './elements/FieldIcon';
import ParameterIcon from './elements/ParameterIcon';
import { AdHocIcon } from './elements/AdHocIcon';
import { ExpressionIcon, ValueIcon } from './InterfaceIcons';

export const ICONS = {
    STYLES_ICON: <StyleIcon />,
    BARCODE_ICON: <BarcodeIcon />,

    BARCODE_CODABAR_ICON: <BarcodeCodabarIcon />,
    BARCODE_QRCODE_ICON: <BarcodeQRCodeIcon />,
    BARCODE_POSTNET_ICON: <BarcodePOSTNETIcon />,
    BARCODE_ROYALMAILCUSTOMER_ICON: <BarcodeRoyalMailCustomerIcon />,
    BARCODE_PDF417_ICON: <BarcodePDF417Icon />,
    BARCODE_UPCA_ICON: <BarcodeUPCAIcon />,
    BARCODE_UPCE_ICON: <BarcodeUPCEIcon />,
    BARCODE_USPS_ICON: <BarcodeUSPSIcon />,
    BARCODE_INT2OF5_ICON: <BarcodeInt2of5Icon />,
    BARCODE_EAN128_ICON: <BarcodeEAN128Icon />,
    BARCODE_EAN13_ICON: <BarcodeEAN13Icon />,
    BARCODE_EAN8_ICON: <BarcodeEAN8Icon />,
    BARCODE_DATAMATRIX_ICON: <BarcodeDataMatrixIcon />,
    BARCODE_CODE128_ICON: <BarcodeCode128Icon />,
    BARCODE_CODE39_ICON: <BarcodeCode39Icon />,


    BREAK_ICON: <BreakIcon />,
    ELLIPSE_ICON: <EllipseIcon />,
    LINE_ICON: <LineIcon />,
    RECTANGLE_ICON: <RectangleIcon />,
    TEXTFIELD_ICON: <TextFieldIcon />,
    STATICTEXT_ICON: <StaticTextIcon />,
    SUBREPORT_ICON: <SubreportIcon />,
    GENERIC_ICON: <GenericIcon />,
    ADHOC_ICON: <AdHocIcon />,
    FRAME_ICON: <FrameIcon />,
    LIST_ICON: <ListIcon />,
    CHART_ICON: <ChartIcon />,
    HIGHCHART_ICON: <HighchartIcon />,
    MAP_ICON: <MapIcon />,
    TIBCOMAP_ICON: <TibcoMapIcon />,
    CUSTOMVIS_ICON: <CustomVisualizationIcon />,
    CHARTS_PRO: <ChartProIcon />,
    MAP_PRO: <MapProIcon />,
    TABLE_ICON: <TableIcon />,
    CROSSTAB_ICON: <CrosstabIcon />,
    IMAGE_ICON: <ImageIcon />,
    WIDGETSPRO_ICON: <WidgetsProIcon />,
    VARIABLE_ICON: <VariableIcon/ >,
    FIELD_ICON: <FieldIcon/ >,
    PARAMETER_ICON: <ParameterIcon/ >,

    VALUE_ICON: <ValueIcon/>,
    EXPRESSION_ICON: <ExpressionIcon/>,
}