/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { IRepositoryItemDescriptor, RepositoryApi } from '@jss/js-rest-api';
import { AxiosResponse } from 'axios';
import { Button, UxType, MenuItem } from '@jss/js-common';
import { RunContext } from '../../RunContainer';
import RefreshIcon from '@material-ui/icons/Refresh';

export interface IResourceRefreshProps {
    refresh: boolean;
    type?: UxType;
    size?: 'small' | 'medium' | 'large';
    disabled?: boolean;
    descriptor: IRepositoryItemDescriptor;
    onChange: (newDescriptor: IRepositoryItemDescriptor) => void;
}

export class ResourceRefresh extends React.Component<IResourceRefreshProps> {
    static defaultProps = {
        refresh: false,
        size: 'medium',
        disabled: false
    }

    public componentDidMount = () => {
        if (this.props.refresh)
            this.doUpdateFile();
    }

    render() {
        switch (this.props.type) {
            case UxType.BUTTON: return <Button onClick={this.doUpdateFile} disabled={this.props.disabled} size={this.props.size}>Refresh</Button>;
            case UxType.MENU: return <MenuItem label="Refresh" icon={<RefreshIcon fontSize="small" />} disabled={this.props.disabled} onClick={this.doUpdateFile} />;
        }
        return <></>;
    }

    private doUpdateFile = () => {
        const withData = this.props.descriptor.data !== undefined;
        const isMain = this.props.descriptor.isMainReport === true;
        if (this.props.descriptor.uuid)
            this.context.runNoCancelModal(RepositoryApi.inst().load)('uuid:' + this.props.descriptor.uuid, true, withData, true).then((response: AxiosResponse) => {
                const d = response.data;
                if (d) {
                    d.isMainReport = isMain;
                    this.props.onChange(d);
                }
            });
    }

}
ResourceRefresh.contextType = RunContext;