/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const ClientMailNotificationSendTypeEnum = new PEnum({
    id: 'ClientMailNotificationSendType',   isEmptyUndefined: true,
    items: [
        { key: '', value: '' },
        { key: 'SEND', value: 'ClientMailNotificationSendType.SEND' },
        { key: 'SEND_ATTACHMENT', value: 'ClientMailNotificationSendType.SEND_ATTACHMENT' },
        { key: 'SEND_ATTACHMENT_NOZIP', value: 'ClientMailNotificationSendType.SEND_ATTACHMENT_NOZIP' },
        { key: 'SEND_EMBED', value: 'ClientMailNotificationSendType.SEND_EMBED' },
        { key: 'SEND_ATTACHMENT_ZIP_ALL', value: 'ClientMailNotificationSendType.SEND_ATTACHMENT_ZIP_ALL' },
        { key: 'SEND_EMBED_ZIP_ALL_OTHERS', value: 'ClientMailNotificationSendType.SEND_EMBED_ZIP_ALL_OTHERS' }
    ]
});

