/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { fromJS, Map, List } from 'immutable';
import * as React from 'react';
import { DropTarget, DropTargetConnector, DropTargetMonitor, DropTargetSpec } from 'react-dnd';
import { connect } from 'react-redux';
import * as ReportActions from '../../actions/reportActions';
import { IState } from '../../reducers';
import * as DocumentUtils from '../../sagas/report/designer/documentUtils';
import { createDefaultDocument } from '../../sagas/report/document/documentFactory';
import { areaContainsPoint, Direction, IPoint, IRectangle } from '../../types/geometry';
import DndLayer from '../common/dnd/DndLayer';
import { Orientation, Scrollbar } from '../common/scrollbar/Scrollbar';
import BandResize from './elements/interaction/BandResize';
import DragElement from './elements/interaction/DragElement';
import { IReportPaletteElement } from './palette/paletteConstants';
import { PALETTE_ITEM } from './palette/ReportPaletteItem';

import '../../assets/uxpl/css/ReportDesigner.css';
import { PAGE_HORIZONTAL_MARGIN_MIN, PAGE_VERTICAL_OFFSET } from "../../sagas/report/designer/documentUtils";
import { IViewport } from './IViewport';

// Mouse events constants
const MOUSEMOVE = 'mousemove';
const MOUSEUP = 'mouseup';

interface IDesigner {
  model: Map<string, any>;
  report: Map<string, any>;
  viewport: IViewport,
  onMouseDown: (p: IPoint, options: { metaKey: boolean, shiftKey: boolean, touch?: boolean }) => void;
  onMouseUp: (p: IPoint, options: { metaKey: boolean, shiftKey: boolean }) => void;
  onMouseMove: (p: IPoint, options: { metaKey: boolean, shiftKey: boolean }, direction?: Direction) => void;
  viewportChanged: (rect: IRectangle) => void;
  setZoom: (val: number) => void;
  onScrollX: (val: number) => void;
  onScrollY: (val: number) => void;
  undo: () => void;
  redo: () => void;
  connectDropTarget?: (e: JSX.Element) => JSX.Element;
  onPaletteItemDrag: (point: IPoint, item: IReportPaletteElement | null) => void;
  onPaletteItemDrop: (point: IPoint, item: IReportPaletteElement) => void;
  createNewDocument: (template: any) => void;
  getPage: () => React.ReactElement;
}

const dropTargetCollect = (dropTargetConnect: DropTargetConnector, dropTargetMonitor: DropTargetMonitor) => {

  return {
    highlighted: dropTargetMonitor.canDrop(),
    hovered: dropTargetMonitor.isOver(),
    connectDropTarget: dropTargetConnect.dropTarget()
  };
}


/**
 * Simply check if the drag is actually inside the designer area.
 * 
 * @param props 
 * @param monitor 
 * @param component 
 */
const isDragEventValid = (props: IDesigner, monitor: DropTargetMonitor, component: SubeditorDesigner): boolean => {
  const clientOffset = monitor.getClientOffset();
  const designerAreaRef = component.getDesignerAreaRef();
  if (clientOffset &&
    designerAreaRef &&
    designerAreaRef.current) {

    // Check if the component is inside the visible area...
    const bbox = designerAreaRef.current.getBoundingClientRect();

    return areaContainsPoint({
      x: bbox.left,
      y: bbox.top,
      width: bbox.width,
      height: bbox.height
    }, clientOffset);
  }

  return false;
};

const dropTarget: DropTargetSpec<IDesigner> = {
  hover(props: IDesigner, monitor: DropTargetMonitor, component: SubeditorDesigner) {

    const clientOffset = monitor.getClientOffset();
    if (clientOffset === null) {
      return;
    }
    if (monitor.getItemType() === PALETTE_ITEM && isDragEventValid(props, monitor, component)) {
      props.onPaletteItemDrag(
        DocumentUtils.convertPageToDesignerCoords(clientOffset),
        monitor.getItem()
      );
    } else {
      props.onPaletteItemDrag(DocumentUtils.convertPageToDesignerCoords(clientOffset), null);
    }
  },

  // For now this method is not necessary
  // canDrop(props: IDesigner, monitor: DropTargetMonitor) {
  // },

  drop(props: IDesigner, monitor: DropTargetMonitor, component: SubeditorDesigner) {

    const clientOffset = monitor.getClientOffset();
    if (clientOffset && monitor.getItemType() === PALETTE_ITEM && isDragEventValid(props, monitor, component)) {
      props.onPaletteItemDrop(
        DocumentUtils.convertPageToDesignerCoords(clientOffset),
        monitor.getItem()
      );
    }
  }
};


/**
 * The Designer represents the area in which all the report interactions occur. It also manages stuff
 * like zoom, provides the scrollbars (which are based on a components), and takes care of handling
 * the root mouse events.
 * Mouse down events may be hijacked from other components, to produce specialized dragging ops
 * (i.e. the handles of a selected element).
 *
 * @class Designer
 * @extends {React.Component<IDesigner>}
 */
class SubeditorDesigner extends React.Component<IDesigner> {

  public state = {
    debugMsg: null
  }

  // Local variables. We don't keep any of them in a state, since nothing in the component
  // really depends by them.
  // Stores the event listeners registered at document level.
  // We may have an helper class for it.
  private listeners = {};
  private designerRef: React.RefObject<HTMLDivElement> = React.createRef();

  public getDesignerAreaRef = () => {
    return this.designerRef;
  }

  // Let's attach the listener on the document for dragging events.
  // The listeners will be attached only if we are actually dragging
  // the cursor bar...
  public componentDidMount = () => {

    this.listeners[MOUSEMOVE] = this.onMouseMove.bind(this);
    this.listeners[MOUSEUP] = this.onMouseUp.bind(this);

    for (const key in this.listeners) {
      if (this.listeners.hasOwnProperty(key)) {
        document.addEventListener(key, this.listeners[key]);
      }
    }

    // Let's create a new empty document...
    if (!this.props.model) {
      this.props.createNewDocument(createDefaultDocument());
    }
  }

  public componentWillUnmount = () => {

    for (const key in this.listeners) {
      if (this.listeners.hasOwnProperty(key)) {
        document.removeEventListener(key, this.listeners[key]);
      }
    }

    // Clear the list of event listeners..
    this.listeners = {};
  }

  public render() {
    const currentEditorIndex = this.props.report.getIn(['currentEditorIndex']);
    const currentEditor = this.props.report.getIn(['subeditors', currentEditorIndex]) as Map<string, any>;

    const scroll = currentEditor.get('scroll').toJS();
    const areaToDisplay = currentEditor.get('clientArea').toJS();
    const zoom = currentEditor.get('zoom');

    const fullHeight = areaToDisplay.height + (2 * PAGE_VERTICAL_OFFSET);
    const widthWithMarging = areaToDisplay.width + PAGE_HORIZONTAL_MARGIN_MIN * 2;
    const fullWidth = Math.max(widthWithMarging * zoom, this.props.viewport.width);
    let horizontalOffset = 0;
    if (widthWithMarging * zoom < this.props.viewport.width) {
      //the viewport is bigger than the report
      horizontalOffset = (this.props.viewport.width - areaToDisplay.width * zoom) / 2;
    } else {
      //the report is bigger thant the viewport
      horizontalOffset = PAGE_HORIZONTAL_MARGIN_MIN;
    }
    const designArea = (
      <div className="DesignerArea" style={{
        top: -scroll.y * zoom,
        left: -scroll.x * zoom,
        height: fullHeight,
        width: fullWidth
      }}
      >
        <div style={{ top: PAGE_VERTICAL_OFFSET, left: horizontalOffset, height: areaToDisplay.height, width: areaToDisplay.width, position: 'absolute' }}>
          <DndLayer zoom={zoom}/>
          {this.props.getPage()}
          {this.getInteractionElements()}
        </div>
      </div>
    );

    const designerContainerClasses = ['DesignerContainer'];

    if (!this.props.connectDropTarget) {
      return null;
    }
    const connectDropTarget = this.props.connectDropTarget;
    return connectDropTarget(
      <div className={designerContainerClasses.join(' ')}>

        {this.props.model ?
          (
            <>
              {/* We could optimize the left column by moving it to its own component
              and kick a refresh only if visible */}
              <div className="Designer" onWheel={this.wheel}
                onMouseDown={this.onMouseDown}
                onTouchStart={this.onTouchStart}
                onTouchMove={this.onTouchMove}
                onTouchEnd={this.onTouchEnd}
                onTouchCancel={this.onTouchEnd} // We trea the cancel just like the end of the touch...
                onContextMenu={this.onContextMenu}
                ref={this.designerRef}
              >
                {designArea}
                <Scrollbar
                  orientation={Orientation.HORIZONTAL}
                  viewportSize={this.props.viewport.width}
                  clientSize={widthWithMarging * zoom}
                  scroll={scroll.x}
                  onScroll={this.onScrollX}
                />
                <Scrollbar
                  orientation={Orientation.VERTICAL}
                  viewportSize={this.props.viewport.height}
                  clientSize={fullHeight * zoom}
                  scroll={scroll.y}
                  onScroll={this.onScrollY}
                />
              </div>
            </>) : null}
      </div>
    );
  }

  public enableScroll = () => {
    document.removeEventListener('wheel', this.preventDefault, false);
  }

  public disableScroll = () => {
    document.addEventListener('wheel', this.preventDefault,
      {
        passive: false,
      }
    );
  }

  public preventDefault(e: any) {
    e = e || window.event
    if (e.preventDefault) {
      e.preventDefault()
    }
    e.returnValue = false
  }


  private onMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    if (!this.props.model || e.button !== 0) {
      return;
    }

    const documentPoint = DocumentUtils.convertPageToDesignerCoords({ x: e.pageX, y: e.pageY });

    // Mouse pressed. Depending by what we are doing, we may handle different things...
    // Our role, in the component, is to just notify a reducer about or event.
    // The operation logic will be then executed there...
    this.props.onMouseDown(documentPoint, { metaKey: e.metaKey, shiftKey: e.shiftKey });
    // In an operarion is enabled, eat the event...
    e.stopPropagation();
    e.preventDefault();
  }

  private onMouseUp = (e: React.MouseEvent<HTMLElement>) => {
    if (!this.props.model) {
      return;
    }

    // In an operarion is enabled, eat the event...
    this.stopEventPropagation(e);

    const documentPoint = DocumentUtils.convertPageToDesignerCoords({ x: e.pageX, y: e.pageY });
    this.props.onMouseUp(documentPoint, { metaKey: e.metaKey, shiftKey: e.shiftKey });
  }


  /**
   * When a mouse event was initiated by the designer to start a drag operation, we want to stop
   * it's propagation so no other elements get this event.
   */
  private stopEventPropagation = (e: React.MouseEvent<HTMLElement> | React.TouchEvent) => {

    // If this event should be consumed only by the designer, let's stop
    // the event propagation...
    const currentEditorIndex = this.props.report.getIn(['currentEditorIndex']);
    const operation = this.props.report.getIn(['subeditors', currentEditorIndex, 'mouse', 'operation']);
    if (operation !== null) {
      e.stopPropagation();
      e.preventDefault();
    }

  }

  private onMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    if (!this.props.model) {
      return;
    }

    if (!this.designerRef.current) {
      return;
    }

    this.stopEventPropagation(e);

    const viewport = this.props.viewport;
    const viewportXEnd = viewport.x + viewport.width;
    const viewportYEnd = viewport.y + viewport.height;

    if (e.pageX <= viewportXEnd && e.pageY <= viewportYEnd) {
      const directions: string[] = [];
      // Check if we should scroll in any direction...
      if (Math.abs(viewportXEnd - e.pageX) <= 10) {
        directions.push('right')
      } else if (Math.abs(e.pageX - viewport.x) <= 10) {
        directions.push('left')
      }

      if (Math.abs(viewportYEnd - e.pageY) <= 10) {
        directions.push('bottom')
      } else if (Math.abs(e.pageY - viewport.y) <= 10) {
        directions.push('top');
      }

      const documentPoint = DocumentUtils.convertPageToDesignerCoords({ x: e.pageX, y: e.pageY });
      //console.log(`page coord ${e.pageX} ${e.pageY}` );
      this.props.onMouseMove(documentPoint, { metaKey: e.metaKey, shiftKey: e.shiftKey }, directions.length > 0 ? DocumentUtils.directionFromString(directions.join('-')) : undefined);

      // In an operarion is enabled, eat the event...
      // e.stopPropagation();
      // e.preventDefault()
    }
  }


  // Touch dragging events ----------------------------------

  private onTouchStart = (e: React.TouchEvent) => {

    if (!this.props.model) {
      return;
    }

    // Apparently on iOS pageY get's cut... so we need to relay on clientY...and calculate pageY by ourself...
    const documentPoint = DocumentUtils.convertPageToDesignerCoords({ x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY });
    this.props.onMouseDown(documentPoint, { metaKey: e.metaKey, shiftKey: e.shiftKey, touch: true });
    e.stopPropagation()
    e.preventDefault()
  }

  private onContextMenu = (e: React.MouseEvent<HTMLElement>) => {

    if (!this.props.model) {
      return;
    }

    // Prevent broswers from showing contextual menu in designer...
    e.stopPropagation()
    e.preventDefault()
  }



  private onTouchMove = (e: React.TouchEvent) => {

    if (!this.props.model) {
      return;
    }

    if (!this.designerRef.current) {
      return;
    }

    this.stopEventPropagation(e);

    const bbox = this.designerRef.current.getBoundingClientRect();

    const touchPoint = { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY };
    const directions: string[] = [];

    // TODO: Changeme. A bug in iOS Safari in getBoundingClientRect
    // provides an incorrect value of top.
    // As quick and dirty workaround, we set the top to 80 pixels
    // but this is subject to change in first place and may be dynamic if the editor
    // is embedded...
    const top = 80;

    // Check if we should scroll in any direction...
    if (touchPoint.y < top + 20) {
      directions.push('top');
    } else if (touchPoint.y > top + bbox.height - 20) {
      directions.push('bottom');
    }

    if (touchPoint.x < bbox.left + 20) {
      directions.push('left');
    }
    else if (touchPoint.x > bbox.left + bbox.width - 20) {
      directions.push('right');
    }

    // Apparently on iOS pageY get's cut... so we need to relay on clientY...and calculate pageY by ourself...
    const documentPoint = DocumentUtils.convertPageToDesignerCoords(touchPoint);

    this.props.onMouseMove(documentPoint, { metaKey: e.metaKey, shiftKey: e.shiftKey }, directions.length > 0 ? DocumentUtils.directionFromString(directions.join('-')) : undefined);

  }

  private onTouchEnd = (e: React.TouchEvent) => {

    if (!this.props.model) {
      return;
    }

    this.stopEventPropagation(e);

    const currentEditorIndex = this.props.report.getIn(['currentEditorIndex']);
    const currentLocation = this.props.report.getIn(['subeditors', currentEditorIndex, 'mouse', 'currentLocation']) as Map<string, any> | undefined;

    if (currentLocation) {
      // Fix point location, in case of touch end event, there is no location, 
      // so in this case we just use the latest location saved, if any.
      const latestLocation = (currentLocation.toJS() as unknown) as IPoint;
      this.props.onMouseUp(latestLocation, { metaKey: e.metaKey, shiftKey: e.shiftKey });
    }

  }

  /**
   * Handler for span using mouse wheel
   */
  private wheel = (e: React.WheelEvent) => {
    e.stopPropagation();

    const currentEditorIndex = this.props.report.getIn(['currentEditorIndex']);
    const zoom = this.props.report.getIn(['subeditors', currentEditorIndex, 'zoom']) as number;

    if (e.metaKey) {
      // Change the zoom...
      this.props.setZoom(zoom + e.deltaY / 100.0);
    }
    else {
      if (e.deltaX !== 0) {
        this.props.onScrollX(e.deltaX);
      }
      if (e.deltaY !== 0) {
        if (e.shiftKey && e.deltaX === 0) { // Use shift if the mouse wheel does not support horizontal scrolling.
          this.props.onScrollX(e.deltaY);
        }
        else {
          this.props.onScrollY(e.deltaY);
        }
      }
    }
  }

  private onScrollX = (delta: number) => {
    this.props.onScrollX(delta);
  }


  private onScrollY = (delta: number) => {
    this.props.onScrollY(delta);
  }

  private getInteractionElements = () => {

    const currentEditorIndex = this.props.report.getIn(['currentEditorIndex']);
    const interactionElements = this.props.report.getIn(['subeditors', currentEditorIndex, 'interactionElements']) as List<Map<string, any>>;
    const zoom = this.props.report.getIn(['subeditors', currentEditorIndex, 'zoom']);
    // Unfortunately {...ele.props} does not seem to work very well...
    const listItems = interactionElements.toList().map((ele: Map<string, any>) => {
      const props = ele.get('props').toJS();
      const component = ele.get('component', null);
      const componentProps = ele.get('componentProps', null) ? ele.get('componentProps', null).toJS() : {};


      if (component === 'BandResize') {
        return (<BandResize key={props.key} zoom={zoom} {...props} {...componentProps} />)
      }

      return (<DragElement key={props.key} zoom={zoom} {...props}  {...componentProps} />);

    });
    return listItems;
  }
}



const mapStateToProps = (state: IState) => {
  return {
    // Plain javascript properties
    viewport: ((state.getIn(['report', 'viewport']) as Map<string, any>).toJS() as unknown) as IViewport,
    model: state.getIn(['report', 'model']) as Map<string, any>,
    report: state.getIn(['report']) as Map<string, any>,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    createNewDocument: (template: any) => {
      dispatch(ReportActions.createNewDocument(template));
    },
    viewportChanged: (viewport: IRectangle) => {
      dispatch(ReportActions.viewportChanged(fromJS(viewport)));
    },
    onScrollX: (x: number) => {
      dispatch(ReportActions.scrollX(x));
    },
    onScrollY: (y: number) => {
      dispatch(ReportActions.scrollY(y));
    },
    setZoom: (val: number) => {
      dispatch(ReportActions.setZoom(val));
    },
    onMouseDown: (point: IPoint, options: any) => { dispatch(ReportActions.mouseDown(point, options)); }, // new DesignerActions.SetMouseDown(point, options) ); },
    onMouseMove: (point: IPoint, options: any, direction?: Direction) => { dispatch(ReportActions.mouseMove(point, options, direction ? direction : undefined)); },
    onMouseUp: (point: IPoint, options: any) => { dispatch(ReportActions.mouseUp(point, options)); },
    undo: () => { dispatch(ReportActions.undo()); },
    redo: () => { dispatch(ReportActions.redo()); },
    onPaletteItemDrag: (point: IPoint, item: IReportPaletteElement | null) => { dispatch(ReportActions.paletteItemDrag(point, item)); },
    onPaletteItemDrop: (point: IPoint, item: IReportPaletteElement) => { dispatch(ReportActions.paletteItemDrop(point, item)); },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DropTarget(PALETTE_ITEM, dropTarget, dropTargetCollect)(SubeditorDesigner));
