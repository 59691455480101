/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import {   reg } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';

import UPTableDetail from './UPTableDetail';

const TYPEID = 'connectedTableDetail';
export class PConnectedTableDetail extends APDescriptor {
    default?: any;
    expanded?: boolean = false;
    rowLabel?: string;
    rowLabelPaths?: string[];
    maxRows?: number;
    public constructor(init: Partial<PConnectedTableDetail>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPTableDetail mcontext={mc} />; });


