/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Accordion, AccordionDetails, AccordionSummary, EditableTable, Select, TextInput, Typography } from '@jss/js-common';
import { Tab, Tabs } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import { TabContext, TabPanel } from '@material-ui/lab';
import * as React from 'react';
import { createNode, getChilNodeByName, IDataAdapterConfig } from '.';
import '../assets/css/DataSourceEditor.css';
import i18n from '../i18n';

interface IXmlDataAdapterEditor {
    HTTPUsername?: string,
    HTTPPassword?: string,
    HTTPType?: string,
    HTTPURLParameterNames?: string[],
    HTTPURLParameterValues?: string[],
    HTTPHeaderParameterNames?: string[],
    HTTPHeaderParameterValues?: string[],
    HTTPPOSTParameterNames?: string[],
    HTTPPOSTParameterValues?: string[],
    HTTPPOSTBodyText?: string,
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

interface IState {
    expanded: boolean,
    selectedTab: string,
    allowPostBodyText: boolean,
}

const readParameter = (parameterNode: Element) => {
    let name;
    let value;
    parameterNode.childNodes.forEach((childNode) => {
        const nodeName = childNode.nodeName;
        if (nodeName === 'name') {
            name = childNode.textContent;
        } else if (nodeName === 'value') {
            value = childNode.textContent;
        }
    });
    return { name, value };
}

const createParameter = (xmlDoc: XMLDocument, datafile: Element, tagName: string, parameterName: string, parameterValue: string | undefined) => {
    const newEle = xmlDoc.createElement(tagName);

    const nameNode = xmlDoc.createElement('name');
    const nameNodeText = xmlDoc.createTextNode(parameterName);
    nameNode.appendChild(nameNodeText);

    const valueNode = xmlDoc.createElement('value');
    const valueNodeText = xmlDoc.createTextNode(parameterValue);
    valueNode.appendChild(valueNodeText);

    newEle.appendChild(nameNode);
    newEle.appendChild(valueNode);

    datafile.appendChild(newEle);
}

export const writeXML = (xmlDoc: XMLDocument, config: IDataAdapterConfig, datafile: Element) => {
    const userNameNode = createNode(xmlDoc, config, 'HTTPUsername', 'username');
    if (userNameNode) {
        datafile.appendChild(userNameNode);
    }
    const passwordNode = createNode(xmlDoc, config, 'HTTPPassword', 'password');
    if (passwordNode) {
        datafile.appendChild(passwordNode);
    }

    const bodyNode = createNode(xmlDoc, config, 'HTTPPOSTBodyText', 'body');
    if (bodyNode) {
        datafile.appendChild(bodyNode);
    }

    const methodValue = config['HTTPType'] ? config['HTTPType'] : 'GET';
    const newEle = xmlDoc.createElement('method');
    const newText = xmlDoc.createTextNode(methodValue);
    newEle.appendChild(newText);
    datafile.appendChild(newEle);

    const HTTPURLParameterNames = config['HTTPURLParameterNames'];

    if (HTTPURLParameterNames) {
        const HTTPURLParameterValues = config['HTTPURLParameterValues'] ? config['HTTPURLParameterValues'] : [];
        HTTPURLParameterNames.forEach((HTTPURLParameterName: string, index) => {
            createParameter(xmlDoc, datafile, 'urlParameter', HTTPURLParameterName, HTTPURLParameterValues[index]);
        });
    }

    const HTTPHeaderParameterNames = config['HTTPHeaderParameterNames'];

    if (HTTPHeaderParameterNames) {
        const HTTPHeaderParameterValues = config['HTTPHeaderParameterValues'] ? config['HTTPHeaderParameterValues'] : [];
        HTTPHeaderParameterNames.forEach((HTTPHeaderParameterName: string, index) => {
            createParameter(xmlDoc, datafile, 'header', HTTPHeaderParameterName, HTTPHeaderParameterValues[index]);
        });
    }

    const HTTPPOSTParameterNames = config['HTTPPOSTParameterNames'];

    if (HTTPPOSTParameterNames) {
        const HTTPPOSTParameterValues = config['HTTPPOSTParameterValues'] ? config['HTTPPOSTParameterValues'] : [];
        HTTPPOSTParameterNames.forEach((HTTPPOSTParameterName: string, index) => {
            createParameter(xmlDoc, datafile, 'postParameter', HTTPPOSTParameterName, HTTPPOSTParameterValues[index]);
        });
    }
}

export const readXML = (datafileNode: Element): object => {
    const result: any = {}
    const methodNode = getChilNodeByName(datafileNode, 'method');
    if (methodNode) {
        result.HTTPType = methodNode.textContent.toLowerCase();
    }
    const usernameNode = getChilNodeByName(datafileNode, 'username');
    if (usernameNode) {
        result.HTTPUsername = usernameNode.textContent.toLowerCase();
    }
    const passwordNode = getChilNodeByName(datafileNode, 'password');
    if (passwordNode) {
        result.HTTPPassword = passwordNode.textContent.toLowerCase();
    }
    const bodyNode = getChilNodeByName(datafileNode, 'body');
    if (bodyNode) {
        result.HTTPPOSTBodyText = bodyNode.textContent.toLowerCase();
    }
    const HTTPURLParameterNames = [];
    const HTTPURLParameterValues = [];
    const HTTPHeaderParameterNames = [];
    const HTTPHeaderParameterValues = [];
    const HTTPPOSTParameterNames = [];
    const HTTPPOSTParameterValues = [];
    datafileNode.childNodes.forEach((childNode) => {
        const nodeName = childNode.nodeName;
        if (nodeName === 'urlParameter') {
            const parameter = readParameter(childNode as Element);
            HTTPURLParameterNames.push(parameter.name);
            HTTPURLParameterValues.push(parameter.value);
        } else if (nodeName === 'header') {
            const parameter = readParameter(childNode as Element);
            HTTPHeaderParameterNames.push(parameter.name);
            HTTPHeaderParameterValues.push(parameter.value);
        } else if (nodeName === 'postParameter') {
            const parameter = readParameter(childNode as Element);
            HTTPPOSTParameterNames.push(parameter.name);
            HTTPPOSTParameterValues.push(parameter.value);
        }
    });


    return result;
}

export class HTTPConfigurationPanel extends React.Component<IXmlDataAdapterEditor, IState> {

    state = {
        expanded: false,
        selectedTab: 'urlParameters',
        allowPostBodyText: false,
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }

    handleExpanded = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    private onUsernameChange = (str: string) => {
        this.notifyChange({ HTTPUsername: str });
    }
    private onBodyTextChange = (str: string) => {
        this.notifyChange({ HTTPPOSTBodyText: str });
    }

    private onPasswordchange = (str: string) => {
        this.notifyChange({ HTTPPassword: str });
    }

    private getHTTPType = () => {
        if (this.props.HTTPType !== undefined) {
            return this.props.HTTPType;
        }
        return 'get';
    }

    private onTypeChange = (str: string) => {
        this.setState({ selectedTab: 'urlParameters' }, () => {
            this.notifyChange({ HTTPType: str });
        });
    }

    getColumnSize = () => {
        /*const width = document.getElementById('tableContainer')?.clientWidth;
        if (width){
            return [width * 2/3, width * 1/3];
        }
        return undefined;*/
        return [400 * 2 / 3, 400 * 1 / 3];
    }

    getRows = (parameterNames: string[] | undefined, parameterValues: string[] | undefined) => {
        const result: string[][] = [];
        const rows = parameterNames ? [...parameterNames] : [];
        const values = parameterValues ? [...parameterValues] : [];
        rows.forEach((columnName: string, index: number) => {
            result.push([columnName, values[index]]);
        });
        return result;
    }

    private getFirstAvailableIndex = (currentNames: string[]) => {
        let index = 1;
        let found = false;
        while (!found) {
            const newName = 'PARAMETER_' + index;
            const elementIndex = currentNames.findIndex((value: string) => {
                return value.toUpperCase().trim() === newName;
            });
            if (elementIndex === -1) {
                found = true;
            } else {
                index += 1;
            }
        }
        return index;
    }

    private onAddURLParameterAction = () => {
        const rows = this.props.HTTPURLParameterNames ? [...this.props.HTTPURLParameterNames] : [];
        const rowIndexes = this.props.HTTPURLParameterValues ? [...this.props.HTTPURLParameterValues] : [];
        const index = this.getFirstAvailableIndex(rows);
        rows.push('PARAMETER_' + index);
        rowIndexes.push('Value');
        this.notifyChange({ HTTPURLParameterNames: rows, HTTPURLParameterValues: rowIndexes });
    }

    private onAddPOSTParameterAction = () => {
        const rows = this.props.HTTPPOSTParameterNames ? [...this.props.HTTPPOSTParameterNames] : [];
        const rowIndexes = this.props.HTTPPOSTParameterValues ? [...this.props.HTTPPOSTParameterValues] : [];
        const index = this.getFirstAvailableIndex(rows);
        rows.push('PARAMETER_' + index);
        rowIndexes.push('Value');
        this.notifyChange({ HTTPPOSTParameterNames: rows, HTTPPOSTParameterValues: rowIndexes });
    }

    private onAddHTTPHeaderParameterAction = () => {
        const rows = this.props.HTTPHeaderParameterNames ? [...this.props.HTTPHeaderParameterNames] : [];
        const rowIndexes = this.props.HTTPHeaderParameterValues ? [...this.props.HTTPHeaderParameterValues] : [];
        const index = this.getFirstAvailableIndex(rows);
        rows.push('PARAMETER_' + index);
        rowIndexes.push('Value');
        this.notifyChange({ HTTPHeaderParameterNames: rows, HTTPHeaderParameterValues: rowIndexes });
    }

    onURLParametersCellChange = (row: number, col: number, value: string, isEditing: boolean) => {
        const newParams = this.onCellChange(this.props.HTTPURLParameterNames, this.props.HTTPURLParameterValues, row, col, value, isEditing);
        if (!isEditing) {
            this.notifyChange({ HTTPURLParameterNames: newParams.columnNames, HTTPURLParameterValues: newParams.columnValues });
        }
    }

    onPOSTParametersCellChange = (row: number, col: number, value: string, isEditing: boolean) => {
        const newParams = this.onCellChange(this.props.HTTPPOSTParameterNames, this.props.HTTPPOSTParameterValues, row, col, value, isEditing);
        if (!isEditing) {
            this.notifyChange({ HTTPPOSTParameterNames: newParams.columnNames, HTTPPOSTParameterValues: newParams.columnValues });
        }
    }

    onHTTPParameterCellChange = (row: number, col: number, value: string, isEditing: boolean) => {
        const newParams = this.onCellChange(this.props.HTTPHeaderParameterNames, this.props.HTTPHeaderParameterValues, row, col, value, isEditing);
        if (!isEditing) {
            this.notifyChange({ HTTPHeaderParameterNames: newParams.columnNames, HTTPHeaderParameterValues: newParams.columnValues });
        }
    }

    onCellChange = (parameterNames: string[] | undefined, parameterValues: string[] | undefined, row: number, col: number, value: string, isEditing: boolean) => {
        const rowNames = parameterNames ? [...parameterNames] : [];
        const rowIndexes = parameterValues ? [...parameterValues] : [];
        if (col === 0 && value.trim().length === 0 && !isEditing) {
            //has erased a row, delete the row
            rowNames.splice(row, 1);
            rowIndexes.splice(row, 1);
        } else if (!isEditing) {
            if (col === 0) {
                rowNames[row] = value;
            } else if (col === 1) {
                rowIndexes[row] = value;
            }
        }
        return { columnNames: rowNames, columnValues: rowIndexes };
    }

    onURLParametersDelete = (rowIndex: number) => {
        const newColumnsNames = [...this.props.HTTPURLParameterNames];
        newColumnsNames.splice(rowIndex, 1);
        const newColumnsIndexes = [...this.props.HTTPURLParameterValues];
        newColumnsIndexes.splice(rowIndex, 1);
        this.notifyChange({ HTTPURLParameterNames: newColumnsNames, HTTPURLParameterValues: newColumnsIndexes });
    }

    onPOSTParametersDelete = (rowIndex: number) => {
        const newColumnsNames = [...this.props.HTTPPOSTParameterNames];
        newColumnsNames.splice(rowIndex, 1);
        const newColumnsIndexes = [...this.props.HTTPPOSTParameterValues];
        newColumnsIndexes.splice(rowIndex, 1);
        this.notifyChange({ HTTPPOSTParameterNames: newColumnsNames, HTTPPOSTParameterValues: newColumnsIndexes });
    }

    onHTTPHeaderParametersDelete = (rowIndex: number) => {
        const newColumnsNames = [...this.props.HTTPHeaderParameterNames];
        newColumnsNames.splice(rowIndex, 1);
        const newColumnsIndexes = [...this.props.HTTPHeaderParameterValues];
        newColumnsIndexes.splice(rowIndex, 1);
        this.notifyChange({ HTTPHeaderParameterNames: newColumnsNames, HTTPHeaderParameterValues: newColumnsIndexes });
    }

    getTabContent = () => {
        const URLParameterTableRows = this.getRows(this.props.HTTPURLParameterNames, this.props.HTTPURLParameterValues);
        const HTTPParameterTableRows = this.getRows(this.props.HTTPHeaderParameterNames, this.props.HTTPHeaderParameterValues);
        const POSTParameterTableRows = this.getRows(this.props.HTTPPOSTParameterNames, this.props.HTTPHeaderParameterValues);
        const buttons = [
            <TabPanel key={'urlParameters'} value="urlParameters" className="tc-jsw-datasource-editor-categories-no-padding">
                <div style={{ display: 'flex', alignItems: 'stretch', marginTop: 10, flexDirection: 'column' }} id="urlTableContainer">
                    <EditableTable cellStyle={{ textAlign: 'left' }} columnsWidths={this.getColumnSize()} onCellChange={this.onURLParametersCellChange} cellData={URLParameterTableRows} numRows={this.props.HTTPURLParameterNames ? this.props.HTTPURLParameterNames.length + 1 : 1} columnNames={[i18n.t('datasource.html.table.urlparameter.name'), i18n.t('datasource.html.table.parameter.value'), '']}
                        columnActions={[{ onClick: this.onURLParametersDelete, icon: <DeleteIcon /> }]} onAddAction={this.onAddURLParameterAction} readOnly={this.props.readOnly}/>
                </div>
            </TabPanel>,
            <TabPanel key={'postParameters'} value="postParameters" className="tc-jsw-datasource-editor-categories-no-padding">
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.allowPostBodyText}
                                onChange={() => { this.setState({ allowPostBodyText: !this.state.allowPostBodyText }); }}
                                name="allowBodyText"
                                color="primary"
                                disabled={this.props.readOnly}
                            />
                        }
                        label={i18n.t('datasource.html.allowBodyText')}
                    />
                    {
                        this.state.allowPostBodyText ?
                            <TextInput
                                id="data-adapter-body-text"
                                className={'tc-jsw-dataadapter-editor-text-input'}
                                style={{ paddingTop: 0, marginTop: 0 }}
                                // inputStyles={{ textAlign: 'center' }}
                                value={this.props.HTTPPOSTBodyText}
                                multiline={true}
                                rows={8}
                                onChange={this.onBodyTextChange}
                                InputProps={{
                                    readOnly: this.props.readOnly,
                                }}/>
                            :
                            <div style={{ display: 'flex', alignItems: 'stretch', marginTop: 10, flexDirection: 'column' }} id="httpHeaderTableContainer">
                                <EditableTable cellStyle={{ textAlign: 'left' }} columnsWidths={this.getColumnSize()} onCellChange={this.onPOSTParametersCellChange} cellData={POSTParameterTableRows} numRows={this.props.HTTPPOSTParameterNames ? this.props.HTTPPOSTParameterNames.length + 1 : 1} columnNames={[i18n.t('datasource.html.table.postparameter.name'), i18n.t('datasource.html.table.parameter.value'), '']}
                                    columnActions={[{ onClick: this.onPOSTParametersDelete, icon: <DeleteIcon /> }]} onAddAction={this.onAddPOSTParameterAction} readOnly={this.props.readOnly}/>
                            </div>
                    }
                </div>
            </TabPanel>,
            <TabPanel key={'putParameters'} value="putParameters" className="tc-jsw-datasource-editor-categories-no-padding">
                <div style={{ display: 'flex', alignItems: 'stretch', marginTop: 10, flexDirection: 'column' }} id="httpHeaderTableContainer">
                    <EditableTable cellStyle={{ textAlign: 'left' }} columnsWidths={this.getColumnSize()} onCellChange={this.onPOSTParametersCellChange} cellData={POSTParameterTableRows} numRows={this.props.HTTPPOSTParameterNames ? this.props.HTTPPOSTParameterNames.length + 1 : 1} columnNames={[i18n.t('datasource.html.table.putparameter.name'), i18n.t('datasource.html.table.parameter.value'), '']}
                        columnActions={[{ onClick: this.onPOSTParametersDelete, icon: <DeleteIcon /> }]} onAddAction={this.onAddPOSTParameterAction} readOnly={this.props.readOnly}/>
                </div>
            </TabPanel>,
            <TabPanel key={'httpHeaderParameters'} value="httpHeaderParameters" className="tc-jsw-datasource-editor-categories-no-padding">
                <div style={{ display: 'flex', alignItems: 'stretch', marginTop: 10, flexDirection: 'column' }} id="httpHeaderTableContainer">
                    <EditableTable cellStyle={{ textAlign: 'left' }} columnsWidths={this.getColumnSize()} onCellChange={this.onHTTPParameterCellChange} cellData={HTTPParameterTableRows} numRows={this.props.HTTPHeaderParameterNames ? this.props.HTTPHeaderParameterNames.length + 1 : 1} columnNames={[i18n.t('datasource.html.table.httpparameter.name'), i18n.t('datasource.html.table.parameter.value'), '']}
                        columnActions={[{ onClick: this.onHTTPHeaderParametersDelete, icon: <DeleteIcon /> }]} onAddAction={this.onAddHTTPHeaderParameterAction} readOnly={this.props.readOnly}/>
                </div>
            </TabPanel>,
        ];
        return buttons;
    }

    public render() {

        const tabs = [<Tab key={'urlParameters'} label={i18n.t('datasource.html.URLParameters')} value="urlParameters" />];
        const httpType = this.getHTTPType();
        if (httpType === 'post') {
            tabs.push(<Tab key={'postParameters'} label={i18n.t('datasource.html.POSTParameters')} value="postParameters" />);
        }
        if (httpType === 'put') {
            tabs.push(<Tab key={'putParameters'} label={i18n.t('datasource.html.PUTParameters')} value="putParameters" />);
        }
        tabs.push(<Tab key={'httpHeaderParameters'} label={i18n.t('datasource.html.HTTPHeader')} value="httpHeaderParameters" />);

        return <Accordion expanded={this.state.expanded} style={{ marginTop: 10 }} onChange={this.handleExpanded} >
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className="js-jrws-search-accordion-header" >
                <Typography>{i18n.t('datasource.html.httpOptionsPanel')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
                    <TextInput label={i18n.t('datasource.html.username')}
                        id="http-username"
                        className={'tc-jsw-dataadapter-editor-text-input'}
                        // inputStyles={{ textAlign: 'center' }}
                        style={{ marginTop: 0 }}
                        autoComplete="off"
                        value={this.props.HTTPUsername}
                        onChange={this.onUsernameChange} 
                        InputProps={{
                            readOnly: this.props.readOnly,
                        }}/>

                    <TextInput label={i18n.t('datasource.html.password')}
                        id="data-adapter-password"
                        className={'tc-jsw-dataadapter-editor-text-input'}
                        style={{ paddingTop: 0, marginTop: 0 }}
                        type="password"
                        autoComplete="off"
                        // inputStyles={{ textAlign: 'center' }}
                        value={this.props.HTTPPassword}
                        onChange={this.onPasswordchange}                   
                        InputProps={{
                            readOnly: this.props.readOnly,
                        }}/>
                    <Select className={'tc-jsw-dataadapter-editor-type'}
                        style={{ marginTop: 0 }}
                        label={i18n.t('datasource.html.type')}
                        items={[{ key: 'get', value: 'GET' }, { key: 'post', value: 'POST' }, { key: 'put', value: 'PUT' }]}
                        value={this.getHTTPType()} onItemSelected={this.onTypeChange} 
                        InputProps={{
                            readOnly: this.props.readOnly,
                        }}/>
                    <TabContext value={this.state.selectedTab}>
                        <div>
                            <Tabs
                                value={this.state.selectedTab}
                                onChange={(event, newValue) => { this.setState({ selectedTab: newValue }) }}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="auto tabs example" >
                                {tabs}
                            </Tabs>
                        </div>
                        {this.getTabContent()}
                    </TabContext>
                </div>
            </AccordionDetails>
        </Accordion>
    }


}