/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const TextPositionEnum = new PEnum({
    id: 'textPosition', label: 'TextPosition.label', isEmptyUndefined: true,
    items: [
        { key: '', value: '' },
        { key: 'none', value: 'TextPosition.none' },
        { key: 'bottom', value: 'TextPosition.bottom' },
        { key: 'top', value: 'TextPosition.top' }
    ]
}); 

