/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React, { forwardRef } from 'react';
import { Divider as MuiDivider, DividerProps } from '@material-ui/core';
import '../../assets/uxpl/css/MenuDivider.css';

// eslint-disable-next-line react/display-name
export const MenuDivider = forwardRef<HTMLHRElement, DividerProps>(({
    className = '', ...rest
}, ref) => {
    return (
        <MuiDivider ref={ref} component="div" className="jr-jrws-menu-divider" classes={{ root: `mui ${className}` }} {...rest} />
    )
});
