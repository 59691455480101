/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import '../../../assets/uxpl/css/ReportPaletteItem.css';

import * as React from 'react';
import {
      ConnectDragPreview,
      ConnectDragSource,
      DragSource,
      DragSourceCollector,
      DragSourceConnector,
      DragSourceMonitor,
} from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import * as ReportActions from '../../../actions/reportActions';
import { PALETTE_ITEM_POSTFIX } from '../../common/palette/Palette';
import { IReportPaletteElement } from './paletteConstants';
import { connect } from 'react-redux';
import i18n from '../../../i18n';


export interface IReportPaletteItem extends IReportPaletteItemDragSource {
      item: IReportPaletteElement;
      paletteItemDragStart?: () => void;
      paletteItemDragEnd?: () => void;
}

interface IReportPaletteItemDragSource {
      connectDragSource?: ConnectDragSource
      connectDragPreview?: ConnectDragPreview
      isDragging?: boolean
}


export const PALETTE_ITEM = 'REPORT' + PALETTE_ITEM_POSTFIX;


/**
 * Specifies which props to inject into your component.
 */
const dragSourceCollect: DragSourceCollector<IReportPaletteItemDragSource, any> = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
});


/**
 * Specifies the drag source contract.
 * Only `beginDrag` function is required.
 */
const paletteItemSource = {
      beginDrag(props: IReportPaletteItem) {
            // Return the data palette item.
            props.paletteItemDragStart();
            return props.item;
      },

      endDrag(props: IReportPaletteItem, monitor: DragSourceMonitor) {
            props.paletteItemDragEnd();
            if (!monitor.didDrop()) {
                  return;
            }

            // When dropped on a compatible target, do something
            // const item = monitor.getItem();
            // const dropResult = monitor.getDropResult();
      }
};


class PaletteItem extends React.Component<IReportPaletteItem> {

      public componentDidMount() {

            const { connectDragPreview } = this.props
            if (connectDragPreview) {
                  // As drag preview we use an empty image.
                  // The real image will be displayed in form of react component in the DndLayer
                  connectDragPreview(getEmptyImage(), {
                        // IE fallback: specify that we'd rather screenshot the node
                        // when it already knows it's being dragged so we can hide it with CSS.
                        captureDraggingState: true
                  });
            }
      }

      public render() {
            if (this.props.item.break === true) {
                  return <div className="PaletteItemBreak"> </div>
            }

            const { connectDragSource } = this.props;

            let icon = null;
            if (this.props.item.icon) {
                  icon = this.props.item.icon
                  if (typeof icon === "string")
                        icon = <img src={icon as string} />;
            }

            return connectDragSource && connectDragSource(<div className="PaletteItem">{icon} {i18n.t(this.props.item.label)}</div>);
      }
}

const mapDispatchToProps = (dispatch: any) => {
      return {
            paletteItemDragStart: () => {
                  dispatch(ReportActions.paletteItemDragStart());
            },
            paletteItemDragEnd: () => {
                  dispatch(ReportActions.paletteItemDragEnd());
            },
      };
}

export default connect(null, mapDispatchToProps)(DragSource(PALETTE_ITEM, paletteItemSource, dragSourceCollect)(PaletteItem));