/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const TextFieldIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/palette/text-field" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M11.5,3 L11.5,15 L1,15 C0.44771525,15 6.76353751e-17,14.5522847 0,14 L0,4 C-6.76353751e-17,3.44771525 0.44771525,3 1,3 L11.5,3 Z M19,3 C19.5522847,3 20,3.44771525 20,4 L20,14 C20,14.5522847 19.5522847,15 19,15 L15.5,15 L15.5,3 L19,3 Z" id="Combined-Shape" fill="#C1DCF5"></path>
                <path d="M13,1.125 L11.5,1.125 C11.2238576,1.125 11,0.901142375 11,0.625 L11,0.5 C11,0.223857625 11.2238576,5.07265313e-17 11.5,0 L15.5,0 C15.7761424,-5.07265313e-17 16,0.223857625 16,0.5 L16,0.625 C16,0.901142375 15.7761424,1.125 15.5,1.125 L14,1.125 L14,16.875 L15.5,16.875 C15.7761424,16.875 16,17.0988576 16,17.375 L16,17.5 C16,17.7761424 15.7761424,18 15.5,18 L11.5,18 C11.2238576,18 11,17.7761424 11,17.5 L11,17.375 C11,17.0988576 11.2238576,16.875 11.5,16.875 L13,16.875 L13,1.125 Z" id="Combined-Shape" fill="#318DDE"></path>
                <path d="M4.746,12.108 C5.295,12.108 5.772,11.829 6.186,11.478 L6.213,11.478 L6.285,12 L6.897,12 L6.897,9.309 C6.897,8.229 6.438,7.518 5.376,7.518 C4.674,7.518 4.062,7.815 3.657,8.085 L3.945,8.589 C4.287,8.364 4.746,8.139 5.25,8.139 C5.961,8.139 6.141,8.67 6.15,9.219 C4.278,9.426 3.468,9.894 3.468,10.866 C3.468,11.658 4.017,12.108 4.746,12.108 Z M4.953,11.496 C4.53,11.496 4.197,11.307 4.197,10.821 C4.197,10.254 4.674,9.921 6.15,9.75 L6.15,10.938 C5.727,11.307 5.376,11.496 4.953,11.496 Z" id="a" fill="#062E79" fillRule="nonzero"></path>
            </g>
        </svg>
    );
}
export default TextFieldIcon;