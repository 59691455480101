/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { LineSpacingEnum } from "../enums/LineSpacingEnum";
import { TabStopAlignEnum } from "../enums/TabStopAlignEnum";
import { PInt } from "../../ui/primitive/UPInteger";
import { PConnectedTable } from "../../ui/tables/UPConnectedTable";
import { PTableElement } from "../../ui/tables/UPTableElement";

export const FParagraph = {
    lineSpacing: { ...LineSpacingEnum, className: 'span2' },
    spacingBefore: new PInt({ id: 'spacingBefore', label: 'Before', min: 0 /*, className: 'labelAlignRight'*/ }),
    spacingAfter: new PInt({ id: 'spacingAfter', label: 'After', min: 0 /*, className: 'labelAlignRight'*/ }),
    spacingSize: new PInt({ id: 'spacingSize', label: 'Size', className: 'span2', min: 0 }),


    rightIndent: new PInt({ id: 'rightIndent', label: 'Right', min: 0, /*, className: 'labelAlignRight'*/ }),
    leftIndent: new PInt({ id: 'leftIndent', label: 'Left', min: 0 /*, className: 'labelAlignRight' */ }),
    firstLineIndent: new PInt({ id: 'firstLineIndent', label: 'First Line', className: 'span2', min: 0 }),

    tabStopWidth: new PInt({ id: 'tabStopWidth', label: 'Width', min: 0 })
};

export const FParagraphTabStop = {
    alignment: TabStopAlignEnum,
    position: new PInt({ id: 'position', label: 'Position' })
};

export const MParagraph = new PAccordeon({
    id: 'paragraph', label: 'Paragraph', path: 'paragraph',
    layouts: [new PAccordeon({
        id: 'spacing', label: 'Spacing',
        //style: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: 10 },
        layouts: [FParagraph.lineSpacing, FParagraph.spacingSize, FParagraph.spacingBefore, FParagraph.spacingAfter]
    }),
    new PAccordeon({
        id: 'indent', label: 'Indent',
        //style: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: 10 },
        layouts: [FParagraph.rightIndent, FParagraph.leftIndent, FParagraph.firstLineIndent]
    }),
    new PAccordeon({
        id: 'tabStop', label: 'Tab Stops',
        layouts: [FParagraph.tabStopWidth, new PConnectedTable({
            id: "tabStops", label: 'Stops', rowLabel: 'Tab Stop', rowLabelPaths: ['position'], layouts: [
                new PTableElement({
                    label: 'Tab Stop',
                    layouts: [FParagraphTabStop.position, FParagraphTabStop.alignment],
                    anchorOrigin: {vertical: 'center', horizontal: 'right'},
                    transformOrigin: {vertical: 'center', horizontal: 'right'},
                })],
            default: { position: 0, alignment: 'Left' }
        })]
    })]
});

export const MParagraphStyle = new PAccordeon({
    id: 'paragraph', label: 'Paragraph', path: 'paragraph',
    layouts: [new PAccordeon({
        id: 'spacing', label: 'Spacing',
        //style: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: 10 },
        layouts: [{ ...FParagraph.lineSpacing, default: undefined },
        { ...FParagraph.spacingSize, default: undefined },
        { ...FParagraph.spacingBefore, default: undefined },
        { ...FParagraph.spacingAfter, default: undefined }]
    }),
    new PAccordeon({
        id: 'indent', label: 'Indent',
        //style: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: 10 },
        layouts: [{ ...FParagraph.rightIndent, default: undefined },
        { ...FParagraph.leftIndent, default: undefined },
        { ...FParagraph.firstLineIndent, default: undefined }]
    }),
    new PAccordeon({
        id: 'tabStop', label: 'Tab Stops',
        layouts: [{ ...FParagraph.tabStopWidth, default: undefined }, new PConnectedTable({
            id: "tabStops", label: 'Stops', rowLabel: 'Tab Stop', rowLabelPaths: ['position'], layouts: [
                new PTableElement({
                    label: 'Tab Stop',
                    anchorOrigin: {vertical: 'center', horizontal: 'right'},
                    transformOrigin: {vertical: 'center', horizontal: 'right'},
                    layouts: [{ ...FParagraphTabStop.position, default: undefined }, { ...FParagraphTabStop.alignment, default: undefined }]
                })],
            default: { position: 0, alignment: 'Left' }
        })]
    })]
});
