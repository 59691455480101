export const fr = {
	components: {
		report: {
			jrxmlviewer: {
				menu: {
					file: "Fishier"
				}
			}
		},
		datasets: {
			whenResourceMissing: {
				label: "Lorsque la ressource est manquante",
				null: "NULL",
				empty: "Vide",
				error: "Erreur",
				key: "Clé"
			},
			resourceBundle: "Groupe de ressources",
			scriptletClass: "Classe de scripts",
			language: "Langue",
			showQueryEditor: "Afficher l'éditeur de requête",
			filterExpression: "Expression de filtre",
			fields: "Champs",
			field: "Champ",
			parameters: {
				label: "Paramètres",
				dateError: "Format de date invalide"
			},
			variables: "Variables",
			groups: "Groupes",
			sortFields: "Trier Par",
			scriptlets: "Scriptlets"
		}
	},
	enum: {
		inherited: "[hérité]"
	},
	true: "vrai",
	false: "faux",
	StretchType: {
		label: "Type d'étirement",
		NoStretch: "Pas d'étirement",
		RelativeToTallestObject: "Par rapport à l'objet le plus haut",
		RelativeToBandHeight: "Par rapport à la hauteur de la bande",
		ElementGroupHeight: "Hauteur du groupe d'éléments",
		ElementGroupBottom: "Bas du groupe d'éléments",
		ContainerHeight: "Hauteur du conteneur",
		ContainerBottom: "Bas du conteneur"
	},
	PositionType: {
		label: "Type de poste",
		Float: "Flottant",
		FixRelativeToTop: "Fixer par rapport au haut",
		FixRelativeToBottom: "Fixer par rapport au bas"
	},
	HorizontalTextAlign: {
		label: "Alignement horizontal du texte",
		Left: "Gauche",
		Center: "Centre",
		Right: "Droite",
		Justify: "Justifier"
	},
	HorizontalAlign: {
		label: "Position horizontale"
	},
	TabStopAlignEnum: {
		label: "Alignement"
	},
	HorizontalImageAlign: {
		label: "Alignement horizontal de l'image",
		Left: "Gauche",
		Center: "Centre",
		Right: "Droite"
	},
	ImageAnchorType: {
		Label: "Type d'ancre d'image",
		MoveSize: "Déplacer la taille",
		MoveNoSize: "Déplacer aucune taille",
		NoMoveNoSize: "Pas de déplacement, pas de taille"
	},
	ErrorCorrectionLevel: {
		label: "Niveau de correction d'erreur"
	},
	VerticalTextAlign: {
		label: "Alignement vertical du texte",
		Top: "Haut",
		Middle: "Milieu",
		Bottom: "Bas",
		Justify: "Justifier"
	},
	VerticalImageAlign: {
		label: "Alignement vertical de l'image",
		Top: "Haut",
		Middle: "Milieu",
		Bottom: "Bas"
	},
	Rotation: {
		label: "Rotation",
		None: "Aucun",
		Gauche: "Gauche",
		Droite: "Droite",
		UpsideDown: "À l'envers"
	},
	band: {
		title: "Titre",
		pageHeader: "En-tête de page",
		columnHeader: "En-tête de colonne",
		groupHeader: "En-tête de groupe",
		detail: "Détail",
		groupFooter: "Pied de page du groupe",
		columnFooter: "Pied de page de colonne",
		pageFooter: "Pied de page",
		background: "Arrière-plan",
		noData: "Aucune donnée",
		summary: "Résumé",
		lastPageFooter: "Pied de page de la dernière page"
	},
	WhenNoDataType: {
		label: "Lorsqu'aucune donnée ne s'affiche",
		NoPages: "Aucun",
		BlankPage: "Page vide",
		AllSectionsNoDetail: "Toutes les sections sans détail",
		NoDataSection: "Aucune section de données"
	},
	WhenNoDataTypeTable: {
		label: "Type d'ancre d'image",
		Blank: "Vide",
		NoDataCell: "Aucune cellule de données",
		AllSectionsNoDetail: "Toutes les sections sans détail"
	},
	ParameterEvaluationTime: {
		label: "Temps d'évaluation",
		description: "Évaluer l'expression de la valeur par défaut du paramètre avant ou après l'adaptateur de données",
		Early: "Tôt",
		Late: "En retard"
	},
	PdfFieldBorderStyleEnum: {
		label: "Style de bordure",
		Solid: "Solide",
		Dashed: "En pointillé",
		Beveled: "Biseauté",
		Encart: "Encart",
		Underline: "Souligné"
	},
	PdfFieldCheckTypeEnum: {
		label: "Style de bordure",
		Check: "Vérifier",
		Circle: "Cercle",
		Cross: "Croix",
		Diamant: "Diamant",
		Carré: "Carré",
		Star: "Étoile"
	},
	PdfFieldTypeEnum: {
		label: "Style de bordure",
		Text: "Texte",
		Combo: "Combo",
		List: "Liste",
		Check: "Vérifier",
		Radio: "Radio"
	},
	PdfPrintScalingEnum: {
		label: "Style de bordure",
		None: "Aucun",
		Default: "Par défaut"
	},
	EvaluationTime: {
		label: "Heure d'évaluation",
		Now: "Maintenant",
		Report: "Rapport",
		Page: "Page",
		Column: "Colonne",
		Group: "Groupe",
		Band: "Bande",
		Auto: "Auto",
		Master: "Maître"
	},
	TextAdjust: {
		label: "Ajustement du texte",
		CutText: "Couper le texte",
		StretchHeight: "Étirement de la hauteur",
		ScaleFont: "Police d'échelle"
	},
	SplitType: {
		label: "Type de fractionnement",
		Stretch: "Étirement",
		Prevent: "Empêcher",
		Immediate: "Immédiat"
	},
	OrientationType: {
		label: "Type d'orientation",
		Portrait: "Portrait",
		Landscape: "Paysage"
	},
	PrintOrder: {
		label: "Imprimer la commande",
		Vertical: "Vertical",
		Horizontal: "Horizontal"
	},
	ScaleImage: {
		label: "Redimensionner l'image",
		Clip: "Clip",
		FillFrame: "Remplir le cadre",
		RetainShape: "Conserver la forme",
		RealHeight: "Hauteur réelle",
		RealSize: "Taille réelle"
	},
	Fill: {
		label: "Remplir",
		SOLID: "Solide"
	},
	OnErrorType: {
		label: "En cas d'erreur",
		Erreur: "Erreur",
		Blank: "Vide",
		Icon: "Icône"
	},
	IncrementType: {
		label: "Incrément sur",
		None: "Aucun",
		Report: "Rapport",
		Page: "Page",
		Column: "Colonne",
		Group: "Groupe"
	},
	Calculation: {
		DistinctCount: "Compte distinct"
	},
	ResetType: {
		label: "Réinitialiser sur",
		None: "Aucun",
		Report: "Rapport",
		Page: "Page",
		Column: "Colonne",
		Master: "Maître",
		Group: "Groupe"
	},
	Order: {
		label: "Ordre",
		Descending: "Décroissant"
	},
	Ordre: {
		Croissant: "Croissant"
	},
	BorderSplittype: {
		label: "Type de fractionnement de bordure",
		description: "Spécifie la position de l'objet lorsque la section du rapport est affectée par l'étirement.",
		NoBorders: "Aucune bordure",
		DrawBorders: "Dessiner des bordures"
	},
	LineDirection: {
		label: "Direction",
		TopDown: "Dessus vers le bas",
		BottomUp: "Dessous vers le haut"
	},
	BreakType: {
		label: "Type",
		Page: "Page",
		Column: "Colonne"
	},
	OverflowType: {
		label: "En cas de débordement",
		description: "Détermine ce qui se passe lorsque le contenu du sous-rapport dépasse la hauteur de l'élément. S'il n'est pas spécifié, le sous-rapport s'étirera pour s'adapter au contenu.",
		Stretch: "Étirement",
		NoStretch: "Pas d'étirement"
	},
	LineStyle: {
		label: "Style de ligne",
		Solid: "Solide",
		Dashed: "En pointillé",
		Dotted: "Pointillé"
	},
	StyleLigne: {
		Double: "Double"
	},
	Orientation: {
		label: "Orientation",
		haut: "Haut",
		gauche: "Gauche",
		bas: "Bas",
		droite: "Droite"
	},
	TextPosition: {
		label: "Position du texte",
		none: "Aucun",
		bottom: "En bas",
		top: "Haut"
	},
	ChecksumMode: {
		label: "Mode somme de contrôle",
		auto: "Auto",
		ignore: "Ignorer",
		add: "Ajouter",
		check: "Vérifier"
	},
	LineSpacing: {
		label: "Type",
		Single: "Unique",
		1_1_2: "1,5 lignes",
		Double: "Double",
		AtLeast: "Au moins",
		Fixed: "Fixe",
		Proportional: "Proportionnel"
	},
	DataMatrixShape: {
		label: "Forme",
		forceNone: "Forcer aucun",
		forceSquare: "Forcer le carré",
		forceRectangle: "Forcer le rectangle"
	},
	BaselinePosition: {
		label: "Position de base",
		auto: "Auto",
		ignore: "Ignorer"
	},
	Edge: {
		label: "Position"
	},
	Bord: {
		Haut: "Haut",
		Bas: "Bas",
		Gauche: "Gauche",
		Droite: "Droite"
	},
	PlotOrientation: {
		label: "Orientation",
		Horizontal: "Horizontal",
		Vertical: "Vertical"
	},
	SpiderRotation: {
		label: "Rotation",
		Clockwise: "Sens horaire",
		Anticlockwise: "Anti-horaire"
	},
	ScaleType: {
		label: "Type d'échelle",
		BothAxes: "Les deux axes",
		DomainAxis: "Axe du domaine",
		RangeAxis: "Axe de plage"
	},
	ValueLocation: {
		label: "Emplacement",
		none: "Aucun",
		left: "Gauche",
		right: "Droit",
		bulb: "Ampoule"
	},
	HyperlinkType: {
		label: "Type de lien",
		Null: "Null",
		None: "Aucun",
		Reference: "Référence",
		LocalAnchor: "Ancre locale",
		LocalPage: "Page locale",
		RemoteAnchor: "Ancre distante",
		RemotePage: "Page distante",
		Custom: "Personnalisé"
	},
	HyperlinkTarget: {
		label: "Cible du lien",
		None: "Aucun",
		Self: "Self",
		Blank: "Blank",
		Parent: "Parent",
		Top: "Haut",
		Custom: "Personnalisé"
	},
	MeterShape: {
		label: "Forme",
		chord: "accord",
		circle: "cercle",
		pie: "tarte",
		dial: "cadran"
	},
	AxisPosition: {
		label: "Position de l'axe",
		leftOrTop: "Gauche ou haut",
		rightOrBottom: "Droite ou bas"
	},
	TimePeriodEnum: {
		label: "Période de temps",
		Year: "Année",
		Quarter: "Trimestre",
		Month: "Mois",
		Week: "Semaine",
		Jour: "Jour",
		Hour: "Heure",
		Minute: "Minute",
		Second: "Seconde",
		Miliseconde: "Miliseconde"
	},
	TransactionIsolation: {
		label: "Isolement des transactions",
		NONE: "AUCUN",
		ReadUcommitted: "Lire Ucommitted",
		ReadCommitted: "Lecture validée",
		RepeatableRead: "Lecture répétable",
		Serializable: "Sérialisable"
	},
	TableOrderEnum: {
		label: "Ordre de la table de légende"
	},
	TableOrder: {
		Row: "Ligne",
		Column: "Colonne"
	},
	MapType: {
		label: "Type de carte",
		roadmap: "feuille de route",
		hybrid: "hybride"
	},
	TypeCarte: {
		satellite: "satellite",
		terrain: "terrain"
	},
	RunDirection: {
		label: "Direction d'exécution",
		LTR: "De gauche à droite",
		RTL: "De droite à gauche"
	},
	Mode: {
		label: "Transparence",
		Opaque: "Opaque",
		Transparent: "Transparent"
	},
	CrosstabPercentage: {
		label: "Pourcentage",
		None: "Aucun",
		GrandTotal: "Total général"
	},
	CrosstabTotalPosition: {
		label: "Position Du Totale",
		None: "Aucun",
		Start: "Démarrer",
		End: "Fin"
	},
	Bucket: {
		label: "Commande",
		None: "Aucun",
		Ascending: "Croissant",
		Descending: "Décroissant"
	},
	CrosstabRowPosition: {
		label: "Position",
		Top: "Haut",
		Moyen: "Moyen",
		Bas: "Bas",
		Stretch: "Étirement"
	},
	CrosstabColumnPosition: {
		label: "Position",
		Left: "Gauche",
		Center: "Centre",
		Right: "Droite",
		Stretch: "Étirement"
	},
	SortFieldType: {
		label: "Type",
		Field: "Champ",
		Variable: "Variable"
	},
	CROSSTAB_ROW_GROUPS: "Groupes de lignes",
	CROSSTAB_COLUMN_GROUPS: "Groupes de colonnes",
	CROSSTAB_MEASURES: "Mesures",
	CROSSTAB_DETAIL: "Détail",
	CROSSTAB_NO_DATA_CELL: "Aucune cellule de données",
	CROSSTAB_ROW_HEADER_NAME_PREFIX: "En-tête",
	CROSSTAB_TOTAL_ROW_HEADER_NAME_PREFIX: "Total",
	CROSSTAB_COLUMN_HEADER_NAME_PREFIX: "En-tête",
	CROSSTAB_TOTAL_COLUMN_HEADER_NAME_PREFIX: "Total",
	TABLE_HEADER: "En-tête du tableau",
	TABLE_GROUP_COLUMN: "Colonne de groupe de tables",
	TABLE_COLUMN_HEADER: "En-tête de colonne",
	TABLE_GROUP_HEADER: "En-tête de groupe",
	TABLE_DETAIL: "Détail",
	TABLE_FOOTER: "Pied de page du tableau",
	TABLE_COLUMN_FOOTER: "Pied de page de colonne",
	TABLE_GROUP_FOOTER: "Pied de page du groupe",
	chartType: {
		visualCategory: {
			all: "Tous",
			column_and_bar: "Colonne et barre",
			dual_and_multiaxis: "Deux et multi-axes",
			line_and_area: "Ligne et surface",
			pie: "Carteau",
			range: "Plage",
			scatter_and_bubble: "Dispersion et bulle",
			time_series: "Séries temporelles",
			gauge: "Jauge"
		}
	},
	descriptionAlignTick: "Lorsque vous utilisez plusieurs axes, les graduations de deux ou plusieurs axes opposés seront automatiquement alignées en ajoutant des graduations à l'axe ou aux axes avec le moins de graduations. Cela peut être évité en définissant alignTicks sur false. Si les lignes de la grille semblent désordonné, c'est une bonne idée de les masquer pour l'axe secondaire en définissant gridLineWidth sur 0.",
	descriptionAnimation: "Définit l'animation globale pour toutes les mises à jour des graphiques.",
	descriptionBackgroundColor: "La couleur d'arrière-plan ou le dégradé de la zone extérieure du graphique.",
	descriptionBorderColor: "La couleur de la bordure extérieure du graphique.",
	descriptionBorderRadius: "Le rayon d'angle de la bordure extérieure du graphique.",
	descriptionBorderWidth: "La largeur en pixels de la bordure extérieure du graphique.",
	descriptionBottomMargin: "La marge entre le bord extérieur inférieur du graphique et la zone de tracé. Utilisez ceci pour définir une valeur de pixel fixe pour la marge par opposition à la marge dynamique par défaut. Voir aussi spacingBottom.",
	descriptionBottomSpacing: "L'espace entre le bord inférieur du graphique et le contenu (zone de tracé, titre et étiquettes de l'axe, titre, sous-titre ou légende en position supérieure).",
	descriptionChartWidth: "Une largeur explicite pour le graphique. Par défaut, la largeur est calculée à partir de la largeur de décalage de l'élément contenant.",
	descriptionColorsPalette: "Un tableau contenant les couleurs par défaut pour la série du graphique. Lorsque toutes les couleurs sont utilisées, les nouvelles couleurs sont extraites depuis le début.",
	descriptionCredits: "Le texte du libellé des crédits.",
	descriptionDaysXAxes: "Modèle de jours pour le format de date de l'étiquette de l'axe X.",
	descriptionDaysYAxes: "Modèle de jours pour le format de date de l'étiquette de l'axe Y.",
	descriptionDropShadow: "S'il faut appliquer une ombre portée à la zone extérieure du graphique. Nécessite que backgroundColor soit défini.",
	descriptionFloatingLegend: "Lorsque la légende est flottante, la zone de tracé l'ignore et peut être placée en dessous.",
	descriptionHorizontalAlignment: "L'alignement horizontal de l'étiquette du générique.",
	descriptionHoursXAxes: "Modèle d'heures pour le format de date de l'étiquette de l'axe X.",
	descriptionHoursYAxes: "Modèle d'heures pour le format de date de l'étiquette de l'axe Y.",
	descriptionHyperlinkReference: "L'URL du libellé des crédits.",
	descriptionIgnoreHiddenSeries: "Si vrai, les axes s'adapteront à la série visible restante une fois qu'une série est masquée. Si faux, masquer et afficher une série n'affectera pas les axes ou l'autre série. Pour les piles, une fois qu'une série dans la pile est caché, le reste de la pile se refermera autour de lui même si l'axe n'est pas affecté.",
	descriptionInvertAxes: "S'il faut inverser les axes pour que l'axe des x soit vertical et l'axe des y soit horizontal. Lorsque vrai, l'axe des x est inversé par défaut. Si une série de barres est présente dans le graphique, elle sera automatiquement inversée. L'inversion du graphique n'a pas d'effet s'il n'y a pas de série cartésienne dans le graphique ou si le graphique est polaire.",
	descriptionLeftMargin: "La marge entre le bord extérieur gauche du graphique et la zone de tracé. Utilisez ceci pour définir une valeur de pixel fixe pour la marge par opposition à la marge dynamique par défaut. Voir aussi spacingLeft.",
	descriptionLeftSpacing: "L'espace entre le bord gauche du graphique et le contenu (zone de tracé, titre et étiquettes de l'axe, titre, sous-titre ou légende en position haute).",
	descriptionLegendBackgroundColor: "La couleur de fond de la légende.",
	descriptionLegendBorderColor: "La couleur de la bordure dessinée autour de la légende.",
	descriptionLegendBorderRadius: "Le rayon du coin de la bordure de la légende.",
	descriptionLegendBorderWidth: "La largeur de la bordure dessinée autour de la légende.",
	descriptionLegendHorizontalAlignment: "L'alignement horizontal de la zone de légende dans la zone du graphique.",
	descriptionLegendItemsBottomMargin: "La marge inférieure en pixels pour chaque élément de légende.",
	descriptionLegendItemsLayout: "La disposition des éléments de légende.",
	descriptionLegendItemsReverseOrder: "S'il faut inverser l'ordre des éléments de légende par rapport à l'ordre des séries ou des points tel que défini dans l'objet de configuration.",
	descriptionLegendItemsTopMargin: "La marge supérieure en pixels pour chaque élément de légende.",
	descriptionLegendItemsWidth: "La largeur de chaque élément de légende. Ceci est utile dans une disposition horizontale avec de nombreux éléments lorsque vous souhaitez que les éléments s'alignent verticalement.",
	descriptionLegendMargin: "Si la taille de la zone de tracé est calculée automatiquement et que la légende n'est pas flottante, la marge de la légende est l'espace entre la légende et les étiquettes des axes ou la zone de tracé.",
	descriptionLegendMaxHeight: "Hauteur maximale en pixels pour la légende. Lorsque la hauteur maximale est étendue, la navigation s'affichera.",
	descriptionLegendPadding: "Le rembourrage intérieur de la boîte de légende.",
	descriptionLegendShowSymbol: "Afficher ou non le symbole à droite du texte plutôt qu'à gauche. C'est courant en arabe et en hébreu.",
	descriptionLegendSymbolHeight: "La hauteur en pixels du symbole pour les types de série qui utilisent un rectangle dans la légende. Par défaut, la taille de police des éléments de légende.",
	descriptionLegendSymbolPadding: "Le remplissage en pixels entre le symbole de l'élément de légende et le texte de l'élément de légende.",
	descriptionLegendSymbolRadius: "Le rayon de bordure du symbole pour les types de série qui utilisent un rectangle dans la légende.",
	descriptionLegendSymbolWidth: "La largeur en pixels du symbole de l'élément de légende.",
	descriptionLegendVerticalAlignment: "L'alignement vertical de la boîte de légende.",
	descriptionLegendWidth: "La largeur de la boîte de légende.",
	descriptionLegendXOffset: "Le décalage x de la légende par rapport à son alignement horizontal s'aligne dans chart.spacingLeft et chart.spacingRight. Un x négatif le déplace vers la gauche, un x positif le déplace vers la droite.",
	descriptionLegendYOffset: "Le décalage vertical de la légende par rapport à son alignement vertical verticalAlign dans chart.spacingTop et chart.spacingBottom. Un y négatif le déplace vers le haut, un y positif le déplace vers le bas.",
	descriptionMillisecondsXAxes: "Modèle de millisecondes pour le format de date dans l'en-tête de l'infobulle.",
	descriptionMillisecondsYAxes: "Modèle de millisecondes pour le format de date dans l'en-tête de l'info-bulle.",
	descriptionMinutesXAxes: "Modèle de minutes pour le format de date de l'étiquette de l'axe X.",
	descriptionMinutesYAxes: "Modèle de minutes pour le format de date de l'étiquette de l'axe Y.",
	descriptionMonths: "Modèle de mois pour le format de date de l'étiquette de l'axe X.",
	descriptionMonthsYAxes: "Modèle de mois pour le format de date de l'étiquette de l'axe Y.",
	descriptionPlotBackgroundColor: "La couleur d'arrière-plan ou le dégradé de la zone de tracé.",
	descriptionPlotBackgroundImage: "L'URL d'une image à utiliser comme arrière-plan du tracé. Pour définir une image comme arrière-plan pour l'ensemble du graphique, définissez une image d'arrière-plan CSS pour l'élément conteneur. Notez que pour l'image à appliquer aux graphiques exportés , son URL doit être accessible par le serveur d'exportation.",
	descriptionPlotBorderColor: "La couleur de la bordure intérieure du graphique ou de la zone de tracé.",
	descriptionPlotBorderWidth: "La largeur en pixels de la bordure de la zone de tracé.",
	descriptionPlotOptionCropTreshold: "Lorsque la série contient moins de points que le seuil de recadrage, tous les points sont dessinés, même si les points tombent en dehors de la zone de tracé visible au zoom actuel. L'avantage de dessiner tous les points (y compris les marqueurs et les colonnes), est cette animation est effectuée sur les mises à jour. D'autre part, lorsque la série contient plus de points que le seuil de recadrage, les données de la série sont recadrées pour ne contenir que les points qui se trouvent dans la zone de tracé. L'avantage de recadrer les points invisibles est d'augmenter les performances sur de grandes séries.",
	descriptionPlotOptionYears: "Modèle d'années pour le format de date dans l'en-tête de l'info-bulle.",
	descriptionPlotOptionsDashStyle: "Un nom pour le style de tiret à utiliser pour le graphique. S'applique uniquement au type de série ayant un graphique, comme une ligne, une spline, une zone et un nuage de points dans le cas où il a une largeur de ligne.",
	descriptionPlotOptionsDays: "Modèle de jours pour le format de date dans l'en-tête de l'info-bulle.",
	descriptionPlotOptionsHours: "Modèle d'heures pour le format de date dans l'en-tête de l'info-bulle.",
	descriptionPlotOptionsLineWidth: "Pixel avec la ligne de graphique.",
	descriptionPlotOptionsMainColor: "La couleur principale ou la série. Dans les séries de type ligne, elle s'applique à la ligne et aux marqueurs de points, sauf indication contraire. Dans les séries de type barre, elle s'applique aux barres, sauf si une couleur est spécifiée par point. La valeur par défaut est extrait du tableau options.colors.",
	descriptionPlotOptionsMilliseconds: "Modèle de millisecondes pour le format de date dans l'en-tête de l'infobulle.",
	descriptionPlotOptionsMinutes: "Modèle de minutes pour le format de date dans l'en-tête de l'info-bulle.",
	descriptionPlotOptionsMonths: "Modèle de mois pour le format de date dans l'en-tête de l'info-bulle.",
	descriptionPlotOptionsSeconds: "Modèle de secondes pour le format de date dans l'en-tête de l'info-bulle.",
	descriptionPlotOptionsShowCheckbox: "Vous pouvez définir le curseur sur \"pointeur\" si vous avez des événements de clic attachés à la série, pour signaler à l'utilisateur que les points et les lignes peuvent être cliqués.",
	descriptionPlotOptionsWeeks: "Modèle de semaines pour le format de date dans l'en-tête de l'info-bulle.",
	descriptionPlotShadow: "S'il faut appliquer une ombre portée à la zone de tracé. Nécessite que plotBackgroundColor soit défini.",
	descriptionReflowChart: "S'il faut redistribuer le graphique pour qu'il s'adapte à la largeur de la div conteneur lors du redimensionnement de la fenêtre.",
	descriptionRightMargin: "La marge entre le bord extérieur droit du graphique et la zone de tracé. Utilisez ceci pour définir une valeur de pixel fixe pour la marge par opposition à la marge dynamique par défaut. Voir aussi spacingRight.",
	descriptionRightSpacing: "L'espace entre le bord droit du graphique et le contenu (zone de tracé, titre et étiquettes de l'axe, titre, sous-titre ou légende en position haute).",
	descriptionSecondsXAxes: "Modèle de secondes pour le format de date dans l'en-tête de l'infobulle.",
	descriptionSecondsYAxes: "Modèle de secondes pour le format de date dans l'en-tête de l'infobulle.",
	descriptionSelectionMarkFillColor: "La couleur d'arrière-plan du carré marqueur lors de la sélection (zoom avant) d'une zone du graphique.",
	descriptionSeriesAllPointSelection: "Autoriser la sélection des points de cette série en cliquant sur les marqueurs, les barres ou les secteurs.",
	descriptionSeriesConnectNullPoints: "S'il faut connecter une ligne de graphique entre des points nuls.",
	descriptionSeriesSelected: "S'il faut sélectionner la série initialement. Si showCheckbox est vrai, la case à côté du nom de la série sera cochée pour une série sélectionnée.",
	descriptionSeriesShowAnimation: "Activer ou désactiver l'animation initiale lors de l'affichage d'une série.",
	descriptionSeriesShowCheckbox: "Si vrai, une case à cocher s'affiche à côté de l'élément de légende pour permettre de sélectionner la série. L'état de la case à cocher est déterminé par l'option sélectionnée.",
	descriptionSeriesShowInLegend: "Afficher ou non cette série particulière ou ce type de série dans la légende. La valeur par défaut est true pour les séries autonomes, false pour les séries liées.",
	descriptionSeriesShowShadow: "S'il faut appliquer une ombre portée à la ligne du graphique.",
	descriptionSeriesVisible: "Définit la visibilité initiale de la série.",
	descriptionShowAxes: "Afficher ou non les axes initialement. Cela ne s'applique qu'aux graphiques vides où les séries sont ajoutées dynamiquement, car les axes sont automatiquement ajoutés aux séries cartésiennes.",
	descriptionShowCredits: "Afficher ou non le texte des crédits.",
	descriptionShowDataPoints: "Activer ou désactiver le marqueur de point.",
	descriptionShowLegend: "Activer ou désactiver la légende.",
	descriptionShowMeasureNameOnValueAxis: "Affiche l'expression de l'étiquette de mesure sur l'axe des valeurs.",
	descriptionShowTooltip: "Activer ou désactiver l'info-bulle.",
	descriptionShowTooltipAnimation: "Active ou désactive l'animation de l'info-bulle. Dans les anciens navigateurs IE lents, l'animation est désactivée par défaut.",
	descriptionSubtitleFloatingTitle: "Lorsque le sous-titre est flottant, la zone de tracé ne bouge pas pour lui faire de la place.",
	descriptionSubtitleFontColor: "Propriété de couleur CSS qui définit la couleur de premier plan.",
	descriptionSubtitleFontFamily: "Propriété CSS font-family qui définit la famille de polices.",
	descriptionSubtitleFontSize: "Propriété CSS font-size qui définit la taille de la police.",
	descriptionSubtitleHorizontalAlignment: "L'alignement horizontal du sous-titre.",
	descriptionSubtitleText: "Le sous-titre du graphique. Pour désactiver le sous-titre, définissez le texte sur null.",
	descriptionSubtitleVerticalAlignment: "L'alignement vertical du sous-titre. Lorsqu'une valeur est donnée, le titre se comporte comme flottant.",
	descriptionSubtitleXPosition: "La position x du sous-titre par rapport à l'alignement entre chart.spacingLeft et chart.spacingRight.",
	descriptionSubtitleYPosition: "La position y du sous-titre par rapport à l'alignement dans chart.spacingTop et chart.spacingBottom. Par défaut, le sous-titre est disposé sous le titre, sauf si le titre est flottant. La valeur par défaut est null.",
	descriptionTitleFloatingTitle: "Lorsque le titre est flottant, la zone de tracé ne bouge pas pour lui faire de la place.",
	descriptionTitleFontColor: "Propriété de couleur CSS qui définit la couleur de premier plan.",
	descriptionTitleFontFamily: "Propriété CSS font-family qui définit la famille de polices.",
	descriptionTitleFontSize: "Propriété CSS font-size qui définit la taille de la police.",
	descriptionTitleHorizontalAlignment: "L'alignement horizontal du titre.",
	descriptionTitleMargin: "La marge entre le titre et la zone de tracé, ou si un sous-titre est présent, la marge entre le sous-titre et la zone de tracé.",
	descriptionTitleText: "Le titre du graphique. Pour désactiver le titre, définissez le texte sur null.",
	descriptionTitleVerticalAlignment: "L'alignement vertical du titre. Lorsqu'une valeur est donnée, le titre se comporte comme flottant.",
	descriptionTitleXPosition: "La position x du titre par rapport à l'alignement dans chart.spacingLeft et chart.spacingRight.",
	descriptionTitleYPosition: "La position y du titre par rapport à l'alignement dans chart.spacingTop et chart.spacingBottom. Par défaut, cela dépend de la taille de la police.",
	descriptionTooltipBackgroundColor: "La couleur d'arrière-plan ou le dégradé de l'info-bulle.",
	descriptionTooltipBorderColor: "La couleur de la bordure de l'info-bulle. Lorsqu'elle est nulle, la bordure prend la couleur de la série ou du point correspondant.",
	descriptionTooltipBorderRadius: "Le rayon des coins arrondis de la bordure.",
	descriptionTooltipBorderWidth: "La largeur en pixels de la bordure de l'info-bulle.",
	descriptionTooltipDateFormat: "Le format de la date dans l'en-tête de l'info-bulle si l'axe X est un axe date-heure. La valeur par défaut est une meilleure estimation basée sur la plus petite distance entre les points du graphique.",
	descriptionTooltipFooterFormat: "Une chaîne à ajouter au format de l'infobulle",
	descriptionTooltipHeaderFormat: "Le code HTML de la ligne d'en-tête de l'info-bulle. Les variables sont entourées d'accolades. Les variables disponibles sont point.key, series.name, series.color et d'autres membres des objets point et series. La variable point.key contient le nom de la catégorie, la valeur x ou la chaîne datetime selon le type d'axe. Pour les axes datetime, le format de date point.key peut être défini à l'aide de tooltip.xDateFormat.",
	descriptionTooltipPointFormat: "Le code HTML de la ligne du point dans l'info-bulle. Les variables sont entourées d'accolades. Les variables disponibles sont point.x, point.y, series.name et series.color et d'autres propriétés sur le même formulaire. De plus, point.y peut être étendu par les variables tooltip.valuePrefix et tooltip.valueSuffix. Cela peut également être remplacé pour chaque série, ce qui en fait un bon crochet pour afficher les unités.",
	descriptionTooltipShowShadow: "S'il faut appliquer une ombre portée à l'info-bulle.",
	descriptionTooltipUseHTML: "Utilisez HTML pour afficher le contenu de l'info-bulle au lieu de SVG. L'utilisation de HTML permet un formatage avancé comme des tableaux et des images dans l'info-bulle. Il est également recommandé pour les langages rtl car il contourne les bogues rtl dans les premiers Firefox.",
	descriptionTooltipValueDecimals: "Combien de décimales afficher dans la valeur y de chaque série. Ceci est remplaçable dans l'objet d'options d'info-bulle de chaque série. La valeur par défaut est de conserver toutes les décimales.",
	descriptionTooltipValuePrefix: "Une chaîne à ajouter à la valeur y de chaque série. Remplaçable dans l'objet d'options d'info-bulle de chaque série.",
	descriptionTooltipValueSuffix: "Une chaîne à ajouter à la valeur y de chaque série. Remplaçable dans l'objet d'options d'info-bulle de chaque série.",
	descriptionTopMargin: "La marge entre le bord extérieur supérieur du graphique et la zone de tracé. Utilisez ceci pour définir une valeur de pixel fixe pour la marge par opposition à la marge dynamique par défaut. Voir aussi spacingTop.",
	descriptionTopSpacing: "L'espace entre le bord supérieur du graphique et le contenu (zone de tracé, titre et étiquettes de l'axe, titre, sous-titre ou légende en position supérieure).",
	descriptionUseSharedTooltip: "Lorsque l'info-bulle est partagée, toute la zone de tracé capture les mouvements de la souris ou les événements tactiles. Les textes des info-bulles pour les types de séries avec des données ordonnées (pas de tarte, de dispersion, de drapeaux, etc.) seront affichés dans une seule bulle. C'est recommandé pour les graphiques à série unique et pour les graphiques optimisés pour tablette/mobile.",
	descriptionVerticalAlignment: "L'alignement vertical de l'étiquette du générique.",
	descriptionWeeksXAxes: "Modèle de semaines pour le format de date de l'étiquette de l'axe X.",
	descriptionWeeksYAxes: "Modèle de semaines pour le format de date de l'étiquette de l'axe Y.",
	descriptionXAxisRotation: "Rotation des étiquettes en degrés.",
	descriptionXAxisStep: "Pour afficher uniquement chaque nième étiquette sur l'axe, définissez le pas sur n. Définir le pas sur 2 affiche toutes les autres étiquettes. Par défaut, le pas est calculé automatiquement pour éviter les chevauchements. Pour éviter cela, définissez à 1. Cela ne se produit généralement que sur un axe des catégories, et est souvent un signe que vous avez choisi le mauvais type d'axe.",
	descriptionXPosition: "La position x du libellé du générique.",
	descriptionYAxisRotation: "Rotation des étiquettes en degrés.",
	descriptionYAxisStep: "Pour afficher uniquement chaque nième étiquette sur l'axe, définissez le pas sur n. Définir le pas sur 2 affiche toutes les autres étiquettes. Par défaut, le pas est calculé automatiquement pour éviter les chevauchements. Pour éviter cela, définissez à 1. Cela ne se produit généralement que sur un axe des catégories, et est souvent un signe que vous avez choisi le mauvais type d'axe.",
	descriptionYPosition: "La position y du libellé du générique",
	descriptionYearsXAxes: "Modèle d'années pour le format de date de l'étiquette de l'axe X.",
	descriptionYearsYAxes: "Modèle d'années pour le format de date de l'étiquette de l'axe Y.",
	descriptionZoomType: "Décide dans quelles dimensions l'utilisateur peut zoomer en faisant glisser la souris. Peut être x, y ou xy.",
	labelAlignTicks: "Aligner les tiques",
	labelAnimation: "Afficher l'animation lors de la mise à jour du graphique",
	labelBackgroundColor: "Couleur de fond",
	labelBorderColor: "Couleur de la bordure",
	labelBorderRadius: "Rayon de la bordure",
	labelBorderWidth: "Largeur de la bordure",
	labelBottomMargin: "Marge inférieure",
	labelBottomSpacing: "Espacement inférieur",
	labelChartWidth: "Largeur du graphique",
	labelColorsPalette: "Palette de couleurs",
	labelCredits: "Crédits",
	labelDaysXAxes: "Jours",
	labelDaysYAxes: "Jours",
	labelDropShadow: "Afficher l'ombre portée",
	labelFloatingLegend: "Légende flottante",
	labelHorizontalAlignment: "Alignement horizontal",
	labelHoursXAxes: "Heures",
	labelHoursYAxes: "Heures",
	labelHyperlinkReference: "Référence du lien hypertexte",
	labelIgnoreHiddenSeries: "Ignorer les séries cachées",
	labelInvertAxes: "Inverser les axes",
	labelLeftMargin: "Marge de gauche",
	labelLeftSpacing: "Espacement gauche",
	labelLegendBackgroundColor: "Couleur de fond",
	labelLegendBorderColor: "Couleur de la bordure",
	labelLegendBorderRadius: "Rayon de la bordure",
	labelLegendBorderWidht: "Largeur de la bordure",
	labelLegendHorizontalAlignment: "Alignement horizontal",
	labelLegendItemsBottomMargin: "Marge inférieure",
	labelLegendItemsLayout: "Disposition des éléments",
	labelLegendItemsReverseOrder: "Ordre inversé",
	labelLegendItemsTopMargin: "Marge supérieure",
	labelLegendItemsWidth: "Largeur",
	labelLegendMargin: "Marge",
	labelLegendMaxHeight: "Hauteur maximale",
	labelLegendPadding: "Remplissage",
	labelLegendShowSymbol: "Afficher le symbole à droite du texte",
	labelLegendSymbolHeight: "Hauteur (symbole rectangle)",
	labelLegendSymbolPadding: "Remplissage",
	labelLegendSymbolRadius: "Rayon (symbole rectangle)",
	labelLegendSymbolWidth: "Largeur",
	labelLegendVerticalAlignment: "Alignement vertical",
	labelLegendWidth: "Largeur",
	labelLegendXOffset: "Décalage X",
	labelLegendYOffset: "Décalage Y",
	labelMillisecondsXAxes: "Millisecondes",
	labelMillisecondsYAxes: "Millisecondes",
	labelMinutesXAxes: "Minutes",
	labelMinutesYAxes: "Minutes",
	labelMonthsXAxes: "Mois",
	labelMonthsYAxes: "Mois",
	labelPlotBackgroundColor: "Couleur d'arrière-plan du tracé",
	labelPlotBackgroundImage: "Image d'arrière-plan du tracé",
	labelPlotBorderColor: "Couleur de la bordure du tracé",
	labelPlotBorderWidth: "Largeur de la bordure du tracé",
	labelPlotOptionCropTreshold: "Seuil de recadrage",
	labelPlotOptionsDashStyle: "Style de tiret",
	labelPlotOptionsDays: "Jours",
	labelPlotOptionsHours: "Heures",
	labelPlotOptionsLineWidth: "Largeur de ligne",
	labelPlotOptionsMainColor: "Couleur principale",
	labelPlotOptionsMilliseconds: "Millisecondes",
	labelPlotOptionsMinutes: "Minutes",
	labelPlotOptionsMonths: "Mois",
	labelPlotOptionsSeconds: "Secondes",
	labelPlotOptionsShowCheckbox: "Afficher la case à cocher",
	labelPlotOptionsWeeks: "Semaines",
	labelPlotOptionsYears: "Années",
	labelPlotShadow: "Ombre du tracé",
	labelReflowChart: "Graphique de redistribution",
	labelRightMargin: "Marge droite",
	labelRightSpacing: "Espacement droit",
	labelSecondsXAxes: "Secondes",
	labelSecondsYAxes: "Secondes",
	labelSelectionMarkFillColor: "Couleur de remplissage du marqueur de sélection",
	labelSeriesConnectNullPoints: "Connecter des points nuls",
	labelSeriesSelected: "Sélectionné",
	labelSeriesShowAnimation: "Afficher l'animation initiale",
	labelSeriesShowCheckbox: "Afficher la case à cocher",
	labelSeriesShowInLegend: "Afficher dans la légende",
	labelSeriesShowShadow: "Afficher l'ombre",
	labelSeriesVisible: "Visible",
	labelShowAxes: "Afficher les axes",
	labelShowCredits: "Afficher les crédits",
	labelShowDataPoints: "Afficher les points de données",
	labelShowLegend: "Afficher la légende",
	labelShowMeasureNameOnValueAxis: "Afficher le nom de la mesure sur l'axe des valeurs",
	labelShowTooltip: "Afficher l'info-bulle",
	labelShowTooltipAnimation: "Afficher l'animation",
	labelSubtitleFloatingTitle: "Titre flottant",
	labelSubtitleFontColor: "Couleur de la police",
	labelSubtitleFontFamily: "Famille de polices",
	labelSubtitleFontSize: "Taille de la police",
	labelSubtitleHorizontalAlignment: "Alignement horizontal",
	labelSubtitleText: "Sous-titre",
	labelSubtitleVerticalAlignment: "Alignement vertical",
	labelSubtitleXPosition: "Position X",
	labelSubtitleYPosition: "Position Y",
	labelTitleFloatingTitle: "Titre flottant",
	labelTitleFontColor: "Couleur de la police",
	labelTitleFontFamily: "Famille de polices",
	labelTitleFontSize: "Taille de la police",
	labelTitleHorizontalAlignment: "Alignement horizontal",
	labelTitleMargin: "Marge",
	labelTitleText: "Titre",
	labelTitleVerticalAlignment: "Alignement vertical",
	labelTitleXPosition: "Position X",
	labelTitleYPosition: "Position Y",
	labelTooltipBackgroundColor: "Couleur de fond",
	labelTooltipBorderColor: "Couleur de la bordure",
	labelTooltipBorderRadius: "Rayon de la bordure",
	labelTooltipBorderWidth: "Largeur de la bordure",
	labelTooltipDateFormat: "Format de date",
	labelTooltipFooterFormat: "Format de pied de page",
	labelTooltipHeaderFormat: "Format d'en-tête",
	labelTooltipPointFormat: "Format des points",
	labelTooltipShowShadow: "Afficher l'ombre",
	labelTooltipUseHTML: "Utiliser HTML",
	labelTooltipValueDecimals: "Valeur décimale",
	labelTooltipValuePrefix: "Préfixe de valeur",
	labelTooltipValueSuffix: "Suffixe de valeur",
	labelTopMargin: "Marge supérieure",
	labelTopSpacing: "Espacement supérieur",
	labelUseSharedTooltip: "Utiliser l'info-bulle partagée",
	labelVerticalAlignment: "Alignement vertical",
	labelWeeksXAxes: "Semaines",
	labelWeeksYAxes: "Semaines",
	labelXAxisRotation: "Degrés pour faire pivoter les étiquettes de l'axe X",
	labelXAxisStep: "Étapes pour afficher les étiquettes de l'axe X",
	labelXPosition: "Position X",
	labelYAxisRotation: "Degrés pour faire pivoter les étiquettes de l'axe Y",
	labelYAxisStep: "Étapes pour afficher les étiquettes de l'axe Y",
	labelYPosition: "Position Y",
	labelYearsXAxes: "Années",
	labelYearsYAxes: "Années",
	labelZoomType: "Type de zoom",
	lebelSeriesAllPointSelection: "Autoriser la sélection de points",
	labelYAxisMin: "Valeur minimale",
	labelYAxisMax: "Valeur maximale",
	labelSolidGaugeValueDecimals: "Décimales",
	labelSolidGaugeValueSuffix: "Suffixe",
	labelColorStops: "Arrêts de couleur",
	descriptionYAxisMin: "La valeur minimale de l'axe. Si nulle, la valeur minimale est automatiquement calculée.",
	descriptionYAxisMax: "La valeur maximale de l'axe. Si nulle, la valeur maximale est automatiquement calculée.",
	descriptionSolidGaugeValueSuffix: "Une chaîne à ajouter à la valeur y de chaque série. Remplaçable dans l'objet d'options d'info-bulle de chaque série.",
	descriptionSolidGaugeValueDecimals: "Combien de décimales afficher dans la valeur y de chaque série. Ceci est remplaçable dans l'objet d'options d'info-bulle de chaque série. La valeur par défaut est de conserver toutes les décimales",
	descriptionColorStops: "Série de jauge solide uniquement. Arrêts de couleur pour la jauge solide. Utilisez ceci dans les cas où un dégradé linéaire entre un minColor et un maxColor n'est pas suffisant. Les arrêts sont un tableau de tuples, où le premier élément est un flottant entre 0 et 1 attribuant la position relative dans le dégradé, et le deuxième élément est la couleur.",
	tileShape: "Forme de tuile",
	colorByPoint: "Couleur par point",
	descriptionTileShape: "Forme des tuiles dans un graphique Tilemap",
	descriptionColorByPoint: "Activer ou désactiver la couleur par point",
	viewer: {
		zoom: {
			fitWidth: "Ajuster la largeur",
			fitHeight: "Ajuster la hauteur",
			fitPage: "Ajuster la page"
		},
		pageControl: {
			page: {
				label: "Page",
				of: "de"
			}
		},
		report: {
			error: {
				label: "Erreur",
				details: "Détails"
			},
			empty: "Le rapport est vide !"
		},
		bookmarks: "Signets"
	},
	table: {
		novalues: "Il n'y a pas de valeurs"
	},
	queryeditor: {
		querypreview: {
			title: "Aperçu de la requête",
			first10: "10 premiers enregistrements",
			first100: "100 premiers enregistrements",
			first500: "500 premiers enregistrements",
			first1000: "1 000 premiers enregistrements",
			previewbutton: "Aperçu des données",
			resetbutton: "Réinitialiser"
		},
		metadata: {
			readbutton: "Obtenir les métadonnées"
		},
		help: {
			tooltip: "Aide"
		},
		format: {
			tooltip: "Formater"
		}
	},
	reportdesigner: {
		menu: {
			report: "Rapport",
			resetzoom: "Réinitialiser le zoom"
		},
		properties: {
			query: {
				language: {
					default: "La valeur par défaut est SQL"
				},
				filteredtable: {
					filter: {
						button: {
							tooltip: "Afficher le système ou l'utilisateur"
						}
					}
				}
			}
		},
		property: {
			resourceNotFound: "Ressource introuvable"
		}
	},
	IS_IGNORE_PAGINATION: "Ignorer la pagination",
	REPORT_LOCALE: "Paramètres Régionaux",
	REPORT_TIME_ZONE: "Fuseau Horaire",
	REPORT_VIRTUALIZER: "Virtualizer",
	REPORT_MAX_COUNT: "Nombre maximum d'enregistrements",
	PropertyEvaluationTimeEnum: {
		Report: "Rapport"
	},
	ExpressionTypeEnum: {
		label: "Type",
		default: "par défaut",
		simpleText: "simpleText"
	},
	MBand: {
		property: {
			height: {
				label: "Hauteur"
			},
			printWhen: {
				label: "Imprimer si l'expression"
			},
			error: {
				emptyHeight: "La valeur ne peut pas être vide",
				notANumber: "La valeur n'est pas un nombre",
				maximumExceeded: "La valeur dépasse le maximum",
				minimumExceeded: "La valeur est inférieure au minimum",
				documentHeightExceeded: "La hauteur totale des bandes dépasse la hauteur maximale du rapport"
			}
		}
	},
	MBreak: {
		property: {
			location: {
				label: "Emplacement"
			},
			size: {
				label: "Taille"
			},
			break: {
				label: "Pause"
			},
			appearance: {
				label: "Apparence"
			}
		}
	},
	MEllipse: {
		property: {
			appearance: {
				label: "Apparence"
			},
			ellipse: {
				label: "Ellipse"
			}
		}
	},
	MFrame: {
		property: {
			appearance: {
				label: "Apparence"
			},
			frame: {
				label: "Cadre"
			}
		}
	},
	JavaClass: {
		className: {
			error: {
				label: "Please define a valid Classname",
				mandatory: "Please define a Classname"
			}
		}
	},
	ReportDesigner: {
		save: {
			reportName: "Nom de Rapport",
			save: "Sauvegarder",
			saveAs: "Enregistrer Sous...",
			okButton: "Ok",
			saveButton: "Sauvegarder"
		}
	},
	properties: {
		selected: {
			label: "Sélectionné",
			label_one: "Sélectionné: {{element}}",
			label_other: "Sélectionné: {{element}} ({{count}} elements)"
		},
		filterby: "Rechercher par nom"
	},
	ClientJobAlertState: {
		NONE: "Aucun",
		ALL: "Tous",
		FAIL_ONLY: "Échec uniquement",
		SUCCESS_ONLY: "Réussite uniquement"
	},
	ClientMailNotificationSendType: {
		SEND: "Envoyer",
		SEND_ATTACHMENT: "Envoyer la pièce jointe",
		SEND_ATTACHMENT_NOZIP: "Envoyer la pièce jointe sans ZIP",
		SEND_EMBED: "Envoyer intégré",
		SEND_ATTACHMENT_ZIP_ALL: "Envoyer tout le ZIP de la pièce jointe",
		SEND_EMBED_ZIP_ALL_OTHERS: "Envoyer le ZIP intégré à tous les autres"
	},
	ClientJobAlertRecipient: {
		NONE: "Aucun",
		OWNER: "Propriétaire",
		ADMIN: "Administrateur",
		OWNER_AND_ADMIN: "Propriétaire et administrateur"
	}
}