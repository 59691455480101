/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const SplitTypeEnum = new PEnum({
    id: 'splitType', label: 'SplitType.label', isEmptyUndefined: true, default: 'Stretch',
    items: [
        { key: '', value: '' },
        { key: 'Stretch', value: 'SplitType.Stretch' },
        { key: 'Prevent', value: 'SplitType.Prevent' },
        { key: 'Immediate', value: 'SplitType.Immediate' }
    ]
});

