/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React, { forwardRef } from 'react';
import { MenuItem } from '@material-ui/core';

export type SelectItemProps = Parameters<typeof MenuItem>[0];

// eslint-disable-next-line react/display-name
export const SelectItem = forwardRef<HTMLLIElement, SelectItemProps>(({
    className = '', children, ...rest
}, ref) => {
    return (
        <MenuItem ref={ref} className={`jr-mInput-select-item mui ${className}`} {...rest}>{children}</MenuItem>
    )
})
