/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { ExcelDataAdapterEditor } from './ExcelDataAdapterEditor';
import { createNode, createNodeWithValue, getChilNodeByName, getProlog, IDataAdapterConfig, IDataAdapterDescriptor, setFileDataAdapterAttributes } from '../IDataAdapterDescriptor';
import format from 'xml-formatter';
import { MIME_TYPES } from '@jss/js-rest-api';
import { readXML } from '../HTTPConfigurationPanel';
import { nameValidator, urlValidator } from '@jss/js-common/src/utils/validators';
import { getDataAdapterNode } from '../../editor/Utils';


export default class ExcelDataAdapterDescriptor implements IDataAdapterDescriptor {

  public getMime = () => {
    return MIME_TYPES.DATA_ADAPTER_XLS;
  }

  public getIcon = () => {
    return <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 81 81"
      fill="#fff"
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <use xlinkHref="#excelIcon" x={0.5} y={0.5} />
      <symbol id="excelIcon" overflow="visible">
        <g fill="#02723b" stroke="none">
          <use xlinkHref="#b" />
          <use xlinkHref="#b" y={-9.498} />
          <use xlinkHref="#b" y={9.71} />
          <use xlinkHref="#b" y={19.209} />
          <path d="M57.684 55.726h12.842v6.332H57.684zM77.895 8.654H46.947V0L0 8.232v63.747L46.947 80v-9.71h30.947c1.263 0 2.105-1.055 2.105-2.111V10.765c0-1.055-.842-2.111-2.105-2.111zM26.526 54.248l-4.421-11.609h-.211c0 .211-4.421 10.976-4.421 10.976l-5.895-.422 6.947-13.72-6.316-13.72 5.895-.422 4 10.765h.211c0-.211 4.421-11.187 4.421-11.187l6.105-.422-7.368 14.987 7.579 14.987-6.526-.211zm50.737 13.72H46.947v-5.91h7.368v-6.333h-7.368v-3.166h7.368v-6.332h-7.368v-3.166h7.368v-6.332h-7.368v-3.166h7.368V27.23h-7.368v-3.166h7.368v-6.332h-7.368V11.4h30.316v56.57z" />
        </g>
      </symbol>
      <defs>
        <path id="excelIcon2" d="M57.684 26.807h12.842v6.333H57.684z" />
      </defs>
    </svg>
  }

  /**
   *  Return the name of this data adapter to be presented to the user
   *  i.e. Excel File
   */
  public getDataAdapterLabel = () => {
    return 'datasource.excel.label';
  }

  /**
  *  Return the name of this data adapter to be presented to the user
  *  i.e. CSV File
  */
  public getDataAdapterDescription = () => {
    return 'datasource.excel.description';
  }

  /**
   *  Return the canonical name of the class which impements this data adapter in Java.
   *  net.sf.jasperreports.data.csv.CsvDataAdapter
   */
  public getDataAdapterClass = () => {
    return 'net.sf.jasperreports.data.excel.ExcelDataAdapterImpl'
  }

  public getDataAdapterRootName = () => {
    return 'excelDataAdapter';
  }

  /**
   * Return a plain json object with the default data adapter configuration.
   * It can be just an empty object.
   *
   * @memberof IDataAdapter
   */
  public initializeDataAdapterConfig = () => {
    return {
      class: this.getDataAdapterClass(),
      name: 'New Excel Data Adapter',
      location: '',
      sheetSelection: '',
      format: 'autodetect',
      columnNames: [],
      columnIndexes: [],
      datePattern: undefined,
      numberPattern: undefined,
      locale: undefined,
      timeZone: undefined,
      useFirstRowAsHeader: false,
      queryExecuterMode: false,
    }
  };

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public validateDataAdapterConfig = (config: IDataAdapterConfig) => {

    // Check for errors...
    const errors = [];

    const nameError = nameValidator(config.name);
    if (nameError) {
      errors.push(nameError);
    }

    const urlError = urlValidator(config.location);
    if (urlError) {
      errors.push(urlError);
    }

    if (errors.length > 0) {
      return errors.join("\n");
    }

    return undefined;

  }

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public createEditor = (config: IDataAdapterConfig, onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => {
    return <ExcelDataAdapterEditor {...config} onDataAdapterSettingsChange={onDataAdapterSettingsChange} readOnly={readOnly} />
  }

  public getLanguages = () => {
    return ['text'];
  }

  public toXml = (config: IDataAdapterConfig) => {
    const xmlDoc = document.implementation.createDocument(null, this.getDataAdapterRootName());
    const root = xmlDoc.getElementsByTagName(this.getDataAdapterRootName())[0];
    root.setAttribute('class', this.getDataAdapterClass());

    const nameNode = createNode(xmlDoc, config, 'name', 'name');
    if (nameNode) {
      root.appendChild(nameNode);
    }

    setFileDataAdapterAttributes(xmlDoc, config, root);

    const userFirstRowAsHeaderNode = createNode(xmlDoc, config, 'useFirstRowAsHeader', 'useFirstRowAsHeader');
    if (userFirstRowAsHeaderNode) {
      root.appendChild(userFirstRowAsHeaderNode);
    }

    const queryExecuterModeNode = createNode(xmlDoc, config, 'queryExecuterMode', 'queryExecuterMode');
    if (queryExecuterModeNode) {
      root.appendChild(queryExecuterModeNode);
    }

    const sheetSelectionNode = createNode(xmlDoc, config, 'sheetSelection', 'sheetSelection');
    if (sheetSelectionNode) {
      root.appendChild(sheetSelectionNode);
    }

    const formatNode = createNode(xmlDoc, config, 'format', 'format');
    if (formatNode) {
      root.appendChild(formatNode);
    }

    const numberPatternNode = createNode(xmlDoc, config, 'numberPattern', 'numberPattern');
    if (numberPatternNode) {
      root.appendChild(numberPatternNode);
    }

    const datePatternNode = createNode(xmlDoc, config, 'datePattern', 'datePattern');
    if (datePatternNode) {
      root.appendChild(datePatternNode);
    }

    const timezoneNode = createNode(xmlDoc, config, 'timeZone', 'timeZone');
    if (timezoneNode) {
      root.appendChild(timezoneNode);
    }

    const localeValue = config.locale === 'en_EN' ? 'en' : config.locale;
    const localeNode = createNodeWithValue(xmlDoc, config, 'locale', localeValue);
    if (localeNode) {
      root.appendChild(localeNode);
    }

    config.columnNames.forEach((columnName: string) => {
      const columnNameNode = xmlDoc.createElement('columnNames');
      const newText = xmlDoc.createTextNode(columnName);
      columnNameNode.appendChild(newText);
      root.appendChild(columnNameNode);
    });

    config.columnIndexes.forEach((columnIndex: string) => {
      const columnNameNode = xmlDoc.createElement('columnIndexes');
      const newText = xmlDoc.createTextNode(columnIndex);
      columnNameNode.appendChild(newText);
      root.appendChild(columnNameNode);
    });

    const prolog = getProlog();
    const xml = prolog + (new XMLSerializer().serializeToString(xmlDoc));
    return format(xml, { indentation: '  ', collapseContent: true });
  }

  public toJson = (xml: Document) => {
    let result: any = {
      class: this.getDataAdapterClass(),
      name: '',
      location: undefined,
      sheetSelection: '',
      format: 'autodetect',
      columnNames: [],
      columnIndexes: [],
      datePattern: undefined,
      numberPattern: undefined,
      locale: undefined,
      timeZone: undefined,
      useFirstRowAsHeader: false,
    }

    getDataAdapterNode(xml.getRootNode()).childNodes.forEach((childNode) => {
      const nodeName = childNode.nodeName;
      if (nodeName === 'name') {
        result.name = childNode.textContent;
      } else if (nodeName === 'dataFile') {
        const locationNode = getChilNodeByName(childNode, 'location');
        if (!locationNode) {
          const urlNode = getChilNodeByName(childNode, 'url');
          const url = urlNode.textContent;
          result.location = url;
        } else {
          result.location = locationNode.textContent;
        }
        result = {
          ...result,
          ...readXML(childNode as Element),
        }
      } else if (nodeName === 'fileName') {
        result.location = childNode.textContent;
      } else if (nodeName === 'queryExecuterMode') {
        result.queryExecuterMode = (childNode.textContent === 'true');
      } else if (nodeName === 'sheetSelection') {
        result.sheetSelection = childNode.textContent;
      } else if (nodeName === 'format') {
        result.format = childNode.textContent;
      } else if (nodeName === 'locale') {
        //replace the en locale with en_EN to avoid glitch when typing since the key is the same of 
        //the start of the name
        const locale = childNode.textContent === 'en' ? 'en_EN' : childNode.textContent;
        result.locale = locale;
      } else if (nodeName === 'numberPattern') {
        result.numberPattern = childNode.textContent;
      } else if (nodeName === 'datePattern') {
        result.datePattern = childNode.textContent;
      } else if (nodeName === 'timeZone') {
        result.timeZone = childNode.textContent;
      } else if (nodeName === 'columnNames') {
        result.columnNames.push(childNode.textContent);
      } else if (nodeName === 'columnIndexes') {
        result.columnIndexes.push(childNode.textContent);
      } else if (nodeName === 'useFirstRowAsHeader') {
        result.useFirstRowAsHeader = (childNode.textContent === 'true');
      }
    });
    return result;
  }
}