/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { getKey, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { Switch } from '@jss/js-common';
import { IconButton } from '@jss/js-common';
import { PEnum, UPEnum } from './UPEnum';
import { resolveAttribute } from '../../types/common/StyleResolver';
import { Map } from 'immutable';
import '../../../../../assets/uxpl/css/UPControls.css';
import Commoni18n from '@jss/js-common/src/i18n';
import i18n from '../../../../../i18n';

const TYPEID = 'boolean';
reg(TYPEID, (mc) => { return <UPBoolean mcontext={mc} />; });
export class PBoolean extends APDescriptor {
    icon?: string;
    canBeNull?: boolean;
    default?: string;
    iconComponent?: React.ReactElement;
    public constructor(init: Partial<PBoolean>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

export interface IUPBoolean {
    additionalIcon?: React.ReactElement,
}

export class UPBoolean extends React.Component<UiPropertyProps & IUPBoolean> {

    shouldComponentUpdate(nextProps: Readonly<UiPropertyProps>): boolean {
        const d1 = this.props.mcontext.descriptor as PBoolean;
        const d2 = nextProps.mcontext.descriptor as PBoolean;
        const p1 = getPath(d1.id, this.props.mcontext.elements[0].path);
        const p2 = getPath(d2.id, nextProps.mcontext.elements[0].path);
        const v1 = this.props.mcontext.model.getIn(p1);
        const v2 = nextProps.mcontext.model.getIn(p2);
        return v1 !== v2;
    }

    render() {
        const d = this.props.mcontext.descriptor as PBoolean;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        let v = this.props.mcontext.model.getIn(p) as boolean | undefined;
        let isInherited = false;
        if (v === undefined && this.props.mcontext.descriptor.inheritedPropertyId) {
            const elementPath = getPath(undefined, this.props.mcontext.elements[0].path);
            const element = this.props.mcontext.model.getIn(elementPath) as Map<string, any>;
            isInherited = true;
            v = resolveAttribute(this.props.mcontext.model, element, this.props.mcontext.descriptor.inheritedPropertyId);
        }

        if (d.canBeNull === true) {
            const newd = new PEnum({
                ...d,
                items: [
                    { key: '', value: '' },
                    { key: 'true', value: Commoni18n.t('true') },
                    { key: 'false', value: Commoni18n.t('false') }
                ]
            })
            return <UPEnum additionalIcon={this.props.additionalIcon} mcontext={{ ...this.props.mcontext, descriptor: newd }} />
        }
        if (v === undefined) {
            v = d.default ? (d.default === 'true' ? true : false) : false;
            isInherited = true;
        }
        if (d.icon) {
            const nextValue = v !== undefined ? v.toString() !== 'true' : true;
            return <IconButton selected={!nextValue} icon={d.icon} size="small" key={getKey(p)} onClick={() => this.onChange(nextValue)} title={d.label} />
        }
        const classes: string[] = [];
        classes.push('sliderUniformHeight');
        if (this.props.mcontext.descriptor.className) {
            classes.push(this.props.mcontext.descriptor.className);
        }
        if (!isInherited) {
            classes.push('sliderDotNotInheritedColor');
        } else {
            classes.push('sliderInherithedColor');
            classes.push('sliderDotInheritedColor');
        }
        const label = i18n.t(this.props.mcontext.descriptor.label);
        return <Switch
            className={classes.join(' ')}
            style={this.props.mcontext.descriptor.style}
            key={getKey(p)}
            onChange={this.onSwitchChange}
            label={label}
            SwitchLabelProps={d.deprecated ? {className: 'deprecatedProperty'} : undefined}
            disabled={d.readonly}
            size={'small'}
            iconButtons={this.props.additionalIcon ? [this.props.additionalIcon] : undefined}
            checked={v}
        />
    }

    public onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.mcontext.elements.forEach(key => {
            const propertyPath = this.props.mcontext.descriptor.id.split('.');
            const propertyName = propertyPath.splice(propertyPath.length - 1, 1)[0];
            const fullPath = [...getPath(undefined, key.path), ...propertyPath]
            this.props.mcontext.setObjectProperties(fullPath, { [propertyName]: event.target.checked });
        });
    }

    public onChange = (value: boolean) => {
        this.props.mcontext.elements.forEach(key => {
            const propertyPath = this.props.mcontext.descriptor.id.split('.');
            const propertyName = propertyPath.splice(propertyPath.length - 1, 1)[0];
            const fullPath = [...getPath(undefined, key.path), ...propertyPath]
            this.props.mcontext.setObjectProperties(fullPath, { [propertyName]: value });
        });
    }

}