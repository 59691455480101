/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg } from '../../ui/UiProperty';
import UPSizeAndPosition, { PSizeAndPosition } from './UPSizeAndPosition';

const TYPEID = 'connectedSizeAndPosition';
export class PConnectedSizeAndPosition extends PSizeAndPosition {
    
    public constructor(init: Partial<PConnectedSizeAndPosition>) {
        super(init);
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPSizeAndPosition mcontext={mc} />; });