/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { AnyAction } from "redux";

/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 * 
 * -----
 * File Created: Tuesday, 25th September 2018 3:43:36 pm
 * Last Modified: Sunday, 30th September 2018 1:13:28 pm
 * Modified By: gtoffoli (gtoffoli@tibco.com>)
 * -----
 * Author: gtoffoli (gtoffoli@tibco.com)
 */


/**
 * Simplified way to create an action given a constant and its payload keys....
 * It returns a function to return the payload...
 * 
 * This function is suggested in Redux docs, but it is cleary not designed to create
 * strongly typed Actions.
 * 
 * @param {*} type 
 * @param {*} argNames 
 */
export const makeActionCreator = (type: string, ...argNames: any[]) => {
  return (...args: any[]) => {
    const action = { type }
    argNames.forEach((arg: any, index) => {
      action[argNames[index]] = args[index]
    })
    return action
  }
}


export type UndoDecorator = ( action: AnyAction) => AnyAction;
/**
 * Action decorator to force an undo operation before/after the execution.
 * 
 * @param action 
 */
export const undoPush = ( action: AnyAction): AnyAction => {
  if (action.type.endsWith('_ACTION')) {
      console.warn( "Undo decorators should not be used with Saga actions");
  }
  return { ...action, _perform_undo: true };
}

/**
 * Action decorator to force ad undo operation before the execution, by replacing
 * the previous undo.
 * 
 * @param action 
 */
export const undoReplace = ( action: AnyAction): AnyAction => {
if (action.type.endsWith('_ACTION')) {
  console.warn( "Undo decorators should not be used with Saga actions");
}
return { ...action, _perform_undo_replace: true };
}