export const en = {
	components: {
		report: {
			jrxmlviewer: {
				menu: {
					file: "File"
				},
				export: {
					pdf: "PDF Document (.pdf)",
					xlsx: "Microsoft Excel (.xlsx)",
					docx: "Microsoft Word (.docx)",
					pptx: "Microsoft PowerPoint (.pptx)",
					csv: "Comma Separated Values (.csv)",
					data_csv: "Comma Separated Values - Metadata (.csv)",
					xls: "Microsoft Excel (.xls)",
					data_xlsx: "Microsoft Excel - Metadata (.xlsx)",
					ods: "OpenDocument Spreadsheet (.ods)",
					odt: "OpenDocument Text (.odt)",
					rtf: "Rich Text Format (.rtf)"
				}
			},
			reportUnitWarning: 'Previewing this report will modify the original report unit. This action cannot be undone. Are you sure you want to continue with the report unit preview?',
			dontAskAnymore: 'Do not show this again for the current report editing session.'
		},
		datasets: {
			label: 'Dataset',
			whenResourceMissing: {
				label: "When Resource Missing",
				null: "NULL",
				empty: "Empty",
				error: "Error",
				key: "Key"
			},
			resourceBundle: "Resource Bundle",
			scripletClass: "Scriptlet Class",
			language: "Language",
			showQueryEditor: "Show Query Editor",
			filterExpression: "Filter Expression",
			fields: "Fields",
			field: "Field",
			parameters: {
				label: "Parameters",
				dateError: "Invalid Date Format"
			},
			variables: "Variables",
			groups: "Groups",
			sortFields: "Sort Fields",
			scriptlets: "Scriptlets"
		},
		common: {
			error: {
				dialog: {
					title: "Error",
					details: "Details"
				},
				boundary: {
					message: "An unexpected error occurred!"
				}
			}
		}
	},
	adhoc: {
		outlineLabel: "ADHOC COMPONENT"
	},
	genericElement: {
		outlineLabel: "GENERIC ELEMENT"
	},
	enum: {
		inherited: "[inherited]"
	},
	true: "true",
	false: "false",
	ResourcePicker: {
		selectResource: "Select Resource"
	},
	ExpressionValue: {
		switchToExpression: "Constant value, click to switch to Expression",
		switchToValue: "Expression, click to switch to Constant value"
	},
	StretchType: {
		label: "Stretch Type",
		NoStretch: "No Stretch",
		RelativeToTallestObject: "Relative To Tallest Object",
		RelativeToBandHeight: "Relative To Band Height",
		ElementGroupHeight: "Element Group Height",
		ElementGroupBottom: "Element Group Bottom",
		ContainerHeight: "Container Height",
		ContainerBottom: "Container Bottom"
	},
	PositionType: {
		label: "Position Type",
		Float: "Float",
		FixRelativeToTop: "Fix Relative To Top",
		FixRelativeToBottom: "Fix Relative To Bottom"
	},
	HorizontalTextAlign: {
		label: "Horizontal Text Alignment",
		Left: "Left",
		Center: "Center",
		Right: "Right",
		Justify: "Horizontal Justify"
	},
	HorizontalAlign: {
		label: "Horizontal Position"
	},
	TabStopAlignEnum: {
		label: "Alignment"
	},
	HorizontalImageAlign: {
		label: "Horizontal Image Alignment",
		Left: "Left",
		Center: "Center",
		Right: "Right"
	},
	ImageAnchorType: {
		Label: "Image Anchor Type",
		MoveSize: "Move Size",
		MoveNoSize: "Move No Size",
		NoMoveNoSize: "No Move No Size"
	},
	ErrorCorrectionLevel: {
		label: "Error Correction Level"
	},
	VerticalTextAlign: {
		label: "Vertical Text Alignment",
		Top: "Top",
		Middle: "Middle",
		Bottom: "Bottom",
		Justify: "Vertical Justify"
	},
	VerticalImageAlign: {
		label: "Vertical Image Alignment",
		Top: "Top",
		Middle: "Middle",
		Bottom: "Bottom"
	},
	Rotation: {
		label: "Rotation",
		None: "None",
		Left: "Left",
		Right: "Right",
		UpsideDown: "Upside Down"
	},
	band: {
		title: "Title",
		pageHeader: "Page Header",
		columnHeader: "Column Header",
		groupHeader: "Group Header",
		detail: "Detail",
		groupFooter: "Group Footer",
		columnFooter: "Column Footer",
		pageFooter: "Page Footer",
		background: "Background",
		noData: "No Data",
		summary: "Summary",
		lastPageFooter: "Last Page Footer"
	},
	WhenNoDataType: {
		label: "When No Data Show",
		NoPages: "None",
		BlankPage: "Blank Page",
		AllSectionsNoDetail: "All Sections No Detail",
		NoDataSection: "No Data Section"
	},
	WhenNoDataTypeTable: {
		label: "Image Anchor Type",
		Blank: "Blank",
		NoDataCell: "No Data Cell",
		AllSectionsNoDetail: "All Sections No Detail"
	},
	TablePrintWhen: {
		PrintWhen: 'Print When'
	},
	TableSplitType: {
		Label: 'Split Type',
		Inherited: 'Inherited',
		Stretch: 'Stretch',
		Prevent: 'Prevent',
		Immediate: 'Immediate',
	},
	ParameterEvaluationTime: {
		label: "Evaluation Time",
		description: "Evaluate the parameter default value expression before or after the data adapter",
		Early: "Early",
		Late: "Late"
	},
	StyleCombo: {
		noStyle: "No Style",
	},
	PdfFieldBorderStyleEnum: {
		label: "Border Style",
		Solid: "Solid",
		Dashed: "Dashed",
		Beveled: "Beveled",
		Inset: "Inset",
		Underline: "Underline"
	},
	PdfFieldCheckTypeEnum: {
		label: "Border Style",
		Check: "Check",
		Circle: "Circle",
		Cross: "Cross",
		Diamond: "Diamond",
		Square: "Square",
		Star: "Star"
	},
	PdfFieldTypeEnum: {
		label: "Border Style",
		Text: "Text",
		Combo: "Combo",
		List: "List",
		Check: "Check",
		Radio: "Radio"
	},
	PdfPrintScalingEnum: {
		label: "Border Style",
		None: "None",
		Default: "Default"
	},
	EvaluationTime: {
		label: "Evaluation Time",
		Now: "Now",
		Report: "Report",
		Page: "Page",
		Column: "Column",
		Group: "Group",
		Band: "Band",
		Auto: "Auto",
		Master: "Master"
	},
	TextAdjust: {
		label: "Text Adjust",
		CutText: "Cut Text",
		StretchHeight: "Stretch Height",
		ScaleFont: "Scale Font"
	},
	SplitType: {
		label: "Split Type",
		Stretch: "Stretch",
		Prevent: "Prevent",
		Immediate: "Immediate"
	},
	OrientationType: {
		label: "Orientation Type",
		Portrait: "Portrait",
		Landscape: "Landscape"
	},
	PrintOrder: {
		label: "Print Order",
		Vertical: "Vertical",
		Horizontal: "Horizontal"
	},
	ScaleImage: {
		label: "Scale Image",
		Clip: "Clip",
		FillFrame: "Fill Frame",
		RetainShape: "Retain Shape",
		RealHeight: "Real Height",
		RealSize: "Real Size"
	},
	Fill: {
		label: "Fill",
		SOLID: "Solid"
	},
	OnErrorType: {
		label: "On Error",
		Error: "Error",
		Blank: "Blank",
		Icon: "Icon"
	},
	IncrementType: {
		label: "Increment On",
		None: "None",
		Report: "Report",
		Page: "Page",
		Column: "Column",
		Group: "Group"
	},
	Calculation: {
		label: "Calculation",
		Nothing: "None",
		Count: "Count",
		Sum: "Sum",
		Average: "Average",
		Lowest: "Lowest",
		Highest: "Highest",
		StandardDeviation: "Standard Deviation",
		Variance: "Variance",
		System: "System",
		First: "First",
		DistinctCount: "Distinct Count"
	},
	ResetType: {
		label: "Reset On",
		None: "None",
		Report: "Report",
		Page: "Page",
		Column: "Column",
		Master: "Master",
		Group: "Group"
	},
	Order: {
		label: "Order",
		Ascending: "Ascending",
		Descending: "Descending"
	},
	BorderSplittype: {
		label: "Border Split Type",
		description: "Specifies the object position when the report section is affected by stretch.",
		NoBorders: "No Borders",
		DrawBorders: "Draw Borders"
	},
	LineDirection: {
		label: "Direction",
		TopDown: "Top Down",
		BottomUp: "Bottom Up"
	},
	BreakType: {
		label: "Type",
		Page: "Page",
		Column: "Column"
	},
	OverflowType: {
		label: "On Overflow",
		description: "Determines what happens when the subreport contents overflows the element height. If not specified, the subreport will stretch to accomodate the contents.",
		Stretch: "Stretch",
		NoStretch: "No Stretch"
	},
	LineStyle: {
		label: "Line Style",
		Solid: "Solid",
		Dashed: "Dashed",
		Dotted: "Dotted",
		Double: "Double"
	},
	Orientation: {
		label: "Orientation",
		up: "Up",
		left: "Left",
		down: "Down",
		right: "Right"
	},
	TextPosition: {
		label: "Text Position",
		none: "None",
		bottom: "Bottom",
		top: "Top"
	},
	ChecksumMode: {
		label: "Checksum Mode",
		auto: "Auto",
		ignore: "Ignore",
		add: "Add",
		check: "Check"
	},
	LineSpacing: {
		label: "Type",
		Single: "Single",
		1_1_2: "1.5 Lines",
		Double: "Double",
		AtLeast: "At Least",
		Fixed: "Fixed",
		Proportional: "Proportional"
	},
	DataMatrixShape: {
		label: "Shape",
		forceNone: "Force None",
		forceSquare: "Force Square",
		forceRectangle: "Force Rectangle"
	},
	BaselinePosition: {
		label: "Baseline Position",
		auto: "Auto",
		ignore: "Ignore"
	},
	Edge: {
		label: "Position",
		Top: "Top",
		Bottom: "Bottom",
		Left: "Left",
		Right: "Right"
	},
	PlotOrientation: {
		label: "Orientation",
		Horizontal: "Horizontal",
		Vertical: "Vertical"
	},
	SpiderRotation: {
		label: "Rotation",
		Clockwise: "Clockwise",
		Anticlockwise: "Anticlockwise"
	},
	ScaleType: {
		label: "Scale Type",
		BothAxes: "Both Axes",
		DomainAxis: "Domain Axis",
		RangeAxis: "Range Axis"
	},
	ValueLocation: {
		label: "Location",
		none: "None",
		left: "Left",
		right: "Right",
		bulb: "Bulb"
	},
	HyperlinkType: {
		label: "Link Type",
		Null: "Null",
		None: "None",
		Reference: "Reference",
		LocalAnchor: "Local Anchor",
		LocalPage: "Local Page",
		RemoteAnchor: "Remote Anchor",
		RemotePage: "Remote Page",
		Custom: "Custom"
	},
	HyperlinkTarget: {
		label: "Link Target",
		None: "None",
		Self: "Self",
		Blank: "Blank",
		Parent: "Parent",
		Top: "Top",
		Custom: "Custom"
	},
	MeterShape: {
		label: "Shape",
		chord: "chord",
		circle: "circle",
		pie: "pie",
		dial: "dial"
	},
	AxisPosition: {
		label: "Axis Position",
		leftOrTop: "Left Or Top",
		rightOrBottom: "Right Or Bottom"
	},
	FooterPosition: {
		label: "Footer Position",
		Normal: "Normal",
		StackAtBottom: "Stack At Bottom",
		ForceAtBottom: "Force At Bottom",
		CollateAtBottom: "Collate At Bottom"
	},
	TimePeriodEnum: {
		label: "Time Period",
		Year: "Year",
		Quarter: "Quarter",
		Month: "Month",
		Week: "Week",
		Day: "Day",
		Hour: "Hour",
		Minute: "Minute",
		Second: "Second",
		Milisecond: "Milisecond"
	},
	TransactionIsolation: {
		label: "Transaction Isolation",
		NONE: "NONE",
		ReadUcommitted: "Read Ucommitted",
		ReadCommitted: "Read Committed",
		RepeatableRead: "Repeatable Read",
		Serializable: "Serializable"
	},
	TableOrderEnum: {
		label: "Legend Table Order"
	},
	TableOrder: {
		Row: "Row",
		Column: "Column"
	},
	MapType: {
		label: "Map Type",
		roadmap: "roadmap",
		satellite: "satellite",
		terrain: "terrain",
		hybrid: "hybrid"
	},
	RunDirection: {
		label: "Run Direction",
		LTR: "Left To Right",
		RTL: "Right To Left"
	},
	Mode: {
		label: "Transparency",
		Opaque: "Opaque",
		Transparent: "Transparent"
	},
	CrosstabPercentage: {
		label: "Percentage",
		None: "None",
		GrandTotal: "GrandTotal"
	},
	CrosstabTotalPosition: {
		label: "Total Position",
		None: "None",
		Start: "Start",
		End: "End"
	},
	Bucket: {
		label: "Order",
		None: "None",
		Ascending: "Ascending",
		Descending: "Descending"
	},
	CrosstabRowPosition: {
		label: "Position",
		Top: "Top",
		Middle: "Middle",
		Bottom: "Bottom",
		Stretch: "Stretch"
	},
	CrosstabColumnPosition: {
		label: "Position",
		Left: "Left",
		Center: "Center",
		Right: "Right",
		Stretch: "Stretch"
	},
	SortFieldType: {
		label: "Type",
		Field: "Field",
		Variable: "Variable"
	},
	CROSSTAB_ROW_GROUPS: "Row Groups",
	CROSSTAB_COLUMN_GROUPS: "Column Groups",
	CROSSTAB_MEASURES: "Measures",
	CROSSTAB_DETAIL: "Detail",
	CROSSTAB_NO_DATA_CELL: "When No Data",
	CROSSTAB_HEADER_CELL: "Crosstab Header Cell",
	CROSSTAB_ROW_HEADER_NAME_PREFIX: "Header",
	CROSSTAB_TOTAL_ROW_HEADER_NAME_PREFIX: "Total",
	CROSSTAB_COLUMN_HEADER_NAME_PREFIX: "Header",
	CROSSTAB_TOTAL_COLUMN_HEADER_NAME_PREFIX: "Total",
	TABLE_HEADER: "Table Header",
	TABLE_CELL: "Column",
	TABLE_GROUP_COLUMN: "Table Group Column",
	TABLE_COLUMN_HEADER: "Column Header",
	TABLE_GROUP_HEADER: "Group Header",
	TABLE_DETAIL: "Detail",
	TABLE_FOOTER: "Table Footer",
	TABLE_COLUMN_FOOTER: "Column Footer",
	TABLE_GROUP_FOOTER: "Group Footer",
	TABLE_GROUP_CELL: "Group Cell",
	viewer: {
		zoom: {
			fitWidth: "Fit Width",
			fitHeight: "Fit Height",
			fitPage: "Fit Page"
		},
		pageControl: {
			page: {
				label: "Page",
				of: "of",
				firstPage: "Go to First Page",
				previousPage: "Go to Previous Page",
				nextPage: "Go to Next Page",
				lastPage: "Go to Last Page"
			},
			export: "Export",
			run: "Run"
		},
		searchControl: {
			placeHolder: "Search report",
			searchPrevious: "Search previous",
			searchNext: "Search next",
			noResults: "No matches were found for: \"{{searchTerm}}\"!",
			searchCondition: "Search term should contain at least one non white-space character!",
			options: {
				caseSensitive: "Case Sensitive",
				caseSensitiveTitle: "The search will be case sensitive",
				wholeWordsOnly: "Whole Words Only",
				wholeWordsOnlyTitle: "Only whole words will be taken into account"
			}
		},
		report: {
			empty: "The report is empty!"
		},
		bookmarks: "Bookmarks"
	},
	tableElement: {
		novalues: "There are no values"
	},
	queryeditor: {
		querypreview: {
			title: "Query Preview",
			first10: "First 10 Records",
			first100: "First 100 Records",
			first500: "First 500 Records",
			first1000: "First 1000 Records",
			previewbutton: "Preview Data",
			resetbutton: "Reset"
		},
		metadata: {
			readbutton: "Get Metadata"
		},
		help: {
			tooltip: "Help"
		},
		format: {
			tooltip: "Format"
		}
	},
	reportdesigner: {
		menu: {
			report: "Report",
			resetzoom: "Reset Zoom"
		},
		properties: {
			label: 'Properties', 
			query: {
				language: {
					default: "Default is SQL"
				},
				filteredtable: {
					filter: {
						button: {
							tooltip: "Show built-in or user parameters"
						}
					}
				}
			}
		},
		property: {
			resourceNotFound: "Resource not found"
		}
	},
	IS_IGNORE_PAGINATION: "Ignore Pagination",
	REPORT_LOCALE: "Locale",
	REPORT_TIME_ZONE: "Time Zone",
	REPORT_VIRTUALIZER: "Virtualizer",
	REPORT_MAX_COUNT: "Max Rows",
	PropertyEvaluationTimeEnum: {
		Report: "Report"
	},
	ExpressionTypeEnum: {
		label: "Type",
		default: "default",
		simpleText: "simpleText"
	},
	MBand: {
		property: {
			height: {
				label: "Height"
			},
			printWhen: {
				label: "Print When Expression"
			},
			error: {
				emptyHeight: "Value can't be empty",
				notANumber: "The value is not a number",
				maximumExceeded: "The value exceed the maximum",
				minimumExceeded: "The value is below the minimum",
				documentHeightExceeded: "The total height of the bands exceed the maximum height of the report"
			}
		}
	},
	MBreak: {
		property: {
			location: {
				label: "Location"
			},
			size: {
				label: "Size"
			},
			break: {
				label: "Break"
			},
			appearance: {
				label: "Appearance"
			}
		}
	},
	MEllipse: {
		property: {
			appearance: {
				label: "Appearance"
			},
			ellipse: {
				label: "Ellipse"
			}
		}
	},
	MFrame: {
		property: {
			appearance: {
				label: "Appearance"
			},
			frame: {
				label: "Frame"
			}
		}
	},
	JavaClass: {
		className: {
			error: {
				label: "Please define a valid Classname",
				mandatory: "Please define a Classname"
			}
		}
	},
	ReportDesigner: {
		save: {
			reportName: "Report Name",
			save: "Save Report",
			saveAs: "Save Report As...",
			okButton: "Ok",
			saveButton: "Save."
		}
	},
	properties: {
		selected: {
			label_one: "Selected: {{element}}",
			label_other: "Selected: {{element}} ({{count}} elements)"
		},
		unknownElement: "Unsupported Element",
		filterby: "Search by name"
	},
	ClientJobAlertState: {
		NONE: "None",
		ALL: "All",
		FAIL_ONLY: "Fail Only",
		SUCCESS_ONLY: "Success Only"
	},
	ClientMailNotificationSendType: {
		SEND: "Send",
		SEND_ATTACHMENT: "Send Attachment",
		SEND_ATTACHMENT_NOZIP: "Send Attachment No ZIP",
		SEND_EMBED: "Send Embedded",
		SEND_ATTACHMENT_ZIP_ALL: "Send Attachment ZIP All",
		SEND_EMBED_ZIP_ALL_OTHERS: "Send Embed ZIP All Others"
	},
	ClientJobAlertRecipient: {
		NONE: "None",
		OWNER: "Owner",
		ADMIN: "Admin",
		OWNER_AND_ADMIN: "Owner And Admin"
	},
	"com.jaspersoft.studio.element.name" : "Element Label",
}