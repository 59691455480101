/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const PropertyEvaluationTimeEnum = new PEnum({
    id: 'evaluationTime', label: 'ParameterEvaluationTime.label', isEmptyUndefined: true, default: 'Early',
    items: [
        { key: '', value: '' },
        { key: 'Early', value: 'ParameterEvaluationTime.Early' },
        { key: 'Late', value: 'ParameterEvaluationTime.Late' },
        { key: 'Report', value: 'PropertyEvaluationTimeEnum.Report' }
    ]
});

