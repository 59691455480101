/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { fromJS, Map } from 'immutable';
import { call, put, select, spawn } from 'redux-saga/effects';

import * as DocumentUtils from '../documentUtils';

import { areaContainsArea, areaOverlapsArea, IPoint, IRectangle } from '../../../../types/geometry';

import * as ReportActions from '../../../../actions/reportActions';
import { IState } from '../../../../reducers';
import { ElementTypes } from '../../document/elementTypes';
import { getEditorIndex } from '../../../../reducers/report/reportReducer';


/**
 * This function manage the dragging of elements, produces special effects and conclude the final
 * operation on end...
 * The handle will process 3 possible states:
 * 1. drag not yet started
 * 2. drag in progress (dragStarted === true && commit === false)
 * 3. drag finished (commit === false)m it happens for instance on mouseUp event
 * 
 */
export function* handleSelectionDrag(point: IPoint, options: any, commit = false) {

  // We use let here only because we will update state with the latest immutable version.
  let state = yield select((s: IState) => s);
  const currentEditorIndex = getEditorIndex(state.get('report'));
  let mouse;
  if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
    const currentEditor = state.getIn(['report', 'subeditors', currentEditorIndex]);
    mouse = currentEditor.get('mouse');
  } else {
    mouse = state.getIn(['report', 'mouse']);
  }

  // 1. Let's see if this was the first move...
  const model = state.getIn(['report', 'model']);

  if (mouse.get('operation') !== ReportActions.DragOperations.DRAG_OP_SELECTION ||
    model == null) {
    return; // Nothing to do...
  }

  const currentLocation = point;
  let dragStarted = mouse.get('dragStarted');
  const figureId = "drag-selection";


  const startLocation = mouse.get('startLocation').toJS();

  const selectionRect = {
    x: Math.min(startLocation.x, currentLocation.x),
    y: Math.min(startLocation.y, currentLocation.y),
    width: Math.abs(currentLocation.x - startLocation.x),
    height: Math.abs(currentLocation.y - startLocation.y)
  }

  const selectionFullInclusive = startLocation.x <= currentLocation.x;

  // Let's find the elements contained in the selection....
  const elementIds = findElementsInRect(selectionRect, selectionFullInclusive, state.getIn(['report', 'model', 'elements']));

  if (!commit) { // Keep dragging.log...

    // 1. get the interaction figure...
    //    We use fromJS to transform this to a Map with nested map for props and style..
    const figureDef = fromJS({
      id: figureId,
      x: Math.min(startLocation.x, currentLocation.x),
      y: Math.min(startLocation.y, currentLocation.y),
      width: Math.abs(currentLocation.x - startLocation.x),
      height: Math.abs(currentLocation.y - startLocation.y),
      transient: true,
      props: {
        key: figureId,
        style: {
          left: Math.min(startLocation.x, currentLocation.x),
          top: Math.min(startLocation.y, currentLocation.y),
          width: Math.abs(currentLocation.x - startLocation.x),
          height: Math.abs(currentLocation.y - startLocation.y),
        },
        className: 'DragSelection'
      }
    });

    const originalDragStarted = dragStarted;
    if (!dragStarted) {
      dragStarted = currentLocation.x !== startLocation.x || currentLocation.y !== startLocation.y;
    }

    if (dragStarted) {
      if (!originalDragStarted) {
        // We need to initialize the drag and create the figure representing it...
        yield put(ReportActions.addInteractionFigure(figureId, figureDef));
      }
      else {
        yield put(ReportActions.updateInteractionFigure(figureId, figureDef));
      }

      yield put(ReportActions.setHighlightedElements(elementIds));
    }

    yield put(ReportActions.updateDragOperation(fromJS(currentLocation), dragStarted));
    // Recalculate the client area...
    yield call(DocumentUtils.updateClientArea);
  } else { // Ready to commit!!!
    let selectionData = elementIds.map((elementId) => {
      const path = ['elements', elementId];
      const element = model.getIn(path);
      const type = element.get('type');
      return {
        id: elementId,
        info: {
          path,
          type
        }
      }
    });
    if (selectionData.length === 0 && selectionRect.width === 0 && selectionRect.height === 0) {
      const fullPath = DocumentUtils.findContainerAt({ x: selectionRect.x, y: selectionRect.y });
      if (fullPath) {
        const path = fullPath.split('/');
        const band = model.getIn(path);
        const type = band.get('type');
        if (type === ElementTypes.BAND) {
          selectionData = [
            {
              id: band.get('id'),
              info: {
                path,
                type
              }
            }
          ];
        }
      } else {
        const currentEditorIndex = getEditorIndex(state.get('report'));
        if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
          const currentEditor = state.getIn(['report', 'subeditors', currentEditorIndex]) as Map<string, any>;
          const editedResourceId = currentEditor.get('editedResourceId').split('/')[1];
          const resource = state.getIn(['report', 'model', 'elements', editedResourceId]);
          const type = resource.get('type');
          const path = ['elements', editedResourceId];
          selectionData = [
            {
              id: editedResourceId,
              info: {
                path,
                type
              }
            }
          ];
        }
      }
    }
    yield put(ReportActions.selectElements(selectionData, options.shiftKey === true));

    yield put(ReportActions.setHighlightedElements([]));

    // Refresh the state...
    let updatedFigure
    state = yield select((s: IState) => s);
    const currentEditorIndex = getEditorIndex(state.get('report'));
    if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
      const currentEditor = state.getIn(['report', 'subeditors', currentEditorIndex]);
      updatedFigure = currentEditor.getIn(['interactionElements', figureId]);
    } else {
      // Make our selection no longer transient, so we can keep it around for another 200 ms...
      // and add the class fadeOut...
      updatedFigure = state.getIn(['report', 'interactionElements', figureId]);
    }

    if (updatedFigure) { // This should be always true...
      updatedFigure = updatedFigure.updateIn(['props', 'className'], (val: string) => val + ' fadeOut')
        .set('transient', false);
      yield put(ReportActions.updateInteractionFigure(figureId, updatedFigure));

      // Recalculate the client area and spawn the detached process to clean up the figure
      // Saga will not wait for removeInteractionFigureAfterFade.
      yield spawn(DocumentUtils.removeInteractionFiguresAfterAnimation, [figureId]);
      yield call(DocumentUtils.updateClientArea);

    }
  }
}




/**
 * Find the ids of the elements that are selected by the specified rectangle.
 * 
 * @param selectionRect 
 * @param selectionFullInclusive 
 * @param state 
 * 
 * @return a plain array of element ids.
 */
const findElementsInRect = (selectionRect: IRectangle, selectionFullInclusive: boolean, elements: Map<string, any>) => {

  let elementIds: string[] = [];

  const positionFinder = DocumentUtils.createObjectAbsolutePositionFinder();

  if (selectionFullInclusive) {

    elementIds = elements.reduce((ids: string[], ele: Map<string, any>, key: string) => {
      const elementPosition = positionFinder.findPosition(ele);
      if (elementPosition.renderElement && areaContainsArea(selectionRect, { ...DocumentUtils.immutableBoxToRect(ele), ...elementPosition })) {
        ids.push(key);
      }
      return ids;
    }, []);
  }
  else {

    elementIds = elements.reduce((ids: string[], ele: Map<string, any>, key: string) => {
      const elementPosition = positionFinder.findPosition(ele);
      if (elementPosition.renderElement && areaOverlapsArea(selectionRect, { ...DocumentUtils.immutableBoxToRect(ele), ...elementPosition })) {
        ids.push(key);
      }
      return ids;
    }, []);
  }

  return elementIds;

}
