/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from '../../ui/primitive/UPEnum';

export const TIMEZONE_ITEMS = [
	{ key: '', value: '' },
	{ key: 'Africa/Abidjan', value: "Greenwich Mean Time" },
	{ key: 'Africa/Accra', value: "Ghana Mean Time" },
	{ key: 'Africa/Addis_Ababa', value: "Eastern African Time" },
	{ key: 'Africa/Algiers', value: "Central European Time" },
	{ key: 'Africa/Asmara', value: "Eastern African Time" },
	{ key: 'Africa/Asmera', value: "Eastern African Time" },
	{ key: 'Africa/Bamako', value: "Greenwich Mean Time" },
	{ key: 'Africa/Bangui', value: "Western African Time" },
	{ key: 'Africa/Banjul', value: "Greenwich Mean Time" },
	{ key: 'Africa/Bissau', value: "Greenwich Mean Time" },
	{ key: 'Africa/Blantyre', value: "Central African Time" },
	{ key: 'Africa/Brazzaville', value: "Western African Time" },
	{ key: 'Africa/Bujumbura', value: "Central African Time" },
	{ key: 'Africa/Cairo', value: "Eastern European Time" },
	{ key: 'Africa/Casablanca', value: "Western European Time" },
	{ key: 'Africa/Ceuta', value: "Central European Time" },
	{ key: 'Africa/Conakry', value: "Greenwich Mean Time" },
	{ key: 'Africa/Dakar', value: "Greenwich Mean Time" },
	{ key: 'Africa/Dar_es_Salaam', value: "Eastern African Time" },
	{ key: 'Africa/Djibouti', value: "Eastern African Time" },
	{ key: 'Africa/Douala', value: "Western African Time" },
	{ key: 'Africa/El_Aaiun', value: "Western European Time" },
	{ key: 'Africa/Freetown', value: "Greenwich Mean Time" },
	{ key: 'Africa/Gaborone', value: "Central African Time" },
	{ key: 'Africa/Harare', value: "Central African Time" },
	{ key: 'Africa/Johannesburg', value: "South Africa Standard Time" },
	{ key: 'Africa/Juba', value: "Central African Time" },
	{ key: 'Africa/Kampala', value: "Eastern African Time" },
	{ key: 'Africa/Khartoum', value: "Central African Time" },
	{ key: 'Africa/Kigali', value: "Central African Time" },
	{ key: 'Africa/Kinshasa', value: "Western African Time" },
	{ key: 'Africa/Lagos', value: "Western African Time" },
	{ key: 'Africa/Libreville', value: "Western African Time" },
	{ key: 'Africa/Lome', value: "Greenwich Mean Time" },
	{ key: 'Africa/Luanda', value: "Western African Time" },
	{ key: 'Africa/Lubumbashi', value: "Central African Time" },
	{ key: 'Africa/Lusaka', value: "Central African Time" },
	{ key: 'Africa/Malabo', value: "Western African Time" },
	{ key: 'Africa/Maputo', value: "Central African Time" },
	{ key: 'Africa/Maseru', value: "South Africa Standard Time" },
	{ key: 'Africa/Mbabane', value: "South Africa Standard Time" },
	{ key: 'Africa/Mogadishu', value: "Eastern African Time" },
	{ key: 'Africa/Monrovia', value: "Greenwich Mean Time" },
	{ key: 'Africa/Nairobi', value: "Eastern African Time" },
	{ key: 'Africa/Ndjamena', value: "Western African Time" },
	{ key: 'Africa/Niamey', value: "Western African Time" },
	{ key: 'Africa/Nouakchott', value: "Greenwich Mean Time" },
	{ key: 'Africa/Ouagadougou', value: "Greenwich Mean Time" },
	{ key: 'Africa/Porto-Novo', value: "Western African Time" },
	{ key: 'Africa/Sao_Tome', value: "Greenwich Mean Time" },
	{ key: 'Africa/Timbuktu', value: "Greenwich Mean Time" },
	{ key: 'Africa/Tripoli', value: "Eastern European Time" },
	{ key: 'Africa/Tunis', value: "Central European Time" },
	{ key: 'Africa/Windhoek', value: "Central African Time" },
	{ key: 'America/Adak', value: "Hawaii Standard Time" },
	{ key: 'America/Anchorage', value: "Alaska Standard Time" },
	{ key: 'America/Anguilla', value: "Atlantic Standard Time" },
	{ key: 'America/Antigua', value: "Atlantic Standard Time" },
	{ key: 'America/Araguaina', value: "Brasilia Time" },
	{ key: 'America/Argentina/Buenos_Aires', value: "Argentine Time" },
	{ key: 'America/Argentina/Catamarca', value: "Argentine Time" },
	{ key: 'America/Argentina/ComodRivadavia', value: "Argentine Time" },
	{ key: 'America/Argentina/Cordoba', value: "Argentine Time" },
	{ key: 'America/Argentina/Jujuy', value: "Argentine Time" },
	{ key: 'America/Argentina/La_Rioja', value: "Argentine Time" },
	{ key: 'America/Argentina/Mendoza', value: "Argentine Time" },
	{ key: 'America/Argentina/Rio_Gallegos', value: "Argentine Time" },
	{ key: 'America/Argentina/Salta', value: "Argentine Time" },
	{ key: 'America/Argentina/San_Juan', value: "Argentine Time" },
	{ key: 'America/Argentina/San_Luis', value: "Argentine Time" },
	{ key: 'America/Argentina/Tucuman', value: "Argentine Time" },
	{ key: 'America/Argentina/Ushuaia', value: "Argentine Time" },
	{ key: 'America/Aruba', value: "Atlantic Standard Time" },
	{ key: 'America/Asuncion', value: "Paraguay Time" },
	{ key: 'America/Atikokan', value: "Eastern Standard Time" },
	{ key: 'America/Atka', value: "Hawaii Standard Time" },
	{ key: 'America/Bahia', value: "Brasilia Time" },
	{ key: 'America/Bahia_Banderas', value: "Central Standard Time" },
	{ key: 'America/Barbados', value: "Atlantic Standard Time" },
	{ key: 'America/Belem', value: "Brasilia Time" },
	{ key: 'America/Belize', value: "Central Standard Time" },
	{ key: 'America/Blanc-Sablon', value: "Atlantic Standard Time" },
	{ key: 'America/Boa_Vista', value: "Amazon Time" },
	{ key: 'America/Bogota', value: "Colombia Time" },
	{ key: 'America/Boise', value: "Mountain Standard Time" },
	{ key: 'America/Buenos_Aires', value: "Argentine Time" },
	{ key: 'America/Cambridge_Bay', value: "Mountain Standard Time" },
	{ key: 'America/Campo_Grande', value: "Amazon Time" },
	{ key: 'America/Cancun', value: "Eastern Standard Time" },
	{ key: 'America/Caracas', value: "Venezuela Time" },
	{ key: 'America/Catamarca', value: "Argentine Time" },
	{ key: 'America/Cayenne', value: "French Guiana Time" },
	{ key: 'America/Cayman', value: "Eastern Standard Time" },
	{ key: 'America/Chicago', value: "Central Standard Time" },
	{ key: 'America/Chihuahua', value: "Mountain Standard Time" },
	{ key: 'America/Coral_Harbour', value: "Eastern Standard Time" },
	{ key: 'America/Cordoba', value: "Argentine Time" },
	{ key: 'America/Costa_Rica', value: "Central Standard Time" },
	{ key: 'America/Creston', value: "Mountain Standard Time" },
	{ key: 'America/Cuiaba', value: "Amazon Time" },
	{ key: 'America/Curacao', value: "Atlantic Standard Time" },
	{ key: 'America/Danmarkshavn', value: "Greenwich Mean Time" },
	{ key: 'America/Dawson', value: "Mountain Standard Time" },
	{ key: 'America/Dawson_Creek', value: "Mountain Standard Time" },
	{ key: 'America/Denver', value: "Mountain Standard Time" },
	{ key: 'America/Detroit', value: "Eastern Standard Time" },
	{ key: 'America/Dominica', value: "Atlantic Standard Time" },
	{ key: 'America/Edmonton', value: "Mountain Standard Time" },
	{ key: 'America/Eirunepe', value: "Acre Time" },
	{ key: 'America/El_Salvador', value: "Central Standard Time" },
	{ key: 'America/Ensenada', value: "Pacific Standard Time" },
	{ key: 'America/Fort_Nelson', value: "Mountain Standard Time" },
	{ key: 'America/Fort_Wayne', value: "Eastern Standard Time" },
	{ key: 'America/Fortaleza', value: "Brasilia Time" },
	{ key: 'America/Glace_Bay', value: "Atlantic Standard Time" },
	{ key: 'America/Godthab', value: "Western Greenland Time" },
	{ key: 'America/Goose_Bay', value: "Atlantic Standard Time" },
	{ key: 'America/Grand_Turk', value: "Eastern Standard Time" },
	{ key: 'America/Grenada', value: "Atlantic Standard Time" },
	{ key: 'America/Guadeloupe', value: "Atlantic Standard Time" },
	{ key: 'America/Guatemala', value: "Central Standard Time" },
	{ key: 'America/Guayaquil', value: "Ecuador Time" },
	{ key: 'America/Guyana', value: "Guyana Time" },
	{ key: 'America/Halifax', value: "Atlantic Standard Time" },
	{ key: 'America/Havana', value: "Cuba Standard Time" },
	{ key: 'America/Hermosillo', value: "Mountain Standard Time" },
	{ key: 'America/Indiana/Indianapolis', value: "Eastern Standard Time" },
	{ key: 'America/Indiana/Knox', value: "Central Standard Time" },
	{ key: 'America/Indiana/Marengo', value: "Eastern Standard Time" },
	{ key: 'America/Indiana/Petersburg', value: "Eastern Standard Time" },
	{ key: 'America/Indiana/Tell_City', value: "Central Standard Time" },
	{ key: 'America/Indiana/Vevay', value: "Eastern Standard Time" },
	{ key: 'America/Indiana/Vincennes', value: "Eastern Standard Time" },
	{ key: 'America/Indiana/Winamac', value: "Eastern Standard Time" },
	{ key: 'America/Indianapolis', value: "Eastern Standard Time" },
	{ key: 'America/Inuvik', value: "Mountain Standard Time" },
	{ key: 'America/Iqaluit', value: "Eastern Standard Time" },
	{ key: 'America/Jamaica', value: "Eastern Standard Time" },
	{ key: 'America/Jujuy', value: "Argentine Time" },
	{ key: 'America/Juneau', value: "Alaska Standard Time" },
	{ key: 'America/Kentucky/Louisville', value: "Eastern Standard Time" },
	{ key: 'America/Kentucky/Monticello', value: "Eastern Standard Time" },
	{ key: 'America/Knox_IN', value: "Central Standard Time" },
	{ key: 'America/Kralendijk', value: "Atlantic Standard Time" },
	{ key: 'America/La_Paz', value: "Bolivia Time" },
	{ key: 'America/Lima', value: "Peru Time" },
	{ key: 'America/Los_Angeles', value: "Pacific Standard Time" },
	{ key: 'America/Louisville', value: "Eastern Standard Time" },
	{ key: 'America/Lower_Princes', value: "Atlantic Standard Time" },
	{ key: 'America/Maceio', value: "Brasilia Time" },
	{ key: 'America/Managua', value: "Central Standard Time" },
	{ key: 'America/Manaus', value: "Amazon Time" },
	{ key: 'America/Marigot', value: "Atlantic Standard Time" },
	{ key: 'America/Martinique', value: "Atlantic Standard Time" },
	{ key: 'America/Matamoros', value: "Central Standard Time" },
	{ key: 'America/Mazatlan', value: "Mountain Standard Time" },
	{ key: 'America/Mendoza', value: "Argentine Time" },
	{ key: 'America/Menominee', value: "Central Standard Time" },
	{ key: 'America/Merida', value: "Central Standard Time" },
	{ key: 'America/Metlakatla', value: "Alaska Standard Time" },
	{ key: 'America/Mexico_City', value: "Central Standard Time" },
	{ key: 'America/Miquelon', value: "Pierre & Miquelon Standard Time" },
	{ key: 'America/Moncton', value: "Atlantic Standard Time" },
	{ key: 'America/Monterrey', value: "Central Standard Time" },
	{ key: 'America/Montevideo', value: "Uruguay Time" },
	{ key: 'America/Montreal', value: "Eastern Standard Time" },
	{ key: 'America/Montserrat', value: "Atlantic Standard Time" },
	{ key: 'America/Nassau', value: "Eastern Standard Time" },
	{ key: 'America/New_York', value: "Eastern Standard Time" },
	{ key: 'America/Nipigon', value: "Eastern Standard Time" },
	{ key: 'America/Nome', value: "Alaska Standard Time" },
	{ key: 'America/Noronha', value: "Fernando de Noronha Time" },
	{ key: 'America/North_Dakota/Beulah', value: "Central Standard Time" },
	{ key: 'America/North_Dakota/Center', value: "Central Standard Time" },
	{ key: 'America/North_Dakota/New_Salem', value: "Central Standard Time" },
	{ key: 'America/Nuuk', value: "Western Greenland Time" },
	{ key: 'America/Ojinaga', value: "Mountain Standard Time" },
	{ key: 'America/Panama', value: "Eastern Standard Time" },
	{ key: 'America/Pangnirtung', value: "Eastern Standard Time" },
	{ key: 'America/Paramaribo', value: "Suriname Time" },
	{ key: 'America/Phoenix', value: "Mountain Standard Time" },
	{ key: 'America/Port-au-Prince', value: "Eastern Standard Time" },
	{ key: 'America/Port_of_Spain', value: "Atlantic Standard Time" },
	{ key: 'America/Porto_Acre', value: "Acre Time" },
	{ key: 'America/Porto_Velho', value: "Amazon Time" },
	{ key: 'America/Puerto_Rico', value: "Atlantic Standard Time" },
	{ key: 'America/Punta_Arenas', value: "GMT-03:00" },
	{ key: 'America/Rainy_River', value: "Central Standard Time" },
	{ key: 'America/Rankin_Inlet', value: "Central Standard Time" },
	{ key: 'America/Recife', value: "Brasilia Time" },
	{ key: 'America/Regina', value: "Central Standard Time" },
	{ key: 'America/Resolute', value: "Central Standard Time" },
	{ key: 'America/Rio_Branco', value: "Acre Time" },
	{ key: 'America/Rosario', value: "Argentine Time" },
	{ key: 'America/Santa_Isabel', value: "Pacific Standard Time" },
	{ key: 'America/Santarem', value: "Brasilia Time" },
	{ key: 'America/Santiago', value: "Chile Time" },
	{ key: 'America/Santo_Domingo', value: "Atlantic Standard Time" },
	{ key: 'America/Sao_Paulo', value: "Brasilia Time" },
	{ key: 'America/Scoresbysund', value: "Eastern Greenland Time" },
	{ key: 'America/Shiprock', value: "Mountain Standard Time" },
	{ key: 'America/Sitka', value: "Alaska Standard Time" },
	{ key: 'America/St_Barthelemy', value: "Atlantic Standard Time" },
	{ key: 'America/St_Johns', value: "Newfoundland Standard Time" },
	{ key: 'America/St_Kitts', value: "Atlantic Standard Time" },
	{ key: 'America/St_Lucia', value: "Atlantic Standard Time" },
	{ key: 'America/St_Thomas', value: "Atlantic Standard Time" },
	{ key: 'America/St_Vincent', value: "Atlantic Standard Time" },
	{ key: 'America/Swift_Current', value: "Central Standard Time" },
	{ key: 'America/Tegucigalpa', value: "Central Standard Time" },
	{ key: 'America/Thule', value: "Atlantic Standard Time" },
	{ key: 'America/Thunder_Bay', value: "Eastern Standard Time" },
	{ key: 'America/Tijuana', value: "Pacific Standard Time" },
	{ key: 'America/Toronto', value: "Eastern Standard Time" },
	{ key: 'America/Tortola', value: "Atlantic Standard Time" },
	{ key: 'America/Vancouver', value: "Pacific Standard Time" },
	{ key: 'America/Virgin', value: "Atlantic Standard Time" },
	{ key: 'America/Whitehorse', value: "Mountain Standard Time" },
	{ key: 'America/Winnipeg', value: "Central Standard Time" },
	{ key: 'America/Yakutat', value: "Alaska Standard Time" },
	{ key: 'America/Yellowknife', value: "Mountain Standard Time" },
	{ key: 'Antarctica/Casey', value: "Australian Western Standard Time" },
	{ key: 'Antarctica/Davis', value: "Davis Time" },
	{ key: 'Antarctica/DumontDUrville', value: "Dumont-d'Urville Time" },
	{ key: 'Antarctica/Macquarie', value: "Australian Eastern Standard Time (Macquarie)" },
	{ key: 'Antarctica/Mawson', value: "Mawson Time" },
	{ key: 'Antarctica/McMurdo', value: "New Zealand Standard Time" },
	{ key: 'Antarctica/Palmer', value: "Chile Time" },
	{ key: 'Antarctica/Rothera', value: "Rothera Time" },
	{ key: 'Antarctica/South_Pole', value: "New Zealand Standard Time" },
	{ key: 'Antarctica/Syowa', value: "Syowa Time" },
	{ key: 'Antarctica/Troll', value: "Coordinated Universal Time" },
	{ key: 'Antarctica/Vostok', value: "Vostok Time" },
	{ key: 'Arctic/Longyearbyen', value: "Central European Time" },
	{ key: 'Asia/Aden', value: "Arabia Standard Time" },
	{ key: 'Asia/Almaty', value: "Alma-Ata Time" },
	{ key: 'Asia/Amman', value: "Eastern European Time" },
	{ key: 'Asia/Anadyr', value: "Anadyr Time" },
	{ key: 'Asia/Aqtau', value: "Aqtau Time" },
	{ key: 'Asia/Aqtobe', value: "Aqtobe Time" },
	{ key: 'Asia/Ashgabat', value: "Turkmenistan Time" },
	{ key: 'Asia/Ashkhabad', value: "Turkmenistan Time" },
	{ key: 'Asia/Atyrau', value: "GMT+05:00" },
	{ key: 'Asia/Baghdad', value: "Arabia Standard Time" },
	{ key: 'Asia/Bahrain', value: "Arabia Standard Time" },
	{ key: 'Asia/Baku', value: "Azerbaijan Time" },
	{ key: 'Asia/Bangkok', value: "Indochina Time" },
	{ key: 'Asia/Barnaul', value: "GMT+07:00" },
	{ key: 'Asia/Beirut', value: "Eastern European Time" },
	{ key: 'Asia/Bishkek', value: "Kirgizstan Time" },
	{ key: 'Asia/Brunei', value: "Brunei Time" },
	{ key: 'Asia/Calcutta', value: "India Standard Time" },
	{ key: 'Asia/Chita', value: "Yakutsk Time" },
	{ key: 'Asia/Choibalsan', value: "Choibalsan Time" },
	{ key: 'Asia/Chongqing', value: "China Standard Time" },
	{ key: 'Asia/Chungking', value: "China Standard Time" },
	{ key: 'Asia/Colombo', value: "India Standard Time" },
	{ key: 'Asia/Dacca', value: "Bangladesh Time" },
	{ key: 'Asia/Damascus', value: "Eastern European Time" },
	{ key: 'Asia/Dhaka', value: "Bangladesh Time" },
	{ key: 'Asia/Dili', value: "Timor-Leste Time" },
	{ key: 'Asia/Dubai', value: "Gulf Standard Time" },
	{ key: 'Asia/Dushanbe', value: "Tajikistan Time" },
	{ key: 'Asia/Famagusta', value: "GMT+02:00" },
	{ key: 'Asia/Gaza', value: "Eastern European Time" },
	{ key: 'Asia/Harbin', value: "China Standard Time" },
	{ key: 'Asia/Hebron', value: "Eastern European Time" },
	{ key: 'Asia/Ho_Chi_Minh', value: "Indochina Time" },
	{ key: 'Asia/Hong_Kong', value: "Hong Kong Time" },
	{ key: 'Asia/Hovd', value: "Hovd Time" },
	{ key: 'Asia/Irkutsk', value: "Irkutsk Time" },
	{ key: 'Asia/Istanbul', value: "Eastern European Time" },
	{ key: 'Asia/Jakarta', value: "West Indonesia Time" },
	{ key: 'Asia/Jayapura', value: "East Indonesia Time" },
	{ key: 'Asia/Jerusalem', value: "Israel Standard Time" },
	{ key: 'Asia/Kabul', value: "Afghanistan Time" },
	{ key: 'Asia/Kamchatka', value: "Petropavlovsk-Kamchatski Time" },
	{ key: 'Asia/Karachi', value: "Pakistan Time" },
	{ key: 'Asia/Kashgar', value: "Xinjiang Standard Time" },
	{ key: 'Asia/Kathmandu', value: "Nepal Time" },
	{ key: 'Asia/Katmandu', value: "Nepal Time" },
	{ key: 'Asia/Khandyga', value: "Yakutsk Time" },
	{ key: 'Asia/Kolkata', value: "India Standard Time" },
	{ key: 'Asia/Krasnoyarsk', value: "Krasnoyarsk Time" },
	{ key: 'Asia/Kuala_Lumpur', value: "Malaysia Time" },
	{ key: 'Asia/Kuching', value: "Malaysia Time" },
	{ key: 'Asia/Kuwait', value: "Arabia Standard Time" },
	{ key: 'Asia/Macao', value: "China Standard Time" },
	{ key: 'Asia/Macau', value: "China Standard Time" },
	{ key: 'Asia/Magadan', value: "Magadan Time" },
	{ key: 'Asia/Makassar', value: "Central Indonesia Time" },
	{ key: 'Asia/Manila', value: "Philippines Standard Time" },
	{ key: 'Asia/Muscat', value: "Gulf Standard Time" },
	{ key: 'Asia/Nicosia', value: "Eastern European Time" },
	{ key: 'Asia/Novokuznetsk', value: "Krasnoyarsk Time" },
	{ key: 'Asia/Novosibirsk', value: "Novosibirsk Time" },
	{ key: 'Asia/Omsk', value: "Omsk Time" },
	{ key: 'Asia/Oral', value: "Oral Time" },
	{ key: 'Asia/Phnom_Penh', value: "Indochina Time" },
	{ key: 'Asia/Pontianak', value: "West Indonesia Time" },
	{ key: 'Asia/Pyongyang', value: "Korea Standard Time" },
	{ key: 'Asia/Qatar', value: "Arabia Standard Time" },
	{ key: 'Asia/Qostanay', value: "GMT+06:00" },
	{ key: 'Asia/Qyzylorda', value: "Qyzylorda Time" },
	{ key: 'Asia/Rangoon', value: "Myanmar Time" },
	{ key: 'Asia/Riyadh', value: "Arabia Standard Time" },
	{ key: 'Asia/Saigon', value: "Indochina Time" },
	{ key: 'Asia/Sakhalin', value: "Sakhalin Time" },
	{ key: 'Asia/Samarkand', value: "Uzbekistan Time" },
	{ key: 'Asia/Seoul', value: "Korea Standard Time" },
	{ key: 'Asia/Shanghai', value: "China Standard Time" },
	{ key: 'Asia/Singapore', value: "Singapore Time" },
	{ key: 'Asia/Srednekolymsk', value: "Srednekolymsk Time" },
	{ key: 'Asia/Taipei', value: "China Standard Time" },
	{ key: 'Asia/Tashkent', value: "Uzbekistan Time" },
	{ key: 'Asia/Tbilisi', value: "Georgia Time" },
	{ key: 'Asia/Tehran', value: "Iran Standard Time" },
	{ key: 'Asia/Tel_Aviv', value: "Israel Standard Time" },
	{ key: 'Asia/Thimbu', value: "Bhutan Time" },
	{ key: 'Asia/Thimphu', value: "Bhutan Time" },
	{ key: 'Asia/Tokyo', value: "Japan Standard Time" },
	{ key: 'Asia/Tomsk', value: "GMT+07:00" },
	{ key: 'Asia/Ujung_Pandang', value: "Central Indonesia Time" },
	{ key: 'Asia/Ulaanbaatar', value: "Ulaanbaatar Time" },
	{ key: 'Asia/Ulan_Bator', value: "Ulaanbaatar Time" },
	{ key: 'Asia/Urumqi', value: "Xinjiang Standard Time" },
	{ key: 'Asia/Ust-Nera', value: "Ust-Nera Time" },
	{ key: 'Asia/Vientiane', value: "Indochina Time" },
	{ key: 'Asia/Vladivostok', value: "Vladivostok Time" },
	{ key: 'Asia/Yakutsk', value: "Yakutsk Time" },
	{ key: 'Asia/Yangon', value: "Myanmar Time" },
	{ key: 'Asia/Yekaterinburg', value: "Yekaterinburg Time" },
	{ key: 'Asia/Yerevan', value: "Armenia Time" },
	{ key: 'Atlantic/Azores', value: "Azores Time" },
	{ key: 'Atlantic/Bermuda', value: "Atlantic Standard Time" },
	{ key: 'Atlantic/Canary', value: "Western European Time" },
	{ key: 'Atlantic/Cape_Verde', value: "Cape Verde Time" },
	{ key: 'Atlantic/Faeroe', value: "Western European Time" },
	{ key: 'Atlantic/Faroe', value: "Western European Time" },
	{ key: 'Atlantic/Jan_Mayen', value: "Central European Time" },
	{ key: 'Atlantic/Madeira', value: "Western European Time" },
	{ key: 'Atlantic/Reykjavik', value: "Greenwich Mean Time" },
	{ key: 'Atlantic/South_Georgia', value: "South Georgia Standard Time" },
	{ key: 'Atlantic/St_Helena', value: "Greenwich Mean Time" },
	{ key: 'Atlantic/Stanley', value: "Falkland Is. Time" },
	{ key: 'Australia/ACT', value: "Australian Eastern Standard Time (New South Wales)" },
	{ key: 'Australia/Adelaide', value: "Australian Central Standard Time (South Australia)" },
	{ key: 'Australia/Brisbane', value: "Australian Eastern Standard Time (Queensland)" },
	{ key: 'Australia/Broken_Hill', value: "Australian Central Standard Time (South Australia/New South Wales)" },
	{ key: 'Australia/Canberra', value: "Australian Eastern Standard Time (New South Wales)" },
	{ key: 'Australia/Currie', value: "Australian Eastern Standard Time (New South Wales)" },
	{ key: 'Australia/Darwin', value: "Australian Central Standard Time (Northern Territory)" },
	{ key: 'Australia/Eucla', value: "Australian Central Western Standard Time" },
	{ key: 'Australia/Hobart', value: "Australian Eastern Standard Time (Tasmania)" },
	{ key: 'Australia/LHI', value: "Lord Howe Standard Time" },
	{ key: 'Australia/Lindeman', value: "Australian Eastern Standard Time (Queensland)" },
	{ key: 'Australia/Lord_Howe', value: "Lord Howe Standard Time" },
	{ key: 'Australia/Melbourne', value: "Australian Eastern Standard Time (Victoria)" },
	{ key: 'Australia/NSW', value: "Australian Eastern Standard Time (New South Wales)" },
	{ key: 'Australia/North', value: "Australian Central Standard Time (Northern Territory)" },
	{ key: 'Australia/Perth', value: "Australian Western Standard Time" },
	{ key: 'Australia/Queensland', value: "Australian Eastern Standard Time (Queensland)" },
	{ key: 'Australia/South', value: "Australian Central Standard Time (South Australia)" },
	{ key: 'Australia/Sydney', value: "Australian Eastern Standard Time (New South Wales)" },
	{ key: 'Australia/Tasmania', value: "Australian Eastern Standard Time (Tasmania)" },
	{ key: 'Australia/Victoria', value: "Australian Eastern Standard Time (Victoria)" },
	{ key: 'Australia/West', value: "Australian Western Standard Time" },
	{ key: 'Australia/Yancowinna', value: "Australian Central Standard Time (South Australia/New South Wales)" },
	{ key: 'Brazil/Acre', value: "Acre Time" },
	{ key: 'Brazil/DeNoronha', value: "Fernando de Noronha Time" },
	{ key: 'Brazil/East', value: "Brasilia Time" },
	{ key: 'Brazil/West', value: "Amazon Time" },
	{ key: 'CET', value: "Central European Time" },
	{ key: 'CST6CDT', value: "Central Standard Time" },
	{ key: 'Canada/Atlantic', value: "Atlantic Standard Time" },
	{ key: 'Canada/Central', value: "Central Standard Time" },
	{ key: 'Canada/Eastern', value: "Eastern Standard Time" },
	{ key: 'Canada/Mountain', value: "Mountain Standard Time" },
	{ key: 'Canada/Newfoundland', value: "Newfoundland Standard Time" },
	{ key: 'Canada/Pacific', value: "Pacific Standard Time" },
	{ key: 'Canada/Saskatchewan', value: "Central Standard Time" },
	{ key: 'Canada/Yukon', value: "Mountain Standard Time" },
	{ key: 'Chile/Continental', value: "Chile Time" },
	{ key: 'Chile/EasterIsland', value: "Easter Is. Time" },
	{ key: 'Cuba', value: "Cuba Standard Time" },
	{ key: 'EET', value: "Eastern European Time" },
	{ key: 'EST5EDT', value: "Eastern Standard Time" },
	{ key: 'Egypt', value: "Eastern European Time" },
	{ key: 'Eire', value: "Greenwich Mean Time" },
	{ key: 'Etc/GMT', value: "Greenwich Mean Time" },
	{ key: 'Etc/GMT+0', value: "Greenwich Mean Time" },
	{ key: 'Etc/GMT+1', value: "GMT-01:00" },
	{ key: 'Etc/GMT+10', value: "GMT-10:00" },
	{ key: 'Etc/GMT+11', value: "GMT-11:00" },
	{ key: 'Etc/GMT+12', value: "GMT-12:00" },
	{ key: 'Etc/GMT+2', value: "GMT-02:00" },
	{ key: 'Etc/GMT+3', value: "GMT-03:00" },
	{ key: 'Etc/GMT+4', value: "GMT-04:00" },
	{ key: 'Etc/GMT+5', value: "GMT-05:00" },
	{ key: 'Etc/GMT+6', value: "GMT-06:00" },
	{ key: 'Etc/GMT+7', value: "GMT-07:00" },
	{ key: 'Etc/GMT+8', value: "GMT-08:00" },
	{ key: 'Etc/GMT+9', value: "GMT-09:00" },
	{ key: 'Etc/GMT-0', value: "Greenwich Mean Time" },
	{ key: 'Etc/GMT-1', value: "GMT+01:00" },
	{ key: 'Etc/GMT-10', value: "GMT+10:00" },
	{ key: 'Etc/GMT-11', value: "GMT+11:00" },
	{ key: 'Etc/GMT-12', value: "GMT+12:00" },
	{ key: 'Etc/GMT-13', value: "GMT+13:00" },
	{ key: 'Etc/GMT-14', value: "GMT+14:00" },
	{ key: 'Etc/GMT-2', value: "GMT+02:00" },
	{ key: 'Etc/GMT-3', value: "GMT+03:00" },
	{ key: 'Etc/GMT-4', value: "GMT+04:00" },
	{ key: 'Etc/GMT-5', value: "GMT+05:00" },
	{ key: 'Etc/GMT-6', value: "GMT+06:00" },
	{ key: 'Etc/GMT-7', value: "GMT+07:00" },
	{ key: 'Etc/GMT-8', value: "GMT+08:00" },
	{ key: 'Etc/GMT-9', value: "GMT+09:00" },
	{ key: 'Etc/GMT0', value: "Greenwich Mean Time" },
	{ key: 'Etc/Greenwich', value: "Greenwich Mean Time" },
	{ key: 'Etc/UCT', value: "Coordinated Universal Time" },
	{ key: 'Etc/UTC', value: "Coordinated Universal Time" },
	{ key: 'Etc/Universal', value: "Coordinated Universal Time" },
	{ key: 'Etc/Zulu', value: "Coordinated Universal Time" },
	{ key: 'Europe/Amsterdam', value: "Central European Time" },
	{ key: 'Europe/Andorra', value: "Central European Time" },
	{ key: 'Europe/Astrakhan', value: "GMT+04:00" },
	{ key: 'Europe/Athens', value: "Eastern European Time" },
	{ key: 'Europe/Belfast', value: "Greenwich Mean Time" },
	{ key: 'Europe/Belgrade', value: "Central European Time" },
	{ key: 'Europe/Berlin', value: "Central European Time" },
	{ key: 'Europe/Bratislava', value: "Central European Time" },
	{ key: 'Europe/Brussels', value: "Central European Time" },
	{ key: 'Europe/Bucharest', value: "Eastern European Time" },
	{ key: 'Europe/Budapest', value: "Central European Time" },
	{ key: 'Europe/Busingen', value: "Central European Time" },
	{ key: 'Europe/Chisinau', value: "Eastern European Time" },
	{ key: 'Europe/Copenhagen', value: "Central European Time" },
	{ key: 'Europe/Dublin', value: "Greenwich Mean Time" },
	{ key: 'Europe/Gibraltar', value: "Central European Time" },
	{ key: 'Europe/Guernsey', value: "Greenwich Mean Time" },
	{ key: 'Europe/Helsinki', value: "Eastern European Time" },
	{ key: 'Europe/Isle_of_Man', value: "Greenwich Mean Time" },
	{ key: 'Europe/Istanbul', value: "Eastern European Time" },
	{ key: 'Europe/Jersey', value: "Greenwich Mean Time" },
	{ key: 'Europe/Kaliningrad', value: "Eastern European Time" },
	{ key: 'Europe/Kiev', value: "Eastern European Time" },
	{ key: 'Europe/Kirov', value: "GMT+03:00" },
	{ key: 'Europe/Lisbon', value: "Western European Time" },
	{ key: 'Europe/Ljubljana', value: "Central European Time" },
	{ key: 'Europe/London', value: "Greenwich Mean Time" },
	{ key: 'Europe/Luxembourg', value: "Central European Time" },
	{ key: 'Europe/Madrid', value: "Central European Time" },
	{ key: 'Europe/Malta', value: "Central European Time" },
	{ key: 'Europe/Mariehamn', value: "Eastern European Time" },
	{ key: 'Europe/Minsk', value: "Moscow Standard Time" },
	{ key: 'Europe/Monaco', value: "Central European Time" },
	{ key: 'Europe/Moscow', value: "Moscow Standard Time" },
	{ key: 'Europe/Nicosia', value: "Eastern European Time" },
	{ key: 'Europe/Oslo', value: "Central European Time" },
	{ key: 'Europe/Paris', value: "Central European Time" },
	{ key: 'Europe/Podgorica', value: "Central European Time" },
	{ key: 'Europe/Prague', value: "Central European Time" },
	{ key: 'Europe/Riga', value: "Eastern European Time" },
	{ key: 'Europe/Rome', value: "Central European Time" },
	{ key: 'Europe/Samara', value: "Samara Time" },
	{ key: 'Europe/San_Marino', value: "Central European Time" },
	{ key: 'Europe/Sarajevo', value: "Central European Time" },
	{ key: 'Europe/Saratov', value: "GMT+04:00" },
	{ key: 'Europe/Simferopol', value: "Moscow Standard Time" },
	{ key: 'Europe/Skopje', value: "Central European Time" },
	{ key: 'Europe/Sofia', value: "Eastern European Time" },
	{ key: 'Europe/Stockholm', value: "Central European Time" },
	{ key: 'Europe/Tallinn', value: "Eastern European Time" },
	{ key: 'Europe/Tirane', value: "Central European Time" },
	{ key: 'Europe/Tiraspol', value: "Eastern European Time" },
	{ key: 'Europe/Ulyanovsk', value: "GMT+04:00" },
	{ key: 'Europe/Uzhgorod', value: "Eastern European Time" },
	{ key: 'Europe/Vaduz', value: "Central European Time" },
	{ key: 'Europe/Vatican', value: "Central European Time" },
	{ key: 'Europe/Vienna', value: "Central European Time" },
	{ key: 'Europe/Vilnius', value: "Eastern European Time" },
	{ key: 'Europe/Volgograd', value: "Moscow Standard Time" },
	{ key: 'Europe/Warsaw', value: "Central European Time" },
	{ key: 'Europe/Zagreb', value: "Central European Time" },
	{ key: 'Europe/Zaporozhye', value: "Eastern European Time" },
	{ key: 'Europe/Zurich', value: "Central European Time" },
	{ key: 'GB', value: "Greenwich Mean Time" },
	{ key: 'GB-Eire', value: "Greenwich Mean Time" },
	{ key: 'GMT', value: "Greenwich Mean Time" },
	{ key: 'GMT0', value: "Greenwich Mean Time" },
	{ key: 'Greenwich', value: "Greenwich Mean Time" },
	{ key: 'Hongkong', value: "Hong Kong Time" },
	{ key: 'Iceland', value: "Greenwich Mean Time" },
	{ key: 'Indian/Antananarivo', value: "Eastern African Time" },
	{ key: 'Indian/Chagos', value: "Indian Ocean Territory Time" },
	{ key: 'Indian/Christmas', value: "Christmas Island Time" },
	{ key: 'Indian/Cocos', value: "Cocos Islands Time" },
	{ key: 'Indian/Comoro', value: "Eastern African Time" },
	{ key: 'Indian/Kerguelen', value: "French Southern & Antarctic Lands Time" },
	{ key: 'Indian/Mahe', value: "Seychelles Time" },
	{ key: 'Indian/Maldives', value: "Maldives Time" },
	{ key: 'Indian/Mauritius', value: "Mauritius Time" },
	{ key: 'Indian/Mayotte', value: "Eastern African Time" },
	{ key: 'Indian/Reunion', value: "Reunion Time" },
	{ key: 'Iran', value: "Iran Standard Time" },
	{ key: 'Israel', value: "Israel Standard Time" },
	{ key: 'Jamaica', value: "Eastern Standard Time" },
	{ key: 'Japan', value: "Japan Standard Time" },
	{ key: 'Kwajalein', value: "Marshall Islands Time" },
	{ key: 'Libya', value: "Eastern European Time" },
	{ key: 'MET', value: "Middle Europe Time" },
	{ key: 'MST7MDT', value: "Mountain Standard Time" },
	{ key: 'Mexico/BajaNorte', value: "Pacific Standard Time" },
	{ key: 'Mexico/BajaSur', value: "Mountain Standard Time" },
	{ key: 'Mexico/General', value: "Central Standard Time" },
	{ key: 'NZ', value: "New Zealand Standard Time" },
	{ key: 'NZ-CHAT', value: "Chatham Standard Time" },
	{ key: 'Navajo', value: "Mountain Standard Time" },
	{ key: 'PRC', value: "China Standard Time" },
	{ key: 'PST8PDT', value: "Pacific Standard Time" },
	{ key: 'Pacific/Apia', value: "West Samoa Standard Time" },
	{ key: 'Pacific/Auckland', value: "New Zealand Standard Time" },
	{ key: 'Pacific/Bougainville', value: "Bougainville Standard Time" },
	{ key: 'Pacific/Chatham', value: "Chatham Standard Time" },
	{ key: 'Pacific/Chuuk', value: "Chuuk Time" },
	{ key: 'Pacific/Easter', value: "Easter Is. Time" },
	{ key: 'Pacific/Efate', value: "Vanuatu Time" },
	{ key: 'Pacific/Enderbury', value: "Phoenix Is. Time" },
	{ key: 'Pacific/Fakaofo', value: "Tokelau Time" },
	{ key: 'Pacific/Fiji', value: "Fiji Time" },
	{ key: 'Pacific/Funafuti', value: "Tuvalu Time" },
	{ key: 'Pacific/Galapagos', value: "Galapagos Time" },
	{ key: 'Pacific/Gambier', value: "Gambier Time" },
	{ key: 'Pacific/Guadalcanal', value: "Solomon Is. Time" },
	{ key: 'Pacific/Guam', value: "Chamorro Standard Time" },
	{ key: 'Pacific/Honolulu', value: "Hawaii Standard Time" },
	{ key: 'Pacific/Johnston', value: "Hawaii Standard Time" },
	{ key: 'Pacific/Kanton', value: "Phoenix Is. Time" },
	{ key: 'Pacific/Kiritimati', value: "Line Is. Time" },
	{ key: 'Pacific/Kosrae', value: "Kosrae Time" },
	{ key: 'Pacific/Kwajalein', value: "Marshall Islands Time" },
	{ key: 'Pacific/Majuro', value: "Marshall Islands Time" },
	{ key: 'Pacific/Marquesas', value: "Marquesas Time" },
	{ key: 'Pacific/Midway', value: "Samoa Standard Time" },
	{ key: 'Pacific/Nauru', value: "Nauru Time" },
	{ key: 'Pacific/Niue', value: "Niue Time" },
	{ key: 'Pacific/Norfolk', value: "Norfolk Time" },
	{ key: 'Pacific/Noumea', value: "New Caledonia Time" },
	{ key: 'Pacific/Pago_Pago', value: "Samoa Standard Time" },
	{ key: 'Pacific/Palau', value: "Palau Time" },
	{ key: 'Pacific/Pitcairn', value: "Pitcairn Standard Time" },
	{ key: 'Pacific/Pohnpei', value: "Pohnpei Time" },
	{ key: 'Pacific/Ponape', value: "Pohnpei Time" },
	{ key: 'Pacific/Port_Moresby', value: "Papua New Guinea Time" },
	{ key: 'Pacific/Rarotonga', value: "Cook Is. Time" },
	{ key: 'Pacific/Saipan', value: "Chamorro Standard Time" },
	{ key: 'Pacific/Samoa', value: "Samoa Standard Time" },
	{ key: 'Pacific/Tahiti', value: "Tahiti Time" },
	{ key: 'Pacific/Tarawa', value: "Gilbert Is. Time" },
	{ key: 'Pacific/Tongatapu', value: "Tonga Time" },
	{ key: 'Pacific/Truk', value: "Chuuk Time" },
	{ key: 'Pacific/Wake', value: "Wake Time" },
	{ key: 'Pacific/Wallis', value: "Wallis & Futuna Time" },
	{ key: 'Pacific/Yap', value: "Chuuk Time" },
	{ key: 'Poland', value: "Central European Time" },
	{ key: 'Portugal', value: "Western European Time" },
	{ key: 'ROK', value: "Korea Standard Time" },
	{ key: 'Singapore', value: "Singapore Time" },
	{ key: 'SystemV/AST4', value: "Atlantic Standard Time" },
	{ key: 'SystemV/AST4ADT', value: "Atlantic Standard Time" },
	{ key: 'SystemV/CST6', value: "Central Standard Time" },
	{ key: 'SystemV/CST6CDT', value: "Central Standard Time" },
	{ key: 'SystemV/EST5', value: "Eastern Standard Time" },
	{ key: 'SystemV/EST5EDT', value: "Eastern Standard Time" },
	{ key: 'SystemV/HST10', value: "Hawaii Standard Time" },
	{ key: 'SystemV/MST7', value: "Mountain Standard Time" },
	{ key: 'SystemV/MST7MDT', value: "Mountain Standard Time" },
	{ key: 'SystemV/PST8', value: "Pacific Standard Time" },
	{ key: 'SystemV/PST8PDT', value: "Pacific Standard Time" },
	{ key: 'SystemV/YST9', value: "Alaska Standard Time" },
	{ key: 'SystemV/YST9YDT', value: "Alaska Standard Time" },
	{ key: 'Turkey', value: "Eastern European Time" },
	{ key: 'UCT', value: "Coordinated Universal Time" },
	{ key: 'US/Alaska', value: "Alaska Standard Time" },
	{ key: 'US/Aleutian', value: "Hawaii Standard Time" },
	{ key: 'US/Arizona', value: "Mountain Standard Time" },
	{ key: 'US/Central', value: "Central Standard Time" },
	{ key: 'US/East-Indiana', value: "Eastern Standard Time" },
	{ key: 'US/Eastern', value: "Eastern Standard Time" },
	{ key: 'US/Hawaii', value: "Hawaii Standard Time" },
	{ key: 'US/Indiana-Starke', value: "Central Standard Time" },
	{ key: 'US/Michigan', value: "Eastern Standard Time" },
	{ key: 'US/Mountain', value: "Mountain Standard Time" },
	{ key: 'US/Pacific', value: "Pacific Standard Time" },
	{ key: 'US/Samoa', value: "Samoa Standard Time" },
	{ key: 'UTC', value: "Coordinated Universal Time" },
	{ key: 'Universal', value: "Coordinated Universal Time" },
	{ key: 'W-SU', value: "Moscow Standard Time" },
	{ key: 'WET', value: "Western European Time" },
	{ key: 'Zulu', value: "Coordinated Universal Time" },
	{ key: 'EST', value: "Eastern Standard Time" },
	{ key: 'HST', value: "Hawaii Standard Time" },
	{ key: 'MST', value: "Mountain Standard Time" },
	{ key: 'ACT', value: "Australian Central Standard Time (Northern Territory)" },
	{ key: 'AET', value: "Australian Eastern Standard Time (New South Wales)" },
	{ key: 'AGT', value: "Argentine Time" },
	{ key: 'ART', value: "Eastern European Time" },
	{ key: 'AST', value: "Alaska Standard Time" },
	{ key: 'BET', value: "Brasilia Time" },
	{ key: 'BST', value: "Bangladesh Time" },
	{ key: 'CAT', value: "Central African Time" },
	{ key: 'CNT', value: "Newfoundland Standard Time" },
	{ key: 'CST', value: "Central Standard Time" },
	{ key: 'CTT', value: "China Standard Time" },
	{ key: 'EAT', value: "Eastern African Time" },
	{ key: 'ECT', value: "Central European Time" },
	{ key: 'IET', value: "Eastern Standard Time" },
	{ key: 'IST', value: "India Standard Time" },
	{ key: 'JST', value: "Japan Standard Time" },
	{ key: 'MIT', value: "West Samoa Standard Time" },
	{ key: 'NET', value: "Armenia Time" },
	{ key: 'NST', value: "New Zealand Standard Time" },
	{ key: 'PLT', value: "Pakistan Time" },
	{ key: 'PNT', value: "Mountain Standard Time" },
	{ key: 'PRT', value: "Atlantic Standard Time" },
	{ key: 'PST', value: "Pacific Standard Time" },
	{ key: 'SST', value: "Solomon Is. Time" },
	{ key: 'VST', value: "Indochina Time" },
];

export const TimeZone = new PEnum({ id: 'TimeZone', items: TIMEZONE_ITEMS })