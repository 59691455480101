/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import React, { useContext, useCallback } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ViewerStoreContext } from "./ViewerStore";


export function ReportParts({ onItemClick }: { onItemClick: (page: number) => void }) {
    const viewerStore = useContext(ViewerStoreContext);

    const onTabChange = useCallback(
        (event, tabIndex) => {
            onItemClick(viewerStore.reportParts[tabIndex].page);
        }, [onItemClick, viewerStore.reportParts]);

    const a11yProps = useCallback(
        (index) => {
            return {
                id: `scrollable-auto-tab-${index}`,
                'aria-controls': `scrollable-auto-tabpanel-${index}`
            };
        }, []);

    const getSelectedTab = useCallback(
        () => {
            const parts = viewerStore.reportParts;
            const currentPage = viewerStore.currentPage;
            let found = 0;
            for ( let i = 0 , ln = parts.length; i < ln; i = i + 1 ) {
                if (currentPage >= parts[i].page) {
                    found = i;
                }
            }
            return found;
        }, [viewerStore.currentPage, viewerStore.reportParts]);


    return (
        viewerStore.reportParts && viewerStore.reportParts.length > 0
            ? (
                <Tabs
                    value={getSelectedTab()}
                    onChange={onTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example" >
                    {
                        viewerStore.reportParts.map(
                            (part, index) => {
                                return <Tab key={part.name} label={part.name} { ...a11yProps(index) }/>
                            }
                        )
                    }
                </Tabs>
            )
            : <></>
    );
}