/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IResultDialogProp } from '@jss/js-common';
import { IRepositoryItemDescriptor } from '@jss/js-rest-api';
import { DialogActions, DialogContentText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import * as React from 'react';
import '../../assets/uxpl/css/ResourcePicker.css';
import i18n from '../../i18n';

export interface IOverwriteResourceDialog extends IResultDialogProp {
    open: boolean;
    title?: string;
    fileNameLabel?: string;
    confirmButtonLabel?: string;
}

export interface IOverwriteResourceState {
    folderUUID: string | null;
    file: IRepositoryItemDescriptor | null;
    fileName: string | undefined;
}

export class OverwriteResourceDialog extends React.Component<IOverwriteResourceDialog, IOverwriteResourceState> {
    
    render() {
        return <Dialog
        open={true}
        onClose={this.onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {i18n.t('resource.ovewrite.message"')}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={this.onCancel} size="large" color="secondary" variant="contained">{i18n.t('common.actions.button.cancel')}</Button>
            <Button onClick={this.onConfirm} size="large" color="primary" variant="contained">{i18n.t('common.actions.button.yes')}</Button>
        </DialogActions>
    </Dialog>;
    }

    private onConfirm = () => {
        this.props.onEnd({ canceled: false });
    }
    
    private onCancel = () => {
        this.props.onEnd({ canceled: true });
    }
}