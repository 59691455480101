/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { fromJS, Map, Set } from "immutable";
import { call, delay, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects";

import * as ReportActions from "../../../actions/reportActions";
import { IState } from "../../../reducers";
import { getEditorIndex } from "../../../reducers/report/reportReducer";
import { Direction, IPoint } from "../../../types/geometry";
import { ElementTypes } from "../document/elementTypes";
import * as DocumentUtils from "./documentUtils";

import * as BandResizeHandler from './handlers/bandResizeHandler';
import * as ElementDragHandler from './handlers/elementDragHandler';
import * as ElementHighlighter from './handlers/elementHighlighter';
import * as ElementResizeHandler from './handlers/elementResizeHandler';
import * as PanHandler from './handlers/panHandler';
import * as SelectionDragHandler from './handlers/selectionDragHandler';


/**
 * Manages mouseDown event on the main designer area. Other components may intercept this event.
 * 
 * @param action 
 */
function* mouseDownHandlerSaga(action: any) {

    const { point, options } = action;
    const state = yield select( (s: IState) => s);
    const model = state.getIn(['report', 'model']);
    const selection: Set<string> = state.getIn(['report', 'selection']);

    const ele = DocumentUtils.findElementAt(model, point);
    if (ele) {
        const id = ele.get('id');
        const type = ele.get('type');
        const isSelected = selection.has(id);
        if (!isSelected) {
            // Case 1: the element is not selected, in this case change the selection
            //         and start a drag operation
            yield put(ReportActions.selectElement(id, { path: [type === ElementTypes.BAND ? 'bands' : 'elements', id], type }, options.shiftKey === true));
            yield put(ReportActions.setDragOperation(ReportActions.DragOperations.DRAG_OP_ELEMENT, fromJS(point), Map({})));

        } else if (options.shiftKey === true) {
            // Case 2: The element was already selected, so we need to invert the selection
            //         which is the behavior of selecting an element twice by holding the SHIFT key
            //         Note that i this case no drag operation is started.
            yield put(ReportActions.deselectElement(id));
        } else {
            // Start drag operation keeping current selection
            yield put(ReportActions.setDragOperation(ReportActions.DragOperations.DRAG_OP_ELEMENT, fromJS(point), Map({})));
        }

    } else {
        if (options.shiftKey === true || options.touch) {
            yield put(ReportActions.setDragOperation(ReportActions.DragOperations.DRAG_OP_PAN, fromJS(point)));

            if (options.touch) {
                // In case of touch, if there is no movement without 300ms, the drop operation will be changed in "DRAG SELECTION" from "DRAG PAN"
                const { timeout } = yield race({
                    timeout: call(delay, 300),
                    cancel: take([ReportActions.Actions.DESIGNER_MOUSE_UP_ACTION, ReportActions.Actions.DESIGNER_MOUSE_MOVE_ACTION])
                });

                if (timeout) {
                    yield put(ReportActions.setDragOperation(ReportActions.DragOperations.DRAG_OP_SELECTION, fromJS(point)));
                }

            }
        }
        else {
            yield put(ReportActions.setDragOperation(ReportActions.DragOperations.DRAG_OP_SELECTION, fromJS(point)));
        }
    }
}



/**
 * Saga to handle mouse move.
 * @param action 
 */
function* mouseMoveHandlerSaga(action: any) {

    const { point, options, scrollDirection } = action;

    const op = yield select((state: IState) => { 
        const currentEditorIndex = getEditorIndex(state.get('report'));
        if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
            const currentEditor = state.getIn(['report', 'subeditors', currentEditorIndex]) as Map<string, any>;
            return currentEditor.getIn(['mouse', 'operation'], false);
        }
        return state.getIn(['report', 'mouse', 'operation'], false);
    });
    if (!op) {
        yield call(ElementHighlighter.handleElementHighlight, point);
    } else {
        // Update the state based on the dragging operation we are working with...
        switch (op) {
            case ReportActions.DragOperations.DRAG_OP_ELEMENT:
                yield call(ElementDragHandler.handleDrag, point, options, false);
                break;
            case ReportActions.DragOperations.DRAG_OP_SELECTION:
                yield call(SelectionDragHandler.handleSelectionDrag, point, options, false);
                break;
            case ReportActions.DragOperations.DRAG_OP_PAN:
                yield call(PanHandler.handlePan, point, options, false);
                break;
            case ReportActions.DragOperations.DRAG_OP_ELEMENT_RESIZE:
                yield call(ElementResizeHandler.handleDrag, point, options, false);
                break;
            case ReportActions.DragOperations.DRAG_OP_BAND_RESIZE:
                yield call(BandResizeHandler.handleDrag, point, options, false);
                break;
            default:
            // Nothing to do...
        }

        yield call(DocumentUtils.updateClientArea);

        if (scrollDirection) {
            yield call(handlePanWhileDragging, point, options, scrollDirection);
        }
    }
}



/**
 * This function ensure that the visible area moves properly in the direction of scrollDirection during a dragging operation.
 * It comes to play only then the touch/mouse is enough close to a visible area border or outside of it.
 * 
 * @param point 
 * @param options 
 * @param scrollDirection 
 */
function* handlePanWhileDragging(point: IPoint, options: any, scrollDirection: Direction) {
    const movementSize = 10; // "re-pan speed";
    const nextLocation = { ...point };

    if (scrollDirection === Direction.LEFT ||
        scrollDirection === Direction.TOP_LEFT ||
        scrollDirection === Direction.BOTTOM_LEFT) {
        yield put(ReportActions.scrollX(-movementSize / 2));
    } else if (scrollDirection === Direction.RIGHT ||
        scrollDirection === Direction.TOP_RIGHT ||
        scrollDirection === Direction.BOTTOM_RIGHT) {
            yield put(ReportActions.scrollX( movementSize / 2));
    }

    if (scrollDirection === Direction.TOP ||
        scrollDirection === Direction.TOP_RIGHT ||
        scrollDirection === Direction.TOP_LEFT) {
            yield put(ReportActions.scrollY(- movementSize / 2));
    } else if (scrollDirection === Direction.BOTTOM ||
        scrollDirection === Direction.BOTTOM_RIGHT ||
        scrollDirection === Direction.BOTTOM_LEFT) {
            yield put(ReportActions.scrollY(movementSize / 2));

    }

        // Let's start a timer. If the timeout expires before getting a mouseUp or a mouseMove event, the
    // mouse will simulate a move.
    const { timeout } = yield race({
        timeout: delay(25),
        cancel: take([ReportActions.Actions.DESIGNER_MOUSE_UP_ACTION, ReportActions.Actions.DESIGNER_MOUSE_MOVE_ACTION])
    });

    if (timeout) {
        yield put(ReportActions.mouseMove(nextLocation, options, scrollDirection));
    }
}

function* mouseUpHandlerSaga(action: any) {

    const { point, options } = action;
    const op = yield select((state: IState) => {
        const currentEditorIndex = getEditorIndex(state.get('report'));
        if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
            const currentEditor = state.getIn(['report', 'subeditors', currentEditorIndex]) as Map<string, any>;
            return currentEditor.getIn(['mouse', 'operation'], false);
        }
        return state.getIn(['report', 'mouse', 'operation'], false);
    });
    
    if (op) {
        // Commit pending operation....
        switch (op) {
            case ReportActions.DragOperations.DRAG_OP_ELEMENT:
                yield call(ElementDragHandler.handleDrag, point, options, true);
                break;
            case ReportActions.DragOperations.DRAG_OP_SELECTION:
                yield call(SelectionDragHandler.handleSelectionDrag, point, options, true);
                break;
            case ReportActions.DragOperations.DRAG_OP_ELEMENT_RESIZE:
                yield call(ElementResizeHandler.handleDrag, point, options, true);
                break;
            case ReportActions.DragOperations.DRAG_OP_BAND_RESIZE:
                yield call(BandResizeHandler.handleDrag, point, options, true);
                break;
            default:
            // no handler...
        }
        yield put(ReportActions.clearDragOperation());
    }

    // Remove temporary interactive elements that previous actions may have created...
    const transientElementIds = yield select((state: IState) => {
        const currentEditorIndex = getEditorIndex(state.get('report'));
        let interactionElements;
        if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
            const currentEditor = state.getIn(['report', 'subeditors', currentEditorIndex]) as Map<string, any>;
            interactionElements = currentEditor.get('interactionElements');
        } else {
            interactionElements = state.getIn(['report', 'interactionElements']);
        }
        return interactionElements.reduce((arr: string[], ele: Map<string, any>) => {
            if (ele.get('transient')) {
                arr.push(ele.get('id'));
            }
            return arr;
        }, [])
    });

    if (transientElementIds.length > 0) {
        yield put(ReportActions.removeInteractionFigures(transientElementIds));
    }
}


/**
 * Binding of event type to the proper flow control for saga.
 */
export const mouseHandlerSagas = [
    takeEvery(ReportActions.Actions.DESIGNER_MOUSE_DOWN_ACTION, mouseDownHandlerSaga),
    takeLatest(ReportActions.Actions.DESIGNER_MOUSE_MOVE_ACTION, mouseMoveHandlerSaga),
    takeEvery(ReportActions.Actions.DESIGNER_MOUSE_UP_ACTION, mouseUpHandlerSaga),
    takeEvery(ReportActions.Actions.DESIGNER_BAND_RESIZE_INIT_ACTION, BandResizeHandler.initBandResizeSaga)
]



