/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Button, CommandRegistry, IconButton, IEditor, IMenuItem, IResourceInfo, IResourceListener, IResourceListenerManager, IToolBarItemOptions, IToolbarProvider, ToolBar, ToolButton } from '@jss/js-common';
import { ResourceInfoDialog, ResourcePickerDialog } from '@jss/js-repository';
import { IRepositoryItemDescriptor, isReadOnly, RepositoryApi, RESOURCE_TYPE } from '@jss/js-rest-api';
import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import PublishIcon from '@material-ui/icons/Publish';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ace from 'ace-builds';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { List as ImmutableList } from 'immutable';
import { Base64 } from 'js-base64';
import * as React from 'react';
import AceEditor, { IAceEditorProps } from 'react-ace';
import ReactResizeDetector from 'react-resize-detector';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../i18n';
import { XMLValidator } from 'fast-xml-parser';

import '../assets/uxpl/css/TextualEditor.css';

export interface IAbstractTextualEditor {
    resourceListenerManagers: IResourceListenerManager[],
    commandRegistry: CommandRegistry,
    acceptedFileTypes?: string,
    defaultExtension?: string,
    language?: string,
    getEditorContent?: () => string,
    setEditorContent?: (value: string) => void;
    getUndoHistory?: () => ImmutableList<string>,
    getRedoHistory?: () => ImmutableList<string>,
    setHistory?: (history: ImmutableList<string>, redoHistory: ImmutableList<string>) => void;
    onExit?: () => void;
}

interface IAbstralTextualEditorState {
    openDescriptor: IRepositoryItemDescriptor | undefined,
    editorContent: string,
    width: number,
    height: number,
    isDirty: boolean,
    history: ImmutableList<string>,
    redoHistory: ImmutableList<string>,
    toolbarData: IToolbarProvider | undefined,
    errorMessage: string | null,
    successMessage: string | null,
    pageLoading: boolean,
    path?: IRepositoryItemDescriptor[],
    defaultFileName: string,
}

export const EXIT_TEXT_EDITOR = 'EXIT_TEXT_EDITOR';

export const TEXT_SAVED = 'TEXT_SAVED';

export const SAVE_TEXT = 'SAVE_TEXT';

export const TEXT_MODIFIED = 'TEXT_MODIFIED';

export const TEXT_EDITOR_DIRTY = 'TEXT_EDITOR_DIRTY';

export const TEXT_SAVED_AS = 'TEXT_SAVED_AS';

export const UPDATED_DESCRIPTOR = 'UPDATED_DESCRIPTOR';

function buildFileSelector(handleFileChange: (e) => void, acceptedFileTypes: string) {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', acceptedFileTypes);
    fileSelector.onchange = handleFileChange;
    fileSelector.onclick = function () {
        (this as any).value = null;
    };
    return fileSelector;
}

export abstract class AbstractTextualEditor extends React.Component<IAbstractTextualEditor, IAbstralTextualEditorState> implements IEditor {

    public state: IAbstralTextualEditorState = {
        openDescriptor: undefined,
        editorContent: '',
        width: 0,
        height: 0,
        isDirty: false,
        history: ImmutableList<string>(),
        redoHistory: ImmutableList<string>(),
        toolbarData: undefined,
        errorMessage: null,
        successMessage: null,
        pageLoading: false,
        defaultFileName: 'New Text File',
        path: undefined,
    }

    private resourceListenerId = '';

    private timer: NodeJS.Timeout | null = null;

    protected editor = React.createRef<AceEditor>();

    protected fileSelector: HTMLInputElement;

    constructor(props: IAbstractTextualEditor) {
        super(props);
    }

    public componentDidMount = () => {
        this.fileSelector = buildFileSelector(this.onFileSelected, this.getAcceptedFileTypes());
        this.resourceListenerId = uuidv4();
        const resourceListener: IResourceListener = {
            onResourceOpen: (resource: IResourceInfo) => {
                if (resource.resource) {
                    this.clearTimer();
                    if (!resource.content) {
                        //it has a descriptor but not content, consider it a new da
                        this.setState({
                            editorContent: '',
                            openDescriptor: resource.resource,
                            path: resource.path,
                            history: ImmutableList<string>(),
                            redoHistory: ImmutableList<string>(),
                            defaultFileName: resource.resource.name,
                        }, () => {
                            if (this.props.setEditorContent){
                                this.props.setEditorContent('')
                            }
                            if (this.props.setHistory){
                                this.props.setHistory(ImmutableList<string>(), ImmutableList<string>());
                            }
                        });
                    } else {
                        let isXmlValid = true;
                        if (resource.content) {
                            isXmlValid = XMLValidator.validate(resource.content) === true;
                        }
                        const textValue = isXmlValid ? this.formatContent(resource.content) : resource.content;
                        const newUndo = resource.options?.keepHistory ? this.getUndoHistory() : ImmutableList<string>();
                        const newRedo = resource.options?.keepHistory ? this.getRedoHistory() : ImmutableList<string>();
                        this.setState({
                            editorContent: textValue,
                            openDescriptor: resource.resource,
                            path: resource.path,
                            isDirty: resource.isResourceDirty !== undefined ? resource.isResourceDirty : false,
                            defaultFileName: resource.resource.name,
                            history: newUndo,
                            redoHistory: newRedo,
                        }, () => {
                            if (this.props.setEditorContent){
                                this.props.setEditorContent(textValue)
                            }
                            if (this.props.setHistory){
                                this.props.setHistory(newUndo, newRedo);
                            }
                        });
                    }
                }
            }
        }
        this.props.resourceListenerManagers.forEach(manager => {
            manager.addResourceListener(this.resourceListenerId, resourceListener);
        });
        this.props.commandRegistry.registerHandler(EXIT_TEXT_EDITOR, {
            execute: () => {
                if (this.props.commandRegistry) {
                    this.props.commandRegistry.executeCommand(EXIT_TEXT_EDITOR);
                }
            },
            isEnabled: () => {
                return true;
            }
        });
        this.props.commandRegistry.registerHandler(SAVE_TEXT, {
            execute: (params: Record<string, unknown>, callback?: () => void) => {
                this.doSave(false, callback);
            },
            isEnabled: () => {
                return true;
            }
        });

        this.provideToolbar();
    }

    componentWillUnmount() {
        this.props.resourceListenerManagers.forEach(manager => {
            manager.removeResourceListener(this.resourceListenerId);
        });
    }

    componentDidUpdate = (previousProps: IAbstractTextualEditor, previousState: IAbstralTextualEditorState) => {
        const previousUndoHistorySize = previousProps.getUndoHistory ? previousProps.getUndoHistory().size : previousState.history.size;
        const currentUndoHisotrySize = this.getUndoHistory().size;
        const previousRedoHistorySize = previousProps.getRedoHistory ? previousProps.getRedoHistory().size : previousState.redoHistory.size;
        const currentRedoHisotrySize = this.getRedoHistory().size;
        if (previousState.openDescriptor !== this.state.openDescriptor || previousState.isDirty !== this.state.isDirty ||
            previousRedoHistorySize !== currentRedoHisotrySize || previousUndoHistorySize !== currentUndoHisotrySize ||
            this.props.language !== previousProps.language) {
            this.provideToolbar();
            this.props.commandRegistry.executeCommand(TEXT_EDITOR_DIRTY, { isDirty: this.state.isDirty });
        }
        if (this.editor.current !== null) {
            const currentAceEditor = this.editor.current.editor;
            if (this.hasFomatter()) {
                const doFormatCommand = () => {
                    if (this.hasFomatter()) {
                        this.doFormat();
                    }
                }
                currentAceEditor.commands.addCommand({
                    name: "Format Text",
                    bindKey: { win: "Ctrl-Alt-l", mac: "Command-Alt-l" },
                    exec: doFormatCommand,
                });
            }

            currentAceEditor.commands.addCommand({
                name: "showKeyboardShortcuts",
                bindKey: { win: "Ctrl-Alt-h", mac: "Command-Alt-h" },
                exec: function (editor) {
                    ace.config.loadModule("ace/ext/keybinding_menu", function (module) {
                        module.init(editor);
                        currentAceEditor.showKeyboardShortcuts()
                    })
                }
            })
        }
    }

    getEditorContent = () => {
        if (this.props.getEditorContent){
            return this.props.getEditorContent();
        }
        return this.state.editorContent;
    }

    getUndoHistory = () => {
        if (this.props.getUndoHistory) {
            return this.props.getUndoHistory();
        }
        return this.state.history;
    }

    getRedoHistory = () => {
        if (this.props.getRedoHistory) {
            return this.props.getRedoHistory();
        }
        return this.state.redoHistory;
    }

    undo = () => {
        const previousState = this.getUndoHistory().last<string>();
        const newRedo = this.getRedoHistory().push(this.getEditorContent());
        const newUndo = this.getUndoHistory().pop();
        if (this.props.setEditorContent){
            this.props.setEditorContent(previousState);
            if (this.props.setHistory){
                this.props.setHistory(newUndo, newRedo);
            }
        } else {
            this.setState({ history: newUndo, redoHistory: newRedo, editorContent: previousState });
        }
    }

    redo = () => {
        const nextState = this.getRedoHistory().last<string>();
        const newUndo = this.getUndoHistory().push(this.getEditorContent());
        const newRedo = this.getRedoHistory().pop();
        if (this.props.setEditorContent){
            this.props.setEditorContent(nextState);
            if (this.props.setHistory){
                this.props.setHistory(newUndo, newRedo);
            }
        } else {
            this.setState({ history: newUndo, redoHistory: newRedo, editorContent: nextState });
        }
    }

    canUndo = (): boolean => {
        return this.getUndoHistory().size > 0;
    }

    canRedo = (): boolean => {
        return this.getRedoHistory().size > 0;
    }

    provideToolbar = () => {
        this.setState({ toolbarData: this.getInternalToolbar() });
        //this.props.commandRegistry.registerToolbar(this.getToolbar());
    }

    getToolbar = (): IToolbarProvider => {
        return {
            getMenus: () => {
                return [];
            },
            getToolItems: () => {
                return [];
            }
        }
    }

    handleUploadPress = (e) => {
        e.preventDefault();
        this.fileSelector.click();
    }

    private showPropertisDialog = () => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.context.showDialog(ResourceInfoDialog, { descriptor: this.state.openDescriptor, onViewClose: () => { }, onChanged: this.handleItemPropertiesChanged });
    }

    getInternalToolbar = (): IToolbarProvider => {
        const isRO = !this.state.openDescriptor || isReadOnly(this.state.openDescriptor);
        const isSaveDisabled = !this.state.isDirty || isRO;
        const getSaveSubmenu: IMenuItem[] = [
            {
                id: 'saveAction',
                label: i18n.t('common.actions.menu.save'),
                isEnabled: !isSaveDisabled,
                icon: <IconButton className='jr-mMenuDecorated jr-mMenu-list-item-icon jr-MuiListItemIcon-root jr-mIcon mui' style={{minWidth: 0, backgroundColor: 'transparent'}} icon={'save'} size="small" disabled={isSaveDisabled} />,
                onClick: () => {
                    if (this.state.openDescriptor.uuid) {
                        this.doSave(false);
                    } else {
                        //if the file is new force a new file to let the user choose the name
                        this.doSave(true);
                    }
                }
            },
            {
                id: 'saveAsAction',
                label: i18n.t('common.actions.menu.saveas'),
                icon: <SaveAltIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    this.doSave(true);
                }
            },
            {
                id: 'propertiesAction',
                label: i18n.t('common.actions.menu.resourceinfo'),
                icon: <InfoIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    this.showPropertisDialog();
                },
                isEnabled: this.state.openDescriptor !== undefined && this.state.openDescriptor.uuid !== undefined,
            },
            {
                label: i18n.t('common.actions.menu.upload'),
                id: 'importAction',
                getComponent: (handleClose: () => void) => {
                    return (
                        <MenuItem key={'importAction'} selected={false} className={"jr-mMenu-list-item mui"} style={{opacity: isRO ? 0.5 : undefined}}>
                            <div style={{ display: 'flex' }} onClick={(event) => { if (!isRO) {
                                handleClose(); this.handleUploadPress(event) 
                                }}}>
                                <ListItemIcon className={"jr-mMenu-list-item-icon mui"}>
                                    <PublishIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />
                                </ListItemIcon>
                                <ListItemText primary={i18n.t('common.actions.menu.upload')} classes={{ primary: "jr-mText mui" }} />
                            </div>
                        </MenuItem>)
                }
            },
            {
                label: i18n.t('common.actions.menu.download'),
                icon: <GetAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                id: 'exportAction',
                onClick: () => {
                    const blob = new Blob([this.getEditorContent()], { type: "text/xml;charset=utf-8" });
                    const extension = !this.state.openDescriptor.name.endsWith(this.getDefaultExtension()) ? `.${this.getDefaultExtension()}` : '';
                    saveAs(blob, this.state.openDescriptor?.name + extension);
                }
            },
            {
                label: i18n.t('common.actions.menu.exit'),
                id: 'exitAction',
                icon: <ExitToAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />,
                onClick: () => {
                    if (this.props.commandRegistry) {
                        this.props.commandRegistry.executeCommand(EXIT_TEXT_EDITOR);
                    }
                }
            },
        ];
        return {
            getToolItems: () => {
                const items = [
                    {
                        id: 'saveToolaction',
                        render: (options?: IToolBarItemOptions) => {
                            //disable the save when the report is not dirty or we don't have write permission
                            const height = options && options.height ? options.height : 24;
                            const backgroundColor = options && options.background ? options.background : undefined;
                            return <ToolButton label={i18n.t('common.actions.menu.save')} isDisabled={isSaveDisabled} onClick={() => {
                                if (this.state.openDescriptor.uuid) {
                                    this.doSave(false);
                                } else {
                                    //if the file is new force a new file to let the user choose the name
                                    this.doSave(true);
                                }
                            }} height={height} backgroundColor={backgroundColor} content={getSaveSubmenu}>
                                <IconButton style={{ backgroundColor: 'transparent' }} icon={'save'} size="small" disabled={isSaveDisabled} />
                            </ToolButton>
                        }
                    },
                    {
                        id: 'undoToolaction',
                        render: (options?: IToolBarItemOptions | undefined) => {
                            const isUndoDisabled = !this.canUndo();
                            const height = options && options.height ? options.height : 24;
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                                <div title={i18n.t('common.actions.menu.undo')} style={{ height: height, cursor: isUndoDisabled ? 'default' : 'pointer', justifyContent: 'center', alignItems: 'center', marginRight: 15 }} onClick={!isUndoDisabled ? this.undo : undefined}>
                                    <IconButton style={{ backgroundColor: 'transparent' }} icon={'undo'} size="small" disabled={isUndoDisabled} />
                                </div>
                            </div>
                        }
                    },
                    {
                        id: 'redoToolaction',
                        render: (options?: IToolBarItemOptions) => {
                            const height = options && options.height ? options.height : 24;
                            const isRedoDisabled = !this.canRedo();
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div title={i18n.t('common.actions.menu.redo')} style={{ height: height, cursor: isRedoDisabled ? 'default' : 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={!isRedoDisabled ? this.redo : undefined}>
                                    <IconButton icon={'redo'} size="small" disabled={isRedoDisabled} />
                                </div>
                                <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                            </div>;
                        }
                    },

                ];
                if (this.hasFomatter()) {
                    items.push(
                        {
                            id: 'formatToolaction',
                            render: (options?: IToolBarItemOptions) => {
                                const height = options && options.height ? options.height : 24;
                                return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <div title={i18n.t('text.editor.format.tooltip')} style={{ height: height, cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={this.doFormat}>
                                        <IconButton icon={'textACenter'} size="small" />
                                    </div>
                                </div>;
                            }
                        }
                    );
                }
                if (this.editor.current !== null) {
                    const currentAceEditor = this.editor.current.editor;
                    items.push(
                        {
                            id: 'helpToolAction',
                            render: (options?: IToolBarItemOptions) => {
                                const height = options && options.height ? options.height : 24;
                                return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                                    <div title={i18n.t('common.actions.menu.help')} style={{ height: height, cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                                        currentAceEditor.execCommand("showKeyboardShortcuts");
                                    }}>
                                        <HelpOutlineIcon className='jr-iconColor' />
                                    </div>
                                </div>;
                            }
                        }
                    );
                }
                return items;
            },
        }
    }

    protected doFormat = () => {
        const formattedText = this.formatContent(this.getEditorContent());
        if (this.props.setEditorContent){
            this.props.setEditorContent(formattedText);
        } else {
            this.setState({ editorContent: formattedText });
        }
    }

    private onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            const content = fileReader.result as string;
            // TODO - perform some kind of validation of the loaded content
            // or be sure that a sort of validation is triggered when editorContent is updated
            this.clearTimer();
            const newUndo = this.getUndoHistory().push(this.getEditorContent());
            const newRedo = ImmutableList<string>();
            if (this.props.setEditorContent){
                this.props.setEditorContent(content);
                if (this.props.setHistory){
                    this.props.setHistory(newUndo, newRedo);
                }
            } else {
                this.setState({ editorContent: content, history: newUndo, redoHistory: newRedo, isDirty: true });
            }
        };
        fileReader.readAsText(event.currentTarget.files[0]);
    }

    public openSaveDialog() {
        const defaultPath: IRepositoryItemDescriptor[] = this.state.path;
        this.context.showDialog(ResourcePickerDialog, {
            mode: 'save',
            title: i18n.t('text.editor.savedialogtitle'),
            onViewClose: () => {
                //empty
            },
            onFileSelected: this.confirmSave,
            defaultFileName: this.state.defaultFileName,
            defaultPath: defaultPath,
        });
    }

    protected confirmSave = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null, fileName: string) => {
        const xmlDaContent = this.getEditorContent();
        const base64data = Base64.encode(xmlDaContent);
        let root = false;
        const newDescriptor: IRepositoryItemDescriptor = {
            type: RESOURCE_TYPE.FILE,
            name: fileName,
            path: RepositoryApi.inst().getParentPath(folderPath.filter(k => {
                root = root || file === null || k.type !== RESOURCE_TYPE.CONTAINER;
                return file == null || (root && k.type === RESOURCE_TYPE.CONTAINER)
            })),
            data: base64data,
            mime: this.state.openDescriptor.mime,
        }
        if (file !== null) {
            //the save as is overwriting a file
            newDescriptor.path = file.path;
            newDescriptor.uuid = file.uuid;
        }

        this.context.runNoCancel(RepositoryApi.inst().save)(file !== null ? 'uuid:' + file.uuid : '', newDescriptor).then((response: AxiosResponse) => {
            if (response.data) {
                this.setState({ openDescriptor: response.data, isDirty: false }, () => {
                    this.setState({ openDescriptor: response.data });
                    this.props.commandRegistry.executeCommand(TEXT_SAVED_AS, { descriptor: response.data, data: xmlDaContent });
                });
            }
        });
    }

    protected handleItemPropertiesChanged = (oldDescriptor: IRepositoryItemDescriptor, newDescriptor: IRepositoryItemDescriptor | null) => {
        this.setState({
            openDescriptor: newDescriptor,
        }, () => {
            this.props.commandRegistry.executeCommand(UPDATED_DESCRIPTOR, { descriptor: newDescriptor });
        });
    }

    protected doSave = (forceNewFile = false, callback?: () => void) => {
        const xmlDaContent = this.getEditorContent();
        if (this.state.openDescriptor && !forceNewFile) {
            //it is a save of an existing report
            const base64data = Base64.encode(xmlDaContent);
            const descriptor: IRepositoryItemDescriptor = this.state.openDescriptor;
            const newDescriptor: IRepositoryItemDescriptor = {
                ...(descriptor as any),
                data: base64data,
            }

            this.context.runNoCancel(RepositoryApi.inst().save)(descriptor.uuid ? 'uuid:' + descriptor.uuid : (descriptor.path ? descriptor.path : '/'), newDescriptor, newDescriptor).then((response: AxiosResponse) => {
                if (response.data) {
                    //need to update the descriptor also in simple save because it could contain the version
                    this.setState({ openDescriptor: response.data, isDirty: false }, () => {
                        this.props.commandRegistry.executeCommand(TEXT_SAVED, { descriptor: response.data, data: xmlDaContent });
                        if (callback) {
                            callback();
                        }
                    });
                }
            });
        } else {
            //it is a new file
            this.openSaveDialog();
        }
    }

    private storeStateToHistory = (oldContent) => {
        const newUndo = this.getUndoHistory().push(oldContent)
        this.setState({ history: newUndo }, () => {
            this.timer = null;
            if (this.props.setHistory){
                this.props.setHistory(newUndo, this.getRedoHistory());
            }
        });
    }

    private startTimer = (oldContent) => {
        this.timer = setTimeout(() => this.storeStateToHistory(oldContent), 500);
    }

    private clearTimer = () => {
        if (this.timer !== null) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    private onEditorChange = (newContent: string) => {
        const oldContent = this.getEditorContent();
        const newRedo =  this.getRedoHistory().size > 0 ? ImmutableList<string>() : this.getRedoHistory();
        this.setState({
            editorContent: newContent,
            redoHistory: newRedo,
            isDirty: true,
            //the typing always delete the redo history
        }, () => {
            if (this.props.setEditorContent){
                this.props.setEditorContent(newContent);
                this.props.setHistory(this.getUndoHistory(), newRedo);
            }
            if (this.timer === null) {
                this.startTimer(oldContent);
            }
            if (this.props.commandRegistry) {
                this.props.commandRegistry.executeCommand(TEXT_MODIFIED, this.state.openDescriptor, newContent);
            }
        });
    }

    private onResize = (width: number | undefined, height: number | undefined) => {
        if (width !== this.state.width || height !== this.state.height) {
            this.setState({ width: width ? width : 0, height: height ? height : 0 });
        }
    }

    public abstract getTextEditor(editorProps: IAceEditorProps);

    public abstract getDefaultExtension();

    public formatContent(content) {
        return content;
    }

    public hasFomatter() {
        return true;
    }

    protected clearSuccessMessage = () => {
        this.setState({ successMessage: null });
    }

    protected clearErrors = () => {
        this.setState({ errorMessage: null });
    }

    private getSuccessMessageDialog = () => {
        return <Dialog
            open={this.state.successMessage !== null}
            title={'Success'}
            aria-labelledby="simple-dialog-title"
            fullWidth={true}
            maxWidth={'xs'}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    this.clearSuccessMessage();
                }
            }}
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.state.successMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button size="large" color="primary" variant="contained" className="tc-jsw-margin-right" onClick={this.clearSuccessMessage}>{i18n.t('text.editor.test.close')}</Button>
            </DialogActions>
        </Dialog>
    }

    protected getErrorDialog = () => {
        return <Dialog
            open={this.state.errorMessage !== null}
            aria-labelledby="simple-dialog-title"
            fullWidth={true}
            maxWidth={'xs'}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    this.clearErrors();
                }
            }}
            aria-describedby="alert-dialog-description"
            title={i18n.t('text.editor.test.error')}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.state.errorMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button size="large" color="secondary" variant="contained" className="tc-jsw-margin-right tc-buttons-borderless" onClick={this.clearErrors}>{i18n.t('text.editor.test.cancel')}</Button>
            </DialogActions>
        </Dialog>
    }

    /**
     * Returns the string representing the suitable resources for
     * the attribute "accept" of an input type file.
     *
     * Example: ".jrxml" or "text/plain,.txt"
     */
    public abstract getAcceptedFileTypes(): string;

    public render() {
        if (this.state.openDescriptor) {
            const editorProps: IAceEditorProps = {
                width: `${this.state.width}px`,
                height: `${this.state.height}px`,
                onChange: this.onEditorChange,
                name: this.state.openDescriptor.uuid,
                editorProps: { $blockScrolling: true },
                value: this.getEditorContent(),
            };
            return (
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                        <ToolBar toolbarData={this.state.toolbarData} />
                    </div>
                    <ReactResizeDetector handleWidth={true} handleHeight={true} onResize={this.onResize}>
                        <div style={{ flex: 1, display: 'flex', position: 'relative' }} contentEditable={true} suppressContentEditableWarning={true}>
                            <div style={{ display: 'flex', position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
                                {this.getTextEditor(editorProps)}
                            </div>
                        </div>
                    </ReactResizeDetector>
                    {this.getErrorDialog()}
                    {this.getSuccessMessageDialog()}
                </div>
            );
        } else {
            return null;
        }
    }
}
