/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const BaselinePositionEnum = new PEnum({
    id: 'baselinePosition', label: 'BaselinePosition.label', isEmptyUndefined: true,
    items: [
        { key: '', value: '' },
        { key: 'Top', value: 'BaselinePosition.auto' },
        { key: 'Bottom', value: 'BaselinePosition.ignore' }
    ]
});

