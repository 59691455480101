/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { MBarbecue } from "./MBarbecue";

export const M3of9: APDescriptor = {
    ...MBarbecue, id: '3of9', label: '3of9', icon: ICONS.BARCODE_CODABAR_ICON,
    default: { ...MBarbecue.default, barbecue_type: '3of9' }
};
