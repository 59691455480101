/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const RunDirectionEnum = new PEnum({
    id: 'runDirection', label: 'RunDirection.label',
    items: [
        { key: 'LTR', value: 'RunDirection.LTR'},
        { key: 'RTL', value: 'RunDirection.RTL'}
    ]
}); 
 
