/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { Pref} from "./Conf";

export class Lang {

    public static init(cfg: any) {
        cfg[Pref.LOCALES_AVAILABLE] = Lang.norm(["en", "fr", "it"]);
        cfg[Pref.LOCALES] = Lang.norm(window.navigator.languages as string[]);
        cfg[Pref.LOCALE] = Lang.getLocale(window.navigator.language, cfg[Pref.LOCALES_AVAILABLE]);
    }

    public static norm(l: string[]): string[] {
        return l.map(key => this.normLocale(key));
    }
    public static normLocale(l: string): string {
        return l.replace(/_/g, '-');
    }

    public static getLocale(loc: string, locAvailable: string[]) {
        loc = Lang.normLocale(loc).toLowerCase();
        if (!locAvailable.includes(loc)) {
            const m = loc.split('-')[0].toLowerCase();
            let newlang = locAvailable.find(key => key.toLowerCase() === m);
            if (!newlang) {
                newlang = locAvailable.find(key => key.toLowerCase().startsWith(m));
            }
            if (!newlang) {
                newlang = 'en';
            }
            return newlang;
        }
        return loc;
    }
}