/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StackedBar3DChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="802"
      height="493"
      version="1.1"
      style={{
        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        userSelect: "none",
        position: "relative",
      }}
      cursor="default"
      overflow="hidden"
      viewBox="0 0 802 493"
      {...props}
    >
      <defs>
        <linearGradient
          id="19-329.0909881288068-rgba_187_192_181_1_-rgba_236_240_232_1_"
          x1="0"
          x2="1"
          y1="0"
          y2="0.599"
        >
          <stop offset="0%" stopColor="#bbc0b5"></stop>
          <stop offset="100%" stopColor="#ecf0e8"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgb_209.35_217.6_198.9_-rgb_111.8_121.55_99.45_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#d1d9c6"></stop>
          <stop offset="100%" stopColor="#6f7963"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgb_129_140.25_114.75_-rgb_60.199999999999996_65.45_53.55_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#818c72"></stop>
          <stop offset="100%" stopColor="#3c4135"></stop>
        </linearGradient>
        <linearGradient
          id="19-65.55604521958347-rgb_134.16_145.86_119.34_-rgb_236.74_240.04_232.56_"
          x1="0"
          x2="0.455"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#869177"></stop>
          <stop offset="100%" stopColor="#ecf0e8"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#d3e9fb"></stop>
          <stop offset="100%" stopColor="#718ca1"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#83a2ba"></stop>
          <stop offset="100%" stopColor="#3d4b56"></stop>
        </linearGradient>
        <filter
          id="4503FE3D-6CB2-4251-8D17-1085B38C50DA"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="0F1882A2-A376-475B-9ABA-3BBDD90D0CE1"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#dcdcdc" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#7c7c7c" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#909090" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#434343" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="19-51.811561333185864-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.787"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="E8F8A4BD-2594-452F-808E-C0D5CA7B0BDE"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="294ACD2C-7B6B-445C-8189-A372C59EAC07"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-47.449579388784194-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.918"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="92C9C08A-FE05-49DA-9403-D298EF64AB07"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="57D9D883-159B-4318-BF69-008B65B0EBDB"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-45.94576799210946-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.968"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="7C6316FA-54BE-4E6D-8D24-B495FAE54AA8"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="7FE3FF70-6505-4566-AAD5-55F311DD2035"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-55.18837588585133-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.695"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="0CF94F4C-79EC-4D1B-999A-7AE8C2631242"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="3757BC7F-9E1C-4F0F-883E-945B17E2DA85"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-55.2125693595277-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.695"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="408B53EA-2A32-4310-9A76-B01766286C7D"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="41FAEB30-723B-41B3-A09B-3A9E70716917"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-48.44768819065952-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.886"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="2E3E88A2-4287-4ECE-83AB-F9A9DA3236C6"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="C9463F8A-2C21-43D1-B504-B2889663CFE6"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-45.777240413255576-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.973"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#fada7b"></stop>
          <stop offset="100%" stopColor="#9f7a09"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#b88d0b"></stop>
          <stop offset="100%" stopColor="#564205"></stop>
        </linearGradient>
        <filter
          id="F0812373-1BCC-41B0-8311-A45C49846580"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="C1AC5784-0A6B-46D9-8C14-D3731CA24065"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-57.04646316278437-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.648"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="D6FBF0CF-83C0-4452-93E3-A3FF8E67323F"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="FDBA4124-33D8-44F5-811B-82E7CEADE37D"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-49.69053211655048-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.848"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="719DC6E5-2436-47BF-A87E-C6FA8BE42B46"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="022827C7-7180-4BC8-979B-72FD31DF4524"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-46.86033213287644-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.937"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="4B74DA09-9AC2-4042-B470-D571426DF935"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="6E579D0B-FD81-4ABE-B3B1-EF015170DF3F"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-61.94498113388585-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.533"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="1647DD6E-B0C7-4996-A116-6395210CCC3E"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="F21A485D-01C3-44E9-9812-F059BF9B8B88"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-61.97780732073063-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.532"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="C603ED7A-FF93-4F78-BBA6-4B596BF9D27C"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="4879A0F6-F18D-47E7-9BA6-30DBC8FCDB18"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-51.483604912814734-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.796"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="8CB1929B-2A9F-4A2F-ACEE-FFDE0BD7E538"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="C4A5BBAB-3EBA-434E-9FF4-0E6AEE301069"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-46.533402509585706-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.948"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#bfd972"></stop>
          <stop offset="100%" stopColor="#5a7800"></stop>
        </linearGradient>
        <linearGradient
          id="19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_"
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="0%" stopColor="#688b00"></stop>
          <stop offset="100%" stopColor="#304100"></stop>
        </linearGradient>
        <filter
          id="28744BB7-F666-4D39-92DF-DBD01BCDE189"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="8E325256-6B6B-4D95-B30D-4DB963A00998"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-64.37905406192772-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.48"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="E0FD861F-2515-4E99-A029-A2B2D0E1FBF1"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="BC5529DF-1FAE-4F3A-9F22-37EA91E6B5D2"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-53.623435869146036-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.737"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="91CC3DF6-9BD2-4F90-AD81-5E7AF4A8B5F7"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="93F00157-E6F2-4B5B-9013-882E3F9E31B9"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-48.60013898720514-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.882"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="46F3E750-D1B1-4449-87C3-03822396498B"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="67D8B7D1-3DC3-42C3-BD1D-BA7350C954B9"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-70.03531207013216-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.363"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="31FC84EA-03E1-499D-95B1-87B5BD78056B"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="3A9A89F7-4677-4852-A13A-7AFB6A938099"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-70.0698933565156-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.363"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="551CD7A6-1410-4796-BA39-8253F0B45B81"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="E194B91B-DC64-4A4B-85D4-0A7221D2DA65"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-56.53545368961432-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.661"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id="B02722B0-84FB-4A4A-9D75-8B4FB831D311"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <filter
          id="219BB146-8CD1-4C86-A197-1A7B98123549"
          width="200%"
          height="200%"
        >
          <feOffset
            dx="1"
            dy="-1"
            in="SourceGraphic"
            result="offOut"
          ></feOffset>
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            values="0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 0 0.25098039215686274 0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="matrixOut"
            result="blurOut"
            stdDeviation="1.732"
          ></feGaussianBlur>
          <feComposite in="SourceGraphic" in2="blurOut"></feComposite>
        </filter>
        <linearGradient
          id="19-47.984859244439306-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_"
          x1="0"
          x2="0.901"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#959595" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#f1f1f1" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="19-360-rgba_175_216_248_1__0-rgba_175_216_248_1__30-rgba_223_240_252_1__60-rgba_175_216_248_1__90-rgba_175_216_248_1__100"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
        >
          <stop offset="0%" stopColor="#afd8f8"></stop>
          <stop offset="30%" stopColor="#afd8f8"></stop>
          <stop offset="60%" stopColor="#dff0fc"></stop>
          <stop offset="90%" stopColor="#afd8f8"></stop>
          <stop offset="100%" stopColor="#afd8f8"></stop>
        </linearGradient>
        <linearGradient
          id="19-360-rgba_246_189_15_1__0-rgba_246_189_15_1__30-rgba_252_229_159_1__60-rgba_246_189_15_1__90-rgba_246_189_15_1__100"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
        >
          <stop offset="0%" stopColor="#f6bd0f"></stop>
          <stop offset="30%" stopColor="#f6bd0f"></stop>
          <stop offset="60%" stopColor="#fce59f"></stop>
          <stop offset="90%" stopColor="#f6bd0f"></stop>
          <stop offset="100%" stopColor="#f6bd0f"></stop>
        </linearGradient>
        <linearGradient
          id="19-360-rgba_139_186_0_1__0-rgba_139_186_0_1__30-rgba_209_228_153_1__60-rgba_139_186_0_1__90-rgba_139_186_0_1__100"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
        >
          <stop offset="0%" stopColor="#8bba00"></stop>
          <stop offset="30%" stopColor="#8bba00"></stop>
          <stop offset="60%" stopColor="#d1e499"></stop>
          <stop offset="90%" stopColor="#8bba00"></stop>
          <stop offset="100%" stopColor="#8bba00"></stop>
        </linearGradient>
        <linearGradient
          id="19-51.811561333185864-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
          x1="0"
          x2="0.787"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#88a8c1"></stop>
          <stop offset="100%" stopColor="#edf6fd"></stop>
        </linearGradient>
        <linearGradient
          id="19-47.449579388784194-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
          x1="0"
          x2="0.918"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#88a8c1"></stop>
          <stop offset="100%" stopColor="#edf6fd"></stop>
        </linearGradient>
        <linearGradient
          id="19-45.94576799210946-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
          x1="0"
          x2="0.968"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#88a8c1"></stop>
          <stop offset="100%" stopColor="#edf6fd"></stop>
        </linearGradient>
        <linearGradient
          id="19-55.18837588585133-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
          x1="0"
          x2="0.695"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#88a8c1"></stop>
          <stop offset="100%" stopColor="#edf6fd"></stop>
        </linearGradient>
        <linearGradient
          id="19-55.2125693595277-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
          x1="0"
          x2="0.695"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#88a8c1"></stop>
          <stop offset="100%" stopColor="#edf6fd"></stop>
        </linearGradient>
        <linearGradient
          id="19-48.44768819065952-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
          x1="0"
          x2="0.886"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#88a8c1"></stop>
          <stop offset="100%" stopColor="#edf6fd"></stop>
        </linearGradient>
        <linearGradient
          id="19-45.777240413255576-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_"
          x1="0"
          x2="0.973"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#88a8c1"></stop>
          <stop offset="100%" stopColor="#edf6fd"></stop>
        </linearGradient>
        <linearGradient
          id="19-57.04646316278437-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_"
          x1="0"
          x2="0.648"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#bf930b"></stop>
          <stop offset="100%" stopColor="#fdf0ca"></stop>
        </linearGradient>
        <linearGradient
          id="19-49.69053211655048-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_"
          x1="0"
          x2="0.848"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#bf930b"></stop>
          <stop offset="100%" stopColor="#fdf0ca"></stop>
        </linearGradient>
        <linearGradient
          id="19-46.86033213287644-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_"
          x1="0"
          x2="0.937"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#bf930b"></stop>
          <stop offset="100%" stopColor="#fdf0ca"></stop>
        </linearGradient>
        <linearGradient
          id="19-61.94498113388585-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_"
          x1="0"
          x2="0.533"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#bf930b"></stop>
          <stop offset="100%" stopColor="#fdf0ca"></stop>
        </linearGradient>
        <linearGradient
          id="19-61.97780732073063-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_"
          x1="0"
          x2="0.532"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#bf930b"></stop>
          <stop offset="100%" stopColor="#fdf0ca"></stop>
        </linearGradient>
        <linearGradient
          id="19-51.483604912814734-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_"
          x1="0"
          x2="0.796"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#bf930b"></stop>
          <stop offset="100%" stopColor="#fdf0ca"></stop>
        </linearGradient>
        <linearGradient
          id="19-46.533402509585706-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_"
          x1="0"
          x2="0.948"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#bf930b"></stop>
          <stop offset="100%" stopColor="#fdf0ca"></stop>
        </linearGradient>
        <linearGradient
          id="19-64.37905406192772-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_"
          x1="0"
          x2="0.48"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#6c9100"></stop>
          <stop offset="100%" stopColor="#e5efc6"></stop>
        </linearGradient>
        <linearGradient
          id="19-53.623435869146036-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_"
          x1="0"
          x2="0.737"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#6c9100"></stop>
          <stop offset="100%" stopColor="#e5efc6"></stop>
        </linearGradient>
        <linearGradient
          id="19-48.60013898720514-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_"
          x1="0"
          x2="0.882"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#6c9100"></stop>
          <stop offset="100%" stopColor="#e5efc6"></stop>
        </linearGradient>
        <linearGradient
          id="19-70.03531207013216-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_"
          x1="0"
          x2="0.363"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#6c9100"></stop>
          <stop offset="100%" stopColor="#e5efc6"></stop>
        </linearGradient>
        <linearGradient
          id="19-70.0698933565156-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_"
          x1="0"
          x2="0.363"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#6c9100"></stop>
          <stop offset="100%" stopColor="#e5efc6"></stop>
        </linearGradient>
        <linearGradient
          id="19-56.53545368961432-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_"
          x1="0"
          x2="0.661"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#6c9100"></stop>
          <stop offset="100%" stopColor="#e5efc6"></stop>
        </linearGradient>
        <linearGradient
          id="19-47.984859244439306-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_"
          x1="0"
          x2="0.901"
          y1="1"
          y2="0"
        >
          <stop offset="0%" stopColor="#6c9100"></stop>
          <stop offset="100%" stopColor="#e5efc6"></stop>
        </linearGradient>
      </defs>
      <g className="raphael-group-20-background">
        <rect width="802" height="493" fill="#FFF" rx="0" ry="0"></rect>
        <rect
          width="802"
          height="493"
          fill="none"
          stroke="#666"
          strokeWidth="0"
          rx="0"
          ry="0"
        ></rect>
      </g>
      <g className="raphael-group-27-canvas">
        <rect
          width="660"
          height="348"
          x="118"
          y="58"
          fill="none"
          stroke="#545454"
          strokeWidth="0"
          rx="0"
          ry="0"
        ></rect>
        <path
          fill="url('#19-329.0909881288068-rgba_187_192_181_1_-rgba_236_240_232_1_')"
          strokeWidth="0"
          d="M118 58l3.6-3H775l3 3z"
        ></path>
        <path
          fill="url('#19-65.55604521958347-rgb_134.16_145.86_119.34_-rgb_236.74_240.04_232.56_')"
          strokeLinejoin="round"
          strokeWidth="0"
          d="M102 64l1 1h9v-1l6-6h-10z"
          shapeRendering="geometricprecision"
        ></path>
        <path
          fill="url('#19-270-rgb_129_140.25_114.75_-rgb_60.199999999999996_65.45_53.55_')"
          strokeLinejoin="round"
          strokeWidth="0"
          d="M111 65v347h1l6-6V58l-6 6"
          shapeRendering="geometricprecision"
        ></path>
        <path
          fill="url('#19-270-rgb_209.35_217.6_198.9_-rgb_111.8_121.55_99.45_')"
          strokeLinejoin="round"
          strokeWidth="0"
          d="M112 64v348h-10V64z"
          shapeRendering="geometricprecision"
        ></path>
        <path fill="none" stroke="#FAFBF9" d="M118 58v348"></path>
        <rect
          width="660"
          height="348"
          x="118"
          y="58"
          fill="url('#19-329.0909881288068-rgba_187_192_181_1_-rgba_236_240_232_1_')"
          strokeWidth="0"
          rx="0"
          ry="0"
        ></rect>
      </g>
      <g className="raphael-group-35-axisbottom">
        <g
          fill="none"
          stroke="#ACBB99"
          className="raphael-group-46-x-axis-lines"
          shapeRendering="crispedges"
        >
          <path d="M250 58v348M382 58v348M514 58v348M646 58v348"></path>
        </g>
        <g className="fusioncharts-xaxis-0-gridlabels">
          <text
            x="97"
            y="85.276"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
          >
            <tspan x="97" dy="4.002">
              Denmark
            </tspan>
          </text>
          <text
            x="97"
            y="134.618"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
          >
            <tspan x="97" dy="4.001">
              France
            </tspan>
          </text>
          <text
            x="97"
            y="183.96"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
          >
            <tspan x="97" dy="3.991">
              Germany
            </tspan>
          </text>
          <text
            x="97"
            y="233.302"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
          >
            <tspan x="97" dy="3.99">
              Italy
            </tspan>
          </text>
          <text
            x="97"
            y="282.645"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
          >
            <tspan x="97" dy="3.988">
              Spain
            </tspan>
          </text>
          <text
            x="97"
            y="331.987"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
          >
            <tspan x="97" dy="3.995">
              UK
            </tspan>
          </text>
          <text
            x="97"
            y="381.329"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
          >
            <tspan x="97" dy="3.993">
              USA
            </tspan>
          </text>
        </g>
        <g className="fusioncharts-yaxis-0-gridlabels">
          <text
            x="108"
            y="427"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="middle"
          >
            <tspan x="108" dy="-2">
              0.00
            </tspan>
          </text>
          <text
            x="240"
            y="427"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="middle"
          >
            <tspan x="240" dy="-2">
              10.00K
            </tspan>
          </text>
          <text
            x="372"
            y="427"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="middle"
          >
            <tspan x="372" dy="-2">
              20.00K
            </tspan>
          </text>
          <text
            x="504"
            y="427"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="middle"
          >
            <tspan x="504" dy="-2">
              30.00K
            </tspan>
          </text>
          <text
            x="636"
            y="427"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="middle"
          >
            <tspan x="636" dy="-2">
              40.00K
            </tspan>
          </text>
          <text
            x="768"
            y="427"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            fontStyle="undefined"
            fontWeight="undefined"
            textAnchor="middle"
          >
            <tspan x="768" dy="-2">
              50.00K
            </tspan>
          </text>
        </g>
        <g className="raphael-group-55-axis-name">
          <text
            fill="#49563A"
            className="fusioncharts-yaxis-0-title"
            fontSize="10"
            textAnchor="middle"
            transform="translate(448 432)"
          >
            <tspan x="0" dy="10">
              Orders
            </tspan>
          </text>
          <text
            fill="#49563A"
            className="fusioncharts-xaxis-0-title"
            fontSize="10"
            textAnchor="middle"
            transform="rotate(-90 132.5 99.5)"
          >
            <tspan x="0" dy="10">
              States
            </tspan>
          </text>
        </g>
      </g>
      <g className="raphael-group-21-dataset">
        <g className="raphael-group-75-bars">
          <g
            strokeLinejoin="round"
            className="raphael-group-78-positive-values"
            shapeRendering="geometricprecision"
          >
            <path
              fill="url('#19-45.777240413255576-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
              d="M113 366.592l1 1h362.562v-1l5-5H118z"
              filter='url("#2E3E88A2-4287-4ECE-83AB-F9A9DA3236C6")'
            ></path>
            <path
              fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
              d="M475.562 367.592v38.474h1l5-5v-39.474l-5 5"
              filter='url("#C9463F8A-2C21-43D1-B504-B2889663CFE6")'
            ></path>
            <path
              fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
              d="M476.562 366.592v39.474H113v-39.474z"
            ></path>
            <path
              fill="url('#19-46.533402509585706-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
              d="M476.562 366.592l1 1h180.781v-1l5-5h-181.78z"
              filter='url("#8CB1929B-2A9F-4A2F-ACEE-FFDE0BD7E538")'
            ></path>
            <path
              fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
              d="M657.343 367.592v38.474h1l5-5v-39.474l-5 5"
              filter='url("#C4A5BBAB-3EBA-434E-9FF4-0E6AEE301069")'
            ></path>
            <path
              fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
              d="M658.343 366.592v39.474h-181.78v-39.474z"
            ></path>
            <path
              fill="url('#19-47.984859244439306-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
              d="M658.343 366.592l1 1h89.89v-1l5-5h-90.89z"
              filter='url("#B02722B0-84FB-4A4A-9D75-8B4FB831D311")'
            ></path>
            <path
              fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
              d="M748.234 367.592v38.474h1l5-5v-39.474l-5 5"
              filter='url("#219BB146-8CD1-4C86-A197-1A7B98123549")'
            ></path>
            <path
              fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
              d="M749.234 366.592v39.474h-90.89v-39.474z"
            ></path>
            <g>
              <path
                fill="url('#19-48.44768819065952-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                d="M113 317.25l1 1h76.993v-1l5-5H118z"
                filter='url("#408B53EA-2A32-4310-9A76-B01766286C7D")'
              ></path>
              <path
                fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                d="M189.993 318.25v38.474h1l5-5V312.25l-5 5"
                filter='url("#41FAEB30-723B-41B3-A09B-3A9E70716917")'
              ></path>
              <path
                fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                d="M190.993 317.25v39.474H113V317.25z"
              ></path>
              <path
                fill="url('#19-51.483604912814734-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
                d="M190.993 317.25l1 1h37.996v-1l5-5h-38.996z"
                filter='url("#C603ED7A-FF93-4F78-BBA6-4B596BF9D27C")'
              ></path>
              <path
                fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
                d="M228.99 318.25v38.474h1l5-5V312.25l-5 5"
                filter='url("#4879A0F6-F18D-47E7-9BA6-30DBC8FCDB18")'
              ></path>
              <path
                fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
                d="M229.99 317.25v39.474h-38.997V317.25z"
              ></path>
              <path
                fill="url('#19-56.53545368961432-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
                d="M229.99 317.25l1 1h18.497v-1l5-5H234.99z"
                filter='url("#551CD7A6-1410-4796-BA39-8253F0B45B81")'
              ></path>
              <path
                fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
                d="M248.487 318.25v38.474h1l5-5V312.25l-5 5"
                filter='url("#E194B91B-DC64-4A4B-85D4-0A7221D2DA65")'
              ></path>
              <path
                fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
                d="M249.487 317.25v39.474H229.99V317.25z"
              ></path>
            </g>
            <g>
              <path
                fill="url('#19-55.2125693595277-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                d="M113 267.908l1 1h21.754v-1l5-5H118z"
                filter='url("#0CF94F4C-79EC-4D1B-999A-7AE8C2631242")'
              ></path>
              <path
                fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                d="M134.754 268.908v38.473h1l5-5v-39.473l-5 5"
                filter='url("#3757BC7F-9E1C-4F0F-883E-945B17E2DA85")'
              ></path>
              <path
                fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                d="M135.754 267.908v39.473H113v-39.473z"
              ></path>
              <path
                fill="url('#19-61.97780732073063-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
                d="M135.754 267.908l1 1h10.377v-1l5-5h-11.377z"
                filter='url("#1647DD6E-B0C7-4996-A116-6395210CCC3E")'
              ></path>
              <path
                fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
                d="M146.13 268.908v38.473h1l5-5v-39.473l-5 5"
                filter='url("#F21A485D-01C3-44E9-9812-F059BF9B8B88")'
              ></path>
              <path
                fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
                d="M147.13 267.908v39.473h-11.376v-39.473z"
              ></path>
              <path
                fill="url('#19-70.0698933565156-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
                d="M147.13 267.908l1 1h4.69v-1l5-5h-5.69z"
                filter='url("#31FC84EA-03E1-499D-95B1-87B5BD78056B")'
              ></path>
              <path
                fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
                d="M151.82 268.908v38.473h1l5-5v-39.473l-5 5"
                filter='url("#3A9A89F7-4677-4852-A13A-7AFB6A938099")'
              ></path>
              <path
                fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
                d="M152.82 267.908v39.473h-5.69v-39.473z"
              ></path>
            </g>
            <g>
              <path
                fill="url('#19-55.18837588585133-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                d="M113 218.566l1 1h21.821v-1l5-5H118z"
                filter='url("#7C6316FA-54BE-4E6D-8D24-B495FAE54AA8")'
              ></path>
              <path
                fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                d="M134.821 219.566v38.473h1l5-5v-39.473l-5 5"
                filter='url("#7FE3FF70-6505-4566-AAD5-55F311DD2035")'
              ></path>
              <path
                fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                d="M135.821 218.566v39.473H113v-39.473z"
              ></path>
              <path
                fill="url('#19-61.94498113388585-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
                d="M135.821 218.566l1 1h10.41v-1l5-5h-11.41z"
                filter='url("#4B74DA09-9AC2-4042-B470-D571426DF935")'
              ></path>
              <path
                fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
                d="M146.232 219.566v38.473h1l5-5v-39.473l-5 5"
                filter='url("#6E579D0B-FD81-4ABE-B3B1-EF015170DF3F")'
              ></path>
              <path
                fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
                d="M147.232 218.566v39.473h-11.41v-39.473z"
              ></path>
              <path
                fill="url('#19-70.03531207013216-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
                d="M147.232 218.566l1 1h4.705v-1l5-5h-5.705z"
                filter='url("#46F3E750-D1B1-4449-87C3-03822396498B")'
              ></path>
              <path
                fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
                d="M151.937 219.566v38.473h1l5-5v-39.473l-5 5"
                filter='url("#67D8B7D1-3DC3-42C3-BD1D-BA7350C954B9")'
              ></path>
              <path
                fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
                d="M152.937 218.566v39.473h-5.705v-39.473z"
              ></path>
            </g>
            <g>
              <path
                fill="url('#19-45.94576799210946-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                d="M113 169.223l1 1h296.879v-1l5-5H118z"
                filter='url("#92C9C08A-FE05-49DA-9403-D298EF64AB07")'
              ></path>
              <path
                fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                d="M409.879 170.223v38.474h1l5-5v-39.474l-5 5"
                filter='url("#57D9D883-159B-4318-BF69-008B65B0EBDB")'
              ></path>
              <path
                fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                d="M410.879 169.223v39.474H113v-39.474z"
              ></path>
              <path
                fill="url('#19-46.86033213287644-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
                d="M410.879 169.223l1 1h147.939v-1l5-5h-148.94z"
                filter='url("#719DC6E5-2436-47BF-A87E-C6FA8BE42B46")'
              ></path>
              <path
                fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
                d="M558.818 170.223v38.474h1l5-5v-39.474l-5 5"
                filter='url("#022827C7-7180-4BC8-979B-72FD31DF4524")'
              ></path>
              <path
                fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
                d="M559.818 169.223v39.474h-148.94v-39.474z"
              ></path>
              <path
                fill="url('#19-48.60013898720514-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
                d="M559.818 169.223l1 1h73.47v-1l5-5h-74.47z"
                filter='url("#91CC3DF6-9BD2-4F90-AD81-5E7AF4A8B5F7")'
              ></path>
              <path
                fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
                d="M633.288 170.223v38.474h1l5-5v-39.474l-5 5"
                filter='url("#93F00157-E6F2-4B5B-9013-882E3F9E31B9")'
              ></path>
              <path
                fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
                d="M634.288 169.223v39.474h-74.47v-39.474z"
              ></path>
            </g>
            <g>
              <path
                fill="url('#19-47.449579388784194-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                d="M113 119.881l1 1h110.879v-1l5-5H118z"
                filter='url("#E8F8A4BD-2594-452F-808E-C0D5CA7B0BDE")'
              ></path>
              <path
                fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                d="M223.879 120.881v38.474h1l5-5V114.88l-5 5"
                filter='url("#294ACD2C-7B6B-445C-8189-A372C59EAC07")'
              ></path>
              <path
                fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                d="M224.879 119.881v39.474H113V119.88z"
              ></path>
              <path
                fill="url('#19-49.69053211655048-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
                d="M224.879 119.881l1 1h54.94v-1l5-5h-55.94z"
                filter='url("#D6FBF0CF-83C0-4452-93E3-A3FF8E67323F")'
              ></path>
              <path
                fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
                d="M279.818 120.881v38.474h1l5-5V114.88l-5 5"
                filter='url("#FDBA4124-33D8-44F5-811B-82E7CEADE37D")'
              ></path>
              <path
                fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
                d="M280.818 119.881v39.474H224.88V119.88z"
              ></path>
              <path
                fill="url('#19-53.623435869146036-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
                d="M280.818 119.881l1 1h26.97v-1l5-5h-27.97z"
                filter='url("#E0FD861F-2515-4E99-A029-A2B2D0E1FBF1")'
              ></path>
              <path
                fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
                d="M307.788 120.881v38.474h1l5-5V114.88l-5 5"
                filter='url("#BC5529DF-1FAE-4F3A-9F22-37EA91E6B5D2")'
              ></path>
              <path
                fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
                d="M308.788 119.881v39.474h-27.97V119.88z"
              ></path>
            </g>
            <g>
              <path
                fill="url('#19-51.811561333185864-rgba_136.5_168.48000000000002_193.44_1_-rgba_237.4_246.42_253.46_1_')"
                d="M113 70.539l1 1h35.86v-1l5-5H118z"
                filter='url("#4503FE3D-6CB2-4251-8D17-1085B38C50DA")'
              ></path>
              <path
                fill="url('#19-270-rgba_131.25_162_186_1_-rgba_61.24999999999999_75.6_86.8_1_')"
                d="M148.86 71.539v38.474h1l5-5V65.539l-5 5"
                filter='url("#0F1882A2-A376-475B-9ABA-3BBDD90D0CE1")'
              ></path>
              <path
                fill="url('#19-270-rgba_211_233.55_251.15_1_-rgba_113.75_140.4_161.20000000000002_1_')"
                d="M149.86 70.539v39.474H113V70.539z"
              ></path>
              <path
                fill="url('#19-57.04646316278437-rgba_191.88_147.42000000000002_11.700000000000001_1_-rgba_253.02_240.48_202.2_1_')"
                d="M149.86 70.539l1 1h17.43v-1l5-5h-18.43z"
                filter='url("#F0812373-1BCC-41B0-8311-A45C49846580")'
              ></path>
              <path
                fill="url('#19-270-rgba_184.5_141.75_11.25_1_-rgba_86.1_66.14999999999999_5.25_1_')"
                d="M167.29 71.539v38.474h1l5-5V65.539l-5 5"
                filter='url("#C1AC5784-0A6B-46D9-8C14-D3731CA24065")'
              ></path>
              <path
                fill="url('#19-270-rgba_250.05_218.7_123_1_-rgba_159.9_122.85000000000001_9.75_1_')"
                d="M168.29 70.539v39.474h-18.43V70.539z"
              ></path>
              <path
                fill="url('#19-64.37905406192772-rgba_108.42_145.08_0_1_-rgba_229.48_239.82_198.9_1_')"
                d="M168.29 70.539l1 1h8.214v-1l5-5h-9.215z"
                filter='url("#28744BB7-F666-4D39-92DF-DBD01BCDE189")'
              ></path>
              <path
                fill="url('#19-270-rgba_104.25_139.5_0_1_-rgba_48.65_65.1_0_1_')"
                d="M176.504 71.539v38.474h1l5-5V65.539l-5 5"
                filter='url("#8E325256-6B6B-4D95-B30D-4DB963A00998")'
              ></path>
              <path
                fill="url('#19-270-rgba_191.2_217.05_114.75_1_-rgba_90.35000000000001_120.9_0_1_')"
                d="M177.504 70.539v39.474h-9.215V70.539z"
              ></path>
            </g>
          </g>
        </g>
      </g>
      <g className="raphael-group-26-datalabels">
        <g className="raphael-group-254-datalabel">
          <text
            x="131.43"
            y="90.276"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="131.43" dy="4.002">
              2.79K
            </tspan>
          </text>
          <text
            x="168.939"
            y="139.618"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="168.939" dy="4.001">
              8.48K
            </tspan>
          </text>
          <text
            x="261.939"
            y="188.96"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="261.939" dy="3.999">
              22.57K
            </tspan>
          </text>
          <text
            x="129.828"
            y="238.302"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="129.828" dy="3.998">
              1.73K
            </tspan>
          </text>
          <text
            x="129.828"
            y="287.645"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="129.828" dy="3.996">
              1.72K
            </tspan>
          </text>
          <text
            x="151.996"
            y="336.987"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="151.996" dy="3.995">
              5.91K
            </tspan>
          </text>
          <text
            x="294.781"
            y="386.329"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="294.781" dy="3.993">
              27.54K
            </tspan>
          </text>
        </g>
        <g className="raphael-group-262-datalabel">
          <text
            x="159.074"
            y="90.276"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="159.074" dy="4.002">
              1.40K
            </tspan>
          </text>
          <text
            x="252.849"
            y="139.618"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="252.849" dy="4.001">
              4.24K
            </tspan>
          </text>
          <text
            x="485.348"
            y="188.96"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="485.348" dy="3.999">
              11.28K
            </tspan>
          </text>
          <text
            x="141.527"
            y="238.302"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="141.527" dy="3.998">
              864.44
            </tspan>
          </text>
          <text
            x="141.442"
            y="287.645"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="141.442" dy="3.996">
              861.89
            </tspan>
          </text>
          <text
            x="210.491"
            y="336.987"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="210.491" dy="3.995">
              2.95K
            </tspan>
          </text>
          <text
            x="567.453"
            y="386.329"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="567.453" dy="3.993">
              13.77K
            </tspan>
          </text>
        </g>
        <g className="raphael-group-270-datalabel">
          <text
            x="172.897"
            y="90.276"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="172.897" dy="4.002">
              698.10
            </tspan>
          </text>
          <text
            x="294.803"
            y="139.618"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="294.803" dy="4.001">
              2.12K
            </tspan>
          </text>
          <text
            x="597.053"
            y="188.96"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="597.053" dy="3.999">
              5.64K
            </tspan>
          </text>
          <text
            x="150.084"
            y="238.302"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="150.084" dy="3.998">
              432.22
            </tspan>
          </text>
          <text
            x="149.975"
            y="287.645"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="149.975" dy="3.996">
              430.95
            </tspan>
          </text>
          <text
            x="239.738"
            y="336.987"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="239.738" dy="3.995">
              1.48K
            </tspan>
          </text>
          <text
            x="703.788"
            y="386.329"
            fill="#49563A"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
          >
            <tspan x="703.788" dy="3.993">
              6.89K
            </tspan>
          </text>
        </g>
      </g>
      <g className="fusioncharts-legend" transform="translate(292.5 452.5)">
        <rect
          width="304"
          height="25"
          fill="none"
          stroke="#404040"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="304"
          height="25"
          fill="none"
          stroke="#404040"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="304"
          height="25"
          fill="none"
          stroke="#404040"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="304"
          height="25"
          fill="#F0F3ED"
          stroke="#C6CFB8"
          rx="0"
          ry="0"
        ></rect>
        <text
          x="23"
          y="6.5"
          fill="#49563A"
          className="fusioncharts-legenditem"
          cursor="pointer"
          fontSize="10"
        >
          <tspan x="23" dy="9.25">
            Double Freight
          </tspan>
        </text>
        <path
          fill="url('#19-360-rgba_175_216_248_1__0-rgba_175_216_248_1__30-rgba_223_240_252_1__60-rgba_175_216_248_1__90-rgba_175_216_248_1__100')"
          stroke="#CFE8FB"
          strokeWidth="0.5"
          d="M7 7h6.6v2.75H7zm0 4.125h11v2.75H7zm0 4.125h3.3V18H7z"
          cursor="pointer"
        ></path>
        <rect
          width="96"
          height="17"
          x="4"
          y="4"
          fill="silver"
          fillOpacity="0"
          cursor="pointer"
          rx="0"
          ry="0"
        ></rect>
        <text
          x="129.85"
          y="6.5"
          fill="#49563A"
          className="fusioncharts-legenditem"
          cursor="pointer"
          fontSize="10"
        >
          <tspan x="129.85" dy="9.25">
            Normal Freight
          </tspan>
        </text>
        <path
          fill="url('#19-360-rgba_246_189_15_1__0-rgba_246_189_15_1__30-rgba_252_229_159_1__60-rgba_246_189_15_1__90-rgba_246_189_15_1__100')"
          stroke="#FAD76F"
          strokeWidth="0.5"
          d="M113.85 7h6.6v2.75h-6.6zm0 4.125h11v2.75h-11zm0 4.125h3.3V18h-3.3z"
          cursor="pointer"
        ></path>
        <rect
          width="97"
          height="17"
          x="110.85"
          y="4"
          fill="silver"
          fillOpacity="0"
          cursor="pointer"
          rx="0"
          ry="0"
        ></rect>
        <text
          x="237.7"
          y="6.5"
          fill="#49563A"
          className="fusioncharts-legenditem"
          cursor="pointer"
          fontSize="10"
        >
          <tspan x="237.7" dy="9.25">
            Half Freight
          </tspan>
        </text>
        <path
          fill="url('#19-360-rgba_139_186_0_1__0-rgba_139_186_0_1__30-rgba_209_228_153_1__60-rgba_139_186_0_1__90-rgba_139_186_0_1__100')"
          stroke="#B9D666"
          strokeWidth="0.5"
          d="M221.7 7h6.6v2.75h-6.6zm0 4.125h11v2.75h-11zm0 4.125h3.3V18h-3.3z"
          cursor="pointer"
        ></path>
        <rect
          width="80"
          height="17"
          x="218.7"
          y="4"
          fill="silver"
          fillOpacity="0"
          cursor="pointer"
          rx="0"
          ry="0"
        ></rect>
      </g>
      <g className="raphael-group-251-caption">
        <text
          x="448.012"
          y="16"
          fill="#49563A"
          className="fusioncharts-caption"
          fontFamily="Verdana, sans"
          fontSize="13"
          fontWeight="bold"
          textAnchor="middle"
        >
          <tspan x="448.012" dy="12.992">
            Chart Caption
          </tspan>
        </text>
        <text
          x="448.012"
          y="34"
          fill="#49563A"
          className="fusioncharts-subcaption"
          fontFamily="Verdana, sans"
          fontSize="11"
          fontWeight="bold"
          textAnchor="middle"
        >
          <tspan x="448.012" dy="10.852">
            Chart Sub Caption
          </tspan>
        </text>
      </g>
      <g className="raphael-group-22-hot">
        <g
          stroke="silver"
          strokeLinejoin="round"
          strokeOpacity="0"
          className="raphael-group-80-positive-values"
          shapeRendering="geometricprecision"
        >
          <path
            fill="url('#19-45.777240413255576-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M113 366.592l1 1h362.562v-1l5-5H118z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M475.562 367.592v38.474h1l5-5v-39.474l-5 5"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M476.562 366.592v39.474H113v-39.474z"
          ></path>
          <path
            fill="url('#19-46.533402509585706-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M476.562 366.592l1 1h180.781v-1l5-5h-181.78z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M657.343 367.592v38.474h1l5-5v-39.474l-5 5"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M658.343 366.592v39.474h-181.78v-39.474z"
          ></path>
          <path
            fill="url('#19-47.984859244439306-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
            d="M658.343 366.592l1 1h89.89v-1l5-5h-90.89z"
          ></path>
          <path
            fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
            d="M748.234 367.592v38.474h1l5-5v-39.474l-5 5"
          ></path>
          <path
            fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
            d="M749.234 366.592v39.474h-90.89v-39.474z"
          ></path>
          <g>
            <path
              fill="url('#19-48.44768819065952-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M113 317.25l1 1h76.993v-1l5-5H118z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M189.993 318.25v38.474h1l5-5V312.25l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M190.993 317.25v39.474H113V317.25z"
            ></path>
            <path
              fill="url('#19-51.483604912814734-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M190.993 317.25l1 1h37.996v-1l5-5h-38.996z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M228.99 318.25v38.474h1l5-5V312.25l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M229.99 317.25v39.474h-38.997V317.25z"
            ></path>
            <path
              fill="url('#19-56.53545368961432-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M229.99 317.25l1 1h18.497v-1l5-5H234.99z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M248.487 318.25v38.474h1l5-5V312.25l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M249.487 317.25v39.474H229.99V317.25z"
            ></path>
          </g>
          <g>
            <path
              fill="url('#19-55.2125693595277-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M113 267.908l1 1h21.754v-1l5-5H118z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M134.754 268.908v38.473h1l5-5v-39.473l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M135.754 267.908v39.473H113v-39.473z"
            ></path>
            <path
              fill="url('#19-61.97780732073063-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M135.754 267.908l1 1h10.377v-1l5-5h-11.377z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M146.13 268.908v38.473h1l5-5v-39.473l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M147.13 267.908v39.473h-11.376v-39.473z"
            ></path>
            <path
              fill="url('#19-70.0698933565156-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M147.13 267.908l1 1h4.69v-1l5-5h-5.69z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M151.82 268.908v38.473h1l5-5v-39.473l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M152.82 267.908v39.473h-5.69v-39.473z"
            ></path>
          </g>
          <g>
            <path
              fill="url('#19-55.18837588585133-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M113 218.566l1 1h21.821v-1l5-5H118z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M134.821 219.566v38.473h1l5-5v-39.473l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M135.821 218.566v39.473H113v-39.473z"
            ></path>
            <path
              fill="url('#19-61.94498113388585-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M135.821 218.566l1 1h10.41v-1l5-5h-11.41z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M146.232 219.566v38.473h1l5-5v-39.473l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M147.232 218.566v39.473h-11.41v-39.473z"
            ></path>
            <path
              fill="url('#19-70.03531207013216-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M147.232 218.566l1 1h4.705v-1l5-5h-5.705z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M151.937 219.566v38.473h1l5-5v-39.473l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M152.937 218.566v39.473h-5.705v-39.473z"
            ></path>
          </g>
          <g>
            <path
              fill="url('#19-45.94576799210946-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M113 169.223l1 1h296.879v-1l5-5H118z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M409.879 170.223v38.474h1l5-5v-39.474l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M410.879 169.223v39.474H113v-39.474z"
            ></path>
            <path
              fill="url('#19-46.86033213287644-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M410.879 169.223l1 1h147.939v-1l5-5h-148.94z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M558.818 170.223v38.474h1l5-5v-39.474l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M559.818 169.223v39.474h-148.94v-39.474z"
            ></path>
            <path
              fill="url('#19-48.60013898720514-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M559.818 169.223l1 1h73.47v-1l5-5h-74.47z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M633.288 170.223v38.474h1l5-5v-39.474l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M634.288 169.223v39.474h-74.47v-39.474z"
            ></path>
          </g>
          <g>
            <path
              fill="url('#19-47.449579388784194-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M113 119.881l1 1h110.879v-1l5-5H118z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M223.879 120.881v38.474h1l5-5V114.88l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M224.879 119.881v39.474H113V119.88z"
            ></path>
            <path
              fill="url('#19-49.69053211655048-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M224.879 119.881l1 1h54.94v-1l5-5h-55.94z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M279.818 120.881v38.474h1l5-5V114.88l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M280.818 119.881v39.474H224.88V119.88z"
            ></path>
            <path
              fill="url('#19-53.623435869146036-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M280.818 119.881l1 1h26.97v-1l5-5h-27.97z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M307.788 120.881v38.474h1l5-5V114.88l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M308.788 119.881v39.474h-27.97V119.88z"
            ></path>
          </g>
          <g>
            <path
              fill="url('#19-51.811561333185864-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M113 70.539l1 1h35.86v-1l5-5H118z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M148.86 71.539v38.474h1l5-5V65.539l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M149.86 70.539v39.474H113V70.539z"
            ></path>
            <path
              fill="url('#19-57.04646316278437-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M149.86 70.539l1 1h17.43v-1l5-5h-18.43z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M167.29 71.539v38.474h1l5-5V65.539l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M168.29 70.539v39.474h-18.43V70.539z"
            ></path>
            <path
              fill="url('#19-64.37905406192772-rgba_149.76_149.76_149.76_0.000001_-rgba_241.14_241.14_241.14_0.000001_')"
              d="M168.29 70.539l1 1h8.214v-1l5-5h-9.215z"
            ></path>
            <path
              fill="url('#19-270-rgba_144_144_144_0.000001_-rgba_67.19999999999999_67.19999999999999_67.19999999999999_0.000001_')"
              d="M176.504 71.539v38.474h1l5-5V65.539l-5 5"
            ></path>
            <path
              fill="url('#19-270-rgba_220.35_220.35_220.35_0.000001_-rgba_124.80000000000001_124.80000000000001_124.80000000000001_0.000001_')"
              d="M177.504 70.539v39.474h-9.215V70.539z"
            ></path>
          </g>
        </g>
      </g>
    </svg>)};

export default StackedBar3DChartIcon;