/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../../ui/APDescriptor";
import { MHyperlinks } from "../../../common/MHyperlinks";
import { PExpression } from "../../../../ui/primitive/UPExpression";
import { PConnectedTable } from "../../../../ui/tables/UPConnectedTable";
import { PTableElement } from "../../../../ui/tables/UPTableElement";

export const FTimeSeries = {
    timePeriodExpression: new PExpression({ id: 'timePeriodExpression', label: 'Time Period Expression' }),
    labelExpression: new PExpression({ id: 'labelExpression', label: 'Label Expression' }),
    valueExpression: new PExpression({ id: 'valueExpression', label: 'Value Expression' }),
    seriesExpression: new PExpression({ id: 'seriesExpression', label: 'Series Expression' })
};

export const MTimeSeries: APDescriptor = new PConnectedTable({
    id: 'timeSeries', label: 'Series', rowLabel: 'Series',  rowLabelPaths:[FTimeSeries.seriesExpression.id],
    layouts: [
        new PTableElement({
            label: 'Time Series',
            anchorOrigin: {vertical: 'center', horizontal: 'right'},
            transformOrigin: {vertical: 'center', horizontal: 'right'},
            layouts: [FTimeSeries.labelExpression, FTimeSeries.timePeriodExpression, FTimeSeries.valueExpression,
            FTimeSeries.seriesExpression,
            { ...MHyperlinks, id: 'itemHyperlink', label: 'Item Hyperlink' }]
        })]
});
