export const en = {
  "dataAdaptersView.basename.text": "New Data Adapter",
  "dataAdaptersView.tabs.editor": "Editor",
  "dataAdaptersView.tabs.source": "Source",
  "home.buttons.gdrive.login": "Login to GDrive",
  "home.buttons.github.login": "Login to GitHub",
  "home.buttons.js.login": "Login to JasperReports Server",
  "home.buttons.s3.login": "Login to Amazon S3",
  "home.buttons.login": "Login to {{name}}",
  "home.buttons.login.file": "{{name}} Folder",
  "home.buttons.jrws.login": "Login to JRWS SAAS",
  "repositoryView.contextual.action.run": "Run",
  "repositoryView.create.menu.newreport": "New Report",
  "repositoryView.create.menu.newda": "New Data Adapter",
  "repositoryView.create.menu.newfolder": "New Folder",
  "languageselector.combo.language": "English",
  "appheader.selector.theme.text": "Theme:",
  "appheader.selector.lang.text": "Language:",
  "appheader.selector.lang.sendfeedback": "Send Feedback",
  "appheader.button.logout": "Logout",
  "appheader.button.home.tooltip": "Go to Home",
  "dark": "Dark",
  "light": "Light",
  "home.login.button": "Login",
  "home.login.username": "Username",
  "home.login.password": "Password",

  "home.login.js.userid": "User ID",
  "home.login.js.organization": "Organization",
  "home.login.js.serverurl": "Server URL",
  "home.login.js.info": "To be used with JasperReports Server 8.0.0 or later.",

  "home.login.s3.accesskey": "Access Key",
  "home.login.s3.secretkey": "Secret Key"
}