/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { nameValidator } from '@jss/js-common/src/utils/validators';
import { MIME_TYPES } from '@jss/js-rest-api';
import * as React from 'react';
import format from 'xml-formatter';
import { getDataAdapterNode } from '../../editor/Utils';
import { createNode, getProlog, IDataAdapterConfig, IDataAdapterDescriptor } from '../IDataAdapterDescriptor';
import { HibernateDataAdapterEditor } from './HibernateDataAdapterEditor';


export default class HibernateDataAdapterDescriptor implements IDataAdapterDescriptor {

  public getMime = () => {
    return MIME_TYPES.DATA_ADAPTER_HIBERNATE;
  }

  public getIcon = () => {
    return <svg version="1.1" id="Layer_1"  x="0px" y="0px"
      width="256px" height="256px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" xmlSpace="preserve">
      <g>
        <polygon fill="#59666C" points="103.4,85.268 54.151,170.661 54.151,170.661 4.94,85.269 54.17,0 	" />
        <polygon fill="#BCAE79" points="152.66,0.003 54.198,0.003 103.43,85.272 201.916,85.269 	" />
        <polygon fill="#59666C" points="152.633,170.703 201.88,85.309 201.88,85.309 251.092,170.703 201.861,255.971 	" />
        <polygon fill="#BCAE79" points="103.374,255.968 201.835,255.968 152.604,170.697 54.117,170.703 	" />
      </g>
    </svg>
  };

  /**
   *  Return the name of this data adapter to be presented to the user
   *  i.e. CSV File
   */
  public getDataAdapterLabel = () => {
    return 'datasource.hibernate.descriptor.label';
  }

  /**
  *  Return the name of this data adapter to be presented to the user
  *  i.e. CSV File
  */
  public getDataAdapterDescription = () => {
    return 'datasource.hibernate.description';
  }

  /**
   *  Return the canonical name of the class which impements this data adapter in Java.
   *  net.sf.jasperreports.data.csv.CsvDataAdapter
   */
  public getDataAdapterClass = () => {
    return 'net.sf.jasperreports.data.hibernate.HibernateDataAdapterImpl'
  }

  public getDataAdapterRootName = () => {
    return 'hibernateDataAdapter';
  }

  /**
   * Return a plain json object with the default data adapter configuration.
   * It can be just an empty object.
   *
   * @memberof IDataAdapter
   */
  public initializeDataAdapterConfig = () => {
    return {
      class: this.getDataAdapterClass(),
      name: 'New Hibernate Data Adapter',
      xMLFileName: '',
      propertiesFileName: '',
      useAnnotation: false,
      classpaths: [],
    }
  };

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public validateDataAdapterConfig = (config: IDataAdapterConfig) => {

    // Check for errors...
    const errors = [];

    const nameError = nameValidator(config.name);
    if (nameError) {
      errors.push(nameError);
    }

    if (errors.length > 0) {
      return errors.join("\n");
    }

    return undefined;

  }

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public createEditor = (config: IDataAdapterConfig, onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => {
    return <HibernateDataAdapterEditor {...config} onDataAdapterSettingsChange={onDataAdapterSettingsChange} readOnly={readOnly}/>
  }

  public getLanguages = () => {
    return ['text'];
  }

  public toXml = (config: IDataAdapterConfig) => {
    const xmlDoc = document.implementation.createDocument(null, this.getDataAdapterRootName());
    const root = xmlDoc.getElementsByTagName(this.getDataAdapterRootName())[0];
    root.setAttribute('class', this.getDataAdapterClass());

    const nameNode = createNode(xmlDoc, config, 'name', 'name');
    if (nameNode) {
      root.appendChild(nameNode);
    }

    const xMLFileNamerNode = createNode(xmlDoc, config, 'xMLFileName', 'xMLFileName');
    if (xMLFileNamerNode) {
      root.appendChild(xMLFileNamerNode);
    }

    const propertiesFileNameNode = createNode(xmlDoc, config, 'propertiesFileName', 'propertiesFileName');
    if (propertiesFileNameNode) {
      root.appendChild(propertiesFileNameNode);
    }

    const useAnnotationNode = createNode(xmlDoc, config, 'useAnnotation', 'useAnnotation');
    if (useAnnotationNode) {
      root.appendChild(useAnnotationNode);
    }

    config.classpaths.forEach((classpath: string) => {
      const classpatNode = xmlDoc.createElement('classpath');
      const newText = xmlDoc.createTextNode(classpath);
      classpatNode.appendChild(newText);
      root.appendChild(classpatNode);
    });

    const prolog = getProlog();
    const xml = prolog + (new XMLSerializer().serializeToString(xmlDoc));
    return format(xml, { indentation: '  ', collapseContent: true });
  }

  public toJson = (xml: Document) => {
    const result: any = {
      class: this.getDataAdapterClass(),
      name: '',
      xMLFileName: '',
      propertiesFileName: '',
      useAnnotation: false,
      classpaths: [],
    }

    getDataAdapterNode(xml.getRootNode()).childNodes.forEach((childNode) => {
      const nodeName = childNode.nodeName;
      if (nodeName === 'name') {
        result.name = childNode.textContent;
      } else if (nodeName === 'xMLFileName') {
        result.xMLFileName = childNode.textContent;
      } else if (nodeName === 'propertiesFileName') {
        result.propertiesFileName = childNode.textContent;
      } else if (nodeName === 'useAnnotation') {
        result.useAnnotation = (childNode.textContent === 'true');
      } else if (nodeName === 'classpath') {
        result.classpaths.push(childNode.textContent);
      }
    });
    return result;
  }
}