/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const BucketEnum = new PEnum({
    id: 'order', label: 'Bucket.label', default: 'None',
    items: [
        { key: 'None', value: 'Bucket.None' },
        { key: 'Ascending', value: 'Bucket.Ascending' },
        { key: 'Descending', value: 'Bucket.Descending' }
    ]
});

