/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

export const TIMEZONES = [
    {
        id: 'ACT',
        label: 'Australian Central Standard Time (Northern Territory)'
    },
    {
        id: 'AET',
        label: 'Australian Eastern Standard Time (New South Wales)'
    },
    {
        id: 'AGT',
        label: 'Argentine Time'
    },
    {
        id: 'ART',
        label: 'Eastern European Time'
    },
    {
        id: 'AST',
        label: 'Alaska Standard Time'
    },
    {
        id: 'Africa/Abidjan',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Africa/Accra',
        label: 'Ghana Mean Time'
    },
    {
        id: 'Africa/Addis_Ababa',
        label: 'Eastern African Time'
    },
    {
        id: 'Africa/Algiers',
        label: 'Central European Time'
    },
    {
        id: 'Africa/Asmara',
        label: 'Eastern African Time'
    },
    {
        id: 'Africa/Asmera',
        label: 'Eastern African Time'
    },
    {
        id: 'Africa/Bamako',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Africa/Bangui',
        label: 'Western African Time'
    },
    {
        id: 'Africa/Banjul',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Africa/Bissau',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Africa/Blantyre',
        label: 'Central African Time'
    },
    {
        id: 'Africa/Brazzaville',
        label: 'Western African Time'
    },
    {
        id: 'Africa/Bujumbura',
        label: 'Central African Time'
    },
    {
        id: 'Africa/Cairo',
        label: 'Eastern European Time'
    },
    {
        id: 'Africa/Casablanca',
        label: 'Western European Time'
    },
    {
        id: 'Africa/Ceuta',
        label: 'Central European Time'
    },
    {
        id: 'Africa/Conakry',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Africa/Dakar',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Africa/Dar_es_Salaam',
        label: 'Eastern African Time'
    },
    {
        id: 'Africa/Djibouti',
        label: 'Eastern African Time'
    },
    {
        id: 'Africa/Douala',
        label: 'Western African Time'
    },
    {
        id: 'Africa/El_Aaiun',
        label: 'Western European Time'
    },
    {
        id: 'Africa/Freetown',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Africa/Gaborone',
        label: 'Central African Time'
    },
    {
        id: 'Africa/Harare',
        label: 'Central African Time'
    },
    {
        id: 'Africa/Johannesburg',
        label: 'South Africa Standard Time'
    },
    {
        id: 'Africa/Juba',
        label: 'Eastern African Time'
    },
    {
        id: 'Africa/Kampala',
        label: 'Eastern African Time'
    },
    {
        id: 'Africa/Khartoum',
        label: 'Eastern African Time'
    },
    {
        id: 'Africa/Kigali',
        label: 'Central African Time'
    },
    {
        id: 'Africa/Kinshasa',
        label: 'Western African Time'
    },
    {
        id: 'Africa/Lagos',
        label: 'Western African Time'
    },
    {
        id: 'Africa/Libreville',
        label: 'Western African Time'
    },
    {
        id: 'Africa/Lome',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Africa/Luanda',
        label: 'Western African Time'
    },
    {
        id: 'Africa/Lubumbashi',
        label: 'Central African Time'
    },
    {
        id: 'Africa/Lusaka',
        label: 'Central African Time'
    },
    {
        id: 'Africa/Malabo',
        label: 'Western African Time'
    },
    {
        id: 'Africa/Maputo',
        label: 'Central African Time'
    },
    {
        id: 'Africa/Maseru',
        label: 'South Africa Standard Time'
    },
    {
        id: 'Africa/Mbabane',
        label: 'South Africa Standard Time'
    },
    {
        id: 'Africa/Mogadishu',
        label: 'Eastern African Time'
    },
    {
        id: 'Africa/Monrovia',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Africa/Nairobi',
        label: 'Eastern African Time'
    },
    {
        id: 'Africa/Ndjamena',
        label: 'Western African Time'
    },
    {
        id: 'Africa/Niamey',
        label: 'Western African Time'
    },
    {
        id: 'Africa/Nouakchott',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Africa/Ouagadougou',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Africa/Porto-Novo',
        label: 'Western African Time'
    },
    {
        id: 'Africa/Sao_Tome',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Africa/Timbuktu',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Africa/Tripoli',
        label: 'Eastern European Time'
    },
    {
        id: 'Africa/Tunis',
        label: 'Central European Time'
    },
    {
        id: 'Africa/Windhoek',
        label: 'Western African Time'
    },
    {
        id: 'America/Adak',
        label: 'Hawaii Standard Time'
    },
    {
        id: 'America/Anchorage',
        label: 'Alaska Standard Time'
    },
    {
        id: 'America/Anguilla',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Antigua',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Araguaina',
        label: 'Brasilia Time'
    },
    {
        id: 'America/Argentina/Buenos_Aires',
        label: 'Argentine Time'
    },
    {
        id: 'America/Argentina/Catamarca',
        label: 'Argentine Time'
    },
    {
        id: 'America/Argentina/ComodRivadavia',
        label: 'Argentine Time'
    },
    {
        id: 'America/Argentina/Cordoba',
        label: 'Argentine Time'
    },
    {
        id: 'America/Argentina/Jujuy',
        label: 'Argentine Time'
    },
    {
        id: 'America/Argentina/La_Rioja',
        label: 'Argentine Time'
    },
    {
        id: 'America/Argentina/Mendoza',
        label: 'Argentine Time'
    },
    {
        id: 'America/Argentina/Rio_Gallegos',
        label: 'Argentine Time'
    },
    {
        id: 'America/Argentina/Salta',
        label: 'Argentine Time'
    },
    {
        id: 'America/Argentina/San_Juan',
        label: 'Argentine Time'
    },
    {
        id: 'America/Argentina/San_Luis',
        label: 'Argentine Time'
    },
    {
        id: 'America/Argentina/Tucuman',
        label: 'Argentine Time'
    },
    {
        id: 'America/Argentina/Ushuaia',
        label: 'Argentine Time'
    },
    {
        id: 'America/Aruba',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Asuncion',
        label: 'Paraguay Time'
    },
    {
        id: 'America/Atikokan',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Atka',
        label: 'Hawaii Standard Time'
    },
    {
        id: 'America/Bahia',
        label: 'Brasilia Time'
    },
    {
        id: 'America/Bahia_Banderas',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Barbados',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Belem',
        label: 'Brasilia Time'
    },
    {
        id: 'America/Belize',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Blanc-Sablon',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Boa_Vista',
        label: 'Amazon Time'
    },
    {
        id: 'America/Bogota',
        label: 'Colombia Time'
    },
    {
        id: 'America/Boise',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Buenos_Aires',
        label: 'Argentine Time'
    },
    {
        id: 'America/Cambridge_Bay',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Campo_Grande',
        label: 'Amazon Time'
    },
    {
        id: 'America/Cancun',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Caracas',
        label: 'Venezuela Time'
    },
    {
        id: 'America/Catamarca',
        label: 'Argentine Time'
    },
    {
        id: 'America/Cayenne',
        label: 'French Guiana Time'
    },
    {
        id: 'America/Cayman',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Chicago',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Chihuahua',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Coral_Harbour',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Cordoba',
        label: 'Argentine Time'
    },
    {
        id: 'America/Costa_Rica',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Creston',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Cuiaba',
        label: 'Amazon Time'
    },
    {
        id: 'America/Curacao',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Danmarkshavn',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'America/Dawson',
        label: 'Pacific Standard Time'
    },
    {
        id: 'America/Dawson_Creek',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Denver',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Detroit',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Dominica',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Edmonton',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Eirunepe',
        label: 'Acre Time'
    },
    {
        id: 'America/El_Salvador',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Ensenada',
        label: 'Pacific Standard Time'
    },
    {
        id: 'America/Fort_Nelson',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Fort_Wayne',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Fortaleza',
        label: 'Brasilia Time'
    },
    {
        id: 'America/Glace_Bay',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Godthab',
        label: 'Western Greenland Time'
    },
    {
        id: 'America/Goose_Bay',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Grand_Turk',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Grenada',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Guadeloupe',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Guatemala',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Guayaquil',
        label: 'Ecuador Time'
    },
    {
        id: 'America/Guyana',
        label: 'Guyana Time'
    },
    {
        id: 'America/Halifax',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Havana',
        label: 'Cuba Standard Time'
    },
    {
        id: 'America/Hermosillo',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Indiana/Indianapolis',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Indiana/Knox',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Indiana/Marengo',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Indiana/Petersburg',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Indiana/Tell_City',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Indiana/Vevay',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Indiana/Vincennes',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Indiana/Winamac',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Indianapolis',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Inuvik',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Iqaluit',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Jamaica',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Jujuy',
        label: 'Argentine Time'
    },
    {
        id: 'America/Juneau',
        label: 'Alaska Standard Time'
    },
    {
        id: 'America/Kentucky/Louisville',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Kentucky/Monticello',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Knox_IN',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Kralendijk',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/La_Paz',
        label: 'Bolivia Time'
    },
    {
        id: 'America/Lima',
        label: 'Peru Time'
    },
    {
        id: 'America/Los_Angeles',
        label: 'Pacific Standard Time'
    },
    {
        id: 'America/Louisville',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Lower_Princes',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Maceio',
        label: 'Brasilia Time'
    },
    {
        id: 'America/Managua',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Manaus',
        label: 'Amazon Time'
    },
    {
        id: 'America/Marigot',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Martinique',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Matamoros',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Mazatlan',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Mendoza',
        label: 'Argentine Time'
    },
    {
        id: 'America/Menominee',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Merida',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Metlakatla',
        label: 'Alaska Standard Time'
    },
    {
        id: 'America/Mexico_City',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Miquelon',
        label: 'Pierre & Miquelon Standard Time'
    },
    {
        id: 'America/Moncton',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Monterrey',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Montevideo',
        label: 'Uruguay Time'
    },
    {
        id: 'America/Montreal',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Montserrat',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Nassau',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/New_York',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Nipigon',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Nome',
        label: 'Alaska Standard Time'
    },
    {
        id: 'America/Noronha',
        label: 'Fernando de Noronha Time'
    },
    {
        id: 'America/North_Dakota/Beulah',
        label: 'Central Standard Time'
    },
    {
        id: 'America/North_Dakota/Center',
        label: 'Central Standard Time'
    },
    {
        id: 'America/North_Dakota/New_Salem',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Ojinaga',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Panama',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Pangnirtung',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Paramaribo',
        label: 'Suriname Time'
    },
    {
        id: 'America/Phoenix',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Port-au-Prince',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Port_of_Spain',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Porto_Acre',
        label: 'Acre Time'
    },
    {
        id: 'America/Porto_Velho',
        label: 'Amazon Time'
    },
    {
        id: 'America/Puerto_Rico',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Punta_Arenas',
        label: 'GMT-03:00'
    },
    {
        id: 'America/Rainy_River',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Rankin_Inlet',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Recife',
        label: 'Brasilia Time'
    },
    {
        id: 'America/Regina',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Resolute',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Rio_Branco',
        label: 'Acre Time'
    },
    {
        id: 'America/Rosario',
        label: 'Argentine Time'
    },
    {
        id: 'America/Santa_Isabel',
        label: 'Pacific Standard Time'
    },
    {
        id: 'America/Santarem',
        label: 'Brasilia Time'
    },
    {
        id: 'America/Santiago',
        label: 'Chile Time'
    },
    {
        id: 'America/Santo_Domingo',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Sao_Paulo',
        label: 'Brasilia Time'
    },
    {
        id: 'America/Scoresbysund',
        label: 'Eastern Greenland Time'
    },
    {
        id: 'America/Shiprock',
        label: 'Mountain Standard Time'
    },
    {
        id: 'America/Sitka',
        label: 'Alaska Standard Time'
    },
    {
        id: 'America/St_Barthelemy',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/St_Johns',
        label: 'Newfoundland Standard Time'
    },
    {
        id: 'America/St_Kitts',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/St_Lucia',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/St_Thomas',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/St_Vincent',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Swift_Current',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Tegucigalpa',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Thule',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Thunder_Bay',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Tijuana',
        label: 'Pacific Standard Time'
    },
    {
        id: 'America/Toronto',
        label: 'Eastern Standard Time'
    },
    {
        id: 'America/Tortola',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Vancouver',
        label: 'Pacific Standard Time'
    },
    {
        id: 'America/Virgin',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'America/Whitehorse',
        label: 'Pacific Standard Time'
    },
    {
        id: 'America/Winnipeg',
        label: 'Central Standard Time'
    },
    {
        id: 'America/Yakutat',
        label: 'Alaska Standard Time'
    },
    {
        id: 'America/Yellowknife',
        label: 'Mountain Standard Time'
    },
    {
        id: 'Antarctica/Casey',
        label: 'Australian Western Standard Time'
    },
    {
        id: 'Antarctica/Davis',
        label: 'Davis Time'
    },
    {
        id: 'Antarctica/DumontDUrville',
        label: 'Dumont-d\'Urville Time'
    },
    {
        id: 'Antarctica/Macquarie',
        label: 'Macquarie Island Standard Time'
    },
    {
        id: 'Antarctica/Mawson',
        label: 'Mawson Time'
    },
    {
        id: 'Antarctica/McMurdo',
        label: 'New Zealand Standard Time'
    },
    {
        id: 'Antarctica/Palmer',
        label: 'Chile Time'
    },
    {
        id: 'Antarctica/Rothera',
        label: 'Rothera Time'
    },
    {
        id: 'Antarctica/South_Pole',
        label: 'New Zealand Standard Time'
    },
    {
        id: 'Antarctica/Syowa',
        label: 'Syowa Time'
    },
    {
        id: 'Antarctica/Troll',
        label: 'Coordinated Universal Time'
    },
    {
        id: 'Antarctica/Vostok',
        label: 'Vostok Time'
    },
    {
        id: 'Arctic/Longyearbyen',
        label: 'Central European Time'
    },
    {
        id: 'Asia/Aden',
        label: 'Arabia Standard Time'
    },
    {
        id: 'Asia/Almaty',
        label: 'Alma-Ata Time'
    },
    {
        id: 'Asia/Amman',
        label: 'Eastern European Time'
    },
    {
        id: 'Asia/Anadyr',
        label: 'Anadyr Time'
    },
    {
        id: 'Asia/Aqtau',
        label: 'Aqtau Time'
    },
    {
        id: 'Asia/Aqtobe',
        label: 'Aqtobe Time'
    },
    {
        id: 'Asia/Ashgabat',
        label: 'Turkmenistan Time'
    },
    {
        id: 'Asia/Ashkhabad',
        label: 'Turkmenistan Time'
    },
    {
        id: 'Asia/Atyrau',
        label: 'GMT+05:00'
    },
    {
        id: 'Asia/Baghdad',
        label: 'Arabia Standard Time'
    },
    {
        id: 'Asia/Bahrain',
        label: 'Arabia Standard Time'
    },
    {
        id: 'Asia/Baku',
        label: 'Azerbaijan Time'
    },
    {
        id: 'Asia/Bangkok',
        label: 'Indochina Time'
    },
    {
        id: 'Asia/Barnaul',
        label: 'GMT+07:00'
    },
    {
        id: 'Asia/Beirut',
        label: 'Eastern European Time'
    },
    {
        id: 'Asia/Bishkek',
        label: 'Kirgizstan Time'
    },
    {
        id: 'Asia/Brunei',
        label: 'Brunei Time'
    },
    {
        id: 'Asia/Calcutta',
        label: 'India Standard Time'
    },
    {
        id: 'Asia/Chita',
        label: 'Yakutsk Time'
    },
    {
        id: 'Asia/Choibalsan',
        label: 'Choibalsan Time'
    },
    {
        id: 'Asia/Chongqing',
        label: 'China Standard Time'
    },
    {
        id: 'Asia/Chungking',
        label: 'China Standard Time'
    },
    {
        id: 'Asia/Colombo',
        label: 'India Standard Time'
    },
    {
        id: 'Asia/Dacca',
        label: 'Bangladesh Time'
    },
    {
        id: 'Asia/Damascus',
        label: 'Eastern European Time'
    },
    {
        id: 'Asia/Dhaka',
        label: 'Bangladesh Time'
    },
    {
        id: 'Asia/Dili',
        label: 'Timor-Leste Time'
    },
    {
        id: 'Asia/Dubai',
        label: 'Gulf Standard Time'
    },
    {
        id: 'Asia/Dushanbe',
        label: 'Tajikistan Time'
    },
    {
        id: 'Asia/Famagusta',
        label: 'GMT+03:00'
    },
    {
        id: 'Asia/Gaza',
        label: 'Eastern European Time'
    },
    {
        id: 'Asia/Harbin',
        label: 'China Standard Time'
    },
    {
        id: 'Asia/Hebron',
        label: 'Eastern European Time'
    },
    {
        id: 'Asia/Ho_Chi_Minh',
        label: 'Indochina Time'
    },
    {
        id: 'Asia/Hong_Kong',
        label: 'Hong Kong Time'
    },
    {
        id: 'Asia/Hovd',
        label: 'Hovd Time'
    },
    {
        id: 'Asia/Irkutsk',
        label: 'Irkutsk Time'
    },
    {
        id: 'Asia/Istanbul',
        label: 'Eastern European Time'
    },
    {
        id: 'Asia/Jakarta',
        label: 'West Indonesia Time'
    },
    {
        id: 'Asia/Jayapura',
        label: 'East Indonesia Time'
    },
    {
        id: 'Asia/Jerusalem',
        label: 'Israel Standard Time'
    },
    {
        id: 'Asia/Kabul',
        label: 'Afghanistan Time'
    },
    {
        id: 'Asia/Kamchatka',
        label: 'Petropavlovsk-Kamchatski Time'
    },
    {
        id: 'Asia/Karachi',
        label: 'Pakistan Time'
    },
    {
        id: 'Asia/Kashgar',
        label: 'Xinjiang Standard Time'
    },
    {
        id: 'Asia/Kathmandu',
        label: 'Nepal Time'
    },
    {
        id: 'Asia/Katmandu',
        label: 'Nepal Time'
    },
    {
        id: 'Asia/Khandyga',
        label: 'Yakutsk Time'
    },
    {
        id: 'Asia/Kolkata',
        label: 'India Standard Time'
    },
    {
        id: 'Asia/Krasnoyarsk',
        label: 'Krasnoyarsk Time'
    },
    {
        id: 'Asia/Kuala_Lumpur',
        label: 'Malaysia Time'
    },
    {
        id: 'Asia/Kuching',
        label: 'Malaysia Time'
    },
    {
        id: 'Asia/Kuwait',
        label: 'Arabia Standard Time'
    },
    {
        id: 'Asia/Macao',
        label: 'China Standard Time'
    },
    {
        id: 'Asia/Macau',
        label: 'China Standard Time'
    },
    {
        id: 'Asia/Magadan',
        label: 'Magadan Time'
    },
    {
        id: 'Asia/Makassar',
        label: 'Central Indonesia Time'
    },
    {
        id: 'Asia/Manila',
        label: 'Philippines Time'
    },
    {
        id: 'Asia/Muscat',
        label: 'Gulf Standard Time'
    },
    {
        id: 'Asia/Nicosia',
        label: 'Eastern European Time'
    },
    {
        id: 'Asia/Novokuznetsk',
        label: 'Krasnoyarsk Time'
    },
    {
        id: 'Asia/Novosibirsk',
        label: 'Novosibirsk Time'
    },
    {
        id: 'Asia/Omsk',
        label: 'Omsk Time'
    },
    {
        id: 'Asia/Oral',
        label: 'Oral Time'
    },
    {
        id: 'Asia/Phnom_Penh',
        label: 'Indochina Time'
    },
    {
        id: 'Asia/Pontianak',
        label: 'West Indonesia Time'
    },
    {
        id: 'Asia/Pyongyang',
        label: 'Korea Standard Time'
    },
    {
        id: 'Asia/Qatar',
        label: 'Arabia Standard Time'
    },
    {
        id: 'Asia/Qyzylorda',
        label: 'Qyzylorda Time'
    },
    {
        id: 'Asia/Rangoon',
        label: 'Myanmar Time'
    },
    {
        id: 'Asia/Riyadh',
        label: 'Arabia Standard Time'
    },
    {
        id: 'Asia/Saigon',
        label: 'Indochina Time'
    },
    {
        id: 'Asia/Sakhalin',
        label: 'Sakhalin Time'
    },
    {
        id: 'Asia/Samarkand',
        label: 'Uzbekistan Time'
    },
    {
        id: 'Asia/Seoul',
        label: 'Korea Standard Time'
    },
    {
        id: 'Asia/Shanghai',
        label: 'China Standard Time'
    },
    {
        id: 'Asia/Singapore',
        label: 'Singapore Time'
    },
    {
        id: 'Asia/Srednekolymsk',
        label: 'Srednekolymsk Time'
    },
    {
        id: 'Asia/Taipei',
        label: 'China Standard Time'
    },
    {
        id: 'Asia/Tashkent',
        label: 'Uzbekistan Time'
    },
    {
        id: 'Asia/Tbilisi',
        label: 'Georgia Time'
    },
    {
        id: 'Asia/Tehran',
        label: 'Iran Standard Time'
    },
    {
        id: 'Asia/Tel_Aviv',
        label: 'Israel Standard Time'
    },
    {
        id: 'Asia/Thimbu',
        label: 'Bhutan Time'
    },
    {
        id: 'Asia/Thimphu',
        label: 'Bhutan Time'
    },
    {
        id: 'Asia/Tokyo',
        label: 'Japan Standard Time'
    },
    {
        id: 'Asia/Tomsk',
        label: 'GMT+07:00'
    },
    {
        id: 'Asia/Ujung_Pandang',
        label: 'Central Indonesia Time'
    },
    {
        id: 'Asia/Ulaanbaatar',
        label: 'Ulaanbaatar Time'
    },
    {
        id: 'Asia/Ulan_Bator',
        label: 'Ulaanbaatar Time'
    },
    {
        id: 'Asia/Urumqi',
        label: 'Xinjiang Standard Time'
    },
    {
        id: 'Asia/Ust-Nera',
        label: 'Ust-Nera Time'
    },
    {
        id: 'Asia/Vientiane',
        label: 'Indochina Time'
    },
    {
        id: 'Asia/Vladivostok',
        label: 'Vladivostok Time'
    },
    {
        id: 'Asia/Yakutsk',
        label: 'Yakutsk Time'
    },
    {
        id: 'Asia/Yangon',
        label: 'Myanmar Time'
    },
    {
        id: 'Asia/Yekaterinburg',
        label: 'Yekaterinburg Time'
    },
    {
        id: 'Asia/Yerevan',
        label: 'Armenia Time'
    },
    {
        id: 'Atlantic/Azores',
        label: 'Azores Time'
    },
    {
        id: 'Atlantic/Bermuda',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'Atlantic/Canary',
        label: 'Western European Time'
    },
    {
        id: 'Atlantic/Cape_Verde',
        label: 'Cape Verde Time'
    },
    {
        id: 'Atlantic/Faeroe',
        label: 'Western European Time'
    },
    {
        id: 'Atlantic/Faroe',
        label: 'Western European Time'
    },
    {
        id: 'Atlantic/Jan_Mayen',
        label: 'Central European Time'
    },
    {
        id: 'Atlantic/Madeira',
        label: 'Western European Time'
    },
    {
        id: 'Atlantic/Reykjavik',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Atlantic/South_Georgia',
        label: 'South Georgia Standard Time'
    },
    {
        id: 'Atlantic/St_Helena',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Atlantic/Stanley',
        label: 'Falkland Is. Time'
    },
    {
        id: 'Australia/ACT',
        label: 'Australian Eastern Standard Time (New South Wales)'
    },
    {
        id: 'Australia/Adelaide',
        label: 'Australian Central Standard Time (South Australia)'
    },
    {
        id: 'Australia/Brisbane',
        label: 'Australian Eastern Standard Time (Queensland)'
    },
    {
        id: 'Australia/Broken_Hill',
        label: 'Australian Central Standard Time (South Australia/New South Wales)'
    },
    {
        id: 'Australia/Canberra',
        label: 'Australian Eastern Standard Time (New South Wales)'
    },
    {
        id: 'Australia/Currie',
        label: 'Australian Eastern Standard Time (New South Wales)'
    },
    {
        id: 'Australia/Darwin',
        label: 'Australian Central Standard Time (Northern Territory)'
    },
    {
        id: 'Australia/Eucla',
        label: 'Australian Central Western Standard Time'
    },
    {
        id: 'Australia/Hobart',
        label: 'Australian Eastern Standard Time (Tasmania)'
    },
    {
        id: 'Australia/LHI',
        label: 'Lord Howe Standard Time'
    },
    {
        id: 'Australia/Lindeman',
        label: 'Australian Eastern Standard Time (Queensland)'
    },
    {
        id: 'Australia/Lord_Howe',
        label: 'Lord Howe Standard Time'
    },
    {
        id: 'Australia/Melbourne',
        label: 'Australian Eastern Standard Time (Victoria)'
    },
    {
        id: 'Australia/NSW',
        label: 'Australian Eastern Standard Time (New South Wales)'
    },
    {
        id: 'Australia/North',
        label: 'Australian Central Standard Time (Northern Territory)'
    },
    {
        id: 'Australia/Perth',
        label: 'Australian Western Standard Time'
    },
    {
        id: 'Australia/Queensland',
        label: 'Australian Eastern Standard Time (Queensland)'
    },
    {
        id: 'Australia/South',
        label: 'Australian Central Standard Time (South Australia)'
    },
    {
        id: 'Australia/Sydney',
        label: 'Australian Eastern Standard Time (New South Wales)'
    },
    {
        id: 'Australia/Tasmania',
        label: 'Australian Eastern Standard Time (Tasmania)'
    },
    {
        id: 'Australia/Victoria',
        label: 'Australian Eastern Standard Time (Victoria)'
    },
    {
        id: 'Australia/West',
        label: 'Australian Western Standard Time'
    },
    {
        id: 'Australia/Yancowinna',
        label: 'Australian Central Standard Time (South Australia/New South Wales)'
    },
    {
        id: 'BET',
        label: 'Brasilia Time'
    },
    {
        id: 'BST',
        label: 'Bangladesh Time'
    },
    {
        id: 'Brazil/Acre',
        label: 'Acre Time'
    },
    {
        id: 'Brazil/DeNoronha',
        label: 'Fernando de Noronha Time'
    },
    {
        id: 'Brazil/East',
        label: 'Brasilia Time'
    },
    {
        id: 'Brazil/West',
        label: 'Amazon Time'
    },
    {
        id: 'CAT',
        label: 'Central African Time'
    },
    {
        id: 'CET',
        label: 'Central European Time'
    },
    {
        id: 'CNT',
        label: 'Newfoundland Standard Time'
    },
    {
        id: 'CST',
        label: 'Central Standard Time'
    },
    {
        id: 'CST6CDT',
        label: 'Central Standard Time'
    },
    {
        id: 'CTT',
        label: 'China Standard Time'
    },
    {
        id: 'Canada/Atlantic',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'Canada/Central',
        label: 'Central Standard Time'
    },
    {
        id: 'Canada/East-Saskatchewan',
        label: 'Central Standard Time'
    },
    {
        id: 'Canada/Eastern',
        label: 'Eastern Standard Time'
    },
    {
        id: 'Canada/Mountain',
        label: 'Mountain Standard Time'
    },
    {
        id: 'Canada/Newfoundland',
        label: 'Newfoundland Standard Time'
    },
    {
        id: 'Canada/Pacific',
        label: 'Pacific Standard Time'
    },
    {
        id: 'Canada/Saskatchewan',
        label: 'Central Standard Time'
    },
    {
        id: 'Canada/Yukon',
        label: 'Pacific Standard Time'
    },
    {
        id: 'Chile/Continental',
        label: 'Chile Time'
    },
    {
        id: 'Chile/EasterIsland',
        label: 'Easter Is. Time'
    },
    {
        id: 'Cuba',
        label: 'Cuba Standard Time'
    },
    {
        id: 'EAT',
        label: 'Eastern African Time'
    },
    {
        id: 'ECT',
        label: 'Central European Time'
    },
    {
        id: 'EET',
        label: 'Eastern European Time'
    },
    {
        id: 'EST',
        label: 'Eastern Standard Time'
    },
    {
        id: 'EST5EDT',
        label: 'Eastern Standard Time'
    },
    {
        id: 'Egypt',
        label: 'Eastern European Time'
    },
    {
        id: 'Eire',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Etc/GMT',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Etc/GMT+0',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Etc/GMT+1',
        label: 'GMT-01:00'
    },
    {
        id: 'Etc/GMT+10',
        label: 'GMT-10:00'
    },
    {
        id: 'Etc/GMT+11',
        label: 'GMT-11:00'
    },
    {
        id: 'Etc/GMT+12',
        label: 'GMT-12:00'
    },
    {
        id: 'Etc/GMT+2',
        label: 'GMT-02:00'
    },
    {
        id: 'Etc/GMT+3',
        label: 'GMT-03:00'
    },
    {
        id: 'Etc/GMT+4',
        label: 'GMT-04:00'
    },
    {
        id: 'Etc/GMT+5',
        label: 'GMT-05:00'
    },
    {
        id: 'Etc/GMT+6',
        label: 'GMT-06:00'
    },
    {
        id: 'Etc/GMT+7',
        label: 'GMT-07:00'
    },
    {
        id: 'Etc/GMT+8',
        label: 'GMT-08:00'
    },
    {
        id: 'Etc/GMT+9',
        label: 'GMT-09:00'
    },
    {
        id: 'Etc/GMT-0',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Etc/GMT-1',
        label: 'GMT+01:00'
    },
    {
        id: 'Etc/GMT-10',
        label: 'GMT+10:00'
    },
    {
        id: 'Etc/GMT-11',
        label: 'GMT+11:00'
    },
    {
        id: 'Etc/GMT-12',
        label: 'GMT+12:00'
    },
    {
        id: 'Etc/GMT-13',
        label: 'GMT+13:00'
    },
    {
        id: 'Etc/GMT-14',
        label: 'GMT+14:00'
    },
    {
        id: 'Etc/GMT-2',
        label: 'GMT+02:00'
    },
    {
        id: 'Etc/GMT-3',
        label: 'GMT+03:00'
    },
    {
        id: 'Etc/GMT-4',
        label: 'GMT+04:00'
    },
    {
        id: 'Etc/GMT-5',
        label: 'GMT+05:00'
    },
    {
        id: 'Etc/GMT-6',
        label: 'GMT+06:00'
    },
    {
        id: 'Etc/GMT-7',
        label: 'GMT+07:00'
    },
    {
        id: 'Etc/GMT-8',
        label: 'GMT+08:00'
    },
    {
        id: 'Etc/GMT-9',
        label: 'GMT+09:00'
    },
    {
        id: 'Etc/GMT0',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Etc/Greenwich',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Etc/UCT',
        label: 'Coordinated Universal Time'
    },
    {
        id: 'Etc/UTC',
        label: 'Coordinated Universal Time'
    },
    {
        id: 'Etc/Universal',
        label: 'Coordinated Universal Time'
    },
    {
        id: 'Etc/Zulu',
        label: 'Coordinated Universal Time'
    },
    {
        id: 'Europe/Amsterdam',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Andorra',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Astrakhan',
        label: 'GMT+04:00'
    },
    {
        id: 'Europe/Athens',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Belfast',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Europe/Belgrade',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Berlin',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Bratislava',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Brussels',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Bucharest',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Budapest',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Busingen',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Chisinau',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Copenhagen',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Dublin',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Europe/Gibraltar',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Guernsey',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Europe/Helsinki',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Isle_of_Man',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Europe/Istanbul',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Jersey',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Europe/Kaliningrad',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Kiev',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Kirov',
        label: 'GMT+03:00'
    },
    {
        id: 'Europe/Lisbon',
        label: 'Western European Time'
    },
    {
        id: 'Europe/Ljubljana',
        label: 'Central European Time'
    },
    {
        id: 'Europe/London',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Europe/Luxembourg',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Madrid',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Malta',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Mariehamn',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Minsk',
        label: 'Moscow Standard Time'
    },
    {
        id: 'Europe/Monaco',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Moscow',
        label: 'Moscow Standard Time'
    },
    {
        id: 'Europe/Nicosia',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Oslo',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Paris',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Podgorica',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Prague',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Riga',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Rome',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Samara',
        label: 'Samara Time'
    },
    {
        id: 'Europe/San_Marino',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Sarajevo',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Saratov',
        label: 'GMT+04:00'
    },
    {
        id: 'Europe/Simferopol',
        label: 'Moscow Standard Time'
    },
    {
        id: 'Europe/Skopje',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Sofia',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Stockholm',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Tallinn',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Tirane',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Tiraspol',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Ulyanovsk',
        label: 'GMT+04:00'
    },
    {
        id: 'Europe/Uzhgorod',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Vaduz',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Vatican',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Vienna',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Vilnius',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Volgograd',
        label: 'Moscow Standard Time'
    },
    {
        id: 'Europe/Warsaw',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Zagreb',
        label: 'Central European Time'
    },
    {
        id: 'Europe/Zaporozhye',
        label: 'Eastern European Time'
    },
    {
        id: 'Europe/Zurich',
        label: 'Central European Time'
    },
    {
        id: 'GB',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'GB-Eire',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'GMT',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'GMT0',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Greenwich',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'HST',
        label: 'Hawaii Standard Time'
    },
    {
        id: 'Hongkong',
        label: 'Hong Kong Time'
    },
    {
        id: 'IET',
        label: 'Eastern Standard Time'
    },
    {
        id: 'IST',
        label: 'India Standard Time'
    },
    {
        id: 'Iceland',
        label: 'Greenwich Mean Time'
    },
    {
        id: 'Indian/Antananarivo',
        label: 'Eastern African Time'
    },
    {
        id: 'Indian/Chagos',
        label: 'Indian Ocean Territory Time'
    },
    {
        id: 'Indian/Christmas',
        label: 'Christmas Island Time'
    },
    {
        id: 'Indian/Cocos',
        label: 'Cocos Islands Time'
    },
    {
        id: 'Indian/Comoro',
        label: 'Eastern African Time'
    },
    {
        id: 'Indian/Kerguelen',
        label: 'French Southern & Antarctic Lands Time'
    },
    {
        id: 'Indian/Mahe',
        label: 'Seychelles Time'
    },
    {
        id: 'Indian/Maldives',
        label: 'Maldives Time'
    },
    {
        id: 'Indian/Mauritius',
        label: 'Mauritius Time'
    },
    {
        id: 'Indian/Mayotte',
        label: 'Eastern African Time'
    },
    {
        id: 'Indian/Reunion',
        label: 'Reunion Time'
    },
    {
        id: 'Iran',
        label: 'Iran Standard Time'
    },
    {
        id: 'Israel',
        label: 'Israel Standard Time'
    },
    {
        id: 'JST',
        label: 'Japan Standard Time'
    },
    {
        id: 'Jamaica',
        label: 'Eastern Standard Time'
    },
    {
        id: 'Japan',
        label: 'Japan Standard Time'
    },
    {
        id: 'Kwajalein',
        label: 'Marshall Islands Time'
    },
    {
        id: 'Libya',
        label: 'Eastern European Time'
    },
    {
        id: 'MET',
        label: 'Middle Europe Time'
    },
    {
        id: 'MIT',
        label: 'West Samoa Standard Time'
    },
    {
        id: 'MST',
        label: 'Mountain Standard Time'
    },
    {
        id: 'MST7MDT',
        label: 'Mountain Standard Time'
    },
    {
        id: 'Mexico/BajaNorte',
        label: 'Pacific Standard Time'
    },
    {
        id: 'Mexico/BajaSur',
        label: 'Mountain Standard Time'
    },
    {
        id: 'Mexico/General',
        label: 'Central Standard Time'
    },
    {
        id: 'NET',
        label: 'Armenia Time'
    },
    {
        id: 'NST',
        label: 'New Zealand Standard Time'
    },
    {
        id: 'NZ',
        label: 'New Zealand Standard Time'
    },
    {
        id: 'NZ-CHAT',
        label: 'Chatham Standard Time'
    },
    {
        id: 'Navajo',
        label: 'Mountain Standard Time'
    },
    {
        id: 'PLT',
        label: 'Pakistan Time'
    },
    {
        id: 'PNT',
        label: 'Mountain Standard Time'
    },
    {
        id: 'PRC',
        label: 'China Standard Time'
    },
    {
        id: 'PRT',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'PST',
        label: 'Pacific Standard Time'
    },
    {
        id: 'PST8PDT',
        label: 'Pacific Standard Time'
    },
    {
        id: 'Pacific/Apia',
        label: 'West Samoa Standard Time'
    },
    {
        id: 'Pacific/Auckland',
        label: 'New Zealand Standard Time'
    },
    {
        id: 'Pacific/Bougainville',
        label: 'Bougainville Standard Time'
    },
    {
        id: 'Pacific/Chatham',
        label: 'Chatham Standard Time'
    },
    {
        id: 'Pacific/Chuuk',
        label: 'Chuuk Time'
    },
    {
        id: 'Pacific/Easter',
        label: 'Easter Is. Time'
    },
    {
        id: 'Pacific/Efate',
        label: 'Vanuatu Time'
    },
    {
        id: 'Pacific/Enderbury',
        label: 'Phoenix Is. Time'
    },
    {
        id: 'Pacific/Fakaofo',
        label: 'Tokelau Time'
    },
    {
        id: 'Pacific/Fiji',
        label: 'Fiji Time'
    },
    {
        id: 'Pacific/Funafuti',
        label: 'Tuvalu Time'
    },
    {
        id: 'Pacific/Galapagos',
        label: 'Galapagos Time'
    },
    {
        id: 'Pacific/Gambier',
        label: 'Gambier Time'
    },
    {
        id: 'Pacific/Guadalcanal',
        label: 'Solomon Is. Time'
    },
    {
        id: 'Pacific/Guam',
        label: 'Chamorro Standard Time'
    },
    {
        id: 'Pacific/Honolulu',
        label: 'Hawaii Standard Time'
    },
    {
        id: 'Pacific/Johnston',
        label: 'Hawaii Standard Time'
    },
    {
        id: 'Pacific/Kiritimati',
        label: 'Line Is. Time'
    },
    {
        id: 'Pacific/Kosrae',
        label: 'Kosrae Time'
    },
    {
        id: 'Pacific/Kwajalein',
        label: 'Marshall Islands Time'
    },
    {
        id: 'Pacific/Majuro',
        label: 'Marshall Islands Time'
    },
    {
        id: 'Pacific/Marquesas',
        label: 'Marquesas Time'
    },
    {
        id: 'Pacific/Midway',
        label: 'Samoa Standard Time'
    },
    {
        id: 'Pacific/Nauru',
        label: 'Nauru Time'
    },
    {
        id: 'Pacific/Niue',
        label: 'Niue Time'
    },
    {
        id: 'Pacific/Norfolk',
        label: 'Norfolk Time'
    },
    {
        id: 'Pacific/Noumea',
        label: 'New Caledonia Time'
    },
    {
        id: 'Pacific/Pago_Pago',
        label: 'Samoa Standard Time'
    },
    {
        id: 'Pacific/Palau',
        label: 'Palau Time'
    },
    {
        id: 'Pacific/Pitcairn',
        label: 'Pitcairn Standard Time'
    },
    {
        id: 'Pacific/Pohnpei',
        label: 'Pohnpei Time'
    },
    {
        id: 'Pacific/Ponape',
        label: 'Pohnpei Time'
    },
    {
        id: 'Pacific/Port_Moresby',
        label: 'Papua New Guinea Time'
    },
    {
        id: 'Pacific/Rarotonga',
        label: 'Cook Is. Time'
    },
    {
        id: 'Pacific/Saipan',
        label: 'Chamorro Standard Time'
    },
    {
        id: 'Pacific/Samoa',
        label: 'Samoa Standard Time'
    },
    {
        id: 'Pacific/Tahiti',
        label: 'Tahiti Time'
    },
    {
        id: 'Pacific/Tarawa',
        label: 'Gilbert Is. Time'
    },
    {
        id: 'Pacific/Tongatapu',
        label: 'Tonga Time'
    },
    {
        id: 'Pacific/Truk',
        label: 'Chuuk Time'
    },
    {
        id: 'Pacific/Wake',
        label: 'Wake Time'
    },
    {
        id: 'Pacific/Wallis',
        label: 'Wallis & Futuna Time'
    },
    {
        id: 'Pacific/Yap',
        label: 'Chuuk Time'
    },
    {
        id: 'Poland',
        label: 'Central European Time'
    },
    {
        id: 'Portugal',
        label: 'Western European Time'
    },
    {
        id: 'ROK',
        label: 'Korea Standard Time'
    },
    {
        id: 'SST',
        label: 'Solomon Is. Time'
    },
    {
        id: 'Singapore',
        label: 'Singapore Time'
    },
    {
        id: 'SystemV/AST4',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'SystemV/AST4ADT',
        label: 'Atlantic Standard Time'
    },
    {
        id: 'SystemV/CST6',
        label: 'Central Standard Time'
    },
    {
        id: 'SystemV/CST6CDT',
        label: 'Central Standard Time'
    },
    {
        id: 'SystemV/EST5',
        label: 'Eastern Standard Time'
    },
    {
        id: 'SystemV/EST5EDT',
        label: 'Eastern Standard Time'
    },
    {
        id: 'SystemV/HST10',
        label: 'Hawaii Standard Time'
    },
    {
        id: 'SystemV/MST7',
        label: 'Mountain Standard Time'
    },
    {
        id: 'SystemV/MST7MDT',
        label: 'Mountain Standard Time'
    },
    {
        id: 'SystemV/PST8',
        label: 'Pacific Standard Time'
    },
    {
        id: 'SystemV/PST8PDT',
        label: 'Pacific Standard Time'
    },
    {
        id: 'SystemV/YST9',
        label: 'Alaska Standard Time'
    },
    {
        id: 'SystemV/YST9YDT',
        label: 'Alaska Standard Time'
    },
    {
        id: 'Turkey',
        label: 'Eastern European Time'
    },
    {
        id: 'UCT',
        label: 'Coordinated Universal Time'
    },
    {
        id: 'US/Alaska',
        label: 'Alaska Standard Time'
    },
    {
        id: 'US/Aleutian',
        label: 'Hawaii Standard Time'
    },
    {
        id: 'US/Arizona',
        label: 'Mountain Standard Time'
    },
    {
        id: 'US/Central',
        label: 'Central Standard Time'
    },
    {
        id: 'US/East-Indiana',
        label: 'Eastern Standard Time'
    },
    {
        id: 'US/Eastern',
        label: 'Eastern Standard Time'
    },
    {
        id: 'US/Hawaii',
        label: 'Hawaii Standard Time'
    },
    {
        id: 'US/Indiana-Starke',
        label: 'Central Standard Time'
    },
    {
        id: 'US/Michigan',
        label: 'Eastern Standard Time'
    },
    {
        id: 'US/Mountain',
        label: 'Mountain Standard Time'
    },
    {
        id: 'US/Pacific',
        label: 'Pacific Standard Time'
    },
    {
        id: 'US/Pacific-New',
        label: 'Pacific Standard Time'
    },
    {
        id: 'US/Samoa',
        label: 'Samoa Standard Time'
    },
    {
        id: 'UTC',
        label: 'Coordinated Universal Time'
    },
    {
        id: 'Universal',
        label: 'Coordinated Universal Time'
    },
    {
        id: 'VST',
        label: 'Indochina Time'
    },
    {
        id: 'W-SU',
        label: 'Moscow Standard Time'
    },
    {
        id: 'WET',
        label: 'Western European Time'
    },
    {
        id: 'Zulu',
        label: 'Coordinated Universal Time'
    },
]