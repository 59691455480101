/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../ui/APDescriptor";

export const MEmpty: APDescriptor = {
    type: 'MEmpty',
    id: 'emptyProeprtiesDescriptor',
    layouts: [],
};
