/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MGraphicElement } from "../common/MGraphicElement";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { ICONS } from "@jss/js-common";
import { EvaluationTimeEnum } from "../enums/EvaluationTimeEnum";
import { OnErrorTypeEnum } from "../enums/OnErrorTypeEnum";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PEnum } from "../../ui/primitive/UPEnum";
import { MapTypeEnum } from "../enums/MapTypeEnum";
import { MMapMarker } from "./MMapMarker";
import { MMapPath } from "./MMapPath";
import { MMapStyle } from "./MMapStyle";
import { PConnectedTableDetail } from "../../ui/tables/UPConnectedTableDetail";
import { MMapCustom } from "./MMapCustom";
import { PImplementationCombo } from "../../ui/primitive/UPImplementationCombo";
import { MMapLegend, MMapReset } from "./MMapLegendReset";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PAccordeon } from "../../ui/composite/UPAccordeon";

export const FMap = {
    evaluationTime: EvaluationTimeEnum,
    onErrorType: OnErrorTypeEnum,

    latitudeExpression: new PExpression({ id: 'c:latitudeExpression', label: 'Latitude' }),
    longitudeExpression: new PExpression({ id: 'c:longitudeExpression', label: 'Longitude' }),
    addressExpression: new PExpression({ id: 'c:addressExpression', label: 'Address' }),
    zoomExpression: new PExpression({ id: 'c:zoomExpression', label: 'Zoom' }),
    languageExpression: new PExpression({ id: 'c:languageExpression', label: 'Language' }),

    mapScale: new PEnum({ id: 'mapScale', label: 'Map Scale', items: [{ key: '1', value: '1' }, { key: '2', value: '2' }, { key: '4', value: '4' }], default: '1' }),
    imageType: new PEnum({ id: 'imageType', label: 'Image Type', items: [{ key: 'png', value: 'png' }, { key: 'png8', value: 'png8' }, { key: 'png32', value: 'png32' }, { key: 'gif', value: 'gif' }, { key: 'jpg', value: 'jpg' }, { key: 'jpg-baseline', value: 'jpg-baseline' }], default: 'png' }),
    mapType: MapTypeEnum,

};
const MapImplCombo = new PImplementationCombo({types: [{type: ElementTypes.MAP, id: 'googleMapPropertiesDescriptor', label: 'Google Map'}, {type: ElementTypes.TIBCO_MAP, id: 'tibcoMapPropertiesDescriptor', label: 'Tibco Map'}]})

export const MMap: APDescriptor = {
    id: ElementTypes.MAP,
    type: ElementTypes.MAP,
    label: 'Map',
    icon: ICONS.MAP_ICON,
    default: {
        MAP_XMLNS_NAMESPACE: 'c',
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:c': "http://jasperreports.sourceforge.net/jasperreports/components",
        width: 400, height: 150, 'c:latitudeExpression': '45.433968f', 'c:longitudeExpression': '12.337967f'
    },
    layouts: [new PScrollableCategories({
        id: 'mapPropertiesTabsDescriptor',
        layouts: [
            new PDiv({ id: 'appearance', label: 'Appearance', layouts: [...MGraphicElement.layouts, MMapCustom] }),
            new PDiv({
                id: 'map',
                label: 'Map', layouts: [
                    FMap.latitudeExpression, FMap.longitudeExpression, FMap.addressExpression, FMap.zoomExpression, FMap.languageExpression,
                    FMap.mapType,
                    FMap.mapScale, FMap.imageType,
                    FMap.evaluationTime, FMap.onErrorType,
                    MapImplCombo
                ]
            }),
            new PDiv({
                id: 'mkdata', label: 'Markers', layouts: [
                    new PBoolean({ id: "markerClustering", label: 'Marker Clustering', description: 'Marker Clustering', canBeNull: true}),
                    new PBoolean({ id: "markerSpidering", label: 'Marker Spidering', description: 'Marker Spidering', canBeNull: true }),
                    new PConnectedTableDetail({
                        id: 'markerDatas', label: 'Markers By Dataset', rowLabel: 'Set Of Markers', expanded: true, layouts: [MMapMarker],
                        default: { id: 'marker-1' }
                    })]
            }),
            new PDiv({
                id: 'pathsdata', label: 'Paths', layouts: [
                    new PConnectedTableDetail({
                        id: 'pathDatas', label: 'Paths By Dataset', rowLabel: 'Set Of Paths', expanded: true, layouts: [MMapPath],
                        default: { id: 'path-1' }
                    })]
            }),
            new PDiv({
                id: 'stylesdata', label: 'Styles', layouts: [
                    new PConnectedTableDetail({
                        id: 'styleDatas', label: 'Styles By Dataset', rowLabel: 'Set Of Styles', expanded: true, layouts: [MMapStyle],
                        default: { id: 'style-1' }
                    })]
            }),
            new PDiv({
                id: 'stylesdata', label: 'Legend & Reset', layouts: [
                    new PAccordeon({
                        id: 'legendItems', label: 'Legend Items', expanded: true, layouts: [MMapLegend],
                        default: { id: 'legend-1' }
                    }),
                    new PAccordeon({
                        id: 'resetMapItems', label: 'Reset Items', expanded: true, layouts: [MMapReset],
                        default: { id: 'reset-1' },
                    })]
            })
        ]
    })]
};
