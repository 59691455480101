/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor, SubtypedElement } from "../../ui/APDescriptor";
import { MBoxGraphicElement } from "../common/MGraphicElement";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { ICONS } from "@jss/js-common";
import { PAccordeonCategoriesByID } from "../../ui/composite/UPAccordeonCategories";
import { MAreaChart, MBar3DChart, MBarChart, MBubbleChart, MCandleStickChart, MGanttChart, MHighLowChart, MLineChart, MMeterChart, MMultiAxisChart, MPie3DChart, MPieChart, MScatterChart, MSpiderChart, MStackedAreaChart, MStackedBar3DChart, MstackedBarChart, MThermometerChart, MTimeSeriesChart, MXYAreaChart, MXYBarChart, MXYLineChart } from "./MChart";
import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { MChartDataset } from "./dataset/MChartDataset";
import { MCategorySeries } from "./dataset/series/MCategorySeries";
import { MXYSeries } from "./dataset/series/MXYSeries";
import { MXYZSeries } from "./dataset/series/MXYZSeries";
import { MTimeSeriesDataset } from "./dataset/MTimeSeriesDataset";
import { MPieDataset } from "./dataset/MPieDataset";
import { MTimePeriodSeries } from "./dataset/series/MTimePeriodSeries";
import { MTimeSeries } from "./dataset/series/MTimeSeries";
import { MPieSeries } from "./dataset/series/MPieSeries";
import { MGanttSeries } from "./dataset/series/MGanttSeries";
import { MValueDataset } from "./dataset/MValueDataset";
import { MHighLowDataset } from "./dataset/MHighLowDataset";
import { PChartDatasets, PChartPlots } from "./dataset/UPChartTypes";
import { PConnectedTableDetail } from "../../ui/tables/UPConnectedTableDetail";
import { AxisPositionEnum } from "../enums/AxisPositionEnum";
import { PCategories } from "../../ui/composite/UPCategories";
import { MChartCustom } from "./MChartCustom";


export const FJFreeChart = {
};

export const MChartCategoryDataset = { type: 'MChartCategoryDataset', id: 'categoryDataset', path: 'categoryDataset', layouts: [{ ...MChartDataset, path: 'dataset' }, MCategorySeries] };
export const MChartXYDataset = { type: 'MChartXYDataset', id: 'xyDataset', path: 'xyDataset', layouts: [{ ...MChartDataset, path: 'dataset' }, MXYSeries] };
export const MChartXYZDataset = { type: 'MChartXYZDataset', id: 'xyzDataset', path: 'xyzDataset', layouts: [{ ...MChartDataset, path: 'dataset' }, MXYZSeries] };
export const MChartTimePeriodDataset = { type: 'MChartTimePeriodDataset', id: 'timePeriodDataset', path: 'timePeriodDataset', layouts: [{ ...MChartDataset, path: 'dataset' }, MTimePeriodSeries] };
export const MChartTimeDataset = { type: 'MChartTimeDataset', id: 'timeSeriesDataset', path: 'timeSeriesDataset', layouts: [{ ...MTimeSeriesDataset, path: 'dataset' }, MTimeSeries] };
export const MChartPieDataset = { type: 'MChartPieDataset', id: 'pieDataset', path: 'pieDataset', layouts: [{ ...MPieDataset, path: 'dataset' }, MPieSeries] };
export const MChartGanttDataset = { type: 'MChartGanttDataset', id: 'ganttDataset', path: 'ganttDataset', layouts: [{ ...MChartDataset, path: 'dataset' }, MGanttSeries] };
export const MChartValueDataset = { type: 'MChartValueDataset', id: 'valueDataset', path: 'valueDataset', layouts: [{ ...MValueDataset, path: 'dataset' }] };
export const MChartHighLowDataset = { type: 'MChartHighLowDataset', id: 'highLowDataset', path: 'highLowDataset', layouts: [{ ...MHighLowDataset, path: 'dataset' }, MCategorySeries] };
export const MChartSpiderDataset = { type: 'MChartSpiderDataset', id: 'spiderDataset', path: 'spiderDataset', layouts: [{ ...MChartDataset, path: 'dataset' }, MCategorySeries] };
export const MMultiAxis: APDescriptor = {
    type: 'MMultiAxis', id: 'multiaxis', path: 'multiAxisPlot',
    layouts: [new PConnectedTableDetail({
        id: 'axis', label: 'Chart Axis', rowLabel: 'Chart Axis', expanded: true, layouts: [new PCategories({
            id: 'multiAxisChartsPropertiesTabsDescriptor',
            layouts: [
                new PDiv({
                    id: 'multiaxis.subchart',
                    label: 'Chart',
                    layouts: [AxisPositionEnum, new PAccordeonCategoriesByID({
                        label: 'Chart Types', path: 'subchart', selectionField: 'CHART_TYPE',
                        categories: [MAreaChart, MStackedAreaChart, MXYAreaChart,
                            MBarChart, MBar3DChart, MXYBarChart, MstackedBarChart, MStackedBar3DChart,
                            MBubbleChart,
                            MCandleStickChart, MHighLowChart,
                            MLineChart, MXYLineChart,
                            MScatterChart,
                            MTimeSeriesChart,
                        ]
                    })]
                }),
                new PChartPlots({ id: 'multiAxis.subchart.plots', path: 'subchart' }),
                new PChartDatasets({
                    id: 'multiAxis.subchart.datasets', path: 'subchart',
                    layouts: [MChartHighLowDataset, MChartTimeDataset, MChartXYDataset, MChartXYZDataset, MChartTimePeriodDataset, MChartCategoryDataset]
                })
            ]
        })],
        default: { id: 'entity-1' }
    })]
};

export const MJFreeChart: SubtypedElement = {
    type: ElementTypes.JFREECHART,
    label: 'Chart',
    id: 'jFreeChartPropertiesDescriptor',
    icon: ICONS.CHART_ICON,
    subtypes: [
        MAreaChart, MStackedAreaChart, MXYAreaChart,
        MBarChart, MBar3DChart, MXYBarChart, MstackedBarChart, MStackedBar3DChart,
        MBubbleChart,
        MCandleStickChart,
        MGanttChart,
        MHighLowChart,
        MLineChart, MXYLineChart,
        MMeterChart,
        MMultiAxisChart,
        MPieChart, MPie3DChart,
        MScatterChart,
        MThermometerChart,
        MTimeSeriesChart,
        MSpiderChart
    ],
    default: { width: 200, height: 200, ...MAreaChart.default },
    layouts: [new PScrollableCategories({
        id: 'jfreechartPropertiesTabsDescriptor',
        layouts: [new PDiv({ label: 'Appearance', layouts: [...MBoxGraphicElement.layouts, MChartCustom] }),
        new PAccordeonCategoriesByID({
            id: 'chart.types',
            label: 'Chart Types', selectionField: 'CHART_TYPE',
            categories: [MAreaChart, MStackedAreaChart, MXYAreaChart,
                MBarChart, MBar3DChart, MXYBarChart, MstackedBarChart, MStackedBar3DChart,
                MBubbleChart,
                MCandleStickChart,
                MGanttChart,
                MHighLowChart,
                MLineChart, MXYLineChart,
                MMeterChart,
                MMultiAxisChart,
                MPieChart, MPie3DChart,
                MScatterChart,
                MThermometerChart,
                MTimeSeriesChart,
                MSpiderChart
            ]
        }),
        new PChartPlots({ id: 'chart.plots', }),
        new PChartDatasets({
            id: 'chart.datasets',
            layouts: [MChartHighLowDataset, MChartTimeDataset, MChartXYDataset, MChartXYZDataset, MChartTimePeriodDataset, MChartCategoryDataset, MChartValueDataset, MChartPieDataset, MChartGanttDataset, MChartSpiderDataset, MMultiAxis],
            mapping: { [MMultiAxisChart.id]: MMultiAxis }
        })]
    })]
};

