/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';

import { createObjectAbsolutePositionFinder, IPositionFinder } from '../../../sagas/report/designer/documentUtils';


type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Subtract<T, K> = Omit<T, keyof K>;

export interface IWithPositionFinder {
  positionFinder?: IPositionFinder
}

export const PositionFinderContext = React.createContext(createObjectAbsolutePositionFinder());

const withPositionFinder = <P extends IWithPositionFinder>(Component: React.ComponentType<P>) =>
    class PositionFinder extends React.Component<Subtract<P, IWithPositionFinder>> {

          public render() {
            return (
              <PositionFinderContext.Consumer>
                {positionFinder => <Component {...this.props as P} positionFinder={positionFinder} />}
              </PositionFinderContext.Consumer>
            );
          }
    }

export default withPositionFinder;
