/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
    TextField as MuiTextField,
    FormHelperText,
} from '@material-ui/core';
import {
    INLINE_CLASS, SizeToClass, WidthToClass, LABEL_CONTAINED
} from '../types/InputTypes';
import FormControlLabel from '../_from_mui_src/FormControlLabel/FormControlLabel';
import '../../assets/uxpl/css/FormControlLabel.css';
import { TextFieldProps } from './TextField';
import { ValidationResult, VALIDATION_RESULT } from '../../utils/validators';
import { InputAdornment } from '@material-ui/core';

export interface FormControlledTextFieldProps {
    validator?: (string) => ValidationResult,
    iconButtons?: React.ReactElement[],
    iconInnerButtons?: React.ReactElement[],
    inputStyle?: React.CSSProperties,
}

export const FormControlledTextField = forwardRef<HTMLDivElement, TextFieldProps & FormControlledTextFieldProps>(({
    size = 'small',
    width = 'normal',
    labelContained = false,
    InputLabelProps = {},
    InputProps = {},
    FormHelperTextProps = {},
    FormErrorTextProps = {},
    SelectProps = {},
    className = '',
    textFieldClassName = '',
    select,
    inline,
    id,
    errorId,
    helperText,
    TextFieldPrefix,
    TextFieldSuffix,
    WrapperProps,
    value,
    onTextChange,
    onBlur,
    onFocus,
    classes = {},
    inputRef,
    validator,
    iconButtons,
    inputProps,
    iconInnerButtons,
    inputStyle,
    ...rest
}, ref) => {
    const [textValue, setTextValue] = useState<{ currentValue: string | undefined, oldValue: string | undefined }>({ currentValue: undefined, oldValue: undefined });
    //const [isFocused, setFocusValue] = useState<boolean>(false);
    const innerInputRef = useRef<HTMLInputElement>(null);
    const inlineClass = inline ? INLINE_CLASS : '';
    const labelContainedClass = labelContained ? LABEL_CONTAINED : '';
    const inputSelectClass = select ? 'jr-mInputSelect' : '';
    const { classes: inputLabelPropsClasses = {}, ...InputLabelPropsRest } = InputLabelProps;
    const { classes: inputPropsClasses = {}, ...InputPropsRest } = InputProps;
    const { classes: selectPropsClasses = {}, ...SelectPropsRest } = SelectProps;
    const { classes: helperTextPropsClasses = {}, ...HelperTextPropsRest } = FormHelperTextProps;
    const { className: errorTextPropsClassName = '', ...ErrorTextPropsRest } = FormErrorTextProps;

    const validationResult = validator ? validator(textValue.currentValue) : { message: undefined, result: VALIDATION_RESULT.VALID }

    const hasErrorText: boolean = validationResult.message !== undefined

    const errorTextId = errorId ?? (hasErrorText && id ? `${id}-error-text` : undefined);
    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

    const inputLabelProps = {
        classes: { root: `jr-mInput-label mui ${inputLabelPropsClasses?.root ?? ''}`, ...inputLabelPropsClasses },
        disableAnimation: true,
        ...InputLabelPropsRest
    };

    const inputAriaDescribedBy = [helperTextId, errorTextId].reduce((acc, textId) => {
        const accWithSpace = acc ? `${acc} ` : '';
        return textId ? `${accWithSpace}${textId}` : acc;
    }, '');

    const InputPropsMui = select ? {} : {
        classes: { input: `jr-mInput-text mui ${inputPropsClasses?.input ?? ''}`, ...inputPropsClasses },
        ...(inputAriaDescribedBy ? { 'aria-describedby': inputAriaDescribedBy } : {}),
        'aria-invalid': hasErrorText,
        ...InputPropsRest,
        endAdornment: iconInnerButtons ?
            <InputAdornment
                position="end"
                className={"jr-mInputSearch-adornment mui"}
            >
                {iconInnerButtons}
            </InputAdornment> : InputPropsRest.endAdornment,
    };
    const iconClassName = iconInnerButtons ? 'jr-formControlledTextFieldContainerIcon' : '';

    const selectProps = {
        classes: { root: `jr-mInput-select mui ${selectPropsClasses?.root ?? ''}`, ...selectPropsClasses },
        ...(inputAriaDescribedBy ? { 'aria-describedby': inputAriaDescribedBy } : {}),
        ...SelectPropsRest
    };

    const helperTextProps = {
        classes: { root: `jr-mInput-helper mui ${helperTextPropsClasses?.root ?? ''}`, ...helperTextPropsClasses },
        ...HelperTextPropsRest
    };

    //little hack here, there is a known bug between react and the input control. If the value of the input is set
    //with the value it will reset the cursor at every change of the value, essentially react lose the information 
    //about the cursor between a render to another. There is a workaround, use the defaultValue instead of the value,
    //this workaround doesn't however work in any case because even if the passed value is correct sometime the widget
    //is not updated. The most reliable workaround is always use the exact same string of the change event to be store in the model
    //however in some cases the string is manipulated/copied and this is not reliable in an external model. For this
    //reason an internal model as state is used, and it will keep the value from the change event
    if (textValue.currentValue !== value && textValue.oldValue !== value) {
        setTextValue({ currentValue: (value as string | undefined), oldValue: (value as string | undefined) });
    } else if (textValue.currentValue === value && textValue.oldValue !== value) {
        setTextValue({ currentValue: (value as string | undefined), oldValue: (value as string | undefined) });
    }

    const innerOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.currentTarget.value;
        const newValidationResult = validator ? validator(newValue) : { message: undefined, result: VALIDATION_RESULT.VALID }
        let cursorPosition = event.currentTarget.selectionStart;
        if (newValidationResult.result !== VALIDATION_RESULT.INVALID) {
            //set the inner value only if the validation could accept it
            setTextValue({ currentValue: newValue, oldValue: value as string | undefined });
        } else {
            if (cursorPosition !== null){
                cursorPosition -= 1;
            }
            if (inputRef !== null && inputRef){
               (inputRef as any).current?.setSelectionRange(2, 2);
            } else if (innerInputRef !== null && innerInputRef){
                setTimeout(() => {
                    (innerInputRef as any).current?.setSelectionRange(cursorPosition, cursorPosition);
                })
             }
        }
    }

    const internalBlur = (event) => {
        setTextValue({ currentValue: value as string, oldValue: value as string });
        //setFocusValue(false);
        if (onBlur) {
            onBlur(event);
        }
    }

    const internalFocus = (event) => {
       // setFocusValue(true);
        if (onFocus) {
            onFocus(event);
        }
    }

    useEffect(() => {
        if (textValue.currentValue !== value && onTextChange) {
            if (validationResult.result === VALIDATION_RESULT.VALID) {
                onTextChange(textValue.currentValue);
            }
        }
    }, [textValue, onTextChange, value, validationResult.result]);

    /*useEffect(() => {
        return () => {
            if (isFocused && onBlur){
                onBlur((undefined as unknown) as any);
            }
        }
    }, [])*/
    const usedInputStyle = {margin: 0, ...inputStyle};
    const control = <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <div ref={ref} className={`${className} jr-formControlledTextFieldTextWrapper`} {...WrapperProps}>
            {TextFieldPrefix}
            <MuiTextField
                id={id}
                select={select}
                helperText={helperText}
                variant="outlined"
                className={`jr-mInput jr-mInputText mui ${inputSelectClass} ${SizeToClass[size]} ${inlineClass} ${WidthToClass[width]} ${labelContainedClass} ${textFieldClassName} ${iconClassName}`}
                InputLabelProps={inputLabelProps}
                InputProps={InputPropsMui}
                inputProps={inputProps}
                SelectProps={selectProps}
                FormHelperTextProps={helperTextProps}
                value={textValue.currentValue}
                onChange={innerOnChange}
                inputRef={inputRef ? inputRef : innerInputRef}
                onBlur={internalBlur}
                onFocus={internalFocus}
                ref={inputRef}
                {...rest}
                error={hasErrorText}
                label={null}
                style={usedInputStyle}
            />
            {TextFieldSuffix}
            {hasErrorText && (
                <FormHelperText className={`jr-formControlledTextFieldErrorContainer jr-mInput-error mui ${errorTextPropsClassName}`} id={errorTextId} {...ErrorTextPropsRest}>
                    {validationResult.message}
                </FormHelperText>
            )}
        </div>
        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            {iconButtons}
        </div>
    </div>

    if (rest.label) {
        const labelClasses = InputLabelProps && InputLabelProps.className ? InputLabelProps.className : ''; 
        return (
            <div className={`jr-formControlledTextFieldContainer ${SizeToClass[size]} jr-mInputText mui`}>
                <FormControlLabel
                    classes={{ label: `jr-formControlledTextFieldContainer jr-mInputs-group-label jr-mInput-label mui ${labelClasses} ${classes?.root ?? ''}`, ...classes }}
                    style={{ margin: 0 }}
                    control={control}    
                    labelPlacement="start"
                    {...rest}
                    label={rest.label}
                />
            </div>
        )
    } else {
        return control;
    }
})
