/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { FFusionWidgets, MFusionWidgetCommon } from "./MFusionWidgetsCommon";
import BulbChartIcon from "../../../../../../assets/icons/charts/fusion/bulb.svg";
import { PDiv } from "../../../ui/composite/UPDiv";
import { PConnectedTableDetail } from "../../../ui/tables/UPConnectedTableDetail";
import { MHyperlinks } from "../../common/MHyperlinks";
import { MDatasetRun } from "../../dataset/MDatasetRun";
import { PCategories } from "../../../ui/composite/UPCategories";

export const MFusionBulb: APDescriptor = {
    id: 'Bulb', type: 'fusion.chart.Bulb', label: 'Bulb', icon: BulbChartIcon, path: 'widgetProperties',
    layouts: [...MFusionWidgetCommon.layouts],
    default: {
        type: 'FUSION_WIDGET', widgetType: 'Bulb',
        widgetProperties: [], valueExpression: "new Integer(20)"
    }
};

export const MFusionBulbDataset: APDescriptor = {
    id: 'Bulb', type: 'fusion.chart.Bulb', label: 'Bulb', icon: BulbChartIcon, path: 'widgetProperties',
    layouts: [new PDiv({
        id: 'fusion.data.dataset', path: 'valuesDataset.dataset',
        label: 'Dataset',
        layouts: [FFusionWidgets.incrementType, FFusionWidgets.incrementWhenExpression, FFusionWidgets.resetType, MDatasetRun]
    }),
    new PDiv({
        id: 'fusion.data.entities', path: 'valuesDataset',
        label: 'Items',
        layouts: [new PConnectedTableDetail({
            id: 'valueSets', label: 'Value Sets', rowLabel: 'Value Set', expanded: true, layouts: [new PCategories({
                layouts: [
                    new PDiv({
                        id: 'fusion.data.valuesets.data',
                        label: 'Data',
                        layouts: []
                    }),
                    new PDiv({
                        id: 'fusion.data.valuesets.hyperlinks',
                        label: 'Hyperlink', path: 'hyperlink',
                        layouts: [...MHyperlinks.layouts]
                    })
                ]
            })],
            default: { id: 'entity-1' }
        })]
    })]
};
