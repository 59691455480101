/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const DualMeasureTreeMapChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="802" height="1000" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 802 1000" {...props}>
      <defs>
        <linearGradient id="highcharts-4eeie2f-1" x1="0" x2="1" y1="0" y2="0">
          <stop offset="0" stopColor="#FFF"></stop>
          <stop offset="1" stopColor="#7cb5ec"></stop>
        </linearGradient>
        <clipPath id="highcharts-4eeie2f-2">
          <path fill="none" d="M0 0H782V916H0z"></path>
        </clipPath>
      </defs>
      <rect
        width="802"
        height="1000"
        fill="#fff"
        className="highcharts-background"
        rx="0"
        ry="0"
      ></rect>
      <path
        fill="none"
        d="M10 10H792V926H10z"
        className="highcharts-plot-background"
      ></path>
      <path
        fill="none"
        d="M10 10H792V926H10z"
        className="highcharts-plot-border"
        data-z-index="1"
      ></path>
      <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
        <path
          fill="none"
          d="M10 926h782"
          className="highcharts-axis-line"
          data-z-index="7"
        ></path>
      </g>
      <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
        <path
          fill="none"
          d="M10 10v916"
          className="highcharts-axis-line"
          data-z-index="7"
        ></path>
      </g>
      <g className="highcharts-series-group" data-z-index="3">
        <g
          className="highcharts-series highcharts-series-0 highcharts-treemap-series highcharts-color-0 highcharts-tracker"
          clipPath="url(#highcharts-4eeie2f-2)"
          data-z-index="0.1"
          transform="translate(10 10)"
        >
          <g className="highcharts-level-group-2" data-z-index="998">
            <path
              fill="#8FBFEF"
              stroke="#e6e6e6"
              d="M-0.5 -0.5H552.5V676.5H-0.5z"
              className="highcharts-point highcharts-color-0"
            ></path>
            <path
              fill="#D7E9F9"
              stroke="#e6e6e6"
              d="M-0.5 676.5H552.5V915.5H-0.5z"
              className="highcharts-point highcharts-color-0"
            ></path>
            <path
              fill="#D8E9F9"
              stroke="#e6e6e6"
              d="M552.5 -0.5H781.5V566.5H552.5z"
              className="highcharts-point highcharts-color-0"
            ></path>
            <path
              fill="#F8FBFE"
              stroke="#e6e6e6"
              d="M552.5 566.5H672.5V748.5H552.5z"
              className="highcharts-point highcharts-color-0"
            ></path>
            <path
              fill="#F9FCFE"
              stroke="#e6e6e6"
              d="M552.5 748.5H672.5V915.5H552.5z"
              className="highcharts-point highcharts-color-0"
            ></path>
            <path
              fill="#CEE4F8"
              stroke="#e6e6e6"
              d="M727.5 898.5H780.5V915.5H727.5z"
              className="highcharts-point highcharts-color-0"
            ></path>
            <path
              fill="#CCE2F8"
              stroke="#e6e6e6"
              d="M672.5 898.5H727.5V915.5H672.5z"
              className="highcharts-point highcharts-color-0"
            ></path>
            <path
              fill="#FEFFFF"
              stroke="#e6e6e6"
              d="M780.5 898.5H781.5V915.5H780.5z"
              className="highcharts-point highcharts-color-0"
            ></path>
            <path
              fill="#FCFDFF"
              stroke="#e6e6e6"
              d="M777.5 739.5H781.5V898.5H777.5z"
              className="highcharts-point highcharts-color-0"
            ></path>
            <path
              fill="#B8D7F5"
              stroke="#e6e6e6"
              d="M672.5 739.5H777.5V887.5H672.5z"
              className="highcharts-point highcharts-color-0"
            ></path>
            <path
              fill="#FAFCFE"
              stroke="#e6e6e6"
              d="M672.5 887.5H777.5V898.5H672.5z"
              className="highcharts-point highcharts-color-0"
            ></path>
            <path
              fill="#F9FCFE"
              stroke="#e6e6e6"
              d="M672.5 566.5H781.5V739.5H672.5z"
              className="highcharts-point highcharts-color-0"
            ></path>
          </g>
          <g className="highcharts-level-group-1" data-z-index="999">
            <path
              fill="rgba(215,233,249,0.15)"
              stroke="#e6e6e6"
              strokeWidth="3"
              d="M-0.5 676.5H552.5V915.5H-0.5z"
              className="highcharts-point highcharts-color-0 highcharts-internal-node-interactive"
              cursor="pointer"
            ></path>
            <path
              fill="rgba(248,251,254,0.15)"
              stroke="#e6e6e6"
              strokeWidth="3"
              d="M552.5 566.5H672.5V748.5H552.5z"
              className="highcharts-point highcharts-color-0 highcharts-internal-node-interactive"
              cursor="pointer"
            ></path>
            <path
              fill="rgba(249,252,254,0.15)"
              stroke="#e6e6e6"
              strokeWidth="3"
              d="M552.5 748.5H672.5V915.5H552.5z"
              className="highcharts-point highcharts-color-0 highcharts-internal-node-interactive"
              cursor="pointer"
            ></path>
            <path
              fill="rgba(254,255,255,0.15)"
              stroke="#e6e6e6"
              strokeWidth="3"
              d="M672.5 898.5H781.5V915.5H672.5z"
              className="highcharts-point highcharts-color-0 highcharts-internal-node-interactive"
              cursor="pointer"
            ></path>
            <path
              fill="rgba(250,252,254,0.15)"
              stroke="#e6e6e6"
              strokeWidth="3"
              d="M672.5 739.5H781.5V898.5H672.5z"
              className="highcharts-point highcharts-color-0 highcharts-internal-node-interactive"
              cursor="pointer"
            ></path>
            <path
              fill="rgba(249,252,254,0.15)"
              stroke="#e6e6e6"
              strokeWidth="3"
              d="M672.5 566.5H781.5V739.5H672.5z"
              className="highcharts-point highcharts-color-0 highcharts-internal-node-interactive"
              cursor="pointer"
            ></path>
            <path
              fill="rgba(143,191,239,0.15)"
              stroke="#E6E6E6"
              strokeWidth="3"
              d="M-0.5 -0.5H552.5V676.5H-0.5z"
              className="highcharts-point highcharts-color-0 highcharts-internal-node-interactive"
              cursor="pointer"
              data-z-index="0"
            ></path>
            <path
              fill="rgba(216,233,249,0.15)"
              stroke="#E6E6E6"
              strokeWidth="3"
              d="M552.5 -0.5H781.5V566.5H552.5z"
              className="highcharts-point highcharts-color-0 highcharts-internal-node-interactive"
              cursor="pointer"
              data-z-index="0"
            ></path>
          </g>
        </g>
      </g>
      <g
        className="highcharts-data-labels highcharts-series-0 highcharts-treemap-series highcharts-color-0 highcharts-tracker"
        data-z-index="6"
      >
        <text
          x="5"
          y="16"
          color="#000"
          data-z-index="1"
          fontSize="11"
          fontWeight="bold"
          transform="translate(10 10) translate(263 327)"
          className="0"
        >
          <tspan
            x="5"
            y="16"
            fill="#FFF"
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="2"
            className="highcharts-text-outline"
          >
            AK
          </tspan>
          <tspan x="5" y="16">
            AK
          </tspan>
        </text>
        <text
          x="5"
          y="16"
          color="#000"
          data-z-index="1"
          fontSize="11"
          fontWeight="bold"
          transform="translate(10 10) translate(263 785)"
          className="0"
        >
          <tspan
            x="5"
            y="16"
            fill="#FFF"
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="2"
            className="highcharts-text-outline"
          >
            CA
          </tspan>
          <tspan x="5" y="16">
            CA
          </tspan>
        </text>
        <text
          x="5"
          y="16"
          color="#000"
          data-z-index="1"
          fontSize="11"
          fontWeight="bold"
          transform="translate(10 10) translate(656 272)"
          className="0"
        >
          <tspan
            x="5"
            y="16"
            fill="#FFF"
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="2"
            className="highcharts-text-outline"
          >
            ID
          </tspan>
          <tspan x="5" y="16">
            ID
          </tspan>
        </text>
        <text
          x="5"
          y="16"
          color="#000"
          data-z-index="1"
          fontSize="11"
          fontWeight="bold"
          transform="translate(10 10) translate(599 646)"
          className="0"
        >
          <tspan
            x="5"
            y="16"
            fill="#FFF"
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="2"
            className="highcharts-text-outline"
          >
            MT
          </tspan>
          <tspan x="5" y="16">
            MT
          </tspan>
        </text>
        <text
          x="5"
          y="16"
          color="#000"
          data-z-index="1"
          fontSize="11"
          fontWeight="bold"
          transform="translate(10 10) translate(598 821)"
          className="0"
        >
          <tspan
            x="5"
            y="16"
            fill="#FFF"
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="2"
            className="highcharts-text-outline"
          >
            NM
          </tspan>
          <tspan x="5" y="16">
            NM
          </tspan>
        </text>
        <text
          x="5"
          y="16"
          color="#000"
          data-z-index="1"
          fontSize="11"
          fontWeight="bold"
          transform="translate(10 10) translate(714 896)"
          className="0"
        >
          <tspan
            x="5"
            y="16"
            fill="#FFF"
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="2"
            className="highcharts-text-outline"
          >
            OR
          </tspan>
          <tspan x="5" y="16">
            OR
          </tspan>
        </text>
        <text
          x="5"
          y="16"
          color="#000"
          data-z-index="1"
          fontSize="11"
          fontWeight="bold"
          transform="translate(10 10) translate(713 808)"
          className="0"
        >
          <tspan
            x="5"
            y="16"
            fill="#FFF"
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="2"
            className="highcharts-text-outline"
          >
            WA
          </tspan>
          <tspan x="5" y="16">
            WA
          </tspan>
        </text>
        <text
          x="5"
          y="16"
          color="#000"
          data-z-index="1"
          fontSize="11"
          fontWeight="bold"
          transform="translate(10 10) translate(713 642)"
          className="0"
        >
          <tspan
            x="5"
            y="16"
            fill="#FFF"
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="2"
            className="highcharts-text-outline"
          >
            WY
          </tspan>
          <tspan x="5" y="16">
            WY
          </tspan>
        </text>
      </g>
      <g
        className="highcharts-legend"
        data-z-index="7"
        transform="translate(294 938)"
      >
        <rect
          width="214"
          height="47"
          fill="none"
          className="highcharts-legend-box"
          rx="0"
          ry="0"
        ></rect>
        <g
          className="highcharts-legend-item highcharts-undefined-series highcharts-color-undefined"
          data-z-index="1"
          transform="translate(8 3)"
        >
          <path
            fill="url(#highcharts-4eeie2f-1)"
            d="M0 4H200V16H0z"
            data-z-index="1"
          ></path>
          <g
            className="highcharts-grid highcharts-coloraxis-grid"
            data-z-index="1"
          >
            <path
              fill="none"
              stroke="#e6e6e6"
              d="M-.5 4v12M66.5 4v12M132.5 4v12M200.5 4v12"
              className="highcharts-grid-line"
              data-z-index="1"
            ></path>
          </g>
          <g className="highcharts-axis highcharts-coloraxis" data-z-index="2">
            <path
              fill="none"
              d="M0 16h200"
              className="highcharts-axis-line"
              data-z-index="7"
            ></path>
          </g>
          <g
            fill="#666"
            className="highcharts-axis-labels highcharts-coloraxis-labels"
            color="#666"
            cursor="default"
            data-z-index="7"
            fontSize="11"
            textAnchor="middle"
          >
            <text x="3.48" y="35">
              0
            </text>
            <text x="66.667" y="35">
              50
            </text>
            <text x="133.333" y="35">
              100
            </text>
            <text x="189.566" y="35">
              150
            </text>
          </g>
        </g>
      </g>
 </svg>
 )};

export default DualMeasureTreeMapChartIcon;