/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { MBarbecue } from "./MBarbecue";

export const M2of7: APDescriptor = {
    ...MBarbecue, id: '2of7', label: '2of7', icon: ICONS.BARCODE_CODABAR_ICON,
    default: { ...MBarbecue.default, barbecue_type: '2of7' }
};
