/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../../ui/APDescriptor";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { MChartDataset } from "./MChartDataset";

export const FValueDataset = {
    valueExpression: new PExpression({id: 'valueExpression', label: 'Value'})
};

export const MValueDataset: APDescriptor = {
    type: 'MValueDataset', id: 'valueDataset', path: 'valueDataset.dataset',
    layouts: [...MChartDataset.layouts, FValueDataset.valueExpression]
};
