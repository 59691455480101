/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { FormControlledTextField } from '@jss/js-common';
import { getKey, getNormalizedPath, getPath, PropertyContext, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { Map } from 'immutable';
import { resolveAttribute } from '../../types/common/StyleResolver';
import '../../../../../assets/uxpl/css/UPControls.css';
import i18n from '../../../../../i18n';
import { ValidationResult, VALIDATION_RESULT } from '@jss/js-common/src/utils/validators';

const TYPEID = 'float';
const pattern = /^-?\d*[\,|\.]?\d*$/;

reg(TYPEID, (mc) => { return <UPFloat mcontext={mc} />; });
export class PFloat extends APDescriptor {
    min?: number;
    max?: number;
    canBeNull?: boolean = true;
    default?: number;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public validator(v: number | undefined, propertyContex: PropertyContext): string | undefined {
        if (!v && this.canBeNull)
            return undefined;
        if (v === undefined && !this.canBeNull) {
            return i18n.t('validators.fieldNameEmpty');
        }
        if (isNaN(v)) {
            return 'The value is not a number';
        }
        if (this.max !== undefined && v > this.max) {
            return 'The value exceed the maximum';
        }
        if (this.min !== undefined && v < this.min) {
            return 'The value is below the minimum';
        }
        return undefined;
    }

    public constructor(init: Partial<PFloat>) {
        super();
        Object.assign(this, { validator: this.validator, ...init, type: TYPEID });
    }
}

const floatValidator = (stringValue: string | undefined, descriptor: PFloat, mcontext: PropertyContext): ValidationResult => {
    if (!stringValue) {
        if (descriptor.canBeNull) {
            return { result: VALIDATION_RESULT.VALID }
        } else {
            return { result: VALIDATION_RESULT.INVALID, message: i18n.t('validators.fieldNameEmpty') }
        }
    }
    if (pattern.test(stringValue) || stringValue.length === 0) {
        if (stringValue.length === 1 && stringValue.startsWith('-')) {
            return { result: VALIDATION_RESULT.PARTIALLY_INVALID }
        } else if (stringValue.endsWith('.') || stringValue.startsWith('.')) {
            return { result: VALIDATION_RESULT.PARTIALLY_INVALID }
        } else {
            const newValue = stringValue.length === 0 ? undefined : parseFloat(stringValue);
            if (Number.isNaN(newValue)) {
                return { result: VALIDATION_RESULT.INVALID }
            }
            if (descriptor.validator) {
                const validationMessage = descriptor.validator(newValue, mcontext);
                if (validationMessage) {
                    return { result: VALIDATION_RESULT.INVALID, message: validationMessage }
                }
            }
        }
    } else {
        return { result: VALIDATION_RESULT.INVALID }
    }
    return { result: VALIDATION_RESULT.VALID }
}

export interface IUPFloat{
    additionalIcon?: React.ReactElement, 
}

export class UPFloat extends React.Component<UiPropertyProps & IUPFloat> {

    shouldComponentUpdate(nextProps: Readonly<UiPropertyProps>): boolean {
        const p1 = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const p2 = getPath(nextProps.mcontext.descriptor.id, nextProps.mcontext.elements[0].path);
        const v1 = this.props.mcontext.model.getIn(p1);
        const v2 = nextProps.mcontext.model.getIn(p2);
        return v1 !== v2;
    }

    render() {
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const descriptor = this.props.mcontext.descriptor as PFloat;
        let isInherited = false;
        let v = this.props.mcontext.model.getIn(p);
        if (v === undefined && this.props.mcontext.descriptor.inheritedPropertyId) {
            const elementPath = getPath(undefined, this.props.mcontext.elements[0].path).slice(0, 2);
            const element = this.props.mcontext.model.getIn(elementPath) as Map<string, any>;
            isInherited = true;
            v = resolveAttribute(this.props.mcontext.model, element, this.props.mcontext.descriptor.inheritedPropertyId);
        }
        const classes: string[] = [];
        if (this.props.mcontext.descriptor.className) {
            classes.push(this.props.mcontext.descriptor.className);
        }
        if (isInherited) {
            classes.push('placeHolderTextColor');
        }

        const validator = (stringValue: string) => floatValidator(stringValue, descriptor, this.props.mcontext);

        return <FormControlledTextField
            className={classes.join(' ')}
            style={this.props.mcontext.descriptor.style}
            key={getKey(p)}
            onTextChange={this.onValueChange}
            label={i18n.t(this.props.mcontext.descriptor.label)}
            inline={true}
            size={'small'}
            InputLabelProps={descriptor.deprecated ? { className: 'deprecatedProperty' } : undefined}
            disabled={descriptor.readonly}
            value={v !== undefined ? v.toString() : ''}
            iconButtons={this.props.additionalIcon ? [this.props.additionalIcon] : undefined}
            validator={validator}
        />
    }

    public onValueChange = (stringValue: string | undefined) => {
        const newValue = stringValue === undefined || stringValue.length === 0 ? undefined : parseFloat(stringValue);
        this.props.mcontext.elements.forEach(key => {
            if (newValue === undefined) {
                const path = getNormalizedPath(key.path);
                path.push(this.props.mcontext.descriptor.id);
                this.props.mcontext.deleteElement(path);
            } else {
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: newValue });
            }
        });
    }
}