/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Map } from 'immutable';
import { BarcodeTypes, BARCODE_NAMESPACE, BARCODE_TYPE } from '../reader/JrxmlBarcodeUtils';
import { createReportElement, setAttributeIfPresent, setExpressionNodeWithPrefixFromMap, setXMLAttributeIfPresent } from './JrxmlHelper';

export const createBarcodeElement = (elementNode: Map<string, any>, xmlDocument: Document) : Element | null => {
    const componentElement = xmlDocument.createElement("componentElement");
    const reportElement = createReportElement(elementNode, xmlDocument);
    componentElement.appendChild(reportElement);

    const barcodeType = elementNode.get(BARCODE_TYPE);
    const baseNamespace = elementNode.get(BARCODE_NAMESPACE, "jr");
    const namespace = baseNamespace + ":";
    let barcodeComponent : Element | null = null;
    if (barcodeType === BarcodeTypes.BARBECUE_NAME){
        barcodeComponent = createBarbecue(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.CODABAR_NAME){
        barcodeComponent = createCodabar(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.CODE128_NAME){
        barcodeComponent = createCode128(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.EAN128_NAME){
        barcodeComponent = createEan128(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.EAN13_NAME){
        barcodeComponent = createEan13(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.EAN8_NAME){
        barcodeComponent = createEan8(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.CODE39_NAME){
        barcodeComponent = createCode39(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.PDF417_NAME){
        barcodeComponent = createPDF417(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.POSTNET_NAME){
        barcodeComponent = createPostnet(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.DATAMATRIX_NAME){
        barcodeComponent = createDatamatrix(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.INTERLEAVED2OF5_NAME){
        barcodeComponent = createInt2Of5(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.UPCA_NAME){
        barcodeComponent = createUPCA(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.UPCE_NAME){
        barcodeComponent = createUPCE(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.QRCODE_NAME){
        barcodeComponent = createQRCode(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.ROYALMAILCUSTOMER_NAME){
        barcodeComponent = createRoyalMail(elementNode, xmlDocument, namespace);
    } else if (barcodeType === BarcodeTypes.USPS_NAME){
        barcodeComponent = createUSPS(elementNode, xmlDocument, namespace);
    } else {
        return null;
    }
    if (barcodeComponent !== null){
        barcodeComponent.setAttribute("xmlns:" + baseNamespace, elementNode.get("xmlns:" + baseNamespace, "http://jasperreports.sourceforge.net/jasperreports/components"));
        barcodeComponent.setAttribute("xsi:schemaLocation", elementNode.get("xsi:schemaLocation", "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd"));
        componentElement.appendChild(barcodeComponent);
    }
    return componentElement;
}

const createBarbecue = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barbecueElement = xmlDocument.createElement(prefix + BarcodeTypes.BARBECUE_NAME);

    setExpressionNodeWithPrefixFromMap(barbecueElement, xmlDocument, elementNode, "codeExpression", prefix);
    setExpressionNodeWithPrefixFromMap(barbecueElement, xmlDocument, elementNode, "applicationIdentifierExpression", prefix);

    setXMLAttributeIfPresent(elementNode, "barbecue_type", "type", barbecueElement);
    setAttributeIfPresent(elementNode, "drawText", barbecueElement);
    setAttributeIfPresent(elementNode, "checksumRequired", barbecueElement);
    setAttributeIfPresent(elementNode, "barWidth", barbecueElement);
    setAttributeIfPresent(elementNode, "barHeight", barbecueElement);
    setAttributeIfPresent(elementNode, "evaluationTime", barbecueElement);
    setAttributeIfPresent(elementNode, "evaluationGroup", barbecueElement);
    setAttributeIfPresent(elementNode, "rotation", barbecueElement);
    return barbecueElement;
}

const createBarcode = (elementNode: Map<string, any>, xmlDocument: Document, tagName: string, prefix : string) : Element => {
    const barcodeElement = xmlDocument.createElement(prefix + tagName);
    setExpressionNodeWithPrefixFromMap(barcodeElement, xmlDocument, elementNode, "codeExpression", prefix);
    setAttributeIfPresent(elementNode, "evaluationTime", barcodeElement);
    setAttributeIfPresent(elementNode, "evaluationGroup", barcodeElement);
    return barcodeElement;
}

const createBarcode4j = (elementNode: Map<string, any>, xmlDocument: Document, tagName: string, prefix: string) : Element => {
    const barcodeElement = createBarcode(elementNode, xmlDocument, tagName, prefix);
    setExpressionNodeWithPrefixFromMap(barcodeElement, xmlDocument, elementNode, "patternExpression", prefix);
    setAttributeIfPresent(elementNode, "orientation", barcodeElement);
    setAttributeIfPresent(elementNode, "textPosition", barcodeElement);
    setAttributeIfPresent(elementNode, "moduleWidth", barcodeElement);
    setAttributeIfPresent(elementNode, "quietZone", barcodeElement);
    setAttributeIfPresent(elementNode, "verticalQuietZone", barcodeElement);
    return barcodeElement;
}

const createCodabar = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4j(elementNode, xmlDocument, BarcodeTypes.CODABAR_NAME, prefix);
    setAttributeIfPresent(elementNode, "wideFactor", barcodeElement);
    return barcodeElement;
}

const createCode128 = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4j(elementNode, xmlDocument, BarcodeTypes.CODE128_NAME, prefix);
    return barcodeElement;
}

const createEan128 = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4j(elementNode, xmlDocument, BarcodeTypes.EAN128_NAME, prefix);
    setExpressionNodeWithPrefixFromMap(barcodeElement, xmlDocument, elementNode, "templateExpression", prefix);
    setAttributeIfPresent(elementNode, "checksumMode", barcodeElement);
    return barcodeElement;
}

const createEan13 = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4j(elementNode, xmlDocument, BarcodeTypes.EAN13_NAME, prefix);
    setAttributeIfPresent(elementNode, "checksumMode", barcodeElement);
    return barcodeElement;
}

const createEan8 = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4j(elementNode, xmlDocument, BarcodeTypes.EAN8_NAME, prefix);
    setAttributeIfPresent(elementNode, "checksumMode", barcodeElement);
    return barcodeElement;
}

const createCode39 = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4j(elementNode, xmlDocument, BarcodeTypes.CODE39_NAME, prefix);
    setAttributeIfPresent(elementNode, "checksumMode", barcodeElement);
    setAttributeIfPresent(elementNode, "displayChecksum", barcodeElement);
    setAttributeIfPresent(elementNode, "displayStartStop", barcodeElement);
    setAttributeIfPresent(elementNode, "extendedCharSetEnabled", barcodeElement);
    setAttributeIfPresent(elementNode, "wideFactor", barcodeElement);
    setAttributeIfPresent(elementNode, "intercharGapWidth", barcodeElement);
    return barcodeElement;
}

const createPDF417 = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4j(elementNode, xmlDocument, BarcodeTypes.PDF417_NAME, prefix);
    setAttributeIfPresent(elementNode, "minColumns", barcodeElement);
    setAttributeIfPresent(elementNode, "maxColumns", barcodeElement);
    setAttributeIfPresent(elementNode, "minRows", barcodeElement);
    setAttributeIfPresent(elementNode, "maxRows", barcodeElement);
    setAttributeIfPresent(elementNode, "errorCorrectionLevel", barcodeElement);
    setAttributeIfPresent(elementNode, "widthToHeightRatio", barcodeElement);
    return barcodeElement;
}

const createPostnet = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4j(elementNode, xmlDocument, BarcodeTypes.POSTNET_NAME, prefix);
    setAttributeIfPresent(elementNode, "baselinePosition", barcodeElement);
    setAttributeIfPresent(elementNode, "checksumMode", barcodeElement);
    setAttributeIfPresent(elementNode, "displayChecksum", barcodeElement);
    setAttributeIfPresent(elementNode, "shortBarHeight", barcodeElement);
    setAttributeIfPresent(elementNode, "intercharGapWidth", barcodeElement);
    return barcodeElement;
}

const createDatamatrix = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4j(elementNode, xmlDocument, BarcodeTypes.DATAMATRIX_NAME, prefix);
    setAttributeIfPresent(elementNode, "shape", barcodeElement);
    return barcodeElement;
}

const createInt2Of5 = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4j(elementNode, xmlDocument, BarcodeTypes.INTERLEAVED2OF5_NAME, prefix);
    setAttributeIfPresent(elementNode, "checksumMode", barcodeElement);
    setAttributeIfPresent(elementNode, "displayChecksum", barcodeElement);
    setAttributeIfPresent(elementNode, "wideFactor", barcodeElement);
    return barcodeElement;
}

const createUPCA = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4j(elementNode, xmlDocument, BarcodeTypes.UPCA_NAME, prefix);
    setAttributeIfPresent(elementNode, "checksumMode", barcodeElement);
    return barcodeElement;
}

const createUPCE = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4j(elementNode, xmlDocument, BarcodeTypes.UPCE_NAME, prefix);
    setAttributeIfPresent(elementNode, "checksumMode", barcodeElement);
    return barcodeElement;
}

const createQRCode = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode(elementNode, xmlDocument, BarcodeTypes.QRCODE_NAME, prefix);
    setAttributeIfPresent(elementNode, "errorCorrectionLevel", barcodeElement);
    setAttributeIfPresent(elementNode, "margin", barcodeElement);
    return barcodeElement;
}

const createBarcode4JFourState = (elementNode: Map<string, any>, xmlDocument: Document, tagName: string, prefix: string) : Element => {
    const barcodeElement = createBarcode4j(elementNode, xmlDocument, tagName, prefix);
    setAttributeIfPresent(elementNode, "checksumMode", barcodeElement);
    setAttributeIfPresent(elementNode, "ascenderHeight", barcodeElement);
    setAttributeIfPresent(elementNode, "intercharGapWidth", barcodeElement);
    setAttributeIfPresent(elementNode, "trackHeight", barcodeElement);
    return barcodeElement;
}

const createRoyalMail = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4JFourState(elementNode, xmlDocument, BarcodeTypes.ROYALMAILCUSTOMER_NAME, prefix);
    return barcodeElement;
}

const createUSPS = (elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    const barcodeElement = createBarcode4JFourState(elementNode, xmlDocument, BarcodeTypes.USPS_NAME, prefix);
    return barcodeElement;
}