/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { nameValidator, pathValidator } from '@jss/js-common/src/utils/validators';
import { MIME_TYPES } from '@jss/js-rest-api';
import * as React from 'react';
import format from 'xml-formatter';
import { getDataAdapterNode } from '../../editor/Utils';
import { createNode, getProlog, IDataAdapterConfig, IDataAdapterDescriptor } from '../IDataAdapterDescriptor';
import { JRSDatasourceDataAdapterEditor, simpleJrsUrlValidator } from './JRSDatasourceDataAdapterEditor';


export default class JRSDatasourceDataAdapterDescriptor implements IDataAdapterDescriptor {

  public getMime = () => {
    return MIME_TYPES.DATA_ADAPTER_JRS;
  }

  public getIcon = () => {
    return <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 63 65"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <use xlinkHref="#jrsIcon" x={0.5} y={0.5} />
    <symbol id="jrsIcon" overflow="visible">
      <path
        d="M47.516 4.495a28.502 28.502 0 0 0-2.828-1.525C36.696 18.3 22.178 36.53 2.948 46.9a28.91 28.91 0 0 0 4.168 6.728C23.641 42.264 38.945 23.262 47.52 4.5m-3.558-1.847C40.01.922 35.534 0 30.675 0 12.698 0 0 13.877 0 32.144a39.17 39.17 0 0 0 .95 8.7C19.343 33.348 35.683 16.82 43.97 2.642m4.238 2.286C38.778 28.122 27.28 44.54 12.594 58.66 17.556 62.105 23.73 64 30.678 64c17.98 0 30.68-13.972 30.68-32.243 0-12.1-5.017-21.498-13.152-26.83"
        stroke="none"
        fill="#1287d9"
        fillRule="nonzero"
      />
    </symbol>
  </svg>
  }

  /**
   *  Return the name of this data adapter to be presented to the user
   *  i.e. CSV File
   */
  public getDataAdapterLabel = () => {
    return 'datasource.jrsdatasource.descriptor.label';
  }

  /**
  *  Return the name of this data adapter to be presented to the user
  *  i.e. CSV File
  */
  public getDataAdapterDescription = () => {
    return 'datasource.jrsdatasource.description';
  }

  /**
   *  Return the canonical name of the class which impements this data adapter in Java.
   *  net.sf.jasperreports.data.csv.CsvDataAdapter
   */
  public getDataAdapterClass = () => {
    return 'com.jaspersoft.jasperreports.data.jrs.JrsDataAdapterImpl'
  }

  public getDataAdapterRootName = () => {
    return 'jrsDataAdapter';
  }

  /**
   * Return a plain json object with the default data adapter configuration.
   * It can be just an empty object.
   *
   * @memberof IDataAdapter
   */
  public initializeDataAdapterConfig = () => {
    return {
      class: this.getDataAdapterClass(),
      name: 'New JRS Datasource Data Adapter',
      serverUrl: '',
      username: '',
      password: '',
      organization: '',
      useSSO: false,
      datasourceUrl: '',
    }
  };

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public validateDataAdapterConfig = (config: IDataAdapterConfig) => {

    // Check for errors...
    const errors = [];

    const nameError = nameValidator(config.name);
    if (nameError) {
      errors.push(nameError);
    }

    const urlError = simpleJrsUrlValidator(config.serverUrl);
    if (urlError) {
      errors.push(urlError);
    }

    const pathError = pathValidator(config.datasourceUrl);
    if (pathError) {
      errors.push(pathError);
    }

    if (errors.length > 0) {
      return errors.join('\n');
    }

    return undefined;

  }

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public createEditor = (config: IDataAdapterConfig, onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => {
    return <JRSDatasourceDataAdapterEditor {...config} onDataAdapterSettingsChange={onDataAdapterSettingsChange} readOnly={readOnly}/>
  }

  public getLanguages = () => {
    return ['text'];
  }

  public toXml = (config: IDataAdapterConfig) => {
    const xmlDoc = document.implementation.createDocument(null, this.getDataAdapterRootName());
    const root = xmlDoc.getElementsByTagName(this.getDataAdapterRootName())[0];
    root.setAttribute('class', this.getDataAdapterClass());

    const nameNode = createNode(xmlDoc, config, 'name', 'name');
    if (nameNode) {
      root.appendChild(nameNode);
    }

    const serverUrNode = createNode(xmlDoc, config, 'serverUrl', 'serverUrl');
    if (serverUrNode) {
      root.appendChild(serverUrNode);
    }

    const usernameNode = createNode(xmlDoc, config, 'username', 'username');
    if (usernameNode) {
      root.appendChild(usernameNode);
    }

    const passwordNode = createNode(xmlDoc, config, 'password', 'password');
    if (passwordNode) {
      root.appendChild(passwordNode);
    }

    const organizationNode = createNode(xmlDoc, config, 'organization', 'organization');
    if (organizationNode) {
      root.appendChild(organizationNode);
    }

    const datasourceUrlNode = createNode(xmlDoc, config, 'datasourceUrl', 'datasourceUrl');
    if (datasourceUrlNode) {
      root.appendChild(datasourceUrlNode);
    }

    const useSSONode = createNode(xmlDoc, config, 'useSSO', 'useSSO');
    if (useSSONode) {
      root.appendChild(useSSONode);
    }

    const prolog = getProlog();
    const xml = prolog + (new XMLSerializer().serializeToString(xmlDoc));
    return format(xml, { indentation: '  ', collapseContent: true });
  }

  public toJson = (xml: Document) => {
    const result: any = {
      class: this.getDataAdapterClass(),
      name: '',
      serverUrl: '',
      username: '',
      password: '',
      organization: '',
      useSSO: false,
      datasourceUrl: '',
    }

    getDataAdapterNode(xml.getRootNode()).childNodes.forEach((childNode) => {
      const nodeName = childNode.nodeName;
      if (nodeName === 'name') {
        result.name = childNode.textContent;
      } else if (nodeName === 'serverUrl') {
        result.serverUrl = childNode.textContent;
      } else if (nodeName === 'username') {
        result.username = childNode.textContent;
      } else if (nodeName === 'password') {
        result.password = childNode.textContent;
      } else if (nodeName === 'organization') {
        result.organization = childNode.textContent;
      } else if (nodeName === 'datasourceUrl') {
        result.datasourceUrl = childNode.textContent;
      } else if (nodeName === 'useSSO') {
        result.useSSO = (childNode.textContent === 'true');
      }
    });
    return result;
  }
}