/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const CustomVisualizationIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/palette/custom-viz" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" transform="translate(1.500000, 2.000000)">
                    <path d="M15.5,10.5 C16.3284271,10.5 17,11.1715729 17,12 C17,12.8284271 16.3284271,13.5 15.5,13.5 C14.6715729,13.5 14,12.8284271 14,12 C14,11.1715729 14.6715729,10.5 15.5,10.5 Z M15.5,3.5 C16.3284271,3.5 17,4.17157288 17,5 C17,5.82842712 16.3284271,6.5 15.5,6.5 C14.6715729,6.5 14,5.82842712 14,5 C14,4.17157288 14.6715729,3.5 15.5,3.5 Z M15.5,0 C16.3284271,0 17,0.671572875 17,1.5 C17,2.32842712 16.3284271,3 15.5,3 C14.6715729,3 14,2.32842712 14,1.5 C14,0.671572875 14.6715729,0 15.5,0 Z" id="Combined-Shape" fill="#0081CB"></path>
                    <path d="M12,7 C12.8284271,7 13.5,7.67157288 13.5,8.5 C13.5,9.32842712 12.8284271,10 12,10 C11.1715729,10 10.5,9.32842712 10.5,8.5 C10.5,7.67157288 11.1715729,7 12,7 Z M12,3.5 C12.8284271,3.5 13.5,4.17157288 13.5,5 C13.5,5.82842712 12.8284271,6.5 12,6.5 C11.1715729,6.5 10.5,5.82842712 10.5,5 C10.5,4.17157288 11.1715729,3.5 12,3.5 Z" id="Combined-Shape" fill="#00A9D7"></path>
                    <path d="M8.5,3.5 C9.32842712,3.5 10,4.17157288 10,5 C10,5.82842712 9.32842712,6.5 8.5,6.5 C7.67157288,6.5 7,5.82842712 7,5 C7,4.17157288 7.67157288,3.5 8.5,3.5 Z M8.5,0 C9.32842712,0 10,0.671572875 10,1.5 C10,2.32842712 9.32842712,3 8.5,3 C7.67157288,3 7,2.32842712 7,1.5 C7,0.671572875 7.67157288,0 8.5,0 Z" id="Combined-Shape" fill="#55ADEB"></path>
                    <path d="M5,7 C5.82842712,7 6.5,7.67157288 6.5,8.5 C6.5,9.32842712 5.82842712,10 5,10 C4.17157288,10 3.5,9.32842712 3.5,8.5 C3.5,7.67157288 4.17157288,7 5,7 Z M5,3.5 C5.82842712,3.5 6.5,4.17157288 6.5,5 C6.5,5.82842712 5.82842712,6.5 5,6.5 C4.17157288,6.5 3.5,5.82842712 3.5,5 C3.5,4.17157288 4.17157288,3.5 5,3.5 Z M5,0 C5.82842712,0 6.5,0.671572875 6.5,1.5 C6.5,2.32842712 5.82842712,3 5,3 C4.17157288,3 3.5,2.32842712 3.5,1.5 C3.5,0.671572875 4.17157288,0 5,0 Z" id="Combined-Shape" fill="#7CC344"></path>
                    <path d="M1.5,10.5 C2.32842712,10.5 3,11.1715729 3,12 C3,12.8284271 2.32842712,13.5 1.5,13.5 C0.671572875,13.5 0,12.8284271 0,12 C0,11.1715729 0.671572875,10.5 1.5,10.5 Z M1.5,7 C2.32842712,7 3,7.67157288 3,8.5 C3,9.32842712 2.32842712,10 1.5,10 C0.671572875,10 0,9.32842712 0,8.5 C0,7.67157288 0.671572875,7 1.5,7 Z M1.5,0 C2.32842712,0 3,0.671572875 3,1.5 C3,2.32842712 2.32842712,3 1.5,3 C0.671572875,3 0,2.32842712 0,1.5 C0,0.671572875 0.671572875,0 1.5,0 Z" id="Combined-Shape" fill="#B6D978"></path>
                </g>
            </g>
        </svg>
    );
}
export default CustomVisualizationIcon;