/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import i18n from '../i18n';
import { IResultDialogProp } from '@jss/js-common';


export interface ConfirmDialogProps extends IResultDialogProp {
    title: string;
    message: string;
}


export class ConfirmDialog extends React.Component<ConfirmDialogProps> {
    static defaultProps = {
        title: 'Confirmation'
    }

    render() {
        return <Dialog
            open={true}
            onClose={this.onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{this.props.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onCancel} color="primary">{i18n.t('repository.delete.dialog.cancel')}</Button>
                <Button onClick={this.onConfirm} color="primary" autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>;
    }
    private onCancel = () => {
        this.props.onEnd({ canceled: true });
    }

    private onConfirm = () => {
        this.props.onEnd({ canceled: false });
    }

}