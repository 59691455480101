/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg, UiProperty, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../../ui/APDescriptor';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Typography, PopoverControl } from "@jss/js-common";
import _ from 'lodash';
import { List, Map } from 'immutable';
import * as ReportActions from '../../../../../actions/reportActions';
import { connect } from 'react-redux';
import { IState } from '../../../../../reducers';
import { ListItem, ListItemSecondaryAction, ListItemText, List as WList } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import { PDiv } from '../../ui/composite/UPDiv';
import i18n from '../../../../../i18n';
import { MMainDatasetLayouts, MSubdatasetDatasetLayouts } from './MDatasets';
import { uuidv4 } from '../../../../../utils/uuidGenerator';


const TYPEID = 'datasetList';
reg(TYPEID, (mc) => { return <UPDatasetList mcontext={mc} />; });
export class PDatasetList extends APDescriptor {
    expanded?: boolean = false;
    detail: APDescriptor[];
    public constructor(init: Partial<PDatasetList>) {
        super();
        Object.assign(this, { ...init, id: 'subdatasets', type: TYPEID });
    }
}

interface PDatasetListState {
    openedDatasetPopoverId: string | undefined;
}

interface IUPDatasetListProps extends UiPropertyProps {
    subeditors?: List<Map<string, any>>;
    currentEditorIndex?: number | undefined;
    datasetKey?: string,
    setEditorProperties?: (index: number, editorLabel: string, isSelectable: boolean, editedResourceId: string, params?: Record<string, unknown>) => void;
    deletePropertyRefreshAction?: (path: (string | number)[], reportPath: string) => void;
    addProperty?: (path: string[], element) => void;
    setSelectedDataset?: (id: string) => void;
}

class UPDatasetList extends React.Component<IUPDatasetListProps, PDatasetListState> {
    public state: PDatasetListState = {
        openedDatasetPopoverId: undefined
    };

    private isExpanded = () => {
        const defaultValue = (this.props.mcontext.descriptor as PDatasetList).expanded === true;
        const isExpanded = this.props.mcontext.rootModel.getIn(['widgetStatus', this.props.mcontext.descriptor.id, 'expanded'], defaultValue) as boolean;
        return isExpanded;        
    }

    private handleExpanded = () => {
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expanded'], !this.isExpanded(), false, true);
    }

    private isDatasetEditorVisible = () => {
        if (this.props.currentEditorIndex !== undefined && this.props.currentEditorIndex >= 0) {
            const currentEditorType = this.props.subeditors.getIn([this.props.currentEditorIndex, 'type']);
            return (currentEditorType === 'datasetQueryEditor');
        }
        return false;
    }

    public onSelectionChange = (datasetKey) => {
        this.props.setSelectedDataset(datasetKey);
        if (this.isDatasetEditorVisible()) {
            this.props.setEditorProperties(this.props.currentEditorIndex, 'dataset editor', false, datasetKey.toString());
        }
    }

    render() {
        const datasetName = !this.props.datasetKey || this.props.datasetKey ? 'Main Dataset' : this.props.subeditors.getIn([this.props.datasetKey, 'name'], '');
        const layouts = this.props.datasetKey !== 'main' ? MSubdatasetDatasetLayouts : MMainDatasetLayouts;
        return <div style={{ flex: 1, flexDirection: 'column', flexBasis: 0, overflow: 'auto' }}>
            <Accordion expanded={this.isExpanded()} onChange={this.handleExpanded} style={{ borderBottom: 'solid #dedede 1px', marginBottom: '8px' }}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className="js-jrws-search-accordion-header" >
                    <Typography>{i18n.t(this.props.mcontext.descriptor.label)}: {datasetName}</Typography>
                    <IconButton color='primary' icon='plus' size='small' onClick={this.addDataset} />
                </AccordionSummary>
                <AccordionDetails
                    style={this.props.mcontext.descriptor.style ? this.props.mcontext.descriptor.style : { display: 'block', maxHeight: '150px', overflow: 'auto' }}
                    className={this.props.mcontext.descriptor.className}>
                    {this.renderItems()}
                </AccordionDetails>
            </Accordion>
            {layouts.map((key, index) => {
                const els = _.cloneDeep(this.props.mcontext.elements);
                if (this.props.datasetKey !== 'main') {
                    els.forEach(key => { key.path = ['subdatasets', this.props.datasetKey] });
                }
                return <UiProperty key={index} mcontext={{ ...this.props.mcontext, descriptor: key, elements: els }} />
            })}
        </div>

    }

    private renderItems = () => {
        const items = this.getItems();
        return <div>
            <WList style={{ height: 125 }}>
                {items.map((item: { key: string | number, name: string }, index) => {
                    return <ListItem key={'listitem' + item.key} button onClick={() => { this.onSelectionChange(item.key) }} selected={item.key === this.props.datasetKey}>
                        <ListItemText primary={item.name} />
                        <ListItemSecondaryAction>
                            {this.renderProperties(item.key, index)}
                            {this.renderDelete(item.key)}
                        </ListItemSecondaryAction>
                    </ListItem>;
                })}
            </WList>
        </div>
    }

    private renderProperties(key, index) {
        if (key !== 'main')
            return <PopoverControl id={this.props.mcontext.descriptor.id + '.popover'} getContent={(handleClick) => this.renderContent(index, handleClick)} getControl={(handleClick) => this.renderTriggerButton(key, handleClick)} isOpen={this.state.openedDatasetPopoverId === key} onClose={this.handleClose} />;
    }
    private renderTriggerButton = (key, handleClick: (event: any) => void) => {
        return <IconButton key={'tib.' + key} icon="more" onClick={event => { handleClick(event); this.handleClick(key) }}><MoreHorizIcon /></IconButton>;
    }
    private renderContent = (index, handleClose: () => void) => {
        const d = this.props.mcontext.descriptor as PDatasetList
        const newd = new PDiv({ id: 'ds', layouts: d.detail, path: `subdatasets.${index - 1}` });

        const els = _.cloneDeep(this.props.mcontext.elements);

        return <div style={{ padding: '10px', width: '400px' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: 'solid 1px lightgray', marginBottom: '10px' }}>
                <IconButton icon="close" onClick={() => { handleClose(); this.handleClose(); }} size='small' >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h1">{this.props.mcontext.descriptor.label ? i18n.t(this.props.mcontext.descriptor.label) : ''}</Typography>
            </div>
            <UiProperty mcontext={{ ...this.props.mcontext, descriptor: newd, elements: els }} />
        </div>;
    }

    public handleClose = (): void => {
        this.setState({ openedDatasetPopoverId: undefined });
    }

    public handleClick = (openPopoverId: string): void => {
        this.setState({ openedDatasetPopoverId: openPopoverId });
    }

    private renderDelete(key) {
        if (key !== 'main'){
            return <IconButton key={'ib.' + key} icon='delete' onClick={() => this.deleteDataset(key)} />;
        }
    }

    private deleteDataset = (key) => {
        const descriptor = this.props.mcontext.reportDescriptor;
        let pa = descriptor && descriptor.path ? descriptor.path : '/';
        if (!pa.endsWith('/')) {
            pa += "/";
        }
        pa += descriptor ? descriptor.name :  '';
        if (key === this.props.datasetKey){
            this.onSelectionChange('main');
        }
        this.props.deletePropertyRefreshAction(['model', 'subdatasets', key], pa);
    }

    private getItems = (): { key: string | number, name: string }[] => {
        const items: { key: string | number, name: string }[] = [{ key: 'main', name: 'Main Dataset' }];
        const subdatasets: List<Map<string, any>> = this.props.mcontext.model.getIn(['subdatasets']) as List<Map<string, any>>;
        if (subdatasets) {
            subdatasets.forEach((subdataset, index) => {
                items.push({ key: index, name: subdataset.get('name') });
            })
        }
        return items;
    }

    private addDataset = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        const def = { name: 'Dataset', fields: [], parameters: [], variables: [], sortFields: [], scriptlets: [], uuid: uuidv4() };
        if (def.name) {
            const items = this.getItems();
            def.name = this.findName(def.name, def.name, 1, items);
        }
        const p: string[] = ['model', 'subdatasets'];
        this.props.addProperty(p, def);
    }

    private findName = (n: string, prefix: string, id: number, datasets: { key: string | number, name: string }[]): string => {
        for (const f of datasets) {
            const name = f.name;
            if (name === n) {
                return this.findName(prefix + id, prefix, id + 1, datasets);
            }
        }
        return n;
    }
}

const mapStateToProps = (state: IState) => {
    return {
        subeditors: state.getIn(['report', 'subeditors']),
        currentEditorIndex: state.getIn(['report', 'currentEditorIndex']),
        datasetKey: state.getIn(['report', 'datasetSelection'], 'main'),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        deletePropertyRefreshAction: (path: (string | number)[], reportPath: string) => { dispatch(ReportActions.deletePropertyRefreshAction(path, reportPath)); },
        addProperty: (path: string[], element) => { dispatch(ReportActions.addProperty(path, element)); },
        setEditorProperties: (index: number, editorLabel: string, isSelectable: boolean, editedResourceId: string, params?: Record<string, unknown>) => {
            dispatch(ReportActions.setEditorProperties(index, editorLabel, isSelectable, editedResourceId, params));
        },
        setSelectedDataset: (key: string) => {
            dispatch(ReportActions.selectDataset(key));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UPDatasetList);
