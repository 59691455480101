/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg } from '../../ui/UiProperty';
import { APDescriptor } from '../../ui/APDescriptor';
import UPDatasetList from './UPDatasetList';

const TYPEID = 'connectedDatasetList';
reg(TYPEID, (mc) => { return <UPDatasetList mcontext={mc} />; });
export class PConnectedDatasetList extends APDescriptor {
    expanded?: boolean = false;
    detail: APDescriptor[];
    public constructor(init: Partial<PConnectedDatasetList>) {
        super();
        Object.assign(this, { ...init, id: 'subdatasets', type: TYPEID });
    }
}
