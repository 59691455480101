/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { FormControlledTextField } from '@jss/js-common';
import { getKey, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import i18n from '../../../../../i18n';

const TYPEID = 'pattern';
reg(TYPEID, (mc) => { return <UPPattern mcontext={mc} />; });
export class PPattern extends APDescriptor {
    public constructor(init: Partial<PPattern>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

export class UPPattern extends React.Component<UiPropertyProps> {

    render() {
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p) as string | undefined;
        const descriptor = this.props.mcontext.descriptor as PPattern;
        return <FormControlledTextField
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            key={getKey(p)}
            onTextChange={this.onPatternChange}
            label={i18n.t(this.props.mcontext.descriptor.label)}
            // defaultValue={this.props.placeholder}
            InputLabelProps={descriptor.deprecated ? {className: 'deprecatedProperty'} : undefined}
            InputProps={{title: v}}
            inline={true}
            size={'small'}
            value={v}
            disabled={this.props.mcontext.descriptor.readonly}
            type={'text'}
        />
    }
    public onPatternChange = (value: string) => {
        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: value });
        });
    }

}
