/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { IRepositoryItemDescriptor, isBinary, RepositoryApi, RESOURCE_TYPE } from '@jss/js-rest-api';
import { UxType } from '@jss/js-common';
import MenuItem from '@material-ui/core/MenuItem';
import { RunContext } from '../../RunContainer';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { saveAs } from 'file-saver';
import { AxiosResponse } from 'axios';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';

export interface IResourceDownloadProps {
    type?: UxType;
    size?: 'small' | 'medium' | 'large';
    disabled?: boolean;
    descriptor: IRepositoryItemDescriptor;
    onClose: () => void;
}

export class ResourceDownload extends React.Component<IResourceDownloadProps> {
    static defaultProps = {
        type: UxType.BUTTON,
        size: 'large',
        disabled: false
    }
    render() {
        if (this.props.descriptor.type === RESOURCE_TYPE.FILE) {
            switch (this.props.type) {
                case UxType.BUTTON: return <>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={this.doDownload}
                    />
                    <label htmlFor="raised-button-file">
                        <Button component="span" > Download </Button>
                    </label>
                </>;
                case UxType.MENU: return <MenuItem disabled={this.props.disabled} style={{ height: 30 }} onClick={this.doDownload} key='resource.download'>
                    <ListItemIcon>
                        <GetAppIcon className='jr-MuiListItemIcon-root jr-mIcon mui' />
                    </ListItemIcon>
                    <Typography variant="inherit">Download</Typography>
                </MenuItem>;
            }
        }
        return <></>;
    }

    private doDownload = () => {
        this.context.runNoCancel(RepositoryApi.inst().load)('uuid:' + this.props.descriptor.uuid, true, true).then((response: AxiosResponse<IRepositoryItemDescriptor>) => {
            if (isBinary(this.props.descriptor)) {
                const byteCharacters = response.data.data;
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray]);
                saveAs(blob, this.props.descriptor.name);
            } else {
                const blob = new Blob([response.data.data], { type: "text/xml;charset=utf-8" });
                saveAs(blob, this.props.descriptor.name);
            }
            this.props.onClose();
        }).catch(error => console.log(error));
    }

}
ResourceDownload.contextType = RunContext;