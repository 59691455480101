/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../ui/APDescriptor";
import { PCategories } from "../../ui/composite/UPCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { MDivElementDataset } from "../dataset/MDatasetRun"; 
import { PValueExpression } from "../../ui/primitive/UPValueExpession";
import { PString } from "../../ui/primitive/UPString";
import { PInt } from "../../ui/primitive/UPInteger";
import { PEnum } from "../../ui/primitive/UPEnum";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { PConnectedTableDetail } from "../../ui/tables/UPConnectedTableDetail";

export const FTibcoMapMarker = {
    title: new PValueExpression({ id: 'title', vtype: new PString({ id: "title", label: 'Title', description: 'Marker Title' }) }),
    latitude: new PValueExpression({ id: 'latitude', vtype: new PInt({ id: "latitude", label: 'Latitude', description: 'Marker latitude', min: -85, max: 85, default: 37.7833 }) }),
    longitude: new PValueExpression({ id: 'longitude', vtype: new PInt({ id: "longitude", label: 'Longitude', description: 'Marker longitude', min: -180, max: 180, default: -122.4167 }) }),

    country: new PValueExpression({ id: 'country', vtype: new PString({ id: "country", label: 'Country', description: 'Country' }) }),
    state: new PValueExpression({ id: 'state', vtype: new PString({ id: "state", label: 'State', description: 'State' }) }),
    zip: new PValueExpression({ id: 'zip', vtype: new PString({ id: "zip", label: 'Zip', description: 'Zip' }) }),
    city: new PValueExpression({ id: 'city', vtype: new PString({ id: "city", label: 'City', description: 'City' }) }),
    street: new PValueExpression({ id: 'street', vtype: new PString({ id: "street", label: 'Street', description: 'Street' }) }),

    xoffset: new PValueExpression({ id: 'xoffset', vtype: new PInt({ id: "xoffset", label: 'X Offset', description: 'the horizontal offset of the marker icon measured in pixels', default: 0 }) }),
    yoffset: new PValueExpression({ id: 'yoffset', vtype: new PInt({ id: "yoffset", label: 'Y Offset', description: 'the horizontal offset of the marker icon measured in pixels', default: 0 }) }),

    hyperlink: new PValueExpression({ id: 'hyperlink', vtype: new PString({ id: "hyperlink", label: 'Hyperlink', description: 'The hyperlink text for the marker' }) }),
    target: new PValueExpression({ id: 'target', vtype: new PString({ id: "target", label: 'Target', description: 'The target for the marker', default: '_blank' }) }),
    anchor: new PValueExpression({
        id: 'anchor', vtype: new PEnum({
            id: "anchor", label: 'Anchor', description: 'The anchor point of the marker icon.', default: 'bottom-center',
            items: [{ key: "", value: "" }, { key: "bottom-left", value: "Bottom Left" }, { key: "bottom-center", value: "Bottom Center" }, { key: "bottom-right", value: "Bottom Right" },]
        })
    }),
    iconurl: new PValueExpression({ id: 'icon.url', vtype: new PString({ id: "icon.url", label: 'Icon URL', description: 'The icon for the marker' }) }),
    draggable: new PValueExpression({ id: 'draggable', vtype: new PBoolean({ id: "draggable", label: 'Draggable', description: 'The target for the marker', default: 'false', canBeNull: true }) }),
};

export const MTibcoMapMarker: APDescriptor = new PCategories({
    id: 'tibcomap.markers',
    layouts: [
        new PDiv({
            id: 'tibcomap.markers.markers',
            label: 'Markers', layouts: [new PConnectedTableDetail({
                id: 'items', label: 'Markers', rowLabel: 'Marker', rowLabelPaths: ['title/value', 'title/valueExpression'], expanded: true,
                layouts: [new PDiv({
                    label: 'Marker',
                    layouts: [new PAccordeon({
                        id: 'tibcomap.marker.location', label: "Location", expanded: true, layouts: [FTibcoMapMarker.latitude, FTibcoMapMarker.longitude,
                        FTibcoMapMarker.country, FTibcoMapMarker.state, FTibcoMapMarker.zip, FTibcoMapMarker.city, FTibcoMapMarker.street]
                    }),
                    FTibcoMapMarker.title,
                    FTibcoMapMarker.hyperlink, FTibcoMapMarker.target, FTibcoMapMarker.anchor,
                    FTibcoMapMarker.iconurl, FTibcoMapMarker.draggable, FTibcoMapMarker.xoffset, FTibcoMapMarker.yoffset,]
                })]
            })]
        }),
        { ...MDivElementDataset, path: 'dataset' }
    ]
});
