/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MGraphicElement } from "../common/MGraphicElement";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { ICONS } from "@jss/js-common";
import { MDatasetRun } from "../dataset/MDatasetRun";
import { WhenNoDataTypeEnum } from "../enums/WhenNoDataTypeEnum";
import { MTableCustom } from "./MTableCustom"; 

export const FTable = {
    whenNoDataType: WhenNoDataTypeEnum
};

export const MTable: APDescriptor = {
    type: ElementTypes.TABLE,
    label: 'Table',
    id: 'tableProeprtiesDescriptor',
    default: {
        width: 200, height: 200, TABLE_XMLNS_NAMESPACE: "jr",
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:jr': "http://jasperreports.sourceforge.net/jasperreports/components",
        elementIds: [],
        datasetRun: {subDataset: 'DefaultDataset'}
    },
    icon: ICONS.TABLE_ICON,
    layouts: [new PScrollableCategories({
        id: 'tablePropertiesTabsDescriptor',
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MGraphicElement.layouts, MTableCustom] }),
            new PDiv({
                label: 'Data',
                layouts: [FTable.whenNoDataType, MDatasetRun]
            })
        ]
    })]
};
