/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const HorizontalPositionEnum = new PEnum({
    id: 'horizontalPosition', label: 'HorizontalAlign.label',
    items: [
        { key: 'Left', value: 'HorizontalImageAlign.Left' },
        { key: 'Center', value: 'HorizontalImageAlign.Center' },
        { key: 'Right', value: 'HorizontalImageAlign.Right' }
    ]
});

