/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const XYLineChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M39 4v134h223V4z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M15.51 157.188L254.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M15.51 173.5L254.49 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M15.51 173.5L15.51 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M254.49 173.5L254.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#F55" strokeLinejoin="bevel" d="M19.01 165.344h14" clipPath="url(#clipPath1)" />
            <text x="37.01" y="168.113" stroke="none" strokeLinejoin="bevel" clipPath="url(#clipPath1)">Double Freight</text>
            <path fill="none" stroke="#55F" strokeLinejoin="bevel" d="M102.22 165.344h14" clipPath="url(#clipPath1)" />
            <text x="120.221" y="168.113" stroke="none" strokeLinejoin="bevel" clipPath="url(#clipPath1)">Normal Freight</text>
            <path fill="none" stroke="#5F5" strokeLinejoin="bevel" d="M186.17 165.344h14" clipPath="url(#clipPath1)" />
            <text x="204.17" y="168.113" stroke="none" strokeLinejoin="bevel" clipPath="url(#clipPath1)">Half Freight</text>
            <path fill="none" strokeWidth="0.5" d="M39 138L262 138" clipPath="url(#clipPath1)" />
            <text x="33.148" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.250</text>
            <path fill="none" strokeWidth="0.5" d="M47.144 140L47.144 138" clipPath="url(#clipPath1)" />
            <text x="95.41" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.500</text>
            <path fill="none" strokeWidth="0.5" d="M109.407 140L109.407 138" clipPath="url(#clipPath1)" />
            <text x="157.673" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.750</text>
            <path fill="none" strokeWidth="0.5" d="M171.669 140L171.669 138" clipPath="url(#clipPath1)" />
            <text x="219.936" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">11.000</text>
            <path fill="none" strokeWidth="0.5" d="M233.932 140L233.932 138" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M39 4L39 138" clipPath="url(#clipPath1)" />
            <text x="27.91" y="140.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
            <path fill="none" strokeWidth="0.5" d="M37 138L39 138" clipPath="url(#clipPath1)" />
            <text x="17.73" y="120.575" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">250</text>
            <path fill="none" strokeWidth="0.5" d="M37 117.806L39 117.806" clipPath="url(#clipPath1)" />
            <text x="17.73" y="100.381" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">500</text>
            <path fill="none" strokeWidth="0.5" d="M37 97.612L39 97.612" clipPath="url(#clipPath1)" />
            <text x="17.73" y="80.187" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">750</text>
            <path fill="none" strokeWidth="0.5" d="M37 77.418L39 77.418" clipPath="url(#clipPath1)" />
            <text x="10.098" y="59.993" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">1.000</text>
            <path fill="none" strokeWidth="0.5" d="M37 57.223L39 57.223" clipPath="url(#clipPath1)" />
            <text x="10.098" y="39.799" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">1.250</text>
            <path fill="none" strokeWidth="0.5" d="M37 37.029L39 37.029" clipPath="url(#clipPath1)" />
            <text x="10.098" y="19.605" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">1.500</text>
            <path fill="none" strokeWidth="0.5" d="M37 16.835L39 16.835" clipPath="url(#clipPath1)" />
         </g>
         <g fill="none" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path d="M47.144 4L47.144 138" clipPath="url(#clipPath2)" />
            <path d="M109.407 4L109.407 138" clipPath="url(#clipPath2)" />
            <path d="M171.669 4L171.669 138" clipPath="url(#clipPath2)" />
            <path d="M233.932 4L233.932 138" clipPath="url(#clipPath2)" />
            <path d="M39 138L262 138" clipPath="url(#clipPath2)" />
            <path d="M39 117.806L262 117.806" clipPath="url(#clipPath2)" />
            <path d="M39 97.612L262 97.612" clipPath="url(#clipPath2)" />
            <path d="M39 77.418L262 77.418" clipPath="url(#clipPath2)" />
            <path d="M39 57.223L262 57.223" clipPath="url(#clipPath2)" />
            <path d="M39 37.029L262 37.029" clipPath="url(#clipPath2)" />
            <path d="M39 16.835L262 16.835" clipPath="url(#clipPath2)" />
         </g>
         <g fill="none" stroke="#5F5" strokeLinejoin="bevel" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path d="M49.136 132.325L50.382 132.101" clipPath="url(#clipPath2)" />
            <path d="M50.382 132.101L72.298 131.444" clipPath="url(#clipPath2)" />
            <path d="M72.298 131.444L72.796 123.435" clipPath="url(#clipPath2)" />
            <path d="M72.796 123.435L76.532 133.882" clipPath="url(#clipPath2)" />
            <path d="M76.532 133.882L80.019 134.172" clipPath="url(#clipPath2)" />
            <path d="M80.019 134.172L82.011 132.896" clipPath="url(#clipPath2)" />
            <path d="M82.011 132.896L82.509 133.054" clipPath="url(#clipPath2)" />
            <path d="M82.509 133.054L85 135.258" clipPath="url(#clipPath2)" />
            <path d="M85 135.258L85.249 135.02" clipPath="url(#clipPath2)" />
            <path d="M85.249 135.02L91.226 136.736" clipPath="url(#clipPath2)" />
            <path d="M91.226 136.736L91.973 119.471" clipPath="url(#clipPath2)" />
            <path d="M91.973 119.471L94.962 136.064" clipPath="url(#clipPath2)" />
            <path d="M94.962 136.064L106.667 137.786" clipPath="url(#clipPath2)" />
            <path d="M106.667 137.786L112.893 106.095" clipPath="url(#clipPath2)" />
            <path d="M112.893 106.095L116.878 124.299" clipPath="url(#clipPath2)" />
            <path d="M116.878 124.299L127.089 136.947" clipPath="url(#clipPath2)" />
            <path d="M127.089 136.947L133.066 134.091" clipPath="url(#clipPath2)" />
            <path d="M133.066 134.091L133.565 136.582" clipPath="url(#clipPath2)" />
            <path d="M133.565 136.582L142.53 118.698" clipPath="url(#clipPath2)" />
            <path d="M142.53 118.698L150.998 134.846" clipPath="url(#clipPath2)" />
            <path d="M150.998 134.846L155.73 134.102" clipPath="url(#clipPath2)" />
            <path d="M155.73 134.102L158.719 126.995" clipPath="url(#clipPath2)" />
            <path d="M158.719 126.995L170.922 133.261" clipPath="url(#clipPath2)" />
            <path d="M170.922 133.261L175.156 132.125" clipPath="url(#clipPath2)" />
            <path d="M175.156 132.125L176.899 137.548" clipPath="url(#clipPath2)" />
            <path d="M176.899 137.548L177.398 134.105" clipPath="url(#clipPath2)" />
            <path d="M177.398 134.105L178.145 123.802" clipPath="url(#clipPath2)" />
            <path d="M178.145 123.802L182.877 132.884" clipPath="url(#clipPath2)" />
            <path d="M182.877 132.884L193.088 121.365" clipPath="url(#clipPath2)" />
            <path d="M193.088 121.365L195.08 136.981" clipPath="url(#clipPath2)" />
            <path d="M195.08 136.981L197.571 133.952" clipPath="url(#clipPath2)" />
            <path d="M197.571 133.952L207.782 131.427" clipPath="url(#clipPath2)" />
            <path d="M207.782 131.427L225.962 134.987" clipPath="url(#clipPath2)" />
            <path d="M225.962 134.987L228.702 123.74" clipPath="url(#clipPath2)" />
            <path d="M228.702 123.74L231.441 133.25" clipPath="url(#clipPath2)" />
            <path d="M231.441 133.25L235.924 134.791" clipPath="url(#clipPath2)" />
            <path d="M235.924 134.791L238.166 107.537" clipPath="url(#clipPath2)" />
            <path d="M238.166 107.537L247.132 135.857" clipPath="url(#clipPath2)" />
            <path d="M247.132 135.857L251.864 127.554" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M49.136 126.65L50.382 126.202" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M50.382 126.202L72.298 124.888" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M72.298 124.888L72.796 108.87" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M72.796 108.87L76.532 129.765" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M76.532 129.765L80.019 130.345" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M80.019 130.345L82.011 127.791" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M82.011 127.791L82.509 128.108" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M82.509 128.108L85 132.517" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M85 132.517L85.249 132.04" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M85.249 132.04L91.226 135.472" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M91.226 135.472L91.973 100.941" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M91.973 100.941L94.962 134.128" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M94.962 134.128L106.667 137.573" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M106.667 137.573L112.893 74.191" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M112.893 74.191L116.878 110.599" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M116.878 110.599L127.089 135.895" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M127.089 135.895L133.066 130.182" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M133.066 130.182L133.565 135.163" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M133.565 135.163L142.53 99.397" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M142.53 99.397L150.998 131.692" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M150.998 131.692L155.73 130.205" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M155.73 130.205L158.719 115.991" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M158.719 115.991L170.922 128.523" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M170.922 128.523L175.156 126.251" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M175.156 126.251L176.899 137.096" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M176.899 137.096L177.398 130.211" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M177.398 130.211L178.145 109.605" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M178.145 109.605L182.877 127.769" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M182.877 127.769L193.088 104.73" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M193.088 104.73L195.08 135.963" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M195.08 135.963L197.571 129.905" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M197.571 129.905L207.782 124.854" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M207.782 124.854L225.962 131.974" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M225.962 131.974L228.702 109.48" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M228.702 109.48L231.441 128.5" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M231.441 128.5L235.924 131.582" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M235.924 131.582L238.166 77.073" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M238.166 77.073L247.132 133.715" clipPath="url(#clipPath2)" />
            <path stroke="#55F" d="M247.132 133.715L251.864 117.108" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M49.136 115.3L50.382 114.403" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M50.382 114.403L72.298 111.775" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M72.298 111.775L72.796 79.739" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M72.796 79.739L76.532 121.53" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M76.532 121.53L80.019 122.69" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M80.019 122.69L82.011 117.583" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M82.011 117.583L82.509 118.216" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M82.509 118.216L85 127.034" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M85 127.034L85.249 126.079" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M85.249 126.079L91.226 132.945" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M91.226 132.945L91.973 63.883" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M91.973 63.883L94.962 130.255" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M94.962 130.255L106.667 137.145" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M106.667 137.145L112.893 10.381" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M112.893 10.381L116.878 83.198" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M116.878 83.198L127.089 133.79" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M127.089 133.79L133.066 122.365" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M133.066 122.365L133.565 132.326" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M133.565 132.326L142.53 60.794" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M142.53 60.794L150.998 125.384" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M150.998 125.384L155.73 122.41" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M155.73 122.41L158.719 93.982" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M158.719 93.982L170.922 119.045" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M170.922 119.045L175.156 114.502" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M175.156 114.502L176.899 136.192" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M176.899 136.192L177.398 122.421" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M177.398 122.421L178.145 81.209" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M178.145 81.209L182.877 117.538" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M182.877 117.538L193.088 71.459" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M193.088 71.459L195.08 133.926" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M195.08 133.926L197.571 121.809" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M197.571 121.809L207.782 111.707" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M207.782 111.707L225.962 125.948" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M225.962 125.948L228.702 80.96" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M228.702 80.96L231.441 119" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M231.441 119L235.924 125.163" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M235.924 125.163L238.166 16.147" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M238.166 16.147L247.132 129.43" clipPath="url(#clipPath2)" />
            <path stroke="#F55" d="M247.132 129.43L251.864 96.216" clipPath="url(#clipPath2)" />
         </g>
      </svg>
   );
}
export default XYLineChartIcon;