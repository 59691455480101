/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { APDescriptor } from "../../ui/APDescriptor";
import { HorizontalTextAlignEnum } from "../enums/HorizontalTextAlignEnum";
import { VerticalTextAlignEnum } from "../enums/VerticalTextAlignEnum";
import { MParagraph } from "./MParagraph";
import { MFont } from "./MFont";



export const FTextElement = {
    horizontalTextAlign: HorizontalTextAlignEnum,
    verticalTextAlign: VerticalTextAlignEnum,
};

export const MTextElement: APDescriptor = {
    type: 'MTextElement',
    id: 'textElementProeprtiesDescriptor',
    layouts: [new PAccordeon({
        label: 'Text Arrangement', expanded: true,
        layouts: [FTextElement.horizontalTextAlign, FTextElement.verticalTextAlign]
    }), ...MFont.layouts, MParagraph]
};
