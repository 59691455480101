/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { defaultReportState } from "../reportReducer";
import { Map } from 'immutable';
import { removeElement } from "./reducerUtils";

export const refactorGroup = (state = defaultReportState, action: any, newState: Map<string, any> | null): Map<string, any> | null => {
    if (action.properties.name) {
        const oldName = state.getIn([...action.path, 'name']);
        const newName = action.properties.name;
        let result = newState;
        const bookSections : Map<string, any> = state.getIn(['model', 'bookSections']) as Map<string, any>;
        let oldHeaderSection : Map<string, any> | undefined = bookSections.get('groupHeaderSection_' + oldName);
        if (oldHeaderSection){
            oldHeaderSection = oldHeaderSection.set('groupName', newName);
            result = result.deleteIn(['model', 'bookSections', 'groupHeaderSection_' + oldName]);
            result = result.setIn(['model', 'bookSections', 'groupHeaderSection_' + newName], oldHeaderSection);
        }
        let oldFooterSection : Map<string, any> | undefined = bookSections.get('groupFooterSection_' + oldName);
        if (oldFooterSection){
            oldFooterSection = oldFooterSection.set('groupName', newName);
            result = result.deleteIn(['model', 'bookSections', 'groupFooterSection_' + oldName]);
            result = result.setIn(['model', 'bookSections', 'groupFooterSection_' + newName], oldFooterSection);
        }

        const bands : Map<string, Map<string, any>> = state.getIn(['model', 'bands']) as Map<string, any>;
        const groupBandsKeys = [];
        bands.forEach((value, key) => {
            if (value.get('groupName') === oldName) {
                groupBandsKeys.push(key);
            }
        });
        groupBandsKeys.forEach((key) => {
            result = result.setIn(['model', 'bands', key, 'groupName'], newName);
        });
        return result;
    }
    return newState;
};

export const refactorDeleteGroup = (state = defaultReportState, action: any, newState: Map<string, any> | null): Map<string, any> | null => {
    const oldName = state.getIn([...action.path, 'name']);
    let result = newState;
   /* const bookSections : Map<string, any> = state.getIn(['model', 'bookSections']) as Map<string, any>;
    let oldHeaderSection : Map<string, any> | undefined = bookSections.get('groupHeaderSection_' + oldName);
    if (oldHeaderSection){
        oldHeaderSection = oldHeaderSection.set('groupName', newName);
        result = result.deleteIn(['model', 'bookSections', 'groupHeaderSection_' + oldName]);
        result = result.setIn(['model', 'bookSections', 'groupHeaderSection_' + newName], oldHeaderSection);
    }
    let oldFooterSection : Map<string, any> | undefined = bookSections.get('groupFooterSection_' + oldName);
    if (oldFooterSection){
        oldFooterSection = oldFooterSection.set('groupName', newName);
        result = result.deleteIn(['model', 'bookSections', 'groupFooterSection_' + oldName]);
        result = result.setIn(['model', 'bookSections', 'groupFooterSection_' + newName], oldFooterSection);
    }*/

    const bands : Map<string, Map<string, any>> = state.getIn(['model', 'bands']) as Map<string, any>;
    const groupBandsKeys = [];
    bands.forEach((value, key) => {
        if (value.get('groupName') === oldName) {
            groupBandsKeys.push(key);
        }
    });
    groupBandsKeys.forEach((key) => {
        result = removeElement(result, ['bands', key])
    });
    return result;
};