/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Conf, Pref } from "@jss/js-rest-api";
import { Select } from "@jss/js-common";
import React from "react";
import { connect } from "react-redux";
import { setTheme } from "../../store/actions/actions";
import i18n from "../../i18n";

interface IThemeSelector {
    theme?: string,
    setTheme?: (newThem: string | undefined) => void;
}

class ThemeSelector extends React.Component<IThemeSelector> {

    private getCurrentTheme = () => {
        if (this.props.theme) {
            return this.props.theme;
        } else {
            return Conf.get(Pref.THEME);
        }
    }

    public render() {
        const themes: string[] = Conf.get(Pref.THEME_AVAILABLE);
        if (themes && themes.length > 0) {
            return <Select items={themes.map((currentTheme) => { return { key: currentTheme, value: i18n.t(currentTheme) }; })}
                className="tc-jsw-theme-selector"
                value={this.getCurrentTheme()}
                onItemSelected={this.onSelection} />
        }
        return null;
    }

    private onSelection = (newValue: string) => {
        Conf.set(Pref.THEME, newValue);
        if (this.props.setTheme)
            this.props.setTheme(newValue);
    }
}

const mapStateToProps = (state: any) => {
    return {
        theme: state.routerReducer.theme,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setTheme: (newTheme: string | undefined) => dispatch(setTheme(newTheme)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSelector);
