/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StackedColumnChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
  return (<svg
    xmlns="http://www.w3.org/2000/svg"
    width="802"
    height="493"
    version="1.1"
    style={{
      WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      userSelect: "none",
      position: "relative",
    }}
    cursor="default"
    overflow="hidden"
    viewBox="0 0 802 493"
    {...props}
  >
    <defs>
      <linearGradient
        id="19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100"
        x1="0"
        x2="0"
        y1="1"
        y2="0"
      >
        <stop offset="0%" stopColor="#cbcbcb" stopOpacity="0.5"></stop>
        <stop offset="100%" stopColor="#e9e9e9" stopOpacity="0.5"></stop>
      </linearGradient>
      <clipPath id="11EB130F-7AC9-477C-883A-235CF414D12A">
        <path d="M79 55H784V411H79z"></path>
      </clipPath>
      <clipPath id="4916578B-6B95-47F2-B9A6-A928E620D356">
        <path d="M79 55H784V411H79z"></path>
      </clipPath>
      <linearGradient
        id="19-270-rgba_175_216_248_1_-rgba_255_255_255_1_"
        x1="0"
        x2="0"
        y1="0"
        y2="1"
      >
        <stop offset="0%" stopColor="#afd8f8"></stop>
        <stop offset="100%" stopColor="#fff"></stop>
      </linearGradient>
      <clipPath id="1432D9C3-6C9A-4E65-A47B-6FD45478D012">
        <path d="M79 55H784V411H79z"></path>
      </clipPath>
      <clipPath id="7C8A425C-B722-4B1B-88DF-131158CBA6C4">
        <path d="M79 55H784V411H79z"></path>
      </clipPath>
      <linearGradient
        id="19-270-rgba_246_189_15_1_-rgba_255_255_255_1_"
        x1="0"
        x2="0"
        y1="0"
        y2="1"
      >
        <stop offset="0%" stopColor="#f6bd0f"></stop>
        <stop offset="100%" stopColor="#fff"></stop>
      </linearGradient>
      <clipPath id="F5101E03-FAB2-474F-B687-567667D9FBA8">
        <path d="M79 55H784V411H79z"></path>
      </clipPath>
      <clipPath id="DCD0862E-DE81-4093-99EE-878D4249A0CB">
        <path d="M79 55H784V411H79z"></path>
      </clipPath>
      <linearGradient
        id="19-270-rgba_139_186_0_1_-rgba_255_255_255_1_"
        x1="0"
        x2="0"
        y1="0"
        y2="1"
      >
        <stop offset="0%" stopColor="#8bba00"></stop>
        <stop offset="100%" stopColor="#fff"></stop>
      </linearGradient>
      <linearGradient
        id="19-90-rgba_175_216_248_1__0-rgba_175_216_248_1__30-rgba_223_240_252_1__60-rgba_175_216_248_1__90-rgba_175_216_248_1__100"
        x1="0"
        x2="0"
        y1="1"
        y2="0"
      >
        <stop offset="0%" stopColor="#afd8f8"></stop>
        <stop offset="30%" stopColor="#afd8f8"></stop>
        <stop offset="60%" stopColor="#dff0fc"></stop>
        <stop offset="90%" stopColor="#afd8f8"></stop>
        <stop offset="100%" stopColor="#afd8f8"></stop>
      </linearGradient>
      <linearGradient
        id="19-90-rgba_246_189_15_1__0-rgba_246_189_15_1__30-rgba_252_229_159_1__60-rgba_246_189_15_1__90-rgba_246_189_15_1__100"
        x1="0"
        x2="0"
        y1="1"
        y2="0"
      >
        <stop offset="0%" stopColor="#f6bd0f"></stop>
        <stop offset="30%" stopColor="#f6bd0f"></stop>
        <stop offset="60%" stopColor="#fce59f"></stop>
        <stop offset="90%" stopColor="#f6bd0f"></stop>
        <stop offset="100%" stopColor="#f6bd0f"></stop>
      </linearGradient>
      <linearGradient
        id="19-90-rgba_139_186_0_1__0-rgba_139_186_0_1__30-rgba_209_228_153_1__60-rgba_139_186_0_1__90-rgba_139_186_0_1__100"
        x1="0"
        x2="0"
        y1="1"
        y2="0"
      >
        <stop offset="0%" stopColor="#8bba00"></stop>
        <stop offset="30%" stopColor="#8bba00"></stop>
        <stop offset="60%" stopColor="#d1e499"></stop>
        <stop offset="90%" stopColor="#8bba00"></stop>
        <stop offset="100%" stopColor="#8bba00"></stop>
      </linearGradient>
    </defs>
    <g className="raphael-group-20-background">
      <rect
        width="800"
        height="491"
        x="1"
        y="1"
        fill="url('#19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100')"
        rx="0"
        ry="0"
      ></rect>
      <rect
        width="801"
        height="492"
        x="0.5"
        y="0.5"
        fill="none"
        stroke="#767575"
        strokeOpacity="0.5"
        rx="0"
        ry="0"
      ></rect>
    </g>
    <g className="raphael-group-27-canvas">
      <rect
        width="707"
        height="358"
        x="78"
        y="54"
        fill="none"
        stroke="#545454"
        strokeWidth="2"
        rx="0"
        ry="0"
      ></rect>
      <rect
        width="705"
        height="356"
        x="79"
        y="55"
        fill="#FFF"
        strokeWidth="0"
        rx="0"
        ry="0"
      ></rect>
    </g>
    <g className="raphael-group-30-axisbottom">
      <g
        fill="#EEE"
        fillOpacity="0.5"
        stroke="#000"
        strokeWidth="0"
        className="raphael-group-39-y-axis-bands"
      >
        <rect width="705" height="71.2" x="79" y="268.6" rx="0" ry="0"></rect>
        <rect width="705" height="71.2" x="79" y="126.2" rx="0" ry="0"></rect>
      </g>
      <g
        fill="none"
        stroke="#717170"
        strokeOpacity="0.4"
        className="raphael-group-41-y-axis-lines"
        shapeRendering="crispedges"
      >
        <path d="M79 340.5h705M79 269.5h705M79 197.5h705M79 126.5h705"></path>
      </g>
      <g className="fusioncharts-xaxis-0-gridlabels">
        <text
          x="129.357"
          y="427"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="129.357" dy="-2">
            Denmark
          </tspan>
        </text>
        <text
          x="230.071"
          y="427"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="230.071" dy="-2">
            France
          </tspan>
        </text>
        <text
          x="330.786"
          y="427"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="330.786" dy="-2.008">
            Germany
          </tspan>
        </text>
        <text
          x="431.5"
          y="427"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="431.5" dy="-2.008">
            Italy
          </tspan>
        </text>
        <text
          x="532.214"
          y="427"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="532.214" dy="-2.008">
            Spain
          </tspan>
        </text>
        <text
          x="632.929"
          y="427"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="632.929" dy="-2">
            UK
          </tspan>
        </text>
        <text
          x="733.643"
          y="427"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="733.643" dy="-2">
            USA
          </tspan>
        </text>
      </g>
      <g className="fusioncharts-yaxis-0-gridlabels">
        <text
          x="73"
          y="411"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          fontStyle="undefined"
          fontWeight="undefined"
          textAnchor="end"
        >
          <tspan x="73" dy="4">
            0.00
          </tspan>
        </text>
        <text
          x="73"
          y="339.8"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          fontStyle="undefined"
          fontWeight="undefined"
          textAnchor="end"
        >
          <tspan x="73" dy="3.995">
            10.00K
          </tspan>
        </text>
        <text
          x="73"
          y="268.6"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          fontStyle="undefined"
          fontWeight="undefined"
          textAnchor="end"
        >
          <tspan x="73" dy="3.998">
            20.00K
          </tspan>
        </text>
        <text
          x="73"
          y="197.4"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          fontStyle="undefined"
          fontWeight="undefined"
          textAnchor="end"
        >
          <tspan x="73" dy="4.002">
            30.00K
          </tspan>
        </text>
        <text
          x="73"
          y="126.2"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          fontStyle="undefined"
          fontWeight="undefined"
          textAnchor="end"
        >
          <tspan x="73" dy="4.005">
            40.00K
          </tspan>
        </text>
        <text
          x="73"
          y="55"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          fontStyle="undefined"
          fontWeight="undefined"
          textAnchor="end"
        >
          <tspan x="73" dy="4">
            50.00K
          </tspan>
        </text>
      </g>
      <g className="raphael-group-50-axis-name">
        <text
          fill="#555"
          className="fusioncharts-yaxis-0-title"
          fontSize="10"
          textAnchor="middle"
          transform="rotate(-90 125.5 107.5)"
        >
          <tspan x="0" dy="10">
            Orders
          </tspan>
        </text>
        <text
          fill="#555"
          className="fusioncharts-xaxis-0-title"
          fontSize="10"
          textAnchor="middle"
          transform="translate(431.5 432)"
        >
          <tspan x="0" dy="10">
            States
          </tspan>
        </text>
      </g>
    </g>
    <g className="raphael-group-21-dataset">
      <g
        fill="none"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="raphael-group-150-shadow"
        clipPath="url('#DCD0862E-DE81-4093-99EE-878D4249A0CB')"
      >
        <rect
          width="50"
          height="5"
          x="104.5"
          y="376.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="5"
          x="104.5"
          y="376.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="5"
          x="104.5"
          y="376.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="15"
          x="205.5"
          y="305.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="15"
          x="205.5"
          y="305.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="15"
          x="205.5"
          y="305.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="40"
          x="305.5"
          y="129.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="40"
          x="305.5"
          y="129.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="40"
          x="305.5"
          y="129.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="3"
          x="406.5"
          y="389.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="3"
          x="406.5"
          y="389.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="3"
          x="406.5"
          y="389.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="3"
          x="507.5"
          y="389.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="3"
          x="507.5"
          y="389.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="3"
          x="507.5"
          y="389.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="10"
          x="607.5"
          y="337.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="10"
          x="607.5"
          y="337.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="10"
          x="607.5"
          y="337.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="49"
          x="708.5"
          y="67.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="49"
          x="708.5"
          y="67.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="49"
          x="708.5"
          y="67.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
      </g>
      <g
        fill="none"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="raphael-group-112-shadow"
        clipPath="url('#7C8A425C-B722-4B1B-88DF-131158CBA6C4')"
      >
        <rect
          width="50"
          height="10"
          x="104.5"
          y="381.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="10"
          x="104.5"
          y="381.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="10"
          x="104.5"
          y="381.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="30"
          x="205.5"
          y="320.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="30"
          x="205.5"
          y="320.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="30"
          x="205.5"
          y="320.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="81"
          x="305.5"
          y="169.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="81"
          x="305.5"
          y="169.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="81"
          x="305.5"
          y="169.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="6"
          x="406.5"
          y="392.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="6"
          x="406.5"
          y="392.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="6"
          x="406.5"
          y="392.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="6"
          x="507.5"
          y="392.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="6"
          x="507.5"
          y="392.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="6"
          x="507.5"
          y="392.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="21"
          x="607.5"
          y="347.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="21"
          x="607.5"
          y="347.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="21"
          x="607.5"
          y="347.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="98"
          x="708.5"
          y="116.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="98"
          x="708.5"
          y="116.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="98"
          x="708.5"
          y="116.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
      </g>
      <g
        fill="none"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="raphael-group-74-shadow"
        clipPath="url('#4916578B-6B95-47F2-B9A6-A928E620D356')"
      >
        <rect
          width="50"
          height="21"
          x="104.5"
          y="391.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="21"
          x="104.5"
          y="391.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="21"
          x="104.5"
          y="391.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="62"
          x="205.5"
          y="350.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="62"
          x="205.5"
          y="350.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="62"
          x="205.5"
          y="350.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="162"
          x="305.5"
          y="250.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="162"
          x="305.5"
          y="250.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="162"
          x="305.5"
          y="250.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="14"
          x="406.5"
          y="398.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="14"
          x="406.5"
          y="398.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="14"
          x="406.5"
          y="398.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="14"
          x="507.5"
          y="398.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="14"
          x="507.5"
          y="398.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="14"
          x="507.5"
          y="398.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="44"
          x="607.5"
          y="368.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="44"
          x="607.5"
          y="368.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="44"
          x="607.5"
          y="368.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="198"
          x="708.5"
          y="214.5"
          strokeOpacity="0.05"
          strokeWidth="5"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="198"
          x="708.5"
          y="214.5"
          strokeOpacity="0.1"
          strokeWidth="3"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
        <rect
          width="50"
          height="198"
          x="708.5"
          y="214.5"
          strokeOpacity="0.15"
          rx="0"
          ry="0"
          transform="translate(1 1)"
        ></rect>
      </g>
      <g
        fill="url('#19-270-rgba_175_216_248_1_-rgba_255_255_255_1_')"
        stroke="#333"
        className="raphael-group-72-columns"
        clipPath="url('#11EB130F-7AC9-477C-883A-235CF414D12A')"
      >
        <rect width="50" height="21" x="104.5" y="391.5" rx="0" ry="0"></rect>
        <rect width="50" height="62" x="205.5" y="350.5" rx="0" ry="0"></rect>
        <rect
          width="50"
          height="162"
          x="305.5"
          y="250.5"
          rx="0"
          ry="0"
        ></rect>
        <rect width="50" height="14" x="406.5" y="398.5" rx="0" ry="0"></rect>
        <rect width="50" height="14" x="507.5" y="398.5" rx="0" ry="0"></rect>
        <rect width="50" height="44" x="607.5" y="368.5" rx="0" ry="0"></rect>
        <rect
          width="50"
          height="198"
          x="708.5"
          y="214.5"
          rx="0"
          ry="0"
        ></rect>
      </g>
      <g
        fill="url('#19-270-rgba_246_189_15_1_-rgba_255_255_255_1_')"
        stroke="#333"
        className="raphael-group-110-columns"
        clipPath="url('#1432D9C3-6C9A-4E65-A47B-6FD45478D012')"
      >
        <rect width="50" height="10" x="104.5" y="381.5" rx="0" ry="0"></rect>
        <rect width="50" height="30" x="205.5" y="320.5" rx="0" ry="0"></rect>
        <rect width="50" height="81" x="305.5" y="169.5" rx="0" ry="0"></rect>
        <rect width="50" height="6" x="406.5" y="392.5" rx="0" ry="0"></rect>
        <rect width="50" height="6" x="507.5" y="392.5" rx="0" ry="0"></rect>
        <rect width="50" height="21" x="607.5" y="347.5" rx="0" ry="0"></rect>
        <rect width="50" height="98" x="708.5" y="116.5" rx="0" ry="0"></rect>
      </g>
      <g
        fill="url('#19-270-rgba_139_186_0_1_-rgba_255_255_255_1_')"
        stroke="#333"
        className="raphael-group-148-columns"
        clipPath="url('#F5101E03-FAB2-474F-B687-567667D9FBA8')"
      >
        <rect width="50" height="5" x="104.5" y="376.5" rx="0" ry="0"></rect>
        <rect width="50" height="15" x="205.5" y="305.5" rx="0" ry="0"></rect>
        <rect width="50" height="40" x="305.5" y="129.5" rx="0" ry="0"></rect>
        <rect width="50" height="3" x="406.5" y="389.5" rx="0" ry="0"></rect>
        <rect width="50" height="3" x="507.5" y="389.5" rx="0" ry="0"></rect>
        <rect width="50" height="10" x="607.5" y="337.5" rx="0" ry="0"></rect>
        <rect width="50" height="49" x="708.5" y="67.5" rx="0" ry="0"></rect>
      </g>
    </g>
    <g className="raphael-group-26-datalabels">
      <g className="raphael-group-203-datalabel">
        <text
          x="129.5"
          y="402"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="129.5" dy="4">
            2.79K
          </tspan>
        </text>
        <text
          x="230.5"
          y="381.5"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="230.5" dy="4">
            8.48K
          </tspan>
        </text>
        <text
          x="330.5"
          y="331.5"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="330.5" dy="4">
            22.57K
          </tspan>
        </text>
        <text
          x="431.5"
          y="403"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="431.5" dy="4">
            1.73K
          </tspan>
        </text>
        <text
          x="532.5"
          y="403"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="532.5" dy="4">
            1.72K
          </tspan>
        </text>
        <text
          x="632.5"
          y="390.5"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="632.5" dy="4">
            5.91K
          </tspan>
        </text>
        <text
          x="733.5"
          y="313.5"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="733.5" dy="4">
            27.54K
          </tspan>
        </text>
      </g>
      <g className="raphael-group-211-datalabel">
        <text
          x="129.5"
          y="386.5"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="129.5" dy="4">
            1.40K
          </tspan>
        </text>
        <text
          x="230.5"
          y="335.5"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="230.5" dy="4">
            4.24K
          </tspan>
        </text>
        <text
          x="330.5"
          y="210"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="330.5" dy="4">
            11.28K
          </tspan>
        </text>
        <text
          x="431.5"
          y="395.5"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="431.5" dy="4">
            864.44
          </tspan>
        </text>
        <text
          x="532.5"
          y="395.5"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="532.5" dy="4">
            861.89
          </tspan>
        </text>
        <text
          x="632.5"
          y="358"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="632.5" dy="4">
            2.95K
          </tspan>
        </text>
        <text
          x="733.5"
          y="165.5"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="733.5" dy="4">
            13.77K
          </tspan>
        </text>
      </g>
      <g className="raphael-group-219-datalabel">
        <text
          x="129.5"
          y="379"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="129.5" dy="4">
            698.10
          </tspan>
        </text>
        <text
          x="230.5"
          y="313"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="230.5" dy="4">
            2.12K
          </tspan>
        </text>
        <text
          x="330.5"
          y="149.5"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="330.5" dy="4">
            5.64K
          </tspan>
        </text>
        <text
          x="431.5"
          y="391"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="431.5" dy="4">
            432.22
          </tspan>
        </text>
        <text
          x="532.5"
          y="391"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="532.5" dy="4">
            430.95
          </tspan>
        </text>
        <text
          x="632.5"
          y="342.5"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="632.5" dy="4">
            1.48K
          </tspan>
        </text>
        <text
          x="733.5"
          y="92"
          fill="#555"
          className="fusioncharts-label"
          fontFamily="Verdana, sans"
          fontSize="10"
          textAnchor="middle"
        >
          <tspan x="733.5" dy="4">
            6.89K
          </tspan>
        </text>
      </g>
    </g>
    <g className="fusioncharts-legend" transform="translate(279.5 452.5)">
      <rect
        width="304"
        height="25"
        fill="none"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.05"
        strokeWidth="5"
        rx="0"
        ry="0"
        transform="translate(1 1)"
      ></rect>
      <rect
        width="304"
        height="25"
        fill="none"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.1"
        strokeWidth="3"
        rx="0"
        ry="0"
        transform="translate(1 1)"
      ></rect>
      <rect
        width="304"
        height="25"
        fill="none"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.15"
        rx="0"
        ry="0"
        transform="translate(1 1)"
      ></rect>
      <rect
        width="304"
        height="25"
        fill="#FFF"
        stroke="#545454"
        rx="0"
        ry="0"
      ></rect>
      <text
        x="23"
        y="6.5"
        fill="#555"
        className="fusioncharts-legenditem"
        cursor="pointer"
        fontSize="10"
      >
        <tspan x="23" dy="9.25">
          Double Freight
        </tspan>
      </text>
      <path
        fill="url('#19-90-rgba_175_216_248_1__0-rgba_175_216_248_1__30-rgba_223_240_252_1__60-rgba_175_216_248_1__90-rgba_175_216_248_1__100')"
        stroke="#CFE8FB"
        strokeWidth="0.5"
        d="M7 18v-7.7h2.75V18zm4.125 0V7h2.75v11zm4.125 0v-4.4H18V18z"
        cursor="pointer"
      ></path>
      <rect
        width="95"
        height="17"
        x="4"
        y="4"
        fill="silver"
        fillOpacity="0"
        cursor="pointer"
        rx="0"
        ry="0"
      ></rect>
      <text
        x="128.85"
        y="6.5"
        fill="#555"
        className="fusioncharts-legenditem"
        cursor="pointer"
        fontSize="10"
      >
        <tspan x="128.85" dy="9.25">
          Normal Freight
        </tspan>
      </text>
      <path
        fill="url('#19-90-rgba_246_189_15_1__0-rgba_246_189_15_1__30-rgba_252_229_159_1__60-rgba_246_189_15_1__90-rgba_246_189_15_1__100')"
        stroke="#FAD76F"
        strokeWidth="0.5"
        d="M112.85 18v-7.7h2.75V18zm4.125 0V7h2.75v11zm4.125 0v-4.4h2.75V18z"
        cursor="pointer"
      ></path>
      <rect
        width="97"
        height="17"
        x="109.85"
        y="4"
        fill="silver"
        fillOpacity="0"
        cursor="pointer"
        rx="0"
        ry="0"
      ></rect>
      <text
        x="236.7"
        y="6.5"
        fill="#555"
        className="fusioncharts-legenditem"
        cursor="pointer"
        fontSize="10"
      >
        <tspan x="236.7" dy="9.25">
          Half Freight
        </tspan>
      </text>
      <path
        fill="url('#19-90-rgba_139_186_0_1__0-rgba_139_186_0_1__30-rgba_209_228_153_1__60-rgba_139_186_0_1__90-rgba_139_186_0_1__100')"
        stroke="#B9D666"
        strokeWidth="0.5"
        d="M220.7 18v-7.7h2.75V18zm4.125 0V7h2.75v11zm4.125 0v-4.4h2.75V18z"
        cursor="pointer"
      ></path>
      <rect
        width="80"
        height="17"
        x="217.7"
        y="4"
        fill="silver"
        fillOpacity="0"
        cursor="pointer"
        rx="0"
        ry="0"
      ></rect>
    </g>
    <g className="raphael-group-200-caption">
      <text
        x="431.5"
        y="16"
        fill="#555"
        className="fusioncharts-caption"
        fontFamily="Verdana, sans"
        fontSize="13"
        fontWeight="bold"
        textAnchor="middle"
      >
        <tspan x="431.5" dy="12.992">
          Chart Caption
        </tspan>
      </text>
      <text
        x="431.5"
        y="34"
        fill="#555"
        className="fusioncharts-subcaption"
        fontFamily="Verdana, sans"
        fontSize="11"
        fontWeight="bold"
        textAnchor="middle"
      >
        <tspan x="431.5" dy="10.852">
          Chart Sub Caption
        </tspan>
      </text>
    </g>
    <g className="raphael-group-22-hot">
      <g
        fill="silver"
        fillOpacity="0"
        stroke="silver"
        strokeOpacity="0"
        className="raphael-group-73-col-hot"
      >
        <rect width="50" height="21" x="104.5" y="391.5" rx="0" ry="0"></rect>
        <rect width="50" height="62" x="205.5" y="350.5" rx="0" ry="0"></rect>
        <rect
          width="50"
          height="162"
          x="305.5"
          y="250.5"
          rx="0"
          ry="0"
        ></rect>
        <rect width="50" height="14" x="406.5" y="398.5" rx="0" ry="0"></rect>
        <rect width="50" height="14" x="507.5" y="398.5" rx="0" ry="0"></rect>
        <rect width="50" height="44" x="607.5" y="368.5" rx="0" ry="0"></rect>
        <rect
          width="50"
          height="198"
          x="708.5"
          y="214.5"
          rx="0"
          ry="0"
        ></rect>
      </g>
      <g
        fill="silver"
        fillOpacity="0"
        stroke="silver"
        strokeOpacity="0"
        className="raphael-group-111-col-hot"
      >
        <rect width="50" height="10" x="104.5" y="381.5" rx="0" ry="0"></rect>
        <rect width="50" height="30" x="205.5" y="320.5" rx="0" ry="0"></rect>
        <rect width="50" height="81" x="305.5" y="169.5" rx="0" ry="0"></rect>
        <rect width="50" height="6" x="406.5" y="392.5" rx="0" ry="0"></rect>
        <rect width="50" height="6" x="507.5" y="392.5" rx="0" ry="0"></rect>
        <rect width="50" height="21" x="607.5" y="347.5" rx="0" ry="0"></rect>
        <rect width="50" height="98" x="708.5" y="116.5" rx="0" ry="0"></rect>
      </g>
      <g
        fill="silver"
        fillOpacity="0"
        stroke="silver"
        strokeOpacity="0"
        className="raphael-group-149-col-hot"
      >
        <rect width="50" height="5" x="104.5" y="376.5" rx="0" ry="0"></rect>
        <rect width="50" height="15" x="205.5" y="305.5" rx="0" ry="0"></rect>
        <rect width="50" height="40" x="305.5" y="129.5" rx="0" ry="0"></rect>
        <rect width="50" height="5" x="406.5" y="388.5" rx="0" ry="0"></rect>
        <rect width="50" height="5" x="507.5" y="388.5" rx="0" ry="0"></rect>
        <rect width="50" height="10" x="607.5" y="337.5" rx="0" ry="0"></rect>
        <rect width="50" height="49" x="708.5" y="67.5" rx="0" ry="0"></rect>
      </g>
    </g>
  </svg>)
};

export default StackedColumnChartIcon;