/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map, OrderedMap } from 'immutable';
import { initializeMapWithObject } from '../../../../sagas/report/document/documentFactory';
import { ElementTypes } from '../../../../sagas/report/document/elementTypes';
import { addAttributeToMap, addBooleanAttributeToMap, addChildToParent, addChildValueToMap, addIntAttributeToMap, createBox, createChartCrosstabDataset, createReportElement, generatePath, getChildNodeByName, getPropertyValue, IObjectCounter } from './JrxmlHelpers';
import { parseNode } from './JrxmlReader';

const CROSSTAB_TITLE_CELL_NAME = 'CROSSTAB_TITLE_CELL';
const CROSSTAB_ROW_HEADER_NAME = 'CROSSTAB_ROW_HEADER';
const CROSSTAB_COLUMN_HEADER_NAME = 'CROSSTAB_COLUMN_HEADER';
const CROSSTAB_TOTAL_ROW_HEADER_NAME = 'CROSSTAB_TOTAL_ROW_HEADER';
const CROSSTAB_TOTAL_COLUMN_HEADER_NAME = 'CROSSTAB_TOTAL_COLUMN_HEADER';
const CROSSTAB_HEADER_CELL_NAME = 'CROSSTAB_HEADER_CELL';
const CROSSTAB_NO_DATA_CELL_NAME = 'CROSSTAB_NO_DATA_CELL';
const CROSSTAB_NO_DATA_CELL_PLACEHOLDER = 'CROSSTAB_NO_DATA_CELL_PLACEHOLDER';
const CROSSTAB_HEADER_NAME = 'CROSSTAB_HEADER';
const CROSSTAB_ROW_GROUP_NAME = 'CROSSTAB_ROW_GROUP';
const CROSSTAB_COLUMN_GROUP_NAME = 'CROSSTAB_COLUMN_GROUP';
const CROSSTAB_CELL_NAME = 'CROSSTAB_CELL';
const CROSSTAB_MEASURE_NAME = 'CROSSTAB_MEASURE';

export const CrosstabTypes = {
    CROSSTAB_TITLE_CELL_NAME,
    CROSSTAB_ROW_HEADER_NAME,
    CROSSTAB_COLUMN_HEADER_NAME,
    CROSSTAB_TOTAL_ROW_HEADER_NAME,
    CROSSTAB_TOTAL_COLUMN_HEADER_NAME,
    CROSSTAB_HEADER_CELL_NAME,
    CROSSTAB_NO_DATA_CELL_NAME,
    CROSSTAB_NO_DATA_CELL_PLACEHOLDER,
    CROSSTAB_HEADER_NAME,
    CROSSTAB_ROW_GROUP_NAME,
    CROSSTAB_COLUMN_GROUP_NAME,
    CROSSTAB_CELL_NAME,
    CROSSTAB_MEASURE_NAME
}

export const createCrosstabElement = (crosstabElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    const reportElementNode : Element = getChildNodeByName(crosstabElement, "reportElement") as Element;
    let crosstab : Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.CROSSTAB, objectCounter);
    crosstab = crosstab.set("parameters", List<Map<string, string>>());
    crosstab = crosstab.set("elementIds", List<string>());
    crosstab = crosstab.set("measures", List<Map<string,any>>());
    crosstab = addBooleanAttributeToMap(crosstabElement, "isRepeatColumnHeaders", crosstab);
    crosstab = addBooleanAttributeToMap(crosstabElement, "isRepeatRowHeaders", crosstab);
    crosstab = addBooleanAttributeToMap(crosstabElement, "ignoreWidth", crosstab);
    crosstab = addAttributeToMap(crosstabElement, "columnBreakOffset", crosstab);
    crosstab = addAttributeToMap(crosstabElement, "runDirection", crosstab);
    crosstab = addAttributeToMap(crosstabElement, "horizontalPosition", crosstab);

    crosstab = addChildValueToMap(crosstabElement, "parametersMapExpression", crosstab);

    const boxElement = getChildNodeByName(crosstabElement, "box");
    if (boxElement !== null){
        const box : Map<string, any> = createBox(boxElement as Element);
        crosstab = crosstab.set("box", box);
    }

    // search for the parameters
    crosstabElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "crosstabParameter"){
           let parameter = Map<string, string>();
           parameter = addAttributeToMap(element as Element, "name", parameter);
           parameter = addAttributeToMap(element as Element, "class", parameter);
           parameter = addChildValueToMap(element as Element, "parameterValueExpression", parameter);
           crosstab = crosstab.updateIn(["parameters"], list => (list as List<Map<string, string>> || List<Map<string, string>>()).push(parameter));
        }
    });

    // check for the dataset 
    const datasetNode = getChildNodeByName(crosstabElement, "crosstabDataset");
    if (datasetNode !== null){
        crosstab = addBooleanAttributeToMap(datasetNode as Element, "isDataPreSorted", crosstab);
        const jrDatasetNode = getChildNodeByName(datasetNode, "dataset");
        if (jrDatasetNode !== null){
            crosstab = crosstab.set("dataset", createChartCrosstabDataset(jrDatasetNode as Element, objectCounter));
        }
    }

    // add the measures 
    crosstabElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "measure" ){
            const measure = createMeasure(element as Element);
            crosstab = crosstab.updateIn(["measures"], list => (list as List<Map<string, any>> || List<Map<string,any>>()).push(measure));
        } 
    });

    let newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, crosstab.get("id"));
    newDocument = newDocument.setIn(["elements", crosstab.get("id")], crosstab);
    crosstabElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "titleCell" ){
            newDocument = createTitleCell(element as Element, crosstab, newDocument, objectCounter);
        } else if  (elementName === "crosstabHeaderCell"){
            newDocument = createCrosstabHeaderCell(element as Element, crosstab, newDocument, objectCounter);
        } else if  (elementName === "rowGroup"){
            newDocument = createRowGroup(element as Element, crosstab, newDocument, objectCounter);
        } else if  (elementName === "columnGroup"){
            newDocument = createColumnGroup(element as Element, crosstab, newDocument, objectCounter);
        } else if  (elementName === "crosstabCell"){
            newDocument = createCell(element as Element, crosstab, newDocument, objectCounter);
        } else if  (elementName === "whenNoDataCell"){
            newDocument = createNoDataCell(element as Element, crosstab, newDocument, objectCounter);
        }
    });

    newDocument = computeCrosstabSize((newDocument.getIn(["elements", crosstab.get("id")]) as Map<string, any>), newDocument);
    return newDocument;
}

export const computeCrosstabRealSize = (crosstab: Map<string, any>, model: Map<string, any>): { width: number, height: number } => {
    let height = crosstab.get('height', 0);
    let width = crosstab.get('width', 0);
    const crosstabChild : List<string> = crosstab.get("elementIds");
    if (crosstabChild){
        crosstabChild.forEach(childId => {
            const child : Map<string, any> = model.getIn(["elements", childId]) as Map<string, any>;
            const type = child.get("type");
            if (type === CROSSTAB_ROW_GROUP_NAME || type === CROSSTAB_COLUMN_GROUP_NAME){
                const groupChildrenIds : List<string> = child.get("elementIds");
                groupChildrenIds.forEach(groupChildId => {
                    const groupCell : Map<string, any> = model.getIn(["elements", groupChildId]) as Map<string, any>;
                    const cellWidth = groupCell.get('width', 0);
                    const cellHeight = groupCell.get('height', 0);
                    const cellX = groupCell.get('x', 0);
                    const cellY = groupCell.get('y', 0);
                    width = Math.max(width, cellX + cellWidth);
                    height = Math.max(height, cellY + cellHeight);
                });
            } else if (type === CROSSTAB_CELL_NAME) {
                const cellWidth = child.get('width', 0);
                const cellHeight = child.get('height', 0);
                const cellX = child.get('x', 0);
                const cellY = child.get('y', 0);
                width = Math.max(width, cellX + cellWidth);
                height = Math.max(height, cellY + cellHeight);
            }
        });
    }
    return { width, height: height };
}


class GroupCells {
    public biggestWidth: number;
    public biggestHeight: number;
    public cells: List<ICell>; 
    
    public constructor(){
        this.biggestWidth = 0;
        this.biggestHeight = 0;
        this.cells = List<ICell>();
    }

    public addCell(width: number, height: number, cellId: string, isDetail: boolean) : GroupCells{
        if (width > this.biggestHeight){
            this.biggestWidth = width;
        }
        if (height > this.biggestHeight){
            this.biggestHeight = height;
        }
        this.cells = this.cells.push({'width': width, 'height': height, 'cellId' : cellId, 'isDetail': isDetail});
        return this;
    }
}

interface ICellsMatrix {
    rowGroupCells : Map<string, GroupCells>;
    colGroupCells : Map<string, GroupCells>;
    detailCell: {width: number, height: number, cellId: string};
    crosstabTitleCellId : string | undefined;
    crosstabHeaderCellId: string | undefined;
}

interface ICell {
    width: number, 
    height: number, 
    cellId: string,
    isDetail: boolean
}

const computeCrosstabSize = (crosstab: Map<string, any>, jrxmlDocument: Map<string, any>) : Map<string, any> => {
    const cellMatrix = createCellMatrix(crosstab, jrxmlDocument);
    let newDocument = jrxmlDocument;
    const headerSize = calculateCrosstabHeaderSize(crosstab, newDocument);
    newDocument = computeRowGroupsSize(crosstab, newDocument, cellMatrix);
    newDocument = computeColumnGroupsSize(crosstab, newDocument, cellMatrix, headerSize);
    const crosstabChild : List<string> = crosstab.get("elementIds");


    let yHeaderOffset = 0;

    // handle the title
    const crosstabTitleId = cellMatrix.crosstabTitleCellId;
    if (crosstabTitleId !== undefined){
        newDocument = newDocument.setIn(["elements", crosstabTitleId, "x"], 0);
        newDocument = newDocument.setIn(["elements", crosstabTitleId, "y"], 0);
        newDocument = newDocument.setIn(["elements", crosstabTitleId, "width"], crosstab.get("width"));
        yHeaderOffset = newDocument.getIn(["elements", crosstabTitleId, "height"]) as number;
    }

    // handle the crosstab header
    const crosstabHeaderId = cellMatrix.crosstabHeaderCellId;
    if (crosstabHeaderId !== undefined){
        newDocument = newDocument.setIn(["elements", crosstabHeaderId, "x"], 0);
        newDocument = newDocument.setIn(["elements", crosstabHeaderId, "y"], 0 + yHeaderOffset);
        newDocument = newDocument.setIn(["elements", crosstabHeaderId, "width"], headerSize.width);
        newDocument = newDocument.setIn(["elements", crosstabHeaderId, "height"], headerSize.height);
    }

    // handle the groups

    let xRowOffset = 0;
    let yRowOffset = headerSize.height + yHeaderOffset;
    let xColOffset = headerSize.width;
    let yColOffset = yHeaderOffset;

    crosstabChild.forEach(childId => {
        const child : Map<string, any> = newDocument.getIn(["elements", childId]) as Map<string, any>;
        const type = child.get("type");
        if (type === CROSSTAB_ROW_GROUP_NAME){
            const groupName = child.get("name");
            const groupChildrenIds : List<string> = child.get("elementIds");
            let header : Map<string, any> | null = null; 
            let total : Map<string, any> | null = null;
            groupChildrenIds.forEach(groupChildId => {
                const groupChild : Map<string, any> = newDocument.getIn(["elements", groupChildId]) as Map<string, any>;
                const groupChildType = groupChild.get("type");
                if (groupChildType === CROSSTAB_ROW_HEADER_NAME){
                    header = groupChild;
                } else if (groupChildType === CROSSTAB_TOTAL_ROW_HEADER_NAME){
                   total = groupChild;
                }
            });
            if (header != null){
                newDocument = newDocument.setIn(["elements", header.get("id"), "x"], xRowOffset);
                newDocument = newDocument.setIn(["elements", header.get("id"), "y"], yRowOffset);
                yRowOffset += header.get("height");
            }
            if (total != null){
                newDocument = newDocument.setIn(["elements", total.get("id"), "x"], xRowOffset);
                newDocument = newDocument.setIn(["elements", total.get("id"), "y"], yRowOffset);
                let totalCellX = xRowOffset + total.get("width");
                const groupCells = cellMatrix.rowGroupCells.get(groupName);
                if (groupCells){
                    groupCells.cells.forEach((cellInfo : ICell) => {
                        newDocument = newDocument.setIn(["elements", cellInfo.cellId, "x"], totalCellX);
                        newDocument = newDocument.setIn(["elements", cellInfo.cellId, "y"], yRowOffset);
                        totalCellX += cellInfo.width;
                    });
                }
            }
            if (header !== null){
                // need to do it here before bacuse in the first header != null block this must remain
                // untouched to be used by the total block 
                xRowOffset += header.get("width");
            }
            yRowOffset = headerSize.height + yHeaderOffset;
        } else if (type === CROSSTAB_COLUMN_GROUP_NAME){
            const groupName = child.get("name");
            const groupChildrenIds : List<string> = child.get("elementIds");
            let header : Map<string, any> | null = null; 
            let total : Map<string, any> | null = null;
            let crosstabHeader : Map<string, any> | null = null;
            groupChildrenIds.forEach(groupChildId => {
                const groupChild : Map<string, any> = newDocument.getIn(["elements", groupChildId]) as Map<string, any>;
                const groupChildType = groupChild.get("type");
                if (groupChildType === CROSSTAB_COLUMN_HEADER_NAME){
                    header = groupChild;
                } else if (groupChildType === CROSSTAB_TOTAL_COLUMN_HEADER_NAME){
                   total = groupChild;
                } else if (groupChildType === CROSSTAB_HEADER_NAME){
                    crosstabHeader = groupChild;
                }
            });
            if (header != null){
                newDocument = newDocument.setIn(["elements", header.get("id"), "x"], xColOffset);
                newDocument = newDocument.setIn(["elements", header.get("id"), "y"], yColOffset);
                xColOffset += header.get("width");
            }
            if (total != null){
                newDocument = newDocument.setIn(["elements", total.get("id"), "x"], xColOffset);
                newDocument = newDocument.setIn(["elements", total.get("id"), "y"], yColOffset);
                const groupCells = cellMatrix.colGroupCells.get(groupName);
                let totalCellY = yColOffset + total.get("height");
                if (groupCells){
                    groupCells.cells.forEach((cellInfo : ICell) => {
                        if (cellInfo.isDetail){
                            newDocument = newDocument.setIn(["elements", cellInfo.cellId, "x"], xColOffset);
                            newDocument = newDocument.setIn(["elements", cellInfo.cellId, "y"], totalCellY);
                            totalCellY += cellInfo.height;
                        }
                    });
                }
            }
            if (crosstabHeader !== null){
                // the crosstab group header has the same height of the group header
                newDocument = newDocument.setIn(["elements", crosstabHeader.get("id"), "x"], 0);
                newDocument = newDocument.setIn(["elements", crosstabHeader.get("id"), "y"], yColOffset);
            }
            if (header !== null){
                // need to do it here before bacuse in the first header != null block this must remain
                // untouched to be used by the total block 
                yColOffset += header.get("height");
            }
            xColOffset = headerSize.width;
        }
    });
    const detailCellId : Map<string, any> = newDocument.getIn(["elements", cellMatrix.detailCell.cellId], null) as Map<string, any>;
    if (detailCellId !== null){
        newDocument = newDocument.setIn(["elements", cellMatrix.detailCell.cellId, "x"], xRowOffset);
        newDocument = newDocument.setIn(["elements", cellMatrix.detailCell.cellId, "y"], yColOffset);
    }
    return newDocument;
}

const calculateCrosstabHeaderSize = (crosstab: Map<string, any>, jrxmlDocument: Map<string, any>) : {width: number, height: number} =>{ 
    const crosstabChild : List<string> = crosstab.get("elementIds");
    let widthValue = 0;
    let heightValue = 0;
    crosstabChild.forEach(childId => {
        const child = jrxmlDocument.getIn(["elements", childId]) as Map<string, any>;
        const type = child.get("type");
        if (type === CROSSTAB_COLUMN_GROUP_NAME){
            heightValue += child.get("height", 0);
        } else if (type === CROSSTAB_ROW_GROUP_NAME){
            widthValue += child.get("width", 0);
        }
    });
    return {width: widthValue, height: heightValue};
} 

const createCellMatrix = (crosstab: Map<string, any>, jrxmlDocument: Map<string, any>) : ICellsMatrix =>{
    const crosstabChild : List<string> = crosstab.get("elementIds");
    let detailCell = {width: 0, height: 0, cellId: ""};
    let rowGroupCells = Map<string, GroupCells>();
    let colGroupCells = Map<string, GroupCells>();
    let crosstabTitleId : string | undefined;
    let crosstabHeaderId : string | undefined;
    crosstabChild.forEach((childId : string) => {
        const child : Map<string, any> = jrxmlDocument.getIn(["elements", childId]) as Map<string, any>;
        const type = child.get("type");
        if (type === CROSSTAB_CELL_NAME){
           const rowGroup = child.get("rowTotalGroup", null);
           const columnGroup = child.get("columnTotalGroup", null);
           if (rowGroup !== null){
               const width = child.get("width", 0);
               const height = child.get("height", 0);
               rowGroupCells = rowGroupCells.update(rowGroup, groupCells => (groupCells || new GroupCells()).addCell(width, height, childId, columnGroup === null));
           }
           if (columnGroup !== null){
               const width = child.get("width", 0);
               const height = child.get("height", 0);
               colGroupCells = colGroupCells.update(columnGroup, groupCells => (groupCells || new GroupCells()).addCell(width, height, childId, rowGroup === null));
           }
           if (columnGroup === null && rowGroup === null){
               detailCell = {width: child.get("width", 0), height: child.get("height", 0), cellId: child.get("id")};
           }
        } else if (type === CROSSTAB_TITLE_CELL_NAME){
            crosstabTitleId = childId;
        } else if (type === CROSSTAB_HEADER_CELL_NAME){
            crosstabHeaderId = childId;
        }
    });
    return {'rowGroupCells': rowGroupCells, 'colGroupCells': colGroupCells, 'detailCell': detailCell, crosstabTitleCellId: crosstabTitleId, crosstabHeaderCellId: crosstabHeaderId}; 
}

const computeRowGroupsSize = (crosstab: Map<string, any>, jrxmlDocument: Map<string, any>, cellsMatrix: ICellsMatrix) : Map<string, any> =>{ 
    const crosstabChild : List<string> = (crosstab.get("elementIds") as List<string>).reverse() as List<string>;
    // it is important to iterate backward to find the groups from the last to the first
    let previousGroupHeight =  cellsMatrix.detailCell.height;
    let previousGroupTotalWidth = 0;
    let newDocument = jrxmlDocument;
    crosstabChild.forEach(childId => {
        const child : Map<string, any> = jrxmlDocument.getIn(["elements", childId]) as Map<string, any>;
        const type = child.get("type");
        if (type === CROSSTAB_ROW_GROUP_NAME){
            const groupChildrenIds : List<string> = child.get("elementIds");
            const groupName = child.get("name");
            const groupWidth = child.get("width");
            groupChildrenIds.forEach(groupChildId => {
                const groupChild : Map<string, any> = jrxmlDocument.getIn(["elements", groupChildId]) as Map<string, any>;
                const groupChildType = groupChild.get("type");
                if (groupChildType === CROSSTAB_ROW_HEADER_NAME){
                    const headerSize = {width: groupWidth, height: previousGroupHeight};
                    newDocument = newDocument.setIn(["elements", groupChildId, "width"], headerSize.width);
                    newDocument = newDocument.setIn(["elements", groupChildId, "height"], headerSize.height);
                } else if (groupChildType === CROSSTAB_TOTAL_ROW_HEADER_NAME){
                    const groupCells : GroupCells = cellsMatrix.rowGroupCells.get(groupName);
                    const totalSize = {width:  groupWidth + previousGroupTotalWidth, height: groupCells ? groupCells.biggestHeight : 0 };
                    previousGroupHeight += totalSize.height;
                    previousGroupTotalWidth = totalSize.width;
                    newDocument = newDocument.setIn(["elements", groupChildId, "width"], totalSize.width);
                    newDocument = newDocument.setIn(["elements", groupChildId, "height"], totalSize.height);
                }
            });
        }
    });
    return newDocument;
} 

const computeColumnGroupsSize = (crosstab: Map<string, any>, jrxmlDocument: Map<string, any>, cellsMatrix: ICellsMatrix, crosstabHeaderSize: {width:number, height: number}) : Map<string, any> =>{ 
    const crosstabChild : List<string> = (crosstab.get("elementIds") as List<string>).reverse() as List<string>;
    // it is important to iterate backward to find the groups from the last to the first
    let previousGroupWidth =  cellsMatrix.detailCell.width;
    let previousGroupTotalHeight = 0;
    let newDocument = jrxmlDocument;
    crosstabChild.forEach(childId => {
        const child : Map<string, any> = jrxmlDocument.getIn(["elements", childId]) as Map<string, any>;
        const type = child.get("type");
        if (type === CROSSTAB_COLUMN_GROUP_NAME){
            const groupChildrenIds : List<string> = child.get("elementIds");
            const groupName = child.get("name");
            const groupHeight = child.get("height");
            groupChildrenIds.forEach(groupChildId => {
                const groupChild : Map<string, any> = jrxmlDocument.getIn(["elements", groupChildId]) as Map<string, any>;
                const groupChildType = groupChild.get("type");
                if (groupChildType === CROSSTAB_COLUMN_HEADER_NAME){
                    const headerSize = {width: previousGroupWidth, height: groupHeight};
                    newDocument = newDocument.setIn(["elements", groupChildId, "width"], headerSize.width);
                    newDocument = newDocument.setIn(["elements", groupChildId, "height"], headerSize.height);
                } else if (groupChildType === CROSSTAB_TOTAL_COLUMN_HEADER_NAME){
                    const groupCells : GroupCells = cellsMatrix.colGroupCells.get(groupName);
                    const totalSize = {width: groupCells ? groupCells.biggestWidth : 0, height: previousGroupTotalHeight + groupHeight};
                    previousGroupTotalHeight = totalSize.height;
                    previousGroupWidth += totalSize.width;
                    newDocument = newDocument.setIn(["elements", groupChildId, "width"], totalSize.width);
                    newDocument = newDocument.setIn(["elements", groupChildId, "height"], totalSize.height);
                } else if (groupChildType === CROSSTAB_HEADER_NAME){
                    newDocument = newDocument.setIn(["elements", groupChildId, "width"], crosstabHeaderSize.width);
                    newDocument = newDocument.setIn(["elements", groupChildId, "height"], groupHeight);
                }
            });
        }
    });
    return newDocument;
} 

const createRowHeader = (rowGroupHeaderElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    return createGenericCellContainer(rowGroupHeaderElement, parentElement, document, objectCounter, CROSSTAB_ROW_HEADER_NAME);
}

const createColumnHeader = (crosstabColumnHeaderElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    return createGenericCellContainer(crosstabColumnHeaderElement, parentElement, document, objectCounter, CROSSTAB_COLUMN_HEADER_NAME);
}

const createTotalRowHeader = (rowGroupHeaderElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    return createGenericCellContainer(rowGroupHeaderElement, parentElement, document, objectCounter, CROSSTAB_TOTAL_ROW_HEADER_NAME);
}

const createTotalColumnHeader = (TotalColumnHeaderElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    return createGenericCellContainer(TotalColumnHeaderElement, parentElement, document, objectCounter, CROSSTAB_TOTAL_COLUMN_HEADER_NAME);
}

const createCrosstabHeaderCell = (crosstabHeaderElementCell: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    return createGenericCellContainer(crosstabHeaderElementCell, parentElement, document, objectCounter, CROSSTAB_HEADER_CELL_NAME);
}

const createNoDataCell = (crosstabNoDataElementCell: Element, parentCrosstabElemenet: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentCrosstabElemenet);
    const cellContainer = initializeMapWithObject({
        id: objectCounter.uniqueID("ele-"),
        path: pathValue,
        type: CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME,
        selected: false,
        highlighted: false,
        properties: OrderedMap<string,string | null>(),
        elementIds: List<string>(),
        width: parentCrosstabElemenet.get('width'),
        height: parentCrosstabElemenet.get('height'),
      });

    let newDocument = addChildToParent(parentCrosstabElemenet.get("id"), parentCrosstabElemenet.get("type"), document, cellContainer.get("id"));
    newDocument = newDocument.setIn(["elements", cellContainer.get("id")], cellContainer);

    const cellContentsNode = getChildNodeByName(crosstabNoDataElementCell, "cellContents");
    if (cellContentsNode !== null){
        newDocument = createCellContents(cellContentsNode as Element, cellContainer, newDocument, objectCounter);
    }

    return newDocument;
}

const createCrosstabHeader = (crosstabHeaderElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    return createGenericCellContainer(crosstabHeaderElement, parentElement, document, objectCounter, CROSSTAB_HEADER_NAME);
}

const createRowGroup = (rowGroupEelement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    let rowGroup = initializeMapWithObject({
        id: objectCounter.uniqueID("ele-"),
        path: pathValue,
        type: CROSSTAB_ROW_GROUP_NAME,
        selected: false,
        highlighted: false,
        x: 0,
        y: 0,
        properties: OrderedMap<string,string | null>(),
        elementIds: List<string>()
      });

    rowGroup = addIntAttributeToMap(rowGroupEelement, "width", rowGroup);
    rowGroup = addAttributeToMap(rowGroupEelement, "name", rowGroup);
    rowGroup = addAttributeToMap(rowGroupEelement, "totalPosition", rowGroup);
    rowGroup = addAttributeToMap(rowGroupEelement, "headerPosition", rowGroup);
    rowGroup = addBooleanAttributeToMap(rowGroupEelement, "mergeHeaderCells", rowGroup);

    const bucket = getChildNodeByName(rowGroupEelement, "bucket");
    if (bucket !== null){
        rowGroup = rowGroup.set("bucket", createBucket(bucket as Element));
    }

    let newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, rowGroup.get("id"));
    newDocument = newDocument.setIn(["elements", rowGroup.get("id")], rowGroup);
   
    const rowHeader = getChildNodeByName(rowGroupEelement, "crosstabRowHeader");
    if (rowHeader !== null){
       newDocument = createRowHeader(rowHeader as Element, rowGroup, newDocument, objectCounter);
    }

    const totalRowHeader = getChildNodeByName(rowGroupEelement, "crosstabTotalRowHeader");
    if (totalRowHeader !== null){
       newDocument = createTotalRowHeader(totalRowHeader as Element, rowGroup, newDocument, objectCounter);
    }

    return newDocument;
}

const createColumnGroup = (columnGroupEelement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    let columnGroup = initializeMapWithObject({
        id: objectCounter.uniqueID("ele-"),
        path: pathValue,
        type: CROSSTAB_COLUMN_GROUP_NAME,
        x: 0,
        y: 0,
        selected: false,
        highlighted: false,
        properties: OrderedMap<string,string | null>(),
        elementIds: List<string>()
      });

    columnGroup = addIntAttributeToMap(columnGroupEelement, "height", columnGroup);
    columnGroup = addAttributeToMap(columnGroupEelement, "name", columnGroup);
    columnGroup = addAttributeToMap(columnGroupEelement, "totalPosition", columnGroup);
    columnGroup = addAttributeToMap(columnGroupEelement, "headerPosition", columnGroup);
    columnGroup = addBooleanAttributeToMap(columnGroupEelement, "mergeHeaderCells", columnGroup);

    const bucket = getChildNodeByName(columnGroupEelement, "bucket");
    if (bucket !== null){
        columnGroup = columnGroup.set("bucket", createBucket(bucket as Element));
    }

    let newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, columnGroup.get("id"));
    newDocument = newDocument.setIn(["elements", columnGroup.get("id")], columnGroup);
   
    const crosstabHeader = getChildNodeByName(columnGroupEelement, "crosstabHeader");
    if (crosstabHeader !== null){
       newDocument = createCrosstabHeader(crosstabHeader as Element, columnGroup, newDocument, objectCounter);
    }

    const columnHeader = getChildNodeByName(columnGroupEelement, "crosstabColumnHeader");
    if (columnHeader !== null){
       newDocument = createColumnHeader(columnHeader as Element, columnGroup, newDocument, objectCounter);
    }

    const totalColumnHeader = getChildNodeByName(columnGroupEelement, "crosstabTotalColumnHeader");
    if (totalColumnHeader !== null){
       newDocument = createTotalColumnHeader(totalColumnHeader as Element, columnGroup, newDocument, objectCounter);
    }

    return newDocument;
}

const createTitleCell = (columnCellElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    let columnCell = initializeMapWithObject({
        id: objectCounter.uniqueID("ele-"),
        path: pathValue,
        type: CROSSTAB_TITLE_CELL_NAME,
        selected: false,
        highlighted: false,
        properties: OrderedMap<string,string | null>(),
        elementIds: List<string>()
      });

    columnCell = addIntAttributeToMap(columnCellElement, "height", columnCell);
    columnCell = addAttributeToMap(columnCellElement, "contentsPosition", columnCell);

    let newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, columnCell.get("id"));
    newDocument = newDocument.setIn(["elements", columnCell.get("id")], columnCell);
   
    const cellContentsNode = getChildNodeByName(columnCellElement, "cellContents");
    if (cellContentsNode !== null){
        newDocument = createCellContents(cellContentsNode as Element, columnCell, newDocument, objectCounter);
    }

    return newDocument;
}

const createCell = (cellElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    let cell = initializeMapWithObject({
        id: objectCounter.uniqueID("ele-"),
        path: pathValue,
        type: CROSSTAB_CELL_NAME,
        selected: false,
        highlighted: false,
        properties: OrderedMap<string,string | null>(),
        elementIds: List<string>()
      });

    cell = addIntAttributeToMap(cellElement, "height", cell);
    cell = addIntAttributeToMap(cellElement, "width", cell);
    cell = addAttributeToMap(cellElement, "rowTotalGroup", cell);
    cell = addAttributeToMap(cellElement, "columnTotalGroup", cell);

    let newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, cell.get("id"));
    newDocument = newDocument.setIn(["elements", cell.get("id")], cell);
   
    const cellContentsNode = getChildNodeByName(cellElement, "cellContents");
    if (cellContentsNode !== null){
        newDocument = createCellContents(cellContentsNode as Element, cell, newDocument, objectCounter);
    }

    return newDocument;
}

const createBucket = (bucketElement : Element) : Map<string, any> => {
    let result = Map<string, any>();

    result = addChildValueToMap(bucketElement, "bucketExpression", result);
    result = addChildValueToMap(bucketElement, "orderByExpression", result);
    result = addChildValueToMap(bucketElement, "comparatorExpression", result);

    result = addAttributeToMap(bucketElement, "class", result);
    result = addAttributeToMap(bucketElement, "order", result);
    
    return result;
}

const createMeasure = (measureElement : Element) : Map<string, any> => {
    let result = Map<string, any>();

    result = addChildValueToMap(measureElement, "measureExpression", result);

    result = addAttributeToMap(measureElement, "class", result);
    result = addAttributeToMap(measureElement, "name", result);
    result = addAttributeToMap(measureElement, "calculation", result);
    result = addAttributeToMap(measureElement, "incrementerFactoryClass", result);
    result = addAttributeToMap(measureElement, "percentageOf", result);
    result = addAttributeToMap(measureElement, "percentageCalculatorClass", result);
    
    return result;
}

const createGenericCellContainer = (rowGroupHeaderElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter, cellType: string) : Map<string, any> => {
    // here we mix the properties from the component element and the child table
    const pathValue = generatePath(parentElement);
    const cellContainer = initializeMapWithObject({
        id: objectCounter.uniqueID("ele-"),
        path: pathValue,
        type: cellType,
        selected: false,
        highlighted: false,
        properties: OrderedMap<string,string | null>(),
        elementIds: List<string>()
      });

    let newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, cellContainer.get("id"));
    newDocument = newDocument.setIn(["elements", cellContainer.get("id")], cellContainer);

    const cellContentsNode = getChildNodeByName(rowGroupHeaderElement, "cellContents");
    if (cellContentsNode !== null){
        newDocument = createCellContents(cellContentsNode as Element, cellContainer, newDocument, objectCounter);
    }

    return newDocument;
}

const createCellContents = (cellContentsElement: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) : Map<string, any> => {

    let updatedParent = parentElement;
    updatedParent = addAttributeToMap(cellContentsElement, "backcolor", updatedParent);
    updatedParent = addAttributeToMap(cellContentsElement, "mode", updatedParent);
    updatedParent = addAttributeToMap(cellContentsElement, "style", updatedParent);
    updatedParent = updatedParent.set("properties", OrderedMap<string,string | null>());
    updatedParent = updatedParent.set("elementIds", List<string>());
    const boxElement = getChildNodeByName(cellContentsElement, "box");

    let newDocument = document;

    cellContentsElement.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "property"){
            const childElement : Element = element as Element;
            const attName = childElement.getAttribute("name");
            const attValue = getPropertyValue(childElement); 
            updatedParent = updatedParent.set("properties", updatedParent.get("properties").set(attName, attValue));
            newDocument = newDocument.setIn(["elements", updatedParent.get("id")], updatedParent);
        } else if (elementName === "box"){
            const box : Map<string, any> = createBox(boxElement as Element);
            updatedParent = updatedParent.set("box", box);
            newDocument = newDocument.setIn(["elements", updatedParent.get("id")], updatedParent);
        } else  {
            newDocument = parseNode(element, cellContentsElement, updatedParent, newDocument, objectCounter);
        }
    });

    return newDocument;
}
