/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { i18n } from '@jss/js-common';
import { en } from './assets/locales/en/messages';
import { it } from './assets/locales/it/messages';
import { fr } from './assets/locales/fr/messages';
import { configureAceModuleUrls } from "@jss/js-text-editor";

i18n.addResourceBundle('en', 'jrws', en, true);
i18n.addResourceBundle('it', 'jrws', it, true);
i18n.addResourceBundle('fr', 'jrws', fr, true);

// For standalone app, the modules' URLs will have the default application context se to "/"
configureAceModuleUrls();

const theme = createTheme({
    palette: {
        primary: {
            light: '#60b2fc',
            main: '#1483c9',
            dark: '#005798',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffffff',
            main: '#e4e4e4',
            dark: '#b2b2b2',
            contrastText: '#000',
        },
    },
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>,
    document.getElementById('root')
);

// Register a Service Worker to add custom headers to specific requests
if (navigator && navigator.serviceWorker) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/jrws-sw.js').then(() => {
            console.log("JRWS Service Worker registered");
        }).catch(registrationError => {
            console.log("JRWS Service Worker registration failed", registrationError);
        });
    });

    navigator.serviceWorker.addEventListener("message", (event: MessageEvent) => {
        console.log(`Received message from JRWS Service Worker: ${event.data}`);
    });

    navigator.serviceWorker.ready.then((registration: ServiceWorkerRegistration) => {
        registration.active?.postMessage({ type: "PING" });
    });
}
