/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const CrosstabTotalPositionEnum = new PEnum({
    id: 'totalPosition', label: 'CrosstabTotalPosition.label', default: 'None',
    items: [
        { key: 'None', value: 'CrosstabTotalPosition.None'},
        { key: 'Start', value: 'CrosstabTotalPosition.Start'},
        { key: 'End', value: 'CrosstabTotalPosition.End'} 
    ]
}); 
 
