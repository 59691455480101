export const en = {
	components: {
		filesTable: {
			filename: "File Name",
			type: "Type",
			lastmodified: "Last Modified"
		}
	},
	searchfile: {
		placeholder: "Search",
		help: "Type the name of the file"
	},
	repoview: {
		emptyfolder: {
			text: "This folder is empty"
		},
		home: "Home"
	},
	createfolder: {
		text: "New Folder"
	},
	newresources: {
		menubutton: {
			text: "New"
		},
		folder: {
			text: "New Folder"
		},
		report: {
			text: "New Report"
		},
		da: {
			text: "New Data Adapter"
		},
		upload: {
			text: "Upload..."
		}
	},
	resourcestable: {
		header: {
			name: "Name",
			lastmodified: "Last Modified",
			type: "Type"
		}
	},
	repository: {
		delete: {
			dialog: {
				cancel: "Cancel",
				save: "Save",
				delitem: "Delete item",
				msg: "Are you sure you want to delete the item?"
			},
			confirmation: {
				title: "Please, confirm resource deletion",
				text: "Think twice, resource will be permanently deleted."
			}
		}
	},
	menuitem: {
		rename: {
			text: "Rename"
		}
	},
	resource: {
		permission: {
			label: "Permission",
			read: "Read",
			write: "Write",
			execute: "Execute",
			none: "None"
		},
		lastmodified: {
			date: "Last Modified Date"
		},
		created: {
			date: "Created Date"
		},
		ovewrite: {
			message: "A file with the same name already exist, do you want to overwrite it?"
		},
		type: "Type",
		name: "Name",
		path: "Path",
		mime: "MIME",
		id: "ID",
		description: "Description"
	},
}