/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const CandleStickChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M39 4v134h223V4z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M111.34 157.188L158.66 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M111.34 173.5L158.66 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M111.34 173.5L111.34 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M158.66 173.5L158.66 157.188" clipPath="url(#clipPath1)" />
            <path fill="#F55" stroke="none" d="M114.84 161.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="126.84" y="168.113" stroke="none" clipPath="url(#clipPath1)">Freight</text>
            <path fill="none" strokeWidth="0.5" d="M39 138L262 138" clipPath="url(#clipPath1)" />
            <text x="12.129" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">23:59:59.999</text>
            <path fill="none" strokeWidth="0.5" d="M39 140L39 138" clipPath="url(#clipPath1)" />
            <text x="123.629" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">00:00:00.000</text>
            <path fill="none" strokeWidth="0.5" d="M150.5 140L150.5 138" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M39 4L39 138" clipPath="url(#clipPath1)" />
            <text x="27.91" y="140.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
            <path fill="none" strokeWidth="0.5" d="M37 138L39 138" clipPath="url(#clipPath1)" />
            <text x="17.73" y="127.444" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">100</text>
            <path fill="none" strokeWidth="0.5" d="M37 124.675L39 124.675" clipPath="url(#clipPath1)" />
            <text x="17.73" y="114.119" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">200</text>
            <path fill="none" strokeWidth="0.5" d="M37 111.35L39 111.35" clipPath="url(#clipPath1)" />
            <text x="17.73" y="100.794" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">300</text>
            <path fill="none" strokeWidth="0.5" d="M37 98.024L39 98.024" clipPath="url(#clipPath1)" />
            <text x="17.73" y="87.469" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">400</text>
            <path fill="none" strokeWidth="0.5" d="M37 84.699L39 84.699" clipPath="url(#clipPath1)" />
            <text x="17.73" y="74.144" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">500</text>
            <path fill="none" strokeWidth="0.5" d="M37 71.374L39 71.374" clipPath="url(#clipPath1)" />
            <text x="17.73" y="60.818" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">600</text>
            <path fill="none" strokeWidth="0.5" d="M37 58.049L39 58.049" clipPath="url(#clipPath1)" />
            <text x="17.73" y="47.493" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">700</text>
            <path fill="none" strokeWidth="0.5" d="M37 44.724L39 44.724" clipPath="url(#clipPath1)" />
            <text x="17.73" y="34.168" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">800</text>
            <path fill="none" strokeWidth="0.5" d="M37 31.398L39 31.398" clipPath="url(#clipPath1)" />
            <text x="17.73" y="20.843" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">900</text>
            <path fill="none" strokeWidth="0.5" d="M37 18.073L39 18.073" clipPath="url(#clipPath1)" />
            <text x="10.098" y="7.518" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">1.000</text>
            <path fill="none" strokeWidth="0.5" d="M37 4.748L39 4.748" clipPath="url(#clipPath1)" />
         </g>
         <g fill="none" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path d="M39 4L39 138" clipPath="url(#clipPath2)" />
            <path d="M150.5 4L150.5 138" clipPath="url(#clipPath2)" />
            <path d="M39 138L262 138" clipPath="url(#clipPath2)" />
            <path d="M39 124.675L262 124.675" clipPath="url(#clipPath2)" />
            <path d="M39 111.35L262 111.35" clipPath="url(#clipPath2)" />
            <path d="M39 98.024L262 98.024" clipPath="url(#clipPath2)" />
            <path d="M39 84.699L262 84.699" clipPath="url(#clipPath2)" />
            <path d="M39 71.374L262 71.374" clipPath="url(#clipPath2)" />
            <path d="M39 58.049L262 58.049" clipPath="url(#clipPath2)" />
            <path d="M39 44.724L262 44.724" clipPath="url(#clipPath2)" />
            <path d="M39 31.398L262 31.398" clipPath="url(#clipPath2)" />
            <path d="M39 18.073L262 18.073" clipPath="url(#clipPath2)" />
            <path d="M39 4.748L262 4.748" clipPath="url(#clipPath2)" />
         </g>
         <g fill="gray" stroke="gray" strokeLinejoin="bevel" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path stroke="none" d="M78.821 4.13H222.178V138H78.821z" clipPath="url(#clipPath2)" opacity="0.3" />
            <path fill="none" stroke="#F55" d="M150.5 10.381L150.5 82.167" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M150.5 106.095L150.5 26.333" clipPath="url(#clipPath2)" />
            <path fill="#0f0" stroke="none" d="M78.821 26.333H222.178V82.166H78.821z" clipPath="url(#clipPath2)" />
            <path fill="none" stroke="#F55" d="M78.821 26.333H222.178V82.166H78.821z" clipPath="url(#clipPath2)" />
         </g>
      </svg>
   );
}
export default CandleStickChartIcon;