/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg } from '../../ui/UiProperty';
import { PString, UPString } from './UPString';
import { List, Map } from 'immutable';
import { ValidationResult, VALIDATION_RESULT } from '@jss/js-common/src/utils/validators';
import { i18n } from '@jss/js-common';

export class UPUniqueName<T = void> extends UPString<T> {

    getUniqueNameValidator = (value: string, descriptor) : ValidationResult => {
        const elementPath = [...this.props.mcontext.elements[0].path];
        const elementIndex = elementPath.pop();
        const children = this.props.mcontext.model.getIn(elementPath) as List<Map<string, any>>;
        const sameValueChild = children.find((child, index) => {
            if (elementIndex !== index){
                const childValue = child.get(this.props.mcontext.descriptor.id);
                return (childValue === value);
            }
            return false;
        });
        if (sameValueChild){
            return {
                result: VALIDATION_RESULT.PARTIALLY_INVALID,
                message: `The value of property ${i18n.t(this.props.mcontext.descriptor.label)} should be unique`,
            }
        }
        if (descriptor.validator) {
            const validationResult = descriptor.validator(value);
            if (validationResult) {
                return validationResult;
            }
        }
        return { result: VALIDATION_RESULT.VALID }
    }

    getValidator = (descriptor) => { 
        return (stringValue: string) => this.getUniqueNameValidator(stringValue, descriptor);
    }

}

const TYPEID = 'uniqueNameString';
reg(TYPEID, (mc) => { return <UPUniqueName mcontext={mc} />; });
export class PUniqueName extends PString {
    public constructor(init: Partial<PUniqueName>) {
        super(init);
        Object.assign(this, { ...init, type: TYPEID });
    }
}