/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import BarcodeCodabarIcon from '@jss/js-common/src/svg/elements/BarcodeCodabarIcon';
import BarcodeCode128Icon from '@jss/js-common/src/svg/elements/BarcodeCode128Icon';
import BarcodeCode39Icon from '@jss/js-common/src/svg/elements/BarcodeCode39Icon';
import BarcodeDataMatrixIcon from '@jss/js-common/src/svg/elements/BarcodeDataMatrixIcon';
import BarcodeEAN128Icon from '@jss/js-common/src/svg/elements/BarcodeEAN128Icon';
import BarcodeEAN13Icon from '@jss/js-common/src/svg/elements/BarcodeEAN13Icon';
import BarcodeEAN8Icon from '@jss/js-common/src/svg/elements/BarcodeEAN8Icon';
import BarcodeIcon from '@jss/js-common/src/svg/elements/BarcodeIcon';
import BarcodeInt2of5Icon from '@jss/js-common/src/svg/elements/BarcodeInt2of5Icon';
import BarcodePOSTNETIcon from '@jss/js-common/src/svg/elements/BarcodePOSTNETIcon';
import BarcodeQRCodeIcon from '@jss/js-common/src/svg/elements/BarcodeQRCodeIcon';
import BarcodeRoyalMailCustomerIcon from '@jss/js-common/src/svg/elements/BarcodeRoyalMailCustomerIcon';
import BarcodeUPCAIcon from '@jss/js-common/src/svg/elements/BarcodeUPCAIcon';
import BarcodeUPCEIcon from '@jss/js-common/src/svg/elements/BarcodeUPCEIcon';
import BarcodeUSPSIcon from '@jss/js-common/src/svg/elements/BarcodeUSPSIcon';
import BarcodePDF417Icon from '@jss/js-common/src/svg/elements/BarcodPDF417Icon';
import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../reducers';
import { BarcodeTypes, BARCODE_TYPE } from '../../common/JrxmlModel/reader/JrxmlBarcodeUtils';

interface IBarcodeElement {
  id: string;
  element?: any;
  zoom?: number;
  availableWidth: number,
  availableHeight: number,
}

const visualProperties = ['barbecue_type', BARCODE_TYPE];

class BarcodeElement extends React.Component<IBarcodeElement> {

  public shouldComponentUpdate = (nextProps: Readonly<IBarcodeElement>) => {
    if (this.props.availableHeight !== nextProps.availableHeight || this.props.availableWidth !== nextProps.availableWidth) {
      return true;
    }

    if (this.props.zoom !== nextProps.zoom) {
      return true;
    }
    for (const propName of visualProperties) {
      if (this.props.element.get(propName) !== nextProps.element.get(propName)) {
        return true;
      }
    }

    return false; // Nothing impacting the content of the figure is changed...
  }

  public render() {
    // The actual content of the frame is rendered at band level.
    // Essentially a frame is just a logical component with nothing to do...
    let image = <BarcodeIcon width='100%' height='100%' preserveAspectRatio='none'/>;
    let barcodeType = this.props.element.get(BARCODE_TYPE);
    if (barcodeType === BarcodeTypes.BARBECUE_NAME) {
      barcodeType = this.props.element.get('barbecue_type');
    }
    if (barcodeType === BarcodeTypes.CODABAR_NAME) {
      image = <BarcodeCodabarIcon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.CODE128_NAME) {
      image = <BarcodeCode128Icon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.CODE39_NAME) {
      image = <BarcodeCode39Icon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.EAN128_NAME) {
      image = <BarcodeEAN128Icon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.EAN13_NAME) {
      image = <BarcodeEAN13Icon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.EAN8_NAME) {
      image = <BarcodeEAN8Icon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.DATAMATRIX_NAME) {
      image = <BarcodeDataMatrixIcon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.INTERLEAVED2OF5_NAME || barcodeType === 'Int2of5') {
      image = <BarcodeInt2of5Icon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.PDF417_NAME) {
      image = <BarcodePDF417Icon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.POSTNET_NAME) {
      image = <BarcodePOSTNETIcon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.QRCODE_NAME) {
      image = <BarcodeQRCodeIcon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.ROYALMAILCUSTOMER_NAME) {
      image = <BarcodeRoyalMailCustomerIcon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.UPCA_NAME) {
      image = <BarcodeUPCAIcon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.UPCE_NAME) {
      image = <BarcodeUPCEIcon width='100%' height='100%' preserveAspectRatio='none'/>;
    } else if (barcodeType === BarcodeTypes.USPS_NAME) {
      image = <BarcodeUSPSIcon width='100%' height='100%' preserveAspectRatio='none'/>;
    }
    return <div style={{ width: '100%', height: '100%' }}>
      {image}
    </div>;
  }
}

const mapStateToProps = (state: IState, props: IBarcodeElement) => {

  return {
    element: state.getIn(['report', 'model', 'elements', props.id]),
    model: state.getIn(['report', 'model']),
    zoom: state.getIn(['report', 'zoom']),
  };
}

export default connect(mapStateToProps)(BarcodeElement);   // ,mapDispatchToProps