/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { APDescriptor } from "../../ui/APDescriptor";
import { HyperlinkTargetEnum } from "../enums/HyperlinkTargetEnum";
import { HyperlinkTypeEnum } from "../enums/HyperlinkTypeEnum";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PString } from "../../ui/primitive/UPString";
import { PConnectedTable } from "../../ui/tables/UPConnectedTable";
import { PTableElement } from "../../ui/tables/UPTableElement";

export const FHyperlinksParameter = {
    name: new PString({ id: 'name', label: 'Name' }),
    valueExpression: new PExpression({ id: 'valueExpression', label: 'Value' }),
}

export const FHyperlinks = {
    anchorNameExpression: new PExpression({ id: 'anchorNameExpression', label: 'Anchor Name Expression' }),

    hyperlinkAnchorExpression: new PExpression({ id: 'hyperlinkAnchorExpression', label: 'Anchor Expression' }),
    hyperlinkPageExpression: new PExpression({ id: 'hyperlinkPageExpression', label: 'Page Expression' }),
    hyperlinkReferenceExpression: new PExpression({ id: 'hyperlinkReferenceExpression', label: 'Reference Expression' }),
    hyperlinkWhenExpression: new PExpression({ id: 'hyperlinkWhenExpression', label: 'When Expression' }),
    hyperlinkTooltipExpression: new PExpression({ id: 'hyperlinkTooltipExpression', label: 'Tooltip Expression' }),
    linkTarget: HyperlinkTargetEnum,
    hyperlinkTarget: { ...HyperlinkTargetEnum, id: 'hyperlinkTarget' },
    hyperlinkType: HyperlinkTypeEnum,
    hyperlinkParameters: new PConnectedTable({
        id: "hyperlinkParameters", label: 'Parameters', rowLabel: 'Parameter', rowLabelPaths: [FHyperlinksParameter.name.id, FHyperlinksParameter.valueExpression.id],
        layouts: [new PTableElement({
            label: 'Hyperlink Parameter',
            anchorOrigin: { vertical: 'center', horizontal: 'right' },
            transformOrigin: { vertical: 'center', horizontal: 'right' },
            layouts: [FHyperlinksParameter.name, FHyperlinksParameter.valueExpression]
        })],
        default: { name: 'Parameter', valueExpression: '' }
    })
};

export const MHyperlinks: APDescriptor = new PAccordeon({
    id: 'element.hyperlinks', label: 'Hyperlink',
    layouts: [FHyperlinks.anchorNameExpression, FHyperlinks.hyperlinkAnchorExpression,
    FHyperlinks.hyperlinkPageExpression,
    FHyperlinks.hyperlinkReferenceExpression,
    FHyperlinks.hyperlinkWhenExpression,
    FHyperlinks.hyperlinkTooltipExpression,
    FHyperlinks.linkTarget,
    FHyperlinks.hyperlinkType,
    FHyperlinks.hyperlinkParameters
    ]
});

export const MHyperlinksTarget: APDescriptor = new PAccordeon({
    id: 'element.hyperlinks', label: 'Hyperlink',
    layouts: [FHyperlinks.anchorNameExpression, FHyperlinks.hyperlinkAnchorExpression,
    FHyperlinks.hyperlinkPageExpression,
    FHyperlinks.hyperlinkReferenceExpression,
    FHyperlinks.hyperlinkWhenExpression,
    FHyperlinks.hyperlinkTooltipExpression,
    FHyperlinks.hyperlinkTarget,
    FHyperlinks.hyperlinkType,
    FHyperlinks.hyperlinkParameters
    ]
});
