/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { FormControlledTextField, Icon } from '@jss/js-common';
import { ResourcePickerDialog, RunContext } from '@jss/js-repository';
import { getRealName, IRepositoryItemDescriptor, RepositoryApi } from '@jss/js-rest-api';
import { InputAdornment } from '@material-ui/core';
import { Map } from 'immutable';
import React, { CSSProperties, HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import * as ReportActions from '../../../../../actions/reportActions';
import '../../../../../assets/uxpl/css/ValidatedPath.css';
import i18n from '../../../../../i18n';
import { IState } from '../../../../../reducers';
import { FETCHING_STATUS } from '../../../../../reducers/report/reportReducer';
import { getKey, getNormalizedPath, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';

const TYPEID = 'ValidatedPath';
reg(TYPEID, (mc) => { return <UPValidatedPath mcontext={mc} />; });
export class PValidatedPath extends APDescriptor {
    allowedMimes?: string[] = [];
    refreshCacheOnDelete?: boolean;
    refreshCacheOnAdd?: boolean;
    public constructor(init: Partial<PValidatedPath>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

interface IValidaredPathProps {
    resourcesCache?: Map<string, any>,
    repositoryPath?: IRepositoryItemDescriptor[],
    checkResourceAction?: (reportPath: string, resourcePath: string) => void,
    additionalIcon?: React.ReactElement, 
}

interface IValidatedPathState {
    uncommittedValue: string | undefined,
}

const ENTER_KEY = 13;


export class UPValidatedPath extends React.Component<UiPropertyProps & IValidaredPathProps, IValidatedPathState> {

    private timer: any;

    state = {
        uncommittedValue: undefined,
    }

    private getReportPath = () => {
        return RepositoryApi.getAPath(this.props.mcontext.reportDescriptor);
    }

    componentDidMount = () => {
        this.timer = null;
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p) as string | undefined;
        if (v && v.length > 0) {
            const resourceStatus = this.props.resourcesCache?.get(v);
            if (!resourceStatus && this.props.checkResourceAction) {
                //request the fetch of the resource
                const reportPath = this.getReportPath();
                this.props.checkResourceAction(reportPath, v);
            }
        }
    }

    private handleChange = (newValue) => {
        clearTimeout(this.timer);
        this.setState({ uncommittedValue: newValue }, this.startTimer);
    }

    private startTimer = () => {
        this.timer = setTimeout(() => {
            this.validatePath();
            this.onValueChange(false);
        }, 1000);
    }

    private handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case "Enter": // enter
            case "Escape": // esc
                e.preventDefault();
                break;
        }
        const keycode = e.keyCode;
        if (keycode === ENTER_KEY) {
            clearTimeout(this.timer);
            this.onValueChange(false);
        }
    }

    validatePath = () => {
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        let v;
        if (this.state.uncommittedValue !== undefined) {
            v = this.state.uncommittedValue;
        } else {
            v = this.props.mcontext.model.getIn(p);
        }
        if (v && v.length > 0 && this.props.resourcesCache) {
            const resourceStatus = this.props.resourcesCache.get(v);
            if (!resourceStatus) {
                //request the fetch of the resource
                const reportPath = this.getReportPath();
                this.props.checkResourceAction(reportPath, v);
            }
        }
    }

    render() {
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        let v;
        if (this.state.uncommittedValue !== undefined) {
            v = this.state.uncommittedValue;
        } else {
            v = this.props.mcontext.model.getIn(p);
        }
        const descriptor = this.props.mcontext.descriptor as PValidatedPath;
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch'}}>
            <FormControlledTextField
                WrapperProps={this.getStyleProps()}
                style={{flex: 1}}
                className="tc-jsw-validated-path"
                key={getKey(p)}
                onTextChange={this.handleChange}
                label={i18n.t(this.props.mcontext.descriptor.label)}
                inline={true}
                size={'small'}
                value={v !== undefined ? v : ''}
                type={'text'}
                disabled={descriptor.readonly}
                onKeyDown={this.handleKeyDown}
                error={this.getErrorMessage(descriptor, v)}
                inputProps={{
                    style: {paddingRight: 27},
                }}
                InputProps={{
                    title: v,
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            className={"jr-mInputSearch-adornment mui"}
                        >
                            {this.getSearchIcon()}
                        </InputAdornment>
                    )
                }}
                iconButtons={this.props.additionalIcon ? [this.props.additionalIcon] : undefined}
                InputLabelProps={{
                    disableAnimation: true,
                    className: descriptor.deprecated ? 'deprecatedProperty' : undefined,
                }} 
            />

        </div>
    }

    getStyleProps = () => {
        const style: CSSProperties = {
            flex: 1
        }
        const result: HTMLAttributes<HTMLDivElement> = {
            style
        }
        return result;
    }

    getSearchIcon = () => {
        return <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer'}}>
            <Icon icon="search" onClick={this.openSaveDialog} />
        </div>;
    }

    getErrorMessage = (descriptor: PValidatedPath, v: string) => {
        const resourceStatus = this.props.resourcesCache?.get(v);
        if (resourceStatus) {
            const currentStatus = resourceStatus.get('status');
            if (currentStatus === FETCHING_STATUS.STATUS_NOT_FOUND) {
                return i18n.t('reportdesigner.property.resourceNotFound');
            }
        }
        return undefined;
    }

    public openSaveDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = this.props.repositoryPath ? this.props.repositoryPath : [];
        const descriptor = this.props.mcontext.descriptor as PValidatedPath;
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: 'resource',
                mode: 'open',
                title: i18n.t('ResourcePicker.selectResource'),
                onFileSelected: this.confirmFile,
                defaultPath: defaultPath,
                allowSearch: true,
                allowedMimes: descriptor.allowedMimes,
            }
        );
    }

    protected confirmFile = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => {
        const path = folderPath.length > 0 ? `${RepositoryApi.inst().getParentPath(folderPath)}/${getRealName(file)}` : `/${getRealName(file)}`;  
        this.setState({ uncommittedValue: path }, () => {
            this.validatePath();
            this.onValueChange(true);
        });
    }

    public onValueChange = (refreshCache: boolean) => {
        const descriptor = this.props.mcontext.descriptor as PValidatedPath;
        let newValue = this.state.uncommittedValue;
        this.setState({ uncommittedValue: undefined }, () => {
            this.props.mcontext.elements.forEach(key => {
                if (!newValue || newValue.trim().length === 0){
                    if (this.props.mcontext.descriptor.id){
                        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
                        p.pop();
                        const path = getNormalizedPath(p);
                        this.props.mcontext.deleteElement(path, descriptor.refreshCacheOnDelete);
                    } else {
                        this.props.mcontext.deleteElement(key.path, descriptor.refreshCacheOnDelete);
                    }
                } else {
                    newValue = '/'+ newValue.replace(/^\/+/, '');

                    if (this.props.mcontext.descriptor.id){
                        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
                        const lastSegment = p.pop();
                        this.props.mcontext.setObjectProperties(p, { [lastSegment]: newValue }, refreshCache && descriptor.refreshCacheOnAdd);
                    } else {
                        this.props.mcontext.setObjectProperties(key.path, newValue, refreshCache && descriptor.refreshCacheOnAdd);
                    }
                }
            });
        });
    }
}

const mapStateToProps = (state: IState) => {
    return {
        resourcesCache: state.getIn(['report', 'cachedData', 'cachedResources'], Map<string, any>()),
        repositoryPath: state.getIn(['report', 'repositoryPath']),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        checkResourceAction: (reportPath, resourcePath) => { dispatch(ReportActions.checkResourceAction(reportPath, resourcePath)); },
    };
}

UPValidatedPath.contextType = RunContext;

export default connect(mapStateToProps, mapDispatchToProps)(UPValidatedPath);