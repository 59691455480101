/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../reducers';
import { Map } from 'immutable';
import image from '../../../assets/uxpl/img/subreport.png';
import * as ReportActions from '../../../actions/reportActions';
import { ElementTypes } from '../../../sagas/report/document/elementTypes';


interface ILineElement {
  id: string,
  element?: Map<string, any>,
  selection?: Map<string, any>;
  model?: Map<string, any>;
  elementClicked?: (id: string, info: { path: string[], type: string }, options: { metaKey: boolean, shiftKey: boolean, touch?: boolean }) => void;
}

const bookWidth = 100;

const bookHeight = 200;


class PartElement extends React.Component<ILineElement> {

  private onClick = (e: React.MouseEvent<HTMLElement>) => {

    if (!this.props.model || (e.button !== 0) || !this.props.elementClicked) {
        return;
    }
    const info =  { 
      path: ['elements', this.props.id],
      type: ElementTypes.PART,
    };
    this.props.elementClicked(this.props.id, info, { metaKey: e.metaKey, shiftKey: e.shiftKey });
}

  public render() {
    const isSelected = this.props.selection.has(this.props.id);
    let selectedStyle: React.CSSProperties = {};
    if (isSelected) {
      selectedStyle = {
        borderColor: 'rgb(0, 129, 203)',
        borderWidth: 1,
        borderStyle: 'solid',
      }
    }
    return <div style={{ display: 'flex', width: bookWidth, height: bookHeight, justifyContent: 'center', alignItems: 'center', marginLeft: 5, marginTop: 5, ...selectedStyle }} onClick={this.onClick}>
      <div style={{ width: bookWidth - 5, height: bookHeight - 5, display: 'flex', position: 'relative' }}>
        <img src={image} style={{ width: '100%', height: '100%' }} />
        <div style={{ display: 'flex', top: 5, left: 5, width: bookWidth - 10, height: bookHeight - 10, justifyContent: 'center', alignItems: 'center', position: 'absolute' }}>
          <span style={{ textAlign: 'center', wordBreak: 'break-all' }}>{this.props.element.get('partNameExpression')}</span>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state: IState, props: ILineElement) => {

  return {
    model: state.getIn(['report', 'model']),
    element: state.getIn(['report', 'model', 'elements', props.id]),
    selection: state.getIn(['report', 'selection']),
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
      elementClicked: (id: string, info: { path: string[], type: string }, options: { metaKey: boolean, shiftKey: boolean, touch?: boolean }) => {
          dispatch(ReportActions.selectElement(id, info, options.shiftKey === true));
      },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartElement);   // ,mapDispatchToProps