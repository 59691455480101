/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map, OrderedMap } from 'immutable';
import { uuidv4 } from '../../../../utils/uuidGenerator';
import { createBand, initializeMapWithObject } from '../../../../sagas/report/document/documentFactory';
import { BandTypes, ElementTypes } from '../../../../sagas/report/document/elementTypes';
import { createCVCElement, isCVC } from './JrxmCVCUtils';
import { createAdhocElement, isAdhoc } from './JrxmlAdhocUtils';
import { createBarcodeElement, isBarcode } from './JrxmlBarcodeUtils';
import { createChartElement, createSpiderChartElement, isChart, isSpiderChart } from './JrxmlChartUtils';
import { createCrosstabElement } from './JrxmlCrosstabUtils';
import { createFusionChartElement, isFusionChart } from './JrxmlFusionChartUtils';
import { createFusionWidgetElement, isFusionWidget } from './JrxmlFusionWidgetUtils';
import { addAttributeToMap, addAttributeToMapWithName, addBooleanAttributeToMap, addChildToParent, addChildValueToMap, addFloatAttributeToMap, addIntAttributeToMap, createBox, createFont, createHyperLinkForElement, createPen, createReportElement, createReportFont, generatePath, getBandTypeFromJRType, getChildNodeByName, getNamespace, getPropertyValue, IObjectCounter, readCDataText } from './JrxmlHelpers';
import { createHTML5ChartElement, isHTML5Chart } from './JrxmlHTML5ChartUtils';
import { createListElement, isList } from './JrxmlListUtils';
import { createFusionMapElement, createMapElement, createTibcoMapElement, isFusionMap, isMap, isTibcoMap } from './JrxmlMapUtils';
import { createTableElement, isTable } from './JrxmlTableUtils';

export const parseNode = (currentNode: Node, parentNode: Node | null, parentElement: Map<string, any> | null, document: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    const nodeName = currentNode.nodeName;
    if (nodeName === "band") {
        const type = parentNode.nodeName;
        const band = createBandNode(currentNode as Element, type, objectCounter);
        let newDocument = document.setIn(["bands", band.get('id')], band);
        const bandType = band.get('bandType');
        if (bandType === BandTypes.BAND_DETAIL) {
            newDocument = newDocument.updateIn(["detailsOrder"], (list: List<string>) => (list || List<string>()).push(band.get('id')));
        } else if (bandType === BandTypes.BAND_GROUP_HEADER) {
            newDocument = newDocument.updateIn(["groupHeadersOrder"], (list: List<string>) => (list || List<string>()).push(band.get('id')));
        } else if (bandType === BandTypes.BAND_GROUP_FOOTER) {
            newDocument = newDocument.updateIn(["groupFootersOrder"], (list: List<string>) => (list || List<string>()).push(band.get('id')));
        }
        currentNode.childNodes.forEach(element => {
            newDocument = parseNode(element, currentNode, band, newDocument, objectCounter);
        });
        return newDocument;
    } else if (nodeName === "part") {
        //group part is handled in its own case, here can be only a detail part
        let newDocument = document;
        let parentSection = newDocument.getIn(["bookSections", "detailSection"]) as Map<string, any> | undefined;
        if (!parentSection) {
            parentSection = createDetailSection();
            newDocument = newDocument.setIn(["bookSections", parentSection.get('id')], parentSection);
        }
        const path = generatePath(parentSection);
        const part: Map<string, any> = createPart(currentNode as Element, path, objectCounter);
        newDocument = addChildToParent(parentSection.get("id"), parentSection.get("type"), newDocument, part.get("id"));
        newDocument = newDocument.setIn(["elements", part.get("id")], part);
        return newDocument;
    } else if (nodeName === "textField") {
        const path = generatePath(parentElement);
        const textField: Map<string, any> = createTextField(currentNode as Element, path, objectCounter);
        const newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, textField.get("id"));
        return newDocument.setIn(["elements", textField.get("id")], textField);
    } else if (nodeName === "staticText") {
        const path = generatePath(parentElement);
        const staticText: Map<string, any> = createStaticText(currentNode as Element, path, objectCounter);
        const newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, staticText.get("id"));
        return newDocument.setIn(["elements", staticText.get("id")], staticText);
    } else if (nodeName === "image") {
        const path = generatePath(parentElement);
        const image: Map<string, any> = createImage(currentNode as Element, path, objectCounter);
        const newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, image.get("id"));
        return newDocument.setIn(["elements", image.get("id")], image);
    } else if (nodeName === "rectangle") {
        const path = generatePath(parentElement);
        const rectangle: Map<string, any> = createRectangle(currentNode as Element, path, objectCounter);
        const newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, rectangle.get("id"));
        return newDocument.setIn(["elements", rectangle.get("id")], rectangle);
    } else if (nodeName === "ellipse") {
        const path = generatePath(parentElement);
        const ellipse: Map<string, any> = createEllipse(currentNode as Element, path, objectCounter);
        const newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, ellipse.get("id"));
        return newDocument.setIn(["elements", ellipse.get("id")], ellipse);
    } else if (nodeName === "line") {
        const path = generatePath(parentElement);
        const line: Map<string, any> = createLine(currentNode as Element, path, objectCounter);
        const newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, line.get("id"));
        return newDocument.setIn(["elements", line.get("id")], line);
    } else if (nodeName === "subreport") {
        const path = generatePath(parentElement);
        const subreport: Map<string, any> = createSubreport(currentNode as Element, path, objectCounter);
        const newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, subreport.get("id"));
        return newDocument.setIn(["elements", subreport.get("id")], subreport);
    } else if (nodeName === "break") {
        const path = generatePath(parentElement);
        const breakElement = createBreak(currentNode as Element, path, objectCounter);
        const newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, breakElement.get("id"));
        return newDocument.setIn(["elements", breakElement.get("id")], breakElement);
    } else if (nodeName === "genericElement") {
        const path = generatePath(parentElement);
        const genericElement = createGenericElement(currentNode as Element, path, objectCounter);
        const newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, genericElement.get("id"));
        return newDocument.setIn(["elements", genericElement.get("id")], genericElement);
    } else if (nodeName === "crosstab") {
        return createCrosstabElement(currentNode as Element, parentElement, document, objectCounter);
    } else if (nodeName === "componentElement") {
        return createComponentElement(currentNode as Element, parentElement, document, objectCounter);
    } else if (nodeName === "jasperReport") {
        let newDocument = createJasperReports(currentNode as Element, document, objectCounter);
        currentNode.childNodes.forEach(element => {
            newDocument = parseNode(element, currentNode, null, newDocument, objectCounter);
        });
        return newDocument;
    } else if (nodeName === "frame") {
        const path = generatePath(parentElement);
        const frame: Map<string, any> = createFrame(currentNode as Element, path, objectCounter);
        let newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, frame.get("id"));
        newDocument = newDocument.setIn(["elements", frame.get("id")], frame);
        currentNode.childNodes.forEach(element => {
            newDocument = parseNode(element, currentNode, frame, newDocument, objectCounter);
        });
        return newDocument;
    } else if (nodeName === "elementGroup") {
        const path = generatePath(parentElement);
        const elementGroup: Map<string, any> = createElementGroup(currentNode as Element, path, objectCounter);
        let newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, elementGroup.get("id"));
        newDocument = newDocument.setIn(["elements", elementGroup.get("id")], elementGroup);
        currentNode.childNodes.forEach(element => {
            newDocument = parseNode(element, currentNode, elementGroup, newDocument, objectCounter);
        });
        return newDocument;
    } else if (nodeName === "import") {
        const importValue = (currentNode as Element).getAttribute("value");
        return document.updateIn(["imports"], (list: List<string>) => (list || List<string>()).push(importValue));
    } else if (nodeName === "queryString") {
        return document.set("queryString", readCDataText(currentNode)).set("queryLanguage", (currentNode as Element).getAttribute("language"));
    } else if (nodeName === "filterExpression") {
        return document.set("filterExpression", readCDataText(currentNode));
    } else if (nodeName === "template") {
        const templateExp = readCDataText(currentNode);
        let templateMap = Map<string, any>();
        templateMap = templateMap.set('expression', templateExp);
        templateMap = templateMap.set('id', objectCounter.uniqueID("template-"));
        templateMap = templateMap.set('type', ElementTypes.STYLE_TEMPLATE_REFERENCE);
        return document.updateIn(["templateStyles"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(templateMap));
    } else if (nodeName === "reportFont") {
        const reportFont = createReportFont(currentNode as Element);
        return document.updateIn(["reportFonts"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(reportFont));
    } else if (nodeName === "style") {
        const newStyle = createStyle(currentNode as Element, objectCounter);
        return document.updateIn(["styles"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(newStyle));
    } else if (nodeName === "scriptlet") {
        const scriptlet = createScriptlet(currentNode as Element, objectCounter);
        return document.updateIn(["scriptlets"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(scriptlet));
    } else if (nodeName === "parameter") {
        const paramter = createParameter(currentNode as Element, objectCounter);
        return document.updateIn(["parameters"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(paramter));
    } else if (nodeName === "field") {
        const field = createField(currentNode as Element, objectCounter);
        return document.updateIn(["fields"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(field));
    } else if (nodeName === "variable") {
        const variable = createVariable(currentNode as Element, objectCounter);
        return document.updateIn(["variables"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(variable));
    } else if (nodeName === "sortField") {
        const sortField = createSortField(currentNode as Element, objectCounter);
        return document.updateIn(["sortFields"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(sortField));
    } else if (nodeName === "subDataset") {
        const subdataset = createSubDataset(currentNode as Element, objectCounter);
        return document.updateIn(["subdatasets"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(subdataset));
    } else if (nodeName === "group") {
        const newDocument = createGroup(currentNode as Element, objectCounter, document);
        return newDocument;
    } else if (isChart(nodeName)) {
        return createChartElement(currentNode as Element, parentElement, document, objectCounter);
    } else {
        let newDocument = document;
        currentNode.childNodes.forEach(element => {
            newDocument = parseNode(element, currentNode, parentElement, newDocument, objectCounter);
        });
        return newDocument;
    }
}

const createDetailSection = () => {
    const section: Map<string, any> = Map<string, any>(initializeMapWithObject({
        type: ElementTypes.SECTION_DETAIL,
        elementIds: List<string>(),
        sectionType: BandTypes.BAND_DETAIL,
        id: 'detailSection',
    }));
    return section;
}

const createGroupSection = (groupName: string, groupTagName: string) => {
    let section: Map<string, any> = Map<string, any>(initializeMapWithObject({
        type: ElementTypes.SECTION_GROUP,
        elementIds: List<string>()
    }));
    if (groupTagName === "groupHeader") {
        const sectionName = "groupHeaderSection_" + groupName;
        section = section.set('sectionType', BandTypes.BAND_GROUP_HEADER);
        section = section.set('id', sectionName);
    } else if (groupTagName === "groupFooter") {
        const sectionName = "groupFooterSection_" + groupName;
        section = section.set('sectionType', BandTypes.BAND_GROUP_FOOTER);
        section = section.set('id', sectionName);
    }
    section = section.set('groupName', groupName);
    return section;
}

const parseGroupBandNode = (currentNode: Node, parentNode: Node | null, parentElement: Map<string, any> | null, document: Map<string, any>, objectCounter: IObjectCounter, groupName: string): Map<string, any> => {
    const nodeName = currentNode.nodeName;
    if (nodeName === "part") {
        let newDocument = document;
        let parentSectionName;
        const parentTagName = parentNode.nodeName;
        if (parentTagName === "groupHeader") {
            parentSectionName = "groupHeaderSection_" + groupName;
        } else {
            parentSectionName = "groupFooterSection_" + groupName;
        }
        let parentSection = newDocument.getIn(["bookSections", parentSectionName]) as Map<string, any> | undefined;
        if (!parentSection) {
            parentSection = createGroupSection(groupName, parentTagName);
            newDocument = newDocument.setIn(["bookSections", parentSection.get('id')], parentSection);
        }
        const path = generatePath(parentSection);
        const part: Map<string, any> = createPart(currentNode as Element, path, objectCounter);
        newDocument = addChildToParent(parentSection.get("id"), parentSection.get("type"), newDocument, part.get("id"));
        newDocument = newDocument.setIn(["elements", part.get("id")], part);
        return newDocument;
    } else if (nodeName === "band") {
        const type = parentNode.nodeName;
        let band = createGroupBand(currentNode as Element, type, groupName, objectCounter);
        let newDocument = document;
        if (band !== null) {
            band = band.set('groupName', groupName);
            const bandType = band.get('bandType');
            newDocument = document.setIn(["bands", band.get('id')], band);
            if (bandType === BandTypes.BAND_GROUP_HEADER) {
                newDocument = newDocument.updateIn(["groupHeadersOrder"], (list: List<string>) => (list || List<string>()).push(band.get('id')));
            } else if (bandType === BandTypes.BAND_GROUP_FOOTER) {
                newDocument = newDocument.updateIn(["groupFootersOrder"], (list: List<string>) => (list || List<string>()).push(band.get('id')));
            }
            currentNode.childNodes.forEach(element => {
                newDocument = parseNode(element, currentNode, band, newDocument, objectCounter);
            });
        }
        return newDocument;
    } else if (nodeName === "groupHeader" || nodeName === "groupFooter") {
        let newDocument = document;
        currentNode.childNodes.forEach(element => {
            newDocument = parseGroupBandNode(element, currentNode, parentElement, newDocument, objectCounter, groupName);
        });
        return newDocument;
    }
    return parseNode(currentNode, parentNode, parentElement, document, objectCounter);
}

const createJasperReports = (jasperReportsElement: Element, document: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    //const pageWidth = parseInt(jasperReportsElement.getAttribute("pageWidth") as string, 10);
    const docWidth = parseInt(jasperReportsElement.getAttribute("pageWidth") as string, 10);
    const docHeight = parseInt(jasperReportsElement.getAttribute("pageHeight") as string, 10);

    const sectionType = jasperReportsElement.getAttribute('sectionType');
    const isBook = sectionType && sectionType.toLowerCase() === 'part';

    let newDocument = document.set("docWidth", docWidth).set("docHeight", docHeight);

    if (isBook) {
        newDocument = newDocument.set('type', ElementTypes.BOOK);
    }

    newDocument = addAttributeToMap(jasperReportsElement, "name", newDocument);
    newDocument = addAttributeToMap(jasperReportsElement, "language", newDocument);
    newDocument = addAttributeToMap(jasperReportsElement, "printOrder", newDocument);
    newDocument = addAttributeToMap(jasperReportsElement, "columnDirection", newDocument);
    newDocument = addAttributeToMap(jasperReportsElement, "formatFactoryClass", newDocument);
    newDocument = addAttributeToMap(jasperReportsElement, "whenResourceMissingType", newDocument);
    newDocument = addAttributeToMap(jasperReportsElement, "resourceBundle", newDocument);
    newDocument = addAttributeToMap(jasperReportsElement, "scriptletClass", newDocument);
    newDocument = addAttributeToMap(jasperReportsElement, "whenNoDataType", newDocument);
    newDocument = addAttributeToMap(jasperReportsElement, "sectionType", newDocument);
    newDocument = addAttributeToMap(jasperReportsElement, "orientation", newDocument);
    newDocument = addAttributeToMap(jasperReportsElement, "xmlns", newDocument);
    newDocument = addAttributeToMap(jasperReportsElement, "xmlns:xsi", newDocument);
    newDocument = addAttributeToMap(jasperReportsElement, "xsi:schemaLocation", newDocument);

    newDocument = addBooleanAttributeToMap(jasperReportsElement, "isIgnorePagination", newDocument);
    newDocument = addBooleanAttributeToMap(jasperReportsElement, "isFloatColumnFooter", newDocument);
    newDocument = addBooleanAttributeToMap(jasperReportsElement, "isSummaryWithPageHeaderAndFooter", newDocument);
    newDocument = addBooleanAttributeToMap(jasperReportsElement, "isSummaryNewPage", newDocument);
    newDocument = addBooleanAttributeToMap(jasperReportsElement, "isTitleNewPage", newDocument);

    newDocument = addIntAttributeToMap(jasperReportsElement, "columnCount", newDocument);
    newDocument = addIntAttributeToMap(jasperReportsElement, "columnWidth", newDocument);
    newDocument = addIntAttributeToMap(jasperReportsElement, "columnSpacing", newDocument);
    newDocument = addIntAttributeToMap(jasperReportsElement, "leftMargin", newDocument);
    newDocument = addIntAttributeToMap(jasperReportsElement, "rightMargin", newDocument);
    newDocument = addIntAttributeToMap(jasperReportsElement, "topMargin", newDocument);
    newDocument = addIntAttributeToMap(jasperReportsElement, "bottomMargin", newDocument);

    let uuid = jasperReportsElement.getAttribute('uuid');
    if (uuid === null){
        while(uuid === null){
            const newUuid = uuidv4();
            if (!objectCounter.uuidsMap.has(newUuid)){
                uuid = newUuid;
            }
        }
    }
    objectCounter.addUuid(uuid);
    newDocument = newDocument.set('uuid', uuid)

    jasperReportsElement.childNodes.forEach(child => {
        const nodeName = child.nodeName;
        if (nodeName === "property") {
            const childElement: Element = child as Element;
            const attName = childElement.getAttribute("name");
            const attValue = getPropertyValue(childElement);
            let addProperty = true;
            if (attName === 'net.sf.jasperreports.data.adapter'){
                //avoid to add an empty data adapter property to note borke the report
                if (attValue !== null && attValue.trim().length === 0){
                    addProperty = false;
                }
            }
            if (addProperty){
                let oldValue: Map<string, any> = newDocument.getIn(["properties", attName], Map<string, any>()) as Map<string, any>;
                oldValue = oldValue.set('value', attValue);
                newDocument = newDocument.setIn(["properties", attName], oldValue);
            }
        } else if (child.nodeName === "propertyExpression") {
            const childElement: Element = child as Element;
            const attName = childElement.getAttribute("name");
            const attEvaluationTime = childElement.getAttribute("evaluationTime");
            const attValue = readCDataText(childElement);
            let oldValue = newDocument.getIn(["properties", attName], Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('valueExpression', attValue);
            if (attEvaluationTime !== null) {
                oldValue = oldValue.set('evaluationTime', attEvaluationTime);
            }
            newDocument = newDocument.setIn(["properties", attName], oldValue);
        }
    });

    return newDocument;
}

const createGroup = (groupNode: Element, objectCounter: IObjectCounter, document: Map<string, any>): Map<string, any> => {
    let newDocument = document;
    const nameValue = groupNode.getAttribute("name");
    const groupObj = {
        type: ElementTypes.MAIN_DATASET_GROUP,
        name: nameValue,
    }
    let result: Map<string, any> = Map<string, any>(initializeMapWithObject(groupObj));
    result = addBooleanAttributeToMap(groupNode, "isStartNewColumn", result);
    result = addBooleanAttributeToMap(groupNode, "isStartNewPage", result);
    result = addBooleanAttributeToMap(groupNode, "isResetPageNumber", result);
    result = addBooleanAttributeToMap(groupNode, "isReprintHeaderOnEachPage", result);
    result = addBooleanAttributeToMap(groupNode, "isReprintHeaderOnEachColumn", result);
    result = addBooleanAttributeToMap(groupNode, "keepTogether", result);
    result = addBooleanAttributeToMap(groupNode, "preventOrphanFooter", result);

    result = addIntAttributeToMap(groupNode, "minHeightToStartNewPage", result);
    result = addIntAttributeToMap(groupNode, "minDetailsToStartFromTop", result);
    result = addAttributeToMap(groupNode, "footerPosition", result);

    groupNode.childNodes.forEach(child => {
        if (child.nodeName === "groupExpression") {
            // set the expression
            const expressionValue = readCDataText(child);
            result = result.set("groupExpression", expressionValue);
        } else if (child.nodeName === "groupHeader") {
            newDocument = parseGroupBandNode(child, null, null, newDocument, objectCounter, nameValue);
        } else if (child.nodeName === "groupFooter") {
            newDocument = parseGroupBandNode(child, null, null, newDocument, objectCounter, nameValue);
        }
    });
    newDocument = newDocument.updateIn(["groups"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(result));

    return newDocument;
}

const createBaseStyle = (styleElement: Element, objectCounter: IObjectCounter): Map<string, any> => {
    let style = initializeMapWithObject({
        id: objectCounter.uniqueID("style-"),
    });

    style = addAttributeToMap(styleElement, "name", style);
    style = addAttributeToMap(styleElement, "style", style);
    style = addAttributeToMap(styleElement, "mode", style);
    style = addAttributeToMap(styleElement, "forecolor", style);
    style = addAttributeToMap(styleElement, "backcolor", style);
    style = addAttributeToMap(styleElement, "scaleImage", style);
    style = addAttributeToMap(styleElement, "hAlign", style);
    style = addAttributeToMap(styleElement, "hTextAlign", style);
    style = addAttributeToMap(styleElement, "hImageAlign", style);
    style = addAttributeToMap(styleElement, "vAlign", style);
    style = addAttributeToMap(styleElement, "vTextAlign", style);
    style = addAttributeToMap(styleElement, "vImageAlign", style);
    style = addAttributeToMap(styleElement, "border", style);
    style = addAttributeToMap(styleElement, "borderColor", style);
    style = addAttributeToMap(styleElement, "topBorder", style);
    style = addAttributeToMap(styleElement, "topBorderColor", style);
    style = addAttributeToMap(styleElement, "leftBorder", style);
    style = addAttributeToMap(styleElement, "leftBorderColor", style);
    style = addAttributeToMap(styleElement, "bottomBorder", style);
    style = addAttributeToMap(styleElement, "bottomBorderColor", style);
    style = addAttributeToMap(styleElement, "rightBorder", style);
    style = addAttributeToMap(styleElement, "rightBorderColor", style);
    style = addAttributeToMap(styleElement, "rotation", style);
    style = addAttributeToMap(styleElement, "lineSpacing", style);
    style = addAttributeToMap(styleElement, "markup", style);
    style = addAttributeToMap(styleElement, "fontName", style);
    style = addAttributeToMap(styleElement, "pdfFontName", style);
    style = addAttributeToMap(styleElement, "pdfEncoding", style);
    style = addAttributeToMap(styleElement, "pattern", style);
    style = addAttributeToMap(styleElement, "rightBorder", style);
    style = addAttributeToMap(styleElement, "rightBorder", style);
    style = addAttributeToMap(styleElement, "rightBorder", style);

    style = addBooleanAttributeToMap(styleElement, "isBlankWhenNull", style);
    style = addBooleanAttributeToMap(styleElement, "isDefault", style);
    style = addBooleanAttributeToMap(styleElement, "isStyledText", style);
    style = addBooleanAttributeToMap(styleElement, "isBold", style);
    style = addBooleanAttributeToMap(styleElement, "isItalic", style);
    style = addBooleanAttributeToMap(styleElement, "isUnderline", style);
    style = addBooleanAttributeToMap(styleElement, "isStrikeThrough", style);
    style = addBooleanAttributeToMap(styleElement, "isBlankWhenNull", style);
    style = addBooleanAttributeToMap(styleElement, "isPdfEmbedded", style);

    style = addFloatAttributeToMap(styleElement, "fontSize", style);
    style = addFloatAttributeToMap(styleElement, "rightPadding", style);
    style = addFloatAttributeToMap(styleElement, "bottomPadding", style);
    style = addFloatAttributeToMap(styleElement, "leftPadding", style);
    style = addFloatAttributeToMap(styleElement, "topPadding", style);
    style = addFloatAttributeToMap(styleElement, "padding", style);
    style = addFloatAttributeToMap(styleElement, "radius", style);

    const pen = getChildNodeByName(styleElement, "pen");
    if (pen !== null) {
        const penValue = createPen(pen as Element);
        style = style.set("pen", penValue);
    }

    const box = getChildNodeByName(styleElement, "box");
    if (box !== null) {
        const boxValue = createBox(box as Element);
        style = style.set("box", boxValue);
    }

    const paragraph = getChildNodeByName(styleElement, "paragraph");
    if (paragraph !== null) {
        const paragraphValue = createParagraph(paragraph as Element);
        style = style.set("paragraph", paragraphValue);
    }

    return style;
}

const createStyle = (styleElement: Element, objectCounter: IObjectCounter): Map<string, any> => {
    let style = createBaseStyle(styleElement, objectCounter);
    style = style.set("conditionalStyles", List<Map<string, any>>());
    style = style.set('type', ElementTypes.STYLE);
    style = style.set('id', objectCounter.uniqueID('style-'));
    styleElement.childNodes.forEach(child => {
        if (child.nodeName === "conditionalStyle") {
            const childStyleNode = getChildNodeByName(child as Element, "style");
            if (childStyleNode !== null) {
                let conditionalStyle = createBaseStyle(childStyleNode as Element, objectCounter);
                conditionalStyle = addChildValueToMap(child as Element, "conditionExpression", conditionalStyle);
                conditionalStyle = conditionalStyle.set('type', ElementTypes.CONDITIONAL_STYLE);
                conditionalStyle = conditionalStyle.set('id', objectCounter.uniqueID('conditionalStyle-'))
                style = style.updateIn(["conditionalStyles"], list => (list as List<Map<string, any>> || List<Map<string, any>>()).push(conditionalStyle));
            }
        }
    });

    return style;
}

const createSubDataset = (subdatasetNode: Element, objectCounter: IObjectCounter): Map<string, any> => {
    const nameValue = subdatasetNode.getAttribute("name");
    let result: Map<string, any> = initializeMapWithObject(
        {
            id: objectCounter.uniqueID("dataset-"),
            name: nameValue,
            properties: OrderedMap<string, Map<string, any>>(),
            scriptlets: List<Map<string, any>>(),
            parameters: List<Map<string, any>>(),
            fields: List<Map<string, any>>(),
            sortFields: List<Map<string, any>>(),
            variables: List<Map<string, any>>(),
            groups: List<Map<string, any>>(),
            type: ElementTypes.DATASET,
        });

    result = addAttributeToMap(subdatasetNode, "scriptletClass", result);
    result = addAttributeToMap(subdatasetNode, "resourceBundle", result);
    result = addAttributeToMap(subdatasetNode, "whenResourceMissingType", result);

    let uuid = subdatasetNode.getAttribute('uuid');
    if (uuid === null){
        while(uuid === null){
            const newUuid = uuidv4();
            if (!objectCounter.uuidsMap.has(newUuid)){
                uuid = newUuid;
            }
        }
    }
    objectCounter.addUuid(uuid);
    result = result.set('uuid', uuid)

    subdatasetNode.childNodes.forEach(child => {
        const nodeName = child.nodeName;
        if (nodeName === "property") {
            const childElement: Element = child as Element;
            const attName = childElement.getAttribute("name");
            const attValue = getPropertyValue(childElement);
            let addProperty = true;
            if (attName === 'net.sf.jasperreports.data.adapter'){
                //avoid to add an empty data adapter property to note borke the report
                if (attValue !== null && attValue.trim().length === 0){
                    addProperty = false;
                }
            }
            if (addProperty){
                let oldValue = result.getIn(["properties", attName], Map<string, any>()) as Map<string, any>;
                oldValue = oldValue.set('value', attValue);
                result = result.setIn(["properties", attName], oldValue);
            }
        } else if (child.nodeName === "propertyExpression") {
            const childElement: Element = child as Element;
            const attName = childElement.getAttribute("name");
            const attValue = readCDataText(childElement);
            let oldValue = result.getIn(["properties", attName], Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('valueExpression', attValue);
            const attType = childElement.getAttribute("type");
            if (attType !== null) {
                oldValue = oldValue.set('type', attType);
            }
            const attEvaluationTime = childElement.getAttribute("evaluationTime");
            if (attEvaluationTime !== null) {
                oldValue = oldValue.set('evaluationTime', attEvaluationTime);
            }
            result = result.setIn(["propertiesExpression", attName], oldValue);
        } else if (nodeName === "queryString") {
            result = result.set("queryString", readCDataText(child)).set("queryLanguage", (child as Element).getAttribute("language"));
        } else if (nodeName === "filterExpression") {
            result = result.set("filterExpression", readCDataText(child));
        } else if (nodeName === "scriptlet") {
            const scriptlet = createScriptlet(child as Element, objectCounter);
            result = result.updateIn(["scriptlets"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(scriptlet));
        } else if (nodeName === "parameter") {
            const parameter = createParameter(child as Element, objectCounter);
            result = result.updateIn(["parameters"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(parameter));
        } else if (nodeName === "field") {
            const field = createField(child as Element, objectCounter);
            result = result.updateIn(["fields"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(field));
        } else if (nodeName === "variable") {
            const variable = createVariable(child as Element, objectCounter);
            result = result.updateIn(["variables"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(variable));
        } else if (nodeName === "sortField") {
            const sortField = createSortField(child as Element, objectCounter);
            result = result.updateIn(["sortFields"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(sortField));
        } else if (nodeName === "group") {
            const group = createSubDatasetGroup(child as Element, objectCounter);
            result = result.updateIn(["groups"], (list: List<Map<string, any>>) => (list || List<Map<string, any>>()).push(group));
        }
    });
    return result;
}

const createSubDatasetGroup = (groupNode: Element, objectCounter: IObjectCounter): Map<string, any> => {
    let result: Map<string, any> = initializeMapWithObject(
        {
            id: objectCounter.uniqueID("subdatasetGroup-"),
            type: ElementTypes.DATASET_GROUP,
        });
    result = addAttributeToMap(groupNode, "name", result);
    result = addBooleanAttributeToMap(groupNode, "isReprintHeaderOnEachPage", result);
    result = addBooleanAttributeToMap(groupNode, "keepTogether", result);
    result = addBooleanAttributeToMap(groupNode, "isStartNewColumn", result);
    result = addBooleanAttributeToMap(groupNode, "isStartNewPage", result);
    result = addBooleanAttributeToMap(groupNode, "isResetPageNumber", result);
    result = addBooleanAttributeToMap(groupNode, "preventOrphanFooter", result);
    result = addBooleanAttributeToMap(groupNode, "keepTogether", result);
    result = addFloatAttributeToMap(groupNode, "minHeightToStartNewPage", result);
    result = addFloatAttributeToMap(groupNode, "minDetailsToStartFromTop", result);
    result = addAttributeToMap(groupNode, "footerPosition", result);

    result = addChildValueToMap(groupNode, "groupExpression", result);
    return result;
}

const createScriptlet = (scriptletNode: Element, objectCounter: IObjectCounter): Map<string, any> => {
    const nameValue = scriptletNode.getAttribute("name");
    const classValue = scriptletNode.getAttribute("class");
    let result = initializeMapWithObject({
        id: objectCounter.uniqueID("scriptlet-"),
        name: nameValue,
        class: classValue,
        type: ElementTypes.SCRIPTLET,
        properties: OrderedMap<string, Map<string, any>>(),
    });
    result = addChildValueToMap(scriptletNode, "scriptletDescription", result);
    scriptletNode.childNodes.forEach(child => {
        const nodeName = child.nodeName;
        if (nodeName === "property") {
            const childElement: Element = child as Element;
            const attName = childElement.getAttribute("name");
            const attValue = getPropertyValue(childElement);
            let oldValue = result.getIn(["properties", attName], Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('value', attValue);
            result = result.setIn(["properties", attName], oldValue);
        } else if (child.nodeName === "propertyExpression") {
            const childElement: Element = child as Element;
            const attName = childElement.getAttribute("name");
            const attValue = readCDataText(childElement);
            let oldValue = result.getIn(["properties", attName], Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('valueExpression', attValue);
            result = result.setIn(["properties", attName], oldValue);
        }
    });
    return result;
}

const createField = (fieldNode: Element, objectCounter: IObjectCounter): Map<string, any> => {
    const nameValue = fieldNode.getAttribute("name");
    let result = initializeMapWithObject({
        id: objectCounter.uniqueID("field-"),
        name: nameValue,
        type: ElementTypes.FIELD,
        properties: OrderedMap<string, Map<string, any>>(),
    });
    result = addAttributeToMap(fieldNode, "class", result);
    result = addChildValueToMap(fieldNode, "fieldDescription", result);
    fieldNode.childNodes.forEach(child => {
        const nodeName = child.nodeName;
        if (nodeName === "property") {
            const childElement: Element = child as Element;
            const attName = childElement.getAttribute("name");
            const attValue = getPropertyValue(childElement);
            let oldValue = result.getIn(["properties", attName], Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('value', attValue);
            result = result.setIn(["properties", attName], oldValue);
        } else if (child.nodeName === "propertyExpression") {
            const childElement: Element = child as Element;
            const attName = childElement.getAttribute("name");
            const attValue = readCDataText(childElement);
            let oldValue = result.getIn(["properties", attName], Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('valueExpression', attValue);
            result = result.setIn(["properties", attName], oldValue);
        }
    });
    return result;
}

const createParameter = (parameterNode: Element, objectCounter: IObjectCounter): Map<string, any> => {
    const nameValue = parameterNode.getAttribute("name");
    let result = initializeMapWithObject({
        id: objectCounter.uniqueID("parameter-"),
        name: nameValue,
        type: ElementTypes.PARAMETER,
        properties: OrderedMap<string, Map<string, any>>(),
    });

    result = addBooleanAttributeToMap(parameterNode, "isForPrompting", result);

    result = addAttributeToMap(parameterNode, "evaluationTime", result);
    result = addAttributeToMap(parameterNode, "nestedType", result);
    result = addAttributeToMap(parameterNode, "class", result);

    result = addChildValueToMap(parameterNode, "parameterDescription", result);
    result = addChildValueToMap(parameterNode, "defaultValueExpression", result);

    parameterNode.childNodes.forEach(child => {
        const nodeName = child.nodeName;
        if (nodeName === "property") {
            const childElement: Element = child as Element;
            const attName = childElement.getAttribute("name");
            const attValue = getPropertyValue(childElement);
            let oldValue = result.getIn(["properties", attName], Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('value', attValue);
            result = result.setIn(["properties", attName], oldValue);
        } else if (child.nodeName === "propertyExpression") {
            const childElement: Element = child as Element;
            const attName = childElement.getAttribute("name");
            const attValue = readCDataText(childElement);
            let oldValue = result.getIn(["properties", attName], Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('valueExpression', attValue);
            result = result.setIn(["properties", attName], oldValue);
        }
    });

    return result;
}

const createVariable = (variableNode: Element, objectCounter: IObjectCounter): Map<string, any> => {
    let result = initializeMapWithObject({
        id: objectCounter.uniqueID("variable-"),
        type: ElementTypes.VARIABLE
    });

    result = addAttributeToMap(variableNode, "name", result);
    result = addAttributeToMap(variableNode, "resetType", result);
    result = addAttributeToMap(variableNode, "resetGroup", result);
    result = addAttributeToMap(variableNode, "class", result);
    result = addAttributeToMap(variableNode, "incrementType", result);
    result = addAttributeToMap(variableNode, "incrementGroup", result);

    result = addAttributeToMap(variableNode, "calculation", result);

    result = addAttributeToMap(variableNode, "incrementerFactoryClass", result);

    result = addChildValueToMap(variableNode, "variableDescription", result);
    result = addChildValueToMap(variableNode, "variableExpression", result);
    result = addChildValueToMap(variableNode, "initialValueExpression", result);
    return result;
}

const createSortField = (sortFields: Element, objectCounter: IObjectCounter): Map<string, any> => {
    let result = initializeMapWithObject({
        id: objectCounter.uniqueID("sortField-"),
        type: ElementTypes.SORT_FIELD
    });

    result = addAttributeToMap(sortFields, "name", result);
    result = addAttributeToMap(sortFields, "order", result);
    result = addAttributeToMapWithName(sortFields, "type", "sortFieldType", result);
    return result;
}

const createBaseBand = (bandNode: Element, bandName: string, bandType: string) => {
    let band: Map<string, any> = createBand(bandName, bandType);
    band = addIntAttributeToMap(bandNode, "height", band);
    band = addAttributeToMap(bandNode, "splitType", band);
    band = addBooleanAttributeToMap(bandNode, "isSplitAllowed", band);
    band = addChildValueToMap(bandNode, "printWhenExpression", band);

    // add the properties
    bandNode.childNodes.forEach(child => {
        const nodeName = child.nodeName;
        if (nodeName === "property") {
            const attName = (child as Element).getAttribute("name");
            const attValue = getPropertyValue(child as Element);
            band = band.setIn(["properties", attName], attValue);
        } else if (nodeName === "returnValue") {
            let returnValue: Map<string, string> = Map<string, string>();
            returnValue = addAttributeToMap(child as Element, "toVariable", returnValue);
            returnValue = addAttributeToMap(child as Element, "calculation", returnValue);
            returnValue = addAttributeToMap(child as Element, "incrementerFactoryClass", returnValue);
            returnValue = addChildValueToMap(child as Element, "expression", returnValue);
            band = band.updateIn(["returnValues"], (list: List<Map<string, string>>) => (list || List<Map<string, string>>()).push(returnValue));
        }
    });

    return band;
}

const createPart = (partNode: Element, path: string, objectCounter: IObjectCounter) => {
    let part: Map<string, any> = Map<string, any>(initializeMapWithObject({
        type: ElementTypes.PART,
        id: objectCounter.uniqueID("ele-"),
        path,
        properties: OrderedMap<string, string | null>(),
        returnValues: List<Map<string, string>>(),
    }));

    part = addAttributeToMap(partNode, "evaluationTime", part);
    part = addAttributeToMap(partNode, "evaluationGroup", part);
    part = addChildValueToMap(partNode, "printWhenExpression", part);
    part = addChildValueToMap(partNode, "partNameExpression", part);

    let uuid = partNode.getAttribute('uuid');
    if (uuid === null){
        while(uuid === null){
            const newUuid = uuidv4();
            if (!objectCounter.uuidsMap.has(newUuid)){
                uuid = newUuid;
            }
        }
    }
    objectCounter.addUuid(uuid);
    part = part.set('uuid', uuid)

    // add the properties
    partNode.childNodes.forEach(child => {
        if (child.nodeType === Node.ELEMENT_NODE) {
            const nodeName = child.nodeName;
            if (nodeName === "property") {
                const attName = (child as Element).getAttribute("name");
                const attValue = getPropertyValue(child as Element);
                part = part.setIn(["properties", attName], attValue);
            } else {
                const baseNamespace = getNamespace(child as Element);
                const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : "";
                if (nodeName === namespace + 'subreportPart') {
                    const subreportPartElement = child as Element;
                    part = addAttributeToMap(subreportPartElement, "usingCache", part);
                    part = part.set("returnValues", List<Map<string, string>>());
                    part = part.set("subreportParameters", List<{ name: string, expression: string }>());
                    part = addChildValueToMap(subreportPartElement, "parametersMapExpression", part);
                    part = addChildValueToMap(subreportPartElement, "subreportExpression", part);
                    subreportPartElement.childNodes.forEach((subreportPartChild) => {
                        const subChildName = subreportPartChild.nodeName;
                        if (subChildName === "returnValue") {
                            let returnValue: Map<string, string> = Map<string, string>();
                            returnValue = addAttributeToMap(subreportPartChild as Element, "toVariable", returnValue);
                            returnValue = addAttributeToMap(subreportPartChild as Element, "calculation", returnValue);
                            returnValue = addAttributeToMap(subreportPartChild as Element, "incrementerFactoryClass", returnValue);
                            returnValue = addAttributeToMap(subreportPartChild as Element, "subreportVariable", returnValue);
                            part = part.updateIn(["returnValues"], (list: List<Map<string, string>>) => (list || List<Map<string, string>>()).push(returnValue));
                        } else if (subChildName === "subreportParameter") {
                            const nameValue = (subreportPartChild as Element).getAttribute("name");
                            const expressionValue = readCDataText(getChildNodeByName(subreportPartChild, "subreportParameterExpression"));
                            const subreportParameter = { name: nameValue, expression: expressionValue };
                            part = part.updateIn(["subreportParameters"], (list: List<{ name: string, expression: string }>) => (list || List<{ name: string, expression: string }>()).push(subreportParameter));
                        }
                    });
                }
            }
        }
    });
    return part;
}

const createGroupBand = (bandNode: Element, bandTagName: string, groupName: string, objectCounter: IObjectCounter): Map<string, any> | null => {
    if (bandTagName === "groupHeader") {
        const bandName = "groupHeader_" + groupName + "_" + objectCounter.uniqueGroupBandId(groupName, true);
        return createBaseBand(bandNode, bandName, BandTypes.BAND_GROUP_HEADER);
    } else if (bandTagName === "groupFooter") {
        const bandName = "groupFooter_" + groupName + "_" + objectCounter.uniqueGroupBandId(groupName, false);
        return createBaseBand(bandNode, bandName, BandTypes.BAND_GROUP_FOOTER);
    }
    return null;
}

const createBandNode = (bandNode: Element, type: string, objectCounter: IObjectCounter) => {
    let bandName = type;
    if (bandName === "detail") {
        bandName = "detail_" + objectCounter.uniqueDetailId();
    }
    return createBaseBand(bandNode, bandName, getBandTypeFromJRType(type));
}

const createElementGroup = (frameNode: Element, pathValue: string, objectCounter: IObjectCounter) => {
    const element = initializeMapWithObject({
        id: objectCounter.uniqueID("ele-"),
        path: pathValue,
        type: ElementTypes.ELEMENT_GROUP,
        selected: false,
        highlighted: false,
        elementIds: List<string>()
    });
    return element;
}

const createFrame = (frameNode: Element, pathValue: string, objectCounter: IObjectCounter) => {
    const reportElementNode: Element = getChildNodeByName(frameNode, "reportElement") as Element;
    let frameElement: Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.FRAME, objectCounter);
    const boxAttributes: Node | null = getChildNodeByName(frameNode, "box");
    if (boxAttributes !== null) {
        frameElement = frameElement.set("box", createBox(boxAttributes as Element));
    }
    frameElement = addAttributeToMap(frameNode, "borderSplitType", frameElement);
    frameElement = frameElement.set("elementIds", List<string>());
    return frameElement;
}

const createImage = (imageNode: Element, pathValue: string, objectCounter: IObjectCounter) => {
    const reportElementNode: Element = getChildNodeByName(imageNode, "reportElement") as Element;
    let result: Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.IMAGE, objectCounter);
    const boxAttributes: Node | null = getChildNodeByName(imageNode, "box");
    if (boxAttributes !== null) {
        result = result.set("box", createBox(boxAttributes as Element));
    }

    const innerGraphicElement: Element = getChildNodeByName(imageNode, "graphicElement") as Element;

    if (innerGraphicElement !== null) {
        // the name was changed to avoid clashes with the same name of the stretchType attribute of the reportElement node
        result = addAttributeToMapWithName(innerGraphicElement, "stretchType", "graphicElement_stretchType", result);
        result = addAttributeToMapWithName(innerGraphicElement, "fill", "graphicElement_fill", result);
        const penNode: Node | null = getChildNodeByName(innerGraphicElement, "pen");
        if (penNode !== null) {
            result = result.set("pen", createPen(penNode as Element));
        }
    }
    result = addAttributeToMap(imageNode, "rotation", result);
    result = addAttributeToMap(imageNode, "scaleImage", result);
    result = addAttributeToMap(imageNode, "hAlign", result);
    result = addAttributeToMap(imageNode, "vAlign", result);
    result = addAttributeToMap(imageNode, "onErrorType", result);
    result = addAttributeToMap(imageNode, "evaluationTime", result);
    result = addAttributeToMap(imageNode, "evaluationGroup", result);

    result = addBooleanAttributeToMap(imageNode, "isUsingCache", result);
    result = addBooleanAttributeToMap(imageNode, "isLazy", result);

    result = addChildValueToMap(imageNode, "imageExpression", result);

    result = createHyperLinkForElement(imageNode, result);
    return result;
}

const createBreak = (breakNode: Element, pathValue: string, objectCounter: IObjectCounter) => {
    const reportElementNode: Element = getChildNodeByName(breakNode, "reportElement") as Element;
    let result: Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.BREAK, objectCounter);
    result = addAttributeToMapWithName(breakNode, "type", "breakType", result);
    return result;
}

const createGenericElement = (genericElementNode: Element, pathValue: string, objectCounter: IObjectCounter) => {
    const reportElementNode: Element = getChildNodeByName(genericElementNode, "reportElement") as Element;
    let result: Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.GENERIC_ELEMENT, objectCounter);
    result = addAttributeToMap(genericElementNode, "evaluationTime", result);
    result = addAttributeToMap(genericElementNode, "evaluationGroup", result);

    const genericElementType = getChildNodeByName(genericElementNode, "genericElementType");
    if (genericElementType !== null) {
        result = addAttributeToMap(genericElementType as Element, "name", result);
        result = addAttributeToMap(genericElementType as Element, "namespace", result);
    }

    let genericElementParameters = List<Map<string, any>>();
    genericElementNode.childNodes.forEach(child => {
        const nodeName = child.nodeName;
        if (nodeName === "genericElementParameter") {
            let parametersValue = Map<string, string>();
            parametersValue = addAttributeToMap(child as Element, "name", parametersValue);
            parametersValue = addBooleanAttributeToMap(child as Element, "skipWhenNull", parametersValue);
            parametersValue = addChildValueToMap(child as Element, "valueExpression", parametersValue);
            genericElementParameters = genericElementParameters.push(parametersValue);
        }
    });
    if (!genericElementParameters.isEmpty()) {
        result = result.set("genericElementParameters", genericElementParameters);
    }

    return result;
}

const createTextField = (textElementNode: Element, pathValue: string, objectCounter: IObjectCounter) => {
    let result: Map<string, any> = createBaseTextElement(textElementNode, pathValue, ElementTypes.TEXTFIELD, objectCounter);

    // text field only attributes
    result = addChildValueToMap(textElementNode, "textFieldExpression", result);

    result = addChildValueToMap(textElementNode, "patternExpression", result);

    result = addBooleanAttributeToMap(textElementNode, "isStretchWithOverflow", result);
    result = addBooleanAttributeToMap(textElementNode, "isBlankWhenNull", result);

    result = addAttributeToMap(textElementNode, "evaluationTime", result);
    result = addAttributeToMap(textElementNode, "evaluationGroup", result);
    result = addAttributeToMap(textElementNode, "pattern", result);
    result = addAttributeToMap(textElementNode, "textAdjust", result);

    result = createHyperLinkForElement(textElementNode, result);

    return result;
}

const createStaticText = (textElementNode: Element, pathValue: string, objectCounter: IObjectCounter) => {
    let result: Map<string, any> = createBaseTextElement(textElementNode, pathValue, ElementTypes.STATICTEXT, objectCounter);

    // static text only attributes
    const textNode = getChildNodeByName(textElementNode, "text") as Element;
    if (textNode !== null) {
        result = result.set("text", readCDataText(textNode));
    }
    return result;
}

const getCustomComponentNodeName = (componentElement : Element) : string => {
    const childList = componentElement.childNodes;
    let name = '';
    for (let i = 0; i < childList.length && !name; i++) {
        const child = childList[i];
        const nodeName = child.nodeName;
        if (child.nodeType === Node.ELEMENT_NODE && nodeName !== "reportElement"){
            const basePrefix = getNamespace(child as Element);
            name = basePrefix.length > 0 ? nodeName.substring(basePrefix.length + 1) : nodeName;
        }
    }
    return name;
}

const createComponentElement = (componentElementNode: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) => {
    // the first match will return skupping the other, otherwise it will arrive to the end and handle the unknown element
    const isTableValue = isTable(componentElementNode);
    if (isTableValue) {
        const tableElement = createTableElement(componentElementNode, parentElement, document, objectCounter);
        return tableElement;
    }
    const isListValue = isList(componentElementNode);
    if (isListValue) {
        return createListElement(componentElementNode, parentElement, document, objectCounter);
    }
    const isBarcodeValue = isBarcode(componentElementNode);
    if (isBarcodeValue) {
        return createBarcodeElement(componentElementNode, parentElement, document, objectCounter);
    }
    const isFusionMapValue = isFusionMap(componentElementNode);
    if (isFusionMapValue) {
        return createFusionMapElement(componentElementNode, parentElement, document, objectCounter);
    }
    const isTibcoMapValue = isTibcoMap(componentElementNode);
    if (isTibcoMapValue) {
        return createTibcoMapElement(componentElementNode, parentElement, document, objectCounter);
    }
    const isMapValue = isMap(componentElementNode);
    if (isMapValue) {
        return createMapElement(componentElementNode, parentElement, document, objectCounter);
    }
    const isSpiderChartValue = isSpiderChart(componentElementNode);
    if (isSpiderChartValue) {
        return createSpiderChartElement(componentElementNode, parentElement, document, objectCounter);
    }
    const isCVCValue = isCVC(componentElementNode);
    if (isCVCValue) {
        return createCVCElement(componentElementNode, parentElement, document, objectCounter);
    }
    const isAdhocValue = isAdhoc(componentElementNode);
    if (isAdhocValue) {
        return createAdhocElement(componentElementNode, parentElement, document, objectCounter);
    }
    const isHTML5ChartValue = isHTML5Chart(componentElementNode);
    if (isHTML5ChartValue) {
        return createHTML5ChartElement(componentElementNode, parentElement, document, objectCounter);
    }
    const isFusionChartValue = isFusionChart(componentElementNode);
    if (isFusionChartValue) {
        return createFusionChartElement(componentElementNode, parentElement, document, objectCounter);
    }
    const isFusionFusionValue = isFusionWidget(componentElementNode);
    if (isFusionFusionValue) {
        return createFusionWidgetElement(componentElementNode, parentElement, document, objectCounter);
    }
    return createUnknownComponentElement(componentElementNode, parentElement, document, objectCounter);
}

const createUnknownComponentElement = (componentElementNode: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) => {
    const reportElementNode: Element = getChildNodeByName(componentElementNode, "reportElement") as Element;
    const pathValue = generatePath(parentElement);
    let result: Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.COMPONENT_ELEMENT_GENERIC, objectCounter);
    let rawJrxml = List<string>();
    const componentName = getCustomComponentNodeName(componentElementNode);
    componentElementNode.childNodes.forEach(child => {
        const nodeName = child.nodeName;
        if (child.nodeType === Node.ELEMENT_NODE && nodeName !== "reportElement") {
            // here we handle the unrecognized elements
            const oSerializer = new XMLSerializer();
            rawJrxml = rawJrxml.push(oSerializer.serializeToString(child));
        }
    });
    if (!rawJrxml.isEmpty()) {
        result = result.set("rawJrxml", rawJrxml);
    }
    result = result.set('componentName', componentName);
    const newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, result.get("id"));
    return newDocument.setIn(["elements", result.get("id")], result);
}

const createSubreport = (subreportElementNode: Element, pathValue: string, objectCounter: IObjectCounter) => {
    const reportElementNode: Element = getChildNodeByName(subreportElementNode, "reportElement") as Element;
    // basic appearance information
    let subreportElement: Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.SUBREPORT, objectCounter);
    subreportElement = subreportElement.set("returnValues", List<Map<string, string>>());
    subreportElement = subreportElement.set("subreportParameters", List<{ name: string, expression: string }>());


    subreportElement = addChildValueToMap(subreportElementNode, "parametersMapExpression", subreportElement);
    subreportElement = addChildValueToMap(subreportElementNode, "connectionExpression", subreportElement);
    subreportElement = addChildValueToMap(subreportElementNode, "dataSourceExpression", subreportElement);
    subreportElement = addChildValueToMap(subreportElementNode, "subreportExpression", subreportElement);

    subreportElement = addBooleanAttributeToMap(subreportElementNode, "isUsingCache", subreportElement);
    subreportElement = addAttributeToMap(subreportElementNode, "runToBottom", subreportElement);
    subreportElement = addAttributeToMap(subreportElementNode, "overflowType", subreportElement);

    subreportElementNode.childNodes.forEach(child => {
        const nodeName = child.nodeName;
        if (nodeName === "returnValue") {
            let returnValue: Map<string, string> = Map<string, string>();
            returnValue = addAttributeToMap(child as Element, "toVariable", returnValue);
            returnValue = addAttributeToMap(child as Element, "calculation", returnValue);
            returnValue = addAttributeToMap(child as Element, "incrementerFactoryClass", returnValue);
            returnValue = addAttributeToMap(child as Element, "subreportVariable", returnValue);
            subreportElement = subreportElement.updateIn(["returnValues"], (list: List<Map<string, string>>) => (list || List<Map<string, string>>()).push(returnValue));
        } else if (nodeName === "subreportParameter") {
            const nameValue = (child as Element).getAttribute("name");
            const expressionValue = readCDataText(getChildNodeByName(child, "subreportParameterExpression"));
            let subreportParameter = Map<string, string>();
            subreportParameter = subreportParameter.set('name', nameValue);
            subreportParameter = subreportParameter.set('expression', expressionValue);
            subreportElement = subreportElement.updateIn(["subreportParameters"], (list: List<Map<string, string>>) => (list || List<Map<string, string>>()).push(subreportParameter));
        }
    });

    return subreportElement;
}

/**
 * Converts the JR rectangle element.
 *
 * @param rectangleElementNode
 * @param pathValue
 */
const createRectangle = (rectangleElementNode: Element, pathValue: string, objectCounter: IObjectCounter) => {
    const reportElementNode: Element = getChildNodeByName(rectangleElementNode, "reportElement") as Element;
    // basic appearance information
    let rectangleElement: Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.RECTANGLE, objectCounter);
    // borders information
    const innerGraphicElement: Element = getChildNodeByName(rectangleElementNode, "graphicElement") as Element;

    if (innerGraphicElement !== null) {
        // the name was changed to avoid clashes with the same name of the stretchType attribute of the reportElement node
        rectangleElement = addAttributeToMapWithName(innerGraphicElement, "stretchType", "graphicElement_stretchType", rectangleElement);
        rectangleElement = addAttributeToMapWithName(innerGraphicElement, "fill", "graphicElement_fill", rectangleElement);
        const penNode: Node | null = getChildNodeByName(innerGraphicElement, "pen");
        if (penNode !== null) {
            rectangleElement = rectangleElement.set("pen", createPen(penNode as Element));
        }
    }

    rectangleElement = addIntAttributeToMap(rectangleElementNode, "radius", rectangleElement);
    return rectangleElement;
}

/**
 * Converts the JR ellipse element.
 *
 * @param ellipseElementNode
 * @param pathValue
 */
const createEllipse = (ellipseElementNode: Element, pathValue: string, objectCounter: IObjectCounter) => {
    const reportElementNode: Element = getChildNodeByName(ellipseElementNode, "reportElement") as Element;
    let ellipseElement: Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.ELLIPSE, objectCounter);
    const innerGraphicElement: Element = getChildNodeByName(ellipseElementNode, "graphicElement") as Element;

    if (innerGraphicElement !== null) {
        // the name was changed to avoid clashes with the same name of the stretchType attribute of the reportElement node
        ellipseElement = addAttributeToMapWithName(innerGraphicElement, "stretchType", "graphicElement_stretchType", ellipseElement);
        ellipseElement = addAttributeToMapWithName(innerGraphicElement, "fill", "graphicElement_fill", ellipseElement);
        const penNode: Node | null = getChildNodeByName(innerGraphicElement, "pen");
        if (penNode !== null) {
            ellipseElement = ellipseElement.set("pen", createPen(penNode as Element));
        }
    }

    return ellipseElement;
}

/**
 * Converts the JR line element.
 *
 * @param lineElementNode
 * @param pathValue
 */
const createLine = (lineElementNode: Element, pathValue: string, objectCounter: IObjectCounter) => {
    const reportElementNode: Element = getChildNodeByName(lineElementNode, "reportElement") as Element;
    let lineElement: Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.LINE, objectCounter);
    const innerGraphicElement: Element = getChildNodeByName(lineElementNode, "graphicElement") as Element;

    if (innerGraphicElement !== null) {
        // the name was changed to avoid clashes with the same name of the stretchType attribute of the reportElement node
        lineElement = addAttributeToMapWithName(innerGraphicElement, "stretchType", "graphicElement_stretchType", lineElement);
        lineElement = addAttributeToMapWithName(innerGraphicElement, "fill", "graphicElement_fill", lineElement);
        const penNode: Node | null = getChildNodeByName(innerGraphicElement, "pen");
        if (penNode !== null) {
            lineElement = lineElement.set("pen", createPen(penNode as Element));
        }
    }

    lineElement = addAttributeToMap(lineElementNode, "direction", lineElement);
    return lineElement;
}

const createBaseTextElement = (textElementNode: Element, pathValue: string, typeValue: string, objectCounter: IObjectCounter) => {
    const reportElementNode: Element = getChildNodeByName(textElementNode, "reportElement") as Element;
    let textElement: Map<string, any> = createReportElement(reportElementNode, pathValue, typeValue, objectCounter);

    const textAttributes: Node | null = getChildNodeByName(textElementNode, "textElement");
    if (textAttributes !== null) {

        textElement = addAttributeToMap(textAttributes as Element, "textAlignment", textElement);
        textElement = addAttributeToMap(textAttributes as Element, "verticalAlignment", textElement);
        textElement = addAttributeToMap(textAttributes as Element, "rotation", textElement);
        textElement = addAttributeToMap(textAttributes as Element, "markup", textElement);
        textElement = addAttributeToMap(textAttributes as Element, "lineSpacing", textElement);
        textElement = addBooleanAttributeToMap(textAttributes as Element, "isStyledText", textElement);

        // read the sub attributes
        const fontAttributes: Node | null = getChildNodeByName(textAttributes, "font");
        if (fontAttributes !== null) {
            const fontElement = createFont(fontAttributes as Element);
            if (!fontElement.isEmpty()) {
                textElement = textElement.set("font", fontElement);
            }
        }
        const paragraphAttributes: Node | null = getChildNodeByName(textAttributes, "paragraph");
        if (paragraphAttributes !== null) {
            const paragraphElement = createParagraph(paragraphAttributes as Element);
            if (!paragraphElement.isEmpty()) {
                textElement = textElement.set("paragraph", paragraphElement);
            }
        }
    }

    const boxAttributes: Node | null = getChildNodeByName(textElementNode, "box");
    if (boxAttributes !== null) {
        textElement = textElement.set("box", createBox(boxAttributes as Element));
    }
    return textElement;
}

const createParagraph = (paragraphNode: Element): Map<string, any> => {
    let result: Map<string, any> = Map<string, any>();

    result = addAttributeToMap(paragraphNode, "lineSpacing", result);
    result = addFloatAttributeToMap(paragraphNode, "lineSpacingSize", result);
    result = addIntAttributeToMap(paragraphNode, "firstLineIndent", result);
    result = addIntAttributeToMap(paragraphNode, "leftIndent", result);
    result = addIntAttributeToMap(paragraphNode, "rightIndent", result);
    result = addIntAttributeToMap(paragraphNode, "spacingBefore", result);
    result = addIntAttributeToMap(paragraphNode, "spacingAfter", result);
    result = addIntAttributeToMap(paragraphNode, "tabStopWidth", result);

    let tabStops: List<Map<string, string | number | null>> = List<Map<string, string | number | null>>();
    paragraphNode.childNodes.forEach(element => {
        if (element.nodeName === "tabStop") {
            const tabStopNode: Element = element as Element;
            const positionValue: number = parseInt(tabStopNode.getAttribute("position") as string, 10);
            const alignmentValue: string | null = tabStopNode.getAttribute("alignment");
            let tabStop = Map<string, string | number | null>();
            tabStop = tabStop.set('position', positionValue);
            tabStop = tabStop.set('alignment', alignmentValue);
            tabStops = tabStops.push(tabStop);
        }
    });
    if (!tabStops.isEmpty()) {
        result = result.set("tabStops", tabStops);
    }
    return result;
}

export default function jrxmlToJs(jrxml: string): Map<string, any> {
    const objectCounter: IObjectCounter = {
        objectID: 1,
        detailID: 0,
        groupIDs: Map<string, { footer: number, header: number } | null>(),
        uuidsMap: new Set<string>(),

        addUuid(uuid: string) {
            this.uuidsMap.add(uuid);
        },

        uniqueID(str: string) {
            return str + (this.objectID++);
        },

        uniqueDetailId() {
            return this.detailID++;
        },

        uniqueGroupBandId(groupName: string, isHeader: boolean) {
            let currentGroup = this.groupIDs.get(groupName, null);
            if (currentGroup === null) {
                currentGroup = { footer: 0, header: 0 };
            }
            const result = isHeader ? currentGroup.header : currentGroup.footer;

            if (isHeader) {
                currentGroup.header++;
            } else {
                currentGroup.footer++;
            }
            this.groupIDs = this.groupIDs.set(groupName, currentGroup);

            return result;
        }
    }

    let document = Map({
        type: ElementTypes.REPORT,
        // height: 842,
        docWidth: 595,
        docHeight: 842,
        properties: OrderedMap<string, Map<string, any>>(),
        elements: OrderedMap<string, Map<string, any>>(),
        bands: Map<string, any>(),
        groupHeadersOrder: List<string>(),
        groupFootersOrder: List<string>(),
        detailsOrder: List<string>(),
        templateStyles: List<string>(),
        styles: List<Map<string, any>>(),
        scriptlets: List<Map<string, any>>(),
        parameters: List<Map<string, any>>(),
        fields: List<Map<string, any>>(),
        sortFields: List<Map<string, any>>(),
        variables: List<Map<string, any>>(),
        groups: List<Map<string, any>>(),
        imports: List<string>(),
        reportFonts: List<Map<string, any>>(),
        subdatasets: List<Map<string, any>>(),
        bookSections: Map<string, any>(),
    });

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(jrxml, "text/xml");
    xmlDoc.childNodes.forEach(element => {
        document = parseNode(element, null, null, document, objectCounter);
    });
    document = document.set('lastUniqueId', objectCounter.objectID);
    return document;
}

export const isBook = (jrxml: string): boolean => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(jrxml, "text/xml");
    let jasperReportsNodeFound = false;
    const childNodes = xmlDoc.childNodes;
    let isBook = false;
    for (let i = 0; i < childNodes.length && !jasperReportsNodeFound; i++) {
        const node = childNodes[i];
        const nodeName = node.nodeName;
        if (nodeName === 'jasperReport') {
            isBook = (node as Element).getAttribute('sectionType') !== null;
            jasperReportsNodeFound = true;
        }
    }
    return isBook;
}