/* eslint-disable react/display-name */
import {
    FormHelperText,
    FormHelperTextProps as MuiFormHelperTextProps, TextField as MuiTextField,
    TextFieldProps as MuiTextFieldProps
} from '@material-ui/core';
import React, { forwardRef, HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import { ValidationResult, VALIDATION_RESULT } from '../../utils/validators';
import {
    INLINE_CLASS,
    InputSize, InputWidth, LABEL_CONTAINED, SizeToClass, WidthToClass
} from '../types/InputTypes';

export type TextFieldProps = Omit<MuiTextFieldProps, 'size' | 'error'> & {
    size?: InputSize,
    width?: InputWidth,
    inline?: boolean,
    labelContained?: boolean,
    error?: string | boolean,
    errorId?: string,
    textFieldClassName?: string,
    FormErrorTextProps?: Partial<MuiFormHelperTextProps>,
    TextFieldPrefix?: ReactNode,
    TextFieldSuffix?: ReactNode,
    WrapperProps?: HTMLAttributes<HTMLDivElement> & { [key: string]: any },
    onTextChange?: (value: string | undefined) => void
    validator?: (string) => ValidationResult,
}

export const ValidatedTexField = forwardRef<HTMLDivElement, TextFieldProps>(({
    size = 'medium',
    width = 'normal',
    labelContained = false,
    InputLabelProps = {},
    InputProps = {},
    FormHelperTextProps = {},
    FormErrorTextProps = {},
    SelectProps = {},
    className = '',
    textFieldClassName = '',
    select,
    inline,
    id,
    error,
    errorId,
    helperText,
    inputRef,
    TextFieldPrefix,
    TextFieldSuffix,
    WrapperProps,
    value,
    validator,
    onTextChange,
    onBlur,
    ...rest
}, ref) => {
    //little hack here, there is a known bug between react and the input control. If the value of the input is set
    //with the value it will reset the cursor at every change of the value, essentially react lose the information 
    //about the cursor between a render to another. There is a workaround, use the defaultValue instead of the value,
    //this workaround doesn't however work in any case because even if the passed value is correct sometime the widget
    //is not updated. The most reliable workaround is always use the exact same string of the change event to be store in the model
    //however in some cases the string is manipulated/copied and this is not reliable in an external model. For this
    //reason an internal model as state is used, and it will keep the value from the change event
    const [textValue, setTextValue] = useState<{ currentValue: string | undefined, oldValue: string | undefined }>({ currentValue: undefined, oldValue: undefined });
    const innerInputRef = useRef<HTMLInputElement>(null);
    const inlineClass = inline ? INLINE_CLASS : '';
    const labelContainedClass = labelContained ? LABEL_CONTAINED : '';
    const inputSelectClass = select ? 'jr-mInputSelect' : '';
    const { classes: inputLabelPropsClasses = {}, ...InputLabelPropsRest } = InputLabelProps;
    const { classes: inputPropsClasses = {}, ...InputPropsRest } = InputProps;
    const { classes: selectPropsClasses = {}, ...SelectPropsRest } = SelectProps;
    const { classes: helperTextPropsClasses = {}, ...HelperTextPropsRest } = FormHelperTextProps;
    const { className: errorTextPropsClassName = '', ...ErrorTextPropsRest } = FormErrorTextProps;

    const validationResult = validator ? validator(textValue.currentValue) : { message: undefined, result: VALIDATION_RESULT.VALID }

    const hasErrorText: boolean = validationResult.message !== undefined || error !== undefined;
    const errorTextId = errorId ?? (id ? `${id}-error-text` : undefined);
    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

    const inputLabelProps = {
        classes: { root: `jr-mInput-label mui ${inputLabelPropsClasses?.root ?? ''}`, ...inputLabelPropsClasses },
        disableAnimation: true,
        ...InputLabelPropsRest
    };

    const inputAriaDescribedBy = [helperTextId, errorTextId].reduce((acc, textId) => {
        const accWithSpace = acc ? `${acc} ` : '';
        return textId ? `${accWithSpace}${textId}` : acc;
    }, '');

    const inputProps = select ? {} : {
        classes: { input: `jr-mInput-text mui ${inputPropsClasses?.input ?? ''}`, ...inputPropsClasses },
        ...(inputAriaDescribedBy ? { 'aria-describedby': inputAriaDescribedBy } : {}),
        'aria-invalid': hasErrorText,
        ...InputPropsRest
    };

    const selectProps = {
        classes: { root: `jr-mInput-select mui ${selectPropsClasses?.root ?? ''}`, ...selectPropsClasses },
        ...(inputAriaDescribedBy ? { 'aria-describedby': inputAriaDescribedBy } : {}),
        ...SelectPropsRest
    };

    const helperTextProps = {
        classes: { root: `jr-mInput-helper mui ${helperTextPropsClasses?.root ?? ''}`, ...helperTextPropsClasses },
        ...HelperTextPropsRest
    };

    if (textValue.currentValue !== value && textValue.oldValue !== value) {
        setTextValue({ currentValue: (value as string | undefined), oldValue: (value as string | undefined) });
    } else if (textValue.currentValue === value && textValue.oldValue !== value) {
        setTextValue({ currentValue: (value as string | undefined), oldValue: (value as string | undefined) });
    }

    const innerOnChange = (event) => {
        const newValue = event.currentTarget.value;
        const newValidationResult = validator ? validator(newValue) : { message: undefined, result: VALIDATION_RESULT.VALID }
        let cursorPosition = event.currentTarget.selectionStart;
        if (newValidationResult.result !== VALIDATION_RESULT.INVALID) {
            //set the inner value only if the validation could accept it
            setTextValue({ currentValue: newValue, oldValue: value as string | undefined });
        } else {
            if (cursorPosition !== null) {
                cursorPosition -= 1;
            }
            if (inputRef !== null && inputRef) {
                setTimeout(() => {
                    (inputRef as any).current?.setSelectionRange(cursorPosition, cursorPosition);
                })
            } else if (innerInputRef !== null && innerInputRef) {
                setTimeout(() => {
                    (innerInputRef as any).current?.setSelectionRange(cursorPosition, cursorPosition);
                })
            }
        }
    }

    const internalBlur = (event) => {
        setTextValue({ currentValue: value as string, oldValue: value as string });
        if (onBlur) {
            onBlur(event);
        }
    }

    useEffect(() => {
        if (textValue.currentValue !== value && onTextChange) {
            if (validationResult.result === VALIDATION_RESULT.VALID) {
                onTextChange(textValue.currentValue);
            }
        }
    }, [textValue, onTextChange, value, validationResult.result]);

    return (
        <div ref={ref} className={className} {...WrapperProps}>
            {TextFieldPrefix}
            <MuiTextField
                id={id}
                select={select}
                helperText={helperText}
                variant="outlined"
                className={`jr-mInput jr-mInputText mui ${inputSelectClass} ${SizeToClass[size]} ${inlineClass} ${WidthToClass[width]} ${labelContainedClass} ${textFieldClassName}`}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
                SelectProps={selectProps}
                FormHelperTextProps={helperTextProps}
                value={textValue.currentValue}
                inputRef={inputRef ? inputRef : innerInputRef}
                onBlur={internalBlur}
                onChange={innerOnChange}
                {...rest}
                error={hasErrorText}
            />
            {TextFieldSuffix}
            {hasErrorText && (
                <FormHelperText className={`jr-mInput-error mui ${errorTextPropsClassName}`} id={errorTextId} {...ErrorTextPropsRest}>
                    {validationResult.message ? validationResult.message : error}
                </FormHelperText>
            )}
        </div>
    )
})
