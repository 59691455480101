/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { IRepositoryItemDescriptor } from '@jss/js-rest-api';
import Menu from '@material-ui/core/Menu';
import { ResourceDelete } from './ResourceDelete';
import { UxType } from '@jss/js-common';
import { ResourceInfo } from './ResourceInfo';
import { ResourceDownload } from './ResourceDownload';

export interface IResourceContextMenuProps {
    anchorEl: any;
    descriptor: IRepositoryItemDescriptor,
    onChanged: (oldDescriptor: IRepositoryItemDescriptor, newDescriptor: IRepositoryItemDescriptor) => void,
    onDeleted?: () => void,
    onClose?: () => void
}

interface IState {
    newDescriptor?: IRepositoryItemDescriptor;
    saveDisabled: boolean;
}

export class ResourceContextMenu extends React.Component<IResourceContextMenuProps, IState> {

    public state: IState = { saveDisabled: true }

    render() {
        if (this.props.anchorEl)
            return <Menu id="simple-menu" anchorEl={this.props.anchorEl.target} keepMounted open={true} onClose={this.props.onClose}
                TransitionProps={
                    {
                        onExit: this.props.onClose
                    }
                }
                anchorReference="anchorPosition"
                anchorPosition={
                    this.props.anchorEl.clientY !== null && this.props.anchorEl.clientX !== null
                        ? { top: this.props.anchorEl.clientY, left: this.props.anchorEl.clientX }
                        : undefined
                }
            >
                <ResourceInfo descriptor={this.props.descriptor} onClose={this.props.onClose} type={UxType.MENU} onChanged={this.props.onChanged} />
                <ResourceDownload descriptor={this.props.descriptor} type={UxType.MENU} onClose={this.props.onClose} />
                <ResourceDelete selection={[this.props.descriptor]} onDeleted={this.props.onDeleted} type={UxType.MENU} onClose={this.props.onClose} />
            </Menu>;
        return <></>;
    }

    private onSave = (newDescriptor: IRepositoryItemDescriptor) => {
        this.props.onChanged(this.props.descriptor, newDescriptor);
        this.props.onClose?.();
    }

}
