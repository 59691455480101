/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from "react";
import { ResourceViewer, RunContainer } from "@jss/js-repository";
import { registerMimes } from './registerMimes';
import { useSelector } from "react-redux";

registerMimes();

export function ResourceViewerWrapper() {

    const onExit = () => {
            //switched from navigate to url injection because the navigation after a refresh of the 
            //page doesn't work correctly
            window.location.href = '/';
        };
    //language is actually not used since it is read from the server conf, but this will trigger the update
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language: string | undefined = useSelector((state: any) => {
        return (state.persistentReducer.storedData as Map<string, any>).get('language') as string | undefined
    });
    return (
        <RunContainer>
            <ResourceViewer onExit={onExit}/>
        </RunContainer>
    );
}
