
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../ui/APDescriptor";
import { PEnum } from "../../ui/primitive/UPEnum";
import { PExpression } from "../../ui/primitive/UPExpression";

export const FTableRow = {
    printWhenExpression: new PExpression({ id: 'printWhenExpression', label: 'TablePrintWhen.PrintWhen' }),
    splitType: new PEnum({ id: 'splitType', label: 'TableSplitType.Label', items: [
        { key: '', value: 'TableSplitType.Inherited' },
        { key: 'Stretch', value: 'TableSplitType.Stretch'  },
        { key: 'Prevent', value: 'TableSplitType.Prevent' },
        { key: 'Immediate', value: 'TableSplitType.Immediate'  }
    ]})
};

export const MTableRow: APDescriptor = {
    type: 'table.row',
    label: 'Table Row',
    id: 'tableRowProeprtiesDescriptor',
    layouts: [FTableRow.printWhenExpression, FTableRow.splitType]
};
