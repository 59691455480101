/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const CalculationEnum = new PEnum({
    id: 'calculation', label: 'Calculation.label', default: 'Nothing',
    items: [
        { key: 'Nothing', value: 'Calculation.Nothing' },
        { key: 'Count', value: 'Calculation.Count' },
        { key: 'Sum', value: 'Calculation.Sum' },
        { key: 'Average', value: 'Calculation.Average' },
        { key: 'Lowest', value: 'Calculation.Lowest' },
        { key: 'Highest', value: 'Calculation.Highest' },
        { key: 'StandardDeviation', value: 'Calculation.StandardDeviation' },
        { key: 'Variance', value: 'Calculation.Variance' },
        { key: 'System', value: 'Calculation.System' },
        { key: 'First', value: 'Calculation.First' },
        { key: 'DistinctCount', value: 'Calculation.DistinctCount' }
    ]
});

