/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const FieldIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>jrws/outline/fields</title>
            <g id="jrws/outline/fields" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect id="fill---field" fill="#0081CB" fillRule="nonzero" x="5" y="2" width="8" height="2"></rect>
                <path d="M13,4 L13,7.342 L9.94532662,7.3429054 C9.87119107,7.3429054 9.80482324,7.38364741 9.77028733,7.44610914 L9.75072988,7.49673066 L9.43052811,8.84617473 C9.40913317,8.93634059 9.3286008,9 9.23593136,9 L9.23593136,9 L8.34993499,9 C8.25461038,9 8.17253402,9.06727594 8.15382491,9.16074652 L8.15382491,9.16074652 L7.14092219,14.2212012 L8.37881362,14.2212012 L8.151,15 L5,15 L5,4 L13,4 Z" id="Combined-Shape" fill="#C1DCF5" fillRule="nonzero"></path>
                <path d="M10.244,15.5 L10.678,13.4 L12.232,13.4 L11.798,15.5 L12.96,15.5 L13.394,13.4 L14.962,13.4 L15.1470632,12.448 L13.59,12.448 L13.856,11.188 L15.368,11.188 L15.6350663,10.236 L14.052,10.236 L14.486,8.136 L13.324,8.136 L12.89,10.236 L11.336,10.236 L11.77,8.136 L10.65,8.136 L10.216,10.236 L8.82807732,10.236 L8.606,11.188 L10.02,11.188 L9.754,12.448 L8.41137699,12.448 L8.186,13.4 L9.558,13.4 L9.124,15.5 L10.244,15.5 Z M12.428,12.448 L10.874,12.448 L11.14,11.188 L12.694,11.188 L12.428,12.448 Z" id="#" fill="#055DAB" fillRule="nonzero"></path>
            </g>
        </svg>
    );
}
export default FieldIcon;