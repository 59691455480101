/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

export interface IPoint {
  x: number,
  y: number
}

export interface ISize {
  width: number,
  height: number
}

export interface IRectangle extends IPoint, ISize {
}

export enum Direction {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  CENTER = 'center' // This is used only by the border layout, see IElementPortion.
}


 /**
  * Check if a point is inside a rectangle;
  * 
  * @param rect 
  * @param point 
  */
export const areaContainsPoint = (rect: IRectangle, point: IPoint) => {
  return   point.x >= rect.x &&
           point.x <= rect.x + rect.width &&
           point.y >= rect.y &&
           point.y <= rect.y + rect.height;
}


/**
 * Check if a rectangle is inside another rectangle;
 * 
 * @param rectContainer 
 * @param rect 
 */
export const areaContainsArea = (rectContainer: IRectangle, rect: IRectangle) => {
  return areaContainsPoint(rectContainer, { x: rect.x, y: rect.y}) &&
          areaContainsPoint(rectContainer, { x: rect.x + rect.width, y: rect.y + rect.height});
}

 /**
  * Check if a rectangle overlaps another rectangle
  * 
  * @param r1
  * @param r2 
  */
export const areaOverlapsArea = (r1: IRectangle, r2: IRectangle) => {
  return (!(r1.x > r2.x + r2.width || r1.x + r1.width < r2.x) ) &&
       (!(r1.y > r2.y + r2.height || r1.y + r1.height < r2.y) );
}


/**
 * Convert an IRectangle to css top/left/width/height
 */
export const rectangleToStyle = (r: IRectangle) => {
    return { top: r.y, left: r.x, width: r.width, height: r.height };
}