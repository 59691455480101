/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../../ui/APDescriptor";
import { MHyperlinks } from "../../../common/MHyperlinks";
import { PExpression } from "../../../../ui/primitive/UPExpression";
import { PConnectedTable } from "../../../../ui/tables/UPConnectedTable";
import { PTableElement } from "../../../../ui/tables/UPTableElement";

export const FGanttSeries = {
    startDateExpression: new PExpression({ id: 'startDateExpression', label: 'Start Date Expression' }),
    endDateExpression: new PExpression({ id: 'endDateExpression', label: 'End Date Expression' }),
    percentExpression: new PExpression({ id: 'percentExpression', label: 'Percent Expression' }),
    subtaskExpression: new PExpression({ id: 'subtaskExpression', label: 'Subtask Expression' }),
    taskExpression: new PExpression({ id: 'taskExpression', label: 'Task Expression' }),

    labelExpression: new PExpression({ id: 'labelExpression', label: 'Label Expression' }),
    seriesExpression: new PExpression({ id: 'seriesExpression', label: 'Series Expression' })
};

export const MGanttSeries: APDescriptor = new PConnectedTable({
    id: 'categorySeries', label: 'Gantt Series', rowLabel: 'Series',  rowLabelPaths:[FGanttSeries.seriesExpression.id],
    layouts: [
        new PTableElement({
            label: 'Gantt Series',
            anchorOrigin: {vertical: 'center', horizontal: 'right'},
            transformOrigin: {vertical: 'center', horizontal: 'right'},
            layouts: [FGanttSeries.labelExpression,
            FGanttSeries.seriesExpression,
            FGanttSeries.taskExpression, FGanttSeries.subtaskExpression,
            FGanttSeries.startDateExpression, FGanttSeries.endDateExpression,
            { ...MHyperlinks, id: 'itemHyperlink', label: 'Item Hyperlink' }]
        })]
});
