/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { FFusionWidgetProps, FFusionWidgets, MFusionWidgetCommon } from "./MFusionWidgetsCommon";
import BulletChartIcon from "../../../../../../assets/icons/charts/fusion/bullet_vertical.svg";
import { PDiv } from "../../../ui/composite/UPDiv";
import { PConnectedTableDetail } from "../../../ui/tables/UPConnectedTableDetail";
import { MHyperlinks } from "../../common/MHyperlinks";
import { MDatasetRun } from "../../dataset/MDatasetRun";
import { PCategories } from "../../../ui/composite/UPCategories";
import { FFusionChart } from "../chart/MFusionChart";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";

export const MFusionVerticalBullet: APDescriptor = {
    id: 'VerticalBullet', type: 'fusion.chart.VerticalBullet', label: 'Vertical Bullet', icon: BulletChartIcon, path: 'widgetProperties',
    layouts: [...MFusionWidgetCommon.layouts,
    new PAccordeon({
        id: 'vBulletsTicks', label: "Ticks", layouts: [FFusionWidgets.showTickMarks, FFusionWidgets.showTickValues, FFusionWidgets.forceTickValueDecimals, FFusionWidgets.setAdaptiveMin, FFusionWidgets.placeTicksInside, FFusionWidgets.ticksOnRight]
    }),
    new PAccordeon({
        id: 'plot', label: "Plot", layouts: [FFusionWidgets.plotFillPercent, FFusionWidgets.plotAsDot, FFusionWidgetProps.plotFillColor, FFusionWidgetProps.plotFillAlpha,
        new PAccordeon({
            id: 'plot.border', label: "Border", layouts: [FFusionWidgets.showPlotBorder, FFusionWidgetProps.plotBorderColor, FFusionWidgetProps.plotBorderAlpha, FFusionWidgetProps.plotBorderThickness]
        })
        ]
    }), new PAccordeon({
        id: 'vBullets', label: "Vertical Bullet", layouts: [new PAccordeon({
            id: 'butllet.target', label: "Target",
            layouts: [FFusionWidgets.targetColor, FFusionWidgets.targetThickness, FFusionWidgets.targetFillPercent]
        }),
        new PAccordeon({
            id: 'gauge.limits', label: "Limits",
            layouts: [FFusionWidgetProps.showLimits, FFusionWidgets.lowerLimit, FFusionWidgets.upperLimit,
            new PAccordeon({
                id: 'gauge.limits.display', label: "Display Limits",
                layouts: [FFusionWidgets.lowerLimitDisplay, FFusionWidgets.upperLimitDisplay]
            })]
        })
        ]
    })],
    default: {
        type: 'FUSION_WIDGET', widgetType: 'VerticalBullet', widgetProperties: [], valueExpression: "new Integer(20)"
    }
};

export const MFusionVerticalBulletDataset: APDescriptor = {
    id: 'VerticalBullet', type: 'fusion.chart.VerticalBullet', label: 'Vertical Bullet', icon: BulletChartIcon,
    layouts: [
        new PCategories({
            label: 'Data', layouts: [
                new PDiv({
                    id: 'fusion.data.dataset', path: 'dataset',
                    label: 'Dataset',
                    layouts: [FFusionWidgets.incrementType, FFusionWidgets.incrementWhenExpression, FFusionWidgets.resetType, MDatasetRun]
                }),
                new PDiv({
                    id: 'fusion.data.entities',
                    label: 'Value',
                    layouts: [FFusionWidgets.valueExpression, FFusionWidgets.targetExpression]
                }),
                new PConnectedTableDetail({
                    id: 'colorRanges', label: 'Color Ranges', rowLabel: 'Color Range', rowLabelPaths: ['color', 'labelExpression'], expanded: true, layouts: [new PDiv({
                        id: 'fusion.colorranges',
                        layouts: [FFusionChart.color, FFusionChart.labelExpression, FFusionWidgets.minExpression, FFusionWidgets.maxExpression
                        ]
                    })], default: { color: '#000000' }
                }),
                new PDiv({
                    id: 'fusion.data.valuesets.hyperlinks',
                    label: 'Hyperlink', path: 'hyperlink',
                    layouts: [...MHyperlinks.layouts]
                })]
        })]
};