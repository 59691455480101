/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React, {
    FC, UIEvent, useLayoutEffect, useRef, useState
} from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { IconButton } from '../IconButton/IconButton';
import { SubPanelSlots } from './collapsiblePanelTypes';
import i18n from '../../i18n';

export interface SubPanelProps {
    id: string,
    label: string,
    hideHeader?: boolean,
    slots?: SubPanelSlots,
    onClose: () => void,
    onScroll: (scrollPos: number) => void,
    scrollPos: number
}

export const SubPanel: FC<SubPanelProps> = ({
    id, label, hideHeader, children, slots = { }, onClose, onScroll, scrollPos
}) => {
    const { headerAction } = slots;
    const closeSubPanelLabel = i18n.t('materialUi.component.collapsiblePanel.close');
    const contentRef = useRef<HTMLDivElement>(null);

    const [internalScrollPos, setInternalScrollPos] = useState(0);

    const handleScroll = (ev: UIEvent<HTMLDivElement>) => {
        const newScrollPos = (ev.target as HTMLDivElement).scrollTop;
        setInternalScrollPos(newScrollPos);
        if (newScrollPos !== scrollPos) {
            onScroll(newScrollPos)
        }
    }

    useLayoutEffect(() => {
        if (contentRef.current && scrollPos !== internalScrollPos) {
            contentRef.current.scrollTop = scrollPos;
            setInternalScrollPos(scrollPos);
        }
    }, [contentRef, internalScrollPos, scrollPos])

    let header;
    const classes: string[] = [];
    classes.push("jrws-mPanel-fill-height");
    if (!hideHeader){
        header = <CardHeader
        className="jr-mPanel-section-header mui"
        classes={{ content: 'jr-mPanel-section-header-title mui' }}
        title={label}
        action={(
            <>
                {headerAction}
                <IconButton
                    data-name={`${id}-collapse`}
                    icon="cancel"
                    variant="contained"
                    color="default"
                    aria-label={closeSubPanelLabel}
                    onClick={onClose}
                />
            </>
        )}
        disableTypography
    />
    } else {
        classes.push("jrws-mPanel-no-padding");
    }


    return (
        <Card elevation={0} className="jrws-mPanel-fill">
            {header}
            <CardContent className={classes.join(" ")}  ref={contentRef} component="div" onScroll={handleScroll}>{children}</CardContent>
        </Card>
    )
};
