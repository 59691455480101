export const en = {
    "chartType.visualCategory.all": "All ",
    "chartType.visualCategory.column_and_bar": "Column and Bar",
    "chartType.visualCategory.dual_and_multiaxis": "Dual and Multi-Axis",
    "chartType.visualCategory.line_and_area": "Line and Area",
    "chartType.visualCategory.pie": "Pie",
    "chartType.visualCategory.range": "Range",
    "chartType.visualCategory.scatter_and_bubble": "Scatter and Bubble",
    "chartType.visualCategory.time_series": "Time Series",
    "chartType.visualCategory.gauge": "Gauge",
    "descriptionAlignTick": "When using multiple axis, the ticks of two or more opposite axes will automatically be aligned by adding ticks to the axis or axes with the least ticks. This can be prevented by setting alignTicks to false. If the grid lines look messy, it's a good idea to hide them for the secondary axis by setting gridLineWidth to 0.",
    "descriptionAnimation": "Set the overall animation for all chart updating.",
    "descriptionAnimation_Map": "Set the overall animation for all map updating.",
    "descriptionBackgroundColor": "The background color or gradient for the outer chart area.",
    "descriptionBorderColor": "The color of the outer chart border.",
    "descriptionBorderRadius": "The corner radius of the outer chart border.",
    "descriptionBorderWidth": "The pixel width of the outer chart border.",
    "descriptionBottomMargin": "The margin between the bottom outer edge of the chart and the plot area. Use this to set a fixed pixel value for the margin as opposed to the default dynamic margin. See also spacingBottom.",
    "descriptionBottomSpacing": "The space between the bottom edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).",
    "descriptionChartWidth": "An explicit width for the chart. By default the width is calculated from the offset width of the containing element.",
    "descriptionColorsPalette": "An array containing the default colors for the chart's series. When all colors are used, new colors are pulled from the start again.",
    "descriptionCredits": "The text for the credits label.",
    "descriptionCreditsMapText": "Credits for map source to be concatenated with conventional credit text. By default this is a format string that collects copyright information from the map if available.",
    "descriptionCreditsMapTextFull": "Detailed credits for map source to be displayed on hover of credits text. By default this is a format string that collects copyright information from the map if available.",
    "descriptionDaysXAxes": "Days pattern for the date format of the X axis label.",
    "descriptionDaysYAxes": "Days pattern for the date format of the Y axis label.",
    "descriptionDropShadow": "Whether to apply a drop shadow to the outer chart area. Requires that backgroundColor be set.",
    "descriptionFloatingLegend": "When the legend is floating, the plot area ignores it and is allowed to be placed below it.",
    "descriptionHorizontalAlignment": "The horizontal alignment of the credits label.",
    "descriptionHoursXAxes": "Hours pattern for the date format of the X axis label.",
    "descriptionHoursYAxes": "Hours pattern for the date format of the Y axis label.",
    "descriptionHyperlinkReference": "The URL for the credits label.",
    "descriptionIgnoreHiddenSeries": "If true, the axes will scale to the remaining visible series once one series is hidden. If false, hiding and showing a series will not affect the axes or the other series. For stacks, once one series within the stack is hidden, the rest of the stack will close in around it even if the axis is not affected.",
    "descriptionInvertAxes": "Whether to invert the axes so that the x axis is vertical and y axis is horizontal. When true, the x axis is reversed by default. If a bar series is present in the chart, it will be inverted automatically. Inverting the chart doesn't have an effect if there are no cartesian series in the chart, or if the chart is polar.",
    "descriptionLeftMargin": "The margin between the left outer edge of the chart and the plot area. Use this to set a fixed pixel value for the margin as opposed to the default dynamic margin. See also spacingLeft.",
    "descriptionLeftSpacing": "The space between the left edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).",
    "descriptionLegendBackgroundColor": "The background color of the legend.",
    "descriptionLegendBorderColor": "The color of the drawn border around the legend.",
    "descriptionLegendBorderRadius": "The border corner radius of the legend.",
    "descriptionLegendBorderWidth": "The width of the drawn border around the legend.",
    "descriptionLegendHorizontalAlignment": "The horizontal alignment of the legend box within the chart area.",
    "descriptionLegendItemsBottomMargin": "The pixel bottom margin for each legend item.",
    "descriptionLegendItemsLayout": "The layout of the legend items.",
    "descriptionLegendItemsReverseOrder": "Whether to reverse the order of the legend items compared to the order of the series or points as defined in the configuration object.",
    "descriptionLegendItemsTopMargin": "The pixel top margin for each legend item.",
    "descriptionLegendItemsWidth": "The width for each legend item. This is useful in a horizontal layout with many items when you want the items to align vertically.",
    "descriptionLegendMargin": "If the plot area sized is calculated automatically and the legend is not floating, the legend margin is the space between the legend and the axis labels or plot area.",
    "descriptionLegendMaxHeight": "Maximum pixel height for the legend. When the maximum height is extended, navigation will show.",
    "descriptionLegendPadding": "The inner padding of the legend box.",
    "descriptionLegendShowSymbol": "Whether to show the symbol on the right side of the text rather than the left side. This is common in Arabic and Hebraic.",
    "descriptionLegendSymbolHeight": "The pixel height of the symbol for series types that use a rectangle in the legend. Defaults to the font size of legend items.",
    "descriptionLegendSymbolPadding": "The pixel padding between the legend item symbol and the legend item text.",
    "descriptionLegendSymbolRadius": "The border radius of the symbol for series types that use a rectangle in the legend.",
    "descriptionLegendSymbolWidth": "The pixel width of the legend item symbol.",
    "descriptionLegendVerticalAlignment": "The vertical alignment of the legend box.",
    "descriptionLegendWidth": "The width of the legend box.",
    "descriptionLegendXOffset": "The x offset of the legend relative to its horizontal alignment align within chart.spacingLeft and chart.spacingRight. Negative x moves it to the left, positive x moves it to the right.",
    "descriptionLegendYOffset": "The vertical offset of the legend relative to it's vertical alignment verticalAlign within chart.spacingTop and chart.spacingBottom. Negative y moves it up, positive y moves it down.",
    "descriptionMillisecondsXAxes": "Milliseconds pattern for the date format in the tooltip's header.",
    "descriptionMillisecondsYAxes": "Milliseconds pattern for the date format in the tooltip's header.",
    "descriptionMinutesXAxes": "Minutes pattern for the date format of the X axis label.",
    "descriptionMinutesYAxes": "Minutes pattern for the date format of the Y axis label.",
    "descriptionMonths": "Months pattern for the date format of the X axis label.",
    "descriptionMonthsYAxes": "Months pattern for the date format of the Y axis label.",
    "descriptionPlotBackgroundColor": "The background color or gradient for the plot area.",
    "descriptionPlotBackgroundImage": "The URL for an image to use as the plot background. To set an image as the background for the entire chart, set a CSS background image to the container element. Note that for the image to be applied to exported charts, its URL needs to be accessible by the export server.",
    "descriptionPlotBorderColor": "The color of the inner chart or plot area border.",
    "descriptionPlotBorderWidth": "The pixel width of the plot area border.",
    "descriptionPlotOptionCropTreshold": "When the series contains less points than the crop threshold, all points are drawn, event if the points fall outside the visible plot area at the current zoom. The advantage of drawing all points (including markers and columns), is that animation is performed on updates. On the other hand, when the series contains more points than the crop threshold, the series data is cropped to only contain points that fall within the plot area. The advantage of cropping away invisible points is to increase performance on large series.",
    "descriptionPlotOptionYears": "Years pattern for the date format in the tooltip's header.",
    "descriptionPlotOptionsDashStyle": "A name for the dash style to use for the graph. Applies only to series type having a graph, like line, spline, area and scatter in case it has a lineWidth.",
    "descriptionPlotOptionsDays": "Days pattern for the date format in the tooltip's header.",
    "descriptionPlotOptionsHours": "Hours pattern for the date format in the tooltip's header.",
    "descriptionPlotOptionsLineWidth": "Pixel with of the graph line.",
    "descriptionPlotOptionsMainColor": "The main color or the series. In line type series it applies to the line and the point markers unless otherwise specified. In bar type series it applies to the bars unless a color is specified per point. The default value is pulled from the options.colors array.",
    "descriptionPlotOptionsMilliseconds": "Milliseconds pattern for the date format in the tooltip's header.",
    "descriptionPlotOptionsMinutes": "Minutes pattern for the date format in the tooltip's header.",
    "descriptionPlotOptionsMonths": "Months pattern for the date format in the tooltip's header.",
    "descriptionPlotOptionsSeconds": "Seconds pattern for the date format in the tooltip's header.",
    "descriptionPlotOptionsCursor": "You can set the cursor to \"pointer\" if you have click events attached to the series, to signal to the user that the points and lines can be clicked.",
    "descriptionPlotOptionsWeeks": "Weeks pattern for the date format in the tooltip's header.",
    "descriptionPlotShadow": "Whether to apply a drop shadow to the plot area. Requires that plotBackgroundColor be set.",
    "descriptionReflowChart": "Whether to reflow the chart to fit the width of the container div on resizing the window.",
    "descriptionRightMargin": "The margin between the right outer edge of the chart and the plot area. Use this to set a fixed pixel value for the margin as opposed to the default dynamic margin. See also spacingRight.",
    "descriptionRightSpacing": "The space between the right edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).",
    "descriptionSecondsXAxes": "Seconds pattern for the date format in the tooltip's header.",
    "descriptionSecondsYAxes": "Seconds pattern for the date format in the tooltip's header.",
    "descriptionSelectionMarkFillColor": "The background color of the marker square when selecting (zooming in on) an area of the chart.",
    "descriptionSeriesAllPointSelection": "Allow this series' points to be selected by clicking on the markers, bars or pie slices.",
    "descriptionSeriesConnectNullPoints": "Whether to connect a graph line across null points.",
    "descriptionSeriesSelected": "Whether to select the series initially. If showCheckbox is true, the checkbox next to the series name will be checked for a selected series.",
    "descriptionSeriesShowAnimation": "Enable or disable the initial animation when a series is displayed.",
    "descriptionSeriesShowCheckbox": "If true, a checkbox is displayed next to the legend item to allow selecting the series. The state of the checkbox is determined by the selected option.",
    "descriptionSeriesShowInLegend": "Whether to display this particular series or series type in the legend. The default value is true for standalone series, false for linked series.",
    "descriptionSeriesShowShadow": "Whether to apply a drop shadow to the graph line.",
    "descriptionSeriesVisible": "Set the initial visibility of the series.",
    "descriptionShowAxes": "Whether to show the axes initially. This only applies to empty charts where series are added dynamically, as axes are automatically added to cartesian series.",
    "descriptionShowCredits": "Whether to show the credits text.",
    "descriptionShowDataPoints": "Enable or disable the point marker.",
    "descriptionShowLegend": "Enable or disable the legend.",
    "descriptionShowMeasureNameOnValueAxis": "Show the measure label expression on the value axis.",
    "descriptionShowTooltip": "Enable or disable the tooltip.",
    "descriptionShowTooltipAnimation": "Enable or disable animation of the tooltip. In slow legacy IE browsers the animation is disabled by default.",
    "descriptionSubtitleFloatingTitle": "When the subtitle is floating, the plot area will not move to make space for it.",
    "descriptionSubtitleFontColor": "CSS color property that defines the foreground color.",
    "descriptionSubtitleFontFamily": "CSS font-family property that defines the font family.",
    "descriptionSubtitleFontSize": "CSS font-size property that defines the size of the font.",
    "descriptionSubtitleHorizontalAlignment": "The horizontal alignment of the subtitle.",
    "descriptionSubtitleText": "The subtitle of the chart. To disable the subtitle, set the text to null.",
    "descriptionSubtitleVerticalAlignment": "The vertical alignment of the subtitle. When a value is given, the title behaves as floating.",
    "descriptionSubtitleXPosition": "The x position of the subtitle relative to the alignment within chart.spacingLeft and chart.spacingRight.",
    "descriptionSubtitleYPosition": "The y position of the subtitle relative to the alignment within chart.spacingTop and chart.spacingBottom. By default the subtitle is laid out below the title unless the title is floating. Defaults to null.",
    "descriptionTitleFloatingTitle": "When the title is floating, the plot area will not move to make space for it.",
    "descriptionTitleFontColor": "CSS color property that defines the foreground color.",
    "descriptionTitleFontFamily": "CSS font-family property that defines the font family.",
    "descriptionTitleFontSize": "CSS font-size property that defines the size of the font.",
    "descriptionTitleHorizontalAlignment": "The horizontal alignment of the title.",
    "descriptionTitleMargin": "The margin between the title and the plot area, or if a subtitle is present, the margin between the subtitle and the plot area.",
    "descriptionTitleText": "The title of the chart. To disable the title, set the text to null.",
    "descriptionTitleVerticalAlignment": "The vertical alignment of the title. When a value is given, the title behaves as floating.",
    "descriptionTitleXPosition": "The x position of the title relative to the alignment within chart.spacingLeft and chart.spacingRight.",
    "descriptionTitleYPosition": "The y position of the title relative to the alignment within chart.spacingTop and chart.spacingBottom. By default it depends on the font size.",
    "descriptionTooltipBackgroundColor": "The background color or gradient for the tooltip.",
    "descriptionTooltipBorderColor": "The color of the tooltip border. When null, the border takes the color of the corresponding series or point.",
    "descriptionTooltipBorderRadius": "The radius of the rounded border corners.",
    "descriptionTooltipBorderWidth": "The pixel width of the tooltip border.",
    "descriptionTooltipDateFormat": "The format for the date in the tooltip header if the X axis is a datetime axis. The default is a best guess based on the smallest distance between points in the chart.",
    "descriptionTooltipFooterFormat": "A string to append to the tooltip format",
    "descriptionTooltipHeaderFormat": "The HTML of the tooltip header line. Variables are enclosed by curly brackets. Available variables are point.key, series.name, series.color and other members from the point and series objects. The point.key variable contains the category name, x value or datetime string depending on the type of axis. For datetime axes, the point.key date format can be set using tooltip.xDateFormat.",
    "descriptionTooltipPointFormat": "The HTML of the point's line in the tooltip. Variables are enclosed by curly brackets. Available variables are point.x, point.y, series.name and series.color and other properties on the same form. Furthermore, point.y can be extended by the tooltip.valuePrefix and tooltip.valueSuffix variables. This can also be overridden for each series, which makes it a good hook for displaying units.",
    "descriptionTooltipShowShadow": "Whether to apply a drop shadow to the tooltip.",
    "descriptionTooltipUseHTML": "Use HTML to render the contents of the tooltip instead of SVG. Using HTML allows advanced formatting like tables and images in the tooltip. It is also recommended for rtl languages as it works around rtl bugs in early Firefox.",
    "descriptionTooltipValueDecimals": "How many decimals to show in each series' y value. This is overridable in each series' tooltip options object. The default is to preserve all decimals.",
    "descriptionTooltipValuePrefix": "A string to prepend to each series' y value. Overridable in each series' tooltip options object.",
    "descriptionTooltipValueSuffix": "A string to append to each series' y value. Overridable in each series' tooltip options object.",
    "descriptionTopMargin": "The margin between the top outer edge of the chart and the plot area. Use this to set a fixed pixel value for the margin as opposed to the default dynamic margin. See also spacingTop.",
    "descriptionTopSpacing": "The space between the top edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).",
    "descriptionUseSharedTooltip": "When the tooltip is shared, the entire plot area will capture mouse movement or touch events. Tooltip texts for series types with ordered data (not pie, scatter, flags etc) will be shown in a single bubble. This is recommended for single series charts and for tablet/mobile optimized charts.",
    "descriptionVerticalAlignment": "The vertical alignment of the credits label.",
    "descriptionWeeksXAxes": "Weeks pattern for the date format of the X axis label.",
    "descriptionWeeksYAxes": "Weeks pattern for the date format of the Y axis label.",
    "descriptionXAxisRotation": "Rotation of the labels in degrees.",
    "descriptionXAxisStep": "To show only every n'th label on the axis, set the step to n. Setting the step to 2 shows every other label. By default, the step is calculated automatically to avoid overlap. To prevent this, set it to 1. This usually only happens on a category axis, and is often a sign that you have chosen the wrong axis type.",
    "descriptionXPosition": "The x position of the credits label.",
    "descriptionYAxisRotation": "Rotation of the labels in degrees.",
    "descriptionYAxisStep": "To show only every n'th label on the axis, set the step to n. Setting the step to 2 shows every other label. By default, the step is calculated automatically to avoid overlap. To prevent this, set it to 1. This usually only happens on a category axis, and is often a sign that you have chosen the wrong axis type.",
    "descriptionYPosition": "The y position of the credits label",
    "descriptionYearsXAxes": "Years pattern for the date format of the X axis label.",
    "descriptionYearsYAxes": "Years pattern for the date format of the Y axis label.",
    "descriptionZoomType": "Decides in what dimensions the user can zoom by dragging the mouse. Can be one of x, y or xy.",
    "labelAlignTicks": "Align Ticks",
    "labelAnimation": "Show Animation on chart update",
    "labelAnimation_Map": "Show Animation on map update",
    "labelBackgroundColor": "Background Color",
    "labelBorderColor": "Border Color",
    "labelBorderRadius": "Border Radius",
    "labelBorderWidth": "Border Width",
    "labelBottomMargin": "Bottom Margin",
    "labelBottomSpacing": "Bottom Spacing",
    "labelChartWidth": "Chart Width",
    "labelColorsPalette": "Colors Palette",
    "labelCredits": "Credits",
    "labelCreditsMapText": "Map source credits",
    "labelCreditsMapTextFull": "Detailed map source credits",
    "labelDaysXAxes": "Days",
    "labelDaysYAxes": "Days",
    "labelDropShadow": "Show Drop Shadow",
    "labelFloatingLegend": "Floating Legend",
    "labelHorizontalAlignment": "Horizontal Alignment",
    "labelHoursXAxes": "Hours",
    "labelHoursYAxes": "Hours",
    "labelHyperlinkReference": "Hyperlink Reference",
    "labelIgnoreHiddenSeries": "Ignore Hidden Series",
    "labelInvertAxes": "Invert Axes",
    "labelLeftMargin": "Left Margin",
    "labelLeftSpacing": "Left Spacing",
    "labelLegendBackgroundColor": "Background Color",
    "labelLegendBorderColor": "Border Color",
    "labelLegendBorderRadius": "Border Radius",
    "labelLegendBorderWidht": "Border Width",
    "labelLegendHorizontalAlignment": "Horizontal Alignment",
    "labelLegendItemsBottomMargin": "Bottom Margin",
    "labelLegendItemsLayout": "Items Layout",
    "labelLegendItemsReverseOrder": "Reversed order",
    "labelLegendItemsTopMargin": "Top Margin",
    "labelLegendItemsWidth": "Width",
    "labelLegendMargin": "Margin",
    "labelLegendMaxHeight": "Max Height",
    "labelLegendPadding": "Padding",
    "labelLegendShowSymbol": "Show symbol on the text's right",
    "labelLegendSymbolHeight": "Height (rectangle symbol)",
    "labelLegendSymbolPadding": "Padding",
    "labelLegendSymbolRadius": "Radius (rectangle symbol)",
    "labelLegendSymbolWidth": "Width",
    "labelLegendVerticalAlignment": "Vertical Alignment",
    "labelLegendWidth": "Width",
    "labelLegendXOffset": "X Offset",
    "labelLegendYOffset": "Y Offset",
    "labelMillisecondsXAxes": "Milliseconds",
    "labelMillisecondsYAxes": "Milliseconds",
    "labelMinutesXAxes": "Minutes",
    "labelMinutesYAxes": "Minutes",
    "labelMonthsXAxes": "Months",
    "labelMonthsYAxes": "Months",
    "labelPlotBackgroundColor": "Plot Background Color",
    "labelPlotBackgroundImage": "Plot Background Image",
    "labelPlotBorderColor": "Plot Border Color",
    "labelPlotBorderWidth": "Plot Border Width",
    "labelPlotOptionCropTreshold": "Crop Threshold",
    "labelPlotOptionsDashStyle": "Dash Style",
    "labelPlotOptionsDays": "Days",
    "labelPlotOptionsHours": "Hours",
    "labelPlotOptionsLineWidth": "Line Width",
    "labelPlotOptionsMainColor": "Main Color",
    "labelPlotOptionsMilliseconds": "Milliseconds",
    "labelPlotOptionsMinutes": "Minutes",
    "labelPlotOptionsMonths": "Months",
    "labelPlotOptionsSeconds": "Seconds",
    "labelPlotOptionsCursor": "Cursor Type",
    "labelPlotOptionsWeeks": "Weeks",
    "labelPlotOptionsYears": "Years",
    "labelPlotShadow": "Plot Shadow",
    "labelReflowChart": "Reflow Chart",
    "labelRightMargin": "Right Margin",
    "labelRightSpacing": "Right Spacing",
    "labelSecondsXAxes": "Seconds",
    "labelSecondsYAxes": "Seconds",
    "labelSelectionMarkFillColor": "Selection Marker Fill Color",
    "labelSeriesConnectNullPoints": "Connect null points",
    "labelSeriesSelected": "Selected",
    "labelSeriesShowAnimation": "Show initial animation",
    "labelSeriesShowCheckbox": "Show Checkbox",
    "labelSeriesShowInLegend": "Show in Legend",
    "labelSeriesShowShadow": "Show Shadow",
    "labelSeriesVisible": "Visible",
    "labelShowAxes": "Show Axes",
    "labelShowCredits": "Show credits",
    "labelShowDataPoints": "Show data points",
    "labelShowLegend": "Show Legend",
    "labelShowMeasureNameOnValueAxis": "Show measure name on value axis",
    "labelShowTooltip": "Show Tooltip",
    "labelShowTooltipAnimation": "Show Animation",
    "labelSubtitleFloatingTitle": "Floating title",
    "labelSubtitleFontColor": "Font Color",
    "labelSubtitleFontFamily": "Font Family",
    "labelSubtitleFontSize": "Font Size",
    "labelSubtitleHorizontalAlignment": "Horizontal alignment",
    "labelSubtitleText": "Subtitle",
    "labelSubtitleVerticalAlignment": "Vertical alignment",
    "labelSubtitleXPosition": "X Position",
    "labelSubtitleYPosition": "Y Position",
    "labelTitleFloatingTitle": "Floating title",
    "labelTitleFontColor": "Font Color",
    "labelTitleFontFamily": "Font Family",
    "labelTitleFontSize": "Font Size",
    "labelTitleHorizontalAlignment": "Horizontal alignment",
    "labelTitleMargin": "Margin",
    "labelTitleText": "Title",
    "labelTitleVerticalAlignment": "Vertical alignment",
    "labelTitleXPosition": "X Position",
    "labelTitleYPosition": "Y Position",
    "labelTooltipBackgroundColor": "Background Color",
    "labelTooltipBorderColor": "Border Color",
    "labelTooltipBorderRadius": "Border Radius",
    "labelTooltipBorderWidth": "Border Width",
    "labelTooltipDateFormat": "Date Format",
    "labelTooltipFooterFormat": "Footer Format",
    "labelTooltipHeaderFormat": "Header Format",
    "labelTooltipPointFormat": "Point Format",
    "labelTooltipShowShadow": "Show Shadow",
    "labelTooltipUseHTML": "Use HTML",
    "labelTooltipValueDecimals": "Value Decimals",
    "labelTooltipValuePrefix": "Value Prefix",
    "labelTooltipValueSuffix": "Value Suffix",
    "labelTopMargin": "Top Margin",
    "labelTopSpacing": "Top Spacing",
    "labelUseSharedTooltip": "Use Shared Tooltip",
    "labelVerticalAlignment": "Vertical Alignment",
    "labelWeeksXAxes": "Weeks",
    "labelWeeksYAxes": "Weeks",
    "labelXAxisRotation": "Degrees to rotate X axis labels",
    "labelXAxisStep": "Steps to show X axis labels",
    "labelXPosition": "X Position",
    "labelYAxisRotation": "Degrees to rotate Y axis labels",
    "labelYAxisStep": "Steps to show Y axis labels",
    "labelYPosition": "Y Position",
    "labelYearsXAxes": "Years",
    "labelYearsYAxes": "Years",
    "labelZoomType": "Zoom Type",
    "lebelSeriesAllPointSelection": "Allow points selection",
    "labelYAxisMin": "Min value",
    "labelYAxisMax": "Max value",
    "labelSolidGaugeValueDecimals": "Decimals",
    "labelSolidGaugeValueSuffix": "Suffix",
    "labelColorStops": "Color Stops",
    "labelSeriesJoinBy": "Join By",
    "descriptionSeriesJoinBy": "What property to join the mapData to the value data. For example, if joinBy is \"code\", the mapData items with a specific code is merged into the data with the same code. For maps loaded from GeoJSON, the keys may be held in each point's properties object.",
    "labelSeriesColorByPoint": "Color by Point",
    "descriptionSeriesColorByPoint": "When using automatic point colors pulled from the global colors or series-specific collections, this option determines whether the chart should receive one color per series or one color per point.",
    "descriptionYAxisMin": "The minimum value of the axis. If null the min value is automatically calculated.",
    "descriptionYAxisMax": "The maximum value of the axis. If null, the max value is automatically calculated.",
    "descriptionSolidGaugeValueSuffix": "A string to append to each series' y value. Overridable in each series' tooltip options object.",
    "descriptionSolidGaugeValueDecimals": "How many decimals to show in each series' y value. This is overridable in each series' tooltip options object. The default is to preserve all decimals",
    "descriptionColorStops": "Solid gauge series only. Color stops for the solid gauge. Use this in cases where a linear gradient between a minColor and maxColor is not sufficient. The stops is an array of tuples, where the first item is a float between 0 and 1 assigning the relative position in the gradient, and the second item is the color.",
    "tileShape": "Tile Shape",
    "colorByPoint": "Color By Point",
    "descriptionTileShape": "Shape of the of Tiles inside a Tilemap chart",
    "descriptionColorByPoint": "Enable or disable the color by point",
    "descriptionColorAxisMax": "The maximum value of the axis in terms of map point values. If null, the max value is automatically calculated. If the endOnTick option is true, the max value might be rounded up. \nDefaults to undefined.",
    "descriptionColorAxisMaxColor": "The color to represent the maximum of the color axis. Unless dataClasses or stops are set, the gradient ends at this value.\nIf dataClasses are set, the color is based on minColor and maxColor unless a color is set for each data class, or the dataClassColor is set.\nDefaults to #003399.",
    "descriptionColorAxisMin": "The minimum value of the axis in terms of map point values. If null, the min value is automatically calculated. If the startOnTick option is true, the min value might be rounded down.\nDefaults to undefined.",
    "descriptionColorAxisMinColor": "The color to represent the minimum of the color axis. Unless dataClasses or stops are set, the gradient starts at this value.\nIf dataClasses are set, the color is based on minColor and maxColor unless a color is set for each data class, or the dataClassColor is set.\nDefaults to #e6ebf5.",
    "descriptionColorAxisTickInterval": "The interval of the tick marks in axis units. When null, the tick interval is computed to approximately follow the tickPixelInterval.\nDefaults to undefined.",
    "labelColorAxisMax": "Max",
    "labelColorAxisMaxColor": "Max Color",
    "labelColorAxisMin": "Min",
    "labelColorAxisMinColor": "Min Color",
    "labelColorAxisTickInterval": "Tick Interval",
    "descriptionPlotOptionsAllAreas": "Whether all areas of the map defined in mapData should be rendered. If true, areas which don't correspond to a data point, are rendered as null points. If false, those areas are skipped.\nDefaults to true.",
    "labelPlotOptionsAllAreas": "Render All Areas",
    "descriptionPlotOptionsDataLabelsAlign": "The alignment of the data label compared to the point. If right, the right side of the label should be touching the point. For points with an extent, like columns, the alignments also dictates how to align it inside the box, as given with the inside option. Can be one of left, center or right. Defaults to center.",
    "descriptionPlotOptionsDataLabelsColor": "The text color for the data labels. Defaults to undefined. For certain series types, like column or map, the data labels can be drawn inside the points. In this case the data label will be drawn with maximum contrast by default. Additionally, it will be given a text-outline style with the opposite color, to further increase the contrast. This can be overridden by setting the text-outline style to none in the dataLabels.style option.\nDefaults to undefined.",
    "descriptionPlotOptionsDataLabelsEnabled": "Enable or disable the data labels.\nDefaults to false.",
    "descriptionPlotOptionsDataLabelsFormat": "A format string for the data label. Available variables are the same as for formatter.\nDefaults to point.value.",
    "descriptionPlotOptionsDataLabelsNullFormat": "Format for points with the value of null. Works analogously to format. nullFormat can be applied only to series which support displaying null points.\nDefaults to undefined.",
    "descriptionPlotOptionsDataLabelsOverflow": "How to handle data labels that flow outside the plot area. The default is \"justify\", which aligns them inside the plot area. To display data labels outside the plot area, set crop to false and overflow to \"allow\".\nDefaults to false.",
    "descriptionPlotOptionsDataLabelsOverlap": "Whether to allow data labels to overlap. To make the labels less sensitive for overlapping, the dataLabels.padding can be set to 0.\nDefaults to false.",
    "descriptionPlotOptionsDataLabelsRotation": "Text rotation in degrees. Note that due to a more complex structure, backgrounds, borders and padding will be lost on a rotated data label.\nDefaults to 0.",
    "descriptionPlotOptionsDataLabelsVerticalAlign": "The vertical alignment of a data label. Can be one of top, middle or bottom. Defaults to middle.",
    "labelPlotOptionsDataLabelsAlign": "Alignment",
    "labelPlotOptionsDataLabelsColor": "Color",
    "labelPlotOptionsDataLabelsEnabled": "Enabled",
    "labelPlotOptionsDataLabelsFormat": "Format",
    "labelPlotOptionsDataLabelsNullFormat": "Null Format",
    "labelPlotOptionsDataLabelsOverflow": "Overflow",
    "labelPlotOptionsDataLabelsOverlap": "Overlap",
    "labelPlotOptionsDataLabelsRotation": "Rotation",
    "labelPlotOptionsDataLabelsVerticalAlign": "Vertical Alignment",
    "descriptionLegendLayout": "The layout of the legend items. Can be one of horizontal or vertical or proximate. When proximate, the legend items will be placed as close as possible to the graphs they're representing, except in inverted charts or when the legend position doesn't allow it. Defaults to horizontal.",
    "labelLegendLayout": "Layout",
}