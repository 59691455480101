/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import BarChartIcon from "../../../../../../assets/icons/charts/bar.svg";
import { PPropertyExpression } from "../../../ui/primitive/UPValueExpession";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { PColor } from "../../../ui/primitive/UPColor";
import { PString } from "../../../ui/primitive/UPString";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { PInt } from "../../../ui/primitive/UPInteger";
import { PEnum } from "../../../ui/primitive/UPEnum";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { EvaluationTimeEnum } from "../../enums/EvaluationTimeEnum";
import { IncrementTypeEnum } from "../../enums/IncrementTypeEnum";
import { ResetTypeEnum } from "../../enums/ResetTypeEnum";

export const FFusionWidgets = {
    mapNameExpression: new PExpression({ id: 'mapNameExpression', label: 'Map Name' }),
    entityNamesBundle: new PString({ id: 'entityNamesBundle', label: 'Names Bundle' }),
    evaluationTime: EvaluationTimeEnum,

    incrementType: IncrementTypeEnum,
    resetType: ResetTypeEnum,
    incrementWhenExpression: new PExpression({ id: 'incrementWhenExpression', label: 'Increment When' }),
    valueExpression: new PExpression({ id: 'valueExpression', label: 'Value' }),

    targetExpression: new PExpression({ id: 'targetExpression', label: 'Target' }),
    minExpression: new PExpression({ id: 'minExpression', label: 'Min' }),
    maxExpression: new PExpression({ id: 'maxExpression', label: 'Max' }),


    color: new PPropertyExpression({ id: 'color', vtype: new PColor({ id: 'color', label: 'Color' }) }),
    alpha: new PPropertyExpression({ id: 'alpha', vtype: new PInt({ id: 'alpha', label: 'Alpha', min: 0, max: 255 }) }),
    showValue: new PPropertyExpression({ id: 'showValue', vtype: new PBoolean({ id: 'showValue', label: 'Show Value' }) }),
    editMode: new PPropertyExpression({ id: 'editMode', vtype: new PBoolean({ id: 'editMode', label: 'Edit Mode' }) }),
    valueInside: new PPropertyExpression({ id: 'valueInside', vtype: new PBoolean({ id: 'valueInside', label: 'Value Inside' }) }),

    markerColor: new PPropertyExpression({ id: 'markerColor', vtype: new PColor({ id: 'markerColor', label: 'Color' }) }),
    useMarker: new PPropertyExpression({ id: 'useMarker', vtype: new PBoolean({ id: 'useMarker', label: 'Use Marker' }) }),

    dashed: new PPropertyExpression({ id: 'dashed', vtype: new PBoolean({ id: 'dashed', label: 'Dashed' }) }),
    isDashed: new PPropertyExpression({ id: 'isDashed', vtype: new PBoolean({ id: 'isDashed', label: 'Dashed' }) }),
    issliced: new PPropertyExpression({ id: 'issliced', vtype: new PBoolean({ id: 'issliced', label: 'Is Sliced' }) }),
    thickness: new PPropertyExpression({ id: 'thickness', vtype: new PInt({ id: 'thickness', label: 'Thickness', min: 0 }) }),
    dashLen: new PPropertyExpression({ id: 'dashLen', vtype: new PInt({ id: 'dashLen', label: 'Dash Length', min: 0 }) }),
    dashGap: new PPropertyExpression({ id: 'dashGap', vtype: new PInt({ id: 'dashGap', label: 'Dash Gap', min: 0 }) }),

    bordercolor: new PPropertyExpression({ id: 'bordercolor', vtype: new PColor({ id: 'bordercolor', label: 'Color' }) }),
    borderalpha: new PPropertyExpression({ id: 'borderalpha', vtype: new PInt({ id: 'borderalpha', label: 'Alpha', min: 0, max: 255 }) }),

    taskIdExpression: new PExpression({ id: 'taskIdExpression', label: 'Task ID' }),
    dateExpression: new PExpression({ id: 'dateExpression', label: 'Date' }),

    numSides: new PPropertyExpression({ id: 'numSides', vtype: new PInt({ id: 'numSides', label: 'Num Sides', min: 0 }) }),
    shape: new PPropertyExpression({
        id: 'shape', vtype: new PEnum({
            id: 'shape', label: 'Shape', writeable: true,
            items: [{ key: "", value: "Default" }, { key: "star", value: "Star" },
            { key: "polygon", value: "Polygon" }]
        })
    }),

    align: new PPropertyExpression({
        id: 'align', vtype: new PEnum({
            id: 'align', label: 'Horizontal Align', writeable: true,
            items: [{ key: "", value: "Default" },
            { key: "left", value: "Left" },
            { key: "center", value: "Center" },
            { key: "right", value: "Right" }]
        })
    }),
    vAlign: new PPropertyExpression({
        id: 'vAlign', vtype: new PEnum({
            id: 'vAlign', label: 'Vertical Align', writeable: true,
            items: [{ key: "", value: "" },
            { key: "top", value: "Top" },
            { key: "middle", value: "Middle" },
            { key: "bottom", value: "Bottom" }]
        })
    }),
    headerAlign: new PPropertyExpression({
        id: 'headerAlign', vtype: new PEnum({
            id: 'headerAlign', label: 'Horizontal Align', writeable: true,
            items: [{ key: "", value: "Default" },
            { key: "left", value: "Left" },
            { key: "center", value: "Center" },
            { key: "right", value: "Right" }]
        })
    }),
    headerVAlign: new PPropertyExpression({
        id: 'headerVAlign', vtype: new PEnum({
            id: 'headerVAlign', label: 'Vertical Align', writeable: true,
            items: [{ key: "", value: "" },
            { key: "top", value: "Top" },
            { key: "middle", value: "Middle" },
            { key: "bottom", value: "Bottom" }]
        })
    }),
    positionInGrid: new PPropertyExpression({
        id: 'positionInGrid', vtype: new PEnum({
            id: 'positionInGrid', label: 'Position In Grid', writeable: true,
            items: [{ key: "", value: "Default" },
            { key: "left", value: "Left" }, 
            { key: "right", value: "Right" }]
        })
    }),

    fromTaskIdExpression: new PExpression({ id: 'fromTaskIdExpression', label: 'From Task ID' }),
    toTaskIdExpression: new PExpression({ id: 'toTaskIdExpression', label: 'To Task ID' }),
    fromTaskConnectStartExpression: new PExpression({ id: 'fromTaskConnectStartExpression', label: 'From Task Connect Start' }),
    toTaskConnectEndExpression: new PExpression({ id: 'toTaskConnectEndExpression', label: 'To Task Connect End' }),
    idExpression: new PExpression({ id: 'idExpression', label: 'ID' }),
    headerTextExpression: new PExpression({ id: 'headerTextExpression', label: 'Header Text' }),
    textExpression: new PExpression({ id: 'textExpression', label: 'Text' }),
    startExpression: new PExpression({ id: 'startExpression', label: 'Start' }),
    endExpression: new PExpression({ id: 'endExpression', label: 'End' }),
    processIdExpression: new PExpression({ id: 'endExpression', label: 'Process ID' }),
    isGroupExpression: new PExpression({ id: 'endExpression', label: 'Is Group' }),
    percentCompleteExpression: new PExpression({ id: 'endExpression', label: 'Percent Complete' }),
    bulkCategoriesExpression: new PExpression({ id: 'bulkCategoriesExpression', label: 'Bulk Categories Expression' }),

    font: new PPropertyExpression({ id: 'font', vtype: new PString({ id: 'font', label: 'Font' }) }),
    fontSize: new PPropertyExpression({ id: 'fontSize', vtype: new PInt({ id: 'fontSize', label: 'Size', min: 0 }) }),
    fontColor: new PPropertyExpression({ id: 'fontColor', vtype: new PColor({ id: 'fontColor', label: 'Color' }) }),
    isBold: new PPropertyExpression({ id: 'isBold', vtype: new PBoolean({ id: 'isBold', label: 'Bold' }) }),
    isItalic: new PPropertyExpression({ id: 'isItalic', vtype: new PBoolean({ id: 'isItalic', label: 'Italic' }) }),
    isUnderline: new PPropertyExpression({ id: 'isUnderline', vtype: new PBoolean({ id: 'isUnderline', label: 'Underline' }) }),


    fontsize: new PPropertyExpression({ id: 'fontsize', vtype: new PInt({ id: 'fontsize', label: 'Size', min: 0 }) }),
    fontcolor: new PPropertyExpression({ id: 'fontcolor', vtype: new PColor({ id: 'fontcolor', label: 'Color' }) }),
    isbold: new PPropertyExpression({ id: 'isbold', vtype: new PBoolean({ id: 'isbold', label: 'Bold' }) }),
    isitalic: new PPropertyExpression({ id: 'isitalic', vtype: new PBoolean({ id: 'isitalic', label: 'Italic' }) }),
    isunderline: new PPropertyExpression({ id: 'isunderline', vtype: new PBoolean({ id: 'isunderline', label: 'Underline' }) }),


    headerFont: new PPropertyExpression({ id: 'headerFont', vtype: new PString({ id: 'headerFont', label: 'Font' }) }),
    headerFontSize: new PPropertyExpression({ id: 'headerFontSize', vtype: new PInt({ id: 'headerFontSize', label: 'Size', min: 0 }) }),
    headerFontColor: new PPropertyExpression({ id: 'headerFontColor', vtype: new PColor({ id: 'headerFontColor', label: 'Color' }) }),
    headerIsBold: new PPropertyExpression({ id: 'headerIsBold', vtype: new PBoolean({ id: 'headerIsBold', label: 'Bold' }) }),
    headerIsItalic: new PPropertyExpression({ id: 'headerIsItalic', vtype: new PBoolean({ id: 'headerIsItalic', label: 'Italic' }) }),
    headerIsUnderline: new PPropertyExpression({ id: 'headerIsUnderline', vtype: new PBoolean({ id: 'headerIsUnderline', label: 'Underline' }) }),

    headerBgColor: new PPropertyExpression({ id: 'headerBgColor', vtype: new PColor({ id: 'headerBgColor', label: 'Color' }) }),
    headerBgAlpha: new PPropertyExpression({ id: 'headerBgAlpha', vtype: new PInt({ id: 'headerBgAlpha', label: 'Alpha', min: 0, max: 255 }) }),

    showPercentLabel: new PPropertyExpression({ id: 'showPercentLabel', vtype: new PBoolean({ id: 'showPercentLabel', label: 'Percent Labels', canBeNull: true }) }),
    showStartDate: new PPropertyExpression({ id: 'showStartDate', vtype: new PBoolean({ id: 'showStartDate', label: 'Show Start Date', canBeNull: true }) }),
    showEndDate: new PPropertyExpression({ id: 'showEndDate', vtype: new PBoolean({ id: 'showEndDate', label: 'Show End Date', canBeNull: true }) }),

    showpercentlabel: new PPropertyExpression({ id: 'showpercentlabel', vtype: new PBoolean({ id: 'showpercentlabel', label: 'Percent Labels', canBeNull: true }) }),
    showstartdate: new PPropertyExpression({ id: 'showstartdate', vtype: new PBoolean({ id: 'showstartdate', label: 'Show Start Date', canBeNull: true }) }),
    showenddate: new PPropertyExpression({ id: 'showenddate', vtype: new PBoolean({ id: 'showenddate', label: 'Show End Date', canBeNull: true }) }),

    height: new PPropertyExpression({ id: 'height', vtype: new PInt({ id: 'height', label: 'Height (%)', min: 0 }) }),
    topPadding: new PPropertyExpression({ id: 'topPadding', vtype: new PInt({ id: 'topPadding', label: 'Top Padding (%)', min: 0 }) }),
    toppadding: new PPropertyExpression({ id: 'toppadding', vtype: new PInt({ id: 'toppadding', label: 'Top Padding (%)', min: 0 }) }),

    showborder: new PPropertyExpression({ id: 'showborder', vtype: new PBoolean({ id: 'showborder', label: 'Show Border' }) }),
    borderthickness: new PPropertyExpression({ id: 'borderthickness', vtype: new PInt({ id: 'borderthickness', label: 'Thickness', min: 0 }) }),
    verticalPadding: new PPropertyExpression({ id: 'verticalPadding', vtype: new PInt({ id: 'verticalPadding', label: 'Vertical Padding', min: 0 }) }),

    dateInToolTip: new PPropertyExpression({ id: 'dateInToolTip', vtype: new PBoolean({ id: 'dateInToolTip', label: 'Show Date In Tool Tip' }) }),
    showFullDataTable: new PPropertyExpression({ id: 'showFullDataTable', vtype: new PBoolean({ id: 'showFullDataTable', label: 'Show Full Data Table' }) }),

    ganttLineColor: new PPropertyExpression({ id: 'ganttLineColor', vtype: new PColor({ id: 'ganttLineColor', label: 'Color' }) }),
    ganttLineAlpha: new PPropertyExpression({ id: 'ganttLineAlpha', vtype: new PInt({ id: 'ganttLineAlpha', label: 'Alpha', min: 0, max: 255 }) }),

    ticksOnRight: new PPropertyExpression({ id: 'ticksOnRight', vtype: new PBoolean({ id: 'ticksOnRight', label: 'Ticks On Right' }) }),
    showTickMarks: new PPropertyExpression({ id: 'showTickMarks', vtype: new PBoolean({ id: 'showTickMarks', label: 'Show Tick Marks' }) }),
    showTickValues: new PPropertyExpression({ id: 'showTickValues', vtype: new PBoolean({ id: 'showTickValues', label: 'Show Tick Values' }) }),
    forceTickValueDecimals: new PPropertyExpression({ id: 'forceTickValueDecimals', vtype: new PBoolean({ id: 'forceTickValueDecimals', label: 'Force Tick Value Decimals' }) }),
    setAdaptiveMin: new PPropertyExpression({ id: 'setAdaptiveMin', vtype: new PBoolean({ id: 'setAdaptiveMin', label: 'Set Adaptive Min' }) }),
    placeTicksInside: new PPropertyExpression({ id: 'placeTicksInside', vtype: new PBoolean({ id: 'placeTicksInside', label: 'Place Ticks Inside' }) }),
    placeValuesInside: new PPropertyExpression({ id: 'placeValuesInside', vtype: new PBoolean({ id: 'placeValuesInside', label: 'Place Values Inside' }) }),

    gaugeFillColor: new PPropertyExpression({ id: 'gaugeFillColor', vtype: new PColor({ id: 'gaugeFillColor', label: 'Color' }) }),
    gaugeFillAlpha: new PPropertyExpression({ id: 'gaugeFillAlpha', vtype: new PInt({ id: 'gaugeFillAlpha', label: 'Alpha', min: 0, max: 255 }) }),

    showGaugeBorder: new PPropertyExpression({ id: 'showGaugeBorder', vtype: new PBoolean({ id: 'showGaugeBorder', label: 'Show Border' }) }),
    gaugeBorderColor: new PPropertyExpression({ id: 'gaugeBorderColor', vtype: new PColor({ id: 'gaugeBorderColor', label: 'Color' }) }),
    gaugeBorderAlpha: new PPropertyExpression({ id: 'gaugeBorderAlpha', vtype: new PInt({ id: 'gaugeBorderAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    gaugeBorderThickness: new PPropertyExpression({ id: 'gaugeBorderThickness', vtype: new PInt({ id: 'gaugeBorderThickness', label: 'Thickness', min: 0 }) }),
    
    gaugeStartAngle: new PPropertyExpression({ id: 'gaugeStartAngle', vtype: new PInt({ id: 'gaugeStartAngle', label: 'Start Angle', min: 0 }) }),
    gaugeEndAngle: new PPropertyExpression({ id: 'gaugeEndAngle', vtype: new PInt({ id: 'gaugeEndAngle', label: 'End Angle', min: 0 }) }),
    gaugeOriginX: new PPropertyExpression({ id: 'gaugeOriginX', vtype: new PInt({ id: 'gaugeOriginX', label: 'Origin X', min: 0 }) }),
    gaugeOriginY: new PPropertyExpression({ id: 'gaugeOriginY', vtype: new PInt({ id: 'gaugeOriginY', label: 'Origin Y', min: 0 }) }),
    gaugeInnerRadius: new PPropertyExpression({ id: 'gaugeInnerRadius', vtype: new PInt({ id: 'gaugeInnerRadius', label: 'Inner Radius', min: 0 }) }),
    gaugeOuterRadius: new PPropertyExpression({ id: 'gaugeOuterRadius', vtype: new PInt({ id: 'gaugeOuterRadius', label: 'Outer Radius', min: 0 }) }),
    gaugeRoundRadius: new PPropertyExpression({ id: 'gaugeRoundRadius', vtype: new PInt({ id: 'gaugeRoundRadius', label: 'Round Radius', min: 0 }) }),

    thmHeight: new PPropertyExpression({ id: 'thmHeight', vtype: new PInt({ id: 'thmHeight', label: 'Height', min: 0 }) }),
    thmBulbRadius: new PPropertyExpression({ id: 'thmBulbRadius', vtype: new PInt({ id: 'thmBulbRadius', label: 'Bulb Radius', min: 0 }) }),

    cylFillColor: new PPropertyExpression({ id: 'cylFillColor', vtype: new PColor({ id: 'cylFillColor', label: 'Fill Color' }) }),
    cylHeight: new PPropertyExpression({ id: 'cylHeight', vtype: new PInt({ id: 'cylHeight', label: 'Height', min: 0 }) }),
    cylYScale: new PPropertyExpression({ id: 'cylYScale', vtype: new PInt({ id: 'cylYScale', label: 'Y Scale', min: 0 }) }),
    cylRadius: new PPropertyExpression({ id: 'cylRadius', vtype: new PInt({ id: 'cylRadius', label: 'Radius', min: 0 }) }),
    cylOriginX: new PPropertyExpression({ id: 'cylOriginX', vtype: new PInt({ id: 'cylOriginX', label: 'Origin X', min: 0 }) }),
    cylOriginY: new PPropertyExpression({ id: 'cylOriginY', vtype: new PInt({ id: 'cylOriginY', label: 'Origin Y', min: 0 }) }),

    lowerLimit: new PPropertyExpression({ id: 'lowerLimit', vtype: new PInt({ id: 'lowerLimit', label: 'Lower', min: 0 }) }),
    lowerLimitDisplay: new PPropertyExpression({ id: 'lowerLimitDisplay', vtype: new PInt({ id: 'lowerLimitDisplay', label: 'Lower', min: 0 }) }),
    upperLimit: new PPropertyExpression({ id: 'upperLimit', vtype: new PInt({ id: 'upperLimit', label: 'Upper', min: 0 }) }),
    upperLimitDisplay: new PPropertyExpression({ id: 'upperLimitDisplay', vtype: new PInt({ id: 'upperLimitDisplay', label: 'Upper', min: 0 }) }),

    isHollow: new PPropertyExpression({ id: 'isHollow', vtype: new PBoolean({ id: 'isHollow', label: 'Is Hollow' }) }),
    is2D: new PPropertyExpression({ id: 'is2D', vtype: new PBoolean({ id: 'is2D', label: 'Is 2D' }) }),
    isSliced: new PPropertyExpression({ id: 'isSliced', vtype: new PBoolean({ id: 'isSliced', label: 'Is Sliced' }) }),
    percentOfPrevious: new PPropertyExpression({ id: 'percentOfPrevious', vtype: new PBoolean({ id: 'percentOfPrevious', label: 'Percent Of Previous' }) }),
    showLabelsAtCenter: new PPropertyExpression({ id: 'showLabelsAtCenter', vtype: new PBoolean({ id: 'showLabelsAtCenter', label: 'Show Labels At Center' }) }),
    showPlotBorder: new PPropertyExpression({ id: 'showPlotBorder', vtype: new PBoolean({ id: 'showPlotBorder', label: 'Show Plot Border' }) }), 
    plotFillPercent: new PPropertyExpression({ id: 'plotFillPercent', vtype: new PInt({ id: 'plotFillPercent', label: 'Fill Percent', min: 0 }) }),
    plotAsDot: new PPropertyExpression({ id: 'plotAsDot', vtype: new PBoolean({ id: 'plotAsDot', label: 'Plot As Dot' }) }),

    showPivotBorder: new PPropertyExpression({ id: 'showPivotBorder', vtype: new PBoolean({ id: 'showPivotBorder', label: 'Show Border' }) }),
    pivotBorderColor: new PPropertyExpression({ id: 'pivotBorderColor', vtype: new PColor({ id: 'pivotBorderColor', label: 'Color' }) }),
    pivotBorderAlpha: new PPropertyExpression({ id: 'pivotBorderAlpha', vtype: new PInt({ id: 'pivotBorderAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    pivotBorderThickness: new PPropertyExpression({ id: 'pivotBorderThickness', vtype: new PInt({ id: 'pivotBorderThickness', label: 'Thickness', min: 0 }) }),
    pivotFillColor: new PPropertyExpression({ id: 'pivotFillColor', vtype: new PColor({ id: 'pivotFillColor', label: 'Color' }) }),
    pivotFillAlpha: new PPropertyExpression({ id: 'pivotFillAlpha', vtype: new PInt({ id: 'pivotFillAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    pivotFillAngle: new PPropertyExpression({ id: 'pivotFillAngle', vtype: new PInt({ id: 'pivotFillAngle', label: 'Thickness', min: 0 }) }),
    pivotRadius: new PPropertyExpression({ id: 'pivotRadius', vtype: new PInt({ id: 'pivotRadius', label: 'Radius', min: 0 }) }),

    ticksBelowGauge: new PPropertyExpression({ id: 'ticksBelowGauge', vtype: new PBoolean({ id: 'ticksBelowGauge', label: 'Ticks Below Gauge' }) }),

    pointerBorderColor: new PPropertyExpression({ id: 'pointerBorderColor', vtype: new PColor({ id: 'pointerBorderColor', label: 'Color' }) }),
    pointerBorderAlpha: new PPropertyExpression({ id: 'pointerBorderAlpha', vtype: new PInt({ id: 'pointerBorderAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    pointerBorderThickness: new PPropertyExpression({ id: 'pointerBorderThickness', vtype: new PInt({ id: 'pointerBorderThickness', label: 'Thickness', min: 0 }) }),
    pointerRadius: new PPropertyExpression({ id: 'pointerRadius', vtype: new PInt({ id: 'pointerRadius', label: 'Radius', min: 0 }) }),
    pointerSides: new PPropertyExpression({ id: 'pointerSides', vtype: new PInt({ id: 'pointerSides', label: 'Sides', min: 0 }) }),
    pointerBgColor: new PPropertyExpression({ id: 'pointerBgColor', vtype: new PColor({ id: 'pointerBgColor', label: 'Color' }) }),
    pointerBgAlpha: new PPropertyExpression({ id: 'pointerBgAlpha', vtype: new PInt({ id: 'pointerBgAlpha', label: 'Alpha', min: 0, max: 255 }) }),

    ledGap: new PPropertyExpression({ id: 'ledGap', vtype: new PInt({ id: 'ledGap', label: 'LED Gap', min: 0 }) }),
    ledSize: new PPropertyExpression({ id: 'ledSize', vtype: new PInt({ id: 'ledSize', label: 'LED Size', min: 0 }) }),

    targetColor: new PPropertyExpression({ id: 'targetColor', vtype: new PColor({ id: 'targetColor', label: 'Color' }) }),
    targetThickness: new PPropertyExpression({ id: 'targetThickness', vtype: new PInt({ id: 'targetThickness', label: 'Thickness', min: 0 }) }),
    targetFillPercent: new PPropertyExpression({ id: 'targetFillPercent', vtype: new PInt({ id: 'targetFillPercent', label: 'Fill Percent', min: 0 }) }),

    yAxisMinValue: new PPropertyExpression({ id: 'yAxisMinValue', vtype: new PInt({ id: 'yAxisMinValue', label: 'Y Axis Min Value' }) }),
    yAxisMaxValue: new PPropertyExpression({ id: 'yAxisMaxValue', vtype: new PInt({ id: 'yAxisMaxValue', label: 'Y Axis Max Value' }) }),

    periodColor: new PPropertyExpression({ id: 'periodColor', vtype: new PColor({ id: 'periodColor', label: 'Color' }) }),
    periodAlpha: new PPropertyExpression({ id: 'periodAlpha', vtype: new PInt({ id: 'periodAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    periodLength: new PPropertyExpression({ id: 'periodLength', vtype: new PInt({ id: 'periodLength', label: 'Length', min: 0 }) }),

    highColor: new PPropertyExpression({ id: 'highColor', vtype: new PColor({ id: 'highColor', label: 'High Color' }) }),
    lowColor: new PPropertyExpression({ id: 'lowColor', vtype: new PColor({ id: 'lowColor', label: 'Low Color' }) }),
    openColor: new PPropertyExpression({ id: 'openColor', vtype: new PColor({ id: 'openColor', label: 'Open Color' }) }),
    closeColor: new PPropertyExpression({ id: 'closeColor', vtype: new PColor({ id: 'closeColor', label: 'Close Color' }) }),

    winColor: new PPropertyExpression({ id: 'winColor', vtype: new PColor({ id: 'winColor', label: 'Win Color' }) }),
    lossColor: new PPropertyExpression({ id: 'lossColor', vtype: new PColor({ id: 'lossColor', label: 'Loss Color' }) }),
    drawColor: new PPropertyExpression({ id: 'drawColor', vtype: new PColor({ id: 'drawColor', label: 'Draw Color' }) }),
    scorelessColor: new PPropertyExpression({ id: 'scorelessColor', vtype: new PColor({ id: 'scorelessColor', label: 'Scoreless Color' }) })
};

export const FFusionWidgetProps = {

    // padding
    captionPadding: new PPropertyExpression({ id: 'captionPadding', vtype: new PInt({ id: 'captionPadding', label: 'Caption Padding', min: 0 }) }),
    labelPadding: new PPropertyExpression({ id: 'labelPadding', vtype: new PInt({ id: 'labelPadding', label: 'Label', min: 0 }) }),
    valuePadding: new PPropertyExpression({ id: 'valuePadding', vtype: new PInt({ id: 'valuePadding', label: 'Value', min: 0 }) }),
    plotSpacePercent: new PPropertyExpression({ id: 'plotSpacePercent', vtype: new PInt({ id: 'plotSpacePercent', label: 'Plot Space Percent', min: 0 }) }),

    // chart margin
    chartLeftMargin: new PPropertyExpression({ id: 'chartLeftMargin', vtype: new PInt({ id: 'chartLeftMargin', label: 'Left', min: 0 }) }),
    chartRightMargin: new PPropertyExpression({ id: 'chartRightMargin', vtype: new PInt({ id: 'chartRightMargin', label: 'Right', min: 0 }) }),
    chartTopMargin: new PPropertyExpression({ id: 'chartTopMargin', vtype: new PInt({ id: 'chartTopMargin', label: 'Top', min: 0 }) }),
    chartBottomMargin: new PPropertyExpression({ id: 'chartBottomMargin', vtype: new PInt({ id: 'chartBottomMargin', label: 'Bottom', min: 0 }) }),
    // canvas margin
    canvasLeftMargin: new PPropertyExpression({ id: 'canvasLeftMargin', vtype: new PInt({ id: 'canvasLeftMargin', label: 'Left', min: 0 }) }),
    canvasRightMargin: new PPropertyExpression({ id: 'canvasRightMargin', vtype: new PInt({ id: 'canvasRightMargin', label: 'Right', min: 0 }) }),
    canvasTopMargin: new PPropertyExpression({ id: 'canvasTopMargin', vtype: new PInt({ id: 'canvasTopMargin', label: 'Top', min: 0 }) }),
    canvasBottomMargin: new PPropertyExpression({ id: 'canvasBottomMargin', vtype: new PInt({ id: 'canvasBottomMargin', label: 'Bottom', min: 0 }) }),
    // tooltips
    showToolTip: new PPropertyExpression({ id: 'showToolTip', vtype: new PBoolean({ id: 'showToolTip', label: 'Show Tool Tip', canBeNull: true }) }),
    toolTipBgColor: new PPropertyExpression({ id: 'toolTipBgColor', vtype: new PColor({ id: 'toolTipBgColor', label: 'Background Color' }) }),
    toolTipBorderColor: new PPropertyExpression({ id: 'toolTipBorderColor', vtype: new PColor({ id: 'toolTipBorderColor', label: 'Border Color' }) }),
    showToolTipShadow: new PPropertyExpression({ id: 'showToolTipShadow', vtype: new PBoolean({ id: 'showToolTipShadow', label: 'Show Shadow', canBeNull: true }) }),
    seriesNameInTooltip: new PPropertyExpression({ id: 'useSNameInToolTip', vtype: new PBoolean({ id: 'seriesNameInTooltip', label: 'Show Series Name', canBeNull: true }) }),
    // format numbers
    formatNumber: new PPropertyExpression({ id: 'formatNumber', vtype: new PBoolean({ id: 'formatNumber', label: 'Format Number', canBeNull: true }) }),
    formatNumberScale: new PPropertyExpression({ id: 'formatNumberScale', vtype: new PBoolean({ id: 'formatNumberScale', label: 'Format Number Scale', canBeNull: true }) }),
    forceDecimals: new PPropertyExpression({ id: 'forceDecimals', vtype: new PBoolean({ id: 'forceDecimals', label: 'Force Decimals', canBeNull: true }) }),
    decimals: new PPropertyExpression({ id: 'decimals', vtype: new PString({ id: 'decimals', label: 'Decimals' }) }),
    // base font
    baseFont: new PPropertyExpression({ id: 'baseFont', vtype: new PString({ id: 'baseFont', label: 'Font' }) }),
    baseFontSize: new PPropertyExpression({ id: 'baseFontSize', vtype: new PInt({ id: 'baseFontSize', label: 'Size', min: 0 }) }),
    baseFontColor: new PPropertyExpression({ id: 'baseFontColor', vtype: new PColor({ id: 'baseFontColor', label: 'Color' }) }),

    //canvas font
    outCnvBaseFont: new PPropertyExpression({ id: 'outCnvBaseFont', vtype: new PString({ id: 'outCnvBaseFont', label: 'Font' }) }),
    outCnvBaseFontSize: new PPropertyExpression({ id: 'outCnvBaseFontSize', vtype: new PInt({ id: 'outCnvBaseFontSize', label: 'Size', min: 0 }) }),
    outCnvBaseFontColor: new PPropertyExpression({ id: 'outCnvBaseFontColor', vtype: new PColor({ id: 'outCnvBaseFontColor', label: 'Color' }) }),
    // legend
    showLegend: new PPropertyExpression({ id: 'showLegend', vtype: new PBoolean({ id: 'showLegend', label: 'Show Legend', canBeNull: true }) }),
    legendShadow: new PPropertyExpression({ id: 'legendShadow', vtype: new PBoolean({ id: 'legendShadow', label: 'Show Shadow', canBeNull: true }) }),
    legendAllowDrag: new PPropertyExpression({ id: 'legendAllowDrag', vtype: new PBoolean({ id: 'legendAllowDrag', label: 'Allow Drag', canBeNull: true }) }),
    useCircleMarker: new PPropertyExpression({ id: 'useCircleMarker', vtype: new PBoolean({ id: 'useCircleMarker', label: 'Use Cricle Marker', canBeNull: true }) }),
    // legend scroll bar
    legendScrollBgColor: new PPropertyExpression({ id: 'legendScrollBgColor', vtype: new PColor({ id: 'legendScrollBgColor', label: 'Background Color' }) }),
    legendScrollBarColor: new PPropertyExpression({ id: 'legendScrollBarColor', vtype: new PColor({ id: 'legendScrollBarColor', label: 'Bar Color' }) }),
    legendScrollBtnColor: new PPropertyExpression({ id: 'legendScrollBtnColor', vtype: new PString({ id: 'legendScrollBtnColor', label: 'Button Color' }) }),
    // legend border
    legendBorderColor: new PPropertyExpression({ id: 'legendBorderColor', vtype: new PColor({ id: 'legendBorderColor', label: 'Color' }) }),
    legendBorderThickness: new PPropertyExpression({ id: 'legendBorderThickness', vtype: new PString({ id: 'legendBorderThickness', label: 'Thickness' }) }),
    legendBorderAlpha: new PPropertyExpression({ id: 'legendBorderAlpha', vtype: new PInt({ id: 'legendBorderAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    legendCaption: new PPropertyExpression({ id: 'legendCaption', vtype: new PString({ id: 'legendCaption', label: 'Caption' }) }),
    legendPosition: new PPropertyExpression({
        id: 'legendPosition', vtype: new PEnum({
            id: 'legendPosition', label: 'Position', writeable: true,
            items: [{ key: "", value: "" },
            { key: "bottom", value: "Bottom" },
            { key: "right", value: "Right" }]
        })
    }),
    legendBgColor: new PPropertyExpression({ id: 'legendBgColor', vtype: new PColor({ id: 'legendBgColor', label: 'Background Color' }) }),
    legendBgAlpha: new PPropertyExpression({ id: 'legendBgAlpha', vtype: new PInt({ id: 'legendBgAlpha', label: 'Background Alpha', min: 0, max: 255 }) }),

    // background
    bgColor: new PPropertyExpression({ id: 'bgColor', vtype: new PColor({ id: 'bgColor', label: 'Color' }) }),
    bgAlpha: new PPropertyExpression({ id: 'bgAlpha', vtype: new PInt({ id: 'bgAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    bgRatio: new PPropertyExpression({ id: 'bgRatio', vtype: new PInt({ id: 'bgRatio', label: 'Ratio', min: 0 }) }),
    bgAngle: new PPropertyExpression({ id: 'bgAngle', vtype: new PInt({ id: 'bgAngle', label: 'Angle', min: 0 }) }),
    // border
    showBorder: new PPropertyExpression({ id: 'showBorder', vtype: new PBoolean({ id: 'showBorder', label: 'Show Border' }) }),
    borderColor: new PPropertyExpression({ id: 'borderColor', vtype: new PColor({ id: 'borderColor', label: 'Color' }) }),
    borderThickness: new PPropertyExpression({ id: 'borderThickness', vtype: new PInt({ id: 'borderThickness', label: 'Thickness', min: 0 }) }),
    borderAlpha: new PPropertyExpression({ id: 'borderAlpha', vtype: new PInt({ id: 'borderAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    // canvas border
    canvasBorderColor: new PPropertyExpression({ id: 'canvasBorderColor', vtype: new PColor({ id: 'canvasBorderColor', label: 'Color' }) }),
    canvasBorderThickness: new PPropertyExpression({ id: 'canvasBorderThickness', vtype: new PInt({ id: 'canvasBorderThickness', label: 'Thickness', min: 0 }) }),
    canvasBorderAlpha: new PPropertyExpression({ id: 'canvasBorderAlpha', vtype: new PInt({ id: 'canvasBorderAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    // canvas 
    canvasBgColor: new PPropertyExpression({ id: 'canvasBgColor', vtype: new PColor({ id: 'canvasBgColor', label: 'Color' }) }),
    canvasBgAlpha: new PPropertyExpression({ id: 'canvasBgAlpha', vtype: new PInt({ id: 'canvasBgAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    canvasBgRatio: new PPropertyExpression({ id: 'canvasBgRatio', vtype: new PInt({ id: 'canvasBgRatio', label: 'Ratio', min: 0 }) }),
    canvasBgAngle: new PPropertyExpression({ id: 'canvasBgAngle', vtype: new PInt({ id: 'canvasBgAngle', label: 'Angle', min: 0 }) }),
    // captions
    caption: new PPropertyExpression({ id: 'caption', vtype: new PString({ id: 'caption', label: 'Caption' }) }),
    subCaption: new PPropertyExpression({ id: 'subCaption', vtype: new PString({ id: 'subCaption', label: 'Sub Caption' }) }),

    xAxisName: new PPropertyExpression({ id: 'xAxisName', vtype: new PString({ id: 'xAxisName', label: 'Name' }) }),
    xAxisNamePadding: new PPropertyExpression({ id: 'xAxisNamePadding', vtype: new PInt({ id: 'xAxisNamePadding', label: 'Name Padding', min: 0 }) }),
    rotateXAxisName: new PPropertyExpression({ id: 'rotateXAxisName', vtype: new PBoolean({ id: 'rotateXAxisName', label: 'Rotate Name', canBeNull: true }) }),

    yAxisValuesPadding: new PPropertyExpression({ id: 'yAxisValuesPadding', vtype: new PInt({ id: 'yAxisValuesPadding', label: 'Values', min: 0 }) }),
    yAxisNamePadding: new PPropertyExpression({ id: 'yAxisNamePadding', vtype: new PInt({ id: 'yAxisNamePadding', label: 'Name', min: 0 }) }),
    setAdaptiveYMin: new PPropertyExpression({ id: 'setAdaptiveYMin', vtype: new PBoolean({ id: 'setAdaptiveYMin', label: 'Set Adaptive Min', canBeNull: true }) }),
    yAxisMinValue: new PPropertyExpression({ id: 'yAxisMinValue', vtype: new PInt({ id: 'yAxisMinValue', label: 'Min Value' }) }),
    yAxisMaxValue: new PPropertyExpression({ id: 'yAxisMaxValue', vtype: new PInt({ id: 'yAxisMaxValue', label: 'Max Value' }) }),
    showYAxisValues: new PPropertyExpression({ id: 'showYAxisValues', vtype: new PBoolean({ id: 'showYAxisValues', label: 'Show Values', canBeNull: true }) }),
    yAxisName: new PPropertyExpression({ id: 'yAxisName', vtype: new PString({ id: 'yAxisName', label: 'Name' }) }),
    yAxisNameWidth: new PPropertyExpression({ id: 'yAxisNameWidth', vtype: new PBoolean({ id: 'yAxisNameWidth', label: 'Name Width', canBeNull: true }) }),

    // plot
    useRoundEdges: new PPropertyExpression({ id: 'useRoundEdges', vtype: new PBoolean({ id: 'useRoundEdges', label: 'Use Round Edges', canBeNull: true }) }),
    plotBorderDashed: new PPropertyExpression({ id: 'plotBorderDashed', vtype: new PBoolean({ id: 'plotBorderDashed', label: 'Dashed', canBeNull: true }) }),
    plotShowBorder: new PPropertyExpression({ id: 'plotShowBorder', vtype: new PBoolean({ id: 'plotShowBorder', label: 'Show Border' }) }),
    plotBorderColor: new PPropertyExpression({ id: 'plotBorderColor', vtype: new PColor({ id: 'plotBorderColor', label: 'Color' }) }),
    plotBorderThickness: new PPropertyExpression({ id: 'plotBorderThickness', vtype: new PInt({ id: 'plotBorderThickness', label: 'Thickness', min: 0 }) }),
    plotBorderAlpha: new PPropertyExpression({ id: 'plotBorderAlpha', vtype: new PInt({ id: 'plotBorderAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    plotBorderDashLen: new PPropertyExpression({ id: 'plotBorderDashLen', vtype: new PInt({ id: 'plotBorderDashLen', label: 'Dash Lenght', min: 0, max: 255 }) }),
    plotBorderDashGap: new PPropertyExpression({ id: 'plotBorderDashGap', vtype: new PInt({ id: 'plotBorderDashGap', label: 'Dash Gap', min: 0, max: 255 }) }),
    // gradient
    plotGradientColor: new PPropertyExpression({ id: 'plotGradientColor', vtype: new PColor({ id: 'plotGradientColor', label: 'Gradient Color' }) }),
    plotFillColor: new PPropertyExpression({ id: 'plotFillColor', vtype: new PColor({ id: 'plotFillColor', label: 'Fill Color' }) }),
    plotFillAlpha: new PPropertyExpression({ id: 'plotFillAlpha', vtype: new PInt({ id: 'plotFillAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    plotFillRatio: new PPropertyExpression({ id: 'plotFillRatio', vtype: new PInt({ id: 'plotFillRatio', label: 'Fill Ratio', min: 0 }) }),
    plotFillAngle: new PPropertyExpression({ id: 'plotFillAngle', vtype: new PInt({ id: 'plotFillAngle', label: 'Fill Angle', min: 0 }) }),

    // etc
    animation: new PPropertyExpression({ id: 'animation', vtype: new PBoolean({ id: 'animation', label: 'Animation', canBeNull: true }) }),
    showShadow: new PPropertyExpression({ id: 'showShadow', vtype: new PBoolean({ id: 'showShadow', label: 'Show Shadow', canBeNull: true }) }),
    showLimits: new PPropertyExpression({ id: 'showLimits', vtype: new PBoolean({ id: 'showLimits', label: 'Show Limits', canBeNull: true }) }),
    showValues: new PPropertyExpression({ id: 'showValues', vtype: new PBoolean({ id: 'showValues', label: 'Show Values', canBeNull: true }) }),
    placeValuesInside: new PPropertyExpression({ id: 'placeValuesInside', vtype: new PBoolean({ id: 'placeValuesInside', label: 'Place Values Inside', canBeNull: true }) }),
    connectNullData: new PPropertyExpression({ id: 'connectNullData', vtype: new PBoolean({ id: 'connectNullData', label: 'Connect Null Data', canBeNull: true }) }),
    use3DLighting: new PPropertyExpression({ id: 'use3DLighting', vtype: new PBoolean({ id: 'use3DLighting', label: 'Use 3D Lighting', canBeNull: true }) }),

    adjustDiv: new PPropertyExpression({ id: 'adjustDiv', vtype: new PBoolean({ id: 'adjustDiv', label: 'Adjust Div', canBeNull: true }) }),
    showDivLineValues: new PPropertyExpression({ id: 'showDivLineValues', vtype: new PBoolean({ id: 'showDivLineValues', label: 'Show Div Line Values', canBeNull: true }) }),
    numDivLine: new PPropertyExpression({ id: 'numDivLine', vtype: new PInt({ id: 'numDivLine', label: 'Number Of Divisions', min: 0 }) }),
    divLineIsDashed: new PPropertyExpression({ id: 'divLineIsDashed', vtype: new PBoolean({ id: 'divLineIsDashed', label: 'Dashed', canBeNull: true }) }),
    divLineDashLen: new PPropertyExpression({ id: 'divLineDashLen', vtype: new PInt({ id: 'divLineDashLen', label: 'Dash Lenght', min: 0, max: 255 }) }),
    divLineDashGap: new PPropertyExpression({ id: 'divLineDashGap', vtype: new PInt({ id: 'divLineDashGap', label: 'Dash Gap', min: 0, max: 255 }) }),

    divLineThickness: new PPropertyExpression({ id: 'divLineThickness', vtype: new PInt({ id: 'divLineThickness', label: 'Thickness', min: 0 }) }),
    divLineColor: new PPropertyExpression({ id: 'divLineColor', vtype: new PColor({ id: 'divLineColor', label: 'Color' }) }),
    divLineAlpha: new PPropertyExpression({ id: 'divLineAlpha', vtype: new PInt({ id: 'divLineAlpha', label: 'Alpha', min: 0, max: 255 }) }),

    showAlternateVGridColor: new PPropertyExpression({ id: 'showAlternateVGridColor', vtype: new PBoolean({ id: 'showAlternateVGridColor', label: 'Show Alternate Color', canBeNull: true }) }),
    alternateVGridColor: new PPropertyExpression({ id: 'alternateVGridColor', vtype: new PColor({ id: 'alternateVGridColor', label: 'Color' }) }),
    alternateVGridAlpha: new PPropertyExpression({ id: 'alternateVGridAlpha', vtype: new PInt({ id: 'alternateVGridAlpha', label: 'Alpha', min: 0, max: 255 }) }),

    showAlternateHGridColor: new PPropertyExpression({ id: 'showAlternateHGridColor', vtype: new PBoolean({ id: 'showAlternateHGridColor', label: 'Show Alternate Color', canBeNull: true }) }),
    alternateHGridColor: new PPropertyExpression({ id: 'alternateHGridColor', vtype: new PColor({ id: 'alternateHGridColor', label: 'Color' }) }),
    alternateHGridAlpha: new PPropertyExpression({ id: 'alternateHGridAlpha', vtype: new PInt({ id: 'alternateHGridAlpha', label: 'Alpha', min: 0, max: 255 }) }),

    showZeroPlane: new PPropertyExpression({ id: 'showZeroPlane', vtype: new PBoolean({ id: 'showZeroPlane', label: 'Show Zero Plane', canBeNull: true }) }),
    zeroPlaneThickness: new PPropertyExpression({ id: 'zeroPlaneThickness', vtype: new PInt({ id: 'zeroPlaneThickness', label: 'Thickness', min: 0 }) }),
    zeroPlaneColor: new PPropertyExpression({ id: 'zeroPlaneColor', vtype: new PColor({ id: 'zeroPlaneColor', label: 'Color' }) }),
    zeroPlaneAlpha: new PPropertyExpression({ id: 'zeroPlaneAlpha', vtype: new PInt({ id: 'zeroPlaneAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    showZeroPlaneBorder: new PPropertyExpression({ id: 'showZeroPlaneBorder', vtype: new PBoolean({ id: 'showZeroPlaneBorder', label: 'Show Border', canBeNull: true }) }),
    zeroPlaneBorderColor: new PPropertyExpression({ id: 'zeroPlaneBorderColor', vtype: new PColor({ id: 'zeroPlaneBorderColor', label: 'Border Color' }) }),

    showLabels: new PPropertyExpression({ id: 'showLabels', vtype: new PBoolean({ id: 'showLabels', label: 'Show Labels', canBeNull: true }) }),
    showlabels: new PPropertyExpression({ id: 'showlabels', vtype: new PBoolean({ id: 'showlabels', label: 'Show Labels', canBeNull: true }) }),
    
    rotateLabels: new PPropertyExpression({ id: 'rotateLabels', vtype: new PBoolean({ id: 'rotateLabels', label: 'Rotate', canBeNull: true }) }),
    slantLabels: new PPropertyExpression({ id: 'slantLabels', vtype: new PBoolean({ id: 'slantLabels', label: 'Slant Labels', canBeNull: true }) }),
    labelStep: new PPropertyExpression({ id: 'labelStep', vtype: new PInt({ id: 'labelStep', label: 'Step', min: 0 }) }),
    staggerLines: new PPropertyExpression({ id: 'staggerLines', vtype: new PInt({ id: 'staggerLines', label: 'Stagger Lines', min: 0 }) }),
    labelDisplay: new PPropertyExpression({
        id: 'labelDisplay', vtype: new PEnum({
            id: 'labelDisplay', label: 'Label Display', default: 'AUTO',
            items: [{ key: "AUTO", value: "Auto" },
            { key: "NONE", value: "None" },
            { key: "WRAP", value: "Wrap" },
            { key: "STAGGER", value: "Stagger" },
            { key: "ROTATE", value: "Rotate" }]
        })
    }),

    lineColor: new PPropertyExpression({ id: 'plotBorderColor', vtype: new PColor({ id: 'plotBorderColor', label: 'Color' }) }),
    lineThickness: new PPropertyExpression({ id: 'plotBorderThickness', vtype: new PInt({ id: 'plotBorderThickness', label: 'Thickness', min: 0 }) }),
    lineAlpha: new PPropertyExpression({ id: 'plotBorderAlpha', vtype: new PInt({ id: 'plotBorderAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    lineDashed: new PPropertyExpression({ id: 'plotBorderDashed', vtype: new PBoolean({ id: 'plotBorderDashed', label: 'Dashed', canBeNull: true }) }),
    lineDashLen: new PPropertyExpression({ id: 'plotBorderDashLen', vtype: new PInt({ id: 'plotBorderDashLen', label: 'Dash Lenght', min: 0, max: 255 }) }),
    lineDashGap: new PPropertyExpression({ id: 'plotBorderDashGap', vtype: new PInt({ id: 'plotBorderDashGap', label: 'Dash Gap', min: 0, max: 255 }) }),

    drawAnchors: new PPropertyExpression({ id: 'drawAnchors', vtype: new PBoolean({ id: 'drawAnchors', label: 'Draw Anchors', canBeNull: true }) }),
    anchorBorderColor: new PPropertyExpression({ id: 'anchorBorderColor', vtype: new PColor({ id: 'anchorBorderColor', label: 'Color' }) }),
    anchorBorderThickness: new PPropertyExpression({ id: 'anchorBorderThickness', vtype: new PInt({ id: 'anchorBorderThickness', label: 'Thickness', min: 0 }) }),
    anchorAlpha: new PPropertyExpression({ id: 'anchorAlpha', vtype: new PInt({ id: 'anchorAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    anchorBgColor: new PPropertyExpression({ id: 'anchorBgColor', vtype: new PColor({ id: 'anchorBgColor', label: 'Color' }) }),
    anchorBgAlpha: new PPropertyExpression({ id: 'anchorBgAlpha', vtype: new PInt({ id: 'anchorBgAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    anchorRadius: new PPropertyExpression({ id: 'anchorRadius', vtype: new PInt({ id: 'anchorRadius', label: 'Radius', min: 0 }) }),
    anchorSides: new PPropertyExpression({ id: 'anchorSides', vtype: new PInt({ id: 'anchorSides', label: 'Sides', min: 0 }) }),

    palette: new PPropertyExpression({ id: 'palette', vtype: new PInt({ id: 'palette', label: 'Palette', min: 1, max: 4 }) }),
    paletteColors: new PPropertyExpression({ id: 'paletteColors', vtype: new PString({ id: 'paletteColors', label: 'Colors' }) })
};

export const MFusionWidgetCommon: APDescriptor = {
    id: 'Bar', type: 'fusion.chart.bar', label: 'Bar', icon: BarChartIcon,
    layouts: [
        new PAccordeon({
            id: 'fusion.chart.caption', label: 'Captions', layouts: [FFusionWidgetProps.caption,
            FFusionWidgetProps.subCaption, FFusionWidgetProps.captionPadding]
        }),
        new PAccordeon({
            id: 'fusion.chart.axis', label: 'Axis', layouts: [
                new PAccordeon({ id: 'fusion.chart.xAxis', label: 'X Axis', layouts: [FFusionWidgetProps.xAxisName, FFusionWidgetProps.xAxisNamePadding, FFusionWidgetProps.rotateXAxisName] }),
                new PAccordeon({
                    id: 'fusion.chart.yAxis', label: 'Y Axis', layouts: [
                        FFusionWidgetProps.yAxisName, FFusionWidgetProps.yAxisNameWidth,
                        FFusionWidgetProps.setAdaptiveYMin, FFusionWidgetProps.yAxisMinValue, FFusionWidgetProps.yAxisMaxValue,
                        FFusionWidgetProps.showYAxisValues,
                        new PAccordeon({ id: 'fusion.chart.yAxis.padding', label: 'Paddings', layouts: [FFusionWidgetProps.yAxisNamePadding, FFusionWidgetProps.yAxisValuesPadding] })
                    ]
                })
            ]
        }),
        new PAccordeon({ id: 'fusion.chart.labels', label: 'Labels', layouts: [FFusionWidgetProps.showLabels, FFusionWidgetProps.labelDisplay, FFusionWidgetProps.rotateLabels, FFusionWidgetProps.slantLabels, FFusionWidgetProps.labelStep, FFusionWidgetProps.staggerLines, FFusionWidgetProps.labelPadding] }),
        new PAccordeon({ id: 'fusion.chart.values', label: 'Values', layouts: [FFusionWidgetProps.showValues, FFusionWidgetProps.placeValuesInside, FFusionWidgetProps.valuePadding] }),
        new PAccordeon({
            id: 'fusion.chart.plot', label: 'Plot', layouts: [
                FFusionWidgetProps.useRoundEdges,
                FFusionWidgetProps.plotSpacePercent,
                new PAccordeon({
                    id: 'fusion.chart.yAxis.border', label: 'Border', layouts: [
                        FFusionWidgetProps.useRoundEdges,
                        FFusionWidgetProps.plotShowBorder, FFusionWidgetProps.plotBorderColor, FFusionWidgetProps.plotBorderThickness, FFusionWidgetProps.plotBorderAlpha,
                        FFusionWidgetProps.plotBorderDashed, FFusionWidgetProps.plotBorderDashLen, FFusionWidgetProps.plotBorderDashGap
                    ]
                }),
                new PAccordeon({
                    id: 'fusion.chart.yAxis.background', label: 'Background', layouts: [FFusionWidgetProps.plotGradientColor, FFusionWidgetProps.plotFillColor, FFusionWidgetProps.plotFillAlpha, FFusionWidgetProps.plotFillRatio, FFusionWidgetProps.plotFillAngle]
                }),
                new PAccordeon({ id: 'fusion.chart.yAxis.line', label: 'Line', layouts: [FFusionWidgetProps.lineThickness, FFusionWidgetProps.lineColor, FFusionWidgetProps.lineAlpha, FFusionWidgetProps.lineDashed, FFusionWidgetProps.lineDashLen, FFusionWidgetProps.lineDashGap] }),
                new PAccordeon({
                    id: 'fusion.chart.yAxis.anchors', label: 'Anchors', layouts: [FFusionWidgetProps.drawAnchors, FFusionWidgetProps.anchorAlpha,
                    new PAccordeon({ id: 'fusion.chart.yAxis.anchors.border', label: 'Border', layouts: [FFusionWidgetProps.anchorBorderThickness, FFusionWidgetProps.anchorBorderColor] }),
                    new PAccordeon({ id: 'fusion.chart.yAxis.anchors.bg', label: 'Background', layouts: [FFusionWidgetProps.anchorBgColor, FFusionWidgetProps.anchorBgAlpha] }),
                    FFusionWidgetProps.anchorRadius, FFusionWidgetProps.anchorSides]
                })
            ]
        }),
        new PAccordeon({ id: 'fusion.chart.divisions', label: 'Divisions', layouts: [FFusionWidgetProps.adjustDiv, FFusionWidgetProps.showDivLineValues, FFusionWidgetProps.numDivLine, FFusionWidgetProps.divLineIsDashed, FFusionWidgetProps.divLineDashLen, FFusionWidgetProps.divLineDashGap, FFusionWidgetProps.divLineThickness, FFusionWidgetProps.divLineColor, FFusionWidgetProps.divLineAlpha] }),
        new PAccordeon({
            id: 'fusion.chart.grids', label: 'Grids', layouts: [
                new PAccordeon({ id: 'fusion.chart.vgrid', label: 'Vertical', layouts: [FFusionWidgetProps.showAlternateVGridColor, FFusionWidgetProps.alternateVGridColor, FFusionWidgetProps.alternateVGridAlpha] }),
                new PAccordeon({ id: 'fusion.chart.hgrid', label: 'Horizontal', layouts: [FFusionWidgetProps.showAlternateHGridColor, FFusionWidgetProps.alternateHGridColor, FFusionWidgetProps.alternateHGridAlpha] })
            ]
        }),
        new PAccordeon({ id: 'fusion.chart.zeroPlane', label: 'Zero Plane', layouts: [FFusionWidgetProps.showZeroPlane, FFusionWidgetProps.zeroPlaneThickness, FFusionWidgetProps.zeroPlaneColor, FFusionWidgetProps.zeroPlaneAlpha, FFusionWidgetProps.showZeroPlaneBorder, FFusionWidgetProps.zeroPlaneBorderColor] }),
        new PAccordeon({ id: 'fusion.chart.border', label: 'Border', layouts: [FFusionWidgetProps.showBorder, FFusionWidgetProps.borderColor, FFusionWidgetProps.borderThickness, FFusionWidgetProps.borderAlpha] }),
        new PAccordeon({ id: 'fusion.chart.background', label: 'Background', layouts: [FFusionWidgetProps.bgColor, FFusionWidgetProps.bgAlpha, FFusionWidgetProps.bgRatio, FFusionWidgetProps.bgAngle] }),
        new PAccordeon({ id: 'fusion.chart.fonts', label: 'Base Fonts', layouts: [FFusionWidgetProps.baseFont, FFusionWidgetProps.baseFontSize, FFusionWidgetProps.baseFontColor] }),
        new PAccordeon({ id: 'fusion.chart.margins', label: 'Margins', layouts: [FFusionWidgetProps.chartLeftMargin, FFusionWidgetProps.chartRightMargin, FFusionWidgetProps.chartTopMargin, FFusionWidgetProps.chartBottomMargin] }),
        new PAccordeon({
            id: 'fusion.chart.legend', label: 'Legend', layouts: [
                FFusionWidgetProps.showLegend, FFusionWidgetProps.legendShadow, FFusionWidgetProps.legendAllowDrag,
                FFusionWidgetProps.useCircleMarker, FFusionWidgetProps.legendCaption, FFusionWidgetProps.legendPosition,
                new PAccordeon({ id: 'fusion.chart.canvas.background', label: 'Background', layouts: [FFusionWidgetProps.legendBgColor, FFusionWidgetProps.legendBgAlpha] }),
                new PAccordeon({ id: 'fusion.chart.canvas.border', label: 'Border', layouts: [FFusionWidgetProps.legendBorderColor, FFusionWidgetProps.legendBorderThickness, FFusionWidgetProps.legendBorderAlpha] }),
                new PAccordeon({ id: 'fusion.chart.canvas.scrollbar', label: 'Scroll Bar', layouts: [FFusionWidgetProps.legendScrollBarColor, FFusionWidgetProps.legendScrollBgColor, FFusionWidgetProps.legendScrollBtnColor] })]
        }),
        new PAccordeon({
            id: 'fusion.chart.canvas', label: 'Canvas', layouts: [
                new PAccordeon({ id: 'fusion.chart.canvas.border', label: 'Border', layouts: [FFusionWidgetProps.canvasBorderColor, FFusionWidgetProps.canvasBorderThickness, FFusionWidgetProps.canvasBorderAlpha] }),
                new PAccordeon({ id: 'fusion.chart.canvas.margins', label: 'Margins', layouts: [FFusionWidgetProps.canvasLeftMargin, FFusionWidgetProps.canvasRightMargin, FFusionWidgetProps.canvasTopMargin, FFusionWidgetProps.canvasBottomMargin] }),
                new PAccordeon({ id: 'fusion.chart.canvas.bg', label: 'Backrgound', layouts: [FFusionWidgetProps.canvasBgColor, FFusionWidgetProps.canvasBgAlpha, FFusionWidgetProps.canvasBgRatio, FFusionWidgetProps.canvasBgAngle] }),
                new PAccordeon({ id: 'fusion.chart.fonts', label: 'Outside Canvas Fonts', layouts: [FFusionWidgetProps.outCnvBaseFont, FFusionWidgetProps.outCnvBaseFontSize, FFusionWidgetProps.outCnvBaseFontColor] })]
        }),
        new PAccordeon({ id: 'fusion.chart.formatNumber', label: 'Number Formatting', layouts: [FFusionWidgetProps.formatNumber, FFusionWidgetProps.formatNumberScale, FFusionWidgetProps.forceDecimals, FFusionWidgetProps.decimals] }),
        new PAccordeon({ id: 'fusion.chart.tooltip', label: 'Tooltip', layouts: [FFusionWidgetProps.showToolTip, FFusionWidgetProps.showToolTipShadow, FFusionWidgetProps.seriesNameInTooltip, FFusionWidgetProps.toolTipBgColor, FFusionWidgetProps.toolTipBorderColor] }),
        FFusionWidgetProps.animation, FFusionWidgetProps.showShadow, FFusionWidgetProps.showLimits, FFusionWidgetProps.connectNullData, FFusionWidgetProps.use3DLighting,
        new PAccordeon({ id: 'fusion.chart.palette', label: 'Palette', layouts: [FFusionWidgetProps.palette, FFusionWidgetProps.paletteColors] })
    ]
};




