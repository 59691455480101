/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Map as ImmutableMap } from 'immutable';
import { loadExternalCSS } from "./ExternalCssUtils";
import { IState } from "../../reducers";
import { RepositoryApi } from "@jss/js-rest-api";


export const WebfontLoader: React.FunctionComponent = () => {
    const fontsWithPath: ImmutableMap<string, ImmutableMap<string, string>> = useSelector(
        (state: IState) => {
            const fonts = state.getIn(["report", "cachedData", "comboValues", "fonts"]) as ImmutableMap<string, ImmutableMap<string, string>>;
            if (fonts && fonts.size) {
                return fonts.filter(item =>  {
                    return item.get("path") != null;
                });
            }
            return null;
        }
    );

    useEffect(() => {
        if (navigator && navigator.serviceWorker) {
            navigator.serviceWorker.ready.then((registration: ServiceWorkerRegistration) => {
                if (registration.active) {
                    registration.active.postMessage({
                        type: "jrws_set_headers",
                        headers: {
                            "X-JRS-Repo": RepositoryApi.inst().getBase(),
                            "X-JRS-UserID": RepositoryApi.inst().getUserId()
                        }
                    });
                }
            });
        }
    }, []);

    useEffect(()=> {
        if (fontsWithPath && fontsWithPath.size) {
            Array.from(fontsWithPath.values())
                .forEach((item: ImmutableMap<string, string>) => {
                    loadExternalCSS(item.get("path"));
                });
        }
    }, [fontsWithPath]);

    return null;
}