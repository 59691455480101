/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import {
    CommandHandler,
    CommandRegistry,
    getExitDialog,
    ResourceListenerManager,
    RouterProps,
    withRouter
} from "@jss/js-common";
import { ResourceRouteState, RunContainer, RunContext } from "@jss/js-repository";
import {
    Conf,
    IEditorViewProps,
    IRepositoryItemDescriptor,
    isReadOnly,
    JRIOApiService,
    MessageInfo,
    RepositoryApi
} from "@jss/js-rest-api";
import { EXIT_TEXT_EDITOR, JRXMLEditor, TEXT_EDITOR_DIRTY, TEXT_SAVED, TEXT_SAVED_AS } from "@jss/js-text-editor";
import { IconButton } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
import { Base64 } from "js-base64";
import React from "react";
import { Provider } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
    BasicReportViewer as JrxmlViewer,
    EXIT_PREVIEW_EDITOR,
    EXIT_REPORT_DESIGNER,
    FullReportEditor,
    REPORT_EDITOR_DIRTY,
    REPORT_SAVED,
    REPORT_SAVED_AS,
    UPDATED_DESCRIPTOR
} from "../../index";
import { IState as IReportState } from '../../reducers';
import store from '../../store/store';
import { ErrorBoundary } from "../common/error/ErrorBoundary";
import { ConfirmSwitchDialog } from "./ConfirmSwitchDialog";

interface IState {
    width: number;
    height: number;
    //undefined value is not initialized, null it is a new report, IRepositoryItemDescriptor is an opened report
    openDescriptor?: IRepositoryItemDescriptor;
    tempData?: string;
    tempParameters?: ImmutableMap<string, any>,
    path?: IRepositoryItemDescriptor[];
    currentMessage?: MessageInfo;
    savedOnce: boolean;
    currentTabIndex: number;
    isEditorDirty: boolean;
    isBook: boolean;
    exitAlertOpen: boolean;
    logoutCallback: () => void | undefined,
    textEditorContent: string,
    textEditorUndoHistory: ImmutableList<string>,
    textEditorRedoHistory: ImmutableList<string>,
}

class RegistryStub implements CommandRegistry {

    private editor: _JrxmlEditorView;

    constructor(editor: _JrxmlEditorView) {
        this.editor = editor;
    }

    public registerHandler = (id: string, handle: CommandHandler) => {
        this.editor.commandHandlers.set(id, handle);
    }

    public executeCommand = (id: string, ...params: any) => {
        if (id === EXIT_REPORT_DESIGNER || id === EXIT_TEXT_EDITOR || id === EXIT_PREVIEW_EDITOR) {
            if (this.editor.state.isEditorDirty) {
                this.editor.setState({ exitAlertOpen: true });
            } else {
                this.editor.props.doExit();
            }
        } else if (id === REPORT_SAVED || id === TEXT_SAVED || id === UPDATED_DESCRIPTOR || id === REPORT_SAVED_AS || id === TEXT_SAVED_AS) {
            if (params[0] && params[0].descriptor) {
                if (!this.editor.state.savedOnce) {
                    this.editor.props.onFirstSave(params[0].descriptor);
                }
                if (id === REPORT_SAVED_AS) {
                    this.editor.setState({ openDescriptor: params[0].descriptor, savedOnce: true, currentTabIndex: 0 }, () => {
                        window.history.replaceState("", "", `/edit/designer?path=${this.editor.state.openDescriptor.uuid}`);
                        this.editor.props.router.location.search = `?path=${this.editor.state.openDescriptor.uuid}`;
                    });
                } else if (id === TEXT_SAVED_AS) {
                    this.editor.setState({ openDescriptor: params[0].descriptor, savedOnce: true, currentTabIndex: 1 }, () => {
                        window.history.replaceState("", "", `/edit/source?path=${this.editor.state.openDescriptor.uuid}`);
                        this.editor.props.router.location.search = `?path=${this.editor.state.openDescriptor.uuid}`;
                    });
                } else {
                    this.editor.setState({ openDescriptor: params[0].descriptor, savedOnce: true });
                }
            }
        } else if (id === REPORT_EDITOR_DIRTY || id === TEXT_EDITOR_DIRTY) {
            this.editor.setState({ isEditorDirty: params[0].isDirty });
        }
    }

    public registerToolbar = () => {
        // empty
    }

    public showMessage = (messageInfo: MessageInfo) => {
        this.editor.setState({ currentMessage: messageInfo });
    }
}

export type getJrxmlEditorHeaderComponentType = (
    {
        tabs,
        messageInfo,
        tabIndex,
        goToHome,
        requestLogout,
        resourceName,
        warningMessage,
        onTabChange,
        closeMessage
    }:
        {
            tabs: Array<any>,
            messageInfo: MessageInfo,
            tabIndex: number,
            goToHome: () => void,
            requestLogout: (callback) => void,
            resourceName: string,
            warningMessage: string,
            onTabChange: (tabIndex: number) => void,
            closeMessage: () => void
        }) => JSX.Element;


export interface JrxmlEditorViewProps extends IEditorViewProps {
    getHeaderComponent: getJrxmlEditorHeaderComponentType;
    language?: string,
}

const JrxmlEditorView_ROUTES = {
    DESIGNER: "designer",
    SOURCE: "source",
    PREVIEW: "preview"
};

/**
 * This is the repository main screen
 */
class _JrxmlEditorView extends React.Component<JrxmlEditorViewProps & RouterProps, IState> {

    private registryStub = new RegistryStub(this);

    private resourceListeners = [new ResourceListenerManager(this)];

    public commandHandlers: Map<string, CommandHandler> = new Map();

    private jrxmEditorRef: React.RefObject<any> = React.createRef<any>();

    private xmlEditorRef: React.RefObject<any> = React.createRef<any>();

    public state: IState = {
        width: 0,
        height: 0,
        currentTabIndex: 0,
        openDescriptor: undefined,
        tempData: undefined,
        tempParameters: undefined,
        path: undefined,
        currentMessage: undefined,
        savedOnce: false,
        isEditorDirty: false,
        isBook: false,
        exitAlertOpen: false,
        logoutCallback: undefined,
        textEditorContent: '',
        textEditorRedoHistory: ImmutableList<string>(),
        textEditorUndoHistory: ImmutableList<string>(),
    }

    private onBeforeUnload = (event) => {
        // Show prompt based on state
        if (this.state && this.state.isEditorDirty) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = ''
            }
            return '';
        }
    }

    public setTextEditorContent = (value: string) => {
        this.setState({ textEditorContent: value });
    }

    public setTextEditorHistory = (undo: ImmutableList<string>, redo: ImmutableList<string>) => {
        this.setState({ textEditorUndoHistory: undo, textEditorRedoHistory: redo });
    }

    public getTextEditorContent = () => {
        return this.state.textEditorContent;
    }

    public getTextEditorUndoHistory = () => {
        return this.state.textEditorUndoHistory;
    }

    public getTextEditorRedoHistory = () => {
        return this.state.textEditorRedoHistory;
    }

    public componentDidMount = () => {
        window.addEventListener("beforeunload", this.onBeforeUnload);

        const reportData: ResourceRouteState = { descriptor: this.props.descriptor };
        if (reportData) {
            const { descriptor } = reportData;
            const path = this.props.path;
            let defaultEditorPage = 0;
            if (isReadOnly(descriptor) && JRIOApiService.inst().isJrioAvailable(descriptor)) {
                defaultEditorPage = 2;
            }
            const isCurrentReportBook = false;
            const isNewResource = !descriptor.uuid;
            //if it is a new resource the report is dirty by default (the else branch is not currently used)
            this.setState({
                openDescriptor: descriptor,
                currentTabIndex: defaultEditorPage,
                path,
                savedOnce: !isNewResource,
                isBook: isCurrentReportBook,
                isEditorDirty: isNewResource
            }, () => {
                this.resourceListeners.forEach((resourceListener) => {
                    resourceListener.fireOpenEvent(this.state.openDescriptor, this.state.openDescriptor.data, this.state.path);
                });
            });
        }

        const pathname = this.props.router.location.pathname;
        let currentTabIndex = 0;
        if (pathname.endsWith(JrxmlEditorView_ROUTES.SOURCE)) {
            currentTabIndex = 1;
        } else if (pathname.endsWith(JrxmlEditorView_ROUTES.PREVIEW)) {
            currentTabIndex = 2;
        }
        this.setState({ currentTabIndex });
    }

    public componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onBeforeUnload);
    }

    private closeMessage = () => {
        this.setState({ currentMessage: undefined });
    }

    private onTabChange = (newTabIndex: number) => {
        const doSwitchTab = () => {
            this.props.router.navigate(JrxmlEditorView_ROUTES[Object.keys(JrxmlEditorView_ROUTES)[newTabIndex]] + this.props.router.location.search);
        }
        const reportState = store.getState() as IReportState;
        const parameters = reportState.getIn(['report', 'parametersValue']) as ImmutableMap<string, unknown>;
        if (this.state.currentTabIndex === 2) {
            doSwitchTab();
            //we are returning back to the editor from preview
            const tempData = this.state.tempData;
            if (newTabIndex === 0) {
                //restore also the parameters in the visual editor editor
                this.setState({ currentTabIndex: newTabIndex, tempData: undefined });
            } else {
                //keep the parameters in the cache in the text editor
                this.setState({ currentTabIndex: newTabIndex, tempData: undefined }, () => {
                    if (this.state.openDescriptor) {
                        this.resourceListeners.forEach((resourceListener) => {
                            resourceListener.fireOpenEvent(this.state.openDescriptor, tempData ? tempData : this.state.openDescriptor.data, [], { keepCache: true, keepHistory: true }, parameters);
                        });
                    }
                });
            }
        } else {
            if (newTabIndex === 2) {
                let newData;
                if (this.state.currentTabIndex === 0) {
                    //report editor
                    newData = this.jrxmEditorRef.current.getEditorContent();
                } else {
                    //text editor
                    newData = this.xmlEditorRef.current.getEditorContent();
                }
                const descriptor: IRepositoryItemDescriptor = this.state.openDescriptor;
                const base64data = Base64.encode(newData);

                const newDescriptor: IRepositoryItemDescriptor = {
                    ...descriptor,
                    data: base64data,
                    path: RepositoryApi.getAPathOnly(descriptor)
                }
                const preparePreview = () => {
                    const save2temp = RepositoryApi.inst().getBase() !== 'js' || newDescriptor.isMainReport !== true;
                    const t = !save2temp ? RepositoryApi.inst() : RepositoryApi.inst().tmp();
                    if (save2temp && t != RepositoryApi.inst().currentRepository()) {
                        newDescriptor.uuid = undefined;
                    }
                    this.context.runNoCancel(t.save)(newDescriptor.path ? newDescriptor.path : '/', newDescriptor).then((response: AxiosResponse) => {
                        if (response.data) {
                            if (response.data.version) {
                                descriptor.version = response.data.version;
                            }
                            this.setState(
                                { currentTabIndex: newTabIndex, tempData: newData },
                                doSwitchTab
                            );
                        }
                    }).catch(error => console.log(error));
                }
                //check if I'm on the main report of a report unit
                const dontAskReports = Conf.get('dontAskAnymoreReportMap');
                let jsonObject = {};
                if (dontAskReports) {
                    jsonObject = JSON.parse(dontAskReports);
                }
                const openDescriptor = this.state.openDescriptor;
                if (openDescriptor && openDescriptor.isMainReport && this.state.isEditorDirty &&
                    !jsonObject[openDescriptor.uuid ? openDescriptor.uuid : openDescriptor.name]) {
                    this.context.showDialog(ConfirmSwitchDialog, {
                        handleConfirm: (dontAskAnymore: boolean) => {
                            if (dontAskAnymore) {
                                jsonObject[openDescriptor.uuid ? openDescriptor.uuid : openDescriptor.name] = true;
                                Conf.set('dontAskAnymoreReportMap', JSON.stringify(jsonObject));
                            }
                            preparePreview();
                        }
                    });
                } else {
                    preparePreview();
                }
            } else {
                doSwitchTab();
                //i'm switching between text and visual editor, migrate the dirty content
                let newData;
                if (this.state.currentTabIndex === 0) {
                    newData = this.jrxmEditorRef.current.getEditorContent();

                } else if (this.state.currentTabIndex === 1) {
                    newData = this.xmlEditorRef.current.getEditorContent();
                }
                this.setState({ currentTabIndex: newTabIndex }, () => {
                    if (this.state.openDescriptor && newData) {
                        this.resourceListeners.forEach((resourceListener) => {
                            resourceListener.fireOpenEvent(this.state.openDescriptor, newData, this.state.path, { keepCache: true, keepHistory: true }, parameters);
                        });
                    }
                });
            }
        }
    }

    private goToHome = () => {
        if (this.state.currentTabIndex === 1) {
            const handler = this.commandHandlers.get(EXIT_TEXT_EDITOR);
            if (handler && handler.isEnabled()) {
                handler.execute({});
            }
        } else {
            const handler = this.commandHandlers.get(EXIT_REPORT_DESIGNER);
            if (handler && handler.isEnabled()) {
                handler.execute({});
            }
        }
    }

    private requestLogout = (callback: () => void) => {
        if (this.state.isEditorDirty) {
            this.setState({ logoutCallback: callback, exitAlertOpen: true });
        } else {
            callback();
        }
    }

    private getAvailableTabs = () => {
        const tabs = [];
        if (isReadOnly(this.state.openDescriptor)) {
            tabs.push({
                label: 'Preview',
                icon: <IconButton
                    aria-label="edit"
                    className={"jr-mButton jr-mButtonLarge jr-mButtonSecondary jr-MuiButton-contained mui"}
                    classes={{ label: "jr-mButton-icon jr-mIcon mui jr-view" }}
                />
            });
        } else {
            if (!this.state.isBook) {
                tabs.push({
                    label: 'Editor',
                    icon: <IconButton
                        aria-label="edit"
                        className={"jr-mButton jr-mButtonLarge jr-mButtonSecondary jr-MuiButton-contained mui"}
                        classes={{ label: "jr-mButton-icon jr-mIcon mui jr-edit" }}
                    />
                });
            }
            tabs.push({
                label: 'Source',
                icon: <IconButton
                    aria-label="edit"
                    className={"jr-mButton jr-mButtonLarge jr-mButtonSecondary jr-MuiButton-contained mui"}
                    classes={{ label: "jr-mButton-icon jr-mIcon mui jr-code" }}
                />
            });
            if (!this.state.isBook && JRIOApiService.inst().isJrioAvailable(this.state.openDescriptor)) {
                tabs.push({
                    label: 'Preview',
                    icon: <IconButton
                        aria-label="edit"
                        className={"jr-mButton jr-mButtonLarge jr-mButtonSecondary jr-MuiButton-contained mui"}
                        classes={{ label: "jr-mButton-icon jr-mIcon mui jr-view" }}
                    />
                });
            }
        }
        return tabs;
    }

    private handleExitAlertCancel = () => {
        this.setState({ exitAlertOpen: false, logoutCallback: undefined });
    }

    private getDesignerComponent = () => {
        const d = this.state.openDescriptor ? this.state.openDescriptor : this.props.descriptor;
        const previewDescriptor = { ...d };
        if (previewDescriptor !== undefined && previewDescriptor.path !== undefined) {
            previewDescriptor.path = previewDescriptor.path.split('\\').join('/');
        }
        if (isReadOnly(d)) {
            return previewDescriptor ?
                <JrxmlViewer resourceDescriptor={previewDescriptor}
                    commandRegistry={this.registryStub}
                    resourceListenerManagers={this.resourceListeners}
                    isEditorDirty={this.state.isEditorDirty}
                    language={this.props.language} />
                : null;
        } else {
            return this.state.isBook
                ? <JRXMLEditor ref={this.xmlEditorRef}
                    resourceListenerManagers={this.resourceListeners}
                    commandRegistry={this.registryStub}
                    language={this.props.language} />
                : <FullReportEditor ref={this.jrxmEditorRef}
                    resourceListenerManager={this.resourceListeners}
                    commandRegistry={this.registryStub}
                    hidePreviewTab={true}
                    language={this.props.language} />
        }
    }

    private handleExitConfirm = () => {
        this.setState({ exitAlertOpen: false, isEditorDirty: false }, () => {
            if (this.state.logoutCallback) {
                this.state.logoutCallback()
            } else {
                this.props.doExit();
            }
        })
    }

    public render() {
        const d = this.state.openDescriptor ? this.state.openDescriptor : this.props.descriptor;
        let reportName: string;
        const warningMessage = '';
        if (d !== undefined) {
            reportName = d.name;
        }
        const previewDescriptor = { ...d };
        if (previewDescriptor !== undefined && previewDescriptor.path !== undefined)
            previewDescriptor.path = previewDescriptor.path.split('\\').join('/');
        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                {
                    this.props.getHeaderComponent({
                        tabs: this.getAvailableTabs(),
                        messageInfo: this.state.currentMessage,
                        tabIndex: this.state.currentTabIndex,
                        goToHome: this.goToHome,
                        requestLogout: this.requestLogout,
                        resourceName: reportName,
                        warningMessage: warningMessage,
                        onTabChange: this.onTabChange,
                        closeMessage: this.closeMessage
                    })
                }
                <div id="container" style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto', height: '100%', width: '100%' }}>
                    <RunContainer>
                        <Provider store={store as any}>
                            <ErrorBoundary>
                                <Routes>
                                    <Route index element={<Index />} />
                                    <Route path={JrxmlEditorView_ROUTES.DESIGNER}
                                        element={this.getDesignerComponent()} />
                                    <Route path={JrxmlEditorView_ROUTES.SOURCE}
                                        element={<JRXMLEditor ref={this.xmlEditorRef}
                                            resourceListenerManagers={this.resourceListeners}
                                            commandRegistry={this.registryStub}
                                            language={this.props.language}
                                            getEditorContent={this.getTextEditorContent}
                                            getRedoHistory={this.getTextEditorRedoHistory}
                                            getUndoHistory={this.getTextEditorUndoHistory}
                                            setEditorContent={this.setTextEditorContent}
                                            setHistory={this.setTextEditorHistory}
                                        />
                                        } />
                                    {JRIOApiService.inst().isJrioAvailable(this.props.descriptor) &&
                                        <Route path={JrxmlEditorView_ROUTES.PREVIEW}
                                            element={
                                                previewDescriptor ?
                                                    <JrxmlViewer resourceDescriptor={previewDescriptor}
                                                        commandRegistry={this.registryStub}
                                                        resourceListenerManagers={this.resourceListeners}
                                                        isEditorDirty={this.state.isEditorDirty}
                                                        language={this.props.language} />
                                                    : null
                                            } />
                                    }
                                    <Route path="*" element={<Unknown />} />
                                </Routes>
                            </ErrorBoundary>
                        </Provider>
                    </RunContainer>
                </div>
                {getExitDialog(this.state.exitAlertOpen, this.handleExitAlertCancel, this.handleExitConfirm)}
            </div>
        );
    }
}

_JrxmlEditorView.contextType = RunContext;

export const JrxmlEditorView = withRouter(_JrxmlEditorView);

function Index() {
    const location = useLocation();

    return <Navigate to={`${JrxmlEditorView_ROUTES.DESIGNER + location.search}`} />
}

function Unknown() {
    return <div>Unknown!</div>
}