/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const EllipseIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/palette/ellipse" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M10,2 C13.8659932,2 17,5.13400675 17,9 C17,12.8659932 13.8659932,16 10,16 C6.13400675,16 3,12.8659932 3,9 C3,5.13400675 6.13400675,2 10,2 Z M9.9058203,3.59597377 C6.92117587,3.64807093 4.5438766,6.10983639 4.59597377,9.09448082 C4.64807093,12.0791252 7.10983639,14.4564245 10.0944808,14.4043274 C13.0791252,14.3522302 15.4564245,11.8904647 15.4043274,8.9058203 C15.3522302,5.92117587 12.8904647,3.5438766 9.9058203,3.59597377 Z" id="Combined-Shape" fill="#A8D2F9"></path>
                <path d="M10,13.35 C10.9664983,13.35 11.75,14.1335017 11.75,15.1 C11.75,16.0664983 10.9664983,16.85 10,16.85 C9.03350169,16.85 8.25,16.0664983 8.25,15.1 C8.25,14.1335017 9.03350169,13.35 10,13.35 Z M16.1,7.35 C17.0664983,7.35 17.85,8.13350169 17.85,9.1 C17.85,10.0664983 17.0664983,10.85 16.1,10.85 C15.1335017,10.85 14.35,10.0664983 14.35,9.1 C14.35,8.13350169 15.1335017,7.35 16.1,7.35 Z M3.9,7.15 C4.86649831,7.15 5.65,7.93350169 5.65,8.9 C5.65,9.86649831 4.86649831,10.65 3.9,10.65 C2.93350169,10.65 2.15,9.86649831 2.15,8.9 C2.15,7.93350169 2.93350169,7.15 3.9,7.15 Z M10,1.15 C10.9664983,1.15 11.75,1.93350169 11.75,2.9 C11.75,3.86649831 10.9664983,4.65 10,4.65 C9.03350169,4.65 8.25,3.86649831 8.25,2.9 C8.25,1.93350169 9.03350169,1.15 10,1.15 Z" id="Combined-Shape" fill="#0081CB"></path>
            </g>
        </svg>
    );
}
export default EllipseIcon;