
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import '../../../assets/uxpl/css/BaseElement.css';
import { IState } from '../../../reducers';
import withPositionFinder, { IWithPositionFinder } from '../hoc/positionFinder';
import SelectionFigure from '../elements/interaction/SelectionFigure';


interface IBaseElement extends IWithPositionFinder {
  id: string;
  element?: any;
  model?: Map<string, any>;
  selection?: Map<string, any>;
  zoom: number;
  highlightedAsContainer?: boolean;
}

const visualProperties = ['x', 'y', 'width', 'height', 'highlighted'];

class CrosstabFeedbackElement extends React.Component<IBaseElement> {

  public shouldComponentUpdate = (nextProps: Readonly<IBaseElement>) => {

    if (this.props.zoom !== nextProps.zoom) {
      return true;
    }

    if (this.props.highlightedAsContainer !== nextProps.highlightedAsContainer) {
      return true;
    }

    if (this.props.selection.has(this.props.id) !== nextProps.selection.has(this.props.id)) {
      return true;
    }

    for (const propName of visualProperties) {
      if (this.props.element.get(propName) !== nextProps.element.get(propName)) {
        return true;
      }
    }

    return false; // Nothing impacting the content of the figure is changed...
  }

  public render() {
    if (!this.props.positionFinder) {
      return null;
    }

    const zoom = (this.props.zoom) ? this.props.zoom : 1;

    let selectionFigure = null;
    let highlightBorder = null;

    const { x, y, renderElement } = this.props.positionFinder.findPosition(this.props.element);

    if (!renderElement) {
      return null;
    }

    if (isNaN(x) || isNaN(y)) {
      console.log('error');
    }

    const fullWidth = this.props.element.get('width') * zoom;
    const fullHeight = this.props.element.get('height') * zoom;
    const baseAttributes: React.CSSProperties = {
      top: y * zoom,
      left: x * zoom,
      width: fullWidth,
      height: fullHeight,
      display: 'flex',
    };

    const classes = ["BaseElement"];
    const id = this.props.element.get('id', '');
    const isSelected = this.props.selection.has(id);
    if (isSelected) {
      selectionFigure = (<SelectionFigure position={baseAttributes} id={this.props.id} zoom={zoom} />);
    }

    if ((!isSelected && this.props.element.get('highlighted', false)) || this.props.highlightedAsContainer) {
      highlightBorder = (
        <svg className="BaseElementBorder" width={this.props.element.get('width')} height={this.props.element.get('height')} style={{ ...baseAttributes, zIndex: 1 }}>
          <rect shapeRendering="optimizeSpeed" width="100%" height="100%" strokeWidth={2 / zoom} style={{ fill: 'none', stroke: 'rgba(0, 129, 203, 0.5)' }} />
        </svg>);
    }
    return (
      <>
        <div className={classes.join(' ')} style={baseAttributes}/>
        {highlightBorder}
        {selectionFigure}
      </>
    );
  }

}

const mapStateToProps = (state: IState, props: IBaseElement) => {

  return {
    selection: state.getIn(['report', 'selection']),
    element: state.getIn(['report', 'model', 'elements', props.id]),
    model: state.getIn(['report', 'model']),
    highlightedAsContainer: state.getIn(['report', 'highlightedContainer']) === ('elements/' + props.id),
  };
}

export default connect(mapStateToProps)(withPositionFinder(CrosstabFeedbackElement));