/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from '../../ui/APDescriptor';
import { PAccordeon } from '../../ui/composite/UPAccordeon';
import { PDsValueExpression } from '../../ui/primitive/UPValueExpession';
import { PBoolean } from '../../ui/primitive/UPBoolean';
import { PString } from '../../ui/primitive/UPString';

export const MSubreportPartCustom: APDescriptor = { type: 'MSubreportPartCustom', id: 'MSubreportPartCustom', path: 'properties', layouts: [
	new PAccordeon({ id: 'data.cache', label: 'data.cache', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.data.cache.included', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.data.cache.included', label: `net.sf.jasperreports.data.cache.included`, description: `Flag property that specifies if data associated with this object (report parameter, subreport element, subdataset run, report part) 
will be included in the data snapshot.`, sinceVersion: '4.6.0'}), scopes: ['PARAMETER', 'SUBDATASET_RUN', 'SUBREPORT', 'PART'] }),
	]}),
	new PAccordeon({ id: 'data.source', label: 'data.source', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.bookmarks.data.source.parameter', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.bookmarks.data.source.parameter', label: `net.sf.jasperreports.bookmarks.data.source.parameter`, description: `Property that references the parameter containing the bookmarks data source.`, sinceVersion: '6.0.0'}), scopes: ['PART'] }),
	]}),
	new PAccordeon({ id: 'other', label: 'other', custom: true, layouts: [
		new PDsValueExpression({custom: true,  id: 'net.sf.jasperreports.print.part.visible', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.print.part.visible', label: `net.sf.jasperreports.print.part.visible`, description: `Flag set at report/book part level to determine whether report viewers are to be display a tab for the part.`, default: 'true', sinceVersion: '6.20.2'}), scopes: ['PART'] }),
	]}),
]};
