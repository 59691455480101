/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";

import { ChecksumModeEnum } from "../../enums/ChecksumModeEnum";
import { PInt } from "../../../ui/primitive/UPInteger";
import { FBarcode4J, MBarcode4J } from "./MBarcode4J";


export const FRoyalMailCustomer = {
    intercharGapWidth: new PInt({ id: 'intercharGapWidth', label: 'Interchar Gap Width', min: 0 }),
    checksumMode: ChecksumModeEnum,
    ascenderHeight: new PInt({ id: 'ascenderHeight', label: 'Ascender Height', min: 0 }),
    trackHeight: new PInt({ id: 'trackHeight', label: 'Track Height', min: 0 }),
}

export const MRoyalMailCustomer: APDescriptor = {
    type: 'RoyalMailCustomer', id: 'RoyalMailCustomer', label: 'Royal Mail Customer', icon: ICONS.BARCODE_ROYALMAILCUSTOMER_ICON,
    layouts: [...MBarcode4J.layouts, FBarcode4J.verticalQuietZone, FBarcode4J.textPosition, FRoyalMailCustomer.intercharGapWidth, FRoyalMailCustomer.checksumMode, FRoyalMailCustomer.ascenderHeight, FRoyalMailCustomer.trackHeight],
    default: {
        type: 'BARCODE', BARCODE_TYPE: 'RoyalMailCustomer', BARCODE_XMLNS_NAMESPACE: 'c',
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:c': "http://jasperreports.sourceforge.net/jasperreports/components",
        codeExpression: '"123456789"'
    }
};
