/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { Map } from "immutable";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";



import * as ReportActions from '../../../actions/reportActions';
import { IState } from "../../../reducers";

import { createReportElement } from '../document/documentFactory';
import { ElementTypes } from "../document/elementTypes";
import * as DocumentUtils from './documentUtils';

let cachedPositionResolver: DocumentUtils.IPositionFinder | undefined;

function* dragStart() {
    const state = yield select((s: IState) => s);
    cachedPositionResolver = DocumentUtils.createObjectAbsolutePositionFinder(state);
}

function* dragEnd() {
    cachedPositionResolver = undefined;
}


/**
 *  Manage drag of an item from the palette.
 * 
 * @param action 
 */
function* paletteDragItemSaga(action: any) {

    const path = DocumentUtils.findContainerAt(action.location, cachedPositionResolver);
    yield put(ReportActions.highilightContainer(path));

}

/**
 * Manages drop of an item from the palette.
 * 
 * TODO: we should set a specific action type here without using "any"
 * 
 * @param action 
 */
function* paletteDropItemSaga(action: any) {

    const { location, item } = action;

    const state = yield select((s: IState) => s);
    const model = state.getIn(['report', 'model']);
    const containerPath = state.getIn(['report', 'highlightedContainer']);

    yield put(ReportActions.highilightContainer(null));

    if (!model || !containerPath) {
        return;
    }

    // Calcolate the drop position based on the candidate container...

    const relativePosition = DocumentUtils.getObjectAbsolutePosition(state, model.getIn([...containerPath.split('/')]));

    relativePosition.x = location.x - relativePosition.x;
    relativePosition.y = location.y - relativePosition.y;

    // 1. Clear all feedbacks...

    // 2. Add the element(s) dragged in the document by finding the proper container...

    let newEle = null;
    const t = item.id.split(':');
    switch (t[0]) {
        case ElementTypes.TEXTFIELD:
        case ElementTypes.STATICTEXT:
        case ElementTypes.IMAGE:
        case ElementTypes.BREAK:
        case ElementTypes.RECTANGLE:
        case ElementTypes.ELLIPSE:
        case ElementTypes.LINE:
        case ElementTypes.GENERIC_ELEMENT:
        case ElementTypes.FRAME:
        case ElementTypes.SUBREPORT:
        case ElementTypes.BARCODE:
        case ElementTypes.LIST:
        case ElementTypes.JFREECHART:
        case ElementTypes.CROSSTAB:
        case ElementTypes.TABLE:
        case ElementTypes.MAP:
        case ElementTypes.CVC_ELEMENT:
        case ElementTypes.HTML5CHART_JR:
        case ElementTypes.TIBCO_MAP:
        case ElementTypes.FUSIONCHART:
        case ElementTypes.FUSION_MAP:
        case ElementTypes.AD_HOC_COMPONENT:
        case ElementTypes.FUSION_WIDGET:
            {
                newEle = createReportElement(item.id, relativePosition);
                yield call(addAndSelectElement, newEle, containerPath);
                break;
            }
        default:
        // do nothing, unknown palette item provided...
    }
}


/**
 * Add an element to the document, recalculates the client area and select the document
 * 
 * @param newEle 
 */
function* addAndSelectElement(newEle: Map<string, any>, containerPath: string) {
    yield put(ReportActions.addElement(newEle, containerPath, true));
    yield call(DocumentUtils.updateClientArea);
}


/**
 * Binding of event type to the proper flow control for saga.
 */
export const paletteSagas = [
    takeLatest(ReportActions.Actions.DESIGNER_PALETTE_ITEM_DRAG_ACTION, paletteDragItemSaga),
    takeEvery(ReportActions.Actions.DESIGNER_PALETTE_ITEM_DROP_ACTION, paletteDropItemSaga),
    takeEvery(ReportActions.Actions.DESIGNER_PALETTE_ITEM_DRAG_START_ACTION, dragStart),
    takeEvery(ReportActions.Actions.DESIGNER_PALETTE_ITEM_DRAG_END_ACTION, dragEnd),
]