/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import React from 'react';
import { error, IRepositoryItemDescriptor, MessageInfo, msg, JobResult, ErrorDescriptor } from '@jss/js-rest-api';
import axios, { AxiosError, AxiosPromise, CancelTokenSource } from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { IDialogState, IResultDialogProp } from '@jss/js-common';
// import { withStyles } from '@material-ui/styles';

import './assets/uxpl/css/RunContainer.css';
import i18n from './i18n';

enum RunType { MODAL, NONMODAL }
enum RunProgress { LINEAR, CIRCULAR }
enum RunState { LOADING, LOADING_MODAL, LOADED }



export interface IProps {
    type?: RunType;
    progress?: RunProgress
}
interface IState {
    show?: RunState | MessageInfo;
    dialog?: any;
}

export type ARGS = string | number | boolean | IRepositoryItemDescriptor | undefined;

export interface IRunContainer {
    run: (f: (...fargs: ARGS[]) => JobResult) => (...args: ARGS[]) => AxiosPromise<any>;
    runNoCancel: (f: (...fargs: ARGS[]) => AxiosPromise<any>) => (...args: ARGS[]) => AxiosPromise<any>;
    runNoCancelModal: (f: (...fargs: ARGS[]) => AxiosPromise<any>) => (...args: ARGS[]) => AxiosPromise<any>;
    showDialog: (dprm: React.ClassType<IResultDialogProp, any, any>, prps: any) => Promise<IDialogState>;
    isLoading: (b: boolean) => void;
    handleError: (err: string) => void;
}


export const RunContext = React.createContext<IRunContainer>(null);
// const useStyles = theme => ({
//     backdrop: {
//         zIndex: theme.zIndex.drawer + 1,
//         color: '#fff',
//       }
//   });

export class RunContainer extends React.Component<IProps, IState> implements IRunContainer {
    public state: IState = {};

    static defaultProps = {
        type: RunType.NONMODAL,
        progress: RunProgress.LINEAR
    }

    public componentWillUnmount() {
        if (this.cToken) {
            this.cToken.cancel('Canceled');
        }
    }

    public render() {
        return <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {this.renderDialog()}
            {this.renderLoading()}
            {this.renderMessage()}
            <RunContext.Provider value={this}>
                {this.props.children}
            </RunContext.Provider>
        </div>
    }

    protected renderDialog = () => {
        if (this.state.dialog) {
            return this.state.dialog;
        }
    }

    protected renderLoading = () => {
        // const { classes } = this.props;
        switch (this.state.show) {
            case RunState.LOADING: return this.renderProgress();
            case RunState.LOADING_MODAL:
                return (<Backdrop open={true} /*className={classes.backdrop}*/><CircularProgress color="inherit" /></Backdrop>);
        }
    }
    protected renderProgress() {
        switch (this.props.progress) {
            case RunProgress.CIRCULAR: return <CircularProgress />;
            default:
                return <LinearProgress style={{ marginTop: '5px', position: 'absolute', top: 0, left: 0, right: 0, zIndex: 9999 }} />;
        }
    }

    protected renderMessage = () => {
        if (this.state.show instanceof MessageInfo) {
            return <div>
                <Snackbar open={true} autoHideDuration={6000} onClose={this.onClick} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <MuiAlert elevation={6} variant="filled" onClose={this.onClick} severity="error" className='runContainerErrorMessage'>
                        <div style={{ overflow: 'scroll', maxHeight: 500 }}>
                            {msg(this.state.show)}
                        </div>
                    </MuiAlert>
                </Snackbar>
            </div>;
        }
    }
    private onClick = () => {
        this.setState({ show: undefined });
    }

    public isLoading(b: boolean): void {
        this.setState({
            show: b ? RunState.LOADING : RunState.LOADED
        });
    }

    public handleError(err: string): void {
        this.setState({
            show: error(err)
        });
    }

    public showDialog = (dprm: React.ClassType<IResultDialogProp, any, any>, prps: any): Promise<IDialogState> => {
        return new Promise<IDialogState>((resolve) => {
            this.setState({
                dialog: React.createElement(dprm, {
                    ...prps, parameters: [], onEnd: (res: IDialogState) => {
                        this.setState({ dialog: undefined });
                        resolve(res);
                    }
                })
            });
        });
    }

    public errorMsg = (e: string | ErrorDescriptor | AxiosError | Error): MessageInfo => {
        return error(e, (m) => { return i18n.t(m) });
    }

    public runNoCancel = (f: (...fargs: ARGS[]) => AxiosPromise<any>): (...args: ARGS[]) => Promise<any> => {
        return async (...args): Promise<any> => {
            this.setState({ show: RunState.LOADING });
            try {
                const rs = await f(...args);
                this.setState({ show: RunState.LOADED });
                return rs;
            } catch (e) {
                this.setState({ show: this.errorMsg(e) });
                throw e;
            }
        }
    }

    public runNoCancelNoMessage = (f: (...fargs: ARGS[]) => AxiosPromise<any>): (...args: ARGS[]) => Promise<any> => {
        return async (...args): Promise<any> => {
            this.setState({ show: RunState.LOADING });
            try {
                const rs = await f(...args);
                this.setState({ show: RunState.LOADED });
                return rs;
            } catch (e) {
                this.setState({ show: RunState.LOADED });
                throw e;
            }
        }
    }

    public runNoCancelModal = (f: (...fargs: ARGS[]) => AxiosPromise<any>): (...args: ARGS[]) => AxiosPromise<any> => {
        return (...args): AxiosPromise<any> => {
            this.setState({ show: RunState.LOADING_MODAL })
            return f(...args).then(result => {
                this.setState({ show: RunState.LOADED });
                return result;
            }).catch(e => {
                this.setState({ show: this.errorMsg(e) });
                throw e;
            });
        }
    }

    private cToken: CancelTokenSource;

    public run = (f: (...fargs: ARGS[]) => JobResult): (...args: ARGS[]) => AxiosPromise<any> => {
        return (...args): AxiosPromise<any> => {
            if (this.cToken) {
                this.cToken.cancel('Canceled');
            }
            this.setState({ show: RunState.LOADING })
            const r = f(...args);
            this.cToken = r.cancelToken;
            return r.promise.then(result => {
                this.cToken = undefined;
                this.setState({ show: RunState.LOADED })
                return result;
            }).catch(e => {
                this.cToken = undefined;
                if (axios.isCancel(e)) {
                    console.log('canceled');
                } else {
                    this.setState({ show: this.errorMsg(e) });
                }
                throw e;
            });
        }
    }
}