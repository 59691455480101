export const it = {
	components: {
		report: {
			jrxmlviewer: {
				menu: {
					file: "File"
				}
			}
		},
		datasets: {
			whenResourceMissing: {
				label: "Quando una risorsa è mancante",
				null: "NULL",
				empty: "Stampa vuoto",
				error: "Stampa errore",
				key: "Stampa chiave"
			}
		},
		filesTable: {
			filename: "Nome File",
			type: "Tipo",
			lastmodified: "Ultima Modifica"
		},
	},
	band: {
		title: "Titolo",
		pageHeader: "Testata di Pagina",
		columnHeader: "Testata di Colonna",
		groupHeader: "Testata di Gruppo",
		detail: "Dettaglio",
		groupFooter: "Piè di Gruppo",
		columnFooter: "Piè di Colonna",
		pageFooter: "Piè di Pagina",
		background: "Sfondo",
		noData: "Nessun Dato",
		summary: "Sommario",
		lastPageFooter: "Ultimo Piè di Pagina"
	},
}