/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import store from './store/store';
import { IEditor } from '@jss/js-common';
import { IState } from './reducers';
import jsToJrxml from './components/common/JrxmlModel/writer/JrxmlWriter';
import MultiPageEditor, { IResourceHandlerReceiver } from './MultipageEditor';
import { Map } from 'immutable';

export class FullReportEditor extends React.Component<IResourceHandlerReceiver & {language?: string}> implements IEditor {

    public getEditorContent = () => {
        const state: IState = store.getState();
        if (state) {
            const model = state.getIn(['report', 'model']) as Map<string, any>;
            const jrxml = jsToJrxml(model);
            return jrxml;
        }

        return undefined;
    }

    public render() {
        return  <MultiPageEditor {...this.props} />;
    }
}