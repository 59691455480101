/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StackedPercentAreaChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath id="highcharts-mglypmq-1">
          <path fill="none" d="M0 0H499V672H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M46 10H545V682H46z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" d="M87.5 10v672M128.5 10v672M170.5 10v672M211.5 10v672M253.5 10v672M295.5 10v672M336.5 10v672M378.5 10v672M419.5 10v672M461.5 10v672M502.5 10v672M544.5 10v672M45.5 10v672" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M46 682.5h499M46 615.5h499M46 548.5h499M46 480.5h499M46 413.5h499M46 346.5h499M46 279.5h499M46 212.5h499M46 144.5h499M46 77.5h499M46 9.5h499" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M46 10H545V682H46z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" stroke="#ccd6eb" d="M87.5 682v10M128.5 682v10M170.5 682v10M211.5 682v10M253.5 682v10M295.5 682v10M336.5 682v10M378.5 682v10M419.5 682v10M461.5 682v10M502.5 682v10M545.5 682v10M45.5 682v10" className="highcharts-tick" />
       <path fill="none" stroke="#ccd6eb" d="M46 682.5h499" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M46 10v672" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-area-series highcharts-color-0" clipPath="url(#highcharts-mglypmq-1)" data-z-index="0.1" transform="translate(46 10)">
          <path fill="rgba(124,181,236,0.75)" d="M20.792 0H478.208l-41.583 13.832L395.042 0l-41.584 60.47-41.583 458.539L270.292 0h-41.584l-41.583 163.749L145.542 0l-41.584 43.233-41.583 190.323-41.583-24.917" className="highcharts-area" data-z-index="0" />
          <path fill="none" stroke="#7cb5ec" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.792 0H478.208" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M10.792 0H488.208" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-0 highcharts-area-series highcharts-color-0 highcharts-tracker" data-z-index="0.1">
          <path fill="#7cb5ec" d="M24 0a4 4 0 110-.004zM66 0a4 4 0 110-.004zM107 0a4 4 0 110-.004zM149 0a4 4 0 110-.004zM191 0a4 4 0 110-.004zM232 0a4 4 0 110-.004zM274 0a4 4 0 110-.004zM315 0a4 4 0 110-.004zM357 0a4 4 0 110-.004zM399 0a4 4 0 110-.004zM440 0a4 4 0 110-.004zM482 0a4 4 0 110-.004z" className="highcharts-point highcharts-color-0" transform="translate(46 10)" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-area-series highcharts-color-1" clipPath="url(#highcharts-mglypmq-1)" data-z-index="0.1" transform="translate(46 10)">
          <path fill="rgba(67,67,72,0.75)" d="M20.792 208.639l41.583 24.917 41.583-190.323L145.542 0l41.583 163.749L228.708 0h41.584l41.583 519.009 41.583-458.54L395.042 0l41.583 13.832L478.208 0v672l-41.583-411.62-41.583 109.152-41.584 266.348-41.583-23.59-41.583-7.48-41.584-428.897L187.125 672l-41.583-77.097-41.584-238.63-41.583 41.606-41.583 81.772" className="highcharts-area" data-z-index="0" />
          <path fill="none" stroke="#434348" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.792 208.639l41.583 24.917 41.583-190.323L145.542 0l41.583 163.749L228.708 0h41.584l41.583 519.009 41.583-458.54L395.042 0l41.583 13.832L478.208 0" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M10.792 208.639h10l41.583 24.917 41.583-190.323L145.542 0l41.583 163.749L228.708 0h41.584l41.583 519.009 41.583-458.54L395.042 0l41.583 13.832L478.208 0h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-1 highcharts-area-series highcharts-color-1 highcharts-tracker" data-z-index="0.1">
          <path fill="#434348" d="M20 205l4 4-4 4-4-4zM62 230l4 4-4 4-4-4zM103 39l4 4-4 4-4-4zM145-4l4 4-4 4-4-4zM187 160l4 4-4 4-4-4zM228-4l4 4-4 4-4-4zM270-4l4 4-4 4-4-4zM311 515l4 4-4 4-4-4zM353 56l4 4-4 4-4-4zM395-4l4 4-4 4-4-4zM436 9.832l4 4-4 4-4-4zM478-4l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(46 10)" />
       </g>
       <g className="highcharts-series highcharts-series-2 highcharts-area-series highcharts-color-2" clipPath="url(#highcharts-mglypmq-1)" data-z-index="0.1" transform="translate(46 10)">
          <path fill="rgba(144,237,125,0.75)" d="M20.792 479.651l41.583-81.772 41.583-41.605 41.584 238.629L187.125 672l41.583-496.087 41.584 428.897 41.583 7.48 41.583 23.59 41.584-266.348 41.583-109.152L478.208 672H20.792" className="highcharts-area" data-z-index="0" />
          <path fill="none" stroke="#90ed7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.792 479.651l41.583-81.772 41.583-41.605 41.584 238.629L187.125 672l41.583-496.087 41.584 428.897 41.583 7.48 41.583 23.59 41.584-266.348 41.583-109.152L478.208 672" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M10.792 479.651h10l41.583-81.772 41.583-41.605 41.584 238.629L187.125 672l41.583-496.087 41.584 428.897 41.583 7.48 41.583 23.59 41.584-266.348 41.583-109.152L478.208 672h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-2 highcharts-area-series highcharts-color-2 highcharts-tracker" data-z-index="0.1">
          <path fill="#90ed7d" d="M16 476h8v8h-8zM58 394h8v8h-8zM99 352h8v8h-8zM141 591h8v8h-8zM183 668h8v8h-8zM224 172h8v8h-8zM266 601h8v8h-8zM307 608h8v8h-8zM349 632h8v8h-8zM391 366h8v8h-8zM432 256h8v8h-8zM474 668h8v8h-8z" className="highcharts-point highcharts-color-2" transform="translate(46 10)" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(178 762)">
       <rect width="199" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-area-series highcharts-color-0 highcharts-series-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1996</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-area-series highcharts-color-1 highcharts-series-1" data-z-index="1" transform="translate(75.672 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1997</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-area-series highcharts-color-2 highcharts-series-2" data-z-index="1" transform="translate(143.344 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1998</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#90ed7d" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="69.384" y="698" transform="rotate(-45 69.384 698)">Albuquerque</text>
       <text x="110.968" y="698" transform="rotate(-45 110.968 698)">Anchorage</text>
       <text x="152.551" y="698" transform="rotate(-45 152.551 698)">Boise</text>
       <text x="194.134" y="698" transform="rotate(-45 194.134 698)">Butte</text>
       <text x="235.718" y="698" transform="rotate(-45 235.718 698)">Elgin</text>
       <text x="277.301" y="698" transform="rotate(-45 277.301 698)">Eugene</text>
       <text x="318.884" y="698" transform="rotate(-45 318.884 698)">Kirkland</text>
       <text x="360.468" y="698" transform="rotate(-45 360.468 698)">Lander</text>
       <text x="402.051" y="698" transform="rotate(-45 402.051 698)">Portland</text>
       <text x="443.634" y="698" transform="rotate(-45 443.634 698)">
          <tspan>San Francisco</tspan>
       </text>
       <text x="485.218" y="698" transform="rotate(-45 485.218 698)">Seattle</text>
       <text x="526.801" y="698" transform="rotate(-45 526.801 698)">
          <tspan>Walla Walla</tspan>
       </text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="31" y="687">0</text>
       <text x="31" y="619">10</text>
       <text x="31" y="552">20</text>
       <text x="31" y="485">30</text>
       <text x="31" y="418">40</text>
       <text x="31" y="351">50</text>
       <text x="31" y="283">60</text>
       <text x="31" y="216">70</text>
       <text x="31" y="149">80</text>
       <text x="31" y="82">90</text>
       <text x="31" y="15">100</text>
    </g>
 </svg>)};

export default StackedPercentAreaChartIcon;