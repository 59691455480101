/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const FtpTypeEnum = new PEnum({
    id: 'FtpType',   isEmptyUndefined: true,
    items: [
        { key: '', value: '' },
        { key: 'ftp', value: 'ftp' },
        { key: 'ftps', value: 'ftps' },
        { key: 'sftp', value: 'sftp' }
    ]
});

