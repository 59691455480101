/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MGraphicElement } from "../common/MGraphicElement";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { ICONS } from "@jss/js-common";
import { PAccordeonCategories } from "../../ui/composite/UPAccordeonCategories";
import { getPath, UiPropertyProps } from "../../ui/UiProperty";
import { MHArcGaugeChart, MHAreaChart, MHAreaSplineChart, MHBarChart, MHBubbleChart, MHColumnChart, MHColumnLineChart, MHColumnSplineChart, MHDualLevelPieChart, MHDualMeasureTreeMapChart, MHGaugeChart, MHHeatMapChart, MHHighMapChart, MHLineChart, MHMultiAxisColumnChart, MHMultiAxisLineChart, MHMultiAxisSplineChart, MHMultiLevelGaugeChart, MHOneParentTreeMapChart, MHPieChart, MHScatterChart, MHSemiPieChart, MHSpiderAreaChart, MHSpiderColumnChart, MHSpiderLineChart, MHSplineChart, MHStackedAreaChart, MHStackedAreaSplineChart, MHStackedBarChart, MHStackedColumnChart, MHStackedColumnLineChart, MHStackedColumnSplineChart, MHStackedLineChart, MHStackedPercentAreaChart, MHStackedPercentBarChart, MHStackedPercentColumnChart, MHStackedPercentLineChart, MHStackedPercentSplineChart, MHTilemapChart, MHTimeSeriesAreaChart, MHTimeSeriesAreaSplineChart, MHTimeSeriesHeatMapChart, MHTimeSeriesLineChart, MHTimeSeriesSplineChart, MHTreeMapChart, StackedPercentAreaSpline } from "./MChart";
import { MHighChartsDataset } from "./dataset/MHCDataset";
import { MHighChartsCustom } from "./MHighChartsCustom";
import { ChartTypes } from "../../../../../components/common/JrxmlModel/reader/JrxmlHTML5ChartUtils";

export const FHighCharts = {
};

export const MHighCharts: APDescriptor = {
    type: ElementTypes.HTML5CHART_JR,
    label: 'Highcharts',
    id: 'highChartPropertiesDescriptor',
    icon: ICONS.HIGHCHART_ICON,
    default: { width: 400, height: 150, chartType: ChartTypes.BAR_CHART_NAME },
    layouts: [new PScrollableCategories({
        id: 'highChartPropertiesTabsDescriptor',
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MGraphicElement.layouts, MHighChartsCustom] }),
            new PDiv({
                id: 'highchats',
                label: 'Highcharts', layouts: [new PAccordeonCategories({
                    selectionField: 'chartType',
                    getSelected(props: UiPropertyProps): APDescriptor | undefined {
                        const pac = props.mcontext.descriptor as PAccordeonCategories;
                        const p = getPath(pac.selectionField ? pac.selectionField : pac.id, props.mcontext.elements[0].path);
                        const v: string = props.mcontext.model.getIn(p) as string;
                        return pac.layouts?.find(k => {
                            return k.type?.toLowerCase() === v?.toLowerCase()
                        });
                    },
                    categories: [
                        MHBarChart, MHLineChart, MHAreaChart, MHSplineChart, MHAreaSplineChart, MHHeatMapChart, MHStackedBarChart, MHStackedColumnChart, MHStackedLineChart, MHStackedAreaChart, MHStackedAreaSplineChart, MHStackedPercentBarChart, MHStackedPercentColumnChart, MHStackedPercentLineChart, MHStackedPercentAreaChart, MHStackedPercentSplineChart, StackedPercentAreaSpline, MHPieChart, MHDualLevelPieChart, MHSemiPieChart, MHTimeSeriesLineChart, MHTimeSeriesAreaChart, MHTimeSeriesSplineChart, MHTimeSeriesAreaSplineChart, MHTimeSeriesHeatMapChart, MHColumnChart, MHColumnLineChart, MHColumnSplineChart, MHStackedColumnLineChart, MHStackedColumnSplineChart, MHMultiAxisLineChart, MHMultiAxisSplineChart, MHMultiAxisColumnChart, MHScatterChart, MHBubbleChart, MHSpiderColumnChart, MHSpiderLineChart, MHSpiderAreaChart, MHDualMeasureTreeMapChart, MHTreeMapChart, MHOneParentTreeMapChart, MHGaugeChart, MHMultiLevelGaugeChart, MHArcGaugeChart, MHTilemapChart, MHHighMapChart
                    ]
                })]
            }),
            MHighChartsDataset
        ]
    })]
};
