/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const HighchartIcon = (): React.ReactElement => {
    return (
<svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="jrws/palette/html5-charts" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M10,17 C7.77002007,17 5.75314377,16.0875949 4.30232043,14.615734 L6.57271263,12.6405828 C7.46772354,13.4834725 8.67354104,14 10,14 C12.5927767,14 14.724619,12.0265051 14.9753422,9.49969984 L17.9846613,9.49947687 C17.7268205,13.6850887 14.2505297,17 10,17 Z" id="Path" fill="#0081CB"></path>
        <path d="M9.50052313,1.01533875 L9.50030016,4.02465783 C6.97349494,4.27538101 5,6.40722328 5,9 C5,10.0755894 5.33962436,11.0718553 5.91748451,11.8874092 L3.64596694,13.8613622 C2.61352826,12.5139506 2,10.8286239 2,9 C2,4.74947025 5.31491134,1.27317954 9.50052313,1.01533875 Z" id="Path" fill="#7CC344"></path>
        <path d="M17.9846003,8.4995343 L14.9752426,8.49929802 C14.7404291,6.1376536 12.8623464,4.25957085 10.500702,4.02475737 L10.500482,1.01540073 C14.520109,1.26351967 17.7364894,4.47990516 17.9846003,8.4995343 Z" id="Path" fill="#00A9D7"></path>
        <g id="HTML5_logo_and_wordmark" transform="translate(10.000000, 7.000000)" fillRule="nonzero">
            <polyline id="Path" fill="#E44D26" points="0.882352941 9.90374332 0 0 9.70053476 0 8.81818182 9.89839572 4.84224599 11"></polyline>
            <polyline id="Path" fill="#F16529" points="4.85026738 10.157754 4.85026738 0.812834225 8.81550802 0.812834225 8.05882353 9.26203209"></polyline>
            <path d="M1.80213904,2.02406417 L4.85026738,2.02406417 L4.85026738,3.23796791 L3.13368984,3.23796791 L3.2459893,4.48128342 L4.85026738,4.48128342 L4.85026738,5.69251337 L2.13368984,5.69251337 M2.18716578,6.30213904 L3.40641711,6.30213904 L3.49197861,7.27272727 L4.85026738,7.63636364 L4.85026738,8.90374332 L2.35828877,8.20855615" id="Shape" fill="#EBEBEB"></path>
            <path d="M7.88770053,2.02406417 L4.84491979,2.02406417 L4.84491979,3.23796791 L7.77540107,3.23796791 M7.6657754,4.48128342 L4.84491979,4.48128342 L4.84491979,5.69518717 L6.34224599,5.69518717 L6.20053476,7.27272727 L4.84491979,7.63636364 L4.84491979,8.89839572 L7.3315508,8.20855615" id="Shape" fill="#FFFFFF"></path>
        </g>
    </g>
</svg>
    );
}
export default HighchartIcon;