/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PButtonsEnum } from "../../ui/primitive/UPButtonsEnum";

export const TabStopAlignEnum = new PButtonsEnum({
    id: 'alignment', label: 'TabStopAlignEnum.label',
    items: [
        { key: 'Left', value: 'HorizontalImageAlign.Left', icon: 'imageALeft' },
        { key: 'Center', value: 'HorizontalImageAlign.Center', icon: 'imageACenter' },
        { key: 'Right', value: 'HorizontalImageAlign.Right', icon: 'imageARight' }
    ]
}); 
 
