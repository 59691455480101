/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg } from '../../ui/UiProperty';

import { PConnectedTable } from '../../ui/tables/UPConnectedTable';
import UPVariablesTable from './UPVariablesTable';

const TYPEID = 'conVariablesTable';
export class PConVariablesTable extends PConnectedTable {

    public constructor(init: Partial<PConVariablesTable>) {
        super(init);
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPVariablesTable mcontext={mc} />; });