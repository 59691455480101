
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IState } from '../../../reducers';
import { findBandTop, IPositionFinder } from '../../../sagas/report/designer/documentUtils';
import { IElementPosition } from '../../../types/geometry';
import { Map } from 'immutable';
import { ElementTypes } from '../../../sagas/report/document/elementTypes';


export const listClientAreaProvider = (state: IState, editorInfo: Map<string, any>): { x: number, y: number, width: number, height: number } => {
    const listPath = editorInfo.get('editedResourceId').split('/');
    const list = state.getIn(['report', 'model', ...listPath]) as Map<string ,any>;
    const listWidth = list.get('width');
    const listHeight = list.get('height');
    const listSize = computeListRealSize(list, state.getIn(['report', 'model']) as Map<string, any>);
    return {
        x: -10, y: -10, width: Math.max(listSize.width, listWidth), height: Math.max(listSize.height, listHeight),
    }
}

/**
 * Return the position {x,y} of an element, band or whatever is presented on screen by converting
 * its coordinates relative to the container (if it has any) to its absolute position on the designer.
 * 
 * This is an expensive operation, consider to use findObjectAbsolutePositionWithCache().
 */
export const getListObjectAbsolutePosition = (state: IState, element: Map<string, any>, editedListPath: string): IElementPosition => {
    const elementType = element?.get('type', null);

    if (elementType === ElementTypes.BAND) {
        const model = state.getIn(['report', 'model'], null) as Map<string, any>;
        const topMargin = model.get('topMargin', 0);
        const leftMargin = model.get('leftMargin', 0);
        return { x: leftMargin, y: findBandTop(element.get('id')) + topMargin, renderElement: false };
    } else if (elementType === ElementTypes.ELEMENT_GROUP){
        //element group are not visual so they don't have a position
        const containerPath = element.get('path', null);
        const container = state.getIn(['report', 'model', ...containerPath.split('/')], null) as Map<string, any>;
        return getListObjectAbsolutePosition(state, container, editedListPath);
    }

    if (elementType === ElementTypes.LIST) {
        const listPath = `elements/${element.get('id')}`;
        if (listPath === editedListPath) {
            return {
                x: 0,
                y: 0,
                renderElement: true,
            };
        } else {
            //Im in a table inside the table
            return {
                x: 0,
                y: 0,
                renderElement: false,
            };
        }
    }

    // We assume object that are not bands to always have an x,y position
    const position = {
        x: element.get('x'),
        y: element.get('y'),
        //complex element, don't show its content inside the table
        renderElement: (elementType !== ElementTypes.TABLE && elementType !== ElementTypes.CROSSTAB),
    };

    // Let's check if this position is relative to a parent figure
    const containerPath = element.get('path', null);

    if (containerPath) {
        // We have found a container.
        const container = state.getIn(['report', 'model', ...containerPath.split('/')], null) as Map<string, any>;
        if (container) {
            const containerType = container.get('type');
            // We look recursively throug parents...
            if (containerType === ElementTypes.TABLE) {
                //stop here, the element inside table are not rendered in the main editor
                position.renderElement = false;
            } else {
                const containerPosition = getListObjectAbsolutePosition(state, container, editedListPath);
                position.x += containerPosition.x;
                position.y += containerPosition.y;
                position.renderElement = position.renderElement;
            }
        }
    }

    return position;
}

export const computeListRealSize = (currentElement: Map<string, any>, model: Map<string, any>): { width: number, height: number } => {
    const childrenIds = currentElement.get('elementIds')
    if (childrenIds) {
        let minWidth = 0;
        let minHeight = 0;
        childrenIds.forEach(childId => {
            const childModel = model.getIn(['elements', childId]) as Map<string, any>;
            const childRealSize = computeListRealSize(childModel, model);
            minWidth = Math.max(minWidth, childModel.get('x', 0) + childRealSize.width);
            minHeight = Math.max(minHeight, childModel.get('y', 0) + childRealSize.height);
        });
        return { width: minWidth, height: minHeight };
    } else {
        return { width: currentElement.get('width', 0), height: currentElement.get('height', 0) };
    }
}

/**
 * Return a function which will cache positions while used.
 */
export const listPositionFinderProvider = (state: IState, editedTablePath: string): IPositionFinder => {

    const listPath = editedTablePath.split('/');
    const listModel = state.getIn(['report', 'model', ...listPath]) as Map<string, any> | undefined;

    let pageHeight = 0;
    const cachedContainerPositions: any = {};
    if (listModel) {
        const tableSize = computeListRealSize(listModel, state.getIn(['report', 'model']) as Map<string, any>);
        pageHeight = Math.max(tableSize.height, listModel.get('height'));
    }

    return {
        getPageHeight: () => pageHeight,
        findPosition: (o: Map<string, any>): IElementPosition => {
            let container = { x: 0, y: 0, renderElement: true };

            const type = o.get('type');
            if (type === ElementTypes.LIST) {
                return { x: 0, y: 0, renderElement: true };
            }

            let x = 0;
            let y = 0;
            if (type !== ElementTypes.ELEMENT_GROUP){
              x = o.get('x');
              y = o.get('y');
            }

            const containerPath = o.get('path');
            if (containerPath) {
                if (cachedContainerPositions[containerPath] === undefined) {
                    cachedContainerPositions[containerPath] =
                        getListObjectAbsolutePosition(state, state.getIn(['report', 'model', ...containerPath.split('/')]) as Map<string, any>, editedTablePath);
                }
                container = cachedContainerPositions[containerPath];
            }

            const result = { x: container.x + x, y: container.y + y, renderElement: container.renderElement };
            return result;
        },
        debugCache: () => { console.log(cachedContainerPositions) }
    }
}
