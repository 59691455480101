/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { ChecksumModeEnum } from "../../enums/ChecksumModeEnum";
import { FBarcode4J, MBarcode4J } from "./MBarcode4J";


export const FEAN128 = {
    checksumMode: ChecksumModeEnum
}

export const MEAN128: APDescriptor = {
    type: 'EAN128', id: 'EAN128', label: 'EAN128', icon: ICONS.BARCODE_EAN128_ICON,
    layouts: [...MBarcode4J.layouts, FBarcode4J.verticalQuietZone, FBarcode4J.textPosition, FEAN128.checksumMode],
    default: {
        type: 'BARCODE', BARCODE_TYPE: 'EAN128', BARCODE_XMLNS_NAMESPACE: 'c',
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:c': "http://jasperreports.sourceforge.net/jasperreports/components",
        codeExpression: '"12345678901234567890"'
    }
};
