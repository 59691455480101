/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../ui/APDescriptor";
import { CrosstabTypes } from "../../../../common/JrxmlModel/reader/JrxmlCrosstabUtils";
import { PColor } from "../../ui/primitive/UPColor";
import { PMode } from "../../ui/primitive/UPMode";
import { PStyles } from "../styles/UPStyles";
import { PConnectedSizeAndPosition } from "../../ui/primitive/UPConnectedSizePosition";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { FAppearance, MBorders } from "../common/MAppearance";
import { MCrosstabCellCustom } from "./MCrosstabCellCustom";

export const FCrosstabCell = {
    backcolor: new PColor({ id: 'backcolor', label: 'Backcolor', allowTransparent: true, inheritedPropertyId: 'backcolor' }),
    mode: new PMode({ id: 'mode', label: 'Transparent' }),
    style: new PStyles({ id: 'style', label: 'Style' }),
    width: new PConnectedSizeAndPosition({ id: 'width', label: 'Width', min: 0 }),
    height: new PConnectedSizeAndPosition({ id: 'height', label: 'Height', min: 0 }),
};

export const MCrosstabCell: APDescriptor = {
    type: CrosstabTypes.CROSSTAB_CELL_NAME,
    id: 'crosstabCellProeprtiesDescriptor',
    label: 'Cell',
    layouts: [FCrosstabCell.mode, FCrosstabCell.backcolor, FCrosstabCell.style, FCrosstabCell.width, FCrosstabCell.height,
    new PAccordeon({
        id: 'element.paddings', label: 'Paddings', path: 'box',
        // style: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: 10 },
        layouts: [FAppearance.padding, FAppearance.leftPadding, FAppearance.rightPadding, FAppearance.topPadding, FAppearance.bottomPadding]
    }), MBorders, MCrosstabCellCustom]
};
