/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const CrosstabRowPositionEnum = new PEnum({
    id: 'position', label: 'CrosstabRowPosition.label',  
    items: [
        { key: 'Top', value: 'CrosstabRowPosition.Top' },
        { key: 'Middle', value: 'CrosstabRowPosition.Middle' },
        { key: 'Bottom', value: 'CrosstabRowPosition.Bottom' },
        { key: 'Stretch', value: 'CrosstabRowPosition.Stretch' }
    ]
});

