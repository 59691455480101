/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const MultipleAxisChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="547" height="375" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 547 375" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h547v375H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M185.352 152.648v547h-375v-547h375z" />
            </clipPath>
            <clipPath id="clipPath3" clipPathUnits="userSpaceOnUse">
               <path d="M317.836 655.836v-547h375v547h-375z" />
            </clipPath>
            <clipPath id="clipPath4" clipPathUnits="userSpaceOnUse">
               <path d="M361.508 699.508v-547h375v547h-375z" />
            </clipPath>
            <clipPath id="clipPath5" clipPathUnits="userSpaceOnUse">
               <path d="M60 4v330h401V4z" />
            </clipPath>
         </defs>
         <g fill="#fff" stroke="#fff" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path stroke="none" d="M178.166 356.688H368.834V374.001H178.166z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" d="M178.666 357.188L368.334 357.188" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" d="M178.666 373.5L368.334 373.5" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" d="M178.666 373.5L178.666 357.188" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" d="M368.334 373.5L368.334 357.188" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#360" strokeLinejoin="bevel" d="M182.166 365.344h14" clipPath="url(#clipPath1)" />
            <path fill="#360" stroke="none" strokeLinejoin="bevel" d="M186.166 362.344h6v6h-6v-6z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#360" d="M186.166 362.344h6v6h-6v-6z" clipPath="url(#clipPath1)" />
            <text x="200.166" y="368.113" fill="#000" stroke="none" clipPath="url(#clipPath1)"># Orders</text>
            <path fill="none" stroke="#00C" strokeLinejoin="bevel" d="M242.455 365.344h14" clipPath="url(#clipPath1)" />
            <path fill="#00C" stroke="none" strokeLinejoin="bevel" d="M252.455 365.344a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00C" d="M252.455 365.344a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath1)" />
            <text x="260.455" y="368.113" fill="#000" stroke="none" clipPath="url(#clipPath1)">Freight/Orders</text>
            <path fill="#C63" stroke="none" d="M324.514 361.344h8v8h-8v-8z" clipPath="url(#clipPath1)" />
            <text x="336.514" y="368.113" fill="#000" stroke="none" clipPath="url(#clipPath1)">Freight</text>
            <path fill="none" stroke="#000" strokeWidth="0.5" d="M60 334L461 334" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" strokeWidth="0.5" d="M126.833 334L126.833 334" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" strokeWidth="0.5" d="M126.833 334L126.833 336" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" strokeWidth="0.5" d="M260.5 334L260.5 334" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" strokeWidth="0.5" d="M260.5 334L260.5 336" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" strokeWidth="0.5" d="M394.167 334L394.167 334" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#000" strokeWidth="0.5" d="M394.167 334L394.167 336" clipPath="url(#clipPath1)" />
            <text x="78.593" y="347.426" fill="#000" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Buenos Aires, Argentina</text>
            <text x="234.531" y="347.426" fill="#000" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Graz, Austria</text>
            <text x="360.034" y="347.426" fill="#000" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">Salzburg, Austria</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M60 4L60 334" clipPath="url(#clipPath1)" />
            <text x="43.82" y="336.769" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$0</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 334L60 334" clipPath="url(#clipPath1)" />
            <text x="33.641" y="311.446" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$500</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 308.676L60 308.676" clipPath="url(#clipPath1)" />
            <text x="26.008" y="286.122" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$1.000</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 283.353L60 283.353" clipPath="url(#clipPath1)" />
            <text x="26.008" y="260.799" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$1.500</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 258.029L60 258.029" clipPath="url(#clipPath1)" />
            <text x="26.008" y="235.475" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$2.000</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 232.706L60 232.706" clipPath="url(#clipPath1)" />
            <text x="26.008" y="210.151" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$2.500</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 207.382L60 207.382" clipPath="url(#clipPath1)" />
            <text x="26.008" y="184.828" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$3.000</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 182.058L60 182.058" clipPath="url(#clipPath1)" />
            <text x="26.008" y="159.504" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$3.500</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 156.735L60 156.735" clipPath="url(#clipPath1)" />
            <text x="26.008" y="134.181" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$4.000</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 131.411L60 131.411" clipPath="url(#clipPath1)" />
            <text x="26.008" y="108.857" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$4.500</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 106.088L60 106.088" clipPath="url(#clipPath1)" />
            <text x="26.008" y="83.534" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$5.000</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 80.764L60 80.764" clipPath="url(#clipPath1)" />
            <text x="26.008" y="58.21" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$5.500</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 55.44L60 55.44" clipPath="url(#clipPath1)" />
            <text x="26.008" y="32.886" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$6.000</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 30.117L60 30.117" clipPath="url(#clipPath1)" />
            <text x="26.008" y="7.563" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">$6.500</text>
            <path fill="none" stroke="#C63" strokeWidth="0.5" d="M58 4.793L60 4.793" clipPath="url(#clipPath1)" />
            <text x="2.191" y="171.769" fill="#C63" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath2)" transform="rotate(-90 16.352 169)">Freight</text>
         </g>
         <g fill="#360" stroke="#360" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M461 4L461 334" clipPath="url(#clipPath1)" />
            <text x="467" y="336.769" stroke="none" clipPath="url(#clipPath1)">0</text>
            <path fill="none" d="M463 334L461 334" clipPath="url(#clipPath1)" />
            <text x="467" y="310.579" stroke="none" clipPath="url(#clipPath1)">2</text>
            <path fill="none" d="M463 307.81L461 307.81" clipPath="url(#clipPath1)" />
            <text x="467" y="284.389" stroke="none" clipPath="url(#clipPath1)">5</text>
            <path fill="none" d="M463 281.619L461 281.619" clipPath="url(#clipPath1)" />
            <text x="467" y="258.198" stroke="none" clipPath="url(#clipPath1)">8</text>
            <path fill="none" d="M463 255.429L461 255.429" clipPath="url(#clipPath1)" />
            <text x="467" y="232.008" stroke="none" clipPath="url(#clipPath1)">10</text>
            <path fill="none" d="M463 229.238L461 229.238" clipPath="url(#clipPath1)" />
            <text x="467" y="205.817" stroke="none" clipPath="url(#clipPath1)">12</text>
            <path fill="none" d="M463 203.048L461 203.048" clipPath="url(#clipPath1)" />
            <text x="467" y="179.627" stroke="none" clipPath="url(#clipPath1)">15</text>
            <path fill="none" d="M463 176.857L461 176.857" clipPath="url(#clipPath1)" />
            <text x="467" y="153.436" stroke="none" clipPath="url(#clipPath1)">18</text>
            <path fill="none" d="M463 150.667L461 150.667" clipPath="url(#clipPath1)" />
            <text x="467" y="127.246" stroke="none" clipPath="url(#clipPath1)">20</text>
            <path fill="none" d="M463 124.476L461 124.476" clipPath="url(#clipPath1)" />
            <text x="467" y="101.055" stroke="none" clipPath="url(#clipPath1)">22</text>
            <path fill="none" d="M463 98.286L461 98.286" clipPath="url(#clipPath1)" />
            <text x="467" y="74.865" stroke="none" clipPath="url(#clipPath1)">25</text>
            <path fill="none" d="M463 72.095L461 72.095" clipPath="url(#clipPath1)" />
            <text x="467" y="48.674" stroke="none" clipPath="url(#clipPath1)">28</text>
            <path fill="none" d="M463 45.905L461 45.905" clipPath="url(#clipPath1)" />
            <text x="467" y="22.484" stroke="none" clipPath="url(#clipPath1)">30</text>
            <path fill="none" d="M463 19.714L461 19.714" clipPath="url(#clipPath1)" />
            <text x="468.691" y="171.769" stroke="none" clipPath="url(#clipPath3)" transform="rotate(90 486.836 169)"># Orders</text>
         </g>
         <g fill="#00C" stroke="#00C" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M494.492 4L494.492 334" clipPath="url(#clipPath1)" />
            <text x="500.492" y="336.769" stroke="none" clipPath="url(#clipPath1)">$0</text>
            <path fill="none" d="M496.492 334L494.492 334" clipPath="url(#clipPath1)" />
            <text x="500.492" y="321.575" stroke="none" clipPath="url(#clipPath1)">$10</text>
            <path fill="none" d="M496.492 318.806L494.492 318.806" clipPath="url(#clipPath1)" />
            <text x="500.492" y="306.381" stroke="none" clipPath="url(#clipPath1)">$20</text>
            <path fill="none" d="M496.492 303.612L494.492 303.612" clipPath="url(#clipPath1)" />
            <text x="500.492" y="291.187" stroke="none" clipPath="url(#clipPath1)">$30</text>
            <path fill="none" d="M496.492 288.418L494.492 288.418" clipPath="url(#clipPath1)" />
            <text x="500.492" y="275.993" stroke="none" clipPath="url(#clipPath1)">$40</text>
            <path fill="none" d="M496.492 273.223L494.492 273.223" clipPath="url(#clipPath1)" />
            <text x="500.492" y="260.799" stroke="none" clipPath="url(#clipPath1)">$50</text>
            <path fill="none" d="M496.492 258.029L494.492 258.029" clipPath="url(#clipPath1)" />
            <text x="500.492" y="245.605" stroke="none" clipPath="url(#clipPath1)">$60</text>
            <path fill="none" d="M496.492 242.835L494.492 242.835" clipPath="url(#clipPath1)" />
            <text x="500.492" y="230.41" stroke="none" clipPath="url(#clipPath1)">$70</text>
            <path fill="none" d="M496.492 227.641L494.492 227.641" clipPath="url(#clipPath1)" />
            <text x="500.492" y="215.216" stroke="none" clipPath="url(#clipPath1)">$80</text>
            <path fill="none" d="M496.492 212.447L494.492 212.447" clipPath="url(#clipPath1)" />
            <text x="500.492" y="200.022" stroke="none" clipPath="url(#clipPath1)">$90</text>
            <path fill="none" d="M496.492 197.252L494.492 197.252" clipPath="url(#clipPath1)" />
            <text x="500.492" y="184.828" stroke="none" clipPath="url(#clipPath1)">$100</text>
            <path fill="none" d="M496.492 182.058L494.492 182.058" clipPath="url(#clipPath1)" />
            <text x="500.492" y="169.634" stroke="none" clipPath="url(#clipPath1)">$110</text>
            <path fill="none" d="M496.492 166.864L494.492 166.864" clipPath="url(#clipPath1)" />
            <text x="500.492" y="154.44" stroke="none" clipPath="url(#clipPath1)">$120</text>
            <path fill="none" d="M496.492 151.67L494.492 151.67" clipPath="url(#clipPath1)" />
            <text x="500.492" y="139.245" stroke="none" clipPath="url(#clipPath1)">$130</text>
            <path fill="none" d="M496.492 136.476L494.492 136.476" clipPath="url(#clipPath1)" />
            <text x="500.492" y="124.051" stroke="none" clipPath="url(#clipPath1)">$140</text>
            <path fill="none" d="M496.492 121.282L494.492 121.282" clipPath="url(#clipPath1)" />
            <text x="500.492" y="108.857" stroke="none" clipPath="url(#clipPath1)">$150</text>
            <path fill="none" d="M496.492 106.088L494.492 106.088" clipPath="url(#clipPath1)" />
            <text x="500.492" y="93.663" stroke="none" clipPath="url(#clipPath1)">$160</text>
            <path fill="none" d="M496.492 90.893L494.492 90.893" clipPath="url(#clipPath1)" />
            <text x="500.492" y="78.469" stroke="none" clipPath="url(#clipPath1)">$170</text>
            <path fill="none" d="M496.492 75.699L494.492 75.699" clipPath="url(#clipPath1)" />
            <text x="500.492" y="63.275" stroke="none" clipPath="url(#clipPath1)">$180</text>
            <path fill="none" d="M496.492 60.505L494.492 60.505" clipPath="url(#clipPath1)" />
            <text x="500.492" y="48.08" stroke="none" clipPath="url(#clipPath1)">$190</text>
            <path fill="none" d="M496.492 45.311L494.492 45.311" clipPath="url(#clipPath1)" />
            <text x="500.492" y="32.886" stroke="none" clipPath="url(#clipPath1)">$200</text>
            <path fill="none" d="M496.492 30.117L494.492 30.117" clipPath="url(#clipPath1)" />
            <text x="500.492" y="17.692" stroke="none" clipPath="url(#clipPath1)">$210</text>
            <path fill="none" d="M496.492 14.923L494.492 14.923" clipPath="url(#clipPath1)" />
            <text x="495.723" y="171.769" stroke="none" clipPath="url(#clipPath4)" transform="rotate(90 530.508 169)">Freight per Order</text>
         </g>
         <g fill="silver" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M60 334L461 334" clipPath="url(#clipPath5)" />
            <path fill="none" d="M60 307.81L461 307.81" clipPath="url(#clipPath5)" />
            <path fill="none" d="M60 281.619L461 281.619" clipPath="url(#clipPath5)" />
            <path fill="none" d="M60 255.429L461 255.429" clipPath="url(#clipPath5)" />
            <path fill="none" d="M60 229.238L461 229.238" clipPath="url(#clipPath5)" />
            <path fill="none" d="M60 203.048L461 203.048" clipPath="url(#clipPath5)" />
            <path fill="none" d="M60 176.857L461 176.857" clipPath="url(#clipPath5)" />
            <path fill="none" d="M60 150.667L461 150.667" clipPath="url(#clipPath5)" />
            <path fill="none" d="M60 124.476L461 124.476" clipPath="url(#clipPath5)" />
            <path fill="none" d="M60 98.286L461 98.286" clipPath="url(#clipPath5)" />
            <path fill="none" d="M60 72.095L461 72.095" clipPath="url(#clipPath5)" />
            <path fill="none" d="M60 45.905L461 45.905" clipPath="url(#clipPath5)" />
            <path fill="none" d="M60 19.714L461 19.714" clipPath="url(#clipPath5)" />
            <path fill="#C63" stroke="none" d="M80.05 303.684H173.617V334H80.05z" clipPath="url(#clipPath5)" />
            <path fill="#C63" stroke="none" d="M213.717 19.714H307.284V334H213.717z" clipPath="url(#clipPath5)" />
            <path fill="#C63" stroke="none" d="M347.383 273.927H440.95V334H347.383z" clipPath="url(#clipPath5)" />
         </g>
         <g fill="#00C" stroke="#00C" strokeLinejoin="bevel" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M126.833 277.157L260.5 19.714" clipPath="url(#clipPath5)" />
            <path fill="none" d="M260.5 19.714L394.167 153.78" clipPath="url(#clipPath5)" />
            <path stroke="none" d="M129.833 277.157a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath5)" />
            <path fill="none" strokeLinejoin="miter" d="M129.833 277.157a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath5)" />
            <path stroke="none" strokeLinejoin="miter" d="M263.5 19.714a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath5)" />
            <path fill="none" strokeLinejoin="miter" d="M263.5 19.714a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath5)" />
            <path stroke="none" strokeLinejoin="miter" d="M397.167 153.78a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath5)" />
            <path fill="none" strokeLinejoin="miter" d="M397.167 153.78a3 3 0 11-6 0 3 3 0 016 0z" clipPath="url(#clipPath5)" />
            <path fill="none" stroke="#360" d="M126.833 166.381L260.5 19.714" clipPath="url(#clipPath5)" />
            <path fill="none" stroke="#360" d="M260.5 19.714L394.167 229.238" clipPath="url(#clipPath5)" />
            <path fill="#360" stroke="none" d="M123.833 163.381h6v6h-6v-6z" clipPath="url(#clipPath5)" />
            <path fill="none" stroke="#360" strokeLinejoin="miter" d="M123.833 163.381h6v6h-6v-6z" clipPath="url(#clipPath5)" />
            <path fill="#360" stroke="none" strokeLinejoin="miter" d="M257.5 16.714h6v6h-6v-6z" clipPath="url(#clipPath5)" />
            <path fill="none" stroke="#360" strokeLinejoin="miter" d="M257.5 16.714h6v6h-6v-6z" clipPath="url(#clipPath5)" />
            <path fill="#360" stroke="none" strokeLinejoin="miter" d="M391.167 226.238h6v6h-6v-6z" clipPath="url(#clipPath5)" />
            <path fill="none" stroke="#360" strokeLinejoin="miter" d="M391.167 226.238h6v6h-6v-6z" clipPath="url(#clipPath5)" />
         </g>
      </svg>
   );
}
export default MultipleAxisChartIcon;