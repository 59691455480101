/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, IconButton, Typography} from "@jss/js-common";
import Paper from "@material-ui/core/Paper";
import i18n from "../../../i18n";


interface IErrorDialogProps {
    message: string;
    details?: string;
}

interface IClosableErrorDialogProps extends IErrorDialogProps {
    onClose: () => void;
}

export const ErrorDialog: React.FunctionComponent<IErrorDialogProps> = ({ message, details }) => {
    return (
        <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Paper elevation={5} style={{ position: "absolute", width: "750px", alignSelf: "flex-start", marginTop: "50px", padding: "10px", whiteSpace: "normal" }}>
                <Typography variant="body4" style={{ paddingBottom: "20px" }}>{i18n.t("components.common.error.dialog.title")}</Typography>
                <Typography variant="body3" style={{ maxHeight: "200px", overflow: "auto", paddingBottom: "20px" }}>{message}</Typography>
                {
                    details && details.length &&
                        <Accordion>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <Typography>{i18n.t("components.common.error.dialog.details")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ maxHeight: "200px", overflow: "auto" }}>
                                <Typography variant="body3">{details}</Typography>
                            </AccordionDetails>
                        </Accordion>
                }
            </Paper>
        </div>
    );
}

export const ClosableErrorDialog: React.FunctionComponent<IClosableErrorDialogProps> = ({ message, details, onClose }) => {
    const closeLabel = i18n.t('materialUi.component.collapsiblePanel.close');
    return (
        <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Paper elevation={5} style={{ position: "relative", minWidth: "300px", maxWidth: "750px", alignSelf: "flex-start", padding: "10px", whiteSpace: "normal" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body4" style={{ paddingBottom: "20px" }}>{i18n.t("components.common.error.dialog.title")}</Typography>
                    <IconButton
                        aria-label={closeLabel}
                        icon="cancel"
                        variant="contained"
                        color="default"
                        onClick={onClose}
                    />
                </div>
                <Typography variant="body3" style={{ maxHeight: "200px", overflow: "auto", paddingBottom: "20px" }}>{message}</Typography>
                {
                    details && details.length &&
                    <Accordion>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>{i18n.t("components.common.error.dialog.details")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ maxHeight: "200px", overflow: "auto" }}>
                            <Typography variant="body3">{details}</Typography>
                        </AccordionDetails>
                    </Accordion>
                }
            </Paper>
        </div>
    );
}
