/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const CrosstabColumnPositionEnum = new PEnum({
    id: 'position', label: 'CrosstabColumnPosition.label',  
    items: [
        { key: 'Left', value: 'CrosstabColumnPosition.Left' },
        { key: 'Center', value: 'CrosstabColumnPosition.Center' },
        { key: 'Right', value: 'CrosstabColumnPosition.Right' },
        { key: 'Stretch', value: 'CrosstabColumnPosition.Stretch' }
    ]
});

