/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { EditableTable, Icon, TextInput } from '@jss/js-common';
import { ResourcePickerDialog, RunContext } from '@jss/js-repository';
import { getRealName, IRepositoryItemDescriptor, MIME_TYPES, RepositoryApi } from '@jss/js-rest-api';
import { IconButton, InputAdornment } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as React from 'react';
import '../../assets/css/DataAdapterTypePicker.css';
import i18n from '../../i18n';
import DeleteIcon from '@material-ui/icons/Delete';
import { advancedNameValidator } from '@jss/js-common/src/utils/validators';

interface IHibernateDataAdapterEditor {
    name?: string,
    xMLFileName?: string,
    propertiesFileName?: string,
    useAnnotation?: boolean,
    classpaths?: string[],
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

export class HibernateDataAdapterEditor extends React.Component<IHibernateDataAdapterEditor> {

    private getColumnNames = () => {
        if (this.props.classpaths) {
            if (Array.isArray(this.props.classpaths)) {
                return this.props.classpaths;
            } else {
                return [this.props.classpaths];
            }
        }
        return [];
    }

    private getRows = () => {
        const result: string[][] = [];
        const rows = [...this.getColumnNames()];
        rows.forEach((columnName: string) => {
            result.push([columnName]);
        });
        return result;
    }

    private onCellChange = (row: number, col: number, value: string, isEditing: boolean) => {
        const rows = this.props.classpaths ? [...this.props.classpaths] : [];
        if (value.trim().length === 0 && !isEditing) {
            rows.splice(row, 1);
        } else {
            rows[row] = value;
        }
        if (!isEditing) {
            this.notifyChange({ classpaths: rows });
        }
    }

    private onRowDelete = (rowIndex: number) => {
        const newColumns = [...this.props.classpaths];
        newColumns.splice(rowIndex, 1);
        this.notifyChange({ classpaths: newColumns });
    }

    public openClasspathBrowserDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = [];
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('datasource.common.dialog.resource'),
                mode: 'open',
                title: i18n.t('datasource.common.dialog.title'),
                onFileSelected: this.confirmClasspathFile,
                defaultPath: defaultPath,
                allowSearch: true,
                allowedMimes: [MIME_TYPES.JAR, MIME_TYPES.ZIP, 'jar', 'zip'],
            }
        );
    }

    private confirmClasspathFile = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => {
        const path = folderPath.length > 0 ? `${RepositoryApi.inst().getParentPath(folderPath)}/${getRealName(file)}` : `/${getRealName(file)}`;
        const newFiles = [...this.props.classpaths, path];
        this.notifyChange({ classpaths: newFiles });
    }

    getSearchIcon = (setter: (path: string) => void, mime: string[]) => {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 2.5 }}>
            <Icon icon="search" onClick={() => this.openBrowseDialog(setter, mime)} />
        </div>;
    }

    public openBrowseDialog = (setter: (path: string) => void, mime: string[]) => {
        const defaultPath: IRepositoryItemDescriptor[] = [];
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('datasource.common.dialog.resource'),
                mode: 'open',
                title: i18n.t('datasource.common.dialog.title'),
                onFileSelected: (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => this.confirmFile(setter, folderPath, file),
                defaultPath: defaultPath,
                allowSearch: true,
                allowedMimes: mime,
            }
        );
    }

    private confirmFile = (setter: (path: string) => void, folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => {
        const path = folderPath.length > 0 ? `${RepositoryApi.inst().getParentPath(folderPath)}/${getRealName(file)}` : `/${getRealName(file)}`;
        setter(path);
    }

    public render() {
        const tableRows = this.getRows();
        const xMLFileNameAdornament = <InputAdornment position="end">
            <IconButton
                edge="end"
                style={{ padding: 0 }}
            >
                {this.getSearchIcon(this.xMLFileNameChange, ['cfg', 'xml'])}
            </IconButton>
        </InputAdornment>;

        const propertiesFileNameAdornament = <InputAdornment position="end">
            <IconButton
                edge="end"
                style={{ padding: 0 }}
            >
                {this.getSearchIcon(this.propertiesFileNameChange, ['properties'])}
            </IconButton>
        </InputAdornment>;

        return (<div className="tc-jsw-dataadapter-editor">

            <div className="tc-jsw-form-wrapper" style={{ flex: 1, alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}>

                <TextInput label={i18n.t('datasource.csv.name')}
                    help={i18n.t('datasource.hibernate.name.help')}
                    id="data-adapter-name"
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly,
                    }} />

                <TextInput label={i18n.t('datasource.hibernate.xmlFileName')}
                    id="xml-filename-input"
                    value={this.props.xMLFileName}
                    onChange={this.xMLFileNameChange}
                    InputProps={{
                        endAdornment: !this.props.readOnly ? xMLFileNameAdornament : undefined,
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.hibernate.propertiesFileName')}
                    id="properties-filename-input"
                    value={this.props.propertiesFileName}
                    onChange={this.propertiesFileNameChange}
                    InputProps={{
                        endAdornment: !this.props.readOnly ? propertiesFileNameAdornament : undefined,
                        readOnly: this.props.readOnly
                    }} />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.props.useAnnotation}
                            onChange={this.onUseAnnotationChange}
                            name="useAnnotation"
                            color="primary"
                            disabled={this.props.readOnly}
                        />
                    }
                    label={i18n.t('datasource.hibernate.useAnnotation')}
                />
            </div>

            <div style={{ display: 'flex', alignItems: 'stretch' }} id="tableContainer">
                <EditableTable cellStyle={{ textAlign: 'left' }} onCellChange={this.onCellChange} cellData={tableRows} numRows={this.props.classpaths ? this.props.classpaths.length + 1 : 1} columnNames={[i18n.t('datasource.bean.classpaths'), '']}
                    columnActions={[{ onClick: this.onRowDelete, icon: <DeleteIcon /> }]} onAddAction={this.openClasspathBrowserDialog} readOnly={this.props.readOnly} />
            </div>
        </div>);
    }

    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private xMLFileNameChange = (str: string) => {
        this.notifyChange({ xMLFileName: str });
    }

    private propertiesFileNameChange = (str: string) => {
        this.notifyChange({ propertiesFileName: str });
    }

    private onUseAnnotationChange = (event: React.FormEvent<HTMLElement>) => {
        this.notifyChange({ useAnnotation: (event.target as HTMLInputElement).checked });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }
}

HibernateDataAdapterEditor.contextType = RunContext;