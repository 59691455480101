/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { PEnum } from "../../ui/primitive/UPEnum";

export const PdfPrintScalingEnum = new PEnum({
    id: 'pdfPrintScalingEnum', label: 'PdfPrintScalingEnum.label', default: 'none',
    items: [{ key: '', value: '' },
    { key: 'none', value: 'PdfPrintScalingEnum.None' },
    { key: 'default', value: 'PdfPrintScalingEnum.Default' } 
    ]
});

