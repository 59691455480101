/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const BarcodeEAN8Icon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    const fillColor = props.color ? props.color : 'black';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24.6914mm" viewBox="0 0 28.71 24.6914" width="28.71mm" {...props}><g fill={fillColor} stroke="none"><rect height="22.9277" width="0.33" x="3.3" y="0"/><rect height="22.9277" width="0.33" x="3.96" y="0"/><rect height="21.164" width="0.66" x="5.28" y="0"/><rect height="21.164" width="0.33" x="6.27" y="0"/><rect height="21.164" width="0.66" x="7.26" y="0"/><rect height="21.164" width="0.33" x="8.58" y="0"/><rect height="21.164" width="0.33" x="9.57" y="0"/><rect height="21.164" width="0.66" x="10.56" y="0"/><rect height="21.164" width="1.32" x="11.55" y="0"/><rect height="21.164" width="0.33" x="13.2" y="0"/><text fontFamily="DejaVu Sans" fontSize="3.5273" textAnchor="middle" textLength="8.58" x="8.91" y="24.4425">0123</text><rect height="22.9277" width="0.33" x="13.86" y="0"/><rect height="22.9277" width="0.33" x="14.52" y="0"/><rect height="21.164" width="0.33" x="15.18" y="0"/><rect height="21.164" width="0.99" x="15.84" y="0"/><rect height="21.164" width="0.33" x="17.49" y="0"/><rect height="21.164" width="0.99" x="18.48" y="0"/><rect height="21.164" width="0.33" x="19.8" y="0"/><rect height="21.164" width="0.33" x="20.46" y="0"/><rect height="21.164" width="0.33" x="22.11" y="0"/><rect height="21.164" width="0.99" x="23.1" y="0"/><text fontFamily="DejaVu Sans" fontSize="3.5273" textAnchor="middle" textLength="8.58" x="19.8" y="24.4425">4565</text><rect height="22.9277" width="0.33" x="24.42" y="0"/><rect height="22.9277" width="0.33" x="25.08" y="0"/></g></svg>
    );
}
export default BarcodeEAN8Icon;