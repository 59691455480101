/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

// report designer
import { debugSagas } from './report/designer/debugSaga';
import { modelSagas as rdModelSagas } from './report/designer/modelSaga';
import { mouseHandlerSagas as rdMouseHandlerSagas } from './report/designer/mouseHandlerSaga';
import { paletteSagas as rdPaletteSagas } from './report/designer/paletteSaga';
import { undoRedoSagas as rdRedoSagas } from './report/designer/undoRedoSaga';

export const sagas = [
     ...rdModelSagas,
     ...rdMouseHandlerSagas,
     ...rdPaletteSagas,
     ...rdRedoSagas,
     ...debugSagas,
    ];