/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { CircularProgress } from '@material-ui/core';
import * as React from 'react';

interface ISpinner {
    message?: string | React.ReactNode;
}

export class Spinner extends React.Component<ISpinner> {

    public render() {
        return (
            <div className="tc-loading-spinner">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <CircularProgress />
                    <div className="loading-text">{this.props.message}</div>
                </div>
            </div>
        );
    }
}
