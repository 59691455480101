/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import InternalJRIOContextEditor from './InternalJRIOContextEditor';
import { CommandRegistry, IEditor, IResourceListenerManager } from '@jss/js-common';

interface IJRIOContextEditor {
    resourceListenerManagers: IResourceListenerManager[],
    commandRegistry: CommandRegistry,
    language?: string,
    onExit?: () => void;
}


export class JRIOContextEditor extends React.Component<IJRIOContextEditor> implements IEditor {

    private editorRef: React.RefObject<InternalJRIOContextEditor> = React.createRef<InternalJRIOContextEditor>();

    getEditorContent = () => {
        return this.editorRef.current.getEditorContent();
    }

    public render() {
        return <InternalJRIOContextEditor ref={this.editorRef} {...this.props}/>;
    }

}

export default JRIOContextEditor;
