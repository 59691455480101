/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import { NameInputPanel } from '@jss/js-common';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { MIME_TYPES, RepositoryApi, RESOURCE_TYPE } from '@jss/js-rest-api';
import { AxiosResponse } from 'axios';
import { RunContext } from '../../RunContainer';
import i18n from "../../i18n";
import { advancedNotEmptyFolderNameValidator } from '@jss/js-common/src/utils/validators';


export interface ICreateFolderProps {
    path: string,
    close: () => void;
    refresh: () => void;
    type: string,
}

export class CreateFolder extends React.Component<ICreateFolderProps>{

    public render() {
        if (this.props.type === 'organization') {
            return <NameInputPanel advancedValidator={advancedNotEmptyFolderNameValidator} leftIcon={<CreateNewFolderIcon />} title={i18n.t('newresources.resources.newOrganizationTitle')} initialName={i18n.t('newresources.resources.organizationPlaceholder')} onNameCancel={this.props.close} onNameConfirm={this.onNewOrganizationConfirm} />;
        } else if (this.props.type === 'repository') {
            return <NameInputPanel advancedValidator={advancedNotEmptyFolderNameValidator} leftIcon={<CreateNewFolderIcon />} title={i18n.t('newresources.resources.newRepositoryTitle')} initialName={i18n.t('newresources.resources.repositoryPlaceholder')} onNameCancel={this.props.close} onNameConfirm={this.onNewRepositoryConfirm} />;
        } else if (this.props.type === 'user') {
            return <NameInputPanel advancedValidator={advancedNotEmptyFolderNameValidator} leftIcon={<CreateNewFolderIcon />} title={i18n.t('newresources.resources.newUserTitle')} initialName={i18n.t('newresources.resources.userPlaceholder')} onNameCancel={this.props.close} onNameConfirm={this.onNewUserConfirm} />;
        }
        return <NameInputPanel advancedValidator={advancedNotEmptyFolderNameValidator} leftIcon={<CreateNewFolderIcon />} title={i18n.t('newresources.resources.newFolderTitle')} initialName={i18n.t('newresources.resources.folderPlaceholder')} onNameCancel={this.props.close} onNameConfirm={this.onNewFolderConfirm} />;

    }

    private onNewFolderConfirm = (value: string) => {
        this.context.runNoCancel(RepositoryApi.inst().save)('/', {
            name: value,
            type: RESOURCE_TYPE.FOLDER,
            mime: MIME_TYPES.FOLDER,
            path: this.props.path
        }).then((response: AxiosResponse) => {
            if (response.data) {
                this.props.close();
                this.props.refresh();
            }
        }).catch(error => console.log(error));
    }

    private onNewOrganizationConfirm = (value: string) => {
        this.context.runNoCancel(RepositoryApi.inst().save)('/', {
            name: value,
            type: RESOURCE_TYPE.CONTAINER,
            mime: MIME_TYPES.OWNER_ORGANIZATION,
            path: this.props.path
        }).then((response: AxiosResponse) => {
            if (response.data) {
                this.props.close();
                this.props.refresh();
            }
        }).catch(error => console.log(error));
    }

    private onNewRepositoryConfirm = (value: string) => {
        this.context.runNoCancel(RepositoryApi.inst().save)('/', {
            name: value,
            type: RESOURCE_TYPE.CONTAINER,
            mime: MIME_TYPES.REPOSITORY,
            path: this.props.path
        }).then((response: AxiosResponse) => {
            if (response.data) {
                this.props.close();
                this.props.refresh();
            }
        }).catch(error => console.log(error));
    }

    private onNewUserConfirm = (value: string) => {
        this.context.runNoCancel(RepositoryApi.inst().save)('/', {
            name: value,
            type: RESOURCE_TYPE.CONTAINER,
            mime: MIME_TYPES.OWNER_USER,
            path: this.props.path
        }).then((response: AxiosResponse) => {
            if (response.data) {
                this.props.close();
                this.props.refresh();
            }
        }).catch(error => console.log(error));
    }

}

CreateFolder.contextType = RunContext;