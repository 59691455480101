/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map, OrderedMap } from 'immutable';
import { uuidv4 } from '../../../../utils/uuidGenerator';
import { initializeMapWithObject } from '../../../../sagas/report/document/documentFactory';
import { BandTypes, ElementTypes } from '../../../../sagas/report/document/elementTypes';

export interface IObjectCounter {
    objectID: number;
    detailID: number;
    groupIDs: Map<string, { footer: number, header: number } | null>,
    uuidsMap: Set<string>,
    addUuid(uuid: string): void;
    uniqueID(str: string): string;
    uniqueDetailId(): number;
    uniqueGroupBandId(groupName: string, isHeader: boolean): number;
}

export const getNamespace = (node: Element): string => {
    let prefix = node.prefix;
    if (prefix === undefined || prefix === null) {
        prefix = "";
    }
    return prefix;
}

export const readCDataText = (nodeElement: Node): string => {
    const childNodes = nodeElement.childNodes;
    let cdataNode: Element | undefined;
    for (let i = 0; i < childNodes.length && !cdataNode; i++) {
        if (childNodes[i].nodeType === Node.CDATA_SECTION_NODE) {
            cdataNode = childNodes[i] as Element;
        }
    }
    //return the content of cdata of if not available fallback to the text content
    return cdataNode ? cdataNode.textContent : nodeElement.textContent;
}

export const addChildToParent2 = (parentElement: Map<string, any>, document: Map<string, any>, childId: string): Map<string, any> => {
    return addChildToParent(parentElement.get("id"), parentElement.get("type"), document, childId);
}

export const addChildToParent = (parentElementId: string, parentElementType: string, document: Map<string, any>, childId: string): Map<string, any> => {
    if (parentElementType === ElementTypes.BAND) {
        return document.updateIn(["bands", parentElementId, "elementIds"], list => (list as List<string> || List<string>()).push(childId));
    } else if (parentElementType === ElementTypes.SECTION_DETAIL || parentElementType === ElementTypes.SECTION_GROUP) {
        return document.updateIn(["bookSections", parentElementId, "elementIds"], list => (list as List<string> || List<string>()).push(childId));
    } else {
        return document.updateIn(["elements", parentElementId, "elementIds"], list => (list as List<string> || List<string>()).push(childId));
    }
}

export const generatePath = (parentElement: Map<string, any> | null) => {
    let path = "";
    if (parentElement !== null) {
        const parentElementType = parentElement.get("type");
        if (parentElementType === ElementTypes.BAND) {
            path = "bands/" + parentElement.get("id");
        } else if (parentElementType === ElementTypes.SECTION_DETAIL || parentElementType === ElementTypes.SECTION_GROUP) {
            path = "bookSections/" + parentElement.get("id");
        } else {
            path = "elements/" + parentElement.get("id");
        }
    }
    return path;
}

export const getChildNodeByName = (node: Node, elementName: string): Node | null => {
    let result = null;
    if (node != null) {
        const childList = node.childNodes;
        for (let i = 0; i < childList.length && result === null; i++) {
            const child = childList[i];
            if (child.nodeName === elementName) {
                result = child;
            }
        }
    }
    return result;
}

export const addAttributeToMapWithName = (node: Element, attributeName: string, mapName: string, element: Map<string, any>): Map<string, any> => {
    const attributeValue = node.getAttribute(attributeName);
    if (attributeValue !== null) {
        return element.set(mapName, attributeValue);
    } else {
        return element;
    }
}

export const addAttributeToMap = (node: Element, attributeName: string, element: Map<string, any>): Map<string, any> => {
    return addAttributeToMapWithName(node, attributeName, attributeName, element);
}

export const addBooleanAttributeToMap = (node: Element, attributeName: string, element: Map<string, any>): Map<string, any> => {
    const attributeValue = node.getAttribute(attributeName);
    if (attributeValue !== null) {
        return element.set(attributeName, attributeValue.toLowerCase() === 'true');
    } else {
        return element;
    }
}

export const addIntAttributeToMap = (node: Element, attributeName: string, element: Map<string, any>): Map<string, any> => {
    const attributeValue = node.getAttribute(attributeName);
    if (attributeValue !== null) {
        return element.set(attributeName, parseInt(attributeValue, 10));
    } else {
        return element;
    }
}

export const addFloatAttributeToMap = (node: Element, attributeName: string, element: Map<string, any>): Map<string, any> => {
    const attributeValue = node.getAttribute(attributeName);
    if (attributeValue !== null) {
        return element.set(attributeName, parseFloat(attributeValue));
    } else {
        return element;
    }
}

export const addChildValueToMap = (node: Element, childName: string, element: Map<string, any>): Map<string, any> => {
    return addChildValueToMapWithName(node, childName, childName, element);
}

export const addChildValueWithPrefixToMap = (node: Element, childName: string, prefix: string, element: Map<string, any>): Map<string, any> => {
    return addChildValueToMapWithName(node, prefix + childName, childName, element);
}

export const addChildValueToMapWithName = (node: Element, childName: string, mapName: string, element: Map<string, any>): Map<string, any> => {
    const childNode = getChildNodeByName(node, childName);
    if (childNode !== null) {
        //search for a cdata
        const textContent = readCDataText(childNode);
        return element.set(mapName, textContent);
    } else {
        return element;
    }
}

export const createPen = (penNode: Element): Map<string, any> => {
    let result: Map<string, any> = Map<string, any>();
    result = addFloatAttributeToMap(penNode, "lineWidth", result);
    result = addAttributeToMap(penNode, "lineStyle", result);
    result = addAttributeToMap(penNode, "lineColor", result);
    return result;
}

export const createPadding = (paddingNode: Element): Map<string, any> => {
    let result: Map<string, any> = Map<string, any>();
    const padding = paddingNode.getAttribute("padding");
    if (padding !== null) {
        result = result.set("padding", parseInt(padding as string, 10));
    } else {
        result = addIntAttributeToMap(paddingNode, "topPadding", result);
        result = addIntAttributeToMap(paddingNode, "leftPadding", result);
        result = addIntAttributeToMap(paddingNode, "bottomPadding", result);
        result = addIntAttributeToMap(paddingNode, "rightPadding", result);
    }
    return result;
}

export const createBox = (boxNode: Element): Map<string, any> => {
    let result: Map<string, any> = createPadding(boxNode);
    const penNode: Node | null = getChildNodeByName(boxNode, "pen");
    if (penNode !== null) {
        result = result.set("pen", createPen(penNode as Element));
    }

    const topPenNode: Node | null = getChildNodeByName(boxNode, "topPen");
    if (topPenNode !== null) {
        result = result.set("topPen", createPen(topPenNode as Element));
    }

    const leftPenNode: Node | null = getChildNodeByName(boxNode, "leftPen");
    if (leftPenNode !== null) {
        result = result.set("leftPen", createPen(leftPenNode as Element));
    }

    const bottomPenNode: Node | null = getChildNodeByName(boxNode, "bottomPen");
    if (bottomPenNode !== null) {
        result = result.set("bottomPen", createPen(bottomPenNode as Element));
    }

    const rightPenNode: Node | null = getChildNodeByName(boxNode, "rightPen");
    if (rightPenNode !== null) {
        result = result.set("rightPen", createPen(rightPenNode as Element));
    }
    return result;
}

export const createDatasetRun = (datasetRunNodeNode: Element, objectCounter: IObjectCounter): Map<string, any> => {
    let result: Map<string, any> = initializeMapWithObject({
        datasetParameters: List<{ name: string, expression: string }>(),
        returnValues: List<Map<string, string>>(),
        properties: OrderedMap<string, string | null>()
    });
    result = addAttributeToMap(datasetRunNodeNode, "subDataset", result);
    result = addChildValueToMap(datasetRunNodeNode, "parametersMapExpression", result);
    result = addChildValueToMap(datasetRunNodeNode, "connectionExpression", result);
    result = addChildValueToMap(datasetRunNodeNode, "dataSourceExpression", result);

    let uuid = datasetRunNodeNode.getAttribute('uuid');
    if (uuid === null){
        while(uuid === null){
            const newUuid = uuidv4();
            if (!objectCounter.uuidsMap.has(newUuid)){
                uuid = newUuid;
            }
        }
    }
    objectCounter.addUuid(uuid);
    result = result.set('uuid', uuid)

    datasetRunNodeNode.childNodes.forEach(element => {
        const elementName = element.nodeName;
        if (elementName === "datasetParameter") {
            const parameterName = (element as Element).getAttribute("name");
            const expressionValue = readCDataText(getChildNodeByName(element, "datasetParameterExpression"));
            const newParameter = { name: parameterName, expression: expressionValue };
            result = result.updateIn(["datasetParameters"], list => (list as List<{ name: string, expression: string }> || List<{ name: string, expression: string }>()).push(newParameter));
        } else if (elementName === "returnValue") {
            let returnValue: Map<string, string> = initializeMapWithObject({
                id: objectCounter.uniqueID("returnValue-"),
            });
            returnValue = addAttributeToMap(element as Element, "fromVariable", returnValue);
            returnValue = addAttributeToMap(element as Element, "toVariable", returnValue);
            returnValue = addAttributeToMap(element as Element, "calculation", returnValue);
            returnValue = addAttributeToMap(element as Element, "incrementerFactoryClass", returnValue);
            
            result = result.updateIn(["returnValues"], list => (list as List<Map<string, any>> || List<Map<string, string>>()).push(returnValue));
        } else if (elementName === "property") {
            const childElement: Element = element as Element;
            const attName = childElement.getAttribute("name");
            const attValue = getPropertyValue(childElement);
            result = result.updateIn(["properties"], (propMap: OrderedMap<string, string | null>) => (propMap || OrderedMap<string, string | null>()).set(attName, attValue));
        }

    });
    return result;
}

export const createChartCrosstabDataset = (datasetNode: Element, objectCounter: IObjectCounter): Map<string, any> => {
    let result: Map<string, any> = Map<string, any>();
    result = addAttributeToMap(datasetNode, "resetType", result);
    result = addAttributeToMap(datasetNode, "resetGroup", result);
    result = addAttributeToMap(datasetNode, "incrementType", result);
    result = addAttributeToMap(datasetNode, "incrementGroup", result);
    result = addChildValueToMap(datasetNode, "incrementWhenExpression", result);

    const datasetRunNode = getChildNodeByName(datasetNode, "datasetRun");
    if (datasetRunNode !== null) {
        const datasetRun: Map<string, any> = createDatasetRun(datasetRunNode as Element, objectCounter);
        if (!datasetRun.isEmpty()) {
            result = result.set("datasetRun", datasetRun);
        }
    }
    return result;
}

export const createReportFont = (fontNode: Element): Map<string, any> => {
    let result: Map<string, any> = Map<string, any>();

    result = addAttributeToMap(fontNode, "name", result);
    result = addAttributeToMap(fontNode, "fontName", result);
    result = addFloatAttributeToMap(fontNode, "size", result);
    result = addBooleanAttributeToMap(fontNode, "isBold", result);
    result = addBooleanAttributeToMap(fontNode, "isItalic", result);
    result = addBooleanAttributeToMap(fontNode, "isUnderline", result);
    result = addBooleanAttributeToMap(fontNode, "isStrikeThrough", result);
    result = addBooleanAttributeToMap(fontNode, "isDefault", result);

    result = addAttributeToMap(fontNode, "pdfFontName", result);
    result = addAttributeToMap(fontNode, "pdfEncoding", result);
    result = addBooleanAttributeToMap(fontNode, "isPdfEmbedded", result);

    return result;
}

export const createFont = (fontNode: Element): Map<string, any> => {
    let result: Map<string, any> = Map<string, any>();

    result = addAttributeToMap(fontNode, "reportFont", result);
    result = addAttributeToMap(fontNode, "fontName", result);
    result = addFloatAttributeToMap(fontNode, "size", result);
    result = addBooleanAttributeToMap(fontNode, "isBold", result);
    result = addBooleanAttributeToMap(fontNode, "isItalic", result);
    result = addBooleanAttributeToMap(fontNode, "isUnderline", result);
    result = addBooleanAttributeToMap(fontNode, "isStrikeThrough", result);

    result = addAttributeToMap(fontNode, "pdfFontName", result);
    result = addAttributeToMap(fontNode, "pdfEncoding", result);
    result = addBooleanAttributeToMap(fontNode, "isPdfEmbedded", result);

    return result;
}

export const createHyperLinkForElement = (elementNode: Element, currentElementProperties: Map<string, any>): Map<string, any> => {
    let result: Map<string, any> = addAttributeToMap(elementNode, "hyperlinkType", currentElementProperties);
    result = addAttributeToMap(elementNode, "hyperlinkTarget", result);
    result = addIntAttributeToMap(elementNode, "bookmarkLevel", result);
    result = addChildValueToMap(elementNode, "anchorNameExpression", result);
    result = addChildValueToMap(elementNode, "bookmarkLevelExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkReferenceExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkWhenExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkTooltipExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkPageExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkAnchorExpression", result);
    let hyperLinkParameters: List<Map<string, any>> = List<Map<string, any>>();
    elementNode.childNodes.forEach(element => {
        if (element.nodeName === "hyperlinkParameter") {
            const parameterNode = element as Element;
            const parameterName = parameterNode.getAttribute("name");
            const parameterValue = readCDataText(getChildNodeByName(parameterNode, "hyperlinkParameterExpression"));
            let parameterMap = Map<string, any>();
            parameterMap = parameterMap.set('name', parameterName);
            parameterMap = parameterMap.set('valueExpression', parameterValue);
            hyperLinkParameters = hyperLinkParameters.push(parameterMap);
        }
    });
    if (!hyperLinkParameters.isEmpty()) {
        result = result.set("hyperlinkParameters", hyperLinkParameters);
    }
    return result;
}

export const getPropertyValue = (propertyNode: Element) => {
    let attValue = propertyNode.getAttribute("value");
    if (!attValue){
        attValue = readCDataText(propertyNode);
    }
    return attValue;
}

export const createReportElement = (reportElementNode: Element, pathValue: string, typeValue: string, objectCounter: IObjectCounter) => {

    let element = initializeMapWithObject({
        id: objectCounter.uniqueID("ele-"),
        path: pathValue,
        type: typeValue,
        selected: false,
        highlighted: false,
        properties: OrderedMap<string, Map<string, any>>(),
    });

    element = addIntAttributeToMap(reportElementNode, "x", element);
    element = addIntAttributeToMap(reportElementNode, "y", element);
    element = addIntAttributeToMap(reportElementNode, "width", element);
    element = addIntAttributeToMap(reportElementNode, "height", element);

    let uuid = reportElementNode.getAttribute('uuid');
    if (uuid === null){
        while(uuid === null){
            const newUuid = uuidv4();
            if (!objectCounter.uuidsMap.has(newUuid)){
                uuid = newUuid;
            }
        }
    }
    objectCounter.addUuid(uuid);
    element = element.set('uuid', uuid)

    element = addAttributeToMap(reportElementNode, "key", element);
    element = addAttributeToMap(reportElementNode, "style", element);
    element = addAttributeToMap(reportElementNode, "mode", element);
    element = addAttributeToMap(reportElementNode, "forecolor", element);
    element = addAttributeToMap(reportElementNode, "backcolor", element);
    element = addAttributeToMap(reportElementNode, "positionType", element);
    element = addAttributeToMap(reportElementNode, "stretchType", element);
    element = addAttributeToMap(reportElementNode, "printWhenGroupChanges", element);

    element = addBooleanAttributeToMap(reportElementNode, "isPrintRepeatedValues", element);
    element = addBooleanAttributeToMap(reportElementNode, "isRemoveLineWhenBlank", element);
    element = addBooleanAttributeToMap(reportElementNode, "isPrintInFirstWholeBand", element);
    element = addBooleanAttributeToMap(reportElementNode, "isPrintWhenDetailOverflows", element);

    reportElementNode.childNodes.forEach(child => {
        if (child.nodeName === "property") {
            const childElement: Element = child as Element;
            const attName = childElement.getAttribute("name");
            const attValue = getPropertyValue(childElement);
            let oldValue = element.getIn(["properties", attName], Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('value', attValue);
            element = element.setIn(["properties", attName], oldValue);
        } else if (child.nodeName === "propertyExpression") {
            const childElement: Element = child as Element;
            const attName = childElement.getAttribute("name");
            const attValue = readCDataText(childElement);
            let oldValue = element.getIn(["properties", attName], Map<string, any>()) as Map<string, any>;
            oldValue = oldValue.set('valueExpression', attValue);
            const attType = childElement.getAttribute("type");
            if (attType !== null) {
                oldValue = oldValue.set('type', attType);
            }
            const attEvaluationTime = childElement.getAttribute("evaluationTime");
            if (attEvaluationTime !== null) {
                oldValue = oldValue.set('evaluationTime', attEvaluationTime);
            }
            element = element.setIn(["properties", attName], oldValue);
        } else if (child.nodeName === "printWhenExpression") {
            element = element.set("printWhenExpression", readCDataText(child));
        }
    });
    return element;
}

export const getBandTypeFromJRType = (jrType: string) => {
    if (jrType === 'detail') {
        return BandTypes.BAND_DETAIL;
    } else if (jrType === 'background') {
        return BandTypes.BAND_BACKGROUND;
    } else if (jrType === 'title') {
        return BandTypes.BAND_TITLE;
    } else if (jrType === 'pageHeader') {
        return BandTypes.BAND_PAGE_HEADER;
    } else if (jrType === 'columnHeader') {
        return BandTypes.BAND_COLUMN_HEADER;
    } else if (jrType === 'columnFooter') {
        return BandTypes.BAND_COLUMN_FOOTER;
    } else if (jrType === 'pageFooter') {
        return BandTypes.BAND_PAGE_FOOTER;
    } else if (jrType === 'lastPageFooter') {
        return BandTypes.BAND_LAST_PAGE_FOOTER;
    } else if (jrType === 'summary') {
        return BandTypes.BAND_SUMMARY;
    } else if (jrType === 'noData') {
        return BandTypes.BAND_NO_DATA;
    }

    return BandTypes.BAND_DETAIL;
}