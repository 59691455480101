/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import CrosstabPage from './crosstab/CrosstabPage';
import SubeditorDesigner from './SubeditorDesigner';

class CrosstabDesigner extends React.Component {


  public render() {
    return <SubeditorDesigner getPage={() => {return <CrosstabPage/>}}/>
  }

}

export default CrosstabDesigner;
