/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const TibcoMapIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/palette/tibco-maps" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M14.6445906,3.11082295 C16.3837789,4.48433864 17.5,6.61181561 17.5,9 C17.5,13.1421356 14.1421356,16.5 10,16.5 C8.58328412,16.5 7.2583134,16.1071922 6.12805892,15.4245478 C7.04059435,14.596148 7.87213089,13.7533865 8.62508212,12.8968607 C9.86869278,11.4821811 10.6306816,10.4055218 11.6270963,8.85529573 C12.6235109,7.30506969 12.645398,7.2821848 13.5308438,5.53980501 C14.0138143,4.58941603 14.3750974,3.80671185 14.6146931,3.19169247 Z M13.7347632,2.49457633 C13.9689423,2.62930874 14.1951597,2.77632221 14.4125362,2.93473752 C13.9831167,3.78547434 13.4969571,4.65421131 12.9559537,5.53980501 C12.059745,7.00685086 10.9997864,8.35146562 10.2162686,9.27608696 C9.43275086,10.2007083 8.20269892,11.4555633 6.66530429,12.744578 C5.79471533,13.4745154 5.1334172,13.988316 4.68140991,14.2859798 C4.22509015,13.8289091 3.82827932,13.3119403 3.50260693,12.7486867 C5.06808152,11.9068753 6.3463844,11.0655459 7.33917236,10.2245584 C8.22191312,9.4767916 9.14970164,8.6498318 10.2057495,7.45295198 C10.9844273,6.57043166 11.9296737,5.3556102 12.4802736,4.49739078 C13.1049127,3.52376676 13.5124618,2.87466078 13.7029211,2.55007286 Z M10,1.5 C11.2673278,1.5 12.4612389,1.81433481 13.5080218,2.36929316 C13.0758998,3.01368856 12.6025339,3.66011827 12.0891724,4.30921419 C11.2713013,5.34333284 9.83612061,6.91230256 7.33917236,8.79358919 C5.6745402,10.0477803 4.14481608,10.8694559 2.75,11.258616 L2.83802167,11.2329583 C2.61835036,10.527634 2.5,9.7776381 2.5,9 C2.5,4.85786438 5.85786438,1.5 10,1.5 Z" id="Combined-Shape" fill="#0081CB"></path>
            </g>
        </svg>
    );
}
export default TibcoMapIcon;