/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IConfigStore } from "./IConfigStore";

export class CloudConfigStore implements IConfigStore {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public init(cfg: any) {
		// Empty
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public set(key: string, value: any): boolean {
		// here should set value to the current server if available
		return false;
	}
}