/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import AceEditor from 'react-ace';

export interface IAceEditorWrapperProps {
    onValueChange: (value: string) => void;
    value: string | undefined;
    readonly: boolean | undefined,
    aceProps: any,
}

export const AceEditorWrapper : React.FunctionComponent<IAceEditorWrapperProps> = (props: IAceEditorWrapperProps) => {
    const editor = React.useRef<AceEditor>(null);


  const onAceEditorInit = (editor) => {
    editor.on('focus', (_arg, activeEditor) => {
        activeEditor.renderer.updateFontSize();

    });
    setTimeout(() => {
        editor.renderer.updateFontSize();
    }, 250)
  };

    return <AceEditor ref={editor} {...props.aceProps} onLoad={onAceEditorInit} value={props.value} onChange={props.onValueChange} readOnly={props.readonly} focus={true} />;
}
