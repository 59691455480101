/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { FFusionWidgetProps, FFusionWidgets, MFusionWidgetCommon } from "./MFusionWidgetsCommon";
import { PDiv } from "../../../ui/composite/UPDiv";
import { MHyperlinks } from "../../common/MHyperlinks";
import { MDatasetRun } from "../../dataset/MDatasetRun";
import { PCategories } from "../../../ui/composite/UPCategories";
import CylinderChartIcon from "../../../../../../assets/icons/charts/fusion/cylinder.svg";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";

export const MFusionCylinder: APDescriptor = {
    id: 'Cylinder', type: 'fusion.chart.Cylinder', label: 'Cylinder', icon: CylinderChartIcon, path: 'widgetProperties',
    layouts: [...MFusionWidgetCommon.layouts,
    new PAccordeon({
        id: 'cylinderTicks', label: "Ticks", layouts: [FFusionWidgets.showTickMarks, FFusionWidgets.showTickValues, FFusionWidgets.ticksOnRight, FFusionWidgets.forceTickValueDecimals, FFusionWidgets.setAdaptiveMin]
    }),
    new PAccordeon({
        id: 'gauge', label: "Gauge", layouts: [new PAccordeon({
            id: 'gauge.fill', label: "Fill", layouts: [FFusionWidgets.gaugeFillColor, FFusionWidgets.gaugeFillAlpha]
        }), new PAccordeon({
            id: 'gauge.border', label: "Border", layouts: [FFusionWidgets.showGaugeBorder, FFusionWidgets.gaugeBorderColor, FFusionWidgets.gaugeBorderAlpha, FFusionWidgets.gaugeBorderThickness]
        })
        ]
    }), new PAccordeon({
        id: 'cylinder', label: "Cylinder", layouts: [FFusionWidgets.cylFillColor, FFusionWidgets.cylHeight, FFusionWidgets.cylOriginX, FFusionWidgets.cylOriginY, FFusionWidgets.cylRadius, FFusionWidgets.cylYScale,
        new PAccordeon({
            id: 'gauge.limits', label: "Limits",
            layouts: [FFusionWidgetProps.showLimits, FFusionWidgets.lowerLimit, FFusionWidgets.upperLimit,
            new PAccordeon({
                id: 'gauge.limits.display', label: "Display Limits",
                layouts: [FFusionWidgets.lowerLimitDisplay, FFusionWidgets.upperLimitDisplay]
            })]
        })
        ]
    })],
    default: {
        type: 'FUSION_WIDGET', widgetType: 'Cylinder',
        widgetProperties: [], valueExpression: "new Integer(20)"
    }
};

export const MFusionCylinderDataset: APDescriptor = {
    id: 'Cylinder', type: 'fusion.chart.Cylinder', label: 'Cylinder', icon: CylinderChartIcon,
    layouts: [new PCategories({
        label: 'Data', layouts: [
            new PDiv({
                id: 'fusion.data.dataset', path: 'dataset',
                label: 'Dataset',
                layouts: [FFusionWidgets.incrementType, FFusionWidgets.incrementWhenExpression, FFusionWidgets.resetType, MDatasetRun]
            }),
            new PDiv({
                id: 'fusion.data.entities',
                label: 'Value',
                layouts: [FFusionWidgets.valueExpression]
            }),
            new PDiv({
                id: 'fusion.data.valuesets.hyperlinks',
                label: 'Hyperlink', path: 'hyperlink',
                layouts: [...MHyperlinks.layouts]
            })]
    })]
};
