/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map, OrderedMap } from 'immutable';
import { CrosstabTypes } from '../reader/JrxmlCrosstabUtils';
import { createBox, createDatasetRun, createProperties, createReportElement, setAttributeIfPresent, setExpressionNodeFromMap } from './JrxmlHelper';
import { createElement } from './JrxmlWriter';

export const createCrosstabElement = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element | null => {
    const crosstabElement = xmlDocument.createElement("crosstab");
    const reportElement = createReportElement(elementNode, xmlDocument);
    crosstabElement.appendChild(reportElement);

    const boxNode : Map<string, any> = elementNode.get("box", null);
    if (boxNode !== null){
        crosstabElement.appendChild(createBox(boxNode, xmlDocument));
    }

    const crosstabParameters : List<Map<string, string>> = elementNode.get("parameters", List<Map<string, string>>()); 
    crosstabParameters.forEach((crosstabParameter: Map<string, string>) => {
        const parameterElement = xmlDocument.createElement("crosstabParameter");
        setAttributeIfPresent(crosstabParameter, "name", parameterElement);
        setAttributeIfPresent(crosstabParameter, "class", parameterElement);
        setExpressionNodeFromMap(parameterElement, xmlDocument, crosstabParameter, "parameterValueExpression");
        crosstabElement.appendChild(parameterElement);
    });

    setExpressionNodeFromMap(crosstabElement, xmlDocument, elementNode, "parametersMapExpression");

    const datasetNode : Map<string, string> = elementNode.get("dataset", null); 
    if (datasetNode !== null){
        const crosstabDatasetElement = xmlDocument.createElement("crosstabDataset");
        setAttributeIfPresent(elementNode, "isDataPreSorted", crosstabDatasetElement);
        const datasetElement = createCrosstabDataset(datasetNode, xmlDocument);
        crosstabDatasetElement.appendChild(datasetElement);
        crosstabElement.appendChild(crosstabDatasetElement);
    }

    let crosstabTitleCell : Element | null= null;
    let crosstabHeaderCell : Element | null= null;
    let whenNoDataCell : Element | null= null;
    let rowGroups = List<Element>();
    let columnGroups = List<Element>();
    let crosstabCells = List<Element>();
    const children :  List<string> = elementNode.get("elementIds", null);
    if (children !== null){
        children.forEach((elementId: string) => {
            const childNode : Map<string, any> | null = jrxmlDocument.get("elements").get(elementId, null);
            if (childNode !== null){
                const type = childNode.get("type");
                if (type === CrosstabTypes.CROSSTAB_TITLE_CELL_NAME){
                    crosstabTitleCell = createCrosstabTitleCell(jrxmlDocument, childNode, xmlDocument);
                } else if (type === CrosstabTypes.CROSSTAB_HEADER_CELL_NAME){
                    crosstabHeaderCell = createCrosstabHeaderCell(jrxmlDocument, childNode, xmlDocument);
                } else if (type === CrosstabTypes.CROSSTAB_ROW_GROUP_NAME){
                    const rowGroup = createRowGroup(jrxmlDocument, childNode, xmlDocument);
                    if (rowGroup !== null){
                        rowGroups = rowGroups.push(rowGroup);
                    }
                } else if (type === CrosstabTypes.CROSSTAB_COLUMN_GROUP_NAME){
                    const columnGroup = createColumnGroup(jrxmlDocument, childNode, xmlDocument);
                    if (columnGroup !== null){
                        columnGroups = columnGroups.push(columnGroup);
                    }
                } else if (type === CrosstabTypes.CROSSTAB_CELL_NAME){
                    const cell = createCell(jrxmlDocument, childNode, xmlDocument);
                    if (cell !== null){
                        crosstabCells = crosstabCells.push(cell);
                    }
                } else if (type === CrosstabTypes.CROSSTAB_NO_DATA_CELL_NAME){
                    whenNoDataCell = createNoDataCell(jrxmlDocument, childNode, xmlDocument);
                }
            }
        });
    }

    if (crosstabTitleCell !== null){
        crosstabElement.appendChild(crosstabTitleCell);
    }

    if (crosstabHeaderCell !== null){
        crosstabElement.appendChild(crosstabHeaderCell);
    }

    rowGroups.forEach((rowGroup: Element) => {
        crosstabElement.appendChild(rowGroup);
    });

    columnGroups.forEach((columnGroup: Element) => {
        crosstabElement.appendChild(columnGroup);
    });

    const measures : List<Map<string, string>> = elementNode.get("measures", List<Map<string, string>>()); 
    measures.forEach((crosstabMeasure: Map<string, string>) => {
        const measureElement = xmlDocument.createElement("measure");
        setAttributeIfPresent(crosstabMeasure, "class", measureElement);
        setAttributeIfPresent(crosstabMeasure, "name", measureElement);
        setAttributeIfPresent(crosstabMeasure, "calculation", measureElement);
        setAttributeIfPresent(crosstabMeasure, "incrementerFactoryClass", measureElement);
        setAttributeIfPresent(crosstabMeasure, "percentageOf", measureElement);
        setAttributeIfPresent(crosstabMeasure, "percentageCalculatorClass", measureElement);
        setExpressionNodeFromMap(measureElement, xmlDocument, crosstabMeasure, "measureExpression");
        crosstabElement.appendChild(measureElement);
    });

    crosstabCells.forEach((cell: Element) => {
        crosstabElement.appendChild(cell);
    });

    if (whenNoDataCell !== null){
        crosstabElement.appendChild(whenNoDataCell);
    }

    setAttributeIfPresent(elementNode, "isRepeatColumnHeaders", crosstabElement);
    setAttributeIfPresent(elementNode, "isRepeatRowHeaders", crosstabElement);
    setAttributeIfPresent(elementNode, "ignoreWidth", crosstabElement);
    setAttributeIfPresent(elementNode, "columnBreakOffset", crosstabElement);
    setAttributeIfPresent(elementNode, "runDirection", crosstabElement);
    setAttributeIfPresent(elementNode, "horizontalPosition", crosstabElement);
    return crosstabElement;
}

const createColumnGroup = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element  => {
    const columnGroupElement = xmlDocument.createElement("columnGroup");

    const bucketNode : Map<string, any> = elementNode.get("bucket", null);
    if (bucketNode !== null){
        columnGroupElement.appendChild(createBucket(bucketNode, xmlDocument));
    }

    let crosstabHeader : Element | null= null;
    let crosstabColumnHeader : Element | null= null;
    let crosstabTotalColumnHeader : Element | null= null;
    const children :  List<string> = elementNode.get("elementIds", null);
    if (children !== null){
        children.forEach((elementId: string) => {
            const childNode : Map<string, any> | null = jrxmlDocument.get("elements").get(elementId, null);
            if (childNode !== null){
                const type = childNode.get("type");
                if (type === CrosstabTypes.CROSSTAB_HEADER_NAME){
                    crosstabHeader = createCrosstabHeader(jrxmlDocument, childNode, xmlDocument);
                } else if (type === CrosstabTypes.CROSSTAB_COLUMN_HEADER_NAME){
                    crosstabColumnHeader = createCrosstabColumnHeader(jrxmlDocument, childNode, xmlDocument);
                } else if (type === CrosstabTypes.CROSSTAB_TOTAL_COLUMN_HEADER_NAME){
                    crosstabTotalColumnHeader = createCrosstabTotalColumnHeader(jrxmlDocument, childNode, xmlDocument);
                }
            }
        });
    }

    if (crosstabHeader !== null){
        columnGroupElement.appendChild(crosstabHeader);
    }

    if (crosstabColumnHeader !== null){
        columnGroupElement.appendChild(crosstabColumnHeader);
    }

    if (crosstabTotalColumnHeader !== null){
        columnGroupElement.appendChild(crosstabTotalColumnHeader);
    }


    setAttributeIfPresent(elementNode, "height", columnGroupElement);
    setAttributeIfPresent(elementNode, "name", columnGroupElement);
    setAttributeIfPresent(elementNode, "totalPosition", columnGroupElement);
    setAttributeIfPresent(elementNode, "headerPosition", columnGroupElement);
    setAttributeIfPresent(elementNode, "mergeHeaderCells", columnGroupElement);
    return columnGroupElement;
}


const createRowGroup = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element  => {
    const rowGroupElement = xmlDocument.createElement("rowGroup");

    const bucketNode : Map<string, any> = elementNode.get("bucket", null);
    if (bucketNode !== null){
        rowGroupElement.appendChild(createBucket(bucketNode, xmlDocument));
    }

    let crosstabRowHeader : Element | null= null;
    let crosstabTotalRowHeader : Element | null= null;
    const children :  List<string> = elementNode.get("elementIds", null);
    if (children !== null){
        children.forEach((elementId: string) => {
            const childNode : Map<string, any> | null = jrxmlDocument.get("elements").get(elementId, null);
            if (childNode !== null){
                const type = childNode.get("type");
                if (type === CrosstabTypes.CROSSTAB_ROW_HEADER_NAME){
                    crosstabRowHeader = createCrosstabRowHeader(jrxmlDocument, childNode, xmlDocument);
                } else if (type === CrosstabTypes.CROSSTAB_TOTAL_ROW_HEADER_NAME){
                    crosstabTotalRowHeader = createCrosstabTotalRowHeader(jrxmlDocument, childNode, xmlDocument);
                }
            }
        });
    }
    
    if (crosstabRowHeader !== null){
        rowGroupElement.appendChild(crosstabRowHeader);
    }

    if (crosstabTotalRowHeader !== null){
        rowGroupElement.appendChild(crosstabTotalRowHeader);
    }


    setAttributeIfPresent(elementNode, "width", rowGroupElement);
    setAttributeIfPresent(elementNode, "name", rowGroupElement);
    setAttributeIfPresent(elementNode, "totalPosition", rowGroupElement);
    setAttributeIfPresent(elementNode, "headerPosition", rowGroupElement);
    setAttributeIfPresent(elementNode, "mergeHeaderCells", rowGroupElement);
    return rowGroupElement;
}

const createBucket = (elementNode: Map<string, any>, xmlDocument: Document) : Element  => {
    const bucket = xmlDocument.createElement("bucket");

    setExpressionNodeFromMap(bucket, xmlDocument, elementNode, "bucketExpression");
    setExpressionNodeFromMap(bucket, xmlDocument, elementNode, "orderByExpression");
    setExpressionNodeFromMap(bucket, xmlDocument, elementNode, "comparatorExpression");

    setAttributeIfPresent(elementNode, "class", bucket);
    setAttributeIfPresent(elementNode, "order", bucket);
    return bucket;
}

const createNoDataCell = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element  => {
    const noDataCellElement = xmlDocument.createElement("whenNoDataCell");
    noDataCellElement.appendChild(createCellContent(jrxmlDocument, elementNode, xmlDocument));
    return noDataCellElement;
}

const createCrosstabHeader = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element  => {
    const headerElement = xmlDocument.createElement("crosstabHeader");
    headerElement.appendChild(createCellContent(jrxmlDocument, elementNode, xmlDocument));
    return headerElement;
}

const createCrosstabColumnHeader = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element  => {
    const rowHeaderElement = xmlDocument.createElement("crosstabColumnHeader");
    rowHeaderElement.appendChild(createCellContent(jrxmlDocument, elementNode, xmlDocument));
    return rowHeaderElement;
}

const createCrosstabTotalColumnHeader = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element  => {
    const rowHeaderElement = xmlDocument.createElement("crosstabTotalColumnHeader");
    rowHeaderElement.appendChild(createCellContent(jrxmlDocument, elementNode, xmlDocument));
    return rowHeaderElement;
}

const createCrosstabTotalRowHeader = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element  => {
    const rowHeaderElement = xmlDocument.createElement("crosstabTotalRowHeader");
    rowHeaderElement.appendChild(createCellContent(jrxmlDocument, elementNode, xmlDocument));
    return rowHeaderElement;
}

const createCrosstabRowHeader = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element  => {
    const rowHeaderElement = xmlDocument.createElement("crosstabRowHeader");
    rowHeaderElement.appendChild(createCellContent(jrxmlDocument, elementNode, xmlDocument));
    return rowHeaderElement;
}

const createCrosstabTitleCell = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element  => {
    const titleCellElement = xmlDocument.createElement("titleCell");
    titleCellElement.appendChild(createCellContent(jrxmlDocument, elementNode, xmlDocument));
    setAttributeIfPresent(elementNode, "height", titleCellElement);
    setAttributeIfPresent(elementNode, "contentsPosition", titleCellElement);
    return titleCellElement;
}

const createCrosstabHeaderCell = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element  => {
    const headerCellElement = xmlDocument.createElement("crosstabHeaderCell");
    headerCellElement.appendChild(createCellContent(jrxmlDocument, elementNode, xmlDocument));
    return headerCellElement;
}

const createCell = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element  => {
    const cellElement = xmlDocument.createElement("crosstabCell");
    cellElement.appendChild(createCellContent(jrxmlDocument, elementNode, xmlDocument));
    setAttributeIfPresent(elementNode, "width", cellElement);
    setAttributeIfPresent(elementNode, "height", cellElement);
    setAttributeIfPresent(elementNode, "rowTotalGroup", cellElement);
    setAttributeIfPresent(elementNode, "columnTotalGroup", cellElement);
    return cellElement;
}

const createCellContent = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element  => {
    const cellContentElement = xmlDocument.createElement("cellContents");

    const properties : OrderedMap<string, string | null> = elementNode.get("properties"); 
    createProperties(cellContentElement, properties, xmlDocument);

    const boxNode : Map<string, any> = elementNode.get("box", null);
    if (boxNode !== null){
        cellContentElement.appendChild(createBox(boxNode, xmlDocument));
    }
    
    const children :  List<string> = elementNode.get("elementIds", null);
    if (children !== null){
        children.forEach((elementId: string) => {
            const childNode : Map<string, any> | null = jrxmlDocument.get("elements").get(elementId, null);
            if (childNode !== null){
                const childElement = createElement(jrxmlDocument, childNode, xmlDocument);
                if (childElement !== null){
                    cellContentElement.appendChild(childElement);
                }
            }
        });
    }

    setAttributeIfPresent(elementNode, "backcolor", cellContentElement);
    setAttributeIfPresent(elementNode, "mode", cellContentElement);
    setAttributeIfPresent(elementNode, "style", cellContentElement);
    return cellContentElement;
}

const createCrosstabDataset = (elementNode: Map<string, any>, xmlDocument: Document) : Element => {
    const datasetElement = xmlDocument.createElement("dataset");
    setExpressionNodeFromMap(datasetElement, xmlDocument, elementNode, "incrementWhenExpression");
    const datasetRunNode: Map<string, any> = elementNode.get("datasetRun", null);
    if (datasetRunNode !== null && !datasetRunNode.isEmpty()){
        datasetElement.appendChild(createDatasetRun(datasetRunNode, xmlDocument));
    }
    setAttributeIfPresent(elementNode, "resetType", datasetElement);
    setAttributeIfPresent(elementNode, "resetGroup", datasetElement);
    setAttributeIfPresent(elementNode, "incrementType", datasetElement);
    setAttributeIfPresent(elementNode, "incrementGroup", datasetElement);
    
    return datasetElement;
}
