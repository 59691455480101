/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import { IRepositoryItemDescriptor } from '@jss/js-rest-api';
import { RunContainer } from '../RunContainer';
import { RepoView } from './RepoView';
import { NewResources } from './NewResources';

interface IRepositoryState {
    refresh: boolean
}

export interface IRepositoryMatchParams {
    path: IRepositoryItemDescriptor[];
    changePath: (path: IRepositoryItemDescriptor[]) => void;
    openResource: (descriptor: IRepositoryItemDescriptor) => void;
}

/**
 * This is the repository main screen
 */
export class Repository extends React.Component<IRepositoryMatchParams, IRepositoryState> {
    public state: IRepositoryState = {
        refresh: false
    };
    public render() {
        return (
            <RunContainer>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', minHeight: '0px' }}>
                        <div style={{ flex: 3, backgroundColor: '#f7f6f6', borderRight: '1px solid #ccc', maxWidth: '25%' }}>
                            <NewResources path={this.props.path} openResource={this.props.openResource} refresh={this.refresh} />
                        </div>
                        <div style={{ flex: 5, display: 'flex', marginTop: '5px' }}>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: '0px', overflow: 'auto' }}>
                                <RepoView path={this.props.path} changePath={this.props.changePath}
                                    openResource={this.props.openResource} refresh={this.state.refresh} />
                            </div>
                        </div>
                </div >
            </RunContainer>
        );
    }
    private refresh = () => {
        const r = !this.state.refresh;
        this.setState({ refresh: r });
    }

}
