/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const MeterChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M11 27.1V175h248V27.1z" />
            </clipPath>
         </defs>
         <g fill="#777" stroke="#777" textRendering="optimizeLegibility">
            <path stroke="none" d="M259 151.1a124 124 0 00-248 0h124z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M12 151.1L27 151.1" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M13.977 129.137L28.736 131.816" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M19.843 107.881L33.887 113.151" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M29.411 88.013L42.288 95.707" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M42.372 70.174L53.668 80.043" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M58.311 54.935L67.663 66.662" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M76.714 42.787L83.822 55.996" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M96.991 34.12L101.626 48.386" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M118.489 29.213L120.503 44.077" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M140.518 28.224L139.845 43.209" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M162.37 31.184L159.032 45.808" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M183.342 37.998L177.447 51.791" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M202.76 48.447L194.497 60.966" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M220.001 62.196L209.635 73.038" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M234.509 78.802L222.374 87.619" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M245.819 97.732L232.305 104.241" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M253.567 118.377L239.108 122.368" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M257.505 140.074L242.565 141.419" clipPath="url(#clipPath1)" />
            <path fill="#F66" stroke="none" strokeWidth="2" d="M22.652 108.935A120 120 0 0015 151.1h120z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="#00f" strokeWidth="2" d="M12 151.1L27 151.1" clipPath="url(#clipPath1)" />
         </g>
         <g strokeWidth="2" fontFamily="sans-serif" fontSize="10" fontWeight="bold" textRendering="optimizeLegibility">
            <text x="27" y="145.211" stroke="none" clipPath="url(#clipPath1)">0</text>
            <path fill="none" stroke="#00f" d="M19.843 107.881L33.887 113.151" clipPath="url(#clipPath1)" />
            <text x="33.887" y="107.263" stroke="none" clipPath="url(#clipPath1)">2.000</text>
         </g>
         <path fill="#FF0" fillOpacity="0.4" stroke="none" strokeOpacity="0.4" strokeWidth="2" d="M201.108 50.951a120 120 0 00-178.456 57.984L135 151.1z" clipPath="url(#clipPath1)" fontFamily="sans-serif" fontSize="10" fontWeight="bold" textRendering="optimizeLegibility" />
         <g fill="#00f" stroke="#00f" strokeWidth="2" fontFamily="sans-serif" fontSize="10" fontWeight="bold" textRendering="optimizeLegibility">
            <path fill="none" d="M19.843 107.881L33.887 113.151" clipPath="url(#clipPath1)" />
            <text x="33.887" y="107.263" fill="#000" stroke="none" clipPath="url(#clipPath1)">2.000</text>
            <path fill="none" d="M202.76 48.447L194.497 60.966" clipPath="url(#clipPath1)" />
            <text x="159.043" y="66.855" fill="#000" stroke="none" clipPath="url(#clipPath1)">12.000</text>
         </g>
         <path fill="#6C6" fillOpacity="0.502" stroke="none" strokeOpacity="0.502" strokeWidth="2" d="M255 151.1a120 120 0 00-53.892-100.149L135 151.1z" clipPath="url(#clipPath1)" fontFamily="sans-serif" fontSize="10" fontWeight="bold" textRendering="optimizeLegibility" />
         <g fill="#00f" stroke="#00f" strokeWidth="2" fontFamily="sans-serif" fontSize="10" fontWeight="bold" textRendering="optimizeLegibility">
            <path fill="none" d="M202.76 48.447L194.497 60.966" clipPath="url(#clipPath1)" />
            <text x="159.043" y="66.855" fill="#000" stroke="none" clipPath="url(#clipPath1)">12.000</text>
            <path fill="none" d="M258 151.1L243 151.1" clipPath="url(#clipPath1)" />
            <text x="207.546" y="145.211" fill="#000" stroke="none" clipPath="url(#clipPath1)">17.500</text>
         </g>
         <g stroke="none" strokeWidth="2" fontFamily="'DejaVu Sans'" textRendering="optimizeLegibility">
            <text x="85.134" y="172.239" clipPath="url(#clipPath1)">7.990,08 Freight</text>
            <path fill="#006" d="M132 151L137 151 116 14z" clipPath="url(#clipPath1)" />
            <circle cx="135" cy="151.1" r="5" fill="#006" clipPath="url(#clipPath1)" />
         </g>
      </svg>
   );
}
export default MeterChartIcon;