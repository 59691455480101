/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { DataMatrixShapeEnum } from "../../enums/DataMatrixShapeEnum";
import { PInt } from "../../../ui/primitive/UPInteger";
import {   MBarcode4J } from "./MBarcode4J";


export const FDataMatrix = {
    minSymbolHeight: new PInt({ id: 'minSymbolHeight', label: 'Min Symbol Height', min: 0 }),
    maxSymbolHeight: new PInt({ id: 'maxSymbolHeight', label: 'Max Symbol Height', min: 0 }),
    minSymbolWidth: new PInt({ id: 'minSymbolWidth', label: 'Min Symbol Width', min: 0 }),
    maxSymbolWidth: new PInt({ id: 'maxSymbolWidth', label: 'Max Symbol Width', min: 0 }),
    shape: DataMatrixShapeEnum
}

export const MDataMatrix: APDescriptor = {
    type: 'DataMatrix', id: 'DataMatrix', label: 'Data Matrix', icon: ICONS.BARCODE_DATAMATRIX_ICON,
    layouts: [...MBarcode4J.layouts,  FDataMatrix.minSymbolHeight, FDataMatrix.maxSymbolHeight, FDataMatrix.minSymbolWidth, FDataMatrix.maxSymbolWidth, FDataMatrix.shape],
    default: {
        type: 'BARCODE', BARCODE_TYPE: 'DataMatrix', BARCODE_XMLNS_NAMESPACE: 'c',
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:c': "http://jasperreports.sourceforge.net/jasperreports/components",
        codeExpression: '"123456789"',
        width: 30, height: 30

    }
};
