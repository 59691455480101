/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IRepositoryItemDescriptor } from "../repo/IRepositoryItemDescriptor";
import { ResourceFieldREST } from "./ResourceFieldREST";

export class ResourceFieldFile extends ResourceFieldREST {

    public getContainerLenght() {
        return 0;
    }

    public isEditable(res: IRepositoryItemDescriptor, name: string) {
        switch (name) {
            case 'description': return false;
        }
        return super.isEditable(res, name);
    }
    public isAvailable(res: IRepositoryItemDescriptor, name: string) {
        switch (name) {
            case 'description': return false;
        }
        return super.isAvailable(res, name);
    }
    public isAvailable4Children(res: IRepositoryItemDescriptor, name: string) {
        switch (name) {
            case 'description': return false;
        }
        return this.isAvailable(res, name);
    }

}