/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { RepositoryApi } from '@jss/js-rest-api';
import axios from 'axios';
import { Map, List, fromJS } from 'immutable';
import { ADD_LOGGED_USER, ADD_LOGGED_USER_METADATA, LOGOUT_USER, SET_JRS_URL, SET_LANGUAGE } from '../actions/actionTypes';

const initialState = {
    storedData: Map<string, any>({
        loggedUser: undefined,
        jrsURLs: List<string>(),
    }),
};

export const LOGGED_USERS_KEY = 'loggedUser';

export const JRS_URLS = 'jrsURLs';

const persistentReducer = (state = initialState, action: any) => {
    let newData = state.storedData;
    switch (action.type) {
        case ADD_LOGGED_USER:
            {
                const baseUrl = action.baseUrl;
                const userId = action.userId;
                const info = action.info;
                const userInfo = fromJS({ baseUrl, userId, info });
                newData = newData.set(LOGGED_USERS_KEY, userInfo);
            }
            return { storedData: newData };
        case ADD_LOGGED_USER_METADATA:
            {
                const metadata = fromJS(action.metadata);
                newData = newData.setIn([LOGGED_USERS_KEY, 'metadata'], metadata);
            }
            return { storedData: newData };
        case LOGOUT_USER:
            {
                switch (RepositoryApi.inst().getBase()) {
                    case 'js':
                        const urls = (newData.get(JRS_URLS, List<string>()) as List<string>);
                        if (urls.size > 0) {
                            let jrsurl = urls.get(urls.size - 1);
                            if (jrsurl) {
                                if (!jrsurl.endsWith("/"))
                                    jrsurl = jrsurl + "/";
                                const louturl = `${jrsurl}logout.html`;
                                axios.get(louturl).catch(error => console.log(error));
                            }
                        }
                        break;
                }
                newData = newData.delete(LOGGED_USERS_KEY);
            }
            return { storedData: newData };
        case SET_JRS_URL:
            {
                let oldData = (newData.get(JRS_URLS, List<string>()) as List<string>);
                const oldIndex = oldData.findIndex((url: string) => {
                    return url === action.url;
                });
                if (oldIndex !== -1) {
                    oldData = oldData.delete(oldIndex);
                }
                oldData = oldData.push(action.url);
                newData = newData.set(JRS_URLS, oldData);
            }
            return { storedData: newData };
        case SET_LANGUAGE:
            {
                newData = newData.set('language', action.name);
                return { storedData: newData };
            }
        default:
            return state;
    }
};

export default persistentReducer;
