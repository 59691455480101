/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const BubbleChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath id="highcharts-84qktro-1">
          <path fill="none" d="M0 0H489V700H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M56 10H545V710H56z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" d="M83.5 10v700M148.5 10v700M214.5 10v700M280.5 10v700M345.5 10v700M411.5 10v700M477.5 10v700M543.5 10v700" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M56 710.5h489M56 646.5h489M56 583.5h489M56 519.5h489M56 455.5h489M56 392.5h489M56 328.5h489M56 265.5h489M56 201.5h489M56 137.5h489M56 74.5h489M56 9.5h489" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M56 10H545V710H56z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" stroke="#ccd6eb" d="M83.5 710v10M148.5 710v10M214.5 710v10M280.5 710v10M345.5 710v10M411.5 710v10M477.5 710v10M543.5 710v10" className="highcharts-tick" />
       <text x="300.5" y="748" fill="#666" className="highcharts-axis-title" color="#666" data-z-index="7" textAnchor="middle">
          <tspan>Total Freight</tspan>
       </text>
       <path fill="none" stroke="#ccd6eb" d="M56 710.5h489" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M56 10v700" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-bubble-series highcharts-color-0 highcharts-tracker" clipPath="url(#highcharts-84qktro-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="rgba(124,181,236,0.5)" stroke="#7cb5ec" d="M93 472.424a21 21 0 110-.021z" className="highcharts-point highcharts-color-0" />
          <path fill="rgba(124,181,236,0.5)" stroke="#7cb5ec" d="M109 500.01a25 25 0 110-.025z" className="highcharts-point highcharts-color-0" />
          <path fill="rgba(124,181,236,0.5)" stroke="#7cb5ec" d="M147.5 540.814a33.5 33.5 0 110-.034zM55 617.056a21 21 0 110-.02z" className="highcharts-point highcharts-color-0" />
          <path fill="rgba(124,181,236,0.5)" stroke="#7cb5ec" d="M56 625.215a25 25 0 110-.025z" className="highcharts-point highcharts-color-0" />
          <path fill="rgba(124,181,236,0.5)" stroke="#7cb5ec" d="M52 621.543a21 21 0 110-.021zM115 511.84a31 31 0 110-.031z" className="highcharts-point highcharts-color-0" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-bubble-series highcharts-color-1 highcharts-tracker" clipPath="url(#highcharts-84qktro-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="rgba(67,67,72,0.5)" stroke="#434348" d="M87.5 550.054a28.5 28.5 0 110-.029z" className="highcharts-point highcharts-color-1" />
          <path fill="rgba(67,67,72,0.5)" stroke="#434348" d="M67 603.63a25 25 0 110-.026zM489.5 290.13a53.5 53.5 0 110-.053z" className="highcharts-point highcharts-color-1" />
          <path fill="rgba(67,67,72,0.5)" stroke="#434348" d="M63 576.036a21 21 0 110-.02zM173.5 185.214a33.5 33.5 0 110-.034z" className="highcharts-point highcharts-color-1" />
          <path fill="rgba(67,67,72,0.5)" stroke="#434348" d="M73 565.543a25 25 0 110-.025z" className="highcharts-point highcharts-color-1" />
          <path fill="rgba(67,67,72,0.5)" stroke="#434348" d="M96.5 562.208a33.5 33.5 0 110-.033zM97 485.33a31 31 0 110-.032z" className="highcharts-point highcharts-color-1" />
          <path fill="rgba(67,67,72,0.5)" stroke="#434348" d="M56 612.436a21 21 0 110-.02zM130 547.693a38 38 0 110-.038z" className="highcharts-point highcharts-color-1" />
          <path fill="rgba(67,67,72,0.5)" stroke="#434348" d="M51 628.778a21 21 0 110-.02z" className="highcharts-point highcharts-color-1" />
          <path fill="rgba(67,67,72,0.5)" stroke="#434348" d="M58 589.775a21 21 0 110-.02z" className="highcharts-point highcharts-color-1" />
       </g>
       <g className="highcharts-series highcharts-series-2 highcharts-bubble-series highcharts-color-2 highcharts-tracker" clipPath="url(#highcharts-84qktro-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="rgba(144,237,125,0.5)" stroke="#90ed7d" d="M108.5 527.565a28.5 28.5 0 110-.029zM55.5 578.474a16.5 16.5 0 110-.017zM484 107.705a44 44 0 110-.044zM45.5 626.875a16.5 16.5 0 110-.016zM138 457.794a31 31 0 110-.031zM154 178.322a25 25 0 110-.025z" className="highcharts-point highcharts-color-2" />
          <path fill="rgba(144,237,125,0.5)" stroke="#90ed7d" d="M57.5 628.95a28.5 28.5 0 110-.028z" className="highcharts-point highcharts-color-2" />
          <path fill="rgba(144,237,125,0.5)" stroke="#90ed7d" d="M44.5 631.91a16.5 16.5 0 110-.017zM164.5 250.606a28.5 28.5 0 110-.028z" className="highcharts-point highcharts-color-2" />
          <path fill="rgba(144,237,125,0.5)" stroke="#90ed7d" d="M55 613.022a21 21 0 110-.021z" className="highcharts-point highcharts-color-2" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(178 762)">
       <rect width="199" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-bubble-series highcharts-color-0 highcharts-series-0" data-z-index="1">
             <path fill="none" d="M0 11h12" className="highcharts-graph" transform="translate(8 3)" />
             <path fill="rgba(124,181,236,0.5)" stroke="#7cb5ec" d="M12 11a6 6 0 110-.006z" className="highcharts-point" transform="translate(8 3)" />
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold" transform="translate(8 3)">
                <tspan>1996</tspan>
             </text>
          </g>
          <g className="highcharts-legend-item highcharts-bubble-series highcharts-color-1 highcharts-series-1" data-z-index="1">
             <path fill="none" d="M0 11h12" className="highcharts-graph" transform="translate(75.672 3)" />
             <path fill="rgba(67,67,72,0.5)" stroke="#434348" d="M12 11a6 6 0 110-.006z" className="highcharts-point" transform="translate(75.672 3)" />
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold" transform="translate(75.672 3)">
                <tspan>1997</tspan>
             </text>
          </g>
          <g className="highcharts-legend-item highcharts-bubble-series highcharts-color-2 highcharts-series-2" data-z-index="1">
             <path fill="none" d="M0 11h12" className="highcharts-graph" transform="translate(143.344 3)" />
             <path fill="rgba(144,237,125,0.5)" stroke="#90ed7d" d="M12 11a6 6 0 110-.006z" className="highcharts-point" transform="translate(143.344 3)" />
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold" transform="translate(143.344 3)">
                <tspan>1998</tspan>
             </text>
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="middle">
       <text x="83.527" y="729">0</text>
       <text x="149.257" y="729">500</text>
       <text x="214.988" y="729">1.000</text>
       <text x="280.719" y="729">1.500</text>
       <text x="346.449" y="729">2.000</text>
       <text x="412.18" y="729">2.500</text>
       <text x="477.91" y="729">3.000</text>
       <text x="529.344" y="729">3.500</text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="41" y="715">-100</text>
       <text x="41" y="651">0</text>
       <text x="41" y="587">100</text>
       <text x="41" y="524">200</text>
       <text x="41" y="460">300</text>
       <text x="41" y="396">400</text>
       <text x="41" y="333">500</text>
       <text x="41" y="269">600</text>
       <text x="41" y="205">700</text>
       <text x="41" y="142">800</text>
       <text x="41" y="78">900</text>
       <text x="41" y="15">1.000</text>
    </g>
 </svg>)};

export default BubbleChartIcon;