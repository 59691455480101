/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { PEnum } from "../../ui/primitive/UPEnum";

export const PdfFieldCheckTypeEnum = new PEnum({
    id: 'pdfPrintScalingEnum', label: 'PdfFieldCheckTypeEnum.label', sinceVersion: '6.12.0',
    items: [{ key: '', value: '' },
    { key: 'Check', value: 'PdfFieldCheckTypeEnum.Check' },
    { key: 'Circle', value: 'PdfFieldCheckTypeEnum.Circle' },
    { key: 'Cross', value: 'PdfFieldCheckTypeEnum.Cross' },
    { key: 'Diamond', value: 'PdfFieldCheckTypeEnum.Diamond' },
    { key: 'Square', value: 'PdfFieldCheckTypeEnum.Square' },
    { key: 'Star', value: 'PdfFieldCheckTypeEnum.Star' },
    ]
});

