/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { MBarbecue } from "./MBarbecue";

export const MCode128A: APDescriptor = {
    ...MBarbecue, id: 'Code128A', label: 'Code128A', icon:  ICONS.BARCODE_EAN128_ICON,
    default: { ...MBarbecue.default, barbecue_type: 'Code128A' }
};
