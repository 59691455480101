/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IMenuItem, IToolbarProvider, IToolbarItem } from './ToolbarProvider';
import { MenuLabel } from '../button/MenuLabel';
import { MessageInfo } from '@jss/js-rest-api';
import ReactResizeDetector from 'react-resize-detector';

interface IToolbar {
    toolbarData?: IToolbarProvider;
    message?: MessageInfo;
    closeMessage?: () => void;
}

interface IState {
    width: number,
    height: number,
}

export class ToolBar extends React.Component<IToolbar, IState> {

    state: IState = {
        width: 0,
        height: 0,
    }

    private closeMessage = () => {
        if (this.props.closeMessage) {
            this.props.closeMessage();
        }
    }


    public render() {
        if (this.props.toolbarData) {
            let menuItems;
            if (this.props.toolbarData.getMenus && this.props.toolbarData.getMenus().length > 0) {
                menuItems = <div style={{ display: 'flex', alignItems: 'center', height: 20, backgroundColor: 'white' }} className="jr-mToolbar jr">
                    {this.props.toolbarData.getMenus().map((menuItem: IMenuItem) => {
                        return <MenuLabel key={menuItem.id} content={menuItem.subMenus} id={menuItem.id} text={menuItem.label} style={{ color: '#666', fontWeight: 'bold', marginLeft: '10px', cursor: 'pointer' }} />;
                    })}
                </div>
            }
            let toolItemsBar;
            if (this.props.toolbarData.getToolItems) {
                const toolItems = this.props.toolbarData.getToolItems().map((toolItem: IToolbarItem) => {
                    const control = <React.Fragment key={toolItem.id}>{toolItem.render({ foreground: '#666' })}</React.Fragment>;
                    if (toolItem.addSeparatorAfter) {
                        return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            {control}
                            <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                        </div>
                    }
                    return control;
                });

                if (toolItems.length > 0) {
                    toolItemsBar = <div style={{ display: 'flex', alignItems: 'center', height: 40, backgroundColor: 'white', minWidth: 0 }} className="jr-mToolbar jr">
                        <ReactResizeDetector onResize={this.onResize} handleWidth handleHeight>
                            <div style={{flex: 1, minWidth: 0, height: 40, position: 'relative'}}>
                                <div style={{position: 'absolute', top: 0, left: 0, height: 40, width: this.state.width, overflow: 'hidden', display: 'flex', flexGrow: 0, flexBasis: 0, alignItems: 'center'}}>
                                    {toolItems}
                                </div>
                            </div>
                        </ReactResizeDetector>
                    </div>
                }
            }
            let messageBar;
            if (this.props.message) {
                messageBar = <div style={{ display: 'flex', flex: 1, height: 30, maxHeight: 30, flexDirection: 'row', alignItems: 'center', background: 'lightyellow' }}>
                    <div style={{ flex: 1, paddingLeft: 10, textAlign: 'left' }}>
                        {(this.props.message as MessageInfo).getMessage()}
                    </div>
                    <div style={{ paddingRight: 10, cursor: 'pointer' }} onClick={this.closeMessage}>
                        <CloseIcon />
                    </div>
                </div>
            }
            return <div style={{ display: 'flex', marginLeft: - 10, marginRight: -10, zIndex: 99, borderBottomWidth: 2, borderBottomColor: '#f3f4fb', borderBottomStyle: 'solid', backgroundColor: 'white' }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 24, paddingRight: 24 }}>
                    {menuItems}
                    {toolItemsBar}
                    {messageBar}
                </div>
            </div>
        }
        return null;
    }

    private onResize = (width: number, height: number) => {
        // On resize, we should also update the scrollbars.
        // In particular we should adapt the quantity of scroll...
        this.setState({ width: width, height: height });
    }
}