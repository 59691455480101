/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const CrosstabIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Tree/dashboard/crosstab" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="crosstab" transform="translate(1.000000, 2.000000)">
                    <rect id="body" fill="#C1DCF5" x="5" y="5" width="12" height="9"></rect>
                    <path d="M17,0 L17,4 L4,4 L4,14 L0,14 L0,0 L17,0 Z" id="Combined-Shape" fill="#0081CB"></path>
                </g>
            </g>
        </svg>
    );
}
export default CrosstabIcon;