/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../ui/APDescriptor";
import { CrosstabTypes } from "../../../../common/JrxmlModel/reader/JrxmlCrosstabUtils";
import { MCrosstabGroup } from "./MCrosstabGroup";
import { CrosstabRowPositionEnum } from "../enums/CrosstabRowPositionEnum";
import { PConnectedSizeAndPosition } from "../../ui/primitive/UPConnectedSizePosition";

export const FCrosstabRowGroup = {
    position: CrosstabRowPositionEnum,
    width: new PConnectedSizeAndPosition({ id: 'width', label: 'Width', min: 0 })
};

export const MCrosstabRowGroup: APDescriptor = {
    type: CrosstabTypes.CROSSTAB_ROW_GROUP_NAME,
    label: 'Row Group',
    id: 'rowGroupProeprtiesDescriptor',
    layouts: [...MCrosstabGroup.layouts, FCrosstabRowGroup.position, FCrosstabRowGroup.width]
};
