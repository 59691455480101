export const fr = {
  "dataAdaptersView.basename.text": "Nouveau Data Adapter",
  "dataAdaptersView.tabs.editor": "Éditeur",
  "dataAdaptersView.tabs.source": "Source",
  "home.buttons.gdrive.login": "Se connecter à GDrive",
  "home.buttons.github.login": "Se connecter à GitHub",
  "home.buttons.js.login": "Se connecter à JasperReports Server",
  "home.buttons.s3.login": "Se connecter à Amazon S3",
  "home.buttons.login": "Se connecter à {{name}}",
  "home.buttons.login.file": "Dossier {{name}}",
  "repositoryView.contextual.action.run": "Courir",
  "repositoryView.create.menu.newreport": "Nouveau Rapport",
  "repositoryView.create.menu.newda": "Nouveau Data Adapter",
  "repositoryView.create.menu.newfolder": "Nouveau Dossier",
  "languageselector.combo.language": "Français",
  "appheader.selector.theme.text": "Thème:",
  "appheader.selector.lang.text": "Langue:",
  "appheader.button.logout": "Déconnexion",
  "appheader.selector.lang.sendfeedback": "Envoyer des commentaires",
  "appheader.button.home.tooltip": "Accueil",
  "dark": "Foncée",
  "light": "Clair",

  "home.login.button": "Connexion",
  "home.login.username": "Nom Utilisateur",
  "home.login.password": "Mot De Passe",

  "home.login.js.userid": "Nom Utilisateur",
  "home.login.js.organization": "Organisation",
  "home.login.js.serverurl": "URL Du Serveur",
  "home.login.js.info": "À utiliser avec JasperReports Server 8.0.0 ou version ultérieure.",

  "home.login.s3.accesskey": "Clé d'accès",
  "home.login.s3.secretkey": "Clef secrète"
}