/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IRepositoryItemDescriptor, RESOURCE_TYPE } from "@jss/js-rest-api";

export const last = (paths: IRepositoryItemDescriptor[]) => {
    return paths.length === 0 ? { path: '/', uuid: '', type: RESOURCE_TYPE.FOLDER, name: "" } : paths[paths.length - 1];
}

export const getUUID = (paths: IRepositoryItemDescriptor[]): string => {
    const p = last(paths);
    let uuid = '';
    if (p.uuid) {
        if (p.type === RESOURCE_TYPE.CONTAINER) {
            uuid = "container:";
        }
        uuid += 'uuid:' + p.uuid;
    }
    return uuid;
}