/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg, UiProperty, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { ButtonGroup } from '@jss/js-common';
import i18n from '../../../../../i18n';

const TYPEID = 'buttonGroup';
reg(TYPEID, (mc) => { return <UPButtonGroup mcontext={mc} />; });
export class PButtonGroup extends APDescriptor {
    public constructor(init: Partial<PButtonGroup>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
export class UPButtonGroup extends React.Component<UiPropertyProps> {

    render() {
        const d = this.props.mcontext.descriptor as PButtonGroup;
        let labelClasses = 'jr-MuiFormLabel-root jr-MuiInputLabel-root jr-mInput-label mui  jr-MuiInputLabel-formControl jr-MuiInputLabel-outlined';
        if (d.deprecated){
            labelClasses += ' deprecatedProperty';
        }
        return <div className="jr-MuiFormControl-root jr-MuiTextField-root jr-mInput jr-mInputText mui  jr-mInputLarge jr-mInputInline"  >
            <label className={labelClasses} data-shrink="false">
                {i18n.t(this.props.mcontext.descriptor.label)}
            </label>
            <div className="jr-MuiInputBase-root jr-MuiOutlinedInput-root jr-MuiInputBase-formControl">
                <ButtonGroup aria-label="contained primary button group" className="jr-jrws-fill-button-group" disabled={d.readonly}>
                    {d.layouts.map((k, index) => {
                        return <UiProperty key={index} mcontext={{ ...this.props.mcontext, descriptor: k }} />
                    })}
                </ButtonGroup>
            </div>
        </div>
    }


}