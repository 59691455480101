/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import FolderIcon from '@material-ui/icons/Folder';
import SettingsIcon from '@material-ui/icons/Settings';
import StorageIcon from '@material-ui/icons/Storage';
import DescriptionIcon from '@material-ui/icons/Description';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TableChartIcon from '@material-ui/icons/TableChart';
import ImageIcon from '@material-ui/icons/Image';
import LandscapeIcon from '@material-ui/icons/Landscape';
import InboxIcon from '@material-ui/icons/Inbox';
import OrganizationIcon from '@material-ui/icons/Apartment';
import HomeIcon from '@material-ui/icons/House';
import { RESOURCE_TYPE } from '@jss/js-rest-api';

const FOLDER = 'FOLDER';
const CONTAINER = 'CONTAINER';
const DASHBOARD = 'DASHBOARD';
const REPORT = 'REPORT';
const SYSTEM = 'SYSTEM';
const IMAGE = 'IMAGE';
const DATASET = 'DATASET';
const XML = 'XML';
const FILE = 'FILE';
const UNKNOWN = 'UNKNOWN';
const DATASOURCE = 'DATASOURCE';
const REPORTUNIT = 'REPORTUNIT';


export const ResourceTypes = {
    FOLDER,
    CONTAINER,
    DASHBOARD,
    DATASET,
    IMAGE,
    REPORT,
    SYSTEM,
    DATASOURCE,
    REPORTUNIT,
    XML,
    FILE,
    UNKNOWN
}

/**
 * Given an item, returns a constant from RepositoryItemTypes
 * @param item
 */
export const getType = (item: { type: string, mime?: string | undefined, name?: string }): string => {
    if (item.type === RESOURCE_TYPE.FOLDER) {
        return FOLDER;
    }
    if (item.type === RESOURCE_TYPE.CONTAINER) {
        if (item.mime === 'reportUnit') {
            return REPORTUNIT;
        } else {
            return CONTAINER;
        }
    }
    else if (item.type === "nt:file") {
        if (item.mime?.startsWith('application/repository.reportUnit')) {
            return CONTAINER;
        }
        switch (item.mime) {
            case "application/tc-jsw-dashboard":
                return DASHBOARD;
            case "application/datasource":
            case "application/dataadapter": // Case to be removed....
                return DATASOURCE;
            case "application/dataset": // Case to be removed....
                return DATASET;
            case "application/octet-stream":
                const name = item.name ? item.name && item.name.toLowerCase().trim() : '';
                if (name.endsWith('.jrxml')) {
                    return REPORT;
                } else if (name.endsWith('.xml')) {
                    return XML;
                } else {
                    return FILE;
                }
            case "application/xml":
            case "text/xml":
                if (item.name && item.name.toLowerCase().endsWith('.jrxml')) {
                    return REPORT;
                } else {
                    return XML;
                }
            case "jrxml":
                return REPORT;
            default:
                return FILE;
        }
    }
    return SYSTEM;
}

/**
 * Given an item, returns a constant from RepositoryItemTypes
 * @param item
 */
export const getLabel = (typeName: string): string => {
    switch (typeName) {
        case SYSTEM:
            return "System";
        case FOLDER:
            return "Folder";
        case DASHBOARD:
            return "Dashboard";
        case DATASOURCE:
            return "Data Source";
        case DATASET:
            return "Dataset";
        case FILE:
            return "File";
        case REPORT:
            return "Report";
        case XML:
            return "XML";
        case REPORTUNIT:
            return "Report Unit";
        default:
            return "Unknown";
    }
}

/**
 * Given a repository item, return an Icon element
 */
export const getIcon = (item: { type: string, mime?: string | undefined, name?: string }, iconSize = 16): JSX.Element => {
    switch (getType(item)) {
        case SYSTEM:
            return <SettingsIcon style={{ fontSize: iconSize }} />;
        case FOLDER:
            return <FolderIcon style={{ fontSize: iconSize }} />;
        case DASHBOARD:
            return <DashboardIcon style={{ fontSize: iconSize }} />;
        case DATASOURCE:
            return <StorageIcon style={{ fontSize: iconSize }} />;
        case DATASET:
            return <TableChartIcon style={{ fontSize: iconSize }} />;
        default:
            return <DescriptionIcon style={{ fontSize: iconSize }} />;
    }
}


export const getIcon4Name = (mime: string, iconSize = 16): JSX.Element => {
    switch (mime) {
        case SYSTEM: return <SettingsIcon style={{ fontSize: iconSize }} />;
        case 'folder': return <FolderIcon style={{ fontSize: iconSize }} />;
        case 'Image': return <ImageIcon style={{ fontSize: iconSize }} />;
        // case 'Abc': return <AbcIcon style={{ fontSize: iconSize }} />;
        // case 'Css': return <CssIcon style={{ fontSize: iconSize }} />;
        // case 'Javascript': return <JavascriptIcon style={{ fontSize: iconSize }} />;
        // case 'Html': return <HtmlIcon style={{ fontSize: iconSize }} />;
        case 'Inbox': return <InboxIcon style={{fontSize: iconSize}} />;
        case 'Owner': return <HomeIcon style={{fontSize: iconSize}} />;
        case 'Organization': return <OrganizationIcon style={{fontSize: iconSize}} />;
        case 'Landscape': return <LandscapeIcon style={{ fontSize: iconSize }} />;
        case 'Storage': return <StorageIcon style={{ fontSize: iconSize }} />; 
        default: return <DescriptionIcon style={{ fontSize: iconSize }} />;
    }

}
