/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map, OrderedMap } from 'immutable';
import { uuidv4 } from '../../../../utils/uuidGenerator';
import { ElementTypes } from '../../../../sagas/report/document/elementTypes';
import { LIST_NAMESPACE } from '../reader/JrxmlListUtils';
import { createAdhocElement } from './JrxmlAdHoc';
import { createBarcodeElement } from './JrxmlBarcodeUtils';
import { createChartElement } from './JrxmlChartUtils';
import { createCrosstabElement } from './JrxmlCrosstabUtils';
import { createCVCElement } from './JrxmlCVCUtils';
import { createFusionChartElement } from './JrxmlFusionChartUtils';
import { createFusionWidgetElement } from './JrxmlFusionWidgetUtils';
import { addHyperlinkAttributes, createBox, createDatasetRun, createFontElement, createPen, createProperties, createPropertiesExpression, createReportElement, createReportFontElement, getBandJRTypeFromModel, setAttributeIfPresent, setExpressionNode, setExpressionNodeFromMap, setXMLAttributeIfPresent } from './JrxmlHelper';
import { createHTML5ChartElement } from "./JrxmlHTML5ChartUtils";
import { createFusionMapElement, createMapElement, createTibcoMapElement } from './JrxmlMapUtils';
import { createTableElement } from './JrxmlTableUtils';
import { Conf } from '@jss/js-rest-api';

export default function jsToJrxml(jrxmlDocument: Map<string, any>): string {
    const xmlDoc = document.implementation.createDocument("", "", null);
    const root = xmlDoc.createElement("jasperReport");
    createJasperReportsNode(jrxmlDocument, root, xmlDoc);
    xmlDoc.appendChild(root);

    const config = Conf.get('version');
    const version = config ? `version ${config}` : '';
    const prolog = `<?xml version="1.0" encoding="UTF-8"?>\n<!-- Created with Jaspersoft WebStudio ${version} -->`;

    const oSerializer = new XMLSerializer();
    let result = prolog + oSerializer.serializeToString(xmlDoc);

    // FIXME: manually add xmlns attribute because firefox does not add it on the jasperReport element with setAttribute("xmlns", ...)
    result = result.replace("<jasperReport",
        `<jasperReport xmlns=\"${jrxmlDocument.get("xmlns", "http://jasperreports.sourceforge.net/jasperreports")}\"`);

    return result;
}

export const createElement = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const type = elementNode.get("type");
    if (type !== null) {
        if (type === ElementTypes.TEXTFIELD) {
            return createTextField(elementNode, xmlDocument);
        } else if (type === ElementTypes.STATICTEXT) {
            return createStaticText(elementNode, xmlDocument);
        } else if (type === ElementTypes.IMAGE) {
            return createImage(elementNode, xmlDocument);
        } else if (type === ElementTypes.BREAK) {
            return createBreak(elementNode, xmlDocument);
        } else if (type === ElementTypes.RECTANGLE) {
            return createRectangle(elementNode, xmlDocument);
        } else if (type === ElementTypes.ELLIPSE) {
            return createEllipse(elementNode, xmlDocument);
        } else if (type === ElementTypes.LINE) {
            return createLine(elementNode, xmlDocument);
        } else if (type === ElementTypes.GENERIC_ELEMENT) {
            return createGenericElement(elementNode, xmlDocument);
        } else if (type === ElementTypes.FRAME) {
            return createFrame(jrxmlDocument, elementNode, xmlDocument);
        } else if (type === ElementTypes.SUBREPORT) {
            return createSubreport(elementNode, xmlDocument);
        } else if (type === ElementTypes.BARCODE) {
            return createBarcodeElement(elementNode, xmlDocument);
        } else if (type === ElementTypes.LIST) {
            return createList(jrxmlDocument, elementNode, xmlDocument);
        } else if (type === ElementTypes.JFREECHART) {
            return createChartElement(elementNode, xmlDocument);
        } else if (type === ElementTypes.CROSSTAB) {
            return createCrosstabElement(jrxmlDocument, elementNode, xmlDocument);
        } else if (type === ElementTypes.TABLE) {
            return createTableElement(jrxmlDocument, elementNode, xmlDocument);
        } else if (type === ElementTypes.MAP) {
            return createMapElement(elementNode, xmlDocument);
        } else if (type === ElementTypes.CVC_ELEMENT) {
            return createCVCElement(elementNode, xmlDocument);
        } else if (type === ElementTypes.AD_HOC_COMPONENT) {
            return createAdhocElement(elementNode, xmlDocument);
        } else if (type === ElementTypes.TIBCO_MAP) {
            return createTibcoMapElement(elementNode, xmlDocument);
        } else if (type === ElementTypes.FUSION_MAP) {
            return createFusionMapElement(elementNode, xmlDocument);
        } else if (type === ElementTypes.COMPONENT_ELEMENT_GENERIC) {
            return createRawComponentElment(elementNode, xmlDocument);
        } else if (type === ElementTypes.ELEMENT_GROUP) {
            return createElementGroup(jrxmlDocument, elementNode, xmlDocument);
        } else if (type === ElementTypes.HTML5CHART_JR) {
            return createHTML5ChartElement(elementNode, xmlDocument);
        } else if (type === ElementTypes.FUSIONCHART) {
            return createFusionChartElement(elementNode, xmlDocument);
        } else if (type === ElementTypes.FUSION_WIDGET) {
            return createFusionWidgetElement(elementNode, xmlDocument);
        }
    }
    return null;
}

const createRawComponentElment = (elementNode: Map<string, any>, xmlDocument: Document): Element => {
    const componentElement = xmlDocument.createElement("componentElement");
    const reportElement = createReportElement(elementNode, xmlDocument);
    componentElement.appendChild(reportElement);
    const content: List<string> = elementNode.get("rawJrxml", null);
    if (content !== null) {
        const parser = new DOMParser();
        content.forEach((node: string) => {
            const nodeXml = parser.parseFromString(node, "text/xml");
            componentElement.appendChild(nodeXml.childNodes.item(0));
        });
    }
    return componentElement;
}

const createList = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const componentElement = xmlDocument.createElement("componentElement");
    const reportElement = createReportElement(elementNode, xmlDocument);
    componentElement.appendChild(reportElement);

    const baseNamespace = elementNode.get(LIST_NAMESPACE, "jr");
    const namespace = baseNamespace + ":";

    const listElement = xmlDocument.createElement(namespace + "list");
    componentElement.appendChild(listElement);

    const datasetRunNode = elementNode.get("datasetRun", null);
    if (datasetRunNode !== null) {
        listElement.appendChild(createDatasetRun(datasetRunNode, xmlDocument));
    }

    const listContentsElement = xmlDocument.createElement(namespace + "listContents");
    listElement.appendChild(listContentsElement);
    if (elementNode.has("listHeight")) {
        listContentsElement.setAttribute("height", elementNode.get("listHeight"));
    }
    if (elementNode.has("listWidth")) {
        listContentsElement.setAttribute("width", elementNode.get("listWidth"));
    }

    // add child elements
    const childrenIds: List<string> = elementNode.get("elementIds", null);
    if (childrenIds !== null) {
        childrenIds.forEach((childId: string) => {
            const childNode: Map<string, any> | null = jrxmlDocument.get("elements").get(childId, null);
            if (childNode !== null) {
                const childElement = createElement(jrxmlDocument, childNode, xmlDocument);
                if (childElement !== null) {
                    listContentsElement.appendChild(childElement);
                }
            }
        });
    }

    setAttributeIfPresent(elementNode, "printOrder", listElement);
    setAttributeIfPresent(elementNode, "ignoreWidth", listElement);

    listElement.setAttribute("xmlns:" + baseNamespace, elementNode.get("xmlns:" + baseNamespace, "http://jasperreports.sourceforge.net/jasperreports/components"));
    listElement.setAttribute("xsi:schemaLocation", elementNode.get("xsi:schemaLocation", "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd"));

    return componentElement;
}


const createSubreport = (elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const subreportElement = xmlDocument.createElement("subreport");
    const reportElement = createReportElement(elementNode, xmlDocument);
    subreportElement.appendChild(reportElement);

    setExpressionNodeFromMap(subreportElement, xmlDocument, elementNode, "parametersMapExpression");

    const subreportParameters: List<Map<string, string>> | null = elementNode.get("subreportParameters", null);
    if (subreportParameters !== null) {
        subreportParameters.forEach((propertyValue: Map<string, string>) => {
            const subreportParameterElement = xmlDocument.createElement("subreportParameter");
            subreportParameterElement.setAttribute("name", propertyValue.get('name'));
            setExpressionNode(subreportParameterElement, xmlDocument, propertyValue.get('expression'), "subreportParameterExpression");
            subreportElement.appendChild(subreportParameterElement);
        });
    }

    setExpressionNodeFromMap(subreportElement, xmlDocument, elementNode, "connectionExpression");
    setExpressionNodeFromMap(subreportElement, xmlDocument, elementNode, "dataSourceExpression");

    const returnValues: List<Map<string, string>> | null = elementNode.get("returnValues", null);
    if (returnValues !== null) {
        returnValues.forEach((propertyValue: Map<string, string>) => {
            const subreportReturnValueElement = xmlDocument.createElement("returnValue");
            setAttributeIfPresent(propertyValue, "toVariable", subreportReturnValueElement);
            setAttributeIfPresent(propertyValue, "calculation", subreportReturnValueElement);
            setAttributeIfPresent(propertyValue, "incrementerFactoryClass", subreportReturnValueElement);
            setAttributeIfPresent(propertyValue, "subreportVariable", subreportReturnValueElement);
            subreportElement.appendChild(subreportReturnValueElement);
        });
    }

    setExpressionNodeFromMap(subreportElement, xmlDocument, elementNode, "subreportExpression");

    setAttributeIfPresent(elementNode, "isUsingCache", subreportElement);
    setAttributeIfPresent(elementNode, "runToBottom", subreportElement);
    setAttributeIfPresent(elementNode, "overflowType", subreportElement);
    return subreportElement;
}

const createFrame = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const frameElement = xmlDocument.createElement("frame");
    const reportElement = createReportElement(elementNode, xmlDocument);
    frameElement.appendChild(reportElement);

    const boxNode: Map<string, any> | null = elementNode.get("box", null);
    if (boxNode !== null) {
        frameElement.appendChild(createBox(boxNode, xmlDocument));
    }

    // add child elements
    const childrenIds: List<string> = elementNode.get("elementIds", null);
    if (childrenIds !== null) {
        childrenIds.forEach((childId: string) => {
            const childNode: Map<string, any> | null = jrxmlDocument.get("elements").get(childId, null);
            if (childNode !== null) {
                const childElement = createElement(jrxmlDocument, childNode, xmlDocument);
                if (childElement !== null) {
                    frameElement.appendChild(childElement);
                }
            }
        });
    }

    setAttributeIfPresent(elementNode, "borderSplitType", frameElement);
    return frameElement;
}

const createElementGroup = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const elementGroupElement = xmlDocument.createElement("elementGroup");

    // add child elements
    const childrenIds: List<string> = elementNode.get("elementIds", null);
    if (childrenIds !== null) {
        childrenIds.forEach((childId: string) => {
            const childNode: Map<string, any> | null = jrxmlDocument.get("elements").get(childId, null);
            if (childNode !== null) {
                const childElement = createElement(jrxmlDocument, childNode, xmlDocument);
                if (childElement !== null) {
                    elementGroupElement.appendChild(childElement);
                }
            }
        });
    }
    return elementGroupElement;
}

const createGenericElement = (elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const genericElement = xmlDocument.createElement("genericElement");
    const reportElement = createReportElement(elementNode, xmlDocument);
    genericElement.appendChild(reportElement);

    const genericElementTypeElement = xmlDocument.createElement("genericElementType");
    setAttributeIfPresent(elementNode, "name", genericElementTypeElement);
    setAttributeIfPresent(elementNode, "namespace", genericElementTypeElement);
    genericElement.appendChild(genericElementTypeElement);

    const parameters: List<Map<string, any>> | null = elementNode.get("genericElementParameters", null);
    if (parameters !== null) {
        parameters.forEach((propertyValue: Map<string, any>) => {

            const genericElementParameterElement = xmlDocument.createElement("genericElementParameter");
            setAttributeIfPresent(propertyValue, "name", genericElementParameterElement);
            setAttributeIfPresent(propertyValue, "skipWhenNull", genericElementParameterElement);
            setExpressionNodeFromMap(genericElementParameterElement, xmlDocument, propertyValue, "valueExpression");
            genericElement.appendChild(genericElementParameterElement);
        });

    }

    setAttributeIfPresent(elementNode, "evaluationTime", genericElement);
    setAttributeIfPresent(elementNode, "evaluationGroup", genericElement);
    return genericElement;
}

const createRectangle = (elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const rectangleElement = xmlDocument.createElement("rectangle");
    const reportElement = createReportElement(elementNode, xmlDocument);
    rectangleElement.appendChild(reportElement);

    const graphicElement = createGraphicElement(elementNode, xmlDocument);
    if (graphicElement !== null) {
        rectangleElement.appendChild(graphicElement);
    }
    setAttributeIfPresent(elementNode, "radius", rectangleElement);
    return rectangleElement;
}

const createEllipse = (elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const ellipseElement = xmlDocument.createElement("ellipse");
    const reportElement = createReportElement(elementNode, xmlDocument);
    ellipseElement.appendChild(reportElement);

    const graphicElement = createGraphicElement(elementNode, xmlDocument);
    if (graphicElement !== null) {
        ellipseElement.appendChild(graphicElement);
    }
    return ellipseElement;
}

const createLine = (elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const lineElement = xmlDocument.createElement("line");
    const reportElement = createReportElement(elementNode, xmlDocument);
    lineElement.appendChild(reportElement);

    const graphicElement = createGraphicElement(elementNode, xmlDocument);
    if (graphicElement !== null) {
        lineElement.appendChild(graphicElement);
    }
    setAttributeIfPresent(elementNode, "direction", lineElement);
    return lineElement;
}

const createGraphicElement = (elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const graphicElement = xmlDocument.createElement("graphicElement");
    const penNode: Map<string, any> | null = elementNode.get("pen", null);
    let hasPen = false;
    if (penNode !== null && !penNode.isEmpty()) {
        graphicElement.appendChild(createPen(penNode, "pen", xmlDocument));
        hasPen = true;
    }
    setXMLAttributeIfPresent(elementNode, "graphicElement_stretchType", "stretchType", graphicElement);
    setXMLAttributeIfPresent(elementNode, "graphicElement_fill", "fill", graphicElement);
    return (hasPen || graphicElement.attributes.length > 0) ? graphicElement : null;
}

const createImage = (elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const imageElement = xmlDocument.createElement("image");
    const reportElement = createReportElement(elementNode, xmlDocument);
    imageElement.appendChild(reportElement);

    const boxNode: Map<string, any> | null = elementNode.get("box", null);
    if (boxNode !== null) {
        imageElement.appendChild(createBox(boxNode, xmlDocument));
    }

    const graphicElement = createGraphicElement(elementNode, xmlDocument);
    if (graphicElement !== null) {
        imageElement.appendChild(graphicElement);
    }

    setExpressionNodeFromMap(imageElement, xmlDocument, elementNode, "imageExpression");

    addHyperlinkAttributes(elementNode, imageElement, xmlDocument);
    setAttributeIfPresent(elementNode, "rotation", imageElement);
    setAttributeIfPresent(elementNode, "scaleImage", imageElement);
    setAttributeIfPresent(elementNode, "hAlign", imageElement);
    setAttributeIfPresent(elementNode, "vAlign", imageElement);
    setAttributeIfPresent(elementNode, "isUsingCache", imageElement);
    setAttributeIfPresent(elementNode, "isLazy", imageElement);
    setAttributeIfPresent(elementNode, "onErrorType", imageElement);
    setAttributeIfPresent(elementNode, "evaluationTime", imageElement);
    setAttributeIfPresent(elementNode, "evaluationGroup", imageElement);

    return imageElement;
}

const createBreak = (elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const breakElement = xmlDocument.createElement("break");
    const reportElement = createReportElement(elementNode, xmlDocument);
    breakElement.appendChild(reportElement);

    setXMLAttributeIfPresent(elementNode, "breakType", "type", breakElement);
    return breakElement;
}

const createStaticText = (elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const staticTextElement = xmlDocument.createElement("staticText");
    const reportElement = createReportElement(elementNode, xmlDocument);
    staticTextElement.appendChild(reportElement);

    const boxNode: Map<string, any> | null = elementNode.get("box", null);
    if (boxNode !== null) {
        staticTextElement.appendChild(createBox(boxNode, xmlDocument));
    }

    const textElement = createTextElement(elementNode, xmlDocument);
    if (textElement !== null) {
        staticTextElement.appendChild(textElement);
    }

    // it is not an expression but the logic it is the same
    setExpressionNodeFromMap(staticTextElement, xmlDocument, elementNode, "text");

    return staticTextElement;
}

const createTextField = (elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const textFieldElement = xmlDocument.createElement("textField");
    const reportElement = createReportElement(elementNode, xmlDocument);
    textFieldElement.appendChild(reportElement);

    const boxNode: Map<string, any> | null = elementNode.get("box", null);
    if (boxNode !== null) {
        textFieldElement.appendChild(createBox(boxNode, xmlDocument));
    }

    const textElement = createTextElement(elementNode, xmlDocument);
    if (textElement !== null) {
        textFieldElement.appendChild(textElement);
    }

    setExpressionNodeFromMap(textFieldElement, xmlDocument, elementNode, "textFieldExpression");
    setExpressionNodeFromMap(textFieldElement, xmlDocument, elementNode, "patternExpression");

    addHyperlinkAttributes(elementNode, textFieldElement, xmlDocument);

    setAttributeIfPresent(elementNode, "isStretchWithOverflow", textFieldElement);
    setAttributeIfPresent(elementNode, "isBlankWhenNull", textFieldElement);
    setAttributeIfPresent(elementNode, "evaluationTime", textFieldElement);
    setAttributeIfPresent(elementNode, "evaluationGroup", textFieldElement);
    setAttributeIfPresent(elementNode, "pattern", textFieldElement);
    setAttributeIfPresent(elementNode, "textAdjust", textFieldElement);

    return textFieldElement;
}

const createTextElement = (elementNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const textElement = xmlDocument.createElement("textElement");
    setAttributeIfPresent(elementNode, "textAlignment", textElement);
    setAttributeIfPresent(elementNode, "verticalAlignment", textElement);
    setAttributeIfPresent(elementNode, "rotation", textElement);
    setAttributeIfPresent(elementNode, "markup", textElement);
    setAttributeIfPresent(elementNode, "lineSpacing", textElement);
    setAttributeIfPresent(elementNode, "isStyledText", textElement);

    let childSet = false;

    const fontNode = elementNode.get("font", null);
    if (fontNode !== null && !fontNode.isEmpty()) {
        const fontElement = createFontElement(fontNode, xmlDocument);
        if (fontElement !== null) {
            childSet = true;
            textElement.appendChild(fontElement);
        }
    }

    const paragraphNode = elementNode.get("paragraph", null);
    if (paragraphNode !== null && !paragraphNode.isEmpty()) {
        const paragraphElement = createParagraphElement(paragraphNode, xmlDocument);
        if (paragraphElement !== null) {
            childSet = true;
            textElement.appendChild(paragraphElement);
        }
    }

    if (childSet || textElement.attributes.length > 0) {
        return textElement;
    }
    return null;
}

const createParagraphElement = (paragraphNode: Map<string, any>, xmlDocument: Document): Element | null => {
    const paragraphElement = xmlDocument.createElement("paragraph");

    setAttributeIfPresent(paragraphNode, "lineSpacing", paragraphElement);
    setAttributeIfPresent(paragraphNode, "lineSpacingSize", paragraphElement);
    setAttributeIfPresent(paragraphNode, "firstLineIndent", paragraphElement);
    setAttributeIfPresent(paragraphNode, "leftIndent", paragraphElement);
    setAttributeIfPresent(paragraphNode, "rightIndent", paragraphElement);
    setAttributeIfPresent(paragraphNode, "spacingBefore", paragraphElement);
    setAttributeIfPresent(paragraphNode, "spacingAfter", paragraphElement);
    setAttributeIfPresent(paragraphNode, "tabStopWidth", paragraphElement);

    let tabStopFound = false;
    const tabStops: List<Map<string, string | number | null>> = paragraphNode.get("tabStops", null);
    if (tabStops !== null) {
        tabStops.forEach((propertyValue: Map<string, string | number | null>) => {
            const tabStopElement = xmlDocument.createElement("tabStop");
            tabStopElement.setAttribute("position", String(propertyValue.get('position')));
            if (propertyValue.get('alignment') !== null) {
                tabStopElement.setAttribute("alignment", propertyValue.get('alignment') as string);
            }
            paragraphElement.appendChild(tabStopElement);
            tabStopFound = true;
        });

    }

    return (tabStopFound || paragraphElement.attributes.length > 0) ? paragraphElement : null;
}

const createBaseStyle = (elementNode: Map<string, any>, styleElement: Element, xmlDocument: Document) => {
    setAttributeIfPresent(elementNode, "name", styleElement);
    setAttributeIfPresent(elementNode, "style", styleElement);
    setAttributeIfPresent(elementNode, "mode", styleElement);
    setAttributeIfPresent(elementNode, "forecolor", styleElement);
    setAttributeIfPresent(elementNode, "backcolor", styleElement);
    setAttributeIfPresent(elementNode, "scaleImage", styleElement);
    setAttributeIfPresent(elementNode, "hAlign", styleElement);
    setAttributeIfPresent(elementNode, "hTextAlign", styleElement);
    setAttributeIfPresent(elementNode, "hImageAlign", styleElement);
    setAttributeIfPresent(elementNode, "vAlign", styleElement);
    setAttributeIfPresent(elementNode, "vTextAlign", styleElement);
    setAttributeIfPresent(elementNode, "vImageAlign", styleElement);
    setAttributeIfPresent(elementNode, "border", styleElement);
    setAttributeIfPresent(elementNode, "borderColor", styleElement);
    setAttributeIfPresent(elementNode, "topBorder", styleElement);
    setAttributeIfPresent(elementNode, "topBorderColor", styleElement);
    setAttributeIfPresent(elementNode, "leftBorder", styleElement);
    setAttributeIfPresent(elementNode, "leftBorderColor", styleElement);
    setAttributeIfPresent(elementNode, "bottomBorder", styleElement);
    setAttributeIfPresent(elementNode, "bottomBorderColor", styleElement);
    setAttributeIfPresent(elementNode, "rightBorder", styleElement);
    setAttributeIfPresent(elementNode, "rightBorderColor", styleElement);
    setAttributeIfPresent(elementNode, "rotation", styleElement);
    setAttributeIfPresent(elementNode, "lineSpacing", styleElement);
    setAttributeIfPresent(elementNode, "markup", styleElement);
    setAttributeIfPresent(elementNode, "fontName", styleElement);
    setAttributeIfPresent(elementNode, "pdfFontName", styleElement);
    setAttributeIfPresent(elementNode, "pdfEncoding", styleElement);
    setAttributeIfPresent(elementNode, "pattern", styleElement);
    setAttributeIfPresent(elementNode, "rightBorder", styleElement);
    setAttributeIfPresent(elementNode, "rightBorder", styleElement);
    setAttributeIfPresent(elementNode, "rightBorder", styleElement);
    setAttributeIfPresent(elementNode, "isBlankWhenNull", styleElement);
    setAttributeIfPresent(elementNode, "isDefault", styleElement);
    setAttributeIfPresent(elementNode, "isStyledText", styleElement);
    setAttributeIfPresent(elementNode, "isBold", styleElement);
    setAttributeIfPresent(elementNode, "isItalic", styleElement);
    setAttributeIfPresent(elementNode, "isUnderline", styleElement);
    setAttributeIfPresent(elementNode, "isStrikeThrough", styleElement);
    setAttributeIfPresent(elementNode, "isBlankWhenNull", styleElement);
    setAttributeIfPresent(elementNode, "isPdfEmbedded", styleElement);
    setAttributeIfPresent(elementNode, "fontSize", styleElement);
    setAttributeIfPresent(elementNode, "rightPadding", styleElement);
    setAttributeIfPresent(elementNode, "bottomPadding", styleElement);
    setAttributeIfPresent(elementNode, "leftPadding", styleElement);
    setAttributeIfPresent(elementNode, "topPadding", styleElement);
    setAttributeIfPresent(elementNode, "padding", styleElement);
    setAttributeIfPresent(elementNode, "radius", styleElement);

    const penNode: Map<string, any> | null = elementNode.get("pen", null);
    if (penNode !== null && !penNode.isEmpty()) {
        styleElement.appendChild(createPen(penNode, "pen", xmlDocument));
    }

    const boxNode: Map<string, any> | null = elementNode.get("box", null);
    if (boxNode !== null && !boxNode.isEmpty()) {
        styleElement.appendChild(createBox(boxNode, xmlDocument));
    }

    const paragraphNode = elementNode.get("paragraph", null);
    if (paragraphNode !== null && !paragraphNode.isEmpty()) {
        const paragraphElement = createParagraphElement(paragraphNode, xmlDocument);
        if (paragraphElement !== null) {
            styleElement.appendChild(paragraphElement);
        }
    }
}

const createStyle = (elementNode: Map<string, any>, xmlDocument: Document): Element => {
    const styleElement = xmlDocument.createElement("style");
    createBaseStyle(elementNode, styleElement, xmlDocument);

    const conditionalStyles: List<Map<string, any>> | null = elementNode.get("conditionalStyles", null);
    if (conditionalStyles !== null) {
        conditionalStyles.forEach((propertyValue: Map<string, any>) => {
            const conditionalStyleElement = xmlDocument.createElement("conditionalStyle");
            setExpressionNodeFromMap(conditionalStyleElement, xmlDocument, propertyValue, "conditionExpression");
            const conditionalInnerStyle = xmlDocument.createElement("style");
            createBaseStyle(propertyValue, conditionalInnerStyle, xmlDocument);
            conditionalStyleElement.appendChild(conditionalInnerStyle);
            styleElement.appendChild(conditionalStyleElement);
        });

    }

    return styleElement;
}

const createBand = (jrxmlDocument: Map<string, any>, bandType: string, bandNode: Map<string, any>, xmlDocument: Document): Element => {
    const bandTagName = getBandJRTypeFromModel(bandType);
    const alreadyExistingBand = xmlDocument.getElementsByName(bandTagName);
    let bandElement: HTMLElement;
    if (alreadyExistingBand.length > 0) {
        bandElement = alreadyExistingBand.item(0);
    } else {
        bandElement = xmlDocument.createElement(bandTagName);
    }
    bandElement.appendChild(createInnerBand(jrxmlDocument, bandNode, xmlDocument));
    return bandElement;
}

const createPart = (jrxmlDocument: Map<string, any>, partNode: Map<string, any>, xmlDocument: Document): Element => {
    const partElement = xmlDocument.createElement("part");
    setAttributeIfPresent(partNode, "evaluationTime", partElement);
    setAttributeIfPresent(partNode, "evaluationGroup", partElement);
    setAttributeIfPresent(partNode, "uuid", partElement);

    let uuid = partNode.get('uuid');
    if (!uuid) {
        uuid = uuidv4();
    }
    partElement.setAttribute('uuid', uuid);

    setExpressionNode(partElement, xmlDocument, partNode.get("partNameExpression", null), "partNameExpression");
    setExpressionNode(partElement, xmlDocument, partNode.get("printWhenExpression", null), "printWhenExpression");
    const subreportElement = xmlDocument.createElement("p:subreportPart");
    subreportElement.setAttribute("xmlns:p", "http://jasperreports.sourceforge.net/jasperreports/parts");
    subreportElement.setAttribute("xsi:schemaLocation", "http://jasperreports.sourceforge.net/jasperreports/parts http://jasperreports.sourceforge.net/xsd/parts.xsd");


    setExpressionNodeFromMap(subreportElement, xmlDocument, partNode, "parametersMapExpression");

    const subreportParameters: List<{ name: string, expression: string }> | null = partNode.get("subreportParameters");
    if (subreportParameters && subreportParameters !== null) {
        subreportParameters.forEach((propertyValue: { name: string, expression: string }) => {
            const subreportParameterElement = xmlDocument.createElement("subreportParameter");
            subreportParameterElement.setAttribute("name", propertyValue.name);
            setExpressionNode(subreportParameterElement, xmlDocument, propertyValue.expression, "subreportParameterExpression");
            subreportElement.appendChild(subreportParameterElement);
        });
    }

    const returnValues: List<Map<string, string>> | null = partNode.get("returnValues");
    if (returnValues && returnValues !== null) {
        returnValues.forEach((propertyValue: Map<string, string>) => {
            const subreportReturnValueElement = xmlDocument.createElement("returnValue");
            setAttributeIfPresent(propertyValue, "toVariable", subreportReturnValueElement);
            setAttributeIfPresent(propertyValue, "calculation", subreportReturnValueElement);
            setAttributeIfPresent(propertyValue, "incrementerFactoryClass", subreportReturnValueElement);
            setAttributeIfPresent(propertyValue, "subreportVariable", subreportReturnValueElement);
            subreportElement.appendChild(subreportReturnValueElement);
        });
    }

    setExpressionNodeFromMap(subreportElement, xmlDocument, partNode, "subreportExpression");


    partElement.appendChild(subreportElement);
    setAttributeIfPresent(partNode, "usingCache", subreportElement);
    return partElement;
}

const createInnerBand = (jrxmlDocument: Map<string, any>, bandNode: Map<string, any>, xmlDocument: Document): Element => {
    const bandInnerElement = xmlDocument.createElement("band");
    setAttributeIfPresent(bandNode, "height", bandInnerElement);
    setAttributeIfPresent(bandNode, "splitType", bandInnerElement);
    setAttributeIfPresent(bandNode, "isSplitAllowed", bandInnerElement);
    setExpressionNode(bandInnerElement, xmlDocument, bandNode.get("printWhenExpression", null), "printWhenExpression");

    const properties: OrderedMap<string, string | null> = bandNode.get("properties", null);
    createProperties(bandInnerElement, properties, xmlDocument);

    // add child elements
    const childrenIds: List<string> = bandNode.get("elementIds", null);
    if (childrenIds !== null) {
        childrenIds.forEach((childId: string) => {
            const childNode: Map<string, any> | null = jrxmlDocument.get("elements").get(childId, null);
            if (childNode !== null) {
                const childElement = createElement(jrxmlDocument, childNode, xmlDocument);
                if (childElement !== null) {
                    bandInnerElement.appendChild(childElement);
                }
            }
        });
    }

    const returnValues: List<Map<string, string>> = bandNode.get("returnValues", null);
    if (returnValues !== null) {
        returnValues.forEach((retrunValue: Map<string, string>) => {
            const returnValueElement = xmlDocument.createElement("returnValue");
            setAttributeIfPresent(retrunValue, "toVariable", returnValueElement);
            setAttributeIfPresent(retrunValue, "calculation", returnValueElement);
            setAttributeIfPresent(retrunValue, "incrementerFactoryClass", returnValueElement);
            setExpressionNode(returnValueElement, xmlDocument, retrunValue.get("expression", null), "expression");
            bandInnerElement.appendChild(returnValueElement);
        });
    }

    return bandInnerElement;
}

const createGroups = (jrxmlDocument: Map<string, any>, rootElement: Element, xmlDoc: Document) => {
    const groups = jrxmlDocument.get("groups", null) as List<Map<string, any>> | null;
    if (groups !== null) {
        const groupHeadersBands = jrxmlDocument.get("groupHeadersOrder", null) as List<string>;
        const groupFootersBands = jrxmlDocument.get("groupFootersOrder", null) as List<string>;
        const bands: Map<string, Map<string, any>> | null = jrxmlDocument.get("bands", null);
        const bookSections: Map<string, Map<string, any>> | undefined = jrxmlDocument.get('bookSections');
        groups.forEach((value: Map<string, any>) => {
            const groupElement = xmlDoc.createElement("group");
            const groupNode = value as Map<string, any>;
            const groupName = groupNode.get("name") as string;
            groupElement.setAttribute("name", groupName);
            setAttributeIfPresent(groupNode, "isStartNewColumn", groupElement);
            setAttributeIfPresent(groupNode, "isStartNewPage", groupElement);
            setAttributeIfPresent(groupNode, "isResetPageNumber", groupElement);
            setAttributeIfPresent(groupNode, "preventOrphanFooter", groupElement);
            setAttributeIfPresent(groupNode, "isReprintHeaderOnEachPage", groupElement);
            setAttributeIfPresent(groupNode, "isReprintHeaderOnEachColumn", groupElement);
            setAttributeIfPresent(groupNode, "keepTogether", groupElement);
            setAttributeIfPresent(groupNode, "minHeightToStartNewPage", groupElement);
            setAttributeIfPresent(groupNode, "minDetailsToStartFromTop", groupElement);
            setAttributeIfPresent(groupNode, "footerPosition", groupElement);
            setExpressionNode(groupElement, xmlDoc, groupNode.get("groupExpression"), "groupExpression");
            let groupHeaderElement: Element | null = null;
            let groupFooterElement: Element | null = null;
            if (bookSections && bookSections.size > 0) {
                //it is a book
                const groupHeaderSection = bookSections.get("groupHeaderSection_" + groupName);
                if (groupHeaderSection) {
                    const childParts: List<string> = groupHeaderSection.get('elementIds');
                    childParts.forEach((childPartId) => {
                        const childPart: Map<string, any> = jrxmlDocument.get("elements").get(childPartId, null);
                        if (childPart) {
                            const partElement = createPart(jrxmlDocument, childPart, xmlDoc);
                            if (groupHeaderElement === null) {
                                groupHeaderElement = xmlDoc.createElement("groupHeader");
                            }
                            groupHeaderElement.appendChild(partElement);
                        }
                    });
                }

                const groupFooterSection = bookSections.get("groupFooterSection_" + groupName);
                if (groupFooterSection) {
                    const childParts: List<string> = groupFooterSection.get('elementIds');
                    childParts.forEach((childPartId) => {
                        const childPart: Map<string, any> = jrxmlDocument.get("elements").get(childPartId, null);
                        if (childPart) {
                            const partElement = createPart(jrxmlDocument, childPart, xmlDoc);
                            if (groupFooterElement === null) {
                                groupFooterElement = xmlDoc.createElement("groupFooter");
                            }
                            groupFooterElement.appendChild(partElement);
                        }
                    });
                }
            } else {
                //it is a standard report
                groupHeadersBands.forEach((bandName) => {
                    const band: Map<string, any> = bands.get(bandName);
                    if (band.get("groupName", null) === groupName) {
                        if (groupHeaderElement === null) {
                            groupHeaderElement = xmlDoc.createElement("groupHeader");
                        }
                        groupHeaderElement.appendChild(createInnerBand(jrxmlDocument, band, xmlDoc));
                    }
                });
                groupFootersBands.forEach((bandName) => {
                    const band: Map<string, any> = bands.get(bandName);
                    if (band.get("groupName", null) === groupName) {
                        if (groupFooterElement === null) {
                            groupFooterElement = xmlDoc.createElement("groupFooter");
                        }
                        groupFooterElement.appendChild(createInnerBand(jrxmlDocument, band, xmlDoc));
                    }
                });
            }
            if (groupHeaderElement !== null) {
                groupElement.appendChild(groupHeaderElement);
            }
            if (groupFooterElement !== null) {
                groupElement.appendChild(groupFooterElement);
            }

            rootElement.appendChild(groupElement);
        });
    }
}

const createScriptlet = (elementNode: Map<string, any>, xmlDocument: Document) => {
    const scriptletElement = xmlDocument.createElement("scriptlet");

    const propertiesExpression: OrderedMap<string, Map<string, any>> = elementNode.get("properties", null);
    createPropertiesExpression(scriptletElement, propertiesExpression, xmlDocument);

    setExpressionNodeFromMap(scriptletElement, xmlDocument, elementNode, "scriptletDescription");
    setAttributeIfPresent(elementNode, "name", scriptletElement);
    setAttributeIfPresent(elementNode, "class", scriptletElement);
    return scriptletElement;
}

const createField = (elementNode: Map<string, any>, xmlDocument: Document) => {
    const fieldElement = xmlDocument.createElement("field");

    const propertiesExpression: OrderedMap<string, Map<string, any>> = elementNode.get("properties", null);
    createPropertiesExpression(fieldElement, propertiesExpression, xmlDocument);

    setExpressionNodeFromMap(fieldElement, xmlDocument, elementNode, "fieldDescription");
    setAttributeIfPresent(elementNode, "name", fieldElement);
    setAttributeIfPresent(elementNode, "class", fieldElement);
    return fieldElement;
}

const createParameter = (elementNode: Map<string, any>, xmlDocument: Document) => {
    const parameterElement = xmlDocument.createElement("parameter");

    const propertiesExpression: OrderedMap<string, Map<string, any>> = elementNode.get("properties", null);
    createPropertiesExpression(parameterElement, propertiesExpression, xmlDocument);

    setExpressionNodeFromMap(parameterElement, xmlDocument, elementNode, "parameterDescription");
    setExpressionNodeFromMap(parameterElement, xmlDocument, elementNode, "defaultValueExpression");
    setAttributeIfPresent(elementNode, "isForPrompting", parameterElement);
    setAttributeIfPresent(elementNode, "evaluationTime", parameterElement);
    setAttributeIfPresent(elementNode, "name", parameterElement);
    setAttributeIfPresent(elementNode, "class", parameterElement);
    setAttributeIfPresent(elementNode, "nestedType", parameterElement);
    return parameterElement;
}

const createSortField = (elementNode: Map<string, any>, xmlDocument: Document) => {
    const sortFieldElement = xmlDocument.createElement("sortField");
    setAttributeIfPresent(elementNode, "name", sortFieldElement);
    setAttributeIfPresent(elementNode, "order", sortFieldElement);
    setXMLAttributeIfPresent(elementNode, "sortFieldType", "type", sortFieldElement);
    return sortFieldElement;
}

const createVariable = (elementNode: Map<string, any>, xmlDocument: Document) => {
    const variableElement = xmlDocument.createElement("variable");
    setAttributeIfPresent(elementNode, "name", variableElement);
    setAttributeIfPresent(elementNode, "resetType", variableElement);
    setAttributeIfPresent(elementNode, "resetGroup", variableElement);
    setAttributeIfPresent(elementNode, "class", variableElement);
    setAttributeIfPresent(elementNode, "incrementType", variableElement);
    setAttributeIfPresent(elementNode, "incrementGroup", variableElement);
    setAttributeIfPresent(elementNode, "calculation", variableElement);
    setAttributeIfPresent(elementNode, "incrementerFactoryClass", variableElement);
    setExpressionNodeFromMap(variableElement, xmlDocument, elementNode, "variableDescription");
    setExpressionNodeFromMap(variableElement, xmlDocument, elementNode, "variableExpression");
    setExpressionNodeFromMap(variableElement, xmlDocument, elementNode, "initialValueExpression");
    return variableElement;
}

const createSubdatasetGroup = (elementNode: Map<string, any>, xmlDocument: Document) => {
    const groupElement = xmlDocument.createElement("group");
    setAttributeIfPresent(elementNode, "name", groupElement);
    setAttributeIfPresent(elementNode, "keepTogether", groupElement);
    setAttributeIfPresent(elementNode, "isStartNewColumn", groupElement);
    setAttributeIfPresent(elementNode, "isStartNewPage", groupElement);
    setAttributeIfPresent(elementNode, "isResetPageNumber", groupElement);
    setAttributeIfPresent(elementNode, "isReprintHeaderOnEachPage", groupElement);
    setAttributeIfPresent(elementNode, "isReprintHeaderOnEachColumn", groupElement);
    setAttributeIfPresent(elementNode, "minHeightToStartNewPage", groupElement);
    setAttributeIfPresent(elementNode, "minDetailsToStartFromTop", groupElement);
    setAttributeIfPresent(elementNode, "footerPosition", groupElement);
    setAttributeIfPresent(elementNode, "preventOrphanFooter", groupElement);

    setExpressionNodeFromMap(groupElement, xmlDocument, elementNode, "groupExpression");
    return groupElement;
}

const createSubdataset = (elementNode: Map<string, any>, xmlDocument: Document) => {
    const subDataset = xmlDocument.createElement("subDataset");

    const properties: OrderedMap<string, Map<string, any>> = elementNode.get("properties", null);

    createPropertiesExpression(subDataset, properties, xmlDocument);

    const scriptlets: List<Map<string, any>> = elementNode.get("scriptlets", null);
    if (scriptlets !== null) {
        scriptlets.forEach((value: Map<string, any>) => {
            subDataset.appendChild(createScriptlet(value, xmlDocument));
        });
    }

    const parameters: List<Map<string, any>> = elementNode.get("parameters", null);
    if (parameters !== null) {
        parameters.forEach((value: Map<string, any>) => {
            subDataset.appendChild(createParameter(value, xmlDocument));
        });
    }

    const queryString = elementNode.get("queryString", null);
    const language = elementNode.get("queryLanguage", null);
    if (queryString !== null || language !== null) {
        const queryElement = xmlDocument.createElement("queryString");
        const queryContent = xmlDocument.createCDATASection(queryString !== null ? queryString : '');
        queryElement.appendChild(queryContent);
        if (language !== null && language.trim().length > 0) {
            queryElement.setAttribute("language", language);
        }
        subDataset.appendChild(queryElement);
    }

    const fields: List<Map<string, any>> = elementNode.get("fields", null);
    if (fields !== null) {
        fields.forEach((value: Map<string, any>) => {
            subDataset.appendChild(createField(value, xmlDocument));
        });
    }

    const sortFields: List<Map<string, any>> = elementNode.get("sortFields", null);
    if (sortFields !== null) {
        sortFields.forEach((value: Map<string, any>) => {
            subDataset.appendChild(createSortField(value, xmlDocument));
        });
    }

    const variables: List<Map<string, any>> = elementNode.get("variables", null);
    if (variables !== null) {
        variables.forEach((value: Map<string, any>) => {
            subDataset.appendChild(createVariable(value, xmlDocument));
        });
    }

    setExpressionNodeFromMap(subDataset, xmlDocument, elementNode, "filterExpression");

    const groups: List<Map<string, any>> = elementNode.get("groups", null);
    if (groups !== null) {
        groups.forEach((value: Map<string, any>) => {
            subDataset.appendChild(createSubdatasetGroup(value, xmlDocument));
        });
    }

    let uuid = elementNode.get('uuid');
    if (!uuid) {
        uuid = uuidv4();
    }
    subDataset.setAttribute('uuid', uuid);

    setAttributeIfPresent(elementNode, "name", subDataset);
    setAttributeIfPresent(elementNode, "scriptletClass", subDataset);
    setAttributeIfPresent(elementNode, "resourceBundle", subDataset);
    setAttributeIfPresent(elementNode, "whenResourceMissingType", subDataset);

    return subDataset;
}

const getOrderedBands = (model: Map<string, any>) => {
    //custom get ordered bands, in the jrxml the background is the first band
    const bands: Map<string, Map<string, any>> = model.get('bands');
    const groupHeadersOrder: List<string> = model.get('groupHeadersOrder', List<string>());
    const detailsOrder: List<string> = model.get('detailsOrder', List<string>());
    const groupFootersOrder: List<string> = model.get('groupFootersOrder', List<string>());
    const orderedBands = [];
    if (bands.has('background')) {
        orderedBands.push('background');
    }
    if (bands.has('title')) {
        orderedBands.push('title');
    }
    if (bands.has('pageHeader')) {
        orderedBands.push('pageHeader');
    }
    if (bands.has('columnHeader')) {
        orderedBands.push('columnHeader');
    }
    groupHeadersOrder.forEach((bandName: string) => {
        orderedBands.push(bandName);
    });
    detailsOrder.forEach((bandName: string) => {
        orderedBands.push(bandName);
    });
    groupFootersOrder.forEach((bandName: string) => {
        orderedBands.push(bandName);
    });
    if (bands.has('columnFooter')) {
        orderedBands.push('columnFooter');
    }
    if (bands.has('pageFooter')) {
        orderedBands.push('pageFooter');
    }
    if (bands.has('lastPageFooter')) {
        orderedBands.push('lastPageFooter');
    }
    if (bands.has('summary')) {
        orderedBands.push('summary');
    }
    if (bands.has('noData')) {
        orderedBands.push('noData');
    }
    return List<string>(orderedBands);
}

const createJasperReportsNode = (jrxmlDocument: Map<string, any>, rootElement: Element, xmlDoc: Document) => {
    rootElement.setAttribute("xmlns:xsi", jrxmlDocument.get("xmlns:xsi", "http://www.w3.org/2001/XMLSchema-instance"));
    rootElement.setAttribute("xsi:schemaLocation", jrxmlDocument.get("xsi:schemaLocation", "http://jasperreports.sourceforge.net/jasperreports http://jasperreports.sourceforge.net/xsd/jasperreport.xsd"));

    const bookSections: Map<string, Map<string, any>> | null = jrxmlDocument.get('bookSections');
    if (bookSections && bookSections.size > 0) {
        rootElement.setAttribute('sectionType', 'Part');
    }

    setXMLAttributeIfPresent(jrxmlDocument, "docWidth", "pageWidth", rootElement);
    setXMLAttributeIfPresent(jrxmlDocument, "docHeight", "pageHeight", rootElement);

    setAttributeIfPresent(jrxmlDocument, "name", rootElement);
    setAttributeIfPresent(jrxmlDocument, "language", rootElement, true);
    setAttributeIfPresent(jrxmlDocument, "printOrder", rootElement);
    setAttributeIfPresent(jrxmlDocument, "columnDirection", rootElement);
    setAttributeIfPresent(jrxmlDocument, "uuid", rootElement);
    setAttributeIfPresent(jrxmlDocument, "formatFactoryClass", rootElement);
    setAttributeIfPresent(jrxmlDocument, "whenResourceMissingType", rootElement);
    setAttributeIfPresent(jrxmlDocument, "resourceBundle", rootElement);
    setAttributeIfPresent(jrxmlDocument, "scriptletClass", rootElement);
    setAttributeIfPresent(jrxmlDocument, "whenNoDataType", rootElement);
    setAttributeIfPresent(jrxmlDocument, "sectionType", rootElement);
    setAttributeIfPresent(jrxmlDocument, "orientation", rootElement);
    setAttributeIfPresent(jrxmlDocument, "isIgnorePagination", rootElement);
    setAttributeIfPresent(jrxmlDocument, "isFloatColumnFooter", rootElement);
    setAttributeIfPresent(jrxmlDocument, "isSummaryWithPageHeaderAndFooter", rootElement);
    setAttributeIfPresent(jrxmlDocument, "isSummaryNewPage", rootElement);
    setAttributeIfPresent(jrxmlDocument, "isTitleNewPage", rootElement);
    setAttributeIfPresent(jrxmlDocument, "columnCount", rootElement);
    setAttributeIfPresent(jrxmlDocument, "columnWidth", rootElement);
    setAttributeIfPresent(jrxmlDocument, "columnSpacing", rootElement);
    setAttributeIfPresent(jrxmlDocument, "leftMargin", rootElement);
    setAttributeIfPresent(jrxmlDocument, "rightMargin", rootElement);
    setAttributeIfPresent(jrxmlDocument, "topMargin", rootElement);
    setAttributeIfPresent(jrxmlDocument, "bottomMargin", rootElement);

    let uuid = jrxmlDocument.get('uuid');
    if (!uuid) {
        uuid = uuidv4();
    }
    rootElement.setAttribute('uuid', uuid);

    // FIXME: commented the following line because firefox does not allow to manually set the element xmlns
    // rootElement.setAttribute("xmlns", jrxmlDocument.get("xmlns", "http://jasperreports.sourceforge.net/jasperreports"));

    const properties: OrderedMap<string, Map<string, any>> = jrxmlDocument.get("properties", null);

    createPropertiesExpression(rootElement, properties, xmlDoc);

    const imports: List<string> = jrxmlDocument.get("imports", null);
    if (imports !== null) {
        imports.forEach((value: string) => {
            const importElement = xmlDoc.createElement("import");
            importElement.setAttribute("value", value);
            rootElement.appendChild(importElement);
        });
    }

    const reportFonts: List<Map<string, any>> = jrxmlDocument.get("reportFonts", null);
    if (reportFonts !== null) {
        reportFonts.forEach((value: Map<string, any>) => {
            const reportFont = createReportFontElement(value, xmlDoc);
            if (reportFont !== null) {
                rootElement.appendChild(reportFont);
            }
        });
    }

    const templateStyles: List<Map<string, any>> = jrxmlDocument.get("templateStyles", null);
    if (templateStyles !== null) {
        templateStyles.forEach((value: Map<string, any>) => {
            const templateElement = xmlDoc.createElement("template");
            const expressionContent = xmlDoc.createCDATASection(value.get('expression'));
            templateElement.appendChild(expressionContent);
            rootElement.appendChild(templateElement);
        });
    }

    const styles: List<Map<string, any>> = jrxmlDocument.get("styles", null);
    if (styles !== null) {
        styles.forEach((value: Map<string, any>) => {
            rootElement.appendChild(createStyle(value, xmlDoc));
        });
    }

    const subdatasets: List<Map<string, any>> = jrxmlDocument.get("subdatasets", null);
    if (subdatasets !== null) {
        subdatasets.forEach((value: Map<string, any>) => {
            rootElement.appendChild(createSubdataset(value, xmlDoc));
        });
    }

    const scriptlets: List<Map<string, any>> = jrxmlDocument.get("scriptlets", null);
    if (scriptlets !== null) {
        scriptlets.forEach((value: Map<string, any>) => {
            rootElement.appendChild(createScriptlet(value, xmlDoc));
        });
    }

    const parameters: List<Map<string, any>> = jrxmlDocument.get("parameters", null);
    if (parameters !== null) {
        parameters.forEach((value: Map<string, any>) => {
            rootElement.appendChild(createParameter(value, xmlDoc));
        });
    }

    const queryString = jrxmlDocument.get("queryString", null);
    const language = jrxmlDocument.get("queryLanguage", null);
    if (queryString !== null || language !== null) {
        const queryElement = xmlDoc.createElement("queryString");
        const queryContent = xmlDoc.createCDATASection(queryString !== null ? queryString : '');
        queryElement.appendChild(queryContent);
        if (language !== null && language.trim().length > 0) {
            queryElement.setAttribute("language", language);
        }
        rootElement.appendChild(queryElement);
    }

    const fields: List<Map<string, any>> = jrxmlDocument.get("fields", null);
    if (fields !== null) {
        fields.forEach((value: Map<string, any>) => {
            rootElement.appendChild(createField(value, xmlDoc));
        });
    }

    const sortFields: List<Map<string, any>> = jrxmlDocument.get("sortFields", null);
    if (sortFields !== null) {
        sortFields.forEach((value: Map<string, any>) => {
            rootElement.appendChild(createSortField(value, xmlDoc));
        });
    }

    const variables: List<Map<string, any>> = jrxmlDocument.get("variables", null);
    if (variables !== null) {
        variables.forEach((value: Map<string, any>) => {
            rootElement.appendChild(createVariable(value, xmlDoc));
        });
    }

    setExpressionNodeFromMap(rootElement, xmlDoc, jrxmlDocument, "filterExpression");

    createGroups(jrxmlDocument, rootElement, xmlDoc);

    if (bookSections.size > 0) {
        //create the detail book section (the other are already created by the group)
        const detailBookSection: Map<string, any> = bookSections.get("detailSection");
        if (detailBookSection) {
            const childParts: List<string> = detailBookSection.get('elementIds');
            const detailBand = xmlDoc.createElement("detail");
            childParts.forEach((childPartId) => {
                const childPart: Map<string, any> = jrxmlDocument.get("elements").get(childPartId, null);
                if (childPart) {
                    const partElement = createPart(jrxmlDocument, childPart, xmlDoc);
                    detailBand.appendChild(partElement);
                }
            });
            rootElement.appendChild(detailBand);
        }
    } else {
        // create the bands
        const bands: Map<string, Map<string, any>> | null = jrxmlDocument.get("bands", null);
        if (bands !== null) {
            const orderedBands = getOrderedBands(jrxmlDocument);
            orderedBands.forEach((bandKey: string) => {
                const band = bands.get(bandKey);
                if (!band.has("groupName")) {
                    // check the band is not a group
                    const bandType = band.get("bandType") as string;
                    const bandElement = createBand(jrxmlDocument, bandType, band, xmlDoc);
                    rootElement.appendChild(bandElement);
                }
            });
        }
    }
}