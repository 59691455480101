/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const DoughnutChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="802"
      height="493"
      version="1.1"
      style={{
         WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
         WebkitUserSelect: "none",
         MozUserSelect: "none",
         userSelect: "none",
         position: "relative",
      }}
      cursor="default"
      overflow="hidden"
      viewBox="0 0 802 493"
      {...props}
   >
      <defs>
         <linearGradient
            id="19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100"
            x1="0"
            x2="0"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#cbcbcb" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#e9e9e9" stopOpacity="0.5"></stop>
         </linearGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_145_179_205_1__50-rgba_211_234_251_1__62-rgba_211_234_251_1__88-rgba_145_179_205_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="50%" stopColor="#91b3cd"></stop>
            <stop offset="62%" stopColor="#d3eafb"></stop>
            <stop offset="88%" stopColor="#d3eafb"></stop>
            <stop offset="100%" stopColor="#91b3cd"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_204_156_12_1__50-rgba_250_219_123_1__62-rgba_250_219_123_1__88-rgba_204_156_12_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="50%" stopColor="#cc9c0c"></stop>
            <stop offset="62%" stopColor="#fadb7b"></stop>
            <stop offset="88%" stopColor="#fadb7b"></stop>
            <stop offset="100%" stopColor="#cc9c0c"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_115_154_0_1__50-rgba_191_217_115_1__62-rgba_191_217_115_1__88-rgba_115_154_0_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="50%" stopColor="#739a00"></stop>
            <stop offset="62%" stopColor="#bfd973"></stop>
            <stop offset="88%" stopColor="#bfd973"></stop>
            <stop offset="100%" stopColor="#739a00"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_211_117_58_1__50-rgba_255_193_153_1__62-rgba_255_193_153_1__88-rgba_211_117_58_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="50%" stopColor="#d3753a"></stop>
            <stop offset="62%" stopColor="#ffc199"></stop>
            <stop offset="88%" stopColor="#ffc199"></stop>
            <stop offset="100%" stopColor="#d3753a"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_0_117_117_1__50-rgba_115_193_193_1__62-rgba_115_193_193_1__88-rgba_0_117_117_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="50%" stopColor="#007575"></stop>
            <stop offset="62%" stopColor="#73c1c1"></stop>
            <stop offset="88%" stopColor="#73c1c1"></stop>
            <stop offset="100%" stopColor="#007575"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_177_58_58_1__50-rgba_232_153_153_1__62-rgba_232_153_153_1__88-rgba_177_58_58_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="50%" stopColor="#b13a3a"></stop>
            <stop offset="62%" stopColor="#e89999"></stop>
            <stop offset="88%" stopColor="#e89999"></stop>
            <stop offset="100%" stopColor="#b13a3a"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_117_58_117_1__50-rgba_193_153_193_1__62-rgba_193_153_193_1__88-rgba_117_58_117_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="50%" stopColor="#753a75"></stop>
            <stop offset="62%" stopColor="#c199c1"></stop>
            <stop offset="88%" stopColor="#c199c1"></stop>
            <stop offset="100%" stopColor="#753a75"></stop>
         </radialGradient>
         <radialGradient
            id="19-xr___174_401_266_userSpaceOnUse_rgba_73_110_31_1__50-rgba_163_188_136_1__62-rgba_163_188_136_1__88-rgba_73_110_31_1__100"
            cx="401"
            cy="266"
            r="174"
            fx="401"
            fy="266"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="50%" stopColor="#496e1f"></stop>
            <stop offset="62%" stopColor="#a3bc88"></stop>
            <stop offset="88%" stopColor="#a3bc88"></stop>
            <stop offset="100%" stopColor="#496e1f"></stop>
         </radialGradient>
      </defs>
      <g className="raphael-group-20-background">
         <rect
            width="800"
            height="491"
            x="1"
            y="1"
            fill="url('#19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100')"
            rx="0"
            ry="0"
         ></rect>
         <rect
            width="801"
            height="492"
            x="0.5"
            y="0.5"
            fill="none"
            stroke="#767575"
            strokeOpacity="0.5"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-21-dataset">
         <g
            fill="none"
            stroke="#404040"
            strokeLinecap="round"
            strokeLinejoin="round"
         >
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M553.43 181.297A174 174 0 01576 267h-87a87 87 0 00-11.285-42.852z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M553.43 181.297A174 174 0 01576 267h-87a87 87 0 00-11.285-42.852z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M553.43 181.297A174 174 0 01576 267h-87a87 87 0 00-11.285-42.852z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M532.697 152.134a174 174 0 0120.733 29.163l-75.715 42.851a87 87 0 00-10.367-14.581z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M532.697 152.134a174 174 0 0120.733 29.163l-75.715 42.851a87 87 0 00-10.367-14.581z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M532.697 152.134a174 174 0 0120.733 29.163l-75.715 42.851a87 87 0 00-10.367-14.581z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M283.806 139.304a174 174 0 01248.891 12.83l-65.349 57.433a87 87 0 00-124.445-6.415z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M283.806 139.304a174 174 0 01248.891 12.83l-65.349 57.433a87 87 0 00-124.445-6.415z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M283.806 139.304a174 174 0 01248.891 12.83l-65.349 57.433a87 87 0 00-124.445-6.415z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M265.563 159.014a174 174 0 0118.243-19.71l59.097 63.848a87 87 0 00-9.121 9.855z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M265.563 159.014a174 174 0 0118.243-19.71l59.097 63.848a87 87 0 00-9.121 9.855z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M265.563 159.014a174 174 0 0118.243-19.71l59.097 63.848a87 87 0 00-9.121 9.855z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M233.737 311.313a174 174 0 0131.826-152.299l68.219 53.993a87 87 0 00-15.913 76.15z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M233.737 311.313a174 174 0 0131.826-152.299l68.219 53.993a87 87 0 00-15.913 76.15z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M233.737 311.313a174 174 0 0131.826-152.299l68.219 53.993a87 87 0 00-15.913 76.15z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M424.341 439.56a174 174 0 01-190.604-128.247l84.132-22.157a87 87 0 0095.302 64.124z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M424.341 439.56a174 174 0 01-190.604-128.247l84.132-22.157a87 87 0 0095.302 64.124z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M424.341 439.56a174 174 0 01-190.604-128.247l84.132-22.157a87 87 0 0095.302 64.124z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M557.64 344.794a174 174 0 01-133.299 94.766l-11.17-86.28a87 87 0 0066.65-47.383z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M557.64 344.794a174 174 0 01-133.299 94.766l-11.17-86.28a87 87 0 0066.65-47.383z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M557.64 344.794a174 174 0 01-133.299 94.766l-11.17-86.28a87 87 0 0066.65-47.383z"
            ></path>
            <path
               strokeOpacity="0.05"
               strokeWidth="5"
               d="M576 267a174 174 0 01-18.36 77.794l-77.82-38.897A87 87 0 00489 267z"
            ></path>
            <path
               strokeOpacity="0.1"
               strokeWidth="3"
               d="M576 267a174 174 0 01-18.36 77.794l-77.82-38.897A87 87 0 00489 267z"
            ></path>
            <path
               strokeOpacity="0.15"
               d="M576 267a174 174 0 01-18.36 77.794l-77.82-38.897A87 87 0 00489 267z"
            ></path>
         </g>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_145_179_205_1__50-rgba_211_234_251_1__62-rgba_211_234_251_1__88-rgba_145_179_205_1__100')"
            stroke="#EBF6FE"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M552.43 180.297A174 174 0 01575 266h-87a87 87 0 00-11.285-42.852z"
         ></path>
         <text
            x="603.439"
            y="212.774"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="603.439" dy="4">
               AK, 10
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M569.263 221.687l24.176-8.913h5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_204_156_12_1__50-rgba_250_219_123_1__62-rgba_250_219_123_1__88-rgba_204_156_12_1__100')"
            stroke="#FDEFC3"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M531.697 151.134a174 174 0 0120.733 29.163l-75.715 42.851a87 87 0 00-10.367-14.581z"
         ></path>
         <text
            x="573.191"
            y="144.901"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="573.191" dy="4.003">
               CA, 4
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M542.815 165.18l20.376-20.279h5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_115_154_0_1__50-rgba_191_217_115_1__62-rgba_191_217_115_1__88-rgba_115_154_0_1__100')"
            stroke="#E2EEC0"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M282.806 138.304a174 174 0 01248.891 12.83l-65.349 57.433a87 87 0 00-124.445-6.415z"
         ></path>
         <text
            x="421.244"
            y="57.277"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="421.244" dy="4.004">
               ID, 31
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M409.957 92.23l1.287-34.953h5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_211_117_58_1__50-rgba_255_193_153_1__62-rgba_255_193_153_1__88-rgba_211_117_58_1__100')"
            stroke="#FFE3D1"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M264.563 158.014a174 174 0 0118.243-19.71l59.097 63.848a87 87 0 00-9.121 9.855z"
         ></path>
         <text
            x="244.957"
            y="124.032"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="244.957" dy="3.993">
               MT, 3
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M273.304 147.806l-18.347-23.774h-5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_0_117_117_1__50-rgba_115_193_193_1__62-rgba_115_193_193_1__88-rgba_0_117_117_1__100')"
            stroke="#C0E3E3"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M232.737 310.313a174 174 0 0131.826-152.299l68.219 53.993a87 87 0 00-15.913 76.15z"
         ></path>
         <text
            x="196.208"
            y="223.249"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="196.208" dy="4.006">
               NM, 18
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M230.68 230.408l-24.472-7.16h-5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_177_58_58_1__50-rgba_232_153_153_1__62-rgba_232_153_153_1__88-rgba_177_58_58_1__100')"
            stroke="#F5D1D1"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M423.341 438.56a174 174 0 01-190.604-128.247l84.132-22.157a87 87 0 0095.302 64.124z"
         ></path>
         <text
            x="279.909"
            y="439.402"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="end"
         >
            <tspan x="279.909" dy="4.004">
               OR, 28
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M303.866 410.364l-13.957 29.038h-5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_117_58_117_1__50-rgba_193_153_193_1__62-rgba_193_153_193_1__88-rgba_117_58_117_1__100')"
            stroke="#E3D1E3"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M556.64 343.794a174 174 0 01-133.299 94.766l-11.17-86.28a87 87 0 0066.65-47.383z"
         ></path>
         <text
            x="526.305"
            y="436.341"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="526.305" dy="4.005">
               WA, 19
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M501.82 407.815l14.485 28.526h5"
         ></path>
         <path
            fill="url('#19-xr___174_401_266_userSpaceOnUse_rgba_73_110_31_1__50-rgba_163_188_136_1__62-rgba_163_188_136_1__88-rgba_73_110_31_1__100')"
            stroke="#D6E1C9"
            strokeLinejoin="round"
            strokeOpacity="0.8"
            d="M575 266a174 174 0 01-18.36 77.794l-77.82-38.897A87 87 0 00488 266z"
         ></path>
         <text
            x="604.68"
            y="314.005"
            fill="#555"
            fontFamily="Verdana, sans"
            fontSize="10"
         >
            <tspan x="604.68" dy="3.997">
               WY, 9
            </tspan>
         </text>
         <path
            fill="none"
            stroke="#767575"
            strokeWidth="0.7"
            d="M570.348 305.966l24.332 8.039h5"
         ></path>
      </g>
      <g className="raphael-group-75-caption">
         <text
            x="401"
            y="16"
            fill="#555"
            className="fusioncharts-caption"
            fontFamily="Verdana, sans"
            fontSize="13"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="401" dy="12.992">
               Chart Caption
            </tspan>
         </text>
         <text
            x="401"
            y="34"
            fill="#555"
            className="fusioncharts-subcaption"
            fontFamily="Verdana, sans"
            fontSize="11"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="401" dy="10.852">
               Chart Sub Caption
            </tspan>
         </text>
      </g>
   </svg>)
};

export default DoughnutChartIcon;