/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { AbstractTextualEditor, IAbstractTextualEditor } from './AbstractTextualEditor';
import AceEditor, { IAceEditorProps } from 'react-ace';
import format from 'xml-formatter';
import { RunContext } from '@jss/js-repository';


export default class InternalXMLEditor extends AbstractTextualEditor {

    constructor(props: IAbstractTextualEditor) {
        super(props);
    }

    public getAcceptedFileTypes(): string {
        return this.props.acceptedFileTypes ? this.props.acceptedFileTypes : ".xml,.jrxml,application/xml"
    }

    public getDefaultExtension(): string {
        return this.props.defaultExtension ? this.props.defaultExtension : 'xml';
    }

    public getTextEditor(editorProps: IAceEditorProps): React.ReactElement {
        const props = {
            ...editorProps,
            mode: 'xml',
            wrapEnabled: true,
            enableBasicAutocompletion: true,
            tabSize: 2,
            theme: 'textmate',
        };
        return <AceEditor {...props} ref={this.editor} />;
    }

    public formatContent(content: string): string { 
        try {
            return format(content, { indentation: '  ', collapseContent: true });
        } catch (error) {
            return content;
        }
    }

    public hasFomatter = () => {
        return true;
    }
}

InternalXMLEditor.contextType = RunContext;
