/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MGraphicElement } from "../common/MGraphicElement";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { EvaluationTimeEnum } from "../enums/EvaluationTimeEnum";

import { OnErrorTypeEnum } from "../enums/OnErrorTypeEnum";
import { PJavaClass } from "../../ui/primitive/UPJavaClass";
import { ICONS } from "@jss/js-common";
import { PCategories } from "../../ui/composite/UPCategories";
import { MDatasetRun } from "../dataset/MDatasetRun";
import { IncrementTypeEnum } from "../enums/IncrementTypeEnum";
import { ResetTypeEnum } from "../enums/ResetTypeEnum";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PConnectedTableDetail } from "../../ui/tables/UPConnectedTableDetail";
import { PConnectedTable } from "../../ui/tables/UPConnectedTable";
import { PTableElement } from "../../ui/tables/UPTableElement";
import { PString } from "../../ui/primitive/UPString";
import { PValueExpression } from "../../ui/primitive/UPValueExpession";
import { MCVCCustom } from "./MCVCCustom";

export const FCVC = {
    evaluationTime: EvaluationTimeEnum,
    onErrorType: OnErrorTypeEnum,
    processingClass: new PJavaClass({ id: 'processingClass', label: 'Processing Class' }),

    incrementType: IncrementTypeEnum,
    resetType: ResetTypeEnum,
    incrementWhenExpression: new PExpression({ id: 'incrementWhenExpression', label: 'Increment When' }),

    name: new PString({ id: 'name', label: 'Name' }),
    value: new PValueExpression({ id: '', vtype: new PString({ id: "", label: 'Value' }) })
};

export const MCVC: APDescriptor = {
    type: ElementTypes.CVC_ELEMENT,
    label: 'CVC',
    id: 'cvcProeprtiesDescriptor',
    icon: ICONS.CUSTOMVIS_ICON,
    default: {
        width: 100, height: 30,
        'xmlns:cvc': "http://www.jaspersoft.com/cvcomponent",
        "xsi:schemaLocation": "http://www.jaspersoft.com/cvcomponent http://www.jaspersoft.com/cvcomponent/component.xsd"
    },
    layouts: [new PScrollableCategories({
        id: 'cvcPropertiesTabsDescriptor',
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MGraphicElement.layouts, MCVCCustom] }),
            new PDiv({
                label: 'CVC', layouts: [
                    FCVC.evaluationTime, FCVC.onErrorType, FCVC.processingClass,
                    new PConnectedTable({
                        id: 'cvcProperties', label: 'CVC Properties', rowLabelPaths: ['name'], expanded: true,
                        layouts: [new PTableElement({
                            anchorOrigin: { vertical: 'center', horizontal: 'right' },
                            transformOrigin: { vertical: 'center', horizontal: 'right' },
                            label: 'Property',
                            layouts: [FCVC.name, FCVC.value]
                        })],
                        default: { name: 'property' }
                    }),
                ]
            }),
            new PDiv({
                label: 'Data', layouts: [new PConnectedTableDetail({
                    id: 'cvcData', label: 'Values By Dataset', rowLabel: 'Set of Values', expanded: true, layouts: [new PDiv({
                        id: 'cvcitems',
                        layouts: [
                            new PCategories({
                                layouts: [new PConnectedTableDetail({
                                    id: 'cvcItems', label: 'CVC Values', rowLabel: 'Values', rowLabelPaths: ['name'], expanded: true, layouts: [
                                        new PConnectedTable({
                                            id: '', label: 'Value', expanded: true, rowLabelPaths: ['name', 'value', 'valueExpression'],
                                            layouts: [new PTableElement({
                                                anchorOrigin: { vertical: 'center', horizontal: 'right' },
                                                transformOrigin: { vertical: 'center', horizontal: 'right' },
                                                label: 'Property',
                                                layouts: [FCVC.name, FCVC.value],
                                                default: { name: 'item' }
                                            })],
                                            default: { name: 'cvcProperty' }
                                        })
                                    ],
                                    default: { name: 'cvcItem' }
                                }),
                                new PDiv({
                                    id: 'fusion.shapes.dataset', path: 'dataset',
                                    label: 'Dataset',
                                    layouts: [FCVC.incrementType, FCVC.incrementWhenExpression, FCVC.resetType, MDatasetRun]
                                })]
                            })
                        ]
                    })],
                    default: { name: 'cvcData' }
                })
                ]
            })
        ]
    })]
};
