/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MGraphicElement } from "../common/MGraphicElement";
import { PInt } from "../../ui/primitive/UPInteger";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { MLinePen } from "../common/MLinePen";
import { ICONS } from "@jss/js-common";
import { MRectangleCustom } from "./MRectangleCustom";

export const FRectangle = {
    borderRadius: new PInt({ id: 'radius', label: 'Border Radius', default: 0, min: 0, inheritedPropertyId: 'radius' })
};

export const MRectangle: APDescriptor = {
    type: ElementTypes.RECTANGLE,
    label: 'Rectangle',
    id: 'rectangleProeprtiesDescriptor',
    icon: ICONS.RECTANGLE_ICON,
    default: { width: 100, height: 50 },
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MGraphicElement.layouts, MRectangleCustom] }),
            new PDiv({ label: 'Rectangle', layouts: [FRectangle.borderRadius, MLinePen] })
        ]
    })]
};
