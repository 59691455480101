/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { PEnum } from "../../ui/primitive/UPEnum";

export const TransactionIsolation = new PEnum({
    id: 'transactionIsolation', label: 'TransactionIsolation.label',
    items: [{ key: '', value: '' },
    { key: 'NONE', value: 'TransactionIsolation.NONE' },
    { key: 'READ_UNCOMMITTED', value: 'TransactionIsolation.ReadUcommitted' },
    { key: 'READ_COMMITTED', value: 'TransactionIsolation.ReadCommitted' },
    { key: 'REPEATABLE_READ', value: 'TransactionIsolation.RepeatableRead' },
    { key: 'SERIALIZABLE', value: 'TransactionIsolation.Serializable' }
    ]
});

