/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { FBarcode4J, MBarcode4J } from "./MBarcode4J";


export const FCode128 = {

}

export const MCode128: APDescriptor = {
    type: 'Code128', id: 'Code128', label: 'Code128', icon: ICONS.BARCODE_CODE128_ICON,
    layouts: [...MBarcode4J.layouts, FBarcode4J.verticalQuietZone, FBarcode4J.textPosition],
    default: {
        type: 'BARCODE', BARCODE_TYPE: 'Code128', BARCODE_XMLNS_NAMESPACE: 'c',
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:c': "http://jasperreports.sourceforge.net/jasperreports/components",
        codeExpression: '"123456789"'
    }
};
