/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import _ from 'lodash';
import { UiProperty } from './ui/UiProperty';
import { PopoverControl, TimedSearchBar } from '@jss/js-common';

import { Map as ImmutableMap } from 'immutable';
import { MReport } from './types/report/MReport';
import { getModel } from './types/TypeFactory';
import { IElements } from './ui/UiProperty';

import '../../../assets/uxpl/css/ReportProperties.css';
import { APDescriptor } from './ui/APDescriptor';
import TuneIcon from '@mui/icons-material/Tune';
import { ListItem, ListItemSecondaryAction, ListItemText, List, Switch } from '@material-ui/core';
import { Conf, IRepositoryItemDescriptor, Pref } from '@jss/js-rest-api';
import i18n from '../../../i18n';
import { PPropertiesExpression } from './ui/primitive/UPValueExpession';

export interface ISelectedProperties {
  rootModel: ImmutableMap<string, any>;
  model: ImmutableMap<string, any>;
  selection?: ImmutableMap<string, any>;
  reportDescriptor: IRepositoryItemDescriptor,
  setObjectProperties?: (path: string[], value: any, refreshCache?: boolean, isWidgetProperty?: boolean) => void;
  deleteElement?: (path: string[]) => void;
  refreshCache?: () => void;
}

interface ISelectedPropertiesState {
  root: APDescriptor;
  elements: IElements[];
  search?: string;
  showDeprecated: boolean;
  showCustom: boolean;
  lastSelection: ImmutableMap<string, any> | undefined;
  lastModel: ImmutableMap<string, any> | undefined;
}
interface IFilters {
  search?: string;
  showDeprecated: boolean;
  showCustom: boolean;
}

const getSelectedElements = (props: ISelectedProperties): IElements[] => {
  const elements: IElements[] = [{ path: [], descriptor: MReport }];
  if (props.selection) {
    if (props.selection.size > 0) {
      elements.pop();
      props.selection.forEach((value) => {
        const path = value.path;
        const type = value.type;
        if (path === '/') {
          elements.push({ path: [], descriptor: getModel(type) });
        } else {
          elements.push({ path: value.path, descriptor: getModel(type) });
        }
      })
    } else {
      elements[0].descriptor = getModel(getDefaultType(props));
    }
  } else {
    elements[0].descriptor = getModel(getDefaultType(props));
  }
  return elements;
}

const getDefaultType = (props: ISelectedProperties): string => {
  if (props.model) {
    const type = props.model.get('type');
    if (type)
      return type;
  }
  return MReport.id;
}

const getDescriptor = (elements: IElements[], filter: IFilters): APDescriptor | undefined => {
  const res: APDescriptor[] = _.uniqBy(elements.map(k => k.descriptor), function (e) { return e.type; });
  if (res.length > 0)
    return buildLayout(res[0], res, filter);
  return res[0];
}

const buildLayout = (desc: APDescriptor, elements: APDescriptor[], filter: IFilters): APDescriptor | undefined => {
  if (canShowProperty(desc, filter)) {
    if (desc.layouts?.length > 0) {
      const mdesc: APDescriptor = { ...desc, layouts: [] };
      desc.layouts?.forEach((key) => {
        if (key && canShowProperty(key, filter)) {
          if (key.layouts?.length > 0) {
            const c = buildLayout(key, elements, filter);
            if (c) {
              mdesc.layouts.push(c);
            }
          } else if (hasProperties(key, elements)) {
            mdesc.layouts.push(key);
          }
        }
      });
      return mdesc.layouts.length === 0 ? undefined : mdesc;
    } else if (hasProperties(desc, elements)) {
      return desc;
    }
  }
  return undefined;
}

const hasProperties = (ref: APDescriptor, elements: APDescriptor[]) => {
  return elements.length === 1 || elements.some((k, i) => i > 0 && hasProperty(ref, k));
}

const hasProperty = (ref: APDescriptor, element: APDescriptor): boolean => {
  if (element.layouts?.length > 0) {
    return element.layouts?.some(key => {
      return key.layouts?.some(k => hasProperty(ref, k));
    });
  }
  return (element.id === ref.id && element.type === ref.type);
}

const canShowProperty = (desc: APDescriptor, filter: IFilters) => {
  if (!filter.showDeprecated && (desc.deprecated === true || ((desc as PPropertiesExpression).vtype && (desc as PPropertiesExpression).vtype.deprecated)))
    return false;
  if (!filter.showCustom && desc.custom === true)
    return false;
  if (!filter.search)
    return true;
  if (!desc.id || desc.layouts?.length > 0)
    return true;
  const canShow = desc.id.toLowerCase().includes(filter.search) || i18n.t(desc.label).toLowerCase().includes(filter.search);
  return canShow;
}

const isShowDeprecated = (props: ISelectedProperties) => {
  const value = props.rootModel.getIn(['widgetStatus', 'showDeprecated']) as boolean | undefined;
  if (value === undefined){
    return Conf.get(Pref.EDITOR_JRXML_PROPS_SHOWDEPRECATED) === undefined ? false : Conf.get(Pref.EDITOR_JRXML_PROPS_SHOWDEPRECATED)
  }
  return value;
}

const isShowCustom = (props: ISelectedProperties) => {
  const value = props.rootModel.getIn(['widgetStatus', 'showCustom']) as boolean | undefined;
  if (value === undefined){
    return Conf.get(Pref.EDITOR_JRXML_PROPS_SHOWCUSTOMPROPERTIES) === undefined ? false : Conf.get(Pref.EDITOR_JRXML_PROPS_SHOWCUSTOMPROPERTIES)
  }
  return value;
}

export class SelectedProperties extends React.Component<ISelectedProperties, ISelectedPropertiesState> {
  public state: ISelectedPropertiesState = {
    root: MReport, elements: [{ path: [], descriptor: MReport }],
    showDeprecated: false,
    showCustom: false,
    lastModel: undefined,
    lastSelection: undefined,
  };

  static getDerivedStateFromProps = (props: ISelectedProperties, state: ISelectedPropertiesState): ISelectedPropertiesState => {
    if ((!state.lastModel && props.model) || !_.isEqual(props.selection, state.lastSelection)) {
      const elements = getSelectedElements(props);
      const showDeprecated = isShowDeprecated(props);
      const showCustom = isShowCustom(props);
      return {
        root: getDescriptor(elements, { showDeprecated: showDeprecated, showCustom: showCustom }),
        showDeprecated: showDeprecated,
        showCustom: showCustom,
        elements: elements,
        search: undefined,
        lastModel: props.model,
        lastSelection: props.selection,
      }
    }
    return null;
  }

  render() {
    if (this.props.model !== null) {
      return <div  className='jr-jrws-properties-root'>
        <div className='jr-jrws-properties-content'>
          {this.renderProperties()}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', gap: '5px' }}>
          <TimedSearchBar
            pauseTime={100}
            placeholder={i18n.t('properties.filterby')}
            id="file-searchbar"
            WrapperProps={{ style: { flex: 1 } }}
            onChange={this.searchTextChange}
            value={this.state.search} />
          <PopoverControl id={'popoverAction'} getContent={this.renderPopoverContent} getControl={this.renderPopoverControl} />

        </div>
        <div>
          {this.renderLabel()}
        </div>
      </div>;
    }
    return <></>;
  }
  private renderPopoverContent = () => {
    return <List style={{ width: '200px' }}>
      <ListItem key={'pref.deprecated'} button>
        <ListItemText primary='Show Deprecated' title='Show or hide deprecated properties' />
        <ListItemSecondaryAction>
          <Switch
            edge="end" color="primary"
            onChange={this.onShowDeprecated}
            checked={this.state.showDeprecated}
            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem key={'pref.custom.properties'} button>
        <ListItemText primary='Show All Properties' title='Show or hide custom properties' />
        <ListItemSecondaryAction>
          <Switch
            edge="end" color="primary"
            onChange={this.onShowCustom}
            checked={this.state.showCustom}
            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </ List>;
  }
  private renderPopoverControl = (handleClick: (clickEvent: any) => void) => {
    return <div onClick={handleClick} title='Preferences' style={{ cursor: 'pointer' }} >
      <TuneIcon />
    </div>;
  }

  private renderLabel = () => {
    return i18n.t('properties.selected.label', { element: `$t(${this.state.elements[0].descriptor.label})`, count: this.state.elements.length });
  }

  private renderProperties = () => {
    if (this.state.root) {
      return this.state.root.layouts?.length > 0 ?
        this.state.root.layouts?.map((key, index) => {
          const k = key.id ? key.id : 'sp.' + this.state.root.id + '.' + index;
          return <UiProperty key={k} mcontext={{ reportDescriptor: this.props.reportDescriptor, model: this.props.model, rootModel: this.props.rootModel, descriptor: key, elements: this.state.elements, setObjectProperties: this.props.setObjectProperties, deleteElement: this.props.deleteElement, refreshCache: this.props.refreshCache }} />;
        })
        : <UiProperty mcontext={{ reportDescriptor: this.props.reportDescriptor, model: this.props.model, rootModel: this.props.rootModel, descriptor: this.state.root, elements: this.state.elements, setObjectProperties: this.props.setObjectProperties, deleteElement: this.props.deleteElement, refreshCache: this.props.refreshCache }} />
    }
  }
  private onShowCustom = () => {
    const sd = !this.state.showCustom;

    const d = getDescriptor(this.state.elements, { showDeprecated: this.state.showDeprecated, showCustom: sd, search: this.state.search });
    this.setState({ showCustom: sd, root: d }, () => {
      this.props.setObjectProperties(['showCustom'], this.state.showCustom, false, true);
    });
  }
  private onShowDeprecated = () => {
    const sd = !this.state.showDeprecated;
    const d = getDescriptor(this.state.elements, { showCustom: this.state.showCustom, showDeprecated: sd, search: this.state.search });
    this.setState({ showDeprecated: sd, root: d }, () => {
      this.props.setObjectProperties(['showDeprecated'], this.state.showDeprecated, false, true);
    });
  }
  private searchTextChange = (v: string) => {
    v = v.trim().toLowerCase();
    v = v.length > 0 ? v : undefined;
    const d = getDescriptor(this.state.elements, { showDeprecated: this.state.showDeprecated, showCustom: this.state.showCustom, search: v });
    this.setState({ search: v, root: d });
  }
}
