/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import {
    CollapsiblePanel, CommandRegistry, getExitDialog as getCommonExitDialog,
    IResourceInfo, IResourceListenerManager, Typography
} from '@jss/js-common';
import { RunContext } from '@jss/js-repository';
import { ErrorDescriptor, JRIOApiService, RepositoryApi, IRepositoryItemDescriptor } from '@jss/js-rest-api';
import Paper from '@material-ui/core/Paper';
import { Map } from 'immutable';
import React, { Dispatch, useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as ReportActions from '../../../actions/reportActions';
import jrxmlToJs from '../../../components/common/JrxmlModel/reader/JrxmlReader';
import i18n from "../../../i18n";
import { IState } from '../../../reducers';
import ParametersTab from '../parameters/ParametersTab';
import { Bookmarks } from './Bookmarks';
import { loadExternalScript } from "./ExternalScriptUtils";
import { IViewerStore, useViewerStore, ViewerStoreContext } from './ViewerStore';
import { ViewerToolbar } from './ViewerToolbar';
import { OnResizeStopCallback } from "@jss/js-common/compiled/uxpl/CollapsiblePanel/collapsiblePanelTypes";
import { PanelState } from "@jss/js-common/compiled/uxpl/CollapsiblePanel/useCollapsiblePanelState";
import { ClosableErrorDialog } from "../../common/error/ErrorDialog";


export const EXIT_PREVIEW_EDITOR = 'EXIT_PREVIEW_EDITOR';

interface IBasicReportViewer {
    resourceDescriptor: IRepositoryItemDescriptor;
    hideToolbar?: boolean;
    language?: string,
    commandRegistry: CommandRegistry;
    resourceListenerManagers: IResourceListenerManager[],
    isEditorDirty?: boolean,
}

const onResourceOpen = (resource: IResourceInfo, dispatch: Dispatch<any>) => {
    const descriptor = resource.resource;
    const pa = '/' + RepositoryApi.getAPath(descriptor);
    if (resource.content) {
        const jsModel = jrxmlToJs(resource.content);
        dispatch(ReportActions.openDocumentAction(jsModel, descriptor, resource.path, resource.parameters, resource.options));
        if (resource.isResourceDirty !== undefined) {
            dispatch(ReportActions.setDirty(resource.isResourceDirty));
        }
    }

    if (!resource.options || !resource.options.keepCache){
        //load the fonts
        dispatch(ReportActions.loadCombosAction(pa));
   }
}

const resourceListenerId = uuidv4();

const convertReportParameterValues = (originalParams: Record<string, unknown>): Record<string, Array<unknown>> => {
    const convertedReportParams: Record<string, Array<unknown>> = {};
    if (originalParams && Object.keys(originalParams).length > 0) {
        for (const key in originalParams) {
            const val = originalParams[key];
            convertedReportParams[key] = Array.isArray(val) ? val : [val];
        }
    }
    return convertedReportParams;
};

export const BasicReportViewer: React.FunctionComponent<IBasicReportViewer> = ({
        resourceDescriptor, hideToolbar = false, commandRegistry, resourceListenerManagers, isEditorDirty = false
    }) => {
    const runContext = useContext(RunContext);
    const [containerClassName] = useState<string>(uuidv4());
    const [exitAlertOpen, setExitAlertOpen] = useState(false);

    // used to store the latest reference of viewerStore in order to be accessed from reportInstance registered event handlers
    const viewerStoreRef = useRef<IViewerStore>();

    const viewerStore = useViewerStore(runContext.handleError.bind(runContext));
    const reportParameterValues: Map<string, unknown> = useSelector(
        (state: IState) => {
            return state.getIn(['report', 'parametersValue']) as Map<string, unknown>;
        }
    );
    const widgetStatus: Map<string, any> = useSelector(
        (state: IState) => {
            return state.getIn(['report', 'widgetStatus']) as Map<string, unknown>;
        }
    );

    const dispatch = useDispatch();
    resourceListenerManagers.forEach(manager => {
        manager.removeResourceListener(resourceListenerId);
    });
    resourceListenerManagers.forEach(manager => {
        manager.addResourceListener(resourceListenerId, { onResourceOpen: (resource: IResourceInfo) => onResourceOpen(resource, dispatch)} );
    });
    //hook for component did mount, register listener
    useEffect(() => {
        return () => {
            resourceListenerManagers.forEach(manager => {
                manager.removeResourceListener(resourceListenerId);
            });
        }
    });

    // update the viewerStoreRef whenever viewerStore updates
    useEffect(() => {
        viewerStoreRef.current = viewerStore;
    }, [viewerStore]);

    const confirmExitPreviewEditor = () => {
        commandRegistry.executeCommand(EXIT_PREVIEW_EDITOR);
    }

    const exitPreviewEditorAction = () => {
        if (isEditorDirty) {
            setExitAlertOpen(true);
        } else {
            confirmExitPreviewEditor();
        }
    }

    const getExitDialog = () => {
        return getCommonExitDialog(exitAlertOpen, () => setExitAlertOpen(false), confirmExitPreviewEditor);
    }

    const getErrorContent = (reportError: ErrorDescriptor) => {

        return (
            <ClosableErrorDialog
                message={reportError.message}
                details={reportError.parameters && reportError.parameters.length ? reportError.parameters[0] : undefined}
                onClose={() => {
                    viewerStore.reset();
                }}
            />
        );
    };

    const handleReportError = useCallback((error) => {
        viewerStore.setIsLoading(false);
        if (error.errorDescriptor) {
            viewerStore.setReportError(error.errorDescriptor);
        } else {
            viewerStore.setReportError(new ErrorDescriptor(error));
        }
    }, [viewerStore]);

    const startReportExecution = useCallback(
        (reportParameters: Record<string, unknown> | undefined) => {
            (async () => {
                viewerStore.setIsLoading(true);

                try {
                    await loadExternalScript(JRIOApiService.inst().getViewerClientURL(resourceDescriptor));

                    const clientName = JRIOApiService.inst().getViewerClientName(resourceDescriptor);
                    let eventsConfig = {};

                    if ("jrio" === clientName) {
                        eventsConfig = {
                            beforeExecutionRun: (headerConfig) => {
                                headerConfig.headers = {
                                    "X-JRS-Repo": RepositoryApi.inst().getBase(),
                                    "X-JRS-UserID": RepositoryApi.inst().getUserId(),
                                    'X-JRS-Context-Location': RepositoryApi.getContainer(RepositoryApi.getAPath(resourceDescriptor))
                                };
                            }
                        };
                    }

                    const clientConfig = JRIOApiService.inst().getViewerClientConfig(resourceDescriptor);
                    if (clientConfig !== null) {
                        window[clientName].config(clientConfig);
                    }

                    window[clientName](function (client) {
                        const reportInstance = client.report({
                            resource: JRIOApiService.inst().getExecutionPath(resourceDescriptor),
                            params: reportParameters,
                            container: `.${containerClassName}`,
                            centerReport: true,
                            error: (e) => {
                                handleReportError(e);
                            },
                            success: () => {
                                viewerStoreRef.current.setReportInstance(reportInstance);
                                viewerStoreRef.current.setContainerClassName(containerClassName);
                            },
                            events: {
                                beforeAction: function(actions/*, shouldCancel*/) {
                                    viewerStoreRef.current.setBeforeActions(actions);
                                },
                                afterRender: () => {
                                    viewerStoreRef.current.setIsPageLoading(false);
                                },
                                changePagesState: viewerStoreRef.current.setCurrentPage,
                                changeTotalPages: viewerStoreRef.current.setTotalPages,
                                canUndo: viewerStoreRef.current.setCanUndo,
                                canRedo: viewerStoreRef.current.setCanRedo,
                                reportCompleted: (status, error) => {
                                    viewerStoreRef.current.setIsLoading(false);
                                    if (error) {
                                        handleReportError(error);
                                    }
                                },
                                bookmarksReady: (bookmarks) => {
                                    viewerStoreRef.current.setBookmarks(bookmarks);
                                },
                                reportPartsReady: (reportParts) => {
                                    viewerStoreRef.current.setReportParts(reportParts);
                                },
                                ...eventsConfig
                            },
                            linkOptions: {
                                beforeRender: function(elementDataPairs) {
                                    elementDataPairs.forEach(function(elemData) {
                                        elemData.element && (elemData.element.style.cursor = "pointer");
                                    });
                                },
                                events: {
                                    "click": function(event, link) {
                                        switch(link.type) {
                                            case "LocalAnchor":
                                            case "LocalPage":
                                                viewerStoreRef.current.goToAnchor({ page: link.pages, anchor: link.anchor});
                                                break;
                                            case "RemoteAnchor":
                                            case "Reference":
                                            case "ReportExecution":
                                                window.open(link.href, link.targetValue);
                                                break;
                                        }
                                    }
                                }
                            }
                        });
                    }).fail(e => { throw e; });
                } catch (e) {
                    viewerStore.setIsLoading(false);
                    viewerStore.setReportError(e);
                }
            })();

            return () => {
                // try to cleanup
                runContext.isLoading(false);
                if (viewerStoreRef.current.reportInstance !== null) {
                    viewerStoreRef.current.reportInstance.cancel();
                    viewerStoreRef.current.reportInstance.destroy();
                }
            };
        },
        [containerClassName, resourceDescriptor, viewerStore, runContext, handleReportError]
    );

    // hook for starting report execution on mount
    useEffect(() => {
        // Try to communicate with the Service Worker to pass the custom headers
        if ("jrio" === JRIOApiService.inst().getViewerClientName(resourceDescriptor) && navigator && navigator.serviceWorker) {
            navigator.serviceWorker.ready.then((registration: ServiceWorkerRegistration) => {
                registration.active?.postMessage({
                    type: "jrws_set_headers",
                    headers: {
                        "X-JRS-Repo": RepositoryApi.inst().getBase(),
                        "X-JRS-UserID": RepositoryApi.inst().getUserId()
                    }
                });
            });
        }

        const reportParams = convertReportParameterValues(reportParameterValues.toJS());
        return startReportExecution(reportParams);
    }, []); // empty

    useEffect(() => {
        runContext.isLoading(viewerStore.isLoading);
    }, [viewerStore.isLoading, runContext]);

    const onBookmarkItemClick = useCallback(
        ({ page, anchor }: { page: number, anchor: string }) => {
            viewerStore.goToAnchor({ page, anchor });
        }, [viewerStore]);

    const runReportAction = useCallback(
        () => {
            const reportParams = convertReportParameterValues(reportParameterValues.toJS());

            if (viewerStore.reportInstance === null) {
                // it may happen that we do not have a reportInstance to apply parameters to, so we need to create one
                startReportExecution(reportParams);
            } else {
                viewerStore.applyReportParameters(reportParams);
            }
        }, [viewerStore, reportParameterValues]);

    const handlePanelCollapse = useCallback(
        ({ id, subPanels }) => {
            dispatch(ReportActions.setWidgetStatus(['panelProperties', id, 'open'], false));
            subPanels.forEach((panel) => {
                dispatch(ReportActions.setWidgetStatus(['panelProperties', panel.id, 'open'], false));
            });
        }, []);

    const handlePanelExpand = useCallback(
        ({ id, subPanels }) => {
            dispatch(ReportActions.setWidgetStatus(['panelProperties', id, 'open'], true));
            subPanels.forEach((panel) => {
                dispatch(ReportActions.setWidgetStatus(['panelProperties', panel.id, 'open'], true));
            });
        }, []);

    const handlePanelResizeStop: OnResizeStopCallback = useCallback(
        (panelsState: PanelState[]) => {
            panelsState.forEach((panel) => {
                dispatch(ReportActions.setWidgetStatus(['panelProperties', panel.id, 'width'], panel.width));
                panel.subPanels.forEach((subpanel) => {
                    dispatch(ReportActions.setWidgetStatus(['panelProperties', subpanel.id, 'height'], subpanel.height));
                })
            });
        }, []);

    return (
        <div style={{ display: 'flex', background: '#f7f6f6', flexDirection: 'column', overflow: 'hidden', flex: 1 }}>
            {
                !hideToolbar && (
                    <ViewerStoreContext.Provider value={viewerStore}>
                        <ViewerToolbar exitEditorAction={exitPreviewEditorAction} runReportAction={runReportAction} />
                    </ViewerStoreContext.Provider>
                )
            }
            {
                viewerStore.reportError != null &&
                    <div style={{
                        display: "flex",
                        position: "absolute",
                        top: "0",
                        bottom: "0",
                        backgroundColor: "rgba(100,100,100,0.5)",
                        width: "100%",
                        zIndex: "10000",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        { getErrorContent(viewerStore.reportError) }
                    </div>
            }
            <div style={{ display: "flex", overflow: 'auto', flex: 1 }}>
                <>
                    <CollapsiblePanel
                        style={{ display: 'flex', backgroundColor: '#f7f6f6' }}
                        anchor="left"
                        onCollapseClick={handlePanelCollapse}
                        onExpandClick={handlePanelExpand}
                        onResizeStop={handlePanelResizeStop}
                        panels={[
                            {
                                id: 'reportViewerBookmarksColumn',
                                width: widgetStatus.getIn(['panelProperties', 'reportViewerBookmarksColumn', 'width'], 250) as number,
                                subPanels: [
                                    {
                                        id: 'parametersPanel',
                                        height: widgetStatus.getIn(['panelProperties', 'parametersPanel', 'height'], 250) as number,
                                        open: widgetStatus.getIn(['panelProperties', 'parametersPanel', 'open'], true) as boolean,
                                        hideHeader: false,
                                        label: "Parameters",
                                        minHeight: 150,
                                        content: <ParametersTab />
                                    },
                                    {
                                        id: 'bookmarksPanel',
                                        open: widgetStatus.getIn(['panelProperties', 'bookmarksPanel', 'open'], false) as boolean,
                                        label: i18n.t("viewer.bookmarks"),
                                        minHeight: 150,
                                        content: <Bookmarks bookmarks={viewerStore.bookmarks} onItemClick={onBookmarkItemClick} />
                                    }
                                ]
                            },
                        ]}
                    />
                    <div className={containerClassName}
                         style={{
                             flex: viewerStore.totalPages === 0 ? 0 : 1,
                             overflow: "auto",
                             paddingTop: "20px"
                         }}
                    />
                </>
                {
                    viewerStore.totalPages === 0 && (
                        <div style={{ flex: 1, display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                            <Paper elevation={5} style={{ minWidth: "200px", maxWidth: "450px", padding: "10px", marginTop: "50px", whiteSpace: "normal" }}>
                                <Typography variant="body3" style={{ maxHeight: "200px", overflow: "auto", padding: "20px", textAlign: "center" }}>
                                    {i18n.t("viewer.report.empty")}
                                </Typography>
                            </Paper>
                        </div>
                    )
                }
            </div>
            {getExitDialog()}
        </div>
    )
};
