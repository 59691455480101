/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const PrintOrderEnum = new PEnum({
    id: 'printOrder', label: 'PrintOrder.label', default: 'Vertical',
    items: [
        { key: 'Vertical', value: 'PrintOrder.Vertical' },
        { key: 'Horizontal', value: 'PrintOrder.Horizontal' }
    ]
}); 

