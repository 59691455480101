/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from "react";
import { ConnectedComponent } from "react-redux";
import { NavigateFunction, Location, useLocation, useNavigate, useSearchParams } from "react-router-dom";


export interface RouteComponentProps {
    location: Location;
    navigate: NavigateFunction;
    searchParams: URLSearchParams;
}

export interface RouterProps {
    router?: RouteComponentProps;
}

export function withRouter<TProps>(Component: React.ComponentClass<TProps> |
    ConnectedComponent<any, any>): React.FunctionComponent<TProps & RouterProps> {
    function ComponentWithRouterProp(props: TProps) {
        const location = useLocation();
        const navigate = useNavigate();
        const [searchParams]  = useSearchParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, searchParams }}
            />
        );
    }

    return ComponentWithRouterProp;
}
