/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { FormControlledTextField } from "@jss/js-common";
import { ValidationResult, VALIDATION_RESULT } from "@jss/js-common/src/utils/validators";
import React from "react";
import i18n from '../../../i18n';
import { ICParameters } from "./ParametersTab";

const pattern = /^-?\d*$/;

const integerValidator = (stringValue: string | number | undefined): ValidationResult => {
    if (stringValue){
        if (typeof stringValue === 'number'){
            stringValue = stringValue.toString();
        }
        if (pattern.test(stringValue) || stringValue.length === 0) {
            if (stringValue.length === 1 && stringValue.startsWith('-')) {
                return { result: VALIDATION_RESULT.PARTIALLY_INVALID }
            } else {
                const newValue = stringValue.length === 0 ? undefined : parseInt(stringValue, 10);
                if (Number.isNaN(newValue)) {
                    return { result: VALIDATION_RESULT.INVALID }
                }
            }
        } else {
            return { result: VALIDATION_RESULT.INVALID }
        }
    }
    return { result: VALIDATION_RESULT.VALID }
}


export class ICInteger extends React.Component<ICParameters> {

    render() {
        const value = this.props.parametersValue?.get(this.props.param.name);
        return <FormControlledTextField
            key={this.props.param.name}
            onTextChange={this.onValueChange}
            label={i18n.t(this.props.param.name)}
            inline={true}
            size={'small'}
            //use default value for the value, since value tend to put the cursor at the end
            // defaultValue={v}
            title={this.props.param.description}
            value={value ? value : ''}
            validator={integerValidator}
        // onBlur={() => { this.setState({ uncommittedValue: undefined }) }}
        // error={descriptor.validator && descriptor.validator(v)}
        />
    }
    private onValueChange = (newValue: string) => {
        if (pattern.test(newValue) || newValue.length === 0 ){
            this.props.setParameterValue(this.props.param.name, newValue);
        }
    }
}