/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { Conf, Pref } from "@jss/js-rest-api";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

interface IThemeWrapper {
    theme?: string,
}

const darkTheme = `.tc-jsw-app-header { background-color: black; }
:root {
    --dark-blue:      #10203f; /* TIBCO midnight */
    --medium-blue:    #576379  /* TIBCO midnight 30% lighter */
}

/* BUTTONS */
.button.capsule.superfocus.subfocus:hover,
.button.capsule.up.over,
.button.capsule.down,
.button.capsule.down.over,
.button.capsule.down.pressed {
    background-color: #576379; /* midnight 30% lighter */
}

.button.capsule.up.pressed,
.button.capsule.down.over,
.button.capsule.superfocus.subfocus.pressed {
    background-color: #10203f; /* midnight */
}

.action.jumbo.up.pressed {
    background: none repeat scroll 0 0 #FFFFFF;
    border: 1px solid #bf5b08;
}

.action.primary,
.action.primary.pressed,
.action.primary.pressed.over {
    background-image: none;
    background-color: #10203f; /* midnight */
}

.action.primary.over {
    background-color: #576379;
}

.action.small.up.over,
.action.small.up.pressed {
    background-color: #576379; /* midnight 30% lighter */
}

.action.small.pressed.over,
.action.small.down,
.action.small.down.over,
.action.small.down.pressed,
.sourceDialogNew .header .button.over.subfocus {
    background-color: #10203f; /* midnight */
}

#reportViewer .toolsRight .button.action.up.over,
#reportViewer .toolsRight .button.action.up.pressed,
#reportViewer .toolsRight .button.action.down,
#reportViewer .toolsRight .button.action.down.over,
#reportViewer .toolsRight .button.action.down.pressed {
    background-color: #576379; /* midnight 30% lighter */
}

#designer .toolbar .buttonSet .node > .button.pressed {
    background-color: #576379; /* midnight 30% lighter */
}

#tableOptions.over , #chartOptions.over {
    background-color: #576379; /* midnight 30% lighter */
}

#reportViewer #asyncCancel,
#reportViewer .toolbar #fileOptions.pressed,
#reportViewer .toolbar #export.pressed {
    background-color: #576379; /* midnight 30% lighter */
}

#reportPartsContainer .reportPart.active {
    background-color: #10203f;
}

#reportPartsContainer .button.action.up.over {
    background-color: #576379;
}

#reportPartsContainer .reportPart.over span {
    color: #083970;
}

.dashboardToolbar #save.pressed,
.dashboardToolbar #export.pressed,
.dashboardCanvas .dashletToolbar .button.text.cancelDashletButton,
.dashboardCanvas .dashletToolbar .button.exportDashletButton.pressed {
    background-color: #576379; /* midnight 30% lighter */
}

.addNewItem, a.addNewItem {
    border: 1px solid #10203f; /* midnight */
    color: #10203f; /* midnight */
}

/* CONTAINERS */

.banner,
#frameFooter {
    background-color: #10203f; /* midnight */
}

.menu.primaryNav .wrap.over,
.menu.primaryNav .wrap.pressed {
    background-color: #576379; /* midnight 30% lighter */
}

#metaLinks li.subfocus:not(select) {
    background-color: #576379;
}

column.decorated {
    border:1px solid #000b2c;
}

.column.decorated > .content > .header,
.column.decorated > .content > .header > .subheader {
    background-color: #f0f2f3;
}

.panel.tooltip .message.label {
    color: #10203f; /* midnight */
}

.menu.vertical li > .wrap.over,
.menu.vertical li > .wrap.pressed {
    background-color: #576379 /* midnight 30% lighter */
}

#systemMessage {
    color: #035b85;
    font-weight: bold;
    text-align: center;
}


/* CONTROLS */


/* LISTS */
/* SELECT LIST CONTROLS */

.list > li > .wrap.pressed,
.list > li.selected > .wrap,
.list > li.selected > .wrap.over,
.sList li.selected,
.mSelect-svList > .selected .mSelect-svList-button,
.list.filters > li > .wrap.over,
.list.filters > li > .wrap.pressed,
.list.filters > li:not(.selected) > .wrap.over,
.list.filters > li:not(.selected) > .wrap.pressed {
    background-color: #576379;
}

.list.filters > li:not(.selected) > .wrap.over .launcher,
.list.filters > li:not(.selected) > .wrap.pressed .launcher {
    color: #fff;
}

.sList li.active.selected,
.mSelect-svList > .active.selected .mSelect-svList-button {
    background-color:#054e71;
}

.list.tabular.resources.header,
.list.tabular.jobs.header {
    background-color: #ecf1f4;
}

.list.resources.sublist .resourceName > a {
    color: #083970;
}

.tabSet.vertical.buttons .button {
    background-color: #576379;
}

.tabSet.vertical.buttons .selected .button,
.tabSet.vertical.buttons .selected .button.over,
.tabSet.vertical.buttons .selected .button.pressed,
.tabSet.vertical.buttons .button.pressed,
.tabSet.vertical.buttons .button.over {
    background-color: #10203f;
}


/* THEME */

/* sky */
.palette09,
.button.action.jumbo,
.homeSidebar-title,
.workflow-title,
.resourceBlock a:link,
.resourceBlock a:visited,
.resourceBlock a:hover,
.resourceBlock a:active,
.workflow-description a:hover {
    color:#035b85;
}

.button.action.primary.disabled,
.button.action.primary[disabled] {
    color: #475a72;
}

.tabSet.buttons.vertical [disabled] {
    /* eqivalent to .35 opacity */
    color: #9399a8;
}

.palette10,
#metaLinks a:active {
    color:#92c6de;
}

/* dark blue in gradient */
.palette11,
.column.decorated.minimized .vtitle,
.tabSet.horizontal.buttons .selected > .button > .wrap,
#filter-container .filterName,
#expression-container .filterName,
#scheduler_editor #stepDisplay > fieldset > legend,
.textAccent02,
.tabSet.text .selected.tab>.wrap,
.tabSet.text .selected.tab>.wrap.over,
.tabSet.text .selected.tab>.wrap.pressed,
.list.filters>li.selected>.wrap,
.list.stepIndicator .selected>.wrap,
.panel.info.system .message,
.resourceName,
.fsection,
#reportContainer a,
.plusminus .node>.wrap>.jrbookmark,
.filterManagerDialog .addNewFilter {
    color:#035b85;
}

.palette11,
.textAccent02,
.tabSet.text .selected.tab > .wrap,
.tabSet.text .selected.tab > .wrap.over,
.tabSet.text .selected.tab > .wrap.pressed,
.list.filters > li.selected>.wrap,
.list.filters > li.selected>.wrap.pressed,
.list.filters > li.selected>.wrap .launcher,
.list.stepIndicator .selected>.wrap,
.panel.info.system .message,
.list.type_basic .wrap a,
.resourceName,
.fsection,
.launcher,
#reportContainer a,
#scheduler_editor #stepDisplay > fieldset > legend,
.homeSidebar-title,
.workflow-title,
#resultsContainer .resourceName,
.resourceBlock a:link,
.resourceBlock a:visited,
.resourceBlock a:hover,
.resourceBlock a:active,
.workflow-description a:hover,
.workflow-buttonContainer .button,
.plusminus .node > .wrap > .jrbookmark,
.list.settings .panel.pane.settings .header > .title,
.tabSet.horizontal.buttons .selected > .button > .wrap,
.tabSet.horizontal.buttons .button.over,
.tabSet.horizontal.buttons .button.pressed,
.content .header > .title,
.resourceBlock-title,
.column.decorated.minimized .vtitle,
.flow #stepDisplay .column.primary .header .title,
.flow #stepDisplay .column.secondary .header .title,
#loginPage #metaLinks li,
#loginPage #metaLinks a,
#loginPage #metaLinks a:hover,
#loginPage #metaLinks a:active,
#needHelp {
    color: #083970;
}

/* orange */
.palette13,
.textAccent,
.button.action.jumbo.over {
    color:#dd770d;
}

/* .orange2 */
.palette14,
#needHelp:active,
.button.action.jumbo.pressed {
    color:#bf5b08;
}

/* orange3 */
.palette15,
.button.capsule.down {
    color: #532a0a;
}

/* orange4 */
.palette16,
.button.capsule.down.disabled,
.button.capsule.down[disabled],
.resourcePath {
    color: #9f481d;
}

.wrap.dragging,
.overlay.col.dragging,
.overlay.group.dragging,
.overlay.xtab.gr.dragging,
.overlay.xtab.m.dragging,
.overlay.legend.dragging,
.dragMultiple.dragging .wrap {
    /* an object being dragged gets the .dragging class applied */
    background-color:#035b85 !important;
}

#frameFooter #about a,
#frameFooter #about a:link,
#frameFooter #about a:visited,
#frameFooter #about a:hover,
#frameFooter #about a:active,
#frameFooter #copyright {
    color: #ddf4ff;
}

a.emphasis,
a.emphasis:link,
a.emphasis:visited,
a.emphasis:hover,
a.emphasis:active {
    color:inherit;
}


.palette09_bkgd {background-color:#035b85;}
.palette10_bkgd {background-color:#92c6de;}
.palette11_bkgd {background-color:#035b85;}
.palette13_bkgd {background-color:#dd770d;}
.palette14_bkgd {background-color:#bf5b08;}
.palette15_bkgd {background-color:#532a0a;}
.palette16_bkgd {background-color:#9f481d;}



/* PAGE SPECIFIC */
#loginPage #copy {
    background-color: #102035;
}

#loginPage #loginForm fieldset label .wrap,
#loginPage #loginForm fieldset label {
    color: #083970;
}

#loginForm .devices {
    background-position: center 0;
    background-size: auto;
}

#loginPage #frameFooter {
    border-top: 1px solid #a7b7d0; /* TIBCO navy, 65% lighter */
    height: 18px;
}

.resourceBlock-header {
    background-color: #f0f2f3;
}

.authorityPicker .selected,
.authorityPicker .highlighted.selected {
    background-color: #035b85;
}


/* JIVE */

.jive_dialog .dialogHeader {
    color: #035b85 !important;
}

#jive_dialog .button.action {
    background: url("images/button_action_sprite.png") repeat-x scroll 0 0 #D8DAD7 !important;
}

#jive_dialog .button.action.primary {
    background: url("images/button_action_primary_sprite.png") repeat-x scroll 0 0 #2881BB !important;
}

.jive_inputbutton {
    background-image:url("images/dialog_button_sprite.png") !important;
}

.jive_inputbutton.plain {
    background: none repeat scroll 0 0 transparent !important;
}


/* NEW UI PAGES */
/* domain designer */
.jr-mDomain-header.jr {
    border-bottom-color:#083970;
}

.jr-mDomain-mode-title-text.jr {
    color: #083970;
}


/* NEW COMPONENTS */
/* button */
.jr-mButtonAction.jr-mButtonOutline.jr-isDown.jr,
.jr-mButtonPrimary.jr {
    background-color: #10203f;
}

.jr-mButtonPrimary.jr-isHovered.jr,
.jr-mButtonPrimary.jr:focus,
.jr-mButtonPrimary.jr:hover {
    background-color: #004574;
}

.jr-mButtonLink.jr,
.jr-mButton-labelTheme.jr{
    color: #083970;
}

/* dialog */
.jr-mDialog-header-title.jr {
    color: #083970;
}

/* dropzone */
.jr-mDropzone.jr-isDropped.jr {
    border-color: #083970;
}

.jr-mDropzone.jr-isDropped.jr .jr-mDropzone-label.jr,
.jr-mDropzone-remover.jr {
    color: #083970;
}

.jr-mDropzone.jr-isOver.jr,
.jr-mDropzoneBar.jr {
    background-color: #083970;
}

/* icon */
.jr-mIcon.jr-loading.jr {
    border-top-color: #035b85;
}

/* link */
.jr-mLinkTheme.jr,
.jr-mLinkTheme.jr:link,
.jr-mLinkTheme.jr:visited,
.jr-mLinkTheme.jr:hover,
.jr-mLinkTheme.jr:active {
    color: #083970;
}

/* multi-select */
.jr-mSelectlist-item.jr-isSelected.jr,
.jr-mSelectlistSelected.jr > .jr-mSelectlist-item.jr {
    background-color: #10203f;
}

.jr-mSelectlist-item.jr-isSelected.jr-isHovered.jr {
    background-color: #576379  /* TIBCO midnight 30% lighter */
}

/* spinner */
.jr-mSpinnerPlain.jr {
    border-top-color: #035b85;
}

/* panel */
.jr-mPanel-header-title.jr {
    color: #083970;
}

/* tab */
.jr-mTab-item.jr-isActive.jr {
    background-color: #083970;
}

.jr-mTabSecondary.jr .jr-mTab-item.jr-isActive.jr {
    border-bottom-color: #083970;
}

.jr-mTabSecondary.jr .jr-mTab-item.jr-isActive.jr .jr-mTab-item-label.jr {
    color: #083970;
}

/* table */
.jr-mTablePresentation.jr .jr-mTable-rowDropzone.jr-isHovered.jr {
    background-color: #083970;
}

/* tooltip */
.jr-mTooltip.jr {
    background-color: #083970;
}

.jr-mTooltipBottom.jr:before,
.jr-mTooltipBottomRight:before,
.jr-mTooltipBottomLeft:before {
    border-bottom-color: #083970;
}

.jr-mTooltipTop:after,
.jr-mTooltipTopRight:after,
.jr-mTooltipTopLeft:after {
    border-top-color: #083970;
}

/* tree */
.jr-mTree-node.jr-isSelected.jr,
.jr-mTree-leaf.jr-isSelected.jr {
    background-color: #083970;
}
`;

class ThemeWrapper extends React.Component<IThemeWrapper> {

    private resolveTheme = (themeName) => {
        if (themeName === 'light'){
            return ""
        } else {
            return darkTheme;
        }
    }

    private getCurrentTheme = () => {
        if (this.props.theme){
            return this.props.theme;
        } else {
            return Conf.get(Pref.THEME);
        }
    }

    public render() {
        return <div>
            <Helmet>
                <style type="text/css" id="style_theme">{this.resolveTheme(this.getCurrentTheme())}</style>
            </Helmet>
        </div>
    }
}

const mapStateToProps = (state: any) => {
    return {
        theme: state.routerReducer.theme,
    };
};

export default connect(mapStateToProps)(ThemeWrapper);
