/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { TextInput, Select, WritableCombo, LOCALES, TIMEZONES, Icon } from '@jss/js-common';
import '../../assets/css/DataAdapterTypePicker.css';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import i18n from '../../i18n';
import { HTTPConfigurationPanel } from '../HTTPConfigurationPanel';
import { getRealName, IRepositoryItemDescriptor, MIME_TYPES, RepositoryApi } from '@jss/js-rest-api';
import { ResourcePickerDialog, RunContext } from '@jss/js-repository';
import { IconButton, InputAdornment } from '@material-ui/core';
import { advancedNameValidator, advancedUrlValidator } from '@jss/js-common/src/utils/validators';

interface IXmlDataAdapterEditor {
    name?: string,
    location?: string | null,
    useConnection?: boolean,
    language?: string,
    datePattern?: string,
    numberPattern?: string,
    selectExpression?: string,
    locale?: string,
    timeZone?: string,
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

const ALTERED_LOCALES = LOCALES.map((locale) => { 
    if (locale.key === 'en'){
        return {key: locale.key + '_EN', value: locale.value}
    }
    return locale; 
});

export class JsonDataAdapterEditor extends React.Component<IXmlDataAdapterEditor> {

    getSearchIcon = () => {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 2.5 }}>
            <Icon icon="search" onClick={this.openBrowseDialog} />
        </div>;
    }

    public openBrowseDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = [];
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('datasource.common.dialog.resource'),
                mode: 'open',
                title: i18n.t('datasource.common.dialog.title'),
                onFileSelected: this.confirmFile,
                defaultPath: defaultPath,
                allowSearch: true,
                allowedMimes: [MIME_TYPES.JSON, 'json'],
            }
        );
    }

    private confirmFile = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => {
        const path = folderPath.length > 0 ? `${RepositoryApi.inst().getParentPath(folderPath)}/${getRealName(file)}` : `/${getRealName(file)}`;  
        this.onFileUrlChange(path);
    }

    public render() {
        let httpConfigurationPanel;
        let searchIconAdornament;
        if (this.props.location && (this.props.location.startsWith('http://') || this.props.location.startsWith('https://'))) {
            httpConfigurationPanel = <HTTPConfigurationPanel {...this.props} />
        } else {
            searchIconAdornament = <InputAdornment position="end">
                <IconButton
                    edge="end"
                    style={{ padding: 0 }}
                >
                    {this.getSearchIcon()}
                </IconButton>
            </InputAdornment>;
        }
        return (<div className="tc-jsw-dataadapter-editor">

            <div className="tc-jsw-form-wrapper" style={{ flex: 1, alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}>

                <TextInput label={i18n.t('datasource.json.name')}
                    help={i18n.t('datasource.json.name.help')}
                    id="data-adapter-name"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.json.url')}
                    help={i18n.t('datasource.json.url.help')}
                    id="json-url-input"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.location}
                    onChange={this.onFileUrlChange}
                    advancedValidator={advancedUrlValidator}
                    InputProps={{
                        endAdornment: !this.props.readOnly ? searchIconAdornament : undefined,
                        readOnly: this.props.readOnly
                    }}/>

                {httpConfigurationPanel}

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.props.useConnection}
                            onChange={this.onUseconnectionChange}
                            name="useConnection"
                            color="primary"
                            disabled={this.props.readOnly}
                        />
                    }
                    label={i18n.t('datasource.json.useConnection')}
                />

                <Select className={'tc-jsw-dataadapter-editor-text-input'}
                    label={i18n.t('datasource.json.language')} items={[{ key: 'json', value: 'json' }, { key: 'jsonql', value: 'jsonql' }]}
                    value={this.props.language}
                    onItemSelected={this.onLanguageChange}
                    disabled={this.props.readOnly}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <TextInput label={i18n.t('datasource.json.datepattern')}
                    help={i18n.t('datasource.json.datepattern.help')}
                    id="json-date-pattern"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.datePattern}
                    onChange={this.onDatePatternChange}
                    InputProps={{
                        readOnly: this.props.readOnly,
                        title: i18n.t('datasource.csv.descriptor.datepattern'),
                    }} />

                <TextInput label={i18n.t('datasource.json.numberpattern')}
                    help={i18n.t('datasource.json.numberpattern.help')}
                    id="json-number-pattern"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.numberPattern}
                    onChange={this.onNumberPatternChange}
                    InputProps={{
                        readOnly: this.props.readOnly,
                        title: i18n.t('datasource.csv.descriptor.numberpattern'),
                    }} />

                <TextInput label={i18n.t('datasource.json.selectExpression')}
                    id="json-select-expression"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.selectExpression}
                    onChange={this.onSelectExpressionChange}
                    InputProps={{
                        readOnly: this.props.readOnly
                    }} />

                <WritableCombo label={i18n.t('datasource.json.timezone')}
                    id="json-timezone"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.timeZone}
                    items={TIMEZONES.map((timezone) => { return { key: timezone.id, value: `${timezone.id} (${timezone.label})` } })}
                    onComboChange={this.onTimezoneChange}
                    disabled={this.props.readOnly}/>

                <WritableCombo label={i18n.t('datasource.json.locale')}
                    id="json-locale"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    items={ALTERED_LOCALES}
                    value={this.props.locale}
                    onComboChange={this.onLocalChange}
                    disabled={this.props.readOnly}/>

            </div>
        </div>);
    }

    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private onFileUrlChange = (str: string) => {
        this.notifyChange({ location: str });
    }

    private onDatePatternChange = (str: string) => {
        this.notifyChange({ datePattern: str });
    }

    private onNumberPatternChange = (str: string) => {
        this.notifyChange({ numberPattern: str });
    }

    private onLocalChange = (selectedKey: string | undefined, value: string) => {
        this.notifyChange({ locale: selectedKey ? selectedKey : value });
    }

    private onTimezoneChange = (selectedKey: string | undefined, value: string) => {
        this.notifyChange({ timeZone: selectedKey ? selectedKey : value });
    }

    private onSelectExpressionChange = (str: string) => {
        this.notifyChange({ selectExpression: str });
    }

    private onUseconnectionChange = (event: React.FormEvent<HTMLElement>) => {
        this.notifyChange({ useConnection: (event.target as HTMLInputElement).checked });
    }

    private onLanguageChange = (str: string) => {
        this.notifyChange({ language: str });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }
}

JsonDataAdapterEditor.contextType = RunContext;