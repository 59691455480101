/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { MDivElementDataset } from "../../dataset/MDatasetRun";
import { PHighChartsDataset } from "../UPHighChartsDataset";
import { PLabel } from "../../../ui/primitive/UPLabel";
import { PConnectedTable } from "../../../ui/tables/UPConnectedTable";
import { MHCMeasures } from "./MHCMeasures";
import { MHCSeries } from "./MHCSeries";

export const FHighChartsDataset = {
};

export const MHighChartsDataset: APDescriptor = new PHighChartsDataset({
    id: 'chartSettings._jrs_adhoc', label: 'Data',
    layouts: [{ ...MDivElementDataset, path: 'multiAxisData.multiAxisDataset' },
    new PConnectedTable({
        path: 'multiAxisData.dataAxes',
        id: "Columns", label: 'Categories', rowLabel: 'Category', rowLabelPaths: ['axisLevelName'], layouts: [MHCSeries],
        default: { id: 'categories-1' }
    }),
    new PConnectedTable({
        path: 'multiAxisData.dataAxes',
        id: "Rows", label: 'Series', rowLabel: 'Series', rowLabelPaths: ['axisLevelName'], layouts: [MHCSeries],
        default: { id: 'series-1' }
    }),
    new PConnectedTable({
        path: 'multiAxisData',
        id: "multiAxisMeasures", label: 'Measures', rowLabel: 'Measure', rowLabelPaths: ['measureName'], layouts: [MHCMeasures],
        default: { id: 'measures-1' }
    })],
    layouts1: [new PLabel({ label: ' AdHoc Data' })]
});
