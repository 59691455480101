export const fr = {
	searchfile: {
		placeholder: "Recherche",
		help: "Tapez le nom du fichier"
	},
	repoview: {
		emptyfolder: {
			text: "Ce dossier est vide"
		},
		home: "Accueil"
	},
	createfolder: {
		text: "Nouveau dossier"
	},
	newresources: {
		menubutton: {
			text: "Nouveau"
		},
		folder: {
			text: "Nouveau dossier"
		},
		report: {
			text: "Nouveau rapport"
		},
		da: {
			text: "Nouveau Data Adapter"
		}
	},
	resourcestable: {
		header: {
			name: "Nom",
			lastmodified: "Dernière modification",
			type: "Type"
		}
	},
	repository: {
		delete: {
			dialog: {
				cancel: "Annuler",
				save: "Enregistrer",
				delitem: "Supprimer l'élément",
				msg: "Voulez-vous vraiment supprimer l'élément?"
			},
			confirmation: {
				title: "Confirmer la suppresion de la ressource",
				text: "Réfléchissez a deux fois, la resource sera définitivement supprimée."
			}
		}
	},
	menuitem: {
		info: {
			text: "Info"
		},
		rename: {
			text: "Renommer"
		},
		delete: {
			text: "Supprimer"
		}
	},
	resource: {
		permission: {
			read: "Lecture",
			write: "Ecriture",
			execute: "Execution",
			none: "None"
		},
		name: "Nom",
		path: "Chemin",
		mime: "MIME",
		id: "ID",
		description: "Description",
		lastmodified: {
			date: "Date de la dernière modification"
		},
		created: {
			date: "Date de création"
		}
	},
	File: "Fichier",
	Folder: "Dossier",
	Repository: "Dépôt",
	Branch: "Branche",
	Properties: "Propriétés",
	Image: "Image",
	Font: "Police",
}