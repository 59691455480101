/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { PlotOrientationEnum } from "../../enums/PlotOrientationEnum";
import { PColor } from "../../../ui/primitive/UPColor";
import { PInt } from "../../../ui/primitive/UPInteger";
import { PConnectedTable } from "../../../ui/tables/UPConnectedTable";
import { PTableElement } from "../../../ui/tables/UPTableElement";


export const FChartPlot = {
    backcolor: new PColor({ id: 'backcolor', label: 'Backcolor' }),
    backgroundAlpha: new PInt({ id: 'backgroundAlpha', label: 'Background Alpha' }),
    foregroundAlpha: new PInt({ id: 'foregroundAlpha', label: 'Foreground Alpha' }),
    orientation: PlotOrientationEnum,
    seriesColors: new PConnectedTable({
        id: 'seriesColor', path:'plot', label: 'Series Color', expanded: true, rowLabel: 'Color', rowLabelPaths: ['color'],
        layouts: [new PTableElement({
            label: 'Series Color',
            anchorOrigin: {vertical: 'center', horizontal: 'right'},
            transformOrigin: {vertical: 'center', horizontal: 'right'},
            layouts: [new PColor({ id: 'color', label: 'Color' }), new PInt({ id: 'seriesOrder', label: 'Series Order' })]
        })]
    })
};

export const MChartPlot: APDescriptor = {
    type: 'MChartPlot', id: 'plot', path: 'plot',
    layouts: [FChartPlot.backcolor, FChartPlot.backgroundAlpha, FChartPlot.foregroundAlpha, FChartPlot.orientation, FChartPlot.seriesColors]
};

export const MMultiAxisPlot: APDescriptor = {
    type: 'multiAxisPlot', id: 'multiAxisPlot',
    path: 'multiAxisPlot.plot',
    layouts: [...MChartPlot.layouts]
};