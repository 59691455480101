/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { MChartPlot } from "./MChartPlot";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { MCategoryAxis, MValueAxis } from "./MAxis";

export const FLinePlot = {
    isShowLines: new PBoolean({id: 'isShowLines', label: 'Show Lines', canBeNull: true}),
    isShowShapes: new PBoolean({id: 'isShowShapes', label: 'Show Shapes', canBeNull: true}),
};

export const MLinePlot: APDescriptor = {
    type: 'MLinePlot',
    path: 'linePlot',
    id: 'linePlotProeprtiesDescriptor',
    layouts: [...MChartPlot.layouts, FLinePlot.isShowLines, FLinePlot.isShowShapes,
        MCategoryAxis, MValueAxis
    ]
};
