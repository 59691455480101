/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { FFusionWidgetProps, FFusionWidgets, MFusionWidgetCommon } from "./MFusionWidgetsCommon";
import GanttChartIcon from "../../../../../../assets/icons/charts/gantt.svg";
import { PDiv } from "../../../ui/composite/UPDiv";
import { PConnectedTableDetail } from "../../../ui/tables/UPConnectedTableDetail";
import { MHyperlinks, MHyperlinksTarget } from "../../common/MHyperlinks";
import { MDatasetRun } from "../../dataset/MDatasetRun";
import { PCategories } from "../../../ui/composite/UPCategories";
import { FFusionChart } from "../chart/MFusionChart";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { FFusionMap } from "../map/MFusionMap";
import { PBoolean } from "../../../ui/primitive/UPBoolean";

export const FFusionGantt = {
    useDate: new PBoolean({ id: 'useDate', label: 'Use Date' }),
    useTime: new PBoolean({ id: 'useDate', label: 'Use Time' })
};

export const MFusionGanttChart: APDescriptor = {
    id: 'Gantt', type: 'fusion.chart.Gantt', label: 'Gantt', icon: GanttChartIcon, path: 'widgetProperties',
    layouts: [FFusionGantt.useDate, FFusionGantt.useTime, ...MFusionWidgetCommon.layouts,
    new PAccordeon({
        id: 'ganttLine', label: "Gantt Line", layouts: [FFusionWidgets.ganttLineColor, FFusionWidgets.ganttLineAlpha]
    }),
    FFusionWidgets.dateInToolTip, FFusionWidgets.showFullDataTable],
    default: {
        type: 'FUSION_WIDGET', widgetType: 'Gantt',
        widgetProperties: [],
        categorySets: [{ categories: [{ startExpression: "new SimpleDateFormat(\"yyyy-MM-dd\").parse(\"2009-07-27\")", endExpression: "new SimpleDateFormat(\"yyyy-MM-dd\").parse(\"2009-08-27\")", labelExpression: '"Sample Category"' }] }],
        tasks: {
            dataset: {},
            tasks: [{
                idExpression: '"Task1"',
                process: { idExpression: '"Task-Process"', labelExpression: '"Task1-Process1"' }, startExpression: "new SimpleDateFormat(\"yyyy-MM-dd\").parse(\"2009-07-27\")", endExpression: "new SimpleDateFormat(\"yyyy-MM-dd\").parse(\"2009-08-27\")", labelExpression: '"Sample Task"',
            }],
            tasksProperties: []
        }
    }
};

export const MColumn: APDescriptor = new PAccordeon({
    id: 'col', label: "Column", layouts: [
        FFusionWidgets.align, FFusionWidgets.vAlign,
        new PAccordeon({
            id: 'bg', label: "Background", layouts: [FFusionMap.bgColor, FFusionMap.bgAlpha]
        }),
        new PAccordeon({
            id: 'font', label: "Font", layouts: [FFusionWidgets.font, FFusionWidgets.fontSize, FFusionWidgets.fontColor, FFusionWidgets.isBold, FFusionWidgets.isItalic, FFusionWidgets.isUnderline]
        })]
});
export const MColumnHeader: APDescriptor = new PAccordeon({
    id: 'colheader', label: "Column Header", layouts: [
        FFusionWidgets.headerAlign, FFusionWidgets.headerVAlign,
        new PAccordeon({
            id: 'bg', label: "Background", layouts: [FFusionWidgets.headerBgColor, FFusionWidgets.headerBgAlpha]
        }),
        new PAccordeon({
            id: 'font', label: "Font", layouts: [FFusionWidgets.headerFont, FFusionWidgets.headerFontSize, FFusionWidgets.headerFontColor, FFusionWidgets.headerIsBold, FFusionWidgets.headerIsItalic, FFusionWidgets.headerIsUnderline]
        })]
});
export const MColumnPadding: APDescriptor = new PAccordeon({
    id: 'col', label: "Column", layouts: [
        FFusionWidgets.align, FFusionWidgets.vAlign,
        new PAccordeon({
            id: 'bg', label: "Background", layouts: [FFusionMap.bgColor, FFusionMap.bgAlpha, FFusionWidgets.verticalPadding]
        }),
        new PAccordeon({
            id: 'font', label: "Font", layouts: [FFusionWidgets.font, FFusionWidgets.fontSize, FFusionWidgets.fontColor, FFusionWidgets.isBold, FFusionWidgets.isItalic, FFusionWidgets.isUnderline]
        })]
});
export const MMilestone: APDescriptor = new PCategories({
    id: 'gantt.miliestone',
    label: 'Milestones', layouts: [
        new PDiv({
            id: 'fusion.milestones', label: 'Milestone',
            layouts: [FFusionWidgets.taskIdExpression, FFusionWidgets.dateExpression,
            new PAccordeon({
                id: 'milestone.properties', label: "Properties", path: 'milestoneProperties',
                layouts: [FFusionWidgets.shape, FFusionWidgets.numSides, FFusionMap.startAngle, FFusionMap.radius, FFusionWidgets.color, FFusionWidgets.alpha]
            }),
            new PAccordeon({
                id: 'milestone.border', label: "Border", path: 'milestoneProperties',
                layouts: [FFusionMap.borderColor, FFusionMap.borderThickness]
            })]
        }),

        new PDiv({
            id: 'fusion.data.milestones.hyperlinks',
            label: 'Hyperlink', path: 'hyperlink',
            layouts: [...MHyperlinks.layouts]
        })
    ]
});
export const MProcess: APDescriptor = new PCategories({
    id: 'gantt.process',
    label: 'Processes', layouts: [
        new PDiv({
            id: 'fusion.processe', label: 'Process',
            layouts: [FFusionChart.labelExpression, FFusionWidgets.idExpression,
            { ...MColumn, id: 'properties', label: "Properties", path: 'processProperties' }]
        }),
        new PDiv({
            id: 'fusion.data.processes.hyperlinks',
            label: 'Hyperlink', path: 'hyperlink',
            layouts: [...MHyperlinks.layouts]
        })
    ]
});
export const MTaskProperties: APDescriptor = new PDiv({
    id: 'tasksprops', label: "Properties",
    layouts: [
        FFusionWidgetProps.showLabels, FFusionWidgets.showPercentLabel, FFusionWidgets.showStartDate, FFusionWidgets.showEndDate,
        FFusionWidgets.height, FFusionWidgets.topPadding,
        new PAccordeon({
            id: 'task.border', label: "Border",
            layouts: [FFusionMap.showBorder, FFusionMap.borderColor, FFusionMap.borderAlpha, FFusionMap.borderThickness]
        }),
        new PAccordeon({
            id: 'task.fillcolor', label: "Fill Color", layouts: [FFusionWidgets.color, FFusionWidgets.alpha]
        }),
        new PAccordeon({
            id: 'task.font', label: "Font", layouts: [FFusionWidgets.font, FFusionWidgets.fontSize, FFusionWidgets.fontColor]
        })
    ]
});
export const MTaskPropertiesSmall: APDescriptor = new PDiv({
    id: 'tasksprops', label: "Properties",
    layouts: [
        FFusionWidgetProps.showlabels, FFusionWidgets.showpercentlabel, FFusionWidgets.showstartdate, FFusionWidgets.showenddate,
        new PAccordeon({
            id: 'task.border', label: "Border",
            layouts: [FFusionWidgets.showborder, FFusionWidgets.bordercolor, FFusionWidgets.borderalpha, FFusionWidgets.borderthickness]
        }),
        new PAccordeon({
            id: 'task.fillcolor', label: "Fill Color", layouts: [FFusionWidgets.color, FFusionWidgets.alpha]
        }),
        new PAccordeon({
            id: 'task.font', label: "Font", layouts: [FFusionWidgets.font, FFusionWidgets.fontsize, FFusionWidgets.fontcolor]
        })
    ]
});
export const MFusionGanttChartDataset: APDescriptor = {
    id: 'Gantt', type: 'fusion.chart.Gantt.data', label: 'Gantt', icon: GanttChartIcon,
    layouts: [
        new PCategories({
            id: 'gantt', label: 'Data', layouts: [
                new PConnectedTableDetail({
                    id: 'categorySets', label: 'Categories', rowLabel: 'Category', expanded: true,
                    layouts: [new PCategories({
                        id: 'gantt.categorysets.category',
                        label: 'Category', layouts: [
                            new PDiv({ ...MColumnPadding, id: 'categories.properties', label: "Properties", path: 'categoriesProperties' }),
                            new PConnectedTableDetail({
                                id: 'categories', label: 'Categories', rowLabel: 'Category', rowLabelPaths: ['labelExpression'], expanded: true,
                                layouts: [new PCategories({
                                    id: 'gantt.categorysets.categories.item',
                                    layouts: [
                                        new PDiv({
                                            id: 'fusion.category', label: 'Category',
                                            layouts: [FFusionChart.labelExpression, FFusionWidgets.startExpression, FFusionWidgets.endExpression,
                                            { ...MColumnPadding, id: 'categories.properties', label: "Properties", path: 'categoryProperties' }]
                                        }),
                                        new PDiv({
                                            id: 'fusion.data.categories.hyperlinks',
                                            label: 'Hyperlink', path: 'hyperlink',
                                            layouts: [...MHyperlinksTarget.layouts]
                                        })]
                                })], default: { labelExpression: '"change me"' }
                            }),
                            new PDiv({
                                id: 'fusion.data.bulk',
                                label: 'Bulk Expression',
                                layouts: [FFusionWidgets.bulkCategoriesExpression]
                            })]
                    })], default: {}
                }),
                new PCategories({
                    id: 'gantt.tasks',
                    label: 'Tasks', path: 'tasks', layouts: [
                        new PDiv({
                            id: 'fusion.data.dataset', path: 'dataset',
                            label: 'Dataset',
                            layouts: [FFusionWidgets.incrementType, FFusionWidgets.incrementWhenExpression, FFusionWidgets.resetType, MDatasetRun]
                        }),
                        { ...MTaskPropertiesSmall, id: 'task.props.options', path: 'tasksProperties' },
                        new PConnectedTableDetail({
                            id: 'tasks', label: 'Tasks', rowLabel: 'Task', rowLabelPaths: ['idExpression'], expanded: true,
                            layouts: [new PCategories({
                                id: 'gantt.tasks.tasks',
                                label: 'Tasks', layouts: [
                                    new PDiv({
                                        id: 'fusion.task', label: 'Task',
                                        layouts: [FFusionMap.idExpression, FFusionChart.labelExpression, FFusionWidgets.startExpression, FFusionWidgets.endExpression,
                                        FFusionWidgets.processIdExpression, FFusionWidgets.percentCompleteExpression, FFusionWidgets.isGroupExpression,
                                        { ...MTaskProperties, path: 'taskProperties' }]
                                    }),
                                    { ...MMilestone, id: 'gantt.task.milestone', path: 'milestone' },
                                    { ...MProcess, id: 'gantt.task.process', path: 'process' },
                                    new PDiv({
                                        id: 'fusion.data.tasks.hyperlinks',
                                        label: 'Hyperlink', path: 'hyperlink',
                                        layouts: [...MHyperlinks.layouts]
                                    })
                                ]
                            })
                            ], default: { color: '#000000' }
                        })
                    ]
                }),
                new PCategories({
                    id: 'gantt.datatable',
                    label: 'Datatable', path: 'datatable', layouts: [
                        new PDiv({
                            id: 'datatable.props', path: 'datatableProperties', label: 'Properties',
                            layouts: [MColumn, MColumnHeader]
                        }),
                        new PConnectedTableDetail({
                            id: 'dataColumns', label: 'Datatable Columns', rowLabel: 'Datatable Column', rowLabelPaths: ['headerTextExpression'], expanded: true,
                            layouts: [new PCategories({
                                id: 'gantt.datatable.column',
                                label: 'Datatable Columns', layouts: [
                                    new PDiv({
                                        id: 'fusion.colheader', label: 'Column Header',
                                        layouts: [FFusionWidgets.headerTextExpression, { ...MColumnHeader, path: 'columnProperties' }]
                                    }),
                                    new PDiv({
                                        id: 'fusion.col', label: 'Column',
                                        layouts: [{ ...MColumn, path: 'columnProperties' },
                                        new PConnectedTableDetail({
                                            id: 'items', label: 'Items', rowLabel: 'Item', rowLabelPaths: ['textExpression'], expanded: true,
                                            layouts: [new PCategories({
                                                id: 'gantt.datatable.item',
                                                label: 'Items', layouts: [
                                                    new PDiv({
                                                        id: 'fusion.datatable.col.item.col', label: 'Column',
                                                        layouts: [FFusionWidgets.textExpression, { ...MColumn, path: 'itemProperties' }]
                                                    }),
                                                    new PDiv({
                                                        id: 'fusion.data.datacolumn.col.item.hyperlinks',
                                                        label: 'Hyperlink', path: 'hyperlink',
                                                        layouts: [...MHyperlinks.layouts]
                                                    })
                                                ]
                                            })
                                            ], default: { color: '#000000' }
                                        })
                                        ]
                                    }),
                                    new PDiv({
                                        id: 'fusion.data.datacolumns.hyperlinks',
                                        label: 'Hyperlink', path: 'headerHyperlink',
                                        layouts: [...MHyperlinks.layouts]
                                    })
                                ]
                            })
                            ], default: { color: '#000000' }
                        })
                    ]
                }),
                new PCategories({
                    id: 'gantt.processes',
                    label: 'Processes', path: 'processes', layouts: [
                        new PDiv({
                            id: 'fusion.data.dataset', path: 'dataset',
                            label: 'Dataset',
                            layouts: [FFusionWidgets.incrementType, FFusionWidgets.incrementWhenExpression, FFusionWidgets.resetType, MDatasetRun]
                        }),
                        new PDiv({
                            id: 'processesproperties', path: 'processesProperties', label: 'Properties',
                            layouts: [FFusionWidgets.positionInGrid, MColumn, MColumnHeader]
                        }),
                        new PConnectedTableDetail({
                            id: 'processes', label: 'Processes', rowLabel: 'Process', rowLabelPaths: ['taskIdExpression'], expanded: true,
                            layouts: [MProcess], default: { color: '#000000' }
                        })
                    ]
                }),
                new PCategories({
                    id: 'gantt.connectors',
                    label: 'Connectors', path: 'connectors', layouts: [
                        new PDiv({
                            id: 'fusion.data.dataset', path: 'dataset',
                            label: 'Dataset',
                            layouts: [FFusionWidgets.incrementType, FFusionWidgets.incrementWhenExpression, FFusionWidgets.resetType, MDatasetRun]
                        }),
                        new PDiv({
                            id: 'connectors.line', path: 'connectorsProperties', label: 'Line',
                            layouts: [FFusionWidgets.color, FFusionWidgets.alpha, FFusionWidgets.isDashed, FFusionWidgets.thickness]
                        }),
                        new PConnectedTableDetail({
                            id: 'connectors', label: 'Connectors', rowLabel: 'Connector', rowLabelPaths: ['fromTaskIdExpression'], expanded: true,
                            layouts: [new PDiv({
                                id: 'fusion.connector', label: 'Connector',
                                layouts: [FFusionWidgets.fromTaskIdExpression, FFusionWidgets.toTaskIdExpression, FFusionWidgets.fromTaskConnectStartExpression, FFusionWidgets.toTaskConnectEndExpression,
                                new PAccordeon({
                                    id: 'connector.line', path: 'connectorProperties', label: 'Line', expanded: true,
                                    layouts: [FFusionWidgets.color, FFusionWidgets.alpha, FFusionWidgets.isDashed, FFusionWidgets.thickness]
                                })]
                            })], default: { color: '#000000' }
                        })
                    ]
                }),
                new PCategories({
                    id: 'gantt.milestones',
                    label: 'Milestones', path: 'milestones', layouts: [
                        new PDiv({
                            id: 'fusion.data.dataset', path: 'dataset',
                            label: 'Dataset',
                            layouts: [FFusionWidgets.incrementType, FFusionWidgets.incrementWhenExpression, FFusionWidgets.resetType, MDatasetRun]
                        }),
                        new PConnectedTableDetail({
                            id: 'milestones', label: 'Milestones', rowLabel: 'Milestone', rowLabelPaths: ['taskIdExpression'], expanded: true,
                            layouts: [MMilestone], default: { color: '#000000' }
                        })
                    ]
                }),
                new PConnectedTableDetail({
                    id: 'legendItems', label: 'Legend Items', rowLabel: 'Legend Item', rowLabelPaths: ['color', 'labelExpression'], expanded: true, layouts: [new PDiv({
                        id: 'fusion.legenditem',
                        layouts: [FFusionChart.color, FFusionChart.labelExpression]
                    })], default: { color: '#000000' }
                }),
                new PConnectedTableDetail({
                    id: 'trendLines', label: 'Trend Lines', rowLabel: 'Trend Line', rowLabelPaths: ['color', 'labelExpression'], expanded: true, layouts: [new PDiv({
                        id: 'fusion.colorranges',
                        layouts: [FFusionChart.color, FFusionChart.trendZone, FFusionChart.labelExpression, FFusionChart.startValueExpression, FFusionChart.endValueExpression
                        ]
                    })], default: { color: '#000000' }
                }),
                new PDiv({
                    id: 'fusion.data.gantt.hyperlinks',
                    label: 'Hyperlink', path: 'hyperlink',
                    layouts: [...MHyperlinks.layouts]
                })]
        })]
};