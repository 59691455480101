/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { List, Map, OrderedMap, fromJS, isImmutable } from 'immutable';
import { initializeMapWithObject } from '../../sagas/report/document/documentFactory';
import * as Actions from '../../actions/reportActions';
import { PAGE_HORIZONTAL_MARGIN_MIN, PAGE_VERTICAL_OFFSET } from '../../sagas/report/designer/documentUtils';
import { BandTypes, ElementTypes } from '../../sagas/report/document/elementTypes';
import { getCrosstabNoDataCell } from '../../components/report/crosstab/CrosstabUtils';
import { refactorElement, refactorOnDelete } from './refactorings/refactorElement';
import _ from 'lodash';
import { removeElement } from './refactorings/reducerUtils';


export const STATUS_LOADING = 'LOADING';
export const STATUS_LOADED = 'LOADED';
export const STATUS_NOT_LOADED = 'NOT_LOADED';
export const STATUS_NOT_FOUND = 'NOT_FOUND';
export const STATUS_ERROR = 'ERROR';
export const FETCHING_STATUS = { STATUS_LOADED, STATUS_LOADING, STATUS_NOT_LOADED, STATUS_ERROR, STATUS_NOT_FOUND }

/**
 * At start, we don't have any document to work with, so our model is actually null.
 */
export const defaultReportState = Map<string, any>(initializeMapWithObject({
    model: null,
    isDirty: false,
    undoRedo: Map({
        past: List([]),
        future: List([]),
    }),
    zoom: 1.0,
    viewport: Map<string, number>(initializeMapWithObject({
        x: 0,
        y: 0,
        width: 0,
        height: 0
    })),
    // The client area is the calculated working space, which contains all the objects on
    // screen and an additional margin of 20px...
    clientArea: Map({
        x: 0, // Useful for negative extension of the document
        y: 0, // Useful for negative extension of the document
        width: 0,
        height: 0
    }),
    interactionElements: OrderedMap(), // Elements shown during interactions... (to be replaced with a sorted hashmap...)
    mouse: Map({
        operation: null, // Define the current drag operation type (i.e. drag selection, element move, resize, ecc...)
        dragStarted: false, // This will be updated after the first mouse move
        // (eventually, based on the operation, with some resistence)
        startLocation: null,
        currentLocation: null,
        operationState: null, // The state of the operation, it may contain a phantom figure or other info....
    }),
    highlightedContainer: null, // This property shows the path value of the currently selected container for a potential
    // drop opertation
    scroll: Map({
        x: 0, // Scroll is already zoomed
        y: 0  // Scroll is already zoomed
    }),
    selection: Map<string, any>(),
    datasetSelection: 'main',
    outlineExpandedNodes: ['/'],
    modelActions: List(),
    currentEditorIndex: undefined,
    subeditors: List<Map<string, any>>(),
    fileDescriptor: undefined,
    repositoryPath: [],
    cachedData: Map({
        comboValues: Map({
            status: FETCHING_STATUS.STATUS_NOT_LOADED,
        }),
    }),
    parametersValue: Map<string, any>(),
    isTextControlFocused: false,
    widgetStatus: Map<string, any>(),
}))

const clearSelection = (state: Map<string, any>) => {
    //const elements = state.getIn(['model', 'elements']).map((ele: any, key: string) => ele.set('selected', false));
    //return state.setIn(['model', 'elements'], elements);
    return state.set('selection', Map<string, any>());
}

const validateSelection = (state: Map<string, any>) => {
    let newState = state;
    const currentSelection = state.get('selection') as Map<string, any>;
    const elements = state.getIn(['model', 'elements']) as Map<string, any>;
    currentSelection.forEach((selectionValue, elementKey) => {
        const selectedElement = elements.get(elementKey);
        if (!selectedElement || selectedElement.get('type') !== selectionValue.type) {
            newState = newState.removeIn(['selection', elementKey]);
        }
    });
    return newState;
}

const pushToUndoStack = (state: Map<string, any>, label: string, selection: Map<string, any>) => {
    const oldPresent = state.get('model');
    const parametersValue = state.get('parametersValue');
    state = state.updateIn(["undoRedo", "past"], List(), list => (list as List<Map<string, any>>).push(
        Map({ label: label ? label : 'Operation', model: oldPresent, selection: selection, parametersValue: parametersValue })));
    // Cleare future...
    return state.setIn(["undoRedo", "future"], List());
}

const clearUndoStack = (state: Map<string, any>) => {
    return state.setIn(['undoRedo', 'past'], List([]))
        .setIn(['undoRedo', 'future'], List([]));
}

const performUndo = (state: Map<string, any>) => {

    if ((state.getIn(['undoRedo', 'past']) as List<Map<string, any>>).count() === 0) {
        return state; // No undo operation available...
    }

    const previousOperation = (state.getIn(['undoRedo', 'past']) as List<Map<string, any>>).last();
    const previousModel = previousOperation.get('model');
    const label = previousOperation.get('label');
    const selection = previousOperation.get('selection');
    const parametersValue = previousOperation.get('parametersValue');
    const currentSelection = state.get('selection');
    // Remove from the past
    state = state.updateIn(["undoRedo", "past"], List<Map<string, any>>(), list => (list as List<Map<string, any>>).pop());
    // Add current model to the future...
    const currentModel = state.get('model');
    const currentParametersValue = state.get('parametersValue');
    state = state.updateIn(["undoRedo", "future"], List<Map<string, any>>(), list => (list as List<Map<string, any>>).push(
        Map({ label, model: currentModel, selection: selection ? currentSelection : undefined, parametersValue: currentParametersValue })
    ));

    //set the editor dirty
    state = state.setIn(['isDirty'], true);

    if (selection) {
        state = state.set('selection', selection);
    }

    //restore also the paremters 
    if (parametersValue) {
        state = state.set('parametersValue', parametersValue);
    }

    // Restore the model
    return state.set('model', previousModel);
}

const performRedo = (state: Map<string, any>) => {

    if ((state.getIn(['undoRedo', 'future']) as List<Map<string, any>>).count() === 0) {
        return state; // No redo operation available...
    }

    const previousOperation = (state.getIn(['undoRedo', 'future']) as List<Map<string, any>>).last();
    const previousModel = previousOperation.get('model');
    const label = previousOperation.get('label');
    const selection = previousOperation.get('selection');
    const parametersValue = previousOperation.get('parametersValue');
    const currentModel = state.get('model');
    const currentSelection = state.get('selection');
    const currentParametersValue = state.get('parametersValue');

    // Remove from the future
    state = state.updateIn(["undoRedo", "future"], List(), list => (list as List<Map<string, any>>).pop());
    // Add current model to the past...
    state = state.updateIn(["undoRedo", "past"], List(), list => (list as List<Map<string, any>>).push(Map({ label, model: currentModel, selection: currentSelection, parametersValue: currentParametersValue })));

    //set the editor dirty
    state = state.setIn(['isDirty'], true);

    if (selection) {
        state = state.set('selection', selection);
    }

    //restore also the paremters 
    if (parametersValue) {
        state = state.set('parametersValue', parametersValue);
    }

    // Restore the model
    return state.set('model', previousModel);
}

/**
 * This function updates the scroll bar position when the viewport changes.
 * The state is an immutable Map.
 * We need to assume, in the future, that this is not the case and return instead
 * ad object with the only 2 things that are updated:
 * newState.scroll.x and newState.scroll.y
 * 
 */
const updateScroll = (newState: Map<string, any>, subeditorIndex?: number) => {

    if (subeditorIndex === undefined) {
        //the operation is on the main editor

        const clientArea = newState.get('clientArea').toJS();
        const zoom = newState.get('zoom');
        const viewport = newState.get('viewport').toJS();
        const fullHeight = clientArea.height + (2 * PAGE_VERTICAL_OFFSET);
        const fullWidth = Math.max(clientArea.width + 2 * PAGE_HORIZONTAL_MARGIN_MIN, viewport.width);

        const maxXScroll = (fullWidth * zoom - viewport.width) / zoom;
        const maxYScroll = (fullHeight * zoom - viewport.height) / zoom;

        if (fullWidth * zoom <= viewport.width) {
            newState = newState.setIn(['scroll', 'x'], 0);
        } else {
            // Max scroll = 
            if (newState.getIn(['scroll', 'x']) < 0) {
                newState = newState.setIn(['scroll', 'x'], 0);
            }
            else if (newState.getIn(['scroll', 'x']) > maxXScroll) {
                newState = newState.setIn(['scroll', 'x'], maxXScroll);
            }
        }

        if (fullHeight * zoom <= viewport.height) {
            newState = newState.setIn(['scroll', 'y'], 0);
        } else {
            const currentY = newState.getIn(['scroll', 'y'], 0) as number;
            if (currentY < 0) {
                newState = newState.setIn(['scroll', 'y'], 0);
            }
            else if (currentY > maxYScroll) {
                newState = newState.setIn(['scroll', 'y'], maxYScroll);
            }
        }
        return newState;
    } else {
        let subeditor = newState.getIn(['subeditors', subeditorIndex]) as Map<string, any> | undefined;
        if (subeditor) {
            const clientArea = subeditor.get('clientArea').toJS();
            const zoom = subeditor.get('zoom');
            const viewport = newState.get('viewport').toJS();
            const fullHeight = clientArea.height + (2 * PAGE_VERTICAL_OFFSET);
            const fullWidth = Math.max(clientArea.width + 2 * PAGE_HORIZONTAL_MARGIN_MIN, viewport.width);

            const maxXScroll = (fullWidth * zoom - viewport.width);
            const maxYScroll = (fullHeight * zoom - viewport.height);

            if (fullWidth * zoom <= viewport.width) {
                subeditor = subeditor.setIn(['scroll', 'x'], 0);
            }
            else {
                // Max scroll = 
                if (subeditor.getIn(['scroll', 'x']) < 0) {
                    subeditor = subeditor.setIn(['scroll', 'x'], 0);
                }
                else if (subeditor.getIn(['scroll', 'x']) > maxXScroll) {
                    subeditor = subeditor.setIn(['scroll', 'x'], maxXScroll);
                }
            }

            if (fullHeight * zoom <= viewport.height) {
                subeditor = subeditor.setIn(['scroll', 'y'], 0);
            }
            else {
                if (subeditor.getIn(['scroll', 'y']) < 0) {
                    subeditor = subeditor.setIn(['scroll', 'y'], 0);
                }
                else if (subeditor.getIn(['scroll', 'y']) > maxYScroll) {
                    subeditor = subeditor.setIn(['scroll', 'y'], maxYScroll);
                }
            }
            return newState.setIn(['subeditors', subeditorIndex], subeditor);
        }
    }
}

const addToModelActions = (currentState: Map<string, any>, action: any) => {
    let currentActions = currentState.get('modelActions');
    if (!currentActions) {
        currentActions = List();
    }
    currentActions = currentActions.push(action);
    return currentState.set('modelActions', currentActions);
}

const addBand = (state: Map<string, any>, band: Map<string, any>, index: number) => {
    const model = state.get('model');
    let newState = state;
    if (model) {
        newState = pushToUndoStack(state, 'Add Band', state.get('selection'));
        //set the editor dirty
        newState = newState.setIn(['isDirty'], true);
        newState = newState.setIn(['model', 'bands', band.get('id')], band);
        const bandType = band.get('bandType');
        if (bandType === BandTypes.BAND_DETAIL) {
            let bands = newState.getIn(['model', 'detailsOrder'], List<string>()) as List<string>;
            if (index) {
                bands = bands.splice(index, 0, band.get('id'));
            } else {
                bands = bands.push(band.get('id'));
            }
            newState = newState.setIn(['model', 'detailsOrder'], bands);
        } else if (bandType === BandTypes.BAND_GROUP_HEADER) {
            let groupHeaders: List<string> = newState.getIn(['model', 'groupHeadersOrder'], List<string>()) as List<string>;
            if (index) {
                groupHeaders = groupHeaders.splice(index, 0, band.get('id'));
            } else {
                const bands: Map<string, Map<string, any>> = newState.getIn(['model', 'bands']) as Map<string, Map<string, any>>;
                const groupName = band.get('groupName');

                const groups: OrderedMap<string, Map<string, any>> = newState.getIn(['model', 'groups']) as OrderedMap<string, Map<string, any>>;
                let insertPosition = 0;
                let groupFound = false;
                groups.forEach(group => {
                    if (!groupFound) {
                        const name = group.get('name');
                        if (name === groupName) {
                            groupFound = true;
                        }

                        const lastExistingBandIndex = groupHeaders.findLastIndex((currentHeaderBandId: string) => {
                            const currentBand = bands.get(currentHeaderBandId);
                            return currentBand && currentBand.get('groupName') === name;
                        });
                        if (lastExistingBandIndex !== -1) {
                            insertPosition = lastExistingBandIndex;
                        }
                    }
                });
                if (insertPosition + 1 >= groupHeaders.size) {
                    groupHeaders = groupHeaders.push(band.get('id'));
                } else {
                    groupHeaders = groupHeaders.splice(insertPosition + 1, 0, band.get('id'));
                }
            }
            newState = newState.setIn(['model', 'groupHeadersOrder'], groupHeaders);
        } else if (bandType === BandTypes.BAND_GROUP_FOOTER) {

            if (index) {
                let groupFooters: List<string> = (newState.getIn(['model', 'groupFootersOrder'], List<string>()) as List<string>);
                groupFooters = groupFooters.splice(index, 0, band.get('id'));
                newState = newState.setIn(['model', 'groupFootersOrder'], groupFooters);
            } else {
                let groupFooters: List<string> = (newState.getIn(['model', 'groupFootersOrder'], List<string>()) as List<string>).reverse();
                const bands: Map<string, Map<string, any>> = newState.getIn(['model', 'bands']) as Map<string, Map<string, any>>;
                const groupName = band.get('groupName');

                const groups: OrderedMap<string, Map<string, any>> = newState.getIn(['model', 'groups']) as OrderedMap<string, Map<string, any>>;
                let insertPosition = 0;
                let groupFound = false;
                groups.forEach(group => {
                    if (!groupFound) {
                        const name = group.get('name');
                        if (name === groupName) {
                            groupFound = true;
                        }

                        const lastExistingBandIndex = groupFooters.findLastIndex((currentHeaderBandId: string) => {
                            const currentBand = bands.get(currentHeaderBandId);
                            return currentBand && currentBand.get('groupName') === name;
                        });
                        if (lastExistingBandIndex !== -1) {
                            insertPosition = lastExistingBandIndex;
                        }
                    }
                });
                if (insertPosition + 1 >= groupFooters.size) {
                    groupFooters = groupFooters.push(band.get('id'));
                } else {
                    groupFooters = groupFooters.splice(insertPosition + 1, 0, band.get('id'));
                }
                newState = newState.setIn(['model', 'groupFootersOrder'], groupFooters.reverse())
            }
        }
    }
    return newState;
}

/*const comparePath = (a, b) => {
    return (!a && !b) || (a && b && a.length === b.length && a.every((v, i) => v === b[i]));
}*/

export const getEditorIndex = (state: Map<string, any>) => {
    if (state) {
        const index = state.get('currentEditorIndex');
        if (index !== undefined && index >= 0) {
            return index;
        }
    }
    return undefined;
}

/**
 * The designer reduce. We expect this to grow a lot, so we may consider to
 * split it at some point.
 * Moreover, see note on the start of the method, we use cloneDeep from Lodash.
 * This is not good for performances, moreover prevent us from using reference
 * objects and in the future map sorted collections.
 * 
 * @param {*} state 
 * @param {*} action 
 */
const reportReducer = (state = defaultReportState, action: any) => {

    // bare var definition, to be used only if the state actually changes...
    // so we don't have to declare it in other places.
    // It will old a new state if actually created.
    let newState: Map<string, any> | null = null;
    const editorIndex = getEditorIndex(state);
    switch (action.type) {
        case Actions.Actions.RESET_EDITOR:
            newState = defaultReportState;
            if (action.keepCache) {
                //restore the old cache
                newState = newState.set('cachedData', state.get('cachedData'));
                newState = newState.set('outlineExpandedNodes', state.get('outlineExpandedNodes'));
                newState = newState.set('selection', state.get('selection'));
                newState = newState.set('widgetStatus', state.get('widgetStatus'));
                newState = newState.set('zoom', state.get('zoom'));
            }
            if (action.keepHistory) {
                newState = newState.set('undoRedo', state.get('undoRedo'));
                const newModel = action.model;
                if (newModel){
                    let currentEditorIndex = state.get('currentEditorIndex');
                    const oldOpenedEditor = state.get('subeditors', List());
                    let newOpenedEditor = List();
                    oldOpenedEditor.forEach((subeditor, index) => {
                        const editedElementId = subeditor.get('editedResourceId');
                        const path = editedElementId.split('/');
                        const editedElement = newModel.getIn(path);
                        const subeditorType = subeditor.get('type');
                        if (subeditorType === 'datasetQueryEditor' || (editedElement && editedElement.get('type') === subeditorType)){
                            newOpenedEditor = newOpenedEditor.push(subeditor);
                            if (currentEditorIndex === index){
                                currentEditorIndex = newOpenedEditor.size - 1;
                            }
                        } else {
                            //the editor has been removed for invalid resource and it was selected, return to the main editor
                            if (currentEditorIndex === index){
                                currentEditorIndex = undefined;
                            }
                        }
                    });
                    newState = newState.set('currentEditorIndex', currentEditorIndex);
                    newState = newState.set('subeditors', newOpenedEditor);
                }
            }
            return newState;
        case Actions.Actions.DESIGNER_SET_DIRTY:
            newState = state.set('isDirty', action.isDirty);
            return newState;
        case Actions.Actions.OPEN_DOCUMENT:
            const newReportModel: Map<string, any> = action.model;
            newState = state.set('model', newReportModel);
            newState = newState.set('isDirty', false);
            let modelActions = List();
            modelActions = modelActions.push({ type: Actions.Actions.OPEN_DOCUMENT });
            newState = newState.set('modelActions', modelActions);
            if (action.keepCache) {
                //remove from the old selection non existing elements
                newState = validateSelection(newState);
            } else {
                newState = clearSelection(newState);
            }
            return newState;
        case Actions.Actions.SET_DESCRIPTOR:
            newState = state.set('fileDescriptor', action.descriptor);
            newState = newState.set('repositoryPath', action.path);
            return newState;
        case Actions.Actions.INSTANTIATE_EDITOR:
            newState = state;
            //check if an editor for the same resource already exist
            const subeditors: List<Map<string, any>> = state.get('subeditors', List());
            const existingEditorIndex = subeditors.findIndex((currentElement) => {
                const currentResourceId = currentElement.get('editedResourceId');
                return currentResourceId === action.editedResourceId;
            });
            if (existingEditorIndex === -1) {
                //not existing, create the new editor
                const editorData = {
                    label: action.editorLabel,
                    type: action.editorType,
                    isSelectable: action.isSelectable,
                    editedResourceId: action.editedResourceId,
                    params: action.params ? fromJS(action.params) : Map(),
                    zoom: 1.0,
                    clientArea: Map({
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0
                    }),
                    interactionElements: OrderedMap(),
                    mouse: Map({
                        operation: null,
                        dragStarted: false,
                        startLocation: null,
                        currentLocation: null,
                        operationState: null,
                    }),
                    scroll: Map({
                        x: 0,
                        y: 0
                    })
                }
                const editorMap = Map<string, any>(initializeMapWithObject(editorData));
                newState = newState.update('subeditors', (subeditorsList) => subeditorsList.push(editorMap));
                if (action.setActive) {
                    const newEditorIndex = newState.get('subeditors').size - 1;
                    newState = newState.set('currentEditorIndex', newEditorIndex);
                }
            } else {
                //already existing, activate the existing one
                newState = newState.set('currentEditorIndex', existingEditorIndex);
            }
            return newState;
        case Actions.Actions.SET_EDITOR_PROPERTIES:
            newState = state;
            let subeditor = newState.getIn(['subeditors', action.index]) as Map<string, any>;
            if (subeditor) {
                subeditor = subeditor.set('label', action.label);
                subeditor = subeditor.set('isSelectable', action.isSelectable);
                subeditor = subeditor.set('params', action.params ? fromJS(action.params) : Map());
                subeditor = subeditor.set('editedResourceId', action.editedResourceId);
                newState = newState.setIn(['subeditors', action.index], subeditor);
            }
            return newState;
        case Actions.Actions.CLOSE_EDITOR:
            newState = state;
            newState = newState.deleteIn(['subeditors', action.editorIndex]);
            if (newState.get('currentEditorIndex') === action.editorIndex) {
                newState = newState.delete('currentEditorIndex');
            }
            return newState;
        case Actions.Actions.SET_CURRENT_EDITOR:
            newState = state;
            newState = newState.set('currentEditorIndex', action.editorIndex);
            newState = newState.set('selection', Map<string, any>());
            return newState;
        case Actions.Actions.DESIGNER_SET_ZOOM:
            if (editorIndex !== undefined) {
                //subeditor
                newState = state.setIn(['subeditors', editorIndex, 'zoom'], Math.min(Math.max(action.zoom, 0.1), 5));
            } else {
                //main editor
                newState = state.set('zoom', Math.min(Math.max(action.zoom, 0.1), 5));
            }
            newState = updateScroll(newState, editorIndex);
            return newState;

        case Actions.Actions.DESIGNER_INCREASE_ZOOM:
            if (editorIndex !== undefined) {
                //subeditor
                newState = state.updateIn(['subeditors', editorIndex, 'zoom'], (oldZoom: number) => Math.min(Math.max(oldZoom + 0.2, 0.1), 5));
            } else {
                //main editor
                newState = state.update('zoom', (oldZoom) => Math.min(Math.max(oldZoom + 0.2, 0.1), 5));
            }
            newState = updateScroll(newState, editorIndex);
            return newState;

        case Actions.Actions.DESIGNER_DECREASE_ZOOM:
            if (editorIndex !== undefined) {
                //subeditor
                newState = state.updateIn(['subeditors', editorIndex, 'zoom'], (oldZoom: number) => Math.min(Math.max(oldZoom - 0.2, 0.1), 5));
            } else {
                //main editor
                newState = state.update('zoom', (oldZoom) => Math.min(Math.max(oldZoom - 0.20, 0.1), 5));
            }
            newState = updateScroll(newState, editorIndex);
            return newState;

        case Actions.Actions.DESIGNER_SCROLL_X:
            if (editorIndex !== undefined) {
                //subeditor
                newState = state.updateIn(['subeditors', editorIndex, 'scroll', 'x'], (oldValue) => oldValue + action.x);
            } else {
                //main editor
                newState = state.updateIn(['scroll', 'x'], (oldValue) => oldValue + action.x);
            }
            newState = updateScroll(newState, editorIndex);
            return newState;

        case Actions.Actions.DESIGNER_SCROLL_Y:
            if (editorIndex !== undefined) {
                //subeditor
                newState = state.updateIn(['subeditors', editorIndex, 'scroll', 'y'], (oldValue) => oldValue + action.y);
            } else {
                //main editor
                newState = state.updateIn(['scroll', 'y'], (oldValue) => oldValue + action.y);
            }
            newState = updateScroll(newState, editorIndex);
            return newState;

        case Actions.Actions.DESIGNER_VIEWPORT_CHANGED:
            // The payload (viewport) is an immutable Map
            newState = state.set('viewport', action.viewport);
            // Let's recalculate the client area first...
            // newState = updateVisibleArea(newState);
            newState = updateScroll(newState);
            return newState;

        case Actions.Actions.DESIGNER_CLIENTAREA_CHANGED:
            if (editorIndex !== undefined) {
                //subeditor
                // The payload (clientArea) is an immutable Map
                newState = state.setIn(['subeditors', editorIndex, 'clientArea'], action.clientArea);
            } else {
                // The payload (clientArea) is an immutable Map
                newState = state.set('clientArea', action.clientArea);
            }
            newState = updateScroll(newState, editorIndex);
            return newState;

        case Actions.Actions.DESIGNER_SET_DRAG_OP:
            {
                // The payload object options is an immutable Map or null
                const path = editorIndex !== undefined ? ['subeditors', editorIndex] : [];
                return state
                    .setIn([...path, 'mouse', 'operation'], action.operation)
                    .setIn([...path, 'mouse', 'dragStarted'], false)
                    .setIn([...path, 'mouse', 'startLocation'], action.location)
                    .setIn([...path, 'mouse', 'currentLocation'], action.location)
                    .setIn([...path, 'mouse', 'operationState'], action.options);
            }
        case Actions.Actions.DESIGNER_CLEAR_DRAG_OP:
            {
                // Reset the entire map...
                const path = editorIndex !== undefined ? ['subeditors', editorIndex, 'mouse'] : ['mouse'];
                return state.setIn(path, Map({
                    operation: null,
                    dragStarted: false,
                    startLocation: null,
                    currentLocation: null,
                    operationState: null
                }));
            }

        case Actions.Actions.DESIGNER_UPDATE_DRAG_OP:
            {
                const path = editorIndex !== undefined ? ['subeditors', editorIndex, 'mouse'] : ['mouse'];
                return state
                    .setIn([...path, 'dragStarted'], action.dragStarted)
                    .setIn([...path, 'currentLocation'], action.newLocation)
                    .setIn([...path, 'operationState'], action.options);
            }
        case Actions.Actions.DESIGNER_ADD_INTERACTION_FIGURE:
        // In the future add and update could have different behavior.
        // fallsthrough
        case Actions.Actions.DESIGNER_UPDATE_INTERACTION_FIGURE:
            {
                const path = editorIndex !== undefined ? ['subeditors', editorIndex, 'interactionElements'] : ['interactionElements'];
                // Figure is a Map, not a plain object!
                return state.setIn([...path, action.id], action.figure);
            }
        case Actions.Actions.DESIGNER_REMOVE_INTERACTION_FIGURE:
            {
                const path = editorIndex !== undefined ? ['subeditors', editorIndex, 'interactionElements'] : ['interactionElements'];

                return state.removeIn([...path, action.id]);
            }
        case Actions.Actions.DESIGNER_ADD_INTERACTION_FIGURES:
        // In the future add and update could have different behavior.
        // fallsthrough
        case Actions.Actions.DESIGNER_UPDATE_INTERACTION_FIGURES:
            {
                const path = editorIndex !== undefined ? ['subeditors', editorIndex, 'interactionElements'] : ['interactionElements'];
                // Figure is a Map, not a plain object!
                if (action.figures && action.figures.count() === 0) {
                    return state;
                }
                for (let i = 0; i < action.figures.count(); ++i) {
                    const figure = action.figures.get(i);
                    newState = ((newState) ? newState : state).setIn([...path, figure.getIn(['props', 'key'])], figure);
                }
                return newState;
            }
        case Actions.Actions.DESIGNER_REMOVE_INTERACTION_FIGURES:
            {
                const path = editorIndex !== undefined ? ['subeditors', editorIndex, 'interactionElements'] : ['interactionElements'];
                // Attention! figuresIds is a plain javascript array, so we use length, not count().
                if (action.figureIds && action.figureIds.length === 0) {
                    return state;
                }
                for (const figureId of action.figureIds) {
                    newState = ((newState) ? newState : state).removeIn([...path, figureId]);
                }
                return newState;
            }
        case Actions.Actions.DESIGNER_HIGHLIGHT_CONTAINER:
            if (state.get('highlightedContainer') !== action.path) {
                return state.set('highlightedContainer', action.path);
            }
            break;
        case Actions.Actions.UNDO:
            return performUndo(addToModelActions(state, action));

        case Actions.Actions.REDO:
            return performRedo(addToModelActions(state, action));

        case Actions.Actions.CREATE_NEW_DOCUMENT:
            // Model is an Immutable, validation of the model
            // should have been done somewhere else.
            newState = clearUndoStack(state);
            newState = clearSelection(newState);
            newState = newState.set('modelActions', List());
            return newState.set('model', action.model);
        case Actions.Actions.CREATE_ELEMENTS:
            const model = state.get('model');
            if (model) {
                newState = pushToUndoStack(state, 'Add element', state.get('selection'));
                const elements: { path: string, element: Map<string, any> }[] = action.elements;
                newState = newState.setIn(['isDirty'], true);
                newState = addToModelActions(newState, action);
                elements.forEach((elementToCreate) => {
                    let newElement = elementToCreate.element.set('path', elementToCreate.path);
                    const currentId = newState.getIn(['model', 'lastUniqueId'], 0) as number;
                    const currentElementId = newElement.get('id', 'element-');
                    const currentElementNewId = currentElementId + currentId;
                    newElement = newElement.set('id', currentElementNewId);
                    const pathParts = elementToCreate.path.split('/');
                    const containerElementsPath = ['model', ...pathParts, 'elementIds'];
                    newState = newState.setIn(['model', 'elements', newElement.get('id')], newElement).setIn(['model', 'lastUniqueId'], currentId + 1)
                        .updateIn(containerElementsPath, (val: List<string>) => (val || List<string>()).push(newElement.get('id')));
                });
                return newState;
            }
        case Actions.Actions.CREATE_ELEMENT:
            {
                const model = state.get('model');
                if (model) {
                    newState = pushToUndoStack(state, 'Add element', state.get('selection'));
                    // UNDOREDO Here is where to generate an undo operation....

                    // we add the new element to the global list of elements, and we add it's id
                    // to the container specified by the action path...
                    const pathParts = action.path.split('/');
                    const containerElementsPath = ['model', ...pathParts, 'elementIds'];
                    let newElement = action.element.set('path', action.path);

                    //set the editor dirty
                    newState = newState.setIn(['isDirty'], true);
                    newState = addToModelActions(newState, action);
                    const currentId = newState.getIn(['model', 'lastUniqueId'], 0) as number;
                    const currentElementId = newElement.get('id', 'element-');
                    const currentElementNewId = currentElementId + currentId;
                    newElement = newElement.set('id', currentElementNewId);
                    newState = newState.setIn(['model', 'elements', newElement.get('id')], newElement).setIn(['model', 'lastUniqueId'], currentId + 1)
                        .updateIn(containerElementsPath, (val: List<string>) => (val || List<string>()).push(newElement.get('id')));

                    //check if the elemenet should be selected
                    if (action.select) {
                        newState = clearSelection(newState);
                        let currentSelection: Map<string, any> = newState.get('selection');
                        const type = newElement.get('type');
                        const selectionInfo = { path: ['elements', currentElementNewId], type }
                        currentSelection = currentSelection.set(currentElementNewId, selectionInfo);
                        newState = newState.set('selection', currentSelection);
                    }

                    return newState;
                }
            }
            break;
        case Actions.Actions.CREATE_PROPERTY:
            {
                const model = state.get('model');
                if (model) {
                    newState = pushToUndoStack(state, 'Add element', state.get('selection'));
                    const path = action.path;

                    //set the editor dirty
                    newState = newState.setIn(['isDirty'], true);
                    newState = addToModelActions(newState, action);

                    let items = state.getIn(path) as List<Map<string, any>> | undefined;
                    if (!items) {
                        items = List<Map<string, any>>();
                    }

                    let newElement = action.element;
                    if (newElement !== Object(newElement) || _.isString(newElement)) {
                        return newState.setIn(path, items.push(newElement));
                    } else if (!isImmutable(newElement)) {
                        //be sure the element is an immutable
                        newElement = fromJS(newElement);
                    }

                    const currentId = newState.getIn(['model', 'lastUniqueId'], 0) as number;
                    const currentElementId = newElement.get('id');
                    newElement = newElement.set('id', currentElementId + currentId.toString());

                    return newState.setIn(path, items.push(newElement)).setIn(['model', 'lastUniqueId'], currentId + 1);
                }
            }
            break;
        case Actions.Actions.CREATE_PROPERTIES:
            {
                const model = state.get('model');
                if (model) {
                    newState = pushToUndoStack(state, 'Add elements', state.get('selection'));
                    const path = action.path;

                    //set the editor dirty
                    newState = newState.setIn(['isDirty'], true);
                    newState = addToModelActions(newState, action);
                    const replaceExisting = action.replaceExisting;
                    let items = replaceExisting ? List<Map<string, any>>() : state.getIn(path) as List<Map<string, any>> | undefined;
                    if (!items) {
                        items = List<Map<string, any>>();
                    }

                    const newElements = action.elements;
                    let currentId = newState.getIn(['model', 'lastUniqueId'], 0) as number;

                    newElements.forEach(currentElement => {
                        let newElement = currentElement;
                        if (!isImmutable(newElement)) {
                            //be sure the element is an immutable
                            newElement = fromJS(newElement);
                        }
                        const currentElementId = newElement.get('id', '');
                        newElement = newElement.set('id', currentElementId + currentId);
                        items = items.push(newElement);
                        currentId++;
                    });
                    return newState.setIn(path, items).setIn(['model', 'lastUniqueId'], currentId + 1);
                }
            }
            break;
        case Actions.Actions.CREATE_BAND:
            {
                let newState = addBand(state, action.band, action.index);
                newState = addToModelActions(newState, action);
                return newState;
            }
        case Actions.Actions.SELECT_ELEMENT:
            newState = state;
            {
                if (!action.add) {
                    newState = clearSelection(newState);
                }
                let currentSelection: Map<string, any> = newState.get('selection');
                currentSelection = currentSelection.set(action.id, action.info);
                newState = addToModelActions(newState, action);
                newState = newState.set('selection', currentSelection);
            }
            return newState;
        case Actions.Actions.SELECT_DATASET:
            newState = state;
            {
                newState = newState.set('datasetSelection', action.id);
            }
            return newState;
        case Actions.Actions.DESELECT_ELEMENT:
            newState = state;
            {
                let currentSelection: Map<string, any> = newState.get('selection');
                currentSelection = currentSelection.remove(action.id);
                newState = addToModelActions(newState, action);
                newState = newState.set('selection', currentSelection);
            }
            return newState;

        case Actions.Actions.SELECT_ELEMENTS:
            newState = state;
            {
                if (!action.add) {
                    newState = clearSelection(newState);
                }
                let currentSelection: Map<string, any> = newState.get('selection');
                action.ids.forEach(currentSelectedElement => {
                    currentSelection = currentSelection.set(currentSelectedElement.id, currentSelectedElement.info);
                });
                newState = addToModelActions(newState, action);
                newState = newState.set('selection', currentSelection);
            }
            return newState;

        case Actions.Actions.CLEAR_SELECTION:
            newState = clearSelection(state);
            newState = addToModelActions(newState, action);
            return newState

        case Actions.Actions.UPDATE_ELEMENTS_CONTAINER:
            {
                const newPathParts = action.path.split('/');
                const newContainerElementsPath = ['model', ...newPathParts, 'elementIds'];

                newState = state;
                // For each changed element, we first remove the element from the original container,
                // then we add it to the new one anche change the element itself...
                for (const eleId of action.elementIds) {
                    const oldContainer = state.getIn(['model', 'elements', eleId, 'path']) as string;
                    if (oldContainer !== action.path) {
                        const oldPathParts = oldContainer.split('/');
                        const oldContainerElementsPath = ['model', ...oldPathParts, 'elementIds'];
                        newState = newState.setIn(['model', 'elements', eleId, 'path'], action.path) // Set the new path
                            // add the element to the new container
                            .updateIn(newContainerElementsPath, (val: List<string>) => val.push(eleId))
                            // remove the element from the old container
                            .setIn(oldContainerElementsPath,
                                (newState.getIn(oldContainerElementsPath) as List<string>).filter((val: string) => val !== eleId));

                    }
                }

                //set the editor dirty
                newState = newState.setIn(['isDirty'], true);
                newState = addToModelActions(newState, action);
                return newState;
            }
        case Actions.Actions.UPDATE_ELEMENT_SIZE_AND_POSITION:
            {
                // We have an array of changes to apply...
                newState = pushToUndoStack(state, 'Element(s) moved/resized', state.get('selection'));
                const props = ['x', 'y', 'width', 'height'];
                for (const change of action.changes) {
                    for (const prop of props) {
                        if (change[prop] !== undefined) {
                            newState = newState.setIn(['model', 'elements', change.id, prop], change[prop]);
                            if (prop === 'width' || prop === 'height') {
                                const changedElement = newState.getIn(['model', 'elements', change.id]) as Map<string, any>;
                                const changedElementType = changedElement.get('type');
                                if (changedElementType === ElementTypes.CROSSTAB) {
                                    //special case, reisze also the no data cell
                                    const noDataCellId = getCrosstabNoDataCell(changedElement, newState.get('model'));
                                    if (noDataCellId) {
                                        newState = newState.setIn(['model', 'elements', noDataCellId, prop], change[prop]);
                                    }
                                }
                            }
                        }
                    }
                }

                //set the editor dirty
                newState = newState.setIn(['isDirty'], true);
                newState = addToModelActions(newState, action);
                return newState;
            }
        case Actions.Actions.SET_HIGHLIGHTED_ELEMENTS:

            // We change the state here only if really required....
            // First we set to true the highlighted attribute of our selected elements (only if not already true)
            for (const eleId of action.elementIds) {
                if (!state.getIn(['model', 'elements', eleId, 'highlighted'])) {
                    newState = ((newState) ? newState : state).setIn(['model', 'elements', eleId, 'highlighted'], true);
                }
            }

            (state.getIn(['model', 'elements']) as Map<string, any>).keySeq().forEach((key: string) => {
                if (action.elementIds.indexOf(key) < 0) {
                    if (((newState) ? newState : state).getIn(['model', 'elements', key, 'highlighted']) === true) {
                        newState = ((newState) ? newState : state).setIn(['model', 'elements', key, 'highlighted'], false);
                    }
                }
            });

            return (newState) ? newState : state;
        case Actions.Actions.SET_OBJECT_PROPERTIES:
            if (action.properties.type !== undefined) {
                //if the type change store also the selection to restore it later
                newState = pushToUndoStack(state, 'Object ' + action.path + ' changed', state.get('selection'));
            } else {
                newState = pushToUndoStack(state, 'Object ' + action.path + ' changed', state.get('selection'));
            }
            //set the editor dirty
            newState = newState.setIn(['isDirty'], true);
            newState = addToModelActions(newState, action);
            newState = refactorElement(state, action, newState);

            if (Object(action.properties) !== action.properties)
                return newState.setIn(action.path, action.properties);
            newState = newState.mergeDeepIn(action.path, action.properties);
            if (action.properties.type !== undefined) {
                //the type of the element changed, update the selection
                const id = action.path[action.path.length - 1];
                if (newState.hasIn(['selection', id])) {
                    newState = newState.setIn(['selection', id, 'type'], action.properties.type);
                }
            }
            return newState;
        case Actions.Actions.SET_PARAMETER_VALUE:
            newState = state.setIn(['parametersValue', action.parameterName], action.parameterValue);
            return newState;
        case Actions.Actions.SET_PARAMETERS_VALUES:
            newState = state.set('parametersValue', action.parameters);
            return newState;
        case Actions.Actions.SET_BAND_SIZE:
            newState = pushToUndoStack(state, 'Object ' + action.path + ' changed', state.get('selection'));

            //set the editor dirty
            newState = newState.setIn(['isDirty'], true);
            newState = addToModelActions(newState, action);
            if (Object(action.properties) !== action.properties)
                return newState.setIn(action.path, action.properties);
            return newState.mergeDeepIn(action.path, action.properties);
        case Actions.Actions.DELETE_ELEMENT:

            newState = pushToUndoStack(state, 'Delete ' + action.path, state.get('selection'));
            //set the editor dirty
            newState = newState.setIn(['isDirty'], true);
            newState = addToModelActions(newState, action);
            newState = refactorOnDelete(state, action, newState);
            return newState.deleteIn(action.path);
        case Actions.Actions.DELETE_VISUAL_ELEMENT:

            newState = pushToUndoStack(state, 'Delete Element ' + action.path, state.get('selection'));

            //set the editor dirty
            newState = newState.setIn(['isDirty'], true);
            newState = addToModelActions(newState, action);
            const paths: string[][] = action.paths;
            paths.forEach((path) => {
                newState = removeElement(newState, path);
            })
            return newState;
        case Actions.Actions.STORE_IN_CACHE:
            newState = state;
            const cacheType = action.cacheType;
            let cacheData = action.data;
            if (!isImmutable(cacheData)) {
                cacheData = fromJS(cacheData);
            }
            newState = newState.setIn(['cachedData', cacheType], cacheData);
            return newState;
        case Actions.Actions.EXPAND_OUTLINE_ELEMENT:
            newState = state;
            newState = newState.set('outlineExpandedNodes', action.expandedElements);
            return newState;
        case Actions.Actions.FOCUS_TEXT_CONTROL:
            newState = state;
            newState = newState.set('isTextControlFocused', action.isFocused);
            return newState;
        case Actions.Actions.SET_WIDGET_STATUS:
            newState = state;
            const path = Array.isArray(action.path) ? action.path : [action.path];
            newState = newState.setIn(['widgetStatus', ...path], action.value);
            return newState;
        default:
    }
    return state;
}

export default reportReducer;
