/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { PopoverControl, Typography } from '@jss/js-common';
import { reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { UPDiv } from '../../ui/composite/UPDiv';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { PopoverOrigin } from '@material-ui/core';
import i18n from '../../../../../i18n';
import ReactResizeDetector from 'react-resize-detector';

const TYPEID = 'tableElement';
reg(TYPEID, (mc) => { return <UPTableElement mcontext={mc} />; });
export class PTableElement extends APDescriptor {
    hasPopover?: boolean = true;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    public constructor(init: Partial<PTableElement>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
interface UPTableElementState {
    opened: boolean;
    width: number | undefined,
    height: number | undefined,
}
export class UPTableElement extends React.Component<UiPropertyProps, UPTableElementState> {

    public state: UPTableElementState = {
        opened: false,
        width: undefined,
        height: undefined,
    };

    private divRef: React.RefObject<HTMLDivElement> = React.createRef();

    private onResize = (width: number | undefined, height: number | undefined) => {
        if (this.state.width === undefined || this.state.height === undefined) {
            this.setState({ width: width, height: height });
        }
    }

    private getPathAndValue = (): { path: any[], value: any } => {
        const p = this.props.mcontext.elements[0].path;
        const v = this.props.mcontext.descriptor.id;
        return { path: p, value: v };
    }

    render() {
        const pv = this.getPathAndValue();
        const d = this.props.mcontext.descriptor as PTableElement;

        const fullLbl: string = pv.value !== undefined ? pv.value : d.label;
        let shortLbl = fullLbl;
        if (shortLbl.length > 35) {
            shortLbl = shortLbl.substring(0, 16) + '...' + shortLbl.substring(shortLbl.length - 16, shortLbl.length);
        }
        return <>
            <div ref={this.divRef} />
            {this.renderIcon(d)}
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', flex: 1, display: 'inherit', alignItems: 'center' }} title={fullLbl}>{shortLbl}</span>
            {d.hasPopover === true ? this.renderPopover() : <></>}
        </>;
    }

    private renderPopover = () => {
        const descriptor = this.props.mcontext.descriptor as PTableElement;
        return <PopoverControl anchorRef={this.divRef} id={this.props.mcontext.descriptor.id + '.popover'} getContent={this.renderContent} getControl={this.renderTriggerButton} isOpen={this.state.opened}
            onClose={this.handleClose} transformOrigin={descriptor.transformOrigin} anchorOrigin={descriptor.anchorOrigin} />;
    }
    private renderTriggerButton = (handleClick: (event: any) => void) => {
        return <IconButton aria-label="more" onClick={event => { handleClick(event); this.handleClick() }} style={{ padding: '0' }}><MoreHorizIcon /></IconButton>;
    }
    private renderContent = (handleClose: () => void) => {
        return <div style={{ padding: '10px', width: '400px' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottom: 'solid 1px lightgray', marginBottom: '10px' }}>
                <Typography variant="h1">{this.props.mcontext.descriptor.label ? i18n.t(this.props.mcontext.descriptor.label) : ''}</Typography>
                <IconButton aria-label="close" onClick={() => { handleClose(); this.handleClose(); }} size='small'>
                    <CloseIcon />
                </IconButton>
            </div>
            <ReactResizeDetector handleWidth={true} handleHeight={true} onResize={this.onResize}>
                <div style={{ display: 'flex', flex: 1, overflow: 'auto', maxHeight: this.state.height }}>
                    <UPDiv mcontext={this.props.mcontext} />
                </div>
            </ReactResizeDetector>
        </div>;
    }
    public renderIcon = (d: PTableElement) => {
        return d.icon ? <img src={d.icon}/> : <></>;
    }
    public handleClose = (): void => {
        this.setState({ opened: false, width: undefined, height: undefined });
    }
    public handleClick = (): void => {
        const open = !this.state.opened;
        this.setState({ opened: open });
    }

}