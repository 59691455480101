/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { Conf, Pref } from '../config/Conf';
import { UserCredential } from '../user/UserCredential';
import { Lang } from '../config/Lang';

export class UserJRS {

    public getUser(serverAddress: string, organization: string, username: string): AxiosPromise<any> {
        let url = `${serverAddress}${serverAddress.endsWith('/') ? '' : '/'}rest_v2/`;
        this.getUserConfig(serverAddress);
        if (organization) {
            url += `organizations/${organization}/`;
        }
        url += `users/${username}`;

        return axios.get(url, { headers: { 'X-REMOTE-DOMAIN': '1', 'X-Suppress-Basic': 'true' }, withCredentials: true }).then((response: AxiosResponse) => {
            const uc: UserCredential = {
                type: 'jrs',
                username: username,
                firstname: response.data.fullName,
            };
            response.data = uc;
            return response;
        });
    }

    public getUserConfig(serverAddress: string): AxiosPromise<any> {
        const url = `${serverAddress}${serverAddress.endsWith('/') ? '' : '/'}rest_v2/settings/visualizeSettings`;
        return axios.get(url, { headers: { 'X-REMOTE-DOMAIN': '1', 'X-Suppress-Basic': 'true' }, withCredentials: true }).then((response: AxiosResponse) => {
            const config = response.data;

            Conf.set(Pref.LOCALES_AVAILABLE, Lang.norm(config.availableLocales));
            Conf.set(Pref.LOCALE, Lang.getLocale(config.userLocale, Conf.get(Pref.LOCALES_AVAILABLE)));

            Conf.set('jrws.user.timezone', config.userTimezone);
            Conf.set('jrws.user.theme.prefix', config.themePrefix);

            return response;
        });
    }
}