/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */



import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MBoxGraphicElement } from "../common/MGraphicElement";
import { BorderSplitTypeEnum } from "../enums/BorderSplitTypeEnum";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { ICONS } from "@jss/js-common";
import { MFrameCustom } from "./MFrameCustom";

export const FFrame = {
    borderSplitType: BorderSplitTypeEnum
};

export const MFrame: APDescriptor = {
    type: ElementTypes.FRAME,
    id: 'frameProeprtiesDescriptor',
    label: 'MFrame.property.frame.label',
    icon: ICONS.FRAME_ICON,
    default: { width: 200, height: 200 },
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'MFrame.property.appearance.label', layouts: [...MBoxGraphicElement.layouts, MFrameCustom] }),
            new PDiv({ label: 'MFrame.property.frame.label', layouts: [FFrame.borderSplitType] })
        ]
    })]
};
