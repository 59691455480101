/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../../ui/APDescriptor";
import { MHyperlinks } from "../../common/MHyperlinks";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { MChartDataset } from "./MChartDataset";

export const FHighLowDataset = {
    closeExpression: new PExpression({ id: 'closeExpression', label: 'Close' }),
    openExpression: new PExpression({ id: 'openExpression', label: 'Open' }),
    dateExpression: new PExpression({ id: 'dateExpression', label: 'Date' }),
    highExpression: new PExpression({ id: 'highExpression', label: 'High' }),
    lowExpression: new PExpression({ id: 'lowExpression', label: 'Low' }),
    seriesExpression: new PExpression({ id: 'seriesExpression', label: 'Series' }),
    volumeExpression: new PExpression({ id: 'volumeExpression', label: 'Volume' })
};

export const MHighLowDataset: APDescriptor = {
    type: 'MHighLowDataset', id: 'highLowDataset', path: 'highLowDataset.dataset',
    layouts: [...MChartDataset.layouts, FHighLowDataset.openExpression, FHighLowDataset.closeExpression, FHighLowDataset.lowExpression, FHighLowDataset.highExpression, FHighLowDataset.dateExpression, FHighLowDataset.seriesExpression, FHighLowDataset.volumeExpression, { ...MHyperlinks, path: 'itemHyperlink' }]
};
