/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg } from '../../ui/UiProperty';
import { APDescriptor } from '..//APDescriptor';
import UPStringTable from './UPStringTable';

const TYPEID = 'connectedStringTable';
export class PConnectedStringTable extends APDescriptor {
    default?: any;
    seachable?: boolean;
    expanded?: boolean = false;
    rowLabel?: string;
    rowLabelPaths?: string[];
    public constructor(init: Partial<PConnectedStringTable>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPStringTable mcontext={mc} />; });