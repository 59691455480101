/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from '../../ui/APDescriptor';
import { PAccordeon } from '../../ui/composite/UPAccordeon';
import { PBoolean } from '../../ui/primitive/UPBoolean';
import { PString } from '../../ui/primitive/UPString';
import { PPropertiesExpression } from '../../ui/primitive/UPValueExpession';

export const MTableColumnCustom: APDescriptor = { type: 'MTableColumnCustom', id: 'MTableColumnCustom', path: 'properties', layouts: [
	new PAccordeon({ id: 'table', label: 'table', custom: true, layouts: [
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.components.table.column.field', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.components.table.column.field', label: `net.sf.jasperreports.components.table.column.field`, description: `Property that specifies the field to be used for sorting and/or filtering`, sinceVersion: '5.0.1'}), scopes: ['TABLE_COLUMN'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.components.table.column.filterable', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.components.table.column.filterable', label: `net.sf.jasperreports.components.table.column.filterable`, description: `Property that enables/disables filtering`, default: 'true', sinceVersion: '5.0.1'}), scopes: ['TABLE_COLUMN'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.components.table.column.sortable', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.components.table.column.sortable', label: `net.sf.jasperreports.components.table.column.sortable`, description: `Property that enables/disables sorting`, default: 'true', sinceVersion: '5.0.1'}), scopes: ['TABLE_COLUMN'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.components.table.column.variable', vtype: new PString({ custom: true, id: 'net.sf.jasperreports.components.table.column.variable', label: `net.sf.jasperreports.components.table.column.variable`, description: `Property that specifies the variable to be used for sorting and/or filtering`, sinceVersion: '5.0.1'}), scopes: ['TABLE_COLUMN'] }),
		new PPropertiesExpression({custom: true,  id: 'net.sf.jasperreports.components.table.interactive', vtype: new PBoolean({ custom: true, id: 'net.sf.jasperreports.components.table.interactive', label: `net.sf.jasperreports.components.table.interactive`, description: `Property that enables/disables the interactivity in the table component`, default: 'true', sinceVersion: '4.7.1'}), scopes: ['GLOBAL', 'CONTEXT', 'REPORT', 'COMPONENT', 'TABLE_COLUMN'] }),
	]}),
]};
