/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { AbstractTextualEditor, IAbstractTextualEditor } from './AbstractTextualEditor';
import InternalPropertiesEditor from './InternalPropertiesEditor';

export class PropertiesEditor extends React.Component<IAbstractTextualEditor> {

    private propertiesEditorRef: React.RefObject<AbstractTextualEditor> = React.createRef<AbstractTextualEditor>();

    getEditorContent = () => {
        return this.propertiesEditorRef.current?.getEditorContent();
    }

    render() {
        return <InternalPropertiesEditor ref={this.propertiesEditorRef} {...this.props}/>;
    }
}