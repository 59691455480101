/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from "react";
import Moment from "react-moment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getExtension, IRepositoryItemDescriptor, Mimes, ResourceSorting, RESOURCE_TYPE } from '@jss/js-rest-api';
import '../assets/uxpl/css/ResourcePicker.css';
import i18n from "../i18n";

export interface IFilesTable {
    files: IRepositoryItemDescriptor[];  // We use an immutable object to store the elements.... 
    onElementClick?: (element: IRepositoryItemDescriptor) => void;
    selectedElement?: IRepositoryItemDescriptor;
    allowedMimes?: string[];
    showOnlyFolder?: boolean;
}


/**
 * Show a list of files/folders in a table.
 *
 * @class FileTable
 * @extends {React.Component}
 */
export default class FilesTable extends React.Component<IFilesTable> {


    private onClick = (item) => {
        if (this.props.onElementClick) {
            this.props.onElementClick(item);
        }
    }

    private isFolderOrContainer = (item: IRepositoryItemDescriptor) => {
        return item.type === RESOURCE_TYPE.CONTAINER || item.type === RESOURCE_TYPE.FOLDER;
    }

    public render() {
        const items: IRepositoryItemDescriptor[] = this.props.files ? this.props.files : [];
        return <div className="jrsw-file-browser-scrollable-container">
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n.t('components.filesTable.filename')}</TableCell>
                            <TableCell align="right">{i18n.t('components.filesTable.type')}</TableCell>
                            <TableCell align="right">{i18n.t('components.filesTable.lastmodified')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.sort((item1, item2) => {
                            /*if (item1.mime === item2.mime){
                                return ResourceSorting.sortByName(item1.name, item2.name, false);
                            }
                            if (RESOURCE_TYPE.FOLDER === item1.type){
                                return -1;
                            }
                            const item1Mime = Mimes.get(item1.mime).shortLabel;
                            const item2Mime = Mimes.get(item2.mime).shortLabel;
                            return ResourceSorting.sortByType(item1Mime, item2Mime, false);*/
                            if (this.isFolderOrContainer(item1) && !this.isFolderOrContainer(item2)) {
                                return -1;
                            } else if (!this.isFolderOrContainer(item1) && this.isFolderOrContainer(item2)) {
                                return 1;
                            } else return ResourceSorting.sortByName(item1.name, item2.name, false);
                        }).map((row) => {
                            const realType = Mimes.get(row.mime).shortLabel;
                            const extension = getExtension(row);
                            const allowedMimes = this.props.allowedMimes;
                            if (this.props.showOnlyFolder) {
                                if (this.isFolderOrContainer(row)) {
                                    return <TableRow onClick={() => { this.onClick(row) }} key={row.name} selected={this.props.selectedElement && row.uuid === this.props.selectedElement.uuid}>
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                        <TableCell align="right">{realType}</TableCell>
                                        <TableCell align="right">{row.lastModified ? <Moment date={row.lastModified} format="MMM DD, YYYY h:mm A" /> : ''}</TableCell>
                                    </TableRow>;
                                }
                            } else if (!allowedMimes || allowedMimes.length === 0 || this.isFolderOrContainer(row) || allowedMimes.includes(row.mime) || allowedMimes.includes(extension)) {
                                return <TableRow onClick={() => { this.onClick(row) }} key={row.name} selected={this.props.selectedElement && row.uuid === this.props.selectedElement.uuid}>
                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                    <TableCell align="right">{realType}</TableCell>
                                    <TableCell align="right">{row.lastModified ? <Moment date={row.lastModified} format="MMM DD, YYYY h:mm A" /> : ''}</TableCell>
                                </TableRow>;
                            }
                            return null;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>;
    }
}