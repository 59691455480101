/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { FFusionWidgetProps, FFusionWidgets, MFusionWidgetCommon } from "./MFusionWidgetsCommon";
import SparkChartIcon from "../../../../../../assets/icons/charts/fusion/spark_winloss.svg";
import { PDiv } from "../../../ui/composite/UPDiv";
import { PConnectedTableDetail } from "../../../ui/tables/UPConnectedTableDetail";
import { MHyperlinks } from "../../common/MHyperlinks";
import { MDatasetRun } from "../../dataset/MDatasetRun";
import { PCategories } from "../../../ui/composite/UPCategories";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";

export const FFusionItems = {
    resultExpression: new PExpression({ id: 'resultExpression', label: 'Result' }),
    scorelessExpression: new PExpression({ id: 'scorelessExpression', label: 'Scoreless' })
};

export const MFusionSparkWinLoss: APDescriptor = {
    id: 'SparkWinLoss', type: 'fusion.chart.SparkWinLoss', label: 'Spark Win Loss', icon: SparkChartIcon, path: 'widgetProperties',
    layouts: [...MFusionWidgetCommon.layouts,
    new PAccordeon({
        id: 'sc.SparkWL', label: "Spark Win Loss", layouts: [
            new PAccordeon({
                id: 'butllet.period', label: "Period",
                layouts: [FFusionWidgets.periodColor, FFusionWidgets.periodAlpha, FFusionWidgets.periodLength]
            }),
            new PAccordeon({
                id: 'butllet.plot', label: "Plot",
                layouts: [FFusionWidgets.winColor, FFusionWidgets.lossColor, FFusionWidgets.drawColor, FFusionWidgets.scorelessColor]
            }),
            new PAccordeon({
                id: 'gauge.limits', label: "Limits",
                layouts: [FFusionWidgetProps.setAdaptiveYMin]
            })
        ]
    })],
    default: {
        type: 'FUSION_WIDGET', widgetType: 'SparkWinLoss',
        valuesDataset: { dataset: {}, items: [{ resultExpression: "", scorelessExpression: "" }] }
    }
};

export const MFusionSparkWinLossDataset: APDescriptor = {
    id: 'SparkWinLoss', type: 'fusion.chart.SparkWinLoss', label: 'Spark Win Loss', icon: SparkChartIcon,
    layouts: [
        new PCategories({
            label: 'Data', layouts: [
                new PDiv({
                    id: 'fusion.data.dataset', path: 'valuesDataset.dataset',
                    label: 'Dataset',
                    layouts: [FFusionWidgets.incrementType, FFusionWidgets.incrementWhenExpression, FFusionWidgets.resetType, MDatasetRun]
                }),
                new PDiv({
                    id: 'fusion.data.items', path: 'valuesDataset',
                    label: 'Items',
                    layouts: [new PConnectedTableDetail({
                        id: 'items', label: 'Items', rowLabel: 'Item', expanded: true, layouts: [
                            new PDiv({
                                id: 'fusion.data.items.data',
                                label: 'Data',
                                layouts: [FFusionItems.resultExpression, FFusionItems.scorelessExpression]
                            })],
                        default: { id: 'item-1' }
                    })]
                }),
                new PDiv({
                    id: 'fusion.data.valuesets.hyperlinks',
                    label: 'Hyperlink', path: 'hyperlink',
                    layouts: [...MHyperlinks.layouts]
                })]
        })]
};