/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const VariableIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>jrws/outline/variables</title>
            <g id="jrws/outline/variables" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fontFamily="ArialUnicodeMS, Arial Unicode MS" fontSize="14" fontWeight="normal">
                <text id="(x)" fill="#055DAB">
                    <tspan x="2" y="13">(</tspan>
                    <tspan x="6.66210938" y="13" fontFamily="Cambria-BoldItalic, Cambria" fontStyle="italic" fontWeight="bold">x</tspan>
                    <tspan x="13.4228516" y="13">)</tspan>
                </text>
            </g>
        </svg>
    );
}
export default VariableIcon;