
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { AbstractTextualEditor, IAbstractTextualEditor } from './AbstractTextualEditor';
import AceEditor, { IAceEditorProps } from 'react-ace';
import format from 'xml-formatter';
import { RunContext } from '@jss/js-repository/src/RunContainer';
import { IRepositoryItemDescriptor } from '@jss/js-rest-api';
import { ResourcePickerDialog } from '@jss/js-repository';


export default class InternalJRXMLEditor extends AbstractTextualEditor {

    constructor(props: IAbstractTextualEditor) {
        super(props);
    }

    public getAcceptedFileTypes() {
        return ".jrxml"
    }

    public getDefaultExtension() {
        return 'jrxml';
    }
    
    public getTextEditor(editorProps: IAceEditorProps) : React.ReactElement{
        const props = {
            ...editorProps,
            mode: 'xml',
            wrapEnabled: true,
            enableBasicAutocompletion: true,
            tabSize: 2,
            theme: 'textmate',
        };
        return <AceEditor {...props} ref={this.editor} />;
    }

    public formatContent(content: string) : string {
        try{
            return format(content, {indentation: '  ', collapseContent: true});
        } catch (error) {
            return content;
        }
    }

    public openSaveDialog() : void {
        const defaultPath: IRepositoryItemDescriptor[] = this.state.path;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.context.showDialog(ResourcePickerDialog, {fileNameLabel: 'Report Name', mode: 'save', title: 'Save Report As...', onViewClose: () => { }, onFileSelected: this.confirmSave, defaultFileName: `${this.state.openDescriptor?.name}`,  defaultPath: defaultPath });
    }

    public hasFomatter = () => {
        return true;
    }
}

InternalJRXMLEditor.contextType = RunContext;
