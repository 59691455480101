/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import axios, { AxiosPromise } from 'axios';
import { IRepositoryItemDescriptor } from '../repo/IRepositoryItemDescriptor';
import { RepositoryApi } from '../repo/RepositoryApi';
import { IJrio } from '../jrio/IJrio';
import { Base64 } from 'js-base64';
import { RepositoryJRS } from './RepositoryJRS';

const headers = { withCredentials: true, headers: { 'Cache-Control': 'no-cache', 'X-REMOTE-DOMAIN': '1', 'X-Suppress-Basic': 'true' } };

export class JrioJRS implements IJrio {

  /**
   * Check the status of a report execution
   *
   * @param executionId
   */
  public reportExecutionDetails(executionId: string): AxiosPromise<any> {
    return axios.get(RepositoryApi.inst().getBaseURL() + '/reportExecutions/' + encodeURIComponent(executionId), headers);
  }

  public getExecutionPath(d: IRepositoryItemDescriptor): string {
    if (d.uuid) {
      const indx = d.uuid.indexOf('_files/');
      if (indx >= 0) {
        return d.uuid.substring(0, indx);
      }
    }
    return '';
  }

  public isPreviewAvailable(d: IRepositoryItemDescriptor): boolean {
    return d !== undefined && d.uuid !== undefined && d.uuid.indexOf('_files/') > -1 && d.isMainReport === true;
  }

  public getViewerClientURL(_d: IRepositoryItemDescriptor): string {
    let baseUrl = RepositoryApi.inst().getBaseURL();
    if (RepositoryApi.inst().getBase() === 'js') {
      const info = RepositoryApi.inst().getInfo();
      if (info) {
        const inf = Base64.decode(info);
        const vars = inf.split('&');
        let uname, pass;
        for (let i = 0; i < vars.length; i++) {
          const pair = vars[i].split('=');
          if (pair[0] === 'serverAddress')
            baseUrl = pair[1];
          else if (pair[0] === 'username')
            uname = pair[1];
          else if (pair[0] === 'password')
            pass = pair[1];
        }
        if (uname !== undefined) {
          // here maybe we could fix the run through the jrws server, to avoid things like that
          RepositoryJRS.authenticateClient(baseUrl, uname, pass);
        }
      }
    }
    return `${baseUrl}${baseUrl.endsWith("/") ? "" : "/"}client/visualize.js`;
  }

  public getViewerClientName(_d: IRepositoryItemDescriptor): string {
    return "visualize";
  }

  public getViewerClientConfig(_d: IRepositoryItemDescriptor): Record<string, unknown> {
    return {
      logEnabled: false,
      useTheme: false
    };
  }

}