/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg } from '../../ui/UiProperty';
import UPFontName , { PFontName } from './UPFontName';

const TYPEID = 'connectedFontName';
export class PConnectedFontName extends PFontName {
    
    public constructor(init: Partial<PConnectedFontName>) {
        super(init);
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPFontName mcontext={mc} />; });