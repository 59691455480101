/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const ClientJobAlertStateEnum = new PEnum({
    id: 'ClientJobAlertState',   isEmptyUndefined: true,
    items: [
        { key: '', value: '' },
        { key: 'NONE', value: 'ClientJobAlertState.NONE' },
        { key: 'ALL', value: 'ClientJobAlertState.ALL' },
        { key: 'FAIL_ONLY', value: 'ClientJobAlertState.FAIL_ONLY' },
        { key: 'SUCCESS_ONLY', value: 'ClientJobAlertState.SUCCESS_ONLY' }
    ]
});

