/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const BarChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath id="highcharts-hde13ng-1">
          <path fill="none" d="M0 0H719V448H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M97 10H545V729H97z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" d="M97 70.5h448M97 130.5h448M97 190.5h448M97 250.5h448M97 310.5h448M97 369.5h448M97 429.5h448M97 489.5h448M97 549.5h448M97 609.5h448M97 669.5h448M97 729.5h448M97 10.5h448" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M96.5 10v719M160.5 10v719M224.5 10v719M288.5 10v719M352.5 10v719M416.5 10v719M480.5 10v719M545.5 10v719" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M97 10H545V729H97z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" stroke="#ccd6eb" d="M97 70.5H87M97 130.5H87M97 190.5H87M97 250.5H87M97 310.5H87M97 370.5H87M97 429.5H87M97 489.5H87M97 549.5H87M97 609.5H87M97 669.5H87M97 729.5H87M97 9.5H87" className="highcharts-tick" />
       <path fill="none" stroke="#ccd6eb" d="M96.5 10v719" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M97 729h448" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-bar-series highcharts-color-0 highcharts-tracker" clipPath="url(#highcharts-hde13ng-1)" data-z-index="0.1" transform="matrix(0 -1 -1 0 545 729)">
          <path fill="#7cb5ec" stroke="#fff" d="M695.5 364.5H705.5V448.5H695.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M635.5 404.5H645.5V448.5H635.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M575.5 393.5H585.5V448.5H575.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M456.5 442.5H466.5V448.5H456.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M276.5 393.5H286.5V448.5H276.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M216.5 444.5H226.5V448.5H216.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M96.5 444.5H106.5V448.5H96.5z" className="highcharts-point highcharts-color-0" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-bar-series highcharts-color-1 highcharts-tracker" clipPath="url(#highcharts-hde13ng-1)" data-z-index="0.1" transform="matrix(0 -1 -1 0 545 729)">
          <path fill="#434348" stroke="#fff" d="M683.5 338.5H693.5V448.5H683.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M623.5 417.5H633.5V448.5H623.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M563.5 49.5H573.5V448.5H563.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M503.5 433.5H513.5V448.5H503.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M444.5 428.5H454.5V448.5H444.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M384.5 413.5H394.5V448.5H384.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M324.5 440.5H334.5V448.5H324.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M264.5 438.5H274.5V448.5H264.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M204.5 411.5H214.5V448.5H204.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M144.5 434.5H154.5V448.5H144.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M84.5 384.5H94.5V448.5H84.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M24.5 446.5H34.5V448.5H24.5z" className="highcharts-point highcharts-color-1" />
       </g>
       <g className="highcharts-series highcharts-series-2 highcharts-bar-series highcharts-color-2 highcharts-tracker" clipPath="url(#highcharts-hde13ng-1)" data-z-index="0.1" transform="matrix(0 -1 -1 0 545 729)">
          <path fill="#90ed7d" stroke="#fff" d="M671.5 370.5H681.5V448.5H671.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M611.5 397.5H621.5V448.5H611.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ED7D" stroke="#fff" d="M551.5 46.5H561.5V448.5H551.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M492.5 446.5H502.5V448.5H492.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M372.5 348.5H382.5V448.5H372.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M312.5 447.5H322.5V448.5H312.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M252.5 442.5H262.5V448.5H252.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M192.5 446.5H202.5V448.5H192.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M132.5 436.5H142.5V448.5H132.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M72.5 342.5H82.5V448.5H72.5z" className="highcharts-point highcharts-color-2" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(178 762)">
       <rect width="199" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-bar-series highcharts-color-0 highcharts-series-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1996</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-bar-series highcharts-color-1 highcharts-series-1" data-z-index="1" transform="translate(75.672 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1997</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-bar-series highcharts-color-2 highcharts-series-2" data-z-index="1" transform="translate(143.344 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1998</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#90ed7d" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="82" y="44">Albuquerque</text>
       <text x="82" y="104">Anchorage</text>
       <text x="82" y="164">Boise</text>
       <text x="82" y="224">Butte</text>
       <text x="82" y="284">Elgin</text>
       <text x="82" y="344">Eugene</text>
       <text x="82" y="404">Kirkland</text>
       <text x="82" y="464">Lander</text>
       <text x="82" y="524">Portland</text>
       <text x="82" y="584">
          <tspan>San Francisco</tspan>
       </text>
       <text x="82" y="644">Seattle</text>
       <text x="82" y="704">
          <tspan>Walla Walla</tspan>
       </text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="middle">
       <text x="97" y="748">0</text>
       <text x="161" y="748">500</text>
       <text x="225" y="748">1.000</text>
       <text x="289" y="748">1.500</text>
       <text x="353" y="748">2.000</text>
       <text x="417" y="748">2.500</text>
       <text x="481" y="748">3.000</text>
       <text x="529.344" y="748">3.500</text>
    </g>
 </svg>)};

export default BarChartIcon;