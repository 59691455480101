/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import MuiMenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

import '../../assets/uxpl/css/MenuItem.css';

interface IMenuItemProps {
    label: string,
    icon?: JSX.Element,
    disabled?: boolean,
    onClick?: (event: any) => void,
}

export class MenuItem extends React.Component<IMenuItemProps> {

    public render() {
        let icon;
        if (this.props.icon) {
            icon = <ListItemIcon>
                {icon}
            </ListItemIcon>;
        }
        return <MuiMenuItem disabled={this.props.disabled} className="tc-jsw-menu-entry" onClick={this.props.onClick}>
            {icon}
            <Typography variant="inherit">{this.props.label}</Typography>
        </MuiMenuItem>
    }
}