/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { en } from "./assets/locales/en/messages";
import { it } from "./assets/locales/it/messages";
import { fr } from "./assets/locales/fr/messages";
import { i18n } from "@jss/js-common";

i18n.addResourceBundle('en', 'jrws', en, true);
i18n.addResourceBundle('it', 'jrws', it, true);
i18n.addResourceBundle('fr', 'jrws', fr, true);

export * from './repository/Repository';
export * from './repository/repositoryUtils';
export * from './views/detail/ResourceDetail';
export * from './repository/actions/ContextualActions';
export * from './repository/ResourceViewer';
export * from './RunContainer';
export * from './views/detail/ResourceInfoDialog';
export * from './views/treebrowser/ResourcePickerDialog';
export * from './views/treebrowser/OverwriteResourceDialog';
export * from './views/withRunContainer';
export * from './views/detail/ResourceInfo';