

/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import Modal from '../../dialog/Modal';
import '../../assets/uxpl/css/Footer.css';
import i18n from '../../i18n';

interface IConfig {
    buildRevision?: string,
    buildTime?: string,
    edition?: string,
    expirationDate?: string,
    licenseType?: string,
    version?: string,
    concurrentReportExecutions?: string,
}

interface IFooter {
    config?: IConfig
}

export class Footer extends React.Component<IFooter> {

  public state = {
    showAbout: false,
  }

  private onOpenAbout = () => {
      this.setState({ showAbout: true });
  }

  private onCloseAbout = () => {
    this.setState({ showAbout: false });
  }


  public render = () => {
    const config : IConfig = this.props.config ? this.props.config : {};
    const msg = i18n.t('about');
    return (
        <>
            <div id="frameFooter" className='frameFooter'>
                <p id="about">
                    <a href="#" onClick={ this.onOpenAbout}>{msg}</a>

                </p>
                <p id="copyright">Copyright &copy; 2005-{new Date().getFullYear()}  Cloud Software Group, Inc</p>
            </div>

            <Modal open={this.state.showAbout} className="aboutBox" title={msg} >
                    <div className="jrws-body">

                        <div className="control groupBox fillParent">

                            <div className="content ">

                                <div className="jrws-body">

                                    <p className="message">Product Edition:&nbsp;<span className="emphasis">{config.edition}</span></p>
                                    <p className="message">Product Version: <span className="emphasis">{config.version}</span></p>
                                    <p className="message">Build: <span className="emphasis">{config.buildRevision}</span></p>
                                    <p className="message">Build Time: <span className="emphasis">{config.buildTime}</span></p>
                                    <p className="message">License Type: <span className="emphasis">{config.licenseType}</span></p>
                                    <p className="message">Expiration: <span className="emphasis">{config.expirationDate}</span></p>
                                    <p className="message">Concurrent Report Execution: <span className="emphasis">{config.concurrentReportExecutions}</span></p>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="footer">
                        <button className="button action primary up" onClick={this.onCloseAbout}><span className="wrap">Close</span><span className="icon"/></button>
                    </div>
            </Modal>
        </>
    );
  }

}
