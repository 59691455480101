/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { AxiosPromise } from 'axios';
import { IRepositoryItemDescriptor } from '../repo/IRepositoryItemDescriptor';
import { IJrio } from './IJrio';
import { JrioJRS } from '../jrs/JrioJRS';
import { JrioRest } from '../rest/JrioRest';

export class JRIOApiService {
  private static instance: JRIOApiService = new JRIOApiService();
  private current: IJrio = new JrioRest();

  public static inst(): JRIOApiService {
    return this.instance;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setBase(base: string, userID: string) {
    if (base.startsWith('jrs:')) {
      this.current = new JrioJRS();
    } else {
      this.current = new JrioRest();
    }
  }

  /**
   * Check the status of a report execution
   *
   * @param executionId
   */
  public reportExecutionDetails(executionId: string): AxiosPromise<any> {
    return this.current.reportExecutionDetails(executionId);
  }

  public getExecutionPath(d: IRepositoryItemDescriptor): string {
    return this.current.getExecutionPath(d);
  }

  public isJrioAvailable(d: IRepositoryItemDescriptor): boolean {
    return this.current.isPreviewAvailable(d);
  }

  public getViewerClientURL(d: IRepositoryItemDescriptor): string {
    return this.current.getViewerClientURL(d);
  }

  public getViewerClientName(d: IRepositoryItemDescriptor): string {
    return this.current.getViewerClientName(d);
  }

  public getViewerClientConfig(d: IRepositoryItemDescriptor): Record<string, unknown> {
    return this.current.getViewerClientConfig(d);
  }
}