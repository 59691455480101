/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { PEnum } from "../../ui/primitive/UPEnum";

export const PdfFieldBorderStyleEnum = new PEnum({
    id: 'pdfFieldBorderStyleEnum', label: 'PdfFieldBorderStyleEnum.label', default: 'none',
    items: [{ key: '', value: '' },
    { key: 'Solid', value: 'PdfFieldBorderStyleEnum.Solid' },
    { key: 'Dashed', value: 'PdfFieldBorderStyleEnum.Dashed' },
    { key: 'Beveled', value: 'PdfFieldBorderStyleEnum.Beveled' },
    { key: 'Inset', value: 'PdfFieldBorderStyleEnum.Inset' },
    { key: 'Underline', value: 'PdfFieldBorderStyleEnum.Underline' },
    ]
});

