/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import {
    CommandHandler,
    CommandRegistry,
    getExitDialog,
    i18n,
    IToolbarProvider,
    ResourceListenerManager,
    RouterProps,
    withRouter
} from "@jss/js-common";
import { IEditorViewProps, IRepositoryItemDescriptor, MessageInfo, MIME_TYPES } from "@jss/js-rest-api";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

import {
    EXIT_TEXT_EDITOR,
    ImageEditor,
    TEXT_EDITOR_DIRTY, TEXT_SAVED_AS
} from "./ImageEditor";
import { ResourceRouteState, RunContainer } from "@jss/js-repository";
import { XMLEditor } from "@jss/js-text-editor";
import { IconButton } from "@material-ui/core";


interface IState {
    openDescriptor: IRepositoryItemDescriptor | undefined;
    path?: IRepositoryItemDescriptor[];
    toolbardData: IToolbarProvider | undefined;
    currentMessage: MessageInfo | undefined;
    savedOnce: boolean;
    isEditorDirty: boolean;
    exitAlertOpen: boolean;
    currentTabIndex: number,
    logoutCallback: () => void | undefined,
}

export type getImageEditorHeaderComponentType = (
    {
        resourceName,
        goToHome,
        requestLogout,
        tabs,
        tabIndex,
        onTabChange,
    }:
        {
            resourceName: string,
            goToHome: () => void,
            tabs: Array<any>,
            tabIndex: number,
            onTabChange: (tabIndex: number) => void,
            requestLogout: (callback) => void,
        }) => JSX.Element;


export interface ImageEditorViewProps extends IEditorViewProps {
    getHeaderComponent: getImageEditorHeaderComponentType;
    language?: string,
}

class RegistryStub implements CommandRegistry {

    private editor: _ImageEditorView;

    constructor(editor: _ImageEditorView) {
        this.editor = editor;
    }

    public registerHandler = (id: string, handle: CommandHandler) => {
        this.editor.commandHandlers.set(id, handle);
    }

    public registerToolbar = (provider: IToolbarProvider) => {
        this.editor.updateToolbar(provider);
    }

    public executeCommand = (id: string, ...params: any) => {
        if (id === EXIT_TEXT_EDITOR) {
            if (this.editor.state.isEditorDirty) {
                this.editor.setState({ exitAlertOpen: true });
            } else {
                this.editor.props.doExit();
            }
        } else if (id === TEXT_SAVED_AS) {
            if (params[0] && params[0].descriptor) {
                if (!this.editor.state.savedOnce) {
                    this.editor.props.onFirstSave(params[0].descriptor);
                }
                this.editor.setState({ openDescriptor: params[0].descriptor, savedOnce: true });
            }
        } else if (id === TEXT_EDITOR_DIRTY) {
            this.editor.setState({ isEditorDirty: params[0].isDirty });
        }
    }

    public showMessage = (messageInfo: MessageInfo) => {
        this.editor.setState({ currentMessage: messageInfo });
    }
}

class _ImageEditorView extends React.Component<ImageEditorViewProps & RouterProps, IState>  {

    private registryStub = new RegistryStub(this);

    private resourceListeners = [new ResourceListenerManager(this)];

    public commandHandlers: Map<string, CommandHandler> = new Map();

    private xmlEditorRef: React.RefObject<any> = React.createRef<any>();

    private imageEditorRef: React.RefObject<any> = React.createRef<any>();

    public state: IState = {
        currentTabIndex: 0,
        openDescriptor: undefined,
        path: undefined,
        savedOnce: false,
        toolbardData: undefined,
        currentMessage: undefined,
        isEditorDirty: false,
        exitAlertOpen: false,
        logoutCallback: undefined,
    }

    private onBeforeUnload = (event) => {
        // Show prompt based on state
        if (this.state.isEditorDirty) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = ''
            }
            return '';
        }
    }

    public componentDidMount = () => {
        window.addEventListener("beforeunload", this.onBeforeUnload);

        const textEditorData: ResourceRouteState = { descriptor: this.props.descriptor };
        if (textEditorData) {
            const { descriptor } = textEditorData;
            const path = this.props.path;
            const isNewResource = !descriptor.uuid;
            this.setState({
                openDescriptor: descriptor,
                path,
                savedOnce: !isNewResource,
                isEditorDirty: isNewResource
            }, () => {
                this.resourceListeners.forEach((resourceListener) => {
                    resourceListener.fireOpenEvent(this.state.openDescriptor, this.state.openDescriptor.data, this.state.path);
                });
            });
        }
    }

    public componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onBeforeUnload);
    }

    public updateToolbar = (toolbarProvider: IToolbarProvider) => {
        this.setState({ toolbardData: toolbarProvider });
    }

    private goToHome = () => {
        const handler = this.commandHandlers.get(EXIT_TEXT_EDITOR);
        if (handler && handler.isEnabled()) {
            handler.execute({});
        }
    }

    private requestLogout = (callback: () => void) => {
        if (this.state.isEditorDirty) {
            this.setState({ logoutCallback: callback, exitAlertOpen: true });
        } else {
            callback();
        }
    }

    private handleExitAlertCancel = () => {
        this.setState({ exitAlertOpen: false, logoutCallback: undefined });
    }

    private handleExitConfirm = () => {
        this.setState({exitAlertOpen: false, isEditorDirty: false}, () => {
            if (this.state.logoutCallback) {
                this.state.logoutCallback()
            } else {
                this.props.doExit();
            }
        })
    }

    public onTabChange = (newTabIndex: number) => {
        let newData;
        if (this.state.currentTabIndex === 0) {
            const currentData = this.imageEditorRef.current.getEditorContent();
            newData = currentData;
        } else if (this.state.currentTabIndex === 1) {
            const currentData = this.xmlEditorRef.current.getEditorContent();
            newData = currentData;
        }
        this.setState({ currentTabIndex: newTabIndex }, () => {
            this.resourceListeners.forEach((resourceListener) => {
                resourceListener.fireOpenEvent(this.state.openDescriptor, newData, this.state.path);
            });
        });
    }

    private getTabs = () => {
        if (this.state.openDescriptor && this.state.openDescriptor.mime === MIME_TYPES.SVG) {
            return [
                {
                    label: i18n.t('dataAdaptersView.tabs.editor'),
                    icon: <IconButton
                        aria-label="edit"
                        className={"jr-mButton jr-mButtonLarge jr-mButtonSecondary jr-MuiButton-contained mui"}
                        classes={{ label: "jr-mButton-icon jr-mIcon mui jr-edit" }}
                    />
                },
                {
                    label: i18n.t('dataAdaptersView.tabs.source'),
                    icon: <IconButton
                        aria-label="edit"
                        className={"jr-mButton jr-mButtonLarge jr-mButtonSecondary jr-MuiButton-contained mui"}
                        classes={{ label: "jr-mButton-icon jr-mIcon mui jr-code" }}
                    />
                }
            ]
        }
        return [];
    }

    public getTabContent = () => {
        if (this.state.currentTabIndex === 1) {
            return <XMLEditor language={this.props.language} ref={this.xmlEditorRef} resourceListenerManagers={this.resourceListeners} commandRegistry={this.registryStub} />
        } else {
            return <ImageEditor language={this.props.language} resourceListenerManagers={this.resourceListeners} ref={this.imageEditorRef} commandRegistry={this.registryStub} />
        }
    }

    public render() {
        if (this.state.openDescriptor) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    {
                        this.props.getHeaderComponent({
                            goToHome: this.goToHome, requestLogout: this.requestLogout, resourceName: this.state.openDescriptor.name,
                            tabIndex: this.state.currentTabIndex, onTabChange: this.onTabChange, tabs: this.getTabs()
                        })
                    }
                    <div style={{ flex: 1, justifyContent: 'stretch', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                        <RunContainer>
                            {this.getTabContent()}
                        </RunContainer>
                    </div>
                    {getExitDialog(this.state.exitAlertOpen, this.handleExitAlertCancel, this.handleExitConfirm)}
                </div>
            );
        } else {
            return (
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            )
        }
    }
}

export const ImageEditorView = withRouter(_ImageEditorView);
