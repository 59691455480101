/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { MChartPlot } from "./MChartPlot";
import { MCategoryAxis, MValueAxis } from "./MAxis";


export const FAreaPlot = {

};

export const MAreaPlot: APDescriptor = {
    type: 'areaPlot',
    path: 'areaPlot',
    id: 'areaPlotPropertiesDescriptor',
    layouts: [...MChartPlot.layouts, MCategoryAxis, MValueAxis]
};


