/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { CSVDataAdapterEditor } from './CSVDataAdapterEditor';
import { IDataAdapterConfig, IDataAdapterDescriptor, createNode, setFileDataAdapterAttributes, getChilNodeByName, createNodeWithValue, getProlog } from '../IDataAdapterDescriptor';
import format from 'xml-formatter';
import { MIME_TYPES } from '@jss/js-rest-api';
import { readXML } from '../HTTPConfigurationPanel';
import { v4 as uuidv4 } from 'uuid';
import { nameValidator, urlValidator } from '@jss/js-common/src/utils/validators';
import { getDataAdapterNode } from '../../editor/Utils';

export default class CSVDataAdapterDescriptor implements IDataAdapterDescriptor {

  public getMime = () => {
    return MIME_TYPES.DATA_ADAPTER_CSV;
  }

  public getIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.5 106.5" xmlSpace="preserve">
      <path d="M99.4 7.4 15.7.1c-4.2-.4-8 2.8-8.3 7L.1 90.7c-.4 4.2 2.8 8 7 8.3l83.7 7.3c4.2.4 8-2.8 8.3-7l7.3-83.7c.4-4.1-2.8-7.8-7-8.2" style={{ fill: "#fff" }} />
      <path d="M92 36.2 81.9 69.5c-.1.3-.2.4-.5.5l-7.5.7c-.2 0-.4-.1-.4-.4l-7.8-31.6c-.2-.4.1-.8.5-.8l6.3-.6c.3 0 .5.1.5.4l5.3 25.2 7-26.3c.1-.3.2-.5.6-.5l5.7-.5c.2 0 .5.3.4.6m-28.6 4.2-2.2 4.4c-.1.3-.4.5-.8.4-1.7-.6-3.4-.8-5.5-.6-2.8.2-4.3 1.6-4.1 4.2.1 1.1.6 2.1 5.1 3.6 5.5 2 6.8 4.6 7.1 7.7.7 7.9-4.6 12.2-11.3 12.8-3.6.3-6.5.2-9.4-1.1-.2-.1-.4-.3-.3-.5l2.2-5c.2-.4.3-.5.8-.4 1.8.7 3.3 1.2 6.3 1 2.6-.2 4.5-1.6 4.3-4.3-.1-1.2-.7-2.2-4.9-3.8-5.4-2-7.1-4.7-7.4-8.3-.6-6.7 4.1-11.3 10.6-11.9 4.1-.4 6-.2 9.2 1 .4.1.4.4.3.8m-23.5 2.3L37.7 47c-.1.2-.2.4-.4.4-.1 0-.3 0-.4-.1-1.7-.7-3.2-.9-5.2-.8-3.6.3-9 4.3-8.1 14 .5 5.5 3.6 8.2 7.4 7.8 1.5-.1 3.2-.5 5.2-1.7.4-.2.6-.1.8.1l2 4.1c.2.4.1.7-.3 1-2.6 1.8-5 2.4-8.5 2.7-7.9.7-13-3.8-13.7-12.3-1.2-13.2 5.9-20.8 14.9-21.6 3.7-.3 6-.1 8.3 1.2.4.1.4.5.2.9m59.2-32.3L15.5 3.1c-2.6-.2-4.8 1.7-5.1 4.2L3.1 91c-.2 2.6 1.7 4.8 4.2 5.1l83.7 7.3c2.6.2 4.8-1.7 5.1-4.2l7.3-83.7c.2-2.6-1.7-4.9-4.3-5.1" style={{ fill: "#f58220" }}/>
    </svg>
  }

  /**
   *  Return the name of this data adapter to be presented to the user
   *  i.e. CSV File
   */
  public getDataAdapterLabel = () => {
    return 'datasource.csv.descriptor.label';
  }

  /**
  *  Return the name of this data adapter to be presented to the user
  *  i.e. CSV File
  */
  public getDataAdapterDescription = () => {
    return 'datasource.csv.description';
  }

  /**
   *  Return the canonical name of the class which impements this data adapter in Java.
   *  net.sf.jasperreports.data.csv.CsvDataAdapter
   */
  public getDataAdapterClass = () => {
    return 'net.sf.jasperreports.data.csv.CsvDataAdapterImpl'
  }

  public getDataAdapterRootName = () => {
    return 'csvDataAdapter';
  }

  /**
   * Return a plain json object with the default data adapter configuration.
   * It can be just an empty object.
   *
   * @memberof IDataAdapter
   */
  public initializeDataAdapterConfig = () => {
    return {
      class: this.getDataAdapterClass(),
      name: 'New CSV Data Adapter',
      location: '',
      fieldDelimiter: ',',
      recordDelimiter: '',
      columnNames: [],
      datePattern: undefined,
      numberPattern: undefined,
      locale: undefined,
      timeZone: undefined,
      useFirstRowAsHeader: false,
    }
  };

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public validateDataAdapterConfig = (config: IDataAdapterConfig) => {

    // Check for errors...
    const errors = [];

    const nameError = nameValidator(config.name);
    if (nameError) {
      errors.push(nameError);
    }

    const urlError = urlValidator(config.location);
    if (urlError) {
      errors.push(urlError);
    }

    if (errors.length > 0) {
      return errors.join("\n");
    }

    return undefined;

  }

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public createEditor = (config: IDataAdapterConfig,
    onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => {
    return <CSVDataAdapterEditor {...config} onDataAdapterSettingsChange={onDataAdapterSettingsChange} readOnly={readOnly}/>
  }

  public getLanguages = () => {
    return ['csv', 'text'];
  }

  private lowReplace = (initialValue: string, searched: string, replacement: string) : string => {
    let newString = initialValue
    let index = newString.indexOf(searched);
    while(index !== -1){
      newString = newString.substring(0, index) + replacement + newString.substring(index + searched.length);
      index = newString.indexOf(searched);
    }
    return newString;
  }

  public toXml = (config: IDataAdapterConfig) => {
    const xmlDoc = document.implementation.createDocument(null, this.getDataAdapterRootName());
    const root = xmlDoc.getElementsByTagName(this.getDataAdapterRootName())[0];
    root.setAttribute('class', this.getDataAdapterClass());

    const nameNode = createNode(xmlDoc, config, 'name', 'name');
    if (nameNode) {
      root.appendChild(nameNode);
    }

    setFileDataAdapterAttributes(xmlDoc, config, root);

    let recordDelimiterValue = config['recordDelimiter'];
    //hack here, the xmlserializer doesn't allow to wrote unencoded values, but in our case the value is 
    //already encoded, so we replace the string in the final text/xml
    const newLineUnixUUID = uuidv4();
    const newLineWindwsUUID = uuidv4();
    const newLineMacUUID = uuidv4();
    const tabUUID = uuidv4();
    if (recordDelimiterValue) {
      recordDelimiterValue = this.lowReplace(recordDelimiterValue, '\r\n', newLineWindwsUUID);
      recordDelimiterValue = this.lowReplace(recordDelimiterValue, '\n', newLineUnixUUID);
      recordDelimiterValue = this.lowReplace(recordDelimiterValue, '\r', newLineMacUUID);
      recordDelimiterValue = this.lowReplace(recordDelimiterValue, '\t', tabUUID);

      const newEle = xmlDoc.createElement('recordDelimiter');
      const newText = xmlDoc.createTextNode(recordDelimiterValue);
      newText.insertData
      newEle.appendChild(newText);
      root.appendChild(newEle);
    }

    let fieldDelimiterValue = config['fieldDelimiter'];
    if (fieldDelimiterValue) {
      fieldDelimiterValue = this.lowReplace(fieldDelimiterValue, '\r\n', newLineWindwsUUID);
      fieldDelimiterValue = this.lowReplace(fieldDelimiterValue, '\n', newLineUnixUUID);
      fieldDelimiterValue = this.lowReplace(fieldDelimiterValue, '\r', newLineMacUUID);
      fieldDelimiterValue = this.lowReplace(fieldDelimiterValue, '\t', tabUUID);

      const newEle = xmlDoc.createElement('fieldDelimiter');
      const newText = xmlDoc.createTextNode(fieldDelimiterValue);
      newText.insertData
      newEle.appendChild(newText);
      root.appendChild(newEle);
    }

    const useFirstRowAsHeaderNode = createNode(xmlDoc, config, 'useFirstRowAsHeader', 'useFirstRowAsHeader');
    if (useFirstRowAsHeaderNode) {
      root.appendChild(useFirstRowAsHeaderNode);
    }

    const queryExecuterModeNode = createNode(xmlDoc, config, 'queryExecuterMode', 'queryExecuterMode');
    if (queryExecuterModeNode) {
      root.appendChild(queryExecuterModeNode);
    }

    const numberPatternNode = createNode(xmlDoc, config, 'numberPattern', 'numberPattern');
    if (numberPatternNode) {
      root.appendChild(numberPatternNode);
    }

    const datePatternNode = createNode(xmlDoc, config, 'datePattern', 'datePattern');
    if (datePatternNode) {
      root.appendChild(datePatternNode);
    }

    const timezoneNode = createNode(xmlDoc, config, 'timeZone', 'timeZone');
    if (timezoneNode) {
      root.appendChild(timezoneNode);
    }

    const localeValue = config.locale === 'en_EN' ? 'en' : config.locale;
    const localeNode = createNodeWithValue(xmlDoc, config, 'locale', localeValue);
    if (localeNode) {
      root.appendChild(localeNode);
    }

    const encodingNode = createNode(xmlDoc, config, 'encoding', 'encoding');
    if (encodingNode) {
      root.appendChild(encodingNode);
    }

    config.columnNames.forEach((columnName: string) => {
      const columnNameNode = xmlDoc.createElement('columnNames');
      const newText = xmlDoc.createTextNode(columnName);
      columnNameNode.appendChild(newText);
      root.appendChild(columnNameNode);
    });

    const prolog = getProlog();
    let xml = prolog + (new XMLSerializer().serializeToString(xmlDoc));
    xml = this.lowReplace(xml, newLineUnixUUID, '&#10;');
    xml = this.lowReplace(xml, newLineMacUUID, '&#13;');
    xml = this.lowReplace(xml, newLineWindwsUUID, '&#13;&#10;');
    xml = this.lowReplace(xml, tabUUID, '&#09;');
    return format(xml, { indentation: '  ', collapseContent: true });
  }

  public toJson = (xml: Document) => {
    let result: any = {
      class: this.getDataAdapterClass(),
      name: '',
      location: '',
      fieldDelimiter: ',',
      recordDelimiter: '',
      columnNames: [],
      datePattern: undefined,
      numberPattern: undefined,
      timeZone: undefined,
      locale: undefined,
      useFirstRowAsHeader: false,
    }

    getDataAdapterNode(xml.getRootNode()).childNodes.forEach((childNode) => {
      const nodeName = childNode.nodeName;
      if (nodeName === 'name') {
        result.name = childNode.textContent;
      } else if (nodeName === 'dataFile') {
        const locationNode = getChilNodeByName(childNode, 'location');
        if (!locationNode) {
          const urlNode = getChilNodeByName(childNode, 'url');
          const url = urlNode.textContent;
          result.location = url;
        } else {
          result.location = locationNode.textContent;
        }
        result = {
          ...result,
          ...readXML(childNode as Element),
        }
      } else if (nodeName === 'fileName') {
        result.location = childNode.textContent;
      } else if (nodeName === 'recordDelimiter') {
        result.recordDelimiter = childNode.textContent;
      } else if (nodeName === 'fieldDelimiter') {
        result.fieldDelimiter = childNode.textContent;
      } else if (nodeName === 'useFirstRowAsHeader') {
        result.useFirstRowAsHeader = (childNode.textContent === 'true');
      } else if (nodeName === 'locale') {
        //replace the en locale with en_EN to avoid glitch when typing since the key is the same of 
        //the start of the name
        const locale = childNode.textContent === 'en' ? 'en_EN' : childNode.textContent;
        result.locale = locale;
      } else if (nodeName === 'numberPattern') {
        result.numberPattern = childNode.textContent;
      } else if (nodeName === 'datePattern') {
        result.datePattern = childNode.textContent;
      } else if (nodeName === 'timeZone') {
        result.timeZone = childNode.textContent;
      } else if (nodeName === 'queryExecuterMode') {
        result.queryExecuterMode = (childNode.textContent === 'true');
      } else if (nodeName === 'columnNames') {
        result.columnNames.push(childNode.textContent);
      } else if (nodeName === 'encoding') {
        result.encoding = childNode.textContent;
      }
    });
    return result;
  }
}