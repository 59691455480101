/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import '../../../assets/uxpl/css/Band.css';

import * as React from 'react';
import { connect } from 'react-redux';

import * as DocumentUtils from '../../../sagas/report/designer/documentUtils';
import { IPoint } from '../../../types/geometry';
import { IState } from '../../../reducers';
import * as ReportActions from '../../../actions/reportActions';

interface IBand {
  id: string,
  top: number;
  height: number;
  zoom: number;
  highlighted?: boolean;
  label: string;
  initBandResize?: (point: IPoint, bandId: string, inverse: boolean) => void;
}

class Band extends React.Component<IBand> {


  public render() {

    const { id, top, height, zoom } = this.props;

    const classes = ["Band", "Band-" + id];

    let highlightBorder = null;

    // If we need to highlight the band, we create and SVG border with the style 
    if (this.props.highlighted) {
        highlightBorder = (
              <svg className="BandHighlightedBorder" width="100%" height={height * zoom}>
                  <rect shapeRendering="optimizeSpeed" width="100%" height="100%" strokeWidth={2/zoom} style={{ fill: 'none', stroke: 'rgba(0, 129, 203, 0.5)' }} />
              </svg>);
    }

    return (<div className={classes.join(' ')} style={{ height: height * zoom, top: top * zoom }}>
      <label style={{ maxHeight: height * zoom, fontSize: 40 * zoom, whiteSpace: 'nowrap' }}>{this.props.label}</label>
      <div className="BandHandle" 
            onMouseDown={ this.onMouseDown }
            onTouchStart={ this.onTouchStart }
        >
        <svg width="100%" height="1">
          <rect x="0" y="0" width="100%" height={1/zoom} stroke="none" fill="#b2bed5" shapeRendering="crispedges"/>
        </svg>
      </div>
      {highlightBorder}
    </div>);
  }

  /**
   * Initialize the band resize saga on mouse down on the band handle
   */
  private onMouseDown = (e: React.MouseEvent<HTMLElement>) => {
      
      e.preventDefault();
      e.stopPropagation();

      const documentLocation = DocumentUtils.convertPageToDesignerCoords( { x: e.pageX, y: e.pageY } );
      if (this.props.initBandResize) {
        this.props.initBandResize(documentLocation, this.props.id, !e.shiftKey);
      }
  }

  /**
   * Initialize the band resize saga on touch on the band handle
   */
  private onTouchStart = (e: React.TouchEvent) => {
    
    e.stopPropagation()
    e.preventDefault()

    const documentLocation = DocumentUtils.convertPageToDesignerCoords( { x: e.touches[0].pageX, y: e.touches[0].pageY } );
    if (this.props.initBandResize) {
        this.props.initBandResize(documentLocation, this.props.id, true);
    }
  }

}

// 
const mapStateToProps = (state: IState, ownProps: IBand) => {
  return { 
    highlighted: state.getIn(['report','highlightedContainer']) === ('bands/' + ownProps.id),
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return { 
    initBandResize: (point: IPoint, bandId: string, inverse: boolean) => { 
      dispatch( ReportActions.initBandResize( point, bandId, inverse) );
  },
   };
}


export default connect(mapStateToProps, mapDispatchToProps)(Band);
