/* eslint-disable react/display-name */
import React, { forwardRef, HTMLAttributes } from 'react';
import {
    FormControlLabelProps, InputLabelProps, Switch as MuiSwitch, SwitchProps as MuiSwitchProps
} from '@material-ui/core';
import {
    INLINE_CLASS, NO_LABEL_CLASS, InputSize, SizeToClass
} from '../types/InputTypes';
import FormControlLabel from '../_from_mui_src/FormControlLabel/FormControlLabel';
import '../../assets/uxpl/css/Switch.css';

export interface SwitchProps extends Omit<FormControlLabelProps, 'control'> {
    size?: Exclude<InputSize, 'large'>,
    noLabel?: boolean,
    inline?: boolean,
    control?: React.ReactElement<any, any>,
    SwitchProps?: Partial<MuiSwitchProps>,
    WrapperProps?: HTMLAttributes<HTMLDivElement> & {[key: string]: any}
    iconButtons?: React.ReactElement[],
    SwitchLabelProps?: Partial<InputLabelProps>;
}

export const Switch = forwardRef<HTMLDivElement, SwitchProps>(({
    classes = {}, noLabel = false, inline = true, size = 'medium', control, SwitchProps = {}, WrapperProps, iconButtons, checked, onChange, disabled, SwitchLabelProps,  ...rest
}, ref) => {

    const { className: switchPropsClassName = '', ...restSwitchProps } = SwitchProps;
    const inlineClass = inline ? INLINE_CLASS : '';
    const noLabelClass = noLabel ? NO_LABEL_CLASS : '';
    const labelClasses = SwitchLabelProps && SwitchLabelProps.className ? SwitchLabelProps.className : ''; 
    const innerControl = <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
    {control ?? <MuiSwitch size={size} color="primary" className={`jrws-mInputSwitch jr-mInput-switch mui ${switchPropsClassName}`} disabled={disabled} onChange={onChange} checked={checked} {...restSwitchProps} />}
    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        {iconButtons}
    </div>
</div>
    return (
        <div ref={ref} className={`jr-mInput jr-mInputSwitch ${inlineClass} ${noLabelClass} ${SizeToClass[size]} mui`} {...WrapperProps}>
            <FormControlLabel
                classes={{ label: `jr-mInput-label mui ${labelClasses} ${classes?.root ?? ''}`, ...classes }}
                control={innerControl}
                labelPlacement="start"
                {...rest}
            />
        </div>
    )
})
