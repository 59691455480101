/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { i18n } from "i18next";

/**
 *  I18n service to handle globally the localization of the modules.
 */
export class I18nService {

    private i18nProviderInstances: Map<string, i18n> = new Map();
    private static INST: I18nService = new I18nService();

    private constructor() {
        // Empty
    }

    /**
     * Register an i18n provider instance for the specified module.
     *
     * @param moduleName the module name to be used as key
     * @param i18nProvider the i18n provider
     */
    public static registerProvider(moduleName: string, i18nProvider: i18n): void {
        //console.log('Register provider for module ' + moduleName);
        I18nService.INST.i18nProviderInstances.set(moduleName, i18nProvider);
    }

    /**
     * Un-register the i18n provider possibly set for the specified module.
     *
     * @param moduleName the module name
     */
    public static unregisterProvider(moduleName: string): void {
        //console.log('Unregister provider for module ' + moduleName);
        I18nService.INST.i18nProviderInstances.delete(moduleName);
    }

    /**
     * Returns the specified i18n provider
     *
     * @param moduleName the module name
     */
    public static getProvider(moduleName: string): i18n | undefined {
        //console.log('Returning provider for module ' + moduleName);
        return I18nService.INST.i18nProviderInstances.get(moduleName);
    }

    /**
     * Checks if it exists an i18n provider for the specified module.
     *
     * @param moduleName
     */
    public static existProvider(moduleName: string): boolean {
        //console.log('Checking if it exists provider for module ' + moduleName);
        return I18nService.INST.i18nProviderInstances.has(moduleName);
    }

    /**
     * Update the language for all the i18n provider instances
     *
     * @param newLanguage the new language to set
     */
    public static changeAllLanguages(newLanguage: string): void {
        // TODO - verify if we can improve this operation
        //console.log("Updating all modules language with new one " + newLanguage);
        I18nService.INST.i18nProviderInstances.forEach((lngService) => {
            lngService.changeLanguage(newLanguage, (err) => {
                if (err) console.log("An error occurred: ", err);
            });
        });
    }

    /**
     * Update the language for the specified module i18n provider.
     *
     * @param moduleName the module name
     * @param newLanguage the new language to set
     */
    public static changeLanguage(moduleName: string, newLanguage: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (I18nService.existProvider(moduleName)) {
                //console.log("Updating language for module " + module);
                (I18nService.getProvider(moduleName) as i18n).changeLanguage(newLanguage, (err) => {
                    if (err) {
                        console.log("An error occurred: ", err);
                        reject(err);
                    } else {
                        resolve(true);
                    }
                });
            }
            reject(new Error(`No provider for module '${moduleName}' could be found!`));
        });
    }

    /**
     * Translates a key using the specified module i18n provider.
     *
     * @param key the message key
     * @param moduleName the module name
     */
    public static translate(key: string, moduleName: string): string {
        const prov = this.getProvider(moduleName);
        if (prov) {
            return prov.t(key);
        }
        else {
            return key;
        }
    }
}
