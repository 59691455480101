/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Conf } from "../config/Conf";
import { IRepositoryItemDescriptor } from "./IRepositoryItemDescriptor";

export enum MIME_TYPES {
    FOLDER = 'folder',
    REPOSITORY = 'repository',
    OWNER_USER = 'owner/user',
    OWNER_ORGANIZATION = 'owner/organization',
    REPOSITORY_ROOT_JACKRABBIT = 'repository/root+jackrabbit',

    S3_BUCKET = 's3/bucket',
    GITHUB_REPOSITORY = 'github/repository',

    JRXML = 'application/vnd.jaspersoft.report.jrxml+xml',
    REPORTUNIT = 'application/repository.reportUnit+json',
    JRTX = 'application/vnd.jaspersoft.report.jrtx+xml',
    JRCTX = 'application/vnd.jaspersoft.report.jrctx+xml',
    JRPRINT = 'application/vnd.jaspersoft.report.jrprint+xml',
    JASPER = 'application/vnd.jaspersoft.report.jasper+xml',
    DATA_ADAPTER_JDBC = 'application/vnd.jaspersoft.dataadapter.jdbc+xml',
    DATA_ADAPTER_CSV = 'application/vnd.jaspersoft.dataadapter.csv+xml',
    DATA_ADAPTER_XML = 'application/vnd.jaspersoft.dataadapter.xml+xml',
    DATA_ADAPTER_XLS = 'application/vnd.jaspersoft.dataadapter.xls+xml',
    DATA_ADAPTER_JNDI = 'application/vnd.jaspersoft.dataadapter.jndi+xml',
    DATA_ADAPTER_JSON = 'application/vnd.jaspersoft.dataadapter.json+xml',
    DATA_ADAPTER_EMPTY = 'application/vnd.jaspersoft.dataadapter.empty+xml',
    DATA_ADAPTER_RANDOM = 'application/vnd.jaspersoft.dataadapter.random+xml',
    DATA_ADAPTER_DATA_SOURCE = 'application/vnd.jaspersoft.dataadapter.datasource+xml',
    DATA_ADAPTER_XMLA = 'application/vnd.jaspersoft.dataadapter.xmla+xml',
    DATA_ADAPTER_MONDRIAN = 'application/vnd.jaspersoft.dataadapter.mondrian+xml',
    DATA_ADAPTER_SPRING_HIBERNATE = 'application/vnd.jaspersoft.dataadapter.springhibernate+xml',
    DATA_ADAPTER_QUERY_EXECUTER = 'application/vnd.jaspersoft.dataadapter.qe+xml',
    DATA_ADAPTER_JRS = 'application/vnd.jaspersoft.dataadapter.jrs+xml',
    DATA_ADAPTER_MONGODB = 'application/vnd.jaspersoft.dataadapter.mongodb+xml',
    DATA_ADAPTER_EJBQL = 'application/vnd.jaspersoft.dataadapter.ejbql+xml',
    DATA_ADAPTER_BEAN = 'application/vnd.jaspersoft.dataadapter.bean+xml',
    DATA_ADAPTER_HIBERNATE = 'application/vnd.jaspersoft.dataadapter.hibernate+xml',
    DATA_ADAPTER_PROVIDER = 'application/vnd.jaspersoft.dataadapter.provider+xml',
    DATA_ADAPTER_SPOTFIRE = 'application/vnd.jaspersoft.dataadapter.spotfire+xml',

    DATASOURCE_BEAN = 'application/repository.beanDataSource+json',
    DATASOURCE_CUSTOM = 'application/repository.customDataSource+json',
    DATASOURCE_DOMAIN = 'application/repository.domain+json',
    DATASOURCE_SEMANTIC_LAYER = 'application/repository.semanticLayerDataSource+json',
    DATASOURCE_JNDI = 'application/repository.jndiJdbcDataSource+json',
    DATASOURCE_MONDRIAN_CONNECTION = 'application/repository.mondrianConnection+json',
    DATASOURCE_MONDRIAN_XMLA_DEFINITION = 'application/repository.mondrianXmlaDefinition+json',
    DATASOURCE_MONDRIAN_SECURE_CONNECTION = 'application/repository.secureMondrianConnection+json',
    DATASOURCE_VIRTUAL = 'application/repository.virtualDataSource+json',
    DATASOURCE_XMLA_CONNECTION = 'application/repository.xmlaConnection+json',
    DATASOURCE_AWS = 'application/repository.awsDataSource+json',
    DATASOURCE_AZURE_SQL = 'application/repository.azureSqlDataSource+json',
    DATASOURCE_JDBC = 'application/repository.jdbcDataSource+json',
    DATASOURCE_ADHOC_DATA_VIEW = 'application/repository.adhocDataView+json',

    JRIO_CONTEXT_XML = 'application/vnd.jaspersoft.jrio.context+xml',

    FILE = 'application/octet-stream',

    PROPERTIES = 'text/properties',
    JAVASCRIPT = 'text/javascript',
    JAVASCRIPT_APP = 'application/javascript',
    CSS = 'text/css',
    HTML = 'text/html',
    XHTML = 'application/xhtml+xm',
    XML = 'application/xml',
    JSON = 'application/json',
    TXML = 'text/xml',
    CSV = 'text/csv',
    PDF = 'application/pdf',
    EXCEL = 'application/vnd.ms-excel',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    TEXT = 'text/plain',
    SH = 'application/x-sh',
    BAT = 'application/x-msdownload',

    JPG = 'image/jpeg',
    PNG = 'image/png',
    SVG = 'image/svg+xml',
    BMP = 'image/bmp',
    ICO = 'image/vnd.microsoft.icon',
    GIF = 'image/gif',
    TIFF = 'image/tiff',
    WEBP = 'image/webp',

    EOT = 'application/vnd.ms-fontobject',
    TTF = 'font/ttf',
    OTF = 'font/otf',
    WOFF = 'font/woff',
    WOFF2 = 'font/woff2',

    ZIP = 'application/zip',
    JAR = 'application/java-archive',
}
export interface MimeDescriptor {
    mime: string;
    logo?: string;
    shortLabel?: string;
    description?: string;
    extensions?: string[];
    show?(item: IRepositoryItemDescriptor, path: IRepositoryItemDescriptor[], callback: IResourceManager);
}

export interface IResourceManager {
    onExit(): void;
    onSave(descriptor: IRepositoryItemDescriptor): void;
    onFirstSave(descriptor: IRepositoryItemDescriptor): void;
}

export const isBinary = (d: any) => {
    return (d.mime && (d.mime.startsWith('image/') || d.mime === MIME_TYPES.EXCEL || d.mine === MIME_TYPES.PDF ||
        d.mime.startsWith('application/vnd.oasis.opendocument') || d.mime.startsWith('application/vnd.openxmlformats-officedocument')
        || d.mime === MIME_TYPES.JAR || d.mime === MIME_TYPES.ZIP
        || d.mime === MIME_TYPES.FILE || d.mime.type === MIME_TYPES.EOT || d.mime.startsWith('font')));
}

export const getExtension = (descriptor: IRepositoryItemDescriptor) => {
    const name = descriptor.name;
    const separator = name.lastIndexOf('.');
    if (separator !== -1){
        return name.substring(separator + 1).toLowerCase();
    }
    return name;
}

export class Mimes {
    private static descriptors = new Map<string, MimeDescriptor>();

    public static get(key: string, def = true): MimeDescriptor | undefined {
        const m = this.descriptors.get(key);
        return m !== undefined || !def ? m : this.descriptors.get(MIME_TYPES.FILE);
    }

    public static register(descriptor: MimeDescriptor) {
        this.descriptors.set(descriptor.mime, descriptor);
    }

    public static show(item: IRepositoryItemDescriptor, path: IRepositoryItemDescriptor[], callback: IResourceManager) {
        let m = item.mime !== undefined ? this.descriptors.get(item.mime) : undefined;
        if (!m || !m.show || item.permission === 0) {
            m = this.descriptors.get(MIME_TYPES.FILE); // put a default file editor
        }
        if (m && m.show)
            return m.show(item, path, callback);
    }

    public static mime4extension(d: IRepositoryItemDescriptor) {
        this.descriptors.forEach((v: MimeDescriptor, key: string) => {
            if (key.indexOf('/') > -1 && v.extensions && v.extensions.some(ext => d.name?.endsWith(ext))) {
                d.mime = v.mime;
                return;
            }
        });
    }

    public static getDescriptor(mime: string) {
        const d = this.descriptors.get(mime);
        if (!d && mime.startsWith('text/'))
            return this.descriptors.get(MIME_TYPES.TEXT);
        return d;
    }

    public static init() {
        this.register({ mime: MIME_TYPES.FILE, logo: 'InsertDriveFile', shortLabel: 'File', description: 'File' });
        this.register({ mime: MIME_TYPES.FOLDER, logo: 'folder', shortLabel: 'Folder', description: 'Folder' });
        this.register({ mime: 'repository', logo: 'Inbox', shortLabel: 'Repository', description: 'Repository' });
        this.register({ mime: 'owner/user', logo: 'Owner', shortLabel: 'Owner', description: 'Owner' });
        this.register({ mime: 'owner/organization', logo: 'Organization', shortLabel: 'Organization', description: 'Organization' });

        this.register({ mime: MIME_TYPES.GITHUB_REPOSITORY, logo: 'Inbox', shortLabel: 'Repository', description: 'Github repository' });
        this.register({ mime: 'github/branch', logo: 'folder', shortLabel: 'Branch', description: 'Github branch' });
        this.register({ mime: 'github/tag', logo: 'folder', shortLabel: 'Tag', description: 'Github tag' });
        this.register({ mime: MIME_TYPES.S3_BUCKET, logo: 'folder', shortLabel: 'Bucket', description: 'S3 Bucket' });
        this.register({ mime: 'reportUnit', logo: 'folder', shortLabel: 'Report Unit', description: 'Report Unit' });
        this.register({ mime: MIME_TYPES.REPORTUNIT, logo: 'folder', shortLabel: 'Report Unit', description: 'Report Unit' });
        //-------- Basic, standard editors
        this.register({ mime: MIME_TYPES.TEXT, logo: 'InsertDriveFile', shortLabel: 'Text', description: 'Text file', extensions: ['.txt'] });
        this.register({ mime: MIME_TYPES.SH, logo: 'InsertDriveFile', shortLabel: 'Shell', description: 'Shell file', extensions: ['.sh'] });
        this.register({ mime: MIME_TYPES.BAT, logo: 'InsertDriveFile', shortLabel: 'Shell', description: 'Shell file', extensions: ['.bat'] });
        this.register({ mime: 'text/manifest.mf', logo: 'InsertDriveFile', shortLabel: 'Text', description: 'Manifest', extensions: ['.mf'] });
        this.register({ mime: MIME_TYPES.CSS, logo: 'Css', shortLabel: 'css', description: 'CSS', extensions: ['.css'] });
        this.register({ mime: MIME_TYPES.JAVASCRIPT, logo: 'Javascript', shortLabel: 'Javascript', description: 'Javascript', extensions: ['.js'] });
        this.register({ mime: MIME_TYPES.JAVASCRIPT_APP, logo: 'Javascript', shortLabel: 'Javascript', description: 'Javascript', extensions: ['.js'] });
        this.register({ mime: MIME_TYPES.HTML, logo: 'Html', shortLabel: 'html', description: 'HTML', extensions: ['.html', '.htm'] });
        this.register({ mime: MIME_TYPES.XHTML, logo: 'Html', shortLabel: 'xhtml', description: 'XHTML', extensions: ['.xhtml'] });
        this.register({ mime: MIME_TYPES.PDF, logo: 'InsertDriveFile', shortLabel: 'pdf', description: 'PDF', extensions: ['.pdf'] });
        this.register({ mime: MIME_TYPES.TXML, logo: 'InsertDriveFile', shortLabel: 'xml', description: 'XML', extensions: ['.xml'] });
        this.register({ mime: MIME_TYPES.XML, logo: 'InsertDriveFile', shortLabel: 'xml', description: 'XML', extensions: ['.xml'] });
        this.register({ mime: MIME_TYPES.JRIO_CONTEXT_XML, logo: 'JRIO Context', shortLabel: 'context', description: 'JRIO Context', extensions: ['.xml'] });
        this.register({ mime: MIME_TYPES.JSON, logo: 'InsertDriveFile', shortLabel: 'json', description: 'JSON', extensions: ['.json'] });
        this.register({ mime: MIME_TYPES.PROPERTIES, logo: 'InsertDriveFile', shortLabel: 'Properties', description: 'Resource bundle', extensions: ['.properties'] });
        this.register({ mime: MIME_TYPES.EXCEL, logo: 'InsertDriveFile', shortLabel: 'Excel', description: 'Excel', extensions: ['.xls', '.xlsx'] });
        this.register({ mime: MIME_TYPES.CSV, logo: 'InsertDriveFile', shortLabel: 'csv', description: 'Comma-separated values (CSV)', extensions: ['.csv'] });
        this.register({ mime: 'text/tab-separated-values', logo: 'InsertDriveFile', shortLabel: 'tsv', description: 'Tab-separated values', extensions: ['.tsv', '.tab'] });
        this.register({ mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", logo: 'InsertDriveFile', shortLabel: 'xlsx', description: 'Excel', extensions: ['.xlsx'] });
        // images
        this.register({ mime: MIME_TYPES.JPG, logo: 'Image', shortLabel: 'Image', description: 'Image jpeg', extensions: ['.jpeg', '.jpg'] });
        this.register({ mime: MIME_TYPES.PNG, logo: 'Image', shortLabel: 'Image', description: 'Image png', extensions: ['.png'] });
        this.register({ mime: MIME_TYPES.SVG, logo: 'Image', shortLabel: 'Image', description: 'Image svg', extensions: ['.svg'] });
        this.register({ mime: MIME_TYPES.BMP, logo: 'Image', shortLabel: 'Image', description: 'Image bmp', extensions: ['.bmp'] });
        this.register({ mime: MIME_TYPES.ICO, logo: 'Image', shortLabel: 'Image', description: 'Image ico', extensions: ['.ico'] });
        this.register({ mime: MIME_TYPES.GIF, logo: 'Image', shortLabel: 'Image', description: 'Image gif', extensions: ['.gif'] });
        this.register({ mime: MIME_TYPES.TIFF, logo: 'Image', shortLabel: 'Image', description: 'Image tiff', extensions: ['.tiff'] });
        this.register({ mime: MIME_TYPES.WEBP, logo: 'Image', shortLabel: 'Image', description: 'Image webp', extensions: ['.webp'] });
        this.register({ mime: "img", logo: 'Image', shortLabel: 'Image', description: 'Image', extensions: ['.jpeg', '.jpg', '.png', '.svg', '.bmp', '.ico', '.gif', '.tiff', '.webp'] });
        // fonts
        this.register({ mime: MIME_TYPES.EOT, logo: 'Abc', shortLabel: 'Font', description: 'Font .eot', extensions: ['.eot'] });
        this.register({ mime: MIME_TYPES.TTF, logo: 'Abc', shortLabel: 'Font', description: 'Font .ttf', extensions: ['.ttf'] });
        this.register({ mime: MIME_TYPES.OTF, logo: 'Abc', shortLabel: 'Font', description: 'Font .otf', extensions: ['.otf'] });
        this.register({ mime: MIME_TYPES.WOFF, logo: 'Abc', shortLabel: 'Font', description: 'Font .woff', extensions: ['.woff'] });
        this.register({ mime: MIME_TYPES.WOFF2, logo: 'Abc', shortLabel: 'Font', description: 'Font .woff2', extensions: ['.woff2'] });
        this.register({ mime: "font", logo: 'Abc', shortLabel: 'Font', description: 'Font', extensions: ['.eot', '.ttf', '.otf', '.woff', '.woff2'] });
        // archives
        this.register({ mime: MIME_TYPES.ZIP, logo: 'InsertDriveFile', shortLabel: 'zip', description: 'ZIP', extensions: ['.zip'] });
        this.register({ mime: MIME_TYPES.JAR, logo: 'InsertDriveFile', shortLabel: 'jar', description: 'JAR', extensions: ['.jar'] });
        //-------- JR editors
        this.register({ mime: MIME_TYPES.JRXML, logo: 'Landscape', shortLabel: 'JR Report', description: 'JasperReports templates', extensions: ['.jrxml'] });
        this.register({ mime: MIME_TYPES.JRTX, logo: 'Landscape', shortLabel: 'JR Styles', description: 'JasperReports styles template', extensions: ['.jrtx'] });
        this.register({ mime: MIME_TYPES.JRCTX, logo: 'Landscape', shortLabel: 'JR Chart Styles', description: 'JasperReports chart styles template', extensions: ['.jrctx'] });
        this.register({ mime: MIME_TYPES.JASPER, logo: 'Landscape', shortLabel: 'Jasper', description: 'JasperReports chart styles template', extensions: ['.jasper'] });
        this.register({ mime: MIME_TYPES.JRPRINT, logo: 'Landscape', shortLabel: 'JR Print', description: 'JasperReports chart styles template', extensions: ['.jrprint'] });
        //-------- data adapters
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_JDBC, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter JDBC', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_CSV, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter CSV', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_XML, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter XML', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_XLS, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter XLS', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_JNDI, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter JNDI', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_JSON, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter JSON', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_EMPTY, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter Empty records', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_RANDOM, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter Random value records', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_DATA_SOURCE, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter DataSource value records', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_XMLA, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter XMLA', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_MONDRIAN, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter Mondrian', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_HIBERNATE, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter Hibernate', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_SPRING_HIBERNATE, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter Hibernate with Spring', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_QUERY_EXECUTER, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter Query Executer', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_JRS, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter JRS', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_MONGODB, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter MongoDB', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_EJBQL, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter EJBQL', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_BEAN, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter Java Bean', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_PROVIDER, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter DataSourceProvider', extensions: ['.jrdax'] });
        this.register({ mime: MIME_TYPES.DATA_ADAPTER_SPOTFIRE, logo: 'Storage', shortLabel: 'Data Adapter', description: 'Data Adapter Spotfire', extensions: ['.jrdax'] });
        //-------- JRS data sources
        this.register({ mime: MIME_TYPES.DATASOURCE_ADHOC_DATA_VIEW, logo: 'Storage', shortLabel: 'Data Source', description: 'Adhoc Data View Data Source', extensions: [] });
        this.register({ mime: MIME_TYPES.DATASOURCE_AWS, logo: 'Storage', shortLabel: 'Data Source', description: 'AWS Data Source', extensions: [] });
        this.register({ mime: MIME_TYPES.DATASOURCE_AZURE_SQL, logo: 'Storage', shortLabel: 'Data Source', description: 'Azure SQL Data Source', extensions: [] });
        this.register({ mime: MIME_TYPES.DATASOURCE_BEAN, logo: 'Storage', shortLabel: 'Data Source', description: 'Bean Data Source', extensions: [] });
        this.register({ mime: MIME_TYPES.DATASOURCE_CUSTOM, logo: 'Storage', shortLabel: 'Data Source', description: 'Custome Data Source', extensions: [] });
        this.register({ mime: MIME_TYPES.DATASOURCE_DOMAIN, logo: 'Storage', shortLabel: 'Data Source', description: 'Domain Data Source', extensions: [] });
        this.register({ mime: MIME_TYPES.DATASOURCE_JDBC, logo: 'Storage', shortLabel: 'Data Source', description: 'JDBC Data Source', extensions: [] });
        this.register({ mime: MIME_TYPES.DATASOURCE_JNDI, logo: 'Storage', shortLabel: 'Data Source', description: 'JNDI Data Source', extensions: [] });
        this.register({ mime: MIME_TYPES.DATASOURCE_MONDRIAN_CONNECTION, logo: 'Storage', shortLabel: 'Data Source', description: 'Mondrian connection Data Source', extensions: [] });
        this.register({ mime: MIME_TYPES.DATASOURCE_MONDRIAN_SECURE_CONNECTION, logo: 'Storage', shortLabel: 'Data Source', description: 'Mondrian secure connection Data Source', extensions: [] });
        this.register({ mime: MIME_TYPES.DATASOURCE_MONDRIAN_XMLA_DEFINITION, logo: 'Storage', shortLabel: 'Data Source', description: 'Mondrian XMLA definition Data Source', extensions: [] });
        this.register({ mime: MIME_TYPES.DATASOURCE_SEMANTIC_LAYER, logo: 'Storage', shortLabel: 'Data Source', description: 'Semantic layer (domain) Data Source', extensions: [] });
        this.register({ mime: MIME_TYPES.DATASOURCE_VIRTUAL, logo: 'Storage', shortLabel: 'Data Source', description: 'Virtual Data Source', extensions: [] });
        this.register({ mime: MIME_TYPES.DATASOURCE_XMLA_CONNECTION, logo: 'Storage', shortLabel: 'Data Source', description: 'XMLA Connection Data Source', extensions: [] });
    }

    public static sortByType = (a: string, b: string, desc: boolean) => {
        if (a !== b && a === MIME_TYPES.FOLDER) {
            return -1;
        }
        if (a !== b && b === MIME_TYPES.FOLDER) {
            return 1;
        }
        return Mimes.sortByName(a, b, desc);
    }

    public static sortByName = (a: string, b: string, desc: boolean): number => {
        a = a ? a : '';
        b = b ? b : '';
        return desc ? a.localeCompare(b, Conf.getLocale()) : b.localeCompare(a, Conf.getLocale());
    }

}


Mimes.init();
