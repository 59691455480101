/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { AceEditorWrapper, FormControlledTextField, PopoverControl } from '@jss/js-common';
import { getKey, getPath, reg, UiPropertyProps } from '../UiProperty';
import { APDescriptor } from '../APDescriptor';
import i18n from '../../../../../i18n';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ValidationResult, VALIDATION_RESULT } from '@jss/js-common/src/utils/validators';

const TYPEID = 'expandableString';
reg(TYPEID, (mc) => { return <UPEpandableString mcontext={mc} />; });
export class PExpandableString extends APDescriptor {
    public static readonly type: string = TYPEID;
    default?: string;
    canBeEmpty?: boolean = true;
    canBeNull?: boolean = true;
    max?: number = 1000;

    public validator(v: string | undefined): ValidationResult {
        if (this.max && v && v.length > this.max) { 
            return {result: VALIDATION_RESULT.PARTIALLY_INVALID, message: 'value is exceeding ' + this.max};
        }
        if (!this.canBeEmpty && (!v || v.trim().length === 0)) { 
            return {result: VALIDATION_RESULT.INVALID, message: `The value can't be empty`};
        }
        if (!this.canBeNull && (!v || v === null)) { 
            return {result: VALIDATION_RESULT.INVALID, message: `The value can't be empty`};
        }
        return {result: VALIDATION_RESULT.VALID};
    }

    public constructor(init: Partial<PExpandableString>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

const stringValidator = (stringValue: string | undefined, descriptor: PExpandableString): ValidationResult => {
    if (descriptor.validator) {
        const validationResult = descriptor.validator(stringValue);
        if (validationResult) {
            return validationResult;
        }
    }
    return { result: VALIDATION_RESULT.VALID }
}


export interface IUPExpandableString {
    additionalIcon?: React.ReactElement,
}

export class UPEpandableString extends React.Component<UiPropertyProps & IUPExpandableString> {

    constructor(props: UiPropertyProps) {
        super(props);
    }

    private getPopoverContent = (handleClose: () => void) => {
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p) as string | undefined;
        const props = {
            mode: 'text',
            wrapEnabled: true,
            enableBasicAutocompletion: true,
            showGutter: false, // boolean: true if show line gutter
            tabSize: 2
        }
        return <div style={{ padding: 10, height: 200, width: 500, display: 'flex', flexDirection: 'column' }} contentEditable={true} suppressContentEditableWarning={true}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <div/>
                <IconButton aria-label="close" onClick={() => { handleClose(); }} size='small'>
                    <CloseIcon />
                </IconButton>
            </div>
            <AceEditorWrapper aceProps={props} value={v} onValueChange={this.onValueChange} readonly={this.props.mcontext.descriptor.readonly}/>
        </div>;
    }

    private getControl = (handleClick: (event) => void) => {
        return <div style={{ display: 'flex' }} onClick={handleClick}><MoreHorizIcon style={{ cursor: 'pointer' }} /></div>;
    }

    render() {
        const p = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p) as string | undefined;
        const descriptor = this.props.mcontext.descriptor as PExpandableString;
        const classes: string[] = [];
        if (this.props.mcontext.descriptor.className) {
            classes.push(this.props.mcontext.descriptor.className);
        }

        const validator = (stringValue: string) => stringValidator(stringValue, descriptor);
        return <FormControlledTextField
            className={classes.join(' ')}
            key={getKey(p)}
            onTextChange={this.onValueChange}
            label={i18n.t(this.props.mcontext.descriptor.label)}
            title={i18n.t(this.props.mcontext.descriptor.description)}
            // defaultValue={this.props.placeholder}
            inline={true}
            size={'small'}
            value={v === undefined ? '' : v}
            type={'text'}
            InputLabelProps={descriptor.deprecated ? { className: 'deprecatedProperty' } : undefined}
            iconInnerButtons={[<PopoverControl id="editExpressionButton" key="editExpressionButton" getContent={this.getPopoverContent} getControl={this.getControl} />]}
            iconButtons={this.props.additionalIcon ? [this.props.additionalIcon] : undefined}
            disabled={this.props.mcontext.descriptor.readonly}
            inputProps={{
                style: {paddingRight: 27},
            }}
            InputProps={
                {
                    title: v,
                    readOnly: false,
                }
            }
            validator={validator}
        />
    }

    public onValueChange = (newValue: string | undefined) => {
        //this is called only if the value is valid, no need to redo the validation
        this.props.mcontext.elements.forEach(key => {
            if (this.props.mcontext.descriptor.id)
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: newValue });
            else
                this.props.mcontext.setObjectProperties(key.path, newValue);
        });
    }
}
