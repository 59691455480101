/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';

interface IGenericTextElement {
    text: string,
    fontSize: number,
    fontName?: string,
    isBold: boolean,
    isItalic: boolean,
    isStrikeTrough: boolean,
    isUnderline: boolean
    horizontalAlignment: string,
    verticalAlignment: string,
    rotation: string,
    width: number,
    height: number,
    firstLineIndent: number,
    lineSpacingEnum: string,
    lineSpacingSize: number,
    leftIndent: number,
    rightIndent: number,
}

class GenericTextElement extends React.Component<IGenericTextElement> {

    private getCssAlignFromJrHAlign = (): 'center' | 'left' | 'right' | 'justify' => {
        let horizontalAlignment: 'center' | 'left' | 'right' | 'justify' = 'left';
        if (this.props.text && this.props.text.length > 0) {
            switch (this.props.horizontalAlignment) {
                case 'Right':
                    {
                        horizontalAlignment = 'right';
                        break;
                    }
                case 'Center':
                    {
                        horizontalAlignment = 'center';
                        break;
                    }
                case 'Justified':
                    {
                        horizontalAlignment = 'justify';
                        break;
                    }
                case 'Left':
                default:
                    {
                        horizontalAlignment = 'left';
                    }
            }
        }
        return horizontalAlignment;
    }

    private getCssAlignFromJrVAlign = (): 'top' | 'bottom' | 'middle' => {
        let verticalAlignment: 'top' | 'bottom' | 'middle' = 'top';
        switch (this.props.verticalAlignment) {
            case 'Bottom':
                {
                    verticalAlignment = 'bottom';
                    break;
                }
            case 'Middle':
                {
                    verticalAlignment = 'middle';
                    break;
                }
            default:
                {
                    verticalAlignment = 'top';
                }
        }
        return verticalAlignment;
    }

    setRotationStyles = (horizontalAlignment: 'center' | 'left' | 'right' | 'justify', spanStyleBuffer: React.CSSProperties, divStyleBuffer: React.CSSProperties): string => {
        let rotationValue;
        const textWidth = this.props.width;
        const textHeight = this.props.height;
        let rotatedWidth;
        let rotatedHeight;

        let rotationIE;
        let rotationAngle;
        let translateX;
        let translateY;
        switch (this.props.rotation) {
            case 'Left':
                {
                    translateX = - (textHeight - textWidth) / 2;
                    translateY = (textHeight - textWidth) / 2;
                    rotatedWidth = textHeight;
                    rotatedHeight = textWidth;
                    rotationIE = 3;
                    rotationAngle = -90;
                    rotationValue = "left";
                    break;
                }
            case 'Right':
                {
                    translateX = - (textHeight - textWidth) / 2;
                    translateY = (textHeight - textWidth) / 2;
                    rotatedWidth = textHeight;
                    rotatedHeight = textWidth;
                    rotationIE = 1;
                    rotationAngle = 90;
                    rotationValue = "right";
                    break;
                }
            case 'UpsideDown':
                {
                    translateX = 0;
                    translateY = 0;
                    rotatedWidth = textWidth;
                    rotatedHeight = textHeight;
                    rotationIE = 2;
                    rotationAngle = 180;
                    rotationValue = "upsideDown";
                    break;
                }
            default:
                {
                    //console.log('unrecognized rotation ' + this.props.rotation);
                    //default rotation
                }
        }

        divStyleBuffer.width = `${textWidth}px`;
        divStyleBuffer.height = `${textHeight}px`;

        spanStyleBuffer.width = `${rotatedWidth}px`;
        spanStyleBuffer.height = `${rotatedHeight}px`;

        spanStyleBuffer.textAlign = horizontalAlignment;
        spanStyleBuffer.WebkitTransform = `translate(${translateX}px, ${translateY}px) rotate(${rotationAngle}deg)`;
        spanStyleBuffer.msTransform = `translate(${translateX}px, ${translateY}px) rotate(${rotationAngle}deg)`;
        spanStyleBuffer.filter = `progid:DXImageTransform.Microsoft.BasicImage(rotation="${rotationIE}")`
        return rotationValue;
    }

    exportStyledText = (tdVerticalAlignment: 'top' | 'bottom' | 'middle') : React.ReactElement[] => {
        const allText = this.props.text;

        const indentFirstLine = true;
        const justifyLastLine = false;

        const firstLineIndent = this.props.firstLineIndent;

        const result = [];
        if ((firstLineIndent != 0 && allText.indexOf('\n') > 0) || (!indentFirstLine || justifyLastLine)) {
            const paragraphs = allText.split('\n');
            for (let i = 0; i < paragraphs.length; i++) {
                const isLastParagraph = i === paragraphs.length - 1;
                const isFirstParagraph = i === 0;
                const key = allText + i;
                result.push(this.exportParagraph(key, paragraphs[i], isFirstParagraph && !indentFirstLine ? 0 : firstLineIndent, isLastParagraph && justifyLastLine, tdVerticalAlignment));
                if (i < paragraphs.length - 1){
                    result.push(<br key={"break" + i}/>);
                }
            }
        } else {
            const paragraphs = allText.split('\n');
            for (let i = 0; i < paragraphs.length; i++) {
                const key = allText + i;
                const replacedTab = paragraphs[i];
                //const replacedTab = paragraphs[i].replace(new RegExp(' ', 'g'), '&nbsp;');
                result.push(this.exportParagraph(key, replacedTab, !indentFirstLine ? 0 : firstLineIndent, justifyLastLine, tdVerticalAlignment, paragraphs.length > 1));
            }
        }
        return result;
    }

    exportParagraph = (key: string, paragraphText: string, firstLineIndent: number, justifyLastLine: boolean, tdVerticalAlignment: 'top' | 'bottom' | 'middle', forceDiv = false) : React.ReactElement => {
        const leftIndent = this.props.leftIndent;
        const rightIndent = this.props.rightIndent
        if (firstLineIndent || justifyLastLine || (leftIndent != null && leftIndent > 0) || (rightIndent != null && rightIndent > 0)) {
            const divStyle: React.CSSProperties = {};
            if (firstLineIndent) {
                divStyle.textIndent = `${firstLineIndent}px`;
            }
            if (justifyLastLine) {
                divStyle.textAlignLast = 'justify';
            }
            if (leftIndent && leftIndent > 0) {
                divStyle.paddingLeft = `${leftIndent}px`;
            }
            if (rightIndent && rightIndent > 0) {
                divStyle.paddingRight = `${rightIndent}px`;
            }
            return <div key={key} style={divStyle}>
                {this.exportStyledTextRun(paragraphText, key, tdVerticalAlignment, forceDiv)}
            </div>
        } else {
            return this.exportStyledTextRun(paragraphText, key, tdVerticalAlignment, forceDiv);
        }
    }

    exportStyledTextRun = (text: string, key: string, tdVerticalAlignment: 'top' | 'bottom' | 'middle', forceDiv = false) : React.ReactElement => {

        const isBold = this.props.isBold;
        const isItalic = this.props.isItalic;
        const fontFamily = this.props.fontName ? this.props.fontName : 'arial';
        // do not put single quotes around family name here because the value might already contain quotes, 
        // especially if it is coming from font extension export configuration
        const spanStyle: React.CSSProperties = {};
        spanStyle.fontFamily = fontFamily;
        spanStyle.fontSize = `${this.props.fontSize}px`;
        spanStyle.whiteSpace = 'pre';

        let lineSpacingFactor = 0;
        lineSpacingFactor = 1;
        switch (this.props.lineSpacingEnum) {
            case 'Single':
            default:
                {
                    if (lineSpacingFactor == 0) {
                        spanStyle.lineHeight = 1;
                    }
                    else {
                        spanStyle.lineHeight = lineSpacingFactor;
                    }
                    break;
                }
            case '1_1_2':
                {
                    if (lineSpacingFactor == 0) {
                        spanStyle.lineHeight = 1.5;
                    }
                    else {
                        spanStyle.lineHeight = lineSpacingFactor;
                    }
                    break;
                }
            case 'Double':
                {
                    if (lineSpacingFactor == 0) {
                        spanStyle.lineHeight = 2;
                    }
                    else {
                        spanStyle.lineHeight = lineSpacingFactor;
                    }
                    break;
                }
            case 'Proportional':
                {
                    if (this.props.lineSpacingSize) {
                        spanStyle.lineHeight = this.props.lineSpacingSize;
                    }
                    break;
                }
            case 'AtLeast':
            case 'Fixed':
                {
                    if (this.props.lineSpacingSize) {
                        spanStyle.lineHeight = this.props.lineSpacingSize;
                    }
                    break;
                }
        }

        if (isBold) {
            spanStyle.fontWeight = 'bold';
        }

        if (isItalic) {
            spanStyle.fontStyle = 'italic';
        }

        if (this.props.isUnderline) {
            spanStyle.textDecoration = 'underline';
		}

        if (this.props.isStrikeTrough){
            if (spanStyle.textDecoration) {
                spanStyle.textDecoration += ' line-through';
            } else {
                spanStyle.textDecoration = 'line-through';
            }
        }

        spanStyle.verticalAlign = tdVerticalAlignment;
        if (forceDiv){
            return <div key={key} style={spanStyle}>{text}</div>
        } else {
            return <span key={key} style={spanStyle}>{text}</span>
        }
    }



    public render() {
        if (this.props.text === undefined) {
            return null;
        }
        const tdStyle: React.CSSProperties = {};
        const spanStyle: React.CSSProperties = {};
        const divStyle: React.CSSProperties = {};

        tdStyle.textIndent = `${this.props.firstLineIndent}px`;
        const verticalAlignment = this.getCssAlignFromJrVAlign();
        const horizontalAlignment = this.getCssAlignFromJrHAlign();
        let rotationValue;
        if (this.props.rotation === 'None' || !this.props.rotation) {
            tdStyle.verticalAlign = verticalAlignment;
            //writing text align every time even when it's left
            //because IE8 with transitional defaults to center 
            tdStyle.textAlign = horizontalAlignment;
        } else {
            rotationValue = this.setRotationStyles(horizontalAlignment, spanStyle, divStyle);
        }


        if (rotationValue) {
            divStyle.position = 'relative';
            divStyle.overflow = 'hidden';
            spanStyle.position = 'absolute';
            spanStyle.display = 'table';
            return <table style={{ flex: 1, borderCollapse: 'collapse' }}>
                <tbody>
                    <tr>
                        <td style={tdStyle}>
                            <div style={divStyle}>
                                <span data-rotation={rotationValue} style={spanStyle}>
                                    <span style={{ display: 'table-cell', verticalAlign: verticalAlignment }}>
                                        {this.exportStyledText(verticalAlignment)}
                                    </span>
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        } else {
            return <table style={{ flex: 1, borderCollapse: 'collapse' }}>
                <tbody>
                    <tr>
                        <td style={tdStyle}>
                            {this.exportStyledText(verticalAlignment)}
                        </td>
                    </tr>
                </tbody>
            </table>
        }
    }


}

export default GenericTextElement;