/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { MChartPlot } from "./MChartPlot";

import { ScaleTypeEnum } from "../../enums/ScaleTypeEnum";
import { MCategoryAxis, MValueAxis } from "./MAxis";

export const FBubblePlot = {
    scaleType: ScaleTypeEnum,
};

export const MBubblePlot: APDescriptor = {
    type: 'MBubblePlot',
    path: 'bubblePlot',
    id: 'bubblePlotProeprtiesDescriptor',
    layouts: [...MChartPlot.layouts, FBubblePlot.scaleType,
        MCategoryAxis, MValueAxis
    ]
};
