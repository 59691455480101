/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../../ui/APDescriptor";
import { OrientationEnum } from "../../enums/OrientationEnum";
import { TextPositionEnum } from "../../enums/TextPositionEnum";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { PInt } from "../../../ui/primitive/UPInteger";


export const FBarcode4J = {
    verticalQuietZone: new PInt({ id: 'verticalQuietZone', label: 'Vertical Quiet Zone' }),
    patternExpression: new PExpression({ id: 'patternExpression', label: 'Pattern Expression' }),
    quietZone: new PInt({ id: 'quietZone', label: 'Quiet Zone' }),
    moduleWidth: new PInt({ id: 'moduleWidth', label: 'Module Width', min: 1, max: 40 }),
    textPosition: TextPositionEnum,
    orientation: OrientationEnum
}

export const MBarcode4J: APDescriptor = {
    type: 'Barcode4J',
    id: 'barcode4JPropertiesDescriptor',
    layouts: [FBarcode4J.patternExpression, FBarcode4J.quietZone, FBarcode4J.moduleWidth, FBarcode4J.orientation]
};
