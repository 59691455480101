/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const MultiAxisLineChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath id="highcharts-4r6ta72-1">
          <path fill="none" d="M0 0H366V672H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M56 10H422V682H56z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" d="M86.5 10v672M116.5 10v672M147.5 10v672M177.5 10v672M208.5 10v672M238.5 10v672M269.5 10v672M299.5 10v672M330.5 10v672M360.5 10v672M391.5 10v672M421.5 10v672M55.5 10v672" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M56 682.5h366M56 615.5h366M56 548.5h366M56 480.5h366M56 413.5h366M56 346.5h366M56 279.5h366M56 212.5h366M56 144.5h366M56 77.5h366M56 9.5h366" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M56 682.5h366M56 615.5h366M56 548.5h366M56 480.5h366M56 413.5h366M56 346.5h366M56 279.5h366M56 212.5h366M56 144.5h366M56 77.5h366M56 9.5h366" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M56 682.5h366M56 615.5h366M56 548.5h366M56 480.5h366M56 413.5h366M56 346.5h366M56 279.5h366M56 212.5h366M56 144.5h366M56 77.5h366M56 9.5h366" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M56 10H422V682H56z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" stroke="#ccd6eb" d="M86.5 682v10M116.5 682v10M147.5 682v10M177.5 682v10M208.5 682v10M238.5 682v10M269.5 682v10M299.5 682v10M330.5 682v10M360.5 682v10M391.5 682v10M422.5 682v10M55.5 682v10" className="highcharts-tick" />
       <path fill="none" stroke="#ccd6eb" d="M56 682.5h366" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M56 10v672" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <text x="478.313" y="346" fill="#666" className="highcharts-axis-title" color="#666" data-z-index="7" textAnchor="middle" transform="rotate(90 478.313 346)">
          <tspan>Max Freight</tspan>
       </text>
       <path fill="none" d="M422 10v672" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <text x="531.234" y="346" fill="#666" className="highcharts-axis-title" color="#666" data-z-index="7" textAnchor="middle" transform="rotate(90 531.234 346)">
          <tspan>Orders</tspan>
       </text>
       <path fill="none" d="M492 10v672" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-line-series highcharts-color-0" clipPath="url(#highcharts-4r6ta72-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="none" stroke="#7cb5ec" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.25 493.443l30.5 95.94 30.5-478.814 30.5 550.514 30.5-6.478 30.5-71.205 30.5 82.72 30.5-41.048 30.5 18.204 30.5 11.747 30.5-96.68 30.5 112.027" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M5.25 493.443h10l30.5 95.94 30.5-478.814 30.5 550.514 30.5-6.478 30.5-71.205 30.5 82.72 30.5-41.048 30.5 18.204 30.5 11.747 30.5-96.68 30.5 112.027h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-0 highcharts-line-series highcharts-color-0 highcharts-tracker" data-z-index="0.1">
          <path fill="#7cb5ec" d="M19 493a4 4 0 110-.004zM49 589a4 4 0 110-.004zM80 111a4 4 0 110-.004zM110 661a4 4 0 110-.004zM141 655a4 4 0 110-.004zM171 583a4 4 0 110-.004zM202 666a4 4 0 110-.004zM232 625a4 4 0 110-.004zM263 643a4 4 0 110-.004zM293 655a4 4 0 110-.004zM324 558a4 4 0 110-.004zM354 670a4 4 0 110-.004z" className="highcharts-point highcharts-color-0" transform="translate(56 10)" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-line-series highcharts-color-1" clipPath="url(#highcharts-4r6ta72-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="none" stroke="#434348" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.25 195.586l30.5 303.293 30.5-385.143 30.5 494.558 30.5-11.08 30.5-408.906 30.5 458.425 30.5-106.23 30.5-27.995 30.5 98.36 30.5-346.228 30.5 399.35" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M5.25 195.586h10l30.5 303.293 30.5-385.143 30.5 494.558 30.5-11.08 30.5-408.906 30.5 458.425 30.5-106.23 30.5-27.995 30.5 98.36 30.5-346.228 30.5 399.35h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-1 highcharts-line-series highcharts-color-1 highcharts-tracker" data-z-index="0.1">
          <path fill="#434348" d="M15 192l4 4-4 4-4-4zM45 495l4 4-4 4-4-4zM76 110l4 4-4 4-4-4zM106 604l4 4-4 4-4-4zM137 593l4 4-4 4-4-4zM167 184l4 4-4 4-4-4zM198 643l4 4-4 4-4-4zM228 537l4 4-4 4-4-4zM259 509l4 4-4 4-4-4zM289 607l4 4-4 4-4-4zM320 261l4 4-4 4-4-4zM350 660l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(56 10)" />
       </g>
       <g className="highcharts-series highcharts-series-2 highcharts-line-series highcharts-color-2" clipPath="url(#highcharts-4r6ta72-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="none" stroke="#90ed7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.25 386.4L45.75 504l30.5-352.8 30.5 470.4 30.5-33.6 30.5-67.2 30.5 100.8 30.5-100.8 30.5-50.4 30.5 134.4 30.5-168 30.5 201.6" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M5.25 386.4h10L45.75 504l30.5-352.8 30.5 470.4 30.5-33.6 30.5-67.2 30.5 100.8 30.5-100.8 30.5-50.4 30.5 134.4 30.5-168 30.5 201.6h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-2 highcharts-line-series highcharts-color-2 highcharts-tracker" data-z-index="0.1">
          <path fill="#90ed7d" d="M11 382h8v8h-8zM41 500h8v8h-8zM72 147h8v8h-8zM102 618h8v8h-8zM133 584h8v8h-8zM163 517h8v8h-8zM194 618h8v8h-8zM224 517h8v8h-8zM255 466h8v8h-8zM285 601h8v8h-8zM316 433h8v8h-8z" className="highcharts-point highcharts-color-2" transform="translate(56 10)" />
          <path fill="#90ed7d" strokeWidth="0" d="M346 634.4h8v8h-8z" className="highcharts-point highcharts-color-2" transform="translate(56 10)" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(128 762)">
       <rect width="299" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-line-series highcharts-color-0 highcharts-series-0" data-z-index="1">
             <path fill="none" stroke="#7cb5ec" strokeWidth="2" d="M0 11h12" className="highcharts-graph" transform="translate(8 3)" />
             <path fill="#7cb5ec" d="M10 11a4 4 0 110-.004z" className="highcharts-point" transform="translate(8 3)" />
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold" transform="translate(8 3)">
                <tspan>Total Freight</tspan>
             </text>
          </g>
          <g className="highcharts-legend-item highcharts-line-series highcharts-color-1 highcharts-series-1" data-z-index="1">
             <path fill="none" stroke="#434348" strokeWidth="2" d="M0 11h12" className="highcharts-graph" transform="translate(123.672 3)" />
             <path fill="#434348" d="M6 7l4 4-4 4-4-4z" className="highcharts-point" transform="translate(123.672 3)" />
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold" transform="translate(123.672 3)">
                <tspan>Max Freight</tspan>
             </text>
          </g>
          <g className="highcharts-legend-item highcharts-line-series highcharts-color-2 highcharts-series-2" data-z-index="1">
             <path fill="none" stroke="#90ed7d" strokeWidth="2" d="M0 11h12" className="highcharts-graph" transform="translate(232.61 3)" />
             <path fill="#90ed7d" d="M2 7h8v8H2z" className="highcharts-point" transform="translate(232.61 3)" />
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold" transform="translate(232.61 3)">
                <tspan>Orders</tspan>
             </text>
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="73.843" y="698" transform="rotate(-45 73.843 698)">Albuquerque</text>
       <text x="104.343" y="698" transform="rotate(-45 104.343 698)">Anchorage</text>
       <text x="134.843" y="698" transform="rotate(-45 134.843 698)">Boise</text>
       <text x="165.343" y="698" transform="rotate(-45 165.343 698)">Butte</text>
       <text x="195.843" y="698" transform="rotate(-45 195.843 698)">Elgin</text>
       <text x="226.343" y="698" transform="rotate(-45 226.343 698)">Eugene</text>
       <text x="256.843" y="698" transform="rotate(-45 256.843 698)">Kirkland</text>
       <text x="287.343" y="698" transform="rotate(-45 287.343 698)">Lander</text>
       <text x="317.843" y="698" transform="rotate(-45 317.843 698)">Portland</text>
       <text x="348.343" y="698" transform="rotate(-45 348.343 698)">
          <tspan>San Francisco</tspan>
       </text>
       <text x="378.843" y="698" transform="rotate(-45 378.843 698)">Seattle</text>
       <text x="409.343" y="698" transform="rotate(-45 409.343 698)">
          <tspan>Walla Walla</tspan>
       </text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="41" y="687">0</text>
       <text x="41" y="619">800</text>
       <text x="41" y="552">1.600</text>
       <text x="41" y="485">2.400</text>
       <text x="41" y="418">3.200</text>
       <text x="41" y="351">4.000</text>
       <text x="41" y="283">4.800</text>
       <text x="41" y="216">5.600</text>
       <text x="41" y="149">6.400</text>
       <text x="41" y="82">7.200</text>
       <text x="41" y="15">8.000</text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11">
       <text x="437" y="687">0</text>
       <text x="437" y="619">100</text>
       <text x="437" y="552">200</text>
       <text x="437" y="485">300</text>
       <text x="437" y="418">400</text>
       <text x="437" y="351">500</text>
       <text x="437" y="283">600</text>
       <text x="437" y="216">700</text>
       <text x="437" y="149">800</text>
       <text x="437" y="82">900</text>
       <text x="437" y="15">1.000</text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11">
       <text x="507.313" y="687">0</text>
       <text x="507.313" y="619">4</text>
       <text x="507.313" y="552">8</text>
       <text x="507.313" y="485">12</text>
       <text x="507.313" y="418">16</text>
       <text x="507.313" y="351">20</text>
       <text x="507.313" y="283">24</text>
       <text x="507.313" y="216">28</text>
       <text x="507.313" y="149">32</text>
       <text x="507.313" y="82">36</text>
       <text x="507.313" y="15">40</text>
    </g>
 </svg>)};

export default MultiAxisLineChartIcon;