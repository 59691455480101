/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import React from 'react';
import { RouterProps, withRouter } from '@jss/js-common';
import { Button, Footer } from '@jss/js-common';
import { Conf, IConfigStoreListener } from '@jss/js-rest-api';
import { AppHeader } from './common/AppHeader';
import i18n from "../i18n";
import { Popover, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import { uuidv4 } from '@jss/js-jrxml-editor/src/utils/uuidGenerator';

interface IHomeState {
    anchorEl: HTMLElement | null
}
class _Home extends React.Component<RouterProps, IHomeState> implements IConfigStoreListener {
    public state: IHomeState = { anchorEl: null }
    refresh(): void {
        this.forceUpdate();
    }
    componentDidMount() {
        Conf.addListener(this);
    }
    componentWillUnmount() {
        Conf.removeListener(this);
    }
    public render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <AppHeader />
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 3, backgroundColor: '#f7f6f6', borderRight: '1px solid #ccc', maxWidth: '25%' }}>
                        {this.getLeftColumn()}
                    </div>
                    <div style={{ flex: 5, paddingTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {this.renderButtons()}
                    </div>
                </div>
                <Footer config={Conf.get('jrws.jrio.serverInfo')} />
            </div>
        );
    }

    private renderButtons = () => {
        return <div style={{ width: '30%', flexDirection: 'column', alignItems: 'stretch', display: 'flex', minWidth: '300px', textTransform: 'capitalize' }}>
            {this.renderButton('gdrive')}
            {this.renderButton('github')}

            {this.renderButton('js')}
            {this.renderButton('jackrabbit', true)}
            {this.renderButton('jrws', true)}

            {this.renderFiles()}
        </div>
    }
    private renderFiles = () => {
        const fs = Conf.getPrefix('jrws.url.repository.file.');
        if (fs && fs.length > 0) {
            return fs.map(k => { return this.renderButton(k.replace('jrws.url.repository.', '')) });
        }
        return this.renderButton('file.filesystem', true);
    }

    private renderButton = (srv: string, hide?: boolean) => {
        let msg;
        if (srv.startsWith('file.')) {
            msg = srv.replace('file.', '');
            msg = i18n.t('home.buttons.login.file', { name: _.startCase(msg) });
        } else {
            const d = i18n.t('home.buttons.login', { name: _.startCase(srv) });
            msg = i18n.t(`home.buttons.${srv}.login`, d);
        }
        if (Conf.get(`jrws.url.rest.login.${srv}`))
            return <Button key={srv} size="large" color="primary" variant="contained" title={msg} onClick={() => { this.callLoginServlet(srv) }} style={{ marginTop: '10px', marginLeft: '0px', textTransform: 'capitalize' }}>{msg}</Button>;
        if (Conf.get('jrws.hide.login.buttons') === 'false' && !hide)
            return <>{this.renderDisabledButton(msg)}</>;
        return <></>;
    }
    private renderDisabledButton = (msg: string) => {
        return <div style={{ display: 'flex', position: 'relative', marginTop: '10px' }}>
            <Button size="large" color="primary" disabled variant="contained" style={{ width: '100%', textTransform: 'capitalize' }}>{msg}</Button>
            <div style={{ position: 'absolute', width: '100%', height: '100%' }} onMouseEnter={this.handlePopoverOpen} />
            {this.renderPopover()}
        </div>;
    }
    private renderPopover = () => {
        return <Popover
            id="mouse-over-popover"
            open={Boolean(this.state.anchorEl !== null)}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            disableRestoreFocus
            PaperProps={{
                onMouseLeave: this.handlePopoverClose,
            }}
        >
            <Typography style={{ padding: '25px' }}>To be able to login you have to configure your application first. Please, read this <a href='https://community.jaspersoft.com/wiki/tibco-jasperreports-web-studio-professional-edition-beta-1' target='blank'>Wiki</a> page.</Typography>
        </Popover>;
    }

    private handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    private handlePopoverClose = () => {
        this.setState({ anchorEl: null });
    };
    private getLeftColumn = () => {
        return <div />;
    }
    private callLoginServlet = (srv: string) => {
        if (srv === 'js')
            this.props.router?.navigate('/js-login');
        else {
            let hr = Conf.get(`jrws.url.rest.login.${srv}`) as string;
            if (hr.includes('&user=userid')) {
                hr = hr.replace('&user=userid', '&user=' + uuidv4())
            }
            window.location.href = hr;
        }
    }
    private callJRSLoginServlet = () => {
        this.props.router?.navigate('/jrslogin');
    }
}

const mapStateToProps = (state: any) => {
    return {
        storedData: state.persistentReducer.storedData,
    };
}

//connected to the store to update the language
export const Home = withRouter(connect(mapStateToProps)(_Home));
