export const en = {
	components: {
		filesTable: {
			filename: "File Name",
			type: "Type",
			lastmodified: "Last Modified"
		}
	},
	searchfile: {
		placeholder: "Search",
		help: "Type the name of the file"
	},
	repoview: {
		emptyfolder: {
			text: "This folder is empty"
		},
		home: "Home"
	},
	createfolder: {
		text: "New Folder"
	},
	newresources: {
		menubutton: {
			text: "New"
		},
		resources: {
			newFolderTitle: 'Enter the Folder name',
			newOrganizationTitle: 'Enter the Organization Owner of the Repositories',
			newRepositoryTitle: 'Enter the Repository name',
			newUserTitle: 'Enter the Owner of Repositories',
			folderPlaceholder: 'New Folder',
			organizationPlaceholder: 'organization',
			userPlaceholder: 'username',
			repositoryPlaceholder: 'New Repository',
		},
		owner: {
			user: { text: "New Owner" },
			organization: { text: 'New Organization Owner' }
		},
		repository: { text: "New Repository" },
		report: {
			text: "New Report"
		},
		da: {
			text: "New Data Adapter"
		},
		upload: {
			text: "Upload..."
		},
		uploadFolder: {
			text: "Upload Folder..."
		},
	},
	resourcestable: {
		header: {
			name: "Name",
			lastmodified: "Last Modified",
			type: "Type"
		}
	},
	repository: {
		delete: {
			dialog: {
				cancel: "Cancel",
				save: "Save",
				delitem: "Delete item",
				msg: "Are you sure you want to delete the item?"
			},
			confirmation: {
				title: "Please, confirm resource deletion",
				text: "Think twice, resource will be permanently deleted."
			}
		},
		upload: {
			limit: { over: "You reached the repository size limit of: {0}" },
			file: { size: "Your uploaded file is bigger than the limit of: {0}" }
		}
	},
	menuitem: {
		rename: {
			text: "Rename"
		}
	},
	resource: {
		permission: {
			label: "Permission",
			read: "Read",
			write: "Write",
			execute: "Execute",
			none: "None",
			delete: "Delete",
			administer: "Administration",
			create: "Create",
			shared: { label: "Shared With" }
		},
		owner: "Owner",
		lastmodified: {
			date: "Last Modified Date"
		},
		created: {
			date: "Created Date"
		},
		ovewrite: {
			message: "A file with the same name already exist, do you want to overwrite it?"
		},
		type: "Type",
		name: "Name",
		path: "Path",
		mime: "MIME",
		id: "ID",
		size: "Size",
		description: "Description"
	},
	jrws: {
		owner: {
			propLabel: "Owner Limits",
			max: {
				repositories: "Max Repositories",
				space: "Max Space",
				files: "Maximum Number Of Files",
				file: { size: "Maximum Size Of File" }
			},

		},
		repository: {
			propLabel: "Per Repository Limits",
			max: {
				space: "Max Repository Space",
				files: "Maximum Number Of Files",
				file: { size: "Maximum Size Of File" }
			},

		},
		subresource: {
			number: "Files And Folders"
		}
	}
}