/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import { IRepositoryItemDescriptor, RepositoryApi } from '@jss/js-rest-api';
import { TextInput, formatDateAsString, Typography } from '@jss/js-common';
import i18n from '../../i18n';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import _ from 'lodash';
import { TextareaAutosize } from '@material-ui/core';

export const sizeOf = function (bytes) {
    if (bytes == 0) { return "0.00 B"; }
    const e = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B';
}
export interface IResourceDetailProps {
    descriptor: IRepositoryItemDescriptor,
    canSave?: (enable: boolean, newDesc: IRepositoryItemDescriptor) => void
}

export interface IItemInfoState {
    name: string | undefined,
    description: string | undefined,
    showTab: string;
    properties: any;
    acl: any;
}

export class ResourceDetail extends React.Component<IResourceDetailProps, IItemInfoState> {

    public state = {
        name: this.props.descriptor.name,
        description: this.props.descriptor.description,
        showTab: 'rinfo_tpanel0',
        properties: _.cloneDeep(this.props.descriptor.properties),
        acl: _.cloneDeep(this.props.descriptor.acl)
    }


    private isEditable(field: string) {
        return this.props.canSave && RepositoryApi.inst().field().isEditable(this.props.descriptor, field);
    }

    private validateForm() {
        const d = _.cloneDeep(this.props.descriptor);
        const name = this.state.name;
        const desc = this.state.description;
        if (d.properties)
            Object.keys(d.properties).forEach(key => {
                d.properties[key] = this.state.properties && this.state.properties[key] ? this.state.properties[key] : d.properties[key];
            });
        const props = d.properties;
        if (d.acl)
            Object.keys(d.acl).forEach(key => {
                d.acl[key] = this.state.acl && this.state.acl[key] ? this.state.acl[key] : d.acl[key];
            });
        const acl = d.acl;

        const cs = RepositoryApi.inst().field().isEditable(d, 'name')
            && !this.nameValidator(name) && !this.descValidator(desc)
            && (name !== d.name || desc !== d.description || !_.isEqual(d.properties, props) || !_.isEqual(d.acl, acl));
        this.props.canSave(cs, cs ? { ...(d), name: name, description: desc, properties: props, acl: acl } : d);
    }

    private nameValidator = (value: string | undefined) => {
        return RepositoryApi.inst().field().validate('name', value);
    }

    private descValidator = (value: string | undefined) => {
        return RepositoryApi.inst().field().validate('description', value);
    }

    private propValidator = (key, value: string | undefined) => {
        return RepositoryApi.inst().field().validate(key, value);
    }

    private onNameChange = (str: string) => {
        this.setState({ name: str }, () => {
            this.validateForm();
        });
    }

    private onDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let d = event.currentTarget.value;
        d = d ? d.trim() : d;
        d = d.length === 0 ? undefined : d;
        this.setState({ description: d }, () => {
            this.validateForm();
        });
    }

    render() {
        const d = this.props.descriptor;
        const hasProperties = this.props.descriptor.properties && Object.keys(d.properties).length > 0;
        const tabs = [
            <Tab key="rinfo_tab0" value="rinfo_tpanel0" classes={{ wrapper: "jr-mTab-label mui" }} className={"jr-mTab mui"} label={"General"} />,
            <Tab key="rinfo_tab1" value="rinfo_tpanel1" classes={{ wrapper: "jr-mTab-label mui" }} className={"jr-mTab mui"} label={"Permissions"} />
        ];
        if (hasProperties) {
            tabs.push(<Tab key="rinfo_tab2" value="rinfo_tpanel2" classes={{ wrapper: "jr-mTab-label mui" }} className={"jr-mTab mui"} label={"Properties"} />)
        }
        return <>
            <div className='jr-jrws-pccontainer-root' style={{ minWidth: 400, minHeight: 500, display: 'flex', alignItems: 'stretch' }}>
                <TabContext value={this.state.showTab} >
                    <div>
                        <Tabs
                            value={this.state.showTab}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs"
                            className={"jr-mTabs jr-mTabsMedium mui"}>
                            {tabs}
                        </Tabs>
                    </div>
                    <div style={{ display: 'flex', flex: 1, overflow: 'auto', flexDirection: 'column', alignItems: 'stretch' }}>
                        {this.state.showTab === 'rinfo_tpanel0' && <TabPanel key="rinfo_tpanel0" value="rinfo_tpanel0" className="jr-jrws-pccontainer-content-nopadding" style={{ display: 'flex', alignItems: 'stretch', flexDirection: 'column', padding: 0, marginTop: 10 }}>
                            {this.renderField(i18n.t('resource.path'), d.path)}

                            {this.renderName()}
                            {this.renderDescription()}

                            {this.renderField(i18n.t('resource.id'), d.uuid)}
                            {this.renderField(i18n.t('resource.type'), d.type.substring(3))}
                            {this.renderField(i18n.t('resource.mime'), d.mime)}
                            {this.renderSize()}

                            {this.renderCreatedDate()}
                            {this.renderLastModified()}
                        </TabPanel>}
                        {this.state.showTab === 'rinfo_tpanel1' && <TabPanel key="rinfo_tpanel1" value="rinfo_tpanel1" className="jr-jrws-pccontainer-content-nopadding" style={{ display: 'flex', alignItems: 'stretch', flexDirection: 'column', padding: 0, marginTop: 10 }}>
                            {this.renderOwner()}
                            {this.renderPermission()}
                            <div style={{ display: 'flex', paddingTop: '16px' }} className='jr-MuiPaper-root jr-MuiAccordion-root jr-mAccordion mui jr-mAccordionSmall  jr-MuiPaper-elevation0' >
                                <div style={{ flex: 1, justifyContent: 'flex-end', cursor: 'default' }} className='jr-MuiButtonBase-root jr-MuiAccordionSummary-root jr-mAccordion-title mui jr-mAccordion-titleShaded'>
                                    <div className='jr-MuiAccordionSummary-content jr-mAccordion-title-text mui ' >
                                        <Typography style={{ textTransform: 'capitalize' }}>{i18n.t('resource.permission.shared.label')}</Typography>
                                    </div>
                                </div>
                            </div>
                            {d.acl ?
                                Object.keys(d.acl).map(key => {
                                    const v = this.state.acl && this.state.acl[key] ? this.state.acl[key] : d.acl[key];
                                    return this.renderACL(key, v);
                                }) : <div style={{ textAlign: 'center', color: 'lightgray' }}>{i18n.t('tableElement.novalues')}</div>}
                        </TabPanel>}
                        {hasProperties && this.state.showTab === 'rinfo_tpanel2' &&
                            <TabPanel key="rinfo_tpanel2" value="rinfo_tpanel2" className="jr-jrws-pccontainer-content-nopadding" style={{ display: 'flex', alignItems: 'stretch', flexDirection: 'column', padding: 0, marginTop: 10 }}>
                                {this.renderProperties()}
                            </TabPanel>}

                    </div>
                </TabContext>
            </div>
        </>;
    }

    private handleChange = (event, value) => {
        this.setState({ showTab: value });
    }

    private onPropertyChange = (key: string, str: string) => {
        this.setState(prevState => ({ properties: { ...prevState.properties, [key]: str } }), () => { this.validateForm(); });
    }

    private renderACL = (label: string, value: any) => {
        value = RepositoryApi.inst().field().decode('permission', `${value}`)?.split(',').map(p => {
            return i18n.t(p);
        }).join(', ');
        return <div key={`kaclmd_${label}`} style={{ display: 'flex', alignItems: 'center', height: 22, marginBottom: 8, paddingLeft: '1em' }} className='jr-mInputSmall mui'>
            {this.renderPropertyLabel(label, 'kaclsd_')}
            {this.isEditable(label) ?
                <div key={`kacldiv_${label}`} style={{ flex: 6, display: 'flex', alignItems: 'center' }}>
                    <TextInput
                        key={`kacl_${label}`}
                        id="resource-name"
                        value={this.state.properties[label]}
                        onChange={value => this.onPropertyChange(label, value)}
                        validator={value => this.propValidator(label, value)}
                        WrapperProps={{ style: { flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', padding: 0 } }}
                        style={{ flex: 1, margin: 0 }}
                    />
                </div>
                : this.renderReadOnlyValue(`kaclvl_${label}`, value)
            }
        </div>
    }
    private renderProperties = () => {
        const d = this.props.descriptor;
        const keys = Object.keys(d.properties);

        const owner = keys.filter(k => k.startsWith('jrws.owner.') || k.startsWith('jrws.subresource.'));
        const repository = keys.filter(k => k.startsWith('jrws.repository.'));

        let dif = _.differenceWith(keys, owner, _.isEqual);
        dif = _.differenceWith(dif, repository, _.isEqual);

        return <>
            {this.renderGroupOfProperties(owner.sort().reverse(), i18n.t('jrws.owner.propLabel'))}
            {this.renderGroupOfProperties(repository.sort().reverse(), i18n.t('jrws.repository.propLabel'))}
            {dif.map(key => {
                const v = this.state.properties && this.state.properties[key] ? this.state.properties[key] : d.properties[key];
                return this.renderProperty(key, v);
            })}
        </>
    }
    private renderGroupOfProperties = (keys: string[], label: string) => {
        const d = this.props.descriptor;
        return <>
            <div style={{ display: 'flex', paddingTop: '16px' }} className='jr-MuiPaper-root jr-MuiAccordion-root jr-mAccordion mui jr-mAccordionSmall  jr-MuiPaper-elevation0' >
                <div style={{ flex: 1, justifyContent: 'flex-end', cursor: 'default' }} className='jr-MuiButtonBase-root jr-MuiAccordionSummary-root jr-mAccordion-title mui jr-mAccordion-titleShaded'>
                    <div className='jr-MuiAccordionSummary-content jr-mAccordion-title-text mui ' >
                        <Typography style={{ textTransform: 'capitalize' }}>{label}</Typography>
                    </div>
                </div>
            </div>
            {keys.map(key => {
                const v = this.state.properties && this.state.properties[key] ? this.state.properties[key] : d.properties[key];
                return this.renderProperty(key, v);
            })}
        </>
    }

    private renderProperty = (label: string, value: any) => {
        return <div key={`kpdiv_${label}`} style={{ display: 'flex', alignItems: 'center', height: 22, marginBottom: 8, paddingLeft: '1em' }} className='jr-mInputSmall mui'>
            {this.renderPropertyLabel(label, 'kpsd_')}
            {this.isEditable(label) ?
                <div style={{ flex: 6, display: 'flex', alignItems: 'center' }}>
                    <TextInput
                        key={`kp_${label}`}
                        id="resource-name"
                        value={this.state.properties[label]}
                        onChange={value => this.onPropertyChange(label, value)}
                        validator={value => this.propValidator(label, value)}
                        WrapperProps={{ style: { flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex' } }}
                        style={{ flex: 1, margin: 0 }}
                    />
                </div>
                : this.renderReadOnlyValue(`kpd_${label}`, value)
            }
        </div>
    }

    private renderPropertyLabel = (label: string, prefix: string) => {
        return <div key={`${prefix}${label}`} className='jr-MuiTypography-root jr-MuiFormControlLabel-label jr-mInput-label mui jr-MuiTypography-body1' style={{ marginRight: '1em', color: 'gray', flex: 2 }}>{i18n.t(label)}</div>;
    }

    private renderReadOnlyValue(key: string, value: any) {
        return <div key={key} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '12px', flex: 6 }} title={value}>{value}</div>;
    }

    private renderField = (label: string, value: string) => {
        return <div style={{ display: 'flex', alignItems: 'center', height: 22, marginBottom: 8, paddingLeft: '1em' }} className='jr-mInputSmall mui'>
            <div className='jr-MuiTypography-root jr-MuiFormControlLabel-label jr-mInput-label mui jr-MuiTypography-body1' style={{ marginRight: '1em', color: 'gray', flex: 1 }}>{label}</div>
            {this.renderReadOnlyValue(label, value)}
        </div>
    }

    private renderPermission() {
        if (this.props.descriptor.permission && RepositoryApi.inst().field().isAvailable(this.props.descriptor, 'permission')) {
            const perm = RepositoryApi.inst().field().decode('permission', `${this.props.descriptor.permission}`)?.split(',').map(p => {
                return i18n.t(p);
            }).join(', ');
            return <>{this.renderField(i18n.t('resource.permission.label'), perm)}</>;
        }
    }

    private renderOwner() {
        if (this.props.descriptor.permission && RepositoryApi.inst().field().isAvailable(this.props.descriptor, 'owner'))
            return this.renderField(i18n.t('resource.owner'), this.props.descriptor.owner);
    }

    private renderSize() {
        if (this.props.descriptor.size !== undefined && RepositoryApi.inst().field().isAvailable(this.props.descriptor, 'size'))
            return this.renderField(i18n.t('resource.size'), `${sizeOf(this.props.descriptor.size)}`);
    }

    private renderName() {
        return this.isEditable('name') ?
            <div style={{ display: 'flex', height: 22, marginBottom: 8, alignItems: 'center', paddingLeft: '1em' }} className='jr-mInputSmall mui'>
                <div className='jr-MuiTypography-root jr-MuiFormControlLabel-label jr-mInput-label mui jr-MuiTypography-body1' style={{ marginRight: '1em', color: 'gray', flex: 1 }}>{i18n.t('resource.name')}</div>
                <div style={{ flex: 6, display: 'flex', alignItems: 'center' }}>
                    <TextInput
                        id="resource-name"
                        value={this.state.name}
                        onChange={this.onNameChange}
                        validator={this.nameValidator}
                        WrapperProps={{ style: { flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex' } }}
                        style={{ flex: 1, margin: 0 }}
                    />
                </div>
            </div>
            : <> {this.renderField(i18n.t('resource.name'), this.props.descriptor.name)}</>;
    }

    private renderDescription() {
        if (RepositoryApi.inst().field().isAvailable(this.props.descriptor, 'description')) {
            return this.isEditable('description') ?
                <div style={{ display: 'flex', marginBottom: 8, alignItems: 'center', paddingLeft: '1em' }} className='jr-mInputSmall mui'>
                    <div className='jr-MuiTypography-root jr-MuiFormControlLabel-label jr-mInput-label mui jr-MuiTypography-body1' style={{ marginRight: '1em', color: 'gray', flex: 1 }}>{i18n.t('resource.description')}</div>
                    <div style={{ flex: 6, display: 'flex', alignItems: 'center' }}>
                        <TextareaAutosize
                            style={{ width: '100%', resize: 'vertical' }}
                            minRows={3}
                            maxRows={5}
                            onChange={this.onDescriptionChange}
                            defaultValue={this.props.descriptor.description}
                        />
                    </div>
                </div>
                : <>{this.renderField(i18n.t('resource.description'), this.props.descriptor.description)}</>;
        }
    }

    private renderLastModified() {
        if (RepositoryApi.inst().field().isAvailable(this.props.descriptor, 'lastModified'))
            return <>{this.renderField(i18n.t('resource.lastmodified.date'), formatDateAsString(this.props.descriptor.lastModified))}</>
    }

    private renderCreatedDate() {
        if (RepositoryApi.inst().field().isAvailable(this.props.descriptor, 'created'))
            return <>{this.renderField(i18n.t('resource.created.date'), formatDateAsString(this.props.descriptor.created))}</>
    }

}