/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { PEnum } from "../../ui/primitive/UPEnum";

export const WhenNoDataTypeTableEnum = new PEnum({
    id: 'whenNoDataTypeTableEnum', label: 'WhenNoDataTypeTable.label', default: 'Blank', sinceVersion: '6.0.0',
    items: [{ key: '', value: '' },
    { key: 'Blank', value: 'WhenNoDataTypeTable.Blank' },
    { key: 'NoDataCell', value: 'WhenNoDataTypeTable.NoDataCell' },
    { key: 'AllSectionsNoDetail', value: 'WhenNoDataTypeTable.AllSectionsNoDetail' },
    ]
});

