/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from '../uxpl/button/Button';
import i18n from '../i18n';
import { IResultDialogProp } from './IDialogState';

interface IErrorDialog extends IResultDialogProp {
    message?: string,
    content?: React.ReactElement,
    onClose?: () => void,
}

export class ErrorDialog extends React.Component<IErrorDialog> {

    private onClose = () => {
        this.props.onEnd({ canceled: false });
        if (this.props.onClose){
            this.props.onClose();
        }
    }

    render() {
        return <Dialog
            open={true}
            onClose={this.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{whiteSpace: "break-spaces", minWidth: 300}}>
                    {this.props.content ? this.props.content : this.props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose} size="large" color="primary" variant="contained">{i18n.t('common.button.close')}</Button>
            </DialogActions>
        </Dialog>
    }
}