/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { FormControlledSelect, FormControlledWritableCombo } from "@jss/js-common";
import { getKey, getNormalizedPath, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { resolveAttribute } from '../../types/common/StyleResolver';
import { Map } from 'immutable';
import '../../../../../assets/uxpl/css/UPControls.css';
import i18n from '../../../../../i18n';

const TYPEID = 'enum';
reg(TYPEID, (mc) => { return <UPEnum mcontext={mc} />; });
export class PEnum extends APDescriptor {
    items: { key: string, value: string, deprecated?: boolean }[];
    default?: string;
    writeable?: boolean;
    isEmptyUndefined?: boolean;
    public constructor(init: Partial<PEnum>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

export interface IUPEnum{
    additionalIcon?: React.ReactElement, 
}

export class UPEnum extends React.Component<UiPropertyProps & IUPEnum> {

    shouldComponentUpdate(nextProps: Readonly<UiPropertyProps>): boolean {
        const d1 = this.props.mcontext.descriptor as PEnum;
        const d2 = nextProps.mcontext.descriptor as PEnum;
        const p1 = getPath(d1.id, this.props.mcontext.elements[0].path);
        const p2 = getPath(d2.id, nextProps.mcontext.elements[0].path);
        const v1 = this.props.mcontext.model.getIn(p1);
        const v2 = nextProps.mcontext.model.getIn(p2);
        return v1 !== v2;
    }

    render() {
        const d = this.props.mcontext.descriptor as PEnum;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p);
        let inheritedValue;
        if (v === undefined && this.props.mcontext.descriptor.inheritedPropertyId) {
            const elementPath = getPath(undefined, this.props.mcontext.elements[0].path).slice(0, 2);
            const element = this.props.mcontext.model.getIn(elementPath) as Map<string, any>;
            inheritedValue = resolveAttribute(this.props.mcontext.model, element, this.props.mcontext.descriptor.inheritedPropertyId);
        }

        if (inheritedValue === undefined) {
            inheritedValue = d.default;
        }
        const label = i18n.t(d.label);
        const items = d.items.map((item) => {
            return {
                ...item,
                value: i18n.t(item.value),
            }
        });
        if (v === undefined && inheritedValue === undefined && items.length > 0) {
            inheritedValue = items[0].key;
        }
        if (inheritedValue && inheritedValue.toString){
            inheritedValue = inheritedValue.toString();
        }
        if (d.writeable === true) {
            return <FormControlledWritableCombo key={getKey(p)} items={items}
                className={this.props.mcontext.descriptor.className}
                style={this.props.mcontext.descriptor.style}
                value={v}
                placeholder={inheritedValue}
                onComboChange={this.onWritableNameChange}
                label={label}
                InputLabelProps={d.deprecated ? {className: 'deprecatedProperty'} : undefined}
                disabled={d.readonly}
                inline={true}
                size="small"
                iconButtons={this.props.additionalIcon ? [this.props.additionalIcon] : undefined}
            />
        }
        const classes: string[] = [];
        if (this.props.mcontext.descriptor.className) {
            classes.push(this.props.mcontext.descriptor.className);
        }
        if (!v) {
            classes.push('placeHolderTextColor');
        }
        return <FormControlledSelect key={getKey(p)} items={items}
            className={classes.join(' ')}
            style={this.props.mcontext.descriptor.style}
            value={v ? v.toString() : inheritedValue}
            onItemSelected={this.onNameChange}
            label={label}
            disabled={d.readonly}
            InputLabelProps={d.deprecated ? {className: 'deprecatedProperty'} : undefined}
            inline={true} size={'small'}
            iconButtons={this.props.additionalIcon ? [this.props.additionalIcon] : undefined}
        />
    }
    public onNameChange = (str: string) => {
        const d = this.props.mcontext.descriptor as PEnum;
        this.props.mcontext.elements.forEach(key => {
            if (d.isEmptyUndefined && str !== undefined && str.trim().length === 0){
                const path = getNormalizedPath(key.path);
                path.push(this.props.mcontext.descriptor.id);
                this.props.mcontext.deleteElement(path);
            } else {
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: str });
            }
        });
    }
    public onWritableNameChange = (selectedKey: string | undefined, selectedText: string) => {
        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: selectedKey ? selectedKey : selectedText });
        });
    }

}