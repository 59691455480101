/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const SubreportIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/palette/subreport" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M7.5,1 L15,1 L15,1 L15,5 L19,5 L19,15.5 C19,15.7761424 18.7761424,16 18.5,16 L7.5,16 C7.22385763,16 7,15.7761424 7,15.5 L7,1.5 C7,1.22385763 7.22385763,1 7.5,1 Z" id="Rectangle" stroke="#55ADEB" strokeWidth="0.5" fill="#EEF4F9"></path>
                <path d="M15.2486572,0.75151062 C15.7806894,1.28131901 16.4012097,1.90111426 17.1102179,2.61089638 C17.8192262,3.3206785 18.5318104,4.03400296 19.2479706,4.75086975 L19.2479706,4.98822223 L15.0522142,4.98822223 L15.0522142,0.75151062 L15.2486572,0.75151062 Z" id="Rectangle" fill="#55ADEB"></path>
                <rect id="Rectangle" fill="#0081CB" x="9" y="5" width="2" height="9"></rect>
                <rect id="Rectangle-Copy" fill="#249490" x="12" y="8" width="2" height="6"></rect>
                <rect id="Rectangle-Copy-2" fill="#55ADEB" x="15" y="10" width="2" height="4"></rect>
                <path d="M4.75181991,4.93801131 L7.97285259,8.62312978 L5.62687022,8.62312978 L5.62687022,12.1231298 L3.87977265,12.1231298 L3.87977265,8.62312978 L1.52734125,8.62312978 L4.75181991,4.93801131 Z" id="Triangle" stroke="#FFFFFF" strokeWidth="0.25" fill="#0081CB" transform="translate(4.750000, 8.498130) rotate(90.000000) translate(-4.750000, -8.498130) "></path>
            </g>
        </svg>
    );
}
export default SubreportIcon;