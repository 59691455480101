/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import { PSelectedType } from "../../../ui/composite/UPSelectedType";
import { getPath, UiPropertyProps } from "../../../ui/UiProperty";
import { MAreaChart, MXYAreaChart, MStackedAreaChart, MBarChart, MXYBarChart, MstackedBarChart, MGanttChart, MBar3DChart, MStackedBar3DChart, MBubbleChart, MCandleStickChart, MHighLowChart, MScatterChart, MTimeSeriesChart, MLineChart, MXYLineChart, MMeterChart, MThermometerChart, MPieChart, MPie3DChart, MSpiderChart, MMultiAxisChart } from "../MChart";
import { MChartValueDataset, MChartHighLowDataset, MChartTimeDataset, MChartPieDataset, MChartXYDataset, MChartGanttDataset, MChartXYZDataset, MChartTimePeriodDataset, MChartSpiderDataset, MChartCategoryDataset } from "../MJFreeChart";
import { MAreaPlot } from "../plot/MAreaPlot";
import { MBar3DPlot } from "../plot/MBar3DPlot";
import { MBarPlot } from "../plot/MBarPlot";
import { MBubblePlot } from "../plot/MBubblePlot";
import { MCandlestickPlot } from "../plot/MCandlestickPlot";
import { MMultiAxisPlot } from "../plot/MChartPlot";
import { MHighLowPlot } from "../plot/MHighLowPlot";
import { MLinePlot } from "../plot/MLinePlot";
import { MMeterPlot } from "../plot/MMeterPlot";
import { MPie3DPlot } from "../plot/MPie3DPlot";
import { MPiePlot } from "../plot/MPiePlot";
import { MSpiderPlot } from "../plot/MSpiderPlot";
import { MThermometerPlot } from "../plot/MThermometerPlot";

export class PChartPlots extends PSelectedType {

    public getSelected = (props: UiPropertyProps): APDescriptor | undefined => {
        const pac = props.mcontext.descriptor as PSelectedType;
        const p = getPath(pac.selectionField ? pac.selectionField : pac.id, props.mcontext.elements[0].path);
        const v: string = props.mcontext.model.getIn(p) as string;
        let r;
        switch (v) {
            case MAreaChart.id:
            case MXYAreaChart.id:
            case MStackedAreaChart.id:
                r = MAreaPlot;
                break;
            case MBarChart.id:
            case MXYBarChart.id:
            case MstackedBarChart.id:
            case MGanttChart.id:
                r = MBarPlot;
                break;
            case MBar3DChart.id:
            case MStackedBar3DChart.id:
                r = MBar3DPlot;
                break;
            case MBubbleChart.id: r = MBubblePlot; break;
            case MCandleStickChart.id: r = MCandlestickPlot; break;
            case MHighLowChart.id: r = MHighLowPlot; break;
            case MScatterChart.id:
            case MTimeSeriesChart.id:
            case MLineChart.id:
            case MXYLineChart.id:
                r = MLinePlot;
                break;
            case MMeterChart.id: r = MMeterPlot; break;
            case MThermometerChart.id: r = MThermometerPlot; break;
            case MPieChart.id: r = MPiePlot; break;
            case MPie3DChart.id: r = MPie3DPlot; break;
            case MSpiderChart.id: r = MSpiderPlot; break;
            case MMultiAxisChart.id: r = MMultiAxisPlot; break;
        }
        if (r) {
            return pac.layouts.find(k => { return k.type === r.type });
        }
    }

    public constructor(init: Partial<PChartPlots>) {
        super(init);
        Object.assign(this, {
            ...init, label: 'Plot',
            selectionField: 'CHART_TYPE', layouts: [MAreaPlot, MBarPlot, MBar3DPlot, MBubblePlot, MCandlestickPlot, MHighLowPlot, MLinePlot, MMeterPlot, MThermometerPlot, MPiePlot, MPie3DPlot, MMultiAxisPlot]
        });
    }
}

export class PChartDatasets extends PSelectedType {
    mapping?: any = {};

    public getSelected = (props: UiPropertyProps): APDescriptor | undefined => {
        const pac = props.mcontext.descriptor as PSelectedType;
        const p = getPath(pac.selectionField ? pac.selectionField : pac.id, props.mcontext.elements[0].path);
        const v: string = props.mcontext.model.getIn(p) as string;
        let r = this.mapping[v];
        if (!r)
            switch (v) {
                case MMeterChart.id:
                case MThermometerChart.id:
                    r = MChartValueDataset; break;
                case MHighLowChart.id: r = MChartHighLowDataset; break;
                case MTimeSeriesChart.id: r = MChartTimeDataset; break;
                case MPieChart.id:
                case MPie3DChart.id:
                    r = MChartPieDataset; break;
                case MScatterChart.id:
                case MXYAreaChart.id:
                case MXYLineChart.id:
                    r = MChartXYDataset; break;
                case MGanttChart.id: r = MChartGanttDataset; break;
                case MBubbleChart.id: r = MChartXYZDataset; break;
                case MXYBarChart.id:
                    if (props.mcontext.model.getIn(getPath('timeSeriesDataset', props.mcontext.elements[0].path))) {
                        r = MChartTimeDataset; break;
                    }
                    if (props.mcontext.model.getIn(getPath('timePeriodDataset', props.mcontext.elements[0].path))) {
                        r = MChartTimePeriodDataset; break;
                    }
                    if (props.mcontext.model.getIn(getPath('xyDataset', props.mcontext.elements[0].path))) {
                        r = MChartXYDataset; break;
                    }
                    break;
                case MSpiderChart.id: r = MChartSpiderDataset; break;
                default:
                    r = MChartCategoryDataset; break;
            }
        if (r) {
            return pac.layouts.find(k => { return k.type === r.type });
        }
    }

    public constructor(init: Partial<PChartDatasets>) {
        super(init);
        Object.assign(this, { ...init, label: 'DATA', selectionField: 'CHART_TYPE' });
    }
}