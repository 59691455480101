/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../../../reducers';
import { APDescriptor } from '../../ui/APDescriptor';
import { reg, UiProperty, UiPropertyProps } from '../../ui/UiProperty';
import { List, Map as IMap } from 'immutable';
import { PString } from '../../ui/primitive/UPString';
import { PDsValueExpression } from '../../ui/primitive/UPValueExpession';
import { PAccordeon } from '../../ui/composite/UPAccordeon';
import { PInt } from '../../ui/primitive/UPInteger';
import { PBoolean } from '../../ui/primitive/UPBoolean';
import { Locale } from '../enums/Locale';
import { TimeZone } from '../enums/TimeZone';
import { PropertyEvaluationTimeEnum } from '../enums/PropertyEvaluationTimeEnum';
import { ParameterEvaluationTimeEnum } from '../enums/ParameterEvaluationTimeEnum';
import { TransactionIsolation } from '../enums/TransactionIsolation';
import { ClientJobAlertStateEnum } from '../enums/ClientJobAlertState';
import { ClientJobAlertRecipientEnum } from '../enums/ClientJobAlertRecipient';
import { ClientMailNotificationSendTypeEnum } from '../enums/ClientMailNotificationSendType';
import { FtpTypeEnum } from '../enums/FtpType';
import { PConnectedValidatedPath } from '../../ui/primitive/UPConnectedValidatedPath';
import { MIME_TYPES } from '@jss/js-rest-api';
import { DsObject, PDsObject } from './UPDsObject';

interface IDatasetProeperties extends UiPropertyProps {
    report?: IMap<string, any>,
    selectedDataset?: string,
    datasetProperties?: List<any>
}

const TYPEID = 'BDynProperties';
reg(TYPEID, (mc) => { return <UPDynProperties mcontext={mc} />; });

export class PDynProperties extends APDescriptor {

    propspath: string;
    propsname?: string;

    public constructor(init: Partial<PDynProperties>) {
        super();
        Object.assign(this, { ...init, type: TYPEID, custom: true });
    }
}

class UPDynProperties extends React.Component<IDatasetProeperties> {

    render() {
        let className = "jr-jrws-properties-content";
        if (this.props.mcontext.descriptor.className) {
            className += " " + this.props.mcontext.descriptor.className;
        }
        return <div
            style={this.props.mcontext.descriptor.style ? this.props.mcontext.descriptor.style : { display: 'flex', flexDirection: 'column', flex: 1 }}
            className={className}>

            {this.getPDescriptors().map((key, index) => {
                key.readonly = this.props.mcontext.descriptor.readonly;
                return <UiProperty key={key.id ? key.id + '.' + index : 'div.' + key.type + '.' + index} mcontext={{ ...this.props.mcontext, descriptor: key }} />
            })}
        </div>
    }

    private getPDescriptors = (): APDescriptor[] => {
        const d = this.props.mcontext.descriptor as PDynProperties;
        const pdesc = [] as APDescriptor[];

        if (this.props.datasetProperties) {

            const dsname = this.getDatasetName();
            const allprops = this.props.datasetProperties.find(k => {
                return k.get('name') === dsname;
            });
            let props;
            if (allprops) {
                if (d.propsname) {
                    const namepath = [...this.props.mcontext.elements[0].path];
                    namepath.push(d.propsname);
                    const nm = this.props.mcontext.model.getIn(namepath);
                    props = allprops.getIn([d.propspath, nm]);
                } else
                    props = allprops.get(d.propspath);
            }
            if (props) {
                const properties = props.toJS();
                // create map of categories
                const m = new Map<string, APDescriptor>();

                properties.forEach(k => {
                    if (!k.scopes.some(s => s.toUpperCase() === d.propspath.toUpperCase()))
                        return;

                    let cat = m.get(k.category);
                    if (!cat) {
                        const label = k.category.substr('net.sf.jasperreports.metadata.property.category'.length + 1).replaceAll('.', ' ');

                        cat = new PAccordeon({ id: k.category, label: label, layouts: [], path: 'properties' });
                        m.set(k.category, cat);
                    }

                    let vt;
                    const defvalue = k.defaultValue !== 'N/A' ? k.defaultValue : '';
                    const desc = `${k.name}\n\n${k.description}`;
                    const common = { id: k.name, label: k.label, default: defvalue, deprecated: k.deprecated, description: desc, sinceVersion: k.sinceVersion }
                    switch (k.valueType) {
                        case 'java.lang.String':
                            vt = new PString({ ...common });
                            break;
                        case 'java.lang.Byte':
                        case 'java.lang.Short':
                        case 'java.lang.Integer':
                        case 'java.lang.Long':
                        case 'java.math.BigInteger':
                        case 'java.lang.Integer':
                        case 'java.lang.Double':
                        case 'java.lang.Float':
                        case 'java.math.BigDecimal':
                            vt = new PInt({ ...common });
                            break;
                        case 'java.lang.Boolean':
                            vt = new PBoolean({ ...common });
                            break;
                        case 'java.util.Locale':
                            vt = { ...Locale, ...common };
                            break;
                        case 'java.util.TimeZone':
                            vt = { ...TimeZone, ...common };
                            break;
                        case 'net.sf.jasperreports.engine.type.PropertyEvaluationTimeEnum':
                            vt = { ...PropertyEvaluationTimeEnum, ...common };
                            break;
                        case 'net.sf.jasperreports.engine.type.ParameterEvaluationTimeEnum':
                            vt = { ...ParameterEvaluationTimeEnum, ...common };
                            break;
                        case 'net.sf.jasperreports.data.jdbc.TransactionIsolation':
                            vt = { ...TransactionIsolation, ...common };
                            break;
                        case 'com.jaspersoft.jasperserver.dto.job.ClientJobAlertState':
                            vt = { ...ClientJobAlertStateEnum };
                            break;
                        case 'com.jaspersoft.jasperserver.dto.job.ClientJobAlertRecipient':
                            vt = { ...ClientJobAlertRecipientEnum };
                            break;
                        case 'com.jaspersoft.jasperserver.dto.job.ClientMailNotificationSendType':
                            vt = { ...ClientMailNotificationSendTypeEnum };
                            break;
                        case 'com.jaspersoft.jasperserver.dto.connection.FtpConnection.FtpType':
                            vt = { ...FtpTypeEnum };
                            break;
                        case 'net.sf.jasperreports.engine.JRVariable':
                            vt = new PDsObject({ ...common, types: [DsObject.VARIABLE] });
                            break;
                        case 'net.sf.jasperreports.engine.JRParameter':
                            vt = new PDsObject({ ...common, types: [DsObject.PARAMETER] });
                            break;
                        case 'net.sf.jasperreports.engine.JRField':
                            vt = new PDsObject({ ...common, types: [DsObject.FIELD] });
                            break;
                        case 'net.sf.jasperreports.engine.JRGroup':
                            vt = new PDsObject({ ...common, types: [DsObject.GROUP] });
                            break;
                        case 'com.jaspersoft.jasperreports.data.jrs.JrsDataAdapter':
                            vt = new PConnectedValidatedPath({ allowedMimes: [MIME_TYPES.DATA_ADAPTER_JRS], ...common });
                            break;
                        case 'java.nio.file.Path':
                            vt = new PConnectedValidatedPath({ ...common });
                            break;
                        case 'com.jaspersoft.jasperserver.dto.resources.ClientReportUnit':
                            vt = new PConnectedValidatedPath({ allowedMimes: ['reportUnit'], ...common });
                            break;
                        case 'com.jaspersoft.jasperserver.dto.resources.ClientFolder':
                            vt = new PConnectedValidatedPath({ allowedMimes: [MIME_TYPES.FOLDER], ...common });
                            break;
                    }
                    if (vt) {
                        cat.layouts.push(new PDsValueExpression({ custom: true, onlyValue: k.valueOnly, id: k.name, label: k.label, description: desc, vtype: vt }));
                    }
                })
                for (const value of m.values()) {
                    value.layouts?.sort((a, b) => a.id.localeCompare(b.id));
                    pdesc.push(value);
                }
                pdesc.sort((a, b) => a.id.localeCompare(b.id));
            }
        }
        return pdesc;
    }

    private getDatasetName = (): string => {
        if (this.props.selectedDataset !== undefined && this.props.selectedDataset !== 'main') {
            const ds = this.props.report.getIn(['model', 'subdatasets', this.props.selectedDataset]) as Immutable.Collection<string, any>;
            return ds.get('name');
        }
        return 'main';
    }

}

const mapStateToProps = (state: IState) => {
    return {
        report: state.getIn(['report']),
        selectedDataset: state.getIn(['report', 'datasetSelection'], 'main'),
        datasetProperties: state.getIn(['report', 'cachedData', 'comboValues', 'datasetProperties'])
    };
}


export default connect(mapStateToProps)(UPDynProperties);