/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const OverflowTypeEnum = new PEnum({
    id: 'overflowType', label: 'OverflowType.label', description: 'OverflowType.description', default: 'Stretch',
    items: [
        { key: 'Stretch', value: 'OverflowType.Stretch'},
        { key: 'NoStretch', value: 'OverflowType.NoStretch'}
    ]
}); 
 
