/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { List, Map } from "immutable";
import { HTML5CHART_NAMESPACE } from "../reader/JrxmlHTML5ChartUtils";
import { createDatasetRun, createReportElement, setAttributeIfPresent, setExpressionNode, setExpressionNodeFromMap, setXMLAttributeIfPresent } from "./JrxmlHelper";


/**
 * Creates the chart settings information of the HTML5 chart for the final JRXML.
 *
 * @param elementNode the JSON map containing the HTML5 chart details
 * @param xmlDocument the XML document
 * @param html5ChartElement the HTML5 chart JRXML part
 * @param namespace the namespace used for HTML5 charts
 */
const createChartSettings = (elementNode: Map<string, any>, xmlDocument: Document, html5ChartElement: HTMLElement, namespace: string) => {
    const chartSettings: Map<string, Map<string, any>> = elementNode.get("chartSettings", null);
    if (chartSettings !== null) {
        chartSettings.forEach((value, key) => {
            const currentSettings = xmlDocument.createElement(namespace+"chartSetting");
            currentSettings.setAttribute("name", key as string);
            (value as Map<string, any>).forEach((innerValue, innerKey) => {
                const property = xmlDocument.createElement(namespace+"chartProperty");
                property.setAttribute("name", innerKey as string);
                setAttributeIfPresent(innerValue, "value", property);
                setExpressionNode(property, xmlDocument, (innerValue as Map<string, any>).get("valueExpression", null), namespace+"propertyExpression");
                currentSettings.appendChild(property);
            });
            html5ChartElement.appendChild(currentSettings);
        });
    }
}

/**
 * Creates the hyperlink information of the HTML5 chart for the final JRXML.
 *
 * @param elementNode the JSON map containing the HTML5 chart details
 * @param xmlDocument the XML document
 * @param html5ChartElement the HTML5 chart JRXML part
 * @param namespace the namespace used for HTML5 charts
 */
const createHyperlinkDetails = (elementNode: Map<string, any>, xmlDocument: Document, html5ChartElement: HTMLElement, namespace: string) => {
    const hyperlinkDetails: Map<string, any> = elementNode.get("hyperlink", null);
    if (hyperlinkDetails !== null) {
        const hyperlinkElement = xmlDocument.createElement(namespace+"hyperlink");
        setAttributeIfPresent(hyperlinkDetails, "hyperlinkType", hyperlinkElement);
        setAttributeIfPresent(hyperlinkDetails, "hyperlinkTarget", hyperlinkElement);
        setExpressionNode(hyperlinkElement, xmlDocument, hyperlinkDetails.get("hyperlinkReferenceExpression"), "hyperlinkReferenceExpression");
        setExpressionNode(hyperlinkElement, xmlDocument, hyperlinkDetails.get("hyperlinkAnchorExpression"), "hyperlinkAnchorExpression");
        setExpressionNode(hyperlinkElement, xmlDocument, hyperlinkDetails.get("hyperlinkPageExpression"), "hyperlinkPageExpression");
        setExpressionNode(hyperlinkElement, xmlDocument, hyperlinkDetails.get("hyperlinkTooltipExpression"), "hyperlinkTooltipExpression");

        const hyperlinkParameters: List<{ name: string, value: string }> = hyperlinkDetails.get("hyperlinkParameters", null);
        if (hyperlinkParameters !== null) {
            hyperlinkParameters.forEach((propertyValue: { name: string, value: string }) => {
                const hyperlinkParameterElement = xmlDocument.createElement("hyperlinkParameter");
                hyperlinkParameterElement.setAttribute("name", String(propertyValue.name));
                setExpressionNode(hyperlinkParameterElement, xmlDocument, propertyValue.value, "hyperlinkParameterExpression");
                hyperlinkElement.appendChild(hyperlinkParameterElement);
            });
        }
        html5ChartElement.appendChild(hyperlinkElement);
    }
}

/**
 * Creates the chart series information of the HTML5 chart for the final JRXML.
 *
 * @param elementNode the JSON map containing the HTML5 chart details
 * @param xmlDocument the XML document
 * @param html5ChartElement the HTML5 chart JRXML part
 * @param namespace the namespace used for HTML5 charts
 */
const createChartSeries = (elementNode: Map<string, any>, xmlDocument: Document, html5ChartElement: HTMLElement, namespace: string) => {
    const allChartSeries: Map<string, any> = elementNode.get("chartSeries", null);
    if (allChartSeries !== null) {
        allChartSeries.forEach((value, key) => {
            const currentSeries = xmlDocument.createElement(namespace+"series");
            currentSeries.setAttribute("name", key as string);
            setAttributeIfPresent(value, "visible", currentSeries);

            const contributors = (value as Map<string, any>).get("contributors", null);
            if (contributors !== null) {
                (contributors as Map<string, any>).forEach((innerValue, innerKey) => {
                    const currentContributor = xmlDocument.createElement((namespace+"contributor"));
                    currentContributor.setAttribute("name", innerKey as string);
                    const currentContributorProperties = (innerValue as Map<string, any>);
                    currentContributorProperties.forEach((propertyDetails, propertyName) => {
                        const contributorProperty = xmlDocument.createElement((namespace+"contributorProperty"));
                        contributorProperty.setAttribute("name", propertyName as string);
                        setAttributeIfPresent(propertyDetails, "valueType", contributorProperty);
                        setAttributeIfPresent(propertyDetails, "value", contributorProperty);
                        setExpressionNode(contributorProperty, xmlDocument, propertyDetails.get("valueExpression", null), namespace+"valueExpression");
                        currentContributor.appendChild(contributorProperty);
                    });
                    currentSeries.appendChild(currentContributor);
                });
            }
            html5ChartElement.appendChild(currentSeries);
        });
    }
}

/**
 * Creates the multi axis dataset information of the HTML5 chart for the final JRXML.
 *
 * @param multiAxisDataDetails the JSON map containing the multi axis data information
 * @param xmlDocument the XML document
 * @param multiAxisDataElement the multi axis data JRXML part
 */
const createMultiAxisDataset = (multiAxisDataDetails: Map<string, any>, xmlDocument: Document, multiAxisDataElement: HTMLElement) => {
    const multiAxisDatasetDetails = multiAxisDataDetails.get("multiAxisDataset", undefined);
    if (multiAxisDatasetDetails !== null) {
        const multiAxisDatasetElement = xmlDocument.createElement("multiAxisDataset");
        const datasetElement = xmlDocument.createElement("dataset");
        setAttributeIfPresent(multiAxisDatasetDetails, "resetType", datasetElement);
        setAttributeIfPresent(multiAxisDatasetDetails, "resetGroup", datasetElement);
        setAttributeIfPresent(multiAxisDatasetDetails, "incrementType", datasetElement);
        setAttributeIfPresent(multiAxisDatasetDetails, "incrementGroup", datasetElement);
        setExpressionNodeFromMap(datasetElement, xmlDocument, multiAxisDatasetDetails, "incrementWhenExpression");
        const datasetRunNode: Map<string, any> = multiAxisDatasetDetails.get("datasetRun", null);
        if (datasetRunNode !== null && !datasetRunNode.isEmpty()) {
            datasetElement.appendChild(createDatasetRun(datasetRunNode, xmlDocument));
        }
        multiAxisDatasetElement.appendChild(datasetElement);
        multiAxisDataElement.appendChild(multiAxisDatasetElement);
    } else if (multiAxisDatasetDetails === null) {
        const multiAxisDatasetElement = xmlDocument.createElement("multiAxisDataset");
        multiAxisDataElement.appendChild(multiAxisDatasetElement);
    }
}

/**
 * Creates the data axis information of the HTML5 chart for the final JRXML.
 *
 * @param multiAxisDataDetails the JSON map containing the multi axis data information
 * @param xmlDocument the XML document
 * @param multiAxisDataElement the multi axis data JRXML part
 * @param dataAxisType the type of data axis being created
 */
const createDataAxis = (multiAxisDataDetails: Map<string, any>, xmlDocument: Document, multiAxisDataElement: HTMLElement, dataAxisType: string) => {
    const dataAxisDetails : List<Map<string,any>> = multiAxisDataDetails.getIn(['dataAxes', dataAxisType], null) as List<Map<string, any>>;
    if(dataAxisDetails!==null){
        const dataAxisElement = xmlDocument.createElement("dataAxis");
        dataAxisElement.setAttribute("axis", dataAxisType);
        dataAxisDetails.forEach((axisLevelDetails: Map<string,any>) => {
            const levelElement = xmlDocument.createElement("axisLevel");
            levelElement.setAttribute("name", axisLevelDetails.get('axisLevelName'));
            setExpressionNodeFromMap(levelElement,xmlDocument,axisLevelDetails,"labelExpression");
            const levelBucketDetails : Map<string,any> = axisLevelDetails.get("axisLevelBucket",null);
            if(levelBucketDetails!==null){
                const levelBucketElement = xmlDocument.createElement("axisLevelBucket");
                setAttributeIfPresent(levelBucketDetails,"class",levelBucketElement);
                setAttributeIfPresent(levelBucketDetails,"order",levelBucketElement);
                setExpressionNodeFromMap(levelBucketElement,xmlDocument,levelBucketDetails,"bucketExpression");
                setExpressionNodeFromMap(levelBucketElement,xmlDocument,levelBucketDetails,"labelExpression");
                setExpressionNodeFromMap(levelBucketElement,xmlDocument,levelBucketDetails,"comparatorExpression");

                const bucketPropertiesDetails : Map<string,string> = levelBucketDetails.get("bucketProperties", null);
                if(bucketPropertiesDetails!==null){
                    bucketPropertiesDetails.forEach((propertyValue: string,propertyName: string) => {
                        const bucketPropertyElement = xmlDocument.createElement("bucketProperty");
                        bucketPropertyElement.setAttribute("name", propertyName);
                        bucketPropertyElement.appendChild(xmlDocument.createCDATASection(propertyValue));
                        levelBucketElement.appendChild(bucketPropertyElement);
                    });
                }
                levelElement.appendChild(levelBucketElement);
            }
            dataAxisElement.appendChild(levelElement);
        });
        multiAxisDataElement.appendChild(dataAxisElement);
    }
}

/**
 * Creates the measure information of the HTML5 chart for the final JRXML.
 *
 * @param elementNode the JSON map containing the HTML5 chart details
 * @param xmlDocument the XML document
 * @param multiAxisDataElement the multi axis data JRXML part
 */
const createMultiAxisMeasures = (elementNode: Map<string, any>, xmlDocument: Document, multiAxisDataElement: HTMLElement) => {
    const multiAxisMeasures : List<Map<string,any>>= elementNode.get("multiAxisMeasures",null);
    if(multiAxisMeasures!==null){
        multiAxisMeasures.forEach((measureDetails: Map<string,any>) => {
            const measureElement = xmlDocument.createElement("multiAxisMeasure");
            measureElement.setAttribute("name", measureDetails.get('measureName'));
            setAttributeIfPresent(measureDetails,"class",measureElement);
            setAttributeIfPresent(measureDetails,"calculation",measureElement);
            setAttributeIfPresent(measureDetails,"incrementerFactoryClass",measureElement);
            setExpressionNodeFromMap(measureElement,xmlDocument,measureDetails,"labelExpression");
            setExpressionNodeFromMap(measureElement,xmlDocument,measureDetails, "valueExpression");
            multiAxisDataElement.appendChild(measureElement);
        });
    }
}

/**
 * Creates the multi axis data information of the HTML5 chart for the final JRXML.
 *
 * @param elementNode the JSON map containing the HTML5 chart details
 * @param xmlDocument the XML document
 * @param html5ChartElement the HTML5 chart JRXML part
 */
const createMultiAxisDataNode = (elementNode: Map<string, any>, xmlDocument: Document, html5ChartElement: HTMLElement) => {
    const multiAxisDataDetails : Map<string,any> = elementNode.get("multiAxisData", null);
    if(multiAxisDataDetails!==null){
        const multiAxisDataElement = xmlDocument.createElement("multiAxisData");

        // multiAxisDataset
        createMultiAxisDataset(multiAxisDataDetails, xmlDocument, multiAxisDataElement);

        // dataAxes
        createDataAxis(multiAxisDataDetails, xmlDocument, multiAxisDataElement, "Rows");
        createDataAxis(multiAxisDataDetails, xmlDocument, multiAxisDataElement, "Columns");

        // multiAxisMeasures
        createMultiAxisMeasures(multiAxisDataDetails, xmlDocument, multiAxisDataElement);

        html5ChartElement.appendChild(multiAxisDataElement);
    }
}

/**
 * Creates the HTML5 chart information for the final JRXML.
 *
 * @param elementNode the JSON map containing the HTML5 chart details
 * @param xmlDocument the XML document
 */
export const createHTML5ChartElement = (elementNode: Map<string, any>, xmlDocument: Document): Element => {
    const componentElement = xmlDocument.createElement("componentElement");
    const reportElement = createReportElement(elementNode, xmlDocument);
    componentElement.appendChild(reportElement);

    const baseNamespace = elementNode.get(HTML5CHART_NAMESPACE, "hc");
    const namespace = baseNamespace + ":";

    const html5ChartElement = xmlDocument.createElement(namespace+"chart");

    html5ChartElement.setAttribute("xmlns:" + baseNamespace, elementNode.get("xmlns:" + baseNamespace, "http://jaspersoft.com/highchart"));
    html5ChartElement.setAttribute("xsi:schemaLocation", elementNode.get("xsi:schemaLocation", "http://jaspersoft.com/highcharts http://jaspersoft.com/schema/highcharts.xsd"));

    setAttributeIfPresent(elementNode, "evaluationTime", html5ChartElement);
    setAttributeIfPresent(elementNode, "evaluationGroup", html5ChartElement);
    setAttributeIfPresent(elementNode, "rowLevel", html5ChartElement);
    setAttributeIfPresent(elementNode, "columnLevel", html5ChartElement);
    setAttributeIfPresent(elementNode, "preferredHeight", html5ChartElement);
    setXMLAttributeIfPresent(elementNode, "chartType", "type", html5ChartElement);

    createHyperlinkDetails(elementNode, xmlDocument, html5ChartElement, namespace);
    createChartSettings(elementNode, xmlDocument, html5ChartElement, namespace);
    setExpressionNode(html5ChartElement,xmlDocument,elementNode.get("multiAxisDataSourceExpression",null),namespace+"multiAxisDataSourceExpression");
    createMultiAxisDataNode(elementNode, xmlDocument, html5ChartElement);
    createChartSeries(elementNode, xmlDocument, html5ChartElement, namespace);

    componentElement.appendChild(html5ChartElement);

    return componentElement;
}