/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const ListIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/palette/list" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M3.5,12.3 C4.05228475,12.3 4.5,12.7477153 4.5,13.3 C4.5,13.8522847 4.05228475,14.3 3.5,14.3 L3,14.3 C2.44771525,14.3 2,13.8522847 2,13.3 C2,12.7477153 2.44771525,12.3 3,12.3 L3.5,12.3 Z M3.5,7.55 C4.05228475,7.55 4.5,7.99771525 4.5,8.55 C4.5,9.10228475 4.05228475,9.55 3.5,9.55 L3,9.55 C2.44771525,9.55 2,9.10228475 2,8.55 C2,7.99771525 2.44771525,7.55 3,7.55 L3.5,7.55 Z M3.5,2.8 C4.05228475,2.8 4.5,3.24771525 4.5,3.8 C4.5,4.35228475 4.05228475,4.8 3.5,4.8 L3,4.8 C2.44771525,4.8 2,4.35228475 2,3.8 C2,3.24771525 2.44771525,2.8 3,2.8 L3.5,2.8 Z" id="Combined-Shape" fill="#0081CB"></path>
                <path d="M17.35,12.75 C17.7089851,12.75 18,13.0410149 18,13.4 C18,13.7589851 17.7089851,14.05 17.35,14.05 L6.65,14.05 C6.29101491,14.05 6,13.7589851 6,13.4 C6,13.0410149 6.29101491,12.75 6.65,12.75 L17.35,12.75 Z M17.35,8 C17.7089851,8 18,8.29101491 18,8.65 C18,9.00898509 17.7089851,9.3 17.35,9.3 L6.65,9.3 C6.29101491,9.3 6,9.00898509 6,8.65 C6,8.29101491 6.29101491,8 6.65,8 L17.35,8 Z M17.35,3.25 C17.7089851,3.25 18,3.54101491 18,3.9 C18,4.25898509 17.7089851,4.55 17.35,4.55 L6.65,4.55 C6.29101491,4.55 6,4.25898509 6,3.9 C6,3.54101491 6.29101491,3.25 6.65,3.25 L17.35,3.25 Z" id="Combined-Shape" fill="#00A9D7"></path>
            </g>
        </svg>
    );
}
export default ListIcon;