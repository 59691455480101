/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const MapIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/palette/maps" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M17.75,2 C17.8880712,2 18,2.11192881 18,2.25 L18,15.75 C18,15.8880712 17.8880712,16 17.75,16 L2.25,16 C2.11192881,16 2,15.8880712 2,15.75 L2,2.25 C2,2.11192881 2.11192881,2 2.25,2 L17.75,2 Z M17.25,2.5 L2.75,2.5 C2.61192881,2.5 2.5,2.61192881 2.5,2.75 L2.5,2.75 L2.5,15.25 C2.5,15.3880712 2.61192881,15.5 2.75,15.5 L2.75,15.5 L17.25,15.5 C17.3880712,15.5 17.5,15.3880712 17.5,15.25 L17.5,15.25 L17.5,2.75 C17.5,2.61192881 17.3880712,2.5 17.25,2.5 L17.25,2.5 Z" id="Combined-Shape" fill="#7AC1F3" transform="translate(10.000000, 9.000000) scale(1, -1) translate(-10.000000, -9.000000) "></path>
                <path d="M17.5,2.5 L17.5,15.5 L2.5,15.5 L2.5,2.5 L17.5,2.5 Z M16.5,3 L3.5,3 C3.22385763,3 3,3.22385763 3,3.5 L3,3.5 L3,14.5 C3,14.7761424 3.22385763,15 3.5,15 L3.5,15 L16.5,15 C16.7761424,15 17,14.7761424 17,14.5 L17,14.5 L17,3.5 C17,3.22385763 16.7761424,3 16.5,3 L16.5,3 Z" id="Combined-Shape" fill="#FFFFFF" transform="translate(10.000000, 9.000000) scale(1, -1) translate(-10.000000, -9.000000) "></path>
                <g id="map-elements" transform="translate(10.000430, 9.010769) scale(1, -1) translate(-10.000430, -9.010769) translate(3.000000, 3.000000)">
                    <path d="M14.0008592,2.70920139 C13.1873051,2.23753643 12.3343655,2.05250171 11.6504618,2.29716937 C11.3864248,2.38379502 10.848523,2.79002098 10.4444356,3.42590526 C10.3435456,3.58466882 10.2383296,3.85033461 10.125853,4.17040446 L10.125853,4.17040446 L9.85929,4.96022083 L9.66429901,5.52314999 C9.42767447,6.17774036 9.15757926,6.79797365 8.83828477,7.10249256 C8.34568148,7.57230027 7.50422541,7.90827382 6.79119441,8.15497578 C5.9309446,8.41406568 5.23857788,8.69313901 4.71409426,8.99219576 C3.92736884,9.44078088 3.51311319,9.71481615 3.0785401,10.1987316 C2.64396701,10.6826471 2.22205479,11.5589994 2.1,12 L2.1,12 L0,12 L0,0 L14,0 Z" id="Combined-Shape" fill="#D2F19A"></path>
                    <g id="Group" transform="translate(2.100000, 2.189125)">
                        <path d="M11.9,9.83241344 L11.9,1.68573173 C11.2541646,0.87830201 10.8162836,0.846525706 9.99262377,0.946645974 C9.16896393,1.04676624 8.84130127,1.93851918 8.66072938,2.68621039 C8.48015748,3.43390161 8.14964279,4.18874563 8.03312686,4.45356095 C7.85953117,4.81194001 7.45058101,5.37313644 6.99313523,5.80031826 C6.59421941,6.08833155 5.95467208,6.39535627 5.07449325,6.72139242 C4.76572978,6.83014342 3.96298432,7.02764925 3.68706624,7.17401358 C2.99328016,7.5420414 2.53009591,7.86671066 2.10202349,8.28721574 C1.67395107,8.70772082 1.36919519,9.15061142 1.28127224,9.81087498 C2.98295309,9.81087498 6.52252901,9.81805447 11.9,9.83241344 Z" id="blue-shape" fill="#85CAFB"></path>
                        <path d="M11.9,0.52007637 C11.9005728,0.880367293 11.9008592,1.25431298 11.9008592,1.64191343 C11.4388884,1.19778187 10.9733167,0.860878566 10.3121283,0.908612406 C9.65093987,0.956346245 9.03672943,1.46199961 8.70905203,2.60981285 C8.40675455,3.78206368 7.96768226,5.00573732 7.10323118,5.72099749 C6.2387801,6.43625765 4.96084322,6.77724814 4.35483087,6.95559873 C3.68706624,7.17401358 2.6538672,7.70502748 2.10202349,8.28721574 C1.55017978,8.869404 1.28127224,9.49779525 1.28127224,9.81087498 C1.03348389,9.81087498 0.316473389,9.81087498 0,9.81087498 C0.398138428,8.62325291 0.814418641,8.20849005 1.15424194,7.81273655 C1.49406525,7.41698306 2.48622525,6.78386091 3.21817627,6.4595261 C3.98424754,6.12007227 5.9,5.71204066 6.7374256,4.91336754 C7.46724158,4.21732433 7.9400164,1.8718345 8.34357639,1.23678024 C8.74766383,0.600895959 9.28556561,0.19467 9.54960261,0.108044347 C10.2335064,-0.136623312 11.0864459,0.0484114109 11.9,0.52007637 Z" id="white-line" fill="#FFFFFF"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}
export default MapIcon;