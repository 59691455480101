/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MGraphicElement } from "../common/MGraphicElement";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { ICONS } from "@jss/js-common";
import { EvaluationTimeEnum } from "../enums/EvaluationTimeEnum";
import { OnErrorTypeEnum } from "../enums/OnErrorTypeEnum";
import { PValueExpression } from "../../ui/primitive/UPValueExpession";
import { PInt } from "../../ui/primitive/UPInteger";
import { PString } from "../../ui/primitive/UPString";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PConnectedTableDetail } from "../../ui/tables/UPConnectedTableDetail";
import { MTibcoMapMarker } from "./MTibcoMapMarker";
import { MTibcoMapPath } from "./MTibcoMapPath";
import { MTibcoMapStyle } from "./MTibcoMapStyle";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { MTibcoMapCustom } from "./MTibcoMapCustom";
import { PImplementationCombo } from "../../ui/primitive/UPImplementationCombo";

export const FTibcoMap = {
    evaluationTime: EvaluationTimeEnum,
    onErrorType: OnErrorTypeEnum,

    latitude: new PValueExpression({ id: 'latitude', vtype: new PInt({ id: "latitude", label: 'Latitude', description: 'Marker latitude', min: -85, max: 85, default: 37.7833 }) }),
    longitude: new PValueExpression({ id: 'longitude', vtype: new PInt({ id: "longitude", label: 'Longitude', description: 'Marker longitude', min: -180, max: 180, default: -122.4167 }) }),

    country: new PValueExpression({ id: 'country', vtype: new PString({ id: "country", label: 'Country', description: 'Country' }) }),
    state: new PValueExpression({ id: 'state', vtype: new PString({ id: "state", label: 'State', description: 'State' }) }),
    zip: new PValueExpression({ id: 'zip', vtype: new PString({ id: "zip", label: 'Zip', description: 'Zip' }) }),
    city: new PValueExpression({ id: 'city', vtype: new PString({ id: "city", label: 'City', description: 'City' }) }),
    street: new PValueExpression({ id: 'street', vtype: new PString({ id: "street", label: 'Street', description: 'Street' }) }),

    customerName: new PValueExpression({ id: 'customerName', vtype: new PString({ id: "customerName", label: 'Customer Name', description: 'the customer name necessary for using the geocoding' }) }),
    customerKey: new PValueExpression({ id: 'customerKey', vtype: new PString({ id: "customerKey", label: 'Customer Key', description: 'The authorization key necessary for using the geocoding service' }) }),

    layerName: new PValueExpression({ id: 'layerName', vtype: new PString({ id: "layerName", label: 'Layer Name', description: 'The map layer name' }) }),
    markersLayerName: new PValueExpression({ id: 'markersLayerName', vtype: new PString({ id: "markersLayerName", label: 'Markers Layer Name', description: 'The markers layer name' }) }),
    pathsLayerName: new PValueExpression({ id: 'pathsLayerName', vtype: new PString({ id: "pathsLayerName", label: 'Paths Layer Name', description: 'The paths layer name' }) }),

    useCanvas: new PValueExpression({ id: 'useCanvas', vtype: new PBoolean({ id: "useCanvas", label: 'Use Canvas', description: 'Specifies whether to use canvas or not', canBeNull: true }) }),
    opacity: new PValueExpression({ id: 'opacity', vtype: new PInt({ id: "opacity", label: 'Opacity', description: 'opacity of the map layer', min: 0, default: 1 }) }),
    zoom: new PValueExpression({ id: 'zoom', vtype: new PInt({ id: "zoom", label: 'Zoom', description: 'Zoom of the map layer', min: 0, default: 5 }) }),
    maxZoom: new PValueExpression({ id: 'maxZoom', vtype: new PInt({ id: "maxZoom", label: 'Max Zoom', description: 'max zoom', min: 0, max: 185 }) }),
    repeatX: new PValueExpression({ id: 'repeatX', vtype: new PBoolean({ id: "repeatX", label: 'Repeat X', description: 'Specifies whether tiles will be repeated when the the world\'s bounds are exceeded horizontally', canBeNull: true, default: 'true' }) }),
    clipOffset: new PValueExpression({ id: 'clipOffset', vtype: new PInt({ id: "clipOffset", label: 'Clip Offset', description: 'the clip offset of the map', default: 100 }) }),
    layerscontrol: new PValueExpression({ id: 'layers.control', vtype: new PBoolean({ id: "layers.control", label: 'Layers Control', description: 'Specifies whether the layers control will be enabled on the map', canBeNull: true, default: 'true' }) }),
};

export const MTibcoMap: APDescriptor = {
    id: ElementTypes.TIBCO_MAP,
    type: ElementTypes.TIBCO_MAP,
    label: 'Tibco Map',
    icon: ICONS.TIBCOMAP_ICON,
    default: {
        MAP_XMLNS_NAMESPACE: 'c',
        'xsi:schemaLocation': "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd",
        'xmlns:c': "http://jasperreports.sourceforge.net/jasperreports/components",
        width: 400, height: 150, mapData: { latitude: { value: '45.433968f' }, longitude: { value: '12.337967f' } }
    },
    layouts: [new PScrollableCategories({
        id: 'tibcoMapPropertiesTabsDescriptor',
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MGraphicElement.layouts, MTibcoMapCustom] }),
            new PDiv({
                label: 'Tibco Map',
                layouts: [
                    new PDiv({
                        path: 'mapData',
                        layouts: [
                            FTibcoMap.evaluationTime, FTibcoMap.onErrorType,
                            new PAccordeon({
                                id: 'tibcomap.location', label: "Location", layouts: [FTibcoMap.latitude, FTibcoMap.longitude,
                                FTibcoMap.country, FTibcoMap.state, FTibcoMap.zip, FTibcoMap.city, FTibcoMap.street]
                            }),
                            new PAccordeon({
                                id: 'tibcomap.attributes', label: "Map Attributes", layouts: [FTibcoMap.useCanvas, FTibcoMap.opacity, FTibcoMap.zoom, FTibcoMap.maxZoom, FTibcoMap.repeatX, FTibcoMap.clipOffset]
                            }),
                            new PAccordeon({
                                id: 'tibcomap.layer', label: "Layers", layouts: [FTibcoMap.layerName, FTibcoMap.markersLayerName, FTibcoMap.pathsLayerName, FTibcoMap.layerscontrol]
                            }),
                            new PAccordeon({
                                id: 'tibcomap.credentials', label: "Maporama Credentials", layouts: [FTibcoMap.customerName, FTibcoMap.customerKey]
                            }),
                        ]
                    }),
                    new PImplementationCombo({ types: [{ type: ElementTypes.MAP, id: 'googleMapPropertiesDescriptor', label: 'Google Map' }, { type: ElementTypes.TIBCO_MAP, id: 'tibcoMapPropertiesDescriptor', label: 'Tibco Map' }] })
                ]
            }),
            new PDiv({
                id: 'tmkdata', label: 'Markers', layouts: [
                    new PConnectedTableDetail({
                        id: 'markerDatas', label: 'Markers By Dataset', rowLabel: 'Markers', expanded: true, layouts: [MTibcoMapMarker],
                        default: { id: 'marker-1' }
                    })]
            }),
            new PDiv({
                id: 'tpathsdata', label: 'Paths', layouts: [
                    new PConnectedTableDetail({
                        id: 'pathDatas', label: 'Paths By Dataset', rowLabel: 'Paths', expanded: true, layouts: [MTibcoMapPath],
                        default: { id: 'path-1' }
                    })]
            }),
            new PDiv({
                id: 'tstyledata', label: 'Styles', layouts: [
                    new PConnectedTableDetail({
                        id: 'pathStyles', label: 'Styles By Dataset', rowLabel: 'Styles', expanded: true, layouts: [MTibcoMapStyle],
                        default: { id: 'style-1' }
                    })]
            })
        ]
    })]
};
