/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

enum Colors {
    TRANSPARENT = 'transparent'
}

export default Colors;
