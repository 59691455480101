/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const ColumnChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath id="highcharts-cwezhrd-1">
          <path fill="none" d="M0 0H489V672H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M56 10H545V682H56z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" d="M96.5 10v672M137.5 10v672M177.5 10v672M218.5 10v672M259.5 10v672M300.5 10v672M340.5 10v672M381.5 10v672M422.5 10v672M463.5 10v672M503.5 10v672M544.5 10v672M55.5 10v672" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M56 682.5h489M56 634.5h489M56 586.5h489M56 538.5h489M56 490.5h489M56 442.5h489M56 394.5h489M56 346.5h489M56 298.5h489M56 250.5h489M56 202.5h489M56 154.5h489M56 106.5h489M56 58.5h489M56 9.5h489" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M56 10H545V682H56z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" stroke="#ccd6eb" d="M96.5 682v10M137.5 682v10M177.5 682v10M218.5 682v10M259.5 682v10M300.5 682v10M340.5 682v10M381.5 682v10M422.5 682v10M463.5 682v10M503.5 682v10M545.5 682v10M55.5 682v10" className="highcharts-tick" />
       <path fill="none" stroke="#ccd6eb" d="M56 682.5h489" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M56 10v672" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-column-series highcharts-color-0 highcharts-tracker" clipPath="url(#highcharts-cwezhrd-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="#7cb5ec" stroke="#fff" d="M8.5 545.5H15.5V672.5H8.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M49.5 606.5H56.5V672.5H49.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M89.5 589.5H96.5V672.5H89.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M171.5 662.5H178.5V672.5H171.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M293.5 589.5H300.5V672.5H293.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M334.5 666.5H341.5V672.5H334.5z" className="highcharts-point highcharts-color-0" />
          <path fill="#7cb5ec" stroke="#fff" d="M415.5 667.5H422.5V672.5H415.5z" className="highcharts-point highcharts-color-0" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-column-series highcharts-color-1 highcharts-tracker" clipPath="url(#highcharts-cwezhrd-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="#434348" stroke="#fff" d="M16.5 507.5H23.5V672.5H16.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M57.5 626.5H64.5V672.5H57.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M98.5 74.5H105.5V672.5H98.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M138.5 650.5H145.5V672.5H138.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M179.5 642.5H186.5V672.5H179.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M220.5 619.5H227.5V672.5H220.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M261.5 660.5H268.5V672.5H261.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M301.5 657.5H308.5V672.5H301.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M342.5 616.5H349.5V672.5H342.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M383.5 651.5H390.5V672.5H383.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M424.5 577.5H431.5V672.5H424.5z" className="highcharts-point highcharts-color-1" />
          <path fill="#434348" stroke="#fff" d="M464.5 668.5H471.5V672.5H464.5z" className="highcharts-point highcharts-color-1" />
       </g>
       <g className="highcharts-series highcharts-series-2 highcharts-column-series highcharts-color-2 highcharts-tracker" clipPath="url(#highcharts-cwezhrd-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="#90ed7d" stroke="#fff" d="M24.5 555.5H31.5V672.5H24.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M65.5 595.5H72.5V672.5H65.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M106.5 69.5H113.5V672.5H106.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M147.5 669.5H154.5V672.5H147.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M228.5 522.5H235.5V672.5H228.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M269.5 671.5H276.5V672.5H269.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M310.5 662.5H317.5V672.5H310.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M350.5 668.5H357.5V672.5H350.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M391.5 655.5H398.5V672.5H391.5z" className="highcharts-point highcharts-color-2" />
          <path fill="#90ed7d" stroke="#fff" d="M432.5 513.5H439.5V672.5H432.5z" className="highcharts-point highcharts-color-2" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(178 762)">
       <rect width="199" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-column-series highcharts-color-0 highcharts-series-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1996</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-column-series highcharts-color-1 highcharts-series-1" data-z-index="1" transform="translate(75.672 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1997</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-column-series highcharts-color-2 highcharts-series-2" data-z-index="1" transform="translate(143.344 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1998</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#90ed7d" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="78.968" y="698" transform="rotate(-45 78.968 698)">Albuquerque</text>
       <text x="119.718" y="698" transform="rotate(-45 119.718 698)">Anchorage</text>
       <text x="160.468" y="698" transform="rotate(-45 160.468 698)">Boise</text>
       <text x="201.218" y="698" transform="rotate(-45 201.218 698)">Butte</text>
       <text x="241.968" y="698" transform="rotate(-45 241.968 698)">Elgin</text>
       <text x="282.718" y="698" transform="rotate(-45 282.718 698)">Eugene</text>
       <text x="323.468" y="698" transform="rotate(-45 323.468 698)">Kirkland</text>
       <text x="364.218" y="698" transform="rotate(-45 364.218 698)">Lander</text>
       <text x="404.968" y="698" transform="rotate(-45 404.968 698)">Portland</text>
       <text x="445.718" y="698" transform="rotate(-45 445.718 698)">
          <tspan>San Francisco</tspan>
       </text>
       <text x="486.468" y="698" transform="rotate(-45 486.468 698)">Seattle</text>
       <text x="527.218" y="698" transform="rotate(-45 527.218 698)">
          <tspan>Walla Walla</tspan>
       </text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="41" y="687">0</text>
       <text x="41" y="639">250</text>
       <text x="41" y="591">500</text>
       <text x="41" y="543">750</text>
       <text x="41" y="495">1.000</text>
       <text x="41" y="447">1.250</text>
       <text x="41" y="399">1.500</text>
       <text x="41" y="351">1.750</text>
       <text x="41" y="303">2.000</text>
       <text x="41" y="255">2.250</text>
       <text x="41" y="207">2.500</text>
       <text x="41" y="159">2.750</text>
       <text x="41" y="111">3.000</text>
       <text x="41" y="63">3.250</text>
       <text x="41" y="15">3.500</text>
    </g>
 </svg>)};

export default ColumnChartIcon;