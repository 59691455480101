/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { EmptyView } from '@jss/js-common';
import { IRepositoryItemDescriptor, RepositoryApi, RESOURCE_TYPE } from '@jss/js-rest-api';
import * as React from 'react';
import '../assets/uxpl/css/Repository.css';
import { ResourcesTable } from '../views/ResourcesTable';
import { PathBreadcrumb } from '../views/PathBreadcrumb';
import { SearchFile } from '../views/SearchFile';
import { RunContext } from '../RunContainer';
import { ResourceContextMenu } from '../views/detail/ResourceContextMenu';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import i18n from "../i18n";
import _ from 'lodash';
import { ResourceSorting } from '@jss/js-rest-api';
import { getUUID, last } from './repositoryUtils';

enum Mode { FILES, SEARCH }

interface IRepositoryState {
    items: IRepositoryItemDescriptor[];
    mode: Mode;
    selection: IRepositoryItemDescriptor[];
    menuAnchor?: any;
}

export interface IRepoViewMatchParams {
    path: IRepositoryItemDescriptor[],
    // path: string,
    refresh: boolean;
    changePath: (path: IRepositoryItemDescriptor[]) => void;
    openResource: (descriptor: IRepositoryItemDescriptor) => void;
}

/**
 * This is the repository main screen
 */
export class RepoView extends React.Component<IRepoViewMatchParams, IRepositoryState> {

    public state: IRepositoryState = {
        mode: Mode.FILES,
        items: [],
        selection: []
    };

    constructor(props) {
        super(props);
    }

    public componentDidMount = () => {
        this.refreshList();
    }

    public componentDidUpdate = (prevProps: IRepoViewMatchParams) => {
        if (!_.isEqual(prevProps.path, this.props.path)) {
            this.refreshList();
        }
        if (prevProps.refresh !== this.props.refresh) {
            this.refreshList();
        }
    }

    public handleSelected = (value?: IRepositoryItemDescriptor[]) => {
        this.setState({ selection: value });
    }

    public render() {
        return (
            <>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px', paddingRight: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div />
                            <div className='tc-jsw-repository-title-bar-item'>
                                <SearchFile path={getUUID(this.props.path)} search={this.setSearch} setItems={this.setItems} />
                            </div>
                        </div>
                    </div>
                    {
                        this.state.mode === Mode.FILES &&
                        <div style={{ display: 'flex', paddingLeft: '10px' }}>
                            <PathBreadcrumb path={this.props.path} changePath={this.props.changePath} />
                        </div>
                    }
                </div>
                <ResourceContextMenu anchorEl={this.state.menuAnchor} descriptor={this.state.selection[0]}
                    onChanged={this.handleItemChanged}
                    onDeleted={this.handleDeleted}
                    onClose={this.handleOnContextMenuClose} />
                {
                    !this.state.items || this.state.items.length == 0
                        ? <EmptyView icon={<FolderOpenIcon />} title="" subtitle={i18n.t('repoview.emptyfolder.text')} />
                        : <ResourcesTable
                            parent={last(this.props.path)}
                            files={this.state.items}
                            selection={this.state.selection}
                            onOpen={this.itemClicked}
                            onContextMenu={this.handleOnContextMenu}
                            onSelected={this.handleSelected}
                        />
                }
            </>
        );
    }

    private handleOnContextMenuClose = () => {
        this.setState({ menuAnchor: undefined });
    }

    private handleOnContextMenu = (anchorEl, items: IRepositoryItemDescriptor[]) => {
        this.setState({ menuAnchor: anchorEl, selection: items });
    }

    private handleItemChanged = (oldDescriptor: IRepositoryItemDescriptor, newDescriptor: IRepositoryItemDescriptor) => {
        if (oldDescriptor.uuid !== newDescriptor.uuid)
            this.refreshList();
        else
            this.setState((prevState) => {
                return { items: prevState.items.map(key => key.uuid === oldDescriptor.uuid ? newDescriptor : key), selection: [newDescriptor] };
            });
    }
    private handleDeleted = () => {
        this.refreshList();
    }

    private itemClicked = async (descriptor: any) => {
        const itemType = descriptor.type;
        if (itemType === RESOURCE_TYPE.FOLDER || itemType === RESOURCE_TYPE.CONTAINER) {
            this.setState({ mode: Mode.FILES, items: [] }, () => {
                const newPath = [...this.props.path, descriptor];
                this.props.changePath(newPath);
            });
        } else {
            if (!descriptor.path) {
                descriptor.path = '';
                this.props.path.forEach(item => {
                    descriptor.path += '/' + item.name;
                });
            }
            try {
                const response = await this.context.runNoCancel(RepositoryApi.inst().load)('uuid:' + descriptor.uuid, true, true);
                if (descriptor.isMainReport) {
                    response.data.isMainReport = descriptor.isMainReport;
                }
                if (!response.data.path) {
                    response.data.path = descriptor.path;
                }
                this.props.openResource(response.data);
            } catch (error) {
            }
        }
    }

    private refreshList = async () => {
        const p = _.cloneDeep(this.props.path);
        try {
            const response = await this.context.run(RepositoryApi.inst().listWithCancel)(getUUID(p))
            if (response.data) {
                const tableData = [];
                p[p.length - 1] = response.data;
                if (response.data.children) {
                    for (const k of Object.keys(response.data.children)) {
                        const item = response.data.children[k];
                        item.id = k; // We add the key to the element...
                        tableData.push(item);
                    }
                }
                tableData.sort((a, b) => {
                    return ResourceSorting.sortByName(a.name, b.name, false);
                });
                this.setState({ items: tableData }); 
                this.props.changePath(p);
            }
        } catch (error) {
            if (error.request?.status === 404) {
                p.pop();
                this.props.changePath(p);
            }
        }
    }
    private setItems = (items: IRepositoryItemDescriptor[]) => {
        this.setState({ items: items });
    }

    private setSearch = (value: boolean) => {
        this.setState({ mode: value ? Mode.SEARCH : Mode.FILES }, () => {
            if (!value)
                this.refreshList();
        });
    }
}

RepoView.contextType = RunContext;