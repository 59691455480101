/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { AbstractTextualEditor, IAbstractTextualEditor } from './AbstractTextualEditor';
import InternalJavascriptEditor from './InternalJavascriptEditor';

export class JavascriptEditor extends React.Component<IAbstractTextualEditor> {

    private javascriptEditorRef: React.RefObject<AbstractTextualEditor> = React.createRef<AbstractTextualEditor>();

    getEditorContent = () => {
        return this.javascriptEditorRef.current?.getEditorContent();
    }

    render() {
        return <InternalJavascriptEditor ref={this.javascriptEditorRef} {...this.props}/>;
    }
}