/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React, { forwardRef } from 'react';
import {
    Icon as MuiIcon, IconProps as MuiIconProps
} from '@material-ui/core';
import { IconSize, SizeToClass } from '../types/IconTypes';

export type IconProps = MuiIconProps & {
    icon: string
    size?: IconSize
};

// eslint-disable-next-line react/display-name
export const Icon = forwardRef<HTMLButtonElement, IconProps>(({
    className = '', icon, size = 'medium', ...rest
}, ref) => {

    const iconClassName = `jr-${icon}`;

    return (
        <MuiIcon
            ref={ref}
            className={`jr-mIcon mui ${iconClassName} ${className} ${SizeToClass[size]}`}
            {...rest}
        />
    )
})
