/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../ui/APDescriptor";
import { PAccordeon } from "../../ui/composite/UPAccordeon";

import { PDiv } from "../../ui/composite/UPDiv";
import { PConnectedQueryLanguage } from "../../ui/primitive/UPConnectedQueryLanguage";
import { PEnum } from "../../ui/primitive/UPEnum";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PString } from "../../ui/primitive/UPString";
import { PConnectedTable } from "../../ui/tables/UPConnectedTable";
import { MField } from "./MField";
import { MGroup } from "./MGroup";
import { MParameter } from "./MParameter";
import { MScriptlet } from "./MScriptlet";
import { MSortField } from "./MSortField";
import { MVariable } from "./MVariable";
import { PConnectedDatasetList } from "./UPConnectedDatasetList";
import { PConnectedQuery } from "./UPConnectedQuery";

import { MIME_TYPES } from "@jss/js-rest-api";
import { PConnectedValidatedPath } from "../../ui/primitive/UPConnectedValidatedPath";
import { PConnectedDynProperties } from "./UPConnectedDynProperties";
import { PConParametersTable } from "./UPConParametersTable";
import { PConVariablesTable } from "./UPConVariablesTable";
import { PJavaClass } from "../../ui/primitive/UPJavaClass";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { PSortFieldTable } from "../../ui/tables/UPSortFieldsTable";
import { PValidatedPropertiesPath } from "../../ui/primitive/UPValidatedPropertiesPath";

export const FDatasets = {
    name: new PString({ id: 'name', label: 'Name', canBeNull: false, canBeEmpty: false }),
    dataAdapter: new PConnectedValidatedPath({
        path: 'properties', id: 'net.sf.jasperreports.data.adapter', label: 'Data Adapter',
        refreshCacheOnAdd: true,
        refreshCacheOnDelete: true,
        allowedMimes: [MIME_TYPES.DATA_ADAPTER_XLS,
        MIME_TYPES.DATA_ADAPTER_CSV,
        MIME_TYPES.DATA_ADAPTER_EMPTY,
        MIME_TYPES.DATA_ADAPTER_JDBC,
        MIME_TYPES.DATA_ADAPTER_JNDI,
        MIME_TYPES.DATA_ADAPTER_JSON,
        MIME_TYPES.DATA_ADAPTER_RANDOM,
        MIME_TYPES.DATA_ADAPTER_XML,
        MIME_TYPES.DATA_ADAPTER_BEAN,
        MIME_TYPES.DATA_ADAPTER_DATA_SOURCE,
        MIME_TYPES.DATA_ADAPTER_EJBQL,
        MIME_TYPES.DATA_ADAPTER_HIBERNATE,
        MIME_TYPES.DATA_ADAPTER_JRS,
        MIME_TYPES.DATA_ADAPTER_MONDRIAN,
        MIME_TYPES.DATA_ADAPTER_MONGODB,
        MIME_TYPES.DATA_ADAPTER_PROVIDER,
        MIME_TYPES.DATA_ADAPTER_QUERY_EXECUTER,
        MIME_TYPES.DATA_ADAPTER_SPOTFIRE,
        MIME_TYPES.DATA_ADAPTER_SPRING_HIBERNATE,
        MIME_TYPES.DATA_ADAPTER_XMLA,
        MIME_TYPES.XML,
            'jrdax'
        ]
    }),
    language: new PConnectedQueryLanguage({ id: 'queryLanguage', label: 'components.datasets.language', writeable: true }),
    query: new PConnectedQuery({ id: 'query', label: 'components.datasets.showQueryEditor' }),
    whenResourceMissingType: new PEnum({
        id: 'whenResourceMissingType', label: 'components.datasets.whenResourceMissing.label', isEmptyUndefined: true,
        default: 'Null',
        items: [
            { key: 'Null', value: 'components.datasets.whenResourceMissing.null' },
            { key: 'Empty', value: 'components.datasets.whenResourceMissing.empty' },
            { key: 'Key', value: 'components.datasets.whenResourceMissing.key' },
            { key: 'Error', value: 'components.datasets.whenResourceMissing.error' }
        ]
    }),
    resourceBundle: new PValidatedPropertiesPath({ id: 'resourceBundle', label: 'components.datasets.resourceBundle' }),
    scriptletClass: new PJavaClass({ id: 'scriptletClass', label: 'components.datasets.scripletClass', refreshCacheOnAdd: true, refreshCacheOnDelete: true }),
    filterExpression: new PExpression({ id: 'filterExpression', label: 'components.datasets.filterExpression' }),
}

export const MMainDatasetLayouts = [
    { ...FDatasets.dataAdapter, id: 'net.sf.jasperreports.data.adapter|value' },
    FDatasets.language,
    FDatasets.query,
    new PConnectedTable({
        id: 'fields', label: 'components.datasets.fields', seachable: true, rowLabel: 'Field', rowLabelPaths: ['name'], layouts: [MField],
        default: { name: 'Field', class: 'java.lang.String', id: 'field-1', type: 'FIELD' }
    }),
    new PConParametersTable({
        id: 'parameters', label: 'components.datasets.parameters.label', rowLabel: 'Parameter', rowLabelPaths: ['name'], layouts: [MParameter],
        default: { name: 'Parameter', class: 'java.lang.String', id: 'parameter-1', type: 'PARAMETER', isForPrompting: true, }
    }),
    new PConVariablesTable({
        id: "variables", label: 'components.datasets.variables', rowLabel: 'Variable', rowLabelPaths: ['name'], layouts: [MVariable],
        default: { name: 'Variable', class: 'java.lang.String', id: 'variable-1', type: 'VARIABLE' }
    }),
    new PConnectedTable({
        id: "groups", label: 'components.datasets.groups', rowLabel: 'Group', rowLabelPaths: ['name'], layouts: [MGroup],
        default: { name: 'Group', id: 'group-1', type: ElementTypes.MAIN_DATASET_GROUP }
    }),
    new PSortFieldTable({
        id: "sortFields", label: 'components.datasets.sortFields', rowLabel: 'Sort Field', rowLabelPaths: ['name'], layouts: [MSortField]
        , default: { name: 'REPORT_COUNT', id: 'sortField-1', type: 'SORTFIELD' }
    }),
    new PConnectedTable({
        id: "scriptlets", label: 'components.datasets.scriptlets', rowLabel: 'Scriptlet', rowLabelPaths: ['name'], layouts: [MScriptlet], refreshCacheOnDelete: true,
        default: { name: 'Scriptlet', id: 'scriptlet-1', type: 'SCRIPTLET', class: 'net.sf.jasperreports.engine.JRDefaultScriptlet' }
    }),
    new PAccordeon({
        id: 'resourceBundle', label: 'components.datasets.resourceBundle', layouts: [FDatasets.resourceBundle, FDatasets.whenResourceMissingType]
    }),
    FDatasets.filterExpression, new PConnectedDynProperties({ id: 'dsCustomProperties', propspath: "dataset" })
];

export const MSubdatasetDatasetLayouts = [
    { ...FDatasets.dataAdapter, id: 'net.sf.jasperreports.data.adapter|value' },
    FDatasets.language,
    FDatasets.query,
    new PConnectedTable({
        id: 'fields', label: 'components.datasets.fields', seachable: true, rowLabel: 'Field', rowLabelPaths: ['name'], layouts: [MField],
        default: { name: 'Field', valueClassName: 'java.lang.String', id: 'field-1', type: 'FIELD' }
    }),
    new PConParametersTable({
        id: 'parameters', label: 'components.datasets.parameters.label', rowLabel: 'Parameter', rowLabelPaths: ['name'], layouts: [MParameter],
        default: { name: 'Parameter', valueClassName: 'java.lang.String', id: 'parameter-1', type: 'PARAMETER' }
    }),
    new PConVariablesTable({
        id: "variables", label: 'components.datasets.variables', rowLabel: 'Variable', rowLabelPaths: ['name'], layouts: [MVariable],
        default: { name: 'Variable', valueClassName: 'java.lang.String', id: 'variable-1', type: 'VARIABLE' }
    }),
    new PConnectedTable({
        id: "groups", label: 'components.datasets.groups', rowLabel: 'Group', rowLabelPaths: ['name'], layouts: [MGroup],
        default: { name: 'Group', id: 'group-1', type: ElementTypes.DATASET_GROUP }
    }),
    new PConnectedTable({
        id: "sortFields", label: 'components.datasets.sortFields', rowLabel: 'Sort Field', rowLabelPaths: ['name'], layouts: [MSortField]
        , default: { name: 'Sort Field', id: 'sortField-1', type: 'SORTFIELD' }
    }),
    new PConnectedTable({
        id: "scriptlets", label: 'components.datasets.scriptlets', rowLabel: 'Scriptlet', rowLabelPaths: ['name'], layouts: [MScriptlet], refreshCacheOnDelete: true,
        default: { name: 'Scriptlet', id: 'scriptlet-1', type: 'SCRIPTLET', class: 'net.sf.jasperreports.engine.JRDefaultScriptlet' }
    }),
    new PAccordeon({
        id: 'resourceBundle', label: 'components.datasets.resourceBundle', layouts: [FDatasets.resourceBundle, FDatasets.whenResourceMissingType]
    }),
    FDatasets.filterExpression, new PConnectedDynProperties({ id: 'dsCustomProperties', propspath: "dataset" })
];

export const MDatasets: APDescriptor = new PConnectedDatasetList({
    label: 'Dataset', expanded: true,
    detail: [FDatasets.name],
});

export const MDataset: APDescriptor = new PDiv({
    label: 'Dataset',
    layouts: [{ ...FDatasets.dataAdapter, id: 'net.sf.jasperreports.data.adapter|value' }, FDatasets.language, FDatasets.query,
    new PConnectedTable({
        id: 'fields', label: 'components.datasets.fields', seachable: true, rowLabel: 'Field', rowLabelPaths: ['name'], layouts: [MField],
        default: { name: 'Field', valueClassName: 'java.lang.String', id: 'field-1', type: 'FIELD' }
    }),
    new PConnectedTable({
        id: 'parameters', label: 'components.datasets.parameters.label', rowLabel: 'Parameter', rowLabelPaths: ['name'], layouts: [MParameter],
        default: { name: 'Parameter', valueClassName: 'java.lang.String', id: 'parameter-1', type: 'PARAMETER' }
    }),
    new PConnectedTable({
        id: "variables", label: 'components.datasets.variables', rowLabel: 'Variable', rowLabelPaths: ['name'], layouts: [MVariable],
        default: { name: 'Variable', valueClassName: 'java.lang.String', id: 'variable-1', type: 'VARIABLE' }
    }),
    new PConnectedTable({
        id: "groups", label: 'components.datasets.groups', rowLabel: 'Group', rowLabelPaths: ['name'], layouts: [MGroup],
        default: { name: 'Group', id: 'group-1', type: 'GROUP' }
    }),
    new PConnectedTable({
        id: "sortFields", label: 'components.datasets.sortFields', rowLabel: 'Sort Field', rowLabelPaths: ['name'], layouts: [MSortField]
        , default: { name: 'Sort Field', id: 'sortField-1', type: 'SORTFIELD' }
    }),
    new PConnectedTable({
        id: "scriptlets", label: 'components.datasets.scriptlets', rowLabel: 'Scriptlet', rowLabelPaths: ['name'], layouts: [MScriptlet]
        , default: { name: 'Scriptlet', id: 'scriptlet-1', type: 'SCRIPTLET', class: 'net.sf.jasperreports.engine.JRDefaultScriptlet' }
    }),
    new PAccordeon({
        id: 'resourceBundle', label: 'components.datasets.resourceBundle', layouts: [FDatasets.resourceBundle, FDatasets.whenResourceMissingType]
    }),
    FDatasets.filterExpression, new PConnectedDynProperties({ id: 'dsCustomProperties', propspath: "dataset" })
    ]
});
