/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { fromJS, List, Map } from 'immutable';
import { call, put, select, spawn } from 'redux-saga/effects';

// import store from '../../store/store';
import * as DocumentUtils from '../documentUtils';

// import { uniqueID } from '../../reducers/utils/prepareObjects';
import { Direction, IPoint } from '../../../../types/geometry';

import * as ReportActions from '../../../../actions/reportActions';
import { IState } from '../../../../reducers';
import { getEditorIndex } from '../../../../reducers/report/reportReducer';

const RESIZE_ELEMENT_PREFIX = "resize-";

/**
 * This function manage the dragging of an elements handle to resize it, produces special effects and conclude the final
 * operation on end...
 * The handle will process 3 possible states:
 * 1. drag not yet started
 * 2. drag in progress (dragStarted === true && commit === false)
 * 3. drag finished (commit === false)m it happens for instance on mouseUp event
 * 
 */
export function* handleDrag(point: IPoint, options: any, commit = false) {

  const state = yield select((s: IState) => s);
  const currentEditorIndex = getEditorIndex(state.get('report'));
  let mouse;
  if (currentEditorIndex !== undefined && currentEditorIndex >= 0) {
    const currentEditor = state.getIn(['report', 'subeditors', currentEditorIndex]);
    mouse = currentEditor.get('mouse');
  } else {
    mouse = state.getIn(['report', 'mouse']);
  }

  // 1. Let's see if this was the first move...
  const model = state.getIn(['report', 'model']);
  const selection = state.getIn(['report', 'selection']);
  if (mouse.get('operation') !== ReportActions.DragOperations.DRAG_OP_ELEMENT_RESIZE ||
    model === null) {
    return; // Nothing to do...
  }

  const resizeDirection: Direction = mouse.getIn(['operationState', 'direction']);
  const startLocation = mouse.get('startLocation').toJS(); // Convert the immutable map to object { }
  const currentLocation = point;
  let dragStarted = mouse.get('dragStarted'); // This is a primitive, no need to convert.
  const selectedElements = DocumentUtils.getSelectedElements(selection, model.get('elements')).toList();

  if (selectedElements.count() === 0) {
    return; // Nothing to do with 0 elements selected... it should never happen...
  }

  const pointDelta = {
    x: point.x - startLocation.x,
    y: point.y - startLocation.y
  };

  const positionFinder = DocumentUtils.createObjectAbsolutePositionFinder(state);

  if (!dragStarted) {

    // We need to initialize the drag. We will do it after some lag.
    // If the drag can start, we will initialize our phantom shape that will
    // be added to the list of intaraction elements.
    // Let's check if we are ready to start (3px lag required...)


    if (Math.abs(pointDelta.x) > 3 || Math.abs(pointDelta.y) > 3) {
      dragStarted = true;

      let figureElements = selectedElements.map((ele: Map<string, any>) => DocumentUtils.createInteractionFigure(ele, RESIZE_ELEMENT_PREFIX + ele.get('id'), true, positionFinder)).toList();
      // Let's create some interaction figures, one for each element on which we operate...
      figureElements = resizeElements(
        figureElements,
        resizeDirection,
        pointDelta);

      // Now we have a set of figures, let's add them to the state...
      yield put(ReportActions.updateDragOperation(fromJS(currentLocation), dragStarted, mouse.get("operationState")));
      yield put(ReportActions.addInteractionFigures(figureElements));

    }
  }
  else { // Keep dragging.log...

    // let figureElements = getState().getIn(['report','interactionElements']).filter( (ele: Map<string, any>, key: string) => key.startsWith(RESIZE_ELEMENT_PREFIX) ).toList();

    // We recreate the figures. After all the structure is very symple and names will be reused...
    const figureElements = selectedElements.map((ele: Map<string, any>) => DocumentUtils.createInteractionFigure(ele, RESIZE_ELEMENT_PREFIX + ele.get('id'), true, positionFinder)).toList();
    // Let's update our interaction figures
    let updatedfigureElements = resizeElements(
      figureElements,
      resizeDirection,
      pointDelta);

    if (!commit) {
      yield put(ReportActions.updateDragOperation(fromJS(currentLocation), true, mouse.get("operationState")));
      yield put(ReportActions.updateInteractionFigures(updatedfigureElements));

      // Recalculate client area in response to element modifications
      yield call(DocumentUtils.updateClientArea);
    }
    else {

      for (let i = 0; i < updatedfigureElements.count(); ++i) {
        updatedfigureElements = updatedfigureElements.update(i, (ele: Map<string, any>) => {
          return ele.updateIn(['props', 'className'], (val: string) => val + ' fadeOut')
            .set('transient', false);
        });
      }

      const figureElementIds = updatedfigureElements.reduce((arr: string[], coll: Map<string, any>) => { arr.push(coll.get('id')); return arr; }, []);

      yield put(ReportActions.updateInteractionFigures(updatedfigureElements));


      const elementChanges = [];

      figureElements.forEach((orignalFigureElement, index) => {
        const finalFigureElement = updatedfigureElements.get(index);
        const xOffset = finalFigureElement.get('x') - orignalFigureElement.get('x');
        const yOffset = finalFigureElement.get('y') - orignalFigureElement.get('y');
        const widthOffset = finalFigureElement.get('width') - orignalFigureElement.get('width');
        const heightOffset = finalFigureElement.get('height') - orignalFigureElement.get('height');
        const elementId = orignalFigureElement.get('elementId');
        const element = model.getIn(['elements', elementId]);
        //applay the change offset of the figure to the model
        elementChanges.push({
          id: elementId,
          x: element.get('x') + xOffset,
          y: element.get('y') + yOffset,
          width: element.get('width') + widthOffset,
          height: element.get('height') + heightOffset,
        });
      });

      if (elementChanges.length > 0) {
        yield put(ReportActions.updateElementsSizeAndPosition(elementChanges));
      }

      // Recalculate the client area and spawn the detached process to clean up the figure
      // Saga will not wait for removeInteractionFigureAfterFade.
      yield spawn(DocumentUtils.removeInteractionFiguresAfterAnimation, figureElementIds);
      yield call(DocumentUtils.updateClientArea);
    }
  }
}


/**
 * Resize a set of elements by considering the delta and the direction.
 * 
 * It returns the real delta applied, since it may be constrained by the current size of the elements.
 * 
 * @param ele 
 * @param resizeDirection 
 * @param pointDelta 
 */
const resizeElements = (elements: List<any>, resizeDirection: Direction, pointDelta: IPoint): List<Map<string, any>> => {

  // Let's make a copy first...
  pointDelta = { ...pointDelta };

  // Let's store the changes to apply, so we can apply them in a single loop at the end.
  const changesDelta = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }

  // We don't want width and height to become negatives, so we need to fix the delta accordingly
  // to the resize direction...
  const minHeight = elements.reduce((min, o) => min = o && o.get('height') < min ? o.get('height') : min, elements.first().get('height'));
  const minWidth = elements.reduce((min, o) => min = o && o.get('width') < min ? o.get('width') : min, elements.first().get('width'));

  // HEIGHT
  switch (resizeDirection) {
    case Direction.BOTTOM:
    case Direction.BOTTOM_LEFT:
    case Direction.BOTTOM_RIGHT:
      // If I'm lowering the height from the bottom, cannot go up more than minHeight
      changesDelta.height = Math.max(-minHeight, pointDelta.y);
      break;
    case Direction.TOP:
    case Direction.TOP_LEFT:
    case Direction.TOP_RIGHT:
      // If I'm lowering the height from the top, cannot go down more than minHeight
      changesDelta.y = Math.min(minHeight, pointDelta.y);
      changesDelta.height = -changesDelta.y;
      break;
  }

  // WIDTH
  switch (resizeDirection) {
    case Direction.RIGHT:
    case Direction.BOTTOM_RIGHT:
    case Direction.TOP_RIGHT:
      // If I'm lowering the width from the right, cannot go left more than minWidth
      changesDelta.width = Math.max(-minWidth, pointDelta.x);
      break;
    case Direction.LEFT:
    case Direction.BOTTOM_LEFT:
    case Direction.TOP_LEFT:
      // If I'm lowering the width from the left, cannot go right more than minWidth
      changesDelta.x = Math.min(minWidth, pointDelta.x);
      changesDelta.width = -changesDelta.x;
      break;
  }

  for (let i = 0; i < elements.count(); ++i) {
    elements = elements.update(i, (ele: Map<string, any>) => {
      return ele.update('x', (val: number) => val + changesDelta.x)
        .update('y', (val: number) => val + changesDelta.y)
        .update('width', (val: number) => val + changesDelta.width)
        .update('height', (val: number) => val + changesDelta.height)
        .updateIn(['props', 'style', 'left'], (val: number) => val + changesDelta.x)
        .updateIn(['props', 'style', 'top'], (val: number) => val + changesDelta.y)
        .updateIn(['props', 'style', 'width'], (val: number) => val + changesDelta.width)
        .updateIn(['props', 'style', 'height'], (val: number) => val + changesDelta.height);
    });
  }

  return elements;
}