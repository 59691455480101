/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const TableOrderEnum = new PEnum({
    id: 'tableOrder', label: 'TableOrderEnum.label', isEmptyUndefined: true,
    items: [
        { key: '', value: ''},
        { key: 'Row', value: 'TableOrder.Row'},
        { key: 'Column', value: 'TableOrder.Column'}
    ]
}); 
 
