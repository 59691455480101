/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ICONS } from "@jss/js-common";
import { ElementTypes } from "../../../../../../sagas/report/document/elementTypes";
import { APDescriptor } from "../../../ui/APDescriptor";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { PCategories } from "../../../ui/composite/UPCategories";
import { PDiv } from "../../../ui/composite/UPDiv";
import { PScrollableCategories } from "../../../ui/composite/UPScrollableCategories";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { PColor } from "../../../ui/primitive/UPColor";
import { PEnum } from "../../../ui/primitive/UPEnum";
import { PExpression } from "../../../ui/primitive/UPExpression";
import { PInt } from "../../../ui/primitive/UPInteger";
import { PString } from "../../../ui/primitive/UPString";
import { PPropertyExpression } from "../../../ui/primitive/UPValueExpession";
import { PConnectedTableDetail } from "../../../ui/tables/UPConnectedTableDetail";
import { MBoxGraphicElement } from "../../common/MGraphicElement";
import { MHyperlinks } from "../../common/MHyperlinks";
import { MDatasetRun } from "../../dataset/MDatasetRun";
import { EvaluationTimeEnum } from "../../enums/EvaluationTimeEnum";
import { IncrementTypeEnum } from "../../enums/IncrementTypeEnum";
import { ResetTypeEnum } from "../../enums/ResetTypeEnum";
import { MFusionMapCustom } from "../MFusionMapCustom";

export const FFusionMap = {
    mapNameExpression: new PExpression({ id: 'mapNameExpression', label: 'Map Name' }),
    entityNamesBundle: new PString({ id: 'entityNamesBundle', label: 'Names Bundle' }),
    evaluationTime: EvaluationTimeEnum,

    incrementType: IncrementTypeEnum,
    resetType: ResetTypeEnum,
    incrementWhenExpression: new PExpression({ id: 'incrementWhenExpression', label: 'Increment When' }),

    idExpression: new PExpression({ id: 'idExpression', label: 'ID' }),
    valueExpression: new PExpression({ id: 'valueExpression', label: 'Value' }),
    labelExpression: new PExpression({ id: 'labelExpression', label: 'Label' }),
    colorExpression: new PExpression({ id: 'colorExpression', label: 'Color' }),

    id: new PPropertyExpression({ id: 'id', vtype: new PString({ id: 'id', label: 'ID', description: 'Unique Id for the marker (alphanumeric). Each marker needs to be allotted a unique ID. ID of each marker can contain only alphabets and numbers. Special characters are not allowed.' }) }),
    label: new PPropertyExpression({ id: 'label', vtype: new PString({ id: 'label', label: 'Label', description: 'Label that you want to display for the marker. This label will be shown along side the marker on the map (unless you\'ve otherwise opted not to).' }) }),
    labelPos: new PPropertyExpression({
        id: 'labelPos', vtype: new PEnum({
            id: 'labelPos', label: 'Label Position', description: 'Where the label for this marker should appear, w.r.t. marker position - left, right, center, top or bottom? You can later over-ride the label position for individual markers, while applying them.',
            items: [{ key: "", value: "" }, { key: "left", value: "Left" },
            { key: "right", value: "Right" },
            { key: "top", value: "Top" },
            { key: "bottom", value: "Bottom" },
            { key: "center", value: "Center" }]
        })
    }),
    shapeId: new PPropertyExpression({
        id: 'shapeId', vtype: new PEnum({
            id: 'shapeId', label: 'Shape', writeable: true,
            description: 'Using this attribute, you can set the shape that this marker will assume on the  FusionMaps XT offers 4 default shapes - circle, arc, triangle or diamond. You can also define your custom shapes and then refer to them.',
            items: [{ key: "", value: "" }, { key: "circle", value: "Circle" },
            { key: "arc", value: "Arc" },
            { key: "triangle", value: "Triangle" },
            { key: "diamond", value: "Diamond" }]
        })
    }),
    radius: new PPropertyExpression({ id: 'radius', vtype: new PInt({ id: 'radius', label: 'Radius', description: 'In case of arc, circle or polygon, this attribute lets you set the radius of the same. The value of this attribute is overriden by the value of the radius attribute in a custom shape element.', min: 0 }) }),
    x: new PPropertyExpression({ id: 'x', vtype: new PInt({ id: 'x', label: 'X', description: 'Relative X-position of the marker. This should be set by the Visual Map Builder.', min: 0 }) }),
    y: new PPropertyExpression({ id: 'y', vtype: new PInt({ id: 'y', label: 'Y', description: 'Relative Y-position of the marker. This should be set by the Visual Map Builder.', min: 0 }) }),

    // ---- shapes

    type: new PPropertyExpression({ id: 'type', vtype: new PString({ id: 'type', label: 'Type' }) }),
    url: new PPropertyExpression({ id: 'url', vtype: new PString({ id: 'url', label: 'URL' }) }),
    sides: new PPropertyExpression({ id: 'sides', vtype: new PString({ id: 'sides', label: 'Sides' }) }),
    alpha: new PPropertyExpression({ id: 'alpha', vtype: new PInt({ id: 'alpha', label: 'Alpha', min: 0, max: 255 }) }),
    labelPadding: new PPropertyExpression({ id: 'labelPadding', vtype: new PInt({ id: 'labelPadding', label: 'Label Padding' }) }),

    fillColor: new PPropertyExpression({ id: 'fillColor', vtype: new PColor({ id: 'fillColor', label: 'Color' }) }),
    fillAlpha: new PPropertyExpression({ id: 'fillAlpha', vtype: new PInt({ id: 'fillAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    fillRatio: new PPropertyExpression({ id: 'fillRatio', vtype: new PString({ id: 'fillRatio', label: 'Ratio' }) }),
    fillAngle: new PPropertyExpression({ id: 'fillAngle', vtype: new PInt({ id: 'fillAngle', label: 'Angle', min: 0 }) }),
    fillPattern: new PPropertyExpression({ id: 'fillPattern', vtype: new PString({ id: 'fillPattern', label: 'Pattern', canBeNull: true }) }),

    showBorder: new PPropertyExpression({ id: 'showBorder', vtype: new PBoolean({ id: 'showBorder', label: 'Show Border' }) }),
    borderColor: new PPropertyExpression({ id: 'borderColor', vtype: new PColor({ id: 'borderColor', label: 'Color' }) }),
    borderThickness: new PPropertyExpression({ id: 'borderThickness', vtype: new PInt({ id: 'borderThickness', label: 'Thickness', min: 0 }) }),
    borderAlpha: new PPropertyExpression({ id: 'borderAlpha', vtype: new PInt({ id: 'borderAlpha', label: 'Alpha', min: 0, max: 255 }) }),

    innerRadius: new PPropertyExpression({ id: 'innerRadius', vtype: new PInt({ id: 'innerRadius', label: 'Inner Radius', min: 0 }) }),
    startAngle: new PPropertyExpression({ id: 'startAngle', vtype: new PInt({ id: 'startAngle', label: 'Start Angle', min: 0 }) }),
    endAngle: new PPropertyExpression({ id: 'endAngle', vtype: new PInt({ id: 'endAngle', label: 'End Angle', min: 0 }) }),
    xScale: new PPropertyExpression({ id: 'xScale', vtype: new PInt({ id: 'xScale', label: 'x Scale', min: 0 }) }),
    yScale: new PPropertyExpression({ id: 'yScale', vtype: new PInt({ id: 'yScale', label: 'y Scale', min: 0 }) }),
    vAlign: new PPropertyExpression({ id: 'vAlign', vtype: new PString({ id: 'vAlign', label: 'Vertical Align' }) }),

    // --- markers
    markerFont: new PPropertyExpression({ id: 'markerFont', vtype: new PString({ id: 'markerFont', label: 'Font' }) }),
    markerFontSize: new PPropertyExpression({ id: 'markerFontSize', vtype: new PString({ id: 'markerFontSize', label: 'Size' }) }),
    markerFontColor: new PPropertyExpression({ id: 'markerFontColor', vtype: new PColor({ id: 'markerFontColor', label: 'Color' }) }),

    showMarkerToolTip: new PPropertyExpression({ id: 'showMarkerToolTip', vtype: new PBoolean({ id: 'showMarkerToolTip', label: 'Show Tool Tip', canBeNull: true }) }),
    showMarkerLabels: new PPropertyExpression({ id: 'showMarkerLabels', vtype: new PBoolean({ id: 'showMarkerLabels', label: 'Show Labels', canBeNull: true }) }),
    markerLabelPadding: new PPropertyExpression({ id: 'markerLabelPadding', vtype: new PString({ id: 'markerLabelPadding', label: 'Label Padding' }) }),
    markerBgColor: new PPropertyExpression({ id: 'markerBgColor', vtype: new PColor({ id: 'markerBgColor', label: 'Background Color' }) }),

    markerBorderColor: new PPropertyExpression({ id: 'markerBorderColor', vtype: new PColor({ id: 'markerBorderColor', label: 'Color' }) }),
    markerBorderAlpha: new PPropertyExpression({ id: 'markerBorderAlpha', vtype: new PInt({ id: 'markerBorderAlpha', label: 'Tranasparency', min: 0, max: 255 }) }),

    markerRadius: new PPropertyExpression({ id: 'markerRadius', vtype: new PInt({ id: 'markerRadius', label: 'Radius', min: 0 }) }),
    autoScaleMarkers: new PPropertyExpression({ id: 'autoScaleMarkers', vtype: new PBoolean({ id: 'autoScaleMarkers', label: 'Auto Scale', canBeNull: true }) }),

    markerFillColor: new PPropertyExpression({ id: 'markerFillColor', vtype: new PColor({ id: 'markerFillColor', label: 'Color' }) }),
    markerFillAlpha: new PPropertyExpression({ id: 'markerFillAlpha', vtype: new PInt({ id: 'markerFillAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    markerFillRatio: new PPropertyExpression({ id: 'markerFillRatio', vtype: new PInt({ id: 'markerFillRatio', label: 'Ratio', min: 0 }) }),
    markerFillAngle: new PPropertyExpression({ id: 'markerFillAngle', vtype: new PInt({ id: 'markerFillAngle', label: 'Angle', min: 0 }) }),

    // ---- map properties
    animation: new PPropertyExpression({ id: 'animation', vtype: new PBoolean({ id: 'animation', label: 'Animation', canBeNull: true }) }),
    defaultAnimation: new PPropertyExpression({ id: 'defaultAnimation', vtype: new PString({ id: 'defaultAnimation', label: 'Default Animation' }) }),

    showLabels: new PPropertyExpression({ id: 'showLabels', vtype: new PBoolean({ id: 'showLabels', label: 'Show Labels', canBeNull: true }) }),
    includeNameInLabels: new PPropertyExpression({ id: 'includeNameInLabels', vtype: new PBoolean({ id: 'includeNameInLabels', label: 'Include Name In Labels', canBeNull: true }) }),
    includeValueInLabels: new PPropertyExpression({ id: 'includeValueInLabels', vtype: new PBoolean({ id: 'includeValueInLabels', label: 'Include Value In Labels', canBeNull: true }) }),
    useSNameInLabels: new PPropertyExpression({ id: 'useSNameInLabels', vtype: new PBoolean({ id: 'useSNameInLabels', label: 'Uses Short Name In Labels', canBeNull: true }) }),

    showShadow: new PPropertyExpression({ id: 'showShadow', vtype: new PBoolean({ id: 'showShadow', label: 'Show Shadow', canBeNull: true }) }),
    showBevel: new PPropertyExpression({ id: 'showBevel', vtype: new PBoolean({ id: 'showBevel', label: 'Show Bevel', canBeNull: true }) }),
    clickURL: new PPropertyExpression({ id: 'clickURL', vtype: new PString({ id: 'clickURL', label: 'Click URL' }) }),

    hoverOnEmpty: new PPropertyExpression({ id: 'hoverOnEmpty', vtype: new PString({ id: 'hoverOnEmpty', label: 'Hover On Empty' }) }),
    exposeHoverEvent: new PPropertyExpression({ id: 'exposeHoverEvent', vtype: new PString({ id: 'exposeHoverEvent', label: 'Expose Hover Event' }) }),
    useHoverColor: new PPropertyExpression({ id: 'useHoverColor', vtype: new PBoolean({ id: 'useHoverColor', label: 'Use Hover Color', canBeNull: true }) }),
    hoverColor: new PPropertyExpression({ id: 'hoverColor', vtype: new PColor({ id: 'hoverColor', label: 'Color' }) }),

    showPrintMenuItem: new PPropertyExpression({ id: 'showPrintMenuItem', vtype: new PBoolean({ id: 'showPrintMenuItem', label: 'Show Print Menu Item', canBeNull: true }) }),

    showAboutMenuItem: new PPropertyExpression({ id: 'showAboutMenuItem', vtype: new PBoolean({ id: 'showAboutMenuItem', label: 'Show About Menu Item', canBeNull: true }) }),
    aboutMenuItemLabel: new PPropertyExpression({ id: 'aboutMenuItemLabel', vtype: new PString({ id: 'aboutMenuItemLabel', label: 'Label' }) }),
    aboutMenuItemLink: new PPropertyExpression({ id: 'aboutMenuItemLink', vtype: new PString({ id: 'aboutMenuItemLink', label: 'Link' }) }),

    showCanvasBorder: new PPropertyExpression({ id: 'showCanvasBorder', vtype: new PBoolean({ id: 'showCanvasBorder', label: 'Show Canvas Border', canBeNull: true }) }),
    canvasBorderColor: new PPropertyExpression({ id: 'canvasBorderColor', vtype: new PColor({ id: 'canvasBorderColor', label: 'Color' }) }),
    canvasBorderThickness: new PPropertyExpression({ id: 'canvasBorderThickness', vtype: new PInt({ id: 'canvasBorderThickness', label: 'Thickness', min: 0 }) }),
    canvasBorderAlpha: new PPropertyExpression({ id: 'canvasBorderAlpha', vtype: new PInt({ id: 'canvasBorderAlpha', label: 'Alpha', min: 0, max: 255 }) }),

    bgColor: new PPropertyExpression({ id: 'bgColor', vtype: new PColor({ id: 'bgColor', label: 'Color' }) }),
    bgAlpha: new PPropertyExpression({ id: 'bgAlpha', vtype: new PInt({ id: 'bgAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    bgRatio: new PPropertyExpression({ id: 'bgRatio', vtype: new PInt({ id: 'bgRatio', label: 'Ratio', min: 0 }) }),
    bgAngle: new PPropertyExpression({ id: 'bgAngle', vtype: new PInt({ id: 'bgAngle', label: 'Angle', min: 0 }) }),
    bgSWF: new PPropertyExpression({ id: 'bgSWF', vtype: new PString({ id: 'bgSWF', label: 'SWF' }) }),
    bgSWFAlpha: new PPropertyExpression({ id: 'bgSWFAlpha', vtype: new PInt({ id: 'bgSWFAlpha', label: 'SWF Alpha', min: 0, max: 255 }) }),

    logoURL: new PPropertyExpression({ id: 'logoURL', vtype: new PString({ id: 'logoURL', label: 'URL' }) }),
    logoPosition: new PPropertyExpression({ id: 'logoPosition', vtype: new PString({ id: 'logoPosition', label: 'Position' }) }),
    logoAlpha: new PPropertyExpression({ id: 'logoAlpha', vtype: new PInt({ id: 'logoAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    logoScale: new PPropertyExpression({ id: 'logoScale', vtype: new PInt({ id: 'logoScale', label: 'Scale', min: 0 }) }),
    logoLink: new PPropertyExpression({ id: 'logoLink', vtype: new PString({ id: 'logoLink', label: 'Link' }) }),

    connectorColor: new PPropertyExpression({ id: 'connectorColor', vtype: new PColor({ id: 'connectorColor', label: 'Connector Color' }) }),
    connectorAlpha: new PPropertyExpression({ id: 'connectorAlpha', vtype: new PInt({ id: 'connectorAlpha', label: 'Connector Alpha', min: 0, max: 255 }) }),

    baseFont: new PPropertyExpression({ id: 'baseFont', vtype: new PString({ id: 'baseFont', label: 'Font' }) }),
    baseFontSize: new PPropertyExpression({ id: 'baseFontSize', vtype: new PInt({ id: 'baseFontSize', label: 'Size', min: 0 }) }),
    baseFontColor: new PPropertyExpression({ id: 'baseFontColor', vtype: new PColor({ id: 'baseFontColor', label: 'Color' }) }),

    imageSave: new PPropertyExpression({ id: 'imageSave', vtype: new PBoolean({ id: 'imageSave', label: 'Image Save', canBeNull: true }) }),
    imageSaveURL: new PPropertyExpression({ id: 'imageSaveURL', vtype: new PString({ id: 'imageSaveURL', label: 'URL' }) }),
    imageSaveDialogColor: new PPropertyExpression({ id: 'imageSaveDialogColor', vtype: new PColor({ id: 'imageSaveDialogColor', label: 'Dialog Color' }) }),
    imageSaveDialogFontColor: new PPropertyExpression({ id: 'imageSaveDialogFontColor', vtype: new PColor({ id: 'imageSaveDialogFontColor', label: 'Dialog Font Color' }) }),

    markerConnThickness: new PPropertyExpression({ id: 'markerConnThickness', vtype: new PInt({ id: 'markerConnThickness', label: 'Thickness', min: 0 }) }),
    markerConnColor: new PPropertyExpression({ id: 'markerConnColor', vtype: new PColor({ id: 'markerConnColor', label: 'Color' }) }),
    markerConnAlpha: new PPropertyExpression({ id: 'markerConnAlpha', vtype: new PInt({ id: 'markerConnAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    markerConnDashed: new PPropertyExpression({ id: 'markerConnDashed', vtype: new PBoolean({ id: 'markerConnDashed', label: 'Dashed', canBeNull: true }) }),
    markerConnDashLen: new PPropertyExpression({ id: 'markerConnDashLen', vtype: new PInt({ id: 'markerConnDashLen', label: 'Dash Length', min: 0 }) }),
    markerConnDashGap: new PPropertyExpression({ id: 'markerConnDashGap', vtype: new PInt({ id: 'markerConnDashGap', label: 'Dash Gap', min: 0 }) }),

    showToolTip: new PPropertyExpression({ id: 'showToolTip', vtype: new PBoolean({ id: 'showToolTip', label: 'Show Tool Tip', canBeNull: true }) }),
    toolTipBgColor: new PPropertyExpression({ id: 'toolTipBgColor', vtype: new PColor({ id: 'toolTipBgColor', label: 'Background Color' }) }),
    toolTipBorderColor: new PPropertyExpression({ id: 'toolTipBorderColor', vtype: new PColor({ id: 'toolTipBorderColor', label: 'Border Color' }) }),
    toolTipSepChar: new PPropertyExpression({ id: 'toolTipSepChar', vtype: new PString({ id: 'toolTipSepChar', label: 'Sep Char' }) }),
    showToolTipShadow: new PPropertyExpression({ id: 'showToolTipShadow', vtype: new PBoolean({ id: 'showToolTipShadow', label: 'Show Shadow', canBeNull: true }) }),
    useSNameInToolTip: new PPropertyExpression({ id: 'useSNameInToolTip', vtype: new PBoolean({ id: 'useSNameInToolTip', label: 'Use Short Name', canBeNull: true }) }),

    mapLeftMargin: new PPropertyExpression({ id: 'mapLeftMargin', vtype: new PInt({ id: 'mapLeftMargin', label: 'Left', min: 0 }) }),
    mapRightMargin: new PPropertyExpression({ id: 'mapRightMargin', vtype: new PInt({ id: 'mapRightMargin', label: 'Right', min: 0 }) }),
    mapTopMargin: new PPropertyExpression({ id: 'mapTopMargin', vtype: new PInt({ id: 'mapTopMargin', label: 'Top', min: 0 }) }),
    mapBottomMargin: new PPropertyExpression({ id: 'mapBottomMargin', vtype: new PInt({ id: 'mapBottomMargin', label: 'Bottom', min: 0 }) }),

    showLegend: new PPropertyExpression({ id: 'showLegend', vtype: new PBoolean({ id: 'showLegend', label: 'Show Legend', canBeNull: true }) }),
    legendPadding: new PPropertyExpression({ id: 'legendPadding', vtype: new PInt({ id: 'legendPadding', label: 'Padding', min: 0 }) }),
    legendCaption: new PPropertyExpression({ id: 'legendCaption', vtype: new PString({ id: 'legendCaption', label: 'Caption' }) }),
    legendPosition: new PPropertyExpression({
        id: 'legendPosition', vtype: new PEnum({
            id: 'legendPosition', label: 'Position', writeable: true,
            items: [{ key: "", value: "" },
            { key: "bottom", value: "Bottom" },
            { key: "right", value: "Right" }]
        })
    }),
    legendBgColor: new PPropertyExpression({ id: 'legendBgColor', vtype: new PColor({ id: 'legendBgColor', label: 'Background Color' }) }),
    legendBgAlpha: new PPropertyExpression({ id: 'legendBgAlpha', vtype: new PInt({ id: 'legendBgAlpha', label: 'Background Alpha', min: 0, max: 255 }) }),

    legendBorderColor: new PPropertyExpression({ id: 'legendBorderColor', vtype: new PColor({ id: 'legendBorderColor', label: 'Color' }) }),
    legendBorderThickness: new PPropertyExpression({ id: 'legendBorderThickness', vtype: new PString({ id: 'legendBorderThickness', label: 'Thickness' }) }),
    legendBorderAlpha: new PPropertyExpression({ id: 'legendBorderAlpha', vtype: new PInt({ id: 'legendBorderAlpha', label: 'Alpha', min: 0, max: 255 }) }),

    legendShadow: new PPropertyExpression({ id: 'legendShadow', vtype: new PBoolean({ id: 'legendShadow', label: 'Show Shadow', canBeNull: true }) }),
    legendAllowDrag: new PPropertyExpression({ id: 'legendAllowDrag', vtype: new PBoolean({ id: 'legendAllowDrag', label: 'Allow Drag', canBeNull: true }) }),

    legendScrollBgColor: new PPropertyExpression({ id: 'legendScrollBgColor', vtype: new PColor({ id: 'legendScrollBgColor', label: 'Background Color' }) }),
    legendScrollBarColor: new PPropertyExpression({ id: 'legendScrollBarColor', vtype: new PColor({ id: 'legendScrollBarColor', label: 'Bar Color' }) }),
    legendScrollBtnColor: new PPropertyExpression({ id: 'legendScrollBtnColor', vtype: new PString({ id: 'legendScrollBtnColor', label: 'Button Color' }) }),

    reverseLegend: new PPropertyExpression({ id: 'reverseLegend', vtype: new PBoolean({ id: 'reverseLegend', label: 'Reverse Legend', canBeNull: true }) }),

    formatNumberScale: new PPropertyExpression({ id: 'formatNumberScale', vtype: new PString({ id: 'formatNumberScale', label: 'Format Number Scale' }) }),
    defaultNumberScale: new PPropertyExpression({ id: 'defaultNumberScale', vtype: new PString({ id: 'defaultNumberScale', label: 'Default Number Scale' }) }),
    numberScaleUnit: new PPropertyExpression({ id: 'numberScaleUnit', vtype: new PString({ id: 'numberScaleUnit', label: 'Number Scale Unit' }) }),
    numberScaleValue: new PPropertyExpression({ id: 'numberScaleValue', vtype: new PString({ id: 'numberScaleValue', label: 'Number Scale Value' }) }),

    formatNumber: new PPropertyExpression({ id: 'formatNumber', vtype: new PString({ id: 'formatNumber', label: 'Format Number' }) }),
    numberPrefix: new PPropertyExpression({ id: 'numberPrefix', vtype: new PString({ id: 'numberPrefix', label: 'Number Prefix' }) }),
    numberSuffix: new PPropertyExpression({ id: 'numberSuffix', vtype: new PString({ id: 'numberSuffix', label: 'Number Suffix' }) }),
    decimalSeparator: new PPropertyExpression({ id: 'decimalSeparator', vtype: new PString({ id: 'decimalSeparator', label: 'Decimal Separator' }) }),
    thousandSeparator: new PPropertyExpression({ id: 'thousandSeparator', vtype: new PString({ id: 'thousandSeparator', label: 'Thousand Separator' }) }),
    inDecimalSeparator: new PPropertyExpression({ id: 'inDecimalSeparator', vtype: new PString({ id: 'inDecimalSeparator', label: 'In Decimal Separator' }) }),
    inThousandSeparator: new PPropertyExpression({ id: 'inThousandSeparator', vtype: new PString({ id: 'inThousandSeparator', label: 'In Thousand Separator' }) }),
    decimals: new PPropertyExpression({ id: 'decimals', vtype: new PString({ id: 'decimals', label: 'Decimals' }) }),

    // --- color ranges
    color: new PColor({ id: 'color', label: 'Color' }),
    minValueExpression: new PExpression({ id: 'minValueExpression', label: 'Min Value' }),
    maxValueExpression: new PExpression({ id: 'maxValueExpression', label: 'Max Value' }),
};

export const MFusionMap: APDescriptor = {
    type: ElementTypes.FUSION_MAP,
    id: 'funsionMapPropertiesDescriptor',
    label: 'Fusion Map',
    default: {
        width: 200, height: 30,
        MAP_XMLNS_NAMESPACE: 'fm',
        'xmlns:fm': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        mapNameExpression: '"US"', mapDataset: {
            dataset: {}, entities: [
                { idExpression: '""', valueExpression: '""', labelExpression: '"Label"', entityProperties: [] }]
        }

    },
    icon: ICONS.MAP_PRO,
    layouts: [new PScrollableCategories({
        id: 'fusionMapPropertiesTabsDescriptor',
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MBoxGraphicElement.layouts, MFusionMapCustom] }),
            new PCategories({
                id: 'fusionMapMapPropertiesTabsDescriptor',
                label: 'Fusion Map',
                layouts: [
                    new PDiv({
                        label: 'Configuration',
                        layouts: [FFusionMap.mapNameExpression, FFusionMap.entityNamesBundle, FFusionMap.evaluationTime,
                        new PDiv({
                            path: 'mapProperties',
                            layouts: [
                                FFusionMap.animation, FFusionMap.defaultAnimation,
                                FFusionMap.showPrintMenuItem,
                                FFusionMap.showShadow, FFusionMap.showBevel, FFusionMap.clickURL,
                                FFusionMap.connectorColor, FFusionMap.connectorAlpha,
                                new PAccordeon({
                                    id: 'numbers', label: "Number Formatting", layouts: [FFusionMap.formatNumberScale,
                                    FFusionMap.defaultNumberScale, FFusionMap.numberScaleUnit, FFusionMap.numberScaleValue,
                                    FFusionMap.formatNumber, FFusionMap.numberPrefix, FFusionMap.numberSuffix,
                                    FFusionMap.decimalSeparator, FFusionMap.thousandSeparator, FFusionMap.inDecimalSeparator, FFusionMap.inThousandSeparator,
                                    FFusionMap.decimals
                                    ]
                                }),
                                new PAccordeon({
                                    id: 'baseFont', label: "Base Font", layouts: [FFusionMap.baseFont, FFusionMap.baseFontSize, FFusionMap.baseFontColor]
                                }),
                                new PAccordeon({
                                    id: 'margins', label: "Margins", layouts: [FFusionMap.mapLeftMargin, FFusionMap.mapRightMargin, FFusionMap.mapTopMargin, FFusionMap.mapBottomMargin]
                                }),
                                new PAccordeon({
                                    id: 'tooltip', label: "Tool Tip", layouts: [FFusionMap.showToolTip, FFusionMap.showToolTipShadow, FFusionMap.toolTipBgColor, FFusionMap.toolTipBorderColor, FFusionMap.toolTipSepChar, FFusionMap.useSNameInToolTip]
                                }),
                                new PAccordeon({
                                    id: 'hover', label: "Hover", layouts: [FFusionMap.hoverOnEmpty, FFusionMap.exposeHoverEvent, FFusionMap.useHoverColor, FFusionMap.hoverColor]
                                }),
                                new PAccordeon({
                                    id: 'bg', label: "Background", layouts: [FFusionMap.bgColor, FFusionMap.bgAlpha, FFusionMap.bgRatio, FFusionMap.bgAngle, FFusionMap.bgSWF, FFusionMap.bgSWFAlpha]
                                }),
                                new PAccordeon({
                                    id: 'fill', label: "Fill", layouts: [FFusionMap.fillColor,
                                    FFusionMap.fillAlpha,
                                    FFusionMap.fillRatio,
                                    FFusionMap.fillAngle,
                                    FFusionMap.fillPattern]
                                }),
                                new PAccordeon({
                                    id: 'aboutMenu', label: "About Menu", layouts: [FFusionMap.showAboutMenuItem, FFusionMap.aboutMenuItemLabel, FFusionMap.aboutMenuItemLink]
                                }),
                                new PAccordeon({
                                    id: 'logo', label: "Logo", layouts: [FFusionMap.logoURL, FFusionMap.logoLink, FFusionMap.logoPosition, FFusionMap.logoScale, FFusionMap.logoAlpha]
                                }),
                                new PAccordeon({
                                    id: 'canvasBorder', label: "Canvas Border", layouts: [FFusionMap.showCanvasBorder, FFusionMap.canvasBorderColor, FFusionMap.canvasBorderAlpha, FFusionMap.canvasBorderThickness]
                                }),
                                new PAccordeon({
                                    id: 'imageSave', label: "Image Save", layouts: [FFusionMap.imageSave, FFusionMap.imageSaveURL, FFusionMap.imageSaveDialogColor, FFusionMap.imageSaveDialogFontColor]
                                }),
                                new PAccordeon({
                                    id: 'labels', label: "Labels", layouts: [FFusionMap.showLabels, FFusionMap.includeNameInLabels, FFusionMap.includeValueInLabels, FFusionMap.useSNameInLabels]
                                }),
                                new PAccordeon({
                                    id: 'legend', label: "Legend", layouts: [FFusionMap.showLegend, FFusionMap.legendCaption, FFusionMap.legendPosition, FFusionMap.legendPadding,
                                    FFusionMap.legendBgColor, FFusionMap.legendBgAlpha,
                                    FFusionMap.legendShadow, FFusionMap.legendAllowDrag,
                                    new PAccordeon({
                                        id: 'border', label: "Border", layouts: [FFusionMap.legendBorderColor, FFusionMap.legendBorderAlpha, FFusionMap.legendBorderThickness]
                                    }),
                                    new PAccordeon({
                                        id: 'scroll', label: "Scroll", layouts: [FFusionMap.legendScrollBarColor, FFusionMap.legendScrollBgColor, FFusionMap.legendScrollBtnColor]
                                    })
                                    ]
                                }),
                                new PAccordeon({
                                    id: 'markerConnection', label: "Marker Connection", layouts: [FFusionMap.markerConnColor, FFusionMap.markerConnAlpha, FFusionMap.markerConnDashed, FFusionMap.markerConnDashLen, FFusionMap.markerConnDashGap]
                                }),
                            ]
                        })
                        ]
                    }),
                    new PDiv({
                        id: 'fusion.data.dataset', path: 'mapDataset.dataset',
                        label: 'Dataset',
                        layouts: [FFusionMap.incrementType, FFusionMap.incrementWhenExpression, FFusionMap.resetType, MDatasetRun]
                    }),
                    new PDiv({
                        id: 'fusion.data.entities', path: 'mapDataset',
                        label: 'Entities',
                        layouts: [new PConnectedTableDetail({
                            id: 'entities', label: 'Entities', rowLabel: 'Entity', expanded: true, layouts: [new PCategories({
                                layouts: [
                                    new PDiv({
                                        id: 'fusion.data.entities.data',
                                        label: 'Data',
                                        layouts: [FFusionMap.idExpression, FFusionMap.valueExpression, FFusionMap.labelExpression, FFusionMap.colorExpression]
                                    }),
                                    new PDiv({
                                        id: 'fusion.data.entities.hyperlinks',
                                        label: 'Hyperlink', path: 'hyperlink',
                                        layouts: [...MHyperlinks.layouts]
                                    })
                                ]
                            })],
                            default: { id: 'entity-1' }
                        })]
                    })
                ]
            }),
            new PCategories({
                id: 'fusionMapMarkersPropertiesTabsDescriptor',
                label: 'Markers',
                layouts: [
                    new PDiv({
                        label: 'Configuration', path: 'mapProperties', layouts: [
                            FFusionMap.showMarkerToolTip,
                            FFusionMap.showMarkerLabels, FFusionMap.markerLabelPadding,
                            FFusionMap.markerBgColor, FFusionMap.markerRadius, FFusionMap.autoScaleMarkers,

                            new PAccordeon({
                                id: 'font', label: "Font", layouts: [FFusionMap.markerFont, FFusionMap.markerFontSize, FFusionMap.markerFontColor]
                            }),
                            new PAccordeon({
                                id: 'fill', label: "Fill", layouts: [FFusionMap.markerFillColor,
                                FFusionMap.markerFillAlpha,
                                FFusionMap.markerFillRatio,
                                FFusionMap.markerFillAngle]
                            }),
                            new PAccordeon({
                                id: 'border', label: "Border", layouts: [FFusionMap.markerBorderColor, FFusionMap.markerBorderAlpha]
                            }),
                        ]
                    }),
                    new PDiv({
                        id: 'fusion.data.entities', path: 'markerData',
                        label: 'Markers',
                        layouts: [new PConnectedTableDetail({
                            id: 'items', label: 'Markers', rowLabel: 'Marker', expanded: true, layouts: [new PDiv({
                                id: 'fusion.marker.items.data',
                                layouts: [FFusionMap.id, FFusionMap.label, FFusionMap.labelPos, FFusionMap.x, FFusionMap.y, FFusionMap.shapeId, FFusionMap.radius]
                            })], default: { id: 'item-1' }
                        })]
                    }),
                    new PDiv({
                        id: 'fusion.data.dataset', path: 'markerData.dataset',
                        label: 'Dataset',
                        layouts: [FFusionMap.incrementType, FFusionMap.incrementWhenExpression, FFusionMap.resetType, MDatasetRun]
                    })
                ]
            }),
            new PCategories({
                id: 'fusionMapShapesPropertiesTabsDescriptor',
                label: 'Shapes',
                layouts: [
                    new PDiv({
                        id: 'fusion.shapes.items', path: 'shapeData',
                        label: 'Shapes',
                        layouts: [new PConnectedTableDetail({
                            id: 'items', label: 'Shapes', rowLabel: 'Shape', expanded: true, layouts: [new PDiv({
                                id: 'fusion.shapes.items',
                                layouts: [FFusionMap.id, FFusionMap.type, FFusionMap.url, FFusionMap.sides, FFusionMap.alpha, FFusionMap.labelPadding,
                                new PAccordeon({
                                    id: 'fill', label: "Fill", layouts: [FFusionMap.fillColor,
                                    FFusionMap.fillAlpha,
                                    FFusionMap.fillRatio,
                                    FFusionMap.fillAngle,
                                    FFusionMap.fillPattern]
                                }),
                                new PAccordeon({
                                    id: 'border', label: "Border", layouts: [FFusionMap.showBorder, FFusionMap.borderColor, FFusionMap.borderAlpha, FFusionMap.borderThickness]
                                }),
                                FFusionMap.radius, FFusionMap.innerRadius, FFusionMap.startAngle, FFusionMap.endAngle, FFusionMap.xScale, FFusionMap.yScale, FFusionMap.vAlign]
                            })], default: { id: 'shape-1', type: 'circle' }
                        })]
                    }),
                    new PDiv({
                        id: 'fusion.shapes.dataset', path: 'shapeData.dataset',
                        label: 'Dataset',
                        layouts: [FFusionMap.incrementType, FFusionMap.incrementWhenExpression, FFusionMap.resetType, MDatasetRun]
                    })
                ]
            }),
            new PConnectedTableDetail({
                id: 'fusionMapColorRangesPropertiesTabsDescriptor', label: 'Color Ranges', rowLabel: 'Color', rowLabelPaths: ['color', 'labelExpression'], expanded: true, layouts: [new PDiv({
                    id: 'fusion.colorranges',
                    layouts: [FFusionMap.color, FFusionMap.labelExpression, FFusionMap.minValueExpression, FFusionMap.maxValueExpression
                    ]
                })], default: { color: '#000000' }
            })]
    })]
};
