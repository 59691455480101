
/*
* Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
* Licensed under commercial Jaspersoft Subscription License Agreement
*/

import * as React from 'react';
import { getPath, reg, UiProperty, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { Map, List, isImmutable } from 'immutable';
import _ from 'lodash';

import { Accordion, AccordionDetails, AccordionSummary, IconButton, TimedSearchBar, Typography } from '@jss/js-common';
import i18n from '../../../../../i18n';
import { IRepositoryItemDescriptor } from '@jss/js-rest-api';
import { PExpression } from '../primitive/UPExpression';

export interface UPTableState {
    selected: number;
    search?: string;
}

export interface IConnectedUPTable {
    descriptor?: IRepositoryItemDescriptor | undefined;
}

const TYPEID = 'ElementParametersTable';
export class PElementParametersTable extends APDescriptor {
    default?: any;
    seachable?: boolean;
    expanded?: boolean = false;
    rowLabel?: string;
    rowLabelPaths?: string[];
    refreshCacheOnDelete?: boolean;
    refreshCacheOnAdd?: boolean;
    public constructor(init: Partial<PElementParametersTable>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPElementParametersTable mcontext={mc} />; });



export class UPElementParametersTable extends React.Component<UiPropertyProps & IConnectedUPTable, UPTableState> {

    public state: UPTableState = { selected: 0 };

    private isExpanded = () => {
        const defaultValue = (this.props.mcontext.descriptor as PElementParametersTable).expanded === true;
        const isExpanded = this.props.mcontext.rootModel.getIn(['widgetStatus', this.props.mcontext.descriptor.id, 'expanded'], defaultValue) as boolean;
        return isExpanded;
    }

    private handleExpanded = () => {
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expanded'], !this.isExpanded(), false, true);
    }

    render() {
        return <Accordion expanded={this.isExpanded()} onChange={this.handleExpanded} size='small'>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className="js-jrws-search-accordion-header" size='small'>
                <Typography style={{ textTransform: 'capitalize' }}>{i18n.t(this.props.mcontext.descriptor.label)}</Typography>
                {this.renderSearch()}
                {/*<IconButton color='primary' icon='plus' size='small' style={{ marginRight: '-8px', marginLeft: '4px' }} onClick={this.addItem} title={i18n.t('common.actions.add')} />*/}
            </AccordionSummary>
            <AccordionDetails
                style={this.props.mcontext.descriptor.style ? this.props.mcontext.descriptor.style : { display: 'block', maxHeight: '350px', overflow: 'auto', paddingLeft: 0, paddingRight: 0 }}
                className={this.props.mcontext.descriptor.className}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {this.renderFields()}
                </div>
            </AccordionDetails>
        </Accordion>;
    }

    public renderFields = () => {
        const d = this.props.mcontext.descriptor as PElementParametersTable;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p) as List<Map<string, any>> | undefined;
        if (!v) {
            return <div style={{ textAlign: 'center', color: 'lightgray' }}>{i18n.t('tableElement.novalues')}</div>;
        }

        const fields: List<Map<string, any> | string> = v;
        if (fields.size < 1) {
            return <div style={{ textAlign: 'center', color: 'lightgray' }}>{i18n.t('tableElement.novalues')}</div>;
        }
        const tmp = [];
        let filteredFields = fields;
        if (this.state.search && this.state.search.length > 0) {
            filteredFields = fields.filter((field) => {
                const name = typeof field === 'string' ? field : field.get('name');
                return name.toLowerCase().includes(this.state.search)
            })
        }

        filteredFields.map((f: Map<string, any>, index) => {
            if (f !== undefined) {
                const fieldId = f.get('name');
                const els = _.cloneDeep(this.props.mcontext.elements);
                els.forEach(k => { k.path = [...p]; k.path.push(index) });
                const expressionDescriptor = new PExpression({ id: 'valueExpression', label: fieldId });
                const mc = { ...this.props.mcontext, descriptor: expressionDescriptor, elements: els };
                tmp.push(<div key={'div.' + fieldId} style={{ display: 'grid', gridTemplateColumns: '1fr 23px' }}>
                    <UiProperty key={fieldId} mcontext={mc} />
                    {mc.descriptor.readonly ? <></> : this.renderDelete(index)}
                </div>);
            }
        })
        return tmp;
    }

    private deleteElement = (key) => {
        console.log(key);
        const path = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
        const p = ['model', ...path, key];
        this.props.mcontext.deleteElement(p);
    }

    public renderDelete(key) {
        return <IconButton key={'ib.' + key} icon='delete' onClick={() => this.deleteElement(key)} title={i18n.t('common.actions.menu.delete')} />
        // return <MIconButton key={'ib.' + key} aria-label='delete' onClick={() => this.deleteElement(key)} ><DeleteIcon /> </MIconButton>;
    }

    private renderSearch() {
        const d = this.props.mcontext.descriptor as PElementParametersTable;
        if (d.seachable === true)
            return <TimedSearchBar onClick={this.onClick} className="js-jrws-properties-search" style={{ marginTop: '12px' }} placeholder='search ...' id="file-searchbar" onChange={this.seearchTextChange} value={this.state.search} pauseTime={40} />
    }

    private onClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private seearchTextChange = (v: string) => {
        v = v.trim().toLowerCase();
        v = v.length > 0 ? v : undefined;
        this.setState({ search: v });
    }

    public addItem = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        /*
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expanded'], true, false, true);

        const d = this.props.mcontext.descriptor as PParametersTable;
        let def = {};
        if (d.default !== undefined) {
            if (d.default !== Object(d.default) || _.isString(d.default))
                def = d.default;
            else {
                def = { ...d.default };
                if (def['name']) {
                    const pth = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
                    const v = this.props.mcontext.model.getIn(pth) as List<Map<string, any>> | undefined;

                    if (v) {
                        const fields: List<Map<string, any>> = v;
                        def['name'] = this.findName(def['name'], def['name'], 1, fields);
                    }
                }
            }
        }
        const p: string[] = ['model', ...getPath(d.id, this.props.mcontext.elements[0].path)];
        this.props.addProperty(p, def);*/
    }

    private findName = (n: string, prefix: string, id: number, fields: List<Map<string, any>>): string => {
        for (const f of fields) {
            const fld = isImmutable(f) ? f.toJS() : f;
            const name = fld.name;
            if (name === n) {
                return this.findName(prefix + id, prefix, id + 1, fields);
            }
        }
        return n;
    }
}


export default UPElementParametersTable;
