/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import { IRepositoryItemDescriptor, MIME_TYPES, RepositoryApi, RESOURCE_TYPE } from '@jss/js-rest-api';
import { TimedSearchBar } from '@jss/js-common';
import { AxiosResponse } from 'axios';
import { RunContext } from '../RunContainer';
import '../assets/uxpl/css/Repository.css';
import i18n from "../i18n";


export interface ISearchFileProps {
    path: string;
    search: (on: boolean) => void;
    setItems: (items: IRepositoryItemDescriptor[]) => void;
}
interface IState {
    text: string
}

export class SearchFile extends React.Component<ISearchFileProps, IState>{
    public state: IState = { text: '' };

    public render() {
        return <TimedSearchBar
            placeholder={i18n.t('searchfile.placeholder')}
            id="file-searchbar"
            onChange={this.searchTextChange}
            value={this.state.text} />;
    }

    private searchTextChange = async (v: string) => {
        v = v.trim();
        this.setState({ text: v }, () => {
            this.props.search(v.length > 0);
            if (v.length > 0) {
                this.context.run(RepositoryApi.inst().findWithCancel)({ name: v, root: this.props.path }).then((response: AxiosResponse) => {
                    let filteredElements: IRepositoryItemDescriptor[] = [];
                    if (response.data) {
                        filteredElements = response.data.filter((d: IRepositoryItemDescriptor) => {
                            const mimes = Object.values([MIME_TYPES.S3_BUCKET, MIME_TYPES.GITHUB_REPOSITORY, MIME_TYPES.OWNER_ORGANIZATION, MIME_TYPES.OWNER_USER, MIME_TYPES.REPOSITORY]) as string[];
                            return   (d.type !== RESOURCE_TYPE.CONTAINER || mimes.includes(d.mime));
                        });
                    }
                    this.props.setItems(filteredElements);
                }).catch(error => console.log(error));
            }
        });
    }
}

SearchFile.contextType = RunContext;