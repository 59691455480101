/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from "../../ui/primitive/UPEnum";

export const HyperlinkTargetEnum = new PEnum({
    id: 'linkTarget', label: 'HyperlinkTarget.label',
    items: [
        { key: 'None', value: 'HyperlinkTarget.None' },
        { key: 'Self', value: 'HyperlinkTarget.Self' },
        { key: 'Blank', value: 'HyperlinkTarget.Blank' },
        { key: 'Parent', value: 'HyperlinkTarget.Parent' },
        { key: 'Top', value: 'HyperlinkTarget.Top' },
        { key: 'Custom', value: 'HyperlinkTarget.Custom' }
    ]
});

