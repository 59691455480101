/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const XYBarChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M39 4v133h223V4z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M27.51 156.5L242.49 156.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M27.51 173.5L242.49 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M27.51 173.5L27.51 156.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M242.49 173.5L242.49 156.5" clipPath="url(#clipPath1)" />
            <path fill="#F55" stroke="none" d="M31.01 160h6v10h-6v-10z" clipPath="url(#clipPath1)" />
            <text x="41.01" y="167.769" stroke="none" clipPath="url(#clipPath1)">Half Freight</text>
            <path fill="#55F" stroke="none" d="M93.83 160h6v10h-6v-10z" clipPath="url(#clipPath1)" />
            <text x="103.83" y="167.769" stroke="none" clipPath="url(#clipPath1)">Normal Freight</text>
            <path fill="#5F5" stroke="none" d="M169.78 160h6v10h-6v-10z" clipPath="url(#clipPath1)" />
            <text x="179.779" y="167.769" stroke="none" clipPath="url(#clipPath1)">Double Freight</text>
            <path fill="none" strokeWidth="0.5" d="M39 137L262 137" clipPath="url(#clipPath1)" />
            <text x="60.21" y="148.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">102,5</text>
            <path fill="none" strokeWidth="0.5" d="M71.662 139L71.662 137" clipPath="url(#clipPath1)" />
            <text x="116.524" y="148.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">105,0</text>
            <path fill="none" strokeWidth="0.5" d="M127.975 139L127.975 137" clipPath="url(#clipPath1)" />
            <text x="172.837" y="148.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">107,5</text>
            <path fill="none" strokeWidth="0.5" d="M184.288 139L184.288 137" clipPath="url(#clipPath1)" />
            <text x="229.15" y="148.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">110,0</text>
            <path fill="none" strokeWidth="0.5" d="M240.601 139L240.601 137" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M39 4L39 137" clipPath="url(#clipPath1)" />
            <text x="27.91" y="139.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
            <path fill="none" strokeWidth="0.5" d="M37 137L39 137" clipPath="url(#clipPath1)" />
            <text x="17.73" y="115.167" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">500</text>
            <path fill="none" strokeWidth="0.5" d="M37 112.397L39 112.397" clipPath="url(#clipPath1)" />
            <text x="10.098" y="90.564" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">1.000</text>
            <path fill="none" strokeWidth="0.5" d="M37 87.795L39 87.795" clipPath="url(#clipPath1)" />
            <text x="10.098" y="65.962" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">1.500</text>
            <path fill="none" strokeWidth="0.5" d="M37 63.192L39 63.192" clipPath="url(#clipPath1)" />
            <text x="10.098" y="41.359" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">2.000</text>
            <path fill="none" strokeWidth="0.5" d="M37 38.59L39 38.59" clipPath="url(#clipPath1)" />
            <text x="10.098" y="16.757" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">2.500</text>
            <path fill="none" strokeWidth="0.5" d="M37 13.987L39 13.987" clipPath="url(#clipPath1)" />
         </g>
         <g fill="silver" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M71.662 4L71.662 137" clipPath="url(#clipPath2)" />
            <path fill="none" d="M127.975 4L127.975 137" clipPath="url(#clipPath2)" />
            <path fill="none" d="M184.288 4L184.288 137" clipPath="url(#clipPath2)" />
            <path fill="none" d="M240.601 4L240.601 137" clipPath="url(#clipPath2)" />
            <path fill="none" d="M39 137L262 137" clipPath="url(#clipPath2)" />
            <path fill="none" d="M39 112.397L262 112.397" clipPath="url(#clipPath2)" />
            <path fill="none" d="M39 87.795L262 87.795" clipPath="url(#clipPath2)" />
            <path fill="none" d="M39 63.192L262 63.192" clipPath="url(#clipPath2)" />
            <path fill="none" d="M39 38.59L262 38.59" clipPath="url(#clipPath2)" />
            <path fill="none" d="M39 13.987L262 13.987" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M49.136 108.799H71.661V137H49.136z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M71.662 41.688H94.18700000000001V137H71.662z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M94.187 69.592H116.71199999999999V137H94.187z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M116.712 10.333H139.237V137H116.712z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M139.237 45.974H161.762V136.999H139.237z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M161.763 53.49H184.288V137H161.763z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M184.288 68.106H206.81300000000002V137H184.288z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M206.813 83.339H229.338V137H206.813z" clipPath="url(#clipPath2)" />
            <path fill="#5F5" stroke="none" d="M229.338 24.28H251.863V137H229.338z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M49.136 122.899H71.661V137H49.136z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M71.662 89.344H94.18700000000001V137H71.662z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M94.187 103.296H116.71199999999999V137H94.187z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M116.712 73.667H139.237V137H116.712z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M139.237 91.487H161.762V137H139.237z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M161.763 95.245H184.288V137H161.763z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M184.288 102.553H206.81300000000002V137H184.288z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M206.813 110.169H229.338V137H206.813z" clipPath="url(#clipPath2)" />
            <path fill="#55F" stroke="none" d="M229.338 80.64H251.863V137H229.338z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M49.136 129.95H71.661V137H49.136z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M71.662 113.172H94.18700000000001V137H71.662z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M94.187 120.148H116.71199999999999V137H94.187z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M116.712 105.333H139.237V137H116.712z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M139.237 114.244H161.762V137H139.237z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M161.763 116.123H184.288V137.001H161.763z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M184.288 119.776H206.81300000000002V137H184.288z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M206.813 123.585H229.338V137H206.813z" clipPath="url(#clipPath2)" />
            <path fill="#F55" stroke="none" d="M229.338 108.82H251.863V137H229.338z" clipPath="url(#clipPath2)" />
         </g>
      </svg>
   );
}
export default XYBarChartIcon;