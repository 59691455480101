/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map, OrderedMap } from 'immutable';
import { TableTypes, TABLE_NAMESPACE } from '../reader/JrxmlTableUtils';
import { createBox, createDatasetRun, createProperties, createPropertiesExpression, createReportElement, setAttributeIfPresent, setExpressionNodeFromMap, setExpressionNode } from './JrxmlHelper';
import { createElement } from './JrxmlWriter';

export const createTableElement = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document) : Element | null => {
    const componentElement = xmlDocument.createElement("componentElement");
    const reportElement = createReportElement(elementNode, xmlDocument);
    componentElement.appendChild(reportElement);
    
    const baseNamespace = elementNode.get(TABLE_NAMESPACE, "jr");
    const namespace = baseNamespace + ":";
    const tableElement = xmlDocument.createElement(namespace + "table");

    const datasetRunNode = elementNode.get("datasetRun", null);
    if (datasetRunNode !== null){
        tableElement.appendChild(createDatasetRun(datasetRunNode, xmlDocument));
    }

    const children :  List<string> = elementNode.get("elementIds", null);
    let groupHeaders = List<Map<string,any>>();
    let groupFooters = List<Map<string,any>>();
    let tableHeader : Map<string, any> | null = null;
    let tableFooter : Map<string, any> | null = null;
    let columnHeader : Map<string, any> | null = null;
    let columnFooter : Map<string, any> | null = null;
    let detail : Map<string, any> | null = null;
    let noDataCell : Map<string, any> | null = null;
    if (children !== null){
        children.forEach((elementId: string) => {
            const childNode : Map<string, any> | null = jrxmlDocument.getIn(["elements", elementId], null) as Map<string, any> | null;
            if (childNode !== null){
                const type = childNode.get("type");
                if (type === TableTypes.TABLE_HEADER_NAME){
                    tableHeader = childNode;
                } else if (type === TableTypes.TABLE_FOOTER_NAME){
                    tableFooter = childNode;
                } else if (type === TableTypes.TABLE_COLUMN_HEADER_NAME){
                    columnHeader = childNode;
                } else if (type === TableTypes.TABLE_COLUMN_FOOTER_NAME){
                    columnFooter = childNode;
                } else if (type === TableTypes.TABLE_DETAIL_NAME){
                    detail = childNode;
                } else if (type === TableTypes.TABLE_GROUP_HEADER_NAME){
                    groupHeaders = groupHeaders.push(childNode);
                } else if (type === TableTypes.TABLE_GROUP_FOOTER_NAME){
                    groupFooters = groupFooters.push(childNode);
                } else if (type === TableTypes.TABLE_NO_DATA_CELL_NAME){
                    noDataCell = childNode;
                }
            }
        });
    }

    exploreTree(tableHeader, columnHeader, detail, columnFooter, tableFooter, groupHeaders, groupFooters, [], tableElement, xmlDocument, jrxmlDocument, namespace);
    if (noDataCell !== null){
        const noDataCellElement = createTableCell(jrxmlDocument, noDataCell, xmlDocument, namespace + "noData");
        tableElement.appendChild(noDataCellElement);
    }
    tableElement.setAttribute("xmlns:" + baseNamespace, elementNode.get("xmlns:" + baseNamespace, "http://jasperreports.sourceforge.net/jasperreports/components"));
    tableElement.setAttribute("xsi:schemaLocation", elementNode.get("xsi:schemaLocation", "http://jasperreports.sourceforge.net/jasperreports/components http://jasperreports.sourceforge.net/xsd/components.xsd"));

    setAttributeIfPresent(elementNode, "whenNoDataType", tableElement);

    createSectionProperties(tableHeader, 'tableHeader', namespace, tableElement, xmlDocument);
    createSectionProperties(tableFooter, 'tableFooter', namespace, tableElement, xmlDocument);
    createSectionProperties(columnHeader, 'columnHeader', namespace, tableElement, xmlDocument);
    createSectionProperties(columnFooter, 'columnFooter', namespace, tableElement, xmlDocument);
    createSectionProperties(detail, 'detail', namespace, tableElement, xmlDocument);
    if (groupHeaders){
        groupHeaders.forEach((groupHeader) => {
            const groupName = groupHeader.get("groupName");
            createSectionProperties(groupHeader, 'groupHeader', namespace, tableElement, xmlDocument, groupName);
        });
    }
    if (groupFooters){
        groupFooters.forEach((groupFooter) => {
            const groupName = groupFooter.get("groupName");
            createSectionProperties(groupFooter, 'groupFooter', namespace, tableElement, xmlDocument, groupName);
        });
    }

    componentElement.appendChild(tableElement);
    return componentElement;
}

const createSectionProperties = (sectionProperties: Map<string, any> | null, sectionName:string, prefix: string, tableElement: Element, xmlDocument: Document, groupName?: string) => {
    if (sectionProperties){
        const splitType = sectionProperties.get('splitType');
        const printWhen = sectionProperties.get('printWhenExpression');
        if (splitType || printWhen){
            if (groupName){
                const tableSection = xmlDocument.createElement(prefix + sectionName);
                tableSection.setAttribute('groupName', groupName);

                const rowSection = xmlDocument.createElement(prefix + 'row');
                if (splitType) {
                    rowSection.setAttribute('splitType', splitType);
                }
    
                if (printWhen){
                    setExpressionNode(rowSection, xmlDocument, printWhen, 'printWhenExpression');
                }
                tableSection.appendChild(rowSection);
                tableElement.appendChild(tableSection);
            } else {
                const tableSection = xmlDocument.createElement(prefix + sectionName);
                if (splitType) {
                    tableSection.setAttribute('splitType', splitType);
                }
    
                if (printWhen){
                    setExpressionNode(tableSection, xmlDocument, printWhen, 'printWhenExpression');
                }
                tableElement.appendChild(tableSection);
            }
        }
    }
}

const exploreTree = (tableHeader: Map<string, any>, columnHeader: Map<string, any>, detail: Map<string, any>, columnFooter: Map<string, any>, tableFooter: Map<string, any>, 
                        groupHeaders: List<Map<string,any>>, groupFooters: List<Map<string,any>>, path: number[], parentElement: Element, xmlDocument : Document, jrxmlDocument: Map<string, any>, prefix: string) => {
    // the child structure is the same on all the brenches so we take the table header as reference
    const currentNode = getChildNode(tableHeader, path, jrxmlDocument);
    if (currentNode){
        const thChildren : List<string> = currentNode.get("elementIds", List<string>());
        let currentIndex = 0;
        thChildren.forEach((childId) => {
            const child = jrxmlDocument.getIn(["elements", childId]) as Map<string, any>;
            const childType = child.get("type");
            if (childType === TableTypes.TABLE_GROUP_COLUMN_NAME){
                const groupWidth = calcGroupWidth(child, jrxmlDocument);
                const columnGroup = createTableColumnGroup(jrxmlDocument, child, groupWidth, xmlDocument, prefix);
                createColumnGroupCells(tableHeader, columnHeader, detail, columnFooter, tableFooter, groupHeaders, groupFooters, path.concat(currentIndex), columnGroup, xmlDocument, jrxmlDocument, prefix);
                const newPath =  path.concat(currentIndex);
                exploreTree(tableHeader, columnHeader, detail, columnFooter, tableFooter, groupHeaders, groupFooters, newPath, columnGroup, xmlDocument, jrxmlDocument, prefix);
                parentElement.appendChild(columnGroup);
            } else if (childType === TableTypes.TABLE_CELL_NAME){
                const column = createTableColumn(jrxmlDocument, child, child.get("width"), xmlDocument, prefix);
                
                createBaseColumnSections(tableHeader, columnHeader, detail, columnFooter, tableFooter, groupHeaders, groupFooters, path.concat(currentIndex), column, xmlDocument, jrxmlDocument, prefix);
    
                const detailCell =  getChildNode(detail, path.concat(currentIndex), jrxmlDocument);
                const hasDetailCell = detailCell.get("isDefined", false);
                if (hasDetailCell){
                    column.appendChild(createDetailCell(jrxmlDocument, detailCell, xmlDocument, prefix));
                }
                parentElement.appendChild(column);
            }
            currentIndex++;
        });
    }
} 

const getChildNode = (staritngNode: Map<string, any>, path: number[], jrxmlDocument: Map<string, any>) : Map<string, any> =>{
    let currentElement = staritngNode;
    path.forEach((currentIndex : number) => {
        const childId = currentElement.getIn(["elementIds", currentIndex]);
        currentElement = jrxmlDocument.getIn(["elements", childId]) as Map<string, any>;
    });
    return currentElement;
}

const createBaseColumnSections = (tableHeader: Map<string, any>, columnHeader: Map<string, any>, detail: Map<string, any>, columnFooter: Map<string, any>, tableFooter: Map<string, any>, 
    groupHeaders: List<Map<string,any>>, groupFooters: List<Map<string,any>>, path: number[], column: Element, xmlDocument : Document, jrxmlDocument: Map<string, any>, prefix: string) => {

    const tableHeaderCell = getChildNode(tableHeader, path, jrxmlDocument);

    const hasTableHeaderCell = tableHeaderCell.get("isDefined", false);
    if (hasTableHeaderCell){
        column.appendChild(createTableHeader(jrxmlDocument, tableHeaderCell, xmlDocument, prefix));
    }

    const tableFooterCell = getChildNode(tableFooter, path, jrxmlDocument);
    const hasTableFooterCell = tableFooterCell.get("isDefined", false);
    if (hasTableFooterCell){
        column.appendChild(createTableFooter(jrxmlDocument, tableFooterCell, xmlDocument, prefix));
    }

    groupHeaders.forEach((groupHeader: Map<string, any>) => {
        const groupHeaderCell = getChildNode(groupHeader, path, jrxmlDocument);
        const hasGroupHeaderCell = groupHeaderCell.get("isDefined", false);
        if (hasGroupHeaderCell){
            const groupName = groupHeader.get("groupName");
            column.appendChild(createGroupHeader(jrxmlDocument, groupHeaderCell, groupName, xmlDocument, prefix));
        }
    });

    groupFooters.forEach((groupFooter: Map<string, any>) => {
        const groupFooterCell = getChildNode(groupFooter, path, jrxmlDocument);
        const hasGroupFooterCell = groupFooterCell.get("isDefined", false);
        if (hasGroupFooterCell){
            const groupName = groupFooter.get("groupName");
            column.appendChild(createGroupFooter(jrxmlDocument, groupFooterCell, groupName, xmlDocument, prefix));
        }
    });

    const columnHeaderCell = getChildNode(columnHeader, path, jrxmlDocument);
    const hasColumnHeaderCell = columnHeaderCell.get("isDefined", false);
    if (hasColumnHeaderCell){
        column.appendChild(createColumnHeader(jrxmlDocument, columnHeaderCell, xmlDocument, prefix));
    }

    const columnFooterCell = getChildNode(columnFooter, path, jrxmlDocument);
    const hasColumnFooterCell = columnFooterCell.get("isDefined", false);
    if (hasColumnFooterCell){
        column.appendChild(createColumnFooter(jrxmlDocument, columnFooterCell, xmlDocument, prefix));
    }
}

const getGroupCell = (groupNode: Map<string, any>, jrxmlDocument: Map<string, any>) : Map<string, any> | null => {
    const groupChildrenIds : List<string> = groupNode.get("elementIds");
    if (groupChildrenIds === undefined){
        console.log("undefined parent");
    }
    for (let i = groupChildrenIds.size - 1; i >= 0; i--) {
        const groupChild : Map<string, any> = jrxmlDocument.getIn(["elements", groupChildrenIds.get(i)]) as Map<string, any>;
        if (groupChild.get("type") === TableTypes.TABLE_GROUP_CELL_NAME){
            return groupChild;
        }
    }
    return null;
}

const createColumnGroupCells = (tableHeader: Map<string, any>, columnHeader: Map<string, any>, detail: Map<string, any>, columnFooter: Map<string, any>, tableFooter: Map<string, any>, 
    groupHeaders: List<Map<string,any>>, groupFooters: List<Map<string,any>>, path: number[], column: Element, xmlDocument : Document, jrxmlDocument: Map<string, any>, prefix: string) => {

    const tableHeaderGroup = getChildNode(tableHeader, path, jrxmlDocument);
    const tableHeaderGroupCell = getGroupCell(tableHeaderGroup, jrxmlDocument);
    if (tableHeaderGroupCell !== null){
        column.appendChild(createTableHeader(jrxmlDocument, tableHeaderGroupCell, xmlDocument, prefix));
    }

    const tableFooterGroup = getChildNode(tableFooter, path, jrxmlDocument);
    const tableFooterGroupCell = getGroupCell(tableFooterGroup, jrxmlDocument);
    if (tableFooterGroupCell !== null){
        column.appendChild(createTableFooter(jrxmlDocument, tableFooterGroupCell, xmlDocument, prefix));
    }

    groupHeaders.forEach((groupHeader: Map<string, any>) => {
        const groupHeaderGroup = getChildNode(groupHeader, path, jrxmlDocument);
        const groupHeaderGroupCell = getGroupCell(groupHeaderGroup, jrxmlDocument);
        if (groupHeaderGroupCell !== null){
            const groupName = groupHeader.get("groupName");
            column.appendChild(createGroupHeader(jrxmlDocument, groupHeaderGroupCell, groupName, xmlDocument, prefix));
        }
    });

    groupFooters.forEach((groupFooter: Map<string, any>) => {
        const groupFooterGroup = getChildNode(groupFooter, path, jrxmlDocument);
        const groupFooterGroupCell = getGroupCell(groupFooterGroup, jrxmlDocument);
        if (groupFooterGroupCell !== null){
            const groupName = groupFooter.get("groupName");
            column.appendChild(createGroupFooter(jrxmlDocument, groupFooterGroupCell, groupName, xmlDocument, prefix));
        }
    });

    const columnHeaderGroup = getChildNode(columnHeader, path, jrxmlDocument);
    const columnHeaderGroupCell = getGroupCell(columnHeaderGroup, jrxmlDocument);
    if (columnHeaderGroupCell !== null){
        column.appendChild(createColumnHeader(jrxmlDocument, columnHeaderGroupCell, xmlDocument, prefix));
    }

    const columnFooterGroup = getChildNode(columnFooter, path, jrxmlDocument);
    const columnFooterGroupCell = getGroupCell(columnFooterGroup, jrxmlDocument);
    if (columnFooterGroupCell !== null){
        column.appendChild(createColumnFooter(jrxmlDocument, columnFooterGroupCell, xmlDocument, prefix));
    }
}

const calcGroupWidth = (columnGroupNode: Map<string, any>, jrxmlDocument: Map<string, any>) : number => {
    let result = 0;
    const childrenIds : List<string> = columnGroupNode.get("elementIds");
    childrenIds.forEach((childId) => {
        const child : Map<string, any> = jrxmlDocument.getIn(["elements", childId]) as Map<string, any>;
        const childType = child.get("type");
        if (childType === TableTypes.TABLE_CELL_NAME){
            result += child.get("width");
        } else if (childType === TableTypes.TABLE_GROUP_COLUMN_NAME){
            result += calcGroupWidth(child, jrxmlDocument);
        }
    });
    return result;
}

const createTableCell = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document, tagName: string) : Element => {
    const tableCell = xmlDocument.createElement(tagName);

    const properties: OrderedMap<string,string | null> = elementNode.get("properties", null);
    createProperties(tableCell, properties, xmlDocument);

    const boxNode : Map<string, any> = elementNode.get("box", null);
    if (boxNode !== null){
        tableCell.appendChild(createBox(boxNode, xmlDocument));
    }

    const children :  List<string> = elementNode.get("elementIds", null);
    if (children !== null){
        children.forEach((elementId: string) => {
            const childNode : Map<string, any> | null = jrxmlDocument.get("elements").get(elementId, null);
            if (childNode !== null){
                const childElement = createElement(jrxmlDocument, childNode, xmlDocument);
                if (childElement !== null){
                    tableCell.appendChild(childElement);
                }
            }
        });
    }

    setAttributeIfPresent(elementNode, "style", tableCell);
    setAttributeIfPresent(elementNode, "height", tableCell);
    setAttributeIfPresent(elementNode, "rowSpan", tableCell);

    return tableCell;
}

const createTableHeader = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    return createTableCell(jrxmlDocument, elementNode, xmlDocument, prefix + "tableHeader");
}

const createTableFooter = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    return createTableCell(jrxmlDocument, elementNode, xmlDocument, prefix + "tableFooter");
}

const createColumnHeader = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    return createTableCell(jrxmlDocument, elementNode, xmlDocument, prefix + "columnHeader");
}

const createColumnFooter = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    return createTableCell(jrxmlDocument, elementNode, xmlDocument, prefix + "columnFooter");
}

const createDetailCell = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, xmlDocument: Document, prefix: string) : Element => {
    return createTableCell(jrxmlDocument, elementNode, xmlDocument, prefix + "detailCell");
}

const createGroupHeader = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, groupName: string, xmlDocument: Document, prefix: string) : Element => {
    const groupHeader = xmlDocument.createElement(prefix + "groupHeader");
    groupHeader.setAttribute("groupName", groupName);
    groupHeader.appendChild(createTableCell(jrxmlDocument, elementNode, xmlDocument, prefix + "cell"));
    return groupHeader;
}

const createGroupFooter = (jrxmlDocument: Map<string, any>, elementNode: Map<string, any>, groupName: string, xmlDocument: Document, prefix: string) : Element => {
    const groupFooter = xmlDocument.createElement(prefix + "groupFooter");
    groupFooter.setAttribute("groupName", groupName);
    groupFooter.appendChild(createTableCell(jrxmlDocument, elementNode, xmlDocument, prefix + "cell"));
    return groupFooter;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createBaseColumn = (jrxmlDocument: Map<string, any>, tableHeaderNode: Map<string, any>, width: number, tagName: string, xmlDocument: Document, prefix: string) : Element => {
    const baseColumn = xmlDocument.createElement(tagName);

    const columnProperties = tableHeaderNode.get('columnProperties', OrderedMap<string, Map<string, any>>()) as OrderedMap<string, Map<string, any>>;
    createPropertiesExpression(baseColumn, columnProperties, xmlDocument);

    setExpressionNodeFromMap(baseColumn, xmlDocument, tableHeaderNode, "printWhenExpression");

    baseColumn.setAttribute("width", width.toString());
    setAttributeIfPresent(tableHeaderNode, "uuid", baseColumn);

    return baseColumn;
}

const createTableColumn = (jrxmlDocument: Map<string, any>, tableHeaderNode: Map<string, any>, width: number, xmlDocument: Document, prefix: string) : Element => {
    const column = createBaseColumn(jrxmlDocument, tableHeaderNode, width, prefix + "column", xmlDocument, prefix);
    return column;
}

const createTableColumnGroup = (jrxmlDocument: Map<string, any>, tableHeaderNode: Map<string, any>, width: number, xmlDocument: Document, prefix: string) : Element => {
    const columnGroup = createBaseColumn(jrxmlDocument, tableHeaderNode, width, prefix + "columnGroup", xmlDocument, prefix);
    return columnGroup;
}