/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const BubbleChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="175" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" colorInterpolation="auto" fontFamily="'Dialog'" fontSize="12" viewBox="0 0 270 175" {...props}>
         <defs>
            <clipPath id="clipPath1" clipPathUnits="userSpaceOnUse">
               <path d="M0 0h270v175H0V0z" />
            </clipPath>
            <clipPath id="clipPath2" clipPathUnits="userSpaceOnUse">
               <path d="M32 4v134h230V4z" />
            </clipPath>
         </defs>
         <g fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M24.51 157.188L245.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M24.51 173.5L245.49 173.5" clipPath="url(#clipPath1)" />
            <path fill="none" d="M24.51 173.5L24.51 157.188" clipPath="url(#clipPath1)" />
            <path fill="none" d="M245.49 173.5L245.49 157.188" clipPath="url(#clipPath1)" />
            <path fill="#F55" stroke="none" d="M36.01 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="gray" d="M36.01 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <text x="40.01" y="168.113" stroke="none" clipPath="url(#clipPath1)">Double Freight</text>
            <path fill="#55F" stroke="none" d="M113.22 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="gray" d="M113.22 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <text x="117.221" y="168.113" stroke="none" clipPath="url(#clipPath1)">Normal Freight</text>
            <path fill="#5F5" stroke="none" d="M191.17 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <path fill="none" stroke="gray" d="M191.17 165.344a4 4 0 11-8 0 4 4 0 018 0z" clipPath="url(#clipPath1)" />
            <text x="195.17" y="168.113" stroke="none" clipPath="url(#clipPath1)">Half Freight</text>
            <path fill="none" strokeWidth="0.5" d="M32 138L262 138" clipPath="url(#clipPath1)" />
            <text x="57.958" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.500</text>
            <path fill="none" strokeWidth="0.5" d="M71.954 140L71.954 138" clipPath="url(#clipPath1)" />
            <text x="139.001" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">10.750</text>
            <path fill="none" strokeWidth="0.5" d="M152.997 140L152.997 138" clipPath="url(#clipPath1)" />
            <text x="220.044" y="149.426" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">11.000</text>
            <path fill="none" strokeWidth="0.5" d="M234.04 140L234.04 138" clipPath="url(#clipPath1)" />
            <path fill="none" strokeWidth="0.5" d="M32 4L32 138" clipPath="url(#clipPath1)" />
            <text x="20.91" y="140.769" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">0</text>
            <path fill="none" strokeWidth="0.5" d="M30 138L32 138" clipPath="url(#clipPath1)" />
            <text x="15.82" y="126.125" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">50</text>
            <path fill="none" strokeWidth="0.5" d="M30 123.355L32 123.355" clipPath="url(#clipPath1)" />
            <text x="10.73" y="111.48" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">100</text>
            <path fill="none" strokeWidth="0.5" d="M30 108.71L32 108.71" clipPath="url(#clipPath1)" />
            <text x="10.73" y="96.835" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">150</text>
            <path fill="none" strokeWidth="0.5" d="M30 94.065L32 94.065" clipPath="url(#clipPath1)" />
            <text x="10.73" y="82.19" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">200</text>
            <path fill="none" strokeWidth="0.5" d="M30 79.421L32 79.421" clipPath="url(#clipPath1)" />
            <text x="10.73" y="67.545" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">250</text>
            <path fill="none" strokeWidth="0.5" d="M30 64.776L32 64.776" clipPath="url(#clipPath1)" />
            <text x="10.73" y="52.9" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">300</text>
            <path fill="none" strokeWidth="0.5" d="M30 50.131L32 50.131" clipPath="url(#clipPath1)" />
            <text x="10.73" y="38.255" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">350</text>
            <path fill="none" strokeWidth="0.5" d="M30 35.486L32 35.486" clipPath="url(#clipPath1)" />
            <text x="10.73" y="23.61" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">400</text>
            <path fill="none" strokeWidth="0.5" d="M30 20.841L32 20.841" clipPath="url(#clipPath1)" />
            <text x="10.73" y="8.966" stroke="none" strokeWidth="0.5" clipPath="url(#clipPath1)">450</text>
            <path fill="none" strokeWidth="0.5" d="M30 6.196L32 6.196" clipPath="url(#clipPath1)" />
         </g>
         <g fill="silver" stroke="silver" strokeDasharray="2,2" strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="0" strokeWidth="0.5" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <path fill="none" d="M71.954 4L71.954 138" clipPath="url(#clipPath2)" />
            <path fill="none" d="M152.997 4L152.997 138" clipPath="url(#clipPath2)" />
            <path fill="none" d="M234.04 4L234.04 138" clipPath="url(#clipPath2)" />
            <path fill="none" d="M32 138L262 138" clipPath="url(#clipPath2)" />
            <path fill="none" d="M32 123.355L262 123.355" clipPath="url(#clipPath2)" />
            <path fill="none" d="M32 108.71L262 108.71" clipPath="url(#clipPath2)" />
            <path fill="none" d="M32 94.065L262 94.065" clipPath="url(#clipPath2)" />
            <path fill="none" d="M32 79.421L262 79.421" clipPath="url(#clipPath2)" />
            <path fill="none" d="M32 64.776L262 64.776" clipPath="url(#clipPath2)" />
            <path fill="none" d="M32 50.131L262 50.131" clipPath="url(#clipPath2)" />
            <path fill="none" d="M32 35.486L262 35.486" clipPath="url(#clipPath2)" />
            <path fill="none" d="M32 20.841L262 20.841" clipPath="url(#clipPath2)" />
            <path fill="none" d="M32 6.196L262 6.196" clipPath="url(#clipPath2)" />
            <circle cx="229.502" cy="106.095" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
         </g>
         <g fill="gray" stroke="gray" fontFamily="'DejaVu Sans'" fontSize="8" textRendering="optimizeLegibility">
            <circle cx="229.502" cy="106.095" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="42.455" cy="133.631" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="42.455" cy="133.631" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="55.097" cy="132.315" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="55.097" cy="132.315" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="78.762" cy="135.478" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="78.762" cy="135.478" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="82.004" cy="136.811" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="82.004" cy="136.811" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="240.199" cy="137.536" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="240.199" cy="137.536" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="141.975" cy="134.695" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="141.975" cy="134.695" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="163.371" cy="137.839" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="163.371" cy="137.839" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="220.425" cy="130.742" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="220.425" cy="130.742" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="175.365" cy="135.106" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="175.365" cy="135.106" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="178.283" cy="124.695" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="178.283" cy="124.695" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="251.546" cy="137.952" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="251.546" cy="137.952" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="195.464" cy="137.584" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="195.464" cy="137.584" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="213.617" cy="133.385" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="213.617" cy="133.385" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="200.975" cy="137.814" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="200.975" cy="137.814" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="206.81" cy="128.661" r="2.929" fill="#5F5" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="206.81" cy="128.661" r="2.929" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="229.502" cy="74.189" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="229.502" cy="74.189" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="42.455" cy="129.263" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="42.455" cy="129.263" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="55.097" cy="126.63" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="55.097" cy="126.63" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="78.762" cy="132.956" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="78.762" cy="132.956" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="82.004" cy="135.622" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="82.004" cy="135.622" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="240.199" cy="137.071" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="240.199" cy="137.071" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="141.975" cy="131.389" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="141.975" cy="131.389" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="163.371" cy="137.678" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="163.371" cy="137.678" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="220.425" cy="123.484" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="220.425" cy="123.484" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="175.365" cy="132.212" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="175.365" cy="132.212" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="178.283" cy="111.39" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="178.283" cy="111.39" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="251.546" cy="137.903" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="251.546" cy="137.903" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="195.464" cy="137.168" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="195.464" cy="137.168" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="213.617" cy="128.771" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="213.617" cy="128.771" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="200.975" cy="137.628" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="200.975" cy="137.628" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="206.81" cy="119.322" r="4.394" fill="#55F" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="206.81" cy="119.322" r="4.394" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="229.502" cy="10.379" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="229.502" cy="10.379" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="42.455" cy="120.526" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="42.455" cy="120.526" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="55.097" cy="115.259" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="55.097" cy="115.259" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="78.762" cy="127.913" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="78.762" cy="127.913" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="82.004" cy="133.243" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="82.004" cy="133.243" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="240.199" cy="136.143" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="240.199" cy="136.143" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="141.975" cy="124.779" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="141.975" cy="124.779" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="163.371" cy="137.356" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="163.371" cy="137.356" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="220.425" cy="108.968" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="220.425" cy="108.968" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="175.365" cy="126.425" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="175.365" cy="126.425" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="178.283" cy="84.781" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="178.283" cy="84.781" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="251.546" cy="137.807" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="251.546" cy="137.807" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="195.464" cy="136.336" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="195.464" cy="136.336" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="213.617" cy="119.542" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="213.617" cy="119.542" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="200.975" cy="137.256" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="200.975" cy="137.256" r="5.858" fill="none" clipPath="url(#clipPath2)" />
            <circle cx="206.81" cy="100.644" r="5.858" fill="#F55" stroke="none" clipPath="url(#clipPath2)" />
            <circle cx="206.81" cy="100.644" r="5.858" fill="none" clipPath="url(#clipPath2)" />
         </g>
      </svg>
   );
}
export default BubbleChartIcon;