/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { TextInput, EditableTable, Select, WritableCombo, TIMEZONES, LOCALES, Icon } from '@jss/js-common';
import '../../assets/css/DataAdapterTypePicker.css'
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import i18n from '../../i18n';
import { HTTPConfigurationPanel } from '../HTTPConfigurationPanel';
import { IconButton, InputAdornment } from '@material-ui/core';
import { getRealName, IRepositoryItemDescriptor, MIME_TYPES, RepositoryApi } from '@jss/js-rest-api';
import { ResourcePickerDialog, RunContext } from '@jss/js-repository';
import { advancedNameValidator, advancedUrlValidator } from '@jss/js-common/src/utils/validators';

interface IExcelDataAdapterEditor {
    name?: string,
    location?: string | null,
    format?: string,
    sheetSelection?: string,
    columnNames?: string[],
    columnIndexes?: string[],
    datePattern?: string,
    numberPattern?: string,
    locale?: string,
    timeZone?: string,
    useFirstRowAsHeader?: boolean,
    queryExecuterMode?: boolean,
    readOnly?: boolean,
    onDataAdapterSettingsChange?: (data: any) => void
}

const ALTERED_LOCALES = LOCALES.map((locale) => { 
    if (locale.key === 'en'){
        return {key: locale.key + '_EN', value: locale.value}
    }
    return locale; 
});

export class ExcelDataAdapterEditor extends React.Component<IExcelDataAdapterEditor> {

    getRows = () => {
        const result: string[][] = [];
        const rows = this.props.columnNames ? [...this.props.columnNames] : [];
        const values = this.props.columnIndexes ? [...this.props.columnIndexes] : [];
        rows.forEach((columnName: string, index: number) => {
            result.push([columnName, values[index]]);
        });
        return result;
    }

    isNumber = (value: string) => {
        return (!Number.isNaN(parseInt(value, 10)));
    }

    onCellChange = (row: number, col: number, value: string, isEditing: boolean) => {
        const rowNames = this.props.columnNames ? [...this.props.columnNames] : [];
        const rowIndexes = this.props.columnIndexes ? [...this.props.columnIndexes] : [];
        if (col === 0 && value.trim().length === 0 && !isEditing) {
            //has erased a row, delete the row
            rowNames.splice(row, 1);
            rowIndexes.splice(row, 1);
        } else if (!isEditing) {
            if (col === 0) {
                rowNames[row] = value;
            } else if (col === 1) {
                rowIndexes[row] = value;
            }
        }
        if (!isEditing) {
            this.notifyChange({ columnNames: rowNames, columnIndexes: rowIndexes });
        }
    }

    onRowDelete = (rowIndex: number) => {
        const newColumnsNames = [...this.props.columnNames];
        newColumnsNames.splice(rowIndex, 1);
        const newColumnsIndexes = [...this.props.columnIndexes];
        newColumnsIndexes.splice(rowIndex, 1);
        this.notifyChange({ columnNames: newColumnsNames, columnIndexes: newColumnsIndexes });
    }

    getColumnSize = () => {
        /*const width = document.getElementById('tableContainer')?.clientWidth;
        if (width){
            return [width * 2/3, width * 1/3];
        }
        return undefined;*/
        return [400 * 2 / 3, 400 * 1 / 3];
    }

    private onAddAction = () => {
        let index = 1;
        let found = false;
        const rows = this.props.columnNames ? [...this.props.columnNames] : [];
        const rowIndexes = this.props.columnIndexes ? [...this.props.columnIndexes] : [];
        while (!found) {
            const newName = 'COLUMN_' + index;
            const elementIndex = rows.findIndex((value: string) => {
                return value.toUpperCase().trim() === newName;
            });
            if (elementIndex === -1) {
                found = true;
            } else {
                index += 1;
            }
        }
        rows.push('COLUMN_' + index);
        rowIndexes.push(index.toString());
        this.notifyChange({ columnNames: rows, columnIndexes: rowIndexes });
    }


    getSearchIcon = () => {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 2.5 }}>
            <Icon icon="search" onClick={this.openBrowseDialog} />
        </div>;
    }

    public openBrowseDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = [];
        this.context.showDialog(
            ResourcePickerDialog,
            {
                fileNameLabel: i18n.t('datasource.common.dialog.resource'),
                mode: 'open',
                title: i18n.t('datasource.common.dialog.title'),
                onFileSelected: this.confirmFile,
                defaultPath: defaultPath,
                allowSearch: true,
                allowedMimes: [MIME_TYPES.EXCEL, 'xls', 'xlsx'],
            }
        );
    }

    private confirmFile = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => {
        const path = folderPath.length > 0 ? `${RepositoryApi.inst().getParentPath(folderPath)}/${getRealName(file)}` : `/${getRealName(file)}`;
        this.onFileUrlChange(path);
    }

    public render() {
        const tableRows = this.getRows();
        let httpConfigurationPanel;
        let searchIconAdornament;
        if (this.props.location && (this.props.location.startsWith('http://') || this.props.location.startsWith('https://'))) {
            httpConfigurationPanel = <HTTPConfigurationPanel {...this.props} />
        } else {
            searchIconAdornament = <InputAdornment position="end">
                <IconButton
                    edge="end"
                    style={{ padding: 0 }}
                >
                    {this.getSearchIcon()}
                </IconButton>
            </InputAdornment>;
        }
        return (<div className="tc-jsw-dataadapter-editor">

            <div className="tc-jsw-form-wrapper" style={{ flex: 1, alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}>

                <TextInput label={i18n.t('datasource.excel.name')}
                    help={i18n.t('datasource.excel.name.help')}
                    id="data-adapter-name"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.name}
                    onChange={this.onNameChange}
                    advancedValidator={advancedNameValidator}
                    InputProps={{
                        readOnly: this.props.readOnly,
                    }} />

                <TextInput label={i18n.t('datasource.excel.url')}
                    help={i18n.t('datasource.excel.url.help')}
                    id="excel-url-input"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.location}
                    onChange={this.onFileUrlChange}
                    advancedValidator={advancedUrlValidator}
                    InputProps={{
                        endAdornment: !this.props.readOnly ? searchIconAdornament : undefined,
                        readOnly: this.props.readOnly
                    }}
                />

                {httpConfigurationPanel}

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.props.queryExecuterMode}
                            onChange={this.onQueryExecuterChange}
                            name="queryExecuterMode"
                            color="primary"
                            disabled={this.props.readOnly}
                        />
                    }
                    title={i18n.t('datasource.excel.queryexecuter.help')}
                    label={i18n.t('datasource.excel.queryMode')}
                />

                <Select className={'tc-jsw-dataadapter-editor-text-input'}
                    label={i18n.t('datasource.excel.format')}
                    items={[{ key: 'autodetect', value: 'autodetect' }, { key: 'xls', value: 'XLS' }, { key: 'xlsx', value: 'XLSX' }]}
                    value={this.props.format}
                    onItemSelected={this.onFormatChange}
                    InputProps={{
                        readOnly: this.props.readOnly,
                    }}
                    disabled={this.props.readOnly}
                    WrapperProps={{
                        title: i18n.t('datasource.excel.format.help'),
                    }}/>

                <TextInput label={i18n.t('datasource.excel.datepattern')}
                    help={i18n.t('datasource.excel.datepattern.help')}
                    id="excel-date-pattern"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    // inputStyles={{ textAlign: 'center' }}
                    value={this.props.datePattern}
                    onChange={this.onDatePatternChange}
                    InputProps={{
                        readOnly: this.props.readOnly,
                        title: i18n.t('datasource.excel.descriptor.datepattern'),
                    }} />

                <TextInput label={i18n.t('datasource.excel.numberpattern')}
                    help={i18n.t("datasource.excel.numberpattern.help")}
                    id="excel-number-pattern"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.numberPattern}
                    onChange={this.onNumberPatternChange}
                    InputProps={{
                        readOnly: this.props.readOnly,
                        title: i18n.t('datasource.excel.descriptor.numberpattern'),
                    }} />

                <WritableCombo label={i18n.t('datasource.excel.timezone')}
                    id="excel-timezone"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    value={this.props.timeZone}
                    items={TIMEZONES.map((timezone) => { return { key: timezone.id, value: `${timezone.id} (${timezone.label})` } })}
                    onComboChange={this.onTimezoneChange}
                    disabled={this.props.readOnly}
                    InputProps={{
                        title: i18n.t('datasource.excel.timezone.help'),
                    }} />

                <WritableCombo label={i18n.t('datasource.excel.locale')}
                    id="excel-locale"
                    items={ALTERED_LOCALES}
                    value={this.props.locale}
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    onComboChange={this.onLocalChange}
                    disabled={this.props.readOnly}
                    InputProps={{
                        title: i18n.t('datasource.excel.locale.help'),
                    }} />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.props.useFirstRowAsHeader}
                            onChange={this.onSkipFirstRowChange}
                            name="useFirstLineAsHeader"
                            color="primary"
                            disabled={this.props.readOnly}
                        />
                    }
                    title={i18n.t('datasource.excel.skipfirstrow.help')}
                    label={i18n.t('datasource.excel.useFirstLineAsHeader')}
                />

                <TextInput label={i18n.t('datasource.excel.sheetSelection')}
                    // placeholder="postgres://"
                    id="excel-sheet-selection"
                    className={'tc-jsw-dataadapter-editor-text-input'}
                    // inputStyles={{ textAlign: 'center' }}
                    value={this.props.sheetSelection}
                    onChange={this.onSheetSelectionChange}
                    InputProps={{
                        readOnly: this.props.readOnly,
                        title: i18n.t('datasource.excel.sheet.help'),
                    }} />

                <div style={{ display: 'flex', alignItems: 'stretch' }} id="tableContainer">
                    <EditableTable cellStyle={{ textAlign: 'left' }} columnsWidths={this.getColumnSize()} onCellChange={this.onCellChange} cellData={tableRows} numRows={this.props.columnNames ? this.props.columnNames.length + 1 : 1} columnNames={[i18n.t('datasource.excel.columnName'), i18n.t('datasource.excel.columnIndex'), '']}
                        columnActions={[{ onClick: this.onRowDelete, icon: <DeleteIcon /> }]} onAddAction={this.onAddAction} readOnly={this.props.readOnly} />
                </div>
            </div>
        </div>);
    }



    private onNameChange = (str: string) => {
        this.notifyChange({ name: str });
    }

    private onFileUrlChange = (str: string) => {
        this.notifyChange({ location: str });
    }

    private onFormatChange = (str: string) => {
        this.notifyChange({ format: str });
    }

    private onDatePatternChange = (str: string) => {
        this.notifyChange({ datePattern: str });
    }

    private onNumberPatternChange = (str: string) => {
        this.notifyChange({ numberPattern: str });
    }

    private onLocalChange = (selectedKey: string | undefined, value: string) => {
        this.notifyChange({ locale: selectedKey ? selectedKey : value });
    }

    private onTimezoneChange = (selectedKey: string | undefined, value: string) => {
        this.notifyChange({ timeZone: selectedKey ? selectedKey : value });
    }

    private onSheetSelectionChange = (str: string) => {
        this.notifyChange({ sheetSelection: str });
    }

    private onSkipFirstRowChange = (event: React.FormEvent<HTMLElement>) => {
        this.notifyChange({ useFirstRowAsHeader: (event.target as HTMLInputElement).checked });
    }

    private onQueryExecuterChange = (event: React.FormEvent<HTMLElement>) => {
        this.notifyChange({ queryExecuterMode: (event.target as HTMLInputElement).checked });
    }

    private notifyChange = (data: any) => {
        if (this.props.onDataAdapterSettingsChange) {
            this.props.onDataAdapterSettingsChange(data);
        }
    }


}

ExcelDataAdapterEditor.contextType = RunContext;