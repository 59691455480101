/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { APDescriptor } from '../../ui/APDescriptor';
import { getPath, reg, UiProperty, UiPropertyProps } from '../../ui/UiProperty';

const TYPEID = 'BDatasetRun';
reg(TYPEID, (mc) => { return <UPDatasetRun mcontext={mc} />; });
export class PDatasetRun extends APDescriptor {

    public constructor(init: Partial<PDatasetRun>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

export class UPDatasetRun extends React.Component<UiPropertyProps> {
    public state = { selected: 0 }
    render() {
        let className = "jr-jrws-properties-content";
        if (this.props.mcontext.descriptor.className) {
            className += " " + this.props.mcontext.descriptor.className;
        }
        const d = this.props.mcontext.descriptor;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);

        // read styles from the model ... ? get also the files from template?

        const v = this.props.mcontext.model.getIn(p) as string | undefined;
        const isMain = (v === undefined || v.trim() === '');

        return <div
            style={this.props.mcontext.descriptor.style ? this.props.mcontext.descriptor.style : { display: 'block' }}
            className={className}>
            {this.props.mcontext.descriptor.layouts?.map((key, index) => {
                if (!isMain || key.id === d.id)
                    return <UiProperty key={index} mcontext={{ ...this.props.mcontext, descriptor: key }} />
            })}
        </div>
    }

}