/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StackedAreaSplineChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="802" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 802" {...props}>
    <defs>
       <clipPath id="highcharts-s3l465q-1">
          <path fill="none" d="M0 0H507V672H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="802" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M38 10H545V682H38z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" d="M79.5 10v672M122.5 10v672M164.5 10v672M206.5 10v672M248.5 10v672M291.5 10v672M333.5 10v672M375.5 10v672M417.5 10v672M460.5 10v672M502.5 10v672M544.5 10v672M37.5 10v672" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M38 682.5h507M38 598.5h507M38 514.5h507M38 430.5h507M38 346.5h507M38 262.5h507M38 178.5h507M38 94.5h507M38 9.5h507" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M38 10H545V682H38z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" stroke="#ccd6eb" d="M79.5 682v10M122.5 682v10M164.5 682v10M206.5 682v10M248.5 682v10M291.5 682v10M333.5 682v10M375.5 682v10M417.5 682v10M460.5 682v10M502.5 682v10M545.5 682v10M37.5 682v10" className="highcharts-tick" />
       <path fill="none" stroke="#ccd6eb" d="M38 682.5h507" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M38 10v672" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-areaspline-series highcharts-color-0" clipPath="url(#highcharts-s3l465q-1)" data-z-index="0.1" transform="translate(38 10)">
          <path fill="rgba(124,181,236,0.75)" d="M21.125 493.443s25.35 95.94 42.25 95.94 25.35-478.814 42.25-478.814 25.35 550.514 42.25 550.514 25.35 0 42.25-6.478 25.35-71.205 42.25-71.205 25.35 82.72 42.25 82.72 25.35-41.048 42.25-41.048 25.35 12.214 42.25 18.204 25.35 11.747 42.25 11.747 25.35-96.68 42.25-96.68 42.25 112.027 42.25 112.027-25.35-109.688-42.25-109.688-25.35 94.34-42.25 94.34-25.35-9.161-42.25-9.161-25.35 11.403-42.25 15.455-25.35 4.803-42.25 4.803-25.35-82.719-42.25-82.719-25.35 73.204-42.25 75.444c-16.9 2.24-25.35 2.24-42.25 2.24s-25.35-514.396-42.25-514.396-25.35 471.41-42.25 471.41-42.25-69.218-42.25-69.218" className="highcharts-area" data-z-index="0" />
          <path fill="none" stroke="#7cb5ec" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21.125 493.443s25.35 95.94 42.25 95.94 25.35-478.814 42.25-478.814 25.35 550.514 42.25 550.514 25.35 0 42.25-6.478 25.35-71.205 42.25-71.205 25.35 82.72 42.25 82.72 25.35-41.048 42.25-41.048 25.35 12.214 42.25 18.204 25.35 11.747 42.25 11.747 25.35-96.68 42.25-96.68 42.25 112.027 42.25 112.027" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M11.125 493.443h10s25.35 95.94 42.25 95.94 25.35-478.814 42.25-478.814 25.35 550.514 42.25 550.514 25.35 0 42.25-6.478 25.35-71.205 42.25-71.205 25.35 82.72 42.25 82.72 25.35-41.048 42.25-41.048 25.35 12.214 42.25 18.204 25.35 11.747 42.25 11.747 25.35-96.68 42.25-96.68 42.25 112.027 42.25 112.027h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-0 highcharts-areaspline-series highcharts-color-0 highcharts-tracker" data-z-index="0.1">
          <path fill="#7cb5ec" d="M25 493a4 4 0 110-.004zM67 589a4 4 0 110-.004zM109 111a4 4 0 110-.004zM151 661a4 4 0 110-.004zM194 655a4 4 0 110-.004zM236 583a4 4 0 110-.004zM278 666a4 4 0 110-.004zM320 625a4 4 0 110-.004zM363 643a4 4 0 110-.004zM405 655a4 4 0 110-.004zM447 558.343a4 4 0 110-.004zM489 670a4 4 0 110-.004z" className="highcharts-point highcharts-color-0" transform="translate(38 10)" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-areaspline-series highcharts-color-1" clipPath="url(#highcharts-s3l465q-1)" data-z-index="0.1" transform="translate(38 10)">
          <path fill="rgba(67,67,72,0.75)" d="M21.125 548.88s25.35 69.217 42.25 69.217 25.35-471.409 42.25-471.409 25.35 514.395 42.25 514.395 25.35 0 42.25-2.24c16.9-2.239 25.35-75.443 42.25-75.443s25.35 82.72 42.25 82.72 25.35-.752 42.25-4.804c16.9-4.052 25.35-15.455 42.25-15.455s25.35 9.162 42.25 9.162 25.35-94.34 42.25-94.34 42.25 109.687 42.25 109.687V672s-25.35-69.618-42.25-69.618-25.35 55.879-42.25 61.977c-16.9 6.097-25.35 6.097-42.25 6.097s-25.35-2.626-42.25-2.626-25.35 3.582-42.25 3.582-25.35-64.819-42.25-64.819S207.025 672 190.125 672s-25.35 0-42.25-1.252c-16.9-1.253-25.35-262.526-42.25-262.526S80.275 638.3 63.375 638.3s-42.25-17.408-42.25-17.408" className="highcharts-area" data-z-index="0" />
          <path fill="none" stroke="#434348" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21.125 548.88s25.35 69.217 42.25 69.217 25.35-471.409 42.25-471.409 25.35 514.395 42.25 514.395 25.35 0 42.25-2.24c16.9-2.239 25.35-75.443 42.25-75.443s25.35 82.72 42.25 82.72 25.35-.752 42.25-4.804c16.9-4.052 25.35-15.455 42.25-15.455s25.35 9.162 42.25 9.162 25.35-94.34 42.25-94.34 42.25 109.687 42.25 109.687" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M11.125 548.88h10s25.35 69.217 42.25 69.217 25.35-471.409 42.25-471.409 25.35 514.395 42.25 514.395 25.35 0 42.25-2.24c16.9-2.239 25.35-75.443 42.25-75.443s25.35 82.72 42.25 82.72 25.35-.752 42.25-4.804c16.9-4.052 25.35-15.455 42.25-15.455s25.35 9.162 42.25 9.162 25.35-94.34 42.25-94.34 42.25 109.687 42.25 109.687h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-1 highcharts-areaspline-series highcharts-color-1 highcharts-tracker" data-z-index="0.1">
          <path fill="#434348" d="M21 545l4 4-4 4-4-4zM63 614l4 4-4 4-4-4zM105 143l4 4-4 4-4-4zM147 657l4 4-4 4-4-4zM190 655l4 4-4 4-4-4zM232 579l4 4-4 4-4-4zM274 662l4 4-4 4-4-4zM316 657l4 4-4 4-4-4zM359 642l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(38 10)" />
          <path fill="#434348" strokeWidth="0.004" d="M401 651.023l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(38 10)" />
          <path fill="#434348" d="M443 557l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(38 10)" />
          <path fill="#434348" strokeWidth="0" d="M485 666.37l4 4-4 4-4-4z" className="highcharts-point highcharts-color-1" transform="translate(38 10)" />
       </g>
       <g className="highcharts-series highcharts-series-2 highcharts-areaspline-series highcharts-color-2" clipPath="url(#highcharts-s3l465q-1)" data-z-index="0.1" transform="translate(38 10)">
          <path fill="rgba(144,237,125,0.75)" d="M21.125 620.891s25.35 17.408 42.25 17.408 25.35-230.077 42.25-230.077 25.35 261.273 42.25 262.526c16.9 1.252 25.35 1.252 42.25 1.252s25.35-65.407 42.25-65.407 25.35 64.819 42.25 64.819 25.35-3.582 42.25-3.582 25.35 2.626 42.25 2.626 25.35 0 42.25-6.097c16.9-6.098 25.35-61.977 42.25-61.977S485.875 672 485.875 672H21.125" className="highcharts-area" data-z-index="0" />
          <path fill="none" stroke="#90ed7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21.125 620.891s25.35 17.408 42.25 17.408 25.35-230.077 42.25-230.077 25.35 261.273 42.25 262.526c16.9 1.252 25.35 1.252 42.25 1.252s25.35-65.407 42.25-65.407 25.35 64.819 42.25 64.819 25.35-3.582 42.25-3.582 25.35 2.626 42.25 2.626 25.35 0 42.25-6.097c16.9-6.098 25.35-61.977 42.25-61.977S485.875 672 485.875 672" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M11.125 620.891h10s25.35 17.408 42.25 17.408 25.35-230.077 42.25-230.077 25.35 261.273 42.25 262.526c16.9 1.252 25.35 1.252 42.25 1.252s25.35-65.407 42.25-65.407 25.35 64.819 42.25 64.819 25.35-3.582 42.25-3.582 25.35 2.626 42.25 2.626 25.35 0 42.25-6.097c16.9-6.098 25.35-61.977 42.25-61.977S485.875 672 485.875 672h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-markers highcharts-series-2 highcharts-areaspline-series highcharts-color-2 highcharts-tracker" data-z-index="0.1">
          <path fill="#90ed7d" d="M17 617h8v8h-8zM59 634h8v8h-8zM101 404h8v8h-8zM143 667h8v8h-8zM186 668h8v8h-8zM228 603h8v8h-8zM270 667h8v8h-8zM312 664h8v8h-8zM355 666h8v8h-8zM397 660h8v8h-8zM439 598h8v8h-8zM481 668h8v8h-8z" className="highcharts-point highcharts-color-2" transform="translate(38 10)" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(178 762)">
       <rect width="199" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-areaspline-series highcharts-color-0 highcharts-series-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1996</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-areaspline-series highcharts-color-1 highcharts-series-1" data-z-index="1" transform="translate(75.672 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1997</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-areaspline-series highcharts-color-2 highcharts-series-2" data-z-index="1" transform="translate(143.344 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>1998</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#90ed7d" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="61.718" y="698" transform="rotate(-45 61.718 698)">
          <tspan>Albuquerque</tspan>
       </text>
       <text x="103.968" y="698" transform="rotate(-45 103.968 698)">Anchorage</text>
       <text x="146.218" y="698" transform="rotate(-45 146.218 698)">Boise</text>
       <text x="188.468" y="698" transform="rotate(-45 188.468 698)">Butte</text>
       <text x="230.718" y="698" transform="rotate(-45 230.718 698)">Elgin</text>
       <text x="272.968" y="698" transform="rotate(-45 272.968 698)">Eugene</text>
       <text x="315.218" y="698" transform="rotate(-45 315.218 698)">Kirkland</text>
       <text x="357.468" y="698" transform="rotate(-45 357.468 698)">Lander</text>
       <text x="399.718" y="698" transform="rotate(-45 399.718 698)">Portland</text>
       <text x="441.968" y="698" transform="rotate(-45 441.968 698)">
          <tspan>San Francisco</tspan>
       </text>
       <text x="484.218" y="698" transform="rotate(-45 484.218 698)">Seattle</text>
       <text x="526.468" y="698" transform="rotate(-45 526.468 698)">
          <tspan>Walla Walla</tspan>
       </text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="23" y="687">0</text>
       <text x="23" y="603">1k</text>
       <text x="23" y="519">2k</text>
       <text x="23" y="435">3k</text>
       <text x="23" y="351">4k</text>
       <text x="23" y="267">5k</text>
       <text x="23" y="183">6k</text>
       <text x="23" y="99">7k</text>
       <text x="23" y="15">8k</text>
    </g>
 </svg>)};

export default StackedAreaSplineChartIcon;