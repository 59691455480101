/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import i18next from 'i18next';
import {Conf} from '@jss/js-rest-api';
import {I18nService} from "@jss/js-i18n";
import { en } from './assets/locales/en/messages';
import { it } from './assets/locales/it/messages';
import { fr } from './assets/locales/fr/messages';

const i18n = i18next.createInstance(
    {
        lng: Conf.get('jrws.user.locale'),
        fallbackLng: ['en'],
        supportedLngs: ['en','it', 'fr'],
        ns: ['jrws'],
        defaultNS: 'jrws',
        react: { useSuspense: false },
        interpolation: { escapeValue: false },
        resources: {
        },
    },
    // We must provide a function as second parameter, otherwise i18next errors
    (err) => {
        if (err) return console.log(err)
    },
)

i18n.addResourceBundle('en', 'jrws', en, true);
i18n.addResourceBundle('it', 'jrws', it, true);
i18n.addResourceBundle('fr', 'jrws', fr, true);

/*const fixKeys = () => {
    const newObject = {};
    const bundle = it;
    Object.keys(bundle).forEach((key) => {
        const split = key.split('.');
        let currentValue = newObject;
        split.forEach((segment, index) => {
            if (index === split.length - 1){
                currentValue[segment] = bundle[key];
            } else {
                if (!currentValue[segment]) {
                    currentValue[segment] = {};
                } 
                currentValue = currentValue[segment];
            }
        });
    });
    let json = JSON.stringify(newObject);
    json.replace(/\\"/g,"\uFFFF");  // U+ FFFF
    json = json.replace(/"([^"]+)":/g, '$1:').replace(/\uFFFF/g, '\\\"');
    console.log(json);
}

fixKeys();*/

I18nService.registerProvider('js-common',i18n);

export default i18n