/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { MessageInfo } from '@jss/js-rest-api';
import { TitleBar } from './TitleBar';
import { ToolBar } from './ToolBar';
import { IToolbarProvider } from './ToolbarProvider';

interface IHeaderProps {
    toolbarData?: IToolbarProvider,
    message?: MessageInfo,
    resourceName?: string,
    warningMessage?: string,
    documentType?: string,
    tabs: {
        icon: React.ReactElement,
        label: string,
    }[],
    currentTabIndex: number,
    onTabChange?: (newTabIndex: number) => void,
    closeMessage?: () => void,
}

export class Header extends React.Component<IHeaderProps> {

    private onTabChange = (newTab: number) => {
        if (this.props.onTabChange){
            this.props.onTabChange(newTab);
        }
    }

    public render() {
        if (this.props.toolbarData) {
            return <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TitleBar tabList={this.props.tabs} documentName={this.props.resourceName} warningMessage={this.props.warningMessage}
                            currentTabIndex={this.props.currentTabIndex} onTabSwitch={this.onTabChange} documentType={this.props.documentType} />
                </div>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
                    <ToolBar message={this.props.message} closeMessage={this.props.closeMessage} toolbarData={this.props.toolbarData} />
                </div>
            </div>
        }
        return <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <TitleBar tabList={this.props.tabs} documentName={this.props.resourceName} warningMessage={this.props.warningMessage}
                          currentTabIndex={this.props.currentTabIndex} onTabSwitch={this.onTabChange} documentType={this.props.documentType} />
            </div>
        </div>
    }
}
