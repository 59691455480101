/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map } from 'immutable';
import { getKey, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import React from 'react';
import { Select } from '@jss/js-common';
import { APDescriptor } from '../../ui/APDescriptor';
import i18n from '../../../../../i18n';

const TYPEID = 'enumWithDatasets';
reg(TYPEID, (mc) => { return <UPEnumDatasets mcontext={mc} />; });
export class PEnumDatasets extends APDescriptor {
    items: { key: string, value: string, deprecated?: boolean }[];
    groupProperty: string;
    public constructor(init: Partial<PEnumDatasets>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
export class UPEnumDatasets extends React.Component<UiPropertyProps> {
    render() {
        const d = this.props.mcontext.descriptor as PEnumDatasets;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);

        const items = this.getDatasets();

        // read styles from the model ... ? get also the files from template?

        let v = this.props.mcontext.model.getIn(p);
        if (!v) v = items[0].key; 
        return <Select key={getKey(p)} items={items} defaultValue={''}
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            value={v}
            onItemSelected={this.onNameChange}
            label={i18n.t(d.label)}
            inline={true} size={'large'}
        />
    }
    public onNameChange = (str: string) => {
        if(str === 'main')
            str = undefined;
        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: str });
        });
    }
    private getDatasets =(): { key: string, value: string }[] =>{
        const items: { key: string, value: string }[] = [{ key: 'main', value: 'Main Dataset' }];
        const subdatasets = this.props.mcontext.model.getIn(['subdatasets']) as List<Map<string, any>> | undefined;
        if (subdatasets) {
            subdatasets.forEach((subdataset) => {
                const dsname = subdataset.get('name');
                items.push({ key: dsname, value: dsname });
            })
        }
        return items;
    }
}