/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import i18n from "../../../../../i18n";
import { APDescriptor } from "../../ui/APDescriptor";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { PCategories } from "../../ui/composite/UPCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { PBoolean } from "../../ui/primitive/UPBoolean";
import { PButtonGroup } from "../../ui/primitive/UPButtonGroup";
import { PButtonsEnum } from "../../ui/primitive/UPButtonsEnum";
import { PConnectedFontName } from "../../ui/primitive/UPConnectedFontName";
import { PDefaultStyle } from "../../ui/primitive/UPDefaultStyle";
import { PEnum } from "../../ui/primitive/UPEnum";
import { PExpression } from "../../ui/primitive/UPExpression";
import { PInt } from "../../ui/primitive/UPInteger";
import { PString } from "../../ui/primitive/UPString";
import { FAppearance, MAppearanceStyle } from "../common/MAppearance";
import { MLinePenStyle } from "../common/MLinePen";
import { MParagraphStyle } from "../common/MParagraph";
import { FImage } from "../elements/MImage";
import { PParentStyles } from "./UPParentStyles";


export const FStyle = {
    name: new PString({ id: 'name', label: 'Name', canBeEmpty: false, canBeNull: false }),
    parentStyle: new PParentStyles({ id: 'parentStyle', label: 'Parent Style' }),
    isDefault: new PDefaultStyle({ id: 'isDefault', label: 'Is Default' }),

}

const HorizontalTextAlignEnum = new PButtonsEnum({
    id: 'hTextAlign', label: 'Horizontal Text Alignment', inheritedPropertyId: 'textAlignment',
    items: [
        { key: 'Left', value: i18n.t('HorizontalTextAlign.Left'), icon: 'textALeft' },
        { key: 'Center', value: i18n.t('HorizontalTextAlign.Center'), icon: 'textACenter' },
        { key: 'Right', value: i18n.t('HorizontalTextAlign.Right'), icon: 'textARight' },
        { key: 'Justified', value: i18n.t('HorizontalTextAlign.Justify'), icon: 'textAJustify' }
    ]
});

const VerticalTextAlignEnum = new PButtonsEnum({
    id: 'vTextAlign', label: 'Vertical Text Alignment', inheritedPropertyId: 'verticalAlignment',
    items: [
        { key: 'Top', value: i18n.t('VerticalTextAlign.Top'), icon: 'textATop' },
        { key: 'Middle', value: i18n.t('VerticalTextAlign.Middle'), icon: 'textAMiddle' },
        { key: 'Bottom', value: i18n.t('VerticalTextAlign.Bottom'), icon: 'textABottom' },
        { key: 'Justified', value: i18n.t('VerticalTextAlign.Justify'), icon: 'textAStretch' }
    ]
});

const HorizontalImageAlignEnum = new PButtonsEnum({
    id: 'hImageAlign', label: 'Horizontal Image Alignment',
    items: [
        { key: 'Left', value: i18n.t('HorizontalImageAlign.Left'), icon: 'imageALeft' },
        { key: 'Center', value: i18n.t('HorizontalImageAlign.Center'), icon: 'imageACenter' },
        { key: 'Right', value: i18n.t('HorizontalImageAlign.Right'), icon: 'imageARight' }
    ]
});

const VerticalImageAlignEnum = new PButtonsEnum({
    id: 'vImageAlign', label: 'Vertical Image Alignment',
    items: [
        { key: 'Top', value: i18n.t('VerticalImageAlign.Top'), icon: 'imageATop' },
        { key: 'Middle', value: i18n.t('VerticalImageAlign.Middle'), icon: 'imageAMiddle' },
        { key: 'Bottom', value: i18n.t('VerticalImageAlign.Bottom'), icon: 'imageABottom' }
    ]
});

const StyleFont = {
    fontSize: new PInt({ id: 'fontSize', label: 'Size', min: 0, inheritedPropertyId: 'fontSize' }),
    fontName: new PConnectedFontName({
        id: 'fontName', label: 'Name', inheritedPropertyId: 'fontName', writeable: true
    }),
    isBold: new PBoolean({ id: 'isBold', label: 'Bold', icon: 'textBold' }),
    isItalic: new PBoolean({ id: 'isItalic', label: 'Italic', icon: 'textItalic' }),
    isUnderline: new PBoolean({ id: 'isUnderline', label: 'Underline', icon: 'textUnderline' }),
    isStrikeThrough: new PBoolean({ id: 'isStrikeThrough', label: 'Strike Through', icon: 'textStrikethrough' }),

    pdfFontName: new PEnum({
        id: 'pdfFontName', label: 'PDF Font Name', deprecated: true,
        items: [{ key: 'Helvetica', value: 'Helvetica' }, { key: 'Helvetica-Bold', value: 'Helvetica-Bold' }
            , { key: 'Helvetica-BoldOblique', value: 'Helvetica-BoldOblique' }
            , { key: 'Helvetica-Oblique', value: 'Helvetica-Oblique' }
            , { key: 'Courier', value: 'Courier' }
            , { key: 'Courier-Bold', value: 'Courier-Bold' }
            , { key: 'Courier-BoldOblique', value: 'Courier-BoldOblique' }
            , { key: 'Courier-Oblique', value: 'Courier-Oblique' }
            , { key: 'Symbol', value: 'Symbol' }
            , { key: 'Times-Roman', value: 'Times-Roman' }
            , { key: 'Times-Bold', value: 'Times-Bold' }
            , { key: 'Times-BoldItalic', value: 'Times-BoldItalic' }
            , { key: 'Times-Italic', value: 'Times-Italic' }
            , { key: 'ZapfDingbats', value: 'ZapfDingbats' }
            , { key: 'STSong-Light', value: 'STSong-Light' }
            , { key: 'MHei-Medium', value: 'MHei-Medium' }
            , { key: 'MSung-Light', value: 'MSung-Light' }
            , { key: 'HeiseiKakuGo-W5', value: 'HeiseiKakuGo-W5' }
            , { key: 'HeiseiMin-W3', value: 'HeiseiMin-W3' }
            , { key: 'HYGoThic-Medium', value: 'HYGoThic-Medium' }
            , { key: 'HYSMyeongJo-Medium', value: 'HYSMyeongJo-Medium' }
        ]
    }),
    pdfEncoding: new PEnum({
        id: 'pdfEncoding', label: 'PDF Encoding', deprecated: true,
        items: [{ key: '', value: '' },
        { key: 'Cp1250', value: 'CP1250 (Central European)' },
        { key: 'Cp1251', value: 'CP1251 (Cyrillic)' },
        { key: 'Cp1252', value: 'CP1252 (Western European ANSI aka WinAnsi)' },
        { key: 'Cp1253', value: 'CP1253 (Greek)' },
        { key: 'Cp1254', value: 'CP1254 (Turkish)' },
        { key: 'Cp1255', value: 'CP1255 (Hebrew)' },
        { key: 'Cp1256', value: 'CP1256 (Arabic)' },
        { key: 'Cp1257', value: 'CP1257 (Baltic)' },
        { key: 'Cp1258', value: 'CP1258 (Vietnamese)' },
        { key: 'UniGB-UCS2-H', value: 'UniGB-UCS2-H (Chinese Simplified)' },
        { key: 'UniGB-UCS2-V', value: 'UniGB-UCS2-V (Chinese Simplified)' },
        { key: 'UniCNS-UCS2-H', value: 'UniCNS-UCS2-H (Chinese traditional)' },
        { key: 'UniCNS-UCS2-V', value: 'UniCNS-UCS2-V (Chinese traditional)' },
        { key: 'UniJIS-UCS2-H', value: 'UniJIS-UCS2-H (Japanese)' },
        { key: 'UniJIS-UCS2-V', value: 'UniJIS-UCS2-V (Japanese)' },
        { key: 'UniJIS-UCS2-HW-H', value: 'UniJIS-UCS2-HW-H (Japanese)' },
        { key: 'UniJIS-UCS2-HW-V', value: 'UniJIS-UCS2-HW-V (Japanese)' },
        { key: 'UniKS-UCS2-H', value: 'UniKS-UCS2-H (Korean)' },
        { key: 'UniKS-UCS2-V', value: 'UniKS-UCS2-V (Korean)' },
        { key: 'Identity-H', value: 'Identity-H (Unicode with horizontal writing)' },
        { key: 'Identity-V', value: 'Identity-V (Unicode with vertical writing)' }
        ]
    }),
    isPdfEmbedded: new PBoolean({ id: 'isPdfEmbedded', label: 'PDF Embedded', deprecated: true })

};

export const MStyleTextElement: APDescriptor = {
    type: 'MTextElement',
    id: 'styleTextElementPropertiesDescriptor',
    layouts: [new PAccordeon({
        label: 'Text Arrangement', expanded: true,
        layouts: [HorizontalTextAlignEnum, VerticalTextAlignEnum]
    }), new PAccordeon({
        label: 'Font', expanded: true,
        layouts: [StyleFont.fontName, StyleFont.fontSize,
        new PButtonGroup({ label: 'Style', layouts: [StyleFont.isBold, StyleFont.isItalic, StyleFont.isUnderline, StyleFont.isStrikeThrough] })]
    }), new PAccordeon({
        label: 'PDF',
        layouts: [StyleFont.pdfFontName, StyleFont.pdfEncoding, StyleFont.isPdfEmbedded]
    }), MParagraphStyle]
};

export const MStyle: APDescriptor = new PCategories({
    id: 'MStyle',
    layouts: [
        new PDiv({ id: 'style', label: 'Style', layouts: [FStyle.name, FStyle.isDefault, FAppearance.style] }),
        new PDiv({ id: 'appearance', label: 'Appearance', layouts: [...MAppearanceStyle.layouts] }),
        new PDiv({ id: 'text', label: 'Text', layouts: [...MStyleTextElement.layouts] }),
        new PDiv({
            id: 'image', label: 'Image', layouts: [{ ...FImage.fill, default: undefined },
            new PAccordeon({
                label: 'Image Arrangement', expanded: true,
                layouts: [HorizontalImageAlignEnum, VerticalImageAlignEnum, { ...FImage.scaleImage, default: undefined }]
            })]
        }),
        new PDiv({ id: 'line', label: 'Line', layouts: [...MLinePenStyle.layouts] }),
    ]
});

export const MStyleTemplateReference: APDescriptor = new PDiv({
    id: 'MStyleTemplateReference',
    style: {paddingTop: 5},
    layouts: [
        new PExpression({ id: 'expression', label: 'Expression' }),
    ]
});
