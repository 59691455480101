/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import { registerJrxmlEditorMimes, registerTextEditorMimes, registerDataAdapterEditorMimes, registerJRIOContextEditorMimes, registerImageEditorMimes } from '@jss/js-rest-api';
import { JrxmlEditorViewWrapper } from './JrxmlEditorViewWrapper';
import { DataAdapterEditorViewWrapper } from './DataAdapterEditorViewWrapper';
import { TextualEditorViewWrapper } from '../editors/TextualEditorViewWrapper';
import { JRIOContextEditorViewWrapper } from './JRIOContextEditorViewWrapper';
import { ImageEditorViewWrapper } from './ImageEditorViewWrapper';


export function registerMimes() {
    registerJrxmlEditorMimes(
        ({descriptor, path, doExit, onFirstSave}) => {
            return (
                <JrxmlEditorViewWrapper
                    descriptor={descriptor}
                    path={path}
                    doExit={doExit}
                    onFirstSave={onFirstSave}
                />
            );
        }
    );

    registerTextEditorMimes(
        ({descriptor, path, doExit, onFirstSave}) => {
            return (
                <TextualEditorViewWrapper
                    descriptor={descriptor}
                    path={path}
                    doExit={doExit}
                    onFirstSave={onFirstSave}
                />
            );
        }
    );

    registerDataAdapterEditorMimes(
        ({descriptor, path, doExit, onFirstSave}) => {
            return (
                <DataAdapterEditorViewWrapper
                    descriptor={descriptor}
                    path={path}
                    doExit={doExit}
                    onFirstSave={onFirstSave}
                />
            );
        }
    );

    registerJRIOContextEditorMimes(
        ({descriptor, path, doExit, onFirstSave}) => {
            return (
                <JRIOContextEditorViewWrapper
                    descriptor={descriptor}
                    path={path}
                    doExit={doExit}
                    onFirstSave={onFirstSave}
                />
            );
        }
    );

    registerImageEditorMimes(
        ({descriptor, path, doExit, onFirstSave}) => {
            return (
                <ImageEditorViewWrapper
                    descriptor={descriptor}
                    path={path}
                    doExit={doExit}
                    onFirstSave={onFirstSave}
                />
            );
        }
    );
}
