/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const StackedBarChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="802"
      height="493"
      version="1.1"
      style={{
         WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
         WebkitUserSelect: "none",
         MozUserSelect: "none",
         userSelect: "none",
         position: "relative",
      }}
      cursor="default"
      overflow="hidden"
      viewBox="0 0 802 493"
      {...props}
   >
      <defs>
         <linearGradient
            id="19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100"
            x1="0"
            x2="0"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#cbcbcb" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#e9e9e9" stopOpacity="0.5"></stop>
         </linearGradient>
         <clipPath id="C37857F9-1F66-42C5-99CD-6BC483BF7D91">
            <path d="M88 55H778V411H88z"></path>
         </clipPath>
         <linearGradient
            id="19-540-rgba_175_216_248_1_-rgba_255_255_255_1_"
            x1="1"
            x2="0"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#afd8f8"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <clipPath id="5FBC88D2-2885-4A8A-B92C-9F4A59B8F74C">
            <path d="M88 55H778V411H88z"></path>
         </clipPath>
         <linearGradient
            id="19-540-rgba_246_189_15_1_-rgba_255_255_255_1_"
            x1="1"
            x2="0"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#f6bd0f"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <clipPath id="154994B8-4CCB-4A52-B876-AD2E4814E7AF">
            <path d="M88 55H778V411H88z"></path>
         </clipPath>
         <linearGradient
            id="19-540-rgba_139_186_0_1_-rgba_255_255_255_1_"
            x1="1"
            x2="0"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#8bba00"></stop>
            <stop offset="100%" stopColor="#fff"></stop>
         </linearGradient>
         <linearGradient
            id="19-360-rgba_175_216_248_1__0-rgba_175_216_248_1__30-rgba_223_240_252_1__60-rgba_175_216_248_1__90-rgba_175_216_248_1__100"
            x1="0"
            x2="1"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#afd8f8"></stop>
            <stop offset="30%" stopColor="#afd8f8"></stop>
            <stop offset="60%" stopColor="#dff0fc"></stop>
            <stop offset="90%" stopColor="#afd8f8"></stop>
            <stop offset="100%" stopColor="#afd8f8"></stop>
         </linearGradient>
         <linearGradient
            id="19-360-rgba_246_189_15_1__0-rgba_246_189_15_1__30-rgba_252_229_159_1__60-rgba_246_189_15_1__90-rgba_246_189_15_1__100"
            x1="0"
            x2="1"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#f6bd0f"></stop>
            <stop offset="30%" stopColor="#f6bd0f"></stop>
            <stop offset="60%" stopColor="#fce59f"></stop>
            <stop offset="90%" stopColor="#f6bd0f"></stop>
            <stop offset="100%" stopColor="#f6bd0f"></stop>
         </linearGradient>
         <linearGradient
            id="19-360-rgba_139_186_0_1__0-rgba_139_186_0_1__30-rgba_209_228_153_1__60-rgba_139_186_0_1__90-rgba_139_186_0_1__100"
            x1="0"
            x2="1"
            y1="0"
            y2="0"
         >
            <stop offset="0%" stopColor="#8bba00"></stop>
            <stop offset="30%" stopColor="#8bba00"></stop>
            <stop offset="60%" stopColor="#d1e499"></stop>
            <stop offset="90%" stopColor="#8bba00"></stop>
            <stop offset="100%" stopColor="#8bba00"></stop>
         </linearGradient>
      </defs>
      <g className="raphael-group-20-background">
         <rect
            width="800"
            height="491"
            x="1"
            y="1"
            fill="url('#19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100')"
            rx="0"
            ry="0"
         ></rect>
         <rect
            width="801"
            height="492"
            x="0.5"
            y="0.5"
            fill="none"
            stroke="#767575"
            strokeOpacity="0.5"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-27-canvas">
         <rect
            width="692"
            height="358"
            x="87"
            y="54"
            fill="none"
            stroke="#545454"
            strokeWidth="2"
            rx="0"
            ry="0"
         ></rect>
         <rect
            width="690"
            height="356"
            x="88"
            y="55"
            fill="#FFF"
            strokeWidth="0"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-30-axisbottom">
         <g
            fill="#767575"
            fillOpacity="0.1"
            stroke="#000"
            strokeWidth="0"
            className="raphael-group-39-x-axis-bands"
         >
            <rect width="138" height="356" x="226" y="55" rx="0" ry="0"></rect>
            <rect width="138" height="356" x="502" y="55" rx="0" ry="0"></rect>
         </g>
         <g
            fill="none"
            stroke="#717170"
            strokeOpacity="0.4"
            className="raphael-group-41-x-axis-lines"
            shapeRendering="crispedges"
         >
            <path d="M226 55v356M364 55v356M502 55v356M640 55v356"></path>
         </g>
         <g className="fusioncharts-xaxis-0-gridlabels">
            <text
               x="82"
               y="80.429"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="82" dy="3.999">
                  Denmark
               </tspan>
            </text>
            <text
               x="82"
               y="131.286"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="82" dy="3.997">
                  France
               </tspan>
            </text>
            <text
               x="82"
               y="182.143"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="82" dy="3.994">
                  Germany
               </tspan>
            </text>
            <text
               x="82"
               y="233"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="82" dy="3.992">
                  Italy
               </tspan>
            </text>
            <text
               x="82"
               y="283.857"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="82" dy="3.998">
                  Spain
               </tspan>
            </text>
            <text
               x="82"
               y="334.714"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="82" dy="4.003">
                  UK
               </tspan>
            </text>
            <text
               x="82"
               y="385.571"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="end"
            >
               <tspan x="82" dy="4.001">
                  USA
               </tspan>
            </text>
         </g>
         <g className="fusioncharts-yaxis-0-gridlabels">
            <text
               x="88"
               y="427"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="88" dy="-2">
                  0.00
               </tspan>
            </text>
            <text
               x="226"
               y="427"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="226" dy="-2">
                  10.00K
               </tspan>
            </text>
            <text
               x="364"
               y="427"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="364" dy="-2">
                  20.00K
               </tspan>
            </text>
            <text
               x="502"
               y="427"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="502" dy="-2">
                  30.00K
               </tspan>
            </text>
            <text
               x="640"
               y="427"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="640" dy="-2">
                  40.00K
               </tspan>
            </text>
            <text
               x="778"
               y="427"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="middle"
            >
               <tspan x="778" dy="-2">
                  50.00K
               </tspan>
            </text>
         </g>
         <g className="raphael-group-50-axis-name">
            <text
               fill="#555"
               className="fusioncharts-yaxis-0-title"
               fontSize="10"
               textAnchor="middle"
               transform="translate(433 432)"
            >
               <tspan x="0" dy="10">
                  Orders
               </tspan>
            </text>
            <text
               fill="#555"
               className="fusioncharts-xaxis-0-title"
               fontSize="10"
               textAnchor="middle"
               transform="rotate(-90 125.5 107.5)"
            >
               <tspan x="0" dy="10">
                  States
               </tspan>
            </text>
         </g>
      </g>
      <g className="raphael-group-21-dataset">
         <g
            fill="none"
            stroke="#404040"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="raphael-group-150-shadow"
            transform="translate(1 1)"
         >
            <rect
               width="10"
               height="40"
               x="145.5"
               y="60.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="10"
               height="40"
               x="145.5"
               y="60.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="10"
               height="40"
               x="145.5"
               y="60.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="29"
               height="41"
               x="263.5"
               y="110.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="29"
               height="41"
               x="263.5"
               y="110.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="29"
               height="41"
               x="263.5"
               y="110.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="77"
               height="41"
               x="555.5"
               y="161.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="77"
               height="41"
               x="555.5"
               y="161.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="77"
               height="41"
               x="555.5"
               y="161.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="6"
               height="41"
               x="123.5"
               y="212.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="6"
               height="41"
               x="123.5"
               y="212.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="6"
               height="41"
               x="123.5"
               y="212.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="6"
               height="41"
               x="123.5"
               y="263.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="6"
               height="41"
               x="123.5"
               y="263.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="6"
               height="41"
               x="123.5"
               y="263.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="20"
               height="41"
               x="210.5"
               y="314.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="20"
               height="41"
               x="210.5"
               y="314.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="20"
               height="41"
               x="210.5"
               y="314.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="95"
               height="40"
               x="658.5"
               y="365.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="95"
               height="40"
               x="658.5"
               y="365.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="95"
               height="40"
               x="658.5"
               y="365.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
         </g>
         <g
            fill="none"
            stroke="#404040"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="raphael-group-112-shadow"
            transform="translate(1 1)"
         >
            <rect
               width="19"
               height="40"
               x="126.5"
               y="60.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="19"
               height="40"
               x="126.5"
               y="60.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="19"
               height="40"
               x="126.5"
               y="60.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="59"
               height="41"
               x="204.5"
               y="110.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="59"
               height="41"
               x="204.5"
               y="110.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="59"
               height="41"
               x="204.5"
               y="110.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="156"
               height="41"
               x="399.5"
               y="161.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="156"
               height="41"
               x="399.5"
               y="161.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="156"
               height="41"
               x="399.5"
               y="161.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="12"
               height="41"
               x="111.5"
               y="212.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="12"
               height="41"
               x="111.5"
               y="212.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="12"
               height="41"
               x="111.5"
               y="212.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="12"
               height="41"
               x="111.5"
               y="263.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="12"
               height="41"
               x="111.5"
               y="263.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="12"
               height="41"
               x="111.5"
               y="263.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="41"
               height="41"
               x="169.5"
               y="314.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="41"
               height="41"
               x="169.5"
               y="314.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="41"
               height="41"
               x="169.5"
               y="314.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="190"
               height="40"
               x="468.5"
               y="365.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="190"
               height="40"
               x="468.5"
               y="365.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="190"
               height="40"
               x="468.5"
               y="365.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
         </g>
         <g
            fill="none"
            stroke="#404040"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="raphael-group-74-shadow"
            transform="translate(1 1)"
         >
            <rect
               width="39"
               height="40"
               x="87.5"
               y="60.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="39"
               height="40"
               x="87.5"
               y="60.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="39"
               height="40"
               x="87.5"
               y="60.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="117"
               height="41"
               x="87.5"
               y="110.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="117"
               height="41"
               x="87.5"
               y="110.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="117"
               height="41"
               x="87.5"
               y="110.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="312"
               height="41"
               x="87.5"
               y="161.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="312"
               height="41"
               x="87.5"
               y="161.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="312"
               height="41"
               x="87.5"
               y="161.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="24"
               height="41"
               x="87.5"
               y="212.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="24"
               height="41"
               x="87.5"
               y="212.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="24"
               height="41"
               x="87.5"
               y="212.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="24"
               height="41"
               x="87.5"
               y="263.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="24"
               height="41"
               x="87.5"
               y="263.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="24"
               height="41"
               x="87.5"
               y="263.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="82"
               height="41"
               x="87.5"
               y="314.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="82"
               height="41"
               x="87.5"
               y="314.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="82"
               height="41"
               x="87.5"
               y="314.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="381"
               height="40"
               x="87.5"
               y="365.5"
               strokeOpacity="0.05"
               strokeWidth="5"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="381"
               height="40"
               x="87.5"
               y="365.5"
               strokeOpacity="0.1"
               strokeWidth="3"
               rx="0"
               ry="0"
            ></rect>
            <rect
               width="381"
               height="40"
               x="87.5"
               y="365.5"
               strokeOpacity="0.15"
               rx="0"
               ry="0"
            ></rect>
         </g>
         <g
            fill="url('#19-540-rgba_175_216_248_1_-rgba_255_255_255_1_')"
            stroke="#333"
            className="raphael-group-72-bars"
            clipPath="url('#C37857F9-1F66-42C5-99CD-6BC483BF7D91')"
         >
            <rect width="39" height="40" x="87.5" y="60.5" rx="0" ry="0"></rect>
            <rect width="117" height="41" x="87.5" y="110.5" rx="0" ry="0"></rect>
            <rect width="312" height="41" x="87.5" y="161.5" rx="0" ry="0"></rect>
            <rect width="24" height="41" x="87.5" y="212.5" rx="0" ry="0"></rect>
            <rect width="24" height="41" x="87.5" y="263.5" rx="0" ry="0"></rect>
            <rect width="82" height="41" x="87.5" y="314.5" rx="0" ry="0"></rect>
            <rect width="381" height="40" x="87.5" y="365.5" rx="0" ry="0"></rect>
         </g>
         <g
            fill="url('#19-540-rgba_246_189_15_1_-rgba_255_255_255_1_')"
            stroke="#333"
            className="raphael-group-110-bars"
            clipPath="url('#5FBC88D2-2885-4A8A-B92C-9F4A59B8F74C')"
         >
            <rect width="19" height="40" x="126.5" y="60.5" rx="0" ry="0"></rect>
            <rect width="59" height="41" x="204.5" y="110.5" rx="0" ry="0"></rect>
            <rect
               width="156"
               height="41"
               x="399.5"
               y="161.5"
               rx="0"
               ry="0"
            ></rect>
            <rect width="12" height="41" x="111.5" y="212.5" rx="0" ry="0"></rect>
            <rect width="12" height="41" x="111.5" y="263.5" rx="0" ry="0"></rect>
            <rect width="41" height="41" x="169.5" y="314.5" rx="0" ry="0"></rect>
            <rect
               width="190"
               height="40"
               x="468.5"
               y="365.5"
               rx="0"
               ry="0"
            ></rect>
         </g>
         <g
            fill="url('#19-540-rgba_139_186_0_1_-rgba_255_255_255_1_')"
            stroke="#333"
            className="raphael-group-148-bars"
            clipPath="url('#154994B8-4CCB-4A52-B876-AD2E4814E7AF')"
         >
            <rect width="10" height="40" x="145.5" y="60.5" rx="0" ry="0"></rect>
            <rect width="29" height="41" x="263.5" y="110.5" rx="0" ry="0"></rect>
            <rect width="77" height="41" x="555.5" y="161.5" rx="0" ry="0"></rect>
            <rect width="6" height="41" x="123.5" y="212.5" rx="0" ry="0"></rect>
            <rect width="6" height="41" x="123.5" y="263.5" rx="0" ry="0"></rect>
            <rect width="20" height="41" x="210.5" y="314.5" rx="0" ry="0"></rect>
            <rect width="95" height="40" x="658.5" y="365.5" rx="0" ry="0"></rect>
         </g>
      </g>
      <g className="raphael-group-26-datalabels">
         <g className="raphael-group-203-datalabel">
            <text
               x="107"
               y="80.5"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="107" dy="4">
                  2.79K
               </tspan>
            </text>
            <text
               x="146"
               y="131"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="146" dy="4">
                  8.48K
               </tspan>
            </text>
            <text
               x="243.5"
               y="182"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="243.5" dy="4">
                  22.57K
               </tspan>
            </text>
            <text
               x="104.828"
               y="233"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="104.828" dy="4">
                  1.73K
               </tspan>
            </text>
            <text
               x="104.828"
               y="284"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="104.828" dy="4">
                  1.72K
               </tspan>
            </text>
            <text
               x="128.5"
               y="335"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="128.5" dy="4">
                  5.91K
               </tspan>
            </text>
            <text
               x="278"
               y="385.5"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="278" dy="4">
                  27.54K
               </tspan>
            </text>
         </g>
         <g className="raphael-group-211-datalabel">
            <text
               x="136"
               y="80.5"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="136" dy="4">
                  1.40K
               </tspan>
            </text>
            <text
               x="234"
               y="131"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="234" dy="4">
                  4.24K
               </tspan>
            </text>
            <text
               x="477.5"
               y="182"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="477.5" dy="4">
                  11.28K
               </tspan>
            </text>
            <text
               x="117.5"
               y="233"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="117.5" dy="4">
                  864.44
               </tspan>
            </text>
            <text
               x="117.5"
               y="284"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="117.5" dy="4">
                  861.89
               </tspan>
            </text>
            <text
               x="190"
               y="335"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="190" dy="4">
                  2.95K
               </tspan>
            </text>
            <text
               x="563.5"
               y="385.5"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="563.5" dy="4">
                  13.77K
               </tspan>
            </text>
         </g>
         <g className="raphael-group-219-datalabel">
            <text
               x="150.5"
               y="80.5"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="150.5" dy="4">
                  698.10
               </tspan>
            </text>
            <text
               x="278"
               y="131"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="278" dy="4">
                  2.12K
               </tspan>
            </text>
            <text
               x="594"
               y="182"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="594" dy="4">
                  5.64K
               </tspan>
            </text>
            <text
               x="126.5"
               y="233"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="126.5" dy="4">
                  432.22
               </tspan>
            </text>
            <text
               x="126.5"
               y="284"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="126.5" dy="4">
                  430.95
               </tspan>
            </text>
            <text
               x="220.5"
               y="335"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="220.5" dy="4">
                  1.48K
               </tspan>
            </text>
            <text
               x="706"
               y="385.5"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="706" dy="4">
                  6.89K
               </tspan>
            </text>
         </g>
      </g>
      <g className="fusioncharts-legend" transform="translate(283.5 452.5)">
         <rect
            width="305"
            height="25"
            fill="none"
            stroke="#404040"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeOpacity="0.05"
            strokeWidth="5"
            rx="0"
            ry="0"
            transform="translate(1 1)"
         ></rect>
         <rect
            width="305"
            height="25"
            fill="none"
            stroke="#404040"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeOpacity="0.1"
            strokeWidth="3"
            rx="0"
            ry="0"
            transform="translate(1 1)"
         ></rect>
         <rect
            width="305"
            height="25"
            fill="none"
            stroke="#404040"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeOpacity="0.15"
            rx="0"
            ry="0"
            transform="translate(1 1)"
         ></rect>
         <rect
            width="305"
            height="25"
            fill="#FFF"
            stroke="#545454"
            rx="0"
            ry="0"
         ></rect>
         <text
            x="23"
            y="6.5"
            fill="#555"
            className="fusioncharts-legenditem"
            cursor="pointer"
            fontSize="10"
         >
            <tspan x="23" dy="9.25">
               Double Freight
            </tspan>
         </text>
         <path
            fill="url('#19-360-rgba_175_216_248_1__0-rgba_175_216_248_1__30-rgba_223_240_252_1__60-rgba_175_216_248_1__90-rgba_175_216_248_1__100')"
            stroke="#CFE8FB"
            strokeWidth="0.5"
            d="M7 7h6.6v2.75H7zm0 4.125h11v2.75H7zm0 4.125h3.3V18H7z"
            cursor="pointer"
         ></path>
         <rect
            width="96"
            height="17"
            x="4"
            y="4"
            fill="silver"
            fillOpacity="0"
            cursor="pointer"
            rx="0"
            ry="0"
         ></rect>
         <text
            x="129.85"
            y="6.5"
            fill="#555"
            className="fusioncharts-legenditem"
            cursor="pointer"
            fontSize="10"
         >
            <tspan x="129.85" dy="9.25">
               Normal Freight
            </tspan>
         </text>
         <path
            fill="url('#19-360-rgba_246_189_15_1__0-rgba_246_189_15_1__30-rgba_252_229_159_1__60-rgba_246_189_15_1__90-rgba_246_189_15_1__100')"
            stroke="#FAD76F"
            strokeWidth="0.5"
            d="M113.85 7h6.6v2.75h-6.6zm0 4.125h11v2.75h-11zm0 4.125h3.3V18h-3.3z"
            cursor="pointer"
         ></path>
         <rect
            width="97"
            height="17"
            x="110.85"
            y="4"
            fill="silver"
            fillOpacity="0"
            cursor="pointer"
            rx="0"
            ry="0"
         ></rect>
         <text
            x="237.7"
            y="6.5"
            fill="#555"
            className="fusioncharts-legenditem"
            cursor="pointer"
            fontSize="10"
         >
            <tspan x="237.7" dy="9.25">
               Half Freight
            </tspan>
         </text>
         <path
            fill="url('#19-360-rgba_139_186_0_1__0-rgba_139_186_0_1__30-rgba_209_228_153_1__60-rgba_139_186_0_1__90-rgba_139_186_0_1__100')"
            stroke="#B9D666"
            strokeWidth="0.5"
            d="M221.7 7h6.6v2.75h-6.6zm0 4.125h11v2.75h-11zm0 4.125h3.3V18h-3.3z"
            cursor="pointer"
         ></path>
         <rect
            width="80"
            height="17"
            x="218.7"
            y="4"
            fill="silver"
            fillOpacity="0"
            cursor="pointer"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-200-caption">
         <text
            x="433.003"
            y="16"
            fill="#555"
            className="fusioncharts-caption"
            fontFamily="Verdana, sans"
            fontSize="13"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="433.003" dy="12.992">
               Chart Caption
            </tspan>
         </text>
         <text
            x="433.003"
            y="34"
            fill="#555"
            className="fusioncharts-subcaption"
            fontFamily="Verdana, sans"
            fontSize="11"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="433.003" dy="10.852">
               Chart Sub Caption
            </tspan>
         </text>
      </g>
      <g className="raphael-group-22-hot">
         <g
            fill="silver"
            fillOpacity="0"
            stroke="silver"
            strokeOpacity="0"
            className="raphael-group-73-bar-hot"
         >
            <rect width="39" height="40" x="87.5" y="60.5" rx="0" ry="0"></rect>
            <rect width="117" height="41" x="87.5" y="110.5" rx="0" ry="0"></rect>
            <rect width="312" height="41" x="87.5" y="161.5" rx="0" ry="0"></rect>
            <rect width="24" height="41" x="87.5" y="212.5" rx="0" ry="0"></rect>
            <rect width="24" height="41" x="87.5" y="263.5" rx="0" ry="0"></rect>
            <rect width="82" height="41" x="87.5" y="314.5" rx="0" ry="0"></rect>
            <rect width="381" height="40" x="87.5" y="365.5" rx="0" ry="0"></rect>
         </g>
         <g
            fill="silver"
            fillOpacity="0"
            stroke="silver"
            strokeOpacity="0"
            className="raphael-group-111-bar-hot"
         >
            <rect width="19" height="40" x="126.5" y="60.5" rx="0" ry="0"></rect>
            <rect width="59" height="41" x="204.5" y="110.5" rx="0" ry="0"></rect>
            <rect
               width="156"
               height="41"
               x="399.5"
               y="161.5"
               rx="0"
               ry="0"
            ></rect>
            <rect width="12" height="41" x="111.5" y="212.5" rx="0" ry="0"></rect>
            <rect width="12" height="41" x="111.5" y="263.5" rx="0" ry="0"></rect>
            <rect width="41" height="41" x="169.5" y="314.5" rx="0" ry="0"></rect>
            <rect
               width="190"
               height="40"
               x="468.5"
               y="365.5"
               rx="0"
               ry="0"
            ></rect>
         </g>
         <g
            fill="silver"
            fillOpacity="0"
            stroke="silver"
            strokeOpacity="0"
            className="raphael-group-149-bar-hot"
         >
            <rect width="10" height="40" x="145.5" y="60.5" rx="0" ry="0"></rect>
            <rect width="29" height="41" x="263.5" y="110.5" rx="0" ry="0"></rect>
            <rect width="77" height="41" x="555.5" y="161.5" rx="0" ry="0"></rect>
            <rect width="6" height="41" x="123.5" y="212.5" rx="0" ry="0"></rect>
            <rect width="6" height="41" x="123.5" y="263.5" rx="0" ry="0"></rect>
            <rect width="20" height="41" x="210.5" y="314.5" rx="0" ry="0"></rect>
            <rect width="95" height="40" x="658.5" y="365.5" rx="0" ry="0"></rect>
         </g>
      </g>
   </svg>)
};

export default StackedBarChartIcon;