/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


 import * as React from 'react';

 import '../assets/uxpl/css/Section.css';


 interface ISection {
   title?: string;
   titleElement?: React.ReactNode;
   maxHeight?: number;
   bodyStyle?: React.CSSProperties
 }

 export class Section extends React.Component<ISection>  {

      public render() { 
            return (
              <div className="tc-jsw-column-section">
                { this.props.title || this.props.titleElement ? (<div className="tc-jsw-column-section-title">{ this.props.title }{ this.props.titleElement }</div>) : null }
                <div className="tc-jsw-column-section-body" style={ this.props.bodyStyle}>
                    { this.props.children }
                </div>
              </div>
          );
        }
 }
