/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { FormControlledSelect, FormControlledWritableCombo } from "@jss/js-common";
import { getKey, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';
import { APDescriptor } from '../APDescriptor';
import { resolveAttribute } from '../../types/common/StyleResolver';
import { Map } from 'immutable';
import { IState } from '../../../../../reducers';
import { connect } from 'react-redux';
import { List } from 'immutable';
import i18n from '../../../../../i18n';
import '../../../../../assets/uxpl/css/UPControls.css';

const TYPEID = 'reportLanguage';
reg(TYPEID, (mc) => { return <UPReportLanguage mcontext={mc} />; });
export class PReportLanguage extends APDescriptor {
    default?: string;
    writeable?: boolean;
    public constructor(init: Partial<PReportLanguage>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}

interface ReportLanguage {
    combosCache?: Map<string, any>
}

class UPReportLanguage extends React.Component<UiPropertyProps & ReportLanguage> {

    render() {
        const d = this.props.mcontext.descriptor as PReportLanguage;

        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p);
        let inheritedValue;
        if (v === undefined && this.props.mcontext.descriptor.inheritedPropertyId) {
            const elementPath = getPath(undefined, this.props.mcontext.elements[0].path).slice(0, 2);
            const element = this.props.mcontext.model.getIn(elementPath) as Map<string, any>;
            inheritedValue = resolveAttribute(this.props.mcontext.model, element, this.props.mcontext.descriptor.inheritedPropertyId);
        }

        const queryLanguages : List<string> | undefined = this.props.combosCache.get('reportLanguages');
        let items : { key: string, value: string }[] = [];
        if (queryLanguages){
            items = queryLanguages.toArray().map((queryLanguage: string) => {
                return {
                    key: queryLanguage,
                    value: queryLanguage,
                }
            });
        }
        if (v === undefined){
            inheritedValue = d.default;
        }
        if (v === undefined && inheritedValue === undefined && items.length > 0) {
            inheritedValue = items[0].key;
        }
        if (d.writeable === true) {
            return <FormControlledWritableCombo key={getKey(p)} items={items}
                className={this.props.mcontext.descriptor.className}
                style={this.props.mcontext.descriptor.style}
                value={v}
                placeholder={inheritedValue}
                onComboChange={this.onWritableNameChange}
                label={i18n.t(d.label)}
                disabled={d.readonly}
                inline={true}
                InputLabelProps={d.deprecated ? {className: 'deprecatedProperty'} : undefined}
                size="small"
            />
        }
        return <FormControlledSelect key={getKey(p)} items={items}
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            value={v}
            placeholder={inheritedValue}
            onItemSelected={this.onNameChange}
            label={i18n.t(d.label)}
            disabled={d.readonly}
            inline={true}
            size={'small'}
            InputLabelProps={d.deprecated ? {className: 'deprecatedProperty'} : undefined}
        />
    }
    public onNameChange = (str) => {
        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: str });
        });
    }
    public onWritableNameChange = (selectedKey: string | undefined, selectedText: string) => {
        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: selectedKey ? selectedKey : selectedText });
        });
    }

}

const mapStateToProps = (state: IState) => {
    return {
        combosCache: state.getIn(['report', 'cachedData', 'comboValues'], Map<string, any>()),
    };
}

export default connect(mapStateToProps)(UPReportLanguage);