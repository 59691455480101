/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { APDescriptor } from "../../../ui/APDescriptor";
import BarChartIcon from "../../../../../../assets/icons/charts/bar.svg";
import Bar3DChartIcon from "../../../../../../assets/icons/charts/bar_3d.svg";
import ColumnChartIcon from "../../../../../../assets/icons/charts/column.svg";
import Column3DChartIcon from "../../../../../../assets/icons/charts/column_3d.svg";
import LineChartIcon from "../../../../../../assets/icons/charts/line.svg";
import AreaChartIcon from "../../../../../../assets/icons/charts/area.svg";
import StackedBarChartIcon from "../../../../../../assets/icons/charts/stacked_bar.svg";
import StackedBar3DChartIcon from "../../../../../../assets/icons/charts/stacked_bar_3d.svg";
import StackedColumnChartIcon from "../../../../../../assets/icons/charts/stacked_column.svg";
import StackedColumn3DChartIcon from "../../../../../../assets/icons/charts/stacked_column_3d.svg";
import StackedAreaChartIcon from "../../../../../../assets/icons/charts/stacked_area.svg";
import { PPropertyExpression } from "../../../ui/primitive/UPValueExpession";
import { PBoolean } from "../../../ui/primitive/UPBoolean";
import { PColor } from "../../../ui/primitive/UPColor";
import { PString } from "../../../ui/primitive/UPString";
import { PAccordeon } from "../../../ui/composite/UPAccordeon";
import { PInt } from "../../../ui/primitive/UPInteger";
import { PEnum } from "../../../ui/primitive/UPEnum";
import { MFusionChart3DCommon } from "./MFusionChart3DCommon";

export const FFusionChartProps = {

    // padding
    captionPadding: new PPropertyExpression({ id: 'captionPadding', vtype: new PInt({ id: 'captionPadding', label: 'Caption Padding', min: 0 }) }),
    labelPadding: new PPropertyExpression({ id: 'labelPadding', vtype: new PInt({ id: 'labelPadding', label: 'Label', min: 0 }) }),
    valuePadding: new PPropertyExpression({ id: 'valuePadding', vtype: new PInt({ id: 'valuePadding', label: 'Value', min: 0 }) }),
    plotSpacePercent: new PPropertyExpression({ id: 'plotSpacePercent', vtype: new PInt({ id: 'plotSpacePercent', label: 'Plot Space Percent', min: 0 }) }),

    // chart margin
    chartLeftMargin: new PPropertyExpression({ id: 'chartLeftMargin', vtype: new PInt({ id: 'chartLeftMargin', label: 'Left', min: 0 }) }),
    chartRightMargin: new PPropertyExpression({ id: 'chartRightMargin', vtype: new PInt({ id: 'chartRightMargin', label: 'Right', min: 0 }) }),
    chartTopMargin: new PPropertyExpression({ id: 'chartTopMargin', vtype: new PInt({ id: 'chartTopMargin', label: 'Top', min: 0 }) }),
    chartBottomMargin: new PPropertyExpression({ id: 'chartBottomMargin', vtype: new PInt({ id: 'chartBottomMargin', label: 'Bottom', min: 0 }) }),
    // canvas margin
    canvasLeftMargin: new PPropertyExpression({ id: 'canvasLeftMargin', vtype: new PInt({ id: 'canvasLeftMargin', label: 'Left', min: 0 }) }),
    canvasRightMargin: new PPropertyExpression({ id: 'canvasRightMargin', vtype: new PInt({ id: 'canvasRightMargin', label: 'Right', min: 0 }) }),
    canvasTopMargin: new PPropertyExpression({ id: 'canvasTopMargin', vtype: new PInt({ id: 'canvasTopMargin', label: 'Top', min: 0 }) }),
    canvasBottomMargin: new PPropertyExpression({ id: 'canvasBottomMargin', vtype: new PInt({ id: 'canvasBottomMargin', label: 'Bottom', min: 0 }) }),
    // tooltips
    showToolTip: new PPropertyExpression({ id: 'showToolTip', vtype: new PBoolean({ id: 'showToolTip', label: 'Show Tool Tip', canBeNull: true }) }),
    toolTipBgColor: new PPropertyExpression({ id: 'toolTipBgColor', vtype: new PColor({ id: 'toolTipBgColor', label: 'Background Color' }) }),
    toolTipBorderColor: new PPropertyExpression({ id: 'toolTipBorderColor', vtype: new PColor({ id: 'toolTipBorderColor', label: 'Border Color' }) }),
    showToolTipShadow: new PPropertyExpression({ id: 'showToolTipShadow', vtype: new PBoolean({ id: 'showToolTipShadow', label: 'Show Shadow', canBeNull: true }) }),
    seriesNameInTooltip: new PPropertyExpression({ id: 'useSNameInToolTip', vtype: new PBoolean({ id: 'seriesNameInTooltip', label: 'Show Series Name', canBeNull: true }) }),
    // format numbers
    formatNumber: new PPropertyExpression({ id: 'formatNumber', vtype: new PBoolean({ id: 'formatNumber', label: 'Format Number', canBeNull: true }) }),
    formatNumberScale: new PPropertyExpression({ id: 'formatNumberScale', vtype: new PBoolean({ id: 'formatNumberScale', label: 'Format Number Scale', canBeNull: true }) }),
    forceDecimals: new PPropertyExpression({ id: 'forceDecimals', vtype: new PBoolean({ id: 'forceDecimals', label: 'Force Decimals', canBeNull: true }) }),
    decimals: new PPropertyExpression({ id: 'decimals', vtype: new PString({ id: 'decimals', label: 'Decimals' }) }),
    // base font
    baseFont: new PPropertyExpression({ id: 'baseFont', vtype: new PString({ id: 'baseFont', label: 'Font' }) }),
    baseFontSize: new PPropertyExpression({ id: 'baseFontSize', vtype: new PInt({ id: 'baseFontSize', label: 'Size', min: 0 }) }),
    baseFontColor: new PPropertyExpression({ id: 'baseFontColor', vtype: new PColor({ id: 'baseFontColor', label: 'Color' }) }),

    //canvas font
    outCnvBaseFont: new PPropertyExpression({ id: 'outCnvBaseFont', vtype: new PString({ id: 'outCnvBaseFont', label: 'Font' }) }),
    outCnvBaseFontSize: new PPropertyExpression({ id: 'outCnvBaseFontSize', vtype: new PInt({ id: 'outCnvBaseFontSize', label: 'Size', min: 0 }) }),
    outCnvBaseFontColor: new PPropertyExpression({ id: 'outCnvBaseFontColor', vtype: new PColor({ id: 'outCnvBaseFontColor', label: 'Color' }) }),
    // legend
    showLegend: new PPropertyExpression({ id: 'showLegend', vtype: new PBoolean({ id: 'showLegend', label: 'Show Legend', canBeNull: true }) }),
    legendShadow: new PPropertyExpression({ id: 'legendShadow', vtype: new PBoolean({ id: 'legendShadow', label: 'Show Shadow', canBeNull: true }) }),
    legendAllowDrag: new PPropertyExpression({ id: 'legendAllowDrag', vtype: new PBoolean({ id: 'legendAllowDrag', label: 'Allow Drag', canBeNull: true }) }),
    useCircleMarker: new PPropertyExpression({ id: 'useCircleMarker', vtype: new PBoolean({ id: 'useCircleMarker', label: 'Use Cricle Marker', canBeNull: true }) }),
    // legend scroll bar
    legendScrollBgColor: new PPropertyExpression({ id: 'legendScrollBgColor', vtype: new PColor({ id: 'legendScrollBgColor', label: 'Background Color' }) }),
    legendScrollBarColor: new PPropertyExpression({ id: 'legendScrollBarColor', vtype: new PColor({ id: 'legendScrollBarColor', label: 'Bar Color' }) }),
    legendScrollBtnColor: new PPropertyExpression({ id: 'legendScrollBtnColor', vtype: new PString({ id: 'legendScrollBtnColor', label: 'Button Color' }) }),
    // legend border
    legendBorderColor: new PPropertyExpression({ id: 'legendBorderColor', vtype: new PColor({ id: 'legendBorderColor', label: 'Color' }) }),
    legendBorderThickness: new PPropertyExpression({ id: 'legendBorderThickness', vtype: new PString({ id: 'legendBorderThickness', label: 'Thickness' }) }),
    legendBorderAlpha: new PPropertyExpression({ id: 'legendBorderAlpha', vtype: new PInt({ id: 'legendBorderAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    legendCaption: new PPropertyExpression({ id: 'legendCaption', vtype: new PString({ id: 'legendCaption', label: 'Caption' }) }),
    legendPosition: new PPropertyExpression({
        id: 'legendPosition', vtype: new PEnum({
            id: 'legendPosition', label: 'Position', writeable: true,
            items: [{ key: "", value: "" },
            { key: "bottom", value: "Bottom" },
            { key: "right", value: "Right" }]
        })
    }),
    legendBgColor: new PPropertyExpression({ id: 'legendBgColor', vtype: new PColor({ id: 'legendBgColor', label: 'Background Color' }) }),
    legendBgAlpha: new PPropertyExpression({ id: 'legendBgAlpha', vtype: new PInt({ id: 'legendBgAlpha', label: 'Background Alpha', min: 0, max: 255 }) }),

    // background
    bgColor: new PPropertyExpression({ id: 'bgColor', vtype: new PColor({ id: 'bgColor', label: 'Color' }) }),
    bgAlpha: new PPropertyExpression({ id: 'bgAlpha', vtype: new PInt({ id: 'bgAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    bgRatio: new PPropertyExpression({ id: 'bgRatio', vtype: new PInt({ id: 'bgRatio', label: 'Ratio', min: 0 }) }),
    bgAngle: new PPropertyExpression({ id: 'bgAngle', vtype: new PInt({ id: 'bgAngle', label: 'Angle', min: 0 }) }),
    // border
    showBorder: new PPropertyExpression({ id: 'showBorder', vtype: new PBoolean({ id: 'showBorder', label: 'Show Border' }) }),
    borderColor: new PPropertyExpression({ id: 'borderColor', vtype: new PColor({ id: 'borderColor', label: 'Color' }) }),
    borderThickness: new PPropertyExpression({ id: 'borderThickness', vtype: new PInt({ id: 'borderThickness', label: 'Thickness', min: 0 }) }),
    borderAlpha: new PPropertyExpression({ id: 'borderAlpha', vtype: new PInt({ id: 'borderAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    // canvas border
    canvasBorderColor: new PPropertyExpression({ id: 'canvasBorderColor', vtype: new PColor({ id: 'canvasBorderColor', label: 'Color' }) }),
    canvasBorderThickness: new PPropertyExpression({ id: 'canvasBorderThickness', vtype: new PInt({ id: 'canvasBorderThickness', label: 'Thickness', min: 0 }) }),
    canvasBorderAlpha: new PPropertyExpression({ id: 'canvasBorderAlpha', vtype: new PInt({ id: 'canvasBorderAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    // canvas 
    canvasBgColor: new PPropertyExpression({ id: 'canvasBgColor', vtype: new PColor({ id: 'canvasBgColor', label: 'Color' }) }),
    canvasBgAlpha: new PPropertyExpression({ id: 'canvasBgAlpha', vtype: new PInt({ id: 'canvasBgAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    canvasBgRatio: new PPropertyExpression({ id: 'canvasBgRatio', vtype: new PInt({ id: 'canvasBgRatio', label: 'Ratio', min: 0 }) }),
    canvasBgAngle: new PPropertyExpression({ id: 'canvasBgAngle', vtype: new PInt({ id: 'canvasBgAngle', label: 'Angle', min: 0 }) }),
    // captions
    caption: new PPropertyExpression({ id: 'caption', vtype: new PString({ id: 'caption', label: 'Caption' }) }),
    subCaption: new PPropertyExpression({ id: 'subCaption', vtype: new PString({ id: 'subCaption', label: 'Sub Caption' }) }),

    xAxisName: new PPropertyExpression({ id: 'xAxisName', vtype: new PString({ id: 'xAxisName', label: 'Name' }) }),
    xAxisNamePadding: new PPropertyExpression({ id: 'xAxisNamePadding', vtype: new PInt({ id: 'xAxisNamePadding', label: 'Name Padding', min: 0 }) }),
    rotateXAxisName: new PPropertyExpression({ id: 'rotateXAxisName', vtype: new PBoolean({ id: 'rotateXAxisName', label: 'Rotate Name', canBeNull: true }) }),

    yAxisValuesPadding: new PPropertyExpression({ id: 'yAxisValuesPadding', vtype: new PInt({ id: 'yAxisValuesPadding', label: 'Values', min: 0 }) }),
    yAxisNamePadding: new PPropertyExpression({ id: 'yAxisNamePadding', vtype: new PInt({ id: 'yAxisNamePadding', label: 'Name', min: 0 }) }),
    setAdaptiveYMin: new PPropertyExpression({ id: 'setAdaptiveYMin', vtype: new PBoolean({ id: 'setAdaptiveYMin', label: 'Set Adaptive Min', canBeNull: true }) }),
    yAxisMinValue: new PPropertyExpression({ id: 'yAxisMinValue', vtype: new PInt({ id: 'yAxisMinValue', label: 'Min Value' }) }),
    yAxisMaxValue: new PPropertyExpression({ id: 'yAxisMaxValue', vtype: new PInt({ id: 'yAxisMaxValue', label: 'Max Value' }) }),
    showYAxisValues: new PPropertyExpression({ id: 'showYAxisValues', vtype: new PBoolean({ id: 'showYAxisValues', label: 'Show Values', canBeNull: true }) }),
    yAxisName: new PPropertyExpression({ id: 'yAxisName', vtype: new PString({ id: 'yAxisName', label: 'Name' }) }),
    yAxisNameWidth: new PPropertyExpression({ id: 'yAxisNameWidth', vtype: new PBoolean({ id: 'yAxisNameWidth', label: 'Name Width', canBeNull: true }) }),

    // plot
    useRoundEdges: new PPropertyExpression({ id: 'useRoundEdges', vtype: new PBoolean({ id: 'useRoundEdges', label: 'Use Round Edges', canBeNull: true }) }),
    plotBorderDashed: new PPropertyExpression({ id: 'plotBorderDashed', vtype: new PBoolean({ id: 'plotBorderDashed', label: 'Dashed', canBeNull: true }) }),
    plotShowBorder: new PPropertyExpression({ id: 'plotShowBorder', vtype: new PBoolean({ id: 'plotShowBorder', label: 'Show Border' }) }),
    plotBorderColor: new PPropertyExpression({ id: 'plotBorderColor', vtype: new PColor({ id: 'plotBorderColor', label: 'Color' }) }),
    plotBorderThickness: new PPropertyExpression({ id: 'plotBorderThickness', vtype: new PInt({ id: 'plotBorderThickness', label: 'Thickness', min: 0 }) }),
    plotBorderAlpha: new PPropertyExpression({ id: 'plotBorderAlpha', vtype: new PInt({ id: 'plotBorderAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    plotBorderDashLen: new PPropertyExpression({ id: 'plotBorderDashLen', vtype: new PInt({ id: 'plotBorderDashLen', label: 'Dash Lenght', min: 0, max: 255 }) }),
    plotBorderDashGap: new PPropertyExpression({ id: 'plotBorderDashGap', vtype: new PInt({ id: 'plotBorderDashGap', label: 'Dash Gap', min: 0, max: 255 }) }),
    // gradient
    plotGradientColor: new PPropertyExpression({ id: 'plotGradientColor', vtype: new PColor({ id: 'plotGradientColor', label: 'Gradient Color' }) }),
    plotFillColor: new PPropertyExpression({ id: 'plotFillColor', vtype: new PColor({ id: 'plotFillColor', label: 'Fill Color' }) }),
    plotFillAlpha: new PPropertyExpression({ id: 'plotFillAlpha', vtype: new PInt({ id: 'plotFillAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    plotFillRatio: new PPropertyExpression({ id: 'plotFillRatio', vtype: new PInt({ id: 'plotFillRatio', label: 'Fill Ratio', min: 0 }) }),
    plotFillAngle: new PPropertyExpression({ id: 'plotFillAngle', vtype: new PInt({ id: 'plotFillAngle', label: 'Fill Angle', min: 0 }) }),

    // etc
    animation: new PPropertyExpression({ id: 'animation', vtype: new PBoolean({ id: 'animation', label: 'Animation', canBeNull: true }) }),
    showShadow: new PPropertyExpression({ id: 'showShadow', vtype: new PBoolean({ id: 'showShadow', label: 'Show Shadow', canBeNull: true }) }),
    showLimits: new PPropertyExpression({ id: 'showLimits', vtype: new PBoolean({ id: 'showLimits', label: 'Show Limits', canBeNull: true }) }),
    showValues: new PPropertyExpression({ id: 'showValues', vtype: new PBoolean({ id: 'showValues', label: 'Show Values', canBeNull: true }) }),
    placeValuesInside: new PPropertyExpression({ id: 'placeValuesInside', vtype: new PBoolean({ id: 'placeValuesInside', label: 'Place Values Inside', canBeNull: true }) }),
    connectNullData: new PPropertyExpression({ id: 'connectNullData', vtype: new PBoolean({ id: 'connectNullData', label: 'Connect Null Data', canBeNull: true }) }),
    use3DLighting: new PPropertyExpression({ id: 'use3DLighting', vtype: new PBoolean({ id: 'use3DLighting', label: 'Use 3D Lighting', canBeNull: true }) }),

    adjustDiv: new PPropertyExpression({ id: 'adjustDiv', vtype: new PBoolean({ id: 'adjustDiv', label: 'Adjust Div', canBeNull: true }) }),
    showDivLineValues: new PPropertyExpression({ id: 'showDivLineValues', vtype: new PBoolean({ id: 'showDivLineValues', label: 'Show Div Line Values', canBeNull: true }) }),
    numDivLine: new PPropertyExpression({ id: 'numDivLine', vtype: new PInt({ id: 'numDivLine', label: 'Number Of Divisions', min: 0 }) }),
    divLineIsDashed: new PPropertyExpression({ id: 'divLineIsDashed', vtype: new PBoolean({ id: 'divLineIsDashed', label: 'Dashed', canBeNull: true }) }),
    divLineDashLen: new PPropertyExpression({ id: 'divLineDashLen', vtype: new PInt({ id: 'divLineDashLen', label: 'Dash Lenght', min: 0, max: 255 }) }),
    divLineDashGap: new PPropertyExpression({ id: 'divLineDashGap', vtype: new PInt({ id: 'divLineDashGap', label: 'Dash Gap', min: 0, max: 255 }) }),

    divLineThickness: new PPropertyExpression({ id: 'divLineThickness', vtype: new PInt({ id: 'divLineThickness', label: 'Thickness', min: 0 }) }),
    divLineColor: new PPropertyExpression({ id: 'divLineColor', vtype: new PColor({ id: 'divLineColor', label: 'Color' }) }),
    divLineAlpha: new PPropertyExpression({ id: 'divLineAlpha', vtype: new PInt({ id: 'divLineAlpha', label: 'Alpha', min: 0, max: 255 }) }),

    showAlternateVGridColor: new PPropertyExpression({ id: 'showAlternateVGridColor', vtype: new PBoolean({ id: 'showAlternateVGridColor', label: 'Show Alternate Color', canBeNull: true }) }),
    alternateVGridColor: new PPropertyExpression({ id: 'alternateVGridColor', vtype: new PColor({ id: 'alternateVGridColor', label: 'Color' }) }),
    alternateVGridAlpha: new PPropertyExpression({ id: 'alternateVGridAlpha', vtype: new PInt({ id: 'alternateVGridAlpha', label: 'Alpha', min: 0, max: 255 }) }),

    showAlternateHGridColor: new PPropertyExpression({ id: 'showAlternateHGridColor', vtype: new PBoolean({ id: 'showAlternateHGridColor', label: 'Show Alternate Color', canBeNull: true }) }),
    alternateHGridColor: new PPropertyExpression({ id: 'alternateHGridColor', vtype: new PColor({ id: 'alternateHGridColor', label: 'Color' }) }),
    alternateHGridAlpha: new PPropertyExpression({ id: 'alternateHGridAlpha', vtype: new PInt({ id: 'alternateHGridAlpha', label: 'Alpha', min: 0, max: 255 }) }),

    showZeroPlane: new PPropertyExpression({ id: 'showZeroPlane', vtype: new PBoolean({ id: 'showZeroPlane', label: 'Show Zero Plane', canBeNull: true }) }),
    zeroPlaneThickness: new PPropertyExpression({ id: 'zeroPlaneThickness', vtype: new PInt({ id: 'zeroPlaneThickness', label: 'Thickness', min: 0 }) }),
    zeroPlaneColor: new PPropertyExpression({ id: 'zeroPlaneColor', vtype: new PColor({ id: 'zeroPlaneColor', label: 'Color' }) }),
    zeroPlaneAlpha: new PPropertyExpression({ id: 'zeroPlaneAlpha', vtype: new PInt({ id: 'zeroPlaneAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    showZeroPlaneBorder: new PPropertyExpression({ id: 'showZeroPlaneBorder', vtype: new PBoolean({ id: 'showZeroPlaneBorder', label: 'Show Border', canBeNull: true }) }),
    zeroPlaneBorderColor: new PPropertyExpression({ id: 'zeroPlaneBorderColor', vtype: new PColor({ id: 'zeroPlaneBorderColor', label: 'Border Color' }) }),

    showLabels: new PPropertyExpression({ id: 'showLabels', vtype: new PBoolean({ id: 'showLabels', label: 'Show Labels', canBeNull: true }) }),
    rotateLabels: new PPropertyExpression({ id: 'rotateLabels', vtype: new PBoolean({ id: 'rotateLabels', label: 'Rotate', canBeNull: true }) }),
    slantLabels: new PPropertyExpression({ id: 'slantLabels', vtype: new PBoolean({ id: 'slantLabels', label: 'Slant Labels', canBeNull: true }) }),
    labelStep: new PPropertyExpression({ id: 'labelStep', vtype: new PInt({ id: 'labelStep', label: 'Step', min: 0 }) }),
    staggerLines: new PPropertyExpression({ id: 'staggerLines', vtype: new PInt({ id: 'staggerLines', label: 'Stagger Lines', min: 0 }) }),
    labelDisplay: new PPropertyExpression({
        id: 'labelDisplay', vtype: new PEnum({
            id: 'labelDisplay', label: 'Label Display', default: 'AUTO',
            items: [{ key: "AUTO", value: "Auto" },
            { key: "NONE", value: "None" },
            { key: "WRAP", value: "Wrap" },
            { key: "STAGGER", value: "Stagger" },
            { key: "ROTATE", value: "Rotate" }]
        })
    }),

    lineColor: new PPropertyExpression({ id: 'plotBorderColor', vtype: new PColor({ id: 'plotBorderColor', label: 'Color' }) }),
    lineThickness: new PPropertyExpression({ id: 'plotBorderThickness', vtype: new PInt({ id: 'plotBorderThickness', label: 'Thickness', min: 0 }) }),
    lineAlpha: new PPropertyExpression({ id: 'plotBorderAlpha', vtype: new PInt({ id: 'plotBorderAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    lineDashed: new PPropertyExpression({ id: 'plotBorderDashed', vtype: new PBoolean({ id: 'plotBorderDashed', label: 'Dashed', canBeNull: true }) }),
    lineDashLen: new PPropertyExpression({ id: 'plotBorderDashLen', vtype: new PInt({ id: 'plotBorderDashLen', label: 'Dash Lenght', min: 0, max: 255 }) }),
    lineDashGap: new PPropertyExpression({ id: 'plotBorderDashGap', vtype: new PInt({ id: 'plotBorderDashGap', label: 'Dash Gap', min: 0, max: 255 }) }),

    drawAnchors: new PPropertyExpression({ id: 'drawAnchors', vtype: new PBoolean({ id: 'drawAnchors', label: 'Draw Anchors', canBeNull: true }) }),
    anchorBorderColor: new PPropertyExpression({ id: 'anchorBorderColor', vtype: new PColor({ id: 'anchorBorderColor', label: 'Color' }) }),
    anchorBorderThickness: new PPropertyExpression({ id: 'anchorBorderThickness', vtype: new PInt({ id: 'anchorBorderThickness', label: 'Thickness', min: 0 }) }),
    anchorAlpha: new PPropertyExpression({ id: 'anchorAlpha', vtype: new PInt({ id: 'anchorAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    anchorBgColor: new PPropertyExpression({ id: 'anchorBgColor', vtype: new PColor({ id: 'anchorBgColor', label: 'Color' }) }),
    anchorBgAlpha: new PPropertyExpression({ id: 'anchorBgAlpha', vtype: new PInt({ id: 'anchorBgAlpha', label: 'Alpha', min: 0, max: 255 }) }),
    anchorRadius: new PPropertyExpression({ id: 'anchorRadius', vtype: new PInt({ id: 'anchorRadius', label: 'Radius', min: 0 }) }),
    anchorSides: new PPropertyExpression({ id: 'anchorSides', vtype: new PInt({ id: 'anchorSides', label: 'Sides', min: 0 }) }),

    palette: new PPropertyExpression({ id: 'palette', vtype: new PInt({ id: 'palette', label: 'Predefined Palette', min: 1, max: 4 }) }),
    paletteColors: new PPropertyExpression({ id: 'paletteColors', vtype: new PString({ id: 'paletteColors', label: 'Colors' }) }),
};

export const MFusionChartCommon: APDescriptor = {
    id: 'Bar', type: 'fusion.chart.bar', label: 'Bar', icon: BarChartIcon,
    layouts: [
        new PAccordeon({
            id: 'fusion.chart.caption', label: 'Captions', layouts: [FFusionChartProps.caption,
            FFusionChartProps.subCaption, FFusionChartProps.captionPadding]
        }),
        new PAccordeon({
            id: 'fusion.chart.axis', label: 'Axis', layouts: [
                new PAccordeon({ id: 'fusion.chart.xAxis', label: 'X Axis', layouts: [FFusionChartProps.xAxisName, FFusionChartProps.xAxisNamePadding, FFusionChartProps.rotateXAxisName] }),
                new PAccordeon({
                    id: 'fusion.chart.yAxis', label: 'Y Axis', layouts: [
                        FFusionChartProps.yAxisName, FFusionChartProps.yAxisNameWidth,
                        FFusionChartProps.setAdaptiveYMin, FFusionChartProps.yAxisMinValue, FFusionChartProps.yAxisMaxValue,
                        FFusionChartProps.showYAxisValues,
                        new PAccordeon({ id: 'fusion.chart.yAxis.padding', label: 'Paddings', layouts: [FFusionChartProps.yAxisNamePadding, FFusionChartProps.yAxisValuesPadding] })
                    ]
                })
            ]
        }),
        new PAccordeon({ id: 'fusion.chart.labels', label: 'Labels', layouts: [FFusionChartProps.showLabels, FFusionChartProps.labelDisplay, FFusionChartProps.rotateLabels, FFusionChartProps.slantLabels, FFusionChartProps.labelStep, FFusionChartProps.staggerLines, FFusionChartProps.labelPadding] }),
        new PAccordeon({ id: 'fusion.chart.values', label: 'Values', layouts: [FFusionChartProps.showValues, FFusionChartProps.placeValuesInside, FFusionChartProps.valuePadding] }),
        new PAccordeon({
            id: 'fusion.chart.plot', label: 'Plot', layouts: [
                FFusionChartProps.useRoundEdges,
                FFusionChartProps.plotSpacePercent,
                new PAccordeon({
                    id: 'fusion.chart.yAxis.border', label: 'Border', layouts: [
                        FFusionChartProps.useRoundEdges,
                        FFusionChartProps.plotShowBorder, FFusionChartProps.plotBorderColor, FFusionChartProps.plotBorderThickness, FFusionChartProps.plotBorderAlpha,
                        FFusionChartProps.plotBorderDashed, FFusionChartProps.plotBorderDashLen, FFusionChartProps.plotBorderDashGap
                    ]
                }),
                new PAccordeon({
                    id: 'fusion.chart.yAxis.background', label: 'Background', layouts: [FFusionChartProps.plotGradientColor, FFusionChartProps.plotFillColor, FFusionChartProps.plotFillAlpha, FFusionChartProps.plotFillRatio, FFusionChartProps.plotFillAngle]
                }),
                new PAccordeon({ id: 'fusion.chart.yAxis.line', label: 'Line', layouts: [FFusionChartProps.lineThickness, FFusionChartProps.lineColor, FFusionChartProps.lineAlpha, FFusionChartProps.lineDashed, FFusionChartProps.lineDashLen, FFusionChartProps.lineDashGap] }),
                new PAccordeon({
                    id: 'fusion.chart.yAxis.anchors', label: 'Anchors', layouts: [FFusionChartProps.drawAnchors, FFusionChartProps.anchorAlpha,
                    new PAccordeon({ id: 'fusion.chart.yAxis.anchors.border', label: 'Border', layouts: [FFusionChartProps.anchorBorderThickness, FFusionChartProps.anchorBorderColor] }),
                    new PAccordeon({ id: 'fusion.chart.yAxis.anchors.bg', label: 'Background', layouts: [FFusionChartProps.anchorBgColor, FFusionChartProps.anchorBgAlpha] }),
                    FFusionChartProps.anchorRadius, FFusionChartProps.anchorSides]
                })
            ]
        }),
        new PAccordeon({ id: 'fusion.chart.divisions', label: 'Divisions', layouts: [FFusionChartProps.adjustDiv, FFusionChartProps.showDivLineValues, FFusionChartProps.numDivLine, FFusionChartProps.divLineIsDashed, FFusionChartProps.divLineDashLen, FFusionChartProps.divLineDashGap, FFusionChartProps.divLineThickness, FFusionChartProps.divLineColor, FFusionChartProps.divLineAlpha] }),
        new PAccordeon({
            id: 'fusion.chart.grids', label: 'Grids', layouts: [
                new PAccordeon({ id: 'fusion.chart.vgrid', label: 'Vertical', layouts: [FFusionChartProps.showAlternateVGridColor, FFusionChartProps.alternateVGridColor, FFusionChartProps.alternateVGridAlpha] }),
                new PAccordeon({ id: 'fusion.chart.hgrid', label: 'Horizontal', layouts: [FFusionChartProps.showAlternateHGridColor, FFusionChartProps.alternateHGridColor, FFusionChartProps.alternateHGridAlpha] })
            ]
        }),
        new PAccordeon({ id: 'fusion.chart.zeroPlane', label: 'Zero Plane', layouts: [FFusionChartProps.showZeroPlane, FFusionChartProps.zeroPlaneThickness, FFusionChartProps.zeroPlaneColor, FFusionChartProps.zeroPlaneAlpha, FFusionChartProps.showZeroPlaneBorder, FFusionChartProps.zeroPlaneBorderColor] }),
        new PAccordeon({ id: 'fusion.chart.border', label: 'Border', layouts: [FFusionChartProps.showBorder, FFusionChartProps.borderColor, FFusionChartProps.borderThickness, FFusionChartProps.borderAlpha] }),
        new PAccordeon({ id: 'fusion.chart.background', label: 'Background', layouts: [FFusionChartProps.bgColor, FFusionChartProps.bgAlpha, FFusionChartProps.bgRatio, FFusionChartProps.bgAngle] }),
        new PAccordeon({ id: 'fusion.chart.fonts', label: 'Base Fonts', layouts: [FFusionChartProps.baseFont, FFusionChartProps.baseFontSize, FFusionChartProps.baseFontColor] }),
        new PAccordeon({ id: 'fusion.chart.margins', label: 'Margins', layouts: [FFusionChartProps.chartLeftMargin, FFusionChartProps.chartRightMargin, FFusionChartProps.chartTopMargin, FFusionChartProps.chartBottomMargin] }),
        new PAccordeon({
            id: 'fusion.chart.legend', label: 'Legend', layouts: [
                FFusionChartProps.showLegend, FFusionChartProps.legendShadow, FFusionChartProps.legendAllowDrag,
                FFusionChartProps.useCircleMarker, FFusionChartProps.legendCaption, FFusionChartProps.legendPosition,
                new PAccordeon({ id: 'fusion.chart.canvas.background', label: 'Background', layouts: [FFusionChartProps.legendBgColor, FFusionChartProps.legendBgAlpha] }),
                new PAccordeon({ id: 'fusion.chart.canvas.border', label: 'Border', layouts: [FFusionChartProps.legendBorderColor, FFusionChartProps.legendBorderThickness, FFusionChartProps.legendBorderAlpha] }),
                new PAccordeon({ id: 'fusion.chart.canvas.scrollbar', label: 'Scroll Bar', layouts: [FFusionChartProps.legendScrollBarColor, FFusionChartProps.legendScrollBgColor, FFusionChartProps.legendScrollBtnColor] })]
        }),
        new PAccordeon({
            id: 'fusion.chart.canvas', label: 'Canvas', layouts: [
                new PAccordeon({ id: 'fusion.chart.canvas.border', label: 'Border', layouts: [FFusionChartProps.canvasBorderColor, FFusionChartProps.canvasBorderThickness, FFusionChartProps.canvasBorderAlpha] }),
                new PAccordeon({ id: 'fusion.chart.canvas.margins', label: 'Margins', layouts: [FFusionChartProps.canvasLeftMargin, FFusionChartProps.canvasRightMargin, FFusionChartProps.canvasTopMargin, FFusionChartProps.canvasBottomMargin] }),
                new PAccordeon({ id: 'fusion.chart.canvas.bg', label: 'Backrgound', layouts: [FFusionChartProps.canvasBgColor, FFusionChartProps.canvasBgAlpha, FFusionChartProps.canvasBgRatio, FFusionChartProps.canvasBgAngle] }),
                new PAccordeon({ id: 'fusion.chart.fonts', label: 'Outside Canvas Fonts', layouts: [FFusionChartProps.outCnvBaseFont, FFusionChartProps.outCnvBaseFontSize, FFusionChartProps.outCnvBaseFontColor] })]
        }),
        new PAccordeon({ id: 'fusion.chart.formatNumber', label: 'Number Formatting', layouts: [FFusionChartProps.formatNumber, FFusionChartProps.formatNumberScale, FFusionChartProps.forceDecimals, FFusionChartProps.decimals] }),
        new PAccordeon({ id: 'fusion.chart.tooltip', label: 'Tooltip', layouts: [FFusionChartProps.showToolTip, FFusionChartProps.showToolTipShadow, FFusionChartProps.seriesNameInTooltip, FFusionChartProps.toolTipBgColor, FFusionChartProps.toolTipBorderColor] }),
        FFusionChartProps.animation, FFusionChartProps.showShadow, FFusionChartProps.showLimits, FFusionChartProps.connectNullData, FFusionChartProps.use3DLighting,
        new PAccordeon({ id: 'fusion.chart.palette', label: 'Palette', layouts: [FFusionChartProps.palette, FFusionChartProps.paletteColors] })
    ]
};

export const MFusionBarChart: APDescriptor = {
    id: 'Bar', type: 'fusion.chart.bar', label: 'Bar', icon: BarChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'Bar',
        'xmlns:fc': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [{ valueExpression: "new Double(0)", labelExpression: '"Label"' }] }
    }
};
export const MFusionBar3DChart: APDescriptor = {
    id: 'Bar3D', type: 'fusion.chart.bar3d', label: 'Bar 3D', icon: Bar3DChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts, ...MFusionChart3DCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'Bar3D',
        'xmlns:fc': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [{ valueExpression: "new Double(0)", labelExpression: '"Label"' }] }
    }
};
export const MFusionColumnChart: APDescriptor = {
    id: 'Column', type: 'fusion.chart.column', label: 'Column', icon: ColumnChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'Column',
        'xmlns:fc': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [{ valueExpression: "new Double(0)", labelExpression: '"Label"' }] }
    }
};
export const MFusionColumn3DChart: APDescriptor = {
    id: 'Column3D', type: 'fusion.chart.column3d', label: 'Column 3D', icon: Column3DChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts, ...MFusionChart3DCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'Column3D',
        'xmlns:fc': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [{ valueExpression: "new Double(0)", labelExpression: '"Label"' }] }
    }
};
export const MFusionLineChart: APDescriptor = {
    id: 'Line', type: 'fusion.chart.line', label: 'Line', icon: LineChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'Line',
        'xmlns:fc': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [{ valueExpression: "new Double(0)", labelExpression: '"Label"' }] }
    }
};
export const MFusionAreaChart: APDescriptor = {
    id: 'Area', type: 'fusion.chart.area', label: 'Area', icon: AreaChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'Area',
        'xmlns:fc': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [{ valueExpression: "new Double(0)", labelExpression: '"Label"' }] }
    }
};

export const MFusionStackedColumnChart: APDescriptor = {
    id: 'StackedColumn', type: 'fusion.chart.StackedColumn', label: 'Stacked Column', icon: StackedColumnChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'StackedColumn',
        'xmlns:fc': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [{ valueExpression: "new Double(0)", labelExpression: '"Label"' }] }
    }
};
export const MFusionStackedColumn3DChart: APDescriptor = {
    id: 'StackedColumn3D', type: 'fusion.chart.StackedColumn3D', label: 'Stacked Column 3D', icon: StackedColumn3DChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts, ...MFusionChart3DCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'StackedColumn3D',
        'xmlns:fc': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [{ valueExpression: "new Double(0)", labelExpression: '"Label"' }] }
    }
};
export const MFusionStackedBarChart: APDescriptor = {
    id: 'StackedBar', type: 'fusion.chart.StackedBar', label: 'Stacked Bar', icon: StackedBarChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'StackedBar',
        'xmlns:fc': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [{ valueExpression: "new Double(0)", labelExpression: '"Label"' }] }
    }
};
export const MFusionStackedBar3DChart: APDescriptor = {
    id: 'StackedBar3D', type: 'fusion.chart.StackedBar3D', label: 'Stacked Bar 3D', icon: StackedBar3DChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts, ...MFusionChart3DCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'StackedBar3D',
        'xmlns:fc': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [{ valueExpression: "new Double(0)", labelExpression: '"Label"' }] }
    }
};
export const MFusionStackedAreaChart: APDescriptor = {
    id: 'StackedArea', type: 'fusion.chart.StackedArea', label: 'Stacked Area', icon: StackedAreaChartIcon, path: 'chartProperties',
    layouts: [...MFusionChartCommon.layouts],
    default: {
        type: 'FUSIONCHART', chartType: 'StackedArea',
        'xmlns:fc': "http://jaspersoft.com/fusion", "xsi:schemaLocation": "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd",
        chartDataset: { dataset: {}, items: [{ valueExpression: "new Double(0)", labelExpression: '"Label"' }] }
    }
};