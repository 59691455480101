/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const ScaleImageEnum = new PEnum({
    id: 'scaleImage', label: 'ScaleImage.label', inheritedPropertyId: 'scaleImage',
    items: [
        { key: '', value: '' },
        { key: 'Clip', value: 'ScaleImage.Clip' },
        { key: 'FillFrame', value: 'ScaleImage.FillFrame' },
        { key: 'RetainShape', value: 'ScaleImage.RetainShape' },
        { key: 'RealHeight', value: 'ScaleImage.RealHeight' },
        { key: 'RealSize', value: 'ScaleImage.RealSize' }
    ]
});

