/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import { CommandRegistry, IEditor, IResourceListenerManager } from '@jss/js-common';
import InternalDataSourceEditor from './InternalDataSourceEditor';

interface IDataSourceEditor {
    resourceListenerManagers: IResourceListenerManager[],
    commandRegistry: CommandRegistry,
    language?: string,
    onExit?: () => void;
}


export class DataSourceEditor extends React.Component<IDataSourceEditor> implements IEditor {

    private editorRef: React.RefObject<InternalDataSourceEditor> = React.createRef<InternalDataSourceEditor>();

    getEditorContent = () => {
        return this.editorRef.current.getEditorContent();
    }

    public render() {
        return <InternalDataSourceEditor ref={this.editorRef} {...this.props}/>;
    }

}

export default DataSourceEditor;
