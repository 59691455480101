/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { IRepositoryItemDescriptor } from "../repo/IRepositoryItemDescriptor";
import { Permission, RepositoryApi } from "../repo/RepositoryApi";
import { UserCredential } from "../user/UserCredential";
import { ResourceFieldREST } from "./ResourceFieldREST";

export class ResourceFieldJackrabbit extends ResourceFieldREST {

    public getContainerLenght() {
        return 2;
    }

    public isEditable(res: IRepositoryItemDescriptor, name: string) {
        if (!res.permission || RepositoryApi.inst().hasPermission(res, Permission.WRITE)) {
            if ((name.startsWith('jrws.repository.') || name.startsWith('jrws.owner.')) && !UserCredential.isAdmin())
                return false;
            switch (name) {
                case 'jrws.subresource.number':
                    return false;
                case 'jrws.owner.max.repositories':  
                case 'jrws.owner.max.space':  
                case 'jrws.owner.max.file.size': 
                case 'jrws.owner.max.files': 
                case 'jrws.repository.max.space':  
                case 'jrws.repository.max.file.size':  
                case 'jrws.repository.max.files': return true;
            }
        }
        return super.isEditable(res, name);
    }

}