/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { reg } from '../../ui/UiProperty';

import UPParametersTable from './UPParametersTable';
import { PConnectedTable } from '../../ui/tables/UPConnectedTable';

const TYPEID = 'conParametersTable';
export class PConParametersTable extends PConnectedTable {

    public constructor(init: Partial<PConParametersTable>) {
        super(init);
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPParametersTable mcontext={mc} />; });