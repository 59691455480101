/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { TextField } from "@jss/js-common";
import React from "react";
import i18n from '../../../i18n';
import { ICParameters } from "./ParametersTab";
export class ICCharacter extends React.Component<ICParameters> {

    render() {
        const value = this.props.parametersValue?.get(this.props.param.name);
        return <TextField
            key={this.props.param.name}
            onChange={this.onValueChange}
            label={i18n.t(this.props.param.name)}
            inline={true}
            size={'small'}
            //use default value for the value, since value tend to put the cursor at the end
            // defaultValue={v}
            value={value}
            type={'text'}
            title={this.props.param.description}
        // onBlur={() => { this.setState({ uncommittedValue: undefined }) }}
        // error={descriptor.validator && descriptor.validator(v)}
        />
    }
    private onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = event.currentTarget.value;
        if (newValue)
            newValue = newValue.charAt(newValue.length - 1);
        this.props.setParameterValue(this.props.param.name, newValue);
    }
}