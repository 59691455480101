/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import ace from 'ace-builds';
import 'ace-builds/src-min-noconflict/ext-language_tools';

import modeJavascriptUrl from 'ace-builds/src-min-noconflict/mode-javascript';
import modeJavaUrl from 'ace-builds/src-min-noconflict/mode-java';
import modeGroovyUrl from 'ace-builds/src-min-noconflict/mode-groovy';
import workerJavascriptUrl from 'ace-builds/src-min-noconflict/worker-javascript';

import keyBindingUrl from 'ace-builds/src-min-noconflict/ext-keybinding_menu';
import searchBoxUrl from 'ace-builds/src-min-noconflict/ext-searchbox';
import modeJsonUrl from 'ace-builds/src-min-noconflict/mode-json';
import modeSqlUrl from 'ace-builds/src-min-noconflict/mode-sql';
import modeXmlUrl from 'ace-builds/src-min-noconflict/mode-xml';
import modeXqueryUrl from 'ace-builds/src-min-noconflict/mode-xquery';
import workerJsonUrl from 'ace-builds/src-min-noconflict/worker-json';
import workerXmlUrl from 'ace-builds/src-min-noconflict/worker-xml';

import modeCssUrl from 'ace-builds/src-min-noconflict/mode-css';
import workerCssUrl from 'ace-builds/src-min-noconflict/worker-css';

import modeHtmlUrl from 'ace-builds/src-min-noconflict/mode-html';
import workerHtmlUrl from 'ace-builds/src-min-noconflict/worker-html';

import modePropertiesUrl from 'ace-builds/src-min-noconflict/mode-properties';


/**
 * Ace modules' URLs need to take into account the application context path when they are registered
 *
 * @param applicationContextPath optional URL path that starts with '/'
 */
export function configureAceModuleUrls(applicationContextPath = "/") {
    const appContextPath = applicationContextPath.endsWith("/") ? applicationContextPath : `${applicationContextPath}/`;

    setModuleUrl('ace/mode/javascript', appContextPath, modeJavascriptUrl);
    setModuleUrl('ace/mode/javascript_worker', appContextPath, workerJavascriptUrl);
    setModuleUrl('ace/mode/java', appContextPath, modeJavaUrl);
    setModuleUrl('ace/mode/groovy', appContextPath, modeGroovyUrl);

    setModuleUrl('ace/mode/json', appContextPath, modeJsonUrl);
    setModuleUrl('ace/mode/xml', appContextPath, modeXmlUrl);
    setModuleUrl('ace/mode/sql', appContextPath, modeSqlUrl);
    setModuleUrl('ace/mode/xquery', appContextPath, modeXqueryUrl);
    setModuleUrl('ace/mode/xml_worker', appContextPath, workerXmlUrl);
    setModuleUrl('ace/mode/json_worker', appContextPath, workerJsonUrl);
    setModuleUrl('ace/ext/keybinding_menu', appContextPath, keyBindingUrl);
    setModuleUrl('ace/ext/searchbox', appContextPath, searchBoxUrl);

    setModuleUrl('ace/mode/css', appContextPath, modeCssUrl);
    setModuleUrl('ace/mode/css_worker', appContextPath, workerCssUrl);

    setModuleUrl('ace/mode/html', appContextPath, modeHtmlUrl);
    setModuleUrl('ace/mode/html_worker', appContextPath, workerHtmlUrl);

    setModuleUrl('ace/mode/properties', appContextPath, modePropertiesUrl);
}

function setModuleUrl(moduleName: string, appContextPath: string, moduleUrl: string) {
    const suffix = moduleUrl.startsWith("/") ? moduleUrl.substring(1) : moduleUrl;
    ace.config.setModuleUrl(moduleName, `${appContextPath}${suffix}`);
}
