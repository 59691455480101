/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { List, Map } from "immutable";
import { FUSIONCHARTS_NAMESPACE } from "../reader/JrxmlFusionChartUtils";
import { createChartDataset, createSectionHyperlinkElement } from "./JrxmlChartUtils";
import { createReportElement, setAttributeIfPresent, setExpressionNode, setXMLAttributeIfPresent } from "./JrxmlHelper";

const createFusionPropertyExpression = (name: string, itemElement: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const propertyElement = xmlDocument.createElement(namespace + "chartProperty");
    propertyElement.setAttribute('name', name);
    const expression = itemElement.get('propertyExpression');
    setExpressionNode(propertyElement, xmlDocument, expression, namespace + "propertyExpression");
    return propertyElement;
}

const createChartTrendLine = (itemElement: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const trendLineElement = xmlDocument.createElement(namespace + "trendLine");
    setAttributeIfPresent(itemElement, "trendZone", trendLineElement);
    setAttributeIfPresent(itemElement, "color", trendLineElement);
    const startValueExpression = itemElement.get('startValueExpression');
    setExpressionNode(trendLineElement, xmlDocument, startValueExpression, namespace + "startValueExpression");
    const endValueExpression = itemElement.get('endValueExpression');
    setExpressionNode(trendLineElement, xmlDocument, endValueExpression, namespace + "endValueExpression");
    const labelExpression = itemElement.get('labelExpression');
    setExpressionNode(trendLineElement, xmlDocument, labelExpression, namespace + "labelExpression");
    return trendLineElement;
}

const createChartDatasetItem = (itemElement: Map<string, any>, xmlDocument: Document, namespace: string): Element => {
    const datasetItemElement = xmlDocument.createElement(namespace + "item");
    const seriesExpression = itemElement.get('seriesExpression');
    setExpressionNode(datasetItemElement, xmlDocument, seriesExpression, namespace + "seriesExpression");
    const labelExpression = itemElement.get('labelExpression');
    setExpressionNode(datasetItemElement, xmlDocument, labelExpression, namespace + "labelExpression");
    const valueExpression = itemElement.get('valueExpression');
    setExpressionNode(datasetItemElement, xmlDocument, valueExpression, namespace + "valueExpression");
    const displayValueExpression = itemElement.get('displayValueExpression');
    setExpressionNode(datasetItemElement, xmlDocument, displayValueExpression, namespace + "displayValueExpression");
    const hyperlink = itemElement.get('hyperlink');
    if (hyperlink){
        const hyperlinkElement = createSectionHyperlinkElement(hyperlink, xmlDocument, namespace + 'hyperlink');  
        datasetItemElement.appendChild(hyperlinkElement); 
    }
    return datasetItemElement;
}

/**
 * Creates the Fusion chart information for the final JRXML.
 *
 * @param elementNode the JSON map containing the Fusion chart details
 * @param xmlDocument the XML document
 */
export const createFusionChartElement = (elementNode: Map<string, any>, xmlDocument: Document): Element => {
    const componentElement = xmlDocument.createElement("componentElement");
    const reportElement = createReportElement(elementNode, xmlDocument);
    componentElement.appendChild(reportElement);

    const baseNamespace = elementNode.get(FUSIONCHARTS_NAMESPACE, "fc");
    const namespace = baseNamespace + ":";

    const fusionChartElement = xmlDocument.createElement(namespace+"chart");

    fusionChartElement.setAttribute("xmlns:" + baseNamespace, elementNode.get("xmlns:" + baseNamespace, "http://jaspersoft.com/fusion"));
    fusionChartElement.setAttribute("xsi:schemaLocation", elementNode.get("xsi:schemaLocation", "http://jaspersoft.com/fusion http://jaspersoft.com/schema/fusion.xsd"));

    setAttributeIfPresent(elementNode, "evaluationTime", fusionChartElement);
    setAttributeIfPresent(elementNode, "evaluationGroup", fusionChartElement);
    setXMLAttributeIfPresent(elementNode, "chartType", "type", fusionChartElement);

    const hyperlink = elementNode.get('hyperlink') as Map<string, any>;
    if (hyperlink && hyperlink.size > 0){
        const hyperlinkNode = createSectionHyperlinkElement(hyperlink, xmlDocument, namespace + "hyperlink");
        fusionChartElement.appendChild(hyperlinkNode);
    }

    const chartProperties = elementNode.get('chartProperties') as Map<string, Map<string, any>>;
    if (chartProperties && chartProperties.size > 0){
        chartProperties.forEach((chartProperties, propertyName) =>{ 
            const propertyElement = createFusionPropertyExpression(propertyName, chartProperties, xmlDocument, namespace);
            fusionChartElement.appendChild(propertyElement);
        });
    }

    const chartTrendLines = elementNode.get('trendLines') as List<Map<string, any>>;
    if (chartTrendLines && chartTrendLines.size > 0){
        chartTrendLines.forEach((chartTrendLine) => {
            const propertyElement = createChartTrendLine(chartTrendLine, xmlDocument, namespace);
            fusionChartElement.appendChild(propertyElement);
        });
    }

    const chartDataset = elementNode.get('chartDataset') as Map<string, any>;
    if (chartDataset && chartDataset.size > 0){
        const jrDataset = chartDataset.get('dataset') as Map<string, any>;
        const chartDatasetNode = xmlDocument.createElement(namespace + "chartDataset");
        if (jrDataset && jrDataset.size > 0){
            const jrDatasetElement = createChartDataset(jrDataset, xmlDocument);
            chartDatasetNode.appendChild(jrDatasetElement);
        }
        const datasetItems = chartDataset.get('items') as List<Map<string, any>>;
        if (datasetItems && datasetItems.size > 0){
            datasetItems.forEach(datasetItem => {
                const chartDatasetItemElement = createChartDatasetItem(datasetItem, xmlDocument, namespace);
                chartDatasetNode.appendChild(chartDatasetItemElement);
            });
        }
        fusionChartElement.appendChild(chartDatasetNode);
    }
    
    componentElement.appendChild(fusionChartElement);

    return componentElement;
}