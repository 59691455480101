/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PAccordeon } from "../../ui/composite/UPAccordeon";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { PString } from "../../ui/primitive/UPString";
import { PEnum } from "../../ui/primitive/UPEnum";
import { PJavaClass } from "../../ui/primitive/UPJavaClass";
import { PConnectedStringTable } from "../../ui/tables/UPConnectedStringTable";


export const FBook = {
    name: new PString({ id: 'name', label: 'Name', description: 'Name of the report' }),
    description: new PString({ id: 'description', label: 'Description' }),

    language: new PEnum({
        id: 'language', label: 'Language', default: 'java', description: 'Language used for the report expressions', writeable: true,
        items: [{ key: 'java', value: 'Java' }, { key: 'groovy', value: 'Groovy' }, { key: 'javascript', value: 'JavaScript' }]
    }),
    imports: new PJavaClass({ id: 'imports', label: 'Imports'}),
    formatFactoryClass: new PJavaClass({ id: 'formatFactoryClass', label: 'Format Factory Class' }),

};

export const MBook: APDescriptor = {
    type: 'MBook',
    id: 'bookPropertiesDescriptor',
    label: 'Book',
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({
                id: 'book', label: 'Book', layouts: [
                    FBook.name,
                    FBook.description,
                    
                    new PAccordeon({
                        id: 'book.runtime', label: 'Runtime', expanded: true, layouts: [
                            FBook.language, FBook.formatFactoryClass,
                            new PConnectedStringTable({
                                id: "imports", label: 'Imports',rowLabel: 'Import', rowLabelPaths:['name'], default: 'NewImport', layouts: [new PString({})]
                            })
                        ]
                    })
                ]
            })
        ]
    })]
};

