/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';

import '../../../assets/uxpl/css/Palette.css';

export const PALETTE_ITEM_POSTFIX = '_JSS_PALETTE_ITEM';

 class Palette extends React.Component {
    public render() {
        return (<div className="Palette">
        <div className="PaletteItems">
          { this.props.children }
        </div>
      </div>);
    }
}

export default Palette;