/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const GenericIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/palette/generic-puzzle" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M15.3142767,3.3 C15.0588354,4.57925704 15.101996,5.56181284 15.4126118,6.25373883 C16.1214044,6.64089306 16.4521392,6.6155703 16.7494296,6.52651944 L16.8855206,6.48216387 C17.339055,6.32753978 17.6420141,6.22441428 17.9188842,6.22310117 C18.5740434,6.57956995 18.7,7.0691808 18.7,7.93572629 C18.7,8.77015042 18.581616,9.25255165 18.3456037,9.49073933 C17.7510067,9.66088898 17.4935458,9.59114382 17.2450912,9.51454226 C16.6277876,9.31488395 16.2991421,9.22142022 16.0466618,9.23861882 C15.1230665,10.400884 15.0582454,11.8559733 15.3418768,13.9971533 L15.3418768,13.9971533 L11.2845611,13.9971533 C10.9099427,13.6294047 11.0022114,13.3655223 11.1032859,13.0778606 C11.4228323,12.1280121 11.4833889,11.5424591 11.1655596,11.1357506 C10.9253479,10.828365 10.4493361,10.5870092 9.50105373,10.5870092 C8.45962442,10.5870092 7.97178861,10.8743721 7.73827821,11.2314332 C7.41342627,11.7281646 7.59282765,12.4616986 7.80682863,13.131909 C7.87740072,13.3529274 7.95662236,13.5623356 7.9825846,13.7351258 L7.9825846,13.7351258 L4.68914599,13.9745522 L4.68914599,9.3789248 C4.14765455,9.00918132 3.80296219,9.10853564 3.39053545,9.25837138 C2.88796768,9.44095561 2.22061064,9.74042187 1.7640905,9.44658089 C1.45780902,9.24944161 1.3,8.77691712 1.3,7.93572629 C1.3,7.12902551 1.44385925,6.67143136 1.72830222,6.47086272 C2.14016848,6.18044439 2.74802694,6.4281333 3.23525357,6.58197271 C3.6274204,6.70579747 3.97362916,6.79694324 4.19869883,6.76202246 L4.19869883,6.76202246 L4.66937548,3.3 Z" id="generic" stroke="#00A9D7" strokeWidth="0.6" fill="#0081CB"></path>
            </g>
        </svg>
    );
}
export default GenericIcon;