/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import '../../assets/uxpl/css/TextInput.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FilledInputProps, InputProps, OutlinedInputProps } from '@material-ui/core';
import { InputSize } from '../types/InputTypes';
import { ValidationResult, VALIDATION_RESULT } from '../../utils/validators';
import { ValidatedTexField } from './ValidatedTexField';

export interface ITextInput {
    id?: string;
    label?: string;
    type?: string,
    help?: string;
    min?: number;
    max?: number;
    placeholder?: string;
    multiline?: boolean,
    rows?: number,
    inputRef?: React.Ref<any>;
    postfix?: string;
    onChange?: (str: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
    size?: InputSize;
    onKeyDown?: (key: string, keycode: number, value: string) => void;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    validator?: (value: string | undefined) => string | undefined;
    advancedValidator?: (string) => ValidationResult,
    value?: string | undefined;
    disabled?: boolean;
    leftIcon?: React.ReactNode,
    InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>,
    inputProps?: InputProps['inputProps'],
    className?: string,
    style?: React.CSSProperties;
    autoComplete?: string,
    WrapperProps?: React.HTMLAttributes<HTMLDivElement> & { [key: string]: any },
}

interface ITextInputState {
    hasFocus: boolean
}

const pattern = /^-?\d*$/;

const integerValidator = (stringValue: string | undefined, validator?: (value: string | undefined) => string | undefined, advancedValidator?: (string) => ValidationResult): ValidationResult => {
    if (advancedValidator) {
        return advancedValidator(stringValue);
    }
    if (!stringValue) {
        return { result: VALIDATION_RESULT.VALID }
    }
    if (pattern.test(stringValue) || stringValue.length === 0) {
        if (stringValue.length === 1 && stringValue.startsWith('-')) {
            return { result: VALIDATION_RESULT.PARTIALLY_INVALID }
        } else {
            const newValue = stringValue.length === 0 ? undefined : parseInt(stringValue, 10);
            if (Number.isNaN(newValue)) {
                return { result: VALIDATION_RESULT.INVALID }
            }
            if (validator) {
                const validationMessage = validator(stringValue);
                if (validationMessage) {
                    return { result: VALIDATION_RESULT.INVALID, message: validationMessage }
                }
            }
        }
    } else {
        return { result: VALIDATION_RESULT.INVALID }
    }
    return { result: VALIDATION_RESULT.VALID }
}

const baseValidator = (stringValue: string | undefined, validator?: (value: string | undefined) => string | undefined, advancedValidator?: (string) => ValidationResult): ValidationResult => {
    if (advancedValidator) {
        return advancedValidator(stringValue);
    }
    if (validator) {
        const validationMessage = validator(stringValue);
        if (validationMessage) {
            return { result: VALIDATION_RESULT.INVALID, message: validationMessage }
        }
    }
    return { result: VALIDATION_RESULT.VALID }
}

export class TextInput extends React.Component<ITextInput> {

    public state: ITextInputState = {
        hasFocus: false
    }

    private onTextChange = (text: string | undefined) => {
        if (!this.props.onChange) {
            return;
        }
        const textValue = text ? text : '';
        this.props.onChange(textValue);
    }

    private onMouseClick = (event: React.MouseEvent<HTMLElement>) => {
        if (this.props.onClick) {
            this.props.onClick(event);
        }
    }

    private onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case "Enter": // enter
            case "Escape": // esc
                e.preventDefault();
                break;
        }
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e.key, e.keyCode, e.currentTarget.value);
        }
    }

    /**
     * We fire a change event only when the user has done...
     */
    public render() {
        let str = this.props.value ? '' + this.props.value : '';

        if (str.length > 0 && !this.state.hasFocus && this.props.postfix) {
            str += ' ' + this.props.postfix;
        }

        let validationError: string | undefined;
        if (this.props.validator) {
            validationError = this.props.validator(this.props.value);
        }

        let leftIconAdronment: React.ReactNode | undefined;
        if (this.props.leftIcon) {
            leftIconAdronment = <InputAdornment position="start">
                {this.props.leftIcon}
            </InputAdornment>;
        }
        const validator = this.props.type !== 'number' ? (value: string) => baseValidator(value, this.props.validator, this.props.advancedValidator) :
                            (value: string) => integerValidator(value, this.props.validator, this.props.advancedValidator);
        return <ValidatedTexField
            className={this.props.className}
            style={this.props.style}
            autoComplete={this.props.autoComplete}
            error={validationError}
            id={this.props.id}
            onTextChange={this.onTextChange}
            onKeyDown={this.onKeyDown}
            onClick={this.onMouseClick}
            disabled={this.props.disabled}
            TextFieldPrefix={leftIconAdronment}
            InputProps={this.props.InputProps}
            inputProps={this.props.inputProps}
            label={this.props.label}
            placeholder={this.props.placeholder}
            size={this.props.size}
            helperText={this.props.help}
            value={str}
            type={this.props.type !== 'number' ? this.props.type : 'text'}
            multiline={this.props.multiline}
            WrapperProps={this.props.WrapperProps}
            rows={this.props.rows}
            validator={validator}
            inputRef={this.props.inputRef}
        />;
    }
}
