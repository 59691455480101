/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ResourceType, formatDateAsString } from "@jss/js-common";
import { IRepositoryItemDescriptor, isDataSource, Mimes, MIME_TYPES, RepositoryApi, ResourceSorting, RESOURCE_TYPE } from "@jss/js-rest-api";
import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { SortDirection } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import i18n from "../i18n";
import _ from 'lodash';


export interface Column {
    id: string
    headerContent?: any;
    headerStyle?: React.CSSProperties;
    sortable?: boolean;
    alignHeader?: 'center' | 'left' | 'right' | 'justify';
    rowContent?: (r: IRepositoryItemDescriptor) => any;
    rowOnClick?: (r: IRepositoryItemDescriptor) => any;
    rowStyle?: React.CSSProperties;
    alignRow?: 'center' | 'left' | 'right' | 'justify';
    i18n?: boolean;
}

interface IProps {
    parent: IRepositoryItemDescriptor;
    files: IRepositoryItemDescriptor[];
    selection?: IRepositoryItemDescriptor[];
    onOpen?: (element: IRepositoryItemDescriptor) => void,
    onSelected?: (sel: IRepositoryItemDescriptor[]) => void;
    onContextMenu?: (anchorEL, sel: IRepositoryItemDescriptor[]) => void;
}

interface IState {
    asc: boolean,
    orderBy: string,
}

const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '0px',
                width: "max-content",
                height: '40px',
            },
        },
    },
});

export class ResourcesTable extends React.Component<IProps, IState>{

    public state: IState = {
        asc: true,
        orderBy: 'type',
    }

    private cols: Column[];

    constructor(props) {
        super(props);

        this.buildColumns();

        if (!this.cols.some(k => k.id === 'type')) {
            const r = this.cols.find(key => key.sortable);
            if (r) {
                this.state = { asc: r.sortable, orderBy: r.id };
            }
        }
    }
    public componentDidUpdate = (prevProps: IProps) => {
        if (!_.isEqual(prevProps.files, this.props.files) || !_.isEqual(prevProps.parent, this.props.parent)) {
            this.buildColumns();
        }

    }
    private buildColumns = () => {
        this.cols = [{
            id: 'icon',
            headerStyle: { width: '2%' },
            rowStyle: { width: '2%' },
            rowContent: r => <div style={{ cursor: 'pointer' }}>{ResourceType.getIcon4Name(Mimes.get(r.mime, true).logo)}</div>
        },
        {
            id: 'name', headerContent: 'resourcestable.header.name',
            sortable: true, alignHeader: 'left', alignRow: 'left', headerStyle: { width: '70%' }, rowStyle: { width: '70%', wordBreak: 'break-word' },
            rowContent: r => <div style={{ cursor: 'pointer' }}>{r.name}</div>,
            rowOnClick: r => { this.props.onOpen?.(r); }
        }];
        if (RepositoryApi.inst().field().isAvailable4Children(this.props.parent, 'lastModified')) {
            this.cols.push({
                id: 'lastModified', headerContent: 'resourcestable.header.lastmodified',
                sortable: true, alignRow: 'right', headerStyle: { width: '15%' },
                rowContent: r => formatDateAsString(r.lastModified)
            })
        }
        this.cols.push({
            id: 'type',
            headerContent: 'resourcestable.header.type', sortable: true, headerStyle: { width: '10%' },
            rowContent: r => Mimes.get(r.mime).shortLabel,
            i18n: true
        });
    }

    private onClick = (event, col: Column, row: IRepositoryItemDescriptor) => {
        if (col.rowOnClick)
            col.rowOnClick(row);
        else if (this.props.onSelected)
            this.props.onSelected([row]);
    }

    private onContextMenu = (event, col: Column, row: IRepositoryItemDescriptor) => {
        event.preventDefault();
        if (this.props.onContextMenu)
            this.props.onContextMenu(event, [row]);
        else if (this.props.onSelected)
            this.props.onSelected([row]);
    }

    public render() {
        const cm = this.props.onContextMenu;
        return <div style={{ display: 'flex', flex: 1, margin: 10, flexBasis: 0, overflow: 'auto' }}>
            <ThemeProvider theme={theme}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead><TableRow>{this.renderHeaders()}</TableRow></TableHead>
                        <TableBody>
                            {this.sortFiles().map((row) => {
                                return <TableRow key={row.uuid} selected={this.isSelected(row)} hover={true} style={cm ? { cursor: 'context-menu' } : undefined}>
                                    {this.renderRow(row)}
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ThemeProvider>
        </div>;
    }

    private renderRow = (row: IRepositoryItemDescriptor) => {
        return this.cols.map((key, index) => {
            const st =  index === 0 && row.isMainReport ? { ...key.rowStyle, color: '#cc3333'  } : key.rowStyle;
            return <TableCell key={row.name + index}
                align={key.alignRow ? key.alignRow : 'center'} style={st} scope='row' onClick={(event) => this.onClick(event, key, row)}
                onContextMenu={(event) => this.onContextMenu(event, key, row)}>
                {key.i18n === true ? i18n.t(key.rowContent ? key.rowContent(row) : row[key.id]) :
                    key.rowContent ? key.rowContent(row) : row[key.id]}
            </TableCell>;
        });
    }

    private renderHeaders = () => {
        return this.cols.map((key, index) => {
            return !key.sortable ? <TableCell key={index} align={key.alignHeader ? key.alignHeader : 'center'} style={key.headerStyle} component="th">{key.headerContent}</TableCell>
                : <TableCell key={index}
                    align={key.alignHeader ? key.alignHeader : 'center'} sortDirection={this.getSortDirection(key.id)} style={key.headerStyle} >
                    <TableSortLabel
                        active={this.state.orderBy === key.id}
                        direction={this.getSort4Column(key.id)}
                        onClick={() => this.handleSortRequest(key.id)}
                    >{i18n.t(key.headerContent)}</TableSortLabel>
                </TableCell>;
        });
    }
    private isSelected = (file: IRepositoryItemDescriptor): boolean => {
        return this.props.selection?.some(key => key && key.uuid === file.uuid);
    }

    private getSortDirection(name: string): SortDirection {
        return this.state.orderBy !== name ? false : this.state.asc ? 'asc' : 'desc';
    }
    private getSort4Column(name: string): 'asc' | 'desc' {
        return this.state.orderBy !== name ? 'asc' : this.state.asc ? 'asc' : 'desc';
    }

    private handleSortRequest(column: string) {
        this.setState(state => ({ asc: !state.asc, orderBy: column }));
    }

    private sortFiles = (): IRepositoryItemDescriptor[] => {
        const brepo = RepositoryApi.inst().getBase();
        const first = this.props.parent.type === RESOURCE_TYPE.CONTAINER && ['jrs:', 'js'].some(key => brepo.startsWith(key)) ? Mimes.get(MIME_TYPES.JRXML).shortLabel : Mimes.get(MIME_TYPES.FOLDER).shortLabel;
        return this.props.files.filter(row => row !== undefined).sort((a, b) => {
            switch (this.state.orderBy) {
                case 'name': return ResourceSorting.sortByName(a.name, b.name, !this.state.asc);
                case 'type':
                    if (a.mime === b.mime) {
                        return ResourceSorting.sortByName(a.name, b.name, !this.state.asc);
                    }
                    if ([RESOURCE_TYPE.FOLDER].includes(a.type))
                        return this.state.asc ? -1 : 1;

                    if (this.props.parent.type === RESOURCE_TYPE.CONTAINER && ['jrs:', 'js'].some(k => brepo.startsWith(k))) {
                        if (isDataSource(a.mime))
                            return this.state.asc ? -1 : 1;
                        if (isDataSource(b.mime))
                            return !this.state.asc ? -1 : 1;
                    }
                    if (this.props.parent.type === RESOURCE_TYPE.CONTAINER && ['jrs:', 'js'].some(k => brepo.startsWith(k))) {
                        if (a.mime === MIME_TYPES.JRXML && b.mime === MIME_TYPES.JRXML)
                            return a.isMainReport ? -1 : 1;
                    }
                    return ResourceSorting.sortByType(Mimes.get(a.mime).shortLabel, Mimes.get(b.mime).shortLabel, !this.state.asc, first);
                case 'lastModified': return ResourceSorting.sortByDate(a.lastModified, b.lastModified, !this.state.asc);
            }
            return 0;
        });
    }



}