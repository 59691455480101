/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { FormControlledWritableCombo } from "@jss/js-common";
import React from "react";
import i18n from '../../../i18n';
import { TIMEZONE_ITEMS } from "../properties/types/enums/TimeZone";
import { ICParameters } from "./ParametersTab";

export class ICTimeZone extends React.Component<ICParameters> {

    render() {
        const value = this.props.parametersValue?.get(this.props.param.name);
        return <FormControlledWritableCombo
            key={this.props.param.name}
            onComboChange={this.onWritableNameChange}
            label={i18n.t(this.props.param.name)}
            inline={true}
            size={'small'}
            items={TIMEZONE_ITEMS}
            //use default value for the value, since value tend to put the cursor at the end
            // defaultValue={v}
            value={value ? value : ''}
            type={'text'}
            title={this.props.param.description}
        />
    }
    
    private onWritableNameChange = (selectedKey: string | undefined, selectedText: string) => {
        this.props.setParameterValue(this.props.param.name, selectedKey ? selectedKey : selectedText);
    }
}