/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const TimeSeriesAreaChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="555" height="521" className="highcharts-root" fontFamily="&quot;Lucida Grande&quot;, &quot;Lucida Sans Unicode&quot;, Arial, Helvetica, sans-serif" fontSize="12" viewBox="0 0 555 521" {...props}>
    <defs>
       <clipPath id="highcharts-c1dfp01-1">
          <path fill="none" d="M0 0H489V438H0z" />
       </clipPath>
    </defs>
    <rect width="555" height="521" fill="#fff" className="highcharts-background" rx="0" ry="0" />
    <path fill="none" d="M56 10H545V448H56z" className="highcharts-plot-background" />
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="1">
       <path fill="none" d="M90.5 10v438M179.5 10v438M268.5 10v438M358.5 10v438M447.5 10v438M536.5 10v438" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
       <path fill="none" stroke="#e6e6e6" d="M56 448.5h489M56 385.5h489M56 323.5h489M56 260.5h489M56 198.5h489M56 135.5h489M56 73.5h489M56 9.5h489" className="highcharts-grid-line" data-z-index="1" />
    </g>
    <path fill="none" d="M56 10H545V448H56z" className="highcharts-plot-border" data-z-index="1" />
    <g className="highcharts-axis highcharts-xaxis" data-z-index="2">
       <path fill="none" stroke="#ccd6eb" d="M90.5 448v10M179.5 448v10M268.5 448v10M358.5 448v10M447.5 448v10M536.5 448v10" className="highcharts-tick" />
       <path fill="none" stroke="#ccd6eb" d="M56 448.5h489" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
       <path fill="none" d="M56 10v438" className="highcharts-axis-line" data-z-index="7" />
    </g>
    <g className="highcharts-series-group" data-z-index="3">
       <g className="highcharts-series highcharts-series-0 highcharts-area-series highcharts-color-0" clipPath="url(#highcharts-c1dfp01-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="rgba(124,181,236,0.75)" d="M4.794 431.957l6.608 5.472.734.003.734-11.7 20.557-6.16 10.278-13.811 2.937 32.169 2.202-2.123 3.671-7.088 1.468-9.51 2.203 17.203 5.873-25.226 5.14 2.828 7.341 13.448 5.14 7.623 2.936-14.865 2.202 16.7 17.62-23.408 2.937 21.97 8.076-1.356 5.873-11.964 3.671 4.68 1.469 9.592 10.278 1.541 11.747-.518 7.341-10.286 7.342-6.724 13.215 10.022 6.607-81.189 1.469 87.784 2.202-.976 13.215-5.488 5.14-38.606 13.215 45.587 11.012-2.698.734 1.625 8.076-30.105 5.873 29.876 4.405-5.66 2.203 2.676 2.937 1.525 1.468 1.462 6.607 1.165 3.671-.103 1.469-1.389 2.202-3.514 1.468-.089 1.469-.455 2.936-18.956 4.405-43.029 2.203 53.505 5.14 2.72 2.936-1.585 17.62-30.689 2.937 30.21.734 13.767 1.468-3.133 8.81-45.385.734 45.348.734-6.197 8.076-7.91 1.469 4.604 1.468 4.686 4.405-8.826.734 16.603 2.937-6.189.734-14.346 3.67 14.468 1.47-2.896.733 6.615 8.076-3.035 2.203-4.494 5.139-18.855 5.139 19.94 3.67 6.827 7.343-.2 13.215-27.166 1.468 24.003 2.937 3.868.734-88.245.734 85.38.734.105 10.278-56.426 2.937 39.235.734.385 2.203 19.528 2.936 1.626 5.874-2.649.734-8.493 2.937-23.732 1.468 20.583 4.405-5.859 9.544 16.177 1.468-45.944 6.608 32.09 3.67 16.447 1.47-80.664 2.202 55.853.734 18.782.734 7.117 3.67-17.131.735 14.513 4.405 1.694 2.937-102.504 2.202 98.916 1.469 2.688 5.873.605.734-3.843 3.67 4.529V438H4.795" className="highcharts-area" data-z-index="0" />
          <path fill="none" stroke="#7cb5ec" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.794 431.957l6.608 5.472.734.003.734-11.7 20.557-6.16 10.278-13.811 2.937 32.169 2.202-2.123 3.671-7.088 1.468-9.51 2.203 17.203 5.873-25.226 5.14 2.828 7.341 13.448 5.14 7.623 2.936-14.865 2.202 16.7 17.62-23.408 2.937 21.97 8.076-1.356 5.873-11.964 3.671 4.68 1.469 9.592 10.278 1.541 11.747-.518 7.341-10.286 7.342-6.724 13.215 10.022 6.607-81.189 1.469 87.784 2.202-.976 13.215-5.488 5.14-38.606 13.215 45.587 11.012-2.698.734 1.625 8.076-30.105 5.873 29.876 4.405-5.66 2.203 2.676 2.937 1.525 1.468 1.462 6.607 1.165 3.671-.103 1.469-1.389 2.202-3.514 1.468-.089 1.469-.455 2.936-18.956 4.405-43.029 2.203 53.505 5.14 2.72 2.936-1.585 17.62-30.689 2.937 30.21.734 13.767 1.468-3.133 8.81-45.385.734 45.348.734-6.197 8.076-7.91 1.469 4.604 1.468 4.686 4.405-8.826.734 16.603 2.937-6.189.734-14.346 3.67 14.468 1.47-2.896.733 6.615 8.076-3.035 2.203-4.494 5.139-18.855 5.139 19.94 3.67 6.827 7.343-.2 13.215-27.166 1.468 24.003 2.937 3.868.734-88.245.734 85.38.734.105 10.278-56.426 2.937 39.235.734.385 2.203 19.528 2.936 1.626 5.874-2.649.734-8.493 2.937-23.732 1.468 20.583 4.405-5.859 9.544 16.177 1.468-45.944 6.608 32.09 3.67 16.447 1.47-80.664 2.202 55.853.734 18.782.734 7.117 3.67-17.131.735 14.513 4.405 1.694 2.937-102.504 2.202 98.916 1.469 2.688 5.873.605.734-3.843 3.67 4.529" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M-5.206 431.957h10l6.608 5.472.734.003.734-11.7 20.557-6.16 10.278-13.811 2.937 32.169 2.202-2.123 3.671-7.088 1.468-9.51 2.203 17.203 5.873-25.226 5.14 2.828 7.341 13.448 5.14 7.623 2.936-14.865 2.202 16.7 17.62-23.408 2.937 21.97 8.076-1.356 5.873-11.964 3.671 4.68 1.469 9.592 10.278 1.541 11.747-.518 7.341-10.286 7.342-6.724 13.215 10.022 6.607-81.189 1.469 87.784 2.202-.976 13.215-5.488 5.14-38.606 13.215 45.587 11.012-2.698.734 1.625 8.076-30.105 5.873 29.876 4.405-5.66 2.203 2.676 2.937 1.525 1.468 1.462 6.607 1.165 3.671-.103 1.469-1.389 2.202-3.514 1.468-.089 1.469-.455 2.936-18.956 4.405-43.029 2.203 53.505 5.14 2.72 2.936-1.585 17.62-30.689 2.937 30.21.734 13.767 1.468-3.133 8.81-45.385.734 45.348.734-6.197 8.076-7.91 1.469 4.604 1.468 4.686 4.405-8.826.734 16.603 2.937-6.189.734-14.346 3.67 14.468 1.47-2.896.733 6.615 8.076-3.035 2.203-4.494 5.139-18.855 5.139 19.94 3.67 6.827 7.343-.2 13.215-27.166 1.468 24.003 2.937 3.868.734-88.245.734 85.38.734.105 10.278-56.426 2.937 39.235.734.385 2.203 19.528 2.936 1.626 5.874-2.649.734-8.493 2.937-23.732 1.468 20.583 4.405-5.859 9.544 16.177 1.468-45.944 6.608 32.09 3.67 16.447 1.47-80.664 2.202 55.853.734 18.782.734 7.117 3.67-17.131.735 14.513 4.405 1.694 2.937-102.504 2.202 98.916 1.469 2.688 5.873.605.734-3.843 3.67 4.529h10" className="highcharts-tracker" data-z-index="2" />
       </g>
       <g className="highcharts-series highcharts-series-1 highcharts-area-series highcharts-color-1" clipPath="url(#highcharts-c1dfp01-1)" data-z-index="0.1" transform="translate(56 10)">
          <path fill="rgba(67,67,72,0.75)" d="M4.794 425.914l6.608 10.945.734.005.734-23.4 20.557-12.321 10.278-27.622 2.937 64.339 2.202-4.245 3.671-14.176 1.468-19.02 2.203 34.405 5.873-50.453 5.14 5.657 7.341 26.895 5.14 15.248 2.936-29.732 2.202 33.401 17.62-46.816 2.937 43.94 8.076-2.713 5.873-31.52 3.671 16.954 1.469 19.184 10.278 3.08 11.747-1.035 7.341-38.847 7.342 4.828 13.215 20.043 6.607-162.378 1.469 175.568 2.202-1.952L197.88 423.2l5.14-77.214 13.215 91.175 11.012-5.397.734 3.252 8.076-60.212 5.873 59.754 4.405-11.32 2.203 5.35 2.937 3.051 1.468 2.924 6.607 2.33 3.671-.205 1.469-2.779 2.202-7.028 1.468-.177 1.469-.911 2.936-37.911 4.405-86.058 2.203 102.371 5.14 10.077 2.936-3.169 17.62-75.681 2.937 74.723.734 27.534 1.468-6.265 8.81-90.771.734 90.696.734-12.394 8.076-15.82 1.469 9.207 1.468 9.373 4.405-17.652.734 33.205 2.937-12.376.734-34.823 3.67 35.065 1.47-5.791.733 13.23 8.076-6.07 2.203-8.987 5.139-37.71 5.139 37.83 3.67 15.703 7.343-.398 13.215-54.335 1.468 48.007 2.937 7.737.734-176.492.734 170.76.734-1.541 10.278-111.1 2.937 78.47.734.77 2.203 39.055 2.936 3.254 5.874-5.299.734-17.12 2.937-47.33 1.468 41.166 4.405-11.718 9.544 32.354 1.468-91.887 6.608 64.18 3.67 32.893 1.47-161.329 2.202 111.708.734 37.563.734 14.233 3.67-51.789.735 46.553 4.405 3.39 2.937-413.6 2.202 406.423 1.469 5.377 5.873 1.208.734-15.217 3.67 16.59V438H4.795" className="highcharts-area" data-z-index="0" />
          <path fill="none" stroke="#434348" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.794 425.914l6.608 10.945.734.005.734-23.4 20.557-12.321 10.278-27.622 2.937 64.339 2.202-4.245 3.671-14.176 1.468-19.02 2.203 34.405 5.873-50.453 5.14 5.657 7.341 26.895 5.14 15.248 2.936-29.732 2.202 33.401 17.62-46.816 2.937 43.94 8.076-2.713 5.873-31.52 3.671 16.954 1.469 19.184 10.278 3.08 11.747-1.035 7.341-38.847 7.342 4.828 13.215 20.043 6.607-162.378 1.469 175.568 2.202-1.952L197.88 423.2l5.14-77.214 13.215 91.175 11.012-5.397.734 3.252 8.076-60.212 5.873 59.754 4.405-11.32 2.203 5.35 2.937 3.051 1.468 2.924 6.607 2.33 3.671-.205 1.469-2.779 2.202-7.028 1.468-.177 1.469-.911 2.936-37.911 4.405-86.058 2.203 102.371 5.14 10.077 2.936-3.169 17.62-75.681 2.937 74.723.734 27.534 1.468-6.265 8.81-90.771.734 90.696.734-12.394 8.076-15.82 1.469 9.207 1.468 9.373 4.405-17.652.734 33.205 2.937-12.376.734-34.823 3.67 35.065 1.47-5.791.733 13.23 8.076-6.07 2.203-8.987 5.139-37.71 5.139 37.83 3.67 15.703 7.343-.398 13.215-54.335 1.468 48.007 2.937 7.737.734-176.492.734 170.76.734-1.541 10.278-111.1 2.937 78.47.734.77 2.203 39.055 2.936 3.254 5.874-5.299.734-17.12 2.937-47.33 1.468 41.166 4.405-11.718 9.544 32.354 1.468-91.887 6.608 64.18 3.67 32.893 1.47-161.329 2.202 111.708.734 37.563.734 14.233 3.67-51.789.735 46.553 4.405 3.39 2.937-413.6 2.202 406.423 1.469 5.377 5.873 1.208.734-15.217 3.67 16.59" className="highcharts-graph" data-z-index="1" />
          <path fill="none" stroke="rgba(192,192,192,0.0001)" strokeLinejoin="round" strokeWidth="22" d="M-5.206 425.914h10l6.608 10.945.734.005.734-23.4 20.557-12.321 10.278-27.622 2.937 64.339 2.202-4.245 3.671-14.176 1.468-19.02 2.203 34.405 5.873-50.453 5.14 5.657 7.341 26.895 5.14 15.248 2.936-29.732 2.202 33.401 17.62-46.816 2.937 43.94 8.076-2.713 5.873-31.52 3.671 16.954 1.469 19.184 10.278 3.08 11.747-1.035 7.341-38.847 7.342 4.828 13.215 20.043 6.607-162.378 1.469 175.568 2.202-1.952L197.88 423.2l5.14-77.214 13.215 91.175 11.012-5.397.734 3.252 8.076-60.212 5.873 59.754 4.405-11.32 2.203 5.35 2.937 3.051 1.468 2.924 6.607 2.33 3.671-.205 1.469-2.779 2.202-7.028 1.468-.177 1.469-.911 2.936-37.911 4.405-86.058 2.203 102.371 5.14 10.077 2.936-3.169 17.62-75.681 2.937 74.723.734 27.534 1.468-6.265 8.81-90.771.734 90.696.734-12.394 8.076-15.82 1.469 9.207 1.468 9.373 4.405-17.652.734 33.205 2.937-12.376.734-34.823 3.67 35.065 1.47-5.791.733 13.23 8.076-6.07 2.203-8.987 5.139-37.71 5.139 37.83 3.67 15.703 7.343-.398 13.215-54.335 1.468 48.007 2.937 7.737.734-176.492.734 170.76.734-1.541 10.278-111.1 2.937 78.47.734.77 2.203 39.055 2.936 3.254 5.874-5.299.734-17.12 2.937-47.33 1.468 41.166 4.405-11.718 9.544 32.354 1.468-91.887 6.608 64.18 3.67 32.893 1.47-161.329 2.202 111.708.734 37.563.734 14.233 3.67-51.789.735 46.553 4.405 3.39 2.937-413.6 2.202 406.423 1.469 5.377 5.873 1.208.734-15.217 3.67 16.59h10" className="highcharts-tracker" data-z-index="2" />
       </g>
    </g>
    <g className="highcharts-legend" data-z-index="7" transform="translate(163 481)">
       <rect width="228" height="25" fill="none" className="highcharts-legend-box" rx="0" ry="0" />
       <g data-z-index="1">
          <g className="highcharts-legend-item highcharts-area-series highcharts-color-0 highcharts-series-0" data-z-index="1" transform="translate(8 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>max freight</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#7cb5ec" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
          <g className="highcharts-legend-item highcharts-area-series highcharts-color-1 highcharts-series-1" data-z-index="1" transform="translate(115.766 3)">
             <text x="16" y="15" fill="#333" color="#333" cursor="pointer" data-z-index="2" fontWeight="bold">
                <tspan>double freight</tspan>
             </text>
             <rect width="12" height="12" y="4" fill="#434348" className="highcharts-point" data-z-index="3" rx="6" ry="6" />
          </g>
       </g>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-xaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="middle">
       <text x="90.895" y="467">
          <tspan>set 96</tspan>
       </text>
       <text x="180.464" y="467">
          <tspan>gen 97</tspan>
       </text>
       <text x="268.564" y="467">
          <tspan>mag 97</tspan>
       </text>
       <text x="358.866" y="467">
          <tspan>set 97</tspan>
       </text>
       <text x="448.435" y="467">
          <tspan>gen 98</tspan>
       </text>
       <text x="523.438" y="467">
          <tspan>mag 98</tspan>
       </text>
    </g>
    <g fill="#666" className="highcharts-axis-labels highcharts-yaxis-labels" color="#666" cursor="default" data-z-index="7" fontSize="11" textAnchor="end">
       <text x="41" y="453">0</text>
       <text x="41" y="390">500</text>
       <text x="41" y="327">1.000</text>
       <text x="41" y="265">1.500</text>
       <text x="41" y="202">2.000</text>
       <text x="41" y="140">2.500</text>
       <text x="41" y="77">3.000</text>
       <text x="41" y="15">3.500</text>
    </g>
 </svg>)};

export default TimeSeriesAreaChartIcon;