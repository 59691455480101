/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { fromJS, Map, List } from 'immutable';
import { call, delay, put, select, spawn } from 'redux-saga/effects';

import { IState } from '../../../../reducers';

import { IPoint, } from '../../../../types/geometry';
import * as DocumentUtils from '../documentUtils';

import * as ReportActions from '../../../../actions/reportActions';
import { findBandTop } from '../documentUtils';
import { BandTypes } from '../../document/elementTypes';
import { i18n } from '@jss/js-common';
import { getOrderedBands } from '../../document/documentFactory';

const RESIZE_BAND_FIGURE_ID = "__resize-band";


/**
 * This saga is used to init the band resize operation, it will fire immediately also the
 * handleDrag in order to show the figure immediately upon mouse down and start a timer
 * to check if we need to adjust the drag operation to move a different band...
 * 
 * @param point
 * @param bandId - the band to move
 * @param inverse - if true, the band moved will be the top most covered.
 * @param commit 
 */
export function* initBandResizeSaga(action: any) {

  const { location, bandId, inverse } = action;

  let realBandId = bandId;

  // Let's get the state first, we need to check what's the most appropriate band to move...
  const state = yield select((s: IState) => s);

  if (inverse) {

    // We need to find the first band ending at top of bandId...
    const bands = state.getIn(['report', 'model', 'bands']);
    const resizingBand = bands.get(bandId);

    let progressiveTop = 0;
    const topMargin = state.getIn(['report', 'model', 'topMargin'], 0);
    const top = findBandTop(bandId) + resizingBand.get('height', 0) + topMargin;


    const hiddenBandId = bands.findKey((b: Map<string, any>, id: string) => {
      progressiveTop += b.get('height', 0);
      if (id === bandId || top === progressiveTop) { // If we reached our band, return, no band is covered
        return true;
      }

      return false;
    });

    if (hiddenBandId && realBandId !== hiddenBandId) {
      realBandId = hiddenBandId;
    }
  }

  // Now we have all, let's start the drag operation, and show the figure..
  yield put(ReportActions.setDragOperation(
    ReportActions.DragOperations.DRAG_OP_BAND_RESIZE, fromJS(location), Map({ bandId: realBandId })));
  yield call(handleDrag, location, {}, false);
  // yield put( DesignerActions.mouseMove(location, {}, undefined));

  if (realBandId !== bandId) {
    // If nothing is started in 1 second, change the drag op...
    yield spawn(timeoutBandResize, location, bandId);
  }
}

function* timeoutBandResize(point: IPoint, bandId: string) {

  yield delay(1000);
  const state = yield select((s: IState) => s);
  const model = state.getIn(['report', 'model']);

  const mouse = state.getIn(['report', 'mouse']);
  if (mouse.get('operation') !== ReportActions.DragOperations.DRAG_OP_BAND_RESIZE ||
    model === null ||
    mouse.get('dragStarted') === true) {
    return;
  }

  yield put(ReportActions.initBandResize(point, bandId, false));
}


/**
 * This function manages the dragging of a band border, produces special effects and conclude the final
 * operation on end...
 * The handle will process 3 possible states:
 * 1. drag not yet started
 * 2. drag in progress (dragStarted === true && commit === false)
 * 3. drag finished (commit === false)m it happens for instance on mouseUp event
 * 
 */
export function* handleDrag(point: IPoint, options: any, commit = false) {

  const state = yield select((s: IState) => s);
  // 1. Let's see if this was the first move...
  const mouse = state.getIn(['report', 'mouse']);
  const model = state.getIn(['report', 'model']);

  if (mouse.get('operation') !== ReportActions.DragOperations.DRAG_OP_BAND_RESIZE ||
    model === null) {
    return; // Nothing to do...
  }

  const currentLocation = point;
  const startLocation = mouse.get('startLocation').toJS();
  const resizedBandId = mouse.getIn(['operationState', 'bandId']);

  const band = model.getIn(['bands', resizedBandId]);
  if (!band) {
    return; // Band not found... it should never happen
  }



  // The original band height of our band
  const originalBandHeigt = band.get('height', 0);

  let dragStarted = mouse.get('dragStarted'); // This is a primitive value, no need to call toJS()

  const maxBandHeight = DocumentUtils.findMaxBandHeight(resizedBandId);

  // Adjust deltaY to satisfy the document boundries...
  let deltaY = Math.max(-originalBandHeigt, point.y - startLocation.y);
  deltaY = Math.min(maxBandHeight - originalBandHeigt, deltaY);

  const newBandHeight = band.get('height', 0) + deltaY;


  // The name of the interaction figure
  const figureId = RESIZE_BAND_FIGURE_ID;

  // TODO: validata delta point...

  if (!dragStarted) {

    // We need to initialize the drag. We will do it after some lag/treaschold.
    // If the drag can start, we will initialize our phantom shape that will
    // be added to the list of intaraction elements.
    // Let's check if we are ready to start (3px lag required...)
    if (Math.abs(point.y - startLocation.y) > 3) {
      dragStarted = true;
      yield put(ReportActions.updateDragOperation(fromJS(currentLocation), dragStarted, mouse.get("operationState")));
    }
    // Let's create the interaction figure
    let figureBand = createBandDragInteractionFigure(resizedBandId, state);
    figureBand = resizeBandFigure(figureBand, deltaY);

    // Now we have the figures, let's add it to the state...
    yield put(ReportActions.addInteractionFigure(figureId, figureBand));

  }
  else { // Keep dragging

    // We recreate the figure. After all the structure is very symple and names will be reused...
    let figureBand = createBandDragInteractionFigure(resizedBandId, state);
    figureBand = resizeBandFigure(figureBand, deltaY)
      .setIn(['componentProps', 'limitReached'], maxBandHeight - originalBandHeigt === deltaY);

    if (!commit) {
      yield put(ReportActions.updateDragOperation(fromJS(currentLocation), true, mouse.get("operationState")));
      yield put(ReportActions.updateInteractionFigure(figureId, figureBand));

      // Recalculate the client area
      yield call(DocumentUtils.updateClientArea);
    }
    else {

      figureBand = figureBand.updateIn(['props', 'className'], (val: string) => val + ' fadeOut')
        .set('transient', false);

      yield put(ReportActions.updateInteractionFigure(figureId, figureBand));

      if (deltaY !== 0) {

        // resize the band...
        yield put(ReportActions.setBandSize(['model', 'bands', resizedBandId], { height: newBandHeight }));
        yield call(DocumentUtils.updateClientArea);
      }

      // Recalculate the client area and spawn the detached process to clean up the figure
      // Saga will not wait for removeInteractionFigureAfterFade.
      yield spawn(DocumentUtils.removeInteractionFiguresAfterAnimation, [figureId]);
      yield call(DocumentUtils.updateClientArea);

    }
  }
}



/**
 * Move the band interaction figure
 * 
 * @param ele 
 * @param deltaY 
 */
const resizeBandFigure = (figure: Map<string, any>, deltaY: number): Map<string, any> => {

  return figure.update('height', (val: number) => val + deltaY)
    .updateIn(['props', 'style', 'height'], (val: number) => val + deltaY);
}




/**
 * Create an Immutable (Map) representation of an interaction figure (which is essentially an IRectabngle wrapped in a Map) from
 * a real element (which is an immutable Map with the x, y, width and height keys)
 * 
 * @param ele 
 * @param prefix - If provided, it will be prefixed to the original id to create a new key for this element.
 */
export const createBandDragInteractionFigure = (bandId: string, state: IState) => {

  const id = RESIZE_BAND_FIGURE_ID;
  const band = state.getIn(['report', 'model', 'bands', bandId]) as Map<string, any>;
  const bands = state.getIn(['report', 'model', 'bands']) as Map<string, any>;
  const topMargin = state.getIn(['report', 'model', 'topMargin'], 0) as number;
  const top = findBandTop(bandId) + topMargin;
  const width = (state.getIn(['report', 'model', 'docWidth'], 0) as number);
  const height = (band.get('height', 0) as number);
  const bandType = band.get('bandType');

  const bandsOrder: List<string> = getOrderedBands(state.getIn(['report', 'model']) as Map<string, any>);

  let detailCount = 1;

  let found = false;
  let label = bandId;
  if (bandType === BandTypes.BAND_DETAIL) {
    for (let i = 0; i < bandsOrder.size && !found; i++) {
      const currentBandId = bandsOrder.get(i);
      if (currentBandId === bandId) {
        label = `${i18n.t('band.detail')} ${detailCount}`;
        found = true;
      } else {
        const currentBand = bands.get(currentBandId);
        const currentBandType = currentBand.get('bandType');
        if (currentBandType === BandTypes.BAND_DETAIL) {
          detailCount++;
        }
      }
    }
  } else {
    label = DocumentUtils.getBandLabel(bandType);
  }

  const figure = {
    id,
    props: {
      key: id,
      style: {
        left: 0,
        top,
        width,
        height
      },
      className: 'BandResize'
    },
    x: 0,
    y: top,
    width,
    height,
    transient: true,
    component: 'BandResize',
    componentProps: {
      limitReached: false,
      label: label
    }
  };

  return fromJS(figure) as Map<string, any>;
}
