/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../ui/APDescriptor";

import { PJavaClass } from "../../ui/primitive/UPJavaClass";
import { PString } from "../../ui/primitive/UPString";
import { PTableElement } from "../../ui/tables/UPTableElement";
import FieldIcon from "../../../../../assets/icons/outline/fields.svg";
import { DefaultClasses } from "./MParameter"; 
import { PConnectedDynProperties } from "./UPConnectedDynProperties";
import { PUniqueName } from "../../ui/primitive/UPUniqueName";

export const FField = {
    name: new PUniqueName({ id: 'name', label: 'Name', canBeEmpty: false, canBeNull: false }),
    fieldDescription: new PString({ id: 'fieldDescription', label: 'Description' }),
    class: new PJavaClass({ id: 'class', label: 'Class', items: [...DefaultClasses] }),
}

export const MField: APDescriptor = new PTableElement({
    label: 'components.datasets.field', icon: FieldIcon,
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    layouts: [FField.name, FField.fieldDescription, FField.class, new PConnectedDynProperties({id: 'dsFieldCustomProperties',  propspath: "field" })]
});
