/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { Conf, RepositoryApi, UserCredential } from "@jss/js-rest-api";
import React from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { logoutUser } from "../../store/actions/actions";

export function LogOut() {
    const cloud = RepositoryApi.inst().getBase();
    const source = UserCredential.getSource();
    let ret = <Navigate to="/" />;
    switch (cloud) {
        case 'github': ret = logoutGithub(); break;
        case 'gdrive': ret = logoutGoogle(); break;
        case 'jackrabbit': ret= logoutJackrabbit(source); break;
        default:
    }
    const dispatch = useDispatch();
    dispatch(logoutUser());
    return ret;
}
function logoutGithub() {
    openLogoutPopup('https://github.com/logout', false);
    return <Navigate to="/" />
}
function logoutGoogle() {
    openLogoutPopup('https://www.google.com/accounts/Logout', true);
    // document.location.href = 'https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=http://localhost:4000';
    return <Navigate to="/" />
}
function logoutJackrabbit(source: string) {
    switch (source) {
        case 'js-cognito': window.location.href = Conf.get('jrws.url.rest.logout.jrws'); break;
        case 'google': return logoutGoogle();
        default:
            return <Navigate to="/" />
    }
    return <></>;
}

function openLogoutPopup(url: string, closeit: boolean) {
    setTimeout(() => {
        const newWindow = window.open(url, 'googleLogout', 'popup=yes,menubar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=350,height=400');
        if (newWindow && closeit) {
            window.focus();
            newWindow.onload = function () {
                newWindow.close();
            };
            setTimeout(() => { newWindow.close() }, 3000);
        }
    }, 1000);
}