/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const RectangleIcon = (): React.ReactElement => {
    return (
        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="jrws/palette/rectangle" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M3.5,3.5 L3.5,14.5 L16.5,14.5 L16.5,3.5 L3.5,3.5 Z M18,2 L18,16 L2,16 L2,2 L18,2 Z" id="Combined-Shape" fill="#A8D2F9"></path>
                <path d="M2.9,13.35 C3.86649831,13.35 4.65,14.1335017 4.65,15.1 C4.65,16.0664983 3.86649831,16.85 2.9,16.85 C1.93350169,16.85 1.15,16.0664983 1.15,15.1 C1.15,14.1335017 1.93350169,13.35 2.9,13.35 Z M17.1,13.35 C18.0664983,13.35 18.85,14.1335017 18.85,15.1 C18.85,16.0664983 18.0664983,16.85 17.1,16.85 C16.1335017,16.85 15.35,16.0664983 15.35,15.1 C15.35,14.1335017 16.1335017,13.35 17.1,13.35 Z M2.9,1.15 C3.86649831,1.15 4.65,1.93350169 4.65,2.9 C4.65,3.86649831 3.86649831,4.65 2.9,4.65 C1.93350169,4.65 1.15,3.86649831 1.15,2.9 C1.15,1.93350169 1.93350169,1.15 2.9,1.15 Z M17.1,1.15 C18.0664983,1.15 18.85,1.93350169 18.85,2.9 C18.85,3.86649831 18.0664983,4.65 17.1,4.65 C16.1335017,4.65 15.35,3.86649831 15.35,2.9 C15.35,1.93350169 16.1335017,1.15 17.1,1.15 Z" id="Combined-Shape" fill="#0081CB"></path>
            </g>
        </svg>
    );
}
export default RectangleIcon;