/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { RunContext } from '@jss/js-repository';
import { getRealName, IRepositoryItemDescriptor, RepositoryApi } from '@jss/js-rest-api';
import { Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as ReportActions from '../../../../../actions/reportActions';
import { IState } from '../../../../../reducers';
import { reg } from '../../ui/UiProperty';
import { PValidatedPath, UPValidatedPath } from './UPValidatedPath';
import { LOCALE_ITEMS } from '../../types/enums/Locale';

export class UPValidatedPropertiesPath extends UPValidatedPath {

    protected confirmFile = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null) => {
        let filename = getRealName(file);
        if (filename.endsWith('.properties')){
            filename = filename.substring(0, filename.length - 11);
        }
        LOCALE_ITEMS.forEach((locale) => {
            if (filename.endsWith(locale.key)){
                filename = filename.substring(0, filename.length - locale.key.length);
            }
        });
        const path = folderPath.length > 0 ? `${RepositoryApi.inst().getParentPath(folderPath)}/${filename}` : `/${filename}`;
        this.setState({ uncommittedValue: path }, () => {
            this.validatePath();
            this.onValueChange(true);
        });
    }
}

const mapStateToProps = (state: IState) => {
    return {
        resourcesCache: state.getIn(['report', 'cachedData', 'cachedResources'], Map<string, any>()),
        repositoryPath: state.getIn(['report', 'repositoryPath']),
    };
}

UPValidatedPropertiesPath.contextType = RunContext;

const mapDispatchToProps = (dispatch: any) => {
    return {
        checkResourceAction: (reportPath, resourcePath) => { dispatch(ReportActions.checkResourceAction(reportPath, resourcePath)); },
    };
}

const ConnectedValidatedPropertiesPath = connect(mapStateToProps, mapDispatchToProps)(UPValidatedPropertiesPath);

const TYPEID = 'validatedPropertiesPath';
reg(TYPEID, (mc) => { return <ConnectedValidatedPropertiesPath mcontext={mc} />; });
export class PValidatedPropertiesPath extends PValidatedPath {
    public constructor(init: Partial<PValidatedPath>) {
        super(init);
        Object.assign(this, { ...init, type: TYPEID });
    }
}