/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

export const EXIT_REPORT_DESIGNER = 'EXIT_REPORT_DESIGNER';

export const SAVE_REPORT = 'SAVE_REPORT';

export const SAVE_AS_REPORT = 'SAVE_AS_REPORT';

export const REPORT_SAVED = 'REPORT_SAVED';

export const REPORT_MODIFIED = 'REPORT_MODIFIED';

export const REPORT_SAVED_AS = 'REPORT_SAVED_AS';

export const REPORT_EDITOR_DIRTY = 'REPORT_EDITOR_DIRTY';

export const UPDATED_DESCRIPTOR = 'UPDATED_DESCRIPTOR';