/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PButtonsEnum } from "../../ui/primitive/UPButtonsEnum"; 

export const VerticalImageAlignEnum = new PButtonsEnum({
    id: 'vAlign', label: 'VerticalImageAlign.label', inheritedPropertyId: 'verticalImageAlignment',
    items: [
        { key: 'Top', value: 'VerticalImageAlign.Top', icon: 'imageATop' },
        { key: 'Middle', value: 'VerticalImageAlign.Middle', icon: 'imageAMiddle' },
        { key: 'Bottom', value: 'VerticalImageAlign.Bottom', icon: 'imageABottom' }
    ]
}); 
 
