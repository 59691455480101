/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';

const LineChartIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
   return (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="802"
      height="493"
      version="1.1"
      style={{
         WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
         WebkitUserSelect: "none",
         MozUserSelect: "none",
         userSelect: "none",
         position: "relative",
      }}
      cursor="default"
      overflow="hidden"
      viewBox="0 0 802 493"
      {...props}
   >
      <defs>
         <linearGradient
            id="19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100"
            x1="0"
            x2="0"
            y1="1"
            y2="0"
         >
            <stop offset="0%" stopColor="#cbcbcb" stopOpacity="0.5"></stop>
            <stop offset="100%" stopColor="#e9e9e9" stopOpacity="0.5"></stop>
         </linearGradient>
         <clipPath>
            <path d="M55 55H784V444H55z"></path>
         </clipPath>
      </defs>
      <g className="raphael-group-20-background">
         <rect
            width="800"
            height="491"
            x="1"
            y="1"
            fill="url('#19-90-rgba_203_203_203_0.5__0-rgba_233_233_233_0.5__100')"
            rx="0"
            ry="0"
         ></rect>
         <rect
            width="801"
            height="492"
            x="0.5"
            y="0.5"
            fill="none"
            stroke="#767575"
            strokeOpacity="0.5"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-27-canvas">
         <rect
            width="731"
            height="391"
            x="54"
            y="54"
            fill="none"
            stroke="#545454"
            strokeWidth="2"
            rx="0"
            ry="0"
         ></rect>
         <rect
            width="729"
            height="389"
            x="55"
            y="55"
            fill="#FFF"
            strokeWidth="0"
            rx="0"
            ry="0"
         ></rect>
      </g>
      <g className="raphael-group-30-axisbottom">
         <g
            fill="#EEE"
            fillOpacity="0.5"
            stroke="#000"
            strokeWidth="0"
            className="raphael-group-39-y-axis-bands"
         >
            <rect width="729" height="77.8" x="55" y="288.4" rx="0" ry="0"></rect>
            <rect width="729" height="77.8" x="55" y="132.8" rx="0" ry="0"></rect>
         </g>
         <g
            fill="none"
            stroke="#717170"
            strokeOpacity="0.4"
            className="raphael-group-41-y-axis-lines"
            shapeRendering="crispedges"
         >
            <path d="M55 366.5h729M55 288.5h729M55 211.5h729M55 133.5h729"></path>
         </g>
         <g className="fusioncharts-xaxis-0-gridlabels">
            <text
               x="64"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="64" dy="-2">
                  AK
               </tspan>
            </text>
            <text
               x="166"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="166" dy="-2">
                  CA
               </tspan>
            </text>
            <text
               x="268"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="268" dy="-2">
                  ID
               </tspan>
            </text>
            <text
               x="370"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="370" dy="-2">
                  MT
               </tspan>
            </text>
            <text
               x="472"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="472" dy="-2">
                  NM
               </tspan>
            </text>
            <text
               x="574"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="574" dy="-2">
                  OR
               </tspan>
            </text>
            <text
               x="676"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="676" dy="-2">
                  WA
               </tspan>
            </text>
            <text
               x="778"
               y="460"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               textAnchor="middle"
            >
               <tspan x="778" dy="-2">
                  WY
               </tspan>
            </text>
         </g>
         <g className="fusioncharts-yaxis-0-gridlabels">
            <text
               x="49"
               y="444"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="end"
            >
               <tspan x="49" dy="4">
                  0
               </tspan>
            </text>
            <text
               x="49"
               y="366.2"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="end"
            >
               <tspan x="49" dy="4.005">
                  8
               </tspan>
            </text>
            <text
               x="49"
               y="288.4"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="end"
            >
               <tspan x="49" dy="4.002">
                  16
               </tspan>
            </text>
            <text
               x="49"
               y="210.6"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="end"
            >
               <tspan x="49" dy="3.998">
                  24
               </tspan>
            </text>
            <text
               x="49"
               y="132.8"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="end"
            >
               <tspan x="49" dy="3.995">
                  32
               </tspan>
            </text>
            <text
               x="49"
               y="55"
               fill="#555"
               className="fusioncharts-label"
               fontFamily="Verdana, sans"
               fontSize="10"
               fontStyle="undefined"
               fontWeight="undefined"
               textAnchor="end"
            >
               <tspan x="49" dy="4">
                  40
               </tspan>
            </text>
         </g>
         <g className="raphael-group-50-axis-name">
            <text
               fill="#555"
               className="fusioncharts-yaxis-0-title"
               fontSize="10"
               textAnchor="middle"
               transform="rotate(-90 133.75 115.75)"
            >
               <tspan x="0" dy="10">
                  Orders
               </tspan>
            </text>
            <text
               fill="#555"
               className="fusioncharts-xaxis-0-title"
               fontSize="10"
               textAnchor="middle"
               transform="translate(419.5 465)"
            >
               <tspan x="0" dy="10">
                  States
               </tspan>
            </text>
         </g>
      </g>
      <g className="raphael-group-21-dataset">
         <g className="raphael-group-74-line-connector">
            <g
               fill="none"
               stroke="#404040"
               strokeLinecap="round"
               strokeLinejoin="round"
               className="raphael-group-75-connector-shadow"
            >
               <path
                  strokeOpacity="0.05"
                  strokeWidth="8"
                  d="M65 348l102 58 102-262 102 272 102-146 102-97 102 87 102 97"
               ></path>
               <path
                  strokeOpacity="0.1"
                  strokeWidth="6"
                  d="M65 348l102 58 102-262 102 272 102-146 102-97 102 87 102 97"
               ></path>
               <path
                  strokeOpacity="0.15"
                  strokeWidth="4"
                  d="M65 348l102 58 102-262 102 272 102-146 102-97 102 87 102 97"
               ></path>
            </g>
            <path
               fill="none"
               stroke="#767575"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeWidth="4"
               d="M64 347l102 58 102-262 102 272 102-146 102-97 102 87 102 97"
               className="0"
            ></path>
            <g fill="#FFF" stroke="#767575" className="raphael-group-78-anchors">
               <path d="M61 347a3 3 0 006 0 3 3 0 00-6 0zM163 405a3 3 0 006 0 3 3 0 00-6 0zM265 143a3 3 0 006 0 3 3 0 00-6 0zM367 415a3 3 0 006 0 3 3 0 00-6 0zM469 269a3 3 0 006 0 3 3 0 00-6 0zM571 172a3 3 0 006 0 3 3 0 00-6 0zM673 259a3 3 0 006 0 3 3 0 00-6 0zM775 356a3 3 0 006 0 3 3 0 00-6 0z"></path>
            </g>
         </g>
      </g>
      <g className="fusioncharts-datalabels">
         <text
            x="64"
            y="337"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="64" dy="4">
               10
            </tspan>
         </text>
         <text
            x="166"
            y="415"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="166" dy="4">
               4
            </tspan>
         </text>
         <text
            x="268"
            y="133"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="268" dy="4">
               31
            </tspan>
         </text>
         <text
            x="370"
            y="425"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="370" dy="4">
               3
            </tspan>
         </text>
         <text
            x="472"
            y="259"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="472" dy="4">
               18
            </tspan>
         </text>
         <text
            x="574"
            y="162"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="574" dy="4">
               28
            </tspan>
         </text>
         <text
            x="676"
            y="269"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="676" dy="4">
               19
            </tspan>
         </text>
         <text
            x="778"
            y="366"
            fill="#555"
            className="fusioncharts-label"
            fontFamily="Verdana, sans"
            fontSize="10"
            textAnchor="middle"
         >
            <tspan x="778" dy="4">
               9
            </tspan>
         </text>
      </g>
      <g className="raphael-group-107-caption">
         <text
            x="419.5"
            y="16"
            fill="#555"
            className="fusioncharts-caption"
            fontFamily="Verdana, sans"
            fontSize="13"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="419.5" dy="12.992">
               Chart Caption
            </tspan>
         </text>
         <text
            x="419.5"
            y="34"
            fill="#555"
            className="fusioncharts-subcaption"
            fontFamily="Verdana, sans"
            fontSize="11"
            fontWeight="bold"
            textAnchor="middle"
         >
            <tspan x="419.5" dy="10.852">
               Chart Sub Caption
            </tspan>
         </text>
      </g>
      <g className="raphael-group-22-hot">
         <g
            fill="silver"
            fillOpacity="0"
            stroke="silver"
            strokeOpacity="0"
            className="raphael-group-73-line-hot"
         >
            <circle cx="64" cy="347" r="5"></circle>
            <circle cx="166" cy="405" r="5"></circle>
            <circle cx="268" cy="143" r="5"></circle>
            <circle cx="370" cy="415" r="5"></circle>
            <circle cx="472" cy="269" r="5"></circle>
            <circle cx="574" cy="172" r="5"></circle>
            <circle cx="676" cy="259" r="5"></circle>
            <circle cx="778" cy="356" r="5"></circle>
         </g>
      </g>
   </svg>)
};

export default LineChartIcon;