/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { List, Map } from "immutable";
import { ElementTypes } from "../../../../sagas/report/document/elementTypes";
import { addAttributeToMap, addAttributeToMapWithName, addChildToParent, addChildValueToMap, addChildValueToMapWithName, addIntAttributeToMap, createDatasetRun, createReportElement, generatePath, getChildNodeByName, getNamespace, IObjectCounter, readCDataText } from "./JrxmlHelpers";

/** Reference for the HTML5 chart JRXML namespace */
export const HTML5CHART_NAMESPACE = "HTML5CHART_XMLNS_NAMESPACE";

const BAR_CHART_NAME = 'Bar';
const COLUMN_CHART_NAME = 'Column';
const LINE_CHART_NAME = 'Line';
const AREA_CHART_NAME = 'Area';
const SPLINE_CHART_NAME = 'Spline';
const AREA_SPLINE_CHART_NAME = 'AreaSpline';
const STACKED_BAR_CHART_NAME = 'StackedBar';
const STACKED_COLUMN_CHART_NAME = 'StackedColumn';
const STACKED_LINE_CHART_NAME = 'StackedLine';
const STACKED_AREA_CHART_NAME = 'StackedArea';
const STACKED_SPLINE_CHART_NAME = 'StackedSpline';
const STACKED_AREA_SPLINE_NAME = 'StackedAreaSpline';
const STACKED_PERCENT_BAR_NAME = 'StackedPercentBar';
const STACKED_PERCENT_COLUMN_NAME = 'StackedPercentColumn';
const STACKED_PERCENT_LINE_NAME = 'StackedPercentLine';
const STACKED_PERCENT_AREA_NAME = 'StackedPercentArea';
const STACKED_PERCENT_SPLINE_NAME = 'StackedPercentSpline';
const STACKED_PERCENT_AREA_SPLINE_NAME = 'StackedPercentAreaSpline';
const PIE_NAME = 'Pie';
const TIME_SERIES_SPLINE_NAME = 'TimeSeriesSpline';
const TIME_SERIES_LINE_NAME = 'TimeSeriesLine';
const TIME_SERIES_AREA_NAME = 'TimeSeriesArea';
const TIME_SERIES_AREA_SPLINE_NAME = 'TimeSeriesAreaSpline';
const COLUMN_LINE_NAME = 'ColumnLine';
const COLUMN_SPLINE_NAME = 'ColumnSpline';
const STACKED_COLUMN_LINE_NAME = 'StackedColumnLine';
const STACKED_COLUMN_SPLINE_NAME = 'StackedColumnSpline';
const MULTIAXIS_LINE_NAME = 'MultiAxisLine';
const MULTIAXIS_SPLINE_NAME = 'MultiAxisSpline';
const MULTIAXIS_COLUMN_NAME = 'MultiAxisColumn';
const SCATTER_NAME = 'Scatter';
const BUBBLE_NAME = 'Bubble';
const SPIDER_COLUMN_NAME = 'SpiderColumn';
const SPIDER_LINE_NAME = 'SpiderLine';
const SPIDER_AREA_NAME = 'SpiderArea';
const DUAL_LEVEL_PIE_NAME = 'DualLevelPie';
const HEATMAP_NAME = 'HeatMap';
const TIMESERIES_HEATMAP_NAME = 'TimeSeriesHeatMap';
const SEMIPIE_NAME = 'SemiPie';
const TREEMAP_NAME = 'TreeMap';
const DUAL_MEASURE_TREE_MAP = 'DualMeasureTreeMap';
const TILEMAP_NAME = 'TileMap';
const HIGHMAP_NAME = 'Map';
const ONE_PARENT_TREEMAP_NAME = 'OneParentTreeMap';
const GAUGE_NAME = 'Gauge';
const ARCGAUGE_NAME = 'ArcGauge';
const MULTILEVEL_GAUGE_NAME = 'MultiLevelGauge';

export const ChartTypes = {
    BAR_CHART_NAME,
    COLUMN_CHART_NAME,
    LINE_CHART_NAME,
    AREA_CHART_NAME,
    SPLINE_CHART_NAME,
    AREA_SPLINE_CHART_NAME,
    STACKED_BAR_CHART_NAME,
    STACKED_COLUMN_CHART_NAME,
    STACKED_LINE_CHART_NAME,
    STACKED_AREA_CHART_NAME,
    STACKED_SPLINE_CHART_NAME,
    STACKED_AREA_SPLINE_NAME,
    STACKED_PERCENT_BAR_NAME,
    STACKED_PERCENT_COLUMN_NAME,
    STACKED_PERCENT_LINE_NAME,
    STACKED_PERCENT_AREA_NAME,
    STACKED_PERCENT_SPLINE_NAME,
    STACKED_PERCENT_AREA_SPLINE_NAME,
    PIE_NAME,
    TIME_SERIES_SPLINE_NAME,
    TIME_SERIES_LINE_NAME,
    TIME_SERIES_AREA_NAME,
    TIME_SERIES_AREA_SPLINE_NAME,
    COLUMN_LINE_NAME,
    COLUMN_SPLINE_NAME,
    STACKED_COLUMN_LINE_NAME,
    STACKED_COLUMN_SPLINE_NAME,
    MULTIAXIS_LINE_NAME,
    MULTIAXIS_SPLINE_NAME,
    MULTIAXIS_COLUMN_NAME,
    SCATTER_NAME,
    BUBBLE_NAME,
    SPIDER_COLUMN_NAME,
    SPIDER_LINE_NAME,
    SPIDER_AREA_NAME,
    DUAL_LEVEL_PIE_NAME,
    HEATMAP_NAME,
    TIMESERIES_HEATMAP_NAME,
    SEMIPIE_NAME,
    TREEMAP_NAME,
    ONE_PARENT_TREEMAP_NAME,
    TILEMAP_NAME,
    HIGHMAP_NAME,
    GAUGE_NAME,
    ARCGAUGE_NAME,
    MULTILEVEL_GAUGE_NAME,
    DUAL_MEASURE_TREE_MAP,
}

/**
 * Checks if the specified component is a real HTML5 chart.
 *
 * @param componentElement the component element to check
 */
export const isHTML5Chart = (componentElement: Element) : boolean =>{
    const childList = componentElement.childNodes;
    let found = false;
    for (let i = 0; i < childList.length && !found; i++) {
        const child = childList[i];
        const nodeName = child.nodeName;
        if (child.nodeType === Node.ELEMENT_NODE && nodeName !== "reportElement"){
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length>0 ? baseNamespace + ":" : baseNamespace;
            if (namespace + 'chart' === nodeName){
                //check the xmlns to avoid conflict with fusion
                const xmlns = (child as Element).getAttribute( 'xmlns:' + baseNamespace);
                found = xmlns && xmlns.includes('highcharts');
            }
        }
    }
    return found;
}


/**
 * Creates the hyperlink information associated to an HTML5 chart.
 *
 * @param elementNode the hyperlink node
 * @param currentElementProperties the actual properties map
 */
export const createHyperLinkForElement = (elementNode: Element, currentElementProperties: Map<string, any>): Map<string, any> => {
    let result: Map<string, any> = addAttributeToMap(elementNode, "hyperlinkType", currentElementProperties);
    result = addAttributeToMap(elementNode, "hyperlinkTarget", result);
    result = addChildValueToMap(elementNode, "hyperlinkReferenceExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkAnchorExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkPageExpression", result);
    result = addChildValueToMap(elementNode, "hyperlinkTooltipExpression", result);
    let hyperLinkParameters: List<{ name: string, value: string }> = List<{ name: string, value: string }>();
    elementNode.childNodes.forEach(element => {
        if (element.nodeName === "hyperlinkParameter") {
            const parameterNode = element as Element;
            const parameterName = parameterNode.getAttribute("name");
            const parameterValue = readCDataText(getChildNodeByName(parameterNode, "hyperlinkParameterExpression"));
            hyperLinkParameters = hyperLinkParameters.push({name: parameterName, value: parameterValue});
        }
    });
    if (!hyperLinkParameters.isEmpty()) {
        result = result.set("hyperlinkParameters", hyperLinkParameters);
    }
    return result;
}

/**
 * Creates the dataset information associated to an HTML5 chart configuration.
 *
 * @param elementNode the dataset for the multi axis node
 */
export const createMultiAxisDataset = (elementNode: Element, objectCounter: IObjectCounter): Map<string, any> => {
    let result = Map<string, any>();
    result = addAttributeToMap(elementNode, "resetType", result);
    result = addAttributeToMap(elementNode, "resetGroup", result);
    result = addAttributeToMap(elementNode, "incrementType", result);
    result = addAttributeToMap(elementNode, "incrementGroup", result);
    result = addChildValueToMap(elementNode, "incrementWhenExpression", result);
    // check for the dataset run
    const datasetRunNode = getChildNodeByName(elementNode, "datasetRun");
    if (datasetRunNode !== null) {
        result = result.set("datasetRun", createDatasetRun(datasetRunNode as Element, objectCounter));
    }
    return result;
}

/**
 * Creates chart information related to the <hc:chartSettings>...</hc:chartSettings> JRXML part.
 *
 * @param HTML5ChartElement the HTML5 node element
 * @param namespace the namespace for HTML5 charts
 */
const createChartSettings = (HTML5ChartElement : Element, namespace: string): Map<string,any> => {
    let allChartSettings = Map<string, Map<string, any>>();
    HTML5ChartElement.childNodes.forEach(child => {
        const nodeName = child.nodeName;
        if (nodeName === namespace+"chartSetting") {
            const chartSettingsName = (child as Element).getAttribute("name") as string;
            let chartSettings = Map<string, any>();
            child.childNodes.forEach(prop => {
                if (prop.nodeName === namespace+"chartProperty") {
                    const property = prop as Element;
                    const pName = property.getAttribute("name") as string;
                    let valueMap =  Map<string, any>();
                    valueMap = addAttributeToMap(property,"value", valueMap);
                    valueMap = addChildValueToMapWithName(property, namespace+"propertyExpression", "valueExpression", valueMap);
                    chartSettings = chartSettings.set(pName, valueMap);
                }
            });
            allChartSettings = allChartSettings.set(chartSettingsName, chartSettings);
        }
    });
    return allChartSettings;
}

/**
 * Creates chart information related to the <hc:series>...</hc:series> JRXML part.
 *
 * @param HTML5ChartElement the HTML5 node element
 * @param namespace the namespace for HTML5 charts
 */
const createChartSeries = (HTML5ChartElement : Element, namespace: string): Map<string,any> => {
    let allChartSeries = Map<string, any>();
    HTML5ChartElement.childNodes.forEach(child => {
        const nodeName = child.nodeName;
        if (nodeName === namespace+"series") {
            const chartSeriesName = (child as Element).getAttribute("name") as string;
            let chartSeriesDetails = Map<string,any>();
            chartSeriesDetails = addAttributeToMap(child as Element, "visible", chartSeriesDetails);
            let contributors = Map<string, any>();
            child.childNodes.forEach(innerChild => {
                if (innerChild.nodeName === namespace+"contributor") {
                    const seriesContributor = innerChild as Element;
                    const scName = seriesContributor.getAttribute("name") as string;
                    let contributorProperties = Map<string, any>();
                    innerChild.childNodes.forEach(scProp => {
                        if (scProp.nodeName === namespace+"contributorProperty") {
                            const property = scProp as Element;
                            const scpName = property.getAttribute("name") as string;
                            let valueMap = Map<string, any>();
                            valueMap = addAttributeToMap(property, "value", valueMap);
                            valueMap = addAttributeToMap(property, "valueType", valueMap);
                            valueMap = addChildValueToMapWithName(property, namespace+"valueExpression", "valueExpression", valueMap);
                            contributorProperties = contributorProperties.set(scpName, valueMap);
                        }
                    });
                    contributors = contributors.set(scName, contributorProperties);
                }
            });
            if(!contributors.isEmpty()) {
                chartSeriesDetails = chartSeriesDetails.set("contributors", contributors);
            }
            allChartSeries = allChartSeries.set(chartSeriesName, chartSeriesDetails);
        }
    });
    return allChartSeries;
}

/**
 * Creates chart information related to the <multiAxisData>...</multiAxisData> JRXML part.
 *
 * @param multiAxisDataNode the multiAxisData node element
 */
const createMultiAxisData = (multiAxisDataNode: Node, objectCounter: IObjectCounter) => {
    let multiAxisDataDetails = Map<string, any>();

    const multiAxisDatasetNode = getChildNodeByName(multiAxisDataNode, "multiAxisDataset");
    if (multiAxisDatasetNode !== null) {
        const datasetNode = getChildNodeByName(multiAxisDatasetNode, "dataset");
        if (datasetNode !== null) {
            multiAxisDataDetails = multiAxisDataDetails.set("multiAxisDataset", createMultiAxisDataset(datasetNode as Element, objectCounter));
        } else {
            multiAxisDataDetails = multiAxisDataDetails.set("multiAxisDataset", null);
        }
    }

    let dataAxes = Map<string, any>();
    let multiAxisMeasures = List<Map<string, any>>();
    multiAxisDataNode.childNodes.forEach(child => {
        // check for dataAxis (at least one exists)
        if (child.nodeName === "dataAxis") {
            const axisType = (child as Element).getAttribute("axis") as string;
            // look for axisLevel children (can be put in a map)
            let axisLevels = List<Map<string, any>>();
            child.childNodes.forEach(innerChild => {
                if (innerChild.nodeName === "axisLevel") {
                    const axisLevelNode = innerChild as Element;
                    const axisLevelName = axisLevelNode.getAttribute("name") as string;
                    let axisLevelDetails = Map<string, any>();
                    axisLevelDetails = axisLevelDetails.set('axisLevelName', axisLevelName);
                    axisLevelDetails = addChildValueToMap(axisLevelNode, "labelExpression", axisLevelDetails);
                    // Bucket information
                    const axisLevelBucketNode = getChildNodeByName(axisLevelNode, "axisLevelBucket") as Element;
                    let axisLevelBucketDetails = Map<string, any>();
                    let axisLevelBucketProperties = Map<string, any>();
                    if (axisLevelBucketNode != null) {
                        axisLevelBucketNode.childNodes.forEach(bucketNodeChild => {
                            const childNodeName = bucketNodeChild.nodeName;
                            if (childNodeName === "bucketExpression") {
                                axisLevelBucketDetails = axisLevelBucketDetails.set("bucketExpression", readCDataText(bucketNodeChild));
                            }
                            if (childNodeName === "labelExpression") {
                                axisLevelBucketDetails = axisLevelBucketDetails.set("labelExpression", readCDataText(bucketNodeChild));
                            }
                            if (childNodeName === "comparatorExpression") {
                                axisLevelBucketDetails = axisLevelBucketDetails.set("comparatorExpression", readCDataText(bucketNodeChild));
                            }
                            if (childNodeName === "bucketProperty") {
                                const bucketPropertyName = (bucketNodeChild as Element).getAttribute("name") as string;
                                axisLevelBucketProperties = axisLevelBucketProperties.set(bucketPropertyName, readCDataText(bucketNodeChild));
                            }
                        });
                        axisLevelBucketDetails = addAttributeToMap(axisLevelBucketNode, "class", axisLevelBucketDetails);
                        axisLevelBucketDetails = addAttributeToMap(axisLevelBucketNode, "order", axisLevelBucketDetails);
                        if (!axisLevelBucketProperties.isEmpty()) {
                            axisLevelBucketDetails = axisLevelBucketDetails.set("bucketProperties", axisLevelBucketProperties);
                        }
                        axisLevelDetails = axisLevelDetails.set("axisLevelBucket", axisLevelBucketDetails);
                    }
                    axisLevels = axisLevels.push(axisLevelDetails);
                }
            })
            dataAxes = dataAxes.set(axisType, axisLevels);
        }
        // check for multiAxisMeasure(s) (at least one exists)
        if (child.nodeName === "multiAxisMeasure") {
            const measureNode = child as Element;
            let measureDetails = Map<string, any>();
            const measureName = measureNode.getAttribute("name") as string;
            measureDetails = addAttributeToMap(measureNode,"class", measureDetails);
            measureDetails = addAttributeToMap(measureNode,"calculation", measureDetails);
            measureDetails = addAttributeToMap(measureNode,"incrementerFactoryClass", measureDetails);
            measureDetails = addChildValueToMap(measureNode, "labelExpression", measureDetails);
            measureDetails = addChildValueToMap(measureNode, "valueExpression", measureDetails);
            measureDetails = measureDetails.set('measureName', measureName);
            multiAxisMeasures = multiAxisMeasures.push(measureDetails);
        }
    });
    multiAxisDataDetails = multiAxisDataDetails.set("dataAxes", dataAxes);
    multiAxisDataDetails = multiAxisDataDetails.set("multiAxisMeasures", multiAxisMeasures);

    return multiAxisDataDetails;
}

/**
 * Create the JSON structure to represent the HTML5 chart of JRXML.
 *
 * @param componentElementNode the HTML5 chart component node
 * @param parentElement the parent map containing details
 * @param document the current JSON representation of the JRXML
 * @param objectCounter accessory information for producing valid indexes
 */
export const createHTML5ChartElement = (componentElementNode: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) => {
    const reportElementNode: Element = getChildNodeByName(componentElementNode, "reportElement") as Element;
    const pathValue = generatePath(parentElement);
    let result: Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.HTML5CHART_JR, objectCounter);

    let newDocument = document;
    componentElementNode.childNodes.forEach(compChild => {
        if (compChild.nodeType === Node.ELEMENT_NODE && compChild.nodeName !== "reportElement") {
            const baseNamespace = getNamespace(compChild as Element);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : "";
            const HTML5ChartNode = getChildNodeByName(componentElementNode, namespace + "chart");
            if (HTML5ChartNode !== null) {
                const HTML5ChartElement = HTML5ChartNode as Element;
                result = result.set(HTML5CHART_NAMESPACE,baseNamespace);
                result = addAttributeToMap(HTML5ChartElement, baseNamespace.length > 0 ? "xmlns:" + baseNamespace : "xmlns", result);
                result = addAttributeToMap(HTML5ChartElement, "xsi:schemaLocation", result);
                result = addAttributeToMap(HTML5ChartElement, "evaluationTime", result);
                result = addAttributeToMap(HTML5ChartElement, "evaluationGroup", result);
                result = addAttributeToMap(HTML5ChartElement, "rowLevel", result);
                result = addAttributeToMap(HTML5ChartElement, "columnLevel", result);
                result = addIntAttributeToMap(HTML5ChartElement, "preferredHeight", result);
                result = addAttributeToMapWithName(HTML5ChartElement, "type", "chartType", result);

                const hyperlinkElement = getChildNodeByName(HTML5ChartElement, namespace + "hyperlink") as Element;
                if (hyperlinkElement !== null) {
                    const hyperlinkDetails = createHyperLinkForElement(hyperlinkElement, Map<string, any>());
                    result = result.set("hyperlink", hyperlinkDetails);
                }

                const allChartSettings = createChartSettings(HTML5ChartElement,namespace);
                if (!allChartSettings.isEmpty()) {
                    result = result.set("chartSettings", allChartSettings);
                }

                const allChartSeries = createChartSeries(HTML5ChartElement,namespace);
                if (!allChartSeries.isEmpty()) {
                    result = result.set("chartSeries", allChartSeries);
                }

                const multiAxisDSExpressionNode = getChildNodeByName(HTML5ChartElement, namespace + "multiAxisDataSourceExpression");
                if (multiAxisDSExpressionNode !== null) {
                    result = result.set("multiAxisDataSourceExpression", readCDataText(multiAxisDSExpressionNode));
                }

                const multiAxisDataNode = getChildNodeByName(HTML5ChartElement, "multiAxisData");
                if(multiAxisDataNode!==null){
                    result = result.set("multiAxisData", createMultiAxisData(multiAxisDataNode, objectCounter));
                }
            }
            newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, result.get("id"));
            newDocument = newDocument.set("elements", newDocument.get("elements").set(result.get("id"), result));
        }
    });

    return newDocument;
}