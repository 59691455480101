/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import * as React from 'react';
import { TextField, TextFieldProps } from '../textinput/TextField';
import { PopoverControl } from '../button/PopoverControl';
import '../../assets/uxpl/css/ButtonTextField.css';
import { InputAdornment } from '@material-ui/core';
import { Icon } from '../Icon/Icon';
import IconButton from '@material-ui/core/IconButton';

type SelectProps = TextFieldProps & {
    help?: string;
    icon?: string;
    iconComponent?: JSX.Element,
    onButtonClick?: (event) => void;
    getContent?: (handleClose: () => void) => React.ReactNode;
    validator?: (value: string | undefined) => string | undefined;
}

export class ButtonTextField extends React.Component<SelectProps> {


    private getButton = (iconComponent, icon, getContent, onButtonClick) => {
        if (onButtonClick) {
            if (iconComponent) {
                return <IconButton aria-label="more" onClick={onButtonClick} style={{ padding: '0' }}>
                    {iconComponent}
                </IconButton>;
            }
            return <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                <Icon icon={icon ? icon : 'search'} onClick={onButtonClick} />
            </div>;
        } else if (getContent) {
            let popoverTriggerButton;
            if (iconComponent) {
                popoverTriggerButton = (handleClick: (event: any) => void) => {
                    return <IconButton aria-label="more" onClick={handleClick} style={{ padding: '0' }}>
                        {iconComponent}
                    </IconButton>;
                }
            } else {
                popoverTriggerButton = (handleClick: (event: any) => void) => {
                    return <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                        <Icon icon={icon ? icon : 'search'} onClick={handleClick} />
                    </div>;
                }
            }
            return <PopoverControl id={this.props.id + 'popover'} getContent={getContent} getControl={popoverTriggerButton} />
        }
    }

    public render() {
        let validationError: string | undefined;
        if (this.props.validator) {
            validationError = this.props.validator(this.props.value as string | undefined);
        }

        let inputProps;
        if (this.props.InputProps) {
            inputProps = this.props.InputProps;
            if (this.props.InputProps.readOnly !== undefined) {
                inputProps.readOnly = this.props.InputProps.readOnly;
            } else {
                inputProps.readOnly = true;
            }
        } else {
            inputProps = {
                readOnly: true,
            };
        }
        const { iconComponent, icon, getContent, onButtonClick, InputLabelProps = {}, ...restProps } = this.props;
        return (
            <TextField
                size="large"
                id={this.props.id}
                defaultValue={this.props.placeholder}
                helperText={validationError ? validationError : this.props.help}
                error={validationError}
                disabled={this.props.disabled}
                onChange={this.props.onChange}
                value={this.props.value}
                {...restProps}
                label={this.props.label}
                className="tc-jsw-validated-path"
                textFieldClassName={"jr-mInputSearch mui"}
                InputProps={{
                    ...inputProps,
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            className={"jr-mInputSearch-adornment mui"}
                        >
                            {this.getButton(iconComponent, icon, getContent, onButtonClick)}
                        </InputAdornment>
                    )
                }}
                InputLabelProps={{
                    disableAnimation: true,
                    ...InputLabelProps
                }}
            />
        );
    }
}