/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { JndiDataAdapterEditor } from './JndiDataAdapterEditor';
import { createNode, getProlog, IDataAdapterConfig, IDataAdapterDescriptor } from '../IDataAdapterDescriptor';
import format from 'xml-formatter';
import { MIME_TYPES } from '@jss/js-rest-api';
import { nameValidator, notEmptyValidator } from '@jss/js-common/src/utils/validators';
import { getDataAdapterNode } from '../../editor/Utils';

export default class JndiDataAdapterDescriptor implements IDataAdapterDescriptor {

  public getMime = () => {
    return MIME_TYPES.DATA_ADAPTER_JNDI;
  }

  public getIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" height={48} width={48} viewBox="0 0 48 48">
      <path d="M24 22q-8.05 0-13.025-2.45T6 14q0-3.15 4.975-5.575Q15.95 6 24 6t13.025 2.425Q42 10.85 42 14q0 3.1-4.975 5.55Q32.05 22 24 22Zm0 10q-7.3 0-12.65-2.2Q6 27.6 6 24.5v-5q0 1.95 1.875 3.375t4.65 2.35q2.775.925 5.9 1.35Q21.55 27 24 27q2.5 0 5.6-.425 3.1-.425 5.875-1.325 2.775-.9 4.65-2.325Q42 21.5 42 19.5v5q0 3.1-5.35 5.3Q31.3 32 24 32Zm0 10q-7.3 0-12.65-2.2Q6 37.6 6 34.5v-5q0 1.95 1.875 3.375t4.65 2.35q2.775.925 5.9 1.35Q21.55 37 24 37q2.5 0 5.6-.425 3.1-.425 5.875-1.325 2.775-.9 4.65-2.325Q42 31.5 42 29.5v5q0 3.1-5.35 5.3Q31.3 42 24 42Z" />
    </svg>
  }

  /**
   *  Return the name of this data adapter to be presented to the user
   */
  public getDataAdapterLabel = () => {
    return 'datasource.jndi.label';
  }

  /**
  *  Return the name of this data adapter to be presented to the user
  *  i.e. CSV File
  */
  public getDataAdapterDescription = () => {
    return 'datasource.jndi.description';
  }

  /**
   *  Return the canonical name of the class which impements this data adapter in Java.
   *  net.sf.jasperreports.data.jndi.JndiDataAdapter
   */
  public getDataAdapterClass = () => {
    return 'net.sf.jasperreports.data.jndi.JndiDataAdapterImpl'
  }

  public getDataAdapterRootName = () => {
    return 'jndiDataAdapter';
  }

  /**
   * Return a plain json object with the default data adapter configuration.
   * It can be just an empty object.
   *
   * @memberof IDataAdapter
   */
  public initializeDataAdapterConfig = () => {
    return {
      class: this.getDataAdapterClass(),
      name: 'New JNDI Data Adapter',
      jndi: ''
    }
  };

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public validateDataAdapterConfig = (config: IDataAdapterConfig) => {

    // Check for errors...
    const errors = [];

    const nameError = nameValidator(config.name);
    if (nameError) {
      errors.push(nameError);
    }

    const urlError = notEmptyValidator(config.jndi);
    if (urlError) {
      errors.push(urlError);
    }
    
    if (errors.length > 0) {
      return errors.join("\n");
    }

    return undefined;

  }

  /**
   * Given a data adapter configuration, this methos returns null or undefined if
   * the configuration is valid, otherwise it provides an error.
   *
   * @memberof IDataAdapter
   */
  public createEditor = (config: IDataAdapterConfig, onDataAdapterSettingsChange?: (data: any) => void, readOnly?: boolean) => {
    return <JndiDataAdapterEditor {...config} onDataAdapterSettingsChange={onDataAdapterSettingsChange} readOnly={readOnly}/>
  }

  public getLanguages = () => {
    return ['text'];
  }

  public toXml = (config: IDataAdapterConfig) => {
    const xmlDoc = document.implementation.createDocument(null, this.getDataAdapterRootName());
    const root = xmlDoc.getElementsByTagName(this.getDataAdapterRootName())[0];
    root.setAttribute('class', this.getDataAdapterClass());

    const nameNode = createNode(xmlDoc, config, 'name', 'name');
    if (nameNode) {
      root.appendChild(nameNode);
    }

    const dataSourceNameNode = createNode(xmlDoc, config, 'jndi', 'dataSourceName');
    if (dataSourceNameNode) {
      root.appendChild(dataSourceNameNode);
    }

    const prolog = getProlog();
    const xml = prolog + (new XMLSerializer().serializeToString(xmlDoc));
    return format(xml, { indentation: '  ', collapseContent: true });
  }

  public toJson = (xml: Document) => {
    const result: any = {
      class: this.getDataAdapterClass(),
      name: '',
      jndi: '',
    }

    getDataAdapterNode(xml.getRootNode()).childNodes.forEach((childNode) => {
      const nodeName = childNode.nodeName;
      if (nodeName === 'name') {
        result.name = childNode.textContent;
      } else if (nodeName === 'dataSourceName') {
        result.jndi = childNode.textContent;
      }
    });
    return result;
  }
}