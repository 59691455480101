/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import ListPage from './list/ListPage';
import SubeditorDesigner from './SubeditorDesigner';

class ListDesigner extends React.Component {


  public render() {
    return <SubeditorDesigner getPage={() => {return <ListPage/>}}/>
  }

}

export default ListDesigner;
