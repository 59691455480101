/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import i18n from '../i18n';
import { isNullOrUndefined } from '../utils/misc';
import { ValidationResult } from '../utils/validators';
import { Button } from '../uxpl/button/Button';
import { TextInput } from '../uxpl/textinput/TextInput';

interface INameInputPanelProps {
    onNameCancel: () => void;
    onNameConfirm: (name: string) => void;
    initialName?: string,
    title?: string,
    nameItemError?: string,
    leftIcon?: React.ReactNode,
    validator?: (name: string | undefined) => string | undefined,
    advancedValidator?: (string) => ValidationResult,
}

interface INameInputPanelState {
    currentName?: string,
}


export class NameInputPanel extends React.Component<INameInputPanelProps, INameInputPanelState> {

    private textInputRef: React.RefObject<HTMLInputElement> = React.createRef();

    public state = {
        currentName: undefined,
    }

    componentDidMount(): void {
        if (this.textInputRef.current){
            this.textInputRef.current.setSelectionRange(0, this.textInputRef.current.value.length);
            this.textInputRef.current.focus();
        }
    }

    public static getDerivedStateFromProps(props: INameInputPanelProps, state: INameInputPanelState) {
        if (state.currentName !== props.initialName && state.currentName === undefined) {
            return {
                currentName: props.initialName,
            }
        }
        return null;
    }

    private onTextChange = (value: string) => {
        this.setState({
            currentName: value,
        });
    }

    private onCancel = () => {
        this.setState({
            currentName: undefined,
        }, () => {
            this.props.onNameCancel();
        });
    }

    private onConfirm = () => {
        if (this.state.currentName) {
            const name = this.state.currentName;
            this.setState({
                currentName: undefined,
            }, () => {
                this.props.onNameConfirm(name);
            });
        }
    }

    private isConfirmDisabled = () => {
        const result = isNullOrUndefined(this.state.currentName) || 
            (this.props.validator && this.props.validator(this.state.currentName) !== undefined) || 
                (this.props.advancedValidator && this.props.advancedValidator(this.state.currentName).message !== undefined);
        return result;
    }

    public render() {
        return <>
            <div style={{ display: 'flex', padding: 10, paddingBottom: 0 }}>{this.props.title ? this.props.title : i18n.t('common.dialog.newResourceTitle')}</div>
            <div style={{ padding: 10, display: 'flex' }}>
                <TextInput inputRef={this.textInputRef} className={'tc-jsw-new-name-panel-text'} advancedValidator={this.props.advancedValidator} id="input-resource=name" value={this.state.currentName} onChange={this.onTextChange} validator={this.props.validator} />
                {this.props.nameItemError !== '' ? (<span className="tc-error-label">{this.props.nameItemError}</span>) : null}
            </div>
            <div style={{ padding: 10, borderTop: '1px solid #dedede', display: 'flex', justifyContent: 'flex-end' }}>
                <Button size="large" color="secondary" variant="contained" className="tc-button-small" onClick={this.onCancel}>Cancel</Button>
                <Button size="large" color="primary" variant="contained" className="tc-button-small" disabled={this.isConfirmDisabled()} onClick={this.onConfirm}>Confirm</Button>
            </div>
        </>
    }
}
