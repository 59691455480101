/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const WhenNoDataTypeEnum = new PEnum({
    id: 'whenNoDataType', label: 'WhenNoDataType.label', default: 'NoPages', isEmptyUndefined: true,
    items: [
        { key: '', value: '' },
        { key: 'NoPages', value: 'WhenNoDataType.NoPages' },
        { key: 'BlankPage', value: 'WhenNoDataType.BlankPage' },
        { key: 'AllSectionsNoDetail', value: 'WhenNoDataType.AllSectionsNoDetail' },
        { key: 'NoDataSection', value: 'WhenNoDataType.NoDataSection' }
    ]
});

