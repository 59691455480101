/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { Switch } from "@jss/js-common";
import React from "react";
import i18n from '../../../i18n';
import { ICParameters } from "./ParametersTab";


export class ICBoolean extends React.Component<ICParameters> {

    render() {
        const value = this.props.parametersValue?.get(this.props.param.name);
        return <Switch
            key={this.props.param.name}
            onChange={this.onValueChange}
            label={i18n.t(this.props.param.name)}
            // defaultValue={this.props.placeholder}
            // inline={true}
            // disabled={d.readonly}
            size={'small'}
            checked={value !== undefined ? value : false}
            title={this.props.param.description}
        />
    }
    private onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        this.props.setParameterValue(this.props.param.name, newValue);
    }
}