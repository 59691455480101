/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */


import { APDescriptor } from "../../ui/APDescriptor";
import { CrosstabTypes } from "../../../../common/JrxmlModel/reader/JrxmlCrosstabUtils";
import { MCrosstabGroup } from "./MCrosstabGroup";
import { CrosstabColumnPositionEnum } from "../enums/CrosstabColumnPositionEnum";
import { PConnectedSizeAndPosition } from "../../ui/primitive/UPConnectedSizePosition";

export const FCrosstabColumnGroup = {
    position: CrosstabColumnPositionEnum,
    height: new PConnectedSizeAndPosition({ id: 'height', label: 'Height', min: 0 })
};

export const MCrosstabColumnGroup: APDescriptor = {
    type: CrosstabTypes.CROSSTAB_COLUMN_GROUP_NAME,
    id: 'crosstabColumnGroupProeprtiesDescriptor',
    label: 'Column Group',
    layouts: [...MCrosstabGroup.layouts, FCrosstabColumnGroup.position, FCrosstabColumnGroup.height]
};
