/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
const cache = new Map<string, Promise<void>>();

enum ScriptState {
    Loading = "LOADING",
    Ready = "READY",
    Error = "ERROR"
}

export async function loadExternalScript(src: string): Promise<void> {
    let result: Promise<void>;

    if (cache.has(src)) {
        result = cache.get(src);
    } else {
        result = new Promise<void>((resolve, reject) => {
            let script: HTMLScriptElement = document.head.querySelector(`script[src="${src}"]`);

            if (!script) {
                const scriptEventHandler = (event: MessageEvent): void => {
                    if (event.type === "load") {
                        script.setAttribute("data-script-state", ScriptState.Ready);

                        script.removeEventListener("load", scriptEventHandler);
                        script.removeEventListener("error", scriptEventHandler);
                        resolve();
                    }
                    if (event.type === "error") {
                        script.setAttribute("data-script-state", ScriptState.Error);

                        script.removeEventListener("load", scriptEventHandler);
                        script.removeEventListener("error", scriptEventHandler);
                        reject(new Error(`Unable to load script: ${src}`));
                    }
                };

                script = document.createElement("script");
                script.src = src;
                script.async = true;

                script.addEventListener("load", scriptEventHandler);
                script.addEventListener("error", scriptEventHandler);

                document.head.appendChild(script);
            }
            // should not happen if the script was added by us
            else {
                const existingState = script.getAttribute("data-script-state") as ScriptState;
                if (ScriptState.Ready === existingState) {
                    resolve();
                } else if (ScriptState.Error === existingState) {
                    reject(new Error(`Unable to load script: ${src}`));
                }
                // it may happen that the script is already on the page
                else {
                    resolve();
                }
            }
        });

        cache.set(src, result);
    }

    return result;
}
