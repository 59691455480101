/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

const HTML5CHART_JR = 'HTML5CHART_JR';
const FUSIONCHART = 'FUSIONCHART';
const STATICTEXT = 'STATICTEXT';
const TEXTFIELD = 'TEXTFIELD';
const FRAME = 'FRAME';
const ELEMENT_GROUP = 'ELEMENT_GROUP';
const IMAGE = 'IMAGE';
const BREAK = 'BREAK';
const RECTANGLE = 'RECTANGLE';
const SUBREPORT = 'SUBREPORT';
const BARCODE = 'BARCODE';
const ELLIPSE = 'ELLIPSE';
const LINE = 'LINE';
const LIST = 'LIST';
const CROSSTAB = 'CROSSTAB';
const TABLE = 'TABLE';
const MAP = 'MAP';
const TIBCO_MAP = 'TIBCO_MAP';
const FUSION_MAP = 'FUSION_MAP';
const FUSION_WIDGET = 'FUSION_WIDGET';
const JFREECHART = 'JFREECHART';
const GENERIC_ELEMENT = 'GENERIC_ELEMENT';
const COMPONENT_ELEMENT_GENERIC = 'COMPONENT_ELEMENT_GENERIC';
const AD_HOC_COMPONENT = 'AD_HOC_COMPONENT';
const CVC_ELEMENT = 'CVC';
const REPORT = 'PAGE';
const BOOK = 'BOOK';

const BAND = 'BAND';
const PART = 'PART';
const SECTION_GROUP = 'SECTION_GROUP';
const SECTION_DETAIL = 'SECTION_DETAIL';
const BAND_PLACEHOLDER = 'BAND_PLACEHOLDER';
const BAND_TITLE = 'TITLE';
const BAND_PAGE_HEADER = 'PAGE_HEADER';
const BAND_COLUMN_HEADER = 'COLUMN_HEADER';
const BAND_GROUP_HEADER = 'GROUP_HEADER';
const BAND_DETAIL = 'DETAIL';
const BAND_GROUP_FOOTER = 'GROUP_FOOTER';
const BAND_COLUMN_FOOTER = 'COLUMN_FOOTER';
const BAND_PAGE_FOOTER = 'PAGE_FOOTER';
const BAND_LAST_PAGE_FOOTER = 'LAST_PAGE_FOOTER';
const BAND_SUMMARY = 'SUMMARY';
const BAND_NO_DATA = 'NO_DATA';
const BAND_BACKGROUND = 'BACKGROUND';

//non visual elements
const STYLE = 'STYLE';
const CONDITIONAL_STYLE = 'CONDITIONAL_STYLE';
const STYLE_TEMPLATE_REFERENCE = 'STYLE_TEMPLATE_REFERENCE';
const VARIABLE = 'VARIABLE';
const FIELD = 'FIELD';
const SORT_FIELD = 'SORT_FIELD';
const PARAMETER = 'PARAMETER';
const DATASET = 'DATASET';
const DATASET_GROUP = 'DATASET_GROUP';
const MAIN_DATASET_GROUP = 'MAIN_DATASET_GROUP';
const SCRIPTLET = 'SCRIPTLET';

export const BandTypes = {
    BAND_TITLE,
    BAND_PAGE_HEADER,
    BAND_COLUMN_HEADER,
    BAND_GROUP_HEADER,
    BAND_DETAIL,
    BAND_GROUP_FOOTER,
    BAND_COLUMN_FOOTER,
    BAND_PAGE_FOOTER,
    BAND_LAST_PAGE_FOOTER,
    BAND_SUMMARY,
    BAND_NO_DATA,
    BAND_BACKGROUND,
}

export const ElementTypes = {
    REPORT,
    BOOK,
    BAND,
    PART,
    SECTION_GROUP,
    SECTION_DETAIL,
    BAND_PLACEHOLDER,
    FRAME,
    ELEMENT_GROUP,
    IMAGE,
    BREAK,
    RECTANGLE,
    ELLIPSE,
    LINE,
    LIST,
    SUBREPORT,
    BARCODE,
    CROSSTAB,
    JFREECHART,
    TABLE,
    MAP,
    TIBCO_MAP,
    FUSION_MAP,
    FUSION_WIDGET,
    GENERIC_ELEMENT,
    HTML5CHART_JR,
    FUSIONCHART,
    TEXTFIELD,
    STATICTEXT,
    CVC_ELEMENT,
    COMPONENT_ELEMENT_GENERIC,
    AD_HOC_COMPONENT,
    STYLE,
    CONDITIONAL_STYLE,
    STYLE_TEMPLATE_REFERENCE,
    VARIABLE,
    FIELD,
    SORT_FIELD,
    PARAMETER,
    DATASET,
    DATASET_GROUP,
    MAIN_DATASET_GROUP,
    SCRIPTLET
}
