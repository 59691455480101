/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { getPath, reg, UiProperty, UiPropertyProps } from '../../ui/UiProperty';
import { getIn, Map, List, isImmutable } from 'immutable';
import _ from 'lodash';
import * as ReportActions from '../../../../../actions/reportActions';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, TimedSearchBar, Typography } from '@jss/js-common';
import { connect } from 'react-redux';
import { IConnectedUPTable, PTable, UPTableState } from '../../ui/tables/UPTable';
import Immutable from 'immutable';
import i18n from '../../../../../i18n';

const TYPEID = 'variablesTable';
export class PVariablesTable extends PTable {
    public constructor(init: Partial<PVariablesTable>) {
        super(init);
        Object.assign(this, { ...init, type: TYPEID });
    }
}
reg(TYPEID, (mc) => { return <UPVariablesTable mcontext={mc} />; });

export interface UPVariablesTableState extends UPTableState {
    system: boolean;
}

class UPVariablesTable extends React.Component<UiPropertyProps & IConnectedUPTable, UPVariablesTableState> {

    public state: UPVariablesTableState = { selected: 0, system: false };

    private isExpanded = () => {
        const defaultValue = (this.props.mcontext.descriptor as PVariablesTable).expanded === true;
        const isExpanded = this.props.mcontext.rootModel.getIn(['widgetStatus', this.props.mcontext.descriptor.id, 'expanded'], defaultValue) as boolean;
        return isExpanded;        
    }

    private handleExpanded = () => {
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expanded'], !this.isExpanded(), false, true);
    }

    render() {
        return <Accordion expanded={this.isExpanded()} onChange={this.handleExpanded} size='small'>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className="js-jrws-search-accordion-header" size='small'>
                <Typography style={{ textTransform: 'capitalize' }}>{i18n.t(this.props.mcontext.descriptor.label)}</Typography>
                {this.renderSearch()}
                <IconButton color={this.state.system ? 'primary' : 'secondary'} icon='list' size='small' onClick={this.switchList}
                    title={`${i18n.t('reportdesigner.properties.query.filteredtable.filter.button.tooltip')} ${i18n.t(this.props.mcontext.descriptor.label)}`} />
                <IconButton color='primary' icon='plus' size='small' style={{ marginRight: '-8px', marginLeft: '4px' }} onClick={this.addItem}  title={i18n.t('common.actions.add')}/>
            </AccordionSummary>
            <AccordionDetails
                style={this.props.mcontext.descriptor.style ? this.props.mcontext.descriptor.style : { display: 'block', maxHeight: '350px', overflow: 'auto', paddingLeft: 0, paddingRight: 0 }}
                className={this.props.mcontext.descriptor.className}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {this.renderFields()}
                </div>

            </AccordionDetails>
        </Accordion>;
    }
    private switchList = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState((state) => ({ system: !state.system }));
    }
    private getValue = (f: any, t: string[]): string => {
        let r = f;
        t.forEach(k => {
            if (r === undefined || r === null)
                return r;
            r = r[k];
        });
        return r;
    }

    private getRowsPath = (d: PVariablesTable): string[] => {
        if (this.state.system) {
            return ['cachedData', 'comboValues', 'systemVariables'];
        }
        return getPath(d.id, this.props.mcontext.elements[0].path);
    }
    private getRows = (p: string[]): List<Map<string, any>> | undefined => {
        if (this.state.system) {
            let vars = this.props.mcontext.rootModel.getIn(p) as List<Map<string, any>> | undefined;
            if (vars === undefined) {
                vars = Immutable.List();
            }
            const gpath = getPath("groups", this.props.mcontext.elements[0].path);
            if (gpath) {
                const groups = this.props.mcontext.model.getIn(gpath) as List<Map<string, any>> | undefined;
                if (groups) {
                    groups.forEach(g => {
                        const n = g.getIn(["name"]);
                        vars = vars.push(Immutable.Map({
                            name: n + '_COUNT',
                            valueClass: 'Integer.class',
                            resetType: 'Group',
                            resetGroup: n,
                            calculation: 'Count',
                            systemDefined: true,
                            valueExpression: 'new java.lang.Integer(1)',
                            initValueExpression: 'new java.lang.Integer(0)'
                        }));
                    });
                }
            }
            return vars;
        }
        return this.props.mcontext.model.getIn(p) as List<Map<string, any>> | undefined;
    }

    private renderFields = () => {
        const d = this.props.mcontext.descriptor as PTable;
        const p = this.getRowsPath(d);
        const v = this.getRows(p);
        if (!v) {
            return <div style={{textAlign: 'center', color: 'lightgray'}}>{i18n.t('tableElement.novalues')}</div>;
        }

        let fields: List<Map<string, any>> = v;
        if (this.state.search?.length > 0) {
            fields = fields.filter((field) => {
                const name = field.get('name');
                return name && name.toLowerCase().includes(this.state.search);
            });
        }
        if (this.state.system) {
            const isMain = !getPath(d.id, this.props.mcontext.elements[0].path).includes('subdatasets');
            if (!isMain) {
                fields = fields.filter((field) =>
                    field.get('mainDataset') !== true
                );
            }
        }
        if(fields.size < 1){
            return <div style={{textAlign: 'center', color: 'lightgray'}}>{i18n.t('tableElement.novalues')}</div>;
        }
        const tmp = [];
        fields.map((f, index) => {
            if (f !== undefined) {
                let itemname = `${index} - ${d.rowLabel ? d.rowLabel : 'item'}`;
                let imt = isImmutable(f);
                let field: any = f;
                let found = false;
                if (d.rowLabelPaths) {
                    d.rowLabelPaths.some((key) => {
                        const t = key.split('/');
                        const tmp = t.length === 1 ? (imt ? f.get(key) : f[key]) : (imt ? getIn(f, t, undefined) : this.getValue(f, t));
                        if (tmp !== undefined) {
                            itemname = tmp;
                            if (imt) {
                                field = f.toJS();
                                imt = false;
                                found = true;
                            }
                            return true;
                        }
                        return false;
                    });
                }
                const fieldName = itemname;
                let fieldId;
                if (imt) {
                    fieldId = f.get('id');
                } else if (typeof f === 'string') {
                    const els = _.cloneDeep(this.props.mcontext.elements);
                    const dl = d.layouts[0];
                    els.forEach(k => { k.path = [...p]; k.path.push(index) });
                    const mc = { ...this.props.mcontext, descriptor: { ...dl }, elements: els }
                    if (this.state.system) {
                        mc.model = mc.rootModel;
                        mc.descriptor.readonly = true;
                    }
                    tmp.push(<div key={'div.' + fieldId + '.' + index} style={{ display: 'grid', gridTemplateColumns: '1fr 23px' }}>
                        <UiProperty key={fieldId} mcontext={mc} /> {this.renderDelete(index)}
                    </div>); return;
                } else {
                    if (!found && field['id']) {
                        fieldId = field['id'];
                    } else fieldId = itemname
                }
                if (fieldId === undefined) fieldId = 'f' + index;
                if (this.props.mcontext.descriptor.layouts?.length > 0) {
                    const els = _.cloneDeep(this.props.mcontext.elements);
                    const dl = d.layouts[0];
                    els.forEach(k => { k.path = [...p]; k.path.push(index); });
                    const mc = { ...this.props.mcontext, descriptor: { ...dl, id: fieldName }, elements: els }
                    if (this.state.system) {
                        mc.model = mc.rootModel;
                        mc.descriptor.readonly = true;
                    }
                    tmp.push(<div key={'div.' + fieldId + '.' + index} className="jr-MuiFormControl-root jr-MuiTextField-root jr-mInput jr-mInputText mui  jr-mInputLarge jr-mInputInline">
                        <UiProperty key={fieldId} mcontext={mc} /> {mc.descriptor.readonly ? <></> : this.renderDelete(index)}
                    </div>);
                    return;
                }
                tmp.push(<div key={'div.key'} className="jr-MuiFormControl-root jr-MuiTextField-root jr-mInput jr-mInputText mui  jr-mInputLarge jr-mInputInline">{fieldName} {this.renderDelete(index)}</div>);
            }
        })
        return <>{tmp}</>;
    }

    private deleteElement = (key) => {
        if (this.props.deleteElement) {
            const path = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
            const p = ['model', ...path, key];
            this.props.deleteElement(p);
        }
    }

    private renderDelete(key) {
        return <IconButton key={'ib.' + key} icon='delete' onClick={() => this.deleteElement(key)} title={i18n.t('common.actions.menu.delete')}/>
        // return <MIconButton key={'ib.' + key} aria-label='delete' onClick={() => this.deleteElement(key)} ><DeleteIcon /> </MIconButton>;
    }

    private renderSearch() {
        const d = this.props.mcontext.descriptor as PTable;
        if (d.seachable === true)
            return <TimedSearchBar onClick={this.onClick} className="js-jrws-properties-search" style={{ marginTop: '12px' }} placeholder='search ...' id="file-searchbar" onChange={this.seearchTextChange} value={this.state.search} pauseTime={40} />
    }

    private onClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private seearchTextChange = (v: string) => {
        v = v.trim().toLowerCase();
        v = v.length > 0 ? v : undefined;
        this.setState({ search: v });
    }
    private addItem = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.props.mcontext.setObjectProperties([this.props.mcontext.descriptor.id, 'expanded'], true, false, true);
        const d = this.props.mcontext.descriptor as PTable;
        let def = {};
        if (d.default !== undefined) {
            if (d.default !== Object(d.default) || _.isString(d.default))
                def = d.default;
            else {
                def = { ...d.default };
                if (def['name']) {
                    const pth = getPath(this.props.mcontext.descriptor.id, this.props.mcontext.elements[0].path);
                    const v = this.props.mcontext.model.getIn(pth) as List<Map<string, any>> | undefined;

                    if (v) {
                        const fields: List<Map<string, any>> = v;
                        def['name'] = this.findName(def['name'], def['name'], 1, fields);
                    }
                }
            }
        }
        const p: string[] = ['model', ...getPath(d.id, this.props.mcontext.elements[0].path)];
        this.props.addProperty(p, def);
    }

    private findName = (n: string, prefix: string, id: number, fields: List<Map<string, any>>): string => {
        for (const f of fields) {
            const name = f.get('name');
            if (name === n) {
                return this.findName(prefix + id, prefix, id + 1, fields);
            }
        }
        return n;
    }
}


const mapStateToProps = () => {
    return {};
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteElement: (path: string[]) => { dispatch(ReportActions.deleteElement(path)); },
        addProperty: (path: string[], element) => { dispatch(ReportActions.addProperty(path, element)); },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UPVariablesTable);
