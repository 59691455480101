
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { default as MaterialIconButton } from '@material-ui/core/IconButton';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';


const theme = createTheme({
    overrides: {
        MuiIconButton: {
            root: {
                '&:hover': {
                    backgroundColor: "$labelcolor"
                },
                padding: "2px"
            }
        }
    }
})

interface IIconButton {
    disabled?: boolean,
    ariaLabel?: string,
}

export class IconWrapper extends React.Component<IIconButton> {

    public render() {
        return <MuiThemeProvider theme={theme}>
            <MaterialIconButton disabled={this.props.disabled} aria-label={this.props.ariaLabel}>
                {this.props.children}
            </MaterialIconButton>
        </MuiThemeProvider>
    }
}
