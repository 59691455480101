/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PEnum } from '../../ui/primitive/UPEnum';

export const LOCALE_ITEMS = [
	{ key: '', value: '' },
	{ key: '', value: '' },
	{ key: 'ar_AE', value: 'Arabic (United Arab Emirates)' },
	{ key: 'ar_JO', value: 'Arabic (Jordan)' },
	{ key: 'ar_SY', value: 'Arabic (Syria)' },
	{ key: 'hr_HR', value: 'Croatian (Croatia)' },
	{ key: 'fr_BE', value: 'French (Belgium)' },
	{ key: 'es_PA', value: 'Spanish (Panama)' },
	{ key: 'mt_MT', value: 'Maltese (Malta)' },
	{ key: 'es_VE', value: 'Spanish (Venezuela)' },
	{ key: 'bg', value: 'Bulgarian' },
	{ key: 'zh_TW', value: 'Chinese (Taiwan)' },
	{ key: 'it', value: 'Italian' },
	{ key: 'ko', value: 'Korean' },
	{ key: 'uk', value: 'Ukrainian' },
	{ key: 'lv', value: 'Latvian' },
	{ key: 'da_DK', value: 'Danish (Denmark)' },
	{ key: 'es_PR', value: 'Spanish (Puerto Rico)' },
	{ key: 'vi_VN', value: 'Vietnamese (Vietnam)' },
	{ key: 'en_US', value: 'English (United States)' },
	{ key: 'sr_ME', value: 'Serbian (Montenegro)' },
	{ key: 'sv_SE', value: 'Swedish (Sweden)' },
	{ key: 'es_BO', value: 'Spanish (Bolivia)' },
	{ key: 'en_SG', value: 'English (Singapore)' },
	{ key: 'ar_BH', value: 'Arabic (Bahrain)' },
	{ key: 'pt', value: 'Portuguese' },
	{ key: 'ar_SA', value: 'Arabic (Saudi Arabia)' },
	{ key: 'sk', value: 'Slovak' },
	{ key: 'ar_YE', value: 'Arabic (Yemen)' },
	{ key: 'hi_IN', value: 'Hindi (India)' },
	{ key: 'ga', value: 'Irish' },
	{ key: 'en_MT', value: 'English (Malta)' },
	{ key: 'fi_FI', value: 'Finnish (Finland)' },
	{ key: 'et', value: 'Estonian' },
	{ key: 'sv', value: 'Swedish' },
	{ key: 'cs', value: 'Czech' },
	{ key: 'sr_BA_#Latn', value: 'Serbian (Latin,Bosnia & Herzegovina)' },
	{ key: 'el', value: 'Greek' },
	{ key: 'uk_UA', value: 'Ukrainian (Ukraine)' },
	{ key: 'hu', value: 'Hungarian' },
	{ key: 'fr_CH', value: 'French (Switzerland)' },
	{ key: 'in', value: 'Indonesian' },
	{ key: 'es_AR', value: 'Spanish (Argentina)' },
	{ key: 'ar_EG', value: 'Arabic (Egypt)' },
	{ key: 'ja_JP_JP_#u-ca-japanese', value: 'Japanese (Japan,JP)' },
	{ key: 'es_SV', value: 'Spanish (El Salvador)' },
	{ key: 'pt_BR', value: 'Portuguese (Brazil)' },
	{ key: 'be', value: 'Belarusian' },
	{ key: 'is_IS', value: 'Icelandic (Iceland)' },
	{ key: 'cs_CZ', value: 'Czech (Czechia)' },
	{ key: 'es', value: 'Spanish' },
	{ key: 'pl_PL', value: 'Polish (Poland)' },
	{ key: 'tr', value: 'Turkish' },
	{ key: 'ca_ES', value: 'Catalan (Spain)' },
	{ key: 'sr_CS', value: 'Serbian (Serbia and Montenegro)' },
	{ key: 'ms_MY', value: 'Malay (Malaysia)' },
	{ key: 'hr', value: 'Croatian' },
	{ key: 'lt', value: 'Lithuanian' },
	{ key: 'es_ES', value: 'Spanish (Spain)' },
	{ key: 'es_CO', value: 'Spanish (Colombia)' },
	{ key: 'bg_BG', value: 'Bulgarian (Bulgaria)' },
	{ key: 'sq', value: 'Albanian' },
	{ key: 'fr', value: 'French' },
	{ key: 'ja', value: 'Japanese' },
	{ key: 'sr_BA', value: 'Serbian (Bosnia & Herzegovina)' },
	{ key: 'is', value: 'Icelandic' },
	{ key: 'es_PY', value: 'Spanish (Paraguay)' },
	{ key: 'de', value: 'German' },
	{ key: 'es_EC', value: 'Spanish (Ecuador)' },
	{ key: 'es_US', value: 'Spanish (United States)' },
	{ key: 'ar_SD', value: 'Arabic (Sudan)' },
	{ key: 'en', value: 'English' },
	{ key: 'ro_RO', value: 'Romanian (Romania)' },
	{ key: 'en_PH', value: 'English (Philippines)' },
	{ key: 'ca', value: 'Catalan' },
	{ key: 'ar_TN', value: 'Arabic (Tunisia)' },
	{ key: 'sr_ME_#Latn', value: 'Serbian (Latin,Montenegro)' },
	{ key: 'es_GT', value: 'Spanish (Guatemala)' },
	{ key: 'sl', value: 'Slovenian' },
	{ key: 'ko_KR', value: 'Korean (South Korea)' },
	{ key: 'el_CY', value: 'Greek (Cyprus)' },
	{ key: 'es_MX', value: 'Spanish (Mexico)' },
	{ key: 'ru_RU', value: 'Russian (Russia)' },
	{ key: 'es_HN', value: 'Spanish (Honduras)' },
	{ key: 'zh_HK', value: 'Chinese (Hong Kong SAR China)' },
	{ key: 'no_NO_NY', value: 'Norwegian (Norway,Nynorsk)' },
	{ key: 'hu_HU', value: 'Hungarian (Hungary)' },
	{ key: 'th_TH', value: 'Thai (Thailand)' },
	{ key: 'ar_IQ', value: 'Arabic (Iraq)' },
	{ key: 'es_CL', value: 'Spanish (Chile)' },
	{ key: 'fi', value: 'Finnish' },
	{ key: 'ar_MA', value: 'Arabic (Morocco)' },
	{ key: 'ga_IE', value: 'Irish (Ireland)' },
	{ key: 'mk', value: 'Macedonian' },
	{ key: 'tr_TR', value: 'Turkish (Turkey)' },
	{ key: 'et_EE', value: 'Estonian (Estonia)' },
	{ key: 'ar_QA', value: 'Arabic (Qatar)' },
	{ key: 'sr__#Latn', value: 'Serbian (Latin)' },
	{ key: 'pt_PT', value: 'Portuguese (Portugal)' },
	{ key: 'fr_LU', value: 'French (Luxembourg)' },
	{ key: 'ar_OM', value: 'Arabic (Oman)' },
	{ key: 'th', value: 'Thai' },
	{ key: 'sq_AL', value: 'Albanian (Albania)' },
	{ key: 'es_DO', value: 'Spanish (Dominican Republic)' },
	{ key: 'es_CU', value: 'Spanish (Cuba)' },
	{ key: 'ar', value: 'Arabic' },
	{ key: 'ru', value: 'Russian' },
	{ key: 'en_NZ', value: 'English (New Zealand)' },
	{ key: 'sr_RS', value: 'Serbian (Serbia)' },
	{ key: 'de_CH', value: 'German (Switzerland)' },
	{ key: 'es_UY', value: 'Spanish (Uruguay)' },
	{ key: 'ms', value: 'Malay' },
	{ key: 'el_GR', value: 'Greek (Greece)' },
	{ key: 'iw_IL', value: 'Hebrew (Israel)' },
	{ key: 'en_ZA', value: 'English (South Africa)' },
	{ key: 'th_TH_TH_#u-nu-thai', value: 'Thai (Thailand,TH)' },
	{ key: 'hi', value: 'Hindi' },
	{ key: 'fr_FR', value: 'French (France)' },
	{ key: 'de_AT', value: 'German (Austria)' },
	{ key: 'nl', value: 'Dutch' },
	{ key: 'no_NO', value: 'Norwegian (Norway)' },
	{ key: 'en_AU', value: 'English (Australia)' },
	{ key: 'vi', value: 'Vietnamese' },
	{ key: 'nl_NL', value: 'Dutch (Netherlands)' },
	{ key: 'fr_CA', value: 'French (Canada)' },
	{ key: 'lv_LV', value: 'Latvian (Latvia)' },
	{ key: 'de_LU', value: 'German (Luxembourg)' },
	{ key: 'es_CR', value: 'Spanish (Costa Rica)' },
	{ key: 'ar_KW', value: 'Arabic (Kuwait)' },
	{ key: 'sr', value: 'Serbian' },
	{ key: 'ar_LY', value: 'Arabic (Libya)' },
	{ key: 'mt', value: 'Maltese' },
	{ key: 'it_CH', value: 'Italian (Switzerland)' },
	{ key: 'da', value: 'Danish' },
	{ key: 'de_DE', value: 'German (Germany)' },
	{ key: 'ar_DZ', value: 'Arabic (Algeria)' },
	{ key: 'sk_SK', value: 'Slovak (Slovakia)' },
	{ key: 'lt_LT', value: 'Lithuanian (Lithuania)' },
	{ key: 'it_IT', value: 'Italian (Italy)' },
	{ key: 'en_IE', value: 'English (Ireland)' },
	{ key: 'zh_SG', value: 'Chinese (Singapore)' },
	{ key: 'ro', value: 'Romanian' },
	{ key: 'en_CA', value: 'English (Canada)' },
	{ key: 'nl_BE', value: 'Dutch (Belgium)' },
	{ key: 'no', value: 'Norwegian' },
	{ key: 'pl', value: 'Polish' },
	{ key: 'zh_CN', value: 'Chinese (China)' },
	{ key: 'ja_JP', value: 'Japanese (Japan)' },
	{ key: 'de_GR', value: 'German (Greece)' },
	{ key: 'sr_RS_#Latn', value: 'Serbian (Latin,Serbia)' },
	{ key: 'iw', value: 'Hebrew' },
	{ key: 'en_IN', value: 'English (India)' },
	{ key: 'ar_LB', value: 'Arabic (Lebanon)' },
	{ key: 'es_NI', value: 'Spanish (Nicaragua)' },
	{ key: 'zh', value: 'Chinese' },
	{ key: 'mk_MK', value: 'Macedonian (North Macedonia)' },
	{ key: 'be_BY', value: 'Belarusian (Belarus)' },
	{ key: 'sl_SI', value: 'Slovenian (Slovenia)' },
	{ key: 'es_PE', value: 'Spanish (Peru)' },
	{ key: 'in_ID', value: 'Indonesian (Indonesia)' },
	{ key: 'en_GB', value: 'English (United Kingdom)' },
];

export const Locale = new PEnum({ id: 'Locale', items: LOCALE_ITEMS })