/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const ParameterEvaluationTimeEnum = new PEnum({
    id: 'evaluationTime', label: 'ParameterEvaluationTime.label', isEmptyUndefined: true,
    description: 'ParameterEvaluationTime.description', default: 'Late',
    inheritedPropertyId: 'net.sf.jasperreports.parameter.evaluation.time',
    items: [
        { key: '', value: '' },
        { key: 'Early', value: 'ParameterEvaluationTime.Early' },
        { key: 'Late', value: 'ParameterEvaluationTime.Late' }
    ]
});

