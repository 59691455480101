/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

/* tslint:disable:max-classes-per-file */

import { makeActionCreator } from './actionUtils';

const DESIGNER_SET_DIRTY = 'DESIGNER_SET_DIRTY';
const DESIGNER_VIEWPORT_CHANGED = 'DESIGNER_VIEWPORT_CHANGED';
const DESIGNER_CLIENTAREA_CHANGED = 'DESIGNER_CLIENTAREA_CHANGED';
const DESIGNER_SET_ZOOM = 'DESIGNER_SET_ZOOM';
const DESIGNER_INCREASE_ZOOM = 'DESIGNER_INCREASE_ZOOM';
const DESIGNER_DECREASE_ZOOM = 'DESIGNER_DECREASE_ZOOM';
const DESIGNER_SCROLL_X = 'DESIGNER_SCROLL_X';
const DESIGNER_SCROLL_Y = 'DESIGNER_SCROLL_Y';
const DESIGNER_SET_LEFT_COLUMN_VISIBLE = 'DESIGNER_SET_LEFT_COLUMN_VISIBLE';
const DESIGNER_SET_DRAG_OP = 'DESIGNER_SET_DRAG_OP';
const DESIGNER_CLEAR_DRAG_OP = 'DESIGNER_CLEAR_DRAG_OP';
const DESIGNER_UPDATE_DRAG_OP = 'DESIGNER_UPDATE_DRAG_OP';
const DESIGNER_ADD_INTERACTION_FIGURE = 'DESIGNER_ADD_INTERACTION_FIGURE';
const DESIGNER_ADD_INTERACTION_FIGURES = 'DESIGNER_ADD_INTERACTION_FIGURES';
const DESIGNER_UPDATE_INTERACTION_FIGURE = 'DESIGNER_UPDATE_INTERACTION_FIGURE';
const DESIGNER_UPDATE_INTERACTION_FIGURES = 'DESIGNER_UPDATE_INTERACTION_FIGURES';
const DESIGNER_REMOVE_INTERACTION_FIGURE = 'DESIGNER_REMOVE_INTERACTION_FIGURE';
const DESIGNER_REMOVE_INTERACTION_FIGURES = 'DESIGNER_REMOVE_INTERACTION_FIGURES';
const DESIGNER_HIGHLIGHT_CONTAINER = 'DESIGNER_HIGHLIGHT_CONTAINER';

// Actions used to kick sagas
const DESIGNER_MOUSE_DOWN_ACTION = 'DESIGNER_MOUSE_DOWN_ACTION';
const DESIGNER_MOUSE_MOVE_ACTION = 'DESIGNER_MOUSE_MOVE_ACTION';
const DESIGNER_MOUSE_UP_ACTION = 'DESIGNER_MOUSE_UP_ACTION';
const DESIGNER_UNDO_ACTION = 'DESIGNER_UNDO_ACTION';
const DESIGNER_REDO_ACTION = 'DESIGNER_REDO_ACTION';
const DESIGNER_PALETTE_ITEM_DRAG_ACTION = 'DESIGNER_PALETTE_ITEM_DRAG_ACTION';
const DESIGNER_PALETTE_ITEM_DROP_ACTION = 'DESIGNER_PALETTE_ITEM_DROP_ACTION';
const DESIGNER_PALETTE_ITEM_DRAG_START_ACTION = 'DESIGNER_PALETTE_ITEM_DRAG_START_ACTION';
const DESIGNER_PALETTE_ITEM_DRAG_END_ACTION = 'DESIGNER_PALETTE_ITEM_DRAG_END_ACTION';
const DESIGNER_BAND_RESIZE_INIT_ACTION = 'DESIGNER_BAND_RESIZE_INIT_ACTION';
const DESIGNER_DEBUG_CREATE_100_ELEMENTS_ACTION = 'DESIGNER_DEBUG_CREATE_100_ELEMENTS_ACTION';

// Drag operations
const DRAG_OP_ELEMENT = 'DRAG_OP_ELEMENT';
const DRAG_OP_SELECTION = 'DRAG_OP_SELECTION';
const DRAG_OP_ELEMENT_RESIZE = 'DRAG_OP_ELEMENT_RESIZE'; // Used when a selection handle is clicked.
const DRAG_OP_PAN = 'DRAG_OP_PAN';
const DRAG_OP_BAND_RESIZE = 'DRAG_OP_BAND_RESIZE';


export const setDirty = makeActionCreator(DESIGNER_SET_DIRTY, 'isDirty');
export const viewportChanged = makeActionCreator(DESIGNER_VIEWPORT_CHANGED, 'viewport');
export const clientAreaChanged = makeActionCreator(DESIGNER_CLIENTAREA_CHANGED, 'clientArea');
export const setZoom = makeActionCreator(DESIGNER_SET_ZOOM, 'zoom');
export const increaseZoom = makeActionCreator(DESIGNER_INCREASE_ZOOM);
export const decreaseZoom = makeActionCreator(DESIGNER_DECREASE_ZOOM);
export const scrollX = makeActionCreator(DESIGNER_SCROLL_X, 'x');
export const scrollY = makeActionCreator(DESIGNER_SCROLL_Y, 'y');
export const showLeftColumn = makeActionCreator( DESIGNER_SET_LEFT_COLUMN_VISIBLE, 'visible' );
export const setDragOperation = makeActionCreator(DESIGNER_SET_DRAG_OP, 'operation', 'location', 'options');
export const clearDragOperation = makeActionCreator(DESIGNER_CLEAR_DRAG_OP);
export const updateDragOperation = makeActionCreator(DESIGNER_UPDATE_DRAG_OP, 'newLocation', 'dragStarted', 'options');
export const addInteractionFigure = makeActionCreator(DESIGNER_ADD_INTERACTION_FIGURE, 'id', 'figure');
export const addInteractionFigures = makeActionCreator(DESIGNER_ADD_INTERACTION_FIGURES, 'figures');
export const updateInteractionFigure = makeActionCreator(DESIGNER_UPDATE_INTERACTION_FIGURE, 'id', 'figure');
export const updateInteractionFigures = makeActionCreator(DESIGNER_UPDATE_INTERACTION_FIGURES, 'figures');
export const removeInteractionFigure = makeActionCreator(DESIGNER_REMOVE_INTERACTION_FIGURE, 'id');
export const removeInteractionFigures = makeActionCreator(DESIGNER_REMOVE_INTERACTION_FIGURES, 'figureIds');
export const highilightContainer = makeActionCreator(DESIGNER_HIGHLIGHT_CONTAINER, 'path');

// Sagas....
export const undo = makeActionCreator( DESIGNER_UNDO_ACTION );
export const redo = makeActionCreator( DESIGNER_REDO_ACTION );
export const mouseDown = makeActionCreator(DESIGNER_MOUSE_DOWN_ACTION, 'point', 'options');
export const mouseMove = makeActionCreator(DESIGNER_MOUSE_MOVE_ACTION, 'point', 'options', 'scrollDirection');
export const mouseUp = makeActionCreator(DESIGNER_MOUSE_UP_ACTION, 'point', 'options');
export const paletteItemDrag = makeActionCreator(DESIGNER_PALETTE_ITEM_DRAG_ACTION, 'location', 'item');
export const paletteItemDragStart = makeActionCreator(DESIGNER_PALETTE_ITEM_DRAG_START_ACTION);
export const paletteItemDragEnd = makeActionCreator(DESIGNER_PALETTE_ITEM_DRAG_END_ACTION);
export const paletteItemDrop = makeActionCreator(DESIGNER_PALETTE_ITEM_DROP_ACTION, 'location', 'item');
export const initBandResize = makeActionCreator(DESIGNER_BAND_RESIZE_INIT_ACTION, 'location', 'bandId', 'inverse');


export const DragOperations = {
  DRAG_OP_ELEMENT,
  DRAG_OP_SELECTION,
  DRAG_OP_ELEMENT_RESIZE,
  DRAG_OP_PAN,
  DRAG_OP_BAND_RESIZE
}

const CREATE_NEW_DOCUMENT = 'CREATE_NEW_DOCUMENT';
const OPEN_DOCUMENT = 'OPEN_DOCUMENT';
const SET_DESCRIPTOR = 'SET_DESCRIPTOR';
const CREATE_ELEMENT = 'CREATE_ELEMENT';
const CREATE_ELEMENTS = 'CREATE_ELEMENTS';
const CREATE_PROPERTY = 'CREATE_PROPERTY';
const CREATE_PROPERTIES = 'CREATE_PROPERTIES';
const CREATE_BAND = 'CREATE_BAND';
const EXPAND_OUTLINE_ELEMENT = 'EXPAND_OUTLINE_ELEMENT';
const SELECT_ELEMENT = 'SELECT_ELEMENT';
const SELECT_DATASET = 'SELECT_DATASET';
const DESELECT_ELEMENT = 'UNSELECT_ELEMENT';
const SELECT_ELEMENTS = 'SELECT_ELEMENTS';
const CLEAR_SELECTION = 'CLEAR_SELECTION';
const UPDATE_ELEMENT_SIZE_AND_POSITION = 'UPDATE_ELEMENT_SIZE_AND_POSITION';
const UPDATE_ELEMENTS_CONTAINER = 'UPDATE_ELEMENTS_CONTAINER';
const SET_HIGHLIGHTED_ELEMENTS = 'SET_HIGHLIGHTED_ELEMENTS';
const UNDO = 'UNDO';
const REDO = 'REDO';
const SET_OBJECT_PROPERTIES = 'SET_OBJECT_PROPERTIES';
const SET_PARAMETER_VALUE = 'SET_PARAMETER_VALUE';
const SET_PARAMETERS_VALUES = 'SET_PARAMETERS_VALUES';
const SET_BAND_SIZE = 'SET_BAND_SIZE';
const DELETE_ELEMENT = 'DELETE_ELEMENT'; 
const DELETE_VISUAL_ELEMENT = 'DELETE_VISUAL_ELEMENT'; 
const SET_CURRENT_EDITOR = 'SET_CURRENT_EDITOR';
const CREATE_EDITOR = 'CREATE_EDITOR';
const SET_EDITOR_PROPERTIES = 'SET_EDITOR_PROPERTIES';
const INSTANTIATE_EDITOR = 'INSTANTIATE_EDITOR';
const CLOSE_EDITOR = 'CLOSE_EDITOR';
const RESET_EDITOR = 'RESET_EDITOR';
const STORE_IN_CACHE = 'STORE_IN_CACHE';
const FOCUS_TEXT_CONTROL = 'FOCUS_TEXT_CONTROL';
const SET_WIDGET_STATUS = 'SET_WIDGET_STATUS';


// Sagas
const CREATE_NEW_DOCUMENT_ACTION = 'CREATE_NEW_DOCUMENT_ACTION';
const CREATE_NEW_BAND_ACTION = 'CREATE_NEW_BAND_ACTION';
const CREATE_NEW_PART_ACTION = 'CREATE_NEW_PART_ACTION';
const OPEN_DOCUMENT_ACTION = 'OPEN_DOCUMENT_ACTION';
const LOAD_COMBOS_ACTION = 'LOAD_COMBOS_ACTION';
const CHECK_RESOURCE_ACTION = 'CHECK_RESOURCE_ACTION';

//cache refreshing action
const DELETE_PROPERTY_REFRESH_ACTION = 'DELETE_PROPERTY_REFRESH_ACTION';
const SET_PROPERTIES_REFRESH_ACTION = 'SET_PROPERTIES_REFRESH_ACTION';


export const createNewDocument = makeActionCreator(CREATE_NEW_DOCUMENT_ACTION, 'template');
export const resetEditor = makeActionCreator(RESET_EDITOR, 'keepCache', 'keepHistory', 'model');
export const createNewBand = makeActionCreator(CREATE_NEW_BAND_ACTION, 'band', 'index');
export const createNewPart = makeActionCreator(CREATE_NEW_PART_ACTION, 'path', 'part');
export const openDocument = makeActionCreator(OPEN_DOCUMENT, 'model', 'keepCache');
export const setDescriptor = makeActionCreator(SET_DESCRIPTOR, 'descriptor', 'path');
export const openDocumentAction = makeActionCreator(OPEN_DOCUMENT_ACTION, 'model', 'descriptor', 'path', 'parameters', 'options');
export const loadCombosAction = makeActionCreator(LOAD_COMBOS_ACTION, 'path', 'force');
export const deletePropertyRefreshAction = makeActionCreator(DELETE_PROPERTY_REFRESH_ACTION, 'path', 'reportPath');
export const setPropertiesRefreshAction = makeActionCreator(SET_PROPERTIES_REFRESH_ACTION, 'path', 'properties', 'reportPath');

export const checkResourceAction = makeActionCreator(CHECK_RESOURCE_ACTION, 'reportPath', 'resourcePath', 'force');
export const prepareNewDocument = makeActionCreator(CREATE_NEW_DOCUMENT, 'model');
export const addElement = makeActionCreator(CREATE_ELEMENT, 'element', 'path', 'select');
export const addElements = makeActionCreator(CREATE_ELEMENTS, 'elements');
export const addProperty = makeActionCreator(CREATE_PROPERTY, 'path', 'element');
export const addProperties = makeActionCreator(CREATE_PROPERTIES, 'path', 'elements', 'replaceExisting');
export const deleteElement = makeActionCreator(DELETE_ELEMENT, 'path');
export const deleteVisualElements = makeActionCreator(DELETE_VISUAL_ELEMENT, 'paths');
export const addBand = makeActionCreator(CREATE_BAND, 'band', 'index');
export const setCurrentEditor = makeActionCreator(SET_CURRENT_EDITOR, 'editorIndex');
//create editor is catch by the saga, instantiate editor is used by the create editor saga to allocate the new editor, then it continues with the initialization
export const createEditor = makeActionCreator(CREATE_EDITOR, 'editorType', 'editorLabel', 'isSelectable', 'editedResourceId', 'setActive', 'params');
export const setEditorProperties = makeActionCreator(SET_EDITOR_PROPERTIES, 'index', 'editorLabel', 'isSelectable', 'editedResourceId', 'params');
export const instantiateEditor = makeActionCreator(INSTANTIATE_EDITOR, 'editorType', 'editorLabel', 'isSelectable', 'editedResourceId', 'setActive', 'params');
export const closeEditor = makeActionCreator(CLOSE_EDITOR, 'editorIndex');
export const storeInCache = makeActionCreator(STORE_IN_CACHE, 'cacheType', 'data');
export const focusTextControl = makeActionCreator(FOCUS_TEXT_CONTROL, 'isFocused');
export const setWidgetStatus = makeActionCreator(SET_WIDGET_STATUS, 'path', 'value');

/**
 * Select element id.
 * If add is true, the original selection is preserved, and the element is added to the
 * selection.
 */
export const selectElement = makeActionCreator(SELECT_ELEMENT, 'id', 'info','add');

export const selectDataset = makeActionCreator(SELECT_DATASET, 'id');

export const modelUndo = makeActionCreator(UNDO);
export const modelRedo = makeActionCreator(REDO);

// TODO: Is it a good idea to use a renduntant action like deselect? Maybe a setSelecter(true/false) would be more suitable?
export const expandOutlineElements = makeActionCreator(EXPAND_OUTLINE_ELEMENT, 'expandedElements');
export const deselectElement = makeActionCreator(DESELECT_ELEMENT, 'id'); 
export const selectElements = makeActionCreator(SELECT_ELEMENTS, 'ids','add');
export const clearSelection = makeActionCreator(CLEAR_SELECTION);
export const updateElementsSizeAndPosition = makeActionCreator(UPDATE_ELEMENT_SIZE_AND_POSITION, 'changes');
export const setHighlightedElements = makeActionCreator(SET_HIGHLIGHTED_ELEMENTS, 'elementIds');
export const setObjectProperties = makeActionCreator(SET_OBJECT_PROPERTIES, 'path', 'properties');
export const setParameterValue = makeActionCreator(SET_PARAMETER_VALUE, 'parameterName', 'parameterValue');
export const setParametersValues = makeActionCreator(SET_PARAMETERS_VALUES, 'parameters');

export const setBandSize = makeActionCreator(SET_BAND_SIZE, 'path', 'properties');

/**
 * Attention, this operation does not perform any position update of the elements.
 */
export const updateElementsContainer = makeActionCreator(UPDATE_ELEMENTS_CONTAINER, 'elementIds', 'path');


//  If we would like to have strongly typed actions, here is how to do it:
//  export class SetMouseDown {
//     public readonly type = DESIGNER_MOUSE_DOWN_ACTION;
//     constructor( public point: IPoint, public options: any) { }
// }
// export type SetMouseDownAction = SetMouseDown;  



export const Actions = {
    DESIGNER_SET_DIRTY,
    DESIGNER_VIEWPORT_CHANGED,
    DESIGNER_CLIENTAREA_CHANGED,
    DESIGNER_SET_ZOOM,
    DESIGNER_INCREASE_ZOOM,
    DESIGNER_DECREASE_ZOOM,
    DESIGNER_SCROLL_X,
    DESIGNER_SCROLL_Y,
    DESIGNER_SET_LEFT_COLUMN_VISIBLE,
    DESIGNER_SET_DRAG_OP,
    DESIGNER_CLEAR_DRAG_OP,
    DESIGNER_UPDATE_DRAG_OP,
    DESIGNER_ADD_INTERACTION_FIGURE,  // TODO: add/update a single figure can be a special case of the multiple figures version...
    DESIGNER_ADD_INTERACTION_FIGURES,
    DESIGNER_UPDATE_INTERACTION_FIGURE,
    DESIGNER_UPDATE_INTERACTION_FIGURES,
    DESIGNER_REMOVE_INTERACTION_FIGURE,
    DESIGNER_REMOVE_INTERACTION_FIGURES,
    DESIGNER_HIGHLIGHT_CONTAINER,
    DESIGNER_MOUSE_DOWN_ACTION,
    DESIGNER_MOUSE_MOVE_ACTION,
    DESIGNER_MOUSE_UP_ACTION,
    DESIGNER_UNDO_ACTION,
    DESIGNER_REDO_ACTION,
    DESIGNER_PALETTE_ITEM_DRAG_ACTION,
    DESIGNER_PALETTE_ITEM_DROP_ACTION,
    DESIGNER_PALETTE_ITEM_DRAG_START_ACTION,
    DESIGNER_PALETTE_ITEM_DRAG_END_ACTION,
    DESIGNER_BAND_RESIZE_INIT_ACTION,
    DESIGNER_DEBUG_CREATE_100_ELEMENTS_ACTION,
    CREATE_NEW_DOCUMENT,
    OPEN_DOCUMENT,
    SET_DESCRIPTOR,
    CREATE_ELEMENT,
    CREATE_ELEMENTS,
    CREATE_PROPERTY,
    CREATE_PROPERTIES,
    CREATE_BAND,
    EXPAND_OUTLINE_ELEMENT,
    SELECT_ELEMENT,
    SELECT_DATASET,
    DESELECT_ELEMENT,
    SELECT_ELEMENTS,
    CLEAR_SELECTION,
    UPDATE_ELEMENT_SIZE_AND_POSITION,
    SET_HIGHLIGHTED_ELEMENTS,
    UNDO,
    REDO,
    SET_OBJECT_PROPERTIES,
    SET_PARAMETER_VALUE,
    SET_PARAMETERS_VALUES,
    SET_BAND_SIZE,
    DELETE_ELEMENT,
    DELETE_VISUAL_ELEMENT,
    UPDATE_ELEMENTS_CONTAINER,
    CREATE_NEW_DOCUMENT_ACTION,
    CREATE_NEW_BAND_ACTION,
    CREATE_NEW_PART_ACTION,
    OPEN_DOCUMENT_ACTION,
    LOAD_COMBOS_ACTION,
    SET_PROPERTIES_REFRESH_ACTION,
    DELETE_PROPERTY_REFRESH_ACTION,
    CHECK_RESOURCE_ACTION,
    SET_CURRENT_EDITOR,
    CREATE_EDITOR,
    SET_EDITOR_PROPERTIES,
    INSTANTIATE_EDITOR,
    CLOSE_EDITOR,
    RESET_EDITOR,
    STORE_IN_CACHE,
    FOCUS_TEXT_CONTROL,
    SET_WIDGET_STATUS
  }