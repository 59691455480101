/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { PScrollableCategories } from "../../ui/composite/UPScrollableCategories";
import { PDiv } from "../../ui/composite/UPDiv";
import { APDescriptor } from "../../ui/APDescriptor";
import { MGraphicElement } from "../common/MGraphicElement";
import { ElementTypes } from "../../../../../sagas/report/document/elementTypes";
import { ICONS } from "@jss/js-common";
import { MGenericElementCustom } from "./MGenericElementCustom";

export const MAdhocComponent: APDescriptor = {
    type: ElementTypes.AD_HOC_COMPONENT,
    label: 'AdHoc',
    id: 'adhocProeprtiesDescriptor',
    icon: ICONS['ADHOC_ICON'],
    default: { width: 200, height: 30 },
    layouts: [new PScrollableCategories({
        layouts: [
            new PDiv({ label: 'Appearance', layouts: [...MGraphicElement.layouts, MGenericElementCustom] }),
        ]
    })]
};
