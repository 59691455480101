
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

/* eslint @typescript-eslint/no-var-requires: "off" */
import { Footer, RouterProps, withRouter } from '@jss/js-common';
import { Conf, error, MessageInfo, msg, IConfigStoreListener } from '@jss/js-rest-api';
import LinearProgress from '@material-ui/core/LinearProgress';
import axios, { AxiosError } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import '../../assets/uxpl/css/JRSLoginPage.css';
import i18n from '../../i18n';
import { AppHeader } from '../common/AppHeader';
import { Button } from '@jss/js-common';

interface IJackrabbitLoginPageState {
    username: string,
    password: string,
    show?: string | MessageInfo
}

class _JackrabbitLoginPage extends React.Component<RouterProps, IJackrabbitLoginPageState>  implements IConfigStoreListener{

    public state: IJackrabbitLoginPageState = {
        username: '',
        password: ''
    }
    refresh(): void {
        this.forceUpdate();
    }
    componentDidMount() {
        Conf.addListener(this);
    }
    componentWillUnmount() {
        Conf.removeListener(this);
    }
    private onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ username: event.currentTarget.value.trim() });
    }

    private onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ password: event.currentTarget.value.trim() });
    }

    private canLogin = () => {
        return this.state.username.trim().length > 0 && this.state.password.trim().length >= 0;
    }

    private onLogin = () => {
        if (this.canLogin()) {
            const params = new URLSearchParams();
            params.append('username', this.state.username);
            params.append('password', this.state.password);

            this.setState({ show: 'loading' });
            axios.post('/repo' + Conf.get('jrws.url.rest.login.jackrabbit'), params).then((response) => {
                if (response.data === 'google') {
                    this.callLoginServlet();
                } else {
                    this.props.router?.navigate(`/loggedin?provider=jackrabbit&user=${response.data}`);
                }
            }).catch((e: AxiosError) => {
                this.setState({ show: error(e) });
            });
        }
    }

    protected goToHome = () => {
        if (this.props.router) {
            this.props.router.navigate('/');
        }
    }

    public render = () => {
        let textDisableClassName = '';
        let buttonDisableClassName = '';
        if (!this.canLogin()) {
            textDisableClassName = 'textDisabled';
            buttonDisableClassName = 'buttonDisabled';
        }
        Conf.get(`jrws.url.rest.login.jackrabbit`) as string
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <AppHeader goToHome={this.goToHome} />
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                    <div className="loginPage">
                        <div className="loginPanel">
                            <h1 style={{ textAlign: 'center' }}>Jackrabbit</h1>
                            {this.renderGoogle()}
                            <div className="inputContainer"
                                onKeyDown={
                                    (e) => {
                                        if (e.key === "Enter" &&
                                            this.state.username.length &&
                                            this.state.password.length) {
                                            this.onLogin();
                                        }
                                    }
                                }>
                                {this.showStatus()}
                                <div className="usernameContainer">
                                    <span className="labelLogin">{i18n.t('home.login.username')}</span>
                                    <input onChange={this.onUsernameChange} value={this.state.username} className="inputLogin" id="username" name="j_username" type="text" autoCapitalize="off" autoFocus={true} aria-labelledby="usernamelabel" autoComplete="off" />
                                </div>
                                <div className="passwordContainer">
                                    <span className="labelPassword">{i18n.t('home.login.password')}</span>
                                    <input onChange={this.onPasswordChange} value={this.state.password} className="inputPassword" id="password" name="j_password" type="password" autoCapitalize="off" aria-labelledby="passwordLabel" autoComplete="off" />
                                </div>
                            </div>
                            <div className="buttonContainer">
                                <button type="button" id="submitButton" className={`loginButton ${buttonDisableClassName}`} onClick={this.onLogin}>
                                    <span className={`loginButtonLabel ${textDisableClassName}`}>{i18n.t('home.login.button')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer config={Conf.get('jrws.jrio.serverInfo')} />
            </div>
        );
    }

    private renderGoogle = () => {
        if (Conf.get(`jrws.url.rest.login.jackrabbit.google`)) {
            return <>
                <div style={{ textAlign: 'center' }}>
                    <Button key='googlelogin' size="large" color="primary" variant="contained" title='Login with Google' onClick={() => { this.callLoginServlet() }} style={{ marginTop: '10px', marginLeft: '0px', textTransform: 'capitalize' }}>
                        {/* <button type="button" id="googleButton" className={`loginButton1`} onClick={this.callLoginServlet}> */}
                        <span className={`loginButtonLabel`}>Login with Google</span>
                        {/* </button> */}
                    </Button>
                </div>
                <div style={{ textAlign: 'center' }}>Or</div>
            </>;
        }
    }

    private callLoginServlet = () => {
        window.location.href = `repo${Conf.get(`jrws.url.rest.login.jackrabbit.google`) as string}`;
    }

    private showStatus = () => {
        if (this.state.show === 'loading') {
            return <LinearProgress />;
        }
        const m = msg(this.state.show);
        if (m) {
            return <div style={{ color: 'red', maxHeight: 200, overflow: 'auto' }}>{m}</div>;
        }
    }

}

const mapStateToProps = (state: any) => {
    return {
        storedData: state.persistentReducer.storedData,
    };
}

//connected to the store to update the language
export const JackrabbitLoginPage = withRouter(connect(mapStateToProps)(_JackrabbitLoginPage));
