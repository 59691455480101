/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IRepositoryItemDescriptor, RepositoryApi, Mimes } from '@jss/js-rest-api';
import { AxiosResponse } from 'axios';
import { RunContext } from '../RunContainer';


interface IResourceViewerProps {
    onExit: () => void;
}

interface IResourceViewState {
    openDescriptor?: IRepositoryItemDescriptor,
    path?: IRepositoryItemDescriptor[],
}

export interface ResourceRouteState {
    descriptor: IRepositoryItemDescriptor;
    path?: IRepositoryItemDescriptor[]
}

export const ResourceViewer: React.FunctionComponent<IResourceViewerProps> = ({ onExit }) => {
    const [state, setState] = useState<IResourceViewState>({});
    const { editMode } = useParams();
    const [ searchParams ] = useSearchParams();
    const context = useContext(RunContext);
    const location = useLocation();
    const navigate = useNavigate();

    const editResource = useCallback(
        () => {
            const resourcePath = RepositoryApi.inst().getResourcePath(searchParams.get("path"));
            context.runNoCancel(RepositoryApi.inst().load)(resourcePath, true, true)
                .then((response: AxiosResponse<IRepositoryItemDescriptor>) => {
                    setState({ openDescriptor: response.data, path: [] });
                }).catch(error =>  console.log(error));
        }, [context, searchParams]);

    const onSave = useCallback(
        (descriptor: IRepositoryItemDescriptor) => {
            setState({ openDescriptor: descriptor });
        }, []);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onFirstSave = () => {};
    useEffect(() => {
        const reportData = location.state as ResourceRouteState; // FIXME should we do something with reportData.path ?

        // clear location state after retrieving it
        navigate(location.pathname + location.search + location.hash, { replace: true });

        if ("edit" === editMode) {
            // if navigation from repository
            if (reportData) {
                setState({ openDescriptor: reportData.descriptor , path: reportData.path});
            } else {
                editResource();
            }
        } else if ("new" === editMode) {
            // if navigation from repository
            if (reportData) {
                setState({ openDescriptor: reportData.descriptor, path: reportData.path });
            } else {
                onExit();
            }
        }
    }, []); // empty array
    return state.openDescriptor ? Mimes.show(state.openDescriptor, state.path, { onExit, onSave, onFirstSave }) : <></>
}
