/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { AbstractTextualEditor, IAbstractTextualEditor } from './AbstractTextualEditor';
import AceEditor, { IAceEditorProps } from 'react-ace';
import { RunContext } from '@jss/js-repository/src/RunContainer';


export default class InternalPropertiesEditor extends AbstractTextualEditor {

    constructor(props: IAbstractTextualEditor) {
        super(props);
    }

    public getAcceptedFileTypes() {
        return 'text/plain,.txt,.properties';
    }

    public getDefaultExtension() {
        return 'properties';
    }

    public getTextEditor(editorProps: IAceEditorProps){
        const props = {
            ...editorProps,
            mode: 'properties',
            wrapEnabled: true,
            enableBasicAutocompletion: true,
            tabSize: 2,
            theme: 'textmate',
        };
        return <AceEditor {...props} ref={this.editor}/>;
    }
}

InternalPropertiesEditor.contextType = RunContext;
