export const fr = {
	text: {
		editor: {
			text: {
				label: "Test"
			},
			test: {
				error: "Erreur",
				cancel: "Annuler",
				success: "Source de données validée avec succès",
				fail: "Le test de la source de données a échoué, mais aucune raison n'a été fournie",
				tryAgain: "Réessayer",
				close: "Fermer"
			},
			testTooltip: "Cela effectuera un test sur l'adaptateur de données",
			help: {
				tooltip: "Aide"
			},
			format: {
				tooltip: "Format"
			},
			savedialogtitle: "Enregistrer Sous...",
			unrecognizedResource: "TYPE DE RESSOURCE NON RECONNU"
		}
	}
}