/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { ICONS } from "@jss/js-common";
import { APDescriptor } from "../../../ui/APDescriptor";
import { MBarbecue } from "./MBarbecue";

export const MCode39Extended: APDescriptor = {
    ...MBarbecue, id: 'Code39 (Extended)', label: 'Code39 (Extended)', icon: ICONS.BARCODE_CODE39_ICON,
    default: { ...MBarbecue.default, barbecue_type: 'Code39 (Extended)' }
};
