/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from 'react';
import { TextField, TextFieldProps } from '../textinput/TextField';
import { Autocomplete } from './Autocomplete';

import '../../assets/uxpl/css/WritableCombo.css';
import { Box, Popper } from '@material-ui/core';

interface IWritableComboItem {
    key: string,
    value: string,
    icon?: React.ReactElement
}

interface IWritableCombo {
    items: IWritableComboItem[];
    validator?: (value: string | undefined) => string | undefined;
    onComboChange: (selectedKey: string | undefined, value: string) => void;
}

interface IWritableComboState {
    isSuggestionOpened: boolean,
}

export class WritableCombo extends React.Component<TextFieldProps & IWritableCombo, IWritableComboState> {

    /*
    Little hack here, the currentFilter normally should be part of the state since it affect the rendering,
    but doing like this will trigger the rerender of the children, among them the input control. The input
    control when it is controlled in case like this lose the information on the cursor, placing it at the
    end of the control. To avoid this the filter is kept as variable so its change will not trigger a refresh.
    This in this particular case is not a problem since the filter is always updated before a props update or 
    an internal state update, so in any case the component will be refreshed but only once
    */
    private currentFilter: string | undefined = undefined;

    private textFieldRef: React.RefObject<HTMLDivElement> = React.createRef();

    state = {
        isSuggestionOpened: false,
    }

    private onTextChange = (newValue: string) => {
        this.currentFilter = newValue;
        this.props.onComboChange(undefined, newValue);
    }

    getOptionLabel = (option: any) => {
        if (!option) return '';
        if (typeof option === 'object') {
            return option.value ? option.value : (option.key ? option.key : '')
        }
        return option;
    }

    fitToWidthPopper = (props) => {
        return <Popper {...props} style={{ width: 'fit-content', fontSize: '13px' }} placement='bottom-start' />;
    };

    public render() {
        let validationError: string | undefined;
        const { onComboChange, validator, InputProps, ...restProps } = this.props;
        if (validator) {
            validationError = validator(this.props.value as any);
        }
        const textValue = this.props.items.find((currentItem) => {
            return currentItem.key === this.props.value;
        });
        let value: string | undefined;
        if (textValue) {
            value = textValue.value;
        } else if (!value && this.props.value) {
            value = this.props.value as string;
        }
        let items = this.props.items;
        if (this.currentFilter) {
            const currentValue = (this.currentFilter as string).trim().toLowerCase();
            items = items.filter((item) => {
                return item.value.toLowerCase().includes(currentValue);
            })
        }
        return <Autocomplete
            freeSolo
            id={this.props.id}
            disableClearable
            options={items}
            open={this.state.isSuggestionOpened && items.length > 0}
            PopperComponent={this.fitToWidthPopper}
            autoHighlight
            className="jrws-writable-combo"
            classes={{ input: 'jrws-writable-combo-small-font' }}
            onChange={(event, newValue: { key: string, value: string }) => {
                this.currentFilter = undefined;
                this.setState({ isSuggestionOpened: false }, () => {
                    if (this.textFieldRef.current !== null) {
                        this.textFieldRef.current?.blur();
                    }
                    onComboChange(newValue.key, newValue.value);
                });
            }}
            renderOption={(option: IWritableComboItem) => {
                if (option.icon) {
                    return <Box style={{display: 'flex', flexDirection: 'row'}}>
                        {option.icon}
                        {option.value}
                    </Box>
                } else {
                    return option.value;
                }
            }}
            value={textValue ? textValue : { key: value, value: value }}
            getOptionLabel={this.getOptionLabel}
            renderInput={(params) => {
                return <TextField
                    {...params}
                    {...restProps}
                    inputRef={this.textFieldRef}
                    error={validationError}
                    onTextChange={this.onTextChange}
                    id="outlined01"
                    label={this.props.label}
                    size={'large'}
                    // error="You can have error text here."
                    helperText={this.props.helperText}
                    InputLabelProps={{
                        classes: { root: "jr-mInput-label mui" },
                        disableAnimation: true,
                        shrink: true
                    }}
                    InputProps={{
                        readOnly: this.props.disabled,
                        ...params.InputProps,
                    }}
                    inputProps={{
                        ...params.inputProps,
                        title: InputProps ? InputProps.title : undefined,
                        onKeyDown: (e) => {
                            //disable the press of enter on the autocomplete
                            if (e.key === 'Enter') {
                                e.stopPropagation();
                                this.currentFilter = undefined;
                                this.setState({ isSuggestionOpened: false });
                            } else if (e.key === 'Escape') {
                                this.currentFilter = undefined;
                                this.setState({ isSuggestionOpened: false });
                            } else {
                                this.setState({ isSuggestionOpened: true });
                            }
                        },
                        onFocus: (e) => {
                            this.currentFilter = undefined;
                            this.setState({ isSuggestionOpened: true });
                            if ((params.inputProps as any).onFocus) {
                                (params.inputProps as any).onFocus(e);
                            }
                        },
                        onBlur: (e) => {
                            this.currentFilter = undefined;
                            this.setState({ isSuggestionOpened: false });
                            if ((params.inputProps as any).onBlur) {
                                (params.inputProps as any).onBlur(e);
                            }
                        }
                    }}
                    value={value}
                />
            }

            }
            disabled={this.props.disabled}
        />
    }
}

