/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { List, Map } from 'immutable';
import { ElementTypes } from '../../../../sagas/report/document/elementTypes';
import { createSectionHyperLinkForElement } from './JrxmlChartUtils';
import { addAttributeToMap, addBooleanAttributeToMap, addChildToParent, addChildValueToMapWithName, createChartCrosstabDataset, createReportElement, generatePath, getChildNodeByName, getNamespace, IObjectCounter, readCDataText } from './JrxmlHelpers';


const BULB = "Bulb";
const HORIZONTAL_LED = "HorizontalLed";
const VERTICAL_LED = "VerticalLed";
const HORIZONTAL_BULLET = "HorizontalBullet";
const VERTICAL_BULLET = 'VerticalBullet';
const THERMOMETER = 'Thermometer';
const CYLINDER = 'Cylinder'
const ANGULAR_GAUGE = 'AngularGauge';
const LINEAR_GAUGE = 'LinearGauge';
const FUNNEL = 'Funnel';
const PYRAMID = 'Pyramid';
const SPARK_LINE = 'SparkLine';
const SPARK_COLUMN = 'SparkColumn';
const SPARK_WIN_LOSS = 'SparkWinLoss';
const GANTT = 'Gantt';

export const FUSIONWIDGETS_NAMESPACE = "FUSIONWIDGETS_XMLNS_NAMESPACE";

export const FusionWidgetTypes = {
    BULB,
    HORIZONTAL_LED,
    VERTICAL_LED,
    HORIZONTAL_BULLET,
    VERTICAL_BULLET,
    THERMOMETER,
    CYLINDER,
    ANGULAR_GAUGE,
    LINEAR_GAUGE,
    FUNNEL,
    PYRAMID,
    SPARK_LINE,
    SPARK_COLUMN,
    SPARK_WIN_LOSS,
    GANTT
}
/**
 * Checks if the specified component is a real HTML5 chart.
 *
 * @param componentElement the component element to check
 */
export const isFusionWidget = (componentElement: Element): boolean => {
    const childList = componentElement.childNodes;
    let found = false;
    for (let i = 0; i < childList.length && !found; i++) {
        const child = childList[i];
        const nodeName = child.nodeName;
        if (child.nodeType === Node.ELEMENT_NODE && nodeName !== "reportElement") {
            const baseNamespace = getNamespace(child as Element);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : baseNamespace;
            switch (nodeName) {
                case (namespace + "bulb"):
                case (namespace + "horizontalLED"):
                case (namespace + "verticalLED"):
                case (namespace + "thermometer"):
                case (namespace + "cylinder"):
                case (namespace + "horizontalBullet"):
                case (namespace + "verticalBullet"):
                case (namespace + "angularGauge"):
                case (namespace + "linearGauge"):
                case (namespace + "pyramid"):
                case (namespace + "funnel"):
                case (namespace + "sparkLine"):
                case (namespace + "sparkColumn"):
                case (namespace + "ganttChart"):
                case (namespace + "sparkWinLoss"):
                    found = true;
            }
        }
    }
    return found;
}

const createFusionPropertyExpression = (itemElement: Element, namespace: string): {name: string, value: Map<string, any>} => {
    let result = Map<string, any>();
    const name = itemElement.getAttribute("name");
    const attValueNode = getChildNodeByName(itemElement, namespace + "propertyExpression");
    if (attValueNode !== null) {
        const attValue = readCDataText(attValueNode);
        result = result.set('propertyExpression', attValue);
    }
    return {name, value: result};
}

const createColorRange = (itemElement: Element, namespace: string): Map<string, any> | undefined => {
    let result: Map<string, any> = Map<string, any>();
    result = addAttributeToMap(itemElement, 'color', result);
    result = addChildValueToMapWithName(itemElement, namespace + "minValueExpression", "minValueExpression", result);
    result = addChildValueToMapWithName(itemElement, namespace + "maxValueExpression", "maxValueExpression", result);
    result = addChildValueToMapWithName(itemElement, namespace + "labelExpression", "labelExpression", result);
    return result;
}

const createTrendLine = (itemElement: Element, namespace: string): Map<string, any> => {
    let result: Map<string, any> = Map<string, any>();
    result = addBooleanAttributeToMap(itemElement, 'trendZone', result);
    result = addAttributeToMap(itemElement, 'color', result);
    result = addChildValueToMapWithName(itemElement, namespace + "startValueExpression", "startValueExpression", result);
    result = addChildValueToMapWithName(itemElement, namespace + "endValueExpression", "endValueExpression", result);
    result = addChildValueToMapWithName(itemElement, namespace + "labelExpression", "labelExpression", result);
    return result;
}

const createAbstractWidget = (widgetElement: Element, namespace: string, actualValues: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let result: Map<string, any> = actualValues;
    let widgetProperties = Map<string, Map<string, any>>();
    widgetElement.childNodes.forEach(widgetChild => {
        if (widgetChild.nodeType === Node.ELEMENT_NODE) {
            if (widgetChild.nodeName === namespace + "valueExpression") {
                result = result.set('valueExpression', readCDataText(widgetChild));
            } else if (widgetChild.nodeName === namespace + "widgetProperty") {
                const {name, value} = createFusionPropertyExpression(widgetChild as Element, namespace);
                widgetProperties = widgetProperties.set(name, value);
            } else if (widgetChild.nodeName === "dataset") {
                result = result.set("dataset", createChartCrosstabDataset(widgetChild as Element, objectCounter));
            } else if (widgetChild.nodeName === namespace + "hyperlink") {
                let hyperLinkMap = Map<string, any>();
                hyperLinkMap = createSectionHyperLinkForElement(widgetChild as Element, hyperLinkMap);
                result = result.set('hyperlink', hyperLinkMap);
            }
        }
    });
    result = addAttributeToMap(widgetElement, "evaluationTime", result);
    result = addAttributeToMap(widgetElement, "evaluationGroup", result);
    result = result.set('widgetProperties', widgetProperties);
    return result;
}

const createSingleValueWidget = (widgetElement: Element, namespace: string, actualValues: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let result = createAbstractWidget(widgetElement, namespace, actualValues, objectCounter);
    widgetElement.childNodes.forEach(widgetChild => {
        if (widgetChild.nodeType === Node.ELEMENT_NODE) {
            if (widgetChild.nodeName === namespace + "valueExpression") {
                result = result.set('valueExpression', readCDataText(widgetChild));
            } else if (widgetChild.nodeName === "dataset") {
                result = result.set("dataset", createChartCrosstabDataset(widgetChild as Element, objectCounter));
            }
        }
    });
    return result;
}

const createSingleValueRangeWidget = (widgetElement: Element, namespace: string, actualValues: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let result = createSingleValueWidget(widgetElement, namespace, actualValues, objectCounter);
    let colorRanges = List<Map<string, any>>();
    widgetElement.childNodes.forEach(widgetChild => {
        if (widgetChild.nodeType === Node.ELEMENT_NODE) {
            if (widgetChild.nodeName === namespace + "colorRange") {
                colorRanges = colorRanges.push(createColorRange(widgetChild as Element, namespace));
            }
        }
    });
    result = result.set('colorRanges', colorRanges);
    return result;
}

const createSingleValueTargetWidget = (widgetElement: Element, namespace: string, actualValues: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let result = createSingleValueWidget(widgetElement, namespace, actualValues, objectCounter);
    let colorRanges = List<Map<string, any>>();
    widgetElement.childNodes.forEach(widgetChild => {
        if (widgetChild.nodeType === Node.ELEMENT_NODE) {
            if (widgetChild.nodeName === namespace + "colorRange") {
                colorRanges = colorRanges.push(createColorRange(widgetChild as Element, namespace));
            } else if (widgetChild.nodeName === namespace + "targetExpression") {
                result = result.set('targetExpression', readCDataText(widgetChild));
            }
        }
    });
    result = result.set('colorRanges', colorRanges);
    return result;
}

const createDial = (dialElement: Element, namespace: string): Map<string, any> => {
    let result = Map<string, any>();
    let dialProperties = Map<string, Map<string, any>>();
    dialElement.childNodes.forEach(dialChild => {
        if (dialChild.nodeType === Node.ELEMENT_NODE) {
            if (dialChild.nodeName === namespace + "dialProperty") {
                const {name, value} = createFusionPropertyExpression(dialChild as Element, namespace);
                dialProperties = dialProperties.set(name, value);
            } else if (dialChild.nodeName === namespace + "hyperlink") {
                let hyperLinkMap = Map<string, any>();
                hyperLinkMap = createSectionHyperLinkForElement(dialChild as Element, hyperLinkMap);
                result = result.set('hyperlink', hyperLinkMap);
            } else if (dialChild.nodeName === namespace + "valueExpression") {
                result = result.set('valueExpression', readCDataText(dialChild));
            }
        }
    });
    result = result.set('dialProperties', dialProperties);
    return result;
}

const createPointer = (pointerElement: Element, namespace: string): Map<string, any> => {
    let result = Map<string, any>();
    let properties = Map<string, Map<string, any>>();
    pointerElement.childNodes.forEach(pointerChild => {
        if (pointerChild.nodeType === Node.ELEMENT_NODE) {
            if (pointerChild.nodeName === namespace + "property") {
                const {name, value} = createFusionPropertyExpression(pointerChild as Element, namespace);
                properties = properties.set(name, value);
            } else if (pointerChild.nodeName === namespace + "hyperlink") {
                let hyperLinkMap = Map<string, any>();
                hyperLinkMap = createSectionHyperLinkForElement(pointerChild as Element, hyperLinkMap);
                result = result.set('hyperlink', hyperLinkMap);
            } else if (pointerChild.nodeName === namespace + "valueExpression") {
                result = result.set('valueExpression', readCDataText(pointerChild));
            }
        }
    });
    result = result.set('properties', properties);
    return result;
}

const createTrendPoint = (trendPointElement: Element, namespace: string): Map<string, any> => {
    let result = Map<string, any>();
    let properties = Map<string, Map<string, any>>();
    trendPointElement.childNodes.forEach(trendPointChild => {
        if (trendPointChild.nodeType === Node.ELEMENT_NODE) {
            if (trendPointChild.nodeName === namespace + "property") {
                const {name, value} = createFusionPropertyExpression(trendPointChild as Element, namespace);
                properties = properties.set(name, value);
            } else if (trendPointChild.nodeName === namespace + "startValueExpression") {
                result = result.set('startValueExpression', readCDataText(trendPointChild));
            } else if (trendPointChild.nodeName === namespace + "endValueExpression") {
                result = result.set('endValueExpression', readCDataText(trendPointChild));
            } else if (trendPointChild.nodeName === namespace + "labelExpression") {
                result = result.set('labelExpression', readCDataText(trendPointChild));
            }
        }
    });
    result = result.set('properties', properties);
    result = addAttributeToMap(trendPointElement, "color", result);
    return result;
}

const createAngularGaugeWidget = (widgetElement: Element, namespace: string, actualValues: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let result = createAbstractWidget(widgetElement, namespace, actualValues, objectCounter);
    let colorRanges = List<Map<string, any>>();
    let dials = List<Map<string, any>>();
    let trendPoints = List<Map<string, any>>();
    widgetElement.childNodes.forEach(widgetChild => {
        if (widgetChild.nodeType === Node.ELEMENT_NODE) {
            if (widgetChild.nodeName === namespace + "colorRange") {
                colorRanges = colorRanges.push(createColorRange(widgetChild as Element, namespace));
            } else if (widgetChild.nodeName === "dataset") {
                result = result.set("dataset", createChartCrosstabDataset(widgetChild as Element, objectCounter));
            } else if (widgetChild.nodeName === namespace + "dial") {
                dials = dials.push(createDial(widgetChild as Element, namespace));
            } else if (widgetChild.nodeName === namespace + "trendPoint") {
                trendPoints = trendPoints.push(createTrendPoint(widgetChild as Element, namespace));
            }
        }
    });
    result = result.set('colorRanges', colorRanges);
    result = result.set('dials', dials);
    result = result.set('trendPoints', trendPoints);
    return result;
}


const createLinearGaugeWidget = (widgetElement: Element, namespace: string, actualValues: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let result = createAbstractWidget(widgetElement, namespace, actualValues, objectCounter);
    let colorRanges = List<Map<string, any>>();
    let pointers = List<Map<string, any>>();
    let trendPoints = List<Map<string, any>>();
    widgetElement.childNodes.forEach(widgetChild => {
        if (widgetChild.nodeType === Node.ELEMENT_NODE) {
            if (widgetChild.nodeName === namespace + "colorRange") {
                colorRanges = colorRanges.push(createColorRange(widgetChild as Element, namespace));
            } else if (widgetChild.nodeName === "dataset") {
                result = result.set("dataset", createChartCrosstabDataset(widgetChild as Element, objectCounter));
            } else if (widgetChild.nodeName === namespace + "pointer") {
                pointers = pointers.push(createPointer(widgetChild as Element, namespace));
            } else if (widgetChild.nodeName === namespace + "trendPoint") {
                trendPoints = trendPoints.push(createTrendPoint(widgetChild as Element, namespace));
            }
        }
    });
    result = result.set('colorRanges', colorRanges);
    result = result.set('pointers', pointers);
    result = result.set('trendPoints', trendPoints);
    return result;
}

const createValueSet = (valueSetElement: Element, namespace: string): Map<string, any> => {
    let result = Map<string, any>();
    let properties = Map<string, Map<string, any>>();
    valueSetElement.childNodes.forEach(valueDatasetChild => {
        if (valueDatasetChild.nodeType === Node.ELEMENT_NODE) {
            if (valueDatasetChild.nodeName === namespace + "valueExpression") {
                result = result.set('valueExpression', readCDataText(valueDatasetChild));
            } else if (valueDatasetChild.nodeName === namespace + "labelExpression") {
                result = result.set('labelExpression', readCDataText(valueDatasetChild));
            } else if (valueDatasetChild.nodeName === namespace + "property") {
                const {name, value} = createFusionPropertyExpression(valueDatasetChild as Element, namespace);
                properties = properties.set(name, value);
            } else if (valueDatasetChild.nodeName === namespace + "hyperlink") {
                let hyperLinkMap = Map<string, any>();
                hyperLinkMap = createSectionHyperLinkForElement(valueDatasetChild as Element, hyperLinkMap);
                result = result.set('hyperlink', hyperLinkMap);
            }
        }
    });
    result = result.set('properties', properties);
    return result;
}

const createValuesDataset = (valuesDatasetElement: Element, namespace: string, objectCounter: IObjectCounter): Map<string, any> => {
    let result = Map<string, any>();
    let valueSets = List<Map<string, any>>();
    valuesDatasetElement.childNodes.forEach(valueDatasetChild => {
        if (valueDatasetChild.nodeType === Node.ELEMENT_NODE) {
            if (valueDatasetChild.nodeName === "dataset") {
                result = result.set("dataset", createChartCrosstabDataset(valueDatasetChild as Element, objectCounter));
            } else if (valueDatasetChild.nodeName === namespace + "valueSet") {
                valueSets = valueSets.push(createValueSet(valueDatasetChild as Element, namespace));
            }
        }
    });
    result = result.set('valueSets', valueSets);
    return result;
}

const createValueSetWidget = (widgetElement: Element, namespace: string, actualValues: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let result = createAbstractWidget(widgetElement, namespace, actualValues, objectCounter);
    widgetElement.childNodes.forEach(widgetChild => {
        if (widgetChild.nodeType === Node.ELEMENT_NODE) {
            if (widgetChild.nodeName === namespace + "valuesDataset") {
                result = result.set("valuesDataset", createValuesDataset(widgetChild as Element, namespace, objectCounter));
            }
        }
    });
    return result;
}

const createSparkValuesDataset = (valuesDatasetElement: Element, namespace: string, objectCounter: IObjectCounter): Map<string, any> => {
    let result = Map<string, any>();
    let valueExpressions = List<Map<string, any>>();
    valuesDatasetElement.childNodes.forEach(valueDatasetChild => {
        if (valueDatasetChild.nodeType === Node.ELEMENT_NODE) {
            if (valueDatasetChild.nodeName === "dataset") {
                result = result.set("dataset", createChartCrosstabDataset(valueDatasetChild as Element, objectCounter));
            } else if (valueDatasetChild.nodeName === namespace + "valueExpression") {
                let valueExpressionMap = Map<string, any>();
                valueExpressionMap = valueExpressionMap.set('valueExpression', readCDataText(valueDatasetChild));
                valueExpressions = valueExpressions.push(valueExpressionMap);
            }
        }
    });
    result = result.set('valueExpressions', valueExpressions);
    return result;
}

const createSparkChart = (widgetElement: Element, namespace: string, actualValues: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let result = createAbstractWidget(widgetElement, namespace, actualValues, objectCounter);
    let trendLines = List<Map<string, any>>();
    widgetElement.childNodes.forEach(widgetChild => {
        if (widgetChild.nodeType === Node.ELEMENT_NODE) {
            if (widgetChild.nodeName === namespace + 'trendLine') {
                trendLines = trendLines.push(createTrendLine(widgetChild as Element, namespace));
            } else if (widgetChild.nodeName === namespace + "valuesDataset") {
                result = result.set("valuesDataset", createSparkValuesDataset(widgetChild as Element, namespace, objectCounter));
            }
        }
    });
    result = result.set('trendLines', trendLines);
    return result;
}

const createSparkWinLossItem = (itemElement: Element, namespace: string): Map<string, any> => {
    let result = Map<string, any>();
    itemElement.childNodes.forEach(itemElementChild => {
        if (itemElementChild.nodeType === Node.ELEMENT_NODE) {
            if (itemElementChild.nodeName === namespace + "resultExpression") {
                result = result.set('resultExpression', readCDataText(itemElementChild));
            } else if (itemElementChild.nodeName === namespace + "scorelessExpression") {
                result = result.set('scorelessExpression', readCDataText(itemElementChild));
            }
        }
    });
    return result;
}

const createSparkWinLossValuesDataset = (valuesDatasetElement: Element, namespace: string, objectCounter: IObjectCounter): Map<string, any> => {
    let result = Map<string, any>();
    let items = List<Map<string, any>>();
    valuesDatasetElement.childNodes.forEach(valueDatasetChild => {
        if (valueDatasetChild.nodeType === Node.ELEMENT_NODE) {
            if (valueDatasetChild.nodeName === "dataset") {
                result = result.set("dataset", createChartCrosstabDataset(valueDatasetChild as Element, objectCounter));
            } else if (valueDatasetChild.nodeName === namespace + "item") {
                items = items.push(createSparkWinLossItem(valueDatasetChild as Element, namespace));
            }
        }
    });
    result = result.set('items', items);
    return result;
}

const createSparkWinLossChart = (widgetElement: Element, namespace: string, actualValues: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let result = createAbstractWidget(widgetElement, namespace, actualValues, objectCounter);
    widgetElement.childNodes.forEach(widgetChild => {
        if (widgetChild.nodeType === Node.ELEMENT_NODE) {
            if (widgetChild.nodeName === namespace + "valuesDataset") {
                result = result.set("valuesDataset", createSparkWinLossValuesDataset(widgetChild as Element, namespace, objectCounter));
            }
        }
    });
    return result;
}

const createGanttCategory = (categoryElement: Element, namespace: string) => {
    let result = Map<string, any>();
    let categoryProperties = Map<string, Map<string, any>>();
    categoryElement.childNodes.forEach(categoryChild => {
        if (categoryChild.nodeType === Node.ELEMENT_NODE) {
            if (categoryChild.nodeName === namespace + "categoryProperty") {
                const {name, value} = createFusionPropertyExpression(categoryChild as Element, namespace);
                categoryProperties = categoryProperties.set(name, value);
            } else if (categoryChild.nodeName === namespace + "startExpression") {
                result = result.set('startExpression', readCDataText(categoryChild));
            } else if (categoryChild.nodeName === namespace + "endExpression") {
                result = result.set('endExpression', readCDataText(categoryChild));
            } else if (categoryChild.nodeName === namespace + "labelExpression") {
                result = result.set('labelExpression', readCDataText(categoryChild));
            } else if (categoryChild.nodeName === namespace + "hyperlink") {
                let hyperLinkMap = Map<string, any>();
                hyperLinkMap = createSectionHyperLinkForElement(categoryChild as Element, hyperLinkMap);
                result = result.set('hyperlink', hyperLinkMap);
            }
        }
    });

    result = result.set('categoryProperties', categoryProperties);
    return result;
}

const createGanttCategorySet = (categorySetElement: Element, namespace: string) => {
    let result = Map<string, any>();
    let categoriesProperties = Map<string, Map<string, any>>();
    let categories = List<Map<string, any>>();
    categorySetElement.childNodes.forEach(categorySetChild => {
        if (categorySetChild.nodeType === Node.ELEMENT_NODE) {
            if (categorySetChild.nodeName === namespace + "categoriesProperty") {
                const {name, value} = createFusionPropertyExpression(categorySetChild as Element, namespace);
                categoriesProperties = categoriesProperties.set(name, value);
            } else if (categorySetChild.nodeName === namespace + "bulkCategoriesExpression") {
                result = result.set('bulkCategoriesExpression', readCDataText(categorySetChild));
            } else if (categorySetChild.nodeName === namespace + "category") {
                categories = categories.push(createGanttCategory(categorySetChild as Element, namespace));
            }
        }
    });
    if (categories.size > 0 && !result.has('bulkCategoriesExpression')) {
        result = result.set('categories', categories);
    }
    result = result.set('categoriesProperties', categoriesProperties);
    return result;
}

const createGanttProcess = (processElement: Element, namespace: string) => {
    let result = Map<string, any>();
    let processProperties = Map<string, Map<string, any>>();
    processElement.childNodes.forEach(processChild => {
        if (processChild.nodeType === Node.ELEMENT_NODE) {
            if (processChild.nodeName === namespace + "processProperty") {
                const {name, value} = createFusionPropertyExpression(processChild as Element, namespace);
                processProperties = processProperties.set(name, value);
            } else if (processChild.nodeName === namespace + "idExpression") {
                result = result.set('idExpression', readCDataText(processChild));
            } else if (processChild.nodeName === namespace + "labelExpression") {
                result = result.set('labelExpression', readCDataText(processChild));
            } else if (processChild.nodeName === namespace + "hyperlink") {
                let hyperLinkMap = Map<string, any>();
                hyperLinkMap = createSectionHyperLinkForElement(processChild as Element, hyperLinkMap);
                result = result.set('hyperlink', hyperLinkMap);
            }
        }
    });

    result = result.set('processProperties', processProperties);
    return result;
}

const createGanttProcesses = (processesElement: Element, namespace: string, objectCounter: IObjectCounter) => {
    let result = Map<string, any>();
    let processesProperties = Map<string, Map<string, any>>();
    let processes = List<Map<string, any>>();
    processesElement.childNodes.forEach(processesChild => {
        if (processesChild.nodeType === Node.ELEMENT_NODE) {
            if (processesChild.nodeName === namespace + "processesProperty") {
                const {name, value} = createFusionPropertyExpression(processesChild as Element, namespace);
                processesProperties = processesProperties.set(name, value);
            } else if (processesChild.nodeName === "dataset") {
                result = result.set("dataset", createChartCrosstabDataset(processesChild as Element, objectCounter));
            } else if (processesChild.nodeName === namespace + "process") {
                processes = processes.push(createGanttProcess(processesChild as Element, namespace));
            }
        }
    });
    result = result.set('processes', processes);
    result = result.set('processesProperties', processesProperties);
    return result;
}

const createGanttMilestone = (milestoneElement: Element, namespace: string) => {
    let result = Map<string, any>();
    let milestoneProperties = Map<string, Map<string, any>>();
    milestoneElement.childNodes.forEach(milestoneChild => {
        if (milestoneChild.nodeType === Node.ELEMENT_NODE) {
            if (milestoneChild.nodeName === namespace + "milestoneProperty") {
                const {name, value} = createFusionPropertyExpression(milestoneChild as Element, namespace);
                milestoneProperties = milestoneProperties.set(name, value);
            } else if (milestoneChild.nodeName === namespace + "taskIdExpression") {
                result = result.set('taskIdExpression', readCDataText(milestoneChild));
            } else if (milestoneChild.nodeName === namespace + "dateExpression") {
                result = result.set('dateExpression', readCDataText(milestoneChild));
            } else if (milestoneChild.nodeName === namespace + "hyperlink") {
                let hyperLinkMap = Map<string, any>();
                hyperLinkMap = createSectionHyperLinkForElement(milestoneChild as Element, hyperLinkMap);
                result = result.set('hyperlink', hyperLinkMap);
            }
        }
    });
    result = result.set('milestoneProperties', milestoneProperties);
    return result;
}

const createGanttTask = (taskElement: Element, namespace: string) => {
    let result = Map<string, any>();
    let taskProperties = Map<string, Map<string, any>>();
    taskElement.childNodes.forEach(taskChild => {
        if (taskChild.nodeType === Node.ELEMENT_NODE) {
            if (taskChild.nodeName === namespace + "taskProperty") {
                const {name, value} = createFusionPropertyExpression(taskChild as Element, namespace);
                taskProperties = taskProperties.set(name, value);
            } else if (taskChild.nodeName === namespace + "idExpression") {
                result = result.set('idExpression', readCDataText(taskChild));
            } else if (taskChild.nodeName === namespace + "labelExpression") {
                result = result.set('labelExpression', readCDataText(taskChild));
            } else if (taskChild.nodeName === namespace + "startExpression") {
                result = result.set('startExpression', readCDataText(taskChild));
            } else if (taskChild.nodeName === namespace + "endExpression") {
                result = result.set('endExpression', readCDataText(taskChild));
            } else if (taskChild.nodeName === namespace + "isGroupExpression") {
                result = result.set('isGroupExpression', readCDataText(taskChild));
            } else if (taskChild.nodeName === namespace + "percentCompleteExpression") {
                result = result.set('percentCompleteExpression', readCDataText(taskChild));
            } else if (taskChild.nodeName === namespace + "processIdExpression") {
                result = result.set('processIdExpression', readCDataText(taskChild));
            } else if (taskChild.nodeName === namespace + "process") {
                result = result.set('process', createGanttProcess(taskChild as Element, namespace));
            } else if (taskChild.nodeName === namespace + "hyperlink") {
                let hyperLinkMap = Map<string, any>();
                hyperLinkMap = createSectionHyperLinkForElement(taskChild as Element, hyperLinkMap);
                result = result.set('hyperlink', hyperLinkMap);
            } else if (taskChild.nodeName === namespace + "milestone") {
                result = result.set('milestone', createGanttMilestone(taskChild as Element, namespace));
            }
        }
    });

    result = result.set('taskProperties', taskProperties);
    return result;
}

const createGanttTasks = (processesElement: Element, namespace: string, objectCounter: IObjectCounter) => {
    let result = Map<string, any>();
    let tasksProperties = Map<string, Map<string, any>>();
    let tasks = List<Map<string, any>>();
    processesElement.childNodes.forEach(processesChild => {
        if (processesChild.nodeType === Node.ELEMENT_NODE) {
            if (processesChild.nodeName === namespace + "tasksProperty") {
                const {name, value} = createFusionPropertyExpression(processesChild as Element, namespace);
                tasksProperties = tasksProperties.set(name, value);
            } else if (processesChild.nodeName === "dataset") {
                result = result.set("dataset", createChartCrosstabDataset(processesChild as Element, objectCounter));
            } else if (processesChild.nodeName === namespace + "task") {
                tasks = tasks.push(createGanttTask(processesChild as Element, namespace));
            }
        }
    });
    result = result.set('tasks', tasks);
    result = result.set('tasksProperties', tasksProperties);
    return result;
}

const createGanttDataItem = (dataItemElement: Element, namespace: string) => {
    let result = Map<string, any>();
    let itemProperties = Map<string, Map<string, any>>();
    dataItemElement.childNodes.forEach(dataItemChild => {
        if (dataItemChild.nodeType === Node.ELEMENT_NODE) {
            if (dataItemChild.nodeName === namespace + "itemProperty") {
                const {name, value} = createFusionPropertyExpression(dataItemChild as Element, namespace);
                itemProperties = itemProperties.set(name, value);
            } else if (dataItemChild.nodeName === namespace + "textExpression") {
                result = result.set('textExpression', readCDataText(dataItemChild));
            } else if (dataItemChild.nodeName === namespace + "hyperlink") {
                let hyperLinkMap = Map<string, any>();
                hyperLinkMap = createSectionHyperLinkForElement(dataItemChild as Element, hyperLinkMap);
                result = result.set('hyperlink', hyperLinkMap);
            }
        }
    });
    result = result.set('itemProperties', itemProperties);
    return result;
}

const createGanttDataColumn = (dataColumnElement: Element, namespace: string) => {
    let result = Map<string, any>();
    let columnProperties = Map<string, Map<string, any>>();
    let items = List<Map<string, any>>();
    dataColumnElement.childNodes.forEach(dataColumnChild => {
        if (dataColumnChild.nodeType === Node.ELEMENT_NODE) {
            if (dataColumnChild.nodeName === namespace + "columnProperty") {
                const {name, value} = createFusionPropertyExpression(dataColumnChild as Element, namespace);
                columnProperties = columnProperties.set(name, value);
            } else if (dataColumnChild.nodeName === namespace + "headerTextExpression") {
                result = result.set('headerTextExpression', readCDataText(dataColumnChild));
            } else if (dataColumnChild.nodeName === namespace + "headerHyperlink") {
                let hyperLinkMap = Map<string, any>();
                hyperLinkMap = createSectionHyperLinkForElement(dataColumnChild as Element, hyperLinkMap);
                result = result.set('headerHyperlink', hyperLinkMap);
            } else if (dataColumnChild.nodeName === namespace + "item") {
                items = items.push(createGanttDataItem(dataColumnChild as Element, namespace));
            }
        }
    });
    result = result.set('items', items);
    result = result.set('columnProperties', columnProperties);
    return result;
}

const createGanttDatatable = (datatableElement: Element, namespace: string) => {
    let result = Map<string, any>();
    let datatableProperties = Map<string, Map<string, any>>();
    let dataColumns = List<Map<string, any>>();
    datatableElement.childNodes.forEach(datatableChild => {
        if (datatableChild.nodeType === Node.ELEMENT_NODE) {
            if (datatableChild.nodeName === namespace + "datatableProperty") {
                const {name, value} = createFusionPropertyExpression(datatableChild as Element, namespace);
                datatableProperties = datatableProperties.set(name, value);
            } else if (datatableChild.nodeName === namespace + "datacolumn") {
                dataColumns = dataColumns.push(createGanttDataColumn(datatableChild as Element, namespace));
            }
        }
    });
    result = result.set('dataColumns', dataColumns);
    result = result.set('datatableProperties', datatableProperties);
    return result;
}

const createGanttConnector = (connectorElement: Element, namespace: string) => {
    let result = Map<string, any>();
    let connectorProperties = Map<string, Map<string, any>>();
    connectorElement.childNodes.forEach(connectorChild => {
        if (connectorChild.nodeType === Node.ELEMENT_NODE) {
            if (connectorChild.nodeName === namespace + "connectorProperty") {
                const {name, value} = createFusionPropertyExpression(connectorChild as Element, namespace);
                connectorProperties = connectorProperties.set(name, value);
            } else if (connectorChild.nodeName === namespace + "fromTaskIdExpression") {
                result = result.set('fromTaskIdExpression', readCDataText(connectorChild));
            } else if (connectorChild.nodeName === namespace + "toTaskIdExpression") {
                result = result.set('toTaskIdExpression', readCDataText(connectorChild));
            } else if (connectorChild.nodeName === namespace + "fromTaskConnectStartExpression") {
                result = result.set('fromTaskConnectStartExpression', readCDataText(connectorChild));
            } else if (connectorChild.nodeName === namespace + "toTaskConnectEndExpression") {
                result = result.set('toTaskConnectEndExpression', readCDataText(connectorChild));
            }
        }
    });

    result = result.set('connectorProperties', connectorProperties);
    return result;
}

const createGanttConnectors = (connectorsElement: Element, namespace: string, objectCounter: IObjectCounter) => {
    let result = Map<string, any>();
    let connectorsProperties = Map<string, Map<string, any>>();
    let connectors = List<Map<string, any>>();
    connectorsElement.childNodes.forEach(connectorsChild => {
        if (connectorsChild.nodeType === Node.ELEMENT_NODE) {
            if (connectorsChild.nodeName === namespace + "connectorsProperty") {
                const {name, value} = createFusionPropertyExpression(connectorsChild as Element, namespace);
                connectorsProperties = connectorsProperties.set(name, value);
            } else if (connectorsChild.nodeName === "dataset") {
                result = result.set("dataset", createChartCrosstabDataset(connectorsChild as Element, objectCounter));
            } else if (connectorsChild.nodeName === namespace + "connector") {
                connectors = connectors.push(createGanttConnector(connectorsChild as Element, namespace));
            }
        }
    });
    result = result.set('connectors', connectors);
    result = result.set('connectorsProperties', connectorsProperties);
    return result;
}

const createGanttMilestones = (milestonesElement: Element, namespace: string, objectCounter: IObjectCounter) => {
    let result = Map<string, any>();
    let milestones = List<Map<string, any>>();
    milestonesElement.childNodes.forEach(milestonesChild => {
        if (milestonesChild.nodeType === Node.ELEMENT_NODE) {
            if (milestonesChild.nodeName === "dataset") {
                result = result.set("dataset", createChartCrosstabDataset(milestonesChild as Element, objectCounter));
            } else if (milestonesChild.nodeName === namespace + "milestone") {
                milestones = milestones.push(createGanttMilestone(milestonesChild as Element, namespace));
            }
        }
    });
    result = result.set('milestones', milestones);
    return result;
}

const createGanttLegendItem = (legendItem: Element, namespace: string): Map<string, any> | undefined => {
    let result: Map<string, any> = Map<string, any>();
    result = addAttributeToMap(legendItem, 'color', result);
    result = addChildValueToMapWithName(legendItem, namespace + "labelExpression", "labelExpression", result);
    return result;
}

const createGanttChart = (widgetElement: Element, namespace: string, actualValues: Map<string, any>, objectCounter: IObjectCounter): Map<string, any> => {
    let result = createAbstractWidget(widgetElement, namespace, actualValues, objectCounter);
    let categorySets = List<Map<string, any>>();
    let trendLines = List<Map<string, any>>();
    let legendItems = List<Map<string, any>>();
    widgetElement.childNodes.forEach(widgetChild => {
        if (widgetChild.nodeType === Node.ELEMENT_NODE) {
            if (widgetChild.nodeName === namespace + 'categorySet') {
                categorySets = categorySets.push(createGanttCategorySet(widgetChild as Element, namespace));
            } else if (widgetChild.nodeName === namespace + "processes") {
                result = result.set("processes", createGanttProcesses(widgetChild as Element, namespace, objectCounter));
            } else if (widgetChild.nodeName === namespace + "tasks") {
                result = result.set("tasks", createGanttTasks(widgetChild as Element, namespace, objectCounter));
            } else if (widgetChild.nodeName === namespace + "datatable") {
                result = result.set("datatable", createGanttDatatable(widgetChild as Element, namespace));
            } else if (widgetChild.nodeName === namespace + "connectors") {
                result = result.set("connectors", createGanttConnectors(widgetChild as Element, namespace, objectCounter));
            } else if (widgetChild.nodeName === namespace + "milestones") {
                result = result.set("milestones", createGanttMilestones(widgetChild as Element, namespace, objectCounter));
            } else if (widgetChild.nodeName === namespace + "trendLine") {
                trendLines = trendLines.push(createTrendLine(widgetChild as Element, namespace));
            } else if (widgetChild.nodeName === namespace + "legendItem") {
                legendItems = legendItems.push(createGanttLegendItem(widgetChild as Element, namespace));
            }
        }
    });
    result = addAttributeToMap(widgetElement, 'useDate', result);
    result = addAttributeToMap(widgetElement, 'useTime', result);
    result = result.set('categorySets', categorySets);
    result = result.set('trendLines', trendLines);
    result = result.set('legendItems', legendItems);
    return result;
}

/**
 * Create the JSON structure to represent the HTML5 chart of JRXML.
 *
 * @param componentElementNode the HTML5 chart component node
 * @param parentElement the parent map containing details
 * @param document the current JSON representation of the JRXML
 * @param objectCounter accessory information for producing valid indexes
 */
export const createFusionWidgetElement = (componentElementNode: Element, parentElement: Map<string, any>, document: Map<string, any>, objectCounter: IObjectCounter) => {
    const reportElementNode: Element = getChildNodeByName(componentElementNode, "reportElement") as Element;
    const pathValue = generatePath(parentElement);
    let result: Map<string, any> = createReportElement(reportElementNode, pathValue, ElementTypes.FUSION_WIDGET, objectCounter);

    let newDocument = document;
    componentElementNode.childNodes.forEach(compChild => {
        if (compChild.nodeType === Node.ELEMENT_NODE) {
            const baseNamespace = getNamespace(compChild as Element);
            const namespace = baseNamespace.length > 0 ? baseNamespace + ":" : "";
            result = result.set(FUSIONWIDGETS_NAMESPACE, baseNamespace);
            if (compChild.nodeName === namespace + 'bulb') {
                result = result.set('widgetType', BULB);
                result = createSingleValueRangeWidget(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'horizontalLED') {
                result = result.set('widgetType', HORIZONTAL_LED);
                result = createSingleValueRangeWidget(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'verticalLED') {
                result = result.set('widgetType', VERTICAL_LED);
                result = createSingleValueRangeWidget(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'horizontalBullet') {
                result = result.set('widgetType', HORIZONTAL_BULLET);
                result = createSingleValueTargetWidget(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'verticalBullet') {
                result = result.set('widgetType', VERTICAL_BULLET);
                result = createSingleValueTargetWidget(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'cylinder') {
                result = result.set('widgetType', CYLINDER);
                result = createSingleValueWidget(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'thermometer') {
                result = result.set('widgetType', THERMOMETER);
                result = createSingleValueWidget(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'angularGauge') {
                result = result.set('widgetType', ANGULAR_GAUGE);
                result = createAngularGaugeWidget(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'linearGauge') {
                result = result.set('widgetType', LINEAR_GAUGE);
                result = createLinearGaugeWidget(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'pyramid') {
                result = result.set('widgetType', PYRAMID);
                result = createValueSetWidget(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'funnel') {
                result = result.set('widgetType', FUNNEL);
                result = createValueSetWidget(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'sparkLine') {
                result = result.set('widgetType', SPARK_LINE);
                result = createSparkChart(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'sparkColumn') {
                result = result.set('widgetType', SPARK_COLUMN);
                result = createSparkChart(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'sparkWinLoss') {
                result = result.set('widgetType', SPARK_WIN_LOSS);
                result = createSparkWinLossChart(compChild as Element, namespace, result, objectCounter);
            } else if (compChild.nodeName === namespace + 'ganttChart') {
                result = result.set('widgetType', GANTT);
                result = createGanttChart(compChild as Element, namespace, result, objectCounter);
            }
        }
    });

    newDocument = addChildToParent(parentElement.get("id"), parentElement.get("type"), document, result.get("id"));
    newDocument = newDocument.set("elements", newDocument.get("elements").set(result.get("id"), result));

    return newDocument;
}