/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React from "react"
import { connect } from 'react-redux';
import { IState } from '../../../reducers';
import { Map, List as ImmutableList } from 'immutable';
import * as ReportActions from '../../../actions/reportActions';
import { Base64 } from 'js-base64';
import { saveAs } from 'file-saver';
import { CommandRegistry, IconButton, IEditor, IMenuItem, IResourceInfo, IResourceInfoOptions, IResourceListener, IResourceListenerManager, IToolbarContributor, IToolBarItemOptions, IToolbarProvider, ToolBar, ToolButton } from '@jss/js-common';
import { uuidv4 } from '../../../utils/uuidGenerator';
import jrxmlToJs from '../../common/JrxmlModel/reader/JrxmlReader';
import jsToJrxml from '../../common/JrxmlModel/writer/JrxmlWriter';
import { createDefaultDocument } from '../../../sagas/report/document/documentFactory';
import { EXIT_REPORT_DESIGNER, REPORT_EDITOR_DIRTY, REPORT_SAVED, REPORT_SAVED_AS, SAVE_REPORT, UPDATED_DESCRIPTOR } from '../../..';
import PartElement from '../elements/PartElement';
import { IRepositoryItemDescriptor, isReadOnly, RepositoryApi, RESOURCE_TYPE } from '@jss/js-rest-api';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import PublishIcon from '@material-ui/icons/Publish';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { ResourceInfoDialog, ResourcePickerDialog, RunContext } from '@jss/js-repository';
import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import { getEditorIndex } from '../../../reducers/report/reportReducer';
import QueryEditor from '../QueryEditor/QueryEditor';
import i18n from '../../../i18n';


interface IBookEditorProps {
    resourceListenerManagers: IResourceListenerManager[],
    commandRegistry: CommandRegistry,
    model: any,
    report: any,
    toolbarData: undefined,
    isDirty: boolean,
    undoRedo: Map<string, any>,
    subeditors?: ImmutableList<Map<string, any>>;
    openDescriptor?: IRepositoryItemDescriptor | undefined;
    repositoryPath?: IRepositoryItemDescriptor[],
    language?: string;
    loadCombosAction?: (path: string) => void,
    openDocumentAction?: (model: any, descriptor: IRepositoryItemDescriptor, path: IRepositoryItemDescriptor[], parameters: Map<string, any> | undefined, options: IResourceInfoOptions) => void,
    setDescriptor: (descriptor: IRepositoryItemDescriptor, path: IRepositoryItemDescriptor[]) => void, setDirty: (isDirty: boolean) => void,
    undo: () => void;
    redo: () => void;
}

interface IBookEditorState {
    toolbarData: IToolbarProvider | undefined,
    defaultFileName: string
}

function buildFileSelector(handleFileChange: (e) => void, acceptedFileTypes: string) {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', acceptedFileTypes);
    fileSelector.onchange = handleFileChange;
    fileSelector.onclick = function () {
        (this as any).value = null;
    };
    return fileSelector;
}

class BookEditor extends React.Component<IBookEditorProps, IBookEditorState> implements IEditor {

    private queryEditor: React.RefObject<any> = React.createRef<IToolbarContributor>();

    public resourceListenerId = '';

    protected fileSelector: HTMLInputElement;

    public state = {
        toolbarData: undefined,
        defaultFileName: 'My Book Report',
    };

    componentDidMount() {
        this.resourceListenerId = uuidv4();
        this.fileSelector = buildFileSelector(this.onFileSelected, '.jrxml');
        const resourceListener: IResourceListener = {
            onResourceOpen: (resource: IResourceInfo) => {
                if (this.props.openDocumentAction) {
                    const descriptor = resource.resource;
                    let pa = descriptor.path;
                    if (!pa.endsWith('/')) {
                        pa += "/";
                    }
                    pa += descriptor.name;
                    if (resource.content) {
                        const jsModel = jrxmlToJs(resource.content);
                        this.props.openDocumentAction(jsModel, resource.resource, resource.path, resource.parameters, resource.options);
                        if (resource.isResourceDirty !== undefined) {
                            this.props.setDirty(resource.isResourceDirty);
                        }
                        if (!resource.options || !resource.options.keepCache) {
                            //load the fonts
                            this.props.loadCombosAction(pa);
                        }
                    } else {
                        this.props.openDocumentAction(createDefaultDocument(), resource.resource, resource.path, resource.parameters, resource.options);
                        if (resource.isResourceDirty !== undefined) {
                            this.props.setDirty(resource.isResourceDirty);
                        }
                        this.props.loadCombosAction(pa);

                    }

                }
            }
        }
        this.props.resourceListenerManagers.forEach(manager => {
            manager.addResourceListener(this.resourceListenerId, resourceListener);
        });

        this.props.commandRegistry.registerHandler(EXIT_REPORT_DESIGNER, {
            execute: () => {
                if (this.props.commandRegistry) {
                    this.props.commandRegistry.executeCommand(EXIT_REPORT_DESIGNER);
                }
            },
            isEnabled: () => {
                return true;
            }
        });
        this.props.commandRegistry.registerHandler(SAVE_REPORT, {
            execute: (params: Record<string, unknown>, callback?: () => void) => {
                this.doSave(false, callback);
            },
            isEnabled: () => {
                return true;
            }
        });
        this.provideToolbar();
    }

    componentDidUpdate = (previousProps: IBookEditorProps) => {
        const currentEditorIndex = getEditorIndex(this.props.report);
        const prevEditorIndex = getEditorIndex(previousProps.report);
        if (previousProps.openDescriptor !== this.props.openDescriptor || this.props.language !== previousProps.language) {
            this.provideToolbar();
        } else if (this.props.isDirty !== previousProps.isDirty) {
            this.provideToolbar();
            this.props.commandRegistry.executeCommand(REPORT_EDITOR_DIRTY, { isDirty: this.props.isDirty });
        } else if (this.props.undoRedo !== previousProps.undoRedo) {
            this.provideToolbar();
        } else if (currentEditorIndex !== prevEditorIndex || this.props.subeditors.size !== previousProps.subeditors.size) {
            this.provideToolbar();
        } else if (previousProps.report.getIn(['currentEditor', 'editorType']) !== this.props.report.getIn(['currentEditor', 'editorType'])) {
            this.provideToolbar();
        }
    }

    provideToolbar = () => {
        this.setState({ toolbarData: this.getToolbar() });
    }

    getToolbar = (): IToolbarProvider => {
        const isSaveDisabled = !this.props.isDirty || !this.props.openDescriptor || isReadOnly(this.props.openDescriptor);
        const getSaveSubmenu: IMenuItem[] = [
            {
                id: 'saveAction',
                label: i18n.t('common.actions.menu.save'),
                isEnabled: !isSaveDisabled,
                icon: <IconButton style={{ backgroundColor: 'transparent' }} icon={'save'} size="small" disabled={isSaveDisabled} />,
                onClick: () => {
                    this.doSave(false);
                }
            },
            {
                id: 'saveAsAction',
                label: i18n.t('common.actions.menu.saveas'),
                icon: <SaveAltIcon />,
                onClick: () => {
                    this.doSave(true);
                }
            },
            {
                id: 'propertiesAction',
                label: i18n.t('common.actions.menu.resourceinfo'),
                icon: <InfoIcon />,
                onClick: () => {
                    this.showPropertisDialog();
                },
                isEnabled: this.props.openDescriptor !== undefined && this.props.openDescriptor.uuid !== undefined,
            }
        ]
        getSaveSubmenu.push(
            {
                // TODO -- Improve the structure of this MenuItem.
                // 1. avoid duplication for icon and text
                // 2. the fallback is the central part of the menu item
                // is not trigger the file choose opening
                label: i18n.t('common.actions.menu.upload'),
                id: 'importAction',
                getComponent: (handleClose: () => void) => {
                    return (
                        <MenuItem key={'importAction'} selected={false}>
                            <div style={{ display: 'flex' }} onClick={(event) => { handleClose(); this.handleUploadPress(event) }}>
                                <ListItemIcon>
                                    <PublishIcon />
                                </ListItemIcon>
                                <ListItemText primary={i18n.t('common.actions.menu.upload')} />
                            </div>
                        </MenuItem>
                    );
                },
            }
        );
        getSaveSubmenu.push(
            {
                label: i18n.t('common.actions.menu.download'),
                icon: <GetAppIcon />,
                id: 'exportAction',
                onClick: () => {
                    this.exportReport();
                }
            }
        );
        getSaveSubmenu.push(
            {
                label: i18n.t('common.actions.menu.exit'),
                id: 'exitAction',
                icon: <ExitToAppIcon />,
                onClick: () => {
                    if (this.props.commandRegistry) {
                        this.props.commandRegistry.executeCommand(EXIT_REPORT_DESIGNER);
                    }
                }
            }
        );
        return {
            getMenus: () => {
                return []
            },
            getToolItems: () => {
                const items = [
                    {
                        id: 'saveToolaction',
                        render: (options?: IToolBarItemOptions) => {
                            //disable the save when the report is not dirty or we don't have write permission
                            const height = options && options.height ? options.height : 24;
                            const backgroundColor = options && options.background ? options.background : undefined;
                            return <ToolButton label={i18n.t('common.actions.menu.save')} isDisabled={isSaveDisabled} onClick={() => this.doSave(false)} height={height} backgroundColor={backgroundColor} content={getSaveSubmenu}>
                                <IconButton style={{ backgroundColor: 'transparent' }} icon={'save'} size="small" disabled={isSaveDisabled} />
                            </ToolButton>
                        }
                    },
                    {
                        id: 'undoToolaction',
                        render: (options?: IToolBarItemOptions | undefined) => {
                            const isUndoDisabled = !this.props.undoRedo || this.props.undoRedo.get('past').count() === 0;
                            const height = options && options.height ? options.height : 24;
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                                <div title={i18n.t('common.actions.menu.undo')} style={{ height: height, cursor: isUndoDisabled ? 'default' : 'pointer', justifyContent: 'center', alignItems: 'center', marginRight: 15 }} onClick={!isUndoDisabled ? this.undo : undefined}>
                                    <IconButton style={{ backgroundColor: 'transparent' }} icon={'undo'} size="small" disabled={isUndoDisabled} />
                                </div>
                            </div>
                        }
                    },
                    {
                        id: 'redoToolaction',
                        render: (options?: IToolBarItemOptions) => {
                            const height = options && options.height ? options.height : 24;
                            const isRedoDisabled = !this.props.undoRedo || this.props.undoRedo.get('future').count() === 0;
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div title={i18n.t('common.actions.menu.redo')} style={{ height: height, cursor: isRedoDisabled ? 'default' : 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={!isRedoDisabled ? this.redo : undefined}>
                                    <IconButton icon={'redo'} size="small" disabled={isRedoDisabled} />
                                </div>
                                <div style={{ height: 28 }} className="jr-MuiDivider-root jr-mToolbar-divider mui jr-MuiDivider-vertical" />
                            </div>;
                        }
                    },

                ];
                if (this.queryEditor.current !== null) {
                    const subToolbar = this.queryEditor.current.contributeToolbar();
                    subToolbar.getToolItems().forEach((tooltiem) => {
                        items.push(tooltiem);
                    });
                }
                return items;
            }
        }
    }

    undo = () => {
        this.props.undo();
    }

    redo = () => {
        this.props.redo();
    }

    public getEditorContent = () => {
        const jrxml = jsToJrxml(this.props.model);
        return jrxml;
    }

    private doSave = (forceNewFile: boolean, callback?: () => void) => {
        const jrxml = jsToJrxml(this.props.model);
        if (jrxml) {
            if (this.props.openDescriptor && !forceNewFile) {
                //it is a save of an existing report
                const base64data = Base64.encode(jrxml);
                const descriptor: IRepositoryItemDescriptor = this.props.openDescriptor;
                const newDescriptor: IRepositoryItemDescriptor = {
                    ...(descriptor as any),
                    data: base64data,
                }

                this.context.runNoCancel(RepositoryApi.inst().save)(descriptor.uuid ? 'uuid:' + descriptor.uuid : (descriptor.path ? descriptor.path : '/'), newDescriptor, newDescriptor).then((response: AxiosResponse) => {
                    if (response.data) {
                        this.props.commandRegistry.executeCommand(REPORT_SAVED, { descriptor: response.data, data: jrxml });
                        this.props.setDirty(false);
                        if (callback) {
                            callback();
                        }
                    }
                });
            } else {
                //it is a new file
                this.openSaveDialog();
            }
        }
    }

    private handleItemPropertiesChanged = (oldDescriptor: IRepositoryItemDescriptor, newDescriptor: IRepositoryItemDescriptor | null) => {
        this.props.setDescriptor(newDescriptor, this.props.repositoryPath);
        this.props.commandRegistry.executeCommand(REPORT_SAVED, { descriptor: newDescriptor });
    }

    private confirmSave = (folderPath: IRepositoryItemDescriptor[], file: IRepositoryItemDescriptor | null, fileName: string) => {
        const jrxml = jsToJrxml(this.props.model);
        const base64data = Base64.encode(jrxml);
        let root = false;
        const newDescriptor: IRepositoryItemDescriptor = {
            type: RESOURCE_TYPE.FILE,
            name: fileName,
            path: RepositoryApi.inst().getParentPath(folderPath.filter(k => {
                root = root || file === null || k.type !== RESOURCE_TYPE.CONTAINER;
                return file == null || (root && k.type === RESOURCE_TYPE.CONTAINER)
            })),
            data: base64data,
        }
        if (file !== null) {
            //the save as is overwriting a file
            newDescriptor.path = file.path;
            newDescriptor.uuid = file.uuid;
        }
        this.context.runNoCancel(RepositoryApi.inst().save)(file !== null ? 'uuid:' + file.uuid : '', newDescriptor).then((response: AxiosResponse) => {
            if (response.data) {
                this.props.setDescriptor(response.data, folderPath);
                this.props.commandRegistry.executeCommand(REPORT_SAVED_AS, { descriptor: response.data, data: jrxml });
                this.props.setDirty(false);
            }
        });
    }

    private showPropertisDialog = () => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.context.showDialog(ResourceInfoDialog, { descriptor: this.props.openDescriptor, onViewClose: () => { }, onChanged: this.handleItemPropertiesChanged });
    }

    public openSaveDialog = () => {
        const defaultPath: IRepositoryItemDescriptor[] = [];
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.context.showDialog(ResourcePickerDialog, { fileNameLabel: 'Book Name', mode: 'save', title: 'Save Report Book As...', onViewClose: () => { }, onFileSelected: this.confirmSave, defaultFileName: `${this.state.defaultFileName}.jrxml`, defaultPath: defaultPath });
    }

    handleUploadPress = (e) => {
        e.preventDefault();
        this.fileSelector.click();
    }

    private exportReport = () => {
        const jrxml = jsToJrxml(this.props.model);
        const blob = new Blob([jrxml], { type: "text/xml;charset=utf-8" });
        const extension = !this.props.openDescriptor.name.endsWith('.jrxml') ? '.jrxml' : '';
        saveAs(blob, this.props.openDescriptor.name + extension);
    }

    private onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            const jrxml = fileReader.result as string;
            // TODO - perform some kind of validation of the loaded content
            // or be sure that a sort of validation is triggered when editorContent is updated
            const base64data = Base64.encode(jrxml);
            const descriptor: IRepositoryItemDescriptor = this.props.openDescriptor;
            const newDescriptor: IRepositoryItemDescriptor = {
                ...(descriptor as any),
                data: base64data,
            }
            const jsModel = jrxmlToJs(jrxml);
            this.props.openDocumentAction(jsModel, newDescriptor, this.props.repositoryPath, undefined, {});
            this.props.commandRegistry.executeCommand(UPDATED_DESCRIPTOR, { descriptor: newDescriptor, data: jrxml });
        };
        fileReader.readAsText(event.currentTarget.files[0]);
    }

    public render() {
        const currentEditorIndex = getEditorIndex(this.props.report);
        let isQueryEditorOpen = false;
        if (currentEditorIndex !== undefined) {
            isQueryEditorOpen = this.props.report.getIn(['subeditors', currentEditorIndex, 'type']) === 'datasetQueryEditor';
        }
        let mainControl;
        if (!isQueryEditorOpen) {
            const components = [];
            const groups = this.props.report.getIn(['model', 'groups']);
            if (groups) {
                groups.forEach(group => {
                    const groupName = group.get('name');
                    const groupHeaderParts = this.props.report.getIn(['model', 'bookSections', 'groupHeaderSection_' + groupName]);
                    if (groupHeaderParts && groupHeaderParts.size > 0) {
                        const partsIds = groupHeaderParts.get('elementIds');
                        if (partsIds) {
                            components.push(
                                <div key={'groupHeader_' + groupName} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ marginTop: 10, paddingBottom: 10, marginBottom: 10, borderBottomStyle: 'solid', borderBottomColor: 'lightgray', borderBottomWidth: 1, color: 'lightgray' }}>{`Group Header ${groupName}`}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {partsIds.size > 0 ? partsIds.map((partId: string) => {
                                            const part = this.props.report.getIn(['model', 'elements', partId]);
                                            return <PartElement key={part.get('id')} id={partId} />
                                        }) : <div style={{ display: 'flex', height: 200 }} />}
                                    </div>
                                </div>
                            );
                        }
                    } else {
                        components.push(
                            <div key={'groupHeader_' + groupName} style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ marginTop: 10, paddingBottom: 10, marginBottom: 10, borderBottomStyle: 'solid', borderBottomColor: 'lightgray', borderBottomWidth: 1, color: 'lightgray' }}>{`Group Header ${groupName}`}</div>
                                <div style={{ display: 'flex', height: 200 }} />
                            </div>
                        );
                    }
                });
            }
            const detailSection = this.props.report.getIn(['model', 'bookSections', 'detailSection']);
            if (detailSection) {
                const partsIds = detailSection.get('elementIds');
                components.push(
                    <div key={'content'} style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginTop: 10, paddingBottom: 10, marginBottom: 10, borderBottomStyle: 'solid', borderBottomColor: 'lightgray', borderBottomWidth: 1, color: 'lightgray' }}>Detail</div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {partsIds.size > 0 ? partsIds.map((partId: string) => {
                                const part = this.props.report.getIn(['model', 'elements', partId]);
                                return <PartElement key={part.get('id')} id={partId} />;
                            }) : <div style={{ display: 'flex', height: 200 }} />}
                        </div>
                    </div>
                );
            }
            if (groups) {
                groups.forEach(group => {
                    const groupName = group.get('name');
                    const groupFooterParts = this.props.report.getIn(['model', 'bookSections', 'groupFooterSection_' + groupName]);
                    if (groupFooterParts && groupFooterParts.size > 0) {
                        const partsIds = groupFooterParts.get('elementIds');
                        if (partsIds) {
                            components.push(
                                <div key={'groupFooter_' + groupName} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ marginTop: 10, paddingBottom: 10, marginBottom: 10, borderBottomStyle: 'solid', borderBottomColor: 'lightgray', borderBottomWidth: 1, color: 'lightgray' }}>{`Group Footer ${groupName}`}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {partsIds.size > 0 ? partsIds.map((partId: string) => {
                                            const part = this.props.report.getIn(['model', 'elements', partId]);
                                            return <PartElement key={part.get('id')} id={partId} />
                                        }) : <div style={{ display: 'flex', height: 200 }} />}
                                    </div>
                                </div>
                            );
                        }
                    } else {
                        components.push(
                            <div key={'groupFooter_' + groupName} style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ marginTop: 10, paddingBottom: 10, marginBottom: 10, borderBottomStyle: 'solid', borderBottomColor: 'lightgray', borderBottomWidth: 1, color: 'lightgray' }}>{`Group Footer ${groupName}`}</div>
                                <div style={{ display: 'flex', height: 200 }} />
                            </div>
                        );
                    }
                });
            }
            mainControl = <div style={{ marginLeft: 10, marginRight: 10, overflow: 'scroll' }}>
                {components}
            </div>
        } else {
            //query editor open
            mainControl = <div style={{ display: 'flex', flex: 1, flexBasis: 0, minHeight: 0 }}>
                <QueryEditor ref={this.queryEditor} />
            </div>
        }
        return <div style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: 'rgb(247, 246, 246)', overflow: 'scroll', flexBasis: 0 }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                <ToolBar toolbarData={this.state.toolbarData} />
            </div>
            {mainControl}
        </div>

    }

}

const mapStateToProps = (state: IState) => {
    return {
        report: state.getIn(['report']),
        model: state.getIn(['report', 'model']),
        undoRedo: state.getIn(['report', 'undoRedo']),
        subeditors: state.getIn(['report', 'subeditors']),
        openDescriptor: state.getIn(['report', 'fileDescriptor']),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setObjectProperties: (path: string[], value: any) => { dispatch(ReportActions.setObjectProperties(path, value)); },
        openDocumentAction: (model: any, descriptor: IRepositoryItemDescriptor, path: IRepositoryItemDescriptor[], parameters: Map<string, any> | undefined, options: IResourceInfoOptions) => {
            dispatch(ReportActions.openDocumentAction(model, descriptor, path, parameters, options));
        },
        setDescriptor: (descriptor: IRepositoryItemDescriptor, path: IRepositoryItemDescriptor[]) => {
            dispatch(ReportActions.setDescriptor(descriptor, path));
        },
        setDirty: (isDirty: boolean) => {
            dispatch(ReportActions.setDirty(isDirty));
        },
        undo: () => {
            dispatch(ReportActions.undo());
        },
        redo: () => {
            dispatch(ReportActions.redo());
        },
        loadCombosAction: (path: string) => {
            dispatch(ReportActions.loadCombosAction(path, false));
        },
    };
}

BookEditor.contextType = RunContext;


export default connect(mapStateToProps, mapDispatchToProps)(BookEditor);
