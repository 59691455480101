
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { AbstractTextualEditor, IAbstractTextualEditor } from './AbstractTextualEditor';
import AceEditor, { IAceEditorProps } from 'react-ace';
import { RunContext } from '@jss/js-repository/src/RunContainer';
import { IRepositoryItemDescriptor } from '@jss/js-rest-api';
import { ResourcePickerDialog } from '@jss/js-repository';
import SimplyBeautiful from 'simply-beautiful';

export default class InternalJavascriptEditor extends AbstractTextualEditor {

    constructor(props: IAbstractTextualEditor) {
        super(props);
    }

    public getAcceptedFileTypes() {
        return ".js,.jsx,.ts,.tsx";
    }

    public getDefaultExtension() {
        return 'js';
    }
    
    public getTextEditor(editorProps: IAceEditorProps) : React.ReactElement{
        const props = {
            ...editorProps,
            mode: 'javascript',
            wrapEnabled: true,
            enableBasicAutocompletion: true,
            tabSize: 2,
            theme: 'textmate',
        };
        return <AceEditor {...props} ref={this.editor} />;
    }

    public openSaveDialog() : void {
        const defaultPath: IRepositoryItemDescriptor[] = this.state.path;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.context.showDialog(ResourcePickerDialog, {fileNameLabel: 'File Name', mode: 'save', title: 'Save File As...', onViewClose: () => { }, onFileSelected: this.confirmSave, defaultFileName: `${this.state.openDescriptor?.name}`,  defaultPath: defaultPath });
    }

    public formatContent(content: string): string { 
        try {
            const options = {
                indent_size: 2,
            };
            const result = SimplyBeautiful.js(content, options);
            return result;
        } catch (error) {
            return content;
        }
    }

    public hasFomatter = () => {
        return true;
    }
}

InternalJavascriptEditor.contextType = RunContext;
