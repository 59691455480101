/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import React, {
    forwardRef
} from 'react';

import { TextField, TextFieldProps } from '../textinput/TextField';
import { ColorPickerSample } from './ColorPickerSample';

export type ColorPickerProps = Omit<TextFieldProps, 'value'> & {
    value?: string;
    allowTransparent?: boolean;
    allowNull?: boolean;
    onColorChange: (color: string) => void;
    onColorTextChange?: (color: string) => void;
}

const getTextualValue = (value?: string): string | undefined => {
    if (value && value.startsWith('rgba(')){
        return value.substring(5, value.length - 1);
    }
    return value;
}

const isValid = (color: string | undefined, allowNull?: boolean) : boolean => {
    if (color){
        if (color.startsWith('#')){
            return /^#[0-9A-F]{6}$/i.test(color);
        } else if (color.startsWith('rgba(')){
            const values = color.substring(5, color.length - 1).split(',');
            for(let i=0; i < values.length; i++){
                const valueNumber = parseInt(values[i]);
                if (isNaN(valueNumber) || valueNumber < 0 || valueNumber > 255){
                    return false;
                }
            }
            return true;
        } else if (color.startsWith('rgb(')){
            const values = color.substring(4, color.length - 1).split(',');
            for(let i=0; i < values.length; i++){
                const valueNumber = parseInt(values[i]);
                if (isNaN(valueNumber) || valueNumber < 0 || valueNumber > 255){
                    return false;
                }
            }
            return true;
        }
    } else if (allowNull){
        return true;
    }
    return false;
}

// eslint-disable-next-line react/display-name
export const ColorPicker = forwardRef<HTMLDivElement, ColorPickerProps>(({
    InputProps = {}, value, allowTransparent, onColorChange, onColorTextChange, allowNull, className = '', ...rest
}, ref) => {

    const isColorPickerDisabled = rest.disabled ?? InputProps?.readOnly;
    return (
        <TextField
            ref={ref}
            className={`jr-mControl jr-mControlColor mui ${className}`}
            value={getTextualValue(value)}
            type="string"
            onTextChange={(value: string | undefined) => {
                if (onColorTextChange && isValid(value, allowNull)){
                    onColorTextChange(value ? value : '');
                }
            }}
            InputProps={{
                startAdornment: <ColorPickerSample
                    color={value}
                    onChange={onColorChange}
                    allowTransparent={allowTransparent}
                    disableAlpha={!allowTransparent}
                    disabled={isColorPickerDisabled}
                />,
                ...InputProps
            }}
            onBlurWithSetter={(event, setText) => {
                const lastValidText = getTextualValue(value);
                setText(lastValidText ? lastValidText : '');
            }}
            {...rest}
        />
    )
})
