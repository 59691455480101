/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { FormControlledSelect, FormControlledWritableCombo, ICONS } from "@jss/js-common";
import { Map, List } from 'immutable';
import * as React from 'react';
import '../../../../../assets/uxpl/css/UPControls.css';
import i18n from '../../../../../i18n';
import { getDataset } from "../../DatasetUtils";
import { APDescriptor } from '../../ui/APDescriptor';
import { getKey, getNormalizedPath, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';

const TYPEID = 'dsObject';
reg(TYPEID, (mc) => { return <UPDsObject mcontext={mc} />; });

export enum DsObject { FIELD, PARAMETER, VARIABLE, GROUP }
export enum DsType { CURRENT, MAIN }

export class PDsObject extends APDescriptor {
    types: DsObject[];
    default?: string;
    writeable?: boolean = true;
    isEmptyUndefined?: boolean;
    dataset?: string | DsType.CURRENT | DsType.MAIN = DsType.CURRENT; // string = property in the current model to get the name of the dataset
    public constructor(init: Partial<PDsObject>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
export class UPDsObject extends React.Component<UiPropertyProps> {

    render() {
        const d = this.props.mcontext.descriptor as PDsObject;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        const v = this.props.mcontext.model.getIn(p) as string | undefined;

        let dataset : Map<string, any>;
        if (d.dataset === DsType.MAIN){
            dataset = this.props.mcontext.rootModel.get('model');
        } else if (d.dataset === DsType.CURRENT) {
            dataset = getDataset(this.props.mcontext.model, getPath(undefined, this.props.mcontext.elements[0].path));
        } else {
            dataset = this.props.mcontext.model.getIn(['subdatasets', d.dataset]) as Map<string, any>;
        }

        const items = [];
        if (dataset){
            if (d.types.includes(DsObject.FIELD)){
                const fileds = dataset.get('fields');
                if (fileds){
                    fileds.forEach((field: Map<string, any>)  => {
                        const name = field.get('name');
                        items.push({key: name, value: name, icon: ICONS.FIELD_ICON});
                    });
                }
            }
            if (d.types.includes(DsObject.VARIABLE)){
                const variables = dataset.get('variables');
                if (variables){
                    variables.forEach((variable: Map<string, any>)  => {
                        const name = variable.get('name');
                        items.push({key: name, value: name, icon: ICONS.VARIABLE_ICON});
                    });
                }
                const systemVariables = this.props.mcontext.rootModel.getIn(['cachedData', 'comboValues', 'systemVariables']) as List<Map<string, any>> | undefined;
                if (systemVariables){
                    systemVariables.forEach((variable: Map<string, any>)  => {
                        const name = variable.get('name');
                        items.push({key: name, value: name, icon: ICONS.VARIABLE_ICON});
                    });
                }
            }
            if (d.types.includes(DsObject.PARAMETER)){
                const parameters = dataset.get('parameters');
                if (parameters){
                    parameters.forEach((parameter: Map<string, any>)  => {
                        const name = parameter.get('name');
                        items.push({key: name, value: name, icon: ICONS.PARAMETER_ICON});
                    });
                }
                const language = dataset.get('queryLanguage', 'sql');
                const systemParameters = this.props.mcontext.rootModel.getIn(['cachedData', 'comboValues', 'systemParameters', language]) as List<Map<string, any>> | undefined;
                if (systemParameters){
                    systemParameters.forEach((parameter: Map<string, any>)  => {
                        const name = parameter.get('name');
                        items.push({key: name, value: name, icon: ICONS.PARAMETER_ICON});
                    });
                }
            }
            if (d.types.includes(DsObject.GROUP)){
                const groups = dataset.get('groups');
                if (groups){
                    groups.forEach((group: Map<string, any>)  => {
                        const name = group.get('name');
                        items.push({key: name, value: name});
                    });
                }
            }
        }
        if (!d.writeable) {
            return <FormControlledSelect key={getKey(p)} items={items}
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            value={v}
            onItemSelected={this.onWritableNameChange}
            label={i18n.t(this.props.mcontext.descriptor.label)}
            disabled={d.readonly}
            InputLabelProps={d.deprecated ? {className: 'deprecatedProperty'} : undefined}
            inline={true} size={'small'}
        />
        }
        return <FormControlledWritableCombo
            items={items}
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            key={getKey(p)}
            value={v}
            disabled={d.readonly}
            onComboChange={this.onWritableNameChange}
            label={i18n.t(this.props.mcontext.descriptor.label)}
            inline={true}
            InputLabelProps={d.deprecated ? {className: 'deprecatedProperty'} : undefined}
            InputProps={{title: v}}
        />
    }

    public onWritableNameChange = (selectedKey: string | undefined, selectedText: string) => {
        this.props.mcontext.elements.forEach(key => {
            if ((!selectedKey && !selectedText) || (!selectedKey && selectedText.trim().length === 0)){
                const path = getNormalizedPath(key.path);
                path.push(this.props.mcontext.descriptor.id);
                this.props.mcontext.deleteElement(path);
            } else {
                this.props.mcontext.setObjectProperties(key.path, { [this.props.mcontext.descriptor.id]: selectedKey ? selectedKey : selectedText });
            }
        });
    }

}