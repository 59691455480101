/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import axios, { AxiosPromise } from 'axios';
import { RepositoryApi } from '../repo/RepositoryApi';
import { IRepositoryItemDescriptor } from '../repo/IRepositoryItemDescriptor';
import { Conf } from '../config/Conf';
import { IJrio } from '../jrio/IJrio';
import { RepositoryJRS } from '../jrs/RepositoryJRS';
import { Base64 } from 'js-base64';

const nocache = { headers: { 'Cache-Control': 'no-cache' } };

export class JrioRest implements IJrio {

  private baseURL;

  public getBaseURL(): string {
    if (!this.baseURL) {
      this.baseURL = Conf.get('jrws.url.rest.jrio');
    }
    return this.baseURL;
  }

  /**
   * Check the status of a report execution
   *
   * @param executionId
   */
  public reportExecutionDetails(executionId: string): AxiosPromise<any> {
    return axios.get(this.getBaseURL() + '/reportExecutions/' + encodeURIComponent(executionId), nocache);
  }

  public getExecutionPath(d: IRepositoryItemDescriptor): string {
    if (RepositoryApi.inst().getBase() === 'js' && d.isMainReport) {
      if (d.uuid) {
        const indx = d.uuid.indexOf('_files/');
        if (indx >= 0) {
          return d.uuid.substring(0, indx);
        }
      }
      return '';
    }

    const p = RepositoryApi.getAPath(d).split('\\').join('/');
    return p.startsWith('/') ? p : '/' + p;
  }


  public isPreviewAvailable(d: IRepositoryItemDescriptor): boolean {
    if (d === undefined)
      return false;
    if (d.permission !== undefined && d.permission < 1)
      return false;
    return true;
  }

  public getViewerClientURL(d: IRepositoryItemDescriptor): string {
    let burl = RepositoryApi.inst().getBaseURL();
    if (RepositoryApi.inst().getBase() === 'js') {
      const info = RepositoryApi.inst().getInfo();
      if (info) {
        const inf = Base64.decode(info);
        const vars = inf.split('&');
        let uname, pass;
        for (let i = 0; i < vars.length; i++) {
          const pair = vars[i].split('=');
          if (pair[0] === 'serverAddress')
            burl = pair[1];
          else if (pair[0] === 'username')
            uname = pair[1];
          else if (pair[0] === 'password')
            pass = pair[1];
        }
        if (uname !== undefined) {
          // here maybe we could fix the run through the jrws server, to avoid things like that
          RepositoryJRS.authenticateClient(burl, uname, pass).catch(e => {
            console.log(e);
            // throw e;
          });
        }
      }
    }
    if (RepositoryApi.inst().getBase() === 'js' && d.isMainReport) {
      return `${burl}${burl.endsWith("/") ? "" : "/"}client/visualize.js`;
    }
    const baseUrl = Conf.get("jrws.url.rest.jrio.client");
    return `${baseUrl}${baseUrl.endsWith("/") ? "" : "/"}scripts/jrio/jrio.js`;
  }

  public getViewerClientName(d: IRepositoryItemDescriptor): string {
    return RepositoryApi.inst().getBase() === 'js' && d.isMainReport ? "visualize" : "jrio";
  }

  public getViewerClientConfig(d: IRepositoryItemDescriptor): Record<string, unknown> {
    if (RepositoryApi.inst().getBase() === 'js' && d.isMainReport) {
      return {
        logEnabled: false,
        useTheme: false
      };
    }
    return {
      server:  Conf.get('jrws.url.rest.jrio'),
      locale: "en_US",  // FIXME: use proper locale
      useTheme: false
    };
  }
}