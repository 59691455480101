
/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import { Provider } from 'react-redux';
import store from '../../../store/store';
import SelectedAndDatasetProperties from './SelectedAndDatasetProperties';

export class PropertiesView extends React.Component {

  public render() {
    return (<Provider store={store as any}>
      <SelectedAndDatasetProperties />
    </Provider>);
  }
}
