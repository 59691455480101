/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import * as React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { IMenuItem } from "./ToolbarProvider";
import { ListItemText, Menu } from '@material-ui/core';

interface IToolButton {
    isDisabled?: boolean,
    onClick?: () => void,
    label: string,
    height: number | string,
    backgroundColor: string,
    content?: IMenuItem[];
}

interface IToolButtonSate {
    anchor: any,
}

export class ToolButton extends React.Component<IToolButton, IToolButtonSate> {

    private divRef: React.RefObject<HTMLDivElement> = React.createRef();

    state = {
        anchor: null,
    }

    private onClick = () => {
        if (!this.props.isDisabled && this.props.onClick) {
            this.props.onClick();
        }
    }

    handleClick = (event) => {
        this.setState({ anchor: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchor: null });
    };

    getPopoverContent = () => {
        return <Menu
            classes={{
                paper: "jr-mMenu jr-mMenuDecorated mui",
                list: "jr-mMenu-list mui"
            }}
            elevation={4}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            id="customized-menu"
            anchorEl={this.divRef.current}
            keepMounted
            open={Boolean(this.state.anchor)}
            onClose={this.handleClose}
        >
            <div/>
            {this.props.content?.map((element, index) => {
                if (element.getComponent) {
                    return element.getComponent(this.handleClose);
                } else if (element === null) {
                    return <Divider key={index} />;
                } else {
                    const isEnabled = element.isEnabled === undefined || element.isEnabled;
                    if (element.icon) {
                        return <MenuItem key={index} className={"jr-mMenu-list-item mui"} onClick={() => {
                            if (isEnabled) {
                                this.handleClose();
                                element.onClick?.();
                            }
                        }}>
                            <ListItemIcon className={"jr-mMenu-list-item-icon mui"}>
                                {element.icon}
                            </ListItemIcon>
                            <ListItemText
                                className={"jr-mMenu-list-item-text mui"}
                                classes={{ primary: "jr-mText mui" }}
                                style={{ color: isEnabled ? undefined : 'lightgray' }}
                                primary={element.label}
                            />
                        </MenuItem>
                    } else {
                        return <MenuItem key={index} className={"jr-mMenu-list-item mui"} onClick={() => {
                            if (isEnabled) {
                                this.handleClose();
                                element.onClick?.();
                            }
                        }}>
                            <ListItemText
                                className={"jr-mMenu-list-item-text mui"}
                                classes={{ primary: "jr-mText mui" }}
                                style={{ color: isEnabled ? undefined : 'lightgray' }}
                                primary={element.label}
                            />
                        </MenuItem>
                    }
                }
            })}
        </Menu>
    }


    public getPopoverMainControl = (handleClick: (clickEvent: any) => void) => {
        return <div style={{
            width: 24,
            height: this.props.height,
            display: 'flex',
            cursor: this.props.isDisabled ? 'default' : 'pointer',
            justifyContent: 'center',
            alignItems: 'center'
        }} onClick={handleClick}>
            {this.props.children}
        </div>;
    }

    public render() {
        let dropDownButton;
        if (this.props.content) {
            dropDownButton = <div>
                <ArrowDropDownIcon style={{ width: 15, cursor: 'pointer', height: this.props.height }} onClick={this.handleClick} />
                {this.getPopoverContent()}
            </div>
        }
        let mainControl;
        if (!this.props.onClick && this.props.content) {
            mainControl = <div style={{
                width: 24,
                height: this.props.height,
                display: 'flex',
                cursor: this.props.isDisabled ? 'default' : 'pointer',
                justifyContent: 'center',
                alignItems: 'center'
            }} onClick={this.handleClick}>
                {this.props.children}
            </div>
        } else {
            mainControl = <div style={{
                width: 24,
                height: this.props.height,
                display: 'flex',
                cursor: this.props.isDisabled ? 'default' : 'pointer',
                justifyContent: 'center',
                alignItems: 'center'
            }} onClick={this.onClick}>
                {this.props.children}
            </div>
        }
        return <div title={this.props.label} ref={this.divRef} style={{
            display: 'flex',
            width: '39px',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            background: this.props.backgroundColor
        }}>
            {mainControl}
            {dropDownButton}
        </div>
    }
}