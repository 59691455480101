/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const PlotOrientationEnum = new PEnum({
    id: 'orientation', label: 'PlotOrientation.label', isEmptyUndefined: true,
    items: [
        { key: '', value: ''},
        { key: 'Horizontal', value: 'PlotOrientation.Horizontal'},
        { key: 'Vertical', value: 'PlotOrientation.Vertical'}
    ]
}); 
 
