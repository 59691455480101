export const en = {
 "datasource.csv.descriptor.datepattern": "Date Pattern is a java SimpleDateFormatted pattern format string, used to parse dates, times and timestamp coming from the file",
 "datasource.csv.descriptor.numberpattern": "Number Pattern is a java DecimalFormat pattern format string, used to parse numbers coming from the file",
 "datasource.csv.descriptor.label": "CSV",
 "datasource.csv.description": "Get data from CSV files",
 "datasource.editor.testTooltip": "This will perform a test on data adapter",
 "datasource.editor.test.label": "Test",
 "datasource.editor.saveAs.name": "Data Adapter Name",
 "datasource.editor.saveAs.title": "Save Data Adapter As...",
 "datasource.editor.save.title": "Save Data Adapter",
 "datasource.editor.saveAs.okButton": "Ok",
 "datasource.editor.save.saveButton": "Save",
 "datasource.editor.saveAs.saveOk": "Data Adapter Correctly Saved",
 "datasource.editor.unknowData": "Unknown source selected....",
 "datasource.editor.test.success": "Data Source successfully validated",
 "datasource.editor.test.fail": "The Data Source test failed, but no reason has been provided.",
 "datasource.editor.test.close": "Close",
 "datasource.editor.test.error": "Error",
 "datasource.editor.test.cancel": "Cancel",
 "datasource.editor.test.tryAgain": "Try Again",
 "datasource.editor.loading": "Processing, please wait...",
 "datasource.csv.name": "Name",
 "datasource.csv.name.help": "Specify a name for this  Data Adapter",
 "datasource.csv.url": "CSV File Path or URL",
 "datasource.csv.url.help": "The URL or Path to retrieve the CSV file",
 "datasource.csv.separator": "Field Delimiter",
 "datasource.csv.separator.help": "Property holding the value of the record delimiter from the CSV source",
 "datasource.csv.delimiter": "Record Delimiter",
 "datasource.csv.delimiter.help": "Property holding the value of the field delimiter from the CSV source",
 "datasource.csv.datepattern": "Date Pattern",
 "datasource.csv.datepattern.help": "Date Pattern is a java SimpleDateFormatted pattern format string, used to parse dates, times and timestamp coming from the file",
 "datasource.csv.numberpattern": "Number Pattern",
 "datasource.csv.numberpattern.help": "Number Pattern is a java DecimalFormat pattern format string, used to parse numbers coming from the file",
 "datasource.csv.timezone": "Timezone",
 "datasource.csv.timezone.help": "Property holding the text value of the time zone id to be used when parsing the CSV data",
 "datasource.csv.locale": "Locale",
 "datasource.csv.encoding": "Encoding",
 "datasource.csv.encoding.help": "Property holding the value of the charset used to encode the CSV stream. ",
 "datasource.csv.useFirstLineAsHeader": "Use First Line As Header",
 "datasource.csv.columnName": "Column Name",
 "datasource.csv.locale.help": "Property holding the text code of the locale to be used when parsing the CSV data",
 "datasource.csv.useFirstLineAsHeader.help": "Property specifying whether or not the column names should be obtained from the first row in the CSV source",
 "datasource.csv.queryExecuterMode": "Use query executer mode",
 "datasource.csv.queryExecuterMode.help": "Use query exectuer mode, the report must use the CSV query language",


 "datasource.csv.delimiter.lf": "New Line \\n LF, Unix",
 "datasource.csv.delimiter.cr": "New Line \\r CR, MacOS",
 "datasource.csv.delimiter.crlf": "New Line \\r\\n CRLF, Windows",
 "datasource.csv.delimiter.tab": "Tabulation \\t",
 "datasource.csv.delimiter.semicolon": "Semicolon",
 "datasource.csv.delimiter.comma": "Comma",
 "datasource.csv.delimiter.space": "Space",

 "datasource.excel.label": "Microsoft Excel (XLS, XLSX)",
 "datasource.excel.description": "Read data from XLS, XLSX files",
 "datasource.excel.name": "Name",
 "datasource.excel.name.help": "Specify a name for this  Data Adapter",
 "datasource.excel.sheet.help": "Property holding the value of the sheet name to be used when parsing the Excel data",
 "datasource.excel.locale.help": "Property holding the text code of the locale to be used when parsing the Excel data",
 "datasource.excel.timezone.help": "Property holding the text value of the time zone id to be used when parsing the Excel data",
 "datasource.excel.format.help": "Specify the expected excel format between",
 "datasource.excel.queryexecuter.help": "This method is called at fill time for reports/datasets having a query supported by this factory. The report must use the XLS query language",
 "datasource.excel.skipfirstrow.help": "Property specifying whether or not the column names should be obtained from the first row in the Excel source",
 "datasource.excel.url": "Excel File Path or URL",
 "datasource.excel.url.help": "The URL or Path to retrieve the Excel file",
 "datasource.excel.queryMode": "Use query executer mode",
 "datasource.excel.format": "Excel format",
 "datasource.excel.datepattern": "Date Pattern",
 "datasource.excel.datepattern.help": "Date Pattern is a java SimpleDateFormatted pattern format string, used to parse dates, times and timestamp coming from the file",
 "datasource.excel.numberpattern": "Number Pattern",
 "datasource.excel.numberpattern.help": "Number Pattern is a java DecimalFormat pattern format string, used to parse numbers coming from the file",
 "datasource.excel.timezone": "Timezone",
 "datasource.excel.locale": "Locale",
 "datasource.excel.useFirstLineAsHeader": "Use First Line As Header",
 "datasource.excel.sheetSelection": "Sheet Selection",
 "datasource.excel.columnName": "Column Name",
 "datasource.excel.columnIndex": "Column Index",
 "datasource.excel.descriptor.datepattern": "Date Pattern is a java SimpleDateFormatted pattern format string, used to parse dates, times and timestamp coming from the file",
 "datasource.excel.descriptor.numberpattern": "Number Pattern is a java DecimalFormat pattern format string, used to parse numbers coming from the file",

 "datasource.jdbc.label": "JDBC Connection",
 "datasource.jdbc.description": "Connect to SQL Databases",
 "datasource.jdbc.name": "Name",
 "datasource.jdbc.name.help": "Specify a name for this  Data Adapter",
 "datasource.jdbc.driver": "Driver",
 "datasource.jdbc.url": "URL",
 "datasource.jdbc.urlHelp": "The URL to connect to JDBC",
 "datasource.jdbc.username": "Username",
 "datasource.jdbc.password": "Password",
 "datasource.jdbc.urlError": "Please define a valid URL",
 "datasource.jdbc.className": "Please define a valid Classname",
 "datasource.jdbc.methodName": "Please define a valid Method name",
 "datasource.jdbc.pathName": "Please define a valid path",
 "datasource.jdbc.autocommit": "Auto Commit",
 "datasource.jdbc.readonly": "Read Only",
 "datasource.jdbc.transactionisolation": "Transaction Isolation",

 "datasource.jndi.label": "JNDI",
 "datasource.jndi.description": "Use JNDI as data source",
 "datasource.jndi.name": "Name",
 "datasource.jndi.name.help": "Specify a name for this  Data Adapter",
 "datasource.jndi.jndi": "JNDI",

 "datasource.queryexecuter.label": "Query Executor",
 "datasource.queryexecuter.description": "Forces the report to go through the Query",
 "datasource.queryexecuter.name": "Name",
 "datasource.queryexecuter.name.help": "Specify a name for this  Data Adapter",

 "datasource.json.label": "JSON",
 "datasource.json.description": "Use data from JSON files",
 "datasource.json.name": "Name",
 "datasource.json.name.help": "Specify a name for this  Data Adapter",
 "datasource.json.url": "JSON File Path or URL",
 "datasource.json.url.help": "The URL or Path to retrieve the JSON file",
 "datasource.json.useConnection":"Use Connection",
 "datasource.json.language":"Language",
 "datasource.json.datepattern": "Date Pattern",
 "datasource.json.datepattern.help": "Date Pattern is a java SimpleDateFormatted pattern format string, used to parse dates, times and timestamp coming from the file",
 "datasource.json.numberpattern": "Number Pattern",
 "datasource.json.numberpattern.help": "Number Pattern is a java DecimalFormat pattern format string, used to parse numbers coming from the file",
 "datasource.json.timezone": "Timezone",
 "datasource.json.locale": "Locale",
 "datasource.json.selectExpression": "Select Expression", 

 "datasource.empty.label": "Empty Data",
 "datasource.empty.description": "Null values is generated for every field",
 "datasource.empty.recordnumber": "Number Of Records",

 "datasource.random.label": "Random Data",
 "datasource.random.description": "Random data is generated for every field",
 "datasource.random.name": "Name",
 "datasource.random.name.help": "Specify a name for this  Data Adapter",
 "datasource.random.recordnumber": "Number Of Records",
 "datasource.random.number.notanumber": "The Number Of Records is not a number",
 "datasource.random.number.negative": "The Number Of Records can not be negative",

 "datasource.xml.label": "XML",
 "datasource.xml.description": "Use data from XML files",
 "datasource.xml.name": "Name",
 "datasource.xml.locale.help": "Property holding the text code of the locale to be used when parsing the XML data",
 "datasource.xml.name.help": "Specify a name for this  Data Adapter",
 "datasource.xml.selectExpression": "Select Expression",
 "datasource.xml.url": "XML File Path or URL",
 "datasource.xml.url.help": "The URL or Path to retrieve the XML file",
 "datasource.xml.enableNamespace": "Enable Namespace support",
 "datasource.xml.xpath": "Use report XPath when filling the report",
 "datasource.xml.expression": "Create datasource using select expression",
 "datasource.xml.datepattern": "Date Pattern",
 "datasource.xml.datepattern.help": "Date Pattern is a java SimpleDateFormatted pattern format string, used to parse dates, times and timestamp coming from the file",
 "datasource.xml.numberpattern": "Number Pattern",
 "datasource.xml.numberpattern.help": "Number Pattern is a java DecimalFormat pattern format string, used to parse numbers coming from the file",
 "datasource.xml.timezone": "Timezone",
 "datasource.xml.locale": "Locale",
 "datasource.xml.timezone.help": "Property holding the text value of the time zone id to be used when parsing the XML data",


 "datasource.datasource.label": "Data Source",
 "datasource.datasource.description": "Read data from a java class",
 "datasource.datasource.name": "Name",
 "datasource.datasource.name.help": "Specify a name for this  Data Adapter",
 "datasource.datasource.factoryClass": "Factory Class",
 "datasource.datasource.methodToCall": "Method to Call",

 "datasource.html.allowBodyText": "Allow Text inside Body",
 "datasource.html.httpOptionsPanel": "HTTP OPTIONS",
 "datasource.html.username": "Username",
 "datasource.html.password": "Password",
 "datasource.html.type": "Type",
 "datasource.html.URLParameters": "URL Parameters",
 "datasource.html.POSTParameters": "POST Parameters",
 "datasource.html.PUTParameters": "PUT Parameters",
 "datasource.html.HTTPHeader": "HTTP Header",
 "datasource.html.table.parameter.name": "Name",
 "datasource.html.table.parameter.value": "Value",
 "datasource.html.table.urlparameter.name": "URL Parameter",
 "datasource.html.table.postparameter.name": "POST Parameter",
 "datasource.html.table.putparameter.name": "PUT Parameter", 
 "datasource.html.table.httpparameter.name": "HTTP Header Parameter", 

 "datasource.common.dialog.resource": "Resource",
 "datasource.common.dialog.title": "Select the Data Adapter Resource",

 "datasource.bean.name": "Name",
 "datasource.bean.name.help": "Specify a name for this  Data Adapter",
 "datasource.bean.factoryClass": "Factory Class",
 "datasource.bean.factoryClass.help": "The class that will produce the JavaBeans",
 "datasource.bean.methodName": "Method Name",
 "datasource.bean.methodName.help": "The static method in the factory that return a Collection or an Array of Objects",
 "datasource.bean.classpaths": "Jar Files Path",
 "datasource.bean.useFiledDescription": "Use Field Description",
 "datasource.bean.descriptor.label": "JavaBeans",
 "datasource.bean.description": "Use JavaBeans as datasource",
 "datasource.bean.useFieldDescription": "Use Field Description",

 "datasource.ejbql.descriptor.label": "EJBQL",
 "datasource.ejbql.description": "Read data from EJBQL",
 "datasource.ejbql.name": "Name",
 "datasource.ejbql.name.help": "Specify a name for this  Data Adapter",
 "datasource.bean.persistanceUnitName": "Persistence Unit Name",

 "datasource.hibernate.descriptor.label": "Hibernate",
 "datasource.hibernate.description": "Read data from Hibernate",
 "datasource.hibernate.name": "Name",
 "datasource.hibernate.name.help": "Specify a name for this  Data Adapter",
 "datasource.hibernate.xmlFileName": "Hibernate (.cfg.xml) File",
 "datasource.hibernate.propertiesFileName": "hibernate.proeprties File",
 "datasource.hibernate.useAnnotation": "Use Annotation",
 "datasource.hibernate.springConfigName": "Config Name",

 "datasource.springhibernate.descriptor.label": "Hibernate + Spring",
 "datasource.springhibernate.description": "Read data from Hibernate loaded with Spring",
 "datasource.springhibernate.springConfigName": "Spring Configuration",
 "datasource.springhibernate.sessionID": "Session Factory Bean ID",

 "datasource.xmla.descriptor.label": "XMLA",
 "datasource.xmla.description": "Read data from XMLA database",
 "datasource.xmla.descriptor.url": "XML/A Server URL",
 "datasource.xmla.descriptor.datasource": "Data Source",
 "datasource.xmla.descriptor.catalog": "Catalog",
 "datasource.xmla.descriptor.cube": "Cube",
 "datasource.xmla.descriptor.username": "Username",
 "datasource.xmla.descriptor.password": "Password",

 "datasource.mondrian.label": "Mondrian",
 "datasource.mondrian.description": "Read data from Mondrian database",
 "datasource.mondrian.catalog": "Catalog URI",

 "datasource.datasourceprovider.descriptor.label": "DataSourceProvider",
 "datasource.datasourceprovider.description": "Read data from a DataSourceProvider",
 "datasource.datasourceprovider.descriptor.className": "DataSourceProvider Class Name",

 "datasource.jrsdatasource.descriptor.label": "JasperReports Server",
 "datasource.jrsdatasource.description": "Connect to and use JasperReports Server",
 "datasource.jrsdatasource.name": "Name",
 "datasource.jrsdatasource.name.help": "Specify a name for this  Data Adapter",
 "datasource.jrsdatasource.descriptor.serverurl": "Server",
 "datasource.jrsdatasource.descriptor.username": "Username",
 "datasource.jrsdatasource.descriptor.password": "Password",
 "datasource.jrsdatasource.descriptor.organizzation": "Organization",
 "datasource.jrsdatasource.descriptor.datasourceurl": "Datasource Path",

 "datasource.mongoDB.descriptor.label": "MongoDB",
 "datasource.mongoDB.description": "Read data from MongoDB",
 "datasource.mongoDB.name": "Name",
 "datasource.mongoDB.name.help": "Specify a name for this  Data Adapter",
 "datasource.mongoDB.descriptor.username": "Username",
 "datasource.mongoDB.descriptor.password": "Password",
 "datasource.mongoDB.descriptor.mongouri": "Mongo URI",

 "datasource.spotfire.descriptor.label": "Spotfire Information Link",
 "datasource.spotfire.description": "Read data from Spotfire",
 "datasource.spotfire.name": "Name",
 "datasource.spotfire.name.help": "Specify a name for this  Data Adapter",
 "datasource.spotfire.descriptor.url": "URL",
 "datasource.spotfire.descriptor.username": "Username",
 "datasource.spotfire.descriptor.password": "Password",
 "datasource.spotfire.descriptor.version": "Version",
 "datasource.spotfire.descriptor.resourceid": "Resource ID",
 "datasource.spotfire.descriptor.usequery": "Use a query (required to use parameters)",

 "datasource.dbconfiguration.location": "Database Location",
 "datasource.dbconfiguration.connectionProperties": "Properties", 
 "datasource.dbconfiguration.classpath": "Classpath",
 "datasource.dbconfiguration.property.name": "Property",
 "datasource.dbconfiguration.property.value": "Value",

 "datasource.error.completefields": "Fill all the mandatory fields",

 "validator.url.invalidPath":  "The inserted value is not a valid path",
 "validator.url.invalidURL": "The inserted value is not a valid URL",
 "validator.url.invalidURLorPath": "Please define an URL or a Path",
 "validator.url.invalidJRSURL": "The URL must start with http:// or https://",
 "validator.url.invalidMongoDBURL": "The URL must start with mongodb://",
 "validator.name.invalidName": "Please define a name",
 "validator.value.isEmpty": "The value can't be empty",

 "datasource.record.notanumbererror": "The Record Count is not a number",
 "datasource.record.negativeerror" : "The Record Count can not be negative",
 "datasource.record.valuetoobigerror": "Max allowed value is 2147483647",
}
