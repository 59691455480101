/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */
import { PEnum } from "../../ui/primitive/UPEnum";

export const LineSpacingEnum = new PEnum({
    id: 'lineSpacing', label: 'LineSpacing.label', isEmptyUndefined: true, default: 'Single',
    items: [
        { key: '', value: ''},
        { key: 'Single', value: 'LineSpacing.Single'},
        { key: '1_1_2', value: 'LineSpacing.1_1_2'},
        { key: 'Double', value: 'LineSpacing.Double'},
        { key: 'AtLeast', value: 'LineSpacing.AtLeast'},
        { key: 'Fixed', value: 'LineSpacing.Fixed'},
        { key: 'Proportional', value: 'LineSpacing.Proportional'}
    ]
}); 
 
