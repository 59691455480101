/*
 * Copyright © 2018-2023. Cloud Software Group, Inc. All rights reserved.
 * Licensed under commercial Jaspersoft Subscription License Agreement
 */

import { FormControlledSelect } from "@jss/js-common";
import { List, Map } from 'immutable';
import * as React from 'react';
import i18n from '../../../../../i18n';
import { getDataset } from "../../DatasetUtils";
import { APDescriptor } from '../../ui/APDescriptor';
import { getKey, getPath, reg, UiPropertyProps } from '../../ui/UiProperty';

const TYPEID = 'enumWithGroup';
reg(TYPEID, (mc) => { return <UPEnumGroup mcontext={mc} />; });
export class PEnumGroup extends APDescriptor {
    items: { key: string, value: string, deprecated?: boolean }[];
    groupProperty: string;
    public constructor(init: Partial<PEnumGroup>) {
        super();
        Object.assign(this, { ...init, type: TYPEID });
    }
}
export class UPEnumGroup extends React.Component<UiPropertyProps> {
    private static PREFIX = 'group_';
    render() {
        const d = this.props.mcontext.descriptor as PEnumGroup;
        const p = getPath(d.id, this.props.mcontext.elements[0].path);
        const subdataset = getDataset(this.props.mcontext.model, this.props.mcontext.elements[0].path);

        const items = d.items.map((item) => {
            return {
                ...item,
                value: i18n.t(item.value),
            }
        })
        let ind = items.findIndex(i => i.key === 'Group');

        const groups: List<Map<string, any>> = subdataset.get('groups');
        if (groups?.size > 0) {
            items.splice(ind, 1);
            groups?.forEach(s => {
                const name = s.get('name');
                ind++;
                items.splice(ind, 0, { key: UPEnumGroup.PREFIX + name, value: 'Group - ' + name });
            });
        } else {
            items.splice(ind, 1);
        }
        let v = this.props.mcontext.model.getIn(p);
        if (!v) v = d.default;
        if (!v) v = d.items[0].key;

        if (v === 'Group') {
            const gname = this.props.mcontext.model.getIn(getPath(d.groupProperty, this.props.mcontext.elements[0].path));
            v = UPEnumGroup.PREFIX + gname;
        }
        return <FormControlledSelect key={getKey(p)} items={items} defaultValue={''}
            className={this.props.mcontext.descriptor.className}
            style={this.props.mcontext.descriptor.style}
            value={v}
            onItemSelected={this.onNameChange}
            label={i18n.t(d.label)}
            disabled={d.readonly}
            inline={true} size={'small'}
        />
    }
    public onNameChange = (str: string) => {
        const d = this.props.mcontext.descriptor as PEnumGroup;
        if (str.startsWith(UPEnumGroup.PREFIX)) {
            this.props.mcontext.elements.forEach(key => {
                this.props.mcontext.setObjectProperties(key.path, { [d.groupProperty]: str.substr(UPEnumGroup.PREFIX.length) });
            });
            str = 'Group';
        }
        this.props.mcontext.elements.forEach(key => {
            this.props.mcontext.setObjectProperties(key.path, { [d.id]: str });
        });
    }
}